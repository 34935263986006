import {assert} from 'assert-ts';
import {WorkMetadata} from 'api/types';
import {Schemas} from '../dto.generated';
import {mapExpressionBaseDto} from './mapExpressionBaseDto';
import {mapManifestationBaseDto} from './mapManifestationBaseDto';
import {mapWorkBaseDto} from './mapWorkBaseDto';

export const mapWorkFlattenedDto = (
  dto: Schemas.WorkFlattenedDto,
): WorkMetadata => {
  return {
    work: mapWorkBaseDto(
      assert(dto.work, 'mapWorkFlattenedDto: work expected', dto),
    ),
    expressions: assert(
      dto.expressions,
      'mapWorkFlattenedDto: expressions expected',
    ).map(mapExpressionBaseDto),
    manifestations: assert(
      dto.manifestations,
      'mapWorkFlattenedDto: manifestations expected',
    ).map(mapManifestationBaseDto),
  };
};
