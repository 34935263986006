import {assert} from 'assert-ts';
import dayjs from 'dayjs';
import {NoteContextType, NoteData} from 'api/types';
import {Schemas} from 'api/dto.generated';

export const mapCommentDto = (comment: Schemas.CommentDto): NoteData => ({
  id: assert(comment.id),
  userId: assert(comment.userId),
  contextId: assert(comment.contextId),
  contextType: assert(comment.contextType) as NoteContextType,
  created: assert(comment.created),
  modified: assert(comment.modified),
  message: assert(comment.message),
  lastModified: (typeof comment.modified === 'number'
    ? dayjs(comment.modified)
    : dayjs(comment.modified)
  )
    .toDate()
    .valueOf(),
});
