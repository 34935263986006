import {useCallback, useMemo, useRef} from 'react';
import assert from 'assert-ts';
import {DataLoadStatus, WorkSummary, getWorks} from 'api';
import {useGetResourceListByIds} from 'api/hooks/useGetResourceListByIds';
import {useGetTokens} from 'services/auth';
import {useCodelists} from 'services/codeLists';
import {mapWorkMetadataToWorkSummary} from '../functions/mapWorkMetadataToWorkSummary';
import {useMetadataCodelistIds} from './useMetadataCodelistIds';

export const useWorkSummaries = (
  idItems: Array<{id: string}> | undefined,
): DataLoadStatus<WorkSummary[]> => {
  const mock = false;
  const getTokens = useGetTokens();
  const idsRef = useRef<string[]>([]);
  const ids = useMemo(() => {
    const newIds = (idItems ?? []).map(item => item.id);
    if (
      idsRef.current.length !== newIds.length &&
      !newIds.every(id => idsRef.current.includes(id))
    ) {
      idsRef.current = newIds;
    }

    return idsRef.current;
  }, [idItems]);

  const handleGetWorks = useCallback(
    (ids: string[]) => getWorks(ids, getTokens, mock),
    [getTokens, mock],
  );

  const works = useGetResourceListByIds(
    idItems ? ids : undefined,
    handleGetWorks,
  );

  const codelistIds = useMetadataCodelistIds();
  const codelists = useCodelists(codelistIds);

  return useMemo((): DataLoadStatus<WorkSummary[]> => {
    const data = works.data
      ? works.data.map(work => mapWorkMetadataToWorkSummary(work, codelists))
      : undefined;
    return works.status === 'NotLoaded' || works.status === 'Loading'
      ? {
          status: works.status,
          data,
        }
      : works.status === 'Loaded'
      ? {
          status: 'Loaded',
          data: assert(data, 'useWorkSummaries: data expected'),
        }
      : {
          status: works.status,
          error: assert(works.error, 'useWorkSummaries: error expected'),
        };
  }, [codelists, works.data, works.error, works.status]);
  return {status: 'NotLoaded'};
};
