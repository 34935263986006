import React from 'react';
import {Ruler} from 'components';
import {DataFormV1} from 'schema';

type Props = {
  description: string | undefined;
  hideRuler?: boolean;
};
export const ManifestationDescription: React.FC<Props> = ({
  description,
  hideRuler,
}) => {
  if (!description) {
    return null;
  }

  return (
    <>
      {hideRuler ? null : <Ruler />}
      <DataFormV1
        schema={{
          name: 'description',
          parts: [
            {
              type: 'html',
              name: 'descriptionContent',
              readonly: true,
            },
          ],
        }}
        data={{
          descriptionContent: description,
        }}
        relatedData={{}}
      />
    </>
  );
};
