import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, SxProps} from '@mui/material';
import Grid from '@mui/material/Grid';
import {ThemeProvider} from '@mui/system';
import {DDSStatus, Product} from 'api/types';
import {Configuration} from 'configuration';
import {ColorPalette} from 'theme';
import {
  countExternalSuggestions,
  countProductsData,
  useGetManifestationEditValue,
  useLazyExternalSuggestions,
  useLazyManifestationMediaData,
  useLazyNotes,
  useManifestationEditValue,
  useSetManifestationEditValue,
} from 'services/data';
import {useMetadataDeleteOperations} from 'services/data/metadata/hooks/useMetadataDeleteOperations';
import {testDownloadUrl} from 'services/utils/functions';
import {useShortcut} from 'shortcuts';
import {Accordion} from 'components';
import {useLayoutTheme} from 'components/scene/hooks/useLayoutTheme';
import {DataForm} from 'schema/form';
import {useManifestationCount} from 'scenes/updateMetadata/hooks';
import {
  ManifestationCardProps,
  ManifestationPanel as ManifestationPanelType,
} from './types';
import {ChangeRequestCard} from '../ChangeRequestCard';
import {
  ManifestationButtons,
  ManifestationDescription,
  ManifestationHeader,
  ManifestationPanel,
} from './components';
import {
  ManifestationEditState,
  useHandleManifestationStatus,
  useManifestationDescription,
  useManifestationFormConfiguration,
} from './hooks';

const manifestationButtonWrapperSx: SxProps = {
  zIndex: 999,
};

type Props = {
  props: ManifestationCardProps;
  editState: ManifestationEditState;
};

export const ManifestationCardCore: React.FC<Props> = ({props, editState}) => {
  const getEditValue = useGetManifestationEditValue();
  const setEditValue = useSetManifestationEditValue(props.manifestationId);
  const {
    manifestationStatus,
    relatedScope,
    schema,
    readonly,
    showErrors,
    valid,
  } = editState;

  const {handleManifestationStatusPreview, handleManifestationStatusChanged} =
    useHandleManifestationStatus(props.manifestationId);

  const manifestationFormConfiguration = useManifestationFormConfiguration();

  const [isExpanded, setIsExpanded] = useState(!!props.initialExpanded);
  const [ebookDownloadUrl, setEbookDownloadUrl] = useState<string | undefined>(
    undefined,
  );

  useShortcut('expandAllManifestations', () => setIsExpanded(true));
  useShortcut('collapseAllManifestations', () => setIsExpanded(false));

  const {media} = useLazyManifestationMediaData(
    props.manifestationId,
    true,
    false, // props.mock,
  );

  const {notes, createNote, updateNote, removeNote} = useLazyNotes(
    props.manifestationId,
    true,
    'manifestation',
    false, // props.mock,
  );

  const {externalSuggestions} = useLazyExternalSuggestions(
    props.manifestationId,
    true,
    false,
  );

  const [showManifestationPanel, setShowManifestationPanel] = useState<
    ManifestationPanelType | undefined
  >();

  const handleToggleManifestationPanel = useCallback(
    (panel: ManifestationPanelType) => {
      setShowManifestationPanel(prev => (prev === panel ? undefined : panel));
    },
    [],
  );

  const {deleteManifestation} = useMetadataDeleteOperations();
  const count = useManifestationCount();
  const navigate = useNavigate();
  const onManifestationDelete = useCallback(
    () =>
      deleteManifestation(props.manifestationId).then(() => {
        if (count < 2) {
          navigate('/overview');
        } else {
          location.reload();
        }
      }),
    [count, deleteManifestation, navigate, props.manifestationId],
  );

  useEffect(() => {
    const isbn = getEditValue('isbn', props.manifestationId);
    const productForm = getEditValue('productForm', props.manifestationId);
    const isEbook = productForm === 'ED';
    const productOwnerCode = getEditValue(
      'productOwners[0].code',
      props.manifestationId,
    );
    const contentRecieveDate = getEditValue(
      'contentReceivedDate',
      props.manifestationId,
    );

    if (isEbook && contentRecieveDate) {
      testDownloadUrl(
        `${Configuration.app.bstEbookDownloadUrl}?isbn=${isbn}&vareeier=90${productOwnerCode}`,
      ).then(url => {
        setEbookDownloadUrl(url);
      });
    }
  }, [getEditValue, props.manifestationId]);

  const {metaqUrl, historyUrl} = useMemo(() => {
    const ean = getEditValue('ean', props.manifestationId);

    return {
      metaqUrl: `${Configuration.bb_app.search}/work/${props.workId}?ean=${ean}`,
      historyUrl: `${Configuration.bb_app.history}?ean=${ean}`,
    };
  }, [getEditValue, props.manifestationId, props.workId]);

  const changeRequestSchemaSize = 12;
  const manifestationSchemaSize = showManifestationPanel ? 7 : 9;
  const manifestationButtonsSize = 12 - manifestationSchemaSize;
  const manifestationSchemaLayout = useLayoutTheme(manifestationSchemaSize);
  const manifestationButtonsLayout = useLayoutTheme(manifestationButtonsSize);

  const miniMode = !!showManifestationPanel;
  const notesCount = notes.data?.length ?? 0;
  const mediaCount = media.data?.length ?? 0;
  const infoCount = useMemo(() => {
    const ddsStatus = getEditValue('ddsStatus', props.manifestationId) as
      | DDSStatus[]
      | undefined;
    const products = getEditValue('products', props.manifestationId) as
      | Product[]
      | undefined;

    const count =
      (getEditValue('availabilityStatus', props.manifestationId) ? 1 : 0) +
      (ddsStatus?.length ?? 0) +
      countExternalSuggestions(externalSuggestions.data ?? {}) +
      (countProductsData(products) ?? 0) +
      (getEditValue('registered', props.manifestationId) ? 1 : 0);
    return count;
  }, [externalSuggestions.data, getEditValue, props.manifestationId]);

  const manifestationDescription = useManifestationDescription(
    getEditValue,
    props.manifestationId,
  );

  return (
    <>
      <Accordion
        data-cy={'manifestation-card'}
        colorx={ColorPalette.lightBeige}
        initialExpanded={props.initialExpanded}
        unmountOnExit
        onChange={setIsExpanded}
        expanded={isExpanded}>
        <Accordion.Header>
          <ManifestationHeader
            manifestationId={props.manifestationId}
            isExpanded={isExpanded}
            showErrors={showErrors}
            valid={valid}
            manifestationStatus={manifestationStatus}
            notesCount={notesCount}
            mediaCount={mediaCount}
          />
        </Accordion.Header>
        <Accordion.Content>
          <Box
            component={Grid}
            container
            spacing={2}
            padding={0}
            flex={1}
            flexDirection={'row'}>
            {props.changeRequests?.map(mcr => (
              <Box
                key={mcr.taskId}
                component={Grid}
                item
                xs={changeRequestSchemaSize}>
                <ChangeRequestCard
                  key={mcr.taskId}
                  changeRequestId={mcr.taskId}
                />
              </Box>
            ))}
            <Box component={Grid} item xs={manifestationSchemaSize}>
              <ThemeProvider theme={manifestationSchemaLayout}>
                <DataForm
                  id={props.manifestationId}
                  schema={schema}
                  configuration={manifestationFormConfiguration}
                  relatedData={relatedScope}
                  mode={readonly ? 'read-only' : 'edit'}
                  showErrors={showErrors}
                  getEditValue={getEditValue}
                  useEditValue={useManifestationEditValue}
                  setEditValue={setEditValue}
                />
                <ManifestationDescription
                  description={manifestationDescription}
                />
              </ThemeProvider>
            </Box>
            <Box component={Grid} item xs={manifestationButtonsSize}>
              <ThemeProvider theme={manifestationButtonsLayout}>
                <Box component={Grid} container spacing={0}>
                  {showManifestationPanel ? (
                    <Box component={Grid} item xs={10}>
                      <ManifestationPanel
                        manifestationId={props.manifestationId}
                        showManifestationPanel={showManifestationPanel}
                        onToggleManifestationPanel={
                          handleToggleManifestationPanel
                        }
                        media={media}
                        notes={notes}
                        createNote={createNote}
                        updateNote={updateNote}
                        removeNote={removeNote}
                        externalSuggestions={externalSuggestions}
                      />
                    </Box>
                  ) : null}

                  <Box
                    component={Grid}
                    item
                    xs={showManifestationPanel ? 2 : 12}
                    sx={manifestationButtonWrapperSx}>
                    <ManifestationButtons
                      manifestationId={props.manifestationId}
                      manifestationStatus={manifestationStatus}
                      notesCount={notesCount}
                      mediaCount={mediaCount}
                      infoCount={infoCount}
                      miniMode={miniMode}
                      panel={showManifestationPanel}
                      changeStatusDisabled={props.changeStatusDisabled}
                      moveDisabled={props.moveDisabled}
                      onManifestationStatusPreview={
                        handleManifestationStatusPreview
                      }
                      onManifestationStatusChanged={
                        handleManifestationStatusChanged
                      }
                      onToggleManifestationPanel={
                        handleToggleManifestationPanel
                      }
                      metaqUrl={metaqUrl}
                      historyUrl={historyUrl}
                      ebookUrl={ebookDownloadUrl}
                      onManifestationDelete={onManifestationDelete}
                    />
                  </Box>
                </Box>
              </ThemeProvider>
            </Box>
          </Box>
        </Accordion.Content>
      </Accordion>
    </>
  );
};
