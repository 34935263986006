import {Fragment, ReactElement} from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import {CustomTextProps} from 'schema/types';
import {FlexBox, Spacer} from 'components';
import {usePreviewConfigurationContext} from 'schema/preview/contexts';
import {useTextLabel} from 'schema/preview/hooks';
import {Part, Valx} from '../../../../schemaDefinition/types';
import {BaseFieldProps} from '../types';
import {useLabelAndPlaceholder} from '../../../form/hooks';

type Props<TPart extends Part<Valx>> = Pick<
  BaseFieldProps<TPart>,
  'part' | 'layoutDirection'
> & {
  renderValue: () => ReactElement | null;
  /** Overrides any label lookup */
  forcedLabel?: string;
  labelProps?: CustomTextProps;
};

export const ValueLayout = <TPart extends Part<Valx>>({
  renderValue,
  forcedLabel,
  labelProps,
  part,
  layoutDirection,
}: Props<TPart>) => {
  const {label: defaultLabel} = useLabelAndPlaceholder(part);
  const config = usePreviewConfigurationContext();
  const {noLabels, noGrid} = config.layout;

  const TextLabel = useTextLabel(labelProps);

  const labelValue =
    forcedLabel ?? defaultLabel ?? part.name + ': label missing';

  return noLabels && !forcedLabel ? (
    renderValue()
  ) : layoutDirection === 'vertical' ? (
    <Fragment>
      {/* Label */}
      <TextLabel>{labelValue}</TextLabel>
      {/* Value */}
      {renderValue()}
    </Fragment>
  ) : noGrid ? (
    <FlexBox horizontal left>
      {/* Label */}
      <TextLabel>{`${labelValue}:`}</TextLabel>
      <Spacer width={0.5} />
      {/* Value */}
      {renderValue()}
    </FlexBox>
  ) : (
    <Grid2 container spacing={0}>
      {/* Label */}
      <Grid2 xs={4} sm={3} sx={{pl: 0}}>
        <TextLabel>{labelValue}</TextLabel>
      </Grid2>
      {/* Value */}
      <Grid2 xs={8} sm={9}>
        {renderValue()}
      </Grid2>
    </Grid2>
  );
};
