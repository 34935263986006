import {UniqueIdentifier} from '@dnd-kit/core/dist/types/other';
import {SxProps} from '@mui/material';
import {
  AvailableFilterWithLabel,
  ColumnSet,
  ColumnWithLabel,
  CurrentFilterType,
  CustomFilterType,
  DataResponse,
  Filter,
  FilterSet,
  LoadStatus,
} from 'api/types';
import {IconButtonProps} from 'components/button/types';
import {PaginationState} from 'components/paginator/types';
import {Schema} from 'schemaDefinition/types';

export type AdvancedSearchContextType = CurrentFiltersType &
  Omit<CurrentColumnsType, 'loadingColumns'> &
  Pick<
    DataFetchType,
    'searchResult' | 'pagination' | 'loadingSearch' | 'loadingDownload'
  > &
  Pick<AvailableFiltersType, 'availableFilters'> &
  Pick<
    ColumnSetsType,
    'columnSets' | 'deleteColumnSet' | 'createColumnSet' | 'editColumnSet'
  > &
  Pick<FilterSetsType, 'filterSets' | 'deleteFilterSet'> & {
    createFilter: (name: string, isPublic: boolean) => Promise<FilterSet>;
    loading: boolean;
    onSearch: (resetPage?: boolean) => void;
    onDownload: (columns?: string[]) => Promise<void>;
  };

export type DataFetchType = {
  searchResult: DataResponse['rows'];
  pagination: PaginationState;
  onSearch: (
    filters: Filter[],
    columns?: string[],
    page?: number,
    size?: number,
    sort?: string,
  ) => void;
  onDownload: (
    filters: Filter[],
    columns: string[] | undefined,
    sort?: string,
  ) => Promise<void>;
  loadingSearch: boolean;
  loadingDownload: boolean;
};

export type CurrentFiltersType = {
  currentFilters: CurrentFilterType[];
  setCurrentFilters: (filters: Array<Filter | CustomFilterType>) => void;
  addCurrentFilter: (filter: Filter) => void;
  updateCurrentFilter: (id: number, filter: Filter) => void;
  removeCurrentFilterById: (id: number) => void;
};

export type CurrentColumnsType = {
  schema: Schema;
  allColumns: ColumnWithLabel[];
  loadingColumns: boolean;
  currentColumns: string[];
  addCurrentColumn: (column: string) => void;
  setCurrentColumns: (columns: string[]) => void;
  removeCurrentColumn: (column: string) => void;
};

export type AvailableFiltersType = {
  availableFilters: AvailableFilterWithLabel[];
  statusAvailableFilters: LoadStatus;
};

export type FilterSetsType = {
  filterSets: FilterSet[];
  statusFilterSets: LoadStatus;
  createFilterSet: (
    name: string,
    filters: Filter[],
    isPublic: boolean,
  ) => Promise<FilterSet>;
  deleteFilterSet: (id: string) => Promise<void>;
};

export type ColumnSetsType = {
  columnSets: ColumnSet[];
  statusColumnSets: LoadStatus;
  createColumnSet: (
    name: string,
    columns: string[],
    isPublic: boolean,
  ) => Promise<ColumnSet>;
  editColumnSet: (
    id: string,
    name: string,
    columns: string[],
    isPublic: boolean,
  ) => Promise<ColumnSet>;
  deleteColumnSet: (id: string) => Promise<void>;
};

export type BooleanOption<T = 'true' | 'false'> = {
  value: T;
  label: string;
};
export type BooleanOptions = [BooleanOption<'true'>, BooleanOption<'false'>];

export const advancedSearchSchemaName = 'advancedSearch';

export type SortDirection = 'asc' | 'desc';

export type AutocompleteOption = {
  id: string;
  label: string;
};

export type ColumnName = 'pickableColumns' | 'pickedColumns';
export const ColumnNames: ColumnName[] = ['pickableColumns', 'pickedColumns'];
export type ColumnItems = {
  [key in ColumnName]: Array<string>;
};

export type DndDraggableItem = {
  id: UniqueIdentifier;
  label: string;
};

export type DndItemProps = DndDraggableItem & {
  active?: boolean;
  action?: DndItemAction;
};

export type DndItemAction = {
  type: 'add' | 'remove';
  action: (item: DndDraggableItem) => void;
};

export type ItemAction = {
  position: 'beforeText' | 'afterText';
  props: IconButtonProps;
};

export type ItemProps = {
  label: string;
  onClick?: () => void;
  active?: boolean;
  actions?: ItemAction[];
  sxProps?: SxProps;
};
