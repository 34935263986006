import {LinkAction} from 'types';
import {LinkedValueSearchFieldConfig} from 'feature/linkedValueSearch/types';
import {FieldExtensionEnum} from 'sceneExtensions/types';
import {LinkedValueLink, VerifiedLinkedValueLink} from 'schemaDefinition/types';
import {useFieldExtensionContext} from 'sceneExtensions/hooks';
import {LinkedValueSearchFieldExtensionContext} from '../types';

export const useLinkedValueSearchFieldExtension =
  (): LinkedValueSearchFieldExtensionContext => {
    return useFieldExtensionContext<
      FieldExtensionEnum.linkedValueSearch,
      LinkedValueLink,
      VerifiedLinkedValueLink,
      LinkAction,
      LinkedValueSearchFieldConfig
    >(FieldExtensionEnum.linkedValueSearch);
  };
