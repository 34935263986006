import {GrepDetails, GrepExtendedConcept} from 'api/types';
import {Schemas} from 'api/dto.generated';
import {parseNote} from './parseNote';

export const mapGrepExtendedConceptDto = (
  code: string,
  {id, type, label, deactivated, note, related}: Schemas.GrepExtendedConcept,
): GrepExtendedConcept => {
  let details: GrepDetails = {};
  if (note) {
    details = parseNote(note);
  }
  if (related && related.length > 0) {
    details.related = related.map(r => ({...r, code}));
  }
  return {
    id,
    code,
    type,
    label,
    deactivated,
    details,
  };
};
