import {assert} from 'assert-ts';
import {AdornmentResolver} from 'schema/types';
import {DataValue, Part} from 'schemaDefinition/types';
import {isNullish} from 'services/utils';

export function getAdornments<TVal>(
  part: Part<TVal>,
  value?: DataValue,
  resolver?: AdornmentResolver,
): {
  prefix?: string | JSX.Element;
  suffix?: string | JSX.Element;
} {
  const isEmpty = isNullish(value) || value === '';

  if (part.type !== 'text' && part.type !== 'int') {
    return {};
  }

  const suffix = isEmpty ? part.suffixOnEmpty ?? part.suffix : part.suffix;
  const prefix = isEmpty ? part.prefixOnEmpty ?? part.prefix : part.prefix;

  if (isNullish(prefix) && isNullish(suffix)) {
    return {prefix, suffix};
  }

  if (
    !assert.soft(resolver, 'useAdornments:Adornment resolver is not defined.')
  ) {
    return {};
  }

  return {
    prefix: resolver(prefix),
    suffix: resolver(suffix),
  };
}
