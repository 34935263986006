import {useMemo} from 'react';
import {Translate} from 'localization/core/translation/types';
import {useLocalization} from 'localization';
import {NationalAgentType} from 'api';
import {EditorMode} from '../types';

const getRegisterLabels = (
  agentType: NationalAgentType,
  hasNationalId: boolean,
  t: Translate,
) => ({
  title: t(`wizard.agent.${agentType}.register.title`),
  upsertLabel: hasNationalId
    ? t(`wizard.agent.upsert.edit.label`)
    : t(`wizard.agent.upsert.register.label`),
  steps: [
    {
      label: t('wizard.agent.step.1.label'),
      guide: t('wizard.agent.step.1.guide'),
    },
    {
      label: t('wizard.agent.step.2.label'),
      guide: '', // t('wizard.agent.step.2.guide'),
    },
  ],
});

const getEditLabels = (
  agentType: NationalAgentType,
  hasNationalId: boolean,
  t: Translate,
) => ({
  title: t(`wizard.agent.${agentType}.edit.title`),
  upsertLabel: hasNationalId
    ? t(`wizard.agent.upsert.edit.label`)
    : t(`wizard.agent.upsert.register.label`),
  steps: [
    {
      label: t('wizard.agent.step.1.label'),
      guide: t('wizard.agent.step.1.guide'),
    },
    {
      label: t('wizard.agent.step.2.label'),
      guide: '', // t('wizard.agent.step.2.guide'),
    },
  ],
});

export const useWizardLabels = (
  agentType: NationalAgentType,
  mode: EditorMode,
  hasNationalId: boolean,
) => {
  const {t} = useLocalization();
  return useMemo(
    () =>
      mode === 'edit'
        ? getEditLabels(agentType, hasNationalId, t)
        : getRegisterLabels(agentType, hasNationalId, t),
    [agentType, hasNationalId, mode, t],
  );
};
