import {assert} from 'assert-ts';
import last from 'lodash/last';
import {PartValueModifier} from 'schemaDefinition/types';
import {CodeListRef} from 'api';
import {isNullish} from 'services/utils';

const higherEdCode = CodeListRef.LITERATURE_TYPE['Lærebøker (høyere utd.)'];
const nonFictCode = CodeListRef.LITERATURE_TYPE.Faglitteratur;

export const literatureTypeModifier: PartValueModifier = ({
  value,
  part,
  oldValue,
}) => {
  assert(
    part && part.customizeKey === 'literatureType',
    'literatureTypeModifier: part must have customizeKey literatureType',
    part,
  );

  assert(
    isNullish(value) || Array.isArray(value),
    'literatureTypeModifier: value must be null or an array',
    {value, part},
  );
  assert(
    isNullish(oldValue) || Array.isArray(oldValue),
    'literatureTypeModifier: oldValue must be null or an array',
    {oldValue, part},
  );

  if (Array.isArray(value) && value.length > 0) {
    const newVal = value as string[];
    const oldVal = (oldValue ?? []) as string[];

    // Just sequence changed or item removed
    if (newVal.length <= oldVal.length) {
      return {value};
    }

    // Only add nonFictCode when
    if (
      // - appending higherEdCode
      last(newVal) === higherEdCode &&
      // - not already in value
      !newVal.includes(nonFictCode) &&
      // - not in old value
      !oldVal.includes(higherEdCode)
    ) {
      const modified = [...newVal];
      modified.splice(newVal.length - 1, 0, nonFictCode);
      return {value: modified};
    }
  }

  return {value};
};
