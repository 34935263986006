import React, {PropsWithChildren, useMemo} from 'react';
import Typography, {TypographyTypeMap} from '@mui/material/Typography';
import {useThemeColors} from 'theme';
import {TextProps} from './types';

export const Text: React.FC<PropsWithChildren<TextProps>> = ({
  variant,
  kind = 'primary',
  mode = 'main',
  sx,
  component = 'p',
  textTransform,
  keepWhitespace,
  children,
  className,
  noWrap,
  onClick,
  'data-cy': dataCy,
  'data-sentry-mask': dataSentryMask,
}) => {
  const palette = useThemeColors();
  const resolvedSx = useMemo(
    (): TypographyTypeMap['props']['sx'] =>
      textTransform || keepWhitespace
        ? {
            ...sx,
            whiteSpace: keepWhitespace ? 'pre-wrap' : undefined,
            textTransform,
          }
        : sx,
    [keepWhitespace, sx, textTransform],
  );

  return (
    <Typography
      data-sentry-mask={dataSentryMask}
      data-cy={dataCy}
      className={className}
      variant={variant}
      color={kind !== 'inherit' ? palette.text[kind][mode] : kind}
      component={component}
      noWrap={noWrap}
      onClick={onClick}
      sx={resolvedSx}>
      {children}
    </Typography>
  );
};
