import {Data, Schema} from 'schemaDefinition/types';
import {hasNestedCompare} from 'schemaDefinition';
import {ThesaurusMap} from '../types';
import {getPartsCountAndPaths} from './getPartsCountAndPaths';
import {TrimSchemaOptions, getTrimmedSchema} from './getTrimmedSchema';

const defaultOptions: TrimSchemaOptions = {
  excludeEmpty: false,
  excludeEqual: true,
  excludeReadOnly: true,
};

export const getActualDataChangesWithSchema = <T extends Data>(
  changes: T,
  original: Data,
  schema: Schema,
  thesauruses: ThesaurusMap | undefined = undefined,
  options: TrimSchemaOptions = defaultOptions,
):
  | {
      actualChanges: T;
      trimmedSchema: Schema;
      count: number;
      paths: string[];
    }
  | undefined => {
  // Start out with full copy of changes, then traverse whole schema and
  // - remove all values that are equal to corresponding original
  // - aggregate trimmed schema matching just actual changes
  // Rational: maintain values not part of the schema, e.g. workId, modified
  const actualChanges = JSON.parse(JSON.stringify(changes)) as T;

  const trimmedSchema = getTrimmedSchema(
    actualChanges,
    original,
    schema,
    options,
  );

  return trimmedSchema.hasChanges
    ? {
        actualChanges,
        trimmedSchema: trimmedSchema.trimmed,
        ...getPartsCountAndPaths(
          trimmedSchema.trimmed,
          hasNestedCompare,
          actualChanges,
          original,
          thesauruses,
        ),
      }
    : undefined;
};
