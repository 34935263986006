import {Concept, LinkAction} from 'types';
import {IconName} from 'components/icons/types';
import {LocalizationContextType} from 'localization/context/types';
import {useLocalization} from 'localization';

const actionIconMap: {[key in LinkAction]: IconName} = {
  link: 'Check',
  unlink: 'CloseSmall',
  changeLink: 'Check',
  move: 'Move',
  copy: 'Copy',
};

export const getLinkButtonIconAndTitle = (
  action: LinkAction,
  entityType: Concept | undefined,
  localization: LocalizationContextType,
) => {
  const {t, tryT} = localization;
  return {
    icon: actionIconMap[action],
    title: entityType
      ? tryT(`search.link.action.title.${action}.${entityType}`) ??
        t(`search.link.action.title.${action}`)
      : t(`search.link.action.title.${action}`),
  };
};

export const useLinkButtonIconAndTitle = (
  action: LinkAction,
  entityType?: Concept,
) => {
  return getLinkButtonIconAndTitle(action, entityType, useLocalization());
};
