import {ExpressionV4} from 'api';
import {Metadata} from '../../../types';

export const replaceLocalExpression = (
  localExpressionId: string,
  newExpression: ExpressionV4,
  currentMetadata: Metadata,
): Metadata => {
  return {
    ...currentMetadata,
    expressions: currentMetadata.expressions.map(e =>
      e.id === localExpressionId ? newExpression : e,
    ),
  };
};
