import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {assert} from 'assert-ts';
import {ThesaurusId, ThesaurusNode} from 'api/types';
import {ThesaurusValuesProps} from 'components/thesaurusValue/components/types';
import {FieldExtensionUnsubscribe as ThesaurusSearchUnsubscribe} from 'sceneExtensions/types';
import {
  FieldRequiredSimple,
  PartThesaurus,
  ThesaurusValue,
} from 'schemaDefinition/types';
import {ColorPalette} from 'theme';
import {
  areThesaurusValuesEqual,
  assertThesaurusNodeWithAncestors,
  orderByThesaurusCategories,
  useThesaurus,
} from 'services/thesaurus';
import {moveItem} from 'services/utils';
import {IconButton} from 'components/button';
import {InteractiveLayout, Layout} from 'components/layout';
import {Text} from 'components/text';
import {
  ThesaurusValuesByCategory,
  ThesaurusValuesByTypes,
} from 'components/thesaurusValue';
import {useThesaurusEditContext} from 'sceneExtensions';
import {BaseFieldProps} from './types';
import {FieldErrorMessage} from './FieldErrorMessage';
import {FieldLabel} from './FieldLabel';

export type ThesaurusFieldProps = BaseFieldProps &
  Omit<
    ThesaurusValuesProps,
    'thesaurus' | 'onDelete' | 'onSelect' | 'onMove'
  > & {
    name: string;
    thesaurusId: ThesaurusId;
    variant?: PartThesaurus['variant'];
    required?: FieldRequiredSimple;
    onChange: (thesaurusValue: ThesaurusValue | null) => void;
    onBlur?: () => void;
  };

const emptySx = {color: ColorPalette.warmGreen06} as const;
const editSx = {py: 0};
export const ThesaurusField: React.FC<ThesaurusFieldProps> = ({
  thesaurusId,
  variant = 'byCategory',
  showCode,
  selectableNodeTypes,
  // focusableId,
  label,
  placeholder,
  name,
  required,
  error,
  errorMessage,
  readonly,
  thesaurusValue,
  onChange,
  onBlur,
  flex,
  ...props
}) => {
  const thesaurus = useThesaurus(thesaurusId);
  const thesaurusValueOrdered = useMemo(() => {
    if (thesaurusValue) {
      return variant === 'byCategory'
        ? orderByThesaurusCategories(
            thesaurusValue,
            thesaurus,
            t => assertThesaurusNodeWithAncestors(t, thesaurus).ancestors[1].id,
          )
        : thesaurusValue;
    }
    return thesaurusValue;
  }, [thesaurus, thesaurusValue, variant]);

  const handleMoveNodeAtIdx = useCallback(
    (oldIndex: number, newIndex: number) => {
      if (
        !assert.soft(
          thesaurusValueOrdered,
          'ThesaurusField.handleMoveNodeAtIdx: old value expected',
        )
      ) {
        return;
      }

      const newValue = moveItem(thesaurusValueOrdered, oldIndex, newIndex);
      onChange(newValue);
    },
    [onChange, thesaurusValueOrdered],
  );

  const handleDeleteNodeCode = useCallback(
    (nodeCode: string) => {
      if (
        !assert.soft(
          thesaurusValueOrdered,
          'ThesaurusField.handleDeleteNodeCode: old value expected',
        )
      ) {
        return;
      }
      const newValue = thesaurusValueOrdered.filter(node => node !== nodeCode);
      onChange(newValue?.length > 0 ? newValue : null);
    },
    [onChange, thesaurusValueOrdered],
  );

  const {
    // sourceField,
    setSourceField,
  } = useThesaurusEditContext();

  const unsubscribeRef = useRef<ThesaurusSearchUnsubscribe>();

  const lastEditValueRef = useRef<ThesaurusValue | undefined>();
  const handleSetSourceValue = useCallback(
    (newValue: ThesaurusValue | undefined) => {
      lastEditValueRef.current = newValue;
      // To keep open, don't unsubscribe
      // unsubscribeRef.current && unsubscribeRef.current(name);
      // unsubscribeRef.current = undefined;
      onChange(newValue ?? null);
    },
    [onChange],
  );

  const handleDisconnect = useCallback(() => {
    unsubscribeRef.current = undefined;
  }, []);

  const handleEditThesaurusCore = useCallback(
    (value: ThesaurusValue | undefined, focusNode?: ThesaurusNode) => {
      Promise.resolve(
        setSourceField(
          {
            fieldId: name,
            currentValue: value,
            fieldConfig: {
              thesaurusId,
              showCode: showCode,
              selectableNodeTypes,
              focusNodeCodeWithTimestamp: focusNode
                ? `${focusNode.id}:${Date.now()}`
                : undefined,
            },
          },
          handleSetSourceValue,
          handleDisconnect,
        ),
      ).then(unsubscribe => {
        unsubscribeRef.current = unsubscribe;
      });
    },
    [
      setSourceField,
      name,
      thesaurusId,
      showCode,
      selectableNodeTypes,
      handleSetSourceValue,
      handleDisconnect,
    ],
  );

  const handleEditThesaurus = useCallback(
    () => handleEditThesaurusCore(thesaurusValueOrdered),
    [handleEditThesaurusCore, thesaurusValueOrdered],
  );

  const handleSelectNode = useCallback(
    (node: ThesaurusNode) => {
      return handleEditThesaurusCore(thesaurusValueOrdered, node);
    },
    [handleEditThesaurusCore, thesaurusValueOrdered],
  );

  // When value is changed and editing value in right panel, update source value.
  useEffect(() => {
    if (
      unsubscribeRef.current &&
      !areThesaurusValuesEqual(lastEditValueRef.current, thesaurusValueOrdered)
    ) {
      handleEditThesaurusCore(thesaurusValueOrdered);
    }

    // Only trigger on updated value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thesaurusValueOrdered]);

  useEffect(() => {
    return () => {
      unsubscribeRef.current && unsubscribeRef.current(name);
    };
    // On unmount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emptyValue =
    !thesaurusValueOrdered || thesaurusValueOrdered.length === 0;
  // const editing = !readonly && sourceField?.fieldId === name;
  // const iconColor = 'primary';
  // const className = editing ? 'ThesaurusField-searching' : undefined;

  const ThesaurusValues =
    variant === 'byCategory'
      ? ThesaurusValuesByCategory
      : ThesaurusValuesByTypes;

  return (
    <InteractiveLayout
      flex={flex}
      onBlur={onBlur}
      data-cy={`field-thesaurus-${thesaurusId}`}>
      {/* Header */}
      <Layout horizontal adjustCenter justifyContent={'space-between'}>
        <Layout>
          {label && (
            <FieldLabel label={label} required={required} error={error} />
          )}
          <FieldErrorMessage error={error} errorMessage={errorMessage} />
        </Layout>
        {!readonly ? (
          <IconButton
            icon={emptyValue ? 'Add' : 'Edit'}
            onClick={handleEditThesaurus}
            data-cy={'thesaurus-add-or-edit'}
            sx={editSx}
          />
        ) : null}
      </Layout>

      {emptyValue ? (
        <Text variant="body1" sx={emptySx}>
          {placeholder}
        </Text>
      ) : (
        <ThesaurusValues
          {...props}
          thesaurus={thesaurus}
          thesaurusValue={thesaurusValueOrdered}
          showCode={showCode}
          selectableNodeTypes={selectableNodeTypes}
          readonly={readonly}
          onDelete={handleDeleteNodeCode}
          onSelect={handleSelectNode}
          onMove={handleMoveNodeAtIdx}
        />
      )}
    </InteractiveLayout>
  );
};
