import React, {Fragment} from 'react';
import Typography from '@mui/material/Typography';
import {DataLoadStatus, Media} from 'api/types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {ActivityIndicator, Card, Spacer} from 'components';
import {useTextValue} from 'schema/preview/hooks';
import {FileCard} from './FileCard';
import {ManifestationPanelContainer} from './ManifestationPanelContainer';

type Props = {
  files: DataLoadStatus<Media[]>;
  mock?: boolean;
  onClose: () => void;
};

export const FilesPanel: React.FC<Props> = ({files, mock, onClose}) => {
  const data = files.data;
  const TextValue = useTextValue();
  const {t} = useLocalization();

  return (
    <ManifestationPanelContainer
      title={t('page.metadata.manifestation.tools.files')}
      icon={'Files'}
      onClose={onClose}>
      {files.status === 'NotLoaded' || files.status === 'Loading' ? (
        <ActivityIndicator />
      ) : files.status === 'Failed' ? (
        <Typography variant="body1" color={ColorPalette.error}>
          {t('error.files.failedToLoad')}
        </Typography>
      ) : Array.isArray(data) && data.length > 0 ? (
        data.map((f, idx, data) => (
          <Fragment key={f.id}>
            <FileCard file={f} mock={mock} />
            {idx + 1 < data.length ? <Spacer size={1} /> : null}
          </Fragment>
        ))
      ) : (
        <Card colorx={ColorPalette.primary.white}>
          <TextValue>{t('error.files.empty')}</TextValue>
        </Card>
      )}
    </ManifestationPanelContainer>
  );
};
