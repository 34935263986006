import {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FilterModel, Sorting} from 'services/settings/types';
import {AppState} from 'store/types';
import {ColumnConfig} from '../types';

const tablesSelector = (state: AppState) => state.settings.tables;

export type ColumnConfigStateProps = {
  columnConfigs: ColumnConfig[] | undefined;
  setColumnConfigs: (columnConfigs: ColumnConfig[]) => void;
  setColumnConfigVisibility: (columnName: string, visible: boolean) => void;
  setColumnConfigWidth: (columnName: string, width: number) => void;
  sortingConfig?: Sorting;
  setSortingConfig: (sorting?: Sorting) => void;
  filterModel?: FilterModel | undefined;
  setFilterModel: (filterModel?: FilterModel) => void;
};

export const useColumnConfigState = (
  schemaName: string,
): ColumnConfigStateProps => {
  const dispatch = useDispatch();
  const tablesState = useSelector(tablesSelector);

  const columnConfigs = useMemo(() => {
    return tablesState[schemaName]?.columns ?? [];
  }, [tablesState, schemaName]);

  const sortingConfig = useMemo(() => {
    return tablesState[schemaName]?.sorting ?? undefined;
  }, [tablesState, schemaName]);

  const setSortingConfig = useCallback(
    (sorting?: Sorting) => {
      dispatch({
        type: 'SET_TABLE_SORTING',
        payload: {
          name: schemaName,
          sorting: sorting,
        },
      });
    },
    [schemaName, dispatch],
  );

  const filterModel = useMemo(() => {
    return tablesState[schemaName]?.filterModel ?? undefined;
  }, [tablesState, schemaName]);

  const setFilterModel = useCallback(
    (filterModel?: FilterModel) => {
      dispatch({
        type: 'SET_TABLE_FILTERMODEL',
        payload: {
          name: schemaName,
          filterModel: filterModel,
        },
      });
    },
    [schemaName, dispatch],
  );

  const setColumnConfigs = useCallback(
    (columnConfigs: ColumnConfig[]) => {
      dispatch({
        type: 'SET_TABLE_COLUMN_CONFIGS',
        payload: {
          name: schemaName,
          config: columnConfigs,
        },
      });
    },
    [schemaName, dispatch],
  );

  const setColumnConfigVisibility = useCallback(
    (columnName: string, visible: boolean) => {
      const columnConfig = columnConfigs.find(s => s.name === columnName);
      if (columnConfig) {
        columnConfig.visible = visible;
      } else {
        columnConfigs.push({
          name: columnName,
          visible: visible,
        });
      }

      setColumnConfigs(columnConfigs);
    },
    [columnConfigs, setColumnConfigs],
  );

  const setColumnConfigWidth = useCallback(
    (columnName: string, width: number) => {
      const columnConfig = columnConfigs.find(s => s.name === columnName);
      if (columnConfig) {
        columnConfig.width = width;
      } else {
        columnConfigs.push({
          name: columnName,
          width: width,
          visible: true, // Default true when handled
        });
      }

      setColumnConfigs(columnConfigs);
    },
    [columnConfigs, setColumnConfigs],
  );

  return {
    columnConfigs,
    setColumnConfigs,
    setColumnConfigVisibility,
    setColumnConfigWidth,
    sortingConfig,
    setSortingConfig,
    filterModel,
    setFilterModel,
  };
};
