import {DefaultPartCompare, Part, Valx} from 'schemaDefinition/types';
import {PartDiffProps} from '../components/types';

export const needsOrignalSubValueContainer = (
  part: Part<Valx>,
  diff: PartDiffProps,
): boolean => {
  return (
    diff &&
    diff.mode === 'original' &&
    (part.compare ?? diff.outerCompare ?? DefaultPartCompare) === 'subValues'
  );
};
