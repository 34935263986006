import {Text} from './Text';

type Props = {
  message: string;
};

export const ErrorMessage = ({message}: Props) => (
  <Text kind="error" variant="body2">
    {message}
  </Text>
);
