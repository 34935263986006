import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const AddIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M17 11H13V7H11V11H7V13H11V17H13V13H17V11Z" />
    </SvgIcon>
  );
};
