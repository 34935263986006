import React from 'react';
import {Box} from '@mui/material';
import {Spacer} from 'components';

export const ACTIONS_SIZE = '90px';
export const ACTIONS_HALF_SIZE = '45px';

type Props = {
  renderCurrent: () => React.ReactNode;
  renderProposed: () => React.ReactNode;
};

/**
 * Two column layout for comparing data.
 * Handles column width of current and proposed values,
 * allocating extra space to proposed value to allow for
 * action buttons, e.g. replace
 */
export const DataFormCompareLayout: React.FC<Props> = ({
  renderCurrent,
  renderProposed,
}) => {
  return (
    <>
      <Box style={{width: `calc(50% - ${ACTIONS_HALF_SIZE})`}}>
        {renderCurrent()}
      </Box>
      <Spacer size={3} />
      <Box style={{width: `calc(50% + ${ACTIONS_HALF_SIZE})`}}>
        {renderProposed()}
      </Box>
    </>
  );
};
