import {GridColumnVisibilityModel} from '@mui/x-data-grid-pro';

export function compareVisibilityModels(
  before: GridColumnVisibilityModel,
  after: GridColumnVisibilityModel,
): GridColumnVisibilityModel {
  const diff: GridColumnVisibilityModel = {};

  Object.keys(before).forEach(key => {
    if (before[key] !== after[key]) {
      diff[key] = after[key] ?? false;
    }
  });

  Object.keys(after).forEach(key => {
    if (after[key] !== before[key]) {
      diff[key] = after[key] ?? false;
    }
  });

  return diff;
}
