import {assert} from 'assert-ts';
import {GetTokens, ManifestationsMedia, Media} from './types';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpGet, httpPost} from './http/ebba';
import {
  mapMultipleManifestationMedia,
  mapSingleManifestationMedia,
} from './mappers/mapMediaDto';

export const getMediaForManifestation = (
  id: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<Media[]> => {
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.MediaForManifestationsDto, string[]>({
      subdir: 'manifestation/media',
      body: [id],
      getTokens,
      mock,
    }).then(mapSingleManifestationMedia),
  );
};

export const getMediaForManifestations = (
  ids: string[],
  getTokens: GetTokens,
  mock?: boolean,
): Promise<ManifestationsMedia[]> => {
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.MediaForManifestationsDto, string[]>({
      subdir: 'manifestation/media',
      body: ids,
      getTokens,
      mock,
    }).then(mapMultipleManifestationMedia),
  );
};

export const getMediaUrl = (
  mediaId: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<string> => {
  return exceptionToPromiseReject(() =>
    httpGet<Schemas.DownloadInfoDto>({
      subdir: 'media/{mediaId}',
      subdirParams: {mediaId},
      getTokens,
      mock,
    }).then(dto => assert(dto.uri, 'getMediaUrl: uri expected')),
  );
};
