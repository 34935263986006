import {ErrorKeyAndMessage, HttpError} from 'api/http/types';

export const getErrorInfo = (
  error: unknown,
): {
  errorMessage?: string;
  errorKey?: string;
} => {
  if (error instanceof HttpError) {
    if (
      (error.info as ErrorKeyAndMessage).errorKey ||
      (error.info as ErrorKeyAndMessage).errorMessage
    ) {
      return error.info as ErrorKeyAndMessage;
    }
  }

  if (error instanceof Error && error.message) {
    return {
      errorMessage: error.message,
    };
  }

  return {};
};
