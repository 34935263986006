import {useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import isEqual from 'lodash/isEqual';
import {ExpressionImportProps} from '../types';
import {expressionExportsSelectorById, workExportsSelector} from '../selectors';
import {useCreateSelectorById} from './useCreateSelectorById';

export const useExpressionImportProps = (
  expressionId: string,
): ExpressionImportProps => {
  const workExports = useSelector(workExportsSelector);
  const expressionExportsSelector = useCreateSelectorById(
    expressionId,
    expressionExportsSelectorById,
  );
  const expressionExports = useSelector(expressionExportsSelector);

  const exportsRef = useRef<ExpressionImportProps>();

  return useMemo(() => {
    const newExports = {
      work: workExports,
      expression: assert(
        expressionExports,
        'useExpressionImportProps: unknown expressionId',
      ),
    };

    if (!isEqual(newExports, exportsRef.current)) {
      exportsRef.current = newExports;
    }

    return assert(exportsRef.current);
  }, [expressionExports, workExports]);
};
