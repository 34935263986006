import {CodeList, CodeListId, DataLoadByIdStatus} from 'api/types';
import {LoadByIdAction} from 'services/utils/redux/actions';

export type CodeListsState = DataLoadByIdStatus<CodeList, CodeListId>;

export enum CodeListActionType {
  LOAD_CODELIST = 'LOAD_CODELIST',
}

export type CodeListsAction_LoadById = {
  type: CodeListActionType.LOAD_CODELIST;
} & LoadByIdAction<CodeList, CodeListId>;

export type CodeListsAction = CodeListsAction_LoadById;
