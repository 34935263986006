/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useCallback, useMemo, useState} from 'react';
import {Box, Button, Grid} from '@mui/material';
import {ColumnSet, ColumnWithLabel} from 'api/types';
import {useLocalization} from 'localization';
import {
  Dialog,
  FieldLabel,
  GroupItemNameValue,
  Icon,
  Layout,
  Select,
  SelectProps,
  Spacer,
} from 'components';
import {SortDirection} from '../types';
import {ColumnCheckbox} from '../components/ColumnCheckbox';
import {mapColumnSetToOption} from '../mappers/mapColumnSetToOption';
import {mapSortingConfigToString} from '../mappers/mapSortingConfigToString';

const ALL_COLUMNS = 'allColumns';
export const useDownloadDialog = (
  onDownload: (columns?: string[], sort?: string) => Promise<void>,
) => {
  const {t} = useLocalization();
  const [open, setOpen] = useState<boolean>(false);
  const [showColumnCheckboxes, setShowColumnCheckboxes] =
    useState<boolean>(false);

  const [sortOptionId, setSortOptionId] = useState<
    ColumnWithLabel['name'] | ''
  >('');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');

  const [allColumnSets, setAllColumnSets] = useState<ColumnSet[]>();
  const [allColumns, setAllColumns] = useState<ColumnWithLabel[]>();

  const [selectedColumnSetId, setSelectedColumnSetId] = useState<string>();
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [downloading, setDownloading] = useState<boolean>(false);

  const handleOnSelectColumnSet = useCallback(
    (columnSetId: string) => {
      setSelectedColumnSetId(columnSetId);

      if (columnSetId === ALL_COLUMNS) {
        setSelectedColumns(allColumns?.map(c => c.name) ?? []);
      } else {
        const selectedColumnSet = allColumnSets?.find(
          c => c.id === columnSetId,
        );
        setSelectedColumns(selectedColumnSet?.columns ?? []);
      }
    },
    [allColumnSets, allColumns],
  );

  const handleToggleSelectedColumn = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const columnName = event.target.name;
      if (!checked) {
        setSelectedColumns(selectedColumns.filter(c => c !== columnName));
      } else {
        setSelectedColumns([...selectedColumns, columnName]);
      }
    },
    [selectedColumns],
  );

  const columnSetOptions: SelectProps['options'] = useMemo(() => {
    if (!(allColumnSets ?? []).length) {
      return [];
    }

    // Grab the "allColumns"-option and place it first.
    const allColumnsOption = mapColumnSetToOption(
      allColumnSets?.shift() as ColumnSet,
    );

    // Adds a Group item between each private/public-block.
    const privateOptions = (allColumnSets ?? [])
      .filter(c => !c.isPublic)
      .map(mapColumnSetToOption);

    if (privateOptions.length > 0) {
      privateOptions.unshift({
        value: GroupItemNameValue,
        label: t('data.field.private.label'),
      });
    }

    const publicOptions = (allColumnSets ?? [])
      .filter(c => c.isPublic)
      .map(mapColumnSetToOption);

    if (publicOptions.length > 0) {
      publicOptions.unshift({
        value: GroupItemNameValue,
        label: t('data.field.public.label'),
      });
    }
    return [allColumnsOption, ...privateOptions, ...publicOptions];
  }, [allColumnSets, t]);

  const sortOptions: SelectProps['options'] = useMemo(() => {
    return (allColumns ?? []).map(a => ({
      value: a.name,
      label: a.label,
    }));
  }, [allColumns]);

  const sortDirectionOptions: SelectProps<SortDirection>['options'] =
    useMemo(() => {
      const sortDirections: SortDirection[] = ['asc', 'desc'];
      return sortDirections.map(direction => ({
        value: direction,
        label: t(`general.sort.${direction}`),
      }));
    }, [t]);

  const handleOpen = useCallback(
    (
      allCollections: ColumnSet[],
      allColumns: ColumnWithLabel[],
      currentCollectionId?: string,
    ) => {
      setAllColumnSets([
        {
          id: ALL_COLUMNS,
          name: t('data.column.all'),
          isPublic: false,
          columns: [],
        },
        ...allCollections,
      ]);
      setAllColumns(allColumns);
      //setSortOptionId(allColumns[0].name);
      handleOnSelectColumnSet('');
      setOpen(true);
    },
    [handleOnSelectColumnSet, t],
  );

  const handleClose = useCallback(() => {
    setAllColumnSets([]);
    setOpen(false);
  }, []);

  const handleSave = useCallback(() => {
    setDownloading(true);
    const sort = sortOptionId
      ? mapSortingConfigToString({
          field: sortOptionId,
          sort: sortDirection,
        })
      : undefined;
    onDownload(selectedColumns, sort).then(() => {
      setDownloading(false);
      handleClose();
    });
  }, [selectedColumns, sortOptionId, sortDirection, onDownload, handleClose]);

  const RenderColumnCheckboxes = useMemo(() => {
    return (allColumns ?? []).map(column => (
      <Grid item xs={6} sm={4} md={3} key={column.name}>
        <ColumnCheckbox
          name={column.name}
          label={column.label}
          checked={selectedColumns.includes(column.name)}
          onChange={handleToggleSelectedColumn}
        />
      </Grid>
    ));
  }, [allColumns, handleToggleSelectedColumn, selectedColumns]);

  const Form = useMemo(() => {
    return (
      <Dialog
        isOpen={open}
        title={t('page.search.filter.download.title')}
        body={
          <Layout flex={1}>
            <FieldLabel label={t('page.search.filter.collection.label')} />

            <Select
              value={selectedColumnSetId}
              options={columnSetOptions}
              onChange={value => handleOnSelectColumnSet(value)}
            />
            <Spacer height={2} />
            <Layout>
              <Button
                variant={'text'}
                size={'large'}
                onClick={() => setShowColumnCheckboxes(!showColumnCheckboxes)}
                startIcon={
                  <Icon
                    icon={showColumnCheckboxes ? 'ChevronUp' : 'DropDown'}
                  />
                }>
                {showColumnCheckboxes
                  ? 'Skjul enkeltkolonner'
                  : 'Vis enkeltkolonner'}
              </Button>
            </Layout>
            <Spacer height={1} />
            {showColumnCheckboxes ? (
              <Box
                component={Grid}
                container
                sx={{
                  maxWidth: 'md',
                }}
                spacing={1}
                padding={0}
                flex={1}
                flexDirection={'row'}>
                {RenderColumnCheckboxes}
              </Box>
            ) : null}

            <Spacer height={3} />
            <FieldLabel label={t('general.sort.title')} />
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <Select
                  value={sortOptionId}
                  options={sortOptions}
                  onChange={value => setSortOptionId(value)}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Select<SortDirection>
                  value={sortDirection}
                  options={sortDirectionOptions}
                  onChange={value => setSortDirection(value)}
                />
              </Grid>
            </Grid>
          </Layout>
        }
        dialogItems={[
          {
            itemType: 'action',
            showSpinnerOnClick: true,
            disabled: downloading,
            title: t('general.ok'),
            onClick: () => handleSave(),
          },
          {
            itemType: 'action',
            type: 'reset',
            alignLeft: true,
            disabled: downloading,
            title: t('general.cancel'),
            onClick: () => handleClose(),
          },
        ]}
      />
    );
  }, [
    open,
    t,
    selectedColumnSetId,
    columnSetOptions,
    showColumnCheckboxes,
    RenderColumnCheckboxes,
    sortOptionId,
    sortOptions,
    sortDirection,
    sortDirectionOptions,
    downloading,
    handleOnSelectColumnSet,
    handleSave,
    handleClose,
  ]);

  return {
    DownloadCsvDialog: Form,
    openDownloadCsvDialog: handleOpen,
  };
};
