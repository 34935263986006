import {CodeList, CodeListRef} from 'api/types';
import {ExpressionImportProps} from 'services/data/metadata/types';
import {isNullish} from 'services/utils';

export function getRestrictedExpressionRoleCodelist(
  {expression: {expressionFormat}}: ExpressionImportProps,
  codelist: CodeList,
): CodeList {
  if (isNullish(expressionFormat)) {
    return codelist;
  }

  let codes = codelist.codes;
  if (
    (
      [
        CodeListRef.EXPRESSION_FORMAT.AUDIO,
        // TODO: Add validation of roles against restricted codelist, in general, validate codes against restricted codelists
        CodeListRef.EXPRESSION_FORMAT.TEXT,
      ] as string[]
    ).includes(expressionFormat)
  ) {
    codes = codes.map(item =>
      !(
        [
          CodeListRef.EXPRESSION_ROLE_TYPE['Illustrert av'],
          CodeListRef.EXPRESSION_ROLE_TYPE['Fotografier av'],
        ] as string[]
      ).includes(item.code)
        ? item
        : {...item, disabled: true},
    );
  }

  if (
    (
      [
        CodeListRef.EXPRESSION_FORMAT.TEXT,
        CodeListRef.EXPRESSION_FORMAT.ILLUSTRATED_TEXT,
      ] as string[]
    ).includes(expressionFormat)
  ) {
    codes = codes.map(item =>
      !(
        [
          CodeListRef.EXPRESSION_ROLE_TYPE['Solist (vokal)'],
          CodeListRef.EXPRESSION_ROLE_TYPE['Solist (instrumental)'],
        ] as string[]
      ).includes(item.code)
        ? item
        : {...item, disabled: true},
    );
  }

  return {
    ...codelist,
    codes,
  };
}
