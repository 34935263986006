import {AgentSubType, CollectionSubType, Concept} from 'types';
import {
  Data,
  LinkedAgentMultiRole,
  LinkedLiterary,
  VerifiedLinkedCatalogPost,
} from 'schemaDefinition/types';
import {
  LinkedRoleCodeList,
  LinkedRoleCodeListId,
} from 'schemaDefinition/linkTypes';
import {HttpError} from './http/types';
import {Schemas} from './dto.generated';

export type Tokens = {
  accessToken: string;
  idToken: string;
};

export type GetTokens = () => Promise<Tokens>;

export type LoadStatus = 'NotLoaded' | 'Loading' | 'Loaded' | 'Failed';

export type DataLoadStatus<TData> =
  | {
      status: 'NotLoaded' | 'Loading';
      partial?: boolean;
      data?: TData;
      error?: HttpError;
    }
  | {
      status: 'Loaded';
      partial?: boolean;
      data: TData;
      error?: HttpError;
    }
  | {
      status: 'Failed';
      partial?: boolean;
      data?: TData;
      error: HttpError;
    };

export type DataLoadByIdStatus<TData, TId extends string | number> = {
  [key in TId]?: DataLoadStatus<TData>;
};

export type UpdateStatus = {
  status: LoadStatus;
  error?: HttpError;
};

//=== Domain types =================================================================================
// I.e. domain types used in front-end, based on DTOs from the API.

//===== Utility types ==============================================================================

export type EntityBase<TId = string> = {
  id: TId;
  created: number;
  modified: number;
};

//----- Task ---------------------------------------------------------------------------------------

export type TaskType = 'new' | 'pre' | 'control' | 'change' | 'mottak' | 'ml';

export type TaskTypeAll = TaskType | 'all';

export const TaskTypes: Readonly<TaskType[]> = [
  'new',
  'pre',
  'control',
  'change',
  'mottak',
  'ml',
] as const;

export const DeprecatedTaskTypes: Readonly<TaskType[]> = [
  'ml',
  'mottak',
] as const;

export type ManifestationStatus =
  Required<Schemas.ManifestationBaseDto>['status'];

export type ExternalSuggestions = Schemas.ExternalSuggestionsDto;

export type TaskStatus = Required<Schemas.TaskDto>['status'];

/**
 * @deprecated Use ProductStatus instead
 */
export type AvailabilityStatus = Required<
  Required<Schemas.MetadataDto>
>['availabilityStatus'];

export type ProductStatus = Required<Required<Schemas.ProductDto>>['status'];

export type TaskV2 = EntityBase<number> & {
  type: TaskType;
  status?: Schemas.TaskV2Dto['status'];
  metadata: {
    manifestationId: Required<Schemas.TaskV2Dto>['metadata']['manifestationId'];
    workId: Required<Schemas.TaskV2Dto>['metadata']['workId'];
    isbn?: Required<Schemas.TaskV2Dto>['metadata']['isbn']; // string;
    mainTitle?: Required<Schemas.TaskV2Dto>['metadata']['title']; // string;
    mainAgent?: string; // Just a string, because we recieve a Verified Agent without id.
    productForm?: Required<Schemas.TaskV2Dto>['metadata']['productForm']; // string;
    contentReceivedDate?: Schemas.MetadataDto['contentReceivedDate'];
    publisherSalesDate?: Schemas.MetadataDto['publisherSalesDate'];
    inSaleDate?: Schemas.MetadataDto['inSaleDate'];
    availabilityStatus?: Schemas.MetadataDto['availabilityStatus'];
    media?: Media[];
    productOwner?: ProductOwner;
    registered?: Schemas.MetadataDto['registered'];
  };
};

//----- Receive -----
export type RegisteredReceiveContentResponse =
  Schemas.PhysicalContentReceivedResponse;

//----- Data -----------------------------------------------------------------------------------

export type FilterType = Schemas.AvailableFilter['type'];
export type FilterOperation = Schemas.Filter['operation'] | 'BETWEEN';
export type FilterOperator = Schemas.Filter['operator'];

export type Filter = Omit<
  Schemas.Filter,
  'value' | 'operator' | 'operation'
> & {
  values?: Array<string>;
  operation: FilterOperation;
};

export type CurrentFilterType = Filter & {
  id: number;
  hidden?: boolean;
};

export type CustomFilterType = CurrentFilterType & {
  hidden: true;
};

export type FilterSet = Omit<Schemas.FilterSet, 'filters'> & {
  filters: Array<Filter | CustomFilterType>;
};

export type Column = Omit<Schemas.Column, 'codelistId'> & {
  codelistId?: Schemas.CodelistDto['id'];
};
export type ColumnWithLabel = Column & {
  label: string;
};
export type ColumnSet = Schemas.ColumnSet;

export type Pageable = Required<Omit<Schemas.Pageable, 'sort'>> & {
  sort?: string;
};

export type DataRow = Schemas.ExportRow;
export type DataResponse = {
  hits: number;
  rows: Data[];
};

export type AvailableFilter = Omit<
  Schemas.AvailableFilter,
  'codelistId' | 'operations'
> & {
  operations: Array<FilterOperation>;
  codelistId?: Schemas.CodelistDto['id'];
};
export type AvailableFilterWithLabel = AvailableFilter & {
  label: string;
  codelistId?: CodeListId;
};

//----- DateType -----------------------------------------------------------------------------------

export type DateType = Schemas.DateTypeDto & {
  bce?: boolean;
};

//----- AgentName ----------------------------------------------------------------------------------

export type AgentName = Omit<Schemas.AgentNameDto, 'nameOrder'> & {
  description?: string;
};

//----- Agent --------------------------------------------------------------------------------------

export type AgentTypeDto = Schemas.AgentDto['agentType'];

/**
 *  When not yet verified/resolved agent will have undefined id
 */
export type AgentBase = Omit<
  Schemas.AgentDto,
  'nameVariants' | 'agentType' | 'works'
> & {
  nameVariants: AgentName[];
  works?: AgentWorkSummary[];
};

export type AgentWorkSummary = WorkSummary &
  Pick<Schemas.AgentWorkRoleDto, 'roles'>;

export type AgentPerson = AgentBase &
  Omit<
    Schemas.AgentPersonDto,
    'agentType' | 'nameVariants' | 'works' | 'birth' | 'death'
  > & {
    agentType: Extract<AgentSubType, Concept.person>;
    birth?: DateType;
    death?: DateType;
    links?: LinkedLiterary[];
  };

export type Gender = Schemas.AgentPersonDto['gender'];

export type AgentPublisher = AgentBase &
  Omit<Schemas.AgentPublisherDto, 'agentType' | 'nameVariants' | 'works'> & {
    agentType: Extract<AgentSubType, Concept.publisher>;
    links?: LinkedLiterary[];
  };

export type AgentNameWithDescription = AgentName & {description?: string};

/**
 * Adjust AgentCorporation to have description on each nameVariant
 * instead of agent object. Will be handled by mapping from/to
 * DTOs when doing API calls
 */
export type AgentCorporation = Omit<AgentBase, 'nameVariants'> &
  Omit<
    Schemas.AgentCorporationDto,
    'agentType' | 'nameVariants' | 'works' | 'description'
  > & {
    agentType: Extract<AgentSubType, Concept.corporation>;
    nameVariants: AgentNameWithDescription[];
    links?: LinkedLiterary[];
  };

export type AgentEvent = Omit<AgentBase, 'agentType' | 'nameVariants'> & {
  agentType: Extract<AgentSubType, Concept.event>;
  place?: Schemas.AgentEventDto['place'];
  from?: Schemas.AgentEventDto['from'];
  to?: Schemas.AgentEventDto['to'];
  nameVariants: AgentNameWithDescription[];
  links?: LinkedLiterary[];
};

export type Agent =
  | AgentPerson
  | AgentPublisher
  | AgentCorporation
  | AgentEvent;

/**
 * Nasjonalt Autoritetsregister har ikke PUBLISHERs
 */
export type NationalAgentType = Exclude<AgentSubType, Concept.publisher>;

export type NationalRegistryStatus = 'kat1' | 'kat2' | 'kat3' | 'deleted';
/**
 * Nasjonalt Autoritetsregister har kun personer, korporasjoner og events
 */
export type NationalAgent = (AgentPerson | AgentCorporation | AgentEvent) & {
  registryStatus?: NationalRegistryStatus;
};

//----- Linked Value Links: Generalisation of link-part of LinkedAgent and LinkedLiterary  ------------------------------

//----- LinkedAgent ----------------------------------------------------------------------------------

//----- LinkedLiterary ----------------------------------------------------------------------------------

//----- Media --------------------------------------------------------------------------------------

export type MediaType = Schemas.MediaDto['mediaType'];
export type MediaSubType = Schemas.MediaDto['mediaSubType'];

export type Media = {
  id: Required<Schemas.MediaDto>['mediaId'];
  extension: Required<Schemas.MediaDto>['fileExtension'];
  type: Required<Schemas.MediaDto>['mediaType'];
  subType: Required<Schemas.MediaDto>['mediaSubType'];
  version: Required<Schemas.MediaDto>['version'];

  fileNr: number;
};

export type ManifestationsMedia = {
  manifestationId: string;
  medias: Media[];
};

//----- DownloadInfo -------------------------------------------------------------------------------

export type DownloadInfo = {
  uri: Required<Schemas.DownloadInfoDto>['uri'];
  status: Required<Schemas.DownloadInfoDto>['status'];
};

//----- Work ---------------------------------------------------------------------------------------

export type Dewey = Schemas.DeweyDto;

export type WorkV4 = EntityBase & {
  type: Required<Schemas.WorkBaseDto>['type'];
  preferredTitle?: Schemas.WorkTitleInfoDto['preferredTitle'];
  originalTitle?: Schemas.WorkTitleInfoDto['originalTitle'];
  otherTitles?: OtherTitle[];
  languages?: Schemas.WorkBaseDto['languages'];
  dewey?: Schemas.WorkBaseDto['dewey'];
  intellectualLevel?: Schemas.WorkBaseDto['intellectualLevel'];
  literatureType?: Schemas.WorkBaseDto['literatureType'];
  themes?: Schemas.WorkBaseDto['themes'];
  genreAndForm?: Schemas.WorkBaseDto['genreAndForm'];
  subjects?: Schemas.WorkBaseDto['subjects'];

  bibleVersion?: Schemas.WorkBaseDto['bibleVersion'];
  bibleContent?: Schemas.WorkBaseDto['bibleContent'];

  agents?: LinkedAgentMultiRole[];
  links?: LinkedLiterary[];

  educationLevel?: Schemas.WorkBaseDto['educationLevel'];
  educationTargetGroup?: Schemas.WorkBaseDto['educationTargetGroup'];
  grep?: string[];
  generalNote?: string[];
  norwegianForAdultImmigrants?: Schemas.WorkBaseDto['norwegianForAdultImmigrants'];
  useTitleAsMainEntry?: Schemas.WorkBaseDto['useTitleAsMainEntry'];
};

//----- WorkSummary --------------------------------------------------------------------------------

export type WorkSummary = WorkV4 & {
  // Using required to avoid undefined which causes type errors in typed schema
  mainTitles: Required<ManifestationV4>['mainTitles'] | null;
  themaLabels?: WorkV4['themes'];
  themaCodes?: WorkV4['themes'];
};

//----- Expression ---------------------------------------------------------------------------------

export type ExpressionV4 = EntityBase & {
  workId: Required<Schemas.ExpressionBaseDto>['workId'];
  expressionFormat?: Schemas.ExpressionBaseDto['expressionFormat'];
  languages?: Schemas.ExpressionBaseDto['languages'];
  intermediateOriginalLanguage?: Schemas.ExpressionBaseDto['intermediateOriginalLanguage'];
  content?: Schemas.ExpressionBaseDto['content'];

  agents?: LinkedAgentMultiRole[];
};

//----- Manifestation ------------------------------------------------------------------------------

export type ProductOwner = Schemas.ProductOwnerDto;

export type Product = Schemas.ProductDto;
export type DDSStatus = Schemas.DDSEbookDto;

export type AgentsDescription = {
  descriptionContent?: Schemas.AgentsDescriptionDto['content'];
  descriptionLanguage?: Schemas.AgentsDescriptionDto['language'];
  descriptionAgentId?: Schemas.AgentsDescriptionDto['agentId'];
};

export type PhysicalProperties = {
  colour?: string;
  epages?: string;
  scale?: string[];
};

export type SubTitle = {
  value: string;
  parallelTitles: string[];
};

export type MainTitle = {
  value: string;
  subTitles: SubTitle[];
  parallelTitles: string[];
};

export type OtherTitle = {
  type: string;
  value: string;
};

export type ManifestationNotes = {
  generalNote?: Schemas.ManifestationBaseDto['generalNote'];
  editionNote?: Schemas.ManifestationBaseDto['editionNote'];
  editionType?: Schemas.ManifestationBaseDto['editionType'];
  attachment?: Schemas.ManifestationBaseDto['attachment'];
};

export type ManifestationV4 = EntityBase &
  PhysicalProperties &
  AgentsDescription & {
    expressionId: Required<Schemas.ManifestationBaseDto>['expressionId'];
    status: Required<Schemas.ManifestationBaseDto>['status'];
    isbn?: Schemas.ManifestationBaseDto['isbn'];
    ismn?: Schemas.ManifestationBaseDto['ismn'];
    ean?: Schemas.ManifestationBaseDto['ean'];
    basedOnIsbn?: Schemas.ManifestationBaseDto['basedOnIsbn'];
    wrongIsbn?: Schemas.ManifestationBaseDto['wrongIsbn'];
    mainTitles?: MainTitle[];
    otherTitles?: OtherTitle[];
    edition?: Schemas.ManifestationBaseDto['edition'];
    publishedYear?: Schemas.ManifestationBaseDto['publishedYear'];
    pageCount?: Schemas.ManifestationBaseDto['pageCount'];
    contentReceivedDate?: Schemas.ManifestationBaseDto['contentReceivedDate'];
    externalSystemInfo?: Schemas.ManifestationBaseDto['externalSystemInfo'];

    productForm?: Schemas.ManifestationBaseDto['productForm'];
    productFormDetail?: Schemas.ManifestationBaseDto['productFormDetail'];
    productGroup?: Schemas.ManifestationBaseDto['productGroup'];
    bookGroup?: Schemas.ManifestationBaseDto['bookGroup'];
    fileFormatVersion?: Schemas.ManifestationBaseDto['fileFormatVersion'];

    productOwners?: Schemas.ManifestationBaseDto['productOwners'];
    products?: Product[];
    ddsStatus?: DDSStatus[];

    teachingMaterials?: Schemas.ManifestationBaseDto['teachingMaterials'];
    packQuantity?: Schemas.ManifestationBaseDto['packQuantity'];

    imprints?: LinkedAgentMultiRole[];
    agents?: LinkedAgentMultiRole[];
    links?: LinkedLiterary[];

    notes?: ManifestationNotes;
    runtimeSeconds?: string;
    registered?: number;
    publishedYearNotPrintedInBook?: Schemas.ManifestationBaseDto['publishedYearNotPrintedInBook'];
    statementOfResponsibility?: Schemas.ManifestationBaseDto['statementOfResponsibility'];
    autoGenerateStatementOfResponsibility?: Schemas.ManifestationBaseDto['autoGenerateStatementOfResponsibility'];
    unnumberedPages?: Schemas.ManifestationWithExpressionDto['unnumberedPages'];
  };

//----- ChangeRequest ------------------------------------------------------------------------------

// TODO: Fix type
export type ChangeRequestType =
  | Extract<
      Schemas.TaskV2Dto['type'],
      'ML_MANUAL_APPROVAL' | 'METADATA_NEW' | 'METADATA_IMPORT_CHANGE'
    >
  | 'METADATA_MOVED'
  | 'METADATA_COPIED';

/**
 * backend: change request is stored in backend.
 *  Change request from ML, publisher or other external source.
 *
 * local: change request is stored in local storage.
 *  Stored locally during moving manifestation to another work.
 *  Stored values are from the work you moved from.
 *
 * transient: change request is not stored, only in memory.
 *  Used during copying from work/manifestation to current work/manifestation.
 */
export type ChangeRequestStorage = 'backend' | 'local' | 'transient';

/**
 * work: change request is on work level.
 *  Used when copying from one work to another.
 *
 * manifestation: change request is on manifestation level.
 *  Used when moving manifestation to another work.
 *  Plus all changerequests from backend (ML, publisher, etc.).
 */
export type ChangeRequestLevel = 'work' | 'manifestation';

export type ChangeRequest = {
  /** Mapped from taskId: number to id:string in API layer */
  id: string;
  taskType: ChangeRequestType;
  storage: ChangeRequestStorage;
  level: ChangeRequestLevel;
  work: WorkV4;
  expression: ExpressionV4;
  manifestation: ManifestationV4;
  externalSuggestions?: ExternalSuggestions;
  status?: TaskStatus | undefined;
};

export type ChangeRequestData = ChangeRequest[];

export type ChangeRequestApproval = Schemas.ManualApprovalChangeRequestDto;

//----- ML Prediction
export type MLPrediction = Schemas.MLPredictionDto;

//----- Series -------------------------------------------------------------------------------------
export type CollectionTypeDto = Schemas.SeriesWithRelationsDto['seriesType'];
export type CollectionTitleDto = Schemas.SeriesTitleDto;
export type CollectionTitle = CollectionTitleDto;

export type Collection = {
  id: string;
  collectionType: CollectionSubType;
  titles: CollectionTitle[];
  note?: string;
  links: VerifiedLinkedCatalogPost[];
};

export type RelationRoleDto =
  | Schemas.WorkToWorkRelationDto['role']
  | Schemas.AgentRelationDto['role']
  | Schemas.SeriesRelationDto['role']
  | Schemas.AgentCorporationRelationDto['role']
  | Schemas.AgentPersonRelationDto['role'];

export type RelationTargetTypeDto =
  | Schemas.WorkToWorkRelationDto['type']
  | Schemas.AgentRelationDto['type']
  | Schemas.SeriesRelationDto['type'];

//----- NoteData -----------------------------------------------------------------------------------

export type NoteContextType = 'manifestation';

export type NoteDataDto = Omit<Required<Schemas.CommentDto>, 'contextType'> & {
  contextType: NoteContextType;
};

export type NoteData = NoteDataDto & {
  /** client-side timestamp, updated on create and update */
  lastModified: number;
};

//----- LockData -----------------------------------------------------------------------------------

export type LockContextType = 'work';

export type Lock = Required<Schemas.LockDto>;

//----- CodeList, Code ----------------------------------------------------------------------------

export type CombinedCodeListId = 'WORK_AND_EXPRESSION_ROLE_TYPE';

export const CombinedCodeListIds: Readonly<CombinedCodeListId[]> = [
  'WORK_AND_EXPRESSION_ROLE_TYPE',
] as const;

export const PartCodeLists: Readonly<Record<CombinedCodeListId, CodeListId[]>> =
  {
    WORK_AND_EXPRESSION_ROLE_TYPE: ['WORK_ROLE_TYPE', 'EXPRESSION_ROLE_TYPE'],
  };

// Generated code lists from context
export type LocalCodeListId = 'expression_manifestation'; // Manifestations in expression: { code: isbn, value: isbn / format  / år}

export type CodeListId =
  | Required<Schemas.CodelistDto>['id']
  | CombinedCodeListId
  | LocalCodeListId
  | 'manifestation_role_type'
  | 'collection_type'
  | 'work_collection_type'
  | 'product_form_simple_values'
  | 'availability_status'
  | 'task_type'
  | 'media_sub_type'
  | 'WORK_TITLE_TYPE'
  | 'WORK_TYPE';

export type Code = {
  code: Schemas.CodeDto['code'];
  value: Schemas.CodeDto['value'];
  deactivated?: Schemas.CodeDto['deactivated'];
  notes?: string;
  disabled?: boolean;
};

export type CodeList = {
  language: Schemas.CodelistDto['language'];
  id: CodeListId;
  codes: Code[];
};

export type StandardCodeListMap = {[key in CodeListId]?: CodeList};

export type LinkedRoleCodeListMap = {
  [key in LinkedRoleCodeListId]?: LinkedRoleCodeList;
};

export type CodeListMap = StandardCodeListMap & LinkedRoleCodeListMap;

//----- Thesaurus, ThesaurusItem ----------------------------------------------------------------------------

export type ThesaurusNodeType = Required<Schemas.GrepExport>['type'];

export type ThesaurusNodeDto = {
  id: (Schemas.ThemaExport &
    Schemas.ThesaurusExport &
    Schemas.GrepExport)['id'];
  label: (Schemas.ThemaExport &
    Schemas.ThesaurusExport &
    Schemas.GrepExport)['label'];
  code?: Schemas.GrepExport['code'];
  uri?: Schemas.GenreAndFormExport['uri'];
  deactivated?: Schemas.GrepExport['deactivated'];
  type?: ThesaurusNodeType;
  alternativeLabels?: (Schemas.ThemaExport &
    Schemas.ThesaurusExport)['alternativeLabels'];
  note?: (Schemas.ThemaExport & Schemas.ThesaurusExport)['note'];
  definition?: Schemas.GenreAndFormExport['definition'];
  related?: (Schemas.ThemaExport &
    Schemas.ThesaurusExport &
    Schemas.GenreAndFormConcept)['related'];
  children?: (Schemas.ThemaExport &
    Schemas.ThesaurusExport &
    Schemas.GenreAndFormExport)['children'];
};

export type ThesaurusId = 'thema' | 'bokbasen' | 'grep' | 'genreandform';

export type ThesaurusNode<TId = string> = {
  // Unique id to use in tree
  id: TId;
  // Either uri, code or id from API, may have duplicates
  code: string;
  type?: ThesaurusNodeType;
  /* Mapped from !deactivated on dto. Used to show action button */
  selectable?: boolean;
  /* Mapped from deactivated on dto, but set to false if any active children */
  deactivated?: boolean;
  label: string;
  note?: string | null;
  definition?: string | null;
  alternativeLabels?: string[];
  related?: RelatedThesaurusNode[];
  children?: ThesaurusNode[];
};

export type RelatedThesaurusNode = Pick<
  ThesaurusNode,
  'id' | 'code' | 'label' | 'note' | 'deactivated'
>;

export type Thesaurus = ThesaurusNode<ThesaurusId> & {
  language: string;
};

export type ThesaurusValue = string[];

export type ThesaurusesMap = {[id in ThesaurusId]: Thesaurus};

//----- Grep node details -------------------------------------------------------------------------

export type GrepConcept = Schemas.GrepConcept & {
  code: string;
};

export type GrepExport = Schemas.GrepExport;

export type GrepDetailsTextValue = {
  type: 'text' | 'textarea';
  value: string;
};

export type GrepDetailsDateValue = {
  type: 'date';
  value: Date;
};

export type GrepDetailsCodeValue = {
  type: 'code';
  value: string[];
};

export type GrepDetailsValue =
  | GrepDetailsTextValue
  | GrepDetailsDateValue
  | GrepDetailsCodeValue
  | GrepConcept[];

export type GrepDetails = {[key: string]: GrepDetailsValue};

export type GrepExtendedConcept = {
  id: Schemas.GrepExtendedConcept['id'];
  code: Schemas.GrepExtendedConcept['id'];
  type: ThesaurusNodeType;
  deactivated?: Schemas.GrepExtendedConcept['deactivated'];
  label: Schemas.GrepExtendedConcept['label'];
  details: GrepDetails;
};

//----- User ---------------------------------------------------------------------------------------

export type UserDto = {
  id: string;
  email: string;
  name: string;
  picture?: string;
};

export type User = UserDto;

//----- SystemHealth -------------------------------------------------------------------------------

export type ComponentAvailabilityStatus = Schemas.SyncStatusEntryDto['status'];

export type SystemComponentStatus = Required<Schemas.SyncStatusEntryDto>;

export type MessageQueueStatus = {
  topic: Required<Schemas.MessageQueueStatusEntryDto>['topic'];
  length: Required<Schemas.MessageQueueStatusEntryDto>['messagesBehind'];
};

export type SystemHealth = {
  syncComponents: SystemComponentStatus[];
  messageQueues: MessageQueueStatus[];
  services: SystemComponentStatus[];
};

//===== Derived types ==============================================================================

//----- OverviewTask -------------------------------------------------------------------------------
export type OverviewTask = {
  /**
   * Id of user who has locked the task (work), if any
   */
  lockedBy?: string;
  id: number;
  taskTypes: TaskType[];
  manifestationId?: string;
  workId?: string;
  isbn?: string;
  ean?: string;
  mainTitle?: string;
  mainAgent?: string;
  productForm?: string;
  availabilityStatus?: AvailabilityStatus;
  // Should be undefined, not null, when missing
  inSaleDate?: string | undefined;
  publisherSalesDate?: string | undefined;
  contentReceivedDate?: string | undefined;
  publishedYear?: number;
  media?: Media[];
  searchableTaskString: string;
  productOwner?: ProductOwner;
  productOwnerName?: ProductOwner['name'];
  productOwnerCode?: ProductOwner['code'];
  registered?: number;
};

//----- FullManifestation --------------------------------------------------------------------------
/**
 * Union of work, expression and manifestation
 */
export type FullManifestation = Omit<WorkV4, 'id' | 'agents'> & {
  workId: WorkV4['id'];
  workAgents?: WorkV4['agents'];
} & Omit<ExpressionV4, 'id' | 'agents'> & {
    expressionId: ExpressionV4['id'];
    expressionAgents?: ExpressionV4['agents'];
  } & ManifestationV4;

export type WorkMetadata = {
  work: WorkV4;
  expressions: ExpressionV4[];
  manifestations: ManifestationV4[];
};

export type Manifestation = ManifestationV4;
export type ExpressionWithManifestations = ExpressionV4 & {
  manifestations: Manifestation[];
};
export type WorkWithExpressions = WorkV4 & {
  expressions?: ExpressionWithManifestations[];
};

// ----- Search ----------------------------------------------------------------
export type SearchHits = {
  id: string;
  total: number;
  sources: SearchHit[];
  highlights: Highlight[];
};

export type Highlight = string;

export type SearchHit = {
  id: string;
  type: 'work' | 'agent';
  source: WorkSearch | AgentSearch;
};

export type WorkSearch = {
  id: string;
  agents?: WorkSearchAgent[];
  deweys?: string[];
  languages?: string[];
  intellectualLevel?: string[];
  literatureType?: string[];
  originalTitle?: string;
  preferredTitle?: string;
  themas?: string[];
  emneord?: string[];
};

export type WorkSearchAgent =
  | WorkSearchVerifiedAgent
  | WorkSearchUnverifiedAgent;

export type WorkSearchVerifiedAgent = {
  type: 'verified';
  id: string;
  agentType: string;
  name?: string;
  surName?: string;
  roles?: string[];
};

export type WorkSearchUnverifiedAgent = {
  type: 'unverified';
  agentType?: string;
  name?: string;
  surName?: string;
  roles?: string[];
};

export type AgentSearch = {
  id: string;
  agentType?: string;
  nameVariants?: SearchNameVariant[];
  country?: string;
  countryOfBirth?: string;
  gender?: string;
};

export type SearchNameVariant = {
  name?: string;
  surName?: string;
};

// ----- Codelist references ----------------------------------------------------------------

export const CodeListRef = {
  LITERATURE_TYPE: {
    ['Faglitteratur']: '10',
    ['Lærebøker (høyere utd.)']: '11',
    ['Skjønnlitteratur']: '12',
    ['Skolebøker']: '13',
    ['Schoolbooks']: '13', // Shortcut
  },
  DEWEY_SOURCE: {
    ['23/nor']: '15',
    ['3/nor']: '16',
  },
  INTELLECTUAL_LEVEL: {
    ['0-3']: '10',
    ['3-6']: '12',
    ['6-9']: '13',
    ['9-12']: '14',
    ['12-16']: '11',
    ['Voksen']: '15',
  },
  EDUCATION_LEVEL: {
    ['7. trinn']: '7',
    ['8. trinn']: '8',
    ['9. trinn']: '9',
  },
  WORK_ROLE_TYPE: {
    ['Forfatter']: 'A01',
    ['Med']: 'A02', // Deaktivert
    ['Manus av']: 'A03',
  },
  EXPRESSION_FORMAT: {
    TEXT: 'TEXT',
    ILLUSTRATED_TEXT: 'ILLUSTRATED_TEXT',
    AUDIO: 'AUDIO',
  },
  EXPRESSION_ROLE_TYPE: {
    ['Komponist']: 'A06',
    ['Illustrert av']: 'A12',
    ['Fotografier av']: 'A13',
    ['Oversatt av']: 'B06',
    ['Lest av']: 'E07',
    ['Solist (vokal)']: 'E05',
    ['Solist (instrumental)']: 'E06',
  },
  LANGUAGE: {
    ['Nynorsk']: 'nno',
    ['Bokmål']: 'nob',
    ['Svensk']: 'swe',
    ['Flerspråklig']: 'mul',
  },
  PRODUCT_FORM: {
    ['Udefinert']: '00',
    ['Lyd']: 'AA',
    ['Lydkassett']: 'AB',
    ['CD (lyd)']: 'AC',
    ['DAT (lyd)']: 'AD',
    ['Lyddisk']: 'AE',
    ['Lydbånd']: 'AF',
    ['MiniDisc']: 'AG',
    ['Lyd- og data-CD']: 'AH',
    ['DVD (lyd)']: 'AI',
    ['Lydfil']: 'AJ',
    ['MP3-spiller med innhold']: 'AK',
    ['SD minnekort med innnhold']: 'AL',
    ['LP']: 'AM',
    ['Nedlastbar og nettbasert (online) lydfil']: 'AN',
    ['Nettbasert (online) lydfil']: 'AO',
    ['Annet lydformat']: 'AZ',
    ['Bok']: 'BA',
    ['Innbundet']: 'BB',
    ['Heftet']: 'BC',
    ['Løse ark']: 'BD',
    ['Spiralbundet']: 'BE',
    ['Stiftet']: 'BF',
    ['Praktinnbinding']: 'BG',
    ['Pappbok']: 'BH',
    ['Tekstilbok']: 'BI',
    ['Badebok']: 'BJ',
    ['Eksperimentell innbinding']: 'BK',
    ['Fleksibind']: 'BL',
    ['Tavlebok']: 'BM',
    ['Utdrag, del av større verk']: 'BN',
    ['Leporello (brettet)']: 'BO',
    ['Skumgummibok']: 'BP',
    ['Annet bokformat']: 'BZ',
    ['Kart, uspesifisert']: 'CA',
    ['Kart, falset']: 'CB',
    ['Kart, plano']: 'CC',
    ['Kart, rullet']: 'CD',
    ['Globus']: 'CE',
    ['Annet kartografisk format']: 'CZ',
    ['Digitalt format (på fysisk bærer)']: 'DA',
    ['CD-I']: 'DC',
    ['Diskett']: 'DF',
    ['Secure Digital (SD) (minnekort)']: 'DJ',
    ['Compact Flash (minnekort)']: 'DK',
    ['Memory Stick (minnekort)']: 'DL',
    ['Tosidig CD/DVD']: 'DN',
    ['Annet digitalt format (på fysisk bærer)']: 'DZ',
    ['Digitalt format (leveres elektronisk)']: 'EA',
    ['Digitalt format, nedlastbart og nettbasert (online)']: 'EB',
    ['Digitalt format, nettbasert (online)']: 'EC',
    ['Digitalt format, nedlastbart (download)']: 'ED',
    ['E-bok']: 'ED', // Shortcut
    ['Ebok']: 'ED', // Shortcut
    ['Film eller lysark']: 'FA',
    ['Lysbilder']: 'FC',
    ['Lysark']: 'FD',
    ['Filmremse']: 'FE',
    ['Film']: 'FF',
    ['Annet film- eller lysarkformat']: 'FZ',
    ['Digitalt produkt, lisens']: 'LA',
    ['Digitalt produkt, lisensnøkkel']: 'LB',
    ['Digitalt produkt, lisenskode']: 'LC',
    ['Mikroform']: 'MA',
    ['Mikrofiche']: 'MB',
    ['Mikrofilm']: 'MC',
    ['Andre mikroformat']: 'MZ',
    ['Diverse trykk']: 'PA',
    ['Adressebok']: 'PB',
    ['Kalender']: 'PC',
    ['Kort']: 'PD',
    ['Kopieringsoriginaler']: 'PE',
    ['Dagbok']: 'PF',
    ['Frise']: 'PG',
    ['Eske/samlesett']: 'PH',
    ['Notetrykk']: 'PI',
    ['Postkort']: 'PJ',
    ['Plakat']: 'PK',
    ['Minnebok']: 'PL',
    ['Mappe eller omslag']: 'PM',
    ['Bilder eller fotografier']: 'PN',
    ['Plansje']: 'PO',
    ['Klistremerker']: 'PP',
    ['Ark']: 'PQ',
    ['Notisbok / skrivebok']: 'PR',
    ['Syvende sans']: 'PS',
    ['Bokmerke']: 'PT',
    ['Brosjyre']: 'PU',
    ['Bokeiermerke']: 'PV',
    ['Andre trykte artikler']: 'PZ',
    ['Produkt bestående av flere enkeltprodukter']: 'SA',
    ['Produkt bestående av flere enkeltprodukter, i eske']: 'SB',
    ['Produkt bestående av flere enkeltprodukter, i kassett']: 'SC',
    ['Produkt bestående av flere enkeltprodukter, plastpakket']: 'SD',
    ['Produkt bestående av flere enkeltprodukter, ikke emballert']: 'SE',
    ['Produkt bestående av flere enkeltprodukter, del(er) er lagt ved']: 'SF',
    ['Video']: 'VA',
    ['Videodisk']: 'VF',
    ['DVD video']: 'VI',
    ['VHS video']: 'VJ',
    ['Betamax video']: 'VK',
    ['HD DVD']: 'VN',
    ['Blu-ray']: 'VO',
    ['UMD Video']: 'VP',
    ['CBHD']: 'VQ',
    ['Andre videoformater']: 'VZ',
    ['Markedsføringsmateriell']: 'XA',
    ['Tilbudskasse – tom']: 'XB',
    ['Tilbudskasse – fylt']: 'XC',
    ['Diskeske – tom']: 'XD',
    ['Diskeske- fylt']: 'XE',
    ['Reklameplakat']: 'XF',
    ['Hyllemarkør']: 'XG',
    ['Vindusmateriell']: 'XH',
    ['Gulvstativ (uten innhold)']: 'XJ',
    ['Forstørret omslag']: 'XK',
    ['Plastpakket']: 'XL',
    ['Pakke (i eske)']: 'XM',
    ['Pakke (emballasje er ikke spesifisert)']: 'XN',
    ['Gulvstativ (med innhold)']: 'XO',
    ['Annet reklamemateriall - inkludert produkter']: 'XY',
    ['Annet reklamemateriell']: 'XZ',
    ['Varer, uspesifisert']: 'ZA',
    ['Dukke eller figur']: 'ZB',
    ['Myk leke']: 'ZC',
    ['Leke']: 'ZD',
    ['Spill']: 'ZE',
    ['T-skjorte']: 'ZF',
    ['Lesebrett']: 'ZG',
    ['Nettbrett']: 'ZH',
    ['Lydbokspiller']: 'ZI',
    ['Puslespill']: 'ZJ',
    ['Kopp']: 'ZK',
    ['Handlenett']: 'ZL',
    ['Servise']: 'ZM',
    ['Paraply']: 'ZN',
    ['Maling, fargestifter, fargeblyanter']: 'ZO',
    ['Andre leker/spill/tilbehør']: 'ZX',
    ['Andre klær']: 'ZY',
    ['Andre varer']: 'ZZ',

    /** Digitalt format, nedlastbart og nettbasert (online) */
    ['EB']: 'EB',
    /** "Digitalt format, nedlastbart (download)" */
    ['ED']: 'ED',
  },
  PRODUCT_FORM_DETAIL: {
    ['EPUB']: 'E101',
    ['OEB']: 'E102',
    ['MP3-format']: 'A103',
  },
  PRODUCT_GROUP: {
    'Grunnskole, diverse': '11000',
    'Grunnskole, diverse ebøker': '11001',
    'Grunnskole, diverse lydbøker': '11002',
    Astronomi: '27015',
    'Astronomi ebøker': '27016',
    'Astronomi lydbøker': '27017',
    Kryssordbøker: '39585',
    'Kryssordbøker ebøker': '39586',
    'Kryssordbøker lydbøker': '39587',
    Tidsskrifter: '85030',
  },
  BOOK_GROUP: {
    'Annet. Sammensatte produkter': '838',
  },
  collection_type: {
    Serie: Concept.series,
    Læreverk: Concept.educationalSeries,
    'Annen tilknytning': Concept.otherConnection,
    Forlagsserie: Concept.publisherSeries,
  },
  TEACHING_MATERIALS: {
    Lettlest: '10',
    Tavleressurser: '22',
  },
  WORK_TYPE: {
    Bok: 'BOOK',
    Vare: 'MERCHANDISE',
  },
} as const;

// Assigned to typed const to verify valid codelist ids
type ActualKeys = keyof typeof CodeListRef;
const ak: ActualKeys = 'EXPRESSION_FORMAT' as ActualKeys;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const vk: CodeListId = ak;
