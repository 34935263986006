import {CodeList} from 'api/types';
import {ManifestationImportProps} from 'services/data/metadata/types';
import {getRestrictedAndSortedProductGroupCodelist} from './getRestrictedAndSortedProductGroupCodelist';
import {getRestrictedProductFormCodelistByWorkType} from './getRestrictedProductFormCodelistByWorkType';
import {getRestrictedProductFormDetailCodelist} from './getRestrictedProductFormDetailCodelist';

export const getRestrictedManifestationCodelist = (
  importProps: ManifestationImportProps,
  outerCodelist: CodeList,
): CodeList => {
  if (importProps === undefined) {
    return outerCodelist;
  }

  if (outerCodelist.id === 'PRODUCT_FORM_DETAIL') {
    return getRestrictedProductFormDetailCodelist(importProps, outerCodelist);
  }

  if (outerCodelist.id === 'PRODUCT_GROUP') {
    return getRestrictedAndSortedProductGroupCodelist(
      importProps,
      outerCodelist,
    );
  }

  if (outerCodelist.id === 'PRODUCT_FORM') {
    return getRestrictedProductFormCodelistByWorkType(
      importProps,
      outerCodelist,
    );
  }

  return outerCodelist;
};
