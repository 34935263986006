import {SxProps} from '@mui/material';
import {ManifestationStatus as ManifestationStatusType} from 'api/types';
import {px2rem} from 'theme';
import {
  useManifestationStatusColor,
  useManifestationStatusLabel,
} from 'services/utils';
import {Layout, Text} from 'components';

type Props = {
  status: ManifestationStatusType;
};

const sx: SxProps = {
  height: px2rem(24),
  px: px2rem(12),
  borderRadius: 1,
};

export const ManifestationStatus: React.FC<Props> = ({status}) => {
  const label = useManifestationStatusLabel(status);
  const color = useManifestationStatusColor(status);

  return (
    <Layout bgcolor={color} adjustCenter sx={sx}>
      <Text variant="body2">{label}</Text>
    </Layout>
  );
};
