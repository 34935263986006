import React, {useCallback, useMemo} from 'react';
import {Stack} from '@mui/material';
import {assert} from 'assert-ts';
import {CategoryNodesWithAncestors} from 'services/thesaurus/types';
import {groupAndOrderByCategory} from 'services/thesaurus/functions/groupAndOrderByCategory';
import {ThesaurusValuesProps} from './types';
import {ThesaurusValueCategory} from './ThesaurusValueCategory';

export const ThesaurusValuesByCategory: React.FC<ThesaurusValuesProps> = ({
  thesaurusValue,
  thesaurus,
  readonly,
  showCode,
  highlight = [],
  highlightType,
  onDelete,
  onSelect,
  onMove,
}) => {
  const prCategory = useMemo((): CategoryNodesWithAncestors[] => {
    return groupAndOrderByCategory(thesaurus, thesaurusValue);
  }, [thesaurus, thesaurusValue]);

  const handleMoveInsideCategory = useCallback(
    (
      category: CategoryNodesWithAncestors,
      oldIndex: number,
      newIndex: number,
    ) => {
      if (onMove) {
        const categoryIndex = prCategory.findIndex(
          c => c.categoryNode.id === category.categoryNode.id,
        );
        assert(categoryIndex >= 0, 'ThesaurusValues: categoryIndex >= 0');
        const categoryOffset = prCategory.reduce<number>((acc, c, i) => {
          if (i < categoryIndex) {
            return acc + c.nodes.length;
          }
          return acc;
        }, 0);
        onMove(oldIndex + categoryOffset, newIndex + categoryOffset);
      }
    },
    [onMove, prCategory],
  );

  // useLogValue('thesaurusValue', thesaurusValue);

  return thesaurusValue ? (
    <Stack spacing={0}>
      {prCategory.map(c => (
        <ThesaurusValueCategory
          key={c.categoryNode.id}
          category={c.categoryNode}
          nodes={c.nodes}
          readonly={readonly}
          showCode={showCode}
          highlight={highlight}
          highlightType={highlightType}
          onDelete={onDelete}
          onSelect={onSelect}
          onMove={(oldIndex, newIndex) =>
            handleMoveInsideCategory(c, oldIndex, newIndex)
          }
        />
      ))}
    </Stack>
  ) : null;
};
