import {PreviewConfiguration} from 'schema/types';
import {MainTitles} from 'schemas/renderers';
import {workPreviewConfig} from './workPreviewConfig';

export const workSummaryPreviewConfig: PreviewConfiguration = {
  ...workPreviewConfig,
  partValueRenderers: {
    ...workPreviewConfig.partValueRenderers,
    mainTitles: MainTitles,
  },
};
