import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import {ManifestationV4} from 'api/types';
import {metadataEditManifestationSelectorById} from '../selectors';
import {useCreateSelectorById} from './useCreateSelectorById';

export const useAssertManifestationEdit = (
  manifestationId: string,
): ManifestationV4 => {
  const selector = useCreateSelectorById(
    manifestationId,
    metadataEditManifestationSelectorById,
  );
  const manifestation = useSelector(selector);
  if (!manifestation) {
    assert(false, 'useAsserManifestationEdit: no manifestation', {
      manifestationId,
    });
  }

  return manifestation;
};
