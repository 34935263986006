import React, {useMemo} from 'react';
import {SxProps, Theme} from '@mui/material';
import {FCWithChildren} from 'types';
import {CustomTextProps} from 'schema/types';
import {ColorPalette} from 'theme';
import {Text} from 'components';
import {usePreviewConfigurationContext} from '../contexts';

type Props = {
  onClick?: () => void;
};

const defaultTextProps: Omit<CustomTextProps, 'variant'> = {
  sx: {
    color: ColorPalette.textPrimary,
  },
};

const DefaultTextValue: FCWithChildren<Props> = ({onClick, children}) =>
  onClick ? (
    <Text onClick={onClick} variant="link" {...defaultTextProps}>
      {children}
    </Text>
  ) : (
    <Text variant="body1" {...defaultTextProps}>
      {children}
    </Text>
  );

export const useTextValue = (): FCWithChildren<Props> => {
  const configuration = usePreviewConfigurationContext();
  return useMemo(() => {
    const {sx, variant, ...textProps} = configuration.text?.value ?? {};
    return textProps
      ? ((({onClick, children}) =>
          onClick ? (
            <Text onClick={onClick} variant={'link'} {...textProps} sx={sx}>
              {children}
            </Text>
          ) : (
            <Text
              {...textProps}
              component={'span'}
              variant={variant ?? 'body1'}
              sx={sx}>
              {children}
            </Text>
          )) as FCWithChildren<Props>)
      : DefaultTextValue;
  }, [configuration.text?.value]);
};

export const useStaticTextValue = (
  text: string,
  sx?: SxProps<Theme>,
): React.FC => {
  const configuration = usePreviewConfigurationContext();
  return useMemo(() => {
    const {
      sx: valueSx,
      variant = 'body1',
      ...textProps
    } = configuration.text?.value ?? {};
    const staticSx = (valueSx && sx ? {...valueSx, ...sx} : sx ?? valueSx) as
      | SxProps<Theme>
      | undefined;
    return () => (
      <Text variant={variant} {...textProps} sx={staticSx}>
        {text}
      </Text>
    );
  }, [configuration.text?.value, sx, text]);
};
