import {assert} from 'assert-ts';
import {Thesaurus, ThesaurusNode} from 'api/types';

export const assertThesaurusNode = (
  id: string,
  thesaurus: Thesaurus,
): ThesaurusNode => {
  const item = findItem(id, thesaurus as unknown as ThesaurusNode);

  if (
    assert.soft(item, 'getCodeSortValue: code expected', {
      id,
      thesaurusId: thesaurus.id,
      language: thesaurus.language,
    })
  ) {
    return item;
  }

  return {id, code: id, label: id};
};

const findItem = (
  id: string,
  node: ThesaurusNode,
): ThesaurusNode | undefined => {
  if (node.id === id) {
    return node;
  }

  if (node.children) {
    for (const child of node.children) {
      const item = findItem(id, child);
      if (item) {
        return item;
      }
    }
  }

  return undefined;
};
