import React, {useMemo} from 'react';
import InputLabel, {InputLabelProps} from '@mui/material/InputLabel';
import {ColorPalette} from 'theme';

type Props = InputLabelProps & {
  label: string;
};

export const GroupLabel: React.FC<Props> = ({label, sx, ...props}) => {
  const style = useMemo(() => ({...sx, color: ColorPalette.burgundy}), [sx]);
  return (
    <InputLabel {...props} sx={style}>
      {label}
    </InputLabel>
  );
};
