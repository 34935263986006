import isEqual from 'lodash/isEqual';
import {WorkV4} from 'api/types';
import {MetadataExports} from 'services/data/metadata/types';
import {getWorkExportProps} from './getWorkExportProps';

export const updateWorkExportProps = (
  work: WorkV4,
  currentExports: MetadataExports | undefined,
): MetadataExports => {
  const newProps = getWorkExportProps(work);
  return currentExports
    ? isEqual(currentExports.work, newProps)
      ? currentExports
      : {
          ...currentExports,
          work: newProps,
        }
    : {
        work: newProps,
        expressions: {},
        manifestations: {},
      };
};
