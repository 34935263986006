import {OverviewTask} from 'api/types';
import {TypedSchema} from 'schemaDefinition/types';

export const taskSchema: TypedSchema<OverviewTask> = {
  name: 'task',
  key: 'task',
  parts: [
    {
      type: 'text',
      name: 'lockedBy',
    },
    {
      type: 'text',
      name: 'id',
    },
    {
      type: 'text', //codelist
      name: 'taskTypes',
      //codelistId: 'task_type',
    },
    {
      type: 'text',
      name: 'manifestationId',
    },
    {
      type: 'text',
      name: 'workId',
    },
    {
      type: 'text',
      name: 'isbn',
      labelKey: 'isbn',
    },
    {
      type: 'text',
      name: 'ean',
      labelKey: 'ean',
    },
    {
      type: 'text',
      name: 'mainTitle',
      labelKey: 'mainTitle',
    },
    {
      type: 'text',
      name: 'mainAgent',
      labelKey: 'mainAgent',
    },
    {
      type: 'text',
      name: 'productOwner',
    },
    {
      type: 'text',
      name: 'productOwnerName',
    },
    {
      type: 'text',
      name: 'productOwnerCode',
    },
    {
      type: 'codelist',
      name: 'productForm',
      codelistId: 'PRODUCT_FORM',
      showCode: true,
    },
    {
      type: 'date',
      name: 'publisherSalesDate',
      labelKey: 'publisherSalesDate',
    },
    {
      type: 'date',
      name: 'inSaleDate',
      labelKey: 'inSaleDate',
    },
    {
      type: 'codelist',
      name: 'availabilityStatus',
      codelistId: 'availability_status',
    },
    {
      type: 'date',
      name: 'contentReceivedDate',
      labelKey: 'contentReceivedDate',
    },
    {
      type: 'codelist',
      name: 'media',
      codelistId: 'media_sub_type',
      cardinality: 'multiple',
    },
    {
      type: 'text',
      name: 'searchableTaskString',
    },
    {
      type: 'date',
      name: 'registered',
    },
  ],
};
