import {Metadata} from 'services/data/metadata/types';
import {ManifestationV4} from 'api';
import {MetadataEditAction} from 'services/data/metadata/metadataEditActionTypes';
import {assertManifestation} from './assertManifestation';
import {partialReplace} from './partialReplace';

export const replaceManifestationBase = (
  newManifestation: ManifestationV4,
  currentMetadata: Metadata,
  excludeProps?: (keyof ManifestationV4)[],
  action?: MetadataEditAction,
  qualifier?: 'saved' | 'edit',
): Metadata => {
  const currentManifestation = assertManifestation(
    newManifestation.id,
    currentMetadata,
    action,
    qualifier,
  );
  const adjustedManifestation = partialReplace(
    newManifestation,
    currentManifestation,
    excludeProps,
  );

  return {
    ...currentMetadata,
    manifestations: currentMetadata.manifestations.map(m =>
      m.id === newManifestation.id ? adjustedManifestation : m,
    ),
  };
};
