import React from 'react';
import {ReadonlyExpressionCardProps} from 'widgets/metadata/readonlyExpressionCard/types';
import {useSavedExpression} from 'services/data';
import {ReadonlyExpressionCard} from 'widgets/metadata/readonlyExpressionCard/ReadonlyExpressionCard';

type Props = Omit<ReadonlyExpressionCardProps, 'expression'> & {
  expressionId: string;
};

export const ReadonlyExpression: React.FC<Props> = ({
  expressionId,
  ...rest
}) => {
  const expression = useSavedExpression(expressionId);

  return <ReadonlyExpressionCard expression={expression} {...rest} />;
};
