import {ChangeRequest, GetTokens} from './types';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpGet, httpPost} from './http/ebba';
import {loadAllChangeRequestsForWork} from './localStorage';
import {mapManualApprovalChangeRequestsDto} from './mappers/mapChangeRequestForImportApprovalDto';

export const postChangeRequestClose = (
  id: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<void> => {
  return exceptionToPromiseReject<void>(() => {
    return httpPost<void>({
      subdir: 'manualapproval/changerequest/{refId}/resolve',
      subdirParams: {refId: id},
      mock: !!mock,
      getTokens,
    });
  });
};

export const getChangeRequestsForWork = (
  workId: string,
  eans: string[],
  getTokens: GetTokens,
  mock?: boolean,
): Promise<ChangeRequest[]> => {
  return exceptionToPromiseReject(() => {
    return getChangeRequestApprovals(workId, eans, getTokens, mock).then(
      backendCRs => {
        const localCRs = loadAllChangeRequestsForWork(workId);
        return [...backendCRs, ...localCRs];
      },
    );
  });
};

export const getChangeRequestApprovals = (
  workId: string,
  eans: string[],
  getTokens: GetTokens,
  mock?: boolean,
): Promise<ChangeRequest[]> => {
  return httpGet<Schemas.ManualApprovalChangeRequestsDto>({
    subdir: 'manualapproval/changerequest/ean?ean={eans}',
    subdirParams: {eans: eans.join(',')},
    mock: !!mock,
    getTokens,
  }).then(crs => mapManualApprovalChangeRequestsDto(workId, crs));
};
