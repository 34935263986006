import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Auth} from '@aws-amplify/auth';
import {NoProps} from '../../../types';
import {useAuthState} from '../../../services/auth/hooks/useAuthState';
import {useCallbackParams} from './hooks/useCallbackParams';

type Props = NoProps;

export const LoginCallback: React.FC<Props> = () => {
  const {code, state, error} = useCallbackParams();

  const navigate = useNavigate();

  const {login, loginFailed} = useAuthState();

  useEffect(() => {
    if (code) {
      Auth.currentSession().then(session => {
        login(session.getIdToken().getJwtToken());
        navigate('/', {replace: true});
      });
    } else {
      loginFailed(typeof error === 'string' ? error : undefined);
    }
  }, [code, state, error, navigate, login, loginFailed]);

  return null;
};
