import {Product} from 'api/types';

export const countProductsData = (
  products: Product[] | undefined,
): number | undefined => {
  return products?.reduce((acc, product) => {
    return (
      acc +
      (product.owner ? 1 : 0) +
      (product.distributor ? 1 : 0) +
      (product.status ? 1 : 0) +
      (product.hidden !== undefined ? 1 : 0) +
      (product.comingDate ? 1 : 0) +
      (product.inSaleDate ? 1 : 0) +
      (product.publisherSalesDate ? 1 : 0) +
      (product.measures?.length ? product.measures.length : 0) +
      (product.descriptions?.length ? product.descriptions.length : 0)
    );
  }, 0);
};
