import {Concept} from 'types';
import {Metadata, MetadataStatuses} from 'services/data/metadata/types';
import {getAggregateStatusFromList} from './getAggregateStatusFromList';
import {getAggregateStatusFromMap} from './getAggregateStatusFromMap';

export const aggregateStatuses = (data: Metadata): MetadataStatuses => {
  const statuses: MetadataStatuses = {};

  data.expressions.forEach(e => {
    const manifestations = data.manifestations.filter(
      m => m.expressionId === e.id,
    );
    const expressionStatus = getAggregateStatusFromList(
      manifestations,
      'status',
    );
    statuses[e.id] = {
      type: Concept.expression,
      status: expressionStatus ?? 'COMPLETED',
    };
  });

  statuses[data.work.id] = {
    type: Concept.work,
    status: getAggregateStatusFromMap(statuses) ?? 'COMPLETED',
  };

  data.manifestations.reduce<MetadataStatuses>((acc, m) => {
    acc[m.id] = {
      type: Concept.manifestation,
      status: m.status,
    };
    return acc;
  }, statuses);

  return statuses;
};
