import React from 'react';
import {Text} from 'components/text';

type Props = {
  value: string;
};

export const FieldValue: React.FC<Props> = ({value}) => {
  return <Text variant="body1">{value}</Text>;
};
