import {assert} from 'assert-ts';
import {
  LinkedAgentMultiRole,
  UnverifiedLinkedAgentMultiRole,
  VerifiedLinkedAgentMultiRole,
} from 'schemaDefinition/types';
import {
  AgentName,
  AgentTypeDto,
  ExpressionWithManifestations,
  Manifestation,
  ManifestationV4,
  WorkWithExpressions,
} from '../types';
import {CatalogSchemas} from '../avro-catalog.generated';
import {SearchResult, SearchResultDto, SearchWorkDto} from '../searchTypes';
import {mapAgentTypeDto} from './mapAgentTypeDto';
import {mapHighlight} from './mapHighlights';
import {mapWorkRelationsCatalogDto} from './mapRelationsDto';
import {sortByOrder} from './sortByOrder';

export const mapSearchWorkResult = (
  dto: SearchResultDto<SearchWorkDto>,
): SearchResult<WorkWithExpressions> => {
  return {
    hits: dto?.hits.hits.map(h => mapSearchWork(h._source)),
    highlights: dto?.hits.hits.map(h => mapHighlight(h.highlight)).flat(1),
    total: dto.hits.total.value,
  };
};

const mapSearchWork = (dto: SearchWorkDto): WorkWithExpressions => {
  const {
    id,
    created,
    modified,
    agents,
    themes,
    expressions,
    relations,
    type,
    ...rest
  } = dto;
  const workId = assert(id, 'mapSearchWork: expected id', dto);

  const links = relations ? mapWorkRelationsCatalogDto(relations) : [];

  return {
    id: workId,
    created: Number(created),
    modified: Number(modified),
    type: type || 'BOOK',
    agents: agents ? agents.map(mapAgentRole) : [],
    themes: (themes || []).map(mapThema),
    expressions: (expressions || []).map(e => mapExpression(workId, e)),
    links,
    ...rest,
  };
};

const mapExpression = (
  workId: string,
  dto: CatalogSchemas.ExpressionDto,
): ExpressionWithManifestations => {
  const {
    id,
    created,
    modified,
    agents,
    expressionFormat,
    manifestations,
    ...rest
  } = dto;
  const expressionId = assert(id, 'mapExpression: expected id', dto);

  return {
    workId,
    id: expressionId,
    created: Number(created),
    modified: Number(modified),
    agents: agents ? agents.map(mapAgentRole) : [],
    expressionFormat: mapExpressionFormat(expressionFormat),
    manifestations: (manifestations || []).map(m =>
      mapManifestation(
        assert(dto.id, 'mapSearchWork: expected expression.id', dto),
        m,
      ),
    ),
    ...rest,
  };
};

const mapExpressionFormat = (
  dto: string | undefined,
): ExpressionWithManifestations['expressionFormat'] | undefined => {
  if (typeof dto === 'string') {
    return dto as ExpressionWithManifestations['expressionFormat'];
  }

  return undefined;
};

const mapManifestation = (
  expressionId: string,
  dto: CatalogSchemas.ManifestationDto,
): Manifestation => {
  const {id, created, modified, imprints, status, titleInfo, ...rest} = dto;
  assert(id, 'mapManifestation: expected id', dto);
  assert(status, 'mapManifestation: expected status', dto);

  return {
    expressionId,
    id: id as string,
    created: Number(created),
    modified: Number(modified),
    imprints: (imprints || []).map(mapImprint),
    status: status as Manifestation['status'],
    ...mapTitleInfo(titleInfo),
    ...rest,
  };
};

export const mapImprint = (
  dto: CatalogSchemas.ImprintDto,
): LinkedAgentMultiRole => {
  return mapAgentRole({
    ...dto.agentName,
    agentType: 'PUBLISHER',
    roles: ['publisher'],
  });
};

export const mapTitleInfo = (
  titleInfo: CatalogSchemas.TitleInfoDto | undefined | null,
): {
  mainTitles: ManifestationV4['mainTitles'];
  otherTitles: ManifestationV4['otherTitles'];
} => {
  const {mainTitles = [], otherTitles = []} = titleInfo || {};

  return {
    mainTitles: sortByOrder(mainTitles ?? []).map(({order: _, ...mT}) => ({
      ...mT,
      parallelTitles: sortByOrder(mT.parallelTitles ?? []).map(pt => pt.value),
      subTitles: sortByOrder(mT.subTitles ?? []).map(({order: _, ...sT}) => ({
        ...sT,
        parallelTitles: sortByOrder(sT.parallelTitles ?? []).map(
          pt => pt.value,
        ),
      })),
    })),
    otherTitles: sortByOrder(otherTitles ?? []).map(({order: _, ...oT}) => oT),
  };
};

const mapThema = (dto: CatalogSchemas.ThemaDto): string => {
  return dto.code;
};

const mapAgentRole = (
  dto: CatalogSchemas.RoleRelationDto,
): LinkedAgentMultiRole => {
  const {roles, agentType, agentName, ...rest} = dto;
  const variant = mapAgentName(agentName);

  if (dto.id) {
    return {
      roles: roles || [],
      link: {
        linkStatus: 'verified',
        entityId: variant.id,
        type: mapAgentTypeDto((agentType || 'PERSON') as AgentTypeDto),
        agentName: variant,
        name: variant.name,
        ...rest,
      },
    } as VerifiedLinkedAgentMultiRole;
  }

  return {
    roles: dto.roles,
    link: {
      linkStatus: 'unverified',
      name: variant.name,
    },
  } as UnverifiedLinkedAgentMultiRole;
};

const mapAgentName = (
  dto: CatalogSchemas.RoleRelationDto['agentName'],
): AgentName => {
  if (!dto) {
    return {};
  }

  if (dto.AgentNameDto) {
    const {name, surName, ...rest} = dto.AgentNameDto;
    return {
      name: `${surName ? surName + ', ' : ''}${name}`,
      ...rest,
    };
  }

  return {
    name: dto.string ?? '',
  };
};
