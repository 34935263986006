import React, {FocusEventHandler, MouseEventHandler} from 'react';
import {IconButton} from '@mui/material';
import {ButtonBaseTypeMap} from '@mui/material/ButtonBase/ButtonBase';
import {IconButtonTypeMap} from '@mui/material/IconButton/IconButton';
import {IconName} from 'components/icons/types';
import {FieldError} from 'schemaDefinition/types';
import {Icon} from '../icons';
import {FlexBox} from '../layout';
import {Text} from '../text';

type Props = {
  icon?: IconName;
  /** Default left */
  iconPosition?: 'left' | 'right';
  label?: string;
  id?: string;
  dataCy?: string;
  error?: FieldError;
  onBlur?: FocusEventHandler;
  color?: IconButtonTypeMap['props']['color'];
  onClick?: MouseEventHandler;
  iconButtonTouchRippleProps?: ButtonBaseTypeMap['props']['TouchRippleProps'];
};

const sxTitleWithIconLeft = {
  pl: 0,
};

const sxTitleWithIconRight = {
  pr: 0,
};

export const ListActionButton: React.FC<Props> = ({
  icon,
  iconPosition = 'left',
  label,
  id,
  dataCy,
  error,
  onBlur,
  onClick,
  color = 'primary',
  iconButtonTouchRippleProps,
}) => {
  // Only in case of empty list and error
  const resolvedColor = !error ? color : error === true ? 'error' : 'warning';
  const resolvedKind = !error
    ? undefined
    : error === true
    ? 'error'
    : 'warning';

  return (
    <FlexBox center left horizontal onBlur={onBlur}>
      {icon && iconPosition === 'left' ? (
        <IconButton
          id={id}
          color={resolvedColor}
          size="small"
          onClick={onClick}>
          <Icon icon={icon} />
        </IconButton>
      ) : null}
      {label ? (
        <IconButton
          id={id}
          tabIndex={-1}
          color={resolvedColor}
          size="small"
          sx={
            icon
              ? iconPosition === 'left'
                ? sxTitleWithIconLeft
                : sxTitleWithIconRight
              : undefined
          }
          disableRipple
          TouchRippleProps={iconButtonTouchRippleProps}
          onClick={onClick}>
          <Text variant="button" kind={resolvedKind} data-cy={dataCy}>
            {label}
          </Text>
        </IconButton>
      ) : null}
      {icon && iconPosition === 'right' ? (
        <IconButton
          id={id}
          color={resolvedColor}
          size="small"
          onClick={onClick}>
          <Icon icon={icon} />
        </IconButton>
      ) : null}
    </FlexBox>
  );
};
