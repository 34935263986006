import {GridSlotsComponentsProps} from '@mui/x-data-grid-pro';

export const filterPanelProps: GridSlotsComponentsProps['filterPanel'] = {
  columnsSort: 'asc',
  filterFormProps: {
    // Customize inputs by passing props
    logicOperatorInputProps: {
      variant: 'outlined',
      size: 'small',
    },
    columnInputProps: {
      variant: 'outlined',
      size: 'small',
      sx: {mt: 'auto'},
    },
    operatorInputProps: {
      variant: 'outlined',
      size: 'small',
      sx: {mt: 'auto'},
    },
    valueInputProps: {
      InputComponentProps: {
        variant: 'outlined',
        size: 'small',
      },
    },
    deleteIconProps: {},
  },
  sx: {
    '& .MuiDataGrid-filterForm': {p: 2, pb: 1},
    '& .MuiDataGrid-filterFormLogicOperatorInput': {mr: 2},
    '& .MuiDataGrid-filterFormColumnInput': {mr: 2, width: 200},
    '& .MuiDataGrid-filterFormOperatorInput': {mr: 2, width: 150},
    '& .MuiDataGrid-filterFormValueInput': {width: 300},
    '& .MuiDataGrid-filterForm label': {display: 'none'},
  },
};
