import {AndCondition, AndConditionV0, Valx} from '../types';
import {EvaluationArgs} from './types';
import {evaluateCondition} from './evaluateCondition';

export const evaluateAndCondition = (
  condition: AndConditionV0<Valx> | AndCondition<Valx>,
  args: EvaluationArgs,
): boolean => {
  const arg =
    (condition as AndCondition<Valx>).$and ??
    (condition as AndConditionV0<Valx>).arg;

  return arg.every(cond => evaluateCondition(cond, args));
};
