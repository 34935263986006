import {Metadata, MetadataEditState} from 'services/data/metadata/types';
import {ChangeRequest} from 'api';

export const addNewChangeRequestFromCopy = (
  newChangeRequest: ChangeRequest,
  state: MetadataEditState,
): MetadataEditState => {
  const {metadata} = state;
  if (!metadata) {
    return state;
  }

  const newMetadata: Metadata = {
    ...metadata,
    changeRequests: [...(metadata.changeRequests ?? []), newChangeRequest],
  };
  return {
    ...state,
    metadata: newMetadata,
  };
};
