import {AgentSubType, Concept} from 'types';
import {DataValue} from 'schemaDefinition/types';
import {assertAsNameVariantValue} from 'schemaDefinition/functions/assertAsNameVariantValue';
import {Builder, wrap} from 'services/utils/string';
import {formatNullValue} from './formatNullValue';

export const formatNameVariantValue = (
  value: DataValue,
  agentType: AgentSubType,
  excludeId: 'excludeId' | undefined = undefined,
): string => {
  const nameVariant = assertAsNameVariantValue(value);
  if (!nameVariant) {
    return formatNullValue();
  }

  const id = !excludeId
    ? wrap(
        '(',
        nameVariant.id && nameVariant.id.length > 4
          ? `...${nameVariant.id.slice(-4)}`
          : nameVariant.id,
        ')',
      )
    : undefined;

  switch (agentType) {
    case Concept.person: {
      const {surName, name, regnalNumber, addition} = nameVariant;
      return (
        new Builder(surName)
          .add(', ', name)
          .add(' ', regnalNumber)
          .add(' ', addition)
          .add(' ', id).value ?? formatNullValue()
      );
    }
    case Concept.publisher: {
      const {name} = nameVariant;
      return new Builder(name).add(' ', id).value ?? formatNullValue();
    }
    case Concept.corporation: {
      const {name, subsidiary} = nameVariant;
      return (
        new Builder(name).add(', ', subsidiary).add(' ', id).value ??
        formatNullValue()
      );
    }
    case Concept.event: {
      const {name, subsidiary} = nameVariant;
      return (
        new Builder(name).add(', ', subsidiary).add(' ', id).value ??
        formatNullValue()
      );
    }
  }
};
