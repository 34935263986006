import React, {useMemo} from 'react';
import {Chip} from '@mui/material';
import {PartValueRenderer} from 'schema/types';
import {useLocalization} from 'localization';
import {px2rem, useSpotColors, useSpotTextColors} from 'theme';
import {TaskType as TaskTypeType, TaskTypes} from 'api';
import {NoWrap, Text} from 'components';

export const TaskType: PartValueRenderer = ({value}) => {
  const spotColors = useSpotColors();
  const spotTextColors = useSpotTextColors();
  const {tLoose} = useLocalization();

  const types = value
    ? Array.isArray(value)
      ? (value as TaskTypeType[])
      : []
    : [];
  type TagTitleMap = {[key in TaskTypeType]: string};
  const tagTitles = useMemo(
    () =>
      TaskTypes.reduce<TagTitleMap>((acc, tag) => {
        acc[tag] = tLoose(`entity.task.type.short.${tag}`);
        return acc;
      }, {} as TagTitleMap),
    [tLoose],
  );

  return (
    <NoWrap>
      {types.map((tt: TaskTypeType) => {
        const title = tagTitles[tt];

        return (
          <Chip
            key={tt}
            label={
              <Text
                variant="body2"
                textTransform="none"
                sx={{color: spotTextColors[tt]}}>
                {title}
              </Text>
            }
            sx={{
              background: spotColors[tt],
              height: px2rem(24),
            }}
          />
        );
      })}
    </NoWrap>
  );
};
