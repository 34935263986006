import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import {ExpressionV4} from 'api/types';
import {metadataEditExpressionSelectorById} from '../selectors';
import {useCreateSelectorById} from './useCreateSelectorById';

export const useAssertExpressionEdit = (expressionId: string): ExpressionV4 => {
  const selector = useCreateSelectorById(
    expressionId,
    metadataEditExpressionSelectorById,
  );
  const expression = useSelector(selector);

  if (!expression) {
    assert(false, 'useAsserExpression: expression not found');
  }

  return expression;
};
