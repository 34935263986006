import {Dispatch} from 'redux';
import {Locale} from 'localization/types';
import {AppState} from 'store/types';
import {DataLoadStatus, GetTokens, Thesaurus, ThesaurusId} from 'api';
import {getThesaurus} from 'api/thesaurus';
import {DefaultDataLoadStatus} from 'services/utils';
import {makeLoadByIdActionFactories} from 'services/utils/redux/functions/makeLoadByIdActionFactories';
import {ThesaurusActionType, ThesaurusesAction} from '../reducer';

const {makeRequestAction, makeSuccessAction, makeFailureAction} =
  makeLoadByIdActionFactories<
    Thesaurus,
    ThesaurusId,
    ThesaurusActionType.LOAD_THESAURUS
  >(ThesaurusActionType.LOAD_THESAURUS);

export const loadThesaurusAction = (
  id: ThesaurusId,
  language: Locale,
  getTokens: GetTokens,
  mock?: boolean,
) => {
  return (dispatch: Dispatch<ThesaurusesAction>, getState: () => AppState) => {
    const thesaurus =
      getState().thesauruses[id] ??
      (DefaultDataLoadStatus as DataLoadStatus<Thesaurus>);

    if (thesaurus.status === 'NotLoaded') {
      dispatch(makeRequestAction(id));

      getThesaurus(id, language, getTokens, mock)
        .then(thesaurus => {
          dispatch(makeSuccessAction(id, thesaurus));
        })
        .catch(error => {
          dispatch(makeFailureAction(id, error));
        });
    }
  };
};
