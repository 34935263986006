import React from 'react';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import {BaseProps} from '../types';
import {DialogItem} from './types';
import {Layout} from '../layout';
import {Text} from '../text';
import {DialogItems} from './DialogItems';

export type DialogProps = BaseProps & {
  isOpen: boolean;
  title: React.ReactNode | string;
  body?: React.ReactNode | string;
  onClose?: () => void;
  dialogItems?: DialogItem[];
  errorMessage?: string;
  dividers?: boolean;
  fullWidth?: boolean;
};

export const Dialog: React.FC<DialogProps> = ({
  isOpen,
  onClose,
  title,
  body,
  dialogItems,
  errorMessage,
  dividers,
  fullWidth,
  'data-cy': dataCy,
}) => {
  return (
    <MuiDialog
      data-cy={dataCy}
      fullWidth={fullWidth}
      maxWidth={'lg'}
      open={isOpen}
      onClose={onClose}>
      <MuiDialogTitle>{title}</MuiDialogTitle>
      {body ? (
        <MuiDialogContent dividers={dividers}>{body}</MuiDialogContent>
      ) : null}
      {dialogItems || errorMessage ? (
        <MuiDialogActions>
          <Layout
            horizontal
            flex={1}
            alignItems="center"
            paddingX={2}
            paddingY={1}>
            <Text variant="h3" kind="error">
              {errorMessage ?? ''}
            </Text>
            <DialogItems dialogItems={dialogItems} />
          </Layout>
        </MuiDialogActions>
      ) : null}
    </MuiDialog>
  );
};
