import {assert} from 'assert-ts';
import {Concept} from 'types';
import {CodeListRef, WorkV4} from 'api/types';
import {
  AnyValidation,
  NotConditionV0,
  PartCodeList,
  PartSchema,
  RegexCondition,
  SchemaValueType,
  SelectableThesaurusNodeType,
  Separator,
  TypedSchema,
  TypedSchemaModifierMap,
} from 'schemaDefinition/types';
import {isFeatureEnabled} from 'configuration';
import {
  MetadataModifierKey,
  StatusModifierKey,
  WorkBook,
  WorkMerchandise,
} from '../types';
import {
  isUnnamedAgentLink,
  validateExternalLinkedAgentId,
  validateLinkedAgentId,
} from './partValidations';

/**
 * WorkWithExpressions schema
 */

export const deweyRegex = [
  // Just 3 digits
  '^[0-9]{3}$',
  // Or more digits with .
  '^[0-9]{3}\\.[0-9]+$',
  // Or more digits with /.
  '^[0-9]{3}/\\.[0-9]+$',
  // Or more digits with .--/--
  '^[0-9]{3}\\.[0-9]+/[0-9]+$',
];

const isBook: RegexCondition = {
  regex: CodeListRef.WORK_TYPE['Bok'],
  arg: {
    $ref: '#type',
  },
  default: true, // BOOK is default although type is required
};

const isSchoolBook: RegexCondition = {
  regex: CodeListRef.LITERATURE_TYPE['Skolebøker'],
  arg: {
    $ref: '#literatureType',
  },
  default: false,
};

const isNotSchoolBook: NotConditionV0 = {
  op: 'not',
  arg: isSchoolBook,
};

export const deweyPart: PartSchema<SchemaValueType, keyof WorkV4> = {
  type: 'schema',
  name: 'dewey',
  cardinality: '2',
  compare: 'items',
  parts: [
    [
      {
        type: 'text',
        name: 'value',
        required: true,
        validation: {
          regex: deweyRegex,
          default: true,
        },
        customizeKey: 'deweyNumber',
        labelKey: 'deweyNumber',
      },
      {
        type: 'codelist',
        name: 'source',
        codelistId: 'DEWEY_SOURCE',
        required: true,
        default: CodeListRef.DEWEY_SOURCE['23/nor'],
        customizeKey: 'deweySource',
        labelKey: 'deweySource',
        validation: [
          // Source must be equal to [0].source
          {
            op: 'eq',
            arg2: {$ref: '#dewey[0].source'},
          },
        ],
      },
    ],
  ],
};

export const intellectualLevelPart: PartCodeList<
  SchemaValueType,
  keyof WorkV4
> = {
  type: 'codelist',
  name: 'intellectualLevel',
  labelKey: 'intellectualLevel',
  cardinality: 'multiple',
  codelistId: 'INTELLECTUAL_LEVEL',
  customizeKey: 'intellectualLevel',
};

/** YP* => 4C*, or equivalent: (!YP* or 4C*) */
export const workValidation_YcodeRequires5Acode: AnyValidation = {
  id: 'YPcodeRequires4Ccode',
  messageKey: 'YPcodeRequires4Ccode',
  op: 'or',
  arg: [
    {
      op: 'not',
      arg: {
        regex: '"YP.*"',
        default: false,
      },
    },
    {
      regex: '"4C.*"',
      default: false,
    },
  ],
};

const selectableGrepNodeTypes: SelectableThesaurusNodeType[] = [
  'education_program_type',
  'education_program',
  'program_area',
  'subject_code',
];

export const workDataSchema: TypedSchema<WorkV4> = {
  name: 'work',
  parts: [
    {
      type: 'codelist',
      name: 'type',
      required: true,
      codelistId: 'WORK_TYPE',
    },
    {
      type: 'linkedAgent',
      name: 'agents',
      cardinality: 'multiple',
      roleCodelistId: 'WORK_ROLE_TYPE',
      entitySubtypes: [Concept.person, Concept.corporation, Concept.event],
      compare: 'subValues',
    },
    ...((isFeatureEnabled('titleAsMainEntry')
      ? [
          {
            type: 'expand',
            role: 'showTitleAsMainEntryIfBook',
            when: [
              {
                condition: isBook,
                parts: [
                  {
                    type: 'bool',
                    name: 'useTitleAsMainEntry',
                    labelKey: 'titleAsMainEntry',
                  },
                ],
              },
            ],
          },
        ]
      : []) as TypedSchema<WorkV4>['parts']),
    Separator('card', 'line'),
    {
      type: 'text',
      name: 'preferredTitle',
      labelKey: 'preferredTitle',
      cardinality: 'multiple',
      compare: 'items',
    },
    {
      type: 'schema',
      name: 'otherTitles',
      parts: [
        [
          {
            type: 'text',
            name: 'value',
            labelKey: 'otherTitle.value',
            required: true,
          },
          {
            type: 'codelist',
            name: 'type',
            labelKey: 'otherTitle.type',
            codelistId: 'WORK_TITLE_TYPE',
            required: true,
          },
        ],
      ],
      cardinality: 'multiple',
    },
    {
      type: 'codelist',
      name: 'languages',
      labelKey: 'language',
      codelistId: 'LANGUAGE',
      cardinality: 'multiple',
    },
    Separator('card', 'line'),
    {
      type: 'linkedLiterary',
      name: 'links',
      labelKey: 'links',
      cardinality: 'multiple',
      //linkPropRequired: 'should',
      roleCodelistId: 'work.linkedRole',
      compare: 'items',
    },
    Separator('card', 'line'),
    {
      type: 'expand',
      role: 'showIfBook',
      when: [
        {
          condition: isBook,
          parts: [
            {
              type: 'codelist',
              name: 'literatureType',
              cardinality: 'multiple',
              codelistId: 'LITERATURE_TYPE',
              customizeKey: 'literatureType',
              listValidation: {
                // Hvis litteraturtype er Lærebøker (høyere utd.), så må også faglitteratur være satt:
                // ('Faglitteratur' or not 'Lærebøker (høyere utd.)')
                op: 'or',
                arg: [
                  {
                    regex: CodeListRef.LITERATURE_TYPE['Faglitteratur'],
                    default: false,
                  },
                  {
                    op: 'not',
                    arg: {
                      regex:
                        CodeListRef.LITERATURE_TYPE['Lærebøker (høyere utd.)'],
                      default: false,
                    },
                  },
                ],
                messageKey: 'literatureType.LærebøkerRequiresFaglitteratur',
              },
            },
            deweyPart,
          ],
        },
      ],
    },
    {
      // Vis trinn og målgruppe når litteraturtype er skolebøker
      type: 'expand',
      role: 'showIfSchoolbook',
      when: [
        {
          condition: [isBook, isSchoolBook],
          parts: [
            {
              type: 'codelist',
              name: 'educationLevel',
              codelistId: 'EDUCATION_LEVEL',
              cardinality: 'multiple',
            },
            {
              type: 'codelist',
              name: 'educationTargetGroup',
              codelistId: 'EDUCATION_TARGET_GROUP',
              cardinality: 'multiple',
            },
          ],
        },
      ],
      default: [intellectualLevelPart],
    },
    {
      type: 'expand',
      role: 'showBibleIfBook',
      when: [
        {
          condition: isBook,
          parts: [
            Separator('card', 'line'),
            {
              type: 'codelist',
              name: 'bibleVersion',
              codelistId: 'BIBLE_VERSION',
            },
            {
              type: 'codelist',
              name: 'bibleContent',
              codelistId: 'BIBLE_CONTENT',
              cardinality: 'multiple',
            },
          ],
        },
      ],
    },
    {
      type: 'expand',
      role: 'showAdultImmigrantsIfNotSchoolBook',
      when: [
        {
          condition: [isBook, isNotSchoolBook],
          parts: [
            Separator('card', 'line'),
            {
              type: 'bool',
              name: 'norwegianForAdultImmigrants',
              labelKey: 'norwegianForAdultImmigrants',
            },
          ],
        },
      ],
    },
    {
      type: 'expand',
      role: 'showGrepIfBookAndSchoolbook',
      when: [
        {
          condition: [isBook, isSchoolBook],
          parts: [
            Separator('card', 'line'),
            {
              type: 'thesaurus',
              name: 'grep',
              cardinality: 'multiple',
              thesaurusId: 'grep',
              showCode: true,
              selectableNodeTypes: selectableGrepNodeTypes,
              variant: 'byType',
            },
          ],
        },
      ],
    },
    Separator('card', 'line'),
    {
      type: 'thesaurus',
      name: 'genreAndForm',
      cardinality: 'multiple',
      thesaurusId: 'genreandform',
      showCode: false,
    },
    {
      type: 'expand',
      role: 'showSubjectIfBookAndNotSchoolbook',
      when: [
        {
          condition: [isBook, isNotSchoolBook],
          parts: [
            Separator('card', 'line'),
            {
              type: 'thesaurus',
              name: 'subjects',
              cardinality: 'multiple',
              thesaurusId: 'bokbasen',
              showCode: false,
            },
          ],
        },
      ],
    },
    Separator('card', 'line'),
    {
      type: 'thesaurus',
      name: 'themes',
      cardinality: 'multiple',
      thesaurusId: 'thema',
      showCode: true,
    },
    Separator('card', 'line'),
    {
      type: 'text',
      name: 'generalNote',
      labelKey: 'generalNote',
      cardinality: 'multiple',
    },
  ],
};

export const workSchemaModifiers: TypedSchemaModifierMap<
  WorkV4,
  MetadataModifierKey
> = {
  [`${WorkMerchandise}.${StatusModifierKey.new}`]: [
    {
      // Required by backend so temporarily required here
      name: 'preferredTitle',
      required: true,
    },
  ],
  [`${WorkMerchandise}.${StatusModifierKey.precat}`]: [
    {
      name: 'languages',
      required: 'should',
    },
  ],
  [`${WorkMerchandise}.${StatusModifierKey.cat}`]: [
    {
      name: 'languages',
      required: true,
    },
  ],
  [`${WorkBook}.${StatusModifierKey.new}`]: [
    {
      name: 'preferredTitle',
      required: true,
    },
  ],
  [`${WorkBook}.${StatusModifierKey.precat}`]: [
    {
      name: 'languages',
      required: 'should',
    },
    {
      name: 'literatureType',
      required: true,
    },
    {
      name: 'intellectualLevel',
      required: 'should',
    },
    {
      name: 'genreAndForm',
      required: {
        should: {
          $or: [
            {$not: {arg: {$ref: '/subjects'}}},
            {
              $includes: [
                {$ref: '/literatureType'},
                CodeListRef.LITERATURE_TYPE['Skjønnlitteratur'],
              ],
              default: false,
            },
          ],
        },
      },
    },
    {
      name: 'subjects',
      required: {
        should: {$not: {arg: {$ref: '/genreAndForm'}}},
      },
    },
  ],
  [`${WorkBook}.${StatusModifierKey.cat}`]: [
    {
      name: 'languages',
      required: true,
      listValidation: {
        level: 'warning',
        $not: {
          $eq: [{$ref: '#languages'}, [CodeListRef.LANGUAGE.Flerspråklig]],
        },
        messageKey: 'languages.not.only.multilingual',
        default: true,
      },
    },
    {
      name: 'intellectualLevel',
      required: true,
    },
    {
      name: 'links',
      validation: {
        // Valdiation will only trigger for links with languages, i.e. series,
        // where one of the languages of the series must match the language of the work
        $includes: [{$ref: '.link.languages'}, {$ref: '#languages[0]'}],
        default: true,
        messageKey: 'link.series.languageMismatch',
        level: 'warning',
      },
    },
    {
      name: 'bibleContent',
      validation: {
        level: 'error',
        arg: {$ref: '#bibleVersion'},
        messageKey: 'bibleVersion.missing',
      },
    },
    {
      name: 'dewey',
      required: 'should',
    },
    {
      name: 'agents',
      required: {
        should: {op: 'not', arg: isUnnamedAgentLink},
      },
      validation: [validateLinkedAgentId, validateExternalLinkedAgentId],
    },
    {
      name: 'educationLevel',
      required: 'should',
    },
    {
      name: 'educationTargetGroup',
      required: 'should',
    },
    {
      name: 'grep',
      required: 'should',
    },
    {
      name: 'genreAndForm',
      required: {
        should: {
          $or: [
            {$not: {arg: {$ref: '/subjects'}}},
            {
              $includes: [
                {$ref: '/literatureType'},
                CodeListRef.LITERATURE_TYPE['Skjønnlitteratur'],
              ],
              default: false,
            },
          ],
        },
      },
    },
    {
      name: 'subjects',
      required: {
        should: {$not: {arg: {$ref: '/genreAndForm'}}},
      },
    },
    {
      name: 'themes',
      required: 'should',
      listValidation: {
        ...workValidation_YcodeRequires5Acode,
        id: assert(workValidation_YcodeRequires5Acode.id),
        level: 'warning',
      },
    },
  ],
};
