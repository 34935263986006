import {LinkedValueLink, VerifiedLinkedValueLink} from 'schemaDefinition/types';
import {isLinkedAgentLink} from 'schemaDefinition/functions/isLinkedAgentLink';
import {Builder, isNullish} from 'services/utils';
import {formatNameVariantValue} from './formatNameVariantValue';

export const formatLinkedValueLink = (
  link: LinkedValueLink | undefined,
  numberInSeries?: string | null,
): string | undefined => {
  if (!link || !link.linkStatus || link.linkStatus === 'empty') {
    return undefined;
  }

  if (link.linkStatus === 'unverified' || link.linkStatus === 'unnamed') {
    return link.name ?? '';
  }

  const verified = link as VerifiedLinkedValueLink;

  return isLinkedAgentLink(verified)
    ? formatNameVariantValue(verified.agentName, verified.type, 'excludeId')
    : isNullish(numberInSeries)
      ? verified.name
      : new Builder(numberInSeries).add(': ', verified.name).value;
};
