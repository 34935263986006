import {ValidationResult} from 'schema/form/functions/validators/types';
import {Valid} from 'schemaDefinition/types';

const someHasValue = (
  value: Valid,
  expression: ValidationResult,
  manifestations: ValidationResult[],
): boolean => {
  return (
    expression.valid === value || manifestations.some(m => m.valid === value)
  );
};

export const aggregateExpressionValid = (
  expression: ValidationResult,
  manifestations: ValidationResult[],
): Valid => {
  if (someHasValue('error', expression, manifestations)) {
    return 'error';
  }

  if (someHasValue('warning', expression, manifestations)) {
    return 'warning';
  }

  return 'valid';
};
