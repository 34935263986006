import React from 'react';
import {AccordionSummary, IconButton, styled} from '@mui/material';
import {FCWithChildren} from 'types';
import {ColorPalette, px2rem} from 'theme';
import {DropDownIcon} from '../icons';

const AccordionHeaderName = 'AccordionHeaderName';

const AccordionSummaryStyled = styled(AccordionSummary)(() => ({
  '&:before': {
    content: 'none',
  },
}));

const iconButtonSx = {
  background: ColorPalette.beige,
  width: px2rem(24),
  height: px2rem(24),
} as const;

const AccordionHeader: FCWithChildren = ({children}) => {
  return (
    <AccordionSummaryStyled
      expandIcon={
        <IconButton sx={iconButtonSx}>
          <DropDownIcon />
        </IconButton>
      }>
      {children}
    </AccordionSummaryStyled>
  );
};

AccordionHeader.displayName = AccordionHeaderName;

export {AccordionHeader, AccordionHeaderName};
