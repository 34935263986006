export const partialReplace = <T>(
  newValue: T,
  currentValue: T,
  excludeProps?: (keyof T)[],
): T => {
  if (!excludeProps || excludeProps.length === 0) {
    return newValue;
  }

  const draftValue = {...newValue};

  excludeProps.forEach(prop => {
    if (currentValue[prop] !== undefined) {
      draftValue[prop] = currentValue[prop];
    } else {
      delete draftValue[prop];
    }
  });

  return draftValue;
};
