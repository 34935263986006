import {useCallback, useMemo} from 'react';
import {Part, Schema, Valx} from '../../../schemaDefinition/types';
import {ColumnConfig} from '../types';
import {useTableConfigurationContext} from '../contexts/TableConfigurationContextType';
import {
  ColumnConfigStateProps,
  useColumnConfigState,
} from './useColumnConfigState';

type ColumnConfigProps = Omit<ColumnConfigStateProps, 'columnConfigs'> & {
  columnConfigs: ColumnConfig[];
};

export const mergeWithDefaultColumnConfigs = (
  schema: Schema,
  defaultColumns: ColumnConfig[],
  columnConfigs?: ColumnConfig[],
) => {
  // Find missing defaults
  const newColumns = defaultColumns.filter(
    d => !(columnConfigs ?? []).find(c => c.name === d.name),
  );

  // Merge missing with current
  const mergedColumns = [...(columnConfigs ?? []), ...newColumns];

  // Remove columns not in schema (won't have value anyway)
  return mergedColumns.filter(c =>
    schema.parts.find(p => (p as Part<Valx>).name === c.name),
  );
};

/**
 * See how many default entries (a) are missing from state entries (b).
 */
const diffColumnConfigsByName = (
  a: ColumnConfig[],
  b?: ColumnConfig[],
): number => {
  return a.filter(aa => !(b ?? []).find(bb => bb.name === aa.name)).length;
};

export const useColumnConfig = (schema: Schema): ColumnConfigProps => {
  const {defaultColumns} = useTableConfigurationContext();
  const {
    columnConfigs,
    setColumnConfigs,
    setColumnConfigVisibility,
    setColumnConfigWidth,
    sortingConfig,
    setSortingConfig,
    filterModel,
    setFilterModel,
  } = useColumnConfigState(schema.name);

  const handleSetColumnConfigs = useCallback(
    (columnConfigs: ColumnConfig[]) => {
      setColumnConfigs(
        mergeWithDefaultColumnConfigs(schema, defaultColumns, columnConfigs),
      );
    },
    [defaultColumns, schema, setColumnConfigs],
  );

  return useMemo(() => {
    const columnConfigsMerged = mergeWithDefaultColumnConfigs(
      schema,
      defaultColumns,
      columnConfigs,
    );
    if (diffColumnConfigsByName(columnConfigsMerged, columnConfigs)) {
      setColumnConfigs(columnConfigsMerged);
    }

    return {
      columnConfigs: columnConfigsMerged,
      setColumnConfigs: handleSetColumnConfigs,
      setColumnConfigVisibility,
      setColumnConfigWidth,
      sortingConfig,
      setSortingConfig,
      filterModel,
      setFilterModel,
    };
  }, [
    columnConfigs,
    defaultColumns,
    filterModel,
    handleSetColumnConfigs,
    schema,
    setColumnConfigVisibility,
    setColumnConfigWidth,
    setColumnConfigs,
    setFilterModel,
    setSortingConfig,
    sortingConfig,
  ]);
};
