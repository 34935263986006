import React from 'react';
import {ThesaurusId} from 'api/types';
import {Card, Layout, Spacer} from 'components';
import {ColorPalette} from '../../../theme';
import {TopLevels} from './TopLevels';
import {TopLevelsGuide} from './TopLevelsGuide';

type Props = {
  thesaurusId: ThesaurusId;
};
export const TopLevelsContainer: React.FC<Props> = ({thesaurusId}) => {
  return (
    <Layout horizontal flex={1}>
      <Card colorx={ColorPalette.offWhite} scrollable flex={1}>
        <Card.Content>
          <TopLevels />
        </Card.Content>
      </Card>
      <Spacer size={2} />

      <TopLevelsGuide thesaurusId={thesaurusId} />
    </Layout>
  );
};
