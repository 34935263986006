import React from 'react';
import {useMediaQuery, useTheme} from '@mui/material';
import {AgentSubType} from 'types';
import {useLocalization} from 'localization';
import {Bar, FlexBox, Text} from 'components';

type Props = {
  title: string;
  type?: AgentSubType;
};

export const AgentTitle: React.FC<Props> = ({title, type}) => {
  const {t} = useLocalization();
  const theme = useTheme();

  // TODO: Refactor breakpoints to represent device types,
  // below should be: useMediaQuery(theme.breakpoints.down('md'));
  const belowMedium = useMediaQuery(theme.breakpoints.down('sm'));

  return belowMedium ? (
    <Text variant="h1">{title}</Text>
  ) : (
    <FlexBox paddingBottom={1} sx={{position: 'relative'}}>
      <FlexBox
        horizontal
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}>
        <Text variant="h1">{title}</Text>
        {type ? <Bar spacing={2} height={20} /> : null}
        {type ? (
          <Text variant="h2" kind="disabled">
            {t(`general.entity.${type}`)}
          </Text>
        ) : null}
      </FlexBox>
    </FlexBox>
  );
};
