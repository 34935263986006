import {useMemo} from 'react';
import {CodeListId, CodeListMap, ExpressionV4} from 'api/types';
import {useLocalization} from 'localization';
import {LooseTranslate} from 'localization/core/translation/coreTypes';
import {useCodelist} from 'services/codeLists';
import {Builder} from 'services/utils';

const getCodelistValue = (
  codelistId: CodeListId,
  code: string | undefined,
  codelists: CodeListMap,
): string | undefined => {
  if (!code) {
    return undefined;
  }

  const codelist = codelists[codelistId];
  if (!codelist) {
    return undefined;
  }

  const codeValue = codelist.codes.find(c => c.code === code);
  return codeValue ? codeValue.value : undefined;
};

const getExpressionTitle = (
  expression: ExpressionV4 | undefined,
  codelists: CodeListMap,
  tLoose: LooseTranslate,
): string => {
  if (!expression) {
    return '';
  }

  const languages = expression.languages
    ? expression.languages.length > 2
      ? expression.languages.join('/')
      : expression.languages
          .map(code => getCodelistValue('LANGUAGE', code, codelists) ?? code)
          .join('/')
    : undefined;

  const format = expression.expressionFormat
    ? tLoose(`page.metadata.expression.format.${expression.expressionFormat}`)
    : undefined;

  return new Builder(languages).add(' - ', format).value ?? '';
};

export const useExpressionTitle = (
  originalExpression: ExpressionV4 | undefined,
  editedExpression?: ExpressionV4 | undefined,
): string => {
  const {tLoose} = useLocalization();
  const languageCodeList = useCodelist('LANGUAGE');

  return useMemo(
    () =>
      getExpressionTitle(
        editedExpression ?? originalExpression,
        {LANGUAGE: languageCodeList},
        tLoose,
      ),
    [editedExpression, languageCodeList, originalExpression, tLoose],
  );
};
