import {TypographyOptions} from '@mui/material/styles/createTypography';
import {ColorPalette} from './colorPalette';
import {px2rem} from './px2rem';

export const FontsReduced: TypographyOptions = {
  h1: {
    fontWeight: 400,
    fontStyle: 'regular',
    fontSize: '19px',
    lineHeight: '26px',
    letterSpacing: '0.16px',
  },
  h2: {
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '18px',
  },
};

export const Fonts: TypographyOptions = {
  h1: {
    fontWeight: 400,
    fontStyle: 'regular',
    fontSize: '32px',
    lineHeight: '44px',
    letterSpacing: '0.27px',
  },
  h2: {
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '22px',
    lineHeight: '30px',
  },
  h3: {
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: px2rem(11),
    lineHeight: px2rem(20),
    textTransform: 'none',
    letterSpacing: '0.399561px',
  },
  h4: {
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: px2rem(10),
    lineHeight: px2rem(10),
  },
  /** Maps to label */
  caption: {
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: px2rem(10),
    // 19 to align with body1
    lineHeight: px2rem(19),
  },
  label: {
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: px2rem(10),
    lineHeight: px2rem(10),
    textTransform: 'uppercase',
  },
  /*** Maps to body */
  body1: {
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: px2rem(13),
    lineHeight: px2rem(19),
  },
  /** Maps to input */
  body2: {
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: px2rem(11),
    lineHeight: px2rem(14),
  },
  link: {
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: px2rem(13),
    lineHeight: px2rem(19),
    textDecorationLine: 'underline',
    textUnderlineOffset: '2px',
    cursor: 'pointer',
    textDecorationColor: ColorPalette.burgundy,
  },
  // TODO: Clean up fonts
  subtitle1: {
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: px2rem(10),
    lineHeight: px2rem(14),
  },
  /** Maps to input */
  button: {
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: px2rem(10),
    lineHeight: px2rem(13),
    textTransform: 'none',
  },
} as const;
