import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const RadioOnIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <rect
        x="4.625"
        y="4.625"
        width="14.75"
        height="14.75"
        rx="7.375"
        fill="#FEFEFE"
        stroke={'currentColor'}
        strokeWidth="1.25"
      />
      <circle cx="12" cy="12" r="5" fill={'currentColor'} />
    </SvgIcon>
  );
};
