import React, {PropsWithChildren, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AnyAction} from 'redux';
import {MasterLocale} from 'localization/types';
import {AppState} from 'store/types';
import {useLocalization} from 'localization';
import {LoadStatus, ThesaurusId} from 'api';
import {useGetTokens} from 'services/auth';
import {useMockContext} from 'services/utils/contexts';
import {Scene} from 'components';
import {loadThesaurusAction} from '../functions/loadThesaurusAction';
import {ThesaurusesState} from '../reducer/thesaurusesReducer';

export const AllThesaurusIds: ThesaurusId[] = [
  'thema',
  'bokbasen',
  'genreandform',
  'grep',
];

type Props = {
  thesauruses: ThesaurusId[] | undefined;
};

const getAggregateStatus = (
  thesauruses: ThesaurusId[],
  state: ThesaurusesState,
): LoadStatus => {
  return thesauruses.reduce<LoadStatus>((acc, name) => {
    const thesaurusStatus = state[name]?.status ?? 'NotLoaded';
    return acc === 'Failed' || thesaurusStatus === 'Failed'
      ? 'Failed'
      : acc === 'Loaded' && thesaurusStatus === 'Loaded'
        ? 'Loaded'
        : 'Loading';
  }, 'Loaded');
};

const getNotLoaded = (
  thesauruses: ThesaurusId[],
  state: ThesaurusesState,
): ThesaurusId[] => {
  return thesauruses.filter(
    name => (state[name]?.status ?? 'NotLoaded') === 'NotLoaded',
  );
};

const thesaurusesStateSelector = (state: AppState) => state.thesauruses;

/**
 * Will only render children after thesauruses are successfully loaded.
 * Shows spinner while loading, and an error message if any fails
 */
export const ThesaurusesGate: React.FC<PropsWithChildren<Props>> = ({
  thesauruses,
  children,
}) => {
  const dispatch = useDispatch();
  const getTokens = useGetTokens();
  const {locale} = useLocalization();
  const mock = useMockContext();
  const thesaurusesState = useSelector(thesaurusesStateSelector);
  const status = useMemo(
    () => getAggregateStatus(thesauruses ?? [], thesaurusesState),
    [thesauruses, thesaurusesState],
  );

  // Trigger loading of thesauruses not yet loaded
  useEffect(() => {
    if (thesauruses !== undefined) {
      const notLoaded = getNotLoaded(thesauruses, thesaurusesState);
      notLoaded.forEach(id => {
        dispatch(
          loadThesaurusAction(
            id,
            locale ?? MasterLocale,
            getTokens,
            mock && false,
            // TODO: Fix typing
          ) as unknown as AnyAction,
        );
      });
    }
    // On thesauruses or local only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thesauruses, locale]);

  return status === 'NotLoaded' ||
    status === 'Loading' ||
    status === 'Failed' ? (
    <Scene>
      <Scene.Header title="" />
      <Scene.Content
        loadStatus={status}
        error={status === 'Failed' ? 'Failed to load thesauruses' : undefined}
      />
    </Scene>
  ) : (
    <>{children}</>
  );
};
