import {PartValueRenderer} from 'schema/types';
import {getFirstName} from 'services/users/functions';
import {useUserById} from 'services/users/hooks/useUserById';
import {Avatar, FlexBox, Spacer} from 'components';

export const LockedBy: PartValueRenderer = ({value}) => {
  const user = useUserById(value as string | undefined);
  const name = getFirstName(user);
  return user ? (
    <FlexBox horizontal center left>
      <Avatar src={user.picture} color="primary" />
      <Spacer size={1} />
      <span>{name ?? ''}</span>
    </FlexBox>
  ) : null;
};
