import {assert} from 'assert-ts';
import {IncludesCondition, Valx} from '../types';
import {EvaluationArgs} from './types';
import {evaluateValx} from './evaluateValx';
import {evaluateValxWithDefault} from './evaluateValxWithDefault';

/**
 * If any values cannot be converted to argType, then the condition is undefined.
 */
export const evaluateIncludesCondition = (
  condition: IncludesCondition<Valx>,
  args: EvaluationArgs,
): boolean =>
  //  | undefined
  {
    const arg1Valx = condition.$includes[0];
    const arg1 = Array.isArray(arg1Valx)
      ? arg1Valx.map(valx => evaluateValxWithDefault(valx, args, args.value))
      : evaluateValxWithDefault(arg1Valx, args, args.value);
    const arg2 = evaluateValx(condition.$includes[1], args);

    assert.soft(
      Array.isArray(arg1) || arg1 === null || arg1 === undefined,
      'evaluateIncludesCondition: arg1 must be an array',
      {arg1},
    );

    if (
      !Array.isArray(arg1) ||
      arg1.length === 0 ||
      arg2 === undefined ||
      arg2 === null ||
      arg2 === ''
    ) {
      return condition.default;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return arg1.includes(arg2);
  };
