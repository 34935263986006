import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import {Thesaurus, ThesaurusId} from 'api/types';
import {AppState} from 'store/types';
import {getDummyThesaurus} from './getDummyThesaurus';

const thesaurusStateSelector = (state: AppState) => state.thesauruses;

export const useThesaurus = (id: ThesaurusId): Thesaurus => {
  const thesaurusState = useSelector(thesaurusStateSelector);
  return useMemo(() => {
    const thesaurus = thesaurusState[id]?.data;
    if (
      assert.soft(thesaurus, 'useThesaurus: ThesaurusItem list not loaded', {
        id,
      })
    ) {
      return thesaurus;
    }
    return getDummyThesaurus(id);
  }, [thesaurusState, id]);
};
