import {DateValue, YearOrDateValue} from 'schemaDefinition/types';

/**
 * String must match format (-)YYYY-MM-DD
 *
 * YYYY must be between -9999 and 9999
 * MM must be between 1 and 12
 * DD must be between 1 and 31
 */
export const stringToDateValue = (string: string): DateValue | undefined => {
  const isBce = string.charAt(0) === '-';

  // Remove first char if isBce.
  const dateString = isBce ? string.substring(1) : string;

  const [year, month, day] = dateString.split('-').map(Number);

  if (year > 9999 || year < -9999) {
    return undefined;
  }

  if (month > 12 || month < 1) {
    return undefined;
  }

  if (day > 31 || day < 1) {
    return undefined;
  }

  return {
    year: isBce ? -year : year,
    month,
    day,
  };
};

export const yearOrDateValueToDateValue = (
  yearOrDateValue: YearOrDateValue,
): DateValue | undefined => {
  if (!yearOrDateValue.date) {
    return undefined;
  }

  const isBce = yearOrDateValue.bce ? '-' : '';

  return stringToDateValue(`${isBce}${yearOrDateValue.date}`);
};

export const dateValueToDate = (dateValue: DateValue): Date => {
  const date = new Date(0, 0, 0, 0, 0, 0, 0);
  date.setUTCFullYear(dateValue.year, dateValue.month - 1, dateValue.day);

  return date;
};
