import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const OtherItemIcon: React.FC<IconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1584 8H18C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V14H19V18C19 18.5304 18.7893 19.0391 18.4142 19.4142C18.0391 19.7893 17.5304 20 17 20H7C6.46957 20 5.96086 19.7893 5.58579 19.4142C5.21071 19.0391 5 18.5304 5 18V14H4V10C4 9.46957 4.21071 8.96086 4.58579 8.58579C4.96086 8.21071 5.46957 8 6 8H6.84155C6.72905 7.6818 6.66998 7.34394 6.66998 7C6.66998 6.20435 6.98605 5.44129 7.54866 4.87868C8.11127 4.31607 8.87433 4 9.66998 4C10.4656 4 11.2287 4.31607 11.7913 4.87868C11.8652 4.95255 11.9348 5.02987 12 5.11027C12.0652 5.02987 12.1348 4.95255 12.2087 4.87868C12.7713 4.31607 13.5344 4 14.33 4C15.1257 4 15.8887 4.31607 16.4513 4.87868C17.0139 5.44129 17.33 6.20435 17.33 7C17.33 7.34394 17.271 7.6818 17.1584 8ZM9.66998 6C9.9352 6 10.1896 6.10536 10.3771 6.29289C10.5646 6.48043 10.67 6.73478 10.67 7V8H9.66998C9.40477 8 9.15041 7.89464 8.96288 7.70711C8.77534 7.51957 8.66998 7.26522 8.66998 7C8.66998 6.73478 8.77534 6.48043 8.96288 6.29289C9.15041 6.10536 9.40477 6 9.66998 6ZM13.33 8H14.33C14.5952 8 14.8496 7.89464 15.0371 7.70711C15.2247 7.51957 15.33 7.26522 15.33 7C15.33 6.73478 15.2247 6.48043 15.0371 6.29289C14.8496 6.10536 14.5952 6 14.33 6C14.0648 6 13.8104 6.10536 13.6229 6.29289C13.4354 6.48043 13.33 6.73478 13.33 7V8ZM13 10V12H18V10H13ZM11 10V12H6V10H11ZM11 14H7V18H11V14ZM13 18H17V14H13V18Z"
      />
    </SvgIcon>
  );
};
