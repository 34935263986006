import {assert} from 'assert-ts';
import {Concept, DerivedConcept} from 'types';
import {
  SchemaReference,
  schemaRefCorporation,
  schemaRefEducationalSeries,
  schemaRefEvent,
  schemaRefOtherConnection,
  schemaRefPerson,
  schemaRefPublisher,
  schemaRefPublisherSeries,
  schemaRefSeries,
  schemaRefWork,
  schemaRefWorkSummary,
  schemaRefexpression,
  schemaRefmanifestation,
} from 'schemas/types';

const schemaReferenceMap: {
  [key in Concept | DerivedConcept]?: SchemaReference;
} = {
  [Concept.work]: schemaRefWork,
  [Concept.expression]: schemaRefexpression,
  [Concept.manifestation]: schemaRefmanifestation,
  [DerivedConcept.workSummary]: schemaRefWorkSummary,
  [Concept.person]: schemaRefPerson,
  [Concept.corporation]: schemaRefCorporation,
  [Concept.event]: schemaRefEvent,
  [Concept.publisher]: schemaRefPublisher,
  [Concept.series]: schemaRefSeries,
  [Concept.educationalSeries]: schemaRefEducationalSeries,
  [Concept.otherConnection]: schemaRefOtherConnection,
  [Concept.publisherSeries]: schemaRefPublisherSeries,
};

export const useSchemaReference = (
  entity: Concept | DerivedConcept,
): SchemaReference => {
  return assert(
    schemaReferenceMap[entity],
    'useSchemaReference: Abstract/unknown entity',
    {entity},
  );
};
