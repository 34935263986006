import React, {useCallback, useMemo, useState} from 'react';
import {FieldError, PartDate, Valx} from 'schemaDefinition/types';
import {evaluateFieldRequired} from 'schemaDefinition/functions';
import {isNullish, valueAsString} from 'services/utils';
import {DatePickerField} from 'components/fields/DatePickerField';
import {toFieldError} from 'schema/form/functions/validators';
import {validateSingleDateValue} from 'schema/form/functions/validators/validateDate';
import {BaseFieldProps} from './types';
import {useDataFormContext} from '../../contexts';

type Props = BaseFieldProps & {
  part: PartDate<Valx>;
};

export const DatePicker: React.FC<Props> = ({
  part,
  value,
  setFieldValue,
  valuePath,
  label,
  mode,
  showWhenReadonlyAndEmpty,
  width,
  maxWidth,
  flex,
  localScope,
  globalScope,
  relatedScope,
}) => {
  const [visited, setVisited] = useState(false);
  const handleSetVisited = useCallback(() => setVisited(true), []);
  const {showErrors} = useDataFormContext();

  const handleValueChange = useCallback(
    (date: string | null | undefined) => {
      const newVal = isNullish(date) ? null : date;
      setFieldValue(valuePath, newVal);
    },
    [valuePath, setFieldValue],
  );

  const required = useMemo(() => {
    return evaluateFieldRequired(
      part.required,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      value,
    );
  }, [globalScope, part.required, relatedScope, localScope, value, valuePath]);

  const error = useMemo((): FieldError => {
    if (showErrors || visited) {
      const valid = validateSingleDateValue(part, {
        value,
        valuePath,
        localScope,
        globalScope,
        relatedScope,
      }).valid;
      return toFieldError(valid);
    }
    return false;
  }, [
    globalScope,
    part,
    relatedScope,
    localScope,
    showErrors,
    value,
    valuePath,
    visited,
  ]);

  const date = valueAsString(value);
  return (
    <DatePickerField
      name={valuePath}
      label={label}
      value={date}
      readonly={mode === 'read-only'}
      showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
      required={required}
      error={error}
      width={width}
      maxWidth={maxWidth}
      flex={flex}
      onChange={handleValueChange}
      onBlur={handleSetVisited}
    />
  );
};
