import React, {PropsWithChildren} from 'react';
import {SxProps} from '@mui/material';
import {ColorPalette} from 'theme';
import {Card} from 'components';

const cardSx: SxProps = {m: 0, mb: 1, pb: 1};

export const ManifestationSubPanelCard: React.FC<PropsWithChildren> = ({
  children,
}) => {
  if (!children) {
    return null;
  }

  if (Array.isArray(children) && !children.length) {
    return null;
  }

  return (
    <Card colorx={ColorPalette.primary.white} sx={cardSx}>
      <Card.Content>{children}</Card.Content>
    </Card>
  );
};
