import {assert} from 'assert-ts';
import {LinkedAgentMultiRole} from 'schemaDefinition/types';
import {Schemas} from 'api/dto.generated';
import {isVerifiedLinkedAgentMultiRole} from 'schemaDefinition';
import {mapAgentRoleDto, mapToVerifiedAgentRoleDto} from './mapAgentRoleDto';

export const mapImprintDto = (
  dto: Schemas.ImprintDto | Schemas.UnverifiedImprintDto,
): LinkedAgentMultiRole => {
  return mapAgentRoleDto({
    ...dto,
    agentType: 'PUBLISHER',
    roles: ['publisher'],
  });
};

export const mapToImprintDto = (
  data: LinkedAgentMultiRole,
): Schemas.ImprintDto => {
  if (isVerifiedLinkedAgentMultiRole(data)) {
    const agentRoleDto = mapToVerifiedAgentRoleDto(data);

    return {
      agentId: agentRoleDto.agentId,
      agentName: agentRoleDto.agentName,
    };
  }

  assert(false, `mapToImprintDto: imprint not verified`, data);
};
