import {ColumnSet} from '../../types';

/**
 * Private columnsets firsts, then sorted by name.
 */
export const sortColumnSet = (a: ColumnSet, b: ColumnSet) => {
  return a.isPublic < b.isPublic
    ? -1
    : a.isPublic > b.isPublic
      ? 1
      : a.name < b.name
        ? -1
        : 1;
};
