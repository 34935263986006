import React from 'react';
import Radio from '@mui/material/Radio';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
} from '@mui/x-data-grid-pro';
import {assert} from 'assert-ts';

export const CheckboxColDef: GridColDef = {
  ...GRID_CHECKBOX_SELECTION_COL_DEF,
  // Rename field from __checked__ to avoid field being hidden
  field: 'singleChecked',
  type: 'string',
  valueGetter: params => {
    return assert(GRID_CHECKBOX_SELECTION_COL_DEF.valueGetter)(params);
  },
  hideable: false,
  renderHeader: () => null,
  headerName: '',
  renderCell: params => {
    return <Radio checked={!!params.value} size="small" />;
  },
};
