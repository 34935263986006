import React, {createContext, useContext, useMemo} from 'react';
import {assert} from 'assert-ts';
import {Schema, Valx} from 'schemaDefinition/types';

export type FormSchemaScopeContextType = {
  schema: Schema<Valx>;
  valuePath: string;
};

export const FormSchemaGlobalScopeContext =
  createContext<FormSchemaScopeContextType>({
    schema: {name: 'noSchema', parts: []},
    valuePath: '',
  });

export const FormSchemaGlobalScopeContextProvider: React.FC<
  React.PropsWithChildren<{
    schema?: FormSchemaScopeContextType['schema'];
    valuePath?: FormSchemaScopeContextType['valuePath'];
  }>
> = ({schema, valuePath, children}) => {
  const outer = useContext(FormSchemaGlobalScopeContext);
  const value = useMemo(
    () => ({
      schema: schema ?? outer.schema,
      valuePath: valuePath ?? outer.valuePath,
    }),
    [outer.schema, outer.valuePath, schema, valuePath],
  );

  return (
    <FormSchemaGlobalScopeContext.Provider value={value}>
      {children}
    </FormSchemaGlobalScopeContext.Provider>
  );
};

export const useFormSchemaGlobalScopeContext =
  (): FormSchemaScopeContextType => {
    const value = useContext(FormSchemaGlobalScopeContext);
    assert(
      value.schema.name !== 'noSchema',
      'useFormSchemaGlobalScopeContext: schema expected',
    );
    return value;
  };

export const FormSchemaLocalScopeContext = createContext<
  FormSchemaScopeContextType | undefined
>(undefined);

export const FormSchemaLocalScopeContextProvider: React.FC<
  React.PropsWithChildren<FormSchemaScopeContextType>
> = ({schema, valuePath, children}) => {
  const value = useMemo(
    () => ({
      schema,
      valuePath,
    }),
    [schema, valuePath],
  );

  return (
    <FormSchemaLocalScopeContext.Provider value={value}>
      {children}
    </FormSchemaLocalScopeContext.Provider>
  );
};

export const useFormSchemaLocalScopeContext =
  (): FormSchemaScopeContextType => {
    return assert(
      useContext(FormSchemaLocalScopeContext),
      'useFormSchemaLocalScopeContext: context expected',
    );
  };
