import {Collection} from 'api/types';
import {TableConfiguration} from 'schema/table/types';
import {defaultTableConfig} from 'schema/table/configuration/defaultTableConfig';
import {Counter, TableCollectionTitles} from '../../renderers';
import {collectionSeriesDefaultColumns} from './columns';

export const collectionTableConfig: TableConfiguration<Collection> = {
  ...defaultTableConfig,
  defaultColumns: collectionSeriesDefaultColumns,
  partValueRenderers: {
    titles: TableCollectionTitles,
    links: Counter,
  },
};
