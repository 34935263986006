import React from 'react';
import {ManifestationCardProps} from './types';
import {ManifestationCardCore} from './ManifestationCardCore';
import {ManifestationRestrictedCodelists} from './components/ManifestationRestrictedCodelists';
import {useManifestationEditState} from './hooks';

export const ManifestationCard: React.FC<ManifestationCardProps> = props => {
  const editState = useManifestationEditState(props);

  return (
    <ManifestationRestrictedCodelists
      expressionId={props.expressionId}
      manifestationId={props.manifestationId}>
      <ManifestationCardCore props={props} editState={editState} />
    </ManifestationRestrictedCodelists>
  );
};
