import {assert} from 'assert-ts';
import {MetadataEditState} from '../../../types';
import {MetadataEditAction} from '../../../metadataEditActionTypes';

export const assertEntityStatus = (
  id: string,
  state: Pick<MetadataEditState, 'statuses'>,
  action?: MetadataEditAction,
) => {
  return assert(
    state.statuses[id],
    'metadataEditReducer: status expected',
    action ?? {},
  );
};
