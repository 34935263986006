import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const BackIcon: React.FC<IconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M20.7801 11H7.64006L13.7101 4.93001L12.2901 3.51001L3.81006 12L12.2901 20.49L13.7101 19.07L7.64006 13H20.7801V11Z" />
    </SvgIcon>
  );
};
