import {DeprecatedTaskTypes, TaskTypeAll, TaskTypes} from 'api/types';
import {OverviewTaskType} from '../types';

export const getTaskTypes = (
  selectedTaskType: TaskTypeAll,
): OverviewTaskType[] =>
  TaskTypes.filter(t => !DeprecatedTaskTypes.includes(t)).map(task => ({
    task,
    checked: task === selectedTaskType,
  }));
