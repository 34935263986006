import {useCallback} from 'react';
import {
  CodelistRestrictor,
  useRestrictedCodelistsContext,
} from 'services/codeLists';
import {useExpressionImportProps} from 'services/data/metadata/hooks/useExpressionImportProps';
import {getRestrictedExpressionCodelist} from '../functions';

export const useExpressionCodelistRestrictor = (
  expressionId: string,
): CodelistRestrictor => {
  const importProps = useExpressionImportProps(expressionId);
  const {getRestrictedCodelist: getOuterRestrictedCodelist} =
    useRestrictedCodelistsContext();

  return useCallback(
    codelist => {
      const outerCodelist = getOuterRestrictedCodelist(codelist);
      return getRestrictedExpressionCodelist(importProps, outerCodelist);
    },
    [getOuterRestrictedCodelist, importProps],
  );
};
