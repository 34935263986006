import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const DeleteIcon: React.FC<IconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M20 7H16V6C16 5.46957 15.7893 4.96086 15.4142 4.58579C15.0391 4.21071 14.5304 4 14 4H10C9.46957 4 8.96086 4.21071 8.58579 4.58579C8.21071 4.96086 8 5.46957 8 6V7H4V9H6V18C6 18.5304 6.21071 19.0391 6.58579 19.4142C6.96086 19.7893 7.46957 20 8 20H16C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18V9H20V7ZM10 6H14V7H10V6ZM16 18H8V9H16V18Z" />
      <path d="M11 10H9V17H11V10Z" />
      <path d="M15 10H13V17H15V10Z" />
    </SvgIcon>
  );
};
