import React, {useCallback, useEffect, useRef} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu, {MenuProps} from '@mui/material/Menu';
import {assert} from 'assert-ts';
import {CloseSmallIcon} from 'components/icons';
import {Layout} from 'components/layout';
import {Spacer} from 'components/spacer';
import {Text} from 'components/text';
import {MenuItemProps} from './types';
import {PopupMenuItem} from './PopupMenuItem';

export type Props<TItemId = string> = {
  id?: string;
  'data-cy'?: string;
  popupTitle?: string;
  renderPopupSubtitle?: () => React.ReactNode;
  renderPopupFooter?: () => React.ReactNode;
  items: MenuItemProps<TItemId>[];
  anchorEl?: MenuProps['anchorEl'];
  layout?: {
    positionVertical?: 'top' | 'bottom';
  };
  onClose: () => void;
  onPreview?: (itemId: TItemId | undefined) => void;
  onSelect?: (itemId: TItemId) => void;
  /**
   * @deprecated
   */
  onSelectOld?: (item: MenuItemProps<TItemId>) => void;
};

export function PopupMenu<TItemId = string>({
  id,
  popupTitle,
  renderPopupSubtitle,
  renderPopupFooter,
  anchorEl,
  items,
  layout,
  onClose,
  onPreview,
  onSelect,
  'data-cy': dataCy,
  onSelectOld,
}: React.PropsWithChildren<Props<TItemId>>) {
  const [previewItemId, setPreviewItemId] = React.useState<
    // Initially, no item is previewed.
    | undefined
    // string: previewing item with id, i.e. mouse over item
    | TItemId
    // null: not previewing any item, i.e. mouse is not over any item
    | null
  >(undefined);

  const handleSelect = useCallback(
    (itemId?: TItemId) => {
      if (onSelect && itemId) {
        // console.log('PopupMenu: onSelect', item.id);
        onSelect(itemId);
      } else if (onSelectOld) {
        const item = assert(
          items.find(i => i.id === itemId),
          'PopupMenu: missing item',
          {itemId},
        );
        onSelectOld(item);
      }
    },
    [items, onSelect, onSelectOld],
  );

  // Keep track of open or not
  const useIsOpenRef = useRef(!!anchorEl);
  useIsOpenRef.current = !!anchorEl;

  // When the previewed item changes. Use effect to avoid resetting on mouse leave when immediately followed by mouse enter.
  // => call the onPreview callback.
  useEffect(() => {
    // Undefined is intially set, so ignore it.
    if (previewItemId !== undefined && useIsOpenRef.current) {
      // console.log('PopupMenu: previewItemId changed', previewItemId);
      onPreview && onPreview(previewItemId ?? undefined);
    }
  }, [onPreview, previewItemId]);

  const positionVertical = layout?.positionVertical ?? 'top';

  return (
    <Menu
      sx={{
        transform:
          positionVertical === 'top' ? 'translateY(-4px)' : 'translateY(6px)',
      }}
      id={id}
      data-cy={dataCy}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: positionVertical,
        horizontal: 'right',
      }}
      // keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={onClose}>
      {popupTitle ? (
        <Layout
          paddingLeft={2}
          paddingRight={1}
          horizontal
          adjustCenter
          flex={1}
          justifyContent="space-between">
          <Text variant="h3" kind="primary">
            {popupTitle}
          </Text>
          <IconButton onClick={onClose} color="primary">
            <CloseSmallIcon fontSize="small" />
          </IconButton>
        </Layout>
      ) : (
        <Layout adjustCenter adjustRight flex={1}>
          <IconButton onClick={onClose}>
            <CloseSmallIcon fontSize="small" />
          </IconButton>
        </Layout>
      )}
      <Spacer size={0.5} />
      {renderPopupSubtitle ? renderPopupSubtitle() : null}
      {items.map((item, idx) => (
        <PopupMenuItem
          key={idx}
          item={item}
          onSelect={handleSelect}
          onSetPreview={setPreviewItemId}
        />
      ))}
      {renderPopupFooter ? renderPopupFooter() : null}
    </Menu>
  );
}
