import React from 'react';
import {useLocalization} from 'localization';
import {useSnacks} from 'components';
import {useOverviewContext} from '../context/OverviewContext';
import {TaskTable} from './TaskTable';

export const Tasks: React.FC = () => {
  const {loadingFailed} = useOverviewContext();

  const {errorSnack} = useSnacks();
  const {t} = useLocalization();

  if (loadingFailed) {
    errorSnack(t('error.api.unknown'));
  }

  return <TaskTable />;
};
