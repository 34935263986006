import React, {useMemo} from 'react';
import {assert} from 'assert-ts';
import {LinkAction} from 'types';
import {Linking} from 'schema/types';
import {NameVariantValue} from 'schemaDefinition/types';
import {assertAgentSubType} from 'services/utils';
import {ActionButton} from 'components';
import {determineAgentLinkAction} from 'schema/preview/functions';
import {useLinkButtonIconAndTitle} from 'schema/preview/hooks';

type Props = {
  value: NameVariantValue;
  linkingConfiguration: Linking;
};

export const LinkMainFormButton: React.FC<Props> = ({
  value,
  linkingConfiguration,
}) => {
  const {linkAction, handleLinkAction} = useMemo((): {
    linkAction: LinkAction;
    handleLinkAction: () => Promise<void>;
  } => {
    const {entityId, entityType, nationalId, currentValue, link, unlink} =
      linkingConfiguration;

    const action = determineAgentLinkAction(
      currentValue,
      assert(
        entityId,
        'LinkMainFormButton: agentId expected',
        linkingConfiguration,
      ),
      value,
      'mainForm',
    );

    const handleClick =
      action === 'unlink'
        ? assert(
            unlink,
            'LinkMainFormButton: unlink expected',
            linkingConfiguration,
          )
        : () =>
            assert(
              link,
              'LinkMainFormButton: link expected',
              linkingConfiguration,
            )({
              linkStatus: 'verified',
              entityId: assert(
                entityId,
                'LinkMainFormButton: entityId expected',
              ),
              nationalId,
              type: assertAgentSubType(entityType),
              agentName: value,
            });
    return {
      linkAction: action,
      handleLinkAction: () => Promise.resolve(handleClick()),
    };
  }, [linkingConfiguration, value]);

  const {title, icon} = useLinkButtonIconAndTitle(linkAction);

  return <ActionButton title={title} icon={icon} onClick={handleLinkAction} />;
};
