import React, {useCallback, useMemo, useState} from 'react';
import {Autocomplete as MuiAutocomplete, Stack} from '@mui/material';
import {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import {CodeListId, CurrentFilterType} from 'api/types';
import {useCodelist} from 'services/codeLists';
import {TextField} from 'components';
import {AutocompleteOption} from '../../types';
import {canFreeSolo} from '../../functions/canFreeSolo';
import {mapCodelistToOptions} from '../../functions/mapCodelistToOptions';

type Props = {
  codelistId: CodeListId;
  filter: CurrentFilterType;
  onChange: (filter: CurrentFilterType) => void;
  onInputValueChange?: (value: string) => void;
};

export const FilterCodelistValue: React.FC<Props> = ({
  codelistId,
  filter,
  onChange,
  onInputValueChange,
}) => {
  const codelist = useCodelist(codelistId);

  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = useCallback(
    (_: unknown, newValue: string) => {
      setInputValue(newValue);
      if (onInputValueChange) {
        onInputValueChange(newValue);
      }
    },
    [onInputValueChange],
  );

  const freeSolo = useMemo(() => {
    return canFreeSolo(filter);
  }, [filter]);

  const handleOnChange = useCallback(
    (
      _: React.SyntheticEvent,
      value:
        | AutocompleteOption
        | (AutocompleteOption | string)[]
        | string
        | null,
    ) => {
      const values = (
        value === null ? [] : Array.isArray(value) ? value : [value]
      )
        .filter(v => !(!freeSolo && typeof v === 'string'))
        .map(v => (typeof v === 'string' ? v : v.id));

      onChange({
        ...filter,
        values,
      });
    },
    [filter, freeSolo, onChange],
  );

  const options: AutocompleteOption[] = useMemo(() => {
    return mapCodelistToOptions(codelist);
  }, [codelist]);

  const values: AutocompleteOption[] = useMemo(() => {
    return (
      filter.values?.map(f => ({
        id: f,
        label: options?.find(c => c.id === f)?.label ?? f,
      })) ?? []
    );
  }, [filter.values, options]);

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField type={'text'} name={filter.name} {...params} flex={1} />
    ),
    [filter.name],
  );

  const renderTags = useCallback(
    (
      value: Array<AutocompleteOption>,
      getTagProps: AutocompleteRenderGetTagProps,
    ) => {
      return (
        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          gap={0.5}
          width={'inherit'}
          overflow={'hidden'}>
          {value.map((option: AutocompleteOption, index: number) => (
            <Chip
              label={option.label ?? option.id}
              size={'small'}
              {...getTagProps({index})}
            />
          ))}
        </Stack>
      );
    },
    [],
  );

  return (
    <MuiAutocomplete
      multiple={true}
      freeSolo={freeSolo}
      value={values}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={options}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      getOptionLabel={v => (typeof v === 'string' ? v : v.label)}
      onChange={handleOnChange}
      filterOptions={onInputValueChange ? x => x : undefined} // Tells MUI not to filter internally if we provide onInputValueChange
      renderTags={renderTags}
      noOptionsText={'Ingen treff'}
      size={'small'}
      renderInput={renderInput}
    />
  );
};
