import {GrepDetails, GrepDetailsValue} from 'api/types';

/**
 * Parses a note string into a GrepDetails object, e.g.:
 * note: "Gyldig fra: 2015-08-01 00:00:00+00. Gyldig til: Høst 2008. Erstattes av:  DHFTG3----.'
 * parsed to:
 * {
 *   'Gyldig fra': {type: 'date', value: new Date('2015-08-01 00:00:00+00')},
 *   'Gyldig til': {type: 'text', value: 'Høst 2008'},
 *   'Erstattes av': {type: 'code', value: ['DHFTG3----']},
 * }
 */
export const parseNote = (note: string | undefined): GrepDetails => {
  if (!note) {
    return {};
  }

  const fields = note
    .split('.')
    .map(f => f.trim())
    .filter(f => f);
  const detailItems: GrepDetails[] = fields.map(f => {
    const [key, ...valueParts] = f.split(':');
    const valueStr = valueParts.join(':').trim();
    const value = parseValue(valueStr);
    return value ? {[key]: value} : {};
  });

  const details = detailItems.reduce((acc, item) => ({...acc, ...item}), {});

  return details;
};

const dateTimeRegex = /^\d{4}-\d{2}-\d{2}( \d{2}:\d{2}:\d{2}(.\d{2})?)?$/;
const codeRegex = /^[A-Z0-9]{2,8}[A-Z0-9-]*(,\s*[A-Z0-9]{2,8}[A-Z0-9-]*)*$/;

const parseValue = (valueStr: string): GrepDetailsValue | undefined => {
  if (valueStr === '<>') {
    return undefined;
  }
  if (dateTimeRegex.test(valueStr)) {
    return {
      type: 'date',
      value: new Date(valueStr),
    };
  }

  if (codeRegex.test(valueStr)) {
    const codes = valueStr.split(',').map(c => c.trim());
    return {
      type: 'code',
      value: codes,
    };
  }

  return {
    type: 'text',
    value: valueStr,
  };
};
