import React from 'react';
import {
  RestrictedCodelistsContext,
  useRestrictedCodelistsContextProviderValue,
} from 'services/codeLists';
import {useManifestationCodelistRestrictor} from 'schemas';

type Props = {
  expressionId: string;
  manifestationId: string;
};

export const ManifestationRestrictedCodelists: React.FC<
  React.PropsWithChildren<Props>
> = ({expressionId, manifestationId, children}) => {
  const codelistRestrictor = useManifestationCodelistRestrictor(
    manifestationId,
    expressionId,
  );

  const restrictedCodelistsContextValue =
    useRestrictedCodelistsContextProviderValue(codelistRestrictor);

  return (
    <RestrictedCodelistsContext.Provider
      value={restrictedCodelistsContextValue}>
      {children}
    </RestrictedCodelistsContext.Provider>
  );
};
