import {PreviewConfiguration} from 'schema/types';

export const defaultPreviewConfig: PreviewConfiguration = {
  nameVariant: {
    excludeMainForm: false,
  },
  layout: {
    groups: {},
  },
};
