import {PropsWithChildren} from 'react';

export type FCWithChildren<TProps = unknown> = React.FC<
  PropsWithChildren<TProps>
>;

export type JSONValue =
  | string
  | number
  | boolean
  | {[x: string]: JSONValue}
  | Array<JSONValue>;

/**
 * Generic key/value map
 */
export type Indexer<TValue = unknown> = Record<string, TValue>;

export type NoProps = Record<string, never>;

export type LinkAction = 'link' | 'unlink' | 'changeLink' | 'move' | 'copy';

export type ThesaurusAction = 'set';

/**
 * All entity types, both abtract and concrete, but not "derived"
 * entities based on property values, e.g. "samleverk" or "læreverk"
 */

export enum Concept {
  // Entity = 'entity',
  changeRequest = 'changeRequest',
  work = 'work',
  expression = 'expression',
  manifestation = 'manifestation',
  /**
   * Collection, abstract.
   * Sub-categories (same representation, but different props/roles):
   * - series (numbered/unnumbered),
   * - educationalSeries (læreverk),
   * - publisherSeries (forlagsserie),
   * - otherCollection (annen tilknytning)
   */
  collection = 'collection',
  series = 'series',
  educationalSeries = 'educationalSeries',
  /** Annen tilknytning */
  otherConnection = 'otherConnection',
  /** I.e. forlagsserie */
  publisherSeries = 'publisherSeries',

  /**
   * Agent, abstract.
   * Sub-types (different representations):
   * - person,
   * - corporation,
   * - event,
   * - publisher
   */
  agent = 'agent',
  person = 'person',
  corporation = 'corporation',
  event = 'event',
  publisher = 'publisher',
}

export enum DerivedConcept {
  /** Work with aggregated properties from expressions and manifestations */
  workSummary = 'workSummary',
}

export type EntityMainType =
  | Concept.changeRequest
  | Concept.work
  | Concept.expression
  | Concept.manifestation
  | Concept.collection
  | Concept.agent;

export type AgentSubType =
  | Concept.person
  | Concept.corporation
  | Concept.event
  | Concept.publisher;

export const AgentSubTypes = [
  Concept.person,
  Concept.corporation,
  Concept.event,
  Concept.publisher,
];

export type CollectionSubType =
  | Concept.series
  | Concept.educationalSeries
  | Concept.otherConnection
  | Concept.publisherSeries;

export const CollectionSubTypes = [
  Concept.series,
  Concept.educationalSeries,
  Concept.otherConnection,
  Concept.publisherSeries,
];

export type EntitySubType = AgentSubType | CollectionSubType;

// Type verification: All concepts should be main or sub type
// - if not, gives type error for t if
const c: Concept = Concept.changeRequest;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const t: EntityMainType | EntitySubType = c;

/**
 * Distinct types of entities that can be linked with link specific properties, i.e.
 * link between work and series may have part number.
 */
export type LinkEntityType =
  | Concept.work
  | Concept.expression
  | Concept.manifestation
  | Concept.agent
  // Specific types for collections, since series may be linked with part number
  | Concept.series
  | Concept.educationalSeries
  | Concept.otherConnection
  | Concept.publisherSeries
  // Specific types for agents, since corporation may be related to other corporation
  | Concept.person
  | Concept.corporation
  | Concept.event
  | Concept.publisher;

export type LinkTargetMainEntityType = Extract<
  EntityMainType,
  Concept.work | Concept.agent | Concept.collection
>;

export const LinkTargetMainEntityTypes: EntityMainType[] = [
  Concept.work,
  Concept.agent,
  Concept.collection,
];

export type LinkTargetSubEntityType = EntitySubType;

export const LinkTargetEntityTypes: LinkEntityType[] = [
  Concept.work,
  Concept.manifestation,
  Concept.agent,
  Concept.series,
  Concept.educationalSeries,
  Concept.otherConnection,
  Concept.publisherSeries,
];

export type LinkTargetCatalogPostType = Extract<
  LinkEntityType,
  | Concept.work
  // | Concept.expression
  | Concept.manifestation
  | Concept.series
  | Concept.educationalSeries
  | Concept.otherConnection
  | Concept.publisherSeries
>;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const LinkTargetCatalogPostTypes: Readonly<LinkTargetCatalogPostType[]> =
  [
    Concept.work,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    Concept.expression,
    Concept.manifestation,
    Concept.series,
    Concept.educationalSeries,
    Concept.otherConnection,
    Concept.publisherSeries,
  ] as const;

/**
 * @deprecated
 */
export type EntityType =
  | 'agent'
  | 'work'
  | 'expression'
  | 'manifestation'
  | 'series';

export const EntityMainTypes: EntityMainType[] = [
  Concept.changeRequest,
  Concept.agent,
  Concept.work,
  Concept.expression,
  Concept.manifestation,
  Concept.collection,
];

export const EntitySubTypes: {[type in EntityMainType]?: EntitySubType[]} = {
  agent: [
    Concept.person,
    Concept.corporation,
    Concept.event,
    Concept.publisher,
  ],
  collection: [
    Concept.series,
    Concept.educationalSeries,
    Concept.otherConnection,
    Concept.publisherSeries,
  ],
};

/**
 * Concrete entities with schemas for edit, preview and table view.
 */
export type ConcreteEntityType =
  | Concept.work
  | Concept.expression
  | Concept.manifestation
  | EntitySubType;

/**
 * Concrete link entities that may be instantiated as target (opposite) entity, e.g.
 * not including abstract agent but concrete types person, event, etc.
 */
export type LinkTargetConcreteEntityType = Exclude<
  ConcreteEntityType,
  Concept.expression
>;

export const LinkTargetConcreteEntityTypes: LinkTargetConcreteEntityType[] = [
  Concept.work,
  ...(EntitySubTypes.agent || []),
  ...(EntitySubTypes.collection || []),
];

export type ManifestationDesignator = {type: 'id' | 'ean' | 'isbn'; id: string};

export type ReviewStatusType = 'approved' | 'rejected' | 'corrected' | 'none';

export const ReviewStatuses: ReviewStatusType[] = [
  'approved',
  'rejected',
  'corrected',
  'none',
];

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? DeepPartial<U>[]
    : T[P] extends object
      ? DeepPartial<T[P]>
      : T[P];
};

/**
 * E.g.:
 * const a = {
 *         b: {
 *           c: 'test',
 *         },
 *         d: 'test2',
 *       } as const;
 * type A = NestedKey<typeof a>;
 * const ka: A = 'b.c';
 */
export type NestedKey<T, TPath extends string = ''> =
  T extends Record<infer TKey, unknown>
    ? TKey extends string
      ? TPath extends ''
        ? NestedKey<T[TKey], TKey>
        : NestedKey<T[TKey], `${TPath}.${TKey}`>
      : TPath
    : TPath;
