import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Sorting} from 'components/dataTable/types';
import {AppState} from 'store/types';
import {SettingsAction} from 'services/settings';

const sortingSelector = (state: AppState) => state.settings.agents.sorting;

export const useAgentsSorting = () => {
  const dispatch = useDispatch();
  const sorting = useSelector(sortingSelector);

  const setSorting = useCallback(
    (sorting?: Sorting) => {
      const action: SettingsAction = {
        type: 'SET_AGENTS_SORTING',
        payload: sorting,
      };
      dispatch(action);
    },
    [dispatch],
  );

  return {
    sorting,
    setSorting,
  };
};
