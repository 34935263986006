import {ManifestationV4} from '../types';
import {Schemas} from '../dto.generated';
import {sortByOrder} from './sortByOrder';

export const mapTitleInfoDto = (
  titleInfo: Schemas.TitleInfoDto | undefined | null,
): {
  mainTitles: ManifestationV4['mainTitles'];
  otherTitles: ManifestationV4['otherTitles'];
} => {
  const {mainTitles = [], otherTitles = []} = titleInfo || {};

  return {
    mainTitles: sortByOrder(mainTitles ?? []).map(({order: _, ...mT}) => ({
      ...mT,
      parallelTitles: sortByOrder(mT.parallelTitles ?? []).map(pt => pt.value),
      subTitles: sortByOrder(mT.subTitles ?? []).map(({order: _, ...sT}) => ({
        ...sT,
        parallelTitles: sortByOrder(sT.parallelTitles ?? []).map(
          pt => pt.value,
        ),
      })),
    })),
    otherTitles: sortByOrder(otherTitles ?? []).map(({order: _, ...oT}) => oT),
  };
};

export const mapToTitleInfoDto = (
  mainTitles: ManifestationV4['mainTitles'] | undefined,
  otherTitles: ManifestationV4['otherTitles'] | undefined,
): Schemas.TitleInfoDto => ({
  mainTitles:
    (mainTitles ?? []).map((mT, mI) => ({
      ...mT,
      order: mI,
      parallelTitles: (mT.parallelTitles || []).map((pT, pI) => ({
        value: pT,
        order: pI,
      })),
      subTitles: (mT.subTitles || []).map((sT, sI) => ({
        ...sT,
        order: sI,
        parallelTitles: (sT.parallelTitles || []).map((psT, psI) => ({
          value: psT,
          order: psI,
        })),
      })),
    })) || [],
  otherTitles: (otherTitles || []).map((oT, oI) => ({
    ...oT,
    order: oI,
  })),
});
