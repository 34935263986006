import {HttpArgs} from './httpMethod';
import {httpWithMock} from './httpWithMock';

type Args<TBody> = Pick<
  HttpArgs<TBody>,
  | 'subdir'
  | 'subdirParams'
  | 'queryParams'
  | 'extraHeaders'
  | 'body'
  | 'getTokens'
> & {mock?: boolean};

export const httpGet = <TResponse, TBody = undefined>(args: Args<TBody>) =>
  httpWithMock<TResponse, TBody>({method: 'GET', ...args});

export const httpPost = <TResponse, TBody = TResponse>(args: Args<TBody>) =>
  httpWithMock<TResponse, TBody>({method: 'POST', ...args});

export const httpPatch = <TResponse, TBody = TResponse>(args: Args<TBody>) =>
  httpWithMock<TResponse, TBody>({method: 'PATCH', ...args});

export const httpPut = <TResponse, TBody = TResponse>(args: Args<TBody>) =>
  httpWithMock<TResponse, TBody>({method: 'PUT', ...args});

export const httpDelete = (args: Args<unknown>) =>
  httpWithMock<void, unknown>({method: 'DELETE', ...args});
