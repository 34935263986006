import {Agent, NationalAgent} from 'api/types';
import {SchemaModifier} from 'schemaDefinition/types';
import {nationIdSchemaModifier} from '../schemas';

export const getAgentSchemaModifiers = (
  agent: Agent | undefined,
): SchemaModifier[] => {
  const modifiers: SchemaModifier[] = [];
  if (!agent) {
    return modifiers;
  }

  if ((agent as NationalAgent).nationalId) {
    modifiers.push(nationIdSchemaModifier);
  }

  return modifiers;
};
