import {useCallback, useEffect, useMemo, useState} from 'react';
import {ColumnSet} from 'api/types';
import {LoadStatus} from 'api';
import {
  deleteColumnSet,
  getColumnSets,
  postColumnSet,
  putColumnSet,
} from 'api/advancedsearch';
import {useGetTokens} from 'services/auth';
import {ColumnSetsType} from '../types';

export const useColumnSets = (): ColumnSetsType => {
  const getTokens = useGetTokens();
  const [columnSets, setColumnSets] = useState<ColumnSet[] | undefined>();

  const status: LoadStatus = useMemo(() => {
    return columnSets === undefined ? 'Loading' : 'Loaded';
  }, [columnSets]);

  const loadColumnSets = useCallback(() => {
    getColumnSets(getTokens).then(r => setColumnSets(r));
  }, [getTokens]);

  const createColumnSet = useCallback(
    (name: string, columns: string[], isPublic: boolean) => {
      setColumnSets(undefined);
      return postColumnSet(getTokens, name, columns, isPublic).then(
        newColumnSet => {
          loadColumnSets();
          return newColumnSet;
        },
      );
    },
    [getTokens, loadColumnSets],
  );

  const editColumnSet = useCallback(
    (id: string, name: string, columns: string[], isPublic: boolean) => {
      setColumnSets(undefined);
      return putColumnSet(getTokens, id, name, columns, isPublic).then(
        updatedColumnSet => {
          loadColumnSets();
          return updatedColumnSet;
        },
      );
    },
    [getTokens, loadColumnSets],
  );

  const handleDeleteColumnSet = useCallback(
    (id: string) => {
      setColumnSets(undefined);
      return deleteColumnSet(getTokens, id).then(() => {
        loadColumnSets();
      });
    },
    [getTokens, loadColumnSets],
  );

  useEffect(() => {
    loadColumnSets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => {
    return {
      columnSets: columnSets ?? [],
      statusColumnSets: status,
      createColumnSet,
      editColumnSet,
      deleteColumnSet: handleDeleteColumnSet,
    };
  }, [
    columnSets,
    createColumnSet,
    editColumnSet,
    handleDeleteColumnSet,
    status,
  ]);
};
