import {assert} from 'assert-ts';
import {
  DataFormValueType,
  PartInt,
  PartLinkedLiterary,
  PartText,
  Valx,
} from 'schemaDefinition/types';
import {LinkedRoleCode} from 'schemaDefinition/linkTypes';

export const getLinkPropPartFromLinkedLiterary = (
  partLinkedLiterary: PartLinkedLiterary<Valx>,
  linkedRoleCode: LinkedRoleCode,
): PartInt<DataFormValueType> | PartText<DataFormValueType> | undefined => {
  return {
    type: assert(
      linkedRoleCode.linkProperty?.type,
      'getLinkPropPartFromLinkedLiterary: type expected',
    ),
    name: assert(
      linkedRoleCode.linkProperty?.name,
      'getLinkPropPartFromLinkedLiterary: linkProperty.name expected',
      linkedRoleCode,
    ),
    required: partLinkedLiterary.linkPropRequired,
  };
};
