import React from 'react';
import {AvailableFilter, CurrentFilterType} from 'api/types';
import {FilterBooleanValue} from './FilterBooleanValue';
import {FilterCodelistValue} from './FilterCodelistValue';
import {FilterDateRangeValue} from './FilterDateRangeValue';
import {FilterDateValue} from './FilterDateValue';
import {FilterTextValue} from './FilterTextValue';
import {FilterThemaValue} from './FilterThemaValue';

type Props = {
  filter: CurrentFilterType;
  onChange: (filter: CurrentFilterType) => void;
  onSubmit?: () => void;
  codelistId?: AvailableFilter['codelistId'];
  type?: AvailableFilter['type'];
};

export const RenderFilterValue: React.FC<Props> = ({
  filter,
  onChange,
  onSubmit,
  codelistId,
  type,
}) => {
  if (filter.operation === 'NULL' || filter.operation === 'NOT_NULL') {
    return null;
  }

  if (
    filter.name === 'themas' &&
    (filter.operation === 'EQUAL' || filter.operation === 'NOT_EQUAL')
  ) {
    return <FilterThemaValue filter={filter} onChange={onChange} />;
  }

  if (codelistId) {
    return (
      <FilterCodelistValue
        codelistId={codelistId}
        filter={filter}
        onChange={onChange}
      />
    );
  }

  if (type === 'DATE' && filter.operation === 'BETWEEN') {
    return (
      <FilterDateRangeValue
        filter={filter}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    );
  }

  if (type === 'DATE') {
    return (
      <FilterDateValue
        filter={filter}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    );
  }

  if (type === 'BOOLEAN' && filter.operation === 'EQUAL') {
    return <FilterBooleanValue filter={filter} onChange={onChange} />;
  }

  const customDelimiters = filter.name === 'ean' ? [' ', ','] : undefined;

  return (
    <FilterTextValue
      filter={filter}
      onChange={onChange}
      onSubmit={onSubmit}
      customDelimiters={customDelimiters}
    />
  );
};
