import {GridColType} from '@mui/x-data-grid-pro';
import {assert} from 'assert-ts';

export const mapType = (schemaPartType?: string): GridColType => {
  assert(
    schemaPartType !== undefined,
    'mapPartToColumns missing schemaPartType',
  );

  switch (schemaPartType) {
    case 'int':
    case 'year':
      return 'number';
    case 'text':
      return 'string';
    case 'date':
      return 'date';
    case 'codelist':
    case 'codelist|text':
      return 'singleSelect';
    default:
      return 'string';
  }
};
