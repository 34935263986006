import {differenceWith} from 'lodash';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {Filter} from 'api/types';

/**
 * Compares if sets of filters are equal.
 *
 * TODO: Equal values in different order will result in false.
 */
export const compareFilters = (
  filtersA: Filter[],
  filtersB: Filter[],
): boolean => {
  if (!filtersA.length || !filtersB.length) {
    return false;
  }

  const cleanA = filtersA.map(f => ({
    ...f,
    id: undefined,
  }));
  const cleanB = filtersB.map(f => ({
    ...f,
    id: undefined,
  }));

  return (
    isEmpty(differenceWith(cleanA, cleanB, isEqual)) &&
    isEmpty(differenceWith(cleanB, cleanA, isEqual))
  );
};
