import assert from 'assert-ts';
import {
  ExternalSuggestions,
  FullManifestation,
  GetTokens,
  ManifestationV4,
  WorkV4,
} from './types';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpDelete, httpGet, httpPost, httpPut} from './http/ebba';
import {
  mapManifestationBaseDto,
  mapManifestationsDto,
  mapToManifestationBaseDto,
  mapWorkBaseDto,
} from './mappers';

export const getManifestation = (
  id: string,
  getTokens: GetTokens,
): Promise<FullManifestation> => {
  /** Use post to allow getting many manifestations at once, @see {@link getManifestations} */
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.ManifestationsDto, string[]>({
      subdir: 'manifestation/v2/id',
      body: [id],
      getTokens,
    })
      .then(mapManifestationsDto)
      .then(manifestations => {
        return manifestations[0];
      }),
  );
};

export const getManifestations = (
  ids: string[],
  getTokens: GetTokens,
): Promise<FullManifestation[]> => {
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.ManifestationsDto, string[]>({
      subdir: 'manifestation/v2/id',
      body: ids,
      getTokens,
    }).then(mapManifestationsDto),
  );
};

export const getManifestationByEan = (
  ean: string,
  getTokens: GetTokens,
): Promise<FullManifestation> => {
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.ManifestationsDto, string[]>({
      subdir: 'manifestation/v2/ean',
      body: [ean],
      getTokens,
    })
      .then(mapManifestationsDto)
      .then(manifestations => {
        return manifestations[0];
      }),
  );
};

export const getManifestationByIsbn = (
  isbn: string,
  getTokens: GetTokens,
): Promise<FullManifestation> => {
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.ManifestationsDto, string[]>({
      subdir: 'manifestation/v2/isbn',
      body: [isbn],
      getTokens,
    })
      .then(mapManifestationsDto)
      .then(manifestations => {
        return manifestations[0];
      }),
  );
};

export const putManifestation = (
  manifestation: ManifestationV4,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<{
  manifestation: ManifestationV4;
  work: WorkV4;
}> => {
  return exceptionToPromiseReject(() =>
    httpPut<Schemas.ManifestationWithExpressionDto>({
      subdir: 'manifestation/v2/{id}',
      subdirParams: {id: manifestation.id},
      body: mapToManifestationBaseDto(manifestation),
      getTokens,
      mock,
    }).then(({expression, ...manifestation}) => {
      return {
        manifestation: mapManifestationBaseDto(manifestation),
        work: mapWorkBaseDto(
          assert(
            expression?.work,
            'putManifestation: expected expression.work',
            expression,
          ),
        ),
      };
    }),
  );
};

export const moveManifestationToExistingWork = (
  manifestationId: string,
  targetWorkId: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<void> => {
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.MoveManifestationToExistingWorkDto>({
      subdir: 'manifestation/{manifestationId}/moveToExistingWork',
      subdirParams: {manifestationId},
      body: {workId: targetWorkId},
      mock: !!mock,
      getTokens,
    }).then(() => {}),
  );
};

export const moveManifestationToDuplicategWork = (
  manifestationId: string,
  fromWorkId: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<string> => {
  return exceptionToPromiseReject(() =>
    httpPost<{workId: string}>({
      subdir: 'manifestation/{manifestationId}/moveToDuplicateWork',
      subdirParams: {manifestationId},
      body: {workId: fromWorkId},
      mock: !!mock,
      getTokens,
    }).then(({workId}) => workId),
  );
};

export const getManifestationExternalSuggestions = (
  manifestationId: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<ExternalSuggestions> => {
  return exceptionToPromiseReject(() =>
    httpGet<Schemas.ExternalSuggestionsDto>({
      subdir: 'manifestation/{manifestationId}/externalSuggestions',
      subdirParams: {manifestationId},
      mock: !!mock,
      getTokens,
    }),
  );
};

export const deleteManifestation = (
  manifestationId: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<void> => {
  return exceptionToPromiseReject(() =>
    httpDelete({
      subdir: 'manifestation/v2/{manifestationId}',
      subdirParams: {manifestationId},
      mock: !!mock,
      getTokens,
    }),
  );
};

/**
 * Triggered when changerequest is revoked, because backend (Meta) needs us to trigger a outbox update anyway.
 * https://bokbasen.jira.com/browse/BB-6488
 */
export const postManifestationOutboxTrigger = (
  manifestationId: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<void> => {
  return exceptionToPromiseReject(() => {
    return httpPost<void>({
      subdir: 'manifestation/v2/{manifestationId}/outbox',
      subdirParams: {manifestationId},
      mock: !!mock,
      getTokens,
    });
  });
};

export const postManifestationPhysicalContentReceived = (
  ean: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<Schemas.PhysicalContentReceivedResponse> => {
  return exceptionToPromiseReject(() => {
    return httpPost<Schemas.PhysicalContentReceivedResponse>({
      subdir: 'manifestation/{ean}/physicalcontentreceived',
      subdirParams: {ean},
      mock: !!mock,
      getTokens,
    });
  });
};
