import {Dispatch} from 'redux';
import {GetTokens, WorkMetadata, getWork} from 'api';
import {makeLoadActionFactories} from 'services/utils';
import {MetadataAction, MetadataActionType} from '../metadataActionTypes';

const {makeRequestAction, makeSuccessAction, makeFailureAction} =
  makeLoadActionFactories<WorkMetadata, MetadataActionType.LOAD_WORK>(
    MetadataActionType.LOAD_WORK,
  );

export const loadWorkAction = (
  workId: string,
  getTokens: GetTokens,
  mock?: boolean,
) => {
  return (dispatch: Dispatch<MetadataAction>) => {
    dispatch(makeRequestAction());

    getWork(workId, getTokens, mock)
      .then(data => {
        dispatch(makeSuccessAction(data));
      })
      .catch(error => {
        dispatch(makeFailureAction(error));
      });
  };
};
