import {assert} from 'assert-ts';
import {AgentSubType} from 'types';
import {EditorMode} from 'editors/agent/types';
import {useLocalization} from 'localization';
import {AgentState} from 'services/data/agent/reducer/agentReducer';
import {formatNameVariantValue} from 'schema/preview/functions';

export const useAgentTitle = (
  mode: EditorMode,
  agent: AgentState | undefined,
  agentType: AgentSubType | undefined,
) => {
  const {t} = useLocalization();
  if (mode === 'register') {
    return t(
      `wizard.agent.${assert(
        agentType,
        'useAgentTitle: type required',
      )}.register.title`,
    );
  }

  const a = assert(agent, 'useAgentTitle: agent is required when mode is edit');
  return a.status === 'Loaded'
    ? formatNameVariantValue(
        a.data.nameVariants[0],
        a.data.agentType,
        'excludeId',
      )
    : '';
};
