import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const StarSmallIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12 8.90989L12.68 10.2899L13.14 11.2299L14.14 11.3799L15.66 11.5999L14.56 12.6699L13.81 13.3999L13.99 14.3999L14.29 15.9999L12.93 15.2899L12 14.7499L11.07 15.2399L9.71 15.9999L10 14.4399L10.18 13.4399L9.43 12.7099L8.3 11.5999L9.82 11.3799L10.82 11.2299L11.28 10.2899L12 8.90989ZM12 4.38989L9.53 9.38989L4 10.1999L8 14.0999L7.06 19.6099L12 16.9999L16.94 19.5999L16 14.0999L20 10.1999L14.47 9.39989L12 4.38989Z" />
    </SvgIcon>
  );
};
