import isEqual from 'lodash/isEqual';
import {FilterModel} from '../types';

const filterModelWithoutIds = (filterModel: FilterModel): FilterModel => {
  return {
    ...filterModel,
    items: filterModel.items
      .map(j => {
        return {
          ...j,
          id: undefined,
        };
      })
      .sort(),
  };
};

export const compareFilterModels = (
  a: FilterModel,
  b?: FilterModel,
): boolean => {
  if (!b) {
    return false;
  }

  return isEqual(filterModelWithoutIds(a), filterModelWithoutIds(b));
};
