import {SxProps} from '@mui/system';
import {ColorPalette, px2rem} from 'theme';
import {Layout, Spacer, Text} from 'components';
import {UnstyledButton} from 'components/button/UnstyledButton';

type Props = {
  title: string;
  count: number;
  onClick: () => void;
};

const sxButtonSome: SxProps = {
  px: 2,
  background: ColorPalette.beige,
  borderRadius: 8,
  height: px2rem(32),
};

const sxButtonNone: SxProps = {
  ...sxButtonSome,
  background: ColorPalette.beige50,
};

const sxTitleNone: SxProps = {
  color: ColorPalette.warmGreen,
};

const sxCountNone: SxProps = {
  fontWeight: 'bold',
  color: ColorPalette.warmGreen,
};

const sxCountSome: SxProps = {
  fontWeight: 'bold',
};

const Content: React.FC<{title: string; count: number}> = ({title, count}) => (
  <Layout horizontal adjustCenter sx={count ? sxButtonSome : sxButtonNone}>
    <Text variant={'body1'} sx={count ? undefined : sxTitleNone}>
      {title}
    </Text>
    <Spacer size={1} />
    <Text variant={'body1'} sx={count ? sxCountSome : sxCountNone}>
      {count || '-'}
    </Text>
  </Layout>
);

export const SearchCategoryButton: React.FC<Props> = ({
  title,
  count,
  onClick,
}) => {
  return count > 0 ? (
    <UnstyledButton onClick={onClick}>
      <Content title={title} count={count} />
    </UnstyledButton>
  ) : (
    <Content title={title} count={count} />
  );
};
