import {ExternalSuggestions} from 'api/types';

export const countExternalSuggestions = (
  externalSuggestions: ExternalSuggestions,
): number => {
  return (
    (externalSuggestions.intellectualLevel?.length || 0) +
    (externalSuggestions.seriesTitles?.length || 0) +
    (externalSuggestions.seriesNumbers?.length || 0)
  );
};
