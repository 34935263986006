import {assert} from 'assert-ts';
import {Data, DataValue} from 'schemaDefinition/types';
import {isData} from 'schemaDefinition';
import {ValidationArgs} from './types';

export const assertDataValidationArgs = (
  args: ValidationArgs<Data | DataValue>,
): ValidationArgs<Data> => {
  if (isData(args.value)) {
    return args as ValidationArgs<Data>;
  }

  assert.soft(
    false,
    `assertIsDataValidationArgs: ValidationArgs value must be Data`,
    args,
  );

  return {
    ...args,
    value: {},
  };
};
