import {PartValueRenderer} from 'schema/types';
import {CollectionTitle} from 'api';
import {useCodelist} from 'services/codeLists';
import {isNullish} from 'services/utils';
import {FlexBox, TextMatch} from 'components';

export const TableCollectionTitles: PartValueRenderer = ({value, match}) => {
  const codelist = useCodelist('LANGUAGE');

  return isNullish(value) ||
    !Array.isArray(value) ||
    value.length === 0 ? null : (
    <FlexBox>
      {value.map((v, i) => {
        const title = v as CollectionTitle;
        return (
          <TextMatch key={i} match={match} variant={'body2'}>
            {`${title.value} (${
              codelist.codes.find(c => c.code === title.language)?.value ??
              title.language
            })`}
          </TextMatch>
        );
      })}
    </FlexBox>
  );
};
