import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Autocomplete as MuiAutocomplete, Stack} from '@mui/material';
import {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import compact from 'lodash/compact';
import debounce from 'lodash/debounce';
import {CurrentFilterType} from 'api/types';
import {useThesaurus} from 'services/thesaurus';
import {TextField} from 'components';
import {AutocompleteOption} from '../../types';
import {canFreeSolo, findThesaurusNode, searchThesaurus} from '../../functions';

type Props = {
  filter: CurrentFilterType;
  onChange: (filter: CurrentFilterType) => void;
};

export const FilterThemaValue: React.FC<Props> = ({filter, onChange}) => {
  const thesaurus = useThesaurus('thema');

  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = useCallback((_: unknown, newValue: string) => {
    setInputValue(newValue);
  }, []);

  const values: AutocompleteOption[] = useMemo(() => {
    const nodes = (filter.values ?? []).map(v =>
      findThesaurusNode(v, thesaurus),
    );
    return compact(nodes ?? []).map(node => ({
      ...node,
      id: node.code, // Use code as id
    }));
  }, [filter.values, thesaurus]);

  const [options, setOptions] = React.useState<AutocompleteOption[]>([]);

  const freeSolo = useMemo(() => {
    return canFreeSolo(filter);
  }, [filter]);

  const handleOnChange = useCallback(
    (
      _: React.SyntheticEvent,
      value:
        | AutocompleteOption
        | (AutocompleteOption | string)[]
        | string
        | null,
    ) => {
      const values = (
        value === null ? [] : Array.isArray(value) ? value : [value]
      )
        .filter(v => !(!freeSolo && typeof v === 'string'))
        .map(v => (typeof v === 'string' ? v : v.id));

      onChange({
        ...filter,
        values,
      });
    },
    [filter, freeSolo, onChange],
  );

  const fetch = useMemo(
    () =>
      debounce(
        (input: string, callback: (results: AutocompleteOption[]) => void) => {
          callback(searchThesaurus(input, thesaurus));
        },
        200,
      ),
    [thesaurus],
  );

  useEffect(() => {
    if (inputValue.length > 0) {
      fetch(inputValue, (options: AutocompleteOption[]) => {
        setOptions(options);
      });
    }
  }, [inputValue, fetch]);

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField type={'text'} name={filter.name} {...params} flex={1} />
    ),
    [filter.name],
  );

  const renderTags = useCallback(
    (
      value: Array<AutocompleteOption>,
      getTagProps: AutocompleteRenderGetTagProps,
    ) => {
      return (
        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          gap={0.5}
          width={'inherit'}
          overflow={'hidden'}>
          {value.map((option: AutocompleteOption, index: number) => (
            <Chip label={option.id} size={'small'} {...getTagProps({index})} />
          ))}
        </Stack>
      );
    },
    [],
  );

  return (
    <MuiAutocomplete
      multiple={true}
      freeSolo={freeSolo}
      value={values}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={options}
      getOptionLabel={v => (typeof v === 'string' ? v : `${v.id}: ${v.label}`)}
      onChange={handleOnChange}
      filterOptions={x => x} // Tells MUI not to filter internally
      noOptionsText={'Ingen treff'}
      isOptionEqualToValue={(o1, o2) => o1.id === o2.id}
      size={'small'}
      renderTags={renderTags}
      renderInput={renderInput}
    />
  );
};
