import {assert} from 'assert-ts';
import {LinkedAgentMultiRole} from 'schemaDefinition/types';

export const assertAsLinkedValue = (
  value: unknown,
  context?: string,
): LinkedAgentMultiRole | undefined => {
  if (value === undefined || value === null) return undefined;

  if (typeof value === 'object' && !Array.isArray(value)) {
    return value as LinkedAgentMultiRole;
  }

  assert.soft(
    false,
    `assertAsLinkedValue: linked value expected (${context ?? ''})`,
    {value},
  );

  return undefined;
};
