import {Metadata} from 'services/data/metadata/types';

export function getExpressionManifestationIds(
  data: Metadata,
  expressionId: string,
): string[] {
  return data.manifestations
    .filter(m => m.expressionId === expressionId)
    .map(m => m.id);
}
