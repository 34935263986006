import React from 'react';
import {Provider} from 'react-redux';
import {Store} from 'redux';
import {PersistGate} from 'redux-persist/integration/react';
import {Persistor} from 'redux-persist/es/types';
import {AuthGate} from './AuthGate';
import {ThemedApp} from './ThemedApp';

type Props = {
  store: Store;
  persistor: Persistor;
};

export const AppContainer: React.FC<Props> = ({store, persistor}) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AuthGate>
          <ThemedApp />
        </AuthGate>
      </PersistGate>
    </Provider>
  );
};
