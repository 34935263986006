import {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {GridColDef} from '@mui/x-data-grid-pro';
import {AgentTypeAll} from 'services/settings/types';
import {AppState} from 'store/types';
import {useLocalization} from 'localization';
import {SettingsAction} from 'services/settings/actions';

export type ColumnName = 'type' | 'name';

export type ColumnProp = {
  key: string;
  title: string;
  checked: boolean;
};

type ColumnProps = {
  [key in ColumnName]: ColumnProp;
};

export type SetColumnVisibility = (
  type: AgentTypeAll,
  name: string,
  checked: boolean,
) => void;

export type SetColumnsOrder = (columnsOrder: ColumnName[]) => void;

const columnsVisibilitySelector = (state: AppState) =>
  state.settings.agents.columnsVisibility;

const columnsOrderSelector = (state: AppState) =>
  state.settings.agents.columnsOrder;

export type useAgentListColumnsType = {
  columns: GridColDef[];
  columnsVisibility: ColumnProp[];
  setColumnVisibility: SetColumnVisibility;
  setColumnsOrder: SetColumnsOrder;
};

export const useAgentListColumns = (
  selectedAgentType: AgentTypeAll,
): useAgentListColumnsType => {
  const dispatch = useDispatch();
  const {t} = useLocalization();

  const columnTitles = useMemo(
    () => ({
      type: t('page.agents.list.header.type'),
      name: t('page.agents.list.header.name'),
    }),
    [t],
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {field: 'id', hide: true},
      {
        field: 'type',
        headerName: columnTitles.type,
        flex: 1,
      },
      {
        field: 'name',
        headerName: columnTitles.name,
        flex: 1,
      },
    ],
    [columnTitles],
  );

  const columnsVisibility = useSelector(columnsVisibilitySelector);

  const setColumnVisibility = useCallback(
    (selectedAgentType: AgentTypeAll, name: string, checked: boolean) => {
      const selectedColumns = {
        ...(columnsVisibility[selectedAgentType] ?? []),
        ...{[name]: checked},
      };

      const action: SettingsAction = {
        type: 'SET_AGENTS_COLUMN_VISIBILITY',
        payload: {
          ...columnsVisibility,
          [selectedAgentType]: selectedColumns,
        },
      };
      dispatch(action);
    },
    [dispatch, columnsVisibility],
  );

  const columnProps = useMemo((): ColumnProps => {
    const selectedType = columnsVisibility[selectedAgentType];

    return {
      type: {
        key: 'type',
        title: t('page.agents.list.header.type'),
        checked: (selectedType && selectedType['type']) ?? true,
      },
      name: {
        key: 'name',
        title: t('page.agents.list.header.name'),
        checked: (selectedType && selectedType['dateForSale']) ?? true,
      },
    };
  }, [columnsVisibility, t, selectedAgentType]);

  /**
   * @Todo Must use columnVisibilityModel instead

    const columnsWithUpdatedVisibility = useMemo(
      () =>
        columns.map(c =>
          c.field === 'id' ||
          !!c.hide === !columnProps[c.field as ColumnName].checked
            ? c
            : {...c, hide: !c.hide},
        ),
      [columnProps, columns],
    );
   */
  const columnsWithUpdatedVisibility = columns;

  const columnsOrders = useSelector(columnsOrderSelector);

  const setColumnsOrder = useCallback(
    (columnsOrder: ColumnName[]) => {
      const action: SettingsAction = {
        type: 'SET_AGENTS_COLUMN_ORDER',
        payload: {
          ...columnsOrders,
          [selectedAgentType]: columnsOrder,
        },
      };

      dispatch(action);
    },
    [dispatch, columnsOrders, selectedAgentType],
  );

  const columnsWithUpdatedOrder = useMemo(() => {
    const columnsOrder = columnsOrders[selectedAgentType];

    if (!Array.isArray(columnsOrder)) {
      return columnsWithUpdatedVisibility;
    }

    return columnsWithUpdatedVisibility.sort((a: GridColDef, b: GridColDef) => {
      if (
        columnsOrder.indexOf(a.field as ColumnName) >
        columnsOrder.indexOf(b.field as ColumnName)
      ) {
        return 1;
      }
      if (
        columnsOrder.indexOf(a.field as ColumnName) <
        columnsOrder.indexOf(b.field as ColumnName)
      ) {
        return -1;
      }

      return 0;
    });
  }, [columnsWithUpdatedVisibility, columnsOrders, selectedAgentType]);

  return {
    columns: columnsWithUpdatedOrder,
    columnsVisibility: Object.keys(columnProps).map(
      key => columnProps[key as ColumnName],
    ),
    setColumnVisibility,
    setColumnsOrder,
  };
};
