import {CodeListRef} from 'api/types';
import {Schemas} from 'api/dto.generated';

const dto: Schemas.ManualApprovalChangeRequestsDto = {
  changeRequests: [
    {
      refId: 'abc123',
      externalSuggestionsDto: {
        seriesTitles: ['Serie 1', 'Serie 2'],
        seriesNumbers: ['1', '2'],
        intellectualLevel: ['Alle aldre', 'En annen'],
      },
      work: {
        id: '89495de6-3796-473f-834c-52af773a4d8d',
        created: 1700235813,
        modified: 1700235813,
      },
      expression: {
        id: '60af6ff2-76f3-416e-bab4-24a54b147f77',
        workId: '89495de6-3796-473f-834c-52af773a4d8d',
        created: 1669324671903,
        modified: 1669324671903,
        expressionFormat: 'ILLUSTRATED_TEXT',
        languages: ['nob'],
        content: [],
        agents: [
          {
            roles: ['A12'],
            agentId: '96203b89-ae0f-467b-b040-b1e115f580bc',
            agentType: 'PERSON',
            agentName: {
              id: 'a9185eec-87a9-4bff-ae34-e9e244810f1e',
              name: 'Inger Sandved',
              surName: 'Anfinsen',
              nameOrder: 0,
            },
            nationalId: '10023785',
          },
          {
            roles: ['A12'],
            name: 'Petter Pan',
          },
        ],
      },
      manifestation: {
        id: '8d72edb2-0b96-4bcf-b460-bd2cb89a961e',
        created: 1700235813,
        modified: 1700235813,
        status: 'NEW',
        expressionId: '60af6ff2-76f3-416e-bab4-24a54b147f77',
        productForm: CodeListRef.PRODUCT_FORM.Heftet,
      },
    },
  ],
};

export default dto;
