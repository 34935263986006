import {HttpError} from 'api/http/types';
import {LoadAction} from '../actions/loadAction';

export function makeLoadActionFactories<TData, TActionType>(
  type: TActionType,
): {
  makeRequestAction: () => LoadAction<TData> & {type: TActionType};
  makeSuccessAction: (data: TData) => LoadAction<TData> & {type: TActionType};
  makeFailureAction: (
    error: HttpError,
  ) => LoadAction<TData> & {type: TActionType};
} {
  return {
    makeRequestAction: () => ({
      type,
      loadType: 'REQUEST',
    }),
    makeSuccessAction: (data: TData) => ({
      type,
      loadType: 'SUCCESS',
      payload: {data},
    }),
    makeFailureAction: (error: HttpError) => ({
      type,
      loadType: 'FAILURE',
      payload: {error},
    }),
  };
}
