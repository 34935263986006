import React, {useMemo} from 'react';
import {assert} from 'assert-ts';
import {
  DataValue,
  PartBool,
  PartDate,
  PartExpand,
  PartHtml,
  PartInt,
  PartLinkedAgent,
  PartLinkedLiterary,
  PartSchema,
  PartText,
  PartTextArea,
  PartYear,
  PartYearOrDate,
  Valx,
} from 'schemaDefinition/types';
import {isEmptyValue} from 'services/utils';
import {useFormSchemaGlobalScopeContext} from 'schema/contexts';
import {BasePartProps} from './types';
import {useDataFormContext} from '../contexts';
import {
  needsOrignalSubValueContainer,
  needsUpdatedSubValueContainer,
} from '../functions';
import {
  useLabelAndPlaceholder,
  useLayout,
  useResolvedDataFormPartConfiguration,
  verifyGlobalScope,
  verifyLocalScope,
} from '../hooks';
import {DataFormOriginalEmptyValueContainer} from './DataFormOriginalValueContainer';
import {DataFormPartExpand} from './DataFormPartExpand';
import {DataFormSchema} from './DataFormSchema';
import {
  DataFormUpdatedEmptySubValueContainer,
  DataFormUpdatedSubValueContainer,
  DataFormUpdatedValueContainerType,
} from './DataFormUpdatedValueContainer';
import {
  Checkbox,
  DatePicker,
  Int,
  LinkedAgent,
  LinkedLiterary,
  Text,
  TextArea,
  YearOrDatePicker,
  YearPicker,
} from './fields';
import {Html} from './fields/Html';

type DataFormSingleValueProps = BasePartProps & {
  part:
    | PartText<Valx>
    | PartTextArea<Valx>
    | PartHtml<Valx>
    | PartInt<Valx>
    | PartBool<Valx>
    | PartYear<Valx>
    | PartDate<Valx>
    | PartYearOrDate<Valx>
    | PartLinkedAgent<Valx>
    | PartLinkedLiterary<Valx>
    // | PartNameVariant
    | PartExpand<Valx>
    | PartSchema<Valx>;
  noLabel?: boolean;
  /** Id of inner element to receive focus */
  focusableId?: string;
};

export const DataFormSingleValue: React.FC<DataFormSingleValueProps> = ({
  part,
  useValue,
  useOriginalValue,
  noLabel,
  mode,
  scopePath,
  usesGlobalScope,
  diff,
  ...restProps
}) => {
  const {valuePath, relatedScope} = restProps;
  const {label, placeholder} = useLabelAndPlaceholder(part, noLabel);
  const layout = useLayout(part);
  const {id, setEditValue} = useDataFormContext();
  const {showWhenReadonlyAndEmpty} = useResolvedDataFormPartConfiguration(part);
  const value = useValue(
    valuePath,
    id,
    part.name ?? part.role ?? 'single value no name',
  ) as DataValue;
  const localScope = verifyLocalScope(
    useValue(scopePath, id, `${part.name}.localScope`),
    scopePath,
  );
  const {valuePath: globalPath} = useFormSchemaGlobalScopeContext();
  const globalScope = verifyGlobalScope(
    useValue(
      usesGlobalScope ? globalPath : undefined,
      id,
      `${part.name}.globalScope`,
    ),
    usesGlobalScope,
  );

  // TODO: Make hook returning Container
  // Use updated (proposed) value container if diff.mode is 'updated' and compare is 'fullValue'
  const useUpdatedValueContainer =
    diff && needsUpdatedSubValueContainer(part, diff);

  const useOriginalValueContainer =
    diff && needsOrignalSubValueContainer(part, diff);

  const PartContainer: DataFormUpdatedValueContainerType =
    useUpdatedValueContainer
      ? isEmptyValue(value)
        ? DataFormUpdatedEmptySubValueContainer
        : DataFormUpdatedSubValueContainer
      : useOriginalValueContainer
        ? isEmptyValue(value)
          ? DataFormOriginalEmptyValueContainer
          : React.Fragment
        : React.Fragment;

  const partContainerProps = useUpdatedValueContainer
    ? {valuePath, part}
    : undefined;

  return useMemo(() => {
    switch (part.type) {
      case 'text': {
        assert(mode !== 'compare');
        return (
          <PartContainer {...partContainerProps}>
            <Text
              part={part}
              value={value}
              setFieldValue={setEditValue}
              mode={mode}
              showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
              localScope={localScope}
              globalScope={globalScope}
              diff={diff}
              {...restProps}
              {...layout}
              label={label}
              placeholder={placeholder}
            />
          </PartContainer>
        );
      }
      case 'textarea': {
        assert(mode !== 'compare');
        return (
          <PartContainer {...partContainerProps}>
            <TextArea
              part={part}
              value={value}
              setFieldValue={setEditValue}
              mode={mode}
              showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
              localScope={localScope}
              globalScope={globalScope}
              diff={diff}
              {...restProps}
              {...layout}
              label={label}
              placeholder={placeholder}
            />
          </PartContainer>
        );
      }
      case 'html': {
        assert(mode !== 'compare');
        return (
          <PartContainer {...partContainerProps}>
            <Html
              part={part}
              value={value}
              setFieldValue={setEditValue}
              mode={mode}
              showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
              localScope={localScope}
              globalScope={globalScope}
              diff={diff}
              {...restProps}
              {...layout}
              label={label}
              placeholder={placeholder}
            />
          </PartContainer>
        );
      }
      case 'int': {
        assert(mode !== 'compare');
        return (
          <PartContainer {...partContainerProps}>
            <Int
              part={part}
              value={value}
              setFieldValue={setEditValue}
              mode={mode}
              showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
              localScope={localScope}
              globalScope={globalScope}
              diff={diff}
              {...restProps}
              {...layout}
              label={label}
              placeholder={placeholder}
            />
          </PartContainer>
        );
      }
      case 'bool': {
        assert(mode !== 'compare');
        return (
          <PartContainer {...partContainerProps}>
            <Checkbox
              part={part}
              value={value}
              setFieldValue={setEditValue}
              mode={mode}
              showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
              localScope={localScope}
              globalScope={globalScope}
              diff={diff}
              {...restProps}
              {...layout}
              label={label}
            />
          </PartContainer>
        );
      }
      case 'date': {
        assert(mode !== 'compare');
        return (
          <PartContainer {...partContainerProps}>
            <DatePicker
              part={part}
              value={value}
              setFieldValue={setEditValue}
              mode={mode}
              showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
              localScope={localScope}
              globalScope={globalScope}
              diff={diff}
              {...restProps}
              {...layout}
              label={label}
              placeholder={placeholder}
            />
          </PartContainer>
        );
      }
      case 'year': {
        assert(mode !== 'compare');
        return (
          <PartContainer {...partContainerProps}>
            <YearPicker
              part={part}
              value={value}
              setFieldValue={setEditValue}
              mode={mode}
              showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
              localScope={localScope}
              globalScope={globalScope}
              diff={diff}
              {...restProps}
              {...layout}
              label={label}
              placeholder={placeholder}
            />
          </PartContainer>
        );
      }
      case 'yearOrDate': {
        assert(mode !== 'compare');
        return (
          <PartContainer {...partContainerProps}>
            <YearOrDatePicker
              part={part}
              value={value}
              setFieldValue={setEditValue}
              mode={mode}
              showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
              localScope={localScope}
              globalScope={globalScope}
              diff={diff}
              {...restProps}
              {...layout}
              label={label}
              placeholder={placeholder}
            />
          </PartContainer>
        );
      }
      case 'linkedAgent': {
        assert(mode !== 'compare');
        return (
          <LinkedAgent
            part={part}
            value={value}
            setFieldValue={setEditValue}
            mode={mode}
            showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
            localScope={localScope}
            globalScope={globalScope}
            diff={diff}
            ValueContainer={PartContainer}
            {...restProps}
            {...layout}
            label={label}
            placeholder={placeholder}
          />
        );
      }
      case 'linkedLiterary': {
        assert(mode !== 'compare');
        return (
          <PartContainer {...partContainerProps}>
            <LinkedLiterary
              part={part}
              value={value}
              setFieldValue={setEditValue}
              mode={mode}
              showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
              localScope={localScope}
              globalScope={globalScope}
              diff={diff}
              {...restProps}
              {...layout}
              label={label}
              placeholder={placeholder}
            />
          </PartContainer>
        );
      }
      case 'schema': {
        return (
          <DataFormSchema
            schema={part}
            valuePath={valuePath}
            relatedScope={relatedScope}
            mode={mode}
            diff={diff}
            noLabel={noLabel}
            useValue={useValue}
            useOriginalValue={useOriginalValue}
          />
        );
      }
      case 'expand': {
        return (
          <DataFormPartExpand
            expand={part}
            valuePath={valuePath}
            scopePath={scopePath}
            usesGlobalScope={usesGlobalScope}
            relatedScope={relatedScope}
            mode={mode}
            diff={diff}
            useValue={useValue}
            useOriginalValue={useOriginalValue}
          />
        );
        // assert.soft(
        //   false,
        //   'SingleInputComponent: expand part requires object value',
        //   {value, valuePath},
        // );
      }
      default:
        return null;
    }
    // Only depend on values that may change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [part, mode, value, localScope, globalScope, restProps, relatedScope]);
};
