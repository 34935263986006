import {LocalizationContextType} from 'localization/context/types';
import {FieldRootKeys} from '../../../schemaDefinition/functions/types';
import {getFieldString} from './getFieldString';

export const getLabel = (
  fieldRootKeys: FieldRootKeys | undefined,
  required: boolean,
  localization: LocalizationContextType,
): string | undefined =>
  getFieldString(fieldRootKeys, required, '.label', localization);
