import React, {PropsWithChildren} from 'react';
import {SxProps} from '@mui/material';
import {Layout} from '../layout';

const CardHeaderName = 'CardHeaderName';

type Props = {
  minHeight?: string | number;
  sx?: SxProps;
};

const CardHeader: React.FC<PropsWithChildren<Props>> = ({
  minHeight = '40px',
  sx,
  children,
}) => {
  return (
    <Layout horizontal minHeight={minHeight} adjustLeft adjustCenter sx={sx}>
      {children}
    </Layout>
  );
};

CardHeader.displayName = CardHeaderName;

export {CardHeader, CardHeaderName};
