import {useCallback, useMemo} from 'react';
import {useStore} from 'react-redux';
import {ActualChangesWithSchema} from 'services/data/metadata/types';
import {AppState} from 'store/types';
import {useActualChangesWithSchema} from 'services/data/metadata/hooks/useAdjustedChangeRequest';
import {MetadataEditAction} from 'services/data/metadata/metadataEditActionTypes';
import {ChangeRequestCardProps} from '../types';
import {getManifestationHeaderTitle} from '../functions';

export const useChangeRequestEditState = ({
  changeRequestId,
  initialManifestationId: _,
}: ChangeRequestCardProps): {
  title: string;
  initialExpanded: boolean;
  changeRequest: ActualChangesWithSchema | undefined;
  rejectRemaining: () => void;
  autoClose: () => void;
} => {
  const {dispatch} = useStore<AppState>();
  const changeRequest = useActualChangesWithSchema(changeRequestId);
  const initialExpanded =
    changeRequest?.taskType === 'METADATA_MOVED' ||
    changeRequest?.taskType === 'METADATA_COPIED';

  const rejectRemaining = useCallback(() => {
    const action: MetadataEditAction = {
      type: 'METADATAEDIT_CLOSE_CHANGEREQUEST',
      payload: {
        changeRequestId,
      },
    };

    dispatch(action);
  }, [changeRequestId, dispatch]);

  const autoClose = useCallback(() => {
    rejectRemaining();
  }, [rejectRemaining]);

  return useMemo(
    () => ({
      initialExpanded,
      title: getManifestationHeaderTitle(changeRequest?.original),
      changeRequest,
      rejectRemaining,
      autoClose,
    }),
    [initialExpanded, changeRequest, rejectRemaining, autoClose],
  );
};
