import {Concept} from 'types';
import {
  ExpressionIds,
  ManifestationItem,
  MetadataEditState,
  MetadataIds,
} from '../types';
import {getHasChangedById} from './getHasChangedId';

export const getEntityIdsNeedingValidation = (
  editState: MetadataEditState,
): MetadataIds | undefined => {
  const {metadata, order} = editState;
  if (metadata) {
    const expressions = order.map(expression => ({
      expressionId: expression.expressionId,
      manifestationIds: expression.content
        .filter(item => item.type === Concept.manifestation)
        .map(item => (item as ManifestationItem).id),
    }));

    const expressionsWithChanges = expressions
      .map(expression => {
        const manifestationIds = expression.manifestationIds.filter(
          manifestationId =>
            getHasChangedById(
              manifestationId,
              editState,
              Concept.manifestation,
            ),
        );
        const expressionId =
          manifestationIds.length > 0 ||
          getHasChangedById(
            expression.expressionId,
            editState,
            Concept.expression,
          )
            ? expression.expressionId
            : undefined;
        return expressionId ? {expressionId, manifestationIds} : undefined;
      })
      .filter(expression => expression !== undefined) as ExpressionIds[];

    return expressionsWithChanges.length > 0 ||
      getHasChangedById(metadata.work.id, editState, Concept.work)
      ? {
          workId: metadata.work.id,
          expressions: expressionsWithChanges,
        }
      : undefined;
  }

  return undefined;
};
