import React from 'react';
import {SxProps} from '@mui/material';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {RowId} from 'components/dataTable/types';
import {PaginationState} from 'components/paginator/types';
import {Data, Schema} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {ThesaurusesGate} from 'services/thesaurus';
import {DataTable, Layout, Spacer} from 'components';
import {BasePartProps} from './types';
import {useSchemaThesaurusIds} from '../../hooks';
import {useTableConfigurationContext} from '../contexts/TableConfigurationContextType';
import {sortColumnConfigs} from '../functions';
import {
  useColumnConfig,
  useDataTableColumns,
  usePageSizeProps,
  usePaginationProps,
  useRowFunctions,
} from '../hooks';
import {mapToVisibilityModel} from '../mapper/mapToVisibilityModel';
import {QuickFilterButtons} from './QuickFilterButtons';

type TableSchemaProps<TData extends Data> = Omit<
  BasePartProps<undefined>,
  'part' | 'valuePath' | 'value' | 'relatedScope'
> & {
  header: React.ReactNode | string;
  schema: Schema;
  rows?: TData[];
  loading?: boolean;
  onRowClick?: (row: TData) => void;
  highlights?: string[];
  pagination?: PaginationState;
  selection?: RowId[];
  onSelectionChanged?: (selection: RowId[]) => void;
  onRowsUpdate?: (gridRows: GridValidRowModel[]) => void;
};

const tableSx: SxProps = {
  p: 2,
  backgroundColor: ColorPalette.primary.white,
  borderRadius: 1,
};

export function TableSchema<TData extends Data>({
  header,
  schema,
  rows,
  loading,
  onRowClick,
  highlights,
  pagination,
  selection,
  onSelectionChanged,
  onRowsUpdate,
}: TableSchemaProps<TData>) {
  const {tLoose} = useLocalization();
  const {
    indicateRowId,
    disableSorting,
    disableFiltering,
    disableFilteringByName,
    disableColumnResize,
    enableSelectionButton,
    autoHeight,
    contentHeight,
    partValueRenderers,
    partValueGetters,
    partValueDelimiter,
    paginationPreText,
    autoRowHeight,
    quickFilters,
    enableQuickFilter,
    hidden,
    hideColumnSelector,
    pageSizeAllowAll,
    hideFooter,
    pinnedColumns,
  } = useTableConfigurationContext();
  const {
    columnConfigs,
    setColumnConfigs,
    setColumnConfigWidth,
    sortingConfig,
    setSortingConfig,
    setColumnConfigVisibility,
    filterModel,
    setFilterModel,
  } = useColumnConfig(schema);

  const thesaurusIds = useSchemaThesaurusIds(schema);

  const datatableColumns: GridColDef[] = useDataTableColumns(
    schema,
    columnConfigs,
    partValueRenderers,
    partValueGetters,
    partValueDelimiter,
    disableFiltering,
    disableFilteringByName,
    highlights,
    indicateRowId,
  );

  const {getRowHeight} = useRowFunctions(schema, rows, autoRowHeight);
  const columnVisibilityModel: GridColumnVisibilityModel = mapToVisibilityModel(
    columnConfigs,
    hidden,
  );

  const paginationProps = usePaginationProps(pagination, paginationPreText);
  const pageSizeProps = usePageSizeProps(pagination, pageSizeAllowAll);
  const apiRef = useGridApiRef();

  return (
    <ThesaurusesGate thesauruses={thesaurusIds}>
      {enableQuickFilter ? (
        <>
          <QuickFilterButtons
            setFilterModel={setFilterModel}
            onOpenFilterPanel={() => apiRef.current.showFilterPanel()}
            quickFilters={quickFilters}
            filterModel={filterModel}
          />
          <Spacer size={3} />
        </>
      ) : null}
      <Layout flex={1} height={contentHeight} position="relative" sx={tableSx}>
        <DataTable
          apiRef={apiRef}
          key={schema.name}
          className={`datatable-${schema.name}`}
          columns={datatableColumns}
          rows={rows ?? []}
          loading={loading !== undefined ? loading : rows === undefined}
          autoHeight={autoHeight}
          getRowHeight={getRowHeight}
          onRowClick={onRowClick}
          pinnedColumns={pinnedColumns}
          filterModel={filterModel}
          setFilterModel={setFilterModel}
          columnVisibilityModel={columnVisibilityModel}
          setColumnVisibility={(columnName, visible) => {
            setColumnConfigVisibility(columnName, visible);
          }}
          setColumnsOrder={newOrder => {
            setColumnConfigs(sortColumnConfigs(columnConfigs, newOrder));
          }}
          setColumnWidth={
            disableColumnResize
              ? undefined
              : (columnName, width) => {
                  setColumnConfigWidth(columnName, width);
                }
          }
          sorting={disableSorting ? undefined : sortingConfig}
          onSortingChanged={disableSorting ? undefined : setSortingConfig}
          enableSelectionButton={enableSelectionButton}
          selection={selection}
          onSelectionChanged={onSelectionChanged}
          header={header}
          columnSelectionTitle={tLoose(`schema.table.columnselect.title`, {
            name: tLoose(`schema.name.${schema.name}.title`),
          })}
          paginatorProps={paginationProps}
          pageSizeProps={pageSizeProps}
          hideFooter={hideFooter}
          slotProps={{
            toolbar: {
              hiddenColumns: hidden ? Object.keys(hidden) : undefined,
              hideColumnSelector,
              onRowsUpdate,
            },
          }}
        />
      </Layout>
    </ThesaurusesGate>
  );
}
