import {useSelector} from 'react-redux';
import memoizeOne from 'memoize-one';
import {AppState} from 'store/types';
import {WorkV4} from 'api';
import {formatLinkedValueLink} from 'schema/preview/functions';

const getTitles = (
  agents: WorkV4['agents'] | undefined,
  preferredTitle: WorkV4['preferredTitle'] | undefined,
) => {
  const mainActor = agents?.[0];
  return {
    title: preferredTitle?.[0] ?? '',
    subtitle: mainActor?.link
      ? formatLinkedValueLink(mainActor.link)
      : undefined,
  };
};

const memoizedGetTitles = memoizeOne(getTitles);

const workTitlesSelector = (
  state: AppState,
): {
  title: string;
  subtitle?: string;
} => {
  const work = state.metadata.data?.work;
  if (!work) {
    return {title: ''};
  }

  return memoizedGetTitles(work.agents, work.preferredTitle);
};

export const useWorkTitles = () => {
  return useSelector(workTitlesSelector);
};
