type MatchItem = {
  type: 'match' | 'nonMatch';
  value: string;
};

export const findMatches = <T>(
  text: T,
  query: RegExp | string | undefined,
): {original: T | undefined; matches: MatchItem[] | undefined} => {
  if (!query || !text || typeof text !== 'string') {
    return {original: text, matches: undefined};
  }

  const regex =
    typeof query === 'string'
      ? // Remove backspaces when creating regular expression (all cases so far has been a missclick from user)
        new RegExp(query.replace('\\', ''), 'gi')
      : query;
  const modifiedText = text.replace(regex, match => `<!>@@${match}<!>`);
  if (modifiedText.length === text.length) {
    return {original: text, matches: undefined};
  }

  const parts = modifiedText.split('<!>');
  const matches = parts
    .map<MatchItem>(part =>
      part.startsWith('@@')
        ? {type: 'match', value: part.substring(2)}
        : {type: 'nonMatch', value: part},
    )
    .filter(m => m.value.length > 0);

  return {original: undefined, matches};
};
