import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  Popper,
} from '@mui/material';
import random from 'lodash/random';
import {isNullish} from 'services/utils';
import {Card} from '../card/Card';
import {Icon} from '../icons';
import {Layout} from '../layout';
import './ToolInput.scss';

export type Suggestion = {
  id: string;
  content: ReactElement;
  hasFocus?: boolean;
};

type Props = {
  value?: string;
  placeholder?: string;
  enableClear?: boolean;
  /**
   * Initial/changes to non-nullish values will set focus on input field
   */
  triggerFocus?: unknown;
  /**
   * @deprecated use triggerFocus instead
   */
  autoFocus?: boolean;
  onChange: (value: string) => void;
  enableSuggestions?: boolean;
  suggestions?: Suggestion[];
  onSubmit?: () => void;
};

export const ToolInput: React.FC<Props> = ({
  value,
  placeholder,
  enableClear,
  triggerFocus,
  onChange,
  enableSuggestions,
  suggestions,
  onSubmit,
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (enableSuggestions && event.target) {
        setAnchorSuggestElement(event.target);
      }
      const newValue = event.target.value;
      onChange(newValue);
    },
    [onChange, enableSuggestions],
  );

  const [anchorSuggestElement, setAnchorSuggestElement] = React.useState<
    null | HTMLInputElement | HTMLTextAreaElement
  >(null);

  const {sx, adornment} = useMemo(() => {
    const handleClear = () => {
      onChange('');
      inputRef.current?.focus();
    };

    return {
      sx: {
        borderRadius: 4,
      },
      adornment: enableClear ? (
        <InputAdornment position="end">
          <IconButton size="small" onClick={handleClear}>
            <Icon icon={'CloseSmall'} />
          </IconButton>
        </InputAdornment>
      ) : undefined,
    };
  }, [enableClear, onChange]);

  const suggestionsWrapperSx = useMemo(() => {
    return {
      marginTop: 1,
      p: 1,
      background: '#FEFEFE',
      boxShadow: '2px 4px 6px rgba(0, 0, 0, 0.15)',
      borderRadius: '16px',
    } as const;
  }, []);

  const suggestionSx = useCallback((s: Suggestion) => {
    return {
      cursor: 'pointer',
      backgroundColor: s.hasFocus ? undefined : 'inherit',
      '& :hover': {
        backgroundColor: undefined,
      },
    } as const;
  }, []);

  useEffect(() => {
    if (!isNullish(triggerFocus)) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);
    }
  }, [triggerFocus]);

  return (
    <>
      <MuiTextField
        // autoFocus={autoFocus}
        id="toolInput"
        name="toolInput"
        value={value}
        className="ToolInput"
        placeholder={placeholder}
        sx={sx}
        aria-autocomplete={enableSuggestions ? 'none' : undefined}
        InputProps={{
          inputRef: inputRef,
          endAdornment: adornment,
        }}
        onChange={handleChange}
        onSubmit={onSubmit}
        onKeyDown={e => {
          if (onSubmit && e.key === 'Enter') {
            onSubmit();
          }
        }}
        autoComplete={enableSuggestions ? 'off' : undefined}
      />
      {enableSuggestions && anchorSuggestElement && (
        <>
          <Popper
            placement={'bottom-start'}
            open={(suggestions && suggestions?.length > 0) === true}
            anchorEl={anchorSuggestElement}>
            <Layout sx={suggestionsWrapperSx}>
              {suggestions?.map(s => {
                return (
                  <Card key={s.id ? s.id : random()} sx={suggestionSx(s)}>
                    {s.content}
                  </Card>
                );
              })}
            </Layout>
          </Popper>
        </>
      )}
    </>
  );
};
