export async function testDownloadUrl(
  url: string,
  acceptedStatusCodes: number[] = [200],
): Promise<string | undefined> {
  const response = await fetch(url, {
    method: 'HEAD',
  }).catch(() => {
    return {
      status: 404,
    };
  });
  return acceptedStatusCodes.includes(response.status) ? url : undefined;
}
