import React from 'react';
import {useHandleDelayedCall} from 'services/utils/hooks/useHandleDelayedCall';
import {IconButtonProps} from './types';
import {ControlledIconButton} from './ControlledIconButton';

export const IconButton: React.FC<IconButtonProps> = ({onClick, ...props}) => {
  const {handleCall, isCalling, isDelayed} = useHandleDelayedCall(onClick);
  return (
    <ControlledIconButton
      {...props}
      onClick={handleCall}
      isCalling={isCalling}
      isDelayed={isDelayed}
    />
  );
};
