import {useRef} from 'react';
import {SelectorById} from '../selectors/types';

/**
 * Create a redux selector that selects an entity by given id.
 * Purpose: avoid creating a new selector on every render,
 *          or too coarse dependencies triggering re-renders.
 */
export const useCreateSelectorById = <T, TId>(
  entityId: TId,
  selectorById: SelectorById<T, TId>,
) => useRef(selectorById(entityId)).current;
