import {assert} from 'assert-ts';
import {MetadataEditState} from '../types';

export const assertEntityStatus = (
  id: string,
  state: Pick<MetadataEditState, 'statuses'>,
  context: string,
) => {
  return assert(state.statuses[id], context);
};
