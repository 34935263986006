import {assert} from 'assert-ts';
import {ChangeRequest} from 'api/types';
import {MetadataEditState} from '../types';

export const assertChangeRequest = (
  taskId: string,
  state: Pick<MetadataEditState, 'metadata'>,
): ChangeRequest => {
  return assert(
    state.metadata?.changeRequests.find(cr => cr.id === taskId),
    `metadataEditReducer.assertChangeRequest: not found`,
    {taskId},
  );
};
