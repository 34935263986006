import React from 'react';
import {ColorPalette} from 'theme';
import {IconProps} from './types';
import {CheckRoundBackgroundIcon} from './CheckRoundBackgroundIcon';

export const VerifiedIcon: React.FC<IconProps> = props => {
  return (
    <CheckRoundBackgroundIcon
      fillColorCode={ColorPalette.enhancedGreen}
      {...props}
    />
  );
};
