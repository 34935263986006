import dayjs from 'dayjs';
import {valueAsNumber} from './valueAsNumber';

export const valueAsYear = (value: unknown): number | undefined => {
  const numValue = valueAsNumber(value);
  return numValue === undefined
    ? undefined
    : Math.abs(numValue) < 3000
    ? numValue
    : dayjs.unix(numValue).year();
};
