import {Log} from '../../logging/types';
import {Indexer} from '../../types';

export const deepMergeStates = <T extends Indexer = Indexer<unknown>>(
  persistState: Partial<T> | undefined,
  initialState: T,
  logWarn?: Log,
): T => {
  if (persistState === undefined) {
    return initialState;
  }

  const mergedKeys = Object.keys({...initialState, ...persistState});
  const merged = {...initialState};

  mergedKeys.forEach(key => {
    const iVal = initialState[key];
    const pVal = persistState[key];

    if (pVal === undefined) {
      return;
    }

    if (Array.isArray(iVal)) {
      if (Array.isArray(pVal)) {
        merged[key as keyof T] = pVal;
      } else {
        logWarn &&
          logWarn('deepMergeState: converting to array', {
            key,
            from: pVal,
            to: iVal,
          });
      }
      return;
    }

    if (typeof iVal === 'object') {
      if (!Array.isArray(pVal) && typeof pVal === 'object') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        merged[key as keyof T] = deepMergeStates(pVal, iVal, logWarn);
      } else {
        logWarn &&
          logWarn('deepMergeState: converting to object', {
            key,
            from: pVal,
            to: iVal,
          });
      }
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    merged[key as keyof T] = pVal !== undefined ? pVal : iVal;
  });

  return merged;
};
