import {assert} from 'assert-ts';
import {Data, DataValue, PathKeys} from '../types';

export const evaluateByPathKeys = (
  pathKeys: PathKeys,
  scope: Data,
  assertProps: object | false,
): DataValue => {
  // Resolve value
  let referencedValue: DataValue = scope;
  pathKeys.forEach(key => {
    if (Array.isArray(referencedValue)) {
      if (typeof key !== 'number' || isNaN(key)) {
        assertProps &&
          assert.soft(
            false,
            'evaluateByPathKeys: Invalid array index in pathKeys',
            assertProps,
          );

        referencedValue = null;
      } else {
        referencedValue = referencedValue[key] ?? null;
      }
      return;
    }

    if (referencedValue && typeof referencedValue === 'object') {
      // referencedValue may also be LinkedValue.
      // Then it will give null if key is not id or name (which should
      // be reported by a schema validator - invalid path)
      referencedValue = (referencedValue as Data)[key] ?? null;
      return;
    }

    assertProps &&
      assert.soft(false, 'evaluateByPathKeys: Invalid $ref', assertProps);

    referencedValue = null;
  });

  return referencedValue;
};
