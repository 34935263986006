import {ColumnConfig} from 'schema/table/types';

export const collectionSeriesDefaultColumns: ColumnConfig[] = [
  {
    name: 'titles',
    visible: true,
    width: 300,
  },
  {
    name: 'links',
    visible: true,
  },
];
