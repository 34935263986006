import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const FilmIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M19.38 6.08C19.1979 6.00342 18.9972 5.9825 18.8032 6.01987C18.6092 6.05725 18.4306 6.15124 18.29 6.29L15 9.59V8C15 7.46957 14.7893 6.96086 14.4142 6.58579C14.0391 6.21071 13.5304 6 13 6H6C5.46957 6 4.96086 6.21071 4.58579 6.58579C4.21071 6.96086 4 7.46957 4 8V16C4 16.5304 4.21071 17.0391 4.58579 17.4142C4.96086 17.7893 5.46957 18 6 18H13C13.5304 18 14.0391 17.7893 14.4142 17.4142C14.7893 17.0391 15 16.5304 15 16V14.41L18.29 17.71C18.3834 17.8027 18.4943 17.876 18.6161 17.9258C18.7379 17.9755 18.8684 18.0008 19 18C19.1312 18.0034 19.2613 17.976 19.38 17.92C19.5626 17.845 19.7189 17.7176 19.8293 17.5539C19.9396 17.3901 19.999 17.1974 20 17V7C19.999 6.80257 19.9396 6.60986 19.8293 6.44614C19.7189 6.28242 19.5626 6.15502 19.38 6.08ZM13 16H6V8H13V16ZM18 14.59L15.41 12L18 9.41V14.59Z" />
    </SvgIcon>
  );
};
