import {CustomListActions} from 'schema/types';
import {Data, DataSimpleValue} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {FlexBox, useListOperations} from 'components';
import {ListActionButton} from 'components/fields/ListActionButton';
import {useDataFormContext} from 'schema/form/contexts';

export const CustomAgentActions: CustomListActions = ({
  part,
  valuePath,
  useValue,
}) => {
  const {t} = useLocalization();

  const {id, setEditValue} = useDataFormContext();
  const value = useValue(valuePath, id, part.name) ?? null;
  const {onAppendValue} = useListOperations<Data | DataSimpleValue>(
    update => {
      const nextValue = update((value ?? []) as (Data | DataSimpleValue)[]);
      setEditValue(valuePath, nextValue, part);
      return nextValue;
    },
    () => ({
      link: {linkStatus: 'unnamed'},
      roles: [],
    }),
  );

  return (
    <FlexBox flex={1} left pl={4}>
      <ListActionButton
        icon="Add"
        label={t('form.global.field.agents.addUnnamed')}
        onClick={onAppendValue}
      />
    </FlexBox>
  );
};
