import {TreeNode} from 'services/thesaurus/functions/types';
import {isNullish} from 'services/utils';
import {getVisibleNodeIds} from './getVisibleNodeIds';

export const getVisibleNodeIndexAndTotal = (
  nodeId: string | undefined,
  expanded: string[],
  nodes: TreeNode[],
): {
  nodeIndex: number | undefined;
  totalVisible: number;
} => {
  const visibleNodes = getVisibleNodeIds(expanded, nodes);
  const index = isNullish(nodeId) ? -1 : visibleNodes.indexOf(nodeId);
  return {
    nodeIndex: index >= 0 ? index : undefined,
    totalVisible: visibleNodes.length,
  };
};
