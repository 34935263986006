import React, {useCallback, useMemo, useState} from 'react';
import {assert} from 'assert-ts';
import {PersonNameVariantFieldLayout} from 'components/fields/types';
import {DataValue, NameVariantValue} from 'schemaDefinition/types';
import {ColorPalette} from 'theme';
import {assertSimpleRequired} from 'schemaDefinition/functions';
import {valueAsNameVariantArray} from 'services/utils';
import {useUniqueFieldId} from 'components/fields/hooks/useUniqueFieldId';
import {DataFormNameVariantListProps} from './types';
import {
  CheckboxField,
  Layout,
  SingleValueListField,
  useListOperations,
} from '../../../components';
import {useDataFormContext} from '../contexts';
import {
  useAddItemLabel,
  usePersonNameVariantLabelsAndPlaceholders,
  useResolvedDataFormPartConfiguration,
} from '../hooks';
import {PersonNameVariant} from './fields';

const nameVariantFieldLayout: PersonNameVariantFieldLayout = {
  name: {
    flex: 3,
  },
  surName: {
    flex: 3,
  },
  regnalNumber: {
    flex: 1,
  },
  addition: {
    flex: 3,
  },
  mainForm: {
    width: '80px',
  },
};

const hasAnySuffix = (value: DataValue): boolean => {
  const nameVariants = valueAsNameVariantArray(value);
  if (!nameVariants) return false;
  return nameVariants.some(v => v.addition);
};

const hasAnyNumber = (value: DataValue): boolean => {
  const nameVariants = valueAsNameVariantArray(value);
  if (!nameVariants) return false;
  return nameVariants.some(v => v.regnalNumber);
};

const newVariant = (
  withRegnalNumber: boolean,
  withSuffix: boolean,
): NameVariantValue => {
  const value: NameVariantValue = {};
  if (withRegnalNumber) {
    value.regnalNumber = '';
  }
  if (withSuffix) {
    value.addition = '';
  }
  return value;
};

export const DataFormPersonNameVariantList: React.FC<
  DataFormNameVariantListProps
> = ({part, useValue, valuePath, mode}) => {
  const {id, setEditValue} = useDataFormContext();

  const {showWhenReadonlyAndEmpty, hideStructureOperations} =
    useResolvedDataFormPartConfiguration(part);
  const value = (useValue(valuePath, id, part.name) ?? null) as DataValue;
  const [showSuffix, setShowSuffix] = useState(() => hasAnySuffix(value));
  const [showRegnalNumber, setShowRegnalNumber] = useState(() =>
    hasAnyNumber(value),
  );

  const setItems = useCallback(
    (items: NameVariantValue[] | null) => setEditValue(valuePath, items, part),
    [part, setEditValue, valuePath],
  );

  assert(
    value === null || Array.isArray(value),
    'DataForm: "name variants"-value property must be array (or null)',
    {part: part, contextPath: valuePath, dataScope: value},
  );

  const requiredValue = useMemo(
    () =>
      !value || value.length === 0
        ? [newVariant(showRegnalNumber, showSuffix)]
        : value,
    [value, showRegnalNumber, showSuffix],
  );

  const {onAppendValue, onDeleteValue, onMoveValue} =
    useListOperations<NameVariantValue>(
      update => {
        const nextValue = update(requiredValue as NameVariantValue[]);
        setItems(nextValue);
        return nextValue;
      },
      () => newVariant(showRegnalNumber, showSuffix),
    );

  const {contentLabels, fieldLayoutWithPlaceholders} =
    usePersonNameVariantLabelsAndPlaceholders(
      showRegnalNumber,
      showSuffix,
      nameVariantFieldLayout,
    );
  const addLabel = useAddItemLabel(part);

  const numberFieldId = useUniqueFieldId(`${valuePath}.number`);
  const additionFieldId = useUniqueFieldId(`${valuePath}.addition`);

  const renderCustomActions = useCallback(() => {
    const handleChangeShowSuffix = (checked: boolean) => {
      const nameVariants = valueAsNameVariantArray(requiredValue);
      setShowSuffix(checked);
      const newValue = checked
        ? nameVariants?.map<NameVariantValue>(nv => ({
            ...nv,
            addition: nv.addition ?? '',
          }))
        : nameVariants?.map<NameVariantValue>(({addition: _, ...nv}) => nv);
      setItems(newValue ?? null);
    };

    const handleChangeShowNumber = (checked: boolean) => {
      const nameVariants = valueAsNameVariantArray(requiredValue);
      setShowRegnalNumber(checked);
      const newValue = checked
        ? nameVariants?.map<NameVariantValue>(nv => ({
            ...nv,
            regnalNumber: nv.regnalNumber ?? '',
          }))
        : nameVariants?.map<NameVariantValue>(({regnalNumber: _, ...nv}) => nv);
      setItems(newValue ?? null);
    };

    return (
      <Layout horizontal>
        <CheckboxField
          fieldId={numberFieldId}
          name={`${valuePath}.number`}
          value={showRegnalNumber}
          boxLabel={'Nummer'}
          onChange={handleChangeShowNumber}
        />
        <CheckboxField
          fieldId={additionFieldId}
          name={`${valuePath}.addition`}
          value={showSuffix}
          boxLabel={'Tilføyelse'}
          onChange={handleChangeShowSuffix}
        />
      </Layout>
    );
  }, [
    numberFieldId,
    valuePath,
    showRegnalNumber,
    additionFieldId,
    showSuffix,
    requiredValue,
    setItems,
  ]);

  return (
    <SingleValueListField<NameVariantValue>
      addLabel={addLabel}
      label={'.'}
      contentLabels={contentLabels}
      itemBackgroundColor={ColorPalette.enhancedLightBeige}
      itemPadding={1}
      name={part.name}
      required={assertSimpleRequired(part.required)}
      readonly={mode === 'read-only'}
      showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
      values={requiredValue as NameVariantValue[]}
      getValueKey={idx => `${valuePath}[${idx}]`}
      renderValue={(idx, value, focusableId) => {
        return (
          <PersonNameVariant
            focusableId={focusableId}
            part={part}
            value={value as NameVariantValue}
            valuePath={`${valuePath}[${idx}]`}
            setFieldValue={setEditValue}
            isMainForm={idx === 0}
            mode={mode}
            showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
            layout={fieldLayoutWithPlaceholders}
          />
        );
      }}
      renderCustomActions={renderCustomActions}
      onAppendValue={
        hideStructureOperations?.includes('add') ? undefined : onAppendValue
      }
      onDeleteValue={
        hideStructureOperations?.includes('delete') ? undefined : onDeleteValue
      }
      onMoveValue={
        hideStructureOperations?.includes('move') ? undefined : onMoveValue
      }
    />
  );
};
