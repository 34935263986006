import {assert} from 'assert-ts';
import {GetTokens, TaskStatus, TaskV2} from './types';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpGet, httpPut} from './http/ebba';
import {mapTaskV2Dto} from './mappers';

export const getTasksV2 = (getTokens: GetTokens): Promise<TaskV2[]> => {
  return exceptionToPromiseReject(() =>
    httpGet<Schemas.TaskV2Dto[]>({
      subdir: 'tasksv2/open',
      getTokens,
    }).then(dto => {
      const dtoTasks = dto ?? [];
      const invalidTasks = dtoTasks.filter(t => !t.metadata?.workId);
      assert.soft(invalidTasks.length === 0, 'Task is missing workId', {
        invalidTasks,
      });
      const validTasks = dtoTasks.filter(t => !!t.metadata?.workId);
      return validTasks.map(mapTaskV2Dto);
    }),
  );
};

export const putTaskStatus = (
  id: number,
  status: TaskStatus,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<void> => {
  return exceptionToPromiseReject(() =>
    httpPut<void, Schemas.UpdateTaskStatusDto>({
      subdir: 'tasks/{id}/status',
      subdirParams: {id},
      body: {status},
      getTokens,
      mock,
    }),
  );
};
