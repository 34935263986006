import {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {assert} from 'assert-ts';
import {AgentSubType} from 'types';
import {useQueryParams} from 'navigation/hooks';
import {AgentIdParam, NewAgentParam} from '../types';

export const useAgentUrlParameters = (): AgentIdParam | NewAgentParam => {
  const {agentId} = useParams();
  const queryParams = useQueryParams<{
    type: AgentSubType;
    suggestion: string;
  }>();

  return useMemo(() => {
    return agentId === 'new'
      ? {
          suggestion: queryParams.suggestion,
          agentType: assert(
            queryParams.type,
            'useAgentUrlParameters: agentType expected',
            {agentId, queryParams},
          ),
        }
      : {
          agentId: assert(agentId, 'useAgentUrlParameters: agentId expected', {
            agentId,
            queryParams,
          }),
        };
  }, [agentId, queryParams]);
};
