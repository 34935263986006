import {
  AndCondition,
  AndConditionV0,
  BooleanCondition,
  ComparisonCondition,
  ComparisonConditionV0,
  Condition,
  EqComparisonCondition,
  GeComparisonCondition,
  GtComparisonCondition,
  IncludesCondition,
  LeComparisonCondition,
  LengthCondition,
  LtComparisonCondition,
  NotCondition,
  NotConditionV0,
  OrCondition,
  OrConditionV0,
  RangeCondition,
  RegexCondition,
} from '../types';

const baseConditionKeys = ['id', 'level', 'messageKey'];

export const isRangeCondition = <TVal>(
  condition: Condition<TVal>,
): condition is RangeCondition<TVal> => {
  const range = condition as RangeCondition<TVal>;
  return range.min !== undefined || range.max !== undefined;
};

export const isLengthCondition = <TVal>(
  condition: Condition<TVal>,
): condition is LengthCondition<TVal> => {
  const length = condition as LengthCondition<TVal>;
  return length.minLength !== undefined || length.maxLength !== undefined;
};

export const isRegexCondition = <TVal>(
  condition: Condition<TVal>,
): condition is RegexCondition<TVal> => {
  const regex = condition as RegexCondition<TVal>;
  return regex.regex !== undefined;
};

export const isComparisonConditionV0 = <TVal>(
  condition: Condition<TVal>,
): condition is ComparisonConditionV0<TVal> => {
  const comparison = condition as ComparisonConditionV0<TVal>;
  return (
    ['lt', 'le', 'eq', 'ge', 'gt'].includes(comparison.op) && !!comparison.arg2
  );
};

export const isEqComparisonCondition = <TVal>(
  condition: Condition<TVal>,
): condition is EqComparisonCondition<TVal> => {
  const eq = condition as EqComparisonCondition<TVal>;
  return eq.$eq !== undefined;
};

export const isLtComparisonCondition = <TVal>(
  condition: Condition<TVal>,
): condition is LtComparisonCondition<TVal> => {
  const lt = condition as LtComparisonCondition<TVal>;
  return lt.$lt !== undefined;
};

export const isLeComparisonCondition = <TVal>(
  condition: Condition<TVal>,
): condition is LeComparisonCondition<TVal> => {
  const le = condition as LeComparisonCondition<TVal>;
  return le.$le !== undefined;
};

export const isGeComparisonCondition = <TVal>(
  condition: Condition<TVal>,
): condition is GeComparisonCondition<TVal> => {
  const ge = condition as GeComparisonCondition<TVal>;
  return ge.$ge !== undefined;
};

export const isGtComparisonCondition = <TVal>(
  condition: Condition<TVal>,
): condition is GtComparisonCondition<TVal> => {
  const gt = condition as GtComparisonCondition<TVal>;
  return gt.$gt !== undefined;
};

export const isComparisonCondition = <TVal>(
  condition: Condition<TVal>,
): condition is ComparisonCondition<TVal> => {
  return (
    isEqComparisonCondition(condition) ||
    isLtComparisonCondition(condition) ||
    isLeComparisonCondition(condition) ||
    isGeComparisonCondition(condition) ||
    isGtComparisonCondition(condition)
  );
};

export const isNotConditionV0 = <TVal>(
  condition: Condition<TVal>,
): condition is NotConditionV0<TVal> => {
  const not = condition as NotConditionV0<TVal>;
  return not.op === 'not' && !!not.arg;
};

export const isNotCondition = <TVal>(
  condition: Condition<TVal>,
): condition is NotCondition<TVal> => {
  const not = condition as NotCondition<TVal>;
  return not.$not !== undefined;
};

export const isOrConditionV0 = <TVal>(
  condition: Condition<TVal>,
): condition is OrConditionV0<TVal> => {
  const or = condition as OrConditionV0<TVal>;
  return or.op === 'or' && Array.isArray(or.arg);
};

export const isOrCondition = <TVal>(
  condition: Condition<TVal>,
): condition is OrCondition<TVal> => {
  const or = condition as OrCondition<TVal>;
  return or.$or !== undefined;
};

export const isAndConditionV0 = <TVal>(
  condition: Condition<TVal>,
): condition is AndConditionV0<TVal> => {
  const and = condition as AndConditionV0<TVal>;
  return and.op === 'and' && Array.isArray(and.arg);
};

export const isAndCondition = <TVal>(
  condition: Condition<TVal>,
): condition is AndCondition<TVal> => {
  const and = condition as AndCondition<TVal>;
  return and.$and !== undefined;
};

const boolConditionKeys = ['arg'];

export const isBooleanCondition = <TVal>(
  condition: Condition<TVal>,
): condition is BooleanCondition<TVal> => {
  const asBoolean = condition as BooleanCondition<TVal>;
  return (
    typeof asBoolean === 'object' &&
    typeof asBoolean.arg === 'object' &&
    Object.keys(asBoolean).every(
      key => boolConditionKeys.includes(key) || baseConditionKeys.includes(key),
    )
  );
};

export const isIncludesCondition = <TVal>(
  condition: Condition<TVal>,
): condition is IncludesCondition<TVal> => {
  const includes = condition as IncludesCondition<TVal>;
  return includes.$includes !== undefined;
};
