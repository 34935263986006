import {CodeList, CodeListMap} from 'api/types';
import {Metadata} from '../types';
import {getExpressionManifestationsLocalCodelist} from './getExpressionManifestationsLocalCodelist';

export const getExpressionLocalCodelistMap = (
  expressionId: string,
  state: {metadataEdit: {metadata?: Pick<Metadata, 'manifestations'>}},
  codelists: {
    productFormOverride: CodeList;
    productForm: CodeList;
    productFormDetails: CodeList;
  },
): CodeListMap => {
  const expressionManifestationCodelist =
    getExpressionManifestationsLocalCodelist(state, expressionId, codelists);
  return {
    expression_manifestation: expressionManifestationCodelist,
  };
};
