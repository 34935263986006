import {
  EditStatuses,
  EntityEditStatus,
  MetadataEditState,
} from 'services/data/metadata/types';
import {assertManifestation} from './assertManifestation';
import {getMergedEditStatus} from './getMergedEditStatus';
import {updateEditStatuses} from './updateEditStatuses';

export function updateEditStatusOnMove(
  manifestationId: string,
  toExpressionId: string,
  editStatuses: EditStatuses,
  state: MetadataEditState,
): EditStatuses {
  const savedManifestation = assertManifestation(
    manifestationId,
    state.savedMetadata,
    undefined,
    'saved',
  );
  const current: EntityEditStatus | undefined = editStatuses[manifestationId];
  const hasMoved = savedManifestation.expressionId !== toExpressionId;
  const newStatus = getMergedEditStatus({hasMoved}, current);
  return updateEditStatuses(manifestationId, newStatus, editStatuses);
}
