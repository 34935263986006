import React from 'react';
import {IconName, IconProps} from './types';
import {AIIcon} from './AIIcon';
import {AddIcon} from './AddIcon';
import {AddItemIcon} from './AddItemIcon';
import {ArrowRightIcon} from './ArrowRightIcon';
import {AudioBookIcon} from './AudioBookIcon';
import {BackIcon} from './BackIcon';
import {BuildingIcon} from './BuildingIcon';
import {CalendarIcon} from './CalendarIcon';
import {CancelEditIcon} from './CancelEditIcon';
import {CartoonIcon} from './CartoonIcon';
import {CheckCircleIcon} from './CheckCircleIcon';
import {CheckIcon} from './CheckIcon';
import {CheckRoundBackgroundIcon} from './CheckRoundBackgroundIcon';
import {ChevronLeftIcon} from './ChevronLeftIcon';
import {ChevronRightIcon} from './ChevronRightIcon';
import {ChevronUpIcon} from './ChevronUpIcon';
import {CircleIcon} from './CircleIcon';
import {CloseIcon} from './CloseIcon';
import {CloseSmallIcon} from './CloseSmallIcon';
import {CommentsFilledIcon} from './CommentsFilledIcon';
import {CommentsIcon} from './CommentsIcon';
import {ConnectionIcon} from './ConnectionIcon';
import {DeleteIcon} from './DeleteIcon';
import {DigitalPlatformIcon} from './DigitalPlatformIcon';
import {DocumentIcon} from './DocumentIcon';
import {DoubleChevronLeftIcon} from './DoubleChevronLeftIcon';
import {DoubleChevronRightIcon} from './DoubleChevronRightIcon';
import {DownloadIcon} from './DownloadIcon';
import {DragIcon} from './DragIcon';
import {DropDownIcon} from './DropDownIcon';
import {EbookIcon} from './EbookIcon';
import {EditCircleIcon} from './EditCircleIcon';
import {EditIcon} from './EditIcon';
import {EventIcon} from './EventIcon';
import {FilesIcon} from './FilesIcon';
import {FilmIcon} from './FilmIcon';
import {FilterIcon} from './FilterIcon';
import {GhostIcon} from './GhostIcon';
import {HistoryIcon} from './HistoryIcon';
import {InfoCircleIcon} from './InfoCircleIcon';
import {InfoIcon} from './InfoIcon';
import {LessIcon} from './LessIcon';
import {LockedIcon} from './LockedIcon';
import {MLIcon} from './MLIcon';
import {MLIcon2} from './MLIcon2';
import {MenuIcon} from './MenuIcon';
import {MetadataSelectionIcon} from './MetadataSelectionIcon';
import {MinusIcon} from './MinusIcon';
import {MoreIcon} from './MoreIcon';
import {MoveIcon} from './MoveIcon';
import {MusicIcon} from './MusicIcon';
import {NoneIcon} from './NoneIcon';
import {NotificationIcon} from './NotificationIcon';
import {OpenIcon} from './OpenIcon';
import {OtherItemIcon} from './OtherItemIcon';
import {PersonNotVerifiedIcon} from './PersonNotVerifiedIcon';
import {PocketBookIcon} from './PocketBookIcon';
import {PreviewIcon} from './PreviewIcon';
import {PrintBookIcon} from './PrintBookIcon';
import {RadioOnIcon} from './RadioOnIcon';
import {RemoveCircleIcon} from './RemoveCircleIcon';
import {RemoveIcon} from './RemoveIcon';
import {RoundBackgroundIcon} from './RoundBackgroundIcon';
import {SchoolBookIcon} from './SchoolBookIcon';
import {SearchIcon} from './SearchIcon';
import {SendIcon} from './SendIcon';
import {SeriesIcon} from './SeriesIcon';
import {SettingsAlternativeIcon} from './SettingsAlternativeIcon';
import {SettingsIcon} from './SettingsIcon';
import {SnoozeIcon} from './SnoozeIcon';
import {StarIcon} from './StarIcon';
import {StarSmallIcon} from './StarSmallIcon';
import {SwapIcon} from './SwapIcon';
import {UserIcon} from './UserIcon';
import {VerifiedIcon} from './VerifiedIcon';

type Props = IconProps & {
  icon: IconName;
};

const iconMap: {[name in IconName]: React.FC<IconProps>} = {
  Add: AddIcon,
  AddItem: AddItemIcon,
  AI: AIIcon,
  ArrowRight: ArrowRightIcon,
  AudioBook: AudioBookIcon,
  Back: BackIcon,
  Building: BuildingIcon,
  Calendar: CalendarIcon,
  CancelEdit: CancelEditIcon,
  Cartoon: CartoonIcon,
  Check: CheckIcon,
  CheckCircle: CheckCircleIcon,
  CheckRoundBackground: CheckRoundBackgroundIcon,
  ChevronLeft: ChevronLeftIcon,
  ChevronRight: ChevronRightIcon,
  ChevronUp: ChevronUpIcon,
  Circle: CircleIcon,
  Close: CloseIcon,
  CloseSmall: CloseSmallIcon,
  Comments: CommentsIcon,
  CommentsFilled: CommentsFilledIcon,
  Connection: ConnectionIcon,
  Copy: DocumentIcon,
  Delete: DeleteIcon,
  DigitalPlatform: DigitalPlatformIcon,
  Document: DocumentIcon,
  DoubleChevronLeft: DoubleChevronLeftIcon,
  DoubleChevronRight: DoubleChevronRightIcon,
  Download: DownloadIcon,
  Drag: DragIcon,
  DropDown: DropDownIcon,
  Ebook: EbookIcon,
  Edit: EditIcon,
  EditCircle: EditCircleIcon,
  Event: EventIcon,
  Files: FilesIcon,
  Film: FilmIcon,
  Filter: FilterIcon,
  Ghost: GhostIcon,
  History: HistoryIcon,
  Info: InfoIcon,
  InfoCircle: InfoCircleIcon,
  Less: LessIcon,
  Locked: LockedIcon,
  Menu: MenuIcon,
  MetadataSelection: MetadataSelectionIcon,
  Minus: MinusIcon,
  ML: MLIcon,
  ML2: MLIcon2,
  More: MoreIcon,
  Move: MoveIcon,
  Music: MusicIcon,
  None: NoneIcon,
  Notification: NotificationIcon,
  Open: OpenIcon,
  OtherItem: OtherItemIcon,
  PersonNotVerified: PersonNotVerifiedIcon,
  PocketBook: PocketBookIcon,
  Preview: PreviewIcon,
  PrintBook: PrintBookIcon,
  RadioOn: RadioOnIcon,
  RemoveCircle: RemoveCircleIcon,
  Remove: RemoveIcon,
  RoundBackground: RoundBackgroundIcon,
  SchoolBook: SchoolBookIcon,
  Save: SendIcon,
  Search: SearchIcon,
  Send: SendIcon,
  Series: SeriesIcon,
  SettingsAlternative: SettingsAlternativeIcon,
  Settings: SettingsIcon,
  Snooze: SnoozeIcon,
  Star: StarIcon,
  StarSmall: StarSmallIcon,
  Swap: SwapIcon,
  User: UserIcon,
  Verified: VerifiedIcon,
};

export const IconNames = Object.keys(iconMap) as IconName[];

export const Icon: React.FC<Props> = ({icon, ...props}) => {
  return iconMap[icon](props);
};
