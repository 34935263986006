import {assert} from 'assert-ts';
import {CodeListId, CodeListMap} from 'api/types';
import {ManifestationImportProps} from 'services/data/metadata/types';
import {getRestrictedManifestationCodelist} from './getRestrictedManifestationCodelist';

export const getRestrictedManifestationCodelists = (
  importProps: ManifestationImportProps,
  outerCodelists: CodeListMap,
): CodeListMap => {
  if (importProps === undefined) {
    return outerCodelists;
  }

  const restrictedCodelists = {...outerCodelists};

  Object.keys(restrictedCodelists).forEach(key => {
    const id = key as CodeListId;
    const outer = assert(outerCodelists[id]);
    restrictedCodelists[id] = getRestrictedManifestationCodelist(
      importProps,
      outer,
    );
  });

  return restrictedCodelists;
};
