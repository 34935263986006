import React from 'react';
import {FieldRequiredSimple} from 'schemaDefinition/types';
import {TextFieldProps} from '../types';
import {BaseFieldProps} from './types';
import {Html} from '../text';
import {FieldLabel} from './FieldLabel';

type Props = BaseFieldProps &
  Pick<TextFieldProps, 'placeholder' | 'onChange' | 'onBlur' | 'id'> &
  Required<Pick<TextFieldProps, 'name'>> & {
    value: string | null;
    required?: FieldRequiredSimple;
    maxLength?: number;
  };

export const HtmlField: React.FC<Props> = ({
  label,
  name,
  id,
  value,
  required = false,
  error = false,
  ...props
}) => {
  return (
    <div>
      {/* Label and count */}
      {label ? (
        <FieldLabel
          label={label}
          required={required}
          error={error}
          htmlFor={id}
        />
      ) : null}
      <Html name={name} id={id} value={value} {...props} />
    </div>
  );
};
