import React from 'react';
import {Grid, SxProps} from '@mui/material';
import {ResponsiveStyleValue} from '@mui/system';

type RelativeSize = 'full' | 'large' | 'medium' | 'small';
type Props = {
  relativeSize?: RelativeSize;
  sx?: SxProps;
};

const columnsMap: {[size in RelativeSize]: ResponsiveStyleValue<number>} = {
  full: [null, null, null, 12, 12],
  large: [null, null, null, null, 12],
  medium: [null, null, null, null, null],
  small: [null, null, null, 5, 5],
};

export const GridLayout: React.FC<React.PropsWithChildren<Props>> = ({
  relativeSize = 'full',
  sx = {flex: 1},
  children,
}) => {
  return (
    <Grid container columns={columnsMap[relativeSize]} spacing={2} sx={sx}>
      {children}
    </Grid>
  );
};
