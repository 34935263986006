import isEqual from 'lodash/isEqual';
import {Agent} from 'api/types';
import {isNullish} from 'services/utils/isNullish';

/**
 *
 * @param agent1
 * @param agent2
 * @returns
 */
export const areAgentsEqual = (
  agent1: Agent | undefined | null,
  agent2: Agent | undefined | null,
): boolean => {
  const a1 = isNullish(agent1) ? ({} as Agent) : agent1;
  const a2 = isNullish(agent2) ? ({} as Agent) : agent2;
  const {agentType: agent1Type, ...agent1Props} = a1;
  const {agentType: agent2Type, ...agent2Props} = a2;

  if (
    agent1Type !== agent2Type &&
    !isNullish(agent1Type) &&
    !isNullish(agent2Type)
  ) {
    return false;
  }

  return isEqual(agent1Props, agent2Props);
};
