import {PreviewConfiguration} from 'schema/types';
import {Dewey} from 'schemas/renderers';

export const workPreviewConfig: PreviewConfiguration = {
  layout: {
    linkedValuesByRoles: true,
    groups: {
      info: {
        direction: 'horizontal',
      },
    },
  },
  partValueRenderers: {
    dewey: Dewey,
  },
};
