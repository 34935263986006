import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const SendIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" transform="scale(1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0179 10.5874L3.92383 5.19662L7.49559 12L3.92383 18.8033L19.0179 13.4126C20.3453 12.9386 20.3453 11.0614 19.0179 10.5874ZM9.49197 11.5L8.07623 8.80335L17.0268 12L8.07623 15.1966L9.49196 12.5H12C12.2761 12.5 12.5 12.2761 12.5 12C12.5 11.7239 12.2761 11.5 12 11.5H9.49197Z"
      />
    </SvgIcon>
  );
};
