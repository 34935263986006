import {CodeListMap} from 'api/types';
import {Schema} from 'schemaDefinition/types';
import {useCodelists} from 'services/codeLists';
import {useSchemaCodelistIds} from './useSchemaCodelistIds';

/**
 * Assumes needed codelists have been loaded
 * @param schema
 * @returns
 */
export const useSchemaCodelists = <TVal>(
  schema: Schema<TVal> | undefined,
): CodeListMap => {
  const codelistIds = useSchemaCodelistIds(schema);
  return useCodelists(codelistIds);
};
