import React, {CSSProperties, useMemo} from 'react';
import {SxProps} from '@mui/material';
import {ColorPalette} from 'theme';
import {IconButton, Text} from 'components';
import {DndItemProps} from '../../types';

const style: CSSProperties = {
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  borderRadius: 5,
  backgroundColor: ColorPalette.primary.lightGreen,
  margin: '5px',
  paddingLeft: '10px',
};

const sx: SxProps = {
  color: ColorPalette.primary.burgundy,
};

export const DndItem: React.FC<DndItemProps> = ({
  id,
  label,
  active,
  action,
}) => {
  const wrapperStyle = useMemo(() => {
    return {
      ...style,
      backgroundColor: active
        ? ColorPalette.primary.warmGreen
        : ColorPalette.primary.lightGreen,
    };
  }, [active]);

  const ItemAction = useMemo(() => {
    if (active || !action) {
      return undefined;
    }
    return (
      <IconButton
        icon={action.type === 'add' ? 'Add' : 'Remove'}
        onClick={() => action.action({id, label})}
      />
    );
  }, [action, active, id, label]);

  return (
    <div style={wrapperStyle}>
      <Text variant="h4" sx={sx}>
        {label}
      </Text>
      {ItemAction}
    </div>
  );
};
