import {InputStateEnum, InputTextFieldProps} from './types';
import {MuiTextFieldStyled} from '../../muiStyled';

export const InputTextField: React.FC<InputTextFieldProps> = ({
  value,
  name,
  cardinality,
  readonly,
  error,
  placeholder,
  params,
  onFocus,
  onBlur,
  handleKeyCapture,
  inputState,
}) => {
  // For some reason, useCodeInputContext() returns undefined
  // const {state, handleKeyCapture} = useCodeInputContext();
  const singlePlaceholder = cardinality === 'single' ? placeholder : undefined;
  const multiplePlaceholder =
    cardinality === 'multiple'
      ? Array.isArray(value) && value.length > 0
        ? undefined
        : placeholder
      : undefined;

  const {inputProps, ...otherProps} = params;
  const {className, ...otherInputProps} = inputProps;
  const classNames =
    className +
    (inputState?.state === InputStateEnum.itemFocus
      ? ' Ebba-hide-input-cursor'
      : '');

  return (
    <MuiTextFieldStyled
      // ref={inputRef}
      // inputRef={setNodeRef}
      onKeyDownCapture={handleKeyCapture}
      {...otherProps}
      id={name}
      name={name}
      readonly={readonly}
      error={error !== false}
      placeholder={singlePlaceholder}
      inputProps={{
        ...otherInputProps,
        placeholder: multiplePlaceholder,
        autoComplete: 'off', // disable autocomplete and autofill
        className: classNames,
      }}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};
