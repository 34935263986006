import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const DropDownIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12 15.41L7.29004 10.71L8.71004 9.29004L12 12.59L15.29 9.29004L16.71 10.71L12 15.41Z" />
    </SvgIcon>
  );
};
