import {CodeListRef} from 'api/types';
import {reverseMap} from 'api/mappers/reverseMap';

type ThemaInterest = '5AB' | '5AC' | '5AG' | '5AK' | '5AN';

type ThemaInterestToIntellectualLevelMapping = {
  [code in ThemaInterest]: string;
};

// Thema interesse to intellectual level
export const themaInterestToIntellectualLevelMap: ThemaInterestToIntellectualLevelMapping =
  {
    '5AB': CodeListRef.INTELLECTUAL_LEVEL['0-3'],
    '5AC': CodeListRef.INTELLECTUAL_LEVEL['3-6'],
    '5AG': CodeListRef.INTELLECTUAL_LEVEL['6-9'],
    '5AK': CodeListRef.INTELLECTUAL_LEVEL['9-12'],
    '5AN': CodeListRef.INTELLECTUAL_LEVEL['12-16'],
  };

export const mapThemaInterestToIntellectualLevel = (
  themaInterest: string,
): string | undefined => {
  return (
    themaInterestToIntellectualLevelMap[themaInterest as ThemaInterest] ??
    undefined
  );
};

export const mapIntellectualLevelToThemaInterest = (
  intellectualLevel: string,
): string | undefined => {
  return (
    reverseMap(intellectualLevel, themaInterestToIntellectualLevelMap) ??
    undefined
  );
};

export const hasMappableThemaInterest = (themaInterests: string[]): boolean => {
  const mappableThemaInterests = Object.keys(
    themaInterestToIntellectualLevelMap,
  );
  return (
    themaInterests.filter(themaInterest =>
      mappableThemaInterests.includes(themaInterest),
    ).length > 0
  );
};
