import React, {useMemo} from 'react';
import {FCWithChildren} from 'types';
import {Text} from 'components/text/Text';
import {TextProps} from './types';
import './TextMatch.scss';
import {findMatches} from './functions/findMatches';

export type TextMatchProps = TextProps & {
  /** Text fragments mathcing regex/string will be highlighted  */
  match: RegExp | string | undefined;
};

/**
 * Renders text with highlight of fragments of text matching match using <mark>...</mark> element
 */
export const TextMatch: FCWithChildren<TextMatchProps> = ({
  match,
  children,
  ...textProps
}) => {
  const {original, matches} = useMemo(
    () => findMatches(children, match),
    [children, match],
  );

  return original ? (
    <Text {...textProps}>{original}</Text>
  ) : matches ? (
    <Text {...textProps}>
      {matches.map((m, i) =>
        m.type === 'match' ? (
          <mark key={`${i}${m.value}`}>{m.value}</mark>
        ) : (
          <span key={`${i}${m.value}`}>{m.value}</span>
        ),
      )}
    </Text>
  ) : null;
};
