import {useMemo} from 'react';
import {ManifestationStatus} from 'api/types';
import {useLocalization} from 'localization';

export const useManifestationStatusLabel = (
  status: ManifestationStatus,
): string => {
  const {tLoose} = useLocalization();
  return useMemo(() => {
    return tLoose(`entity.manifestation.status.full.${status}`);
  }, [status, tLoose]);
};
