import {FilterOperation, FilterType} from 'api/types';

export const getDefaultValue = (
  type: FilterType,
  operation: FilterOperation,
): Array<string> => {
  switch (type) {
    case 'BOOLEAN':
      switch (operation) {
        case 'EQUAL':
          return ['true'];
        default:
          return [];
      }
    default:
      return [];
  }
};
