import {AgentWorkSummary} from 'api/types';
import {TypedSchema} from 'schemaDefinition/types';
import {workSummarySchema} from './workSummarySchema';

/**
 * Work schema extended with parts from expression and manifestation,
 * .i.e. mainTitles
 */
export const agentWorkSummarySchema: TypedSchema<AgentWorkSummary> = {
  name: 'agentWorkSummary',
  parts: [
    ...workSummarySchema.parts,
    {
      type: 'codelist',
      name: 'roles',
      cardinality: 'multiple',
      codelistId: 'WORK_AND_EXPRESSION_ROLE_TYPE',
    },
  ],
};
