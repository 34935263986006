import {
  CustomMenuItemProps,
  MenuDividerProps,
  MenuItemProps,
  StandardMenuItemProps,
} from '../types';

export const asCustomItem = <TItemId = string>(
  item: MenuItemProps<TItemId>,
): CustomMenuItemProps<TItemId> | undefined => {
  const custom = item as Partial<CustomMenuItemProps<TItemId>>;
  return custom.renderItem
    ? (custom as CustomMenuItemProps<TItemId>)
    : undefined;
};

export const asStandardItem = <TItemId = string>(
  item: MenuItemProps<TItemId>,
): StandardMenuItemProps<TItemId> | undefined => {
  const custom = item as Partial<StandardMenuItemProps<TItemId>>;
  return custom.title ? (custom as StandardMenuItemProps<TItemId>) : undefined;
};

export const asDivider = <TItemId = string>(
  item: MenuItemProps<TItemId>,
): MenuDividerProps | undefined => {
  const custom = item as Partial<MenuDividerProps>;
  return custom.type === 'divider' ? (custom as MenuDividerProps) : undefined;
};
