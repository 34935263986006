import {assert} from 'assert-ts';
import {Metadata} from 'services/data/metadata/types';

export function getManifestationExpressionId(
  data: Metadata,
  manifestationId: string,
): string {
  const expressionId = data.manifestations.find(m => m.id === manifestationId)
    ?.expressionId;
  return assert(
    expressionId,
    'getManifestationExpressionId: unknownmanifestationId',
    {manifestationId},
  );
}
