import {DataLoadByIdStatus, Thesaurus, ThesaurusId} from 'api/types';
import {LoadByIdAction} from 'services/utils/redux/actions';
import {loadByIdReducer} from 'services/utils/redux/functions/loadByIdReducer';

export type ThesaurusesState = DataLoadByIdStatus<Thesaurus, ThesaurusId>;

export enum ThesaurusActionType {
  LOAD_THESAURUS = 'LOAD_THESAURUS',
}

export type ThesaurusesAction_LoadById = {
  type: ThesaurusActionType.LOAD_THESAURUS;
} & LoadByIdAction<Thesaurus, ThesaurusId>;

export type ThesaurusesAction = ThesaurusesAction_LoadById;

export const ThesaurusesDefaultState: ThesaurusesState = {};

export const thesaurusesReducer = (
  state: ThesaurusesState = ThesaurusesDefaultState,
  action: ThesaurusesAction,
): ThesaurusesState => {
  switch (action.type) {
    case ThesaurusActionType.LOAD_THESAURUS: {
      return loadByIdReducer(state, action);
    }
    default: {
      return state;
    }
  }
};
