import {useCallback, useMemo} from 'react';
import {useDndContext} from '@dnd-kit/core';
import {SortingStrategy} from '@dnd-kit/sortable';
import {assert} from 'assert-ts';
import {Spacing} from '../types';
import {getItemSpacing, getRectTransform} from '../functions';

const defaultSpacing = {marginX: 0, marginY: 0, gutterX: 0, gutterY: 0};

export const useVariableWrappedItemsSortingStrategy = (
  itemIds: string[],
  spacingConfig?: Partial<Spacing>,
): SortingStrategy => {
  const {containerNodeRect, droppableRects} = useDndContext();
  const spacing = useMemo((): Spacing => {
    if (itemIds.length === 0) {
      return defaultSpacing;
    }

    const itemRects = itemIds
      .map(id => droppableRects.get(id))
      .filter(r => r)
      .map(r => assert(r));

    if (itemRects.length < itemIds.length) {
      return defaultSpacing;
    }

    const itemSpacing = getItemSpacing(containerNodeRect, itemRects);

    return {...defaultSpacing, ...itemSpacing, ...spacingConfig};
  }, [containerNodeRect, droppableRects, itemIds, spacingConfig]);

  return useCallback(
    (args: Parameters<SortingStrategy>[0]): ReturnType<SortingStrategy> => {
      return getRectTransform(
        containerNodeRect,
        spacing,
        args.rects,
        args.activeIndex,
        args.overIndex,
        args.index,
      );
      // return rectSortingStrategy(...args);
    },
    [containerNodeRect, spacing],
  );
};
