import {useMemo} from 'react';
import {assert} from 'assert-ts';
import merge from 'lodash/merge';
import {Concept, DerivedConcept} from 'types';
import {TableConfiguration} from 'schema/table/types';
import {AdornmentResolver} from 'schema/types';
import {clone} from 'services/utils';
import {useDefaultAdornementResolver} from 'schema';
import {defaultTableConfig} from 'schema/table/configuration/defaultTableConfig';
import {workSummaryTableConfig} from 'schemas/configurations/table/workSummaryTableConfig';
import {SchemaReference} from '../types';
import {
  collectionTableConfig,
  corporationAgentTableConfig,
  eventAgentTableConfig,
  personAgentTableConfig,
  publisherAgentTableConfig,
  workTableConfig,
} from '../configurations/table';
import {useDefaultColumns} from './useDefaultColumns';

const mergeConfigs = (
  entityConfig: TableConfiguration,
  overrides: Partial<TableConfiguration> = {},
  defaultAdornmentResolver?: AdornmentResolver,
): TableConfiguration => {
  const {partValueRenderers, adornmentResolver, ...rest} = entityConfig;
  const {
    partValueRenderers: partValueRenderersOverrides,
    adornmentResolver: adornmentResolverOverride,
    ...restOverrides
  } = overrides;

  const config = overrides
    ? {
        ...merge(clone(rest), restOverrides),
        partValueRenderers: {
          ...partValueRenderers,
          ...partValueRenderersOverrides,
        },
      }
    : entityConfig;

  return {
    ...config,
    adornmentResolver:
      adornmentResolverOverride ??
      adornmentResolver ??
      defaultAdornmentResolver,
  };
};

export const useTableConfiguration = (
  schemaReference: SchemaReference,
  overrides?: Partial<TableConfiguration>,
): TableConfiguration => {
  const defaultAdornmentResolver = useDefaultAdornementResolver();
  const defaultColumns = useDefaultColumns(schemaReference);

  return useMemo(() => {
    switch (schemaReference) {
      case Concept.work:
        return mergeConfigs(
          workTableConfig,
          overrides,
          defaultAdornmentResolver,
        );
      case DerivedConcept.workSummary:
        return mergeConfigs(
          workSummaryTableConfig,
          overrides,
          defaultAdornmentResolver,
        );

      case Concept.expression:
      case Concept.manifestation:
        return mergeConfigs(
          defaultTableConfig,
          {
            defaultColumns,
          },
          defaultAdornmentResolver,
        );
      default: {
        const {entity, subType} = schemaReference;
        switch (entity) {
          case Concept.agent: {
            switch (subType) {
              case Concept.person:
                return mergeConfigs(personAgentTableConfig, overrides);
              case Concept.publisher:
                return mergeConfigs(publisherAgentTableConfig, overrides);
              case Concept.corporation:
                return mergeConfigs(corporationAgentTableConfig, overrides);
              case Concept.event:
                return mergeConfigs(eventAgentTableConfig, overrides);
              default: {
                assert(false, `useTableConfiguration: Missing mapping`, {
                  schemaReference,
                });
                return defaultTableConfig;
              }
            }
          }
          case Concept.collection: {
            switch (subType) {
              case Concept.series:
              case Concept.educationalSeries:
              case Concept.otherConnection:
              case Concept.publisherSeries:
                return mergeConfigs(collectionTableConfig, overrides);
              default: {
                assert(false, `useTableConfiguration: Missing mapping`, {
                  schemaReference,
                });
                return defaultTableConfig;
              }
            }
          }
          default: {
            assert(false, `useTableConfiguration: Missing mapping`, {
              schemaReference,
            });
            return defaultTableConfig;
          }
        }
      }
    }
  }, [defaultAdornmentResolver, defaultColumns, overrides, schemaReference]);
};
