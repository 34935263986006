import {
  Concept,
  EntityMainType,
  EntitySubType,
  EntitySubTypes,
  LinkEntityType,
} from 'types';

export const inTargetEntityType = (
  subtype: Concept,
  type: LinkEntityType,
): boolean => {
  return (
    subtype === type ||
    (EntitySubTypes[type as EntityMainType] ?? []).includes(
      subtype as EntitySubType,
    )
  );
};
