import {GridSingleSelectColDef} from '@mui/x-data-grid/models/colDef/gridColDef';
import {CodeListMap} from 'api/types';
import {Part} from 'schemaDefinition/types';

export const mapValueOptions = <TVal>(
  part: Part<TVal>,
  codelists?: CodeListMap,
): GridSingleSelectColDef['valueOptions'] => {
  const showCode = part.type === 'codelist' && part.showCode;

  switch (part.type) {
    case 'codelist':
    case 'codelist|text':
      if (!codelists) {
        return undefined;
      }

      return codelists[part.codelistId]?.codes
        .filter(c => !c.disabled)
        .filter(c => !c.deactivated)
        .map(c => {
          return {
            value: c.code,
            label: c.value + (showCode ? ` (${c.code})` : ''),
          };
        });
    default:
      return undefined;
  }
};
