import {useMemo} from 'react';
import assert from 'assert-ts';
import {PageSizeProps, PaginationState} from 'components/paginator/types';

export const usePageSizeProps = (
  paginationState?: PaginationState,
  allowAll?: boolean,
): PageSizeProps | undefined => {
  return useMemo(() => {
    if (!paginationState) {
      return undefined;
    }

    assert(
      paginationState.setSize,
      'usePageSizeProps: PaginationState must have setSize event',
    );

    return {
      ...paginationState,
      allowAll,
    };
  }, [allowAll, paginationState]);
};
