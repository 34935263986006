import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {AppState} from 'store/types';
import {User} from 'api';

const getValue = (
  state: AppState,
  userId: string | undefined,
): User | undefined =>
  userId ? state.users.data?.find(u => u.id === userId) : undefined;

const useUserSelector = (userId: string | undefined) =>
  useCallback(
    (state: AppState): User | undefined => getValue(state, userId),
    [userId],
  );

/**
 * Hook to look up user by userId, if available/existing.
 */
export const useUserById = (userId: string | undefined): User | undefined => {
  const selector = useUserSelector(userId);
  return useSelector(selector);
};
