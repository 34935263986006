import {valueAsBool} from 'services/utils';
import {BooleanCondition, Valx} from '../types';
import {EvaluationArgs} from './types';
import {evaluateValxWithDefault} from './evaluateValxWithDefault';

export const evaluateBooleanCondition = (
  condition: BooleanCondition<Valx>,
  args: EvaluationArgs,
): boolean => {
  const arg = evaluateValxWithDefault(condition.arg, args, args.value);

  const boolValue = valueAsBool(arg);
  return boolValue;
};
