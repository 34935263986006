import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {Box, SxProps} from '@mui/material';
import {assert} from 'assert-ts';
import {Thesaurus as ThesaurusType} from 'api';
import {assertThesaurusNode} from 'services/thesaurus/functions';
import {findNodeWithAncestors} from 'services/thesaurus/functions/findNodeWithAncestors';
import {unique, useLogValue} from 'services/utils';
import {Thesaurus} from 'components/thesaurus';
import {useThesaurusEditStateContext} from '../contexts';
import {getScrollToValue, getVisibleNodeIndexAndTotal} from '../functions';

const getAncestorIds = (
  nodeId: string | undefined,
  thesaurus: ThesaurusType,
): string[] => {
  if (nodeId) {
    const nodeWithAncestors = findNodeWithAncestors(nodeId, thesaurus);
    if (nodeWithAncestors) {
      return nodeWithAncestors.ancestors.map(a => a.id);
    }
  }

  return [];
};

const scrollContainerSx: SxProps = {
  position: 'flex',
  overflowY: 'scroll',
  overflowX: 'hidden',
};

export const ThesaurusTree: React.FC = () => {
  const {
    categoryId,
    thesaurus,
    getDisplayCode,
    thesaurusEditValue,
    focusNodeId,
    gotoFocusNodeId,
  } = useThesaurusEditStateContext();

  const categoryTree = useMemo(() => {
    return assertThesaurusNode(
      assert(categoryId, 'ThesaurusTree: categoryId expected'),
      thesaurus,
    );
  }, [categoryId, thesaurus]);

  const [expanded, setExpanded] = React.useState<string[]>(
    getAncestorIds(focusNodeId, thesaurus),
  );

  const handleExpand = useCallback(
    (event: React.SyntheticEvent, nodeIds: string[]) => {
      // console.log('handleExpand', nodeIds);
      lastInteractionTimeRef.current = Date.now();
      setExpanded(nodeIds);
    },
    [],
  );

  const handleFocus = useCallback(
    (event: React.SyntheticEvent, nodeId: string) => {
      // console.log('handleFocus', nodeId);
      lastInteractionTimeRef.current = Date.now();
      scrollTofocusNodeIdRef.current = nodeId;
      gotoFocusNodeId(nodeId);
    },
    [gotoFocusNodeId],
  );

  // When focusNodeId changes:
  // => expand to that node
  useEffect(() => {
    const ancestorIds = getAncestorIds(focusNodeId, thesaurus);
    if (ancestorIds.length) {
      setExpanded(old => unique([...old, ...ancestorIds]));
    }
  }, [focusNodeId, thesaurus]);

  const thesaurusScrollContainerRef = useRef<HTMLDivElement>(null);
  const thesaursContainerRef = useRef<HTMLDivElement>(null);
  const scrollTofocusNodeIdRef = useRef<string | undefined>();
  const expandedRef = useRef(expanded);
  const lastInteractionTimeRef = useRef(0);

  // When expanded changes or focusNodeId !== scrollTofocusNodeIdRef, and not recent user interaction:
  // => scroll three view
  useEffect(() => {
    if (
      lastInteractionTimeRef.current + 2000 < Date.now() &&
      (scrollTofocusNodeIdRef.current !== focusNodeId ||
        expandedRef.current !== expanded)
    ) {
      // console.log(
      //   'Scroll if needed:',
      //   'scrollTofocusNodeIdRef.current',
      //   scrollTofocusNodeIdRef.current,
      //   'focusNodeId',
      //   focusNodeId,
      //   'expandedRef.current',
      //   expandedRef.current,
      //   'expanded',
      //   expanded,
      //   'lastInteractionTime',
      //   Date.now() - lastInteractionTimeRef.current,
      //   'thesaursContainerRef.clientHeight',
      //   thesaursContainerRef.current?.clientHeight,
      // );
      scrollTofocusNodeIdRef.current = focusNodeId;
      expandedRef.current = expanded;

      let hasScrolled = false;
      const tryScrollTo = () => {
        if (hasScrolled) {
          // console.log('hasScrolled');
          return;
        }

        const {nodeIndex, totalVisible} = getVisibleNodeIndexAndTotal(
          focusNodeId,
          expanded,
          categoryTree.children ?? [],
        );

        // console.log(
        //   'thesaursContainerRef.height (after timeout): ',
        //   thesaursContainerRef.current?.clientHeight,
        //   ', focusNodeId:',
        //   focusNodeId,
        //   ', expanded:',
        //   expanded,
        //   ', nodeIndex:',
        //   nodeIndex,
        //   'totalVisible:',
        //   totalVisible,
        // );

        const top = getScrollToValue({
          scrollTop: thesaurusScrollContainerRef.current?.scrollTop,
          scrollHeight: thesaurusScrollContainerRef.current?.clientHeight,
          contentHeight: thesaursContainerRef.current?.clientHeight,
          itemCount: totalVisible,
          itemIndex: nodeIndex,
        });

        if (top !== undefined) {
          hasScrolled = true;
          thesaurusScrollContainerRef.current?.scrollTo({
            top,
            behavior: 'smooth',
          });
        }
      };

      setTimeout(tryScrollTo, 100);
      // Hack:
      // Try again, in case rendering was not yet finished on first try
      setTimeout(tryScrollTo, 500);
      setTimeout(tryScrollTo, 1000);
      setTimeout(tryScrollTo, 1500);
    }
  }, [categoryTree.children, expanded, focusNodeId]);

  // useLogMounting('ThesaurusTree');
  useLogValue('focusNodeId', focusNodeId);
  // useLogValue('expanded', expanded);
  // console.log('ThesaurusTree: expanded', expanded);

  return (
    <Box ref={thesaurusScrollContainerRef} sx={scrollContainerSx}>
      <Box ref={thesaursContainerRef}>
        <Thesaurus
          data={categoryTree.children || []}
          thesaurusValue={thesaurusEditValue}
          getDisplayCode={getDisplayCode}
          focusedNodeId={focusNodeId}
          expanded={expanded}
          onNodeToggle={handleExpand}
          onNodeFocus={handleFocus}
        />
      </Box>
    </Box>
  );
};
