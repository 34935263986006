import {assert} from 'assert-ts';
import {TaskV2} from '../types';
import {Builder} from '../../services/utils';
import {Schemas} from '../dto.generated';
import {mapMediaDto} from './mapMediaDto';
import {mapTaskTypeDto} from './mapTaskTypeDto';

export const mapTaskV2Dto = (dto: Schemas.TaskV2Dto): TaskV2 => {
  const {
    workId,
    manifestationId,
    title,
    mainAgent,
    publisherSalesDate,
    inSaleDate,
    contentReceivedDate,
    registered,
    ...metadataRestDto
  } = assert(dto.metadata, 'mapTaskV2Dto: metadata expected', dto);

  return {
    ...(dto as Required<Schemas.TaskV2Dto>),
    id: assert(dto.id, 'mapTaskV2Dto: id expected', dto),
    type: mapTaskTypeDto(assert(dto.type, 'mapTaskV2Dto: type expected', dto)),
    metadata: {
      ...(metadataRestDto as Required<Schemas.MetadataDto>),
      workId: assert(workId, 'mapTaskV2Dto: workId expected', dto.metadata),
      manifestationId: assert(
        manifestationId,
        'mapTaskDto: manifestationId expected',
        dto.metadata,
      ),
      mainTitle: title,
      mainAgent: mapTaskAgentNameDtoToName(mainAgent),
      contentReceivedDate: contentReceivedDate, // valueAsDate(contentReceivedDate),
      publisherSalesDate: publisherSalesDate, // valueAsDate(publisherSalesDate),
      inSaleDate: inSaleDate, // valueAsDate(inSaleDate),
      media: (dto.medias ?? []).map(mapMediaDto),
      registered: registered ? registered : undefined, // No null values, as it will be handled as 0 by date formatter
    },
  };
};

/**
 * We need this simplified agent mapping on task, because backend gives
 * us an agent in a verifiedAgent format, but without id.
 */
const mapTaskAgentNameDtoToName = (
  dto:
    | Schemas.AgentRoleDto
    | Schemas.UnverifiedAgentRoleDto
    | Schemas.UnnamedAgentRoleDto
    | undefined,
): string | undefined => {
  if (!dto) {
    return undefined;
  }

  const verifiedDto = dto as Schemas.AgentRoleDto;
  const unverifiedDto = dto as Schemas.UnverifiedAgentRoleDto;
  const unnamedDto = dto as Schemas.UnnamedAgentRoleDto;

  if (unnamedDto.unnamedAgentRole) {
    return unnamedDto.agentName;
  }
  if (verifiedDto.agentName) {
    const {surName, name} = verifiedDto.agentName;
    return new Builder(surName).add(', ', name).value;
  } else if (unverifiedDto.name) {
    return unverifiedDto.name;
  }

  return undefined;
};
