import {Thesaurus, ThesaurusId, ThesaurusNodeDto} from 'api/types';
import {
  emneord,
  genreandform, // grep,
  thema,
} from './data';

const thesauruses = {
  thema: thema,
  bokbasen: emneord,
  grep: undefined,
  genreandform: genreandform,
} as const;

const getDummyThesaurus = (id: ThesaurusId): Omit<Thesaurus, 'language'> => ({
  id,
  code: id,
  label: `Thesaurus ${id}`,
  children: [
    {
      id: 'code1',
      code: 'code1',
      label: `${id}-1`,
      selectable: true,
    },
    {
      id: 'code2',
      code: 'code2',
      label: `${id}-2`,
      selectable: true,
    },
    {
      id: 'code3',
      code: 'code3',
      label: `${id}-3`,
      selectable: true,
    },
  ],
});

export const getMockThesaurus = (
  id: ThesaurusId,
  _language: string,
): Promise<ThesaurusNodeDto[]> => {
  const thesaurus = thesauruses[id] ?? getDummyThesaurus(id);
  // if (id === 'grep') {
  // Hack: Set all ids
  // let count = 100;
  // const setIds = (node: Schemas.ThesaurusExport) => {
  //   node.id = node.id ?? `dummyid-${count++}`;
  //   if ((node as Schemas.GrepExport).deactivated) {
  //     node.label = `${node.label} (deactivated)`;
  //   }
  //   node.children?.forEach(setIds);
  // };
  // thesaurus.forEach(setIds);
  // Delete deactivated nodes
  // const deleteDeactivated = (node: Schemas.ThesaurusExport) => {
  //   node.children = node.children?.filter(deleteDeactivated);

  //   return !(
  //     (node as Schemas.GrepExport).deactivated ||
  //     node.label.endsWith('muntlig')
  //   );
  // };
  // thesaurus.forEach(deleteDeactivated);

  // const felles = thesaurus
  //   .find(n => n.id === 'opplaeringsnivaa_videregaaende')
  //   ?.children.find(n => n.id === 'utdanningsprogramtype_custom');
  // if (felles) {
  //   const grouped = groupBy(
  //     (felles as unknown as Schemas.GrepExport).children,
  //     n => n.label.split(' ')[0].replace(',', ''),
  //   );
  //   const groupedChildren = Object.keys(grouped).map(key => ({
  //     id: key,
  //     label: key,
  //     children: grouped[key],
  //   }));

  //   felles.children = groupedChildren;
  // }
  // }
  return Promise.resolve((thesaurus ?? []) as ThesaurusNodeDto[]);
};
