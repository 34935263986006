import {Thesaurus} from 'api';
import {NodeCodeToIdsMap, TreeNode} from './types';

/**
 * Returns map of {code: nodeId[]} for all nodes,
 * both with duplicates and single
 * @param thesaurus
 * @returns
 */
export const getNodeCodeToIdsMap = (thesaurus: Thesaurus): NodeCodeToIdsMap => {
  const map: NodeCodeToIdsMap = new Map();
  // Find all {code: id[]} maps, single or duplicates
  const visit = (node: TreeNode) => {
    if (map.has(node.code)) {
      map.set(node.code, [...(map.get(node.code) ?? []), node.id]);
    } else {
      map.set(node.code, [node.id]);
    }
    if (node.children) {
      node.children.forEach(child => visit(child));
    }
  };
  visit(thesaurus);

  return map;
};
