/**
 * Thema codes should be sorted by first number. And all numbers should come last (AA before 2A).
 * It should ONLY be sorted by first numbers, codes with letter first must keep its order.
 */

type ThemaGroup = {
  type: string; // "LETTER" or number as string ("1", "2", etc.)
  codes: string[];
};

export const sortThemaCodes = (themaCodes?: string[]): string[] | undefined => {
  const groups: Array<ThemaGroup> = [];

  themaCodes?.forEach(c => {
    const firstCharacter = c.substring(0, 1).toString();
    const type = isNaN(parseInt(firstCharacter)) ? 'LETTER' : firstCharacter;
    // Groups codes by type to keep the original order.
    const group = groups.find(g => g.type === type);
    if (!group) {
      groups.push({
        type,
        codes: [c],
      });
    } else {
      group.codes.push(c);
    }
  });

  return groups
    .sort((a, b) => compareTypes(a.type, b.type))
    .map(g => g.codes)
    .flat();
};

/**
 * Letters before numbers.
 */
const compareTypes = (a: string, b: string): number => {
  const aIsNumber = !isNaN(parseInt(a));
  const bIsNumber = !isNaN(parseInt(b));

  if (aIsNumber && bIsNumber) {
    return parseInt(a) - parseInt(b);
  } else if (aIsNumber) {
    return 1;
  } else if (bIsNumber) {
    return -1;
  }

  // Don't compare strings.
  return 0;
};
