import last from 'lodash/last';
import {Code, CodeList, CodeListRef} from 'api/types';
import {ManifestationImportProps} from 'services/data/metadata/types';
import {isAudioProductForm} from './isAudioProductForm';

type Filter = (code: Code) => boolean;

const isAudio: Filter = (code: Code) =>
  last(code.code) === '2' ||
  code.code === CodeListRef.PRODUCT_GROUP['Astronomi lydbøker'] ||
  code.code === CodeListRef.PRODUCT_GROUP['Kryssordbøker lydbøker'];

const isEbook: Filter = (code: Code) =>
  last(code.code) === '1' ||
  code.code === CodeListRef.PRODUCT_GROUP['Astronomi ebøker'] ||
  code.code === CodeListRef.PRODUCT_GROUP['Kryssordbøker ebøker'] ||
  code.code === CodeListRef.PRODUCT_GROUP['Tidsskrifter'];

const isNotAudioOrEbook: Filter = (code: Code) =>
  (!isAudio(code) && !isEbook(code)) ||
  // Tidsskrifter can be both ebook and book
  code.code === CodeListRef.PRODUCT_GROUP['Tidsskrifter'];

const isFaglAndHigherEdLitt: Filter = (code: Code) => code.code[0] === '2';
const isFagOnlyLitt: Filter = (code: Code) => code.code[0] === '3';
const isSkjønnlitt: Filter = (code: Code) => code.code[0] === '4';
const isSkolelbøker: Filter = (code: Code) => code.code[0] === '1';

export const getRestrictedAndSortedProductGroupCodelist = (
  {
    work: {literatureType},
    manifestation: {productForm},
  }: ManifestationImportProps,
  outerCodelist: CodeList,
): CodeList => {
  const filterAndSort: {
    include?: Filter;
    first?: Filter;
  } = {};

  if (productForm) {
    // Lydbok
    if (isAudioProductForm(productForm)) {
      filterAndSort.include = isAudio;
    }
    // E-bok
    else if (productForm === CodeListRef.PRODUCT_FORM.ED) {
      filterAndSort.include = isEbook;
    }
    // Else, others
    else {
      filterAndSort.include = isNotAudioOrEbook;
    }
  }

  if (literatureType && literatureType.length > 0) {
    // Fag & lærebøker?
    if (
      [
        CodeListRef.LITERATURE_TYPE.Faglitteratur,
        CodeListRef.LITERATURE_TYPE['Lærebøker (høyere utd.)'],
      ].every(littType => literatureType.includes(littType))
    ) {
      filterAndSort.first = isFaglAndHigherEdLitt;
    }
    // Fag only?
    else if (
      [CodeListRef.LITERATURE_TYPE.Faglitteratur].every(littType =>
        literatureType.includes(littType),
      )
    ) {
      filterAndSort.first = isFagOnlyLitt;
    }
    // Skjønnlitteratur?
    else if (
      literatureType.some(
        littType => littType === CodeListRef.LITERATURE_TYPE.Skjønnlitteratur,
      )
    ) {
      filterAndSort.first = isSkjønnlitt;
    }
    // Skolebøker?
    else if (
      literatureType.some(
        littType => littType === CodeListRef.LITERATURE_TYPE.Skolebøker,
      )
    ) {
      filterAndSort.first = isSkolelbøker;
    }
  }

  const {include, first} = filterAndSort;

  if (include === undefined && first === undefined) {
    return outerCodelist;
  }

  const filteredCodes = include
    ? outerCodelist.codes.filter(include)
    : outerCodelist.codes;
  const firstCodes = first
    ? [...filteredCodes.filter(first), ...filteredCodes.filter(c => !first(c))]
    : filteredCodes;

  return {
    ...outerCodelist,
    codes: firstCodes,
  };
};
