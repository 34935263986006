import {useCallback} from 'react';
import {useStore} from 'react-redux';
import {produce} from 'immer';
import set from 'lodash/set';
import {DataValue} from 'schemaDefinition/types';
import {AppState} from 'store/types';
import {WorkV4} from 'api';
import {MetadataEditAction} from '../metadataEditActionTypes';

export const useSetWorkEditValue = () => {
  const {getState, dispatch} = useStore<AppState, MetadataEditAction>();
  return useCallback(
    (localPath: string, value: DataValue) => {
      const work = (getState().metadataEdit.metadata?.work ?? {}) as WorkV4;
      const newWork = produce(work, draft => {
        set(draft, localPath, value);
      });

      const action: MetadataEditAction = {
        type: 'METADATAEDIT_WORK_EDITED',
        payload: newWork,
      };

      dispatch(action);
    },
    [dispatch, getState],
  );
};
