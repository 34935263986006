import {assert} from 'assert-ts';
import {Concept, ConcreteEntityType} from 'types';
import {isAgentValue} from './isAgentValue';
import {isCollectionValue} from './isCollectionValue';
import {isWorkValue} from './isWorkValue';

export const getConcreteEntityType = (
  value: unknown,
): ConcreteEntityType | undefined => {
  if (!value || typeof value !== 'object' || Array.isArray(value)) {
    return undefined;
  }

  if (isAgentValue(value)) {
    return value.agentType;
  }

  if (isCollectionValue(value)) {
    return value.collectionType;
  }

  if (isWorkValue(value)) {
    return Concept.work;
  }

  // TODO: Links, add manifestation and expression
  assert(false, 'getConcreteEntityType: unknown value', {value});
};
