import {WorkSummary} from 'api/types';
import {TableConfiguration} from 'schema/table/types';
import {MainTitlesCell} from 'schemas/renderers/MainTitlesCell';
import {workSummaryDefaultColumns} from './columns/workSummaryDefaultColumns';
import {workTableConfig} from './workTableConfig';

export const workSummaryTableConfig: TableConfiguration<WorkSummary> = {
  ...workTableConfig,
  defaultColumns: workSummaryDefaultColumns,
  partValueRenderers: {
    ...workTableConfig.partValueRenderers,
    mainTitles: MainTitlesCell,
  },
};
