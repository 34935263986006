import {useMemo} from 'react';
import {
  ResolvedDataFormPartConfiguration,
  StructureOperation,
} from 'schema/types';
import {Part} from 'schemaDefinition/types';
import {useDataFormConfigurationContext} from '../contexts';

type PartProps = Partial<Pick<Part<unknown>, 'type' | 'labelKey' | 'name'>>;

const allStructureOperations: Array<StructureOperation> = [
  'add',
  'move',
  'delete',
];

export const useResolvedDataFormPartConfiguration = (
  part: PartProps | undefined,
): Partial<ResolvedDataFormPartConfiguration> => {
  const config = useDataFormConfigurationContext();

  return useMemo(() => {
    if (!part || part.type === 'expand') {
      return {
        showWhenReadonlyAndEmpty: config.showWhenReadonlyAndEmpty,
        hideStructureOperations: config.hideAllStructureOperations
          ? allStructureOperations
          : undefined,
      };
    }

    const partKey = part.labelKey ?? part.name;
    const partConfig = partKey ? config.parts?.[partKey] : undefined;

    return {
      ...partConfig,
      showWhenReadonlyAndEmpty:
        partConfig?.showWhenReadonlyAndEmpty ??
        config.showWhenReadonlyAndEmpty ??
        false,
      hideStructureOperations: config.hideAllStructureOperations
        ? allStructureOperations
        : partConfig?.hideStructureOperations,
    };
  }, [
    config.hideAllStructureOperations,
    config.parts,
    config.showWhenReadonlyAndEmpty,
    part,
  ]);
};
