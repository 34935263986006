import {useMemo} from 'react';
import {DataValue, Part} from 'schemaDefinition/types';
import {getAdornments} from 'schemaDefinition/functions';
import {usePreviewConfigurationContext} from '../contexts';

export function useAdornments<TVal>(
  part: Part<TVal>,
  value?: DataValue,
): {
  prefix?: string | JSX.Element;
  suffix?: string | JSX.Element;
} {
  const configuration = usePreviewConfigurationContext();

  return useMemo(
    () => getAdornments(part, value, configuration.adornmentResolver),
    [configuration.adornmentResolver, part, value],
  );
}
