import {HttpArgs} from './httpMethod';
import {httpWithMock} from './httpWithMock';

type Args<TBody> = Pick<
  HttpArgs<TBody>,
  | 'subdir'
  | 'queryParams'
  | 'extraHeaders'
  | 'body'
  | 'getTokens'
  | 'abortSignal'
> & {mock?: boolean};

export const httpGet = <TResponse>(args: Args<TResponse>) =>
  httpWithMock<TResponse>({method: 'GET', ...args});

export const httpPost = <TResponse, TBody>(args: Args<TBody>) =>
  httpWithMock<TResponse>({method: 'POST', ...args});
