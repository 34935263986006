import {useCallback} from 'react';
import {useSelector, useStore} from 'react-redux';
import get from 'lodash/get';
import {GetValueById, UseValueById} from 'schema/form/types';
import {DataValue} from 'schemaDefinition/types';
import {AppState} from 'store/types';

const getValue = (
  state: AppState,
  localPath: string | undefined,
  entityId: string,
): DataValue => {
  if (localPath === undefined) {
    return null;
  }

  const changeRequest = (
    state.metadataEdit.metadata?.changeRequests ?? []
  ).find(cr => cr.id.toString() === entityId);

  return changeRequest ? get(changeRequest, localPath) ?? null : null;
};

const useValueSelector = (localPath: string | undefined, entityId: string) =>
  useCallback(
    (state: AppState): DataValue => getValue(state, localPath, entityId),
    [entityId, localPath],
  );

/**
 * Hook to look up entity property value by entityId and local path.
 * Use to trigger updates (re-render) when store changes
 */
export const useChangeRequestEditValue: UseValueById = (
  localPath: string | undefined,
  entityId: string,
): DataValue | undefined => {
  const selector = useValueSelector(localPath, entityId);
  const value = useSelector(selector);

  return value;
};

/**
 * Returns function to look up entity property value by entityId and local path.
 * Use to lookup value in store when needed
 */
export const useGetChangeRequestEditValue = (): GetValueById => {
  const {getState} = useStore<AppState>();
  return useCallback(
    (localPath: string | undefined, entityId: string): DataValue | undefined =>
      getValue(getState(), localPath, entityId),
    [getState],
  );
};
