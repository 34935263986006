import {CodeListMap} from 'api/types';
import {useCodelists} from 'services/codeLists/hooks/useCodelists';
import {useSchemaCodelistIds} from 'schema/hooks/useSchemaCodelistIds';
import {SchemaReference} from '../types';
import {useSchema} from './useSchema';

/**
 * Assumes needed codelists have been loaded
 */
export const useSchemaRefCodelists = (
  schemaRef: SchemaReference,
): CodeListMap => {
  const schema = useSchema(schemaRef);
  const codelistIds = useSchemaCodelistIds(schema);
  return useCodelists(codelistIds);
};
