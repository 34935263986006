import {assert} from 'assert-ts';
import {LinkedValueSearchFieldConfig} from 'feature/linkedValueSearch/types';
import {LinkedValueLink} from 'schemaDefinition/types';
import {isVerifiedLinkedCatalogPostLink} from 'schemaDefinition/functions/isVerifiedLinkedCatalogPostLink';
import {CoreSearchState, DefaultMainEntity, EntitySearchState} from '../types';
import {getDefaultSubEntity} from './getDefaultSubEntity';
import {getSearchString} from './getSearchString';

const defaultState: CoreSearchState = {
  state: EntitySearchState.search,
  searchQuery: '',
  selectedMainEntity: DefaultMainEntity,
};

export const makeInitialSearchState = (
  currentSourceValue: LinkedValueLink | undefined,
  currentSourceConfig: LinkedValueSearchFieldConfig | undefined,
): CoreSearchState => {
  const mainEntity = currentSourceConfig?.entity ?? DefaultMainEntity;
  const defaultTypes = {
    selectedMainEntity: mainEntity,
    selectedSubEntity: getDefaultSubEntity(mainEntity, currentSourceConfig),
  } as const;

  if (!currentSourceValue || currentSourceValue.linkStatus === 'empty') {
    return {
      ...defaultState,
      ...defaultTypes,
    };
  }

  switch (currentSourceValue.linkStatus) {
    case 'unnamed': {
      assert(false, 'makeInitialSearchState: unnamed link not supported');
      break;
    }
    case 'unverified': {
      return {
        state: EntitySearchState.search,
        searchQuery: getSearchString(currentSourceValue) ?? '',
        ...defaultTypes,
      };
    }
    case 'verified': {
      return currentSourceConfig?.initialState === EntitySearchState.search
        ? {
            state: EntitySearchState.search,
            searchQuery: isVerifiedLinkedCatalogPostLink(currentSourceValue)
              ? currentSourceValue.name
              : '',
            ...defaultTypes,
          }
        : {
            ...defaultState,
            ...defaultTypes,
            state: EntitySearchState.viewEntity,
            viewEntity: {
              id: assert(
                currentSourceValue.entityId,
                'useEntitySearchContextProviderValue: entityId expected',
                currentSourceValue,
              ),
            },
          };
    }
  }
};
