import {assert} from 'assert-ts';
import {Code, CodeList, CodeListId, CodeListRef} from 'api/types';
import {Schemas} from 'api/dto.generated';

export const mapCodeListDto = (dto: Schemas.CodelistDto): CodeList => {
  const codelist = {
    id: assert(dto.id, 'mapCodeListDto: dto.id is required'),
    language: dto.language,
    codes: assert(dto.codes, 'mapCodeListDto: dto.codes is required').map<Code>(
      ({code, value, deactivated}) => ({
        code: assert(code, 'mapCodeListDto: dto.codes.code is required'),
        value: assert(value ?? code),
        deactivated,
      }),
    ),
  };

  return assertValidRefs(codelist);
};

const assertValidRefs = (codelist: CodeList): CodeList => {
  const refs =
    (CodeListRef as {[id in CodeListId]?: {[key in string]: string}})[
      codelist.id
    ] ?? {};
  Object.keys(refs).forEach(key => {
    assert(
      codelist.codes.find(c => c.code === refs[key]),
      'Invalid codelist ref value',
      {ref: refs[key], codelistId: codelist.id, codes: codelist.codes},
    );
  });

  return codelist;
};
