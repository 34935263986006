import React, {useMemo} from 'react';
import {Badge, SxProps} from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import Chip from '@mui/material/Chip';
import {styled} from '@mui/material/styles';
import {IconName} from '../icons/types';
import {BaseProps} from '../types';
import {px2rem} from '../../theme';
import {ColorPalette} from '../../theme/colorPalette';
import {Icon} from '../icons';
import {FlexBox, Layout} from '../layout';
import {Text} from '../text';

const ToolButtonRaw = styled(ButtonBase)(() => ({
  // TODO: Use theme colors ({ theme })
  display: 'flex',
  background: ColorPalette.secondary.beige,
  borderRadius: px2rem(34),
  height: px2rem(34),
  transition: '0.2s',
  '&:hover, &.Mui-focusVisible': {
    background: ColorPalette.primary.warmGreen06,
  },
  '&:active, &.Mui-focusVisible': {
    background: ColorPalette.primary.warmGreen06,
  },
  '&.checked': {
    border: `1px solid ${ColorPalette.primary.burgundy}`,
  },
}));

export type ToolButtonProps = BaseProps & {
  icon?: IconName;
  renderIcon?: () => React.ReactNode;
  tag?: string;
  tagColor?: string;
  title: string;
  disabled?: boolean;
  checked?: boolean;
  badgeCount?: number;
  sx?: SxProps;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  iconLast?: boolean;
  iconOnly?: boolean;
};

const badgeContainerSx = {
  flex: 1,
} as const;
/**
 * Big round button with label, icon or tag, and badge. Used on manifestation details
 */
export const ToolButton: React.FC<ToolButtonProps> = ({
  icon,
  renderIcon,
  tag,
  tagColor,
  title,
  disabled,
  checked,
  badgeCount,
  sx,
  onClick,
  iconLast,
  'data-cy': dataCy,
  iconOnly,
}) => {
  const iconNode = icon ? (
    <Layout flex={1} px={1}>
      <Icon icon={icon} />
    </Layout>
  ) : renderIcon ? (
    <Layout flex={1} px={1}>
      {renderIcon()}
    </Layout>
  ) : null;

  const buttonSx: SxProps = useMemo(() => {
    return {
      ...sx,
      width: `${iconOnly ? 2.5 : 8}rem`,
    };
  }, [iconOnly, sx]);

  return (
    <FlexBox>
      <ToolButtonRaw
        data-cy={dataCy}
        onClick={onClick}
        disabled={disabled}
        disableRipple
        className={checked ? 'checked' : undefined}
        sx={buttonSx}>
        <Layout horizontal adjustCenter flex={1} px={1}>
          {iconLast ? null : iconNode}
          {!iconOnly ? (
            <Layout flex={3} adjustLeft>
              <Text variant="body2" textTransform="capitalize">
                {title}
              </Text>
            </Layout>
          ) : null}

          {!iconOnly ? (
            <Layout adjustCenter flex={1}>
              <Badge badgeContent={badgeCount}>
                <Layout kind="primary" adjustCenter sx={badgeContainerSx}>
                  {tag ? (
                    <Chip
                      size="small"
                      label={tag}
                      sx={{background: tagColor}}
                    />
                  ) : null}
                </Layout>
              </Badge>
            </Layout>
          ) : null}
          {iconLast ? iconNode : null}
        </Layout>
      </ToolButtonRaw>
    </FlexBox>
  );
};
