import {useCallback, useMemo, useState} from 'react';
import {assert} from 'assert-ts';
import {LocalizationContextType} from 'localization/context/types';
import {useLocalization} from 'localization';
import {noOp} from 'services/utils/functions/noOp';
import {AlertDialog, AlertDialogProps} from '../AlertDialog';

export type AlertDialogStringProps = Omit<
  AlertDialogProps,
  'isOpen' | 'onCancel' | 'onConfirm'
>;

const getDialogStringProps = (
  propsOrKey: AlertDialogStringProps | string,
  {tLoose, tryT}: LocalizationContextType,
): AlertDialogStringProps => {
  return typeof propsOrKey === 'string'
    ? {
        title: tLoose(propsOrKey + '.title'),
        body: tryT(propsOrKey + '.body'),
        cancelTitle: tryT(propsOrKey + '.cancelTitle'),
        okTitle: tryT(propsOrKey + '.okTitle'),
      }
    : propsOrKey;
};

const dummyStringProps: AlertDialogStringProps = {
  title: 'AlertDialogAsPromiseV2: title missing',
};

const createDialog = (
  isOpen: boolean,
  props: AlertDialogStringProps,
  onCancel: () => void = noOp,
  onConfirm = () => Promise.resolve(),
) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      {...props}
    />
  );
};

/**
 * @deprecated use useAlertDialogAsPromiseV3 or useStaticAlertDialogAsPromise instead
 */
export const useAlertDialogAsPromiseV2 = (
  staticPropsOrRootKey?: AlertDialogStringProps | string,
): {
  /**
   * Present dialog and resolve to true if confirmed (and onConfirm completed successfully),
   * false if cancelled
   * @param onConfirm
   * @param dynamicPropsOrRootKey
   * @returns
   */
  pleaseConfirm: (
    /**
     * Confirm action, performed while dialog is still open with spinner if delayed
     */
    onConfirm: () => void | Promise<void>,
    dynamicPropsOrRootKey?: AlertDialogStringProps | string,
  ) => Promise<boolean>;
  AlertDialog: React.FC;
} => {
  const localization = useLocalization();
  const [dialogInstance, setDialogInstance] = useState<React.ReactElement>(
    createDialog(
      false,
      staticPropsOrRootKey
        ? getDialogStringProps(staticPropsOrRootKey, localization)
        : // Placholder until dynamic props are provided
          dummyStringProps,
    ),
  );

  const pleaseConfirm = useCallback(
    (
      onConfirm: () => void | Promise<void>,
      dynamicPropsOrRootKey?: AlertDialogStringProps | string,
    ) => {
      const propsOrRootKey = assert(
        dynamicPropsOrRootKey || staticPropsOrRootKey,
        'useAlertDialogAsPromise: propsOrRootKey expected',
      );
      const props = getDialogStringProps(propsOrRootKey, localization);

      return new Promise<boolean>(resolve => {
        const cancel = () => {
          setDialogInstance(() => createDialog(false, props));
          resolve(false);
        };

        const confirm = () => {
          return (
            Promise.resolve(onConfirm())
              .then(() => {
                setDialogInstance(() => createDialog(false, props));
                resolve(true);
              })
              // Keep open if failing, assume error is handled by onConfirm
              .catch(() => undefined)
          );
        };

        setDialogInstance(() => createDialog(true, props, cancel, confirm));
      });
    },
    [localization, staticPropsOrRootKey],
  );

  return useMemo(
    () => ({
      pleaseConfirm,
      AlertDialog: () => dialogInstance,
    }),
    [dialogInstance, pleaseConfirm],
  );
};
