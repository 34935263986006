import React, {useMemo} from 'react';
import {SxProps} from '@mui/material';
import {NationalAgentType} from 'api/types';
import {RowId} from 'components/dataTable/types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {Icon, Layout, RadioButtonsField, Spacer, Text} from 'components';
import {Table} from 'schema';
import {
  EditorMode,
  EmptyResultAlternative,
  NationalAgentWithInEbba,
} from '../types';
import {useNationalRegistryAgentSchema} from '../hooks';
import {useNationalAgentsResultTableConfig} from '../hooks/useNationalAgentsResultTableConfig';
import {SearchAgentEmptyResult} from './SearchAgentEmptyResult';
import {SearchAgentError} from './SearchAgentError';
import {SearchingAgent} from './SearchingAgent';

type Props = {
  agentType: NationalAgentType;
  mode: EditorMode;
  isSearching: boolean;
  showSearchErrors: boolean;
  searchResult: NationalAgentWithInEbba[] | undefined;
  resultSelection: RowId[];
  setResultSelection: (selection: RowId[]) => void;
  alternativeSelection: boolean;
  setAlternativeSelection: (selection: boolean) => void;
  alternativeLabel: string;
  emptyResultAlternative: EmptyResultAlternative;
  setEmptyResultAlternative: (value: EmptyResultAlternative) => void;
};

const MAX_HITS = 100;

const searchResultContainerSx: SxProps = {
  padding: 2,
  borderRadius: 2,
  backgroundColor: ColorPalette.enhancedGreen,
};

const searchResultItemsContainerSx: SxProps = {
  padding: 2,
  pb: 1,
  borderRadius: 2,
  backgroundColor: ColorPalette.lightBeige,
};

export const SearchAgentResult: React.FC<Props> = ({
  agentType,
  mode,
  isSearching,
  showSearchErrors,
  searchResult,
  resultSelection,
  setResultSelection,
  alternativeLabel,
  alternativeSelection,
  setAlternativeSelection,
  emptyResultAlternative,
  setEmptyResultAlternative,
}) => {
  const {t} = useLocalization();
  const resultTableConfig = useNationalAgentsResultTableConfig(agentType);
  const nationalAgentSchema = useNationalRegistryAgentSchema(agentType);

  const hitCount = searchResult?.length ?? 0;

  const LayoutCreateNew = useMemo(() => {
    return (
      <Layout horizontal pt={1} pl={2}>
        <RadioButtonsField
          name="createNew"
          value={alternativeSelection ? 'ALTERNATIVE' : null}
          options={[
            {
              code: 'ALTERNATIVE',
              value: alternativeLabel,
            },
          ]}
          onChange={val => setAlternativeSelection(!!val)}
        />
      </Layout>
    );
  }, [alternativeLabel, alternativeSelection, setAlternativeSelection]);

  return (
    // {/* Search errors or search result */}
    showSearchErrors ? (
      <SearchAgentError mode={mode} />
    ) : searchResult ? (
      searchResult.length > 0 ? (
        <Layout adjustBottom sx={searchResultContainerSx}>
          {/* Search result container header */}
          <Layout>
            <Layout horizontal adjustTop adjustLeft>
              <Icon icon="Check" />
              <Spacer size={1} />
              <Layout>
                <Text variant="h3">
                  {t('wizard.agent.step.1.hits', {
                    count: hitCount === MAX_HITS ? `${hitCount}+` : hitCount,
                  })}
                </Text>
                <Text variant="body2">{t('wizard.agent.step.1.b.guide')}</Text>
              </Layout>
            </Layout>
          </Layout>
          <Spacer size={2} />
          <Layout sx={searchResultItemsContainerSx}>
            {/* Alternative radio button */}
            {LayoutCreateNew}
            {/* Search result table */}
            <Layout horizontal adjustBottom>
              <Table
                header={null}
                schema={nationalAgentSchema}
                data={searchResult}
                loading={isSearching}
                selection={resultSelection}
                onSelectionChanged={setResultSelection}
                configuration={resultTableConfig}
              />
            </Layout>
            {/* Alternative radio button */}
            {LayoutCreateNew}
          </Layout>
        </Layout>
      ) : isSearching ? (
        <SearchingAgent />
      ) : (
        <SearchAgentEmptyResult
          emptyResultAlternative={emptyResultAlternative}
          setEmptyResultAlternative={setEmptyResultAlternative}
        />
      )
    ) : (
      <Spacer size={10} />
    )
  );
};
