import {useMemo} from 'react';
import {assert} from 'assert-ts';
import {Concept} from 'types';
import {MetadataValidationResult} from 'schemas/types';
import {useLocalization} from 'localization';
import {Layout, Spacer, Text} from 'components';
import {getMetadataValidationLevels} from '../functions';
import {getMostSevereMetadataValidationPrPart} from '../functions/getMostSevereMetadataValidationPrPart';
import {EntityValidationErrors} from './EntityValidationErrors';

type Props = {
  validation: MetadataValidationResult;
};

export const MetadataValidationErrors: React.FC<Props> = ({
  validation: fullValidation,
}) => {
  const status = 'NEW';
  const localization = useLocalization();
  const {t, tLoose} = localization;

  const validation = useMemo(
    () => getMostSevereMetadataValidationPrPart(fullValidation),
    [fullValidation],
  );

  const validationLevels = useMemo(
    () => getMetadataValidationLevels(validation),
    [validation],
  );

  const {pretext, posttext} = useMemo(() => {
    if (!(validationLevels && status)) {
      return {pretext: undefined, posttext: undefined};
    }

    return {
      pretext: tLoose(`page.metadata.v1.saveWith.${validationLevels}.pretext`),
      posttext:
        validationLevels === 'warning'
          ? t('page.metadata.v1.saveWith.warning.posttext')
          : undefined,
    };
  }, [t, tLoose, validationLevels]);

  if (validation.valid === 'valid') {
    return null;
  }

  return validationLevels ? (
    <Layout data-cy={'metadata-validation-errors'}>
      <Text variant="body2">{pretext}</Text>
      <Spacer size={1} />
      {validation.work && validation.work.valid !== 'valid' ? (
        <EntityValidationErrors
          entity={Concept.work}
          entityId={assert(validation.workId)}
          validation={validation.work}
          status={validation.workStatus}
        />
      ) : null}

      {validation.expressions?.map(expression =>
        expression.valid !== 'valid' ? (
          <Layout key={expression.expressionId}>
            <EntityValidationErrors
              entity={Concept.expression}
              entityId={expression.expressionId}
              validation={expression.validation}
              status={expression.expressionStatus}
            />
            <Layout>
              {expression.manifestations?.map(manifestation =>
                manifestation.validation.valid !== 'valid' ? (
                  <EntityValidationErrors
                    key={manifestation.manifestationId}
                    entity={Concept.manifestation}
                    entityId={manifestation.manifestationId}
                    validation={manifestation.validation}
                    status={manifestation.manifestationStatus}
                  />
                ) : null,
              )}
            </Layout>
            <Spacer size={1} />
          </Layout>
        ) : null,
      )}

      {posttext ? <Text variant="body2">{posttext}</Text> : null}
    </Layout>
  ) : null;
};
