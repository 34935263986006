import {CustomFilterType} from '../types';

export const excludeInternationalFilter: CustomFilterType = {
  id: 1001,
  name: 'productOwnerCode',
  operation: 'NOT_EQUAL',
  values: ['4253', '4252', '4296', '4476'],
  hidden: true,
};

export const excludeBokbasenFilter: CustomFilterType = {
  id: 1002,
  name: 'productOwnerCode',
  operation: 'NOT_EQUAL',
  values: ['0020', '4479'],
  hidden: true,
};

export const getCustomFilters: CustomFilterType[] = [
  excludeInternationalFilter,
  excludeBokbasenFilter,
];
