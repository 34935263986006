import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import assert from 'assert-ts';
import {ReviewStatusType} from 'types';
import {UseValueById} from 'schema/form/types';
import {AppState} from 'store/types';

const getValue = (
  state: AppState,
  rootPath: string | undefined,
  changeRequestId: string,
): ReviewStatusType => {
  if (rootPath === undefined) {
    assert(false, 'useChangeRequestStatus: localPath is undefined');
    return 'none';
  }

  return (
    state.metadataEdit.approvedChanges[changeRequestId]?.rootPaths ?? []
  ).includes(rootPath)
    ? 'approved'
    : 'none';
};

const useValueSelector = (
  localPath: string | undefined,
  changeRequestId: string,
) =>
  useCallback(
    (state: AppState): ReviewStatusType =>
      getValue(state, localPath, changeRequestId),
    [changeRequestId, localPath],
  );

export const useChangeRequestStatus: UseValueById<ReviewStatusType> = (
  /**
   * Change request root path, including 'work.', 'expression.', or 'manifestation.'
   */
  rootPath: string | undefined,
  changeRequestId: string,
): ReviewStatusType => {
  const selector = useValueSelector(rootPath, changeRequestId);
  const value = useSelector(selector);

  return value;
};

export const getChangeRequestStatus = getValue;
