import React from 'react';
import {Checkbox, FormControlLabel} from '@mui/material';

type Props = {
  name: string;
  label: string;
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
};
export const ColumnCheckbox: React.FC<Props> = ({
  name,
  label,
  checked,
  onChange,
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={checked}
          onChange={onChange}
          inputProps={{
            'aria-label': 'controlled',
          }}
        />
      }
      label={label}
    />
  );
};
