import {Data, DataValue} from 'schemaDefinition/types';

const EmptyScope = {};

export const verifyLocalScope = (
  value: DataValue | undefined,
  localScopePath: string | undefined,
): Data | undefined => {
  const verified = (value ?? (localScopePath ? EmptyScope : undefined)) as
    | Data
    | undefined;

  return verified;
};
