import {ColumnConfig} from '../types';

export const sortColumnConfigs = (
  columnConfigs: ColumnConfig[],
  newOrder: string[],
): ColumnConfig[] => {
  columnConfigs.sort((a, b) => {
    return newOrder.indexOf(a.name) - newOrder.indexOf(b.name);
  });

  return columnConfigs;
};
