import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const CancelEditIcon: React.FC<IconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g fillRule="evenodd">
        <g transform="translate(4 4)" fillRule="nonzero">
          <g id="a" transform="translate(1 1)">
            <polygon points="13.42 12 1.42 -1.6653e-16 -1.6653e-16 1.42 12 13.42" />
          </g>
          <path d="m15.1 0.8686c-0.581-0.55738-1.3549-0.8686-2.16-0.8686s-1.579 0.31122-2.16 0.8686l-10.78 10.78v4.32h4.32l10.78-10.78c0.2903-0.27985 0.5213-0.61536 0.679-0.98649 0.1578-0.37113 0.2391-0.77025 0.2391-1.1735s-0.0813-0.80238-0.2391-1.1735c-0.1577-0.37113-0.3887-0.70665-0.679-0.9865zm-11.6 13.1h-1.5v-1.5l8-8 1.5 1.5-8 8zm10.19-10.19-0.75 0.74-1.49-1.49 0.74-0.75c0.2025-0.19201 0.4709-0.29905 0.75-0.29905s0.5475 0.10704 0.75 0.29905c0.1967 0.20011 0.3068 0.46944 0.3068 0.75s-0.1101 0.5499-0.3068 0.75z" />
        </g>
      </g>
    </SvgIcon>
  );
};
