import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import {FCWithChildren} from 'types';
import {LoadStatus} from 'api/types';
import {useLocalization} from 'localization';
import {px2rem} from 'theme';
import {
  ActionButton,
  ActivityIndicator,
  Card,
  Layout,
  Spacer,
  Text,
} from 'components';

const SceneContentName = 'SceneContentName';

type Props = {
  loadStatus?: LoadStatus;
  error?: string;
};

const SceneContent: FCWithChildren<Props> = ({loadStatus, error, children}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useLocalization();

  if (loadStatus === 'NotLoaded' || loadStatus === 'Loading')
    return (
      <Layout adjustCenter paddingTop="10vh">
        <ActivityIndicator />
      </Layout>
    );

  if (loadStatus === 'Failed') {
    return (
      <Layout adjustCenter paddingTop="10vh">
        <Layout sx={{maxWidth: px2rem(400)}}>
          <Card color="secondary">
            <Layout p={2} adjustCenter>
              <Text variant="h3" kind="error">
                {error ?? t('error.failedToLoadPage')}
              </Text>
              <Spacer size={4} />
              <Layout sx={{minWidth: px2rem(100)}}>
                <ActionButton
                  title={t('error.retry')}
                  onClick={() =>
                    navigate(`${location.pathname}${location.search}`)
                  }
                />
              </Layout>
            </Layout>
          </Card>
        </Layout>
      </Layout>
    );
  }

  return <Box id="scene-content">{children}</Box>;
};

SceneContent.displayName = SceneContentName;

export {SceneContent, SceneContentName};
