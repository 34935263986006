import {HttpError} from './http/types';
import {GetTokens, SystemHealth} from './types';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpGet} from './http/ebba/http';
import {mapHealthCheckDto} from './mappers';

const systemGreen: SystemHealth = {
  syncComponents: [
    {
      name: 'EXPORT_AKTOER',
      status: 'UP',
    },
    {
      name: 'EXPORT_METADATA',
      status: 'UP',
    },
    {
      name: 'IMPORT_METADATA_AND_AKTOER',
      status: 'UP',
    },
  ],
  messageQueues: [
    {
      topic: 'DASHBOARD',
      length: 0,
    },
  ],
  services: [
    {
      name: 'CATALOG_MASTERDATA_SERVICE',
      status: 'UP',
    },
  ],
};

const systemYellow: SystemHealth = {
  syncComponents: [
    {
      name: 'EXPORT_AKTOER',
      status: 'UP',
    },
    {
      name: 'EXPORT_METADATA',
      status: 'UP',
    },
    {
      name: 'IMPORT_METADATA_AND_AKTOER',
      status: 'UP',
    },
  ],
  messageQueues: [
    {
      topic: 'DASHBOARD',
      length: 10,
    },
  ],
  services: [
    {
      name: 'CATALOG_MASTERDATA_SERVICE',
      status: 'UP',
    },
  ],
};

const systemRed: SystemHealth = {
  syncComponents: [
    {
      name: 'EXPORT_AKTOER',
      status: 'DOWN',
    },
    {
      name: 'EXPORT_METADATA',
      status: 'DOWN',
    },
    {
      name: 'IMPORT_METADATA_AND_AKTOER',
      status: 'DOWN',
    },
  ],
  messageQueues: [
    {
      topic: 'DASHBOARD',
      length: 255,
    },
  ],
  services: [
    {
      name: 'CATALOG_MASTERDATA_SERVICE',
      status: 'DOWN',
    },
  ],
};

const mockHealth = [systemGreen, systemYellow, systemRed];
let mockIdx = mockHealth.length;

export const getSystemHealth = (
  getTokens: GetTokens,
  mock?: boolean,
): Promise<SystemHealth> => {
  if (mock) {
    return new Promise<SystemHealth>((resolve, reject) => {
      setTimeout(() => {
        const healthIndex = mockIdx;
        mockIdx = (mockIdx + 1) % (mockHealth.length + 1);
        if (healthIndex === mockHealth.length) {
          reject(new HttpError(500, 'healtcheck', 'Mock error'));
        }
        resolve(mockHealth[healthIndex]);
      }, 1000);
    });
  }

  return exceptionToPromiseReject(() =>
    httpGet<Schemas.HealthcheckDto>({
      subdir: 'healthcheck',
      getTokens,
      mock,
    }).then(mapHealthCheckDto),
  );
};
