import React from 'react';
import {Stack} from '@mui/material';
import {isData} from 'schemaDefinition';
import {Ruler} from 'components';
import {Data, SeparatedParts, Valx} from '../../../schemaDefinition/types';
import {FormMode, UseValueById} from '../types';
import {PartDiffProps} from './types';
import {DataFormPartWrapper} from './DataFormPartWrapper';

type Props = {
  useValue: UseValueById;
  useOriginalValue?: UseValueById | undefined;
  valuePath: string;
  relatedScope: Data | undefined;
  fragment: SeparatedParts<Valx>;
  mode: FormMode;
  diff?: PartDiffProps;
  noLabel: boolean;
  reviewStatuses?: Data;
};
/**
 * Renders a vertical list of schema parts/sublist of parts,
 * where sublists of parts renders horizontally
 */
export const DataFormSchemaFragment: React.FC<Props> = ({
  useValue,
  useOriginalValue,
  valuePath,
  relatedScope,
  fragment,
  mode,
  diff,
  noLabel,
  reviewStatuses,
}) => {
  const fragments = fragment.map((part, idx) => {
    if (!Array.isArray(part) && part.type === 'separator') {
      return part.form === 'line' &&
        ((idx > 0 && idx < fragment.length - 1) ||
          part.include === 'always') ? (
        <Ruler key={idx} spacing={1} />
      ) : null;
    }

    // Handle horizontal layout of parts, i.e. [part1, part2, part3]
    if (Array.isArray(part)) {
      return part.length > 0 ? (
        <Stack key={idx} direction={'row'} spacing={2}>
          <DataFormSchemaFragment
            useValue={useValue}
            useOriginalValue={useOriginalValue}
            valuePath={valuePath}
            relatedScope={relatedScope}
            fragment={part}
            mode={mode}
            diff={diff}
            noLabel={noLabel}
            reviewStatuses={reviewStatuses}></DataFormSchemaFragment>
        </Stack>
      ) : null;
    } else {
      const partValuePath =
        (part.name
          ? valuePath
            ? valuePath + '.' + part.name
            : part.name
          : valuePath) ?? '';
      const compareValue = isData(diff?.compareValue)
        ? (diff?.compareValue as Data)
        : undefined;
      const partDiff: PartDiffProps | undefined = diff
        ? ({
            ...diff,
            compareValue:
              compareValue && part.name ? compareValue[part.name] : null,
          } as const)
        : undefined;

      return (
        <DataFormPartWrapper
          key={part.name ?? part.role}
          part={part}
          useValue={useValue}
          relatedScope={relatedScope}
          valuePath={partValuePath}
          scopePath={undefined}
          usesGlobalScope={undefined}
          useOriginalValue={useOriginalValue}
          mode={mode}
          diff={partDiff}
          noLabel={noLabel}
        />
      );
    }
  });

  return fragments.length ? <>{fragments}</> : null;
};
