import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import {ExpressionV4} from 'api/types';
import {SelectorById} from '../selectors/types';
import {useCreateSelectorById} from './useCreateSelectorById';

const savedExpressionSelectorById: SelectorById<ExpressionV4> =
  expressionId => state => {
    const expression = state.metadata.data?.expressions.find(
      e => e.id === expressionId,
    );
    if (!expression) {
      assert(false, 'useSavedExpression: unknown expressionId', {
        expressionId,
      });
    }

    return expression;
  };

export const useSavedExpression = (expressionId: string): ExpressionV4 => {
  const selector = useCreateSelectorById(
    expressionId,
    savedExpressionSelectorById,
  );
  return useSelector(selector);
};
