import {useCallback, useMemo} from 'react';
import {useStore} from 'react-redux';
import {ManifestationStatus} from 'api/types';
import {AppState} from 'store/types';
import {MetadataEditAction} from 'services/data/metadata/metadataEditActionTypes';

export const useHandleManifestationStatus = (
  manifestationId: string,
): {
  handleManifestationStatusPreview: (
    status: ManifestationStatus | undefined,
  ) => void;
  handleManifestationStatusChanged: (status: ManifestationStatus) => void;
} => {
  const {dispatch} = useStore<AppState>();

  const handleManifestationStatusPreview = useCallback(
    (status: ManifestationStatus | undefined) => {
      const action: MetadataEditAction = {
        type: 'METADATAEDIT_MANIFESTATION_STAUTS_PREVIEW',
        payload: {
          id: manifestationId,
          status,
        },
      };
      dispatch(action);
    },
    [dispatch, manifestationId],
  );

  const handleManifestationStatusChanged = useCallback(
    (
      status: // Status selected in dropdown
      | ManifestationStatus
        // Set to undefined when cancel edits
        | undefined,
    ) => {
      const action: MetadataEditAction = {
        type: 'METADATAEDIT_MANIFESTATION_STATUS_CHANGED',
        payload: {
          id: manifestationId,
          status,
        },
      };
      dispatch(action);
      handleManifestationStatusPreview(undefined);
    },
    [dispatch, handleManifestationStatusPreview, manifestationId],
  );

  return useMemo(() => {
    return {
      handleManifestationStatusPreview,
      handleManifestationStatusChanged,
    };
  }, [handleManifestationStatusPreview, handleManifestationStatusChanged]);
};
