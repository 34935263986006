import React from 'react';
import {FlexBox} from 'components';
import {CompareValueContainerType} from './DataFormUpdatedValueContainer';

/**
 * Wraps empty value with invisible container
 */
export const DataFormOriginalEmptyValueContainer: CompareValueContainerType = ({
  children,
}) => <FlexBox visibility={'hidden'}>{children}</FlexBox>;

/**
 * Just renders children in fragment
 */
export const DataFormOriginalValueContainer: CompareValueContainerType = ({
  children,
}) => <>{children}</>;
