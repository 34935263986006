import React, {useCallback} from 'react';
import IconButton from '@mui/material/IconButton';
import {IconName} from 'components/icons/types';
import {Icon} from 'components/icons';
import {Layout} from 'components/layout';
import {Text} from 'components/text';
import {MenuItemProps} from './types';
import {PopupMenu} from './PopupMenu';

type Props = {
  icon?: IconName;
  title?: string;
  backgroundColor?: string;
  popupId?: string;
  popupTitle?: string;
  items: MenuItemProps[];
};
export const ButtonWithPopupMenu: React.FC<Props> = ({
  icon = 'Menu',
  title,
  backgroundColor,
  popupId,
  popupTitle,
  items,
}) => {
  const [anchorEButton, setAnchorEButton] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEButton(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEButton(null);
  };
  // const handleMenuItemOld = useCallback((item: MenuItemProps) => {
  //   return Promise.resolve(item.action ? item.action() : undefined).finally(
  //     handleCloseMenu,
  //   );
  // }, []);
  const handleMenuItem = useCallback(
    (itemId: string) => {
      const item = items.find(item => item.id === itemId);
      return Promise.resolve(
        item && item.action ? item.action() : undefined,
      ).finally(handleCloseMenu);
    },
    [items],
  );

  return (
    <>
      <IconButton
        // id={getElementId('addDeleteBtn', values.length)}
        color="primary"
        size="small"
        sx={
          backgroundColor
            ? {
                backgroundColor,
              }
            : undefined
        }
        onClick={handleOpenMenu}>
        {title ? (
          <Layout horizontal adjustCenter paddingLeft={1} paddingRight={2}>
            <Icon icon={icon} />
            <Text variant="h4">{title}</Text>
          </Layout>
        ) : (
          <Icon icon={icon} />
        )}
      </IconButton>
      <PopupMenu
        id={popupId}
        popupTitle={popupTitle}
        items={items}
        anchorEl={anchorEButton}
        onClose={handleCloseMenu}
        // onSelectOld={handleMenuItemOld}
        onSelect={handleMenuItem}
      />
    </>
  );
};
