import {User} from '../../api/types';

type AuthStatus = 'Unknown' | 'Anonymous' | 'Failed' | 'Authenticated';

export type AuthState =
  | {
      status: Exclude<AuthStatus, 'Authenticated'>;
      user?: undefined;
    }
  | {
      status: Extract<AuthStatus, 'Authenticated'>;
      user: User;
    };

export const AuthDefaultState: AuthState = {
  status: 'Unknown',
} as const;
