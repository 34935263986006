import {useCallback, useState} from 'react';
import {HttpError} from 'api/http/types';
import {RegisteredReceiveContentResponse} from 'api/types';
import {useLocalization} from 'localization';
import {postManifestationPhysicalContentReceived} from 'api';
import {useGetTokens} from 'services/auth';
import {Attempt, ReceiveEan} from '../types';

export const useReceiveEans = (): ReceiveEan => {
  const [attempts, setAttempt] = useState<Array<Attempt>>([]);
  const getTokens = useGetTokens();
  const {tryT, t} = useLocalization();

  const createReason = useCallback(
    (response: RegisteredReceiveContentResponse): string | undefined => {
      const updatedString = `${response.updated ? t('page.receive.response.updated') : t('page.receive.response.already_received')}`;
      const dateString = `${t('page.receive.response.dato.label')}: ${response.date ? new Date(response.date).toLocaleDateString('no') : t('general.empty')}`;
      const statusString = `${t('page.receive.response.status.label')}: ${response.catalogingStatus ? t(`entity.manifestation.status.full.${response.catalogingStatus}`) : t('general.empty')}`;

      return `${updatedString} - ${dateString} - ${statusString}`;
    },
    [t],
  );

  const addEan = useCallback(
    (ean: string): void => {
      postManifestationPhysicalContentReceived(ean, getTokens)
        .then(response => {
          setAttempt(prevState => [
            ...prevState,
            {
              ean,
              error: false,
              reason: createReason(response),
            },
          ]);
        })
        .catch((error: HttpError) => {
          setAttempt(prevState => [
            ...prevState,
            {
              ean,
              error: true,
              reason:
                tryT(`page.receive.response.error.${error.status}`) ??
                t('page.receive.response.error.unknown'),
            },
          ]);
        });
    },
    [createReason, getTokens, t, tryT],
  );

  return {
    addEan,
    attempts,
  };
};
