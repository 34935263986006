import {useMemo} from 'react';
import {OverviewTask, TaskTypeAll, TaskTypes} from 'api/types';

type TaskTypeCounts = {
  [type in TaskTypeAll]: number;
};

export const useTaskTypeCounts = (tasks: OverviewTask[]): TaskTypeCounts => {
  return useMemo(
    () =>
      TaskTypes.reduce<TaskTypeCounts>(
        (acc, t) => ({
          ...acc,
          [t]: tasks.filter(task => task.taskTypes?.includes(t)).length,
        }),
        {} as TaskTypeCounts,
      ),
    [tasks],
  );
};
