import React from 'react';
import {SxProps} from '@mui/material';
import {DialogItem} from './types';
import {ActionButton} from '../button';
import {FlexBox, Layout} from '../layout';
import {Spacer} from '../spacer';

type Props = {
  dialogItems?: DialogItem[];
};

const hiddenSx: SxProps = {
  opacity: '0!important',
  visibility: 'hidden!important',
};

export const DialogItems: React.FC<Props> = ({dialogItems}) => {
  const hasLeftItems = dialogItems?.some(a => a.alignLeft);
  const rightItems = hasLeftItems
    ? dialogItems?.filter(a => !a.alignLeft)
    : dialogItems;
  const leftItems = hasLeftItems
    ? dialogItems?.filter(a => a.alignLeft)
    : undefined;

  return dialogItems ? (
    hasLeftItems ? (
      <FlexBox horizontal flex={1} justifyContent={'space-between'}>
        <Layout horizontal>
          {(leftItems ?? []).map((item, i) => (
            <FlexBox
              horizontal
              center
              key={i}
              sx={item.hide ? hiddenSx : undefined}>
              {item.itemType === 'action' ? (
                <ActionButton {...item} />
              ) : (
                item.render()
              )}
              {i < dialogItems.length - 1 && <Spacer size={2} />}
            </FlexBox>
          ))}
        </Layout>
        <FlexBox horizontal center>
          {(rightItems ?? []).map((item, i) => (
            <FlexBox
              horizontal
              center
              key={i}
              sx={item.hide ? hiddenSx : undefined}>
              {item.itemType === 'action' ? (
                <ActionButton {...item} />
              ) : (
                item.render()
              )}
              {i < (rightItems?.length ?? 0) - 1 && <Spacer size={2} />}
            </FlexBox>
          ))}
        </FlexBox>
      </FlexBox>
    ) : (
      <FlexBox horizontal center right>
        {dialogItems.map((item, i) => (
          <FlexBox
            horizontal
            center
            key={i}
            sx={item.hide ? hiddenSx : undefined}>
            {item.itemType === 'action' ? (
              <ActionButton {...item} />
            ) : (
              item.render()
            )}
            {i < dialogItems.length - 1 && <Spacer size={2} />}
          </FlexBox>
        ))}
      </FlexBox>
    )
  ) : null;
};
