import {TypedSchema} from 'schemaDefinition/types';
import {Collection} from 'api';
import {collectionTitlesSchemaPart} from './collectionTitlesSchemaPart';

export const publisherSeriesSchema: TypedSchema<Collection> = {
  name: 'publisherSeries',
  key: 'publisherSeries',
  parts: [
    {
      type: 'codelist',
      name: 'collectionType',
      codelistId: 'collection_type',
      required: true,
      readonly: true,
    },
    collectionTitlesSchemaPart,
    {
      type: 'text',
      name: 'note',
    },
    {
      type: 'linkedLiterary',
      name: 'links',
      roleCodelistId: 'publisherSeries.linkedRole',
      linkRole: 'contains.manifestation',
      cardinality: 'multiple',
      // TODO: Remove readonly, when full edit of supported series is supported by backend
      readonly: true,
    },
  ],
};
