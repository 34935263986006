import {DataLoadStatus} from 'api/types';
import {LoadAction} from '../actions/loadAction';

export const DefaultDataLoadStatus = {
  status: 'NotLoaded',
} as DataLoadStatus<unknown>;

export function loadReducer<TData>(
  state: DataLoadStatus<TData>,
  action: LoadAction<TData>,
): DataLoadStatus<TData> {
  switch (action.loadType) {
    case 'NOT_LOADED': {
      return {status: 'NotLoaded'};
    }
    case 'REQUEST':
      return {
        ...state,
        status: 'Loading',
        error: undefined,
      };
    case 'SUCCESS':
      return {
        status: 'Loaded',
        data: action.payload.data,
        error: undefined,
      };
    case 'FAILURE':
      return {
        ...state,
        status: 'Failed',
        error: action.payload.error,
      };
  }
}
