import {PartValueRenderer} from 'schema/types';
import {CollectionTitle} from 'api';
import {useCodelist} from 'services/codeLists';
import {isNullish} from 'services/utils';
import {FlexBox} from 'components';
import {useTextValue} from 'schema/preview/hooks';

export const CollectionTitles: PartValueRenderer = ({value}) => {
  const TextValue = useTextValue();
  const codelist = useCodelist('LANGUAGE');

  return isNullish(value) ||
    !Array.isArray(value) ||
    value.length === 0 ? null : (
    <FlexBox>
      {value.map((v, i) => {
        const title = v as CollectionTitle;
        return (
          <TextValue key={i}>
            {`${title.value} (${
              codelist.codes.find(c => c.code === title.language)?.value ??
              title.language
            })`}
          </TextValue>
        );
      })}
    </FlexBox>
  );
};
