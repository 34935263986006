import React, {useEffect} from 'react';
import {Auth} from '@aws-amplify/auth';
import {Hub} from '@aws-amplify/core';
import {FCWithChildren} from 'types';
import {useAuthState} from '../services/auth/hooks/useAuthState';

export const AuthGate: FCWithChildren = ({children}) => {
  const {authState, login, logout} = useAuthState();

  // Update auth state status from "UNKNOWN" to something.
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        login(user.signInUserSession.getIdToken().getJwtToken());
      })
      .catch(() => {
        logout();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Listens to AWS Hub auth events, and either log out or login in user.
  useEffect(() => {
    return Hub.listen('auth', data => {
      switch (data.payload.event) {
        case 'signIn': {
          login(data.payload.data.signInUserSession.getIdToken().getJwtToken());

          break;
        }
        case 'signOut': {
          logout();
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return authState.status !== 'Unknown' ? <>{children}</> : null;
};
