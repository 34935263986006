import {WorkSummary} from 'api/types';
import {TypedSchema} from 'schemaDefinition/types';
import {mainTitlesPart} from './manifestationSchema';
import {workDataSchema} from './workSchema';

/**
 * Work schema extended with parts from expression and manifestation,
 * .i.e. mainTitles
 */

export const workSummarySchema: TypedSchema<WorkSummary> = {
  name: 'workSummary',
  parts: [
    ...workDataSchema.parts,
    mainTitlesPart,
    {
      type: 'thesaurus',
      name: 'themaLabels',
      labelKey: 'themaLabels',
      cardinality: 'multiple',
      thesaurusId: 'thema',
      showCode: false,
    },
    {
      type: 'text',
      name: 'themaCodes',
      labelKey: 'themaCodes',
      cardinality: 'multiple',
    },
  ],
};
