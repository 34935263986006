import React, {useState} from 'react';
import {FieldError} from 'schemaDefinition/types';
import {CodeListId, CodeListRef} from 'api';
import {CodeListsGate, useCodelist} from 'services/codeLists';
import {
  CheckboxField,
  CodeListField,
  FlexBox,
  RadioButtonsField,
  SortableCodeListField,
  Spacer,
  Text,
} from 'components';

const TestCodeListSelectorsCore: React.FC = () => {
  const languages = useCodelist('LANGUAGE');

  const [value, setValue] = useState<string[] | null>([
    CodeListRef.LANGUAGE.Bokmål,
  ]);
  const [hasPlaceholder, setHasPlaceholder] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [fullWidth, setFullWidth] = useState<boolean>(false);
  const showWhenReadonlyAndEmpty = false;
  const [error, setError] = useState<FieldError>(false as FieldError);

  const placeholder = hasPlaceholder ? 'Velg språk for oppleser' : undefined;

  return (
    <FlexBox>
      <Text variant="h3">Parameters</Text>
      <Spacer size={2} />
      <FlexBox horizontal left>
        <CheckboxField
          fieldId="placeholder"
          label="Placeholder"
          value={hasPlaceholder}
          onChange={setHasPlaceholder}
          name="placeholder"
        />
        <Spacer size={2} />
        <CheckboxField
          fieldId="readonly"
          label="Readonly"
          value={readOnly}
          onChange={setReadOnly}
          name="readonly"
        />
        <Spacer size={2} />
        <CheckboxField
          fieldId="fullWidth"
          label="Full width"
          value={fullWidth}
          onChange={setFullWidth}
          name="fullWidth"
        />
      </FlexBox>
      <Spacer size={2} />
      <RadioButtonsField
        label="Error"
        value={error === true ? 'true' : error === false ? 'false' : 'warning'}
        options={[
          {
            value: 'No error',
            code: 'false',
          },
          {
            value: 'Warning',
            code: 'warning',
          },
          {
            value: 'Error',
            code: 'true',
          },
        ]}
        name="error"
        onChange={newVal =>
          setError(newVal === 'warning' ? 'warning' : newVal === 'true')
        }
        horizontal
      />
      <Spacer size={4} />
      <CodeListField
        label={'Single - non-sortable'}
        value={value ? value[0] : null}
        placeholder={placeholder}
        cardinality={'single'}
        readonly={readOnly}
        showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
        error={error}
        name={'single-nd'}
        id={'single-nd'}
        options={languages.codes}
        fullWidth={fullWidth}
        onChange={newVal =>
          setValue(Array.isArray(newVal) ? newVal : newVal ? [newVal] : null)
        }
      />
      <Spacer size={2} />
      <CodeListField
        label={'Multiple - non-sortable'}
        value={value ? value : null}
        placeholder={placeholder}
        cardinality={'multiple'}
        readonly={readOnly}
        showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
        error={error}
        name={'single-nd'}
        id={'single-nd'}
        options={languages.codes}
        fullWidth={fullWidth}
        onChange={newVal =>
          setValue(Array.isArray(newVal) ? newVal : newVal ? [newVal] : null)
        }
      />
      <Spacer size={4} />
      <SortableCodeListField
        label={'Single - sortable'}
        value={value ? value[0] : null}
        placeholder={placeholder}
        cardinality={'single'}
        readonly={readOnly}
        showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
        error={error}
        name={'single-nd'}
        id={'single-nd'}
        options={languages.codes}
        fullWidth={fullWidth}
        onChange={newVal =>
          setValue(Array.isArray(newVal) ? newVal : newVal ? [newVal] : null)
        }
      />
      <Spacer size={2} />
      <SortableCodeListField
        label={'Multiple - sortable'}
        value={value ? value : null}
        placeholder={placeholder}
        cardinality={'multiple'}
        readonly={readOnly}
        showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
        error={error}
        name={'single-nd'}
        id={'single-nd'}
        options={languages.codes}
        fullWidth={fullWidth}
        onChange={newVal =>
          setValue(Array.isArray(newVal) ? newVal : newVal ? [newVal] : null)
        }
      />{' '}
    </FlexBox>
  );
};

const codelistIds: CodeListId[] = ['LANGUAGE'];

export const TestCodeListSelectors: React.FC = () => {
  return (
    <CodeListsGate codeLists={codelistIds}>
      <TestCodeListSelectorsCore />
    </CodeListsGate>
  );
};
