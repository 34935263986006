import React, {useEffect} from 'react';
import {Auth} from '@aws-amplify/auth';
import {NoProps} from '../../types';
import {logWarning} from '../../logging';
import {useAuthState} from '../../services/auth/hooks/useAuthState';

type Props = NoProps;

export const Logout: React.FC<Props> = () => {
  const {logout} = useAuthState();

  useEffect(() => {
    Auth.signOut({
      global: false,
    })
      .catch(error => {
        logWarning(error);
      })
      .finally(() => {
        logout();
      });
  }, [logout]);

  return null;
};
