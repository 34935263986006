import React, {useCallback} from 'react';
import {Concept} from 'types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {
  RestrictedCodelistsContext,
  useRestrictedCodelistsContextProviderValue,
} from 'services/codeLists';
import {
  useExpressionEditValue,
  useGetExpressionEditValue,
  useSetExpressionEditValue,
} from 'services/data';
import {
  ActionButton,
  Card,
  Layout,
  Spacer,
  Text,
  useAlertDialogAsPromise,
} from 'components';
import {DataForm} from 'schema/form';
import {DataFormConfigurationContextType} from 'schema/form/contexts/DataFormConfigurationContext';
import {NewExpressionWithManifestationCardProps} from './types';
import {ManifestationCardCore} from '../ManifestationCard/ManifestationCardCore';
import {ValidationErrors} from '../ValidationErrors';
import {useMovedManifestationId} from './hooks/useMovedManifestationId';
import {useNewExpressionWithManifestationEditState} from './hooks/useNewExpressionWithManifestationEditState';

const ExpressionFormConfiguration: Partial<DataFormConfigurationContextType> = {
  schemaContainers: {},
  showWhenReadonlyAndEmpty: true,
};

export const NewExpressionWithManifestationCard: React.FC<
  NewExpressionWithManifestationCardProps
> = ({workId, expressionId, content}) => {
  const {t, tLoose} = useLocalization();
  const manifestationId = useMovedManifestationId(content);
  const {
    expressionEditState,
    manifestationEditState,
    codelistRestrictor,
    valid,
    showErrors,
    saving,
    handleSave,
    handleCancel,
  } = useNewExpressionWithManifestationEditState(
    workId,
    expressionId,
    manifestationId,
  );

  const getExpressionEditValue = useGetExpressionEditValue();
  const setExpressionEditValue = useSetExpressionEditValue(
    expressionId,
    'METADATAEDIT_NEW_EXPRESSION_EDITED',
  );

  const {AlertDialog: ConfirmOnSaveDialog, pleaseConfirm: pleaseConfirmOnSave} =
    useAlertDialogAsPromise();

  const handleSaveWithConfirmation = useCallback((): Promise<void> => {
    return new Promise((resolve, reject) => {
      return handleSave().then(validation => {
        if (validation.valid === 'valid') {
          return resolve();
        }

        const {okTitle, cancelTitle} =
          validation.valid === 'warning'
            ? {
                okTitle: t('general.save'),
                cancelTitle: t('general.cancel'),
              }
            : {
                okTitle: t('general.ok'),
                cancelTitle: undefined,
              };

        pleaseConfirmOnSave({
          title: t('page.metadata.expression.saveValidationIssues.title'),
          body: (
            <ValidationErrors
              entity={Concept.expression}
              status={manifestationEditState.manifestationStatus}
              validation={validation}
            />
          ),
          okTitle,
          cancelTitle,
        }).then(confirmed => {
          if (confirmed && validation.valid === 'warning') {
            handleSave(true)
              .then(() => resolve())
              .catch(reject);
          } else {
            reject();
          }
        });
      });
    });
  }, [
    handleSave,
    manifestationEditState.manifestationStatus,
    pleaseConfirmOnSave,
    t,
  ]);

  const restrictedCodelistsContextValue =
    useRestrictedCodelistsContextProviderValue(codelistRestrictor);

  return (
    <RestrictedCodelistsContext.Provider
      value={restrictedCodelistsContextValue}>
      <Card color="primary" mode="light" headerSpacing={0}>
        <Card.Header>
          <Layout horizontal flex={1} alignItems="center">
            <Text variant="h4">{t('form.newExpression.header.title')}</Text>
            <Layout horizontal adjustCenter adjustRight>
              {showErrors && (valid === 'warning' || valid === 'error') ? (
                <>
                  <Text variant="h4" kind={valid}>
                    {tLoose(`form.global.validation.${valid}.label`)}
                  </Text>
                  <Spacer width={4} />
                </>
              ) : null}
              <>
                <ActionButton
                  title={t('general.cancel')}
                  disabled={saving}
                  icon="Close"
                  background="transparent"
                  onClick={handleCancel}
                />
                <Spacer size={1} />
                <ActionButton
                  title={t('page.metadata.newExpression.save')}
                  disabled={saving}
                  icon="Check"
                  background={'green2'}
                  showSpinnerOnClick
                  onClick={handleSaveWithConfirmation}
                />
              </>
            </Layout>
          </Layout>
        </Card.Header>
        <Card.Content>
          <Layout paddingTop={1}>
            <Card colorx={ColorPalette.secondary.brown}>
              <Card.Header minHeight={'20px'}>
                <Text variant="h4" textTransform="capitalize">
                  {t('form.newExpression.expression.title')}
                </Text>
              </Card.Header>
              <Card.Content>
                <DataForm
                  id={expressionId}
                  schema={expressionEditState.schema}
                  configuration={ExpressionFormConfiguration}
                  relatedData={expressionEditState.relatedScope}
                  mode={'edit'}
                  showErrors={showErrors}
                  getEditValue={getExpressionEditValue}
                  useEditValue={useExpressionEditValue}
                  setEditValue={setExpressionEditValue}
                />
                <Layout pt={3} pb={2}>
                  <Text variant="h4" textTransform="capitalize">
                    {t('form.newExpression.manifestation.title')}
                  </Text>
                </Layout>
                <ManifestationCardCore
                  props={{
                    workId,
                    expressionId,
                    manifestationId,
                    initialExpanded: true,
                    saveHidden: true,
                    changeStatusDisabled: true,
                    moveDisabled: true,
                  }}
                  editState={manifestationEditState}
                />
              </Card.Content>
            </Card>
          </Layout>
          <ConfirmOnSaveDialog />
        </Card.Content>
      </Card>
    </RestrictedCodelistsContext.Provider>
  );
};
