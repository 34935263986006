import React, {useEffect, useMemo, useRef} from 'react';
import {SxProps, ToolbarProps} from '@mui/material';
import Toolbar from '@mui/material/Toolbar/Toolbar';
import {
  GridToolbarContainer,
  GridValidRowModel,
  ToolbarPropsOverrides,
  gridColumnDefinitionsSelector,
  gridColumnVisibilityModelSelector,
  gridFilteredSortedTopLevelRowEntriesSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import isEqual from 'lodash/isEqual';
import {PaginatorProps} from 'components/paginator/types';
import {FlexBox, IconTool, Layout, Paginator, Text} from 'components';
import {mapColumnSelectionOptions} from '../functions/mapColumnSelectionOptions';
import {useColumnSelectionPopover} from '../hooks';

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    header?: React.ReactNode | string;
    columnSelectionTitle?: string;
    paginatorProps?: PaginatorProps;
    hiddenColumns?: string[];
    hideColumnSelector: boolean;
    onRowsUpdate?: (gridRows: GridValidRowModel[]) => void;
  }
}

const toolbarActionsSx: SxProps = {
  flexDirection: 'row',
  alignItems: 'center',
};

const headerSx: SxProps = {
  flex: 'auto',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 2,
};

export function CustomGridToolbar({
  header,
  columnSelectionTitle,
  paginatorProps,
  hiddenColumns,
  hideColumnSelector,
  onRowsUpdate,
}: ToolbarProps<typeof Toolbar, ToolbarPropsOverrides>) {
  const {toolbarContainerSx, headerNode} = useMemo(() => {
    const headerNode =
      typeof header === 'string' ? (
        <Text variant={'h3'}>{header}</Text>
      ) : (
        header ?? <Text variant={'h3'} />
      );

    const toolbarContainerSx = {
      flex: 1,
      justifyContent: header !== undefined ? 'space-between' : 'end',
    } as SxProps;

    return {
      headerNode,
      toolbarContainerSx,
    };
  }, [header]);

  const apiRef = useGridApiContext();

  /**
   * Sometimes we need to report back the current rows (after custom task filtering, and filtermodel) with current data.
   */
  const rowsRef = useRef<GridValidRowModel[]>([]);
  const gridRows = gridFilteredSortedTopLevelRowEntriesSelector(apiRef);
  useEffect(() => {
    if (onRowsUpdate && gridRows.length > 0) {
      const rowModels = gridRows.map(r => r.model);
      if (!isEqual(rowsRef.current, rowModels)) {
        rowsRef.current = rowModels;
        onRowsUpdate(rowModels);
      }
    }
  }, [gridRows, onRowsUpdate]);

  /* Column selection */
  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector);
  const columnVisibilityModel = useGridSelector(
    apiRef,
    gridColumnVisibilityModelSelector,
  );
  const options = mapColumnSelectionOptions(
    columns,
    columnVisibilityModel,
  ).filter(c => !hiddenColumns?.includes(c.key));
  const {
    open: openSelectColumns,
    Popover: SelectColumnsPopover,
    popoverProps: selectColumnsProps,
  } = useColumnSelectionPopover(
    options,
    apiRef.current.setColumnVisibility,
    columnSelectionTitle,
  );

  return (
    <GridToolbarContainer sx={toolbarContainerSx}>
      <FlexBox sx={headerSx}>{headerNode}</FlexBox>

      <FlexBox sx={toolbarActionsSx}>
        {paginatorProps ? <Paginator {...paginatorProps} /> : null}

        {!hideColumnSelector ? (
          <Layout>
            <IconTool icon="MetadataSelection" onClick={openSelectColumns} />
            <SelectColumnsPopover {...selectColumnsProps} />
          </Layout>
        ) : null}
      </FlexBox>
    </GridToolbarContainer>
  );
}
