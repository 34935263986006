import {useMemo} from 'react';
import {DataLoadStatus, ExternalSuggestions} from 'api/types';
import {getManifestationExternalSuggestions, useLazyGetResource} from 'api';
import {useGetTokens} from 'services/auth';
import {useLoadError} from 'services/utils';

export const useLazyExternalSuggestions = (
  contextId: string,
  doLoad: boolean,
  mock?: boolean,
): {
  externalSuggestions: DataLoadStatus<ExternalSuggestions>;
} => {
  const getTokens = useGetTokens();

  // Notes loaded from backend
  const externalSuggestions = useLazyGetResource(doLoad, () =>
    getManifestationExternalSuggestions(contextId, getTokens, mock),
  );

  useLoadError(
    externalSuggestions.status,
    'error.externalSuggestions.failedToLoad',
  );

  return useMemo(
    () => ({
      externalSuggestions,
    }),
    [externalSuggestions],
  );
};
