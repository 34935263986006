import React from 'react';
import {DataFormProps} from '../types';

type Props = Pick<DataFormProps, 'onSubmit' | 'id' | 'schema'>;

const formStyle = {width: '100%'} as const;

export const DataFormForm: React.FC<React.PropsWithChildren<Props>> = ({
  onSubmit,
  id,
  schema,
  children,
}) => {
  return (
    <form
      onSubmit={onSubmit}
      id={id.toString()}
      name={schema.name}
      autoComplete="off"
      style={formStyle}>
      {children}
    </form>
  );
};
