import {useCallback, useMemo} from 'react';
import {FilterOperation} from 'api/types';
import {useLocalization} from 'localization';

export const useDataTranslations = () => {
  const {tLoose, tryT, t} = useLocalization();

  const getLabel = useCallback(
    (fieldName: string) => {
      return (
        tryT(`form.advancedSearch.field.${fieldName}.label`) ??
        tLoose(`form.global.field.${fieldName}.label`)
      );
    },
    [tLoose, tryT],
  );

  const getOperation = useCallback(
    (operation: FilterOperation) => {
      return t(`data.operation.${operation}`);
    },
    [t],
  );

  return useMemo(() => {
    return {
      getLabel,
      getOperation,
    };
  }, [getLabel, getOperation]);
};
