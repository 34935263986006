import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const MLIcon2: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M6.70405 15V9.36H7.80005L9.76005 13.76H9.50405L11.4641 9.36H12.5521V15H11.4161V11.168H11.7361L9.98405 15H9.26405L7.50405 11.168H7.84005V15H6.70405ZM13.9013 15V9.36H15.1493V13.952H17.6933V15H13.9013Z"
        fill={'currentColor'}
      />
    </SvgIcon>
  );
};
