import {
  LinkedAgentMultiRole,
  UnnamedLinkedAgentMultiRole,
} from 'schemaDefinition/types';

export const isUnnamedLinkedAgentMultiRole = (
  linkedAgent: LinkedAgentMultiRole,
): linkedAgent is UnnamedLinkedAgentMultiRole => {
  return linkedAgent.link.linkStatus === 'unnamed';
};
