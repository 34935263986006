import React from 'react';
import {useChangeRequestTypes} from 'services/data';
import {FlexBox, Spacer} from 'components';
import {ChangeRequestIcon} from '../../ChangeRequestCard/components';

type Props = {
  manifestationId: string;
};

export const ChangeRequestIndicators: React.FC<Props> = ({manifestationId}) => {
  const changeRequestTypes = useChangeRequestTypes(manifestationId);

  return changeRequestTypes ? (
    <>
      {changeRequestTypes.map(changeRequestType => (
        <FlexBox key={changeRequestType} horizontal>
          <ChangeRequestIcon type={changeRequestType} />
          <Spacer size={1} />
        </FlexBox>
      ))}
    </>
  ) : null;
};
