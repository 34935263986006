import {assert} from 'assert-ts';
import {NameVariantValue} from 'schemaDefinition/types';

export const valueAsNameVariantArray = (
  value: unknown,
): NameVariantValue[] | undefined => {
  if (
    !assert.soft(
      value === undefined || value === null || Array.isArray(value),
      'valueAsNameVariantArray: value must be undefined, null, or a NameVariantValue[]',
    )
  ) {
    return undefined;
  }

  if (!value) {
    return undefined;
  }

  // Assume value is a NameVariantValue[]
  return value as NameVariantValue[];
};
