import React, {useCallback, useMemo} from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Popover,
} from '@mui/material';
import {
  CheckCircleIcon,
  CircleIcon,
  CloseSmallIcon,
  Layout,
  Text,
} from '../../../components';

export type ColumnSelectionOption = {
  key: string;
  title: string;
  checked: boolean;
};

export const useColumnSelectionPopover = (
  options: ColumnSelectionOption[],
  onChange?: (key: string, checked: boolean) => void,
  title?: string,
) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const open = Boolean(anchorEl);
  const id = 'simple-popover';

  const openPopover = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const closePopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.id, event.target.checked);
      }
    },
    [onChange],
  );

  const popoverProps = useMemo(
    () => ({
      anchorEl,
      closePopover,
      handleChange,
      open,
      options,
      title,
    }),
    [anchorEl, closePopover, handleChange, open, options, title],
  );

  const MultiSelectionPopover = useCallback(
    ({
      anchorEl,
      closePopover,
      handleChange,
      open,
      options,
      title,
    }: typeof popoverProps) => {
      return (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}>
          <Layout paddingLeft={2} paddingTop={0.5}>
            <Layout
              horizontal
              adjustCenter
              flex={1}
              justifyContent={'space-between'}>
              <Text variant="h4">{title || ''}</Text>
              <IconButton onClick={closePopover}>
                <CloseSmallIcon fontSize="small" />
              </IconButton>
            </Layout>
            <Layout paddingRight={1} paddingBottom={0.5}>
              <FormGroup>
                {options.map(opt => (
                  <FormControlLabel
                    key={opt.key}
                    control={
                      <Checkbox
                        id={opt.key}
                        checked={opt.checked}
                        onChange={handleChange}
                        icon={<CircleIcon />}
                        checkedIcon={<CheckCircleIcon />}
                      />
                    }
                    label={
                      <Text variant="h4" textTransform="capitalize">
                        {opt.title}
                      </Text>
                    }
                  />
                ))}
              </FormGroup>
            </Layout>
          </Layout>
        </Popover>
      );
    },
    [],
  );

  return useMemo(
    () => ({
      open: openPopover,
      close: closePopover,
      Popover: MultiSelectionPopover,
      popoverProps,
    }),
    [MultiSelectionPopover, closePopover, openPopover, popoverProps],
  );
};
