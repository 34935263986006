import {ClientRect} from '@dnd-kit/core';
import {Transform} from '@dnd-kit/utilities';
import {Spacing} from '../types';
import {getRelativePositionForIndex} from './getRelativePositionForIndex';

export const getRectTransform = (
  containerRect: ClientRect | null,
  spacing: Spacing,
  itemRects: ClientRect[],
  activeIndex: number,
  overIndex: number,
  index: number,
): Transform => {
  return containerRect
    ? {
        ...getRelativePositionForIndex(
          containerRect,
          spacing,
          itemRects,
          activeIndex,
          overIndex,
          index,
        ),
        scaleX: 1,
        scaleY: 1,
      }
    : {x: 0, y: 0, scaleX: 1, scaleY: 1};
};
