import {useMemo} from 'react';
import {CodeListMap} from 'api';
import {useExpressionManifestationsLocalCodelist} from './useExpressionManifestationsLocalCodelist';

export const useExpressionLocalCodelistMap = (
  expressionId: string,
): CodeListMap => {
  const expressionManifestationCodelist =
    useExpressionManifestationsLocalCodelist(expressionId);
  return useMemo(
    (): CodeListMap => ({
      expression_manifestation: expressionManifestationCodelist,
    }),
    [expressionManifestationCodelist],
  );
};
