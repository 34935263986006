import React, {PropsWithChildren, useCallback, useEffect, useMemo} from 'react';
import {Drawer, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {ThemeProvider, useTheme} from '@mui/system';
import {useWindowSize} from 'usehooks-ts';
import {ContainerChild} from 'components/types';
import {ColorPalette, useOuterSx} from 'theme';
import {HeaderHeight, UXUnit} from 'theme/settings';
import {useBrowserTabTitle} from 'services/head/hooks/useBrowserTabTitle';
import {ErrorBoundary} from 'components/errorBoundary';
import {RightPanelConfig} from './types';
import {SceneContentName} from './SceneContent';
import {SceneHeaderName} from './SceneHeader';
import {SceneRightPanelName} from './SceneRightPanel';
import {SceneSubMenuName} from './SceneSubMenu';
import {useLayoutTheme} from './hooks/useLayoutTheme';

type Props = {
  browserTitle?: string;
  rightPanelConfig?: RightPanelConfig;
};

export const Scene: React.FC<PropsWithChildren<Props>> = ({
  browserTitle,
  rightPanelConfig,
  children,
}) => {
  const {setBrowserTabTitle} = useBrowserTabTitle();

  useEffect(() => {
    if (browserTitle) {
      setBrowserTabTitle(browserTitle);
    }
  }, [setBrowserTabTitle, browserTitle]);

  const components = useMemo(
    () => React.Children.toArray(children) as unknown as ContainerChild[],
    [children],
  );

  const getComponentWithName = useCallback(
    (name: string): React.ReactNode | null => {
      const comps = components.filter(
        c => c && c.type && c.type.displayName === name,
      );
      return comps.length > 0 ? comps[0] : null;
    },
    [components],
  );

  const theme = useTheme();
  const outerSx = useOuterSx(theme);
  const headerHeight = HeaderHeight;

  const rightPanel = getComponentWithName(SceneRightPanelName);

  const hasRightPanel = rightPanel !== null;

  const aboveSm = useMediaQuery(theme.breakpoints.up('sm'));
  const aboveLg = useMediaQuery(theme.breakpoints.up('lg'));
  const sceneContentSize = hasRightPanel
    ? rightPanelConfig?.fullWidth
      ? 0
      : aboveSm
      ? aboveLg
        ? 7
        : 5
      : 0
    : 12;
  const {width: windowWidth} = useWindowSize();

  const {rightPanelSx, rightPanelContainerSx, sceneContentSx} = useMemo(() => {
    const rightPanelWidth = (12 - sceneContentSize) * (windowWidth / 12);
    const spacing = 3;

    const sceneContentSx = {
      display: {
        xs: rightPanel ? 'none' : 'block',
        sm: 'block',
      },
    };

    const rightPanelContainerSx = {
      p: theme.spacing(3),
      width: {
        xs: '100vw',
        sm: rightPanelWidth - UXUnit * spacing,
      },
    };

    return {
      rightPanelContainerSx,
      rightPanelSx: {
        '& .MuiDrawer-paper': {
          top: `${headerHeight}px`,
          height: `calc(100% - ${headerHeight}px)`,
          background:
            rightPanelConfig?.backgroundColour ?? ColorPalette.warmGreen,
        },
      },
      sceneContentSx,
    };
  }, [
    sceneContentSize,
    windowWidth,
    rightPanel,
    headerHeight,
    theme,
    rightPanelConfig?.backgroundColour,
  ]);

  const sceneContentLayout = useLayoutTheme(sceneContentSize);
  const sceneRightPanelLayout = useLayoutTheme(12 - sceneContentSize);

  return (
    <Box component={Grid} container sx={outerSx}>
      <Box component={Grid} item xs={sceneContentSize} sx={sceneContentSx}>
        <ThemeProvider theme={sceneContentLayout}>
          {getComponentWithName(SceneHeaderName)}
          <ErrorBoundary>
            {getComponentWithName(SceneSubMenuName)}
            {getComponentWithName(SceneContentName)}
          </ErrorBoundary>
        </ThemeProvider>
      </Box>
      <Drawer
        id="scene-rightpanel"
        sx={rightPanelSx}
        variant="persistent"
        anchor="right"
        open={hasRightPanel}
        transitionDuration={{
          enter: 200,
          exit: 0,
        }}>
        <Box sx={rightPanelContainerSx} data-cy={'scene-rightpanel'}>
          <ThemeProvider theme={sceneRightPanelLayout}>
            {rightPanel}
          </ThemeProvider>
        </Box>
      </Drawer>
    </Box>
  );
};
