import React, {useMemo} from 'react';
import {
  NameForm,
  NameVariantValue,
  PartNameVariant,
  Valx,
} from 'schemaDefinition/types';
import {isLinkedToNameVariant} from 'services/data/agent';
import {Icon, Layout, Spacer} from 'components';
import {BasePartProps} from './types';
import {usePreviewConfigurationContext} from '../contexts';
import {formatNameVariantValue} from '../functions';
import {useTextValue} from '../hooks';
import {LinkNameVariantButton} from './LinkNameVariantButton';
import {MultiValue} from './fields/MultiValue';

type PreviewNameVariantProps = BasePartProps<PartNameVariant<Valx>>;

export const PreviewNameVariant: React.FC<PreviewNameVariantProps> = ({
  part,
  value,
  layoutDirection,
}) => {
  const config = usePreviewConfigurationContext();
  const {nameVariants} = useMemo(() => {
    const values = value as NameVariantValue[];
    return {
      nameVariants: (values
        ? config.nameVariant?.excludeMainForm
          ? values.slice(1)
          : values
        : []
      ).map((val, idx) => ({
        ...val,
        nameForm: (!config.nameVariant?.excludeMainForm && idx === 0
          ? 'mainForm'
          : 'variant') as NameForm,
      })),
    };
  }, [config, value]);

  const TextValue = useTextValue();

  const linking = config.linking;

  return (
    <MultiValue
      part={part}
      value={nameVariants}
      layoutDirection={layoutDirection}
      renderValue={(key, singleValue) => {
        const isLinked =
          linking &&
          isLinkedToNameVariant(
            linking?.currentValue,
            linking.entityId,
            singleValue,
            singleValue.nameForm,
          );
        return linking ? (
          <Layout
            key={key}
            flex={1}
            horizontal
            alignItems="center"
            justifyContent={'space-between'}
            pb={0.5}>
            <Layout horizontal adjustCenter adjustLeft>
              {isLinked ? <Icon icon="CheckCircle" /> : null}
              {isLinked ? <Spacer size={1} /> : null}
              <TextValue key={key}>
                {formatNameVariantValue(
                  singleValue,
                  part.agentType,
                  'excludeId',
                )}
              </TextValue>
            </Layout>
            <LinkNameVariantButton
              value={singleValue}
              linkingConfiguration={linking}
            />
          </Layout>
        ) : (
          <TextValue key={key}>
            {formatNameVariantValue(singleValue, part.agentType, 'excludeId')}
          </TextValue>
        );
      }}
    />
  );
};
