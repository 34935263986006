import {PartValueRenderer} from 'schema/types';
import {useLocalization} from 'localization';

export const Media: PartValueRenderer = ({value}) => {
  const {tLoose} = useLocalization();

  if (Array.isArray(value) && value.length > 0) {
    const strings = value
      .map((v: unknown) => (typeof v === 'string' ? v : undefined))
      .filter(s => s !== undefined) as string[];

    const filtered = strings
      .filter(m => m !== 'ATTACHMENT')
      .map(m => tLoose(`page.overview.task.mediaSubType.${m}.title`))
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort()
      .join(', ');

    return filtered.length ? <>{filtered}</> : null;
  }

  return null;
};
