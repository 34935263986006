import {MetadataTimestamps} from '../../../types';

export const incrementMetadataTimestampForId = (
  id: string,
  timestamps: MetadataTimestamps,
): MetadataTimestamps => {
  const newTimestamps: MetadataTimestamps = {...timestamps};

  newTimestamps[id] = (newTimestamps[id] ?? 0) + 1;

  return newTimestamps;
};
