import {useMemo} from 'react';
import {Code, FieldError} from 'schemaDefinition/types';

export const useClassName = (
  cardinality: 'single' | 'multiple' | undefined,
  fullWidth: boolean | undefined,
  selectedCodes: Code | Code[] | null,
  fieldError: FieldError | undefined,
): string =>
  useMemo(() => {
    const single = cardinality === 'single';
    const empty =
      !selectedCodes ||
      (Array.isArray(selectedCodes) && selectedCodes.length === 0);
    const error = fieldError === true;
    const warning = fieldError === 'warning';
    let className = single
      ? empty
        ? error
          ? 'CodeListField-single Ebba-empty Ebba-error'
          : warning
          ? 'CodeListField-single Ebba-empty Ebba-warning'
          : 'CodeListField-single Ebba-empty'
        : error
        ? 'CodeListField-single Ebba-error'
        : warning
        ? 'CodeListField-single Ebba-warning'
        : 'CodeListField-single'
      : empty
      ? error
        ? 'CodeListField-multiple Ebba-empty Ebba-error'
        : warning
        ? 'CodeListField-multiple Ebba-empty Ebba-warning'
        : 'CodeListField-multiple Ebba-empty'
      : error
      ? 'CodeListField-multiple Ebba-error'
      : warning
      ? 'CodeListField-multiple Ebba-warning'
      : 'CodeListField-multiple';

    className = fullWidth ? `${className} CodeListField-full-width` : className;
    return className;
  }, [cardinality, fieldError, fullWidth, selectedCodes]);
