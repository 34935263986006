import assert from 'assert-ts';
import {NodeIdToCodeMap} from 'services/thesaurus/functions/types';
import {ThesaurusValue} from 'api';

export const mapNodeIdsToThesaurusValue = (
  nodeIds: string[],
  idToCodeMap: NodeIdToCodeMap,
): ThesaurusValue | undefined => {
  if (nodeIds.length === 0) {
    return undefined;
  }

  const codes = nodeIds.map(nodeId =>
    assert(
      idToCodeMap.get(nodeId),
      'mapNodeIdsToThesaurusValue: node id missing',
    ),
  );
  return Array.from(new Set(codes).values());
};
