import React, {useRef, useState} from 'react';
import Grid from '@mui/material/Grid';
import {Box} from '@mui/system';
import {NoProps} from 'types';
import {useLocalization} from 'localization';
import {ActionButton, Card, FlexBox, Spacer, Text, TextField} from 'components';
import {useReceiveEans} from '../hooks/useReceiveEans';
import {AttemptItem} from './AttemptItem';

export const ReceiveEans: React.FC<NoProps> = () => {
  const {t} = useLocalization();
  const {attempts, addEan} = useReceiveEans();
  const [ean, setEan] = useState<string>('');

  const inputRef = useRef<HTMLInputElement>();

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6}>
        <Spacer height={2} />
        <Card>
          <Text variant={'body2'}>Registrere mottak av fysiske bøker.</Text>
          <Spacer height={2} />
          <Box component="form" noValidate autoComplete="off">
            <Box
              display={'flex'}
              justifyContent={'flex-start'}
              alignItems={'flex-end'}
              flexDirection={'row'}
              gap={2}>
              <TextField
                autoFocus
                inputRef={inputRef}
                name={'ean'}
                type={'digits'}
                label={t('page.receive.form.ean.label')}
                value={ean}
                onChange={e => setEan(e.target.value)}
              />
              <ActionButton
                icon={'PrintBook'}
                showSpinnerOnClick
                disabled={ean.length !== 13}
                type={'submit'}
                title={t('page.receive.form.submit.label')}
                onClick={() => {
                  addEan(ean);
                  setEan('');
                  inputRef.current?.focus();
                }}
              />
            </Box>
          </Box>

          {attempts.length > 0 ? (
            <>
              <h2>{t('page.receive.status.header.label')}</h2>
              <FlexBox padding={2} border={1}>
                {attempts.map(attempt => (
                  <AttemptItem key={attempt.ean} {...attempt} />
                ))}
              </FlexBox>
            </>
          ) : null}
        </Card>
      </Grid>
    </Grid>
  );
};
