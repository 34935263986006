import React, {useCallback, useMemo, useRef} from 'react';
import {styled} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import {assert} from 'assert-ts';
import {FCWithChildren} from 'types';
import {BackgroundColor, ColorMode} from 'theme/types';
import {useBackgroundColor} from 'theme';
import {BaseProps, BoxStyleProps, ContainerChild} from '../types';
import {useVerticalCollapsableLayout} from '../layout/hooks';
import './Accordion.scss';
import {AccordionContentName} from './AccordionContent';
import {AccordionHeaderName} from './AccordionHeader';

type Props = BaseProps & {
  initialExpanded?: boolean;
  expanded?: boolean;
  unmountOnExit?: boolean;
  removed?: boolean;
  colorx?: string;
  color?: BackgroundColor;
  mode?: ColorMode;
  spacing?: number;
  onChange?: (expanded: boolean) => void;
};

const MuiAccordionStyled = styled(MuiAccordion)(() => ({
  // Could not figure out how to set this from scss
  '&:before': {
    content: 'none',
  },

  '&:not(.Mui-expanded)': {
    'div.hideWhenCollapsed': {
      pointerEvents: 'none',
      opacity: 0,
    },
  },

  'div.hideWhenCollapsed': {
    opacity: 1,
    transition: 'opacity 0.3s',
  },
}));

export const Accordion: FCWithChildren<Props> = ({
  initialExpanded,
  expanded,
  unmountOnExit = false,
  removed = false,
  colorx,
  color,
  mode,
  children,
  onChange,
  'data-cy': dataCy,
}) => {
  const initialExpandedRef = useRef(initialExpanded);
  const themeColor = useBackgroundColor(color, mode);
  const elementRef = useRef<HTMLDivElement>(null);
  const {style: removeStyle, className: removeClass} =
    useVerticalCollapsableLayout(elementRef, removed);
  const {style, className} = useMemo((): {
    style: BoxStyleProps;
    className: string;
  } => {
    const resolvedColor = colorx ?? themeColor;
    return {
      style: {
        background: resolvedColor,
        ...removeStyle,
      },
      className: removeClass ? 'EbbaAccordion ' + removeClass : 'EbbaAccordion',
    };
  }, [colorx, removeClass, removeStyle, themeColor]);

  const components = useMemo(
    () =>
      (React.Children.toArray(children) as ContainerChild[]).filter(
        c => c.type?.displayName,
      ),
    [children],
  );

  const getComponentWithName = useCallback(
    (name: string): React.ReactNode | null => {
      const comps = components.filter(
        c => c && c.type && c.type.displayName === name,
      );
      return comps.length > 0 ? comps[0] : null;
    },
    [components],
  );

  const handleChange = useCallback(
    (_: unknown, expanded: boolean) => {
      onChange && onChange(expanded);
    },
    [onChange],
  );

  return (
    <MuiAccordionStyled
      data-cy={dataCy}
      ref={elementRef}
      defaultExpanded={initialExpandedRef.current}
      expanded={expanded}
      disableGutters
      sx={style}
      TransitionProps={{unmountOnExit}}
      className={className}
      onChange={handleChange}>
      {assert(
        getComponentWithName(AccordionHeaderName),
        'Accordion must have header',
      )}
      {assert(
        getComponentWithName(AccordionContentName),
        'Accordion must have content',
      )}
    </MuiAccordionStyled>
  );
};
