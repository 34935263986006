import {
  Button,
  Card,
  IconButton,
  OutlinedInput,
  Paper,
  Typography,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import {AddIcon} from 'components/icons/AddIcon';
import {EbookIcon} from 'components/icons/EbookIcon';

export const TestScene = () => {
  const theme = useTheme();
  const r = theme.palette.text.disabled;
  const d = theme.palette.text.disabled;

  return (
    <Paper>
      <Card color="primary">
        <AddIcon />
        <Typography>Test</Typography>
        <Button>{'test'}</Button>
        {/* <Button color="secondary" variant="contained">
          {'test'}
        </Button> */}
        <OutlinedInput />
      </Card>

      <Card sx={{background: 'pink', color: r}}>
        <AddIcon mode={'dark'} />
        <Button>{'test'}</Button>
        <Button color="secondary" variant="contained">
          {'test'}
        </Button>
        <Typography>Test</Typography>
        <OutlinedInput color="secondary" />
      </Card>
      <Card sx={{background: 'pink', color: d}}>
        <AddIcon mode={'dark'} />
        <Button>{'test'}</Button>
        <Button variant="contained">{'test'}</Button>
        <Typography>Test</Typography>
        <IconButton>
          <EbookIcon />
        </IconButton>
        <OutlinedInput color="primary" />
      </Card>
    </Paper>
  );
};
