import assert from 'assert-ts';
import {JSONValue} from 'types';
import {GetTokens} from 'api/types';
import {Configuration} from 'configuration';
import {
  ElasticCloudAuthLambda,
  HighlightPostTag,
  HighlightPreTag,
  SearchType,
} from '../../searchTypes';
import {getOffset} from './functions/getOffset';
import {httpPost} from './http';
import {mapToHighlightsDto} from './mappers/mapToHighlightsDto';

export const httpElastic = <T>(
  getTokens: GetTokens,
  target: ElasticCloudAuthLambda['target'],
  searchType: SearchType,
  query: JSONValue,
  page = 0,
  size: number,
  minScore = 0.1,
  highlightFields?: string[],
  abortSignal?: AbortSignal,
  mock?: boolean,
): Promise<T> => {
  const {indexes} = Configuration.searchApi.elastic;
  const searchTypeIndex = indexes[searchType];

  assert(
    searchTypeIndex,
    `Expected index on search type ${searchType}: Might be missing a configuration key for index/environment`,
    {indexes},
  );

  const from = getOffset(page, size);

  const body: ElasticCloudAuthLambda = {
    target: target,
    indexes: [searchTypeIndex],
    elasticBody: {
      size,
      from,
      query,
      min_score: minScore,
      highlight: highlightFields
        ? {
            pre_tags: HighlightPreTag,
            post_tags: HighlightPostTag,
            fields: mapToHighlightsDto(highlightFields),
          }
        : {},
    },
  };

  return httpPost<T, ElasticCloudAuthLambda>({
    subdir: '',
    body,
    getTokens,
    abortSignal,
    mock,
  });
};
