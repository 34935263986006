import React from 'react';
import {LinkedValueSearch} from 'feature';
import {useLinkedValueSearchContext} from './hooks';

export const LinkedValueSearchContainer: React.FC = () => {
  const {
    sourceField,
    hasChanges,
    setHasChanges,
    setSourceValue,
    closeExtension,
  } = useLinkedValueSearchContext();

  return (
    <LinkedValueSearch
      sourceConfig={sourceField?.fieldConfig}
      fieldId={sourceField?.fieldId ?? ''}
      sourceValue={sourceField?.currentValue}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      onSetSourceValue={setSourceValue}
      onClose={closeExtension}
    />
  );
};
