import QueryString from 'qs';
import {Indexer} from 'types';

export const encodeURIParams = (
  params: Indexer | string | undefined,
): string => {
  if (!params) {
    return '';
  }

  return typeof params === 'string'
    ? params
    : QueryString.stringify(params, {
        format: 'RFC3986',
      });
};
