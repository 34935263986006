import React, {useEffect, useRef} from 'react';
import {TextFieldProps} from 'components/types';
import {Layout} from '../layout';

type Props = Pick<
  TextFieldProps,
  'placeholder' | 'onChange' | 'onBlur' | 'error' | 'className'
> & {
  value: string | null;
  name?: string;
  readonly?: boolean;
  maxLength?: number;
  rows?: number;
  warning?: boolean;
};

export const TextAreaEditable: React.FC<Props> = ({
  value,
  name,
  placeholder,
  error,
  warning,
  maxLength,
  rows = 3,
  className,
  onChange,
  onBlur,
}) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = '';
      ref.current.style.height = `${(ref.current?.scrollHeight ?? 0) + 0}px`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      kind="primary"
      padding={2}
      focusable
      error={error}
      warning={warning}>
      <textarea
        ref={ref}
        name={name}
        value={value ?? ''}
        placeholder={placeholder}
        maxLength={maxLength}
        className={className}
        style={{
          resize: 'none',
          fontFamily: 'inherit',
          fontSize: 'inherit',
          color: 'inherit',
          height: `${(ref.current?.scrollHeight ?? 0) + 0}px`,
        }}
        onInput={() => {
          if (ref.current) {
            ref.current.style.height = '';
            ref.current.style.height = `${
              ref.current?.scrollHeight ?? 0 + 0
            }px`;
          }
        }}
        rows={rows}
        onChange={onChange}
        onBlur={onBlur}
      />
    </Layout>
  );
};
