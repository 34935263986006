import React, {Fragment, useCallback, useState} from 'react';
import {Collapse, List} from '@mui/material';
import {TransitionGroup} from 'react-transition-group';
import {DataLoadStatus} from 'api/types';
import {Note} from 'services/data/metadata/types';
import {useLocalization} from 'localization';
import {useHandleDelayedCall} from 'services/utils';
import {ActivityIndicator, NoteInput, Spacer} from 'components';
import {ManifestationPanelContainer} from './ManifestationPanelContainer';
import {NoteCard} from './NoteCard';

type Props = {
  notes: DataLoadStatus<Note[]>;
  onCreateNote: (note: string) => Promise<void>;
  onUpdateNote: (id: string, note: string) => Promise<void>;
  onRemoveNote: (id: string) => Promise<void>;
  onClose: () => void;
};

const NoteIcons = {
  empty: 'Edit',
  submit: 'Send',
} as const;

export const NotesPanel: React.FC<Props> = ({
  notes,
  onCreateNote,
  onUpdateNote,
  onRemoveNote,
  onClose,
}) => {
  const {t} = useLocalization();
  const [newMessage, setNewMessage] = useState('');

  const handleCreate = useCallback(
    () =>
      onCreateNote(newMessage)
        .then(() => setNewMessage(() => ''))
        .catch(),
    [newMessage, onCreateNote],
  );

  const handleCreateDelayed = useHandleDelayedCall(handleCreate);

  return (
    <ManifestationPanelContainer
      title={t('page.metadata.manifestation.tools.notes')}
      icon={'Comments'}
      onClose={onClose}>
      {notes.status === 'NotLoaded' || notes.status === 'Loading' ? (
        <ActivityIndicator size={'small'} />
      ) : notes.status === 'Failed' ? (
        <p>{notes.error.message}</p>
      ) : (
        <Fragment>
          {notes.status === 'Loaded' ? (
            <NoteInput
              placeholder={t('page.metadata.manifestation.notes.enter')}
              icons={NoteIcons}
              value={newMessage}
              onChange={setNewMessage}
              onReset={() => setNewMessage('')}
              onSubmit={handleCreateDelayed.handleCall}
              isSubmitting={handleCreateDelayed.isCalling}
              isDelayed={handleCreateDelayed.isDelayed}
            />
          ) : null}
          <List disablePadding>
            {/* TODO: Fix typing */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <TransitionGroup>
              {notes.status === 'Loaded'
                ? notes.data.map(note => (
                    <Collapse key={note.note.id}>
                      <Spacer size={1} />
                      <NoteCard
                        note={note}
                        onUpdateNote={onUpdateNote}
                        onRemoveNote={onRemoveNote}
                      />
                    </Collapse>
                  ))
                : notes.status === 'Failed'
                ? 'failed'
                : 'loading'}
            </TransitionGroup>
          </List>
        </Fragment>
      )}
    </ManifestationPanelContainer>
  );
};
