import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import {ExpressionV4} from 'api/types';
import {SelectorById} from '../selectors/types';
import {useCreateSelectorById} from './useCreateSelectorById';

const metadataEditExpressionSelectorById: SelectorById<ExpressionV4> =
  expressionId => state => {
    const expression = state.metadataEdit.metadata?.expressions.find(
      e => e.id === expressionId,
    );
    if (!expression) {
      assert(false, 'useMetadataEditExpression: unknown expressionId', {
        expressionId,
      });
    }

    return expression;
  };

export const useMetadataEditExpression = (
  expressionId: string,
): ExpressionV4 => {
  const selector = useCreateSelectorById(
    expressionId,
    metadataEditExpressionSelectorById,
  );
  return useSelector(selector);
};
