import {ExpressionV4} from 'api';
import {Metadata} from '../../../types';

export const addNewExpression = (
  newExpression: ExpressionV4,
  currentMetadata: Metadata,
): Metadata => {
  return {
    ...currentMetadata,
    expressions: [...currentMetadata.expressions, newExpression],
  };
};
