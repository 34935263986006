import {Metadata, MetadataStatuses} from 'services/data/metadata/types';
import {ManifestationStatus} from 'api';
import {getAggregateStatusFromList} from './getAggregateStatusFromList';
import {getExpressionManifestationIds} from './getExpressionManifestationIds';
import {getExpressionWorkId} from './getExpressionWorkId';
import {getManifestationExpressionId} from './getManifestationExpressionId';
import {getWorkExpressionIds} from './getWorkExpressionIds';

export const aggregateChangedStatus = (
  changedStatus: ManifestationStatus | undefined,
  manifestationId: string,
  currentStatuses: MetadataStatuses,
  data: Metadata,
): MetadataStatuses => {
  const currentManifestationStatus = currentStatuses[manifestationId];
  if (currentManifestationStatus.changedStatus === changedStatus) {
    return currentStatuses;
  }

  const newManifestationStatus = {
    ...currentManifestationStatus,
    changedStatus: changedStatus,
  };

  const newStatuses = {
    ...currentStatuses,
    [manifestationId]: newManifestationStatus,
  };

  const expressionId = getManifestationExpressionId(data, manifestationId);
  const manifestationIds = getExpressionManifestationIds(data, expressionId);
  const manifestationStatuses = manifestationIds.map(mid => newStatuses[mid]);
  const newExpressionChangedStatus = getAggregateStatusFromList(
    manifestationStatuses,
    'changedStatus',
  );

  // No further aggregation is needed if the expression status is not changed
  if (newExpressionChangedStatus === newStatuses[expressionId].changedStatus) {
    return newStatuses;
  }

  newStatuses[expressionId] = {
    ...newStatuses[expressionId],
    changedStatus: newExpressionChangedStatus,
  };

  const workId = getExpressionWorkId(data, expressionId);
  const expressionIds = getWorkExpressionIds(data, workId);
  const expressionStatuses = expressionIds.map(eid => newStatuses[eid]);
  const newWorkChangedStatus = getAggregateStatusFromList(
    expressionStatuses,
    'changedStatus',
  );

  // No further aggregation is needed if the expression status is not changed
  if (newWorkChangedStatus === newStatuses[workId].changedStatus) {
    return newStatuses;
  }

  newStatuses[workId] = {
    ...newStatuses[workId],
    changedStatus: newWorkChangedStatus,
  };

  return newStatuses;
};
