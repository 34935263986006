import {
  ExpressionExports,
  ManifestationExports,
  Metadata,
  MetadataExports,
} from 'services/data/metadata/types';
import {getExpressionExportProps} from './getExpressionExportProps';
import {getManifestationExportProps} from './getManifestationExportProps';
import {getWorkExportProps} from './getWorkExportProps';

export const getMetadataExports = (
  data: Metadata,
  // currentExports: MetadataExports | undefined,
): MetadataExports => {
  return {
    work: getWorkExportProps(data.work),
    expressions: data.expressions.reduce<ExpressionExports>(
      (acc, expression) => {
        return {
          ...acc,
          [expression.id]: getExpressionExportProps(expression),
        };
      },
      {},
    ),
    manifestations: data.manifestations.reduce<ManifestationExports>(
      (acc, manifestation) => {
        return {
          ...acc,
          [manifestation.id]: getManifestationExportProps(manifestation),
        };
      },
      {},
    ),
  };
};
