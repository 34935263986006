import React from 'react';
import {ColorPalette} from 'theme';
import {Text} from 'components';

type Props = {
  title: string;
  color?: string;
};

export const HealthSubtitle: React.FC<Props> = ({
  title,
  color = ColorPalette.offWhite,
}) => {
  return (
    <Text variant="body2" sx={{color}}>
      {title}
    </Text>
  );
};
