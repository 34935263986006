import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const SearchIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M19.71 18.29L16.6 15.18C17.6588 13.7624 18.1413 11.9963 17.9507 10.2373C17.7601 8.47817 16.9103 6.85654 15.5725 5.6986C14.2346 4.54067 12.5079 3.93233 10.7396 3.99599C8.97138 4.05965 7.29285 4.79057 6.04171 6.04171C4.79057 7.29285 4.05965 8.97138 3.99599 10.7396C3.93233 12.5079 4.54067 14.2346 5.6986 15.5725C6.85654 16.9103 8.47817 17.7601 10.2373 17.9507C11.9963 18.1413 13.7624 17.6588 15.18 16.6L18.29 19.71L19.71 18.29ZM6.00002 11C6.00002 10.0111 6.29327 9.04441 6.84267 8.22217C7.39208 7.39992 8.17297 6.75906 9.0866 6.38062C10.0002 6.00218 11.0056 5.90317 11.9755 6.09609C12.9454 6.28902 13.8363 6.76522 14.5356 7.46449C15.2348 8.16375 15.711 9.05466 15.9039 10.0246C16.0969 10.9945 15.9979 11.9998 15.6194 12.9134C15.241 13.8271 14.6001 14.608 13.7779 15.1574C12.9556 15.7068 11.9889 16 11 16C9.67394 16 8.40217 15.4732 7.46449 14.5356C6.5268 13.5979 6.00002 12.3261 6.00002 11Z" />
    </SvgIcon>
  );
};
