import {useEffect, useRef, useState} from 'react';
import {HttpError} from 'api/http/types';
import {DataLoadStatus} from '../types';

export const useLazyGetResource = <TData>(
  doLoad: boolean,
  get: () => Promise<TData>,
): DataLoadStatus<TData> => {
  const doLoadRef = useRef(false);
  const [result, setResult] = useState<DataLoadStatus<TData>>({
    status: 'NotLoaded',
  });

  useEffect(() => {
    // Trigger loading first time doLoad is true
    if (doLoad && !doLoadRef.current) {
      doLoadRef.current = true;

      if (result.status === 'NotLoaded') {
        setResult(old => ({status: 'Loading', data: old.data}));
        get()
          .then(data => setResult(() => ({status: 'Loaded', data})))
          .catch((error: HttpError) =>
            setResult(old => ({
              status: 'Failed',
              error,
              data: old.data,
            })),
          );
      }
    }
  }, [doLoad, get, result.status]);

  return result;
};
