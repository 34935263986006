import React, {Fragment, useMemo} from 'react';
import {assert} from 'assert-ts';
import {
  PartLinkedLiterary,
  Valx,
  VerifiedLinkedValueLink,
} from 'schemaDefinition/types';
import {FieldLabel} from 'components/fields/FieldLabel';
import {useLabelAndPlaceholder} from 'schema/form/hooks';
import {useLinkedRoleCodelist} from 'schemas/links/hooks/useLinkedRoleCodelist';
import {BasePartProps} from './types';
import {usePreviewConfigurationContext} from '../contexts';
import {formatLinkedValueLink, getLinkedLiteralsByRoles} from '../functions';
import {useTextValue} from '../hooks';
import {GroupLabelProps} from './constants';
import {ValueLayout} from './fields/ValueLayout';

type Props = BasePartProps<PartLinkedLiterary<Valx>>;

export const PreviewLinkedLiteralByRoles: React.FC<Props> = ({part, value}) => {
  const {label} = useLabelAndPlaceholder(part);
  const config = usePreviewConfigurationContext();
  const {noLabels} = config.layout;
  const codelist = useLinkedRoleCodelist(part.roleCodelistId);
  const formattedByRoles = useMemo(() => {
    const byRoles = getLinkedLiteralsByRoles(value);
    return byRoles.map(byRole => {
      const role = codelist.codes.find(c => c.code === byRole.role)?.value;

      return {
        role: assert.soft(role, 'PreviewLinkedLiteralByRoles: code missing', {
          code: byRole.role,
          codelist: part.roleCodelistId,
        })
          ? role
          : `${byRole.role} (missing)`,
        links: byRole.links.map(link => ({
          value: formatLinkedValueLink(link.link, link.numberInSeries) ?? '',
          link: link.link,
        })),
      };
    });
  }, [codelist.codes, part.roleCodelistId, value]);

  const TextValue = useTextValue();

  const showLabel = !noLabels && !part.linkRole;

  return (
    <Fragment>
      {showLabel ? <FieldLabel label={label ?? ''} /> : null}
      {formattedByRoles.map(({role, links}, idx) => (
        <ValueLayout
          key={idx}
          renderValue={() => {
            const allVerifiedSiblingIds = links
              .filter(l => l.link.linkStatus === 'verified')
              .map(l => l.link.entityId)
              .filter(Boolean) as string[];

            return (
              <>
                {links.map((link, idx) => {
                  const verifiedLink =
                    link.link.linkStatus === 'verified'
                      ? (link.link as VerifiedLinkedValueLink)
                      : undefined;
                  const onNavigate = config.onNavigate;
                  return onNavigate && verifiedLink ? (
                    <TextValue
                      key={idx}
                      onClick={() =>
                        onNavigate(verifiedLink, allVerifiedSiblingIds, role)
                      }>
                      {link.value}
                    </TextValue>
                  ) : (
                    <TextValue key={idx}>{link.value}</TextValue>
                  );
                })}
              </>
            );
          }}
          forcedLabel={role}
          labelProps={GroupLabelProps}
          part={part}
          layoutDirection={'horizontal'}
        />
      ))}
    </Fragment>
  );
};
