import {assert} from 'assert-ts';
import {Thesaurus, ThesaurusNode} from 'api/types';
import {findNodeWithAncestors} from './findNodeWithAncestors';
import {getMissingNodeWithAncestors} from './getMissingNodeWithAncestors';

export const assertThesaurusNodeWithAncestors = (
  code: string,
  thesaurus: Thesaurus,
): {node: ThesaurusNode; ancestors: ThesaurusNode[]} => {
  const found = findNodeWithAncestors(code, thesaurus);

  if (
    assert.soft(
      found,
      `assertThesaurusNodeWithAncestors: node expected for code ${code}`,
      {
        code,
        thesaurusId: thesaurus.id,
        language: thesaurus.language,
      },
    )
  ) {
    return found;
  }

  return getMissingNodeWithAncestors(code, thesaurus);
};
