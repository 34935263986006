import {DefaultPartCompare, Part, PartSchema} from 'schemaDefinition/types';

export const resolveCompare = <T>(
  part: Part<T>,
  parentParts?: Part<T>[],
): Required<PartSchema>['compare'] => {
  if (part.compare) {
    return part.compare;
  }

  if (!parentParts || parentParts.length === 0) {
    return DefaultPartCompare;
  }

  const inherited = resolveCompare(
    parentParts.slice(-1)[0],
    parentParts.slice(0, -1),
  );
  return inherited === 'nested' ? DefaultPartCompare : inherited;
};
