import {Concept} from 'types';
import {ChangeRequest, ChangeRequestStorage, WorkMetadata} from 'api/types';
import {Schema} from 'schemaDefinition/types';
import {ActualChangesWithSchema, ThesaurusMap} from '../types';
import {find} from './find';
import {getActualDataChangesWithSchema} from './getActualDataChangesWithSchema';
import {TrimSchemaOptions} from './getTrimmedSchema';

/**
 * Compare options pr storage:
 * - transient: used when copying from work: excludeEmpty, excludeEqual
 * - local: used when moving to other work: excludeEmpty, excludeEqual
 * - backend: used when receiving from external source: no options
 */
const optionsByStorageMap: Record<
  ChangeRequestStorage,
  TrimSchemaOptions | undefined
> = {
  transient: {
    excludeEmpty: true,
    excludeEqual: true,
  },
  local: {
    excludeEmpty: true,
    excludeEqual: true,
  },
  backend: undefined,
};

export const getActualChangesWithSchema = (
  currentWorkData: WorkMetadata,
  changeRequest: ChangeRequest,
  schema: {
    work: Schema;
    expression: Schema;
    manifestation: Schema;
  },
  thesauruses?: ThesaurusMap,
): ActualChangesWithSchema | undefined => {
  const currentExpression = find(
    changeRequest.expression.id,
    currentWorkData.expressions,
  );
  const currentManifestation = find(
    changeRequest.manifestation.id,
    currentWorkData.manifestations,
  );
  const workChanges = getActualDataChangesWithSchema(
    changeRequest.work,
    currentWorkData.work,
    schema.work,
    thesauruses,
    optionsByStorageMap[changeRequest.storage],
  );

  const expressionChanges = getActualDataChangesWithSchema(
    changeRequest.expression,
    currentExpression ?? {},
    schema.expression,
    thesauruses,
    optionsByStorageMap[changeRequest.storage],
  );
  const manifestationChanges = getActualDataChangesWithSchema(
    changeRequest.manifestation,
    currentManifestation ?? {},
    schema.manifestation,
    thesauruses,
    optionsByStorageMap[changeRequest.storage],
  );

  const externalSuggestionChangeCount = changeRequest.externalSuggestions
    ? Object.keys(changeRequest.externalSuggestions).length
    : 0;

  const trimmedSchema: Schema = {
    name: 'changeRequest',
    parts: [],
  };

  if (
    !workChanges &&
    !expressionChanges &&
    !manifestationChanges &&
    externalSuggestionChangeCount === 0
  ) {
    const noChanges: ActualChangesWithSchema = {
      id: changeRequest.id,
      taskType: changeRequest.taskType,
      status: changeRequest.status ?? 'OPEN',
      workId: changeRequest.work.id,
      expresssionId: changeRequest.manifestation.expressionId,
      manifestationId: changeRequest.manifestation.id,
      externalSuggestions: undefined,
      externalSuggestionsCount: 0,
      changes: {
        work: undefined,
        expression: undefined,
        manifestation: undefined,
      },
      original: {
        work: currentWorkData.work,
        expression: currentExpression,
        manifestation: currentManifestation,
      },
      count: 0,
      paths: [],
      schema: trimmedSchema,
    };

    return noChanges;
  }

  if (workChanges) {
    trimmedSchema.parts.push({
      ...workChanges.trimmedSchema,
      type: 'schema',
      name: Concept.work,
      globalScopePath: 'work',
      key: schema.work.key,
      compare: 'nested',
    });
  }

  if (expressionChanges) {
    trimmedSchema.parts.push({
      ...expressionChanges.trimmedSchema,
      type: 'schema',
      name: Concept.expression,
      globalScopePath: 'expression',
      key: schema.expression.key,
      compare: 'nested',
    });
  }

  if (manifestationChanges) {
    trimmedSchema.parts.push({
      ...manifestationChanges.trimmedSchema,
      type: 'schema',
      name: Concept.manifestation,
      globalScopePath: 'manifestation',
      compare: 'nested',
    });
  }

  const changes: ActualChangesWithSchema = {
    id: changeRequest.id,
    taskType: changeRequest.taskType,
    status: changeRequest.status ?? 'OPEN',
    workId: changeRequest.work.id,
    expresssionId: changeRequest.expression.id,
    manifestationId: changeRequest.manifestation.id,
    externalSuggestions: changeRequest.externalSuggestions,
    changes: {
      work: workChanges?.actualChanges,
      expression: expressionChanges?.actualChanges,
      manifestation: manifestationChanges?.actualChanges,
    },
    original: {
      work: workChanges ? currentWorkData.work : undefined,
      expression: expressionChanges ? currentExpression : undefined,
      manifestation: manifestationChanges ? currentManifestation : undefined,
    },
    count:
      (workChanges?.count ?? 0) +
      (expressionChanges?.count ?? 0) +
      (manifestationChanges?.count ?? 0),
    externalSuggestionsCount: externalSuggestionChangeCount,
    paths: [
      ...(workChanges?.paths ?? []).map(path => `work.${path}`),
      ...(expressionChanges?.paths ?? []).map(path => `expression.${path}`),
      ...(manifestationChanges?.paths ?? []).map(
        path => `manifestation.${path}`,
      ),
    ],
    schema: trimmedSchema,
  };
  return changes;
};
