import {CodeListMap} from 'api/types';
import {Data, Schema} from 'schemaDefinition/types';
import {ValidationResult} from './types';
import {getSchemaForEdit} from '../getSchemaForEdit';
import {validResult} from './validResult';
import {validateSchema} from './validateSchema';

/**
 * Validate data according to schema
 *
 * @returns {
 *  valid: true/false
 *  errors: array of any errors found during validation
 * }
 */
export const validate = (
  data: Data,
  relatedScope: Data | undefined,
  schema: Schema | undefined,
  codelistMap: CodeListMap | undefined,
): ValidationResult => {
  // Assume valid
  const aggregateResult = validResult();

  if (!schema) {
    return aggregateResult;
  }

  return validateSchema(
    getSchemaForEdit(schema),
    {
      valuePath: '',
      localScope: data,
      globalScope: data,
      relatedScope,
      value: data,
      codelistMap,
    },
    aggregateResult,
  );
};
