import assert from 'assert-ts';
import {Concept} from 'types';
import {Data} from 'schemaDefinition/types';
import {EntityContainer, Metadata} from '../types';

export const getRelatedData = (
  entityContainer: EntityContainer,
  metadata: Metadata,
): Data => {
  switch (entityContainer.type) {
    case Concept.work:
      return {
        work: metadata.work,
      };
    case Concept.expression:
      return {
        work: metadata.work,
        expression: entityContainer.data,
      };
    case Concept.manifestation:
      return {
        work: metadata.work,
        expression: assert(
          metadata.expressions.find(
            e => e.id === entityContainer.data.expressionId,
          ),
          'getRelatedData: expression expected',
          entityContainer.data,
        ),
        manifestation: entityContainer.data,
      };
    case Concept.changeRequest: {
      return {};
    }
  }
};
