import React from 'react';
import InputLabel from '@mui/material/InputLabel';

type Props = {
  label: string;
  optional?: boolean;
  htmlFor: string;
};

export const Label: React.FC<Props> = ({label, optional = false, htmlFor}) => {
  return (
    <InputLabel
      sx={{textTransform: 'uppercase'}}
      htmlFor={htmlFor}
      required={!optional}>
      {label}
    </InputLabel>
  );
};
