import {
  SearchResultType,
  SearchResultValue,
} from 'feature/linkedValueSearch/types';
import {LinkedValueLink} from 'schemaDefinition/types';
import {SearchResult} from 'api/searchTypes';
import {isNullish} from 'services/utils';
import {getIndexOnPage} from 'feature/pagination/functions';
import {EntityIdOnly, EntitySearchState} from '../types';

export const getCurrentEntity = (
  state: EntitySearchState,
  viewEntity: EntityIdOnly | undefined,
  toEntityType: SearchResultType | undefined,
  currentEntityIdx: number | undefined,
  searchPage: number,
  pageSize: number,
  searchResult: SearchResult<SearchResultValue>,
  currentSourceValue: LinkedValueLink | undefined,
  searchEntityType: SearchResultType,
): {
  entity: EntityIdOnly | undefined;
  type: SearchResultType | undefined;
} => {
  const currentEntityPageIdx = getIndexOnPage(
    currentEntityIdx,
    searchPage,
    pageSize,
  );

  const currentEntity =
    state === 'browse' && !isNullish(currentEntityPageIdx)
      ? searchResult.hits[currentEntityPageIdx]
        ? ({
            id: searchResult.hits[currentEntityPageIdx].id,
          } as EntityIdOnly)
        : undefined
      : (state === 'viewEntity' || state === 'navigate') && viewEntity
      ? viewEntity
      : undefined;
  const currentEntityType: SearchResultType | undefined =
    state === 'navigate'
      ? toEntityType
      : currentEntity
      ? (((currentEntity.id === currentSourceValue?.entityId
          ? currentSourceValue?.type
          : undefined) ?? searchEntityType) as SearchResultType)
      : searchEntityType;

  return {
    entity: currentEntity,
    type: currentEntityType,
  };
};
