import {GetTokens, User, UserDto} from './types';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpGet} from './http/ebba';

export const getUsers = (
  getTokens: GetTokens,
  mock?: boolean,
): Promise<User[]> => {
  return exceptionToPromiseReject(() =>
    httpGet<{users: UserDto[]}>({
      subdir: 'users',
      getTokens,
      mock,
    }).then(usersDto => usersDto.users),
  );
};
