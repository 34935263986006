import {createContext, useCallback, useContext, useMemo, useState} from 'react';
import {assert} from 'assert-ts';

type TogglerContextType = {
  workHidden: boolean;
  onToggle: (hidden?: boolean) => void;
};

export const WorkToggleContext = createContext<TogglerContextType | undefined>(
  undefined,
);

export const useWorkToggleContextProviderValue = (): TogglerContextType => {
  const [workHidden, setWorkHidden] = useState<boolean>(false);

  const onToggle = useCallback((hidden?: boolean) => {
    setWorkHidden(prevState => (hidden !== undefined ? hidden : !prevState));
  }, []);

  return useMemo(
    () => ({
      workHidden,
      onToggle,
    }),
    [onToggle, workHidden],
  );
};

export const useWorkToggleContext = (): TogglerContextType => {
  return assert(
    useContext(WorkToggleContext),
    'TogglerContext: context expected',
  );
};
