import {ValidationResult} from 'schema/form/functions/validators/types';
import {Valid} from 'schemaDefinition/types';
import {ExpressionValidationResult} from 'schemas/types';

const someHasValue = (
  value: Valid,
  work: ValidationResult,
  expressions: ExpressionValidationResult[],
): boolean => {
  return work.valid === value || expressions.some(e => e.valid === value);
};

export const aggregateMetadataValid = (
  work: ValidationResult,
  expressions: ExpressionValidationResult[],
): Valid => {
  if (someHasValue('error', work, expressions)) {
    return 'error';
  }

  if (someHasValue('warning', work, expressions)) {
    return 'warning';
  }

  return 'valid';
};
