import {HttpError, ProblemConstraintViolation} from 'api/http/types';
import {LocalizationContextType} from 'localization/context/types';
import {TranslationKey} from 'localization/context/types';

export const getErrorMessage = (
  error: HttpError,
  defaultKey: TranslationKey,
  {t, tryT}: LocalizationContextType,
): string => {
  const info = error.info as ProblemConstraintViolation;

  const apiMsg = info.title ? tryT(`error.api.${info.title}`) : undefined;

  return apiMsg ?? info.detail ?? t(defaultKey);
};
