import React, {useMemo} from 'react';
import {DataValue, Part, Valx} from 'schemaDefinition/types';
import {isEmptyValue} from 'services/utils/isEmptyValue';
import {PartDiffProps} from '../components/types';
import {
  DataFormOriginalEmptyValueContainer,
  DataFormOriginalValueContainer,
} from '../components/DataFormOriginalValueContainer';
import {
  CompareValueContainerProps,
  CompareValueContainerType,
  DataFormUpdatedEmptyItemValueContainer,
  DataFormUpdatedItemValueContainer,
} from '../components/DataFormUpdatedValueContainer';
import {
  getUpdatedItemActions,
  needsItemValueContainer,
  resolveNestedItemDiff,
} from '../functions';
import {useValuePaddingSize} from './useValuePaddingSize';

/**
 * Hook for comparing item values
 * @param diff
 * @param part
 * @param value
 * @param valuePath
 * @returns
 */
export const useCompareItem = (
  diff: PartDiffProps | undefined,
  part: Part<Valx>,
  value: DataValue,
  valuePath: string,
): {
  /** Number of empty items to add in presentation for list to occuppy same space */
  valuePaddingSize: number;
  /** Get path to item */
  getItemValuePath: (idx: number) => string;
  /** Get diff for item */
  getItemDiff?: (idx: number) => PartDiffProps | undefined;
  /** Get container props for item */
  getItemContainerProps?: (
    idx: number,
  ) => CompareValueContainerProps | undefined;
  /** Get container for item */
  getItemContainer: (idx: number) => CompareValueContainerType;
} => {
  const valuePaddingSize = useValuePaddingSize(value, diff?.compareValue);

  return useMemo(() => {
    const needsContainer = diff && needsItemValueContainer(part, diff);

    const getItemValuePath = (idx: number) => `${valuePath}[${idx}]`;

    const getItemDiff = diff
      ? (idx: number) => {
          const nestedDiff = diff
            ? resolveNestedItemDiff(diff, idx, part, needsContainer)
            : undefined;
          return nestedDiff;
        }
      : undefined;

    const getItemContainerProps = needsContainer
      ? (idx: number): CompareValueContainerProps | undefined => {
          const itemPath = getItemValuePath(idx);
          const actions =
            diff.mode === 'updated'
              ? getUpdatedItemActions(idx, value, diff.compareValue)
              : undefined;
          const partContainerProps = needsContainer
            ? actions
              ? {valuePath: itemPath, part, actions}
              : {valuePath: itemPath, part}
            : undefined;

          return partContainerProps;
        }
      : undefined;

    const getItemContainer = (idx: number): CompareValueContainerType => {
      if (!needsContainer) {
        return React.Fragment;
      }

      const itemValue = Array.isArray(value) ? value[idx] : undefined;
      const isEmpty = isEmptyValue(itemValue);
      const updated = diff?.mode === 'updated';

      return updated
        ? isEmpty
          ? DataFormUpdatedEmptyItemValueContainer
          : DataFormUpdatedItemValueContainer
        : isEmpty
          ? DataFormOriginalEmptyValueContainer
          : DataFormOriginalValueContainer;
    };

    return {
      valuePaddingSize,
      getItemValuePath,
      getItemDiff,
      getItemContainerProps,
      getItemContainer,
    };
  }, [diff, part, value, valuePaddingSize, valuePath]);
};
