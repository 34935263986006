import {OrCondition, OrConditionV0, Valx} from '../types';
import {EvaluationArgs} from './types';
import {evaluateCondition} from './evaluateCondition';

export const evaluateOrCondition = (
  condition: OrConditionV0<Valx> | OrCondition<Valx>,
  args: EvaluationArgs,
): boolean => {
  const arg =
    (condition as OrCondition<Valx>).$or ??
    (condition as OrConditionV0<Valx>).arg;

  return arg.some(cond => evaluateCondition(cond, args));
};
