import {
  ChangeRequest,
  CodeListMap,
  ExpressionV4,
  ManifestationStatus,
  ManifestationV4,
  WorkV4,
} from 'api/types';
import {ChangeRequestAction, DataValue} from 'schemaDefinition/types';
import {EditMode, Metadata, MetadataEditState, MetadataSchemas} from './types';
import {
  MetadataAction_ChangeRequestSaved,
  MetadataAction_ExpressionSaved,
  MetadataAction_ManifestationSaved,
  MetadataAction_NewExpressionWithManifestationSaved,
  MetadataAction_Reset,
  MetadataAction_WorkSaved,
} from './metadataActionTypes';

// type PartialData<TData extends {id: string}> = Pick<TData, 'id'> &
//   Partial<Omit<TData, 'id'>>;

export type MetadataEditAction_Loaded = {
  /** Metadata has been loaded from backend */
  type: 'METADATAEDIT_LOADED';
  payload: {
    schemas: MetadataSchemas;
    codelists: CodeListMap;
    metadata: Metadata;
  };
};

type MetadataEditAction_Cancelled = {
  type: 'METADATAEDIT_CANCELLED';
  payload?: undefined;
};

type MetadataEditAction_Saved = {
  type: 'METADATAEDIT_SAVED';
  payload?: undefined;
};

export type MetadataEditAction_WorkEdited = {
  type: 'METADATAEDIT_WORK_EDITED';
  payload: WorkV4;
};

export type MetadataEditAction_ExpressionEdited = {
  type: 'METADATAEDIT_EXPRESSION_EDITED' | 'METADATAEDIT_NEW_EXPRESSION_EDITED';
  payload: ExpressionV4;
};

type MetadataEditAction_ManifestationEdited = {
  type: 'METADATAEDIT_MANIFESTATION_EDITED';
  payload: ManifestationV4;
};

type MetadataEditAction_ChangeRequestEdited = {
  type: 'METADATAEDIT_CHANGEREQUEST_EDITED';
  payload: ChangeRequest;
};

type MetadataEditAction_ApproveChangeRequestItem = {
  type: 'METADATAEDIT_APPROVE_CHANGEREQUESTITEM';
  payload: {
    changeRequestId: string;
    /** Root path of value, including 'work', 'expression' or 'manifestation' */
    rootValuePath: string;
    itemAction: ChangeRequestAction;
    /**
     * Use given value to set value at valuePath, independent action specified
     * If not provided, change request value at valuePath will be used
     */
    newValue?: DataValue;

    /**
     * "Path" to review status, default same as valuePath. Should be provided when
     * value comparison doesn't match specific values in original/change request data,
     * e.g. when comparing thesaurus terms grouped by category
     */
    statusPath?: string;
  };
};

/** Close change request, remaing items are rejected (implicitly) */
export type MetadataEditAction_NewChangeRequestFromCopy = {
  type: 'METADATAEDIT_NEW_CHANGEREQUEST_FROM_COPY';
  payload: ChangeRequest;
};

type MetadataEditAction_CloseChangeRequest = {
  type: 'METADATAEDIT_CLOSE_CHANGEREQUEST';
  payload: {
    changeRequestId: string;
  };
};

type MetadataEditAction_ManifestationStatusPreview = {
  type: 'METADATAEDIT_MANIFESTATION_STAUTS_PREVIEW';
  payload: {
    id: string;
    status?: ManifestationStatus;
  };
};

type MetadataEditAction_ManifestationStatusChanged = {
  type: 'METADATAEDIT_MANIFESTATION_STATUS_CHANGED';
  payload: {
    id: string;
    status?: ManifestationStatus;
  };
};

type MetadataEditAction_MoveManifestationToOtherExpression = {
  type: 'MOVE_MANIFESTATION_TO_OTHER_EXPRESSION';
  payload: {
    manifestationId: string;
    expressionId: string;
  };
};

type MetadataEditAction_MoveManifestationToNewExpression = {
  type: 'MOVE_MANIFESTATION_TO_NEW_EXPRESSION';
  payload: {
    manifestationId: string;
  };
};

type MetadataEditAction_CancelMoveManifestationToNewExpression = {
  type: 'CANCEL_MOVE_MANIFESTATION_TO_NEW_EXPRESSION';
  payload: {
    manifestationId: string;
  };
};

type MetadataEditAction_SetEditMode = {
  type: 'METADATAEDIT_SET_EDITMODE';
  payload: {
    id: string;
    key: string;
    mode: EditMode;
  };
};

type MetadataEditAction_SetSaving = {
  type: 'METADATAEDIT_SET_SAVING';
  payload: {
    saving: boolean;
  };
};

export type MetadataEditAction =
  | MetadataAction_Reset
  | MetadataEditAction_Loaded
  | MetadataEditAction_Cancelled
  | MetadataEditAction_Saved
  | MetadataEditAction_WorkEdited
  | MetadataAction_WorkSaved
  | MetadataEditAction_ExpressionEdited
  | MetadataAction_ExpressionSaved
  | MetadataAction_NewExpressionWithManifestationSaved
  | MetadataEditAction_ManifestationEdited
  | MetadataAction_ManifestationSaved
  | MetadataEditAction_ChangeRequestEdited
  | MetadataEditAction_ApproveChangeRequestItem
  | MetadataEditAction_NewChangeRequestFromCopy
  | MetadataEditAction_CloseChangeRequest
  | MetadataAction_ChangeRequestSaved
  | MetadataEditAction_ManifestationStatusPreview
  | MetadataEditAction_ManifestationStatusChanged
  | MetadataEditAction_MoveManifestationToOtherExpression
  | MetadataEditAction_MoveManifestationToNewExpression
  | MetadataEditAction_CancelMoveManifestationToNewExpression
  | MetadataEditAction_SetEditMode
  | MetadataEditAction_SetSaving;

export const MetadataEditDefaultState: MetadataEditState = {
  configuration: {
    schemas: undefined,
    codelists: {},
  },
  saving: false,
  metadata: undefined,
  approvedChanges: {},
  timestamps: {},
  newExpressionIds: [],
  order: [],
  statuses: {},
  editStatuses: {},
  editModes: {},
  exports: {
    work: {
      type: 'BOOK',
    },
    expressions: {},
    manifestations: {},
  },
};
