import {PartYearOrDate, Valx, YearOrDateValue} from 'schemaDefinition/types';
import {evaluateFieldRequired} from 'schemaDefinition';
import {isNullish} from 'services/utils';
import {ValidationArgs, ValidationResult} from './types';
import {fail} from './fail';
import {validResult} from './validResult';
import {isValidDate} from './validateDate';
import {validateValueType} from './validateValueType';
import {isValidYear} from './validateYear';
import {validateYearOrDateValidations} from './validateYearOrDateValidations';
import {warning} from './warning';

export const validateYearOrDate = (
  part: PartYearOrDate<Valx>,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(
    part,
    validateSingleYearOrDateValue,
    args,
    aggregateResult,
  );
};

export const validateSingleYearOrDateValue = (
  part: PartYearOrDate<Valx>,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  const yearOrDateValue = args.value as YearOrDateValue | undefined;

  if (
    isNullish(yearOrDateValue) ||
    (isNullish(yearOrDateValue.year) && isNullish(yearOrDateValue.date))
  ) {
    const required = evaluateFieldRequired(
      part.required,
      args.valuePath,
      args.localScope,
      args.globalScope,
      args.relatedScope,
      args.value,
    );
    return required
      ? (required === true ? fail : warning)(
          {
            ...args,
            part,
            validation: 'required',
            messageKey: 'required',
          },
          aggregateResult,
        )
      : aggregateResult;
  }

  if (Array.isArray(args.value) || typeof args.value !== 'object') {
    return fail(
      {
        ...args,
        part,
        validation: 'invalid type',
        messageKey: 'invalidYearOrDate',
      },
      aggregateResult,
    );
  }

  if (!isNullish(yearOrDateValue.year) && !isValidYear(yearOrDateValue.year)) {
    return fail(
      {
        ...args,
        part,
        validation: 'invalid type',
        messageKey: 'invalidYearOrDate',
      },
      aggregateResult,
    );
  }

  if (!isNullish(yearOrDateValue.date) && !isValidDate(yearOrDateValue.date)) {
    return fail(
      {
        ...args,
        part,
        validation: 'invalid type',
        messageKey: 'invalidYearOrDate',
      },
      aggregateResult,
    );
  }

  if (part.validation) {
    return validateYearOrDateValidations(
      part.validation,
      part,
      args,
      aggregateResult,
    );
  }

  return aggregateResult;
};
