import dayjs from 'dayjs';
import 'dayjs/locale/nb';
import 'dayjs/locale/nn';
import {Locale, MasterLocale} from '../../types';
import {setNumberLocale} from './formatNumber';

dayjs.locale(MasterLocale);

export const setFormattingLocale = (locale: Locale) => {
  dayjs.locale(locale);
  setNumberLocale(locale);
};

export * from './formatDate';
export * from './formatYear';
export * from './formatTime';
export * from './formatRelativeTime';
export * from './formatNumber';
export * from './getApiDateFormat';
