import {Filter} from 'api/types';

export const canFreeSolo = (filter: Filter): boolean => {
  if (filter.name === 'productGroup') {
    if (!['EQUAL', 'NOT_EQUAL'].includes(filter.operation)) {
      return true;
    }
  }

  if (filter.name === 'bookGroup') {
    if (!['EQUAL', 'NOT_EQUAL'].includes(filter.operation)) {
      return true;
    }
  }

  return false;
};
