import {
  GridFilterInputMultipleSingleSelect,
  GridFilterOperator,
} from '@mui/x-data-grid-pro';

export const isArrayFilterOperator: GridFilterOperator = {
  value: 'is',
  getApplyFilterFn: filterItem => {
    if (!Array.isArray(filterItem.value) || filterItem.value.length === 0) {
      return null;
    }

    return params => {
      return filterItem.value.every((v: string) => params.value.includes(v));
    };
  },
  InputComponent: GridFilterInputMultipleSingleSelect,
};
