import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import {Link as MuiLink} from '@mui/material';
import {LinkOwnProps} from '@mui/material/Link/Link';
import {TextVariant} from 'theme/types';
import {TextProps} from '../text/types';
import {Text} from '../text';

type Props = Partial<
  Pick<TextProps, 'textTransform' | 'variant' | 'mode' | 'kind'>
> &
  Pick<RouterLinkProps, 'to' | 'className'> & {
    title: string;
    sx?: LinkOwnProps['sx'];
    children?: React.ReactNode;
  };
export const Link: React.FC<Props> = ({
  to,
  sx,
  title,
  textTransform,
  variant,
  mode,
  kind,
  children,
}) => {
  const variantWithDefault: TextVariant = variant ? variant : 'body1';

  return (
    <MuiLink component={RouterLink} to={to} sx={sx}>
      {children ? (
        children
      ) : (
        <Text
          variant={variantWithDefault}
          component={'span'}
          textTransform={textTransform}
          kind={kind}
          mode={mode}>
          {title}
        </Text>
      )}
    </MuiLink>
  );
};
