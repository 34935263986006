import {WorkV4} from 'api/types';
import {TableConfiguration} from 'schema/table/types';
import {defaultTableConfig} from 'schema/table/configuration/defaultTableConfig';
import {Counter} from '../../renderers';
import {Agents} from '../../renderers/table';
import {workDefaultColumns} from './columns';

export const workTableConfig: TableConfiguration<WorkV4> = {
  ...defaultTableConfig,
  defaultColumns: workDefaultColumns,
  partValueRenderers: {
    links: Counter,
    agents: Agents,
  },
};
