import {useCallback} from 'react';
import {useLocalization} from 'localization';
import {ManifestationV4} from 'api';
import {useSnacks} from 'components';

/**
 * If statementOfResponsiblity has been populated from backend,
 * we notify the user with a snackback.
 */
export const useTriggerStatementOfResponsibilityGeneratedFeedback = () => {
  const {successSnack} = useSnacks();
  const {t} = useLocalization();

  return useCallback(
    (oldManifestation: ManifestationV4, newManifestation: ManifestationV4) => {
      if (
        !oldManifestation.statementOfResponsibility &&
        newManifestation.statementOfResponsibility
      ) {
        successSnack(
          t('page.metadata.manifestation.statementOfResponsibility.generate'),
        );
      }
    },
    [successSnack, t],
  );
};
