import assert from 'assert-ts';
import get from 'lodash/get';
import {Concept} from 'types';
import {ExpressionV4, ManifestationV4, WorkV4} from 'api/types';
import {ChangeRequestAction, DataValue} from 'schemaDefinition/types';
import {
  Metadata,
  MetadataChanges,
  MetadataChangesInfo,
} from 'services/data/metadata/types';
import {getChangeRequestRootAndLocalPath} from 'services/data/metadata/functions';
import {appendItemToOriginalValue} from './appendItemToOriginalValue';
import {getMetadataEntity} from './getMetadataEntity';
import {getParentArrayPathParts} from './getParentArrayPathParts';
import {removeItemFromOriginalValue} from './removeItemFromOriginalValue';
import {replaceOriginalValue} from './replaceOriginalValue';

export const addApproval = (
  changeRequestId: string,
  rootValuePath: string,
  itemAction: ChangeRequestAction,
  newValue: DataValue | undefined,
  statusPath: string | undefined,
  approvals: MetadataChanges,
  metadata: Metadata,
): {
  approvals: MetadataChanges;
  updatedEntity: WorkV4 | ExpressionV4 | ManifestationV4;
  entityType: Concept.work | Concept.expression | Concept.manifestation;
  entityId: string;
} => {
  const {root, localPath} = getChangeRequestRootAndLocalPath(rootValuePath);
  const changeRequest = assert(
    (metadata.changeRequests ?? []).find(cr => cr.id === changeRequestId),
    'addApproval: changeRequest not found',
    {changeRequestId},
  );

  const entityId = changeRequest[root].id;
  const value =
    newValue !== undefined
      ? newValue
      : ((get(changeRequest, rootValuePath) ?? null) as DataValue);

  let updatedHistory = approvals[changeRequestId]?.arraysUpdateHistory ?? {};

  const {entity, entityType} = getMetadataEntity(entityId, metadata);
  let updatedEntity = entity;

  // If newValue is provided, replace, independent of itemAction
  const resolvedItemAction = newValue !== undefined ? 'replace' : itemAction;

  switch (resolvedItemAction) {
    case 'replace':
      updatedEntity = replaceOriginalValue(entity, localPath, value);
      break;
    case 'removeItem':
    case 'addItem': {
      const {parentPath} = getParentArrayPathParts(localPath);
      if (
        !assert.soft(
          parentPath,
          'addItemToOriginalValue: parentArrayPath not found',
        )
      ) {
        break;
      }

      const arrayHistory = updatedHistory[parentPath] ?? [];
      const updated =
        itemAction === 'addItem'
          ? appendItemToOriginalValue(entity, arrayHistory, localPath, value)
          : removeItemFromOriginalValue(entity, arrayHistory, localPath);
      updatedEntity = updated.entity;
      updatedHistory = {
        ...updatedHistory,
        [parentPath]: updated.history ?? [],
      };
      break;
    }
    default:
      assert(false, 'addApproval: unknown itemAction', {itemAction});
  }

  const changeRequestApprovals: MetadataChangesInfo = {
    rootPaths: approvals[changeRequestId]?.rootPaths ?? [],
    arraysUpdateHistory: updatedHistory,
  };

  // Add statusPath if provided, otherwise use rootValuePath
  changeRequestApprovals.rootPaths.push(statusPath ?? rootValuePath);

  const updatedApprovals: MetadataChanges = {
    ...approvals,
    [changeRequestId]: changeRequestApprovals,
  };

  return {approvals: updatedApprovals, updatedEntity, entityType, entityId};
};
