import {ControlledIconButton} from 'components/button';
import {Content} from './Content';
import {IconTool} from './IconTool';
import {ToolBar as ToolBarContainer} from './ToolBar';

type ToolBarType = typeof ToolBarContainer & {
  IconTool: typeof IconTool;
  ControlledIconTool: typeof ControlledIconButton;
  Content: typeof Content;
};
const ToolBar: ToolBarType = ToolBarContainer as ToolBarType;
ToolBar.IconTool = IconTool;
ToolBar.ControlledIconTool = ControlledIconButton;
ToolBar.Content = Content;

export {ToolBar, IconTool};
