import {useAssertExpressionEdit} from 'services/data';
import {Text} from 'components';
import {useExpressionTitle} from './ExpressionCard/hooks/useExpressionTitle';

type Props = {
  id: string;
};

export const ExpressionTitle: React.FC<Props> = ({id}) => {
  const expression = useAssertExpressionEdit(id);
  const title = useExpressionTitle(expression, undefined);

  return (
    <Text variant="body2" sx={{fontWeight: 'bold'}}>
      {title}
    </Text>
  );
};
