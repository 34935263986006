import {assert} from 'assert-ts';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import {WorkV4} from 'api/types';
import {PartValueModifier, ThesaurusValue} from 'schemaDefinition/types';
import {isNullish} from 'services/utils';
import {
  hasMappableThemaInterest,
  mapIntellectualLevelToThemaInterest,
  themaInterestToIntellectualLevelMap,
} from './mapper/themaInterestToIntellectualLevel';

/**
 * Add thema interest based when adding intellectual level
 */
export const intellectualLevelModifier: PartValueModifier = ({
  value,
  part,
  oldValue,
  entityValue,
}) => {
  assert(
    part && part.customizeKey === 'intellectualLevel',
    'intellectualLevelModifier: part must have customizeKey intellectualLevel',
    part,
  );

  assert(
    isNullish(value) || Array.isArray(value),
    'intellectualLevelModifier: value must be null or an array',
    {value, part},
  );
  assert(
    isNullish(oldValue) || Array.isArray(oldValue),
    'intellectualLevelModifier: oldValue must be null or an array',
    {oldValue, part},
  );

  // List just rearranged
  if ((oldValue?.length ?? 0) === (value?.length ?? 0)) {
    return {value};
  }

  /*

  ex 1, level blir lagt til
    * finn ut om man må legge til thema

  ex 2, level blir fjernet
    * finn ut om man må fjerne thema
    *   finn ut om man må legge til annet thema istedet
   */

  const originalThemaInterests = (entityValue as WorkV4).themes ?? [];
  const themaInterests: ThesaurusValue = clone(originalThemaInterests);

  // Check if any thema interests needs to be removed.

  // Remove all thema interests (instead of checking for removed intellectualLevel), will be reapplied if added later
  const valuesAsThemaInterest = Object.values(
    themaInterestToIntellectualLevelMap,
  )
    .map(intellectualLevel =>
      mapIntellectualLevelToThemaInterest(intellectualLevel),
    )
    .filter(t => t) as string[];

  valuesAsThemaInterest.forEach(themaInterest => {
    if (themaInterests.includes(themaInterest)) {
      themaInterests.splice(themaInterests.indexOf(themaInterest), 1);
    }
  });

  // Check if a thema interest needs to be added.
  if (value) {
    if (value.length) {
      const valuesAsThemaInterest = (value as ThesaurusValue)
        .map(intellectualLevel =>
          mapIntellectualLevelToThemaInterest(intellectualLevel),
        )
        .filter(t => t) as string[];
      if (
        !hasMappableThemaInterest(themaInterests) &&
        valuesAsThemaInterest.length
      ) {
        // Only add the first thema interest
        themaInterests.push(valuesAsThemaInterest[0]);
      }
    }
  }

  // If thema interests has changed, add side effect to change it.
  if (!isEqual(originalThemaInterests, themaInterests)) {
    return {
      value,
      sideEffects: [
        {
          value: themaInterests,
          valuePath: 'themes',
        },
      ],
    };
  }

  return {value};
};
