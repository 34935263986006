import React from 'react';
import {AppBar, Toolbar} from '@mui/material';
import {useThemeColors} from '../../theme';
import {Logo} from '../svg';

export const TopBarAnon: React.FC = () => {
  const themeColors = useThemeColors();

  return (
    <AppBar
      position="static"
      sx={{
        backgroundClip: 'padding-box',
      }}>
      <Toolbar
        disableGutters
        sx={{
          paddingX: 3,
          justifyContent: 'space-between',
          background: themeColors.background.topBar.main,
        }}>
        <Logo />
      </Toolbar>
    </AppBar>
  );
};
