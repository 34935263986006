import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const FilesIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M18.29 12L12.93 17.4C12.5609 17.8073 12.1129 18.1355 11.6132 18.3645C11.1135 18.5936 10.5725 18.7188 10.023 18.7326C9.47352 18.7463 8.92694 18.6483 8.41642 18.4446C7.9059 18.2408 7.44207 17.9355 7.05304 17.5472C6.66402 17.1588 6.35791 16.6955 6.15326 16.1854C5.94861 15.6752 5.84969 15.1288 5.86249 14.5793C5.8753 14.0297 5.99956 13.4885 6.22774 12.9884C6.45593 12.4883 6.78328 12.0398 7.18997 11.67L8.37997 10.47L12.75 6.1C13.2037 5.69679 13.7943 5.48217 14.401 5.50003C15.0077 5.51789 15.5847 5.76688 16.0139 6.19608C16.4431 6.62528 16.6921 7.20225 16.7099 7.80897C16.7278 8.41568 16.5132 9.0063 16.11 9.46L15.41 10.15L10.41 15.15C10.3443 15.2157 10.2664 15.2677 10.1806 15.3033C10.0948 15.3388 10.0028 15.3571 9.90997 15.3571C9.81711 15.3571 9.72516 15.3388 9.63937 15.3033C9.55358 15.2677 9.47563 15.2157 9.40997 15.15C9.34431 15.0843 9.29223 15.0064 9.25669 14.9206C9.22115 14.8348 9.20286 14.7429 9.20286 14.65C9.20286 14.5571 9.22115 14.4652 9.25669 14.3794C9.29223 14.2936 9.34431 14.2157 9.40997 14.15L14.88 8.68L13.52 7.27L8.05997 12.74C7.6856 13.1191 7.43224 13.6009 7.33202 14.1242C7.2318 14.6474 7.28924 15.1887 7.49705 15.6793C7.70486 16.1699 8.05369 16.5878 8.49929 16.8799C8.94489 17.172 9.46719 17.3251 9.99997 17.32C10.3533 17.3211 10.7032 17.2518 11.0295 17.1162C11.3557 16.9805 11.6516 16.7813 11.9 16.53L16.77 11.67L17.56 10.87C18.3713 10.0488 18.8247 8.93989 18.8212 7.78548C18.8177 6.63107 18.3576 5.52495 17.5413 4.70865C16.725 3.89236 15.6189 3.43223 14.4645 3.42873C13.3101 3.42524 12.2012 3.87867 11.38 4.69L6.99997 9.06L5.79997 10.25C4.67401 11.3886 4.04449 12.9266 4.04897 14.528C4.05345 16.1293 4.69157 17.6638 5.82388 18.7961C6.9562 19.9284 8.49066 20.5665 10.092 20.571C11.6933 20.5755 13.2313 19.946 14.37 18.82L19.74 13.45L18.29 12Z" />
    </SvgIcon>
  );
};
