import {GrepExtendedConcept} from 'api';
import {isDateValue} from './isDateValue';
import {isTextValue} from './isTextValue';

type GrepDetails = GrepExtendedConcept['details'];

export const groupNodeDetailsItems = (
  details: GrepDetails | undefined,
): GrepDetails[] | undefined => {
  let nextGroup: GrepDetails = {};
  const grouped: GrepDetails[] = [];

  if (!details) {
    return grouped;
  }

  const keys = Object.keys(details);
  keys.forEach(key => {
    const item = details[key];
    if ((isTextValue(item) && item.type === 'text') || isDateValue(item)) {
      nextGroup[key] = item;
    } else {
      if (Object.keys(nextGroup).length > 0) {
        grouped.push(nextGroup);
        nextGroup = {};
      }
      grouped.push({[key]: item});
    }
  });

  if (Object.keys(nextGroup).length > 0) {
    grouped.push(nextGroup);
  }

  return grouped;
};
