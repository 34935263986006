import React from 'react';
import {useLocalization} from 'localization';
import {IconButton, Layout, Scrollable, Spacer} from 'components';
import {FieldLabel} from 'components/fields/FieldLabel';
import {ColorPalette} from '../../../theme';
import {useThesaurusEditStateContext} from '../contexts';
import {useSearchResultPaging} from '../hooks';
import {SearchCategoryButton} from './SearchCategoryButton';
import {SearchResultRange} from './SearchResultRange';
import {SearchingTopLevels} from './SearchingTopLevels';

const pageSize = 10;

export const SearchingTopLevelsContainer: React.FC = () => {
  const {t} = useLocalization();
  const {thesaurus, searchResult} = useThesaurusEditStateContext();
  const {
    resultByCategoriesPage,
    startItemIndex,
    resultCategories,
    canGoto,
    goto,
  } = useSearchResultPaging(thesaurus, searchResult, pageSize);

  const total = searchResult?.matches.length ?? 0;
  const fromItem = startItemIndex + 1;
  const toItem = Math.min(fromItem + pageSize - 1, total);

  return (
    <Layout flex={1}>
      <FieldLabel
        label={t('extension.thesaurusEdit.search.label')}
        colorx={ColorPalette.burgundy}
      />
      <Layout pb={0.5} horizontal adjustCenter justifyContent={'space-between'}>
        <Layout horizontal adjustCenter adjustLeft wrap>
          {resultCategories.map(category => (
            <Layout key={category.id} p={0.5}>
              <SearchCategoryButton
                title={category.label}
                count={category.count}
                onClick={() => goto({categoryId: category.id})}
              />
            </Layout>
          ))}
        </Layout>
        {total > pageSize ? (
          <Layout horizontal adjustCenter justifySelf={'flex-end'}>
            <IconButton
              icon="ChevronLeft"
              disabled={!canGoto('prevPage')}
              onClick={() => goto('prevPage')}
            />
            <SearchResultRange from={fromItem} to={toItem} count={total} />
            <IconButton
              icon="ChevronRight"
              disabled={!canGoto('nextPage')}
              onClick={() => goto('nextPage')}
            />
          </Layout>
        ) : (
          <Layout pr={5} pl={2}>
            <SearchResultRange from={fromItem} to={toItem} count={total} />
          </Layout>
        )}
      </Layout>
      <Spacer size={0.5} />
      <Scrollable>
        <SearchingTopLevels resultByCategories={resultByCategoriesPage} />
      </Scrollable>
    </Layout>
  );
};
