import {Thesaurus, ThesaurusId} from 'api';

export const getDummyThesaurus = (id: ThesaurusId): Thesaurus => ({
  id,
  code: id,
  language: 'nb',
  label: `Thesaurus ${id}`,
  children: [
    {
      id: 'code1',
      code: 'code1',
      label: `${id}-1`,
      selectable: true,
    },
    {
      id: 'code2',
      code: 'code2',
      label: `${id}-2`,
      selectable: true,
    },
    {
      id: 'code3',
      code: 'code3',
      label: `${id}-3`,
      selectable: true,
    },
  ],
});
