import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridColumnOrderChangeParams,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowParams,
  GridValidRowModel,
  gridColumnFieldsSelector,
  gridColumnResizeSelector,
  gridColumnVisibilityModelSelector,
} from '@mui/x-data-grid-pro';
import {GridApiPro} from '@mui/x-data-grid-pro/models/gridApiPro';
import {PageSizeProps, PaginatorProps} from 'components/paginator/types';
import {FilterModel, RowId, RowSelection, Sorting} from './types';
import './DataTable.scss';
import {CustomFooter, CustomGridToolbar, NoRowsOverlay} from './components';
import {
  compareVisibilityModels,
  filterPanelProps,
  getTableStyles,
} from './functions';
import {CheckboxColDef} from './functions/CheckboxColDef';
import {useSorting} from './hooks';

export type DataTableProps<TData extends GridValidRowModel> = {
  apiRef: React.MutableRefObject<GridApiPro>;
  className?: string;
  loading?: boolean;
  columns: GridColDef[];
  rows: TData[];
  sorting?: Sorting;
  enableSelectionButton?: RowSelection;
  selection?: RowId[];
  onSortingChanged?: (sorting?: Sorting) => void;
  onSelectionChanged?: (rowIds: RowId[]) => void;
  onRowClick?: (row: TData) => void;
  filterModel?: FilterModel;
  setFilterModel?: (model: FilterModel) => void;
  setColumnVisibility?: (columnName: string, visible: boolean) => void;
  setColumnsOrder?: (columnsOrder: string[]) => void;
  setColumnWidth?: (columnName: string, width: number) => void;
  getRowHeight?: (params: {
    id: RowId;
    densityFactor: number;
  }) => number | undefined | 'auto';
  header?: ReactNode;
  columnSelectionTitle?: string;
  paginatorProps?: PaginatorProps;
  pageSizeProps?: PageSizeProps;
} & Pick<
  DataGridProProps<TData>,
  | 'hideFooter'
  | 'autoHeight'
  | 'slots'
  | 'slotProps'
  | 'columnVisibilityModel'
  | 'pinnedColumns'
>;

export function DataTable<TData extends GridValidRowModel>({
  apiRef,
  className,
  loading,
  columns: dataColumns,
  rows,
  sorting,
  enableSelectionButton,
  selection,
  autoHeight,
  onSortingChanged,
  onSelectionChanged,
  onRowClick,
  setColumnsOrder,
  setColumnWidth,
  columnVisibilityModel,
  setColumnVisibility,
  filterModel,
  setFilterModel,
  getRowHeight,
  slots,
  slotProps,
  header,
  columnSelectionTitle,
  paginatorProps,
  pageSizeProps,
  hideFooter,
  pinnedColumns,
}: PropsWithChildren<DataTableProps<TData>>) {
  const {sortModel, handleToggleSort} = useSorting(sorting, onSortingChanged);
  const singleSelectionRef = useRef<RowId[]>([]);
  const [singleSelection, setSingleSelection] = useState<RowId[]>([]);

  const handleSelectionChanged = useCallback(
    (newSelection: RowId[]) => {
      if (enableSelectionButton === 'multiple') {
        onSelectionChanged && onSelectionChanged(newSelection);
      } else if (enableSelectionButton === 'single') {
        const prev = singleSelectionRef.current;

        singleSelectionRef.current =
          // Toggle off if selecting same row again
          newSelection.length === 0 ||
          (newSelection.length === 1 && newSelection[0] === prev[0])
            ? []
            : [newSelection[newSelection.length - 1]];
        setSingleSelection(singleSelectionRef.current);
        onSelectionChanged && onSelectionChanged(singleSelectionRef.current);
      }
    },
    [enableSelectionButton, onSelectionChanged],
  );

  const columns = useMemo((): GridColDef[] => {
    return enableSelectionButton === 'single'
      ? [CheckboxColDef, ...dataColumns]
      : dataColumns;
  }, [dataColumns, enableSelectionButton]);

  const handleColumnOrderChange = useCallback(
    (_params: GridColumnOrderChangeParams) => {
      const newOrder = gridColumnFieldsSelector(apiRef);

      if (setColumnsOrder) {
        setColumnsOrder(newOrder);
      }

      return newOrder;
    },
    [apiRef, setColumnsOrder],
  );

  const handleColumnWidthChange = useCallback(
    (params: GridColumnResizeParams) => {
      const columnResizeState = gridColumnResizeSelector(apiRef.current.state);

      if (setColumnWidth) {
        setColumnWidth(params.colDef.field, params.width);
      }

      return columnResizeState;
    },
    [apiRef, setColumnWidth],
  );

  const handleColumnVisibilityChange = useCallback(
    (newModel: GridColumnVisibilityModel) => {
      const columnVisibilityState = gridColumnVisibilityModelSelector(
        apiRef.current.state,
      );

      if (setColumnVisibility) {
        const diff = compareVisibilityModels(columnVisibilityState, newModel);
        Object.keys(diff).forEach(key => {
          setColumnVisibility(key, diff[key]);
        });
      }

      return newModel;
    },
    [apiRef, setColumnVisibility],
  );

  const handleFilterModelChange = useCallback(
    (model: GridFilterModel) => {
      if (setFilterModel) {
        setFilterModel(model);
      }

      return model;
    },
    [setFilterModel],
  );

  const handleRowClick = useCallback(
    (params: GridRowParams) => {
      onRowClick && onRowClick(params.row as TData);
    },
    [onRowClick],
  );

  const sx = useMemo(() => {
    return getTableStyles<TData>(onRowClick, getRowHeight);
  }, [getRowHeight, onRowClick]);

  return (
    <DataGridPro<TData>
      apiRef={apiRef}
      // We handle it remotely (see CustomGridToolbar and CustomFooter)
      pagination={false}
      pinnedColumns={pinnedColumns}
      className={className}
      loading={loading}
      columns={columns}
      rows={rows}
      sx={sx}
      density="compact"
      autoHeight={autoHeight}
      rowSpacingType="margin"
      getRowHeight={getRowHeight}
      disableVirtualization
      checkboxSelection={enableSelectionButton === 'multiple'}
      rowSelectionModel={
        enableSelectionButton === 'single'
          ? selection ?? singleSelection
          : selection
      }
      hideFooter={hideFooter}
      sortModel={sortModel}
      onColumnHeaderClick={handleToggleSort}
      onRowSelectionModelChange={handleSelectionChanged}
      onRowClick={handleRowClick}
      disableColumnResize={setColumnWidth === undefined}
      filterModel={filterModel}
      onFilterModelChange={handleFilterModelChange}
      onColumnOrderChange={handleColumnOrderChange}
      onColumnWidthChange={handleColumnWidthChange}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={handleColumnVisibilityChange}
      disableColumnMenu={true}
      slots={{
        noRowsOverlay: NoRowsOverlay,
        toolbar: CustomGridToolbar,
        footer: CustomFooter,
        ...slots,
      }}
      slotProps={{
        ...slotProps,
        toolbar: {
          header: header,
          columnSelectionTitle,
          paginatorProps,
          ...slotProps?.toolbar,
        },
        footer: {
          paginatorProps,
          pageSizeProps,
        },
        panel: {
          placement: 'bottom-end',
        },
        filterPanel: filterPanelProps,
      }}
    />
  );
}
