import {GetTokens, WorkWithExpressions} from './types';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpElastic} from './http/search/httpElastic';
import {mapSearchWorkResult} from './mappers/mapSearchWorkResults';
import {SearchResult, SearchResultDto, SearchWorkDto} from './searchTypes';

export const searchWork = (
  query: string,
  page: number,
  size: number,
  getTokens: GetTokens,
  signal?: AbortSignal,
  _fields?: string[],
): Promise<SearchResult<WorkWithExpressions>> => {
  return exceptionToPromiseReject(() =>
    httpElastic<SearchResultDto<SearchWorkDto>>(
      getTokens,
      '_search',
      'catalog',
      {
        multi_match: {
          query: query,
          type: 'cross_fields',
          fields: ['*'],
        },
      },
      page,
      size,
      0.1,
      undefined,
      signal,
    ).then(mapSearchWorkResult),
  );
};
