import assert from 'assert-ts';
import {MetadataEditState} from 'services/data/metadata/types';

export const completeChangeRequest = (
  changeRequestId: string,
  state: MetadataEditState,
): MetadataEditState => {
  const {metadata, approvedChanges, editStatuses} = state;
  if (!metadata) {
    return state;
  }

  const changeRequest = assert(
    metadata.changeRequests?.find(cr => cr.id === changeRequestId),
    'completeChangeRequest: Change request not found',
    {changeRequestId},
  );

  return {
    ...state,
    metadata: {
      ...metadata,
      changeRequests: (metadata.changeRequests ?? []).map(cr =>
        cr.id === changeRequestId
          ? {
              ...cr,
              status: 'COMPLETED',
            }
          : cr,
      ),
    },
    editStatuses:
      // Just close the change request if it's transient
      changeRequest.storage === 'transient'
        ? state.editStatuses
        : {...editStatuses, [changeRequestId]: {hasDataChanged: true}},
    approvedChanges: {
      ...approvedChanges,
      [changeRequestId]: {
        rootPaths: [],
        arraysUpdateHistory: {},
        isCompleted: true,
      },
    },
  };
};
