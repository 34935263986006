import {Schemas} from 'api/dto.generated';

export const thema: Schemas.ThemaExport[] = [
  {
    id: '1',
    label: 'Kvalifikatorer: geografi',
    originalLabel: 'Kvalifikatorer: geografi',
    alternativeLabels: [],
    note: 'Bruk kvalifikatorer for geografi MED passende emnekoder f.eks. for å angi sted for ei reisehåndbok, handlingssted for en roman, hvilken jurisdiksjon en lov gjelder for, lokalhistorie, et steds folkeslag, kunst, tradisjoner eller kultur etc. Der det ikke fins en kode for et spesifikt sted, bruk den nærmeste bredere koden som passer. IKKE bruk kvalifikatorer fra 1* for å angi aspekter ved utgivelsen som ikke har med innholdet å gjøre (f.eks. utgivelsessted, forfatters nasjonalitet, tilgjengelighet i ulike markeder etc. - dette beskrives i andre metadata). Nasjonale utvidelser (dvs. på formatet 1*-xx-, hvor ‘xx’ angir en landkode) kan brukes av alle, og er ikke eksklusive for brukere i det angitte landet. IKKE bruk koden ‘1’ alene, men velg en spesifikk kvalifikator fra 1*',
    related: [],
    children: [
      {
        id: '1A',
        label: 'Verden',
        originalLabel: 'Verden',
        alternativeLabels: [],
        note: 'Use for: indicating a specifically global context',
        related: [],
        children: [],
      },
      {
        id: '1D',
        label: 'Europa',
        originalLabel: 'Europa',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '1DD',
            label: 'Vest-Europa',
            originalLabel: 'Vest-Europa',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1DDB',
                label: 'Belgia',
                originalLabel: 'Belgia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DDB-BE-B',
                    label: 'Brussel',
                    originalLabel: 'Brussel',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DDB-BE-F',
                    label: 'Flandern',
                    originalLabel: 'Flandern',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDB-BE-FA',
                        label: 'Antwerpen (provins)',
                        originalLabel: 'Antwerpen (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDB-BE-FAA',
                            label: 'Antwerpen',
                            originalLabel: 'Antwerpen',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDB-BE-FB',
                        label: 'Flamsk Brabant',
                        originalLabel: 'Flamsk Brabant',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDB-BE-FBA',
                            label: 'Leuven',
                            originalLabel: 'Leuven',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDB-BE-FC',
                        label: 'Limburg (BE)',
                        originalLabel: 'Limburg (BE)',
                        alternativeLabels: ['Limbourg'],

                        related: [],
                        children: [
                          {
                            id: '1DDB-BE-FCA',
                            label: 'Hasselt',
                            originalLabel: 'Hasselt',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDB-BE-FD',
                        label: 'Øst-Flandern',
                        originalLabel: 'Øst-Flandern',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDB-BE-FDA',
                            label: 'Gent',
                            originalLabel: 'Gent',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDB-BE-FE',
                        label: 'Vest-Flandern',
                        originalLabel: 'Vest-Flandern',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDB-BE-FEA',
                            label: 'Brugge',
                            originalLabel: 'Brugge',
                            alternativeLabels: ['Brügge'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DDB-BE-W',
                    label: 'Vallonia',
                    originalLabel: 'Vallonia',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDB-BE-WA',
                        label: 'Vallonsk Brabant',
                        originalLabel: 'Vallonsk Brabant',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDB-BE-WAA',
                            label: 'Wavre',
                            originalLabel: 'Wavre',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDB-BE-WB',
                        label: 'Hainaut',
                        originalLabel: 'Hainaut',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDB-BE-WBA',
                            label: 'Mons',
                            originalLabel: 'Mons',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDB-BE-WBB',
                            label: 'Charleroi',
                            originalLabel: 'Charleroi',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDB-BE-WC',
                        label: 'Liège (provins)',
                        originalLabel: 'Liège (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDB-BE-WCA',
                            label: 'Liège',
                            originalLabel: 'Liège',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDB-BE-WD',
                        label: 'Luxembourg (belgisk provins)',
                        originalLabel: 'Luxembourg (belgisk provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDB-BE-WDA',
                            label: 'Arlon',
                            originalLabel: 'Arlon',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDB-BE-WE',
                        label: 'Namur (provins)',
                        originalLabel: 'Namur (provins)',
                        alternativeLabels: ['Namen'],

                        related: [],
                        children: [
                          {
                            id: '1DDB-BE-WEA',
                            label: 'Namur',
                            originalLabel: 'Namur',
                            alternativeLabels: ['Namen'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DDF',
                label: 'Frankrike',
                originalLabel: 'Frankrike',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DDF-FR-X',
                    label: 'Franske regioner',
                    originalLabel: 'Franske regioner',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDF-FR-F',
                        label: 'Bretagne',
                        originalLabel: 'Bretagne',
                        alternativeLabels: [],
                        note: 'Brukes i historisk, kulturell og administrativ sammenheng',
                        related: [],
                        children: [
                          {
                            id: '1DDF-FR-FA',
                            label: 'Côtes-d’Armor',
                            originalLabel: 'Côtes-d’Armor',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-FAA',
                                label: 'Saint-Brieuc',
                                originalLabel: 'Saint-Brieuc',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-FAB',
                                label: 'Dinan',
                                originalLabel: 'Dinan',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-FAC',
                                label: 'Guingamp',
                                originalLabel: 'Guingamp',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-FAD',
                                label: 'Lannion',
                                originalLabel: 'Lannion',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-FB',
                            label: 'Finistère',
                            originalLabel: 'Finistère',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-FBA',
                                label: 'Quimper',
                                originalLabel: 'Quimper',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-FBB',
                                label: 'Brest',
                                originalLabel: 'Brest',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-FBC',
                                label: 'Châteaulin',
                                originalLabel: 'Châteaulin',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-FBD',
                                label: 'Morlaix',
                                originalLabel: 'Morlaix',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-FC',
                            label: 'Ille-et-Vilaine',
                            originalLabel: 'Ille-et-Vilaine',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-FCA',
                                label: 'Rennes',
                                originalLabel: 'Rennes',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-FCB',
                                label: 'Fougères',
                                originalLabel: 'Fougères',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-FCC',
                                label: 'Redon',
                                originalLabel: 'Redon',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-FCD',
                                label: 'Saint-Malo',
                                originalLabel: 'Saint-Malo',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-FD',
                            label: 'Morbihan',
                            originalLabel: 'Morbihan',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-FDA',
                                label: 'Vannes',
                                originalLabel: 'Vannes',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-FDB',
                                label: 'Lorient',
                                originalLabel: 'Lorient',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-FDC',
                                label: 'Pontivy',
                                originalLabel: 'Pontivy',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-FDD',
                                label: 'Carnac',
                                originalLabel: 'Carnac',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-FZ',
                            label: 'Bretagne: reisemål',
                            originalLabel: 'Bretagne: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-FZC',
                                label: 'Cornouaille',
                                originalLabel: 'Cornouaille',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDF-FR-G',
                        label: 'Centre-Val de Loire',
                        originalLabel: 'Centre-Val de Loire',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDF-FR-GA',
                            label: 'Cher',
                            originalLabel: 'Cher',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-GAA',
                                label: 'Saint-Amand-Montrond',
                                originalLabel: 'Saint-Amand-Montrond',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GAB',
                                label: 'Bourges',
                                originalLabel: 'Bourges',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GAC',
                                label: 'Vierzon',
                                originalLabel: 'Vierzon',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GAD',
                                label: 'Sancerre',
                                originalLabel: 'Sancerre',
                                alternativeLabels: [],
                                note: 'Her: Sancerrois',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-GB',
                            label: 'Eure-et-Loir',
                            originalLabel: 'Eure-et-Loir',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-GBA',
                                label: 'Chartres',
                                originalLabel: 'Chartres',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GBB',
                                label: 'Châteaudun',
                                originalLabel: 'Châteaudun',
                                alternativeLabels: [],
                                note: 'Her: Dunois',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GBC',
                                label: 'Dreux',
                                originalLabel: 'Dreux',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GBD',
                                label: 'Nogent-le-Rotrou',
                                originalLabel: 'Nogent-le-Rotrou',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-GC',
                            label: 'Indre',
                            originalLabel: 'Indre',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-GCA',
                                label: 'Châteauroux',
                                originalLabel: 'Châteauroux',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GCB',
                                label: 'Le Blanc',
                                originalLabel: 'Le Blanc',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GCC',
                                label: 'La Châtre',
                                originalLabel: 'La Châtre',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GCD',
                                label: 'Issoudun',
                                originalLabel: 'Issoudun',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-GD',
                            label: 'Indre-et-Loire',
                            originalLabel: 'Indre-et-Loire',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-GDA',
                                label: 'Tours',
                                originalLabel: 'Tours',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GDB',
                                label: 'Chinon',
                                originalLabel: 'Chinon',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GDC',
                                label: 'Loches',
                                originalLabel: 'Loches',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-GE',
                            label: 'Loir-et-Cher',
                            originalLabel: 'Loir-et-Cher',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-GEA',
                                label: 'Blois',
                                originalLabel: 'Blois',
                                alternativeLabels: [],
                                note: 'Her: Blesois',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GEB',
                                label: 'Romorantin-Lanthenay',
                                originalLabel: 'Romorantin-Lanthenay',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GEC',
                                label: 'Vendôme',
                                originalLabel: 'Vendôme',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-GF',
                            label: 'Loiret',
                            originalLabel: 'Loiret',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-GFA',
                                label: 'Orléans',
                                originalLabel: 'Orléans',
                                alternativeLabels: [],
                                note: 'Her: Orléanais',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GFB',
                                label: 'Montargis',
                                originalLabel: 'Montargis',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GFC',
                                label: 'Pithiviers',
                                originalLabel: 'Pithiviers',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-GZ',
                            label: 'Centre-Val de Loire: reisemål',
                            originalLabel: 'Centre-Val de Loire: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-GZB',
                                label: 'Berry',
                                originalLabel: 'Berry',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GZS',
                                label: 'Sologne',
                                originalLabel: 'Sologne',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-GZT',
                                label: 'Touraine',
                                originalLabel: 'Touraine',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDF-FR-I',
                        label: 'Korsika',
                        originalLabel: 'Korsika',
                        alternativeLabels: [],
                        note: 'Brukes i historisk, kulturell og administrativ sammenheng',
                        related: [],
                        children: [
                          {
                            id: '1DDF-FR-IA',
                            label: 'Corse-du-Sud',
                            originalLabel: 'Corse-du-Sud',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-IAA',
                                label: 'Ajaccio',
                                originalLabel: 'Ajaccio',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-IAB',
                                label: 'Sartène',
                                originalLabel: 'Sartène',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-IB',
                            label: 'Haute-Corse',
                            originalLabel: 'Haute-Corse',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-IBA',
                                label: 'Bastia',
                                originalLabel: 'Bastia',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-IBB',
                                label: 'Calvi',
                                originalLabel: 'Calvi',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-IBC',
                                label: 'Corte',
                                originalLabel: 'Corte',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDF-FR-L',
                        label: 'Île-de-France',
                        originalLabel: 'Île-de-France',
                        alternativeLabels: [],
                        note: 'Brukes i historisk, kulturell og administrativ sammenheng',
                        related: [],
                        children: [
                          {
                            id: '1DDF-FR-LA',
                            label: 'Paris (region)',
                            originalLabel: 'Paris (region)',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-LAA',
                                label: 'Paris (by)',
                                originalLabel: 'Paris (by)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-LB',
                            label: 'Seine-et-Marne',
                            originalLabel: 'Seine-et-Marne',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-LBA',
                                label: 'Melun',
                                originalLabel: 'Melun',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LBB',
                                label: 'Fontainebleau',
                                originalLabel: 'Fontainebleau',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LBC',
                                label: 'Meaux',
                                originalLabel: 'Meaux',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LBD',
                                label: 'Provins',
                                originalLabel: 'Provins',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LBE',
                                label: 'Torcy',
                                originalLabel: 'Torcy',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-LC',
                            label: 'Yvelines',
                            originalLabel: 'Yvelines',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-LCA',
                                label: 'Versailles',
                                originalLabel: 'Versailles',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LCB',
                                label: 'Mantes-la-Jolie',
                                originalLabel: 'Mantes-la-Jolie',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LCC',
                                label: 'Rambouillet',
                                originalLabel: 'Rambouillet',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LCD',
                                label: 'Saint-Germain-en-Laye',
                                originalLabel: 'Saint-Germain-en-Laye',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-LD',
                            label: 'Essonne',
                            originalLabel: 'Essonne',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-LDA',
                                label: 'Évry',
                                originalLabel: 'Évry',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LDB',
                                label: 'Étampes',
                                originalLabel: 'Étampes',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LDC',
                                label: 'Palaiseau',
                                originalLabel: 'Palaiseau',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-LE',
                            label: 'Hauts-de-Seine',
                            originalLabel: 'Hauts-de-Seine',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-LEA',
                                label: 'Nanterre',
                                originalLabel: 'Nanterre',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LEB',
                                label: 'Antony',
                                originalLabel: 'Antony',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LEC',
                                label: 'Boulogne-Billancourt',
                                originalLabel: 'Boulogne-Billancourt',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-LF',
                            label: 'Seine-Saint-Denis',
                            originalLabel: 'Seine-Saint-Denis',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-LFA',
                                label: 'Bobigny',
                                originalLabel: 'Bobigny',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LFB',
                                label: 'Le Raincy',
                                originalLabel: 'Le Raincy',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LFC',
                                label: 'Saint-Denis',
                                originalLabel: 'Saint-Denis',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-LG',
                            label: 'Val-de-Marne',
                            originalLabel: 'Val-de-Marne',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-LGA',
                                label: 'Créteil',
                                originalLabel: 'Créteil',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LGB',
                                label: 'L’Haÿ-les-Roses',
                                originalLabel: 'L’Haÿ-les-Roses',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LGC',
                                label: 'Nogent-sur-Marne',
                                originalLabel: 'Nogent-sur-Marne',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-LH',
                            label: 'Val-d’Oise',
                            originalLabel: 'Val-d’Oise',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-LHA',
                                label: 'Pontoise',
                                originalLabel: 'Pontoise',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LHB',
                                label: 'Argenteuil',
                                originalLabel: 'Argenteuil',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LHC',
                                label: 'Sarcelles',
                                originalLabel: 'Sarcelles',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-LZ',
                            label: 'Île-de-France: reisemål',
                            originalLabel: 'Île-de-France: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-LZB',
                                label: 'Brie',
                                originalLabel: 'Brie',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-LZV',
                                label: 'Vexin',
                                originalLabel: 'Vexin',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDF-FR-R',
                        label: 'Pays de la Loire',
                        originalLabel: 'Pays de la Loire',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDF-FR-RA',
                            label: 'Loire-Atlantique',
                            originalLabel: 'Loire-Atlantique',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-RAA',
                                label: 'Saint-Nazaire',
                                originalLabel: 'Saint-Nazaire',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-RAB',
                                label: 'Nantes',
                                originalLabel: 'Nantes',
                                alternativeLabels: [],
                                note: 'Her: Pays Nantais',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-RAC',
                                label: 'Châteaubriant',
                                originalLabel: 'Châteaubriant',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-RB',
                            label: 'Maine-et-Loire',
                            originalLabel: 'Maine-et-Loire',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-RBA',
                                label: 'Angers',
                                originalLabel: 'Angers',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-RBB',
                                label: 'Saumur',
                                originalLabel: 'Saumur',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-RBC',
                                label: 'Cholet',
                                originalLabel: 'Cholet',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-RBD',
                                label: 'Segré',
                                originalLabel: 'Segré',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-RC',
                            label: 'Mayenne',
                            originalLabel: 'Mayenne',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-RCA',
                                label: 'Laval',
                                originalLabel: 'Laval',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-RCB',
                                label: 'Château-Gontier',
                                originalLabel: 'Château-Gontier',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-RD',
                            label: 'Sarthe',
                            originalLabel: 'Sarthe',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-RDA',
                                label: 'Le Mans',
                                originalLabel: 'Le Mans',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-RDB',
                                label: 'La Flèche',
                                originalLabel: 'La Flèche',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-RDC',
                                label: 'Mamers',
                                originalLabel: 'Mamers',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-RE',
                            label: 'Vendée',
                            originalLabel: 'Vendée',
                            alternativeLabels: [],
                            note: 'Brukes i historisk, kulturell og administrativ sammenheng',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-REA',
                                label: 'La Roche-sur-Yon',
                                originalLabel: 'La Roche-sur-Yon',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-REB',
                                label: 'Fontenay-le-Comte',
                                originalLabel: 'Fontenay-le-Comte',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-REC',
                                label: 'Les Sables-d’Olonne',
                                originalLabel: 'Les Sables-d’Olonne',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-RZ',
                            label: 'Pays de la Loire: reisemål',
                            originalLabel: 'Pays de la Loire: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-RZA',
                                label: 'Anjou',
                                originalLabel: 'Anjou',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-RZM',
                                label: 'Maine (provins)',
                                originalLabel: 'Maine (provins)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDF-FR-U',
                        label: 'Provence-Alpes-Côte d’Azur',
                        originalLabel: 'Provence-Alpes-Côte d’Azur',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDF-FR-UA',
                            label: 'Alpes-de-Haute-Provence',
                            originalLabel: 'Alpes-de-Haute-Provence',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-UAA',
                                label: 'Digne-les-Bains',
                                originalLabel: 'Digne-les-Bains',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UAB',
                                label: 'Barcelonnette',
                                originalLabel: 'Barcelonnette',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UAC',
                                label: 'Castellane',
                                originalLabel: 'Castellane',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UAD',
                                label: 'Forcalquier',
                                originalLabel: 'Forcalquier',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-UB',
                            label: 'Hautes-Alpes',
                            originalLabel: 'Hautes-Alpes',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-UBA',
                                label: 'Gap',
                                originalLabel: 'Gap',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UBB',
                                label: 'Briançon',
                                originalLabel: 'Briançon',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-UC',
                            label: 'Alpes-Maritimes',
                            originalLabel: 'Alpes-Maritimes',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-UCA',
                                label: 'Nice',
                                originalLabel: 'Nice',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UCB',
                                label: 'Grasse',
                                originalLabel: 'Grasse',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UCC',
                                label: 'Cannes',
                                originalLabel: 'Cannes',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-UD',
                            label: 'Bouches-du-Rhône',
                            originalLabel: 'Bouches-du-Rhône',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-UDA',
                                label: 'Marseille',
                                originalLabel: 'Marseille',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UDB',
                                label: 'Aix-en-Provence',
                                originalLabel: 'Aix-en-Provence',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UDC',
                                label: 'Arles',
                                originalLabel: 'Arles',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UDD',
                                label: 'Istres',
                                originalLabel: 'Istres',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-UE',
                            label: 'Var',
                            originalLabel: 'Var',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-UEA',
                                label: 'Toulon',
                                originalLabel: 'Toulon',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UEB',
                                label: 'Brignoles',
                                originalLabel: 'Brignoles',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UEC',
                                label: 'Saint-Tropez',
                                originalLabel: 'Saint-Tropez',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UED',
                                label: 'Draguignan',
                                originalLabel: 'Draguignan',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-UF',
                            label: 'Vaucluse',
                            originalLabel: 'Vaucluse',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-UFA',
                                label: 'Avignon',
                                originalLabel: 'Avignon',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UFB',
                                label: 'Apt',
                                originalLabel: 'Apt',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UFC',
                                label: 'Carpentras',
                                originalLabel: 'Carpentras',
                                alternativeLabels: [],
                                note: 'Her: Comtat Venaissin',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UFD',
                                label: 'Orange',
                                originalLabel: 'Orange',
                                alternativeLabels: [],
                                note: 'Her: fyrstedømmet Orange. Brukes i historisk, kulturell og administrativ sammenheng',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-UZ',
                            label: 'Provence-Alpes-Côte d’Azur: reisemål',
                            originalLabel:
                              'Provence-Alpes-Côte d’Azur: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-UZC',
                                label: 'Den franske riviera / Côte d’Azur',
                                originalLabel:
                                  'Den franske riviera / Côte d’Azur',
                                alternativeLabels: ['Den franske rivieraen'],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UZD',
                                label: 'Luberon',
                                originalLabel: 'Luberon',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UZF',
                                label: 'Mercantour',
                                originalLabel: 'Mercantour',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UZG',
                                label: 'Camargue',
                                originalLabel: 'Camargue',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-UZP',
                                label: 'Provence',
                                originalLabel: 'Provence',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDF-FR-XA',
                        label: 'Auvergne-Rhône-Alpes',
                        originalLabel: 'Auvergne-Rhône-Alpes',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDF-FR-C',
                            label: 'Auvergne',
                            originalLabel: 'Auvergne',
                            alternativeLabels: [],
                            note: 'Brukes i historisk og kulturell sammenheng, samt administrativ',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-CA',
                                label: 'Allier',
                                originalLabel: 'Allier',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-CAA',
                                    label: 'Moulins',
                                    originalLabel: 'Moulins',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-CAB',
                                    label: 'Montluçon',
                                    originalLabel: 'Montluçon',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-CAC',
                                    label: 'Vichy',
                                    originalLabel: 'Vichy',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-CB',
                                label: 'Cantal',
                                originalLabel: 'Cantal',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-CBA',
                                    label: 'Aurillac',
                                    originalLabel: 'Aurillac',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-CBB',
                                    label: 'Mauriac',
                                    originalLabel: 'Mauriac',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-CBC',
                                    label: 'Saint-Flour',
                                    originalLabel: 'Saint-Flour',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-CC',
                                label: 'Haute-Loire',
                                originalLabel: 'Haute-Loire',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-CCA',
                                    label: 'Le Puy-en-Velay',
                                    originalLabel: 'Le Puy-en-Velay',
                                    alternativeLabels: [],
                                    note: 'Her: Velay',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-CCB',
                                    label: 'Brioude',
                                    originalLabel: 'Brioude',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-CCC',
                                    label: 'Yssingeaux',
                                    originalLabel: 'Yssingeaux',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-CD',
                                label: 'Puy-de-Dôme',
                                originalLabel: 'Puy-de-Dôme',
                                alternativeLabels: [],
                                note: 'Her: Chaîne des Puys',
                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-CDA',
                                    label: 'Clermont-Ferrand',
                                    originalLabel: 'Clermont-Ferrand',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-CDB',
                                    label: 'Ambert',
                                    originalLabel: 'Ambert',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-CDC',
                                    label: 'Issoire',
                                    originalLabel: 'Issoire',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-CDD',
                                    label: 'Riom',
                                    originalLabel: 'Riom',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-CDE',
                                    label: 'Thiers',
                                    originalLabel: 'Thiers',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-V',
                            label: 'Rhône-Alpes',
                            originalLabel: 'Rhône-Alpes',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-VA',
                                label: 'Ain',
                                originalLabel: 'Ain',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-VAA',
                                    label: 'Bourg-en-Bresse',
                                    originalLabel: 'Bourg-en-Bresse',
                                    alternativeLabels: [],
                                    note: 'Her: Bresse',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VAB',
                                    label: 'Belley',
                                    originalLabel: 'Belley',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VAC',
                                    label: 'Gex',
                                    originalLabel: 'Gex',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VAD',
                                    label: 'Nantua',
                                    originalLabel: 'Nantua',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-VB',
                                label: 'Ardèche',
                                originalLabel: 'Ardèche',
                                alternativeLabels: [],
                                note: 'Her: Vivarais',
                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-VBA',
                                    label: 'Privas',
                                    originalLabel: 'Privas',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VBB',
                                    label: 'Largentière',
                                    originalLabel: 'Largentière',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VBC',
                                    label: 'Tournon-sur-Rhône',
                                    originalLabel: 'Tournon-sur-Rhône',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-VC',
                                label: 'Drôme',
                                originalLabel: 'Drôme',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-VCA',
                                    label: 'Valence',
                                    originalLabel: 'Valence',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VCB',
                                    label: 'Die',
                                    originalLabel: 'Die',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VCC',
                                    label: 'Nyons',
                                    originalLabel: 'Nyons',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-VD',
                                label: 'Isère',
                                originalLabel: 'Isère',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-VDA',
                                    label: 'Grenoble',
                                    originalLabel: 'Grenoble',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VDB',
                                    label: 'La Tour-du-Pin',
                                    originalLabel: 'La Tour-du-Pin',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VDC',
                                    label: 'Vienne (Isère)',
                                    originalLabel: 'Vienne (Isère)',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-VE',
                                label: 'Loire',
                                originalLabel: 'Loire',
                                alternativeLabels: [],
                                note: 'Se også 1DDF-FR-ZL <a href="/thema/nb/1DDF-FR-ZL">Loire og Loiredalen</a>',
                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-VEA',
                                    label: 'Saint-Étienne',
                                    originalLabel: 'Saint-Étienne',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VEB',
                                    label: 'Montbrison',
                                    originalLabel: 'Montbrison',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VEC',
                                    label: 'Roanne',
                                    originalLabel: 'Roanne',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-VF',
                                label: 'Rhône',
                                originalLabel: 'Rhône',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-VFA',
                                    label: 'Lyon',
                                    originalLabel: 'Lyon',
                                    alternativeLabels: [],
                                    note: 'Her: Lyonnais',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VFB',
                                    label: 'Villefranche-sur-Saône',
                                    originalLabel: 'Villefranche-sur-Saône',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-VG',
                                label: 'Savoy',
                                originalLabel: 'Savoy',
                                alternativeLabels: [],
                                note: 'Brukes i historisk og kulturell sammenheng, samt administrativ',
                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-VGA',
                                    label: 'Chambéry',
                                    originalLabel: 'Chambéry',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VGB',
                                    label: 'Albertville',
                                    originalLabel: 'Albertville',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VGC',
                                    label: 'Saint-Jean-de-Maurienne',
                                    originalLabel: 'Saint-Jean-de-Maurienne',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-VH',
                                label: 'Haute-Savoie',
                                originalLabel: 'Haute-Savoie',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-VHA',
                                    label: 'Annecy',
                                    originalLabel: 'Annecy',
                                    alternativeLabels: [],
                                    note: 'Her: Annecysjøen',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VHB',
                                    label: 'Bonneville',
                                    originalLabel: 'Bonneville',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VHC',
                                    label: 'Chamonix',
                                    originalLabel: 'Chamonix',
                                    alternativeLabels: [],
                                    note: 'Se også 1DZTA-FR-B <a href="/thema/nb/1DZTA-FR-B">Mont Blanc-massivet</a>',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VHD',
                                    label: 'Saint-Julien-en-Genevois',
                                    originalLabel: 'Saint-Julien-en-Genevois',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-VHE',
                                    label: 'Thonon-les-Bains',
                                    originalLabel: 'Thonon-les-Bains',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-XAZ',
                            label: 'Auvergne-Rhône-Alpes: reisemål',
                            originalLabel: 'Auvergne-Rhône-Alpes: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-XAZB',
                                label: 'Bourbonnais',
                                originalLabel: 'Bourbonnais',
                                alternativeLabels: [],
                                note: 'Her: Bourbon',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XAZF',
                                label: 'Forez / Livradois',
                                originalLabel: 'Forez / Livradois',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XAZG',
                                label: 'Beaujolais',
                                originalLabel: 'Beaujolais',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XAZH',
                                label: 'Dauphiné / Dauphiny',
                                originalLabel: 'Dauphiné / Dauphiny',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XAZK',
                                label: 'Chablais',
                                originalLabel: 'Chablais',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDF-FR-XB',
                        label: 'Bourgogne-Franche-Comté',
                        originalLabel: 'Bourgogne-Franche-Comté',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDF-FR-E',
                            label: 'Bourgogne',
                            originalLabel: 'Bourgogne',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-EA',
                                label: 'Côte-d’Or',
                                originalLabel: 'Côte-d’Or',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-EAA',
                                    label: 'Dijon',
                                    originalLabel: 'Dijon',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-EAB',
                                    label: 'Beaune',
                                    originalLabel: 'Beaune',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-EAC',
                                    label: 'Montbard',
                                    originalLabel: 'Montbard',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-EB',
                                label: 'Nièvre',
                                originalLabel: 'Nièvre',
                                alternativeLabels: [],
                                note: 'Her: Nivernais',
                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-EBA',
                                    label: 'Nevers',
                                    originalLabel: 'Nevers',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-EBB',
                                    label: 'Château-Chinon og Morvan',
                                    originalLabel: 'Château-Chinon og Morvan',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-EBC',
                                    label: 'Clamecy',
                                    originalLabel: 'Clamecy',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-EBD',
                                    label: 'Cosne-Cours-sur-Loire',
                                    originalLabel: 'Cosne-Cours-sur-Loire',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-EC',
                                label: 'Saône-et-Loire',
                                originalLabel: 'Saône-et-Loire',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-ECA',
                                    label: 'Mâcon og Mâconnais',
                                    originalLabel: 'Mâcon og Mâconnais',
                                    alternativeLabels: [],
                                    note: 'Her: Cluny',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-ECB',
                                    label: 'Autun',
                                    originalLabel: 'Autun',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-ECC',
                                    label: 'Chalon-sur-Saône og Chalonnais',
                                    originalLabel:
                                      'Chalon-sur-Saône og Chalonnais',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-ECD',
                                    label: 'Charolles og Charolais',
                                    originalLabel: 'Charolles og Charolais',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-ECE',
                                    label: 'Louhans',
                                    originalLabel: 'Louhans',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-ED',
                                label: 'Yonne',
                                originalLabel: 'Yonne',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-EDA',
                                    label: 'Auxerre',
                                    originalLabel: 'Auxerre',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-EDB',
                                    label: 'Avallon',
                                    originalLabel: 'Avallon',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-J',
                            label: 'Franche-Comté',
                            originalLabel: 'Franche-Comté',
                            alternativeLabels: [],
                            note: 'Brukes i historisk, kulturell og administrativ sammenheng',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-JA',
                                label: 'Doubs',
                                originalLabel: 'Doubs',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-JAA',
                                    label: 'Montbéliard',
                                    originalLabel: 'Montbéliard',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-JAB',
                                    label: 'Besançon',
                                    originalLabel: 'Besançon',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-JAC',
                                    label: 'Pontarlier',
                                    originalLabel: 'Pontarlier',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-JB',
                                label: 'Jura (39)',
                                originalLabel: 'Jura (39)',
                                alternativeLabels: [],
                                note: 'Se også 1DZT-FR-J <a href="/thema/nb/1DZT-FR-J">Jurafjellene</a>',
                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-JBA',
                                    label: 'Lons-le-Saunier',
                                    originalLabel: 'Lons-le-Saunier',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-JBB',
                                    label: 'Dole',
                                    originalLabel: 'Dole',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-JBC',
                                    label: 'Saint-Claude',
                                    originalLabel: 'Saint-Claude',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-JC',
                                label: 'Haute-Saône',
                                originalLabel: 'Haute-Saône',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-JCA',
                                    label: 'Vesoul',
                                    originalLabel: 'Vesoul',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-JCB',
                                    label: 'Lure',
                                    originalLabel: 'Lure',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-JD',
                                label: 'Territoire de Belfort',
                                originalLabel: 'Territoire de Belfort',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-JDA',
                                    label: 'Belfort',
                                    originalLabel: 'Belfort',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDF-FR-XC',
                        label: 'Occitanie',
                        originalLabel: 'Occitanie',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDF-FR-M',
                            label: 'Languedoc-Roussillon',
                            originalLabel: 'Languedoc-Roussillon',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-MA',
                                label: 'Aude',
                                originalLabel: 'Aude',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-MAA',
                                    label: 'Carcassonne',
                                    originalLabel: 'Carcassonne',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-MAB',
                                    label: 'Limoux',
                                    originalLabel: 'Limoux',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-MAC',
                                    label: 'Narbonne',
                                    originalLabel: 'Narbonne',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-MAD',
                                    label: 'Rennes-le-Château',
                                    originalLabel: 'Rennes-le-Château',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-MB',
                                label: 'Gard',
                                originalLabel: 'Gard',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-MBA',
                                    label: 'Nîmes',
                                    originalLabel: 'Nîmes',
                                    alternativeLabels: [],
                                    note: 'Her: Pont du Gard',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-MBB',
                                    label: 'Alès',
                                    originalLabel: 'Alès',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-MBC',
                                    label: 'Le Vigan',
                                    originalLabel: 'Le Vigan',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-MC',
                                label: 'Hérault',
                                originalLabel: 'Hérault',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-MCA',
                                    label: 'Montpellier',
                                    originalLabel: 'Montpellier',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-MCB',
                                    label: 'Béziers',
                                    originalLabel: 'Béziers',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-MCC',
                                    label: 'Lodève',
                                    originalLabel: 'Lodève',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-MD',
                                label: 'Lozère',
                                originalLabel: 'Lozère',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-MDA',
                                    label: 'Mende',
                                    originalLabel: 'Mende',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-MDB',
                                    label: 'Florac Trois Rivières',
                                    originalLabel: 'Florac Trois Rivières',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-ME',
                                label: 'Pyrénées-Orientales',
                                originalLabel: 'Pyrénées-Orientales',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-MEA',
                                    label: 'Perpignan',
                                    originalLabel: 'Perpignan',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-MEB',
                                    label: 'Céret',
                                    originalLabel: 'Céret',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-MEC',
                                    label: 'Prades',
                                    originalLabel: 'Prades',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-P',
                            label: 'Midi-Pyrénées',
                            originalLabel: 'Midi-Pyrénées',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-PA',
                                label: 'Ariège',
                                originalLabel: 'Ariège',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-PAA',
                                    label: 'Foix',
                                    originalLabel: 'Foix',
                                    alternativeLabels: [],
                                    note: 'Her: Grevskapet Foix',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-PAB',
                                    label: 'Pamiers',
                                    originalLabel: 'Pamiers',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-PAC',
                                    label: 'Saint-Girons',
                                    originalLabel: 'Saint-Girons',
                                    alternativeLabels: [],
                                    note: 'Her: Couserans',
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-PB',
                                label: 'Aveyron',
                                originalLabel: 'Aveyron',
                                alternativeLabels: [],
                                note: 'Her: Rouergue',
                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-PBA',
                                    label: 'Rodez',
                                    originalLabel: 'Rodez',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-PBB',
                                    label: 'Millau',
                                    originalLabel: 'Millau',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-PBC',
                                    label: 'Villefranche-de-Rouergue',
                                    originalLabel: 'Villefranche-de-Rouergue',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-PC',
                                label: 'Haute-Garonne',
                                originalLabel: 'Haute-Garonne',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-PCA',
                                    label: 'Toulouse',
                                    originalLabel: 'Toulouse',
                                    alternativeLabels: [],
                                    note: 'Her: Toulousain',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-PCB',
                                    label: 'Muret',
                                    originalLabel: 'Muret',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-PCC',
                                    label: 'Saint-Gaudens',
                                    originalLabel: 'Saint-Gaudens',
                                    alternativeLabels: [],
                                    note: 'Her: Comminges',
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-PD',
                                label: 'Gers',
                                originalLabel: 'Gers',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-PDA',
                                    label: 'Auch',
                                    originalLabel: 'Auch',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-PDB',
                                    label: 'Condom',
                                    originalLabel: 'Condom',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-PDC',
                                    label: 'Mirande',
                                    originalLabel: 'Mirande',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-PE',
                                label: 'Lot',
                                originalLabel: 'Lot',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-PEA',
                                    label: 'Cahors',
                                    originalLabel: 'Cahors',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-PEB',
                                    label: 'Figeac',
                                    originalLabel: 'Figeac',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-PEC',
                                    label: 'Gourdon',
                                    originalLabel: 'Gourdon',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-PF',
                                label: 'Hautes-Pyrénées',
                                originalLabel: 'Hautes-Pyrénées',
                                alternativeLabels: [],
                                note: 'Her: Bigorre, Lavedan, vallées des Gaves',
                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-PFA',
                                    label: 'Tarbes',
                                    originalLabel: 'Tarbes',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-PFB',
                                    label: 'Argelès-Gazost',
                                    originalLabel: 'Argelès-Gazost',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-PFC',
                                    label: 'Bagnères-de-Bigorre',
                                    originalLabel: 'Bagnères-de-Bigorre',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-PFD',
                                    label: 'Lourdes',
                                    originalLabel: 'Lourdes',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-PG',
                                label: 'Tarn',
                                originalLabel: 'Tarn',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-PGA',
                                    label: 'Albi',
                                    originalLabel: 'Albi',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-PGB',
                                    label: 'Castres',
                                    originalLabel: 'Castres',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-PH',
                                label: 'Tarn-et-Garonne',
                                originalLabel: 'Tarn-et-Garonne',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-PHA',
                                    label: 'Montauban',
                                    originalLabel: 'Montauban',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-PHB',
                                    label: 'Castelsarrasin',
                                    originalLabel: 'Castelsarrasin',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-XCZ',
                            label: 'Occitanie: reisemål',
                            originalLabel: 'Occitanie: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-XCZA',
                                label: 'Armagnac',
                                originalLabel: 'Armagnac',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XCZB',
                                label: 'Aubrac',
                                originalLabel: 'Aubrac',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XCZC',
                                label: 'Cévennes',
                                originalLabel: 'Cévennes',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XCZG',
                                label: 'Gévaudan',
                                originalLabel: 'Gévaudan',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XCZL',
                                label: 'Languedoc',
                                originalLabel: 'Languedoc',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XCZM',
                                label: 'Canal du Midi',
                                originalLabel: 'Canal du Midi',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XCZN',
                                label: 'Causses',
                                originalLabel: 'Causses',
                                alternativeLabels: [],
                                note: 'Her: Grandes Causses',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XCZQ',
                                label: 'Quercy',
                                originalLabel: 'Quercy',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XCZR',
                                label: 'Roussillion',
                                originalLabel: 'Roussillion',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDF-FR-XE',
                        label: 'Grand-Est',
                        originalLabel: 'Grand-Est',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDF-FR-A',
                            label: 'Alsace',
                            originalLabel: 'Alsace',
                            alternativeLabels: ['Elsass'],
                            note: 'Brukes i historisk, kulturell og administrativ sammenheng',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-AA',
                                label: 'Bas-Rhin',
                                originalLabel: 'Bas-Rhin',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-AAA',
                                    label: 'Strasbourg',
                                    originalLabel: 'Strasbourg',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-AAB',
                                    label: 'Haguenau',
                                    originalLabel: 'Haguenau',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-AAC',
                                    label: 'Molsheim',
                                    originalLabel: 'Molsheim',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-AAD',
                                    label: 'Saverne',
                                    originalLabel: 'Saverne',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-AAE',
                                    label: 'Sélestat',
                                    originalLabel: 'Sélestat',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-AB',
                                label: 'Haut-Rhin',
                                originalLabel: 'Haut-Rhin',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-ABA',
                                    label: 'Colmar',
                                    originalLabel: 'Colmar',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-ABB',
                                    label: 'Altkirch',
                                    originalLabel: 'Altkirch',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-ABC',
                                    label: 'Mulhouse',
                                    originalLabel: 'Mulhouse',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-ABD',
                                    label: 'Thann',
                                    originalLabel: 'Thann',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-H',
                            label: 'Champagne-Ardenne',
                            originalLabel: 'Champagne-Ardenne',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-HA',
                                label: 'Ardennes',
                                originalLabel: 'Ardennes',
                                alternativeLabels: [],
                                note: 'Brukes i historisk, kulturell og administrativ sammenheng',
                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-HAA',
                                    label: 'Sedan',
                                    originalLabel: 'Sedan',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-HAB',
                                    label: 'Charleville-Mézières',
                                    originalLabel: 'Charleville-Mézières',
                                    alternativeLabels: [],
                                    note: 'Her: Givet',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-HAC',
                                    label: 'Rethel',
                                    originalLabel: 'Rethel',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-HAD',
                                    label: 'Vouziers',
                                    originalLabel: 'Vouziers',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-HB',
                                label: 'Aube',
                                originalLabel: 'Aube',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-HBA',
                                    label: 'Troyes',
                                    originalLabel: 'Troyes',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-HBB',
                                    label: 'Bar-sur-Aube',
                                    originalLabel: 'Bar-sur-Aube',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-HBC',
                                    label: 'Nogent-sur-Seine',
                                    originalLabel: 'Nogent-sur-Seine',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-HC',
                                label: 'Marne',
                                originalLabel: 'Marne',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-HCA',
                                    label: 'Châlons-en-Champagne',
                                    originalLabel: 'Châlons-en-Champagne',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-HCB',
                                    label: 'Épernay',
                                    originalLabel: 'Épernay',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-HCC',
                                    label: 'Reims',
                                    originalLabel: 'Reims',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-HCD',
                                    label: 'Sainte-Menehould',
                                    originalLabel: 'Sainte-Menehould',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-HCE',
                                    label: 'Vitry-le-François',
                                    originalLabel: 'Vitry-le-François',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-HD',
                                label: 'Haute-Marne',
                                originalLabel: 'Haute-Marne',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-HDA',
                                    label: 'Chaumont',
                                    originalLabel: 'Chaumont',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-HDB',
                                    label: 'Langres',
                                    originalLabel: 'Langres',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-HDC',
                                    label: 'Saint-Dizier',
                                    originalLabel: 'Saint-Dizier',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-O',
                            label: 'Lorraine',
                            originalLabel: 'Lorraine',
                            alternativeLabels: ['Lothringen'],
                            note: 'Brukes i historisk, kulturell og administrativ sammenheng',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-OA',
                                label: 'Meurthe-et-Moselle',
                                originalLabel: 'Meurthe-et-Moselle',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-OAA',
                                    label: 'Nancy',
                                    originalLabel: 'Nancy',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-OAB',
                                    label: 'Lunéville',
                                    originalLabel: 'Lunéville',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-OAC',
                                    label: 'Toul',
                                    originalLabel: 'Toul',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-OAD',
                                    label: 'Val-de-Briey',
                                    originalLabel: 'Val-de-Briey',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-OB',
                                label: 'Meuse',
                                originalLabel: 'Meuse',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-OBA',
                                    label: 'Bar-le-Duc',
                                    originalLabel: 'Bar-le-Duc',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-OBB',
                                    label: 'Commercy',
                                    originalLabel: 'Commercy',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-OBC',
                                    label: 'Verdun',
                                    originalLabel: 'Verdun',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-OC',
                                label: 'Moselle',
                                originalLabel: 'Moselle',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-OCA',
                                    label: 'Metz',
                                    originalLabel: 'Metz',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-OCB',
                                    label: 'Thionville',
                                    originalLabel: 'Thionville',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-OCC',
                                    label: 'Sarreguemines',
                                    originalLabel: 'Sarreguemines',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-OCD',
                                    label: 'Sarrebourg',
                                    originalLabel: 'Sarrebourg',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-OCE',
                                    label: 'Forbach',
                                    originalLabel: 'Forbach',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-OD',
                                label: 'Vosges',
                                originalLabel: 'Vosges',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-ODA',
                                    label: 'Épinal',
                                    originalLabel: 'Épinal',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-ODB',
                                    label: 'Neufchâteau',
                                    originalLabel: 'Neufchâteau',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-ODC',
                                    label: 'Saint-Dié-des-Vosges',
                                    originalLabel: 'Saint-Dié-des-Vosges',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-XEZ',
                            label: 'Grand-Est: reisemål',
                            originalLabel: 'Grand-Est: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-XEZC',
                                label: 'Champagne',
                                originalLabel: 'Champagne',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XEZV',
                                label: 'Vosges Mountains',
                                originalLabel: 'Vosges Mountains',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDF-FR-XH',
                        label: 'Hauts-de-France',
                        originalLabel: 'Hauts-de-France',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDF-FR-Q',
                            label: 'Nord-Pas-de-Calais',
                            originalLabel: 'Nord-Pas-de-Calais',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-QA',
                                label: 'Nord',
                                originalLabel: 'Nord',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-QAA',
                                    label: 'Lille',
                                    originalLabel: 'Lille',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-QAB',
                                    label: 'Avesnes-sur-Helpe',
                                    originalLabel: 'Avesnes-sur-Helpe',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-QAC',
                                    label: 'Cambrai',
                                    originalLabel: 'Cambrai',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-QAD',
                                    label: 'Douai',
                                    originalLabel: 'Douai',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-QAE',
                                    label: 'Dunkirk',
                                    originalLabel: 'Dunkirk',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-QAF',
                                    label: 'Valenciennes',
                                    originalLabel: 'Valenciennes',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-QB',
                                label: 'Pas-de-Calais',
                                originalLabel: 'Pas-de-Calais',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-QBA',
                                    label: 'Arras',
                                    originalLabel: 'Arras',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-QBB',
                                    label: 'Béthune',
                                    originalLabel: 'Béthune',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-QBC',
                                    label: 'Boulogne',
                                    originalLabel: 'Boulogne',
                                    alternativeLabels: [],
                                    note: 'Her: Boulogne-sur-Mer',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-QBD',
                                    label: 'Calais',
                                    originalLabel: 'Calais',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-QBE',
                                    label: 'Lens',
                                    originalLabel: 'Lens',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-QBF',
                                    label: 'Montreuil',
                                    originalLabel: 'Montreuil',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-QBG',
                                    label: 'Saint-Omer',
                                    originalLabel: 'Saint-Omer',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-S',
                            label: 'Picardy',
                            originalLabel: 'Picardy',
                            alternativeLabels: [],
                            note: 'Brukes i historisk, kulturell og administrativ sammenheng',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-SA',
                                label: 'Aisne',
                                originalLabel: 'Aisne',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-SAA',
                                    label: 'Laon',
                                    originalLabel: 'Laon',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-SAB',
                                    label: 'Château-Thierry',
                                    originalLabel: 'Château-Thierry',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-SAC',
                                    label: 'Saint-Quentin',
                                    originalLabel: 'Saint-Quentin',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-SAD',
                                    label: 'Soissons',
                                    originalLabel: 'Soissons',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-SAE',
                                    label: 'Vervins',
                                    originalLabel: 'Vervins',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-SB',
                                label: 'Oise',
                                originalLabel: 'Oise',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-SBA',
                                    label: 'Beauvais',
                                    originalLabel: 'Beauvais',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-SBB',
                                    label: 'Clermont',
                                    originalLabel: 'Clermont',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-SBC',
                                    label: 'Compiègne',
                                    originalLabel: 'Compiègne',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-SBD',
                                    label: 'Senlis',
                                    originalLabel: 'Senlis',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-SC',
                                label: 'Somme',
                                originalLabel: 'Somme',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-SCA',
                                    label: 'Amiens',
                                    originalLabel: 'Amiens',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-SCB',
                                    label: 'Abbeville',
                                    originalLabel: 'Abbeville',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-SCC',
                                    label: 'Montdidier',
                                    originalLabel: 'Montdidier',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-SCD',
                                    label: 'Péronne',
                                    originalLabel: 'Péronne',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-XHZ',
                            label: 'Hauts-de-France: reisemål',
                            originalLabel: 'Hauts-de-France: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-XHZA',
                                label: 'Artois',
                                originalLabel: 'Artois',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDF-FR-XN',
                        label: 'Normandie',
                        originalLabel: 'Normandie',
                        alternativeLabels: [],
                        note: 'Brukes i historisk, kulturell og administrativ sammenheng',
                        related: [],
                        children: [
                          {
                            id: '1DDF-FR-D',
                            label: 'Basse-Normandie',
                            originalLabel: 'Basse-Normandie',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-DA',
                                label: 'Calvados',
                                originalLabel: 'Calvados',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-DAA',
                                    label: 'Caen',
                                    originalLabel: 'Caen',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-DAB',
                                    label: 'Bayeux',
                                    originalLabel: 'Bayeux',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-DAC',
                                    label: 'Lisieux',
                                    originalLabel: 'Lisieux',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-DAD',
                                    label: 'Vire-Normandie',
                                    originalLabel: 'Vire-Normandie',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-DB',
                                label: 'Manche',
                                originalLabel: 'Manche',
                                alternativeLabels: [],
                                note: 'Her: Contentin / Cherbourghalvøya',
                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-DBA',
                                    label: 'Saint-Lô',
                                    originalLabel: 'Saint-Lô',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-DBB',
                                    label: 'Avranches',
                                    originalLabel: 'Avranches',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-DBC',
                                    label: 'Cherbourg-en-Cotentin',
                                    originalLabel: 'Cherbourg-en-Cotentin',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-DBD',
                                    label: 'Coutances',
                                    originalLabel: 'Coutances',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-DBE',
                                    label: 'Mont Saint-Michel',
                                    originalLabel: 'Mont Saint-Michel',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-DC',
                                label: 'Orne',
                                originalLabel: 'Orne',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-DCA',
                                    label: 'Alençon',
                                    originalLabel: 'Alençon',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-DCB',
                                    label: 'Argentan',
                                    originalLabel: 'Argentan',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-DCC',
                                    label: 'Mortagne-au-Perche',
                                    originalLabel: 'Mortagne-au-Perche',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-K',
                            label: 'Haute-Normandie',
                            originalLabel: 'Haute-Normandie',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-KA',
                                label: 'Eure',
                                originalLabel: 'Eure',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-KAA',
                                    label: 'Évreux',
                                    originalLabel: 'Évreux',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-KAB',
                                    label: 'Les Andelys',
                                    originalLabel: 'Les Andelys',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-KAC',
                                    label: 'Bernay',
                                    originalLabel: 'Bernay',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-KB',
                                label: 'Seine-Maritime',
                                originalLabel: 'Seine-Maritime',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-KBA',
                                    label: 'Rouen',
                                    originalLabel: 'Rouen',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-KBB',
                                    label: 'Dieppe',
                                    originalLabel: 'Dieppe',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-KBC',
                                    label: 'Le Havre',
                                    originalLabel: 'Le Havre',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-XNZ',
                            label: 'Normandie: reisemål',
                            originalLabel: 'Normandie: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-XNZB',
                                label: 'Normandie: strender',
                                originalLabel: 'Normandie: strender',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XNZD',
                                label: 'Perche',
                                originalLabel: 'Perche',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XNZF',
                                label: 'Pays d’Auge',
                                originalLabel: 'Pays d’Auge',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XNZH',
                                label: 'Pays de Caux',
                                originalLabel: 'Pays de Caux',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDF-FR-XQ',
                        label: 'Nouvelle-Aquitaine',
                        originalLabel: 'Nouvelle-Aquitaine',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDF-FR-B',
                            label: 'Aquitaine',
                            originalLabel: 'Aquitaine',
                            alternativeLabels: [],
                            note: 'Brukes i historisk, kulturell og administrativ sammenheng',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-BA',
                                label: 'Dordogne',
                                originalLabel: 'Dordogne',
                                alternativeLabels: [],
                                note: 'Se også 1DDF-FR-Z <a href="/thema/nb/1DDF-FR-Z">Frankrike: reisemål</a>',
                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-BAA',
                                    label: 'Périgueux',
                                    originalLabel: 'Périgueux',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-BAB',
                                    label: 'Bergerac',
                                    originalLabel: 'Bergerac',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-BAC',
                                    label: 'Nontron',
                                    originalLabel: 'Nontron',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-BAD',
                                    label: 'Sarlat-la-Canéda',
                                    originalLabel: 'Sarlat-la-Canéda',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-BB',
                                label: 'Gironde',
                                originalLabel: 'Gironde',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-BBA',
                                    label: 'Bordeaux',
                                    originalLabel: 'Bordeaux',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-BBB',
                                    label: 'Arcachon',
                                    originalLabel: 'Arcachon',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-BBC',
                                    label: 'Blaye',
                                    originalLabel: 'Blaye',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-BBD',
                                    label: 'Langon',
                                    originalLabel: 'Langon',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-BBE',
                                    label: 'Lesparre-Médoc',
                                    originalLabel: 'Lesparre-Médoc',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-BBF',
                                    label: 'Libourne',
                                    originalLabel: 'Libourne',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-BBG',
                                    label: 'Saint-Émilion',
                                    originalLabel: 'Saint-Émilion',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-BC',
                                label: 'Landes',
                                originalLabel: 'Landes',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-BCA',
                                    label: 'Mont-de-Marsan',
                                    originalLabel: 'Mont-de-Marsan',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-BCB',
                                    label: 'Dax',
                                    originalLabel: 'Dax',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-BD',
                                label: 'Lot-et-Garonne',
                                originalLabel: 'Lot-et-Garonne',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-BDA',
                                    label: 'Agen',
                                    originalLabel: 'Agen',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-BDB',
                                    label: 'Marmande',
                                    originalLabel: 'Marmande',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-BDC',
                                    label: 'Nérac',
                                    originalLabel: 'Nérac',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-BDD',
                                    label: 'Villeneuve-sur-Lot',
                                    originalLabel: 'Villeneuve-sur-Lot',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-BE',
                                label: 'Pyrénées-Atlantiques',
                                originalLabel: 'Pyrénées-Atlantiques',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-BEA',
                                    label: 'Pau',
                                    originalLabel: 'Pau',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-BEB',
                                    label: 'Bayonne',
                                    originalLabel: 'Bayonne',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-BEC',
                                    label: 'Biarritz',
                                    originalLabel: 'Biarritz',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-BED',
                                    label: 'Oloron-Sainte-Marie',
                                    originalLabel: 'Oloron-Sainte-Marie',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-N',
                            label: 'Limousin',
                            originalLabel: 'Limousin',
                            alternativeLabels: [],
                            note: 'Brukes i historisk, kulturell og administrativ sammenheng',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-NA',
                                label: 'Corrèze',
                                originalLabel: 'Corrèze',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-NAA',
                                    label: 'Tulle',
                                    originalLabel: 'Tulle',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-NAB',
                                    label: 'Brive-la-Gaillarde',
                                    originalLabel: 'Brive-la-Gaillarde',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-NAC',
                                    label: 'Ussel',
                                    originalLabel: 'Ussel',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-NB',
                                label: 'Creuse',
                                originalLabel: 'Creuse',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-NBA',
                                    label: 'Guéret',
                                    originalLabel: 'Guéret',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-NBB',
                                    label: 'Aubusson',
                                    originalLabel: 'Aubusson',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-NC',
                                label: 'Haute-Vienne',
                                originalLabel: 'Haute-Vienne',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-NCA',
                                    label: 'Limoges',
                                    originalLabel: 'Limoges',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-NCB',
                                    label: 'Bellac',
                                    originalLabel: 'Bellac',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-NCC',
                                    label: 'Rochechouart',
                                    originalLabel: 'Rochechouart',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-T',
                            label: 'Poitou-Charentes',
                            originalLabel: 'Poitou-Charentes',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-TA',
                                label: 'Charente',
                                originalLabel: 'Charente',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-TAA',
                                    label: 'Angoulême',
                                    originalLabel: 'Angoulême',
                                    alternativeLabels: [],
                                    note: 'Her: Angoumois',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-TAB',
                                    label: 'Cognac',
                                    originalLabel: 'Cognac',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-TAC',
                                    label: 'Confolens',
                                    originalLabel: 'Confolens',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-TB',
                                label: 'Charente-Maritime',
                                originalLabel: 'Charente-Maritime',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-TBA',
                                    label: 'La Rochelle',
                                    originalLabel: 'La Rochelle',
                                    alternativeLabels: [],
                                    note: 'Her: Aunis',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-TBB',
                                    label: 'Jonzac',
                                    originalLabel: 'Jonzac',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-TBC',
                                    label: 'Rochefort',
                                    originalLabel: 'Rochefort',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-TBD',
                                    label: 'Saint-Jean-d’Angély',
                                    originalLabel: 'Saint-Jean-d’Angély',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-TBE',
                                    label: 'Saintes',
                                    originalLabel: 'Saintes',
                                    alternativeLabels: [],
                                    note: 'Her: Saintonge',
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-TC',
                                label: 'Deux-Sèvres',
                                originalLabel: 'Deux-Sèvres',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-TCA',
                                    label: 'Niort',
                                    originalLabel: 'Niort',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-TCB',
                                    label: 'Bressuire',
                                    originalLabel: 'Bressuire',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-TCC',
                                    label: 'Parthenay',
                                    originalLabel: 'Parthenay',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDF-FR-TD',
                                label: 'Vienne (86)',
                                originalLabel: 'Vienne (86)',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDF-FR-TDA',
                                    label: 'Poitiers',
                                    originalLabel: 'Poitiers',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-TDB',
                                    label: 'Châtellerault',
                                    originalLabel: 'Châtellerault',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DDF-FR-TDC',
                                    label: 'Montmorillon',
                                    originalLabel: 'Montmorillon',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1DDF-FR-XQZ',
                            label: 'Nouvelle-Aquitaine: reisemål',
                            originalLabel: 'Nouvelle-Aquitaine: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDF-FR-XQZB',
                                label: 'Béarn',
                                originalLabel: 'Béarn',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XQZD',
                                label: 'Gascony',
                                originalLabel: 'Gascony',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XQZG',
                                label: 'Guyenne',
                                originalLabel: 'Guyenne',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XQZH',
                                label: 'Nedre Navarra',
                                originalLabel: 'Nedre Navarra',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XQZJ',
                                label: 'Périgord',
                                originalLabel: 'Périgord',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XQZK',
                                label: 'Poitou',
                                originalLabel: 'Poitou',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XQZL',
                                label: 'Lascaux',
                                originalLabel: 'Lascaux',
                                alternativeLabels: [],
                                note: 'Her: forhistoriske steder og grotter i Vézère-dalen',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDF-FR-XQZM',
                                label: 'La Marche (provins)',
                                originalLabel: 'La Marche (provins)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DDF-FR-Z',
                    label: 'Frankrike: reisemål',
                    originalLabel: 'Frankrike: reisemål',
                    alternativeLabels: [],
                    note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                    related: [],
                    children: [
                      {
                        id: '1DDF-FR-ZB',
                        label: 'Seine',
                        originalLabel: 'Seine',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDF-FR-ZD',
                        label: 'Dordogne',
                        originalLabel: 'Dordogne',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDF-FR-ZL',
                        label: 'Loire og Loiredalen',
                        originalLabel: 'Loire og Loiredalen',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDF-FR-ZR',
                        label: 'Rhône',
                        originalLabel: 'Rhône',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDF-FR-ZS',
                        label: 'Sentralmassivet',
                        originalLabel: 'Sentralmassivet',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DDL',
                label: 'Luxembourg',
                originalLabel: 'Luxembourg',
                alternativeLabels: ['Luxemburg'],

                related: [],
                children: [],
              },
              {
                id: '1DDM',
                label: 'Monaco',
                originalLabel: 'Monaco',
                alternativeLabels: [],
                note: 'Class here: Monte Carlo',
                related: [],
                children: [],
              },
              {
                id: '1DDN',
                label: 'Nederland',
                originalLabel: 'Nederland',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DDN-NL-A',
                    label: 'Amsterdam',
                    originalLabel: 'Amsterdam',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DDN-NL-B',
                    label: 'Nord-Brabant',
                    originalLabel: 'Nord-Brabant',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDN-NL-BB',
                        label: 'Breda',
                        originalLabel: 'Breda',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-BE',
                        label: 'Eindhoven',
                        originalLabel: 'Eindhoven',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-BH',
                        label: 's-Hertogenbosch',
                        originalLabel: 's-Hertogenbosch',
                        alternativeLabels: ['Den Bosch'],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-BT',
                        label: 'Tilburg',
                        originalLabel: 'Tilburg',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDN-NL-D',
                    label: 'Drenthe',
                    originalLabel: 'Drenthe',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDN-NL-DA',
                        label: 'Assen',
                        originalLabel: 'Assen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-DE',
                        label: 'Emmen',
                        originalLabel: 'Emmen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDN-NL-F',
                    label: 'Flevoland',
                    originalLabel: 'Flevoland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDN-NL-FA',
                        label: 'Almere',
                        originalLabel: 'Almere',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-FL',
                        label: 'Lelystad',
                        originalLabel: 'Lelystad',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDN-NL-G',
                    label: 'Gelderland',
                    originalLabel: 'Gelderland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDN-NL-GA',
                        label: 'Arnhem',
                        originalLabel: 'Arnhem',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-GD',
                        label: 'Apeldoorn',
                        originalLabel: 'Apeldoorn',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-GE',
                        label: 'Ede',
                        originalLabel: 'Ede',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-GN',
                        label: 'Nijmegen',
                        originalLabel: 'Nijmegen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-GR',
                        label: 'Achterhoek',
                        originalLabel: 'Achterhoek',
                        alternativeLabels: [],
                        note: 'Her: Doesburg, Doetinchem, Winterswijk, Zutphen',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-GS',
                        label: 'Betuwe',
                        originalLabel: 'Betuwe',
                        alternativeLabels: ['Batavia'],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-GV',
                        label: 'Veluwe',
                        originalLabel: 'Veluwe',
                        alternativeLabels: [],
                        note: 'Her: Hoge Veluwe nasjonalpark',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDN-NL-H',
                    label: 'Nord-Holland (provins)',
                    originalLabel: 'Nord-Holland (provins)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDN-NL-HH',
                        label: 'Haarlem',
                        originalLabel: 'Haarlem',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-HM',
                        label: 'Alkmaar',
                        originalLabel: 'Alkmaar',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDN-NL-L',
                    label: 'Limburg (NL)',
                    originalLabel: 'Limburg (NL)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDN-NL-LM',
                        label: 'Maastricht',
                        originalLabel: 'Maastricht',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-LV',
                        label: 'Venlo',
                        originalLabel: 'Venlo',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDN-NL-N',
                    label: 'Groningen (provins)',
                    originalLabel: 'Groningen (provins)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDN-NL-NG',
                        label: 'Groningen',
                        originalLabel: 'Groningen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDN-NL-R',
                    label: 'Friesland',
                    originalLabel: 'Friesland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDN-NL-RL',
                        label: 'Leeuwarden',
                        originalLabel: 'Leeuwarden',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDN-NL-S',
                    label: 'Sør-Holland (provins)',
                    originalLabel: 'Sør-Holland (provins)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDN-NL-SA',
                        label: 'Alphen aan den Rijn',
                        originalLabel: 'Alphen aan den Rijn',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-SC',
                        label: 'Dordrecht',
                        originalLabel: 'Dordrecht',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-SD',
                        label: 'Delft',
                        originalLabel: 'Delft',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-SH',
                        label: 'Haag',
                        originalLabel: 'Haag',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-SL',
                        label: 'Leiden',
                        originalLabel: 'Leiden',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-SR',
                        label: 'Rotterdam',
                        originalLabel: 'Rotterdam',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-SZ',
                        label: 'Zoetermeer',
                        originalLabel: 'Zoetermeer',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDN-NL-U',
                    label: 'Utrecht (provins)',
                    originalLabel: 'Utrecht (provins)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDN-NL-UA',
                        label: 'Amersfoort',
                        originalLabel: 'Amersfoort',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-UU',
                        label: 'Utrecht',
                        originalLabel: 'Utrecht',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-UV',
                        label: 'Utrechtse Heuvelrug',
                        originalLabel: 'Utrechtse Heuvelrug',
                        alternativeLabels: [],
                        note: 'Brukes for nasjonalparken eller området',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDN-NL-V',
                    label: 'Overijssel',
                    originalLabel: 'Overijssel',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDN-NL-VE',
                        label: 'Enschede',
                        originalLabel: 'Enschede',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-VT',
                        label: 'Twente',
                        originalLabel: 'Twente',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-VZ',
                        label: 'Zwolle',
                        originalLabel: 'Zwolle',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDN-NL-X',
                    label: 'Nederland: reisemål',
                    originalLabel: 'Nederland: reisemål',
                    alternativeLabels: [],
                    note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                    related: [],
                    children: [
                      {
                        id: '1DDN-NL-XB',
                        label: 'Groene Hart',
                        originalLabel: 'Groene Hart',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-XD',
                        label: 'IJsselmeer',
                        originalLabel: 'IJsselmeer',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-XF',
                        label: 'Vestfrisiske øyer',
                        originalLabel: 'Vestfrisiske øyer',
                        alternativeLabels: [],
                        note: 'Her: øyer i Vadehavet. Se også 1DFG-DE-VAP <a href="/thema/nb/1DFG-DE-VAP">Østfriesland</a>',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDN-NL-Z',
                    label: 'Zeeland',
                    originalLabel: 'Zeeland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDN-NL-ZG',
                        label: 'Goes',
                        originalLabel: 'Goes',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-ZM',
                        label: 'Middelburg',
                        originalLabel: 'Middelburg',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDN-NL-ZV',
                        label: 'Vlissingen',
                        originalLabel: 'Vlissingen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DDR',
                label: 'Irland',
                originalLabel: 'Irland',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DDR-IE-C',
                    label: 'Connacht',
                    originalLabel: 'Connacht',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDR-IE-CG',
                        label: 'County Galway',
                        originalLabel: 'County Galway',
                        alternativeLabels: ['Inishmore'],
                        note: 'Her: Aranøyene, Inis Mór, Inishmaan',
                        related: [],
                        children: [
                          {
                            id: '1DDR-IE-CGC',
                            label: 'Connemara',
                            originalLabel: 'Connemara',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDR-IE-CGD',
                            label: 'Twelve Bens',
                            originalLabel: 'Twelve Bens',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDR-IE-CGG',
                            label: 'Galway',
                            originalLabel: 'Galway',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDR-IE-CL',
                        label: 'Leitrim',
                        originalLabel: 'Leitrim',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDR-IE-CM',
                        label: 'Mayo',
                        originalLabel: 'Mayo',
                        alternativeLabels: [],
                        note: 'Her: Croagh Patrick, Achill',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDR-IE-CR',
                        label: 'Roscommon',
                        originalLabel: 'Roscommon',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDR-IE-CS',
                        label: 'Sligo',
                        originalLabel: 'Sligo',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDR-IE-L',
                    label: 'Leinster',
                    originalLabel: 'Leinster',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDR-IE-LC',
                        label: 'Carlow',
                        originalLabel: 'Carlow',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDR-IE-LD',
                        label: 'County Dublin',
                        originalLabel: 'County Dublin',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDR-IE-LDD',
                            label: 'Dublin',
                            originalLabel: 'Dublin',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDR-IE-LH',
                        label: 'Louth',
                        originalLabel: 'Louth',
                        alternativeLabels: [],
                        note: 'Here: Cooley-fjellene',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDR-IE-LI',
                        label: 'Wicklow',
                        originalLabel: 'Wicklow',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDR-IE-LIM',
                            label: 'Wicklowfjellene',
                            originalLabel: 'Wicklowfjellene',
                            alternativeLabels: [],
                            note: 'Her: Glendalough',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDR-IE-LK',
                        label: 'Kildare',
                        originalLabel: 'Kildare',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDR-IE-LL',
                        label: 'Laois',
                        originalLabel: 'Laois',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDR-IE-LM',
                        label: 'Meath',
                        originalLabel: 'Meath',
                        alternativeLabels: [],
                        note: 'Her: Kells, Tara-åsen',
                        related: [],
                        children: [
                          {
                            id: '1DDR-IE-LMN',
                            label: 'Newgrange',
                            originalLabel: 'Newgrange',
                            alternativeLabels: [],
                            note: 'Her: Brú na Bóinne, Knowth, Dowth, Boyne',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDR-IE-LN',
                        label: 'Longford',
                        originalLabel: 'Longford',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDR-IE-LO',
                        label: 'Offaly',
                        originalLabel: 'Offaly',
                        alternativeLabels: [],
                        note: 'Her: Clonmacnoise',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDR-IE-LW',
                        label: 'Westmeath',
                        originalLabel: 'Westmeath',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDR-IE-LX',
                        label: 'Wexford',
                        originalLabel: 'Wexford',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDR-IE-LY',
                        label: 'Kilkenny',
                        originalLabel: 'Kilkenny',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDR-IE-M',
                    label: 'Munster',
                    originalLabel: 'Munster',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDR-IE-MC',
                        label: 'County Cork',
                        originalLabel: 'County Cork',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDR-IE-MCC',
                            label: 'Cork',
                            originalLabel: 'Cork',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDR-IE-MK',
                        label: 'Kerry',
                        originalLabel: 'Kerry',
                        alternativeLabels: [],
                        note: 'Her: Skellig Michael, Blasketøyene',
                        related: [],
                        children: [
                          {
                            id: '1DDR-IE-MKD',
                            label: 'Dinglehalvøya',
                            originalLabel: 'Dinglehalvøya',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDR-IE-MKK',
                            label: 'Killarney',
                            originalLabel: 'Killarney',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDR-IE-MKR',
                            label: 'Kerry-runden',
                            originalLabel: 'Kerry-runden',
                            alternativeLabels: ['Ring of Kerry'],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDR-IE-MKS',
                            label: 'Macgillycuddy’s Reeks',
                            originalLabel: 'Macgillycuddy’s Reeks',
                            alternativeLabels: [],
                            note: 'Her: Iveragh, Carrauntoohil',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDR-IE-ML',
                        label: 'Clare',
                        originalLabel: 'Clare',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDR-IE-MLB',
                            label: 'Burren',
                            originalLabel: 'Burren',
                            alternativeLabels: [],
                            note: 'Her: Moher-klippene',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDR-IE-MM',
                        label: 'County Limerick',
                        originalLabel: 'County Limerick',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDR-IE-MML',
                            label: 'Limerick',
                            originalLabel: 'Limerick',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDR-IE-MT',
                        label: 'Tipperary',
                        originalLabel: 'Tipperary',
                        alternativeLabels: [],
                        note: 'Her: Cashel',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDR-IE-MW',
                        label: 'Waterford',
                        originalLabel: 'Waterford',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDR-IE-U',
                    label: 'Ulster',
                    originalLabel: 'Ulster',
                    alternativeLabels: [],
                    note: 'Viser til de ni grevskapene i Ulster: tre i Irland og seks i Storbritannia. Se også 1DDU-GB-N <a href="/thema/nb/1DDU-GB-N">Nord-Irland</a>',
                    related: [],
                    children: [
                      {
                        id: '1DDR-IE-UC',
                        label: 'Cavan',
                        originalLabel: 'Cavan',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDR-IE-UD',
                        label: 'Donegal',
                        originalLabel: 'Donegal',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDR-IE-UM',
                        label: 'Monaghan',
                        originalLabel: 'Monaghan',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDR-IE-Z',
                    label: 'Irland: reisemål',
                    originalLabel: 'Irland: reisemål',
                    alternativeLabels: [],
                    note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                    related: [],
                    children: [
                      {
                        id: '1DDR-IE-ZA',
                        label: 'Irland: atlanterhavskysten',
                        originalLabel: 'Irland: atlanterhavskysten',
                        alternativeLabels: [],
                        note: 'Her: Wild Atlantic way',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDR-IE-ZS',
                        label: 'Shannon',
                        originalLabel: 'Shannon',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DDU',
                label: 'Storbritannia',
                originalLabel: 'Storbritannia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DDU-GB-E',
                    label: 'England',
                    originalLabel: 'England',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDU-GB-EA',
                        label: 'East Anglia',
                        originalLabel: 'East Anglia',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDU-GB-EAC',
                            label: 'Cambridgeshire',
                            originalLabel: 'Cambridgeshire',
                            alternativeLabels: [],
                            note: 'Her: Peterborough, Huntingdon, Ely',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EACD',
                                label: 'Cambridge',
                                originalLabel: 'Cambridge',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-EAN',
                            label: 'Norfolk',
                            originalLabel: 'Norfolk',
                            alternativeLabels: [],
                            note: 'Her: Norwich, Kings Lynn, Yarmouth. Se også 1DDU-GB-EAZN <a href="/thema/nb/1DDU-GB-EAZN">Norfolk Broads</a>',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-EAS',
                            label: 'Suffolk',
                            originalLabel: 'Suffolk',
                            alternativeLabels: [],
                            note: 'Her: Ipswich, Lowestoft, Newmarket, Bury St Edmunds, Felixstowe, Sutton Hoo',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-EAX',
                            label: 'Essex',
                            originalLabel: 'Essex',
                            alternativeLabels: [],
                            note: 'Her: Colchester, Southend, Chelmsford, Epping',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-EAZ',
                            label: 'East Anglia: reisemål',
                            originalLabel: 'East Anglia: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EAZF',
                                label: 'The Fens and the Wash',
                                originalLabel: 'The Fens and the Wash',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EAZN',
                                label: 'Norfolk Broads',
                                originalLabel: 'Norfolk Broads',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDU-GB-EM',
                        label: 'Midlands',
                        originalLabel: 'Midlands',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDU-GB-EMD',
                            label: 'Derbyshire',
                            originalLabel: 'Derbyshire',
                            alternativeLabels: [],
                            note: 'Her: Bakewell, Buxton, Chatsworth, Chesterfield. Se også 1DDU-GB-EMZD <a href="/thema/nb/1DDU-GB-EMZD">Peak District</a>',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EMDD',
                                label: 'Derby',
                                originalLabel: 'Derby',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-EMF',
                            label: 'Herefordshire',
                            originalLabel: 'Herefordshire',
                            alternativeLabels: [],
                            note: 'Her: Hereford, Ross-on-Wye',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-EMH',
                            label: 'Leicestershire',
                            originalLabel: 'Leicestershire',
                            alternativeLabels: [],
                            note: 'Her: Melton Mowbray, Bosworth',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EMHL',
                                label: 'Leicester',
                                originalLabel: 'Leicester',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-EML',
                            label: 'Lincolnshire',
                            originalLabel: 'Lincolnshire',
                            alternativeLabels: [],
                            note: 'Her: South Humberside, Grimsby, Scunthorpe, Skegness, Stamford',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EMLL',
                                label: 'Lincoln (Storbritannia)',
                                originalLabel: 'Lincoln (Storbritannia)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-EMM',
                            label: 'Northamptonshire',
                            originalLabel: 'Northamptonshire',
                            alternativeLabels: [],
                            note: 'Her: Naseby',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-EMN',
                            label: 'Nottinghamshire',
                            originalLabel: 'Nottinghamshire',
                            alternativeLabels: [],
                            note: 'Her: Sherwoodskogen',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EMNN',
                                label: 'Nottingham',
                                originalLabel: 'Nottingham',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-EMP',
                            label: 'Shropshire',
                            originalLabel: 'Shropshire',
                            alternativeLabels: [],
                            note: 'Her: Shrewsbury, Ironbridge, Ludlow, The Wrekin',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-EMR',
                            label: 'Rutland',
                            originalLabel: 'Rutland',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-EMS',
                            label: 'Staffordshire',
                            originalLabel: 'Staffordshire',
                            alternativeLabels: [],
                            note: 'Her: Lichfield',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EMSP',
                                label: 'Stoke-on-Trent og The Potteries',
                                originalLabel:
                                  'Stoke-on-Trent og The Potteries',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-EMT',
                            label: 'Worcestershire',
                            originalLabel: 'Worcestershire',
                            alternativeLabels: [],
                            note: 'Her: Malvern Hills, Evesham',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-EMW',
                            label: 'Warwickshire, West Midlands',
                            originalLabel: 'Warwickshire, West Midlands',
                            alternativeLabels: [],
                            note: 'Her: Rugby',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EMWB',
                                label: 'Birmingham (Storbritannia)',
                                originalLabel: 'Birmingham (Storbritannia)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EMWC',
                                label: 'Coventry',
                                originalLabel: 'Coventry',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EMWS',
                                label: 'Stratford-upon-Avon',
                                originalLabel: 'Stratford-upon-Avon',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EMWW',
                                label: 'Wolverhampton og The Black Country',
                                originalLabel:
                                  'Wolverhampton og The Black Country',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-EMZ',
                            label: 'Midlands: reisemål',
                            originalLabel: 'Midlands: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EMZD',
                                label: 'Peak District',
                                originalLabel: 'Peak District',
                                alternativeLabels: [],
                                note: 'Her: Kinder Scout, Mam Tor, Dark Peak, White Peak, Edale, Castleton',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EMZM',
                                label: 'Welsh Marches',
                                originalLabel: 'Welsh Marches',
                                alternativeLabels: [],
                                note: 'Her: Offa’s Dyke, Wye Valley, Shropshire Hills, Forest of Dean. Brukes med en kode fra 1DDU-GB-W Wales dersom det er hensiktsmessig',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDU-GB-EN',
                        label: 'Nordvest-England',
                        originalLabel: 'Nordvest-England',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDU-GB-ENC',
                            label: 'Cheshire',
                            originalLabel: 'Cheshire',
                            alternativeLabels: [],
                            note: 'Her: Chester, Alderley, Nantwich, Crewe',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-ENL',
                            label: 'Lancashire',
                            originalLabel: 'Lancashire',
                            alternativeLabels: [],
                            note: 'Her: Preston, Lancaster, Blackburn, Burnley, Oldham, Forest of Bowland, Pendle',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-ENLB',
                                label: 'Blackpool',
                                originalLabel: 'Blackpool',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-ENLL',
                                label: 'Liverpool',
                                originalLabel: 'Liverpool',
                                alternativeLabels: [],
                                note: 'Her: Merseyside',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-ENLM',
                                label: 'Manchester',
                                originalLabel: 'Manchester',
                                alternativeLabels: ['Greater Manchester'],
                                note: 'Her: Stor-Manchester',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-ENM',
                            label: 'Cumbria',
                            originalLabel: 'Cumbria',
                            alternativeLabels: [],
                            note: 'Her: Carlisle, Edendalen, Penrith. Se også 1DDU-GB-ENZL <a href="/thema/nb/1DDU-GB-ENZL">Lake District</a>',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-ENZ',
                            label: 'Nordvest-England: reisemål',
                            originalLabel: 'Nordvest-England: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-ENZL',
                                label: 'Lake District',
                                originalLabel: 'Lake District',
                                alternativeLabels: [],
                                note: 'Her: Lakeland Fells, Scafell Pike, Windermere, Wastwater, Helvellyn, Skiddaw, Grasmere, Ullswater, Keswick, Kendal. Se også 1DDU-GB-ENM <a href="/thema/nb/1DDU-GB-ENM">Cumbria</a>',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDU-GB-ES',
                        label: 'Sør- og Sørøst-England',
                        originalLabel: 'Sør- og Sørøst-England',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDU-GB-ESB',
                            label: 'Berkshire',
                            originalLabel: 'Berkshire',
                            alternativeLabels: [],
                            note: 'Her: Eton, Highclere, Greenham',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-ESBR',
                                label: 'Reading (Berkshire)',
                                originalLabel: 'Reading (Berkshire)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-ESBW',
                                label: 'Windsor',
                                originalLabel: 'Windsor',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-ESD',
                            label: 'Bedfordshire',
                            originalLabel: 'Bedfordshire',
                            alternativeLabels: [],
                            note: 'Her: Luton',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-ESF',
                            label: 'Oxfordshire',
                            originalLabel: 'Oxfordshire',
                            alternativeLabels: [],
                            note: 'Her: Uffington, Blenheim, Woodstock',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-ESFX',
                                label: 'Oxford',
                                originalLabel: 'Oxford',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-ESH',
                            label: 'Hampshire',
                            originalLabel: 'Hampshire',
                            alternativeLabels: [],
                            note: 'Her: Winchester, Portsmouth. Se også 1DDU-GB-ESZF <a href="/thema/nb/1DDU-GB-ESZF">New Forest</a>',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-ESHS',
                                label: 'Southampton og Solentsundet',
                                originalLabel: 'Southampton og Solentsundet',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-ESK',
                            label: 'Kent',
                            originalLabel: 'Kent',
                            alternativeLabels: [],
                            note: 'Her: Dover',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-ESKC',
                                label: 'Canterbury',
                                originalLabel: 'Canterbury',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-ESL',
                            label: 'London',
                            originalLabel: 'London',
                            alternativeLabels: [],
                            note: 'Bruk denne for London',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-ESLC',
                                label: 'Central London',
                                originalLabel: 'Central London',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DDU-GB-ESLCW',
                                    label: 'Westminster',
                                    originalLabel: 'Westminster',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DDU-GB-ESLF',
                                label: 'City of London',
                                originalLabel: 'City of London',
                                alternativeLabels: [],
                                note: 'Brukes for det historiske sentrum, samt bank- og finansdistriktet City',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-ESR',
                            label: 'Surrey',
                            originalLabel: 'Surrey',
                            alternativeLabels: [],
                            note: 'Her: Guildford, Woking',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-EST',
                            label: 'Hertfordshire',
                            originalLabel: 'Hertfordshire',
                            alternativeLabels: [],
                            note: 'Her: St Albans',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-ESU',
                            label: 'Buckinghamshire',
                            originalLabel: 'Buckinghamshire',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-ESUB',
                                label: 'Bletchley Park',
                                originalLabel: 'Bletchley Park',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-ESW',
                            label: 'Isle of Wight',
                            originalLabel: 'Isle of Wight',
                            alternativeLabels: [],
                            note: 'Her: Ryde, Cowes',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-ESX',
                            label: 'Sussex',
                            originalLabel: 'Sussex',
                            alternativeLabels: [],
                            note: 'Her: Hastings, Lewes, Chichester, Rye, East Sussex, West Sussex',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-ESXB',
                                label: 'Brighton og Hove',
                                originalLabel: 'Brighton og Hove',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-ESZ',
                            label: 'Sør- og Sørøst-England: reisemål',
                            originalLabel: 'Sør- og Sørøst-England: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-ESZC',
                                label: 'The Chilterns',
                                originalLabel: 'The Chilterns',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-ESZD',
                                label: 'North og South Downs, Weald',
                                originalLabel: 'North og South Downs, Weald',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-ESZF',
                                label: 'New Forest',
                                originalLabel: 'New Forest',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-ESZT',
                                label: 'Themsen',
                                originalLabel: 'Themsen',
                                alternativeLabels: [],
                                note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDU-GB-EW',
                        label: 'Sørvest-England',
                        originalLabel: 'Sørvest-England',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDU-GB-EWC',
                            label: 'Cornwall',
                            originalLabel: 'Cornwall',
                            alternativeLabels: [],
                            note: 'Her: Kernow, Truro, St Ives, Tintagel',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EWCS',
                                label: 'Scillyøyene',
                                originalLabel: 'Scillyøyene',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-EWD',
                            label: 'Devon',
                            originalLabel: 'Devon',
                            alternativeLabels: [],
                            note: 'Her: Exeter, Torquay',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EWDP',
                                label: 'Plymouth',
                                originalLabel: 'Plymouth',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-EWG',
                            label: 'Gloucestershire',
                            originalLabel: 'Gloucestershire',
                            alternativeLabels: [],
                            note: 'Her: Cheltenham, Tewkesbury',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-EWS',
                            label: 'Somerset',
                            originalLabel: 'Somerset',
                            alternativeLabels: [],
                            note: 'Her: Taunton, Wells, the Levels, Mendip Hills, Quantock Hills, Sedgemoor',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EWSB',
                                label: 'Bristol',
                                originalLabel: 'Bristol',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EWSG',
                                label: 'Glastonbury',
                                originalLabel: 'Glastonbury',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EWSH',
                                label: 'Bath',
                                originalLabel: 'Bath',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-EWT',
                            label: 'Dorset',
                            originalLabel: 'Dorset',
                            alternativeLabels: [],
                            note: 'Her: Lyme Regis, Weymouth',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EWTB',
                                label: 'Bournemouth og Poole',
                                originalLabel: 'Bournemouth og Poole',
                                alternativeLabels: [],
                                note: 'Her: Christchurch',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-EWW',
                            label: 'Wiltshire',
                            originalLabel: 'Wiltshire',
                            alternativeLabels: [],
                            note: 'Her: Swindon',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EWWS',
                                label: 'Salisbury',
                                originalLabel: 'Salisbury',
                                alternativeLabels: [],
                                note: 'Her: Old Sarum, Wilton. Se også 1DDU-GB-EWZS <a href="/thema/nb/1DDU-GB-EWZS">Stonehenge</a>',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-EWZ',
                            label: 'Sørvest-England: reisemål',
                            originalLabel: 'Sørvest-England: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EWZC',
                                label: 'Cotswolds',
                                originalLabel: 'Cotswolds',
                                alternativeLabels: [],
                                note: 'Foretrukket kode: brukes istedenfor 1DZT-GB-C',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EWZD',
                                label: 'Jurassic Coast og Purbeck',
                                originalLabel: 'Jurassic Coast og Purbeck',
                                alternativeLabels: [],
                                note: 'Her: Dorset og kysten i East Devon',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EWZM',
                                label: 'Dartmoor, Exmoor og Bodmin Moor',
                                originalLabel:
                                  'Dartmoor, Exmoor og Bodmin Moor',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EWZS',
                                label: 'Stonehenge',
                                originalLabel: 'Stonehenge',
                                alternativeLabels: [],
                                note: 'Her: Avebury, Silbury Hill og andre forhistoriske steder på Salisbury Plain',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EWZW',
                                label: 'Wessex',
                                originalLabel: 'Wessex',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DDU-GB-EY',
                        label: 'Nord- og Nordøst-England',
                        originalLabel: 'Nord- og Nordøst-England',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDU-GB-EYD',
                            label: 'Durham',
                            originalLabel: 'Durham',
                            alternativeLabels: [],
                            note: 'Her: Teesdale. Brukes for: County Durham eller City of Durham',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-EYK',
                            label: 'Yorkshire',
                            originalLabel: 'Yorkshire',
                            alternativeLabels: [],
                            note: 'Her: North Humberside, Harrogate, the Ridings, Scarborough',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EYKH',
                                label: 'Hull',
                                originalLabel: 'Hull',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EYKK',
                                label: 'York',
                                originalLabel: 'York',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EYKL',
                                label: 'Leeds, Bradford',
                                originalLabel: 'Leeds, Bradford',
                                alternativeLabels: [],
                                note: 'Her: West Yorkshire',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EYKM',
                                label: 'Middlesborough og Teesside',
                                originalLabel: 'Middlesborough og Teesside',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EYKS',
                                label: 'Sheffield og Rotherham',
                                originalLabel: 'Sheffield og Rotherham',
                                alternativeLabels: [],
                                note: 'Her: South Yorkshire, Doncaster, Barnsley',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EYKW',
                                label: 'Whitby',
                                originalLabel: 'Whitby',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-EYN',
                            label: 'Northumberland, Tyne og Wear',
                            originalLabel: 'Northumberland, Tyne og Wear',
                            alternativeLabels: [],
                            note: 'Her: Northumbria, Berwick-upon-Tweed, Alnwick, Bamburgh, Hexham',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EYNC',
                                label: 'Newcastle og Gateshead',
                                originalLabel: 'Newcastle og Gateshead',
                                alternativeLabels: [],
                                note: 'Her: North and South Tyneside, Jarrow',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EYNS',
                                label: 'Sunderland og Wearside',
                                originalLabel: 'Sunderland og Wearside',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-EYZ',
                            label: 'Nord- og Nordøst-England: reisemål',
                            originalLabel: 'Nord- og Nordøst-England: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-EYZB',
                                label: 'Penninene',
                                originalLabel: 'Penninene',
                                alternativeLabels: [],
                                note: 'Foretrukket kode: brukes istedenfor 1DZT-GB-P',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EYZF',
                                label: 'North York Moors',
                                originalLabel: 'North York Moors',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EYZH',
                                label: 'Yorkshire Dales',
                                originalLabel: 'Yorkshire Dales',
                                alternativeLabels: [],
                                note: 'Her: Howgills, Wharfedale, Wensleydale, Malham Cove, Nidderdale, Pen-y-ghent, Ingleborough, Whernside',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EYZL',
                                label: 'Farne Islands og Lindisfarne',
                                originalLabel: 'Farne Islands og Lindisfarne',
                                alternativeLabels: ['Farnes'],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EYZN',
                                label: 'Northumberland nasjonalpark',
                                originalLabel: 'Northumberland nasjonalpark',
                                alternativeLabels: [],
                                note: 'Her: The Cheviots, Kirk Yetholm, Kielder Forest og Kielder Water',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-EYZW',
                                label: 'Hadrians mur',
                                originalLabel: 'Hadrians mur',
                                alternativeLabels: [],
                                note: 'Her: Housesteads romerske festning',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DDU-GB-N',
                    label: 'Nord-Irland',
                    originalLabel: 'Nord-Irland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDU-GB-NB',
                        label: 'Belfast',
                        originalLabel: 'Belfast',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDU-GB-NC',
                        label: 'Antrim',
                        originalLabel: 'Antrim',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDU-GB-ND',
                        label: 'Armagh',
                        originalLabel: 'Armagh',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDU-GB-NE',
                        label: 'County Derry / County Londonderry',
                        originalLabel: 'County Derry / County Londonderry',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDU-GB-NEC',
                            label: 'Derry / Londonderry',
                            originalLabel: 'Derry / Londonderry',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDU-GB-NG',
                        label: 'Down',
                        originalLabel: 'Down',
                        alternativeLabels: [],
                        note: 'Her: Strangford Lough, Ardshalvøya',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDU-GB-NJ',
                        label: 'Fermanagh',
                        originalLabel: 'Fermanagh',
                        alternativeLabels: [],
                        note: 'Her: Lough Erne, Enniskillen',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDU-GB-NT',
                        label: 'Tyrone',
                        originalLabel: 'Tyrone',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDU-GB-NZ',
                        label: 'Nord-Irland: reisemål',
                        originalLabel: 'Nord-Irland: reisemål',
                        alternativeLabels: [],
                        note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                        related: [],
                        children: [
                          {
                            id: '1DDU-GB-NZA',
                            label:
                              'Giant’s Causeway, North Antrim Coast og The Glens',
                            originalLabel:
                              'Giant’s Causeway, North Antrim Coast og The Glens',
                            alternativeLabels: [],
                            note: 'Her: Causewaykysten, Rathlin Island',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-NZM',
                            label: 'Mournefjellene',
                            originalLabel: 'Mournefjellene',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-NZS',
                            label: 'Sperrinfjellende',
                            originalLabel: 'Sperrinfjellende',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DDU-GB-S',
                    label: 'Skottland',
                    originalLabel: 'Skottland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DDU-GB-SB',
                        label: 'Det skotske lavlandet og Borders',
                        originalLabel: 'Det skotske lavlandet og Borders',
                        alternativeLabels: [],
                        note: 'Her: Dumfries og Galloway, Southern Uplands, Pentland Hills, Tweeddale',
                        related: [],
                        children: [
                          {
                            id: '1DDU-GB-SBR',
                            label: 'Rhinn of Kells og Galloway Hills',
                            originalLabel: 'Rhinn of Kells og Galloway Hills',
                            alternativeLabels: [],
                            note: 'Her: Galloway Forest Park',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDU-GB-SC',
                        label: 'Sentrale Skottland',
                        originalLabel: 'Sentrale Skottland',
                        alternativeLabels: [],
                        note: 'Her: Clyde, Perth, Firth of Forth, Bute, Ochil Hills',
                        related: [],
                        children: [
                          {
                            id: '1DDU-GB-SCD',
                            label: 'Dundee og Fife',
                            originalLabel: 'Dundee og Fife',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-SCE',
                            label: 'Edinburgh',
                            originalLabel: 'Edinburgh',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-SCG',
                            label: 'Glasgow',
                            originalLabel: 'Glasgow',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-SCS',
                            label: 'Stirling',
                            originalLabel: 'Stirling',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDU-GB-SH',
                        label:
                          'Nordlige Skottland, det skotske høylandet og skotske øyer',
                        originalLabel:
                          'Nordlige Skottland, det skotske høylandet og skotske øyer',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DDU-GB-SHA',
                            label: 'Aberdeen og Deeside',
                            originalLabel: 'Aberdeen og Deeside',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-SHF',
                            label: 'Orknøyene',
                            originalLabel: 'Orknøyene',
                            alternativeLabels: [],
                            note: 'Her: Scapa Flow, Hoy, Kirkwall, Skara Brae',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-SHG',
                            label: 'Grampianfjellene',
                            originalLabel: 'Grampianfjellene',
                            alternativeLabels: [],
                            note: 'Her: Lochnagar, Speyside etc.',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-SHGD',
                                label: 'Cairngorms',
                                originalLabel: 'Cairngorms',
                                alternativeLabels: [],
                                note: 'Her: Cairngorms nasjonalpark, Ben Macdhui, Braeriach, Cairn Toul, Sgor an Lochain Uaine, Angus Glens, Monadhliath, Càrn Dearg, Strathspey, Aviemore, Glenshee, Braemar',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-SHGH',
                                label: 'Ben Nevis og Glen Coe',
                                originalLabel: 'Ben Nevis og Glen Coe',
                                alternativeLabels: [],
                                note: 'Her: Mamores, Glen Etive, Black Mount, Rannoch Moor, Loch Levan, Fort William, Glen Nevis',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-SHJ',
                            label: 'Shetland',
                            originalLabel: 'Shetland',
                            alternativeLabels: [],
                            note: 'Her: Lerwick',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-SHL',
                            label: 'Ytre Hebridene',
                            originalLabel: 'Ytre Hebridene',
                            alternativeLabels: [],
                            note: 'Her: Lewis, Harris, Uist, Benbecula, Barra, Na h-Eileanan Siar, Innse Gall',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-SHN',
                            label: 'Loch Ness og Great Glen',
                            originalLabel: 'Loch Ness og Great Glen',
                            alternativeLabels: [],
                            note: 'Her: Caledoniakanalen',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-SHP',
                            label: 'Indre Hebridene',
                            originalLabel: 'Indre Hebridene',
                            alternativeLabels: [],
                            note: 'Her: Coll, Colonsay, Eigg, Islay, Jura, Rùm, Tiree, Na h-Eileanan a-staigh, Na h-Eileanan Tarsainn, Small Isles',
                            related: [],
                            children: [
                              {
                                id: '1DDU-GB-SHPM',
                                label: 'Isle of Mull og Iona',
                                originalLabel: 'Isle of Mull og Iona',
                                alternativeLabels: [],
                                note: 'Her: Ulva',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DDU-GB-SHQ',
                                label: 'Skye',
                                originalLabel: 'Skye',
                                alternativeLabels: ['Isle of Skye'],
                                note: 'Her: Cuillin Hills',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DDU-GB-SHR',
                            label: 'Arran',
                            originalLabel: 'Arran',
                            alternativeLabels: ['Isle of Arran'],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-SHT',
                            label: 'Loch Lomond og Trossachs',
                            originalLabel: 'Loch Lomond og Trossachs',
                            alternativeLabels: [],
                            note: 'Her: Ben Lui, the Cobbler, Arrochar Alps',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-SHV',
                            label: 'Inverness',
                            originalLabel: 'Inverness',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-SHW',
                            label: 'Northwest Highlands',
                            originalLabel: 'Northwest Highlands',
                            alternativeLabels: [],
                            note: 'Her: Torridon Hills, Ross, Cromarty, Sutherland, Caithness, Kyle of Lochalsh, Cape Wrath etc.',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DDU-GB-W',
                    label: 'Wales',
                    originalLabel: 'Wales',
                    alternativeLabels: [],
                    note: 'Her: Cymru',
                    related: [],
                    children: [
                      {
                        id: '1DDU-GB-WC',
                        label: 'Midt-Wales',
                        originalLabel: 'Midt-Wales',
                        alternativeLabels: [],
                        note: 'Her: Powys, Ceredigion, Cambrian Mountains, Aberystwyth, Machynlleth',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDU-GB-WN',
                        label: 'Nord-Wales',
                        originalLabel: 'Nord-Wales',
                        alternativeLabels: [],
                        note: 'Her: Anglesey, Gwynedd, Clwyd, Llŷn Peninsula, Conwy, Llandudno, Betws-y-Coed, Caernarfon, Barmouth, Dolgellau',
                        related: [],
                        children: [
                          {
                            id: '1DDU-GB-WNS',
                            label: 'Snowdonia (Eryri)',
                            originalLabel: 'Snowdonia (Eryri)',
                            alternativeLabels: [],
                            note: 'Her: Crib Goch, Llyn Llydaw, Glaslyn, Y Garn, Cadair Idris, Yr Wyddfa (Snowdon)',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDU-GB-WS',
                        label: 'Sør-Wales',
                        originalLabel: 'Sør-Wales',
                        alternativeLabels: [],
                        note: 'Her: Newport, Glamorgan, Rhondda',
                        related: [],
                        children: [
                          {
                            id: '1DDU-GB-WSC',
                            label: 'Cardiff',
                            originalLabel: 'Cardiff',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-WSG',
                            label: 'Swansea og Gowerhalvøya',
                            originalLabel: 'Swansea og Gowerhalvøya',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DDU-GB-WSY',
                            label: 'Brecon Beacons',
                            originalLabel: 'Brecon Beacons',
                            alternativeLabels: [],
                            note: 'Her: Black Mountain, Black Mountains and Fforest Fawr, Fan Brycheiniog, Pen y Fan',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DDU-GB-WV',
                        label: 'Sørvest-Wales',
                        originalLabel: 'Sørvest-Wales',
                        alternativeLabels: [],
                        note: 'Her: Carmarthen, Llanelli',
                        related: [],
                        children: [
                          {
                            id: '1DDU-GB-WVP',
                            label: 'Pembrokeshire Coast',
                            originalLabel: 'Pembrokeshire Coast',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DDU-GB-X',
                    label: 'Kanaløyene',
                    originalLabel: 'Kanaløyene',
                    alternativeLabels: [],
                    note: 'Her: Alderney, Sark',
                    related: [],
                    children: [
                      {
                        id: '1DDU-GB-XG',
                        label: 'Guernsey',
                        originalLabel: 'Guernsey',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DDU-GB-XJ',
                        label: 'Jersey',
                        originalLabel: 'Jersey',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DDU-GB-Z',
                    label: 'Man',
                    originalLabel: 'Man',
                    alternativeLabels: ['Isle of Man'],
                    note: 'Her: Douglas, Ellan Vannin, Calf of Man',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '1DF',
            label: 'Sentral-Europa',
            originalLabel: 'Sentral-Europa',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1DFA',
                label: 'Østerrike',
                originalLabel: 'Østerrike',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DFA-AT-B',
                    label: 'Burgenland',
                    originalLabel: 'Burgenland',
                    alternativeLabels: [],
                    note: 'Her: Eisenstadt, Rust, Oberwart',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DFA-AT-K',
                    label: 'Carinthia',
                    originalLabel: 'Carinthia',
                    alternativeLabels: [],
                    note: 'Her: Millstättersee, Ossiacher See',
                    related: [],
                    children: [
                      {
                        id: '1DFA-AT-KB',
                        label: 'Klagenfurt',
                        originalLabel: 'Klagenfurt',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-KD',
                        label: 'Villach',
                        originalLabel: 'Villach',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-KF',
                        label: 'Wörthersee',
                        originalLabel: 'Wörthersee',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DFA-AT-M',
                    label: 'Styria',
                    originalLabel: 'Styria',
                    alternativeLabels: [],
                    note: 'Her: Grimming, Gesäuse',
                    related: [],
                    children: [
                      {
                        id: '1DFA-AT-MB',
                        label: 'Bruck an der Mur',
                        originalLabel: 'Bruck an der Mur',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-MG',
                        label: 'Graz',
                        originalLabel: 'Graz',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-MH',
                        label: 'Hochschwab',
                        originalLabel: 'Hochschwab',
                        alternativeLabels: [],
                        note: 'Her: Mariazell, Tragöss, Wildalpen',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-ML',
                        label: 'Leoben',
                        originalLabel: 'Leoben',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DFA-AT-N',
                    label: 'Niederösterreich',
                    originalLabel: 'Niederösterreich',
                    alternativeLabels: [],
                    note: 'Her: Donau-Auen',
                    related: [],
                    children: [
                      {
                        id: '1DFA-AT-NA',
                        label: 'Industrieviertel',
                        originalLabel: 'Industrieviertel',
                        alternativeLabels: [],
                        note: 'Her: Wiener Neustadt',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-NB',
                        label: 'Mostviertel',
                        originalLabel: 'Mostviertel',
                        alternativeLabels: [],
                        note: 'Her: Sankt Pölten, Waidhofen an der Ybbs',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-NC',
                        label: 'Waldviertel',
                        originalLabel: 'Waldviertel',
                        alternativeLabels: [],
                        note: 'Her: Krems an der Donau',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-ND',
                        label: 'Weinviertel',
                        originalLabel: 'Weinviertel',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-NW',
                        label: 'Wachau',
                        originalLabel: 'Wachau',
                        alternativeLabels: [],
                        note: 'Her: Melk',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DFA-AT-R',
                    label: 'Oberösterreich',
                    originalLabel: 'Oberösterreich',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DFA-AT-RA',
                        label: 'Linz',
                        originalLabel: 'Linz',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-RC',
                        label: 'Hausruckviertel',
                        originalLabel: 'Hausruckviertel',
                        alternativeLabels: [],
                        note: 'Her: Wels',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-RD',
                        label: 'Innviertel',
                        originalLabel: 'Innviertel',
                        alternativeLabels: [],
                        note: 'Her: Schärding',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-RE',
                        label: 'Mühlviertel',
                        originalLabel: 'Mühlviertel',
                        alternativeLabels: [],
                        note: 'Her: Freistadt',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-RF',
                        label: 'Traunviertel',
                        originalLabel: 'Traunviertel',
                        alternativeLabels: [],
                        note: 'Her: Gmunden, Steyr. Se også 1DFA-AT-ZS <a href="/thema/nb/1DFA-AT-ZS">Salzkammergut</a>',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DFA-AT-S',
                    label: 'Salzburg (delstat)',
                    originalLabel: 'Salzburg (delstat)',
                    alternativeLabels: [],
                    note: 'Her: Tennengebirge',
                    related: [],
                    children: [
                      {
                        id: '1DFA-AT-SA',
                        label: 'Salzburg (by)',
                        originalLabel: 'Salzburg (by)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-SC',
                        label: 'Flachgau',
                        originalLabel: 'Flachgau',
                        alternativeLabels: [],
                        note: 'Her: Wallersee. Se også 1DFA-AT-ZS <a href="/thema/nb/1DFA-AT-ZS">Salzkammergut</a>',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-SD',
                        label: 'Pinzgau',
                        originalLabel: 'Pinzgau',
                        alternativeLabels: [],
                        note: 'Her: Saalfelden, Zell am See',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-SE',
                        label: 'Lungau',
                        originalLabel: 'Lungau',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-SF',
                        label: 'Pongau',
                        originalLabel: 'Pongau',
                        alternativeLabels: [],
                        note: 'Her: Gastein, Werfen',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-SG',
                        label: 'Tennengau',
                        originalLabel: 'Tennengau',
                        alternativeLabels: [],
                        note: 'Her: Hallein',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DFA-AT-T',
                    label: 'Tyrol',
                    originalLabel: 'Tyrol',
                    alternativeLabels: ['Tirol'],

                    related: [],
                    children: [
                      {
                        id: '1DFA-AT-TA',
                        label: 'Innsbruck',
                        originalLabel: 'Innsbruck',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-TC',
                        label: 'Ausserfern',
                        originalLabel: 'Ausserfern',
                        alternativeLabels: [],
                        note: 'Her: Reutte',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-TD',
                        label: 'Obertyrol',
                        originalLabel: 'Obertyrol',
                        alternativeLabels: ['Tirol'],
                        note: 'Her: Imst, Landeck, Telfs, Ötztal, Stubaital',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-TE',
                        label: 'Niedertyrol',
                        originalLabel: 'Niedertyrol',
                        alternativeLabels: ['Tirol'],
                        note: 'Her: Hall in Tirol, Kitzbühel, Kaiser Mountains, Zillertal',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-TF',
                        label: 'Øst-Tyrol',
                        originalLabel: 'Øst-Tyrol',
                        alternativeLabels: ['Tirol'],
                        note: 'Her: Lienz',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DFA-AT-V',
                    label: 'Vorarlberg',
                    originalLabel: 'Vorarlberg',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DFA-AT-VB',
                        label: 'Bregenz',
                        originalLabel: 'Bregenz',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-VF',
                        label: 'Feldkirch',
                        originalLabel: 'Feldkirch',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-VM',
                        label: 'Montafon',
                        originalLabel: 'Montafon',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-VR',
                        label: 'Bregenzerwald',
                        originalLabel: 'Bregenzerwald',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DFA-AT-W',
                    label: 'Wien',
                    originalLabel: 'Wien',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DFA-AT-Z',
                    label: 'Østerrike: reisemål',
                    originalLabel: 'Østerrike: reisemål',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DFA-AT-ZA',
                        label: 'Arlberg',
                        originalLabel: 'Arlberg',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-ZD',
                        label: 'Dachstein',
                        originalLabel: 'Dachstein',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-ZR',
                        label: 'Semmering',
                        originalLabel: 'Semmering',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-ZS',
                        label: 'Salzkammergut',
                        originalLabel: 'Salzkammergut',
                        alternativeLabels: [],
                        note: 'Her: Attersee, Mondsee, Traunsee, Wolfgangsee',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFA-AT-ZW',
                        label: 'Wienerwald',
                        originalLabel: 'Wienerwald',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DFG',
                label: 'Tyskland',
                originalLabel: 'Tyskland',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DFG-DE-B',
                    label: 'Nordøst-Tyskland',
                    originalLabel: 'Nordøst-Tyskland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DFG-DE-BE',
                        label: 'Berlin',
                        originalLabel: 'Berlin',
                        alternativeLabels: [],
                        note: 'Brukes for byen eller delstaten, samt for Berlin i historisk sammenheng',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFG-DE-BG',
                        label: 'Brandenburg',
                        originalLabel: 'Brandenburg',
                        alternativeLabels: [],
                        note: 'Brukes for byen eller delstaten, samt for Brandenburg i historisk sammenheng',
                        related: [],
                        children: [
                          {
                            id: '1DFG-DE-BGB',
                            label: 'Havelland',
                            originalLabel: 'Havelland',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BGD',
                            label: 'Potsdam',
                            originalLabel: 'Potsdam',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BGE',
                            label: 'Cottbus',
                            originalLabel: 'Cottbus',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BGF',
                            label: 'Frankfurt (Oder)',
                            originalLabel: 'Frankfurt (Oder)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BGN',
                            label: 'Niederlausitz',
                            originalLabel: 'Niederlausitz',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-BGNS',
                                label: 'Spreewald',
                                originalLabel: 'Spreewald',
                                alternativeLabels: [],
                                note: 'Her: elven Spree',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-BGT',
                            label: 'Uckermark',
                            originalLabel: 'Uckermark',
                            alternativeLabels: ['Prenzlau'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DFG-DE-BK',
                        label: 'Den tyske østersjøkysten og øyer',
                        originalLabel: 'Den tyske østersjøkysten og øyer',
                        alternativeLabels: [],
                        note: 'Her: Lübeckbukta',
                        related: [],
                        children: [
                          {
                            id: '1DFG-DE-BKA',
                            label: 'Rügen',
                            originalLabel: 'Rügen',
                            alternativeLabels: [],
                            note: 'Her: Jasmund nasjonalpark',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BKB',
                            label: 'Fehmarn',
                            originalLabel: 'Fehmarn',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BKD',
                            label: 'Fischland-Darss-Zingst',
                            originalLabel: 'Fischland-Darss-Zingst',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BKF',
                            label: 'Usedom',
                            originalLabel: 'Usedom',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DFG-DE-BM',
                        label: 'Mecklenburg-Vorpommern',
                        originalLabel: 'Mecklenburg-Vorpommern',
                        alternativeLabels: [],
                        note: 'Her: Güstrow',
                        related: [],
                        children: [
                          {
                            id: '1DFG-DE-BMB',
                            label: 'Schwerin',
                            originalLabel: 'Schwerin',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BMC',
                            label: 'Greifswald',
                            originalLabel: 'Greifswald',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BMD',
                            label: 'Rostock',
                            originalLabel: 'Rostock',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BMF',
                            label: 'Stralsund',
                            originalLabel: 'Stralsund',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BMH',
                            label: 'Wismar',
                            originalLabel: 'Wismar',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BMM',
                            label: 'Mecklenburgische Seenplatte',
                            originalLabel: 'Mecklenburgische Seenplatte',
                            alternativeLabels: [],
                            note: 'Her: Neubrandenburg, Müritz',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DFG-DE-BS',
                        label: 'Sachsen-Anhalt',
                        originalLabel: 'Sachsen-Anhalt',
                        alternativeLabels: [],
                        note: 'Her: Stendal, Anhalt, Altmark, Saale-Unstrut-regionen, Triasland',
                        related: [],
                        children: [
                          {
                            id: '1DFG-DE-BSB',
                            label: 'Magdeburg',
                            originalLabel: 'Magdeburg',
                            alternativeLabels: [],
                            note: 'Her: Magdeburg Börde, Elbe-Börde-Heath, Magdeburg (region)',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BSC',
                            label: 'Dessau',
                            originalLabel: 'Dessau',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BSD',
                            label: 'Halberstadt',
                            originalLabel: 'Halberstadt',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BSF',
                            label: 'Halle (Saale)',
                            originalLabel: 'Halle (Saale)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BSG',
                            label: 'Lutherstadt Wittenberg',
                            originalLabel: 'Lutherstadt Wittenberg',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BSH',
                            label: 'Quedlinburg',
                            originalLabel: 'Quedlinburg',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-BSK',
                            label: 'Wernigerode',
                            originalLabel: 'Wernigerode',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DFG-DE-F',
                    label: 'Øst-Tyskland',
                    originalLabel: 'Øst-Tyskland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DFG-DE-FS',
                        label: 'Sachsen',
                        originalLabel: 'Sachsen',
                        alternativeLabels: [],
                        note: 'Her: Elster',
                        related: [],
                        children: [
                          {
                            id: '1DFG-DE-FSA',
                            label: 'Leipzig',
                            originalLabel: 'Leipzig',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-FSB',
                            label: 'Dresden',
                            originalLabel: 'Dresden',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-FSC',
                            label: 'Bautzen',
                            originalLabel: 'Bautzen',
                            alternativeLabels: [],
                            note: 'Se også 1DFG-DE-XA <a href="/thema/nb/1DFG-DE-XA">Oberlausitz</a>',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-FSD',
                            label: 'Görlitz',
                            originalLabel: 'Görlitz',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-FSE',
                            label: 'Chemnitz',
                            originalLabel: 'Chemnitz',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-FSF',
                            label: 'Freiberg',
                            originalLabel: 'Freiberg',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-FSG',
                            label: 'Plauen',
                            originalLabel: 'Plauen',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-FSH',
                            label: 'Zwickau',
                            originalLabel: 'Zwickau',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-FSN',
                            label: 'Saxon Switzerland',
                            originalLabel: 'Saxon Switzerland',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-FSNB',
                                label: 'Elbsandsteingebirge',
                                originalLabel: 'Elbsandsteingebirge',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-FSP',
                            label: 'Erzgebirge',
                            originalLabel: 'Erzgebirge',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-FSQ',
                            label: 'Vogtland',
                            originalLabel: 'Vogtland',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DFG-DE-FT',
                        label: 'Thüringen',
                        originalLabel: 'Thüringen',
                        alternativeLabels: [],
                        note: 'Her: Gera, Mühlhausen, Nordhausen, Eichsfeld-Hainich-Werratal Naturpark, Hainich nasjonalpark, Kyffhäuser',
                        related: [],
                        children: [
                          {
                            id: '1DFG-DE-FTB',
                            label: 'Erfurt',
                            originalLabel: 'Erfurt',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-FTD',
                            label: 'Thüringer Wald',
                            originalLabel: 'Thüringer Wald',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-FTDB',
                                label: 'Eisenach',
                                originalLabel: 'Eisenach',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-FTH',
                            label: 'Jena',
                            originalLabel: 'Jena',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-FTJ',
                            label: 'Weimar',
                            originalLabel: 'Weimar',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DFG-DE-T',
                    label: 'Sørøst-Tyskland',
                    originalLabel: 'Sørøst-Tyskland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DFG-DE-TB',
                        label: 'Bayern',
                        originalLabel: 'Bayern',
                        alternativeLabels: [],
                        note: 'Se også 1DFG-DE-XB <a href="/thema/nb/1DFG-DE-XB">Swabia</a>',
                        related: [],
                        children: [
                          {
                            id: '1DFG-DE-TBA',
                            label: 'Niederbayern',
                            originalLabel: 'Niederbayern',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-TBAB',
                                label: 'Passau',
                                originalLabel: 'Passau',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-TBB',
                            label: 'Oberbayern',
                            originalLabel: 'Oberbayern',
                            alternativeLabels: [],
                            note: 'Class here: Ammersee, Lake Ammer. Se også 1DFG-DE-XB <a href="/thema/nb/1DFG-DE-XB">Swabia</a>',
                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-TBBB',
                                label: 'Berchtesgadener Land',
                                originalLabel: 'Berchtesgadener Land',
                                alternativeLabels: [],
                                note: 'Her: Berchtesgaden nasjonalpark',
                                related: [],
                                children: [
                                  {
                                    id: '1DFG-DE-TBBBA',
                                    label: 'Berchtesgaden',
                                    originalLabel: 'Berchtesgaden',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DFG-DE-TBBD',
                                label: 'Ingolstadt',
                                originalLabel: 'Ingolstadt',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DFG-DE-TBBF',
                                label: 'Chiemgau',
                                originalLabel: 'Chiemgau',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DFG-DE-TBBFS',
                                    label: 'Chiemsee',
                                    originalLabel: 'Chiemsee',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DFG-DE-TBBG',
                                label: 'Starnberger See',
                                originalLabel: 'Starnberger See',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DFG-DE-TBBH',
                                label: 'Tegernsee',
                                originalLabel: 'Tegernsee',
                                alternativeLabels: [],
                                note: 'Her: Tegernsee (by)',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DFG-DE-TBBK',
                                label: 'Ammersee',
                                originalLabel: 'Ammersee',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-TBC',
                            label: 'München',
                            originalLabel: 'München',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-TBD',
                            label: 'Nürnberg',
                            originalLabel: 'Nürnberg',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-TBF',
                            label: 'Franken',
                            originalLabel: 'Franken',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-TBFC',
                                label: 'Oberfranken',
                                originalLabel: 'Oberfranken',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DFG-DE-TBFCB',
                                    label: 'Bamberg',
                                    originalLabel: 'Bamberg',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DFG-DE-TBFCD',
                                    label: 'Bayreuth',
                                    originalLabel: 'Bayreuth',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DFG-DE-TBFCP',
                                    label: 'Fichtelgebirge',
                                    originalLabel: 'Fichtelgebirge',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DFG-DE-TBFM',
                                label: 'Mittelfranken',
                                originalLabel: 'Mittelfranken',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DFG-DE-TBFMB',
                                    label: 'Fürth',
                                    originalLabel: 'Fürth',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DFG-DE-TBFMD',
                                    label: 'Rothenburg ob der Tauber',
                                    originalLabel: 'Rothenburg ob der Tauber',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1DFG-DE-TBFS',
                                label: 'Steigerwald',
                                originalLabel: 'Steigerwald',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DFG-DE-TBFV',
                                label: 'Niederfranken',
                                originalLabel: 'Niederfranken',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1DFG-DE-TBFVB',
                                    label: 'Aschaffenburg',
                                    originalLabel: 'Aschaffenburg',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1DFG-DE-TBFVD',
                                    label: 'Würzburg',
                                    originalLabel: 'Würzburg',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-TBH',
                            label: 'Augsburg',
                            originalLabel: 'Augsburg',
                            alternativeLabels: [],
                            note: 'Se også 1DFG-DE-XB <a href="/thema/nb/1DFG-DE-XB">Swabia</a>',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-TBJ',
                            label: 'Füssen',
                            originalLabel: 'Füssen',
                            alternativeLabels: [],
                            note: 'Se også 1DFG-DE-XB <a href="/thema/nb/1DFG-DE-XB">Swabia</a>',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-TBK',
                            label: 'Kempten',
                            originalLabel: 'Kempten',
                            alternativeLabels: [],
                            note: 'Se også 1DFG-DE-XB <a href="/thema/nb/1DFG-DE-XB">Swabia</a>',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-TBL',
                            label: 'Lindau',
                            originalLabel: 'Lindau',
                            alternativeLabels: [],
                            note: 'Se også 1DFG-DE-XB <a href="/thema/nb/1DFG-DE-XB">Swabia</a>',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-TBM',
                            label: 'Oberpfalz',
                            originalLabel: 'Oberpfalz',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-TBMB',
                                label: 'Regensburg',
                                originalLabel: 'Regensburg',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-TBN',
                            label: 'Altmühltal',
                            originalLabel: 'Altmühltal',
                            alternativeLabels: [],
                            note: 'Her: Altmühl',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-TBP',
                            label: 'Bayerischer Wald',
                            originalLabel: 'Bayerischer Wald',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-TBR',
                            label: 'De bayerske alper',
                            originalLabel: 'De bayerske alper',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DFG-DE-U',
                    label: 'Sørvest-Tyskland',
                    originalLabel: 'Sørvest-Tyskland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DFG-DE-UB',
                        label: 'Baden-Württemberg',
                        originalLabel: 'Baden-Württemberg',
                        alternativeLabels: [],
                        note: 'Class here: Pforzheim, Kraichgau, Tauber Franconia, Rhine-Neckar region',
                        related: [],
                        children: [
                          {
                            id: '1DFG-DE-UBA',
                            label: 'Stuttgart',
                            originalLabel: 'Stuttgart',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-UBC',
                            label: 'Breisgau',
                            originalLabel: 'Breisgau',
                            alternativeLabels: [],
                            note: 'Her: Kaiserstuhl',
                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-UBCF',
                                label: 'Freiburg im Breisgau',
                                originalLabel: 'Freiburg im Breisgau',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-UBE',
                            label: 'Pfalzgrevskapet ved Rhinen',
                            originalLabel: 'Pfalzgrevskapet ved Rhinen',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-UBEB',
                                label: 'Heidelberg',
                                originalLabel: 'Heidelberg',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DFG-DE-UBED',
                                label: 'Mannheim',
                                originalLabel: 'Mannheim',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-UBG',
                            label: 'Heilbronn',
                            originalLabel: 'Heilbronn',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-UBJ',
                            label: 'Karlsruhe',
                            originalLabel: 'Karlsruhe',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-UBL',
                            label: 'Konstanz',
                            originalLabel: 'Konstanz',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-UBP',
                            label: 'Schwäbische Alb',
                            originalLabel: 'Schwäbische Alb',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-UBPB',
                                label: 'Reutlingen',
                                originalLabel: 'Reutlingen',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DFG-DE-UBPD',
                                label: 'Ulm',
                                originalLabel: 'Ulm',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-UBR',
                            label: 'Singen (Hohentwiel)',
                            originalLabel: 'Singen (Hohentwiel)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-UBT',
                            label: 'Tübingen',
                            originalLabel: 'Tübingen',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DFG-DE-UH',
                        label: 'Hessen',
                        originalLabel: 'Hessen',
                        alternativeLabels: [],
                        note: 'Her: Fulda, Limburg (Lahn), Marburg, Vogelsberg, Wetterau',
                        related: [],
                        children: [
                          {
                            id: '1DFG-DE-UHA',
                            label: 'Frankfurt',
                            originalLabel: 'Frankfurt',
                            alternativeLabels: ['Frankfurt-am-Main'],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-UHB',
                            label: 'Frankfurt Rhine-Main',
                            originalLabel: 'Frankfurt Rhine-Main',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-UHC',
                            label: 'Rheingau',
                            originalLabel: 'Rheingau',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-UHCW',
                                label: 'Wiesbaden',
                                originalLabel: 'Wiesbaden',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-UHE',
                            label: 'Darmstadt',
                            originalLabel: 'Darmstadt',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-UHP',
                            label: 'Kassel',
                            originalLabel: 'Kassel',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-UHT',
                            label: 'Taunus',
                            originalLabel: 'Taunus',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DFG-DE-UR',
                        label: 'Rheinland-Pfalz',
                        originalLabel: 'Rheinland-Pfalz',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DFG-DE-URA',
                            label: 'Pfalz',
                            originalLabel: 'Pfalz',
                            alternativeLabels: [
                              'vinrute',
                              'Deutsche Weinstraße',
                            ],
                            note: 'Her: Ludwigshafen, Neustadt an der Weinstrasse, Bad Dürkheim, den tyske vinveien',
                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-URAS',
                                label: 'Speyer',
                                originalLabel: 'Speyer',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-URC',
                            label: 'Rheinhessen',
                            originalLabel: 'Rheinhessen',
                            alternativeLabels: [],
                            note: 'Her: Bingen am Rhein, Ingelheim am Rhein',
                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-URCM',
                                label: 'Mainz',
                                originalLabel: 'Mainz',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DFG-DE-URCW',
                                label: 'Worms',
                                originalLabel: 'Worms',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-URK',
                            label: 'Koblenz',
                            originalLabel: 'Koblenz',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-URT',
                            label: 'Trier',
                            originalLabel: 'Trier',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-URW',
                            label: 'Hunsrück',
                            originalLabel: 'Hunsrück',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DFG-DE-US',
                        label: 'Saarland',
                        originalLabel: 'Saarland',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFG-DE-UV',
                        label: 'Øvre mellomrhindal og -region',
                        originalLabel: 'Øvre mellomrhindal og -region',
                        alternativeLabels: [],
                        note: 'Her: strekningen mellom Rüdesheim og Koblenz langs Rhinen',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DFG-DE-V',
                    label: 'Nordvest-Tyskland',
                    originalLabel: 'Nordvest-Tyskland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DFG-DE-VA',
                        label: 'Den tyske nordsjøkysten og øyer',
                        originalLabel: 'Den tyske nordsjøkysten og øyer',
                        alternativeLabels: ['Wattenmeer'],
                        note: 'Her: Schleswig-Holstein vadehav',
                        related: [],
                        children: [
                          {
                            id: '1DFG-DE-VAF',
                            label: 'Helgoland',
                            originalLabel: 'Helgoland',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VAN',
                            label: 'Nordfrisland',
                            originalLabel: 'Nordfrisland',
                            alternativeLabels: [],
                            note: 'Class here: North Frisian Islands, Halligen',
                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-VANB',
                                label: 'Amrum',
                                originalLabel: 'Amrum',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DFG-DE-VANF',
                                label: 'Föhr',
                                originalLabel: 'Föhr',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DFG-DE-VANS',
                                label: 'Sylt',
                                originalLabel: 'Sylt',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-VAP',
                            label: 'Østfriesland',
                            originalLabel: 'Østfriesland',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-VAPB',
                                label: 'De østfrisiske øyer',
                                originalLabel: 'De østfrisiske øyer',
                                alternativeLabels: [
                                  'Wadden',
                                  'Borkum',
                                  'Sylt',
                                  'Wangerooge',
                                  'Spiekeroog',
                                  'Langeoog',
                                  'Baltrum',
                                  'Norderney',
                                  'Juist',
                                  'Frisians',
                                ],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DFG-DE-VB',
                        label: 'Bremen',
                        originalLabel: 'Bremen',
                        alternativeLabels: [],
                        note: 'Brukes for byen eller delstaten, samt for Bremen i historisk sammenheng',
                        related: [],
                        children: [
                          {
                            id: '1DFG-DE-VBC',
                            label: 'Bremerhaven',
                            originalLabel: 'Bremerhaven',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DFG-DE-VH',
                        label: 'Hamburg',
                        originalLabel: 'Hamburg',
                        alternativeLabels: [],
                        note: 'Brukes for byen eller delstaten, samt for Hamburg i historisk sammenheng',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFG-DE-VN',
                        label: 'Niedersachsen',
                        originalLabel: 'Niedersachsen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DFG-DE-VNA',
                            label: 'Hannover',
                            originalLabel: 'Hannover',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VNB',
                            label: 'Wendland',
                            originalLabel: 'Wendland',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VNC',
                            label: 'Braunschweig',
                            originalLabel: 'Braunschweig',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VND',
                            label: 'Lüneburger Heide',
                            originalLabel: 'Lüneburger Heide',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-VNDB',
                                label: 'Celle',
                                originalLabel: 'Celle',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DFG-DE-VNDD',
                                label: 'Lüneburg',
                                originalLabel: 'Lüneburg',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-VNE',
                            label: 'Goslar',
                            originalLabel: 'Goslar',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VNF',
                            label: 'Göttingen',
                            originalLabel: 'Göttingen',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VNG',
                            label: 'Hildesheim',
                            originalLabel: 'Hildesheim',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VNH',
                            label: 'Oldenburg',
                            originalLabel: 'Oldenburg',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VNK',
                            label: 'Osnabrück',
                            originalLabel: 'Osnabrück',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DFG-DE-VR',
                        label: 'Nordrhein-Westfalen',
                        originalLabel: 'Nordrhein-Westfalen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DFG-DE-VRA',
                            label: 'Aachen',
                            originalLabel: 'Aachen',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VRB',
                            label: 'Düsseldorf',
                            originalLabel: 'Düsseldorf',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VRC',
                            label: 'Köln',
                            originalLabel: 'Köln',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VRD',
                            label: 'Dortmund',
                            originalLabel: 'Dortmund',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VRE',
                            label: 'Essen',
                            originalLabel: 'Essen',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VRG',
                            label: 'Bonn',
                            originalLabel: 'Bonn',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VRH',
                            label: 'Münster (region)',
                            originalLabel: 'Münster (region)',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-VRHM',
                                label: 'Münster',
                                originalLabel: 'Münster',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-VRJ',
                            label: 'Siegen',
                            originalLabel: 'Siegen',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VRK',
                            label: 'Ostwestfalen-Lippe',
                            originalLabel: 'Ostwestfalen-Lippe',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-VRKB',
                                label: 'Bielefeld',
                                originalLabel: 'Bielefeld',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DFG-DE-VRKP',
                                label: 'Paderborn',
                                originalLabel: 'Paderborn',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-VRL',
                            label: 'Bergisches Land',
                            originalLabel: 'Bergisches Land',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-VRLB',
                                label: 'Solingen',
                                originalLabel: 'Solingen',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DFG-DE-VRLD',
                                label: 'Wuppertal',
                                originalLabel: 'Wuppertal',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DFG-DE-VRM',
                            label: 'Niederrhein (region)',
                            originalLabel: 'Niederrhein (region)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VRN',
                            label: 'Sauerland',
                            originalLabel: 'Sauerland',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFG-DE-VRR',
                            label: 'Ruhrområdet',
                            originalLabel: 'Ruhrområdet',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DFG-DE-VRRB',
                                label: 'Bochum',
                                originalLabel: 'Bochum',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DFG-DE-VRRD',
                                label: 'Duisburg',
                                originalLabel: 'Duisburg',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DFG-DE-VS',
                        label: 'Schleswig-Holstein',
                        originalLabel: 'Schleswig-Holstein',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DFG-DE-X',
                    label: 'Tyskland: reisemål',
                    originalLabel: 'Tyskland: reisemål',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DFG-DE-XA',
                        label: 'Oberlausitz',
                        originalLabel: 'Oberlausitz',
                        alternativeLabels: [],
                        note: 'Se også 1DFG-DE-FSC <a href="/thema/nb/1DFG-DE-FSC">Bautzen</a>',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFG-DE-XB',
                        label: 'Swabia',
                        originalLabel: 'Swabia',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFG-DE-XC',
                        label: 'Allgäu',
                        originalLabel: 'Allgäu',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFG-DE-XD',
                        label: 'Rothaargebirge',
                        originalLabel: 'Rothaargebirge',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFG-DE-XE',
                        label: 'Odenwald',
                        originalLabel: 'Odenwald',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DFG-DE-XEB',
                            label: 'Bergstrasse',
                            originalLabel: 'Bergstrasse',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DFG-DE-XF',
                        label: 'Teutoburger Wald',
                        originalLabel: 'Teutoburger Wald',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFG-DE-XG',
                        label: 'Westerwald',
                        originalLabel: 'Westerwald',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFG-DE-XH',
                        label: 'Spessart',
                        originalLabel: 'Spessart',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DFH',
                label: 'Sveits',
                originalLabel: 'Sveits',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DFH-CH-C',
                    label: 'Espace Mittelland',
                    originalLabel: 'Espace Mittelland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DFH-CH-CB',
                        label: 'Bern',
                        originalLabel: 'Bern',
                        alternativeLabels: [],
                        note: 'Her: Biel / Bienne (innsjø og by), Bielersee, Burgdorf, Emmental, Gstaad. Brukes for byen eller kantonen',
                        related: [],
                        children: [
                          {
                            id: '1DFH-CH-CBB',
                            label: 'Bernese Oberland',
                            originalLabel: 'Bernese Oberland',
                            alternativeLabels: [],
                            note: 'Class here: Lake Brienz',
                            related: [],
                            children: [
                              {
                                id: '1DFH-CH-CBBD',
                                label: 'Interlaken',
                                originalLabel: 'Interlaken',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DFH-CH-CBBG',
                                label: 'Grindelwald',
                                originalLabel: 'Grindelwald',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DFH-CH-CBBT',
                                label: 'Thun',
                                originalLabel: 'Thun',
                                alternativeLabels: [],
                                note: 'Her: Thunersjøen',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DFH-CH-CF',
                        label: 'Fribourg / Freiburg',
                        originalLabel: 'Fribourg / Freiburg',
                        alternativeLabels: [],
                        note: 'Her: Greyerz / Gruyères, Murten / Morat (innsjø og by). Brukes for byen eller kantonen',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-CJ',
                        label: 'Jura',
                        originalLabel: 'Jura',
                        alternativeLabels: [],
                        note: 'Her: Delsberg / Delémont',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-CN',
                        label: 'Neuchâtel',
                        originalLabel: 'Neuchâtel',
                        alternativeLabels: [],
                        note: 'Her: Neuchatelsjøen. Brukes for byen eller kantonen',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-CS',
                        label: 'Solothurn',
                        originalLabel: 'Solothurn',
                        alternativeLabels: [],
                        note: 'Her: Olten, Weissenstein. Brukes for byen eller kantonen',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DFH-CH-G',
                    label: 'Genfersjøregionen',
                    originalLabel: 'Genfersjøregionen',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DFH-CH-GG',
                        label: 'Genève',
                        originalLabel: 'Genève',
                        alternativeLabels: ['Genf'],
                        note: 'Brukes for byen eller kantonen',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-GV',
                        label: 'Vaud',
                        originalLabel: 'Vaud',
                        alternativeLabels: [],
                        note: 'Her: Nyon, Vevey, Yverdon-les-Bains, La Côte, Vallée de Joux',
                        related: [],
                        children: [
                          {
                            id: '1DFH-CH-GVL',
                            label: 'Lausanne',
                            originalLabel: 'Lausanne',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFH-CH-GVM',
                            label: 'Montreux',
                            originalLabel: 'Montreux',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DFH-CH-GW',
                        label: 'Valais',
                        originalLabel: 'Valais',
                        alternativeLabels: [],
                        note: 'Her: Brig, Saas-Fee, Sion',
                        related: [],
                        children: [
                          {
                            id: '1DFH-CH-GWB',
                            label: 'Zermatt',
                            originalLabel: 'Zermatt',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DFH-CH-N',
                    label: 'Nordvest-Sveits',
                    originalLabel: 'Nordvest-Sveits',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DFH-CH-NA',
                        label: 'Aargau',
                        originalLabel: 'Aargau',
                        alternativeLabels: [],
                        note: 'Her: Lenzburg',
                        related: [],
                        children: [
                          {
                            id: '1DFH-CH-NAB',
                            label: 'Aarau',
                            originalLabel: 'Aarau',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DFH-CH-NB',
                        label: 'Basel-Landschaft',
                        originalLabel: 'Basel-Landschaft',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DFH-CH-NBL',
                            label: 'Liestal',
                            originalLabel: 'Liestal',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DFH-CH-ND',
                        label: 'Basel',
                        originalLabel: 'Basel',
                        alternativeLabels: [],
                        note: 'Brukes for byen eller kantonen',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DFH-CH-P',
                    label: 'Øst-Sveits',
                    originalLabel: 'Øst-Sveits',
                    alternativeLabels: [],
                    note: 'Her: Appenzellerland, Appenzeller-Alpene',
                    related: [],
                    children: [
                      {
                        id: '1DFH-CH-PC',
                        label: 'Appenzell Innerrhoden',
                        originalLabel: 'Appenzell Innerrhoden',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-PD',
                        label: 'Appenzell Ausserrhoden',
                        originalLabel: 'Appenzell Ausserrhoden',
                        alternativeLabels: [],
                        note: 'Her: Herisau, Trogen',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-PG',
                        label: 'Glarus',
                        originalLabel: 'Glarus',
                        alternativeLabels: [],
                        note: 'Her: Glarner-Alpene. Bruks for byen eller kantonen',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-PK',
                        label: 'Graubünden',
                        originalLabel: 'Graubünden',
                        alternativeLabels: [],
                        note: 'Her: Arosa, Münstertal / Val Müstair, Prättigau, Klosters, Surselva, Grischun, Grigioni',
                        related: [],
                        children: [
                          {
                            id: '1DFH-CH-PKA',
                            label: 'Chur (Coire)',
                            originalLabel: 'Chur (Coire)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFH-CH-PKB',
                            label: 'Davos',
                            originalLabel: 'Davos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DFH-CH-PKM',
                            label: 'Engadine',
                            originalLabel: 'Engadine',
                            alternativeLabels: [],
                            note: 'Her: Malojapasset, Albulapasset, Flüelapasset, Julierpasset, Malojapasset, Ofenpasset / Fuornpasset',
                            related: [],
                            children: [
                              {
                                id: '1DFH-CH-PKMB',
                                label: 'St. Moritz',
                                originalLabel: 'St. Moritz',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DFH-CH-PN',
                        label: 'St. Gallen',
                        originalLabel: 'St. Gallen',
                        alternativeLabels: [],
                        note: 'Her: Rapperswil. Brukes for byen eller kantonen',
                        related: [],
                        children: [
                          {
                            id: '1DFH-CH-PNH',
                            label: 'Toggenburg',
                            originalLabel: 'Toggenburg',
                            alternativeLabels: [],
                            note: 'Her: Wildhaus',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DFH-CH-PP',
                        label: 'Schaffhausen',
                        originalLabel: 'Schaffhausen',
                        alternativeLabels: [],
                        note: 'Her: Stein am Rhein. Brukes for byen eller kantonen',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-PR',
                        label: 'Thurgau',
                        originalLabel: 'Thurgau',
                        alternativeLabels: [],
                        note: 'Her: Frauenfeld',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-PZ',
                        label: 'Øst-Sveits: reisemål',
                        originalLabel: 'Øst-Sveits: reisemål',
                        alternativeLabels: [],
                        note: 'Her: Walensee',
                        related: [],
                        children: [
                          {
                            id: '1DFH-CH-PZB',
                            label: 'Säntis',
                            originalLabel: 'Säntis',
                            alternativeLabels: [],
                            note: 'Her: Alpstein-massivet',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DFH-CH-R',
                    label: 'Tessin',
                    originalLabel: 'Tessin',
                    alternativeLabels: [],
                    note: 'Her: Verzasca-dalen',
                    related: [],
                    children: [
                      {
                        id: '1DFH-CH-RA',
                        label: 'Ascona',
                        originalLabel: 'Ascona',
                        alternativeLabels: [],
                        note: 'Her: Monte Verità',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-RC',
                        label: 'Bellinzona',
                        originalLabel: 'Bellinzona',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-RE',
                        label: 'Locarno',
                        originalLabel: 'Locarno',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-RG',
                        label: 'Lugano',
                        originalLabel: 'Lugano',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DFH-CH-U',
                    label: 'Sentrale Sveits',
                    originalLabel: 'Sentrale Sveits',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DFH-CH-UL',
                        label: 'Luzern',
                        originalLabel: 'Luzern',
                        alternativeLabels: [],
                        note: 'Her: Entlebuch, Sempachersee. Brukes for byen eller kantonen',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-UN',
                        label: 'Nidwalden',
                        originalLabel: 'Nidwalden',
                        alternativeLabels: [],
                        note: 'Her: Stans',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-UO',
                        label: 'Obwalden',
                        originalLabel: 'Obwalden',
                        alternativeLabels: [],
                        note: 'Her: Engelberg, Sarnen',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-US',
                        label: 'Schwyz',
                        originalLabel: 'Schwyz',
                        alternativeLabels: [],
                        note: 'Her: Lauerzsjøen, Sihlsee, Muotatal, Stoos, Einsiedeln. Brukes for byen eller kantonen',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-UU',
                        label: 'Uri',
                        originalLabel: 'Uri',
                        alternativeLabels: [],
                        note: 'Her: Altdorf, Andermatt',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-UV',
                        label: 'Zug',
                        originalLabel: 'Zug',
                        alternativeLabels: [],
                        note: 'Her: Zugsjøen. Brukes for byen eller kantonen',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DFH-CH-UZ',
                        label: 'Sentrale Sveits: reisemål',
                        originalLabel: 'Sentrale Sveits: reisemål',
                        alternativeLabels: [],
                        note: 'Her: fjellene Pilatus og Titlis',
                        related: [],
                        children: [
                          {
                            id: '1DFH-CH-UZD',
                            label: 'Luzernsjøen',
                            originalLabel: 'Luzernsjøen',
                            alternativeLabels: ['Vierwaldstättersjøen'],
                            note: 'Her: innsjøen og områdene rundt',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DFH-CH-X',
                    label: 'Zürich',
                    originalLabel: 'Zürich',
                    alternativeLabels: [],
                    note: 'Her: Zürcher Oberland. Brukes for byen eller kantonen',
                    related: [],
                    children: [
                      {
                        id: '1DFH-CH-XB',
                        label: 'Winterthur',
                        originalLabel: 'Winterthur',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DFH-CH-Z',
                    label: 'Sveits: reisemål',
                    originalLabel: 'Sveits: reisemål',
                    alternativeLabels: [],
                    note: 'Her: elvene Aare og Reuss, Seetal, Hallwilersee, Baldeggersee',
                    related: [],
                    children: [
                      {
                        id: '1DFH-CH-ZS',
                        label: 'Zürichsjøen',
                        originalLabel: 'Zürichsjøen',
                        alternativeLabels: [],
                        note: 'Her innsjøen og områdene rundt',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DFL',
                label: 'Liechtenstein',
                originalLabel: 'Liechtenstein',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '1DN',
            label: 'Norden, Skandinavia',
            originalLabel: 'Norden, Skandinavia',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1DNC',
                label: 'Island',
                originalLabel: 'Island',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DNC-IS-R',
                    label: 'Reykjavík',
                    originalLabel: 'Reykjavík',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DND',
                label: 'Danmark',
                originalLabel: 'Danmark',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DND-DK-B',
                    label: 'Bornholm',
                    originalLabel: 'Bornholm',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DND-DK-F',
                    label: 'Fyn med øyer',
                    originalLabel: 'Fyn med øyer',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DND-DK-FO',
                        label: 'Odense',
                        originalLabel: 'Odense',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DND-DK-J',
                    label: 'Jylland',
                    originalLabel: 'Jylland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DND-DK-JE',
                        label: 'Esbjerg',
                        originalLabel: 'Esbjerg',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DND-DK-JL',
                        label: 'Ålborg',
                        originalLabel: 'Ålborg',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DND-DK-JR',
                        label: 'Århus',
                        originalLabel: 'Århus',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DND-DK-L',
                    label: 'Lolland-Falster',
                    originalLabel: 'Lolland-Falster',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DND-DK-S',
                    label: 'Sjælland',
                    originalLabel: 'Sjælland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DND-DK-SK',
                        label: 'København',
                        originalLabel: 'København',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DND-DK-SR',
                        label: 'Roskilde',
                        originalLabel: 'Roskilde',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DNDF',
                    label: 'Færøyene',
                    originalLabel: 'Færøyene',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DNDF-FO-T',
                        label: 'Thorshavn',
                        originalLabel: 'Thorshavn',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DNF',
                label: 'Finland',
                originalLabel: 'Finland',
                alternativeLabels: [],
                note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                related: [],
                children: [
                  {
                    id: '1DNF-FI-A',
                    label: 'Sørvest-Finland',
                    originalLabel: 'Sørvest-Finland',
                    alternativeLabels: [],
                    note: 'Brukes for: historiske, kulturelle og administrative områder',
                    related: [],
                    children: [
                      {
                        id: '1DNF-FI-AA',
                        label: 'Turku',
                        originalLabel: 'Turku',
                        alternativeLabels: ['Åbo'],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DNF-FI-AB',
                        label: 'Turkus skjærgård',
                        originalLabel: 'Turkus skjærgård',
                        alternativeLabels: ['Åbos skjærgård'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DNF-FI-B',
                    label: 'Åland',
                    originalLabel: 'Åland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DNF-FI-BA',
                        label: 'Mariehamn',
                        originalLabel: 'Mariehamn',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DNF-FI-C',
                    label: 'Uusimaa',
                    originalLabel: 'Uusimaa',
                    alternativeLabels: [],
                    note: 'Brukes for: historiske, kulturelle og administrative områder',
                    related: [],
                    children: [
                      {
                        id: '1DNF-FI-CA',
                        label: 'Vestlige Uusimaa',
                        originalLabel: 'Vestlige Uusimaa',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DNF-FI-CB',
                        label: 'Helsinki (regionen)',
                        originalLabel: 'Helsinki (regionen)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNF-FI-CBA',
                            label: 'Helsinki',
                            originalLabel: 'Helsinki',
                            alternativeLabels: [],
                            note: 'Her: Suomenlinna (Sveaborg)',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNF-FI-CBB',
                            label: 'Espoo',
                            originalLabel: 'Espoo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNF-FI-CBC',
                            label: 'Vantaa',
                            originalLabel: 'Vantaa',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNF-FI-CC',
                        label: 'Østlige Uusimaa',
                        originalLabel: 'Østlige Uusimaa',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNF-FI-CCA',
                            label: 'Porvoo',
                            originalLabel: 'Porvoo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DNF-FI-D',
                    label: 'Häme',
                    originalLabel: 'Häme',
                    alternativeLabels: [],
                    note: 'Brukes for: historiske, kulturelle og administrative områder. Her: Tavastia',
                    related: [],
                    children: [
                      {
                        id: '1DNF-FI-DA',
                        label: 'Kanta-Häme',
                        originalLabel: 'Kanta-Häme',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNF-FI-DAA',
                            label: 'Hämeenlinna',
                            originalLabel: 'Hämeenlinna',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNF-FI-DB',
                        label: 'Päijät-Häme',
                        originalLabel: 'Päijät-Häme',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNF-FI-DBA',
                            label: 'Lahti',
                            originalLabel: 'Lahti',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNF-FI-DC',
                        label: 'Pirkanmaa',
                        originalLabel: 'Pirkanmaa',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNF-FI-DCA',
                            label: 'Tampere',
                            originalLabel: 'Tampere',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNF-FI-DD',
                        label: 'Sentrale Finland',
                        originalLabel: 'Sentrale Finland',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNF-FI-DDA',
                            label: 'Jyväskylä',
                            originalLabel: 'Jyväskylä',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DNF-FI-H',
                    label: 'Satakunta',
                    originalLabel: 'Satakunta',
                    alternativeLabels: [],
                    note: 'Brukes for: historiske, kulturelle og administrative områder',
                    related: [],
                    children: [
                      {
                        id: '1DNF-FI-HA',
                        label: 'Pori',
                        originalLabel: 'Pori',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DNF-FI-HB',
                        label: 'Rauma',
                        originalLabel: 'Rauma',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DNF-FI-J',
                    label: 'Savonia',
                    originalLabel: 'Savonia',
                    alternativeLabels: [],
                    note: 'Brukes for: historiske, kulturelle og administrative områder',
                    related: [],
                    children: [
                      {
                        id: '1DNF-FI-JA',
                        label: 'Sørlige Savonia',
                        originalLabel: 'Sørlige Savonia',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNF-FI-JAB',
                            label: 'Mikkeli',
                            originalLabel: 'Mikkeli',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNF-FI-JB',
                        label: 'Nordlige Savonia',
                        originalLabel: 'Nordlige Savonia',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNF-FI-JBK',
                            label: 'Kuopio',
                            originalLabel: 'Kuopio',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DNF-FI-K',
                    label: 'Karelen',
                    originalLabel: 'Karelen',
                    alternativeLabels: [],
                    note: 'Brukes for: historiske, kulturelle og administrative områder',
                    related: [],
                    children: [
                      {
                        id: '1DNF-FI-KA',
                        label: 'Sør-Karelen',
                        originalLabel: 'Sør-Karelen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNF-FI-KAB',
                            label: 'Lappeenranta',
                            originalLabel: 'Lappeenranta',
                            alternativeLabels: ['Villmanstrand'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNF-FI-KB',
                        label: 'Nord-Karelen',
                        originalLabel: 'Nord-Karelen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNF-FI-KBA',
                            label: 'Joensuu',
                            originalLabel: 'Joensuu',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DNF-FI-L',
                    label: 'Kymenlaakso',
                    originalLabel: 'Kymenlaakso',
                    alternativeLabels: ['Kymmenedalen'],
                    note: 'Brukes for: historiske, kulturelle og administrative områder',
                    related: [],
                    children: [
                      {
                        id: '1DNF-FI-LA',
                        label: 'Kouvola',
                        originalLabel: 'Kouvola',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DNF-FI-LB',
                        label: 'Kotka',
                        originalLabel: 'Kotka',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DNF-FI-M',
                    label: 'Ostrobothnia',
                    originalLabel: 'Ostrobothnia',
                    alternativeLabels: [],
                    note: 'Brukes for: historiske, kulturelle og administrative områder',
                    related: [],
                    children: [
                      {
                        id: '1DNF-FI-MA',
                        label: 'Vasa',
                        originalLabel: 'Vasa',
                        alternativeLabels: ['Vaasa'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DNF-FI-N',
                    label: 'Norra Österbotten',
                    originalLabel: 'Norra Österbotten',
                    alternativeLabels: ['Ostrobothnia'],
                    note: 'Brukes for: historiske, kulturelle og administrative områder',
                    related: [],
                    children: [
                      {
                        id: '1DNF-FI-NA',
                        label: 'Oulu',
                        originalLabel: 'Oulu',
                        alternativeLabels: ['Uleåborg'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DNF-FI-P',
                    label: 'Mellersta Österbotten',
                    originalLabel: 'Mellersta Österbotten',
                    alternativeLabels: ['Ostrobothnia'],

                    related: [],
                    children: [
                      {
                        id: '1DNF-FI-PA',
                        label: 'Kokkola',
                        originalLabel: 'Kokkola',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DNF-FI-Q',
                    label: 'Södra Österbotten',
                    originalLabel: 'Södra Österbotten',
                    alternativeLabels: ['Ostrobothnia'],
                    note: 'Brukes for: historiske, kulturelle og administrative områder',
                    related: [],
                    children: [
                      {
                        id: '1DNF-FI-QA',
                        label: 'Seinäjoki',
                        originalLabel: 'Seinäjoki',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DNF-FI-R',
                    label: 'Kainuu',
                    originalLabel: 'Kainuu',
                    alternativeLabels: ['Kajanaland'],
                    note: 'Brukes for: historiske, kulturelle og administrative områder',
                    related: [],
                    children: [
                      {
                        id: '1DNF-FI-RA',
                        label: 'Kajaani',
                        originalLabel: 'Kajaani',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DNF-FI-S',
                    label: 'Lappi',
                    originalLabel: 'Lappi',
                    alternativeLabels: [],
                    note: 'Her: finsk ‘Lappland’',
                    related: [],
                    children: [
                      {
                        id: '1DNF-FI-SA',
                        label: 'Rovaniemi',
                        originalLabel: 'Rovaniemi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DNF-FI-SB',
                        label: 'Saamelaisten kotiseutualue',
                        originalLabel: 'Saamelaisten kotiseutualue',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DNN',
                label: 'Norge',
                originalLabel: 'Norge',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DNN-NO-D',
                    label: 'Nord-Norge',
                    originalLabel: 'Nord-Norge',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DNN-NO-DA',
                        label: 'Troms og Finnmark',
                        originalLabel: 'Troms og Finnmark',
                        alternativeLabels: [],
                        note: 'Brukes for sammenslåing av Troms og Finnmark til Troms og Finnmark fylke',
                        related: [],
                        children: [
                          {
                            id: '1DNN-NO-DF',
                            label: 'Finnmark',
                            originalLabel: 'Finnmark',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DNN-NO-DFA',
                                label: 'Vardø',
                                originalLabel: 'Vardø',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-DFB',
                                label: 'Kirkenes',
                                originalLabel: 'Kirkenes',
                                alternativeLabels: [],
                                note: 'Her: Sør-Varanger',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-DFC',
                                label: 'Alta',
                                originalLabel: 'Alta',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-DFD',
                                label: 'Hammerfest',
                                originalLabel: 'Hammerfest',
                                alternativeLabels: [],
                                note: 'Her: Kvalsund',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-DFG',
                                label: 'Honningsvåg',
                                originalLabel: 'Honningsvåg',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-DFK',
                                label: 'Nordkapp',
                                originalLabel: 'Nordkapp',
                                alternativeLabels: [],
                                note: 'Her: Knivskjellodden',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-DFV',
                                label: 'Vadsø',
                                originalLabel: 'Vadsø',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DNN-NO-DT',
                            label: 'Troms',
                            originalLabel: 'Troms',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DNN-NO-DTB',
                                label: 'Harstad',
                                originalLabel: 'Harstad',
                                alternativeLabels: [],
                                note: 'Her: Hinnøya',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-DTD',
                                label: 'Finnsnes',
                                originalLabel: 'Finnsnes',
                                alternativeLabels: [],
                                note: 'Her: Senja',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-DTR',
                                label: 'Tromsø',
                                originalLabel: 'Tromsø',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DNN-NO-DL',
                        label: 'Nordland',
                        originalLabel: 'Nordland',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNN-NO-DLB',
                            label: 'Bodø',
                            originalLabel: 'Bodø',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-DLD',
                            label: 'Fauske',
                            originalLabel: 'Fauske',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-DLF',
                            label: 'Lofoten og Vesterålen',
                            originalLabel: 'Lofoten og Vesterålen',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DNN-NO-DLFA',
                                label: 'Sortland',
                                originalLabel: 'Sortland',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-DLFB',
                                label: 'Stokmarknes',
                                originalLabel: 'Stokmarknes',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-DLFC',
                                label: 'Leknes',
                                originalLabel: 'Leknes',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-DLFD',
                                label: 'Svolvær',
                                originalLabel: 'Svolvær',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DNN-NO-DLG',
                            label: 'Helgeland',
                            originalLabel: 'Helgeland',
                            alternativeLabels: [],
                            note: 'Her: Vega',
                            related: [],
                            children: [
                              {
                                id: '1DNN-NO-DLGA',
                                label: 'Mo i Rana',
                                originalLabel: 'Mo i Rana',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-DLGB',
                                label: 'Mosjøen',
                                originalLabel: 'Mosjøen',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-DLGC',
                                label: 'Sandnessjøen',
                                originalLabel: 'Sandnessjøen',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-DLGD',
                                label: 'Brønnøysund',
                                originalLabel: 'Brønnøysund',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DNN-NO-DLN',
                            label: 'Narvik',
                            originalLabel: 'Narvik',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DNN-NO-J',
                    label: 'Sørlandet',
                    originalLabel: 'Sørlandet',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DNN-NO-JB',
                        label: 'Agder',
                        originalLabel: 'Agder',
                        alternativeLabels: [],
                        note: 'Brukes for sammenslåing av Aust- og Vest-Agder til Agder fylke',
                        related: [],
                        children: [
                          {
                            id: '1DNN-NO-JA',
                            label: 'Aust-Agder',
                            originalLabel: 'Aust-Agder',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DNN-NO-JAB',
                                label: 'Grimstad',
                                originalLabel: 'Grimstad',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-JAC',
                                label: 'Tvedestrand',
                                originalLabel: 'Tvedestrand',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-JAD',
                                label: 'Risør',
                                originalLabel: 'Risør',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-JAL',
                                label: 'Arendal',
                                originalLabel: 'Arendal',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-JGL',
                                label: 'Lillesand',
                                originalLabel: 'Lillesand',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DNN-NO-JG',
                            label: 'Vest-Agder',
                            originalLabel: 'Vest-Agder',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DNN-NO-JGF',
                                label: 'Farsund',
                                originalLabel: 'Farsund',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-JGK',
                                label: 'Kristiansand',
                                originalLabel: 'Kristiansand',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-JGM',
                                label: 'Lyngdal',
                                originalLabel: 'Lyngdal',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-JGN',
                                label: 'Mandal',
                                originalLabel: 'Mandal',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-JGP',
                                label: 'Flekkefjord',
                                originalLabel: 'Flekkefjord',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DNN-NO-T',
                    label: 'Trøndelag',
                    originalLabel: 'Trøndelag',
                    alternativeLabels: [],
                    note: 'Brukes for sammenslåing av Nord- og Sør-Trøndelag til Trøndelag fylke',
                    related: [],
                    children: [
                      {
                        id: '1DNN-NO-TD',
                        label: 'Nord-Trøndelag',
                        originalLabel: 'Nord-Trøndelag',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNN-NO-TDA',
                            label: 'Levanger',
                            originalLabel: 'Levanger',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-TDB',
                            label: 'Namsos',
                            originalLabel: 'Namsos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-TDC',
                            label: 'Kolvereid',
                            originalLabel: 'Kolvereid',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-TDD',
                            label: 'Stjørdal',
                            originalLabel: 'Stjørdal',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-TDE',
                            label: 'Verdal',
                            originalLabel: 'Verdal',
                            alternativeLabels: [],
                            note: 'Her: Verdalsøra',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-TDS',
                            label: 'Steinkjer',
                            originalLabel: 'Steinkjer',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNN-NO-TR',
                        label: 'Sør-Trøndelag',
                        originalLabel: 'Sør-Trøndelag',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNN-NO-TRA',
                            label: 'Orkanger',
                            originalLabel: 'Orkanger',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-TRB',
                            label: 'Brekstad',
                            originalLabel: 'Brekstad',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-TRC',
                            label: 'Røros',
                            originalLabel: 'Røros',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-TRH',
                            label: 'Trondheim',
                            originalLabel: 'Trondheim',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DNN-NO-V',
                    label: 'Vestlandet',
                    originalLabel: 'Vestlandet',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DNN-NO-VA',
                        label: 'Vestland',
                        originalLabel: 'Vestland',
                        alternativeLabels: [],
                        note: 'Brukes for sammenslåing av Sogn og Fjordane og Hordaland til Vestland fylke',
                        related: [],
                        children: [
                          {
                            id: '1DNN-NO-VH',
                            label: 'Hordaland',
                            originalLabel: 'Hordaland',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DNN-NO-VHB',
                                label: 'Bergen',
                                originalLabel: 'Bergen',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-VHC',
                                label: 'Stord',
                                originalLabel: 'Stord',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-VHD',
                                label: 'Odda',
                                originalLabel: 'Odda',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DNN-NO-VW',
                            label: 'Sogn og Fjordane',
                            originalLabel: 'Sogn og Fjordane',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DNN-NO-VWA',
                                label: 'Florø',
                                originalLabel: 'Florø',
                                alternativeLabels: [],
                                note: 'Her: Flora',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-VWB',
                                label: 'Førde',
                                originalLabel: 'Førde',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-VWC',
                                label: 'Måløy',
                                originalLabel: 'Måløy',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DNN-NO-VM',
                        label: 'Møre og Romsdal',
                        originalLabel: 'Møre og Romsdal',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNN-NO-VMA',
                            label: 'Ålesund',
                            originalLabel: 'Ålesund',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-VMB',
                            label: 'Fosnavåg',
                            originalLabel: 'Fosnavåg',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-VMD',
                            label: 'Ulsteinvik',
                            originalLabel: 'Ulsteinvik',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-VMK',
                            label: 'Kristiansund',
                            originalLabel: 'Kristiansund',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-VML',
                            label: 'Molde',
                            originalLabel: 'Molde',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-VMN',
                            label: 'Åndalsnes',
                            originalLabel: 'Åndalsnes',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNN-NO-VR',
                        label: 'Rogaland',
                        originalLabel: 'Rogaland',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNN-NO-VRA',
                            label: 'Bryne',
                            originalLabel: 'Bryne',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-VRB',
                            label: 'Eigersund',
                            originalLabel: 'Eigersund',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-VRC',
                            label: 'Haugesund',
                            originalLabel: 'Haugesund',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-VRD',
                            label: 'Jørpeland',
                            originalLabel: 'Jørpeland',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-VRF',
                            label: 'Sandnes',
                            originalLabel: 'Sandnes',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-VRG',
                            label: 'Sauda',
                            originalLabel: 'Sauda',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-VRH',
                            label: 'Skudeneshavn',
                            originalLabel: 'Skudeneshavn',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-VRJ',
                            label: 'Kopervik',
                            originalLabel: 'Kopervik',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-VRK',
                            label: 'Åkrehamn',
                            originalLabel: 'Åkrehamn',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNN-NO-VRS',
                            label: 'Stavanger',
                            originalLabel: 'Stavanger',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DNN-NO-X',
                    label: 'Østlandet',
                    originalLabel: 'Østlandet',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DNN-NO-XC',
                        label: 'Viken',
                        originalLabel: 'Viken',
                        alternativeLabels: [],
                        note: 'Sammenslåing av Akershus, Buskerud og Østfold til Viken fylke',
                        related: [],
                        children: [
                          {
                            id: '1DNN-NO-XA',
                            label: 'Akershus',
                            originalLabel: 'Akershus',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DNN-NO-XAA',
                                label: 'Drøbak',
                                originalLabel: 'Drøbak',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XAB',
                                label: 'Lillestrøm',
                                originalLabel: 'Lillestrøm',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XAC',
                                label: 'Sandvika',
                                originalLabel: 'Sandvika',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XAD',
                                label: 'Ski',
                                originalLabel: 'Ski',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XAE',
                                label: 'Jessheim',
                                originalLabel: 'Jessheim',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DNN-NO-XB',
                            label: 'Buskerud',
                            originalLabel: 'Buskerud',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DNN-NO-XBC',
                                label: 'Hokksund',
                                originalLabel: 'Hokksund',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XBD',
                                label: 'Drammen',
                                originalLabel: 'Drammen',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XBH',
                                label: 'Hønefoss',
                                originalLabel: 'Hønefoss',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XBK',
                                label: 'Kongsberg',
                                originalLabel: 'Kongsberg',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DNN-NO-XZ',
                            label: 'Østfold',
                            originalLabel: 'Østfold',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DNN-NO-XZA',
                                label: 'Askim',
                                originalLabel: 'Askim',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XZB',
                                label: 'Mysen',
                                originalLabel: 'Mysen',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XZF',
                                label: 'Fredrikstad',
                                originalLabel: 'Fredrikstad',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XZH',
                                label: 'Halden',
                                originalLabel: 'Halden',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XZM',
                                label: 'Moss',
                                originalLabel: 'Moss',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XZS',
                                label: 'Sarpsborg',
                                originalLabel: 'Sarpsborg',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DNN-NO-XD',
                        label: 'Innlandet',
                        originalLabel: 'Innlandet',
                        alternativeLabels: [],
                        note: 'Sammenslåing av Oppland og Hedmark til Innlandet fylke',
                        related: [],
                        children: [
                          {
                            id: '1DNN-NO-XH',
                            label: 'Hedmark',
                            originalLabel: 'Hedmark',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DNN-NO-XHA',
                                label: 'Elverum',
                                originalLabel: 'Elverum',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XHB',
                                label: 'Kongsvinger',
                                originalLabel: 'Kongsvinger',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XHC',
                                label: 'Brumunddal',
                                originalLabel: 'Brumunddal',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XHD',
                                label: 'Moelv',
                                originalLabel: 'Moelv',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XHR',
                                label: 'Hamar',
                                originalLabel: 'Hamar',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DNN-NO-XP',
                            label: 'Oppland',
                            originalLabel: 'Oppland',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DNN-NO-XPA',
                                label: 'Fagernes',
                                originalLabel: 'Fagernes',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XPB',
                                label: 'Gjøvik',
                                originalLabel: 'Gjøvik',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XPC',
                                label: 'Otta',
                                originalLabel: 'Otta',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XPD',
                                label: 'Raufoss',
                                originalLabel: 'Raufoss',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XPE',
                                label: 'Vinstra',
                                originalLabel: 'Vinstra',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XPL',
                                label: 'Lillehammer',
                                originalLabel: 'Lillehammer',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DNN-NO-XF',
                        label: 'Oslofjorden',
                        originalLabel: 'Oslofjorden',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DNN-NO-XR',
                        label: 'Vestfold og Telemark',
                        originalLabel: 'Vestfold og Telemark',
                        alternativeLabels: [],
                        note: 'Sammenslåing av Vestfold og Telemark til Vestfold og Telemark fylke',
                        related: [],
                        children: [
                          {
                            id: '1DNN-NO-XT',
                            label: 'Telemark',
                            originalLabel: 'Telemark',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DNN-NO-XTA',
                                label: 'Kragerø',
                                originalLabel: 'Kragerø',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XTB',
                                label: 'Notodden',
                                originalLabel: 'Notodden',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XTC',
                                label: 'Brevik',
                                originalLabel: 'Brevik',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XTD',
                                label: 'Porsgrunn',
                                originalLabel: 'Porsgrunn',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XTE',
                                label: 'Rjukan',
                                originalLabel: 'Rjukan',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XTF',
                                label: 'Langesund',
                                originalLabel: 'Langesund',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XTG',
                                label: 'Stathelle',
                                originalLabel: 'Stathelle',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XTK',
                                label: 'Skien',
                                originalLabel: 'Skien',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DNN-NO-XV',
                            label: 'Vestfold',
                            originalLabel: 'Vestfold',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DNN-NO-XVA',
                                label: 'Holmestrand',
                                originalLabel: 'Holmestrand',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XVB',
                                label: 'Horten',
                                originalLabel: 'Horten',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XVC',
                                label: 'Stavern',
                                originalLabel: 'Stavern',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XVD',
                                label: 'Svelvik',
                                originalLabel: 'Svelvik',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XVF',
                                label: 'Åsgårdstrand',
                                originalLabel: 'Åsgårdstrand',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XVL',
                                label: 'Larvik',
                                originalLabel: 'Larvik',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XVN',
                                label: 'Sandefjord',
                                originalLabel: 'Sandefjord',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DNN-NO-XVR',
                                label: 'Tønsberg',
                                originalLabel: 'Tønsberg',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DNN-NO-XS',
                        label: 'Oslo',
                        originalLabel: 'Oslo',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DNS',
                label: 'Sverige',
                originalLabel: 'Sverige',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DNS-SE-A',
                    label: 'Svealand',
                    originalLabel: 'Svealand',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DNS-SE-AA',
                        label: 'Stockholm',
                        originalLabel: 'Stockholm',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-AAA',
                            label: 'Stockholms skjærgård',
                            originalLabel: 'Stockholms skjærgård',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-AB',
                        label: 'Uppland',
                        originalLabel: 'Uppland',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-ABA',
                            label: 'Uppsala',
                            originalLabel: 'Uppsala',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-AC',
                        label: 'Södermanland',
                        originalLabel: 'Södermanland',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-ACA',
                            label: 'Nyköping',
                            originalLabel: 'Nyköping',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-AD',
                        label: 'Närke',
                        originalLabel: 'Närke',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-ADA',
                            label: 'Örebro',
                            originalLabel: 'Örebro',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-AE',
                        label: 'Västmanland',
                        originalLabel: 'Västmanland',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-AEA',
                            label: 'Västerås',
                            originalLabel: 'Västerås',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-AF',
                        label: 'Dalarna',
                        originalLabel: 'Dalarna',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-AFA',
                            label: 'Falun',
                            originalLabel: 'Falun',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-AG',
                        label: 'Värmland',
                        originalLabel: 'Värmland',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-AGA',
                            label: 'Karlstad',
                            originalLabel: 'Karlstad',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-AH',
                        label: 'Bergslagen',
                        originalLabel: 'Bergslagen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DNS-SE-B',
                    label: 'Götaland',
                    originalLabel: 'Götaland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DNS-SE-BA',
                        label: 'Skåne',
                        originalLabel: 'Skåne',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-BAA',
                            label: 'Malmö',
                            originalLabel: 'Malmö',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNS-SE-BAB',
                            label: 'Österlen',
                            originalLabel: 'Österlen',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-BB',
                        label: 'Halland',
                        originalLabel: 'Halland',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-BBA',
                            label: 'Halmstad',
                            originalLabel: 'Halmstad',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-BC',
                        label: 'Blekinge',
                        originalLabel: 'Blekinge',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-BCA',
                            label: 'Karlskrona',
                            originalLabel: 'Karlskrona',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-BD',
                        label: 'Småland',
                        originalLabel: 'Småland',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-BDA',
                            label: 'Växjö',
                            originalLabel: 'Växjö',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DNS-SE-BDB',
                            label: 'Kalmar',
                            originalLabel: 'Kalmar',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-BE',
                        label: 'Öland',
                        originalLabel: 'Öland',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DNS-SE-BF',
                        label: 'Gotland',
                        originalLabel: 'Gotland',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-BFA',
                            label: 'Visby',
                            originalLabel: 'Visby',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-BG',
                        label: 'Östergötland',
                        originalLabel: 'Östergötland',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-BGA',
                            label: 'Linköping',
                            originalLabel: 'Linköping',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-BH',
                        label: 'Västergötland',
                        originalLabel: 'Västergötland',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DNS-SE-BJ',
                        label: 'Göteborg',
                        originalLabel: 'Göteborg',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-BJA',
                            label: 'Göteborgs skjærgård',
                            originalLabel: 'Göteborgs skjærgård',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-BK',
                        label: 'Bohuslän',
                        originalLabel: 'Bohuslän',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DNS-SE-BL',
                        label: 'Dalsland',
                        originalLabel: 'Dalsland',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DNS-SE-C',
                    label: 'Norrland',
                    originalLabel: 'Norrland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DNS-SE-CA',
                        label: 'Gästrikland',
                        originalLabel: 'Gästrikland',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-CAA',
                            label: 'Gävle',
                            originalLabel: 'Gävle',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-CB',
                        label: 'Hälsingland',
                        originalLabel: 'Hälsingland',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DNS-SE-CC',
                        label: 'Jämtland',
                        originalLabel: 'Jämtland',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-CCA',
                            label: 'Östersund',
                            originalLabel: 'Östersund',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-CD',
                        label: 'Härjedalen',
                        originalLabel: 'Härjedalen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DNS-SE-CE',
                        label: 'Medelpad',
                        originalLabel: 'Medelpad',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DNS-SE-CF',
                        label: 'Ångermanland',
                        originalLabel: 'Ångermanland',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-CFA',
                            label: 'Härnösand',
                            originalLabel: 'Härnösand',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-CG',
                        label: 'Västerbotten',
                        originalLabel: 'Västerbotten',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-CGA',
                            label: 'Umeå',
                            originalLabel: 'Umeå',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-CH',
                        label: 'Norrbotten',
                        originalLabel: 'Norrbotten',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DNS-SE-CHA',
                            label: 'Luleå',
                            originalLabel: 'Luleå',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DNS-SE-CJ',
                        label: 'Lappland',
                        originalLabel: 'Lappland',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DNS-SE-CK',
                        label: 'Tornedalen',
                        originalLabel: 'Tornedalen',
                        alternativeLabels: [],
                        note: 'Her: Meänmaa',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: '1DS',
            label: 'Sør-Europa',
            originalLabel: 'Sør-Europa',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1DSE',
                label: 'Spania',
                originalLabel: 'Spania',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DSE-ES-A',
                    label: 'Andalusía (autonom region)',
                    originalLabel: 'Andalusía (autonom region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-AA',
                        label: 'Almeria (provins)',
                        originalLabel: 'Almeria (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-AAA',
                            label: 'Almeria (by)',
                            originalLabel: 'Almeria (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-AB',
                        label: 'Cadiz (provins)',
                        originalLabel: 'Cadiz (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-ABA',
                            label: 'Cadiz (by)',
                            originalLabel: 'Cadiz (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-AC',
                        label: 'Cordoba (provins)',
                        originalLabel: 'Cordoba (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-ACA',
                            label: 'Cordoba (by)',
                            originalLabel: 'Cordoba (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-AD',
                        label: 'Granada (provins)',
                        originalLabel: 'Granada (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-ADA',
                            label: 'Granada (by)',
                            originalLabel: 'Granada (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DSE-ES-ADX',
                            label: 'Granada: reisemål',
                            originalLabel: 'Granada: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DSE-ES-ADXA',
                                label: 'Sierra Nevada',
                                originalLabel: 'Sierra Nevada',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-AE',
                        label: 'Huelva (provins)',
                        originalLabel: 'Huelva (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-AEA',
                            label: 'Huelva (by)',
                            originalLabel: 'Huelva (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-AF',
                        label: 'Jaen (provins)',
                        originalLabel: 'Jaen (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-AFA',
                            label: 'Jaen (by)',
                            originalLabel: 'Jaen (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-AG',
                        label: 'Malaga (provins)',
                        originalLabel: 'Malaga (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-AGA',
                            label: 'Malaga (by)',
                            originalLabel: 'Malaga (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DSE-ES-AGX',
                            label: 'Malaga: reisemål',
                            originalLabel: 'Malaga: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DSE-ES-AGXA',
                                label: 'Costa del Sol',
                                originalLabel: 'Costa del Sol',
                                alternativeLabels: ['Solkysten'],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-AH',
                        label: 'Sevilla (provins)',
                        originalLabel: 'Sevilla (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-AHA',
                            label: 'Sevilla (by)',
                            originalLabel: 'Sevilla (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-B',
                    label: 'Balearene (øyer)',
                    originalLabel: 'Balearene (øyer)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-BA',
                        label: 'Balearene (provins)',
                        originalLabel: 'Balearene (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-BAA',
                            label: 'Mallorca',
                            originalLabel: 'Mallorca',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DSE-ES-BAAB',
                                label: 'Palma de Mallorca',
                                originalLabel: 'Palma de Mallorca',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DSE-ES-BAB',
                            label: 'Menorca',
                            originalLabel: 'Menorca',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DSE-ES-BABA',
                                label: 'Mahon',
                                originalLabel: 'Mahon',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DSE-ES-BAC',
                            label: 'Ibiza (øy)',
                            originalLabel: 'Ibiza (øy)',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DSE-ES-BACA',
                                label: 'Ibiza (by)',
                                originalLabel: 'Ibiza (by)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DSE-ES-BAD',
                            label: 'Formentera',
                            originalLabel: 'Formentera',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-C',
                    label: 'Aragon (autonom region)',
                    originalLabel: 'Aragon (autonom region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-CA',
                        label: 'Huesca (provins)',
                        originalLabel: 'Huesca (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-CAA',
                            label: 'Huesca (by)',
                            originalLabel: 'Huesca (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-CB',
                        label: 'Teruel (provins)',
                        originalLabel: 'Teruel (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-CBA',
                            label: 'Teruel (by)',
                            originalLabel: 'Teruel (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-CC',
                        label: 'Zaragoza (provins)',
                        originalLabel: 'Zaragoza (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-CCA',
                            label: 'Zaragoza (by)',
                            originalLabel: 'Zaragoza (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-D',
                    label: 'Asturias (autonom region)',
                    originalLabel: 'Asturias (autonom region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-DA',
                        label: 'Asturias (provins)',
                        originalLabel: 'Asturias (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-DAA',
                            label: 'Oviedo',
                            originalLabel: 'Oviedo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DSE-ES-DAB',
                            label: 'Gijon',
                            originalLabel: 'Gijon',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-E',
                    label: 'Kanariøyene',
                    originalLabel: 'Kanariøyene',
                    alternativeLabels: [],
                    note: 'Use for: political and social aspects. Prefer: 1MTANC for geophysical, natural history and travel aspects. Se også 1MTANC <a href="/thema/nb/1MTANC">Kanariøyene</a>',
                    related: [
                      {
                        id: '1MTANC',
                        label: 'Kanariøyene',
                        originalLabel: 'Kanariøyene',
                        note: 'Brukes i forbindelse med geofysikk, naturhistorie og reiser, eller hvis man ikke bruker de nasjonale utvidelsene for Spania. Se også 1DSE-ES-E <a href="/thema/nb/1DSE-ES-E">Kanariøyene</a>',
                      },
                    ],
                    children: [
                      {
                        id: '1DSE-ES-EA',
                        label: 'Las Palmas (provins)',
                        originalLabel: 'Las Palmas (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-EAA',
                            label: 'Gran Canaria',
                            originalLabel: 'Gran Canaria',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DSE-ES-EAAA',
                                label: 'Las Palmas de Gran Canaria',
                                originalLabel: 'Las Palmas de Gran Canaria',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DSE-ES-EAB',
                            label: 'Fuerteventura',
                            originalLabel: 'Fuerteventura',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DSE-ES-EAC',
                            label: 'Lanzarote',
                            originalLabel: 'Lanzarote',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-EB',
                        label: 'Santa Cruz de Tenerife (provins)',
                        originalLabel: 'Santa Cruz de Tenerife (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-EBA',
                            label: 'Tenerife',
                            originalLabel: 'Tenerife',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DSE-ES-EBAA',
                                label: 'Santa Cruz de Tenerife (by)',
                                originalLabel: 'Santa Cruz de Tenerife (by)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DSE-ES-EBB',
                            label: 'El Hierro',
                            originalLabel: 'El Hierro',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DSE-ES-EBC',
                            label: 'La Gomera',
                            originalLabel: 'La Gomera',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DSE-ES-EBD',
                            label: 'La Palma',
                            originalLabel: 'La Palma',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-F',
                    label: 'Cantabria (autonom region)',
                    originalLabel: 'Cantabria (autonom region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-FA',
                        label: 'Cantabria (provins)',
                        originalLabel: 'Cantabria (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-FAA',
                            label: 'Santander',
                            originalLabel: 'Santander',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-G',
                    label: 'Castile-La Mancha (autonom region)',
                    originalLabel: 'Castile-La Mancha (autonom region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-GA',
                        label: 'Albacete (provins)',
                        originalLabel: 'Albacete (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-GAA',
                            label: 'Albacete (by)',
                            originalLabel: 'Albacete (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-GB',
                        label: 'Ciudad Real (provins)',
                        originalLabel: 'Ciudad Real (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-GBA',
                            label: 'Ciudad Real (by)',
                            originalLabel: 'Ciudad Real (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-GC',
                        label: 'Cuenca (provins)',
                        originalLabel: 'Cuenca (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-GCA',
                            label: 'Cuenca (by)',
                            originalLabel: 'Cuenca (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-GD',
                        label: 'Guadalajara (provins)',
                        originalLabel: 'Guadalajara (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-GDA',
                            label: 'Guadalajara (by)',
                            originalLabel: 'Guadalajara (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-GE',
                        label: 'Toledo (provins)',
                        originalLabel: 'Toledo (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-GEA',
                            label: 'Toledo (by)',
                            originalLabel: 'Toledo (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-H',
                    label: 'Castile and Leon (autonom region)',
                    originalLabel: 'Castile and Leon (autonom region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-HA',
                        label: 'Avila (provins)',
                        originalLabel: 'Avila (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-HAA',
                            label: 'Avila (by)',
                            originalLabel: 'Avila (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-HB',
                        label: 'Burgos (provins)',
                        originalLabel: 'Burgos (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-HBA',
                            label: 'Burgos (by)',
                            originalLabel: 'Burgos (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-HC',
                        label: 'Leon (provins)',
                        originalLabel: 'Leon (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-HCA',
                            label: 'Leon (by)',
                            originalLabel: 'Leon (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-HD',
                        label: 'Palencia (provins)',
                        originalLabel: 'Palencia (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-HDA',
                            label: 'Palencia (by)',
                            originalLabel: 'Palencia (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-HE',
                        label: 'Salamanca (provins)',
                        originalLabel: 'Salamanca (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-HEA',
                            label: 'Salamanca (by)',
                            originalLabel: 'Salamanca (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-HF',
                        label: 'Segovia (provins)',
                        originalLabel: 'Segovia (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-HFA',
                            label: 'Segovia (by)',
                            originalLabel: 'Segovia (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-HG',
                        label: 'Soria (provins)',
                        originalLabel: 'Soria (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-HGA',
                            label: 'Soria (by)',
                            originalLabel: 'Soria (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-HH',
                        label: 'Valladolid (provins)',
                        originalLabel: 'Valladolid (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-HHA',
                            label: 'Valladolid (by)',
                            originalLabel: 'Valladolid (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-HJ',
                        label: 'Zamora (provins)',
                        originalLabel: 'Zamora (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-HJA',
                            label: 'Zamora (by)',
                            originalLabel: 'Zamora (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-J',
                    label: 'Catalonia (Katalonia)',
                    originalLabel: 'Catalonia (Katalonia)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-JA',
                        label: 'Barcelona (provins)',
                        originalLabel: 'Barcelona (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-JAA',
                            label: 'Barcelona (by)',
                            originalLabel: 'Barcelona (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-JB',
                        label: 'Girona (provins)',
                        originalLabel: 'Girona (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-JBA',
                            label: 'Girona (by)',
                            originalLabel: 'Girona (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DSE-ES-JBX',
                            label: 'Girona: reisemål',
                            originalLabel: 'Girona: reisemål',
                            alternativeLabels: [],
                            note: 'Use for: physical features, historical and cultural areas or other places of regional interest or importance',
                            related: [],
                            children: [
                              {
                                id: '1DSE-ES-JBXA',
                                label: 'Costa Brava',
                                originalLabel: 'Costa Brava',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-JC',
                        label: 'Lerida / Lleida (provins)',
                        originalLabel: 'Lerida / Lleida (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-JCA',
                            label: 'Lerida / Lleida (by)',
                            originalLabel: 'Lerida / Lleida (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-JD',
                        label: 'Tarragona (provins)',
                        originalLabel: 'Tarragona (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-JDA',
                            label: 'Tarragona (by)',
                            originalLabel: 'Tarragona (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-K',
                    label: 'Extremadura (autonom region)',
                    originalLabel: 'Extremadura (autonom region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-KA',
                        label: 'Caceres (provins)',
                        originalLabel: 'Caceres (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-KAA',
                            label: 'Caceres (by)',
                            originalLabel: 'Caceres (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-KB',
                        label: 'Badajoz (provins)',
                        originalLabel: 'Badajoz (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-KBA',
                            label: 'Badajoz (by)',
                            originalLabel: 'Badajoz (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-L',
                    label: 'Galicia (autonom region)',
                    originalLabel: 'Galicia (autonom region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-LA',
                        label: 'Corunna (provins)',
                        originalLabel: 'Corunna (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-LAA',
                            label: 'Corunna (by)',
                            originalLabel: 'Corunna (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DSE-ES-LAB',
                            label: 'Santiago de Compostela',
                            originalLabel: 'Santiago de Compostela',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-LB',
                        label: 'Lugo (provins)',
                        originalLabel: 'Lugo (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-LBA',
                            label: 'Lugo (by)',
                            originalLabel: 'Lugo (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-LC',
                        label: 'Orense (provins)',
                        originalLabel: 'Orense (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-LCA',
                            label: 'Orense (by)',
                            originalLabel: 'Orense (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-LD',
                        label: 'Pontevedra (provins)',
                        originalLabel: 'Pontevedra (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-LDA',
                            label: 'Pontevedra (by)',
                            originalLabel: 'Pontevedra (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-M',
                    label: 'Madrid (region)',
                    originalLabel: 'Madrid (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-MA',
                        label: 'Madrid (provins)',
                        originalLabel: 'Madrid (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-MAA',
                            label: 'Madrid (by)',
                            originalLabel: 'Madrid (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-N',
                    label: 'Murcia (region)',
                    originalLabel: 'Murcia (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-NA',
                        label: 'Murcia (provins)',
                        originalLabel: 'Murcia (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-NAA',
                            label: 'Murcia (by)',
                            originalLabel: 'Murcia (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-Q',
                    label: 'Navarre (autonom region)',
                    originalLabel: 'Navarre (autonom region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-QA',
                        label: 'Navarre',
                        originalLabel: 'Navarre',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-QAB',
                            label: 'Pamplona',
                            originalLabel: 'Pamplona',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-R',
                    label: 'Baskerland (autonom region)',
                    originalLabel: 'Baskerland (autonom region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-RA',
                        label: 'Alava',
                        originalLabel: 'Alava',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-RAA',
                            label: 'Vitoria',
                            originalLabel: 'Vitoria',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-RB',
                        label: 'Guipuzcoa',
                        originalLabel: 'Guipuzcoa',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-RBA',
                            label: 'San Sebastian',
                            originalLabel: 'San Sebastian',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-RC',
                        label: 'Bizkaia',
                        originalLabel: 'Bizkaia',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-RCA',
                            label: 'Bilbao',
                            originalLabel: 'Bilbao',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-S',
                    label: 'La Rioja (autonom region)',
                    originalLabel: 'La Rioja (autonom region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-SA',
                        label: 'Logroño (provins)',
                        originalLabel: 'Logroño (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-SAA',
                            label: 'Logroño (by)',
                            originalLabel: 'Logroño (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-T',
                    label: 'Valencia (region)',
                    originalLabel: 'Valencia (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-TA',
                        label: 'Alicante (provins)',
                        originalLabel: 'Alicante (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-TAA',
                            label: 'Alicante (by)',
                            originalLabel: 'Alicante (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DSE-ES-TAX',
                            label: 'Alicante: reisemål',
                            originalLabel: 'Alicante: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1DSE-ES-TAXA',
                                label: 'Costa Blanca',
                                originalLabel: 'Costa Blanca',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-TB',
                        label: 'Castellon (provins)',
                        originalLabel: 'Castellon (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-TBA',
                            label: 'Castellon (by)',
                            originalLabel: 'Castellon (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DSE-ES-TC',
                        label: 'Valencia (provins)',
                        originalLabel: 'Valencia (provins)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DSE-ES-TCA',
                            label: 'Valencia (by)',
                            originalLabel: 'Valencia (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DSE-ES-U',
                    label: 'Ceuta (autonom by)',
                    originalLabel: 'Ceuta (autonom by)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DSE-ES-V',
                    label: 'Melilla (autonom by)',
                    originalLabel: 'Melilla (autonom by)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DSE-ES-X',
                    label: 'Spain: reisemål',
                    originalLabel: 'Spain: reisemål',
                    alternativeLabels: [],
                    note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                    related: [],
                    children: [
                      {
                        id: '1DSE-ES-XA',
                        label: 'Pilegrimsveien til Santiago de Compostela',
                        originalLabel:
                          'Pilegrimsveien til Santiago de Compostela',
                        alternativeLabels: [],
                        note: 'Her: El Camino de Santiago. Brukes med kvalifikatorer for sted for bøker som tar for seg deler av pilegrimsveien',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DSE-ES-XB',
                        label: 'La Mancha',
                        originalLabel: 'La Mancha',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DSE-ES-XC',
                        label: 'De kantabriske fjell',
                        originalLabel: 'De kantabriske fjell',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DSG',
                label: 'Gibraltar',
                originalLabel: 'Gibraltar',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1DSM',
                label: 'Malta',
                originalLabel: 'Malta',
                alternativeLabels: [],
                note: 'Her: Valetta, Comino',
                related: [],
                children: [
                  {
                    id: '1DSM-MT-G',
                    label: 'Gozo',
                    originalLabel: 'Gozo',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DSN',
                label: 'Andorra',
                originalLabel: 'Andorra',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1DSP',
                label: 'Portugal',
                originalLabel: 'Portugal',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DSP-PT-B',
                    label: 'Norte region (Nord-Portugal)',
                    originalLabel: 'Norte region (Nord-Portugal)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSP-PT-BP',
                        label: 'Porto',
                        originalLabel: 'Porto',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DSP-PT-C',
                    label: 'Centro region (Sentrale Portugal)',
                    originalLabel: 'Centro region (Sentrale Portugal)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DSP-PT-L',
                    label: 'Lisboa (region)',
                    originalLabel: 'Lisboa (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSP-PT-LL',
                        label: 'Lisboa',
                        originalLabel: 'Lisboa',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DSP-PT-LS',
                        label: 'Sintra',
                        originalLabel: 'Sintra',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DSP-PT-N',
                    label: 'Alentejo',
                    originalLabel: 'Alentejo',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DSP-PT-P',
                    label: 'Algarve',
                    originalLabel: 'Algarve',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DSP-PT-PF',
                        label: 'Faro',
                        originalLabel: 'Faro',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DSP-PT-R',
                    label: 'Madeira (autonom region)',
                    originalLabel: 'Madeira (autonom region)',
                    alternativeLabels: [],
                    note: 'Use for: political and social aspects. Prefer: 1MTANM for geophysical, natural history and travel aspects',
                    related: [],
                    children: [
                      {
                        id: '1DSP-PT-RF',
                        label: 'Funchal',
                        originalLabel: 'Funchal',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DSP-PT-T',
                    label: 'Azores (autonom region)',
                    originalLabel: 'Azores (autonom region)',
                    alternativeLabels: [],
                    note: 'Use for: political and social aspects. Prefer: 1MTANZ for geophysical, natural history and travel aspects',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DST',
                label: 'Italia',
                originalLabel: 'Italia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DST-IT-N',
                    label: 'Nord-Italia',
                    originalLabel: 'Nord-Italia',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DST-IT-NA',
                        label: 'Aostadalen',
                        originalLabel: 'Aostadalen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DST-IT-NF',
                        label: 'Friuli Venezia Giulia',
                        originalLabel: 'Friuli Venezia Giulia',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-NFG',
                            label: 'Gorizia',
                            originalLabel: 'Gorizia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NFP',
                            label: 'Pordenone',
                            originalLabel: 'Pordenone',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NFT',
                            label: 'Trieste',
                            originalLabel: 'Trieste',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NFU',
                            label: 'Udine',
                            originalLabel: 'Udine',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-NG',
                        label: 'Liguria',
                        originalLabel: 'Liguria',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-NGA',
                            label: 'Genova',
                            originalLabel: 'Genova',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NGM',
                            label: 'Imperia',
                            originalLabel: 'Imperia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NGS',
                            label: 'Savona',
                            originalLabel: 'Savona',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NGZ',
                            label: 'La Spezia',
                            originalLabel: 'La Spezia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-NL',
                        label: 'Lombardia',
                        originalLabel: 'Lombardia',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-NLA',
                            label: 'Varese',
                            originalLabel: 'Varese',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NLB',
                            label: 'Bergamo',
                            originalLabel: 'Bergamo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NLC',
                            label: 'Como',
                            originalLabel: 'Como',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NLD',
                            label: 'Lodi',
                            originalLabel: 'Lodi',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NLE',
                            label: 'Lecco',
                            originalLabel: 'Lecco',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NLM',
                            label: 'Milano',
                            originalLabel: 'Milano',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NLN',
                            label: 'Sondrio',
                            originalLabel: 'Sondrio',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NLR',
                            label: 'Cremona',
                            originalLabel: 'Cremona',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NLS',
                            label: 'Brescia',
                            originalLabel: 'Brescia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NLT',
                            label: 'Mantova',
                            originalLabel: 'Mantova',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NLV',
                            label: 'Pavia',
                            originalLabel: 'Pavia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NLZ',
                            label: 'Monza og Brianza',
                            originalLabel: 'Monza og Brianza',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-NP',
                        label: 'Piemonte',
                        originalLabel: 'Piemonte',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-NPA',
                            label: 'Alessandria',
                            originalLabel: 'Alessandria',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NPL',
                            label: 'Biella',
                            originalLabel: 'Biella',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NPN',
                            label: 'Novara',
                            originalLabel: 'Novara',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NPR',
                            label: 'Vercelli',
                            originalLabel: 'Vercelli',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NPS',
                            label: 'Asti',
                            originalLabel: 'Asti',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NPT',
                            label: 'Torino',
                            originalLabel: 'Torino',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NPU',
                            label: 'Cuneo',
                            originalLabel: 'Cuneo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NPV',
                            label: 'Verbano Cusio Ossola (provins)',
                            originalLabel: 'Verbano Cusio Ossola (provins)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-NT',
                        label: 'Trentino-Sør-Tyrol',
                        originalLabel: 'Trentino-Sør-Tyrol',
                        alternativeLabels: ['Trentino Alto Adige'],
                        note: 'Her: Sør-Tyrol',
                        related: [],
                        children: [
                          {
                            id: '1DST-IT-NTR',
                            label: 'Trento',
                            originalLabel: 'Trento',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NTZ',
                            label: 'Bolzano',
                            originalLabel: 'Bolzano',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-NV',
                        label: 'Veneto',
                        originalLabel: 'Veneto',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-NVD',
                            label: 'Padova',
                            originalLabel: 'Padova',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NVE',
                            label: 'Venezia',
                            originalLabel: 'Venezia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NVN',
                            label: 'Verona',
                            originalLabel: 'Verona',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NVR',
                            label: 'Rovigo',
                            originalLabel: 'Rovigo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NVS',
                            label: 'Treviso',
                            originalLabel: 'Treviso',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NVU',
                            label: 'Belluno',
                            originalLabel: 'Belluno',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NVZ',
                            label: 'Vicenza',
                            originalLabel: 'Vicenza',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-NZ',
                        label: 'Nord-Italia: reisemål',
                        originalLabel: 'Nord-Italia: reisemål',
                        alternativeLabels: [],
                        note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                        related: [],
                        children: [
                          {
                            id: '1DST-IT-NZB',
                            label: 'De nordlige Appenninene',
                            originalLabel: 'De nordlige Appenninene',
                            alternativeLabels: [],
                            note: 'Foretrukket kode: brukes isteden for 1DZT-IT-A',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NZD',
                            label: 'Dolomittene',
                            originalLabel: 'Dolomittene',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-NZL',
                            label: 'Italienske innsjøer',
                            originalLabel: 'Italienske innsjøer',
                            alternativeLabels: [],
                            note: 'Her: Ortasjøen, Luganosjøen, Idrosjøen',
                            related: [],
                            children: [
                              {
                                id: '1DST-IT-NZLC',
                                label: 'Comosjøen',
                                originalLabel: 'Comosjøen',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DST-IT-NZLG',
                                label: 'Gardasjøen',
                                originalLabel: 'Gardasjøen',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DST-IT-NZLM',
                                label: 'Maggioresjøen',
                                originalLabel: 'Maggioresjøen',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DST-IT-NZLQ',
                                label: 'Iseosjøen',
                                originalLabel: 'Iseosjøen',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DST-IT-NZP',
                            label: 'Po og sideelver',
                            originalLabel: 'Po og sideelver',
                            alternativeLabels: [],
                            note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet. Foretrukket kode: brukes istedenfor 1DZA-IT-A',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DST-IT-T',
                    label: 'Sentrale Italia',
                    originalLabel: 'Sentrale Italia',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DST-IT-TG',
                        label: 'Emilia Romagna',
                        originalLabel: 'Emilia Romagna',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-TGB',
                            label: 'Bologna',
                            originalLabel: 'Bologna',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TGC',
                            label: 'Forlì-Cesena',
                            originalLabel: 'Forlì-Cesena',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TGF',
                            label: 'Ferrara',
                            originalLabel: 'Ferrara',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TGM',
                            label: 'Modena',
                            originalLabel: 'Modena',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TGN',
                            label: 'Rimini',
                            originalLabel: 'Rimini',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TGP',
                            label: 'Parma',
                            originalLabel: 'Parma',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TGR',
                            label: 'Reggio Emilia',
                            originalLabel: 'Reggio Emilia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TGV',
                            label: 'Ravenna',
                            originalLabel: 'Ravenna',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TGZ',
                            label: 'Piacenza',
                            originalLabel: 'Piacenza',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-TM',
                        label: 'Marche',
                        originalLabel: 'Marche',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-TMA',
                            label: 'Ancona',
                            originalLabel: 'Ancona',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TMF',
                            label: 'Fermo',
                            originalLabel: 'Fermo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TMP',
                            label: 'Pesaro og Urbino',
                            originalLabel: 'Pesaro og Urbino',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TMS',
                            label: 'Ascoli Piceno',
                            originalLabel: 'Ascoli Piceno',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TMT',
                            label: 'Macerata',
                            originalLabel: 'Macerata',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-TR',
                        label: 'Umbria',
                        originalLabel: 'Umbria',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-TRP',
                            label: 'Perugia',
                            originalLabel: 'Perugia',
                            alternativeLabels: [],
                            note: 'Class here: Assisi',
                            related: [],
                            children: [
                              {
                                id: '1DST-IT-TRPT',
                                label: 'Trasimeno',
                                originalLabel: 'Trasimeno',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DST-IT-TRT',
                            label: 'Terni',
                            originalLabel: 'Terni',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-TS',
                        label: 'Toscana',
                        originalLabel: 'Toscana',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-TSA',
                            label: 'Arezzo',
                            originalLabel: 'Arezzo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TSE',
                            label: 'Siena',
                            originalLabel: 'Siena',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DST-IT-TSEB',
                                label: 'Val d’Orcia',
                                originalLabel: 'Val d’Orcia',
                                alternativeLabels: [],
                                note: 'Foretrukket kode: brukes istedenfor 1DZT-IT-N',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DST-IT-TSF',
                            label: 'Firenze',
                            originalLabel: 'Firenze',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TSG',
                            label: 'Grosseto',
                            originalLabel: 'Grosseto',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TSL',
                            label: 'Livorno',
                            originalLabel: 'Livorno',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DST-IT-TSLB',
                                label: 'Elba',
                                originalLabel: 'Elba',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DST-IT-TSM',
                            label: 'Massa-Carrara',
                            originalLabel: 'Massa-Carrara',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TSP',
                            label: 'Pisa',
                            originalLabel: 'Pisa',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TSR',
                            label: 'Prato',
                            originalLabel: 'Prato',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TST',
                            label: 'Pistoia',
                            originalLabel: 'Pistoia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TSU',
                            label: 'Lucca',
                            originalLabel: 'Lucca',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-TX',
                        label: 'Sentrale Italia: reisemål',
                        originalLabel: 'Sentrale Italia: reisemål',
                        alternativeLabels: [],
                        note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                        related: [],
                        children: [
                          {
                            id: '1DST-IT-TXA',
                            label: 'Arno og sideelver',
                            originalLabel: 'Arno og sideelver',
                            alternativeLabels: [],
                            note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet. Foretrukket kode: brukes istedenfor 1DZA-IT-C',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TXC',
                            label: 'Tiber og sideelver',
                            originalLabel: 'Tiber og sideelver',
                            alternativeLabels: [],
                            note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet. Foretrukket kode: brukes istedenfor 1DZA-IT-E',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TXF',
                            label: 'De sentrale Appenninene',
                            originalLabel: 'De sentrale Appenninene',
                            alternativeLabels: [],
                            note: 'Foretrukket kode: brukes istedenfor 1DZT-IT-B',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TXM',
                            label: 'Maremma',
                            originalLabel: 'Maremma',
                            alternativeLabels: [],
                            note: 'Foretrukket kode: brukes istedenfor 1DZT-IT-L',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-TZ',
                        label: 'Lazio',
                        originalLabel: 'Lazio',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-TZF',
                            label: 'Frosinone',
                            originalLabel: 'Frosinone',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TZL',
                            label: 'Latina',
                            originalLabel: 'Latina',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TZR',
                            label: 'Roma',
                            originalLabel: 'Roma',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TZT',
                            label: 'Rieto',
                            originalLabel: 'Rieto',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-TZV',
                            label: 'Viterbo',
                            originalLabel: 'Viterbo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DST-IT-U',
                    label: 'Sør-Italia og øyene',
                    originalLabel: 'Sør-Italia og øyene',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DST-IT-UA',
                        label: 'Abruzzo',
                        originalLabel: 'Abruzzo',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-UAH',
                            label: 'Chieti',
                            originalLabel: 'Chieti',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UAP',
                            label: 'Pescara',
                            originalLabel: 'Pescara',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UAQ',
                            label: 'L’Aquila',
                            originalLabel: 'L’Aquila',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UAT',
                            label: 'Teramo',
                            originalLabel: 'Teramo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-UC',
                        label: 'Calabria',
                        originalLabel: 'Calabria',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-UCK',
                            label: 'Crotone',
                            originalLabel: 'Crotone',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UCR',
                            label: 'Reggio Calabria',
                            originalLabel: 'Reggio Calabria',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UCS',
                            label: 'Cosenza',
                            originalLabel: 'Cosenza',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UCV',
                            label: 'Vibo Valentia',
                            originalLabel: 'Vibo Valentia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UCZ',
                            label: 'Catanzaro',
                            originalLabel: 'Catanzaro',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-UD',
                        label: 'Sardinia',
                        originalLabel: 'Sardinia',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-UDC',
                            label: 'Cagliari',
                            originalLabel: 'Cagliari',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UDG',
                            label: 'Ogliastra (provins)',
                            originalLabel: 'Ogliastra (provins)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UDM',
                            label: 'Medio Campidano (provins)',
                            originalLabel: 'Medio Campidano (provins)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UDN',
                            label: 'Carbonia-Iglesias',
                            originalLabel: 'Carbonia-Iglesias',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UDR',
                            label: 'Oristano',
                            originalLabel: 'Oristano',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UDS',
                            label: 'Sassari',
                            originalLabel: 'Sassari',
                            alternativeLabels: [],
                            note: 'Her: Maddalenaøyene',
                            related: [],
                            children: [
                              {
                                id: '1DST-IT-UDSD',
                                label: 'Costa Smeralda',
                                originalLabel: 'Costa Smeralda',
                                alternativeLabels: [],
                                note: 'Foretrukket kode: brukes istedenfor 1DZT-IT-T',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DST-IT-UDT',
                            label: 'Olbia Tempio',
                            originalLabel: 'Olbia Tempio',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UDU',
                            label: 'Nuoro',
                            originalLabel: 'Nuoro',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-UE',
                        label: 'Molise',
                        originalLabel: 'Molise',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-UEC',
                            label: 'Campobasso',
                            originalLabel: 'Campobasso',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UES',
                            label: 'Isernia',
                            originalLabel: 'Isernia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-UL',
                        label: 'Sicilia',
                        originalLabel: 'Sicilia',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-ULA',
                            label: 'Agrigento',
                            originalLabel: 'Agrigento',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-ULC',
                            label: 'Caltanissetta',
                            originalLabel: 'Caltanissetta',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-ULE',
                            label: 'Enna',
                            originalLabel: 'Enna',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-ULM',
                            label: 'Messina',
                            originalLabel: 'Messina',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DST-IT-ULME',
                                label: 'De eoliske øyer',
                                originalLabel: 'De eoliske øyer',
                                alternativeLabels: [],
                                note: 'Her: Stromboli, Lipari, Vulcano, Panarea, Alicudi, Filicudi',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DST-IT-ULN',
                            label: 'Trapani',
                            originalLabel: 'Trapani',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DST-IT-ULND',
                                label: 'De egadiske øyer',
                                originalLabel: 'De egadiske øyer',
                                alternativeLabels: [],
                                note: 'Her: Favignana, Levanzo, Marettimo',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DST-IT-ULP',
                            label: 'Palermo',
                            originalLabel: 'Palermo',
                            alternativeLabels: [],
                            note: 'Class here: Ustica',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-ULR',
                            label: 'Ragusa',
                            originalLabel: 'Ragusa',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-ULS',
                            label: 'Siracusa',
                            originalLabel: 'Siracusa',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-ULT',
                            label: 'Catania',
                            originalLabel: 'Catania',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DST-IT-ULTE',
                                label: 'Etna',
                                originalLabel: 'Etna',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-UM',
                        label: 'Campania',
                        originalLabel: 'Campania',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-UML',
                            label: 'Avellino',
                            originalLabel: 'Avellino',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UMN',
                            label: 'Napoli',
                            originalLabel: 'Napoli',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DST-IT-UMNC',
                                label: 'Capri / Ischia',
                                originalLabel: 'Capri / Ischia',
                                alternativeLabels: [],
                                note: 'Her: Anacapri, Procida, De flegreiske øyene',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DST-IT-UMNP',
                                label: 'Pompeii / Herculaneum',
                                originalLabel: 'Pompeii / Herculaneum',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DST-IT-UMNV',
                                label: 'Vesuv',
                                originalLabel: 'Vesuv',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DST-IT-UMS',
                            label: 'Salerno',
                            originalLabel: 'Salerno',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DST-IT-UMSC',
                                label: 'Amalfikysten',
                                originalLabel: 'Amalfikysten',
                                alternativeLabels: [],
                                note: 'Foretrukket kode: brukes istedenfor 1DZT-IT-R',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DST-IT-UMT',
                            label: 'Caserta',
                            originalLabel: 'Caserta',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UMV',
                            label: 'Benevento',
                            originalLabel: 'Benevento',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-UP',
                        label: 'Apulia',
                        originalLabel: 'Apulia',
                        alternativeLabels: [],
                        note: 'Her: Puglia',
                        related: [],
                        children: [
                          {
                            id: '1DST-IT-UPA',
                            label: 'Bari',
                            originalLabel: 'Bari',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UPG',
                            label: 'Foggia',
                            originalLabel: 'Foggia',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DST-IT-UPGB',
                                label: 'Tavoliere delle Puglie',
                                originalLabel: 'Tavoliere delle Puglie',
                                alternativeLabels: [],
                                note: 'Foretrukket kode: brukes istedenfor 1DZT-IT-P',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DST-IT-UPGT',
                                label: 'Tremiti',
                                originalLabel: 'Tremiti',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DST-IT-UPL',
                            label: 'Lecce',
                            originalLabel: 'Lecce',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UPR',
                            label: 'Barletta Andria Trani',
                            originalLabel: 'Barletta Andria Trani',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UPS',
                            label: 'Brindisi',
                            originalLabel: 'Brindisi',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-UPT',
                            label: 'Taranto',
                            originalLabel: 'Taranto',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-US',
                        label: 'Basilicata',
                        originalLabel: 'Basilicata',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-USM',
                            label: 'Matera',
                            originalLabel: 'Matera',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-USP',
                            label: 'Potenza',
                            originalLabel: 'Potenza',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-UZ',
                        label: 'Sør-Italia: reisemål',
                        originalLabel: 'Sør-Italia: reisemål',
                        alternativeLabels: [],
                        note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                        related: [],
                        children: [
                          {
                            id: '1DST-IT-UZC',
                            label: 'De sørlige Appenninene',
                            originalLabel: 'De sørlige Appenninene',
                            alternativeLabels: [],
                            note: 'Foretrukket kode: brukes istedenfor 1DZT-IT-C',
                            related: [],
                            children: [
                              {
                                id: '1DST-IT-UZCE',
                                label: 'Gran Sasso d’Italia',
                                originalLabel: 'Gran Sasso d’Italia',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DST-IT-UZCP',
                                label: 'Pollino',
                                originalLabel: 'Pollino',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DST-IT-UZD',
                            label: 'Sila',
                            originalLabel: 'Sila',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DST-IT-X',
                    label: 'Historiske og kulturelle områder i Italia',
                    originalLabel: 'Historiske og kulturelle områder i Italia',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DST-IT-XN',
                        label: 'Historiske og kulturelle områder: Nord-Italia',
                        originalLabel:
                          'Historiske og kulturelle områder: Nord-Italia',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-XNA',
                            label: 'Langhe',
                            originalLabel: 'Langhe',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XNC',
                            label: 'Monferrato',
                            originalLabel: 'Monferrato',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XNE',
                            label: 'Canavese',
                            originalLabel: 'Canavese',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XNF',
                            label: 'Cinque Terre',
                            originalLabel: 'Cinque Terre',
                            alternativeLabels: ['5 terre'],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XNH',
                            label: 'Tigullio',
                            originalLabel: 'Tigullio',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XNL',
                            label: 'Franciacorta',
                            originalLabel: 'Franciacorta',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XNN',
                            label: 'Lomellina',
                            originalLabel: 'Lomellina',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XNP',
                            label: 'Polesine',
                            originalLabel: 'Polesine',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XNR',
                            label: 'Carnia',
                            originalLabel: 'Carnia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-XT',
                        label:
                          'Historiske og kulturelle områder: Sentrale Italia',
                        originalLabel:
                          'Historiske og kulturelle områder: Sentrale Italia',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-XTA',
                            label: 'Lunigiana',
                            originalLabel: 'Lunigiana',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XTC',
                            label: 'Garfagnana',
                            originalLabel: 'Garfagnana',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XTE',
                            label: 'Chianti',
                            originalLabel: 'Chianti',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XTG',
                            label: 'Mugello',
                            originalLabel: 'Mugello',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XTL',
                            label: 'Versilia',
                            originalLabel: 'Versilia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XTN',
                            label: 'Ciociaria',
                            originalLabel: 'Ciociaria',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XTT',
                            label: 'Tuscia',
                            originalLabel: 'Tuscia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DST-IT-XU',
                        label:
                          'Historiske og kulturelle områder: Sør-Italia og øyene',
                        originalLabel:
                          'Historiske og kulturelle områder: Sør-Italia og øyene',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DST-IT-XUA',
                            label: 'Cilento',
                            originalLabel: 'Cilento',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XUC',
                            label: 'Irpinia',
                            originalLabel: 'Irpinia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XUE',
                            label: 'Sannio',
                            originalLabel: 'Sannio',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XUG',
                            label: 'Salento',
                            originalLabel: 'Salento',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DST-IT-XUL',
                            label: 'Gallura',
                            originalLabel: 'Gallura',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DSU',
                label: 'San Marino',
                originalLabel: 'San Marino',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1DSV',
                label: 'Vatikanstaten',
                originalLabel: 'Vatikanstaten',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '1DT',
            label: 'Øst-Europa',
            originalLabel: 'Øst-Europa',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1DTA',
                label: 'Russland',
                originalLabel: 'Russland',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DTA-RU-B',
                    label: 'Russland: den sentrale føderasjonskretsen',
                    originalLabel: 'Russland: den sentrale føderasjonskretsen',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DTA-RU-BK',
                        label: 'Kursk',
                        originalLabel: 'Kursk',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTA-RU-BM',
                        label: 'Moskva',
                        originalLabel: 'Moskva',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTA-RU-BS',
                        label: 'Smolensk',
                        originalLabel: 'Smolensk',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTA-RU-D',
                    label: 'Russland: Den sørlige føderasjonskretsen',
                    originalLabel: 'Russland: Den sørlige føderasjonskretsen',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DTA-RU-DB',
                        label: 'Volgograd',
                        originalLabel: 'Volgograd',
                        alternativeLabels: [],
                        note: 'Her: Stalingrad',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTA-RU-DD',
                        label: 'Rostov',
                        originalLabel: 'Rostov',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTA-RU-F',
                    label: 'Russland: Den nordvestlige føderasjonskretsen',
                    originalLabel:
                      'Russland: Den nordvestlige føderasjonskretsen',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DTA-RU-FA',
                        label: 'Arkhangelsk',
                        originalLabel: 'Arkhangelsk',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTA-RU-FC',
                        label: 'Kaliningrad',
                        originalLabel: 'Kaliningrad',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTA-RU-FD',
                        label: 'Novgorod',
                        originalLabel: 'Novgorod',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTA-RU-FP',
                        label: 'St. Petersburg',
                        originalLabel: 'St. Petersburg',
                        alternativeLabels: ['Petrograd'],
                        note: 'Her: Leningrad',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTA-RU-H',
                    label: 'Russland: Fjernøstkretsene',
                    originalLabel: 'Russland: Fjernøstkretsene',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTA-RU-J',
                    label: 'Russland: Sibirkretsene',
                    originalLabel: 'Russland: Sibirkretsene',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DTA-RU-JZ',
                        label: 'Sibir: reisemål',
                        originalLabel: 'Sibir: reisemål',
                        alternativeLabels: [],
                        note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                        related: [],
                        children: [
                          {
                            id: '1DTA-RU-JZB',
                            label: 'Sibir: Baikalsjøen',
                            originalLabel: 'Sibir: Baikalsjøen',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DTA-RU-L',
                    label: 'Russland: Uralkretsene',
                    originalLabel: 'Russland: Uralkretsene',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTA-RU-N',
                    label: 'Russland: Volgakretsen',
                    originalLabel: 'Russland: Volgakretsen',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DTA-RU-NN',
                        label: 'Nizjnij Novgorod',
                        originalLabel: 'Nizjnij Novgorod',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTA-RU-P',
                    label: 'Russland: Den nordkaukasiske føderasjonskretsen',
                    originalLabel:
                      'Russland: Den nordkaukasiske føderasjonskretsen',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DTA-RU-PC',
                        label: 'Tsjetsjenia',
                        originalLabel: 'Tsjetsjenia',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DTB',
                label: 'Belarus',
                originalLabel: 'Belarus',
                alternativeLabels: ['Hviterussland'],
                note: 'Her: Brest, Litovsk',
                related: [],
                children: [
                  {
                    id: '1DTB-BY-M',
                    label: 'Minsk',
                    originalLabel: 'Minsk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DTD',
                label: 'Latvia',
                originalLabel: 'Latvia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DTD-LV-R',
                    label: 'Riga',
                    originalLabel: 'Riga',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DTE',
                label: 'Estland',
                originalLabel: 'Estland',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DTE-EE-T',
                    label: 'Tallinn',
                    originalLabel: 'Tallinn',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DTF',
                label: 'Litauen',
                originalLabel: 'Litauen',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DTF-LT-V',
                    label: 'Vilnius',
                    originalLabel: 'Vilnius',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DTG',
                label: 'Georgia',
                originalLabel: 'Georgia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DTG-GE-T',
                    label: 'Tblisi',
                    originalLabel: 'Tblisi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DTH',
                label: 'Ungarn',
                originalLabel: 'Ungarn',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DTH-HU-B',
                    label: 'Sentrale Ungarn',
                    originalLabel: 'Sentrale Ungarn',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DTH-HU-BB',
                        label: 'Budapest',
                        originalLabel: 'Budapest',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTH-HU-D',
                    label: 'Transdanubia',
                    originalLabel: 'Transdanubia',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DTH-HU-DB',
                        label: 'Balatonsjøen',
                        originalLabel: 'Balatonsjøen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTH-HU-DD',
                        label: 'Neusiedler See / Ferto',
                        originalLabel: 'Neusiedler See / Ferto',
                        alternativeLabels: [],
                        note: 'Brukes med 1DFA* dersom boka handler om Østerrike',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTH-HU-DF',
                        label: 'Mecsek',
                        originalLabel: 'Mecsek',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTH-HU-DG',
                        label: 'De transdonauske mellomfjellene',
                        originalLabel: 'De transdonauske mellomfjellene',
                        alternativeLabels: [],
                        note: 'Her: Bakony',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTH-HU-DH',
                        label: 'Den lille ungarske slette',
                        originalLabel: 'Den lille ungarske slette',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTH-HU-DP',
                        label: 'Esztergom',
                        originalLabel: 'Esztergom',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTH-HU-DQ',
                        label: 'Győr',
                        originalLabel: 'Győr',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTH-HU-DR',
                        label: 'Pécs',
                        originalLabel: 'Pécs',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTH-HU-DS',
                        label: 'Sopron',
                        originalLabel: 'Sopron',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTH-HU-DT',
                        label: 'Székesfehérvár',
                        originalLabel: 'Székesfehérvár',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTH-HU-DV',
                        label: 'Veszprém',
                        originalLabel: 'Veszprém',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTH-HU-F',
                    label: 'Den nordlige store sletta',
                    originalLabel: 'Den nordlige store sletta',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DTH-HU-FD',
                        label: 'De nordungarske mellomfjellene',
                        originalLabel: 'De nordungarske mellomfjellene',
                        alternativeLabels: [],
                        note: 'Her: Mátra, Bükk',
                        related: [],
                        children: [
                          {
                            id: '1DTH-HU-FDE',
                            label: 'Eger',
                            originalLabel: 'Eger',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DTH-HU-FP',
                        label: 'Den ungarske slette',
                        originalLabel: 'Den ungarske slette',
                        alternativeLabels: [],
                        note: 'Her: Store Alföld',
                        related: [],
                        children: [
                          {
                            id: '1DTH-HU-FPD',
                            label: 'Hortobágy nasjonalpark',
                            originalLabel: 'Hortobágy nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DTH-HU-FPJ',
                            label: 'Debrecen',
                            originalLabel: 'Debrecen',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DTH-HU-FPK',
                            label: 'Szeged',
                            originalLabel: 'Szeged',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DTH-HU-FPW',
                            label: 'Tiszasjøen',
                            originalLabel: 'Tiszasjøen',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DTH-HU-Z',
                    label: 'Ungarn: reisemål',
                    originalLabel: 'Ungarn: reisemål',
                    alternativeLabels: [],
                    note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                    related: [],
                    children: [
                      {
                        id: '1DTH-HU-ZD',
                        label: 'Donau-Tisza platålandskap',
                        originalLabel: 'Donau-Tisza platålandskap',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTH-HU-ZT',
                        label: 'Transtisza / Tiszántúl',
                        originalLabel: 'Transtisza / Tiszántúl',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DTJ',
                label: 'Tsjekkia',
                originalLabel: 'Tsjekkia',
                alternativeLabels: ['Tsjekkoslovakia'],
                note: 'Her: Den tsjekkiske republikk',
                related: [],
                children: [
                  {
                    id: '1DTJ-CZ-A',
                    label: 'Praha',
                    originalLabel: 'Praha',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTJ-CZ-B',
                    label: 'Sør-Mähren (region)',
                    originalLabel: 'Sør-Mähren (region)',
                    alternativeLabels: ['Moravia', 'Morava'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTJ-CZ-C',
                    label: 'Sør-Böhmen (region)',
                    originalLabel: 'Sør-Böhmen (region)',
                    alternativeLabels: ['Čechy'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTJ-CZ-E',
                    label: 'Pardubice (region)',
                    originalLabel: 'Pardubice (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTJ-CZ-H',
                    label: 'Hradec Králové (region)',
                    originalLabel: 'Hradec Králové (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTJ-CZ-J',
                    label: 'Vysočina (region)',
                    originalLabel: 'Vysočina (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTJ-CZ-K',
                    label: 'Karlovy Vary (region)',
                    originalLabel: 'Karlovy Vary (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTJ-CZ-L',
                    label: 'Liberec (region)',
                    originalLabel: 'Liberec (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTJ-CZ-M',
                    label: 'Olomouc (region)',
                    originalLabel: 'Olomouc (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTJ-CZ-P',
                    label: 'Plzeň (region)',
                    originalLabel: 'Plzeň (region)',
                    alternativeLabels: ['Pilsen'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTJ-CZ-S',
                    label: 'Sentral-Böhmen (region)',
                    originalLabel: 'Sentral-Böhmen (region)',
                    alternativeLabels: ['Čechy'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTJ-CZ-T',
                    label: 'Mähren-Schlesien (region)',
                    originalLabel: 'Mähren-Schlesien (region)',
                    alternativeLabels: ['Moravia', 'Morava', 'Slezko'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTJ-CZ-U',
                    label: 'Ústí nad Labem (region)',
                    originalLabel: 'Ústí nad Labem (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTJ-CZ-X',
                    label: 'Tsjekkia: reisemål',
                    originalLabel: 'Tsjekkia: reisemål',
                    alternativeLabels: [],
                    note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                    related: [],
                    children: [
                      {
                        id: '1DTJ-CZ-XB',
                        label: 'Böhmen',
                        originalLabel: 'Böhmen',
                        alternativeLabels: ['Čechy'],

                        related: [],
                        children: [
                          {
                            id: '1DTJ-CZ-XBF',
                            label: 'Böhmerwald',
                            originalLabel: 'Böhmerwald',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DTJ-CZ-XBG',
                            label: 'Krkonoše / Kjempefjellene',
                            originalLabel: 'Krkonoše / Kjempefjellene',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DTJ-CZ-XBV',
                            label: 'Vltava og sideelver',
                            originalLabel: 'Vltava og sideelver',
                            alternativeLabels: [],
                            note: 'Her: elvene Berounka, Luznice, Otava, Sazava',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DTJ-CZ-XM',
                        label: 'Mähren',
                        originalLabel: 'Mähren',
                        alternativeLabels: ['Mähren'],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTJ-CZ-XS',
                        label: 'Tsjekkisk Schlesien',
                        originalLabel: 'Tsjekkisk Schlesien',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTJ-CZ-Z',
                    label: 'Zlín (region)',
                    originalLabel: 'Zlín (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DTK',
                label: 'Slovakia',
                originalLabel: 'Slovakia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DTK-SK-B',
                    label: 'Bratislava (region)',
                    originalLabel: 'Bratislava (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DTK-SK-BB',
                        label: 'Bratislava',
                        originalLabel: 'Bratislava',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTK-SK-K',
                    label: 'Košice (region)',
                    originalLabel: 'Košice (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTK-SK-N',
                    label: 'Nitra (region)',
                    originalLabel: 'Nitra (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTK-SK-P',
                    label: 'Prešov (region)',
                    originalLabel: 'Prešov (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTK-SK-S',
                    label: 'Banská Bystrica (region)',
                    originalLabel: 'Banská Bystrica (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTK-SK-T',
                    label: 'Trenčín (region)',
                    originalLabel: 'Trenčín (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTK-SK-V',
                    label: 'Trnava (region)',
                    originalLabel: 'Trnava (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTK-SK-Y',
                    label: 'Žilina (region)',
                    originalLabel: 'Žilina (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTK-SK-Z',
                    label: 'Slovakia: reisemål',
                    originalLabel: 'Slovakia: reisemål',
                    alternativeLabels: [],
                    note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                    related: [],
                    children: [
                      {
                        id: '1DTK-SK-ZV',
                        label: 'Váh og sideelver',
                        originalLabel: 'Váh og sideelver',
                        alternativeLabels: [],
                        note: 'Her: elvene Nitra, Orava',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DTM',
                label: 'Moldova',
                originalLabel: 'Moldova',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1DTN',
                label: 'Ukraina',
                originalLabel: 'Ukraina',
                alternativeLabels: ['Donbass', 'Odessa', 'Prypjat'],
                note: 'Her: Tsjernihiv, Tsjernobyl, Donbas, Dnestr, Dnipro, Donetsk, Kharkiv, Kherson, Lviv, Mariupol, Odesa, Poltava, Pripjat, Sumy',
                related: [],
                children: [
                  {
                    id: '1DTN-UA-K',
                    label: 'Kyiv',
                    originalLabel: 'Kyiv',
                    alternativeLabels: ['Kiev'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTNX',
                    label: 'Krim / Krim-halvøya',
                    originalLabel: 'Krim / Krim-halvøya',
                    alternativeLabels: ['Krym'],
                    note: 'Her: Sevastopol. Brukes i historisk og kulturell sammenheng, samt geografisk og administrativ',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DTP',
                label: 'Polen',
                originalLabel: 'Polen',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DTP-PL-A',
                    label: 'Vestpommerske voivodskap',
                    originalLabel: 'Vestpommerske voivodskap',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DTP-PL-AB',
                        label: 'Szczecin',
                        originalLabel: 'Szczecin',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTP-PL-AP',
                        label: 'Det pommerske innsjøplatå',
                        originalLabel: 'Det pommerske innsjøplatå',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTP-PL-B',
                    label: 'Øst-Pommern',
                    originalLabel: 'Øst-Pommern',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DTP-PL-BA',
                        label: 'Kashubia',
                        originalLabel: 'Kashubia',
                        alternativeLabels: ['Kasjubia'],

                        related: [],
                        children: [
                          {
                            id: '1DTP-PL-BAA',
                            label: 'Gdańsk, Gdynia, Sopot – Trippelbyen',
                            originalLabel:
                              'Gdańsk, Gdynia, Sopot – Trippelbyen',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DTP-PL-BD',
                        label: 'Helhalvøya',
                        originalLabel: 'Helhalvøya',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTP-PL-BF',
                        label: 'Żuławy-regionen',
                        originalLabel: 'Żuławy-regionen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DTP-PL-BFA',
                            label: 'Malbork',
                            originalLabel: 'Malbork',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DTP-PL-C',
                    label: 'Kujaviskpommerske voivodskap',
                    originalLabel: 'Kujaviskpommerske voivodskap',
                    alternativeLabels: ['Voivodskapet Kujawy-Pomorze'],

                    related: [],
                    children: [
                      {
                        id: '1DTP-PL-CB',
                        label: 'Bydgoszcz',
                        originalLabel: 'Bydgoszcz',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTP-PL-CC',
                        label: 'Toruń',
                        originalLabel: 'Toruń',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTP-PL-D',
                    label: 'Łódź',
                    originalLabel: 'Łódź',
                    alternativeLabels: ['Lodz'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTP-PL-E',
                    label: 'Ermlandskmasuriske voivodskap',
                    originalLabel: 'Ermlandskmasuriske voivodskap',
                    alternativeLabels: ['Voivodskapet Warmia-Mazury'],

                    related: [],
                    children: [
                      {
                        id: '1DTP-PL-EA',
                        label: 'Olsztyn',
                        originalLabel: 'Olsztyn',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTP-PL-EB',
                        label: 'Biskupiec',
                        originalLabel: 'Biskupiec',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTP-PL-H',
                    label: 'Podlasie voivodskap',
                    originalLabel: 'Podlasie voivodskap',
                    alternativeLabels: ['Voivodskapet Podlasie'],

                    related: [],
                    children: [
                      {
                        id: '1DTP-PL-HB',
                        label: 'Białystok',
                        originalLabel: 'Białystok',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTP-PL-HD',
                        label: 'Suwalki',
                        originalLabel: 'Suwalki',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTP-PL-HR',
                        label: 'Bialowieza nasjonalpark',
                        originalLabel: 'Bialowieza nasjonalpark',
                        alternativeLabels: ['Białowieżaskogen'],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTP-PL-HS',
                        label: 'Biebrza nasjonalpark',
                        originalLabel: 'Biebrza nasjonalpark',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTP-PL-J',
                    label: 'Lubusz-provinsen',
                    originalLabel: 'Lubusz-provinsen',
                    alternativeLabels: [],
                    note: 'Her: Lubusz voivodskap',
                    related: [],
                    children: [
                      {
                        id: '1DTP-PL-JA',
                        label: 'Zielona Góra',
                        originalLabel: 'Zielona Góra',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTP-PL-K',
                    label:
                      'Kielceområdet / Helligkorsvoivodskapet / Świętokrzyskie',
                    originalLabel:
                      'Kielceområdet / Helligkorsvoivodskapet / Świętokrzyskie',
                    alternativeLabels: ['Voivodskapet Święty Krzyż'],

                    related: [],
                    children: [
                      {
                        id: '1DTP-PL-KA',
                        label: 'Kielce',
                        originalLabel: 'Kielce',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTP-PL-KS',
                        label: 'Sandomierz og Sandomierzbekkenet',
                        originalLabel: 'Sandomierz og Sandomierzbekkenet',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTP-PL-L',
                    label: 'Lublinområdet',
                    originalLabel: 'Lublinområdet',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DTP-PL-LA',
                        label: 'Lublin',
                        originalLabel: 'Lublin',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTP-PL-LK',
                        label: 'Kazimierz Dolny',
                        originalLabel: 'Kazimierz Dolny',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTP-PL-LR',
                        label: 'Roztocze nasjonalpark',
                        originalLabel: 'Roztocze nasjonalpark',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTP-PL-M',
                    label: 'Masoviske voivodskap',
                    originalLabel: 'Masoviske voivodskap',
                    alternativeLabels: ['Voivodskapet Mazowsze'],

                    related: [],
                    children: [
                      {
                        id: '1DTP-PL-MA',
                        label: 'Warszawa',
                        originalLabel: 'Warszawa',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTP-PL-MP',
                        label: 'Płock',
                        originalLabel: 'Płock',
                        alternativeLabels: ['Plock'],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTP-PL-MR',
                        label: 'Kampinoski nasjonalpark',
                        originalLabel: 'Kampinoski nasjonalpark',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTP-PL-N',
                    label: 'Storpolske voivodskap',
                    originalLabel: 'Storpolske voivodskap',
                    alternativeLabels: ['Voivodskapet Wielkopolska'],

                    related: [],
                    children: [
                      {
                        id: '1DTP-PL-NB',
                        label: 'Poznań',
                        originalLabel: 'Poznań',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTP-PL-P',
                    label: 'Det lillepolske voivodskap',
                    originalLabel: 'Det lillepolske voivodskap',
                    alternativeLabels: ['Voivodskapet Małopolska'],

                    related: [],
                    children: [
                      {
                        id: '1DTP-PL-PK',
                        label: 'Kraków',
                        originalLabel: 'Kraków',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTP-PL-PR',
                        label: 'Kraków-Częstochowa jurahøylandet',
                        originalLabel: 'Kraków-Częstochowa jurahøylandet',
                        alternativeLabels: [],
                        note: 'Her: Den polske jurakjeden, Det polske jurahøylandet',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTP-PL-PT',
                        label: 'Podhale (Det polske høylandet)',
                        originalLabel: 'Podhale (Det polske høylandet)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DTP-PL-PTZ',
                            label: 'Zakopane',
                            originalLabel: 'Zakopane',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DTP-PL-R',
                    label: 'Det schlesiske voivodskap',
                    originalLabel: 'Det schlesiske voivodskap',
                    alternativeLabels: ['Voivodskapet Śląsk'],

                    related: [],
                    children: [
                      {
                        id: '1DTP-PL-RA',
                        label: 'Katowice',
                        originalLabel: 'Katowice',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DTP-PL-RC',
                        label: 'Częstochowa',
                        originalLabel: 'Częstochowa',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DTP-PL-S',
                    label: 'Det nederschlesiske voivodskap',
                    originalLabel: 'Det nederschlesiske voivodskap',
                    alternativeLabels: [
                      'Nedre Schlesia',
                      'Voivodskapet Dolny Śląsk',
                    ],

                    related: [],
                    children: [
                      {
                        id: '1DTP-PL-SA',
                        label: 'Wrocław',
                        originalLabel: 'Wrocław',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DTT',
                label: 'Tyrkia',
                originalLabel: 'Tyrkia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DTTN',
                    label: 'Anatolia / Lilleasia',
                    originalLabel: 'Anatolia / Lilleasia',
                    alternativeLabels: [],
                    note: 'Brukes i historisk og kulturell sammenheng, samt geografisk og administrativ',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTT-TR-A',
                    label: 'Istanbul',
                    originalLabel: 'Istanbul',
                    alternativeLabels: [],
                    note: 'Her: Bosporos, Üsküdar, Det gylne horn, Sarayburnu (Palassodden), Fatih, Konstantinopel, Bysants',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTT-TR-B',
                    label: 'Tyrkia: Marmararegionen',
                    originalLabel: 'Tyrkia: Marmararegionen',
                    alternativeLabels: ['Hellespont'],
                    note: 'Her: Øst-Trakia, Gallipoli, Dardanellene, Troja, Hisarlik, Bursa',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTT-TR-C',
                    label: 'Tyrkia: Svartehavsregionen',
                    originalLabel: 'Tyrkia: Svartehavsregionen',
                    alternativeLabels: [],
                    note: 'Her: Trabzon, Bolu, Sinop, Amasya, Safranbolu',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTT-TR-D',
                    label: 'Tyrkia: Egeerregionen',
                    originalLabel: 'Tyrkia: Egeerregionen',
                    alternativeLabels: [],
                    note: 'Her: Izmir, Efesos, Didim, Bodrum, Hierapolis, Pamukkale, Pergamon, Didyma, Ölüdeniz, Fethiye',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTT-TR-E',
                    label: 'Tyrkia: Middelhavsregionen',
                    originalLabel: 'Tyrkia: Middelhavsregionen',
                    alternativeLabels: [],
                    note: 'Her: Adana, Cilicia, Antalya, Tarsus, Taurusfjellene, Hatay, Antakya, Marmaris',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTT-TR-F',
                    label: 'Tyrkia: Sentral-Anatolia (region)',
                    originalLabel: 'Tyrkia: Sentral-Anatolia (region)',
                    alternativeLabels: [],
                    note: 'Her: Ankara, Cappadocia, Göreme, Sivas, Konya, Kayseri',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTT-TR-G',
                    label: 'Tyrkia: Øst-Anatolia (region)',
                    originalLabel: 'Tyrkia: Øst-Anatolia (region)',
                    alternativeLabels: [],
                    note: 'Her: Kars, Van, Ararat, Ani',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DTT-TR-H',
                    label: 'Tyrkia: Sørøst-Anatolia (region)',
                    originalLabel: 'Tyrkia: Sørøst-Anatolia (region)',
                    alternativeLabels: [],
                    note: 'Her: Mardin, Nemrut, Gaziantep',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DTV',
                label: 'Armenia',
                originalLabel: 'Armenia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DTV-AM-Y',
                    label: 'Jerevan',
                    originalLabel: 'Jerevan',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DTX',
                label: 'Aserbajdsjan',
                originalLabel: 'Aserbajdsjan',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DTX-AZ-B',
                    label: 'Baku',
                    originalLabel: 'Baku',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DTZ',
                label: 'Kasakhstan',
                originalLabel: 'Kasakhstan',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '1DX',
            label: 'Sørøst-Europa',
            originalLabel: 'Sørøst-Europa',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1DXA',
                label: 'Albania',
                originalLabel: 'Albania',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DXA-AL-T',
                    label: 'Tirana',
                    originalLabel: 'Tirana',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DXB',
                label: 'Bulgaria',
                originalLabel: 'Bulgaria',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DXB-BG-S',
                    label: 'Sofia',
                    originalLabel: 'Sofia',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DXC',
                label: 'Kroatia',
                originalLabel: 'Kroatia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DXC-HR-A',
                    label: 'Zagreb',
                    originalLabel: 'Zagreb',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DXC-HR-C',
                    label: 'Sentrale Kroatia',
                    originalLabel: 'Sentrale Kroatia',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DXC-HR-D',
                    label: 'Dalmatia',
                    originalLabel: 'Dalmatia',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DXC-HR-DD',
                        label: 'Dubrovnik',
                        originalLabel: 'Dubrovnik',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DXC-HR-DK',
                        label: 'Šibenik',
                        originalLabel: 'Šibenik',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DXC-HR-DS',
                        label: 'Split',
                        originalLabel: 'Split',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DXC-HR-DY',
                        label: 'Zadar',
                        originalLabel: 'Zadar',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DXC-HR-J',
                    label: 'Istria',
                    originalLabel: 'Istria',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DXC-HR-K',
                    label: 'Kvarner',
                    originalLabel: 'Kvarner',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DXC-HR-L',
                    label: 'Lika - Karlovac',
                    originalLabel: 'Lika - Karlovac',
                    alternativeLabels: [],
                    note: 'Her: Nordlige Kroatia',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DXC-HR-S',
                    label: 'Slavonia',
                    originalLabel: 'Slavonia',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DXD',
                label: 'Nord-Makedonia',
                originalLabel: 'Nord-Makedonia',
                alternativeLabels: ['Republikken Makedonia'],

                related: [],
                children: [
                  {
                    id: '1DXD-MK-S',
                    label: 'Skopje',
                    originalLabel: 'Skopje',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DXG',
                label: 'Hellas',
                originalLabel: 'Hellas',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DXG-GR-E',
                    label: 'Sentrale Hellas',
                    originalLabel: 'Sentrale Hellas',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DXG-GR-EA',
                        label: 'Attika',
                        originalLabel: 'Attika',
                        alternativeLabels: [],
                        note: 'Her: Parnithafjellet / Parnitha, Marathon',
                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-EAA',
                            label: 'Athen',
                            originalLabel: 'Athen',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-EAE',
                            label: 'Eleusis',
                            originalLabel: 'Eleusis',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-EAP',
                            label: 'Pireus',
                            originalLabel: 'Pireus',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DXG-GR-EE',
                        label: 'Evvia',
                        originalLabel: 'Evvia',
                        alternativeLabels: ['Euboia'],

                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-EEC',
                            label: 'Khalkis',
                            originalLabel: 'Khalkis',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DXG-GR-EF',
                        label: 'Fokis',
                        originalLabel: 'Fokis',
                        alternativeLabels: [],
                        note: 'Her: Parnassos',
                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-EFD',
                            label: 'Delfi',
                            originalLabel: 'Delfi',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DXG-GR-EK',
                        label: 'Etolia og Akarnania',
                        originalLabel: 'Etolia og Akarnania',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-EKM',
                            label: 'Mesolóngi',
                            originalLabel: 'Mesolóngi',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DXG-GR-ET',
                        label: 'Fthiotis',
                        originalLabel: 'Fthiotis',
                        alternativeLabels: ['Fthiotida'],
                        note: 'Her: Thermopylene, Phthia',
                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-ETA',
                            label: 'Atalanti',
                            originalLabel: 'Atalanti',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DXG-GR-EV',
                        label: 'Boiotia',
                        originalLabel: 'Boiotia',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-EVT',
                            label: 'Theben',
                            originalLabel: 'Theben',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DXG-GR-H',
                    label: 'Epirus',
                    originalLabel: 'Epirus',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DXG-GR-L',
                    label: 'Thessalia',
                    originalLabel: 'Thessalia',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DXG-GR-M',
                    label: 'Makedonia (GR)',
                    originalLabel: 'Makedonia (GR)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DXG-GR-MC',
                        label: 'Khalkidiki',
                        originalLabel: 'Khalkidiki',
                        alternativeLabels: [],
                        note: 'Her: Athosfjellet',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DXG-GR-ME',
                        label: 'Pella',
                        originalLabel: 'Pella',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DXG-GR-MP',
                        label: 'Pieria',
                        originalLabel: 'Pieria',
                        alternativeLabels: [],
                        note: 'Her: Olymposfjellet',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DXG-GR-MT',
                        label: 'Thessaloniki (region)',
                        originalLabel: 'Thessaloniki (region)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-MTH',
                            label: 'Thessaloniki',
                            originalLabel: 'Thessaloniki',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DXG-GR-P',
                    label: 'Peloponnes',
                    originalLabel: 'Peloponnes',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DXG-GR-PA',
                        label: 'Akhaia',
                        originalLabel: 'Akhaia',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-PAP',
                            label: 'Patras',
                            originalLabel: 'Patras',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DXG-GR-PD',
                        label: 'Arkadia',
                        originalLabel: 'Arkadia',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DXG-GR-PH',
                        label: 'Elis / Ilia',
                        originalLabel: 'Elis / Ilia',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-PHO',
                            label: 'Olympia',
                            originalLabel: 'Olympia',
                            alternativeLabels: [],
                            note: 'Her: helligdomen i antikkens Hellas hvor de olympiske leker ble arrangert',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DXG-GR-PK',
                        label: 'Korinthia',
                        originalLabel: 'Korinthia',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-PKK',
                            label: 'Korinth',
                            originalLabel: 'Korinth',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DXG-GR-PL',
                        label: 'Lakonia',
                        originalLabel: 'Lakonia',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-PLS',
                            label: 'Sparta',
                            originalLabel: 'Sparta',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-PLY',
                            label: 'Mistrá',
                            originalLabel: 'Mistrá',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DXG-GR-PM',
                        label: 'Messenia',
                        originalLabel: 'Messenia',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DXG-GR-PR',
                        label: 'Argolida',
                        originalLabel: 'Argolida',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-PRA',
                            label: 'Argos',
                            originalLabel: 'Argos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-PRM',
                            label: 'Mykene',
                            originalLabel: 'Mykene',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DXG-GR-S',
                    label: 'Greske øyer',
                    originalLabel: 'Greske øyer',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DXG-GR-SA',
                        label: 'De nordegeiske øyer',
                        originalLabel: 'De nordegeiske øyer',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-SAC',
                            label: 'Khios',
                            originalLabel: 'Khios',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SAH',
                            label: 'Limnos',
                            originalLabel: 'Limnos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SAL',
                            label: 'Lésvos',
                            originalLabel: 'Lésvos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SAS',
                            label: 'Samos',
                            originalLabel: 'Samos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DXG-GR-SC',
                        label: 'Kreta',
                        originalLabel: 'Kreta',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-SCH',
                            label: 'Heraklion',
                            originalLabel: 'Heraklion',
                            alternativeLabels: [],
                            note: 'Her: Knossos',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DXG-GR-SD',
                        label: 'Dodekanesene',
                        originalLabel: 'Dodekanesene',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-SDH',
                            label: 'Karpathos',
                            originalLabel: 'Karpathos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SDK',
                            label: 'Kos',
                            originalLabel: 'Kos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SDL',
                            label: 'Leros',
                            originalLabel: 'Leros',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SDP',
                            label: 'Patmos',
                            originalLabel: 'Patmos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SDR',
                            label: 'Rhodos',
                            originalLabel: 'Rhodos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DXG-GR-SF',
                        label: 'De joniske øyer',
                        originalLabel: 'De joniske øyer',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-SFC',
                            label: 'Korfu',
                            originalLabel: 'Korfu',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SFF',
                            label: 'Paxos',
                            originalLabel: 'Paxos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SFK',
                            label: 'Kefalonia',
                            originalLabel: 'Kefalonia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SFL',
                            label: 'Lefkada',
                            originalLabel: 'Lefkada',
                            alternativeLabels: ['Lefkas'],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SFP',
                            label: 'Ithaca',
                            originalLabel: 'Ithaca',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SFR',
                            label: 'Zakynthos',
                            originalLabel: 'Zakynthos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DXG-GR-SK',
                        label: 'Kykladene',
                        originalLabel: 'Kykladene',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-SKK',
                            label: 'Mykonos',
                            originalLabel: 'Mykonos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SKM',
                            label: 'Milos',
                            originalLabel: 'Milos',
                            alternativeLabels: [],
                            note: 'Her: Kimolos',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SKN',
                            label: 'Naxos',
                            originalLabel: 'Naxos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SKP',
                            label: 'Paros',
                            originalLabel: 'Paros',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SKS',
                            label: 'Santorini',
                            originalLabel: 'Santorini',
                            alternativeLabels: [],
                            note: 'Her: Thera',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SKY',
                            label: 'Syros',
                            originalLabel: 'Syros',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DXG-GR-SR',
                        label: 'De saroniske øyer',
                        originalLabel: 'De saroniske øyer',
                        alternativeLabels: [],
                        note: 'Her: Agistri',
                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-SRA',
                            label: 'Egina',
                            originalLabel: 'Egina',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SRS',
                            label: 'Salamis',
                            originalLabel: 'Salamis',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DXG-GR-SS',
                        label: 'Sporadene',
                        originalLabel: 'Sporadene',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-SSA',
                            label: 'Alonnisos',
                            originalLabel: 'Alonnisos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-SSK',
                            label: 'Skiathos',
                            originalLabel: 'Skiathos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DXG-GR-ST',
                        label: 'Andre greske øyer',
                        originalLabel: 'Andre greske øyer',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DXG-GR-STK',
                            label: 'Kythira',
                            originalLabel: 'Kythira',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-STM',
                            label: 'Samothraki',
                            originalLabel: 'Samothraki',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DXG-GR-STS',
                            label: 'Skyros',
                            originalLabel: 'Skyros',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DXG-GR-T',
                    label: 'Trakia',
                    originalLabel: 'Trakia',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DXH',
                label: 'Bosnia-Hercegovina',
                originalLabel: 'Bosnia-Hercegovina',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DXH-BA-S',
                    label: 'Sarajevo',
                    originalLabel: 'Sarajevo',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DXK',
                label: 'Kosovo',
                originalLabel: 'Kosovo',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1DXN',
                label: 'Montenegro',
                originalLabel: 'Montenegro',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DXN-ME-P',
                    label: 'Podgorica',
                    originalLabel: 'Podgorica',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DXR',
                label: 'Romania',
                originalLabel: 'Romania',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DXR-RO-B',
                    label: 'Bucuresti',
                    originalLabel: 'Bucuresti',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DXR-RO-Z',
                    label: 'Romania: reisemål',
                    originalLabel: 'Romania: reisemål',
                    alternativeLabels: [],
                    note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                    related: [],
                    children: [
                      {
                        id: '1DXR-RO-ZT',
                        label: 'Transylvania',
                        originalLabel: 'Transylvania',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DXS',
                label: 'Serbia',
                originalLabel: 'Serbia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DXS-RS-B',
                    label: 'Beograd',
                    originalLabel: 'Beograd',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DXV',
                label: 'Slovenia',
                originalLabel: 'Slovenia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DXV-SI-C',
                    label: 'Krain',
                    originalLabel: 'Krain',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DXV-SI-CL',
                        label: 'Ljubljana',
                        originalLabel: 'Ljubljana',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1DXY',
                label: 'Kypros',
                originalLabel: 'Kypros',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DXY-CY-C',
                    label: 'Nikosia',
                    originalLabel: 'Nikosia',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '1DZ',
            label: 'Europa: fysiske aspekter',
            originalLabel: 'Europa: fysiske aspekter',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1DZA',
                label: 'Europa: elver, innsjøer etc.',
                originalLabel: 'Europa: elver, innsjøer etc.',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DZA-CH-L',
                    label: 'Luganosjøen',
                    originalLabel: 'Luganosjøen',
                    alternativeLabels: [],
                    note: 'Her: innsjøen og områdene rundt',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZAD',
                    label: 'Donau og sideelver',
                    originalLabel: 'Donau og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                    related: [],
                    children: [
                      {
                        id: '1DZADT',
                        label: 'Tisza og sideelver',
                        originalLabel: 'Tisza og sideelver',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DZA-DE-E',
                    label: 'Elbe og sideelver',
                    originalLabel: 'Elbe og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZA-DE-N',
                    label: 'Oder og Neisse og sideelver',
                    originalLabel: 'Oder og Neisse og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZA-DE-W',
                    label: 'Weser og sideelver',
                    originalLabel: 'Weser og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZA-IT-A',
                    label: 'Po og sideelver',
                    originalLabel: 'Po og sideelver',
                    alternativeLabels: [],
                    note: 'Foretrekk kode i Nord-Italia: reisemål. Se også 1DST-IT-NZP <a href="/thema/nb/1DST-IT-NZP">Po og sideelver</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZA-IT-C',
                    label: 'Arno og sideelver',
                    originalLabel: 'Arno og sideelver',
                    alternativeLabels: [],
                    note: 'Foretrekk kode i Sentrale Italia: reisemål. Se også 1DST-IT-TXA <a href="/thema/nb/1DST-IT-TXA">Arno og sideelver</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZA-IT-E',
                    label: 'Tiber og sideelver',
                    originalLabel: 'Tiber og sideelver',
                    alternativeLabels: [],
                    note: 'Foretrekk kode i Sentrale Italia: reisemål. Se også 1DST-IT-TXC <a href="/thema/nb/1DST-IT-TXC">Tiber og sideelver</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZA-PL-V',
                    label: 'Vistula og sideelver',
                    originalLabel: 'Vistula og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                    related: [],
                    children: [
                      {
                        id: '1DZA-PL-VB',
                        label: 'Bug / Vestre Buh',
                        originalLabel: 'Bug / Vestre Buh',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DZAR',
                    label: 'Rhinen og sideelver',
                    originalLabel: 'Rhinen og sideelver',
                    alternativeLabels: [
                      'Vorderrhein',
                      'Hinterrhein',
                      'Hochrhein',
                      'Alpenrheintal',
                      'Oberrhein',
                      'Mittelrhein',
                      'Niederrhein',
                      'Nederrijn',
                      'Rhein-Maas-Delta',
                    ],
                    note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                    related: [],
                    children: [
                      {
                        id: '1DZARC',
                        label: 'Bodensjøen',
                        originalLabel: 'Bodensjøen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DZAR-DE-M',
                        label: 'Mosel og sideelver',
                        originalLabel: 'Mosel og sideelver',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver, elvedalen og regionen',
                        related: [],
                        children: [
                          {
                            id: '1DZAR-DE-MS',
                            label: 'Saar og sideelver',
                            originalLabel: 'Saar og sideelver',
                            alternativeLabels: [],
                            note: 'Her: elven, dens sideelver, elvedalen og regionen',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DZAR-DE-N',
                        label: 'Neckar og sideelver',
                        originalLabel: 'Neckar og sideelver',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver, elvedalen og regionen',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DZAS',
                    label: 'Maas (Meuse) og sideelver',
                    originalLabel: 'Maas (Meuse) og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZAT',
                    label: 'Schelde og sideelver',
                    originalLabel: 'Schelde og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen, elvedeltaet og regionen',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZAV',
                    label: 'Volga og sideelver',
                    originalLabel: 'Volga og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen, elvedeltaet og regionen',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1DZT',
                label: 'Europa: fjell, åser, sletter, kystlinjer etc.',
                originalLabel: 'Europa: fjell, åser, sletter, kystlinjer etc.',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1DZTA',
                    label: 'Alpene',
                    originalLabel: 'Alpene',
                    alternativeLabels: [],
                    note: 'Brukes med andre kvalifikatorer fra 1* for bøker som handler om spesielle deler av Alpene, som de østerrikske eller franske alpene, og hvor det ikke fins en egen kode',
                    related: [],
                    children: [
                      {
                        id: '1DZTA-CH-A',
                        label: 'Sveitsiske alper',
                        originalLabel: 'Sveitsiske alper',
                        alternativeLabels: [],
                        note: 'Her: Glarner-Alpene',
                        related: [],
                        children: [
                          {
                            id: '1DZTA-CH-AD',
                            label: 'Berna-Alpene',
                            originalLabel: 'Berna-Alpene',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1DZTA-CH-ADA',
                                label: 'Aletsch',
                                originalLabel: 'Aletsch',
                                alternativeLabels: [],
                                note: 'Her: Finsteraarhorn, Aarmassif, Aletsch Glacier, Bietschhorn',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1DZTA-CH-ADE',
                                label: 'Eiger',
                                originalLabel: 'Eiger',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1DZTA-CH-ADJ',
                                label: 'Jungfrau',
                                originalLabel: 'Jungfrau',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1DZTA-CH-AG',
                            label: 'Saint-Gotthard Massif',
                            originalLabel: 'Saint-Gotthard Massif',
                            alternativeLabels: [],
                            note: 'Her: Sankt Gotthard-passet',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DZTA-CH-B',
                        label: 'Val Bregaglia',
                        originalLabel: 'Val Bregaglia',
                        alternativeLabels: [],
                        note: 'Her: Bregagliafjellene',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1DZTA-DE-B',
                        label: 'Allgäu-Alpene',
                        originalLabel: 'Allgäu-Alpene',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1DZTA-FR-B',
                        label: 'Mont Blanc-massivet',
                        originalLabel: 'Mont Blanc-massivet',
                        alternativeLabels: [],
                        note: 'Class here: Grandes Jorasses, Aiguille du Midi, Miage Glacier, Mer de Glace, Mont Dolent, Picco Luigi Amedeo',
                        related: [],
                        children: [
                          {
                            id: '1DZTA-FR-BM',
                            label: 'Mont Blanc',
                            originalLabel: 'Mont Blanc',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DZTAP',
                        label: 'Wallis-alpene',
                        originalLabel: 'Wallis-alpene',
                        alternativeLabels: [],
                        note: 'Her: Valais',
                        related: [],
                        children: [
                          {
                            id: '1DZTAPM',
                            label: 'Matterhorn',
                            originalLabel: 'Matterhorn',
                            alternativeLabels: [],
                            note: 'Her: Mount Cervin',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1DZTAPR',
                            label: 'Monte Rosa-massivet',
                            originalLabel: 'Monte Rosa-massivet',
                            alternativeLabels: [],
                            note: 'Her: Dufourspitze',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DZTA-SI-C',
                        label: 'De juliske alper',
                        originalLabel: 'De juliske alper',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DZTB',
                    label: 'Kaukasus',
                    originalLabel: 'Kaukasus',
                    alternativeLabels: [],
                    note: 'Brukes for fjellene og regionen',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-BE-A',
                    label: 'Ardennene',
                    originalLabel: 'Ardennene',
                    alternativeLabels: [],
                    note: 'Her: Ardennerskogen',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZTC',
                    label: 'Karpatene',
                    originalLabel: 'Karpatene',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DZTCB',
                        label: 'Beskidene',
                        originalLabel: 'Beskidene',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DZTCBB',
                            label: 'Bieszczady',
                            originalLabel: 'Bieszczady',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1DZTCBP',
                            label: 'Pieninyfjellene',
                            originalLabel: 'Pieninyfjellene',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1DZTCT',
                        label: 'Tatrafjellene',
                        originalLabel: 'Tatrafjellene',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1DZT-DE-A',
                    label: 'Harz',
                    originalLabel: 'Harz',
                    alternativeLabels: [],
                    note: 'Her: Brocken, Blokksberg',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-DE-B',
                    label: 'Rhön',
                    originalLabel: 'Rhön',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-DE-C',
                    label: 'Eifel',
                    originalLabel: 'Eifel',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-DE-D',
                    label: 'Schwarzwald',
                    originalLabel: 'Schwarzwald',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-DE-E',
                    label: 'Rhinland',
                    originalLabel: 'Rhinland',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-FR-J',
                    label: 'Jurafjellene',
                    originalLabel: 'Jurafjellene',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-GB-C',
                    label: 'Cotswolds',
                    originalLabel: 'Cotswolds',
                    alternativeLabels: [],
                    note: 'Foretrekk kode i Sørvest-England: reisemål. Se også 1DDU-GB-EWZC <a href="/thema/nb/1DDU-GB-EWZC">Cotswolds</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-GB-P',
                    label: 'Penninene',
                    originalLabel: 'Penninene',
                    alternativeLabels: [],
                    note: 'Foretrekk kode i Nord- og Nordøst-England: reisemål. Se også 1DDU-GB-EYZB <a href="/thema/nb/1DDU-GB-EYZB">Penninene</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZTH',
                    label: 'Balkan',
                    originalLabel: 'Balkan',
                    alternativeLabels: [],
                    note: 'Brukes for fjellene og regionen',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-IT-A',
                    label: 'De nordlige Appenninene',
                    originalLabel: 'De nordlige Appenninene',
                    alternativeLabels: [],
                    note: 'Foretrekk kode i Nord-Italia: reisemål. Se også 1DST-IT-NZB <a href="/thema/nb/1DST-IT-NZB">De nordlige Appenninene</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-IT-B',
                    label: 'De sentrale Appenninene',
                    originalLabel: 'De sentrale Appenninene',
                    alternativeLabels: [],
                    note: 'Foretrekk kode i Sentrale Italia: reisemål. Se også 1DST-IT-TXF <a href="/thema/nb/1DST-IT-TXF">De sentrale Appenninene</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-IT-C',
                    label: 'De sørlige Appenninene',
                    originalLabel: 'De sørlige Appenninene',
                    alternativeLabels: [],
                    note: 'Foretrekk kode i Sør-Italia: reisemål. Se også 1DST-IT-UZC <a href="/thema/nb/1DST-IT-UZC">De sørlige Appenninene</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-IT-G',
                    label: 'Kars',
                    originalLabel: 'Kars',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-IT-L',
                    label: 'Maremma',
                    originalLabel: 'Maremma',
                    alternativeLabels: [],
                    note: 'Foretrekk kode i Sentrale Italia: reisemål. Se også 1DST-IT-TXM <a href="/thema/nb/1DST-IT-TXM">Maremma</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-IT-N',
                    label: 'Val d’Orcia',
                    originalLabel: 'Val d’Orcia',
                    alternativeLabels: [],
                    note: 'Foretrekk kode i Italia-Toscana-Siena. Se også 1DST-IT-TSEB <a href="/thema/nb/1DST-IT-TSEB">Val d’Orcia</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-IT-P',
                    label: 'Tavoliere delle Puglie',
                    originalLabel: 'Tavoliere delle Puglie',
                    alternativeLabels: [],
                    note: 'Foretrekk kode i Italia-Apulia-Foggia. Se også 1DST-IT-UPGB <a href="/thema/nb/1DST-IT-UPGB">Tavoliere delle Puglie</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-IT-R',
                    label: 'Amalfikysten',
                    originalLabel: 'Amalfikysten',
                    alternativeLabels: [],
                    note: 'Foretrekk kode i Italia-Campania-Salerna. Se også 1DST-IT-UMSC <a href="/thema/nb/1DST-IT-UMSC">Amalfikysten</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZT-IT-T',
                    label: 'Costa Smeralda',
                    originalLabel: 'Costa Smeralda',
                    alternativeLabels: [],
                    note: 'Foretrekk kode i Italia-Sardinia-Sassari. Se også 1DST-IT-UDSD <a href="/thema/nb/1DST-IT-UDSD">Costa Smeralda</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZTJ',
                    label: 'Böhmische Masse',
                    originalLabel: 'Böhmische Masse',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1DZTJS',
                        label: 'Sudetene',
                        originalLabel: 'Sudetene',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1DZTJSH',
                            label: 'Stolowefjella',
                            originalLabel: 'Stolowefjella',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1DZTP',
                    label: 'Pyreneene',
                    originalLabel: 'Pyreneene',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZTS',
                    label: 'Den skandinaviske fjellkjede',
                    originalLabel: 'Den skandinaviske fjellkjede',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1DZTU',
                    label: 'Uralfjellene',
                    originalLabel: 'Uralfjellene',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: '1F',
        label: 'Asia',
        originalLabel: 'Asia',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '1FB',
            label: 'Midtøsten',
            originalLabel: 'Midtøsten',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1FBG',
                label: 'Jerusalem',
                originalLabel: 'Jerusalem',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1FBH',
                label: 'Israel',
                originalLabel: 'Israel',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FBH-IL-A',
                    label: 'Tel-Aviv',
                    originalLabel: 'Tel-Aviv',
                    alternativeLabels: [],
                    note: 'Her: Jaffa',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1FBH-IL-H',
                    label: 'Haifa',
                    originalLabel: 'Haifa',
                    alternativeLabels: [],
                    note: 'Her: Karmelberget',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FBJ',
                label: 'Jordan',
                originalLabel: 'Jordan',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FBJ-JO-P',
                    label: 'Petra',
                    originalLabel: 'Petra',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FBL',
                label: 'Libanon',
                originalLabel: 'Libanon',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FBL-LB-B',
                    label: 'Beirut',
                    originalLabel: 'Beirut',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FBN',
                label: 'Iran',
                originalLabel: 'Iran',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FBN-IR-A',
                    label: 'Teheran',
                    originalLabel: 'Teheran',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FBN-IR-S',
                    label: 'Shiraz',
                    originalLabel: 'Shiraz',
                    alternativeLabels: [],
                    note: 'Her: Persepolis',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FBP',
                label: 'Palestina',
                originalLabel: 'Palestina',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FBP-PS-G',
                    label: 'Gaza',
                    originalLabel: 'Gaza',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FBQ',
                label: 'Irak',
                originalLabel: 'Irak',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FBQ-IQ-B',
                    label: 'Bagdad',
                    originalLabel: 'Bagdad',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FBS',
                label: 'Syria',
                originalLabel: 'Syria',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FBS-SY-A',
                    label: 'Aleppo',
                    originalLabel: 'Aleppo',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FBS-SY-D',
                    label: 'Damaskus',
                    originalLabel: 'Damaskus',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FBS-SY-P',
                    label: 'Palmyra',
                    originalLabel: 'Palmyra',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FBX',
                label: 'Den arabiske halvøy',
                originalLabel: 'Den arabiske halvøy',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FBXB',
                    label: 'Bahrain',
                    originalLabel: 'Bahrain',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FBXK',
                    label: 'Kuwait',
                    originalLabel: 'Kuwait',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FBXM',
                    label: 'Oman',
                    originalLabel: 'Oman',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FBXQ',
                    label: 'Qatar',
                    originalLabel: 'Qatar',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FBXS',
                    label: 'Saudi-Arabia',
                    originalLabel: 'Saudi-Arabia',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FBXS-SA-H',
                        label: 'Hedsjas / Hijaz',
                        originalLabel: 'Hedsjas / Hijaz',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FBXS-SA-HJ',
                            label: 'Jidda',
                            originalLabel: 'Jidda',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FBXS-SA-HM',
                            label: 'Mekka',
                            originalLabel: 'Mekka',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FBXS-SA-HN',
                            label: 'Medina',
                            originalLabel: 'Medina',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1FBXS-SA-R',
                        label: 'Riyadh',
                        originalLabel: 'Riyadh',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FBXU',
                    label: 'De forente arabiske emirater',
                    originalLabel: 'De forente arabiske emirater',
                    alternativeLabels: [],
                    note: 'Her: Ajman, Fujairah, Ras al-Khaimah, Umm Al Quwain',
                    related: [],
                    children: [
                      {
                        id: '1FBXU-AE-A',
                        label: 'Abu Dhabi',
                        originalLabel: 'Abu Dhabi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FBXU-AE-D',
                        label: 'Dubai',
                        originalLabel: 'Dubai',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FBXU-AE-F',
                        label: 'Sharjah',
                        originalLabel: 'Sharjah',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FBXY',
                    label: 'Jemen',
                    originalLabel: 'Jemen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FBZ',
                label: 'Midtøsten: reisemål',
                originalLabel: 'Midtøsten: reisemål',
                alternativeLabels: [],
                note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                related: [],
                children: [
                  {
                    id: '1FBZB',
                    label: 'Tigris og Eufrat',
                    originalLabel: 'Tigris og Eufrat',
                    alternativeLabels: [],
                    note: 'Her: elvene, deres sideelver, elvedalene og elvedeltaene',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1FBZJ',
                    label: 'Jordan',
                    originalLabel: 'Jordan',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver og elvedalen',
                    related: [],
                    children: [
                      {
                        id: '1FBZJD',
                        label: 'Dødehavet',
                        originalLabel: 'Dødehavet',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: '1FC',
            label: 'Sentral-Asia',
            originalLabel: 'Sentral-Asia',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1FCA',
                label: 'Afghanistan',
                originalLabel: 'Afghanistan',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FCA-AF-K',
                    label: 'Kabul',
                    originalLabel: 'Kabul',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FCD',
                label: 'Tadsjikistan',
                originalLabel: 'Tadsjikistan',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1FCK',
                label: 'Kirgisistan',
                originalLabel: 'Kirgisistan',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FCK-KG-B',
                    label: 'Bisjkek',
                    originalLabel: 'Bisjkek',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FCS',
                label: 'Sibir',
                originalLabel: 'Sibir',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1FCT',
                label: 'Turkmenistan',
                originalLabel: 'Turkmenistan',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1FCU',
                label: 'Usbekistan',
                originalLabel: 'Usbekistan',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '1FK',
            label: 'Sør-Asia (Det indiske subkontinent)',
            originalLabel: 'Sør-Asia (Det indiske subkontinent)',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1FKA',
                label: 'India',
                originalLabel: 'India',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FKA-IN-A',
                    label: 'Nordlige India',
                    originalLabel: 'Nordlige India',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FKA-IN-AB',
                        label: 'Chandigarh',
                        originalLabel: 'Chandigarh',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-AD',
                        label:
                          'Dehli (National Capital Territory of Delhi, NCT)',
                        originalLabel:
                          'Dehli (National Capital Territory of Delhi, NCT)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FKA-IN-ADN',
                            label: 'New Delhi',
                            originalLabel: 'New Delhi',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1FKA-IN-AG',
                        label: 'Haryana',
                        originalLabel: 'Haryana',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-AH',
                        label: 'Himachal Pradesh',
                        originalLabel: 'Himachal Pradesh',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FKA-IN-AHS',
                            label: 'Shimla / Simla',
                            originalLabel: 'Shimla / Simla',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1FKA-IN-AJ',
                        label: 'Jammu og Kashmir',
                        originalLabel: 'Jammu og Kashmir',
                        alternativeLabels: [],
                        note: 'Her: Ladakh',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-AP',
                        label: 'Punjab',
                        originalLabel: 'Punjab',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FKA-IN-APB',
                            label: 'Amritsar',
                            originalLabel: 'Amritsar',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1FKA-IN-AR',
                        label: 'Rajasthan',
                        originalLabel: 'Rajasthan',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FKA-IN-ARB',
                            label: 'Jaipur',
                            originalLabel: 'Jaipur',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FKA-IN-ARD',
                            label: 'Jodhpur',
                            originalLabel: 'Jodhpur',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FKA-IN-ARF',
                            label: 'Udaipur',
                            originalLabel: 'Udaipur',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1FKA-IN-C',
                    label: 'Sentrale India',
                    originalLabel: 'Sentrale India',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FKA-IN-CB',
                        label: 'Chhattisgarh',
                        originalLabel: 'Chhattisgarh',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-CD',
                        label: 'Madhya Pradesh',
                        originalLabel: 'Madhya Pradesh',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-CF',
                        label: 'Uttar Pradesh',
                        originalLabel: 'Uttar Pradesh',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FKA-IN-CFA',
                            label: 'Agra',
                            originalLabel: 'Agra',
                            alternativeLabels: [],
                            note: 'Her: Taj Mahal',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1FKA-IN-CFC',
                            label: 'Varanasi / Benares',
                            originalLabel: 'Varanasi / Benares',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1FKA-IN-CH',
                        label: 'Uttarakhand',
                        originalLabel: 'Uttarakhand',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FKA-IN-E',
                    label: 'Østlige India',
                    originalLabel: 'Østlige India',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FKA-IN-EB',
                        label: 'Bihar',
                        originalLabel: 'Bihar',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-ED',
                        label: 'Jharkhand',
                        originalLabel: 'Jharkhand',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-EF',
                        label: 'Odisha',
                        originalLabel: 'Odisha',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-EH',
                        label: 'Vest-Bengal',
                        originalLabel: 'Vest-Bengal',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FKA-IN-EHC',
                            label: 'Kolkata',
                            originalLabel: 'Kolkata',
                            alternativeLabels: [],
                            note: 'Her: Calcutta',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1FKA-IN-EHD',
                            label: 'Darjeeling',
                            originalLabel: 'Darjeeling',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1FKA-IN-G',
                    label: 'Nordøstlige India',
                    originalLabel: 'Nordøstlige India',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FKA-IN-GA',
                        label: 'Assam',
                        originalLabel: 'Assam',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-GC',
                        label: 'Arunachal Pradesh',
                        originalLabel: 'Arunachal Pradesh',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-GE',
                        label: 'Manipur',
                        originalLabel: 'Manipur',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-GH',
                        label: 'Meghalaya',
                        originalLabel: 'Meghalaya',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-GJ',
                        label: 'Mizoram',
                        originalLabel: 'Mizoram',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-GL',
                        label: 'Nagaland',
                        originalLabel: 'Nagaland',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-GN',
                        label: 'Tripura',
                        originalLabel: 'Tripura',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-GS',
                        label: 'Sikkim',
                        originalLabel: 'Sikkim',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FKA-IN-J',
                    label: 'Vestlige India',
                    originalLabel: 'Vestlige India',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FKA-IN-JB',
                        label: 'Dadra og Nagar Haveli',
                        originalLabel: 'Dadra og Nagar Haveli',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-JD',
                        label: 'Daman og Diu',
                        originalLabel: 'Daman og Diu',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-JF',
                        label: 'Goa',
                        originalLabel: 'Goa',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-JG',
                        label: 'Gujarat',
                        originalLabel: 'Gujarat',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-JM',
                        label: 'Maharashtra',
                        originalLabel: 'Maharashtra',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FKA-IN-JMM',
                            label: 'Mumbai',
                            originalLabel: 'Mumbai',
                            alternativeLabels: [],
                            note: 'Her: Bombay',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1FKA-IN-L',
                    label: 'Sørlige India',
                    originalLabel: 'Sørlige India',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FKA-IN-LA',
                        label: 'Andhra Pradesh',
                        originalLabel: 'Andhra Pradesh',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-LC',
                        label: 'Karnataka',
                        originalLabel: 'Karnataka',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FKA-IN-LCB',
                            label: 'Bangalore',
                            originalLabel: 'Bangalore',
                            alternativeLabels: [],
                            note: 'Class here: Bangalore',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1FKA-IN-LE',
                        label: 'Kerala',
                        originalLabel: 'Kerala',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FKA-IN-LEC',
                            label: 'Kochi',
                            originalLabel: 'Kochi',
                            alternativeLabels: [],
                            note: 'Her: Cochin',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1FKA-IN-LG',
                        label: 'Puducherry',
                        originalLabel: 'Puducherry',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FKA-IN-LGB',
                            label: 'Pondicherry',
                            originalLabel: 'Pondicherry',
                            alternativeLabels: [],
                            note: 'Her: Puducherry',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1FKA-IN-LJ',
                        label: 'Tamil Nadu',
                        originalLabel: 'Tamil Nadu',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FKA-IN-LJC',
                            label: 'Chennai',
                            originalLabel: 'Chennai',
                            alternativeLabels: [],
                            note: 'Her: Madras',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1FKA-IN-LK',
                        label: 'Telangana',
                        originalLabel: 'Telangana',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FKA-IN-LKH',
                            label: 'Hyderabad',
                            originalLabel: 'Hyderabad',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1FKA-IN-LN',
                        label: 'Andamanene og Nikobarene',
                        originalLabel: 'Andamanene og Nikobarene',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FKA-IN-LP',
                        label: 'Lakshadweep',
                        originalLabel: 'Lakshadweep',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1FKB',
                label: 'Bangladesh',
                originalLabel: 'Bangladesh',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FKB-BD-D',
                    label: 'Dhaka',
                    originalLabel: 'Dhaka',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FKH',
                label: 'Bhutan',
                originalLabel: 'Bhutan',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1FKN',
                label: 'Nepal',
                originalLabel: 'Nepal',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FKN-NP-K',
                    label: 'Katmandu',
                    originalLabel: 'Katmandu',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FKP',
                label: 'Pakistan',
                originalLabel: 'Pakistan',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FKP-PK-B',
                    label: 'Balutsjistan',
                    originalLabel: 'Balutsjistan',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FKP-PK-D',
                    label: 'Khyber Pakhtunkhwa',
                    originalLabel: 'Khyber Pakhtunkhwa',
                    alternativeLabels: [],
                    note: 'Her: Den nordvestlige grenseprovinsen, Kyberpasset',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1FKP-PK-F',
                    label: 'Punjab (PK)',
                    originalLabel: 'Punjab (PK)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FKP-PK-FB',
                        label: 'Lahore',
                        originalLabel: 'Lahore',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FKP-PK-H',
                    label: 'Sindh',
                    originalLabel: 'Sindh',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FKP-PK-HB',
                        label: 'Karachi',
                        originalLabel: 'Karachi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FKP-PK-L',
                    label: 'Islamabad',
                    originalLabel: 'Islamabad',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FKS',
                label: 'Sri Lanka',
                originalLabel: 'Sri Lanka',
                alternativeLabels: [],
                note: 'Her: Ceylon',
                related: [],
                children: [
                  {
                    id: '1FKS-LK-C',
                    label: 'Colombo',
                    originalLabel: 'Colombo',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '1FM',
            label: 'Sørøst-Asia',
            originalLabel: 'Sørøst-Asia',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1FMB',
                label: 'Myanmar',
                originalLabel: 'Myanmar',
                alternativeLabels: ['Yangôn', 'Rangoon'],
                note: 'Her: Burma, Yangon, Nay Pyi Taw, Irrawaddy, Mandalay',
                related: [],
                children: [],
              },
              {
                id: '1FMC',
                label: 'Kambodsja',
                originalLabel: 'Kambodsja',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FMC-KH-A',
                    label: 'Phnom Penh',
                    originalLabel: 'Phnom Penh',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FMC-KH-B',
                    label: 'Siem Reap',
                    originalLabel: 'Siem Reap',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FMC-KH-BA',
                        label: 'Angkor',
                        originalLabel: 'Angkor',
                        alternativeLabels: ['Angkor Wat'],
                        note: 'Her: Angkor Vat',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1FML',
                label: 'Laos',
                originalLabel: 'Laos',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1FMM',
                label: 'Malaysia',
                originalLabel: 'Malaysia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FMM-MY-B',
                    label: 'Kuala Lumpur',
                    originalLabel: 'Kuala Lumpur',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FMM-MY-M',
                    label: 'Malakka',
                    originalLabel: 'Malakka',
                    alternativeLabels: ['Melaka', 'Malacca'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FMM-MY-P',
                    label: 'Penang',
                    originalLabel: 'Penang',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FMM-MY-S',
                    label: 'Sarawak',
                    originalLabel: 'Sarawak',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FMM-MY-T',
                    label: 'Sabah',
                    originalLabel: 'Sabah',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FMN',
                label: 'Indonesia',
                originalLabel: 'Indonesia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FMNB',
                    label: 'Bali',
                    originalLabel: 'Bali',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FMN-ID-B',
                    label: 'Sumatra',
                    originalLabel: 'Sumatra',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FMN-ID-D',
                    label: 'Java',
                    originalLabel: 'Java',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FMN-ID-DJ',
                        label: 'Jakarta',
                        originalLabel: 'Jakarta',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FMN-ID-F',
                    label: 'Kalimantan',
                    originalLabel: 'Kalimantan',
                    alternativeLabels: [],
                    note: 'Her: indonesisk Borneo',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1FMN-ID-K',
                    label: 'Sulawesi',
                    originalLabel: 'Sulawesi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FMN-ID-M',
                    label: 'Molukkene',
                    originalLabel: 'Molukkene',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FMN-ID-P',
                    label: 'Papua',
                    originalLabel: 'Papua',
                    alternativeLabels: [],
                    note: 'Se også 1MKLP <a href="/thema/nb/1MKLP">Ny-Guinea</a>',
                    related: [
                      {
                        id: '1MKLP',
                        label: 'Ny-Guinea',
                        originalLabel: 'Ny-Guinea',
                        note: 'Brukes for hele øya. Se også 1FMN-ID-P <a href="/thema/nb/1FMN-ID-P">Papua</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: '1FMN-ID-S',
                    label: 'De små Sundaøyer',
                    originalLabel: 'De små Sundaøyer',
                    alternativeLabels: [],
                    note: 'Her: Øst-Nusa Tenggara, Vest-Timor',
                    related: [],
                    children: [
                      {
                        id: '1FMN-ID-SL',
                        label: 'Lombok',
                        originalLabel: 'Lombok',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FMNT',
                    label: 'Øst-Timor',
                    originalLabel: 'Øst-Timor',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FMNX',
                    label: 'Borneo',
                    originalLabel: 'Borneo',
                    alternativeLabels: [],
                    note: 'Brukes for hele øya',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FMP',
                label: 'Filippinene',
                originalLabel: 'Filippinene',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FMP-PH-A',
                    label: 'Manila',
                    originalLabel: 'Manila',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FMR',
                label: 'Brunei',
                originalLabel: 'Brunei',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1FMS',
                label: 'Singapore',
                originalLabel: 'Singapore',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1FMT',
                label: 'Thailand',
                originalLabel: 'Thailand',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FMT-TH-B',
                    label: 'Bangkok',
                    originalLabel: 'Bangkok',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FMT-TH-C',
                    label: 'Chiang Mai',
                    originalLabel: 'Chiang Mai',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FMT-TH-K',
                    label: 'Krabi',
                    originalLabel: 'Krabi',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FMT-TH-KL',
                        label: 'Ko Lanta',
                        originalLabel: 'Ko Lanta',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FMT-TH-P',
                    label: 'Phuket',
                    originalLabel: 'Phuket',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FMT-TH-Q',
                    label: 'Surat Thani',
                    originalLabel: 'Surat Thani',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FMT-TH-QB',
                        label: 'Ko Samui / Koh Samui',
                        originalLabel: 'Ko Samui / Koh Samui',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FMT-TH-Y',
                    label: 'Ayutthaya',
                    originalLabel: 'Ayutthaya',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FMV',
                label: 'Vietnam',
                originalLabel: 'Vietnam',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FMV-VN-B',
                    label: 'Ho Chi Minh-byen',
                    originalLabel: 'Ho Chi Minh-byen',
                    alternativeLabels: [],
                    note: 'Her: Saigon',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1FMV-VN-D',
                    label: 'Hanoi',
                    originalLabel: 'Hanoi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '1FP',
            label: 'Øst-Asia, Fjerne Østen',
            originalLabel: 'Øst-Asia, Fjerne Østen',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1FPC',
                label: 'Kina',
                originalLabel: 'Kina',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FPC-CN-D',
                    label: 'Nordlige Kina',
                    originalLabel: 'Nordlige Kina',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FPC-CN-DB',
                        label: 'Beijing',
                        originalLabel: 'Beijing',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-DD',
                        label: 'Tianjin',
                        originalLabel: 'Tianjin',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-DF',
                        label: 'Hebei',
                        originalLabel: 'Hebei',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-DH',
                        label: 'Shanxi',
                        originalLabel: 'Shanxi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-DJ',
                        label: 'Indre Mongolia',
                        originalLabel: 'Indre Mongolia',
                        alternativeLabels: [],
                        note: 'Her: Nei Mongol',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FPC-CN-F',
                    label: 'Nordøstlige Kina',
                    originalLabel: 'Nordøstlige Kina',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FPC-CN-FB',
                        label: 'Liaoning',
                        originalLabel: 'Liaoning',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-FD',
                        label: 'Jilin',
                        originalLabel: 'Jilin',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-FF',
                        label: 'Heilongjiang',
                        originalLabel: 'Heilongjiang',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FPC-CN-H',
                    label: 'Østlige Kina',
                    originalLabel: 'Østlige Kina',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FPC-CN-HB',
                        label: 'Shanghai',
                        originalLabel: 'Shanghai',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-HD',
                        label: 'Jiangsu',
                        originalLabel: 'Jiangsu',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-HF',
                        label: 'Zhejiang',
                        originalLabel: 'Zhejiang',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-HH',
                        label: 'Anhui',
                        originalLabel: 'Anhui',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-HJ',
                        label: 'Fujian',
                        originalLabel: 'Fujian',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-HL',
                        label: 'Jiangxi',
                        originalLabel: 'Jiangxi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-HN',
                        label: 'Shandong',
                        originalLabel: 'Shandong',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FPC-CN-J',
                    label: 'Sentrale Kina',
                    originalLabel: 'Sentrale Kina',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FPC-CN-JB',
                        label: 'Henan',
                        originalLabel: 'Henan',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-JD',
                        label: 'Hubei',
                        originalLabel: 'Hubei',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-JF',
                        label: 'Hunan',
                        originalLabel: 'Hunan',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FPC-CN-L',
                    label: 'Sørlige Kina',
                    originalLabel: 'Sørlige Kina',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FPC-CN-LB',
                        label: 'Guangdong',
                        originalLabel: 'Guangdong',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-LD',
                        label: 'Guangxi',
                        originalLabel: 'Guangxi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-LF',
                        label: 'Hainan',
                        originalLabel: 'Hainan',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FPC-CN-N',
                    label: 'Sørvestlige Kina',
                    originalLabel: 'Sørvestlige Kina',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FPC-CN-NB',
                        label: 'Chongqing',
                        originalLabel: 'Chongqing',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-ND',
                        label: 'Sichuan',
                        originalLabel: 'Sichuan',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-NF',
                        label: 'Guizhou',
                        originalLabel: 'Guizhou',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-NH',
                        label: 'Yunnan',
                        originalLabel: 'Yunnan',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPCT',
                        label: 'Tibet',
                        originalLabel: 'Tibet',
                        alternativeLabels: [],
                        note: 'Her: Xizang',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FPC-CN-P',
                    label: 'Nordvestlige Kina',
                    originalLabel: 'Nordvestlige Kina',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FPC-CN-PB',
                        label: 'Shaanxi',
                        originalLabel: 'Shaanxi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-PD',
                        label: 'Gansu',
                        originalLabel: 'Gansu',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-PF',
                        label: 'Qinghai',
                        originalLabel: 'Qinghai',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-PH',
                        label: 'Ningxia',
                        originalLabel: 'Ningxia',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPC-CN-PJ',
                        label: 'Xinjiang',
                        originalLabel: 'Xinjiang',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FPCH',
                    label: 'Hong Kong',
                    originalLabel: 'Hong Kong',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FPCM',
                    label: 'Macao',
                    originalLabel: 'Macao',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FPCW',
                    label: 'Taiwan',
                    originalLabel: 'Taiwan',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FPJ',
                label: 'Japan',
                originalLabel: 'Japan',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FPJ-JP-A',
                    label: 'Hokkaido (region)',
                    originalLabel: 'Hokkaido (region)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FPJ-JP-AA',
                        label: 'Hokkaido',
                        originalLabel: 'Hokkaido',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FPJ-JP-B',
                    label: 'Tohoku',
                    originalLabel: 'Tohoku',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FPJ-JP-BA',
                        label: 'Aomori',
                        originalLabel: 'Aomori',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-BB',
                        label: 'Iwate',
                        originalLabel: 'Iwate',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-BC',
                        label: 'Miyagi',
                        originalLabel: 'Miyagi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-BD',
                        label: 'Akita',
                        originalLabel: 'Akita',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-BE',
                        label: 'Yamagata',
                        originalLabel: 'Yamagata',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-BF',
                        label: 'Fukushima',
                        originalLabel: 'Fukushima',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FPJ-JP-C',
                    label: 'Kanto',
                    originalLabel: 'Kanto',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FPJ-JP-CA',
                        label: 'Ibaraki',
                        originalLabel: 'Ibaraki',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-CB',
                        label: 'Tochigi',
                        originalLabel: 'Tochigi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-CC',
                        label: 'Gunma',
                        originalLabel: 'Gunma',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-CD',
                        label: 'Saitama',
                        originalLabel: 'Saitama',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-CE',
                        label: 'Chiba',
                        originalLabel: 'Chiba',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-CF',
                        label: 'Tokyo',
                        originalLabel: 'Tokyo',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-CG',
                        label: 'Kanagawa',
                        originalLabel: 'Kanagawa',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FPJ-JP-D',
                    label: 'Chubu',
                    originalLabel: 'Chubu',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FPJ-JP-DA',
                        label: 'Niigata',
                        originalLabel: 'Niigata',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-DB',
                        label: 'Toyama',
                        originalLabel: 'Toyama',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-DC',
                        label: 'Ishikawa',
                        originalLabel: 'Ishikawa',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-DD',
                        label: 'Fukui',
                        originalLabel: 'Fukui',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-DE',
                        label: 'Yamanashi',
                        originalLabel: 'Yamanashi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-DF',
                        label: 'Nagano',
                        originalLabel: 'Nagano',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-DG',
                        label: 'Gifu',
                        originalLabel: 'Gifu',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-DH',
                        label: 'Shizuoka',
                        originalLabel: 'Shizuoka',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-DJ',
                        label: 'Aichi',
                        originalLabel: 'Aichi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FPJ-JP-E',
                    label: 'Kinki / Kansai',
                    originalLabel: 'Kinki / Kansai',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FPJ-JP-EA',
                        label: 'Mie',
                        originalLabel: 'Mie',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-EB',
                        label: 'Shiga',
                        originalLabel: 'Shiga',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-EC',
                        label: 'Kyoto',
                        originalLabel: 'Kyoto',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-ED',
                        label: 'Osaka',
                        originalLabel: 'Osaka',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-EE',
                        label: 'Hyogo',
                        originalLabel: 'Hyogo',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-EF',
                        label: 'Nara',
                        originalLabel: 'Nara',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-EG',
                        label: 'Wakayama',
                        originalLabel: 'Wakayama',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FPJ-JP-F',
                    label: 'Chugoku',
                    originalLabel: 'Chugoku',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FPJ-JP-FA',
                        label: 'Tottori',
                        originalLabel: 'Tottori',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-FB',
                        label: 'Shimane',
                        originalLabel: 'Shimane',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-FC',
                        label: 'Okayama',
                        originalLabel: 'Okayama',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-FD',
                        label: 'Hiroshima',
                        originalLabel: 'Hiroshima',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-FE',
                        label: 'Yamaguchi',
                        originalLabel: 'Yamaguchi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FPJ-JP-G',
                    label: 'Shikoku',
                    originalLabel: 'Shikoku',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FPJ-JP-GA',
                        label: 'Tokushima',
                        originalLabel: 'Tokushima',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-GB',
                        label: 'Kagawa',
                        originalLabel: 'Kagawa',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-GC',
                        label: 'Ehime',
                        originalLabel: 'Ehime',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-GD',
                        label: 'Kochi (JP)',
                        originalLabel: 'Kochi (JP)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1FPJ-JP-H',
                    label: 'Kyushu',
                    originalLabel: 'Kyushu',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FPJ-JP-HA',
                        label: 'Fukuoka',
                        originalLabel: 'Fukuoka',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-HB',
                        label: 'Saga (prefektur)',
                        originalLabel: 'Saga (prefektur)',
                        alternativeLabels: [],
                        note: 'Her: Saga (by)',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-HC',
                        label: 'Nagasaki',
                        originalLabel: 'Nagasaki',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-HD',
                        label: 'Kumamoto',
                        originalLabel: 'Kumamoto',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-HE',
                        label: 'Oita',
                        originalLabel: 'Oita',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-HF',
                        label: 'Miyazaki',
                        originalLabel: 'Miyazaki',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-HG',
                        label: 'Kagoshima',
                        originalLabel: 'Kagoshima',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FPJ-JP-HH',
                        label: 'Okinawa',
                        originalLabel: 'Okinawa',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1FPK',
                label: 'Korea',
                originalLabel: 'Korea',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FPKN',
                    label: 'Nord-Korea',
                    originalLabel: 'Nord-Korea',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FPKS',
                    label: 'Sør-Korea',
                    originalLabel: 'Sør-Korea',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FPKS-KR-B',
                        label: 'Seoul (hovedstadsområdet / sudogwon)',
                        originalLabel: 'Seoul (hovedstadsområdet / sudogwon)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FPKS-KR-BA',
                            label: 'Seoul (by)',
                            originalLabel: 'Seoul (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FPKS-KR-BC',
                            label: 'Incheon',
                            originalLabel: 'Incheon',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FPKS-KR-BD',
                            label: 'Gyeonggi-do (Gyeonggi-provinsen)',
                            originalLabel: 'Gyeonggi-do (Gyeonggi-provinsen)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1FPKS-KR-C',
                        label: 'Gangwon og Chungcheong (region)',
                        originalLabel: 'Gangwon og Chungcheong (region)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FPKS-KR-CA',
                            label: 'Gangwon-do (Gangwon-provinsen)',
                            originalLabel: 'Gangwon-do (Gangwon-provinsen)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FPKS-KR-CB',
                            label:
                              'Chungcheongbuk-do (Nord-Chungcheong-provinsen)',
                            originalLabel:
                              'Chungcheongbuk-do (Nord-Chungcheong-provinsen)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FPKS-KR-CD',
                            label:
                              'Chungcheongnam-do (Sør-Chungcheong-provinsen)',
                            originalLabel:
                              'Chungcheongnam-do (Sør-Chungcheong-provinsen)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FPKS-KR-CE',
                            label: 'Daejeon',
                            originalLabel: 'Daejeon',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FPKS-KR-CF',
                            label: 'Sejong',
                            originalLabel: 'Sejong',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1FPKS-KR-D',
                        label: 'Yeongnam (region)',
                        originalLabel: 'Yeongnam (region)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FPKS-KR-DA',
                            label:
                              'Gyeongsangbuk-do (Nord-Gyeongsang-provinsen)',
                            originalLabel:
                              'Gyeongsangbuk-do (Nord-Gyeongsang-provinsen)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FPKS-KR-DB',
                            label:
                              'Gyeongsangnam-do (Sør-Gyeongsang-provinsen)',
                            originalLabel:
                              'Gyeongsangnam-do (Sør-Gyeongsang-provinsen)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FPKS-KR-DC',
                            label: 'Daegu',
                            originalLabel: 'Daegu',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FPKS-KR-DE',
                            label: 'Busan',
                            originalLabel: 'Busan',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FPKS-KR-DF',
                            label: 'Ulsan',
                            originalLabel: 'Ulsan',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FPKS-KR-DK',
                            label: 'Dokdo',
                            originalLabel: 'Dokdo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1FPKS-KR-F',
                        label: 'Honam region',
                        originalLabel: 'Honam region',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FPKS-KR-FA',
                            label: 'Jeollabuk-do (Nord-Jeolla-provinsen)',
                            originalLabel:
                              'Jeollabuk-do (Nord-Jeolla-provinsen)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FPKS-KR-FB',
                            label: 'Jeollanam-do (Sør-Jeolla-provinsen)',
                            originalLabel:
                              'Jeollanam-do (Sør-Jeolla-provinsen)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1FPKS-KR-FC',
                            label: 'Gwangju',
                            originalLabel: 'Gwangju',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1FPKS-KR-J',
                        label: 'Jeju-do (Jeju-provinsen)',
                        originalLabel: 'Jeju-do (Jeju-provinsen)',
                        alternativeLabels: [],
                        note: 'Her: Hallasan nasjonalpark',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1FPM',
                label: 'Mongolia',
                originalLabel: 'Mongolia',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '1FZ',
            label: 'Asia: fysiske aspekter',
            originalLabel: 'Asia: fysiske aspekter',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1FZA',
                label: 'Asia: elver, innsjøer etc.',
                originalLabel: 'Asia: elver, innsjøer etc.',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FZAG',
                    label: 'Ganges og sideelver',
                    originalLabel: 'Ganges og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1FZAJ',
                    label: 'Indus og sideelver',
                    originalLabel: 'Indus og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1FZAM',
                    label: 'Mekong og sideelver',
                    originalLabel: 'Mekong og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1FZAX',
                    label: 'Chang Jiang og sideelver',
                    originalLabel: 'Chang Jiang og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1FZAY',
                    label: 'Huang He og sideelver',
                    originalLabel: 'Huang He og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1FZT',
                label: 'Asia: fjell, åser, sletter, kystlinjer etc.',
                originalLabel: 'Asia: fjell, åser, sletter, kystlinjer etc.',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1FZTA',
                    label: 'Altajfjellene',
                    originalLabel: 'Altajfjellene',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FZTG',
                    label: 'Gobiørkenen',
                    originalLabel: 'Gobiørkenen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1FZTH',
                    label: 'Himalaya',
                    originalLabel: 'Himalaya',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1FZTHA',
                        label: 'Annapurna',
                        originalLabel: 'Annapurna',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1FZTHK',
                        label: 'Karakorum',
                        originalLabel: 'Karakorum',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1FZTHKK',
                            label: 'K2',
                            originalLabel: 'K2',
                            alternativeLabels: [],
                            note: 'Her: Godwin-Austen eller Chhogori',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1FZTHM',
                        label: 'Mahalangur Himal',
                        originalLabel: 'Mahalangur Himal',
                        alternativeLabels: [],
                        note: 'Her: Makalu, Lhotse, Cho Oyu',
                        related: [],
                        children: [
                          {
                            id: '1FZTHME',
                            label: 'Mount Everest',
                            originalLabel: 'Mount Everest',
                            alternativeLabels: [],
                            note: 'Her: Chomolungma',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: '1H',
        label: 'Afrika',
        originalLabel: 'Afrika',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '1HB',
            label: 'Nord-Afrika',
            originalLabel: 'Nord-Afrika',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1HBA',
                label: 'Algerie',
                originalLabel: 'Algerie',
                alternativeLabels: [],
                note: 'Her: Oran, Constantine, Sétif, El Oued, Annaba',
                related: [],
                children: [
                  {
                    id: '1HBA-DZ-A',
                    label: 'Alger',
                    originalLabel: 'Alger',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1HBC',
                label: 'Tsjad',
                originalLabel: 'Tsjad',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1HBE',
                label: 'Egypt',
                originalLabel: 'Egypt',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1HBE-AA-D',
                    label: 'Nildalen og -deltaet',
                    originalLabel: 'Nildalen og -deltaet',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HBE-AA-DA',
                        label: 'Alexandria',
                        originalLabel: 'Alexandria',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-DB',
                        label: 'Aswan',
                        originalLabel: 'Aswan',
                        alternativeLabels: [],
                        note: 'Her: Abu Simbel',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-DC',
                        label: 'Kairo',
                        originalLabel: 'Kairo',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-DD',
                        label: 'Al Buhayrah',
                        originalLabel: 'Al Buhayrah',
                        alternativeLabels: ['Beheira'],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-DF',
                        label: 'Beni Suef',
                        originalLabel: 'Beni Suef',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-DG',
                        label: 'Ad Daqahliyah',
                        originalLabel: 'Ad Daqahliyah',
                        alternativeLabels: ['Dakahlia'],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-DH',
                        label: 'Damietta',
                        originalLabel: 'Damietta',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-DJ',
                        label: 'Al Gharbiyah',
                        originalLabel: 'Al Gharbiyah',
                        alternativeLabels: ['Gharbia'],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-DK',
                        label: 'Giza',
                        originalLabel: 'Giza',
                        alternativeLabels: [],
                        note: 'Her: Memfis, Pyramidene',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-DL',
                        label: 'Kafr el-Sheikh',
                        originalLabel: 'Kafr el-Sheikh',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-DN',
                        label: 'Al-Minya',
                        originalLabel: 'Al-Minya',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-DP',
                        label: 'Al Minufiyah',
                        originalLabel: 'Al Minufiyah',
                        alternativeLabels: ['Monufia'],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-DQ',
                        label: 'Al Qalyubia',
                        originalLabel: 'Al Qalyubia',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-DS',
                        label: 'Ash Sharqiyah',
                        originalLabel: 'Ash Sharqiyah',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-DW',
                        label: 'Sohag',
                        originalLabel: 'Sohag',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-DX',
                        label: 'Luxor',
                        originalLabel: 'Luxor',
                        alternativeLabels: [],
                        note: 'Her: Teben, Kongenes dal',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HBE-AA-J',
                    label: 'Egypt: Vestørkenen',
                    originalLabel: 'Egypt: Vestørkenen',
                    alternativeLabels: ['Den libyske ørken'],

                    related: [],
                    children: [
                      {
                        id: '1HBE-AA-JF',
                        label: 'Faiyum',
                        originalLabel: 'Faiyum',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-JM',
                        label: 'Matruh',
                        originalLabel: 'Matruh',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-JV',
                        label: 'Al Wadi al Jadid (region)',
                        originalLabel: 'Al Wadi al Jadid (region)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HBE-AA-M',
                    label: 'Egypt: Østørkenen',
                    originalLabel: 'Egypt: Østørkenen',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HBE-AA-MA',
                        label: 'Asyut',
                        originalLabel: 'Asyut',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-MQ',
                        label: 'Qena',
                        originalLabel: 'Qena',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-MR',
                        label: 'Al-Bahr al-Ahmar (region)',
                        originalLabel: 'Al-Bahr al-Ahmar (region)',
                        alternativeLabels: ['Rødehavet'],
                        note: 'Se også 1QSL <a href="/thema/nb/1QSL">Rødehavet</a>',
                        related: [
                          {
                            id: '1QSL',
                            label: 'Rødehavet',
                            originalLabel: 'Rødehavet',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HBE-AA-S',
                    label: 'Sinaihalvøya',
                    originalLabel: 'Sinaihalvøya',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HBE-AA-SL',
                        label: 'Ismailia',
                        originalLabel: 'Ismailia',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-SN',
                        label: 'Nord-Sinai',
                        originalLabel: 'Nord-Sinai',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-SP',
                        label: 'Port Said',
                        originalLabel: 'Port Said',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-SS',
                        label: 'Sør-Sinai',
                        originalLabel: 'Sør-Sinai',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBE-AA-SZ',
                        label: 'Suez',
                        originalLabel: 'Suez',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1HBL',
                label: 'Libya',
                originalLabel: 'Libya',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1HBL-LY-C',
                    label: 'Kyrenaika',
                    originalLabel: 'Kyrenaika',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HBL-LY-CB',
                        label: 'Benghazi',
                        originalLabel: 'Benghazi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HBL-LY-T',
                    label: 'Tripolitania',
                    originalLabel: 'Tripolitania',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HBL-LY-TT',
                        label: 'Tripoli',
                        originalLabel: 'Tripoli',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1HBM',
                label: 'Marokko',
                originalLabel: 'Marokko',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1HBM-MA-B',
                    label: 'Tanger-Tetouan-Al Hoceima',
                    originalLabel: 'Tanger-Tetouan-Al Hoceima',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HBM-MA-BC',
                        label: 'Tanger',
                        originalLabel: 'Tanger',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HBM-MA-F',
                    label: 'Fès-Meknès',
                    originalLabel: 'Fès-Meknès',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HBM-MA-FE',
                        label: 'Fez',
                        originalLabel: 'Fez',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HBM-MA-H',
                    label: 'Rabat-Salé-Kenitra',
                    originalLabel: 'Rabat-Salé-Kenitra',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HBM-MA-HR',
                        label: 'Rabat',
                        originalLabel: 'Rabat',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HBM-MA-L',
                    label: 'Casablanca-Settat',
                    originalLabel: 'Casablanca-Settat',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HBM-MA-LC',
                        label: 'Casablanca',
                        originalLabel: 'Casablanca',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HBM-MA-M',
                    label: 'Marrakesh-Safi',
                    originalLabel: 'Marrakesh-Safi',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HBM-MA-ME',
                        label: 'Essaouira',
                        originalLabel: 'Essaouira',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HBM-MA-MM',
                        label: 'Marrakesh / Marrakech',
                        originalLabel: 'Marrakesh / Marrakech',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HBM-MA-P',
                    label: 'Drâa-Tafilalet',
                    originalLabel: 'Drâa-Tafilalet',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HBM-MA-PO',
                        label: 'Ouarzazate',
                        originalLabel: 'Ouarzazate',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HBM-MA-R',
                    label: 'Souss-Massa',
                    originalLabel: 'Souss-Massa',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HBM-MA-RA',
                        label: 'Agadir',
                        originalLabel: 'Agadir',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1HBS',
                label: 'Sudan',
                originalLabel: 'Sudan',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1HBS-SD-B',
                    label: 'Khartoum',
                    originalLabel: 'Khartoum',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1HBT',
                label: 'Tunisia',
                originalLabel: 'Tunisia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1HBT-TN-A',
                    label: 'Tunis',
                    originalLabel: 'Tunis',
                    alternativeLabels: [],
                    note: 'Her: Kartago',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1HBT-TN-D',
                    label: 'Djerba / Jerba',
                    originalLabel: 'Djerba / Jerba',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1HBW',
                label: 'Vest-Sahara',
                originalLabel: 'Vest-Sahara',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1HBZ',
                label: 'Sør-Sudan',
                originalLabel: 'Sør-Sudan',
                alternativeLabels: ['Juba'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '1HF',
            label: 'Afrika sør for Sahara',
            originalLabel: 'Afrika sør for Sahara',
            alternativeLabels: [],
            note: 'Her: subsaharisk Afrika',
            related: [],
            children: [
              {
                id: '1HFD',
                label: 'Vest-Afrika',
                originalLabel: 'Vest-Afrika',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1HFDA',
                    label: 'Mauritania',
                    originalLabel: 'Mauritania',
                    alternativeLabels: ['Nouakchott'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFDB',
                    label: 'Benin',
                    originalLabel: 'Benin',
                    alternativeLabels: ['Dahomey'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFDE',
                    label: 'Sierra Leone',
                    originalLabel: 'Sierra Leone',
                    alternativeLabels: ['Freetown'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFDF',
                    label: 'Burkina Faso',
                    originalLabel: 'Burkina Faso',
                    alternativeLabels: ['Ouagadougou'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFDG',
                    label: 'Gambia',
                    originalLabel: 'Gambia',
                    alternativeLabels: ['Banjul'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFDH',
                    label: 'Ghana',
                    originalLabel: 'Ghana',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HFDH-GH-A',
                        label: 'Accra',
                        originalLabel: 'Accra',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HFDL',
                    label: 'Liberia',
                    originalLabel: 'Liberia',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HFDL-LR-A',
                        label: 'Monrovia',
                        originalLabel: 'Monrovia',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HFDM',
                    label: 'Mali',
                    originalLabel: 'Mali',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HFDM-ML-A',
                        label: 'Timbuktu',
                        originalLabel: 'Timbuktu',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HFDN',
                    label: 'Nigeria',
                    originalLabel: 'Nigeria',
                    alternativeLabels: [],
                    note: 'Her: Abuja',
                    related: [],
                    children: [
                      {
                        id: '1HFDN-NG-A',
                        label: 'Lagos',
                        originalLabel: 'Lagos',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HFDN-NG-R',
                        label: 'Nigerdeltaet (region)',
                        originalLabel: 'Nigerdeltaet (region)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HFDR',
                    label: 'Niger',
                    originalLabel: 'Niger',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFDS',
                    label: 'Senegal',
                    originalLabel: 'Senegal',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HFDS-SN-A',
                        label: 'Dakar',
                        originalLabel: 'Dakar',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HFDT',
                    label: 'Togo',
                    originalLabel: 'Togo',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFDU',
                    label: 'Guinea',
                    originalLabel: 'Guinea',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HFDU-GN-A',
                        label: 'Conakry',
                        originalLabel: 'Conakry',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HFDV',
                    label: 'Kapp Verde',
                    originalLabel: 'Kapp Verde',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFDX',
                    label: 'Guinea-Bissau',
                    originalLabel: 'Guinea-Bissau',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFDY',
                    label: 'Elfenbenskysten',
                    originalLabel: 'Elfenbenskysten',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HFDY-CI-A',
                        label: 'Abidjan',
                        originalLabel: 'Abidjan',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1HFG',
                label: 'Øst-Afrika',
                originalLabel: 'Øst-Afrika',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1HFGA',
                    label: 'Etiopia',
                    originalLabel: 'Etiopia',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HFGA-ET-A',
                        label: 'Addis Abeba',
                        originalLabel: 'Addis Abeba',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HFGD',
                    label: 'Djibouti',
                    originalLabel: 'Djibouti',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFGE',
                    label: 'Eritrea',
                    originalLabel: 'Eritrea',
                    alternativeLabels: ['Asmara'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFGK',
                    label: 'Kenya',
                    originalLabel: 'Kenya',
                    alternativeLabels: ['Maasai Mara'],
                    note: 'Her: Masai Mara, Tsavo, Mount Kenya, Kisumu',
                    related: [],
                    children: [
                      {
                        id: '1HFGK-KE-A',
                        label: 'Nairobi',
                        originalLabel: 'Nairobi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HFGK-KE-M',
                        label: 'Mombasa',
                        originalLabel: 'Mombasa',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HFGQ',
                    label: 'Burundi',
                    originalLabel: 'Burundi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFGR',
                    label: 'Rwanda',
                    originalLabel: 'Rwanda',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HFGR-RW-A',
                        label: 'Kigali',
                        originalLabel: 'Kigali',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HFGS',
                    label: 'Somalia',
                    originalLabel: 'Somalia',
                    alternativeLabels: ['Mogadishu'],

                    related: [],
                    children: [
                      {
                        id: '1HFGSR',
                        label: 'Somaliland',
                        originalLabel: 'Somaliland',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HFGT',
                    label: 'Tanzania',
                    originalLabel: 'Tanzania',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HFGT-TZ-B',
                        label: 'Dar es Salaam',
                        originalLabel: 'Dar es Salaam',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HFGT-TZ-C',
                        label: 'Zanzibar',
                        originalLabel: 'Zanzibar',
                        alternativeLabels: ['Unguja', 'Pemb'],
                        note: 'Her: øygruppen Zanzibar',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1HFGT-TZ-E',
                        label: 'Nordlige Tanzania',
                        originalLabel: 'Nordlige Tanzania',
                        alternativeLabels: [],
                        note: 'Her: Ngorongoro naturvernområde, Ngorongoro-krateret, Olduvai Gorge, Tarangire nasjonalpark, Manyarasjøen',
                        related: [],
                        children: [
                          {
                            id: '1HFGT-TZ-EB',
                            label: 'Kilimanjaro',
                            originalLabel: 'Kilimanjaro',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1HFGT-TZ-ED',
                            label: 'Serengeti',
                            originalLabel: 'Serengeti',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1HFGU',
                    label: 'Uganda',
                    originalLabel: 'Uganda',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HFGU-UG-A',
                        label: 'Kampala',
                        originalLabel: 'Kampala',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1HFJ',
                label: 'Sentral-Afrika',
                originalLabel: 'Sentral-Afrika',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1HFJA',
                    label: 'Kamerun',
                    originalLabel: 'Kamerun',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFJC',
                    label: 'Republikken Kongo (Kongo-Brazzaville)',
                    originalLabel: 'Republikken Kongo (Kongo-Brazzaville)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HFJC-CG-A',
                        label: 'Brazzaville',
                        originalLabel: 'Brazzaville',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HFJG',
                    label: 'Gabon',
                    originalLabel: 'Gabon',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFJQ',
                    label: 'Ekvatorial-Guinea',
                    originalLabel: 'Ekvatorial-Guinea',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFJR',
                    label: 'Den sentralafrikanske republikk',
                    originalLabel: 'Den sentralafrikanske republikk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFJS',
                    label: 'São Tomé og Príncipe',
                    originalLabel: 'São Tomé og Príncipe',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFJZ',
                    label: 'Den demokratiske republikken Kongo',
                    originalLabel: 'Den demokratiske republikken Kongo',
                    alternativeLabels: [],
                    note: 'Her: Kongo-Kinshasa (tidligere Zaïre), Garamba, Salonga, nasjonalparkene Kahuzi-Biega og Virunga, Goma, Kisangani',
                    related: [],
                    children: [
                      {
                        id: '1HFJZ-CD-A',
                        label: 'Kinshasa',
                        originalLabel: 'Kinshasa',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1HFM',
                label: 'Sørlige Afrika',
                originalLabel: 'Sørlige Afrika',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1HFMA',
                    label: 'Angola',
                    originalLabel: 'Angola',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFMB',
                    label: 'Botswana',
                    originalLabel: 'Botswana',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HFMB-BW-A',
                        label: 'Gaborone',
                        originalLabel: 'Gaborone',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HFMB-BW-V',
                        label: 'Okavangoelven og -deltaet',
                        originalLabel: 'Okavangoelven og -deltaet',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver og elvedalen',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HFMK',
                    label: 'Eswatini',
                    originalLabel: 'Eswatini',
                    alternativeLabels: [],
                    note: 'Her: kongedømmet Eswatini, Swaziland, Lobamba, Mbabane',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFML',
                    label: 'Lesotho',
                    originalLabel: 'Lesotho',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFMM',
                    label: 'Malawi',
                    originalLabel: 'Malawi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFMN',
                    label: 'Namibia',
                    originalLabel: 'Namibia',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HFMQ',
                    label: 'Mosambik',
                    originalLabel: 'Mosambik',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HFMQ-MZ-A',
                        label: 'Maputo',
                        originalLabel: 'Maputo',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HFMS',
                    label: 'Sør-Afrika',
                    originalLabel: 'Sør-Afrika',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HFMS-ZA-A',
                        label: 'Eastern Cape',
                        originalLabel: 'Eastern Cape',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HFMS-ZA-B',
                        label: 'Free State',
                        originalLabel: 'Free State',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1HFMS-ZA-BB',
                            label: 'Bloemfontein',
                            originalLabel: 'Bloemfontein',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1HFMS-ZA-D',
                        label: 'Gauteng',
                        originalLabel: 'Gauteng',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1HFMS-ZA-DB',
                            label: 'Johannesburg',
                            originalLabel: 'Johannesburg',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1HFMS-ZA-DD',
                            label: 'Pretoria',
                            originalLabel: 'Pretoria',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1HFMS-ZA-F',
                        label: 'KwaZulu-Natal',
                        originalLabel: 'KwaZulu-Natal',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1HFMS-ZA-FB',
                            label: 'Durban',
                            originalLabel: 'Durban',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1HFMS-ZA-H',
                        label: 'Limpopo',
                        originalLabel: 'Limpopo',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HFMS-ZA-J',
                        label: 'Mpumalanga',
                        originalLabel: 'Mpumalanga',
                        alternativeLabels: [],
                        note: 'Her: Øst-Transvaal',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1HFMS-ZA-K',
                        label: 'North West Province',
                        originalLabel: 'North West Province',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HFMS-ZA-M',
                        label: 'Northern Cape',
                        originalLabel: 'Northern Cape',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1HFMS-ZA-N',
                        label: 'Western Cape',
                        originalLabel: 'Western Cape',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1HFMS-ZA-NB',
                            label: 'Cape Town',
                            originalLabel: 'Cape Town',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1HFMS-ZA-Z',
                        label: 'Sør-Afrika: reisemål',
                        originalLabel: 'Sør-Afrika: reisemål',
                        alternativeLabels: [],
                        note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                        related: [],
                        children: [
                          {
                            id: '1HFMS-ZA-ZD',
                            label: 'Drakensberg',
                            originalLabel: 'Drakensberg',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1HFMS-ZA-ZK',
                            label: 'Kruger nasjonalpark',
                            originalLabel: 'Kruger nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1HFMW',
                    label: 'Zimbabwe',
                    originalLabel: 'Zimbabwe',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HFMW-ZW-A',
                        label: 'Harare',
                        originalLabel: 'Harare',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1HFMZ',
                    label: 'Zambia',
                    originalLabel: 'Zambia',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1HFMZ-ZM-A',
                        label: 'Lusaka',
                        originalLabel: 'Lusaka',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: '1HS',
            label: 'Øyer i Sør-Indiahavet',
            originalLabel: 'Øyer i Sør-Indiahavet',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1HSC',
                label: 'Komorene',
                originalLabel: 'Komorene',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1HS-FR-L',
                label: 'La Réunion',
                originalLabel: 'La Réunion',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1HS-FR-LA',
                    label: 'Saint-Denis (Réunion)',
                    originalLabel: 'Saint-Denis (Réunion)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HS-FR-LB',
                    label: 'Saint-Benoît',
                    originalLabel: 'Saint-Benoît',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HS-FR-LC',
                    label: 'Saint-Paul',
                    originalLabel: 'Saint-Paul',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HS-FR-LD',
                    label: 'Saint-Pierre (Réunion)',
                    originalLabel: 'Saint-Pierre (Réunion)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1HS-FR-M',
                label: 'Mayotte',
                originalLabel: 'Mayotte',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1HS-FR-MA',
                    label: 'Dzaoudzi',
                    originalLabel: 'Dzaoudzi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1HSM',
                label: 'Madagaskar',
                originalLabel: 'Madagaskar',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1HSU',
                label: 'Mauritius',
                originalLabel: 'Mauritius',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1HSU-MU-B',
                    label: 'Rodrigues',
                    originalLabel: 'Rodrigues',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1HSV',
                label: 'Maldivene',
                originalLabel: 'Maldivene',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1HSY',
                label: 'Seychellene',
                originalLabel: 'Seychellene',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '1HZ',
            label: 'Afrika: fysiske aspekter',
            originalLabel: 'Afrika: fysiske aspekter',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1HZA',
                label: 'Afrika: elver, innsjøer etc.',
                originalLabel: 'Afrika: elver, innsjøer etc.',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1HZAC',
                    label: 'Kongo og sideelver',
                    originalLabel: 'Kongo og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1HZAG',
                    label: 'Niger og sideelver',
                    originalLabel: 'Niger og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1HZAM',
                    label: 'Malawisjøen',
                    originalLabel: 'Malawisjøen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HZAN',
                    label: 'Nilen og sideelver',
                    originalLabel: 'Nilen og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet. Se også 1HBE-AA-D <a href="/thema/nb/1HBE-AA-D">Nildalen og -deltaet</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1HZAS',
                    label: 'Suezkanalen',
                    originalLabel: 'Suezkanalen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HZAT',
                    label: 'Tanganyikasjøen',
                    originalLabel: 'Tanganyikasjøen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HZAV',
                    label: 'Victoriasjøen',
                    originalLabel: 'Victoriasjøen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HZAZ',
                    label: 'Zambezi og sideelver',
                    originalLabel: 'Zambezi og sideelver',
                    alternativeLabels: [],
                    note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                    related: [],
                    children: [
                      {
                        id: '1HZAZV',
                        label: 'Mosi-oa-Tunya / Victoriafallene',
                        originalLabel: 'Mosi-oa-Tunya / Victoriafallene',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1HZT',
                label: 'Afrika: fjell, åser, sletter, kystlinjer etc.',
                originalLabel: 'Afrika: fjell, åser, sletter, kystlinjer etc.',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1HZTA',
                    label: 'Atlasfjellene',
                    originalLabel: 'Atlasfjellene',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HZTK',
                    label: 'Kalahariørkenen',
                    originalLabel: 'Kalahariørkenen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HZTR',
                    label: 'Riftdalen',
                    originalLabel: 'Riftdalen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HZTS',
                    label: 'Sahara',
                    originalLabel: 'Sahara',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1HZTT',
                    label: 'Sahel',
                    originalLabel: 'Sahel',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: '1K',
        label: 'Amerika',
        originalLabel: 'Amerika',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '1KB',
            label: 'Nord-Amerika (USA og Canada)',
            originalLabel: 'Nord-Amerika (USA og Canada)',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1KBB',
                label: 'USA',
                originalLabel: 'USA',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1KBB-US-M',
                    label: 'Midtvesten',
                    originalLabel: 'Midtvesten',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBB-US-ML',
                        label:
                          'Midtvesten: nordøstlige sentralstater (Great Lakes)',
                        originalLabel:
                          'Midtvesten: nordøstlige sentralstater (Great Lakes)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBB-US-MLC',
                            label: 'Illinois',
                            originalLabel: 'Illinois',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-MLCC',
                                label: 'Chicago',
                                originalLabel: 'Chicago',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-MLCS',
                                label: 'Springfield (IL)',
                                originalLabel: 'Springfield (IL)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-MLD',
                            label: 'Indiana',
                            originalLabel: 'Indiana',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-MLDD',
                                label: 'Indianapolis',
                                originalLabel: 'Indianapolis',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-MLG',
                            label: 'Michigan',
                            originalLabel: 'Michigan',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-MLGD',
                                label: 'Detroit',
                                originalLabel: 'Detroit',
                                alternativeLabels: [],
                                note: 'Her: Wayne County, Dearborn',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-MLO',
                            label: 'Ohio',
                            originalLabel: 'Ohio',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-MLOL',
                                label: 'Cleveland',
                                originalLabel: 'Cleveland',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-MLON',
                                label: 'Cincinnati',
                                originalLabel: 'Cincinnati',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-MLOO',
                                label: 'Columbus',
                                originalLabel: 'Columbus',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-MLT',
                            label: 'Minnesota',
                            originalLabel: 'Minnesota',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-MLTM',
                                label: 'Minneapolis-St. Paul',
                                originalLabel: 'Minneapolis-St. Paul',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-MLW',
                            label: 'Wisconsin',
                            originalLabel: 'Wisconsin',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-MLWM',
                                label: 'Milwaukee',
                                originalLabel: 'Milwaukee',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-MLWW',
                                label: 'Madison',
                                originalLabel: 'Madison',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1KBB-US-MP',
                        label:
                          'Midtvesten: nordvestlige sentralstater (Great Plains)',
                        originalLabel:
                          'Midtvesten: nordvestlige sentralstater (Great Plains)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBB-US-MPA',
                            label: 'Iowa',
                            originalLabel: 'Iowa',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBB-US-MPK',
                            label: 'Kansas',
                            originalLabel: 'Kansas',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBB-US-MPM',
                            label: 'Missouri',
                            originalLabel: 'Missouri',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-MPMK',
                                label: 'Kansas City (MO)',
                                originalLabel: 'Kansas City (MO)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-MPML',
                                label: 'St. Louis',
                                originalLabel: 'St. Louis',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-MPN',
                            label: 'Nebraska',
                            originalLabel: 'Nebraska',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-MPNL',
                                label: 'Lincoln',
                                originalLabel: 'Lincoln',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-MPNO',
                                label: 'Omaha',
                                originalLabel: 'Omaha',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-MPR',
                            label: 'North Dakota',
                            originalLabel: 'North Dakota',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBB-US-MPT',
                            label: 'South Dakota',
                            originalLabel: 'South Dakota',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-MPTR',
                                label: 'Mount Rushmore',
                                originalLabel: 'Mount Rushmore',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KBB-US-N',
                    label: 'Nordøststatene',
                    originalLabel: 'Nordøststatene',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBB-US-NA',
                        label: 'Nordøststatene: mellomatlantiske stater',
                        originalLabel:
                          'Nordøststatene: mellomatlantiske stater',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBB-US-NAJ',
                            label: 'New Jersey',
                            originalLabel: 'New Jersey',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-NAJA',
                                label: 'Atlantic City',
                                originalLabel: 'Atlantic City',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-NAJH',
                                label: 'Hoboken',
                                originalLabel: 'Hoboken',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-NAJN',
                                label: 'Newark',
                                originalLabel: 'Newark',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-NAJS',
                                label: 'Jersey Shore',
                                originalLabel: 'Jersey Shore',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-NAK',
                            label: 'New York',
                            originalLabel: 'New York',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-NAKA',
                                label: 'Albany',
                                originalLabel: 'Albany',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-NAKB',
                                label: 'Buffalo',
                                originalLabel: 'Buffalo',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-NAKC',
                                label: 'New York City',
                                originalLabel: 'New York City',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1KBB-US-NAKCB',
                                    label: 'Brooklyn',
                                    originalLabel: 'Brooklyn',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1KBB-US-NAKCM',
                                    label: 'Manhattan',
                                    originalLabel: 'Manhattan',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [
                                      {
                                        id: '1KBB-US-NAKCMG',
                                        label: 'Greenwich Village',
                                        originalLabel: 'Greenwich Village',
                                        alternativeLabels: [],

                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: '1KBB-US-NAKCMH',
                                        label: 'Harlem',
                                        originalLabel: 'Harlem',
                                        alternativeLabels: [],

                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                id: '1KBB-US-NAKL',
                                label: 'Long Island',
                                originalLabel: 'Long Island',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-NAKN',
                                label: 'Niagara Falls (by)',
                                originalLabel: 'Niagara Falls (by)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-NAP',
                            label: 'Pennsylvania',
                            originalLabel: 'Pennsylvania',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-NAPD',
                                label: 'Pennsylvania Dutch Country',
                                originalLabel: 'Pennsylvania Dutch Country',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-NAPG',
                                label: 'Gettysburg',
                                originalLabel: 'Gettysburg',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-NAPH',
                                label: 'Philadelphia',
                                originalLabel: 'Philadelphia',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-NAPT',
                                label: 'Pittsburgh',
                                originalLabel: 'Pittsburgh',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1KBB-US-NE',
                        label: 'Nordøststatene: New England',
                        originalLabel: 'Nordøststatene: New England',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBB-US-NEC',
                            label: 'Connecticut',
                            originalLabel: 'Connecticut',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-NECH',
                                label: 'Hartford',
                                originalLabel: 'Hartford',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-NEI',
                            label: 'Maine',
                            originalLabel: 'Maine',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-NEIA',
                                label: 'Acadia nasjonalpark',
                                originalLabel: 'Acadia nasjonalpark',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-NEIP',
                                label: 'Portland (ME)',
                                originalLabel: 'Portland (ME)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-NEM',
                            label: 'Massachusetts',
                            originalLabel: 'Massachusetts',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-NEMB',
                                label: 'Boston',
                                originalLabel: 'Boston',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-NEMC',
                                label: 'Cape Cod',
                                originalLabel: 'Cape Cod',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1KBB-US-NEMCM',
                                    label: 'Martha’s Vineyard',
                                    originalLabel: 'Martha’s Vineyard',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1KBB-US-NEMCN',
                                    label: 'Nantucket',
                                    originalLabel: 'Nantucket',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1KBB-US-NEMM',
                                label: 'Cambridge (MA)',
                                originalLabel: 'Cambridge (MA)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-NEMS',
                                label: 'Salem (MA)',
                                originalLabel: 'Salem (MA)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-NEN',
                            label: 'New Hampshire',
                            originalLabel: 'New Hampshire',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBB-US-NER',
                            label: 'Rhode Island',
                            originalLabel: 'Rhode Island',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-NERN',
                                label: 'Newport',
                                originalLabel: 'Newport',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-NERP',
                                label: 'Providence',
                                originalLabel: 'Providence',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-NEV',
                            label: 'Vermont',
                            originalLabel: 'Vermont',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KBB-US-S',
                    label: 'Sørstatene',
                    originalLabel: 'Sørstatene',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBB-US-SC',
                        label: 'Sørstatene: sørøstlige sentralstater',
                        originalLabel: 'Sørstatene: sørøstlige sentralstater',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBB-US-SCA',
                            label: 'Alabama',
                            originalLabel: 'Alabama',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-SCAM',
                                label: 'Montgomery',
                                originalLabel: 'Montgomery',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-SCK',
                            label: 'Kentucky',
                            originalLabel: 'Kentucky',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-SCKL',
                                label: 'Louisville',
                                originalLabel: 'Louisville',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-SCM',
                            label: 'Mississippi',
                            originalLabel: 'Mississippi',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBB-US-SCT',
                            label: 'Tennessee',
                            originalLabel: 'Tennessee',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-SCTM',
                                label: 'Memphis',
                                originalLabel: 'Memphis',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SCTN',
                                label: 'Nashville',
                                originalLabel: 'Nashville',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1KBB-US-SE',
                        label: 'Sørstatene: søratlantiske stater',
                        originalLabel: 'Sørstatene: søratlantiske stater',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBB-US-SEC',
                            label: 'District of Columbia (Washington D.C.)',
                            originalLabel:
                              'District of Columbia (Washington D.C.)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBB-US-SED',
                            label: 'Delaware',
                            originalLabel: 'Delaware',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBB-US-SEF',
                            label: 'Florida',
                            originalLabel: 'Florida',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-SEFA',
                                label: 'St. Augustine',
                                originalLabel: 'St. Augustine',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SEFE',
                                label: 'Everglades',
                                originalLabel: 'Everglades',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SEFH',
                                label: 'Tallahassee',
                                originalLabel: 'Tallahassee',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SEFJ',
                                label: 'Jacksonville',
                                originalLabel: 'Jacksonville',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SEFK',
                                label: 'Florida Keys',
                                originalLabel: 'Florida Keys',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SEFM',
                                label: 'Miami',
                                originalLabel: 'Miami',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SEFO',
                                label: 'Orlando',
                                originalLabel: 'Orlando',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SEFP',
                                label: 'Tampa',
                                originalLabel: 'Tampa',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-SEG',
                            label: 'Georgia (stat i USA)',
                            originalLabel: 'Georgia (stat i USA)',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-SEGS',
                                label: 'Savannah',
                                originalLabel: 'Savannah',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SEGT',
                                label: 'Atlanta',
                                originalLabel: 'Atlanta',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-SEM',
                            label: 'Maryland',
                            originalLabel: 'Maryland',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-SEMA',
                                label: 'Annapolis',
                                originalLabel: 'Annapolis',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SEMB',
                                label: 'Baltimore',
                                originalLabel: 'Baltimore',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-SEN',
                            label: 'Nord-Carolina',
                            originalLabel: 'Nord-Carolina',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-SENA',
                                label: 'Asheville',
                                originalLabel: 'Asheville',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SENB',
                                label: 'Outer Banks',
                                originalLabel: 'Outer Banks',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SENC',
                                label: 'Charlotte',
                                originalLabel: 'Charlotte',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SENR',
                                label: 'Raleigh',
                                originalLabel: 'Raleigh',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-SES',
                            label: 'Sør-Carolina',
                            originalLabel: 'Sør-Carolina',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-SESC',
                                label: 'Charleston (SC)',
                                originalLabel: 'Charleston (SC)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SESM',
                                label: 'Myrtle Beach',
                                originalLabel: 'Myrtle Beach',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-SEV',
                            label: 'Virginia',
                            originalLabel: 'Virginia',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-SEVC',
                                label: 'Charlottesville',
                                originalLabel: 'Charlottesville',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SEVR',
                                label: 'Richmond',
                                originalLabel: 'Richmond',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SEVV',
                                label: 'Virginia Beach',
                                originalLabel: 'Virginia Beach',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SEVW',
                                label: 'Williamsburg',
                                originalLabel: 'Williamsburg',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-SEW',
                            label: 'Vest-Virginia',
                            originalLabel: 'Vest-Virginia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBB-US-SG',
                        label: 'Great Smoky Mountains nasjonalpark',
                        originalLabel: 'Great Smoky Mountains nasjonalpark',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBB-US-SW',
                        label: 'Sørstatene: sørvestlige sentralstater',
                        originalLabel: 'Sørstatene: sørvestlige sentralstater',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBB-US-SWA',
                            label: 'Arkansas',
                            originalLabel: 'Arkansas',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-SWAH',
                                label: 'Hot Springs',
                                originalLabel: 'Hot Springs',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SWAL',
                                label: 'Little Rock',
                                originalLabel: 'Little Rock',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-SWL',
                            label: 'Louisiana',
                            originalLabel: 'Louisiana',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-SWLB',
                                label: 'Baton Rouge',
                                originalLabel: 'Baton Rouge',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SWLN',
                                label: 'New Orleans',
                                originalLabel: 'New Orleans',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-SWO',
                            label: 'Oklahoma',
                            originalLabel: 'Oklahoma',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-SWOC',
                                label: 'Oklahoma City',
                                originalLabel: 'Oklahoma City',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SWOT',
                                label: 'Tulsa',
                                originalLabel: 'Tulsa',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-SWT',
                            label: 'Texas',
                            originalLabel: 'Texas',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-SWTA',
                                label: 'Austin',
                                originalLabel: 'Austin',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SWTD',
                                label: 'Dallas',
                                originalLabel: 'Dallas',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SWTH',
                                label: 'Houston',
                                originalLabel: 'Houston',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SWTN',
                                label: 'San Antonio',
                                originalLabel: 'San Antonio',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SWTP',
                                label: 'El Paso',
                                originalLabel: 'El Paso',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-SWTW',
                                label: 'Forth Worth',
                                originalLabel: 'Forth Worth',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KBB-US-W',
                    label: 'Veststatene',
                    originalLabel: 'Veststatene',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBB-US-WM',
                        label: 'Veststatene: fjellstatene',
                        originalLabel: 'Veststatene: fjellstatene',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBB-US-WMA',
                            label: 'Arizona',
                            originalLabel: 'Arizona',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-WMAF',
                                label: 'Flagstaff',
                                originalLabel: 'Flagstaff',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WMAG',
                                label: 'Grand Canyon nasjonalpark',
                                originalLabel: 'Grand Canyon nasjonalpark',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WMAP',
                                label: 'Phoenix',
                                originalLabel: 'Phoenix',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WMAS',
                                label: 'Sedona',
                                originalLabel: 'Sedona',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WMAT',
                                label: 'Tucson',
                                originalLabel: 'Tucson',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-WMC',
                            label: 'Colorado',
                            originalLabel: 'Colorado',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-WMCA',
                                label: 'Aspen',
                                originalLabel: 'Aspen',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WMCB',
                                label: 'Boulder',
                                originalLabel: 'Boulder',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WMCD',
                                label: 'Denver',
                                originalLabel: 'Denver',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WMCR',
                                label: 'Rocky Mountains nasjonalpark',
                                originalLabel: 'Rocky Mountains nasjonalpark',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-WMD',
                            label: 'Idaho',
                            originalLabel: 'Idaho',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-WMDB',
                                label: 'Boise',
                                originalLabel: 'Boise',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-WMM',
                            label: 'Montana',
                            originalLabel: 'Montana',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-WMMG',
                                label: 'Glacier nasjonalpark',
                                originalLabel: 'Glacier nasjonalpark',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-WMN',
                            label: 'Nevada',
                            originalLabel: 'Nevada',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-WMNR',
                                label: 'Reno',
                                originalLabel: 'Reno',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WMNV',
                                label: 'Las Vegas',
                                originalLabel: 'Las Vegas',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-WMT',
                            label: 'New Mexico',
                            originalLabel: 'New Mexico',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-WMTA',
                                label: 'Albuquerque',
                                originalLabel: 'Albuquerque',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WMTF',
                                label: 'Santa Fe',
                                originalLabel: 'Santa Fe',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WMTT',
                                label: 'Taos',
                                originalLabel: 'Taos',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-WMU',
                            label: 'Utah',
                            originalLabel: 'Utah',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-WMUS',
                                label: 'Salt Lake City',
                                originalLabel: 'Salt Lake City',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WMUZ',
                                label: 'Zion og andre nasjonalparker i Utah',
                                originalLabel:
                                  'Zion og andre nasjonalparker i Utah',
                                alternativeLabels: [
                                  'Dinosaur and Grand Staircase–Escalante National Monuments',
                                ],
                                note: 'Her: Bryce Canyon, Canyonlands, Arches, Capitol Reef nasjonalpark',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-WMW',
                            label: 'Wyoming',
                            originalLabel: 'Wyoming',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-WMWC',
                                label: 'Cheyenne (WY)',
                                originalLabel: 'Cheyenne (WY)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WMWJ',
                                label: 'Jackson Hole',
                                originalLabel: 'Jackson Hole',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WMWT',
                                label: 'Grand Teton nasjonalpark',
                                originalLabel: 'Grand Teton nasjonalpark',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-WMY',
                            label: 'Yellowstone nasjonalpark',
                            originalLabel: 'Yellowstone nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBB-US-WP',
                        label: 'Veststatene: stillehavsstatene',
                        originalLabel: 'Veststatene: stillehavsstatene',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBB-US-WPC',
                            label: 'California',
                            originalLabel: 'California',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-WPCA',
                                label: 'Los Angeles',
                                originalLabel: 'Los Angeles',
                                alternativeLabels: [],

                                related: [],
                                children: [
                                  {
                                    id: '1KBB-US-WPCAB',
                                    label: 'Beverly Hills',
                                    originalLabel: 'Beverly Hills',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1KBB-US-WPCAH',
                                    label: 'Hollywood',
                                    originalLabel: 'Hollywood',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1KBB-US-WPCD',
                                label: 'San Diego',
                                originalLabel: 'San Diego',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WPCF',
                                label: 'San Francisco',
                                originalLabel: 'San Francisco',
                                alternativeLabels: [],
                                note: 'Her: Oakland, Berkeley, San Francisco-bukta',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WPCJ',
                                label: 'San Jose',
                                originalLabel: 'San Jose',
                                alternativeLabels: [],
                                note: 'Her: Silicon Valley, Santa Clara, Palo Alto, Stanford, Mountain View',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WPCM',
                                label: 'Monterey og kystområdene rundt',
                                originalLabel: 'Monterey og kystområdene rundt',
                                alternativeLabels: [],
                                note: 'Her: Big Sur, Santa Barbara, San Luis Obispo, San Benito, Santa Cruz, Carmel, Salinas Valley, Pinnacles nasjonalpark',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WPCN',
                                label: 'Napa og Sonoma',
                                originalLabel: 'Napa og Sonoma',
                                alternativeLabels: [],
                                note: 'Her: Russian River, California’s Wine Country, Solano, Lake County, Mendocino County, Napa Valley',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WPCP',
                                label: 'Palm Springs og Inland Empire',
                                originalLabel: 'Palm Springs og Inland Empire',
                                alternativeLabels: [],
                                note: 'Her: Riverside, San Bernardino, Coachella Valley, Joshua Tree nasjonalpark',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WPCR',
                                label: 'Fresno og San Joaquin Valley',
                                originalLabel: 'Fresno og San Joaquin Valley',
                                alternativeLabels: [],
                                note: 'Her: Bakersfield, the Central Valley, Modesto, Stockton',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WPCS',
                                label: 'Sacramento',
                                originalLabel: 'Sacramento',
                                alternativeLabels: [],
                                note: 'Her: Sacramento County og Greater Sacramento area, Roseville og Sutter Yuba, Yolo, El Dorado, Placer og Nevada Counties',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WPCW',
                                label: 'North Coast og Redwood (region)',
                                originalLabel:
                                  'North Coast og Redwood (region)',
                                alternativeLabels: [],
                                note: 'Her: Redwood nasjonalpark, Muir Woods, Point Reyes, Redwood Coast og Mendocino, Humboldt, Del Norte, Marin Counties, Eureka',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WPCX',
                                label: 'Death Valley nasjonalpark',
                                originalLabel: 'Death Valley nasjonalpark',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WPCY',
                                label:
                                  'Yosemite, Sequoia og Kings Canyon nasjonalparker',
                                originalLabel:
                                  'Yosemite, Sequoia og Kings Canyon nasjonalparker',
                                alternativeLabels: [],
                                note: 'Her: Sequoia National Forest, Mount Whitney, Lake Tahoe, Sierra Nevada',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-WPH',
                            label: 'Hawaii',
                            originalLabel: 'Hawaii',
                            alternativeLabels: ['Hawaiʻi'],
                            note: 'Her: Niihau (Niʻihau), de nordvestlige øyene på Hawaii. Brukes i forbindelse med politikk og sosiologi. Foretrekk/legg til 1MKPH i forbindelse med geofysikk, naturhistorie og reiser. Se også 1MKPH <a href="/thema/nb/1MKPH">Hawaii-øyene</a>',
                            related: [
                              {
                                id: '1MKPH',
                                label: 'Hawaii-øyene',
                                originalLabel: 'Hawaii-øyene',
                                note: 'Brukes i forbindelse med geofysikk, naturhistorie og reiser, eller hvis man ikke bruker de nasjonale utvidelsene for USA. Se også 1KBB-US-WPH <a href="/thema/nb/1KBB-US-WPH">Hawaii</a>',
                              },
                            ],
                            children: [
                              {
                                id: '1KBB-US-WPHH',
                                label: 'Hawaii (Big Island)',
                                originalLabel: 'Hawaii (Big Island)',
                                alternativeLabels: [],
                                note: 'Her: Hawaiʻi, Kilauea, Mauna Loa, Hawaii Volcanoes nasjonalpark, Mauna Kea, Hilo',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WPHK',
                                label: 'Kauai',
                                originalLabel: 'Kauai',
                                alternativeLabels: [],
                                note: 'Her: Kauaʻi, Kawaikini, Waimea Canyon, Nā Pali Coast State Park',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WPHM',
                                label: 'Maui',
                                originalLabel: 'Maui',
                                alternativeLabels: [],
                                note: 'Her: Mau’I, Haleakala, Molokai (Molokaʻi), Lanai (Lānaʻi),Kahoolawe (Kahoʻolawe)',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1KBB-US-WPHO',
                                label: 'Oahu',
                                originalLabel: 'Oahu',
                                alternativeLabels: [],
                                note: 'Her: Oʻahu, Mount Kaʻala, Pearl Harbor',
                                related: [],
                                children: [
                                  {
                                    id: '1KBB-US-WPHOH',
                                    label: 'Honolulu',
                                    originalLabel: 'Honolulu',
                                    alternativeLabels: [],
                                    note: 'Her: Waikiki',
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: '1KBB-US-WPN',
                            label: 'Nordvestlige stillehavsstater',
                            originalLabel: 'Nordvestlige stillehavsstater',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBB-US-WPNA',
                                label: 'Alaska',
                                originalLabel: 'Alaska',
                                alternativeLabels: [],
                                note: 'Her: Sitka, Seward, Aleutene, Nome, Little Diomede og Katmi, Denali, Kenai-fjordene, Kobuk Valley, Gates of the Artic, Wrangell–St. Elias, Bering Land Bridge, Glacier Bay nasjonalparker',
                                related: [],
                                children: [
                                  {
                                    id: '1KBB-US-WPNAA',
                                    label: 'Anchorage',
                                    originalLabel: 'Anchorage',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1KBB-US-WPNAJ',
                                    label: 'Juneau',
                                    originalLabel: 'Juneau',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1KBB-US-WPNR',
                                label: 'Oregon',
                                originalLabel: 'Oregon',
                                alternativeLabels: [],
                                note: 'Her: Crater Lake, Mount Hood, Mount Mazama, Multnomah Falls, Mount Bachelor, Salem, Willamette Valley, Columbia River Gorge',
                                related: [],
                                children: [
                                  {
                                    id: '1KBB-US-WPNRP',
                                    label: 'Portland (OR)',
                                    originalLabel: 'Portland (OR)',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: '1KBB-US-WPNW',
                                label: 'Washington (staten)',
                                originalLabel: 'Washington (staten)',
                                alternativeLabels: [],
                                note: 'Her: Mount Rainier og North Cascades nasjonalparker, Mount Baker, Kulshan, Glacier Peak, Dakobed, Tahoma, Mount St. Helens, Saint, Mount Adams, Pahto, Klickitat, Haro Straits, San Juan Islands, Puget Sound',
                                related: [],
                                children: [
                                  {
                                    id: '1KBB-US-WPNWO',
                                    label: 'Olympic nasjonalpark',
                                    originalLabel: 'Olympic nasjonalpark',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: '1KBB-US-WPNWS',
                                    label: 'Seattle',
                                    originalLabel: 'Seattle',
                                    alternativeLabels: [],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1KBC',
                label: 'Canada',
                originalLabel: 'Canada',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1KBC-CA-A',
                    label: 'Alberta',
                    originalLabel: 'Alberta',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBC-CA-AN',
                        label: 'Northern Alberta',
                        originalLabel: 'Northern Alberta',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-ANB',
                            label:
                              'Northern Alberta: Wood Buffalo nasjonalpark',
                            originalLabel:
                              'Northern Alberta: Wood Buffalo nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-AND',
                            label: 'Northern Alberta: Peace River Country (AB)',
                            originalLabel:
                              'Northern Alberta: Peace River Country (AB)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-ANG',
                            label:
                              'Northern Alberta: Alberta’s Rockies og Banff og Jasper nasjonalparker',
                            originalLabel:
                              'Northern Alberta: Alberta’s Rockies og Banff og Jasper nasjonalparker',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-AS',
                        label: 'Southern Alberta',
                        originalLabel: 'Southern Alberta',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-ASC',
                            label: 'Southern Alberta: Calgary-regionen',
                            originalLabel: 'Southern Alberta: Calgary-regionen',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-ASF',
                            label: 'Southern Alberta: Edmonton Capital Region',
                            originalLabel:
                              'Southern Alberta: Edmonton Capital Region',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-ASJ',
                            label: 'Southern Alberta: Elk Island nasjonalpark',
                            originalLabel:
                              'Southern Alberta: Elk Island nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-ASN',
                            label:
                              'Southern Alberta: Waterton Lakes nasjonalpark',
                            originalLabel:
                              'Southern Alberta: Waterton Lakes nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KBC-CA-B',
                    label: 'British Columbia',
                    originalLabel: 'British Columbia',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBC-CA-BD',
                        label: 'British Columbia Interior',
                        originalLabel: 'British Columbia Interior',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-BDA',
                            label: 'British Columbia Interior: Atlin District',
                            originalLabel:
                              'British Columbia Interior: Atlin District',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BDB',
                            label: 'British Columbia Interior: Stikine Country',
                            originalLabel:
                              'British Columbia Interior: Stikine Country',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BDC',
                            label:
                              'British Columbia Interior: Peace River Country',
                            originalLabel:
                              'British Columbia Interior: Peace River Country',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BDD',
                            label: 'British Columbia Interior: Nechako',
                            originalLabel: 'British Columbia Interior: Nechako',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BDE',
                            label: 'British Columbia Interior: Bulkley',
                            originalLabel: 'British Columbia Interior: Bulkley',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BDF',
                            label: 'British Columbia Interior: Cariboo',
                            originalLabel: 'British Columbia Interior: Cariboo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BDG',
                            label: 'British Columbia Interior: Chilcotin',
                            originalLabel:
                              'British Columbia Interior: Chilcotin',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BDH',
                            label:
                              'British Columbia Interior: Omineca-Prince George',
                            originalLabel:
                              'British Columbia Interior: Omineca-Prince George',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BDJ',
                            label: 'British Columbia Interior: Robson Valley',
                            originalLabel:
                              'British Columbia Interior: Robson Valley',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BDK',
                            label: 'British Columbia Interior: Kootenays',
                            originalLabel:
                              'British Columbia Interior: Kootenays',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBC-CA-BDKA',
                                label:
                                  'British Columbia Interior: Kootenays nasjonalpark (Mount Revelstoke, Kootenay, Glacier og Yoho)',
                                originalLabel:
                                  'British Columbia Interior: Kootenays nasjonalpark (Mount Revelstoke, Kootenay, Glacier og Yoho)',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBC-CA-BDL',
                            label: 'British Columbia Interior: Okanagan',
                            originalLabel:
                              'British Columbia Interior: Okanagan',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BDM',
                            label: 'British Columbia Interior: Boundary',
                            originalLabel:
                              'British Columbia Interior: Boundary',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BDN',
                            label: 'British Columbia Interior: Similkameen',
                            originalLabel:
                              'British Columbia Interior: Similkameen',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BDP',
                            label: 'British Columbia Interior: Thompson',
                            originalLabel:
                              'British Columbia Interior: Thompson',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BDQ',
                            label: 'British Columbia Interior: Shuswap',
                            originalLabel: 'British Columbia Interior: Shuswap',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BDR',
                            label:
                              'British Columbia Interior: Lillooet-Fraser Canyon',
                            originalLabel:
                              'British Columbia Interior: Lillooet-Fraser Canyon',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-BF',
                        label: 'British Columbia South Coast',
                        originalLabel: 'British Columbia South Coast',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-BFB',
                            label:
                              'British Columbia South Coast: Lower Mainland',
                            originalLabel:
                              'British Columbia South Coast: Lower Mainland',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BFD',
                            label:
                              'British Columbia South Coast: Greater Vancouver',
                            originalLabel:
                              'British Columbia South Coast: Greater Vancouver',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BFF',
                            label:
                              'British Columbia South Coast: Fraser Valley',
                            originalLabel:
                              'British Columbia South Coast: Fraser Valley',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BFJ',
                            label:
                              'British Columbia South Coast: Sea-to-Sky Corridor',
                            originalLabel:
                              'British Columbia South Coast: Sea-to-Sky Corridor',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BFM',
                            label:
                              'British Columbia South Coast: Gulf Islands og Gulf Islands nasjonalpark',
                            originalLabel:
                              'British Columbia South Coast: Gulf Islands og Gulf Islands nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BFR',
                            label:
                              'British Columbia South Coast: Sunshine Coast',
                            originalLabel:
                              'British Columbia South Coast: Sunshine Coast',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-BL',
                        label: 'British Columbia Vancouver Island',
                        originalLabel: 'British Columbia Vancouver Island',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-BLC',
                            label:
                              'British Columbia Vancouver Island: Greater Victoria',
                            originalLabel:
                              'British Columbia Vancouver Island: Greater Victoria',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BLK',
                            label:
                              'British Columbia Vancouver Island: Mid-Island',
                            originalLabel:
                              'British Columbia Vancouver Island: Mid-Island',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BLN',
                            label:
                              'British Columbia Vancouver Island: North Island',
                            originalLabel:
                              'British Columbia Vancouver Island: North Island',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BLS',
                            label:
                              'British Columbia Vancouver Island: West Coast',
                            originalLabel:
                              'British Columbia Vancouver Island: West Coast',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BLW',
                            label:
                              'British Columbia Vancouver Island: Juan de Fuca region og Pacific Rim nasjonalpark',
                            originalLabel:
                              'British Columbia Vancouver Island: Juan de Fuca region og Pacific Rim nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-BP',
                        label: 'British Columbia Central Coast',
                        originalLabel: 'British Columbia Central Coast',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-BPD',
                            label:
                              'British Columbia Central Coast: Queen Charlotte Strait',
                            originalLabel:
                              'British Columbia Central Coast: Queen Charlotte Strait',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BPL',
                            label:
                              'British Columbia Central Coast: Bella Coola Valley',
                            originalLabel:
                              'British Columbia Central Coast: Bella Coola Valley',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-BT',
                        label: 'British Columbia North Coast',
                        originalLabel: 'British Columbia North Coast',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-BTE',
                            label:
                              'British Columbia North Coast: Haida Gwaii og Gwaii Haanas nasjonalpark og Haida Heritage Site',
                            originalLabel:
                              'British Columbia North Coast: Haida Gwaii og Gwaii Haanas nasjonalpark og Haida Heritage Site',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BTM',
                            label: 'British Columbia North Coast: Skeena',
                            originalLabel:
                              'British Columbia North Coast: Skeena',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BTR',
                            label: 'British Columbia North Coast: Nass',
                            originalLabel: 'British Columbia North Coast: Nass',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-BTY',
                            label:
                              'British Columbia North Coast: Stewart Country',
                            originalLabel:
                              'British Columbia North Coast: Stewart Country',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KBC-CA-C',
                    label: 'Manitoba',
                    originalLabel: 'Manitoba',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBC-CA-CN',
                        label: 'Northern Manitoba',
                        originalLabel: 'Northern Manitoba',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-CNB',
                            label: 'Northern Manitoba: Wapusk nasjonalpark',
                            originalLabel:
                              'Northern Manitoba: Wapusk nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-CP',
                        label: 'Interlake and Central Manitoba',
                        originalLabel: 'Interlake and Central Manitoba',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-CPR',
                            label:
                              'Interlake and Central Manitoba: Riding Mountain nasjonalpark',
                            originalLabel:
                              'Interlake and Central Manitoba: Riding Mountain nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-CS',
                        label: 'Southern Manitoba',
                        originalLabel: 'Southern Manitoba',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-CSW',
                            label: 'Southern Manitoba: Winnipeg Capital Region',
                            originalLabel:
                              'Southern Manitoba: Winnipeg Capital Region',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KBC-CA-D',
                    label: 'New Brunswick',
                    originalLabel: 'New Brunswick',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBC-CA-DF',
                        label: 'New Brunswick: Fundy nasjonalpark',
                        originalLabel: 'New Brunswick: Fundy nasjonalpark',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-DK',
                        label: 'New Brunswick: Greater Saint John',
                        originalLabel: 'New Brunswick: Greater Saint John',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-DN',
                        label: 'New Brunswick: Greater Fredericton',
                        originalLabel: 'New Brunswick: Greater Fredericton',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KBC-CA-F',
                    label: 'Newfoundland og Labrador',
                    originalLabel: 'Newfoundland og Labrador',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBC-CA-FL',
                        label: 'Labrador',
                        originalLabel: 'Labrador',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-FLT',
                            label: 'Labrador: Torngat Mountains nasjonalpark',
                            originalLabel:
                              'Labrador: Torngat Mountains nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-FN',
                        label: 'Newfoundland',
                        originalLabel: 'Newfoundland',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-FNA',
                            label: 'Newfoundland: Avalon Peninsula',
                            originalLabel: 'Newfoundland: Avalon Peninsula',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KBC-CA-FNAJ',
                                label:
                                  'Newfoundland: Avalon Peninsula: St John’s',
                                originalLabel:
                                  'Newfoundland: Avalon Peninsula: St John’s',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KBC-CA-FNC',
                            label: 'Newfoundland: Burin Peninsula',
                            originalLabel: 'Newfoundland: Burin Peninsula',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-FNE',
                            label:
                              'Newfoundland: Bonavista Peninsula og Terra Nova nasjonalpark',
                            originalLabel:
                              'Newfoundland: Bonavista Peninsula og Terra Nova nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-FNH',
                            label: 'Newfoundland: South Coast',
                            originalLabel: 'Newfoundland: South Coast',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-FNK',
                            label:
                              'Newfoundland: West Coast og Gros Morne nasjonalpark',
                            originalLabel:
                              'Newfoundland: West Coast og Gros Morne nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-FNM',
                            label: 'Newfoundland: Great Northern Peninsula',
                            originalLabel:
                              'Newfoundland: Great Northern Peninsula',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-FNR',
                            label: 'Newfoundland: Northeast Coast',
                            originalLabel: 'Newfoundland: Northeast Coast',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KBC-CA-H',
                    label: 'Northwest Territories',
                    originalLabel: 'Northwest Territories',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBC-CA-HL',
                        label:
                          'Northwest Territories: Inuvik Region og Tuktut Nogait nasjonalpark',
                        originalLabel:
                          'Northwest Territories: Inuvik Region og Tuktut Nogait nasjonalpark',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-HP',
                        label: 'Northwest Territories: Sahtu Region',
                        originalLabel: 'Northwest Territories: Sahtu Region',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-HT',
                        label:
                          'Northwest Territories: Dehcho Region og Nahanni og Naats’ihch’oh nasjonalparker',
                        originalLabel:
                          'Northwest Territories: Dehcho Region og Nahanni og Naats’ihch’oh nasjonalparker',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-HV',
                        label: 'Northwest Territories: North Slave Region',
                        originalLabel:
                          'Northwest Territories: North Slave Region',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-HW',
                        label: 'Northwest Territories: Yellowknife',
                        originalLabel: 'Northwest Territories: Yellowknife',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-HX',
                        label: 'Northwest Territories: South Slave Region',
                        originalLabel:
                          'Northwest Territories: South Slave Region',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KBC-CA-J',
                    label: 'Nova Scotia',
                    originalLabel: 'Nova Scotia',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBC-CA-JA',
                        label: 'Nova Scotia: Annapolis Valley',
                        originalLabel: 'Nova Scotia: Annapolis Valley',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-JB',
                        label:
                          'Nova Scotia: Cape Breton Island og Cape Breton Highlands nasjonalpark',
                        originalLabel:
                          'Nova Scotia: Cape Breton Island og Cape Breton Highlands nasjonalpark',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-JC',
                        label: 'Central Nova Scotia',
                        originalLabel: 'Central Nova Scotia',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-JD',
                        label:
                          'Nova Scotia: Eastern Shore og Sable Island nasjonalpark',
                        originalLabel:
                          'Nova Scotia: Eastern Shore og Sable Island nasjonalpark',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-JH',
                        label:
                          'Nova Scotia: Halifax Regional Municipality (HRM)',
                        originalLabel:
                          'Nova Scotia: Halifax Regional Municipality (HRM)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-JK',
                        label: 'Nova Scotia: North Shore',
                        originalLabel: 'Nova Scotia: North Shore',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-JM',
                        label:
                          'Nova Scotia: South Shore og Kejimkujik nasjonalpark',
                        originalLabel:
                          'Nova Scotia: South Shore og Kejimkujik nasjonalpark',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KBC-CA-K',
                    label: 'Nunavut',
                    originalLabel: 'Nunavut',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBC-CA-KC',
                        label: 'Nunavut: Kitikmeot Region',
                        originalLabel: 'Nunavut: Kitikmeot Region',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-KN',
                        label:
                          'Nunavut: Kivalliq Region og Ukkusiksalik National Park',
                        originalLabel:
                          'Nunavut: Kivalliq Region og Ukkusiksalik National Park',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-KQ',
                        label:
                          'Nunavut: Qikiqtaaluk Region og Auyuittuq, Sirmilik og Quttinirpaaq nasjonalparker',
                        originalLabel:
                          'Nunavut: Qikiqtaaluk Region og Auyuittuq, Sirmilik og Quttinirpaaq nasjonalparker',
                        alternativeLabels: ['Baffin island'],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-KQT',
                            label: 'Nunavut: Qikiqtaaluk Region: Iqaluit',
                            originalLabel:
                              'Nunavut: Qikiqtaaluk Region: Iqaluit',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KBC-CA-O',
                    label: 'Ontario',
                    originalLabel: 'Ontario',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBC-CA-OB',
                        label: 'Northwestern Ontario',
                        originalLabel: 'Northwestern Ontario',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-OBP',
                            label:
                              'Northwestern Ontario: Pukaskwa nasjonalpark',
                            originalLabel:
                              'Northwestern Ontario: Pukaskwa nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-OD',
                        label: 'Northeastern Ontario',
                        originalLabel: 'Northeastern Ontario',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-OG',
                        label: 'Central Ontario',
                        originalLabel: 'Central Ontario',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-OGC',
                            label:
                              'Central Ontario: Haliburton og Algonquin park',
                            originalLabel:
                              'Central Ontario: Haliburton og Algonquin park',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-OGF',
                            label: 'Central Ontario: Kawartha Lakes',
                            originalLabel: 'Central Ontario: Kawartha Lakes',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-OGJ',
                            label: 'Central Ontario: Muskoka',
                            originalLabel: 'Central Ontario: Muskoka',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-OGQ',
                            label: 'Central Ontario: Bay of Quinte',
                            originalLabel: 'Central Ontario: Bay of Quinte',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-OM',
                        label: 'Eastern Ontario',
                        originalLabel: 'Eastern Ontario',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-OMC',
                            label:
                              'Eastern Ontario: Ottawa / National Capital Region',
                            originalLabel:
                              'Eastern Ontario: Ottawa / National Capital Region',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-OML',
                            label: 'Eastern Ontario: Ottawa Valley',
                            originalLabel: 'Eastern Ontario: Ottawa Valley',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-OMT',
                            label:
                              'Eastern Ontario: Thousand Islands og St. Lawrence Islands nasjonalpark',
                            originalLabel:
                              'Eastern Ontario: Thousand Islands og St. Lawrence Islands nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-OS',
                        label: 'Southwestern Ontario',
                        originalLabel: 'Southwestern Ontario',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-OSB',
                            label:
                              'Southwestern Ontario: Bruce-halvøya og nasjonalpark',
                            originalLabel:
                              'Southwestern Ontario: Bruce-halvøya og nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-OSF',
                            label:
                              'Southwestern Ontario: Georgian Triangle og Georgian Bay Islands nasjonalpark',
                            originalLabel:
                              'Southwestern Ontario: Georgian Triangle og Georgian Bay Islands nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-OSM',
                            label: 'Southwestern Ontario: Greater Toronto Area',
                            originalLabel:
                              'Southwestern Ontario: Greater Toronto Area',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-OSR',
                            label:
                              'Southwestern Ontario: Niagara Peninsula og Point Pelee nasjonalpark',
                            originalLabel:
                              'Southwestern Ontario: Niagara Peninsula og Point Pelee nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KBC-CA-P',
                    label: 'Prince Edward Island',
                    originalLabel: 'Prince Edward Island',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBC-CA-PM',
                        label:
                          'Prince Edward Island: North Shore og Prince Edward Island nasjonalpark',
                        originalLabel:
                          'Prince Edward Island: North Shore og Prince Edward Island nasjonalpark',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-PR',
                        label: 'Prince Edward Island: South Shore',
                        originalLabel: 'Prince Edward Island: South Shore',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-PT',
                        label: 'Prince Edward Island: Summerside Area',
                        originalLabel: 'Prince Edward Island: Summerside Area',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-PV',
                        label: 'Prince Edward Island: Charlottetown Area',
                        originalLabel:
                          'Prince Edward Island: Charlottetown Area',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KBC-CA-Q',
                    label: 'Quebec',
                    originalLabel: 'Quebec',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBC-CA-QB',
                        label: 'Quebec: Bas-Saint-Laurent',
                        originalLabel: 'Quebec: Bas-Saint-Laurent',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-QBB',
                            label: 'Rimouski',
                            originalLabel: 'Rimouski',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-QBG',
                            label: 'Kamouraska',
                            originalLabel: 'Kamouraska',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-QBK',
                            label: 'Reford Gardens',
                            originalLabel: 'Reford Gardens',
                            alternativeLabels: [],
                            note: 'Her: Jardins de Métis',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-QD',
                        label: 'Quebec: Saguenay–Lac-Saint-Jean',
                        originalLabel: 'Quebec: Saguenay–Lac-Saint-Jean',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-QDC',
                            label: 'Saguenay (by)',
                            originalLabel: 'Saguenay (by)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-QDF',
                            label: 'Saguenay (elv)',
                            originalLabel: 'Saguenay (elv)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-QDK',
                            label: 'Lac Saint-Jean',
                            originalLabel: 'Lac Saint-Jean',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-QF',
                        label: 'Quebec, National Capital Region',
                        originalLabel: 'Quebec, National Capital Region',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-QFC',
                            label: 'Quebec City',
                            originalLabel: 'Quebec City',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-QFF',
                            label: 'Orleans Island',
                            originalLabel: 'Orleans Island',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-QFG',
                            label: 'Charlevoix',
                            originalLabel: 'Charlevoix',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-QH',
                        label: 'Quebec: Mauricie',
                        originalLabel: 'Quebec: Mauricie',
                        alternativeLabels: [],
                        note: 'Her: La Mauricie National Park',
                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-QHD',
                            label: 'Trois-Rivières',
                            originalLabel: 'Trois-Rivières',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-QHH',
                            label: 'Shawinigan',
                            originalLabel: 'Shawinigan',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-QJ',
                        label: 'Quebec: Estrie / Eastern Townships',
                        originalLabel: 'Quebec: Estrie / Eastern Townships',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-QJS',
                            label: 'Sherbrooke',
                            originalLabel: 'Sherbrooke',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-QL',
                        label: 'Quebec: Outaouais',
                        originalLabel: 'Quebec: Outaouais',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-QLG',
                            label: 'Gatineau',
                            originalLabel: 'Gatineau',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-QM',
                        label: 'Quebec: Montréal (region)',
                        originalLabel: 'Quebec: Montréal (region)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-QMM',
                            label: 'Montreal',
                            originalLabel: 'Montreal',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-QN',
                        label: 'Quebec: Abitibi-Témiscamingue',
                        originalLabel: 'Quebec: Abitibi-Témiscamingue',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-QP',
                        label: 'Quebec: Côte-Nord',
                        originalLabel: 'Quebec: Côte-Nord',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-QPC',
                            label: 'Mingan Archipelago',
                            originalLabel: 'Mingan Archipelago',
                            alternativeLabels: [],
                            note: 'Her: Mingan Archipelago National Park',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-QPD',
                            label: 'Anticosti Island',
                            originalLabel: 'Anticosti Island',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-QR',
                        label: 'Quebec: Nord-du-Québec',
                        originalLabel: 'Quebec: Nord-du-Québec',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-QRK',
                            label: 'James Bay',
                            originalLabel: 'James Bay',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-QRN',
                            label: 'Nunavik',
                            originalLabel: 'Nunavik',
                            alternativeLabels: [],
                            note: 'Her: Kativik',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-QRU',
                            label: 'Ungava Bay',
                            originalLabel: 'Ungava Bay',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-QS',
                        label: 'Quebec: Gaspésie–Îles-de-la-Madeleine',
                        originalLabel: 'Quebec: Gaspésie–Îles-de-la-Madeleine',
                        alternativeLabels: [],
                        note: 'Her: Forillon nasjonalpark',
                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-QSJ',
                            label: 'Chaleur Bay',
                            originalLabel: 'Chaleur Bay',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-QT',
                        label: 'Quebec: Chaudière-Appalaches',
                        originalLabel: 'Quebec: Chaudière-Appalaches',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-QU',
                        label: 'Quebec: Laval',
                        originalLabel: 'Quebec: Laval',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-QV',
                        label: 'Quebec: Lanaudière',
                        originalLabel: 'Quebec: Lanaudière',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-QW',
                        label: 'Quebec: Laurentides',
                        originalLabel: 'Quebec: Laurentides',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-QX',
                        label: 'Quebec: Montérégie',
                        originalLabel: 'Quebec: Montérégie',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-QY',
                        label: 'Quebec: Centre-du-Québec',
                        originalLabel: 'Quebec: Centre-du-Québec',
                        alternativeLabels: [],
                        note: 'Her: Bois-Franc',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KBC-CA-S',
                    label: 'Saskatchewan',
                    originalLabel: 'Saskatchewan',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBC-CA-SD',
                        label: 'Northern Saskatchewan',
                        originalLabel: 'Northern Saskatchewan',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-SG',
                        label: 'Central Saskatchewan',
                        originalLabel: 'Central Saskatchewan',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-SGA',
                            label: 'Central Saskatchewan: Saskatoon',
                            originalLabel: 'Central Saskatchewan: Saskatoon',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-SGP',
                            label:
                              'Central Saskatchewan: Prince Albert National Park',
                            originalLabel:
                              'Central Saskatchewan: Prince Albert National Park',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBC-CA-SS',
                        label: 'Southern Saskatchewan',
                        originalLabel: 'Southern Saskatchewan',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KBC-CA-SSA',
                            label: 'Southern Saskatchewan: Regina',
                            originalLabel: 'Southern Saskatchewan: Regina',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KBC-CA-SSP',
                            label:
                              'Southern Saskatchewan: Grasslands nasjonalpark',
                            originalLabel:
                              'Southern Saskatchewan: Grasslands nasjonalpark',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KBC-CA-U',
                    label: 'Yukon Territory',
                    originalLabel: 'Yukon Territory',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBC-CA-UD',
                        label:
                          'Yukon Territory: Northern Yukon, Vuntut og Ivvavik nasjonalparker',
                        originalLabel:
                          'Yukon Territory: Northern Yukon, Vuntut og Ivvavik nasjonalparker',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-UF',
                        label: 'Yukon Territory: Silver Trail Region',
                        originalLabel: 'Yukon Territory: Silver Trail Region',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-UK',
                        label: 'Yukon Territory: Klondike Region',
                        originalLabel: 'Yukon Territory: Klondike Region',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-UL',
                        label:
                          'Yukon Territory: Kluane Region og Kluane nasjonalpark',
                        originalLabel:
                          'Yukon Territory: Kluane Region og Kluane nasjonalpark',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-UO',
                        label:
                          'Yukon Territory: Campbell and Watson Lakes Regions',
                        originalLabel:
                          'Yukon Territory: Campbell and Watson Lakes Regions',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBC-CA-UW',
                        label:
                          'Yukon Territory: Whitehorse and Southern Lakes Regions',
                        originalLabel:
                          'Yukon Territory: Whitehorse and Southern Lakes Regions',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KBC-CA-Z',
                    label: 'Canada: reisemål',
                    originalLabel: 'Canada: reisemål',
                    alternativeLabels: [],
                    note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                    related: [],
                    children: [
                      {
                        id: '1KBC-CA-ZH',
                        label: 'Hudson Bay',
                        originalLabel: 'Hudson Bay',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1KBZ',
                label: 'Nord-Amerika: fysiske aspekter',
                originalLabel: 'Nord-Amerika: fysiske aspekter',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1KBZA',
                    label: 'Nord-Amerika: elver, innsjøer etc.',
                    originalLabel: 'Nord-Amerika: elver, innsjøer etc.',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBZAA',
                        label: 'De store sjøer',
                        originalLabel: 'De store sjøer',
                        alternativeLabels: [],
                        note: 'Her: Lakes Ontario, Erie, Huron, Superior, Michigan, Great Lakes',
                        related: [],
                        children: [
                          {
                            id: '1KBZAAN',
                            label: 'Niagarafallene',
                            originalLabel: 'Niagarafallene',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBZAC',
                        label: 'Colorado og sideelver',
                        originalLabel: 'Colorado og sideelver',
                        alternativeLabels: [],
                        note: 'Use for: the river, its tributaries, its valley and basin',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBZAG',
                        label: 'Rio Grande og sideelver',
                        originalLabel: 'Rio Grande og sideelver',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBZAK',
                        label: 'Mackenzie og sideelver',
                        originalLabel: 'Mackenzie og sideelver',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBZAM',
                        label: 'Mississippi-Missouri og sideelver',
                        originalLabel: 'Mississippi-Missouri og sideelver',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBZAS',
                        label: 'St. Lawrence og sideelver',
                        originalLabel: 'St. Lawrence og sideelver',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                        related: [],
                        children: [
                          {
                            id: '1KBZAS-CA-L',
                            label: 'St. Lawrence-bukta',
                            originalLabel: 'St. Lawrence-bukta',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KBZA-US-H',
                        label: 'Hudson og sideelver',
                        originalLabel: 'Hudson og sideelver',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBZA-US-L',
                        label: 'Great Salt Lake',
                        originalLabel: 'Great Salt Lake',
                        alternativeLabels: ['Store Saltsjø'],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBZA-US-O',
                        label: 'Columbia og sideelver',
                        originalLabel: 'Columbia og sideelver',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBZA-US-P',
                        label: 'Potomac og Chesapeake Bay',
                        originalLabel: 'Potomac og Chesapeake Bay',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBZAY',
                        label: 'Yukon og sideelver',
                        originalLabel: 'Yukon og sideelver',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver, elvedalen og elvedeltaet',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KBZT',
                    label:
                      'Nord-Amerika: fjell, åser, sletter, kystlinjer, ørkener etc.',
                    originalLabel:
                      'Nord-Amerika: fjell, åser, sletter, kystlinjer, ørkener etc.',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KBZTA',
                        label: 'Appalachene',
                        originalLabel: 'Appalachene',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBZTG',
                        label: 'Grand Canyon',
                        originalLabel: 'Grand Canyon',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBZTP',
                        label: 'Nord-Amerika: Great Plains',
                        originalLabel: 'Nord-Amerika: Great Plains',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBZTR',
                        label: 'Rocky Mountains',
                        originalLabel: 'Rocky Mountains',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBZTS',
                        label: 'Nord-amerikanske ørkener',
                        originalLabel: 'Nord-amerikanske ørkener',
                        alternativeLabels: [],
                        note: 'Her: Mojave-, Sonoran, Chihuahua-, Great Basin-ørkenen etc.',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1KBZT-US-D',
                        label: 'Denali',
                        originalLabel: 'Denali',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: '1KJ',
            label: 'De karibiske øyer',
            originalLabel: 'De karibiske øyer',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1KJC',
                label: 'Cuba',
                originalLabel: 'Cuba',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1KJC-CU-A',
                    label: 'Cuba: den vestlige regionen',
                    originalLabel: 'Cuba: den vestlige regionen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1KJC-CU-B',
                    label: 'Cuba: den sentrale regionen',
                    originalLabel: 'Cuba: den sentrale regionen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1KJC-CU-C',
                    label: 'Cuba: den østlige regionen',
                    originalLabel: 'Cuba: den østlige regionen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1KJC-CU-D',
                    label: 'Havana',
                    originalLabel: 'Havana',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1KJC-CU-V',
                    label: 'Guantánamo',
                    originalLabel: 'Guantánamo',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1KJD',
                label: 'Den dominikanske republikk',
                originalLabel: 'Den dominikanske republikk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1KJD-DO-A',
                    label: 'Norte – Cilbao',
                    originalLabel: 'Norte – Cilbao',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1KJD-DO-B',
                    label: 'Den dominikanske republikk: den sørlige regionen',
                    originalLabel:
                      'Den dominikanske republikk: den sørlige regionen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1KJD-DO-C',
                    label:
                      'Den dominikanske republikk: den sørøstlige / østlige regionen',
                    originalLabel:
                      'Den dominikanske republikk: den sørøstlige / østlige regionen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1KJD-DO-D',
                    label: 'Santo Domingo',
                    originalLabel: 'Santo Domingo',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1KJH',
                label: 'Haiti',
                originalLabel: 'Haiti',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1KJH-HT-P',
                    label: 'Port-au-Prince',
                    originalLabel: 'Port-au-Prince',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1KJM',
                label: 'Caymanøyene',
                originalLabel: 'Caymanøyene',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1KJP',
                label: 'Puerto Rico',
                originalLabel: 'Puerto Rico',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1KJP-PR-C',
                    label: 'De spanske jomfruøyer',
                    originalLabel: 'De spanske jomfruøyer',
                    alternativeLabels: [],
                    note: 'Her: De puertorikanske Jomfruøyer',
                    related: [],
                    children: [
                      {
                        id: '1KJP-PR-CC',
                        label: 'Culebra',
                        originalLabel: 'Culebra',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KJP-PR-CD',
                        label: 'Vieques',
                        originalLabel: 'Vieques',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KJP-PR-J',
                    label: 'San Juan',
                    originalLabel: 'San Juan',
                    alternativeLabels: [],
                    note: 'Class here: El Yunque park',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1KJW',
                label: 'Vestindiske øyer',
                originalLabel: 'Vestindiske øyer',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1KJWB',
                    label: 'Bahamas',
                    originalLabel: 'Bahamas',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KJWB-BS-A',
                        label: 'Nassau',
                        originalLabel: 'Nassau',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KJWJ',
                    label: 'Jamaica',
                    originalLabel: 'Jamaica',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KJWJ-JM-B',
                        label: 'Kingston',
                        originalLabel: 'Kingston',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KJWT',
                    label: 'Turks- og Caicosøyene',
                    originalLabel: 'Turks- og Caicosøyene',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1KJWV',
                    label: 'Leewardøyene',
                    originalLabel: 'Leewardøyene',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KJWVA',
                        label: 'Anguilla',
                        originalLabel: 'Anguilla',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KJWVB',
                        label: 'Antigua og Barbuda',
                        originalLabel: 'Antigua og Barbuda',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KJWVG',
                        label: 'Guadeloupe',
                        originalLabel: 'Guadeloupe',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KJWVG-FR-A',
                            label: 'Basse Terre',
                            originalLabel: 'Basse Terre',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KJWVG-FR-B',
                            label: 'Pointe-à-Pitre',
                            originalLabel: 'Pointe-à-Pitre',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KJWVK',
                        label: 'Saint Kitts og Nevis',
                        originalLabel: 'Saint Kitts og Nevis',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KJWVM',
                        label: 'Montserrat',
                        originalLabel: 'Montserrat',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KJWVV',
                        label: 'Jomfruøyene',
                        originalLabel: 'Jomfruøyene',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KJWVVK',
                            label: 'De britiske jomfruøyene',
                            originalLabel: 'De britiske jomfruøyene',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KJWVVS',
                            label: 'De amerikanske jomfruøyene',
                            originalLabel: 'De amerikanske jomfruøyene',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KJWW',
                    label: 'Windwardøyene',
                    originalLabel: 'Windwardøyene',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KJWWB',
                        label: 'Barbados',
                        originalLabel: 'Barbados',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KJWWD',
                        label: 'Dominica',
                        originalLabel: 'Dominica',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KJWWG',
                        label: 'Grenada',
                        originalLabel: 'Grenada',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KJWWL',
                        label: 'Saint Lucia',
                        originalLabel: 'Saint Lucia',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KJWWM',
                        label: 'Martinique',
                        originalLabel: 'Martinique',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KJWWM-FR-A',
                            label: 'Fort-De-France',
                            originalLabel: 'Fort-De-France',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KJWWM-FR-B',
                            label: 'La Trinité',
                            originalLabel: 'La Trinité',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KJWWM-FR-C',
                            label: 'Le Marin',
                            originalLabel: 'Le Marin',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KJWWM-FR-D',
                            label: 'Saint-Pierre',
                            originalLabel: 'Saint-Pierre',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KJWWT',
                        label: 'Trinidad og Tobago',
                        originalLabel: 'Trinidad og Tobago',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KJWWT-TT-P',
                            label: 'Port of Spain',
                            originalLabel: 'Port of Spain',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KJWWV',
                        label: 'Saint Vincent',
                        originalLabel: 'Saint Vincent',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1KJX',
                label: 'De små Antillene',
                originalLabel: 'De små Antillene',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1KJXA',
                    label: 'Aruba',
                    originalLabel: 'Aruba',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1KJX-BL-B',
                    label: 'Saint Barthélemy',
                    originalLabel: 'Saint Barthélemy',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1KJX-BQ-B',
                    label: 'Bonaire',
                    originalLabel: 'Bonaire',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1KJX-BQ-E',
                    label: 'Sint Eustatius',
                    originalLabel: 'Sint Eustatius',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1KJX-BQ-S',
                    label: 'Saba',
                    originalLabel: 'Saba',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1KJX-CW-C',
                    label: 'Curaçao',
                    originalLabel: 'Curaçao',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1KJX-MF-M',
                    label: 'Saint Martin',
                    originalLabel: 'Saint Martin',
                    alternativeLabels: [],
                    note: 'Brukes for den franske delen',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1KJXN',
                    label: 'De nederlandske Antiller',
                    originalLabel: 'De nederlandske Antiller',
                    alternativeLabels: [],
                    note: 'Class here: Netherlands Antilles. Se også 1KJXA <a href="/thema/nb/1KJXA">Aruba</a>, 1KJX-BQ-B <a href="/thema/nb/1KJX-BQ-B">Bonaire</a>, 1KJX-BQ-E <a href="/thema/nb/1KJX-BQ-E">Sint Eustatius</a>, 1KJX-BQ-S <a href="/thema/nb/1KJX-BQ-S">Saba</a>, 1KJX-CW-C <a href="/thema/nb/1KJX-CW-C">Curaçao</a>, 1KJX-SX-M <a href="/thema/nb/1KJX-SX-M">Sint Maarten</a>',
                    related: [
                      {
                        id: '1KJXA',
                        label: 'Aruba',
                        originalLabel: 'Aruba',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: '1KJX-SX-M',
                    label: 'Sint Maarten',
                    originalLabel: 'Sint Maarten',
                    alternativeLabels: [],
                    note: 'Brukes for den nederlandske delen',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '1KL',
            label: 'Sør- og Sentral Amerika (inkludert Mexico), Latin-Amerika',
            originalLabel:
              'Sør- og Sentral Amerika (inkludert Mexico), Latin-Amerika',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1KLC',
                label: 'Sentral-Amerika (inkludert Mexico)',
                originalLabel: 'Sentral-Amerika (inkludert Mexico)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1KLCB',
                    label: 'Belize',
                    originalLabel: 'Belize',
                    alternativeLabels: [],
                    note: 'Class here: Belize Barrier Reef and Cayes, Caracol, Xunantunich',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1KLCG',
                    label: 'Guatemala',
                    originalLabel: 'Guatemala',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLCG-GT-A',
                        label: 'Guatemala department',
                        originalLabel: 'Guatemala department',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLCG-GT-AC',
                            label: 'Guatemala City',
                            originalLabel: 'Guatemala City',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLCG-GT-E',
                        label: 'Guatemala: den sentrale regionen',
                        originalLabel: 'Guatemala: den sentrale regionen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLCG-GT-EB',
                            label: 'Sacatepéquez',
                            originalLabel: 'Sacatepéquez',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLCG-GT-EBA',
                                label: 'Antigua Guatemala',
                                originalLabel: 'Antigua Guatemala',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1KLCG-GT-F',
                        label: 'Guatemala: den sør-vestlige regionen',
                        originalLabel: 'Guatemala: den sør-vestlige regionen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLCG-GT-FB',
                            label: 'Sololá',
                            originalLabel: 'Sololá',
                            alternativeLabels: [],
                            note: 'Her: Lake Atitlan',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLCG-GT-H',
                        label: 'Petan department',
                        originalLabel: 'Petan department',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLCG-GT-HF',
                            label: 'Flores (El Petén)',
                            originalLabel: 'Flores (El Petén)',
                            alternativeLabels: [],
                            note: 'Her: Tikal',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KLCH',
                    label: 'Honduras',
                    originalLabel: 'Honduras',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLCH-HN-A',
                        label: 'Honduras: den vestlige regionen',
                        originalLabel: 'Honduras: den vestlige regionen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLCH-HN-AC',
                            label: 'Copan',
                            originalLabel: 'Copan',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLCH-HN-C',
                        label: 'Honduras: den nordøstlige regionen',
                        originalLabel: 'Honduras: den nordøstlige regionen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLCH-HN-CJ',
                            label: 'Bay Islands',
                            originalLabel: 'Bay Islands',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLCH-HN-E',
                        label: 'Honduras: sentral-øst regionen',
                        originalLabel: 'Honduras: sentral-øst regionen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLCH-HN-EA',
                            label: 'Francisco Morazán (department)',
                            originalLabel: 'Francisco Morazán (department)',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLCH-HN-EAB',
                                label: 'Tegucigalpa',
                                originalLabel: 'Tegucigalpa',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KLCM',
                    label: 'Mexico',
                    originalLabel: 'Mexico',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLCM-MX-A',
                        label: 'Nordvestlige Mexico',
                        originalLabel: 'Nordvestlige Mexico',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLCM-MX-AA',
                            label: 'Baja California',
                            originalLabel: 'Baja California',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLCM-MX-AAB',
                                label: 'Tijuana',
                                originalLabel: 'Tijuana',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLCM-MX-AB',
                            label: 'Baja California Sur',
                            originalLabel: 'Baja California Sur',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLCM-MX-ABB',
                                label: 'Los Cabos',
                                originalLabel: 'Los Cabos',
                                alternativeLabels: [],
                                note: 'Class here: Cabo San Lucas / Cabo José del Cabo',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLCM-MX-AC',
                            label: 'Chihuahua',
                            originalLabel: 'Chihuahua',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLCM-MX-AD',
                            label: 'Durango',
                            originalLabel: 'Durango',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLCM-MX-AE',
                            label: 'Sinaloa',
                            originalLabel: 'Sinaloa',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLCM-MX-AEB',
                                label: 'Mazatlan',
                                originalLabel: 'Mazatlan',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLCM-MX-AF',
                            label: 'Sonora',
                            originalLabel: 'Sonora',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLCM-MX-B',
                        label: 'Nordøstlige Mexico',
                        originalLabel: 'Nordøstlige Mexico',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLCM-MX-BA',
                            label: 'Coahuila de Zaragoza',
                            originalLabel: 'Coahuila de Zaragoza',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLCM-MX-BB',
                            label: 'Nuevo Leon',
                            originalLabel: 'Nuevo Leon',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLCM-MX-BC',
                            label: 'Tamaulipas',
                            originalLabel: 'Tamaulipas',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLCM-MX-C',
                        label: 'Vest-Mexico',
                        originalLabel: 'Vest-Mexico',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLCM-MX-CA',
                            label: 'Colima',
                            originalLabel: 'Colima',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLCM-MX-CB',
                            label: 'Jalisco',
                            originalLabel: 'Jalisco',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLCM-MX-CBA',
                                label: 'Guadalajara',
                                originalLabel: 'Guadalajara',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KLCM-MX-CBD',
                                label: 'Puerto Vallarta',
                                originalLabel: 'Puerto Vallarta',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLCM-MX-CC',
                            label: 'Michoacán de Ocampo',
                            originalLabel: 'Michoacán de Ocampo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLCM-MX-CD',
                            label: 'Nayarit',
                            originalLabel: 'Nayarit',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLCM-MX-D',
                        label: 'Øst-Mexico',
                        originalLabel: 'Øst-Mexico',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLCM-MX-DA',
                            label: 'Hidalgo',
                            originalLabel: 'Hidalgo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLCM-MX-DB',
                            label: 'Puebla',
                            originalLabel: 'Puebla',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLCM-MX-DC',
                            label: 'Tlaxcala',
                            originalLabel: 'Tlaxcala',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLCM-MX-DD',
                            label: 'Veracruz',
                            originalLabel: 'Veracruz',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLCM-MX-E',
                        label: 'Nordlige sentral-Mexico',
                        originalLabel: 'Nordlige sentral-Mexico',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLCM-MX-EA',
                            label: 'Aguascalientes',
                            originalLabel: 'Aguascalientes',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLCM-MX-EB',
                            label: 'Guanajuato',
                            originalLabel: 'Guanajuato',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLCM-MX-EBB',
                                label: 'San Miguel de Allende',
                                originalLabel: 'San Miguel de Allende',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLCM-MX-EC',
                            label: 'Queretaro',
                            originalLabel: 'Queretaro',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLCM-MX-ED',
                            label: 'San Luis Potosi',
                            originalLabel: 'San Luis Potosi',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLCM-MX-EE',
                            label: 'Zacatecas',
                            originalLabel: 'Zacatecas',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLCM-MX-F',
                        label: 'Sørlige sentral-Mexico',
                        originalLabel: 'Sørlige sentral-Mexico',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLCM-MX-FA',
                            label: 'Mexico City',
                            originalLabel: 'Mexico City',
                            alternativeLabels: [],
                            note: 'Class here: Mexico-Tenochtitlan',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLCM-MX-FB',
                            label: 'Mexico State',
                            originalLabel: 'Mexico State',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLCM-MX-FBM',
                                label: 'Teotihuacan',
                                originalLabel: 'Teotihuacan',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLCM-MX-FC',
                            label: 'Morelos',
                            originalLabel: 'Morelos',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLCM-MX-G',
                        label: 'Southwest Mexico',
                        originalLabel: 'Southwest Mexico',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLCM-MX-GA',
                            label: 'Chiapas',
                            originalLabel: 'Chiapas',
                            alternativeLabels: [],
                            note: 'Class here: Palenque',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLCM-MX-GB',
                            label: 'Guerrero',
                            originalLabel: 'Guerrero',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLCM-MX-GBB',
                                label: 'Acapulco',
                                originalLabel: 'Acapulco',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KLCM-MX-GBE',
                                label: 'Ixtapa-Zihuatanejo',
                                originalLabel: 'Ixtapa-Zihuatanejo',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLCM-MX-GC',
                            label: 'Oaxaca',
                            originalLabel: 'Oaxaca',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLCM-MX-GCA',
                                label: 'Oaxaca de Juarez',
                                originalLabel: 'Oaxaca de Juarez',
                                alternativeLabels: [],
                                note: 'Her: Monte Albán',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1KLCM-MX-H',
                        label: 'Southeast Mexico',
                        originalLabel: 'Southeast Mexico',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLCM-MX-HA',
                            label: 'Campeche',
                            originalLabel: 'Campeche',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLCM-MX-HB',
                            label: 'Quintana Roo',
                            originalLabel: 'Quintana Roo',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLCM-MX-HBB',
                                label: 'Cancun',
                                originalLabel: 'Cancun',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KLCM-MX-HBC',
                                label: 'Cozumel',
                                originalLabel: 'Cozumel',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KLCM-MX-HBD',
                                label: 'Playa del Carmen',
                                originalLabel: 'Playa del Carmen',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KLCM-MX-HBM',
                                label: 'Tulum',
                                originalLabel: 'Tulum',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KLCM-MX-HBN',
                                label: 'Riviera Maya',
                                originalLabel: 'Riviera Maya',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLCM-MX-HC',
                            label: 'Tabasco',
                            originalLabel: 'Tabasco',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLCM-MX-HD',
                            label: 'Yucatán',
                            originalLabel: 'Yucatán',
                            alternativeLabels: [],
                            note: 'Class here: Uxmal, Mayapan',
                            related: [],
                            children: [
                              {
                                id: '1KLCM-MX-HDA',
                                label: 'Merida',
                                originalLabel: 'Merida',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KLCM-MX-HDM',
                                label: 'Chichen Itza',
                                originalLabel: 'Chichen Itza',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLCM-MX-HX',
                            label: 'Sørøstlige Mexico: reisemål',
                            originalLabel: 'Sørøstlige Mexico: reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1KLCM-MX-HXP',
                                label: 'Yucatan',
                                originalLabel: 'Yucatan',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1KLCM-MX-Z',
                        label: 'Mexico: reisemål',
                        originalLabel: 'Mexico: reisemål',
                        alternativeLabels: [],
                        note: 'Use for: physical features, historical and cultural areas or other places of national interest or importance',
                        related: [],
                        children: [
                          {
                            id: '1KLCM-MX-ZC',
                            label: 'Californiabukta / Sea of Cortes',
                            originalLabel: 'Californiabukta / Sea of Cortes',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KLCN',
                    label: 'Nicaragua',
                    originalLabel: 'Nicaragua',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLCN-NI-A',
                        label: 'Nicaragua: stillehavsregionen',
                        originalLabel: 'Nicaragua: stillehavsregionen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLCN-NI-B',
                        label: 'Nicaragua: den sentrale regionen',
                        originalLabel: 'Nicaragua: den sentrale regionen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLCN-NI-C',
                        label: 'Nicaragua: den karibiske regionen',
                        originalLabel: 'Nicaragua: den karibiske regionen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLCN-NI-M',
                        label: 'Managua',
                        originalLabel: 'Managua',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KLCP',
                    label: 'Panama',
                    originalLabel: 'Panama',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLCP-PA-A',
                        label: 'Panama by',
                        originalLabel: 'Panama by',
                        alternativeLabels: [],
                        note: 'Class here: Panama City',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLCP-PA-C',
                        label: 'Colón',
                        originalLabel: 'Colón',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLCP-PA-D',
                        label: 'Darien',
                        originalLabel: 'Darien',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KLCR',
                    label: 'Costa Rica',
                    originalLabel: 'Costa Rica',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLCR-CR-A',
                        label: 'San José',
                        originalLabel: 'San José',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KLCS',
                    label: 'El Salvador',
                    originalLabel: 'El Salvador',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLCS-SV-A',
                        label: 'El Salvador: vest',
                        originalLabel: 'El Salvador: vest',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLCS-SV-B',
                        label: 'El Salvador: sentral',
                        originalLabel: 'El Salvador: sentral',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLCS-SV-BA',
                            label: 'San Salvador',
                            originalLabel: 'San Salvador',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLCS-SV-C',
                        label: 'El Salvador: øst',
                        originalLabel: 'El Salvador: øst',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1KLS',
                label: 'Sør-Amerika',
                originalLabel: 'Sør-Amerika',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1KLSA',
                    label: 'Argentina',
                    originalLabel: 'Argentina',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLSA-AR-A',
                        label: 'Nordvestlige Argentina',
                        originalLabel: 'Nordvestlige Argentina',
                        alternativeLabels: ['NOA'],

                        related: [],
                        children: [
                          {
                            id: '1KLSA-AR-AA',
                            label: 'Catamarca',
                            originalLabel: 'Catamarca',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSA-AR-AB',
                            label: 'Jujuy',
                            originalLabel: 'Jujuy',
                            alternativeLabels: [],
                            note: 'Her: Quebrada de Humahuaca',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSA-AR-AC',
                            label: 'La Rioja',
                            originalLabel: 'La Rioja',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSA-AR-AD',
                            label: 'Salta',
                            originalLabel: 'Salta',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSA-AR-AE',
                            label: 'Santiago del Estero',
                            originalLabel: 'Santiago del Estero',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSA-AR-AF',
                            label: 'Tucumán',
                            originalLabel: 'Tucumán',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLSA-AR-B',
                        label: 'Nordøstlige Argentina',
                        originalLabel: 'Nordøstlige Argentina',
                        alternativeLabels: ['NEA'],

                        related: [],
                        children: [
                          {
                            id: '1KLSA-AR-BA',
                            label: 'Corrientes',
                            originalLabel: 'Corrientes',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSA-AR-BB',
                            label: 'Chaco',
                            originalLabel: 'Chaco',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSA-AR-BC',
                            label: 'Formosa (AR)',
                            originalLabel: 'Formosa (AR)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSA-AR-BD',
                            label: 'Misiones',
                            originalLabel: 'Misiones',
                            alternativeLabels: [],
                            note: 'Her: Iguazú nasjonalpark',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLSA-AR-C',
                        label: 'Cuyo',
                        originalLabel: 'Cuyo',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSA-AR-CA',
                            label: 'Mendoza',
                            originalLabel: 'Mendoza',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSA-AR-CAM',
                                label: 'Aconcagua',
                                originalLabel: 'Aconcagua',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLSA-AR-CB',
                            label: 'San Juan (AR)',
                            originalLabel: 'San Juan (AR)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSA-AR-CC',
                            label: 'San Luis (AR)',
                            originalLabel: 'San Luis (AR)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLSA-AR-D',
                        label: 'Sentrale Argentina',
                        originalLabel: 'Sentrale Argentina',
                        alternativeLabels: ['Centro'],

                        related: [],
                        children: [
                          {
                            id: '1KLSA-AR-DA',
                            label: 'Buenos Aires',
                            originalLabel: 'Buenos Aires',
                            alternativeLabels: [],
                            note: 'Her: Mar del Plata',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSA-AR-DB',
                            label: 'Córdoba (AR)',
                            originalLabel: 'Córdoba (AR)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSA-AR-DC',
                            label: 'Entre Rios (Mesopotamia)',
                            originalLabel: 'Entre Rios (Mesopotamia)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSA-AR-DD',
                            label: 'La Pampa',
                            originalLabel: 'La Pampa',
                            alternativeLabels: ['Pampas'],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSA-AR-DE',
                            label: 'Santa Fe (AR)',
                            originalLabel: 'Santa Fe (AR)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLSA-AR-E',
                        label: 'Patagonia',
                        originalLabel: 'Patagonia',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSA-AR-EA',
                            label: 'Chubut',
                            originalLabel: 'Chubut',
                            alternativeLabels: [],
                            note: 'Her: Valdes Peninsula, Los Alerces',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSA-AR-EB',
                            label: 'Neuquén',
                            originalLabel: 'Neuquén',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSA-AR-EC',
                            label: 'Rio Negro (AR)',
                            originalLabel: 'Rio Negro (AR)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSA-AR-ED',
                            label: 'Santa Cruz (AR)',
                            originalLabel: 'Santa Cruz (AR)',
                            alternativeLabels: [],
                            note: 'Her: Perito Moreno, Fitz Roy / Chalten',
                            related: [],
                            children: [
                              {
                                id: '1KLSA-AR-EDM',
                                label: 'Glacier nasjonalpark',
                                originalLabel: 'Glacier nasjonalpark',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLSA-AR-EE',
                            label: 'Ildlandet',
                            originalLabel: 'Ildlandet',
                            alternativeLabels: ['Tierra del Fuego'],

                            related: [],
                            children: [
                              {
                                id: '1KLSA-AR-EEA',
                                label: 'Ushuaia',
                                originalLabel: 'Ushuaia',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KLSB',
                    label: 'Brasil',
                    originalLabel: 'Brasil',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLSB-BR-A',
                        label: 'Brasil: nord (region)',
                        originalLabel: 'Brasil: nord (region)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSB-BR-AA',
                            label: 'Acre (stat)',
                            originalLabel: 'Acre (stat)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-AB',
                            label: 'Amapa',
                            originalLabel: 'Amapa',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-AC',
                            label: 'Amazonas (stat)',
                            originalLabel: 'Amazonas (stat)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-AD',
                            label: 'Para',
                            originalLabel: 'Para',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-AE',
                            label: 'Rondônia',
                            originalLabel: 'Rondônia',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-AF',
                            label: 'Roraima',
                            originalLabel: 'Roraima',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-AG',
                            label: 'Tocantins',
                            originalLabel: 'Tocantins',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLSB-BR-B',
                        label: 'Brasil: nordøst (region)',
                        originalLabel: 'Brasil: nordøst (region)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSB-BR-BA',
                            label: 'Alagoas',
                            originalLabel: 'Alagoas',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-BB',
                            label: 'Bahia',
                            originalLabel: 'Bahia',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSB-BR-BBB',
                                label: 'Salvador de Bahia',
                                originalLabel: 'Salvador de Bahia',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLSB-BR-BC',
                            label: 'Ceara',
                            originalLabel: 'Ceara',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-BD',
                            label: 'Maranhão',
                            originalLabel: 'Maranhão',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-BE',
                            label: 'Paraíba',
                            originalLabel: 'Paraíba',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-BF',
                            label: 'Pernambuco',
                            originalLabel: 'Pernambuco',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSB-BR-BFB',
                                label: 'Recife',
                                originalLabel: 'Recife',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLSB-BR-BG',
                            label: 'Piaui',
                            originalLabel: 'Piaui',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-BH',
                            label: 'Rio Grande do Norte',
                            originalLabel: 'Rio Grande do Norte',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-BJ',
                            label: 'Sergipe',
                            originalLabel: 'Sergipe',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLSB-BR-C',
                        label: 'Brasil: sentral-vest (region)',
                        originalLabel: 'Brasil: sentral-vest (region)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSB-BR-CA',
                            label: 'Distrito Federal',
                            originalLabel: 'Distrito Federal',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSB-BR-CAB',
                                label: 'Brasilia',
                                originalLabel: 'Brasilia',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLSB-BR-CB',
                            label: 'Goias',
                            originalLabel: 'Goias',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-CC',
                            label: 'Mato Grosso',
                            originalLabel: 'Mato Grosso',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-CD',
                            label: 'Mato Grosso do Sul',
                            originalLabel: 'Mato Grosso do Sul',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-CZ',
                            label: 'Brasil: sentral-vest (region): reisemål',
                            originalLabel:
                              'Brasil: sentral-vest (region): reisemål',
                            alternativeLabels: [],
                            note: 'Brukes for fysiske egenskaper, historiske og kulturelle områder eller andre steder i regionen som er viktige eller av interesse',
                            related: [],
                            children: [
                              {
                                id: '1KLSB-BR-CZP',
                                label: 'Pantanal',
                                originalLabel: 'Pantanal',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1KLSB-BR-D',
                        label: 'Brasil: sørøst (region)',
                        originalLabel: 'Brasil: sørøst (region)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSB-BR-DA',
                            label: 'Espirito Santo',
                            originalLabel: 'Espirito Santo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-DB',
                            label: 'Minas Gerais',
                            originalLabel: 'Minas Gerais',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-DC',
                            label: 'Rio de Janeiro (stat)',
                            originalLabel: 'Rio de Janeiro (stat)',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSB-BR-DCA',
                                label: 'Rio de Janeiro',
                                originalLabel: 'Rio de Janeiro',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLSB-BR-DD',
                            label: 'São Paulo',
                            originalLabel: 'São Paulo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLSB-BR-E',
                        label: 'Brasil: sør (region)',
                        originalLabel: 'Brasil: sør (region)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSB-BR-EA',
                            label: 'Parana',
                            originalLabel: 'Parana',
                            alternativeLabels: [],
                            note: 'Her: Iguaçu nasjonalpark',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-EB',
                            label: 'Santa Catarina',
                            originalLabel: 'Santa Catarina',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSB-BR-EC',
                            label: 'Rio Grande do Sul',
                            originalLabel: 'Rio Grande do Sul',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KLSC',
                    label: 'Colombia',
                    originalLabel: 'Colombia',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLSC-CO-A',
                        label: 'Colombia: Amazonasregionen',
                        originalLabel: 'Colombia: Amazonasregionen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSC-CO-B',
                        label: 'Colombia: Andesregionen',
                        originalLabel: 'Colombia: Andesregionen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSC-CO-BA',
                            label: 'Antioquia',
                            originalLabel: 'Antioquia',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSC-CO-BAA',
                                label: 'Medellin',
                                originalLabel: 'Medellin',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLSC-CO-BB',
                            label: 'Boyaca',
                            originalLabel: 'Boyaca',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSC-CO-BD',
                            label: 'Cundinamarca',
                            originalLabel: 'Cundinamarca',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSC-CO-BDA',
                                label: 'Bogota',
                                originalLabel: 'Bogota',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLSC-CO-BF',
                            label: 'Norte de Santander',
                            originalLabel: 'Norte de Santander',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSC-CO-BJ',
                            label: 'Santander (department)',
                            originalLabel: 'Santander (department)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLSC-CO-C',
                        label: 'Colombia: Den karibiske kystregionen',
                        originalLabel: 'Colombia: Den karibiske kystregionen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSC-CO-CB',
                            label: 'Bolivar (department)',
                            originalLabel: 'Bolivar (department)',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSC-CO-CBA',
                                label: 'Cartagena',
                                originalLabel: 'Cartagena',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLSC-CO-CG',
                            label: 'San Andres, Providencia og Santa Catalina',
                            originalLabel:
                              'San Andres, Providencia og Santa Catalina',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLSC-CO-D',
                        label: 'Colombia: Orinoquia-regionen',
                        originalLabel: 'Colombia: Orinoquia-regionen',
                        alternativeLabels: ['Orinoco'],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSC-CO-E',
                        label: 'Colombia: Stillehavsregionen',
                        originalLabel: 'Colombia: Stillehavsregionen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSC-CO-ED',
                            label: 'Valle del Cauca',
                            originalLabel: 'Valle del Cauca',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSC-CO-EDA',
                                label: 'Cali',
                                originalLabel: 'Cali',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KLSE',
                    label: 'Ecuador',
                    originalLabel: 'Ecuador',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLSE-EC-A',
                        label: 'Ecuador: La Costa-regionen',
                        originalLabel: 'Ecuador: La Costa-regionen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSE-EC-B',
                        label: 'Ecuador: Andesregionen',
                        originalLabel: 'Ecuador: Andesregionen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSE-EC-BA',
                            label: 'Azuay',
                            originalLabel: 'Azuay',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSE-EC-BAA',
                                label: 'Cuenca',
                                originalLabel: 'Cuenca',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLSE-EC-BE',
                            label: 'Chimborazo',
                            originalLabel: 'Chimborazo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSE-EC-BF',
                            label: 'Cotopaxi',
                            originalLabel: 'Cotopaxi',
                            alternativeLabels: [],
                            note: 'Class here: Quilotoa',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSE-EC-BJ',
                            label: 'Pichincha',
                            originalLabel: 'Pichincha',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSE-EC-BJA',
                                label: 'Quito',
                                originalLabel: 'Quito',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1KLSE-EC-C',
                        label: 'Ecuador: Amazonasregionen',
                        originalLabel: 'Ecuador: Amazonasregionen',
                        alternativeLabels: [],
                        note: 'Class here: Cayambe Coca, Yasuni, Sangay',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KLSF',
                    label: 'Fransk Guyana',
                    originalLabel: 'Fransk Guyana',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLSF-FR-A',
                        label: 'Cayenne',
                        originalLabel: 'Cayenne',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSF-FR-B',
                        label: 'Saint-Laurent-du-Maroni',
                        originalLabel: 'Saint-Laurent-du-Maroni',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KLSG',
                    label: 'Guyana',
                    originalLabel: 'Guyana',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLSG-GY-G',
                        label: 'Georgetown',
                        originalLabel: 'Georgetown',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KLSH',
                    label: 'Chile',
                    originalLabel: 'Chile',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLSH-CL-A',
                        label: 'Norte Grande',
                        originalLabel: 'Norte Grande',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSH-CL-B',
                        label: 'Norte Chico',
                        originalLabel: 'Norte Chico',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSH-CL-C',
                        label: 'Chile: Zona Central',
                        originalLabel: 'Chile: Zona Central',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSH-CL-CD',
                            label: 'Santiago',
                            originalLabel: 'Santiago',
                            alternativeLabels: [],
                            note: 'Her: storbyområdet',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSH-CL-CV',
                            label: 'Valparaíso',
                            originalLabel: 'Valparaíso',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLSH-CL-D',
                        label: 'Zona Sur',
                        originalLabel: 'Zona Sur',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSH-CL-DA',
                            label: 'Biobío-regionen',
                            originalLabel: 'Biobío-regionen',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSH-CL-DAA',
                                label: 'Concepción',
                                originalLabel: 'Concepción',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLSH-CL-DC',
                            label: 'Los Ríos-regionen',
                            originalLabel: 'Los Ríos-regionen',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSH-CL-DCA',
                                label: 'Valdivia',
                                originalLabel: 'Valdivia',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLSH-CL-DD',
                            label: 'Los Lagos-regionen',
                            originalLabel: 'Los Lagos-regionen',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSH-CL-DDA',
                                label: 'Archipiélago de Chiloé',
                                originalLabel: 'Archipiélago de Chiloé',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1KLSH-CL-E',
                        label: 'Zona Austral',
                        originalLabel: 'Zona Austral',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSH-CL-EA',
                            label: 'Magallanes y de la Antártica Chilena',
                            originalLabel:
                              'Magallanes y de la Antártica Chilena',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KLSL',
                    label: 'Bolivia',
                    originalLabel: 'Bolivia',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLSL-BO-A',
                        label: 'Pando',
                        originalLabel: 'Pando',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSL-BO-B',
                        label: 'La Paz',
                        originalLabel: 'La Paz',
                        alternativeLabels: [],
                        note: 'Her: Tiwanaku, Tiahuanaco, Sorata',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSL-BO-C',
                        label: 'Beni',
                        originalLabel: 'Beni',
                        alternativeLabels: [],
                        note: 'Her: Rurrenabaque, Madidi',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSL-BO-D',
                        label: 'Oruro',
                        originalLabel: 'Oruro',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSL-BO-E',
                        label: 'Cochabamba',
                        originalLabel: 'Cochabamba',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSL-BO-F',
                        label: 'Santa Cruz',
                        originalLabel: 'Santa Cruz',
                        alternativeLabels: [],
                        note: 'Her: Noel Kempff Mercado, Chiquitania, Amboro, Samaipata',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSL-BO-G',
                        label: 'Potosi',
                        originalLabel: 'Potosi',
                        alternativeLabels: [],
                        note: 'Her: Salar de Uyuni, Torotoro',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSL-BO-H',
                        label: 'Chuquisaca',
                        originalLabel: 'Chuquisaca',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSL-BO-HA',
                            label: 'Sucre',
                            originalLabel: 'Sucre',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLSL-BO-J',
                        label: 'Tarija',
                        originalLabel: 'Tarija',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KLSP',
                    label: 'Paraguay',
                    originalLabel: 'Paraguay',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLSP-PY-A',
                        label: 'Asunción',
                        originalLabel: 'Asunción',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSP-PY-B',
                        label: 'Paraguay: den vestlige regionen',
                        originalLabel: 'Paraguay: den vestlige regionen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSP-PY-C',
                        label: 'Paraguay: den østlige regionen',
                        originalLabel: 'Paraguay: den østlige regionen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KLSR',
                    label: 'Peru',
                    originalLabel: 'Peru',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLSR-PE-A',
                        label: 'Peru: Amazonasregionen',
                        originalLabel: 'Peru: Amazonasregionen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSR-PE-AA',
                            label: 'Amazonasregionen',
                            originalLabel: 'Amazonasregionen',
                            alternativeLabels: [],
                            note: 'Her: Kuélap',
                            related: [],
                            children: [
                              {
                                id: '1KLSR-PE-AAB',
                                label: 'Chachapoyas',
                                originalLabel: 'Chachapoyas',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLSR-PE-AC',
                            label: 'Madre de Dios-regionen',
                            originalLabel: 'Madre de Dios-regionen',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSR-PE-ACT',
                                label: 'Tambopata',
                                originalLabel: 'Tambopata',
                                alternativeLabels: [],
                                note: 'Her: Tambopata nasjonalpark',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1KLSR-PE-B',
                        label: 'Peru: Andesregionen',
                        originalLabel: 'Peru: Andesregionen',
                        alternativeLabels: [],
                        note: 'Class here: Peruvian Highlands',
                        related: [],
                        children: [
                          {
                            id: '1KLSR-PE-BD',
                            label: 'Cuzcoregionen',
                            originalLabel: 'Cuzcoregionen',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSR-PE-BDC',
                                label: 'Cuzco',
                                originalLabel: 'Cuzco',
                                alternativeLabels: ['Cusco'],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KLSR-PE-BDD',
                                label: 'La Convención',
                                originalLabel: 'La Convención',
                                alternativeLabels: [],
                                note: 'Her: Choquequirao, Wamanmarka',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1KLSR-PE-BDM',
                                label: 'Machu Picchu',
                                originalLabel: 'Machu Picchu',
                                alternativeLabels: [],
                                note: 'Her: Urubambadalen',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1KLSR-PE-BDV',
                                label:
                                  'Nevado Ausangate og Cordillera Vilcanota',
                                originalLabel:
                                  'Nevado Ausangate og Cordillera Vilcanota',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1KLSR-PE-C',
                        label: 'Peru: den nordlige kystregionen',
                        originalLabel: 'Peru: den nordlige kystregionen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSR-PE-D',
                        label: 'Peru: den sentrale kystregionen',
                        originalLabel: 'Peru: den sentrale kystregionen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSR-PE-DA',
                            label: 'Ancash-regionen',
                            originalLabel: 'Ancash-regionen',
                            alternativeLabels: [],
                            note: 'Her: Chavín de Huantar, Sechín, Pañamarca',
                            related: [],
                            children: [
                              {
                                id: '1KLSR-PE-DAB',
                                label: 'Huaraz',
                                originalLabel: 'Huaraz',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                              {
                                id: '1KLSR-PE-DAS',
                                label:
                                  'Cordillera Blanca og Huascaran nasjonalpark',
                                originalLabel:
                                  'Cordillera Blanca og Huascaran nasjonalpark',
                                alternativeLabels: [],
                                note: 'Her: Cordillera Negra',
                                related: [],
                                children: [],
                              },
                              {
                                id: '1KLSR-PE-DAY',
                                label: 'Yerupaja og Huayhuash',
                                originalLabel: 'Yerupaja og Huayhuash',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: '1KLSR-PE-DC',
                            label: 'La Libertad-regionen',
                            originalLabel: 'La Libertad-regionen',
                            alternativeLabels: [],
                            note: 'Her: Huacas de Moche',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSR-PE-DD',
                            label: 'Lima-regionen',
                            originalLabel: 'Lima-regionen',
                            alternativeLabels: [],

                            related: [],
                            children: [
                              {
                                id: '1KLSR-PE-DDL',
                                label: 'Lima',
                                originalLabel: 'Lima',
                                alternativeLabels: [],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: '1KLSR-PE-E',
                        label: 'Peru: den sørlige kystregionen',
                        originalLabel: 'Peru: den sørlige kystregionen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSR-PE-EA',
                            label: 'Arequipa-regionen',
                            originalLabel: 'Arequipa-regionen',
                            alternativeLabels: [],
                            note: 'Her: Colca Canyon, Cotahuasi Canyon',
                            related: [],
                            children: [],
                          },
                          {
                            id: '1KLSR-PE-EB',
                            label: 'Ica-regionen',
                            originalLabel: 'Ica-regionen',
                            alternativeLabels: [],
                            note: 'Her: Huacachina',
                            related: [],
                            children: [
                              {
                                id: '1KLSR-PE-EBN',
                                label: 'Nazca',
                                originalLabel: 'Nazca',
                                alternativeLabels: [],
                                note: 'Her: Nazcalinjene',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KLSS',
                    label: 'Surinam',
                    originalLabel: 'Surinam',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLSS-SR-P',
                        label: 'Paramaribo',
                        originalLabel: 'Paramaribo',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KLSU',
                    label: 'Uruguay',
                    originalLabel: 'Uruguay',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLSU-UY-A',
                        label: 'Uruguay: nord',
                        originalLabel: 'Uruguay: nord',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSU-UY-B',
                        label: 'Uruguay: nordvest',
                        originalLabel: 'Uruguay: nordvest',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSU-UY-C',
                        label: 'Uruguay: sørvest',
                        originalLabel: 'Uruguay: sørvest',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSU-UY-D',
                        label: 'Uruguay: øst',
                        originalLabel: 'Uruguay: øst',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSU-UY-E',
                        label: 'Uruguay: sentrale sør',
                        originalLabel: 'Uruguay: sentrale sør',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSU-UY-EF',
                            label: 'Montevideo',
                            originalLabel: 'Montevideo',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1KLSV',
                    label: 'Venezuela',
                    originalLabel: 'Venezuela',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLSV-VE-A',
                        label: 'Venezuela: Andesregionen',
                        originalLabel: 'Venezuela: Andesregionen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSV-VE-AA',
                            label: 'Mérida (Venezuela)',
                            originalLabel: 'Mérida (Venezuela)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLSV-VE-B',
                        label: 'Venezuela: den sentrale regionen',
                        originalLabel: 'Venezuela: den sentrale regionen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSV-VE-C',
                        label: 'Hovedstadsregionen og Caracas',
                        originalLabel: 'Hovedstadsregionen og Caracas',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSV-VE-D',
                        label: 'Venezuela: Guayanaregionen',
                        originalLabel: 'Venezuela: Guayanaregionen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSV-VE-E',
                        label: 'Venezuela: øyregionen',
                        originalLabel: 'Venezuela: øyregionen',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLSV-VE-EA',
                            label: 'Isla Margarita',
                            originalLabel: 'Isla Margarita',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLSV-VE-F',
                        label: 'Los Llanos-regionen',
                        originalLabel: 'Los Llanos-regionen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSV-VE-G',
                        label: 'Venezuela: den nordøstlige regionen',
                        originalLabel: 'Venezuela: den nordøstlige regionen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSV-VE-H',
                        label: 'Venezuela: den sentrale, vestlige regionen',
                        originalLabel:
                          'Venezuela: den sentrale, vestlige regionen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLSV-VE-J',
                        label: 'Zulianaregionen',
                        originalLabel: 'Zulianaregionen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1KLZ',
                label: 'Sør- og Sentral-Amerika: fysiske aspekter',
                originalLabel: 'Sør- og Sentral-Amerika: fysiske aspekter',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1KLZA',
                    label: 'Sør- og Sentral-Amerika: elver, innsjøer etc.',
                    originalLabel:
                      'Sør- og Sentral-Amerika: elver, innsjøer etc.',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLZAA',
                        label: 'Amazonas og sideelver',
                        originalLabel: 'Amazonas og sideelver',
                        alternativeLabels: [],
                        note: 'Her: Amazonasbekkenet, Amazonasregnskogen, Amazonasbiomet, Amazonesdeltaet. Brukes for: elven, dens sideelver, elvedalen og elvedeltaet',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLZAC',
                        label: 'Panamakanalen',
                        originalLabel: 'Panamakanalen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLZAO',
                        label: 'Orinoco',
                        originalLabel: 'Orinoco',
                        alternativeLabels: [],
                        note: 'Her: elven, dens sideelver og elvedeltaet',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLZAP',
                        label: 'Paraná og Río de la Plata og sideelver',
                        originalLabel: 'Paraná og Río de la Plata og sideelver',
                        alternativeLabels: [],
                        note: 'Her: Uruguayelven. Brukes for elven, dens sideelver, elvedalen og elvedeltaet',
                        related: [],
                        children: [
                          {
                            id: '1KLZAPF',
                            label: 'Iguazú',
                            originalLabel: 'Iguazú',
                            alternativeLabels: ['Iguazúfallene, Iguaçufallene'],
                            note: 'Her: elven, dens sideelver og elvedalen',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLZAT',
                        label: 'Titicacasjøen',
                        originalLabel: 'Titicacasjøen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1KLZT',
                    label:
                      'Sør- og Sentral-Amerika: fjell, åser, sletter, kystlinjer etc.',
                    originalLabel:
                      'Sør- og Sentral-Amerika: fjell, åser, sletter, kystlinjer etc.',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1KLZTA',
                        label: 'Andesfjellene',
                        originalLabel: 'Andesfjellene',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1KLZTAB',
                            label: 'Altiplano',
                            originalLabel: 'Altiplano',
                            alternativeLabels: [],
                            note: 'Her: høysletten i Andesfjellene',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1KLZTG',
                        label: 'Galápagosøyene',
                        originalLabel: 'Galápagosøyene',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1KLZTM',
                        label: 'Sierra Madre',
                        originalLabel: 'Sierra Madre',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: '1M',
        label: 'Australasia, Oseania og andre landområder',
        originalLabel: 'Australasia, Oseania og andre landområder',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '1MB',
            label: 'Australia og New Zealand / Aotearoa',
            originalLabel: 'Australia og New Zealand / Aotearoa',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1MBF',
                label: 'Australia',
                originalLabel: 'Australia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1MBF-AU-C',
                    label: 'Australian Capital Territory (ACT)',
                    originalLabel: 'Australian Capital Territory (ACT)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1MBF-AU-CA',
                        label: 'Canberra',
                        originalLabel: 'Canberra',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1MBF-AU-N',
                    label: 'New South Wales',
                    originalLabel: 'New South Wales',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1MBF-AU-NS',
                        label: 'Sydney',
                        originalLabel: 'Sydney',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1MBF-AU-Q',
                    label: 'Queensland',
                    originalLabel: 'Queensland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1MBF-AU-QB',
                        label: 'Brisbane',
                        originalLabel: 'Brisbane',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1MBF-AU-S',
                    label: 'Sør-Australia',
                    originalLabel: 'Sør-Australia',
                    alternativeLabels: ['South Australia'],

                    related: [],
                    children: [
                      {
                        id: '1MBF-AU-SA',
                        label: 'Adelaide',
                        originalLabel: 'Adelaide',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1MBF-AU-T',
                    label: 'Tasmania',
                    originalLabel: 'Tasmania',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1MBF-AU-TH',
                        label: 'Hobart',
                        originalLabel: 'Hobart',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1MBF-AU-V',
                    label: 'Victoria',
                    originalLabel: 'Victoria',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1MBF-AU-VM',
                        label: 'Melbourne',
                        originalLabel: 'Melbourne',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1MBF-AU-W',
                    label: 'Vest-Australia',
                    originalLabel: 'Vest-Australia',
                    alternativeLabels: ['Western Australia'],

                    related: [],
                    children: [
                      {
                        id: '1MBF-AU-WP',
                        label: 'Perth',
                        originalLabel: 'Perth',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1MBF-AU-X',
                    label: 'Nordterritoriet',
                    originalLabel: 'Nordterritoriet',
                    alternativeLabels: ['Northern Territory'],

                    related: [],
                    children: [
                      {
                        id: '1MBF-AU-XD',
                        label: 'Darwin',
                        originalLabel: 'Darwin',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1MBN',
                label: 'New Zealand / Aotearoa',
                originalLabel: 'New Zealand / Aotearoa',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1MBN-NZ-D',
                    label: 'Nordøya / Te Ika-a-Māui',
                    originalLabel: 'Nordøya / Te Ika-a-Māui',
                    alternativeLabels: ['North Island'],

                    related: [],
                    children: [
                      {
                        id: '1MBN-NZ-DA',
                        label: 'Northland / Te Tai Tokerau',
                        originalLabel: 'Northland / Te Tai Tokerau',
                        alternativeLabels: [],
                        note: 'Her: Whangarei',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBN-NZ-DB',
                        label: 'Auckland / Tāmaki-makau-rau',
                        originalLabel: 'Auckland / Tāmaki-makau-rau',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBN-NZ-DC',
                        label: 'Waikato',
                        originalLabel: 'Waikato',
                        alternativeLabels: [],
                        note: 'Her: Hamilton',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBN-NZ-DD',
                        label: 'Bay of Plenty / Te Moana-a-Toitehuatahi',
                        originalLabel:
                          'Bay of Plenty / Te Moana-a-Toitehuatahi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBN-NZ-DE',
                        label: 'Gisborne / Tūranga-nui-a-Kiwa',
                        originalLabel: 'Gisborne / Tūranga-nui-a-Kiwa',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBN-NZ-DF',
                        label: 'Hawke‘s Bay / Te Matau-a-Māui',
                        originalLabel: 'Hawke‘s Bay / Te Matau-a-Māui',
                        alternativeLabels: [],
                        note: 'Her: Napier',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBN-NZ-DG',
                        label: 'Taranaki',
                        originalLabel: 'Taranaki',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBN-NZ-DH',
                        label: 'Manawatu-Whanganui',
                        originalLabel: 'Manawatu-Whanganui',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBN-NZ-DJ',
                        label: 'Wellington / Te Whanganui-a-Tara',
                        originalLabel: 'Wellington / Te Whanganui-a-Tara',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1MBN-NZ-G',
                    label: 'Sørøya / Te Waipounamu',
                    originalLabel: 'Sørøya / Te Waipounamu',
                    alternativeLabels: ['South Island'],

                    related: [],
                    children: [
                      {
                        id: '1MBN-NZ-GA',
                        label: 'Tasman / Te Tai o Aorere',
                        originalLabel: 'Tasman / Te Tai o Aorere',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBN-NZ-GB',
                        label: 'Nelson / Whakatū',
                        originalLabel: 'Nelson / Whakatū',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBN-NZ-GC',
                        label: 'Marlborough / Te Tauihu-o-te-waka',
                        originalLabel: 'Marlborough / Te Tauihu-o-te-waka',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBN-NZ-GD',
                        label: 'West Coast / Te Tai Poutini',
                        originalLabel: 'West Coast / Te Tai Poutini',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBN-NZ-GE',
                        label: 'Canterbury / Waitaha',
                        originalLabel: 'Canterbury / Waitaha',
                        alternativeLabels: [],
                        note: 'Her: Christchurch',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBN-NZ-GF',
                        label: 'Otago / Ōtākou',
                        originalLabel: 'Otago / Ōtākou',
                        alternativeLabels: [],
                        note: 'Her: Dunedin',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBN-NZ-GG',
                        label: 'Southland / Murihiku',
                        originalLabel: 'Southland / Murihiku',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBN-NZ-GP',
                        label: 'Southern Alps / Kā Tiritiri o te Moana',
                        originalLabel: 'Southern Alps / Kā Tiritiri o te Moana',
                        alternativeLabels: [],
                        note: 'Her: Aoraki / Mount Cook',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1MBZ',
                label: 'Australasia: fysiske aspekter',
                originalLabel: 'Australasia: fysiske aspekter',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1MBZA',
                    label: 'Australasia: elver, innsjøer etc.',
                    originalLabel: 'Australasia: elver, innsjøer etc.',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1MBZAD',
                        label: 'Murray-Darling og sideelver',
                        originalLabel: 'Murray-Darling og sideelver',
                        alternativeLabels: [],
                        note: 'Brukes for elvebekkenet, sideelver og elvemunningen',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1MBZT',
                    label: 'Australasia: fjell, åser, sletter, kystlinjer etc.',
                    originalLabel:
                      'Australasia: fjell, åser, sletter, kystlinjer etc.',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1MBZTB',
                        label: 'Great Dividing Range',
                        originalLabel: 'Great Dividing Range',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBZTD',
                        label: 'Det store barriererevet',
                        originalLabel: 'Det store barriererevet',
                        alternativeLabels: ['Great barrier reef'],
                        note: 'eng: Great barrier reef',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1MBZTU',
                        label: 'Uluru og The Outback',
                        originalLabel: 'Uluru og The Outback',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: '1MK',
            label: 'Oseania',
            originalLabel: 'Oseania',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1MKC',
                label: 'Mikronesia',
                originalLabel: 'Mikronesia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1MKCB',
                    label: 'Palau',
                    originalLabel: 'Palau',
                    alternativeLabels: [],
                    note: 'Her: Belau',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1MKCC',
                    label: 'Karolinene',
                    originalLabel: 'Karolinene',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1MKCF',
                    label: 'Mikronesiaføderasjonen',
                    originalLabel: 'Mikronesiaføderasjonen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1MKCG',
                    label: 'Gilbertøyene',
                    originalLabel: 'Gilbertøyene',
                    alternativeLabels: [],
                    note: 'Se også 1MKPK <a href="/thema/nb/1MKPK">Kiribati</a>',
                    related: [
                      {
                        id: '1MKPK',
                        label: 'Kiribati',
                        originalLabel: 'Kiribati',
                        note: 'Se også 1MKCG <a href="/thema/nb/1MKCG">Gilbertøyene</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: '1MKCM',
                    label: 'Marshalløyene',
                    originalLabel: 'Marshalløyene',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1MKCN',
                    label: 'Nauru',
                    originalLabel: 'Nauru',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1MKCU',
                    label: 'Guam',
                    originalLabel: 'Guam',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1MKCV',
                    label: 'Nord-Marianene',
                    originalLabel: 'Nord-Marianene',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1MKL',
                label: 'Melanesia',
                originalLabel: 'Melanesia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1MKLF',
                    label: 'Fiji',
                    originalLabel: 'Fiji',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1MKLN',
                    label: 'Ny-Caledonia',
                    originalLabel: 'Ny-Caledonia',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1MKLN-FR-N',
                        label: 'Nouméa',
                        originalLabel: 'Nouméa',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1MKLP',
                    label: 'Ny-Guinea',
                    originalLabel: 'Ny-Guinea',
                    alternativeLabels: [],
                    note: 'Brukes for hele øya. Se også 1FMN-ID-P <a href="/thema/nb/1FMN-ID-P">Papua</a>',
                    related: [],
                    children: [
                      {
                        id: '1MKLPN',
                        label: 'Papua New Guinea',
                        originalLabel: 'Papua New Guinea',
                        alternativeLabels: ['Port Moresby'],
                        note: 'Se også 1FMN-ID-P <a href="/thema/nb/1FMN-ID-P">Papua</a>, 1MKLP <a href="/thema/nb/1MKLP">Ny-Guinea</a>',
                        related: [
                          {
                            id: '1MKLP',
                            label: 'Ny-Guinea',
                            originalLabel: 'Ny-Guinea',
                            note: 'Brukes for hele øya. Se også 1FMN-ID-P <a href="/thema/nb/1FMN-ID-P">Papua</a>',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1MKLS',
                    label: 'Salomonøyene',
                    originalLabel: 'Salomonøyene',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1MKLV',
                    label: 'Vanuatu',
                    originalLabel: 'Vanuatu',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1MKP',
                label: 'Polynesia',
                originalLabel: 'Polynesia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1MKPC',
                    label: 'Cookøyene',
                    originalLabel: 'Cookøyene',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1MKPCR',
                        label: 'Rarotonga',
                        originalLabel: 'Rarotonga',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1MKPE',
                    label: 'Påskeøya',
                    originalLabel: 'Påskeøya',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1MKPF',
                    label: 'Fransk Polynesia',
                    originalLabel: 'Fransk Polynesia',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1MKPFT',
                        label: 'Tahiti',
                        originalLabel: 'Tahiti',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1MKPFT-FR-P',
                            label: 'Papeete',
                            originalLabel: 'Papeete',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '1MKPH',
                    label: 'Hawaii-øyene',
                    originalLabel: 'Hawaii-øyene',
                    alternativeLabels: [],
                    note: 'Brukes i forbindelse med geofysikk, naturhistorie og reiser, eller hvis man ikke bruker de nasjonale utvidelsene for USA. Se også 1KBB-US-WPH <a href="/thema/nb/1KBB-US-WPH">Hawaii</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1MKPK',
                    label: 'Kiribati',
                    originalLabel: 'Kiribati',
                    alternativeLabels: [],
                    note: 'Se også 1MKCG <a href="/thema/nb/1MKCG">Gilbertøyene</a>',
                    related: [
                      {
                        id: '1MKCG',
                        label: 'Gilbertøyene',
                        originalLabel: 'Gilbertøyene',
                        note: 'Se også 1MKPK <a href="/thema/nb/1MKPK">Kiribati</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: '1MKPN',
                    label: 'Niue',
                    originalLabel: 'Niue',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1MKPP',
                    label: 'Pitcairnøyene',
                    originalLabel: 'Pitcairnøyene',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1MKPR',
                    label: 'Samoaøyene',
                    originalLabel: 'Samoaøyene',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1MKPRA',
                        label: 'Amerikansk Samoa',
                        originalLabel: 'Amerikansk Samoa',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MKPRW',
                        label: 'Samoa',
                        originalLabel: 'Samoa',
                        alternativeLabels: [],
                        note: 'Tidligere Vest-Samoa',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1MKPT',
                    label: 'Tonga',
                    originalLabel: 'Tonga',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1MKPU',
                    label: 'Tokelau',
                    originalLabel: 'Tokelau',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1MKPV',
                    label: 'Tuvalu',
                    originalLabel: 'Tuvalu',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1MKPW',
                    label: 'Wallis- og Futunaøyene',
                    originalLabel: 'Wallis- og Futunaøyene',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1MKPW-FR-M',
                        label: 'Mata-Utu',
                        originalLabel: 'Mata-Utu',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: '1MT',
            label: 'Andre landområder',
            originalLabel: 'Andre landområder',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1MTA',
                label: 'Atlanterhavsøyer',
                originalLabel: 'Atlanterhavsøyer',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1MTAN',
                    label: 'Nordatlantiske øyer',
                    originalLabel: 'Nordatlantiske øyer',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1MTANB',
                        label: 'Bermuda',
                        originalLabel: 'Bermuda',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MTANC',
                        label: 'Kanariøyene',
                        originalLabel: 'Kanariøyene',
                        alternativeLabels: [],
                        note: 'Brukes i forbindelse med geofysikk, naturhistorie og reiser, eller hvis man ikke bruker de nasjonale utvidelsene for Spania. Se også 1DSE-ES-E <a href="/thema/nb/1DSE-ES-E">Kanariøyene</a>',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1MTAN-FR-P',
                        label: 'Saint Pierre og Miquelon',
                        originalLabel: 'Saint Pierre og Miquelon',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MTANM',
                        label: 'Madeira',
                        originalLabel: 'Madeira',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MTANZ',
                        label: 'Azorene',
                        originalLabel: 'Azorene',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1MTAS',
                    label: 'Søratlantiske øyer',
                    originalLabel: 'Søratlantiske øyer',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1MTASC',
                        label: 'Ascension',
                        originalLabel: 'Ascension',
                        alternativeLabels: [],
                        note: 'Se også 1MTASH <a href="/thema/nb/1MTASH">St Helena</a>, 1MTAST <a href="/thema/nb/1MTAST">Tristan da Cunha</a>',
                        related: [
                          {
                            id: '1MTASH',
                            label: 'St Helena',
                            originalLabel: 'St Helena',
                            note: 'Se også 1MTASC <a href="/thema/nb/1MTASC">Ascension</a>, 1MTAST <a href="/thema/nb/1MTAST">Tristan da Cunha</a>',
                          },
                          {
                            id: '1MTAST',
                            label: 'Tristan da Cunha',
                            originalLabel: 'Tristan da Cunha',
                            note: 'Se også 1MTASC <a href="/thema/nb/1MTASC">Ascension</a>, 1MTASH <a href="/thema/nb/1MTASH">St Helena</a>',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: '1MTASF',
                        label: 'Falklandsøyene',
                        originalLabel: 'Falklandsøyene',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MTASG',
                        label: 'Sør-Georgia',
                        originalLabel: 'Sør-Georgia',
                        alternativeLabels: [],
                        note: 'Class here: South Sandwich Islands',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1MTASH',
                        label: 'St Helena',
                        originalLabel: 'St Helena',
                        alternativeLabels: [],
                        note: 'Se også 1MTASC <a href="/thema/nb/1MTASC">Ascension</a>, 1MTAST <a href="/thema/nb/1MTAST">Tristan da Cunha</a>',
                        related: [
                          {
                            id: '1MTASC',
                            label: 'Ascension',
                            originalLabel: 'Ascension',
                            note: 'Se også 1MTASH <a href="/thema/nb/1MTASH">St Helena</a>, 1MTAST <a href="/thema/nb/1MTAST">Tristan da Cunha</a>',
                          },
                          {
                            id: '1MTAST',
                            label: 'Tristan da Cunha',
                            originalLabel: 'Tristan da Cunha',
                            note: 'Se også 1MTASC <a href="/thema/nb/1MTASC">Ascension</a>, 1MTASH <a href="/thema/nb/1MTASH">St Helena</a>',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: '1MTAST',
                        label: 'Tristan da Cunha',
                        originalLabel: 'Tristan da Cunha',
                        alternativeLabels: [],
                        note: 'Se også 1MTASC <a href="/thema/nb/1MTASC">Ascension</a>, 1MTASH <a href="/thema/nb/1MTASH">St Helena</a>',
                        related: [
                          {
                            id: '1MTASC',
                            label: 'Ascension',
                            originalLabel: 'Ascension',
                            note: 'Se også 1MTASH <a href="/thema/nb/1MTASH">St Helena</a>, 1MTAST <a href="/thema/nb/1MTAST">Tristan da Cunha</a>',
                          },
                          {
                            id: '1MTASH',
                            label: 'St Helena',
                            originalLabel: 'St Helena',
                            note: 'Se også 1MTASC <a href="/thema/nb/1MTASC">Ascension</a>, 1MTAST <a href="/thema/nb/1MTAST">Tristan da Cunha</a>',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1MTN',
                label: 'Arktis',
                originalLabel: 'Arktis',
                alternativeLabels: ['nordområdene'],
                note: 'Her: det sirkumpolare Arktis, Polarsirkelen, Nordpolen',
                related: [],
                children: [
                  {
                    id: '1MTNG',
                    label: 'Grønland',
                    originalLabel: 'Grønland',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '1MTNG-GL-A',
                        label: 'Nordøst-Grønland biosfærereservat',
                        originalLabel: 'Nordøst-Grønland biosfærereservat',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MTNG-GL-D',
                        label: 'Kujalleq',
                        originalLabel: 'Kujalleq',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1MTNG-GL-G',
                        label: 'Sermersooq',
                        originalLabel: 'Sermersooq',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1MTNG-GL-GN',
                            label: 'Nuuk',
                            originalLabel: 'Nuuk',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1MTNG-GL-J',
                        label: 'Qeqqata',
                        originalLabel: 'Qeqqata',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '1MTNG-GL-JK',
                            label: 'Kangerlussuaq (Søndre Strømfjord)',
                            originalLabel: 'Kangerlussuaq (Søndre Strømfjord)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1MTNG-GL-M',
                        label: 'Avannaata',
                        originalLabel: 'Avannaata',
                        alternativeLabels: [],
                        note: 'Her: Qaasuitsup',
                        related: [],
                        children: [
                          {
                            id: '1MTNG-GL-MP',
                            label: 'Thule Air Base (Pituffik)',
                            originalLabel: 'Thule Air Base (Pituffik)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '1MTNG-GL-N',
                        label: 'Qeqertalik',
                        originalLabel: 'Qeqertalik',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1MTN-NO-J',
                    label: 'Jan Mayen',
                    originalLabel: 'Jan Mayen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1MTN-NO-S',
                    label: 'Svalbard',
                    originalLabel: 'Svalbard',
                    alternativeLabels: [],
                    note: 'Her: Spitsbergen',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1MTS',
                label: 'Antarktis',
                originalLabel: 'Antarktis',
                alternativeLabels: ['sydpolen'],
                note: 'Her: Sørpolen, Den antarktiske polarsirkelen',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: '1Q',
        label: 'Andre geografiske grupperinger, hav og sjøer',
        originalLabel: 'Andre geografiske grupperinger, hav og sjøer',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '1QB',
            label: 'Historiske land og riker',
            originalLabel: 'Historiske land og riker',
            alternativeLabels: [],
            note: 'Brukes for historiske land, riker, territorier og regioner. Brukes for å angi at ei bok tar for seg et historisk geografisk eller kulturelt område. Brukes med andre 1QB*-koder når det ikke fins en mer spesifikk kode for det historiske området',
            related: [],
            children: [
              {
                id: '1QBA',
                label: 'Oldtiden',
                originalLabel: 'Oldtiden',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1QBAA',
                    label: 'Assyriske riker',
                    originalLabel: 'Assyriske riker',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBAB',
                    label: 'Babylonia',
                    originalLabel: 'Babylonia',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBAE',
                    label: 'Det gamle Egypt',
                    originalLabel: 'Det gamle Egypt',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBAG',
                    label: 'Antikkens Hellas',
                    originalLabel: 'Antikkens Hellas',
                    alternativeLabels: [],
                    note: 'Class here: Classical and Hellenistic Greek worlds',
                    related: [],
                    children: [
                      {
                        id: '1QBAG-IT-A',
                        label: 'Magna Graecia',
                        originalLabel: 'Magna Graecia',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1QBAH',
                    label: 'Hettitterriket',
                    originalLabel: 'Hettitterriket',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBAL',
                    label: 'Oldtidens Palestina',
                    originalLabel: 'Oldtidens Palestina',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBAM',
                    label: 'Mesopotamia',
                    originalLabel: 'Mesopotamia',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBAP',
                    label: 'Perserriket',
                    originalLabel: 'Perserriket',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBAQ',
                    label: 'Fønikia',
                    originalLabel: 'Fønikia',
                    alternativeLabels: [],
                    note: 'Her: den fønikiske, puniske / karthagiske sivilisasjonen',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBAR',
                    label: 'Romerriket',
                    originalLabel: 'Romerriket',
                    alternativeLabels: [],
                    note: 'Class here: Roman Republic, the Roman Empire',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBAS',
                    label: 'Samaria',
                    originalLabel: 'Samaria',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBAT',
                    label: 'Etruria',
                    originalLabel: 'Etruria',
                    alternativeLabels: [],
                    note: 'Her: etruskere',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1QBC',
                label: 'Historiske stater og riker: multikontinental',
                originalLabel: 'Historiske stater og riker: multikontinental',
                alternativeLabels: [],
                note: 'Brukes for historiske land, riker, territorier og regioner. Brukes for å angi at ei bok tar for seg et historisk geografisk eller kulturelt område som dekket mer enn et kontinent. Brukes med andre kvalifikatorer for sted når det ikke fins en mer spesifikk kode for det historiske området',
                related: [],
                children: [
                  {
                    id: '1QBCB',
                    label: 'Det bysantinske riket',
                    originalLabel: 'Det bysantinske riket',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBCE',
                    label: 'Det islamske kalifat',
                    originalLabel: 'Det islamske kalifat',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBC-FR-C',
                    label: 'Det franske koloniriket',
                    originalLabel: 'Det franske koloniriket',
                    alternativeLabels: [],
                    note: 'Se også 1QBK-CA-Q <a href="/thema/nb/1QBK-CA-Q">Ny-Frankrike</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBCL',
                    label: 'Mongolriket',
                    originalLabel: 'Mongolriket',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBCM',
                    label: 'Silkeveien',
                    originalLabel: 'Silkeveien',
                    alternativeLabels: [],
                    note: 'Brukes for bøker om byer, geografiske områder eller handelsruter som forbindes med Silkeveien',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBCS',
                    label: 'Det osmanske riket',
                    originalLabel: 'Det osmanske riket',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBCU',
                    label: 'Det britiske imperiet',
                    originalLabel: 'Det britiske imperiet',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBCV',
                    label: 'Det spanske imperiet',
                    originalLabel: 'Det spanske imperiet',
                    alternativeLabels: [],
                    note: 'Her: det spanske monarkiet, Visekongedømmet Ny-Spania, Visekongedømmet Peru, Visekongedømmet Ny-Granada, og Visekongedømmet Rio de la Plata',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBCW',
                    label: 'Det portugisiske imperiet',
                    originalLabel: 'Det portugisiske imperiet',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1QBD',
                label: 'Historiske land og riker: Europa',
                originalLabel: 'Historiske land og riker: Europa',
                alternativeLabels: [],
                note: 'Brukes for historiske land, riker, territorier og regioner. Brukes for å angi at ei bok tar for seg et historisk geografisk eller kulturelt område i Europa. Brukes med andre 1D*-kvalifikatorer der det er hensiktsmessig',
                related: [],
                children: [
                  {
                    id: '1QBDA',
                    label: 'Østerrike-Ungarn',
                    originalLabel: 'Østerrike-Ungarn',
                    alternativeLabels: [],
                    note: 'Her: Habsburgmonarkiet, Det østerrikske keiserrike',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBDB',
                    label: 'Vikinger / Norrøne sivilisasjoner',
                    originalLabel: 'Vikinger / Norrøne sivilisasjoner',
                    alternativeLabels: [],
                    note: 'Her: regioner og steder man forbinder med historiske, skandinaviske folkeslag, og grupper som normannere, væringer, anglo-skandinaver, norrøn-gælere, danelag-områder. Brukes med andre kvalifikatorer for sted for å angi at ei bok handler om et spesielt område',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBD-BE-B',
                    label:
                      'De burgundiske Nederlandene / De sytten nederlandske provinser',
                    originalLabel:
                      'De burgundiske Nederlandene / De sytten nederlandske provinser',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBD-BE-C',
                    label: 'Sørlige Nederlandene',
                    originalLabel: 'Sørlige Nederlandene',
                    alternativeLabels: [],
                    note: 'Her: De spanske Nederlandene, De østerrikske Nederlandene',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBDC',
                    label: 'Keltiske sivilisasjoner',
                    originalLabel: 'Keltiske sivilisasjoner',
                    alternativeLabels: [],
                    note: 'Her: steder man forbinder med kulturer og folkeslag som kalles keltere, enten i historisk sammenheng, som f.eks. gælere, gallere, keltiberere, britonere, eller for bøker om noen eller alle av de keltiske nasjonene og territoriene. Brukes med andre kvalifikatorer for sted og tid der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBDF',
                    label: 'Det tysk-romerske riket',
                    originalLabel: 'Det tysk-romerske riket',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBD-FR-G',
                    label: 'Gallia',
                    originalLabel: 'Gallia',
                    alternativeLabels: [],
                    note: 'Her: keltisk og romersk Gallia',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBD-FR-L',
                    label: 'Det karolingske riket',
                    originalLabel: 'Det karolingske riket',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBD-FR-N',
                    label: 'Det første franske keiserdømme',
                    originalLabel: 'Det første franske keiserdømme',
                    alternativeLabels: [],
                    note: 'Brukes om Napoleon Bonapartes styre i perioden 1804-1814',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBD-FR-Z',
                    label: 'Frankrike: historiske regioner',
                    originalLabel: 'Frankrike: historiske regioner',
                    alternativeLabels: [],
                    note: 'Brukes med koder for franske regioner',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBDK',
                    label: 'Tsjekkoslovakia',
                    originalLabel: 'Tsjekkoslovakia',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBDL',
                    label: 'Øst-Tyskland (DDR)',
                    originalLabel: 'Øst-Tyskland (DDR)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBDN',
                    label: 'Vest-Tyskland',
                    originalLabel: 'Vest-Tyskland',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBDP',
                    label: 'Preussen',
                    originalLabel: 'Preussen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBDR',
                    label: 'Sovjetunionen',
                    originalLabel: 'Sovjetunionen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBDT',
                    label: 'Det russiske keiserdømmet',
                    originalLabel: 'Det russiske keiserdømmet',
                    alternativeLabels: [],
                    note: 'Her: det russiske imperiet, Tsar-Russland',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBDY',
                    label: 'Jugoslavia',
                    originalLabel: 'Jugoslavia',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1QBF',
                label: 'Historiske land og riker: Asia',
                originalLabel: 'Historiske land og riker: Asia',
                alternativeLabels: [],
                note: 'Brukes for historiske land, riker, territorier og regioner. Brukes for å angi at ei bok tar for seg et historisk geografisk eller kulturelt område i Asia. Brukes med andre 1F*-kvalifikatorer der det er hensiktsmessig',
                related: [],
                children: [
                  {
                    id: '1QBFB',
                    label: 'Induskulturen',
                    originalLabel: 'Induskulturen',
                    alternativeLabels: [],
                    note: 'Her: den harappanske sivilisasjon',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBFC',
                    label: 'Det keiserlige Kina',
                    originalLabel: 'Det keiserlige Kina',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBFG',
                    label: 'Mogulriket',
                    originalLabel: 'Mogulriket',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1QBH',
                label: 'Historiske land og riker: Afrika',
                originalLabel: 'Historiske land og riker: Afrika',
                alternativeLabels: [],
                note: 'Use for: indicating a work concerns an historic geographic or cultural area in Africa. Use with: other PLACE 1H* codes as appropriate',
                related: [],
                children: [
                  {
                    id: '1QBHK',
                    label: 'Ashanti',
                    originalLabel: 'Ashanti',
                    alternativeLabels: [],
                    note: 'Class here: Asante civilisations',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBHM',
                    label: 'Beninriket / Edo-kongedømmet',
                    originalLabel: 'Beninriket / Edo-kongedømmet',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBHN',
                    label: 'Nubia',
                    originalLabel: 'Nubia',
                    alternativeLabels: [],
                    note: 'Her: Kongedømmet Kusj, Meroë',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1QBK',
                label: 'Historiske land og riker: Amerika',
                originalLabel: 'Historiske land og riker: Amerika',
                alternativeLabels: [],
                note: 'Brukes for historiske land, riker, territorier og regioner. Brukes for å angi at ei bok tar for seg et historisk geografisk eller kulturelt område i Amerika. Brukes med andre 1K*-kvalifikatorer der det er hensiktsmessig',
                related: [],
                children: [
                  {
                    id: '1QBKC',
                    label: 'Amerikas konfødererte stater',
                    originalLabel: 'Amerikas konfødererte stater',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBK-CA-Q',
                    label: 'Ny-Frankrike',
                    originalLabel: 'Ny-Frankrike',
                    alternativeLabels: ['Nouvelle-France'],
                    note: 'Se også 1QBC-FR-C <a href="/thema/nb/1QBC-FR-C">Det franske koloniriket</a>',
                    related: [],
                    children: [
                      {
                        id: '1QBK-CA-QA',
                        label: 'Ny-Frankrike: Acadia',
                        originalLabel: 'Ny-Frankrike: Acadia',
                        alternativeLabels: ['Nouvelle-France'],

                        related: [],
                        children: [],
                      },
                      {
                        id: '1QBK-CA-QL',
                        label: 'Ny-Frankrike: Louisiana',
                        originalLabel: 'Ny-Frankrike: Louisiana',
                        alternativeLabels: ['Nouvelle-France'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1QBK-CO-B',
                    label: 'Gran Colombia',
                    originalLabel: 'Gran Colombia',
                    alternativeLabels: ['Stor-Colombia'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBK-CO-C',
                    label: 'Nueva Granada',
                    originalLabel: 'Nueva Granada',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBKK',
                    label: 'Sivilisasjoner og kulturer i Andes',
                    originalLabel: 'Sivilisasjoner og kulturer i Andes',
                    alternativeLabels: [],
                    note: 'Her: Caral, Norte Chico, Valdivia, Chavin, Nazca, Moche, Chachapoyas, Wari, Huari, Tiwanku, Tiahuanaco, Cañari, Chimú, Muisca Confederation, Timoto–Cuica',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBKL',
                    label: 'Inkariket',
                    originalLabel: 'Inkariket',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QBKM',
                    label: 'Mesoamerikanske kulturer / sivilisasjoner',
                    originalLabel: 'Mesoamerikanske kulturer / sivilisasjoner',
                    alternativeLabels: ['Teotihuacan', 'Izapan', 'Tarascan'],
                    note: 'Her: huastekere, mixtekere, olmekere, toltekere, totonakere, zapotekere',
                    related: [],
                    children: [
                      {
                        id: '1QBKMA',
                        label: 'Det aztekiske riket',
                        originalLabel: 'Det aztekiske riket',
                        alternativeLabels: [
                          'aztekere',
                          'Mexico-Tenochtitlan',
                          'Tetzcoco',
                          'Tlacopan',
                          'Tepanecs',
                          'Mexica',
                        ],
                        note: 'Her: Den aztekiske trippelalliansen',
                        related: [],
                        children: [],
                      },
                      {
                        id: '1QBKMM',
                        label: 'Mayasivilisasjonen',
                        originalLabel: 'Mayasivilisasjonen',
                        alternativeLabels: ['mayakulturen'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1QBM',
                label:
                  'Historiske land og riker: Australasia, Oseania, Stillehavsøyene',
                originalLabel:
                  'Historiske land og riker: Australasia, Oseania, Stillehavsøyene',
                alternativeLabels: [],
                note: 'Brukes for historiske land, riker, territorier og regioner. Brukes for å angi at ei bok tar for seg et historisk geografisk eller kulturelt område i Oseania. Brukes med andre 1M*-kvalifikatorer der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '1QF',
            label: 'Politiske-, sosioøkonomiske- og strategiske grupperinger',
            originalLabel:
              'Politiske-, sosioøkonomiske- og strategiske grupperinger',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1QFA',
                label: 'Den arabiske verden',
                originalLabel: 'Den arabiske verden',
                alternativeLabels: ['den arabiske liga'],
                note: 'Brukes for grupper av arabisktalende land',
                related: [],
                children: [
                  {
                    id: '1QFAM',
                    label: 'Maghreb',
                    originalLabel: 'Maghreb',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1QFB',
                label: 'Den afrikanske union',
                originalLabel: 'Den afrikanske union',
                alternativeLabels: [],
                note: 'Her: Organisasjonen for afrikansk enhet',
                related: [],
                children: [],
              },
              {
                id: '1QFC',
                label: 'Samveldet av nasjoner',
                originalLabel: 'Samveldet av nasjoner',
                alternativeLabels: [],
                note: 'Her: Det britiske samveldet (tidligere kjent som)',
                related: [],
                children: [],
              },
              {
                id: '1QF-CA-A',
                label: 'Canada: atlantiske provinser (NB, PE, NS, NL)',
                originalLabel: 'Canada: atlantiske provinser (NB, PE, NS, NL)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QF-CA-C',
                label: 'Canada: sentrale provinser (ON, QC)',
                originalLabel: 'Canada: sentrale provinser (ON, QC)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QF-CA-E',
                label: 'Canada: østlige provinser (ON, QC, NB, PE, NS, NL)',
                originalLabel:
                  'Canada: østlige provinser (ON, QC, NB, PE, NS, NL)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QF-CA-M',
                label: 'Canada: maritime provinser (NB, PE, NS)',
                originalLabel: 'Canada: maritime provinser (NB, PE, NS)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QF-CA-P',
                label: 'Canada: prærieprovinser (AB, SK, MB)',
                originalLabel: 'Canada: prærieprovinser (AB, SK, MB)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QF-CA-T',
                label: 'Canada: territorier (YT, NT, NU)',
                originalLabel: 'Canada: territorier (YT, NT, NU)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QF-CA-W',
                label: 'Canada: vestlige provinser (BC, AB, SK, MB)',
                originalLabel: 'Canada: vestlige provinser (BC, AB, SK, MB)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QFE',
                label: 'EU (Den europeiske union)',
                originalLabel: 'EU (Den europeiske union)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QFG',
                label: 'Det globale sør / Utviklingsland',
                originalLabel: 'Det globale sør / Utviklingsland',
                alternativeLabels: ['u-land', 'uland'],
                note: 'Her: SDS (Small Island Developing States), minst utviklede land, LLDC (utviklingsland uten kystlinje). Brukes for gruppering av land som ikke nødvendigvis befinner seg på den sørlige halvkule, men som grupperes ut fra sosioøkonomiske og politiske karakteristika, ofte lavinntektsland med ulike nivåer av velstand, økonomisk utvikling og inntektsforskjeller. Disse er det motsatte av de landene som grupperes i 1QHF',
                related: [],
                children: [],
              },
              {
                id: '1QFH',
                label: 'Det globale nord / Industriland',
                originalLabel: 'Det globale nord / Industriland',
                alternativeLabels: ['i-land', 'iland'],
                note: 'Brukes for gruppering av land som ikke nødvendigvis befinner seg på den nordlige halvkule, men som grupperes ut fra sosioøkonomiske og politiske karakteristika, ofte høyinntekts- eller mellominntektsland. Disse er det motsatte av de landene som grupperes i 1QFG',
                related: [],
                children: [],
              },
              {
                id: '1QFL',
                label: 'Sameland (Sápmi)',
                originalLabel: 'Sameland (Sápmi)',
                alternativeLabels: [],
                note: 'Her: region i Nord-Europa',
                related: [],
                children: [],
              },
              {
                id: '1QFM',
                label: 'Den muslimske verden',
                originalLabel: 'Den muslimske verden',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QFN',
                label: 'NATO',
                originalLabel: 'NATO',
                alternativeLabels: ['North Atlantic Treaty Organisation'],
                note: 'Her: North atlantic treaty organization',
                related: [],
                children: [],
              },
              {
                id: '1QFP',
                label: 'OPEC',
                originalLabel: 'OPEC',
                alternativeLabels: [
                  'Organization of the Petroleum Exporting Countries',
                ],
                note: 'Her: Organisasjonen av oljeeksporterende land',
                related: [],
                children: [],
              },
              {
                id: '1QFS',
                label: 'ASEAN',
                originalLabel: 'ASEAN',
                alternativeLabels: [],
                note: 'Her: Association of Southeast Asian Nations',
                related: [],
                children: [],
              },
              {
                id: '1QFT',
                label: 'Mercosur / Mercosul',
                originalLabel: 'Mercosur / Mercosul',
                alternativeLabels: [],
                note: 'Her: Mercado Común del Sur',
                related: [],
                children: [],
              },
              {
                id: '1QFU',
                label: 'FN',
                originalLabel: 'FN',
                alternativeLabels: [
                  'Forente nasjoner',
                  'UN',
                  'UNESCO',
                  'UNEP',
                  'FAO',
                  'ILO',
                  'IMF',
                  'World Bank',
                  'WHIO',
                  'WIPO',
                  'WTO',
                ],
                note: 'Her: organisasjoner tilknyttet FN',
                related: [],
                children: [],
              },
              {
                id: '1QFW',
                label: 'Warszawapakten, Østblokken',
                originalLabel: 'Warszawapakten, Østblokken',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QFX',
                label: 'Iberoamerika',
                originalLabel: 'Iberoamerika',
                alternativeLabels: [],
                note: 'Brukes for den offisielle grupperingen av land i Amerika som er spansk- eller portugisisktalende, eller bøker om organisasjoner som grupperer disse landene sammen, og noen ganger Spania og Portugal',
                related: [],
                children: [],
              },
              {
                id: '1QFY',
                label: 'Francophonie',
                originalLabel: 'Francophonie',
                alternativeLabels: [],
                note: 'Her: Organisation internationale de la Francophonie or OIF. Brukes for grupperingen av alle de fransktalende landene',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '1QM',
            label: 'Klimaregioner',
            originalLabel: 'Klimaregioner',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1QMP',
                label: 'Polarområder',
                originalLabel: 'Polarområder',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QMQ',
                label: 'Subarktiske regioner / soner',
                originalLabel: 'Subarktiske regioner / soner',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QMR',
                label: 'Tempererte regioner/ soner',
                originalLabel: 'Tempererte regioner/ soner',
                alternativeLabels: [],
                note: 'Her: boreale, kontinentale, oseaniske, middelhavs- og fuktige subtropiske klimasoner',
                related: [],
                children: [],
              },
              {
                id: '1QMS',
                label: 'Tørre soner / ørkenområder',
                originalLabel: 'Tørre soner / ørkenområder',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QMT',
                label: 'Tropene',
                originalLabel: 'Tropene',
                alternativeLabels: [],
                note: 'Her: tropiske og ekvatoriale soner, inkludert regnskog- og monsunsoner',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '1QR',
            label: 'Områder forbundet av hav',
            originalLabel: 'Områder forbundet av hav',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1QRG',
                label: 'Det indopasifiske området',
                originalLabel: 'Det indopasifiske området',
                alternativeLabels: [],
                note: 'Brukes for regioner som er forbundet gjennom Indiahavet og de vestlige og sentrale delene av Stillehavet, samt andre hav som forbinder de to havområdene',
                related: [],
                children: [],
              },
              {
                id: '1QRM',
                label: 'Middelhavsland',
                originalLabel: 'Middelhavsland',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QRP',
                label: 'Stillehavsregionen',
                originalLabel: 'Stillehavsregionen',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1QRPN',
                    label: 'Asia-Pacific (APAC)',
                    originalLabel: 'Asia-Pacific (APAC)',
                    alternativeLabels: [],
                    note: 'Brukes for den delen av verden som ligger i nærheten av det vestlige Stillehavet. Inkluderer vanligvis Øst-Asia. Sørøst-Asia, Sør-Asia, Russlands fjerne østen og Oseania',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '1QS',
            label: 'Hav og sjøer',
            originalLabel: 'Hav og sjøer',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1QSA',
                label: 'Atlanterhavet',
                originalLabel: 'Atlanterhavet',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1QSAN',
                    label: 'Nord-Atlanteren',
                    originalLabel: 'Nord-Atlanteren',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QSAS',
                    label: 'Sør-Atlanteren',
                    originalLabel: 'Sør-Atlanteren',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1QSB',
                label: 'Østersjøen',
                originalLabel: 'Østersjøen',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QSC',
                label: 'Det karibiske hav',
                originalLabel: 'Det karibiske hav',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QSD',
                label: 'Mexicogolfen',
                originalLabel: 'Mexicogolfen',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QSE',
                label: 'Irskesjøen',
                originalLabel: 'Irskesjøen',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QSF',
                label: 'Nordsjøen',
                originalLabel: 'Nordsjøen',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QSG',
                label: 'Den engelske kanal',
                originalLabel: 'Den engelske kanal',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QSJ',
                label: 'Kaspiske hav',
                originalLabel: 'Kaspiske hav',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QSK',
                label: 'Svartehavet',
                originalLabel: 'Svartehavet',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QSL',
                label: 'Rødehavet',
                originalLabel: 'Rødehavet',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QSM',
                label: 'Middelhavet',
                originalLabel: 'Middelhavet',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1QSMA',
                    label: 'Det liguriske hav',
                    originalLabel: 'Det liguriske hav',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QSMB',
                    label: 'Tyrrenhavet',
                    originalLabel: 'Tyrrenhavet',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QSMC',
                    label: 'Adriaterhavet',
                    originalLabel: 'Adriaterhavet',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1QSMD',
                    label: 'Det joniske hav',
                    originalLabel: 'Det joniske hav',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1QSN',
                label: 'Det indiske hav',
                originalLabel: 'Det indiske hav',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1QSNP',
                    label: 'Persiabukta',
                    originalLabel: 'Persiabukta',
                    alternativeLabels: ['Den persiske gulf'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1QSP',
                label: 'Stillehavet',
                originalLabel: 'Stillehavet',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1QSPL',
                    label: 'Det vestlige Stillehav',
                    originalLabel: 'Det vestlige Stillehav',
                    alternativeLabels: [],
                    note: 'Her: Sørkinahavet, Østkinahavet, Filippinerhavet',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QSPN',
                    label: 'Det nordlige Stillehav',
                    originalLabel: 'Det nordlige Stillehav',
                    alternativeLabels: [],
                    note: 'Her: Beringhavet, Beringstredet, Alaskabukta, Salishhavet',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1QSPS',
                    label: 'Det sydlige Stillehav',
                    originalLabel: 'Det sydlige Stillehav',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1QSR',
                label: 'Nordishavet',
                originalLabel: 'Nordishavet',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QSS',
                label: 'Sørishavet',
                originalLabel: 'Sørishavet',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1QST',
                label: 'Tasmanhavet',
                originalLabel: 'Tasmanhavet',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: '1Z',
        label: 'Verdensrommet, planeter og utenomjordiske steder',
        originalLabel: 'Verdensrommet, planeter og utenomjordiske steder',
        alternativeLabels: [],
        note: 'Use for: works beyond the earth where a location has meaning either in setting or subject for the majority of the work',
        related: [],
        children: [
          {
            id: '1ZM',
            label: 'Solsystemet',
            originalLabel: 'Solsystemet',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '1ZMA',
                label: 'Solen',
                originalLabel: 'Solen',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '1ZMC',
                label: 'Planetene',
                originalLabel: 'Planetene',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1ZMCB',
                    label: 'Merkur',
                    originalLabel: 'Merkur',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1ZMCD',
                    label: 'Venus',
                    originalLabel: 'Venus',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1ZMCE',
                    label: 'Jorden',
                    originalLabel: 'Jorden',
                    alternativeLabels: [],
                    note: 'Use for: works on Earth as a planet, in the context of the Solar System or space. Se også 1A <a href="/thema/nb/1A">Verden</a>',
                    related: [
                      {
                        id: '1A',
                        label: 'Verden',
                        originalLabel: 'Verden',
                        note: 'Use for: indicating a specifically global context',
                      },
                    ],
                    children: [
                      {
                        id: '1ZMCEL',
                        label: 'Månen',
                        originalLabel: 'Månen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1ZMCF',
                    label: 'Mars',
                    originalLabel: 'Mars',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1ZMCH',
                    label: 'Jupiter',
                    originalLabel: 'Jupiter',
                    alternativeLabels: [],
                    note: 'Her: Jupiters måner, galileiske måner, Io, Europa, Gamymedes, Callisto',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1ZMCK',
                    label: 'Saturn',
                    originalLabel: 'Saturn',
                    alternativeLabels: [],
                    note: 'Her: Saturns ringer og månder, Titan, Iapetus, Tethys, Rhea, Enceladus, Mimas',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1ZMCM',
                    label: 'Uranus',
                    originalLabel: 'Uranus',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1ZMCN',
                    label: 'Neptun',
                    originalLabel: 'Neptun',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '1ZMG',
                label: 'Andre enheter i solsystemet',
                originalLabel: 'Andre enheter i solsystemet',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '1ZMGP',
                    label: 'Pluto og dvergplaneter',
                    originalLabel: 'Pluto og dvergplaneter',
                    alternativeLabels: [],
                    note: 'Class here: Kuiper Belt',
                    related: [],
                    children: [],
                  },
                  {
                    id: '1ZMGR',
                    label: 'Kometer',
                    originalLabel: 'Kometer',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '1ZMGT',
                    label: 'Asteroider, asteroidebeltet',
                    originalLabel: 'Asteroider, asteroidebeltet',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: '2',
    label: 'Kvalifikatorer: språk',
    originalLabel: 'Kvalifikatorer: språk',
    alternativeLabels: [],
    note: 'Bruk kvalifikatorer for språk MED passende emnekoder for å angi et språk boka handler OM, ikke det språket boka er skrevet på (dette beskrives i andre metadata), f.eks. bøker om lingvistikk, språklæringsmetoder for et bestemt språk, parlør eller ordbok. Nasjonale utvidelser (dvs. på formen 2*-xx-, hvor xx angir en landkode) kan brukes av alle, og er ikke eksklusive for brukere i det angitte landet. IKKE bruk kode ‘2’ alene, men velg en spesifikk kvalifikator fra 2*',
    related: [],
    children: [
      {
        id: '2A',
        label: 'Indoeuropeiske språk',
        originalLabel: 'Indoeuropeiske språk',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '2AC',
            label: 'Germanske og nordiske språk',
            originalLabel: 'Germanske og nordiske språk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2ACB',
                label: 'Engelsk',
                originalLabel: 'Engelsk',
                alternativeLabels: [],
                note: 'Brukes med kvalifikator(er) for geografi der det er hensiktsmessig, for å gi mer informasjon om ulike varianter av engelsk eller engelske dialekter',
                related: [],
                children: [
                  {
                    id: '2ACBA',
                    label: 'Angelsaksisk / gammelengelsk',
                    originalLabel: 'Angelsaksisk / gammelengelsk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2ACBC',
                    label: 'Mellomengelsk',
                    originalLabel: 'Mellomengelsk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2ACBK',
                    label: 'Amerikansk-engelsk',
                    originalLabel: 'Amerikansk-engelsk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2ACBM',
                    label: 'Australsk-engelsk',
                    originalLabel: 'Australsk-engelsk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2ACBR',
                    label: 'Kanadisk-engelsk',
                    originalLabel: 'Kanadisk-engelsk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2ACBX',
                    label: 'Internasjonal engelsk',
                    originalLabel: 'Internasjonal engelsk',
                    alternativeLabels: [],
                    note: 'Her: global engelsk, verdensengelsk etc.',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2ACC',
                label: 'Skotsk',
                originalLabel: 'Skotsk',
                alternativeLabels: [],
                note: 'Brukes for skotsk språk og dets regionale varianter, inkludert skotsk på Orknøyene og Shetland, dialekter i nordlige, sentrale og sørlige deler av Skottland. Brukes med kvalifikatorer for geografi fra 1DDU-GB-S* for å gi en indikasjon på hvilken skotske dialekt det er snakk om',
                related: [],
                children: [
                  {
                    id: '2ACCU',
                    label: 'Ulster-skotsk',
                    originalLabel: 'Ulster-skotsk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2ACD',
                label: 'Nederlandsk',
                originalLabel: 'Nederlandsk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2ACF',
                label: 'Flamsk',
                originalLabel: 'Flamsk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2ACG',
                label: 'Tysk',
                originalLabel: 'Tysk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '2ACGH',
                    label: 'Sveitsertysk / Alemannisk / Elsassisk',
                    originalLabel: 'Sveitsertysk / Alemannisk / Elsassisk',
                    alternativeLabels: [],
                    note: 'Her: schwabiske og alemanniske dialekter. Tilsvarer ISO639-2 gsw',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2ACGM',
                    label: 'Middelhøytysk',
                    originalLabel: 'Middelhøytysk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2ACGP',
                    label: 'Gammelhøytysk',
                    originalLabel: 'Gammelhøytysk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2ACK',
                label: 'Afrikaans',
                originalLabel: 'Afrikaans',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2ACS',
                label: 'Nordiske språk',
                originalLabel: 'Nordiske språk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '2ACSC',
                    label: 'Islandsk',
                    originalLabel: 'Islandsk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2ACSD',
                    label: 'Dansk',
                    originalLabel: 'Dansk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2ACSF',
                    label: 'Færøysk',
                    originalLabel: 'Færøysk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2ACSJ',
                    label: 'Jysk',
                    originalLabel: 'Jysk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2ACSN',
                    label: 'Norsk',
                    originalLabel: 'Norsk',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '2ACSNB',
                        label: 'Norsk, bokmål',
                        originalLabel: 'Norsk, bokmål',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2ACSNK',
                        label: 'Norsk, nynorsk',
                        originalLabel: 'Norsk, nynorsk',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '2ACSW',
                    label: 'Svensk',
                    originalLabel: 'Svensk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2ACSX',
                    label: 'Norrønt',
                    originalLabel: 'Norrønt',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2ACY',
                label: 'Jiddisch',
                originalLabel: 'Jiddisch',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2ACZ',
                label: 'Andre germanske språk og dialekter',
                originalLabel: 'Andre germanske språk og dialekter',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '2ACZF',
                    label: 'Frisisk',
                    originalLabel: 'Frisisk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2ACZH',
                    label: 'Nedertysk / nedersaksisk',
                    originalLabel: 'Nedertysk / nedersaksisk',
                    alternativeLabels: ['lavtysk', 'platt-tysk'],
                    note: 'Tilsvarer ISO 639-2 nds',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2ACZK',
                    label: 'Limburgsk',
                    originalLabel: 'Limburgsk',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-2 lim',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2ACZL',
                    label: 'Luxembourgsk',
                    originalLabel: 'Luxembourgsk',
                    alternativeLabels: ['Luxemburgsk', 'Lëtzebuergesch'],
                    note: 'Tilsvarer ISO 639-2 ltz',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '2AD',
            label: 'Romanske-, italiske- og retoromanske språk',
            originalLabel: 'Romanske-, italiske- og retoromanske språk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2AD-BE-W',
                label: 'Vallonsk',
                originalLabel: 'Vallonsk',
                alternativeLabels: [],
                note: 'Brukes med 1DDB* eller andre kvalifikatorer for sted for spesifikke dialekter. Tilsvarer ISO 639-2 wln',
                related: [],
                children: [],
              },
              {
                id: '2ADC',
                label: 'Katalansk',
                originalLabel: 'Katalansk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2ADD',
                label: 'Valensiansk',
                originalLabel: 'Valensiansk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2AD-ES-G',
                label: 'Aragonesisk',
                originalLabel: 'Aragonesisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2AD-ES-N',
                label: 'Aranesisk',
                originalLabel: 'Aranesisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2AD-ES-T',
                label: 'Asturiansk',
                originalLabel: 'Asturiansk',
                alternativeLabels: [],
                note: 'Her: leonesisk, asturleonesisk. Tilsvarer ISO 639-2 ast',
                related: [],
                children: [],
              },
              {
                id: '2ADF',
                label: 'Fransk',
                originalLabel: 'Fransk',
                alternativeLabels: ['langue d‘oïl'],
                note: 'Her: gallisk-romanske språk d‘oïl, standardisert fransk. Brukes for både det historiske og det eksisterende språket, samt dets varianter. Brukes med kvalifikatorer for sted for å angi hvilken fransk dialekt det er snakk om',
                related: [],
                children: [
                  {
                    id: '2ADFC',
                    label: 'Normannisk',
                    originalLabel: 'Normannisk',
                    alternativeLabels: ['Guernsey-fransk'],
                    note: 'Her: guernésiais, jèrriais, sarkesisk, normanniske dialekter. Tilsvarer ISO 639-3 nrf',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2ADFP',
                    label: 'Frankoprovençalsk',
                    originalLabel: 'Frankoprovençalsk',
                    alternativeLabels: [],
                    note: 'Her: arpitansk. Tilsvarer ISO 639-3 frp',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2ADFQ',
                    label: 'Fransk-kanadisk',
                    originalLabel: 'Fransk-kanadisk',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '2ADFQ-CA-A',
                        label: 'Akadisk fransk (kanadisk fransk)',
                        originalLabel: 'Akadisk fransk (kanadisk fransk)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2ADFQ-CA-C',
                        label: 'Québécois (kanadisk fransk)',
                        originalLabel: 'Québécois (kanadisk fransk)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '2AD-FR-C',
                label: 'Oksitansk',
                originalLabel: 'Oksitansk',
                alternativeLabels: [],
                note: 'Tilsvarer ISO 639-2 oci',
                related: [],
                children: [],
              },
              {
                id: '2AD-FR-P',
                label: 'Pikardisk',
                originalLabel: 'Pikardisk',
                alternativeLabels: ['ch‘ti'],
                note: 'Tilsvarer ISO 639-3 pcd',
                related: [],
                children: [],
              },
              {
                id: '2ADH',
                label: 'Korsikansk',
                originalLabel: 'Korsikansk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2ADL',
                label: 'Latin',
                originalLabel: 'Latin',
                alternativeLabels: [],
                note: 'Brukes for kirkelatin, middelaldersk og klassisk latin etc.',
                related: [],
                children: [],
              },
              {
                id: '2ADP',
                label: 'Portugisisk',
                originalLabel: 'Portugisisk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '2ADPB',
                    label: 'Brasiliansk-portugisisk',
                    originalLabel: 'Brasiliansk-portugisisk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2ADQ',
                label: 'Galisisk',
                originalLabel: 'Galisisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2ADR',
                label: 'Rumensk',
                originalLabel: 'Rumensk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '2ADRB',
                    label: 'Arumensk',
                    originalLabel: 'Arumensk',
                    alternativeLabels: ['Macedo-Romanian', 'Vlach'],
                    note: 'Tilsvarer ISO 639-3 rup',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2ADS',
                label: 'Spansk',
                originalLabel: 'Spansk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '2ADSL',
                    label: 'Latinamerikansk-spansk',
                    originalLabel: 'Latinamerikansk-spansk',
                    alternativeLabels: [],
                    note: 'Brukes med kvalifikator(er) for geografi der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2ADSR',
                    label: 'Jødespansk / Ladino',
                    originalLabel: 'Jødespansk / Ladino',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-2 lad',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2ADT',
                label: 'Italiensk',
                originalLabel: 'Italiensk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '2ADTX',
                    label: 'Italienske dialekter',
                    originalLabel: 'Italienske dialekter',
                    alternativeLabels: [],
                    note: 'Her: ligurisk, monegaskisk, piemontesisk, siciliansk, napolitansk-kalabresisk, venetiansk, lombardisk. Brukes med kavalifikatorer for sted for å angi hvilken italiensk dialekt det er snakk om',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2ADV',
                label: 'Sardinsk',
                originalLabel: 'Sardinsk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2ADW',
                label: 'Retoromanske språk',
                originalLabel: 'Retoromanske språk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '2ADWD',
                    label: 'Ladinsk',
                    originalLabel: 'Ladinsk',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-3 lld',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2ADWF',
                    label: 'Friulisk',
                    originalLabel: 'Friulisk',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-2 fur',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2ADWR',
                    label: 'Retoromansk',
                    originalLabel: 'Retoromansk',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-2 roh',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '2AF',
            label: 'Keltiske språk',
            originalLabel: 'Keltiske språk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2AFB',
                label: 'Bretonsk',
                originalLabel: 'Bretonsk',
                alternativeLabels: [],
                note: 'Tilsvarer ISO 639-2 bre',
                related: [],
                children: [],
              },
              {
                id: '2AFC',
                label: 'Kornisk',
                originalLabel: 'Kornisk',
                alternativeLabels: [],
                note: 'Tilsvarer ISO 639-2 cor',
                related: [],
                children: [],
              },
              {
                id: '2AFG',
                label: 'Gallisk',
                originalLabel: 'Gallisk',
                alternativeLabels: [],
                note: 'Her: galatisk, norisk',
                related: [],
                children: [],
              },
              {
                id: '2AFM',
                label: 'Mansk',
                originalLabel: 'Mansk',
                alternativeLabels: [],
                note: 'Tilsvarer ISO 639-2 glv',
                related: [],
                children: [],
              },
              {
                id: '2AFR',
                label: 'Irsk',
                originalLabel: 'Irsk',
                alternativeLabels: [],
                note: 'Tilsvarer ISO 639-2 gle',
                related: [],
                children: [],
              },
              {
                id: '2AFS',
                label: 'Skotsk-gælisk',
                originalLabel: 'Skotsk-gælisk',
                alternativeLabels: [],
                note: 'Tilsvarer ISO 639-2 gla',
                related: [],
                children: [],
              },
              {
                id: '2AFW',
                label: 'Walisisk',
                originalLabel: 'Walisisk',
                alternativeLabels: [],
                note: 'Tilsvarer ISO 639-2 cym',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '2AG',
            label: 'Slaviske språk',
            originalLabel: 'Slaviske språk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2AGB',
                label: 'Bulgarsk',
                originalLabel: 'Bulgarsk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2AGC',
                label: 'Kirkeslavisk',
                originalLabel: 'Kirkeslavisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2AGK',
                label: 'Slovakisk',
                originalLabel: 'Slovakisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2AGL',
                label: 'Hviterussisk',
                originalLabel: 'Hviterussisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2AGM',
                label: 'Makedonsk',
                originalLabel: 'Makedonsk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2AGN',
                label: 'Rusinsk',
                originalLabel: 'Rusinsk',
                alternativeLabels: [],
                note: 'Tilsvarer ISO 639-3 rue',
                related: [],
                children: [],
              },
              {
                id: '2AGP',
                label: 'Polsk',
                originalLabel: 'Polsk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2AGR',
                label: 'Russisk',
                originalLabel: 'Russisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2AGS',
                label: 'Serbokroatisk',
                originalLabel: 'Serbokroatisk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '2AGSB',
                    label: 'Bosnisk',
                    originalLabel: 'Bosnisk',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-3 bos',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2AGSC',
                    label: 'Kroatisk',
                    originalLabel: 'Kroatisk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2AGSK',
                    label: 'Montenegrinsk',
                    originalLabel: 'Montenegrinsk',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-2 cnr',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2AGSS',
                    label: 'Serbisk',
                    originalLabel: 'Serbisk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2AGU',
                label: 'Ukrainsk',
                originalLabel: 'Ukrainsk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2AGV',
                label: 'Slovensk',
                originalLabel: 'Slovensk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2AGW',
                label: 'Sorbisk (Vendisk)',
                originalLabel: 'Sorbisk (Vendisk)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2AGZ',
                label: 'Tsjekkisk',
                originalLabel: 'Tsjekkisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '2AH',
            label: 'Gresk',
            originalLabel: 'Gresk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2AHA',
                label: 'Gammelgresk',
                originalLabel: 'Gammelgresk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2AHB',
                label: 'Nytestamentlig gresk',
                originalLabel: 'Nytestamentlig gresk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2AHM',
                label: 'Nygresk',
                originalLabel: 'Nygresk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '2AJ',
            label: 'Baltiske- og andre indoeuropeiske språk',
            originalLabel: 'Baltiske- og andre indoeuropeiske språk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2AJB',
                label: 'Baltiske språk',
                originalLabel: 'Baltiske språk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '2AJBL',
                    label: 'Litauisk',
                    originalLabel: 'Litauisk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2AJBV',
                    label: 'Latvisk',
                    originalLabel: 'Latvisk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2AJK',
                label: 'Andre indoeuropeiske språk',
                originalLabel: 'Andre indoeuropeiske språk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '2AJKL',
                    label: 'Albansk',
                    originalLabel: 'Albansk',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-2 alb og aae, aat, aln, als fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2AJKR',
                    label: 'Armensk',
                    originalLabel: 'Armensk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: '2B',
        label: 'Indoariske, østindoeuropeiske og dravidiske språk',
        originalLabel: 'Indoariske, østindoeuropeiske og dravidiske språk',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '2BB',
            label: 'Urindoariske språk',
            originalLabel: 'Urindoariske språk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2BBA',
                label: 'Sanskrit',
                originalLabel: 'Sanskrit',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2BBP',
                label: 'Pali',
                originalLabel: 'Pali',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '2BM',
            label: 'Nyindoariske språk',
            originalLabel: 'Nyindoariske språk',
            alternativeLabels: [],
            note: 'Brukes for moderne indiske språk som ikke har en mer presis kode',
            related: [],
            children: [
              {
                id: '2BMB',
                label: 'Bengali',
                originalLabel: 'Bengali',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2BMD',
                label: 'Marathi',
                originalLabel: 'Marathi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2BMG',
                label: 'Gujarati',
                originalLabel: 'Gujarati',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2BMH',
                label: 'Hindi',
                originalLabel: 'Hindi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2BMJ',
                label: 'Rajasthani',
                originalLabel: 'Rajasthani',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2BMK',
                label: 'Kashmiri',
                originalLabel: 'Kashmiri',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2BMN',
                label: 'Nepali',
                originalLabel: 'Nepali',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2BMP',
                label: 'Panjabi',
                originalLabel: 'Panjabi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2BMR',
                label: 'Romani',
                originalLabel: 'Romani',
                alternativeLabels: [],
                note: 'Her: romanés. Brukes for ulike språk og dialekter som snakkes av rom og romanifolket',
                related: [],
                children: [],
              },
              {
                id: '2BMS',
                label: 'Singhalesisk',
                originalLabel: 'Singhalesisk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '2BMSM',
                    label: 'Maldivisk',
                    originalLabel: 'Maldivisk',
                    alternativeLabels: [],
                    note: 'Her: dhivehi',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2BMU',
                label: 'Urdu',
                originalLabel: 'Urdu',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '2BR',
            label: 'Dravidiske språk',
            originalLabel: 'Dravidiske språk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2BRB',
                label: 'Brahui',
                originalLabel: 'Brahui',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2BRK',
                label: 'Kannada (Kanaresisk)',
                originalLabel: 'Kannada (Kanaresisk)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2BRL',
                label: 'Telugu',
                originalLabel: 'Telugu',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2BRM',
                label: 'Malayalam',
                originalLabel: 'Malayalam',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2BRT',
                label: 'Tamilsk',
                originalLabel: 'Tamilsk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '2BX',
            label: 'Indoiranske språk',
            originalLabel: 'Indoiranske språk',
            alternativeLabels: [],
            note: 'Brukes for iranske eller indoiranske språk som ikke har en mer presis kode',
            related: [],
            children: [
              {
                id: '2BXF',
                label: 'Persisk (Farsi)',
                originalLabel: 'Persisk (Farsi)',
                alternativeLabels: [],
                note: 'Her: dari, tadsjikisk, parsi',
                related: [],
                children: [],
              },
              {
                id: '2BXK',
                label: 'Kurdisk',
                originalLabel: 'Kurdisk',
                alternativeLabels: [],
                note: 'Her: sorani, nordkurdisk, sørkurdisk',
                related: [],
                children: [],
              },
              {
                id: '2BXL',
                label: 'Pashto ( Pushto, Afghansk)',
                originalLabel: 'Pashto ( Pushto, Afghansk)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2BXZ',
                label: 'Avestisk',
                originalLabel: 'Avestisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: '2C',
        label: 'Afroasiatiske språk',
        originalLabel: 'Afroasiatiske språk',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '2CS',
            label: 'Semittiske språk',
            originalLabel: 'Semittiske språk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2CSA',
                label: 'Arameisk',
                originalLabel: 'Arameisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2CSB',
                label: 'Akkadisk',
                originalLabel: 'Akkadisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2CSJ',
                label: 'Hebraisk',
                originalLabel: 'Hebraisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2CSM',
                label: 'Maltesisk',
                originalLabel: 'Maltesisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2CSR',
                label: 'Arabisk',
                originalLabel: 'Arabisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2CSS',
                label: 'Syrisk',
                originalLabel: 'Syrisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2CST',
                label: 'Etiopiske språk',
                originalLabel: 'Etiopiske språk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '2CSTA',
                    label: 'Amharisk (Amarinja)',
                    originalLabel: 'Amharisk (Amarinja)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2CSTT',
                    label: 'Tigrinja',
                    originalLabel: 'Tigrinja',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '2CX',
            label: 'Ikke-semittiske afroasiatiske språk',
            originalLabel: 'Ikke-semittiske afroasiatiske språk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2CXB',
                label: 'Berberspråk',
                originalLabel: 'Berberspråk',
                alternativeLabels: [],
                note: 'Her: tuareg, tamazight, tashelhiyt, tarifit, kabylsk, tamasheq',
                related: [],
                children: [],
              },
              {
                id: '2CXC',
                label: 'Koptisk',
                originalLabel: 'Koptisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2CXG',
                label: 'Egyptisk',
                originalLabel: 'Egyptisk',
                alternativeLabels: [],
                note: 'Her: ur-, gammel-, mellom- og nyegyptisk',
                related: [],
                children: [],
              },
              {
                id: '2CXH',
                label: 'Hausa',
                originalLabel: 'Hausa',
                alternativeLabels: [],
                note: 'Her: tsjadspråk',
                related: [],
                children: [],
              },
              {
                id: '2CXS',
                label: 'Kusjittiske språk',
                originalLabel: 'Kusjittiske språk',
                alternativeLabels: [],
                note: 'Tilsvarer ISO 639-2 cus',
                related: [],
                children: [
                  {
                    id: '2CXSD',
                    label: 'Somali',
                    originalLabel: 'Somali',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-2 som',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2CXSR',
                    label: 'Oromo',
                    originalLabel: 'Oromo',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: '2F',
        label: 'Ural-altaiske språk og paleoasiatiske språk',
        originalLabel: 'Ural-altaiske språk og paleoasiatiske språk',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '2FC',
            label: 'Finsk-ugriske språk',
            originalLabel: 'Finsk-ugriske språk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2FCD',
                label: 'Estisk',
                originalLabel: 'Estisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2FCF',
                label: 'Finsk',
                originalLabel: 'Finsk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2FCL',
                label: 'Samisk',
                originalLabel: 'Samisk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '2FCLD',
                    label: 'Sørsamisk',
                    originalLabel: 'Sørsamisk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2FCLF',
                    label: 'Nordsamisk',
                    originalLabel: 'Nordsamisk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2FCLL',
                    label: 'Lulesamisk',
                    originalLabel: 'Lulesamisk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2FCLN',
                    label: 'Enaresamisk',
                    originalLabel: 'Enaresamisk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2FCLS',
                    label: 'Skoltesamisk',
                    originalLabel: 'Skoltesamisk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2FCLX',
                    label: 'Øvrige samiske språk',
                    originalLabel: 'Øvrige samiske språk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2FCM',
                label: 'Ungarsk',
                originalLabel: 'Ungarsk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2FCU',
                label: 'Uralske språk',
                originalLabel: 'Uralske språk',
                alternativeLabels: [],
                note: 'Her: samojediske, permiske, mordvinske, mariske språk',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '2FM',
            label: 'Tyrkiske språk',
            originalLabel: 'Tyrkiske språk',
            alternativeLabels: [],
            note: 'Her: tatarisk, uigurisk. Brukes for tyrkiske språk som ikke har en mer presis kode',
            related: [],
            children: [
              {
                id: '2FMC',
                label: 'Tyrkisk',
                originalLabel: 'Tyrkisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2FMH',
                label: 'Kirgisisk',
                originalLabel: 'Kirgisisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2FMK',
                label: 'Kasakhisk',
                originalLabel: 'Kasakhisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2FMN',
                label: 'Turkmensk',
                originalLabel: 'Turkmensk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2FMU',
                label: 'Usbekisk',
                originalLabel: 'Usbekisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2FMZ',
                label: 'Aserbajdsjansk',
                originalLabel: 'Aserbajdsjansk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '2FV',
            label: 'Mongolsk',
            originalLabel: 'Mongolsk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '2FW',
            label: 'Tungusiske språk',
            originalLabel: 'Tungusiske språk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2FWK',
                label: 'Evenkisk',
                originalLabel: 'Evenkisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2FWM',
                label: 'Mandsjuisk',
                originalLabel: 'Mandsjuisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '2FX',
            label: 'Paleoasiatiske språk',
            originalLabel: 'Paleoasiatiske språk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: '2G',
        label: 'Øst- og sørøstasiatiske språk',
        originalLabel: 'Øst- og sørøstasiatiske språk',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '2GD',
            label: 'Sino-tibetanske språk',
            originalLabel: 'Sino-tibetanske språk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2GDB',
                label: 'Burmansk',
                originalLabel: 'Burmansk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2GDC',
                label: 'Kinesisk',
                originalLabel: 'Kinesisk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '2GDCC',
                    label: 'Kantonesisk',
                    originalLabel: 'Kantonesisk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2GDCK',
                    label: 'Hokkien',
                    originalLabel: 'Hokkien',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2GDCM',
                    label: 'Mandarin',
                    originalLabel: 'Mandarin',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2GDCW',
                    label: 'Wu',
                    originalLabel: 'Wu',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2GDCY',
                    label: 'Amoy',
                    originalLabel: 'Amoy',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2GDK',
                label: 'Karen',
                originalLabel: 'Karen',
                alternativeLabels: [],
                note: 'Tilsvarer ISO 639-2 kar',
                related: [],
                children: [],
              },
              {
                id: '2GDT',
                label: 'Tibetansk',
                originalLabel: 'Tibetansk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '2GJ',
            label: 'Japansk',
            originalLabel: 'Japansk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '2GK',
            label: 'Koreansk',
            originalLabel: 'Koreansk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '2GR',
            label: 'Øvrige sørsøstasiatiske- og austroasiatiske språk',
            originalLabel: 'Øvrige sørsøstasiatiske- og austroasiatiske språk',
            alternativeLabels: [],
            note: 'Her: tai-kadai-språk',
            related: [],
            children: [
              {
                id: '2GRH',
                label: 'Khmer (Kambodsjansk)',
                originalLabel: 'Khmer (Kambodsjansk)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2GRL',
                label: 'Lao',
                originalLabel: 'Lao',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2GRM',
                label: 'Miao-yao',
                originalLabel: 'Miao-yao',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2GRS',
                label: 'Thai',
                originalLabel: 'Thai',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2GRV',
                label: 'Vietnamesisk',
                originalLabel: 'Vietnamesisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: '2H',
        label: 'Afrikanske språk',
        originalLabel: 'Afrikanske språk',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '2HC',
            label: 'Niger-kongospråk',
            originalLabel: 'Niger-kongospråk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2HCB',
                label: 'Bantuspråk',
                originalLabel: 'Bantuspråk',
                alternativeLabels: [],
                note: 'Her: umbundu, mbundu, kirundi, beti, lingala, ngala, luba-kasai, luba-katanga, songe, nande, sena, ovambo, kinyarwanda, sukuma, gogo, haya, chaga, nyamwezi, makonde, lusoga, masaba. Brukes for bantuspråk som ikke har en mer presis kode',
                related: [],
                children: [
                  {
                    id: '2HCBA',
                    label: 'Egentlige bantuspråk',
                    originalLabel: 'Egentlige bantuspråk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2HCBB',
                    label: 'Bantuspråk (Sentral-Afrika)',
                    originalLabel: 'Bantuspråk (Sentral-Afrika)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '2HCBBC',
                        label: 'Chichewa (Chewa)',
                        originalLabel: 'Chichewa (Chewa)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCBBF',
                        label: 'Lomwe',
                        originalLabel: 'Lomwe',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCBBH',
                        label: 'Nyanja',
                        originalLabel: 'Nyanja',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCBBJ',
                        label: 'Chitonga (Tonga, Nyasa)',
                        originalLabel: 'Chitonga (Tonga, Nyasa)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCBBL',
                        label: 'Tumbuka (Chitumbuka)',
                        originalLabel: 'Tumbuka (Chitumbuka)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCBBN',
                        label: 'Yao',
                        originalLabel: 'Yao',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCBBP',
                        label: 'Bemba',
                        originalLabel: 'Bemba',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCBBQ',
                        label: 'Kaonde',
                        originalLabel: 'Kaonde',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCBBR',
                        label: 'Kongo',
                        originalLabel: 'Kongo',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCBBS',
                        label: 'Lunda',
                        originalLabel: 'Lunda',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCBBU',
                        label: 'Luvale',
                        originalLabel: 'Luvale',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '2HCBD',
                    label: 'Swahili (Kiswahili)',
                    originalLabel: 'Swahili (Kiswahili)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2HCBH',
                    label: 'Herero',
                    originalLabel: 'Herero',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2HCBK',
                    label: 'Kikuyu',
                    originalLabel: 'Kikuyu',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2HCBL',
                    label: 'Nyoroganda',
                    originalLabel: 'Nyoroganda',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '2HCBLG',
                        label: 'Luganda',
                        originalLabel: 'Luganda',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCBLR',
                        label: 'Nyankore (Nyankole)',
                        originalLabel: 'Nyankore (Nyankole)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '2HCBM',
                    label: 'Fang',
                    originalLabel: 'Fang',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2HCBN',
                    label: 'Duala',
                    originalLabel: 'Duala',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2HCBP',
                    label: 'Tshivenda (Venda)',
                    originalLabel: 'Tshivenda (Venda)',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-2 ven',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2HCBQ',
                    label: 'Shona',
                    originalLabel: 'Shona',
                    alternativeLabels: [],
                    note: 'Her: korekore, zezuru, manyika, tewe, karanga, ndau. Tilsvarer ISO 639-3 sna, mxc, twx, ndc',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2HCBS',
                    label: 'Sotho–Tswana-språk',
                    originalLabel: 'Sotho–Tswana-språk',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '2HCBSA',
                        label: 'Sesotho (Sotho)',
                        originalLabel: 'Sesotho (Sotho)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCBSB',
                        label: 'Nordsotho (Pedi)',
                        originalLabel: 'Nordsotho (Pedi)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCBSD',
                        label: 'Setswana (Tswana)',
                        originalLabel: 'Setswana (Tswana)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCBSF',
                        label: 'Lozi',
                        originalLabel: 'Lozi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '2HCBT',
                    label: 'Sørndebele',
                    originalLabel: 'Sørndebele',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-2 nbl',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2HCBU',
                    label: 'Makua',
                    originalLabel: 'Makua',
                    alternativeLabels: ['Makhuwa'],
                    note: 'Her: koti, ekoti, nathembo, lomwe, chuwabo, maindo, moniga',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2HCBV',
                    label: 'XiTsonga (Tsonga)',
                    originalLabel: 'XiTsonga (Tsonga)',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-3 tso, tsc, rng',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2HCBW',
                    label: 'Siswati (Swazi)',
                    originalLabel: 'Siswati (Swazi)',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-2 ssw',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2HCBX',
                    label: 'isiXhosa (Xhosa)',
                    originalLabel: 'isiXhosa (Xhosa)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2HCBY',
                    label: 'isiNdebele (Ndebele)',
                    originalLabel: 'isiNdebele (Ndebele)',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-3 nde',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2HCBZ',
                    label: 'isiZulu (Zulu)',
                    originalLabel: 'isiZulu (Zulu)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2HCW',
                label: 'Vestatlantiske- og Volta-kongospråk',
                originalLabel: 'Vestatlantiske- og Volta-kongospråk',
                alternativeLabels: [],
                note: 'Her: ubangian, banda, ngbandi, gur, serer, ngbaka, ekajuk, kru, wolof, zande',
                related: [],
                children: [
                  {
                    id: '2HCWF',
                    label: 'Fulfulde',
                    originalLabel: 'Fulfulde',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-2 ful',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2HCWM',
                    label: 'Mandespråk',
                    originalLabel: 'Mandespråk',
                    alternativeLabels: [],
                    note: 'Her: bisa, samo, tura, dan, maninka, mandinka, manding, bambara, jowulu, kpelle, dioula, bozo, mende, soninke, susu, vai, yalunka',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2HCWV',
                    label: 'Volta-kongospråk',
                    originalLabel: 'Volta-kongospråk',
                    alternativeLabels: [],
                    note: 'Her: adangme, dangbe, akan, edo, bini, efik, ewondo, kwa, gbe, fon, fulani, tano, bamileke, nun, ngemba. nkambe, tiv, twi',
                    related: [],
                    children: [
                      {
                        id: '2HCWVB',
                        label: 'Ibo (Igbo)',
                        originalLabel: 'Ibo (Igbo)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCWVD',
                        label: 'Dagbani',
                        originalLabel: 'Dagbani',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCWVE',
                        label: 'Ewe',
                        originalLabel: 'Ewe',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCWVG',
                        label: 'Ga',
                        originalLabel: 'Ga',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCWVN',
                        label: 'Fanti',
                        originalLabel: 'Fanti',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCWVS',
                        label: 'Ashanti (Twi)',
                        originalLabel: 'Ashanti (Twi)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCWVT',
                        label: 'Akwapim Twi',
                        originalLabel: 'Akwapim Twi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2HCWVY',
                        label: 'Yoruba',
                        originalLabel: 'Yoruba',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: '2HK',
            label: 'Khoisanspråk',
            originalLabel: 'Khoisanspråk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '2HN',
            label: 'Nilo-saharaspråk',
            originalLabel: 'Nilo-saharaspråk',
            alternativeLabels: [],
            note: 'Her: dholou, kanuri, songhay, teso, lugbara, nandi–markweta, kalenjin, lango, luo, acholi, nuer, ngambay, fur, tubu',
            related: [],
            children: [
              {
                id: '2HND',
                label: 'Dinka',
                originalLabel: 'Dinka',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2HNM',
                label: 'Masai',
                originalLabel: 'Masai',
                alternativeLabels: ['maasai'],
                note: 'Tilsvarer ISO 639-3 mas, saq',
                related: [],
                children: [],
              },
              {
                id: '2HNR',
                label: 'Nubisk',
                originalLabel: 'Nubisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2HNT',
                label: 'Teso (Iteso)',
                originalLabel: 'Teso (Iteso)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '2HX',
            label: 'Øvrige afrikanske språk',
            originalLabel: 'Øvrige afrikanske språk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: '2J',
        label: 'Amerikanske urfolks språk',
        originalLabel: 'Amerikanske urfolks språk',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '2JN',
            label: 'Nord- og sentralamerikanske urfolks språk',
            originalLabel: 'Nord- og sentralamerikanske urfolks språk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2JNA',
                label: 'Aleutisk',
                originalLabel: 'Aleutisk',
                alternativeLabels: [],
                note: 'Tilsvarer ale fra ISO 639-3',
                related: [],
                children: [],
              },
              {
                id: '2JNB',
                label: 'Inuittiske språk',
                originalLabel: 'Inuittiske språk',
                alternativeLabels: [],
                note: 'Her: eskimoisk-aleutiske språk. Tilsvarer esk fra ISO 639-3',
                related: [],
                children: [
                  {
                    id: '2JNBK',
                    label: 'Inuktitut',
                    originalLabel: 'Inuktitut',
                    alternativeLabels: [],
                    note: 'Tilsvarer kodene iku, ike, ikt fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNBL',
                    label: 'Inuinnaqtun',
                    originalLabel: 'Inuinnaqtun',
                    alternativeLabels: [],
                    note: 'Tilsvarer ikt fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNBM',
                    label: 'Inupiak',
                    originalLabel: 'Inupiak',
                    alternativeLabels: [],
                    note: 'Tilsvarer kodene ipk, esi, esk fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNBN',
                    label: 'Yupik-språk',
                    originalLabel: 'Yupik-språk',
                    alternativeLabels: [],
                    note: 'Tilsvarer ypk fra ISO 639-2 og ems, esu, ess fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNBR',
                    label: 'Kalaallisut / Grønlandsk',
                    originalLabel: 'Kalaallisut / Grønlandsk',
                    alternativeLabels: [],
                    note: 'Tilsvarer kal fra ISO 639-2',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2JNC',
                label: 'Algonkin',
                originalLabel: 'Algonkin',
                alternativeLabels: [],
                note: 'Tilsvarer alg fra ISO 639-3',
                related: [],
                children: [
                  {
                    id: '2JNCA',
                    label: 'Siksika',
                    originalLabel: 'Siksika',
                    alternativeLabels: [],
                    note: 'Tilsvarer bla fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNCB',
                    label: 'Arapaho',
                    originalLabel: 'Arapaho',
                    alternativeLabels: [],
                    note: 'Tilsvarer arp fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNCC',
                    label: 'Cheyenne',
                    originalLabel: 'Cheyenne',
                    alternativeLabels: [],
                    note: 'Tilsvarer chy fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNCE',
                    label: 'Cree',
                    originalLabel: 'Cree',
                    alternativeLabels: [],
                    note: 'Tilsvarer cre fra ISO 639-2',
                    related: [],
                    children: [
                      {
                        id: '2JNCEA',
                        label: 'Plains Cree',
                        originalLabel: 'Plains Cree',
                        alternativeLabels: [],
                        note: 'Tilsvarer crk fra ISO 639-3',
                        related: [],
                        children: [],
                      },
                      {
                        id: '2JNCEB',
                        label: 'Woods Cree',
                        originalLabel: 'Woods Cree',
                        alternativeLabels: [],
                        note: 'Tilsvarer cwd fra ISO 639-3',
                        related: [],
                        children: [],
                      },
                      {
                        id: '2JNCEC',
                        label: 'Swampy Cree',
                        originalLabel: 'Swampy Cree',
                        alternativeLabels: [],
                        note: 'Tilsvarer csw fra ISO 639-3',
                        related: [],
                        children: [],
                      },
                      {
                        id: '2JNCED',
                        label: 'East Cree',
                        originalLabel: 'East Cree',
                        alternativeLabels: [],
                        note: 'Tilsvarer crl, crj fra ISO 639-3',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '2JNCF',
                    label: 'Naskapi',
                    originalLabel: 'Naskapi',
                    alternativeLabels: [],
                    note: 'Tilsvarer nsk fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNCG',
                    label: 'Innu-aimun / Montagnais',
                    originalLabel: 'Innu-aimun / Montagnais',
                    alternativeLabels: [],
                    note: 'Tilsvarer moe fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNCH',
                    label: 'Atikamekw',
                    originalLabel: 'Atikamekw',
                    alternativeLabels: [],
                    note: 'Tilsvarer atj fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNCJ',
                    label: 'Ojibwa',
                    originalLabel: 'Ojibwa',
                    alternativeLabels: [],
                    note: 'Tilsvarer oji, ojg, ojc, ojb, ojw fra ISO 639-3',
                    related: [],
                    children: [
                      {
                        id: '2JNCJB',
                        label: 'Chippewa',
                        originalLabel: 'Chippewa',
                        alternativeLabels: [],
                        note: 'Tilsvarer ciw fra ISO 639-3',
                        related: [],
                        children: [],
                      },
                      {
                        id: '2JNCJC',
                        label: 'Ottawa / Odawa',
                        originalLabel: 'Ottawa / Odawa',
                        alternativeLabels: [],
                        note: 'Tilsvarer otw fra ISO 639-3',
                        related: [],
                        children: [],
                      },
                      {
                        id: '2JNCJD',
                        label: 'Oji-Cree / Severn Ojibwa',
                        originalLabel: 'Oji-Cree / Severn Ojibwa',
                        alternativeLabels: [],
                        note: 'Tilsvarer ojs fra ISO 639-3',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '2JNCK',
                    label: 'Algonquin / Algonkin',
                    originalLabel: 'Algonquin / Algonkin',
                    alternativeLabels: [],
                    note: 'Tilsvarer alq fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNCL',
                    label: 'Potawatomi',
                    originalLabel: 'Potawatomi',
                    alternativeLabels: [],
                    note: 'Tilsvarer pot fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNCM',
                    label: 'Mesquakie-Sauk-Kickapoo',
                    originalLabel: 'Mesquakie-Sauk-Kickapoo',
                    alternativeLabels: [],
                    note: 'Tilsvarer sac, kic fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNCN',
                    label: 'Shawnee',
                    originalLabel: 'Shawnee',
                    alternativeLabels: [],
                    note: 'Tilsvarer sjw fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNCP',
                    label: 'Miami / Myaamia',
                    originalLabel: 'Miami / Myaamia',
                    alternativeLabels: [],
                    note: 'Tilsvarer mia fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNCQ',
                    label: 'Mi‘kmaq / Micmac',
                    originalLabel: 'Mi‘kmaq / Micmac',
                    alternativeLabels: [],
                    note: 'Tilsvarer mic fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNCR',
                    label: 'Abnaki / Abenaki',
                    originalLabel: 'Abnaki / Abenaki',
                    alternativeLabels: [],
                    note: 'Tilsvarer abe fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNCS',
                    label: 'Malecite-Passamaquoddy',
                    originalLabel: 'Malecite-Passamaquoddy',
                    alternativeLabels: [],
                    note: 'Tilsvarer pqm fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNCT',
                    label: 'Delaware / Lenape språk',
                    originalLabel: 'Delaware / Lenape språk',
                    alternativeLabels: [],
                    note: 'Tilsvarer del, umu fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2JND',
                label: 'Na-dene og athabaskiske språk',
                originalLabel: 'Na-dene og athabaskiske språk',
                alternativeLabels: [],
                note: 'Tilsvarer xnd fra ISO 639-5',
                related: [],
                children: [
                  {
                    id: '2JNDB',
                    label: 'Tlingit',
                    originalLabel: 'Tlingit',
                    alternativeLabels: [],
                    note: 'Tilsvarer tli fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNDD',
                    label: 'Athabaskiske språk / Dene-språk',
                    originalLabel: 'Athabaskiske språk / Dene-språk',
                    alternativeLabels: [],
                    note: 'Tilsvarer ath fra ISO 639-2',
                    related: [],
                    children: [
                      {
                        id: '2JNDDB',
                        label: 'Nordathabaskiske språk',
                        originalLabel: 'Nordathabaskiske språk',
                        alternativeLabels: [],
                        note: 'Her: Ahtena, Tanaina, Degexit‘an, Holikachuk, Koyukon, Upper Kuskokwim, Tanana, Tutchone, Gwichʼin, Han, Tagish, Tahltan, Kaska, Sekani, Beaver,Dane-zaa, Babine, Carrier, Chilcotin, Sarsi. Tilsvarer aht, tfn, ing, hoi, koy, kuu, taa, tce, ttm, gwi, haa, tgx, tht, kkz,sek, bea, bcr, crx, caf, clc, srs fra ISO 639-3',
                        related: [],
                        children: [
                          {
                            id: '2JNDDBK',
                            label: 'Slavey (språk)',
                            originalLabel: 'Slavey (språk)',
                            alternativeLabels: [],
                            note: 'Her: nordslavey, sørslavey. Tilsvarer den, scs, xsl fra ISO 639-3',
                            related: [],
                            children: [],
                          },
                          {
                            id: '2JNDDBP',
                            label: 'Dogrib',
                            originalLabel: 'Dogrib',
                            alternativeLabels: ['Tlicho'],
                            note: 'Tilsvarer dgr fra ISO 639-3',
                            related: [],
                            children: [],
                          },
                          {
                            id: '2JNDDBR',
                            label: 'Chipewyan / Dene Suline',
                            originalLabel: 'Chipewyan / Dene Suline',
                            alternativeLabels: [],
                            note: 'Tilsvarer chp fra ISO 639-3',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '2JNDDH',
                        label: 'Apachespråk (sørathabaskiske språk)',
                        originalLabel: 'Apachespråk (sørathabaskiske språk)',
                        alternativeLabels: [],
                        note: 'Her: Kiowa Apache, Mescalero-Chiricahua, Vest-Apache, Jicarilla. Tilsvarer apa fra ISO 639-2 og apk, apj, apm, apw fra ISO 639-3',
                        related: [],
                        children: [
                          {
                            id: '2JNDDHJ',
                            label: 'Navajo / Navaho',
                            originalLabel: 'Navajo / Navaho',
                            alternativeLabels: [],
                            note: 'Tilsvarer nav fra ISO 639-2',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                id: '2JNE',
                label: 'Kutenai',
                originalLabel: 'Kutenai',
                alternativeLabels: [],
                note: 'Tilsvarer kut fra ISO 639-2',
                related: [],
                children: [],
              },
              {
                id: '2JNF',
                label: 'Salishanske språk',
                originalLabel: 'Salishanske språk',
                alternativeLabels: [],
                note: 'Tilsvarer sal fra ISO 639-2, og lut, slh, ska, sno, sec, clm, str, twa, crd, col, fla, spo fra ISO 639-3',
                related: [],
                children: [
                  {
                    id: '2JNFB',
                    label: 'Nuxalk / Bella Coola',
                    originalLabel: 'Nuxalk / Bella Coola',
                    alternativeLabels: [],
                    note: 'Tilsvarer blc fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNFC',
                    label: 'Comox',
                    originalLabel: 'Comox',
                    alternativeLabels: [],
                    note: 'Tilsvarer coo fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNFD',
                    label: 'Halkomelem',
                    originalLabel: 'Halkomelem',
                    alternativeLabels: [],
                    note: 'Tilsvarer hur fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNFG',
                    label: 'Squamish',
                    originalLabel: 'Squamish',
                    alternativeLabels: [],
                    note: 'Tilsvarer squ fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNFH',
                    label: 'Shuswap',
                    originalLabel: 'Shuswap',
                    alternativeLabels: [],
                    note: 'Tilsvarer shs fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNFM',
                    label: 'Lillooet',
                    originalLabel: 'Lillooet',
                    alternativeLabels: [],
                    note: 'Tilsvarer lil fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNFR',
                    label: 'Ntlakyapamuk / Thompson',
                    originalLabel: 'Ntlakyapamuk / Thompson',
                    alternativeLabels: [],
                    note: 'Tilsvarer thp fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNFT',
                    label: 'Okanagan',
                    originalLabel: 'Okanagan',
                    alternativeLabels: [],
                    note: 'Tilsvarer oka fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2JNG',
                label: 'Irokesiske språk',
                originalLabel: 'Irokesiske språk',
                alternativeLabels: [],
                note: 'Tilsvarer iro fra ISO 639-2 og see, cay, ono, one, tus',
                related: [],
                children: [
                  {
                    id: '2JNGC',
                    label: 'Cherokee',
                    originalLabel: 'Cherokee',
                    alternativeLabels: [],
                    note: 'Tilsvarer chr fra ISO 639-2',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNGM',
                    label: 'Mohawk',
                    originalLabel: 'Mohawk',
                    alternativeLabels: [],
                    note: 'Tilsvarer moh fra ISO 639-2',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNGW',
                    label: 'Wyandot / Wendat – Huron',
                    originalLabel: 'Wyandot / Wendat – Huron',
                    alternativeLabels: [],
                    note: 'Tilsvarer wya fra ISO 639-3',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2JNH',
                label: 'Sioux- og catawbaspråk',
                originalLabel: 'Sioux- og catawbaspråk',
                alternativeLabels: [],
                note: 'Tilsvarer sio fra ISO 639-2 og cro, hid fra ISO 639-3',
                related: [],
                children: [
                  {
                    id: '2JNHB',
                    label: 'Sioux- / dakotaspråk',
                    originalLabel: 'Sioux- / dakotaspråk',
                    alternativeLabels: [],
                    note: 'Tilsvarer dak fra ISO 639-2',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2JNJ',
                label: 'Haidaspråk',
                originalLabel: 'Haidaspråk',
                alternativeLabels: [],
                note: 'Tilsvarer hai, hdn, hax fra ISO 639-3',
                related: [],
                children: [],
              },
              {
                id: '2JNK',
                label: 'Wakashanspråk',
                originalLabel: 'Wakashanspråk',
                alternativeLabels: [],
                note: 'Tilsvarer wak fra ISO 639-2 og dtd, has, hei, kwk, nuk from ISO 639-3',
                related: [],
                children: [],
              },
              {
                id: '2JNL',
                label: 'Tsimshianicspråk',
                originalLabel: 'Tsimshianicspråk',
                alternativeLabels: [],
                note: 'Tilsvarer git, ncg, tsi fra ISO 639-3',
                related: [],
                children: [],
              },
              {
                id: '2JNM',
                label: 'Mayaspråk',
                originalLabel: 'Mayaspråk',
                alternativeLabels: [],
                note: 'Her: maya, itzaʼ, mopan, wasteko, huasteco, chol, chʼol–chontal, chʼortiʼ–chʼoltiʼ, tzotzil, tzeltal, qʼanjobalan, quichean, mam',
                related: [],
                children: [],
              },
              {
                id: '2JNN',
                label: 'Uto-aztekiske språk',
                originalLabel: 'Uto-aztekiske språk',
                alternativeLabels: [],
                note: 'Her: tepehuán, o’otham, tarahumara, rarámuri / ralámuli, huarijio, varihío, huichol, cora, hopi, ute, paiute, shoshoni, nawat, comanche, luiseño, piman, oʼodham, yaqui, mayo',
                related: [],
                children: [
                  {
                    id: '2JNNA',
                    label: 'Nahuatlspråk',
                    originalLabel: 'Nahuatlspråk',
                    alternativeLabels: [],
                    note: 'Her: aztekisk. Brukes for ulike språk og dialekter som hører til denne språkfamilien. Tilsvarer nah fra ISO 639-2',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2JNP',
                label: 'Otomangueanske språk',
                originalLabel: 'Otomangueanske språk',
                alternativeLabels: [],
                note: 'Her: otomi, oto-Pamean, chinantecan, tlapanec, popolocan, mazatec, matlatzinca, amuzgoan, mazahua, chichimeca jonaz',
                related: [],
                children: [
                  {
                    id: '2JNPM',
                    label: 'Mixtecisk',
                    originalLabel: 'Mixtecisk',
                    alternativeLabels: [],
                    note: 'Her: mixteciske språk, cuiatec',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2JNPZ',
                    label: 'Zapotecisk',
                    originalLabel: 'Zapotecisk',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-2 zap',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2JNZ',
                label: 'Zuñi',
                originalLabel: 'Zuñi',
                alternativeLabels: [],
                note: 'Her: shiwiʼma',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '2JS',
            label: 'Søramerikanske- og karibiske urfolks språk',
            originalLabel: 'Søramerikanske- og karibiske urfolks språk',
            alternativeLabels: [
              'Arawakan',
              'Araucanian',
              'Mapuche',
              'Mapudungun',
              'Terena',
              'Carib',
              'Huilliche',
              'Tereno',
              'Maipurean',
            ],
            note: 'Brukes for urfolks språk fra regionen som ikke har en mer presis kode',
            related: [],
            children: [
              {
                id: '2JSC',
                label: 'Karibiske språk',
                originalLabel: 'Karibiske språk',
                alternativeLabels: [],
                note: 'Her: kari‘nja, karibisk',
                related: [],
                children: [],
              },
              {
                id: '2JSG',
                label: 'Tupianske- og guaraníspråk',
                originalLabel: 'Tupianske- og guaraníspråk',
                alternativeLabels: ['guarani'],
                note: 'Her: simba, avá, mbya',
                related: [],
                children: [],
              },
              {
                id: '2JSQ',
                label: 'Quechuaspråk',
                originalLabel: 'Quechuaspråk',
                alternativeLabels: [
                  'Kechua',
                  'Runa Simi',
                  'Quechua Wáywash',
                  'Chawpi Qichwa',
                  'Kichwa',
                  'Inga Kichwa Huaylay',
                ],
                note: 'Tilsvarer ISO 639-2 que',
                related: [],
                children: [],
              },
              {
                id: '2JSR',
                label: 'Aymara',
                originalLabel: 'Aymara',
                alternativeLabels: [],
                note: 'Tilsvarer ISO 639-2 aym',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: '2P',
        label: 'Oseaniske- og austronesiske språk',
        originalLabel: 'Oseaniske- og austronesiske språk',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '2PB',
            label: 'Australske (aboriginer) språk',
            originalLabel: 'Australske (aboriginer) språk',
            alternativeLabels: [],
            note: 'Her: murrinh-patha, pama–nyungan, yolŋu matha, warlpiri, tiwi, aranda (arunta), murngin, pitjantjatjara, alyawarr, warumungu, pintupi, arrernte, pertame, luritja, yankunytjatjara og andre australske aboriginerspråk',
            related: [],
            children: [],
          },
          {
            id: '2PC',
            label: 'Papuanske språk',
            originalLabel: 'Papuanske språk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2PCS',
                label: 'Susuami',
                originalLabel: 'Susuami',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '2PG',
            label: 'Austronesiske språk (Malayo-polynesiske språk)',
            originalLabel: 'Austronesiske språk (Malayo-polynesiske språk)',
            alternativeLabels: [
              'Bugis',
              'Achinese',
              'Chamorro',
              'Ilonggo',
              'Hiri Motu',
              'Ilocano',
              'Iloko',
              'Land Dayak',
              'Bikol',
              'Buginese',
              'Chamic',
              'Manobo',
              'Pangasinan',
              'Kapampangan',
              'Tetum',
              'Bicolano',
            ],
            note: 'Her: filippinske språk, papuanske språk. Brukes for austronesiske og malayo-polynesiske språk som ikke har en mer presis kode',
            related: [],
            children: [
              {
                id: '2PGB',
                label: 'Formosansk (Taiwansk)',
                originalLabel: 'Formosansk (Taiwansk)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2PGG',
                label: 'Gassisk (Madagassisk)',
                originalLabel: 'Gassisk (Madagassisk)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2PGJ',
                label: 'Filipino (Tagalog)',
                originalLabel: 'Filipino (Tagalog)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2PGK',
                label: 'Bisaya / Visayan',
                originalLabel: 'Bisaya / Visayan',
                alternativeLabels: [],
                note: 'Her: cebuano, hiligaynon, waray, banton, asi',
                related: [],
                children: [],
              },
              {
                id: '2PGN',
                label: 'Indonesiske språk',
                originalLabel: 'Indonesiske språk',
                alternativeLabels: [],
                note: 'Her: batak, buginesisk, maduresisk, minangkabau, sundanesiske språk. Brukes for: indonesiske språk som ikke har en mer presis kode',
                related: [],
                children: [
                  {
                    id: '2PGNA',
                    label: 'Bahasa Indonesia',
                    originalLabel: 'Bahasa Indonesia',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2PGNC',
                    label: 'Balinesisk',
                    originalLabel: 'Balinesisk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2PGND',
                    label: 'Javanesisk',
                    originalLabel: 'Javanesisk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2PGNM',
                    label: 'Bahasa Malaysia (Malayisk)',
                    originalLabel: 'Bahasa Malaysia (Malayisk)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2PGP',
                label: 'Oseaniske- og polynesiske språk',
                originalLabel: 'Oseaniske- og polynesiske språk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '2PGPA',
                    label: 'Maori / te reo Māori',
                    originalLabel: 'Maori / te reo Māori',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2PGPF',
                    label: 'Fijiansk',
                    originalLabel: 'Fijiansk',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-2 fij',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2PGPG',
                    label: 'Tongansk',
                    originalLabel: 'Tongansk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2PGPH',
                    label: 'Tahitisk',
                    originalLabel: 'Tahitisk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2PGPR',
                    label: 'Cook Islands maori (Rarotongan)',
                    originalLabel: 'Cook Islands maori (Rarotongan)',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-2 rar',
                    related: [],
                    children: [],
                  },
                  {
                    id: '2PGPS',
                    label: 'Samoansk',
                    originalLabel: 'Samoansk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2PGPW',
                    label: 'Hawaiisk',
                    originalLabel: 'Hawaiisk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '2PGPX',
                    label: 'Øvrige oseaniske språk',
                    originalLabel: 'Øvrige oseaniske språk',
                    alternativeLabels: [],
                    note: 'Her: søroseaniske språk fra Ny-Caledonia, Vanuatu, Gilbertese, Kiribati, Tolai. Brukes for oseaniske språk som ikke har en mer presis kode',
                    related: [],
                    children: [
                      {
                        id: '2PGPXK',
                        label: 'Mokilesisk',
                        originalLabel: 'Mokilesisk',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2PGPXM',
                        label: 'Marshallesisk',
                        originalLabel: 'Marshallesisk',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2PGPXN',
                        label: 'Ponapisk',
                        originalLabel: 'Ponapisk',
                        alternativeLabels: [],
                        note: 'Tilsvarer ISO 639-2 pon',
                        related: [],
                        children: [],
                      },
                      {
                        id: '2PGPXP',
                        label: 'Palauisk',
                        originalLabel: 'Palauisk',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '2PGPXT',
                        label: 'Tokelauisk',
                        originalLabel: 'Tokelauisk',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: '2S',
        label: 'Tegnspråk',
        originalLabel: 'Tegnspråk',
        alternativeLabels: [],
        note: 'Brukes med kvalifikatorer for språk eller geografi der det er hensiktsmessig',
        related: [],
        children: [],
      },
      {
        id: '2Z',
        label: 'Øvrige språk',
        originalLabel: 'Øvrige språk',
        alternativeLabels: [],
        note: 'Brukes for språk som ikke har en egen kode og som heller ikke passer inn i noen andre grupperinger',
        related: [],
        children: [
          {
            id: '2ZB',
            label: 'Baskisk',
            originalLabel: 'Baskisk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '2ZC',
            label: 'Kaukasiske språk',
            originalLabel: 'Kaukasiske språk',
            alternativeLabels: [],
            note: 'Her: abazgi, abkhazisk, abaza, adygisk, andisk, avarisk, tsjetsjensk, tsjerkessisk, kabardinsk. Brukes for kaukasiske språk som ikke har en mer presis kode',
            related: [],
            children: [
              {
                id: '2ZCG',
                label: 'Georgisk',
                originalLabel: 'Georgisk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '2ZM',
            label: 'Sumerisk',
            originalLabel: 'Sumerisk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '2ZP',
            label: 'Pidgin- og kreolspråk',
            originalLabel: 'Pidgin- og kreolspråk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '2ZPE',
                label: 'Engelskbaserte kreolspråk',
                originalLabel: 'Engelskbaserte kreolspråk',
                alternativeLabels: [
                  'Manglish',
                  'Bislama',
                  'Hawaiian Pidgin',
                  'Liberian Kreyol',
                  'Krio',
                  'Jamaican Patois',
                  'Singlish',
                  'pijin',
                ],
                note: 'Brukes for kreolske språk som har sitt utspring i engelsk',
                related: [],
                children: [],
              },
              {
                id: '2ZPF',
                label: 'Franskbaserte kreolspråk',
                originalLabel: 'Franskbaserte kreolspråk',
                alternativeLabels: [
                  'Kreyol, Kwéyòl, Patois',
                  'Antillean Creole',
                  'Louisiana Creole',
                  'Tay',
                ],
                note: 'Brukes for kreolske språk som har sitt utspring i fransk',
                related: [],
                children: [
                  {
                    id: '2ZPFH',
                    label: 'Haitisk / Haitisk kreol',
                    originalLabel: 'Haitisk / Haitisk kreol',
                    alternativeLabels: [],
                    note: 'Tilsvarer ISO 639-2 hat',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '2ZPH',
                label: 'Saramaccansk',
                originalLabel: 'Saramaccansk',
                alternativeLabels: [],
                note: 'Tilsvarer ISO 639-3 srm',
                related: [],
                children: [],
              },
              {
                id: '2ZPJ',
                label: 'Sranan, surinamesisk',
                originalLabel: 'Sranan, surinamesisk',
                alternativeLabels: ['sranan tongo', 'taki-taki'],
                note: 'Tilsvarer ISO 639-2 srn',
                related: [],
                children: [],
              },
              {
                id: '2ZPP',
                label: 'Portugisiskbaserte kreolspråk',
                originalLabel: 'Portugisiskbaserte kreolspråk',
                alternativeLabels: [],
                note: 'Brukes for kreolske språk som har sitt utspring i portugisisk',
                related: [],
                children: [],
              },
              {
                id: '2ZPQ',
                label: 'Papiamento',
                originalLabel: 'Papiamento',
                alternativeLabels: [],
                note: 'Tilsvarer ISO 639-2 pap',
                related: [],
                children: [],
              },
              {
                id: '2ZPS',
                label: 'Spanskbaserte kreolspråk',
                originalLabel: 'Spanskbaserte kreolspråk',
                alternativeLabels: ['chavacano', 'palenquero'],
                note: 'Brukes for kreolske språk som har sitt utspring i spansk',
                related: [],
                children: [],
              },
              {
                id: '2ZPT',
                label: 'Tok pisin (Pidginengelsk)',
                originalLabel: 'Tok pisin (Pidginengelsk)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '2ZS',
            label: 'Argot / Hemmelige språk / Skjulespråk',
            originalLabel: 'Argot / Hemmelige språk / Skjulespråk',
            alternativeLabels: [],
            note: 'Her: kryptolekter etc. Brukes for bøker om språk som er skapt av en lukket gruppe for at utenforstående ikke skal forstå det som blir sagt',
            related: [],
            children: [],
          },
          {
            id: '2ZX',
            label: 'Konstruerte språk',
            originalLabel: 'Konstruerte språk',
            alternativeLabels: [],
            note: 'Her: klingon, dothraki, alvespråk, sindarin. Brukes for bøker om konstruerte eller kunstige språk',
            related: [],
            children: [
              {
                id: '2ZXA',
                label: 'Afrihili',
                originalLabel: 'Afrihili',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2ZXC',
                label: 'Interlingue (Occidental)',
                originalLabel: 'Interlingue (Occidental)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2ZXP',
                label: 'Esperanto',
                originalLabel: 'Esperanto',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '2ZXT',
                label: 'Interlingua',
                originalLabel: 'Interlingua',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: '3',
    label: 'Kvalifikatorer: tidsperiode',
    originalLabel: 'Kvalifikatorer: tidsperiode',
    alternativeLabels: [],
    note: 'Bruk kvalifikatorer for tidsperiode MED passende emnekoder for å angi tidsperiode, f.eks. den perioden som er omtalt i ei kunstbok (A*), historiebok (N*), en biografi (DN*), historisk skjønnlitteratur (FV*), historiebøker for barn (YNH*) etc. Nasjonale utvidelser (dvs. på formatet 3*-xx-, hvor xx angir en landkode) skal brukes for navngitte historiske perioder som er viktige i et gitt marked, men som ikke nødvendigvis passer inn i den generelle strukturen for tidsperioder. Nasjonale utvidelser kan brukes av alle og er ikke eksklusive for brukere i det angitte landet. Kvalifikatorer for tidsperiode brukes ofte sammen med kvalifikatorer for geografi. IKKE bruk kvalifikatorer for tidsperiode for å angi utgivelsesår for ei årbok, et vegatlas eller ei reisehåndbok, eller for å angi originalt utgivelsesår (dette beskrives i andre metadata). IKKE bruk koden ‘3’ alene, men velg spesifikke kvalifikatorer fra 3*',
    related: [],
    children: [
      {
        id: '3A',
        label: 'Geologisk tidsregning',
        originalLabel: 'Geologisk tidsregning',
        alternativeLabels: ['geologisk tidsskala'],

        related: [],
        children: [
          {
            id: '3AC',
            label: 'Kenozoikum',
            originalLabel: 'Kenozoikum',
            alternativeLabels: [],
            note: 'Her: jordas nytid (fra 66 millioner år tilbake og fram til nå), paleocen, eocen og oligocen (utgjør til sammen den paleogene perioden); pliocen og miocen (utgjør til sammen den neogene perioden)',
            related: [],
            children: [
              {
                id: '3ACF',
                label: 'Kvartær',
                originalLabel: 'Kvartær',
                alternativeLabels: [],
                note: 'Her: perioden fra 11 700 år siden til i dag',
                related: [],
                children: [
                  {
                    id: '3ACFB',
                    label: 'Pleistocen',
                    originalLabel: 'Pleistocen',
                    alternativeLabels: [],
                    note: 'Her: de store istidene',
                    related: [],
                    children: [],
                  },
                  {
                    id: '3ACFD',
                    label: 'Holocen',
                    originalLabel: 'Holocen',
                    alternativeLabels: [],
                    note: 'Her: mellomistiden, antropocen',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '3AM',
            label: 'Mesozoikum',
            originalLabel: 'Mesozoikum',
            alternativeLabels: [],
            note: 'Her: jordas mellomalder (fra 252 til 66 millioner år siden), trias, jura, kritt',
            related: [],
            children: [],
          },
          {
            id: '3AP',
            label: 'Paleozoikum',
            originalLabel: 'Paleozoikum',
            alternativeLabels: [],
            note: 'Her: jordas oldtid (fra 541 til 252 millioner år siden), kambrium, ordovicium, silur, devon, karbon, perm',
            related: [],
            children: [],
          },
          {
            id: '3AR',
            label: 'Proterozoikum',
            originalLabel: 'Proterozoikum',
            alternativeLabels: [],
            note: 'Her: prekambrium (fra 2500 til 541 millioner år siden)',
            related: [],
            children: [],
          },
          {
            id: '3AT',
            label: 'Arkeikum og hadeikum',
            originalLabel: 'Arkeikum og hadeikum',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: '3B',
        label: 'Forhistorisk tid',
        originalLabel: 'Forhistorisk tid',
        alternativeLabels: [],
        note: 'Bruk 3B*-koder for ulike tidsperioder avhengig av geografisk område. 3B*-koder er ikke knyttet til spesifikke datoer, siden disse kan variere ut fra geografisk område og kultur',
        related: [],
        children: [
          {
            id: '3B-AA-E',
            label:
              '5500 til 2700 f.Kr. (førdynastisk og tidlig dynastisk tid i Egypt)',
            originalLabel:
              '5500 til 2700 f.Kr. (førdynastisk og tidlig dynastisk tid i Egypt)',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '3BD',
            label: 'Steinalderen',
            originalLabel: 'Steinalderen',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '3BDF',
                label: 'Steinalderen: paleolittisk tid',
                originalLabel: 'Steinalderen: paleolittisk tid',
                alternativeLabels: [],
                note: 'Brukes for perioden mellom ca. 2,6 millioner år før nåtid og frem til siste istid (I Norge fram til ca. 8000 år f. Kr.)',
                related: [],
                children: [],
              },
              {
                id: '3BD-JP-J',
                label: '16.500 til 1000 f.Kr. (den japanske Jomon-perioden)',
                originalLabel:
                  '16.500 til 1000 f.Kr. (den japanske Jomon-perioden)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3BDK',
                label: 'Steinalderen: mesolittisk tid',
                originalLabel: 'Steinalderen: mesolittisk tid',
                alternativeLabels: [],
                note: 'Mellomsteinalderen (I Norge ca. 8000 - 4000 f.Kr)',
                related: [],
                children: [],
              },
              {
                id: '3BDQ',
                label: 'Steinalderen: neolittisk tid',
                originalLabel: 'Steinalderen: neolittisk tid',
                alternativeLabels: [],
                note: 'Yngre steinalder (I Norge ca. 4000 - 1800 f.Kr.)',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '3BL',
            label: 'Bronsealderen',
            originalLabel: 'Bronsealderen',
            alternativeLabels: [],
            note: 'Brukes for kobberalderen, tidlig, midterste og sen bronsealder',
            related: [],
            children: [],
          },
          {
            id: '3BR',
            label: 'Jernalderen',
            originalLabel: 'Jernalderen',
            alternativeLabels: [],
            note: 'Brukes for eldre og yngre jernalder',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: '3C',
        label: 'F.Kr.- Protohistorie',
        originalLabel: 'F.Kr.- Protohistorie',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '3CA',
            label: '50 000 til 20 000 f.Kr.',
            originalLabel: '50 000 til 20 000 f.Kr.',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '3C-AA-E',
            label: '2686 f.Kr. til 323 f.Kr. (oldtidens Egypt)',
            originalLabel: '2686 f.Kr. til 323 f.Kr. (oldtidens Egypt)',
            alternativeLabels: [],
            note: 'Use for: periods of the Old, Middle and New Kingdoms and Intermediate periods',
            related: [],
            children: [],
          },
          {
            id: '3CB',
            label: '20 000 til 10 000 f.Kr.',
            originalLabel: '20 000 til 10 000 f.Kr.',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '3CC',
            label: '10 000 til 4000 f.Kr.',
            originalLabel: '10 000 til 4000 f.Kr.',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '3CD',
            label: '4000 til 3000 f.Kr.',
            originalLabel: '4000 til 3000 f.Kr.',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '3CDA',
                label: '4000 til 3500 f.Kr.',
                originalLabel: '4000 til 3500 f.Kr.',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3CDB',
                label: '3500 til 3000 f.Kr.',
                originalLabel: '3500 til 3000 f.Kr.',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '3CG',
            label: '3000 til 2000 f.Kr.',
            originalLabel: '3000 til 2000 f.Kr.',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '3CGA',
                label: '3000 til 2500 f.Kr.',
                originalLabel: '3000 til 2500 f.Kr.',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3CGB',
                label: '2500 til 2000 f.Kr.',
                originalLabel: '2500 til 2000 f.Kr.',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '3CJ',
            label: '2000 til 1000 f.Kr.',
            originalLabel: '2000 til 1000 f.Kr.',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '3CJA',
                label: '2000 til 1500 f.Kr.',
                originalLabel: '2000 til 1500 f.Kr.',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3CJB',
                label: '1500 til 1000 f.Kr.',
                originalLabel: '1500 til 1000 f.Kr.',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '3C-KR-G',
            label: '2000 f.Kr. til 300 f.Kr. (oldtidens Korea)',
            originalLabel: '2000 f.Kr. til 300 f.Kr. (oldtidens Korea)',
            alternativeLabels: [],
            note: 'Her: Gojoseonperioden, De tidlige tre kongedømmer',
            related: [],
            children: [],
          },
          {
            id: '3CT',
            label: '1000 f.Kr. til år 0',
            originalLabel: '1000 f.Kr. til år 0',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '3CTA',
                label: '1000 til 500 f.Kr.',
                originalLabel: '1000 til 500 f.Kr.',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3CTB',
                label: '500 til 1 f.Kr.',
                originalLabel: '500 til 1 f.Kr.',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3CTBA',
                    label: '499 til 400 f.Kr.',
                    originalLabel: '499 til 400 f.Kr.',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3CTBB',
                    label: '399 til 300 f.Kr.',
                    originalLabel: '399 til 300 f.Kr.',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3CTBC',
                    label: '299 til 200 f.Kr.',
                    originalLabel: '299 til 200 f.Kr.',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3CTBD',
                    label: '199 til 100 f.Kr.',
                    originalLabel: '199 til 100 f.Kr.',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3CTBE',
                    label: '99 til 1 f.Kr.',
                    originalLabel: '99 til 1 f.Kr.',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '3CT-DE-A',
                label:
                  '800 f.Kr. til 500 e.Kr. (oldtiden / antikken - Tyskland, Sentral- og Øst-Europa)',
                originalLabel:
                  '800 f.Kr. til 500 e.Kr. (oldtiden / antikken - Tyskland, Sentral- og Øst-Europa)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3CT-ES-A',
                label: 'Før 200 f.Kr. (antikken i Spania)',
                originalLabel: 'Før 200 f.Kr. (antikken i Spania)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3CT-ES-B',
                label: '200 f.Kr. til 400 e.Kr. (Romerriket i Spania)',
                originalLabel: '200 f.Kr. til 400 e.Kr. (Romerriket i Spania)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3CT-IT-A',
                label: '1000 til 500 f.Kr. (antikken i Italia)',
                originalLabel: '1000 til 500 f.Kr. (antikken i Italia)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3CT-JP-Y',
                label: '1000 f.Kr. til 300 e.Kr. (den japanske Yayoi-perioden)',
                originalLabel:
                  '1000 f.Kr. til 300 e.Kr. (den japanske Yayoi-perioden)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: '3K',
        label: '1 til 1500 e.Kr.',
        originalLabel: '1 til 1500 e.Kr.',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '3KB',
            label: '1 til 500 e.Kr.',
            originalLabel: '1 til 500 e.Kr.',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '3KB-AA-E',
                label:
                  '332 f.Kr. til 630 e.Kr. (gresk-romersk periode i Egypt)',
                originalLabel:
                  '332 f.Kr. til 630 e.Kr. (gresk-romersk periode i Egypt)',
                alternativeLabels: [],
                note: 'Class here: Ptolemaic period',
                related: [],
                children: [],
              },
              {
                id: '3KBF',
                label: '1. århundre, 1 til 99',
                originalLabel: '1. århundre, 1 til 99',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KB-GB-A',
                label: '43 f.Kr. til 410 e.Kr. (Romersk Britannia)',
                originalLabel: '43 f.Kr. til 410 e.Kr. (Romersk Britannia)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KB-JP-K',
                label: '300 e.Kr. til 591 e.Kr. (den japanske Kofun-perioden)',
                originalLabel:
                  '300 e.Kr. til 591 e.Kr. (den japanske Kofun-perioden)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KBK',
                label: '2. århundre, 100 til 199',
                originalLabel: '2. århundre, 100 til 199',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KB-KR-B',
                label:
                  '57 f.Kr. til 668 f.Kr. (De tidlige tre kongedømmer i Korea)',
                originalLabel:
                  '57 f.Kr. til 668 f.Kr. (De tidlige tre kongedømmer i Korea)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KBN',
                label: '3. århundre, 200 til 299',
                originalLabel: '3. århundre, 200 til 299',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KBW',
                label: '4. århundre, 300 til 399',
                originalLabel: '4. århundre, 300 til 399',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KBY',
                label: '5. århundre, 400 til 499',
                originalLabel: '5. århundre, 400 til 499',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '3K-ES-A',
            label: '400 til 1492 (middelalderen i Spania)',
            originalLabel: '400 til 1492 (middelalderen i Spania)',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '3K-ES-B',
            label: '711–1492 (arabisk styre i Spania)',
            originalLabel: '711–1492 (arabisk styre i Spania)',
            alternativeLabels: [],
            note: 'Brukes for Al-Andalus, umajjadene, taifa, almoravidene',
            related: [],
            children: [],
          },
          {
            id: '3K-ES-C',
            label: '711–1492 (reconquista-perioden i Spania)',
            originalLabel: '711–1492 (reconquista-perioden i Spania)',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '3KH',
            label: '500 til 1000 e.Kr.',
            originalLabel: '500 til 1000 e.Kr.',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '3KH-AA-E',
                label: '630 til 1517 (middelalderen i Egypt)',
                originalLabel: '630 til 1517 (middelalderen i Egypt)',
                alternativeLabels: [],
                note: 'Use for: the periods of the Umayyad, Abbasid, Fatimid, Ayyubid and Mamluk dynasties',
                related: [],
                children: [],
              },
              {
                id: '3KH-DK-H',
                label: '800 til 1050 (vikingtiden i Danmark)',
                originalLabel: '800 til 1050 (vikingtiden i Danmark)',
                alternativeLabels: [],
                note: 'Foretrekk 3KH-NO-V for vikingtiden',
                related: [],
                children: [],
              },
              {
                id: '3KH-ES-A',
                label:
                  '400 til 600 (germanske og visigotiske invasjoner i Spania)',
                originalLabel:
                  '400 til 600 (germanske og visigotiske invasjoner i Spania)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KHF',
                label: '6. århundre, 500 til 599',
                originalLabel: '6. århundre, 500 til 599',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3KHFB',
                    label: '500 til 550',
                    originalLabel: '500 til 550',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KHFQ',
                    label: '550 til 599',
                    originalLabel: '550 til 599',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '3KH-GB-B',
                label: '400 til 1066 (den angelsaksiske periode)',
                originalLabel: '400 til 1066 (den angelsaksiske periode)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KH-IE-S',
                label: '400 til 800 (tidlig middelalder i Irland)',
                originalLabel: '400 til 800 (tidlig middelalder i Irland)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KH-IE-V',
                label: '800 til 1014 (vikingtiden i Irland)',
                originalLabel: '800 til 1014 (vikingtiden i Irland)',
                alternativeLabels: [],
                note: 'Foretrekk 3KH-NO-V for vikingtiden',
                related: [],
                children: [],
              },
              {
                id: '3KH-IT-C',
                label:
                  '500 til 1000 (germanske og frankiske kongedømmer i Italia)',
                originalLabel:
                  '500 til 1000 (germanske og frankiske kongedømmer i Italia)',
                alternativeLabels: [],
                note: 'Brukes for tidlig middelalder i Italia, den perioden som etterfølger Vestromerrikets fall, perioden hvor germanske og slaviske grupper bosetter seg på den italienska halvøya; Longobardene, gotiske og visigotiske kongedømmer fram til Frankerriket. Brukes med andre kvalifikatorer for tid og og sted der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: '3KH-JP-H',
                label:
                  '785 til 1068 (den japanske tidlige og midtre Heian-perioden)',
                originalLabel:
                  '785 til 1068 (den japanske tidlige og midtre Heian-perioden)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KHK',
                label: '7. århundre, 600 til 699',
                originalLabel: '7. århundre, 600 til 699',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3KHKB',
                    label: '600 til 650',
                    originalLabel: '600 til 650',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KHK-JP-A',
                    label:
                      '592 e.Kr. til 710 e.Kr. (den japanske Asuka-perioden)',
                    originalLabel:
                      '592 e.Kr. til 710 e.Kr. (den japanske Asuka-perioden)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KHKQ',
                    label: '650 til 699',
                    originalLabel: '650 til 699',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '3KH-KR-H',
                label: '668 til 935 (Det forente Silla i Korea)',
                originalLabel: '668 til 935 (Det forente Silla i Korea)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KH-KR-N',
                label: '698 til 926 (Balhae-perioden i Korea)',
                originalLabel: '698 til 926 (Balhae-perioden i Korea)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KHN',
                label: '8. århundre, 700 til 799',
                originalLabel: '8. århundre, 700 til 799',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3KHNB',
                    label: '700 til 750',
                    originalLabel: '700 til 750',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KHN-JP-N',
                    label: '710 til 784 (den japanske Nara- perioden)',
                    originalLabel: '710 til 784 (den japanske Nara- perioden)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KHNQ',
                    label: '750 til 799',
                    originalLabel: '750 til 799',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '3KH-NO-V',
                label: '800 til 1050 (vikingtiden)',
                originalLabel: '800 til 1050 (vikingtiden)',
                alternativeLabels: ['vikingtida', 'vikinger'],
                note: 'Brukes med 1QBDB eller andre kvalifikatorer for sted for å angi at boka handler om vikingtiden på et spesifikt sted',
                related: [],
                children: [],
              },
              {
                id: '3KH-SE-H',
                label: '800 til 1050 (vikingtiden i Sverige)',
                originalLabel: '800 til 1050 (vikingtiden i Sverige)',
                alternativeLabels: [],
                note: 'Foretrekk 3KH-NO-V for vikingtiden',
                related: [],
                children: [],
              },
              {
                id: '3KHW',
                label: '9. århundre, 800 til 899',
                originalLabel: '9. århundre, 800 til 899',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3KHWB',
                    label: '800 til 850',
                    originalLabel: '800 til 850',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KHWQ',
                    label: '850 til 899',
                    originalLabel: '850 til 899',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '3KHY',
                label: '10. århundre, 900 til 999',
                originalLabel: '10. århundre, 900 til 999',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3KHYB',
                    label: '900 til 950',
                    originalLabel: '900 til 950',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KHYQ',
                    label: '950 til 999',
                    originalLabel: '950 til 999',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '3K-IT-B',
            label:
              '500 f.Kr. til 1500 e.Kr. (antikken og middelalderen i Italia)',
            originalLabel:
              '500 f.Kr. til 1500 e.Kr. (antikken og middelalderen i Italia)',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '3KL',
            label: '1000 til 1500',
            originalLabel: '1000 til 1500',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '3KLF',
                label: '11. århundre, 1000 til 1099',
                originalLabel: '11. århundre, 1000 til 1099',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3KLFB',
                    label: '1000 til 1050',
                    originalLabel: '1000 til 1050',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KLFQ',
                    label: '1050 til 1099',
                    originalLabel: '1050 til 1099',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '3KL-GB-C',
                label: '1066–1154 (den normanniske perioden i England)',
                originalLabel: '1066–1154 (den normanniske perioden i England)',
                alternativeLabels: [],
                note: 'Brukes for normannernes invasjon og erobring av England, hertugdømmet Normandie',
                related: [],
                children: [],
              },
              {
                id: '3KL-GB-D',
                label: '1154–1485 (Plantagenetperioden og Angevin)',
                originalLabel: '1154–1485 (Plantagenetperioden og Angevin)',
                alternativeLabels: ['Huset Plantagenet'],
                note: 'Brukes for middelalderen i England',
                related: [],
                children: [],
              },
              {
                id: '3KL-IE-N',
                label:
                  '1169 til 1350 (normannisk periode og middelalderen i Irland)',
                originalLabel:
                  '1169 til 1350 (normannisk periode og middelalderen i Irland)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KL-IT-D',
                label: '1000 til 1400 (Italia: ‘Signoria’)',
                originalLabel: '1000 til 1400 (Italia: ‘Signoria’)',
                alternativeLabels: [],
                note: 'Her: høymiddelalderen i Italia. Brukes med andre kvalifikatorer for tid og sted der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: '3KLK',
                label: '12. århundre, 1100 til 1199',
                originalLabel: '12. århundre, 1100 til 1199',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3KLKB',
                    label: '1100 til 1150',
                    originalLabel: '1100 til 1150',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KLK-JP-H',
                    label: '1068 til 1185 (den japanske senere Heian-perioden)',
                    originalLabel:
                      '1068 til 1185 (den japanske senere Heian-perioden)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KLKQ',
                    label: '1150 til 1199',
                    originalLabel: '1150 til 1199',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '3KL-KR-N',
                label: '918 til 1392 (Goryeo-perioden i Korea)',
                originalLabel: '918 til 1392 (Goryeo-perioden i Korea)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KLN',
                label: '13. århundre, 1200 til 1299',
                originalLabel: '13. århundre, 1200 til 1299',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3KLNB',
                    label: '1200 til 1250',
                    originalLabel: '1200 til 1250',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KLN-GB-E',
                    label:
                      '1200 til 1283 (høymiddelalder i Wales og den engelske erobringen av Wales)',
                    originalLabel:
                      '1200 til 1283 (høymiddelalder i Wales og den engelske erobringen av Wales)',
                    alternativeLabels: [],
                    note: 'Use for: the period of the last native princes of Wales and Edwardian annexation of the Welsh principalities',
                    related: [],
                    children: [],
                  },
                  {
                    id: '3KLN-JP-K',
                    label: '1185 til 1333 (den japanske Kamakura-perioden)',
                    originalLabel:
                      '1185 til 1333 (den japanske Kamakura-perioden)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KLNQ',
                    label: '1250 til 1299',
                    originalLabel: '1250 til 1299',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '3KLNQ-BE-B',
                        label: '1297–1305 (den fransk-flamske krigen)',
                        originalLabel: '1297–1305 (den fransk-flamske krigen)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '3KL-PL-A',
                label: '960–1370 (Piast-dynastiet i Polen)',
                originalLabel: '960–1370 (Piast-dynastiet i Polen)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KL-SE-J',
                label: '1050-1520 (middelalderen i Sverige)',
                originalLabel: '1050-1520 (middelalderen i Sverige)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3KLW',
                label: '14. århundre, 1300 til 1399',
                originalLabel: '14. århundre, 1300 til 1399',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3KLWB',
                    label: '1300 til 1350',
                    originalLabel: '1300 til 1350',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KLW-JP-C',
                    label: '1333 til 1392 (den japanske Nanboku-chō-perioden)',
                    originalLabel:
                      '1333 til 1392 (den japanske Nanboku-chō-perioden)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KLWQ',
                    label: '1350 til 1399',
                    originalLabel: '1350 til 1399',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '3KLY',
                label: '15. århundre, 1400 til 1499',
                originalLabel: '15. århundre, 1400 til 1499',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3KLYB',
                    label: '1400 til 1450',
                    originalLabel: '1400 til 1450',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KLY-GB-F',
                    label: '1455 til 1487 (Rosekrigene)',
                    originalLabel: '1455 til 1487 (Rosekrigene)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KLY-IT-E',
                    label: '1400 til 1499 (renessansen i Italia)',
                    originalLabel: '1400 til 1499 (renessansen i Italia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KLY-JP-M',
                    label: '1392 til 1573 (den japanske Muromachi-perioden)',
                    originalLabel:
                      '1392 til 1573 (den japanske Muromachi-perioden)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KLY-PL-A',
                    label: '1386–1572 (det jagellonske dynasti i Polen)',
                    originalLabel:
                      '1386–1572 (det jagellonske dynasti i Polen)',
                    alternativeLabels: ['Huset Jagello'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3KLYQ',
                    label: '1450 til 1499',
                    originalLabel: '1450 til 1499',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: '3M',
        label: '1500 til i dag',
        originalLabel: '1500 til i dag',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '3M-AA-E',
            label: '1517 til 1914 (tidlige moderne Egypt)',
            originalLabel: '1517 til 1914 (tidlige moderne Egypt)',
            alternativeLabels: [],
            note: 'Her: perioder med ottomansk styre, fransk okkupasjon, Muhammad Ali-dynastiet, Khedivatet Egypt',
            related: [],
            children: [],
          },
          {
            id: '3MD',
            label: '16. århundre, 1500 til 1599',
            originalLabel: '16. århundre, 1500 til 1599',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '3MDB',
                label: 'Tidlig 16. århundre, 1500 til 1550',
                originalLabel: 'Tidlig 16. århundre, 1500 til 1550',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3MDBA',
                    label: '1500 til 1509',
                    originalLabel: '1500 til 1509',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MDBF',
                    label: '1510 til 1519',
                    originalLabel: '1510 til 1519',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MDBH',
                    label: '1520 til 1529',
                    originalLabel: '1520 til 1529',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MDBJ',
                    label: '1530 til 1539',
                    originalLabel: '1530 til 1539',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MDBL',
                    label: '1540 til 1549',
                    originalLabel: '1540 til 1549',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '3MD-CA-A',
                label: '1534–1763 (Ny-Frankrike)',
                originalLabel: '1534–1763 (Ny-Frankrike)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3MD-GB-G',
                label: '1485–1603 (Tudortiden)',
                originalLabel: '1485–1603 (Tudortiden)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3MD-GB-GE',
                    label: '1558–1603 (Elisabethansk tid)',
                    originalLabel: '1558–1603 (Elisabethansk tid)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '3MD-IE-P',
                label: '1556–1663 (Irlands bosetting)',
                originalLabel: '1556–1663 (Irlands bosetting)',
                alternativeLabels: [],
                note: 'Her: engelske bosettinger og konfiskering av irske eiendommer',
                related: [],
                children: [],
              },
              {
                id: '3MD-IT-N',
                label:
                  '1494 til 1559 (de italienske kriger, italiensk høyrenessanse)',
                originalLabel:
                  '1494 til 1559 (de italienske kriger, italiensk høyrenessanse)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3MDQ',
                label: 'Sent 16. århundre, 1550 til 1599',
                originalLabel: 'Sent 16. århundre, 1550 til 1599',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3MDQ-JP-A',
                    label: '1573 til 1600 (den japanske Momoyama-perioden)',
                    originalLabel:
                      '1573 til 1600 (den japanske Momoyama-perioden)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MDQM',
                    label: '1550 til 1559',
                    originalLabel: '1550 til 1559',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MDQ-NL-D',
                    label: '1566–1648 (Åttiårskrigen)',
                    originalLabel: '1566–1648 (Åttiårskrigen)',
                    alternativeLabels: [],
                    note: 'Her: de nederlandske uavhengighetskrigene, den nederlandsk-portugisiske krigen, oppstanden i de søtten provinsene',
                    related: [],
                    children: [],
                  },
                  {
                    id: '3MDQS',
                    label: '1560 til 1569',
                    originalLabel: '1560 til 1569',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MDQV',
                    label: '1570 til 1579',
                    originalLabel: '1570 til 1579',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MDQX',
                    label: '1580 til 1589',
                    originalLabel: '1580 til 1589',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MDQZ',
                    label: '1590 til 1599',
                    originalLabel: '1590 til 1599',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '3MD-SE-L',
                label: '520-1611 (vasatiden i Sverige)',
                originalLabel: '520-1611 (vasatiden i Sverige)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '3M-ES-A',
            label: '1492–1808 (tidlig spansk moderne historie)',
            originalLabel: '1492–1808 (tidlig spansk moderne historie)',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '3M-ES-AB',
                label: '1492–1690 (spansk gullalder)',
                originalLabel: '1492–1690 (spansk gullalder)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '3MG',
            label: '17. århundre, 1600 til 1699',
            originalLabel: '17. århundre, 1600 til 1699',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '3MGB',
                label: 'Tidlig 17. århundre, 1600 til 1650',
                originalLabel: 'Tidlig 17. århundre, 1600 til 1650',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3MGBA',
                    label: '1600 til 1609',
                    originalLabel: '1600 til 1609',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MGB-DE-B',
                    label: '1618 til 1648 (trettiårskrigen)',
                    originalLabel: '1618 til 1648 (trettiårskrigen)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MGBF',
                    label: '1610 til 1619',
                    originalLabel: '1610 til 1619',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MGB-GB-H',
                    label: '1603–1649 (den tidlige stuarttiden)',
                    originalLabel: '1603–1649 (den tidlige stuarttiden)',
                    alternativeLabels: [],
                    note: 'Her: Karl Is regjeringstid',
                    related: [],
                    children: [],
                  },
                  {
                    id: '3MGBH',
                    label: '1620 til 1629',
                    originalLabel: '1620 til 1629',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MGBJ',
                    label: '1630 til 1639',
                    originalLabel: '1630 til 1639',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MGBL',
                    label: '1640 til 1649',
                    originalLabel: '1640 til 1649',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '3MG-IT-G',
                label: '1559–1714 (spansk dominans i Italia)',
                originalLabel: '1559–1714 (spansk dominans i Italia)',
                alternativeLabels: [],
                note: 'Brukes for perioden med spansk habsburgstyre i Italia',
                related: [],
                children: [],
              },
              {
                id: '3MG-PL-A',
                label: '1569–1795 (samveldet Polen-Litauen)',
                originalLabel: '1569–1795 (samveldet Polen-Litauen)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3MGQ',
                label: 'Sent 17. århundre, 1650 til 1699',
                originalLabel: 'Sent 17. århundre, 1650 til 1699',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3MGQM',
                    label: '1650 til 1659',
                    originalLabel: '1650 til 1659',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '3MGQM-GB-J',
                        label:
                          '1639 til 1660 (britiske borgerkriger og interregnum)',
                        originalLabel:
                          '1639 til 1660 (britiske borgerkriger og interregnum)',
                        alternativeLabels: [],
                        note: 'Brukes for de tre kongedømmers krig, Det engelske samveldet',
                        related: [],
                        children: [],
                      },
                      {
                        id: '3MGQM-IE-C',
                        label: '1649–1653 (Cromwells erobring av Irland)',
                        originalLabel:
                          '1649–1653 (Cromwells erobring av Irland)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '3MGQS',
                    label: '1660 til 1669',
                    originalLabel: '1660 til 1669',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '3MGQS-GB-K',
                        label:
                          '1660–1714 (Restaurasjonen og Den senere stuarttiden)',
                        originalLabel:
                          '1660–1714 (Restaurasjonen og Den senere stuarttiden)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '3MGQV',
                    label: '1670 til 1679',
                    originalLabel: '1670 til 1679',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MGQX',
                    label: '1680 til 1689',
                    originalLabel: '1680 til 1689',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '3MGQX-GB-M',
                        label:
                          '1688–1689 (Vilhelm IIIs regjeringstid og de britiske tronfølgerkrigene)',
                        originalLabel:
                          '1688–1689 (Vilhelm IIIs regjeringstid og de britiske tronfølgerkrigene)',
                        alternativeLabels: [],
                        note: 'Brukes for jakobittopprørene i 1689, Vilhelmskrigen i Irland, Den ærerike revolusjon',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '3MGQZ',
                    label: '1690 til 1699',
                    originalLabel: '1690 til 1699',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '3MG-SE-N',
                label: '1611-1718 (stormaktstiden i Sverige)',
                originalLabel: '1611-1718 (stormaktstiden i Sverige)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3MG-US-A',
                label:
                  '1600 til 1775 (europeisk kolonisering og nybyggertid i Nord-Amerika)',
                originalLabel:
                  '1600 til 1775 (europeisk kolonisering og nybyggertid i Nord-Amerika)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '3M-JP-E',
            label: '1600 til 1867 (den japanske edo- perioden)',
            originalLabel: '1600 til 1867 (den japanske edo- perioden)',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '3M-KR-T',
            label: '1392 til 1910 (Joseondynastiet i Korea)',
            originalLabel: '1392 til 1910 (Joseondynastiet i Korea)',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '3ML',
            label: '18. århundre, 1700 til 1799',
            originalLabel: '18. århundre, 1700 til 1799',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '3MLB',
                label: 'Tidlig 18. århundre, 1700 til 1750',
                originalLabel: 'Tidlig 18. århundre, 1700 til 1750',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3MLBA',
                    label: '1700 til 1709',
                    originalLabel: '1700 til 1709',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MLBF',
                    label: '1710 til 1719',
                    originalLabel: '1710 til 1719',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MLB-GB-J',
                    label: '1700 til 1760 (jakobittperioden)',
                    originalLabel: '1700 til 1760 (jakobittperioden)',
                    alternativeLabels: [],
                    note: 'Brukes for jakobittopprørene i 1715, 1719 og 1745, slaget ved Culloden og The early Highland Clearances',
                    related: [],
                    children: [],
                  },
                  {
                    id: '3MLBH',
                    label: '1720 til 1729',
                    originalLabel: '1720 til 1729',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MLBJ',
                    label: '1730 til 1739',
                    originalLabel: '1730 til 1739',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MLBL',
                    label: '1740 til 1749',
                    originalLabel: '1740 til 1749',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '3ML-DE-B',
                label: '1648–1779 (enevelde og opplysningstid i Tyskland)',
                originalLabel:
                  '1648–1779 (enevelde og opplysningstid i Tyskland)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3ML-GB-P',
                label: '1714–1837 (Georgiansk tid)',
                originalLabel: '1714–1837 (Georgiansk tid)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3ML-GB-PR',
                    label: '1811 til 1820 (Regency-perioden)',
                    originalLabel: '1811 til 1820 (Regency-perioden)',
                    alternativeLabels: [],
                    note: 'Brukes med FR*koder for skjønnlitterære bøker i romantikksjangeren som er lagt til denne tidsperioden. Se også 6RE <a href="/thema/nb/6RE">Regency-perioden</a>',
                    related: [
                      {
                        id: '6RE',
                        label: 'Regency-perioden',
                        originalLabel: 'Regency-perioden',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: '3ML-IT-H',
                label: '1701–1796 (østerriksk dominans i Italia)',
                originalLabel: '1701–1796 (østerriksk dominans i Italia)',
                alternativeLabels: [],
                note: 'Brukes for perioden med østerriksk habsburgstyre i Italia',
                related: [],
                children: [],
              },
              {
                id: '3MLQ',
                label: 'Sent 18. århundre, 1750 til 1799',
                originalLabel: 'Sent 18. århundre, 1750 til 1799',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3MLQM',
                    label: '1750 til 1759',
                    originalLabel: '1750 til 1759',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MLQ-PL-A',
                    label: '1772–1795 (Polen-Litauens tre delinger)',
                    originalLabel: '1772–1795 (Polen-Litauens tre delinger)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MLQS',
                    label: '1760 til 1769',
                    originalLabel: '1760 til 1769',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MLQ-SE-R',
                    label: '1772-1809 (gustaviansk tid i Sverige)',
                    originalLabel: '1772-1809 (gustaviansk tid i Sverige)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MLQ-US-B',
                    label: '1775–1783 (Den amerikanske revolusjonen)',
                    originalLabel: '1775–1783 (Den amerikanske revolusjonen)',
                    alternativeLabels: [],
                    note: 'Brukes for Den amerikanske uavhengighetskrigen',
                    related: [],
                    children: [],
                  },
                  {
                    id: '3MLQ-US-C',
                    label: '1783 til 1800 (føderalistene)',
                    originalLabel: '1783 til 1800 (føderalistene)',
                    alternativeLabels: [],
                    note: 'Her: konføderasjonen, ‘New Nation’',
                    related: [],
                    children: [],
                  },
                  {
                    id: '3MLQV',
                    label: '1770 til 1779',
                    originalLabel: '1770 til 1779',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MLQX',
                    label: '1780 til 1789',
                    originalLabel: '1780 til 1789',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MLQZ',
                    label: '1790 til 1799',
                    originalLabel: '1790 til 1799',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '3MLQZ-BE-B',
                        label:
                          '1789–1791 (den brabantiske revolusjon og revolusjonen i Liège)',
                        originalLabel:
                          '1789–1791 (den brabantiske revolusjon og revolusjonen i Liège)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MLQZ-FR-A',
                        label: '1789 til 1799 (Den franske revolusjon)',
                        originalLabel: '1789 til 1799 (Den franske revolusjon)',
                        alternativeLabels: [],
                        note: 'Brukes for Den første franske republikk, Skrekkveldet, Direktoriet',
                        related: [],
                        children: [],
                      },
                      {
                        id: '3MLQZ-IE-R',
                        label: '1798 (Det irske opprøret av 1798)',
                        originalLabel: '1798 (Det irske opprøret av 1798)',
                        alternativeLabels: [],
                        note: 'Brukes for den franske invasjonen i 1796',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '3ML-SE-Q',
                label: '1718-1772 (Sverige: frihetstiden)',
                originalLabel: '1718-1772 (Sverige: frihetstiden)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '3MN',
            label: '19. århundre, 1800 til 1899',
            originalLabel: '19. århundre, 1800 til 1899',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '3MNB',
                label: 'Tidlig 19. århundre, 1800 til 1850',
                originalLabel: 'Tidlig 19. århundre, 1800 til 1850',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3MNBA',
                    label: '1800 til 1809',
                    originalLabel: '1800 til 1809',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNB-BE-C',
                    label: '1814–1830 (Det forente kongeriket Nederlandene)',
                    originalLabel:
                      '1814–1830 (Det forente kongeriket Nederlandene)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNB-DE-C',
                    label: '1780–1848 (dei europeiske revolusjonene)',
                    originalLabel: '1780–1848 (dei europeiske revolusjonene)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNB-ES-A',
                    label: '1808-1813 (Den spanske uavhengighetskrigen)',
                    originalLabel:
                      '1808-1813 (Den spanske uavhengighetskrigen)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNBF',
                    label: '1810 til 1819',
                    originalLabel: '1810 til 1819',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNB-GB-T',
                    label:
                      '1760 til 1840 (Den industrielle revolusjon i Storbritannia)',
                    originalLabel:
                      '1760 til 1840 (Den industrielle revolusjon i Storbritannia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNBH',
                    label: '1820 til 1829',
                    originalLabel: '1820 til 1829',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNB-IT-M',
                    label: '1796–1848 (napoleonstiden, Italias samling)',
                    originalLabel:
                      '1796–1848 (napoleonstiden, Italias samling)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNBJ',
                    label: '1830 til 1839',
                    originalLabel: '1830 til 1839',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '3MNBJ-BE-D',
                        label:
                          '1830–1831 (den belgiske revolusjonen og uavhengighetstiden)',
                        originalLabel:
                          '1830–1831 (den belgiske revolusjonen og uavhengighetstiden)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MNBJ-CA-D',
                        label:
                          '1837–1838 (Lower Canada Rebellion / Patriots’ War)',
                        originalLabel:
                          '1837–1838 (Lower Canada Rebellion / Patriots’ War)',
                        alternativeLabels: [],
                        note: 'Her: Upper Canada Rebellion',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '3MNBL',
                    label: '1840 til 1849',
                    originalLabel: '1840 til 1849',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '3MNBL-IE-F',
                        label: '1845–1852 (den irske hungersnauda)',
                        originalLabel: '1845–1852 (den irske hungersnauda)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '3MNB-US-D',
                    label:
                      '1800 til 1861 (Nord-Amerika: utforskning og utvidelse)',
                    originalLabel:
                      '1800 til 1861 (Nord-Amerika: utforskning og utvidelse)',
                    alternativeLabels: [],
                    note: 'Her: de jeffersonske og jacksonske periodene, antebellumperioden',
                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNB-XA-R',
                    label:
                      '1808 til 1833 (de spansk-amerikanske uavhengighetskrigene)',
                    originalLabel:
                      '1808 til 1833 (de spansk-amerikanske uavhengighetskrigene)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '3MN-DK-G',
                label: '1800 til 1850 (dansk gullalder)',
                originalLabel: '1800 til 1850 (dansk gullalder)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3MN-ES-A',
                label: '1808–2000 (spansk samtidshistorie)',
                originalLabel: '1808–2000 (spansk samtidshistorie)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3MN-FI-A',
                label: '1809–1917 (finsk selvstendighet)',
                originalLabel: '1809–1917 (finsk selvstendighet)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3MN-PA-A',
                label: '1821–1903 (union mellom Colombia og Panama)',
                originalLabel: '1821–1903 (union mellom Colombia og Panama)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3MNQ',
                label: 'Sent 19. århundre, 1850 til 1899',
                originalLabel: 'Sent 19. århundre, 1850 til 1899',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3MNQ-AR-D',
                    label:
                      '1880-1916 (Generation of ’80 og ‘República Conservadora’ i Argentina)',
                    originalLabel:
                      '1880-1916 (Generation of ’80 og ‘República Conservadora’ i Argentina)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNQ-GB-V',
                    label: '1837–1901 (Viktoriatiden)',
                    originalLabel: '1837–1901 (Viktoriatiden)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNQ-IE-G',
                    label: '1850 til 1916 (gælisk renessanse i Irland)',
                    originalLabel: '1850 til 1916 (gælisk renessanse i Irland)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNQ-IT-N',
                    label:
                      '1850–1861 (nasjonal uavhengighet og samling i Italia)',
                    originalLabel:
                      '1850–1861 (nasjonal uavhengighet og samling i Italia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNQ-IT-P',
                    label:
                      '1861–1900 (nasjonal samling og de første tiårene til Kongedømmet Italia)',
                    originalLabel:
                      '1861–1900 (nasjonal samling og de første tiårene til Kongedømmet Italia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNQ-JP-M',
                    label: '1868 til 1912 (den japanske Meiji-perioden)',
                    originalLabel:
                      '1868 til 1912 (den japanske Meiji-perioden)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNQM',
                    label: '1850 til 1859',
                    originalLabel: '1850 til 1859',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNQ-MX-P',
                    label: '1876 to 1910 (Porfiriato-perioden i Mexico)',
                    originalLabel:
                      '1876 to 1910 (Porfiriato-perioden i Mexico)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNQ-NL-B',
                    label: '1871 til 1914 (Belle Époque i Nederland)',
                    originalLabel: '1871 til 1914 (Belle Époque i Nederland)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNQS',
                    label: '1860 til 1869',
                    originalLabel: '1860 til 1869',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNQ-US-E',
                    label:
                      '1861–1877 (Den amerikanske borgerkrigen og rekonstruksjonsperioden)',
                    originalLabel:
                      '1861–1877 (Den amerikanske borgerkrigen og rekonstruksjonsperioden)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNQ-US-F',
                    label: '1877 til 1893 (gullalderen i USA)',
                    originalLabel: '1877 til 1893 (gullalderen i USA)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNQV',
                    label: '1870 til 1879',
                    originalLabel: '1870 til 1879',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNQX',
                    label: '1880 til 1889',
                    originalLabel: '1880 til 1889',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MNQZ',
                    label: '1890 til 1899',
                    originalLabel: '1890 til 1899',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '3MNQZ-DE-D',
                        label: '1890–1914 (Tyskland: imperialisme)',
                        originalLabel: '1890–1914 (Tyskland: imperialisme)',
                        alternativeLabels: [],
                        note: 'Her: det tyske keiserriket',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: '3MP',
            label: '20. århundre, 1900 til 1999',
            originalLabel: '20. århundre, 1900 til 1999',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '3MP-AA-E',
                label: '1882 til d.d. (Moderne Egypt)',
                originalLabel: '1882 til d.d. (Moderne Egypt)',
                alternativeLabels: [],
                note: 'Her: britisk protektorat, Kongedømmet Egypt, Den arabiske republikk Egypt',
                related: [],
                children: [],
              },
              {
                id: '3MPB',
                label: 'Tidlig 20. århundre, 1900 til 1950',
                originalLabel: 'Tidlig 20. århundre, 1900 til 1950',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3MPBA',
                    label: '1900 til 1910',
                    originalLabel: '1900 til 1910',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPB-AR-B',
                    label:
                      '1916-1930 (Saenz Peña og de første demokratiske regjeringene i Argentina)',
                    originalLabel:
                      '1916-1930 (Saenz Peña og de første demokratiske regjeringene i Argentina)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPB-ES-A',
                    label: '1900 til 1931 (avskaffelsen av monarkiet i Spania)',
                    originalLabel:
                      '1900 til 1931 (avskaffelsen av monarkiet i Spania)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPBF',
                    label: '1910 til 1919',
                    originalLabel: '1910 til 1919',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '3MPBFB',
                        label: '1914-1918 (Første verdenskrig)',
                        originalLabel: '1914-1918 (Første verdenskrig)',
                        alternativeLabels: [],
                        note: 'Brukes for den perioden som dekker alle de ulike konfliktene, militære felttog, okkupasjoner, invasjoner, grusomheter, krigsforbrytelser og andre hendelser som forbindes med perioden, selv om tidsperioden kan være ulik fra land til land',
                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPBF-FI-A',
                        label: '1918 (den finske borgerkrigen)',
                        originalLabel: '1918 (den finske borgerkrigen)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPBF-IE-R',
                        label: '1916–1922 (den irske revolusjonstiden)',
                        originalLabel: '1916–1922 (den irske revolusjonstiden)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPBF-MX-R',
                        label: '1910 til 1917 (meksikanske revolusjoner)',
                        originalLabel:
                          '1910 til 1917 (meksikanske revolusjoner)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPBF-RU-R',
                        label: '1917–1923 (russiske revolusjoner)',
                        originalLabel: '1917–1923 (russiske revolusjoner)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '3MPB-FI-A',
                    label: '1899–1917 (russifiseringen av Finland)',
                    originalLabel: '1899–1917 (russifiseringen av Finland)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPBG',
                    label: '1919 til 1939 (mellomkrigstiden)',
                    originalLabel: '1919 til 1939 (mellomkrigstiden)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '3MPBG-DE-G',
                        label: '1918–1933 (Weimarrepublikken)',
                        originalLabel: '1918–1933 (Weimarrepublikken)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPBGH',
                        label: '1920 til 1929',
                        originalLabel: '1920 til 1929',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '3MPBGH-ES-A',
                            label:
                              '1923–1930 (Primo de Riveras diktatur i Spania)',
                            originalLabel:
                              '1923–1930 (Primo de Riveras diktatur i Spania)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '3MPBGH-IE-C',
                            label: '1922–1923 (Den irske borgerkrigen)',
                            originalLabel: '1922–1923 (Den irske borgerkrigen)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '3MPBGH-US-J',
                            label: '1919–1929 (den amerikanske jazztiden)',
                            originalLabel:
                              '1919–1929 (den amerikanske jazztiden)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '3MPBG-IE-S',
                        label: '1922–1937 (Den irske fristaten)',
                        originalLabel: '1922–1937 (Den irske fristaten)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPBG-IT-S',
                        label:
                          '1918–1943 (mellomkrigstiden og fascismen i Italia)',
                        originalLabel:
                          '1918–1943 (mellomkrigstiden og fascismen i Italia)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPBGJ',
                        label: '1930 til 1939',
                        originalLabel: '1930 til 1939',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '3MPBGJ-AR-A',
                            label: '1930–1943 (Década infame i Argentina)',
                            originalLabel:
                              '1930–1943 (Década infame i Argentina)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '3MPBGJ-DE-H',
                            label:
                              '1933–1945 (den nasjonalsosialistiske perioden i Tyskland)',
                            originalLabel:
                              '1933–1945 (den nasjonalsosialistiske perioden i Tyskland)',
                            alternativeLabels: ['Nazitiden'],

                            related: [],
                            children: [],
                          },
                          {
                            id: '3MPBGJ-ES-A',
                            label: '1931–1936 (Den andre spanske republikken)',
                            originalLabel:
                              '1931–1936 (Den andre spanske republikken)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '3MPBGJ-ES-B',
                            label: '1936–1939 (den spanske borgerkrigen)',
                            originalLabel:
                              '1936–1939 (den spanske borgerkrigen)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '3MPBGJ-US-K',
                            label: '1929–1939 (depresjonstiden i USA)',
                            originalLabel: '1929–1939 (depresjonstiden i USA)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: '3MPB-IT-R',
                    label: '1900–1914 (Giolitti-perioden i Italia)',
                    originalLabel: '1900–1914 (Giolitti-perioden i Italia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPB-JP-B',
                    label: '1912 til 1926 (den japanske Taisho-perioden)',
                    originalLabel:
                      '1912 til 1926 (den japanske Taisho-perioden)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPB-JP-D',
                    label: '1926 til 1945 (den japanske Showa-perioden)',
                    originalLabel:
                      '1926 til 1945 (den japanske Showa-perioden)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPB-KR-S',
                    label: '1910 to 1945 (japansk kolonitid i Korea)',
                    originalLabel: '1910 to 1945 (japansk kolonitid i Korea)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPBL',
                    label: '1940 til 1949',
                    originalLabel: '1940 til 1949',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '3MPBL-AR-C',
                        label:
                          '1943–1955 (peronismen og Peróns presidentperioder i Argentina)',
                        originalLabel:
                          '1943–1955 (peronismen og Peróns presidentperioder i Argentina)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPBLB',
                        label: '1938-1946 (Andre verdenskrig)',
                        originalLabel: '1938-1946 (Andre verdenskrig)',
                        alternativeLabels: [],
                        note: 'Brukes for den perioden som dekker alle de ulike konfliktene, militære felttog, okkupasjoner, invasjoner, grusomheter, krigsforbrytelser og andre hendelser som forbindes med perioden, selv om tidsperioden kan være ulik fra land til land',
                        related: [],
                        children: [
                          {
                            id: '3MPBLB-FI-A',
                            label:
                              '1939-1944 (Den finske vinterkrigen og Den finske fortsettelseskrigen)',
                            originalLabel:
                              '1939-1944 (Den finske vinterkrigen og Den finske fortsettelseskrigen)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '3MPBLB-PL-A',
                            label: '1943 (opprøret i Warszawagettoen)',
                            originalLabel: '1943 (opprøret i Warszawagettoen)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '3MPBLB-PL-B',
                            label: '1944 (Warszawaoppstanden)',
                            originalLabel: '1944 (Warszawaoppstanden)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '3MPBL-BE-F',
                        label:
                          '1940–1951 (okkupasjonstiden og det ‘kongelige spørsmål’ i Belgia)',
                        originalLabel:
                          '1940–1951 (okkupasjonstiden og det ‘kongelige spørsmål’ i Belgia)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPBL-DK-B',
                        label: '1940–1945 (okkupasjonstiden i Danmark)',
                        originalLabel: '1940–1945 (okkupasjonstiden i Danmark)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPBL-ES-A',
                        label:
                          '1940–1949 (perioden etter den spanske borgerkrigen)',
                        originalLabel:
                          '1940–1949 (perioden etter den spanske borgerkrigen)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPBL-IT-T',
                        label:
                          '1943–1945 (frigjøring fra fascisme og motstandsarbeid i Italia)',
                        originalLabel:
                          '1943–1945 (frigjøring fra fascisme og motstandsarbeid i Italia)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPBL-IT-U',
                        label:
                          '1946–1968 (etterkrigstiden og grunnlegging av den italienske republikken)',
                        originalLabel:
                          '1946–1968 (etterkrigstiden og grunnlegging av den italienske republikken)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '3MPB-US-H',
                    label: '1890 til 1929 (den progressive perioden i USA)',
                    originalLabel:
                      '1890 til 1929 (den progressive perioden i USA)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '3MP-JP-S',
                label: '1926 til 1989 (den japanske showa-perioden)',
                originalLabel: '1926 til 1989 (den japanske showa-perioden)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3MP-PA-B',
                label: '1903–1968 (den republikanske perioden i Panama)',
                originalLabel:
                  '1903–1968 (den republikanske perioden i Panama)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '3MPQ',
                label: 'Sent 20. århundre, 1950 til 1999',
                originalLabel: 'Sent 20. århundre, 1950 til 1999',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3MPQ-DE-J',
                    label: '1945–1990 (den kalde krigen)',
                    originalLabel: '1945–1990 (den kalde krigen)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPQ-ES-A',
                    label: '1940 til 1975 (Francos diktatur i Spania)',
                    originalLabel: '1940 til 1975 (Francos diktatur i Spania)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPQ-ES-B',
                    label: '1975 til 1982 (overgang til demokrati i Spania)',
                    originalLabel:
                      '1975 til 1982 (overgang til demokrati i Spania)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPQ-ES-C',
                    label: '1982 til d.d. (demokrati i Spania)',
                    originalLabel: '1982 til d.d. (demokrati i Spania)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPQ-IE-T',
                    label: '1968–1999 (konflikten i Nord-Irland)',
                    originalLabel: '1968–1999 (konflikten i Nord-Irland)',
                    alternativeLabels: ['The Troubles'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPQ-IT-V',
                    label:
                      '1950–1968 (gjenoppbygging, det økonomiske mirakel, sosiale og politiske endringer i Italia)',
                    originalLabel:
                      '1950–1968 (gjenoppbygging, det økonomiske mirakel, sosiale og politiske endringer i Italia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPQ-JP-B',
                    label:
                      '1945 til 1989 (den japanske etterkrigsperioden Showa)',
                    originalLabel:
                      '1945 til 1989 (den japanske etterkrigsperioden Showa)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPQM',
                    label: '1950 til 1959',
                    originalLabel: '1950 til 1959',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '3MPQM-US-N',
                        label: '1950–1953 (Koreakrigen)',
                        originalLabel: '1950–1953 (Koreakrigen)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '3MPQ-PA-A',
                    label: '1968–1989 (militærdiktatur i Panama)',
                    originalLabel: '1968–1989 (militærdiktatur i Panama)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPQ-PL-A',
                    label: '1947–1989 (Folkerepublikken Polen)',
                    originalLabel: '1947–1989 (Folkerepublikken Polen)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPQS',
                    label: '1960 til 1969',
                    originalLabel: '1960 til 1969',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '3MPQS-CA-Q',
                        label: '1960–1968 (den stille revolusjonen i Quebec)',
                        originalLabel:
                          '1960–1968 (den stille revolusjonen i Quebec)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPQS-DE-K',
                        label: '1968 (Tyskland: protestene i 1968)',
                        originalLabel: '1968 (Tyskland: protestene i 1968)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPQS-US-P',
                        label: '1954 til 1968 (borgerrettsbevegelsen i USA)',
                        originalLabel:
                          '1954 til 1968 (borgerrettsbevegelsen i USA)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPQS-US-Q',
                        label: '1955 til 1975 (Vietnamkrigen)',
                        originalLabel: '1955 til 1975 (Vietnamkrigen)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '3MPQV',
                    label: '1970 til 1979',
                    originalLabel: '1970 til 1979',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '3MPQV-AR-C',
                        label: '1976–1983 (militærdiktatur i Argentina)',
                        originalLabel:
                          '1976–1983 (militærdiktatur i Argentina)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPQV-DE-L',
                        label: '1977 (Den tyske høsten / Deutscher Herbst)',
                        originalLabel:
                          '1977 (Den tyske høsten / Deutscher Herbst)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '3MPQV-IT-W',
                        label:
                          '1969–1980 (protestbevegelser og blyårene i Italia)',
                        originalLabel:
                          '1969–1980 (protestbevegelser og blyårene i Italia)',
                        alternativeLabels: ['Anni di Piombo'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '3MPQX',
                    label: '1980 til 1989',
                    originalLabel: '1980 til 1989',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MPQZ',
                    label: '1990 til 1999',
                    originalLabel: '1990 til 1999',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '3MPQZ-IT-X',
                        label:
                          '1990–1999 (krisene i Italia på 90-tallet og den såkalte andre republikken)',
                        originalLabel:
                          '1990–1999 (krisene i Italia på 90-tallet og den såkalte andre republikken)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '3MP-SE-A',
                label: '1930 til 1965 (Folkhemmet)',
                originalLabel: '1930 til 1965 (Folkhemmet)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '3MR',
            label: '21. århundre, 2000 til 2100',
            originalLabel: '21. århundre, 2000 til 2100',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '3MRB',
                label: 'Tidlig 21. århundre, 2000 til 2050',
                originalLabel: 'Tidlig 21. århundre, 2000 til 2050',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '3MRBA',
                    label: '2000 til 2009',
                    originalLabel: '2000 til 2009',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MRBF',
                    label: '2010 til 2019',
                    originalLabel: '2010 til 2019',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MRBH',
                    label: '2020 til 2029',
                    originalLabel: '2020 til 2029',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MRBJ',
                    label: '2030 til 2039',
                    originalLabel: '2030 til 2039',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MRB-JP-D',
                    label: '1989 til 2019 (den japanske heisei-perioden)',
                    originalLabel:
                      '1989 til 2019 (den japanske heisei-perioden)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MRB-JP-R',
                    label: '2019 og framover (den japanske reiwa- perioden)',
                    originalLabel:
                      '2019 og framover (den japanske reiwa- perioden)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '3MRBL',
                    label: '2040 til 2049',
                    originalLabel: '2040 til 2049',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: '4',
    label: 'Kvalifikatorer: pedagogisk formål',
    originalLabel: 'Kvalifikatorer: pedagogisk formål',
    alternativeLabels: [],
    note: 'Bruk kvalifikatorer for pedagogisk formål MED passende emnekoder. Bruk koder fra 4Z* når de er tilgjengelige for et lands utdanningssystem. Angi alltid en kvalifikator fra 4* dersom du har brukt emnekoder fra YP*. Skal IKKE brukes dersom boka handler om utdanningsinstitusjoner, f.eks. et universitets historie. IKKE bruk koden ‘4’ alene, men velg spesifikke koder fra 4* der det er hensiktsmessig',
    related: [],
    children: [
      {
        id: '4C',
        label: 'For alle utdanningsnivå',
        originalLabel: 'For alle utdanningsnivå',
        alternativeLabels: [],
        note: 'Bruk 4C*-koder for læremidler. Foretrekk nasjonale utvidelser i 4Z* der det er mulig. Brukes med 4Z* og kvalifikator(er) for sted der det er hensiktsmessig',
        related: [],
        children: [
          {
            id: '4CA',
            label: 'Førskoleundervisning',
            originalLabel: 'Førskoleundervisning',
            alternativeLabels: [],
            note: 'Brukes for læremidler ment for opplæring på førskolenivå',
            related: [],
            children: [],
          },
          {
            id: '4CD',
            label: 'Småskoletrinnet',
            originalLabel: 'Småskoletrinnet',
            alternativeLabels: ['barneskolen', 'småskolen', 'grunnskolen'],
            note: 'Brukes for læremidler som er ment for undervisning på barneskolens 1.-4. trinn (eller tilsvarende)',
            related: [],
            children: [],
          },
          {
            id: '4CF',
            label: 'Mellomtrinnet',
            originalLabel: 'Mellomtrinnet',
            alternativeLabels: ['barneskolen', 'grunnskolen'],
            note: 'Brukes for læremidler som er ment for undervisning på barneskolens 5.-7. trinn (eller tilsvarende)',
            related: [],
            children: [],
          },
          {
            id: '4CL',
            label: 'Ungdomstrinnet',
            originalLabel: 'Ungdomstrinnet',
            alternativeLabels: ['grunnskolen'],
            note: 'Brukers for læremidler som er ment for undervisning på ungdomsskolens 8.-10. trinn',
            related: [],
            children: [],
          },
          {
            id: '4CN',
            label: 'Videregående skole',
            originalLabel: 'Videregående skole',
            alternativeLabels: [],
            note: 'Brukes for læremidler ment for undervisning på videregående skole (studieforberedende utdanningsprogram)',
            related: [],
            children: [],
          },
          {
            id: '4CP',
            label: 'Yrkesfaglig utdanning',
            originalLabel: 'Yrkesfaglig utdanning',
            alternativeLabels: [],
            note: 'Brukes for læremidler som brukes ved utdanning av lærlinger (yrkesfaglig utdanningsprogram). Brukes med 4CT, 4CL eller 4CN der det er hensiktsmessig, men foretrekk nasjonale utvidelser i 4Z* der det er mulig. Brukes med 4Z* og kvalifikator(er) for geografi der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: '4CPC',
                label: 'Yrkesfaglig sertifisering',
                originalLabel: 'Yrkesfaglig sertifisering',
                alternativeLabels: [],
                note: 'Her: svenneprøve, mesterbrev. Brukes med 4TN for samlinger av eksamensoppgaver etc.',
                related: [],
                children: [],
              },
              {
                id: '4CPF',
                label:
                  'Kvalifisering og eksamener for offisielle myndigheter / embetsverket',
                originalLabel:
                  'Kvalifisering og eksamener for offisielle myndigheter / embetsverket',
                alternativeLabels: [],
                note: 'Brukes for materiell som hører til kurs, eksamener eller sertifisering som kreves for at man skulle jobbe for en stat, regionale myndigheter eller transnasjonale offisielle organisasjoner',
                related: [],
                children: [],
              },
              {
                id: '4CPG',
                label: 'Tester for bruk i rekrutteringsøyemed',
                originalLabel: 'Tester for bruk i rekrutteringsøyemed',
                alternativeLabels: [],
                note: 'Her: evnetester, ferdighetstester, personlighetstester, verbale eller ikke-verbale resonnementer, testing av analytiske og logiske evner. Brukes for bøker utgitt som en del av en rekrutteringsprosess, både for offentlige institusjoner og det private næringsliv etc.',
                related: [],
                children: [],
              },
              {
                id: '4CPK',
                label: 'Tester om allmennkunnskap eller kultur',
                originalLabel: 'Tester om allmennkunnskap eller kultur',
                alternativeLabels: [],
                note: 'Brukes for bøker ment for å forberede seg til eksamener eller tester som skal teste kandidatens allmennkunnskap eller kjennskap til en kultur',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '4CQ',
            label: 'Forkurs til høyere utdanning',
            originalLabel: 'Forkurs til høyere utdanning',
            alternativeLabels: [],
            note: 'Brukes for bøker som er ment spesielt for bruk på forkurs til høyere utdanning, og som kan brukes til å fylle på med kunnskap som er et krav for å starte på høyere utdanning',
            related: [],
            children: [],
          },
          {
            id: '4CT',
            label: 'Høyere utdanning / høgskole- og universitetsutdanning',
            originalLabel:
              'Høyere utdanning / høgskole- og universitetsutdanning',
            alternativeLabels: [],
            note: 'Brukes for titler utgitt for høgskole eller universitet, men foretrekk nasjonale utvidelser i 4Z* dersom materialet er ment for bruk kun i tilknytning til et gitt nasjonalt pensum . Brukes med 4Z* og kvalifikator(er) for geografi der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: '4CTB',
                label: 'Lavere akademiske grader',
                originalLabel: 'Lavere akademiske grader',
                alternativeLabels: [],
                note: 'Brukes for bøker ment for bachelor-studenter eller tilsvarende, men foretrekk nasjonale utvidelser i 4Z* dersom materialet er ment for bruk kun i tilknytning til et gitt nasjonalt pensum. Brukes med 4Z*-koder og kvalifikator(er) for geografi der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: '4CTM',
                label: 'Høyere akademiske grader',
                originalLabel: 'Høyere akademiske grader',
                alternativeLabels: [],
                note: 'Brukes for bøker ment for master-studenter eller tilsvarende, men foretrekk nasjonale utvidelser i 4Z* dersom materialet er ment for bruk kun i tilknytning til et gitt nasjonalt pensum. Brukes med 4Z*-koder og kvalifikator(er) for geografi der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '4CX',
            label: 'Voksenopplæring',
            originalLabel: 'Voksenopplæring',
            alternativeLabels: [],
            note: 'Brukes for læremidler ment for voksenopplæring gjennom et eget utdanningsprogram eller egen læreplan. Foretrekk andre koder for utdanning på universitetsnivå. Se også 4TY <a href="/thema/nb/4TY">For selvstudium</a>',
            related: [
              {
                id: '4TY',
                label: 'For selvstudium',
                originalLabel: 'For selvstudium',
                note: 'Brukes for læremidler ment for selvstudium, for å lære en ny ferdighet eller et emne, eller materiale som kan brukes som et supplement til andre læremidler. Se også 4CX <a href="/thema/nb/4CX">Voksenopplæring</a>',
              },
            ],
            children: [
              {
                id: '4CXB',
                label: 'Voksenopplæring: begynnernivå',
                originalLabel: 'Voksenopplæring: begynnernivå',
                alternativeLabels: [],
                note: 'Brukes for læremidler på begynnernivå, ment for personer med lite eller ingen kunnskap om emnet, og ment for bruk gjennom et eget utdanningsprogram eller egen læreplan',
                related: [],
                children: [],
              },
              {
                id: '4CXF',
                label: 'Voksenopplæring: mellomnivå',
                originalLabel: 'Voksenopplæring: mellomnivå',
                alternativeLabels: [],
                note: 'Brukes for læremidler ment for personer med noe kunnskap om emnet, og ment for bruk gjennom et eget utdanningsprogram eller egen læreplan',
                related: [],
                children: [],
              },
              {
                id: '4CXL',
                label: 'Voksenopplæring: videregående nivå',
                originalLabel: 'Voksenopplæring: videregående nivå',
                alternativeLabels: [],
                note: 'Brukes for læremidler ment for personer med god kunnskap om emnet, og ment for bruk gjennom et eget utdanningsprogram eller egen læreplan',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: '4G',
        label: 'For internasjonale læreplaner og eksamener',
        originalLabel: 'For internasjonale læreplaner og eksamener',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '4GA',
            label:
              'For International Baccalaureate (IB) pre-diploma programmer',
            originalLabel:
              'For International Baccalaureate (IB) pre-diploma programmer',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4GAJ',
                label:
                  'For International Baccalaureate (IB) Early Years programmer',
                originalLabel:
                  'For International Baccalaureate (IB) Early Years programmer',
                alternativeLabels: [],
                note: 'Her: Primary Years programmer',
                related: [],
                children: [],
              },
              {
                id: '4GAS',
                label:
                  'For International Baccalaureate (IB) Middle Years programmer',
                originalLabel:
                  'For International Baccalaureate (IB) Middle Years programmer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '4GB',
            label: 'For International Baccalaureate (IB) Diploma',
            originalLabel: 'For International Baccalaureate (IB) Diploma',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '4GC',
            label:
              'For International Baccalaureate (IB) karriere-relaterte sertifikat',
            originalLabel:
              'For International Baccalaureate (IB) karriere-relaterte sertifikat',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '4GH',
            label: 'For International GCSE (IGCSE)',
            originalLabel: 'For International GCSE (IGCSE)',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '4GJ',
            label: 'For International AS / A level',
            originalLabel: 'For International AS / A level',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: '4L',
        label: 'Språkopplæring: kurs og eksamener',
        originalLabel: 'Språkopplæring: kurs og eksamener',
        alternativeLabels: [],
        note: 'Bruk 4L* for læremidler for opplæring i et andrespråk eller fremmedspråk, for læremidler ment personer som har et annet morsmål enn det som læremiddelet dekker. Brukes med kvalifikator(er) for språk der det er hensiktsmessig',
        related: [],
        children: [
          {
            id: '4LB',
            label: 'Tester og eksamener i språkferdigheter',
            originalLabel: 'Tester og eksamener i språkferdigheter',
            alternativeLabels: [],
            note: 'Brukes med kvalifikator(er) for språk der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: '4LC',
            label: 'For TELC språktester',
            originalLabel: 'For TELC språktester',
            alternativeLabels: [],
            note: 'Her: European Language Certificates',
            related: [],
            children: [],
          },
          {
            id: '4LE',
            label: 'Engelsk språkopplæring: eksamener og sertifikater',
            originalLabel: 'Engelsk språkopplæring: eksamener og sertifikater',
            alternativeLabels: [],
            note: 'Her: ELT, ESL, EAL, ELL, EFL. Brukes for læremidler ment for personer som har engelsk som andre- eller fremmedspråk, eller læremidler ment for den som skal undervise i engelsk til personer som ikke har engelsk som morsmål. Se også 4LZ <a href="/thema/nb/4LZ">For spesifikk språkopplæring unntatt engelsk språk</a>',
            related: [
              {
                id: '4LZ',
                label: 'For spesifikk språkopplæring unntatt engelsk språk',
                originalLabel:
                  'For spesifikk språkopplæring unntatt engelsk språk',
                note: 'Brukes med kvalifikatorer for språk der det er hensiktsmessig. Se også 4LE <a href="/thema/nb/4LE">Engelsk språkopplæring: eksamener og sertifikater</a>',
              },
            ],
            children: [
              {
                id: '4LEA',
                label: 'Cambridge English eksamener',
                originalLabel: 'Cambridge English eksamener',
                alternativeLabels: [],
                note: 'Her: YLE, KET, PET, FCE, CAE, CPE',
                related: [],
                children: [],
              },
              {
                id: '4LEC',
                label: 'Internasjonal engelsk språktest (IELTS)',
                originalLabel: 'Internasjonal engelsk språktest (IELTS)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4LEF',
                label: 'Test for engelsk som fremmedspråk',
                originalLabel: 'Test for engelsk som fremmedspråk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4LEH',
                label:
                  'Test av English for International Communication (TOEIC)',
                originalLabel:
                  'Test av English for International Communication (TOEIC)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4LEP',
                label:
                  'ELT: eksamener og prøver i engelsk språkopplæring for spesielle formål',
                originalLabel:
                  'ELT: eksamener og prøver i engelsk språkopplæring for spesielle formål',
                alternativeLabels: [],
                note: 'Her: BULATS, BEC, ICFE, ILEC, TOLES',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '4LZ',
            label: 'For spesifikk språkopplæring unntatt engelsk språk',
            originalLabel: 'For spesifikk språkopplæring unntatt engelsk språk',
            alternativeLabels: [],
            note: 'Brukes med kvalifikatorer for språk der det er hensiktsmessig. Se også 4LE <a href="/thema/nb/4LE">Engelsk språkopplæring: eksamener og sertifikater</a>',
            related: [
              {
                id: '4LE',
                label: 'Engelsk språkopplæring: eksamener og sertifikater',
                originalLabel:
                  'Engelsk språkopplæring: eksamener og sertifikater',
                note: 'Her: ELT, ESL, EAL, ELL, EFL. Brukes for læremidler ment for personer som har engelsk som andre- eller fremmedspråk, eller læremidler ment for den som skal undervise i engelsk til personer som ikke har engelsk som morsmål. Se også 4LZ <a href="/thema/nb/4LZ">For spesifikk språkopplæring unntatt engelsk språk</a>',
              },
            ],
            children: [
              {
                id: '4LZ-ES-D',
                label: 'Spansk som fremmedspråk eller andrespråk',
                originalLabel: 'Spansk som fremmedspråk eller andrespråk',
                alternativeLabels: [],
                note: 'Her: DELE, ELSE, CELU, ELE',
                related: [],
                children: [],
              },
              {
                id: '4LZ-FR-D',
                label: 'Fransk som fremmedspråk eller andrespråk',
                originalLabel: 'Fransk som fremmedspråk eller andrespråk',
                alternativeLabels: [],
                note: 'Her: DELF / DALF / DILF / TCF / TEF. Brukes for bøker som brukes til å forberede seg til prøver for fransk som fremmedspråk',
                related: [],
                children: [],
              },
              {
                id: '4LZ-IT-H',
                label: 'Sertifisering av språkopplæring i italiensk',
                originalLabel: 'Sertifisering av språkopplæring i italiensk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4LZ-IT-HB',
                    label: 'For språkopplæring i italiensk: CELI Certificate',
                    originalLabel:
                      'For språkopplæring i italiensk: CELI Certificate',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4LZ-IT-HD',
                    label: 'For språkopplæring i italiensk: CIC Certificate',
                    originalLabel:
                      'For språkopplæring i italiensk: CIC Certificate',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4LZ-IT-HF',
                    label: 'For språkopplæring i italiensk: PLIDA Certificate',
                    originalLabel:
                      'For språkopplæring i italiensk: PLIDA Certificate',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4LZ-IT-HH',
                    label: 'For språkopplæring i italiensk: CILS Certificate',
                    originalLabel:
                      'For språkopplæring i italiensk: CILS Certificate',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4LZ-KR-K',
                label: 'For tester og eksamener i koreansk',
                originalLabel: 'For tester og eksamener i koreansk',
                alternativeLabels: [],
                note: 'Her: TOPIK, KLAT',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: '4T',
        label: 'For spesifikke utdanningsformål',
        originalLabel: 'For spesifikke utdanningsformål',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: '4TC',
            label: 'Lærebøker, kursmateriell',
            originalLabel: 'Lærebøker, kursmateriell',
            alternativeLabels: [],
            note: 'Brukes for å angi at bøker er lærebøker eller kursmateriell, vanligvis ment for elever eller studenter. Brukes med 4Z*-koder der det er hensiktsmessig, eller andre kvalifikatorer fra 4* for å angi utdanningsnivå',
            related: [],
            children: [],
          },
          {
            id: '4TM',
            label: 'Repetisjonshefter og studieveiledninger',
            originalLabel: 'Repetisjonshefter og studieveiledninger',
            alternativeLabels: [],
            note: 'Brukes for supplerende materiale som f.eks. oppsummeringer av tekster eller emner som er ment brukt av studenter til å repetere et spesifikt emne eller en tekst, og som vanligvis er knyttet til et pensum',
            related: [],
            children: [],
          },
          {
            id: '4TN',
            label: 'Eksamener / prøver / tester',
            originalLabel: 'Eksamener / prøver / tester',
            alternativeLabels: [],
            note: 'Her: gamle eksamensoppgaver, testeksamener, bøker for å forberede seg til eksamen. Brukes for bøker som er ment brukt som forberedelse til eksamener og prøver, eller som inneholder eksempler på eksamensoppgaver og andre oppgaver',
            related: [],
            children: [
              {
                id: '4TNC',
                label: 'Statsborgerprøve',
                originalLabel: 'Statsborgerprøve',
                alternativeLabels: [],
                note: 'Brukes med kvalifikator for geografi og emnekoder som angir prøvens innhold der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: '4TNH',
                label: 'For opptakseksamener / -prøver',
                originalLabel: 'For opptakseksamener / -prøver',
                alternativeLabels: ['opptaksprøver'],
                note: 'Brukes for bøker som er utgitt i tilknytning til eksamener eller prøver som skal gi adgang til utdanningsinstitusjoner som skoler, universiteter, høgskoler etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '4TP',
            label: 'Content and language integrated learning (CLIL)',
            originalLabel: 'Content and language integrated learning (CLIL)',
            alternativeLabels: [],
            note: 'Brukes for materiale til undervisning i et ikke-språkfag på et fremmedspråk',
            related: [],
            children: [],
          },
          {
            id: '4TQ',
            label: 'For Intercultural bilingual education (IBE)',
            originalLabel: 'For Intercultural bilingual education (IBE)',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '4TR',
            label: 'For flerspråklig eller interkulturell undervisning',
            originalLabel: 'For flerspråklig eller interkulturell undervisning',
            alternativeLabels: [],
            note: 'Brukes for undervisningsmateriale som er ment for å forbedre flerspråklig undervisning eller flerkulturell kompetanse, eller interkulturell eller krysskulturell undervisning',
            related: [],
            children: [],
          },
          {
            id: '4TV',
            label: 'Tilleggsundervisning',
            originalLabel: 'Tilleggsundervisning',
            alternativeLabels: [],
            note: 'Brukes for undervisningsmateriell ment for å veie opp for manglende erfaring hos vanskeligstilte barn (f.eks. kulturell erfaring)',
            related: [],
            children: [],
          },
          {
            id: '4TW',
            label: 'For spesifikke lærevansker',
            originalLabel: 'For spesifikke lærevansker',
            alternativeLabels: [],
            note: 'Brukes for undervisningsmateriell beregnet for differensiert læring og folk med spesifikke lærevansker',
            related: [],
            children: [],
          },
          {
            id: '4TY',
            label: 'For selvstudium',
            originalLabel: 'For selvstudium',
            alternativeLabels: ['autonom læring'],
            note: 'Brukes for læremidler ment for selvstudium, for å lære en ny ferdighet eller et emne, eller materiale som kan brukes som et supplement til andre læremidler. Se også 4CX <a href="/thema/nb/4CX">Voksenopplæring</a>',
            related: [
              {
                id: '4CX',
                label: 'Voksenopplæring',
                originalLabel: 'Voksenopplæring',
                note: 'Brukes for læremidler ment for voksenopplæring gjennom et eget utdanningsprogram eller egen læreplan. Foretrekk andre koder for utdanning på universitetsnivå. Se også 4TY <a href="/thema/nb/4TY">For selvstudium</a>',
              },
            ],
            children: [
              {
                id: '4TYB',
                label: 'For selvstudium: begynnernivå',
                originalLabel: 'For selvstudium: begynnernivå',
                alternativeLabels: [],
                note: 'Brukes for læremidler ment for personer med lite eller ingen kunnskap om emnet, og som er ment for bruk utenfor et gitt utdanningsløp eller pensum, og hvor bøkene er ment brukt i et progresjonsløp',
                related: [],
                children: [],
              },
              {
                id: '4TYF',
                label: 'For selvstudium: mellomnivå',
                originalLabel: 'For selvstudium: mellomnivå',
                alternativeLabels: [],
                note: 'Brukes for læremidler ment for personer med noe kunnskap om emnet, og som er ment brukt utenfor et gitt utdanningsløp eller pensum',
                related: [],
                children: [],
              },
              {
                id: '4TYL',
                label: 'For selvstudium: videregående nivå',
                originalLabel: 'For selvstudium: videregående nivå',
                alternativeLabels: [],
                note: 'Brukes for læremidler ment for personer med god kunnskap om emnet, og som er ment brukt utenfor et gitt utdanningsløp eller pensum',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: '4Z',
        label: 'For spesifikke nasjonale eller regionale læreplaner',
        originalLabel: 'For spesifikke nasjonale eller regionale læreplaner',
        alternativeLabels: [],
        note: 'Brukes med kvalifikator(er) for geografi dersom landet ikke har egne 4Z*-koder',
        related: [],
        children: [
          {
            id: '4Z-AA-',
            label:
              'For pensum knyttet til utdanningssystemer i den arabiske verden',
            originalLabel:
              'For pensum knyttet til utdanningssystemer i den arabiske verden',
            alternativeLabels: [],
            note: 'Brukes med: kvalifikator(er) for geografi dersom landet ikke har egne 4Z*-koder',
            related: [],
            children: [
              {
                id: '4Z-AA-E',
                label: 'For pensum knyttet til egyptiske utdanningssystemer',
                originalLabel:
                  'For pensum knyttet til egyptiske utdanningssystemer',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-AA-EA',
                    label:
                      'For pensum knyttet til utdanningssystemet Al-Azhar (Egypt)',
                    originalLabel:
                      'For pensum knyttet til utdanningssystemet Al-Azhar (Egypt)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '4Z-AR-',
            label: 'For pensum knyttet til utdanningssystemet i Argentina',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Argentina',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-AR-A',
                label: 'For førskole (Argentina)',
                originalLabel: 'For førskole (Argentina)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-AR-B',
                label: 'For primary education (Argentina)',
                originalLabel: 'For primary education (Argentina)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-AR-BA',
                    label: 'Primary Education 1st Cycle (Argentina)',
                    originalLabel: 'Primary Education 1st Cycle (Argentina)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-AR-BC',
                    label: 'Primary Education 2nd Cycle (Argentina)',
                    originalLabel: 'Primary Education 2nd Cycle (Argentina)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-AR-BD',
                    label: '7th Grade Primary Education (Argentina)',
                    originalLabel: '7th Grade Primary Education (Argentina)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-AR-C',
                label: 'For secondary education (Argentina)',
                originalLabel: 'For secondary education (Argentina)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-AR-CA',
                    label: 'For basic secondary education (Argentina)',
                    originalLabel: 'For basic secondary education (Argentina)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-AR-CB',
                    label: 'For higher secondary education (Argentina)',
                    originalLabel: 'For higher secondary education (Argentina)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-AR-CC',
                    label: 'For technical secondary education (Argentina)',
                    originalLabel:
                      'For technical secondary education (Argentina)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '4Z-AU-',
            label: 'For pensum knyttet til utdanningssystemet i Australia',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Australia',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-AU-A',
                label: 'For Early years education (Australia)',
                originalLabel: 'For Early years education (Australia)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-AU-AA',
                    label: 'For Pre-school education (Australia)',
                    originalLabel: 'For Pre-school education (Australia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-AU-AB',
                    label:
                      'For Kindergarten education / Foundation Level (Australia)',
                    originalLabel:
                      'For Kindergarten education / Foundation Level (Australia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-AU-B',
                label: 'For Primary education (Australia)',
                originalLabel: 'For Primary education (Australia)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-AU-BA',
                    label: 'For Year 1 (Australia)',
                    originalLabel: 'For Year 1 (Australia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-AU-BB',
                    label: 'For Year 2 (Australia)',
                    originalLabel: 'For Year 2 (Australia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-AU-BC',
                    label: 'For Year 3 (Australia)',
                    originalLabel: 'For Year 3 (Australia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-AU-BD',
                    label: 'For Year 4 (Australia)',
                    originalLabel: 'For Year 4 (Australia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-AU-BE',
                    label: 'For Year 5 (Australia)',
                    originalLabel: 'For Year 5 (Australia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-AU-BF',
                    label: 'For Year 6 (Australia)',
                    originalLabel: 'For Year 6 (Australia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-AU-C',
                label: 'For Secondary education (Australia)',
                originalLabel: 'For Secondary education (Australia)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-AU-CA',
                    label: 'For Year 7 (Australia)',
                    originalLabel: 'For Year 7 (Australia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-AU-CB',
                    label: 'For Year 8 (Australia)',
                    originalLabel: 'For Year 8 (Australia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-AU-CC',
                    label: 'For Year 9 (Australia)',
                    originalLabel: 'For Year 9 (Australia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-AU-CD',
                    label: 'For Year 10 (Australia)',
                    originalLabel: 'For Year 10 (Australia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-AU-CE',
                    label: 'For Year 11 (Australia)',
                    originalLabel: 'For Year 11 (Australia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-AU-CF',
                    label: 'For Year 12 (Australia)',
                    originalLabel: 'For Year 12 (Australia)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-AU-D',
                label:
                  'For Australian vocational and educational training (VET)',
                originalLabel:
                  'For Australian vocational and educational training (VET)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-AU-H',
                label: 'For The Australian Curriculum',
                originalLabel: 'For The Australian Curriculum',
                alternativeLabels: [],
                note: 'Assessment and Reporting Authority (ACARA)',
                related: [],
                children: [],
              },
              {
                id: '4Z-AU-N',
                label: 'For New South Wales curriculum',
                originalLabel: 'For New South Wales curriculum',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-AU-NH',
                    label: 'For the Higher School Certificate',
                    originalLabel: 'For the Higher School Certificate',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-AU-Q',
                label: 'For Queensland curriculum',
                originalLabel: 'For Queensland curriculum',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-AU-QH',
                    label: 'For the Queensland Certificate of Education (QCE)',
                    originalLabel:
                      'For the Queensland Certificate of Education (QCE)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-AU-S',
                label: 'For South Australia curriculum',
                originalLabel: 'For South Australia curriculum',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-AU-SH',
                    label: 'South Australian Certificate of Education (SACE)',
                    originalLabel:
                      'South Australian Certificate of Education (SACE)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-AU-T',
                label: 'For Tasmanian curriculum',
                originalLabel: 'For Tasmanian curriculum',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-AU-TH',
                    label: 'Tasmanian Certificate of Education (TCE)',
                    originalLabel: 'Tasmanian Certificate of Education (TCE)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-AU-V',
                label: 'For Victoria curriculum',
                originalLabel: 'For Victoria curriculum',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-AU-VH',
                    label: 'Victorian Certificate of Education (VCE)',
                    originalLabel: 'Victorian Certificate of Education (VCE)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-AU-W',
                label: 'For Western Australia curriculum',
                originalLabel: 'For Western Australia curriculum',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-AU-WH',
                    label: 'Western Australian Certificate of Education (WACE)',
                    originalLabel:
                      'Western Australian Certificate of Education (WACE)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-AU-X',
                label: 'For the Northern Territories curriculum',
                originalLabel: 'For the Northern Territories curriculum',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-AU-XH',
                    label: 'Northern Territory Certificate of Education (NTCE)',
                    originalLabel:
                      'Northern Territory Certificate of Education (NTCE)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-AU-Y',
                label: 'For the Australian Capital Territory curriculum',
                originalLabel:
                  'For the Australian Capital Territory curriculum',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-AU-YH',
                    label:
                      'For the Senior Secondary Certificate and Record of Achievement (ACT SSC)',
                    originalLabel:
                      'For the Senior Secondary Certificate and Record of Achievement (ACT SSC)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '4Z-BE-',
            label: 'For pensum knyttet til utdanningssystemet i Belgia',
            originalLabel: 'For pensum knyttet til utdanningssystemet i Belgia',
            alternativeLabels: [],
            note: 'Brukes med andre kvalifikatorer fra 4* for å angi nivå',
            related: [],
            children: [
              {
                id: '4Z-BE-B',
                label:
                  'For pensum knyttet til utdanningssystemet i flamsk Belgia',
                originalLabel:
                  'For pensum knyttet til utdanningssystemet i flamsk Belgia',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-BE-BA',
                    label: 'Pre-primary education (Flemish)',
                    originalLabel: 'Pre-primary education (Flemish)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-BE-BB',
                    label: 'Special Pre-primary education (Flemish)',
                    originalLabel: 'Special Pre-primary education (Flemish)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-BE-BC',
                    label: 'Primary education (Flemish)',
                    originalLabel: 'Primary education (Flemish)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-BE-BD',
                    label: 'Special Primary education (Flemish)',
                    originalLabel: 'Special Primary education (Flemish)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-BE-BE',
                    label: 'Secondary Education (Flemish)',
                    originalLabel: 'Secondary Education (Flemish)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-BE-BEA',
                        label: 'First cycle (years 1 and 2)',
                        originalLabel: 'First cycle (years 1 and 2)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '4Z-BE-BEAA',
                            label: 'A-stream (BE)',
                            originalLabel: 'A-stream (BE)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-BE-BEAB',
                            label: 'B-stream (BE)',
                            originalLabel: 'B-stream (BE)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '4Z-BE-BEB',
                        label: 'Second cycle (years 3 and 4)',
                        originalLabel: 'Second cycle (years 3 and 4)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '4Z-BE-BEBA',
                            label:
                              'Second cycle: General Secondary Education (ASO)',
                            originalLabel:
                              'Second cycle: General Secondary Education (ASO)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-BE-BEBB',
                            label:
                              'Second cycle: Vocational Secondary Education (BSO)',
                            originalLabel:
                              'Second cycle: Vocational Secondary Education (BSO)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-BE-BEBC',
                            label:
                              'Second cycle: Art Secondary Education (KSO)',
                            originalLabel:
                              'Second cycle: Art Secondary Education (KSO)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-BE-BEBD',
                            label:
                              'Second cycle: Technical Secondary Education (TSO)',
                            originalLabel:
                              'Second cycle: Technical Secondary Education (TSO)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '4Z-BE-BEC',
                        label: 'Third cycle (years 5 and 6)',
                        originalLabel: 'Third cycle (years 5 and 6)',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '4Z-BE-BECA',
                            label:
                              'Third cycle: General Secondary Education (ASO)',
                            originalLabel:
                              'Third cycle: General Secondary Education (ASO)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-BE-BECB',
                            label:
                              'Third cycle: Vocational Secondary Education (BSO)',
                            originalLabel:
                              'Third cycle: Vocational Secondary Education (BSO)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-BE-BECC',
                            label: 'Third cycle: Art Secondary Education (KSO)',
                            originalLabel:
                              'Third cycle: Art Secondary Education (KSO)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-BE-BECD',
                            label:
                              'Third cycle: Technical Secondary Education (TSO)',
                            originalLabel:
                              'Third cycle: Technical Secondary Education (TSO)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-BE-BECE',
                            label: 'Secondary-after-Secondary (Se-n-Se)',
                            originalLabel:
                              'Secondary-after-Secondary (Se-n-Se)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                id: '4Z-BE-D',
                label:
                  'For the educational curricula of the French community (Belgium)',
                originalLabel:
                  'For the educational curricula of the French community (Belgium)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-BE-DA',
                    label: 'Pre-primary education (French community BE)',
                    originalLabel:
                      'Pre-primary education (French community BE)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-BE-DC',
                    label: 'Primary education (French community BE)',
                    originalLabel: 'Primary education (French community BE)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-BE-DE',
                    label: 'Secondary Education (French community BE)',
                    originalLabel: 'Secondary Education (French community BE)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-BE-H',
                label:
                  'For the educational curricula of the German-speaking community (Belgium)',
                originalLabel:
                  'For the educational curricula of the German-speaking community (Belgium)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-BE-HA',
                    label:
                      'Pre-primary education (German-speaking community BE)',
                    originalLabel:
                      'Pre-primary education (German-speaking community BE)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-BE-HC',
                    label: 'Primary education (German-speaking community BE)',
                    originalLabel:
                      'Primary education (German-speaking community BE)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-BE-HE',
                    label: 'Secondary Education (German-speaking community BE)',
                    originalLabel:
                      'Secondary Education (German-speaking community BE)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '4Z-BO-',
            label: 'For pensum knyttet til utdanningssystemet i Bolivia',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Bolivia',
            alternativeLabels: [],
            note: 'Brukes med: andre kvalifikatorer for pedagogisk formål for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-BR-',
            label: 'For pensum knyttet til utdanningssystemet i Brasil',
            originalLabel: 'For pensum knyttet til utdanningssystemet i Brasil',
            alternativeLabels: [],
            note: 'Brukes med: andre kvalifikatorer for pedagogisk formål for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-CA-',
            label: 'For pensum knyttet til utdanningssystemet i Canada',
            originalLabel: 'For pensum knyttet til utdanningssystemet i Canada',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-CA-A',
                label: 'For Elementary Education (Canada)',
                originalLabel: 'For Elementary Education (Canada)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-CA-C',
                label: 'For Secondary Education (Canada)',
                originalLabel: 'For Secondary Education (Canada)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-CA-F',
                label: 'For College, Pre-University Programs (Canada)',
                originalLabel: 'For College, Pre-University Programs (Canada)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-CA-H',
                label: 'For College, Technical Programs (Canada)',
                originalLabel: 'For College, Technical Programs (Canada)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-CA-J',
                label: 'For University, Bachelor’s Degree (Canada)',
                originalLabel: 'For University, Bachelor’s Degree (Canada)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-CA-L',
                label: 'For University, Master’s Degree (Canada)',
                originalLabel: 'For University, Master’s Degree (Canada)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-CA-M',
                label: 'For University, Doctorate (Canada)',
                originalLabel: 'For University, Doctorate (Canada)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-CA-Q',
                label: 'For Quebec educational curricula',
                originalLabel: 'For Quebec educational curricula',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-CA-QA',
                    label: 'Quebec: for Elementary education',
                    originalLabel: 'Quebec: for Elementary education',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-CA-QC',
                    label: 'Quebec: for Secondary education',
                    originalLabel: 'Quebec: for Secondary education',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-CA-QF',
                    label: 'Quebec: CEGEP pre-university programme',
                    originalLabel: 'Quebec: CEGEP pre-university programme',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-CA-QH',
                    label: 'Quebec: CEGEP technical programme',
                    originalLabel: 'Quebec: CEGEP technical programme',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-CA-QJ',
                    label: 'Quebec: for university Bachelor’s degree',
                    originalLabel: 'Quebec: for university Bachelor’s degree',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-CA-QK',
                    label: 'Quebec: for university Master’s degree',
                    originalLabel: 'Quebec: for university Master’s degree',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-CA-QL',
                    label: 'Quebec: for university Doctorate',
                    originalLabel: 'Quebec: for university Doctorate',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '4Z-CB-',
            label:
              'For pensum knyttet til utdanningssystemet på de karibiske øyene',
            originalLabel:
              'For pensum knyttet til utdanningssystemet på de karibiske øyene',
            alternativeLabels: [],
            note: 'Brukes med: kvalifikator(er) for geografi dersom landet ikke har egne 4Z*-koder og med andre kvalifikatorer for pedagogisk formål for å angi nivå',
            related: [],
            children: [
              {
                id: '4Z-CB-C',
                label: 'For Caribbean Examinations Council (CXC)',
                originalLabel: 'For Caribbean Examinations Council (CXC)',
                alternativeLabels: [],
                note: 'Use for: titles that are suitable for use for studying for a CXC approved exam or qualification',
                related: [],
                children: [
                  {
                    id: '4Z-CB-CB',
                    label: 'For Caribbean Primary Exit Assessment (CPEA)',
                    originalLabel:
                      'For Caribbean Primary Exit Assessment (CPEA)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-CB-CD',
                    label:
                      'For Caribbean Secondary Education Certificate (CSEC)',
                    originalLabel:
                      'For Caribbean Secondary Education Certificate (CSEC)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-CB-CF',
                    label:
                      'For Caribbean Advanced Proficiency Examinations (CAPE)',
                    originalLabel:
                      'For Caribbean Advanced Proficiency Examinations (CAPE)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-CB-CS',
                    label:
                      'For Caribbean Certificate of Secondary Level Competence (CCSLC)',
                    originalLabel:
                      'For Caribbean Certificate of Secondary Level Competence (CCSLC)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-CB-CV',
                    label: 'For Caribbean Vocational Qualification (CVQ)',
                    originalLabel:
                      'For Caribbean Vocational Qualification (CVQ)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-CB-E',
                label:
                  'For the educational curricula of Organisation of Eastern Caribbean States (OECS) states',
                originalLabel:
                  'For the educational curricula of Organisation of Eastern Caribbean States (OECS) states',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '4Z-CL-',
            label: 'For pensum knyttet til utdanningssystemet i Chile',
            originalLabel: 'For pensum knyttet til utdanningssystemet i Chile',
            alternativeLabels: [],
            note: 'Brukes med: andre kvalifikatorer for pedagogisk formål for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-CO-',
            label: 'For pensum knyttet til utdanningssystemet i Colombia',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Colombia',
            alternativeLabels: [],
            note: 'Brukes med: andre kvalifikatorer for pedagogisk formål for å angi nivå',
            related: [],
            children: [
              {
                id: '4Z-CO-A',
                label: 'Initial education - Pre-jardín and Jardín (Colombia)',
                originalLabel:
                  'Initial education - Pre-jardín and Jardín (Colombia)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-CO-B',
                label: 'Preschool education -Transición (Colombia)',
                originalLabel: 'Preschool education -Transición (Colombia)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-CO-C',
                label: 'Basic Education - Primary and Secondary (Colombia)',
                originalLabel:
                  'Basic Education - Primary and Secondary (Colombia)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-CO-D',
                label:
                  'Middle Education - Tenth and Eleventh Grades (Colombia)',
                originalLabel:
                  'Middle Education - Tenth and Eleventh Grades (Colombia)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-CO-E',
                label: 'Higher Education (Colombia)',
                originalLabel: 'Higher Education (Colombia)',
                alternativeLabels: [],
                note: 'Class here: Undergraduate level, professional, technological and university technical careers / Postgraduate level: Specialisations, masters and doctorates',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '4Z-CR-',
            label: 'For pensum knyttet til utdanningssystemet i Costa Rica',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Costa Rica',
            alternativeLabels: [],
            note: 'Brukes med: andre kvalifikatorer for pedagogisk formål for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-CU-',
            label: 'For pensum knyttet til utdanningssystemet på Cuba',
            originalLabel: 'For pensum knyttet til utdanningssystemet på Cuba',
            alternativeLabels: [],
            note: 'Brukes med: andre kvalifikatorer for pedagogisk formål for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-CZ-',
            label: 'For pensum knyttet til utdanningssystemet på Tsjekkia',
            originalLabel:
              'For pensum knyttet til utdanningssystemet på Tsjekkia',
            alternativeLabels: [],
            note: 'Brukes med: andre kvalifikatorer for pedagogisk formål for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-DE-',
            label: 'For pensum knyttet til utdanningssystemet i Tyskland',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Tyskland',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-DE-A',
                label: 'For pre-school learning (Germany)',
                originalLabel: 'For pre-school learning (Germany)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-DE-B',
                label: 'For primary education (Germany)',
                originalLabel: 'For primary education (Germany)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-DE-BA',
                    label: 'For German elementary school',
                    originalLabel: 'For German elementary school',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-DE-C',
                label: 'For German common school',
                originalLabel: 'For German common school',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-DE-D',
                label: 'For secondary education (Germany)',
                originalLabel: 'For secondary education (Germany)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-DE-DA',
                    label: 'For German academic high school add on education',
                    originalLabel:
                      'For German academic high school add on education',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-DE-DAB',
                        label: 'For German integrated secondary school',
                        originalLabel: 'For German integrated secondary school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-DAD',
                        label: 'For German cooperative comprehensive school',
                        originalLabel:
                          'For German cooperative comprehensive school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-DAE',
                        label: 'For German laboratory school',
                        originalLabel: 'For German laboratory school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-DAF',
                        label: 'For German intermediate school',
                        originalLabel: 'For German intermediate school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-DAG',
                        label: 'For German pre-intermediate school',
                        originalLabel: 'For German pre-intermediate school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-DAH',
                        label: 'For German ten-class secondary school',
                        originalLabel: 'For German ten-class secondary school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-DAJ',
                        label: 'For German upper school college',
                        originalLabel: 'For German upper school college',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-DAK',
                        label: 'For German junior high school',
                        originalLabel: 'For German junior high school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-DAL',
                        label:
                          'For German combined middle and secondary school',
                        originalLabel:
                          'For German combined middle and secondary school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-DE-DB',
                    label: 'For German middle school add on education',
                    originalLabel: 'For German middle school add on education',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-DE-DBA',
                        label: 'For German regular school',
                        originalLabel: 'For German regular school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-DBB',
                        label: 'For German regional school',
                        originalLabel: 'For German regional school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-DBC',
                        label: 'For German orientation classes',
                        originalLabel: 'For German orientation classes',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-DBD',
                        label: 'For German schools of a special kind',
                        originalLabel: 'For German schools of a special kind',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-DBE',
                        label: 'For German secondary school',
                        originalLabel: 'For German secondary school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-DBF',
                        label: 'For German municipality school',
                        originalLabel: 'For German municipality school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-DBG',
                        label:
                          'For German craft related junior high or main school',
                        originalLabel:
                          'For German craft related junior high or main school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-DE-DC',
                    label: 'For German comprehensive school',
                    originalLabel: 'For German comprehensive school',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-DD',
                    label: 'For German academic high school',
                    originalLabel: 'For German academic high school',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-DE-DDA',
                        label: 'For German academic high school – eight year',
                        originalLabel:
                          'For German academic high school – eight year',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-DDB',
                        label: 'For German academic high school – nine year',
                        originalLabel:
                          'For German academic high school – nine year',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-DE-DJ',
                    label: 'For German general school',
                    originalLabel: 'For German general school',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-DL',
                    label: 'For German integrated comprehensive school',
                    originalLabel: 'For German integrated comprehensive school',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-DE-F',
                label: 'For vocational education (Germany)',
                originalLabel: 'For vocational education (Germany)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-DE-FA',
                    label: 'For German vocational high school',
                    originalLabel: 'For German vocational high school',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-DE-FAB',
                        label:
                          'For German vocational professional school, two-year course',
                        originalLabel:
                          'For German vocational professional school, two-year course',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FAD',
                        label:
                          'For occupational training one year basic course or for German vocational elementary school',
                        originalLabel:
                          'For occupational training one year basic course or for German vocational elementary school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FAF',
                        label: 'For German vocational college',
                        originalLabel: 'For German vocational college',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FAH',
                        label: 'For German vocational secondary school',
                        originalLabel: 'For German vocational secondary school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FAJ',
                        label:
                          'For German vocational professional school qualifying for a certain profession',
                        originalLabel:
                          'For German vocational professional school qualifying for a certain profession',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FAL',
                        label: 'For German vocational school',
                        originalLabel: 'For German vocational school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FAM',
                        label: 'For German preliminary vocational training',
                        originalLabel:
                          'For German preliminary vocational training',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FAN',
                        label:
                          'For German build-up year for vocational training',
                        originalLabel:
                          'For German build-up year for vocational training',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FAP',
                        label:
                          'For German courses of education – with double qualifications',
                        originalLabel:
                          'For German courses of education – with double qualifications',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-DE-FB',
                    label: 'For German vocational upper secondary school',
                    originalLabel:
                      'For German vocational upper secondary school',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-DE-FBB',
                        label: 'For German dual vocational school',
                        originalLabel: 'For German dual vocational school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FBD',
                        label: 'For German professional academy',
                        originalLabel: 'For German professional academy',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FBF',
                        label: 'For German professional high school',
                        originalLabel: 'For German professional high school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FBH',
                        label: 'For German university of applied sciences',
                        originalLabel:
                          'For German university of applied sciences',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FBJ',
                        label:
                          'For German specialist classes of the dual system of vocational training',
                        originalLabel:
                          'For German specialist classes of the dual system of vocational training',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FBL',
                        label: 'For German upper secondary vocational school',
                        originalLabel:
                          'For German upper secondary vocational school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FBM',
                        label: 'For German secondary vocational school',
                        originalLabel: 'For German secondary vocational school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FBN',
                        label: 'For German upper secondary professional school',
                        originalLabel:
                          'For German upper secondary professional school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FBP',
                        label: 'For German commercial college',
                        originalLabel: 'For German commercial college',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FBR',
                        label: 'For German technical college',
                        originalLabel: 'For German technical college',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-DE-FC',
                    label: 'For German vocational academy',
                    originalLabel: 'For German vocational academy',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-DE-FCB',
                        label:
                          'For German integrated vocational training preparation',
                        originalLabel:
                          'For German integrated vocational training preparation',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FCD',
                        label: 'For German independent vocational schools',
                        originalLabel:
                          'For German independent vocational schools',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FCF',
                        label:
                          'For German occupational training one year prep classes',
                        originalLabel:
                          'For German occupational training one year prep classes',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FCH',
                        label:
                          'For German independent vocational schools with focus on crafts',
                        originalLabel:
                          'For German independent vocational schools with focus on crafts',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-FCJ',
                        label: 'For German commercial school',
                        originalLabel: 'For German commercial school',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-DE-FD',
                    label: 'For German vocational build-up school',
                    originalLabel: 'For German vocational build-up school',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-FF',
                    label:
                      'For preparation of entry into professional life – one year school (Germany)',
                    originalLabel:
                      'For preparation of entry into professional life – one year school (Germany)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-FH',
                    label:
                      'For preparation of entry into professional life – class (Germany)',
                    originalLabel:
                      'For preparation of entry into professional life – class (Germany)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-FJ',
                    label:
                      'For preparation of entry into professional life – school (Germany)',
                    originalLabel:
                      'For preparation of entry into professional life – school (Germany)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-FL',
                    label: 'For German vocational professional school',
                    originalLabel: 'For German vocational professional school',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-FN',
                    label:
                      'For German vocational professional school, one year course',
                    originalLabel:
                      'For German vocational professional school, one year course',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-DE-G',
                label: 'For special needs schools (Germany)',
                originalLabel: 'For special needs schools (Germany)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-DE-GA',
                    label:
                      'For German special-needs school (with focus on assistance with learning disabilities)',
                    originalLabel:
                      'For German special-needs school (with focus on assistance with learning disabilities)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-GC',
                    label:
                      'For German special-needs school (with focus on assistance with mental development)',
                    originalLabel:
                      'For German special-needs school (with focus on assistance with mental development)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-GE',
                    label:
                      'For German special-needs school (with focus on assistance with emotional and social development)',
                    originalLabel:
                      'For German special-needs school (with focus on assistance with emotional and social development)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-GH',
                    label:
                      'For German special-needs school (with focus on assistance with language learning)',
                    originalLabel:
                      'For German special-needs school (with focus on assistance with language learning)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-GJ',
                    label:
                      'For German special-needs school (with focus on assistance with physical and motor development)',
                    originalLabel:
                      'For German special-needs school (with focus on assistance with physical and motor development)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-GL',
                    label:
                      'For German special-needs school (with focus on assistance with hearing)',
                    originalLabel:
                      'For German special-needs school (with focus on assistance with hearing)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-GN',
                    label:
                      'For German special-needs school (with focus on assistance with vision)',
                    originalLabel:
                      'For German special-needs school (with focus on assistance with vision)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-DE-H',
                label: 'For German College or University Education',
                originalLabel: 'For German College or University Education',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-DE-J',
                label: 'For adult education (Germany)',
                originalLabel: 'For adult education (Germany)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-DE-JB',
                    label: 'For German three-year school of adult education',
                    originalLabel:
                      'For German three-year school of adult education',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-DE-L',
                label: 'For learning years (Germany)',
                originalLabel: 'For learning years (Germany)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-DE-LA',
                    label: 'For learning year 1 (Germany)',
                    originalLabel: 'For learning year 1 (Germany)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-LB',
                    label: 'For learning year 2 (Germany)',
                    originalLabel: 'For learning year 2 (Germany)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-LC',
                    label: 'For learning year 3 (Germany)',
                    originalLabel: 'For learning year 3 (Germany)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-LD',
                    label: 'For learning year 4 (Germany)',
                    originalLabel: 'For learning year 4 (Germany)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-LE',
                    label: 'For learning year 5 (Germany)',
                    originalLabel: 'For learning year 5 (Germany)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-LF',
                    label: 'For learning year 6 (Germany)',
                    originalLabel: 'For learning year 6 (Germany)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-LG',
                    label: 'For learning year 7 (Germany)',
                    originalLabel: 'For learning year 7 (Germany)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-LH',
                    label: 'For learning year 8 (Germany)',
                    originalLabel: 'For learning year 8 (Germany)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-LJ',
                    label: 'For learning year 9 (Germany)',
                    originalLabel: 'For learning year 9 (Germany)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-LK',
                    label: 'For learning year 10 (Germany)',
                    originalLabel: 'For learning year 10 (Germany)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-LL',
                    label: 'For learning year 11 (Germany)',
                    originalLabel: 'For learning year 11 (Germany)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-LM',
                    label: 'For learning year 12 (Germany)',
                    originalLabel: 'For learning year 12 (Germany)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DE-LN',
                    label: 'For learning year 13 (Germany)',
                    originalLabel: 'For learning year 13 (Germany)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-DE-U',
                label:
                  'For specific German vocational, professional or university qualifications',
                originalLabel:
                  'For specific German vocational, professional or university qualifications',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-DE-UD',
                    label:
                      'For specific German professional qualifications and degrees',
                    originalLabel:
                      'For specific German professional qualifications and degrees',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-DE-UDA',
                        label:
                          'For German law qualifications (bachelors and other undergraduate)',
                        originalLabel:
                          'For German law qualifications (bachelors and other undergraduate)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-UDB',
                        label:
                          'For German law qualifications (masters, postgraduate and doctoral)',
                        originalLabel:
                          'For German law qualifications (masters, postgraduate and doctoral)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-UDC',
                        label:
                          'For German first state exam in licensed professions (Erste Staatsexamen)',
                        originalLabel:
                          'For German first state exam in licensed professions (Erste Staatsexamen)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-UDD',
                        label:
                          'For German training placements in state-licensed professions (Referendariat / Stationen)',
                        originalLabel:
                          'For German training placements in state-licensed professions (Referendariat / Stationen)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-UDE',
                        label:
                          'For German second state exam for licensed professions (Zweite Staatsexamen)',
                        originalLabel:
                          'For German second state exam for licensed professions (Zweite Staatsexamen)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-UDF',
                        label:
                          'For German lawyers, paralegals and legal secretaries (professional development and reference)',
                        originalLabel:
                          'For German lawyers, paralegals and legal secretaries (professional development and reference)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-UDG',
                        label:
                          'For German tax consulting (professional development and reference)',
                        originalLabel:
                          'For German tax consulting (professional development and reference)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-UDH',
                        label:
                          'For German curricula leading to certificated legal specialisms',
                        originalLabel:
                          'For German curricula leading to certificated legal specialisms',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-UDI',
                        label: 'For German tax advisors’ exams',
                        originalLabel: 'For German tax advisors’ exams',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-UDJ',
                        label: 'For German civil law notaries’ exams',
                        originalLabel: 'For German civil law notaries’ exams',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-DE-UDK',
                        label: 'For German auditors’ exams',
                        originalLabel: 'For German auditors’ exams',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-DE-UH',
                    label:
                      'For specific German professional/vocational qualifications and degrees (dual education system)',
                    originalLabel:
                      'For specific German professional/vocational qualifications and degrees (dual education system)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-DE-UHA',
                        label:
                          'For German vocational qualifications working in the land sector',
                        originalLabel:
                          'For German vocational qualifications working in the land sector',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '4Z-DE-UHAA',
                            label:
                              'For German vocational qualifications in agriculture, husbandry, forestry, landscaping and horticulture',
                            originalLabel:
                              'For German vocational qualifications in agriculture, husbandry, forestry, landscaping and horticulture',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHAB',
                            label:
                              'For German vocational qualifications in land surveying',
                            originalLabel:
                              'For German vocational qualifications in land surveying',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '4Z-DE-UHB',
                        label:
                          'For German vocational qualifications in the food industries',
                        originalLabel:
                          'For German vocational qualifications in the food industries',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '4Z-DE-UHBA',
                            label:
                              'For German vocational qualifications in baking, pastry making and confectionery',
                            originalLabel:
                              'For German vocational qualifications in baking, pastry making and confectionery',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHBB',
                            label:
                              'For German vocational qualifications as a butcher',
                            originalLabel:
                              'For German vocational qualifications as a butcher',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHBC',
                            label:
                              'For German vocational qualifications as a chef',
                            originalLabel:
                              'For German vocational qualifications as a chef',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHBD',
                            label:
                              'For German vocational qualifications as a food and beverage specialist',
                            originalLabel:
                              'For German vocational qualifications as a food and beverage specialist',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '4Z-DE-UHD',
                        label:
                          'For German vocational qualifications in metal production and metal working industries',
                        originalLabel:
                          'For German vocational qualifications in metal production and metal working industries',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '4Z-DE-UHDA',
                            label:
                              'For German vocational qualifications in precision engineering and related professions',
                            originalLabel:
                              'For German vocational qualifications in precision engineering and related professions',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHDB',
                            label:
                              'For German vocational qualifications in metal production and processing',
                            originalLabel:
                              'For German vocational qualifications in metal production and processing',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHDC',
                            label:
                              'For German vocational qualifications in metalworking, plant construction, sheet metalwork, casting, and fitting',
                            originalLabel:
                              'For German vocational qualifications in metalworking, plant construction, sheet metalwork, casting, and fitting',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHDD',
                            label:
                              'For German vocational qualifications as an industrial mechanic or toolmaker',
                            originalLabel:
                              'For German vocational qualifications as an industrial mechanic or toolmaker',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '4Z-DE-UHE',
                        label:
                          'For German vocational qualifications in stone, glass and ceramic production and related industries',
                        originalLabel:
                          'For German vocational qualifications in stone, glass and ceramic production and related industries',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '4Z-DE-UHEA',
                            label:
                              'For German vocational qualifications in stone, glass or ceramics processing and building materials',
                            originalLabel:
                              'For German vocational qualifications in stone, glass or ceramics processing and building materials',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHEB',
                            label:
                              'For German vocational qualifications in mining and quarrying',
                            originalLabel:
                              'For German vocational qualifications in mining and quarrying',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '4Z-DE-UHF',
                        label:
                          'For German vocational qualifications in the cloth and leather industries',
                        originalLabel:
                          'For German vocational qualifications in the cloth and leather industries',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '4Z-DE-UHFA',
                            label:
                              'For German vocational qualifications in spinning, weaving, textile finishing',
                            originalLabel:
                              'For German vocational qualifications in spinning, weaving, textile finishing',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHFB',
                            label:
                              'For German vocational qualifications in textile processing and leatherworking',
                            originalLabel:
                              'For German vocational qualifications in textile processing and leatherworking',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '4Z-DE-UHH',
                        label:
                          'For German vocational qualifications in engineering, manufacturing and construction',
                        originalLabel:
                          'For German vocational qualifications in engineering, manufacturing and construction',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '4Z-DE-UHHA',
                            label:
                              'For German vocational qualifications in electrical trades',
                            originalLabel:
                              'For German vocational qualifications in electrical trades',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHHB',
                            label:
                              'For German vocational qualifications as technicians or mechanical engineer',
                            originalLabel:
                              'For German vocational qualifications as technicians or mechanical engineer',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHHC',
                            label:
                              'For German vocational qualifications as a drafter or drafting technician',
                            originalLabel:
                              'For German vocational qualifications as a drafter or drafting technician',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHHD',
                            label:
                              'For German vocational qualifications in vehicle or aircraft manufacture and maintenance',
                            originalLabel:
                              'For German vocational qualifications in vehicle or aircraft manufacture and maintenance',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHHE',
                            label:
                              'For German vocational qualifications in construction, wood and plastic processing',
                            originalLabel:
                              'For German vocational qualifications in construction, wood and plastic processing',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHHF',
                            label:
                              'For German vocational qualifications in the chemical and plastics industries',
                            originalLabel:
                              'For German vocational qualifications in the chemical and plastics industries',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHHG',
                            label:
                              'For German vocational qualifications in paper manufacture, processing and printing',
                            originalLabel:
                              'For German vocational qualifications in paper manufacture, processing and printing',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '4Z-DE-UHJ',
                        label:
                          'For German vocational qualifications in scientific, geographical and IT related professions',
                        originalLabel:
                          'For German vocational qualifications in scientific, geographical and IT related professions',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '4Z-DE-UHJA',
                            label:
                              'For German vocational qualifications in chemistry, physics or natural sciences',
                            originalLabel:
                              'For German vocational qualifications in chemistry, physics or natural sciences',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHJB',
                            label: 'For German vocational qualifications in IT',
                            originalLabel:
                              'For German vocational qualifications in IT',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '4Z-DE-UHL',
                        label:
                          'For German vocational qualifications in transport, logistics, security and safety',
                        originalLabel:
                          'For German vocational qualifications in transport, logistics, security and safety',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '4Z-DE-UHLA',
                            label:
                              'For German vocational qualifications in the transport sector',
                            originalLabel:
                              'For German vocational qualifications in the transport sector',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHLB',
                            label:
                              'For German vocational qualifications in aviation or seafaring',
                            originalLabel:
                              'For German vocational qualifications in aviation or seafaring',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHLC',
                            label:
                              'For German vocational qualifications in quality control and inspection',
                            originalLabel:
                              'For German vocational qualifications in quality control and inspection',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHLD',
                            label:
                              'For German vocational qualifications as packers, warehouse or transport workers',
                            originalLabel:
                              'For German vocational qualifications as packers, warehouse or transport workers',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHLE',
                            label:
                              'For German vocational qualifications in personal protection and security',
                            originalLabel:
                              'For German vocational qualifications in personal protection and security',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHLF',
                            label:
                              'For German vocational qualifications in the safety and security professions',
                            originalLabel:
                              'For German vocational qualifications in the safety and security professions',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHLG',
                            label:
                              'For German vocational qualifications in cleansing and waste disposal',
                            originalLabel:
                              'For German vocational qualifications in cleansing and waste disposal',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '4Z-DE-UHM',
                        label:
                          'For German vocational qualifications in commercial services, retail, distribution, hospitality and tourism',
                        originalLabel:
                          'For German vocational qualifications in commercial services, retail, distribution, hospitality and tourism',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '4Z-DE-UHMA',
                            label:
                              'For German vocational qualifications in the retail sector',
                            originalLabel:
                              'For German vocational qualifications in the retail sector',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHMB',
                            label:
                              'For German vocational qualifications in the wholesale sector',
                            originalLabel:
                              'For German vocational qualifications in the wholesale sector',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHMC',
                            label:
                              'For German vocational qualifications as caretakers and property management',
                            originalLabel:
                              'For German vocational qualifications as caretakers and property management',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHMD',
                            label:
                              'For German vocational qualifications in the hospitality industry',
                            originalLabel:
                              'For German vocational qualifications in the hospitality industry',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '4Z-DE-UHP',
                        label:
                          'For German vocational qualifications in the finance, business and administration sectors',
                        originalLabel:
                          'For German vocational qualifications in the finance, business and administration sectors',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '4Z-DE-UHPA',
                            label:
                              'For German vocational qualifications in banking or insurance',
                            originalLabel:
                              'For German vocational qualifications in banking or insurance',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHPB',
                            label:
                              'For German vocational qualifications in commercial office skills',
                            originalLabel:
                              'For German vocational qualifications in commercial office skills',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHPC',
                            label:
                              'For German vocational qualifications in management, accounting and business consulting',
                            originalLabel:
                              'For German vocational qualifications in management, accounting and business consulting',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHPD',
                            label:
                              'For German vocational qualifications in public administration',
                            originalLabel:
                              'For German vocational qualifications in public administration',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHPE',
                            label:
                              'For German vocational qualifications in finance and bookkeeping',
                            originalLabel:
                              'For German vocational qualifications in finance and bookkeeping',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHPF',
                            label:
                              'For German vocational qualifications in secretarial and office skills',
                            originalLabel:
                              'For German vocational qualifications in secretarial and office skills',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHPG',
                            label:
                              'For German vocational qualifications in the legal sector',
                            originalLabel:
                              'For German vocational qualifications in the legal sector',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '4Z-DE-UHQ',
                        label:
                          'For German vocational qualifications in the health, social care and educational sectors',
                        originalLabel:
                          'For German vocational qualifications in the health, social care and educational sectors',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '4Z-DE-UHQA',
                            label:
                              'For German vocational qualifications in personal care',
                            originalLabel:
                              'For German vocational qualifications in personal care',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHQB',
                            label:
                              'For German vocational qualifications in health care (licenced)',
                            originalLabel:
                              'For German vocational qualifications in health care (licenced)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHQC',
                            label:
                              'For German vocational qualifications in health care (without a licence)',
                            originalLabel:
                              'For German vocational qualifications in health care (without a licence)',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHQD',
                            label:
                              'For German vocational qualifications in social work',
                            originalLabel:
                              'For German vocational qualifications in social work',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHQE',
                            label:
                              'For German vocational qualifications in teaching',
                            originalLabel:
                              'For German vocational qualifications in teaching',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: '4Z-DE-UHS',
                        label:
                          'For German vocational qualifications in the cultural sector, creative industries and the media',
                        originalLabel:
                          'For German vocational qualifications in the cultural sector, creative industries and the media',
                        alternativeLabels: [],

                        related: [],
                        children: [
                          {
                            id: '4Z-DE-UHSA',
                            label:
                              'For German vocational qualifications in advertising',
                            originalLabel:
                              'For German vocational qualifications in advertising',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHSB',
                            label:
                              'For German vocational qualifications in art or music',
                            originalLabel:
                              'For German vocational qualifications in art or music',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHSC',
                            label:
                              'For German vocational qualifications in design or photography',
                            originalLabel:
                              'For German vocational qualifications in design or photography',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                          {
                            id: '4Z-DE-UHSD',
                            label:
                              'For German vocational qualifications in publishing, librarianship, translation and related sectors',
                            originalLabel:
                              'For German vocational qualifications in publishing, librarianship, translation and related sectors',
                            alternativeLabels: [],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: '4Z-DK-',
            label: 'For pensum knyttet til utdanningssystemet i Danmark',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Danmark',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-DK-A',
                label: 'For early education (Denmark)',
                originalLabel: 'For early education (Denmark)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-DK-F',
                label: 'For elementary school (Denmark)',
                originalLabel: 'For elementary school (Denmark)',
                alternativeLabels: [],
                note: 'Class here: similar tuition at private and boarding schools',
                related: [],
                children: [
                  {
                    id: '4Z-DK-FA',
                    label: 'For pre-school (Denmark)',
                    originalLabel: 'For pre-school (Denmark)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DK-FB',
                    label: 'For Grade 0 (Denmark)',
                    originalLabel: 'For Grade 0 (Denmark)',
                    alternativeLabels: [],
                    note: 'Class here: for reception',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DK-FC',
                    label: 'For Grade 1 (Denmark)',
                    originalLabel: 'For Grade 1 (Denmark)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DK-FD',
                    label: 'For Grade 2 (Denmark)',
                    originalLabel: 'For Grade 2 (Denmark)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DK-FE',
                    label: 'For Grade 3 (Denmark)',
                    originalLabel: 'For Grade 3 (Denmark)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DK-FF',
                    label: 'For Grade 4 (Denmark)',
                    originalLabel: 'For Grade 4 (Denmark)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DK-FG',
                    label: 'For Grade 5 (Denmark)',
                    originalLabel: 'For Grade 5 (Denmark)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DK-FH',
                    label: 'For Grade 6 (Denmark)',
                    originalLabel: 'For Grade 6 (Denmark)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DK-FI',
                    label: 'For Grade 7 (Denmark)',
                    originalLabel: 'For Grade 7 (Denmark)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DK-FJ',
                    label: 'For Grade 8 (Denmark)',
                    originalLabel: 'For Grade 8 (Denmark)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DK-FK',
                    label: 'For Grade 9 (Denmark)',
                    originalLabel: 'For Grade 9 (Denmark)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DK-FL',
                    label: 'For Grade 10 (Denmark)',
                    originalLabel: 'For Grade 10 (Denmark)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-DK-G',
                label: 'For secondary school (Denmark)',
                originalLabel: 'For secondary school (Denmark)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-DK-GA',
                    label: 'For HF (Higher Preparatory Exam -Denmark)',
                    originalLabel: 'For HF (Higher Preparatory Exam -Denmark)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DK-GB',
                    label: 'For HHX (Higher Commercial Exam -Denmark)',
                    originalLabel: 'For HHX (Higher Commercial Exam -Denmark)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DK-GC',
                    label: 'For HTX (Higher Technical Exam -Denmark)',
                    originalLabel: 'For HTX (Higher Technical Exam -Denmark)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-DK-GD',
                    label: 'For STX (Students’ Exam -Denmark)',
                    originalLabel: 'For STX (Students’ Exam -Denmark)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-DK-H',
                label: 'For vocational education and training (Denmark)',
                originalLabel:
                  'For vocational education and training (Denmark)',
                alternativeLabels: [],
                note: 'Class here: VET',
                related: [],
                children: [],
              },
              {
                id: '4Z-DK-K',
                label: 'For college and professional training (Denmark)',
                originalLabel:
                  'For college and professional training (Denmark)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-DK-N',
                label: 'For higher education, universities (Denmark)',
                originalLabel: 'For higher education, universities (Denmark)',
                alternativeLabels: [],
                note: 'Use for: undergraduate and graduate studies',
                related: [],
                children: [],
              },
              {
                id: '4Z-DK-V',
                label: 'For adult vocational education and training (Denmark)',
                originalLabel:
                  'For adult vocational education and training (Denmark)',
                alternativeLabels: [],
                note: 'Class here: AMU',
                related: [],
                children: [],
              },
              {
                id: '4Z-DK-X',
                label: 'For continuing education (Denmark)',
                originalLabel: 'For continuing education (Denmark)',
                alternativeLabels: [],
                note: 'Class here: diplomas, training academy and further education courses',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '4Z-DO-',
            label:
              'For pensum knyttet til utdanningssystemet i Den dominikanske republikk',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Den dominikanske republikk',
            alternativeLabels: [],
            note: 'Brukes med: andre kvalifikatorer for pedagogisk formål for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-EC-',
            label: 'For pensum knyttet til utdanningssystemet i Ecuador',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Ecuador',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-EC-A',
                label: 'For pre-school education (Ecuador)',
                originalLabel: 'For pre-school education (Ecuador)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-EC-B',
                label: 'Basic General Education (Ecuador)',
                originalLabel: 'Basic General Education (Ecuador)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-EC-C',
                label: 'Unified General Baccalaureate (Ecuador)',
                originalLabel: 'Unified General Baccalaureate (Ecuador)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '4Z-ES-',
            label: 'For pensum knyttet til utdanningssystemet i Spania',
            originalLabel: 'For pensum knyttet til utdanningssystemet i Spania',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-ES-A',
                label: 'For general education (Spain)',
                originalLabel: 'For general education (Spain)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-ES-AA',
                    label: 'For pre-school learning (Spain)',
                    originalLabel: 'For pre-school learning (Spain)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-ES-AB',
                    label: 'For primary education (Spain)',
                    originalLabel: 'For primary education (Spain)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-ES-AC',
                    label: 'For mandatory secondary education (Spain)',
                    originalLabel: 'For mandatory secondary education (Spain)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-ES-AD',
                    label:
                      'For optional / advanced secondary education (Spain)',
                    originalLabel:
                      'For optional / advanced secondary education (Spain)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-ES-AE',
                    label: 'For vocational / professional education (Spain)',
                    originalLabel:
                      'For vocational / professional education (Spain)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-ES-AF',
                    label:
                      'For higher / tertiary / university education (Spain)',
                    originalLabel:
                      'For higher / tertiary / university education (Spain)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-ES-B',
                label: 'For languages education (Spain)',
                originalLabel: 'For languages education (Spain)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-ES-C',
                label: 'For music education (Spain)',
                originalLabel: 'For music education (Spain)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-ES-D',
                label: 'For dance education (Spain)',
                originalLabel: 'For dance education (Spain)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-ES-E',
                label: 'For dramatic arts education (Spain)',
                originalLabel: 'For dramatic arts education (Spain)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-ES-F',
                label: 'For arts and design education (Spain)',
                originalLabel: 'For arts and design education (Spain)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-ES-G',
                label: 'For sports education (Spain)',
                originalLabel: 'For sports education (Spain)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-ES-H',
                label: 'For adult education (Spain)',
                originalLabel: 'For adult education (Spain)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-ES-J',
                label: 'For special education (Spain)',
                originalLabel: 'For special education (Spain)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-ES-K',
                label: 'For compensatory education (Spain)',
                originalLabel: 'For compensatory education (Spain)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-ES-L',
                label: 'For social guarantee programs (Spain)',
                originalLabel: 'For social guarantee programs (Spain)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-ES-X',
                label: 'For other educational levels or types (Spain)',
                originalLabel: 'For other educational levels or types (Spain)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '4Z-FI-',
            label: 'For pensum knyttet til utdanningssystemet i Finland',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Finland',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-FI-A',
                label: 'Pre-primary education (Finland)',
                originalLabel: 'Pre-primary education (Finland)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-FI-B',
                label: 'Basic education (Finland)',
                originalLabel: 'Basic education (Finland)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-FI-BA',
                    label: 'Basic Education Grades 1­–6 (Finland)',
                    originalLabel: 'Basic Education Grades 1­–6 (Finland)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-FI-BB',
                    label: 'Basic Education Grades 7–9 (Finland)',
                    originalLabel: 'Basic Education Grades 7–9 (Finland)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-FI-BD',
                    label: 'Basic Education – OPS2016 (Finland)',
                    originalLabel: 'Basic Education – OPS2016 (Finland)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-FI-C',
                label: 'General upper secondary education (Finland)',
                originalLabel: 'General upper secondary education (Finland)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-FI-CB',
                    label: 'General upper secondary education – LOPS2016',
                    originalLabel:
                      'General upper secondary education – LOPS2016',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-FI-CC',
                    label: 'General upper secondary education – LOPS2021',
                    originalLabel:
                      'General upper secondary education – LOPS2021',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-FI-D',
                label: 'Vocational upper secondary education (Finland)',
                originalLabel: 'Vocational upper secondary education (Finland)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '4Z-FR-',
            label: 'For pensum knyttet til utdanningssystemet i Frankrike',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Frankrike',
            alternativeLabels: [],
            note: 'Brukes med: andre kvalifikatorer for pedagogisk formål for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-GB-',
            label: 'For pensum knyttet til utdanningssystemet i Storbritannia',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Storbritannia',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-GB-A',
                label:
                  'For pensum knyttet til utdanningsssystemet i England og Wales',
                originalLabel:
                  'For pensum knyttet til utdanningsssystemet i England og Wales',
                alternativeLabels: [],
                note: 'Brukes også for læremidler ment kun for engelsk pensum',
                related: [],
                children: [
                  {
                    id: '4Z-GB-AC',
                    label: 'For National Curriculum (England and Wales)',
                    originalLabel:
                      'For National Curriculum (England and Wales)',
                    alternativeLabels: [],
                    note: 'Use for: works that are also aimed at the England-only national curriculum. Se også 4Z-GB-W <a href="/thema/nb/4Z-GB-W">For pensum knyttet til utdanningssystemet i Wales</a>',
                    related: [],
                    children: [
                      {
                        id: '4Z-GB-ACA',
                        label:
                          'For National Curriculum Early Years (England and Wales)',
                        originalLabel:
                          'For National Curriculum Early Years (England and Wales)',
                        alternativeLabels: [],
                        note: 'Class here: Early Years Foundation Stage (EYFS), Reception',
                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-ACF',
                        label:
                          'For National Curriculum Key Stage 1 (England and Wales)',
                        originalLabel:
                          'For National Curriculum Key Stage 1 (England and Wales)',
                        alternativeLabels: [],
                        note: 'Class here: Year 1, Year 2',
                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-ACJ',
                        label:
                          'For National Curriculum Key Stage 2 (England and Wales)',
                        originalLabel:
                          'For National Curriculum Key Stage 2 (England and Wales)',
                        alternativeLabels: [],
                        note: 'Class here: Year 3, Year 4, Year 5, Year 6',
                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-ACL',
                        label: 'Eleven Plus (11+) exam',
                        originalLabel: 'Eleven Plus (11+) exam',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-ACN',
                        label:
                          'For National Curriculum Key Stage 3 (England and Wales)',
                        originalLabel:
                          'For National Curriculum Key Stage 3 (England and Wales)',
                        alternativeLabels: [],
                        note: 'Class here: Year 7, Year 8, Year 9',
                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-ACT',
                        label:
                          'For National Curriculum Key Stage 4 and GCSE (England and Wales)',
                        originalLabel:
                          'For National Curriculum Key Stage 4 and GCSE (England and Wales)',
                        alternativeLabels: [],
                        note: 'Class here: Year 10, Year 11',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-GB-AL',
                    label:
                      'Designed / suitable for A and AS Level (England and Wales)',
                    originalLabel:
                      'Designed / suitable for A and AS Level (England and Wales)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-GB-E',
                label: 'For U.K. Exam boards',
                originalLabel: 'For U.K. Exam boards',
                alternativeLabels: [],
                note: 'Se også 4Z-GB-VT <a href="/thema/nb/4Z-GB-VT">For UK Awarding Bodies in vocational, technical and professional education</a>',
                related: [],
                children: [
                  {
                    id: '4Z-GB-EA',
                    label: 'AQA – Assessment and Qualifications Alliance',
                    originalLabel:
                      'AQA – Assessment and Qualifications Alliance',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-EC',
                    label:
                      'CCEA – Council for the Curriculum, Examinations and Assessment (Northern Ireland)',
                    originalLabel:
                      'CCEA – Council for the Curriculum, Examinations and Assessment (Northern Ireland)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-ED',
                    label:
                      'ICAAE – International Curriculum and Assessment Agency Examinations',
                    originalLabel:
                      'ICAAE – International Curriculum and Assessment Agency Examinations',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-EE',
                    label: 'Edexcel',
                    originalLabel: 'Edexcel',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-EF',
                    label: 'CIE – Cambridge International Examinations',
                    originalLabel: 'CIE – Cambridge International Examinations',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-ER',
                    label: 'OCR – Oxford, Cambridge and RSA Examinations',
                    originalLabel:
                      'OCR – Oxford, Cambridge and RSA Examinations',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-ES',
                    label: 'SQA – Scottish Qualifications Authority',
                    originalLabel: 'SQA – Scottish Qualifications Authority',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-EW',
                    label:
                      'WJEC / CBAC (and Eduqas) – Welsh Joint Education Committee',
                    originalLabel:
                      'WJEC / CBAC (and Eduqas) – Welsh Joint Education Committee',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-GB-N',
                label:
                  'For pensum knyttet til utdanningssystemet i Nord-Irland',
                originalLabel:
                  'For pensum knyttet til utdanningssystemet i Nord-Irland',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-GB-NC',
                    label: 'For National Curriculum (Northern Ireland)',
                    originalLabel: 'For National Curriculum (Northern Ireland)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-GB-NCA',
                        label:
                          'For National Curriculum Early Years / Foundation Stage (Northern Ireland)',
                        originalLabel:
                          'For National Curriculum Early Years / Foundation Stage (Northern Ireland)',
                        alternativeLabels: [],
                        note: 'Class here: P1 and/or P2',
                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-NCF',
                        label:
                          'For National Curriculum Key Stage 1 (Northern Ireland)',
                        originalLabel:
                          'For National Curriculum Key Stage 1 (Northern Ireland)',
                        alternativeLabels: [],
                        note: 'Class here: P3 and/or P4',
                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-NCJ',
                        label:
                          'For National Curriculum Key Stage 2 (Northern Ireland)',
                        originalLabel:
                          'For National Curriculum Key Stage 2 (Northern Ireland)',
                        alternativeLabels: [],
                        note: 'Class here: P5, P6 and/or P7',
                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-NCN',
                        label:
                          'For National Curriculum Key Stage 3 (Northern Ireland)',
                        originalLabel:
                          'For National Curriculum Key Stage 3 (Northern Ireland)',
                        alternativeLabels: [],
                        note: 'Class here: Year 8, 9 and/or 10',
                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-NCT',
                        label:
                          'For National Curriculum Key Stage 4 and GCSE (Northern Ireland)',
                        originalLabel:
                          'For National Curriculum Key Stage 4 and GCSE (Northern Ireland)',
                        alternativeLabels: [],
                        note: 'Class here: Year 11 and/or 12',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-GB-NL',
                    label:
                      'Designed / suitable for A and AS Level (Northern Ireland)',
                    originalLabel:
                      'Designed / suitable for A and AS Level (Northern Ireland)',
                    alternativeLabels: [],
                    note: 'Class here: Sixth form, year 13 or 14, Key Stage 5, GCE Advanced level',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-NV',
                    label:
                      'For vocational or technical qualifications (Northern Ireland)',
                    originalLabel:
                      'For vocational or technical qualifications (Northern Ireland)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-GB-S',
                label: 'For pensum knyttet til utdanningssystemet i Skottland',
                originalLabel:
                  'For pensum knyttet til utdanningssystemet i Skottland',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-GB-SA',
                    label: 'For Scottish Curriculum (P1 to S3)',
                    originalLabel: 'For Scottish Curriculum (P1 to S3)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-GB-SAA',
                        label: 'For Scottish Primary Curriculum – Early Level',
                        originalLabel:
                          'For Scottish Primary Curriculum – Early Level',
                        alternativeLabels: [],
                        note: 'Class here: Nursery, P1',
                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-SAB',
                        label: 'For Scottish Primary Curriculum – First Level',
                        originalLabel:
                          'For Scottish Primary Curriculum – First Level',
                        alternativeLabels: [],
                        note: 'Class here: P2, P3 and/or P4',
                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-SAC',
                        label: 'For Scottish Primary Curriculum – Second Level',
                        originalLabel:
                          'For Scottish Primary Curriculum – Second Level',
                        alternativeLabels: [],
                        note: 'Class here: P5, P6 and/or P7',
                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-SAD',
                        label:
                          'For Scottish Secondary Curriculum – Third Level',
                        originalLabel:
                          'For Scottish Secondary Curriculum – Third Level',
                        alternativeLabels: [],
                        note: 'Class here: S1 and/or S2',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-GB-SB',
                    label: 'For Scottish Curriculum National 4',
                    originalLabel: 'For Scottish Curriculum National 4',
                    alternativeLabels: [],
                    note: 'Class here: S3 and/or S4',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-SD',
                    label: 'For Scottish Curriculum National 5',
                    originalLabel: 'For Scottish Curriculum National 5',
                    alternativeLabels: [],
                    note: 'Class here: S4 and/or S5',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-SE',
                    label: 'For Scottish Curriculum Intermediate 1',
                    originalLabel: 'For Scottish Curriculum Intermediate 1',
                    alternativeLabels: [],
                    note: 'No longer used. Prefer: 4Z-GB-SB. Se også 4Z-GB-SB <a href="/thema/nb/4Z-GB-SB">For Scottish Curriculum National 4</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-SG',
                    label: 'For Scottish Curriculum Intermediate 2',
                    originalLabel: 'For Scottish Curriculum Intermediate 2',
                    alternativeLabels: [],
                    note: 'No longer used. Prefer: 4Z-GB-SD. Se også 4Z-GB-SD <a href="/thema/nb/4Z-GB-SD">For Scottish Curriculum National 5</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-SK',
                    label: 'For Scottish Curriculum Higher',
                    originalLabel: 'For Scottish Curriculum Higher',
                    alternativeLabels: [],
                    note: 'Class here: S5 and/or S6',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-SL',
                    label: 'For Scottish Curriculum Advanced Higher',
                    originalLabel: 'For Scottish Curriculum Advanced Higher',
                    alternativeLabels: [],
                    note: 'Class here: S6',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-GB-V',
                label:
                  'For UK vocational courses, certificates, qualifications and diplomas',
                originalLabel:
                  'For UK vocational courses, certificates, qualifications and diplomas',
                alternativeLabels: [],
                note: 'Class here: qualifications for apprenticeships',
                related: [],
                children: [
                  {
                    id: '4Z-GB-VC',
                    label: 'For Vocational Qualifications (England)',
                    originalLabel: 'For Vocational Qualifications (England)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-GB-VCT',
                        label: 'Designed / suitable for T Levels (England)',
                        originalLabel:
                          'Designed / suitable for T Levels (England)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-GB-VN',
                    label:
                      'For vocational qualifications (England, Wales, Northern Ireland)',
                    originalLabel:
                      'For vocational qualifications (England, Wales, Northern Ireland)',
                    alternativeLabels: [],
                    note: 'Use for: Regulated Qualifications Framework RQF (England and Northern Ireland) plus CQFW (Wales) levels',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-VS',
                    label:
                      'For Scottish vocational qualifications (and apprenticeship)',
                    originalLabel:
                      'For Scottish vocational qualifications (and apprenticeship)',
                    alternativeLabels: [],
                    note: 'Use for: titles suitable for current Scottish vocational qualifications developed by Sector Skills Councils and accredited by Scottish Qualifications Authority (SQA) and Scottish Credit and Qualifications Framework: SCQF',
                    related: [],
                    children: [
                      {
                        id: '4Z-GB-VSA',
                        label:
                          'For Scottish vocational qualifications – Level 1',
                        originalLabel:
                          'For Scottish vocational qualifications – Level 1',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-VSB',
                        label:
                          'For Scottish vocational qualifications – Level 2',
                        originalLabel:
                          'For Scottish vocational qualifications – Level 2',
                        alternativeLabels: [],
                        note: 'Class here: Modern Apprenticeships',
                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-VSC',
                        label:
                          'For Scottish vocational qualifications – Level 3',
                        originalLabel:
                          'For Scottish vocational qualifications – Level 3',
                        alternativeLabels: [],
                        note: 'Class here: Modern Apprenticeships',
                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-VSD',
                        label:
                          'For Scottish Vocational Qualifications – Level 4',
                        originalLabel:
                          'For Scottish Vocational Qualifications – Level 4',
                        alternativeLabels: [],
                        note: 'Class here: Technical Apprenticeships',
                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-VSE',
                        label:
                          'For Scottish vocational qualifications – Level 5',
                        originalLabel:
                          'For Scottish vocational qualifications – Level 5',
                        alternativeLabels: [],
                        note: 'Class here: Professional Apprenticeships',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-GB-VT',
                    label:
                      'For UK Awarding Bodies in vocational, technical and professional education',
                    originalLabel:
                      'For UK Awarding Bodies in vocational, technical and professional education',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-GB-VTA',
                        label:
                          'For BTEC (Business And Technology Education Council)',
                        originalLabel:
                          'For BTEC (Business And Technology Education Council)',
                        alternativeLabels: [],
                        note: 'Class here/ BTEC Nationals, BTEC Firsts',
                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-VTB',
                        label: 'For CACHE / NCFE qualification',
                        originalLabel: 'For CACHE / NCFE qualification',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-VTC',
                        label: 'For Cambridge Nationals',
                        originalLabel: 'For Cambridge Nationals',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-GB-VTD',
                        label: 'For City and Guilds qualifications',
                        originalLabel: 'For City and Guilds qualifications',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '4Z-GB-W',
                label: 'For pensum knyttet til utdanningssystemet i Wales',
                originalLabel:
                  'For pensum knyttet til utdanningssystemet i Wales',
                alternativeLabels: [],
                note: 'For bøker som kun skal brukes i utdanningssystemet i Wales',
                related: [],
                children: [
                  {
                    id: '4Z-GB-WB',
                    label: 'For primary curriculum (Wales)',
                    originalLabel: 'For primary curriculum (Wales)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-WD',
                    label: 'For secondary curriculum (Wales)',
                    originalLabel: 'For secondary curriculum (Wales)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-WF',
                    label: 'Designed / suitable for GCSE (Wales)',
                    originalLabel: 'Designed / suitable for GCSE (Wales)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-WH',
                    label: 'Designed / suitable for A and AS Level (Wales)',
                    originalLabel:
                      'Designed / suitable for A and AS Level (Wales)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-GB-WV',
                    label: 'For Welsh vocational or technical qualifications',
                    originalLabel:
                      'For Welsh vocational or technical qualifications',
                    alternativeLabels: [],
                    note: 'Use with: 4Z-GB-VN* codes to indicate level as appropriate',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '4Z-GT-',
            label: 'For pensum knyttet til utdanningssystemet i Guatemala',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Guatemala',
            alternativeLabels: [],
            note: 'Brukes med: andre kvalifikatorer for pedagogisk formål for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-HN-',
            label: 'For pensum knyttet til utdanningssystemet i Honduras',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Honduras',
            alternativeLabels: [],
            note: 'Brukes med: andre kvalifikatorer for pedagogisk formål for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-HU-',
            label: 'For pensum knyttet til utdanningssystemet i Ungarn',
            originalLabel: 'For pensum knyttet til utdanningssystemet i Ungarn',
            alternativeLabels: [],
            note: 'Brukes med: andre kvalifikatorer for pedagogisk formål for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-IE-',
            label: 'For pensum knyttet til utdanningssystemet i Irland',
            originalLabel: 'For pensum knyttet til utdanningssystemet i Irland',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-IE-P',
                label: 'For Irish primary level curriculum',
                originalLabel: 'For Irish primary level curriculum',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-IE-S',
                label: 'For Irish secondary level curriculum',
                originalLabel: 'For Irish secondary level curriculum',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-IE-SJ',
                    label: 'For Irish Junior Certificate curriculum',
                    originalLabel: 'For Irish Junior Certificate curriculum',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IE-SL',
                    label: 'For Irish Leaving Certificate curriculum',
                    originalLabel: 'For Irish Leaving Certificate curriculum',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '4Z-IN-',
            label: 'For pensum knyttet til utdanningssystemet i India',
            originalLabel: 'For pensum knyttet til utdanningssystemet i India',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-IN-A',
                label: 'For Primary education (India)',
                originalLabel: 'For Primary education (India)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-IN-AA',
                    label: 'For Class 1 (India)',
                    originalLabel: 'For Class 1 (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-AB',
                    label: 'For Class 2 (India)',
                    originalLabel: 'For Class 2 (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-AC',
                    label: 'For Class 3 (India)',
                    originalLabel: 'For Class 3 (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-AD',
                    label: 'For Class 4 (India)',
                    originalLabel: 'For Class 4 (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-AE',
                    label: 'For Class 5 (India)',
                    originalLabel: 'For Class 5 (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IN-B',
                label: 'For Upper Primary education (India)',
                originalLabel: 'For Upper Primary education (India)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-IN-BA',
                    label: 'For Class 6 (India)',
                    originalLabel: 'For Class 6 (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-BB',
                    label: 'For Class 7 (India)',
                    originalLabel: 'For Class 7 (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-BC',
                    label: 'For Class 8 (India)',
                    originalLabel: 'For Class 8 (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IN-C',
                label: 'For Secondary education (India)',
                originalLabel: 'For Secondary education (India)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-IN-CA',
                    label: 'For Class 9 (India)',
                    originalLabel: 'For Class 9 (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-CB',
                    label: 'For Class 10 (India)',
                    originalLabel: 'For Class 10 (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IN-D',
                label: 'For Senior Secondary education (India)',
                originalLabel: 'For Senior Secondary education (India)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-IN-DA',
                    label: 'For Class 11 (India)',
                    originalLabel: 'For Class 11 (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-DB',
                    label: 'For Class 12 (India)',
                    originalLabel: 'For Class 12 (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IN-E',
                label: 'For School Exam Boards (India)',
                originalLabel: 'For School Exam Boards (India)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-IN-EB',
                    label:
                      'Central Board of Secondary Education (CBSE) Curriculum',
                    originalLabel:
                      'Central Board of Secondary Education (CBSE) Curriculum',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-ED',
                    label:
                      'Indian School Certificate (ISC) and Indian Certificate of Secondary education (ICSE)',
                    originalLabel:
                      'Indian School Certificate (ISC) and Indian Certificate of Secondary education (ICSE)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-EG',
                    label: 'National Talent Search Examination (NTSE)',
                    originalLabel: 'National Talent Search Examination (NTSE)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-EJ',
                    label: 'For Olympiad Exams',
                    originalLabel: 'For Olympiad Exams',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-EN',
                    label:
                      'National Council of Educational Research and Training (NCERT)',
                    originalLabel:
                      'National Council of Educational Research and Training (NCERT)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IN-F',
                label:
                  'For Indian State level education, exams and qualifications',
                originalLabel:
                  'For Indian State level education, exams and qualifications',
                alternativeLabels: [],
                note: 'Use with: Other Educational qualifiers as appropriate and PLACE QUALIFIER 1FKA-IN-* to indicate State',
                related: [],
                children: [
                  {
                    id: '4Z-IN-FB',
                    label: 'For Indian State Level School Boards',
                    originalLabel: 'For Indian State Level School Boards',
                    alternativeLabels: [],
                    note: 'Use with: Other Educational qualifiers as appropriate and PLACE QUALIFIER 1FKA-IN-* to indicate State',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-FD',
                    label: 'For Indian State Level Public Service Exams',
                    originalLabel:
                      'For Indian State Level Public Service Exams',
                    alternativeLabels: [],
                    note: 'Use with: Other Educational qualifiers as appropriate and PLACE QUALIFIER 1FKA-IN-* to indicate State',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-FF',
                    label:
                      'For Indian State Level Vocational / Professional Exams and qualifications',
                    originalLabel:
                      'For Indian State Level Vocational / Professional Exams and qualifications',
                    alternativeLabels: [],
                    note: 'Use with: Other Educational qualifiers as appropriate and PLACE QUALIFIER 1FKA-IN-* to indicate State',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-FH',
                    label: 'For Indian State level Recruitment tests or exams',
                    originalLabel:
                      'For Indian State level Recruitment tests or exams',
                    alternativeLabels: [],
                    note: 'Use with: Other Educational qualifiers as appropriate and PLACE QUALIFIER 1FKA-IN-* to indicate State',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IN-G',
                label:
                  'For Engineering Qualifications / Entrance Exams (India)',
                originalLabel:
                  'For Engineering Qualifications / Entrance Exams (India)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-IN-GB',
                    label: 'For National Engineering Entrance Exams (India)',
                    originalLabel:
                      'For National Engineering Entrance Exams (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-IN-GBB',
                        label:
                          'Joint Entrance Examination (JEE) Main and JEE Advanced',
                        originalLabel:
                          'Joint Entrance Examination (JEE) Main and JEE Advanced',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-IN-GBD',
                        label:
                          'For Graduate Aptitude Test in Engineering (GATE)',
                        originalLabel:
                          'For Graduate Aptitude Test in Engineering (GATE)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-IN-GD',
                    label: 'For Regional Engineering Entrance Exams (India)',
                    originalLabel:
                      'For Regional Engineering Entrance Exams (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IN-H',
                label: 'For Medical Qualifications / Entrance Exams (India)',
                originalLabel:
                  'For Medical Qualifications / Entrance Exams (India)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-IN-HB',
                    label: 'National Eligibility cum Entrance Test (NEET-UG)',
                    originalLabel:
                      'National Eligibility cum Entrance Test (NEET-UG)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-HD',
                    label:
                      'All India Institute of Medical Science (AIIMS) and Jawaharlal Institute of Postgraduate Medical Education and Research (JIPMER)',
                    originalLabel:
                      'All India Institute of Medical Science (AIIMS) and Jawaharlal Institute of Postgraduate Medical Education and Research (JIPMER)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-HF',
                    label:
                      'Bachelor of Pharmacy (B. Pharma) and Nursing Entrance exam',
                    originalLabel:
                      'Bachelor of Pharmacy (B. Pharma) and Nursing Entrance exam',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IN-K',
                label:
                  'For Accounting, banking, finance and Insurance Qualifications / Exams (India)',
                originalLabel:
                  'For Accounting, banking, finance and Insurance Qualifications / Exams (India)',
                alternativeLabels: [],
                note: 'Class here: CPA exams, ICWAI exams',
                related: [],
                children: [
                  {
                    id: '4Z-IN-KA',
                    label: 'For Chartered Accountant (CA) Exam (India)',
                    originalLabel: 'For Chartered Accountant (CA) Exam (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-KB',
                    label: 'Chartered Financial Accountant (CFA) Exam (India)',
                    originalLabel:
                      'Chartered Financial Accountant (CFA) Exam (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-KC',
                    label: 'For Actuaries Qualifications / Exams (India)',
                    originalLabel:
                      'For Actuaries Qualifications / Exams (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-KF',
                    label: 'For Banking Qualifications / Exams (India)',
                    originalLabel: 'For Banking Qualifications / Exams (India)',
                    alternativeLabels: [],
                    note: 'Class here: Junior Associate of Indian Institute of Bankers (JAIIB) and the Certified Associate of Indian Institute of Bankers (CAIIB), Indian Institute of Banking and Finance (IIBF), Bank Clerical Exams, Bank Specialist Officers Exams, Bank PO and MT Exams',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IN-L',
                label:
                  'For Law / Legal Professions Exams and qualifications (India)',
                originalLabel:
                  'For Law / Legal Professions Exams and qualifications (India)',
                alternativeLabels: [],
                note: 'Class here: Common Law Admission Test (CLAT)',
                related: [],
                children: [],
              },
              {
                id: '4Z-IN-M',
                label:
                  'For Management / Business Administration Exams / tests (India)',
                originalLabel:
                  'For Management / Business Administration Exams / tests (India)',
                alternativeLabels: [],
                note: 'Class here: Common Admission Test (CAT), Management Aptitude Test (MAT), Common Management Admissions Test (CMAT)',
                related: [],
                children: [],
              },
              {
                id: '4Z-IN-N',
                label:
                  'For Vocational, technical and professional Qualifications / Exams (India)',
                originalLabel:
                  'For Vocational, technical and professional Qualifications / Exams (India)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-IN-NA',
                    label:
                      'For Agriculture Entrance Exams / Pre-Veterinary and Fisheries Test (PVT)',
                    originalLabel:
                      'For Agriculture Entrance Exams / Pre-Veterinary and Fisheries Test (PVT)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IN-P',
                label: 'For Indian Defence Service Exams',
                originalLabel: 'For Indian Defence Service Exams',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-IN-PB',
                    label:
                      'National Defence Academy / Combined Defence Services (NDA / CDS)',
                    originalLabel:
                      'National Defence Academy / Combined Defence Services (NDA / CDS)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-PD',
                    label: 'Indian Airforce Recruitment tests',
                    originalLabel: 'Indian Airforce Recruitment tests',
                    alternativeLabels: [],
                    note: 'Class here: AFCAT',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-PE',
                    label: 'Indian Army Recruitment tests',
                    originalLabel: 'Indian Army Recruitment tests',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-PF',
                    label: 'Indian Navy Recruitment tests',
                    originalLabel: 'Indian Navy Recruitment tests',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IN-Q',
                label: 'For Civil Service Examinations (India)',
                originalLabel: 'For Civil Service Examinations (India)',
                alternativeLabels: [],
                note: 'Class here: Union Public Service Commission (USPC)',
                related: [],
                children: [
                  {
                    id: '4Z-IN-QB',
                    label:
                      'Civil Services Examination (CSE) / Indian Foreign Services (IFS) exams',
                    originalLabel:
                      'Civil Services Examination (CSE) / Indian Foreign Services (IFS) exams',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-QD',
                    label: 'Engineering Services Examination (ESE)',
                    originalLabel: 'Engineering Services Examination (ESE)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-QF',
                    label: 'For Staff Selection Commission (SSC) Exams',
                    originalLabel: 'For Staff Selection Commission (SSC) Exams',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-QT',
                    label:
                      'For Indian Teaching professions and research tests and exams',
                    originalLabel:
                      'For Indian Teaching professions and research tests and exams',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-IN-QTB',
                        label: 'Central Teacher Eligibility Test (CTET)',
                        originalLabel:
                          'Central Teacher Eligibility Test (CTET)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-IN-QTD',
                        label:
                          'Post-Graduate Teacher / Trained Graduate Teacher (PGT / TGT) Tests',
                        originalLabel:
                          'Post-Graduate Teacher / Trained Graduate Teacher (PGT / TGT) Tests',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-IN-QTF',
                        label: 'National Eligibility Tests (NET)',
                        originalLabel: 'National Eligibility Tests (NET)',
                        alternativeLabels: [],
                        note: 'Class here: UGC NET, NTA-UGC-NET, Eligibility for Assistant Professor only / Junior Research Fellowship, Council of Scientific and Industrial Research (CSIR), Central Board of Secondary Education (CBSE)',
                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-IN-QTH',
                        label: 'State Eligibility Tests (SET)',
                        originalLabel: 'State Eligibility Tests (SET)',
                        alternativeLabels: [],
                        note: 'Use with: PLACE QUALIFIER 1FKA-IN-* to indicate State',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '4Z-IN-R',
                label:
                  'For Indian Central government recruitment tests or entrance exams',
                originalLabel:
                  'For Indian Central government recruitment tests or entrance exams',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-IN-RB',
                    label: 'For Indian Railways Board Recruitment Exams',
                    originalLabel:
                      'For Indian Railways Board Recruitment Exams',
                    alternativeLabels: [],
                    note: 'Class here: RBB Non-Technical popular categories (NTPC), Railway Protection Force (RPF), RRB-JE',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-RD',
                    label:
                      'For Public Sector Undertakings (PSU) Recruitment exams',
                    originalLabel:
                      'For Public Sector Undertakings (PSU) Recruitment exams',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-RE',
                    label: 'For Indian Postal Service Recruitment Exams',
                    originalLabel:
                      'For Indian Postal Service Recruitment Exams',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-RF',
                    label: 'Life Insurance Corporation (LIC) recruitment exams',
                    originalLabel:
                      'Life Insurance Corporation (LIC) recruitment exams',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-RH',
                    label: 'For Ordnance Trade Apprentice recruitment tests',
                    originalLabel:
                      'For Ordnance Trade Apprentice recruitment tests',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-RJ',
                    label: 'For Intelligence Bureau (IB) Exams',
                    originalLabel: 'For Intelligence Bureau (IB) Exams',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-RK',
                    label:
                      'For Food Corporation of India (FCI) recruitment exams',
                    originalLabel:
                      'For Food Corporation of India (FCI) recruitment exams',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IN-T',
                label: 'For School Entrance Exams / Admission tests (India)',
                originalLabel:
                  'For School Entrance Exams / Admission tests (India)',
                alternativeLabels: [],
                note: 'Use for: any general guidance for passing an entrance exam or for institutions that do not have a specific code',
                related: [],
                children: [
                  {
                    id: '4Z-IN-TC',
                    label: 'Central Hindu School (CHS) Entrance Exam',
                    originalLabel: 'Central Hindu School (CHS) Entrance Exam',
                    alternativeLabels: [],
                    note: 'Use for: products specifically published with material for exams or tests for admission to or material to complete qualifications administered by this institution',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-TD',
                    label: 'Jawahar Navodya Vidyalya schools entrance exams',
                    originalLabel:
                      'Jawahar Navodya Vidyalya schools entrance exams',
                    alternativeLabels: [],
                    note: 'Use for: products specifically published with material for exams or tests for admission to or material to complete qualifications administered by these institutions',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-TN',
                    label:
                      'For Military Academies and Schools Entrance Exams /Admissions tests (India)',
                    originalLabel:
                      'For Military Academies and Schools Entrance Exams /Admissions tests (India)',
                    alternativeLabels: [],
                    note: 'Class here: Rashtriya Indian Military College (RIMC), Sainik School, Army Cadet College (ACC)',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IN-U',
                label: 'For University Entrance Exams and material (India)',
                originalLabel:
                  'For University Entrance Exams and material (India)',
                alternativeLabels: [],
                note: 'Use for: any general guidance for passing an entrance exam or for institutions that do not have a specific code. Use with: Place Qualifier as appropriate',
                related: [],
                children: [
                  {
                    id: '4Z-IN-UB',
                    label: 'For Aligarh Muslim university (AMU)',
                    originalLabel: 'For Aligarh Muslim university (AMU)',
                    alternativeLabels: [],
                    note: 'Use for: products specifically published with material for exams or tests for admission to or material to complete qualifications administered by this institution',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-UC',
                    label: 'For Allahabad Central University',
                    originalLabel: 'For Allahabad Central University',
                    alternativeLabels: [],
                    note: 'Use for: products specifically published with material for exams or tests for admission to or material to complete qualifications administered by this institution',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-UD',
                    label: 'For Banaras Hindu University (BHU)',
                    originalLabel: 'For Banaras Hindu University (BHU)',
                    alternativeLabels: [],
                    note: 'Use for: products specifically published with material for exams or tests for admission to or material to complete qualifications administered by this institution',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-UE',
                    label: 'For University of Delhi (DU)',
                    originalLabel: 'For University of Delhi (DU)',
                    alternativeLabels: [],
                    note: 'Use for: products specifically published with material for exams or tests for admission to or material to complete qualifications administered by this institution',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-UF',
                    label: 'For IP University',
                    originalLabel: 'For IP University',
                    alternativeLabels: [],
                    note: 'Class here: GGSIPU, Guru Gobind Singh Indraprastha University. Use for: products specifically published with material for exams or tests for admission to or material to complete qualifications administered by this institution',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-UG',
                    label: 'For Jamia Millia Islamia (JMI)',
                    originalLabel: 'For Jamia Millia Islamia (JMI)',
                    alternativeLabels: [],
                    note: 'Use for: products specifically published with material for exams or tests for admission to or material to complete qualifications administered by this institution',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-UH',
                    label: 'For Jawaharlal Nehru University (JNU)',
                    originalLabel: 'For Jawaharlal Nehru University (JNU)',
                    alternativeLabels: [],
                    note: 'Use for: products specifically published with material for exams or tests for admission to or material to complete qualifications administered by this institution',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IN-V',
                label: 'For Technical institutions and education (India)',
                originalLabel:
                  'For Technical institutions and education (India)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-IN-VB',
                    label:
                      'For Institutes of Technology qualifications and exams (India)',
                    originalLabel:
                      'For Institutes of Technology qualifications and exams (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-IN-VBT',
                        label:
                          'For Indian Institute of Technology Joint Admission Test (IIT JAM)',
                        originalLabel:
                          'For Indian Institute of Technology Joint Admission Test (IIT JAM)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-IN-VD',
                    label:
                      'For Industrial Training Institutes (ITI) qualifications and exams',
                    originalLabel:
                      'For Industrial Training Institutes (ITI) qualifications and exams',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IN-VF',
                    label: 'For Polytechnic qualifications and exams (India)',
                    originalLabel:
                      'For Polytechnic qualifications and exams (India)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '4Z-IT-',
            label: 'For pensum knyttet til utdanningssystemet i Italia',
            originalLabel: 'For pensum knyttet til utdanningssystemet i Italia',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-IT-A',
                label: 'For Italian high school and teacher training education',
                originalLabel:
                  'For Italian high school and teacher training education',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-IT-AC',
                    label: 'For Italian high school (with Classics specialism)',
                    originalLabel:
                      'For Italian high school (with Classics specialism)',
                    alternativeLabels: [],
                    note: 'Class here: ‘Liceo classico’',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-AD',
                    label: 'For Italian high school (with Science specialism)',
                    originalLabel:
                      'For Italian high school (with Science specialism)',
                    alternativeLabels: [],
                    note: 'Class here: ‘Liceo scientifico’',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-AG',
                    label:
                      'For Italian high school (with Languages specialism)',
                    originalLabel:
                      'For Italian high school (with Languages specialism)',
                    alternativeLabels: [],
                    note: 'Class here: ‘Liceo linguistico’',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-AH',
                    label:
                      'For Italian high school (aimed at developing European values and identity)',
                    originalLabel:
                      'For Italian high school (aimed at developing European values and identity)',
                    alternativeLabels: [],
                    note: 'Class here: ‘Liceo classico europeo’',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-AL',
                    label:
                      'For Italian high school (aimed at developing international language skills specific to the specialism chosen)',
                    originalLabel:
                      'For Italian high school (aimed at developing international language skills specific to the specialism chosen)',
                    alternativeLabels: [],
                    note: 'Class here: ‘Scuola superiore ad indirizzo opzione internazionale’',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-AN',
                    label:
                      'For Italian high school (with Humanities and Social science specialism)',
                    originalLabel:
                      'For Italian high school (with Humanities and Social science specialism)',
                    alternativeLabels: [],
                    note: 'Class here: ‘Scuola superiore ad indirizzo scienze sociali’',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-AP',
                    label:
                      'For other Italian high schools and educational curricula',
                    originalLabel:
                      'For other Italian high schools and educational curricula',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-AR',
                    label:
                      'For Italian teacher training (for former Italian primary school teacher training)',
                    originalLabel:
                      'For Italian teacher training (for former Italian primary school teacher training)',
                    alternativeLabels: [],
                    note: 'Class here: ‘Ex istituto magistrale’',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-AS',
                    label:
                      'For Italian teacher training (for former Italian nursery school teacher training)',
                    originalLabel:
                      'For Italian teacher training (for former Italian nursery school teacher training)',
                    alternativeLabels: [],
                    note: 'Class here: ‘Ex scuola magistrale’',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IT-C',
                label: 'For artistic education (Italy)',
                originalLabel: 'For artistic education (Italy)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-IT-CB',
                    label: 'For Italian high school (with Arts specialism)',
                    originalLabel:
                      'For Italian high school (with Arts specialism)',
                    alternativeLabels: [],
                    note: 'Class here: ‘Liceo artistico’',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-CE',
                    label: 'For Italian school of art / art college',
                    originalLabel: 'For Italian school of art / art college',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IT-E',
                label: 'For professional education (Italy)',
                originalLabel: 'For professional education (Italy)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-IT-EA',
                    label:
                      'For Italian professional institute of agriculture and environment',
                    originalLabel:
                      'For Italian professional institute of agriculture and environment',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-EC',
                    label:
                      'For Italian professional institute of industry and handicraft',
                    originalLabel:
                      'For Italian professional institute of industry and handicraft',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-EF',
                    label:
                      'For Italian professional institute of commerce and tourism',
                    originalLabel:
                      'For Italian professional institute of commerce and tourism',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-EH',
                    label: 'For Italian professional institute of advertising',
                    originalLabel:
                      'For Italian professional institute of advertising',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-EL',
                    label:
                      'For Italian professional institute of hotel management',
                    originalLabel:
                      'For Italian professional institute of hotel management',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-EN',
                    label:
                      'For Italian professional institute of social services',
                    originalLabel:
                      'For Italian professional institute of social services',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-EQ',
                    label:
                      'For Italian professional institute of auxiliary health services',
                    originalLabel:
                      'For Italian professional institute of auxiliary health services',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-ER',
                    label:
                      'For Italian professional institute of maritime activities',
                    originalLabel:
                      'For Italian professional institute of maritime activities',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-ES',
                    label: 'For other Italian professional institutes',
                    originalLabel: 'For other Italian professional institutes',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-IT-G',
                label: 'For technical education (Italy)',
                originalLabel: 'For technical education (Italy)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-IT-GB',
                    label:
                      'For Italian technical institute of commerce (chartered accounting)',
                    originalLabel:
                      'For Italian technical institute of commerce (chartered accounting)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-GD',
                    label:
                      'For Italian technical institute of business and foreign languages',
                    originalLabel:
                      'For Italian technical institute of business and foreign languages',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-GF',
                    label: 'For Italian technical institute of Industry',
                    originalLabel:
                      'For Italian technical institute of Industry',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-GH',
                    label: 'For Italian technical institute of agriculture',
                    originalLabel:
                      'For Italian technical institute of agriculture',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-GL',
                    label: 'For Italian technical institute of surveying',
                    originalLabel:
                      'For Italian technical institute of surveying',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-GM',
                    label:
                      'For Italian technical institute of nautical studies',
                    originalLabel:
                      'For Italian technical institute of nautical studies',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-GP',
                    label: 'For Italian technical institute of aeronautics',
                    originalLabel:
                      'For Italian technical institute of aeronautics',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-GT',
                    label: 'For Italian technical institute of tourism',
                    originalLabel: 'For Italian technical institute of tourism',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-IT-GZ',
                    label: 'For other Italian technical institutes',
                    originalLabel: 'For other Italian technical institutes',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '4Z-JP-',
            label: 'For pensum knyttet til utdanningssystemet i Japan',
            originalLabel: 'For pensum knyttet til utdanningssystemet i Japan',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-JP-A',
                label: 'For pre-school learning (Japan)',
                originalLabel: 'For pre-school learning (Japan)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-JP-AA',
                    label: 'For nursery education (Japan)',
                    originalLabel: 'For nursery education (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-AB',
                    label:
                      'For ECEC – Early Childhood Education and Care centres (Japan)',
                    originalLabel:
                      'For ECEC – Early Childhood Education and Care centres (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-AC',
                    label: 'For kindergarten (Japan)',
                    originalLabel: 'For kindergarten (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-JP-ACA',
                        label: 'For the first year of kindergarten (Japan)',
                        originalLabel:
                          'For the first year of kindergarten (Japan)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-JP-ACB',
                        label: 'For the second year of kindergarten (Japan)',
                        originalLabel:
                          'For the second year of kindergarten (Japan)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-JP-ACC',
                        label: 'For the third year of kindergarten (Japan)',
                        originalLabel:
                          'For the third year of kindergarten (Japan)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '4Z-JP-B',
                label: 'For primary school learning (Japan)',
                originalLabel: 'For primary school learning (Japan)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-JP-BA',
                    label: 'For primary school learning year 1 (Japan)',
                    originalLabel: 'For primary school learning year 1 (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-BB',
                    label: 'For primary school learning year 2 (Japan)',
                    originalLabel: 'For primary school learning year 2 (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-BC',
                    label: 'For primary school learning year 3 (Japan)',
                    originalLabel: 'For primary school learning year 3 (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-BD',
                    label: 'For primary school learning year 4 (Japan)',
                    originalLabel: 'For primary school learning year 4 (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-BE',
                    label: 'For primary school learning year 5 (Japan)',
                    originalLabel: 'For primary school learning year 5 (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-BF',
                    label: 'For primary school learning year 6 (Japan)',
                    originalLabel: 'For primary school learning year 6 (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-JP-C',
                label: 'For junior high school learning (Japan)',
                originalLabel: 'For junior high school learning (Japan)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-JP-CA',
                    label: 'For junior high school learning year 1 (Japan)',
                    originalLabel:
                      'For junior high school learning year 1 (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-CB',
                    label: 'For junior high school learning year 2 (Japan)',
                    originalLabel:
                      'For junior high school learning year 2 (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-CC',
                    label: 'For junior high school learning year 3 (Japan)',
                    originalLabel:
                      'For junior high school learning year 3 (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-JP-D',
                label: 'For high school / technical college learning (Japan)',
                originalLabel:
                  'For high school / technical college learning (Japan)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-JP-DA',
                    label: 'For high school learning – general course (Japan)',
                    originalLabel:
                      'For high school learning – general course (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-JP-DAA',
                        label:
                          'For high school learning – general course year 1 (Japan)',
                        originalLabel:
                          'For high school learning – general course year 1 (Japan)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-JP-DAB',
                        label:
                          'For high school learning – general course year 2 (Japan)',
                        originalLabel:
                          'For high school learning – general course year 2 (Japan)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-JP-DAC',
                        label:
                          'For high school learning – general course year 3 (Japan)',
                        originalLabel:
                          'For high school learning – general course year 3 (Japan)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-JP-DB',
                    label:
                      'For high school learning – specialised course (Japan)',
                    originalLabel:
                      'For high school learning – specialised course (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-JP-DBA',
                        label:
                          'For high school learning – specialised course year 1 (Japan)',
                        originalLabel:
                          'For high school learning – specialised course year 1 (Japan)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-JP-DBB',
                        label:
                          'For high school learning – specialised course year 2 (Japan)',
                        originalLabel:
                          'For high school learning – specialised course year 2 (Japan)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-JP-DBC',
                        label:
                          'For high school learning – specialised course year 3 (Japan)',
                        originalLabel:
                          'For high school learning – specialised course year 3 (Japan)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-JP-DC',
                    label: 'For technical college learning (Japan)',
                    originalLabel: 'For technical college learning (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-JP-E',
                label: 'For special needs education (Japan)',
                originalLabel: 'For special needs education (Japan)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-JP-EA',
                    label:
                      'For special needs education – pre-school section (Japan)',
                    originalLabel:
                      'For special needs education – pre-school section (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-EB',
                    label:
                      'For special needs education – elementary school section (Japan)',
                    originalLabel:
                      'For special needs education – elementary school section (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-EC',
                    label:
                      'For special needs education – junior high school section (Japan)',
                    originalLabel:
                      'For special needs education – junior high school section (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-ED',
                    label:
                      'For special needs education – senior high school section (Japan)',
                    originalLabel:
                      'For special needs education – senior high school section (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-JP-F',
                label:
                  'For Institution under the control of the government (Japan)',
                originalLabel:
                  'For Institution under the control of the government (Japan)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-JP-G',
                label: 'For university / college learning (Japan)',
                originalLabel: 'For university / college learning (Japan)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-JP-H',
                label: 'For graduate school learning (Japan)',
                originalLabel: 'For graduate school learning (Japan)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-JP-K',
                label: 'For Japanese Entrance and other exams',
                originalLabel: 'For Japanese Entrance and other exams',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-JP-KA',
                    label: 'For kindergarten entrance examination (Japan)',
                    originalLabel:
                      'For kindergarten entrance examination (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-KB',
                    label: 'For primary school entrance examination (Japan)',
                    originalLabel:
                      'For primary school entrance examination (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-KC',
                    label:
                      'For junior high school entrance examination (Japan)',
                    originalLabel:
                      'For junior high school entrance examination (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-KD',
                    label:
                      'For Test of Lower Secondary School Graduation Certificate (Japan)',
                    originalLabel:
                      'For Test of Lower Secondary School Graduation Certificate (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-KE',
                    label:
                      'For high school / technical entrance examination (Japan)',
                    originalLabel:
                      'For high school / technical entrance examination (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-KF',
                    label:
                      'For Test of Upper Secondary School Graduation Certificate examination (Japan)',
                    originalLabel:
                      'For Test of Upper Secondary School Graduation Certificate examination (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-KH',
                    label:
                      'For Institution under the control of the government entrance examination (Japan)',
                    originalLabel:
                      'For Institution under the control of the government entrance examination (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-JP-KJ',
                    label:
                      'For university / college entrance examination (Japan)',
                    originalLabel:
                      'For university / college entrance examination (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-JP-KJA',
                        label:
                          'For National Centre Test for University Admissions (Japan)',
                        originalLabel:
                          'For National Centre Test for University Admissions (Japan)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-JP-KK',
                    label: 'For graduate school entrance examination (Japan)',
                    originalLabel:
                      'For graduate school entrance examination (Japan)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '4Z-KR-',
            label: 'For pensum knyttet til utdanningssystemet i Korea',
            originalLabel: 'For pensum knyttet til utdanningssystemet i Korea',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-KR-A',
                label: 'For primary and secondary school curriculum (Korea)',
                originalLabel:
                  'For primary and secondary school curriculum (Korea)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-KR-AB',
                    label: 'For early education (Korea)',
                    originalLabel: 'For early education (Korea)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-KR-AC',
                    label: 'For kindergarten learning (Korea)',
                    originalLabel: 'For kindergarten learning (Korea)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-KR-AD',
                    label: 'For preliminary elementary school learning (Korea)',
                    originalLabel:
                      'For preliminary elementary school learning (Korea)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-KR-AE',
                    label: 'For elementary school learning (Korea)',
                    originalLabel: 'For elementary school learning (Korea)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-KR-AEA',
                        label: 'For elementary school learning year 1 (Korea)',
                        originalLabel:
                          'For elementary school learning year 1 (Korea)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-KR-AEB',
                        label: 'For elementary school learning year 2 (Korea)',
                        originalLabel:
                          'For elementary school learning year 2 (Korea)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-KR-AEC',
                        label: 'For elementary school learning year 3 (Korea)',
                        originalLabel:
                          'For elementary school learning year 3 (Korea)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-KR-AED',
                        label: 'For elementary school learning year 4 (Korea)',
                        originalLabel:
                          'For elementary school learning year 4 (Korea)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-KR-AEF',
                        label: 'For elementary school learning year 5 (Korea)',
                        originalLabel:
                          'For elementary school learning year 5 (Korea)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-KR-AEG',
                        label: 'For elementary school learning year 6 (Korea)',
                        originalLabel:
                          'For elementary school learning year 6 (Korea)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-KR-AF',
                    label: 'For preliminary middle school learning (Korea)',
                    originalLabel:
                      'For preliminary middle school learning (Korea)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-KR-AG',
                    label: 'For middle school learning (Korea)',
                    originalLabel: 'For middle school learning (Korea)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-KR-AGA',
                        label: 'For middle school learning year 1 (Korea)',
                        originalLabel:
                          'For middle school learning year 1 (Korea)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-KR-AGB',
                        label: 'For middle school learning year 2 (Korea)',
                        originalLabel:
                          'For middle school learning year 2 (Korea)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-KR-AGC',
                        label: 'For middle school learning year 3 (Korea)',
                        originalLabel:
                          'For middle school learning year 3 (Korea)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '4Z-KR-AH',
                    label: 'For preliminary high school learning (Korea)',
                    originalLabel:
                      'For preliminary high school learning (Korea)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-KR-AJ',
                    label: 'For high school learning (Korea)',
                    originalLabel: 'For high school learning (Korea)',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '4Z-KR-AJA',
                        label: 'For high school learning year 1 (Korea)',
                        originalLabel:
                          'For high school learning year 1 (Korea)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-KR-AJB',
                        label: 'For high school learning year 2 (Korea)',
                        originalLabel:
                          'For high school learning year 2 (Korea)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: '4Z-KR-AJC',
                        label: 'For high school learning year 3 (Korea)',
                        originalLabel:
                          'For high school learning year 3 (Korea)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '4Z-KR-B',
                label:
                  'For Korean vocational, professional qualifications, exams and tests',
                originalLabel:
                  'For Korean vocational, professional qualifications, exams and tests',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-KR-BA',
                    label: 'For Korean civil service exams',
                    originalLabel: 'For Korean civil service exams',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-KR-BE',
                    label:
                      'For Korean vocational, professional qualifications, exams and tests in economics, finance, management and logistics sector',
                    originalLabel:
                      'For Korean vocational, professional qualifications, exams and tests in economics, finance, management and logistics sector',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-KR-BH',
                    label:
                      'For Korean vocational, professional qualifications, exams and tests in law, humanity and social science sector',
                    originalLabel:
                      'For Korean vocational, professional qualifications, exams and tests in law, humanity and social science sector',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-KR-BI',
                    label:
                      'For Korean vocational, professional qualifications, exams and tests for medical experts and other health care sector',
                    originalLabel:
                      'For Korean vocational, professional qualifications, exams and tests for medical experts and other health care sector',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-KR-BJ',
                    label:
                      'For Korean recruitment exam, general knowledge, and aptitude tests',
                    originalLabel:
                      'For Korean recruitment exam, general knowledge, and aptitude tests',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-KR-BK',
                    label:
                      'For Korean entry exams for 4-year course college, school qualification exams, and self-education',
                    originalLabel:
                      'For Korean entry exams for 4-year course college, school qualification exams, and self-education',
                    alternativeLabels: [],
                    note: 'Class here: Korean High School Graduation Equivalency Examination (Korean GED)',
                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-KR-BL',
                    label: 'For Korean History Proficiency Test',
                    originalLabel: 'For Korean History Proficiency Test',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '4Z-MX-',
            label: 'For pensum knyttet til utdanningssystemet i Mexico',
            originalLabel: 'For pensum knyttet til utdanningssystemet i Mexico',
            alternativeLabels: [],
            note: 'Brukes med kvalifikatorer for pedagogisk formål for å angi nivå',
            related: [],
            children: [
              {
                id: '4Z-MX-A',
                label: 'For Preschool education (Mexico)',
                originalLabel: 'For Preschool education (Mexico)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-MX-B',
                label: 'For Primary education (Mexico)',
                originalLabel: 'For Primary education (Mexico)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-MX-C',
                label: 'For Secondary Education (Mexico)',
                originalLabel: 'For Secondary Education (Mexico)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-MX-D',
                label: 'Higher Secondary Education - Bachillerato (Mexico)',
                originalLabel:
                  'Higher Secondary Education - Bachillerato (Mexico)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-MX-E',
                label: 'For Higher education (Mexico)',
                originalLabel: 'For Higher education (Mexico)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-MX-F',
                label: 'For Vocational or technical education (Mexico)',
                originalLabel: 'For Vocational or technical education (Mexico)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '4Z-NI-',
            label: 'For pensum knyttet til utdanningssystemet i Nicaragua',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Nicaragua',
            alternativeLabels: [],
            note: 'Brukes med kvalifikatorer for pedagogisk formål for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-NO-',
            label: 'For norske læreplaner',
            originalLabel: 'For norske læreplaner',
            alternativeLabels: ['Norge'],

            related: [],
            children: [
              {
                id: '4Z-NO-A',
                label: 'KL06 Kunnskapsløftet',
                originalLabel: 'KL06 Kunnskapsløftet',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-NO-B',
                label: 'LK20 Kunnskapsløftet',
                originalLabel: 'LK20 Kunnskapsløftet',
                alternativeLabels: [],
                note: 'Her: Fagfornyelsen',
                related: [],
                children: [],
              },
              {
                id: '4Z-NO-C',
                label: 'LK20S Kunnskapsløftet (samiske læreplaner)',
                originalLabel: 'LK20S Kunnskapsløftet (samiske læreplaner)',
                alternativeLabels: [],
                note: 'Her: Fagfornyelsen',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '4Z-NZ-',
            label: 'For pensum knyttet til utdanningssystemet i New Zealand',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i New Zealand',
            alternativeLabels: [],
            note: 'Brukes med andre kvalifikatorer fra 4* for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-PA-',
            label: 'For pensum knyttet til utdanningssystemet i Panama',
            originalLabel: 'For pensum knyttet til utdanningssystemet i Panama',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-PA-A',
                label: 'Primary Education (Panama)',
                originalLabel: 'Primary Education (Panama)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-PA-B',
                label: 'Pre-secondary or secondary education (Panama)',
                originalLabel: 'Pre-secondary or secondary education (Panama)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-PA-C',
                label: 'Secondary education or Bachillerato (Panama)',
                originalLabel: 'Secondary education or Bachillerato (Panama)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '4Z-PE-',
            label: 'For pensum knyttet til utdanningssystemet i Peru',
            originalLabel: 'For pensum knyttet til utdanningssystemet i Peru',
            alternativeLabels: [],
            note: 'Brukes med andre kvalifikatorer fra 4* for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-PL-',
            label: 'For pensum knyttet til utdanningssystemet i Polen',
            originalLabel: 'For pensum knyttet til utdanningssystemet i Polen',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-PL-A',
                label: 'For Primary / elementary education (Poland)',
                originalLabel: 'For Primary / elementary education (Poland)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-PL-B',
                label: 'For Eighth grade exams (Poland)',
                originalLabel: 'For Eighth grade exams (Poland)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-PL-C',
                label: 'For secondary education (Poland)',
                originalLabel: 'For secondary education (Poland)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '4Z-PL-CA',
                    label: 'For Polish general secondary schools (liceum)',
                    originalLabel:
                      'For Polish general secondary schools (liceum)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-PL-CB',
                    label: 'For Polish technical secondary schools (technikum)',
                    originalLabel:
                      'For Polish technical secondary schools (technikum)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '4Z-PL-CD',
                    label: 'For Polish basic vocational schools',
                    originalLabel: 'For Polish basic vocational schools',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '4Z-PL-D',
                label: 'Polish vocational or professional exams',
                originalLabel: 'Polish vocational or professional exams',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-PL-E',
                label: 'Polish secondary school leaving exams (Matura)',
                originalLabel: 'Polish secondary school leaving exams (Matura)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '4Z-PT-',
            label: 'For pensum knyttet til utdanningssystemet i Portugal',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Portugal',
            alternativeLabels: [],
            note: 'Brukes med andre kvalifikatorer fra 4* for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-PY-',
            label: 'For pensum knyttet til utdanningssystemet i Paraguay',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Paraguay',
            alternativeLabels: [],
            note: 'Brukes med andre kvalifikatorer fra 4* for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-RO-',
            label: 'For pensum knyttet til utdanningssystemet i Romania',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Romania',
            alternativeLabels: [],
            note: 'Brukes med andre kvalifikatorer fra 4* for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-SG-',
            label: 'For pensum knyttet til utdanningssystemet i Singapore',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Singapore',
            alternativeLabels: [],
            note: 'Brukes med andre kvalifikatorer fra 4* for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-SK-',
            label: 'For pensum knyttet til utdanningssystemet i Slovakia',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Slovakia',
            alternativeLabels: [],
            note: 'Brukes med andre kvalifikatorer fra 4* for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-SV-',
            label: 'For pensum knyttet til utdanningssystemet i El Salvador',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i El Salvador',
            alternativeLabels: [],
            note: 'Brukes med andre kvalifikatorer fra 4* for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-TR-',
            label: 'For pensum knyttet til utdanningssystemet i Tyrkia',
            originalLabel: 'For pensum knyttet til utdanningssystemet i Tyrkia',
            alternativeLabels: [],
            note: 'Brukes med andre kvalifikatorer fra 4* for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-UA-',
            label: 'For pensum knyttet til utdanningssystemet i Ukraina',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Ukraina',
            alternativeLabels: [],
            note: 'Brukes med andre kvalifikatorer fra 4* for å angi nivå',
            related: [],
            children: [],
          },
          {
            id: '4Z-US-',
            label: 'For pensum knyttet til utdanningssystemet i USA',
            originalLabel: 'For pensum knyttet til utdanningssystemet i USA',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '4Z-US-A',
                label: 'For SAT (Scholastic Assessment Test) (USA)',
                originalLabel: 'For SAT (Scholastic Assessment Test) (USA)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-US-B',
                label: 'For ACT (American College Testing) (USA)',
                originalLabel: 'For ACT (American College Testing) (USA)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-US-C',
                label: 'For GED (General Educational Development Tests) (USA)',
                originalLabel:
                  'For GED (General Educational Development Tests) (USA)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-US-D',
                label: 'For GMAT (Graduate Management Admission Test)',
                originalLabel: 'For GMAT (Graduate Management Admission Test)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-US-E',
                label: 'For GRE (Graduate Record Examination) (USA)',
                originalLabel: 'For GRE (Graduate Record Examination) (USA)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-US-F',
                label: 'For LSAT (Law School Admission Test) (USA)',
                originalLabel: 'For LSAT (Law School Admission Test) (USA)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-US-G',
                label: 'For MCAT (Medical College Admission Test) (USA)',
                originalLabel:
                  'For MCAT (Medical College Admission Test) (USA)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-US-H',
                label:
                  'For PSAT and NMSQT (National Merit Scholarship Qualifying Test) (USA)',
                originalLabel:
                  'For PSAT and NMSQT (National Merit Scholarship Qualifying Test) (USA)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-US-I',
                label: 'For NTE (National Teacher Examinations) (USA)',
                originalLabel: 'For NTE (National Teacher Examinations) (USA)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-US-J',
                label:
                  'For NCLEX (National Council Licensure Examination) (USA)',
                originalLabel:
                  'For NCLEX (National Council Licensure Examination) (USA)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-US-L',
                label: 'For Legal Bar (USA)',
                originalLabel: 'For Legal Bar (USA)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-US-M',
                label: 'For High School Entrance (USA)',
                originalLabel: 'For High School Entrance (USA)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-US-N',
                label: 'For College Entrance (USA)',
                originalLabel: 'For College Entrance (USA)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-US-O',
                label: 'For AP - Advance Placement (USA)',
                originalLabel: 'For AP - Advance Placement (USA)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '4Z-US-P',
                label: 'For Armed Forces (USA)',
                originalLabel: 'For Armed Forces (USA)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '4Z-UY-',
            label: 'For pensum knyttet til utdanningssystemet i Uruguay',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Uruguay',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '4Z-VE-',
            label: 'For pensum knyttet til utdanningssystemet i Venezuela',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Venezuela',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '4Z-ZA-',
            label: 'For pensum knyttet til utdanningssystemet i Sør-Afrika',
            originalLabel:
              'For pensum knyttet til utdanningssystemet i Sør-Afrika',
            alternativeLabels: [],
            note: 'Brukes med andre kvalifikatorer fra 4* for å angi nivå',
            related: [],
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: '5',
    label: 'Kvalifikatorer: interesse',
    originalLabel: 'Kvalifikatorer: interesse',
    alternativeLabels: [],
    note: 'Bruk kvalifikatorer fra 5* SAMMEN MED passende emnekoder for å angi et ulike sett aspekter ved hovedinnholdet i boka. 5A*-koder for intellektuelt nivå brukes sammen med Y*-koder, 5H* brukes for å angi at innholdet handler om spesielle høytider eller begivenheter, 5L* brukes til å angi at inneholdet handler om ei spesiell aldersgruppe, 5P* brukes for å angi at innholdet handler om ei spesiell brukke mennesker etc. Nasjonale utvidelser (dvs. på formatet 5*-xx-, hvor xx angir en landkode) kan brukes av alle, og er ikke ment kun for brukere i det angitte landet. IKKE bruk 5*-koder for å angi målgrupper i markedsføringssammenheng, kvalifikatorer fra 5* må angis ut fra bokas hovedinnhold. IKKE bruk koden ‘5’ alene, men velg en spesifikk kode fra 5* der det er hensiktsmessig',
    related: [],
    children: [
      {
        id: '5A',
        label: 'Intellektuelt nivå / lesenivå',
        originalLabel: 'Intellektuelt nivå / lesenivå',
        alternativeLabels: [],
        note: 'Bruk 5A*-alderskoder for å angi hvilken alder bøker som er tildelt Y*- eller X*-koder passer for. Velg en 5A*-kode for å gi en indikasjon på hvilken alder bokas innhold passer fra. Angi 5A* for alle Y*-koder (bortsett fra YP* som bruker 4*-koder). 5AR, 5AX og 5AZ kan brukes sammen med alle relevante emnekoder. Alle 5A*-koder kan også brukes sammen med X*-koder for tegneserier ment for barn og ungdom. IKKE bruk 5A alene, bruk alltid 5A*-koder. Dersom man ønsker å angi mer detaljerte opplysninger om aldersnivå, lesenivå etc., gjøres dette i andre metadatafelt',
        related: [],
        children: [
          {
            id: '5AB',
            label: 'For barn 0 til 3 år',
            originalLabel: 'For barn 0 til 3 år',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '5ABB',
                label: 'For babyer fra fødselen',
                originalLabel: 'For babyer fra fødselen',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5ABD',
                label: 'For babyer fra 3 måneder',
                originalLabel: 'For babyer fra 3 måneder',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5ABF',
                label: 'For babyer fra 6 måneder',
                originalLabel: 'For babyer fra 6 måneder',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5ABH',
                label: 'For småbarn fra 12 måneder',
                originalLabel: 'For småbarn fra 12 måneder',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5ABJ',
                label: 'For småbarn fra 18 måneder',
                originalLabel: 'For småbarn fra 18 måneder',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5ABK',
                label: 'Intellektuelt nivå: fra 24 måneder',
                originalLabel: 'Intellektuelt nivå: fra 24 måneder',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '5AC',
            label: 'Intellektuelt nivå: fra 3 år',
            originalLabel: 'Intellektuelt nivå: fra 3 år',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '5AD',
            label: 'Intellektuelt nivå: fra 4 år',
            originalLabel: 'Intellektuelt nivå: fra 4 år',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '5AF',
            label: 'Intellektuelt nivå: fra 5 år',
            originalLabel: 'Intellektuelt nivå: fra 5 år',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '5AG',
            label: 'Intellektuelt nivå: fra 6 år',
            originalLabel: 'Intellektuelt nivå: fra 6 år',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '5AH',
            label: 'Intellektuelt nivå: fra 7 år',
            originalLabel: 'Intellektuelt nivå: fra 7 år',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '5AJ',
            label: 'Intellektuelt nivå: fra 8 år',
            originalLabel: 'Intellektuelt nivå: fra 8 år',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '5AK',
            label: 'Intellektuelt nivå: fra 9 år',
            originalLabel: 'Intellektuelt nivå: fra 9 år',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '5AL',
            label: 'Intellektuelt nivå: fra 10 år',
            originalLabel: 'Intellektuelt nivå: fra 10 år',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '5AM',
            label: 'Intellektuelt nivå: fra 11 år',
            originalLabel: 'Intellektuelt nivå: fra 11 år',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '5AN',
            label: 'Intellektuelt nivå: fra 12 år',
            originalLabel: 'Intellektuelt nivå: fra 12 år',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '5AP',
            label: 'Intellektuelt nivå: fra 13 år',
            originalLabel: 'Intellektuelt nivå: fra 13 år',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '5AQ',
            label: 'Intellektuelt nivå: fra 14 år',
            originalLabel: 'Intellektuelt nivå: fra 14 år',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '5AR',
            label: 'Lettlest for barn og ungdom',
            originalLabel: 'Lettlest for barn og ungdom',
            alternativeLabels: [],
            note: 'Brukes for lettleste bøker med korte linjer og stor skrift rettet mot barn og ungdom på ulike alderstrinn, på forskjellige nivåer tilpasset leseferdighet',
            related: [],
            children: [],
          },
          {
            id: '5AS',
            label: 'Intellektuelt nivå: fra 15 år',
            originalLabel: 'Intellektuelt nivå: fra 15 år',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '5AT',
            label: 'Intellektuelt nivå: fra 16 år',
            originalLabel: 'Intellektuelt nivå: fra 16 år',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '5AU',
            label: 'Intellektuelt nivå: fra 17 år',
            originalLabel: 'Intellektuelt nivå: fra 17 år',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '5AX',
            label: 'Lettlest for voksne',
            originalLabel: 'Lettlest for voksne',
            alternativeLabels: [],
            note: 'Brukes for bøker som er spesielt beregnet for voksne med lesevansker, eller for voksne som akkurat har lært seg å lese',
            related: [],
            children: [],
          },
          {
            id: '5AZ',
            label:
              'For lesere med lære- / kommunikasjonsvansker eller funksjonsnedsettelser',
            originalLabel:
              'For lesere med lære- / kommunikasjonsvansker eller funksjonsnedsettelser',
            alternativeLabels: [],
            note: 'Brukes for bøker hvor innholdet er tilpasset til eller skrevet for lesere (i alle aldre) med spesielle behov. Dette inkluderer innhold som er spesielt tilpasset personer med f.eks. dysleksi og andre lese- eller lærevansker, kognitive vansker, problemer med hukommelsen, demens eller Alzheimers, synshemninger etc. Brukes med kvalifikatorer fra 5PM* der det er hensiktsmessig',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: '5H',
        label: 'Høytider, begivenheter og årstider',
        originalLabel: 'Høytider, begivenheter og årstider',
        alternativeLabels: [],
        note: 'Bruk koder fra 5H* for å gi en indikasjon på at innholdet i ei bok hovedsakelig er knyttet til ei høytid, feiring eller årstid, f.eks. 5HPD for ei julefortelling, 5HKA for ei bok om bursdager. IKKE bruk kodene for å sammenstille lister over bøker som skal selges i et gitt tidsrom (denne informasjonen sendes i andre metadata), dersom bøkene ikke har innhold som tilsier en 5H*-kode',
        related: [],
        children: [
          {
            id: '5HC',
            label: 'Høytider og feiringer',
            originalLabel: 'Høytider og feiringer',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '5HCA',
                label: 'Nyttår',
                originalLabel: 'Nyttår',
                alternativeLabels: [],
                note: 'Brukes for bøker om nyttårstradisjoner og -feiringer i ulike kalendere, kulturer og regioner. Brukes med koder fra 5PG* for nyttårsfeiringer i ulike religiøse tradisjoner eller kvalifikatorer for sted for bøker om tradisjoner som forbindes med geografiske områder. Se også 5HCC <a href="/thema/nb/5HCC">Kinesisk nyttår /månefestival</a>',
                related: [
                  {
                    id: '5HCC',
                    label: 'Kinesisk nyttår /månefestival',
                    originalLabel: 'Kinesisk nyttår /månefestival',
                    note: 'Se også 5HCA <a href="/thema/nb/5HCA">Nyttår</a>',
                  },
                ],
                children: [],
              },
              {
                id: '5HCB',
                label: 'Minnedager',
                originalLabel: 'Minnedager',
                alternativeLabels: [],
                note: 'Brukes for bøker om nasjonale eller regionale minnedager som dekker viktige historiske hendelser som ikke har egne 5H*-koder. Dette inkluderer feiring av revolusjoner, dager som feires i tilknytning til personer tilknyttet en religion, historiske personer etc. Brukes med kvalifikatorer for geografi',
                related: [],
                children: [
                  {
                    id: '5HCBA',
                    label: 'Den internasjonale holocaustdagen',
                    originalLabel: 'Den internasjonale holocaustdagen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '5HCB-AR-B',
                    label: 'Mairevolusjonen (Argentina)',
                    originalLabel: 'Mairevolusjonen (Argentina)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '5HCB-AR-D',
                    label:
                      'Día de la Memoria por la Verdad y la Justicia (Argentina)',
                    originalLabel:
                      'Día de la Memoria por la Verdad y la Justicia (Argentina)',
                    alternativeLabels: [
                      'Day of Remembrance for Truth and Justice',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: '5HCBD',
                    label: 'Markering av frigjøring av slaver',
                    originalLabel: 'Markering av frigjøring av slaver',
                    alternativeLabels: ['Juneteenth'],
                    note: 'Brukes for bøker om dager, uker eller festivaler som markerer frigjøring av slaver av afrikansk opprinnelse. Brukes med kvalifikatorer for sted for bøker knyttet til spesifikke geografiske områder',
                    related: [],
                    children: [],
                  },
                  {
                    id: '5HCB-MX-B',
                    label: 'Den meksikanske revolusjonen',
                    originalLabel: 'Den meksikanske revolusjonen',
                    alternativeLabels: [],
                    note: 'Nasjonal minnedag for revolusjonen som startet i 1910',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '5HCC',
                label: 'Kinesisk nyttår /månefestival',
                originalLabel: 'Kinesisk nyttår /månefestival',
                alternativeLabels: [],
                note: 'Se også 5HCA <a href="/thema/nb/5HCA">Nyttår</a>',
                related: [
                  {
                    id: '5HCA',
                    label: 'Nyttår',
                    originalLabel: 'Nyttår',
                    note: 'Brukes for bøker om nyttårstradisjoner og -feiringer i ulike kalendere, kulturer og regioner. Brukes med koder fra 5PG* for nyttårsfeiringer i ulike religiøse tradisjoner eller kvalifikatorer for sted for bøker om tradisjoner som forbindes med geografiske områder. Se også 5HCC <a href="/thema/nb/5HCC">Kinesisk nyttår /månefestival</a>',
                  },
                ],
                children: [],
              },
              {
                id: '5HCD',
                label: 'Uavhengighetsdager',
                originalLabel: 'Uavhengighetsdager',
                alternativeLabels: [],
                note: 'Her: frigjøringsdagen. Brukes med kvalifikatorer for geografi',
                related: [],
                children: [
                  {
                    id: '5HC-US-A',
                    label: 'Den amerikanske uavhengighetsdagen',
                    originalLabel: 'Den amerikanske uavhengighetsdagen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '5HCE',
                label: 'Valentinsdagen',
                originalLabel: 'Valentinsdagen',
                alternativeLabels: ['valentine’s day'],

                related: [],
                children: [],
              },
              {
                id: '5HCF',
                label: 'Nasjonaldager / Grunnlovsdager',
                originalLabel: 'Nasjonaldager / Grunnlovsdager',
                alternativeLabels: [],
                note: 'Her: dager som er ei feiring av en nasjon eller en stat. Brukes med kvalifikatorer for geografi',
                related: [],
                children: [
                  {
                    id: '5HC-CN-G',
                    label: 'Kinas nasjonaldag',
                    originalLabel: 'Kinas nasjonaldag',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '5HCG',
                label: 'Karneval / Mardi Gras',
                originalLabel: 'Karneval / Mardi Gras',
                alternativeLabels: [],
                note: 'Se også 5HPFD <a href="/thema/nb/5HPFD">Fastetiden</a>',
                related: [
                  {
                    id: '5HPFD',
                    label: 'Fastetiden',
                    originalLabel: 'Fastetiden',
                    note: 'Her: askeonsdag, fastelavn. Se også 5HCG <a href="/thema/nb/5HCG">Karneval / Mardi Gras</a>',
                  },
                ],
                children: [],
              },
              {
                id: '5HCH',
                label: 'Den internasjonale kvinnedagen',
                originalLabel: 'Den internasjonale kvinnedagen',
                alternativeLabels: [],
                note: 'Her: 8. mars',
                related: [],
                children: [],
              },
              {
                id: '5HC-IE-P',
                label: 'St. Patricks dag',
                originalLabel: 'St. Patricks dag',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HCJ',
                label: 'Morsdag',
                originalLabel: 'Morsdag',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HCK',
                label: 'Barnas dag',
                originalLabel: 'Barnas dag',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HCL',
                label: 'Farsdag',
                originalLabel: 'Farsdag',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HCM',
                label: 'Midtsommer',
                originalLabel: 'Midtsommer',
                alternativeLabels: [],
                note: 'Her: sankthansaften, midtsommeraften, sommersolverv. Brukes for høytider knyttet til sommersolverv, og som finner sted på ulike tidspunkter, alt ettersom man holder til på den nordlige eller sørlige halvkule. Brukes med kvalifikator(er) for geografi der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: '5HCN',
                label: 'Midthøstfesten',
                originalLabel: 'Midthøstfesten',
                alternativeLabels: ['Chuseok', 'Tsukimi'],
                note: 'Her: månefestival, månefest',
                related: [],
                children: [],
              },
              {
                id: '5HCP',
                label: 'Halloween',
                originalLabel: 'Halloween',
                alternativeLabels: [],
                note: 'Her: allehelgensaften',
                related: [],
                children: [],
              },
              {
                id: '5HCQ',
                label: 'Minnemarkeringer for de døde',
                originalLabel: 'Minnemarkeringer for de døde',
                alternativeLabels: ['alle sjelers dag'],
                note: 'Brukes for høytider som holdes for å minnes de avdøde. Brukes med kvalifikatorer for geografi der det er hensiktsmessig',
                related: [],
                children: [
                  {
                    id: '5HC-CN-Q',
                    label: 'Forfedrenes dag',
                    originalLabel: 'Forfedrenes dag',
                    alternativeLabels: [],
                    note: 'Her: qingmingfesten',
                    related: [],
                    children: [],
                  },
                  {
                    id: '5HC-MX-D',
                    label: 'De dødes dag',
                    originalLabel: 'De dødes dag',
                    alternativeLabels: ['Día de Muertos'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '5HCR',
                label: 'Innhøsting',
                originalLabel: 'Innhøsting',
                alternativeLabels: [],
                note: 'Brukes for høytider knyttet til innhøsting. Brukes med kvalifikator(er) for geografi og 5HR* der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: '5HCS',
                label: 'Høsttakkefest',
                originalLabel: 'Høsttakkefest',
                alternativeLabels: [],
                note: 'Her: thanksgiving',
                related: [],
                children: [],
              },
              {
                id: '5HCT',
                label: 'Urfolkenes dag',
                originalLabel: 'Urfolkenes dag',
                alternativeLabels: [],
                note: 'Her: dager som feirer kulturelt mangfold, Día de la resistencia indígena, día de la Raza, Día del Respeto a la Diversidad Cultural, Día del Encuentro de las Culturas. Brukes med kvalifikatorer for geografi der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: '5HCU',
                label: 'Verdens bokdag',
                originalLabel: 'Verdens bokdag',
                alternativeLabels: [],
                note: 'Brukes for bøker som er utgitt spesielt for Verdens bokdag',
                related: [],
                children: [],
              },
              {
                id: '5HCV',
                label: 'Midtvinter',
                originalLabel: 'Midtvinter',
                alternativeLabels: [],
                note: 'Her: vintersolverv. Brukes for høytider knyttet til vintersolverv, og som finner sted på ulike tidspunkter, alt ettersom man holder til på den nordlige eller sørlige halvkule. Brukes med kvalifikator(er) for geografi der det er hensiktsmessig. Brukes med kvalifikator(er) for geografi der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: '5HCW',
                label: 'Arbeidernes internasjonale kampdag',
                originalLabel: 'Arbeidernes internasjonale kampdag',
                alternativeLabels: [],
                note: 'Her: 1. mai',
                related: [],
                children: [],
              },
              {
                id: '5HCX',
                label:
                  'Festdager i tilknytning til tradisjoner, kultur eller folkeminner',
                originalLabel:
                  'Festdager i tilknytning til tradisjoner, kultur eller folkeminner',
                alternativeLabels: [],
                note: 'Brukes for festdager og feiringer som har sitt utspring i skikker, årstider eller lokale tradisjoner og fortellinger. Brukes med kvalifikatorer for geografi der det er hensiktsmessig',
                related: [],
                children: [
                  {
                    id: '5HC-CN-D',
                    label: 'Dragebåtfestivalen',
                    originalLabel: 'Dragebåtfestivalen',
                    alternativeLabels: ['Duanwu'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '5HC-IE-B',
                    label: 'Sankt Brigids dag',
                    originalLabel: 'Sankt Brigids dag',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '5HK',
            label: 'Spesielle begivenheter',
            originalLabel: 'Spesielle begivenheter',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '5HKA',
                label: 'Bursdager',
                originalLabel: 'Bursdager',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HKB',
                label: 'Skolestart',
                originalLabel: 'Skolestart',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HKC',
                label: 'Skoleavslutning',
                originalLabel: 'Skoleavslutning',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HKF',
                label: 'Dåp',
                originalLabel: 'Dåp',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HKM',
                label: 'Første kommunion, nattverd',
                originalLabel: 'Første kommunion, nattverd',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HKQ',
                label: 'Konfirmasjon',
                originalLabel: 'Konfirmasjon',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HKT',
                label: 'Bar Mitzvah, Bat Mitzvah',
                originalLabel: 'Bar Mitzvah, Bat Mitzvah',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HKU',
                label: 'Bryllup / ekteskap',
                originalLabel: 'Bryllup / ekteskap',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '5HKUD',
                    label: 'Forlovelse',
                    originalLabel: 'Forlovelse',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '5HKV',
                label: 'Feiring av det å bli voksen',
                originalLabel: 'Feiring av det å bli voksen',
                alternativeLabels: ['konfirmasjon'],
                note: 'Brukes med kvalifikator(er) for geografi der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '5HP',
            label: 'Religiøse høytider',
            originalLabel: 'Religiøse høytider',
            alternativeLabels: [],
            note: 'Brukes med koder fra 5PG* for høytider knyttet til religioner som ikke har en egen kode',
            related: [],
            children: [
              {
                id: '5HPD',
                label: 'Julen',
                originalLabel: 'Julen',
                alternativeLabels: ['jula'],
                note: 'Her: julaften og andre dager man forbinder med jul',
                related: [],
                children: [
                  {
                    id: '5HPDA',
                    label: 'Advent',
                    originalLabel: 'Advent',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: '5HPDE',
                    label: 'Helligtrekongersdag',
                    originalLabel: 'Helligtrekongersdag',
                    alternativeLabels: [],
                    note: 'Her: trettendedagen',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '5HPF',
                label: 'Påske',
                originalLabel: 'Påske',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '5HPFD',
                    label: 'Fastetiden',
                    originalLabel: 'Fastetiden',
                    alternativeLabels: [],
                    note: 'Her: askeonsdag, fastelavn. Se også 5HCG <a href="/thema/nb/5HCG">Karneval / Mardi Gras</a>',
                    related: [
                      {
                        id: '5HCG',
                        label: 'Karneval / Mardi Gras',
                        originalLabel: 'Karneval / Mardi Gras',
                        note: 'Se også 5HPFD <a href="/thema/nb/5HPFD">Fastetiden</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: '5HPFH',
                    label: 'Den stille uke',
                    originalLabel: 'Den stille uke',
                    alternativeLabels: [],
                    note: 'Her: skjærtorsdag, langfredag, palmesøndag',
                    related: [],
                    children: [],
                  },
                  {
                    id: '5HPFP',
                    label: 'Pinse',
                    originalLabel: 'Pinse',
                    alternativeLabels: [],
                    note: 'Her: treenighetssøndag',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '5HPG',
                label: 'Holi',
                originalLabel: 'Holi',
                alternativeLabels: ['Hinduistisk vårfestival'],

                related: [],
                children: [],
              },
              {
                id: '5HPH',
                label: 'Divali',
                originalLabel: 'Divali',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HPJ',
                label: 'Baisakhi (Vaisakhi)',
                originalLabel: 'Baisakhi (Vaisakhi)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HPK',
                label: 'Ramadan',
                originalLabel: 'Ramadan',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '5HPKE',
                    label: 'Eid al-fitr',
                    originalLabel: 'Eid al-fitr',
                    alternativeLabels: ['id al-fitr'],
                    note: 'Her: fastebrytingens fest',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '5HPL',
                label: 'Eid al-adha',
                originalLabel: 'Eid al-adha',
                alternativeLabels: ['id al-adha'],
                note: 'Her: offerfesten, id al-kabir',
                related: [],
                children: [],
              },
              {
                id: '5HP-NL-N',
                label: 'Nilsmesse',
                originalLabel: 'Nilsmesse',
                alternativeLabels: [],
                note: 'Her: feiring av Sinterklaas i Nederland',
                related: [],
                children: [],
              },
              {
                id: '5HPU',
                label: 'Hanukka',
                originalLabel: 'Hanukka',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HPV',
                label: 'Pesach',
                originalLabel: 'Pesach',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HPW',
                label: 'Rosh ha-shana',
                originalLabel: 'Rosh ha-shana',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '5HPWY',
                    label: 'Yom kippur',
                    originalLabel: 'Yom kippur',
                    alternativeLabels: ['forsoningsdagen'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '5HPY',
                label: 'Andre religiøse høytider eller festdager',
                originalLabel: 'Andre religiøse høytider eller festdager',
                alternativeLabels: [],
                note: 'Brukes med QR*- og 5PG*-koder, og med kvalifikatorer for geografi der det er hensiktsmessig',
                related: [],
                children: [
                  {
                    id: '5HPYC',
                    label: 'Kristi legemsfest',
                    originalLabel: 'Kristi legemsfest',
                    alternativeLabels: ['Corpus Christi'],

                    related: [],
                    children: [],
                  },
                  {
                    id: '5HPY-MX-G',
                    label: 'Vår frue av Guadalupes minnedag',
                    originalLabel: 'Vår frue av Guadalupes minnedag',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: '5HR',
            label: 'Årstider',
            originalLabel: 'Årstider',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: '5HRA',
                label: 'Vår',
                originalLabel: 'Vår',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HRB',
                label: 'Sommer',
                originalLabel: 'Sommer',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '5HRB-FI-P',
                    label: 'Midnattssol',
                    originalLabel: 'Midnattssol',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '5HRC',
                label: 'Høst',
                originalLabel: 'Høst',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5HRD',
                label: 'Vinter',
                originalLabel: 'Vinter',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: '5HRD-FI-P',
                    label: 'Mørketid',
                    originalLabel: 'Mørketid',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: '5J',
        label: 'Beregnet for spesifikke målgrupper',
        originalLabel: 'Beregnet for spesifikke målgrupper',
        alternativeLabels: [],
        note: 'Bruk 5J*-koder kun i spesielle tilfeller for å angi at boka er skapt eksklusivt for ei spesifikk gruppe, uavhengig av bokas innhold. Boka handler nødvendigvis ikke OM disse gruppene, selv om materialet er skrevet spesielt for disse gruppene. 5J kan brukes med kvalifikatorer fra 5P*, dersom ei bok er skrevet eksklusivt for denne gruppa',
        related: [],
        children: [
          {
            id: '5JA',
            label: 'Primært beregnet for kvinner og/eller jenter',
            originalLabel: 'Primært beregnet for kvinner og/eller jenter',
            alternativeLabels: [],
            note: 'Bruk 5JA i spesielle tilfeller for materiale som er ment å skulle leses av kvinner og/eller jenter, på den måten at materialet er skrevet spesielt for denne gruppa. Materialet handler ikke nødvendigvis om kvinner eller jenter',
            related: [],
            children: [],
          },
          {
            id: '5JB',
            label: 'Primært beregnet for menn og/eller gutter',
            originalLabel: 'Primært beregnet for menn og/eller gutter',
            alternativeLabels: [],
            note: 'Bruk 5JB i spesielle tilfeller for materiale som er ment å skulle leses av menn og/eller gutter, på den måten at materialet er skrevet spesielt for denne gruppa. Materialet handler ikke nødvendigvis om menn eller gutter',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: '5L',
        label: 'Livsfaser',
        originalLabel: 'Livsfaser',
        alternativeLabels: [],
        note: 'Bruk 5L*-koder på bøker hvor innholdets tematikk handler om personer i ulike livsfaser. IKKE bruk disse kodene for å angi målgruppe, de skal kun brukes for å beskrive innholdet',
        related: [],
        children: [
          {
            id: '5LB',
            label: 'Tidlig barndom',
            originalLabel: 'Tidlig barndom',
            alternativeLabels: [],
            note: 'Her: babyer og småbarn. Brukes for titler som hovedsakelig behandler temaer som har med tidlig barndom å gjøre',
            related: [],
            children: [],
          },
          {
            id: '5LC',
            label: 'Barndom',
            originalLabel: 'Barndom',
            alternativeLabels: [],
            note: 'Brukes for titler som hovedsakelig behandler temaer som har med barndom å gjøre, eller som handler om det å være barn',
            related: [],
            children: [],
          },
          {
            id: '5LD',
            label: 'Førungdomstid',
            originalLabel: 'Førungdomstid',
            alternativeLabels: [],
            note: 'Brukes for titler som hovedsakelig behandler temaer som har med barn i alderen 8-12 år å gjøre; perioden før tenårene (tweens)',
            related: [],
            children: [],
          },
          {
            id: '5LF',
            label: 'Ungdomstid',
            originalLabel: 'Ungdomstid',
            alternativeLabels: [],
            note: 'Brukes for titler som hovedsakelig behandler temaer som har med ungdom, tenåringer eller pubertet å gjøre.',
            related: [],
            children: [],
          },
          {
            id: '5LK',
            label: 'Voksenlivet',
            originalLabel: 'Voksenlivet',
            alternativeLabels: [],
            note: 'Brukes for titler som hovedsakelig behandler temaer som har med det å være voksen å gjøre',
            related: [],
            children: [
              {
                id: '5LKE',
                label: 'Tidlig voksenliv',
                originalLabel: 'Tidlig voksenliv',
                alternativeLabels: [],
                note: 'Her: unge voksne. Brukes for titler som hovedsakelig behandler temaer som har med personer fra sent i tenårene og fram til midt i tjueårene å gjøre, ofte tematikk som har med det å bli voksen å gjøre. Brukes med FR* for romantikk for unge voksne, FP for erotikk for unge voksne, FM for fantasy for unge voksne når ei bok handler spesielt om denne livsfasen, og med andre emnekoder for faglitterære bøker som handler som samme livsfase',
                related: [],
                children: [],
              },
              {
                id: '5LKM',
                label: 'Midtlivet',
                originalLabel: 'Midtlivet',
                alternativeLabels: [],
                note: 'Brukes for titler som hovedsakelig behandler temaer som har med middelaldrende voksne å gjøre',
                related: [],
                children: [],
              },
              {
                id: '5LKS',
                label: 'Alderdom',
                originalLabel: 'Alderdom',
                alternativeLabels: [],
                note: 'Brukes for titler som hovedsakelig behandler temaer som har med eldre/pensjonister å gjøre. Brukes med F* eller YF* for bøker som handler spesielt om denne livsfasen, f.eks. FR* for romantikk sent i livet, eller YFN for ei bok som handler som en eldre slektning, og med andre emnekoder for faglitterære bøker som handler om samme livsfase',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: '5P',
        label: 'Relatert til spesifikke grupper og kulturer',
        originalLabel: 'Relatert til spesifikke grupper og kulturer',
        alternativeLabels: [],
        note: 'Bruk 5P* for å angi at utgivelsen har temaer, emner eller handlingsforløp som har med spesifikke grupper av mennesker å gjøre, eller som handler om disse gruppene. IKKE BRUK 5P* for å angi målgrupper eller for å angi forfatterens opphav, bortsett fra i de tilfellene hvor det er relevant for utgivelsens innhold',
        related: [],
        children: [
          {
            id: '5PB',
            label: 'Etniske grupper, urfolk eller andre folkegrupper',
            originalLabel: 'Etniske grupper, urfolk eller andre folkegrupper',
            alternativeLabels: [],
            note: 'Bruk 5PB*-koder for materiale som hovedsakelig er av interesse for eller handler om spesifikke grupper. Brukes med kvalifikatorer for geografi for grupper som har tilknytning til et geografisk område, men som ikke har en egen kode, med JBSL*-koder i tillegg til andre emner der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: '5PBA',
                label: 'Urfolk',
                originalLabel: 'Urfolk',
                alternativeLabels: [],
                note: 'Brukes for bøker som hovedsakelig handler om urfolksgrupper hvor det ikke fins mer presise koder. Brukes med kvalifikatorer for geografi eller språk der det er hensiktsmessig, f.eks. for ei bok om urfolk i Mexico, bruk kvalifikatoren med 1KLCM. Se også 5PB-US-E <a href="/thema/nb/5PB-US-E">Amerikansk urbefolkning</a>, 5PB-NZ-A <a href="/thema/nb/5PB-NZ-A">Maorier</a>, 5PB-AU-A <a href="/thema/nb/5PB-AU-A">Aboriginere og øyboere i Torressundet</a>, 5PBS <a href="/thema/nb/5PBS">Samer</a>',
                related: [
                  {
                    id: '5PBS',
                    label: 'Samer',
                    originalLabel: 'Samer',
                    note: 'Her: samefolket. Se også 5PBA <a href="/thema/nb/5PBA">Urfolk</a>',
                  },
                ],
                children: [],
              },
              {
                id: '5PB-AA-A',
                label: 'Arabere',
                originalLabel: 'Arabere',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5PB-AU-A',
                label: 'Aboriginere og øyboere i Torressundet',
                originalLabel: 'Aboriginere og øyboere i Torressundet',
                alternativeLabels: [],
                note: 'Se også 5PBA <a href="/thema/nb/5PBA">Urfolk</a>',
                related: [
                  {
                    id: '5PBA',
                    label: 'Urfolk',
                    originalLabel: 'Urfolk',
                    note: 'Brukes for bøker som hovedsakelig handler om urfolksgrupper hvor det ikke fins mer presise koder. Brukes med kvalifikatorer for geografi eller språk der det er hensiktsmessig, f.eks. for ei bok om urfolk i Mexico, bruk kvalifikatoren med 1KLCM. Se også 5PB-US-E <a href="/thema/nb/5PB-US-E">Amerikansk urbefolkning</a>, 5PB-NZ-A <a href="/thema/nb/5PB-NZ-A">Maorier</a>, 5PB-AU-A <a href="/thema/nb/5PB-AU-A">Aboriginere og øyboere i Torressundet</a>, 5PBS <a href="/thema/nb/5PBS">Samer</a>',
                  },
                ],
                children: [],
              },
              {
                id: '5PBB',
                label: 'Personer eller grupper med flerkulturell bakgrunn',
                originalLabel:
                  'Personer eller grupper med flerkulturell bakgrunn',
                alternativeLabels: [],
                note: 'Brukes for bøker som hovedsakelig handler om grupper eller personer med flerkulturell bakgrunn',
                related: [],
                children: [],
              },
              {
                id: '5PBC',
                label: 'Migrantgrupper',
                originalLabel: 'Migrantgrupper',
                alternativeLabels: ['migrantsamfunn'],
                note: 'Her: diasporaer. Brukes for bøker som hovedsakelig handler om folkegrupper eller personer som i historisk eller nyere tid har migrert eller blitt fordrevet fra en del av verden til en annen, eller som er del av et diasporasamfunn, og som har beholdt en sterk identitetsfølelse for et annet geografisk eller kulturelt område på sitt nåværende bosted. Bruk alle 5PBC* med kvalifikatorer for geografi der det er hensiktsmessig. Brukes for å angi det geografiske stedet som er bokas emne, vanligvis hvor folkegruppa befinner seg nå, f.eks. ei bok om syriske migrantmiljøer i Sverige, bruk med 1DNS, eller ei bok om migrantarbeidere fra Guatemala i Mexico, bruk med 1KLCM. Se også 5PBD <a href="/thema/nb/5PBD">Personer av afrikansk avstamning</a>',
                related: [
                  {
                    id: '5PBD',
                    label: 'Personer av afrikansk avstamning',
                    originalLabel: 'Personer av afrikansk avstamning',
                    note: 'Brukes for bøker som hovedsakelig handler om personer av afrikansk avstamning rundt om i verden, i historisk eller nyere tid. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. 1KL for bøker om personer av afrikansk avstamning i Latin-Amerika. Foretrekk 1H* uten 5PBD for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller region i Afrika, eller bøker om afrikanske folkeslag. Se også 5PBC* <a href="/thema/nb/5PBC">Migrantgrupper</a> og dens underkategorier, 5PBCD <a href="/thema/nb/5PBCD">Personer av nordafrikansk avstamning</a>, 5PB-GB-B <a href="/thema/nb/5PB-GB-B">Briter med svart hudfarge</a>, 5PB-US-C <a href="/thema/nb/5PB-US-C">Afroamerikanere</a>',
                  },
                ],
                children: [
                  {
                    id: '5PBCB',
                    label: 'Personer med avstamning fra Midtøsten',
                    originalLabel: 'Personer med avstamning fra Midtøsten',
                    alternativeLabels: [],
                    note: 'Her: diasporasamfunn som består av personer og folkegrupper fra Vest-Asia, Anatolia, Den arabiske halvøy. Brukes for bøker som hovedsakelig handler om slike diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. ei bok om tyrkiske migranter i Tyskland, bruk med 1DGF*. Foretrekk 1FB* uten 5PBCB for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Midtøsten, eller bøker om folkeslag i Midtøsten',
                    related: [],
                    children: [],
                  },
                  {
                    id: '5PBCD',
                    label: 'Personer av nordafrikansk avstamning',
                    originalLabel: 'Personer av nordafrikansk avstamning',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Nord-Afrika eller Maghreb lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. en roman som handler om hvordan det er å være kabyler i forstedene til Lyon, med 1DDF-FR-VFA. Foretrekk 1HB* uten 5PBCD for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Nord-Afrika, eller bøker om folkeslag i Nord-Afrika. Se også 5PBD <a href="/thema/nb/5PBD">Personer av afrikansk avstamning</a>',
                    related: [
                      {
                        id: '5PBD',
                        label: 'Personer av afrikansk avstamning',
                        originalLabel: 'Personer av afrikansk avstamning',
                        note: 'Brukes for bøker som hovedsakelig handler om personer av afrikansk avstamning rundt om i verden, i historisk eller nyere tid. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. 1KL for bøker om personer av afrikansk avstamning i Latin-Amerika. Foretrekk 1H* uten 5PBD for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller region i Afrika, eller bøker om afrikanske folkeslag. Se også 5PBC* <a href="/thema/nb/5PBC">Migrantgrupper</a> og dens underkategorier, 5PBCD <a href="/thema/nb/5PBCD">Personer av nordafrikansk avstamning</a>, 5PB-GB-B <a href="/thema/nb/5PB-GB-B">Briter med svart hudfarge</a>, 5PB-US-C <a href="/thema/nb/5PB-US-C">Afroamerikanere</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: '5PBCG',
                    label: 'Personer av sørasiatisk avstamning',
                    originalLabel: 'Personer av sørasiatisk avstamning',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Sør-Asia lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. en ungdomsroman om en tamil i Australia, med 1MBF. Foretrekk 1FK* uten 5PBCB for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Sør-Asia, eller bøker om folkeslag i Sør-Asia',
                    related: [],
                    children: [],
                  },
                  {
                    id: '5PBCH',
                    label: 'Personer av sørøstasiatisk avstamning',
                    originalLabel: 'Personer av sørøstasiatisk avstamning',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Sørøst-Asia lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. ei billedbok om en vietnamesisk familie i Canada, bruk med 1KBB-US-WPC. Foretrekk 1FM* uten 5PBCB for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Sørøst-Asia, eller bøker om folkeslag i Sørøst-Asia',
                    related: [],
                    children: [],
                  },
                  {
                    id: '5PBCJ',
                    label: 'Personer av østasiatisk avstamning',
                    originalLabel: 'Personer av østasiatisk avstamning',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Øst-Asia lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. ei bok om koreanere i Toronto, Canada, bruk med 1KBC-CA-OSM. Foretrekk 1FP* uten 5PBCB for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Øst-Asia, eller bøker om folkeslag i Øst-Asia',
                    related: [],
                    children: [],
                  },
                  {
                    id: '5PBCL',
                    label: 'Personer med avstamning fra stillehavsøyene',
                    originalLabel:
                      'Personer med avstamning fra stillehavsøyene',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Stillehavsøyene lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. ei bok om personer fra Samoa i Brisbane, Australia, bruk med 1MBF-AU-QB. Foretrekk 1M* uten 5PBCB for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Stillehavet, eller bøker om folkeslag på Stillehavsøyene. Se også 5PB-NZ-A <a href="/thema/nb/5PB-NZ-A">Maorier</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '5PBCM',
                    label: 'Personer av karibisk avstamning',
                    originalLabel: 'Personer av karibisk avstamning',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Karibia lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. en biografi om en person av jamaikansk avstamning som vokser opp i New York, bruk med 1KBB-US-NAKC. Foretrekk 1KJ* uten 5PBCB for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Karibia, eller bøker om folkeslag i Karibia',
                    related: [],
                    children: [],
                  },
                  {
                    id: '5PBCN',
                    label: 'Personer av latinamerikansk avstamning',
                    originalLabel: 'Personer av latinamerikansk avstamning',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Latin-Amerika lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. ei bok om personer av meksikansk avstamning som bor i London, bruk med 1DDU-GB-ESL. Foretrekk 1KL* uten 5PBCB for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Latin-Amerika, eller bøker om latinamerikanske folkeslag. Se også 5PB-US-H <a href="/thema/nb/5PB-US-H">Latinamerikanere</a>',
                    related: [],
                    children: [],
                  },
                  {
                    id: '5PBCP',
                    label: 'Personer av nordamerikansk avstamning',
                    originalLabel: 'Personer av nordamerikansk avstamning',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Nord-Amerika lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig. Foretrekk 1K* uten 5PBCB for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Nord-Amerika, eller bøker om nordamerikanske folkeslag',
                    related: [],
                    children: [],
                  },
                  {
                    id: '5PBCQ',
                    label: 'Personer av europeisk avstamning',
                    originalLabel: 'Personer av europeisk avstamning',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Europa lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. en roman om en albansk immigrant i Helsinki, bruk med 1DNF-FI-CB. Foretrekk 1D* uten 5PBCB for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Europa, eller bøker om europeiske folkeslag',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '5PBD',
                label: 'Personer av afrikansk avstamning',
                originalLabel: 'Personer av afrikansk avstamning',
                alternativeLabels: [],
                note: 'Brukes for bøker som hovedsakelig handler om personer av afrikansk avstamning rundt om i verden, i historisk eller nyere tid. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. 1KL for bøker om personer av afrikansk avstamning i Latin-Amerika. Foretrekk 1H* uten 5PBD for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller region i Afrika, eller bøker om afrikanske folkeslag. Se også 5PBC* <a href="/thema/nb/5PBC">Migrantgrupper</a> og dens underkategorier, 5PBCD <a href="/thema/nb/5PBCD">Personer av nordafrikansk avstamning</a>, 5PB-GB-B <a href="/thema/nb/5PB-GB-B">Briter med svart hudfarge</a>, 5PB-US-C <a href="/thema/nb/5PB-US-C">Afroamerikanere</a>',
                related: [
                  {
                    id: '5PBC',
                    label: 'Migrantgrupper',
                    originalLabel: 'Migrantgrupper',
                    note: 'Her: diasporaer. Brukes for bøker som hovedsakelig handler om folkegrupper eller personer som i historisk eller nyere tid har migrert eller blitt fordrevet fra en del av verden til en annen, eller som er del av et diasporasamfunn, og som har beholdt en sterk identitetsfølelse for et annet geografisk eller kulturelt område på sitt nåværende bosted. Bruk alle 5PBC* med kvalifikatorer for geografi der det er hensiktsmessig. Brukes for å angi det geografiske stedet som er bokas emne, vanligvis hvor folkegruppa befinner seg nå, f.eks. ei bok om syriske migrantmiljøer i Sverige, bruk med 1DNS, eller ei bok om migrantarbeidere fra Guatemala i Mexico, bruk med 1KLCM. Se også 5PBD <a href="/thema/nb/5PBD">Personer av afrikansk avstamning</a>',
                  },
                  {
                    id: '5PBCD',
                    label: 'Personer av nordafrikansk avstamning',
                    originalLabel: 'Personer av nordafrikansk avstamning',
                    note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Nord-Afrika eller Maghreb lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. en roman som handler om hvordan det er å være kabyler i forstedene til Lyon, med 1DDF-FR-VFA. Foretrekk 1HB* uten 5PBCD for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Nord-Afrika, eller bøker om folkeslag i Nord-Afrika. Se også 5PBD <a href="/thema/nb/5PBD">Personer av afrikansk avstamning</a>',
                  },
                ],
                children: [],
              },
              {
                id: '5PB-GB-M',
                label: 'BAME',
                originalLabel: 'BAME',
                alternativeLabels: [],
                note: 'Her: BME (Black and minority ethnic), BEM (Black and ethnic minority). Brukes for bøker som hovedsakelig handler om de demografiske gruppene i Storbritannia som omtales under samlebegrepet BAME. Foretrekk mer spesifikke 5PB-GB*-koder der det er hensiktsmessig. Se også 5PBD <a href="/thema/nb/5PBD">Personer av afrikansk avstamning</a>, 5PBC* <a href="/thema/nb/5PBC">Migrantgrupper</a> og dens underkategorier',
                related: [
                  {
                    id: '5PBC',
                    label: 'Migrantgrupper',
                    originalLabel: 'Migrantgrupper',
                    note: 'Her: diasporaer. Brukes for bøker som hovedsakelig handler om folkegrupper eller personer som i historisk eller nyere tid har migrert eller blitt fordrevet fra en del av verden til en annen, eller som er del av et diasporasamfunn, og som har beholdt en sterk identitetsfølelse for et annet geografisk eller kulturelt område på sitt nåværende bosted. Bruk alle 5PBC* med kvalifikatorer for geografi der det er hensiktsmessig. Brukes for å angi det geografiske stedet som er bokas emne, vanligvis hvor folkegruppa befinner seg nå, f.eks. ei bok om syriske migrantmiljøer i Sverige, bruk med 1DNS, eller ei bok om migrantarbeidere fra Guatemala i Mexico, bruk med 1KLCM. Se også 5PBD <a href="/thema/nb/5PBD">Personer av afrikansk avstamning</a>',
                  },
                  {
                    id: '5PBD',
                    label: 'Personer av afrikansk avstamning',
                    originalLabel: 'Personer av afrikansk avstamning',
                    note: 'Brukes for bøker som hovedsakelig handler om personer av afrikansk avstamning rundt om i verden, i historisk eller nyere tid. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. 1KL for bøker om personer av afrikansk avstamning i Latin-Amerika. Foretrekk 1H* uten 5PBD for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller region i Afrika, eller bøker om afrikanske folkeslag. Se også 5PBC* <a href="/thema/nb/5PBC">Migrantgrupper</a> og dens underkategorier, 5PBCD <a href="/thema/nb/5PBCD">Personer av nordafrikansk avstamning</a>, 5PB-GB-B <a href="/thema/nb/5PB-GB-B">Briter med svart hudfarge</a>, 5PB-US-C <a href="/thema/nb/5PB-US-C">Afroamerikanere</a>',
                  },
                ],
                children: [
                  {
                    id: '5PB-GB-A',
                    label: 'Briter av asiatisk avstamning',
                    originalLabel: 'Briter av asiatisk avstamning',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '5PB-GB-AE',
                        label: 'Briter av øst-asiatisk avstamning',
                        originalLabel: 'Briter av øst-asiatisk avstamning',
                        alternativeLabels: [],
                        note: 'Se også 5PBCJ <a href="/thema/nb/5PBCJ">Personer av østasiatisk avstamning</a>',
                        related: [
                          {
                            id: '5PBCJ',
                            label: 'Personer av østasiatisk avstamning',
                            originalLabel: 'Personer av østasiatisk avstamning',
                            note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Øst-Asia lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. ei bok om koreanere i Toronto, Canada, bruk med 1KBC-CA-OSM. Foretrekk 1FP* uten 5PBCB for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Øst-Asia, eller bøker om folkeslag i Øst-Asia',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: '5PB-GB-AS',
                        label: 'Briter av sør-asiatisk avstamning',
                        originalLabel: 'Briter av sør-asiatisk avstamning',
                        alternativeLabels: [],
                        note: 'Se også 5PBCG <a href="/thema/nb/5PBCG">Personer av sørasiatisk avstamning</a>',
                        related: [
                          {
                            id: '5PBCG',
                            label: 'Personer av sørasiatisk avstamning',
                            originalLabel: 'Personer av sørasiatisk avstamning',
                            note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Sør-Asia lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. en ungdomsroman om en tamil i Australia, med 1MBF. Foretrekk 1FK* uten 5PBCB for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Sør-Asia, eller bøker om folkeslag i Sør-Asia',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '5PB-GB-B',
                    label: 'Briter med svart hudfarge',
                    originalLabel: 'Briter med svart hudfarge',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: '5PB-GB-BC',
                        label:
                          'Briter av karibisk avstamning med svart hudfarge',
                        originalLabel:
                          'Briter av karibisk avstamning med svart hudfarge',
                        alternativeLabels: [],
                        note: 'Se også 5PBCM <a href="/thema/nb/5PBCM">Personer av karibisk avstamning</a>',
                        related: [
                          {
                            id: '5PBCM',
                            label: 'Personer av karibisk avstamning',
                            originalLabel: 'Personer av karibisk avstamning',
                            note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Karibia lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. en biografi om en person av jamaikansk avstamning som vokser opp i New York, bruk med 1KBB-US-NAKC. Foretrekk 1KJ* uten 5PBCB for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Karibia, eller bøker om folkeslag i Karibia',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: '5PB-GB-BF',
                        label:
                          'Briter av afrikansk avstamning med svart hudfarge',
                        originalLabel:
                          'Briter av afrikansk avstamning med svart hudfarge',
                        alternativeLabels: [],
                        note: 'Se også 5PBD <a href="/thema/nb/5PBD">Personer av afrikansk avstamning</a>',
                        related: [
                          {
                            id: '5PBD',
                            label: 'Personer av afrikansk avstamning',
                            originalLabel: 'Personer av afrikansk avstamning',
                            note: 'Brukes for bøker som hovedsakelig handler om personer av afrikansk avstamning rundt om i verden, i historisk eller nyere tid. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. 1KL for bøker om personer av afrikansk avstamning i Latin-Amerika. Foretrekk 1H* uten 5PBD for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller region i Afrika, eller bøker om afrikanske folkeslag. Se også 5PBC* <a href="/thema/nb/5PBC">Migrantgrupper</a> og dens underkategorier, 5PBCD <a href="/thema/nb/5PBCD">Personer av nordafrikansk avstamning</a>, 5PB-GB-B <a href="/thema/nb/5PB-GB-B">Briter med svart hudfarge</a>, 5PB-US-C <a href="/thema/nb/5PB-US-C">Afroamerikanere</a>',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '5PB-GB-N',
                label: 'Ulsterskotter',
                originalLabel: 'Ulsterskotter',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5PB-IT-A',
                label: 'Personer med avstamning fra Arbëreshë',
                originalLabel: 'Personer med avstamning fra Arbëreshë',
                alternativeLabels: [],
                note: 'Her: italiensk-albanere',
                related: [],
                children: [],
              },
              {
                id: '5PBK',
                label: 'Kurdere',
                originalLabel: 'Kurdere',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5PB-NO-K',
                label: 'Kvener',
                originalLabel: 'Kvener',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5PB-NZ-A',
                label: 'Maorier',
                originalLabel: 'Maorier',
                alternativeLabels: [],
                note: 'Se også 5PBA <a href="/thema/nb/5PBA">Urfolk</a>, 5PBCL <a href="/thema/nb/5PBCL">Personer med avstamning fra stillehavsøyene</a>',
                related: [
                  {
                    id: '5PBA',
                    label: 'Urfolk',
                    originalLabel: 'Urfolk',
                    note: 'Brukes for bøker som hovedsakelig handler om urfolksgrupper hvor det ikke fins mer presise koder. Brukes med kvalifikatorer for geografi eller språk der det er hensiktsmessig, f.eks. for ei bok om urfolk i Mexico, bruk kvalifikatoren med 1KLCM. Se også 5PB-US-E <a href="/thema/nb/5PB-US-E">Amerikansk urbefolkning</a>, 5PB-NZ-A <a href="/thema/nb/5PB-NZ-A">Maorier</a>, 5PB-AU-A <a href="/thema/nb/5PB-AU-A">Aboriginere og øyboere i Torressundet</a>, 5PBS <a href="/thema/nb/5PBS">Samer</a>',
                  },
                  {
                    id: '5PBCL',
                    label: 'Personer med avstamning fra stillehavsøyene',
                    originalLabel:
                      'Personer med avstamning fra stillehavsøyene',
                    note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Stillehavsøyene lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. ei bok om personer fra Samoa i Brisbane, Australia, bruk med 1MBF-AU-QB. Foretrekk 1M* uten 5PBCB for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Stillehavet, eller bøker om folkeslag på Stillehavsøyene. Se også 5PB-NZ-A <a href="/thema/nb/5PB-NZ-A">Maorier</a>',
                  },
                ],
                children: [],
              },
              {
                id: '5PBR',
                label: 'Romanifolket og romfolk',
                originalLabel: 'Romanifolket og romfolk',
                alternativeLabels: ['sigøynere'],
                note: 'Bruk kvalifikator for geografi der det er hensiktsmessig, for å angi at boka handler om reisende fra et spesielt land eller region',
                related: [],
                children: [
                  {
                    id: '5PBR-IE-T',
                    label: 'Irske reisende',
                    originalLabel: 'Irske reisende',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: '5PBS',
                label: 'Samer',
                originalLabel: 'Samer',
                alternativeLabels: [],
                note: 'Her: samefolket. Se også 5PBA <a href="/thema/nb/5PBA">Urfolk</a>',
                related: [
                  {
                    id: '5PBA',
                    label: 'Urfolk',
                    originalLabel: 'Urfolk',
                    note: 'Brukes for bøker som hovedsakelig handler om urfolksgrupper hvor det ikke fins mer presise koder. Brukes med kvalifikatorer for geografi eller språk der det er hensiktsmessig, f.eks. for ei bok om urfolk i Mexico, bruk kvalifikatoren med 1KLCM. Se også 5PB-US-E <a href="/thema/nb/5PB-US-E">Amerikansk urbefolkning</a>, 5PB-NZ-A <a href="/thema/nb/5PB-NZ-A">Maorier</a>, 5PB-AU-A <a href="/thema/nb/5PB-AU-A">Aboriginere og øyboere i Torressundet</a>, 5PBS <a href="/thema/nb/5PBS">Samer</a>',
                  },
                ],
                children: [],
              },
              {
                id: '5PB-US-B',
                label: 'Amish / mennonitter',
                originalLabel: 'Amish / mennonitter',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5PB-US-C',
                label: 'Afroamerikanere',
                originalLabel: 'Afroamerikanere',
                alternativeLabels: [],
                note: 'Brukes for bøker som hovedsakelig handler om personer, grupper eller folkeslag av afroamerikansk avstamning i USA og dets territorier. Foretrekk 5PBD for bøker om diasporasamfunn som består av personer av afrikansk avstamning i andre land. Se også 5PBD <a href="/thema/nb/5PBD">Personer av afrikansk avstamning</a>, 5PBCM <a href="/thema/nb/5PBCM">Personer av karibisk avstamning</a>',
                related: [
                  {
                    id: '5PBCM',
                    label: 'Personer av karibisk avstamning',
                    originalLabel: 'Personer av karibisk avstamning',
                    note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Karibia lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. en biografi om en person av jamaikansk avstamning som vokser opp i New York, bruk med 1KBB-US-NAKC. Foretrekk 1KJ* uten 5PBCB for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Karibia, eller bøker om folkeslag i Karibia',
                  },
                  {
                    id: '5PBD',
                    label: 'Personer av afrikansk avstamning',
                    originalLabel: 'Personer av afrikansk avstamning',
                    note: 'Brukes for bøker som hovedsakelig handler om personer av afrikansk avstamning rundt om i verden, i historisk eller nyere tid. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. 1KL for bøker om personer av afrikansk avstamning i Latin-Amerika. Foretrekk 1H* uten 5PBD for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller region i Afrika, eller bøker om afrikanske folkeslag. Se også 5PBC* <a href="/thema/nb/5PBC">Migrantgrupper</a> og dens underkategorier, 5PBCD <a href="/thema/nb/5PBCD">Personer av nordafrikansk avstamning</a>, 5PB-GB-B <a href="/thema/nb/5PB-GB-B">Briter med svart hudfarge</a>, 5PB-US-C <a href="/thema/nb/5PB-US-C">Afroamerikanere</a>',
                  },
                ],
                children: [],
              },
              {
                id: '5PB-US-D',
                label: 'Amerikanere med asiatisk avstamming',
                originalLabel: 'Amerikanere med asiatisk avstamming',
                alternativeLabels: [],
                note: 'Her: personer som stammer fra de amerikanske stillehavsøyene. Brukes for bøker som hovedsakelig handler om personer, grupper eller folkeslag med avstamning fra Asia eller stillehavsøyene i USA og dets territorier. Foretrekk 5PBC for bøker om diasporasamfunn som består av personer av slik avstamning i andre land. Se også 5PBC* <a href="/thema/nb/5PBC">Migrantgrupper</a> og dens underkategorier',
                related: [
                  {
                    id: '5PBC',
                    label: 'Migrantgrupper',
                    originalLabel: 'Migrantgrupper',
                    note: 'Her: diasporaer. Brukes for bøker som hovedsakelig handler om folkegrupper eller personer som i historisk eller nyere tid har migrert eller blitt fordrevet fra en del av verden til en annen, eller som er del av et diasporasamfunn, og som har beholdt en sterk identitetsfølelse for et annet geografisk eller kulturelt område på sitt nåværende bosted. Bruk alle 5PBC* med kvalifikatorer for geografi der det er hensiktsmessig. Brukes for å angi det geografiske stedet som er bokas emne, vanligvis hvor folkegruppa befinner seg nå, f.eks. ei bok om syriske migrantmiljøer i Sverige, bruk med 1DNS, eller ei bok om migrantarbeidere fra Guatemala i Mexico, bruk med 1KLCM. Se også 5PBD <a href="/thema/nb/5PBD">Personer av afrikansk avstamning</a>',
                  },
                ],
                children: [],
              },
              {
                id: '5PB-US-E',
                label: 'Amerikansk urbefolkning',
                originalLabel: 'Amerikansk urbefolkning',
                alternativeLabels: ['indianere'],
                note: 'Brukes for bøker som handler om urfolk i USA og dets territorier. Foretrekk 5PBA pluss en kvalifikator for geografi for bøker som handler om urfolk i andre deler av Amerika, f.eks. Canada, Mexico, Peru eller Bolivia. Brukes altså KUN om urfolk i USA. Se også 5PBA <a href="/thema/nb/5PBA">Urfolk</a>',
                related: [
                  {
                    id: '5PBA',
                    label: 'Urfolk',
                    originalLabel: 'Urfolk',
                    note: 'Brukes for bøker som hovedsakelig handler om urfolksgrupper hvor det ikke fins mer presise koder. Brukes med kvalifikatorer for geografi eller språk der det er hensiktsmessig, f.eks. for ei bok om urfolk i Mexico, bruk kvalifikatoren med 1KLCM. Se også 5PB-US-E <a href="/thema/nb/5PB-US-E">Amerikansk urbefolkning</a>, 5PB-NZ-A <a href="/thema/nb/5PB-NZ-A">Maorier</a>, 5PB-AU-A <a href="/thema/nb/5PB-AU-A">Aboriginere og øyboere i Torressundet</a>, 5PBS <a href="/thema/nb/5PBS">Samer</a>',
                  },
                ],
                children: [],
              },
              {
                id: '5PB-US-F',
                label: 'Kreoler',
                originalLabel: 'Kreoler',
                alternativeLabels: [],
                note: 'Brukes med kvalifikator(er) for geografi der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: '5PB-US-G',
                label: 'Cajuner',
                originalLabel: 'Cajuner',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5PB-US-H',
                label: 'Latinamerikanere',
                originalLabel: 'Latinamerikanere',
                alternativeLabels: [],
                note: 'Brukes for bøker som hovedsakelig handler om personer, grupper eller folkeslag av latinamerikansk avstamning i USA og dets territorier. Foretrekk 5PBC* for bøker om diasporasamfunn som består av personer av latinamerikansk avstamning i andre land. Se også 5PBCN <a href="/thema/nb/5PBCN">Personer av latinamerikansk avstamning</a>, 5PBCM <a href="/thema/nb/5PBCM">Personer av karibisk avstamning</a>',
                related: [
                  {
                    id: '5PBCM',
                    label: 'Personer av karibisk avstamning',
                    originalLabel: 'Personer av karibisk avstamning',
                    note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Karibia lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. en biografi om en person av jamaikansk avstamning som vokser opp i New York, bruk med 1KBB-US-NAKC. Foretrekk 1KJ* uten 5PBCB for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Karibia, eller bøker om folkeslag i Karibia',
                  },
                  {
                    id: '5PBCN',
                    label: 'Personer av latinamerikansk avstamning',
                    originalLabel: 'Personer av latinamerikansk avstamning',
                    note: 'Brukes for bøker som hovedsakelig handler om diasporasamfunn, både i historisk og nyere tid, hvor personer og grupper med avstamning fra Latin-Amerika lever i et annet geografisk område, eller en annen kultur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig, f.eks. ei bok om personer av meksikansk avstamning som bor i London, bruk med 1DDU-GB-ESL. Foretrekk 1KL* uten 5PBCB for bøker om personer, grupper eller folkeslag som bor i et spesifikt land eller en region i Latin-Amerika, eller bøker om latinamerikanske folkeslag. Se også 5PB-US-H <a href="/thema/nb/5PB-US-H">Latinamerikanere</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: '5PD',
            label: 'Sosioøkonomiske grupper / status',
            originalLabel: 'Sosioøkonomiske grupper / status',
            alternativeLabels: [],
            note: 'Bruk 5PD* for bøker som hovedsakelig handler om inndeling av grupper og personer etter kriterier som rikdom, inntekt, utdannelse, yrker, sosial status, sosial eller politisk makt, klasse etc., og den relative sosiale posisjonen innen sosiale grupper og kategorier, geografiske områder etc.',
            related: [],
            children: [
              {
                id: '5PDD',
                label: 'Lav sosioøkonomisk status',
                originalLabel: 'Lav sosioøkonomisk status',
                alternativeLabels: [],
                note: 'Her: arbeiderklassen, økonomisk vanskeligstilte, prekariat etc. Brukes for å angi at ei bok tar for seg denne gruppa, basert på ulike sosioøkonomiske faktorer, eller som tar for seg ulike aspekter ved klasse og status sett i sammenheng med andre temaer, enten som et generelt emne, eller fra en personlig synsvinkel, eller som ser på hvordan grupper av folk eller personer som har den laveste sosiale statusen, ofte også er det fattigste og har minst makt og innflytelse',
                related: [],
                children: [],
              },
              {
                id: '5PDH',
                label: 'Middels sosioøkonomisk status',
                originalLabel: 'Middels sosioøkonomisk status',
                alternativeLabels: [],
                note: 'Brukes for bøker som handler om konseptet ‘middelklassen’ basert på ulike sosioøkonomiske faktorer, eller som tar for seg ulike aspekter ved klasse og status sett i sammenheng med andre temaer, enten som et generelt emne, eller fra en personlig synsvinkel, eller som ser på hvordan grupper av folk eller personer som befinner seg midt på den sosioøkonomiske rangstigen',
                related: [],
                children: [],
              },
              {
                id: '5PDM',
                label: 'Høy sosioøkonomisk status',
                originalLabel: 'Høy sosioøkonomisk status',
                alternativeLabels: [],
                note: 'Brukes for bøker som handler om konseptet ‘overklassen’ basert på ulike sosioøkonomiske faktorer, eller som tar for seg ulike aspekter ved klasse og status sett i sammenheng med andre temaer, enten som et generelt emne, eller fra en personlig synsvinkel, eller som ser på hvordan grupper av folk eller personer som har den høyeste sosiale statusen, ofte de rikeste i et klassesamfunn, eller som har størst politisk, økonomisk og sosial makt og innflytelse',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '5PG',
            label: 'Religiøse grupper',
            originalLabel: 'Religiøse grupper',
            alternativeLabels: [],
            note: 'Bruk 5PG*-koder for materiale som hovedsakelig handler om religiøse folkeslag eller personer, grupper eller kulturer; eller om spesifikke religiøse grupper, eller materiale som er tilpasset ei religiøs gruppe. Brukes med JBSR i tillegg til andre emner. For titler som kun handler om den spesifikke religionen, klassifiseres disse kun med QR*-koder, og 5PG* er ikke nødvendig',
            related: [],
            children: [
              {
                id: '5PGC',
                label: 'Konfusianister',
                originalLabel: 'Konfusianister',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5PGD',
                label: 'Hinduer',
                originalLabel: 'Hinduer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5PGE',
                label: 'Sikher',
                originalLabel: 'Sikher',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5PGF',
                label: 'Buddhister',
                originalLabel: 'Buddhister',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5PGJ',
                label: 'Jøder',
                originalLabel: 'Jøder',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5PGM',
                label: 'Kristne',
                originalLabel: 'Kristne',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5PGP',
                label: 'Muslimer',
                originalLabel: 'Muslimer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5PGQ',
                label: 'Drusere',
                originalLabel: 'Drusere',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5PGS',
                label: 'Shintoister',
                originalLabel: 'Shintoister',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: '5PGT',
                label: 'Taoister',
                originalLabel: 'Taoister',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: '5PM',
            label: 'Personer med synlige eller usynlige funksjonsnedsettelser',
            originalLabel:
              'Personer med synlige eller usynlige funksjonsnedsettelser',
            alternativeLabels: ['funksjonshemmede', 'funksjonshemning'],
            note: 'Bruk 5PM*-koder for bøker hvor innholdets tematikk handler om den aktuelle gruppa av personer',
            related: [],
            children: [
              {
                id: '5PMB',
                label:
                  'Personer med funksjonsnedsettelser eller bevegelseshemninger',
                originalLabel:
                  'Personer med funksjonsnedsettelser eller bevegelseshemninger',
                alternativeLabels: ['funksjonshemmede', 'funksjonshemning'],
                note: 'Brukes for bøker som hovedsakelig handler om personer som har ulike former for funksjonsnedsettelser, inkludert rullestolbrukere, amputerte, ryggmargsbrokk etc.',
                related: [],
                children: [],
              },
              {
                id: '5PMD',
                label: 'Personer med synsnedsettelser eller synshemninger',
                originalLabel:
                  'Personer med synsnedsettelser eller synshemninger',
                alternativeLabels: ['blinde'],
                note: 'Brukes for bøker som hovedsakelig handler om personer med synsnedsettelser, som synstap eller blindhet',
                related: [],
                children: [],
              },
              {
                id: '5PMF',
                label:
                  'Personer med hørselsnedsettelser eller hørselshemninger',
                originalLabel:
                  'Personer med hørselsnedsettelser eller hørselshemninger',
                alternativeLabels: ['døve'],
                note: 'Brukes for bøker som hovedsakelig handler om personer med hørselsnedsettelser, som hørselstap eller døvhet',
                related: [],
                children: [],
              },
              {
                id: '5PMH',
                label: 'Personer på autismespekteret',
                originalLabel: 'Personer på autismespekteret',
                alternativeLabels: [],
                note: 'Brukes for bøker som hovedsakelig handler om personer med autisme eller Asperger syndrom',
                related: [],
                children: [],
              },
              {
                id: '5PMJ',
                label: 'Personer med lærevansker',
                originalLabel: 'Personer med lærevansker',
                alternativeLabels: ['lærevansker'],
                note: 'Brukes for bøker som hovedsakelig handler om personer med lære- eller lesevansker, inkludert dysleksi, hyperleksi, aleksi, dyskalkuli, dyspraksi, dysgrafi, dysfasi, afasi, auditive prosesseringsforstyrrelser (APD), ADHD, språkvansker, talevansker, lesevansker, matematikkvansker, motoriske forstyrrelser etc.',
                related: [],
                children: [],
              },
              {
                id: '5PMK',
                label: 'Personer med Downs syndrom',
                originalLabel: 'Personer med Downs syndrom',
                alternativeLabels: ['Trisomi 21'],
                note: 'Brukes for bøker som hovedsakelig handler om personer med Downs syndrom',
                related: [],
                children: [],
              },
              {
                id: '5PML',
                label: 'Personer med cerebral parese',
                originalLabel: 'Personer med cerebral parese',
                alternativeLabels: [],
                note: 'Brukes for bøker som hovedsakelig handler om personer med cerebral parese',
                related: [],
                children: [],
              },
              {
                id: '5PMN',
                label: 'Personer med degenerative sykdommer og tilstander',
                originalLabel:
                  'Personer med degenerative sykdommer og tilstander',
                alternativeLabels: [
                  'Spinal muskelatrofi',
                  'ALS',
                  'Amyotrofisk lateralsklerose',
                  'Lou Gehrig’s disease',
                  'multippel sklerose',
                ],
                note: 'Brukes for bøker som hovedsakelig handler om personer med degenerative tilstander, som demens, Alzheimers sykdom, Parkinsons sykdom, MS, motonevronsykdom etc.',
                related: [],
                children: [],
              },
              {
                id: '5PMP',
                label:
                  'Personer med usynlige eller skjulte funksjonsnedsettelser',
                originalLabel:
                  'Personer med usynlige eller skjulte funksjonsnedsettelser',
                alternativeLabels: [
                  'kroniske smerter',
                  'fatigue',
                  'søvnforstyrrelser',
                  'søvnløshet',
                  'mental sykdom',
                  'psykiske lidelser',
                  'funksjonshemmede',
                  'funksjonshemming',
                ],
                note: 'Brukes for bøker som hovedsakelig handler om personer med skjulte funksjonsnedsettelser- mentale eller fysiske - eller utfordringer som ikke umiddelbart er synlige for andre',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '5PS',
            label: 'LHBT+',
            originalLabel: 'LHBT+',
            alternativeLabels: [
              'homofili',
              'LGBTQ',
              'LHBT',
              'homofile',
              'lesbiske',
              'biseksuelle',
              'LHBTI',
              'LHBTI+',
              'LHBTIQ',
              'LHBTIQ+',
            ],
            note: 'Bruk 5PS*-koder for bøker som hovedsakelig er relatert til, eller som handler om personer eller grupper som identifiserer seg med/som LHBTIQ+, der det ikke fins en mer spesifikk kode. Brukes med andre emnekoder der det er hensiktsmessig, som f.eks. JBSJ, YXB, F*, XQ*, V*, YF*-koder etc. For bøker om f.eks. Pride, bruk 5HC. Foretrekk mer spesifikke kategorier for bøker om lesbiske, homofile, biseksuelle, panseksuelle, aseksuelle eller transpersoner',
            related: [],
            children: [
              {
                id: '5PSB',
                label: 'Biseksuelle eller panseksuelle',
                originalLabel: 'Biseksuelle eller panseksuelle',
                alternativeLabels: ['biseksualitet', 'panseksualitet'],
                note: 'Brukes for bøker som er relatert til, eller handler om biseksuelle, polyseksuelle eller panseksuelle. Brukes med andre emnekoder der det er hensiktsmessig, som f.eks. BSJ, YXB, F*, YF*, XQ*-koder etc.',
                related: [],
                children: [],
              },
              {
                id: '5PSG',
                label: 'Homofile',
                originalLabel: 'Homofile',
                alternativeLabels: ['homofili'],
                note: 'Brukes for bøker som er relatert til eller som handler om homofile eller personer og grupper som identifiserer seg som homofile. Brukes med andre emnekoder der det er hensiktsmessig, som f.eks. JBSJ, YXB, F*, YF*, XQ*-koder etc.',
                related: [],
                children: [],
              },
              {
                id: '5PSL',
                label: 'Lesbiske',
                originalLabel: 'Lesbiske',
                alternativeLabels: [],
                note: 'Brukes for bøker som er relatert til eller handler om lesbiske. Brukes med andre emnekoder der det er hensiktsmessig, som f.eks. JBSJ, YXB, F*, YF*, XQ*-koder etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: '5PT',
            label: 'Transpersoner',
            originalLabel: 'Transpersoner',
            alternativeLabels: ['enby', 'hirja'],
            note: 'Her: kjønnsminoriteter, transseksuelle, transkjønnede, ikke-binære etc. Brukes for bøker som er relatert til eller handler om de som har et kjønn som helt eller delvis avviker fra det de ble tildelt ved fødselen; kjønn i samfunn hvor man har definert at det fins mer enn to kjønn. Brukes med andre emnekoder der det er hensiktsmessig, som f.eks. JBSF3, YXB, F*, V*-koder etc.',
            related: [],
            children: [],
          },
          {
            id: '5PU',
            label: 'Interseksuelle personer',
            originalLabel: 'Interseksuelle personer',
            alternativeLabels: ['interseksualitet', 'intersex'],
            note: 'Brukes for bøker som er relatert til eller handler om personer med interseksuelle trekk eller variasjoner',
            related: [],
            children: [],
          },
          {
            id: '5PV',
            label: 'Aseksuelle personer',
            originalLabel: 'Aseksuelle personer',
            alternativeLabels: ['Ace-miljøet'],
            note: 'Brukes for bøker som er relatert til eller handler om aseksuelle og/eller aromantiske personer, eller aseksualitet',
            related: [],
            children: [],
          },
          {
            id: '5PX',
            label: 'Spesielle kulturelle temaer',
            originalLabel: 'Spesielle kulturelle temaer',
            alternativeLabels: [],
            note: 'Bruk 5PX*-koder for temaer som ikke er dekket andre steder, men som er viktige i nasjonale markeder og bransjer, og som ikke kan uttrykkes ved å bruke andre emnekoder eller kvalifikatorer. NB!: 5PX bør følges av informasjon i de beskrivende metadataene, som forklarer hvorfor denne kvalifikatoren blir brukt',
            related: [],
            children: [
              {
                id: '5PX-GB-S',
                label: 'Shakespeare',
                originalLabel: 'Shakespeare',
                alternativeLabels: [],
                note: 'Brukes for alle utgivelser som kan settes i ei egen Shakespeare-avdeling',
                related: [],
                children: [],
              },
              {
                id: '5PX-XA-A',
                label: 'Kong Arthur',
                originalLabel: 'Kong Arthur',
                alternativeLabels: [],
                note: 'Brukes med andre emnekoder for å angi at bøker er om eller inspirert av legendene om Kong Arthur eller ‘Britiske emner’ (Matter of Britain), f.eks. med FN*-, YFJ eller YFH*-koder for arthuriansk fantasylitteratur',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: '5X',
        label: 'Inneholder eksplisitt eller støtende materiale',
        originalLabel: 'Inneholder eksplisitt eller støtende materiale',
        alternativeLabels: [],
        note: 'Brukes for bøker som inneholder scener eller ord som kan virke støtende på en uforberedt leser, vanligvis av seksuell, voldelig eller diskriminerende art, og som derfor passer best for modne ungdommer eller et voksent publikum, og hvor det oppfattes som viktig at leseren blir gjort oppmerksom på at innholdet kanskje ikke passer for alle. Dersom 5X brukes sammen med en Y*-kode det er sterkt anbefalt at en forklaring sendes i et annet metadatafelt og at en passende 5A*-kvalifikator er angitt',
        related: [],
        children: [],
      },
      {
        id: '5Y',
        label: 'Spesielle aspekter ved innholdet',
        originalLabel: 'Spesielle aspekter ved innholdet',
        alternativeLabels: [],
        note: 'Bruk 5Y* for å beskrive et spesielt aspekt ved innholdet (ikke formatet) i boka, som ikke er dekket av andre kvalifikatorer. NB!: 5Y bør følges av beskrivende metadata som forklarer hvorfor kvalifikatoren er brukt',
        related: [],
        children: [
          {
            id: '5YA',
            label: 'Ikke-stereotypiske kjønnsroller',
            originalLabel: 'Ikke-stereotypiske kjønnsroller',
            alternativeLabels: [],
            note: 'Brukes for bøker som er spesielt skrevet for å utfordre kjønnsroller, normer knyttet til dette eller positiv representasjon av ikke-stereotypiske kjønnsroller, f.eks. YBC*- eller YF*-koder for historier for barn. NB!: 5Y* bør følges av informasjon i de beskrivende metadataene, som forklarer hvorfor denne kvalifikatoren blir brukt',
            related: [],
            children: [],
          },
          {
            id: '5YS',
            label: 'FNs bærekraftsmål',
            originalLabel: 'FNs bærekraftsmål',
            alternativeLabels: [],
            note: 'Brukes for bøker som handler om eller tar for seg et eller flere av FNs mål for bærekraftig utvikling. NB!: beskrivende metadata bør inneholde detaljer om det enkelte bærekraftsmålet (1 til 17) og forklaring til hvorfor denne kvalifikatoren er brukt',
            related: [],
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: '6',
    label: 'Kvalifikatorer: stilretninger',
    originalLabel: 'Kvalifikatorer: stilretninger',
    alternativeLabels: [],
    note: 'Bruk kvalifikatorer for stilretninger MED andre passende emnekoder og med kvalifikatorer for geografi der det er hensiktsmessig. Ikke bruk ‘6’ på de enkelte bøkene, men velg kvalifikatorer fra seksjon 6* der det er hensiktsmessig',
    related: [],
    children: [
      {
        id: '6A',
        label: 'Stiler (A)',
        originalLabel: 'Stiler (A)',
        alternativeLabels: [],
        note: 'Koden 6A skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på A i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6AA',
            label: 'Abstraktisme',
            originalLabel: 'Abstraktisme',
            alternativeLabels: [],
            note: 'Her: abstrakte stilretninger innen kunst',
            related: [],
            children: [],
          },
          {
            id: '6AB',
            label: 'Abstrakt ekspresjonisme',
            originalLabel: 'Abstrakt ekspresjonisme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6AC',
            label: 'Art Deco',
            originalLabel: 'Art Deco',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6AD',
            label: 'Art Nouveau',
            originalLabel: 'Art Nouveau',
            alternativeLabels: [
              'jugendstil',
              'style nouille',
              'stile liberty',
              'glasgow school',
              'glasgow-skolen',
              'nieuwe kunst',
              'tiffany style',
              'Arte joven',
              'Szecesszió',
            ],
            note: 'Brukes for alle navnevarianter av stilarten på ulike språk og i ulike land. Se også 6BD <a href="/thema/nb/6BD">Berlin-secesjonen</a>, 6JG <a href="/thema/nb/6JG">Jugendstil</a>, 6SG <a href="/thema/nb/6SG">Utbrytergrupper</a>, 6VA <a href="/thema/nb/6VA">Wiener sezession</a>',
            related: [
              {
                id: '6BD',
                label: 'Berlin-secesjonen',
                originalLabel: 'Berlin-secesjonen',
              },
              {
                id: '6JG',
                label: 'Jugendstil',
                originalLabel: 'Jugendstil',
                note: 'Se også 6AD <a href="/thema/nb/6AD">Art Nouveau</a>',
              },
              {
                id: '6SG',
                label: 'Utbrytergrupper',
                originalLabel: 'Utbrytergrupper',
                note: 'Her: stilretninger fra ulike utbrytergrupper som ikke har en egen kode. Se også 6AD <a href="/thema/nb/6AD">Art Nouveau</a>, 6BD <a href="/thema/nb/6BD">Berlin-secesjonen</a>, 6VA <a href="/thema/nb/6VA">Wiener sezession</a>',
              },
              {
                id: '6VA',
                label: 'Wiener sezession',
                originalLabel: 'Wiener sezession',
                note: 'Her: Vereinigung Bildender Künstler Österreichs. Se også 6AD <a href="/thema/nb/6AD">Art Nouveau</a>, 6BD <a href="/thema/nb/6BD">Berlin-secesjonen</a>, 6SG <a href="/thema/nb/6SG">Utbrytergrupper</a>',
              },
            ],
            children: [],
          },
          {
            id: '6AF',
            label: 'Arts and craft-stil',
            originalLabel: 'Arts and craft-stil',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6AG',
            label: 'Akademisk kunst, akademisme',
            originalLabel: 'Akademisk kunst, akademisme',
            alternativeLabels: [],
            note: 'Her: Beaux-Arts',
            related: [],
            children: [],
          },
          {
            id: '6AH',
            label: 'Estetisme',
            originalLabel: 'Estetisme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6AJ',
            label: 'Altermodernismen',
            originalLabel: 'Altermodernismen',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6AK',
            label: 'Analytisk kubisme',
            originalLabel: 'Analytisk kubisme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6AL',
            label: 'Arbeitsrat für Kunst',
            originalLabel: 'Arbeitsrat für Kunst',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6AM',
            label: 'Art Informel',
            originalLabel: 'Art Informel',
            alternativeLabels: [],
            note: 'Se også 6TB <a href="/thema/nb/6TB">Tachisme</a>',
            related: [
              {
                id: '6TB',
                label: 'Tachisme',
                originalLabel: 'Tachisme',
              },
            ],
            children: [],
          },
          {
            id: '6AN',
            label: 'Arte Povera',
            originalLabel: 'Arte Povera',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6AP',
            label: 'Assemblage',
            originalLabel: 'Assemblage',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6AQ',
            label: 'Avantgarde',
            originalLabel: 'Avantgarde',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6AR',
            label: 'Afrofuturisme',
            originalLabel: 'Afrofuturisme',
            alternativeLabels: [],
            note: 'Brukes for den retninga innen estetikk, vitenskapsfilosofi og filosofihistorie som utforsker knytter kulturuttrykk i afrikanske diasopramiljøer sammen med teknologi',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6B',
        label: 'Stiler (B)',
        originalLabel: 'Stiler (B)',
        alternativeLabels: [],
        note: 'Koden 6B skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på B i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6BA',
            label: 'Barokk',
            originalLabel: 'Barokk',
            alternativeLabels: [],
            note: 'Her: fransk klassisisme, Petrine Baroque',
            related: [],
            children: [],
          },
          {
            id: '6BB',
            label: 'Barbizonskolen',
            originalLabel: 'Barbizonskolen',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6BC',
            label: 'Bauhaus',
            originalLabel: 'Bauhaus',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6BD',
            label: 'Berlin-secesjonen',
            originalLabel: 'Berlin-secesjonen',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6BF',
            label: 'Biedermeier',
            originalLabel: 'Biedermeier',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6BG',
            label: 'Beat',
            originalLabel: 'Beat',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6BH',
            label: 'Bebop',
            originalLabel: 'Bebop',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6BJ',
            label: 'Der Blaue Reiter',
            originalLabel: 'Der Blaue Reiter',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6BK',
            label: 'Bloomsburygruppen',
            originalLabel: 'Bloomsburygruppen',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6BL',
            label: 'Bluegrass',
            originalLabel: 'Bluegrass',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6BM',
            label: 'Blues',
            originalLabel: 'Blues',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6BN',
            label: 'Die Brücke',
            originalLabel: 'Die Brücke',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6BP',
            label: 'Bysantinsk stil',
            originalLabel: 'Bysantinsk stil',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6BQ',
            label: 'Brutalisme',
            originalLabel: 'Brutalisme',
            alternativeLabels: [],
            note: 'Her: brutalistiske stilarter, Béton brut, proto-brutalisme',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6C',
        label: 'Stiler (C)',
        originalLabel: 'Stiler (C)',
        alternativeLabels: [],
        note: 'Koden 6C skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på C i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6CA',
            label: 'Klassisk stil',
            originalLabel: 'Klassisk stil',
            alternativeLabels: [],
            note: 'Her: klassisisme',
            related: [],
            children: [],
          },
          {
            id: '6CB',
            label: 'Kubismen',
            originalLabel: 'Kubismen',
            alternativeLabels: [],
            note: 'Her: kubistiske stilarter, orfisme',
            related: [],
            children: [],
          },
          {
            id: '6CC',
            label: 'Keltisk stil',
            originalLabel: 'Keltisk stil',
            alternativeLabels: [],
            note: 'Her: La Tène, keltisk renessanse, insulær eller hiberno-saksisk kunst',
            related: [],
            children: [],
          },
          {
            id: '6CD',
            label: 'Camden Town Group',
            originalLabel: 'Camden Town Group',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6CF',
            label: 'Cloisonnisme',
            originalLabel: 'Cloisonnisme',
            alternativeLabels: [],
            note: 'Her: cloisonnistiske stilarter',
            related: [],
            children: [],
          },
          {
            id: '6CG',
            label: 'CoBrA',
            originalLabel: 'CoBrA',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6CH',
            label: 'Color-field painting',
            originalLabel: 'Color-field painting',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6CJ',
            label: 'Datakunst',
            originalLabel: 'Datakunst',
            alternativeLabels: [],
            note: 'Her: multimedia, digitale stilarter innen kunst og kryptokunst',
            related: [],
            children: [],
          },
          {
            id: '6CK',
            label: 'Konseptualisme',
            originalLabel: 'Konseptualisme',
            alternativeLabels: [],
            note: 'Her: konseptuelle kunstretninger, neo-geometrisske stilarter',
            related: [],
            children: [],
          },
          {
            id: '6CL',
            label: 'Konstruktivisme',
            originalLabel: 'Konstruktivisme',
            alternativeLabels: [],
            note: 'Her: konstruktivistiske stilarter',
            related: [],
            children: [],
          },
          {
            id: '6CM',
            label: 'Country og western',
            originalLabel: 'Country og western',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6CN',
            label: 'Kubo-futurisme',
            originalLabel: 'Kubo-futurisme',
            alternativeLabels: [],
            note: 'Her: russiske futurister',
            related: [],
            children: [],
          },
          {
            id: '6CP',
            label: 'Colonial style',
            originalLabel: 'Colonial style',
            alternativeLabels: [],
            note: 'Brukes for retning innen amerikansk arkitektur. Brukes med kvalifikatorer for geografi der det er hensiktsmessig',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6D',
        label: 'Stiler (D)',
        originalLabel: 'Stiler (D)',
        alternativeLabels: [],
        note: 'Koden 6D skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på D i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6DA',
            label: 'Dadaisme',
            originalLabel: 'Dadaisme',
            alternativeLabels: [],
            note: 'Her: dada',
            related: [],
            children: [],
          },
          {
            id: '6DB',
            label: 'Divisjonisme',
            originalLabel: 'Divisjonisme',
            alternativeLabels: [],
            note: 'Her: pointillisme, divisjonistiske stilarter',
            related: [],
            children: [],
          },
          {
            id: '6DC',
            label: 'Düsseldorf-skolen',
            originalLabel: 'Düsseldorf-skolen',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6E',
        label: 'Stiler (E)',
        originalLabel: 'Stiler (E)',
        alternativeLabels: [],
        note: 'Koden 6E skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på E i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6EA',
            label: 'Empirestil',
            originalLabel: 'Empirestil',
            alternativeLabels: [],
            note: 'Her: federal style (amerikansk stilretning innen arkitektur). Se også 6NH <a href="/thema/nb/6NH">Neoklassisisme</a>',
            related: [
              {
                id: '6NH',
                label: 'Neoklassisisme',
                originalLabel: 'Neoklassisisme',
                note: 'Her: Louis Seize',
              },
            ],
            children: [],
          },
          {
            id: '6EB',
            label: 'Easy listening',
            originalLabel: 'Easy listening',
            alternativeLabels: [],
            note: 'Her: ambient, lounge, chill-out',
            related: [],
            children: [],
          },
          {
            id: '6EC',
            label: 'The Eight',
            originalLabel: 'The Eight',
            alternativeLabels: [],
            note: 'For Ashcan-skolen: angi også kvalifikator 1KBB for USA. For The Eight (A Nyolcak): angi også kvalifikator 1DTH for Ungarn',
            related: [],
            children: [],
          },
          {
            id: '6ED',
            label: 'Etruskisk stil',
            originalLabel: 'Etruskisk stil',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6EF',
            label: 'Ekspresjonisme',
            originalLabel: 'Ekspresjonisme',
            alternativeLabels: [],
            note: 'Her: ekspresjonistiske stilarter',
            related: [],
            children: [],
          },
          {
            id: '6EG',
            label: 'Egyptisk stil: oldtiden',
            originalLabel: 'Egyptisk stil: oldtiden',
            alternativeLabels: [],
            note: 'Her: ptolemeiske stilarter',
            related: [],
            children: [],
          },
          {
            id: '6EH',
            label: 'Episk',
            originalLabel: 'Episk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6EJ',
            label: 'Elegisk',
            originalLabel: 'Elegisk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6EK',
            label: 'EDM (elektronisk dansemusikk)',
            originalLabel: 'EDM (elektronisk dansemusikk)',
            alternativeLabels: [],
            note: 'Her: tekno, acid, house, rave, trance, dubstep, Eurodance, IDM og andre stilarter innen EDM',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6F',
        label: 'Stiler (F)',
        originalLabel: 'Stiler (F)',
        alternativeLabels: [],
        note: 'Koden 6F skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på F i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6FA',
            label: 'Fauvisme',
            originalLabel: 'Fauvisme',
            alternativeLabels: [],
            note: 'Her: fauvister',
            related: [],
            children: [],
          },
          {
            id: '6FB',
            label: 'Fado',
            originalLabel: 'Fado',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6FC',
            label: 'Flamenco',
            originalLabel: 'Flamenco',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6FD',
            label: 'Folk, folkloristiske stilretninger',
            originalLabel: 'Folk, folkloristiske stilretninger',
            alternativeLabels: [],
            note: 'Brukes for folkemusikk, folkedans, stilretninger innen kunst, mote, kunsthåndverk, møbler etc.',
            related: [],
            children: [],
          },
          {
            id: '6FF',
            label: 'Futurisme',
            originalLabel: 'Futurisme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6FG',
            label: 'Fantasykunst',
            originalLabel: 'Fantasykunst',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6G',
        label: 'Stiler (G)',
        originalLabel: 'Stiler (G)',
        alternativeLabels: [],
        note: 'Koden 6G skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på G i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6GA',
            label: 'Gotisk stil',
            originalLabel: 'Gotisk stil',
            alternativeLabels: [],
            note: 'Her: ny-gotikk, gotisk renessanse. Brukes for gotiske stilretninger fra ulike perioder og regioner',
            related: [],
            children: [],
          },
          {
            id: '6GB',
            label: 'Georgiansk stil',
            originalLabel: 'Georgiansk stil',
            alternativeLabels: [],
            note: 'Her: ny-gregoriansk stil, gregoriansk renessanse',
            related: [],
            children: [],
          },
          {
            id: '6GC',
            label: 'Gresk stil: antikken',
            originalLabel: 'Gresk stil: antikken',
            alternativeLabels: [],
            note: 'Her: hellenistiske stilretninger',
            related: [],
            children: [],
          },
          {
            id: '6GD',
            label: 'Gospel',
            originalLabel: 'Gospel',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6GE',
            label: 'Gaucho',
            originalLabel: 'Gaucho',
            alternativeLabels: ['Gauchoesque style', 'gauchesco'],
            note: 'Litterær stilretning i Sør-Amerika',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6H',
        label: 'Stiler (H)',
        originalLabel: 'Stiler (H)',
        alternativeLabels: [],
        note: 'Koden 6H skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på H i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6HA',
            label: 'Metal, Heavy metal',
            originalLabel: 'Metal, Heavy metal',
            alternativeLabels: ['black metal'],
            note: 'Brukes for alle stilretninger som forbindes med metal',
            related: [],
            children: [],
          },
          {
            id: '6HB',
            label: 'Hagueskolen',
            originalLabel: 'Hagueskolen',
            alternativeLabels: [],
            note: 'Her: Gray-skolen',
            related: [],
            children: [],
          },
          {
            id: '6HC',
            label: 'Heidelbergskolen',
            originalLabel: 'Heidelbergskolen',
            alternativeLabels: [],
            note: 'Her: australsk impresjonisme',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6J',
        label: 'Stiler (I J)',
        originalLabel: 'Stiler (I J)',
        alternativeLabels: [],
        note: 'Koden 6J skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på I eller J i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6JA',
            label: 'Impresjonisme',
            originalLabel: 'Impresjonisme',
            alternativeLabels: [],
            note: 'Her: impresjonistiske stilretninger',
            related: [],
            children: [],
          },
          {
            id: '6JB',
            label: 'Iberisk stil',
            originalLabel: 'Iberisk stil',
            alternativeLabels: [],
            note: 'Her: Arte ibérico',
            related: [],
            children: [],
          },
          {
            id: '6JC',
            label: 'Indie',
            originalLabel: 'Indie',
            alternativeLabels: [],
            note: 'Her: stilarter som omtales som alternative, uavhengige, undergrunn',
            related: [],
            children: [],
          },
          {
            id: '6JD',
            label: 'Jazz',
            originalLabel: 'Jazz',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6JF',
            label: 'Jack of Diamonds',
            originalLabel: 'Jack of Diamonds',
            alternativeLabels: [],
            note: 'Her: Bubnovyi Valet / Knave of Diamonds',
            related: [],
            children: [],
          },
          {
            id: '6JG',
            label: 'Jugendstil',
            originalLabel: 'Jugendstil',
            alternativeLabels: [],
            note: 'Se også 6AD <a href="/thema/nb/6AD">Art Nouveau</a>',
            related: [
              {
                id: '6AD',
                label: 'Art Nouveau',
                originalLabel: 'Art Nouveau',
                note: 'Brukes for alle navnevarianter av stilarten på ulike språk og i ulike land. Se også 6BD <a href="/thema/nb/6BD">Berlin-secesjonen</a>, 6JG <a href="/thema/nb/6JG">Jugendstil</a>, 6SG <a href="/thema/nb/6SG">Utbrytergrupper</a>, 6VA <a href="/thema/nb/6VA">Wiener sezession</a>',
              },
            ],
            children: [],
          },
          {
            id: '6JL',
            label: 'Islamske stilretninger',
            originalLabel: 'Islamske stilretninger',
            alternativeLabels: [],
            note: 'Brukes med koder fra A* for å angi hvilke kunsttyper det er snakk om',
            related: [],
            children: [],
          },
          {
            id: '6JN',
            label: 'Urfolk (stilretninger)',
            originalLabel: 'Urfolk (stilretninger)',
            alternativeLabels: [],
            note: 'Brukes for stilretninger som man forbinder med folk som identifiserer seg som urfolk, og hvor det ikke fins en mer spesifikk kode. Brukes med kvalifikatorer for geografi der det er hensiktsmessig',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6K',
        label: 'Stiler (K)',
        originalLabel: 'Stiler (K)',
        alternativeLabels: [],
        note: 'Koden 6K skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på K i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6KA',
            label: 'K-pop',
            originalLabel: 'K-pop',
            alternativeLabels: ['Korean pop', 'koreansk pop'],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6L',
        label: 'Stiler (L)',
        originalLabel: 'Stiler (L)',
        alternativeLabels: [],
        note: 'Koden 6L skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på L i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6LA',
            label: 'Lettrisme',
            originalLabel: 'Lettrisme',
            alternativeLabels: [],
            note: 'Her: hypergrafi, metagrafi, lettrister',
            related: [],
            children: [],
          },
          {
            id: '6LB',
            label: 'Lyrisk',
            originalLabel: 'Lyrisk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6LC',
            label: 'Latin',
            originalLabel: 'Latin',
            alternativeLabels: [],
            note: 'Brukes med musikk, kunst, arkitektur, mote ofte i forbindelse med latinamerikansk eller latino kultur. Brukes med andre stilkvalifikatorer og kvalifikatorer for geografi der det er hensiktsmessig',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6M',
        label: 'Stiler (M)',
        originalLabel: 'Stiler (M)',
        alternativeLabels: [],
        note: 'Koden 6M skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på M i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6MA',
            label: 'Manierisme',
            originalLabel: 'Manierisme',
            alternativeLabels: [],
            note: 'Her: menieristiske stilretninger',
            related: [],
            children: [],
          },
          {
            id: '6MB',
            label: 'Middelalderstil',
            originalLabel: 'Middelalderstil',
            alternativeLabels: [],
            note: 'Brukes for stilretninger fra middelalderen som ikke har egne koder',
            related: [],
            children: [],
          },
          {
            id: '6MC',
            label: 'Modernisme',
            originalLabel: 'Modernisme',
            alternativeLabels: [],
            note: 'Her: modernistiske stilretninger',
            related: [],
            children: [],
          },
          {
            id: '6MD',
            label: 'Macchaioli',
            originalLabel: 'Macchaioli',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6MF',
            label: 'Minimalisme',
            originalLabel: 'Minimalisme',
            alternativeLabels: [],
            note: 'Her: minimalistiske stilretninger',
            related: [],
            children: [],
          },
          {
            id: '6MG',
            label: 'Minoisk stil',
            originalLabel: 'Minoisk stil',
            alternativeLabels: [],
            note: 'Her: stilretninger fra antikkens Kreta',
            related: [],
            children: [],
          },
          {
            id: '6MH',
            label: 'Mir iskusstva',
            originalLabel: 'Mir iskusstva',
            alternativeLabels: [],
            note: 'Her: Miriskusniki',
            related: [],
            children: [],
          },
          {
            id: '6MJ',
            label: 'Mozarabisk stil',
            originalLabel: 'Mozarabisk stil',
            alternativeLabels: [],
            note: 'Her: Repoblación',
            related: [],
            children: [],
          },
          {
            id: '6MK',
            label: 'Mykensk stil',
            originalLabel: 'Mykensk stil',
            alternativeLabels: [],
            note: 'Her: kyklopiske stilretninger',
            related: [],
            children: [],
          },
          {
            id: '6MM',
            label: 'Mid-century modern',
            originalLabel: 'Mid-century modern',
            alternativeLabels: [],
            note: 'Amerikansk stilretning innen design',
            related: [],
            children: [],
          },
          {
            id: '6MN',
            label: 'Muralisme',
            originalLabel: 'Muralisme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6MQ',
            label: 'Mogul (stilretning)',
            originalLabel: 'Mogul (stilretning)',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6MR',
            label: 'Mariachi og andre regionale meksikanske stilarter',
            originalLabel: 'Mariachi og andre regionale meksikanske stilarter',
            alternativeLabels: [],
            note: 'Class here: Banda, Duranguense, Grupera, New Mexico music, Norteño, Tejano, Tierra Caliente, Son mexicano and other associated styles',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6N',
        label: 'Stiler (N O)',
        originalLabel: 'Stiler (N O)',
        alternativeLabels: [],
        note: 'Koden 6N skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på N eller O i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6NA',
            label: 'Naivisme',
            originalLabel: 'Naivisme',
            alternativeLabels: [],
            note: 'Her: Naïf art, Grupa Zemlja, Earth group, Hlebine School',
            related: [],
            children: [],
          },
          {
            id: '6NB',
            label: 'Naturalisme',
            originalLabel: 'Naturalisme',
            alternativeLabels: [],
            note: 'Her: naturalistiske stilretninger',
            related: [],
            children: [],
          },
          {
            id: '6NC',
            label: 'Op art, Kinetic art',
            originalLabel: 'Op art, Kinetic art',
            alternativeLabels: [],
            note: 'Her: optisk kunst',
            related: [],
            children: [],
          },
          {
            id: '6ND',
            label: 'Outsiderkunst, Art brut',
            originalLabel: 'Outsiderkunst, Art brut',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6NE',
            label: 'Orientalisme',
            originalLabel: 'Orientalisme',
            alternativeLabels: [],
            note: 'Her: vestlige kunst- og designretninger fra 1800-tallet som gjengir scener og objekter fra Midt-Østen og Asia, eller som tilpasser seg orientalske stilretninger. Brukes IKKE for kunst fra land i Asia',
            related: [],
            children: [],
          },
          {
            id: '6NF',
            label: 'Les nabis',
            originalLabel: 'Les nabis',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6NG',
            label: 'Den nasarenske bevegelse',
            originalLabel: 'Den nasarenske bevegelse',
            alternativeLabels: [],
            note: 'Her: Lukasbund',
            related: [],
            children: [],
          },
          {
            id: '6NH',
            label: 'Neoklassisisme',
            originalLabel: 'Neoklassisisme',
            alternativeLabels: [],
            note: 'Her: Louis Seize',
            related: [],
            children: [],
          },
          {
            id: '6NJ',
            label: 'Neoimpresjonisme',
            originalLabel: 'Neoimpresjonisme',
            alternativeLabels: [],
            note: 'Her: luminisme',
            related: [],
            children: [],
          },
          {
            id: '6NK',
            label: 'New age',
            originalLabel: 'New age',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6NL',
            label: 'Norwich-skolen',
            originalLabel: 'Norwich-skolen',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6NM',
            label: 'Orfisme',
            originalLabel: 'Orfisme',
            alternativeLabels: [],
            note: 'Her: orfisk kubisme',
            related: [],
            children: [],
          },
          {
            id: '6NN',
            label: 'Neorealisme',
            originalLabel: 'Neorealisme',
            alternativeLabels: [],
            note: 'Class here: Neorealismo, Nouveau réalisme, Neo-realism, New realism',
            related: [],
            children: [],
          },
          {
            id: '6NW',
            label: 'New wave',
            originalLabel: 'New wave',
            alternativeLabels: ['ny bølge'],
            note: 'Brukes for nouvelle vague i film etc., samt mursikkretninger innen post-punk',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6P',
        label: 'Stiler (P)',
        originalLabel: 'Stiler (P)',
        alternativeLabels: [],
        note: 'Koden 6P skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på P i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6PA',
            label: 'Pop art',
            originalLabel: 'Pop art',
            alternativeLabels: [],
            note: 'Her: proto-pop',
            related: [],
            children: [],
          },
          {
            id: '6PB',
            label: 'Popmusikk',
            originalLabel: 'Popmusikk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6PC',
            label: 'Postimpresjonisme',
            originalLabel: 'Postimpresjonisme',
            alternativeLabels: [],
            note: 'Her: Pont-Aven-skolen. Se også 6CF <a href="/thema/nb/6CF">Cloisonnisme</a>, 6NF <a href="/thema/nb/6NF">Les nabis</a>, 6NJ <a href="/thema/nb/6NJ">Neoimpresjonisme</a>, 6ST <a href="/thema/nb/6ST">Symbolisme</a>, 6SV <a href="/thema/nb/6SV">Syntetisme</a>',
            related: [
              {
                id: '6CF',
                label: 'Cloisonnisme',
                originalLabel: 'Cloisonnisme',
                note: 'Her: cloisonnistiske stilarter',
              },
              {
                id: '6NF',
                label: 'Les nabis',
                originalLabel: 'Les nabis',
              },
              {
                id: '6NJ',
                label: 'Neoimpresjonisme',
                originalLabel: 'Neoimpresjonisme',
                note: 'Her: luminisme',
              },
              {
                id: '6ST',
                label: 'Symbolisme',
                originalLabel: 'Symbolisme',
              },
              {
                id: '6SV',
                label: 'Syntetisme',
                originalLabel: 'Syntetisme',
              },
            ],
            children: [],
          },
          {
            id: '6PD',
            label: 'Postmodernisme',
            originalLabel: 'Postmodernisme',
            alternativeLabels: [],
            note: 'Her: superflat',
            related: [],
            children: [],
          },
          {
            id: '6PF',
            label: 'Peredvizjniki',
            originalLabel: 'Peredvizjniki',
            alternativeLabels: [],
            note: 'Her: stilretninger som forbindes med The Wanderers (Russland)',
            related: [],
            children: [],
          },
          {
            id: '6PG',
            label: 'La pittura metafisica',
            originalLabel: 'La pittura metafisica',
            alternativeLabels: [],
            note: 'Her: metafysiske stilretninger innen kunst',
            related: [],
            children: [],
          },
          {
            id: '6PH',
            label: 'Piontillisme',
            originalLabel: 'Piontillisme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6PJ',
            label: 'Førhistoriske stiler',
            originalLabel: 'Førhistoriske stiler',
            alternativeLabels: [],
            note: 'Her: hulemalerier, helleristninger, bergkunst',
            related: [],
            children: [],
          },
          {
            id: '6PK',
            label: 'Progrock',
            originalLabel: 'Progrock',
            alternativeLabels: [],
            note: 'Her: progressive stilretninger innen rock',
            related: [],
            children: [],
          },
          {
            id: '6PL',
            label: 'Prerafaelismen',
            originalLabel: 'Prerafaelismen',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6PM',
            label: 'Psykedelisk stil',
            originalLabel: 'Psykedelisk stil',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6PN',
            label: 'Punk',
            originalLabel: 'Punk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6PP',
            label: 'Purisme',
            originalLabel: 'Purisme',
            alternativeLabels: [],
            note: 'Brukes for purisme (kunstbevegelse på 1900-tallet). For purisme i spansk arkitektur, angi kvalifikator 1DSE* for Spania, pluss emnekode for arkitektur',
            related: [],
            children: [],
          },
          {
            id: '6PQ',
            label: 'Førkolumbisk kunst',
            originalLabel: 'Førkolumbisk kunst',
            alternativeLabels: [],
            note: 'Brukes for stilretninger som forbindes med urbefolkning i Amerika og Karibia, før kontakt med europeerne oppsto. Brukes med kvalifikatorer for geografi der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: '6PR',
            label: 'Persiske / iranske stilretninger',
            originalLabel: 'Persiske / iranske stilretninger',
            alternativeLabels: [],
            note: 'Her: Safavid, Qajar. Brukes med koder fra A*',
            related: [],
            children: [],
          },
          {
            id: '6PS',
            label: 'Postkoloniale stilretninger',
            originalLabel: 'Postkoloniale stilretninger',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6Q',
        label: 'Stiler (Q)',
        originalLabel: 'Stiler (Q)',
        alternativeLabels: [],
        note: 'Koden 6Q skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på Q i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6QA',
            label: 'Queen Anne-stil',
            originalLabel: 'Queen Anne-stil',
            alternativeLabels: [],
            note: 'Her: engelsk barokk',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6R',
        label: 'Stiler (R)',
        originalLabel: 'Stiler (R)',
        alternativeLabels: [],
        note: 'Koden 6R skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på R i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6RA',
            label: 'Romantikken',
            originalLabel: 'Romantikken',
            alternativeLabels: [],
            note: 'Brukes med AV* for romantikken som musikksjanger, eller med andre A*-koder for romantikk som sjanger innen andre kunstarter, eller med DC* for romantikk som en sjanger innen lyrikk',
            related: [],
            children: [],
          },
          {
            id: '6RB',
            label: 'Romansk stil',
            originalLabel: 'Romansk stil',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6RC',
            label: 'Renessansestil',
            originalLabel: 'Renessansestil',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6RD',
            label: 'Rokokko',
            originalLabel: 'Rokokko',
            alternativeLabels: [],
            note: 'Her: rocaille',
            related: [],
            children: [],
          },
          {
            id: '6RE',
            label: 'Regency-perioden',
            originalLabel: 'Regency-perioden',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6RF',
            label: 'Rock',
            originalLabel: 'Rock',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6RG',
            label: 'Rock and roll',
            originalLabel: 'Rock and roll',
            alternativeLabels: ['rock n roll', 'rock & roll'],

            related: [],
            children: [],
          },
          {
            id: '6RH',
            label: 'Rythm and blues, R’n’B',
            originalLabel: 'Rythm and blues, R’n’B',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6RJ',
            label: 'Rap og hiphop',
            originalLabel: 'Rap og hiphop',
            alternativeLabels: [],
            note: 'Her: rapping, trip hop, neo soul. Brukes for bøker om musikk, dans, design, mote etc.',
            related: [],
            children: [],
          },
          {
            id: '6RK',
            label: 'Reggae og ska',
            originalLabel: 'Reggae og ska',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6RL',
            label: 'Rayonnisme',
            originalLabel: 'Rayonnisme',
            alternativeLabels: [],
            note: 'Her: rayism',
            related: [],
            children: [],
          },
          {
            id: '6RM',
            label: 'Realisme',
            originalLabel: 'Realisme',
            alternativeLabels: [],
            note: 'Her: realistisk kunst, magisk realisme, moderne stilretninger innen realismen',
            related: [],
            children: [],
          },
          {
            id: '6RN',
            label: 'Relasjonell kunst',
            originalLabel: 'Relasjonell kunst',
            alternativeLabels: [],
            note: 'Her: relasjonell estetikk',
            related: [],
            children: [],
          },
          {
            id: '6RP',
            label: 'Retrokunst',
            originalLabel: 'Retrokunst',
            alternativeLabels: [],
            note: 'Brukes for stilretninger som er inspirert av retro eller vintage',
            related: [],
            children: [],
          },
          {
            id: '6RQ',
            label: 'Return to order',
            originalLabel: 'Return to order',
            alternativeLabels: [],
            note: 'Her: Retour à l’ordre, New Objectivity, Neue Sachlichkeit',
            related: [],
            children: [],
          },
          {
            id: '6RR',
            label: 'Romersk stil: romertiden',
            originalLabel: 'Romersk stil: romertiden',
            alternativeLabels: [],
            note: 'Brukes for alle stilretninger som forbindes med Romerriket og dets territorier',
            related: [],
            children: [],
          },
          {
            id: '6RS',
            label: 'Reggaeton',
            originalLabel: 'Reggaeton',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6S',
        label: 'Stiler (S)',
        originalLabel: 'Stiler (S)',
        alternativeLabels: [],
        note: 'Koden 6S skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på S i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6SA',
            label: 'Surrealisme',
            originalLabel: 'Surrealisme',
            alternativeLabels: [],
            note: 'Her: surrealistiske stilretninger',
            related: [],
            children: [],
          },
          {
            id: '6SB',
            label: 'Soul og funk',
            originalLabel: 'Soul og funk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6SC',
            label: 'Samba og Bossa Nova',
            originalLabel: 'Samba og Bossa Nova',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6SD',
            label: 'Schweizerischer Werkbund',
            originalLabel: 'Schweizerischer Werkbund',
            alternativeLabels: [],
            note: 'Her: den sveitsiske werkbund-stilretningen',
            related: [],
            children: [],
          },
          {
            id: '6SF',
            label: 'Den romerske skole (stilart)',
            originalLabel: 'Den romerske skole (stilart)',
            alternativeLabels: [],
            note: 'Her: Scuola di via Cavour',
            related: [],
            children: [],
          },
          {
            id: '6SG',
            label: 'Utbrytergrupper',
            originalLabel: 'Utbrytergrupper',
            alternativeLabels: [],
            note: 'Her: stilretninger fra ulike utbrytergrupper som ikke har en egen kode. Se også 6AD <a href="/thema/nb/6AD">Art Nouveau</a>, 6BD <a href="/thema/nb/6BD">Berlin-secesjonen</a>, 6VA <a href="/thema/nb/6VA">Wiener sezession</a>',
            related: [
              {
                id: '6AD',
                label: 'Art Nouveau',
                originalLabel: 'Art Nouveau',
                note: 'Brukes for alle navnevarianter av stilarten på ulike språk og i ulike land. Se også 6BD <a href="/thema/nb/6BD">Berlin-secesjonen</a>, 6JG <a href="/thema/nb/6JG">Jugendstil</a>, 6SG <a href="/thema/nb/6SG">Utbrytergrupper</a>, 6VA <a href="/thema/nb/6VA">Wiener sezession</a>',
              },
              {
                id: '6BD',
                label: 'Berlin-secesjonen',
                originalLabel: 'Berlin-secesjonen',
              },
              {
                id: '6VA',
                label: 'Wiener sezession',
                originalLabel: 'Wiener sezession',
                note: 'Her: Vereinigung Bildender Künstler Österreichs. Se også 6AD <a href="/thema/nb/6AD">Art Nouveau</a>, 6BD <a href="/thema/nb/6BD">Berlin-secesjonen</a>, 6SG <a href="/thema/nb/6SG">Utbrytergrupper</a>',
              },
            ],
            children: [],
          },
          {
            id: '6SH',
            label: 'Section d’Or',
            originalLabel: 'Section d’Or',
            alternativeLabels: [],
            note: 'Her: Groupe de Puteaux',
            related: [],
            children: [],
          },
          {
            id: '6SJ',
            label: 'Shakerstil',
            originalLabel: 'Shakerstil',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6SK',
            label: 'Situasjonistiske internasjonale',
            originalLabel: 'Situasjonistiske internasjonale',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6SL',
            label: 'Sovjetisk stil',
            originalLabel: 'Sovjetisk stil',
            alternativeLabels: [],
            note: 'Her: stalinistisk arkitektur',
            related: [],
            children: [],
          },
          {
            id: '6SM',
            label: 'Spansk eklektisisme',
            originalLabel: 'Spansk eklektisisme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6SN',
            label: 'De Stijl, neoplastisisme',
            originalLabel: 'De Stijl, neoplastisisme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6SP',
            label: 'Stukkisme',
            originalLabel: 'Stukkisme',
            alternativeLabels: [],
            note: 'Her: remodernisme',
            related: [],
            children: [],
          },
          {
            id: '6SQ',
            label: 'Sturm und Drang',
            originalLabel: 'Sturm und Drang',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6SR',
            label: 'Suprematisme',
            originalLabel: 'Suprematisme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6SS',
            label: 'Swing',
            originalLabel: 'Swing',
            alternativeLabels: [],
            note: 'Her: storband',
            related: [],
            children: [],
          },
          {
            id: '6ST',
            label: 'Symbolisme',
            originalLabel: 'Symbolisme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6SU',
            label: 'Syntetisk kubisme',
            originalLabel: 'Syntetisk kubisme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6SV',
            label: 'Syntetisme',
            originalLabel: 'Syntetisme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6SW',
            label: 'Satirisk',
            originalLabel: 'Satirisk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6SX',
            label: 'Stiler (S) - fortsettelse',
            originalLabel: 'Stiler (S) - fortsettelse',
            alternativeLabels: [],
            note: 'Koden 6SX skal IKKE brukes på enkeltbøker, den er kun en samlekode for å utvide listen over stilretninger som begynner på S i den engelske versjonen',
            related: [],
            children: [
              {
                id: '6SXA',
                label: 'Spatialisme',
                originalLabel: 'Spatialisme',
                alternativeLabels: [],
                note: 'Her: Movimento Spaziale',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: '6T',
        label: 'Stiler (T)',
        originalLabel: 'Stiler (T)',
        alternativeLabels: [],
        note: 'Koden 6T skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på T i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6TA',
            label: 'Tango',
            originalLabel: 'Tango',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6TB',
            label: 'Tachisme',
            originalLabel: 'Tachisme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6TC',
            label: 'Tartessisk stil',
            originalLabel: 'Tartessisk stil',
            alternativeLabels: [],
            note: 'Brukes for stilretninger som forbindes med Tartessos / Tartessus',
            related: [],
            children: [],
          },
          {
            id: '6TE',
            label: 'Transavanguardia',
            originalLabel: 'Transavanguardia',
            alternativeLabels: [],
            note: 'Her: transavantgardistiske stilretninger',
            related: [],
            children: [],
          },
          {
            id: '6TF',
            label: 'Tyrkiske / ottomanske stilretninger',
            originalLabel: 'Tyrkiske / ottomanske stilretninger',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6U',
        label: 'Stiler (U)',
        originalLabel: 'Stiler (U)',
        alternativeLabels: [],
        note: 'Koden 6U skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på U i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6UB',
            label: 'Urban / ‘Street’',
            originalLabel: 'Urban / ‘Street’',
            alternativeLabels: [],
            note: 'Brukes for bøker om musikk, kunst, design, mote etc. Brukes med andre kvalifikatorer for stil og kvalifikatorer for geografi',
            related: [],
            children: [],
          },
          {
            id: '6UC',
            label: 'Ukiyo-e',
            originalLabel: 'Ukiyo-e',
            alternativeLabels: [],
            note: 'Her: ‘bilder av den (forbi)flytende verden’',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6V',
        label: 'Stiler (V)',
        originalLabel: 'Stiler (V)',
        alternativeLabels: [],
        note: 'Koden 6V skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på V i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6VA',
            label: 'Wiener sezession',
            originalLabel: 'Wiener sezession',
            alternativeLabels: [],
            note: 'Her: Vereinigung Bildender Künstler Österreichs. Se også 6AD <a href="/thema/nb/6AD">Art Nouveau</a>, 6BD <a href="/thema/nb/6BD">Berlin-secesjonen</a>, 6SG <a href="/thema/nb/6SG">Utbrytergrupper</a>',
            related: [
              {
                id: '6AD',
                label: 'Art Nouveau',
                originalLabel: 'Art Nouveau',
                note: 'Brukes for alle navnevarianter av stilarten på ulike språk og i ulike land. Se også 6BD <a href="/thema/nb/6BD">Berlin-secesjonen</a>, 6JG <a href="/thema/nb/6JG">Jugendstil</a>, 6SG <a href="/thema/nb/6SG">Utbrytergrupper</a>, 6VA <a href="/thema/nb/6VA">Wiener sezession</a>',
              },
              {
                id: '6BD',
                label: 'Berlin-secesjonen',
                originalLabel: 'Berlin-secesjonen',
              },
              {
                id: '6SG',
                label: 'Utbrytergrupper',
                originalLabel: 'Utbrytergrupper',
                note: 'Her: stilretninger fra ulike utbrytergrupper som ikke har en egen kode. Se også 6AD <a href="/thema/nb/6AD">Art Nouveau</a>, 6BD <a href="/thema/nb/6BD">Berlin-secesjonen</a>, 6VA <a href="/thema/nb/6VA">Wiener sezession</a>',
              },
            ],
            children: [],
          },
          {
            id: '6VB',
            label: 'Vikingstil',
            originalLabel: 'Vikingstil',
            alternativeLabels: ['vikinger'],
            note: 'Her: stilretninger fra Oseberg, Borre, Jelling, Mammem, Ringerike og Urn og andre stilretninger som forbindes med vikingene eller andre norrøne folkeslag',
            related: [],
            children: [],
          },
          {
            id: '6VC',
            label: 'Visigotisk stil',
            originalLabel: 'Visigotisk stil',
            alternativeLabels: [],
            note: 'Her: vestgotisk stil',
            related: [],
            children: [],
          },
          {
            id: '6VD',
            label: 'Vorticisme',
            originalLabel: 'Vorticisme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: '6VE',
            label: 'Tradisjonell',
            originalLabel: 'Tradisjonell',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: '6W',
        label: 'Stiler (W)',
        originalLabel: 'Stiler (W)',
        alternativeLabels: [],
        note: 'Koden 6W skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på W i den engelske versjonen',
        related: [],
        children: [],
      },
      {
        id: '6X',
        label: 'Stiler (XYZ)',
        originalLabel: 'Stiler (XYZ)',
        alternativeLabels: [],
        note: 'Koden 6X skal IKKE brukes på enkeltbøker, den er kun en samlekode for alle stilretninger som begynner på X, Y eller Z i den engelske versjonen',
        related: [],
        children: [
          {
            id: '6XZ',
            label: 'Zydeco',
            originalLabel: 'Zydeco',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 'A',
    label: 'Kunst',
    originalLabel: 'Kunst',
    alternativeLabels: [],
    note: 'A*-koder brukes både for akademiske bøker og bøker for allmennmarkedet, inkludert illustrerte praktbøker og mer tekstbaserte verk. Foretrekk WF*-koder som hovedkategori for bøker som handler om kunst og kultur som en hobby, sammen med en passende A*-kode der det er hensiktsmessig. Bruk alle A*-koder sammen med andre emnekategorier, og tildel også relevante kvalifikator(er) der det er hensiktsmessig, med særlig vekt på kvalifikatorer for stil, sted og tidsperiode',
    related: [],
    children: [
      {
        id: 'AB',
        label: 'Kunst og kultur',
        originalLabel: 'Kunst og kultur',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'ABA',
            label: 'Kunstteori',
            originalLabel: 'Kunstteori',
            alternativeLabels: [],
            note: 'Se også QDTN <a href="/thema/nb/QDTN">Filosofi: estetikk</a>',
            related: [
              {
                id: 'QDTN',
                label: 'Filosofi: estetikk',
                originalLabel: 'Filosofi: estetikk',
                note: 'Her: debatter om skjønnhet og smak, kunstfilosofi. Se også ABA <a href="/thema/nb/ABA">Kunstteori</a>',
              },
            ],
            children: [],
          },
          {
            id: 'ABC',
            label: 'Konservering og restaurering av kunstverk',
            originalLabel: 'Konservering og restaurering av kunstverk',
            alternativeLabels: ['bevaring'],
            note: 'Brukes med andre A*-koder for bøker om konservering, bevaring, renovering og restaurering av alle typer kunstverk, inkludert bygninger, strukturer, skulpturer, fotografier, malerier etc.',
            related: [],
            children: [],
          },
          {
            id: 'ABK',
            label: 'Forfalskninger og tyverier av kunstverk',
            originalLabel: 'Forfalskninger og tyverier av kunstverk',
            alternativeLabels: ['kunstsvindel'],

            related: [],
            children: [],
          },
          {
            id: 'ABQ',
            label: 'Kunst: økonomiske aspekter',
            originalLabel: 'Kunst: økonomiske aspekter',
            alternativeLabels: ['auksjoner'],
            note: 'Her: salg og auksjon av kunst, kryptokunst, NFT-er, blokkjede, finansiering, støtte, sponsing, kuraterte salg, kunst som økonomisk investering eller spekulasjon, kommersiell verdi av kunst',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'AF',
        label: 'Kunstformer',
        originalLabel: 'Kunstformer',
        alternativeLabels: ['kunsthåndverk'],
        note: 'Brukes for: de skjønne kunster, billedkunst, brukskunst. Ved bruk av AF*-koder, tildel også relevante AG*-kode(r), f.eks. AGA Kunsthistorie, AGZ Kunstteknikker og -prinsipper og stilkvalifikator(er) der det er hensiktsmessig. Foretrekk WF*-koder som hovedkategori for bøker som handler om emnet som en hobby',
        related: [],
        children: [
          {
            id: 'AFC',
            label: 'Malerkunst og malerier',
            originalLabel: 'Malerkunst og malerier',
            alternativeLabels: [],
            note: 'Her: generelle verk om malerkunst eller malerier, eller dersom det ikke fins en annen detaljert kode under AFC*. Bruk AFC*-koder for bøker om malerkunst eller malerier, av individer eller grupper, inkludert historisk behandling av enkeltmalerier eller en spesifikk malerstil. Brukes med stilkvalifikator(er) der det er hensiktsmessig, og med AGA Kunsthistorie, AGB Kunstnere og kunstmonografier, AGZ Kunstteknikker og -prinsipper etc. Foretrekk WF*-koder som hovedkategori for bøker som handler om emnet som en hobby',
            related: [],
            children: [
              {
                id: 'AFCC',
                label: 'Malerkunst og akvareller',
                originalLabel: 'Malerkunst og akvareller',
                alternativeLabels: [
                  'pasteller',
                  'vannfarger',
                  'guache',
                  'guasj',
                ],
                note: 'Her: gouache',
                related: [],
                children: [],
              },
              {
                id: 'AFCL',
                label: 'Malerkunst og oljemalerier',
                originalLabel: 'Malerkunst og oljemalerier',
                alternativeLabels: [],
                note: 'Her: akrylmalerier, malerier',
                related: [],
                children: [],
              },
              {
                id: 'AFCM',
                label: 'Veggmalerier',
                originalLabel: 'Veggmalerier',
                alternativeLabels: ['al-secco', 'secco-maleri', 'Lüftlmalerei'],
                note: 'Her: muralmalerier, trompe l‘oeil, fresker, takmalerier, marouflage',
                related: [],
                children: [],
              },
              {
                id: 'AFCP',
                label: 'Malerkunst og malerier med blekk',
                originalLabel: 'Malerkunst og malerier med blekk',
                alternativeLabels: [],
                note: 'Her: tusjmaleri (østasiatisk kunst). Se også AFFK <a href="/thema/nb/AFFK">Tegnekunst og tegninger med penn eller pensel og blekk</a>',
                related: [
                  {
                    id: 'AFFK',
                    label:
                      'Tegnekunst og tegninger med penn eller pensel og blekk',
                    originalLabel:
                      'Tegnekunst og tegninger med penn eller pensel og blekk',
                    note: 'Se også AFCP <a href="/thema/nb/AFCP">Malerkunst og malerier med blekk</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'AFF',
            label: 'Tegnekunst og tegninger',
            originalLabel: 'Tegnekunst og tegninger',
            alternativeLabels: ['teknisk tegning'],
            note: 'Her: skisser. Bruk AFF*-koder for bøker om tegnekunst eller tegninger, av en enkelt kunstner eller kunstnergrupper, inkludert historisk behandling av enkelttegninger, eller en spesifikk tegnestil. Brukes med stilkvalifikator(er) der det er hensiktsmessig, og med AGA Kunsthistorie, AGB Kunstnere, kunstmonografier, AGZ Kunstteknikker og -prinsipper. Foretrekk WF*-koder som hovedkategori for bøker som handler om emnet som en hobby',
            related: [],
            children: [
              {
                id: 'AFFC',
                label:
                  'Tegnekunst og tegninger med blyant, kull, fargestifter eller pastellfargekritt',
                originalLabel:
                  'Tegnekunst og tegninger med blyant, kull, fargestifter eller pastellfargekritt',
                alternativeLabels: ['conté'],
                note: 'Her: grafitt, kritt, tegneblyanter',
                related: [],
                children: [],
              },
              {
                id: 'AFFK',
                label: 'Tegnekunst og tegninger med penn eller pensel og blekk',
                originalLabel:
                  'Tegnekunst og tegninger med penn eller pensel og blekk',
                alternativeLabels: [],
                note: 'Se også AFCP <a href="/thema/nb/AFCP">Malerkunst og malerier med blekk</a>',
                related: [
                  {
                    id: 'AFCP',
                    label: 'Malerkunst og malerier med blekk',
                    originalLabel: 'Malerkunst og malerier med blekk',
                    note: 'Her: tusjmaleri (østasiatisk kunst). Se også AFFK <a href="/thema/nb/AFFK">Tegnekunst og tegninger med penn eller pensel og blekk</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'AFH',
            label: 'Trykk og trykketeknikker',
            originalLabel: 'Trykk og trykketeknikker',
            alternativeLabels: [],
            note: 'Her: grafikk, etsning, silketrykk, tresnitt, litografi, akvatintetsning, koldnålsradering, mezzotint, monotypier, blokktrykk',
            related: [],
            children: [],
          },
          {
            id: 'AFJ',
            label: 'Andre grafiske eller visuelle kunstformer',
            originalLabel: 'Andre grafiske eller visuelle kunstformer',
            alternativeLabels: [],
            note: 'Her: collage, fotomontasjer. Brukes for generelle verk eller kunstformer som ikke har egne koder i AFC*, AFF* eller AFH*',
            related: [],
            children: [
              {
                id: 'AFJY',
                label: 'Kroppskunst og tatoveringer',
                originalLabel: 'Kroppskunst og tatoveringer',
                alternativeLabels: [],
                note: 'Her: piercing, kroppsmaling. Brukes med AFKP for performancekunst',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'AFK',
            label: 'Ikke-grafiske og elektroniske kunstformer',
            originalLabel: 'Ikke-grafiske og elektroniske kunstformer',
            alternativeLabels: ['Multimedia', 'videokunst'],
            note: 'Brukes for generelle verk om ikke-grafiske kunstformer, eller kunstutrykk som ikke har egne koder, inkludert nye medieformer',
            related: [],
            children: [
              {
                id: 'AFKB',
                label: 'Skulpturer',
                originalLabel: 'Skulpturer',
                alternativeLabels: [
                  'statuer',
                  'figurer',
                  'relieffer',
                  'plastkunst',
                ],
                note: 'Her: statuer, figurer, monumentale skulpturer, billedhuggerkunst. Brukes for verk om tredimensjonal kunst uavhengig av materiale, og for verk om kunstformen, eller tilhørende tradisjoner og teknikker',
                related: [],
                children: [],
              },
              {
                id: 'AFKC',
                label: 'Utskjæring, masker, relieffer',
                originalLabel: 'Utskjæring, masker, relieffer',
                alternativeLabels: ['steinhuggerkunst', 'steinhogger'],
                note: 'Her: treskjæring, helleristninger, mindre og monumentale utskjæringer. Brukes for både teknikken og bøker om spesifikke objekter som har utskjæringer, uavhengig av materiale',
                related: [],
                children: [],
              },
              {
                id: 'AFKG',
                label:
                  'Edle metaller, edelstener og smykker: kunstverk og design',
                originalLabel:
                  'Edle metaller, edelstener og smykker: kunstverk og design',
                alternativeLabels: [],
                note: 'Her: gull- og sølvsmedarbeid, Fabergé etc. Se også WFJ <a href="/thema/nb/WFJ">Smykker og perler</a>, WCP <a href="/thema/nb/WCP">Antikviteter og samleobjekter: smykker</a>, WCR <a href="/thema/nb/WCR">Antikviteter og samleobjekter: gull, sølv og andre metaller (annet enn smykker)</a>, WFP <a href="/thema/nb/WFP">Kunsthåndverk av metall</a>',
                related: [
                  {
                    id: 'WCP',
                    label: 'Antikviteter og samleobjekter: smykker',
                    originalLabel: 'Antikviteter og samleobjekter: smykker',
                  },
                  {
                    id: 'WCR',
                    label:
                      'Antikviteter og samleobjekter: gull, sølv og andre metaller (annet enn smykker)',
                    originalLabel:
                      'Antikviteter og samleobjekter: gull, sølv og andre metaller (annet enn smykker)',
                  },
                  {
                    id: 'WFJ',
                    label: 'Smykker og perler',
                    originalLabel: 'Smykker og perler',
                    note: 'Se også AFKG <a href="/thema/nb/AFKG">Edle metaller, edelstener og smykker: kunstverk og design</a>',
                  },
                  {
                    id: 'WFP',
                    label: 'Kunsthåndverk av metall',
                    originalLabel: 'Kunsthåndverk av metall',
                  },
                ],
                children: [],
              },
              {
                id: 'AFKN',
                label: 'Installasjonskunst',
                originalLabel: 'Installasjonskunst',
                alternativeLabels: ['installasjoner'],
                note: 'Her: stedskunst (land art), stedspesifikk kunst, miljøkunst',
                related: [],
                children: [],
              },
              {
                id: 'AFKP',
                label: 'Performancekunst',
                originalLabel: 'Performancekunst',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'AFKV',
                label: 'Digital- og videokunst, kunst i nye medier',
                originalLabel: 'Digital- og videokunst, kunst i nye medier',
                alternativeLabels: ['softwarekunst'],
                note: 'Her: data-, elektronisk-, holografisk-, virtuell-, kyborg-, interaktiv-, internett-, generativ-, telematisk-, lyd- og evolusjonær kunst, kryptokunst, tv-spillkunst, programvarekunst. Se også UG* <a href="/thema/nb/UG">Datagrafikk og digitale medier</a> og dens underkategorier',
                related: [
                  {
                    id: 'UG',
                    label: 'Datagrafikk og digitale medier',
                    originalLabel: 'Datagrafikk og digitale medier',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'AFP',
            label: 'Keramikk, mosaikk og glass: kunstverk',
            originalLabel: 'Keramikk, mosaikk og glass: kunstverk',
            alternativeLabels: ['emaljekunst', 'vaser', 'terracotta'],
            note: 'Her: keramikk (inkludert porselen, steingods, steintøy, bisque), glassmaleri, fliser eller emalje som kunstverk. Se også WFN <a href="/thema/nb/WFN">Keramikk og glasskunst</a>',
            related: [
              {
                id: 'WFN',
                label: 'Keramikk og glasskunst',
                originalLabel: 'Keramikk og glasskunst',
                note: 'Se også AFP <a href="/thema/nb/AFP">Keramikk, mosaikk og glass: kunstverk</a>',
              },
            ],
            children: [],
          },
          {
            id: 'AFT',
            label: 'Dekorativ kunst',
            originalLabel: 'Dekorativ kunst',
            alternativeLabels: [],
            note: 'Her: ornamenter, dekorasjonsdetaljer, dekorative maleteknikker, dekorative mønstre og former, dekorativt metallarbeid, lakkarbeid og andre dekorative kunstarter. Brukes for bøker som tar for seg emnet fra et kunst- eller kunsthistorisk perspektiv, eller som retter seg mot et profesjonelt publikum, eller et publikum med tekniske ferdigheter. Se også WF* <a href="/thema/nb/WF">Husflid, kunsthåndverk og håndarbeid</a> og dens underkategorier',
            related: [
              {
                id: 'WF',
                label: 'Husflid, kunsthåndverk og håndarbeid',
                originalLabel: 'Husflid, kunsthåndverk og håndarbeid',
                note: 'Bruk WF *-koder for praktiske «hvordan-gjøre-det selv bøker» og hobbybøker. Foretrekk koder fra A* for bøker om kunsthistorie og -kritikk, eller koder fra T* for bøker for høyere utdanning og profesjonsmarkedet. Se også AFT <a href="/thema/nb/AFT">Dekorativ kunst</a>',
              },
            ],
            children: [],
          },
          {
            id: 'AFW',
            label: 'Tekstilkunstverk',
            originalLabel: 'Tekstilkunstverk',
            alternativeLabels: [],
            note: 'Her: tepper, kelimer, billedvever og andre kunstverk av tekstiler. Se også AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>, WFB* <a href="/thema/nb/WFB">Håndarbeid</a> og dens underkategorier, WFF <a href="/thema/nb/WFF">Tepper og teppeknyting</a>, WFG <a href="/thema/nb/WFG">Spinning og veving</a>',
            related: [
              {
                id: 'AKT',
                label: 'Mote- og tekstildesign',
                originalLabel: 'Mote- og tekstildesign',
                note: 'Brukes for stiler og trender innen motedesign, generelle verk, referanseverk, bøker om spesielle klesplagg, illustrerte verk om mote etc. Bruk AKT*-koder med AKB for spesifikke motedesignere, med AKX for motehistorie, pluss kvalifikator(er) for stil, sted og tidsperiode der det er hensiktsmessig, med AJC* for fotografiske samlinger eller samlinger av motefotografier, med AGZ for praktiske teknikker for motestudenter og -designere. Foretrekk WF*-koder som hovedkategori for bøker ment for allmennmarkedet og som handler om hvordan man skal kle seg, hvordan man skal se moteriktig ut, har praktiske tips etc. Se også TDPF* <a href="/thema/nb/TDPF">Tekstiler og fibre</a> og dens underkategorier, KNDD <a href="/thema/nb/KNDD">Tekstilindustri</a>, KNSX <a href="/thema/nb/KNSX">Mote- og skjønnhetsbransjen</a>, WJF <a href="/thema/nb/WJF">Mote- og stilguider</a>',
              },
              {
                id: 'WFB',
                label: 'Håndarbeid',
                originalLabel: 'Håndarbeid',
                note: 'Her: bøker med mønstre og design. Se også AFW <a href="/thema/nb/AFW">Tekstilkunstverk</a>, AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>',
              },
              {
                id: 'WFF',
                label: 'Tepper og teppeknyting',
                originalLabel: 'Tepper og teppeknyting',
                note: 'Se også AFW <a href="/thema/nb/AFW">Tekstilkunstverk</a>, AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>',
              },
              {
                id: 'WFG',
                label: 'Spinning og veving',
                originalLabel: 'Spinning og veving',
                note: 'Se også AFW <a href="/thema/nb/AFW">Tekstilkunstverk</a>, AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'AG',
        label: 'Tema og motiver i kunst',
        originalLabel: 'Tema og motiver i kunst',
        alternativeLabels: [],
        note: 'Brukes for bøker om de skjønne kunster, billed- og brukskunst. Ved bruk av AG*-koder, tildel også relevante AB*-, AF*, AG*, AJ*-kode(r) og kvalifikator(er) for stil, sted og tidsperioder der det er hensiktsmessig',
        related: [],
        children: [
          {
            id: 'AGA',
            label: 'Kunsthistorie',
            originalLabel: 'Kunsthistorie',
            alternativeLabels: [],
            note: 'Bruk AGA sammen med andre kunstkoder der det er hensiktsmessig. tildel også kvalifikator(er) for stil, sted og tidsperiode der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'AGB',
            label: 'Kunstnere, kunstmonografier',
            originalLabel: 'Kunstnere, kunstmonografier',
            alternativeLabels: [],
            note: 'Brukes for bøker både om enkeltkunstnere og atelierer eller verksteder som har produsert kunst under en enkelt kunstners navn',
            related: [],
            children: [],
          },
          {
            id: 'AGC',
            label: 'Utstillingskataloger og kunstsamlinger',
            originalLabel: 'Utstillingskataloger og kunstsamlinger',
            alternativeLabels: ['kataloger', 'gallerier'],
            note: 'Brukes for publikasjoner som er utgitt både til tidsavgrensede og faste utstillinger, og til samlinger hos kunstgallerier, museer eller samlere. Brukes med andre A*-koder for å angi kunstform og kvalifikator(er) for sted dersom katalogen er knyttet til et spesifikt galleri eller museum. Se også WTHM <a href="/thema/nb/WTHM">Reisehåndbøker: museer, historiske steder, gallerier etc.</a>, GLZ <a href="/thema/nb/GLZ">Museumsvitenskap og kulturarvstudier</a>',
            related: [
              {
                id: 'GLZ',
                label: 'Museumsvitenskap og kulturarvstudier',
                originalLabel: 'Museumsvitenskap og kulturarvstudier',
                note: 'Her: museumsforskning, kuratorer, kulturforskning og bevaring av kulturarv. Brukes for bøker om bygninger, monumenter og steder som er viktige for bevaring av et samfunns materielle og immaterielle kulturarv. Se også ABC <a href="/thema/nb/ABC">Konservering og restaurering av kunstverk</a>, AGC <a href="/thema/nb/AGC">Utstillingskataloger og kunstsamlinger</a>, NK* <a href="/thema/nb/NK">Arkeologi</a> og dens underkategorier, RNK <a href="/thema/nb/RNK">Miljøvern</a>, TNKX <a href="/thema/nb/TNKX">Bevaring av bygninger og bygningsmaterialer</a>, WTHM <a href="/thema/nb/WTHM">Reisehåndbøker: museer, historiske steder, gallerier etc.</a>',
              },
              {
                id: 'WTHM',
                label:
                  'Reisehåndbøker: museer, historiske steder, gallerier etc.',
                originalLabel:
                  'Reisehåndbøker: museer, historiske steder, gallerier etc.',
                note: 'Her: reisehåndbøker om kulturarvsteder, bygninger, monumenter, steder av kulturhistorisk og arkeologisk betydning etc.',
              },
            ],
            children: [],
          },
          {
            id: 'AGH',
            label: 'Mennesker fremstilt i kunst',
            originalLabel: 'Mennesker fremstilt i kunst',
            alternativeLabels: [],
            note: 'Her: figurmaleri eller -tegning, illustrasjoner av den menneskelige anatomi for kunstnere, akttegning, eller andre kunstverk som fremstiller mennesket eller deler av menneskekroppen. Brukes med AGZ for tegneteknikker',
            related: [],
            children: [
              {
                id: 'AGHF',
                label: 'Portretter og selvportretter',
                originalLabel: 'Portretter og selvportretter',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'AGHN',
                label: 'Aktstudier',
                originalLabel: 'Aktstudier',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'AGHX',
                label: 'Erotisk kunst',
                originalLabel: 'Erotisk kunst',
                alternativeLabels: [],
                note: 'Brukes med kvalifikator 5X der det er hensiktsmessig. Se også AJCX <a href="/thema/nb/AJCX">Erotisk- og aktfotografi</a>',
                related: [
                  {
                    id: 'AJCX',
                    label: 'Erotisk- og aktfotografi',
                    originalLabel: 'Erotisk- og aktfotografi',
                    note: 'Brukes med kvalifikator 5X der det er hensiktsmessig. Se også AGHX <a href="/thema/nb/AGHX">Erotisk kunst</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'AGK',
            label: 'Småskala, sekulære og dagligdagse kunstscener',
            originalLabel: 'Småskala, sekulære og dagligdagse kunstscener',
            alternativeLabels: [],
            note: 'Her: «sjanger»-emner, hverdagsscener og -hendelser i kunst',
            related: [],
            children: [],
          },
          {
            id: 'AGN',
            label: 'Natur i kunst',
            originalLabel: 'Natur i kunst',
            alternativeLabels: [],
            note: 'Brukes for enhver kunstnerisk gjengivelse av natur. Brukes med AKLB for illustrasjoner eller PS* for kunst som er eller opprinnelig har vært ment for vitenskapelige formål',
            related: [],
            children: [
              {
                id: 'AGNA',
                label: 'Dyr i kunst',
                originalLabel: 'Dyr i kunst',
                alternativeLabels: [],
                note: 'Her: kunst med dyr, dyreliv, fugler, insekter, fisk etc. Brukes for enhver kunstnerisk gjengivelse av dyreliv. Brukes med AKLB for illustrasjoner eller PS* for kunst som er eller opprinnelig har vært ment for vitenskapelige formål',
                related: [],
                children: [],
              },
              {
                id: 'AGNB',
                label: 'Botanisk kunst',
                originalLabel: 'Botanisk kunst',
                alternativeLabels: [],
                note: 'Her: botaniske illustrasjoner. Brukes for enhver kunstnerisk gjengivelse av trær, blomster eller planter gjengitt i alle typer kunstformer eller -tradisjoner. Brukes med PST for botanisk kunst som har et vitenskapelig formål, ofte bøker som har bøker om planter eller reproduksjoner av historiske, botaniske verk, eller med AKLB for bøker om botanisk kunst som eksempel på kunstillustrasjoner',
                related: [],
                children: [],
              },
              {
                id: 'AGNL',
                label: 'Landskap og kystlandskap i kunst',
                originalLabel: 'Landskap og kystlandskap i kunst',
                alternativeLabels: [],
                note: 'Her: bylandskap. Brukes for bøker om ulike typer landskap framstilt i billedkunst',
                related: [],
                children: [],
              },
              {
                id: 'AGNS',
                label: 'Stilleben',
                originalLabel: 'Stilleben',
                alternativeLabels: ['nature morte'],
                note: 'Her: vanitas. Brukes for gjengivelser av scener i liten skala, for det meste med døde gjenstander, både naturlige og menneskeskapte',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'AGP',
            label: 'Menneskeskapte objekter i kunst',
            originalLabel: 'Menneskeskapte objekter i kunst',
            alternativeLabels: [],
            note: 'Her: kunstnerisk gjengivelse av bygninger, bylandskap, transport etc.',
            related: [],
            children: [],
          },
          {
            id: 'AGR',
            label: 'Religiøse emner i kunst',
            originalLabel: 'Religiøse emner i kunst',
            alternativeLabels: [],
            note: 'Her: hellig, seremoniell eller rituell kunst, ikoner. Brukes for bøker om kunst som bruker religiøs inspirasjon eller motiver, kunstnerisk gjengivelse av figurer, mønster, former, kalligrafi etc. men for spirituelle eller religiøse formål. Brukes med relevant kode fra QR* eller andre A*-koder der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'AGT',
            label: 'Kunstnerisk utsmykking',
            originalLabel: 'Kunstnerisk utsmykking',
            alternativeLabels: [],
            note: 'Brukes for kunstverk som har vært planlagt som og er gjennomført som kunst ment for det offentlige rom, ofte utendørs og tilgjengelig for alle, enten permanent eller midlertidig. Kunstverket kan være bestilt spesielt for et spesifikt sted, eller være uoffisielt eller uautorisert',
            related: [],
            children: [
              {
                id: 'AGTS',
                label: 'Urban kunst',
                originalLabel: 'Urban kunst',
                alternativeLabels: [],
                note: 'Her: geriljakunst, graffiti, gatekunst og -installasjoner, klistremerkekunst, strikkegraffiti, urban stilarter etc. Brukes for verk om billed- eller brukskunst i urbane områder, inspirert av urban livsstil eller urbane miljøer og som forekommer i det offentlige rom',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'AGZ',
            label: 'Kunstteknikker og -prinsipper',
            originalLabel: 'Kunstteknikker og -prinsipper',
            alternativeLabels: [],
            note: 'Brukes for lærebøker, bøker for høyere utdanning eller profesjonsmarkedet om teknikker, perspektiv, etc. Brukes med passende AF* eller AK* koder for å angi kunstform, f.eks. AFF for tegneteknikker. Foretrekk WF*-koder som hovedkategori for bøker som handler om emnet som en hobby. Se også WFA <a href="/thema/nb/WFA">Maling, tegning og kunsthåndbøker</a>',
            related: [
              {
                id: 'WFA',
                label: 'Maling, tegning og kunsthåndbøker',
                originalLabel: 'Maling, tegning og kunsthåndbøker',
                note: 'Se også AGZ <a href="/thema/nb/AGZ">Kunstteknikker og -prinsipper</a>',
              },
            ],
            children: [
              {
                id: 'AGZC',
                label: 'Farger og fargeteori',
                originalLabel: 'Farger og fargeteori',
                alternativeLabels: [],
                note: 'Her: fargevitenskap i kunst. Brukes med P*- eller T*-koder for vitenskapelige eller tekniske utgivelser',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'AJ',
        label: 'Fotografering og fotografier',
        originalLabel: 'Fotografering og fotografier',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'AJC',
            label: 'Fotografisamlinger',
            originalLabel: 'Fotografisamlinger',
            alternativeLabels: ['fotografier'],
            note: 'Brukes for bøker som er eller handler om tematiske samlinger, eller som presenterer en samlers, et museums, galleris eller byrås samling. Brukes med AG*-koder eller andre koder som angir publikasjonens tema der det er hensiktsmessig. Se også WQP <a href="/thema/nb/WQP">Steder i gamle fotografier</a>',
            related: [
              {
                id: 'WQP',
                label: 'Steder i gamle fotografier',
                originalLabel: 'Steder i gamle fotografier',
                note: 'Her: samlinger av gamle fotografier knyttet til et bestemt sted eller steder. Brukes sammen med kvalifikatorer for geografi og tidsperiode der det er mulig. Se også AJC <a href="/thema/nb/AJC">Fotografisamlinger</a>',
              },
            ],
            children: [
              {
                id: 'AJCD',
                label: 'Fotografer',
                originalLabel: 'Fotografer',
                alternativeLabels: [],
                note: 'Her: monografier om fotografer',
                related: [],
                children: [],
              },
              {
                id: 'AJCP',
                label: 'Fotografering: portretter og selvportretter',
                originalLabel: 'Fotografering: portretter og selvportretter',
                alternativeLabels: ['selfies'],
                note: 'Brukes for bøker som presenterer individer og grupper gjennom fotografier, inkludert selvportretter og modeller, samt verk om portrettfotografering. Brukes med AJTF for verk om praktiske teknikker for portrettfotografering',
                related: [],
                children: [],
              },
              {
                id: 'AJCX',
                label: 'Erotisk- og aktfotografi',
                originalLabel: 'Erotisk- og aktfotografi',
                alternativeLabels: ['erotisk fotografi'],
                note: 'Brukes med kvalifikator 5X der det er hensiktsmessig. Se også AGHX <a href="/thema/nb/AGHX">Erotisk kunst</a>',
                related: [
                  {
                    id: 'AGHX',
                    label: 'Erotisk kunst',
                    originalLabel: 'Erotisk kunst',
                    note: 'Brukes med kvalifikator 5X der det er hensiktsmessig. Se også AJCX <a href="/thema/nb/AJCX">Erotisk- og aktfotografi</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'AJF',
            label: 'Fotojournalistikk og dokumentarfotografi',
            originalLabel: 'Fotojournalistikk og dokumentarfotografi',
            alternativeLabels: ['paparazzi'],
            note: 'Her: fotoreportasjer, sosiale dokumentarer og fotografier av gatemiljøer, hendelser og kjendiser. Se også DNP <a href="/thema/nb/DNP">Reportasjer eller journalistiske tekster</a>',
            related: [
              {
                id: 'DNP',
                label: 'Reportasjer eller journalistiske tekster',
                originalLabel: 'Reportasjer eller journalistiske tekster',
                note: 'Her: samling av spalter. Brukes for bøker hvor tekstene enten har vært publisert tidligere i et nyhetsmedium eller publisert som et originalverk. Brukes med andre emnekoder for å angi spesifikke emner der det er hensiktsmessig, f.eks. JP*-koder for politikk eller KC*-koder for økonomi; brukes alene for generelle verk. Se også WTL* <a href="/thema/nb/WTL">Reiseskildringer</a> og dens underkategorier, AJF <a href="/thema/nb/AJF">Fotojournalistikk og dokumentarfotografi</a>',
              },
            ],
            children: [],
          },
          {
            id: 'AJT',
            label: 'Fotoutstyr og -teknikker',
            originalLabel: 'Fotoutstyr og -teknikker',
            alternativeLabels: ['kameraer', 'fotografiapparater'],
            note: 'Her: kameraer, linser, film, mørkeromsteknikker og fremkalling av fotografier, retusjering og digital manipulering, fargeprinsipper, komposisjon etc. Se også UGP <a href="/thema/nb/UGP">Foto og bildebehandling</a>',
            related: [
              {
                id: 'UGP',
                label: 'Foto og bildebehandling',
                originalLabel: 'Foto og bildebehandling',
              },
            ],
            children: [
              {
                id: 'AJTA',
                label: 'Kameramanualer',
                originalLabel: 'Kameramanualer',
                alternativeLabels: [
                  'kameraer',
                  'fotografiapparater',
                  'videokameraer',
                ],
                note: 'Brukes for publikasjoner om spesifikke kameratyper eller -merker',
                related: [],
                children: [],
              },
              {
                id: 'AJTF',
                label: 'Fotografi: spesielle teknikker og prinsipper',
                originalLabel: 'Fotografi: spesielle teknikker og prinsipper',
                alternativeLabels: [
                  'dronefotografering',
                  'droner',
                  'landskapsfotografering',
                  'naturfotografering',
                  'undervannsfotografering',
                  'portrettfotografering',
                ],
                note: 'Her: luft-, drone- studio, gate-, landskaps-, natur-, dyrelivs-, portrett-, undervanns-, mikro- og astrofotografering, teknikker og guider. Brukes for bøker om komposisjon etc.',
                related: [],
                children: [],
              },
              {
                id: 'AJTH',
                label: 'Digital fotografering',
                originalLabel: 'Digital fotografering',
                alternativeLabels: ['digital fotografi'],

                related: [],
                children: [],
              },
              {
                id: 'AJTR',
                label: 'Monokromatisk fotografering',
                originalLabel: 'Monokromatisk fotografering',
                alternativeLabels: [],
                note: 'Her: gråskala, svart-hvitt, sepia fotografering. Brukes med AJTV for monokromatiske teknikker i film / video',
                related: [],
                children: [],
              },
              {
                id: 'AJTS',
                label: 'Lys og lyssetting',
                originalLabel: 'Lys og lyssetting',
                alternativeLabels: [],
                note: 'Her: fotografering med blits, bruk av naturlig eller kunstig lys eller lyssetting. Brukes med AJTV for lysteknikker i film / video eller med AT* for lysteknikker eller -design i utøvende kunst',
                related: [],
                children: [],
              },
              {
                id: 'AJTV',
                label: 'Videofotografering / videografi',
                originalLabel: 'Videofotografering / videografi',
                alternativeLabels: [],
                note: 'Se også AFKV <a href="/thema/nb/AFKV">Digital- og videokunst, kunst i nye medier</a>, ATFX <a href="/thema/nb/ATFX">Filmproduksjon: teknikk og bakgrunnskunnskaper</a>, ATJX <a href="/thema/nb/ATJX">TV-produksjon: teknikk og bakgrunnskunnskaper</a>',
                related: [
                  {
                    id: 'AFKV',
                    label: 'Digital- og videokunst, kunst i nye medier',
                    originalLabel: 'Digital- og videokunst, kunst i nye medier',
                    note: 'Her: data-, elektronisk-, holografisk-, virtuell-, kyborg-, interaktiv-, internett-, generativ-, telematisk-, lyd- og evolusjonær kunst, kryptokunst, tv-spillkunst, programvarekunst. Se også UG* <a href="/thema/nb/UG">Datagrafikk og digitale medier</a> og dens underkategorier',
                  },
                  {
                    id: 'ATFX',
                    label: 'Filmproduksjon: teknikk og bakgrunnskunnskaper',
                    originalLabel:
                      'Filmproduksjon: teknikk og bakgrunnskunnskaper',
                    note: 'Her: kinematografi, redigering, klær og sminke, lyssetting, natur, kostymer, spesialeffekter, design, rekvisitter, rollebesetning etc. Se også CBVS <a href="/thema/nb/CBVS">Teknikker innen manusskriving</a>, UG* <a href="/thema/nb/UG">Datagrafikk og digitale medier</a> og dens underkategorier',
                  },
                  {
                    id: 'ATJX',
                    label: 'TV-produksjon: teknikk og bakgrunnskunnskaper',
                    originalLabel:
                      'TV-produksjon: teknikk og bakgrunnskunnskaper',
                    note: 'Her: kameraføring for TV, redigering, klær og sminke, lyssetting, natur, kostymer, spesialeffekter, rekvisitter, rollebesetning etc.',
                  },
                ],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'AK',
        label: 'Design, industriell/kommersiell kunst, illustrasjoner',
        originalLabel: 'Design, industriell/kommersiell kunst, illustrasjoner',
        alternativeLabels: ['produktdesign', 'produktutforming'],
        note: 'Bruk AK*-koder for bøker som tar for seg emnet fra et kunsthistorisk perspektiv eller er ment for profesjonsmarkedet. Ved bruk av AK*-koder, tildel også kvalifikator(er) for stil, sted og tidsperiode der det er hensiktsmessig. Foretrekk WF*-koder som hovedkategori for bøker som handler om emnet som en hobby',
        related: [],
        children: [
          {
            id: 'AKB',
            label: 'Designere',
            originalLabel: 'Designere',
            alternativeLabels: [],
            note: 'Her: individuelle designere, grupper, firmaer eller merkevarer innen design. Brukes med andre emnekoder for å angi et område innen design der er hensiktsmessig. F.eks. AKT for motedesignere pluss AKX for historisk tilnærming til emnet',
            related: [],
            children: [],
          },
          {
            id: 'AKC',
            label: 'Grafisk design',
            originalLabel: 'Grafisk design',
            alternativeLabels: ['logoer'],

            related: [],
            children: [],
          },
          {
            id: 'AKD',
            label: 'Typografi og skrift',
            originalLabel: 'Typografi og skrift',
            alternativeLabels: ['shufa', 'shodo', 'khatt'],
            note: 'Her: skrifttyper, fonter, kalligrafi som kunstform. Se også WFU <a href="/thema/nb/WFU">Kalligrafi og håndskrift</a>',
            related: [
              {
                id: 'WFU',
                label: 'Kalligrafi og håndskrift',
                originalLabel: 'Kalligrafi og håndskrift',
                note: 'Se også AKD <a href="/thema/nb/AKD">Typografi og skrift</a>',
              },
            ],
            children: [],
          },
          {
            id: 'AKH',
            label: 'Bokdesign og bokinnbinding',
            originalLabel: 'Bokdesign og bokinnbinding',
            alternativeLabels: ['innbinding', 'bokbinding'],
            note: 'Her: omslagsdesign, luksusinnbinding, marmorpapir etc. som kunstform. Brukes for bøker om kunsten å designe og skape bøker. Se også WFT <a href="/thema/nb/WFT">Bokkunst, papirkunst og -forming</a>, AKLB <a href="/thema/nb/AKLB">Illustrasjoner</a>, KNTP1 <a href="/thema/nb/KNTP1">Forlags- og bokbransje</a>',
            related: [
              {
                id: 'AKLB',
                label: 'Illustrasjoner',
                originalLabel: 'Illustrasjoner',
                note: 'Her: illustrasjoner i bøker og informativ grafikk som kunstform. Brukes for bøker om illustrasjoner som kunstform, samt illustrasjoner, både nyere og i historisk sammenheng, brukt for vitenskapelige, narrative og andre formål. Se også AFF <a href="/thema/nb/AFF">Tegnekunst og tegninger</a>',
              },
              {
                id: 'KNTP1',
                label: 'Forlags- og bokbransje',
                originalLabel: 'Forlags- og bokbransje',
                note: 'Her: trykte bøker, e-bok og digital publisering, tidsskrifter. Brukes med NHTB for historisk behandling av emnet',
              },
              {
                id: 'WFT',
                label: 'Bokkunst, papirkunst og -forming',
                originalLabel: 'Bokkunst, papirkunst og -forming',
                note: 'Her: lage papir, bokbinding, gavekort, scrapbooking, papirfly, papirmodeller, decoupage, silhuetter etc. Se også AKH <a href="/thema/nb/AKH">Bokdesign og bokinnbinding</a>',
              },
            ],
            children: [
              {
                id: 'AKHM',
                label: 'Manuskripter',
                originalLabel: 'Manuskripter',
                alternativeLabels: [],
                note: 'Her: illuminerte (illustrerte eller dekorerte) eller malte manuskripter, miniatyrillustrasjoner, skriftruller og kodekser',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'AKL',
            label: 'Illustrasjoner og kommersiell kunst',
            originalLabel: 'Illustrasjoner og kommersiell kunst',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'AKLB',
                label: 'Illustrasjoner',
                originalLabel: 'Illustrasjoner',
                alternativeLabels: [],
                note: 'Her: illustrasjoner i bøker og informativ grafikk som kunstform. Brukes for bøker om illustrasjoner som kunstform, samt illustrasjoner, både nyere og i historisk sammenheng, brukt for vitenskapelige, narrative og andre formål. Se også AFF <a href="/thema/nb/AFF">Tegnekunst og tegninger</a>',
                related: [
                  {
                    id: 'AFF',
                    label: 'Tegnekunst og tegninger',
                    originalLabel: 'Tegnekunst og tegninger',
                    note: 'Her: skisser. Bruk AFF*-koder for bøker om tegnekunst eller tegninger, av en enkelt kunstner eller kunstnergrupper, inkludert historisk behandling av enkelttegninger, eller en spesifikk tegnestil. Brukes med stilkvalifikator(er) der det er hensiktsmessig, og med AGA Kunsthistorie, AGB Kunstnere, kunstmonografier, AGZ Kunstteknikker og -prinsipper. Foretrekk WF*-koder som hovedkategori for bøker som handler om emnet som en hobby',
                  },
                ],
                children: [],
              },
              {
                id: 'AKLC',
                label: 'Tegneseriekunst',
                originalLabel: 'Tegneseriekunst',
                alternativeLabels: ['tegneserier', 'tegneseriestriper'],
                note: 'Bruk alle AKLC*koder med X*-koder for å angi type tegneseriekunst, med AGZ for bøker om tegneserieteknikker eller -karakterer, med AKX for historisk behandling av tegneseriekunst. Foretrekk WF*-koder som hovedkategori for bøker som handler om emnet som en hobby eller UGL for digitale illustrasjoner. Se også AFF <a href="/thema/nb/AFF">Tegnekunst og tegninger</a>',
                related: [
                  {
                    id: 'AFF',
                    label: 'Tegnekunst og tegninger',
                    originalLabel: 'Tegnekunst og tegninger',
                    note: 'Her: skisser. Bruk AFF*-koder for bøker om tegnekunst eller tegninger, av en enkelt kunstner eller kunstnergrupper, inkludert historisk behandling av enkelttegninger, eller en spesifikk tegnestil. Brukes med stilkvalifikator(er) der det er hensiktsmessig, og med AGA Kunsthistorie, AGB Kunstnere, kunstmonografier, AGZ Kunstteknikker og -prinsipper. Foretrekk WF*-koder som hovedkategori for bøker som handler om emnet som en hobby',
                  },
                ],
                children: [
                  {
                    id: 'AKLC1',
                    label: 'Grafiske romaner og mangakunst',
                    originalLabel: 'Grafiske romaner og mangakunst',
                    alternativeLabels: ['grafiske romaner'],
                    note: 'Brukes med kode(r) fra X* for å angi kunstverkets form. Se også XA* <a href="/thema/nb/XA">Grafiske romaner/ Tegneserier / Manga: stilarter / tradisjoner</a> og dens underkategorier, XR* <a href="/thema/nb/XR">Grafiske romaner, tegneserier og manga: oppslagsverk, guider og anmeldelser</a> og dens underkategorier, AFF <a href="/thema/nb/AFF">Tegnekunst og tegninger</a>',
                    related: [
                      {
                        id: 'AFF',
                        label: 'Tegnekunst og tegninger',
                        originalLabel: 'Tegnekunst og tegninger',
                        note: 'Her: skisser. Bruk AFF*-koder for bøker om tegnekunst eller tegninger, av en enkelt kunstner eller kunstnergrupper, inkludert historisk behandling av enkelttegninger, eller en spesifikk tegnestil. Brukes med stilkvalifikator(er) der det er hensiktsmessig, og med AGA Kunsthistorie, AGB Kunstnere, kunstmonografier, AGZ Kunstteknikker og -prinsipper. Foretrekk WF*-koder som hovedkategori for bøker som handler om emnet som en hobby',
                      },
                      {
                        id: 'XA',
                        label:
                          'Grafiske romaner/ Tegneserier / Manga: stilarter / tradisjoner',
                        originalLabel:
                          'Grafiske romaner/ Tegneserier / Manga: stilarter / tradisjoner',
                        note: 'Bruk XA* for å angi tradisjon eller type. Bruk XA alene kun dersom den spesifikke tradisjonen eller typen ikke er kjent, det er snakk om en blanding av tradisjoner, eller det ikke fins en mer spesifikk XA*-kode. Bruk XA* med XQ* for å angi sjanger der det er hensiktsmessig',
                      },
                      {
                        id: 'XR',
                        label:
                          'Grafiske romaner, tegneserier og manga: oppslagsverk, guider og anmeldelser',
                        originalLabel:
                          'Grafiske romaner, tegneserier og manga: oppslagsverk, guider og anmeldelser',
                        note: 'Brukes med andre emnekoder der det er hensiktsmessig, f.eks. med FZ for sammenlignende litteratur, GB* for oppslagsverk, DS* for analyse eller kritikk av tegneserier. Se også DSR <a href="/thema/nb/DSR">Litterære oppslagsverk</a>, FZ <a href="/thema/nb/FZ">Skjønnlitteratur: guider, ledsagende bøker</a>',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'AKLF',
                label: 'Dataspillkunst',
                originalLabel: 'Dataspillkunst',
                alternativeLabels: ['videospill'],
                note: 'Brukes for bøker om det kunstneriske aspektet ved dataspilldesign, grafikk, tvspillkunst etc. Se også UGG <a href="/thema/nb/UGG">Spilldesign</a>',
                related: [
                  {
                    id: 'UGG',
                    label: 'Spilldesign',
                    originalLabel: 'Spilldesign',
                    note: 'Brukes for logiske og narrative aspekter ved spilldesign. Se også AKLF <a href="/thema/nb/AKLF">Dataspillkunst</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'AKLP',
                label: 'Plakatkunst',
                originalLabel: 'Plakatkunst',
                alternativeLabels: ['plakater'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'AKP',
            label: 'Produktdesign',
            originalLabel: 'Produktdesign',
            alternativeLabels: ['tilgjengelighet'],
            note: 'Her: universell design, universell utforming, industriell design. Brukes for generelle verk om produktdesign, om design av et spesifikt produkt eller typer av produkter, om funksjonalitet og sikkerhet, bærekraftig design av produkter etc. Se også TBD <a href="/thema/nb/TBD">Teknisk design</a>, UGC <a href="/thema/nb/UGC">Dataassistert konstruksjon (DAK)</a>',
            related: [
              {
                id: 'TBD',
                label: 'Teknisk design',
                originalLabel: 'Teknisk design',
                note: 'Se også AKP <a href="/thema/nb/AKP">Produktdesign</a>, UGC <a href="/thema/nb/UGC">Dataassistert konstruksjon (DAK)</a>',
              },
              {
                id: 'UGC',
                label: 'Dataassistert konstruksjon (DAK)',
                originalLabel: 'Dataassistert konstruksjon (DAK)',
              },
            ],
            children: [],
          },
          {
            id: 'AKR',
            label: 'Møbeldesign',
            originalLabel: 'Møbeldesign',
            alternativeLabels: [
              'stoler',
              'bord',
              'skap',
              'senger',
              'lamper',
              'hyller',
              'bokhyller',
              'reoler',
            ],

            related: [],
            children: [],
          },
          {
            id: 'AKT',
            label: 'Mote- og tekstildesign',
            originalLabel: 'Mote- og tekstildesign',
            alternativeLabels: [
              'klær',
              'kjoler',
              'dresser',
              'klesplagg tekstiler',
              'mote',
              'trender',
            ],
            note: 'Brukes for stiler og trender innen motedesign, generelle verk, referanseverk, bøker om spesielle klesplagg, illustrerte verk om mote etc. Bruk AKT*-koder med AKB for spesifikke motedesignere, med AKX for motehistorie, pluss kvalifikator(er) for stil, sted og tidsperiode der det er hensiktsmessig, med AJC* for fotografiske samlinger eller samlinger av motefotografier, med AGZ for praktiske teknikker for motestudenter og -designere. Foretrekk WF*-koder som hovedkategori for bøker ment for allmennmarkedet og som handler om hvordan man skal kle seg, hvordan man skal se moteriktig ut, har praktiske tips etc. Se også TDPF* <a href="/thema/nb/TDPF">Tekstiler og fibre</a> og dens underkategorier, KNDD <a href="/thema/nb/KNDD">Tekstilindustri</a>, KNSX <a href="/thema/nb/KNSX">Mote- og skjønnhetsbransjen</a>, WJF <a href="/thema/nb/WJF">Mote- og stilguider</a>',
            related: [
              {
                id: 'KNDD',
                label: 'Tekstilindustri',
                originalLabel: 'Tekstilindustri',
                note: 'Brukes for titler som tar for kles- og tekstilindustri. Brukes for verk som tar for seg disse næringene fra et industri-/nærings-/bransjeperspektiv. Se også TDPF <a href="/thema/nb/TDPF">Tekstiler og fibre</a>, AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>',
              },
              {
                id: 'KNSX',
                label: 'Mote- og skjønnhetsbransjen',
                originalLabel: 'Mote- og skjønnhetsbransjen',
                note: 'Her: næringer som driver med sminke, parfyme, hudpleie, hårpleie etc. Brukes for verk som tar for seg disse næringene fra et industri-/nærings-/bransjeperspektiv. Se også AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>, JBCC3 <a href="/thema/nb/JBCC3">Kulturstudier: klær og samfunn</a>, KNDD <a href="/thema/nb/KNDD">Tekstilindustri</a>, TTVH <a href="/thema/nb/TTVH">Frisøryrket, skjønnhetsbehandlinger og salongarbeid</a>, WJF <a href="/thema/nb/WJF">Mote- og stilguider</a>',
              },
              {
                id: 'TDPF',
                label: 'Tekstiler og fibre',
                originalLabel: 'Tekstiler og fibre',
                note: 'Her: spinning, veving, lærarbeid. Brukes for bøker ment for profesjonsmarkedet eller for studenter innen industriell tekstilproduksjon og tilhørende sektorer, inkludert både naturlige materialer og industrielt framstilte materialer. Se også AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>, KNDD <a href="/thema/nb/KNDD">Tekstilindustri</a>',
              },
              {
                id: 'WJF',
                label: 'Mote- og stilguider',
                originalLabel: 'Mote- og stilguider',
              },
            ],
            children: [
              {
                id: 'AKTF',
                label: 'Tilbehør og accessoirer',
                originalLabel: 'Tilbehør og accessoirer',
                alternativeLabels: [
                  'Sko',
                  'vesker',
                  'belter',
                  'hansker',
                  'hatter',
                  'aksessoarer',
                ],
                note: 'Her: hattemakere, skomakere, hanskemakere, mote- og stilretninger innen vesker, hatter, sko, sneakers og annet tilbehør. Brukes for verk som tar for seg aspekter rundt design av tilbehør til mote eller kostymer',
                related: [],
                children: [],
              },
              {
                id: 'AKTR',
                label: 'Nasjonal- og folkedrakter',
                originalLabel: 'Nasjonal- og folkedrakter',
                alternativeLabels: ['gákti', 'samekofte', 'festdrakter'],
                note: 'Her: bunader, folkedrakter, nasjonaldrakter, samedrakter og tradisjonelle klesplagg eller tekstiler som forbindes med ulike grupper eller regioner etc. Brukes for verk som tar for seg nasjonaldrakter fra et historisk perspektiv og for verk som tar for seg nåtidas drakter. Brukes med kvalifikator(er) for sted der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'AKX',
            label: 'Designhistorie',
            originalLabel: 'Designhistorie',
            alternativeLabels: [],
            note: 'Brukes med andre AK*-koder og kvalifikatorer for stil, sted og tidsperiode der det er hensiktsmessig',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'AM',
        label: 'Arkitektur',
        originalLabel: 'Arkitektur',
        alternativeLabels: [],
        note: 'Ved bruk av AM*-koder, tildel også kvalifikator(er) for stil, geografi og tidsperiode der det er hensiktsmessig. Brukes med TNKK eller ABC for bøker om tilpasset gjenbruk, restaurering og konservering av bygninger og strukturer. Brukes med andre TNK*-koder for bøker om tekniske aspekter ved bygninger og konstruksjon',
        related: [],
        children: [
          {
            id: 'AMA',
            label: 'Arkitekturteori',
            originalLabel: 'Arkitekturteori',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'AMB',
            label: 'Arkitekter og arkitektfirmaer',
            originalLabel: 'Arkitekter og arkitektfirmaer',
            alternativeLabels: [],
            note: 'Her: monografier om arkitektur',
            related: [],
            children: [],
          },
          {
            id: 'AMC',
            label: 'Arkitektonisk struktur og design',
            originalLabel: 'Arkitektonisk struktur og design',
            alternativeLabels: [],
            note: 'Her: arkitekttegninger, materialer, prosjektering, plantegninger. Brukes for bøker som tar for seg emnet fra et arkitektonisk perspektiv. Brukes med kvalifikator(er) for stil der det er hensiktsmessig. Foretrekk TN*-koder for verk som tar for seg de tekniske aspektene ved bygge- og konstruksjonsarbeid. Se også TN* <a href="/thema/nb/TN">Byggeteknikk, landmåling og bygging</a> og dens underkategorier',
            related: [
              {
                id: 'TN',
                label: 'Byggeteknikk, landmåling og bygging',
                originalLabel: 'Byggeteknikk, landmåling og bygging',
              },
            ],
            children: [
              {
                id: 'AMCD',
                label: 'Arkitektoniske detaljer, komponenter og motiver',
                originalLabel:
                  'Arkitektoniske detaljer, komponenter og motiver',
                alternativeLabels: [],
                note: 'Brukes for bøker som tar for seg enkeltelementer ved en bygning eller dens struktur, f.eks. vinduer, dører, tak, dekor etc.',
                related: [],
                children: [],
              },
              {
                id: 'AMCM',
                label: 'Arkitektur: materialer',
                originalLabel: 'Arkitektur: materialer',
                alternativeLabels: [],
                note: 'Brukes for bøker som tar for seg strukturer og konstruksjoner med et spesielt blikk på de materialene som er brukt, som betong, murstein, stål, glass, treverk etc. Foretrekk TN*-koder for verk som har en mer teknisk tilnærming til emnet. Se også TN* <a href="/thema/nb/TN">Byggeteknikk, landmåling og bygging</a> og dens underkategorier',
                related: [
                  {
                    id: 'TN',
                    label: 'Byggeteknikk, landmåling og bygging',
                    originalLabel: 'Byggeteknikk, landmåling og bygging',
                  },
                ],
                children: [],
              },
              {
                id: 'AMCR',
                label: 'Miljøvennlig arkitektur og design',
                originalLabel: 'Miljøvennlig arkitektur og design',
                alternativeLabels: ['passivhus'],
                note: 'Her: bærekraftig og energieffektiv eller energinøytral arkitektur, bygningsdesign, interiørdesign, arkeologi, grønne bygninger, økodesign og miljøvennlig design, biofilisk og biomimetisk arkitektur, bærekraftige hjem, karbon-nøytrale byggeteknikker, praktiske guider til materialer, grønne byggeprosjekter, design av passivhus, etc. Brukes med RNU for bøker om bærekraft og TNK*-koder for bøker om bærekraftig bygging og konstruksjon',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'AMD',
            label: 'Arkitektur: profesjonell praksis',
            originalLabel: 'Arkitektur: profesjonell praksis',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'AMG',
            label: 'Arkitektur: offentlige bygninger, nærings- og industribygg',
            originalLabel:
              'Arkitektur: offentlige bygninger, nærings- og industribygg',
            alternativeLabels: ['sykehus', 'museer'],
            note: 'Her: landbruksbygninger, konserthus, arenaer, stadioner, minnesmerker, monumenter, sjukehus, fyr, bibliotek, museum, galleri, utstillingsområder, stasjoner, flyplasser, hotell, fabrikker, lager, kontorer, butikker etc. Brukes for verk om bygninger som ikke primært er ment som boliger. Brukes med AMR der det er hensiktsmessig. Se også JWT <a href="/thema/nb/JWT">Militære institusjoner</a>, WTHM <a href="/thema/nb/WTHM">Reisehåndbøker: museer, historiske steder, gallerier etc.</a>',
            related: [
              {
                id: 'JWT',
                label: 'Militære institusjoner',
                originalLabel: 'Militære institusjoner',
                note: 'Her: regimenter, minnesmerker, minnetavler, krigskirkegårder, kommandosentre, forsvarsverker, krigsskoler, organisasjoner for veteraner etc. Se også AMG <a href="/thema/nb/AMG">Arkitektur: offentlige bygninger, nærings- og industribygg</a>, WCK <a href="/thema/nb/WCK">Militæreffekter, våpen og rustninger</a>',
              },
              {
                id: 'WTHM',
                label:
                  'Reisehåndbøker: museer, historiske steder, gallerier etc.',
                originalLabel:
                  'Reisehåndbøker: museer, historiske steder, gallerier etc.',
                note: 'Her: reisehåndbøker om kulturarvsteder, bygninger, monumenter, steder av kulturhistorisk og arkeologisk betydning etc.',
              },
            ],
            children: [],
          },
          {
            id: 'AMK',
            label: 'Arkitektur: boligbygninger',
            originalLabel: 'Arkitektur: boligbygninger',
            alternativeLabels: [],
            note: 'Her: private hjem, bolighus, leiligheter. Brukes med AMR interiørdesign der det er hensiktsmessig. Se også WJK <a href="/thema/nb/WJK">Interiørdesign og dekor</a>, AMR <a href="/thema/nb/AMR">Arkitektur: interiørdesign</a>',
            related: [
              {
                id: 'AMR',
                label: 'Arkitektur: interiørdesign',
                originalLabel: 'Arkitektur: interiørdesign',
                note: 'Her: bøker for profesjonsmarkedet, inkludert studier av interiørarkitektur og -design, lysdesign, interiørdesign for privatboliger og næringsbygg og andre spesialområder innen fagfeltet. Brukes med AKB for verker om spesifikke interiørdesignere. Foretrekk WJK som hovedkategori for verk som ikke er ment for profesjonsmarkedet. Se også WJK <a href="/thema/nb/WJK">Interiørdesign og dekor</a>',
              },
              {
                id: 'WJK',
                label: 'Interiørdesign og dekor',
                originalLabel: 'Interiørdesign og dekor',
                note: 'Her: innredning for allmennmarkedet. Se også AMR <a href="/thema/nb/AMR">Arkitektur: interiørdesign</a>',
              },
            ],
            children: [
              {
                id: 'AMKH',
                label: 'Arkitektur: små bygninger',
                originalLabel: 'Arkitektur: små bygninger',
                alternativeLabels: [
                  'Sekundærboliger',
                  'strandhytter',
                  'staller',
                  'garasjer',
                  'låver',
                  'trehus',
                  'badehus',
                ],
                note: 'Her: bøker om hytter, skur, trehus, mikrohus, feriehus, sommerboliger, uthus, naust etc. Brukes for bøker om mindre privathus og -bygninger som ikke nødvendigvis er bolighus, og som ofte er brukt i forbindelse med fritid, ferie, rekreasjon eller fornøyelser',
                related: [],
                children: [],
              },
              {
                id: 'AMKL',
                label: 'Arkitektur: borger og festninger',
                originalLabel: 'Arkitektur: borger og festninger',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'AMKS',
                label: 'Arkitektur: slott, herskapshus og herregårder',
                originalLabel: 'Arkitektur: slott, herskapshus og herregårder',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'AMN',
            label: 'Arkitektur: religiøse bygninger',
            originalLabel: 'Arkitektur: religiøse bygninger',
            alternativeLabels: ['kapell', 'stavkirker'],
            note: 'Her: arkitektoniske aspekter ved kloster, katedraler, kirker, moskeer, synagoger, templer og andre religiøse bygninger eller konstruksjoner',
            related: [],
            children: [],
          },
          {
            id: 'AMR',
            label: 'Arkitektur: interiørdesign',
            originalLabel: 'Arkitektur: interiørdesign',
            alternativeLabels: [],
            note: 'Her: bøker for profesjonsmarkedet, inkludert studier av interiørarkitektur og -design, lysdesign, interiørdesign for privatboliger og næringsbygg og andre spesialområder innen fagfeltet. Brukes med AKB for verker om spesifikke interiørdesignere. Foretrekk WJK som hovedkategori for verk som ikke er ment for profesjonsmarkedet. Se også WJK <a href="/thema/nb/WJK">Interiørdesign og dekor</a>',
            related: [
              {
                id: 'WJK',
                label: 'Interiørdesign og dekor',
                originalLabel: 'Interiørdesign og dekor',
                note: 'Her: innredning for allmennmarkedet. Se også AMR <a href="/thema/nb/AMR">Arkitektur: interiørdesign</a>',
              },
            ],
            children: [],
          },
          {
            id: 'AMV',
            label: 'Landskapsarkitektur og -design',
            originalLabel: 'Landskapsarkitektur og -design',
            alternativeLabels: ['landskapsdesign'],
            note: 'Se også WMD <a href="/thema/nb/WMD">Hagedesign og -planlegging</a>',
            related: [
              {
                id: 'WMD',
                label: 'Hagedesign og -planlegging',
                originalLabel: 'Hagedesign og -planlegging',
              },
            ],
            children: [
              {
                id: 'AMVD',
                label: 'Byplanlegging: arkitektoniske aspekter',
                originalLabel: 'Byplanlegging: arkitektoniske aspekter',
                alternativeLabels: [],
                note: 'Se også RPC <a href="/thema/nb/RPC">By- og kommunalplanlegging</a>',
                related: [
                  {
                    id: 'RPC',
                    label: 'By- og kommunalplanlegging',
                    originalLabel: 'By- og kommunalplanlegging',
                    note: 'Her: smarte byer, urban infrastruktur, bruk av det offentlige rom, gatemøbler, byfornyelse etc. Brukes for bøker om byplanlegging, universell design, tilgjengelighet, bærekraftige byer, boligpolitikk etc. Se også AMVD <a href="/thema/nb/AMVD">Byplanlegging: arkitektoniske aspekter</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'AMX',
            label: 'Arkitekturhistorie',
            originalLabel: 'Arkitekturhistorie',
            alternativeLabels: [],
            note: 'Tildel også kvalifikatorer for stil, sted og tidsperiode der det er hensiktsmessig',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'AT',
        label: 'Scenekunst',
        originalLabel: 'Scenekunst',
        alternativeLabels: [],
        note: 'Ved bruk av AT*-koder, tildel også kvalifikatorer for stil, sted og tidsperiode der det er hensiktsmessig',
        related: [],
        children: [
          {
            id: 'ATC',
            label: 'Skuespillere og utøvere',
            originalLabel: 'Skuespillere og utøvere',
            alternativeLabels: ['kjendiser', 'artister'],
            note: 'Her: mediapersonligheter. Brukes med relevant AT*-kode for å indikere teater, film, TV, radio etc. Se også DNBF* <a href="/thema/nb/DNBF">Biografier: kunst og underholdning</a> og dens underkategorier',
            related: [
              {
                id: 'DNBF',
                label: 'Biografier: kunst og underholdning',
                originalLabel: 'Biografier: kunst og underholdning',
                note: 'Her: biografier om personer i kunst- og underholdningsbransjen. Brukes med andre mer spesifikke emnekoder for å angi område der det er mulig, f.eks. ATC og ATF for biografier om filmskuespillere, eller ATJ for TV-personligheter, eller AVP for musikere, AGB for kunstnere, AKB og AKT for motedesignere, ATJS1 for reality-kjendiser fra TV etc.',
              },
            ],
            children: [],
          },
          {
            id: 'ATD',
            label: 'Teatervitenskap',
            originalLabel: 'Teatervitenskap',
            alternativeLabels: ['teater'],
            note: 'Se også DD* <a href="/thema/nb/DD">Skuespill</a> og dens underkategorier',
            related: [
              {
                id: 'DD',
                label: 'Skuespill',
                originalLabel: 'Skuespill',
                note: 'Ved bruk av DD*-koder, tildel også kvalifikator(er) for sted og/eller tidsperiode og stil der disse aspektene er spesielt relevante og viktige; andre emnekoder, inkludert FX*-koder kan brukes sammen med DD*-koder for å angi sentrale temaer i skuespillet. Kvalifikatorer SKAL IKKE BRUKES for å angi hvor eller når skuespillet ble publisert. Se også ATFD <a href="/thema/nb/ATFD">Filmmanus</a>, ATJD <a href="/thema/nb/ATJD">TV: manus og forestillinger</a>, ATLD <a href="/thema/nb/ATLD">Radio: hørespill, manus og forestillinger</a>',
              },
            ],
            children: [
              {
                id: 'ATDC',
                label: 'Skuespillerteknikker',
                originalLabel: 'Skuespillerteknikker',
                alternativeLabels: [],
                note: 'Her: monologer, auditionmateriale, råd og teknikker, stemmeskuespill, stemmeteknikker, skuespillermetoder, skuespill foran kamera, improvisasjonsteknikker, bevegelsesteknikker for skuespillere etc.',
                related: [],
                children: [],
              },
              {
                id: 'ATDF',
                label: 'Teater: regi og produksjon',
                originalLabel: 'Teater: regi og produksjon',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'ATDH',
                label: 'Teater: teknikk og bakgrunnskunnskaper',
                originalLabel: 'Teater: teknikk og bakgrunnskunnskaper',
                alternativeLabels: ['kostymedesign', 'scenografi'],
                note: 'Her: sceneteknikk, kostymer, hår og sminke, lys- og lydteknikk, kulisser og rekvisitter, scene- og settdesign, sceneledelse, spesialeffekter',
                related: [],
                children: [],
              },
              {
                id: 'ATDS',
                label: 'Teateradministrasjon',
                originalLabel: 'Teateradministrasjon',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'ATF',
            label: 'Film og kino',
            originalLabel: 'Film og kino',
            alternativeLabels: [],
            note: 'Bruk ATF*-koder kun for aspekter ved utøvende kunst, både når det gjelder film for kino, filmer lagd for TV eller filmer lagd for nye medier, inkludert strømmetjenester, video on demand eller andre tjenester (men ikke episodiske produksjoner). Se også KNTC <a href="/thema/nb/KNTC">Film-, TV- og radiobransjen</a>',
            related: [
              {
                id: 'KNTC',
                label: 'Film-, TV- og radiobransjen',
                originalLabel: 'Film-, TV- og radiobransjen',
                note: 'Brukes for bøker om bransjer, selskaper og teknologi i tilknytning til TV, kino, video, radio, podkast og lyd, inkludert strømmetjenester',
              },
            ],
            children: [
              {
                id: 'ATFA',
                label: 'Filmhistorie, -teori eller -kritikk',
                originalLabel: 'Filmhistorie, -teori eller -kritikk',
                alternativeLabels: [
                  'movie franchise',
                  'filmteori',
                  'filmkritikk',
                ],
                note: 'Brukes for bøker om kinoer, historikk rundt eller kritikk av spesifikke filmer, filmstudioer, filmserier',
                related: [],
                children: [],
              },
              {
                id: 'ATFB',
                label: 'Filmregissører og filmskapere',
                originalLabel: 'Filmregissører og filmskapere',
                alternativeLabels: ['filmprodusenter'],

                related: [],
                children: [],
              },
              {
                id: 'ATFD',
                label: 'Filmmanus',
                originalLabel: 'Filmmanus',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'ATFG',
                label: 'Filmguider og -anmeldelser',
                originalLabel: 'Filmguider og -anmeldelser',
                alternativeLabels: ['filmanmeldelser'],

                related: [],
                children: [],
              },
              {
                id: 'ATFN',
                label: 'Filmstiler og- sjangre',
                originalLabel: 'Filmstiler og- sjangre',
                alternativeLabels: ['filmsjanger'],
                note: 'Her: bøker om spesifikke stilarter eller sjangre innen film. Brukes med ATM*-koder eller andre ATF*-koder for å beskrive filmens stilart eller sjanger, der det er hensiktsmessig. Se også ATM* <a href="/thema/nb/ATM">Film, TV, radio og sjangre innen utøvende kunst</a> og dens underkategorier',
                related: [
                  {
                    id: 'ATM',
                    label: 'Film, TV, radio og sjangre innen utøvende kunst',
                    originalLabel:
                      'Film, TV, radio og sjangre innen utøvende kunst',
                    note: 'Brukes kun for aspekter ved utøvende kunst innen disse sjangrene. Brukes med andre AT*-koder for å angi at ei bok handler om en spesifikk sjanger innen film, tv-serier, hørespill, sceneforestillinger eller med AVLM for bøker om filmmusikaler. Foretrekk F*-koder bøker som er fiksjon innen spesielle sjangere, inkludert utgivelser med film- eller tv-omslag. Se også ATJS <a href="/thema/nb/ATJS">TV: stiler og sjangre</a>, ATFN <a href="/thema/nb/ATFN">Filmstiler og- sjangre</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'ATFR',
                label: 'Dokumentarfilmer',
                originalLabel: 'Dokumentarfilmer',
                alternativeLabels: ['dokumentarer'],
                note: 'Brukes for bøker om dokumentarfilmer av kort eller langt format, for dokumentarfilmer lagd for kinovisning eller TV',
                related: [],
                children: [],
              },
              {
                id: 'ATFV',
                label: 'Animasjonsfilmer og animasjon',
                originalLabel: 'Animasjonsfilmer og animasjon',
                alternativeLabels: [],
                note: 'Brukes med AGZ for animasjonsteknikker. Se også UGN <a href="/thema/nb/UGN">Digital animasjon</a>',
                related: [
                  {
                    id: 'UGN',
                    label: 'Digital animasjon',
                    originalLabel: 'Digital animasjon',
                  },
                ],
                children: [
                  {
                    id: 'ATFV1',
                    label: 'Anime',
                    originalLabel: 'Anime',
                    alternativeLabels: [],
                    note: 'Brukes for animasjonsfilmer i japansk stil. Se også AKLC1 <a href="/thema/nb/AKLC1">Grafiske romaner og mangakunst</a>',
                    related: [
                      {
                        id: 'AKLC1',
                        label: 'Grafiske romaner og mangakunst',
                        originalLabel: 'Grafiske romaner og mangakunst',
                        note: 'Brukes med kode(r) fra X* for å angi kunstverkets form. Se også XA* <a href="/thema/nb/XA">Grafiske romaner/ Tegneserier / Manga: stilarter / tradisjoner</a> og dens underkategorier, XR* <a href="/thema/nb/XR">Grafiske romaner, tegneserier og manga: oppslagsverk, guider og anmeldelser</a> og dens underkategorier, AFF <a href="/thema/nb/AFF">Tegnekunst og tegninger</a>',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'ATFX',
                label: 'Filmproduksjon: teknikk og bakgrunnskunnskaper',
                originalLabel: 'Filmproduksjon: teknikk og bakgrunnskunnskaper',
                alternativeLabels: ['Casting'],
                note: 'Her: kinematografi, redigering, klær og sminke, lyssetting, natur, kostymer, spesialeffekter, design, rekvisitter, rollebesetning etc. Se også CBVS <a href="/thema/nb/CBVS">Teknikker innen manusskriving</a>, UG* <a href="/thema/nb/UG">Datagrafikk og digitale medier</a> og dens underkategorier',
                related: [
                  {
                    id: 'CBVS',
                    label: 'Teknikker innen manusskriving',
                    originalLabel: 'Teknikker innen manusskriving',
                  },
                  {
                    id: 'UG',
                    label: 'Datagrafikk og digitale medier',
                    originalLabel: 'Datagrafikk og digitale medier',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'ATJ',
            label: 'TV',
            originalLabel: 'TV',
            alternativeLabels: ['TV'],
            note: 'Bruk ATJ*-koder kun for aspekter ved utøvende kunst, både for lineær TV eller nye medier, inkludert strømmetjenester, video on demand (VOD) eller andre tjenester (inkludert episodiske produksjoner). Se også KNTC <a href="/thema/nb/KNTC">Film-, TV- og radiobransjen</a>',
            related: [
              {
                id: 'KNTC',
                label: 'Film-, TV- og radiobransjen',
                originalLabel: 'Film-, TV- og radiobransjen',
                note: 'Brukes for bøker om bransjer, selskaper og teknologi i tilknytning til TV, kino, video, radio, podkast og lyd, inkludert strømmetjenester',
              },
            ],
            children: [
              {
                id: 'ATJD',
                label: 'TV: manus og forestillinger',
                originalLabel: 'TV: manus og forestillinger',
                alternativeLabels: [],
                note: 'Se også WHG <a href="/thema/nb/WHG">Media-relatert humor</a>',
                related: [
                  {
                    id: 'WHG',
                    label: 'Media-relatert humor',
                    originalLabel: 'Media-relatert humor',
                    note: 'Her: TV-relatert humor, digital humor. Brukes for humoristiske bøker som er inspirert av eller hentet fra mediene, inkludert film, TV, radio, internett, sosiale medier, andre medier eller digitale plattformer',
                  },
                ],
                children: [],
              },
              {
                id: 'ATJS',
                label: 'TV: stiler og sjangre',
                originalLabel: 'TV: stiler og sjangre',
                alternativeLabels: [],
                note: 'Her: bøker om dramaserier, såpeoperaer, situasjonskomedier, programmer lagd for TV, både direktesendte og animerte, fiksjon og fakta, drama, dokumentarer, nyheter etc. Brukes for bøker om hvilken som helst TV-stil eller -sjanger. Brukes med ATM*-koder eller andre AT*-koder for å beskrive en spesifikk stil eller sjanger der det er hensiktsmessig. Se også ATM* <a href="/thema/nb/ATM">Film, TV, radio og sjangre innen utøvende kunst</a> og dens underkategorier',
                related: [
                  {
                    id: 'ATM',
                    label: 'Film, TV, radio og sjangre innen utøvende kunst',
                    originalLabel:
                      'Film, TV, radio og sjangre innen utøvende kunst',
                    note: 'Brukes kun for aspekter ved utøvende kunst innen disse sjangrene. Brukes med andre AT*-koder for å angi at ei bok handler om en spesifikk sjanger innen film, tv-serier, hørespill, sceneforestillinger eller med AVLM for bøker om filmmusikaler. Foretrekk F*-koder bøker som er fiksjon innen spesielle sjangere, inkludert utgivelser med film- eller tv-omslag. Se også ATJS <a href="/thema/nb/ATJS">TV: stiler og sjangre</a>, ATFN <a href="/thema/nb/ATFN">Filmstiler og- sjangre</a>',
                  },
                ],
                children: [
                  {
                    id: 'ATJS1',
                    label: 'TV: reality show',
                    originalLabel: 'TV: reality show',
                    alternativeLabels: [],
                    note: 'Her: talentkonkurranser, konkurransedrevet reality eller gameshow, dokusåpe, datingprogrammer, oppussingsprogrammer, talkshow etc. Brukes for bøker som tar for seg aspekter av reality-TV eller spesifikke programmer eller typer av reality-TV eller personer som deltar i reality-programmer, på TV, strømmetjenester, digitale medier, etc. Brukes med DNBF for biografier om reality-deltakere, med AV*-koder for reality-programmer som tar for seg musikk, etc.',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'ATJX',
                label: 'TV-produksjon: teknikk og bakgrunnskunnskaper',
                originalLabel: 'TV-produksjon: teknikk og bakgrunnskunnskaper',
                alternativeLabels: ['casting'],
                note: 'Her: kameraføring for TV, redigering, klær og sminke, lyssetting, natur, kostymer, spesialeffekter, rekvisitter, rollebesetning etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'ATL',
            label: 'Radio / podkast',
            originalLabel: 'Radio / podkast',
            alternativeLabels: ['podcast'],
            note: 'Bruk ATL*-koder kun for aspekter ved utøvende kunst, både for tradisjonell radio og for nye medier, inkludert strømmetjenester, on demand eller andre tjenester for å konsumere lydinnhold. Se også KNT <a href="/thema/nb/KNT">Media-, underholdnings-, informasjons og kommunikasjonsnæringer</a>',
            related: [
              {
                id: 'KNT',
                label:
                  'Media-, underholdnings-, informasjons og kommunikasjonsnæringer',
                originalLabel:
                  'Media-, underholdnings-, informasjons og kommunikasjonsnæringer',
                note: 'Her: reklamebransjen; presse, trykkerier, post og telekommunikasjonsnæringer etc. Se også GTC <a href="/thema/nb/GTC">Kommunikasjonsstudier</a>, JBCT <a href="/thema/nb/JBCT">Medievitenskap</a>',
              },
            ],
            children: [
              {
                id: 'ATLD',
                label: 'Radio: hørespill, manus og forestillinger',
                originalLabel: 'Radio: hørespill, manus og forestillinger',
                alternativeLabels: [],
                note: 'Her: lyddrama, lydteater, muntlige forestillinger',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'ATM',
            label: 'Film, TV, radio og sjangre innen utøvende kunst',
            originalLabel: 'Film, TV, radio og sjangre innen utøvende kunst',
            alternativeLabels: [],
            note: 'Brukes kun for aspekter ved utøvende kunst innen disse sjangrene. Brukes med andre AT*-koder for å angi at ei bok handler om en spesifikk sjanger innen film, tv-serier, hørespill, sceneforestillinger eller med AVLM for bøker om filmmusikaler. Foretrekk F*-koder bøker som er fiksjon innen spesielle sjangere, inkludert utgivelser med film- eller tv-omslag. Se også ATJS <a href="/thema/nb/ATJS">TV: stiler og sjangre</a>, ATFN <a href="/thema/nb/ATFN">Filmstiler og- sjangre</a>',
            related: [
              {
                id: 'ATFN',
                label: 'Filmstiler og- sjangre',
                originalLabel: 'Filmstiler og- sjangre',
                note: 'Her: bøker om spesifikke stilarter eller sjangre innen film. Brukes med ATM*-koder eller andre ATF*-koder for å beskrive filmens stilart eller sjanger, der det er hensiktsmessig. Se også ATM* <a href="/thema/nb/ATM">Film, TV, radio og sjangre innen utøvende kunst</a> og dens underkategorier',
              },
              {
                id: 'ATJS',
                label: 'TV: stiler og sjangre',
                originalLabel: 'TV: stiler og sjangre',
                note: 'Her: bøker om dramaserier, såpeoperaer, situasjonskomedier, programmer lagd for TV, både direktesendte og animerte, fiksjon og fakta, drama, dokumentarer, nyheter etc. Brukes for bøker om hvilken som helst TV-stil eller -sjanger. Brukes med ATM*-koder eller andre AT*-koder for å beskrive en spesifikk stil eller sjanger der det er hensiktsmessig. Se også ATM* <a href="/thema/nb/ATM">Film, TV, radio og sjangre innen utøvende kunst</a> og dens underkategorier',
              },
            ],
            children: [
              {
                id: 'ATMB',
                label:
                  'Film-, TV-, radiosjangre: spennende og eventyrlige produksjoner, krim og thrillere',
                originalLabel:
                  'Film-, TV-, radiosjangre: spennende og eventyrlige produksjoner, krim og thrillere',
                alternativeLabels: [],
                note: 'Her: bøker om filmer, programmer eller serier som tar for seg kampsport, katastrofer, mysterier, spenning, gangstere, kupp, spioner, etterretning og western, samt actionfylte krigsfilmer eller -serier',
                related: [],
                children: [],
              },
              {
                id: 'ATMC',
                label: 'Film-, TV-, radiosjangre: komedier og humor',
                originalLabel: 'Film-, TV-, radiosjangre: komedier og humor',
                alternativeLabels: [],
                note: 'Her: romantiske komedier, humoristisk infotainment. Brukes for bøker om filmer, programmer eller serier som er basert på humor. Brukes med ATJS for bøker om situasjonskomedier på TV.',
                related: [],
                children: [],
              },
              {
                id: 'ATMF',
                label: 'Film-, TV-, radiosjangre: drama',
                originalLabel: 'Film-, TV-, radiosjangre: drama',
                alternativeLabels: [],
                note: 'Her: romantisk, hverdags- eller realistisk drama. Brukes for bøker om filmer, programmer eller serier. Brukes med ATJS for bøker om såpeoperaer eller dramaserier på TV, eller med ATN for serier og programmer på internett.',
                related: [],
                children: [],
              },
              {
                id: 'ATMH',
                label: 'Film-, TV-, radiosjangre: historisk',
                originalLabel: 'Film-, TV-, radiosjangre: historisk',
                alternativeLabels: [],
                note: 'Her: historiske drama, biografier, filmer etc. Brukes for bøker om filmer, programmer eller serier hvor den historiske perioden er det viktigste aspektet',
                related: [],
                children: [],
              },
              {
                id: 'ATMN',
                label:
                  'Film-, TV-, radiosjangre: science fiction, fantasy og grøssere',
                originalLabel:
                  'Film-, TV-, radiosjangre: science fiction, fantasy og grøssere',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'ATMP',
                label: 'Film-, TV-, radiosjangre: superhelter',
                originalLabel: 'Film-, TV-, radiosjangre: superhelter',
                alternativeLabels: [],
                note: 'Brukes for bøker om filmer, programmer eller serier som er inspirert av tegneseriehelter eller -historier',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'ATN',
            label: 'Internett og digitale medier: kunst og forestillinger',
            originalLabel:
              'Internett og digitale medier: kunst og forestillinger',
            alternativeLabels: [
              'sosiale medier',
              'strømming',
              'podkast',
              'youtubere',
              'instagram',
            ],
            note: 'Her: Alle aspekter ved internett som mediekanal, spesielt for materiale som har oppstått på internett; internettkjendiser, bloggere og blogging, video bloggere (vlogger), podkast etc. Se også UD* <a href="/thema/nb/UD">Digital livsstil og nettsamfunn</a> og dens underkategorier, KNTV <a href="/thema/nb/KNTV">Data- og TV-spillbransjen</a>, KNTX <a href="/thema/nb/KNTX">IT-bransjen</a>',
            related: [
              {
                id: 'KNTV',
                label: 'Data- og TV-spillbransjen',
                originalLabel: 'Data- og TV-spillbransjen',
                note: 'Her: interaktiv underholdningsbransje',
              },
              {
                id: 'KNTX',
                label: 'IT-bransjen',
                originalLabel: 'IT-bransjen',
                note: 'Her: bransjer som jobber med informasjonsteknologi, AI og maskinlæring, utvikling av programvare, etc. Se også KJE <a href="/thema/nb/KJE">E-handel: forretningsmessige aspekter</a>',
              },
              {
                id: 'UD',
                label: 'Digital livsstil og nettsamfunn',
                originalLabel: 'Digital livsstil og nettsamfunn',
                note: 'Brukes for bøker for allmennmarkedet, eller guider som er ment å hjelpe brukerne til å foreta informerte valg om digital teknologi. Se også ATN <a href="/thema/nb/ATN">Internett og digitale medier: kunst og forestillinger</a>',
              },
            ],
            children: [],
          },
          {
            id: 'ATQ',
            label: 'Dans',
            originalLabel: 'Dans',
            alternativeLabels: [],
            note: 'Brukes for generelle verk om teknikker, referanseverk etc. Brukes med JBC*-koder for kultur- og medievitenskap, eller for utgivelser for høyere utdanning eller profesjonsmarkedet. Ved bruk av ATQ*-koder, tildel også kvalifikator(er) for stil der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'ATQC',
                label: 'Koreografi',
                originalLabel: 'Koreografi',
                alternativeLabels: ['dans'],

                related: [],
                children: [],
              },
              {
                id: 'ATQL',
                label: 'Ballett',
                originalLabel: 'Ballett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'ATQR',
                label: 'Selskapsdans',
                originalLabel: 'Selskapsdans',
                alternativeLabels: [
                  'sportsdans',
                  'tango',
                  'vals',
                  'foxtrot',
                  'quickstep',
                  'salsa',
                  'samba',
                  'rumba',
                  'paso doble',
                  'jive',
                  'mambo',
                  'bolero',
                  'lindy hop',
                ],
                note: 'Her: latin (dans)',
                related: [],
                children: [],
              },
              {
                id: 'ATQT',
                label: 'Moderne dans',
                originalLabel: 'Moderne dans',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'ATQV',
                label: 'Dansere / koreografer',
                originalLabel: 'Dansere / koreografer',
                alternativeLabels: ['koreografi'],
                note: 'Her: dansegrupper, dansekompanier',
                related: [],
                children: [],
              },
              {
                id: 'ATQZ',
                label: 'Folkedans',
                originalLabel: 'Folkedans',
                alternativeLabels: [],
                note: 'Class here: traditional dances',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'ATS',
            label: 'Scenekunst: sminke',
            originalLabel: 'Scenekunst: sminke',
            alternativeLabels: [],
            note: 'Her: spesialeffektsminke, SFX-sminke, protesesminke, hårdesign, profesjonell sminke eller TV-sminke, scene- og teatersminke, profesjonsbøker for sminkører, cosplay-sminke. Brukes med andre AT*-koder for bøker beregnet for spesifikke typer scenekunst eller med andre A*-koder for bøker om sminke rettet mot motebransjen, fotografi eller med 4CP* for yrkesfaglig utdanning eller andre 4*-kvalifikatorer ment for utdanning. Se også ATDH <a href="/thema/nb/ATDH">Teater: teknikk og bakgrunnskunnskaper</a>, ATFX <a href="/thema/nb/ATFX">Filmproduksjon: teknikk og bakgrunnskunnskaper</a>, ATJX <a href="/thema/nb/ATJX">TV-produksjon: teknikk og bakgrunnskunnskaper</a>',
            related: [
              {
                id: 'ATDH',
                label: 'Teater: teknikk og bakgrunnskunnskaper',
                originalLabel: 'Teater: teknikk og bakgrunnskunnskaper',
                note: 'Her: sceneteknikk, kostymer, hår og sminke, lys- og lydteknikk, kulisser og rekvisitter, scene- og settdesign, sceneledelse, spesialeffekter',
              },
              {
                id: 'ATFX',
                label: 'Filmproduksjon: teknikk og bakgrunnskunnskaper',
                originalLabel: 'Filmproduksjon: teknikk og bakgrunnskunnskaper',
                note: 'Her: kinematografi, redigering, klær og sminke, lyssetting, natur, kostymer, spesialeffekter, design, rekvisitter, rollebesetning etc. Se også CBVS <a href="/thema/nb/CBVS">Teknikker innen manusskriving</a>, UG* <a href="/thema/nb/UG">Datagrafikk og digitale medier</a> og dens underkategorier',
              },
              {
                id: 'ATJX',
                label: 'TV-produksjon: teknikk og bakgrunnskunnskaper',
                originalLabel: 'TV-produksjon: teknikk og bakgrunnskunnskaper',
                note: 'Her: kameraføring for TV, redigering, klær og sminke, lyssetting, natur, kostymer, spesialeffekter, rekvisitter, rollebesetning etc.',
              },
            ],
            children: [],
          },
          {
            id: 'ATT',
            label: 'Scenekunst: kostymer / rekvisitter',
            originalLabel: 'Scenekunst: kostymer / rekvisitter',
            alternativeLabels: [],
            note: 'Her: design og produksjon av kostymer eller rekvisitter, cosplay eller annen utkledningslek. Brukes med andre AT*-koder for bøker beregnet for spesifikke typer scenekunst eller med 4CP* eller andre 4*-kvalifikatorer ment for utdanning. Se også ATDH <a href="/thema/nb/ATDH">Teater: teknikk og bakgrunnskunnskaper</a>, ATFX <a href="/thema/nb/ATFX">Filmproduksjon: teknikk og bakgrunnskunnskaper</a>, ATJX <a href="/thema/nb/ATJX">TV-produksjon: teknikk og bakgrunnskunnskaper</a>',
            related: [
              {
                id: 'ATDH',
                label: 'Teater: teknikk og bakgrunnskunnskaper',
                originalLabel: 'Teater: teknikk og bakgrunnskunnskaper',
                note: 'Her: sceneteknikk, kostymer, hår og sminke, lys- og lydteknikk, kulisser og rekvisitter, scene- og settdesign, sceneledelse, spesialeffekter',
              },
              {
                id: 'ATFX',
                label: 'Filmproduksjon: teknikk og bakgrunnskunnskaper',
                originalLabel: 'Filmproduksjon: teknikk og bakgrunnskunnskaper',
                note: 'Her: kinematografi, redigering, klær og sminke, lyssetting, natur, kostymer, spesialeffekter, design, rekvisitter, rollebesetning etc. Se også CBVS <a href="/thema/nb/CBVS">Teknikker innen manusskriving</a>, UG* <a href="/thema/nb/UG">Datagrafikk og digitale medier</a> og dens underkategorier',
              },
              {
                id: 'ATJX',
                label: 'TV-produksjon: teknikk og bakgrunnskunnskaper',
                originalLabel: 'TV-produksjon: teknikk og bakgrunnskunnskaper',
                note: 'Her: kameraføring for TV, redigering, klær og sminke, lyssetting, natur, kostymer, spesialeffekter, rekvisitter, rollebesetning etc.',
              },
            ],
            children: [],
          },
          {
            id: 'ATX',
            label: 'Annen utøvende kunst',
            originalLabel: 'Annen utøvende kunst',
            alternativeLabels: ['teater'],
            note: 'Her: pantomime, no, gateteater, varieté, kabaret, sjonglering, buktaling etc.',
            related: [],
            children: [
              {
                id: 'ATXB',
                label: 'Dragkultur og -show',
                originalLabel: 'Dragkultur og -show',
                alternativeLabels: [],
                note: 'Her: dragartister, dragqueens, dragkings. Se også JBCC3 <a href="/thema/nb/JBCC3">Kulturstudier: klær og samfunn</a>',
                related: [
                  {
                    id: 'JBCC3',
                    label: 'Mote og samfunn',
                    originalLabel: 'Kulturstudier: klær og samfunn',
                    note: 'Her: framstilling av syn på skjønnhet, kroppsbilde og hvordan mennesker kler seg i ulike kulturer, bøker som tar for seg ulike aspekter ved klær, kostymer og moter i ulike samfunn eller kulturer, eller bøker som ser på hvordan klær brukes for å kommunisere sosiale roller og sosial status, crossdressing i ulike kulturer osv. Brukes med NHTB for historisk synsvinkel eller AKT*-koder der det er hensiktsmessig',
                  },
                ],
                children: [],
              },
              {
                id: 'ATXC',
                label: 'Sirkus',
                originalLabel: 'Sirkus',
                alternativeLabels: [
                  'klassisk sirkus',
                  'moderne sirkus',
                  'nysirkus',
                  'akrobatikk',
                ],
                note: 'Her: sirkusartister, klovner, linedansere, akrobater. Se også ATXZ <a href="/thema/nb/ATXZ">Dyr som opptrer</a>',
                related: [
                  {
                    id: 'ATXZ',
                    label: 'Dyr som opptrer',
                    originalLabel: 'Dyr som opptrer',
                  },
                ],
                children: [],
              },
              {
                id: 'ATXD',
                label: 'Komedier og standup',
                originalLabel: 'Komedier og standup',
                alternativeLabels: ['stand-up', 'stand up'],
                note: 'Her: improvisasjon, komikere',
                related: [],
                children: [],
              },
              {
                id: 'ATXF',
                label: 'Tryllekunster og magi',
                originalLabel: 'Tryllekunster og magi',
                alternativeLabels: [],
                note: 'Her: illusjoner, korttriks, magikere, tryllekunstnere. Se også QRYX2 <a href="/thema/nb/QRYX2">Magi, alkymi og hermeneutiske tanker</a>, VXWM <a href="/thema/nb/VXWM">Magi, trolldom og alkymi</a>',
                related: [
                  {
                    id: 'QRYX2',
                    label: 'Magi, alkymi og hermeneutiske tanker',
                    originalLabel: 'Magi, alkymi og hermeneutiske tanker',
                    note: 'Her: hermetisme, hermetistisk kabbala, rosenkorsordenen',
                  },
                  {
                    id: 'VXWM',
                    label: 'Magi, trolldom og alkymi',
                    originalLabel: 'Magi, trolldom og alkymi',
                    note: 'Her: utøvere av magi og trylleformler som hekser, trollmenn, magikere etc. Foretrekk VXMT for moderne heksekunst og dens utøvere',
                  },
                ],
                children: [],
              },
              {
                id: 'ATXM',
                label: 'Dukketeater',
                originalLabel: 'Dukketeater',
                alternativeLabels: ['teater'],

                related: [],
                children: [],
              },
              {
                id: 'ATXP',
                label: 'Festspill, parader, festivaler',
                originalLabel: 'Festspill, parader, festivaler',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'ATXZ',
                label: 'Dyr som opptrer',
                originalLabel: 'Dyr som opptrer',
                alternativeLabels: ['sirkus', 'rodeo'],

                related: [],
                children: [
                  {
                    id: 'ATXZ1',
                    label: 'Tyrefekting',
                    originalLabel: 'Tyrefekting',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'ATY',
            label: 'Scenekunstens historie',
            originalLabel: 'Scenekunstens historie',
            alternativeLabels: [],
            note: 'Brukes med andre AT*-koder der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'ATZ',
            label: 'Film, TV, radio eller utøvende kunst: ledsagende bøker',
            originalLabel:
              'Film, TV, radio eller utøvende kunst: ledsagende bøker',
            alternativeLabels: [],
            note: 'Brukes for ikke-skjønnlitterære, ledsagende bøker til TV-serier, filmer, programmer etc. For eksempel en oversikt over karakterer eller steder, guider til objekter, maskiner, våpen, rekvisitter, kostymer, eller en bak kulissene-guide til en serie. Brukes med andre AT*-koder der det er hensiktsmessig. Foretrekk FYV eller FZ for skjønnlitterære utgivelser med film- eller tv-omslag, eller skjønnlitterære utgivelser som er inspirert av spesifikke filmer, serier eller programmer. Se også FYV <a href="/thema/nb/FYV">Skjønnlitteratur: inspirert av eller omarbeidet fra andre medier</a>, FZ <a href="/thema/nb/FZ">Skjønnlitteratur: guider, ledsagende bøker</a>, YFZZ <a href="/thema/nb/YFZZ">Skjønnlitteratur for barn og ungdom: ledsagende bøker</a>',
            related: [
              {
                id: 'FYV',
                label: 'Spinoff',
                originalLabel:
                  'Skjønnlitteratur: inspirert av eller omarbeidet fra andre medier',
                note: 'Brukes for skjønnlitteratur som er utgitt som en spinoff eller som et ledsagende produkt til allerede eksisterende medieprodukter eller -varer som ikke er bøker. F.eks. romaner som er inspirert av populære filmer, spill eller TV-serier. Brukes med andre F*-koder for å angi sjanger eller type, eller med DB*-, DC*-, DD*-koder. Skal IKKE brukes for romaner som i etterkant er blitt filmatisert, bøker med filmomslag etc. (dette beskrives i andre metadatafelt)',
              },
              {
                id: 'FZ',
                label: 'Skjønnlitteratur: guider, ledsagende bøker',
                originalLabel: 'Skjønnlitteratur: guider, ledsagende bøker',
                note: 'Brukes for bøker som er utgitt som ledsagende bøker til populære serier eller enkelttitler, f.eks. et atlas over en mytisk verden, en oversikt over karakterer, en guide til serien etc. Brukes med andre F*-koder for å angi sjanger eller type, eller med DB*-, DC*-, DD*- eller XQ*-koder dersom utgivelsen er et dikt, en tegneserie etc. Se også DSR <a href="/thema/nb/DSR">Litterære oppslagsverk</a>, XR* <a href="/thema/nb/XR">Grafiske romaner, tegneserier og manga: oppslagsverk, guider og anmeldelser</a> og dens underkategorier',
              },
              {
                id: 'YFZZ',
                label: 'Ledsagende bøker',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: ledsagende bøker',
                note: 'Brukes for bøker som er utgitt som ledsagende bøker til populære serier eller enkelttitler, f.eks. et atlas over en mytisk verden, en oversikt over karakterer, en guide til serien etc. Brukes med andre Y*-koder eller alene der det er hensiktsmessig',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'AV',
        label: 'Musikk',
        originalLabel: 'Musikk',
        alternativeLabels: [],
        note: 'Ved bruk av AV*-koder, tildel også kvalifikator(er) for stil, sted eller tidsperiode der det er hensiktsmessig',
        related: [],
        children: [
          {
            id: 'AVA',
            label: 'Musikkteori og -vitenskap',
            originalLabel: 'Musikkteori og -vitenskap',
            alternativeLabels: ['musikkvitenskap'],
            note: 'Her: bøker om musikkteori, musikkanalyse, musikkpsykologi, solfa, kontrapunkt, harmoni, melodier, intervaller, skalaer og moduser, motiver, kadens, refrenger, etnomusikologi, gehør etc.',
            related: [],
            children: [],
          },
          {
            id: 'AVC',
            label: 'Musikkanmeldelser og -kritikk',
            originalLabel: 'Musikkanmeldelser og -kritikk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'AVD',
            label: 'Diskografier og kjøpeguider',
            originalLabel: 'Diskografier og kjøpeguider',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'AVL',
            label: 'Musikk: stiler og sjangre',
            originalLabel: 'Musikk: stiler og sjangre',
            alternativeLabels: [],
            note: 'Ved bruk av AVL*-koder, tildel ALLTID kvalifikator(er) for stil',
            related: [],
            children: [
              {
                id: 'AVLA',
                label: 'Klassisk musikk og orkestermusikk',
                originalLabel: 'Klassisk musikk og orkestermusikk',
                alternativeLabels: [],
                note: 'Her: bøker om klassiske musikktradisjoner fra forskjellige kulturer, som vestlig klassisk musikk, indisk klassisk musikk, kammermusikk, konserter, symfonier, sonater, sangkomposisjoner for vokaler og instrumenter. Brukes med andre AV*-koder, og med stilkvalifikator(er) for å indikere en spesifikk stil eller sjanger av musikk, samt kvalifikatorer for å angi sted og tid',
                related: [],
                children: [],
              },
              {
                id: 'AVLC',
                label: 'Kormusikk',
                originalLabel: 'Kormusikk',
                alternativeLabels: [],
                note: 'Her: kormusikk fra alle perioder og tradisjoner. Brukes med AVLK for religiøs kormusikk, som f. eks. gregoriansk sang, eller med andre AV*-koder, og med stilkvalifikator(er) for å indikere en spesifikk stil eller sjanger av musikk, samt kvalifikatorer for å angi sted og tid',
                related: [],
                children: [],
              },
              {
                id: 'AVLF',
                label: 'Opera',
                originalLabel: 'Opera',
                alternativeLabels: [],
                note: 'Her: operetter',
                related: [],
                children: [],
              },
              {
                id: 'AVLK',
                label: 'Religiøs musikk',
                originalLabel: 'Religiøs musikk',
                alternativeLabels: ['gregoriansk musikk', 'kirkemusikk'],
                note: 'Her: salmer, gospel, kormusikk, oratorier, tonesatte bønner, religiøs sang, liturgisk musikk, hellig eller rituell dans. Brukes for bøker om musikk assosiert med eller brukt i religiøse ritualer. Brukes med QR*-koder og kvalifikator(er) for sted og tid der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'AVLM',
                label: 'Musikk fra film og scene',
                originalLabel: 'Musikk fra film og scene',
                alternativeLabels: ['soundtrack'],
                note: 'Her: Bøker om musikaler, filmmusikk',
                related: [],
                children: [],
              },
              {
                id: 'AVLP',
                label: 'Populærmusikk',
                originalLabel: 'Populærmusikk',
                alternativeLabels: [],
                note: 'Her: jazz, rock, pop, soul, rap, blues, country, reggae etc., og varianter av disse sjangerne. Brukes for alle typer populærmusikk som er forskjellig fra klassisk og tradisjonell musikk. Brukes med stilkvalifikator(er) for å indikere stil eller musikksjanger',
                related: [],
                children: [],
              },
              {
                id: 'AVLT',
                label: 'Tradisjonell musikk og folkemusikk',
                originalLabel: 'Tradisjonell musikk og folkemusikk',
                alternativeLabels: [],
                note: 'Her: sjømannsviser, drikkeviser, idrettssanger, protestsanger, sanger basert på muntlig tradisjon, etc. Brukes for bøker om sanger og musikk som har blitt framført over en lang periode (vanligvis flere generasjoner). Som oftest folkemusikk og andre musikksjangere som forbindes med tradisjoner og kulturarv i en spesifikk region. Brukes med kvalifikator(er) for sted og stil der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'AVLW',
                label: 'Andre internasjonale og regionale musikksjangere',
                originalLabel:
                  'Andre internasjonale og regionale musikksjangere',
                alternativeLabels: [],
                note: 'Her: verdensmusikk, world music. Brukes for bøker om musikk som blander to eller flere sjangere eller kulturelle tradisjoner, eller bøker om musikksjangere som ikke passer inn i en av de andre kategoriene. Brukes med kvalifikator (er) for sted der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'AVLX',
                label: 'Elektronisk musikk',
                originalLabel: 'Elektronisk musikk',
                alternativeLabels: ['EDM'],
                note: 'Brukes for bøker om musikk som produseres ved bruk av elektroniske eller digitale instrumenter eller teknologi. Se også AVRS <a href="/thema/nb/AVRS">Elektroniske musikkinstrumenter</a>',
                related: [
                  {
                    id: 'AVRS',
                    label: 'Elektroniske musikkinstrumenter',
                    originalLabel: 'Elektroniske musikkinstrumenter',
                    note: 'Her: synthesizer. Se også AVLX <a href="/thema/nb/AVLX">Elektronisk musikk</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'AVM',
            label: 'Musikkhistorie',
            originalLabel: 'Musikkhistorie',
            alternativeLabels: [],
            note: 'Brukes med kvalifikator(er) for stil, sted og tidsperiode der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'AVN',
            label: 'Komponister og låtskrivere',
            originalLabel: 'Komponister og låtskrivere',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'AVP',
            label: 'Musikere, sangere, band og grupper',
            originalLabel: 'Musikere, sangere, band og grupper',
            alternativeLabels: [],
            note: 'Her: dirigenter, orkestre, korps, storband og andre musikkgrupper eller musikere og sangere, popgrupper, jazzmusikere, klassiske musikere, etc. Brukes med andre AV*-koder og stilkvalifikator(er) for å angi en spesifikk sjanger, og med DNBF for musikkbiografier',
            related: [],
            children: [],
          },
          {
            id: 'AVQ',
            label: 'Noter, sangtekster og librettoer',
            originalLabel: 'Noter, sangtekster og librettoer',
            alternativeLabels: ['libretto'],
            note: 'Her: notetrykk, noter for vokaler, grupper, kor, orkestre, band etc. Brukes med AVR*-koder for å angi om notene er for et spesifikt instrument, med andre AV* koder, og med stilkvalifikator(er) for å angi musikksjanger',
            related: [],
            children: [
              {
                id: 'AVQS',
                label: 'Sangbøker',
                originalLabel: 'Sangbøker',
                alternativeLabels: [],
                note: 'Her: sanger for enkel pianospilling, gitar, eller for andre instrumenter og stemmer, etc. Brukes med andre AV*-koder og med stilkvalifikator(er) for å angi musikksjanger. Se også AVSA <a href="/thema/nb/AVSA">Sangteknikker</a>',
                related: [
                  {
                    id: 'AVSA',
                    label: 'Sangteknikker',
                    originalLabel: 'Sangteknikker',
                    note: 'Her: det å lære å synge, stemmen som et musikkinstrument, stemmeteknikk for kor, solostemmer, duoer, kvinne- og mannsstemmer, musikalske ensembler, etc. Brukes med AVQ*-koder for sangnoter og AVR*-koder for sang til spesifikke instrumenter, AVL*-koder og stilkvalifikator(er) for spesifikke musikkstiler. Se også AVQS <a href="/thema/nb/AVQS">Sangbøker</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'AVR',
            label: 'Musikkinstrumenter',
            originalLabel: 'Musikkinstrumenter',
            alternativeLabels: [],
            note: 'Brukes for bøker om spesifikke typer instrumenter eller deres historiske og geografiske opprinnelse. Bruk alle AVR*-koder med AVQ* for noter beregnet på spesifikke typer instrumenter, med AVP for bøker om musikere, sangere, band og grupper knyttet til en bestemt instrumenttype, med AVL*-koder der instrumentet er knyttet til en spesifikk type musikkstil, med AVS for bøker om å lære å spille et instrument, og med kvalifikatorer for stil og sted der det er hensiktsmessig.',
            related: [],
            children: [
              {
                id: 'AVRG',
                label: 'Tangentinstrumenter',
                originalLabel: 'Tangentinstrumenter',
                alternativeLabels: [],
                note: 'Her: orgel, cembalo, trekkspill, celesta, klavikord, keyboard. Brukes for bøker om tangentinstrumenter eller noter for tangentinstrumenter eller typer av tangentinstrumenter fra andre musikkulturer og -tradisjoner, der det ikke finnes en mer spesifikk kode. Brukes med AVQ*-koder for noter, AVL*-koder og stilkvalifikator(er) for spesifikke musikkstiler',
                related: [],
                children: [
                  {
                    id: 'AVRG1',
                    label: 'Piano',
                    originalLabel: 'Piano',
                    alternativeLabels: ['pianoforte'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'AVRJ',
                label: 'Slaginstrumenter',
                originalLabel: 'Slaginstrumenter',
                alternativeLabels: ['cymbal', 'xylofon'],
                note: 'Her: trommer, bjeller, cymbaler, gongonger, xylofoner, klokkespill, bongotrommer, vibrafoner, tamburiner, kastanjetter, maracas, pauker. Brukes for bøker om perkusjonsinstrumenter eller noter for perkusjonsinstrumenter eller typer av perkusjonsinstrumenter fra andre musikkulturer og -tradisjoner, der det ikke finnes en mer spesifikk kode. Brukes med AVQ-koder for noter, AVL*-koder og stilkvalifikator(er) for spesifikke musikkstiler',
                related: [],
                children: [],
              },
              {
                id: 'AVRL',
                label: 'Strengeinstrumenter',
                originalLabel: 'Strengeinstrumenter',
                alternativeLabels: ['cello', 'kontrabass'],
                note: 'Her: sitar, mandolin, bouzouki, mandola, balalaika, etc. Brukes for bøker om strengeinstrumenter eller noter for strengeinstrumenter, eller typer av strengeinstrumenter fra andre musikkulturer og -tradisjoner, der det ikke finnes en mer spesifikk kode. Brukes med AVQ-koder for noter, AVL*-koder og stilkvalifikator(er) for spesifikke musikkstiler',
                related: [],
                children: [
                  {
                    id: 'AVRL1',
                    label: 'Gitarer',
                    originalLabel: 'Gitarer',
                    alternativeLabels: ['mandolin', 'lutt'],
                    note: 'Her: klassisk gitar, akustisk gitar, elektrisk gitar, bassgitar, steelgitar, etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'AVRL2',
                    label: 'Klimpreinstrumenter',
                    originalLabel: 'Klimpreinstrumenter',
                    alternativeLabels: ['siter'],
                    note: 'Her: harpe, lyre, dulcimer',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'AVRL3',
                    label: 'Fiolin',
                    originalLabel: 'Fiolin',
                    alternativeLabels: ['fele'],
                    note: 'Her: fiolin og andre fiolininstrumenter som cello, bratsj, kontrabass, fele, altfiolin, gambe, buede strengeinstrumenter',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'AVRN',
                label: 'Blåseinstrumenter',
                originalLabel: 'Blåseinstrumenter',
                alternativeLabels: ['tverrfløyte'],
                note: 'Brukes for bøker om blåseinstrumenter eller noter for blåseinstrumenter eller typer av blåseinstrumenter fra andre musikkulturer og -tradisjoner, der det ikke finnes en mer spesifikk kode. Brukes med AVQ-koder for noter, AVL*-koder og stilkvalifikator(er) for spesifikke musikkstiler',
                related: [],
                children: [
                  {
                    id: 'AVRN1',
                    label: 'Messinginstrumenter',
                    originalLabel: 'Messinginstrumenter',
                    alternativeLabels: [],
                    note: 'Her: messinginstrumenter som trompet, trombone, kornett, tuba, horn, etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'AVRN2',
                    label: 'Treblåseinstrumenter',
                    originalLabel: 'Treblåseinstrumenter',
                    alternativeLabels: [],
                    note: 'Her: treblåseinstrumenter som obo, saksofon, fløyte, klarinett',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'AVRQ',
                label: 'Mekaniske musikkinstrumenter',
                originalLabel: 'Mekaniske musikkinstrumenter',
                alternativeLabels: ['lirekasse', 'spilledåse'],

                related: [],
                children: [],
              },
              {
                id: 'AVRS',
                label: 'Elektroniske musikkinstrumenter',
                originalLabel: 'Elektroniske musikkinstrumenter',
                alternativeLabels: ['theremin', 'synthesizer'],
                note: 'Her: synthesizer. Se også AVLX <a href="/thema/nb/AVLX">Elektronisk musikk</a>',
                related: [
                  {
                    id: 'AVLX',
                    label: 'Elektronisk musikk',
                    originalLabel: 'Elektronisk musikk',
                    note: 'Brukes for bøker om musikk som produseres ved bruk av elektroniske eller digitale instrumenter eller teknologi. Se også AVRS <a href="/thema/nb/AVRS">Elektroniske musikkinstrumenter</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'AVS',
            label: 'Musikkteknikker, musikkveiledninger og musikkundervisning',
            originalLabel:
              'Musikkteknikker, musikkveiledninger og musikkundervisning',
            alternativeLabels: [],
            note: 'Her: instrumentopplæring; selvstudium og bøker for eller om undervisning i musikk, dirigentteknikk',
            related: [],
            children: [
              {
                id: 'AVSA',
                label: 'Sangteknikker',
                originalLabel: 'Sangteknikker',
                alternativeLabels: [],
                note: 'Her: det å lære å synge, stemmen som et musikkinstrument, stemmeteknikk for kor, solostemmer, duoer, kvinne- og mannsstemmer, musikalske ensembler, etc. Brukes med AVQ*-koder for sangnoter og AVR*-koder for sang til spesifikke instrumenter, AVL*-koder og stilkvalifikator(er) for spesifikke musikkstiler. Se også AVQS <a href="/thema/nb/AVQS">Sangbøker</a>',
                related: [
                  {
                    id: 'AVQS',
                    label: 'Sangbøker',
                    originalLabel: 'Sangbøker',
                    note: 'Her: sanger for enkel pianospilling, gitar, eller for andre instrumenter og stemmer, etc. Brukes med andre AV*-koder og med stilkvalifikator(er) for å angi musikksjanger. Se også AVSA <a href="/thema/nb/AVSA">Sangteknikker</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'AVSD',
                label: 'Komposisjon',
                originalLabel: 'Komposisjon',
                alternativeLabels: ['komponering'],
                note: 'Her: komposisjon av musikk, musikkarrangement, transponering, sangskriving',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'AVX',
            label: 'Musikkinnspilling og reproduksjon',
            originalLabel: 'Musikkinnspilling og reproduksjon',
            alternativeLabels: ['studioteknikk', 'miksing', 'sampling'],
            note: 'Brukes for bøker om innspilling og reproduksjon av musikk og lyd, både generelle verk og bøker med ei praktisk tilnærming. For bøker med ei mer teknisk tilnærming, foretrekk passende koder fra T* eller U*. Se også KNT <a href="/thema/nb/KNT">Media-, underholdnings-, informasjons og kommunikasjonsnæringer</a>, TTA <a href="/thema/nb/TTA">Akustisk teknologi og lydteknikk</a>, UDM <a href="/thema/nb/UDM">Digital musikk og lyd: brukerveiledninger</a>, UGM <a href="/thema/nb/UGM">Digital musikk: profesjonelt</a>',
            related: [
              {
                id: 'KNT',
                label:
                  'Media-, underholdnings-, informasjons og kommunikasjonsnæringer',
                originalLabel:
                  'Media-, underholdnings-, informasjons og kommunikasjonsnæringer',
                note: 'Her: reklamebransjen; presse, trykkerier, post og telekommunikasjonsnæringer etc. Se også GTC <a href="/thema/nb/GTC">Kommunikasjonsstudier</a>, JBCT <a href="/thema/nb/JBCT">Medievitenskap</a>',
              },
              {
                id: 'TTA',
                label: 'Akustisk teknologi og lydteknikk',
                originalLabel: 'Akustisk teknologi og lydteknikk',
                note: 'Her: lyddesign, lydeffekter, lydingeniørarbeid',
              },
              {
                id: 'UDM',
                label: 'Digital musikk og lyd: brukerveiledninger',
                originalLabel: 'Digital musikk og lyd: brukerveiledninger',
                note: 'Use for: works aimed at a general readership about online music platforms, streaming music, audiobook and podcast services, podcasting, internet radio, long form audio services. Se også ATN <a href="/thema/nb/ATN">Internett og digitale medier: kunst og forestillinger</a>',
              },
              {
                id: 'UGM',
                label: 'Digital musikk: profesjonelt',
                originalLabel: 'Digital musikk: profesjonelt',
              },
            ],
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 'C',
    label: 'Språk og lingvistikk',
    originalLabel: 'Språk og lingvistikk',
    alternativeLabels: [],
    note: 'Bruk C*-koder både for akademiske utgivelser og bøker for allmennmarkedet. IKKE BRUK koden ‘C’ aleine, men velg spesifikke kategorier fra C*. Ved bruk av C*-koder, tildel andre emnekoder og kvalifikatorer der det er hensiktsmessig, spesielt kvalifikatorer for språk, sted og pedagogisk formål',
    related: [],
    children: [
      {
        id: 'CB',
        label: 'Språk: referanseverk og generelle verk',
        originalLabel: 'Språk: referanseverk og generelle verk',
        alternativeLabels: [],
        note: 'Ved bruk av CB*-koder tildel også kvalifikator(er) for språk der det er hensiktsmessig',
        related: [],
        children: [
          {
            id: 'CBD',
            label: 'Ordbøker',
            originalLabel: 'Ordbøker',
            alternativeLabels: [],
            note: 'Her: ordlister. Bruk med kvalifikator(er) for språk der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'CBDX',
                label: 'To- og flerspråklige ordbøker',
                originalLabel: 'To- og flerspråklige ordbøker',
                alternativeLabels: [],
                note: 'Bruk med kvalifikator(er) for språk der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'CBF',
            label: 'Tesauruser',
            originalLabel: 'Tesauruser',
            alternativeLabels: [],
            note: 'Bruk med kvalifikator(er) for språk der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'CBG',
            label: 'Grammatikk',
            originalLabel: 'Grammatikk',
            alternativeLabels: [],
            note: 'Bruk med kvalifikator(er) for språk der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'CBP',
            label: 'Offentlige taler: råd og guider',
            originalLabel: 'Offentlige taler: råd og guider',
            alternativeLabels: [],
            note: 'Brukes for generelle bøker med råd om å tale i offentlighet, eksempler på taler og sitater, festtaler, forberedelse av presentasjoner og presentasjonsteknikk. Brukes med WJW for bryllupstaler eller med KJP for presentasjoner i næringslivet',
            related: [],
            children: [],
          },
          {
            id: 'CBV',
            label: 'Kreativ skriving',
            originalLabel: 'Kreativ skriving',
            alternativeLabels: [],
            note: 'Her: om det å skrive, forfatterskap',
            related: [],
            children: [
              {
                id: 'CBVS',
                label: 'Teknikker innen manusskriving',
                originalLabel: 'Teknikker innen manusskriving',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'CBW',
            label: 'Skrive- og redigeringsguider',
            originalLabel: 'Skrive- og redigeringsguider',
            alternativeLabels: ['journalistikk'],
            note: 'Her: guider for generell, journalistisk og teknisk skriving, guider til å skrive brev etc.',
            related: [],
            children: [],
          },
          {
            id: 'CBX',
            label: 'Språk',
            originalLabel: 'Språk',
            alternativeLabels: ['språkhistorie', 'språkvitenskap'],
            note: 'Her: språkhistorie. Brukes for populærvitenskapelige utgivelser om språk eller utgivelser om språk for allmennmarkedet. Foretrekk CF* for utgivelser med mer vitenskapelig eller akademisk synsvinkel',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'CF',
        label: 'Lingvistikk',
        originalLabel: 'Lingvistikk',
        alternativeLabels: [],
        note: 'Her: språkvitenskap, filologi. Brukes for akademiske utgivelser og utgivelser for profesjonsmarkedet',
        related: [],
        children: [
          {
            id: 'CFA',
            label: 'Språkfilosofi',
            originalLabel: 'Språkfilosofi',
            alternativeLabels: [],
            note: 'Se også GTD <a href="/thema/nb/GTD">Semiotikk / semiologi</a>',
            related: [
              {
                id: 'GTD',
                label: 'Semiotikk / semiologi',
                originalLabel: 'Semiotikk / semiologi',
                note: 'Se også CFA <a href="/thema/nb/CFA">Språkfilosofi</a>',
              },
            ],
            children: [],
          },
          {
            id: 'CFB',
            label: 'Sosiolingvistikk',
            originalLabel: 'Sosiolingvistikk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'CFC',
            label: 'Lese- og skriveferdigheter',
            originalLabel: 'Lese- og skriveferdigheter',
            alternativeLabels: ['literacy'],
            note: 'Her: ferdigheter, teorier og emner knyttet til det å kunne lese og skrive, sosiale og kulturelle aspekter ved lesing og skriving. Se også JNTS <a href="/thema/nb/JNTS">Lese-, skrive- og regneopplæring</a>, VSL <a href="/thema/nb/VSL">Lese- og skriveopplæring for voksne: guider og håndbøker</a>',
            related: [
              {
                id: 'JNTS',
                label: 'Lese-, skrive- og regneopplæring',
                originalLabel: 'Lese-, skrive- og regneopplæring',
                note: 'Her: syntetisk lesemetode, multisensorisk leseopplæring, høytlesing, dialogisk lesing. Brukes for bøker som tar for seg teorier rundt undervisning og opplæring i grunnleggende ferdigheter, eller som ser på hvordan utdanning kan forbedre eller motivere til bedre lese-, skrive- eller regneferdigheter i alle aldre. Se også CFC <a href="/thema/nb/CFC">Lese- og skriveferdigheter</a>, CJC* <a href="/thema/nb/CJC">Språkopplæring: spesifikke ferdigheter</a> og dens underkategorier, VSL <a href="/thema/nb/VSL">Lese- og skriveopplæring for voksne: guider og håndbøker</a>, YPCA2 <a href="/thema/nb/YPCA2">Læremidler: førstespråk/morsmål: lese- og skriveferdigheter</a>',
              },
              {
                id: 'VSL',
                label:
                  'Lese- og skriveopplæring for voksne: guider og håndbøker',
                originalLabel:
                  'Lese- og skriveopplæring for voksne: guider og håndbøker',
                note: 'Se også CFC <a href="/thema/nb/CFC">Lese- og skriveferdigheter</a>',
              },
            ],
            children: [],
          },
          {
            id: 'CFD',
            label: 'Psykolingvistikk og kognitiv lingvistikk',
            originalLabel: 'Psykolingvistikk og kognitiv lingvistikk',
            alternativeLabels: [],
            note: 'Her: neurolingvistikk',
            related: [],
            children: [
              {
                id: 'CFDC',
                label: 'Språktilegnelse',
                originalLabel: 'Språktilegnelse',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'CFDM',
                label: 'To- og flerspråklighet',
                originalLabel: 'To- og flerspråklighet',
                alternativeLabels: [],
                note: 'Her: kodeveksling, språkveksling',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'CFF',
            label: 'Historisk og sammenlignende lingvistikk',
            originalLabel: 'Historisk og sammenlignende lingvistikk',
            alternativeLabels: [],
            note: 'Her: etymologi, studier av språkfamilier. Se også CBX <a href="/thema/nb/CBX">Språk</a>',
            related: [
              {
                id: 'CBX',
                label: 'Språk',
                originalLabel: 'Språk',
                note: 'Her: språkhistorie. Brukes for populærvitenskapelige utgivelser om språk eller utgivelser om språk for allmennmarkedet. Foretrekk CF* for utgivelser med mer vitenskapelig eller akademisk synsvinkel',
              },
            ],
            children: [
              {
                id: 'CFFD',
                label: 'Dialekt, slang og sjargong',
                originalLabel: 'Dialekt, slang og sjargong',
                alternativeLabels: ['idiomer'],
                note: 'Brukes med CB*-koder for ordbøker etc., samt kvalifikator(er) for sted og språk der det er hensiktsmessig. Se også WHL <a href="/thema/nb/WHL">Humor: slang og dialekter</a>',
                related: [
                  {
                    id: 'WHL',
                    label: 'Humor: slang og dialekter',
                    originalLabel: 'Humor: slang og dialekter',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'CFG',
            label: 'Semantikk, diskursanalyse, stilistikk',
            originalLabel: 'Semantikk, diskursanalyse, stilistikk',
            alternativeLabels: [],
            note: 'Her: pragmatikk, leksikologi, retorikk, muntlig kommunikasjon',
            related: [],
            children: [],
          },
          {
            id: 'CFH',
            label: 'Fonetikk, fonologi',
            originalLabel: 'Fonetikk, fonologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'CFK',
            label: 'Grammatikk, syntaks og morfologi',
            originalLabel: 'Grammatikk, syntaks og morfologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'CFL',
            label: 'Paleografi (skrifthistorie)',
            originalLabel: 'Paleografi (skrifthistorie)',
            alternativeLabels: [],
            note: 'Her: læren om gamle skriftarter og hvordan de kan forstås',
            related: [],
            children: [
              {
                id: 'CFLA',
                label: 'Skriftsystem, alfabet',
                originalLabel: 'Skriftsystem, alfabet',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'CFM',
            label: 'Leksikografi',
            originalLabel: 'Leksikografi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'CFP',
            label: 'Oversettelse og tolkning',
            originalLabel: 'Oversettelse og tolkning',
            alternativeLabels: [],
            note: 'Her: hermeneutikk',
            related: [],
            children: [],
          },
          {
            id: 'CFX',
            label: 'Data- og korpuslingvistikk',
            originalLabel: 'Data- og korpuslingvistikk',
            alternativeLabels: ['datalingvistikk'],

            related: [],
            children: [],
          },
          {
            id: 'CFZ',
            label: 'Tegnspråk, blindeskrift og annen språklig kommunikasjon',
            originalLabel:
              'Tegnspråk, blindeskrift og annen språklig kommunikasjon',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'CJ',
        label: 'Språkundervisning og språkopplæring',
        originalLabel: 'Språkundervisning og språkopplæring',
        alternativeLabels: [],
        note: 'Bruk CJ*koder for undervisningsmateriell for fremmedspråklige voksne. Tildel også kvalifikator for språk og pedagogisk formål der det er hensiktsmessig. Se også YPC* <a href="/thema/nb/YPC">Læremidler: språk, litteratur, lese- og skriveopplæring</a> og dens underkategorier, 4LE* <a href="/thema/nb/4LE">Engelsk språkopplæring: eksamener og sertifikater</a> og dens underkategorier, 4LZ* <a href="/thema/nb/4LZ">For spesifikk språkopplæring unntatt engelsk språk</a> og dens underkategorier',
        related: [
          {
            id: '4LE',
            label: 'Engelsk språkopplæring: eksamener og sertifikater',
            originalLabel: 'Engelsk språkopplæring: eksamener og sertifikater',
            note: 'Her: ELT, ESL, EAL, ELL, EFL. Brukes for læremidler ment for personer som har engelsk som andre- eller fremmedspråk, eller læremidler ment for den som skal undervise i engelsk til personer som ikke har engelsk som morsmål. Se også 4LZ <a href="/thema/nb/4LZ">For spesifikk språkopplæring unntatt engelsk språk</a>',
          },
          {
            id: '4LZ',
            label: 'For spesifikk språkopplæring unntatt engelsk språk',
            originalLabel: 'For spesifikk språkopplæring unntatt engelsk språk',
            note: 'Brukes med kvalifikatorer for språk der det er hensiktsmessig. Se også 4LE <a href="/thema/nb/4LE">Engelsk språkopplæring: eksamener og sertifikater</a>',
          },
          {
            id: 'YPC',
            label: 'Læremidler: språk, litteratur, lese- og skriveopplæring',
            originalLabel:
              'Læremidler: språk, litteratur, lese- og skriveopplæring',
            note: 'Bruk språkkvalifikatorer der det er hensiktsmessig',
          },
        ],
        children: [
          {
            id: 'CJA',
            label: 'Språkundervisning: teori og metoder',
            originalLabel: 'Språkundervisning: teori og metoder',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'CJAB',
                label:
                  'Språkundervisning og språkopplæring: morsmål eller førstespråk',
                originalLabel:
                  'Språkundervisning og språkopplæring: morsmål eller førstespråk',
                alternativeLabels: [],
                note: 'Brukes for alle utgivelser om å undervise i eller opplæring i et eller flere førstespråk. Se også YPCA <a href="/thema/nb/YPCA">Læremidler: førstespråk/morsmål</a>',
                related: [
                  {
                    id: 'YPCA',
                    label: 'Læremidler: førstespråk/morsmål',
                    originalLabel: 'Læremidler: førstespråk/morsmål',
                    note: 'Her: norsk i den norske skolen (obs! Ikke morsmålsundervisning for fremmedspråklige elever)',
                  },
                ],
                children: [],
              },
              {
                id: 'CJAD',
                label:
                  'Språkundervisning og språkopplæring: andre- eller fremmedspråk',
                originalLabel:
                  'Språkundervisning og språkopplæring: andre- eller fremmedspråk',
                alternativeLabels: ['andrespråk'],
                note: 'Brukes for alle utgivelser om å undervise i eller opplæring i et eller flere fremmedspråk. Se også YPCK <a href="/thema/nb/YPCK">Læremidler: fremmed- eller andrespråk</a>, 4L* <a href="/thema/nb/4L">Språkopplæring: kurs og eksamener</a> og dens underkategorier, 4TP <a href="/thema/nb/4TP">Content and language integrated learning (CLIL)</a>, 4TQ <a href="/thema/nb/4TQ">For Intercultural bilingual education (IBE)</a>',
                related: [
                  {
                    id: '4L',
                    label: 'Språkopplæring: kurs og eksamener',
                    originalLabel: 'Språkopplæring: kurs og eksamener',
                    note: 'Bruk 4L* for læremidler for opplæring i et andrespråk eller fremmedspråk, for læremidler ment personer som har et annet morsmål enn det som læremiddelet dekker. Brukes med kvalifikator(er) for språk der det er hensiktsmessig',
                  },
                  {
                    id: '4TP',
                    label: 'Content and language integrated learning (CLIL)',
                    originalLabel:
                      'Content and language integrated learning (CLIL)',
                    note: 'Brukes for materiale til undervisning i et ikke-språkfag på et fremmedspråk',
                  },
                  {
                    id: '4TQ',
                    label: 'For Intercultural bilingual education (IBE)',
                    originalLabel:
                      'For Intercultural bilingual education (IBE)',
                  },
                  {
                    id: 'YPCK',
                    label: 'Læremidler: fremmed- eller andrespråk',
                    originalLabel: 'Læremidler: fremmed- eller andrespråk',
                    note: 'Bruk YPCK*-koder med kvalifikator(er) for språk der det er hensiktsmessig',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'CJB',
            label:
              'Språkundervisning og språkopplæring: undervisnings- og kursmateriell',
            originalLabel:
              'Språkundervisning og språkopplæring: undervisnings- og kursmateriell',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'CJBC',
                label:
                  'Språkundervisning og språkopplæring: undervisnings- og kursmateriell : kultur / befolkning',
                originalLabel:
                  'Språkundervisning og språkopplæring: undervisnings- og kursmateriell : kultur / befolkning',
                alternativeLabels: [],
                note: 'Brukes for undervisningsmateriell som presenterer forskjellige aspekter ved et lands kultur og befolkning, for språkopplæring på forskjellige nivåer. Brukes med kvalifikator(er) for sted, språk og pedagogisk formål der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'CJBG',
                label: 'Språkopplæring: grammatikk, vokabular og uttale',
                originalLabel:
                  'Språkopplæring: grammatikk, vokabular og uttale',
                alternativeLabels: [],
                note: 'Her: verb, substantiv, adjektiv, idiomer etc.',
                related: [],
                children: [],
              },
              {
                id: 'CJBR',
                label: 'Språkopplæring: lesehefter',
                originalLabel: 'Språkopplæring: lesehefter',
                alternativeLabels: [],
                note: 'Brukes for tekster som er skrevet spesielt for eller er forenklet for de som lærer et språk. Slike tekster har vanligvis angitt et lesenivå og størrelse på ordforråd. Brukes med kvalifikator(er) for språk og pedagogisk formål der det er hensiktsmessig, og med andre emnekoder for å angi emne eller sjanger, f.eks. F*-koder for skjønnlitteratur',
                related: [],
                children: [],
              },
              {
                id: 'CJBT',
                label: 'Språkopplæring: selvstudium',
                originalLabel: 'Språkopplæring: selvstudium',
                alternativeLabels: ['selvstudier'],
                note: 'Brukes for materiale ment for selvstudium innen språkopplæring. Brukes med kvalifikator(er) for språk og pedagogisk formål der det er hensiktsmessig, f.eks. 4L. Se også WTK <a href="/thema/nb/WTK">Parlører</a>',
                related: [
                  {
                    id: 'WTK',
                    label: 'Parlører',
                    originalLabel: 'Parlører',
                    note: 'Tildel også kvalifikator for språk. Se også CJBT <a href="/thema/nb/CJBT">Språkopplæring: selvstudium</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'CJC',
            label: 'Språkopplæring: spesifikke ferdigheter',
            originalLabel: 'Språkopplæring: spesifikke ferdigheter',
            alternativeLabels: [],
            note: 'Brukes med kvalifikator(er) for språk og pedagogisk formål der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'CJCK',
                label: 'Språkopplæring: muntlige ferdigheter',
                originalLabel: 'Språkopplæring: muntlige ferdigheter',
                alternativeLabels: [],
                note: 'Brukes med kvalifikator(er) for språk og pedagogisk formål der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'CJCL',
                label: 'Språkopplæring: lytteferdigheter',
                originalLabel: 'Språkopplæring: lytteferdigheter',
                alternativeLabels: [],
                note: 'Brukes med kvalifikator(er) for språk og pedagogisk formål der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'CJCR',
                label: 'Språkopplæring: leseferdigheter',
                originalLabel: 'Språkopplæring: leseferdigheter',
                alternativeLabels: [],
                note: 'Brukes med kvalifikator(er) for språk og pedagogisk formål der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'CJCW',
                label: 'Språkopplæring: skriveferdigheter',
                originalLabel: 'Språkopplæring: skriveferdigheter',
                alternativeLabels: [],
                note: 'Her: skriftsystemer, alfabeter, komposisjon, etc. Brukes med kvalifikator(er) for språk og pedagogisk formål der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'CJP',
            label: 'Språkopplæring for spesifikke formål',
            originalLabel: 'Språkopplæring for spesifikke formål',
            alternativeLabels: [],
            note: 'Brukes andre emnekoder for å angi formål eller type språk og med kvalifikator(er) for språk og pedagogisk formål der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'CJPD',
                label: 'Språkopplæring for arbeidslivet',
                originalLabel: 'Språkopplæring for arbeidslivet',
                alternativeLabels: [],
                note: 'Brukes med andre emnekoder for å angi formål eller type språk, f.eks. KJ* for språkopplæring i næringslivet, og kvalifikator(er) for språk og pedagogisk formål der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'CJPG',
                label:
                  'Språkopplæring for akademiske, tekniske og vitenskapelige formål',
                originalLabel:
                  'Språkopplæring for akademiske, tekniske og vitenskapelige formål',
                alternativeLabels: [],
                note: 'Brukes med andre emnekoder for å angi formål eller type språk, f.eks. med P* for vitenskap, og kvalifikator(er) for språk og pedagogisk formål der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'D',
    label: 'Biografier, litteratur og litteraturstudier',
    originalLabel: 'Biografier, litteratur og litteraturstudier',
    alternativeLabels: [],
    note: 'Bruk D*-koder både for akademiske utgivelser og bøker for allmennmarkedet. IKKE BRUK koden ‘D’ aleine, men velg spesifikke kategorier fra D*. Ved bruk av D*-koder, tildel andre emnekoder og kvalifikatorer der det er hensiktsmessig, spesielt kvalifikatorer for stil, språk eller tidsperiode',
    related: [],
    children: [
      {
        id: 'DB',
        label: 'Gamle, klassiske og middelaldertekster',
        originalLabel: 'Gamle, klassiske og middelaldertekster',
        alternativeLabels: [],
        note: 'Her: tekster fra den europeiske renessansen. Bruk alle DB*-koder for tekster, inkludert annoterte versjoner, som er skrevet i et tidlig eller klassisk språk, eller en gammel eller arkaisk versjon av et moderne språk, og som ikke vanligvis kan leses av en leser uten spesialkunnskaper om språket. For utgaver for en moderne leser, foretrekkes andre emnekoder som hovedkategori (dvs. oversettelser av disse tekstene hvor språket er modernisert eller hvor tekstene i utgangpunktet er skrevet i et forståelig språk), f.eks. F* for skjønnlitteratur eller DC* for lyrikk, DD* for skuespill, N* for historie, QD* for filosofi, sammen med koder fra DB*',
        related: [],
        children: [
          {
            id: 'DBS',
            label: 'Sagaer og epos',
            originalLabel: 'Sagaer og epos',
            alternativeLabels: [],
            note: 'Brukes for antikken og middelalderens sagaer og epos, enten på originalspråket, en annotert versjon, eller i moderne oversettelse. Foretrekk DC* pluss 6EH for nyere episk diktning. Se også FT <a href="/thema/nb/FT">Slektsromaner</a>, FN <a href="/thema/nb/FN">Tradisjonelle fortellinger, myter og eventyr</a>',
            related: [
              {
                id: 'FN',
                label: 'Tradisjonelle fortellinger, myter og eventyr',
                originalLabel: 'Tradisjonelle fortellinger, myter og eventyr',
                note: 'Her: tradisjonelle lokale folkeeventyr, skrøner, fabler, eller enhver form for tradisjonell historie fortalt eller gjenfortalt som fiksjon. Brukes for moderne gjenfortellinger i romanform eller samlinger av tradisjonelle fortellinger, først og fremst ment for voksne, eller skjønnlitterære bøker som er inspirert av disse tradisjonelle fortellingene eller av folklore. Se også DBS* <a href="/thema/nb/DBS">Sagaer og epos</a> og dens underkategorier, QRS* <a href="/thema/nb/QRS">Oldtidens religioner og mytologier</a> og dens underkategorier, JBGB <a href="/thema/nb/JBGB">Folklore, myter og legender</a>, YFJ <a href="/thema/nb/YFJ">Skjønnlitteratur for barn og ungdom: tradisjonelle historier</a>, YNRU <a href="/thema/nb/YNRU">Barn og ungdom: oldtidens religioner, mytologi og legender</a>',
              },
              {
                id: 'FT',
                label: 'Slektsromaner',
                originalLabel: 'Slektsromaner',
                note: 'Her: familiesagaer, familier og slekter. Brukes for romaner som skildrer en families/familiers skjebne, ofte gjennom flere generasjoner. Brukes med FR*-koder for romantiske sagaer eller med FV når den historiske tidsperioden er viktig. BRUKES IKKE for norrøne sagaer. Se også FS <a href="/thema/nb/FS">Familieromaner</a>',
              },
            ],
            children: [
              {
                id: 'DBSG',
                label: 'Antikkens greske og romerske litteratur',
                originalLabel: 'Antikkens greske og romerske litteratur',
                alternativeLabels: [
                  'myter',
                  'legender',
                  'illiaden',
                  'odysseen',
                  'æneiden',
                ],
                note: 'Brukes for de klassiske eposene, prosa, lyrikk eller skuespill, inkludert versjoner av de tradisjonelle mytene og legendene, skrevet av klassiske greske, hellenske eller latinske forfattere på originalspråket, annoterte versjoner eller moderne oversettelser. Se også QRSG <a href="/thema/nb/QRSG">Oldtidens greske religion og mytologi</a>, QRSL <a href="/thema/nb/QRSL">Romersk religion og mytologi</a>',
                related: [
                  {
                    id: 'QRSG',
                    label: 'Oldtidens greske religion og mytologi',
                    originalLabel: 'Oldtidens greske religion og mytologi',
                    note: 'Se også DBSG <a href="/thema/nb/DBSG">Antikkens greske og romerske litteratur</a>',
                  },
                  {
                    id: 'QRSL',
                    label: 'Romersk religion og mytologi',
                    originalLabel: 'Romersk religion og mytologi',
                    note: 'Se også DBSG <a href="/thema/nb/DBSG">Antikkens greske og romerske litteratur</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'DBSN',
                label: 'Islandske og norrøne sagaer',
                originalLabel: 'Islandske og norrøne sagaer',
                alternativeLabels: [
                  'myter',
                  'legender',
                  'edda',
                  'kongesagaene',
                  'snorre',
                  'voluspå',
                  'heimskringla',
                ],
                note: 'Brukes for islandske og norrøne sagaer på originalspråket, annoterte versjoner eller moderne oversettelser. Se også QRSW <a href="/thema/nb/QRSW">Norrøn religion og mytologi</a>',
                related: [
                  {
                    id: 'QRSW',
                    label: 'Norrøn religion og mytologi',
                    originalLabel: 'Norrøn religion og mytologi',
                    note: 'Brukes for bøker om førkristen norrøn og germansk mytologi og tro (inkludert anglosaksere og vikinger). Se også DBSN <a href="/thema/nb/DBSN">Islandske og norrøne sagaer</a>',
                  },
                ],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'DC',
        label: 'Lyrikk',
        originalLabel: 'Lyrikk',
        alternativeLabels: ['dikt'],
        note: 'Ved bruk av DC*-koder tildel også stilkvalifikator der det er hensiktsmessig, eller kombiner med FX*-koder eller andre emnekategorier for å indikere hovedtema. Foretrekk YDP for lyrikk for barn og ungdom. Brukes med DNBL for biografier om diktere.',
        related: [],
        children: [
          {
            id: 'DCA',
            label: 'Klassisk lyrikk skrevet før 20. århundre',
            originalLabel: 'Klassisk lyrikk skrevet før 20. århundre',
            alternativeLabels: [],
            note: 'Brukes med DCF eller DCQ og DB* der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'DCC',
            label: 'Moderne lyrikk (fra 1900 og fremover)',
            originalLabel: 'Moderne lyrikk (fra 1900 og fremover)',
            alternativeLabels: [],
            note: 'Brukes med DCF eller DCQ eller DCR* der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'DCF',
            label: 'Lyrikk av en forfatter',
            originalLabel: 'Lyrikk av en forfatter',
            alternativeLabels: ['antologier', 'diktsamlinger'],
            note: 'Brukes med DCA eller DCC eller DCR* der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'DCQ',
            label: 'Lyrikkantologier (flere forfattere)',
            originalLabel: 'Lyrikkantologier (flere forfattere)',
            alternativeLabels: ['antologier', 'diktsamlinger'],
            note: 'Her: diktantologier som er organisert ut fra et emne eller en sjanger/tradisjon. Brukes med DCA, DCC, DCR* eller FX* eller andre emnekategorier der det er hensiktsmessig. Tildel kvalifikator(er) for sted kun der det er viktig å spesifisere at innholdet er knyttet til et sted, f.eks. en antologi over australsk lyrikk; bruk kvalifikator(er) for tid for diktantologier knyttet til en spesifikk periode; bruk kvalifikator(er) for språk kun der diktenes språk utgjør en vesentlig del av innholdet, f.eks. en antologi over anglosaksisk diktning både på anglosaksisk og i moderne oversettelse, ment for å skape bedre forståelse av det anglosaksiske språket. Se også DNT <a href="/thema/nb/DNT">Antologier</a>',
            related: [
              {
                id: 'DNT',
                label: 'Antologier',
                originalLabel: 'Antologier',
                note: 'Brukes for alle antologier unntatt poesiantologier. Brukes sammen med en annen kode som indikerer emne eller litterær form der det er mulig. Se også DCQ <a href="/thema/nb/DCQ">Lyrikkantologier (flere forfattere)</a>',
              },
            ],
            children: [],
          },
          {
            id: 'DCR',
            label: 'Lyrikkformer',
            originalLabel: 'Lyrikkformer',
            alternativeLabels: ['poesiformer', 'diktformer'],
            note: 'Brukes for diktsamlinger av en eller flere diktere, hvor diktenes form brukes som hovedinnfallsvinkel for klassifikasjon eller søk. DCR*-koder kan brukes enten med andre DC*-koder eller DB*-koder der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'DCRB',
                label: 'Haiku',
                originalLabel: 'Lyrikkformer: Haiku',
                alternativeLabels: ['poesiformer', 'diktformer'],
                note: 'Brukes for den tradisjonelle japanske diktsjangeren haiku',
                related: [],
                children: [],
              },
              {
                id: 'DCRC',
                label: 'Tanka',
                originalLabel: 'Lyrikkformer: Tanka',
                alternativeLabels: ['poesiformer', 'diktformer'],
                note: 'Brukes for den tradisjonelle japanske diktsjangeren tanka',
                related: [],
                children: [],
              },
              {
                id: 'DCRG',
                label: 'Gasel',
                originalLabel: 'Lyrikkformer: Gasel',
                alternativeLabels: ['poesiformer', 'diktformer', 'ghazal'],
                note: 'Brukes for den persisk-indiske lyriske diktformen gasel',
                related: [],
                children: [],
              },
              {
                id: 'DCRH',
                label: 'Bildedikt',
                originalLabel: 'Lyrikkformer: Bildedikt',
                alternativeLabels: ['poesiformer', 'diktformer', 'figurdikt'],
                note: 'Brukes for dikt hvor teksten er utformet som et tematisk relatert bilde',
                related: [],
                children: [],
              },
              {
                id: 'DCRL',
                label: 'Limerick',
                originalLabel: 'Lyrikkformer: Limerick',
                alternativeLabels: ['poesiformer', 'diktformer', 'kalligram'],
                note: 'Brukes for korte, spøkefulle dikt på fem linjer',
                related: [],
                children: [],
              },
              {
                id: 'DCRS',
                label: 'Sonetter',
                originalLabel: 'Lyrikkformer: Sonetter',
                alternativeLabels: ['poesiformer', 'diktformer'],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'DD',
        label: 'Skuespill',
        originalLabel: 'Skuespill',
        alternativeLabels: [],
        note: 'Ved bruk av DD*-koder, tildel også kvalifikator(er) for sted og/eller tidsperiode og stil der disse aspektene er spesielt relevante og viktige; andre emnekoder, inkludert FX*-koder kan brukes sammen med DD*-koder for å angi sentrale temaer i skuespillet. Kvalifikatorer SKAL IKKE BRUKES for å angi hvor eller når skuespillet ble publisert. Se også ATFD <a href="/thema/nb/ATFD">Filmmanus</a>, ATJD <a href="/thema/nb/ATJD">TV: manus og forestillinger</a>, ATLD <a href="/thema/nb/ATLD">Radio: hørespill, manus og forestillinger</a>',
        related: [
          {
            id: 'ATFD',
            label: 'Filmmanus',
            originalLabel: 'Filmmanus',
          },
          {
            id: 'ATJD',
            label: 'TV: manus og forestillinger',
            originalLabel: 'TV: manus og forestillinger',
            note: 'Se også WHG <a href="/thema/nb/WHG">Media-relatert humor</a>',
          },
          {
            id: 'ATLD',
            label: 'Radio: hørespill, manus og forestillinger',
            originalLabel: 'Radio: hørespill, manus og forestillinger',
            note: 'Her: lyddrama, lydteater, muntlige forestillinger',
          },
        ],
        children: [
          {
            id: 'DDA',
            label: 'Klassiske skuespill skrevet før 20. århundre',
            originalLabel: 'Klassiske skuespill skrevet før 20. århundre',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'DDC',
            label: 'Moderne skuespill (skrevet fra 1900 og fremover)',
            originalLabel: 'Moderne skuespill (skrevet fra 1900 og fremover)',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'DDL',
            label: 'Komedier',
            originalLabel: 'Komedier',
            alternativeLabels: ['tragikomedie'],
            note: 'Her: farser. Brukes for ulike tradisjoner innen teaterkomedier',
            related: [],
            children: [],
          },
          {
            id: 'DDT',
            label: 'Tragedier',
            originalLabel: 'Tragedier',
            alternativeLabels: [],
            note: 'Her: skuespill med hevn som tema',
            related: [],
            children: [],
          },
          {
            id: 'DDV',
            label: 'Monologer / dialoger',
            originalLabel: 'Monologer / dialoger',
            alternativeLabels: ['duologer'],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'DN',
        label: 'Biografier og sanne fortellinger',
        originalLabel: 'Biografier og sanne fortellinger',
        alternativeLabels: ['life-writing', 'livsskriving'],
        note: 'Merk: ved bruk av DN*-koder, tildel også spesifikke emnekoder for å indikere område der det er mulig, f.eks. SFH «Golf» med DNBS eller JW* «Krigføring og forsvar» med DNP for reportasjer fra militære konflikter, hvis hensiktsmessig. Se også FC <a href="/thema/nb/FC">Biografisk skjønnlitteratur</a>',
        related: [
          {
            id: 'FC',
            label: 'Biografisk skjønnlitteratur',
            originalLabel: 'Biografisk skjønnlitteratur',
            note: 'Her: skjønnlitterære livsfortellinger, selvbiografiske fortellinger, faksjon. Brukes for høyt fiksjonaliserte beretninger om livene til virkelige mennesker (inkludert fiksjonaliserte selvbiografier), eller hvor en forfatter bruker hendelser eller erfaringer fra sitt eget liv som en integrert del av narrativet eller historien. Kan brukes med FV der det er aktuelt. Se også DN* <a href="/thema/nb/DN">Biografier og sanne fortellinger</a> og dens underkategorier',
          },
        ],
        children: [
          {
            id: 'DNB',
            label: 'Biografier',
            originalLabel: 'Biografier',
            alternativeLabels: [],
            note: 'Her: Generelle biografier som ikke har en mer presis kode eller der personen er kjent fra flere områder. Brukes med andre mer spesifikke emnekoder for å indikere område, hvis hensiktsmessig, f.eks. kan biografien til en kreftoverlever kombineres med VFJB3 «Takle kreft» eller biografien til en kjent kokk med WB* «Mat og drikke»',
            related: [],
            children: [
              {
                id: 'DNBA',
                label: 'Selvbiografier',
                originalLabel: 'Selvbiografier',
                alternativeLabels: [],
                note: 'Her: Generelle selvbiografier som ikke har en mer presis kode eller der personen er kjent fra flere områder. Brukes med andre mer spesifikke emnekoder, hvis hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'DNBB',
                label: 'Biografier: næringsliv og industri',
                originalLabel: 'Biografier: næringsliv og industri',
                alternativeLabels: [],
                note: 'Her: biografier om bedriftsledere, administrerende direktører, gründere, innovatører, finansfolk, etc. Brukes med K*-koder eller T*-koder, hvis hensiktsmessig',
                related: [],
                children: [
                  {
                    id: 'DNBB1',
                    label: 'Selvbiografier: næringsliv og industri',
                    originalLabel: 'Selvbiografier: næringsliv og industri',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'DNBF',
                label: 'Biografier: kunst og underholdning',
                originalLabel: 'Biografier: kunst og underholdning',
                alternativeLabels: [
                  'stjerner',
                  'kjendiser',
                  'modeller',
                  'skuespillere',
                  'artister',
                ],
                note: 'Her: biografier om personer i kunst- og underholdningsbransjen. Brukes med andre mer spesifikke emnekoder for å angi område der det er mulig, f.eks. ATC og ATF for biografier om filmskuespillere, eller ATJ for TV-personligheter, eller AVP for musikere, AGB for kunstnere, AKB og AKT for motedesignere, ATJS1 for reality-kjendiser fra TV etc.',
                related: [],
                children: [
                  {
                    id: 'DNBF1',
                    label: 'Selvbiografier: kunst og underholdning',
                    originalLabel: 'Selvbiografier: kunst og underholdning',
                    alternativeLabels: [
                      'stjerner',
                      'kjendiser',
                      'modeller',
                      'skuespillere',
                      'artister',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'DNBG',
                label: 'Biografier: sosiale medier',
                originalLabel: 'Biografier: sosiale medier',
                alternativeLabels: [],
                note: 'Her: biografier om influensere i sosiale medier, bloggere, vloggere, instagrammere, snapchattere, youtubere eller andre som er kjent via internett, etc.',
                related: [],
                children: [
                  {
                    id: 'DNBG1',
                    label: 'Selvbiografier: sosiale medier',
                    originalLabel: 'Selvbiografier: sosiale medier',
                    alternativeLabels: [],
                    note: 'Her: selvbiografier om influensere i sosiale medier, bloggere, vloggere, instagrammere, snapchattere, youtubere eller andre som er kjent via internett, etc.',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'DNBH',
                label: 'Biografier: historie, politikk og militæret',
                originalLabel: 'Biografier: historie, politikk og militæret',
                alternativeLabels: [
                  'presidenter',
                  'statsministre',
                  'diktatorer',
                  'generaler',
                  'admiraler',
                  'statsoverhoder',
                ],
                note: 'Her: biografier om presidenter, statsministere, statsoverhoder, politiske ledere, politikere, politiske aktivister, militære eller historiske personer. Brukes med andre mer spesifikke emnekoder for å indikere område, hvis hensiktsmessig, f. eks. med tidskvalifikator(er) og N*-koder for historiske biografier eller JW*-koder eller NHW*-koder for militærbiografier, eller med JP*-koder for politiske biografier',
                related: [],
                children: [
                  {
                    id: 'DNBH1',
                    label: 'Selvbiografier: historie, politikk og militæret',
                    originalLabel:
                      'Selvbiografier: historie, politikk og militæret',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'DNBL',
                label: 'Biografier: forfattere',
                originalLabel: 'Biografier: forfattere',
                alternativeLabels: ['poeter', 'lyrikere'],
                note: 'Her: biografier om forfattere, diktere, journalister etc. Brukes med andre mer spesifikke emnekoder for å indikere område, hvis hensiktsmessig',
                related: [],
                children: [
                  {
                    id: 'DNBL1',
                    label: 'Selvbiografier: forfattere',
                    originalLabel: 'Selvbiografier: forfattere',
                    alternativeLabels: ['poeter', 'lyrikere'],
                    note: 'Her: selvbiografier om forfattere, diktere, journalister etc.',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'DNBM',
                label: 'Biografier: filosofi og samfunnsfag',
                originalLabel: 'Biografier: filosofi og samfunnsfag',
                alternativeLabels: ['filosofer', 'historikere'],
                note: 'Biografier om filosofer og samfunnsvitere. Brukes med andre mer spesifikke emnekoder, for å indikere område hvis hensiktsmessig, f. eks. med JM* for psykologer eller KC for økonomer, eller CF for lingvister, etc.',
                related: [],
                children: [
                  {
                    id: 'DNBM1',
                    label: 'Selvbiografier: filosofi og samfunnsfag',
                    originalLabel: 'Selvbiografier: filosofi og samfunnsfag',
                    alternativeLabels: ['filosofer', 'historikere'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'DNBP',
                label: 'Biografier: eventyrere og oppdagelsesreisende',
                originalLabel: 'Biografier: eventyrere og oppdagelsesreisende',
                alternativeLabels: [],
                note: 'Her: biografier om globetrottere, sjøfarere, vandrere, etc. Brukes med andre mer spesifikke emnekoder for å indikere område, hvis hensiktsmessig',
                related: [],
                children: [
                  {
                    id: 'DNBP1',
                    label: 'Selvbiografier: eventyrere og oppdagelsesreisende',
                    originalLabel:
                      'Selvbiografier: eventyrere og oppdagelsesreisende',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'DNBR',
                label: 'Biografier: kongelige',
                originalLabel: 'Biografier: kongelige',
                alternativeLabels: [
                  'keisere',
                  'keiserinner',
                  'konger',
                  'dronninger',
                  'prinser',
                  'prinsesser',
                ],
                note: 'Her: biografier om konger, dronninger, prinser, prinsesser, keisere, keiserinner, kongefamilier, dynastier eller andre som anses som kongelige. Brukes med kvalifikatorer for sted og tid og N*-koder for historiske biografier, eller med JHPL-kode for biografier om kongelige som også er statsoverhoder, etc.',
                related: [],
                children: [
                  {
                    id: 'DNBR1',
                    label: 'Selvbiografier: kongelige',
                    originalLabel: 'Selvbiografier: kongelige',
                    alternativeLabels: [
                      'keisere',
                      'keiserinner',
                      'konger',
                      'dronninger',
                      'prinser',
                      'prinsesser',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'DNBS',
                label: 'Biografier: sport',
                originalLabel: 'Biografier: sport',
                alternativeLabels: [],
                note: 'Her: biografier om idrettsutøvere og personer som assosieres med sport av ulike typer. Brukes med S*-koder for å angi hva slags sport biografien handler om',
                related: [],
                children: [
                  {
                    id: 'DNBS1',
                    label: 'Selvbiografier: sport',
                    originalLabel: 'Selvbiografier: sport',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'DNBT',
                label: 'Biografier: vitenskap, teknologi og medisin',
                originalLabel: 'Biografier: vitenskap, teknologi og medisin',
                alternativeLabels: ['leger', 'doktorer', 'forskere'],
                note: 'Biografier om personer innenfor vitenskap, teknologi eller medisin. Brukes med andre emnekoder for å indikere område, hvis hensiktsmessig, f. eks. med PB for biografier om matematikere, PN for kjemikere, TN* for ingeniører, MBD for medisinsk profesjon, etc.',
                related: [],
                children: [
                  {
                    id: 'DNBT1',
                    label: 'Selvbiografier: vitenskap, teknologi og medisin',
                    originalLabel:
                      'Selvbiografier: vitenskap, teknologi og medisin',
                    alternativeLabels: ['leger', 'doktorer', 'forskere'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'DNBX',
                label: 'Biografier: religiøse og spirituelle',
                originalLabel: 'Biografier: religiøse og spirituelle',
                alternativeLabels: [],
                note: 'Her: biografier om religiøse eller spirituelle personer eller grupper. Brukes med andre emnekoder for å indikere område, hvis hensiktsmessig, f. eks. QRMB1 for biografier om paven, etc.',
                related: [],
                children: [
                  {
                    id: 'DNBX1',
                    label: 'Selvbiografier: religiøse og spirituelle',
                    originalLabel: 'Selvbiografier: religiøse og spirituelle',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'DNBZ',
                label: 'Biografiske samlinger',
                originalLabel: 'Biografiske samlinger',
                alternativeLabels: ['samlingsbiografier'],
                note: 'Brukes for biografier om flere personer samlet i samme bok. Brukes etter passende DNB*-kode som indikerer emne der det er mulig. Se også GBCB <a href="/thema/nb/GBCB">Biografiske oppslagsverk</a>',
                related: [
                  {
                    id: 'GBCB',
                    label: 'Biografiske oppslagsverk',
                    originalLabel: 'Biografiske oppslagsverk',
                    note: 'Her: Hvem er hvem-bøker. Brukes med andre emnekoder for å angi område for eller verkets omfang',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'DNC',
            label: 'Personlige beretninger',
            originalLabel: 'Personlige beretninger',
            alternativeLabels: [],
            note: 'Personlige beretninger / memoarer / erindringer er vanligvis mindre strukturert og mindre omfattende enn selvbiografier, og er ofte om en del av et liv (f.eks. barndommen, en politikers tid som politiker) snarere enn hele ens liv. Brukes med en selvbiografikode fra DNB*-seksjonen ovenfor, for å indikere området der det er mulig',
            related: [],
            children: [],
          },
          {
            id: 'DND',
            label: 'Dagbøker, brevsamlinger',
            originalLabel: 'Dagbøker, brevsamlinger',
            alternativeLabels: [],
            note: 'Brukes for bøker som inneholder samlinger av dagboknotater eller brev, inkludert korrespondanse mellom flere personer, der verket ikke er skrevet som en selvbiografi eller som memoarer, eller for samlinger av tekster opprinnelig utgitt på internett eller som moderne, digitale versjoner av dagboknotater, f. eks. personlige blogger, eller e-post-korrespondanse. Brukes med en selvbiografikode fra DNB*-seksjonen ovenfor, for å indikere område der det er mulig',
            related: [],
            children: [],
          },
          {
            id: 'DNG',
            label: 'Biografier om dyr',
            originalLabel: 'Biografier om dyr',
            alternativeLabels: [],
            note: 'Brukes for biografier om spesifikke dyr, som for eksempel dyr som opptrer, veddeløpshester, redningshunder etc.',
            related: [],
            children: [],
          },
          {
            id: 'DNL',
            label: 'Litterære essay',
            originalLabel: 'Litterære essay',
            alternativeLabels: [],
            note: 'Brukes for essay eller kåseri om litterære emner. Brukes med andre emnekategorier for å klassifisere essay om et spesifikt emne, f. eks. filosofi, økonomi',
            related: [],
            children: [],
          },
          {
            id: 'DNP',
            label: 'Reportasjer eller journalistiske tekster',
            originalLabel: 'Reportasjer eller journalistiske tekster',
            alternativeLabels: [],
            note: 'Her: samling av spalter. Brukes for bøker hvor tekstene enten har vært publisert tidligere i et nyhetsmedium eller publisert som et originalverk. Brukes med andre emnekoder for å angi spesifikke emner der det er hensiktsmessig, f.eks. JP*-koder for politikk eller KC*-koder for økonomi; brukes alene for generelle verk. Se også WTL* <a href="/thema/nb/WTL">Reiseskildringer</a> og dens underkategorier, AJF <a href="/thema/nb/AJF">Fotojournalistikk og dokumentarfotografi</a>',
            related: [
              {
                id: 'AJF',
                label: 'Fotojournalistikk og dokumentarfotografi',
                originalLabel: 'Fotojournalistikk og dokumentarfotografi',
                note: 'Her: fotoreportasjer, sosiale dokumentarer og fotografier av gatemiljøer, hendelser og kjendiser. Se også DNP <a href="/thema/nb/DNP">Reportasjer eller journalistiske tekster</a>',
              },
              {
                id: 'WTL',
                label: 'Reiseskildringer',
                originalLabel: 'Reiseskildringer',
                note: 'Her: moderne reiseskildringer, reisedagbøker, reiselitteratur. Brukes for bøker som gir en personlig beskrivelse av et sted, et område, et land, og som ofte inneholder personlige anekdoter og inntrykk, og som kan inneholde noe historie, geografi, moderne samfunn og kultur. Bruk WTL* med kvalifikatorer for sted der det er hensiktsmessig',
              },
            ],
            children: [
              {
                id: 'DNPB',
                label: 'Intervjuer / diskusjoner',
                originalLabel: 'Intervjuer / diskusjoner',
                alternativeLabels: [],
                note: 'Brukes for bøker som inneholder intervjusamlinger, eller der innholdet presenteres som et intervju, samtale eller diskusjon mellom to eller flere personer, eller bøker som inneholder opptak eller podkaster av diskusjoner, chatter, intervjuer, debatter, kommentarer etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'DNS',
            label: 'Taler',
            originalLabel: 'Taler',
            alternativeLabels: [],
            note: 'Brukes for samlinger av transkriberte taler. Brukes sammen med en annen kode som indikerer emne der det er mulig. Se også QRVH <a href="/thema/nb/QRVH">Prekener</a>',
            related: [
              {
                id: 'QRVH',
                label: 'Prekener',
                originalLabel: 'Prekener',
                note: 'Her: det å holde preken. Brukes for samlinger og analyser av prekener, prekenpraksis. Se også DNS <a href="/thema/nb/DNS">Taler</a>',
              },
            ],
            children: [],
          },
          {
            id: 'DNT',
            label: 'Antologier',
            originalLabel: 'Antologier',
            alternativeLabels: [],
            note: 'Brukes for alle antologier unntatt poesiantologier. Brukes sammen med en annen kode som indikerer emne eller litterær form der det er mulig. Se også DCQ <a href="/thema/nb/DCQ">Lyrikkantologier (flere forfattere)</a>',
            related: [
              {
                id: 'DCQ',
                label: 'Lyrikkantologier (flere forfattere)',
                originalLabel: 'Lyrikkantologier (flere forfattere)',
                note: 'Her: diktantologier som er organisert ut fra et emne eller en sjanger/tradisjon. Brukes med DCA, DCC, DCR* eller FX* eller andre emnekategorier der det er hensiktsmessig. Tildel kvalifikator(er) for sted kun der det er viktig å spesifisere at innholdet er knyttet til et sted, f.eks. en antologi over australsk lyrikk; bruk kvalifikator(er) for tid for diktantologier knyttet til en spesifikk periode; bruk kvalifikator(er) for språk kun der diktenes språk utgjør en vesentlig del av innholdet, f.eks. en antologi over anglosaksisk diktning både på anglosaksisk og i moderne oversettelse, ment for å skape bedre forståelse av det anglosaksiske språket. Se også DNT <a href="/thema/nb/DNT">Antologier</a>',
              },
            ],
            children: [],
          },
          {
            id: 'DNX',
            label: 'Historier fra virkeligheten',
            originalLabel: 'Historier fra virkeligheten',
            alternativeLabels: [],
            note: 'Bruk DNX*-koder for populære fortellinger om faktiske hendelser som er skrevet i en fortellende eller journalistisk form, eller for bøker som handler om folks selvopplevde hendelser eller erfaringer. Brukes med andre emnekoder for mer spesifikk beskrivelse',
            related: [],
            children: [
              {
                id: 'DNXC',
                label: 'Forbrytelser',
                originalLabel: 'Historier fra virkeligheten: forbrytelser',
                alternativeLabels: ['true crime'],
                note: 'Brukes for sanne fortellinger om ekte forbrytelser, kriminelle handlinger, kriminelle, kriminelle organisasjoner. Brukes med JKV*-koder for mer detaljert beskrivelse av hva slags type kriminalitet, f.eks. JKVM for fortellinger om kriminelle gjenger, eller med JKVK for økonomisk kriminalitet, eller med DNB for biografier om kriminelle, fredløse, gangstere, etc.',
                related: [],
                children: [
                  {
                    id: 'DNXC3',
                    label: 'Seriemordere og drapsmenn',
                    originalLabel:
                      'Historier fra virkeligheten: seriemordere og drapsmenn',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'DNXH',
                label: 'Oppdagelser',
                originalLabel: 'Historier fra virkeligheten: oppdagelser',
                alternativeLabels: [
                  'oppdagelsesreisende',
                  'oppdagere',
                  'utforskere',
                ],
                note: 'Brukes for historiske eller vitenskapelige oppdagelser',
                related: [],
                children: [],
              },
              {
                id: 'DNXM',
                label: 'Krigs- og kamphistorier',
                originalLabel:
                  'Historier fra virkeligheten: krigs- og kamphistorier',
                alternativeLabels: ['krigshistorier'],

                related: [],
                children: [],
              },
              {
                id: 'DNXP',
                label: 'Heltemot, utholdenhet og overlevelse',
                originalLabel:
                  'Historier fra virkeligheten: heltemot, utholdenhet og overlevelse',
                alternativeLabels: [],
                note: 'Brukes hovedsakelig for fysiske prøvelser som følge av uhell, konflikter, katastrofer etc. Se også DNXR <a href="/thema/nb/DNXR">Historier fra virkeligheten: mishandling, overgrep og urettferdighet</a>',
                related: [
                  {
                    id: 'DNXR',
                    label: 'Mishandling, overgrep og urettferdighet',
                    originalLabel:
                      'Historier fra virkeligheten: mishandling, overgrep og urettferdighet',
                    note: 'Brukes for fysiske eller emosjonelle prøvelser som skyldes mishandling, justismord, ofre for kriminalitet etc. Her: selvbiografiske bøker som handler om hovedpersonens kamp og seier over personlige traumer og mishandling. Foretrekk DNXC for objektivt syn på eller kriminelles syn på kriminelle handlinger. Se også DNXC <a href="/thema/nb/DNXC">Historier fra virkeligheten: forbrytelser</a>, DNXP <a href="/thema/nb/DNXP">Historier fra virkeligheten: heltemot, utholdenhet og overlevelse</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'DNXR',
                label: 'Mishandling, overgrep og urettferdighet',
                originalLabel:
                  'Historier fra virkeligheten: mishandling, overgrep og urettferdighet',
                alternativeLabels: [],
                note: 'Brukes for fysiske eller emosjonelle prøvelser som skyldes mishandling, justismord, ofre for kriminalitet etc. Her: selvbiografiske bøker som handler om hovedpersonens kamp og seier over personlige traumer og mishandling. Foretrekk DNXC for objektivt syn på eller kriminelles syn på kriminelle handlinger. Se også DNXC <a href="/thema/nb/DNXC">Historier fra virkeligheten: forbrytelser</a>, DNXP <a href="/thema/nb/DNXP">Historier fra virkeligheten: heltemot, utholdenhet og overlevelse</a>',
                related: [
                  {
                    id: 'DNXC',
                    label: 'Forbrytelser',
                    originalLabel: 'Historier fra virkeligheten: forbrytelser',
                    note: 'Brukes for sanne fortellinger om ekte forbrytelser, kriminelle handlinger, kriminelle, kriminelle organisasjoner. Brukes med JKV*-koder for mer detaljert beskrivelse av hva slags type kriminalitet, f.eks. JKVM for fortellinger om kriminelle gjenger, eller med JKVK for økonomisk kriminalitet, eller med DNB for biografier om kriminelle, fredløse, gangstere, etc.',
                  },
                  {
                    id: 'DNXP',
                    label: 'Heltemot, utholdenhet og overlevelse',
                    originalLabel:
                      'Historier fra virkeligheten: heltemot, utholdenhet og overlevelse',
                    note: 'Brukes hovedsakelig for fysiske prøvelser som følge av uhell, konflikter, katastrofer etc. Se også DNXR <a href="/thema/nb/DNXR">Historier fra virkeligheten: mishandling, overgrep og urettferdighet</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'DNXZ',
                label: 'Erotiske bekjennelser',
                originalLabel:
                  'Historier fra virkeligheten: erotiske bekjennelser',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'DS',
        label: 'Litteraturhistorie og litteraturkritikk',
        originalLabel: 'Litteraturhistorie og litteraturkritikk',
        alternativeLabels: [],
        note: 'Ved bruk av DS*-koder, tildel kvalifikator(er) for språk for å angi språket litteraturen skrives på og for sted for å angi hvor den litterære tradisjonen er plassert geografisk, der den nasjonale eller regionale konteksten er viktig. F.eks. bøker om litteratur på engelsk i Australia eller litteratur på spansk i Mexico. SKAL IKKE BRUKES på de konkrete verkene fra denne tradisjonen (f.eks. en roman), eller for å angi hvor den ble utgitt. Bruk kvalifikator(er) for tidsperiode for utgivelser om spesielle litteraturhistoriske perioder',
        related: [],
        children: [
          {
            id: 'DSA',
            label: 'Litteraturteori',
            originalLabel: 'Litteraturteori',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'DSB',
            label: 'Litteraturvitenskap',
            originalLabel: 'Litteraturvitenskap',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'DSBB',
                label:
                  'Litteraturvitenskap: antikken, middelalderen og klassiske tekster',
                originalLabel:
                  'Litteraturvitenskap: antikken, middelalderen og klassiske tekster',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'DSBC',
                label: 'Litteraturvitenskap: fra 1400 til 1600',
                originalLabel: 'Litteraturvitenskap: fra 1400 til 1600',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'DSBD',
                label: 'Litteraturvitenskap: fra 1600 til 1800',
                originalLabel: 'Litteraturvitenskap: fra 1600 til 1800',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'DSBF',
                label: 'Litteraturvitenskap: fra 1800 til 1900',
                originalLabel: 'Litteraturvitenskap: fra 1800 til 1900',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'DSBH',
                label: 'Litteraturvitenskap: fra 1900 til 2000',
                originalLabel: 'Litteraturvitenskap: fra 1900 til 2000',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'DSBH5',
                    label: 'Litteraturvitenskap: postkolonial litteratur',
                    originalLabel:
                      'Litteraturvitenskap: postkolonial litteratur',
                    alternativeLabels: [],
                    note: 'Her: litteratur som tar for seg maktforholdet mellom de gamle koloniherrene og de tidligere koloniene',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'DSBJ',
                label: 'Litteraturvitenskap: fra 2000',
                originalLabel: 'Litteraturvitenskap: fra 2000',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'DSC',
            label: 'Litteraturvitenskap: lyrikk og lyrikere',
            originalLabel: 'Litteraturvitenskap: lyrikk og lyrikere',
            alternativeLabels: [],
            note: 'Brukes for litteraturkritiske bøker om lyrikk eller lyrikere, og også for generelle verk om enkelte dikt eller lyrikk, inkludert det å sette pris på lyrikk',
            related: [],
            children: [],
          },
          {
            id: 'DSG',
            label: 'Litteraturvitenskap: skuespill og dramatikere',
            originalLabel: 'Litteraturvitenskap: skuespill og dramatikere',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'DSK',
            label: 'Litteraturvitenskap: skjønnlitteratur og forfattere',
            originalLabel:
              'Litteraturvitenskap: skjønnlitteratur og forfattere',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'DSM',
            label: 'Sammenlignende litteraturvitenskap',
            originalLabel: 'Sammenlignende litteraturvitenskap',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'DSR',
            label: 'Litterære oppslagsverk',
            originalLabel: 'Litterære oppslagsverk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'DSRC',
                label: 'Litterære håndbøker, bokanmeldelser og guider',
                originalLabel: 'Litterære håndbøker, bokanmeldelser og guider',
                alternativeLabels: [],
                note: 'Se også FZ <a href="/thema/nb/FZ">Skjønnlitteratur: guider, ledsagende bøker</a>, XR* <a href="/thema/nb/XR">Grafiske romaner, tegneserier og manga: oppslagsverk, guider og anmeldelser</a> og dens underkategorier',
                related: [
                  {
                    id: 'FZ',
                    label: 'Skjønnlitteratur: guider, ledsagende bøker',
                    originalLabel: 'Skjønnlitteratur: guider, ledsagende bøker',
                    note: 'Brukes for bøker som er utgitt som ledsagende bøker til populære serier eller enkelttitler, f.eks. et atlas over en mytisk verden, en oversikt over karakterer, en guide til serien etc. Brukes med andre F*-koder for å angi sjanger eller type, eller med DB*-, DC*-, DD*- eller XQ*-koder dersom utgivelsen er et dikt, en tegneserie etc. Se også DSR <a href="/thema/nb/DSR">Litterære oppslagsverk</a>, XR* <a href="/thema/nb/XR">Grafiske romaner, tegneserier og manga: oppslagsverk, guider og anmeldelser</a> og dens underkategorier',
                  },
                  {
                    id: 'XR',
                    label:
                      'Grafiske romaner, tegneserier og manga: oppslagsverk, guider og anmeldelser',
                    originalLabel:
                      'Grafiske romaner, tegneserier og manga: oppslagsverk, guider og anmeldelser',
                    note: 'Brukes med andre emnekoder der det er hensiktsmessig, f.eks. med FZ for sammenlignende litteratur, GB* for oppslagsverk, DS* for analyse eller kritikk av tegneserier. Se også DSR <a href="/thema/nb/DSR">Litterære oppslagsverk</a>, FZ <a href="/thema/nb/FZ">Skjønnlitteratur: guider, ledsagende bøker</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'DSY',
            label: 'Barne- og ungdomslitteratur',
            originalLabel: 'Barne- og ungdomslitteratur',
            alternativeLabels: ['barnelitteratur'],

            related: [],
            children: [
              {
                id: 'DSYC',
                label: 'Barne- og ungdomslitteratur: bokanmeldelser og guider',
                originalLabel:
                  'Barne- og ungdomslitteratur: bokanmeldelser og guider',
                alternativeLabels: ['barnelitteratur'],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'F',
    label: 'Skjønnlitteratur og relaterte temaer',
    originalLabel: 'Skjønnlitteratur og relaterte temaer',
    alternativeLabels: [],
    note: 'BRUK IKKE ‘F’ aleine, men velg spesifikke kategorier fra F*. F*-koder kan brukes sammen med andre emnekategorier og kvalifikatorer der det er hensiktsmessig, spesielt kvalifikatorer for sted og tidsperiode der disse aspektene er særskilt relevante og viktige. NB! F*-koder kan brukes med passende emnekoder utenfor F* for å angi viktige temaer i fortellingene, men for all voksenlitteratur må hovedkategorien være en F*-kode. FX*-koder kan brukes for å angi et viktig, underliggende tema. Brukes også med kvalifikatorer fra 5H*, 5J*, 5L* eller 5P* der disse representerer et viktig emne eller del av narrativet. FY*-koder kan brukes til å angi spesielle egenskaper ved en skjønnlitterær utgivelse',
    related: [],
    children: [
      {
        id: 'FB',
        label: 'Skjønnlitteratur',
        originalLabel: 'Skjønnlitteratur',
        alternativeLabels: [],
        note: 'Bruk FB bare på skjønnlitteratur der sjanger og tid er ukjent, eller der ‘klassisk’ eller ‘moderne’ ikke passer. FB*-koder kan brukes med FX*- eller FY*-koder for å gi mer informasjon om temaer eller egenskaper der det er hensiktsmessig. FB*-koder kan brukes som hovedkategori sammen med koder for litterære sjangre i tilfeller der en roman anses som ‘seriøs’ og å ha høyere litterær verdi utover å være ren sjangerfiksjon. Foretrekk FBA eller FBC der det er mulig, og foretrekk en kode for en litterær sjanger som hovedkategori der dette er mer passende',
        related: [],
        children: [
          {
            id: 'FBA',
            label: 'Moderne litteratur',
            originalLabel: 'Moderne litteratur',
            alternativeLabels: [],
            note: 'Her: skjønnlitteratur som ikke hører til en spesiell sjanger. Vanligvis utgitt i løpet av de siste tiårene. Brukes med FX* eller FY*-koder for mer detaljert beskrivelse der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'FBAN',
                label: 'Urban skjønnlitteratur',
                originalLabel: 'Urban skjønnlitteratur',
                alternativeLabels: ['street lit', 'street fiction'],
                note: 'Her: gatelitteratur. Brukes for skjønnlitteratur som vanligvis foregår i urbane bymiljøer. Fortellingene har ofte en mørk undertone og fokuserer på bylivets bakside',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'FBC',
            label: 'Klassisk litteratur',
            originalLabel: 'Klassisk litteratur',
            alternativeLabels: [],
            note: 'Her: skjønnlitteratur som ikke hører til en spesiell sjanger, og som er ansett eller presentert som ‘klassisk’. Vanligvis, men ikke nødvendigvis utgitt for flere tiår siden. Foretrekk kodene for klassisk litteratur innenfor de ulike sjangrene der de er tilgjengelig. Se også DBS* <a href="/thema/nb/DBS">Sagaer og epos</a> og dens underkategorier, FFC <a href="/thema/nb/FFC">Klassisk krim</a>, FLC <a href="/thema/nb/FLC">Klassisk science fiction</a>',
            related: [
              {
                id: 'DBS',
                label: 'Sagaer og epos',
                originalLabel: 'Sagaer og epos',
                note: 'Brukes for antikken og middelalderens sagaer og epos, enten på originalspråket, en annotert versjon, eller i moderne oversettelse. Foretrekk DC* pluss 6EH for nyere episk diktning. Se også FT <a href="/thema/nb/FT">Slektsromaner</a>, FN <a href="/thema/nb/FN">Tradisjonelle fortellinger, myter og eventyr</a>',
              },
              {
                id: 'FFC',
                label: 'Klassisk krim',
                originalLabel: 'Klassisk krim',
                note: 'Her: krim som er ansett som eller presenteres som ‘klassisk’ og som vanligvis, men ikke nødvendigvis er utgitt for flere tiår siden',
              },
              {
                id: 'FLC',
                label: 'Klassisk science fiction',
                originalLabel: 'Klassisk science fiction',
                note: 'Her: science fiction som er ansett som eller presenteres som ‘klassisk’, og som vanligvis er utgitt for flere tiår siden',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'FC',
        label: 'Biografisk skjønnlitteratur',
        originalLabel: 'Biografisk skjønnlitteratur',
        alternativeLabels: [],
        note: 'Her: skjønnlitterære livsfortellinger, selvbiografiske fortellinger, faksjon. Brukes for høyt fiksjonaliserte beretninger om livene til virkelige mennesker (inkludert fiksjonaliserte selvbiografier), eller hvor en forfatter bruker hendelser eller erfaringer fra sitt eget liv som en integrert del av narrativet eller historien. Kan brukes med FV der det er aktuelt. Se også DN* <a href="/thema/nb/DN">Biografier og sanne fortellinger</a> og dens underkategorier',
        related: [
          {
            id: 'DN',
            label: 'Biografier og sanne fortellinger',
            originalLabel: 'Biografier og sanne fortellinger',
            note: 'Merk: ved bruk av DN*-koder, tildel også spesifikke emnekoder for å indikere område der det er mulig, f.eks. SFH «Golf» med DNBS eller JW* «Krigføring og forsvar» med DNP for reportasjer fra militære konflikter, hvis hensiktsmessig. Se også FC <a href="/thema/nb/FC">Biografisk skjønnlitteratur</a>',
          },
        ],
        children: [],
      },
      {
        id: 'FD',
        label: 'Spekulativ skjønnlitteratur',
        originalLabel: 'Spekulativ skjønnlitteratur',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'FDB',
            label: 'Dystopier og utopier',
            originalLabel: 'Dystopier og utopier',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur der handlingen foregår i et ideelt, dysfunksjonelt eller degradert samfunn. Brukes sammen med FL*- eller andre F*-koder der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'FDK',
            label: 'Kontrafaktisk litteratur',
            originalLabel: 'Kontrafaktisk litteratur',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur som ikke er fantasy eller science fiction, og som foregår i en tenkt verden som kunne ha eksistert dersom viktige historiske hendelser hadde fått et annet utfall. F.eks. utfallet av 2. verdenskrig',
            related: [],
            children: [],
          },
          {
            id: 'FDM',
            label: 'Afrofuturisme',
            originalLabel: 'Afrofuturisme',
            alternativeLabels: [],
            note: 'Brukes med FL*-, FK*- eller FM*-koder der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'FDV',
            label: 'Metafysisk / filosofisk litteratur',
            originalLabel: 'Metafysisk / filosofisk litteratur',
            alternativeLabels: [],
            note: 'Brukes for litteratur som utforsker, eller er basert på, en eller annen form for filosofi eler tankesystem, skjønnlitteratur der en betydelig del av innholdet er viet til filosofiske spørsmål',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'FF',
        label: 'Krim og mysterier',
        originalLabel: 'Krim og mysterier',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'FFC',
            label: 'Klassisk krim',
            originalLabel: 'Klassisk krim',
            alternativeLabels: [],
            note: 'Her: krim som er ansett som eller presenteres som ‘klassisk’ og som vanligvis, men ikke nødvendigvis er utgitt for flere tiår siden',
            related: [],
            children: [],
          },
          {
            id: 'FFD',
            label: 'Krim: privatdetektiver / amatørdetektiver',
            originalLabel: 'Krim: privatdetektiver / amatørdetektiver',
            alternativeLabels: ['detektiver'],
            note: 'Her: journalister eller andre som løser en krimgåte',
            related: [],
            children: [],
          },
          {
            id: 'FFH',
            label: 'Historisk krim',
            originalLabel: 'Historisk krim',
            alternativeLabels: [],
            note: 'Brukes for moderne krim hvor handlingen er lagt til tidligere tider, for eksempel i viktoriatiden, middelalderen, romertiden. Bruk kvalifikatorer for sted og/eller tidsperiode for å indikere hvor og/eller når handlingen foregår',
            related: [],
            children: [],
          },
          {
            id: 'FFJ',
            label: 'Krim: kosekrim',
            originalLabel: 'Krim: kosekrim',
            alternativeLabels: [],
            note: 'Brukes for krim og mysterier der handlingen er lagt til bygdesamfunn eller tradisjonelle samfunn med lite vold, og som oftest med amatørdetektiver',
            related: [],
            children: [],
          },
          {
            id: 'FFK',
            label: 'Humoristisk krim',
            originalLabel: 'Humoristisk krim',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'FFL',
            label: 'Krim: hardkokt krim',
            originalLabel: 'Krim: hardkokt krim',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'FFP',
            label: 'Krim: politi',
            originalLabel: 'Krim: politi',
            alternativeLabels: [],
            note: 'Her: politietterforskning, rettsmedisinsk etterforskning',
            related: [],
            children: [],
          },
          {
            id: 'FFS',
            label: 'Krim med kvinnelige hovedpersoner',
            originalLabel: 'Krim med kvinnelige hovedpersoner',
            alternativeLabels: ['kvinnelige etterforskere'],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'FG',
        label: 'Skjønnlitteratur med sportslig innhold',
        originalLabel: 'Skjønnlitteratur med sportslig innhold',
        alternativeLabels: [],
        note: 'S*-koder kan legges til etter FG for å indikere den spesifikke idretten som er omtalt',
        related: [],
        children: [],
      },
      {
        id: 'FH',
        label: 'Thrillere',
        originalLabel: 'Thrillere',
        alternativeLabels: [],
        note: 'Her: konspirasjonsthrillere, katastrofethrillere, økothrillere. Brukes med andre F*-koder hvis hensiktsmessig',
        related: [],
        children: [
          {
            id: 'FHD',
            label: 'Thrillere: agenter og spioner',
            originalLabel: 'Thrillere: agenter og spioner',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'FHK',
            label: 'Teknothrillere',
            originalLabel: 'Teknothrillere',
            alternativeLabels: [],
            note: 'Brukes for thrillere som inneholder vitenskapelige, teknologiske (inkludert databehandling) osv. elementer som viktige deler av fortellingen',
            related: [],
            children: [],
          },
          {
            id: 'FHM',
            label: 'Medisinske thrillere',
            originalLabel: 'Medisinske thrillere',
            alternativeLabels: [],
            note: 'Brukes for thrillere der medisiner, forskning og annet som brukes til medisinske formål blir et våpen eller en trussel, f. eks. et virus, ofte med leger som hovedkarakterer',
            related: [],
            children: [],
          },
          {
            id: 'FHP',
            label: 'Politiske og juridiske thrillere',
            originalLabel: 'Politiske og juridiske thrillere',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'FHQ',
            label: 'Okkulte thrillere',
            originalLabel: 'Okkulte thrillere',
            alternativeLabels: ['konspirasjoner'],
            note: 'Brukes for thrillere hvor handlingen tar for seg gamle ritualer, hemmelig eller esoterisk kunnskap, som for eksempel den hellige gral etc.',
            related: [],
            children: [],
          },
          {
            id: 'FHR',
            label: 'Thrillere: organisert kriminalitet',
            originalLabel: 'Thrillere: organisert kriminalitet',
            alternativeLabels: [],
            note: 'Brukes for thrillere som handler om kriminelle organisasjoner, den kriminelle underverden, mafia, kriminelle syndikater, kriminelle gjenger, etc.',
            related: [],
            children: [],
          },
          {
            id: 'FHS',
            label: 'Thrillere: seriemordere',
            originalLabel: 'Thrillere: seriemordere',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'FHT',
            label: 'Thrillere: terrorisme',
            originalLabel: 'Thrillere: terrorisme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'FHX',
            label: 'Psykologiske thrillere',
            originalLabel: 'Psykologiske thrillere',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'FJ',
        label: 'Spennende og actionfylte fortellinger',
        originalLabel: 'Spennende og actionfylte fortellinger',
        alternativeLabels: ['spenning', 'spenningslitteratur'],
        note: 'Brukes for spennende skjønnlitteratur som ikke er krim og som ofte framkaller en følelse av fare og som er ment å gi leseren en følelse av spenning og action. Se også FH <a href="/thema/nb/FH">Thrillere</a>',
        related: [
          {
            id: 'FH',
            label: 'Thrillere',
            originalLabel: 'Thrillere',
            note: 'Her: konspirasjonsthrillere, katastrofethrillere, økothrillere. Brukes med andre F*-koder hvis hensiktsmessig',
          },
        ],
        children: [
          {
            id: 'FJD',
            label: 'Kampkunstfortellinger',
            originalLabel: 'Kampkunstfortellinger',
            alternativeLabels: [],
            note: 'Her: wuxia',
            related: [],
            children: [],
          },
          {
            id: 'FJH',
            label: 'Historiske spennende og actionfylte fortellinger',
            originalLabel: 'Historiske spennende og actionfylte fortellinger',
            alternativeLabels: [],
            note: 'Brukes for spennende skjønnlitteratur som ikke er krim der handlingen er lagt til eldre tider. Foretrekk FJM*-kode der dette er aktuelt. Bruk kvalifikatorer for sted og/eller tidsperiode for å indikere hvor og/eller når handlingen foregår',
            related: [],
            children: [],
          },
          {
            id: 'FJM',
            label: 'Krigsfortellinger',
            originalLabel: 'Krigsfortellinger',
            alternativeLabels: [],
            note: 'Her: fortellinger om spesialsstyrker. Brukes for spennende og actionfylt skjønnlitteratur om krig, kamper og militæret, men foretrekk andre F*-koder og kvalifikatorer for tidsperiode for bøker hvor krig eller konflikter er en del av bakgrunnshistorien, eller som ser på hvilke innvirkninger krig har på samfunnet, og hvor hovedtemaet ikke er spennende eller actionfylt',
            related: [],
            children: [
              {
                id: 'FJMC',
                label: 'Krigsfortellinger: napoleonskrigen',
                originalLabel: 'Krigsfortellinger: napoleonskrigen',
                alternativeLabels: [],
                note: 'Her: fortellinger der handlingen er lagt til Den franske revolusjon, koalisjonskrigene, Den spanske uavhengighetskrigen, Den britisk-amerikanske krig eller andre tilknyttede kriger fra samme periode. Brukes med kvalifikatorer for å angi sted og tid der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'FJMF',
                label: 'Krigsfortellinger: første verdenskrig',
                originalLabel: 'Krigsfortellinger: første verdenskrig',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'FJMS',
                label: 'Krigsfortellinger: andre verdenskrig',
                originalLabel: 'Krigsfortellinger: andre verdenskrig',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'FJMV',
                label: 'Krigsfortellinger: Vietnamkrigen',
                originalLabel: 'Krigsfortellinger: Vietnamkrigen',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'FJN',
            label: 'Sjøfortellinger',
            originalLabel: 'Sjøfortellinger',
            alternativeLabels: [
              'pirater',
              'sjørøvere',
              'sjømenn',
              'marinen',
              'matroser',
            ],
            note: 'Her: spennende og actionfylt skjønnlitteratur om livet på havet / sjølivet',
            related: [],
            children: [],
          },
          {
            id: 'FJW',
            label: 'Western',
            originalLabel: 'Western',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'FK',
        label: 'Grøssere',
        originalLabel: 'Grøssere',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'FKC',
            label: 'Klassiske grøssere og spøkelseshistorier',
            originalLabel: 'Klassiske grøssere og spøkelseshistorier',
            alternativeLabels: [],
            note: 'Her: klassiske, gotiske grøssere. Brukes for grøssere og spøkelseshistorier som er ansett som eller som presenteres som ‘klassiske’ og som vanligvis, men ikke nødvendigvis er utgitt for flere tiår siden',
            related: [],
            children: [],
          },
          {
            id: 'FKM',
            label: 'Moderne grøssere og spøkelseshistorier',
            originalLabel: 'Moderne grøssere og spøkelseshistorier',
            alternativeLabels: [],
            note: 'Brukes for grøssere som foregår i nåtiden eller samtiden, og som vanligvis er utgitt i løpet av de siste tiårene',
            related: [],
            children: [],
          },
          {
            id: 'FKW',
            label: 'Okkulte romaner',
            originalLabel: 'Okkulte romaner',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'FL',
        label: 'Science fiction',
        originalLabel: 'Science fiction',
        alternativeLabels: [],
        note: 'Se også FD <a href="/thema/nb/FD">Spekulativ skjønnlitteratur</a>',
        related: [
          {
            id: 'FD',
            label: 'Spekulativ skjønnlitteratur',
            originalLabel: 'Spekulativ skjønnlitteratur',
          },
        ],
        children: [
          {
            id: 'FLC',
            label: 'Klassisk science fiction',
            originalLabel: 'Klassisk science fiction',
            alternativeLabels: [],
            note: 'Her: science fiction som er ansett som eller presenteres som ‘klassisk’, og som vanligvis er utgitt for flere tiår siden',
            related: [],
            children: [],
          },
          {
            id: 'FLG',
            label: 'Tidsreiser',
            originalLabel: 'Science fiction: tidsreiser',
            alternativeLabels: [],
            note: 'Her: fortellinger om tidsreiser til fortid eller framtid via tidsmaskiner, tidssløyfer, tidsglidninger eller tidsportaler, utilsiktede tidsreiser, etc. Brukes med: FM*-koder for fantasy som handler om tidsreiser',
            related: [],
            children: [],
          },
          {
            id: 'FLH',
            label: 'Hard science fiction',
            originalLabel: 'Hard science fiction',
            alternativeLabels: [],
            note: 'Brukes for science fiction som legger vekt på vitenskapelige detaljer, som holder seg innenfor rammen av hva som er vitenskapelig mulig, eller som ikke går utenfor universets kjente fysiske lover',
            related: [],
            children: [],
          },
          {
            id: 'FLM',
            label: 'Steampunk',
            originalLabel: 'Science fiction: steampunk',
            alternativeLabels: [],
            note: 'Her: dieselpunk, retrofuturisme. Brukes for science fiction som er basert på en alternativ verden hvor dampmaskiner og annen teknikk fra 1800-tallet har blitt den dominerende teknologien',
            related: [],
            children: [],
          },
          {
            id: 'FLP',
            label: 'Science fiction: nær fremtid',
            originalLabel: 'Science fiction: nær fremtid',
            alternativeLabels: [
              'kunstig intelligens',
              'AI',
              'kyborger',
              'roboter',
            ],
            note: 'Brukes for science fiction lagt til en svært nær framtid, i en verden svært lik vår egen, som ofte inneholder nåværende teknologi eller teknologi i utvikling og dens effekt på verden, f. eks. avansert høyteknologi, kunstig intelligens, roboter, kyborger, transhumanisme, teknologisk singularitet',
            related: [],
            children: [
              {
                id: 'FLPB',
                label: 'Cyberpunk',
                originalLabel: 'Science fiction: cyberpunk / biopunk',
                alternativeLabels: [],
                note: 'Brukes for science fiction som handler om marginaliserte personer som lever i et høyteknologisk, avansert samfunn, eller som fokuserer på konsekvensene av bioteknologi eller genteknologi',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'FLQ',
            label: 'Apokalyptisk / postapokalyptisk science fiction',
            originalLabel: 'Science fiction: apokalyptisk og postapokalyptisk',
            alternativeLabels: ['apokalypser'],
            note: 'Her: endetidshistorier. Brukes for bøker som foregår i verdener som prøver å forhindre eller som allerede har blitt påvirket av en apokalyptisk hendelse, f.eks. klimaendringer, ressursutarming, atomkrig, pandemier, kybernetisk opprør, invasjon fra verdensrommet, etc.',
            related: [],
            children: [],
          },
          {
            id: 'FLR',
            label: 'Militær science fiction',
            originalLabel: 'Science fiction: militæret',
            alternativeLabels: [],
            note: 'Brukes for science fiction der handlingen er lagt til militæret eller der organisert bruk av våpen er sentralt i handlingen. Se også FLS <a href="/thema/nb/FLS">Science fiction: romopera</a>',
            related: [
              {
                id: 'FLS',
                label: 'Romopera',
                originalLabel: 'Science fiction: romopera',
                note: 'Her: galaktiske imperier, planetarisk romantikk. Brukes for science fiction der handlingen er lagt til rommet, vanligvis med avanserte kulturer og teknologi',
              },
            ],
            children: [],
          },
          {
            id: 'FLS',
            label: 'Romopera',
            originalLabel: 'Science fiction: romopera',
            alternativeLabels: [],
            note: 'Her: galaktiske imperier, planetarisk romantikk. Brukes for science fiction der handlingen er lagt til rommet, vanligvis med avanserte kulturer og teknologi',
            related: [],
            children: [],
          },
          {
            id: 'FLU',
            label: 'Science fiction: aliens / UFO-er',
            originalLabel: 'Science fiction: aliens / UFO-er',
            alternativeLabels: [],
            note: 'Her: invasjon av romvesener, menneskelig interaksjon med utenomjordiske livsformer, konspirasjoner om UFO-er',
            related: [],
            children: [],
          },
          {
            id: 'FLW',
            label: 'Science fiction: utforsking av verdensrommet',
            originalLabel: 'Science fiction: utforsking av verdensrommet',
            alternativeLabels: [],
            note: 'Her: kolonisering av verdensrommet, terraforming, månebaser, astronauter',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'FM',
        label: 'Fantasy',
        originalLabel: 'Fantasy',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'FMB',
            label: 'Episk / heroisk fantasy',
            originalLabel: 'Episk / heroisk fantasy',
            alternativeLabels: ['mythopoeia', 'mitopoeia', 'myte-skaping'],
            note: 'Her: høyfantasy (high fantasy)',
            related: [],
            children: [],
          },
          {
            id: 'FMH',
            label: 'Historisk fantasy',
            originalLabel: 'Historisk fantasy',
            alternativeLabels: [],
            note: 'Brukes for fantasy som innlemmer fantastiske elementer, som magi eller elementer fra myter, i gjenkjennelige historiske omgivelser, perioder, kulturer osv. Brukes med kvalifikatorer for sted og/ eller tidsperiode der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'FMK',
            label: 'Humoristisk fantasy',
            originalLabel: 'Humoristisk fantasy',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'FMM',
            label: 'Magisk realisme',
            originalLabel: 'Magisk realisme',
            alternativeLabels: [],
            note: 'Brukes for bøker som innlemmer fantastiske, magiske, mytiske eller overnaturlige elementer i omgivelser som ellers er vanlige eller dagligdagse. Brukes med FBA eller FBC for litterære verk som innlemmer magisk realisme, f.eks. latinamerikanske utgivelser',
            related: [],
            children: [],
          },
          {
            id: 'FMR',
            label: 'Romantisk fantasy',
            originalLabel: 'Romantisk fantasy',
            alternativeLabels: [],
            note: 'Brukes for fantasy som også har mange av sjangertrekkene fra romantikksjangeren, men som likevel først og fremst gjenkjennes som fantasy. Se også FRV <a href="/thema/nb/FRV">Romantikk: dyster stemning</a>, FRT <a href="/thema/nb/FRT">Romantikk: fantasy og paranormal</a>',
            related: [
              {
                id: 'FRT',
                label: 'Romantikk: fantasy og paranormal',
                originalLabel: 'Romantikk: fantasy og paranormal',
                note: 'Her: romantikk med vampyrer, varulver. Brukes for romantisk skjønnlitteratur som blander inn elementer fra fantasysjangeren eller som handler om mytiske vesener eller overnaturlige elementer, men som først og fremst kategoriseres som romantikk. Brukes med FM*-koder der det er hensiktsmessig',
              },
              {
                id: 'FRV',
                label: 'Romantikk: dyster stemning',
                originalLabel: 'Romantikk: dyster stemning',
                note: 'Brukes for romantikk hvor det kan være dystrere/mørkere stemning og som ofte tar for seg ondskap eller frykt, og som vanligvis inkluderer en antihelt og/eller besværlige situasjoner',
              },
            ],
            children: [],
          },
          {
            id: 'FMS',
            label: 'Superhelter',
            originalLabel: 'Superhelter',
            alternativeLabels: [],
            note: 'Brukes for fortellinger hvor hovedpersonen er en superhelt eller oppfattes som en slik',
            related: [],
            children: [],
          },
          {
            id: 'FMT',
            label: 'Dark fantasy',
            originalLabel: 'Dark fantasy',
            alternativeLabels: ['gotiske romaner'],
            note: 'Brukes for mørk fantasy, dvs. fantasy med mørkere og skremmende temaer. Inkluderer ofte elementer fra grøssere eller har en dyster og truende atmosfære, eller man får en følelse av frykt',
            related: [],
            children: [],
          },
          {
            id: 'FMW',
            label: 'Moderne fantasy',
            originalLabel: 'Moderne fantasy',
            alternativeLabels: [],
            note: 'Brukes for fantasy som foregår i nåtiden eller samtiden',
            related: [],
            children: [],
          },
          {
            id: 'FMX',
            label: 'Urban fantasy',
            originalLabel: 'Urban fantasy',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'FN',
        label: 'Tradisjonelle fortellinger, myter og eventyr',
        originalLabel: 'Tradisjonelle fortellinger, myter og eventyr',
        alternativeLabels: [],
        note: 'Her: tradisjonelle lokale folkeeventyr, skrøner, fabler, eller enhver form for tradisjonell historie fortalt eller gjenfortalt som fiksjon. Brukes for moderne gjenfortellinger i romanform eller samlinger av tradisjonelle fortellinger, først og fremst ment for voksne, eller skjønnlitterære bøker som er inspirert av disse tradisjonelle fortellingene eller av folklore. Se også DBS* <a href="/thema/nb/DBS">Sagaer og epos</a> og dens underkategorier, QRS* <a href="/thema/nb/QRS">Oldtidens religioner og mytologier</a> og dens underkategorier, JBGB <a href="/thema/nb/JBGB">Folklore, myter og legender</a>, YFJ <a href="/thema/nb/YFJ">Skjønnlitteratur for barn og ungdom: tradisjonelle historier</a>, YNRU <a href="/thema/nb/YNRU">Barn og ungdom: oldtidens religioner, mytologi og legender</a>',
        related: [
          {
            id: 'DBS',
            label: 'Sagaer og epos',
            originalLabel: 'Sagaer og epos',
            note: 'Brukes for antikken og middelalderens sagaer og epos, enten på originalspråket, en annotert versjon, eller i moderne oversettelse. Foretrekk DC* pluss 6EH for nyere episk diktning. Se også FT <a href="/thema/nb/FT">Slektsromaner</a>, FN <a href="/thema/nb/FN">Tradisjonelle fortellinger, myter og eventyr</a>',
          },
          {
            id: 'JBGB',
            label: 'Folklore, myter og legender',
            originalLabel: 'Folklore, myter og legender',
            note: 'Brukes for faglitteratur som tar for seg tradisjonelle fortellinger eller muntlig overbrakt folklore; folkloristikk, studier av folkereligioner og tradisjoner, studier av urbane og nyere myter og legender, myters opprinnelse, eventyr, løgnhistorier, overnaturlige fortellinger, overnaturlige vesener, overtro etc., utgivelser som tar for seg opprinnelsen til fortellinger om Atlantis, den hellige gral, Bermudatriangelet etc. Foretrekk QR*-koder for spirituelle eller religiøse aspekter ved mytologi eller VX*-koder for populærvitenskapelig tilnærming eller FN*-koder for selve historiene. Se også FN <a href="/thema/nb/FN">Tradisjonelle fortellinger, myter og eventyr</a>, QRS* <a href="/thema/nb/QRS">Oldtidens religioner og mytologier</a> og dens underkategorier, VXQM* <a href="/thema/nb/VXQM">Mytiske / overnaturlige vesen</a> og dens underkategorier',
          },
          {
            id: 'QRS',
            label: 'Oldtidens religioner og mytologier',
            originalLabel: 'Oldtidens religioner og mytologier',
            note: 'Her: paganisme, komparativ mytologi, generelle studier av mytologi og studier av den enkelte, regionale mytologi hvor denne ikke har sin egen kode. Bruk QRS*-koder for bøker som tar for seg disse formene for spirituell tro, deres mytologier, gudshus, religionsutøvelse, guder og ånder, skapelsesberetninger og andre fortellinger, legender, ritualer etc. Bruk QRS*-koder med kvalifikatorer for sted, spesielt 1QB* for historiske land og riker, eller med andre OR*-koder for sammenligning med nyere religioner. Brukes med FN*-koder for moderne gjenfortellinger av tradisjonelle myter fra disse religionene. Se også FN <a href="/thema/nb/FN">Tradisjonelle fortellinger, myter og eventyr</a>, JBGB <a href="/thema/nb/JBGB">Folklore, myter og legender</a>',
          },
          {
            id: 'YFJ',
            label: 'Tradisjonelle historier',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: tradisjonelle historier',
            note: 'Her: eventyr, folkeeventyr, myter, fabler, sagn og legender fortalt som fiksjon. Bøker om troll, nisser etc. Se også YFHG <a href="/thema/nb/YFHG">Skjønnlitteratur for barn og ungdom: mytisk fantasy</a>',
          },
          {
            id: 'YNRU',
            label: 'Oldtidens religioner, mytologier og legender',
            originalLabel:
              'Barn og ungdom: oldtidens religioner, mytologi og legender',
            note: 'Brukes for bøker om eller samlinger av myter og legender fra bl.a. gresk, romersk, norrøn, egyptisk, keltisk, aztekisk, afrikansk, asiatisk, amerikansk religion og mytologi. Brukes med YBC*, YBD, YFH* eller YFJ for skjønnlitteratur basert på myter eller gjenfortelling av de originale historiene, og med kvalifikatorer for sted, språk og interesse der det er hensiktsmessig. Se også YFJ <a href="/thema/nb/YFJ">Skjønnlitteratur for barn og ungdom: tradisjonelle historier</a>, YFHG <a href="/thema/nb/YFHG">Skjønnlitteratur for barn og ungdom: mytisk fantasy</a>, YNXB <a href="/thema/nb/YNXB">Barn og ungdom: overnaturlige og mytologiske vesener</a>',
          },
        ],
        children: [
          {
            id: 'FNF',
            label: 'Folkeeventyr og kunsteventyr',
            originalLabel: 'Folkeeventyr og kunsteventyr',
            alternativeLabels: [],
            note: 'Her: folkeeventyr og andre eventyr fra ulike kulturer i fortellende form. Brukes for moderne gjenfortellinger i romanform eller samlinger av denne typen tradisjonelle fortellinger, rettet primært mot voksne, eller fortellinger inspirert av tradisjonelle eventyr, f. eks. i tilfeller der forfatteren lager en ny fortelling basert på et populært eventyr, eller benytter seg av karakterer, troper, temaer og symbolikk kjent fra eventyr og folklore. Se også JBGB <a href="/thema/nb/JBGB">Folklore, myter og legender</a>, YFJ <a href="/thema/nb/YFJ">Skjønnlitteratur for barn og ungdom: tradisjonelle historier</a>, YNXB4 <a href="/thema/nb/YNXB4">Barn og ungdom: feer og alver</a>',
            related: [
              {
                id: 'JBGB',
                label: 'Folklore, myter og legender',
                originalLabel: 'Folklore, myter og legender',
                note: 'Brukes for faglitteratur som tar for seg tradisjonelle fortellinger eller muntlig overbrakt folklore; folkloristikk, studier av folkereligioner og tradisjoner, studier av urbane og nyere myter og legender, myters opprinnelse, eventyr, løgnhistorier, overnaturlige fortellinger, overnaturlige vesener, overtro etc., utgivelser som tar for seg opprinnelsen til fortellinger om Atlantis, den hellige gral, Bermudatriangelet etc. Foretrekk QR*-koder for spirituelle eller religiøse aspekter ved mytologi eller VX*-koder for populærvitenskapelig tilnærming eller FN*-koder for selve historiene. Se også FN <a href="/thema/nb/FN">Tradisjonelle fortellinger, myter og eventyr</a>, QRS* <a href="/thema/nb/QRS">Oldtidens religioner og mytologier</a> og dens underkategorier, VXQM* <a href="/thema/nb/VXQM">Mytiske / overnaturlige vesen</a> og dens underkategorier',
              },
              {
                id: 'YFJ',
                label: 'Tradisjonelle historier',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: tradisjonelle historier',
                note: 'Her: eventyr, folkeeventyr, myter, fabler, sagn og legender fortalt som fiksjon. Bøker om troll, nisser etc. Se også YFHG <a href="/thema/nb/YFHG">Skjønnlitteratur for barn og ungdom: mytisk fantasy</a>',
              },
              {
                id: 'YNXB4',
                label: 'Feer og alver',
                originalLabel: 'Barn og ungdom: feer og alver',
              },
            ],
            children: [],
          },
          {
            id: 'FNM',
            label: 'Myter og legender',
            originalLabel: 'Myter og legender',
            alternativeLabels: [],
            note: 'Her: myter og legender fra ulike kulturer i fortellende form. Brukes for moderne gjenfortellinger i romanform eller samlinger av denne typen tradisjonelle fortellinger, rettet primært mot voksne, eller fortellinger som er inspirert av tradisjonelle myter, f. eks. i tilfeller der forfatteren lager en ny fortelling basert på en myte, eller benytter seg av karakterer, troper, temaer og symbolikk kjent fra myter og legender. Brukes med QRS*- eller QRR*-koder for å angi hvilken tradisjon de stammer fra, der det er hensiktsmessig. Se også DBS* <a href="/thema/nb/DBS">Sagaer og epos</a> og dens underkategorier, JBGB <a href="/thema/nb/JBGB">Folklore, myter og legender</a>, QRS* <a href="/thema/nb/QRS">Oldtidens religioner og mytologier</a> og dens underkategorier, YFJ <a href="/thema/nb/YFJ">Skjønnlitteratur for barn og ungdom: tradisjonelle historier</a>, YNRU <a href="/thema/nb/YNRU">Barn og ungdom: oldtidens religioner, mytologi og legender</a>',
            related: [
              {
                id: 'DBS',
                label: 'Sagaer og epos',
                originalLabel: 'Sagaer og epos',
                note: 'Brukes for antikken og middelalderens sagaer og epos, enten på originalspråket, en annotert versjon, eller i moderne oversettelse. Foretrekk DC* pluss 6EH for nyere episk diktning. Se også FT <a href="/thema/nb/FT">Slektsromaner</a>, FN <a href="/thema/nb/FN">Tradisjonelle fortellinger, myter og eventyr</a>',
              },
              {
                id: 'JBGB',
                label: 'Folklore, myter og legender',
                originalLabel: 'Folklore, myter og legender',
                note: 'Brukes for faglitteratur som tar for seg tradisjonelle fortellinger eller muntlig overbrakt folklore; folkloristikk, studier av folkereligioner og tradisjoner, studier av urbane og nyere myter og legender, myters opprinnelse, eventyr, løgnhistorier, overnaturlige fortellinger, overnaturlige vesener, overtro etc., utgivelser som tar for seg opprinnelsen til fortellinger om Atlantis, den hellige gral, Bermudatriangelet etc. Foretrekk QR*-koder for spirituelle eller religiøse aspekter ved mytologi eller VX*-koder for populærvitenskapelig tilnærming eller FN*-koder for selve historiene. Se også FN <a href="/thema/nb/FN">Tradisjonelle fortellinger, myter og eventyr</a>, QRS* <a href="/thema/nb/QRS">Oldtidens religioner og mytologier</a> og dens underkategorier, VXQM* <a href="/thema/nb/VXQM">Mytiske / overnaturlige vesen</a> og dens underkategorier',
              },
              {
                id: 'QRS',
                label: 'Oldtidens religioner og mytologier',
                originalLabel: 'Oldtidens religioner og mytologier',
                note: 'Her: paganisme, komparativ mytologi, generelle studier av mytologi og studier av den enkelte, regionale mytologi hvor denne ikke har sin egen kode. Bruk QRS*-koder for bøker som tar for seg disse formene for spirituell tro, deres mytologier, gudshus, religionsutøvelse, guder og ånder, skapelsesberetninger og andre fortellinger, legender, ritualer etc. Bruk QRS*-koder med kvalifikatorer for sted, spesielt 1QB* for historiske land og riker, eller med andre OR*-koder for sammenligning med nyere religioner. Brukes med FN*-koder for moderne gjenfortellinger av tradisjonelle myter fra disse religionene. Se også FN <a href="/thema/nb/FN">Tradisjonelle fortellinger, myter og eventyr</a>, JBGB <a href="/thema/nb/JBGB">Folklore, myter og legender</a>',
              },
              {
                id: 'YFJ',
                label: 'Tradisjonelle historier',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: tradisjonelle historier',
                note: 'Her: eventyr, folkeeventyr, myter, fabler, sagn og legender fortalt som fiksjon. Bøker om troll, nisser etc. Se også YFHG <a href="/thema/nb/YFHG">Skjønnlitteratur for barn og ungdom: mytisk fantasy</a>',
              },
              {
                id: 'YNRU',
                label: 'Oldtidens religioner, mytologier og legender',
                originalLabel:
                  'Barn og ungdom: oldtidens religioner, mytologi og legender',
                note: 'Brukes for bøker om eller samlinger av myter og legender fra bl.a. gresk, romersk, norrøn, egyptisk, keltisk, aztekisk, afrikansk, asiatisk, amerikansk religion og mytologi. Brukes med YBC*, YBD, YFH* eller YFJ for skjønnlitteratur basert på myter eller gjenfortelling av de originale historiene, og med kvalifikatorer for sted, språk og interesse der det er hensiktsmessig. Se også YFJ <a href="/thema/nb/YFJ">Skjønnlitteratur for barn og ungdom: tradisjonelle historier</a>, YFHG <a href="/thema/nb/YFHG">Skjønnlitteratur for barn og ungdom: mytisk fantasy</a>, YNXB <a href="/thema/nb/YNXB">Barn og ungdom: overnaturlige og mytologiske vesener</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'FP',
        label: 'Erotikk',
        originalLabel: 'Erotikk',
        alternativeLabels: [],
        note: 'Brukes for erotisk skjønnlitteratur med eksplisitte skildringer av forhold av seksuell karakter, og hvor hovedpersonene ikke nødvendigvis har et romantisk forhold. Brukes sammen med kvalifikator 5X der det er hensiktsmessig. Skal IKKE brukes med FRX. Se også FRX <a href="/thema/nb/FRX">Erotisk romantikk</a>',
        related: [
          {
            id: 'FRX',
            label: 'Erotisk romantikk',
            originalLabel: 'Erotisk romantikk',
            note: 'Brukes for romantisk skjønnlitteratur med erotisk innhold, men som først og fremst er romantikk og som skildrer romantiske forhold. Skal IKKE brukes sammen med FP. Brukes sammen med kvalifikator 5X der det er hensiktsmessig. Se også FP <a href="/thema/nb/FP">Erotikk</a>',
          },
        ],
        children: [],
      },
      {
        id: 'FQ',
        label: 'Moderne underholdningsromaner',
        originalLabel: 'Moderne underholdningsromaner',
        alternativeLabels: ['chicklit', 'feelgood'],
        note: 'Her: bøker som skildrer en moderne livsstil, romantiske komedier, ‘feel good’. Brukes for skjønnlitteratur som vanligvis foregår i nåtiden eller samtiden, og som handler om realistiske, hverdagslige temaer eller livsstiler, vanligvis i en lett, og noen ganger oppløftende tone. Disse bøkene fokuserer ofte på vennskap, romantiske forhold, opp- og nedturer, hovedpersonens yrkesliv. For bøker med to tidsplan, kombiner med koder for historisk litteratur, f.eks. FRH HIstorisk romantikk',
        related: [],
        children: [],
      },
      {
        id: 'FR',
        label: 'Romantikk',
        originalLabel: 'Romantikk',
        alternativeLabels: ['kjærlighetshistorier'],

        related: [],
        children: [
          {
            id: 'FRD',
            label: 'Moderne romantikk',
            originalLabel: 'Moderne romantikk',
            alternativeLabels: [],
            note: 'Brukes for romantikk hvor hovedpersonene lever og arbeider i nåtiden eller samtiden, med moderne bekvemmeligheter og skikker',
            related: [],
            children: [],
          },
          {
            id: 'FRF',
            label: 'Romantikk: sedelig',
            originalLabel: 'Romantikk: sedelig',
            alternativeLabels: [],
            note: 'Her: koselige romantiske fortellinger. Brukes for romantikk som ikke inneholder skildringer av fysisk intimitet. Brukes med FW for å angi om utgivelsen er av religiøs karakter, og med 5PG* kvalifikator(er) for å indikere tilhørighet til en spesifikk religiøs gruppe, eller med andre FR*-koder der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'FRH',
            label: 'Historisk romantikk',
            originalLabel: 'Historisk romantikk',
            alternativeLabels: [],
            note: 'Brukes for romantisk skjønnlitteratur der handlingen er lagt til fortiden (vanligvis før forfatterens levetid). Tidsperioden er en viktig del av historien og den skildrer tidsånden når det gjelder skikker og sosiale forhold. Brukes med kvalifikatorer for sted og/eller tidsperiode der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'FRJ',
            label: 'Romantikk: livet på landsbygda',
            originalLabel: 'Romantikk: livet på landsbygda',
            alternativeLabels: ['cowboyer'],
            note: 'Her: romantisk western, bygdeliv, outback. Brukes for romantikk som foregår i svært landlige omgivelser, ofte på bondegårder eller i små bygdesamfunn, og som vanligvis ligger langt fra en by',
            related: [],
            children: [],
          },
          {
            id: 'FRM',
            label: 'Romantikk: spenning',
            originalLabel: 'Romantikk: spenning',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'FRP',
            label: 'Romantikk: uniform',
            originalLabel: 'Romantikk: uniform',
            alternativeLabels: [],
            note: 'Her: militære, brannkonstabler, politi, piloter, kabinpersonale etc. Brukes for romantikk hvor en eller flere av hovedpersonene har en jobb hvor man har uniform som arbeidsantrekk',
            related: [],
            children: [],
          },
          {
            id: 'FRQ',
            label: 'Legeromaner',
            originalLabel: 'Romantikk: legeromaner',
            alternativeLabels: ['sykepleiere'],
            note: 'Brukes for romantikk som handler om leger, sjukepleiere etc.',
            related: [],
            children: [],
          },
          {
            id: 'FRR',
            label: 'Romantikk: rike og berømte',
            originalLabel: 'Romantikk: rike og berømte',
            alternativeLabels: [],
            note: 'Brukes for romantikk som handler om rike og berømte personer i besittelse av makt, høy status o.l., som milliardærer, kongelige, aristokrater, kjendiser, rockestjerner, etc.',
            related: [],
            children: [],
          },
          {
            id: 'FRT',
            label: 'Romantikk: fantasy og paranormal',
            originalLabel: 'Romantikk: fantasy og paranormal',
            alternativeLabels: [],
            note: 'Her: romantikk med vampyrer, varulver. Brukes for romantisk skjønnlitteratur som blander inn elementer fra fantasysjangeren eller som handler om mytiske vesener eller overnaturlige elementer, men som først og fremst kategoriseres som romantikk. Brukes med FM*-koder der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'FRU',
            label: 'Romantikk: tidsreiser',
            originalLabel: 'Romantikk: tidsreiser',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'FRV',
            label: 'Romantikk: dyster stemning',
            originalLabel: 'Romantikk: dyster stemning',
            alternativeLabels: [],
            note: 'Brukes for romantikk hvor det kan være dystrere/mørkere stemning og som ofte tar for seg ondskap eller frykt, og som vanligvis inkluderer en antihelt og/eller besværlige situasjoner',
            related: [],
            children: [],
          },
          {
            id: 'FRX',
            label: 'Erotisk romantikk',
            originalLabel: 'Erotisk romantikk',
            alternativeLabels: [],
            note: 'Brukes for romantisk skjønnlitteratur med erotisk innhold, men som først og fremst er romantikk og som skildrer romantiske forhold. Skal IKKE brukes sammen med FP. Brukes sammen med kvalifikator 5X der det er hensiktsmessig. Se også FP <a href="/thema/nb/FP">Erotikk</a>',
            related: [
              {
                id: 'FP',
                label: 'Erotikk',
                originalLabel: 'Erotikk',
                note: 'Brukes for erotisk skjønnlitteratur med eksplisitte skildringer av forhold av seksuell karakter, og hvor hovedpersonene ikke nødvendigvis har et romantisk forhold. Brukes sammen med kvalifikator 5X der det er hensiktsmessig. Skal IKKE brukes med FRX. Se også FRX <a href="/thema/nb/FRX">Erotisk romantikk</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'FS',
        label: 'Familieromaner',
        originalLabel: 'Familieromaner',
        alternativeLabels: [],
        note: 'Brukes for romaner som skildrer familiens hverdagsliv, ofte med hovedvekt på relasjonene innen familien - uansett type, men ikke det samme som FT Slektsromaner. Brukes med andre F*-koder der det er hensiktsmessig. Se også FT <a href="/thema/nb/FT">Slektsromaner</a>',
        related: [
          {
            id: 'FT',
            label: 'Slektsromaner',
            originalLabel: 'Slektsromaner',
            note: 'Her: familiesagaer, familier og slekter. Brukes for romaner som skildrer en families/familiers skjebne, ofte gjennom flere generasjoner. Brukes med FR*-koder for romantiske sagaer eller med FV når den historiske tidsperioden er viktig. BRUKES IKKE for norrøne sagaer. Se også FS <a href="/thema/nb/FS">Familieromaner</a>',
          },
        ],
        children: [],
      },
      {
        id: 'FT',
        label: 'Slektsromaner',
        originalLabel: 'Slektsromaner',
        alternativeLabels: [],
        note: 'Her: familiesagaer, familier og slekter. Brukes for romaner som skildrer en families/familiers skjebne, ofte gjennom flere generasjoner. Brukes med FR*-koder for romantiske sagaer eller med FV når den historiske tidsperioden er viktig. BRUKES IKKE for norrøne sagaer. Se også FS <a href="/thema/nb/FS">Familieromaner</a>',
        related: [
          {
            id: 'FS',
            label: 'Familieromaner',
            originalLabel: 'Familieromaner',
            note: 'Brukes for romaner som skildrer familiens hverdagsliv, ofte med hovedvekt på relasjonene innen familien - uansett type, men ikke det samme som FT Slektsromaner. Brukes med andre F*-koder der det er hensiktsmessig. Se også FT <a href="/thema/nb/FT">Slektsromaner</a>',
          },
        ],
        children: [],
      },
      {
        id: 'FU',
        label: 'Humoristisk skjønnlitteratur',
        originalLabel: 'Humoristisk skjønnlitteratur',
        alternativeLabels: ['Komisk skjønnlitteratur'],
        note: 'Brukes for skjønnlitteratur hvor humor er det primære målet. Foretrekk sjangerspesifikke humor-koder der det finnes. Kan brukes sammen med andre F*-koder der det er hensiktsmessig',
        related: [],
        children: [
          {
            id: 'FUP',
            label: 'Satire og parodier',
            originalLabel: 'Satire og parodier',
            alternativeLabels: [],
            note: 'Brukes for humoristisk skjønnlitteratur som er satirisk og/eller som parodierer annen skjønnlitteratur, sjanger eller forfattere. Se også WHP <a href="/thema/nb/WHP">Parodier</a>, FYH <a href="/thema/nb/FYH">Pastisj</a>',
            related: [
              {
                id: 'FYH',
                label: 'Pastisj',
                originalLabel: 'Pastisj',
                note: 'Brukes for skjønnlitteratur skrevet bevisst i stil med en annen (vanligvis tidligere) forfatter (som hyllest snarere enn en parodi). Vanligvis som en oppfølger til en roman skrevet av, eller som bruker en populær karakter skapt av den opprinnelige forfatteren. Se også FUP <a href="/thema/nb/FUP">Satire og parodier</a>',
              },
              {
                id: 'WHP',
                label: 'Parodier',
                originalLabel: 'Parodier',
                note: 'Se også FUP <a href="/thema/nb/FUP">Satire og parodier</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'FV',
        label: 'Historiske romaner',
        originalLabel: 'Historiske romaner',
        alternativeLabels: [],
        note: 'Brukes for skjønnlitteratur der handlingen er lagt til fortiden (vanligvis før forfatterens levetid). Tidsperioden er en viktig del av historien og den skildrer tidsånden når det gjelder skikker og sosiale forhold. Brukes sammen med andre F*-koder der det er hensiktsmessig. Brukes med kvalifikatorer for sted og/eller tidsperiode brukes for å indikere hvor og/eller når handlingen foregår, der det er hensiktsmessig. Foretrekk sjangerspesifikke koder for historisk skjønnlitteratur der de fins. Se også FFH <a href="/thema/nb/FFH">Historisk krim</a>, FJH <a href="/thema/nb/FJH">Historiske spennende og actionfylte fortellinger</a>, FMH <a href="/thema/nb/FMH">Historisk fantasy</a>, FRH <a href="/thema/nb/FRH">Historisk romantikk</a>',
        related: [
          {
            id: 'FFH',
            label: 'Historisk krim',
            originalLabel: 'Historisk krim',
            note: 'Brukes for moderne krim hvor handlingen er lagt til tidligere tider, for eksempel i viktoriatiden, middelalderen, romertiden. Bruk kvalifikatorer for sted og/eller tidsperiode for å indikere hvor og/eller når handlingen foregår',
          },
          {
            id: 'FJH',
            label: 'Historiske spennende og actionfylte fortellinger',
            originalLabel: 'Historiske spennende og actionfylte fortellinger',
            note: 'Brukes for spennende skjønnlitteratur som ikke er krim der handlingen er lagt til eldre tider. Foretrekk FJM*-kode der dette er aktuelt. Bruk kvalifikatorer for sted og/eller tidsperiode for å indikere hvor og/eller når handlingen foregår',
          },
          {
            id: 'FMH',
            label: 'Historisk fantasy',
            originalLabel: 'Historisk fantasy',
            note: 'Brukes for fantasy som innlemmer fantastiske elementer, som magi eller elementer fra myter, i gjenkjennelige historiske omgivelser, perioder, kulturer osv. Brukes med kvalifikatorer for sted og/ eller tidsperiode der det er hensiktsmessig',
          },
          {
            id: 'FRH',
            label: 'Historisk romantikk',
            originalLabel: 'Historisk romantikk',
            note: 'Brukes for romantisk skjønnlitteratur der handlingen er lagt til fortiden (vanligvis før forfatterens levetid). Tidsperioden er en viktig del av historien og den skildrer tidsånden når det gjelder skikker og sosiale forhold. Brukes med kvalifikatorer for sted og/eller tidsperiode der det er hensiktsmessig',
          },
        ],
        children: [],
      },
      {
        id: 'FW',
        label: 'Religiøs og spirituell skjønnlitteratur',
        originalLabel: 'Religiøs og spirituell skjønnlitteratur',
        alternativeLabels: [],
        note: 'Brukes for skjønnlitteratur som fremmer et religiøst, spirituelt eller visjonært budskap. Brukes sammen med relevante 5PG* kode der det er hensiktsmessig, eller der det ikke er tilgjengelig, en QR*- eller VX*-kode. Brukes med andre F*-koder, f. eks. FR* for religiøse romantikkromaner, med VX*-koder for visjonær eller spirituell fiksjon, etc.',
        related: [],
        children: [],
      },
      {
        id: 'FX',
        label: 'Skjønnlitterære emner',
        originalLabel: 'Skjønnlitterære emner',
        alternativeLabels: [],
        note: 'Bruk FX*-koder for å indikere temaet for en skjønnlitterær utgivelse, når dette ikke kan uttrykkes av en sjangerkode aleine. Bruk FX*-koder med andre F*-koder for å angi sjanger eller type, eller med DB*-, DC*, DD* eller XQ*-koder. FX*-koder kan også brukes sammen med ikke-skjønnlitterære kategorier for å definere det narrative temaet der det er hensiktsmessig. BRUK IKKE FX*-koder som hovedkategori',
        related: [],
        children: [
          {
            id: 'FXB',
            label: 'Oppvekst',
            originalLabel: 'Skjønnlitterære emner: oppvekst',
            alternativeLabels: [],
            note: 'Her: romaner om overgangen mellom barndom/ungdom og voksenlivet. Coming of age, bildungsroman, dannelsesroman',
            related: [],
            children: [],
          },
          {
            id: 'FXD',
            label: 'Kjærlighet og relasjoner',
            originalLabel: 'Skjønnlitterære emner: kjærlighet og relasjoner',
            alternativeLabels: ['parforhold'],
            note: 'Brukes for skjønnlitteratur hvor et sterkt forhold eller kjærlighet er det sentrale emnet, men hvor det ikke er snakk om sjangeren romantikk. Dette kan omfatte vennskap, familieforhold, negative eller fiendtlige forhold etc., som hovedtema i fortellingen. Brukes med kvalifikator(er) for interesse for å indikere spesielle typer av relasjoner der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'FXE',
            label: 'Natur og miljø',
            originalLabel: 'Skjønnlitterære emner: natur og miljø',
            alternativeLabels: ['klimaromaner'],
            note: 'Brukes for skjønnlitteratur som har miljø, klima eller natur som et sentralt tema. Brukes med andre emnekoder for mer detaljert beskrivelse hvis hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'FXK',
            label: 'Helse og sykdom',
            originalLabel: 'Skjønnlitterære emner: helse og sykdom',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur der helse, eller det å leve med fysisk eller psykisk sykdom er et sentralt tema, eller som omhandler konsekvensene av - eller restitusjon etter sykdom, eller andre aspekter relatert til fysisk og psykisk helse',
            related: [],
            children: [],
          },
          {
            id: 'FXL',
            label: 'Død, sorg, tap',
            originalLabel: 'Skjønnlitterære emner: død, sorg, tap',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur hvor tap eller sorgerfaringer er et sentralt tema',
            related: [],
            children: [],
          },
          {
            id: 'FXM',
            label: 'Indre liv',
            originalLabel: 'Skjønnlitterære emner: indre liv',
            alternativeLabels: [],
            note: 'Her: psykologisk realisme. Brukes for skjønnlitteratur som legger stor vekt på å skildre karakterenes motiver, følelser og tanker. Bruk FHX for psykologiske thrillere',
            related: [],
            children: [],
          },
          {
            id: 'FXN',
            label: 'Identitet',
            originalLabel: 'Skjønnlitterære emner: identitet / det å høre til',
            alternativeLabels: ['selvbilde'],
            note: 'Brukes for skjønnlitteratur hvor hovedpersonens identitet, identitetsfølelse eller søking etter identitet – av alle typer, både sosial, kulturell, religiøs, kjønn – både individuelt eller som del av et fellesskap) er hovedtemaet eller et av hovedtemaene i fortellingen. Brukes med andre emnekoder og kvalifikatorer for interesse der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'FXP',
            label: 'Politikk',
            originalLabel: 'Skjønnlitterære emner: politikk',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur som i stor grad tar for seg politikk eller politiske systemer',
            related: [],
            children: [],
          },
          {
            id: 'FXQ',
            label: 'Fordrivelse, eksil, migrasjon',
            originalLabel:
              'Skjønnlitterære emner: fordrivelse, eksil, migrasjon',
            alternativeLabels: ['flyktninger', 'menneskesmugling'],
            note: 'Brukes for skjønnlitteratur som tar for seg temaer som diaspora, asylsøkere, tvangsflytting, forflytning, internt fordrevne, emigrasjon, utvisning, etc. Se også FXV <a href="/thema/nb/FXV">Skjønnlitterære emner: reiser</a>',
            related: [
              {
                id: 'FXV',
                label: 'Reiser',
                originalLabel: 'Skjønnlitterære emner: reiser',
                note: 'Her: fortellinger om bilturer, oppdagelsesreiser, utforskere eller vandrere. Brukes for skjønnlitteratur der en eller annen form for reise er hovedtema, eller et av hovedtemaene i fortellingen. Se også FXQ <a href="/thema/nb/FXQ">Skjønnlitterære emner: fordrivelse, eksil, migrasjon</a>',
              },
            ],
            children: [],
          },
          {
            id: 'FXR',
            label: 'Stedbundet litteratur',
            originalLabel: 'Skjønnlitterære emner: stedbundet litteratur',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur som foregår på et virkelig sted (ofte, men ikke nødvendigvis landlig eller historisk), hvor settingen gjennomsyrer verket i en sånn grad at det ikke kunne foregått noe annet sted; f.eks. den franske tradisjonen med «roman du terroir» Legg til passende kvalifikator for sted',
            related: [],
            children: [],
          },
          {
            id: 'FXS',
            label: 'Sosiale spørsmål',
            originalLabel: 'Skjønnlitterære emner: sosiale spørsmål',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur som har et spesifikt sosialt problem som et sentralt tema. Brukes med andre faglitterære koder, f.eks. fra JBF* for å angi hvilke typer sosiale problemer som beskrives',
            related: [],
            children: [],
          },
          {
            id: 'FXT',
            label: 'Mangfold, likeverd, inkludering',
            originalLabel:
              'Skjønnlitterære emner: mangfold, likeverd, inkludering',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur som tar for seg temaer knyttet til sosial rettferdighet, likhet eller likeverd, mangfold og inkludering (eller motsatsen til disse). Brukes med andre emnekoder og kvalifikator(er) for interesse hvis hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'FXV',
            label: 'Reiser',
            originalLabel: 'Skjønnlitterære emner: reiser',
            alternativeLabels: [],
            note: 'Her: fortellinger om bilturer, oppdagelsesreiser, utforskere eller vandrere. Brukes for skjønnlitteratur der en eller annen form for reise er hovedtema, eller et av hovedtemaene i fortellingen. Se også FXQ <a href="/thema/nb/FXQ">Skjønnlitterære emner: fordrivelse, eksil, migrasjon</a>',
            related: [
              {
                id: 'FXQ',
                label: 'Fordrivelse, eksil, migrasjon',
                originalLabel:
                  'Skjønnlitterære emner: fordrivelse, eksil, migrasjon',
                note: 'Brukes for skjønnlitteratur som tar for seg temaer som diaspora, asylsøkere, tvangsflytting, forflytning, internt fordrevne, emigrasjon, utvisning, etc. Se også FXV <a href="/thema/nb/FXV">Skjønnlitterære emner: reiser</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'FY',
        label: 'Skjønnlitteratur: spesielle utgivelser',
        originalLabel: 'Skjønnlitteratur: spesielle utgivelser',
        alternativeLabels: [],
        note: 'Bruk FY*-koder med andre F*- koder som indikerer sjanger eller type, eller med DB*, DC*, DD* eller XQ*-koder. FY*-koder bør vanligvis ikke være første eller eneste emnekode',
        related: [],
        children: [
          {
            id: 'FYB',
            label: 'Noveller',
            originalLabel: 'Noveller',
            alternativeLabels: [],
            note: 'Må brukes med andre F-koder',
            related: [],
            children: [],
          },
          {
            id: 'FYC',
            label: 'Kortromaner',
            originalLabel: 'Kortromaner',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur som verken anses som noveller eller romaner',
            related: [],
            children: [],
          },
          {
            id: 'FYD',
            label: 'Brev- og dagbokromaner',
            originalLabel: 'Brev- og dagbokromaner',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur som er skrevet som en serie dokumenter, brev, dagbøker, eposter, bloggposter etc.',
            related: [],
            children: [],
          },
          {
            id: 'FYH',
            label: 'Pastisj',
            originalLabel: 'Pastisj',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur skrevet bevisst i stil med en annen (vanligvis tidligere) forfatter (som hyllest snarere enn en parodi). Vanligvis som en oppfølger til en roman skrevet av, eller som bruker en populær karakter skapt av den opprinnelige forfatteren. Se også FUP <a href="/thema/nb/FUP">Satire og parodier</a>',
            related: [
              {
                id: 'FUP',
                label: 'Satire og parodier',
                originalLabel: 'Satire og parodier',
                note: 'Brukes for humoristisk skjønnlitteratur som er satirisk og/eller som parodierer annen skjønnlitteratur, sjanger eller forfattere. Se også WHP <a href="/thema/nb/WHP">Parodier</a>, FYH <a href="/thema/nb/FYH">Pastisj</a>',
              },
            ],
            children: [],
          },
          {
            id: 'FYM',
            label: 'Mashup',
            originalLabel: 'Skjønnlitteratur: mashup',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur som kombinerer en pre-eksisterende tekst, ofte en klassisk roman, med en annen genre, som f.eks. grøssere, i en og samme fortelling. Brukes sammen med andre F*- eller faglitterære koder for å angi komponentelementer. Se også FUP <a href="/thema/nb/FUP">Satire og parodier</a>',
            related: [
              {
                id: 'FUP',
                label: 'Satire og parodier',
                originalLabel: 'Satire og parodier',
                note: 'Brukes for humoristisk skjønnlitteratur som er satirisk og/eller som parodierer annen skjønnlitteratur, sjanger eller forfattere. Se også WHP <a href="/thema/nb/WHP">Parodier</a>, FYH <a href="/thema/nb/FYH">Pastisj</a>',
              },
            ],
            children: [],
          },
          {
            id: 'FYP',
            label:
              'Skjønnlitteratur basert på eller inspirert av virkelige hendelser',
            originalLabel:
              'Skjønnlitteratur basert på eller inspirert av virkelige hendelser',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur basert på eller inspirert av virkelige hendelser, men som er skrevet i en fortellende, fiksjonalisert form, og ikke bare er en beretning om hendelsen i seg selv. Brukes med andre F*-koder for å beskrive sjanger eller med DB*, DC*, DD* eller XQ*-koder. Foretrekk DNX* for historier fra virkeligheten, eller FC for biografisk skjønnlitteratur basert på livene til virkelige mennesker',
            related: [],
            children: [],
          },
          {
            id: 'FYQ',
            label: 'Yaoi',
            originalLabel: 'Skjønnlitteratur: spesielle utgivelser: yaoi',
            alternativeLabels: ['boys love'],
            note: 'Her: BL-romaner, danmei. Brukes med andre F*-koder for å angi sjanger, f.eks. FR*-koder for Romantikk, 5LKE*-koder dersom denne livsfasen er omhandlet. Se også XAMT <a href="/thema/nb/XAMT">Manga: Yaoi</a>',
            related: [
              {
                id: 'XAMT',
                label: 'Manga: Yaoi',
                originalLabel: 'Manga: Yaoi',
                note: 'Her: manga som skildrer homoseksuelle forhold mellom unge menn (BL), hovedsakelig, men ikke utelukkende ment for unge kvinnelige lesere. Brukes med 5PSG der det er hensiktsmessig. Se også FYQ <a href="/thema/nb/FYQ">Skjønnlitteratur: spesielle utgivelser: yaoi</a>',
              },
            ],
            children: [],
          },
          {
            id: 'FYR',
            label: 'Serielitteratur',
            originalLabel: 'Serielitteratur',
            alternativeLabels: [],
            note: 'Brukes for romantikk, science fiction eller annen skjønnlitteratur tilhørende en sjanger og som vanligvis utgis med en fast utgivelsesfrekvens. Brukes med andre F*-koder for å angi sjanger eller type. FYR SKAL ALDRI være første eller eneste emnekode',
            related: [],
            children: [],
          },
          {
            id: 'FYS',
            label: 'Ranobe',
            originalLabel: 'Skjønnlitteratur: spesielle utgivelser: ranobe',
            alternativeLabels: ['lettroman'],
            note: 'Brukes for noveller ment for et voksent publikum, som oftest dialog- og karakterdrevet og vanligvis supplert av manga-/animeillustrasjoner eller tilsvarende. Brukes med andre F*-koder for å angi sjanger. FYS kan brukes som hovedkategori der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'FYT',
            label: 'Oversatt skjønnlitteratur',
            originalLabel: 'Oversatt skjønnlitteratur',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur som er oversatt fra et annet språk. Legg til flere F*-koder for å sikre at klassifikasjonen er meningsfull utover selve oversettelsen av verket. IKKE BRUK kvalifikator for sted eller språk for å beskrive originalspråket (dette beskrives i andre metadatafelt). Du kan likevel bruke kvalifikator for sted, hvis det er relevant for handlingen i boka. FYT SKAL ALDRI være første eller eneste emnekode',
            related: [],
            children: [],
          },
          {
            id: 'FYV',
            label: 'Spinoff',
            originalLabel:
              'Skjønnlitteratur: inspirert av eller omarbeidet fra andre medier',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur som er utgitt som en spinoff eller som et ledsagende produkt til allerede eksisterende medieprodukter eller -varer som ikke er bøker. F.eks. romaner som er inspirert av populære filmer, spill eller TV-serier. Brukes med andre F*-koder for å angi sjanger eller type, eller med DB*-, DC*-, DD*-koder. Skal IKKE brukes for romaner som i etterkant er blitt filmatisert, bøker med filmomslag etc. (dette beskrives i andre metadatafelt)',
            related: [],
            children: [],
          },
          {
            id: 'FYW',
            label: 'Litterære rollespill',
            originalLabel: 'Skjønnlitteratur: spesielle utgivelser: spill',
            alternativeLabels: ['rollespill', 'gamelit', 'rolit'],
            note: 'Her: litterære rollespill (LitRPG). Brukes for skjønnlitteratur som følger samme struktur som et tv-spill eller rollespill, eller har samme type utfordringer som man finner i alle typer spill, eller som foregår i et tv-spill, nettspill, rollespill, etc. Brukes med andre F*-koder, særlig FL* eller FM* der det er hensiktsmessig',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'FZ',
        label: 'Skjønnlitteratur: guider, ledsagende bøker',
        originalLabel: 'Skjønnlitteratur: guider, ledsagende bøker',
        alternativeLabels: [],
        note: 'Brukes for bøker som er utgitt som ledsagende bøker til populære serier eller enkelttitler, f.eks. et atlas over en mytisk verden, en oversikt over karakterer, en guide til serien etc. Brukes med andre F*-koder for å angi sjanger eller type, eller med DB*-, DC*-, DD*- eller XQ*-koder dersom utgivelsen er et dikt, en tegneserie etc. Se også DSR <a href="/thema/nb/DSR">Litterære oppslagsverk</a>, XR* <a href="/thema/nb/XR">Grafiske romaner, tegneserier og manga: oppslagsverk, guider og anmeldelser</a> og dens underkategorier',
        related: [
          {
            id: 'DSR',
            label: 'Litterære oppslagsverk',
            originalLabel: 'Litterære oppslagsverk',
          },
          {
            id: 'XR',
            label:
              'Grafiske romaner, tegneserier og manga: oppslagsverk, guider og anmeldelser',
            originalLabel:
              'Grafiske romaner, tegneserier og manga: oppslagsverk, guider og anmeldelser',
            note: 'Brukes med andre emnekoder der det er hensiktsmessig, f.eks. med FZ for sammenlignende litteratur, GB* for oppslagsverk, DS* for analyse eller kritikk av tegneserier. Se også DSR <a href="/thema/nb/DSR">Litterære oppslagsverk</a>, FZ <a href="/thema/nb/FZ">Skjønnlitteratur: guider, ledsagende bøker</a>',
          },
        ],
        children: [],
      },
    ],
  },
  {
    id: 'G',
    label: 'Referanse, informasjon og tverrfaglige emner',
    originalLabel: 'Referanse, informasjon og tverrfaglige emner',
    alternativeLabels: [],
    note: 'Bruk G*-koder både for akademiske utgivelser og utgivelser for allmennmarkedet. IKKE BRUK koden ‘G’ aleine, men velg spesifikke kategorier fra G*. Alle G*-koder kan brukes sammen med andre emnekoder og kvalifikatorer der det er hensiktsmessig',
    related: [],
    children: [
      {
        id: 'GB',
        label: 'Leksika og oppslagsverk',
        originalLabel: 'Leksika og oppslagsverk',
        alternativeLabels: ['leksikon'],

        related: [],
        children: [
          {
            id: 'GBA',
            label: 'Generelle leksika',
            originalLabel: 'Generelle leksika',
            alternativeLabels: ['leksikon'],
            note: 'Brukes for leksika som ikke er om spesielle emner',
            related: [],
            children: [],
          },
          {
            id: 'GBC',
            label: 'Oppslagsverk',
            originalLabel: 'Oppslagsverk',
            alternativeLabels: [],
            note: 'Bruk GBC etter andre fagkoder som viser verkets område eller omfang. Se også CB* <a href="/thema/nb/CB">Språk: referanseverk og generelle verk</a> og dens underkategorier, DSR* <a href="/thema/nb/DSR">Litterære oppslagsverk</a> og dens underkategorier',
            related: [
              {
                id: 'CB',
                label: 'Språk: referanseverk og generelle verk',
                originalLabel: 'Språk: referanseverk og generelle verk',
                note: 'Ved bruk av CB*-koder tildel også kvalifikator(er) for språk der det er hensiktsmessig',
              },
              {
                id: 'DSR',
                label: 'Litterære oppslagsverk',
                originalLabel: 'Litterære oppslagsverk',
              },
            ],
            children: [
              {
                id: 'GBCB',
                label: 'Biografiske oppslagsverk',
                originalLabel: 'Biografiske oppslagsverk',
                alternativeLabels: [],
                note: 'Her: Hvem er hvem-bøker. Brukes med andre emnekoder for å angi område for eller verkets omfang',
                related: [],
                children: [],
              },
              {
                id: 'GBCD',
                label: 'Fagordbøker',
                originalLabel: 'Fagordbøker',
                alternativeLabels: [],
                note: 'Brukes for ordbøker og ordlister som dekker et bestemt emne, f.eks. juridiske ordbøker. Brukes med andre emnekoder der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'GBCQ',
                label: 'Sitatsamlinger, ordspråk',
                originalLabel: 'Sitatsamlinger, ordspråk',
                alternativeLabels: [],
                note: 'Her: samlinger av vanlige fraser eller aforismer, nyttige sitater for bestemte formål',
                related: [],
                children: [],
              },
              {
                id: 'GBCR',
                label: 'Bibliografier, kataloger',
                originalLabel: 'Bibliografier, kataloger',
                alternativeLabels: [],
                note: 'Se også AVD <a href="/thema/nb/AVD">Diskografier og kjøpeguider</a>',
                related: [
                  {
                    id: 'AVD',
                    label: 'Diskografier og kjøpeguider',
                    originalLabel: 'Diskografier og kjøpeguider',
                  },
                ],
                children: [],
              },
              {
                id: 'GBCS',
                label: 'Serier, tidsskrifter, sammendrag, indekser',
                originalLabel: 'Serier, tidsskrifter, sammendrag, indekser',
                alternativeLabels: [],
                note: 'Her: institusjonsserier',
                related: [],
                children: [],
              },
              {
                id: 'GBCT',
                label: 'Registre',
                originalLabel: 'Registre',
                alternativeLabels: ['telefonkataloger', 'firmaregistre'],
                note: 'Brukes for bøker som gir oversikt over personer eller organisasjoner (vanligvis sortert alfabetisk, tematisk, fonetisk etc.) med detaljer som navn, adresse og telefonnummer',
                related: [],
                children: [],
              },
              {
                id: 'GBCY',
                label: 'Årbøker og almanakker',
                originalLabel: 'Årbøker og almanakker',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'GBD',
            label: 'Samlinger og kompendier',
            originalLabel: 'Samlinger og kompendier',
            alternativeLabels: [],
            note: 'Brukes for utgivelser som inneholder et utvalg tekster som er samlet fra ulike kilder og som kan dekke flere emner og format, som f.eks. sitater, fakta, illustrasjoner etc. Brukes med andre emnekoder når samlinga dekker et bredt emneområde (f.eks. sammen med WN*-koder). Se også WDKX <a href="/thema/nb/WDKX">Spørrebøker</a>',
            related: [
              {
                id: 'WDKX',
                label: 'Spørrebøker',
                originalLabel: 'Spørrebøker',
                note: 'Her: quiz om allmennkunnskap, spørrebøker for ‘pub-quiz’. Brukes med andre emnekoder for spørrebøker om et spesielt tema der det er hensiktsmessig. Se også GBD <a href="/thema/nb/GBD">Samlinger og kompendier</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'GL',
        label: 'Bibliotek-, informasjons- og museumsvitenskap',
        originalLabel: 'Bibliotek-, informasjons- og museumsvitenskap',
        alternativeLabels: ['bibliotek', 'museum'],

        related: [],
        children: [
          {
            id: 'GLC',
            label: 'Bibliotek-, arkiv- og informasjonshåndtering',
            originalLabel: 'Bibliotek-, arkiv- og informasjonshåndtering',
            alternativeLabels: ['arkiv'],

            related: [],
            children: [
              {
                id: 'GLCA',
                label: 'Informasjonsgjenfinning og -tilgang',
                originalLabel: 'Informasjonsgjenfinning og -tilgang',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'GLF',
            label: 'IT, Internett og digitale ressurser i bibliotekene',
            originalLabel: 'IT, Internett og digitale ressurser i bibliotekene',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'GLH',
            label: 'Innkjøp og samlingsutvikling',
            originalLabel: 'Innkjøp og samlingsutvikling',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'GLK',
            label: 'Bibliografisk- og emneordskontroll',
            originalLabel: 'Bibliografisk- og emneordskontroll',
            alternativeLabels: ['bibliografisk kontroll'],
            note: 'Her: indeksering, katalogisering, klassifisering',
            related: [],
            children: [],
          },
          {
            id: 'GLM',
            label: 'Bibliotek- og informasjonstjenester',
            originalLabel: 'Bibliotek- og informasjonstjenester',
            alternativeLabels: [],
            note: 'Her: bibliotek; referansearbeid, offentlige informasjonstjenester',
            related: [],
            children: [],
          },
          {
            id: 'GLP',
            label: 'Arkivering, bevaring og digitalisering',
            originalLabel: 'Arkivering, bevaring og digitalisering',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'GLZ',
            label: 'Museumsvitenskap og kulturarvstudier',
            originalLabel: 'Museumsvitenskap og kulturarvstudier',
            alternativeLabels: [],
            note: 'Her: museumsforskning, kuratorer, kulturforskning og bevaring av kulturarv. Brukes for bøker om bygninger, monumenter og steder som er viktige for bevaring av et samfunns materielle og immaterielle kulturarv. Se også ABC <a href="/thema/nb/ABC">Konservering og restaurering av kunstverk</a>, AGC <a href="/thema/nb/AGC">Utstillingskataloger og kunstsamlinger</a>, NK* <a href="/thema/nb/NK">Arkeologi</a> og dens underkategorier, RNK <a href="/thema/nb/RNK">Miljøvern</a>, TNKX <a href="/thema/nb/TNKX">Bevaring av bygninger og bygningsmaterialer</a>, WTHM <a href="/thema/nb/WTHM">Reisehåndbøker: museer, historiske steder, gallerier etc.</a>',
            related: [
              {
                id: 'ABC',
                label: 'Konservering og restaurering av kunstverk',
                originalLabel: 'Konservering og restaurering av kunstverk',
                note: 'Brukes med andre A*-koder for bøker om konservering, bevaring, renovering og restaurering av alle typer kunstverk, inkludert bygninger, strukturer, skulpturer, fotografier, malerier etc.',
              },
              {
                id: 'AGC',
                label: 'Utstillingskataloger og kunstsamlinger',
                originalLabel: 'Utstillingskataloger og kunstsamlinger',
                note: 'Brukes for publikasjoner som er utgitt både til tidsavgrensede og faste utstillinger, og til samlinger hos kunstgallerier, museer eller samlere. Brukes med andre A*-koder for å angi kunstform og kvalifikator(er) for sted dersom katalogen er knyttet til et spesifikt galleri eller museum. Se også WTHM <a href="/thema/nb/WTHM">Reisehåndbøker: museer, historiske steder, gallerier etc.</a>, GLZ <a href="/thema/nb/GLZ">Museumsvitenskap og kulturarvstudier</a>',
              },
              {
                id: 'NK',
                label: 'Arkeologi',
                originalLabel: 'Arkeologi',
              },
              {
                id: 'RNK',
                label: 'Miljøvern',
                originalLabel: 'Miljøvern',
                note: 'Her: bevaring av miljøet, landskapsforvaltning. Brukes med RBK* eller RBG* for å indiktere et bestemt miljø eller habitat',
              },
              {
                id: 'TNKX',
                label: 'Bevaring av bygninger og bygningsmaterialer',
                originalLabel: 'Bevaring av bygninger og bygningsmaterialer',
                note: 'Brukes for praktiske og tekniske guider til bevaring eller restaurering av bygninger og strukturer, inkludert historiske bygninger og ferdigheter og håndverk som forbindes med dette',
              },
              {
                id: 'WTHM',
                label:
                  'Reisehåndbøker: museer, historiske steder, gallerier etc.',
                originalLabel:
                  'Reisehåndbøker: museer, historiske steder, gallerier etc.',
                note: 'Her: reisehåndbøker om kulturarvsteder, bygninger, monumenter, steder av kulturhistorisk og arkeologisk betydning etc.',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'GP',
        label: 'Forskning og informasjon',
        originalLabel: 'Forskning og informasjon',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'GPF',
            label: 'Informasjonsteori',
            originalLabel: 'Informasjonsteori',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'GPFC',
                label: 'Kybernetikk og systemteori',
                originalLabel: 'Kybernetikk og systemteori',
                alternativeLabels: [],
                note: 'Her: styring av og stabiliteten til systemer, entropi',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'GPH',
            label: 'Datavitenskap og -analyse',
            originalLabel: 'Datavitenskap og -analyse',
            alternativeLabels: ['dataanalyse', 'infografikk', 'big data'],
            note: 'Her: datavisualisering og informasjonsgrafikk, store datasett, stordata. Brukes for tverrfaglige bøker om bruken av data. Brukes med andre emnekoder der det er hensiktsmessig. Se også UN* <a href="/thema/nb/UN">Databaser</a> og dens underkategorier, UX* <a href="/thema/nb/UX">Anvendt databehandling</a> og dens underkategorier, UYZF <a href="/thema/nb/UYZF">Informasjonsvisualisering</a>',
            related: [
              {
                id: 'UN',
                label: 'Databaser',
                originalLabel: 'Databaser',
              },
              {
                id: 'UX',
                label: 'Anvendt databehandling',
                originalLabel: 'Anvendt databehandling',
                note: 'Her: digital forskning og teknikker, anvendelse av kunstig intelligens i samfunnet, analyse av stordata eller metoder og verktøy innen databehandling som brukes på tradisjonelle disipliner. Se også PSAX <a href="/thema/nb/PSAX">Bioinformatikk</a>, MBF <a href="/thema/nb/MBF">Medisinsk- og helsefaglig informatikk</a>',
              },
              {
                id: 'UYZF',
                label: 'Informasjonsvisualisering',
                originalLabel: 'Informasjonsvisualisering',
              },
            ],
            children: [],
          },
          {
            id: 'GPJ',
            label: 'Kodeteori og kryptologi',
            originalLabel: 'Kodeteori og kryptologi',
            alternativeLabels: [],
            note: 'Her: kryptografi. Se også URY <a href="/thema/nb/URY">Datakryptering</a>',
            related: [
              {
                id: 'URY',
                label: 'Datakryptering',
                originalLabel: 'Datakryptering',
                note: 'Se også GPJ <a href="/thema/nb/GPJ">Kodeteori og kryptologi</a>',
              },
            ],
            children: [],
          },
          {
            id: 'GPQ',
            label: 'Beslutningsteori',
            originalLabel: 'Beslutningsteori',
            alternativeLabels: [],
            note: 'Se også KJMD <a href="/thema/nb/KJMD">Lederavgjørelser</a>',
            related: [
              {
                id: 'KJMD',
                label: 'Lederavgjørelser',
                originalLabel: 'Lederavgjørelser',
                note: 'Se også GPQ <a href="/thema/nb/GPQ">Beslutningsteori</a>',
              },
            ],
            children: [
              {
                id: 'GPQD',
                label: 'Risikovurdering',
                originalLabel: 'Risikovurdering',
                alternativeLabels: [],
                note: 'Her: risikoanalyse, risikostyring. Brukes med andre koder for bøker om risiko i ulike sektorer',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'GPS',
            label: 'Forskningsmetoder',
            originalLabel: 'Forskningsmetoder',
            alternativeLabels: [],
            note: 'Brukes med andre emnekoder for forskningsmetoder innen en bestemt disiplin. Se også JHBC <a href="/thema/nb/JHBC">Sosial forskning og statistikk</a>, KJT <a href="/thema/nb/KJT">Operasjonsanalyse</a>',
            related: [
              {
                id: 'JHBC',
                label: 'Sosial forskning og statistikk',
                originalLabel: 'Sosial forskning og statistikk',
                note: 'Se også GPS <a href="/thema/nb/GPS">Forskningsmetoder</a>',
              },
              {
                id: 'KJT',
                label: 'Operasjonsanalyse',
                originalLabel: 'Operasjonsanalyse',
                note: 'Se også PBW <a href="/thema/nb/PBW">Anvendt matematikk</a>, KJMN <a href="/thema/nb/KJMN">Forretningsprosesser / driftsledelse</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'GT',
        label: 'Tverrfaglige studier',
        originalLabel: 'Tverrfaglige studier',
        alternativeLabels: [],
        note: 'Bruk alle GT*-koder med andre emnekategorier og kvalifikatorer for bøker om forskjellige typer av studier, vanligvis akademiske, som inkluderer flere emner eller disipliner',
        related: [],
        children: [
          {
            id: 'GTB',
            label: 'Historiske studier',
            originalLabel:
              'Historiske studier (hovedsakelig samfunnsvitenskap og humaniora)',
            alternativeLabels: [],
            note: 'For historiske studier som konsentrerer seg om fagfelt som ikke omfattes av det engelske begrepet «science» (jf. vitenskapshistorie), men som omfattes av det tyske begrepet «Wissenschaft» (dvs. alle typer akademiske studier). Se også PDA <a href="/thema/nb/PDA">Vitenskapsfilosofi</a>, PDX <a href="/thema/nb/PDX">Vitenskapshistorie</a>',
            related: [
              {
                id: 'PDA',
                label: 'Vitenskapsfilosofi',
                originalLabel: 'Vitenskapsfilosofi',
              },
              {
                id: 'PDX',
                label: 'Vitenskapshistorie',
                originalLabel: 'Vitenskapshistorie',
                note: 'Se også TBX <a href="/thema/nb/TBX">Ingeniørvitenskapens og teknologiens historie</a>',
              },
            ],
            children: [],
          },
          {
            id: 'GTC',
            label: 'Kommunikasjonsstudier',
            originalLabel: 'Kommunikasjonsstudier',
            alternativeLabels: [],
            note: 'Brukes for lærebøker innen kommunikasjon og for verk som dekker en rekke aspekter ved kommunikasjon - språklige, sosiale, industrielle, teknologiske etc. Se også JBCT* <a href="/thema/nb/JBCT">Medievitenskap</a> og dens underkategorier',
            related: [
              {
                id: 'JBCT',
                label: 'Medievitenskap',
                originalLabel: 'Medievitenskap',
                note: 'Se også GTC <a href="/thema/nb/GTC">Kommunikasjonsstudier</a>',
              },
            ],
            children: [],
          },
          {
            id: 'GTD',
            label: 'Semiotikk / semiologi',
            originalLabel: 'Semiotikk / semiologi',
            alternativeLabels: [],
            note: 'Se også CFA <a href="/thema/nb/CFA">Språkfilosofi</a>',
            related: [
              {
                id: 'CFA',
                label: 'Språkfilosofi',
                originalLabel: 'Språkfilosofi',
                note: 'Se også GTD <a href="/thema/nb/GTD">Semiotikk / semiologi</a>',
              },
            ],
            children: [],
          },
          {
            id: 'GTK',
            label: 'Kognitive studier',
            originalLabel: 'Kognitive studier',
            alternativeLabels: [],
            note: 'Brukes for tverrfaglig arbeid. Se også QDTM <a href="/thema/nb/QDTM">Bevissthetsfilosofi</a>, JM* <a href="/thema/nb/JM">Psykologi</a> og dens underkategorier, MKJ <a href="/thema/nb/MKJ">Nevrologi og klinisk nevrofysiologi</a>, PSAN <a href="/thema/nb/PSAN">Nevrovitenskap</a>, UYQ* <a href="/thema/nb/UYQ">Kunstig intelligens</a> og dens underkategorier, UXJ <a href="/thema/nb/UXJ">Dataprogram for pedagogikk, psykologi, sosiologi og samfunnsfag</a>',
            related: [
              {
                id: 'JM',
                label: 'Psykologi',
                originalLabel: 'Psykologi',
                note: 'Bruk JM*-koder for akademiske verk. Bruk VS*-koder for praktiske råd til allmennpublikum. Se også VSP* <a href="/thema/nb/VSP">Populærpsykologi</a> og dens underkategorier',
              },
              {
                id: 'MKJ',
                label: 'Nevrologi og klinisk nevrofysiologi',
                originalLabel: 'Nevrologi og klinisk nevrofysiologi',
                note: 'Brukes for bøker om nevrologiske og nevrodegenerative sykdommer og lidelser, og sykdommer i nervesystemet',
              },
              {
                id: 'PSAN',
                label: 'Nevrovitenskap',
                originalLabel: 'Nevrovitenskap',
                note: 'Bruk MKMT6 «Kognitiv atferdsterapi» for terapeutiske aspekter av nevrovitenskap. Her nevrobiologi. Se også GTK <a href="/thema/nb/GTK">Kognitive studier</a>, MKJ <a href="/thema/nb/MKJ">Nevrologi og klinisk nevrofysiologi</a>, MKMT6 <a href="/thema/nb/MKMT6">Kognitiv adferdsterapi</a>',
              },
              {
                id: 'QDTM',
                label: 'Bevissthetsfilosofi',
                originalLabel: 'Bevissthetsfilosofi',
                note: 'Her: dualisme, monisme, nevrofilosofi, bevissthet. Se også JMA <a href="/thema/nb/JMA">Psykologisk teori, systemer, synspunkter og skoleretninger</a>, GTK <a href="/thema/nb/GTK">Kognitive studier</a>',
              },
              {
                id: 'UXJ',
                label:
                  'Dataprogram for pedagogikk, psykologi, sosiologi og samfunnsfag',
                originalLabel:
                  'Dataprogram for pedagogikk, psykologi, sosiologi og samfunnsfag',
                note: 'Se også GTK <a href="/thema/nb/GTK">Kognitive studier</a>',
              },
              {
                id: 'UYQ',
                label: 'Kunstig intelligens',
                originalLabel: 'Kunstig intelligens',
                note: 'Se også GTK <a href="/thema/nb/GTK">Kognitive studier</a>',
              },
            ],
            children: [],
          },
          {
            id: 'GTM',
            label: 'Regionale / internasjonale studier',
            originalLabel: 'Regionale / internasjonale studier',
            alternativeLabels: ['humangeografi'],
            note: 'Her: områdestudier, globale studier. Brukes for tverrfaglige arbeider som dekker en rekke temaer innenfor et definert geografisk, kulturelt eller lingvistisk område. Brukes med kvalifikatorer for sted og språk der det er hensiktsmessig. Se også RGL <a href="/thema/nb/RGL">Regionalgeografi</a>',
            related: [
              {
                id: 'RGL',
                label: 'Regionalgeografi',
                originalLabel: 'Regionalgeografi',
                note: 'Se også GTM <a href="/thema/nb/GTM">Regionale / internasjonale studier</a>',
              },
            ],
            children: [],
          },
          {
            id: 'GTP',
            label: 'Utviklingsstudier',
            originalLabel: 'Utviklingsstudier',
            alternativeLabels: [],
            note: 'Se også KCM <a href="/thema/nb/KCM">Utviklingsøkonomi og vekstøkonomier</a>',
            related: [
              {
                id: 'KCM',
                label: 'Utviklingsøkonomi og vekstøkonomier',
                originalLabel: 'Utviklingsøkonomi og vekstøkonomier',
                note: 'Se også GTP <a href="/thema/nb/GTP">Utviklingsstudier</a>',
              },
            ],
            children: [],
          },
          {
            id: 'GTQ',
            label: 'Globalisering',
            originalLabel: 'Globalisering',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'GTS',
            label: 'Avkolonisering av kunnskap',
            originalLabel: 'Avkolonisering av kunnskap',
            alternativeLabels: [],
            note: 'Her: epistemologisk eller epistemisk avkolonisering, dekolonialisme, bøker som utforsker en utvidet tilnærming til kunnskap, eller til hva som studeres og hvordan det studeres',
            related: [],
            children: [],
          },
          {
            id: 'GTT',
            label: 'Flagg, emblemer, symboler, logoer',
            originalLabel: 'Flagg, emblemer, symboler, logoer',
            alternativeLabels: [],
            note: 'Her: vexillologi, symbologi. Brukes for bøker om en eller flere av disse emnene. Brukes med andre emnekategorier hvis hensiktsmessig, f. eks. bruk med QR*-koder for religiøse symboler, med KJS*-koder for logoer eller symboler brukt i markedsføring, eller med VXW*-koder for symboler knyttet til mystikk eller magi. Se også NHTG <a href="/thema/nb/NHTG">Genealogi, heraldikk og navnehistorie</a>',
            related: [
              {
                id: 'NHTG',
                label: 'Genealogi, heraldikk og navnehistorie',
                originalLabel: 'Genealogi, heraldikk og navnehistorie',
                note: 'Her: slektsforskning. Se også GTT <a href="/thema/nb/GTT">Flagg, emblemer, symboler, logoer</a>, VFXB1 <a href="/thema/nb/VFXB1">Spedbarnsnavn: håndbøker for foreldre</a>, WQY <a href="/thema/nb/WQY">Familiehistorie og slektsgransking</a>',
              },
            ],
            children: [],
          },
          {
            id: 'GTU',
            label: 'Fredsstudier og konfliktløsning',
            originalLabel: 'Fredsstudier og konfliktløsning',
            alternativeLabels: [],
            note: 'Her: overgangsrett. Se også JWLP <a href="/thema/nb/JWLP">Fredsbevarende operasjoner</a>',
            related: [
              {
                id: 'JWLP',
                label: 'Fredsbevarende operasjoner',
                originalLabel: 'Fredsbevarende operasjoner',
                note: 'Se også GTU <a href="/thema/nb/GTU">Fredsstudier og konfliktløsning</a>',
              },
            ],
            children: [],
          },
          {
            id: 'GTV',
            label: 'Institusjoner og akademiske miljø',
            originalLabel: 'Institusjoner og akademiske miljø',
            alternativeLabels: [],
            note: 'Se også JPSN <a href="/thema/nb/JPSN">Internasjonale institusjoner</a>',
            related: [
              {
                id: 'JPSN',
                label: 'Internasjonale institusjoner',
                originalLabel: 'Internasjonale institusjoner',
                note: 'Her: FN og FN-byråer, EU og EU-institusjoner, mellomstatlige og ikke-statlige organisasjoner etc. Brukes med 1QF*-kvalifikatorer fra geografi der det er hensiktsmessig. Se også GTV <a href="/thema/nb/GTV">Institusjoner og akademiske miljø</a>',
              },
            ],
            children: [],
          },
          {
            id: 'GTZ',
            label: 'Generelle studier og generell kunnskap',
            originalLabel: 'Generelle studier og generell kunnskap',
            alternativeLabels: [],
            note: 'Brukes for tverrfaglig materiale kurs rettet mot studieforberedende opplæring eller etterutdanning. Brukes med 4CN*, 4CP* eller 4TN* for tester. Se også WDKX <a href="/thema/nb/WDKX">Spørrebøker</a>, YPWL <a href="/thema/nb/YPWL">Læremidler: studier / studieteknikk</a>',
            related: [
              {
                id: 'WDKX',
                label: 'Spørrebøker',
                originalLabel: 'Spørrebøker',
                note: 'Her: quiz om allmennkunnskap, spørrebøker for ‘pub-quiz’. Brukes med andre emnekoder for spørrebøker om et spesielt tema der det er hensiktsmessig. Se også GBD <a href="/thema/nb/GBD">Samlinger og kompendier</a>',
              },
              {
                id: 'YPWL',
                label: 'Læremidler: studier / studieteknikk',
                originalLabel: 'Læremidler: studier / studieteknikk',
                note: 'Brukes for tverrfaglige læremidler som handler om det å studere, studieteknikk og studiekompetanse',
              },
            ],
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 'J',
    label: 'Samfunn og samfunnsvitenskap',
    originalLabel: 'Samfunn og samfunnsvitenskap',
    alternativeLabels: [],
    note: 'Bruk J*-koder både for akademiske utgivelser og noen utgivelser for allmennmarkedet, men se også V*-koder for praktisk tilnærming til mange emner. Alle J*-koder kan kombineres med andre emnekategorier og kvalifikatorer der det er hensiktsmessig. Bruk de J*-kodene som er mest passende, siden mange samfunnsfaglige emner er brede og kan ha mange alternative termer eller navn',
    related: [],
    children: [
      {
        id: 'JB',
        label: 'Samfunn og kultur',
        originalLabel: 'Samfunn og kultur',
        alternativeLabels: [],
        note: 'Bruk JB*-koder for både generelle og akademiske bøker. Brukes med JH*-koder der det er hensiktsmessig. Se også VFV* <a href="/thema/nb/VFV">Familie og relasjoner</a> og dens underkategorier',
        related: [
          {
            id: 'VFV',
            label: 'Familie og relasjoner',
            originalLabel: 'Familie og relasjoner',
            note: 'Brukes for bøker som tar for seg ulike typer relasjoner i familier eller sosiale grupper, som ikke er dekket av andre koder, eller som har råd til ulike typer familier, inkludert utvidede, bonus-, dysfunksjonelle familier, relasjoner mellom søsken eller andre slektninger. Se også JBFW <a href="/thema/nb/JBFW">Sex og seksualitet: sosiale aspekter</a>, JHBK <a href="/thema/nb/JHBK">Sosiologi: familie og relasjoner</a>, WQY <a href="/thema/nb/WQY">Familiehistorie og slektsgransking</a>',
          },
        ],
        children: [
          {
            id: 'JBC',
            label: 'Kultur- og medievitenskap',
            originalLabel: 'Kultur- og medievitenskap',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'JBCC',
                label: 'Kulturstudier',
                originalLabel: 'Kulturstudier',
                alternativeLabels: [],
                note: 'Her: sammenlignende kulturstudier, studier av kulturell identitet, kulturarv og samtidskultur i samfunnet. Brukes med kvalifikatorer for sted, språk eller 5P* for å spesifisere region(er) eller land, språkgrupper eller grupper av personer',
                related: [],
                children: [
                  {
                    id: 'JBCC1',
                    label: 'Populærkultur',
                    originalLabel: 'Populærkultur',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBCC2',
                    label: 'Materiell kultur',
                    originalLabel: 'Materiell kultur',
                    alternativeLabels: [],
                    note: 'Brukes for tverrfaglig behandling av forholdet mellom mennesker og deres eiendeler, gjenstander og objekters rolle i samfunnet, objekters framstilling, historie, bevaring og fortolkning',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBCC3',
                    label: 'Mote og samfunn',
                    originalLabel: 'Kulturstudier: klær og samfunn',
                    alternativeLabels: [],
                    note: 'Her: framstilling av syn på skjønnhet, kroppsbilde og hvordan mennesker kler seg i ulike kulturer, bøker som tar for seg ulike aspekter ved klær, kostymer og moter i ulike samfunn eller kulturer, eller bøker som ser på hvordan klær brukes for å kommunisere sosiale roller og sosial status, crossdressing i ulike kulturer osv. Brukes med NHTB for historisk synsvinkel eller AKT*-koder der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBCC4',
                    label: 'Mat og samfunn',
                    originalLabel: 'Kulturstudier: mat og samfunn',
                    alternativeLabels: ['gastronomi'],
                    note: 'Brukes for bøker som tar for seg tradisjoner, skikker, ritualer, seremonier, tabuer knyttet til mat og drikke i ulike kulturer, og mat og drikke som immateriell kulturarv. Brukes med NHTB for historisk synsvinkel og med WB*-koder der det er hensiktsmessig. Se også WB <a href="/thema/nb/WB">Mat og drikke</a>',
                    related: [
                      {
                        id: 'WB',
                        label: 'Mat og drikke',
                        originalLabel: 'Mat og drikke',
                        note: 'Her: gastronomi, generelle utgivelser om mat, matlaging og drikke, journalistikk, kritikk, essay om mat og drikke. Brukes for generelle tekster om mat, matkultur, spising, måltider etc., og som ikke er oppskrifter eller kokebøker. Brukes med DNC for memoarer, med DNP for matjournalistikk, med WTL for reiselitteratur som handler om mat, med WTHR for restaurantanmeldelser. Bruk WB*-koder med NHTB for mat, matlaging og drikke i et historisk perspektiv, eller med JBCC4 for kulturstudier innen emnet. Se også JBCC4 <a href="/thema/nb/JBCC4">Kulturstudier: mat og samfunn</a>, TTVC2 <a href="/thema/nb/TTVC2">Catering og matlaging: ferdigheter og bransjer</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'JBCC6',
                    label: 'Skikker og tradisjoner',
                    originalLabel: 'Kulturstudier: skikker og tradisjoner',
                    alternativeLabels: [],
                    note: 'Her: tradisjonelle ritualer og seremonier, kulturelle tabuer og overtro, oppførsel, verdier og normer slik disse er definert av samfunnet, institusjoner, tradisjoner og kultursyn, studier av immateriell kulturarv, tradisjonelle kulturelle uttrykk, etc. Se også JBGB <a href="/thema/nb/JBGB">Folklore, myter og legender</a>',
                    related: [
                      {
                        id: 'JBGB',
                        label: 'Folklore, myter og legender',
                        originalLabel: 'Folklore, myter og legender',
                        note: 'Brukes for faglitteratur som tar for seg tradisjonelle fortellinger eller muntlig overbrakt folklore; folkloristikk, studier av folkereligioner og tradisjoner, studier av urbane og nyere myter og legender, myters opprinnelse, eventyr, løgnhistorier, overnaturlige fortellinger, overnaturlige vesener, overtro etc., utgivelser som tar for seg opprinnelsen til fortellinger om Atlantis, den hellige gral, Bermudatriangelet etc. Foretrekk QR*-koder for spirituelle eller religiøse aspekter ved mytologi eller VX*-koder for populærvitenskapelig tilnærming eller FN*-koder for selve historiene. Se også FN <a href="/thema/nb/FN">Tradisjonelle fortellinger, myter og eventyr</a>, QRS* <a href="/thema/nb/QRS">Oldtidens religioner og mytologier</a> og dens underkategorier, VXQM* <a href="/thema/nb/VXQM">Mytiske / overnaturlige vesen</a> og dens underkategorier',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'JBCC7',
                    label: 'Tverrkulturelle / interkulturelle studier og emner',
                    originalLabel:
                      'Tverrkulturelle / interkulturelle studier og emner',
                    alternativeLabels: ['kulturforskjeller'],
                    note: 'Her: tverrkulturelle møter, utveksling og kulturkonflikter, interkulturelle forhold. Brukes for bøker som tar for seg møter mellom ulike kulturer, inkludert konflikter, negative eller positive utvekslinger, både på et personlig og et samfunnsmessig nivå. Brukes med NH*-koder for historiske emner',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBCC8',
                    label: 'Kulturpolitikk og -debatter',
                    originalLabel: 'Kulturpolitikk og -debatter',
                    alternativeLabels: [],
                    note: 'Brukes for aktuelle debatter, politikk, ideer, og kontroverser rundt kulturen og kulturarvens rolle i samfunnet',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBCC9',
                    label: 'Idéhistorie',
                    originalLabel: 'Idéhistorie',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som ser på hvordan idéer har blitt uttrykt, bevart og endret over tid. Brukes med andre J*- eller QD*-koder der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'JBCT',
                label: 'Medievitenskap',
                originalLabel: 'Medievitenskap',
                alternativeLabels: [],
                note: 'Se også GTC <a href="/thema/nb/GTC">Kommunikasjonsstudier</a>',
                related: [
                  {
                    id: 'GTC',
                    label: 'Kommunikasjonsstudier',
                    originalLabel: 'Kommunikasjonsstudier',
                    note: 'Brukes for lærebøker innen kommunikasjon og for verk som dekker en rekke aspekter ved kommunikasjon - språklige, sosiale, industrielle, teknologiske etc. Se også JBCT* <a href="/thema/nb/JBCT">Medievitenskap</a> og dens underkategorier',
                  },
                ],
                children: [
                  {
                    id: 'JBCT1',
                    label: 'Internett, digitale medier og samfunn',
                    originalLabel:
                      'Medievitenskap: internett, digitale medier og samfunn',
                    alternativeLabels: [
                      'online',
                      'tweeting',
                      'influencere',
                      'tvitre',
                      'bloggere',
                      'falske nyheter',
                      'fake news',
                      'Facebook',
                      'Instagram',
                      'SnapChat',
                      'TikTok',
                    ],
                    note: 'Brukes for alle aspekter av nettbaserte, digitale og sosiale medier, inkludert blogging, tvitring, påvirkere, spredning av korrekt og falsk informasjon, vaner og oppførsel i tilknytning til digitale medier. Se også ATN <a href="/thema/nb/ATN">Internett og digitale medier: kunst og forestillinger</a>',
                    related: [
                      {
                        id: 'ATN',
                        label:
                          'Internett og digitale medier: kunst og forestillinger',
                        originalLabel:
                          'Internett og digitale medier: kunst og forestillinger',
                        note: 'Her: Alle aspekter ved internett som mediekanal, spesielt for materiale som har oppstått på internett; internettkjendiser, bloggere og blogging, video bloggere (vlogger), podkast etc. Se også UD* <a href="/thema/nb/UD">Digital livsstil og nettsamfunn</a> og dens underkategorier, KNTV <a href="/thema/nb/KNTV">Data- og TV-spillbransjen</a>, KNTX <a href="/thema/nb/KNTX">IT-bransjen</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'JBCT2',
                    label: 'TV og samfunn',
                    originalLabel: 'Medievitenskap: TV og samfunn',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som handler både om tradisjonelle og nye måter å konsumere underholdning på skjerm på, inkludert strømming og on demand-tjenester, hvilken innvirkning dette har på kultur, populærkultur etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBCT3',
                    label: 'Reklame og samfunn',
                    originalLabel: 'Medievitenskap: reklame og samfunn',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBCT4',
                    label: 'Journalistikk',
                    originalLabel: 'Medievitenskap: journalistikk',
                    alternativeLabels: ['Journalister'],
                    note: 'Se også KNTP <a href="/thema/nb/KNTP">Forlagsbransje og journalistikk</a>',
                    related: [
                      {
                        id: 'KNTP',
                        label: 'Forlagsbransje og journalistikk',
                        originalLabel: 'Forlagsbransje og journalistikk',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'JBCT5',
                    label: 'Desinformasjon og feilinformasjon',
                    originalLabel: 'Desinformasjon og feilinformasjon',
                    alternativeLabels: ['fake news'],
                    note: 'Her: propaganda, falske nyheter, manipulerte eller falske bilder og videoer. Brukes for målbevisst bruk av falsk eller feil informasjon, manipulasjon av tanker og handlinger ved bruk av informasjon, spredning av villedende og ikke-faktabasert informasjon. Brukes med JBCT1 for bøker om digitale plattformer eller sosiale mediers rolle, med JBGX for desinformasjon og feilinformasjon knyttet til konspirasjonsteorier. Se også KJST <a href="/thema/nb/KJST">Grønnvasking og andre former for villedende markedsføring</a>, JBGX <a href="/thema/nb/JBGX">Konspirasjonsteorier</a>',
                    related: [
                      {
                        id: 'JBGX',
                        label: 'Konspirasjonsteorier',
                        originalLabel: 'Konspirasjonsteorier',
                        note: 'Her: konspirasjonsteorier, svindel, bløff etc. Se også JBCT5 <a href="/thema/nb/JBCT5">Desinformasjon og feilinformasjon</a>',
                      },
                      {
                        id: 'KJST',
                        label:
                          'Grønnvasking og andre former for villedende markedsføring',
                        originalLabel:
                          'Grønnvasking og andre former for villedende markedsføring',
                        note: 'Brukes for bøker, ofte kritiske, som ser på hvordan bedrifter, organisasjoner, politiske partier, kjendiser etc. bruker årsaker eller temaer knyttet til sosial rettferdighet til markedsføringsformål, PR-formål eller bedrag, med liten eller ingen reell forpliktelse til den faktiske årsaken bak dette. Brukes med JP*-koder for bøker om villedende markedsføring i politikk eller regjering, KN*-koder for bøker som tar for seg ulike industrisektorer osv. Se også JBCT5 <a href="/thema/nb/JBCT5">Desinformasjon og feilinformasjon</a>',
                      },
                    ],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'JBF',
            label: 'Sosiale og etiske spørsmål',
            originalLabel: 'Sosiale og etiske spørsmål',
            alternativeLabels: [],
            note: 'Se også PDR <a href="/thema/nb/PDR">Vitenskapens og teknologiens påvirkning på samfunnet</a>, QDTQ <a href="/thema/nb/QDTQ">Etikk og moralfilosofi</a>, RNT <a href="/thema/nb/RNT">Sosiale konsekvenser av miljøproblemer</a>',
            related: [
              {
                id: 'PDR',
                label: 'Vitenskapens og teknologiens påvirkning på samfunnet',
                originalLabel:
                  'Vitenskapens og teknologiens påvirkning på samfunnet',
                note: 'Brukes for bøker som tar for seg påvirkning en vitenskap eller teknologi har hatt eller kan tenkes å ha på samfunnet. Brukes med NHTB for sosialhistorie eller andre P*, T* eller J*-koder der det er hensiktsmessig. Se også JBFV5 <a href="/thema/nb/JBFV5">Vitenskapelig, teknologisk og medisinsk utvikling</a>, UBJ <a href="/thema/nb/UBJ">Etiske og sosiale aspekter ved digital- og informasjonsteknologi</a>',
              },
              {
                id: 'QDTQ',
                label: 'Etikk og moralfilosofi',
                originalLabel: 'Etikk og moralfilosofi',
                note: 'Her: aksiologi, normativ etikk, anvendt etikk, rett og gal oppførsel, deontologi, det gode og ondes problem, utilitarisme, teologisk etikk. Se også JBFV <a href="/thema/nb/JBFV">Etiske spørsmål</a>, KJG <a href="/thema/nb/KJG">Etikk og sosialt ansvar i næringslivet</a>, LATC <a href="/thema/nb/LATC">Rettsetikk og yrkesutøvelse</a>, MBDC <a href="/thema/nb/MBDC">Medisinsk etikk og yrkesutøvelse</a>, PSAD <a href="/thema/nb/PSAD">Bioetikk</a>',
              },
              {
                id: 'RNT',
                label: 'Sosiale konsekvenser av miljøproblemer',
                originalLabel: 'Sosiale konsekvenser av miljøproblemer',
                note: 'Her: bøker om konsekvenser av miljøproblemer for enkeltpersoner og samfunnet (som f.eks. ekstremvær, tørke, hetebølger, branner, flom, havnivå som stiger, klimaendringer, forurensning, tap av biologisk mangfold) og innvirkning på personer og samfunnet (som migrasjon, helse, jorderosjon, tap at matjord, matsikkerhet, sult, vannsikkerhet, fattigdom, usikkerhet, økonomisk ulikhet, sosial uro, konflikter, hjemløshet, ødelagt infrastruktur etc.)',
              },
            ],
            children: [
              {
                id: 'JBFA',
                label: 'Sosial diskriminering og sosial rettferdighet',
                originalLabel: 'Sosial diskriminering og sosial rettferdighet',
                alternativeLabels: [
                  'fordommer',
                  'inkludering',
                  'ekskludering',
                  'ullikhet',
                  'fremmedfrykt',
                  'kjønnsdiskriminering',
                  'homofobi',
                  'islamofobi',
                ],
                note: 'Her: sosial ekskludering, sosial marginalisering, mangfoldsstudier, hatytringer eller forbrytelser. Brukes for bøker om ulike typer diskriminering, om likestilling og mangel på dette, om fordommer ut fra personers identitet og også om tiltak som gjøres for å adressere dette, som politikk for å bygge opp under mangfold og inkludering, positiv diskriminering, bøker om ubevisst partiskhet. Brukes med andre JB*-kategorier eller andre emnekoder og kvalifikatorer for å spesifisere ulike typer av diskriminering, dvs. med JBSF*-koder for sexisme eller diskriminering på grunn av kjønn, QRAM9 pluss 5PG*-koder for diskriminering på grunn av religion, JBSJ pluss 5PS*-koder for diskriminering på grunn av seksuell legning etc.',
                related: [],
                children: [
                  {
                    id: 'JBFA1',
                    label: 'Rasisme og rasediskriminering / antirasisme',
                    originalLabel:
                      'Rasisme og rasediskriminering / antirasisme',
                    alternativeLabels: [
                      'fremmedfrykt',
                      'antisemittisme',
                      'ulikhet',
                    ],
                    note: 'Her: institusjonell rasisme, fremmedfrykt, raseskille. Brukes for bøker om diskriminering og fordommer basert på hudfarge eller etnisk opphav, eller fordommer som går på at noen er fra et annet land eller utenfor din egen krets, samt tiltak som gjøres for å adressere rasisme',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'JBFB',
                label: 'Integrering',
                originalLabel: 'Integrering',
                alternativeLabels: [
                  'inkludering',
                  'ekskludering',
                  'mangfold',
                  'assimilering',
                  'innvandrere',
                  'flyktninger',
                ],

                related: [],
                children: [],
              },
              {
                id: 'JBFC',
                label: 'Fattigdom og utrygghet',
                originalLabel: 'Fattigdom og utrygghet',
                alternativeLabels: [],
                note: 'Brukes for bøker om økonomisk vanskeligstilte, bøker om matutdeling, arbeidsledighet eller arbeidsforhold når disse er knyttet til fattigdom, prekariatet, økonomisk usikkerhet, undersysselsetting, kaotisk livsstil, feilernæring som følge av fattigdom, fattigdom selv om man har arbeid etc.',
                related: [],
                children: [],
              },
              {
                id: 'JBFD',
                label: 'Boligspørsmål og hjemløshet',
                originalLabel: 'Boligspørsmål og hjemløshet',
                alternativeLabels: [],
                note: 'Her: hjemløse, husløse, tiltak som gjøres for å skaffe boliger, kostnader knyttet til det å skaffe seg bolig, mangel på steder å bo etc.',
                related: [],
                children: [],
              },
              {
                id: 'JBFF',
                label: 'Sosiale konsekvenser av katastrofer og ulykker',
                originalLabel: 'Sosiale konsekvenser av katastrofer og ulykker',
                alternativeLabels: ['korona', 'koronavirus', 'covid'],
                note: 'Brukes for bøker som ser på hvordan naturskapte og menneskeskapte katastrofer og ulykker virker inn på samfunnet og menneskers liv; inkludert ting som: pest, epidemier, pandemier, hungersnød, vulkanutbrudd, jordskjelv, ekstremvær, branner, eksplosjoner, osv. Brukes med andre koder der det er hensiktsmessig, med VSY for titler som handler om å forberede seg på nødsituasjoner, f. eks. hva man skal gjøre ved tsunami eller jordskjelv osv. Se også RNR <a href="/thema/nb/RNR">Naturkatastrofer</a>',
                related: [
                  {
                    id: 'RNR',
                    label: 'Naturkatastrofer',
                    originalLabel: 'Naturkatastrofer',
                    note: 'Her: jordskjelv, vulkanutbrudd, flom, jordras, orkaner, sykloner, tornadoer, tsunamier, tørke, skogbranner etc. Se også JBFF <a href="/thema/nb/JBFF">Sosiale konsekvenser av katastrofer og ulykker</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'JBFG',
                label: 'Flyktninger og politisk asyl',
                originalLabel: 'Flyktninger og politisk asyl',
                alternativeLabels: ['tilfluktssted'],
                note: 'Her: asylsøkere, asyl. Brukes med andre koder som for eksempel JBFA for diskriminering, JBFD for boligspørsmål, NHTB for sosialhistorie, FX for romaner etc.',
                related: [],
                children: [
                  {
                    id: 'JBFG1',
                    label: 'Flukthjelp',
                    originalLabel: 'Flukthjelp',
                    alternativeLabels: [
                      'den underjordiske jernbanen',
                      'réseau comète',
                      'comet line',
                      'Fluchthilfe',
                    ],
                    note: 'Brukes for titler om å hjelpe flyktninger med å flykte fra undertrykkelse, slaveri, konflikter etc. Brukes med NH*-koder og kvalifikatorer for sted og tidsperiode der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'JBFH',
                label: 'Migrasjon, immigrasjon og emigrasjon',
                originalLabel: 'Migrasjon, immigrasjon og emigrasjon',
                alternativeLabels: [
                  'innvandrere',
                  'utvandrere',
                  'migranter',
                  'immigranter',
                  'emigranter',
                ],
                note: 'Brukes for bøker om diasporaer, eksil, nybyggere eller migranter, eller andre emner knyttet til migrasjon. Brukes med andre koder som for eksempel JBFA for diskriminering, JBFD for boligspørsmål, NHTB for sosialhistorie, FX for romaner etc.',
                related: [],
                children: [],
              },
              {
                id: 'JBFJ',
                label: 'Menneskehandel',
                originalLabel: 'Menneskehandel',
                alternativeLabels: ['trafficking', 'menneskesmugling'],
                note: 'Her: tvangsarbeid, moderne slaveri, barnearbeid, tvungen prostitusjon. Se også NHTS <a href="/thema/nb/NHTS">Slaveri og avskaffelse av slaveri</a>',
                related: [
                  {
                    id: 'NHTS',
                    label: 'Slaveri og avskaffelse av slaveri',
                    originalLabel: 'Slaveri og avskaffelse av slaveri',
                    note: 'Brukes om bøker som handler om historiske aspekter ved slaveri i ulike samfunn, kulturer eller regioner',
                  },
                ],
                children: [],
              },
              {
                id: 'JBFK',
                label: 'Vold og mishandling i samfunnet',
                originalLabel: 'Vold og mishandling i samfunnet',
                alternativeLabels: [
                  'tortur',
                  'æresdrap',
                  'personlig sikkerhet',
                  'usikkerhet',
                  'hooligans',
                  'fotballpøbler',
                ],
                note: 'Her: mishandling eller omsorgssvikt, vold på grunn av kulturelle tradisjoner, vold mot grupper eller individer, institusjonell vold, voldskriminalitet etc. Brukes for bøker om oppfattelsen av vold og usikkerhet i samfunnet, samt bøker om faktisk vold, misbruk og omsorgssvikt. Se også JKV <a href="/thema/nb/JKV">Kriminalitet og kriminologi</a>',
                related: [
                  {
                    id: 'JKV',
                    label: 'Kriminalitet og kriminologi',
                    originalLabel: 'Kriminalitet og kriminologi',
                    note: 'Se også DNXC <a href="/thema/nb/DNXC">Historier fra virkeligheten: forbrytelser</a>',
                  },
                ],
                children: [
                  {
                    id: 'JBFK1',
                    label: 'Barnemishandling',
                    originalLabel: 'Barnemishandling',
                    alternativeLabels: [],
                    note: 'Fysisk, seksuell og psykologisk mishandling av barn. Her: incest',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBFK2',
                    label: 'Seksuell mishandling og trakassering',
                    originalLabel: 'Seksuell mishandling og trakassering',
                    alternativeLabels: ['trakassering'],
                    note: 'Her: seksualisert vold, voldtekt, overfall. Se også JKVN <a href="/thema/nb/JKVN">Voldskriminalitet</a>',
                    related: [
                      {
                        id: 'JKVN',
                        label: 'Voldskriminalitet',
                        originalLabel: 'Voldskriminalitet',
                        note: 'Her: mord, drap, kidnapping, tortur, overfall, æresdrap, voldtekt, seksualisert vold etc. Brukes for utgivelser som tar for seg ulike aspekter ved voldskriminalitet. Brukes med DNXC*-koder for journalistiske beskrivelser av faktiske hendelser',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'JBFK3',
                    label: 'Vold i nære relasjoner',
                    originalLabel: 'Vold i nære relasjoner',
                    alternativeLabels: ['vold i ekteskapet', 'omsorgssvikt'],
                    note: 'Her: vold i hjemmet, familievold, partnervold, tvang, psykologisk manipulasjon og mottiltak',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBFK4',
                    label: 'Mobbing og trakassering',
                    originalLabel: 'Mobbing og trakassering',
                    alternativeLabels: ['stalking', 'hersketeknikker'],
                    note: 'Her: trolling i sosiale medier, digital mobbing, trusler, tvang, forfølgelse og truende oppførsel. Brukes med JBCT*-koder når tematikken er knyttet til ulike typer medier, KNXC eller KJMV2 for trakassering på arbeidsplassen',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'JBFL',
                label: 'Kontroll, personvern og sikkerhet',
                originalLabel: 'Kontroll, personvern og sikkerhet',
                alternativeLabels: [],
                note: 'Brukes for personvern, bruk av data, myndighetenes og store selskapers rolle, sikkerhet, anti-terrorisme, individets rettigheter satt opp mot statens rettigheter, overvåkning, offentlig sikkerhet, sosial kontroll og tvang. Se også JPV <a href="/thema/nb/JPV">Politisk kontroll og frihet</a>',
                related: [
                  {
                    id: 'JPV',
                    label: 'Politisk kontroll og frihet',
                    originalLabel: 'Politisk kontroll og frihet',
                    note: 'Her: politisk propaganda',
                  },
                ],
                children: [],
              },
              {
                id: 'JBFM',
                label: 'Funksjonsnedsettelser',
                originalLabel: 'Funksjonsnedsettelser: sosiale aspekter',
                alternativeLabels: [
                  'funksjonshemmede',
                  'psykisk utviklingshemmede',
                  'funksjonshemminger',
                  'funksjonshemninger',
                ],
                note: 'Brukes for bøker som tar for seg sosiale hindringer en person med funksjonsnedsettelser kan møte, måten samfunnet behandler personer med nedsatt funksjonsevne på, hvilken innvirkning politikken har etc. Brukes med 5PM*-kvalifikatorer der det er hensiktsmessig og andre emnekoder som for eksempel JBFA for diskriminering, JBFD for boligspørsmål, JKS* for velferd, NHTB for sosialhistorie, FX for romaner etc.',
                related: [],
                children: [],
              },
              {
                id: 'JBFN',
                label: 'Helse, sykdom og avhengighet',
                originalLabel: 'Helse, sykdom og avhengighet: sosiale aspekter',
                alternativeLabels: ['spillavhengighet'],
                note: 'Brukes for bøker om den innvirkning helsepolitikk har eller ulike sykdommer eller avhengighet; oppførsel eller relasjoner i tilknytning til ulike typer avhengighet, sykdommer eller helsespørsmål. Brukes med MBP for å indikere sosiale aspekter ved helsesystemer.',
                related: [],
                children: [
                  {
                    id: 'JBFN2',
                    label: 'Rusmidler og alkohol',
                    originalLabel: 'Rusmidler og alkohol: sosiale aspekter',
                    alternativeLabels: [],
                    note: 'Her: narkotika- eller alkoholavhengighet, avhengighet av opiater/opioider, rusmisbruk. Brukes for bøker om hvilken innvirkning rusmisbruk har på samfunnet, grupper eller individer, rusmiddelpolitikk, samfunnsmessige holdninger til stoffer som cannabis, drikkekultur, rehabilitering, vold og kriminalitet knyttet til narkotika og alkohol etc.',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'JBFQ',
                label: 'Sosial mobilitet',
                originalLabel: 'Sosial mobilitet',
                alternativeLabels: ['klassesystemer', 'sosial status'],

                related: [],
                children: [],
              },
              {
                id: 'JBFS',
                label: 'Konsumerisme',
                originalLabel: 'Konsumerisme',
                alternativeLabels: ['shopping'],
                note: 'Her: forbrukersamfunn, samspillet mellom forbrukeren og samfunnet/markedet i en kapitalistisk markedsøkonomi',
                related: [],
                children: [],
              },
              {
                id: 'JBFU',
                label: 'Dyr og samfunn',
                originalLabel: 'Dyr og samfunn',
                alternativeLabels: ['veganisme'],
                note: 'Her: dyrerettigheter, viviseksjon, jakt, dyrevelferd, bruk av dyr som mat, ressurser, råvarer, menneskets innvirkning på dyrenes verden, handel med dyr etc., behandlet som et problem',
                related: [],
                children: [],
              },
              {
                id: 'JBFV',
                label: 'Etiske spørsmål',
                originalLabel: 'Etiske spørsmål',
                alternativeLabels: [],
                note: 'Brukes med andre kategorier for bøker som behandler et tema fra en etisk synsvinkel. Se også PSAD <a href="/thema/nb/PSAD">Bioetikk</a>, QDTQ <a href="/thema/nb/QDTQ">Etikk og moralfilosofi</a>',
                related: [
                  {
                    id: 'PSAD',
                    label: 'Bioetikk',
                    originalLabel: 'Bioetikk',
                    note: 'Her: etiske spørsmål knyttet til ny utvikling innen biologi og medisin. Se også JBFV <a href="/thema/nb/JBFV">Etiske spørsmål</a>, MBDC <a href="/thema/nb/MBDC">Medisinsk etikk og yrkesutøvelse</a>',
                  },
                  {
                    id: 'QDTQ',
                    label: 'Etikk og moralfilosofi',
                    originalLabel: 'Etikk og moralfilosofi',
                    note: 'Her: aksiologi, normativ etikk, anvendt etikk, rett og gal oppførsel, deontologi, det gode og ondes problem, utilitarisme, teologisk etikk. Se også JBFV <a href="/thema/nb/JBFV">Etiske spørsmål</a>, KJG <a href="/thema/nb/KJG">Etikk og sosialt ansvar i næringslivet</a>, LATC <a href="/thema/nb/LATC">Rettsetikk og yrkesutøvelse</a>, MBDC <a href="/thema/nb/MBDC">Medisinsk etikk og yrkesutøvelse</a>, PSAD <a href="/thema/nb/PSAD">Bioetikk</a>',
                  },
                ],
                children: [
                  {
                    id: 'JBFV1',
                    label: 'Abort og prevensjon',
                    originalLabel: 'Abort og prevensjon',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBFV2',
                    label: 'Dødsstraff',
                    originalLabel: 'Dødsstraff',
                    alternativeLabels: [],
                    note: 'Class here: the death penalty',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBFV3',
                    label: 'Sensur',
                    originalLabel: 'Sensur',
                    alternativeLabels: [],
                    note: 'Her: debatter om ytringsfrihet, sensur vs. sikkerhet etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBFV4',
                    label: 'Dødshjelp og retten til å dø',
                    originalLabel: 'Dødshjelp og retten til å dø',
                    alternativeLabels: [],
                    note: 'Her: assistert selvmord. Brukes med LNT for juridiske aspekter',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBFV5',
                    label: 'Vitenskapelig, teknologisk og medisinsk utvikling',
                    originalLabel:
                      'Vitenskapelig, teknologisk og medisinsk utvikling',
                    alternativeLabels: ['velferdsteknologi'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'JBFW',
                label: 'Sex og seksualitet',
                originalLabel: 'Sex og seksualitet: sosiale aspekter',
                alternativeLabels: [],
                note: 'Her: generelle bøker om sex og samfunn, pornografi og sexindustrien, sexarbeid og prostitusjon, eller bøker som tar for seg seksualitet i bestemte samfunn, kulturelle eller samfunnsmessige holdninger eller normer knyttet til seksualitet, undertrykkelse og kriminalisering av seksualitet, holdninger til prevensjon og seksualopplysning, etc. Se også JMU <a href="/thema/nb/JMU">Psykologi: seksuell adferd</a>, VFVC <a href="/thema/nb/VFVC">Sex og seksualitet</a>',
                related: [
                  {
                    id: 'JMU',
                    label: 'Psykologi: seksuell adferd',
                    originalLabel: 'Psykologi: seksuell adferd',
                    note: 'Se også VFVC <a href="/thema/nb/VFVC">Sex og seksualitet</a>, JBFW <a href="/thema/nb/JBFW">Sex og seksualitet: sosiale aspekter</a>',
                  },
                  {
                    id: 'VFVC',
                    label: 'Sex og seksualitet',
                    originalLabel: 'Sex og seksualitet',
                    note: 'Her: Kama Sutra, tips til et godt sexliv. Brukes for bøker som handler om eller forklarer ulike sider ved sex og seksualitet, inkludert, men ikke begrenset til samtykke, seksuelle relasjoner, tilfeldig sex, pornografi, fysisk intimitet, seksuell helse, seksuell nytelse, teknikker og råd for å få et bedre sexliv, bruk av afrodisiakum, orgasmer, masturbering, sexråd til eldre, impotens, bøker om de menneskelige kjønnsorganene og forplantningssystemet. Se også JBFW <a href="/thema/nb/JBFW">Sex og seksualitet: sosiale aspekter</a>, JMU <a href="/thema/nb/JMU">Psykologi: seksuell adferd</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'JBFX',
                label: 'Sosiale holdninger',
                originalLabel: 'Sosiale holdninger',
                alternativeLabels: [],
                note: 'Her: politisk korrekthet. Brukes for bøker om sosiale, politiske og moralske holdninger i samfunnet. Brukes med andre kategorier for holdninger om spesielle temaer eller grupper',
                related: [],
                children: [],
              },
              {
                id: 'JBFZ',
                label: 'Fremtidsprognoser, fremtidsstudier',
                originalLabel: 'Fremtidsprognoser, fremtidsstudier',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JBG',
            label: 'Folketro og kontroversiell viten',
            originalLabel: 'Folketro og kontroversiell viten',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'JBGB',
                label: 'Folklore, myter og legender',
                originalLabel: 'Folklore, myter og legender',
                alternativeLabels: [],
                note: 'Brukes for faglitteratur som tar for seg tradisjonelle fortellinger eller muntlig overbrakt folklore; folkloristikk, studier av folkereligioner og tradisjoner, studier av urbane og nyere myter og legender, myters opprinnelse, eventyr, løgnhistorier, overnaturlige fortellinger, overnaturlige vesener, overtro etc., utgivelser som tar for seg opprinnelsen til fortellinger om Atlantis, den hellige gral, Bermudatriangelet etc. Foretrekk QR*-koder for spirituelle eller religiøse aspekter ved mytologi eller VX*-koder for populærvitenskapelig tilnærming eller FN*-koder for selve historiene. Se også FN <a href="/thema/nb/FN">Tradisjonelle fortellinger, myter og eventyr</a>, QRS* <a href="/thema/nb/QRS">Oldtidens religioner og mytologier</a> og dens underkategorier, VXQM* <a href="/thema/nb/VXQM">Mytiske / overnaturlige vesen</a> og dens underkategorier',
                related: [
                  {
                    id: 'FN',
                    label: 'Tradisjonelle fortellinger, myter og eventyr',
                    originalLabel:
                      'Tradisjonelle fortellinger, myter og eventyr',
                    note: 'Her: tradisjonelle lokale folkeeventyr, skrøner, fabler, eller enhver form for tradisjonell historie fortalt eller gjenfortalt som fiksjon. Brukes for moderne gjenfortellinger i romanform eller samlinger av tradisjonelle fortellinger, først og fremst ment for voksne, eller skjønnlitterære bøker som er inspirert av disse tradisjonelle fortellingene eller av folklore. Se også DBS* <a href="/thema/nb/DBS">Sagaer og epos</a> og dens underkategorier, QRS* <a href="/thema/nb/QRS">Oldtidens religioner og mytologier</a> og dens underkategorier, JBGB <a href="/thema/nb/JBGB">Folklore, myter og legender</a>, YFJ <a href="/thema/nb/YFJ">Skjønnlitteratur for barn og ungdom: tradisjonelle historier</a>, YNRU <a href="/thema/nb/YNRU">Barn og ungdom: oldtidens religioner, mytologi og legender</a>',
                  },
                  {
                    id: 'QRS',
                    label: 'Oldtidens religioner og mytologier',
                    originalLabel: 'Oldtidens religioner og mytologier',
                    note: 'Her: paganisme, komparativ mytologi, generelle studier av mytologi og studier av den enkelte, regionale mytologi hvor denne ikke har sin egen kode. Bruk QRS*-koder for bøker som tar for seg disse formene for spirituell tro, deres mytologier, gudshus, religionsutøvelse, guder og ånder, skapelsesberetninger og andre fortellinger, legender, ritualer etc. Bruk QRS*-koder med kvalifikatorer for sted, spesielt 1QB* for historiske land og riker, eller med andre OR*-koder for sammenligning med nyere religioner. Brukes med FN*-koder for moderne gjenfortellinger av tradisjonelle myter fra disse religionene. Se også FN <a href="/thema/nb/FN">Tradisjonelle fortellinger, myter og eventyr</a>, JBGB <a href="/thema/nb/JBGB">Folklore, myter og legender</a>',
                  },
                  {
                    id: 'VXQM',
                    label: 'Mytiske / overnaturlige vesen',
                    originalLabel: 'Mytiske / overnaturlige vesen',
                    note: 'Her: skapninger fra myter og legender: naturånder, humanoider, monstre, kjemper, troll, golem, griffer, havfruer, alver, feer, enhjørninger, kentaurer, fauner, yetier, sjømonstre etc. Brukes for bøker for allmennmarkedet som handler om disse vesenene. Bruk VXQM* med F*-koder eller XQ* for fortellinger som handler om et eller flere av disse vesenene. Foretrekk JBGB eller QR*-koder for bøker for høyere utdanning eller profesjonsmarkedet. Se også JBGB <a href="/thema/nb/JBGB">Folklore, myter og legender</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'JBGX',
                label: 'Konspirasjonsteorier',
                originalLabel: 'Konspirasjonsteorier',
                alternativeLabels: ['kontroversiell viten'],
                note: 'Her: konspirasjonsteorier, svindel, bløff etc. Se også JBCT5 <a href="/thema/nb/JBCT5">Desinformasjon og feilinformasjon</a>',
                related: [
                  {
                    id: 'JBCT5',
                    label: 'Desinformasjon og feilinformasjon',
                    originalLabel: 'Desinformasjon og feilinformasjon',
                    note: 'Her: propaganda, falske nyheter, manipulerte eller falske bilder og videoer. Brukes for målbevisst bruk av falsk eller feil informasjon, manipulasjon av tanker og handlinger ved bruk av informasjon, spredning av villedende og ikke-faktabasert informasjon. Brukes med JBCT1 for bøker om digitale plattformer eller sosiale mediers rolle, med JBGX for desinformasjon og feilinformasjon knyttet til konspirasjonsteorier. Se også KJST <a href="/thema/nb/KJST">Grønnvasking og andre former for villedende markedsføring</a>, JBGX <a href="/thema/nb/JBGX">Konspirasjonsteorier</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'JBS',
            label: 'Sosiale grupper, samfunn og sosial identitet',
            originalLabel: 'Sosiale grupper, samfunn og sosial identitet',
            alternativeLabels: [],
            note: 'Her: generelle verk om sosiale grupper, bøker som ser på ulike aspekter ved sosiale grupper og samfunnet, eller ulike former for studier av fellesskap, eller bøker som tar for seg interseksjonalitet. Bruk alle JBS*-koder med andre koder for å angi spesielle temaer, som f.eks. JBFA for diskriminering, JP*-koder for bøker om identitetspolitikk, NHTB for sosialhistorie, FX* for romaner og kvalifikator for interesse der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'JBSA',
                label: 'Sosiale klasser',
                originalLabel: 'Sosiale klasser',
                alternativeLabels: [
                  'arbeiderklassen',
                  'overklassen',
                  'middelklassen',
                  'underklassen',
                ],
                note: 'Her: sosial lagdeling, klassekonflikter, klasseforskjeller, sosioøkonomiske grupper. Brukes for bøker som tar for seg klassespørsmål, en bestemt klasse og dens karakteristika og kjennetegn, samhandling på tvers av ulike samfunnslag, oppfatninger om og fordommer mot ulike klasser. Brukes med 5PD*-kvalifikatorer for bøker rettet mot bestemte sosioøkonomiske grupper eller status',
                related: [],
                children: [],
              },
              {
                id: 'JBSC',
                label: 'Bygdesamfunn',
                originalLabel: 'Bygdesamfunn',
                alternativeLabels: [],
                note: 'Her: små bosetninger og personer som bor på landsbygda, områder som er delvis landlige, isolerte samfunn',
                related: [],
                children: [],
              },
              {
                id: 'JBSD',
                label: 'Bysamfunn',
                originalLabel: 'Bysamfunn',
                alternativeLabels: ['gettoer', 'bykjerner'],
                note: 'Her: indre by, drabantbyer, byboere, forsteder. Brukes for bøker om bymessige eller delvis bymessige bosetninger og tilhørende samfunn. Brukes med andre emnekoder for ulike effekter på bysamfunn eller studier av f. eks. forfall, gentrifisering, pendlere, byfornyelse',
                related: [],
                children: [],
              },
              {
                id: 'JBSF',
                label: 'Kjønnsstudier og kjønnsgrupper',
                originalLabel: 'Kjønnsstudier og kjønnsgrupper',
                alternativeLabels: [],
                note: 'Brukes for bøker som tar for seg kjønnsroller eller kjønnsidentitet, kjønn basert på et individs forplantningssystem og andre sekundære kjønnskarakteristika, inkludert interseksualitet. Brukes med andre emnekoder som tar for seg kjønnstemaer innenfor bestemte områder, f. eks. med MB* for kjønnsforskjeller i medisin, JBFA for bøker om likestilling eller kjønnsbasert diskriminering, eller F*-koder for skjønnlitteratur med kjønn som hovedtema, etc.',
                related: [],
                children: [
                  {
                    id: 'JBSF1',
                    label: 'Kvinner og jenter',
                    originalLabel: 'Kjønnsstudier: kvinner og jenter',
                    alternativeLabels: [],
                    note: 'Her: kvinnestudier. Brukes sammen med andre emnekoder for bøker som handler mest om kvinner eller jenter, som med JBFA for kjønnsdiskriminering eller sexisme, NHTB for kvinnehistorie, JBFK* eller JKVN for bøker om vold eller voldsforbrytelser mot kvinner, JM* for psykologi, VF*-koder for helserelaterte emner, etc.',
                    related: [],
                    children: [
                      {
                        id: 'JBSF11',
                        label: 'Feminisme og feministisk teori',
                        originalLabel: 'Feminisme og feministisk teori',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'JBSF2',
                    label: 'Menn og gutter',
                    originalLabel: 'Kjønnsstudier: menn og gutter',
                    alternativeLabels: [],
                    note: 'Her: mannsstudier, emner i tilknytning til maskulinitet. Brukes med andre emnekoder for bøker som handler mest om menn eller gutter',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBSF3',
                    label: 'Transpersoner og kjønnsinkongruens',
                    originalLabel:
                      'Kjønnsstudier: transpersoner og kjønnsinkongruens',
                    alternativeLabels: [
                      'tvekjønnethet',
                      'transkjønnhet',
                      'transseksuelle',
                      'interseksuelle',
                    ],
                    note: 'Her: transseksuelle, transkjønnethet, ikke-binær kjønnsidentitet etc. Brukes for bøker om personer med en kjønnsidentitet som skiller seg fra det kjønnet de ble tildelt ved fødselen, eller kjønnsinkongruens i kulturer som har etablert mer enn to kjønn. Brukes med JBFA for temaer som tar for seg diskriminering og transfobi, JBFK* eller JKVN for bøker om vold eller voldsforbrytelser mot transseksuelle, VF*-koder for helserelaterte emner, og med 5PT*-kvalifikatorer hvis hensiktsmessig',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'JBSJ',
                label: 'Samfunnsfaglige studier: LHBT+',
                originalLabel: 'Samfunnsfaglige studier: LHBT+',
                alternativeLabels: [
                  'LBGTQ',
                  'panseksualitet',
                  'panseksuelle',
                  'biseksualitet',
                  'biseksuelle',
                  'aseksuelle',
                  'homofili',
                  'homofile',
                  'lesbiske',
                  'LGBTQIA',
                  'LHBTQIA',
                  'Homofile',
                  'lesbiske',
                  'LHBT',
                  'LHBTI',
                  'LHBTIQ',
                  'LHBTI+',
                  'LHBTIQ+',
                ],
                note: 'Her: homofili, studier rundt seksuelt mangfold. Brukes med andre koder, f.eks. JBFA for homofobi eller diskriminering eller NHTB for historie, eller med JBFK* eller JKVN for bøker om vold eller voldsforbrytelser mot homofile, VF*-koder for helserelaterte emner, og med 5PS*-koder der det er hensiktsmessig',
                related: [],
                children: [
                  {
                    id: 'JBSJ2',
                    label: 'LHBT+: komme ut av skapet',
                    originalLabel: 'LHBT+: komme ut av skapet',
                    alternativeLabels: [
                      'Homofile',
                      'lesbiske',
                      'LHBT',
                      'LHBTI',
                      'LHBTIQ',
                      'LHBTI+',
                      'LHBTIQ+',
                    ],
                    note: 'Brukes for bøker som handler om eller gir råd om prosessen for en LHBT+-person for å bli godtatt, personlig aksept, det å avsløre sin seksuelle orientering eller identitet for andre. Brukes med andre emnekoder og kvalifikatorer fra 5P* der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'JBSL',
                label: 'Etniske studier',
                originalLabel: 'Etniske studier',
                alternativeLabels: [],
                note: 'Brukes med andre emnekoder for tverrfaglige utgivelser og kvalifikator(er) for sted og 5PB*-koder der det er hensiktsmessig, eller med andre J* eller N*-koder for emner knyttet til f. eks. etnisk vold og etniske konflikter, multikulturalisme innenfor spesifikke områder, som migrasjon',
                related: [],
                children: [
                  {
                    id: 'JBSL1',
                    label: 'Etniske minoriteter og multikulturelle studier',
                    originalLabel:
                      'Etniske minoriteter og multikulturelle studier',
                    alternativeLabels: ['polyetnisitet'],
                    note: 'Brukes for bøker som tar for seg emner knyttet til multikulturelle samfunn og grupper. Se også JBFA <a href="/thema/nb/JBFA">Sosial diskriminering og sosial rettferdighet</a>',
                    related: [
                      {
                        id: 'JBFA',
                        label: 'Sosial diskriminering og sosial rettferdighet',
                        originalLabel:
                          'Sosial diskriminering og sosial rettferdighet',
                        note: 'Her: sosial ekskludering, sosial marginalisering, mangfoldsstudier, hatytringer eller forbrytelser. Brukes for bøker om ulike typer diskriminering, om likestilling og mangel på dette, om fordommer ut fra personers identitet og også om tiltak som gjøres for å adressere dette, som politikk for å bygge opp under mangfold og inkludering, positiv diskriminering, bøker om ubevisst partiskhet. Brukes med andre JB*-kategorier eller andre emnekoder og kvalifikatorer for å spesifisere ulike typer av diskriminering, dvs. med JBSF*-koder for sexisme eller diskriminering på grunn av kjønn, QRAM9 pluss 5PG*-koder for diskriminering på grunn av religion, JBSJ pluss 5PS*-koder for diskriminering på grunn av seksuell legning etc.',
                      },
                    ],
                    children: [
                      {
                        id: 'JBSL11',
                        label: 'Urfolk',
                        originalLabel: 'Urfolk',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'JBSL13',
                        label: 'Blandet etnisk bakgrunn',
                        originalLabel: 'Blandet etnisk bakgrunn',
                        alternativeLabels: ['multietnisk'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'JBSP',
                label: 'Aldersgrupper og generasjoner',
                originalLabel: 'Aldersgrupper og generasjoner',
                alternativeLabels: [],
                note: 'Brukes for bøker som tar for seg forskjellige generasjoner, emner og problemer knyttet til de ulike generasjonene eller forholdet mellom dem, generasjonskonflikter og -forskjeller etc.',
                related: [],
                children: [
                  {
                    id: 'JBSP1',
                    label: 'Barn',
                    originalLabel: 'Aldersgrupper: barn',
                    alternativeLabels: ['barndom', 'tweens'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBSP2',
                    label: 'Ungdom',
                    originalLabel: 'Aldersgrupper: ungdom',
                    alternativeLabels: ['tenåringer'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBSP3',
                    label: 'Voksne',
                    originalLabel: 'Aldersgrupper: voksne',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'JBSP4',
                    label: 'Eldre',
                    originalLabel: 'Aldersgrupper: eldre',
                    alternativeLabels: ['alderdom'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'JBSR',
                label: 'Religiøse grupper og samfunn',
                originalLabel: 'Sosiale grupper: religiøse grupper og samfunn',
                alternativeLabels: [],
                note: 'Tildel også 5PG*-koder og QR*-koder der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'JBSW',
                label: 'Alternative livsstiler',
                originalLabel: 'Sosiale grupper: alternative livsstiler',
                alternativeLabels: [
                  'øko-krigere',
                  'veganere',
                  'veganisme',
                  'hippier',
                  'reisende',
                  'punkere',
                  'emoer',
                  'bohemer',
                  'nudister',
                  'polyamorøse',
                  'undergrunnskulturer',
                  'outsidere',
                ],
                note: 'Brukes for livsstiler som skiller seg fra de som regnes som konvensjonelle eller ‘mainstream’ og som oppfattes som å være utenfor de kulturelle normene',
                related: [],
                children: [],
              },
              {
                id: 'JBSX',
                label: 'Hemmelige samfunn',
                originalLabel: 'Hemmelige samfunn',
                alternativeLabels: [],
                note: 'Her: frimureri. Se også QRY* <a href="/thema/nb/QRY">Alternative trossystemer</a> og dens underkategorier, FHQ <a href="/thema/nb/FHQ">Okkulte thrillere</a>',
                related: [
                  {
                    id: 'FHQ',
                    label: 'Okkulte thrillere',
                    originalLabel: 'Okkulte thrillere',
                    note: 'Brukes for thrillere hvor handlingen tar for seg gamle ritualer, hemmelig eller esoterisk kunnskap, som for eksempel den hellige gral etc.',
                  },
                  {
                    id: 'QRY',
                    label: 'Alternative trossystemer',
                    originalLabel: 'Alternative trossystemer',
                    note: 'Her: nyreligiøse bevegelser, neopaganisme, nyhedendom. Foretrekk VX* for populærvitenskapelige bøker',
                  },
                ],
                children: [],
              },
              {
                id: 'JBSY',
                label: 'Klubber og samfunn',
                originalLabel: 'Sosiale grupper: klubber og samfunn',
                alternativeLabels: [],
                note: 'Her: frivillige foreninger eller «selvvalgt samhørighet», hvordan og hvorfor folk assosierer seg med spesifikke grupper',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'JH',
        label: 'Sosiologi og antropologi',
        originalLabel: 'Sosiologi og antropologi',
        alternativeLabels: [],
        note: 'Bruk JH*-koder for lærebøker eller akademiske verk. Brukes sammen med andre J*-koder som indikerer et spesifikt emne der det er hensiktsmessig',
        related: [],
        children: [
          {
            id: 'JHB',
            label: 'Sosiologi',
            originalLabel: 'Sosiologi',
            alternativeLabels: [],
            note: 'Brukes med andre emnekategorier for bøker som behandler et emne sosiologisk, der det ikke finnes en mer spesifikk kode, f. eks. med JW*-koder for militærsosiologi, med JBSC for bøker om bygdesamfunn, etc. Se også LAQ <a href="/thema/nb/LAQ">Lov og samfunn, rettssosiologi</a>, MBS <a href="/thema/nb/MBS">Helsesosiologi</a>',
            related: [
              {
                id: 'LAQ',
                label: 'Rettssosiologi',
                originalLabel: 'Lov og samfunn, rettssosiologi',
              },
              {
                id: 'MBS',
                label: 'Helsesosiologi',
                originalLabel: 'Helsesosiologi',
                note: 'Brukes bor bøker som handler om praktisk bruk og anvendelse av sosiologi innen medisin og helsevesen, eller bøker som studerer årsaker til og konsekvenser av god eller dårlig helse',
              },
            ],
            children: [
              {
                id: 'JHBA',
                label: 'Sosialteori',
                originalLabel: 'Sosialteori',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JHBC',
                label: 'Sosial forskning og statistikk',
                originalLabel: 'Sosial forskning og statistikk',
                alternativeLabels: [],
                note: 'Se også GPS <a href="/thema/nb/GPS">Forskningsmetoder</a>',
                related: [
                  {
                    id: 'GPS',
                    label: 'Forskningsmetoder',
                    originalLabel: 'Forskningsmetoder',
                    note: 'Brukes med andre emnekoder for forskningsmetoder innen en bestemt disiplin. Se også JHBC <a href="/thema/nb/JHBC">Sosial forskning og statistikk</a>, KJT <a href="/thema/nb/KJT">Operasjonsanalyse</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'JHBD',
                label: 'Befolkning og demografi',
                originalLabel: 'Befolkning og demografi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JHBK',
                label: 'Familie og relasjoner',
                originalLabel: 'Sosiologi: familie og relasjoner',
                alternativeLabels: [],
                note: 'Her: familiesosiologi. Brukes for bøker om familiestrukturer, relasjoner innen familien, vennskap, relasjonspsykologi, sosial kontroll eller konformitet og ensretting innen grupper eller familier; medavhengighet i familier, bøker som ser på hvordan mennesker utvikler seg fysisk, følelsesmessig og sosialt innenfor konteksten av familie og samfunn. Se også JBFW <a href="/thema/nb/JBFW">Sex og seksualitet: sosiale aspekter</a>, VFV* <a href="/thema/nb/VFV">Familie og relasjoner</a> og dens underkategorier',
                related: [
                  {
                    id: 'JBFW',
                    label: 'Sex og seksualitet',
                    originalLabel: 'Sex og seksualitet: sosiale aspekter',
                    note: 'Her: generelle bøker om sex og samfunn, pornografi og sexindustrien, sexarbeid og prostitusjon, eller bøker som tar for seg seksualitet i bestemte samfunn, kulturelle eller samfunnsmessige holdninger eller normer knyttet til seksualitet, undertrykkelse og kriminalisering av seksualitet, holdninger til prevensjon og seksualopplysning, etc. Se også JMU <a href="/thema/nb/JMU">Psykologi: seksuell adferd</a>, VFVC <a href="/thema/nb/VFVC">Sex og seksualitet</a>',
                  },
                  {
                    id: 'VFV',
                    label: 'Familie og relasjoner',
                    originalLabel: 'Familie og relasjoner',
                    note: 'Brukes for bøker som tar for seg ulike typer relasjoner i familier eller sosiale grupper, som ikke er dekket av andre koder, eller som har råd til ulike typer familier, inkludert utvidede, bonus-, dysfunksjonelle familier, relasjoner mellom søsken eller andre slektninger. Se også JBFW <a href="/thema/nb/JBFW">Sex og seksualitet: sosiale aspekter</a>, JHBK <a href="/thema/nb/JHBK">Sosiologi: familie og relasjoner</a>, WQY <a href="/thema/nb/WQY">Familiehistorie og slektsgransking</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'JHBL',
                label: 'Arbeid og arbeidskraft',
                originalLabel: 'Sosiologi: arbeid og arbeidskraft',
                alternativeLabels: ['sysselsetting'],
                note: 'Her: arbeidssosiologi / arbeidsløshet',
                related: [],
                children: [],
              },
              {
                id: 'JHBS',
                label: 'Sport og fritid',
                originalLabel: 'Sosiologi: sport og fritid',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JHBZ',
                label: 'Døden',
                originalLabel: 'Sosiologi: døden',
                alternativeLabels: [],
                note: 'Brukes for bøker som undersøker forholdet mellom døden og samfunnet, holdninger og tabuer rundt dødelighet, skikker, seremonier og tradisjoner rundt begravelser, hvordan samfunnet håndterer sorg og etterlatte. Se også VFJX <a href="/thema/nb/VFJX">Takle død og sorg</a>, QRVL <a href="/thema/nb/QRVL">Religion: døden</a>, KNSZ <a href="/thema/nb/KNSZ">Begravelsesbransjen</a>',
                related: [
                  {
                    id: 'KNSZ',
                    label: 'Begravelsesbransjen',
                    originalLabel: 'Begravelsesbransjen',
                    note: 'Brukes for bøker om begravelsesbyråer og andre virksomheter som leverer tjenester knyttet til dødsfall, begravelse, kremasjon, balsamering, minnemarkeringer, kirkegårder, etc.',
                  },
                  {
                    id: 'QRVL',
                    label: 'Religion: døden',
                    originalLabel: 'Religion: døden',
                    note: 'Brukes for bøker som handler om døden og som tar for seg ulike religiøse og spirituelle holdninger til dette, inkludert seremonier i forbindelse med døden, sorg, tilbedelse av forfedre, nær-døden opplevelser etc.',
                  },
                  {
                    id: 'VFJX',
                    label: 'Takle død og sorg',
                    originalLabel: 'Takle død og sorg',
                    note: 'Her: begravelser, minnestunder og tap, råd om hvordan man kan forberede seg på livets slutt, takle dødelige sykdommer. Se også JHBZ <a href="/thema/nb/JHBZ">Sosiologi: døden</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'JHM',
            label: 'Antropologi',
            originalLabel: 'Antropologi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'JHMC',
                label: 'Sosialantropologi og kulturantropologi',
                originalLabel: 'Sosialantropologi og kulturantropologi',
                alternativeLabels: ['skikker', 'tradisjoner', 'etnologi'],
                note: 'Her: etnografi, slektskap og familie, institusjonell antropologi, sosiokulturell antropologi. Brukes med andre J*-koder for forskjellige antropologiske spesialområder. Se også JBCC* <a href="/thema/nb/JBCC">Kulturstudier</a> og dens underkategorier',
                related: [
                  {
                    id: 'JBCC',
                    label: 'Kulturstudier',
                    originalLabel: 'Kulturstudier',
                    note: 'Her: sammenlignende kulturstudier, studier av kulturell identitet, kulturarv og samtidskultur i samfunnet. Brukes med kvalifikatorer for sted, språk eller 5P* for å spesifisere region(er) eller land, språkgrupper eller grupper av personer',
                  },
                ],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'JK',
        label: 'Sosiale tjenester og velferd, kriminologi',
        originalLabel: 'Sosiale tjenester og velferd, kriminologi',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'JKS',
            label: 'Sosiale tjenester og sosial velferd',
            originalLabel: 'Sosiale tjenester og sosial velferd',
            alternativeLabels: [],
            note: 'Class here: human services, social care, social security policy',
            related: [],
            children: [
              {
                id: 'JKSB',
                label: 'Sosiale velferdsordninger',
                originalLabel: 'Sosiale velferdsordninger',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'JKSB1',
                    label: 'Barnevern',
                    originalLabel: 'Barnevern',
                    alternativeLabels: [],
                    note: 'Her: barnevernstjenester for ungdom, sosiale tjenester for sårbare barn og ungdommer. Se også LNMK <a href="/thema/nb/LNMK">Familierett: barn</a>',
                    related: [
                      {
                        id: 'LNMK',
                        label: 'Familierett: barn',
                        originalLabel: 'Familierett: barn',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'JKSF',
                label: 'Adopsjon og fosterhjem',
                originalLabel: 'Adopsjon og fosterhjem',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JKSG',
                label: 'Eldreomsorg',
                originalLabel: 'Eldreomsorg',
                alternativeLabels: [],
                note: 'Her: vold mot og mishandling av eldre, omsorgssvikt, aldershjem og sykehjem. Brukes for sosial- og helsetjenester for eldre, politikk, yrker og roller i eldreomsorg',
                related: [],
                children: [],
              },
              {
                id: 'JKSL',
                label:
                  'Omsorgs- og helsetjenester for personer med spesielle behov',
                originalLabel:
                  'Omsorgs- og helsetjenester for personer med spesielle behov',
                alternativeLabels: [
                  'lærevansker',
                  'autisme',
                  'autismespekteret',
                  'asperger syndrom',
                  'funksjonshemmede',
                  'blinde',
                  'døve',
                  'handikappede',
                  'døvhet',
                  'omsorgstjenester',
                ],
                note: 'Her: omsorgstjenester for personer med fysiske funksjonsnedsettelser, enten synlige eller usynlige, personer med fysiske eller sensoriske svekkelser, personer med nevroutviklingsforstyrrelser. Brukes for tematikk rundt omsorgstjenester, politikk og yrker og roller som hører til dette fagområdet. Brukes med kvalifikatorer fra 5PM* der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'JKSM',
                label: 'Psykisk helsevern',
                originalLabel: 'Psykisk helsevern',
                alternativeLabels: [],
                note: 'Brukes for tematikk rundt omsorgstjenester, politikk og yrker og roller som hører til dette fagområdet. Brukes med kvalifikatorer fra 5PM* der det er hensiktsmessig. Se også MBPK <a href="/thema/nb/MBPK">Psykisk helsetjeneste</a>, VFJQ <a href="/thema/nb/VFJQ">Takle psykiske problemer</a>',
                related: [
                  {
                    id: 'MBPK',
                    label: 'Psykisk helsetjeneste',
                    originalLabel: 'Psykisk helsetjeneste',
                  },
                  {
                    id: 'VFJQ',
                    label: 'Takle psykiske problemer',
                    originalLabel: 'Takle psykiske problemer',
                    note: 'Brukes for bøker som handler om eller gir råd om psykiske problemer og lidelser som ikke er dekket andre steder',
                  },
                ],
                children: [],
              },
              {
                id: 'JKSN',
                label: 'Sosialarbeid',
                originalLabel: 'Sosialarbeid',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'JKSN1',
                    label: 'Veldedighet, frivillighetsarbeid og filantropi',
                    originalLabel:
                      'Veldedighet, frivillighetsarbeid og filantropi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'JKSN2',
                    label: 'Sosialrådgivning og andre rådgivingstjenester',
                    originalLabel:
                      'Sosialrådgivning og andre rådgivingstjenester',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'JKSR',
                label: 'Nødhjelp og bistand',
                originalLabel: 'Nødhjelp og bistand',
                alternativeLabels: [],
                note: 'Her: utgivelser om utviklingshjelp og bistandsprogrammer, humanitær hjelp, katastrofehjelp. Brukes for hjelp og programmer innenlands og utenlands, både for kortsiktige og langsiktige hjelpeprogrammer etc.',
                related: [],
                children: [],
              },
              {
                id: 'JKSW',
                label: 'Nødetater og redningstjenester',
                originalLabel: 'Nødetater og redningstjenester',
                alternativeLabels: [],
                note: 'Her: førstehjelpspersonell, oppsøkende tjenester, beredskapsplanlegging, levering av nød- og redningstjenester osv.',
                related: [],
                children: [
                  {
                    id: 'JKSW1',
                    label: 'Politi- og sikkerhetstjenester',
                    originalLabel: 'Politi- og sikkerhetstjenester',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'JKSW2',
                    label: 'Brannvesen',
                    originalLabel: 'Brannvesen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'JKSW3',
                    label: 'Ambulanse- og redningstjenester',
                    originalLabel: 'Ambulanse- og redningstjenester',
                    alternativeLabels: ['ambulansearbeider'],
                    note: 'Her: luft- og sjøredningstjenester, fjellredning, ambulansepersonell, luftambulanse, etc. Brukes for utgivelser om ambulanse- og redningstjenester fra et ikke-medisinsk perspektiv. Se også MQF <a href="/thema/nb/MQF">Førstehjelp og ambulansetjenester</a>',
                    related: [
                      {
                        id: 'MQF',
                        label: 'Førstehjelp og ambulansetjenester',
                        originalLabel: 'Førstehjelp og ambulansetjenester',
                      },
                    ],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'JKV',
            label: 'Kriminalitet og kriminologi',
            originalLabel: 'Kriminalitet og kriminologi',
            alternativeLabels: [],
            note: 'Se også DNXC <a href="/thema/nb/DNXC">Historier fra virkeligheten: forbrytelser</a>',
            related: [
              {
                id: 'DNXC',
                label: 'Forbrytelser',
                originalLabel: 'Historier fra virkeligheten: forbrytelser',
                note: 'Brukes for sanne fortellinger om ekte forbrytelser, kriminelle handlinger, kriminelle, kriminelle organisasjoner. Brukes med JKV*-koder for mer detaljert beskrivelse av hva slags type kriminalitet, f.eks. JKVM for fortellinger om kriminelle gjenger, eller med JKVK for økonomisk kriminalitet, eller med DNB for biografier om kriminelle, fredløse, gangstere, etc.',
              },
            ],
            children: [
              {
                id: 'JKVC',
                label: 'Årsaker til og forebygging av kriminalitet',
                originalLabel: 'Årsaker til og forebygging av kriminalitet',
                alternativeLabels: ['antiterror'],
                note: 'Her: overvåking, tiltak mot terrorisme, hvordan beskytte befolkningen mot kriminalitet, politikk om fagområdet, teorier rundt sosial kontroll',
                related: [],
                children: [],
              },
              {
                id: 'JKVF',
                label: 'Kriminaletterforskning',
                originalLabel: 'Kriminaletterforskning',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'JKVF1',
                    label: 'Forensisk vitenskap',
                    originalLabel: 'Forensisk vitenskap',
                    alternativeLabels: [],
                    note: 'Her: kriminalteknikk, kriminallingvistikk, etc. Brukes med UY eller UX* for dataetterforskning eller digital rettsmedisin. Se også JMK <a href="/thema/nb/JMK">Kriminalpsykologi eller rettspsykiatri</a>, MKT <a href="/thema/nb/MKT">Rettsmedisin</a>',
                    related: [
                      {
                        id: 'JMK',
                        label: 'Kriminalpsykologi eller rettspsykiatri',
                        originalLabel: 'Kriminalpsykologi eller rettspsykiatri',
                        note: 'Se også JKV* <a href="/thema/nb/JKV">Kriminalitet og kriminologi</a> og dens underkategorier',
                      },
                      {
                        id: 'MKT',
                        label: 'Rettsmedisin',
                        originalLabel: 'Rettsmedisin',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'JKVG',
                label: 'Narkotikahandel og -smugling',
                originalLabel: 'Narkotikahandel og -smugling',
                alternativeLabels: ['narkotikasmugling'],

                related: [],
                children: [],
              },
              {
                id: 'JKVJ',
                label: 'Gatekriminalitet',
                originalLabel: 'Gatekriminalitet',
                alternativeLabels: ['syreangrep'],
                note: 'Her: overfall, lommetyveri, vandalisme, våpenkriminalitet',
                related: [],
                children: [],
              },
              {
                id: 'JKVK',
                label: 'Økonomisk kriminalitet / hvitsnippforbrytelser',
                originalLabel: 'Økonomisk kriminalitet / hvitsnippforbrytelser',
                alternativeLabels: [
                  'yrkeskriminelle',
                  'hvitvasking',
                  'forfalskning',
                  'kredittkortsvindel',
                  'datakriminalitet',
                  'skatteunndragelse',
                  'identitetstyveri',
                ],
                note: 'Her: økonomiske forbrytelser, svindel, underslag, bestikkelser, korrupsjon etc.',
                related: [],
                children: [],
              },
              {
                id: 'JKVM',
                label: 'Organisert kriminalitet',
                originalLabel: 'Organisert kriminalitet',
                alternativeLabels: [
                  'gangstere',
                  'priater',
                  'sjørøvere',
                  'kriminelle syndikater',
                  'banditter',
                ],
                note: 'Her: mafia, kriminelle gjenger, piratvirksomhet',
                related: [],
                children: [],
              },
              {
                id: 'JKVN',
                label: 'Voldskriminalitet',
                originalLabel: 'Voldskriminalitet',
                alternativeLabels: [
                  'ran',
                  'bankran',
                  'terrorister',
                  'voldtektsmenn',
                  'voldtektsforbrytelser',
                  'kidnappere',
                ],
                note: 'Her: mord, drap, kidnapping, tortur, overfall, æresdrap, voldtekt, seksualisert vold etc. Brukes for utgivelser som tar for seg ulike aspekter ved voldskriminalitet. Brukes med DNXC*-koder for journalistiske beskrivelser av faktiske hendelser',
                related: [],
                children: [],
              },
              {
                id: 'JKVP',
                label: 'Fengselsvitenskap og straff',
                originalLabel: 'Fengselsvitenskap og straff',
                alternativeLabels: [],
                note: 'Her: fengsler, straffeanstalter; straffesystemer og -teorier, gjenopprettende prosess, samfunnstjeneste. Se også JWXR <a href="/thema/nb/JWXR">Krigsfanger</a>',
                related: [
                  {
                    id: 'JWXR',
                    label: 'Krigsfanger',
                    originalLabel: 'Krigsfanger',
                  },
                ],
                children: [],
              },
              {
                id: 'JKVQ',
                label: 'Lovbrytere',
                originalLabel: 'Lovbrytere',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'JKVQ1',
                    label: 'Rehabilitering av lovbrytere',
                    originalLabel: 'Rehabilitering av lovbrytere',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'JKVQ2',
                    label: 'Ungdomsforbrytere',
                    originalLabel: 'Ungdomsforbrytere',
                    alternativeLabels: [],
                    note: 'Her: ungdomskriminalitet',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'JKVS',
                label: 'Prøvetidsordninger',
                originalLabel: 'Prøvetidsordninger',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JKVV',
                label: 'Viktimologi og ofre for kriminalitet',
                originalLabel: 'Viktimologi og ofre for kriminalitet',
                alternativeLabels: [],
                note: 'Her: offerets rettigheter, mekling mellom offer og lovbryter. Se også DNXR <a href="/thema/nb/DNXR">Historier fra virkeligheten: mishandling, overgrep og urettferdighet</a>',
                related: [
                  {
                    id: 'DNXR',
                    label: 'Mishandling, overgrep og urettferdighet',
                    originalLabel:
                      'Historier fra virkeligheten: mishandling, overgrep og urettferdighet',
                    note: 'Brukes for fysiske eller emosjonelle prøvelser som skyldes mishandling, justismord, ofre for kriminalitet etc. Her: selvbiografiske bøker som handler om hovedpersonens kamp og seier over personlige traumer og mishandling. Foretrekk DNXC for objektivt syn på eller kriminelles syn på kriminelle handlinger. Se også DNXC <a href="/thema/nb/DNXC">Historier fra virkeligheten: forbrytelser</a>, DNXP <a href="/thema/nb/DNXP">Historier fra virkeligheten: heltemot, utholdenhet og overlevelse</a>',
                  },
                ],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'JM',
        label: 'Psykologi',
        originalLabel: 'Psykologi',
        alternativeLabels: [],
        note: 'Bruk JM*-koder for akademiske verk. Bruk VS*-koder for praktiske råd til allmennpublikum. Se også VSP* <a href="/thema/nb/VSP">Populærpsykologi</a> og dens underkategorier',
        related: [
          {
            id: 'VSP',
            label: 'Populærpsykologi',
            originalLabel: 'Populærpsykologi',
            note: 'Se også QDX <a href="/thema/nb/QDX">Populærfilosofi</a>',
          },
        ],
        children: [
          {
            id: 'JMA',
            label: 'Psykologisk teori, systemer, synspunkter og skoleretninger',
            originalLabel:
              'Psykologisk teori, systemer, synspunkter og skoleretninger',
            alternativeLabels: [],
            note: 'Se også QDTM <a href="/thema/nb/QDTM">Bevissthetsfilosofi</a>',
            related: [
              {
                id: 'QDTM',
                label: 'Bevissthetsfilosofi',
                originalLabel: 'Bevissthetsfilosofi',
                note: 'Her: dualisme, monisme, nevrofilosofi, bevissthet. Se også JMA <a href="/thema/nb/JMA">Psykologisk teori, systemer, synspunkter og skoleretninger</a>, GTK <a href="/thema/nb/GTK">Kognitive studier</a>',
              },
            ],
            children: [
              {
                id: 'JMAF',
                label: 'Psykoanalytisk og freudiansk psykologi',
                originalLabel: 'Psykoanalytisk og freudiansk psykologi',
                alternativeLabels: [],
                note: 'Her: psykoanalyse',
                related: [],
                children: [
                  {
                    id: 'JMAF1',
                    label: 'Lacansk psykoanalyse',
                    originalLabel: 'Lacansk psykoanalyse',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'JMAJ',
                label: 'Analytisk og jungiansk psykologi',
                originalLabel: 'Analytisk og jungiansk psykologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JMAL',
                label: 'Behaviorisme, adferdsteori',
                originalLabel: 'Behaviorisme, adferdsteori',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JMAN',
                label: 'Humanistisk psykologi',
                originalLabel: 'Humanistisk psykologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JMAP',
                label: 'Positiv psykologi',
                originalLabel: 'Positiv psykologi',
                alternativeLabels: [],
                note: 'Her: psykologisk retning med fokus på hva som gir oss mennesker lykke og mentalt velvære. Se også VSPM <a href="/thema/nb/VSPM">Selvutvikling, motivasjon, selvtillit og positiv mental innstilling</a>',
                related: [
                  {
                    id: 'VSPM',
                    label:
                      'Selvutvikling, motivasjon, selvtillit og positiv mental innstilling',
                    originalLabel:
                      'Selvutvikling, motivasjon, selvtillit og positiv mental innstilling',
                    note: 'Her: lykke, velvære, positivt selvbilde, mental helse, balanse mellom arbeidsliv og fritid, positive tanker og hvordan være positivt innstilt til livet. Brukes for bøker som handler om psykologiske prinsipper, har motiverende eller inspirerende råd eller viser til sunn fornuft når det gjelder hvordan man kan bli lykkeligere, gjøre andre personer lykkelige',
                  },
                ],
                children: [],
              },
              {
                id: 'JMAQ',
                label: 'Kognitivisme, kognitiv teori',
                originalLabel: 'Kognitivisme, kognitiv teori',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JMB',
            label: 'Psykologisk metodologi',
            originalLabel: 'Psykologisk metodologi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'JMBT',
                label: 'Psykologisk testing og måling',
                originalLabel: 'Psykologisk testing og måling',
                alternativeLabels: [],
                note: 'Her: psykometri',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JMC',
            label: 'Barne- og utviklingspsykologi',
            originalLabel: 'Barne- og utviklingspsykologi',
            alternativeLabels: ['barnepsykologi'],
            note: 'Her: tilknytningsteori, psykologisk utvikling, sosial og emosjonell utvikling. Se også JNC <a href="/thema/nb/JNC">Pedagogisk psykologi</a>',
            related: [
              {
                id: 'JNC',
                label: 'Pedagogisk psykologi',
                originalLabel: 'Pedagogisk psykologi',
              },
            ],
            children: [],
          },
          {
            id: 'JMD',
            label: 'Aldringspsykologi',
            originalLabel: 'Aldringspsykologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'JMF',
            label: 'Familiepsykologi',
            originalLabel: 'Familiepsykologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'JMG',
            label: 'Kjønnspsykologi',
            originalLabel: 'Kjønnspsykologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'JMH',
            label: 'Sosial- og gruppepsykologi',
            originalLabel: 'Sosial- og gruppepsykologi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'JMHC',
                label: 'Mellommenneskelig kommunikasjon og ferdigheter',
                originalLabel: 'Mellommenneskelig kommunikasjon og ferdigheter',
                alternativeLabels: ['interpersonlig kommunikasjon'],
                note: 'Her: både verbal og ikke-verbal kommunikasjonspsykologi. Se også GTC <a href="/thema/nb/GTC">Kommunikasjonsstudier</a>',
                related: [
                  {
                    id: 'GTC',
                    label: 'Kommunikasjonsstudier',
                    originalLabel: 'Kommunikasjonsstudier',
                    note: 'Brukes for lærebøker innen kommunikasjon og for verk som dekker en rekke aspekter ved kommunikasjon - språklige, sosiale, industrielle, teknologiske etc. Se også JBCT* <a href="/thema/nb/JBCT">Medievitenskap</a> og dens underkategorier',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'JMJ',
            label: 'Arbeids- og industripsykologi',
            originalLabel: 'Arbeids- og industripsykologi',
            alternativeLabels: ['arbeidspsykologi'],
            note: 'Se også SCGP <a href="/thema/nb/SCGP">Idrettspsykologi</a>, KNXC <a href="/thema/nb/KNXC">Helse og sikkerhet på arbeidsplassen</a>',
            related: [
              {
                id: 'KNXC',
                label: 'Helse og sikkerhet på arbeidsplassen',
                originalLabel: 'Helse og sikkerhet på arbeidsplassen',
                note: 'Brukes for bøker som ser på helse, miljø og sikkerhet på arbeidsplassen, råd om hvordan skape trygge arbeidsplasser og et sunt arbeidsmiljø, overholde nødvendige lover og forskrifter, opplæring i helse, miljø og sikkerhet. Se også JMJ <a href="/thema/nb/JMJ">Arbeids- og industripsykologi</a>, LNHH <a href="/thema/nb/LNHH">HMS og arbeidsmiljøloven</a>',
              },
              {
                id: 'SCGP',
                label: 'Idrettspsykologi',
                originalLabel: 'Idrettspsykologi',
              },
            ],
            children: [],
          },
          {
            id: 'JMK',
            label: 'Kriminalpsykologi eller rettspsykiatri',
            originalLabel: 'Kriminalpsykologi eller rettspsykiatri',
            alternativeLabels: [],
            note: 'Se også JKV* <a href="/thema/nb/JKV">Kriminalitet og kriminologi</a> og dens underkategorier',
            related: [
              {
                id: 'JKV',
                label: 'Kriminalitet og kriminologi',
                originalLabel: 'Kriminalitet og kriminologi',
                note: 'Se også DNXC <a href="/thema/nb/DNXC">Historier fra virkeligheten: forbrytelser</a>',
              },
            ],
            children: [],
          },
          {
            id: 'JML',
            label: 'Eksperimentell psykologi',
            originalLabel: 'Eksperimentell psykologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'JMM',
            label: 'Fysiologisk psykologi, nevro- og biopsykologi',
            originalLabel: 'Fysiologisk psykologi, nevro- og biopsykologi',
            alternativeLabels: ['nevropsykologi'],

            related: [],
            children: [],
          },
          {
            id: 'JMP',
            label: 'Abnormalpsykologi',
            originalLabel: 'Abnormalpsykologi',
            alternativeLabels: [],
            note: 'Her: psykiske lidelser. Se også MKM* <a href="/thema/nb/MKM">Klinisk psykologi</a> og dens underkategorier',
            related: [
              {
                id: 'MKM',
                label: 'Klinisk psykologi',
                originalLabel: 'Klinisk psykologi',
                note: 'Se også JMP <a href="/thema/nb/JMP">Abnormalpsykologi</a>',
              },
            ],
            children: [],
          },
          {
            id: 'JMQ',
            label: 'Psykologi: følelser',
            originalLabel: 'Psykologi: følelser',
            alternativeLabels: [],
            note: 'Her: affektiv nevrovitenskap',
            related: [],
            children: [],
          },
          {
            id: 'JMR',
            label: 'Kognitiv psykologi',
            originalLabel: 'Kognitiv psykologi',
            alternativeLabels: [],
            note: 'Her: kognitiv nevrovitenskap, utgivelser om psykologi i forbindelse med læring, kognitive prosesser som hukommelse, hukommelsestap, intelligens, resonnering, persepsjon, språk',
            related: [],
            children: [],
          },
          {
            id: 'JMS',
            label: 'Psykologi: jeget, identitet, personlighet',
            originalLabel: 'Psykologi: jeget, identitet, personlighet',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'JMT',
            label: 'Psykologi: tilstander av bevissthet',
            originalLabel: 'Psykologi: tilstander av bevissthet',
            alternativeLabels: [],
            note: 'Her: psykologiske verk om bevissthet og ubevissthet; søvn og drømmer; endrede sinnstilstander, hypnose, tilstander fremkalt av legemiddel- og narkotika, etc. Se også VXN <a href="/thema/nb/VXN">Drømmer og drømmetydning</a>',
            related: [
              {
                id: 'VXN',
                label: 'Drømmer og drømmetydning',
                originalLabel: 'Drømmer og drømmetydning',
                note: 'Se også JMT <a href="/thema/nb/JMT">Psykologi: tilstander av bevissthet</a>',
              },
            ],
            children: [],
          },
          {
            id: 'JMU',
            label: 'Psykologi: seksuell adferd',
            originalLabel: 'Psykologi: seksuell adferd',
            alternativeLabels: [],
            note: 'Se også VFVC <a href="/thema/nb/VFVC">Sex og seksualitet</a>, JBFW <a href="/thema/nb/JBFW">Sex og seksualitet: sosiale aspekter</a>',
            related: [
              {
                id: 'JBFW',
                label: 'Sex og seksualitet',
                originalLabel: 'Sex og seksualitet: sosiale aspekter',
                note: 'Her: generelle bøker om sex og samfunn, pornografi og sexindustrien, sexarbeid og prostitusjon, eller bøker som tar for seg seksualitet i bestemte samfunn, kulturelle eller samfunnsmessige holdninger eller normer knyttet til seksualitet, undertrykkelse og kriminalisering av seksualitet, holdninger til prevensjon og seksualopplysning, etc. Se også JMU <a href="/thema/nb/JMU">Psykologi: seksuell adferd</a>, VFVC <a href="/thema/nb/VFVC">Sex og seksualitet</a>',
              },
              {
                id: 'VFVC',
                label: 'Sex og seksualitet',
                originalLabel: 'Sex og seksualitet',
                note: 'Her: Kama Sutra, tips til et godt sexliv. Brukes for bøker som handler om eller forklarer ulike sider ved sex og seksualitet, inkludert, men ikke begrenset til samtykke, seksuelle relasjoner, tilfeldig sex, pornografi, fysisk intimitet, seksuell helse, seksuell nytelse, teknikker og råd for å få et bedre sexliv, bruk av afrodisiakum, orgasmer, masturbering, sexråd til eldre, impotens, bøker om de menneskelige kjønnsorganene og forplantningssystemet. Se også JBFW <a href="/thema/nb/JBFW">Sex og seksualitet: sosiale aspekter</a>, JMU <a href="/thema/nb/JMU">Psykologi: seksuell adferd</a>',
              },
            ],
            children: [],
          },
          {
            id: 'JMX',
            label: 'Parapsykologiske studier',
            originalLabel: 'Parapsykologiske studier',
            alternativeLabels: [],
            note: 'Her: parapsykologi. Brukes for profesjonelle, akademiske verk. Foretrekk VX*-koder for populærvitenskapelige bøker rettet mot allmennmarkedet. Se også VXP <a href="/thema/nb/VXP">Psykiske evner og psykiske fenomener</a>',
            related: [
              {
                id: 'VXP',
                label: 'Psykiske evner og psykiske fenomener',
                originalLabel: 'Psykiske evner og psykiske fenomener',
                note: 'Her: ekstrasensorisk persepsjon, telepati, telekinese etc. Se også JMX <a href="/thema/nb/JMX">Parapsykologiske studier</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'JN',
        label: 'Pedagogikk',
        originalLabel: 'Pedagogikk',
        alternativeLabels: [],
        note: 'Her: utdanning. JN*-koder brukes for utgivelser om utdanningsfeltet som fag eller yrke, men bruk YP*-koder for læremidler for elever i grunn- og videregående skole',
        related: [],
        children: [
          {
            id: 'JNA',
            label: 'Pedagogisk filosofi og teori',
            originalLabel: 'Pedagogisk filosofi og teori',
            alternativeLabels: ['didaktikk'],
            note: 'Her: perennialisme, essensialisme, kritisk pedagogikk, demokratisk dannelse, kritisk tenkning i utdanning, teorier om ulike læringsstiler, etc.',
            related: [],
            children: [
              {
                id: 'JNAM',
                label: 'Moralske og sosiale formål med utdanning',
                originalLabel: 'Moralske og sosiale formål med utdanning',
                alternativeLabels: [],
                note: 'Her: verk som støtter teoriene bak utdanning som fokuserer spesielt på emner knyttet til samfunnet og oppførselen i samfunnet',
                related: [],
                children: [],
              },
              {
                id: 'JNAS',
                label: 'Progressiv pedagogikk',
                originalLabel: 'Progressiv pedagogikk',
                alternativeLabels: [],
                note: 'Her: montessoripedagogikk, steinerpedagogikk, waldorfpedagogikk. Brukes for bøker om teorier eller prinsipper regnet som progressive, sammenlignet med mer tradisjonelle læreplaner og teorier. Brukes med andre JN*-koder hvis hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JNB',
            label: 'Pedagogisk historie',
            originalLabel: 'Pedagogisk historie',
            alternativeLabels: [],
            note: 'Her: spesifikke skolers historie, høgskolers og universiteters historie, samt generell historie',
            related: [],
            children: [],
          },
          {
            id: 'JNC',
            label: 'Pedagogisk psykologi',
            originalLabel: 'Pedagogisk psykologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'JND',
            label: 'Utdanningssystemer og -strukturer',
            originalLabel: 'Utdanningssystemer og -strukturer',
            alternativeLabels: ['utdanningsstrukturer'],
            note: 'Her: formell eller uformell utdanning. Brukes med kvalifikator for sted for bøker som sammenligner ulike lands utdanningssystemer',
            related: [],
            children: [
              {
                id: 'JNDG',
                label: 'Pensumplanlegging og -utvikling',
                originalLabel: 'Pensumplanlegging og -utvikling',
                alternativeLabels: ['pensumutvikling'],
                note: 'Her: læreplan',
                related: [],
                children: [],
              },
              {
                id: 'JNDH',
                label: 'Pedagogikk: eksaminasjon og vurdering',
                originalLabel: 'Pedagogikk: eksaminasjon og vurdering',
                alternativeLabels: [],
                note: 'Her: testing og evaluering av elever',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JNE',
            label: 'Sosialpedagogikk',
            originalLabel: 'Sosialpedagogikk',
            alternativeLabels: [],
            note: 'Brukes for bøker om sosialpedagogikk for høyere utdanning',
            related: [],
            children: [],
          },
          {
            id: 'JNF',
            label: 'Undervisningsmetodikk og retningslinjer for utdanning',
            originalLabel:
              'Undervisningsmetodikk og retningslinjer for utdanning',
            alternativeLabels: [],
            note: 'Her: didaktikk; læringsmetoder for leseopplæring og tallforståelse etc.; retningslinjer for strømming etc.',
            related: [],
            children: [
              {
                id: 'JNFC',
                label: 'Student/elevrådgivning og -velferd',
                originalLabel: 'Student/elevrådgivning og -velferd',
                alternativeLabels: [
                  'rådgivning',
                  'studenter',
                  'elever',
                  'studentrådgivning',
                  'elevrådgivning',
                  'elevvelferd',
                ],
                note: 'Her: sjelesorg, studentvelferd, fravær, skulking, disiplin, sikkerhet i utdanningsinstitusjoner, barnevern i skolen, beredskapsøvelser og krisehåndtering, studentutvekslingsprogrammer, etc.',
                related: [],
                children: [],
              },
              {
                id: 'JNFK',
                label:
                  'Undervisningsmetodikk og utdanningspolitikk: inkluderende utdanning',
                originalLabel:
                  'Undervisningsmetodikk og utdanningspolitikk: inkluderende utdanning',
                alternativeLabels: [],
                note: 'Her: bøker om mangfold, inkludering og likeverd, sosial mobilitet i utdanning; eller bøker som tar for seg ekskludering eller de som er ekskludert fra utdanning; eller bøker om tilgang til utdanning for vanskeligstilte. Brukes for både det å utforme metodikk og politikk, og det å praktisk bruke/utføre disse',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JNG',
            label: 'Pass av små barn',
            originalLabel: 'Pass av små barn',
            alternativeLabels: ['barnepiker'],
            note: 'Use for: works aimed at professionals dealing with childcare or general works on subject. Se også JNLA <a href="/thema/nb/JNLA">Førskoler og barnehager</a>',
            related: [
              {
                id: 'JNLA',
                label: 'Førskoler og barnehager',
                originalLabel: 'Førskoler og barnehager',
                note: 'Her: barnehagepedagogikk',
              },
            ],
            children: [],
          },
          {
            id: 'JNH',
            label: 'Hjemmeundervisning',
            originalLabel: 'Hjemmeundervisning',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'JNK',
            label: 'Skoleadministrasjon og -organisering',
            originalLabel: 'Skoleadministrasjon og -organisering',
            alternativeLabels: ['skoleorganisering'],
            note: 'Class here: school governance, boards of education, head teachers, superintendents, principals or other leadership or administrative roles, or general works about the administration or organizations in educational institutions, parent-teacher associations or organizations',
            related: [],
            children: [
              {
                id: 'JNKG',
                label: 'Studiefinansiering og studentøkonomi',
                originalLabel: 'Studiefinansiering og studentøkonomi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JNKH',
                label: 'Undervisningspersonell',
                originalLabel: 'Undervisningspersonell',
                alternativeLabels: ['lærere'],
                note: 'Her: lærere, lærerassistenter',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JNL',
            label: 'Skoler og førskoler',
            originalLabel: 'Skoler og førskoler',
            alternativeLabels: [],
            note: 'Se også VSK <a href="/thema/nb/VSK">Yrkes- og utdanningsveiledning</a>',
            related: [
              {
                id: 'VSK',
                label: 'Yrkes- og utdanningsveiledning',
                originalLabel: 'Yrkes- og utdanningsveiledning',
                note: 'Se også JN* <a href="/thema/nb/JN">Pedagogikk</a> og dens underkategorier',
              },
            ],
            children: [
              {
                id: 'JNLA',
                label: 'Førskoler og barnehager',
                originalLabel: 'Førskoler og barnehager',
                alternativeLabels: ['førskolepedagogikk'],
                note: 'Her: barnehagepedagogikk',
                related: [],
                children: [],
              },
              {
                id: 'JNLB',
                label: 'Barneskoler og ungdomsskoler',
                originalLabel: 'Barneskoler og ungdomsskoler',
                alternativeLabels: [],
                note: 'Her: grunnskolen',
                related: [],
                children: [],
              },
              {
                id: 'JNLC',
                label: 'Videregående skoler',
                originalLabel: 'Videregående skoler',
                alternativeLabels: [],
                note: 'Class here: senior and high schools',
                related: [],
                children: [],
              },
              {
                id: 'JNLP',
                label: 'Privatskoler og privatundervisning',
                originalLabel: 'Privatskoler og privatundervisning',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JNLQ',
                label: 'Internatskoler',
                originalLabel: 'Internatskoler',
                alternativeLabels: [],
                note: 'Her: folkehøyskoler, kostskoler eller andre utdanningsinstitusjoner der elever både bor og får undervisning',
                related: [],
                children: [],
              },
              {
                id: 'JNLR',
                label: 'Religiøse skoler',
                originalLabel: 'Religiøse skoler',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JNLV',
                label: 'Friluftsskoler / -utdanning',
                originalLabel: 'Friluftsskoler / -utdanning',
                alternativeLabels: [],
                note: 'Brukes for organisert opplæring som foregår i friluft',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JNM',
            label: 'Høyere utdanning',
            originalLabel: 'Høyere utdanning',
            alternativeLabels: [
              'høgskole',
              'høyskole',
              'høyskoler',
              'universiteter',
            ],
            note: 'Her: all høyere utdanning; inkludert utdanningsinstitusjoner som tilbyr yrkesutdanning og teknisk utdanning, samt høgskoler og universitet. Se også VSK <a href="/thema/nb/VSK">Yrkes- og utdanningsveiledning</a>',
            related: [
              {
                id: 'VSK',
                label: 'Yrkes- og utdanningsveiledning',
                originalLabel: 'Yrkes- og utdanningsveiledning',
                note: 'Se også JN* <a href="/thema/nb/JN">Pedagogikk</a> og dens underkategorier',
              },
            ],
            children: [
              {
                id: 'JNMT',
                label: 'Lærerutdanning',
                originalLabel: 'Lærerutdanning',
                alternativeLabels: [],
                note: 'Her: faglig utvikling av pedagoger, kontinuerlig læring, etterutdanning',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JNP',
            label: 'Voksenundervisning, videreutdanning',
            originalLabel: 'Voksenundervisning, videreutdanning',
            alternativeLabels: ['selvstudier', 'folkeuniversitetet'],
            note: 'Her: livslang læring, folkeopplysning. Brukes for utgivelser om voksenopplæring eller -undervisning som ikke er på universitetsnivå eller er yrkesfaglig opplæring. Se også VSL <a href="/thema/nb/VSL">Lese- og skriveopplæring for voksne: guider og håndbøker</a>, VSN <a href="/thema/nb/VSN">Matematikk for voksne: guider og håndbøker</a>',
            related: [
              {
                id: 'VSL',
                label:
                  'Lese- og skriveopplæring for voksne: guider og håndbøker',
                originalLabel:
                  'Lese- og skriveopplæring for voksne: guider og håndbøker',
                note: 'Se også CFC <a href="/thema/nb/CFC">Lese- og skriveferdigheter</a>',
              },
              {
                id: 'VSN',
                label: 'Matematikk for voksne: guider og håndbøker',
                originalLabel: 'Matematikk for voksne: guider og håndbøker',
              },
            ],
            children: [],
          },
          {
            id: 'JNQ',
            label: 'Åpen læring, fjernundervisning',
            originalLabel: 'Åpen læring, fjernundervisning',
            alternativeLabels: [],
            note: 'Her: nettbasert læring',
            related: [],
            children: [],
          },
          {
            id: 'JNR',
            label: 'Karriereveiledning',
            originalLabel: 'Karriereveiledning',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'JNRD',
                label: 'Arbeidserfaring, utplassering og praksisplasser',
                originalLabel:
                  'Arbeidserfaring, utplassering og praksisplasser',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JNRV',
                label: 'Yrkesopplæring',
                originalLabel: 'Yrkesopplæring',
                alternativeLabels: [],
                note: 'Brukes for verk om yrkesopplæring og -utdanning som emne. Foretrekk YPW* og andre emnekoder som T* for yrkesfagbøker og andre læremidler for yrkesfaglig opplæring, samt kvalifikatorer fra 4CP* der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JNS',
            label: 'Spesialundervisning',
            originalLabel: 'Spesialundervisning',
            alternativeLabels: [],
            note: 'Her: spesialpedagogikk, undervisning av elever fra ulik kulturell eller språklig bakgrunn etc.',
            related: [],
            children: [
              {
                id: 'JNSC',
                label:
                  'Undervisning for elever med fysiske funksjonsnedsettelser',
                originalLabel:
                  'Undervisning for elever med fysiske funksjonsnedsettelser',
                alternativeLabels: [],
                note: 'Her: undervisning for hørsels- eller synshemmede, eller andre fysiske funksjonsnedsettelser som krever spesialundervisning. Brukes med 5PM*-koder der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'JNSG',
                label: 'Undervisning for elever med lærevansker',
                originalLabel: 'Undervisning for elever med lærevansker',
                alternativeLabels: [],
                note: 'Her: undervisning for elever med dysleksi, autisme, adferdsvansker, eller andre forhold som krever spesialundervisning',
                related: [],
                children: [],
              },
              {
                id: 'JNSL',
                label:
                  'Undervisning for elever med adferdsvansker, sosiale eller følelsesmessige vansker',
                originalLabel:
                  'Undervisning for elever med adferdsvansker, sosiale eller følelsesmessige vansker',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JNSP',
                label: 'Undervisning for begavede eller talentfulle elever',
                originalLabel:
                  'Undervisning for begavede eller talentfulle elever',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JNSR',
                label: 'Ekstraundervisning',
                originalLabel: 'Ekstraundervisning',
                alternativeLabels: [],
                note: 'Her: tilleggsundervisning for elever / studenter som ikke har oppnådd forventet nivå',
                related: [],
                children: [],
              },
              {
                id: 'JNSV',
                label: 'Undervisning av tospråklige eller flerspråklige elever',
                originalLabel:
                  'Undervisning av tospråklige eller flerspråklige elever',
                alternativeLabels: [],
                note: 'Her: bøker som tar for seg undervisning på et andrespråk, undervisning i flerspråklige skoleklasser, undervisning av elever som snakker et annet språk, men hvor språkopplæring ikke er undervisningas hovedformål, bøker om tospråklig eller flerkulturell utdanning, språkfordypning',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JNT',
            label: 'Undervisningsmetoder',
            originalLabel: 'Undervisningsmetoder',
            alternativeLabels: [],
            note: 'Her: kontroll i klasserommet, klasseledelse, mentorskap, coaching, timeplanlegging, veiledning',
            related: [],
            children: [
              {
                id: 'JNTC',
                label: 'Kompetanseutvikling',
                originalLabel: 'Kompetanseutvikling',
                alternativeLabels: [],
                note: 'Her: kompetansebasert undervisning og opplæring, sosial og emosjonell læring, undervisning i analytisk, kreativ eller kritisk tenkning. Brukes for bøker som ser på praktiske tilnærmingsmåter for å tilegne seg kompetanse og konkrete ferdigheter innen undervisning eller opplæring, eller bøker som ser på teorier eller teknikker for å hjelpe elever med å utvikle ferdigheter til å håndtere følelser, løse konflikter og ta ansvarlige beslutninger',
                related: [],
                children: [],
              },
              {
                id: 'JNTP',
                label: 'Prosjektbasert læring',
                originalLabel: 'Prosjektbasert læring',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JNTR',
                label: 'Lekbasert læring',
                originalLabel: 'Lekbasert læring',
                alternativeLabels: [],
                note: 'Brukes for bøker som tar for seg teorier rundt det å bruke lek og aktivitet i undervisning',
                related: [],
                children: [],
              },
              {
                id: 'JNTS',
                label: 'Lese-, skrive- og regneopplæring',
                originalLabel: 'Lese-, skrive- og regneopplæring',
                alternativeLabels: [
                  'leseopplæring',
                  'skriveopplæring',
                  'regneopplæring',
                ],
                note: 'Her: syntetisk lesemetode, multisensorisk leseopplæring, høytlesing, dialogisk lesing. Brukes for bøker som tar for seg teorier rundt undervisning og opplæring i grunnleggende ferdigheter, eller som ser på hvordan utdanning kan forbedre eller motivere til bedre lese-, skrive- eller regneferdigheter i alle aldre. Se også CFC <a href="/thema/nb/CFC">Lese- og skriveferdigheter</a>, CJC* <a href="/thema/nb/CJC">Språkopplæring: spesifikke ferdigheter</a> og dens underkategorier, VSL <a href="/thema/nb/VSL">Lese- og skriveopplæring for voksne: guider og håndbøker</a>, YPCA2 <a href="/thema/nb/YPCA2">Læremidler: førstespråk/morsmål: lese- og skriveferdigheter</a>',
                related: [
                  {
                    id: 'CFC',
                    label: 'Lese- og skriveferdigheter',
                    originalLabel: 'Lese- og skriveferdigheter',
                    note: 'Her: ferdigheter, teorier og emner knyttet til det å kunne lese og skrive, sosiale og kulturelle aspekter ved lesing og skriving. Se også JNTS <a href="/thema/nb/JNTS">Lese-, skrive- og regneopplæring</a>, VSL <a href="/thema/nb/VSL">Lese- og skriveopplæring for voksne: guider og håndbøker</a>',
                  },
                  {
                    id: 'CJC',
                    label: 'Språkopplæring: spesifikke ferdigheter',
                    originalLabel: 'Språkopplæring: spesifikke ferdigheter',
                    note: 'Brukes med kvalifikator(er) for språk og pedagogisk formål der det er hensiktsmessig',
                  },
                  {
                    id: 'VSL',
                    label:
                      'Lese- og skriveopplæring for voksne: guider og håndbøker',
                    originalLabel:
                      'Lese- og skriveopplæring for voksne: guider og håndbøker',
                    note: 'Se også CFC <a href="/thema/nb/CFC">Lese- og skriveferdigheter</a>',
                  },
                  {
                    id: 'YPCA2',
                    label:
                      'Læremidler: førstespråk/morsmål: lese- og skriveferdigheter',
                    originalLabel:
                      'Læremidler: førstespråk/morsmål: lese- og skriveferdigheter',
                    note: 'Brukes for bøker som gir opplæring i det og lese og skrive selvstendig, leseforståelse, kreativ skriving, syntetisk lesemetode etc.',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'JNU',
            label: 'Undervisning i et spesifikt fag',
            originalLabel: 'Undervisning i et spesifikt fag',
            alternativeLabels: ['undervisningsmetodikk', 'didaktikk'],
            note: 'Bruk alle JNU*-koder for bøker som handler om undervisning i et spesifikt fag. Tildel også YP*-kode for faget som det undervises i, eller fagkoder for bøker rettet mot voksne som skal lære seg et spesifikt fag. Tildel også kvalifikator(er) for pedagogisk formål, hvis hensiktsmessig. JNU bør her være hovedkategori',
            related: [],
            children: [
              {
                id: 'JNUM',
                label: 'Lærerveiledninger og undervisningsmateriell',
                originalLabel: 'Lærerveiledninger og undervisningsmateriell',
                alternativeLabels: [],
                note: 'Her: interaktive læremidler, lærerveiledninger, tilleggslitteratur og -materiell etc. Tildel også YP*-kode for faget som det undervises i, eller fagkoder der det er snakk om lærerveiledninger eller undervisningsmateriell for voksne som skal lære seg et spesifikt fag. Tildel også kvalifikator(er) for pedagogisk formål, hvis hensiktsmessig. JNU bør her være hovedkategori',
                related: [],
                children: [
                  {
                    id: 'JNUM1',
                    label: 'Undervisningsplaner',
                    originalLabel: 'Undervisningsplaner',
                    alternativeLabels: ['lekseplaner', 'ukeplaner'],
                    note: 'Brukes for koden for det faget det blir undervist i ved å bruke YP*-koder der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'JNV',
            label: 'Undervisningsutstyr og teknologi, dataassistert læring',
            originalLabel:
              'Undervisningsutstyr og teknologi, dataassistert læring',
            alternativeLabels: [],
            note: 'Brukes for bøker om bruk av digital teknologi og digitale læremidler i undervisning i klasserommet og for opplæring',
            related: [],
            children: [],
          },
          {
            id: 'JNW',
            label: 'Utenomfaglige aktiviteter',
            originalLabel: 'Utenomfaglige aktiviteter',
            alternativeLabels: [],
            note: 'Her: ekskursjoner, skoleturer, sports- og samfunnsaktiviteter, arrangementer, opptredener etc.; også aktiviteter etter skoletid, SFO',
            related: [],
            children: [],
          },
          {
            id: 'JNZ',
            label: 'Studie- og læringsferdigheter',
            originalLabel: 'Studie- og læringsferdigheter',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'JP',
        label: 'Politikk og regjering',
        originalLabel: 'Politikk og regjering',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'JPA',
            label: 'Politisk forskning og teori',
            originalLabel: 'Politisk forskning og teori',
            alternativeLabels: [],
            note: 'Her: statsvitenskap. Se også QDTS <a href="/thema/nb/QDTS">Sosial og politisk filosofi</a>',
            related: [
              {
                id: 'QDTS',
                label: 'Sosial og politisk filosofi',
                originalLabel: 'Sosial og politisk filosofi',
                note: 'Se også JPA <a href="/thema/nb/JPA">Politisk forskning og teori</a>, JPF <a href="/thema/nb/JPF">Politiske ideologier og bevegelser</a>, LAB <a href="/thema/nb/LAB">Rettsmetoder, -teori og -filosofi</a>',
              },
            ],
            children: [],
          },
          {
            id: 'JPB',
            label: 'Sammenlignende politikk',
            originalLabel: 'Sammenlignende politikk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'JPF',
            label: 'Politiske ideologier og bevegelser',
            originalLabel: 'Politiske ideologier og bevegelser',
            alternativeLabels: [
              'ekstremisme',
              'radikalisering',
              'ideologi',
              'libertarianisme',
              'populisme',
            ],
            note: 'Use for: works on different parts of the political spectrum, or political ideologies or movements that do not have more precise codes',
            related: [],
            children: [
              {
                id: 'JPFA',
                label: 'Grønn ideologi / økopolitikk / økologisme',
                originalLabel: 'Grønn ideologi / økopolitikk / økologisme',
                alternativeLabels: [
                  'miljøvern',
                  'Miljøpartiet De Grønne',
                  'MDG',
                  'Grønn ungdom',
                ],
                note: 'Her: grønn politisk tenkning, grønne organisasjoner og bevegelser, miljøaktivister. Se også RNA <a href="/thema/nb/RNA">Miljøvernideologi</a>, RNB <a href="/thema/nb/RNB">Miljøvernere og miljøorganisasjoner</a>',
                related: [
                  {
                    id: 'RNA',
                    label: 'Miljøvernideologi',
                    originalLabel: 'Miljøvernideologi',
                    note: 'Her: økosentrisme, teknosentrisme, dypøkologi, økofeminisme etc. Se også JPFA <a href="/thema/nb/JPFA">Grønn ideologi / økopolitikk / økologisme</a>',
                  },
                  {
                    id: 'RNB',
                    label: 'Miljøvernere og miljøorganisasjoner',
                    originalLabel: 'Miljøvernere og miljøorganisasjoner',
                    note: 'Se også JPFA <a href="/thema/nb/JPFA">Grønn ideologi / økopolitikk / økologisme</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'JPFB',
                label: 'Anarkisme',
                originalLabel: 'Anarkisme',
                alternativeLabels: ['anarki'],

                related: [],
                children: [],
              },
              {
                id: 'JPFC',
                label:
                  'Venstreekstremistiske politiske ideologier og bevegelser',
                originalLabel:
                  'Venstreekstremistiske politiske ideologier og bevegelser',
                alternativeLabels: ['venstreekstremisme'],
                note: 'Her: kommunisme, marxisme, trotskisme, maoisme, venstrepopulisme',
                related: [],
                children: [],
              },
              {
                id: 'JPFF',
                label: 'Sosialisme og venstreorienterte ideologier',
                originalLabel: 'Sosialisme og venstreorienterte ideologier',
                alternativeLabels: [
                  'Arbeiderpartiet',
                  'AP',
                  'Sosialistisk Venstreparti',
                  'SV',
                  'Rødt',
                  'AUF',
                  'SU',
                  'Sosialistisk ungdom',
                  'Rød Ungdom',
                ],
                note: 'Her: sosialdemokrati, sosialisme',
                related: [],
                children: [],
              },
              {
                id: 'JPFK',
                label: 'Sentrumsorienterte ideologier',
                originalLabel: 'Sentrumsorienterte ideologier',
                alternativeLabels: [
                  'KRF',
                  'Kristelig Folkeparti',
                  'Partiet Sentrum',
                  'Senterpartiet',
                  'Venstre',
                ],
                note: 'Her: liberalisme, kommunitarisme, sentrumspolitikk',
                related: [],
                children: [],
              },
              {
                id: 'JPFM',
                label: 'Konservatisme og høyreorienterte ideologier',
                originalLabel: 'Konservatisme og høyreorienterte ideologier',
                alternativeLabels: [
                  'Høyre',
                  'Fremskrittspartiet',
                  'FRP',
                  'Unge Høyre',
                  'Fremskrittspartiets Ungdom',
                ],
                note: 'Her: konservative og kristendemokratiske ideologier',
                related: [],
                children: [],
              },
              {
                id: 'JPFN',
                label: 'Nasjonalisme',
                originalLabel: 'Nasjonalisme',
                alternativeLabels: ['populisme'],
                note: 'Her: etnisk nasjonalisme, nasjonalistiske eller nativistiske bevegelser. Brukes med NH*-koder for historisk behandling av emnet, og med kvalifikatorer fra 5* der det er hensiktsmessig',
                related: [],
                children: [
                  {
                    id: 'JPFN2',
                    label: 'Pannasjonalisme',
                    originalLabel: 'Pannasjonalisme',
                    alternativeLabels: [
                      'panamerikansk',
                      'panasiatisk',
                      'paneuropeisk',
                      'panafrikansk',
                    ],
                    note: 'Brukes for bøker om ulike former for nasjonalisme som går utover tanken om nasjonalstaten, men som søker å skape en pannasjonal identitet, basert på et gitt sett fellesnevnere. Brukes med NH*-koder for historisk behandling av emnet, og med kvalifikatorer fra 5* der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'JPFQ',
                label: 'Høyreekstremistiske politiske ideologier og bevegelser',
                originalLabel:
                  'Høyreekstremistiske politiske ideologier og bevegelser',
                alternativeLabels: [
                  'nasjonalsosialisme',
                  'fascister',
                  'ultranasjonalister',
                  'høyreekstremister',
                  'paleokonservatism',
                  'alt-right',
                  'alt right',
                  'alternativhøyre',
                ],
                note: 'Her: fascisme og nyfascisme, nazisme og nynazisme, ultranasjonalisme, høyreekstremisme, nativisme, høyreradikale bevegelser og populisme',
                related: [],
                children: [],
              },
              {
                id: 'JPFR',
                label: 'Religiøse og teokratiske ideologier',
                originalLabel: 'Religiøse og teokratiske ideologier',
                alternativeLabels: ['islamisme', 'kristen sekterisme'],
                note: 'Her: teokrati. Brukes for bøker om religion som grunnlag for en politisk ideologi, identitet eller handlinger. Brukes med QR*-koder for å angi bestemte religioner der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JPH',
            label: 'Politiske strukturer og prosesser',
            originalLabel: 'Politiske strukturer og prosesser',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'JPHC',
                label: 'Konstitusjon: regjeringen og staten',
                originalLabel: 'Konstitusjon: regjeringen og staten',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JPHF',
                label: 'Valg og folkeavstemninger',
                originalLabel: 'Valg og folkeavstemninger',
                alternativeLabels: [],
                note: 'Her: analyse av valgprosedyrer, valgordninger, stemmerett. Brukes for bøker både om generelle bøker om valgforskning og for bøker om spesifikke valg, studier om velgeratferd, ulike typer valgordninger. Brukes med JPZ for bøker om korrupsjon eller manipulering av valg',
                related: [],
                children: [],
              },
              {
                id: 'JPHL',
                label: 'Politiske ledere og lederskap',
                originalLabel: 'Politiske ledere og lederskap',
                alternativeLabels: [
                  'presidenter',
                  'statsministre',
                  'diktatorer',
                  'statsoverhoder',
                ],
                note: 'Her: presidenter, statsministere, partiledere. Brukes for bøker både om politiske ledere og politisk lederskap',
                related: [],
                children: [],
              },
              {
                id: 'JPHV',
                label: 'Demokrati',
                originalLabel: 'Politiske strukturer: demokrati',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JPHX',
                label: 'Totalitarisme og diktatur',
                originalLabel:
                  'Politiske strukturer: totalitarisme og diktatur',
                alternativeLabels: [],
                note: 'Her: tyranni, absolutisme, autoritære styreformer',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JPL',
            label: 'Politiske partier',
            originalLabel: 'Politiske partier',
            alternativeLabels: [],
            note: 'Her: politiske partiers plattformer. Brukes for bøker om et eller flere politiske partier, eller om ulike politiske plattformer og programmer. Brukes med JPF*-koder for bøker om spesifikke politiske partier',
            related: [],
            children: [],
          },
          {
            id: 'JPN',
            label: 'Urbefolkning: forvaltning og administrasjon',
            originalLabel: 'Urbefolkning: forvaltning og administrasjon',
            alternativeLabels: ['urfolk'],
            note: 'Her: Sametinget',
            related: [],
            children: [],
          },
          {
            id: 'JPP',
            label: 'Offentlig administrasjon',
            originalLabel: 'Offentlig administrasjon',
            alternativeLabels: [],
            note: 'Se også KNV <a href="/thema/nb/KNV">Offentlige tjenester og offentlig sektor</a>',
            related: [
              {
                id: 'KNV',
                label: 'Offentlige tjenester og offentlig sektor',
                originalLabel: 'Offentlige tjenester og offentlig sektor',
                note: 'Se også JPP <a href="/thema/nb/JPP">Offentlig administrasjon</a>',
              },
            ],
            children: [],
          },
          {
            id: 'JPQ',
            label: 'Statlig / nasjonal / føderal myndighet',
            originalLabel: 'Statlig / nasjonal / føderal myndighet',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'JPQB',
                label: 'Statlig / nasjonal / føderal politikk',
                originalLabel: 'Statlig / nasjonal / føderal politikk',
                alternativeLabels: [],
                note: 'Se også KCP <a href="/thema/nb/KCP">Politisk økonomi</a>',
                related: [
                  {
                    id: 'KCP',
                    label: 'Politisk økonomi',
                    originalLabel: 'Politisk økonomi',
                    note: 'Her: sosialøkonomi',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'JPR',
            label: 'Regionalforvaltning',
            originalLabel: 'Regionalforvaltning',
            alternativeLabels: [],
            note: 'Her: myndigheter i et land som ikke er kommunale eller bybasert, de skal klassifiseres i JPT. Se også JPT <a href="/thema/nb/JPT">Kommuneforvaltning</a>',
            related: [
              {
                id: 'JPT',
                label: 'Kommuneforvaltning',
                originalLabel: 'Kommuneforvaltning',
                note: 'Brukes for byregioner (f. eks. Greater London, Bay Area), byområder og andre viktige underavdelinger som bydeler',
              },
            ],
            children: [
              {
                id: 'JPRB',
                label: 'Regionalpolitikk',
                originalLabel: 'Regionalpolitikk',
                alternativeLabels: [],
                note: 'Her: politikken til et lands myndigheter som ikke er kommunale eller bybasert, de skal klassifiseres i JPT. Se også JPT <a href="/thema/nb/JPT">Kommuneforvaltning</a>',
                related: [
                  {
                    id: 'JPT',
                    label: 'Kommuneforvaltning',
                    originalLabel: 'Kommuneforvaltning',
                    note: 'Brukes for byregioner (f. eks. Greater London, Bay Area), byområder og andre viktige underavdelinger som bydeler',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'JPS',
            label: 'Internasjonale forhold',
            originalLabel: 'Internasjonale forhold',
            alternativeLabels: [],
            note: 'Her: internasjonal politikk, utenrikspolitikk',
            related: [],
            children: [
              {
                id: 'JPSD',
                label: 'Diplomati',
                originalLabel: 'Diplomati',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JPSF',
                label: 'Våpenforhandlinger og -kontroll',
                originalLabel: 'Våpenforhandlinger og -kontroll',
                alternativeLabels: ['våpenkontroll'],
                note: 'Her: rustningskappløp, våpenlagring, nedrusting',
                related: [],
                children: [],
              },
              {
                id: 'JPSH',
                label: 'Spionasje og hemmelige tjenester',
                originalLabel: 'Spionasje og hemmelige tjenester',
                alternativeLabels: [
                  'spioner',
                  'agenter',
                  'hemmelige agenter',
                  'etterretningstjenester',
                  'etterretningstjenesten',
                  'MI5',
                  'MI6',
                  'NSA',
                  'CIA',
                  'PST',
                  'kontraspionasje',
                ],
                note: 'Her: overvåking, militær etterretning, kontraetterretning',
                related: [],
                children: [],
              },
              {
                id: 'JPSL',
                label: 'Geopolitikk',
                originalLabel: 'Geopolitikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JPSN',
                label: 'Internasjonale institusjoner',
                originalLabel: 'Internasjonale institusjoner',
                alternativeLabels: [
                  'Verdensbanken',
                  'Forente nasjoner',
                  'UNESCO',
                  'IMF',
                  'Det internasjonale pengefondet',
                  'WHO',
                  'Verdens helseorganisasjon',
                  'G7',
                  'G20,',
                ],
                note: 'Her: FN og FN-byråer, EU og EU-institusjoner, mellomstatlige og ikke-statlige organisasjoner etc. Brukes med 1QF*-kvalifikatorer fra geografi der det er hensiktsmessig. Se også GTV <a href="/thema/nb/GTV">Institusjoner og akademiske miljø</a>',
                related: [
                  {
                    id: 'GTV',
                    label: 'Institusjoner og akademiske miljø',
                    originalLabel: 'Institusjoner og akademiske miljø',
                    note: 'Se også JPSN <a href="/thema/nb/JPSN">Internasjonale institusjoner</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'JPT',
            label: 'Kommuneforvaltning',
            originalLabel: 'Kommuneforvaltning',
            alternativeLabels: ['Bystyre'],
            note: 'Brukes for byregioner (f. eks. Greater London, Bay Area), byområder og andre viktige underavdelinger som bydeler',
            related: [],
            children: [],
          },
          {
            id: 'JPV',
            label: 'Politisk kontroll og frihet',
            originalLabel: 'Politisk kontroll og frihet',
            alternativeLabels: [],
            note: 'Her: politisk propaganda',
            related: [],
            children: [
              {
                id: 'JPVC',
                label: 'Statsborgerskap',
                originalLabel: 'Statsborgerskap',
                alternativeLabels: [],
                note: 'Her: digitalt medborgerskap, politisk deltakelse, rettigheter og plikter knyttet til lovgivningen i det enkelte land, tap av statsborgerskap, etc.',
                related: [],
                children: [],
              },
              {
                id: 'JPVH',
                label: 'Menneskerettigheter og borgerrettigheter',
                originalLabel: 'Menneskerettigheter og borgerrettigheter',
                alternativeLabels: [],
                note: 'Her: ytrings-, informasjons- og religionsfrihet; landrettigheter, sivile og politiske rettigheter, økonomiske, sosiale og kulturelle rettigheter, etc. Brukes med GTU for bøker om overgangsrett. Se også LNDC <a href="/thema/nb/LNDC">Jus: menneskerettigheter og borgerrettigheter</a>',
                related: [
                  {
                    id: 'LNDC',
                    label: 'Jus: menneskerettigheter og borgerrettigheter',
                    originalLabel:
                      'Jus: menneskerettigheter og borgerrettigheter',
                    note: 'Her: grunnleggende rettigheter slik de praktiseres i en jurisdiksjon. Se også JPVH <a href="/thema/nb/JPVH">Menneskerettigheter og borgerrettigheter</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'JPVR',
                label: 'Politisk undertrykkelse og forfølgelse',
                originalLabel: 'Politisk undertrykkelse og forfølgelse',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'JPVR1',
                    label:
                      'Politisk bortføring, fengsling, ‘forsvinning’ og attentat',
                    originalLabel:
                      'Politisk bortføring, fengsling, ‘forsvinning’ og attentat',
                    alternativeLabels: ['kidnapping'],
                    note: 'Brukes for bøker om bortføringer, fengsling eller drap som utføres som politisk undertrykkelse, ‘de forsvunne’ i ulike samfunn, søking etter forsvunne personer etc. Brukes med LBBR eller LNDC for juridiske aspekter eller NH*-koder for historisk materiale',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'JPW',
            label: 'Politisk aktivisme',
            originalLabel: 'Politisk aktivisme',
            alternativeLabels: ['aktivister'],
            note: 'Her: aktivister, sosial aktivisme. Brukes med DNB*-koder for biografier om aktivister',
            related: [],
            children: [
              {
                id: 'JPWA',
                label: 'Opinion og meningsmålinger',
                originalLabel: 'Opinion og meningsmålinger',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JPWB',
                label: 'Lobbyvirksomhet',
                originalLabel: 'Lobbyvirksomhet',
                alternativeLabels: [],
                note: 'Brukes for bøker som ser på hvordan interesseorganisasjoner, PR-firmaer, pressgrupper, næringsliv osv. påvirker politikere og andre beslutningstakere med hensyn til lovgivning, regulereringer, retningslinjer, skatt osv. Se også KJSP <a href="/thema/nb/KJSP">PR</a>',
                related: [
                  {
                    id: 'KJSP',
                    label: 'PR',
                    originalLabel: 'PR',
                  },
                ],
                children: [],
              },
              {
                id: 'JPWC',
                label: 'Politisk kampanje og reklame',
                originalLabel: 'Politisk kampanje og reklame',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JPWG',
                label:
                  'Interessegrupper, protestbevegelser og ikke-voldelige grupperinger',
                originalLabel:
                  'Interessegrupper, protestbevegelser og ikke-voldelige grupperinger',
                alternativeLabels: [],
                note: 'Her: demonstrasjoner, sivil ulydighet, passiv motstand, aktiv motstand mot autoriteter, aktivistkampanjer',
                related: [],
                children: [],
              },
              {
                id: 'JPWH',
                label: 'Ikke-statlige organisasjoner',
                originalLabel: 'Ikke-statlige organisasjoner',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JPWL',
                label: 'Terrorisme og væpnet kamp',
                originalLabel: 'Terrorisme og væpnet kamp',
                alternativeLabels: [
                  'terrorister',
                  'sikkerhetstrusler',
                  'gerilja',
                  '22. juli',
                ],
                note: 'Her: politiske attentat. Se også JWCG <a href="/thema/nb/JWCG">Ureglementerte styrker, geriljastyrker og krigføring</a>',
                related: [
                  {
                    id: 'JWCG',
                    label:
                      'Ureglementerte styrker, geriljastyrker og krigføring',
                    originalLabel:
                      'Ureglementerte styrker, geriljastyrker og krigføring',
                    note: 'Her: væpnede motstandsgrupper, opprørere, fremmedkrigere. Brukes for bøker om ikke-militære styrker som kjemper for politiske, økonomiske, ideologiske eller religiøse grunner. Brukes med NHW*-koder og kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig',
                  },
                ],
                children: [],
              },
              {
                id: 'JPWQ',
                label: 'Revolusjonære grupper og bevegelser',
                originalLabel: 'Revolusjonære grupper og bevegelser',
                alternativeLabels: ['rebeller', 'gerilja'],
                note: 'Se også NHTV <a href="/thema/nb/NHTV">Revolusjoner, oppstander og opprør</a>',
                related: [
                  {
                    id: 'NHTV',
                    label: 'Revolusjoner, oppstander og opprør',
                    originalLabel: 'Revolusjoner, oppstander og opprør',
                    note: 'Her: voldelige forsøk på å endre eksisterende system, statskupp etc. Brukes med NHW* for militærhistoriske bøker som handler om kriger eller konflikter i forbindelse med revolusjoner, oppstander, opprør etc. Se også JPWQ <a href="/thema/nb/JPWQ">Revolusjonære grupper og bevegelser</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'JPWS',
                label: 'Væpnede konflikter',
                originalLabel: 'Væpnede konflikter',
                alternativeLabels: [],
                note: 'Her: opprør, væpnede konflikter, krigsutsatte områder, organisert vold',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JPZ',
            label: 'Politisk korrupsjon',
            originalLabel: 'Politisk korrupsjon',
            alternativeLabels: [],
            note: 'Her: korrupsjon i forvaltning, regjering og offentlig sektor, korrupsjon i rettsvesen og politi, bestikkelser og korrupsjon i dagliglivet etc. Se også JKV* <a href="/thema/nb/JKV">Kriminalitet og kriminologi</a> og dens underkategorier',
            related: [
              {
                id: 'JKV',
                label: 'Kriminalitet og kriminologi',
                originalLabel: 'Kriminalitet og kriminologi',
                note: 'Se også DNXC <a href="/thema/nb/DNXC">Historier fra virkeligheten: forbrytelser</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'JW',
        label: 'Krigføring og forsvar',
        originalLabel: 'Krigføring og forsvar',
        alternativeLabels: ['nasjonal sikkerhet', 'krig'],

        related: [],
        children: [
          {
            id: 'JWA',
            label: 'Krigsteori og militærvitenskap',
            originalLabel: 'Krigsteori og militærvitenskap',
            alternativeLabels: ['krig'],
            note: 'Brukes med RG*-koder for bøker om militær geografi',
            related: [],
            children: [],
          },
          {
            id: 'JWC',
            label: 'Militære styrker og sektorer',
            originalLabel: 'Militære styrker og sektorer',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'JWCD',
                label: 'Landstyrker og krigføring',
                originalLabel: 'Landstyrker og krigføring',
                alternativeLabels: ['krig', 'hær', 'soldater'],
                note: 'Her: hæren, infanterier, krigføring og operasjoner på land. Brukes med NHW* for militærhistorie, JWMV og enten WG* for bøker om spesielle typer militære landkjøretøy, eller TRC* for tekniske aspekter etc.',
                related: [],
                children: [],
              },
              {
                id: 'JWCG',
                label: 'Ureglementerte styrker, geriljastyrker og krigføring',
                originalLabel:
                  'Ureglementerte styrker, geriljastyrker og krigføring',
                alternativeLabels: [
                  'motstandsarbeid',
                  'frihetskjempere',
                  'privat hær',
                  'leiesoldater',
                  'opprørere',
                  'gerilja',
                ],
                note: 'Her: væpnede motstandsgrupper, opprørere, fremmedkrigere. Brukes for bøker om ikke-militære styrker som kjemper for politiske, økonomiske, ideologiske eller religiøse grunner. Brukes med NHW*-koder og kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'JWCK',
                label: 'Sjøstyrker og krigføring',
                originalLabel: 'Sjøstyrker og krigføring',
                alternativeLabels: [],
                note: 'Her: marinen, sjøoperasjoner, ubåtkrig, amfibisk krigføring osv. Brukes med NHW* for militærhistorie eller NHTM for maritim historie, JWMV og enten WGG* for bøker om militære skip, eller TRL* for tekniske aspekter etc. Se også NHTM <a href="/thema/nb/NHTM">Maritim historie</a>',
                related: [
                  {
                    id: 'NHTM',
                    label: 'Maritim historie',
                    originalLabel: 'Maritim historie',
                    note: 'Her: sjøfartshistorie om sjøreiser, sjømenn, sjørøveri, hvalfangst, fiskeri, navigasjon, handel, utforskning og oppdagelser, shipping, kystsamfunn, marinen etc. Brukes for historisk behandling av alle aspekter ved menneskelig samhandling eller aktivitet på sjøen. Se også JWCK <a href="/thema/nb/JWCK">Sjøstyrker og krigføring</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'JWCM',
                label: 'Luftstyrker og krigføring',
                originalLabel: 'Luftstyrker og krigføring',
                alternativeLabels: [],
                note: 'Her: luftforsvaret, luftoperasjoner, fallskjermoperasjoner, luftangrep, strategisk bombing, missiler, droner osv. Brukes med NHW* for militærhistorie, JWMV og enten WGM for spesielle typer luftfartøy, eller TRP for tekniske aspekter etc.',
                related: [],
                children: [],
              },
              {
                id: 'JWCS',
                label: 'Spesial- og elitestyrker',
                originalLabel: 'Spesial- og elitestyrker',
                alternativeLabels: ['spesialsoldater', 'elitesoldater'],
                note: 'Class here: special operations forces',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JWD',
            label: 'Informasjonskrigføring',
            originalLabel: 'Informasjonskrigføring',
            alternativeLabels: [],
            note: 'Her: cyberkrigføring, cyberforsvar, cyberangrep, dataangrep, AI-våpen og -krigføring, etc. Se også LNQE <a href="/thema/nb/LNQE">Datakriminalitet, internettkriminalitet</a>, UR <a href="/thema/nb/UR">Datasikkerhet</a>',
            related: [
              {
                id: 'LNQE',
                label: 'Datakriminalitet, internettkriminalitet',
                originalLabel: 'Datakriminalitet, internettkriminalitet',
              },
              {
                id: 'UR',
                label: 'Datasikkerhet',
                originalLabel: 'Datasikkerhet',
              },
            ],
            children: [],
          },
          {
            id: 'JWJ',
            label: 'Militæradministrasjon',
            originalLabel: 'Militæradministrasjon',
            alternativeLabels: [],
            note: 'Her: personalsaker, disiplin, anskaffelser, finansiering etc.',
            related: [],
            children: [],
          },
          {
            id: 'JWK',
            label: 'Militær- og forsvarsstrategi',
            originalLabel: 'Militær- og forsvarsstrategi',
            alternativeLabels: [],
            note: 'Her: militærtaktikk, sivilforsvar, nasjonal sikkerhet',
            related: [],
            children: [
              {
                id: 'JWKF',
                label: 'Militær etterretning',
                originalLabel: 'Militær etterretning',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JWL',
            label: 'Krigs- og forsvarsoperasjoner',
            originalLabel: 'Krigs- og forsvarsoperasjoner',
            alternativeLabels: [],
            note: 'Se også NHW* <a href="/thema/nb/NHW">Militærhistorie</a> og dens underkategorier',
            related: [
              {
                id: 'NHW',
                label: 'Militærhistorie',
                originalLabel: 'Militærhistorie',
                note: 'Med alle NHW*-koder, tildel kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig. Se også JW* <a href="/thema/nb/JW">Krigføring og forsvar</a> og dens underkategorier',
              },
            ],
            children: [
              {
                id: 'JWLF',
                label: 'Kamper og felttog',
                originalLabel: 'Kamper og felttog',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JWLP',
                label: 'Fredsbevarende operasjoner',
                originalLabel: 'Fredsbevarende operasjoner',
                alternativeLabels: [],
                note: 'Se også GTU <a href="/thema/nb/GTU">Fredsstudier og konfliktløsning</a>',
                related: [
                  {
                    id: 'GTU',
                    label: 'Fredsstudier og konfliktløsning',
                    originalLabel: 'Fredsstudier og konfliktløsning',
                    note: 'Her: overgangsrett. Se også JWLP <a href="/thema/nb/JWLP">Fredsbevarende operasjoner</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'JWM',
            label: 'Våpen og utstyr',
            originalLabel: 'Våpen og utstyr',
            alternativeLabels: [],
            note: 'Brukes med kode fra JWC for å indikere sektor. Se også WCK <a href="/thema/nb/WCK">Militæreffekter, våpen og rustninger</a>',
            related: [
              {
                id: 'WCK',
                label: 'Militæreffekter, våpen og rustninger',
                originalLabel: 'Militæreffekter, våpen og rustninger',
              },
            ],
            children: [
              {
                id: 'JWMC',
                label: 'Kjemiske og biologiske våpen',
                originalLabel: 'Kjemiske og biologiske våpen',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JWMN',
                label: 'Atomvåpen',
                originalLabel: 'Atomvåpen',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JWMV',
                label: 'Militære kjøretøy',
                originalLabel: 'Militære kjøretøy',
                alternativeLabels: [],
                note: 'Her: militære kjøretøy, både til lands, til sjøs og i lufta. Brukes med kode fra JWC for å indikere sektor og WG* eller TR* der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JWT',
            label: 'Militære institusjoner',
            originalLabel: 'Militære institusjoner',
            alternativeLabels: [],
            note: 'Her: regimenter, minnesmerker, minnetavler, krigskirkegårder, kommandosentre, forsvarsverker, krigsskoler, organisasjoner for veteraner etc. Se også AMG <a href="/thema/nb/AMG">Arkitektur: offentlige bygninger, nærings- og industribygg</a>, WCK <a href="/thema/nb/WCK">Militæreffekter, våpen og rustninger</a>',
            related: [
              {
                id: 'AMG',
                label:
                  'Arkitektur: offentlige bygninger, nærings- og industribygg',
                originalLabel:
                  'Arkitektur: offentlige bygninger, nærings- og industribygg',
                note: 'Her: landbruksbygninger, konserthus, arenaer, stadioner, minnesmerker, monumenter, sjukehus, fyr, bibliotek, museum, galleri, utstillingsområder, stasjoner, flyplasser, hotell, fabrikker, lager, kontorer, butikker etc. Brukes for verk om bygninger som ikke primært er ment som boliger. Brukes med AMR der det er hensiktsmessig. Se også JWT <a href="/thema/nb/JWT">Militære institusjoner</a>, WTHM <a href="/thema/nb/WTHM">Reisehåndbøker: museer, historiske steder, gallerier etc.</a>',
              },
              {
                id: 'WCK',
                label: 'Militæreffekter, våpen og rustninger',
                originalLabel: 'Militæreffekter, våpen og rustninger',
              },
            ],
            children: [
              {
                id: 'JWTU',
                label: 'Militæruniformer / gradsmerker',
                originalLabel: 'Militæruniformer / gradsmerker',
                alternativeLabels: ['uniformer', 'insignier'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'JWX',
            label: 'Andre krigførings- og forsvarsspørsmål',
            originalLabel: 'Andre krigførings- og forsvarsspørsmål',
            alternativeLabels: [],
            note: 'Se også TTM <a href="/thema/nb/TTM">Militærteknikk</a>',
            related: [
              {
                id: 'TTM',
                label: 'Militærteknikk',
                originalLabel: 'Militærteknikk',
              },
            ],
            children: [
              {
                id: 'JWXF',
                label: 'Våpenhandel',
                originalLabel: 'Våpenhandel',
                alternativeLabels: [],
                note: 'Her: bevæpning, våpenindustri',
                related: [],
                children: [],
              },
              {
                id: 'JWXJ',
                label: 'Ikke-stridende',
                originalLabel: 'Ikke-stridende',
                alternativeLabels: [],
                note: 'Her: sivile, journalister, hjelpearbeidere, medisinsk personell, feltprester, nøytrale etc.',
                related: [],
                children: [],
              },
              {
                id: 'JWXK',
                label: 'Krigsforbrytelser',
                originalLabel: 'Krigsforbrytelser',
                alternativeLabels: ['massakre', 'folkemord', 'etnisk rensing'],
                note: 'Her: landssvik. Se også NHTZ <a href="/thema/nb/NHTZ">Folkemord og etnisk rensning</a>, NHTZ1 <a href="/thema/nb/NHTZ1">Holocaust</a>',
                related: [
                  {
                    id: 'NHTZ',
                    label: 'Folkemord og etnisk rensning',
                    originalLabel: 'Folkemord og etnisk rensning',
                  },
                  {
                    id: 'NHTZ1',
                    label: 'Holocaust',
                    originalLabel: 'Holocaust',
                  },
                ],
                children: [],
              },
              {
                id: 'JWXN',
                label: 'Leiesoldater',
                originalLabel: 'Leiesoldater',
                alternativeLabels: [],
                note: 'Her: private hærer og private selskaper som leverer forsvars- og sikkerhetstjenester. Brukes for bøker om soldater som kjemper for penger eller for personlig vinning',
                related: [],
                children: [],
              },
              {
                id: 'JWXR',
                label: 'Krigsfanger',
                originalLabel: 'Krigsfanger',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JWXT',
                label: 'Mytteri',
                originalLabel: 'Mytteri',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JWXV',
                label: 'Militærveteraner',
                originalLabel: 'Militærveteraner',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'JWXZ',
                label: 'Kamp-/forsvarsferdigheter og manualer',
                originalLabel: 'Kamp-/forsvarsferdigheter og manualer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'K',
    label: 'Økonomi, finans, næringsliv og ledelse',
    originalLabel: 'Økonomi, finans, næringsliv og ledelse',
    alternativeLabels: [],
    note: 'Bruk K*-koder både for bøker for høyere utdanning og profesjonsmarkedet, og for bøker for allmennmarkedet, men se også VS*-koder for praktisk tilnærming til mange emner. Bruk alle K*-koder med andre emnekategorier og kvalifikatorer der det er hensiktsmessig',
    related: [],
    children: [
      {
        id: 'KC',
        label: 'Økonomi',
        originalLabel: 'Økonomi',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'KCA',
            label: 'Økonomisk teori og filosofi',
            originalLabel: 'Økonomisk teori og filosofi',
            alternativeLabels: [],
            note: 'Inkluderer økonomifagets historie. Tildel også kvalifikator for tidsperiode der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'KCB',
            label: 'Makroøkonomi',
            originalLabel: 'Makroøkonomi',
            alternativeLabels: [],
            note: 'Her: bøker som gir et samlet overblikk over en økonomis adferd og ytelse, eller som behandler generelle økonomiske faktorer som prisnivå, nasjonalinntekt, sysselsetting, bruttonasjonalprodukt (BNP), inflasjon, rentenivå osv.',
            related: [],
            children: [
              {
                id: 'KCBM',
                label: 'Monetær økonomi',
                originalLabel: 'Monetær økonomi',
                alternativeLabels: ['valuta', 'penger', 'veksling'],
                note: 'Her: pengepolitikk, valutapolitikk, pengeteori, pengesystemer, monetarisme',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'KCC',
            label: 'Mikroøkonomi',
            originalLabel: 'Mikroøkonomi',
            alternativeLabels: [],
            note: 'Her: innenrikshandel; forbrukeraktivitet, handelsavtaler etc.',
            related: [],
            children: [
              {
                id: 'KCCD',
                label: 'Innenlands handel',
                originalLabel: 'Innenlands handel',
                alternativeLabels: [],
                note: 'Her: grossister, detalj- og varehandel. Brukes for bøker om handel med produkter produsert i et land, innenfor dette landets grenser. Se også KCLT <a href="/thema/nb/KCLT">Internasjonal handel</a>',
                related: [
                  {
                    id: 'KCLT',
                    label: 'Internasjonal handel',
                    originalLabel: 'Internasjonal handel',
                    note: 'Her: internasjonale handelsavtaler, frihandel og proteksjonisme, tariffer og kvoter, subsidier, dumping, forhandlinger. Se også KCCD <a href="/thema/nb/KCCD">Innenlands handel</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'KCD',
            label: 'Næringsøkonomi',
            originalLabel: 'Næringsøkonomi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'KCF',
            label: 'Arbeidsmarkedsøkonomi',
            originalLabel: 'Arbeidsmarkedsøkonomi',
            alternativeLabels: ['jobber'],
            note: 'Her: lønnspolitikk, grunninntekt, inntekstvekst, inntektsulikhet, arbeidsmarked, økonomisk arbeid med sysselsetting og arbeidsledighet. Se også JBFC <a href="/thema/nb/JBFC">Fattigdom og utrygghet</a>',
            related: [
              {
                id: 'JBFC',
                label: 'Fattigdom og utrygghet',
                originalLabel: 'Fattigdom og utrygghet',
                note: 'Brukes for bøker om økonomisk vanskeligstilte, bøker om matutdeling, arbeidsledighet eller arbeidsforhold når disse er knyttet til fattigdom, prekariatet, økonomisk usikkerhet, undersysselsetting, kaotisk livsstil, feilernæring som følge av fattigdom, fattigdom selv om man har arbeid etc.',
              },
            ],
            children: [],
          },
          {
            id: 'KCG',
            label: 'Økonomisk vekst',
            originalLabel: 'Økonomisk vekst',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'KCH',
            label: 'Økonometri og økonomisk statistikk',
            originalLabel: 'Økonometri og økonomisk statistikk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'KCJ',
            label: 'Økonomiske prognoser',
            originalLabel: 'Økonomiske prognoser',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'KCK',
            label: 'Adferdsøkonomi',
            originalLabel: 'Adferdsøkonomi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'KCL',
            label: 'Internasjonal økonomi',
            originalLabel: 'Internasjonal økonomi',
            alternativeLabels: [],
            note: 'Class here: international finance. Se også RGCM <a href="/thema/nb/RGCM">Økonomisk geografi</a>',
            related: [
              {
                id: 'RGCM',
                label: 'Økonomisk geografi',
                originalLabel: 'Økonomisk geografi',
              },
            ],
            children: [
              {
                id: 'KCLT',
                label: 'Internasjonal handel',
                originalLabel: 'Internasjonal handel',
                alternativeLabels: [],
                note: 'Her: internasjonale handelsavtaler, frihandel og proteksjonisme, tariffer og kvoter, subsidier, dumping, forhandlinger. Se også KCCD <a href="/thema/nb/KCCD">Innenlands handel</a>',
                related: [
                  {
                    id: 'KCCD',
                    label: 'Innenlands handel',
                    originalLabel: 'Innenlands handel',
                    note: 'Her: grossister, detalj- og varehandel. Brukes for bøker om handel med produkter produsert i et land, innenfor dette landets grenser. Se også KCLT <a href="/thema/nb/KCLT">Internasjonal handel</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'KCM',
            label: 'Utviklingsøkonomi og vekstøkonomier',
            originalLabel: 'Utviklingsøkonomi og vekstøkonomier',
            alternativeLabels: [],
            note: 'Se også GTP <a href="/thema/nb/GTP">Utviklingsstudier</a>',
            related: [
              {
                id: 'GTP',
                label: 'Utviklingsstudier',
                originalLabel: 'Utviklingsstudier',
                note: 'Se også KCM <a href="/thema/nb/KCM">Utviklingsøkonomi og vekstøkonomier</a>',
              },
            ],
            children: [],
          },
          {
            id: 'KCP',
            label: 'Politisk økonomi',
            originalLabel: 'Politisk økonomi',
            alternativeLabels: [],
            note: 'Her: sosialøkonomi',
            related: [],
            children: [],
          },
          {
            id: 'KCS',
            label: 'Økonomiske systemer og strukturer',
            originalLabel: 'Økonomiske systemer og strukturer',
            alternativeLabels: ['føydalisme'],
            note: 'Her: verk om systemer som f.eks. overgangsøkonomi, tradisjonell økonomi, deltagerøkonomi, bytteøkonomi, gaveøkonomi etc.',
            related: [],
            children: [
              {
                id: 'KCSA',
                label: 'Kapitalisme',
                originalLabel: 'Kapitalisme',
                alternativeLabels: ['markedsøkonomi', 'nyliberalisme'],
                note: 'Her: markedsøkonomi, fritt marked, nyliberal økonomi, bedriftskapitalisme, merkantilisme etc. Brukes for bøker som ser på kapitalisme som en økonomisk modell',
                related: [],
                children: [],
              },
              {
                id: 'KCSD',
                label: 'Blandingsøkonomi',
                originalLabel: 'Blandingsøkonomi',
                alternativeLabels: [
                  'dirigisme',
                  'nordisk modell',
                  'sosialistisk markedsøkonomi',
                ],
                note: 'Her: sosial markedsøkonomi, velferdsøkonomi, sosialkorporativisme, statsstøttet kapitalisme etc.',
                related: [],
                children: [],
              },
              {
                id: 'KCSG',
                label: 'Planøkonomi',
                originalLabel: 'Planøkonomi',
                alternativeLabels: [
                  'kommunisme',
                  'marxisme',
                  'leninisme',
                  'sosialisme',
                ],
                note: 'Her: sentralstyrte økonomiske systemer, kommandoøkonomi',
                related: [],
                children: [],
              },
              {
                id: 'KCST',
                label: 'Sirkulærøkonomi',
                originalLabel: 'Sirkulærøkonomi',
                alternativeLabels: ['bærekraft', 'resirkulering'],
                note: 'Brukes for bøker om modeller for sirkulærøkonomi som tar utgangspunkt i reparasjon, gjenbruk og redesign av produkter, og modeller for bærekraftig økonomi',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'KCV',
            label: 'Spesifikke sektorers økonomi',
            originalLabel: 'Spesifikke sektorers økonomi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'KCVD',
                label: 'Landbruksøkonomi',
                originalLabel: 'Landbruksøkonomi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KCVG',
                label: 'Miljøøkonomi',
                originalLabel: 'Miljøøkonomi',
                alternativeLabels: [],
                note: 'Her: grønn økonomi, grønt regnskap, grønn skattepolitikk, økonomiske strategier og politikk for miljøvern, grønn vekst, klimavennlige yrker, bærekraftig utvikling, sirkulær karbonøkonomi, miljøtariff, etc. Brukes for bøker som ser på hvordan økonomien påvirkes av ulike miljøpolitiske vedtak. Se også KFFS <a href="/thema/nb/KFFS">Bærekraftig finans</a>, KJJ <a href="/thema/nb/KJJ">Næringslivet og miljøet. «Grønne» tilnærminger til forretninger</a>',
                related: [
                  {
                    id: 'KFFS',
                    label: 'Bærekraftig finans',
                    originalLabel: 'Bærekraftig finans',
                    note: 'Brukes for bøker som har en miljøvennlig eller bærekraftig tilnærming til finans; finansiell aktivitet som er opprettet for å sikre et bedre miljøresultat; ideer til endringer i finansinstitusjoner for å håndtere miljøspørsmål etc. Brukes med andre K*-koder for spesifikke typer av bærekraftig finans. Se også KCVG <a href="/thema/nb/KCVG">Miljøøkonomi</a>, KJJ <a href="/thema/nb/KJJ">Næringslivet og miljøet. «Grønne» tilnærminger til forretninger</a>',
                  },
                  {
                    id: 'KJJ',
                    label:
                      'Næringslivet og miljøet. «Grønne» tilnærminger til forretninger',
                    originalLabel:
                      'Næringslivet og miljøet. «Grønne» tilnærminger til forretninger',
                    note: 'Her: bærekraft i næringslivet, å skape eller drive en bærekraftig eller grønn bedrift, råd til næringslivet for å nå klimamål, bøker om bedrifters påvirkning på miljøet osv. Se også KFFS <a href="/thema/nb/KFFS">Bærekraftig finans</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KCVJ',
                label: 'Helseøkonomi',
                originalLabel: 'Helseøkonomi',
                alternativeLabels: [],
                note: 'Her: helseindustri, forholdet mellom økonomi, helse og helsetjenester, etc. Se også MBP <a href="/thema/nb/MBP">Helsesystemer og -tjenester</a>',
                related: [
                  {
                    id: 'MBP',
                    label: 'Helsesystemer og -tjenester',
                    originalLabel: 'Helsesystemer og -tjenester',
                    note: 'Her: bøker om sykehus. Brukes for bøker om systemer i helsevesenet, hvordan disse finansieres. Foretrekk KCVJ for bøker som tar for seg helsevesenet fra et økonomisk perspektiv. Se også KCVJ <a href="/thema/nb/KCVJ">Helseøkonomi</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KCVK',
                label: 'Velferdsøkonomi',
                originalLabel: 'Velferdsøkonomi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KCVM',
                label: 'Digital- eller internettøkonomi',
                originalLabel: 'Digital- eller internettøkonomi',
                alternativeLabels: ['digital økonomi', 'digitaløkonomi'],
                note: 'Her: delingsøkonomi, plattformøkonomi og andre økonomiske modeller knyttet til digitale tjenester, kjøp og salg av varer og tjenester gjennom digitale plattformer',
                related: [],
                children: [],
              },
              {
                id: 'KCVP',
                label: 'Kunnskapsøkonomi',
                originalLabel: 'Kunnskapsøkonomi',
                alternativeLabels: ['kunnskapsarbeidere'],
                note: 'Her: informasjonsøkonomi, dataøkonomi',
                related: [],
                children: [],
              },
              {
                id: 'KCVQ',
                label: 'Tertiærnæringsøkonomi',
                originalLabel: 'Tertiærnæringsøkonomi',
                alternativeLabels: ['tertiærsektoren'],
                note: 'Brukes for bøker som tar for seg økonomi i servicenæringen, vanligvis bedrifter som tilbyr immaterielle tjenester som underholdning, forsikring, handel, turisme, banktjenester og andre tjenester. Se også KNS <a href="/thema/nb/KNS">Servicenæringer</a>, KFF <a href="/thema/nb/KFF">Finans</a>',
                related: [
                  {
                    id: 'KFF',
                    label: 'Finans',
                    originalLabel: 'Finans',
                    note: 'Her: finanssektoren. Brukes for generelle bøker om finans, finansielle teorier etc. Brukes med KCVQ for bøker om økonomien i finanssektoren eller KNS for bøker som ser på finansielle tjenester fra et industri- og nærlingslivsperspektiv eller andre K*-koder for finans i bestemte sektorer. Se også VSB <a href="/thema/nb/VSB">Personlig økonomi</a>',
                  },
                  {
                    id: 'KNS',
                    label: 'Servicenæringer',
                    originalLabel: 'Servicenæringer',
                    note: 'Brukes for verk som tar for seg disse bransjene fra et industri-/nærings-/bransjeperspektiv. Se også TTVC <a href="/thema/nb/TTVC">Hotell-, serverings- og restaurantbransjen</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KCVS',
                label: 'Urban økonomi',
                originalLabel: 'Urban økonomi',
                alternativeLabels: [],
                note: 'Her: regional økonomi',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'KCX',
            label: 'Økonomiske og finansielle kriser og katastrofer',
            originalLabel: 'Økonomiske og finansielle kriser og katastrofer',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'KCY',
            label: 'Populærøkonomi',
            originalLabel: 'Populærøkonomi',
            alternativeLabels: [],
            note: 'Her: bøker for allmennmarkedet',
            related: [],
            children: [],
          },
          {
            id: 'KCZ',
            label: 'Økonomisk historie',
            originalLabel: 'Økonomisk historie',
            alternativeLabels: [],
            note: 'Brukes med NH*-koder og/eller kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'KF',
        label: 'Finans og regnskap',
        originalLabel: 'Finans og regnskap',
        alternativeLabels: [],
        note: 'Bruk KF*-koder for lærebøker for profesjonsmarkedet og høyere utdanning. Foretrekk VS*-koder for praktiske råd til allmennmarkedet',
        related: [],
        children: [
          {
            id: 'KFC',
            label: 'Regnskap',
            originalLabel: 'Regnskap',
            alternativeLabels: [],
            note: 'Se også UFK <a href="/thema/nb/UFK">Regnskapsprogrammer</a>',
            related: [
              {
                id: 'UFK',
                label: 'Regnskapsprogrammer',
                originalLabel: 'Regnskapsprogrammer',
              },
            ],
            children: [
              {
                id: 'KFCC',
                label: 'Kostnadsregnskap',
                originalLabel: 'Kostnadsregnskap',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KFCF',
                label: 'Finansregnskap',
                originalLabel: 'Finansregnskap',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KFCM',
                label: 'Økonomistyring og bokføring',
                originalLabel: 'Økonomistyring og bokføring',
                alternativeLabels: [],
                note: 'Her: revisjon',
                related: [],
                children: [
                  {
                    id: 'KFCM1',
                    label: 'Revisjon',
                    originalLabel: 'Revisjon',
                    alternativeLabels: ['revisorer'],
                    note: 'her: internrevisjon og eksternrevisjon',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'KFCM2',
                    label: 'Verdivurdering (finans)',
                    originalLabel: 'Verdivurdering (finans)',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som ser på beregninger, teknikker eller prosesser for å bestemme nåverdien til en eiendel eller et selskap',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'KFCP',
                label: 'Offentlige finansregnskap',
                originalLabel: 'Offentlige finansregnskap',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KFCR',
                label: 'Finansrapportering og årsregnskap',
                originalLabel: 'Finansrapportering og årsregnskap',
                alternativeLabels: [],
                note: 'Her: internasjonale regnskapsstandarder som IFRS og GAAP og bøker om andre internasjonalt og nasjonalt anerkjente regnskapsstandarder',
                related: [],
                children: [],
              },
              {
                id: 'KFCT',
                label: 'Skatteplanlegging og overholdelse av regelverk',
                originalLabel: 'Skatteplanlegging og overholdelse av regelverk',
                alternativeLabels: [],
                note: 'Her: hvordan betale minst mulig skatt på en lovlig måte. Brukes for bøker som forklarer skatteregler for både organisasjoner, bedrifter og enkeltpersoner. Brukes med LNU*-koder for akademiske bøker som ser på regler eller prosedyrer for ulike typer av skatt, eller med KJV* for ulike typer organisasjoner. Se også KFFD <a href="/thema/nb/KFFD">Offentlig økonomi og beskatning</a>',
                related: [
                  {
                    id: 'KFFD',
                    label: 'Offentlig økonomi og beskatning',
                    originalLabel: 'Offentlig økonomi og beskatning',
                    note: 'Her: suverene formuesfond, sosiale formuesfond, offentlig finansforvaltning, finanspolitikk, statens inntekter og utgifter, offentlig gjeld, finansiering av offentlige utgifter, statlige låneopptak, statsbudsjetter, etc. Se også KCP <a href="/thema/nb/KCP">Politisk økonomi</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KFCX',
                label: 'Regnskap: studieveiledninger og repetisjonsguider',
                originalLabel:
                  'Regnskap: studieveiledninger og repetisjonsguider',
                alternativeLabels: [],
                note: 'Her: bøker som brukes til repetisjon og forberedelse for kvalifisering som revisor eller regnskapsfører, f.eks. ACCA, CIMA eller ICAEW. Brukes med 4CP*-koder der det er hensiktsmessig. Se også KFFX <a href="/thema/nb/KFFX">Bank og finans: studieveiledninger og repetisjonsguider</a>',
                related: [
                  {
                    id: 'KFFX',
                    label:
                      'Bank og finans: studieveiledninger og repetisjonsguider',
                    originalLabel:
                      'Bank og finans: studieveiledninger og repetisjonsguider',
                    note: 'Her: bøker som brukes til repetisjon og forberedelse for kvalifisering innen bank, finans, forsikring, f.eks. IMC, CFA, FIA etc. Brukes med 4CP*-koder der det er hensiktsmessig. Se også KFCX <a href="/thema/nb/KFCX">Regnskap: studieveiledninger og repetisjonsguider</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'KFF',
            label: 'Finans',
            originalLabel: 'Finans',
            alternativeLabels: ['fintech'],
            note: 'Her: finanssektoren. Brukes for generelle bøker om finans, finansielle teorier etc. Brukes med KCVQ for bøker om økonomien i finanssektoren eller KNS for bøker som ser på finansielle tjenester fra et industri- og nærlingslivsperspektiv eller andre K*-koder for finans i bestemte sektorer. Se også VSB <a href="/thema/nb/VSB">Personlig økonomi</a>',
            related: [
              {
                id: 'VSB',
                label: 'Personlig økonomi',
                originalLabel: 'Personlig økonomi',
                note: 'Her: råd om det å forvalte pengene sine, inntekt, husholdningsbudsjett, familiens økonomi, økonomisk motstandskraft, lån, pensjon, investeringer, sparepenger, gjeld, skatt, tips for å spare eller andre aspekter ved personlig økonomi. Brukes med KF* for ulike sider ved regnskap og finans',
              },
            ],
            children: [
              {
                id: 'KFFC',
                label: 'Innsamling, tilskudd, donasjoner',
                originalLabel: 'Innsamling, tilskudd, donasjoner',
                alternativeLabels: [],
                note: 'Her: søke om tilskudd, samle inn penger til ideelle og frivillige organisasjoner. Brukes med KJVX for innsamling av penger til ideelle organisasjoner. Se også JKSN1 <a href="/thema/nb/JKSN1">Veldedighet, frivillighetsarbeid og filantropi</a>',
                related: [
                  {
                    id: 'JKSN1',
                    label: 'Veldedighet, frivillighetsarbeid og filantropi',
                    originalLabel:
                      'Veldedighet, frivillighetsarbeid og filantropi',
                  },
                ],
                children: [],
              },
              {
                id: 'KFFD',
                label: 'Offentlig økonomi og beskatning',
                originalLabel: 'Offentlig økonomi og beskatning',
                alternativeLabels: ['Oljefondet'],
                note: 'Her: suverene formuesfond, sosiale formuesfond, offentlig finansforvaltning, finanspolitikk, statens inntekter og utgifter, offentlig gjeld, finansiering av offentlige utgifter, statlige låneopptak, statsbudsjetter, etc. Se også KCP <a href="/thema/nb/KCP">Politisk økonomi</a>',
                related: [
                  {
                    id: 'KCP',
                    label: 'Politisk økonomi',
                    originalLabel: 'Politisk økonomi',
                    note: 'Her: sosialøkonomi',
                  },
                ],
                children: [],
              },
              {
                id: 'KFFF',
                label: 'Finansteknologi',
                originalLabel: 'Finansteknologi',
                alternativeLabels: [],
                note: 'Her: fintech. Brukes for bøker som ser på bruken eller virkningen av nye teknologier i finanssektoren. Brukes med andre K*-koder og U*-koder for tekniske tilnærminger, for eksempel UNKD for bruk av kryptovaluta, eller UYQ* bruk av AI. Se også UNKD <a href="/thema/nb/UNKD">Distributed ledger-teknologi / blokkjede</a>',
                related: [
                  {
                    id: 'UNKD',
                    label: 'Distributed ledger-teknologi / blokkjede',
                    originalLabel: 'Distributed ledger-teknologi / blokkjede',
                    note: 'Her: kryptovaluta, generering av nye bitcoins (mining), altcion, Non-fungible token (NFT). Brukes med UBK for bøker om miljøpåvirkning, eller med KF* eller KC* for en økonomisk tilnærming til emnet. Se også KFFJ <a href="/thema/nb/KFFJ">Valuta</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KFFH',
                label: 'Bedriftsfinans',
                originalLabel: 'Bedriftsfinans',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KFFJ',
                label: 'Valuta',
                originalLabel: 'Valuta',
                alternativeLabels: ['utenlandsk valuta', 'bitcoin'],
                note: 'Her: valutahandel, pengemarkeder, kryptovaluta (fra et finans-/investeringssynspunkt) etc. Se også UNKD <a href="/thema/nb/UNKD">Distributed ledger-teknologi / blokkjede</a>',
                related: [
                  {
                    id: 'UNKD',
                    label: 'Distributed ledger-teknologi / blokkjede',
                    originalLabel: 'Distributed ledger-teknologi / blokkjede',
                    note: 'Her: kryptovaluta, generering av nye bitcoins (mining), altcion, Non-fungible token (NFT). Brukes med UBK for bøker om miljøpåvirkning, eller med KF* eller KC* for en økonomisk tilnærming til emnet. Se også KFFJ <a href="/thema/nb/KFFJ">Valuta</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KFFK',
                label: 'Bankvirksomhet',
                originalLabel: 'Bankvirksomhet',
                alternativeLabels: [],
                note: 'Her: ulike typer banker, banksektoren, sentralbanker, ansvarlig kapital',
                related: [],
                children: [],
              },
              {
                id: 'KFFL',
                label: 'Kreditt og kredittinstitusjoner',
                originalLabel: 'Kreditt og kredittinstitusjoner',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KFFM',
                label: 'Investering og verdipapirer',
                originalLabel: 'Investering og verdipapirer',
                alternativeLabels: [],
                note: 'Her: råvarer, aksjer og obligasjoner, hedgefond, aksjeinvesteringer, etc. Se også VSB <a href="/thema/nb/VSB">Personlig økonomi</a>',
                related: [
                  {
                    id: 'VSB',
                    label: 'Personlig økonomi',
                    originalLabel: 'Personlig økonomi',
                    note: 'Her: råd om det å forvalte pengene sine, inntekt, husholdningsbudsjett, familiens økonomi, økonomisk motstandskraft, lån, pensjon, investeringer, sparepenger, gjeld, skatt, tips for å spare eller andre aspekter ved personlig økonomi. Brukes med KF* for ulike sider ved regnskap og finans',
                  },
                ],
                children: [],
              },
              {
                id: 'KFFN',
                label: 'Forsikring og aktuarstudier',
                originalLabel: 'Forsikring og aktuarstudier',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KFFP',
                label: 'Pensjoner',
                originalLabel: 'Pensjoner',
                alternativeLabels: [],
                note: 'Se også VSR <a href="/thema/nb/VSR">Pensjonisttilværelsen</a>',
                related: [
                  {
                    id: 'VSR',
                    label: 'Pensjonisttilværelsen',
                    originalLabel: 'Pensjonisttilværelsen',
                    note: 'Her: råd om å forberede seg på å bli pensjonist, førtidspensjon, planlegge for økonomien etter pensjonering etc. Se også KFFP <a href="/thema/nb/KFFP">Pensjoner</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KFFR',
                label: 'Bolig og eiendom',
                originalLabel: 'Bolig og eiendom',
                alternativeLabels: [],
                note: 'Se også VSH <a href="/thema/nb/VSH">Bolig og eiendom for enkeltindividet: kjøp/salg og juridiske spørsmål</a>',
                related: [
                  {
                    id: 'VSH',
                    label:
                      'Bolig og eiendom for enkeltindividet: kjøp/salg og juridiske spørsmål',
                    originalLabel:
                      'Bolig og eiendom for enkeltindividet: kjøp/salg og juridiske spørsmål',
                    note: 'Her: råd om å leie eller leie ut bolig, flytting, eiendomstyper. Se også WK* <a href="/thema/nb/WK">Hjem og vedlikehold av hus</a> og dens underkategorier',
                  },
                ],
                children: [],
              },
              {
                id: 'KFFS',
                label: 'Bærekraftig finans',
                originalLabel: 'Bærekraftig finans',
                alternativeLabels: [],
                note: 'Brukes for bøker som har en miljøvennlig eller bærekraftig tilnærming til finans; finansiell aktivitet som er opprettet for å sikre et bedre miljøresultat; ideer til endringer i finansinstitusjoner for å håndtere miljøspørsmål etc. Brukes med andre K*-koder for spesifikke typer av bærekraftig finans. Se også KCVG <a href="/thema/nb/KCVG">Miljøøkonomi</a>, KJJ <a href="/thema/nb/KJJ">Næringslivet og miljøet. «Grønne» tilnærminger til forretninger</a>',
                related: [
                  {
                    id: 'KCVG',
                    label: 'Miljøøkonomi',
                    originalLabel: 'Miljøøkonomi',
                    note: 'Her: grønn økonomi, grønt regnskap, grønn skattepolitikk, økonomiske strategier og politikk for miljøvern, grønn vekst, klimavennlige yrker, bærekraftig utvikling, sirkulær karbonøkonomi, miljøtariff, etc. Brukes for bøker som ser på hvordan økonomien påvirkes av ulike miljøpolitiske vedtak. Se også KFFS <a href="/thema/nb/KFFS">Bærekraftig finans</a>, KJJ <a href="/thema/nb/KJJ">Næringslivet og miljøet. «Grønne» tilnærminger til forretninger</a>',
                  },
                  {
                    id: 'KJJ',
                    label:
                      'Næringslivet og miljøet. «Grønne» tilnærminger til forretninger',
                    originalLabel:
                      'Næringslivet og miljøet. «Grønne» tilnærminger til forretninger',
                    note: 'Her: bærekraft i næringslivet, å skape eller drive en bærekraftig eller grønn bedrift, råd til næringslivet for å nå klimamål, bøker om bedrifters påvirkning på miljøet osv. Se også KFFS <a href="/thema/nb/KFFS">Bærekraftig finans</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KFFT',
                label: 'Privat formuesforvaltning',
                originalLabel: 'Privat formuesforvaltning',
                alternativeLabels: [],
                note: 'Her: formueplanlegging, personlig økonomistyring, råd for bevaring av formue i flere generasjoner, eller bøker for fagfolk som jobber med formuesforvaltning. Brukes med KFCT eller LNU* for bøker om skatteplanlegging, med L* for bøker om privatrett, med VSB for bøker rettet mot allmennmarkedet',
                related: [],
                children: [],
              },
              {
                id: 'KFFX',
                label:
                  'Bank og finans: studieveiledninger og repetisjonsguider',
                originalLabel:
                  'Bank og finans: studieveiledninger og repetisjonsguider',
                alternativeLabels: [],
                note: 'Her: bøker som brukes til repetisjon og forberedelse for kvalifisering innen bank, finans, forsikring, f.eks. IMC, CFA, FIA etc. Brukes med 4CP*-koder der det er hensiktsmessig. Se også KFCX <a href="/thema/nb/KFCX">Regnskap: studieveiledninger og repetisjonsguider</a>',
                related: [
                  {
                    id: 'KFCX',
                    label: 'Regnskap: studieveiledninger og repetisjonsguider',
                    originalLabel:
                      'Regnskap: studieveiledninger og repetisjonsguider',
                    note: 'Her: bøker som brukes til repetisjon og forberedelse for kvalifisering som revisor eller regnskapsfører, f.eks. ACCA, CIMA eller ICAEW. Brukes med 4CP*-koder der det er hensiktsmessig. Se også KFFX <a href="/thema/nb/KFFX">Bank og finans: studieveiledninger og repetisjonsguider</a>',
                  },
                ],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'KJ',
        label: 'Næringsvirksomhet, administrasjon og ledelse',
        originalLabel: 'Næringsvirksomhet, administrasjon og ledelse',
        alternativeLabels: ['bedriftsadministrasjon'],
        note: 'Bruk KJ*-koder for lærebøker for profesjonsmarkedet og høyere utdanning. Foretrekk VS*-koder for praktiske råd til allmennmarkedet',
        related: [],
        children: [
          {
            id: 'KJB',
            label: 'Bedriftsøkonomi',
            originalLabel: 'Bedriftsøkonomi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'KJBX',
                label:
                  'Bedriftsøkonomi: studieveiledninger og repetisjonsguider',
                originalLabel:
                  'Bedriftsøkonomi: studieveiledninger og repetisjonsguider',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'KJC',
            label: 'Forretningsstrategi',
            originalLabel: 'Forretningsstrategi',
            alternativeLabels: [],
            note: 'forretningsanalyse, strategisk planlegging',
            related: [],
            children: [],
          },
          {
            id: 'KJD',
            label: 'Innovasjon i næringslivet',
            originalLabel: 'Innovasjon i næringslivet',
            alternativeLabels: ['entreprenørskap', 'gründervirksomhet'],
            note: 'Brukes for bøker om effekten av ny teknologi i næringslivet, strategier og innovasjon. Se også KJH <a href="/thema/nb/KJH">Entreprenørskap</a>',
            related: [
              {
                id: 'KJH',
                label: 'Entreprenørskap',
                originalLabel: 'Entreprenørskap',
                note: 'Her: oppstartsbedrifter, gründere og risiko, enhjørningsselskaper, selskaper i tidlig fase. Brukes for bøker som handler om å etablere og utvikle en ny virksomhet eller bedrift',
              },
            ],
            children: [
              {
                id: 'KJDD',
                label: 'Disruptiv innovasjon',
                originalLabel: 'Disruptiv innovasjon',
                alternativeLabels: [],
                note: 'Brukes for utvikling som skaper nye marked og verdinettverk, og som vanligvis forstyrrer og erstatter eksisterende marked',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'KJE',
            label: 'E-handel: forretningsmessige aspekter',
            originalLabel: 'E-handel: forretningsmessige aspekter',
            alternativeLabels: ['internetthandel'],
            note: 'Her: elektronisk handel, netthandel. Brukes for bøker som handler om å kjøpe og selge varer og tjenester på internett og overføring av penger og data for å kunne utføre disse transaksjonene. Se også KJSG <a href="/thema/nb/KJSG">Digital markedsføring</a>',
            related: [
              {
                id: 'KJSG',
                label: 'Digital markedsføring',
                originalLabel: 'Digital markedsføring',
                note: 'Her: bruk av sosiale medier og nettressurser for markedsføring av produkter, markedsføring på internett, søkemotoroptimalisering, oppmerksomhetsøkonomi, opplevelsesindustri, digitale nyhetsbrev, influensere, digitale anbefalingsmotorer, personlig markedsføring. Se også KJE <a href="/thema/nb/KJE">E-handel: forretningsmessige aspekter</a>',
              },
            ],
            children: [],
          },
          {
            id: 'KJF',
            label: 'Konkurranse i næringslivet',
            originalLabel: 'Konkurranse i næringslivet',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'KJG',
            label: 'Etikk og sosialt ansvar i næringslivet',
            originalLabel: 'Etikk og sosialt ansvar i næringslivet',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'KJH',
            label: 'Entreprenørskap',
            originalLabel: 'Entreprenørskap',
            alternativeLabels: ['start-ups'],
            note: 'Her: oppstartsbedrifter, gründere og risiko, enhjørningsselskaper, selskaper i tidlig fase. Brukes for bøker som handler om å etablere og utvikle en ny virksomhet eller bedrift',
            related: [],
            children: [],
          },
          {
            id: 'KJJ',
            label:
              'Næringslivet og miljøet. «Grønne» tilnærminger til forretninger',
            originalLabel:
              'Næringslivet og miljøet. «Grønne» tilnærminger til forretninger',
            alternativeLabels: [],
            note: 'Her: bærekraft i næringslivet, å skape eller drive en bærekraftig eller grønn bedrift, råd til næringslivet for å nå klimamål, bøker om bedrifters påvirkning på miljøet osv. Se også KFFS <a href="/thema/nb/KFFS">Bærekraftig finans</a>',
            related: [
              {
                id: 'KFFS',
                label: 'Bærekraftig finans',
                originalLabel: 'Bærekraftig finans',
                note: 'Brukes for bøker som har en miljøvennlig eller bærekraftig tilnærming til finans; finansiell aktivitet som er opprettet for å sikre et bedre miljøresultat; ideer til endringer i finansinstitusjoner for å håndtere miljøspørsmål etc. Brukes med andre K*-koder for spesifikke typer av bærekraftig finans. Se også KCVG <a href="/thema/nb/KCVG">Miljøøkonomi</a>, KJJ <a href="/thema/nb/KJJ">Næringslivet og miljøet. «Grønne» tilnærminger til forretninger</a>',
              },
            ],
            children: [],
          },
          {
            id: 'KJK',
            label: 'Internasjonal næringsvirksomhet',
            originalLabel: 'Internasjonal næringsvirksomhet',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'KJL',
            label: 'Konsulenttjenester og tilskudd til bedrifter',
            originalLabel: 'Konsulenttjenester og tilskudd til bedrifter',
            alternativeLabels: ['konsulenter'],
            note: 'Her: det å få eller gi råd om å drive en bedrift, inkludert det å skaffe tilskudd, subsidier, forskningsfinansiering',
            related: [],
            children: [],
          },
          {
            id: 'KJM',
            label: 'Ledelse og ledelsesteknikker',
            originalLabel: 'Ledelse og ledelsesteknikker',
            alternativeLabels: [],
            note: 'Se også VSC <a href="/thema/nb/VSC">Råd om karriere og oppnåelse av suksess</a>',
            related: [
              {
                id: 'VSC',
                label: 'Råd om karriere og oppnåelse av suksess',
                originalLabel: 'Råd om karriere og oppnåelse av suksess',
                note: 'Her: presentasjonsteknikk, hjemmekontor. Brukes for bøker som gir karriereråd og om hvordan du kan fungere best mulig på arbeidsplassen. Brukes med 4CP*-koder for forberedelse til tester, prøver eller eksamener, og med andre emnekoder for bransjespesifikke råd',
              },
            ],
            children: [
              {
                id: 'KJMB',
                label: 'Ledelse: lederskap og motivasjon',
                originalLabel: 'Ledelse: lederskap og motivasjon',
                alternativeLabels: [],
                note: 'Her: endringsledelse',
                related: [],
                children: [],
              },
              {
                id: 'KJMD',
                label: 'Lederavgjørelser',
                originalLabel: 'Lederavgjørelser',
                alternativeLabels: [],
                note: 'Se også GPQ <a href="/thema/nb/GPQ">Beslutningsteori</a>',
                related: [
                  {
                    id: 'GPQ',
                    label: 'Beslutningsteori',
                    originalLabel: 'Beslutningsteori',
                    note: 'Se også KJMD <a href="/thema/nb/KJMD">Lederavgjørelser</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KJMK',
                label: 'Kunnskapsforvaltning',
                originalLabel: 'Kunnskapsforvaltning',
                alternativeLabels: [],
                note: 'Se også KCVP <a href="/thema/nb/KCVP">Kunnskapsøkonomi</a>',
                related: [
                  {
                    id: 'KCVP',
                    label: 'Kunnskapsøkonomi',
                    originalLabel: 'Kunnskapsøkonomi',
                    note: 'Her: informasjonsøkonomi, dataøkonomi',
                  },
                ],
                children: [],
              },
              {
                id: 'KJMN',
                label: 'Forretningsprosesser / driftsledelse',
                originalLabel: 'Forretningsprosesser / driftsledelse',
                alternativeLabels: [],
                note: 'Her: forretningsmetoder. Brukes for bøker som ser på alle oppgaver og prosesser en virksomhet tar i bruk for å kunne produsere produkter og tjenester ut til kunder. Se også KJT <a href="/thema/nb/KJT">Operasjonsanalyse</a>',
                related: [
                  {
                    id: 'KJT',
                    label: 'Operasjonsanalyse',
                    originalLabel: 'Operasjonsanalyse',
                    note: 'Se også PBW <a href="/thema/nb/PBW">Anvendt matematikk</a>, KJMN <a href="/thema/nb/KJMN">Forretningsprosesser / driftsledelse</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KJMP',
                label: 'Prosjektledelse',
                originalLabel: 'Prosjektledelse',
                alternativeLabels: ['agile metoder', 'scrum'],
                note: 'Her: agile metoder, Scrum etc., metoder for prosjektledelse',
                related: [],
                children: [],
              },
              {
                id: 'KJMQ',
                label: 'Kvalitetssikring og totalkvalitet',
                originalLabel: 'Kvalitetssikring og totalkvalitet',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KJMT',
                label: 'Tidsstyring',
                originalLabel: 'Tidsstyring',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KJMV',
                label: 'Ledelse innen spesifikke områder',
                originalLabel: 'Ledelse innen spesifikke områder',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'KJMV1',
                    label: 'Budsjettering og økonomistyring',
                    originalLabel: 'Budsjettering og økonomistyring',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'KJMV2',
                    label: 'Personal- og HR-ledelse',
                    originalLabel: 'Personal- og HR-ledelse',
                    alternativeLabels: [],
                    note: 'Her: rekruttering, ansettelser, oppsigelser, coaching av ansatte, personalpolitikk, administrering av lønn. Se også VSC <a href="/thema/nb/VSC">Råd om karriere og oppnåelse av suksess</a>',
                    related: [
                      {
                        id: 'VSC',
                        label: 'Råd om karriere og oppnåelse av suksess',
                        originalLabel:
                          'Råd om karriere og oppnåelse av suksess',
                        note: 'Her: presentasjonsteknikk, hjemmekontor. Brukes for bøker som gir karriereråd og om hvordan du kan fungere best mulig på arbeidsplassen. Brukes med 4CP*-koder for forberedelse til tester, prøver eller eksamener, og med andre emnekoder for bransjespesifikke råd',
                      },
                    ],
                    children: [
                      {
                        id: 'KJMV21',
                        label: 'Prestasjonsledelse og -vurdering',
                        originalLabel: 'Prestasjonsledelse og -vurdering',
                        alternativeLabels: [],
                        note: 'Her: medarbeidersamtaler, evaluering. Se også VSC <a href="/thema/nb/VSC">Råd om karriere og oppnåelse av suksess</a>',
                        related: [
                          {
                            id: 'VSC',
                            label: 'Råd om karriere og oppnåelse av suksess',
                            originalLabel:
                              'Råd om karriere og oppnåelse av suksess',
                            note: 'Her: presentasjonsteknikk, hjemmekontor. Brukes for bøker som gir karriereråd og om hvordan du kan fungere best mulig på arbeidsplassen. Brukes med 4CP*-koder for forberedelse til tester, prøver eller eksamener, og med andre emnekoder for bransjespesifikke råd',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'KJMV22',
                        label:
                          'Mangfold, likeverd og inkludering på arbeidsplassen',
                        originalLabel:
                          'Mangfold, likeverd og inkludering på arbeidsplassen',
                        alternativeLabels: ['likhet'],
                        note: 'Brukes for bøker om sosial rettferdighet og strategier for å skape et mangfoldig, likeverdig og inkluderende arbeidsmiljø, eller bøker som tar for seg ulikhet, ekskludering og diskriminering på jobben. Brukes med JBK4 for mobbing eller trakassering på arbeidsplassen, eller JBS* for retningslinjer for spesielle grupper',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'KJMV3',
                    label: 'Administrasjon av IT- og informasjonssystemer',
                    originalLabel:
                      'Administrasjon av IT- og informasjonssystemer',
                    alternativeLabels: [],
                    note: 'Her: datasenteradministrasjon, maskinvareforvaltning, programvareforvaltning, administrasjon av infrastruktur, programvarelisensiering, IT-support etc. Brukes for forretnings- og ledelsestilnærminger. Foretrekk U*-koder for bøker som er mer teknisk rettet',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'KJMV4',
                    label: 'Forvaltning av eiendeler: eiendom og anlegg',
                    originalLabel:
                      'Forvaltning av eiendeler: eiendom og anlegg',
                    alternativeLabels: [],
                    note: 'Her: forvaltning av fysiske eiendeler og eiendommer, infrastrukturressurser, forvaltning av bedriftskapital',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'KJMV5',
                    label: 'Produksjons- og kvalitetsledelse',
                    originalLabel: 'Produksjons- og kvalitetsledelse',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'KJMV6',
                    label: 'Forsknings- og utviklingsledelse',
                    originalLabel: 'Forsknings- og utviklingsledelse',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'KJMV7',
                    label: 'Salgs- og markedsføringsledelse',
                    originalLabel: 'Salgs- og markedsføringsledelse',
                    alternativeLabels: [],
                    note: 'Se også KJS <a href="/thema/nb/KJS">Salg og markedsføring</a>',
                    related: [
                      {
                        id: 'KJS',
                        label: 'Salg og markedsføring',
                        originalLabel: 'Salg og markedsføring',
                        note: 'Her: salgsteknikker. Se også KJMV7 <a href="/thema/nb/KJMV7">Salgs- og markedsføringsledelse</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'KJMV8',
                    label: 'Innkjøpsledelse',
                    originalLabel: 'Innkjøpsledelse',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'KJMV9',
                    label: 'Distribusjons- og logistikkledelse',
                    originalLabel: 'Distribusjons- og logistikkledelse',
                    alternativeLabels: [],
                    note: 'Se også KNP <a href="/thema/nb/KNP">Detaljhandel og grossistnæringer</a>',
                    related: [
                      {
                        id: 'KNP',
                        label: 'Detaljhandel og grossistnæringer',
                        originalLabel: 'Detaljhandel og grossistnæringer',
                        note: 'Her: distribusjon, varehus. Brukes for verk som tar for seg disse bransjene fra et industri-/nærings-/ bransjeperspektiv. Se også KJMV9 <a href="/thema/nb/KJMV9">Distribusjons- og logistikkledelse</a>',
                      },
                    ],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'KJN',
            label: 'Næringslivsforhandlinger',
            originalLabel: 'Næringslivsforhandlinger',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'KJP',
            label: 'Kommunikasjon og presentasjon i næringslivet',
            originalLabel: 'Kommunikasjon og presentasjon i næringslivet',
            alternativeLabels: ['Powerpoint', 'Teams', 'Zoom'],
            note: 'Her: teknikker og ferdigheter for jobbpresentasjoner, arbeidsmøter, forretningsetikette, digitale møter, webinarer eller konferanser',
            related: [],
            children: [],
          },
          {
            id: 'KJQ',
            label: 'Matematikk for økonomer og forretningssystemer',
            originalLabel: 'Matematikk for økonomer og forretningssystemer',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'KJR',
            label:
              'Eierstyring og selskapsledelse: styrer og direktørers rolle og ansvar',
            originalLabel:
              'Eierstyring og selskapsledelse: styrer og direktørers rolle og ansvar',
            alternativeLabels: [],
            note: 'Her: roller og ansvar for generalforsamling, styre, direktører etc. Se også KJG <a href="/thema/nb/KJG">Etikk og sosialt ansvar i næringslivet</a>',
            related: [
              {
                id: 'KJG',
                label: 'Etikk og sosialt ansvar i næringslivet',
                originalLabel: 'Etikk og sosialt ansvar i næringslivet',
              },
            ],
            children: [],
          },
          {
            id: 'KJS',
            label: 'Salg og markedsføring',
            originalLabel: 'Salg og markedsføring',
            alternativeLabels: [],
            note: 'Her: salgsteknikker. Se også KJMV7 <a href="/thema/nb/KJMV7">Salgs- og markedsføringsledelse</a>',
            related: [
              {
                id: 'KJMV7',
                label: 'Salgs- og markedsføringsledelse',
                originalLabel: 'Salgs- og markedsføringsledelse',
                note: 'Se også KJS <a href="/thema/nb/KJS">Salg og markedsføring</a>',
              },
            ],
            children: [
              {
                id: 'KJSA',
                label: 'Reklame',
                originalLabel: 'Reklame',
                alternativeLabels: [],
                note: 'Class here: advertising industry',
                related: [],
                children: [],
              },
              {
                id: 'KJSC',
                label: 'Merker og merkevarebygging',
                originalLabel: 'Merker og merkevarebygging',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KJSG',
                label: 'Digital markedsføring',
                originalLabel: 'Digital markedsføring',
                alternativeLabels: [],
                note: 'Her: bruk av sosiale medier og nettressurser for markedsføring av produkter, markedsføring på internett, søkemotoroptimalisering, oppmerksomhetsøkonomi, opplevelsesindustri, digitale nyhetsbrev, influensere, digitale anbefalingsmotorer, personlig markedsføring. Se også KJE <a href="/thema/nb/KJE">E-handel: forretningsmessige aspekter</a>',
                related: [
                  {
                    id: 'KJE',
                    label: 'E-handel: forretningsmessige aspekter',
                    originalLabel: 'E-handel: forretningsmessige aspekter',
                    note: 'Her: elektronisk handel, netthandel. Brukes for bøker som handler om å kjøpe og selge varer og tjenester på internett og overføring av penger og data for å kunne utføre disse transaksjonene. Se også KJSG <a href="/thema/nb/KJSG">Digital markedsføring</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KJSJ',
                label: 'Direktemarkedsføring / telefonsalg',
                originalLabel: 'Direktemarkedsføring / telefonsalg',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KJSM',
                label: 'Markedsundersøkelser',
                originalLabel: 'Markedsundersøkelser',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KJSP',
                label: 'PR',
                originalLabel: 'PR',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KJSR',
                label: 'Sosial markedsføring',
                originalLabel: 'Sosial markedsføring',
                alternativeLabels: [],
                note: 'Brukes for bøker som ser på hvordan organisasjoner, bedrifter og merkevarer bruker markedsføring for å både øke profitt og samtidig skape sosial forbedring i samfunnet. Brukes med JP*-koder for bruk av sosial markedsføring i politikk eller regjering, KN*-koder for bøker som tar for seg ulike industrisektorer osv. For Digital markedsføring, se KJSG',
                related: [],
                children: [],
              },
              {
                id: 'KJST',
                label:
                  'Grønnvasking og andre former for villedende markedsføring',
                originalLabel:
                  'Grønnvasking og andre former for villedende markedsføring',
                alternativeLabels: [],
                note: 'Brukes for bøker, ofte kritiske, som ser på hvordan bedrifter, organisasjoner, politiske partier, kjendiser etc. bruker årsaker eller temaer knyttet til sosial rettferdighet til markedsføringsformål, PR-formål eller bedrag, med liten eller ingen reell forpliktelse til den faktiske årsaken bak dette. Brukes med JP*-koder for bøker om villedende markedsføring i politikk eller regjering, KN*-koder for bøker som tar for seg ulike industrisektorer osv. Se også JBCT5 <a href="/thema/nb/JBCT5">Desinformasjon og feilinformasjon</a>',
                related: [
                  {
                    id: 'JBCT5',
                    label: 'Desinformasjon og feilinformasjon',
                    originalLabel: 'Desinformasjon og feilinformasjon',
                    note: 'Her: propaganda, falske nyheter, manipulerte eller falske bilder og videoer. Brukes for målbevisst bruk av falsk eller feil informasjon, manipulasjon av tanker og handlinger ved bruk av informasjon, spredning av villedende og ikke-faktabasert informasjon. Brukes med JBCT1 for bøker om digitale plattformer eller sosiale mediers rolle, med JBGX for desinformasjon og feilinformasjon knyttet til konspirasjonsteorier. Se også KJST <a href="/thema/nb/KJST">Grønnvasking og andre former for villedende markedsføring</a>, JBGX <a href="/thema/nb/JBGX">Konspirasjonsteorier</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KJSU',
                label: 'Kundeservice',
                originalLabel: 'Kundeservice',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'KJT',
            label: 'Operasjonsanalyse',
            originalLabel: 'Operasjonsanalyse',
            alternativeLabels: [],
            note: 'Se også PBW <a href="/thema/nb/PBW">Anvendt matematikk</a>, KJMN <a href="/thema/nb/KJMN">Forretningsprosesser / driftsledelse</a>',
            related: [
              {
                id: 'KJMN',
                label: 'Forretningsprosesser / driftsledelse',
                originalLabel: 'Forretningsprosesser / driftsledelse',
                note: 'Her: forretningsmetoder. Brukes for bøker som ser på alle oppgaver og prosesser en virksomhet tar i bruk for å kunne produsere produkter og tjenester ut til kunder. Se også KJT <a href="/thema/nb/KJT">Operasjonsanalyse</a>',
              },
              {
                id: 'PBW',
                label: 'Anvendt matematikk',
                originalLabel: 'Anvendt matematikk',
                note: 'Se også PDE <a href="/thema/nb/PDE">Matematikk for forskere</a>, TBJ <a href="/thema/nb/TBJ">Matematikk for ingeniører</a>, UYAM <a href="/thema/nb/UYAM">Matematikk for informatikkfag</a>',
              },
            ],
            children: [],
          },
          {
            id: 'KJU',
            label: 'Organisasjonsteori og organisasjonsutvikling',
            originalLabel: 'Organisasjonsteori og organisasjonsutvikling',
            alternativeLabels: [],
            note: 'Her: endringsledelse i organisasjoner, organisasjonssosiologi',
            related: [],
            children: [],
          },
          {
            id: 'KJV',
            label: 'Eierskap og selskapsformer',
            originalLabel: 'Eierskap og selskapsformer',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'KJVB',
                label: 'Overtagelser, fusjoner og oppkjøp',
                originalLabel: 'Overtagelser, fusjoner og oppkjøp',
                alternativeLabels: [],
                note: 'Class here: acquisitions',
                related: [],
                children: [],
              },
              {
                id: 'KJVD',
                label: 'Privatisering',
                originalLabel: 'Privatisering',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KJVF',
                label: 'Franchiser',
                originalLabel: 'Franchiser',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KJVG',
                label: 'Multinasjonale selskap',
                originalLabel: 'Multinasjonale selskap',
                alternativeLabels: ['globalisering'],

                related: [],
                children: [],
              },
              {
                id: 'KJVN',
                label: 'Offentlig eierskap/ nasjonalisering',
                originalLabel: 'Offentlig eierskap/ nasjonalisering',
                alternativeLabels: ['statseide selskap'],

                related: [],
                children: [],
              },
              {
                id: 'KJVP',
                label: 'Monopol',
                originalLabel: 'Monopol',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KJVQ',
                label: 'Familie- og eierstyrte selskap',
                originalLabel: 'Familie- og eierstyrte selskap',
                alternativeLabels: [],
                note: 'Her: familiebedrifter',
                related: [],
                children: [],
              },
              {
                id: 'KJVS',
                label: 'Småbedrifter og selvstendig næringsdrivende',
                originalLabel: 'Småbedrifter og selvstendig næringsdrivende',
                alternativeLabels: ['start-up'],
                note: 'Her: enkeltmannsforetak, det å starte og drive sin egen bedrift, frilansere, mellomstore bedrifter',
                related: [],
                children: [],
              },
              {
                id: 'KJVT',
                label: 'Utkontraktering og innkontraktering',
                originalLabel: 'Utkontraktering og innkontraktering',
                alternativeLabels: [
                  'outsourcing',
                  'insourcing',
                  'tjenesteutsetting',
                  'konkurranseutsetting',
                ],
                note: 'Brukes for bøker som handler om å bruke eksterne eller interne leverandører og ressurser',
                related: [],
                children: [],
              },
              {
                id: 'KJVV',
                label: 'Fellesforetak',
                originalLabel: 'Fellesforetak',
                alternativeLabels: [],
                note: 'Her: forretningspartnerskap, virksomhetssamarbeid',
                related: [],
                children: [],
              },
              {
                id: 'KJVW',
                label: 'Ansatteierskap og kooperativer',
                originalLabel: 'Ansatteierskap og kooperativer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KJVX',
                label: 'Ideelle organisasjoner',
                originalLabel: 'Ideelle organisasjoner',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'KJW',
            label: 'Kontor og arbeidsplass',
            originalLabel: 'Kontor og arbeidsplass',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'KJWB',
                label: 'Kontorledelse',
                originalLabel: 'Kontorledelse',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KJWF',
                label: 'Kontorsystemer og -utstyr',
                originalLabel: 'Kontorsystemer og -utstyr',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KJWS',
                label: 'Sekretær- og kontoroppgaver',
                originalLabel: 'Sekretær- og kontoroppgaver',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KJWX',
                label: 'Arbeidsmønstre og -praksis',
                originalLabel: 'Arbeidsmønstre og -praksis',
                alternativeLabels: [],
                note: 'Her: hjemmekontor, callsenter, skiftarbeid, deltidsarbeid, fulltidsarbeid, vikarer, fleksible eller restriktive arbeidstidsregelverk etc. Brukes for bøker som ser på hvordan mennesker jobber, kultur på arbeidsplassen, endringer i arbeidsmønstre etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'KJZ',
            label: 'Bedriftshistorie',
            originalLabel: 'Spesifikke selskapers historie / bedriftshistorie',
            alternativeLabels: [],
            note: 'Se også NHTK <a href="/thema/nb/NHTK">Industrialisering og industrihistorie</a>',
            related: [
              {
                id: 'NHTK',
                label: 'Industrialisering og industrihistorie',
                originalLabel: 'Industrialisering og industrihistorie',
                note: 'Her: den industrielle revolusjon, industrielle og teknologiske endringers påvirkning på samfunnet. Se også KJZ <a href="/thema/nb/KJZ">Spesifikke selskapers historie / bedriftshistorie</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'KN',
        label: 'Industri og næringsliv',
        originalLabel: 'Industri og næringsliv',
        alternativeLabels: [],
        note: 'Bruk KN*-koder på verk som tar for seg hele bransjer/næringer, eller som ser på enkeltaspekter ved disse fra et industri-/nærings-/bransjeperspektiv. Kombiner med JP*-koder for politisk tilnærming',
        related: [],
        children: [
          {
            id: 'KNA',
            label: 'Landbruks- og primærnæringer',
            originalLabel: 'Landbruks- og primærnæringer',
            alternativeLabels: [],
            note: 'Brukes for generelle verk om disse næringene, hvor det ikke fins egne koder. Se også KCVD <a href="/thema/nb/KCVD">Landbruksøkonomi</a>',
            related: [
              {
                id: 'KCVD',
                label: 'Landbruksøkonomi',
                originalLabel: 'Landbruksøkonomi',
              },
            ],
            children: [
              {
                id: 'KNAC',
                label: 'Landbruk og matproduksjon',
                originalLabel: 'Landbruk og matproduksjon',
                alternativeLabels: [],
                note: 'Brukes for titler om landbruks- og matproduksjonssektoren fra et industri-/nærings-/bransjeperspektiv, næringsmiddelindustri, industrielt landbruk, prosessert mat. Brukes med RNH for bøker om tap og sløsing av mat i landbruks- og næringsmiddelindustrien. Se også TV* <a href="/thema/nb/TV">Landbruk</a> og dens underkategorier',
                related: [
                  {
                    id: 'TV',
                    label: 'Landbruk',
                    originalLabel: 'Landbruk',
                    note: 'Brukes for titler for høyere utdanning eller profesjonsmarkedet om praksis og teknikker innen landbruk, matproduksjon og tilhørende yrker. Bruk TV* med andre koder fra T* eller U* for bøker som handler om bruk av ny teknologi innen landbruk. Se også KCVD <a href="/thema/nb/KCVD">Landbruksøkonomi</a>, KNA <a href="/thema/nb/KNA">Landbruks- og primærnæringer</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KNAF',
                label: 'Fiskeri og relaterte næringer',
                originalLabel: 'Fiskeri og relaterte næringer',
                alternativeLabels: [],
                note: 'Her: hvalfangst. Se også TVT <a href="/thema/nb/TVT">Akvakultur og fiskeoppdrett</a>',
                related: [
                  {
                    id: 'TVT',
                    label: 'Akvakultur og fiskeoppdrett',
                    originalLabel: 'Akvakultur og fiskeoppdrett',
                    note: 'Her: akvaponikk, havbruk, algekultur. Brukes for bøker for høyere utdanning, allmenn- eller profesjonsmarkedet om praksis og teknikker innen fiskeoppdrett, oppdrett av skalldyr, bløtdyr, vannplanter, alger, tang i ferskvann eller saltvann. Se også KNAF <a href="/thema/nb/KNAF">Fiskeri og relaterte næringer</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KNAL',
                label: 'Skogbruk',
                originalLabel: 'Skogbruk',
                alternativeLabels: [],
                note: 'Brukes for titler om skogbruksnæringa eller industri som skaper, forvalter, bruker, konserverer og reparerer skog og lignende ressurser. Se også TDPJ <a href="/thema/nb/TDPJ">Tømmer og treforedling</a>, TVR <a href="/thema/nb/TVR">Skogbruk og skogkultur</a>',
                related: [
                  {
                    id: 'TDPJ',
                    label: 'Tømmer og treforedling',
                    originalLabel: 'Tømmer og treforedling',
                    note: 'Brukes for alle aspekter ved industriell treproduksjon. Se også KNAL <a href="/thema/nb/KNAL">Skogbruk</a>, TVR <a href="/thema/nb/TVR">Skogbruk og skogkultur</a>',
                  },
                  {
                    id: 'TVR',
                    label: 'Skogbruk og skogkultur',
                    originalLabel: 'Skogbruk og skogkultur',
                    note: 'Her: trepleie, trebehandling, treplanting. Brukes for bøker for høyere utdanning, allmenn- eller profesjonsmarkedet som handler om praksis og teknikker for å administrere, bruke, bevare og reparere skog og relaterte ressurser, forvaltning av skoger og skogsområder, skogplanting, skogslandbruk, urbant skogbruk, tømmerhogst etc. Se også KNAL <a href="/thema/nb/KNAL">Skogbruk</a>, TDPJ <a href="/thema/nb/TDPJ">Tømmer og treforedling</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KNAT',
                label: 'Utvinningsindustri',
                originalLabel: 'Utvinningsindustri',
                alternativeLabels: [],
                note: 'Her: gruvedrift, boring, mudring, bergverksdrift. Brukes for verk som tar for seg disse næringene fra et industri-/nærings-/bransjeperspektiv. Se også RBGL <a href="/thema/nb/RBGL">Ressursgeologi</a>, TTU <a href="/thema/nb/TTU">Gruveteknologi- og teknikk</a>',
                related: [
                  {
                    id: 'RBGL',
                    label: 'Ressursgeologi',
                    originalLabel: 'Ressursgeologi',
                    note: 'Her: økonomisk geologi, petroleums- og gruvegeologi',
                  },
                  {
                    id: 'TTU',
                    label: 'Gruveteknologi- og teknikk',
                    originalLabel: 'Gruveteknologi- og teknikk',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'KNB',
            label: 'Energiindustri',
            originalLabel: 'Energiindustri',
            alternativeLabels: [],
            note: 'Brukes for verk som tar for seg disse bransjene fra et industri-/nærings-/bransjeperspektiv. Se også TH* <a href="/thema/nb/TH">Energiteknologi og -teknikk</a> og dens underkategorier',
            related: [
              {
                id: 'TH',
                label: 'Energiteknologi og -teknikk',
                originalLabel: 'Energiteknologi og -teknikk',
              },
            ],
            children: [
              {
                id: 'KNBL',
                label: 'Produksjon og distribusjon av elektrisk kraft',
                originalLabel: 'Produksjon og distribusjon av elektrisk kraft',
                alternativeLabels: [],
                note: 'Her: offentlige og private kraftselskaper, selskap som produserer atomkraft etc. Brukes for verk som tar for seg disse næringene fra et industri-/nærings-/bransjeperspektiv. Se også THY* <a href="/thema/nb/THY">Energi, kraftproduksjon, -distribusjon, og -lagring</a> og dens underkategorier',
                related: [
                  {
                    id: 'THY',
                    label:
                      'Energi, kraftproduksjon, -distribusjon, og -lagring',
                    originalLabel:
                      'Energi, kraftproduksjon, -distribusjon, og -lagring',
                    note: 'Her: kraftnett, -stasjoner og -verk, kraftutnyttelse og -bruk; energiomdanning og -lagring, kraftdistribusjon, kraftkabler, rørledninger, lagre. Brukes med andre TH*-koder for lagring, distribusjon etc. i ulike sektorer',
                  },
                ],
                children: [],
              },
              {
                id: 'KNBP',
                label: 'Olje-, petroleums- og gassnæringer',
                originalLabel: 'Olje-, petroleums- og gassnæringer',
                alternativeLabels: [
                  'oljeutvinning',
                  'oljenæring',
                  'oljeutvinning',
                  'gassutvinning',
                  'fracking',
                ],
                note: 'Her: hydraulisk oppsprekking. Brukes for verk som tar for seg disse næringene fra et industri-/nærings-/bransjeperspektiv. Se også RBGL <a href="/thema/nb/RBGL">Ressursgeologi</a>, TDCF <a href="/thema/nb/TDCF">Drivstoff og petrokjemikaler</a>',
                related: [
                  {
                    id: 'RBGL',
                    label: 'Ressursgeologi',
                    originalLabel: 'Ressursgeologi',
                    note: 'Her: økonomisk geologi, petroleums- og gruvegeologi',
                  },
                  {
                    id: 'TDCF',
                    label: 'Drivstoff og petrokjemikaler',
                    originalLabel: 'Drivstoff og petrokjemikaler',
                    note: 'Se også KNBP <a href="/thema/nb/KNBP">Olje-, petroleums- og gassnæringer</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KNBT',
                label: 'Alternativ og fornybar energi',
                originalLabel: 'Alternativ og fornybar energi',
                alternativeLabels: [],
                note: 'Brukes for verk om industri som produserer fornybar energi (vannkraft, vindkraft, solenergi, bølgekraft etc.) fra et industri-/nærings-/bransjeperspektiv. Se også THV <a href="/thema/nb/THV">Alternative og fornybare energikilder og teknologier</a>',
                related: [
                  {
                    id: 'THV',
                    label:
                      'Alternative og fornybare energikilder og teknologier',
                    originalLabel:
                      'Alternative og fornybare energikilder og teknologier',
                    note: 'Her: tidevanns- og bølgekraft, saltkraft, havstrømkraft og -teknologi, havvarmekraft, varmepumper, saltkraft. Se også RNU <a href="/thema/nb/RNU">Bærekraft</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KNBW',
                label: 'Vann- og avløpsverk',
                originalLabel: 'Vann- og avløpsverk',
                alternativeLabels: ['vannverk'],
                note: 'Her: offentlige og private selskaper som leverer tjenester innen drikkevann og avløpsvann. Brukes for verk som tar for seg disse bransjene fra et industri-/nærings-/bransjeperspektiv. Se også TQSR <a href="/thema/nb/TQSR">Avfallshåndtering og -deponering</a>, TQSW <a href="/thema/nb/TQSW">Vannforsyning og -behandling</a>',
                related: [
                  {
                    id: 'TQSR',
                    label: 'Avfallshåndtering og -deponering',
                    originalLabel: 'Avfallshåndtering og -deponering',
                    note: 'Her: kloakk og fjerning av helsefarlig avfall. Se også RNH <a href="/thema/nb/RNH">Avfallshåndtering</a>',
                  },
                  {
                    id: 'TQSW',
                    label: 'Vannforsyning og -behandling',
                    originalLabel: 'Vannforsyning og -behandling',
                    note: 'Her: vannrensing og destillering, ressursforvaltning, drikkevannskvalitet, landskapsplanlegging som tar hensyn til vann, bruk av regnvann, infrastruktur. Brukes for bøker som handler om planlegging, utvikling, distribusjon og forvaltning av vannressurser. Se også RNFD <a href="/thema/nb/RNFD">Tørke og vannforsyning</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'KND',
            label: 'Produksjonsindustri',
            originalLabel: 'Produksjonsindustri',
            alternativeLabels: [],
            note: 'Brukes for verk som tar for seg disse bransjene fra et industri-/nærings-/bransjeperspektiv. Se også TDP* <a href="/thema/nb/TDP">Andre produksjonsteknologier</a> og dens underkategorier',
            related: [
              {
                id: 'TDP',
                label: 'Andre produksjonsteknologier',
                originalLabel: 'Andre produksjonsteknologier',
                note: 'Brukes for produksjonsprosesser eller -teknologi som ikke har sine egne koder',
              },
            ],
            children: [
              {
                id: 'KNDC',
                label: 'Kjemisk, bioteknologisk og farmasøytisk industri',
                originalLabel:
                  'Kjemisk, bioteknologisk og farmasøytisk industri',
                alternativeLabels: [],
                note: 'Her: petrokjemisk- og plastindustri. Brukes for verk som tar for seg disse bransjene fra et industri-/nærings-/bransjeperspektiv. Se også TDC* <a href="/thema/nb/TDC">Industriell kjemi og kjemiteknikk</a> og dens underkategorier',
                related: [
                  {
                    id: 'TDC',
                    label: 'Industriell kjemi og kjemiteknikk',
                    originalLabel: 'Industriell kjemi og kjemiteknikk',
                    note: 'Her: teknologien bak vaskemidler, gummi, biocider etc.',
                  },
                ],
                children: [],
              },
              {
                id: 'KNDD',
                label: 'Tekstilindustri',
                originalLabel: 'Tekstilindustri',
                alternativeLabels: [],
                note: 'Brukes for titler som tar for kles- og tekstilindustri. Brukes for verk som tar for seg disse næringene fra et industri-/nærings-/bransjeperspektiv. Se også TDPF <a href="/thema/nb/TDPF">Tekstiler og fibre</a>, AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>',
                related: [
                  {
                    id: 'AKT',
                    label: 'Mote- og tekstildesign',
                    originalLabel: 'Mote- og tekstildesign',
                    note: 'Brukes for stiler og trender innen motedesign, generelle verk, referanseverk, bøker om spesielle klesplagg, illustrerte verk om mote etc. Bruk AKT*-koder med AKB for spesifikke motedesignere, med AKX for motehistorie, pluss kvalifikator(er) for stil, sted og tidsperiode der det er hensiktsmessig, med AJC* for fotografiske samlinger eller samlinger av motefotografier, med AGZ for praktiske teknikker for motestudenter og -designere. Foretrekk WF*-koder som hovedkategori for bøker ment for allmennmarkedet og som handler om hvordan man skal kle seg, hvordan man skal se moteriktig ut, har praktiske tips etc. Se også TDPF* <a href="/thema/nb/TDPF">Tekstiler og fibre</a> og dens underkategorier, KNDD <a href="/thema/nb/KNDD">Tekstilindustri</a>, KNSX <a href="/thema/nb/KNSX">Mote- og skjønnhetsbransjen</a>, WJF <a href="/thema/nb/WJF">Mote- og stilguider</a>',
                  },
                  {
                    id: 'TDPF',
                    label: 'Tekstiler og fibre',
                    originalLabel: 'Tekstiler og fibre',
                    note: 'Her: spinning, veving, lærarbeid. Brukes for bøker ment for profesjonsmarkedet eller for studenter innen industriell tekstilproduksjon og tilhørende sektorer, inkludert både naturlige materialer og industrielt framstilte materialer. Se også AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>, KNDD <a href="/thema/nb/KNDD">Tekstilindustri</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KNDR',
                label: 'Produksjon av kjøretøy og transportmidler',
                originalLabel: 'Produksjon av kjøretøy og transportmidler',
                alternativeLabels: ['biler', 'skip', 'tog', 'fly'],
                note: 'Her: bilindustri, skipsbygging, transportmidler for luftfart og jernbane. Brukes for bøker som tar for seg disse næringene fra et industri-/nærings-/bransjeperspektiv. Brukes med WG*-koder for angi type kjøretøy eller transportmiddel der det er hensiktsmessig. Se også TR* <a href="/thema/nb/TR">Transportteknologi og -næringer</a> og dens underkategorier',
                related: [
                  {
                    id: 'TR',
                    label: 'Transportteknologi og -næringer',
                    originalLabel: 'Transportteknologi og -næringer',
                    note: 'Se også KNG <a href="/thema/nb/KNG">Transportnæringer</a>, RPT <a href="/thema/nb/RPT">Transportplanlegging</a>, WG* <a href="/thema/nb/WG">Transport</a> og dens underkategorier',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'KNG',
            label: 'Transportnæringer',
            originalLabel: 'Transportnæringer',
            alternativeLabels: [],
            note: 'Her: alle transportnæringer; vei, shipping, jernbane, luftfart etc. Brukes for verk som tar for seg disse bransjene fra et industri-/nærings-/bransjeperspektiv. Bruk med WG*-koder for å angi hvilken type kjøretøy det er snakk om der det er hensiktsmessig. Se også KNDR <a href="/thema/nb/KNDR">Produksjon av kjøretøy og transportmidler</a>, TR* <a href="/thema/nb/TR">Transportteknologi og -næringer</a> og dens underkategorier, WG* <a href="/thema/nb/WG">Transport</a> og dens underkategorier',
            related: [
              {
                id: 'KNDR',
                label: 'Produksjon av kjøretøy og transportmidler',
                originalLabel: 'Produksjon av kjøretøy og transportmidler',
                note: 'Her: bilindustri, skipsbygging, transportmidler for luftfart og jernbane. Brukes for bøker som tar for seg disse næringene fra et industri-/nærings-/bransjeperspektiv. Brukes med WG*-koder for angi type kjøretøy eller transportmiddel der det er hensiktsmessig. Se også TR* <a href="/thema/nb/TR">Transportteknologi og -næringer</a> og dens underkategorier',
              },
              {
                id: 'TR',
                label: 'Transportteknologi og -næringer',
                originalLabel: 'Transportteknologi og -næringer',
                note: 'Se også KNG <a href="/thema/nb/KNG">Transportnæringer</a>, RPT <a href="/thema/nb/RPT">Transportplanlegging</a>, WG* <a href="/thema/nb/WG">Transport</a> og dens underkategorier',
              },
              {
                id: 'WG',
                label: 'Transport',
                originalLabel: 'Transport',
                note: 'Se også KNG <a href="/thema/nb/KNG">Transportnæringer</a>, TR* <a href="/thema/nb/TR">Transportteknologi og -næringer</a> og dens underkategorier',
              },
            ],
            children: [],
          },
          {
            id: 'KNJ',
            label: 'Bygg-, anleggs- og tungindustri',
            originalLabel: 'Bygg-, anleggs- og tungindustri',
            alternativeLabels: [],
            note: 'Brukes for verk som tar for seg disse bransjene fra et industri- /nærings-/bransjeperspektiv',
            related: [],
            children: [
              {
                id: 'KNJC',
                label: 'Byggebransjen',
                originalLabel: 'Byggebransjen',
                alternativeLabels: [],
                note: 'Brukes for verk som tar for seg disse næringene fra et industri-/nærings-/bransjeperspektiv. Se også TN* <a href="/thema/nb/TN">Byggeteknikk, landmåling og bygging</a> og dens underkategorier',
                related: [
                  {
                    id: 'TN',
                    label: 'Byggeteknikk, landmåling og bygging',
                    originalLabel: 'Byggeteknikk, landmåling og bygging',
                  },
                ],
                children: [],
              },
              {
                id: 'KNJH',
                label: 'Jern-, stål- og andre metallindustrier',
                originalLabel: 'Jern-, stål- og andre metallindustrier',
                alternativeLabels: [],
                note: 'Brukes for verk som tar for seg disse næringene fra et industri-/nærings-/bransjeperspektiv. Se også TDPM <a href="/thema/nb/TDPM">Metallteknologi og metallurgi</a>',
                related: [
                  {
                    id: 'TDPM',
                    label: 'Metallteknologi og metallurgi',
                    originalLabel: 'Metallteknologi og metallurgi',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'KNP',
            label: 'Detaljhandel og grossistnæringer',
            originalLabel: 'Detaljhandel og grossistnæringer',
            alternativeLabels: [],
            note: 'Her: distribusjon, varehus. Brukes for verk som tar for seg disse bransjene fra et industri-/nærings-/ bransjeperspektiv. Se også KJMV9 <a href="/thema/nb/KJMV9">Distribusjons- og logistikkledelse</a>',
            related: [
              {
                id: 'KJMV9',
                label: 'Distribusjons- og logistikkledelse',
                originalLabel: 'Distribusjons- og logistikkledelse',
                note: 'Se også KNP <a href="/thema/nb/KNP">Detaljhandel og grossistnæringer</a>',
              },
            ],
            children: [],
          },
          {
            id: 'KNS',
            label: 'Servicenæringer',
            originalLabel: 'Servicenæringer',
            alternativeLabels: [],
            note: 'Brukes for verk som tar for seg disse bransjene fra et industri-/nærings-/bransjeperspektiv. Se også TTVC <a href="/thema/nb/TTVC">Hotell-, serverings- og restaurantbransjen</a>',
            related: [
              {
                id: 'TTVC',
                label: 'Hotell-, serverings- og restaurantbransjen',
                originalLabel: 'Hotell-, serverings- og restaurantbransjen',
              },
            ],
            children: [
              {
                id: 'KNSB',
                label: 'Serveringsbransjen',
                originalLabel: 'Serveringsbransjen',
                alternativeLabels: [],
                note: 'Her: catering, restauranter, serveringssteder. Se også TDCT <a href="/thema/nb/TDCT">Næringsmiddelteknologi</a>, TTVC2 <a href="/thema/nb/TTVC2">Catering og matlaging: ferdigheter og bransjer</a>',
                related: [
                  {
                    id: 'TDCT',
                    label: 'Næringsmiddelteknologi',
                    originalLabel: 'Næringsmiddelteknologi',
                    note: 'Her: næringsmiddelvitenskap, teknologi som brukes i bryggerier og destillerier, ernæringslære, nutrasøytisk teknologi og kosttilskudd. Se også KNSB <a href="/thema/nb/KNSB">Serveringsbransjen</a>, PND <a href="/thema/nb/PND">Næringsmiddelkjemi</a>, TTVC <a href="/thema/nb/TTVC">Hotell-, serverings- og restaurantbransjen</a>, WBXD <a href="/thema/nb/WBXD">Drikke: alkoholholdige drikkevarer</a>, WBXD1 <a href="/thema/nb/WBXD1">Vin</a>',
                  },
                  {
                    id: 'TTVC2',
                    label: 'Catering og matlaging: ferdigheter og bransjer',
                    originalLabel:
                      'Catering og matlaging: ferdigheter og bransjer',
                    note: 'Brukes for bøker for profesjonsmarkedet',
                  },
                ],
                children: [],
              },
              {
                id: 'KNSG',
                label: 'Servicenæringer og turisme',
                originalLabel: 'Servicenæringer og turisme',
                alternativeLabels: ['hoteller', 'opplevelser'],
                note: 'Her: hotellbransjen, hotelledelse, temaparker, treningssentre, sports- og aktivitetsbaserte tilbud, parkvesen, opplevelsesindustrien og andre relaterte bransjer. Se også TTVC <a href="/thema/nb/TTVC">Hotell-, serverings- og restaurantbransjen</a>, WT* <a href="/thema/nb/WT">Reiser og ferie</a> og dens underkategorier',
                related: [
                  {
                    id: 'TTVC',
                    label: 'Hotell-, serverings- og restaurantbransjen',
                    originalLabel: 'Hotell-, serverings- og restaurantbransjen',
                  },
                  {
                    id: 'WT',
                    label: 'Reiser og ferie',
                    originalLabel: 'Reiser og ferie',
                    note: 'Med WT*-koder , tildel også den mest spesifikke kvalifikatoren for sted',
                  },
                ],
                children: [],
              },
              {
                id: 'KNSJ',
                label: 'Arrangementer / eventer',
                originalLabel: 'Arrangementer / eventer',
                alternativeLabels: [],
                note: 'Her: bryllup, konferanser, festivaler, seremonier. Brukes for verk som tar for seg disse næringene fra et industri-/nærings-/bransjeperspektiv',
                related: [],
                children: [],
              },
              {
                id: 'KNSX',
                label: 'Mote- og skjønnhetsbransjen',
                originalLabel: 'Mote- og skjønnhetsbransjen',
                alternativeLabels: ['kosmetikk', 'frisører', 'kosmetologer'],
                note: 'Her: næringer som driver med sminke, parfyme, hudpleie, hårpleie etc. Brukes for verk som tar for seg disse næringene fra et industri-/nærings-/bransjeperspektiv. Se også AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>, JBCC3 <a href="/thema/nb/JBCC3">Kulturstudier: klær og samfunn</a>, KNDD <a href="/thema/nb/KNDD">Tekstilindustri</a>, TTVH <a href="/thema/nb/TTVH">Frisøryrket, skjønnhetsbehandlinger og salongarbeid</a>, WJF <a href="/thema/nb/WJF">Mote- og stilguider</a>',
                related: [
                  {
                    id: 'AKT',
                    label: 'Mote- og tekstildesign',
                    originalLabel: 'Mote- og tekstildesign',
                    note: 'Brukes for stiler og trender innen motedesign, generelle verk, referanseverk, bøker om spesielle klesplagg, illustrerte verk om mote etc. Bruk AKT*-koder med AKB for spesifikke motedesignere, med AKX for motehistorie, pluss kvalifikator(er) for stil, sted og tidsperiode der det er hensiktsmessig, med AJC* for fotografiske samlinger eller samlinger av motefotografier, med AGZ for praktiske teknikker for motestudenter og -designere. Foretrekk WF*-koder som hovedkategori for bøker ment for allmennmarkedet og som handler om hvordan man skal kle seg, hvordan man skal se moteriktig ut, har praktiske tips etc. Se også TDPF* <a href="/thema/nb/TDPF">Tekstiler og fibre</a> og dens underkategorier, KNDD <a href="/thema/nb/KNDD">Tekstilindustri</a>, KNSX <a href="/thema/nb/KNSX">Mote- og skjønnhetsbransjen</a>, WJF <a href="/thema/nb/WJF">Mote- og stilguider</a>',
                  },
                  {
                    id: 'JBCC3',
                    label: 'Mote og samfunn',
                    originalLabel: 'Kulturstudier: klær og samfunn',
                    note: 'Her: framstilling av syn på skjønnhet, kroppsbilde og hvordan mennesker kler seg i ulike kulturer, bøker som tar for seg ulike aspekter ved klær, kostymer og moter i ulike samfunn eller kulturer, eller bøker som ser på hvordan klær brukes for å kommunisere sosiale roller og sosial status, crossdressing i ulike kulturer osv. Brukes med NHTB for historisk synsvinkel eller AKT*-koder der det er hensiktsmessig',
                  },
                  {
                    id: 'KNDD',
                    label: 'Tekstilindustri',
                    originalLabel: 'Tekstilindustri',
                    note: 'Brukes for titler som tar for kles- og tekstilindustri. Brukes for verk som tar for seg disse næringene fra et industri-/nærings-/bransjeperspektiv. Se også TDPF <a href="/thema/nb/TDPF">Tekstiler og fibre</a>, AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>',
                  },
                  {
                    id: 'TTVH',
                    label:
                      'Frisøryrket, skjønnhetsbehandlinger og salongarbeid',
                    originalLabel:
                      'Frisøryrket, skjønnhetsbehandlinger og salongarbeid',
                    note: 'Her: profesjonell makeup, kosmetologi, barbering, hårdesign og -stell, hudpleie, massasje, manikyr, pedikyr og alle bøker som er rettet mot yrkesutøvere innen disse områdene. Brukes med kvalifikatorer fra 4CP* der det er hensiktsmessig, og med YPWC5 for bøker med for videregående skole',
                  },
                  {
                    id: 'WJF',
                    label: 'Mote- og stilguider',
                    originalLabel: 'Mote- og stilguider',
                  },
                ],
                children: [],
              },
              {
                id: 'KNSZ',
                label: 'Begravelsesbransjen',
                originalLabel: 'Begravelsesbransjen',
                alternativeLabels: [],
                note: 'Brukes for bøker om begravelsesbyråer og andre virksomheter som leverer tjenester knyttet til dødsfall, begravelse, kremasjon, balsamering, minnemarkeringer, kirkegårder, etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'KNT',
            label:
              'Media-, underholdnings-, informasjons og kommunikasjonsnæringer',
            originalLabel:
              'Media-, underholdnings-, informasjons og kommunikasjonsnæringer',
            alternativeLabels: [],
            note: 'Her: reklamebransjen; presse, trykkerier, post og telekommunikasjonsnæringer etc. Se også GTC <a href="/thema/nb/GTC">Kommunikasjonsstudier</a>, JBCT <a href="/thema/nb/JBCT">Medievitenskap</a>',
            related: [
              {
                id: 'GTC',
                label: 'Kommunikasjonsstudier',
                originalLabel: 'Kommunikasjonsstudier',
                note: 'Brukes for lærebøker innen kommunikasjon og for verk som dekker en rekke aspekter ved kommunikasjon - språklige, sosiale, industrielle, teknologiske etc. Se også JBCT* <a href="/thema/nb/JBCT">Medievitenskap</a> og dens underkategorier',
              },
              {
                id: 'JBCT',
                label: 'Medievitenskap',
                originalLabel: 'Medievitenskap',
                note: 'Se også GTC <a href="/thema/nb/GTC">Kommunikasjonsstudier</a>',
              },
            ],
            children: [
              {
                id: 'KNTC',
                label: 'Film-, TV- og radiobransjen',
                originalLabel: 'Film-, TV- og radiobransjen',
                alternativeLabels: [
                  'kringkasting',
                  'strømming',
                  'abonnementstjenester',
                ],
                note: 'Brukes for bøker om bransjer, selskaper og teknologi i tilknytning til TV, kino, video, radio, podkast og lyd, inkludert strømmetjenester',
                related: [],
                children: [],
              },
              {
                id: 'KNTF',
                label: 'Musikkbransjen',
                originalLabel: 'Musikkbransjen',
                alternativeLabels: [],
                note: 'Her: platebransjen og konsertarrangører, musikkdistribusjon, strømmetjenester',
                related: [],
                children: [],
              },
              {
                id: 'KNTP',
                label: 'Forlagsbransje og journalistikk',
                originalLabel: 'Forlagsbransje og journalistikk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'KNTP1',
                    label: 'Forlags- og bokbransje',
                    originalLabel: 'Forlags- og bokbransje',
                    alternativeLabels: [
                      'forlagsbransjen',
                      'forlag',
                      'bokhandel',
                      'bokhandlere',
                    ],
                    note: 'Her: trykte bøker, e-bok og digital publisering, tidsskrifter. Brukes med NHTB for historisk behandling av emnet',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'KNTP2',
                    label: 'Presse og journalistikk',
                    originalLabel: 'Presse og journalistikk',
                    alternativeLabels: [],
                    note: 'Her: trykte og digitale nyheter, aviser og blad',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'KNTR',
                label: 'Trykkerivirksomhet',
                originalLabel: 'Trykkerivirksomhet',
                alternativeLabels: ['trykkerier', 'reprografi'],
                note: 'Her: emballasje- og merkelapproduksjon',
                related: [],
                children: [],
              },
              {
                id: 'KNTV',
                label: 'Data- og TV-spillbransjen',
                originalLabel: 'Data- og TV-spillbransjen',
                alternativeLabels: ['dataspill', 'TV-spill', 'gaming'],
                note: 'Her: interaktiv underholdningsbransje',
                related: [],
                children: [],
              },
              {
                id: 'KNTX',
                label: 'IT-bransjen',
                originalLabel: 'IT-bransjen',
                alternativeLabels: [],
                note: 'Her: bransjer som jobber med informasjonsteknologi, AI og maskinlæring, utvikling av programvare, etc. Se også KJE <a href="/thema/nb/KJE">E-handel: forretningsmessige aspekter</a>',
                related: [
                  {
                    id: 'KJE',
                    label: 'E-handel: forretningsmessige aspekter',
                    originalLabel: 'E-handel: forretningsmessige aspekter',
                    note: 'Her: elektronisk handel, netthandel. Brukes for bøker som handler om å kjøpe og selge varer og tjenester på internett og overføring av penger og data for å kunne utføre disse transaksjonene. Se også KJSG <a href="/thema/nb/KJSG">Digital markedsføring</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'KNV',
            label: 'Offentlige tjenester og offentlig sektor',
            originalLabel: 'Offentlige tjenester og offentlig sektor',
            alternativeLabels: [],
            note: 'Se også JPP <a href="/thema/nb/JPP">Offentlig administrasjon</a>',
            related: [
              {
                id: 'JPP',
                label: 'Offentlig administrasjon',
                originalLabel: 'Offentlig administrasjon',
                note: 'Se også KNV <a href="/thema/nb/KNV">Offentlige tjenester og offentlig sektor</a>',
              },
            ],
            children: [],
          },
          {
            id: 'KNX',
            label: 'Arbeidsforhold, helse og sikkerhet',
            originalLabel: 'Arbeidsforhold, helse og sikkerhet',
            alternativeLabels: ['fagforeningsarbeid'],
            note: 'Her: fagforeninger, streik, meklinger, arbeidsmiljø, HMS etc. Brukes for alle typer arbeidsplasser, ikke spesifikt produksjon og tungindustri',
            related: [],
            children: [
              {
                id: 'KNXC',
                label: 'Helse og sikkerhet på arbeidsplassen',
                originalLabel: 'Helse og sikkerhet på arbeidsplassen',
                alternativeLabels: [],
                note: 'Brukes for bøker som ser på helse, miljø og sikkerhet på arbeidsplassen, råd om hvordan skape trygge arbeidsplasser og et sunt arbeidsmiljø, overholde nødvendige lover og forskrifter, opplæring i helse, miljø og sikkerhet. Se også JMJ <a href="/thema/nb/JMJ">Arbeids- og industripsykologi</a>, LNHH <a href="/thema/nb/LNHH">HMS og arbeidsmiljøloven</a>',
                related: [
                  {
                    id: 'JMJ',
                    label: 'Arbeids- og industripsykologi',
                    originalLabel: 'Arbeids- og industripsykologi',
                    note: 'Se også SCGP <a href="/thema/nb/SCGP">Idrettspsykologi</a>, KNXC <a href="/thema/nb/KNXC">Helse og sikkerhet på arbeidsplassen</a>',
                  },
                  {
                    id: 'LNHH',
                    label: 'HMS og arbeidsmiljøloven',
                    originalLabel: 'HMS og arbeidsmiljøloven',
                    note: 'Se også KNXC <a href="/thema/nb/KNXC">Helse og sikkerhet på arbeidsplassen</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'KNXN',
                label: 'Industriell voldgift og forhandlinger',
                originalLabel: 'Industriell voldgift og forhandlinger',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'KNXU',
                label: 'Fagforeninger',
                originalLabel: 'Fagforeninger',
                alternativeLabels: [],
                note: 'Her: organisert arbeidskraft',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'L',
    label: 'Jus',
    originalLabel: 'Jus',
    alternativeLabels: [],
    note: 'Bruk L*-koder kun for akademiske utgivelser og utgivelser for profesjonsmarkedet. IKKE BRUK koden ‘L’ alene, men velg spesifikke kategorier fra L*. L* er ikke ment å gjenspeile et spesielt juridisk system, og mange utgivelser vil trenge minst to koder for å angi innholdet korrekt. Bruk kvalifikatorer for sted for å angi jurisdiksjon (spesielt når det gjelder LN*-koder), pluss andre kvalifikatorer der det er hensiktsmessig. Foretrekk VS*-koder for utgivelser for allmennmarkedet',
    related: [],
    children: [
      {
        id: 'LA',
        label: 'Rettsvitenskap',
        originalLabel: 'Rettsvitenskap',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'LAB',
            label: 'Rettsmetoder, -teori og -filosofi',
            originalLabel: 'Rettsmetoder, -teori og -filosofi',
            alternativeLabels: [],
            note: 'Her: analytisk og normativ rettsvitenskap, naturrett, rettspositivisme, rettsrealisme, filosofiske tilnærmingsmåter til juridiske problemer, generelle rettsprinsipper, lovarbeidsteori etc.',
            related: [],
            children: [],
          },
          {
            id: 'LAF',
            label: 'Rettssystemer',
            originalLabel: 'Rettssystemer',
            alternativeLabels: [],
            note: 'Brukes for bøker om ulike rettssystemer eller for å angi at en lov er fra et bestemt rettssystem. LAF*-koder brukes med andre L*-koder og kvalifikatorer for sted der det er hensiktsmessig, for å angi type rettssystem, rettsområde og jurisdiksjon',
            related: [],
            children: [
              {
                id: 'LAFC',
                label: 'Common law',
                originalLabel: 'Rettssystemer: common law',
                alternativeLabels: [],
                note: 'Her: det engelske rettssystemet og rettssystemer som bygger på denne',
                related: [],
                children: [],
              },
              {
                id: 'LAFD',
                label: 'Privatrett',
                originalLabel: 'Rettssystemer: privatrett',
                alternativeLabels: ['sivilrett'],
                note: 'Brukes for bøker om rettssystemer eller sivilrettslige systemer som er basert på ei lovsamling eller juridiske regler. Se også LAFR <a href="/thema/nb/LAFR">Rettssystemer: romerrett</a>',
                related: [
                  {
                    id: 'LAFR',
                    label: 'Romerrett',
                    originalLabel: 'Rettssystemer: romerrett',
                  },
                ],
                children: [],
              },
              {
                id: 'LAFF',
                label: 'Sedvanerett',
                originalLabel: 'Rettssystemer: sedvanerett',
                alternativeLabels: [],
                note: 'Brukes for bøker om rettssystemer eller juridiske systemer som helt eller delvis er basert på sedvane',
                related: [],
                children: [],
              },
              {
                id: 'LAFG',
                label: 'Rettssystemer: blandingssystemer',
                originalLabel: 'Rettssystemer: blandingssystemer',
                alternativeLabels: [],
                note: 'Brukes for bøker om rettssystemer eller juridiske systemer som er basert på mer enn et rettssystem',
                related: [],
                children: [],
              },
              {
                id: 'LAFR',
                label: 'Romerrett',
                originalLabel: 'Rettssystemer: romerrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LAFS',
                label: 'Islamsk lov',
                originalLabel: 'Rettssystemer: islamsk lov',
                alternativeLabels: [],
                note: 'Se også LW <a href="/thema/nb/LW">Sharia</a>',
                related: [
                  {
                    id: 'LW',
                    label: 'Sharia',
                    originalLabel: 'Sharia',
                    note: 'Her: islamsk lov',
                  },
                ],
                children: [],
              },
              {
                id: 'LAFT',
                label: 'Jødisk lov',
                originalLabel: 'Rettssystemer: jødisk lov',
                alternativeLabels: ['Halakha'],

                related: [],
                children: [],
              },
              {
                id: 'LAFX',
                label: 'Kirkerett',
                originalLabel: 'Rettssystemer: kirkerett (kanonisk rett)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LAM',
            label: 'Komparativ rett',
            originalLabel: 'Komparativ rett',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'LAP',
            label: 'Rettsteknologi',
            originalLabel: 'Rettsteknologi',
            alternativeLabels: [],
            note: 'Brukes for bøker som ser på bruk og påvirkning av teknologi i lovgivning og rettsvesenet. Brukes med U* for teknisk tilnærming til emnet',
            related: [],
            children: [],
          },
          {
            id: 'LAQ',
            label: 'Rettssosiologi',
            originalLabel: 'Lov og samfunn, rettssosiologi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LAQG',
                label: 'Kjønnsspørsmål',
                originalLabel: 'Lov og samfunn: kjønnsspørsmål',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LAR',
            label: 'Juridiske aspekter ved kriminologi',
            originalLabel: 'Juridiske aspekter ved kriminologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'LAS',
            label: 'Juridisk kompetanse og praksis',
            originalLabel: 'Juridisk kompetanse og praksis',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LASD',
                label: 'Juridisk kompetanse: forsvar',
                originalLabel: 'Juridisk kompetanse: forsvar',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LASH',
                label: 'Juridisk fagspråk',
                originalLabel: 'Juridisk kompetanse: juridisk fagspråk',
                alternativeLabels: ['juridisk skriving', 'utkast'],
                note: 'Her: akademisk juridisk fagspråk',
                related: [],
                children: [],
              },
              {
                id: 'LASK',
                label: 'Juridiske forhandlinger og intervjuer',
                originalLabel:
                  'Juridisk kompetanse: forhandlinger og intervjuer',
                alternativeLabels: [],
                note: 'Her: juridisk kompetanse innen muntlig kommunikasjon',
                related: [],
                children: [],
              },
              {
                id: 'LASN',
                label: 'Juridiske undersøkelsesmetoder',
                originalLabel: 'Juridisk kompetanse: undersøkelsesmetoder',
                alternativeLabels: [],
                note: 'Her: juridisk kompetanse innen det å bruke nettbaserte tjenester, juridiske databaser etc.',
                related: [],
                children: [],
              },
              {
                id: 'LASP',
                label: 'Advokater, advokatsekretærer og rettshjelp',
                originalLabel:
                  'Rettspraksis: advokater, advokatsekretærer og rettshjelp',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LAT',
            label: 'Juridiske yrker',
            originalLabel: 'Juridiske yrker',
            alternativeLabels: [],
            note: 'Her: bøker som handler om yrkesutøvere innen jus, som ulike typer advokater. Bruk LAT* for bøker om disse yrkesutøverne, deres roller, arbeid og ansvar. Se også LNAL <a href="/thema/nb/LNAL">Juridiske systemer: regulering av juridiske profesjoner</a>',
            related: [
              {
                id: 'LNAL',
                label: 'Regulering av juridiske profesjoner',
                originalLabel:
                  'Juridiske systemer: regulering av juridiske profesjoner',
              },
            ],
            children: [
              {
                id: 'LATC',
                label: 'Rettsetikk og yrkesutøvelse',
                originalLabel: 'Rettsetikk og yrkesutøvelse',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LAY',
            label: 'Anvendt jus i andre yrker og fagområder',
            originalLabel: 'Anvendt jus i andre yrker og fagområder',
            alternativeLabels: [],
            note: 'Se også VSD <a href="/thema/nb/VSD">Lover, statsborgerskap og rettigheter for den vanlige person</a>',
            related: [
              {
                id: 'VSD',
                label:
                  'Lover, statsborgerskap og rettigheter for den vanlige person',
                originalLabel:
                  'Lover, statsborgerskap og rettigheter for den vanlige person',
                note: 'Her: råd om jus, lover, borgerplikter etc. Brukes med L* for bøker om jus for allmennmarkedet. NB: dersom VSD kombineres med L* må VSD angis som den viktigste koden og plasseres først',
              },
            ],
            children: [],
          },
          {
            id: 'LAZ',
            label: 'Rettshistorie',
            originalLabel: 'Rettshistorie',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'LB',
        label: 'Internasjonal rett',
        originalLabel: 'Internasjonal rett',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'LBB',
            label: 'Folkerett',
            originalLabel: 'Folkerett',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LBBC',
                label: 'Folkerett: traktater og andre kilder',
                originalLabel: 'Folkerett: traktater og andre kilder',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'LBBC1',
                    label: 'Folkerett: sedvanerett',
                    originalLabel: 'Folkerett: sedvanerett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LBBD',
                label: 'Folkerett: eksterritorialrett',
                originalLabel: 'Folkerett: eksterritorialrett',
                alternativeLabels: [],
                note: 'Her: diplomatisk immunitet',
                related: [],
                children: [],
              },
              {
                id: 'LBBF',
                label: 'Folkerett: jurisdiksjon og immunitet',
                originalLabel: 'Folkerett: jurisdiksjon og immunitet',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LBBJ',
                label: 'Folkerett: territorium og stat',
                originalLabel: 'Folkerett: territorium og stat',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LBBK',
                label: 'Folkerett: havrett',
                originalLabel: 'Folkerett: havrett',
                alternativeLabels: [],
                note: 'Her: lover vedrørende maritime grenser, ressurser etc. slik det påvirker suverene stater. Se også LBDM <a href="/thema/nb/LBDM">Internasjonal rett, transport og handel: sjørett</a>, LNCB5 <a href="/thema/nb/LNCB5">Shipping: lov og rett</a>',
                related: [
                  {
                    id: 'LBDM',
                    label: 'Internasjonal rett, transport og handel: sjørett',
                    originalLabel:
                      'Internasjonal rett, transport og handel: sjørett',
                    note: 'Her: jus vedrørende frakt, last, forsikring, sjørøveri etc. om det gjelder kommersielle organisasjoner og ikke suverene stater. Se også LBBK <a href="/thema/nb/LBBK">Folkerett: havrett</a>, LNCB5 <a href="/thema/nb/LNCB5">Shipping: lov og rett</a>',
                  },
                  {
                    id: 'LNCB5',
                    label: 'Shipping: lov og rett',
                    originalLabel: 'Shipping: lov og rett',
                    note: 'Brukes for nasjonal lovgivning om sjørett. Se også LBBK <a href="/thema/nb/LBBK">Folkerett: havrett</a>, LBDM <a href="/thema/nb/LBDM">Internasjonal rett, transport og handel: sjørett</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'LBBL',
                label: 'Folkerett: helse',
                originalLabel: 'Folkerett: helse',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LBBM',
                label: 'Folkerett: økonomi og handel',
                originalLabel: 'Folkerett: økonomi og handel',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'LBBM1',
                    label: 'Folkerett: økonomi og handel: tariffer',
                    originalLabel: 'Folkerett: økonomi og handel: tariffer',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LBBM3',
                    label:
                      'Folkerett: økonomi og handel: investeringsavtaler og tvister',
                    originalLabel:
                      'Folkerett: økonomi og handel: investeringsavtaler og tvister',
                    alternativeLabels: [],
                    note: 'Her: internasjonal investeringslovgivning, -regulering, -avtaler',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'LBBM5',
                    label: 'Folkerett: energi og naturressurser',
                    originalLabel: 'Folkerett: energi og naturressurser',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LBBM7',
                    label: 'Folkerett: økonomi og handel: selskaper',
                    originalLabel: 'Folkerett: økonomi og handel: selskaper',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LBBP',
                label: 'Folkerett: miljø',
                originalLabel: 'Folkerett: miljø',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'LBBP1',
                    label: 'Folkerett: miljø: landbruksrett',
                    originalLabel: 'Folkerett: miljø: landbruksrett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LBBQ',
                label: 'Folkerett: administrasjon',
                originalLabel: 'Folkerett: administrasjon',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LBBR',
                label: 'Folkerett: menneskerettigheter',
                originalLabel: 'Folkerett: menneskerettigheter',
                alternativeLabels: [],
                note: 'Her: grunnleggende materielle rettigheter og umistelige rettigheter i folkeretten',
                related: [],
                children: [
                  {
                    id: 'LBBR1',
                    label:
                      'Folkerett: menneskerettigheter: arbeid og sosiale rettigheter',
                    originalLabel:
                      'Folkerett: menneskerettigheter: arbeid og sosiale rettigheter',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LBBS',
                label: 'Folkerett: humanitære lover',
                originalLabel: 'Folkerett: humanitære lover',
                alternativeLabels: [],
                note: 'Her: flyktningrett, krigens rett',
                related: [],
                children: [],
              },
              {
                id: 'LBBU',
                label:
                  'Folkerett: internasjonale organisasjoner og institusjoner',
                originalLabel:
                  'Folkerett: internasjonale organisasjoner og institusjoner',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LBBV',
                label: 'Folkerett: staters og andre organers ansvar',
                originalLabel: 'Folkerett: staters og andre organers ansvar',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LBBZ',
                label: 'Folkerett: strafferett',
                originalLabel: 'Folkerett: strafferett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LBD',
            label: 'Internasjonal rett: transport, kommunikasjon og handel',
            originalLabel:
              'Internasjonal rett: transport, kommunikasjon og handel',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LBDA',
                label:
                  'Internasjonal rett, transport: verdensrommet og romfart',
                originalLabel:
                  'Internasjonal rett, transport: verdensrommet og romfart',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LBDK',
                label:
                  'Internasjonal rett: transnasjonal handel og internasjonal kjøpsrett',
                originalLabel:
                  'Internasjonal rett: transnasjonal handel og internasjonal kjøpsrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LBDM',
                label: 'Internasjonal rett, transport og handel: sjørett',
                originalLabel:
                  'Internasjonal rett, transport og handel: sjørett',
                alternativeLabels: [],
                note: 'Her: jus vedrørende frakt, last, forsikring, sjørøveri etc. om det gjelder kommersielle organisasjoner og ikke suverene stater. Se også LBBK <a href="/thema/nb/LBBK">Folkerett: havrett</a>, LNCB5 <a href="/thema/nb/LNCB5">Shipping: lov og rett</a>',
                related: [
                  {
                    id: 'LBBK',
                    label: 'Folkerett: havrett',
                    originalLabel: 'Folkerett: havrett',
                    note: 'Her: lover vedrørende maritime grenser, ressurser etc. slik det påvirker suverene stater. Se også LBDM <a href="/thema/nb/LBDM">Internasjonal rett, transport og handel: sjørett</a>, LNCB5 <a href="/thema/nb/LNCB5">Shipping: lov og rett</a>',
                  },
                  {
                    id: 'LNCB5',
                    label: 'Shipping: lov og rett',
                    originalLabel: 'Shipping: lov og rett',
                    note: 'Brukes for nasjonal lovgivning om sjørett. Se også LBBK <a href="/thema/nb/LBBK">Folkerett: havrett</a>, LBDM <a href="/thema/nb/LBDM">Internasjonal rett, transport og handel: sjørett</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'LBDT',
                label:
                  'Internasjonal rett: kommunikasjon, telekommunikasjon og media',
                originalLabel:
                  'Internasjonal rett: kommunikasjon, telekommunikasjon og media',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LBG',
            label: 'Internasjonal privatrett og rettskonflikter',
            originalLabel: 'Internasjonal privatrett og rettskonflikter',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'LBH',
            label:
              'Internasjonal rett: internasjonale konflikter og sivilprosess',
            originalLabel:
              'Internasjonal rett: internasjonale konflikter og sivilprosess',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LBHG',
                label: 'Internasjonal rett: domstoler og prosedyrer',
                originalLabel: 'Internasjonal rett: domstoler og prosedyrer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LBHT',
                label: 'Internasjonal rett: voldgift',
                originalLabel: 'Internasjonal rett: voldgift',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LBJ',
            label: 'Internasjonal rett: immaterialrett',
            originalLabel: 'Internasjonal rett: immaterialrett',
            alternativeLabels: [],
            note: 'Her: åndsrett, opphavsrett, patentrett',
            related: [],
            children: [],
          },
          {
            id: 'LBK',
            label: 'Internasjonal rett: idrett',
            originalLabel: 'Internasjonal rett: idrett',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'LBL',
            label: 'Folkerettslige rapporter',
            originalLabel: 'Folkerettslige rapporter',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'LN',
        label: 'Lover vedrørende særskilte jurisdiksjoner og juridiske områder',
        originalLabel:
          'Lover vedrørende særskilte jurisdiksjoner og juridiske områder',
        alternativeLabels: [],
        note: 'Ved bruk av LN*-koder, tildel også kvalifikatorer for geografi for å indikere jurisdiksjon der det er hensiktsmessig',
        related: [],
        children: [
          {
            id: 'LNA',
            label: 'Juridiske systemer',
            originalLabel: 'Juridiske systemer',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LNAA',
                label: 'Domstoler og prosedyrer',
                originalLabel: 'Juridiske systemer: domstoler og prosedyrer',
                alternativeLabels: [],
                note: 'Her: rettsregler. Brukes for bøker om ulike typer domstoler, lover, prosedyrer og prosesser i disse domstolene',
                related: [],
                children: [
                  {
                    id: 'LNAA1',
                    label: 'Dømmende makt',
                    originalLabel:
                      'Juridiske systemer: dommere og dømmende makt',
                    alternativeLabels: [],
                    note: 'Brukes for ulike typer dommere, domstolene og dømmende makter',
                    related: [],
                    children: [
                      {
                        id: 'LNAA12',
                        label: 'Juridiske systemer: lekdommere, lekmannsjuryer',
                        originalLabel:
                          'Juridiske systemer: lekdommere, lekmannsjuryer',
                        alternativeLabels: [],
                        note: 'Brukes for bøker om eller for borgere som ikke har utdanning innen jus, men som deltar i rettssaker enten som lekdommere eller som medlemmer av en jury',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'LNAA2',
                    label: 'Rettergangsstraff',
                    originalLabel: 'Juridiske systemer: rettergangsstraff',
                    alternativeLabels: [],
                    note: 'Her: forakt for retten',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LNAC',
                label: 'Sivilprosess, rettssaker og tvisteløsning',
                originalLabel:
                  'Juridiske systemer: sivilprosess, rettssaker og tvisteløsning',
                alternativeLabels: ['foreldelsesfrist'],
                note: 'Her: foreldelse innen strafferett, prosessrett. Se også LNFX <a href="/thema/nb/LNFX">Straffeprosess</a>',
                related: [
                  {
                    id: 'LNFX',
                    label: 'Straffeprosess',
                    originalLabel: 'Straffeprosess',
                  },
                ],
                children: [
                  {
                    id: 'LNAC1',
                    label: 'Sivile rettsmidler',
                    originalLabel: 'Sivile rettsmidler',
                    alternativeLabels: [],
                    note: 'Her: urettferdig berikelse',
                    related: [],
                    children: [
                      {
                        id: 'LNAC12',
                        label: 'Restitusjon',
                        originalLabel: 'Restitusjon',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'LNAC14',
                        label: 'Skade og erstatning',
                        originalLabel: 'Skade og erstatning',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'LNAC16',
                        label: 'Pålegg og andre ordre',
                        originalLabel: 'Pålegg og andre ordre',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'LNAC3',
                    label: 'Sivilprosess: bevis',
                    originalLabel: 'Sivilprosess: bevis',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNAC4',
                    label:
                      'Sivilprosess: etterforskning og spesifikk saksbehandling',
                    originalLabel:
                      'Sivilprosess: etterforskning og spesifikk saksbehandling',
                    alternativeLabels: [],
                    note: 'Her: skjønn',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNAC5',
                    label: 'Voldgift, mekling og alternativ tvisteløsning',
                    originalLabel:
                      'Voldgift, mekling og alternativ tvisteløsning',
                    alternativeLabels: [],
                    note: 'Her: juridiske forhandlinger',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LNAD',
                label: 'Juridiske systemer: alminnelige bestemmelser',
                originalLabel: 'Juridiske systemer: alminnelige bestemmelser',
                alternativeLabels: [],
                note: 'Brukes for bøker om juridiske systemer eller lovgivning som inneholder generelle regler og definisjoner. Brukes med andre koder fra L* for bøker som handler om anvendelse av disse reglene innen spesifikke områder i jus',
                related: [],
                children: [],
              },
              {
                id: 'LNAF',
                label: 'Kostnader ved og finansiering av juridiske systemer',
                originalLabel: 'Juridiske systemer: kostnader og finansiering',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNAL',
                label: 'Regulering av juridiske profesjoner',
                originalLabel:
                  'Juridiske systemer: regulering av juridiske profesjoner',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LNB',
            label: 'Privatrett',
            originalLabel: 'Privatrett',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LNBA',
                label: 'Juridisk enhet (personer og organisasjoner)',
                originalLabel: 'Juridisk enhet (personer og organisasjoner)',
                alternativeLabels: [],
                note: 'Her: rettslig handleevne eller kompetanse',
                related: [],
                children: [],
              },
              {
                id: 'LNBB',
                label: 'Folkeregistrering',
                originalLabel: 'Folkeregistrering',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNBF',
                label: 'Obligasjonsrett',
                originalLabel: 'Obligasjonsrett',
                alternativeLabels: [],
                note: 'Her: abstraksjonsprinsippet innen tysk rettsvesen',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LNC',
            label: 'Selskaps-, handels- og konkurranserett',
            originalLabel: 'Selskaps-, handels- og konkurranserett',
            alternativeLabels: [],
            note: 'Her: lovsamlinger for ulike sektorer innen næringslivet',
            related: [],
            children: [
              {
                id: 'LNCB',
                label: 'Forretningsjus',
                originalLabel: 'Forretningsjus',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'LNCB1',
                    label: 'Franchiserett',
                    originalLabel: 'Franchiserett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNCB2',
                    label: 'E-handelsloven',
                    originalLabel: 'E-handelsloven',
                    alternativeLabels: ['netthandelsloven'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNCB3',
                    label: 'Kjøpsrett',
                    originalLabel: 'Kjøpsrett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNCB4',
                    label: 'Utkontraktering: lov og rett',
                    originalLabel: 'Utkontraktering: lov og rett',
                    alternativeLabels: ['outsourcing'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNCB5',
                    label: 'Shipping: lov og rett',
                    originalLabel: 'Shipping: lov og rett',
                    alternativeLabels: [],
                    note: 'Brukes for nasjonal lovgivning om sjørett. Se også LBBK <a href="/thema/nb/LBBK">Folkerett: havrett</a>, LBDM <a href="/thema/nb/LBDM">Internasjonal rett, transport og handel: sjørett</a>',
                    related: [
                      {
                        id: 'LBBK',
                        label: 'Folkerett: havrett',
                        originalLabel: 'Folkerett: havrett',
                        note: 'Her: lover vedrørende maritime grenser, ressurser etc. slik det påvirker suverene stater. Se også LBDM <a href="/thema/nb/LBDM">Internasjonal rett, transport og handel: sjørett</a>, LNCB5 <a href="/thema/nb/LNCB5">Shipping: lov og rett</a>',
                      },
                      {
                        id: 'LBDM',
                        label:
                          'Internasjonal rett, transport og handel: sjørett',
                        originalLabel:
                          'Internasjonal rett, transport og handel: sjørett',
                        note: 'Her: jus vedrørende frakt, last, forsikring, sjørøveri etc. om det gjelder kommersielle organisasjoner og ikke suverene stater. Se også LBBK <a href="/thema/nb/LBBK">Folkerett: havrett</a>, LNCB5 <a href="/thema/nb/LNCB5">Shipping: lov og rett</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'LNCB6',
                    label: 'Luftfartsrett',
                    originalLabel: 'Luftfartsrett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNCB7',
                    label: 'Catering- og restaurantrett',
                    originalLabel: 'Catering- og restaurantrett',
                    alternativeLabels: [],
                    note: 'Brukes for bøker om lover og regler som handler om tilberedning av mat for det kommersielle markedet, og lover som har med institusjoner, firmaer eller personer som tilbereder, selger eller serverer mat til kunder, inkludert lover om hygiene, sanitærforhold og sikkerhet. Se også LNTV <a href="/thema/nb/LNTV">Næringsmiddellovgivning</a>',
                    related: [
                      {
                        id: 'LNTV',
                        label: 'Næringsmiddellovgivning',
                        originalLabel: 'Næringsmiddellovgivning',
                        note: 'Brukes for bøker som tar for seg lover og regler i tilknytning til næringsmiddelstandarder, inkludert import og eksport av mat, reguleringer av dyrevelferd, matsikkerhet, sporbarhet, merking og tilbakekallelser av matvarer, råd om allergier etc. Se også LNCB7 <a href="/thema/nb/LNCB7">Catering- og restaurantrett</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'LNCB8',
                    label: 'Reiselivs- og turismerett',
                    originalLabel: 'Reiselivs- og turismerett',
                    alternativeLabels: ['reiseliv', 'turisme'],
                    note: 'Brukes for bøker som handler om lover og regler i tilknytning til reiselivsnæringen og turisme',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LNCC',
                label: 'Overholdelse av regelverk',
                originalLabel: 'Overholdelse av regelverk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNCD',
                label: 'Selskapsrett',
                originalLabel: 'Selskapsrett',
                alternativeLabels: [],
                note: 'Her: generelle verk om selskapsrett',
                related: [],
                children: [
                  {
                    id: 'LNCD1',
                    label: 'Fusjon og overtakelser: lov og rett',
                    originalLabel: 'Fusjon og overtakelser: lov og rett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LNCE',
                label: 'Handelsregistrering: lov og rett',
                originalLabel: 'Handelsregistrering: lov og rett',
                alternativeLabels: [],
                note: 'Her: foretaksregisteret',
                related: [],
                children: [],
              },
              {
                id: 'LNCF',
                label: 'Kompaniskap og samarbeidslover',
                originalLabel: 'Kompaniskap og samarbeidslover',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNCG',
                label: 'Endringslov, endring av foretaksform',
                originalLabel: 'Endringslov, endring av foretaksform',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNCH',
                label: 'Konkurranserett og antitrustlovgivning',
                originalLabel: 'Konkurranserett og antitrustlovgivning',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNCJ',
                label: 'Kontraktsrett',
                originalLabel: 'Kontraktsrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNCK',
                label: 'Lovbrudd i bedrifter og næringsliv',
                originalLabel: 'Lovbrudd i bedrifter og næringsliv',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNCL',
                label: 'Agenturloven',
                originalLabel: 'Agenturloven',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNCN',
                label: 'Anskaffelsesloven',
                originalLabel: 'Anskaffelsesloven',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNCQ',
                label: 'Bygg- og anlegg: lov og rett',
                originalLabel: 'Bygg- og anlegg: lov og rett',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'LNCQ1',
                    label: 'Bygg og anlegg i privat sektor: lov og rett',
                    originalLabel:
                      'Bygg og anlegg i privat sektor: lov og rett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNCQ2',
                    label: 'Bygg og anlegg i offentlig sektor: lov og rett',
                    originalLabel:
                      'Bygg og anlegg i offentlig sektor: lov og rett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LNCR',
                label: 'Energi og naturressurser: lov og rett',
                originalLabel: 'Energi og naturressurser: lov og rett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LND',
            label: 'Konstitusjonell rett og forvaltningsrett',
            originalLabel: 'Konstitusjonell rett og forvaltningsrett',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LNDA',
                label: 'Statsborgerskap og nasjonalitet: lover og regler',
                originalLabel:
                  'Statsborgerskap og nasjonalitet: lover og regler',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'LNDA1',
                    label: 'Immigrasjonsrett',
                    originalLabel: 'Immigrasjonsrett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNDA3',
                    label: 'Asylrett',
                    originalLabel: 'Asylrett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LNDB',
                label: 'Administrativ jurisdiksjon og offentlig forvaltning',
                originalLabel:
                  'Administrativ jurisdiksjon og offentlig forvaltning',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'LNDB1',
                    label: 'Forvaltningsrett',
                    originalLabel: 'Forvaltningsrett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNDB2',
                    label: 'Forvaltningsrett: saksbehandling og domstoler',
                    originalLabel:
                      'Forvaltningsrett: saksbehandling og domstoler',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNDB3',
                    label: 'Statlig og offentlig eiendom',
                    originalLabel: 'Statlig og offentlig eiendom',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNDB4',
                    label: 'Forvaltningsrett: sanksjoner',
                    originalLabel: 'Forvaltningsrett: sanksjoner',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNDB5',
                    label: 'Forvaltningsrett: ekspropriasjon',
                    originalLabel: 'Forvaltningsrett: ekspropriasjon',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNDB6',
                    label:
                      'Opprettelse av regler og administrative bestemmelser',
                    originalLabel:
                      'Opprettelse av regler og administrative bestemmelser',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNDB7',
                    label: 'Regulering av offentlige tjenester',
                    originalLabel: 'Regulering av offentlige tjenester',
                    alternativeLabels: [],
                    note: 'Se også LNCN <a href="/thema/nb/LNCN">Anskaffelsesloven</a>',
                    related: [
                      {
                        id: 'LNCN',
                        label: 'Anskaffelsesloven',
                        originalLabel: 'Anskaffelsesloven',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'LNDB8',
                    label: 'Forskning og høyere utdanning: lov og rett',
                    originalLabel: 'Forskning og høyere utdanning: lov og rett',
                    alternativeLabels: [],
                    note: 'Se også PDJ <a href="/thema/nb/PDJ">Regulering av vitenskap og eksperimenter</a>',
                    related: [
                      {
                        id: 'PDJ',
                        label: 'Regulering av vitenskap og eksperimenter',
                        originalLabel:
                          'Regulering av vitenskap og eksperimenter',
                        note: 'Brukes for bøker om regulering, enten det er bestemt av juridiske eller andre instanser eller myndigheter, rettet mot forskere og praktikere. Brukes sammen med andre P*-koder for å angi område av vitenskap, der det er hensiktsmessig. Se også LNDB8 <a href="/thema/nb/LNDB8">Forskning og høyere utdanning: lov og rett</a>',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'LNDC',
                label: 'Jus: menneskerettigheter og borgerrettigheter',
                originalLabel: 'Jus: menneskerettigheter og borgerrettigheter',
                alternativeLabels: [],
                note: 'Her: grunnleggende rettigheter slik de praktiseres i en jurisdiksjon. Se også JPVH <a href="/thema/nb/JPVH">Menneskerettigheter og borgerrettigheter</a>',
                related: [
                  {
                    id: 'JPVH',
                    label: 'Menneskerettigheter og borgerrettigheter',
                    originalLabel: 'Menneskerettigheter og borgerrettigheter',
                    note: 'Her: ytrings-, informasjons- og religionsfrihet; landrettigheter, sivile og politiske rettigheter, økonomiske, sosiale og kulturelle rettigheter, etc. Brukes med GTU for bøker om overgangsrett. Se også LNDC <a href="/thema/nb/LNDC">Jus: menneskerettigheter og borgerrettigheter</a>',
                  },
                ],
                children: [
                  {
                    id: 'LNDC1',
                    label: 'Konstitusjonell rett og menneskerettigheter',
                    originalLabel:
                      'Konstitusjonell rett og menneskerettigheter',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNDC2',
                    label: 'Personvernrett',
                    originalLabel: 'Personvernrett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNDC4',
                    label: 'Ytringsfrihet',
                    originalLabel: 'Ytringsfrihet',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNDC5',
                    label:
                      'Grunnlovshistorie og komparativ konstitusjonell rett',
                    originalLabel:
                      'Grunnlovshistorie og komparativ konstitusjonell rett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LNDE',
                label: 'Tjenestemanns- og embetsmannsrett',
                originalLabel: 'Tjenestemanns- og embetsmannsrett',
                alternativeLabels: ['tjenestemannsrett', 'embetsmannsrett'],

                related: [],
                children: [],
              },
              {
                id: 'LNDF',
                label: 'Informasjonsfrihet',
                originalLabel: 'Informasjonsfrihet',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNDG',
                label: 'Religiøse lover og konkordater (overenskomster)',
                originalLabel:
                  'Religiøse lover og konkordater (overenskomster)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNDH',
                label: 'Regjeringsmakt',
                originalLabel: 'Regjeringsmakt',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNDJ',
                label: 'Statlig ansvar og erstatningsrett',
                originalLabel: 'Statlig ansvar og erstatningsrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNDK',
                label: 'Militæret, forsvaret, siviltjenesten: lov og rett',
                originalLabel:
                  'Militæret, forsvaret, siviltjenesten: lov og rett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNDL',
                label: 'Sikkerhets- og politirett, våpenlovgivning',
                originalLabel: 'Sikkerhets- og politirett, våpenlovgivning',
                alternativeLabels: [],
                note: 'Politi og sikkerhetstjenester: våpenloven',
                related: [],
                children: [],
              },
              {
                id: 'LNDM',
                label: 'Rettslig prøving',
                originalLabel: 'Rettslig prøving',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNDP',
                label: 'Parlamentarisk og lovgivende praksis',
                originalLabel: 'Parlamentarisk og lovgivende praksis',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNDS',
                label: 'Valgloven',
                originalLabel: 'Valgloven',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNDU',
                label: 'Kommunal forvaltning: lov og rett',
                originalLabel: 'Kommunal forvaltning: lov og rett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNDV',
                label: 'Regional forvaltning: lov og rett',
                originalLabel: 'Regional forvaltning: lov og rett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNDX',
                label: 'Grunnloven',
                originalLabel: 'Grunnloven',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LNE',
            label: 'Forvaltningsrett: handel',
            originalLabel: 'Forvaltningsrett: handel',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LNEA',
                label: 'Forvaltningsrett: offentlig økonomi',
                originalLabel: 'Forvaltningsrett: offentlig økonomi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNEB',
                label: 'Utenriksøkonomi og tollrett',
                originalLabel: 'Utenriksøkonomi og tollrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNEC',
                label: 'Forvaltningsrett: kvoter og subsidier',
                originalLabel: 'Forvaltningsrett: kvoter og subsidier',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNEF',
                label: 'Offentlige anskaffelser, tjenester og varer',
                originalLabel: 'Offentlige anskaffelser, tjenester og varer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LNF',
            label: 'Strafferett: prosedyre og lovbrudd',
            originalLabel: 'Strafferett: prosedyre og lovbrudd',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LNFB',
                label: 'Straffeloven',
                originalLabel: 'Straffeloven',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNFG',
                label: 'Strafferett: lovbrudd mot regjeringen',
                originalLabel: 'Strafferett: lovbrudd mot regjeringen',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'LNFG1',
                    label:
                      'Lovbrudd mot staten, mot offentlig administrasjon og rettsvesenet',
                    originalLabel:
                      'Lovbrudd mot staten, mot offentlig administrasjon og rettsvesenet',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNFG2',
                    label:
                      'Lovbrudd mot arealbruk og byplanlegging, monumenter, land- og miljøvern',
                    originalLabel:
                      'Lovbrudd mot arealbruk og byplanlegging, monumenter, land- og miljøvern',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LNFJ',
                label: 'Strafferett: lovbrudd mot individer',
                originalLabel: 'Strafferett: lovbrudd mot individer',
                alternativeLabels: [],
                note: 'Her: strafferett som dekker kriminalitet mot en person, f.eks. mord, drap, overfall, hatkriminalitet, voldskriminalitet etc.',
                related: [],
                children: [
                  {
                    id: 'LNFJ1',
                    label: 'Strafferett: trakassering og forfølgelse',
                    originalLabel: 'Strafferett: trakassering og forfølgelse',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNFJ2',
                    label: 'Strafferett: kjønnsrelatert vold',
                    originalLabel: 'Strafferett: kjønnsrelatert vold',
                    alternativeLabels: [],
                    note: 'Her: strafferett som dekker fysisk og psykisk vold mot kvinner og jenter, inkludert vold i hjemmet, voldtekt, seksuelle overgrep, partnervold, tvangsaborter eller -sterilisering, æresdrap',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LNFL',
                label: 'Strafferett: lovbrudd mot eiendom',
                originalLabel: 'Strafferett: lovbrudd mot eiendom',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNFN',
                label: 'Svindel',
                originalLabel: 'Svindel',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNFQ',
                label: 'Ungdomskriminalitet: lov og rett',
                originalLabel: 'Ungdomskriminalitet: lov og rett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNFR',
                label: 'Lovbrudd mot offentlig helse, sikkerhet og orden',
                originalLabel:
                  'Lovbrudd mot offentlig helse, sikkerhet og orden',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNFS',
                label: 'Strafferett: tilleggsstraff',
                originalLabel: 'Strafferett: tilleggsstraff',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNFT',
                label: 'Veitrafikkloven: trafikkovertredelser',
                originalLabel: 'Veitrafikkloven: trafikkovertredelser',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNFU',
                label: 'Strafferett: skatte- og avgiftskriminalitet',
                originalLabel: 'Strafferett: skatte- og avgiftskriminalitet',
                alternativeLabels: [],
                note: 'Her: lover, reguleringer og prosedyrer i tilknytning til skattesvindel, skatteunndragelse, ulovlig import av varer, hvitvasking',
                related: [],
                children: [],
              },
              {
                id: 'LNFV',
                label: 'Strafferett: terrorisme',
                originalLabel: 'Strafferett: terrorisme',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNFW',
                label: 'Strafferett: korrupsjon',
                originalLabel: 'Strafferett: korrupsjon',
                alternativeLabels: [],
                note: 'Her: lover om bestikkelser, antikorrupsjonslover',
                related: [],
                children: [],
              },
              {
                id: 'LNFX',
                label: 'Straffeprosess',
                originalLabel: 'Straffeprosess',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'LNFX1',
                    label: 'Straffeutmåling og straff',
                    originalLabel: 'Straffeutmåling og straff',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNFX3',
                    label: 'Straffeprosess: bevis',
                    originalLabel: 'Straffeprosess: bevis',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: 'LNFX31',
                        label:
                          'Straffeprosess: etterforskning og spesifikke fremgangsmåter',
                        originalLabel:
                          'Straffeprosess: etterforskning og spesifikke fremgangsmåter',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'LNFX5',
                    label: 'Politiloven og politiprosedyrer',
                    originalLabel: 'Politiloven og politiprosedyrer',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: 'LNFX51',
                        label: 'Politiloven: midlertidig forføyning',
                        originalLabel: 'Politiloven: midlertidig forføyning',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'LNFX7',
                    label: 'Fengselsloven',
                    originalLabel: 'Fengselsloven',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LNFY',
                label: 'Jurysaker',
                originalLabel: 'Jurysaker',
                alternativeLabels: [],
                note: 'Her: lagrette',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LNH',
            label: 'Sysselsettingsloven og arbeidsrett',
            originalLabel: 'Sysselsettingsloven og arbeidsrett',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LNHD',
                label:
                  'Arbeidsrett: diskriminering og trakassering i arbeidslivet',
                originalLabel:
                  'Arbeidsrett: diskriminering og trakassering i arbeidslivet',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNHH',
                label: 'HMS og arbeidsmiljøloven',
                originalLabel: 'HMS og arbeidsmiljøloven',
                alternativeLabels: [],
                note: 'Se også KNXC <a href="/thema/nb/KNXC">Helse og sikkerhet på arbeidsplassen</a>',
                related: [
                  {
                    id: 'KNXC',
                    label: 'Helse og sikkerhet på arbeidsplassen',
                    originalLabel: 'Helse og sikkerhet på arbeidsplassen',
                    note: 'Brukes for bøker som ser på helse, miljø og sikkerhet på arbeidsplassen, råd om hvordan skape trygge arbeidsplasser og et sunt arbeidsmiljø, overholde nødvendige lover og forskrifter, opplæring i helse, miljø og sikkerhet. Se også JMJ <a href="/thema/nb/JMJ">Arbeids- og industripsykologi</a>, LNHH <a href="/thema/nb/LNHH">HMS og arbeidsmiljøloven</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'LNHJ',
                label: 'Brudd på arbeidslovgivning og straff',
                originalLabel: 'Brudd på arbeidslovgivning og straff',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNHR',
                label: 'Industrielle relasjoner og fagforeningslover',
                originalLabel: 'Industrielle relasjoner og fagforeningslover',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNHU',
                label: 'Ansettelseskontrakter',
                originalLabel: 'Ansettelseskontrakter',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNHW',
                label: 'Fagopplæring: lov og rett',
                originalLabel: 'Fagopplæring: lov og rett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNHX',
                label: 'Arbeidsrett: prosedyre, tvisteløsning',
                originalLabel: 'Arbeidsrett: prosedyre, tvisteløsning',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LNJ',
            label: 'Underholdning og media: lov og rett',
            originalLabel: 'Underholdning og media: lov og rett',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LNJD',
                label: 'Ærekrenkelse og injurielovgivning',
                originalLabel: 'Ærekrenkelse og injurielovgivning',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNJS',
                label: 'Idrett: lov og rett',
                originalLabel: 'Idrett: lov og rett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNJX',
                label: 'Markedsrett',
                originalLabel: 'Markedsrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LNK',
            label: 'Miljø, transport og planlegging: lov og rett',
            originalLabel: 'Miljø, transport og planlegging: lov og rett',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LNKF',
                label: 'Landbruksrett',
                originalLabel: 'Landbruksrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNKG',
                label: 'Dyrevern: lov og rett',
                originalLabel: 'Dyrevern: lov og rett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNKJ',
                label: 'Miljøvern: lov og rett',
                originalLabel: 'Miljøvern: lov og rett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNKK',
                label: 'Katastrofeberedskap og brannvern: lov og rett',
                originalLabel: 'Katastrofeberedskap og brannvern: lov og rett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNKN',
                label: 'Naturvernloven',
                originalLabel: 'Naturvernloven',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNKP',
                label: 'Vann- og avløpsrett',
                originalLabel: 'Vann- og avløpsrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNKT',
                label: 'Transportrett',
                originalLabel: 'Transportrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNKV',
                label: 'Veier og motorveier: lov og rett',
                originalLabel: 'Veier og motorveier: lov og rett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNKW',
                label: 'Plan- og bygningsloven',
                originalLabel: 'Plan- og bygningsloven',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNKX',
                label: 'Vern av historiske bygninger og kulturverdier',
                originalLabel: 'Vern av historiske bygninger og kulturverdier',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LNL',
            label: 'Jus: legater, stiftelser, fond',
            originalLabel: 'Jus: legater, stiftelser, fond',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'LNM',
            label: 'Familierett',
            originalLabel: 'Familierett',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LNMB',
                label: 'Familierett: ekteskap, separasjon og skilsmisse',
                originalLabel:
                  'Familierett: ekteskap, separasjon og skilsmisse',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNMC',
                label: 'Familierett: samboerskap',
                originalLabel: 'Familierett: samboerskap',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNMF',
                label: 'Familierett: homofile partnerskap',
                originalLabel: 'Familierett: homofile partnerskap',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNMG',
                label: 'Familierett: vergemål',
                originalLabel: 'Familierett: vergemål',
                alternativeLabels: ['verger', 'umyndige'],
                note: 'Her: umyndiggjøring',
                related: [],
                children: [],
              },
              {
                id: 'LNMI',
                label: 'Familierett: økonomiske avtaler mellom partnere',
                originalLabel:
                  'Familierett: økonomiske avtaler mellom partnere',
                alternativeLabels: [],
                note: 'Her: lover om felles eiendom, ektepakt, særeie',
                related: [],
                children: [],
              },
              {
                id: 'LNMK',
                label: 'Familierett: barn',
                originalLabel: 'Familierett: barn',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LNP',
            label: 'Bank- og finansrett',
            originalLabel: 'Bank- og finansrett',
            alternativeLabels: ['bankrett'],

            related: [],
            children: [
              {
                id: 'LNPA',
                label: 'Regnskaps- og revisjonsrett',
                originalLabel: 'Regnskaps- og revisjonsrett',
                alternativeLabels: ['regnskapsrett'],

                related: [],
                children: [],
              },
              {
                id: 'LNPB',
                label: 'Bankrett',
                originalLabel: 'Bankrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNPC',
                label: 'Konkurs og insolvens',
                originalLabel: 'Konkurs og insolvens',
                alternativeLabels: [],
                note: 'Her: rettslige og relaterte fremgangsmåter for å avvikle eller omorganisere selskaper med økonomiske problemer',
                related: [],
                children: [
                  {
                    id: 'LNPC1',
                    label: 'Konkursrett: utenomrettslige prosedyrer',
                    originalLabel: 'Konkursrett: utenomrettslige prosedyrer',
                    alternativeLabels: [],
                    note: 'Her: ikke juridiske fremgangsmåter for selskaper med økonomiske problemer, f.eks. frivillige ordninger',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LNPD',
                label: 'Børs- og verdipapirrett og regulering',
                originalLabel: 'Børs- og verdipapirrett og regulering',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNPE',
                label: 'Finansrett: aktive eierfond',
                originalLabel: 'Finansrett: aktive eierfond',
                alternativeLabels: [],
                note: 'Brukes for bøker om lover og reguleringer som dekker finanstransaksjoner som inkluderer risikokapital, oppkjøp og salg etc.',
                related: [],
                children: [],
              },
              {
                id: 'LNPF',
                label: 'Finansielle tjenester: lov og rett',
                originalLabel: 'Finansielle tjenester: lov og rett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNPN',
                label: 'Forsikringsrett',
                originalLabel: 'Forsikringsrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNPP',
                label: 'Pensjoner, alderspensjon og privat tjenestepensjon',
                originalLabel:
                  'Pensjoner, alderspensjon og privat tjenestepensjon',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LNQ',
            label: 'IT og kommunikasjon: lover og regler',
            originalLabel: 'IT og kommunikasjon: lover og regler',
            alternativeLabels: [],
            note: 'Her: Postregelverket, teknologi og telekommunikasjon. Se også LNCB2 <a href="/thema/nb/LNCB2">E-handelsloven</a>',
            related: [
              {
                id: 'LNCB2',
                label: 'E-handelsloven',
                originalLabel: 'E-handelsloven',
              },
            ],
            children: [
              {
                id: 'LNQD',
                label: 'Jus: personvern (datalagring)',
                originalLabel: 'Jus: personvern (datalagring)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNQE',
                label: 'Datakriminalitet, internettkriminalitet',
                originalLabel: 'Datakriminalitet, internettkriminalitet',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LNR',
            label: 'Immaterialrett (åndsrett)',
            originalLabel: 'Immaterialrett (åndsrett)',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LNRC',
                label: 'Opphavsrett',
                originalLabel: 'Opphavsrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNRD',
                label: 'Patentrett',
                originalLabel: 'Patentrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNRF',
                label: 'Varemerkerett',
                originalLabel: 'Varemerkerett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNRL',
                label: 'Designrett',
                originalLabel: 'Designrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNRV',
                label: 'Taushetsplikt',
                originalLabel: 'Taushetsplikt',
                alternativeLabels: [],
                note: 'Se også LNQD <a href="/thema/nb/LNQD">Jus: personvern (datalagring)</a>',
                related: [
                  {
                    id: 'LNQD',
                    label: 'Jus: personvern (datalagring)',
                    originalLabel: 'Jus: personvern (datalagring)',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'LNS',
            label: 'Tingsrett',
            originalLabel: 'Tingsrett',
            alternativeLabels: [],
            note: 'Her: generelle bestemmelser innen eiendomsrett. Se også LNKW <a href="/thema/nb/LNKW">Plan- og bygningsloven</a>',
            related: [
              {
                id: 'LNKW',
                label: 'Plan- og bygningsloven',
                originalLabel: 'Plan- og bygningsloven',
              },
            ],
            children: [
              {
                id: 'LNSH',
                label: 'Bolig- og eiendomsrett',
                originalLabel: 'Bolig- og eiendomsrett',
                alternativeLabels: ['boligrett'],
                note: 'Her: odelsrett, fast eiendom',
                related: [],
                children: [
                  {
                    id: 'LNSH1',
                    label: 'Eierskap og panterett',
                    originalLabel: 'Eierskap og panterett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNSH3',
                    label: 'Husleierett',
                    originalLabel: 'Husleierett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNSH5',
                    label: 'Overdragelse: lov og rett',
                    originalLabel: 'Overdragelse: lov og rett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNSH7',
                    label: 'Verdivurdering og taksering: lov og rett',
                    originalLabel: 'Verdivurdering og taksering: lov og rett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNSH9',
                    label: 'Boligrett',
                    originalLabel: 'Boligrett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LNSP',
                label: 'Personlig eiendomsrett',
                originalLabel: 'Personlig eiendomsrett',
                alternativeLabels: [],
                note: 'Her: lover om løsøre, immateriell og materiell eiendom',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LNT',
            label: 'Helse- og sosialrett',
            originalLabel: 'Helse- og sosialrett',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LNTC',
                label: 'Veldedighet: lov og rett',
                originalLabel: 'Veldedighet: lov og rett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNTD',
                label: 'Utdanningsrett',
                originalLabel: 'Utdanningsrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNTH',
                label: 'Trygde- og velferdsrett',
                originalLabel: 'Trygde- og velferdsrett',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'LNTH1',
                    label: 'Sosialforsikring: lov og rett',
                    originalLabel: 'Sosialforsikring: lov og rett',
                    alternativeLabels: [],
                    note: 'Her: folketrygden',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LNTJ',
                label: 'Folkehelse og sikkerhet: lov og regler',
                originalLabel: 'Folkehelse og sikkerhet: lov og regler',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNTM',
                label: 'Helserett',
                originalLabel: 'Helserett',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'LNTM1',
                    label: 'Psykisk helsevern: lov og rett',
                    originalLabel: 'Psykisk helsevern: lov og rett',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LNTM2',
                    label: 'Regulering av medisin og medisinsk utstyr',
                    originalLabel: 'Regulering av medisin og medisinsk utstyr',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'LNTN',
                label: 'Apotekloven',
                originalLabel: 'Apotekloven',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNTQ',
                label: 'Funksjonsnedsettelser: lov og rett',
                originalLabel: 'Funksjonsnedsettelser: lov og rett',
                alternativeLabels: [
                  'funksjonshemninger',
                  'funksjonshemminger',
                  'funksjonshemmede',
                ],

                related: [],
                children: [],
              },
              {
                id: 'LNTS',
                label: 'Eldre: lov og rett',
                originalLabel: 'Eldre: lov og rett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNTU',
                label: 'Forbrukervern: lov og rett',
                originalLabel: 'Forbrukervern: lov og rett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNTV',
                label: 'Næringsmiddellovgivning',
                originalLabel: 'Næringsmiddellovgivning',
                alternativeLabels: [],
                note: 'Brukes for bøker som tar for seg lover og regler i tilknytning til næringsmiddelstandarder, inkludert import og eksport av mat, reguleringer av dyrevelferd, matsikkerhet, sporbarhet, merking og tilbakekallelser av matvarer, råd om allergier etc. Se også LNCB7 <a href="/thema/nb/LNCB7">Catering- og restaurantrett</a>',
                related: [
                  {
                    id: 'LNCB7',
                    label: 'Catering- og restaurantrett',
                    originalLabel: 'Catering- og restaurantrett',
                    note: 'Brukes for bøker om lover og regler som handler om tilberedning av mat for det kommersielle markedet, og lover som har med institusjoner, firmaer eller personer som tilbereder, selger eller serverer mat til kunder, inkludert lover om hygiene, sanitærforhold og sikkerhet. Se også LNTV <a href="/thema/nb/LNTV">Næringsmiddellovgivning</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'LNTX',
                label: 'Lovgivning om løyver, pengespill og utesteder',
                originalLabel: 'Lovgivning om løyver, pengespill og utesteder',
                alternativeLabels: ['gambling'],
                note: 'Brukes for bøker om lovgivning i tilknytning til alkoholsalg, pengespill etc., inkludert forbud og lovgivning i tilknytning til etablissementer',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LNU',
            label: 'Lovgivning om skatt og avgifter',
            originalLabel: 'Lovgivning om skatt og avgifter',
            alternativeLabels: [],
            note: 'Bruk LNU* for lovgivning, lover og regler som forbindes med ulike typer skatt. Brukes med KFC* eller KFF* for bøker ment for regnskapsførere i finanssektoren',
            related: [],
            children: [
              {
                id: 'LNUC',
                label: 'Selskapsskatt',
                originalLabel: 'Selskapsskatt',
                alternativeLabels: [],
                note: 'Her: lover om selskapsskatt og andre typer beskatning av bedrifter eller organisasjoner. Se også LNUG <a href="/thema/nb/LNUG">Lovregulering av skatt i arbeidslivet</a>',
                related: [
                  {
                    id: 'LNUG',
                    label: 'Lovregulering av skatt i arbeidslivet',
                    originalLabel: 'Lovregulering av skatt i arbeidslivet',
                    note: 'Her: lover og regler i forbindelse med skatt som betales av arbeidsgivere og arbeidstakere, arbeidsgiveravgift. Se også LNUC <a href="/thema/nb/LNUC">Selskapsskatt</a>, LNUP <a href="/thema/nb/LNUP">Skatt av personlig inntekt</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'LNUD',
                label:
                  'Lokal beskatning, kostnader, prising i offentlig sektor',
                originalLabel:
                  'Lokal beskatning, kostnader, prising i offentlig sektor',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNUE',
                label: 'Grønn skattepolitikk',
                originalLabel: 'Grønn skattepolitikk',
                alternativeLabels: [],
                note: 'Her: beskatning av bruk av naturressurser, skatt på utslipp av drivhusgasser, CO2-avgift, karbonavgift',
                related: [],
                children: [],
              },
              {
                id: 'LNUG',
                label: 'Lovregulering av skatt i arbeidslivet',
                originalLabel: 'Lovregulering av skatt i arbeidslivet',
                alternativeLabels: [],
                note: 'Her: lover og regler i forbindelse med skatt som betales av arbeidsgivere og arbeidstakere, arbeidsgiveravgift. Se også LNUC <a href="/thema/nb/LNUC">Selskapsskatt</a>, LNUP <a href="/thema/nb/LNUP">Skatt av personlig inntekt</a>',
                related: [
                  {
                    id: 'LNUC',
                    label: 'Selskapsskatt',
                    originalLabel: 'Selskapsskatt',
                    note: 'Her: lover om selskapsskatt og andre typer beskatning av bedrifter eller organisasjoner. Se også LNUG <a href="/thema/nb/LNUG">Lovregulering av skatt i arbeidslivet</a>',
                  },
                  {
                    id: 'LNUP',
                    label: 'Skatt av personlig inntekt',
                    originalLabel: 'Skatt av personlig inntekt',
                    note: 'Her: inntektsskatt, formuesskatt. Se også LNUG <a href="/thema/nb/LNUG">Lovregulering av skatt i arbeidslivet</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'LNUP',
                label: 'Skatt av personlig inntekt',
                originalLabel: 'Skatt av personlig inntekt',
                alternativeLabels: [],
                note: 'Her: inntektsskatt, formuesskatt. Se også LNUG <a href="/thema/nb/LNUG">Lovregulering av skatt i arbeidslivet</a>',
                related: [
                  {
                    id: 'LNUG',
                    label: 'Lovregulering av skatt i arbeidslivet',
                    originalLabel: 'Lovregulering av skatt i arbeidslivet',
                    note: 'Her: lover og regler i forbindelse med skatt som betales av arbeidsgivere og arbeidstakere, arbeidsgiveravgift. Se også LNUC <a href="/thema/nb/LNUC">Selskapsskatt</a>, LNUP <a href="/thema/nb/LNUP">Skatt av personlig inntekt</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'LNUS',
                label: 'Omsetningsavgift, tariffer og toll',
                originalLabel: 'Omsetningsavgift, tariffer og toll',
                alternativeLabels: [],
                note: 'Her: lovgivning i tilknytning til merverdiavgift og omsetningsavgift',
                related: [],
                children: [],
              },
              {
                id: 'LNUT',
                label: 'Beskatning av stiftelser og arv, gaver',
                originalLabel: 'Beskatning av stiftelser og arv, gaver',
                alternativeLabels: [],
                note: 'Her: arveavgift',
                related: [],
                children: [],
              },
              {
                id: 'LNUU',
                label: 'Eiendomsskatt og verdivurdering av eiendom',
                originalLabel: 'Eiendomsskatt og verdivurdering av eiendom',
                alternativeLabels: [],
                note: 'Her: grunnverdiavgift',
                related: [],
                children: [],
              },
              {
                id: 'LNUV',
                label: 'Avgifter',
                originalLabel: 'Avgifter',
                alternativeLabels: [],
                note: 'Her: lovgivning i tilknytning til avgifter på varer som alkohol, brensel, tobakk',
                related: [],
                children: [],
              },
              {
                id: 'LNUW',
                label: 'Øvrige transaksjonsavgifter',
                originalLabel: 'Øvrige transaksjonsavgifter',
                alternativeLabels: [],
                note: 'Her: skattelegging av finanstransaksjoner, skatt på valutatransaksjoner etc.',
                related: [],
                children: [],
              },
              {
                id: 'LNUX',
                label: 'Internasjonal skatterett',
                originalLabel: 'Internasjonal skatterett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNUY',
                label: 'Beskatningsprosedyre',
                originalLabel: 'Beskatningsprosedyre',
                alternativeLabels: [],
                note: 'Her: lovgivning for skatteadministrasjon og skatteprosedyrer. Brukes for bøker om prosedyrer som regulerer forholdet mellom staten og skattebetalerne når det gjelder rettigheter og plikter for begge parter, inkludert tiltak for å forhindre skatteunndragelse etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LNV',
            label: 'Erstatningsrett, skader og kompensasjon',
            originalLabel: 'Erstatningsrett, skader og kompensasjon',
            alternativeLabels: [],
            note: 'Her: tort og svie. Se også LNAC14 <a href="/thema/nb/LNAC14">Skade og erstatning</a>',
            related: [
              {
                id: 'LNAC14',
                label: 'Skade og erstatning',
                originalLabel: 'Skade og erstatning',
              },
            ],
            children: [
              {
                id: 'LNVC',
                label: 'Uaktsomhet',
                originalLabel: 'Uaktsomhet',
                alternativeLabels: [],
                note: 'Brukes for både generell og profesjonell uaktsomhet',
                related: [],
                children: [],
              },
              {
                id: 'LNVF',
                label: 'Ordensforstyrrelser',
                originalLabel: 'Ordensforstyrrelser',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LNVJ',
                label: 'Personskader',
                originalLabel: 'Personskader',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LNW',
            label: 'Jus: testamenter, tinglysninger, arverett',
            originalLabel: 'Jus: testamenter, tinglysninger, arverett',
            alternativeLabels: ['arv'],
            note: 'Her: bøker som handler om lover som dekker arv av eiendom, titler, gjeld og rettigheter, tronfølgelover',
            related: [],
            children: [
              {
                id: 'LNWB',
                label: 'Jus: privatpersoners juridiske forhold',
                originalLabel: 'Jus: privatpersoners juridiske forhold',
                alternativeLabels: ['trust', 'truster'],
                note: 'Brukes for bøker som er ment for jurister som jobber på vegne av privatpersoner med juridiske forhold som formuesforvaltning, testamenter, skifte, forberedelser for arv. Brukes med andre L*-koder for juridiske spesialområder',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LNX',
            label: 'Offentlig rett',
            originalLabel: 'Offentlig rett',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'LNZ',
            label: 'Primære rettskilder',
            originalLabel: 'Primære rettskilder',
            alternativeLabels: [],
            note: 'Bruk LNZ* for utgivelser av originale tekster som grunnlover, rettsavgjørelser, dommer. Brukes med andre L*-koder for å angi rettskilder og rettsområder',
            related: [],
            children: [
              {
                id: 'LNZC',
                label: 'Rettspraksis',
                originalLabel: 'Rettskilder: rettspraksis, presedens',
                alternativeLabels: [],
                note: 'Brukes for bøker om retts- og domsavgjørelser',
                related: [],
                children: [],
              },
              {
                id: 'LNZL',
                label: 'Lovgivning',
                originalLabel: 'Rettskilder: lovgivning',
                alternativeLabels: [],
                note: 'Her: rettskilder fra lovgivende institusjoner og myndigheter, lover og særtrykk',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'LW',
        label: 'Sharia',
        originalLabel: 'Sharia',
        alternativeLabels: [],
        note: 'Her: islamsk lov',
        related: [],
        children: [
          {
            id: 'LWF',
            label: 'Sharia: Fiqhs fire rettskoler',
            originalLabel: 'Sharia: Fiqhs fire rettskoler',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LWFA',
                label: 'Sharia: Fiqhs fire rettskoler: Al-Shafi’i',
                originalLabel: 'Sharia: Fiqhs fire rettskoler: Al-Shafi’i',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LWFB',
                label: 'Sharia: Fiqhs fire rettskoler: Al-Hanbali',
                originalLabel: 'Sharia: Fiqhs fire rettskoler: Al-Hanbali',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LWFC',
                label: 'Sharia: Fiqhs fire rettskoler: Al-Maaliki',
                originalLabel: 'Sharia: Fiqhs fire rettskoler: Al-Maaliki',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LWFD',
                label: 'Sharia: Fiqhs fire rettskoler: Al- Hanafi',
                originalLabel: 'Sharia: Fiqhs fire rettskoler: Al- Hanafi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'LWK',
            label: 'Sharia: hovedemner og praksis',
            originalLabel: 'Sharia: hovedemner og praksis',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'LWKF',
                label: 'Sharia: familieforhold',
                originalLabel: 'Sharia: familieforhold',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LWKG',
                label: 'Sharia: forbrytelser og straff',
                originalLabel: 'Sharia: forbrytelser og straff',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LWKH',
                label: 'Sharia: arv og avskaffelse av eiendom',
                originalLabel: 'Sharia: arv og avskaffelse av eiendom',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LWKL',
                label: 'Sharia: økonomi og finans',
                originalLabel: 'Sharia: økonomi og finans',
                alternativeLabels: [],
                note: 'Her: islamske banklover',
                related: [],
                children: [],
              },
              {
                id: 'LWKM',
                label: 'Sharia: kost',
                originalLabel: 'Sharia: kost',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LWKN',
                label: 'Sharia: alkohol og pengespill',
                originalLabel: 'Sharia: alkohol og pengespill',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LWKP',
                label: 'Sharia: skikk og bruk',
                originalLabel: 'Sharia: skikk og bruk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'LWKR',
                label: 'Sharia: rettspleie (inkluderer straff og frafall)',
                originalLabel:
                  'Sharia: rettspleie (inkluderer straff og frafall)',
                alternativeLabels: [],
                note: 'Her: fatwa',
                related: [],
                children: [],
              },
              {
                id: 'LWKT',
                label: 'Sharia: islamske ritualer',
                originalLabel: 'Sharia: islamske ritualer',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'LWKT1',
                    label: 'Sharia: islamske ritualer: renselse',
                    originalLabel: 'Sharia: islamske ritualer: renselse',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LWKT2',
                    label: 'Sharia: islamske ritualer: Jihad',
                    originalLabel: 'Sharia: islamske ritualer: Jihad',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LWKT3',
                    label:
                      'Sharia: islamske ritualer: bønn og gravleggingsbønn',
                    originalLabel:
                      'Sharia: islamske ritualer: bønn og gravleggingsbønn',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LWKT4',
                    label: 'Sharia: islamske ritualer: zakat (almisser)',
                    originalLabel:
                      'Sharia: islamske ritualer: zakat (almisser)',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LWKT5',
                    label: 'Sharia: islamske ritualer: faste',
                    originalLabel: 'Sharia: islamske ritualer: faste',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'LWKT6',
                    label: 'Sharia: islamske ritualer: pilegrimsreiser',
                    originalLabel: 'Sharia: islamske ritualer: pilegrimsreiser',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'LX',
        label: 'Jus: studie- og repetisjonsguider',
        originalLabel: 'Jus: studie- og repetisjonsguider',
        alternativeLabels: [],
        note: 'Her: repetisjon og forberedelse til juridiske eksamener. Brukes med andre koder fra L* og kvalifikatorer for geografi eller pedagogisk formål der det er hensiktsmessig',
        related: [],
        children: [],
      },
    ],
  },
  {
    id: 'M',
    label: 'Medisin og sykepleie',
    originalLabel: 'Medisin og sykepleie',
    alternativeLabels: [],
    note: 'Bruk M*-koder kun for akademiske utgivelser og utgivelser for profesjonsmarkedet. Brukes med kvalifikatorer fra 4* der det er hensiktsmessig. Foretrekk V*-koder for bøker for allmennmarkedet',
    related: [],
    children: [
      {
        id: 'MB',
        label: 'Medisin',
        originalLabel: 'Medisin',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'MBD',
            label: 'Medisinsk profesjon',
            originalLabel: 'Medisinsk profesjon',
            alternativeLabels: [],
            note: 'Brukes for bøker om ulike yrker eller stillinger innen medisin, f.eks. en lege eller en LIS-lege. Brukes med DNBT eller DNC for biografier, KCVJ for økonomi i helsevesenet',
            related: [],
            children: [
              {
                id: 'MBDC',
                label: 'Medisinsk etikk og yrkesutøvelse',
                originalLabel: 'Medisinsk etikk og yrkesutøvelse',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MBDP',
                label: 'Lege/pasient-forhold',
                originalLabel: 'Lege/pasient-forhold',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MBDS',
                label: 'Pasientsikkerhet',
                originalLabel: 'Pasientsikkerhet',
                alternativeLabels: [],
                note: 'Brukes for profesjonelle og spesialiserte bøker om prosedyrer, sikringstiltak, standarder og opplæring utformet for å ivareta sikkerhet og omsorg til pasienter innen helsevesenet. Se også MJCJ3 <a href="/thema/nb/MJCJ3">Sykehusinfeksjoner</a>',
                related: [
                  {
                    id: 'MJCJ3',
                    label: 'Sykehusinfeksjoner',
                    originalLabel: 'Sykehusinfeksjoner',
                    note: 'Her: nonsokomielle infeksjoner, iatrogene sykdommer (sykdommer forårsaket av behandling). Se også MBDS <a href="/thema/nb/MBDS">Pasientsikkerhet</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'MBF',
            label: 'Medisinsk- og helsefaglig informatikk',
            originalLabel: 'Medisinsk- og helsefaglig informatikk',
            alternativeLabels: [],
            note: 'Her: bruk av kunstig intelligens i helsevesenet, elektronisk pasientjournal. Brukes for bøker som handler om design, utvikling og bruk av digitale systemer for å forbedre helsevesenet. Se også PSAX <a href="/thema/nb/PSAX">Bioinformatikk</a>',
            related: [
              {
                id: 'PSAX',
                label: 'Bioinformatikk',
                originalLabel: 'Bioinformatikk',
                note: 'Se også MBF <a href="/thema/nb/MBF">Medisinsk- og helsefaglig informatikk</a>',
              },
            ],
            children: [],
          },
          {
            id: 'MBG',
            label: 'Medisinsk utstyr og teknikker',
            originalLabel: 'Medisinsk utstyr og teknikker',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'MBGL',
                label: 'Medisinsk laboratorietesting og teknikker',
                originalLabel: 'Medisinsk laboratorietesting og teknikker',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MBGR',
                label: 'Medisinsk forskning',
                originalLabel: 'Medisinsk forskning',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'MBGR1',
                    label: 'Kliniske forsøk',
                    originalLabel: 'Kliniske forsøk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'MBGT',
                label: 'Telemedisin',
                originalLabel: 'Telemedisin',
                alternativeLabels: [],
                note: 'Her: digitale konsultasjoner. Brukes med andre M*-koder for å angi medisinske spesialområder der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MBN',
            label: 'Folkehelse og forebyggende medisin',
            originalLabel: 'Folkehelse og forebyggende medisin',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'MBNC',
                label: 'Medisinsk screening',
                originalLabel: 'Medisinsk screening',
                alternativeLabels: [],
                note: 'Her: screeningprogrammer, screening av høyrisikogrupper eller utvalgte grupper i befolkningen',
                related: [],
                children: [],
              },
              {
                id: 'MBNH',
                label: 'Personlig helse og folkehelse / helseopplysning',
                originalLabel:
                  'Personlig helse og folkehelse / helseopplysning',
                alternativeLabels: [],
                note: 'Her: profesjonelle og spesialiserte bøker om helseopplysning, pasientopplæring, opplæring av de som skal drive med helseopplysning',
                related: [],
                children: [
                  {
                    id: 'MBNH1',
                    label: 'Hygiene',
                    originalLabel: 'Hygiene',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'MBNH2',
                    label: 'Miljøfaktorer',
                    originalLabel: 'Miljøfaktorer',
                    alternativeLabels: [],
                    note: 'Her: miljøfaktorers påvirkning på folkehelsen etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'MBNH3',
                    label: 'Ernæringsfysiologi og ernæring',
                    originalLabel: 'Ernæringsfysiologi og ernæring',
                    alternativeLabels: [],
                    note: 'Brukes for medisinske og akademiske bøker om forholdet mellom kosthold/ernæring og helse og sykdommer. Brukes med PS eller PND for bøker om mat som medisin eller ernæring i helsevesenet, som først og fremst er beregnet på profesjonsmarkedet eller for høyere utdanning. Se også VFM <a href="/thema/nb/VFM">Trening og kosthold</a>',
                    related: [
                      {
                        id: 'VFM',
                        label: 'Trening og kosthold',
                        originalLabel: 'Trening og kosthold',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'MBNH4',
                    label: 'Prevensjon, fødselskontroll og familieplanlegging',
                    originalLabel:
                      'Prevensjon, fødselskontroll og familieplanlegging',
                    alternativeLabels: [],
                    note: 'Brukes med VFX* for bøker for allmennmarkedet',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'MBNH9',
                    label: 'Helsepsykologi',
                    originalLabel: 'Helsepsykologi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'MBNK',
                label: 'Vaksinasjon',
                originalLabel: 'Vaksinasjon',
                alternativeLabels: ['vaksiner', 'vaksinering'],
                note: 'Se også MJCM <a href="/thema/nb/MJCM">Immunologi</a>',
                related: [
                  {
                    id: 'MJCM',
                    label: 'Immunologi',
                    originalLabel: 'Immunologi',
                    note: 'Her: immunsystemet, immunsykdommer, immunologisk avstøtningsreaksjon, immunterapi. Se også MBNK <a href="/thema/nb/MBNK">Vaksinasjon</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'MBNS',
                label: 'Epidemiologi og medisinsk statistikk',
                originalLabel: 'Epidemiologi og medisinsk statistikk',
                alternativeLabels: [],
                note: 'Her: bøker som handler om innsamling av data for statistikk og anvendelse av disse innen medisinske spesialområder, eller om forekomst, spredning og kontroll av sykdommer (både epidemier og sykdommer generelt), og andre helserelaterte forhold',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MBP',
            label: 'Helsesystemer og -tjenester',
            originalLabel: 'Helsesystemer og -tjenester',
            alternativeLabels: [],
            note: 'Her: bøker om sykehus. Brukes for bøker om systemer i helsevesenet, hvordan disse finansieres. Foretrekk KCVJ for bøker som tar for seg helsevesenet fra et økonomisk perspektiv. Se også KCVJ <a href="/thema/nb/KCVJ">Helseøkonomi</a>',
            related: [
              {
                id: 'KCVJ',
                label: 'Helseøkonomi',
                originalLabel: 'Helseøkonomi',
                note: 'Her: helseindustri, forholdet mellom økonomi, helse og helsetjenester, etc. Se også MBP <a href="/thema/nb/MBP">Helsesystemer og -tjenester</a>',
              },
            ],
            children: [
              {
                id: 'MBPA',
                label: 'Allmennmedisin, Primærhelsetjenesten',
                originalLabel: 'Allmennmedisin, Primærhelsetjenesten',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MBPC',
                label: 'Allmennpraksis',
                originalLabel: 'Allmennpraksis',
                alternativeLabels: [],
                note: 'Her: allmennleger',
                related: [],
                children: [],
              },
              {
                id: 'MBPK',
                label: 'Psykisk helsetjeneste',
                originalLabel: 'Psykisk helsetjeneste',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MBPM',
                label: 'Medisinsk administrasjon og ledelse',
                originalLabel: 'Medisinsk administrasjon og ledelse',
                alternativeLabels: ['helseadministrasjon'],

                related: [],
                children: [],
              },
              {
                id: 'MBPN',
                label: 'Pleieinstitusjoner',
                originalLabel: 'Pleieinstitusjoner',
                alternativeLabels: [],
                note: 'Her: aldershjem, sykehjem, behandlingshjem, pleiehjem, hospice, institusjoner for rehabilitering og rekonvalesens. Se også JKSG <a href="/thema/nb/JKSG">Eldreomsorg</a>, MQCL4 <a href="/thema/nb/MQCL4">Geriatrisk sykepleie</a>, MQCL9 <a href="/thema/nb/MQCL9">Terminalpleie</a>',
                related: [
                  {
                    id: 'JKSG',
                    label: 'Eldreomsorg',
                    originalLabel: 'Eldreomsorg',
                    note: 'Her: vold mot og mishandling av eldre, omsorgssvikt, aldershjem og sykehjem. Brukes for sosial- og helsetjenester for eldre, politikk, yrker og roller i eldreomsorg',
                  },
                  {
                    id: 'MQCL4',
                    label: 'Geriatrisk sykepleie',
                    originalLabel: 'Geriatrisk sykepleie',
                    note: 'Her: gerontologisk sykepleie, pleie av eldre',
                  },
                  {
                    id: 'MQCL9',
                    label: 'Terminalpleie',
                    originalLabel: 'Terminalpleie',
                    note: 'Her: pleie av døende, palliativ sykepleie',
                  },
                ],
                children: [],
              },
              {
                id: 'MBPR',
                label: 'Helseforsikring',
                originalLabel: 'Helseforsikring',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MBQ',
            label: 'Rettsmedisinske problemstillinger',
            originalLabel: 'Rettsmedisinske problemstillinger',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MBS',
            label: 'Helsesosiologi',
            originalLabel: 'Helsesosiologi',
            alternativeLabels: [],
            note: 'Brukes bor bøker som handler om praktisk bruk og anvendelse av sosiologi innen medisin og helsevesen, eller bøker som studerer årsaker til og konsekvenser av god eller dårlig helse',
            related: [],
            children: [],
          },
          {
            id: 'MBX',
            label: 'Medisinsk historie',
            originalLabel: 'Medisinsk historie',
            alternativeLabels: [],
            note: 'Se også NHTF <a href="/thema/nb/NHTF">Historie: pest, sykdommer, hungersnød</a>',
            related: [
              {
                id: 'NHTF',
                label: 'Historie: pest, sykdommer, hungersnød',
                originalLabel: 'Historie: pest, sykdommer, hungersnød',
                note: 'Her: svartedauden, spanskesyken. Brukes for epidemier, pandemier, pest, smittsomme sykdommer og hungersnød gjennom historien. Brukes med JFF for bøker som handler om hvordan dette har påvirket tidligere samfunn. Se også MBX <a href="/thema/nb/MBX">Medisinsk historie</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'MF',
        label: 'Preklinisk medisin: basalfag',
        originalLabel: 'Preklinisk medisin: basalfag',
        alternativeLabels: [],
        note: 'Her: grunnleggende fag. Se også PSB <a href="/thema/nb/PSB">Biokjemi</a>',
        related: [
          {
            id: 'PSB',
            label: 'Biokjemi',
            originalLabel: 'Biokjemi',
            note: 'Her: biokjemisk immunologi, ikke-medisinsk toksikologi, enzymologi, lipider, metabolisme, biomolekyler, proteiner etc.',
          },
        ],
        children: [
          {
            id: 'MFC',
            label: 'Anatomi',
            originalLabel: 'Anatomi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'MFCC',
                label: 'Cytologi',
                originalLabel: 'Cytologi',
                alternativeLabels: [],
                note: 'Her: cellelære',
                related: [],
                children: [],
              },
              {
                id: 'MFCH',
                label: 'Histologi',
                originalLabel: 'Histologi',
                alternativeLabels: [],
                note: 'Her: vevslære',
                related: [],
                children: [],
              },
              {
                id: 'MFCR',
                label: 'Regional anatomi',
                originalLabel: 'Regional anatomi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MFCX',
                label: 'Disseksjon',
                originalLabel: 'Disseksjon',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MFG',
            label: 'Fysiologi',
            originalLabel: 'Fysiologi',
            alternativeLabels: [],
            note: 'Use for: medical titles on the study of how the human body works',
            related: [],
            children: [
              {
                id: 'MFGC',
                label: 'Cellefysiologi',
                originalLabel: 'Cellefysiologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MFGG',
                label: 'Regional fysiologi',
                originalLabel: 'Regional fysiologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MFGM',
                label: 'Metabolisme',
                originalLabel: 'Metabolisme',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MFGT',
                label: 'Elektrofysiologi',
                originalLabel: 'Elektrofysiologi',
                alternativeLabels: [],
                note: 'Her: elektroencefalografi, elektrokardiografi, elektrokortikografi, EEG, ECG, elektroneurografi',
                related: [],
                children: [],
              },
              {
                id: 'MFGV',
                label: 'Biomekanikk',
                originalLabel: 'Biomekanikk',
                alternativeLabels: [],
                note: 'Her: treningsfysiologi, kinesiologi, bøker for profesjonsmarkedet om menneskelig bevegelse. Se også SCGF <a href="/thema/nb/SCGF">Idrettsvitenskap, fysisk fostring</a>',
                related: [
                  {
                    id: 'SCGF',
                    label: 'Idrettsvitenskap, fysisk fostring',
                    originalLabel: 'Idrettsvitenskap, fysisk fostring',
                    note: 'Her: treningsfysiologi eller kinesologi for profesjonelle idrettsutøvere. Se også MFGV <a href="/thema/nb/MFGV">Biomekanikk</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'MFK',
            label: 'Menneskelig reproduksjon og utvikling',
            originalLabel: 'Menneskelig reproduksjon og utvikling',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'MFKC',
                label: 'Reproduksjonsmedisin',
                originalLabel: 'Reproduksjonsmedisin',
                alternativeLabels: ['befruktningsudyktighet', 'barnløshet'],
                note: 'Brukes for bøker om diagnostisering og behandling av infertilitet og andre områder innen reproduktiv helse, eller ulike medisinske spesialområder i tilknytning til pubertet, menopause, prevensjon og spesielle problemer i tilknytning til sex',
                related: [],
                children: [
                  {
                    id: 'MFKC1',
                    label: 'Infertilitet og assistert befruktning',
                    originalLabel: 'Infertilitet og assistert befruktning',
                    alternativeLabels: [
                      'fruktbarhetsbehandling',
                      'in vitro-fertilisering',
                      'barnløshet',
                    ],
                    note: 'Her: teknologi som brukes ved assistert befruktning',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'MFKC3',
                    label: 'Embryologi',
                    originalLabel: 'Embryologi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'MFKH',
                label: 'Menneskelig vekst og utvikling',
                originalLabel: 'Menneskelig vekst og utvikling',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'MFKH3',
                    label: 'Modning og aldring',
                    originalLabel: 'Modning og aldring',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'MFN',
            label: 'Medisinsk genetikk',
            originalLabel: 'Medisinsk genetikk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'MJ',
        label: 'Klinisk medisin og indremedisin',
        originalLabel: 'Klinisk medisin og indremedisin',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'MJA',
            label: 'Medisinsk diagnose',
            originalLabel: 'Medisinsk diagnose',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'MJAD',
                label: 'Undersøkelse av pasienter',
                originalLabel: 'Undersøkelse av pasienter',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MJC',
            label: 'Sykdommer og lidelser',
            originalLabel: 'Sykdommer og lidelser',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'MJCG',
                label: 'Medfødte sykdommer',
                originalLabel: 'Medfødte sykdommer',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'MJCG1',
                    label: 'Arvelige sykdommer og lidelser',
                    originalLabel: 'Arvelige sykdommer og lidelser',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'MJCJ',
                label: 'Smittsomme sykdommer',
                originalLabel: 'Smittsomme sykdommer',
                alternativeLabels: [
                  'pandemier',
                  'bakterier',
                  'virus',
                  'sopp',
                  'immunsystem',
                  'antibiotika',
                  'mikroorganismer',
                  'influensa',
                  'sars',
                  'mers',
                  'covid-19',
                  'koronavirus',
                  'tuberkulose',
                  'malaria',
                  'meslinger',
                  'luftveisinfeksjoner',
                ],
                note: 'Her: overførbare og smittsomme sykdommer, epidemier, virussykdommer, bakterieinfeksjoner, parasitter som forårsaker sykdom, behandling og forebygging. Se også MKFM <a href="/thema/nb/MKFM">Medisinsk mikrobiologi og virologi</a>',
                related: [
                  {
                    id: 'MKFM',
                    label: 'Medisinsk mikrobiologi og virologi',
                    originalLabel: 'Medisinsk mikrobiologi og virologi',
                    note: 'Her: bakteriofager, antibiotika, antivirale midler, medisinsk bakteriologi. Se også MJCJ <a href="/thema/nb/MJCJ">Smittsomme sykdommer</a>',
                  },
                ],
                children: [
                  {
                    id: 'MJCJ1',
                    label: 'Kjønnssykdommer',
                    originalLabel: 'Kjønnssykdommer',
                    alternativeLabels: ['HPV'],
                    note: 'Her: venerologi, seksuelt overførbare sykdommer; klamydia, gonore, herpes etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'MJCJ2',
                    label: 'HIV/AIDS',
                    originalLabel: 'HIV/AIDS',
                    alternativeLabels: [],
                    note: 'Her: retrovirus. Brukes for medisinske bøker om humant immunsviktvirus (HIV) og ervervet immunsviktsyndrom (AIDS)',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'MJCJ3',
                    label: 'Sykehusinfeksjoner',
                    originalLabel: 'Sykehusinfeksjoner',
                    alternativeLabels: [],
                    note: 'Her: nonsokomielle infeksjoner, iatrogene sykdommer (sykdommer forårsaket av behandling). Se også MBDS <a href="/thema/nb/MBDS">Pasientsikkerhet</a>',
                    related: [
                      {
                        id: 'MBDS',
                        label: 'Pasientsikkerhet',
                        originalLabel: 'Pasientsikkerhet',
                        note: 'Brukes for profesjonelle og spesialiserte bøker om prosedyrer, sikringstiltak, standarder og opplæring utformet for å ivareta sikkerhet og omsorg til pasienter innen helsevesenet. Se også MJCJ3 <a href="/thema/nb/MJCJ3">Sykehusinfeksjoner</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'MJCJ4',
                    label: 'Koronavirus',
                    originalLabel: 'Koronavirus',
                    alternativeLabels: ['koronavirussykdommer'],
                    note: 'Her: SARS, MERS, SARS-CoV-2 (Covid-19), koronavirus hos mennesker',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'MJCL',
                label: 'Onkologi',
                originalLabel: 'Onkologi',
                alternativeLabels: ['kreft'],
                note: 'Her: kreftsykdommer',
                related: [],
                children: [
                  {
                    id: 'MJCL1',
                    label: 'Strålebehandling',
                    originalLabel: 'Strålebehandling',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'MJCL2',
                    label: 'Cellegiftbehandling',
                    originalLabel: 'Cellegiftbehandling',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'MJCM',
                label: 'Immunologi',
                originalLabel: 'Immunologi',
                alternativeLabels: ['rejeksjon'],
                note: 'Her: immunsystemet, immunsykdommer, immunologisk avstøtningsreaksjon, immunterapi. Se også MBNK <a href="/thema/nb/MBNK">Vaksinasjon</a>',
                related: [
                  {
                    id: 'MBNK',
                    label: 'Vaksinasjon',
                    originalLabel: 'Vaksinasjon',
                    note: 'Se også MJCM <a href="/thema/nb/MJCM">Immunologi</a>',
                  },
                ],
                children: [
                  {
                    id: 'MJCM1',
                    label: 'Allergier',
                    originalLabel: 'Allergier',
                    alternativeLabels: ['allergi'],
                    note: 'Her: anafylaksi, pollen- og matallergi. Brukes for medisinske bøker om allergier',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'MJD',
            label: 'Kardiologi',
            originalLabel: 'Kardiologi',
            alternativeLabels: [
              'angiologi',
              'hjerteatakk',
              'hjerteinfarkt',
              'arytmi',
            ],
            note: 'Brukes for bøker om hjerte- og karsykdommer, diagnostisering og behandling av slike sykdommer',
            related: [],
            children: [],
          },
          {
            id: 'MJE',
            label: 'Muskel- og skjelettsykdommer',
            originalLabel: 'Muskel- og skjelettsykdommer',
            alternativeLabels: [],
            note: 'Brukes for bøker om diagnostisering og behandling av muskel- og skjelettsykdommer, inkludert skader og sykdommer i musklene, skjelettet og leddene. Se også MNS <a href="/thema/nb/MNS">Ortopedisk kirurgi og beinbrudd</a>',
            related: [
              {
                id: 'MNS',
                label: 'Ortopedisk kirurgi og beinbrudd',
                originalLabel: 'Ortopedisk kirurgi og beinbrudd',
                note: 'Se også MJE <a href="/thema/nb/MJE">Muskel- og skjelettsykdommer</a>',
              },
            ],
            children: [],
          },
          {
            id: 'MJF',
            label: 'Hematologi',
            originalLabel: 'Hematologi',
            alternativeLabels: [],
            note: 'Her: blodsykdommer. Brukes for bøker om diagnostisering og behandling av blodsykdommer, bøker om blødersykdom, blodpropper, leukemi, myelom, lymfom, sigdcelleanemi og andre sykdommer som påvirker produksjonen av blod og dets komponenter',
            related: [],
            children: [],
          },
          {
            id: 'MJG',
            label: 'Endokrinologi',
            originalLabel: 'Endokrinologi',
            alternativeLabels: ['det endokrine systemet'],
            note: 'Her: hormonsykdommer. Brukes for bøker om sykdommer i hormonsystemet, fysiologiske eller adferdsmessige aktiviteter som er forårsaket av hormoner',
            related: [],
            children: [
              {
                id: 'MJGD',
                label: 'Diabetes',
                originalLabel: 'Diabetes',
                alternativeLabels: ['MODY'],
                note: 'Brukes for medisinske bøker om diabetes type 1 og 2, svangerskapsdiabetes, monogen diabetes',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MJH',
            label: 'Gastroenterologi',
            originalLabel: 'Gastroenterologi',
            alternativeLabels: ['magesykdommer'],
            note: 'Her: mage- og tarmsykdommer. Brukes for bøker om diagnostisering og behandling av sykdommer i fordøyelsessystemet',
            related: [],
            children: [],
          },
          {
            id: 'MJJ',
            label: 'Hepatologi',
            originalLabel: 'Hepatologi',
            alternativeLabels: ['leversykdommer'],
            note: 'Her: lever- og galleblæresykdommer. Brukes for bøker om diagnostisering og behandling av sykdommer i leveren, galleblæren, bukspyttkjertelen',
            related: [],
            children: [],
          },
          {
            id: 'MJK',
            label: 'Dermatologi',
            originalLabel: 'Dermatologi',
            alternativeLabels: [],
            note: 'Her: hudsykdommer. Brukes for bøker om tilstander som har med huden, håret eller neglene å gjøre, bøker som handler om hudsykdommer, bøker for dermatologer',
            related: [],
            children: [],
          },
          {
            id: 'MJL',
            label: 'Lungemedisin',
            originalLabel: 'Lungemedisin',
            alternativeLabels: ['lungesykdommer'],
            note: 'Her: lunge- og luftveissykdommer, pulmonologi',
            related: [],
            children: [],
          },
          {
            id: 'MJM',
            label: 'Revmatologi',
            originalLabel: 'Revmatologi',
            alternativeLabels: ['revmatisme'],
            note: 'Her: revmatiske sykdommer. Bøker om diagnostisering og behandling av revmatiske sykdommer, sykdommer i leddene som leddgikt av ulike typer, bindevevssykdommer',
            related: [],
            children: [],
          },
          {
            id: 'MJP',
            label: 'Øre-nese-hals',
            originalLabel: 'Øre-nese-hals',
            alternativeLabels: [],
            note: 'Her: øre-, nese- og halssykdommer, otorinolaryngologi. Brukes med MN*-koder for bøker om kirurgiske teknikker',
            related: [],
            children: [
              {
                id: 'MJPD',
                label: 'Audiologi og otologi',
                originalLabel: 'Audiologi og otologi',
                alternativeLabels: [],
                note: 'Her: øresykdommer og hørsel, neurotologi. Brukes for bøker om hørsel, balanse og tilstander og sykdommer som forbindes med dette, ørets anatomi, det indre øret, diagnostisering og behandling av sykdommer',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MJQ',
            label: 'Øyet og øyesykdommer',
            originalLabel: 'Øyet og øyesykdommer',
            alternativeLabels: [],
            note: 'Her: oftalmologi, øyekirurgi. Brukes for bøker om øyesykdommer og diagnostisering og behandling av disse, bøker for oftalmologer. Brukes med MN*-koder for bøker om kirurgiske teknikker',
            related: [],
            children: [],
          },
          {
            id: 'MJR',
            label: 'Nyrene og nyresykdommer',
            originalLabel: 'Nyrene og nyresykdommer',
            alternativeLabels: [],
            note: 'Her: nefrologi, bøker for profesjonsmarkedet om nyrer, nyrefunksjoner og diagnostisering og behandling av nyresykdommer, nyrefysiologi',
            related: [],
            children: [
              {
                id: 'MJRD',
                label: 'Hemodialyse',
                originalLabel: 'Hemodialyse',
                alternativeLabels: [],
                note: 'Her: dialysebehandling av blod',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MJS',
            label: 'Urologi',
            originalLabel: 'Urologi',
            alternativeLabels: [],
            note: 'Her: erektil dysfunksjon, bøker for profesjonsmarkedet om urogenitalsystemet, sykdommer i urinveiene både hos menn og kvinner, sykdommer i mannlige kjønnsorgan / de mannlige reproduktive organene',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'MK',
        label: 'Medisinske disipliner',
        originalLabel: 'Medisinske disipliner',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'MKA',
            label: 'Anestesiologi',
            originalLabel: 'Anestesiologi',
            alternativeLabels: [],
            note: 'Her: anestesi',
            related: [],
            children: [
              {
                id: 'MKAL',
                label: 'Smerte og smertebehandling',
                originalLabel: 'Smerte og smertebehandling',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MKB',
            label: 'Palliativ medisin',
            originalLabel: 'Palliativ medisin',
            alternativeLabels: [],
            note: 'Her: lindrende behandling for pasienter med uhelbredelig sykdom',
            related: [],
            children: [],
          },
          {
            id: 'MKC',
            label: 'Obstetrikk og gynekologi',
            originalLabel: 'Obstetrikk og gynekologi',
            alternativeLabels: [],
            note: 'Brukes for bøker for profesjonsmarkedet om det kvinnelige reproduktive systemet, eller bøker om graviditet, fødsel og perioden etter fødselen',
            related: [],
            children: [
              {
                id: 'MKCM',
                label: 'Høyrisikosvangerskap / fostermedisin',
                originalLabel: 'Høyrisikosvangerskap / fostermedisin',
                alternativeLabels: ['perinatologi'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MKD',
            label: 'Pediatri',
            originalLabel: 'Pediatri',
            alternativeLabels: ['barneleger'],
            note: 'Brukes for bøker for pediatere om medisinsk behandling av spebarn, barn, ungdom',
            related: [],
            children: [
              {
                id: 'MKDN',
                label: 'Neonatologi',
                originalLabel: 'Neonatologi',
                alternativeLabels: ['for tidlig fødte'],
                note: 'Her: nyfødtmedisin. Brukes for bøker om medisinsk behandling av nyfødte, syke eller premature nyfødte',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MKE',
            label: 'Odontologi',
            originalLabel: 'Odontologi',
            alternativeLabels: ['tannleger'],
            note: 'Her: forebyggende og kosmetisk tannbehandling, tannhelse. Brukes for bøker om generell og klinisk tannbehandling. Brukes med MQC*-koder for tannpleie, MBPM for administrasjon etc.',
            related: [],
            children: [
              {
                id: 'MKED',
                label: 'Kjeveortopedi',
                originalLabel: 'Kjeveortopedi',
                alternativeLabels: [],
                note: 'Her: tannregulering',
                related: [],
                children: [],
              },
              {
                id: 'MKEH',
                label: 'Odontologiske spesialområder',
                originalLabel: 'Odontologiske spesialområder',
                alternativeLabels: ['tannkjøttsykdommer', 'pedodonti'],
                note: 'Her: tannproteser, endodonti, periodonti, barnetannpleie, narkose i forbindelse med tannbehandling. Se også MKED <a href="/thema/nb/MKED">Kjeveortopedi</a>',
                related: [
                  {
                    id: 'MKED',
                    label: 'Kjeveortopedi',
                    originalLabel: 'Kjeveortopedi',
                    note: 'Her: tannregulering',
                  },
                ],
                children: [],
              },
              {
                id: 'MKEP',
                label: 'Oral- og kjevekirurgi',
                originalLabel: 'Oral- og kjevekirurgi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MKF',
            label: 'Patologi',
            originalLabel: 'Patologi',
            alternativeLabels: [],
            note: 'Brukes for bøker om årsaker til og virkninger av sykdommer eller skader. Brukes med MFG* for patofysiologi, med andre M*-koder for andre spesialområder innen patologi',
            related: [],
            children: [
              {
                id: 'MKFC',
                label: 'Cellepatologi',
                originalLabel: 'Cellepatologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MKFH',
                label: 'Histopatologi',
                originalLabel: 'Histopatologi',
                alternativeLabels: [],
                note: 'Her: biopsi',
                related: [],
                children: [],
              },
              {
                id: 'MKFK',
                label: 'Kronisk sykdom',
                originalLabel: 'Kronisk sykdom',
                alternativeLabels: [],
                note: 'Her: kronisk utmattelsessyndrom',
                related: [],
                children: [],
              },
              {
                id: 'MKFM',
                label: 'Medisinsk mikrobiologi og virologi',
                originalLabel: 'Medisinsk mikrobiologi og virologi',
                alternativeLabels: [],
                note: 'Her: bakteriofager, antibiotika, antivirale midler, medisinsk bakteriologi. Se også MJCJ <a href="/thema/nb/MJCJ">Smittsomme sykdommer</a>',
                related: [
                  {
                    id: 'MJCJ',
                    label: 'Smittsomme sykdommer',
                    originalLabel: 'Smittsomme sykdommer',
                    note: 'Her: overførbare og smittsomme sykdommer, epidemier, virussykdommer, bakterieinfeksjoner, parasitter som forårsaker sykdom, behandling og forebygging. Se også MKFM <a href="/thema/nb/MKFM">Medisinsk mikrobiologi og virologi</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'MKFP',
                label: 'Medisinsk parasittologi',
                originalLabel: 'Medisinsk parasittologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MKFS',
                label: 'Psykosomatikk',
                originalLabel: 'Psykosomatikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MKG',
            label: 'Farmakologi',
            originalLabel: 'Farmakologi',
            alternativeLabels: ['apoteker'],
            note: 'Brukes for bøker om grunnleggende og klinisk farmakologi, eller bøker om farmasøytisk vitenskap, studiet av medisiner og hvordan de virker. Brukes med M* eller P* der det er hensiktsmessig. Se også MQP <a href="/thema/nb/MQP">Farmasi og apotekteknikk</a>',
            related: [
              {
                id: 'MQP',
                label: 'Farmasi og apotekteknikk',
                originalLabel: 'Farmasi og apotekteknikk',
                note: 'Se også MKG <a href="/thema/nb/MKG">Farmakologi</a>',
              },
            ],
            children: [
              {
                id: 'MKGT',
                label: 'Medisinsk toksikologi',
                originalLabel: 'Medisinsk toksikologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MKGW',
                label: 'Psykofarmakologi',
                originalLabel: 'Psykofarmakologi',
                alternativeLabels: ['psykofarmaka'],
                note: 'Brukes for bøker om medisiner som påvirker humør, følelser, tanker, oppførsel og kognisjon',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MKH',
            label: 'Regenerativ medisin',
            originalLabel: 'Regenerativ medisin',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'MKHC',
                label: 'Regenerativ medisin: stamceller',
                originalLabel: 'Regenerativ medisin: stamceller',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MKJ',
            label: 'Nevrologi og klinisk nevrofysiologi',
            originalLabel: 'Nevrologi og klinisk nevrofysiologi',
            alternativeLabels: ['Parkinsons'],
            note: 'Brukes for bøker om nevrologiske og nevrodegenerative sykdommer og lidelser, og sykdommer i nervesystemet',
            related: [],
            children: [
              {
                id: 'MKJA',
                label: 'Autisme og Aspergers syndrom',
                originalLabel: 'Autisme og Aspergers syndrom',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MKJD',
                label: 'Alzheimers sykdom og demens',
                originalLabel: 'Alzheimers sykdom og demens',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MKL',
            label: 'Psykiatri',
            originalLabel: 'Psykiatri',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'MKLD',
                label: 'Psykiske lidelser',
                originalLabel: 'Psykiske lidelser',
                alternativeLabels: [
                  'psykoser',
                  'nevroser',
                  'mental helse. OCD',
                ],
                note: 'Her: posttraumatisk stresslidelse (PTSD), klinisk depresjon, tvangslidelser. Brukes for bøker om diagnoser og årsaker til og behandling av psykiske lidelser, eller bøker om spesifikke lidelser eller tilstander',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MKM',
            label: 'Klinisk psykologi',
            originalLabel: 'Klinisk psykologi',
            alternativeLabels: [],
            note: 'Se også JMP <a href="/thema/nb/JMP">Abnormalpsykologi</a>',
            related: [
              {
                id: 'JMP',
                label: 'Abnormalpsykologi',
                originalLabel: 'Abnormalpsykologi',
                note: 'Her: psykiske lidelser. Se også MKM* <a href="/thema/nb/MKM">Klinisk psykologi</a> og dens underkategorier',
              },
            ],
            children: [
              {
                id: 'MKMT',
                label: 'Psykoterapi',
                originalLabel: 'Psykoterapi',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'MKMT1',
                    label: 'Psykoterapi: generell',
                    originalLabel: 'Psykoterapi: generell',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'MKMT2',
                    label: 'Psykoterapi: grupper',
                    originalLabel: 'Psykoterapi: grupper',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'MKMT3',
                    label: 'Psykoterapi: barn og ungdom',
                    originalLabel: 'Psykoterapi: barn og ungdom',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'MKMT4',
                    label: 'Psykoterapi: par og familier',
                    originalLabel: 'Psykoterapi: par og familier',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'MKMT5',
                    label: 'Psykoterapi: rådgivning',
                    originalLabel: 'Psykoterapi: rådgivning',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'MKMT6',
                    label: 'Kognitiv adferdsterapi',
                    originalLabel: 'Kognitiv adferdsterapi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'MKN',
            label: 'Geriatri',
            originalLabel: 'Geriatri',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MKP',
            label: 'Akuttmedisin og førstehjelp',
            originalLabel: 'Akuttmedisin og førstehjelp',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'MKPB',
                label: 'Traumer og sjokk',
                originalLabel: 'Traumer og sjokk',
                alternativeLabels: [],
                note: 'Her: traumatologi, medisinske traumer. Brukes for bøker om diagnostisering, behandling og håndtering av sjokk og alvorlige fysiske skader i akuttmedisin. Brukes med MKL*, MKMT*, MKZ* eller JM* for bøker om fysiologisk traumatologi der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'MKPD',
                label: 'Brannskader',
                originalLabel: 'Brannskader',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MKPL',
                label: 'Intensivmedisin',
                originalLabel: 'Intensivmedisin',
                alternativeLabels: [],
                note: 'Her: behandling og overvåking av pasienter med akutt svikt i vitale kroppsfunksjoner',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MKR',
            label: 'Nukleærmedisin',
            originalLabel: 'Nukleærmedisin',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MKS',
            label: 'Medisinsk avbildning',
            originalLabel: 'Medisinsk avbildning',
            alternativeLabels: [],
            note: 'Se også TTBM <a href="/thema/nb/TTBM">Bildebehandling: systemer og teknologi</a>',
            related: [
              {
                id: 'TTBM',
                label: 'Bildebehandling: systemer og teknologi',
                originalLabel: 'Bildebehandling: systemer og teknologi',
                note: 'Se også MKS* <a href="/thema/nb/MKS">Medisinsk avbildning</a> og dens underkategorier',
              },
            ],
            children: [
              {
                id: 'MKSF',
                label: 'Ultralyd',
                originalLabel: 'Ultralyd',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MKSG',
                label: 'Magnetresonans (NMR / MRI)',
                originalLabel: 'Magnetresonans (NMR / MRI)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MKSH',
                label: 'Radiologi',
                originalLabel: 'Radiologi',
                alternativeLabels: ['røntgen'],

                related: [
                  {
                    id: 'MQH',
                    label: 'Radiografi',
                    originalLabel: 'Radiografi',
                  },
                ],
                children: [],
              },
              {
                id: 'MKSJ',
                label: 'Tomografi',
                originalLabel: 'Tomografi',
                alternativeLabels: ['CT'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MKT',
            label: 'Rettsmedisin',
            originalLabel: 'Rettsmedisin',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MKV',
            label: 'Miljømedisin',
            originalLabel: 'Miljømedisin',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'MKVB',
                label: 'Flymedisin og romfartsmedisin',
                originalLabel: 'Flymedisin og romfartsmedisin',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MKVD',
                label: 'Dykkemedisin og hyperbar behandling',
                originalLabel: 'Dykkemedisin og hyperbar behandling',
                alternativeLabels: [],
                note: 'Her: overtrykksbehandling',
                related: [],
                children: [],
              },
              {
                id: 'MKVP',
                label: 'Arbeidsmedisin',
                originalLabel: 'Arbeidsmedisin',
                alternativeLabels: [],
                note: 'Se også KNX <a href="/thema/nb/KNX">Arbeidsforhold, helse og sikkerhet</a>',
                related: [
                  {
                    id: 'KNX',
                    label: 'Arbeidsforhold, helse og sikkerhet',
                    originalLabel: 'Arbeidsforhold, helse og sikkerhet',
                    note: 'Her: fagforeninger, streik, meklinger, arbeidsmiljø, HMS etc. Brukes for alle typer arbeidsplasser, ikke spesifikt produksjon og tungindustri',
                  },
                ],
                children: [],
              },
              {
                id: 'MKVQ',
                label: 'Reisemedisin',
                originalLabel: 'Reisemedisin',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MKVT',
                label: 'Tropemedisin',
                originalLabel: 'Tropemedisin',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MKW',
            label: 'Idrettsskader og idrettsmedisin',
            originalLabel: 'Idrettsskader og idrettsmedisin',
            alternativeLabels: [],
            note: 'Brukes for bøker innen medisin om fysisk form, behandling og forebygging av skader knyttet til idrettsutøvere, sport og trening. Se også SCGF <a href="/thema/nb/SCGF">Idrettsvitenskap, fysisk fostring</a>',
            related: [
              {
                id: 'SCGF',
                label: 'Idrettsvitenskap, fysisk fostring',
                originalLabel: 'Idrettsvitenskap, fysisk fostring',
                note: 'Her: treningsfysiologi eller kinesologi for profesjonelle idrettsutøvere. Se også MFGV <a href="/thema/nb/MFGV">Biomekanikk</a>',
              },
            ],
            children: [],
          },
          {
            id: 'MKZ',
            label: 'Terapi og legemidler',
            originalLabel: 'Terapi og legemidler',
            alternativeLabels: [],
            note: 'Se også MJCL1 <a href="/thema/nb/MJCL1">Strålebehandling</a>, MJCL2 <a href="/thema/nb/MJCL2">Cellegiftbehandling</a>, MKMT* <a href="/thema/nb/MKMT">Psykoterapi</a> og dens underkategorier, MQS <a href="/thema/nb/MQS">Fysioterapi</a>, MQT* <a href="/thema/nb/MQT">Ergoterapi</a> og dens underkategorier',
            related: [
              {
                id: 'MJCL1',
                label: 'Strålebehandling',
                originalLabel: 'Strålebehandling',
              },
              {
                id: 'MJCL2',
                label: 'Cellegiftbehandling',
                originalLabel: 'Cellegiftbehandling',
              },
              {
                id: 'MKMT',
                label: 'Psykoterapi',
                originalLabel: 'Psykoterapi',
              },
              {
                id: 'MQS',
                label: 'Fysioterapi',
                originalLabel: 'Fysioterapi',
                note: 'Her: fysikalsk behandling',
              },
              {
                id: 'MQT',
                label: 'Ergoterapi',
                originalLabel: 'Ergoterapi',
                note: 'Her: hageterapi, terapeutisk hagebruk, naturassistert terapi',
              },
            ],
            children: [
              {
                id: 'MKZD',
                label: 'Spiseforstyrrelser og terapi',
                originalLabel: 'Spiseforstyrrelser og terapi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MKZF',
                label: 'Overvekt: behandling og terapi',
                originalLabel: 'Overvekt: behandling og terapi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MKZL',
                label: 'Språkvansker: terapi',
                originalLabel: 'Språkvansker: terapi',
                alternativeLabels: ['logoped'],
                note: 'Her: logopedi, alle terapiformer som har med tale og språk å gjøre',
                related: [],
                children: [],
              },
              {
                id: 'MKZR',
                label: 'Avhengighet og terapi',
                originalLabel: 'Avhengighet og terapi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MKZS',
                label: 'Søvnforstyrrelser og terapi',
                originalLabel: 'Søvnforstyrrelser og terapi',
                alternativeLabels: ['søvnforstyrrelser'],
                note: 'Her: dyssomni, søvnløshet og -vansker, søvnapné',
                related: [],
                children: [],
              },
              {
                id: 'MKZV',
                label: 'Genterapi',
                originalLabel: 'Genterapi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'MN',
        label: 'Kirurgi',
        originalLabel: 'Kirurgi',
        alternativeLabels: [],
        note: 'Brukes med andre koder fra M* for kirurgiske spesialområder som ikke har en egen MN*-kode, f.eks. med MJQ for øyekirurgi, MJP for øre-nese-hals-kirurgi, MKD for pediatrisk kirurgi etc. Se også MKEP <a href="/thema/nb/MKEP">Oral- og kjevekirurgi</a>',
        related: [
          {
            id: 'MKEP',
            label: 'Oral- og kjevekirurgi',
            originalLabel: 'Oral- og kjevekirurgi',
          },
        ],
        children: [
          {
            id: 'MNB',
            label: 'Kirurgiske teknikker',
            originalLabel: 'Kirurgiske teknikker',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MNC',
            label: 'Generell kirurgi',
            originalLabel: 'Generell kirurgi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MND',
            label: 'Abdominalkirurgi',
            originalLabel: 'Abdominalkirurgi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MNG',
            label: 'Gastroenterologisk- og kolorektal kirurgi',
            originalLabel: 'Gastroenterologisk- og kolorektal kirurgi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MNH',
            label: 'Hjertekirurgi',
            originalLabel: 'Hjertekirurgi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MNJ',
            label: 'Karkirurgi',
            originalLabel: 'Karkirurgi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MNK',
            label: 'Kirurgisk onkologi',
            originalLabel: 'Kirurgisk onkologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MNL',
            label: 'Akuttkirurgi',
            originalLabel: 'Akuttkirurgi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MNN',
            label: 'Nevrokirurgi',
            originalLabel: 'Nevrokirurgi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MNP',
            label: 'Rekonstruksjonskirurgi og plastisk kirurgi',
            originalLabel: 'Rekonstruksjonskirurgi og plastisk kirurgi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'MNPC',
                label: 'Kosmetisk kirurgi',
                originalLabel: 'Kosmetisk kirurgi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MNQ',
            label: 'Transplantasjonskirurgi',
            originalLabel: 'Transplantasjonskirurgi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MNS',
            label: 'Ortopedisk kirurgi og beinbrudd',
            originalLabel: 'Ortopedisk kirurgi og beinbrudd',
            alternativeLabels: [],
            note: 'Se også MJE <a href="/thema/nb/MJE">Muskel- og skjelettsykdommer</a>',
            related: [
              {
                id: 'MJE',
                label: 'Muskel- og skjelettsykdommer',
                originalLabel: 'Muskel- og skjelettsykdommer',
                note: 'Brukes for bøker om diagnostisering og behandling av muskel- og skjelettsykdommer, inkludert skader og sykdommer i musklene, skjelettet og leddene. Se også MNS <a href="/thema/nb/MNS">Ortopedisk kirurgi og beinbrudd</a>',
              },
            ],
            children: [],
          },
          {
            id: 'MNZ',
            label: 'Perioperativt tilsyn',
            originalLabel: 'Perioperativt tilsyn',
            alternativeLabels: [],
            note: 'Se også MQCL6 <a href="/thema/nb/MQCL6">Kirurgisk sykepleie</a>',
            related: [
              {
                id: 'MQCL6',
                label: 'Kirurgisk sykepleie',
                originalLabel: 'Kirurgisk sykepleie',
                note: 'Her: operasjonssykepleie. Se også MNZ <a href="/thema/nb/MNZ">Perioperativt tilsyn</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'MQ',
        label: 'Sykepleie og støttefunksjoner',
        originalLabel: 'Sykepleie og støttefunksjoner',
        alternativeLabels: [],
        note: 'Brukes for bøker for profesjonsmarkedet',
        related: [],
        children: [
          {
            id: 'MQC',
            label: 'Sykepleie',
            originalLabel: 'Sykepleie',
            alternativeLabels: ['helsesykepleier'],
            note: 'Brukes med andre koder fra M* for å angi spesialiserte områder og emner innen sykepleie, når de ikke har en egen MQCL*-kode',
            related: [],
            children: [
              {
                id: 'MQCA',
                label: 'Grunnleggende sykepleie',
                originalLabel: 'Grunnleggende sykepleie',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MQCB',
                label: 'Sykepleieforskning- og teori',
                originalLabel: 'Sykepleieforskning- og teori',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MQCH',
                label: 'Sykepleier-pasient-forhold',
                originalLabel: 'Sykepleier-pasient-forhold',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MQCL',
                label: 'Spesialiseringer innen sykepleie',
                originalLabel: 'Spesialiseringer innen sykepleie',
                alternativeLabels: [],
                note: 'Brukes med M* eller JK* for å angi spesialiseringsområdet når det ikke fins en mer presis MQCL*-kode',
                related: [],
                children: [
                  {
                    id: 'MQCL1',
                    label: 'Akuttsykepleie',
                    originalLabel: 'Akuttsykepleie',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'MQCL2',
                    label: 'Intensivsykepleie',
                    originalLabel: 'Intensivsykepleie',
                    alternativeLabels: [],
                    note: 'Class here: critical care nursing',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'MQCL3',
                    label: 'Pediatrisk sykepleie',
                    originalLabel: 'Pediatrisk sykepleie',
                    alternativeLabels: [],
                    note: 'Her: barnepleie',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'MQCL4',
                    label: 'Geriatrisk sykepleie',
                    originalLabel: 'Geriatrisk sykepleie',
                    alternativeLabels: [],
                    note: 'Her: gerontologisk sykepleie, pleie av eldre',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'MQCL5',
                    label: 'Psykiatrisk sykepleie / Psykisk helsevern',
                    originalLabel: 'Psykiatrisk sykepleie / Psykisk helsevern',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'MQCL6',
                    label: 'Kirurgisk sykepleie',
                    originalLabel: 'Kirurgisk sykepleie',
                    alternativeLabels: [],
                    note: 'Her: operasjonssykepleie. Se også MNZ <a href="/thema/nb/MNZ">Perioperativt tilsyn</a>',
                    related: [
                      {
                        id: 'MNZ',
                        label: 'Perioperativt tilsyn',
                        originalLabel: 'Perioperativt tilsyn',
                        note: 'Se også MQCL6 <a href="/thema/nb/MQCL6">Kirurgisk sykepleie</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'MQCL7',
                    label: 'Avansert klinisk sykepleie',
                    originalLabel: 'Avansert klinisk sykepleie',
                    alternativeLabels: [
                      'avansert klinisk sykepleier',
                      'klinisk ekspertsykepleier',
                      'nurse practitioner',
                    ],
                    note: 'Her: bøker om spesialområder og roller innen avansert klinisk sykepleie. Brukes med M* for avansert sykepleie innen spesielle områder',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'MQCL8',
                    label: 'Spesialsykepleie',
                    originalLabel: 'Spesialsykepleie',
                    alternativeLabels: ['spesialsykepleier'],
                    note: 'Her: sykepleie for personer med psykiske funksjonsnedsettelser. Brukes for bøker om ferdigheter og kunnskap som kreves innen pleie av pasienter med lærevansker eller funksjonsnedsettelser',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'MQCL9',
                    label: 'Terminalpleie',
                    originalLabel: 'Terminalpleie',
                    alternativeLabels: [],
                    note: 'Her: pleie av døende, palliativ sykepleie',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'MQCM',
                label: 'Farmakologi for sykepleiere',
                originalLabel: 'Farmakologi for sykepleiere',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MQCW',
                label: 'Sosiologi for sykepleiere',
                originalLabel: 'Sosiologi for sykepleiere',
                alternativeLabels: [],
                note: 'Brukes for bøker som handler om praktisk bruk og anvendelse av sosiologi innen sykepleie. Se også MBS <a href="/thema/nb/MBS">Helsesosiologi</a>',
                related: [
                  {
                    id: 'MBS',
                    label: 'Helsesosiologi',
                    originalLabel: 'Helsesosiologi',
                    note: 'Brukes bor bøker som handler om praktisk bruk og anvendelse av sosiologi innen medisin og helsevesen, eller bøker som studerer årsaker til og konsekvenser av god eller dårlig helse',
                  },
                ],
                children: [],
              },
              {
                id: 'MQCX',
                label: 'Hjemmesykepleie',
                originalLabel: 'Hjemmesykepleie',
                alternativeLabels: [],
                note: 'Her: sykepleietjenester som utføres utenfor sykehuset',
                related: [],
                children: [],
              },
              {
                id: 'MQCZ',
                label: 'Sykepleie: administrasjon og ledelse',
                originalLabel: 'Sykepleie: administrasjon og ledelse',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MQD',
            label: 'Jordmorfaget',
            originalLabel: 'Jordmorfaget',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'MQDB',
                label: 'Fødselsmetoder',
                originalLabel: 'Fødselsmetoder',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MQF',
            label: 'Førstehjelp og ambulansetjenester',
            originalLabel: 'Førstehjelp og ambulansetjenester',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MQG',
            label: 'Helsesekretærer',
            originalLabel: 'Helsesekretærer',
            alternativeLabels: ['legekontor'],
            note: 'Brukes for kvalifisert helsepersonell som er utdannet for å bistå leger og annet medisinsk personell. Brukes med andre M*-koder for å angi medisinske spesialområder',
            related: [],
            children: [],
          },
          {
            id: 'MQH',
            label: 'Radiografi',
            originalLabel: 'Radiografi',
            alternativeLabels: [],

            related: [
              {
                id: 'MKSH',
                label: 'Radiologi',
                originalLabel: 'Radiologi',
              },
            ],
            children: [],
          },
          {
            id: 'MQK',
            label: 'Fotpleie, fotterapi og podiatri',
            originalLabel: 'Fotpleie, fotterapi og podiatri',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MQP',
            label: 'Farmasi og apotekteknikk',
            originalLabel: 'Farmasi og apotekteknikk',
            alternativeLabels: [],
            note: 'Se også MKG <a href="/thema/nb/MKG">Farmakologi</a>',
            related: [
              {
                id: 'MKG',
                label: 'Farmakologi',
                originalLabel: 'Farmakologi',
                note: 'Brukes for bøker om grunnleggende og klinisk farmakologi, eller bøker om farmasøytisk vitenskap, studiet av medisiner og hvordan de virker. Brukes med M* eller P* der det er hensiktsmessig. Se også MQP <a href="/thema/nb/MQP">Farmasi og apotekteknikk</a>',
              },
            ],
            children: [],
          },
          {
            id: 'MQR',
            label: 'Optometri / optikere',
            originalLabel: 'Optometri / optikere',
            alternativeLabels: ['oftamologi'],
            note: 'Her: læren om syn og synsforhold',
            related: [],
            children: [],
          },
          {
            id: 'MQS',
            label: 'Fysioterapi',
            originalLabel: 'Fysioterapi',
            alternativeLabels: [],
            note: 'Her: fysikalsk behandling',
            related: [],
            children: [],
          },
          {
            id: 'MQT',
            label: 'Ergoterapi',
            originalLabel: 'Ergoterapi',
            alternativeLabels: [],
            note: 'Her: hageterapi, terapeutisk hagebruk, naturassistert terapi',
            related: [],
            children: [
              {
                id: 'MQTC',
                label: 'Kunst- og uttrykksterapi',
                originalLabel: 'Kunst- og uttrykksterapi',
                alternativeLabels: ['kreativ kunstterapi'],
                note: 'Her: kunstterapi, musikkterapi, dramaterapi, danseterapi, bevegelsesterapi, biblioterapi, skriveterapi, leketerapi',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MQU',
            label: 'Medisinsk veiledning',
            originalLabel: 'Medisinsk veiledning',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MQV',
            label: 'Rehabilitering',
            originalLabel: 'Rehabilitering',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'MQVB',
                label: 'Rehabilitering: hjerne- og ryggmargsskader',
                originalLabel: 'Rehabilitering: hjerne- og ryggmargsskader',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MQW',
            label: 'Biomedisinsk teknikk',
            originalLabel: 'Biomedisinsk teknikk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'MQWB',
                label: 'Ortopedi',
                originalLabel: 'Ortopedi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MQWP',
                label: 'Proteseteknikk',
                originalLabel: 'Proteseteknikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MQZ',
            label: 'Likhuspraksis',
            originalLabel: 'Likhuspraksis',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'MR',
        label:
          'Medisinske studieveiledninger, repetisjonsguider og referansemateriell',
        originalLabel:
          'Medisinske studieveiledninger, repetisjonsguider og referansemateriell',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'MRG',
            label:
              'Medisinske repetisjonsguider, guider til eksamensforberedelser og studieveiledninger',
            originalLabel:
              'Medisinske repetisjonsguider, guider til eksamensforberedelser og studieveiledninger',
            alternativeLabels: [],
            note: 'Brukes med kvalifikatorer for geografi eller pedagogisk formål for medisinske eksamener, nivåer og kvalifisering der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'MRGD',
                label: 'Medisinske repetisjonshjelpemidler: MRCP',
                originalLabel: 'Medisinske repetisjonshjelpemidler: MRCP',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MRGK',
                label: 'Medisinske repetisjonshjelpemidler: kirurgi',
                originalLabel: 'Medisinske repetisjonshjelpemidler: kirurgi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MRGL',
                label:
                  'Medisinske repetisjonshjelpemidler: tester for utenlandske leger',
                originalLabel:
                  'Medisinske repetisjonshjelpemidler: tester for utenlandske leger',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MRP',
            label: 'Medisinske / kliniske retningslinjer',
            originalLabel: 'Medisinske / kliniske retningslinjer',
            alternativeLabels: [],
            note: 'Brukes med relevant kategori for medisinske spesialområder',
            related: [],
            children: [],
          },
          {
            id: 'MRT',
            label: 'Medisinske plansjer og atlas',
            originalLabel: 'Medisinske plansjer og atlas',
            alternativeLabels: [],
            note: 'Her: anatomiske plansjer, atlas og plakater',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'MX',
        label: 'Alternativ medisin / terapi',
        originalLabel: 'Alternativ medisin / terapi',
        alternativeLabels: [],
        note: 'Bruk MX*-koder for bøker om alternativ eller tradisjonell medisin for medisinstudenter og helsepersonell, som bøker om tradisjonell kinesisk og ayurvedisk medisin for profesjonsmarkedet, naturmedisin, fytoterapi, akupunktur, moxibusjon. Brukes med VXH* koder som sekundærkoder for å angi områder innen alternativ medisin der det er hensiktsmessig dersom innholdet er ment for profesjonsmarkedet eller studenter. Se også VXH* <a href="/thema/nb/VXH">Alternative behandlingsmåter, healing og helse</a> og dens underkategorier',
        related: [
          {
            id: 'VXH',
            label: 'Alternative behandlingsmåter, healing og helse',
            originalLabel: 'Alternative behandlingsmåter, healing og helse',
            note: 'Her: kopping, lymfeterapi og andre alternative behandlingsmåter. Brukes for behandlingsmåter og behandlere, inkludert healere, naturmedisinere. Bruk VXH*-koder med MX dersom innholdet er beregnet på medisinsk fagpersonell, studenter, behandlere. Se også MX* <a href="/thema/nb/MX">Alternativ medisin / terapi</a> og dens underkategorier',
          },
        ],
        children: [
          {
            id: 'MXH',
            label: 'Kiropraktikk og osteopati',
            originalLabel: 'Kiropraktikk og osteopati',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MXN',
            label: 'Dyreassistert terapi',
            originalLabel: 'Dyreassistert terapi',
            alternativeLabels: [],
            note: 'Her: terapi med hester eller hunder, terapeutisk ridning',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'MZ',
        label: 'Veterinærmedisin / dyremedisin',
        originalLabel: 'Veterinærmedisin / dyremedisin',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'MZA',
            label: 'Veterinærmedisin',
            originalLabel: 'Veterinærmedisin',
            alternativeLabels: [],
            note: 'Her: generelle emner innen veterinærmedisin',
            related: [],
            children: [
              {
                id: 'MZAB',
                label: 'Veterinærmedisin: forskning',
                originalLabel: 'Veterinærmedisin: forskning',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'MZAD',
                label: 'Veterinærmedisin: referanse og statistikk',
                originalLabel: 'Veterinærmedisin: referanse og statistikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MZB',
            label: 'Preklinisk veterinærmedisin: basalfag',
            originalLabel: 'Preklinisk veterinærmedisin: basalfag',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MZC',
            label: 'Veterinærmedisin: kjæledyr',
            originalLabel: 'Veterinærmedisin: kjæledyr',
            alternativeLabels: [],
            note: 'Her: veterinærmedisin for små dyr som hunder, katter og fugler. Se også MZG <a href="/thema/nb/MZG">Veterinærmedisin: eksotiske dyr</a>',
            related: [
              {
                id: 'MZG',
                label: 'Veterinærmedisin: eksotiske dyr',
                originalLabel: 'Veterinærmedisin: eksotiske dyr',
              },
            ],
            children: [],
          },
          {
            id: 'MZD',
            label: 'Veterinærmedisin: gårdsdyr',
            originalLabel: 'Veterinærmedisin: gårdsdyr',
            alternativeLabels: [],
            note: 'Her: veterinærmedisin for husdyr og gårdsdyr. Se også MZG <a href="/thema/nb/MZG">Veterinærmedisin: eksotiske dyr</a>',
            related: [
              {
                id: 'MZG',
                label: 'Veterinærmedisin: eksotiske dyr',
                originalLabel: 'Veterinærmedisin: eksotiske dyr',
              },
            ],
            children: [
              {
                id: 'MZDH',
                label: 'Veterinærmedisin: hester',
                originalLabel: 'Veterinærmedisin: hester',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MZF',
            label: 'Veterinærmedisin: laboratoriedyr',
            originalLabel: 'Veterinærmedisin: laboratoriedyr',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MZG',
            label: 'Veterinærmedisin: eksotiske dyr',
            originalLabel: 'Veterinærmedisin: eksotiske dyr',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MZH',
            label: 'Veterinærmedisin: anatomi og fysiologi',
            originalLabel: 'Veterinærmedisin: anatomi og fysiologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MZK',
            label: 'Veterinærmedisin: patologi og histologi',
            originalLabel: 'Veterinærmedisin: patologi og histologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MZL',
            label: 'Veterinærmedisin: ernæring',
            originalLabel: 'Veterinærmedisin: ernæring',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MZM',
            label: 'Veterinærmedisin: sykdommer og behandling',
            originalLabel: 'Veterinærmedisin: sykdommer og behandling',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'MZMP',
                label:
                  'Veterinærmedisin: bakteriologi, virologi og parasittologi',
                originalLabel:
                  'Veterinærmedisin: bakteriologi, virologi og parasittologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MZP',
            label: 'Veterinærmedisin: farmakologi',
            originalLabel: 'Veterinærmedisin: farmakologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MZR',
            label: 'Veterinærmedisin: radiologi',
            originalLabel: 'Veterinærmedisin: radiologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MZS',
            label: 'Veterinærmedisin: kirurgi',
            originalLabel: 'Veterinærmedisin: kirurgi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'MZSN',
                label: 'Veterinærmedisin: anestesiologi',
                originalLabel: 'Veterinærmedisin: anestesiologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'MZT',
            label: 'Veterinærmedisin: tannbehandling',
            originalLabel: 'Veterinærmedisin: tannbehandling',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MZV',
            label: 'Veterinærmedisin: sykepleie',
            originalLabel: 'Veterinærmedisin: sykepleie',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'MZX',
            label: 'Alternativ medisin for dyr',
            originalLabel: 'Alternativ medisin for dyr',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 'N',
    label: 'Historie og arkeologi',
    originalLabel: 'Historie og arkeologi',
    alternativeLabels: [],
    note: 'Bruk N*-koder for utgivelser både for profesjons- og allmennmarkedet. IKKE bruk koden ‘N’, men velg spesifikke kategorier fra N*. Bruk N*-koder med andre emnekategorier og kvalifikatorer der det er hensiktsmessig, særlig kvalifikatorer for sted og tid',
    related: [],
    children: [
      {
        id: 'NH',
        label: 'Historie',
        originalLabel: 'Historie',
        alternativeLabels: [],
        note: 'Brukes for historisk behandling av emner, steder etc. Bruk NH*-koder med andre emnekoder for høyere detaljgrad og kvalifikatorer for geografi og/eller tidsperiode der det er hensiktsmessig. Foretrekk kategorier for historie innen et emne der disse fins, f.eks. PDX Vitenskapshistorie, QRAX Religionshistorie, KCZ Økonomisk historie',
        related: [],
        children: [
          {
            id: 'NHA',
            label: 'Historie: teori og metoder',
            originalLabel: 'Historie: teori og metoder',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'NHAH',
                label: 'Historiografi',
                originalLabel: 'Historiografi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'NHAP',
                label: 'Historisk forskning: kildemateriale',
                originalLabel: 'Historisk forskning: kildemateriale',
                alternativeLabels: [],
                note: 'Brukes for bøker om emnet, men også historiske dokumenter som gis ut på nytt, ofte som annoterte versjoner av originalteksten eller som moderniserte oversettelser. Se også CFL <a href="/thema/nb/CFL">Paleografi (skrifthistorie)</a>',
                related: [
                  {
                    id: 'CFL',
                    label: 'Paleografi (skrifthistorie)',
                    originalLabel: 'Paleografi (skrifthistorie)',
                    note: 'Her: læren om gamle skriftarter og hvordan de kan forstås',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'NHB',
            label: 'Generell historie og verdenshistorie',
            originalLabel: 'Generell historie og verdenshistorie',
            alternativeLabels: [],
            note: 'Her: leksikon, ordbøker eller tidslinjer om verdenshistorie. Brukes for verk som tar for seg historie på en overordnet måte, og som ikke passer i en av de mer presise emnekategoriene for historie. For generell historie og verdenshistorie knyttet til et spesifikt emne, bruk NHB med en passende emnekode, men foretrekk historiske koder innen det enkelte emne der slike fins',
            related: [],
            children: [],
          },
          {
            id: 'NHC',
            label: 'Oldtidshistorie',
            originalLabel: 'Oldtidshistorie',
            alternativeLabels: [],
            note: 'Brukes for bøker om oldtidens historie slik denne oppfattes i den enkelte region. Brukes med andre NH*-koder og relevante kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'NHD',
            label: 'Europeisk historie',
            originalLabel: 'Europeisk historie',
            alternativeLabels: [],
            note: 'Tildel også relevante kvalifikatorer for geografi og tidsperiode',
            related: [],
            children: [
              {
                id: 'NHDA',
                label: 'Europeisk historie: romerne',
                originalLabel: 'Europeisk historie: romerne',
                alternativeLabels: [],
                note: 'Tildel også relevante kvalifikatorer for geografi og tidsperiode',
                related: [],
                children: [],
              },
              {
                id: 'NHDC',
                label: 'Europeisk historie: kelterne',
                originalLabel: 'Europeisk historie: kelterne',
                alternativeLabels: [],
                note: 'Tildel også relevante kvalifikatorer for geografi og tidsperiode',
                related: [],
                children: [],
              },
              {
                id: 'NHDE',
                label: 'Europeisk historie: vikingene',
                originalLabel: 'Europeisk historie: vikingene',
                alternativeLabels: ['vikingtiden', 'vikingtida'],
                note: 'Tildel også relevante kvalifikatorer for geografi og tidsperiode',
                related: [],
                children: [],
              },
              {
                id: 'NHDG',
                label: 'Europeisk historie: normannerne',
                originalLabel: 'Europeisk historie: normannerne',
                alternativeLabels: [],
                note: 'Tildel også relevante kvalifikatorer for geografi og tidsperiode',
                related: [],
                children: [],
              },
              {
                id: 'NHDJ',
                label: 'Europeisk historie: middelalderen',
                originalLabel: 'Europeisk historie: middelalderen',
                alternativeLabels: [],
                note: 'Tildel også relevante kvalifikatorer for geografi og tidsperiode',
                related: [],
                children: [],
              },
              {
                id: 'NHDL',
                label: 'Europeisk historie: renessansen',
                originalLabel: 'Europeisk historie: renessansen',
                alternativeLabels: [],
                note: 'Tildel også relevante kvalifikatorer for geografi og tidsperiode',
                related: [],
                children: [],
              },
              {
                id: 'NHDN',
                label: 'Europeisk historie: reformasjonen',
                originalLabel: 'Europeisk historie: reformasjonen',
                alternativeLabels: [],
                note: 'Tildel også relevante kvalifikatorer for geografi og tidsperiode',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'NHF',
            label: 'Asiatisk historie',
            originalLabel: 'Asiatisk historie',
            alternativeLabels: [],
            note: 'Tildel også relevante kvalifikatorer for geografi fra 1F* eller 1QB* og tidsperiode fra 3* der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'NHG',
            label: 'Midtøstens historie',
            originalLabel: 'Midtøstens historie',
            alternativeLabels: [],
            note: 'Tildel også relevante kvalifikatorer for geografi fra 1FB* eller 1QB* og tidsperiode fra 3* der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'NHH',
            label: 'Afrikansk historie',
            originalLabel: 'Afrikansk historie',
            alternativeLabels: [],
            note: 'Tildel også relevante kvalifikatorer for geografi fra 1H* eller 1QB* og tidsperiode fra 3* der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'NHHA',
                label: 'Afrikansk historie: førkolonial tid',
                originalLabel: 'Afrikansk historie: førkolonial tid',
                alternativeLabels: [],
                note: 'Tildel også relevante kvalifikatorer for geografi fra 1H* eller 1QB* og tidsperiode fra 3* der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'NHK',
            label: 'Amerikansk historie',
            originalLabel: 'Amerikansk historie',
            alternativeLabels: [],
            note: 'Tildel også relevante kvalifikatorer for geografi fra 1K* eller 1QB* og tidsperiode fra 3* der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'NHKA',
                label: 'Amerikansk historie: førkolumbisk tid',
                originalLabel: 'Amerikansk historie: førkolumbisk tid',
                alternativeLabels: [],
                note: 'Brukes for historiske bøker om urfolk eller kulturer i Amerika før europeisk erobring, kolonisering og påvirkning. Tildel også relevante kvalifikatorer for geografi fra 1K* eller 1QB* og tidsperiode fra 3* der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'NHM',
            label: 'Australasiatisk historie og stillehavshistorie',
            originalLabel: 'Australasiatisk historie og stillehavshistorie',
            alternativeLabels: [],
            note: 'Her Oceania, Melanesia, Mikronesia, Polynesia etc. Tildel også relevante kvalifikatorer for geografi fra 1M* eller 1QB* og tidsperiode fra 3* der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'NHQ',
            label: 'Andre regioners og lands historie',
            originalLabel: 'Andre regioners og lands historie',
            alternativeLabels: [],
            note: 'Her: Antarktis, polarregionene, isolerte stillehavsøyer etc. Brukes for bøker om områder som ikke er dekket av andre NH*-koder eller utgivelser som dekker transkontinental historie eller historie for flere lokasjoner. Brukes med relevante kvalifikatorer for geografi fra 1Q* og tidsperiode fra 3* der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'NHT',
            label: 'Historie: spesielle hendelser og emner',
            originalLabel: 'Historie: spesielle hendelser og emner',
            alternativeLabels: [],
            note: 'Bruk alle NHT*-koder med regionale NH*-koder der det er mulig, og andre emnekoder for større detaljgrad, og også kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'NHTB',
                label: 'Sosial- og kulturhistorie',
                originalLabel: 'Sosial- og kulturhistorie',
                alternativeLabels: [],
                note: 'Brukes med andre emnekoder, spesielt J*-koder, f.eks. med JHBL for arbeiderhistorie, eller JBSF1 for kvinnehistorie',
                related: [],
                children: [],
              },
              {
                id: 'NHTD',
                label: 'Muntlig historie',
                originalLabel: 'Muntlig historie',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'NHTF',
                label: 'Historie: pest, sykdommer, hungersnød',
                originalLabel: 'Historie: pest, sykdommer, hungersnød',
                alternativeLabels: [
                  'byllepest',
                  'den justinianske pesten',
                  'cocoliztli',
                  'covid',
                  'korona',
                  'HIV',
                  'AIDS',
                  'Zika',
                  'SARS',
                  'svineinfluensa',
                  'matkrise',
                  'ebola',
                ],
                note: 'Her: svartedauden, spanskesyken. Brukes for epidemier, pandemier, pest, smittsomme sykdommer og hungersnød gjennom historien. Brukes med JFF for bøker som handler om hvordan dette har påvirket tidligere samfunn. Se også MBX <a href="/thema/nb/MBX">Medisinsk historie</a>',
                related: [
                  {
                    id: 'MBX',
                    label: 'Medisinsk historie',
                    originalLabel: 'Medisinsk historie',
                    note: 'Se også NHTF <a href="/thema/nb/NHTF">Historie: pest, sykdommer, hungersnød</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'NHTG',
                label: 'Genealogi, heraldikk og navnehistorie',
                originalLabel: 'Genealogi, heraldikk og navnehistorie',
                alternativeLabels: [],
                note: 'Her: slektsforskning. Se også GTT <a href="/thema/nb/GTT">Flagg, emblemer, symboler, logoer</a>, VFXB1 <a href="/thema/nb/VFXB1">Spedbarnsnavn: håndbøker for foreldre</a>, WQY <a href="/thema/nb/WQY">Familiehistorie og slektsgransking</a>',
                related: [
                  {
                    id: 'GTT',
                    label: 'Flagg, emblemer, symboler, logoer',
                    originalLabel: 'Flagg, emblemer, symboler, logoer',
                    note: 'Her: vexillologi, symbologi. Brukes for bøker om en eller flere av disse emnene. Brukes med andre emnekategorier hvis hensiktsmessig, f. eks. bruk med QR*-koder for religiøse symboler, med KJS*-koder for logoer eller symboler brukt i markedsføring, eller med VXW*-koder for symboler knyttet til mystikk eller magi. Se også NHTG <a href="/thema/nb/NHTG">Genealogi, heraldikk og navnehistorie</a>',
                  },
                  {
                    id: 'VFXB1',
                    label: 'Spedbarnsnavn: håndbøker for foreldre',
                    originalLabel: 'Spedbarnsnavn: håndbøker for foreldre',
                  },
                  {
                    id: 'WQY',
                    label: 'Familiehistorie og slektsgransking',
                    originalLabel: 'Familiehistorie og slektsgransking',
                    note: 'Se også NHTG <a href="/thema/nb/NHTG">Genealogi, heraldikk og navnehistorie</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'NHTK',
                label: 'Industrialisering og industrihistorie',
                originalLabel: 'Industrialisering og industrihistorie',
                alternativeLabels: [
                  'industriell revolusjon',
                  'teknologisk revolusjon',
                ],
                note: 'Her: den industrielle revolusjon, industrielle og teknologiske endringers påvirkning på samfunnet. Se også KJZ <a href="/thema/nb/KJZ">Spesifikke selskapers historie / bedriftshistorie</a>',
                related: [
                  {
                    id: 'KJZ',
                    label: 'Bedriftshistorie',
                    originalLabel:
                      'Spesifikke selskapers historie / bedriftshistorie',
                    note: 'Se også NHTK <a href="/thema/nb/NHTK">Industrialisering og industrihistorie</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'NHTM',
                label: 'Maritim historie',
                originalLabel: 'Maritim historie',
                alternativeLabels: [
                  'pirater',
                  'hvalfangere',
                  'fiskere',
                  'oppdagelsesreiser',
                ],
                note: 'Her: sjøfartshistorie om sjøreiser, sjømenn, sjørøveri, hvalfangst, fiskeri, navigasjon, handel, utforskning og oppdagelser, shipping, kystsamfunn, marinen etc. Brukes for historisk behandling av alle aspekter ved menneskelig samhandling eller aktivitet på sjøen. Se også JWCK <a href="/thema/nb/JWCK">Sjøstyrker og krigføring</a>',
                related: [
                  {
                    id: 'JWCK',
                    label: 'Sjøstyrker og krigføring',
                    originalLabel: 'Sjøstyrker og krigføring',
                    note: 'Her: marinen, sjøoperasjoner, ubåtkrig, amfibisk krigføring osv. Brukes med NHW* for militærhistorie eller NHTM for maritim historie, JWMV og enten WGG* for bøker om militære skip, eller TRL* for tekniske aspekter etc. Se også NHTM <a href="/thema/nb/NHTM">Maritim historie</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'NHTP',
                label: 'Historisk geografi',
                originalLabel: 'Historisk geografi',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'NHTP1',
                    label: 'Historiske kart og atlas',
                    originalLabel: 'Historiske kart og atlas',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som presenterer historie gjennom ulike typer kart, inkludert gamle kart eller kart som er laget spesielt for dette formålet, samt historiske atlas. Se også RGXB <a href="/thema/nb/RGXB">Atlas / kart</a>, RGV <a href="/thema/nb/RGV">Kartografi, karttegning og projeksjoner</a>',
                    related: [
                      {
                        id: 'RGV',
                        label: 'Kartografi, karttegning og projeksjoner',
                        originalLabel:
                          'Kartografi, karttegning og projeksjoner',
                        note: 'Brukes for bøker om kart og det å lage kart, men ikke selve kartene',
                      },
                      {
                        id: 'RGXB',
                        label: 'Atlas / kart',
                        originalLabel: 'Atlas / kart',
                        note: 'Her: globuser og verdenskart eller kart over kontinenter i liten målestokk, nasjonale og regionale atlas eller kart, tematiske atlas. Brukes med kvalifikatorer for geografi og andre koder fra R* for ulike typer av kart, f.eks. RGCP for politiske kart eller atlas, RGB for fysiske eller topografiske kart, RBK for verdensatlas over vannressurser, QRP for atlas over islam etc. Se også NHTP1 <a href="/thema/nb/NHTP1">Historiske kart og atlas</a>, WTR* <a href="/thema/nb/WTR">Kart og atlas</a> og dens underkategorier',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'NHTQ',
                label: 'Kolonialisme og imperialisme',
                originalLabel: 'Kolonialisme og imperialisme',
                alternativeLabels: [],
                note: 'Here: kolonisering, studier av kolonier eller imperier, nyimperalisme, nykolonialisme, konsekvenser av kolonialisme. Brukes med NHW* for militærhistoriske bøker om kolonikriger og -konflikter, med JPS* for bøker om nykolonialisme. Se også NHTR <a href="/thema/nb/NHTR">Nasjonal frigjøring og uavhengighet</a>',
                related: [
                  {
                    id: 'NHTR',
                    label: 'Nasjonal frigjøring og uavhengighet',
                    originalLabel: 'Nasjonal frigjøring og uavhengighet',
                    note: 'Brukes for bøker om å oppnå uavhengighet, eller bøker som tar for seg historien til samfunn som nylig har oppnådd uavhengighet. Brukes med NHW* for militærhistoriske bøker om frigjøringskriger og uavhengighet',
                  },
                ],
                children: [],
              },
              {
                id: 'NHTR',
                label: 'Nasjonal frigjøring og uavhengighet',
                originalLabel: 'Nasjonal frigjøring og uavhengighet',
                alternativeLabels: ['postkolonisering', 'postkoloinalisme'],
                note: 'Brukes for bøker om å oppnå uavhengighet, eller bøker som tar for seg historien til samfunn som nylig har oppnådd uavhengighet. Brukes med NHW* for militærhistoriske bøker om frigjøringskriger og uavhengighet',
                related: [],
                children: [
                  {
                    id: 'NHTR1',
                    label: 'Avkolonisering',
                    originalLabel: 'Avkolonisering',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som handler om prosessen rundt avkolonisering, slutten på imperier bygget på kolonisering, fortsatt indirekte kolonisering eller som handler om postkoloniale samfunn',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'NHTS',
                label: 'Slaveri og avskaffelse av slaveri',
                originalLabel: 'Slaveri og avskaffelse av slaveri',
                alternativeLabels: [],
                note: 'Brukes om bøker som handler om historiske aspekter ved slaveri i ulike samfunn, kulturer eller regioner',
                related: [],
                children: [],
              },
              {
                id: 'NHTT',
                label: 'Invasjon, erobring og okkupasjon',
                originalLabel: 'Invasjon, erobring og okkupasjon',
                alternativeLabels: [],
                note: 'Brukes med NWH* for militærhistoriske bøker om kriger eller konflikter i forbindelse med invasjon, erobring eller okkupasjon',
                related: [],
                children: [],
              },
              {
                id: 'NHTV',
                label: 'Revolusjoner, oppstander og opprør',
                originalLabel: 'Revolusjoner, oppstander og opprør',
                alternativeLabels: [],
                note: 'Her: voldelige forsøk på å endre eksisterende system, statskupp etc. Brukes med NHW* for militærhistoriske bøker som handler om kriger eller konflikter i forbindelse med revolusjoner, oppstander, opprør etc. Se også JPWQ <a href="/thema/nb/JPWQ">Revolusjonære grupper og bevegelser</a>',
                related: [
                  {
                    id: 'JPWQ',
                    label: 'Revolusjonære grupper og bevegelser',
                    originalLabel: 'Revolusjonære grupper og bevegelser',
                    note: 'Se også NHTV <a href="/thema/nb/NHTV">Revolusjoner, oppstander og opprør</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'NHTW',
                label: 'Kalde kriger',
                originalLabel: 'Kalde kriger',
                alternativeLabels: ['liksomkriger'],
                note: 'Her: stedfortrederkonflikter, den kalde krigen, falske kriger. Brukes for bøker som tar for seg konflikter mellom nasjoner som ikke innebærer konkrete militære handlinger, men som først og fremst skjer gjennom økonomiske og politiske handlinger, propaganda, spionasje eller stedfortrederkriger som føres av motstridende krefter eller tredjeparter',
                related: [],
                children: [],
              },
              {
                id: 'NHTX',
                label: 'Vold, intoleranse og forfølgelse',
                originalLabel: 'Vold, intoleranse og forfølgelse',
                alternativeLabels: [
                  'heksejakt',
                  'etnisk rensing',
                  'autodafe',
                  'hekseprosesser',
                ],
                note: 'Her: utrenskninger, pogromer, voldelig undertrykkelse, grusomheter, massakre etc. Brukes for bøker som tar for seg disse temaene fra en historisk synsvinkel, aspekter ved disse emnene eller spesifikke historiske hendelser i ulike samfunn, kulturer eller regioner. Se også QRAM9 <a href="/thema/nb/QRAM9">Religiøs intoleranse, forfølgelse og konflikt</a>',
                related: [
                  {
                    id: 'QRAM9',
                    label: 'Religiøs intoleranse, forfølgelse og konflikt',
                    originalLabel:
                      'Religiøs intoleranse, forfølgelse og konflikt',
                    note: 'Brukes for bøker om diskriminering, intoleranser, vold eller forfølgelse av en religion eller innen en religion, av andre med ulik tro. Brukes med JBFA for tematikk rundt diskriminering i samfunnet basert på religiøs identitet, med JW* eller NHW* for religionskriger eller konflikter rundt tro',
                  },
                ],
                children: [],
              },
              {
                id: 'NHTZ',
                label: 'Folkemord og etnisk rensning',
                originalLabel: 'Folkemord og etnisk rensning',
                alternativeLabels: ['konsentrasjonsleirer'],

                related: [],
                children: [
                  {
                    id: 'NHTZ1',
                    label: 'Holocaust',
                    originalLabel: 'Holocaust',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'NHW',
            label: 'Militærhistorie',
            originalLabel: 'Militærhistorie',
            alternativeLabels: [],
            note: 'Med alle NHW*-koder, tildel kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig. Se også JW* <a href="/thema/nb/JW">Krigføring og forsvar</a> og dens underkategorier',
            related: [
              {
                id: 'JW',
                label: 'Krigføring og forsvar',
                originalLabel: 'Krigføring og forsvar',
              },
            ],
            children: [
              {
                id: 'NHWA',
                label: 'Oldtidens krigføring',
                originalLabel: 'Oldtidens krigføring',
                alternativeLabels: [],
                note: 'Brukes med relevante kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'NHWD',
                label:
                  'Krigføring i middelalderen (før krigføring med skytevåpen)',
                originalLabel:
                  'Krigføring i middelalderen (før krigføring med skytevåpen)',
                alternativeLabels: [],
                note: 'Brukes for bøker som tar for seg krigføring etter antikken, vanligvis perioder med større bruk av kavaleri og utstyr som stigbøyler, eller riddere i rustning og borger, men før innføring av moderne teknologi som skytevåpen. Brukes med kvalifikatorer for sted og tid der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'NHWF',
                label:
                  'Tidlig moderne krigføring (inkluderer krigføring med skytevåpen)',
                originalLabel:
                  'Tidlig moderne krigføring (inkluderer krigføring med skytevåpen)',
                alternativeLabels: [],
                note: 'Brukes for bøker om krigføring i perioder preget av utvikling og innføring av skytevåpen og andre krigføringsteknikker. Brukes med relevante kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'NHWL',
                label: 'Moderne krigføring',
                originalLabel: 'Moderne krigføring',
                alternativeLabels: [],
                note: 'Brukes for bøker som tar for seg perioder med globale kriger, krigføring som tar i bruk sofistikert teknologi, verneplikt etc. Brukes med relevante kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'NHWR',
                label: 'Spesifikke kriger og felttog',
                originalLabel: 'Spesifikke kriger og felttog',
                alternativeLabels: [],
                note: 'Brukes for bøker som tar for seg spesifikke kriger eller felttog (krigens navn er beskrevet i andre metadatafelt). Brukes med NH* for typer krig og relevante kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig. F.eks. for militærhistorie under tredveårskrigen i Europa, bruk med 1D og 3MGB-DE-B',
                related: [],
                children: [
                  {
                    id: 'NHWR1',
                    label: 'Spesifikke slag',
                    originalLabel: 'Spesifikke slag',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som tar for seg spesifikke slag (navnet på slaget er beskrevet i andre metadatafelt). Brukes med relevante kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'NHWR3',
                    label: 'Borgerkriger',
                    originalLabel: 'Borgerkriger',
                    alternativeLabels: [],
                    note: 'Brukes med relevante kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'NHWR5',
                    label: 'Første verdenskrig',
                    originalLabel: 'Første verdenskrig',
                    alternativeLabels: [],
                    note: 'Brukes med relevante kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'NHWR7',
                    label: 'Andre verdenskrig',
                    originalLabel: 'Andre verdenskrig',
                    alternativeLabels: [],
                    note: 'Brukes med relevante kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'NHWR9',
                    label:
                      'Militærhistorie: konflikter etter andre verdenskrig',
                    originalLabel:
                      'Militærhistorie: konflikter etter andre verdenskrig',
                    alternativeLabels: [],
                    note: 'Brukes med relevante kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig, f.eks. for ei bok om krigen mellom Iran og Irak, bruk med 1FBN, 1FBQ og 3MPQX',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'NK',
        label: 'Arkeologi',
        originalLabel: 'Arkeologi',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'NKA',
            label: 'Arkeologisk teori',
            originalLabel: 'Arkeologisk teori',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'NKD',
            label: 'Arkeologi etter periode og region',
            originalLabel: 'Arkeologi etter periode og region',
            alternativeLabels: [],
            note: 'Tildel også kvalifikatorer for geografi og tidsperiode der det er hensiktsmessig. f.eks. for førhistorisk arkeologi, bruk med 3B eller for egyptologi, bruk med 1QBAE og 3*',
            related: [],
            children: [
              {
                id: 'NKDS',
                label: 'Arkeologiske utgravingssteder',
                originalLabel: 'Arkeologiske utgravingssteder',
                alternativeLabels: ['kulturarv'],
                note: 'Her: bøker om spesifikke steder eller utgravninger',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'NKL',
            label: 'Landskapsarkeologi',
            originalLabel: 'Landskapsarkeologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'NKP',
            label: 'Miljøarkeologi',
            originalLabel: 'Miljøarkeologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'NKR',
            label: 'Undervannsarkeologi',
            originalLabel: 'Undervannsarkeologi',
            alternativeLabels: [],
            note: 'Her: maritim eller marin arkeologi',
            related: [],
            children: [],
          },
          {
            id: 'NKT',
            label: 'Industriell arkeologi',
            originalLabel: 'Industriell arkeologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'NKV',
            label: 'Slagmarksarkeologi',
            originalLabel: 'Slagmarksarkeologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'NKX',
            label: 'Arkeologisk metodologi og teknikk',
            originalLabel: 'Arkeologisk metodologi og teknikk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 'P',
    label: 'Matematikk og naturvitenskap',
    originalLabel: 'Matematikk og naturvitenskap',
    alternativeLabels: [],
    note: 'Bruk P*-koder først og fremst for akademiske utgivelser og utgivelser for profesjonsmarkedet, samt noen generelle verk for voksne. Det er uvanlig å bruke kvalifikatorer sammen med P*-koder. IKKE bruk koden ‘P’, men vel spesifikke kategorier fra P*. Foretrekk WN*-koder for bøker for allmennmarkedet der det er hensiktsmessig',
    related: [],
    children: [
      {
        id: 'PB',
        label: 'Matematikk',
        originalLabel: 'Matematikk',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'PBB',
            label: 'Matematikkens filosofi',
            originalLabel: 'Matematikkens filosofi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PBC',
            label: 'Matematikkens fundament',
            originalLabel: 'Matematikkens fundament',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'PBCD',
                label: 'Matematisk logikk',
                originalLabel: 'Matematisk logikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBCH',
                label: 'Mengdelære',
                originalLabel: 'Mengdelære',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBCN',
                label: 'Tallsystemer',
                originalLabel: 'Tallsystemer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PBD',
            label: 'Diskret matematikk',
            originalLabel: 'Diskret matematikk',
            alternativeLabels: [],
            note: 'Her: endelig matematikk',
            related: [],
            children: [],
          },
          {
            id: 'PBF',
            label: 'Algebra',
            originalLabel: 'Algebra',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PBG',
            label: 'Grupper og gruppeteori',
            originalLabel: 'Grupper og gruppeteori',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PBH',
            label: 'Tallteori',
            originalLabel: 'Tallteori',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PBJ',
            label: 'Elementær algebra og funksjonslære',
            originalLabel: 'Elementær algebra og funksjonslære',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PBK',
            label: 'Kalkulus og matematisk analyse',
            originalLabel: 'Kalkulus og matematisk analyse',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'PBKA',
                label: 'Kalkulus',
                originalLabel: 'Kalkulus',
                alternativeLabels: [],
                note: 'Her: derivasjon',
                related: [],
                children: [],
              },
              {
                id: 'PBKB',
                label: 'Reell analyse, reelle variabler',
                originalLabel: 'Reell analyse, reelle variabler',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBKD',
                label: 'Kompleks analyse, komplekse variabler',
                originalLabel: 'Kompleks analyse, komplekse variabler',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBKF',
                label: 'Funksjonsanalyse og transformasjon',
                originalLabel: 'Funksjonsanalyse og transformasjon',
                alternativeLabels: [],
                note: 'Her: Fourier, Hilbert, Laplace, Z-transformasjonen',
                related: [],
                children: [],
              },
              {
                id: 'PBKJ',
                label: 'Differensialregning og -ligninger',
                originalLabel: 'Differensialregning og -ligninger',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBKL',
                label: 'Integralregning og -ligninger',
                originalLabel: 'Integralregning og -ligninger',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBKQ',
                label: 'Variasjonsregning',
                originalLabel: 'Variasjonsregning',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBKS',
                label: 'Numerisk analyse',
                originalLabel: 'Numerisk analyse',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PBM',
            label: 'Geometri',
            originalLabel: 'Geometri',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'PBMB',
                label: 'Trigonometri',
                originalLabel: 'Trigonometri',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBMH',
                label: 'Euklidsk geometri',
                originalLabel: 'Euklidsk geometri',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBML',
                label: 'Ikke-euklidsk geometri',
                originalLabel: 'Ikke-euklidsk geometri',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBMP',
                label: 'Differensial- og riemannsk geometri',
                originalLabel: 'Differensial- og riemannsk geometri',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBMS',
                label: 'Analytisk geometri',
                originalLabel: 'Analytisk geometri',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBMW',
                label: 'Algebraisk geometri',
                originalLabel: 'Algebraisk geometri',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBMX',
                label: 'Fraktalgeometri',
                originalLabel: 'Fraktalgeometri',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PBP',
            label: 'Topologi',
            originalLabel: 'Topologi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'PBPD',
                label: 'Algebraisk topologi',
                originalLabel: 'Algebraisk topologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBPH',
                label: 'Analytisk topologi',
                originalLabel: 'Analytisk topologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PBT',
            label: 'Sannsynlighetsberegning og statistikk',
            originalLabel: 'Sannsynlighetsberegning og statistikk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'PBTB',
                label: 'Bayesiansk statistikk',
                originalLabel: 'Bayesiansk statistikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PBU',
            label: 'Optimalisering',
            originalLabel: 'Optimalisering',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'PBUD',
                label: 'Spillteori',
                originalLabel: 'Spillteori',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBUH',
                label: 'Lineær programmering',
                originalLabel: 'Lineær programmering',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PBV',
            label: 'Kombinatorikk og grafteori',
            originalLabel: 'Kombinatorikk og grafteori',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PBW',
            label: 'Anvendt matematikk',
            originalLabel: 'Anvendt matematikk',
            alternativeLabels: [],
            note: 'Se også PDE <a href="/thema/nb/PDE">Matematikk for forskere</a>, TBJ <a href="/thema/nb/TBJ">Matematikk for ingeniører</a>, UYAM <a href="/thema/nb/UYAM">Matematikk for informatikkfag</a>',
            related: [
              {
                id: 'PDE',
                label: 'Matematikk for forskere',
                originalLabel: 'Matematikk for forskere',
                note: 'Se også PBW <a href="/thema/nb/PBW">Anvendt matematikk</a>',
              },
              {
                id: 'TBJ',
                label: 'Matematikk for ingeniører',
                originalLabel: 'Matematikk for ingeniører',
                note: 'Se også PBW <a href="/thema/nb/PBW">Anvendt matematikk</a>',
              },
              {
                id: 'UYAM',
                label: 'Matematikk for informatikkfag',
                originalLabel: 'Matematikk for informatikkfag',
              },
            ],
            children: [
              {
                id: 'PBWH',
                label: 'Matematisk modellering',
                originalLabel: 'Matematisk modellering',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBWL',
                label: 'Stokastikk',
                originalLabel: 'Stokastikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBWR',
                label: 'Ikke-lineær vitenskap',
                originalLabel: 'Ikke-lineær vitenskap',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBWS',
                label: 'Kaosteori',
                originalLabel: 'Kaosteori',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PBWX',
                label: 'Fuzzy mengdelære',
                originalLabel: 'Fuzzy mengdelære',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PBX',
            label: 'Matematisk historie',
            originalLabel: 'Matematisk historie',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'PD',
        label: 'Vitenskap',
        originalLabel: 'Vitenskap',
        alternativeLabels: [],
        note: 'Brukes for både naturvitenskap og for utgivelser for allmennmarkedet om ulike vitenskapsgrener. Brukes med andre emnekoder der det er hensiktsmessig',
        related: [],
        children: [
          {
            id: 'PDA',
            label: 'Vitenskapsfilosofi',
            originalLabel: 'Vitenskapsfilosofi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PDC',
            label: 'Vitenskapelig nomenklatur og klassifisering',
            originalLabel: 'Vitenskapelig nomenklatur og klassifisering',
            alternativeLabels: [],
            note: 'Brukes med P*, M*, T* for bøker om klassifisering innen ulike vitenskapsområder, f.eks. med MJC for klassifikasjon av sykdommer (nosologi). Se også GLK <a href="/thema/nb/GLK">Bibliografisk- og emneordskontroll</a>, PSAB <a href="/thema/nb/PSAB">Taksonomi og systematikk</a>',
            related: [
              {
                id: 'GLK',
                label: 'Bibliografisk- og emneordskontroll',
                originalLabel: 'Bibliografisk- og emneordskontroll',
                note: 'Her: indeksering, katalogisering, klassifisering',
              },
              {
                id: 'PSAB',
                label: 'Taksonomi og systematikk',
                originalLabel: 'Taksonomi og systematikk',
                note: 'Se også GLK <a href="/thema/nb/GLK">Bibliografisk- og emneordskontroll</a>, PDC <a href="/thema/nb/PDC">Vitenskapelig nomenklatur og klassifisering</a>',
              },
            ],
            children: [],
          },
          {
            id: 'PDD',
            label: 'Vitenskapelige standarder, målesystem etc.',
            originalLabel: 'Vitenskapelige standarder, målesystem etc.',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PDE',
            label: 'Matematikk for forskere',
            originalLabel: 'Matematikk for forskere',
            alternativeLabels: [],
            note: 'Se også PBW <a href="/thema/nb/PBW">Anvendt matematikk</a>',
            related: [
              {
                id: 'PBW',
                label: 'Anvendt matematikk',
                originalLabel: 'Anvendt matematikk',
                note: 'Se også PDE <a href="/thema/nb/PDE">Matematikk for forskere</a>, TBJ <a href="/thema/nb/TBJ">Matematikk for ingeniører</a>, UYAM <a href="/thema/nb/UYAM">Matematikk for informatikkfag</a>',
              },
            ],
            children: [],
          },
          {
            id: 'PDG',
            label:
              'Industriell bruk av vitenskapelig forskning og teknologisk innovasjon',
            originalLabel:
              'Industriell bruk av vitenskapelig forskning og teknologisk innovasjon',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PDJ',
            label: 'Regulering av vitenskap og eksperimenter',
            originalLabel: 'Regulering av vitenskap og eksperimenter',
            alternativeLabels: [],
            note: 'Brukes for bøker om regulering, enten det er bestemt av juridiske eller andre instanser eller myndigheter, rettet mot forskere og praktikere. Brukes sammen med andre P*-koder for å angi område av vitenskap, der det er hensiktsmessig. Se også LNDB8 <a href="/thema/nb/LNDB8">Forskning og høyere utdanning: lov og rett</a>',
            related: [
              {
                id: 'LNDB8',
                label: 'Forskning og høyere utdanning: lov og rett',
                originalLabel: 'Forskning og høyere utdanning: lov og rett',
                note: 'Se også PDJ <a href="/thema/nb/PDJ">Regulering av vitenskap og eksperimenter</a>',
              },
            ],
            children: [],
          },
          {
            id: 'PDK',
            label: 'Vitenskapelig finansiering og politikk',
            originalLabel: 'Vitenskapelig finansiering og politikk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PDM',
            label: 'Vitenskapelig forskning',
            originalLabel: 'Vitenskapelig forskning',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PDN',
            label: 'Vitenskapelig utstyr, forsøk og teknikker',
            originalLabel: 'Vitenskapelig utstyr, forsøk og teknikker',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'PDND',
                label: 'Mikroskopi',
                originalLabel: 'Mikroskopi',
                alternativeLabels: ['mikroskop', 'mikroskoper'],
                note: 'Her: optisk-, elektron-, skanningssonde-, røntgen-, ultrafiolett-, infrarød mikroskopi etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PDR',
            label: 'Vitenskapens og teknologiens påvirkning på samfunnet',
            originalLabel:
              'Vitenskapens og teknologiens påvirkning på samfunnet',
            alternativeLabels: [],
            note: 'Brukes for bøker som tar for seg påvirkning en vitenskap eller teknologi har hatt eller kan tenkes å ha på samfunnet. Brukes med NHTB for sosialhistorie eller andre P*, T* eller J*-koder der det er hensiktsmessig. Se også JBFV5 <a href="/thema/nb/JBFV5">Vitenskapelig, teknologisk og medisinsk utvikling</a>, UBJ <a href="/thema/nb/UBJ">Etiske og sosiale aspekter ved digital- og informasjonsteknologi</a>',
            related: [
              {
                id: 'JBFV5',
                label: 'Vitenskapelig, teknologisk og medisinsk utvikling',
                originalLabel:
                  'Vitenskapelig, teknologisk og medisinsk utvikling',
              },
              {
                id: 'UBJ',
                label:
                  'Etiske og sosiale aspekter ved digital- og informasjonsteknologi',
                originalLabel:
                  'Etiske og sosiale aspekter ved digital- og informasjonsteknologi',
                note: 'Her: digitale skiller, industri 4.0, tingenes internett, smartbyer, velferdsteknologi, smarthjem, den fjerde industrielle revolusjonen, nettdugnad, digitalt medborgerskap, digital deltakelse, etikk innen kunstig intelligens, etiske sider ved innsamling av persondata, transhumanisme, teknologisk singularitet, cyberkultur, miljøpåvirkning, energiforbruk, påvirkning på bærekraft etc. Brukes for bøker som handler om påvirkning på samfunnet, næringslivet, miljøet, menneskelig oppførsel, økonomien, hverdagslivet, og som henger sammen med bruk av data-, digital- eller informasjonsteknologi, og deres påvirkning på samfunnet. Se også JBFV5 <a href="/thema/nb/JBFV5">Vitenskapelig, teknologisk og medisinsk utvikling</a>, PDR <a href="/thema/nb/PDR">Vitenskapens og teknologiens påvirkning på samfunnet</a>',
              },
            ],
            children: [],
          },
          {
            id: 'PDT',
            label: 'Nanovitenskap',
            originalLabel: 'Nanovitenskap',
            alternativeLabels: ['nanoteknologi'],
            note: 'Brukes for nanomaterialer, synteser av nanomaterialer, nanopartikler (kjemi). Se også TBN <a href="/thema/nb/TBN">Nanoteknologi</a>',
            related: [
              {
                id: 'TBN',
                label: 'Nanoteknologi',
                originalLabel: 'Nanoteknologi',
                note: 'Se også PDT <a href="/thema/nb/PDT">Nanovitenskap</a>',
              },
            ],
            children: [],
          },
          {
            id: 'PDX',
            label: 'Vitenskapshistorie',
            originalLabel: 'Vitenskapshistorie',
            alternativeLabels: [],
            note: 'Se også TBX <a href="/thema/nb/TBX">Ingeniørvitenskapens og teknologiens historie</a>',
            related: [
              {
                id: 'TBX',
                label: 'Ingeniørvitenskapens og teknologiens historie',
                originalLabel: 'Ingeniørvitenskapens og teknologiens historie',
                note: 'Se også PDX <a href="/thema/nb/PDX">Vitenskapshistorie</a>',
              },
            ],
            children: [],
          },
          {
            id: 'PDZ',
            label: 'Populærvitenskap',
            originalLabel: 'Populærvitenskap',
            alternativeLabels: [],
            note: 'Bruk med andre P*-koder som indikerer vitenskapsområdet. Se også WNW* <a href="/thema/nb/WNW">Jorda: naturhistorie</a> og dens underkategorier, WNX <a href="/thema/nb/WNX">Astronomi og verdensrommet</a>',
            related: [
              {
                id: 'WNW',
                label: 'Jorda: naturhistorie',
                originalLabel: 'Jorda: naturhistorie',
                note: 'Her: verk for allmennmarkedet om jordas naturhistorie, fysiske egenskaper og system, samt verk om livet på jorda. Her: verk om vulkaner, jordskjelv etc. for allmennmarkedet. Se også RB* <a href="/thema/nb/RB">Geovitenskap</a> og dens underkategorier, PSAF <a href="/thema/nb/PSAF">Økologi, biosfæren</a>',
              },
              {
                id: 'WNX',
                label: 'Astronomi og verdensrommet',
                originalLabel: 'Astronomi og verdensrommet',
                note: 'Her: stjernetegn og stjerneatlas, samt astronomi, verdensrommet og romfart for allmennmarkedet. Se også PG* <a href="/thema/nb/PG">Astronomi, sted og tid</a> og dens underkategorier, TTD <a href="/thema/nb/TTD">Romforskning</a>',
              },
            ],
            children: [
              {
                id: 'PDZM',
                label: 'Populærvitenskap: matematikk',
                originalLabel: 'Populærvitenskap: matematikk',
                alternativeLabels: [],
                note: 'Brukes med andre PB*-koder som indikerer hvilket matematisk område som blir behandlet der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'PG',
        label: 'Astronomi, sted og tid',
        originalLabel: 'Astronomi, sted og tid',
        alternativeLabels: [],
        note: 'Se også TTD* <a href="/thema/nb/TTD">Romforskning</a> og dens underkategorier',
        related: [
          {
            id: 'TTD',
            label: 'Romforskning',
            originalLabel: 'Romforskning',
            note: 'Se også WNX <a href="/thema/nb/WNX">Astronomi og verdensrommet</a>',
          },
        ],
        children: [
          {
            id: 'PGC',
            label: 'Teoretisk og matematisk astronomi',
            originalLabel: 'Teoretisk og matematisk astronomi',
            alternativeLabels: [],
            note: 'Se også PHVB <a href="/thema/nb/PHVB">Astrofysikk</a>',
            related: [
              {
                id: 'PHVB',
                label: 'Astrofysikk',
                originalLabel: 'Astrofysikk',
              },
            ],
            children: [],
          },
          {
            id: 'PGG',
            label:
              'Astronomiske observasjoner: observatorier, utstyr og metoder',
            originalLabel:
              'Astronomiske observasjoner: observatorier, utstyr og metoder',
            alternativeLabels: [],
            note: 'Se også WNX <a href="/thema/nb/WNX">Astronomi og verdensrommet</a>',
            related: [
              {
                id: 'WNX',
                label: 'Astronomi og verdensrommet',
                originalLabel: 'Astronomi og verdensrommet',
                note: 'Her: stjernetegn og stjerneatlas, samt astronomi, verdensrommet og romfart for allmennmarkedet. Se også PG* <a href="/thema/nb/PG">Astronomi, sted og tid</a> og dens underkategorier, TTD <a href="/thema/nb/TTD">Romforskning</a>',
              },
            ],
            children: [],
          },
          {
            id: 'PGK',
            label: 'Kosmologi og universet',
            originalLabel: 'Kosmologi og universet',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PGM',
            label: 'Galakser og stjerner',
            originalLabel: 'Galakser og stjerner',
            alternativeLabels: [],
            note: 'Her: eksoplaneter, ekstrasolare planeter',
            related: [],
            children: [],
          },
          {
            id: 'PGS',
            label: 'Solsystemet: solen og planetene',
            originalLabel: 'Solsystemet: solen og planetene',
            alternativeLabels: [],
            note: 'Her: de enkelte planeters måner',
            related: [],
            children: [],
          },
          {
            id: 'PGT',
            label: 'Astronomiske diagrammer og atlas',
            originalLabel: 'Astronomiske diagrammer og atlas',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PGZ',
            label: 'Tid (kronologi), tidssystemer og standarder',
            originalLabel: 'Tid (kronologi), tidssystemer og standarder',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'PH',
        label: 'Fysikk',
        originalLabel: 'Fysikk',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'PHD',
            label: 'Klassisk mekanikk',
            originalLabel: 'Klassisk mekanikk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'PHDB',
                label: 'Elementær mekanikk',
                originalLabel: 'Elementær mekanikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PHDD',
                label: 'Analytisk mekanikk',
                originalLabel: 'Analytisk mekanikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PHDF',
                label: 'Fysikk: flytende stoffers mekanikk',
                originalLabel: 'Fysikk: flytende stoffers mekanikk',
                alternativeLabels: [],
                note: 'Her: væskemekanikk, fluidmekanikk',
                related: [],
                children: [],
              },
              {
                id: 'PHDS',
                label: 'Bølgemekanikk (vibrasjon og akustikk)',
                originalLabel: 'Bølgemekanikk (vibrasjon og akustikk)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PHDT',
                label: 'Dynamikk og statikk',
                originalLabel: 'Dynamikk og statikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PHDV',
                label: 'Gravitasjon',
                originalLabel: 'Gravitasjon',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PHDY',
                label: 'Energi',
                originalLabel: 'Energi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PHF',
            label: 'Materialer / aggregattilstand',
            originalLabel: 'Materialer / aggregattilstand',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'PHFB',
                label: 'Lavtemperaturfysikk',
                originalLabel: 'Lavtemperaturfysikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PHFC',
                label:
                  'Kondenserte fasers fysikk (væskeform og faststoffysikk)',
                originalLabel:
                  'Kondenserte fasers fysikk (væskeform og faststoffysikk)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'PHFC1',
                    label: 'Myke materialers fysikk',
                    originalLabel: 'Myke materialers fysikk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'PHFC2',
                    label: 'Mesoskopisk fysikk',
                    originalLabel: 'Mesoskopisk fysikk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'PHFG',
                label: 'Gassers fysikk',
                originalLabel: 'Gassers fysikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PHFP',
                label: 'Plasmafysikk',
                originalLabel: 'Plasmafysikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PHH',
            label: 'Termodynamikk og varme',
            originalLabel: 'Termodynamikk og varme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PHJ',
            label: 'Optikk',
            originalLabel: 'Optikk',
            alternativeLabels: [],
            note: 'Her: fotonikk',
            related: [],
            children: [
              {
                id: 'PHJL',
                label: 'Laserfysikk',
                originalLabel: 'Laserfysikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PHK',
            label: 'Elektrisitet, elektromagnetisme og magnetisme',
            originalLabel: 'Elektrisitet, elektromagnetisme og magnetisme',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PHM',
            label: 'Atom- og molekylfysikk',
            originalLabel: 'Atom- og molekylfysikk',
            alternativeLabels: [],
            note: 'Her: mikrofysikk',
            related: [],
            children: [],
          },
          {
            id: 'PHN',
            label: 'Kjernefysikk',
            originalLabel: 'Kjernefysikk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PHP',
            label: 'Partikkel- og høyenergifysikk',
            originalLabel: 'Partikkel- og høyenergifysikk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PHQ',
            label: 'Kvantefysikk (kvantemekanikk og kvantefeltteori)',
            originalLabel: 'Kvantefysikk (kvantemekanikk og kvantefeltteori)',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PHR',
            label: 'Relativitetsfysikk',
            originalLabel: 'Relativitetsfysikk',
            alternativeLabels: [],
            note: 'Her: tid (fysikk)',
            related: [],
            children: [],
          },
          {
            id: 'PHS',
            label: 'Statistisk fysikk',
            originalLabel: 'Statistisk fysikk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PHU',
            label: 'Matematisk fysikk',
            originalLabel: 'Matematisk fysikk',
            alternativeLabels: [],
            note: 'Her: beregningsfysikk',
            related: [],
            children: [],
          },
          {
            id: 'PHV',
            label: 'Anvendt fysikk',
            originalLabel: 'Anvendt fysikk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'PHVB',
                label: 'Astrofysikk',
                originalLabel: 'Astrofysikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PHVD',
                label: 'Medisinsk fysikk',
                originalLabel: 'Medisinsk fysikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PHVG',
                label: 'Geofysikk',
                originalLabel: 'Geofysikk',
                alternativeLabels: [],
                note: 'Se også RBG* <a href="/thema/nb/RBG">Geologi, geomorfologi og litosfæren</a> og dens underkategorier',
                related: [
                  {
                    id: 'RBG',
                    label: 'Geologi, geomorfologi og litosfæren',
                    originalLabel: 'Geologi, geomorfologi og litosfæren',
                    note: 'Se også PHVG <a href="/thema/nb/PHVG">Geofysikk</a>, RGB <a href="/thema/nb/RGB">Fysisk geografi og topografi</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'PHVJ',
                label: 'Atmosfærisk fysikk',
                originalLabel: 'Atmosfærisk fysikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PHVN',
                label: 'Biofysikk',
                originalLabel: 'Biofysikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PHVQ',
                label: 'Kjemisk fysikk',
                originalLabel: 'Kjemisk fysikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PHVS',
                label: 'Kryogenikk',
                originalLabel: 'Kryogenikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'PN',
        label: 'Kjemi',
        originalLabel: 'Kjemi',
        alternativeLabels: [],
        note: 'Se også TDC <a href="/thema/nb/TDC">Industriell kjemi og kjemiteknikk</a>, TQ* <a href="/thema/nb/TQ">Miljøvitenskap, -teknikk og -teknologi</a> og dens underkategorier',
        related: [
          {
            id: 'TDC',
            label: 'Industriell kjemi og kjemiteknikk',
            originalLabel: 'Industriell kjemi og kjemiteknikk',
            note: 'Her: teknologien bak vaskemidler, gummi, biocider etc.',
          },
          {
            id: 'TQ',
            label: 'Miljøvitenskap, -teknikk og -teknologi',
            originalLabel: 'Miljøvitenskap, -teknikk og -teknologi',
            note: 'Bruk: TQ*-koder for tekniske og praktiske aspekter av miljø, forurensning, avfallshåndtering etc. Bruk PNC for studier og forskning. Se også RN* <a href="/thema/nb/RN">Miljøet</a> og dens underkategorier',
          },
        ],
        children: [
          {
            id: 'PNB',
            label: 'Farmasøytisk kjemi',
            originalLabel: 'Farmasøytisk kjemi',
            alternativeLabels: [],
            note: 'Her: legemiddelkjemi, utvikling av legemidler, legemiddeldesign. Se også MKG <a href="/thema/nb/MKG">Farmakologi</a>',
            related: [
              {
                id: 'MKG',
                label: 'Farmakologi',
                originalLabel: 'Farmakologi',
                note: 'Brukes for bøker om grunnleggende og klinisk farmakologi, eller bøker om farmasøytisk vitenskap, studiet av medisiner og hvordan de virker. Brukes med M* eller P* der det er hensiktsmessig. Se også MQP <a href="/thema/nb/MQP">Farmasi og apotekteknikk</a>',
              },
            ],
            children: [],
          },
          {
            id: 'PNC',
            label: 'Miljøkjemi',
            originalLabel: 'Miljøkjemi',
            alternativeLabels: [],
            note: 'Bruk TQ* for praktiske anvendelser av miljøkjemi. Her: atmosfærisk-, jord- og vannkjemi, kjemisk forurensning og opprydding. Se også RBGK <a href="/thema/nb/RBGK">Geokjemi</a>, TQ* <a href="/thema/nb/TQ">Miljøvitenskap, -teknikk og -teknologi</a> og dens underkategorier',
            related: [
              {
                id: 'RBGK',
                label: 'Geokjemi',
                originalLabel: 'Geokjemi',
                note: 'Se også PNC <a href="/thema/nb/PNC">Miljøkjemi</a>, PNV <a href="/thema/nb/PNV">Mineralogi, krystaller og edelstener</a>',
              },
              {
                id: 'TQ',
                label: 'Miljøvitenskap, -teknikk og -teknologi',
                originalLabel: 'Miljøvitenskap, -teknikk og -teknologi',
                note: 'Bruk: TQ*-koder for tekniske og praktiske aspekter av miljø, forurensning, avfallshåndtering etc. Bruk PNC for studier og forskning. Se også RN* <a href="/thema/nb/RN">Miljøet</a> og dens underkategorier',
              },
            ],
            children: [],
          },
          {
            id: 'PND',
            label: 'Næringsmiddelkjemi',
            originalLabel: 'Næringsmiddelkjemi',
            alternativeLabels: [],
            note: 'Her: matvitenskap. Foretrekk TDCT2 for praktiske anvendelser av næringsmiddelkjemi. Se også TDCT2 <a href="/thema/nb/TDCT2">Næringsmiddelproduksjon</a>',
            related: [
              {
                id: 'TDCT2',
                label: 'Næringsmiddelproduksjon',
                originalLabel: 'Næringsmiddelproduksjon',
                note: 'Her: matproduksjon per ingrediens',
              },
            ],
            children: [],
          },
          {
            id: 'PNF',
            label: 'Analytisk kjemi',
            originalLabel: 'Analytisk kjemi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'PNFC',
                label: 'Kromatografi',
                originalLabel: 'Kromatografi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PNFR',
                label: 'Magnetresonans',
                originalLabel: 'Magnetresonans',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PNFS',
                label: 'Spektralanalyse, spektrokjemi, massespektrometri',
                originalLabel:
                  'Spektralanalyse, spektrokjemi, massespektrometri',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PNK',
            label: 'Uorganisk kjemi',
            originalLabel: 'Uorganisk kjemi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PNN',
            label: 'Organisk kjemi',
            originalLabel: 'Organisk kjemi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'PNND',
                label: 'Organometallisk kjemi',
                originalLabel: 'Organometallisk kjemi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PNNP',
                label: 'Polymerkjemi',
                originalLabel: 'Polymerkjemi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PNR',
            label: 'Fysikalsk kjemi',
            originalLabel: 'Fysikalsk kjemi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'PNRA',
                label: 'Beregningskjemi',
                originalLabel: 'Beregningskjemi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PNRC',
                label: 'Kolloidkjemi',
                originalLabel: 'Kolloidkjemi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PNRD',
                label: 'Katalyse',
                originalLabel: 'Katalyse',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'PNRD1',
                    label: 'Biokatalyse',
                    originalLabel: 'Biokatalyse',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'PNRE',
                label: 'Eksperimentell kjemi',
                originalLabel: 'Eksperimentell kjemi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PNRH',
                label: 'Elektro- og magnetokjemi',
                originalLabel: 'Elektro- og magnetokjemi',
                alternativeLabels: [],
                note: 'Her: batteri (kjemi)',
                related: [],
                children: [],
              },
              {
                id: 'PNRL',
                label: 'Kjernekjemi, fotokjemi og stråling',
                originalLabel: 'Kjernekjemi, fotokjemi og stråling',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PNRP',
                label: 'Kvantekjemi og teoretisk kjemi',
                originalLabel: 'Kvantekjemi og teoretisk kjemi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PNRR',
                label: 'Fysisk organisk kjemi',
                originalLabel: 'Fysisk organisk kjemi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PNRS',
                label: 'Faststoffkjemi',
                originalLabel: 'Faststoffkjemi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PNRW',
                label: 'Termokjemi og kjemisk termodynamikk',
                originalLabel: 'Termokjemi og kjemisk termodynamikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PNRX',
                label: 'Overflatekjemi og adsorbsjon',
                originalLabel: 'Overflatekjemi og adsorbsjon',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PNT',
            label: 'Krystallografi',
            originalLabel: 'Krystallografi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PNV',
            label: 'Mineralogi, krystaller og edelstener',
            originalLabel: 'Mineralogi, krystaller og edelstener',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'PS',
        label: 'Biologi og livsvitenskap',
        originalLabel: 'Biologi og livsvitenskap',
        alternativeLabels: [],
        note: 'Bruk PS*-koder for bøker for profesjonsmarkedet og høyere utdanning. Foretrekk WN*-koder for naturhistorie etc. for allmennmarkedet. Se også WN* <a href="/thema/nb/WN">Natur</a> og dens underkategorier',
        related: [
          {
            id: 'WN',
            label: 'Natur',
            originalLabel: 'Natur',
            note: 'Her: populærvitenskapelige bøker om naturhistorie, ulike aspekter ved naturlige landskap eller naturens verden. Bruk WN*-koder på bøker for allmennmarkedet. Foretrekk koder fra seksjonene P, R, T etc., på bøker for høyere utdanning og profesjonsmarkedet',
          },
        ],
        children: [
          {
            id: 'PSA',
            label: 'Livsvitenskap',
            originalLabel: 'Livsvitenskap',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'PSAB',
                label: 'Taksonomi og systematikk',
                originalLabel: 'Taksonomi og systematikk',
                alternativeLabels: [],
                note: 'Se også GLK <a href="/thema/nb/GLK">Bibliografisk- og emneordskontroll</a>, PDC <a href="/thema/nb/PDC">Vitenskapelig nomenklatur og klassifisering</a>',
                related: [
                  {
                    id: 'GLK',
                    label: 'Bibliografisk- og emneordskontroll',
                    originalLabel: 'Bibliografisk- og emneordskontroll',
                    note: 'Her: indeksering, katalogisering, klassifisering',
                  },
                  {
                    id: 'PDC',
                    label: 'Vitenskapelig nomenklatur og klassifisering',
                    originalLabel:
                      'Vitenskapelig nomenklatur og klassifisering',
                    note: 'Brukes med P*, M*, T* for bøker om klassifisering innen ulike vitenskapsområder, f.eks. med MJC for klassifikasjon av sykdommer (nosologi). Se også GLK <a href="/thema/nb/GLK">Bibliografisk- og emneordskontroll</a>, PSAB <a href="/thema/nb/PSAB">Taksonomi og systematikk</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'PSAD',
                label: 'Bioetikk',
                originalLabel: 'Bioetikk',
                alternativeLabels: [],
                note: 'Her: etiske spørsmål knyttet til ny utvikling innen biologi og medisin. Se også JBFV <a href="/thema/nb/JBFV">Etiske spørsmål</a>, MBDC <a href="/thema/nb/MBDC">Medisinsk etikk og yrkesutøvelse</a>',
                related: [
                  {
                    id: 'JBFV',
                    label: 'Etiske spørsmål',
                    originalLabel: 'Etiske spørsmål',
                    note: 'Brukes med andre kategorier for bøker som behandler et tema fra en etisk synsvinkel. Se også PSAD <a href="/thema/nb/PSAD">Bioetikk</a>, QDTQ <a href="/thema/nb/QDTQ">Etikk og moralfilosofi</a>',
                  },
                  {
                    id: 'MBDC',
                    label: 'Medisinsk etikk og yrkesutøvelse',
                    originalLabel: 'Medisinsk etikk og yrkesutøvelse',
                  },
                ],
                children: [],
              },
              {
                id: 'PSAF',
                label: 'Økologi, biosfæren',
                originalLabel: 'Økologi, biosfæren',
                alternativeLabels: ['biomer / økosystemer'],
                note: 'Brukes for omfattende vitenskapelige, objektive og beskrivende verk om levende organismer og deres forhold til omgivelsene. Se også RNC <a href="/thema/nb/RNC">Anvendt økologi</a>',
                related: [
                  {
                    id: 'RNC',
                    label: 'Anvendt økologi',
                    originalLabel: 'Anvendt økologi',
                    note: 'Brukes for verk som har en bred eller saksbasert tilnærming. Se også PSAF <a href="/thema/nb/PSAF">Økologi, biosfæren</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'PSAG',
                label: 'Xenobiotika',
                originalLabel: 'Xenobiotika',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PSAJ',
                label: 'Evolusjon',
                originalLabel: 'Evolusjon',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PSAK',
                label: 'Genetikk (ikke-medisinsk)',
                originalLabel: 'Genetikk (ikke-medisinsk)',
                alternativeLabels: [],
                note: 'Her: DNA, genom, RNA, transkriptom, genetiske ressurser, genomikk, epigenetikk etc. Se også MFN <a href="/thema/nb/MFN">Medisinsk genetikk</a>, TCBG <a href="/thema/nb/TCBG">Gen- og celleteknologi</a>',
                related: [
                  {
                    id: 'MFN',
                    label: 'Medisinsk genetikk',
                    originalLabel: 'Medisinsk genetikk',
                  },
                  {
                    id: 'TCBG',
                    label: 'Gen- og celleteknologi',
                    originalLabel: 'Gen- og celleteknologi',
                    note: 'Her: kloning, stamcelleteknologi, genmodifisering, genomredigering. Se også PSAD <a href="/thema/nb/PSAD">Bioetikk</a>, PSAK <a href="/thema/nb/PSAK">Genetikk (ikke-medisinsk)</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'PSAN',
                label: 'Nevrovitenskap',
                originalLabel: 'Nevrovitenskap',
                alternativeLabels: [],
                note: 'Bruk MKMT6 «Kognitiv atferdsterapi» for terapeutiske aspekter av nevrovitenskap. Her nevrobiologi. Se også GTK <a href="/thema/nb/GTK">Kognitive studier</a>, MKJ <a href="/thema/nb/MKJ">Nevrologi og klinisk nevrofysiologi</a>, MKMT6 <a href="/thema/nb/MKMT6">Kognitiv adferdsterapi</a>',
                related: [
                  {
                    id: 'GTK',
                    label: 'Kognitive studier',
                    originalLabel: 'Kognitive studier',
                    note: 'Brukes for tverrfaglig arbeid. Se også QDTM <a href="/thema/nb/QDTM">Bevissthetsfilosofi</a>, JM* <a href="/thema/nb/JM">Psykologi</a> og dens underkategorier, MKJ <a href="/thema/nb/MKJ">Nevrologi og klinisk nevrofysiologi</a>, PSAN <a href="/thema/nb/PSAN">Nevrovitenskap</a>, UYQ* <a href="/thema/nb/UYQ">Kunstig intelligens</a> og dens underkategorier, UXJ <a href="/thema/nb/UXJ">Dataprogram for pedagogikk, psykologi, sosiologi og samfunnsfag</a>',
                  },
                  {
                    id: 'MKJ',
                    label: 'Nevrologi og klinisk nevrofysiologi',
                    originalLabel: 'Nevrologi og klinisk nevrofysiologi',
                    note: 'Brukes for bøker om nevrologiske og nevrodegenerative sykdommer og lidelser, og sykdommer i nervesystemet',
                  },
                  {
                    id: 'MKMT6',
                    label: 'Kognitiv adferdsterapi',
                    originalLabel: 'Kognitiv adferdsterapi',
                  },
                ],
                children: [
                  {
                    id: 'PSAN1',
                    label: 'Cellulær og molekylær nevrovitenskap',
                    originalLabel: 'Cellulær og molekylær nevrovitenskap',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'PSAN2',
                    label: 'Nevroutvikling',
                    originalLabel: 'Nevroutvikling',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'PSAN3',
                    label: 'Nevroradiologi og nevroanatomi',
                    originalLabel: 'Nevroradiologi og nevroanatomi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'PSAN4',
                    label: 'Det somatiske nervesystem',
                    originalLabel: 'Det somatiske nervesystem',
                    alternativeLabels: [],
                    note: 'Her: sanser og motorikk',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'PSAN5',
                    label: 'Kognitiv nevrovitenskap og biopsykologi',
                    originalLabel: 'Kognitiv nevrovitenskap og biopsykologi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'PSAX',
                label: 'Bioinformatikk',
                originalLabel: 'Bioinformatikk',
                alternativeLabels: [],
                note: 'Se også MBF <a href="/thema/nb/MBF">Medisinsk- og helsefaglig informatikk</a>',
                related: [
                  {
                    id: 'MBF',
                    label: 'Medisinsk- og helsefaglig informatikk',
                    originalLabel: 'Medisinsk- og helsefaglig informatikk',
                    note: 'Her: bruk av kunstig intelligens i helsevesenet, elektronisk pasientjournal. Brukes for bøker som handler om design, utvikling og bruk av digitale systemer for å forbedre helsevesenet. Se også PSAX <a href="/thema/nb/PSAX">Bioinformatikk</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'PSB',
            label: 'Biokjemi',
            originalLabel: 'Biokjemi',
            alternativeLabels: ['stoffskifte'],
            note: 'Her: biokjemisk immunologi, ikke-medisinsk toksikologi, enzymologi, lipider, metabolisme, biomolekyler, proteiner etc.',
            related: [],
            children: [
              {
                id: 'PSBD',
                label: 'Fytokjemi',
                originalLabel: 'Fytokjemi',
                alternativeLabels: [],
                note: 'Her: fytokjemikalier, farmakognosi . Brukes med MKG for bøker ment for farmasøyter',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PSC',
            label: 'Utviklingsbiologi',
            originalLabel: 'Utviklingsbiologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PSD',
            label: 'Molekylærbiologi',
            originalLabel: 'Molekylærbiologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PSE',
            label: 'Kjemisk biologi',
            originalLabel: 'Kjemisk biologi',
            alternativeLabels: [],
            note: 'Her: syntetisk biologi',
            related: [],
            children: [],
          },
          {
            id: 'PSF',
            label: 'Cellebiologi (cytologi)',
            originalLabel: 'Cellebiologi (cytologi)',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'PSG',
            label: 'Mikrobiologi (ikke-medisinsk)',
            originalLabel: 'Mikrobiologi (ikke-medisinsk)',
            alternativeLabels: ['chromista'],
            note: 'Her: bakterier, arkebakterier, gulbrune alger, eggsporesopper, protistologi, protister, slimsopper, ikke-medisinsk bakteriologi, parasittologi, virologi etc.',
            related: [],
            children: [
              {
                id: 'PSGN',
                label: 'Protozoer',
                originalLabel: 'Protozoer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PSP',
            label: 'Hydrobiologi',
            originalLabel: 'Hydrobiologi',
            alternativeLabels: [],
            note: 'Se også RBK <a href="/thema/nb/RBK">Hydrosfære og hydrologi</a>',
            related: [
              {
                id: 'RBK',
                label: 'Hydrosfære og hydrologi',
                originalLabel: 'Hydrosfære og hydrologi',
                note: 'Se også PSP <a href="/thema/nb/PSP">Hydrobiologi</a>',
              },
            ],
            children: [
              {
                id: 'PSPA',
                label: 'Fykologi (alger og tang)',
                originalLabel: 'Fykologi (alger og tang)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PSPF',
                label: 'Ferskvannsbiologi',
                originalLabel: 'Ferskvannsbiologi',
                alternativeLabels: [],
                note: 'Se også RBKF <a href="/thema/nb/RBKF">Limnologi (innsjøer og rennende vann)</a>',
                related: [
                  {
                    id: 'RBKF',
                    label: 'Limnologi (innsjøer og rennende vann)',
                    originalLabel: 'Limnologi (innsjøer og rennende vann)',
                    note: 'Her: elver, ferskvanns- og saltsjøer, etc. Se også PSPF <a href="/thema/nb/PSPF">Ferskvannsbiologi</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'PSPM',
                label: 'Marinbiologi',
                originalLabel: 'Marinbiologi',
                alternativeLabels: [],
                note: 'Her: saltvannsbiologi. Se også RBKC <a href="/thema/nb/RBKC">Oseanografi (hav)</a>',
                related: [
                  {
                    id: 'RBKC',
                    label: 'Oseanografi (hav)',
                    originalLabel: 'Oseanografi (hav)',
                    note: 'Se også PSPM <a href="/thema/nb/PSPM">Marinbiologi</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'PSQ',
            label: 'Mykologi, sopp',
            originalLabel: 'Mykologi, sopp',
            alternativeLabels: ['sopper', 'gjær'],
            note: 'Her: lav, lichenologi, mykorrhiza',
            related: [],
            children: [],
          },
          {
            id: 'PST',
            label: 'Botanikk og plantevitenskap',
            originalLabel: 'Botanikk og plantevitenskap',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'PSTB',
                label: 'Plantebiologi',
                originalLabel: 'Plantebiologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'PSTH',
                label: 'Blomsterplanter',
                originalLabel: 'Blomsterplanter',
                alternativeLabels: ['angiospermae', 'angiospermia'],

                related: [],
                children: [],
              },
              {
                id: 'PSTJ',
                label: 'Bartrær og nakenfrøede planter',
                originalLabel: 'Bartrær og nakenfrøede planter',
                alternativeLabels: ['nåletrær'],
                note: 'Her: konglepalmer, tempeltre og gnetophyta',
                related: [],
                children: [],
              },
              {
                id: 'PSTM',
                label: 'Bregner og mose',
                originalLabel: 'Bregner og mose',
                alternativeLabels: [],
                note: 'Her: levermose, kråkefotplanter, nålkapselmoser',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PSV',
            label: 'Zoologi og dyrevitenskap',
            originalLabel: 'Zoologi og dyrevitenskap',
            alternativeLabels: ['patologi hos dyr'],
            note: 'Her: fysiologi, forplantning, sykdommer hos dyr. Se også WNC <a href="/thema/nb/WNC">Ville dyr</a>, MZ* <a href="/thema/nb/MZ">Veterinærmedisin / dyremedisin</a> og dens underkategorier',
            related: [
              {
                id: 'MZ',
                label: 'Veterinærmedisin / dyremedisin',
                originalLabel: 'Veterinærmedisin / dyremedisin',
              },
              {
                id: 'WNC',
                label: 'Ville dyr',
                originalLabel: 'Ville dyr',
                note: 'Se også PSV* <a href="/thema/nb/PSV">Zoologi og dyrevitenskap</a> og dens underkategorier',
              },
            ],
            children: [
              {
                id: 'PSVA',
                label: 'Zoologi: virvelløse dyr',
                originalLabel: 'Zoologi: virvelløse dyr',
                alternativeLabels: ['sjøpinnsvin', 'mangeføttinger'],
                note: 'Her: leddormer, mangefotinger, pigghuder (sjøstjerner, sjøpiggsvin), maneter. Se også WNCN <a href="/thema/nb/WNCN">Ville dyr: insekter, sommerfugler og edderkopper</a>',
                related: [
                  {
                    id: 'WNCN',
                    label: 'Insekter, sommerfugler og edderkopper',
                    originalLabel:
                      'Ville dyr: insekter, sommerfugler og edderkopper',
                    note: 'Se også PSVA <a href="/thema/nb/PSVA">Zoologi: virvelløse dyr</a>',
                  },
                ],
                children: [
                  {
                    id: 'PSVA2',
                    label: 'Insekter',
                    originalLabel: 'Insekter',
                    alternativeLabels: [
                      'bier',
                      'veps',
                      'maur',
                      'biller',
                      'termitter',
                      'fluer',
                      'mygg',
                      'gresshopper',
                    ],
                    note: 'Her: entomologi, larver, sommerfugler, møll',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'PSVA4',
                    label: 'Krepsdyr',
                    originalLabel: 'Krepsdyr',
                    alternativeLabels: ['skalldyr'],
                    note: 'Her: karsinologi, krabber, hummere, ferskvannskreps, reker, krill, skrukketroll, rankeføttinger etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'PSVA6',
                    label: 'Bløtdyr',
                    originalLabel: 'Bløtdyr',
                    alternativeLabels: [],
                    note: 'Her: malakologi, blekkspruter, snegler, muslinger, østers, hjerteskjell, kamskjell etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'PSVA8',
                    label: 'Edderkoppdyr',
                    originalLabel: 'Edderkoppdyr',
                    alternativeLabels: [],
                    note: 'Her: araknologi, edderkopper, skorpioner, flått, midd etc.',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'PSVC',
                label: 'Zoologi: fisk',
                originalLabel: 'Zoologi: fisk',
                alternativeLabels: ['hai'],
                note: 'Her: iktyologi. Se også WNCS <a href="/thema/nb/WNCS">Ville dyr: dyr som lever i vann</a>',
                related: [
                  {
                    id: 'WNCS',
                    label: 'Dyr som lever i vann',
                    originalLabel: 'Ville dyr: dyr som lever i vann',
                    note: 'Se også PSP <a href="/thema/nb/PSP">Hydrobiologi</a>, PSVC <a href="/thema/nb/PSVC">Zoologi: fisk</a>, PSVM2 <a href="/thema/nb/PSVM2">Zoologi: sjøpattedyr</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'PSVF',
                label: 'Zoologi: amfibier og reptiler',
                originalLabel: 'Zoologi: amfibier og reptiler',
                alternativeLabels: [],
                note: 'Her: herpetologi. Se også WNCK <a href="/thema/nb/WNCK">Ville dyr: reptiler og amfibier</a>',
                related: [
                  {
                    id: 'WNCK',
                    label: 'Reptiler og amfibier',
                    originalLabel: 'Ville dyr: reptiler og amfibier',
                    note: 'Se også PSVF <a href="/thema/nb/PSVF">Zoologi: amfibier og reptiler</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'PSVJ',
                label: 'Zoologi: fugler',
                originalLabel: 'Zoologi: fugler',
                alternativeLabels: [],
                note: 'Her: ornitologi. Se også WNCB <a href="/thema/nb/WNCB">Ville dyr: fugler og fugletitting</a>',
                related: [
                  {
                    id: 'WNCB',
                    label: 'Fugler og fugletitting',
                    originalLabel: 'Ville dyr: fugler og fugletitting',
                    note: 'Se også PSVJ <a href="/thema/nb/PSVJ">Zoologi: fugler</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'PSVM',
                label: 'Zoologi: pattedyr',
                originalLabel: 'Zoologi: pattedyr',
                alternativeLabels: [],
                note: 'Her: mammalogi. Se også WNCF <a href="/thema/nb/WNCF">Ville dyr: pattedyr</a>',
                related: [
                  {
                    id: 'WNCF',
                    label: 'Pattedyr',
                    originalLabel: 'Ville dyr: pattedyr',
                    note: 'Se også PSVM <a href="/thema/nb/PSVM">Zoologi: pattedyr</a>',
                  },
                ],
                children: [
                  {
                    id: 'PSVM1',
                    label: 'Zoologi: pungdyr og kloakkdyr',
                    originalLabel: 'Zoologi: pungdyr og kloakkdyr',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'PSVM2',
                    label: 'Zoologi: sjøpattedyr',
                    originalLabel: 'Zoologi: sjøpattedyr',
                    alternativeLabels: [],
                    note: 'Her: hvaler, delfiner, sjøkuer etc. Brukes for marine og semimarine pattedyr, både i saltvann og ferskvann. Se også PSVM <a href="/thema/nb/PSVM">Zoologi: pattedyr</a>, WNCS <a href="/thema/nb/WNCS">Ville dyr: dyr som lever i vann</a>',
                    related: [
                      {
                        id: 'PSVM',
                        label: 'Zoologi: pattedyr',
                        originalLabel: 'Zoologi: pattedyr',
                        note: 'Her: mammalogi. Se også WNCF <a href="/thema/nb/WNCF">Ville dyr: pattedyr</a>',
                      },
                      {
                        id: 'WNCS',
                        label: 'Dyr som lever i vann',
                        originalLabel: 'Ville dyr: dyr som lever i vann',
                        note: 'Se også PSP <a href="/thema/nb/PSP">Hydrobiologi</a>, PSVC <a href="/thema/nb/PSVC">Zoologi: fisk</a>, PSVM2 <a href="/thema/nb/PSVM2">Zoologi: sjøpattedyr</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'PSVM3',
                    label: 'Zoologi: primater',
                    originalLabel: 'Zoologi: primater',
                    alternativeLabels: [],
                    note: 'Her: primatologi, lemurer, aper',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'PSVP',
                label: 'Adferdsbiologi og dyrs oppførsel',
                originalLabel: 'Adferdsbiologi og dyrs oppførsel',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'PSX',
            label: 'Humanbiologi',
            originalLabel: 'Humanbiologi',
            alternativeLabels: ['skjelettet'],
            note: 'Brukes for bøker om menneskekroppen og kroppsdeler, eller som handler om menneskets biologi eller anatomi fra et ikke-medisinsk utgangspunkt, bøker om menneskeskjelettet, blod, muskler, hjernen, innvollene, tenner, hår, kjønn etc.',
            related: [],
            children: [
              {
                id: 'PSXE',
                label: 'Evolusjonsantropologi / Menneskets evolusjon',
                originalLabel: 'Evolusjonsantropologi / Menneskets evolusjon',
                alternativeLabels: [],
                note: 'Her: Paleoantropologi, det tidlige mennesket, hominider, menneskets forfedre og arkaiske mennesker, biologisk og fysisk antropologi, antropogeni. Brukes med 3A* eller 3B* for tidsperiode der det er hensiktsmessig, med NK* for studier innen arkeologi',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'Q',
    label: 'Filosofi og religion',
    originalLabel: 'Filosofi og religion',
    alternativeLabels: [],
    note: 'Bruk Q* for både akademiske utgivelser, samt utgivelser for profesjons- og allmennmarkedet. IKKE bruk koden ‘Q’, men velg spesifikke kategorier fra Q*. QRA*- og QRV*-koder bør brukes sammen med andre QR*-koder for å angi hvilken religion det er snakk om. Se også VX* <a href="/thema/nb/VX">Sinn, kropp, sjel</a> og dens underkategorier',
    related: [
      {
        id: 'VX',
        label: 'Sinn, kropp, sjel',
        originalLabel: 'Sinn, kropp, sjel',
        note: 'Bruk VX*-koder bare på bøker for allmennmarkedet. Foretrekk passende koder fra seksjonene J, M, Q, etc. på bøker for høyere utdanning og profesjonsmarkedet',
      },
    ],
    children: [
      {
        id: 'QD',
        label: 'Filosofi',
        originalLabel: 'Filosofi',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'QDH',
            label: 'Filosofiske tradisjoner og skoler',
            originalLabel: 'Filosofiske tradisjoner og skoler',
            alternativeLabels: [],
            note: 'Her: filosofihistorie. Bruk QHD*-koder sammen med andre Q*-koder og relevante kvalifikatorer for sted og tid der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'QDHA',
                label: 'Antikkens filosofi',
                originalLabel: 'Antikkens filosofi',
                alternativeLabels: [],
                note: 'Her: gresk og romersk filosofi; Sokrates, Platon, Aristoteles, Pytagoras, førsokratisk og hellenistisk filosofi, stoisisme, sofisme, kynisme, epikurisme og andre skoler innen antikkens filosofi',
                related: [],
                children: [],
              },
              {
                id: 'QDHC',
                label: 'Østasiatisk og indisk filosofi',
                originalLabel: 'Østasiatisk og indisk filosofi',
                alternativeLabels: [
                  'kinesisk filosofi',
                  'japansk filosofi',
                  'koreansk filosofi',
                  'legalisme',
                  'mohisme',
                  'taoisme',
                  'konfusianisme',
                  'vedaene',
                ],
                note: 'Se også QRRL1 <a href="/thema/nb/QRRL1">Konfusianisme</a>, QRRL5 <a href="/thema/nb/QRRL5">Taoisme</a>',
                related: [
                  {
                    id: 'QRRL1',
                    label: 'Konfusianisme',
                    originalLabel: 'Konfusianisme',
                  },
                  {
                    id: 'QRRL5',
                    label: 'Taoisme',
                    originalLabel: 'Taoisme',
                  },
                ],
                children: [
                  {
                    id: 'QDHC2',
                    label: 'Yoga (som filosofi)',
                    originalLabel: 'Yoga (som filosofi)',
                    alternativeLabels: [],
                    note: 'Se også VFMG1 <a href="/thema/nb/VFMG1">Yoga som trening</a>',
                    related: [
                      {
                        id: 'VFMG1',
                        label: 'Yoga som trening',
                        originalLabel: 'Yoga som trening',
                        note: 'Se også QDHC2 <a href="/thema/nb/QDHC2">Yoga (som filosofi)</a>',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'QDHF',
                label: 'Middelalderens filosofi',
                originalLabel: 'Middelalderens filosofi',
                alternativeLabels: [
                  'metafysikk',
                  'Boëthius',
                  'Augustin av Hippo',
                  'Anselm',
                  'thomisme',
                ],
                note: 'Her: vestlig filosofi i middelalderen, skolastikk, Aquinas etc.',
                related: [],
                children: [],
              },
              {
                id: 'QDHH',
                label: 'Humanisme',
                originalLabel: 'Humanisme',
                alternativeLabels: [],
                note: 'Her: renessansehumanisme, Erasmus. Se også QRYA <a href="/thema/nb/QRYA">Humanistiske og sekulære alternativ til religion</a>',
                related: [
                  {
                    id: 'QRYA',
                    label: 'Humanistiske og sekulære alternativ til religion',
                    originalLabel:
                      'Humanistiske og sekulære alternativ til religion',
                    note: 'Her: humanetikk, sekularisme. Se også QDHH <a href="/thema/nb/QDHH">Humanisme</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'QDHK',
                label: 'Islamsk og arabisk filosofi',
                originalLabel: 'Islamsk og arabisk filosofi',
                alternativeLabels: [],
                note: 'Se også QRP* <a href="/thema/nb/QRP">Islam</a> og dens underkategorier',
                related: [
                  {
                    id: 'QRP',
                    label: 'Islam',
                    originalLabel: 'Islam',
                  },
                ],
                children: [],
              },
              {
                id: 'QDHL',
                label: 'Jødisk filosofi',
                originalLabel: 'Jødisk filosofi',
                alternativeLabels: [],
                note: 'Her: Maimonides',
                related: [],
                children: [],
              },
              {
                id: 'QDHM',
                label: 'Vestlig filosofi: opplysningstiden',
                originalLabel: 'Vestlig filosofi: opplysningstiden',
                alternativeLabels: [],
                note: 'Her: erfaringsfilosofi, empirisme, rasjonalisme, Descartes til Kant',
                related: [],
                children: [],
              },
              {
                id: 'QDHP',
                label: 'Afrikansk filosofi',
                originalLabel: 'Afrikansk filosofi',
                alternativeLabels: [],
                note: 'Her: afrikansk filosofi og kritisk teori',
                related: [],
                children: [],
              },
              {
                id: 'QDHR',
                label: 'Moderne filosofi: etter 1800',
                originalLabel: 'Moderne filosofi: etter 1800',
                alternativeLabels: [
                  'sosialkonstruksjonisme',
                  'postmoderne filosofi',
                  'dekonstruksjon',
                  'eksperimenterende filosofi',
                  'språkfilosofi',
                ],
                note: 'Her: tysk idealisme, kontinental filosofi. Brukes for bøker om moderne, vestlig filosofi i perioden fra begynnelsen av 1800-tallet og fram til i dag',
                related: [],
                children: [
                  {
                    id: 'QDHR1',
                    label: 'Idealisme',
                    originalLabel: 'Idealisme',
                    alternativeLabels: [],
                    note: 'Her: tysk idealisme, subjektiv eller objektiv idealisme',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QDHR3',
                    label: 'Pragmatisme',
                    originalLabel: 'Pragmatisme',
                    alternativeLabels: [],
                    note: 'Her: pragmatikerne',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QDHR5',
                    label: 'Fenomenologi og eksistensialisme',
                    originalLabel: 'Fenomenologi og eksistensialisme',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QDHR7',
                    label: 'Strukturalisme og poststrukturalisme',
                    originalLabel: 'Strukturalisme og poststrukturalisme',
                    alternativeLabels: [],
                    note: 'Her: dekonstruksjon, strukturalistene',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QDHR9',
                    label: 'Analytisk filosofi og logisk positivisme',
                    originalLabel: 'Analytisk filosofi og logisk positivisme',
                    alternativeLabels: [],
                    note: 'Her: positivistene, nypositivisme, logisk empirisme',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'QDT',
            label: 'Emner i filosofi',
            originalLabel: 'Emner i filosofi',
            alternativeLabels: [],
            note: 'Se også CFA <a href="/thema/nb/CFA">Språkfilosofi</a>, JNA <a href="/thema/nb/JNA">Pedagogisk filosofi og teori</a>, KCA <a href="/thema/nb/KCA">Økonomisk teori og filosofi</a>, PBB <a href="/thema/nb/PBB">Matematikkens filosofi</a>, PDA <a href="/thema/nb/PDA">Vitenskapsfilosofi</a>',
            related: [
              {
                id: 'CFA',
                label: 'Språkfilosofi',
                originalLabel: 'Språkfilosofi',
                note: 'Se også GTD <a href="/thema/nb/GTD">Semiotikk / semiologi</a>',
              },
              {
                id: 'JNA',
                label: 'Pedagogisk filosofi og teori',
                originalLabel: 'Pedagogisk filosofi og teori',
                note: 'Her: perennialisme, essensialisme, kritisk pedagogikk, demokratisk dannelse, kritisk tenkning i utdanning, teorier om ulike læringsstiler, etc.',
              },
              {
                id: 'KCA',
                label: 'Økonomisk teori og filosofi',
                originalLabel: 'Økonomisk teori og filosofi',
                note: 'Inkluderer økonomifagets historie. Tildel også kvalifikator for tidsperiode der det er hensiktsmessig',
              },
              {
                id: 'PBB',
                label: 'Matematikkens filosofi',
                originalLabel: 'Matematikkens filosofi',
              },
              {
                id: 'PDA',
                label: 'Vitenskapsfilosofi',
                originalLabel: 'Vitenskapsfilosofi',
              },
            ],
            children: [
              {
                id: 'QDTJ',
                label: 'Filosofi: metafysikk og ontologi',
                originalLabel: 'Filosofi: metafysikk og ontologi',
                alternativeLabels: [],
                note: 'Se også QRAB <a href="/thema/nb/QRAB">Religionsfilosofi</a>',
                related: [
                  {
                    id: 'QRAB',
                    label: 'Religionsfilosofi',
                    originalLabel: 'Religionsfilosofi',
                    note: 'Brukes for bøker som handler om filosofiske undersøkelser av sentrale emner og konsepter i ulike religiøse tradisjoner eller religionens natur',
                  },
                ],
                children: [],
              },
              {
                id: 'QDTK',
                label: 'Filosofi: erkjennelsesteori og kunnskapsteori',
                originalLabel: 'Filosofi: erkjennelsesteori og kunnskapsteori',
                alternativeLabels: [],
                note: 'Her: epistemologi',
                related: [],
                children: [],
              },
              {
                id: 'QDTL',
                label: 'Filosofi: logikk',
                originalLabel: 'Filosofi: logikk',
                alternativeLabels: [],
                note: 'Her: formell, uformell eller symbolsk logikk, kritisk tenkning',
                related: [],
                children: [],
              },
              {
                id: 'QDTM',
                label: 'Bevissthetsfilosofi',
                originalLabel: 'Bevissthetsfilosofi',
                alternativeLabels: [],
                note: 'Her: dualisme, monisme, nevrofilosofi, bevissthet. Se også JMA <a href="/thema/nb/JMA">Psykologisk teori, systemer, synspunkter og skoleretninger</a>, GTK <a href="/thema/nb/GTK">Kognitive studier</a>',
                related: [
                  {
                    id: 'GTK',
                    label: 'Kognitive studier',
                    originalLabel: 'Kognitive studier',
                    note: 'Brukes for tverrfaglig arbeid. Se også QDTM <a href="/thema/nb/QDTM">Bevissthetsfilosofi</a>, JM* <a href="/thema/nb/JM">Psykologi</a> og dens underkategorier, MKJ <a href="/thema/nb/MKJ">Nevrologi og klinisk nevrofysiologi</a>, PSAN <a href="/thema/nb/PSAN">Nevrovitenskap</a>, UYQ* <a href="/thema/nb/UYQ">Kunstig intelligens</a> og dens underkategorier, UXJ <a href="/thema/nb/UXJ">Dataprogram for pedagogikk, psykologi, sosiologi og samfunnsfag</a>',
                  },
                  {
                    id: 'JMA',
                    label:
                      'Psykologisk teori, systemer, synspunkter og skoleretninger',
                    originalLabel:
                      'Psykologisk teori, systemer, synspunkter og skoleretninger',
                    note: 'Se også QDTM <a href="/thema/nb/QDTM">Bevissthetsfilosofi</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'QDTN',
                label: 'Filosofi: estetikk',
                originalLabel: 'Filosofi: estetikk',
                alternativeLabels: [],
                note: 'Her: debatter om skjønnhet og smak, kunstfilosofi. Se også ABA <a href="/thema/nb/ABA">Kunstteori</a>',
                related: [
                  {
                    id: 'ABA',
                    label: 'Kunstteori',
                    originalLabel: 'Kunstteori',
                    note: 'Se også QDTN <a href="/thema/nb/QDTN">Filosofi: estetikk</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'QDTQ',
                label: 'Etikk og moralfilosofi',
                originalLabel: 'Etikk og moralfilosofi',
                alternativeLabels: [],
                note: 'Her: aksiologi, normativ etikk, anvendt etikk, rett og gal oppførsel, deontologi, det gode og ondes problem, utilitarisme, teologisk etikk. Se også JBFV <a href="/thema/nb/JBFV">Etiske spørsmål</a>, KJG <a href="/thema/nb/KJG">Etikk og sosialt ansvar i næringslivet</a>, LATC <a href="/thema/nb/LATC">Rettsetikk og yrkesutøvelse</a>, MBDC <a href="/thema/nb/MBDC">Medisinsk etikk og yrkesutøvelse</a>, PSAD <a href="/thema/nb/PSAD">Bioetikk</a>',
                related: [
                  {
                    id: 'JBFV',
                    label: 'Etiske spørsmål',
                    originalLabel: 'Etiske spørsmål',
                    note: 'Brukes med andre kategorier for bøker som behandler et tema fra en etisk synsvinkel. Se også PSAD <a href="/thema/nb/PSAD">Bioetikk</a>, QDTQ <a href="/thema/nb/QDTQ">Etikk og moralfilosofi</a>',
                  },
                  {
                    id: 'KJG',
                    label: 'Etikk og sosialt ansvar i næringslivet',
                    originalLabel: 'Etikk og sosialt ansvar i næringslivet',
                  },
                  {
                    id: 'LATC',
                    label: 'Rettsetikk og yrkesutøvelse',
                    originalLabel: 'Rettsetikk og yrkesutøvelse',
                  },
                  {
                    id: 'MBDC',
                    label: 'Medisinsk etikk og yrkesutøvelse',
                    originalLabel: 'Medisinsk etikk og yrkesutøvelse',
                  },
                  {
                    id: 'PSAD',
                    label: 'Bioetikk',
                    originalLabel: 'Bioetikk',
                    note: 'Her: etiske spørsmål knyttet til ny utvikling innen biologi og medisin. Se også JBFV <a href="/thema/nb/JBFV">Etiske spørsmål</a>, MBDC <a href="/thema/nb/MBDC">Medisinsk etikk og yrkesutøvelse</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'QDTS',
                label: 'Sosial og politisk filosofi',
                originalLabel: 'Sosial og politisk filosofi',
                alternativeLabels: [],
                note: 'Se også JPA <a href="/thema/nb/JPA">Politisk forskning og teori</a>, JPF <a href="/thema/nb/JPF">Politiske ideologier og bevegelser</a>, LAB <a href="/thema/nb/LAB">Rettsmetoder, -teori og -filosofi</a>',
                related: [
                  {
                    id: 'JPA',
                    label: 'Politisk forskning og teori',
                    originalLabel: 'Politisk forskning og teori',
                    note: 'Her: statsvitenskap. Se også QDTS <a href="/thema/nb/QDTS">Sosial og politisk filosofi</a>',
                  },
                  {
                    id: 'JPF',
                    label: 'Politiske ideologier og bevegelser',
                    originalLabel: 'Politiske ideologier og bevegelser',
                    note: 'Use for: works on different parts of the political spectrum, or political ideologies or movements that do not have more precise codes',
                  },
                  {
                    id: 'LAB',
                    label: 'Rettsmetoder, -teori og -filosofi',
                    originalLabel: 'Rettsmetoder, -teori og -filosofi',
                    note: 'Her: analytisk og normativ rettsvitenskap, naturrett, rettspositivisme, rettsrealisme, filosofiske tilnærmingsmåter til juridiske problemer, generelle rettsprinsipper, lovarbeidsteori etc.',
                  },
                ],
                children: [
                  {
                    id: 'QDTS1',
                    label: 'Kritisk teori',
                    originalLabel: 'Kritisk teori',
                    alternativeLabels: [],
                    note: 'Her: frankfurterskolen. Brukes med J* for sosiologisk tilnærming til emnet eller ulike filosofiske skoler',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'QDX',
            label: 'Populærfilosofi',
            originalLabel: 'Populærfilosofi',
            alternativeLabels: [],
            note: 'Brukes for faglitteratur for allmennmarkedet, verker som søker å bruke filosofiske prinsipper på hverdagslige situasjoner og problemer, eller bøker som forklarer filosofi for et bredt publikum. Brukes med QD* for bøker som har en populærvitenskapelig tilnærming til ulike filosofiske emner. Se også JBFV <a href="/thema/nb/JBFV">Etiske spørsmål</a>, VSP <a href="/thema/nb/VSP">Populærpsykologi</a>',
            related: [
              {
                id: 'JBFV',
                label: 'Etiske spørsmål',
                originalLabel: 'Etiske spørsmål',
                note: 'Brukes med andre kategorier for bøker som behandler et tema fra en etisk synsvinkel. Se også PSAD <a href="/thema/nb/PSAD">Bioetikk</a>, QDTQ <a href="/thema/nb/QDTQ">Etikk og moralfilosofi</a>',
              },
              {
                id: 'VSP',
                label: 'Populærpsykologi',
                originalLabel: 'Populærpsykologi',
                note: 'Se også QDX <a href="/thema/nb/QDX">Populærfilosofi</a>',
              },
            ],
            children: [
              {
                id: 'QDXB',
                label: 'Populærfilosofi: meningen med livet',
                originalLabel: 'Populærfilosofi: meningen med livet',
                alternativeLabels: [],
                note: 'Her: det å finne mening i livet. Brukes for bøker som bruker filosofiske prinsipper som et verktøy for å kunne finne meningen med eller forstå ulike aspekter av dagliglivet, vanligvis med en populærvitenskapelig tilnærming. Foretrekk VSP* eller VX* for bøker som tar for seg dette temaet fra en psykologisk eller spirituell vinkling',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'QR',
        label: 'Religion og tro',
        originalLabel: 'Religion og tro',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'QRA',
            label: 'Religion',
            originalLabel: 'Religion',
            alternativeLabels: [],
            note: 'Her: religionsstudier. Bruk alle QRA*-koder med andre QR*-koder for å angi hvilken religion det er snakk om',
            related: [],
            children: [
              {
                id: 'QRAB',
                label: 'Religionsfilosofi',
                originalLabel: 'Religionsfilosofi',
                alternativeLabels: [],
                note: 'Brukes for bøker som handler om filosofiske undersøkelser av sentrale emner og konsepter i ulike religiøse tradisjoner eller religionens natur',
                related: [],
                children: [
                  {
                    id: 'QRAB1',
                    label: 'Natur og Guds eksistens',
                    originalLabel: 'Natur og Guds eksistens',
                    alternativeLabels: [],
                    note: 'Brukes for bøker om tro på eller spørsmål rundt eksistensen av guddommer eller andre guddommelige vesener',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRAB7',
                    label: 'Religion: synd og frelse',
                    originalLabel: 'Religion: synd og frelse',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som handler om anger, frelse, forløsning, tilgivelse, eller forestillinger om synd og konsekvenser av denne',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRAB9',
                    label: 'Religion: eskatologi',
                    originalLabel: 'Religion: eskatologi',
                    alternativeLabels: [
                      'ragnarok',
                      'apokalypsen',
                      'armageddon',
                      'dommedag',
                      'hermeneutikk',
                    ],
                    note: 'Brukes for bøker som handler om verdens ende, endetiden, forestillinger om døden, dommedag, menneskesjelens og menneskehetens skjebne. Brukes med andre koder fra QR* for eskatologi i ulike religioner',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRAC',
                label: 'Sammenlignende religionsvitenskap',
                originalLabel: 'Sammenlignende religionsvitenskap',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'QRAF',
                label: 'Mellomreligiøse relasjoner',
                originalLabel: 'Mellomreligiøse relasjoner',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'QRAM',
                label: 'Religiøse problemstillinger og debatter',
                originalLabel: 'Religiøse problemstillinger og debatter',
                alternativeLabels: [],
                note: 'Bruk QRAM*-koder sammen med andre QR-koder for å angi hvilken religion det er snakk om, eller med QRAC dersom det er snakk om sammenligning',
                related: [],
                children: [
                  {
                    id: 'QRAM1',
                    label: 'Religionsetikk',
                    originalLabel: 'Religionsetikk',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som ser på ulike forestillinger om etikk i religion eller moral forbundet med religiøs tro, regler og lover som baserer seg på religiøse doktriner, rammeverk som regulerer personlig oppførsel, forestillinger om rett og galt basert på religiøse verdier, forestillinger om dydighet og manerer i religiøse tradisjoner etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRAM2',
                    label: 'Religion og politikk',
                    originalLabel: 'Religion og politikk',
                    alternativeLabels: [],
                    note: 'Her: religionsfrihet, samvittighetsfrihet, sekularisme, religionens rolle i politikk, bruk av religion for å oppnå politiske mål, statsreligioner, bruk av en religion for å skape lover og politiske strukturer, skillet mellom kirke og stat etc. Se også JPFR <a href="/thema/nb/JPFR">Religiøse og teokratiske ideologier</a>',
                    related: [
                      {
                        id: 'JPFR',
                        label: 'Religiøse og teokratiske ideologier',
                        originalLabel: 'Religiøse og teokratiske ideologier',
                        note: 'Her: teokrati. Brukes for bøker om religion som grunnlag for en politisk ideologi, identitet eller handlinger. Brukes med QR*-koder for å angi bestemte religioner der det er hensiktsmessig',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'QRAM3',
                    label: 'Religion og vitenskap',
                    originalLabel: 'Religion og vitenskap',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRAM6',
                    label: 'Religiøs fundamentalisme',
                    originalLabel: 'Religiøs fundamentalisme',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRAM7',
                    label: 'Blasfemi, kjetteri, apostasi',
                    originalLabel: 'Blasfemi, kjetteri, apostasi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRAM9',
                    label: 'Religiøs intoleranse, forfølgelse og konflikt',
                    originalLabel:
                      'Religiøs intoleranse, forfølgelse og konflikt',
                    alternativeLabels: [],
                    note: 'Brukes for bøker om diskriminering, intoleranser, vold eller forfølgelse av en religion eller innen en religion, av andre med ulik tro. Brukes med JBFA for tematikk rundt diskriminering i samfunnet basert på religiøs identitet, med JW* eller NHW* for religionskriger eller konflikter rundt tro',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRAX',
                label: 'Religionshistorie',
                originalLabel: 'Religionshistorie',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'QRD',
            label: 'Hinduisme',
            originalLabel: 'Hinduisme',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'QRDB',
                label: 'Hinduisme: retninger og grupper',
                originalLabel: 'Hinduisme: retninger og grupper',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'QRDF',
                label: 'Hinduisme: hellige skrifter',
                originalLabel: 'Hinduisme: hellige skrifter',
                alternativeLabels: [],
                note: 'Bruk QRDF* med QRVC for religionskritikk eller tolkning av hellige tekster',
                related: [],
                children: [
                  {
                    id: 'QRDF1',
                    label: 'Hinduistiske skrifter: Vedaene, Upanishadene',
                    originalLabel:
                      'Hinduistiske skrifter: Vedaene, Upanishadene',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRDF2',
                    label: 'Hinduistiske skrifter: Bhagavadgita',
                    originalLabel: 'Hinduistiske skrifter: Bhagavadgita',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRDP',
                label: 'Hinduisme: liv og lære',
                originalLabel: 'Hinduisme: liv og lære',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'QRF',
            label: 'Buddhisme',
            originalLabel: 'Buddhisme',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'QRFB',
                label: 'Buddhisme: retninger og grupper',
                originalLabel: 'Buddhisme: retninger og grupper',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'QRFB1',
                    label: 'Buddhisme: theravada',
                    originalLabel: 'Buddhisme: theravada',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRFB2',
                    label: 'Buddhisme: mahayana',
                    originalLabel: 'Buddhisme: mahayana',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: 'QRFB21',
                        label: 'Tibetansk buddhisme',
                        originalLabel: 'Tibetansk buddhisme',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'QRFB23',
                        label: 'Buddhisme: Zen',
                        originalLabel: 'Buddhisme: Zen',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'QRFF',
                label: 'Buddhisme: hellige skrifter',
                originalLabel: 'Buddhisme: hellige skrifter',
                alternativeLabels: [],
                note: 'Bruk QRFF* med QRVC for religionskritikk eller tolkning av hellige tekster',
                related: [],
                children: [],
              },
              {
                id: 'QRFP',
                label: 'Buddhisme: liv og lære',
                originalLabel: 'Buddhisme: liv og lære',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'QRJ',
            label: 'Jødedommen',
            originalLabel: 'Jødedommen',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'QRJB',
                label: 'Jødedommen: retninger og grupper',
                originalLabel: 'Jødedommen: retninger og grupper',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'QRJB1',
                    label: 'Ortodoks jødedom',
                    originalLabel: 'Ortodoks jødedom',
                    alternativeLabels: [],
                    note: 'Her: haredisk jødedom, moderne ortodoks jødedom, hasidisk jødedom',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRJB2',
                    label: 'Konservativ jødedom',
                    originalLabel: 'Konservativ jødedom',
                    alternativeLabels: [],
                    note: 'Her: masorti',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRJB3',
                    label: 'Liberal- og reformjødedom',
                    originalLabel: 'Liberal- og reformjødedom',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRJF',
                label: 'Jødedommen: hellige skrifter',
                originalLabel: 'Jødedommen: hellige skrifter',
                alternativeLabels: ['halakha', 'haggadáen', 'zohar'],
                note: 'Bruk QRJF* med QRVC for religionskritikk eller tolkning av hellige tekster',
                related: [],
                children: [
                  {
                    id: 'QRJF1',
                    label: 'Jødiske skrifter: Tanakh, Tora, Nevi’im, Ketuvim',
                    originalLabel:
                      'Jødiske skrifter: Tanakh, Tora, Nevi’im, Ketuvim',
                    alternativeLabels: ['Tanakh'],
                    note: 'Her: hebraisk bibel, de fem mosebøkene, mikra',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRJF5',
                    label: 'Rabbinsk litteratur',
                    originalLabel: 'Rabbinsk litteratur',
                    alternativeLabels: [],
                    note: 'Her: Talmud, Misjná',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRJP',
                label: 'Jødedommen: liv og lære',
                originalLabel: 'Jødedommen: liv og lære',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'QRM',
            label: 'Kristendommen',
            originalLabel: 'Kristendommen',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'QRMB',
                label: 'Kristne kirker, kirkesamfunn, grupper',
                originalLabel: 'Kristne kirker, kirkesamfunn, grupper',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'QRMB1',
                    label: 'Romersk katolisisme og romersk-katolske kirker',
                    originalLabel:
                      'Romersk katolisisme og romersk-katolske kirker',
                    alternativeLabels: [],
                    note: 'Her: orientalske katolske kirker, østlig ritus, den maronittiske kirke, den ukrainske gresk-katolske kirke, den syro-malabariske kirke, den melkittiske kirke, den kaldeisk-katolske kirke, den armensk-katolske kirke',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRMB2',
                    label: 'Ortodokse og orientalsk-ortodokse kirker',
                    originalLabel: 'Ortodokse og orientalsk-ortodokse kirker',
                    alternativeLabels: [],
                    note: 'Her: greske, russiske, koptiske, syriske kirker etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRMB3',
                    label: 'Protestantisme og protestantiske kirker',
                    originalLabel: 'Protestantisme og protestantiske kirker',
                    alternativeLabels: ['herrnhutere'],
                    note: 'Her: anabaptisme, amish, hutteritter, mennonitter, brødremenigheten',
                    related: [],
                    children: [
                      {
                        id: 'QRMB31',
                        label: 'Anglikanske og episkopale kirker',
                        originalLabel: 'Anglikanske og episkopale kirker',
                        alternativeLabels: [],
                        note: 'Her: Den engelske kirke, anglikanisme, The Anglican Communion etc.',
                        related: [],
                        children: [],
                      },
                      {
                        id: 'QRMB32',
                        label: 'Baptistkirker',
                        originalLabel: 'Baptistkirker',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'QRMB33',
                        label:
                          'Kalvinistiske, reformerte og presbyterianske kirker',
                        originalLabel:
                          'Kalvinistiske, reformerte og presbyterianske kirker',
                        alternativeLabels: [],
                        note: 'Her: Den skotske kirke, hugenotter, kalvinisme, kongregasjonalisme etc.',
                        related: [],
                        children: [],
                      },
                      {
                        id: 'QRMB34',
                        label: 'Evangelisk-lutherske kirker',
                        originalLabel: 'Evangelisk-lutherske kirker',
                        alternativeLabels: [],
                        note: 'Her: lutheranere, pietister, pietisme',
                        related: [],
                        children: [],
                      },
                      {
                        id: 'QRMB35',
                        label: 'Metodistkirker',
                        originalLabel: 'Metodistkirker',
                        alternativeLabels: [],
                        note: 'Her: metodisme',
                        related: [],
                        children: [],
                      },
                      {
                        id: 'QRMB36',
                        label: 'Pinsebevegelsens kirker',
                        originalLabel: 'Pinsebevegelsens kirker',
                        alternativeLabels: [],
                        note: 'Her: den pinsekarismatiske bevegelsen og lignende kirkesamfunn',
                        related: [],
                        children: [],
                      },
                      {
                        id: 'QRMB37',
                        label: 'Kvekerne (vennenes samfunn)',
                        originalLabel: 'Kvekerne (vennenes samfunn)',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'QRMB39',
                        label:
                          'Andre ikke-konformistiske og evangeliske kirker',
                        originalLabel:
                          'Andre ikke-konformistiske og evangeliske kirker',
                        alternativeLabels: [
                          'La Luz del Mundo',
                          'Iglesia ni Cristo',
                        ],
                        note: 'Her: shakere og andre ikke-trinitariske samfunn, andre ikke-konfesjonelle kristne kirker',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'QRMB5',
                    label: 'Kirkesamfunn av amerikansk opprinnelse',
                    originalLabel: 'Kirkesamfunn av amerikansk opprinnelse',
                    alternativeLabels: [],
                    note: 'Her: adventister, mormonere (siste dagers hellige), Jehovas vitner, Christian Science etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRMB8',
                    label: 'Kristne og kvasi-kristne kulter og sekter',
                    originalLabel: 'Kristne og kvasi-kristne kulter og sekter',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRMB9',
                    label: 'Økumenikk',
                    originalLabel: 'Økumenikk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRMF',
                label: 'Kristendommen: hellige skrifter',
                originalLabel: 'Kristendommen: hellige skrifter',
                alternativeLabels: [],
                note: 'Brukes for kanoniske og ikke-kanoniske tekster. Bruk QRMF* med QRVC for religionskritikk eller tolkning av hellige tekster. Foretrekk den relevante QRMF-koden for selve tekstene',
                related: [],
                children: [
                  {
                    id: 'QRMF1',
                    label: 'Bibelen',
                    originalLabel: 'Bibelen',
                    alternativeLabels: ['kanon', 'apokryfer'],
                    note: 'Her: alle versjoner av Bibelen. Brukes med QRVC for bibelkritikk eller -tolkning, bibelstudier, konkordanser og kommentarer',
                    related: [],
                    children: [
                      {
                        id: 'QRMF12',
                        label: 'Det gamle testamente',
                        originalLabel: 'Det gamle testamente',
                        alternativeLabels: [
                          'Pentateuch',
                          'Septuagint',
                          'mosebøkene',
                          'gammeltestamentlige apokryfer',
                          'GT',
                          'profetene',
                          'bibelstudier',
                        ],
                        note: 'Her: Salmenes bok. Brukes med QRVC for bibelkritikk eller -tolkning',
                        related: [],
                        children: [],
                      },
                      {
                        id: 'QRMF13',
                        label: 'Det nye testamente',
                        originalLabel: 'Det nye testamente',
                        alternativeLabels: [
                          'apostlenes gjerninger',
                          'johannes’ åpenbaring',
                          'apokryfer',
                          'NT',
                          'bibelstudier',
                        ],
                        note: 'Her: evangeliene, Paulus’ brev. Brukes med QRVC for bibelkritikk eller -tolkning av en eller flere av bøkene i det nye testamente',
                        related: [],
                        children: [],
                      },
                      {
                        id: 'QRMF14',
                        label: 'Bibelske apokryfer',
                        originalLabel: 'Bibelske apokryfer',
                        alternativeLabels: [],
                        note: 'Her: pseudepigrafiske bøker, den mellomtestamentlige perioden',
                        related: [],
                        children: [],
                      },
                      {
                        id: 'QRMF19',
                        label: 'Bibeltekster, -utvalg og meditasjoner',
                        originalLabel: 'Bibeltekster, -utvalg og meditasjoner',
                        alternativeLabels: ['bibelstudier'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'QRMF3',
                    label: 'Kirkefedrenes skrifter',
                    originalLabel: 'Kirkefedrenes skrifter',
                    alternativeLabels: [],
                    note: 'Her: patristikk. Brukes for skriftene til de tidlige kirkefedrene og med QRVC for kritisk analyse av tekstene, eller alene for tekstene selv',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRMP',
                label: 'Kristendommen: liv og lære',
                originalLabel: 'Kristendommen: liv og lære',
                alternativeLabels: [],
                note: 'Her: kristenlivet',
                related: [],
                children: [
                  {
                    id: 'QRMP1',
                    label: 'Kristne sakramenter',
                    originalLabel: 'Kristne sakramenter',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'QRP',
            label: 'Islam',
            originalLabel: 'Islam',
            alternativeLabels: ['muslimer'],

            related: [],
            children: [
              {
                id: 'QRPB',
                label: 'Islam: retninger og grupper',
                originalLabel: 'Islam: retninger og grupper',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'QRPB1',
                    label: 'Islam: sunni, salafisme',
                    originalLabel: 'Islam: sunni, salafisme',
                    alternativeLabels: ['wahhabisme'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRPB2',
                    label: 'Islam: kharijitter, khawarijer',
                    originalLabel: 'Islam: kharijitter, khawarijer',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRPB3',
                    label: 'Islam: sjia',
                    originalLabel: 'Islam: sjia',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRPB4',
                    label: 'Islam: sufisme',
                    originalLabel: 'Islam: sufisme',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRPF',
                label: 'Islam: hellige skrifter',
                originalLabel: 'Islam: hellige skrifter',
                alternativeLabels: [
                  'Tawrat',
                  'Zabur',
                  'Injil',
                  'Maghazi',
                  'Sira',
                ],
                note: 'Bruk QRPF*-koder med QRVC for kritikk eller tolkning av hellige skrifter',
                related: [],
                children: [
                  {
                    id: 'QRPF1',
                    label: 'Koranen',
                    originalLabel: 'Koranen',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRPF2',
                    label: 'Hadith',
                    originalLabel: 'Hadith',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRPP',
                label: 'Islam: liv og lære',
                originalLabel: 'Islam: liv og lære',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'QRR',
            label: 'Andre religioner og spirituelle trossamfunn',
            originalLabel: 'Andre religioner og spirituelle trossamfunn',
            alternativeLabels: [],
            note: 'Brukes for bøker om trossamfunn og deres tro, mytologi, ritualer, religionsutøvelse, skrifter, myter etc. Brukes med andre Q*-koder og kvalifikatorer for sted der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'QRRB',
                label: 'Baha’i',
                originalLabel: 'Baha’i',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'QRRC',
                label: 'Jainisme',
                originalLabel: 'Jainisme',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'QRRD',
                label: 'Sikhisme',
                originalLabel: 'Sikhisme',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'QRRF',
                label: 'Zoroastrisme',
                originalLabel: 'Zoroastrisme',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'QRRL',
                label: 'Øst-asiatiske religioner',
                originalLabel: 'Øst-asiatiske religioner',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'QRRL1',
                    label: 'Konfusianisme',
                    originalLabel: 'Konfusianisme',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRRL3',
                    label: 'Shintoisme',
                    originalLabel: 'Shintoisme',
                    alternativeLabels: ['kami-no-michi'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRRL5',
                    label: 'Taoisme',
                    originalLabel: 'Taoisme',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRRL6',
                    label: 'Kinesisk folkereligion',
                    originalLabel: 'Kinesisk folkereligion',
                    alternativeLabels: [],
                    note: 'Her: kinesisk mytologi og folketro',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRRM',
                label: 'Afro-amerikanske religioner',
                originalLabel: 'Afro-amerikanske religioner',
                alternativeLabels: [],
                note: 'Her: religioner i afrikanske diasporasamfunn; candomblé, umbanda, quimbanda, voodoo, afro-amerikanske baptistsamfunn, spiritisme, hoodoo (folkemagi), rastafari. Brukes for forskjellige variasjoner av afrikanske religioner blant amerikanere, eller synkretistiske religioner og spirituelle tradisjoner i Amerika og Karibia',
                related: [],
                children: [],
              },
              {
                id: 'QRRN',
                label:
                  'Tradisjonelle afrikanske religioner og spirituelle trossamfunn',
                originalLabel:
                  'Tradisjonelle afrikanske religioner og spirituelle trossamfunn',
                alternativeLabels: [],
                note: 'Brukes for bøker om afrikanske trossamfunn, både nyere og tradisjonelle, og deres mytologi, gudshus, guder og ånder, skapelsesberetninger og andre fortellinger, legender, myter, religionsutøvelse, ritualer, tradisjonelle medisinmenn etc. Brukes med kvalifikatorer for sted eller språk eller 5P* der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'QRRT',
                label:
                  'Urfolks-, etniske- og folkereligioner og spirituelle trossamfunn',
                originalLabel:
                  'Urfolks-, etniske- og folkereligioner og spirituelle trossamfunn',
                alternativeLabels: ['urfolksreligion', 'stammereligion'],
                note: 'Brukes for bøker hvor ingen andre koder passer om religioner og trossamfunn, inkludert deres mytologi, gudshus, guder og ånder, skapelsesberetninger og andre fortellinger, legender, myter, religionsutøvelse, ritualer etc. Brukes med kvalifikatorer for sted, språk eller 5P* der det er hensiktsmessig, f.eks. for bøker om spiritualitet eller tradisjoner hos aboriginene i Australia, bruk med 5PB-AU-A',
                related: [],
                children: [
                  {
                    id: 'QRRT1',
                    label:
                      'Urfolksreligioner, spirituelle trossamfunn og mytologi i Amerika',
                    originalLabel:
                      'Urfolksreligioner, spirituelle trossamfunn og mytologi i Amerika',
                    alternativeLabels: [
                      'aztekere',
                      'mayaer',
                      'olmekere',
                      'inka',
                      'maya',
                      'inkaer',
                    ],
                    note: 'Her: religion og myter hos aztekerne, mayaene, olmekerne, inkaene, inuitter og andre urfolk eller tradisjoner. Brukes for bøker om spirituell tro, både i dag og i historisk sammenheng, inkludert mytologi, religion, seremonier, ritualer og skikker som forbindes med urfolk i Amerika, både i dag og som eksisterte før urbefolkningens kontakt med europeere, eller andre kulturer og trossamfunn som ikke stammer fra urfolkene. Brukes med kvalifikatorer for sted, språk og 5P* der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRRV',
                label: 'Animisme og sjamanisme',
                originalLabel: 'Animisme og sjamanisme',
                alternativeLabels: [],
                note: 'Her: naturreligioner, tilbedelse av naturen, totemisme, fetisjisme som religiøst fenomen. Brukes for bøker som tar for seg denne typen spirituell tro og deres ritualer, religionsutøvelse, fortellinger, mytologi etc. Brukes med kvalifikatorer for sted der det er hensiktsmessig. Foretrekk VXWS for utgivelser av populærvitenskapelig karakter',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'QRS',
            label: 'Oldtidens religioner og mytologier',
            originalLabel: 'Oldtidens religioner og mytologier',
            alternativeLabels: [],
            note: 'Her: paganisme, komparativ mytologi, generelle studier av mytologi og studier av den enkelte, regionale mytologi hvor denne ikke har sin egen kode. Bruk QRS*-koder for bøker som tar for seg disse formene for spirituell tro, deres mytologier, gudshus, religionsutøvelse, guder og ånder, skapelsesberetninger og andre fortellinger, legender, ritualer etc. Bruk QRS*-koder med kvalifikatorer for sted, spesielt 1QB* for historiske land og riker, eller med andre OR*-koder for sammenligning med nyere religioner. Brukes med FN*-koder for moderne gjenfortellinger av tradisjonelle myter fra disse religionene. Se også FN <a href="/thema/nb/FN">Tradisjonelle fortellinger, myter og eventyr</a>, JBGB <a href="/thema/nb/JBGB">Folklore, myter og legender</a>',
            related: [
              {
                id: 'FN',
                label: 'Tradisjonelle fortellinger, myter og eventyr',
                originalLabel: 'Tradisjonelle fortellinger, myter og eventyr',
                note: 'Her: tradisjonelle lokale folkeeventyr, skrøner, fabler, eller enhver form for tradisjonell historie fortalt eller gjenfortalt som fiksjon. Brukes for moderne gjenfortellinger i romanform eller samlinger av tradisjonelle fortellinger, først og fremst ment for voksne, eller skjønnlitterære bøker som er inspirert av disse tradisjonelle fortellingene eller av folklore. Se også DBS* <a href="/thema/nb/DBS">Sagaer og epos</a> og dens underkategorier, QRS* <a href="/thema/nb/QRS">Oldtidens religioner og mytologier</a> og dens underkategorier, JBGB <a href="/thema/nb/JBGB">Folklore, myter og legender</a>, YFJ <a href="/thema/nb/YFJ">Skjønnlitteratur for barn og ungdom: tradisjonelle historier</a>, YNRU <a href="/thema/nb/YNRU">Barn og ungdom: oldtidens religioner, mytologi og legender</a>',
              },
              {
                id: 'JBGB',
                label: 'Folklore, myter og legender',
                originalLabel: 'Folklore, myter og legender',
                note: 'Brukes for faglitteratur som tar for seg tradisjonelle fortellinger eller muntlig overbrakt folklore; folkloristikk, studier av folkereligioner og tradisjoner, studier av urbane og nyere myter og legender, myters opprinnelse, eventyr, løgnhistorier, overnaturlige fortellinger, overnaturlige vesener, overtro etc., utgivelser som tar for seg opprinnelsen til fortellinger om Atlantis, den hellige gral, Bermudatriangelet etc. Foretrekk QR*-koder for spirituelle eller religiøse aspekter ved mytologi eller VX*-koder for populærvitenskapelig tilnærming eller FN*-koder for selve historiene. Se også FN <a href="/thema/nb/FN">Tradisjonelle fortellinger, myter og eventyr</a>, QRS* <a href="/thema/nb/QRS">Oldtidens religioner og mytologier</a> og dens underkategorier, VXQM* <a href="/thema/nb/VXQM">Mytiske / overnaturlige vesen</a> og dens underkategorier',
              },
            ],
            children: [
              {
                id: 'QRSA',
                label: 'Oldtidens egyptiske religion og mytologi',
                originalLabel: 'Oldtidens egyptiske religion og mytologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'QRSG',
                label: 'Oldtidens greske religion og mytologi',
                originalLabel: 'Oldtidens greske religion og mytologi',
                alternativeLabels: [],
                note: 'Se også DBSG <a href="/thema/nb/DBSG">Antikkens greske og romerske litteratur</a>',
                related: [
                  {
                    id: 'DBSG',
                    label: 'Antikkens greske og romerske litteratur',
                    originalLabel: 'Antikkens greske og romerske litteratur',
                    note: 'Brukes for de klassiske eposene, prosa, lyrikk eller skuespill, inkludert versjoner av de tradisjonelle mytene og legendene, skrevet av klassiske greske, hellenske eller latinske forfattere på originalspråket, annoterte versjoner eller moderne oversettelser. Se også QRSG <a href="/thema/nb/QRSG">Oldtidens greske religion og mytologi</a>, QRSL <a href="/thema/nb/QRSL">Romersk religion og mytologi</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'QRSL',
                label: 'Romersk religion og mytologi',
                originalLabel: 'Romersk religion og mytologi',
                alternativeLabels: [],
                note: 'Se også DBSG <a href="/thema/nb/DBSG">Antikkens greske og romerske litteratur</a>',
                related: [
                  {
                    id: 'DBSG',
                    label: 'Antikkens greske og romerske litteratur',
                    originalLabel: 'Antikkens greske og romerske litteratur',
                    note: 'Brukes for de klassiske eposene, prosa, lyrikk eller skuespill, inkludert versjoner av de tradisjonelle mytene og legendene, skrevet av klassiske greske, hellenske eller latinske forfattere på originalspråket, annoterte versjoner eller moderne oversettelser. Se også QRSG <a href="/thema/nb/QRSG">Oldtidens greske religion og mytologi</a>, QRSL <a href="/thema/nb/QRSL">Romersk religion og mytologi</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'QRST',
                label: 'Keltisk religion og mytologi',
                originalLabel: 'Keltisk religion og mytologi',
                alternativeLabels: [],
                note: 'Brukes for bøker om keltiske mytologier og tro. Brukes med kvalifikatorer for sted der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'QRSV',
                label: 'Slavisk religion og mytologi',
                originalLabel: 'Slavisk religion og mytologi',
                alternativeLabels: [],
                note: 'Brukes for bøker om førkristen slavisk mytologi og tro',
                related: [],
                children: [],
              },
              {
                id: 'QRSW',
                label: 'Norrøn religion og mytologi',
                originalLabel: 'Norrøn religion og mytologi',
                alternativeLabels: [],
                note: 'Brukes for bøker om førkristen norrøn og germansk mytologi og tro (inkludert anglosaksere og vikinger). Se også DBSN <a href="/thema/nb/DBSN">Islandske og norrøne sagaer</a>',
                related: [
                  {
                    id: 'DBSN',
                    label: 'Islandske og norrøne sagaer',
                    originalLabel: 'Islandske og norrøne sagaer',
                    note: 'Brukes for islandske og norrøne sagaer på originalspråket, annoterte versjoner eller moderne oversettelser. Se også QRSW <a href="/thema/nb/QRSW">Norrøn religion og mytologi</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'QRV',
            label: 'Aspekter ved religion',
            originalLabel: 'Aspekter ved religion',
            alternativeLabels: [],
            note: 'Bruk QRV*-koder etter andre QR*-koder for å angi hvilken religion det er snakk om. Dersom det fins spesifikke koder for religionen, foretrekkes disse, f.eks. for Buddhismens hellige skrifter, bruk QRFF, ikke QRF med QRVA',
            related: [],
            children: [
              {
                id: 'QRVA',
                label: 'Hellige skrifter',
                originalLabel: 'Hellige skrifter',
                alternativeLabels: [],
                note: 'Bruk QRVA*-koder for bøker om tekstene, i tillegg til selve tekstene. For studier av, analyser, tolkninger etc., bruk med en passende kode som f.eks. QRVC',
                related: [],
                children: [
                  {
                    id: 'QRVA2',
                    label:
                      'Lesninger, utvalg og meditasjoner fra hellige tekster',
                    originalLabel:
                      'Lesninger, utvalg og meditasjoner fra hellige tekster',
                    alternativeLabels: [],
                    note: 'Brukes for tekstsamlinger eller med andre QR*-koder for tekstutvalg fra gitte tekster, eller bøker som handler om meditasjon i ulike religioner eller spirituelle tradisjoner, hjelpemidler for meditasjon, religiøs selvfordypning og tilsvarende spirituelle skikker, tatt fra eller inspirert av hellige tekster. Se også QRMF19 <a href="/thema/nb/QRMF19">Bibeltekster, -utvalg og meditasjoner</a>',
                    related: [
                      {
                        id: 'QRMF19',
                        label: 'Bibeltekster, -utvalg og meditasjoner',
                        originalLabel: 'Bibeltekster, -utvalg og meditasjoner',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRVC',
                label: 'Kritikk og tolkning av hellige tekster',
                originalLabel: 'Kritikk og tolkning av hellige tekster',
                alternativeLabels: ['bibellesning', 'bibeltolkning'],
                note: 'Her: eksegese av hellige tekster, konkordanser, kommentarer. Brukes for studier av skrifter og religiøse tekster. Brukes med andre QR*-koder for å angi religion eller hvilken tekst det er snakk om, f.eks. QRMF1 for bibeltolkning og bibellesning, eller QRPF1 for tolkninger av Koranen',
                related: [],
                children: [],
              },
              {
                id: 'QRVD',
                label: 'Religiøse doktriner',
                originalLabel: 'Religiøse doktriner',
                alternativeLabels: [],
                note: 'Brukes for læresetninger og dogmer som ligger til grunn for religionsopplæring i et trossystem',
                related: [],
                children: [],
              },
              {
                id: 'QRVG',
                label: 'Teologi',
                originalLabel: 'Teologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'QRVH',
                label: 'Prekener',
                originalLabel: 'Prekener',
                alternativeLabels: [],
                note: 'Her: det å holde preken. Brukes for samlinger og analyser av prekener, prekenpraksis. Se også DNS <a href="/thema/nb/DNS">Taler</a>',
                related: [
                  {
                    id: 'DNS',
                    label: 'Taler',
                    originalLabel: 'Taler',
                    note: 'Brukes for samlinger av transkriberte taler. Brukes sammen med en annen kode som indikerer emne der det er mulig. Se også QRVH <a href="/thema/nb/QRVH">Prekener</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'QRVJ',
                label: 'Bønner og liturgisk materiale',
                originalLabel: 'Bønner og liturgisk materiale',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'QRVJ1',
                    label: 'Tilbedelse, riter, seremonier og ritualer',
                    originalLabel: 'Tilbedelse, riter, seremonier og ritualer',
                    alternativeLabels: [
                      'liturgi',
                      'vielse',
                      'dåp',
                      'begravelse',
                      'konfirmasjon',
                      'sakramenter',
                    ],
                    note: 'Her: salmebøker. Brukes for bøker om innhold i og skikker i forbindelse med seremonier og ritualer, eller bøker som inneholder tekst, musikk og handlinger som brukes i religiøse seremonier, eller bøker om religiøse seremonier eller hellige objekter som forbindes med ulike religiøse eller spirituelle tradisjoner eller typer tilbedelse',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRVJ2',
                    label: 'Bønn og bønnebøker',
                    originalLabel: 'Bønn og bønnebøker',
                    alternativeLabels: [],
                    note: 'Brukes for samlinger av bønner og bøker om hvordan man kan be',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRVJ3',
                    label: 'Andaktsbøker',
                    originalLabel: 'Andaktsbøker',
                    alternativeLabels: [],
                    note: 'Brukes for bøker som tar for seg temaer, fortellinger og spørsmål man finner i hellige tekster. Bøkene er vanligvis ikke dogmatiske eller teologiske, men er ment for personer som ønsker å lese tekstene for sin egen personlige oppbyggelse og spirituelle helse',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRVK',
                label: 'Spiritualitet og religiøse opplevelser',
                originalLabel: 'Spiritualitet og religiøse opplevelser',
                alternativeLabels: [],
                note: 'Her: spirituell oppvåkning, utvikling og personlig religiøs vekst, religiøs og spirituell meditasjon, profetier',
                related: [],
                children: [
                  {
                    id: 'QRVK2',
                    label: 'Mystisisme',
                    originalLabel: 'Mystisisme',
                    alternativeLabels: [],
                    note: 'Her: opplevelser av visjonær karakter. Brukes med andre koder fra QR* for tradisjoner innen mystisisme og ulike religiøse skikker',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRVK4',
                    label: 'Mirakler, åpenbaringer og religiøse fenomener',
                    originalLabel:
                      'Mirakler, åpenbaringer og religiøse fenomener',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRVL',
                label: 'Religion: døden',
                originalLabel: 'Religion: døden',
                alternativeLabels: [],
                note: 'Brukes for bøker som handler om døden og som tar for seg ulike religiøse og spirituelle holdninger til dette, inkludert seremonier i forbindelse med døden, sorg, tilbedelse av forfedre, nær-døden opplevelser etc.',
                related: [],
                children: [],
              },
              {
                id: 'QRVP',
                label: 'Religion: liv og lære',
                originalLabel: 'Religion: liv og lære',
                alternativeLabels: ['trosbekjennelser'],
                note: 'Her: tro, trosliv, religiøst trosliv, trosbekjennelser. Brukes for bøker som handler om religion som en del av hverdagslivet, hvordan det er å leve etter de prinsippene en religion setter. Brukes med QRVX for personlige beretninger som handler om personlig tro',
                related: [],
                children: [
                  {
                    id: 'QRVP1',
                    label: 'Pilegrimsreiser',
                    originalLabel: 'Pilegrimsreiser',
                    alternativeLabels: ['pilegrimsferder'],
                    note: 'Her: pilegrimer. Brukes for pilegrimsvandringer og -steder eller beretninger fra pilegrimer. Brukes med kvalifikatorer for sted der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRVP2',
                    label: 'Religiøse høytider',
                    originalLabel: 'Religiøse høytider',
                    alternativeLabels: ['religiøse festdager'],
                    note: 'Brukes med andre QR*-koder for å angi religion og kvalifikatorer fra 5HP* der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRVP3',
                    label: 'Religiøs instruksjon',
                    originalLabel: 'Religiøs instruksjon',
                    alternativeLabels: ['katekese'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRVP4',
                    label: 'Faste og avholdenhet',
                    originalLabel: 'Faste og avholdenhet',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRVP5',
                    label: 'Religiøs rådgivning',
                    originalLabel: 'Religiøs rådgivning',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRVP7',
                    label:
                      'Religiøse aspekter ved seksualitet, kjønn og forhold',
                    originalLabel:
                      'Religiøse aspekter ved seksualitet, kjønn og forhold',
                    alternativeLabels: [],
                    note: 'Brukes for bøker om religiøs opplæring eller holdninger til seksualitet og forhold (inkludert ekteskap), familie, kjønnsroller, seksuell orientering, kjønnsidentitet, sølibat, seksuell avholdenhet etc. Brukes med andre koder fra QR* eller J* for spesielle emner, som f.eks. JBSF1 for holdninger til kvinner i en religion, JBSJ for religion og emner innen LHBT+, JHBK for religion og familie etc.',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRVQ',
                label: 'Religiøse / hellige steder',
                originalLabel: 'Religiøse / hellige steder',
                alternativeLabels: [],
                note: 'Her: hellige steder, byer, alter, helligdommer, steder for religiøs tilbedelse, landskapsformer som fjell eller elver som forbindes med en religiøs tradisjon, religiøse bygninger eller strukturer. Brukes for bøker som handler om steder som forbindes med religiøse tradisjoner eller trossystemer, eller bøker som handler om spesifikke steder fra et religiøst perspektiv. Brukes med kvalifikatorer for sted der det er hensiktsmessig, QRVP1 for pilegrimssteder og andre QR*-koder der det er hensiktsmessig. Se også AMN <a href="/thema/nb/AMN">Arkitektur: religiøse bygninger</a>',
                related: [
                  {
                    id: 'AMN',
                    label: 'Arkitektur: religiøse bygninger',
                    originalLabel: 'Arkitektur: religiøse bygninger',
                    note: 'Her: arkitektoniske aspekter ved kloster, katedraler, kirker, moskeer, synagoger, templer og andre religiøse bygninger eller konstruksjoner',
                  },
                ],
                children: [],
              },
              {
                id: 'QRVS',
                label: 'Religiøse institusjoner og organisasjoner',
                originalLabel: 'Religiøse institusjoner og organisasjoner',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'QRVS1',
                    label: 'Religiøse og spirituelle figurer',
                    originalLabel: 'Religiøse og spirituelle figurer',
                    alternativeLabels: [],
                    note: 'Her: religiøse ledere og innflytelsesrike eller inspirerende figurer som helgener, guruer, mystikere, profeter, teologer osv.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRVS2',
                    label:
                      'Religionssosiologiske og pastorale tanker og aktiviteter',
                    originalLabel:
                      'Religionssosiologiske og pastorale tanker og aktiviteter',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRVS3',
                    label: 'Religiøse embeter og geistlige',
                    originalLabel: 'Religiøse embeter og geistlige',
                    alternativeLabels: [],
                    note: 'Her: prester, pastorer, biskoper, paver, rabbier, imamer, hazzáner, muftier, mullaer etc. Brukes for bøker om arbeidet til personer som innehar spesielle roller innen en religion',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRVS4',
                    label: 'Misjonering og konvertering',
                    originalLabel: 'Misjonering og konvertering',
                    alternativeLabels: [],
                    note: 'Her: misjonærer, evangelisering. Brukes for bøker om misjonering, eller om bøker som tar for seg enhver form for religiøs konvertering',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRVS5',
                    label: 'Religiøse samfunn og munkeordener',
                    originalLabel: 'Religiøse samfunn og munkeordener',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRVX',
                label:
                  'Personlige religiøse vitnesbyrd og inspirerende tekster',
                originalLabel:
                  'Personlige religiøse vitnesbyrd og inspirerende tekster',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'QRY',
            label: 'Alternative trossystemer',
            originalLabel: 'Alternative trossystemer',
            alternativeLabels: [],
            note: 'Her: nyreligiøse bevegelser, neopaganisme, nyhedendom. Foretrekk VX* for populærvitenskapelige bøker',
            related: [],
            children: [
              {
                id: 'QRYA',
                label: 'Humanistiske og sekulære alternativ til religion',
                originalLabel:
                  'Humanistiske og sekulære alternativ til religion',
                alternativeLabels: [],
                note: 'Her: humanetikk, sekularisme. Se også QDHH <a href="/thema/nb/QDHH">Humanisme</a>',
                related: [
                  {
                    id: 'QDHH',
                    label: 'Humanisme',
                    originalLabel: 'Humanisme',
                    note: 'Her: renessansehumanisme, Erasmus. Se også QRYA <a href="/thema/nb/QRYA">Humanistiske og sekulære alternativ til religion</a>',
                  },
                ],
                children: [
                  {
                    id: 'QRYA5',
                    label: 'Agnostisisme og ateisme',
                    originalLabel: 'Agnostisisme og ateisme',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRYC',
                label: 'Eklektiske og esoteriske religions- og trossystemer',
                originalLabel:
                  'Eklektiske og esoteriske religions- og trossystemer',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'QRYC1',
                    label: 'Gnostisisme',
                    originalLabel: 'Gnostisisme',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRYC5',
                    label: 'Teosofi og antroposofi',
                    originalLabel: 'Teosofi og antroposofi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRYM',
                label: 'Moderne ikke-kristne og parakristne sekter og kulter',
                originalLabel:
                  'Moderne ikke-kristne og parakristne sekter og kulter',
                alternativeLabels: [],
                note: 'Her: scientologi, apokalyptiske sekter etc.',
                related: [],
                children: [
                  {
                    id: 'QRYM2',
                    label: 'Spiritisme',
                    originalLabel: 'Spiritisme',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'QRYX',
                label: 'Okkulte studier',
                originalLabel: 'Okkulte studier',
                alternativeLabels: [],
                note: 'Brukes bare på seriøse og akademiske verk. Se også VXW* <a href="/thema/nb/VXW">Mystikk, magi og okkulte interesser</a> og dens underkategorier',
                related: [
                  {
                    id: 'VXW',
                    label: 'Mystikk, magi og okkulte interesser',
                    originalLabel: 'Mystikk, magi og okkulte interesser',
                    note: 'Bruk VXW*-koder på bøker for allmennmarkedet. Foretrekk QRY*-koder på bøker for høyere utdanning og profesjonsmarkedet. Se også QRYX* <a href="/thema/nb/QRYX">Okkulte studier</a> og dens underkategorier',
                  },
                ],
                children: [
                  {
                    id: 'QRYX2',
                    label: 'Magi, alkymi og hermeneutiske tanker',
                    originalLabel: 'Magi, alkymi og hermeneutiske tanker',
                    alternativeLabels: ['rosenkreutzerne'],
                    note: 'Her: hermetisme, hermetistisk kabbala, rosenkorsordenen',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'QRYX5',
                    label: 'Heksekunst',
                    originalLabel: 'Heksekunst',
                    alternativeLabels: [
                      'wicca',
                      'hekser',
                      'hekseprosesser',
                      'trolldomsprosesser',
                    ],
                    note: 'Brukes for studier både av heksekunst i nåtiden og i et historisk perspektiv, de som praktiserer/praktiserte heksekunst, holdninger til hekser og heksekunst etc. Brukes med kvalifikatorer for sted og tid der det er hensiktsmessig, og med andre emnekoder, f.eks. NHTX for hekseprosesser. Foretrekk VXWT for bøker med populærvitenskapelig tilnærming til emnet. Se også VXWT <a href="/thema/nb/VXWT">Heksekunst og wicca</a>',
                    related: [
                      {
                        id: 'VXWT',
                        label: 'Heksekunst og wicca',
                        originalLabel: 'Heksekunst og wicca',
                        note: 'Her: moderne eller hedensk heksekunst. Se også QRYX5 <a href="/thema/nb/QRYX5">Heksekunst</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'QRYX9',
                    label: 'Satanisme og demonologi',
                    originalLabel: 'Satanisme og demonologi',
                    alternativeLabels: [],
                    note: 'Brukes for bøker om satanistiske kulter, Church of Satan eller andre alternative religiøse bevegelser som tilber Satan, eller bøker som ser på studier av demoner eller skildringer av demoner i ulike religioner. Brukes med andre koder fra QR* der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'R',
    label: 'Geofag, geografi og miljøkunnskap',
    originalLabel: 'Geofag, geografi og miljøkunnskap',
    alternativeLabels: [],
    note: 'Bruk R*-koder for utgivelser både for høyere utdanning, profesjons- og allmennmarkedet. IKKE bruk koden ‘R’, men velg spesifikke kategorier fra R*. Bruk R*-koder med andre emnekoder og kvalifikatorer der det er hensiktsmessig, særlig kvalifikatorer for sted',
    related: [],
    children: [
      {
        id: 'RB',
        label: 'Geovitenskap',
        originalLabel: 'Geovitenskap',
        alternativeLabels: [],
        note: 'Bruk RB*-koder for lærebøker for profesjonsmarkedet og høyere utdanning. Bruk WN*-koder for bøker for allmenmarkedet. Se også RGB* <a href="/thema/nb/RGB">Fysisk geografi og topografi</a> og dens underkategorier, WN* <a href="/thema/nb/WN">Natur</a> og dens underkategorier',
        related: [
          {
            id: 'RGB',
            label: 'Fysisk geografi og topografi',
            originalLabel: 'Fysisk geografi og topografi',
            note: 'Se også RBG <a href="/thema/nb/RBG">Geologi, geomorfologi og litosfæren</a>',
          },
          {
            id: 'WN',
            label: 'Natur',
            originalLabel: 'Natur',
            note: 'Her: populærvitenskapelige bøker om naturhistorie, ulike aspekter ved naturlige landskap eller naturens verden. Bruk WN*-koder på bøker for allmennmarkedet. Foretrekk koder fra seksjonene P, R, T etc., på bøker for høyere utdanning og profesjonsmarkedet',
          },
        ],
        children: [
          {
            id: 'RBC',
            label: 'Vulkanologi og seismologi',
            originalLabel: 'Vulkanologi og seismologi',
            alternativeLabels: [],
            note: 'Brukes for bøker om jordskjelv, vulkaner etc. fra et vitenskapelig perspektiv',
            related: [],
            children: [],
          },
          {
            id: 'RBG',
            label: 'Geologi, geomorfologi og litosfæren',
            originalLabel: 'Geologi, geomorfologi og litosfæren',
            alternativeLabels: [],
            note: 'Se også PHVG <a href="/thema/nb/PHVG">Geofysikk</a>, RGB <a href="/thema/nb/RGB">Fysisk geografi og topografi</a>',
            related: [
              {
                id: 'PHVG',
                label: 'Geofysikk',
                originalLabel: 'Geofysikk',
                note: 'Se også RBG* <a href="/thema/nb/RBG">Geologi, geomorfologi og litosfæren</a> og dens underkategorier',
              },
              {
                id: 'RGB',
                label: 'Fysisk geografi og topografi',
                originalLabel: 'Fysisk geografi og topografi',
                note: 'Se også RBG <a href="/thema/nb/RBG">Geologi, geomorfologi og litosfæren</a>',
              },
            ],
            children: [
              {
                id: 'RBGB',
                label: 'Sedimentologi og pedologi',
                originalLabel: 'Sedimentologi og pedologi',
                alternativeLabels: [],
                note: 'Se også PNC <a href="/thema/nb/PNC">Miljøkjemi</a>, TNCC <a href="/thema/nb/TNCC">Jord- og bergmekanikk</a>, TVBP <a href="/thema/nb/TVBP">Jordbruksvitenskap</a>',
                related: [
                  {
                    id: 'PNC',
                    label: 'Miljøkjemi',
                    originalLabel: 'Miljøkjemi',
                    note: 'Bruk TQ* for praktiske anvendelser av miljøkjemi. Her: atmosfærisk-, jord- og vannkjemi, kjemisk forurensning og opprydding. Se også RBGK <a href="/thema/nb/RBGK">Geokjemi</a>, TQ* <a href="/thema/nb/TQ">Miljøvitenskap, -teknikk og -teknologi</a> og dens underkategorier',
                  },
                  {
                    id: 'TNCC',
                    label: 'Jord- og bergmekanikk',
                    originalLabel: 'Jord- og bergmekanikk',
                    note: 'Her: geoteknisk ingeniørarbeid, geoteknikk. Se også RBGB <a href="/thema/nb/RBGB">Sedimentologi og pedologi</a>',
                  },
                  {
                    id: 'TVBP',
                    label: 'Jordbruksvitenskap',
                    originalLabel: 'Jordbruksvitenskap',
                    note: 'Her: edafologi, jordvitenskap i agronomi og miljøsammenheng, jordkvalitet, jorderosjon etc. Se også RBGB <a href="/thema/nb/RBGB">Sedimentologi og pedologi</a>, TNCC <a href="/thema/nb/TNCC">Jord- og bergmekanikk</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'RBGD',
                label: 'Geomorfologi og geologiske overflateprosesser',
                originalLabel: 'Geomorfologi og geologiske overflateprosesser',
                alternativeLabels: [],
                note: 'Her: erosjon, glasiologi, fluviale og eoliske prosesser etc. Se også RGB <a href="/thema/nb/RGB">Fysisk geografi og topografi</a>',
                related: [
                  {
                    id: 'RGB',
                    label: 'Fysisk geografi og topografi',
                    originalLabel: 'Fysisk geografi og topografi',
                    note: 'Se også RBG <a href="/thema/nb/RBG">Geologi, geomorfologi og litosfæren</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'RBGF',
                label: 'Historisk geologi og paleogeologi',
                originalLabel: 'Historisk geologi og paleogeologi',
                alternativeLabels: ['paleogeologi'],
                note: 'Her: platetektonikk, kontinentaldrift, superkontinenter som Pangaea, Gondwana, Eurasia, jordas geologiske historie etc.',
                related: [],
                children: [],
              },
              {
                id: 'RBGG',
                label: 'Petrologi, petrografi og mineralogi',
                originalLabel: 'Petrologi, petrografi og mineralogi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'RBGH',
                label: 'Stratigrafi',
                originalLabel: 'Stratigrafi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'RBGK',
                label: 'Geokjemi',
                originalLabel: 'Geokjemi',
                alternativeLabels: [],
                note: 'Se også PNC <a href="/thema/nb/PNC">Miljøkjemi</a>, PNV <a href="/thema/nb/PNV">Mineralogi, krystaller og edelstener</a>',
                related: [
                  {
                    id: 'PNC',
                    label: 'Miljøkjemi',
                    originalLabel: 'Miljøkjemi',
                    note: 'Bruk TQ* for praktiske anvendelser av miljøkjemi. Her: atmosfærisk-, jord- og vannkjemi, kjemisk forurensning og opprydding. Se også RBGK <a href="/thema/nb/RBGK">Geokjemi</a>, TQ* <a href="/thema/nb/TQ">Miljøvitenskap, -teknikk og -teknologi</a> og dens underkategorier',
                  },
                  {
                    id: 'PNV',
                    label: 'Mineralogi, krystaller og edelstener',
                    originalLabel: 'Mineralogi, krystaller og edelstener',
                  },
                ],
                children: [],
              },
              {
                id: 'RBGL',
                label: 'Ressursgeologi',
                originalLabel: 'Ressursgeologi',
                alternativeLabels: [],
                note: 'Her: økonomisk geologi, petroleums- og gruvegeologi',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'RBK',
            label: 'Hydrosfære og hydrologi',
            originalLabel: 'Hydrosfære og hydrologi',
            alternativeLabels: [],
            note: 'Se også PSP <a href="/thema/nb/PSP">Hydrobiologi</a>',
            related: [
              {
                id: 'PSP',
                label: 'Hydrobiologi',
                originalLabel: 'Hydrobiologi',
                note: 'Se også RBK <a href="/thema/nb/RBK">Hydrosfære og hydrologi</a>',
              },
            ],
            children: [
              {
                id: 'RBKC',
                label: 'Oseanografi (hav)',
                originalLabel: 'Oseanografi (hav)',
                alternativeLabels: [],
                note: 'Se også PSPM <a href="/thema/nb/PSPM">Marinbiologi</a>',
                related: [
                  {
                    id: 'PSPM',
                    label: 'Marinbiologi',
                    originalLabel: 'Marinbiologi',
                    note: 'Her: saltvannsbiologi. Se også RBKC <a href="/thema/nb/RBKC">Oseanografi (hav)</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'RBKF',
                label: 'Limnologi (innsjøer og rennende vann)',
                originalLabel: 'Limnologi (innsjøer og rennende vann)',
                alternativeLabels: [],
                note: 'Her: elver, ferskvanns- og saltsjøer, etc. Se også PSPF <a href="/thema/nb/PSPF">Ferskvannsbiologi</a>',
                related: [
                  {
                    id: 'PSPF',
                    label: 'Ferskvannsbiologi',
                    originalLabel: 'Ferskvannsbiologi',
                    note: 'Se også RBKF <a href="/thema/nb/RBKF">Limnologi (innsjøer og rennende vann)</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'RBP',
            label: 'Meteorologi og klimatologi',
            originalLabel: 'Meteorologi og klimatologi',
            alternativeLabels: [
              'orkaner',
              'tyfoner',
              'sykloner',
              'tornadoer',
              'skyer',
              'været',
              'vær',
            ],
            note: 'Brukes for bøker om værfenomener og atmosfæriske fenomener. Se også WNWM <a href="/thema/nb/WNWM">Vær og klima</a>, RNPG <a href="/thema/nb/RNPG">Klimaendringer</a>',
            related: [
              {
                id: 'RNPG',
                label: 'Klimaendringer',
                originalLabel: 'Klimaendringer',
                note: 'Her: global oppvarming, drivhuseffekten, ozonlaget, klimakrisen, utslipp av klimagasser, menneskelig påvirkning av jordas værsystemer. Brukes for bøker som handler om klimaendringer, de effektene disse har og mulige tiltak for å begrense konsekvensene av disse, skadebegrensning, avbøtende tiltak, tilpasninger som følge av klimaendringer. Brukes med andre koder for bøker som er på klimaendringer i sammenheng med andre emner, som politikk, økonomi, teknologi etc. Se også TQP <a href="/thema/nb/TQP">Drivhusgasser</a>',
              },
              {
                id: 'WNWM',
                label: 'Vær og klima',
                originalLabel: 'Vær og klima',
                note: 'Her: verk for allmennmarkedet om vær og klima, inkludert stormer og andre ekstreme værforhold. Se også RBP* <a href="/thema/nb/RBP">Meteorologi og klimatologi</a> og dens underkategorier',
              },
            ],
            children: [
              {
                id: 'RBPC',
                label: 'Klimatologi',
                originalLabel: 'Klimatologi',
                alternativeLabels: ['klimaendringer', 'klima'],
                note: 'Her: klimamodeller, paleoklimatologi, klimaprognoser, klimavitenskap',
                related: [],
                children: [],
              },
              {
                id: 'RBPM',
                label: 'Meteorologi',
                originalLabel: 'Meteorologi',
                alternativeLabels: [
                  'orkaner',
                  'tyfoner',
                  'sykloner',
                  'tornadoer',
                  'skyer',
                  'hagl',
                  'stormer',
                  'været',
                  'vær',
                ],
                note: 'Her: meteorologiske fenomener, værsystemer, skyer. Se også WNWM <a href="/thema/nb/WNWM">Vær og klima</a>',
                related: [
                  {
                    id: 'WNWM',
                    label: 'Vær og klima',
                    originalLabel: 'Vær og klima',
                    note: 'Her: verk for allmennmarkedet om vær og klima, inkludert stormer og andre ekstreme værforhold. Se også RBP* <a href="/thema/nb/RBP">Meteorologi og klimatologi</a> og dens underkategorier',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'RBX',
            label: 'Paleontologi',
            originalLabel: 'Paleontologi',
            alternativeLabels: ['fossiler'],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'RG',
        label: 'Geografi',
        originalLabel: 'Geografi',
        alternativeLabels: [],
        note: 'Ved bruk av RG* tildeles også geografiske kvalifikatorer der det er hensiktsmessig',
        related: [],
        children: [
          {
            id: 'RGB',
            label: 'Fysisk geografi og topografi',
            originalLabel: 'Fysisk geografi og topografi',
            alternativeLabels: ['geomorfologi'],
            note: 'Se også RBG <a href="/thema/nb/RBG">Geologi, geomorfologi og litosfæren</a>',
            related: [
              {
                id: 'RBG',
                label: 'Geologi, geomorfologi og litosfæren',
                originalLabel: 'Geologi, geomorfologi og litosfæren',
                note: 'Se også PHVG <a href="/thema/nb/PHVG">Geofysikk</a>, RGB <a href="/thema/nb/RGB">Fysisk geografi og topografi</a>',
              },
            ],
            children: [
              {
                id: 'RGBA',
                label: 'Tørre klimasoner, ørkener',
                originalLabel: 'Tørre klimasoner, ørkener',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'RGBC',
                label: 'Sletter og gressletter',
                originalLabel: 'Sletter og gressletter',
                alternativeLabels: [],
                note: 'Her: stepper',
                related: [],
                children: [
                  {
                    id: 'RGBC1',
                    label: 'Prærier',
                    originalLabel: 'Prærier',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'RGBC2',
                    label: 'Savanner',
                    originalLabel: 'Savanner',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'RGBD',
                label: 'Tundra',
                originalLabel: 'Tundra',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'RGBF',
                label: 'Våtmarker, sumper, myrer',
                originalLabel: 'Våtmarker, sumper, myrer',
                alternativeLabels: [],
                note: 'Her: myrlandskap, torvmyrer, ferskvannssumper, restaurering av myrer',
                related: [],
                children: [],
              },
              {
                id: 'RGBG',
                label: 'Elver og innsjøer',
                originalLabel: 'Elver og innsjøer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'RGBL',
                label: 'Skog',
                originalLabel: 'Skog',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'RGBL1',
                    label: 'Regnskog',
                    originalLabel: 'Regnskog',
                    alternativeLabels: ['jungel'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'RGBL2',
                    label: 'Blandet skog',
                    originalLabel: 'Blandet skog',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'RGBL3',
                    label: 'Løvskog',
                    originalLabel: 'Løvskog',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'RGBL4',
                    label: 'Boreal barskog',
                    originalLabel: 'Boreal barskog',
                    alternativeLabels: [],
                    note: 'Her: Taiga',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'RGBP',
                label: 'Kystlinjer',
                originalLabel: 'Kystlinjer',
                alternativeLabels: ['bukt', 'kyst'],
                note: 'Her: deltaer, elvemunninger, kystregioner, fjorder, strender, landtunger, klipper, kysterosjon etc. Se også RBK* <a href="/thema/nb/RBK">Hydrosfære og hydrologi</a> og dens underkategorier',
                related: [
                  {
                    id: 'RBK',
                    label: 'Hydrosfære og hydrologi',
                    originalLabel: 'Hydrosfære og hydrologi',
                    note: 'Se også PSP <a href="/thema/nb/PSP">Hydrobiologi</a>',
                  },
                ],
                children: [
                  {
                    id: 'RGBP1',
                    label: 'Kystnære våtmarker / mudderflater',
                    originalLabel: 'Kystnære våtmarker / mudderflater',
                    alternativeLabels: [],
                    note: 'Her: saltsumper, saltvannssumper, marskland',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'RGBQ',
                label: 'Øyer',
                originalLabel: 'Øyer',
                alternativeLabels: ['atoller', 'holmer', 'laguner'],
                note: 'Her: arkipeler',
                related: [],
                children: [],
              },
              {
                id: 'RGBR',
                label: 'Korallrev',
                originalLabel: 'Korallrev',
                alternativeLabels: ['atoller'],

                related: [],
                children: [],
              },
              {
                id: 'RGBS',
                label: 'Fjell',
                originalLabel: 'Fjell',
                alternativeLabels: [],
                note: 'Her: høylandet, fjellkjeder, fjellplatåer, lyngheier, åser',
                related: [],
                children: [],
              },
              {
                id: 'RGBU',
                label: 'Isbreer',
                originalLabel: 'Isbreer',
                alternativeLabels: ['platåbre', 'iskappe'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'RGC',
            label: 'Samfunnsgeografi',
            originalLabel: 'Samfunnsgeografi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'RGCD',
                label: 'Utviklingsgeografi og miljøgeografi',
                originalLabel: 'Utviklingsgeografi og miljøgeografi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'RGCG',
                label: 'Befolkningsgeografi',
                originalLabel: 'Befolkningsgeografi',
                alternativeLabels: [],
                note: 'Her: migrasjon, befolkningsvekst og flytting',
                related: [],
                children: [],
              },
              {
                id: 'RGCM',
                label: 'Økonomisk geografi',
                originalLabel: 'Økonomisk geografi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'RGCP',
                label: 'Politisk geografi',
                originalLabel: 'Politisk geografi',
                alternativeLabels: [],
                note: 'Se også JPSL <a href="/thema/nb/JPSL">Geopolitikk</a>',
                related: [
                  {
                    id: 'JPSL',
                    label: 'Geopolitikk',
                    originalLabel: 'Geopolitikk',
                  },
                ],
                children: [],
              },
              {
                id: 'RGCS',
                label: 'Sosialgeografi',
                originalLabel: 'Sosialgeografi',
                alternativeLabels: [],
                note: 'Her: kulturgeografi',
                related: [],
                children: [],
              },
              {
                id: 'RGCT',
                label: 'Samfunnsgeografi: turisme',
                originalLabel: 'Samfunnsgeografi: turisme',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'RGCU',
                label: 'Bosettingsgeografi',
                originalLabel: 'Bosettingsgeografi',
                alternativeLabels: [],
                note: 'Her; by og bygd',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'RGL',
            label: 'Regionalgeografi',
            originalLabel: 'Regionalgeografi',
            alternativeLabels: [],
            note: 'Se også GTM <a href="/thema/nb/GTM">Regionale / internasjonale studier</a>',
            related: [
              {
                id: 'GTM',
                label: 'Regionale / internasjonale studier',
                originalLabel: 'Regionale / internasjonale studier',
                note: 'Her: områdestudier, globale studier. Brukes for tverrfaglige arbeider som dekker en rekke temaer innenfor et definert geografisk, kulturelt eller lingvistisk område. Brukes med kvalifikatorer for sted og språk der det er hensiktsmessig. Se også RGL <a href="/thema/nb/RGL">Regionalgeografi</a>',
              },
            ],
            children: [],
          },
          {
            id: 'RGM',
            label: 'Biogeografi',
            originalLabel: 'Biogeografi',
            alternativeLabels: [],
            note: 'Se også PSAF <a href="/thema/nb/PSAF">Økologi, biosfæren</a>, RN* <a href="/thema/nb/RN">Miljøet</a> og dens underkategorier',
            related: [
              {
                id: 'PSAF',
                label: 'Økologi, biosfæren',
                originalLabel: 'Økologi, biosfæren',
                note: 'Brukes for omfattende vitenskapelige, objektive og beskrivende verk om levende organismer og deres forhold til omgivelsene. Se også RNC <a href="/thema/nb/RNC">Anvendt økologi</a>',
              },
              {
                id: 'RN',
                label: 'Miljøet',
                originalLabel: 'Miljøet',
                note: 'Her: miljøvitenskap. Se også TQ* <a href="/thema/nb/TQ">Miljøvitenskap, -teknikk og -teknologi</a> og dens underkategorier',
              },
            ],
            children: [],
          },
          {
            id: 'RGR',
            label: 'Geografisk oppdagelse og oppdagelsesreiser',
            originalLabel: 'Geografisk oppdagelse og oppdagelsesreiser',
            alternativeLabels: ['oppdagere'],
            note: 'Se også WTLP <a href="/thema/nb/WTLP">Ekspedisjoner</a>',
            related: [
              {
                id: 'WTLP',
                label: 'Ekspedisjoner',
                originalLabel: 'Ekspedisjoner',
                note: 'Her: reiseskildringer om ekspedisjoner og utforskning, for allmennmarkedet. Se også RGR <a href="/thema/nb/RGR">Geografisk oppdagelse og oppdagelsesreiser</a>',
              },
            ],
            children: [],
          },
          {
            id: 'RGV',
            label: 'Kartografi, karttegning og projeksjoner',
            originalLabel: 'Kartografi, karttegning og projeksjoner',
            alternativeLabels: [],
            note: 'Brukes for bøker om kart og det å lage kart, men ikke selve kartene',
            related: [],
            children: [],
          },
          {
            id: 'RGW',
            label: 'Geografiske informasjonssystemer, geodata og fjernanalyse',
            originalLabel:
              'Geografiske informasjonssystemer, geodata og fjernanalyse',
            alternativeLabels: [],
            note: 'Her: geomatikk, kvantitative og kvalitative metoder, geostatistikk, romlig statistikk, GPS, geografisk informasjonsvitenskap, geoinformatikk, geovisualisering, geodesi, fotogrammetri etc. Se også TJKS <a href="/thema/nb/TJKS">Satellittkommunikasjonsteknologi</a>',
            related: [
              {
                id: 'TJKS',
                label: 'Satellittkommunikasjonsteknologi',
                originalLabel: 'Satellittkommunikasjonsteknologi',
                note: 'Her: satellittnavigasjon, bøker om satellittkommunikasjon innen ulike områder som telefoni, TV, internett, datainnsamling, militæret, radiokommunikasjon etc. Se også RGW <a href="/thema/nb/RGW">Geografiske informasjonssystemer, geodata og fjernanalyse</a>',
              },
            ],
            children: [],
          },
          {
            id: 'RGX',
            label: 'Geografiske oppslagsverk',
            originalLabel: 'Geografiske oppslagsverk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'RGXB',
                label: 'Atlas / kart',
                originalLabel: 'Atlas / kart',
                alternativeLabels: ['verdenskart'],
                note: 'Her: globuser og verdenskart eller kart over kontinenter i liten målestokk, nasjonale og regionale atlas eller kart, tematiske atlas. Brukes med kvalifikatorer for geografi og andre koder fra R* for ulike typer av kart, f.eks. RGCP for politiske kart eller atlas, RGB for fysiske eller topografiske kart, RBK for verdensatlas over vannressurser, QRP for atlas over islam etc. Se også NHTP1 <a href="/thema/nb/NHTP1">Historiske kart og atlas</a>, WTR* <a href="/thema/nb/WTR">Kart og atlas</a> og dens underkategorier',
                related: [
                  {
                    id: 'NHTP1',
                    label: 'Historiske kart og atlas',
                    originalLabel: 'Historiske kart og atlas',
                    note: 'Brukes for bøker som presenterer historie gjennom ulike typer kart, inkludert gamle kart eller kart som er laget spesielt for dette formålet, samt historiske atlas. Se også RGXB <a href="/thema/nb/RGXB">Atlas / kart</a>, RGV <a href="/thema/nb/RGV">Kartografi, karttegning og projeksjoner</a>',
                  },
                  {
                    id: 'WTR',
                    label: 'Kart og atlas',
                    originalLabel: 'Kart og atlas',
                    note: 'Bruk WTR* med kvalifikatorer for geografi og med andre koder for spesielle typer kart og atlas, f.eks. med WTHV for togreiser, med WTHX for kart eller atlas ment for turister etc. Se også RGX* <a href="/thema/nb/RGX">Geografiske oppslagsverk</a> og dens underkategorier',
                  },
                ],
                children: [],
              },
              {
                id: 'RGXH',
                label: 'Geografiske kart (spesialkart)',
                originalLabel: 'Geografiske kart (spesialkart)',
                alternativeLabels: ['tematiske kart'],
                note: 'Brukes bare for spesial- og topografiske kart i stor målestokk. Bruk også en annen kode som indikerer et spesifikt emne der det er mulig, f.eks. RBG* for geologiske kart, RBP* for kart over klimasoner, GPH, RGW eller UYZF for tematiske kart som presenterer data eller geovisuelle kart, JHBD for demografiske kart, RP* for planleggingskart etc. Se også WTR* <a href="/thema/nb/WTR">Kart og atlas</a> og dens underkategorier',
                related: [
                  {
                    id: 'WTR',
                    label: 'Kart og atlas',
                    originalLabel: 'Kart og atlas',
                    note: 'Bruk WTR* med kvalifikatorer for geografi og med andre koder for spesielle typer kart og atlas, f.eks. med WTHV for togreiser, med WTHX for kart eller atlas ment for turister etc. Se også RGX* <a href="/thema/nb/RGX">Geografiske oppslagsverk</a> og dens underkategorier',
                  },
                ],
                children: [],
              },
              {
                id: 'RGXM',
                label: 'Sjøkart',
                originalLabel: 'Sjøkart',
                alternativeLabels: [],
                note: 'Her: tidevannstabeller, kart over tidevannsstrømmer. Brukes for detaljerte kart over skipsleden, både på elver, innsjøer og havet. Se også WTR* <a href="/thema/nb/WTR">Kart og atlas</a> og dens underkategorier',
                related: [
                  {
                    id: 'WTR',
                    label: 'Kart og atlas',
                    originalLabel: 'Kart og atlas',
                    note: 'Bruk WTR* med kvalifikatorer for geografi og med andre koder for spesielle typer kart og atlas, f.eks. med WTHV for togreiser, med WTHX for kart eller atlas ment for turister etc. Se også RGX* <a href="/thema/nb/RGX">Geografiske oppslagsverk</a> og dens underkategorier',
                  },
                ],
                children: [],
              },
              {
                id: 'RGXP',
                label: 'Stedsnavn og geografiske oversikter',
                originalLabel: 'Stedsnavn og geografiske oversikter',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'RN',
        label: 'Miljøet',
        originalLabel: 'Miljøet',
        alternativeLabels: [],
        note: 'Her: miljøvitenskap. Se også TQ* <a href="/thema/nb/TQ">Miljøvitenskap, -teknikk og -teknologi</a> og dens underkategorier',
        related: [
          {
            id: 'TQ',
            label: 'Miljøvitenskap, -teknikk og -teknologi',
            originalLabel: 'Miljøvitenskap, -teknikk og -teknologi',
            note: 'Bruk: TQ*-koder for tekniske og praktiske aspekter av miljø, forurensning, avfallshåndtering etc. Bruk PNC for studier og forskning. Se også RN* <a href="/thema/nb/RN">Miljøet</a> og dens underkategorier',
          },
        ],
        children: [
          {
            id: 'RNA',
            label: 'Miljøvernideologi',
            originalLabel: 'Miljøvernideologi',
            alternativeLabels: [],
            note: 'Her: økosentrisme, teknosentrisme, dypøkologi, økofeminisme etc. Se også JPFA <a href="/thema/nb/JPFA">Grønn ideologi / økopolitikk / økologisme</a>',
            related: [
              {
                id: 'JPFA',
                label: 'Grønn ideologi / økopolitikk / økologisme',
                originalLabel: 'Grønn ideologi / økopolitikk / økologisme',
                note: 'Her: grønn politisk tenkning, grønne organisasjoner og bevegelser, miljøaktivister. Se også RNA <a href="/thema/nb/RNA">Miljøvernideologi</a>, RNB <a href="/thema/nb/RNB">Miljøvernere og miljøorganisasjoner</a>',
              },
            ],
            children: [],
          },
          {
            id: 'RNB',
            label: 'Miljøvernere og miljøorganisasjoner',
            originalLabel: 'Miljøvernere og miljøorganisasjoner',
            alternativeLabels: [],
            note: 'Se også JPFA <a href="/thema/nb/JPFA">Grønn ideologi / økopolitikk / økologisme</a>',
            related: [
              {
                id: 'JPFA',
                label: 'Grønn ideologi / økopolitikk / økologisme',
                originalLabel: 'Grønn ideologi / økopolitikk / økologisme',
                note: 'Her: grønn politisk tenkning, grønne organisasjoner og bevegelser, miljøaktivister. Se også RNA <a href="/thema/nb/RNA">Miljøvernideologi</a>, RNB <a href="/thema/nb/RNB">Miljøvernere og miljøorganisasjoner</a>',
              },
            ],
            children: [],
          },
          {
            id: 'RNC',
            label: 'Anvendt økologi',
            originalLabel: 'Anvendt økologi',
            alternativeLabels: [],
            note: 'Brukes for verk som har en bred eller saksbasert tilnærming. Se også PSAF <a href="/thema/nb/PSAF">Økologi, biosfæren</a>',
            related: [
              {
                id: 'PSAF',
                label: 'Økologi, biosfæren',
                originalLabel: 'Økologi, biosfæren',
                note: 'Brukes for omfattende vitenskapelige, objektive og beskrivende verk om levende organismer og deres forhold til omgivelsene. Se også RNC <a href="/thema/nb/RNC">Anvendt økologi</a>',
              },
            ],
            children: [
              {
                id: 'RNCB',
                label: 'Biologisk mangfold',
                originalLabel: 'Biologisk mangfold',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'RND',
            label: 'Miljøpolitikk',
            originalLabel: 'Miljøpolitikk',
            alternativeLabels: [],
            note: 'Brukes med JPQB eller JPRB der det er hensiktsmessig. Se også KCVG <a href="/thema/nb/KCVG">Miljøøkonomi</a>',
            related: [
              {
                id: 'KCVG',
                label: 'Miljøøkonomi',
                originalLabel: 'Miljøøkonomi',
                note: 'Her: grønn økonomi, grønt regnskap, grønn skattepolitikk, økonomiske strategier og politikk for miljøvern, grønn vekst, klimavennlige yrker, bærekraftig utvikling, sirkulær karbonøkonomi, miljøtariff, etc. Brukes for bøker som ser på hvordan økonomien påvirkes av ulike miljøpolitiske vedtak. Se også KFFS <a href="/thema/nb/KFFS">Bærekraftig finans</a>, KJJ <a href="/thema/nb/KJJ">Næringslivet og miljøet. «Grønne» tilnærminger til forretninger</a>',
              },
            ],
            children: [],
          },
          {
            id: 'RNF',
            label: 'Miljøledelse',
            originalLabel: 'Miljøledelse',
            alternativeLabels: [],
            note: 'Her: forvaltning av naturressurser og miljøet. Brukes for bøker som handler om forvaltning av alle typer naturressurser, som luft, land, vann, jord, planter og dyr. Brukes med kode fra RBK* eller RGB* for å indikere et bestemt miljø eller habitat',
            related: [],
            children: [
              {
                id: 'RNFD',
                label: 'Tørke og vannforsyning',
                originalLabel: 'Tørke og vannforsyning',
                alternativeLabels: [],
                note: 'Se også TQSW <a href="/thema/nb/TQSW">Vannforsyning og -behandling</a>',
                related: [
                  {
                    id: 'TQSW',
                    label: 'Vannforsyning og -behandling',
                    originalLabel: 'Vannforsyning og -behandling',
                    note: 'Her: vannrensing og destillering, ressursforvaltning, drikkevannskvalitet, landskapsplanlegging som tar hensyn til vann, bruk av regnvann, infrastruktur. Brukes for bøker som handler om planlegging, utvikling, distribusjon og forvaltning av vannressurser. Se også RNFD <a href="/thema/nb/RNFD">Tørke og vannforsyning</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'RNFF',
                label: 'Matsikkerhet og -forsyning',
                originalLabel: 'Matsikkerhet og -forsyning',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'RNFY',
                label: 'Energiressurser',
                originalLabel: 'Energiressurser',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'RNH',
            label: 'Avfallshåndtering',
            originalLabel: 'Avfallshåndtering',
            alternativeLabels: [],
            note: 'Her: resirkulering, nedbrytbarhet, kildesortering, husholdningsavfall, næringsavfall. Se også TQSR <a href="/thema/nb/TQSR">Avfallshåndtering og -deponering</a>',
            related: [
              {
                id: 'TQSR',
                label: 'Avfallshåndtering og -deponering',
                originalLabel: 'Avfallshåndtering og -deponering',
                note: 'Her: kloakk og fjerning av helsefarlig avfall. Se også RNH <a href="/thema/nb/RNH">Avfallshåndtering</a>',
              },
            ],
            children: [],
          },
          {
            id: 'RNK',
            label: 'Miljøvern',
            originalLabel: 'Miljøvern',
            alternativeLabels: [],
            note: 'Her: bevaring av miljøet, landskapsforvaltning. Brukes med RBK* eller RBG* for å indiktere et bestemt miljø eller habitat',
            related: [],
            children: [
              {
                id: 'RNKH',
                label: 'Bevaring av ville dyr og habitater',
                originalLabel: 'Bevaring av ville dyr og habitater',
                alternativeLabels: [],
                note: 'Her: bevaringsbiologi, det å bevare økosystemer, villmarksområder eller andre naturområder, nasjonalparker etc.',
                related: [],
                children: [
                  {
                    id: 'RNKH1',
                    label: 'Truede arter og artsutryddelse',
                    originalLabel: 'Truede arter og artsutryddelse',
                    alternativeLabels: [],
                    note: 'Her: bøker om mangel på biologisk mangfold',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'RNKH2',
                    label: 'Restaureringsøkologi',
                    originalLabel: 'Restaureringsøkologi',
                    alternativeLabels: ['rewilding'],
                    note: 'Her: det å tilbakeføre naturen til sin opprinnelige ville tilstand',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'RNP',
            label: 'Forurensning og trusler mot miljøet',
            originalLabel: 'Forurensning og trusler mot miljøet',
            alternativeLabels: [
              'oljeutslipp',
              'smog',
              'miljøproblemer',
              'avfallsdeponi',
            ],
            note: 'Her: plastavfall, luftforurensning, vannforurensning, overforbruk. Se også TQK <a href="/thema/nb/TQK">Forurensingskontroll</a>',
            related: [
              {
                id: 'TQK',
                label: 'Forurensingskontroll',
                originalLabel: 'Forurensingskontroll',
                note: 'Se også RNP <a href="/thema/nb/RNP">Forurensning og trusler mot miljøet</a>',
              },
            ],
            children: [
              {
                id: 'RNPD',
                label: 'Avskoging',
                originalLabel: 'Avskoging',
                alternativeLabels: [],
                note: 'Se også TVR <a href="/thema/nb/TVR">Skogbruk og skogkultur</a>',
                related: [
                  {
                    id: 'TVR',
                    label: 'Skogbruk og skogkultur',
                    originalLabel: 'Skogbruk og skogkultur',
                    note: 'Her: trepleie, trebehandling, treplanting. Brukes for bøker for høyere utdanning, allmenn- eller profesjonsmarkedet som handler om praksis og teknikker for å administrere, bruke, bevare og reparere skog og relaterte ressurser, forvaltning av skoger og skogsområder, skogplanting, skogslandbruk, urbant skogbruk, tømmerhogst etc. Se også KNAL <a href="/thema/nb/KNAL">Skogbruk</a>, TDPJ <a href="/thema/nb/TDPJ">Tømmer og treforedling</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'RNPG',
                label: 'Klimaendringer',
                originalLabel: 'Klimaendringer',
                alternativeLabels: [],
                note: 'Her: global oppvarming, drivhuseffekten, ozonlaget, klimakrisen, utslipp av klimagasser, menneskelig påvirkning av jordas værsystemer. Brukes for bøker som handler om klimaendringer, de effektene disse har og mulige tiltak for å begrense konsekvensene av disse, skadebegrensning, avbøtende tiltak, tilpasninger som følge av klimaendringer. Brukes med andre koder for bøker som er på klimaendringer i sammenheng med andre emner, som politikk, økonomi, teknologi etc. Se også TQP <a href="/thema/nb/TQP">Drivhusgasser</a>',
                related: [
                  {
                    id: 'TQP',
                    label: 'Drivhusgasser',
                    originalLabel: 'Drivhusgasser',
                    note: 'Her: bøker om metoder og teknologi for karbonfangst og -lagring, negative utslipp, regulering av klimagassutslipp',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'RNQ',
            label: 'Atomkraftspørsmål',
            originalLabel: 'Atomkraftspørsmål',
            alternativeLabels: [],
            note: 'Her: atomulykker, deponering av radioaktivt avfall',
            related: [],
            children: [],
          },
          {
            id: 'RNR',
            label: 'Naturkatastrofer',
            originalLabel: 'Naturkatastrofer',
            alternativeLabels: [],
            note: 'Her: jordskjelv, vulkanutbrudd, flom, jordras, orkaner, sykloner, tornadoer, tsunamier, tørke, skogbranner etc. Se også JBFF <a href="/thema/nb/JBFF">Sosiale konsekvenser av katastrofer og ulykker</a>',
            related: [
              {
                id: 'JBFF',
                label: 'Sosiale konsekvenser av katastrofer og ulykker',
                originalLabel: 'Sosiale konsekvenser av katastrofer og ulykker',
                note: 'Brukes for bøker som ser på hvordan naturskapte og menneskeskapte katastrofer og ulykker virker inn på samfunnet og menneskers liv; inkludert ting som: pest, epidemier, pandemier, hungersnød, vulkanutbrudd, jordskjelv, ekstremvær, branner, eksplosjoner, osv. Brukes med andre koder der det er hensiktsmessig, med VSY for titler som handler om å forberede seg på nødsituasjoner, f. eks. hva man skal gjøre ved tsunami eller jordskjelv osv. Se også RNR <a href="/thema/nb/RNR">Naturkatastrofer</a>',
              },
            ],
            children: [],
          },
          {
            id: 'RNT',
            label: 'Sosiale konsekvenser av miljøproblemer',
            originalLabel: 'Sosiale konsekvenser av miljøproblemer',
            alternativeLabels: ['klimaendringer'],
            note: 'Her: bøker om konsekvenser av miljøproblemer for enkeltpersoner og samfunnet (som f.eks. ekstremvær, tørke, hetebølger, branner, flom, havnivå som stiger, klimaendringer, forurensning, tap av biologisk mangfold) og innvirkning på personer og samfunnet (som migrasjon, helse, jorderosjon, tap at matjord, matsikkerhet, sult, vannsikkerhet, fattigdom, usikkerhet, økonomisk ulikhet, sosial uro, konflikter, hjemløshet, ødelagt infrastruktur etc.)',
            related: [],
            children: [],
          },
          {
            id: 'RNU',
            label: 'Bærekraft',
            originalLabel: 'Bærekraft',
            alternativeLabels: [],
            note: 'Brukes med andre emnekoder for bærekraft innen spesifikke områder. Se også TBR <a href="/thema/nb/TBR">Tilpasset teknologi</a>, THV <a href="/thema/nb/THV">Alternative og fornybare energikilder og teknologier</a>, VSZ <a href="/thema/nb/VSZ">Selvforsyning og grønn livsstil</a>',
            related: [
              {
                id: 'TBR',
                label: 'Tilpasset teknologi',
                originalLabel: 'Tilpasset teknologi',
              },
              {
                id: 'THV',
                label: 'Alternative og fornybare energikilder og teknologier',
                originalLabel:
                  'Alternative og fornybare energikilder og teknologier',
                note: 'Her: tidevanns- og bølgekraft, saltkraft, havstrømkraft og -teknologi, havvarmekraft, varmepumper, saltkraft. Se også RNU <a href="/thema/nb/RNU">Bærekraft</a>',
              },
              {
                id: 'VSZ',
                label: 'Selvforsyning og grønn livsstil',
                originalLabel: 'Selvforsyning og grønn livsstil',
                note: 'Her: livsstiler som inkluderer det å leve etisk, med lave karbonutslipp, uten plast, enkle og bærekraftige livsstiler, hvordan leve med klimaendringer etc. Brukes for bøker som inneholder praktiske tips og råd, eller forslag til hvordan man kan ha en bærekraftig livsstil, hvordan individer kan redusere sitt karbonavtrykk eller sin påvirkning på miljøet, guider til hvordan man kan gjenbruke og resirkulere og redusere forbruket sitt. Se også RNU <a href="/thema/nb/RNU">Bærekraft</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'RP',
        label: 'Regional- og arealplanlegging',
        originalLabel: 'Regional- og arealplanlegging',
        alternativeLabels: [],
        note: 'Bruk RP*-koder med JPQB eller JPRB eller andre J*-koder der det er hensiktsmessig, f.eks. JKSL for bøker om planlegging for personer med spesielle behov etc.',
        related: [],
        children: [
          {
            id: 'RPC',
            label: 'By- og kommunalplanlegging',
            originalLabel: 'By- og kommunalplanlegging',
            alternativeLabels: ['forvaltning'],
            note: 'Her: smarte byer, urban infrastruktur, bruk av det offentlige rom, gatemøbler, byfornyelse etc. Brukes for bøker om byplanlegging, universell design, tilgjengelighet, bærekraftige byer, boligpolitikk etc. Se også AMVD <a href="/thema/nb/AMVD">Byplanlegging: arkitektoniske aspekter</a>',
            related: [
              {
                id: 'AMVD',
                label: 'Byplanlegging: arkitektoniske aspekter',
                originalLabel: 'Byplanlegging: arkitektoniske aspekter',
                note: 'Se også RPC <a href="/thema/nb/RPC">By- og kommunalplanlegging</a>',
              },
            ],
            children: [],
          },
          {
            id: 'RPG',
            label: 'Distriktsplanlegging',
            originalLabel: 'Distriktsplanlegging',
            alternativeLabels: ['distriktsforvaltning', 'forvaltning'],
            note: 'Brukes for bøker om distriktsplanlegging og -forvaltning, inkludert infrastruktur, bruk av landområder, tilgjengelighet, likebehandling av landområder i distriktene med landområder i urbane områder, landskapsforvaltning',
            related: [],
            children: [],
          },
          {
            id: 'RPT',
            label: 'Transportplanlegging',
            originalLabel: 'Transportplanlegging',
            alternativeLabels: [
              'forvaltning',
              'flyplasser',
              'jernbane',
              'sykkelveier',
              'motorveier',
              'havner',
              'veibygging',
            ],
            note: 'Brukes for bøker om transportplanlegging og -forvaltning, inkludert kollektivtransport, infrastruktur, tilgjengelighet, prisnivå på transporttjenester, smartteknologi innen transport, luftkvalitet og forurensing som kan knyttes til transporttjenester etc.',
            related: [],
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 'S',
    label: 'Idrett og utendørsaktiviteter',
    originalLabel: 'Idrett og utendørsaktiviteter',
    alternativeLabels: [],
    note: 'Bruk S*-koder for bøker for profesjons- og allmennmarkedet. Bruk S*-koder med andre emnekategorier og kvalifikatorer der det er hensiktsmessig, f.eks. med WT* for aktivitetsferier, eller VFM for idrett som del av et treningsregime',
    related: [],
    children: [
      {
        id: 'SC',
        label: 'Idrett',
        originalLabel: 'Idrett',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'SCB',
            label: 'Idrettsarrangementer og organisering',
            originalLabel: 'Idrettsarrangementer og organisering',
            alternativeLabels: [],
            note: 'Bruk SCB*-koder sammen med en kode for spesifikk idrett der det er mulig',
            related: [],
            children: [
              {
                id: 'SCBB',
                label: 'Olympiske og paralympiske leker',
                originalLabel: 'Olympiske og paralympiske leker',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SCBC',
                label: 'Verdensmesterskap og verdenscup',
                originalLabel: 'Verdensmesterskap og verdenscup',
                alternativeLabels: ['FIFA'],
                note: 'Brukes med andre S*-koder for å angi hvilken sport det gjelder',
                related: [],
                children: [],
              },
              {
                id: 'SCBG',
                label: 'Styringsorganer innen idrett',
                originalLabel: 'Styringsorganer innen idrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SCBM',
                label: 'Idrettsorganisering og -anlegg',
                originalLabel: 'Idrettsorganisering og -anlegg',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SCBT',
                label: 'Idrettslag og klubber',
                originalLabel: 'Idrettslag og klubber',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SCBV',
                label: 'Idrettsarenaer',
                originalLabel: 'Idrettsarenaer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'SCG',
            label: 'Trening',
            originalLabel: 'Trening',
            alternativeLabels: [],
            note: 'Her: råd om ernæring, styrke- og kondisjonstrening, bøker for profesjonelle utøvere, trenere eller instruktører, eller råd om hvordan man kan forbedre utøveres resultater. Brukes med VFM* eller WB* der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'SCGF',
                label: 'Idrettsvitenskap, fysisk fostring',
                originalLabel: 'Idrettsvitenskap, fysisk fostring',
                alternativeLabels: ['bevegelsesundervisning'],
                note: 'Her: treningsfysiologi eller kinesologi for profesjonelle idrettsutøvere. Se også MFGV <a href="/thema/nb/MFGV">Biomekanikk</a>',
                related: [
                  {
                    id: 'MFGV',
                    label: 'Biomekanikk',
                    originalLabel: 'Biomekanikk',
                    note: 'Her: treningsfysiologi, kinesiologi, bøker for profesjonsmarkedet om menneskelig bevegelse. Se også SCGF <a href="/thema/nb/SCGF">Idrettsvitenskap, fysisk fostring</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'SCGP',
                label: 'Idrettspsykologi',
                originalLabel: 'Idrettspsykologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'SCK',
            label: 'Medikamentmisbruk i idrett',
            originalLabel: 'Medikamentmisbruk i idrett',
            alternativeLabels: [],
            note: 'Her: doping',
            related: [],
            children: [],
          },
          {
            id: 'SCL',
            label: 'Paraidrett',
            originalLabel: 'Paraidrett',
            alternativeLabels: ['paraidrett'],

            related: [],
            children: [],
          },
          {
            id: 'SCX',
            label: 'Idrettshistorie',
            originalLabel: 'Idrettshistorie',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'SF',
        label: 'Ballsport / ballspill',
        originalLabel: 'Ballsport / ballspill',
        alternativeLabels: [],
        note: 'Her: baskisk pelota, jai alai, krokket, ulama. Brukes for ballsport som ikke har sin egen kode, samt verk som tar for seg ballsport generelt',
        related: [],
        children: [
          {
            id: 'SFB',
            label: 'Fotball og lignende idretter',
            originalLabel: 'Fotball og lignende idretter',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'SFBC',
                label: 'Fotball',
                originalLabel: 'Fotball',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SFBD',
                label: 'Amerikansk fotball',
                originalLabel: 'Amerikansk fotball',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SFBF',
                label: 'Australsk fotball',
                originalLabel: 'Australsk fotball',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SFBH',
                label: 'Kanadisk fotball',
                originalLabel: 'Kanadisk fotball',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SFBK',
                label: 'Gælisk fotball',
                originalLabel: 'Gælisk fotball',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SFBT',
                label: 'Rugby union',
                originalLabel: 'Rugby union',
                alternativeLabels: [],
                note: 'Her: 15-manns rugby',
                related: [],
                children: [],
              },
              {
                id: 'SFBV',
                label: 'Rugby league',
                originalLabel: 'Rugby league',
                alternativeLabels: [],
                note: 'Her: 13-manns rugby',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'SFC',
            label: 'Baseball',
            originalLabel: 'Baseball',
            alternativeLabels: [],
            note: 'Her: softball',
            related: [],
            children: [],
          },
          {
            id: 'SFD',
            label: 'Cricket',
            originalLabel: 'Cricket',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'SFG',
            label: 'Innebandy',
            originalLabel: 'Innebandy',
            alternativeLabels: [],
            note: 'Her: innebandy for rullestol, el-innebandy, rulleskøytehockey, streethockey, inlinehockey og andre hockeyvarianter',
            related: [],
            children: [],
          },
          {
            id: 'SFH',
            label: 'Golf',
            originalLabel: 'Golf',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'SFJ',
            label: 'Landhockey',
            originalLabel: 'Landhockey',
            alternativeLabels: [],
            note: 'Her: innendørsvarianten av hockey. Se også STK <a href="/thema/nb/STK">Ishockey</a>',
            related: [
              {
                id: 'STK',
                label: 'Ishockey',
                originalLabel: 'Ishockey',
                note: 'Se også SFJ <a href="/thema/nb/SFJ">Landhockey</a>',
              },
            ],
            children: [],
          },
          {
            id: 'SFK',
            label: 'Lacrosse',
            originalLabel: 'Lacrosse',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'SFL',
            label: 'Hurling',
            originalLabel: 'Hurling',
            alternativeLabels: [],
            note: 'Her: camogie',
            related: [],
            children: [],
          },
          {
            id: 'SFM',
            label: 'Basketball',
            originalLabel: 'Basketball',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'SFN',
            label: 'Nettball',
            originalLabel: 'Nettball',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'SFP',
            label: 'Volleyball',
            originalLabel: 'Volleyball',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'SFQ',
            label: 'Håndball',
            originalLabel: 'Håndball',
            alternativeLabels: [],
            note: 'Her: alle varianter av håndball, f.eks. gælisk, amerikansk eller australsk håndball',
            related: [],
            children: [],
          },
          {
            id: 'SFT',
            label: 'Racketspill',
            originalLabel: 'Racketspill',
            alternativeLabels: [],
            note: 'Her: padel, padeltennis. Brukes for racketidretter som ikke har en egen kode',
            related: [],
            children: [
              {
                id: 'SFTA',
                label: 'Tennis',
                originalLabel: 'Tennis',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SFTB',
                label: 'Badminton',
                originalLabel: 'Badminton',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SFTC',
                label: 'Squash og rackets (racquets)',
                originalLabel: 'Squash og rackets (racquets)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SFTD',
                label: 'Bordtennis',
                originalLabel: 'Bordtennis',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'SFV',
            label: 'Bowls, bowling, pétanque',
            originalLabel: 'Bowls, bowling, pétanque',
            alternativeLabels: ['boulespill', 'pin bowling'],
            note: 'Her: boule, bocce, boccia, kortbanebowling',
            related: [],
            children: [],
          },
          {
            id: 'SFX',
            label: 'Snooker, biljard, pool',
            originalLabel: 'Snooker, biljard, pool',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'SH',
        label: 'Friidrett, turn og lignende idretter',
        originalLabel: 'Friidrett, turn og lignende idretter',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'SHB',
            label: 'Friidrett',
            originalLabel: 'Friidrett',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'SHBF',
                label: 'Maraton og terrengløp',
                originalLabel: 'Maraton og terrengløp',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SHBM',
                label: 'Idrettsgrener sammensatt av flere disipliner',
                originalLabel: 'Idrettsgrener sammensatt av flere disipliner',
                alternativeLabels: [],
                note: 'Her: triatlon, pentatlon, sjukamp etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'SHG',
            label: 'Turn',
            originalLabel: 'Turn',
            alternativeLabels: ['hopp', 'bom', 'ringer', 'frittstående'],
            note: 'Her: rytmisk sportsgymnastikk, cheerleading, tumbling, trampolineturning. Se også ATXC <a href="/thema/nb/ATXC">Sirkus</a>',
            related: [
              {
                id: 'ATXC',
                label: 'Sirkus',
                originalLabel: 'Sirkus',
                note: 'Her: sirkusartister, klovner, linedansere, akrobater. Se også ATXZ <a href="/thema/nb/ATXZ">Dyr som opptrer</a>',
              },
            ],
            children: [],
          },
          {
            id: 'SHP',
            label: 'Vektløfting',
            originalLabel: 'Vektløfting',
            alternativeLabels: [],
            note: 'Her: styrkeløft. Se også VFMG <a href="/thema/nb/VFMG">Trening og treningsbøker</a>',
            related: [
              {
                id: 'VFMG',
                label: 'Trening og treningsbøker',
                originalLabel: 'Trening og treningsbøker',
                note: 'Her: pilates, aerobics, stretching, fleksibilitet, zumba, spinning, sirkeltrening, CrossFit, dans som trening, intervalltrening etc. Brukes med S*-koder dersom treningsregimet er knyttet til en spesiell idrett',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'SK',
        label: 'Hestesport og dyresport',
        originalLabel: 'Hestesport og dyresport',
        alternativeLabels: [],
        note: 'Her: rodeo, polo. Se også ATXZ1 <a href="/thema/nb/ATXZ1">Tyrefekting</a>, SVH <a href="/thema/nb/SVH">Jakt og skyting</a>',
        related: [
          {
            id: 'ATXZ1',
            label: 'Tyrefekting',
            originalLabel: 'Tyrefekting',
          },
          {
            id: 'SVH',
            label: 'Jakt og skyting',
            originalLabel: 'Jakt og skyting',
          },
        ],
        children: [
          {
            id: 'SKG',
            label: 'Hesteløp',
            originalLabel: 'Hesteløp',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'SKL',
            label: 'Riding, sprangridning og horsemanship',
            originalLabel: 'Riding, sprangridning og horsemanship',
            alternativeLabels: [],
            note: 'Her: feltritt, dressur etc. Se også WNGH <a href="/thema/nb/WNGH">Hester og ponnier</a>',
            related: [
              {
                id: 'WNGH',
                label: 'Hester og ponnier',
                originalLabel: 'Hester og ponnier',
              },
            ],
            children: [],
          },
          {
            id: 'SKR',
            label: 'Hundeløp',
            originalLabel: 'Hundeløp',
            alternativeLabels: [],
            note: 'Se også STLN <a href="/thema/nb/STLN">Hundekjøring</a>',
            related: [
              {
                id: 'STLN',
                label: 'Hundekjøring',
                originalLabel: 'Hundekjøring',
                note: 'Se også SKR <a href="/thema/nb/SKR">Hundeløp</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'SM',
        label: 'Luftsport, motorsport, sykkelsport',
        originalLabel: 'Luftsport, motorsport, sykkelsport',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'SMC',
            label: 'Luftsport og rekreasjon',
            originalLabel: 'Luftsport og rekreasjon',
            alternativeLabels: [],
            note: 'Her: ballongflyging, hang- og paragliding, fallskjermhopping etc.',
            related: [],
            children: [],
          },
          {
            id: 'SMF',
            label: 'Motorsport',
            originalLabel: 'Motorsport',
            alternativeLabels: [],
            note: 'Her: gokart, truck racing og all motorsport som ikke er nevnt nedenfor',
            related: [],
            children: [
              {
                id: 'SMFA',
                label: 'Bilsport',
                originalLabel: 'Bilsport',
                alternativeLabels: [],
                note: 'Her: Formel 1, Grand Prix, Indianapolis 500, 24-timersløpet på Le Mans',
                related: [],
                children: [],
              },
              {
                id: 'SMFC',
                label: 'Rally / rallykjøring',
                originalLabel: 'Rally / rallykjøring',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SMFF',
                label:
                  'Stock car (standardbil modifisert for racing) og dragracing',
                originalLabel:
                  'Stock car (standardbil modifisert for racing) og dragracing',
                alternativeLabels: [],
                note: 'Her: NASCAR, Daytona 500, dragracing',
                related: [],
                children: [],
              },
              {
                id: 'SMFK',
                label: 'Motorsykkelsport',
                originalLabel: 'Motorsykkelsport',
                alternativeLabels: [],
                note: 'Her: motorsykkelløp på vei og bane; TT, Speedway etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'SMQ',
            label: 'Sykkelsport',
            originalLabel: 'Sykkelsport',
            alternativeLabels: [],
            note: 'Her: sykkelløp på vei og velodrom (bane); Tour de France etc. Se også SZD <a href="/thema/nb/SZD">Sykling</a>, WGD <a href="/thema/nb/WGD">Sykler og ikke-motorisert transport</a>',
            related: [
              {
                id: 'SZD',
                label: 'Sykling',
                originalLabel: 'Sykling',
                note: 'Brukes med WTH*-koder for reisehåndbøker og sykkelturer. Se også SMQ <a href="/thema/nb/SMQ">Sykkelsport</a>, WGD <a href="/thema/nb/WGD">Sykler og ikke-motorisert transport</a>',
              },
              {
                id: 'WGD',
                label: 'Sykler og ikke-motorisert transport',
                originalLabel: 'Sykler og ikke-motorisert transport',
                note: 'Her: elsykler, elsparkesykler, guider til sykkelreparasjon. Se også SMQ <a href="/thema/nb/SMQ">Sykkelsport</a>, SZD <a href="/thema/nb/SZD">Sykling</a>',
              },
            ],
            children: [
              {
                id: 'SMQB',
                label: 'BMX',
                originalLabel: 'BMX',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'SMX',
            label: 'Rulleskøyter, rullebrett, etc.',
            originalLabel: 'Rulleskøyter, rullebrett, etc.',
            alternativeLabels: [],
            note: 'Her: aktiviteten; inline rulleskøyter, skateboard, rollerblades',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'SP',
        label: 'Vannsport og rekreasjon',
        originalLabel: 'Vannsport og rekreasjon',
        alternativeLabels: [],
        note: 'Her: vannaerobic, aquafitness, vannpolo',
        related: [],
        children: [
          {
            id: 'SPC',
            label: 'Svømming og stuping',
            originalLabel: 'Svømming og stuping',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'SPCA',
                label: 'Undervannssvømming',
                originalLabel: 'Undervannssvømming',
                alternativeLabels: [],
                note: 'Her: fridykking',
                related: [],
                children: [
                  {
                    id: 'SPCA1',
                    label: 'Apparatdykking',
                    originalLabel: 'Apparatdykking',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'SPCA2',
                    label: 'Snorkling',
                    originalLabel: 'Snorkling',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'SPCD',
                label: 'Stuping',
                originalLabel: 'Stuping',
                alternativeLabels: [],
                note: 'Her: sviktstup, tårnstup',
                related: [],
                children: [],
              },
              {
                id: 'SPCS',
                label: 'Svømming',
                originalLabel: 'Svømming',
                alternativeLabels: [],
                note: 'Her: svømming i åpent vann og basseng',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'SPG',
            label: 'Surfing, brettseiling, vannskisport',
            originalLabel: 'Surfing, brettseiling, vannskisport',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'SPN',
            label: 'Båter: sport og fritid',
            originalLabel: 'Båter: sport og fritid',
            alternativeLabels: ['båtsport'],

            related: [],
            children: [
              {
                id: 'SPND',
                label: 'Motorbåtsport',
                originalLabel: 'Motorbåtsport',
                alternativeLabels: [],
                note: 'Her: racerbåtløp',
                related: [],
                children: [],
              },
              {
                id: 'SPNG',
                label: 'Seiling',
                originalLabel: 'Seiling',
                alternativeLabels: ['seilsport'],
                note: 'Her: det å lære å seile, knuter, spleising og tauverk for seilere, sertifisering etc.',
                related: [],
                children: [],
              },
              {
                id: 'SPNK',
                label: 'Kano- og kajakkpadling',
                originalLabel: 'Kano- og kajakkpadling',
                alternativeLabels: [],
                note: 'Her: rafting',
                related: [],
                children: [],
              },
              {
                id: 'SPNL',
                label: 'Roing',
                originalLabel: 'Roing',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'SR',
        label: 'Kampsport og selvforsvar',
        originalLabel: 'Kampsport og selvforsvar',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'SRB',
            label: 'Boksing',
            originalLabel: 'Boksing',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'SRC',
            label: 'Bryting',
            originalLabel: 'Bryting',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'SRF',
            label: 'Fekting',
            originalLabel: 'Fekting',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'SRM',
            label: 'Kampkunst',
            originalLabel: 'Kampkunst',
            alternativeLabels: [],
            note: 'Her: Krav Maga, historiske europeiske kampkunster som HEMA eller WMA etc. Brukes for generelle verk om kampsport som ikke har sin egen kode',
            related: [],
            children: [
              {
                id: 'SRMA',
                label: 'Aikido',
                originalLabel: 'Aikido',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SRMC',
                label: 'Capoeira',
                originalLabel: 'Capoeira',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SRMJ',
                label: 'Judo',
                originalLabel: 'Judo',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SRMK',
                label: 'Jujutsu',
                originalLabel: 'Jujutsu',
                alternativeLabels: [],
                note: 'Her: Ju jitsu',
                related: [],
                children: [],
              },
              {
                id: 'SRML',
                label: 'Karate',
                originalLabel: 'Karate',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SRMM',
                label: 'Kendo',
                originalLabel: 'Kendo',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SRMN',
                label: 'Kinesisk kampkunst / Kung-fu',
                originalLabel: 'Kinesisk kampkunst / Kung-fu',
                alternativeLabels: [],
                note: 'Her: wushu',
                related: [],
                children: [
                  {
                    id: 'SRMN1',
                    label: 'Tai chi',
                    originalLabel: 'Tai chi',
                    alternativeLabels: [],
                    note: 'Brukes med VFMG for titler som handler om trening, eller VXH titler som handler om helse',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'SRMN2',
                    label: 'Qi gong',
                    originalLabel: 'Qi gong',
                    alternativeLabels: ['qigong'],
                    note: 'Brukes med VFMG for titler som handler om trening, eller VXH titler som handler om helse',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'SRMS',
                label: 'Sumobryting',
                originalLabel: 'Sumobryting',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SRMT',
                label: 'Taekwondo',
                originalLabel: 'Taekwondo',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SRMV',
                label: 'Mixed martial arts (MMA)',
                originalLabel: 'Mixed martial arts (MMA)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'ST',
        label: 'Vintersport',
        originalLabel: 'Vintersport',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'STA',
            label: 'Skisport',
            originalLabel: 'Skisport',
            alternativeLabels: [],
            note: 'Her: telemarkskjøring, freestyle, skicross',
            related: [],
            children: [
              {
                id: 'STAA',
                label: 'Alpint',
                originalLabel: 'Alpint',
                alternativeLabels: [],
                note: 'Her: utfor, slalåm, storslalåm, super-G, frikjøring',
                related: [],
                children: [],
              },
              {
                id: 'STAB',
                label: 'Skiskyting',
                originalLabel: 'Skiskyting',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'STAN',
                label: 'Nordiske grener',
                originalLabel: 'Nordiske grener',
                alternativeLabels: [],
                note: 'Her: kombinert',
                related: [],
                children: [
                  {
                    id: 'STAN1',
                    label: 'Langrenn',
                    originalLabel: 'Langrenn',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'STAN2',
                    label: 'Skihopping',
                    originalLabel: 'Skihopping',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'STC',
            label: 'Snowboard',
            originalLabel: 'Snowboard',
            alternativeLabels: ['snøbrett', 'snøbrettkjøring'],

            related: [],
            children: [],
          },
          {
            id: 'STG',
            label: 'Skøytesport',
            originalLabel: 'Skøytesport',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'STH',
            label: 'Hurtigløp på skøyter',
            originalLabel: 'Hurtigløp på skøyter',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'STJ',
            label: 'Kunstløp',
            originalLabel: 'Kunstløp',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'STK',
            label: 'Ishockey',
            originalLabel: 'Ishockey',
            alternativeLabels: [],
            note: 'Se også SFJ <a href="/thema/nb/SFJ">Landhockey</a>',
            related: [
              {
                id: 'SFJ',
                label: 'Landhockey',
                originalLabel: 'Landhockey',
                note: 'Her: innendørsvarianten av hockey. Se også STK <a href="/thema/nb/STK">Ishockey</a>',
              },
            ],
            children: [],
          },
          {
            id: 'STL',
            label: 'Kjelkesport',
            originalLabel: 'Kjelkesport',
            alternativeLabels: ['bobsled'],
            note: 'Her: aking, skeleton og bobsleigh',
            related: [],
            children: [
              {
                id: 'STLN',
                label: 'Hundekjøring',
                originalLabel: 'Hundekjøring',
                alternativeLabels: ['sledehundkjøring'],
                note: 'Se også SKR <a href="/thema/nb/SKR">Hundeløp</a>',
                related: [
                  {
                    id: 'SKR',
                    label: 'Hundeløp',
                    originalLabel: 'Hundeløp',
                    note: 'Se også STLN <a href="/thema/nb/STLN">Hundekjøring</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'STP',
            label: 'Curling',
            originalLabel: 'Curling',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'SV',
        label: 'Sportsfiske, jakt, skyting',
        originalLabel: 'Sportsfiske, jakt, skyting',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'SVB',
            label: 'Falkejakt',
            originalLabel: 'Falkejakt',
            alternativeLabels: ['falkoneri', 'haukejakt', 'falkonering'],
            note: 'Her: jakt ved hjelp av dresserte rovfugler som falk og hauk',
            related: [],
            children: [],
          },
          {
            id: 'SVF',
            label: 'Sportsfiske',
            originalLabel: 'Sportsfiske',
            alternativeLabels: [],
            note: 'Class here: coarse fishing. Se også KNA <a href="/thema/nb/KNA">Landbruks- og primærnæringer</a>',
            related: [
              {
                id: 'KNA',
                label: 'Landbruks- og primærnæringer',
                originalLabel: 'Landbruks- og primærnæringer',
                note: 'Brukes for generelle verk om disse næringene, hvor det ikke fins egne koder. Se også KCVD <a href="/thema/nb/KCVD">Landbruksøkonomi</a>',
              },
            ],
            children: [
              {
                id: 'SVFF',
                label: 'Fluefiske',
                originalLabel: 'Fluefiske',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'SVFS',
                label: 'Havfiske',
                originalLabel: 'Havfiske',
                alternativeLabels: [],
                note: 'Her: fiske etter store fisketyper som tunfisk og seilfisk',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'SVH',
            label: 'Jakt og skyting',
            originalLabel: 'Jakt og skyting',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'SVHH',
                label: 'Jegeropplæring / skytteropplæring',
                originalLabel: 'Jegeropplæring / skytteropplæring',
                alternativeLabels: ['jegere'],
                note: 'Brukes for bøker om jegerprøven, lisenser, eksamener, sertifisering og kurs knyttet til jakt og skyting',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'SVR',
            label: 'Bueskyting',
            originalLabel: 'Bueskyting',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'SVS',
            label: 'Håndvåpen, pistoler og annet utstyr',
            originalLabel: 'Håndvåpen, pistoler og annet utstyr',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'SVT',
            label: 'Blinkskyting',
            originalLabel: 'Blinkskyting',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'SX',
        label: 'Annen sport og andre konkurranseidretter',
        originalLabel: 'Annen sport og andre konkurranseidretter',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'SXB',
            label: 'Kroppsbygging',
            originalLabel: 'Kroppsbygging',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'SXD',
            label: 'Dart',
            originalLabel: 'Dart',
            alternativeLabels: ['darts'],

            related: [],
            children: [],
          },
          {
            id: 'SXE',
            label: 'E-sport',
            originalLabel: 'E-sport',
            alternativeLabels: ['esport', 'dataspill'],
            note: 'Brukes for profesjonelle gamere, konkurranser i dataspill, gaming',
            related: [],
            children: [],
          },
          {
            id: 'SXQ',
            label: 'Ekstremsport',
            originalLabel: 'Ekstremsport',
            alternativeLabels: [],
            note: 'Her: Strikkhopping etc.',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'SZ',
        label: 'Friluftsaktiviteter',
        originalLabel: 'Friluftsaktiviteter',
        alternativeLabels: [],
        note: 'Her: juving, friløp og parkour, hinderbaner, utendørsaktiviteter. Bruk SZ*-koder også for innendørsaktiviteter som skal tilsvare utendørsaktivitetene. Bruk SZ*-koder med WTH*-koder for reisehåndbøker, VFM* for trening',
        related: [],
        children: [
          {
            id: 'SZC',
            label: 'Fotturer',
            originalLabel: 'Fotturer',
            alternativeLabels: [],
            note: 'Her: gåing, fjellturer. Brukes for bøker om gåing, inkludert fotturer for rekreasjon, trening eller som en spesialisert utendørsaktivitet. Brukes med WHT*-koder for reisehåndbøker eller turveier',
            related: [],
            children: [],
          },
          {
            id: 'SZD',
            label: 'Sykling',
            originalLabel: 'Sykling',
            alternativeLabels: [],
            note: 'Brukes med WTH*-koder for reisehåndbøker og sykkelturer. Se også SMQ <a href="/thema/nb/SMQ">Sykkelsport</a>, WGD <a href="/thema/nb/WGD">Sykler og ikke-motorisert transport</a>',
            related: [
              {
                id: 'SMQ',
                label: 'Sykkelsport',
                originalLabel: 'Sykkelsport',
                note: 'Her: sykkelløp på vei og velodrom (bane); Tour de France etc. Se også SZD <a href="/thema/nb/SZD">Sykling</a>, WGD <a href="/thema/nb/WGD">Sykler og ikke-motorisert transport</a>',
              },
              {
                id: 'WGD',
                label: 'Sykler og ikke-motorisert transport',
                originalLabel: 'Sykler og ikke-motorisert transport',
                note: 'Her: elsykler, elsparkesykler, guider til sykkelreparasjon. Se også SMQ <a href="/thema/nb/SMQ">Sykkelsport</a>, SZD <a href="/thema/nb/SZD">Sykling</a>',
              },
            ],
            children: [],
          },
          {
            id: 'SZE',
            label: 'Løping og jogging',
            originalLabel: 'Løping og jogging',
            alternativeLabels: [],
            note: 'Her: løping på bane eller i terreng, løping som trening. Brukes med WTH*-koder for reisehåndbøker for løpere. Se også SHBF <a href="/thema/nb/SHBF">Maraton og terrengløp</a>',
            related: [
              {
                id: 'SHBF',
                label: 'Maraton og terrengløp',
                originalLabel: 'Maraton og terrengløp',
              },
            ],
            children: [],
          },
          {
            id: 'SZG',
            label: 'Klatring og fjellklatring',
            originalLabel: 'Klatring og fjellklatring',
            alternativeLabels: [],
            note: 'Her: innendørsklatring, Via ferrata, buldring, friklatring, isklatring, rappellering, klatring med og uten tau. Brukes med WTH*-koder for reisehåndbøker for klatrere eller klatreruter',
            related: [],
            children: [],
          },
          {
            id: 'SZK',
            label: 'Orientering',
            originalLabel: 'Orientering',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'SZN',
            label: 'Grotting',
            originalLabel: 'Grotting',
            alternativeLabels: ['grottere'],
            note: 'Class here: Spelunking and speleology',
            related: [],
            children: [],
          },
          {
            id: 'SZR',
            label: 'Camping og villmarksliv',
            originalLabel: 'Camping og villmarksliv',
            alternativeLabels: ['backpacking'],
            note: 'Her: alle typer camping, overlevelsescamping, glamping, ryggsekkturisme',
            related: [],
            children: [],
          },
          {
            id: 'SZV',
            label: 'Overlevelsesteknikker i naturen',
            originalLabel: 'Overlevelsesteknikker i naturen',
            alternativeLabels: ['kartlesing'],
            note: 'Her: teknikker som trengs for å overleve i friluft, navigasjonsteknikker',
            related: [],
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 'T',
    label: 'Teknologi, ingeniørfag, landbruk, industrielle prosesser',
    originalLabel: 'Teknologi, ingeniørfag, landbruk, industrielle prosesser',
    alternativeLabels: [],
    note: 'Brukes for ferdigheter, bransjer og yrker innen disse områdene. Bruk T*-koder for akademiske utgivelser og utgivelser for profesjons- og allmennmarkedet. IKKE bruk koden ‘T’ alene, men velg spesifikke koder fra T*. Bruk alle T*-koder med andre emnekategorier og kvalifikatorer der det er hensiktsmessig, spesielt kvalifikatorer for pedagogisk formål for bøker som er ment for utdanning',
    related: [],
    children: [
      {
        id: 'TB',
        label: 'Teknologi',
        originalLabel: 'Teknologi',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'TBC',
            label: 'Ingeniørfag',
            originalLabel: 'Ingeniørfag',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'TBD',
            label: 'Teknisk design',
            originalLabel: 'Teknisk design',
            alternativeLabels: [],
            note: 'Se også AKP <a href="/thema/nb/AKP">Produktdesign</a>, UGC <a href="/thema/nb/UGC">Dataassistert konstruksjon (DAK)</a>',
            related: [
              {
                id: 'AKP',
                label: 'Produktdesign',
                originalLabel: 'Produktdesign',
                note: 'Her: universell design, universell utforming, industriell design. Brukes for generelle verk om produktdesign, om design av et spesifikt produkt eller typer av produkter, om funksjonalitet og sikkerhet, bærekraftig design av produkter etc. Se også TBD <a href="/thema/nb/TBD">Teknisk design</a>, UGC <a href="/thema/nb/UGC">Dataassistert konstruksjon (DAK)</a>',
              },
              {
                id: 'UGC',
                label: 'Dataassistert konstruksjon (DAK)',
                originalLabel: 'Dataassistert konstruksjon (DAK)',
              },
            ],
            children: [
              {
                id: 'TBDG',
                label: 'Ergonomi',
                originalLabel: 'Ergonomi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TBG',
            label: 'Grafikk og teknisk tegning for ingeniører',
            originalLabel: 'Grafikk og teknisk tegning for ingeniører',
            alternativeLabels: [],
            note: 'Brukes for dataassistert tegning. Brukes med andre koder som f.eks. arkitektur, design eller ingeniørvitenskap der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'TBJ',
            label: 'Matematikk for ingeniører',
            originalLabel: 'Matematikk for ingeniører',
            alternativeLabels: [],
            note: 'Se også PBW <a href="/thema/nb/PBW">Anvendt matematikk</a>',
            related: [
              {
                id: 'PBW',
                label: 'Anvendt matematikk',
                originalLabel: 'Anvendt matematikk',
                note: 'Se også PDE <a href="/thema/nb/PDE">Matematikk for forskere</a>, TBJ <a href="/thema/nb/TBJ">Matematikk for ingeniører</a>, UYAM <a href="/thema/nb/UYAM">Matematikk for informatikkfag</a>',
              },
            ],
            children: [],
          },
          {
            id: 'TBM',
            label: 'Instrumenter og instrumentering',
            originalLabel: 'Instrumenter og instrumentering',
            alternativeLabels: [],
            note: 'Se også TGH <a href="/thema/nb/TGH">Presisjonsinstrumenter</a>',
            related: [
              {
                id: 'TGH',
                label: 'Presisjonsinstrumenter',
                originalLabel: 'Presisjonsinstrumenter',
                note: 'Brukes for bøker om design og produksjon av vitenskapelige, tekniske, medisinske, kjemiske og optiske instrumenter, presisjonsverktøy og -utstyr. Se også TBM <a href="/thema/nb/TBM">Instrumenter og instrumentering</a>',
              },
            ],
            children: [
              {
                id: 'TBMM',
                label: 'Måling og kalibrering',
                originalLabel: 'Måling og kalibrering',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TBN',
            label: 'Nanoteknologi',
            originalLabel: 'Nanoteknologi',
            alternativeLabels: [],
            note: 'Se også PDT <a href="/thema/nb/PDT">Nanovitenskap</a>',
            related: [
              {
                id: 'PDT',
                label: 'Nanovitenskap',
                originalLabel: 'Nanovitenskap',
                note: 'Brukes for nanomaterialer, synteser av nanomaterialer, nanopartikler (kjemi). Se også TBN <a href="/thema/nb/TBN">Nanoteknologi</a>',
              },
            ],
            children: [],
          },
          {
            id: 'TBR',
            label: 'Tilpasset teknologi',
            originalLabel: 'Tilpasset teknologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'TBX',
            label: 'Ingeniørvitenskapens og teknologiens historie',
            originalLabel: 'Ingeniørvitenskapens og teknologiens historie',
            alternativeLabels: [],
            note: 'Se også PDX <a href="/thema/nb/PDX">Vitenskapshistorie</a>',
            related: [
              {
                id: 'PDX',
                label: 'Vitenskapshistorie',
                originalLabel: 'Vitenskapshistorie',
                note: 'Se også TBX <a href="/thema/nb/TBX">Ingeniørvitenskapens og teknologiens historie</a>',
              },
            ],
            children: [],
          },
          {
            id: 'TBY',
            label: 'Oppfinnelser og oppfinnere',
            originalLabel: 'Oppfinnelser og oppfinnere',
            alternativeLabels: [],
            note: 'Her: patenter',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'TC',
        label: 'Biokjemisk teknikk',
        originalLabel: 'Biokjemisk teknikk',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'TCB',
            label: 'Bioteknologi',
            originalLabel: 'Bioteknologi',
            alternativeLabels: [],
            note: 'Her: bioteknologiske ingeniørfag. Brukes med M*, P* eller T* for bruk av bioteknologi innen ulike områder',
            related: [],
            children: [
              {
                id: 'TCBG',
                label: 'Gen- og celleteknologi',
                originalLabel: 'Gen- og celleteknologi',
                alternativeLabels: ['GM'],
                note: 'Her: kloning, stamcelleteknologi, genmodifisering, genomredigering. Se også PSAD <a href="/thema/nb/PSAD">Bioetikk</a>, PSAK <a href="/thema/nb/PSAK">Genetikk (ikke-medisinsk)</a>',
                related: [
                  {
                    id: 'PSAD',
                    label: 'Bioetikk',
                    originalLabel: 'Bioetikk',
                    note: 'Her: etiske spørsmål knyttet til ny utvikling innen biologi og medisin. Se også JBFV <a href="/thema/nb/JBFV">Etiske spørsmål</a>, MBDC <a href="/thema/nb/MBDC">Medisinsk etikk og yrkesutøvelse</a>',
                  },
                  {
                    id: 'PSAK',
                    label: 'Genetikk (ikke-medisinsk)',
                    originalLabel: 'Genetikk (ikke-medisinsk)',
                    note: 'Her: DNA, genom, RNA, transkriptom, genetiske ressurser, genomikk, epigenetikk etc. Se også MFN <a href="/thema/nb/MFN">Medisinsk genetikk</a>, TCBG <a href="/thema/nb/TCBG">Gen- og celleteknologi</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TCBS',
                label: 'Biosensorer',
                originalLabel: 'Biosensorer',
                alternativeLabels: [],
                note: 'Se også TJS <a href="/thema/nb/TJS">Sensorer</a>',
                related: [
                  {
                    id: 'TJS',
                    label: 'Sensorer',
                    originalLabel: 'Sensorer',
                    note: 'Se også TCBS <a href="/thema/nb/TCBS">Biosensorer</a>',
                  },
                ],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'TD',
        label: 'Industriell kjemi og produksjonsteknologi',
        originalLabel: 'Industriell kjemi og produksjonsteknologi',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'TDC',
            label: 'Industriell kjemi og kjemiteknikk',
            originalLabel: 'Industriell kjemi og kjemiteknikk',
            alternativeLabels: [],
            note: 'Her: teknologien bak vaskemidler, gummi, biocider etc.',
            related: [],
            children: [
              {
                id: 'TDCA',
                label: 'Landbrukskjemikalier',
                originalLabel: 'Landbrukskjemikalier',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TDCF',
                label: 'Drivstoff og petrokjemikaler',
                originalLabel: 'Drivstoff og petrokjemikaler',
                alternativeLabels: [],
                note: 'Se også KNBP <a href="/thema/nb/KNBP">Olje-, petroleums- og gassnæringer</a>',
                related: [
                  {
                    id: 'KNBP',
                    label: 'Olje-, petroleums- og gassnæringer',
                    originalLabel: 'Olje-, petroleums- og gassnæringer',
                    note: 'Her: hydraulisk oppsprekking. Brukes for verk som tar for seg disse næringene fra et industri-/nærings-/bransjeperspektiv. Se også RBGL <a href="/thema/nb/RBGL">Ressursgeologi</a>, TDCF <a href="/thema/nb/TDCF">Drivstoff og petrokjemikaler</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TDCJ',
                label: 'Fargestoffer, pigmenter og malingsteknologi',
                originalLabel: 'Fargestoffer, pigmenter og malingsteknologi',
                alternativeLabels: [],
                note: 'Her: kosmetikkteknologi, fargekjemi',
                related: [],
                children: [],
              },
              {
                id: 'TDCP',
                label: 'Plast og polymer',
                originalLabel: 'Plast og polymer',
                alternativeLabels: [],
                note: 'Brukes for alle aspekter ved produksjon av plast. Se også TGMP <a href="/thema/nb/TGMP">Tekniske applikasjoner av polymerer og kompositter</a>',
                related: [
                  {
                    id: 'TGMP',
                    label: 'Tekniske applikasjoner av polymerer og kompositter',
                    originalLabel:
                      'Tekniske applikasjoner av polymerer og kompositter',
                    note: 'Se også TDCP <a href="/thema/nb/TDCP">Plast og polymer</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TDCQ',
                label: 'Keramikk- og glassteknologi',
                originalLabel: 'Keramikk- og glassteknologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TDCT',
                label: 'Næringsmiddelteknologi',
                originalLabel: 'Næringsmiddelteknologi',
                alternativeLabels: [],
                note: 'Her: næringsmiddelvitenskap, teknologi som brukes i bryggerier og destillerier, ernæringslære, nutrasøytisk teknologi og kosttilskudd. Se også KNSB <a href="/thema/nb/KNSB">Serveringsbransjen</a>, PND <a href="/thema/nb/PND">Næringsmiddelkjemi</a>, TTVC <a href="/thema/nb/TTVC">Hotell-, serverings- og restaurantbransjen</a>, WBXD <a href="/thema/nb/WBXD">Drikke: alkoholholdige drikkevarer</a>, WBXD1 <a href="/thema/nb/WBXD1">Vin</a>',
                related: [
                  {
                    id: 'KNSB',
                    label: 'Serveringsbransjen',
                    originalLabel: 'Serveringsbransjen',
                    note: 'Her: catering, restauranter, serveringssteder. Se også TDCT <a href="/thema/nb/TDCT">Næringsmiddelteknologi</a>, TTVC2 <a href="/thema/nb/TTVC2">Catering og matlaging: ferdigheter og bransjer</a>',
                  },
                  {
                    id: 'PND',
                    label: 'Næringsmiddelkjemi',
                    originalLabel: 'Næringsmiddelkjemi',
                    note: 'Her: matvitenskap. Foretrekk TDCT2 for praktiske anvendelser av næringsmiddelkjemi. Se også TDCT2 <a href="/thema/nb/TDCT2">Næringsmiddelproduksjon</a>',
                  },
                  {
                    id: 'TTVC',
                    label: 'Hotell-, serverings- og restaurantbransjen',
                    originalLabel: 'Hotell-, serverings- og restaurantbransjen',
                  },
                  {
                    id: 'WBXD',
                    label: 'Alkoholholdige drikkevarer',
                    originalLabel: 'Drikke: alkoholholdige drikkevarer',
                  },
                  {
                    id: 'WBXD1',
                    label: 'Vin',
                    originalLabel: 'Vin',
                    note: 'Her: vinlegging, vinsmaking, ulike vintyper, sommelier, sette vin og mat sammen, vinatlas, vinguider etc.',
                  },
                ],
                children: [
                  {
                    id: 'TDCT1',
                    label: 'Næringsmiddelsikkerhet',
                    originalLabel: 'Næringsmiddelsikkerhet',
                    alternativeLabels: [],
                    note: 'Her: matkvalitet, virkningen av matregulering og -politikk. Se også RNFF <a href="/thema/nb/RNFF">Matsikkerhet og -forsyning</a>, LNCB7 <a href="/thema/nb/LNCB7">Catering- og restaurantrett</a>, LNTV <a href="/thema/nb/LNTV">Næringsmiddellovgivning</a>',
                    related: [
                      {
                        id: 'LNCB7',
                        label: 'Catering- og restaurantrett',
                        originalLabel: 'Catering- og restaurantrett',
                        note: 'Brukes for bøker om lover og regler som handler om tilberedning av mat for det kommersielle markedet, og lover som har med institusjoner, firmaer eller personer som tilbereder, selger eller serverer mat til kunder, inkludert lover om hygiene, sanitærforhold og sikkerhet. Se også LNTV <a href="/thema/nb/LNTV">Næringsmiddellovgivning</a>',
                      },
                      {
                        id: 'LNTV',
                        label: 'Næringsmiddellovgivning',
                        originalLabel: 'Næringsmiddellovgivning',
                        note: 'Brukes for bøker som tar for seg lover og regler i tilknytning til næringsmiddelstandarder, inkludert import og eksport av mat, reguleringer av dyrevelferd, matsikkerhet, sporbarhet, merking og tilbakekallelser av matvarer, råd om allergier etc. Se også LNCB7 <a href="/thema/nb/LNCB7">Catering- og restaurantrett</a>',
                      },
                      {
                        id: 'RNFF',
                        label: 'Matsikkerhet og -forsyning',
                        originalLabel: 'Matsikkerhet og -forsyning',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'TDCT2',
                    label: 'Næringsmiddelproduksjon',
                    originalLabel: 'Næringsmiddelproduksjon',
                    alternativeLabels: [
                      'Næringsmiddelindustri',
                      'matproduksjon',
                    ],
                    note: 'Her: matproduksjon per ingrediens',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'TDCW',
                label: 'Farmasøytisk kjemi og teknologi',
                originalLabel: 'Farmasøytisk kjemi og teknologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TDCX',
                label: 'Prosessteknikk og -teknologi',
                originalLabel: 'Prosessteknikk og -teknologi',
                alternativeLabels: [],
                note: 'Her: reaksjonsteknologi',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TDP',
            label: 'Andre produksjonsteknologier',
            originalLabel: 'Andre produksjonsteknologier',
            alternativeLabels: [],
            note: 'Brukes for produksjonsprosesser eller -teknologi som ikke har sine egne koder',
            related: [],
            children: [
              {
                id: 'TDPF',
                label: 'Tekstiler og fibre',
                originalLabel: 'Tekstiler og fibre',
                alternativeLabels: [
                  'karding',
                  'klær',
                  'garn',
                  'ull',
                  'bomull',
                  'hamp',
                ],
                note: 'Her: spinning, veving, lærarbeid. Brukes for bøker ment for profesjonsmarkedet eller for studenter innen industriell tekstilproduksjon og tilhørende sektorer, inkludert både naturlige materialer og industrielt framstilte materialer. Se også AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>, KNDD <a href="/thema/nb/KNDD">Tekstilindustri</a>',
                related: [
                  {
                    id: 'AKT',
                    label: 'Mote- og tekstildesign',
                    originalLabel: 'Mote- og tekstildesign',
                    note: 'Brukes for stiler og trender innen motedesign, generelle verk, referanseverk, bøker om spesielle klesplagg, illustrerte verk om mote etc. Bruk AKT*-koder med AKB for spesifikke motedesignere, med AKX for motehistorie, pluss kvalifikator(er) for stil, sted og tidsperiode der det er hensiktsmessig, med AJC* for fotografiske samlinger eller samlinger av motefotografier, med AGZ for praktiske teknikker for motestudenter og -designere. Foretrekk WF*-koder som hovedkategori for bøker ment for allmennmarkedet og som handler om hvordan man skal kle seg, hvordan man skal se moteriktig ut, har praktiske tips etc. Se også TDPF* <a href="/thema/nb/TDPF">Tekstiler og fibre</a> og dens underkategorier, KNDD <a href="/thema/nb/KNDD">Tekstilindustri</a>, KNSX <a href="/thema/nb/KNSX">Mote- og skjønnhetsbransjen</a>, WJF <a href="/thema/nb/WJF">Mote- og stilguider</a>',
                  },
                  {
                    id: 'KNDD',
                    label: 'Tekstilindustri',
                    originalLabel: 'Tekstilindustri',
                    note: 'Brukes for titler som tar for kles- og tekstilindustri. Brukes for verk som tar for seg disse næringene fra et industri-/nærings-/bransjeperspektiv. Se også TDPF <a href="/thema/nb/TDPF">Tekstiler og fibre</a>, AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>',
                  },
                ],
                children: [
                  {
                    id: 'TDPF1',
                    label: 'Klær og mote: teknologi og teknikker',
                    originalLabel: 'Klær og mote: teknologi og teknikker',
                    alternativeLabels: ['skomaker', 'hattemaker', 'modist'],
                    note: 'Her: mønsterkonstruksjon, skreddersøm, skoproduksjon, hattemakeri, veskeproduksjon. Brukes for bøker for profesjonsmarkedet eller studenter innen klesproduksjon, mote eller andre tilhørende sektorer, inkludert accessoirer',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'TDPJ',
                label: 'Tømmer og treforedling',
                originalLabel: 'Tømmer og treforedling',
                alternativeLabels: [],
                note: 'Brukes for alle aspekter ved industriell treproduksjon. Se også KNAL <a href="/thema/nb/KNAL">Skogbruk</a>, TVR <a href="/thema/nb/TVR">Skogbruk og skogkultur</a>',
                related: [
                  {
                    id: 'KNAL',
                    label: 'Skogbruk',
                    originalLabel: 'Skogbruk',
                    note: 'Brukes for titler om skogbruksnæringa eller industri som skaper, forvalter, bruker, konserverer og reparerer skog og lignende ressurser. Se også TDPJ <a href="/thema/nb/TDPJ">Tømmer og treforedling</a>, TVR <a href="/thema/nb/TVR">Skogbruk og skogkultur</a>',
                  },
                  {
                    id: 'TVR',
                    label: 'Skogbruk og skogkultur',
                    originalLabel: 'Skogbruk og skogkultur',
                    note: 'Her: trepleie, trebehandling, treplanting. Brukes for bøker for høyere utdanning, allmenn- eller profesjonsmarkedet som handler om praksis og teknikker for å administrere, bruke, bevare og reparere skog og relaterte ressurser, forvaltning av skoger og skogsområder, skogplanting, skogslandbruk, urbant skogbruk, tømmerhogst etc. Se også KNAL <a href="/thema/nb/KNAL">Skogbruk</a>, TDPJ <a href="/thema/nb/TDPJ">Tømmer og treforedling</a>',
                  },
                ],
                children: [
                  {
                    id: 'TDPJ1',
                    label: 'Papirproduksjon',
                    originalLabel: 'Papirproduksjon',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'TDPM',
                label: 'Metallteknologi og metallurgi',
                originalLabel: 'Metallteknologi og metallurgi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TDPP',
                label: 'Trykking og reproduksjonsteknikker',
                originalLabel: 'Trykking og reproduksjonsteknikker',
                alternativeLabels: [],
                note: 'Se også TDPT <a href="/thema/nb/TDPT">3D-printing</a>',
                related: [
                  {
                    id: 'TDPT',
                    label: '3D-printing',
                    originalLabel: '3D-printing',
                    note: 'Se også TDPP <a href="/thema/nb/TDPP">Trykking og reproduksjonsteknikker</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TDPT',
                label: '3D-printing',
                originalLabel: '3D-printing',
                alternativeLabels: [],
                note: 'Se også TDPP <a href="/thema/nb/TDPP">Trykking og reproduksjonsteknikker</a>',
                related: [
                  {
                    id: 'TDPP',
                    label: 'Trykking og reproduksjonsteknikker',
                    originalLabel: 'Trykking og reproduksjonsteknikker',
                    note: 'Se også TDPT <a href="/thema/nb/TDPT">3D-printing</a>',
                  },
                ],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'TG',
        label: 'Maskinteknikk og materialer',
        originalLabel: 'Maskinteknikk og materialer',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'TGB',
            label: 'Maskinteknikk',
            originalLabel: 'Maskinteknikk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'TGBF',
                label: 'Tribologi (friksjon og smøring)',
                originalLabel: 'Tribologi (friksjon og smøring)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TGBN',
                label: 'Motorer og kraftoverføring',
                originalLabel: 'Motorer og kraftoverføring',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TGH',
            label: 'Presisjonsinstrumenter',
            originalLabel: 'Presisjonsinstrumenter',
            alternativeLabels: ['horologi', 'klokker', 'ur'],
            note: 'Brukes for bøker om design og produksjon av vitenskapelige, tekniske, medisinske, kjemiske og optiske instrumenter, presisjonsverktøy og -utstyr. Se også TBM <a href="/thema/nb/TBM">Instrumenter og instrumentering</a>',
            related: [
              {
                id: 'TBM',
                label: 'Instrumenter og instrumentering',
                originalLabel: 'Instrumenter og instrumentering',
                note: 'Se også TGH <a href="/thema/nb/TGH">Presisjonsinstrumenter</a>',
              },
            ],
            children: [],
          },
          {
            id: 'TGM',
            label: 'Materialvitenskap',
            originalLabel: 'Materialvitenskap',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'TGMB',
                label: 'Teknisk termodynamikk',
                originalLabel: 'Teknisk termodynamikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TGMD',
                label: 'Faststoffmekanikk',
                originalLabel: 'Faststoffmekanikk',
                alternativeLabels: [],
                note: 'Her: dynamikk, vibrasjon, stress, brudd etc.',
                related: [],
                children: [],
              },
              {
                id: 'TGMF',
                label: 'Fluidmekanikk',
                originalLabel: 'Fluidmekanikk',
                alternativeLabels: [],
                note: 'Her: flyt, turbulens, reologi etc. Brukes for anvendelse av fluiddynamikk i teknologi. Se også PHDF <a href="/thema/nb/PHDF">Fysikk: flytende stoffers mekanikk</a>',
                related: [
                  {
                    id: 'PHDF',
                    label: 'Fysikk: flytende stoffers mekanikk',
                    originalLabel: 'Fysikk: flytende stoffers mekanikk',
                    note: 'Her: væskemekanikk, fluidmekanikk',
                  },
                ],
                children: [
                  {
                    id: 'TGMF1',
                    label: 'Aerodynamikk',
                    originalLabel: 'Aerodynamikk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'TGMF2',
                    label: 'Hydraulikk / Pneumatikk',
                    originalLabel: 'Hydraulikk / Pneumatikk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'TGML',
                label: 'Tekniske applikasjoner av biomaterialer',
                originalLabel: 'Tekniske applikasjoner av biomaterialer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TGMM',
                label:
                  'Tekniske applikasjoner av elektroniske, magnetiske, optiske materialer',
                originalLabel:
                  'Tekniske applikasjoner av elektroniske, magnetiske, optiske materialer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TGMP',
                label: 'Tekniske applikasjoner av polymerer og kompositter',
                originalLabel:
                  'Tekniske applikasjoner av polymerer og kompositter',
                alternativeLabels: [],
                note: 'Se også TDCP <a href="/thema/nb/TDCP">Plast og polymer</a>',
                related: [
                  {
                    id: 'TDCP',
                    label: 'Plast og polymer',
                    originalLabel: 'Plast og polymer',
                    note: 'Brukes for alle aspekter ved produksjon av plast. Se også TGMP <a href="/thema/nb/TGMP">Tekniske applikasjoner av polymerer og kompositter</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TGMS',
                label: 'Tekniske applikasjoner av overflatebelegg og -film',
                originalLabel:
                  'Tekniske applikasjoner av overflatebelegg og -film',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TGMT',
                label: 'Materialprøving',
                originalLabel: 'Materialprøving',
                alternativeLabels: [],
                note: 'Brukes for destruktiv og ikke-destruktiv prøving',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TGP',
            label: 'Produksjonsteknikk',
            originalLabel: 'Produksjonsteknikk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'TGPC',
                label: 'Dataassistert produksjon (DAK)',
                originalLabel: 'Dataassistert produksjon (DAK)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TGPQ',
                label: 'Industriell kvalitetskontroll',
                originalLabel: 'Industriell kvalitetskontroll',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TGPR',
                label: 'Sikkerhets- og pålitelighetsteknikk',
                originalLabel: 'Sikkerhets- og pålitelighetsteknikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TGX',
            label: 'Ingeniørfaglige ferdigheter og bransjer',
            originalLabel: 'Ingeniørfaglige ferdigheter og bransjer',
            alternativeLabels: [],
            note: 'Her: verktøyproduksjon, sveising, metallarbeid, mekanikere etc.',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'TH',
        label: 'Energiteknologi og -teknikk',
        originalLabel: 'Energiteknologi og -teknikk',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'THD',
            label: 'Hydrogenteknologi',
            originalLabel: 'Hydrogenteknologi',
            alternativeLabels: ['hydrogen'],

            related: [],
            children: [],
          },
          {
            id: 'THF',
            label: 'Fossilt brensel: teknologi',
            originalLabel: 'Fossilt brensel: teknologi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'THFG',
                label: 'Gassteknologi',
                originalLabel: 'Gassteknologi',
                alternativeLabels: ['gass'],

                related: [],
                children: [],
              },
              {
                id: 'THFP',
                label: 'Petroleumsteknologi',
                originalLabel: 'Petroleumsteknologi',
                alternativeLabels: ['olje'],
                note: 'Her: oljeteknologi',
                related: [],
                children: [],
              },
              {
                id: 'THFS',
                label: 'Fast brensel: teknologi',
                originalLabel: 'Fast brensel: teknologi',
                alternativeLabels: ['kull'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'THK',
            label: 'Kjernekraft og kjernekraftteknikk',
            originalLabel: 'Kjernekraft og kjernekraftteknikk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'THN',
            label: 'Varmeoverføring',
            originalLabel: 'Varmeoverføring',
            alternativeLabels: [],
            note: 'Her: kjøling, industrielle klimaanlegg, varmepumper. Se også TNKH <a href="/thema/nb/TNKH">Varme, belysning og ventilasjon</a>',
            related: [
              {
                id: 'TNKH',
                label: 'Varme, belysning og ventilasjon',
                originalLabel: 'Varme, belysning og ventilasjon',
                note: 'Se også THN <a href="/thema/nb/THN">Varmeoverføring</a>',
              },
            ],
            children: [],
          },
          {
            id: 'THR',
            label: 'Elektroteknikk',
            originalLabel: 'Elektroteknikk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'THRM',
                label: 'Elektriske motorer',
                originalLabel: 'Elektriske motorer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'THRX',
                label: 'Elektrikerfaget: ferdigheter og bransjer',
                originalLabel: 'Elektrikerfaget: ferdigheter og bransjer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'THV',
            label: 'Alternative og fornybare energikilder og teknologier',
            originalLabel:
              'Alternative og fornybare energikilder og teknologier',
            alternativeLabels: ['havstrømteknologi'],
            note: 'Her: tidevanns- og bølgekraft, saltkraft, havstrømkraft og -teknologi, havvarmekraft, varmepumper, saltkraft. Se også RNU <a href="/thema/nb/RNU">Bærekraft</a>',
            related: [
              {
                id: 'RNU',
                label: 'Bærekraft',
                originalLabel: 'Bærekraft',
                note: 'Brukes med andre emnekoder for bærekraft innen spesifikke områder. Se også TBR <a href="/thema/nb/TBR">Tilpasset teknologi</a>, THV <a href="/thema/nb/THV">Alternative og fornybare energikilder og teknologier</a>, VSZ <a href="/thema/nb/VSZ">Selvforsyning og grønn livsstil</a>',
              },
            ],
            children: [
              {
                id: 'THVB',
                label: 'Biodrivstoff',
                originalLabel: 'Biodrivstoff',
                alternativeLabels: ['bioenergi'],
                note: 'Her: energi fra biomasse, biogass',
                related: [],
                children: [],
              },
              {
                id: 'THVG',
                label: 'Geotermisk energi og kraft',
                originalLabel: 'Geotermisk energi og kraft',
                alternativeLabels: [],
                note: 'Her: teknologi som utnytter jordvarme til å produsere elektrisk energi',
                related: [],
                children: [],
              },
              {
                id: 'THVH',
                label: 'Vannkraft',
                originalLabel: 'Vannkraft',
                alternativeLabels: [],
                note: 'Her: demninger, vannmøller, vannhjul',
                related: [],
                children: [],
              },
              {
                id: 'THVS',
                label: 'Solkraft',
                originalLabel: 'Solkraft',
                alternativeLabels: [],
                note: 'Her: solenergi, solcellepaneler, fotovoltaiske paneler, teknologi og systemer knyttet til solenergi, flytende solenergi',
                related: [],
                children: [],
              },
              {
                id: 'THVW',
                label: 'Vindkraft',
                originalLabel: 'Vindkraft',
                alternativeLabels: [],
                note: 'Her: vindmøller, vindturbiner, vindparker, vindenergi, vindkraft på land og havvind, teknologi og systemer knyttet til vindkraft',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'THY',
            label: 'Energi, kraftproduksjon, -distribusjon, og -lagring',
            originalLabel:
              'Energi, kraftproduksjon, -distribusjon, og -lagring',
            alternativeLabels: ['batterier'],
            note: 'Her: kraftnett, -stasjoner og -verk, kraftutnyttelse og -bruk; energiomdanning og -lagring, kraftdistribusjon, kraftkabler, rørledninger, lagre. Brukes med andre TH*-koder for lagring, distribusjon etc. i ulike sektorer',
            related: [],
            children: [
              {
                id: 'THYB',
                label: 'Batteriteknologi / brenselceller',
                originalLabel: 'Batteriteknologi / brenselceller',
                alternativeLabels: [],
                note: 'Her: batterilagringsteknologi, produksjon av batterier',
                related: [],
                children: [],
              },
              {
                id: 'THYC',
                label: 'Energieffektivitet',
                originalLabel: 'Energieffektivitet',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'TJ',
        label: 'Elektronikk og kommunikasjonsteknikk',
        originalLabel: 'Elektronikk og kommunikasjonsteknikk',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'TJD',
            label: 'Haptisk / taktil teknologi',
            originalLabel: 'Haptisk / taktil teknologi',
            alternativeLabels: [],
            note: 'Her: kinestetisk kommunikasjon, taktile overflater på flate skjermer. Brukes for bøker om haptikk i ny teknologi. Brukes med andre emnekategorier for haptikk innen spesielle områder',
            related: [],
            children: [],
          },
          {
            id: 'TJF',
            label: 'Elektronikk',
            originalLabel: 'Elektronikk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'TJFC',
                label: 'Elektronikk: kretser og komponenter',
                originalLabel: 'Elektronikk: kretser og komponenter',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TJFD',
                label: 'Elektronisk utstyr og materialer',
                originalLabel: 'Elektronisk utstyr og materialer',
                alternativeLabels: [],
                note: 'Her: mikroprosessorer, mikrobrikker, transistorer, halv- og superledere etc.',
                related: [],
                children: [],
              },
              {
                id: 'TJFM',
                label: 'Automatisk styrings- og reguleringsteknikk',
                originalLabel: 'Automatisk styrings- og reguleringsteknikk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'TJFM1',
                    label: 'Robotikk',
                    originalLabel: 'Robotikk',
                    alternativeLabels: [],
                    note: 'Her: bøker om design, konstruksjon og bruk av ulike typer roboter, bruk av robotikk og lignende teknologi innen ulike områder',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'TJFN',
                label: 'Mikrobølgeteknologi',
                originalLabel: 'Mikrobølgeteknologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TJK',
            label: 'Kommunikasjonsteknikk / telekommunikasjon',
            originalLabel: 'Kommunikasjonsteknikk / telekommunikasjon',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'TJKD',
                label: 'Radarteknologi',
                originalLabel: 'Radarteknologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TJKH',
                label: 'Signalbehandling',
                originalLabel: 'Signalbehandling',
                alternativeLabels: ['diskret tid', 'kontinuerlig tid'],
                note: 'Her: analog, ikke-lineær og statistisk signalbehandling. Se også UYS <a href="/thema/nb/UYS">Digital signalbehandling</a>',
                related: [
                  {
                    id: 'UYS',
                    label: 'Digital signalbehandling',
                    originalLabel: 'Digital signalbehandling',
                  },
                ],
                children: [],
              },
              {
                id: 'TJKR',
                label: 'Radioteknologi',
                originalLabel: 'Radioteknologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TJKS',
                label: 'Satellittkommunikasjonsteknologi',
                originalLabel: 'Satellittkommunikasjonsteknologi',
                alternativeLabels: [],
                note: 'Her: satellittnavigasjon, bøker om satellittkommunikasjon innen ulike områder som telefoni, TV, internett, datainnsamling, militæret, radiokommunikasjon etc. Se også RGW <a href="/thema/nb/RGW">Geografiske informasjonssystemer, geodata og fjernanalyse</a>',
                related: [
                  {
                    id: 'RGW',
                    label:
                      'Geografiske informasjonssystemer, geodata og fjernanalyse',
                    originalLabel:
                      'Geografiske informasjonssystemer, geodata og fjernanalyse',
                    note: 'Her: geomatikk, kvantitative og kvalitative metoder, geostatistikk, romlig statistikk, GPS, geografisk informasjonsvitenskap, geoinformatikk, geovisualisering, geodesi, fotogrammetri etc. Se også TJKS <a href="/thema/nb/TJKS">Satellittkommunikasjonsteknologi</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TJKT',
                label: 'Telefonteknologi',
                originalLabel: 'Telefonteknologi',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'TJKT1',
                    label: 'Mobiltelefonteknologi',
                    originalLabel: 'Mobiltelefonteknologi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'TJKV',
                label: 'Fjernsynsteknologi',
                originalLabel: 'Fjernsynsteknologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TJKW',
                label: 'Trådløs teknologi (WAP)',
                originalLabel: 'Trådløs teknologi (WAP)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TJS',
            label: 'Sensorer',
            originalLabel: 'Sensorer',
            alternativeLabels: [],
            note: 'Se også TCBS <a href="/thema/nb/TCBS">Biosensorer</a>',
            related: [
              {
                id: 'TCBS',
                label: 'Biosensorer',
                originalLabel: 'Biosensorer',
                note: 'Se også TJS <a href="/thema/nb/TJS">Sensorer</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'TN',
        label: 'Byggeteknikk, landmåling og bygging',
        originalLabel: 'Byggeteknikk, landmåling og bygging',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'TNC',
            label: 'Konstruksjonsteknikk',
            originalLabel: 'Konstruksjonsteknikk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'TNCB',
                label: 'Landmåling',
                originalLabel: 'Landmåling',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TNCC',
                label: 'Jord- og bergmekanikk',
                originalLabel: 'Jord- og bergmekanikk',
                alternativeLabels: [],
                note: 'Her: geoteknisk ingeniørarbeid, geoteknikk. Se også RBGB <a href="/thema/nb/RBGB">Sedimentologi og pedologi</a>',
                related: [
                  {
                    id: 'RBGB',
                    label: 'Sedimentologi og pedologi',
                    originalLabel: 'Sedimentologi og pedologi',
                    note: 'Se også PNC <a href="/thema/nb/PNC">Miljøkjemi</a>, TNCC <a href="/thema/nb/TNCC">Jord- og bergmekanikk</a>, TVBP <a href="/thema/nb/TVBP">Jordbruksvitenskap</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TNCE',
                label: 'Jordskjelvteknikk',
                originalLabel: 'Jordskjelvteknikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TNCJ',
                label: 'Broer',
                originalLabel: 'Broer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TNF',
            label: 'Hydroteknikk',
            originalLabel: 'Hydroteknikk',
            alternativeLabels: [],
            note: 'Her: teknikk rundt demninger, reservoarer, havner etc.; også landvinning og drenering',
            related: [],
            children: [
              {
                id: 'TNFD',
                label: 'Kystteknikk',
                originalLabel: 'Kystteknikk',
                alternativeLabels: [],
                note: 'Brukes for bøker om design, bygging og vedlikehold av bygninger og byggverk ved/nær kysten, eller utvikling av selve kystområdet, eller arbeid knyttet til utvikling av havner, kystforsvar, beskyttelse mot økning av havnivået, flom etc. Se også TTS <a href="/thema/nb/TTS">Marin teknikk</a>',
                related: [
                  {
                    id: 'TTS',
                    label: 'Marin teknikk',
                    originalLabel: 'Marin teknikk',
                    note: 'Her: offshoreteknologi, sonar, marinteknologi, havteknologi etc. Brukes for bøker om å bygge skip eller andre fartøy eller strukturer som brukes til havs. Brukes med THVW for havvindteknologi, THFP for oljeplattformer, oljeboring etc. Se også TRLD <a href="/thema/nb/TRLD">Skipsdesign og -arkitektur</a>, TNFD <a href="/thema/nb/TNFD">Kystteknikk</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TNFL',
                label: 'Flomvern',
                originalLabel: 'Flomvern',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TNH',
            label: 'Vei- og trafikkingeniørfaget',
            originalLabel: 'Vei- og trafikkingeniørfaget',
            alternativeLabels: [],
            note: 'Her: veibygging',
            related: [],
            children: [],
          },
          {
            id: 'TNK',
            label: 'Bygningskonstruksjon og -materialer',
            originalLabel: 'Bygningskonstruksjon og -materialer',
            alternativeLabels: [],
            note: 'Her: bøker om bygningskonstruksjon for profesjonsmarkedet. Bruk TNK* med RNU for bøker om bærekraftig bygging og konstruksjon, med KJMP for prosjektledelse',
            related: [],
            children: [
              {
                id: 'TNKA',
                label: 'Tilgjengelighet i bygninger og bygningsdesign',
                originalLabel: 'Tilgjengelighet i bygninger og bygningsdesign',
                alternativeLabels: ['universell design'],
                note: 'Her: universell utforming, barrierefri, trappefri eller tilgjengelig design og konstruksjon. Se også RPC <a href="/thema/nb/RPC">By- og kommunalplanlegging</a>',
                related: [
                  {
                    id: 'RPC',
                    label: 'By- og kommunalplanlegging',
                    originalLabel: 'By- og kommunalplanlegging',
                    note: 'Her: smarte byer, urban infrastruktur, bruk av det offentlige rom, gatemøbler, byfornyelse etc. Brukes for bøker om byplanlegging, universell design, tilgjengelighet, bærekraftige byer, boligpolitikk etc. Se også AMVD <a href="/thema/nb/AMVD">Byplanlegging: arkitektoniske aspekter</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TNKD',
                label: 'Bygg- og anleggsstandarder',
                originalLabel: 'Bygg- og anleggsstandarder',
                alternativeLabels: [],
                note: 'Her: byggeforskrifter, bøker som forklarer regler og reguleringer rundt det å bygge, restaurere, reparere etc., ofte, men ikke nødvendigvis for profesjonsmarkedet. Brukes med TNK* for å angi spesielle områder. Se også LNCQ* <a href="/thema/nb/LNCQ">Bygg- og anlegg: lov og rett</a> og dens underkategorier',
                related: [
                  {
                    id: 'LNCQ',
                    label: 'Bygg- og anlegg: lov og rett',
                    originalLabel: 'Bygg- og anlegg: lov og rett',
                  },
                ],
                children: [],
              },
              {
                id: 'TNKE',
                label: 'Bygningsfysikk og energieffektive bygg',
                originalLabel: 'Bygningsfysikk og energieffektive bygg',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TNKF',
                label: 'Brannvern og -sikkerhet',
                originalLabel: 'Brannvern og -sikkerhet',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TNKH',
                label: 'Varme, belysning og ventilasjon',
                originalLabel: 'Varme, belysning og ventilasjon',
                alternativeLabels: [],
                note: 'Se også THN <a href="/thema/nb/THN">Varmeoverføring</a>',
                related: [
                  {
                    id: 'THN',
                    label: 'Varmeoverføring',
                    originalLabel: 'Varmeoverføring',
                    note: 'Her: kjøling, industrielle klimaanlegg, varmepumper. Se også TNKH <a href="/thema/nb/TNKH">Varme, belysning og ventilasjon</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TNKP',
                label: 'Byggeprosjekter: planlegging',
                originalLabel: 'Byggeprosjekter: planlegging',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TNKR',
                label: 'Bygningssanering, ettermontering, renovering',
                originalLabel: 'Bygningssanering, ettermontering, renovering',
                alternativeLabels: [],
                note: 'Brukes for praktiske og tekniske guider til sanering, ettermontering eller renovering av infrastruktur, inkludert bygninger, teknisk tilnærming til, adaptiv gjenbruk av strukturer, ettermontering av ny teknologi, f.eks. for beskyttelse mot flom, varme, brann, jordskjelv etc. Brukes med andre koder fra T* som f.eks. TH* for ettermontering av energiteknologi, TNKX for teknisk tilnærming til bevaring av strukturer, med AM* for arkitektonisk tilnærming til emnet, med RNU for bøker om bærekraftig renovasjon',
                related: [],
                children: [],
              },
              {
                id: 'TNKS',
                label: 'Sikkerhets- og brannalarmsystem',
                originalLabel: 'Sikkerhets- og brannalarmsystem',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TNKX',
                label: 'Bevaring av bygninger og bygningsmaterialer',
                originalLabel: 'Bevaring av bygninger og bygningsmaterialer',
                alternativeLabels: [],
                note: 'Brukes for praktiske og tekniske guider til bevaring eller restaurering av bygninger og strukturer, inkludert historiske bygninger og ferdigheter og håndverk som forbindes med dette',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TNT',
            label: 'Byggkompetanse',
            originalLabel: 'Byggkompetanse og bransjer',
            alternativeLabels: [],
            note: 'Her: bøker om konstruksjon, håndverksferdigheter og -områder. Se også THRX <a href="/thema/nb/THRX">Elektrikerfaget: ferdigheter og bransjer</a>',
            related: [
              {
                id: 'THRX',
                label: 'Elektrikerfaget: ferdigheter og bransjer',
                originalLabel: 'Elektrikerfaget: ferdigheter og bransjer',
              },
            ],
            children: [
              {
                id: 'TNTB',
                label: 'Murerarbeid, flislegging, sementarbeid',
                originalLabel: 'Murerarbeid, flislegging, sementarbeid',
                alternativeLabels: ['muring', 'murer', 'murpuss'],
                note: 'Brukes med kvalifikatorer fra 4CP* der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'TNTC',
                label: 'Snekkerarbeid',
                originalLabel: 'Snekkerarbeid',
                alternativeLabels: ['snekring'],
                note: 'Brukes med kvalifikatorer fra 4CP* der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'TNTP',
                label: 'Rørleggerarbeid',
                originalLabel: 'Rørleggerarbeid',
                alternativeLabels: [],
                note: 'Brukes med kvalifikatorer fra 4CP* der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'TNTR',
                label: 'Taktekking',
                originalLabel: 'Taktekking',
                alternativeLabels: [],
                note: 'Brukes med kvalifikatorer fra 4CP* der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'TQ',
        label: 'Miljøvitenskap, -teknikk og -teknologi',
        originalLabel: 'Miljøvitenskap, -teknikk og -teknologi',
        alternativeLabels: [],
        note: 'Bruk: TQ*-koder for tekniske og praktiske aspekter av miljø, forurensning, avfallshåndtering etc. Bruk PNC for studier og forskning. Se også RN* <a href="/thema/nb/RN">Miljøet</a> og dens underkategorier',
        related: [
          {
            id: 'RN',
            label: 'Miljøet',
            originalLabel: 'Miljøet',
            note: 'Her: miljøvitenskap. Se også TQ* <a href="/thema/nb/TQ">Miljøvitenskap, -teknikk og -teknologi</a> og dens underkategorier',
          },
        ],
        children: [
          {
            id: 'TQD',
            label: 'Miljøovervåking',
            originalLabel: 'Miljøovervåking',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'TQK',
            label: 'Forurensingskontroll',
            originalLabel: 'Forurensingskontroll',
            alternativeLabels: [],
            note: 'Se også RNP <a href="/thema/nb/RNP">Forurensning og trusler mot miljøet</a>',
            related: [
              {
                id: 'RNP',
                label: 'Forurensning og trusler mot miljøet',
                originalLabel: 'Forurensning og trusler mot miljøet',
                note: 'Her: plastavfall, luftforurensning, vannforurensning, overforbruk. Se også TQK <a href="/thema/nb/TQK">Forurensingskontroll</a>',
              },
            ],
            children: [],
          },
          {
            id: 'TQP',
            label: 'Drivhusgasser',
            originalLabel: 'Drivhusgasser',
            alternativeLabels: [
              'CO2-fangst',
              'CDR',
              'NET',
              'karbonlagring',
              'GGR',
              'drivhusgasser',
            ],
            note: 'Her: bøker om metoder og teknologi for karbonfangst og -lagring, negative utslipp, regulering av klimagassutslipp',
            related: [],
            children: [],
          },
          {
            id: 'TQS',
            label: 'Sanitær- og kommunalteknikk',
            originalLabel: 'Sanitær- og kommunalteknikk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'TQSR',
                label: 'Avfallshåndtering og -deponering',
                originalLabel: 'Avfallshåndtering og -deponering',
                alternativeLabels: [],
                note: 'Her: kloakk og fjerning av helsefarlig avfall. Se også RNH <a href="/thema/nb/RNH">Avfallshåndtering</a>',
                related: [
                  {
                    id: 'RNH',
                    label: 'Avfallshåndtering',
                    originalLabel: 'Avfallshåndtering',
                    note: 'Her: resirkulering, nedbrytbarhet, kildesortering, husholdningsavfall, næringsavfall. Se også TQSR <a href="/thema/nb/TQSR">Avfallshåndtering og -deponering</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TQSW',
                label: 'Vannforsyning og -behandling',
                originalLabel: 'Vannforsyning og -behandling',
                alternativeLabels: [],
                note: 'Her: vannrensing og destillering, ressursforvaltning, drikkevannskvalitet, landskapsplanlegging som tar hensyn til vann, bruk av regnvann, infrastruktur. Brukes for bøker som handler om planlegging, utvikling, distribusjon og forvaltning av vannressurser. Se også RNFD <a href="/thema/nb/RNFD">Tørke og vannforsyning</a>',
                related: [
                  {
                    id: 'RNFD',
                    label: 'Tørke og vannforsyning',
                    originalLabel: 'Tørke og vannforsyning',
                    note: 'Se også TQSW <a href="/thema/nb/TQSW">Vannforsyning og -behandling</a>',
                  },
                ],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'TR',
        label: 'Transportteknologi og -næringer',
        originalLabel: 'Transportteknologi og -næringer',
        alternativeLabels: [],
        note: 'Se også KNG <a href="/thema/nb/KNG">Transportnæringer</a>, RPT <a href="/thema/nb/RPT">Transportplanlegging</a>, WG* <a href="/thema/nb/WG">Transport</a> og dens underkategorier',
        related: [
          {
            id: 'KNG',
            label: 'Transportnæringer',
            originalLabel: 'Transportnæringer',
            note: 'Her: alle transportnæringer; vei, shipping, jernbane, luftfart etc. Brukes for verk som tar for seg disse bransjene fra et industri-/nærings-/bransjeperspektiv. Bruk med WG*-koder for å angi hvilken type kjøretøy det er snakk om der det er hensiktsmessig. Se også KNDR <a href="/thema/nb/KNDR">Produksjon av kjøretøy og transportmidler</a>, TR* <a href="/thema/nb/TR">Transportteknologi og -næringer</a> og dens underkategorier, WG* <a href="/thema/nb/WG">Transport</a> og dens underkategorier',
          },
          {
            id: 'RPT',
            label: 'Transportplanlegging',
            originalLabel: 'Transportplanlegging',
            note: 'Brukes for bøker om transportplanlegging og -forvaltning, inkludert kollektivtransport, infrastruktur, tilgjengelighet, prisnivå på transporttjenester, smartteknologi innen transport, luftkvalitet og forurensing som kan knyttes til transporttjenester etc.',
          },
          {
            id: 'WG',
            label: 'Transport',
            originalLabel: 'Transport',
            note: 'Se også KNG <a href="/thema/nb/KNG">Transportnæringer</a>, TR* <a href="/thema/nb/TR">Transportteknologi og -næringer</a> og dens underkategorier',
          },
        ],
        children: [
          {
            id: 'TRC',
            label: 'Bilteknologi',
            originalLabel: 'Bilteknologi og bransjer',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'TRCS',
                label: 'Bilmekanikerfaget',
                originalLabel: 'Bilmekanikerfaget',
                alternativeLabels: [],
                note: 'Se også WGCV <a href="/thema/nb/WGCV">Kjøretøy: vedlikehold og manualer</a>',
                related: [
                  {
                    id: 'WGCV',
                    label: 'Kjøretøy: vedlikehold og manualer',
                    originalLabel: 'Kjøretøy: vedlikehold og manualer',
                    note: 'Se også TRCS <a href="/thema/nb/TRCS">Bilmekanikerfaget</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TRCT',
                label: 'Veitransport og transportnæringer',
                originalLabel: 'Veitransport og transportnæringer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TRF',
            label: 'Jernbaneteknologi, -teknikk og -næringer',
            originalLabel: 'Jernbaneteknologi, -teknikk og -næringer',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'TRFT',
                label: 'Jernbanebransjer',
                originalLabel: 'Jernbanebransjer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TRL',
            label: 'Skipsbygging: teknologi, teknikk og næring',
            originalLabel: 'Skipsbygging: teknologi, teknikk og næring',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'TRLD',
                label: 'Skipsdesign og -arkitektur',
                originalLabel: 'Skipsdesign og -arkitektur',
                alternativeLabels: [],
                note: 'Se også TTS <a href="/thema/nb/TTS">Marin teknikk</a>',
                related: [
                  {
                    id: 'TTS',
                    label: 'Marin teknikk',
                    originalLabel: 'Marin teknikk',
                    note: 'Her: offshoreteknologi, sonar, marinteknologi, havteknologi etc. Brukes for bøker om å bygge skip eller andre fartøy eller strukturer som brukes til havs. Brukes med THVW for havvindteknologi, THFP for oljeplattformer, oljeboring etc. Se også TRLD <a href="/thema/nb/TRLD">Skipsdesign og -arkitektur</a>, TNFD <a href="/thema/nb/TNFD">Kystteknikk</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TRLN',
                label: 'Navigering og sjømannskap',
                originalLabel: 'Navigering og sjømannskap',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TRLT',
                label: 'Maritime bransjer',
                originalLabel: 'Maritime bransjer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TRP',
            label: 'Romfarts- og luftfartsteknologi',
            originalLabel: 'Romfarts- og luftfartsteknologi',
            alternativeLabels: ['droner'],
            note: 'Her: ubemannede luftfartøy og droneteknologi, luftfarts- og romfartsteknologi, avionikk',
            related: [],
            children: [
              {
                id: 'TRPS',
                label: 'Fly- og pilotferdigheter',
                originalLabel: 'Fly- og pilotferdigheter',
                alternativeLabels: ['pilotsertifikat'],
                note: 'Her: det å lære å fly, flysertifikat',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TRT',
            label: 'Transportsystem: intelligent og automatisert teknologi',
            originalLabel:
              'Transportsystem: intelligent og automatisert teknologi',
            alternativeLabels: [],
            note: 'Her: selvkjørende biler, busser og andre kjøretøy, avanserte systemer som assisterer sjåføren. Brukes med TR* for ulike typer transport',
            related: [],
            children: [],
          },
          {
            id: 'TRV',
            label: 'Elkjøretøy, elsykler og andre fossilfrie kjøretøy',
            originalLabel: 'Elkjøretøy, elsykler og andre fossilfrie kjøretøy',
            alternativeLabels: ['hybridkjøretøy', 'hydrogenkjøretøy'],
            note: 'Her: kjøretøy som bruker alternative drivstoff, elbiler, hybridbiler, hydrogenbiler, brenselcellebiler, brenselcelleelektriske kjøretøy, nye kjøretøyteknologier, ammoniakk som drivstoff, gassdrevne kjøretøy. Brukes for alle typer teknologi som driver en motor, og som ikke baserer seg kun på bensinbaserte drivstoff. Brukes med andre TR*-koder for spesifikke typer transport, f.eks. TRL* for alternative drivstoff for skip',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'TT',
        label: 'Annen anvendt vitenskap og teknologi',
        originalLabel: 'Annen anvendt vitenskap og teknologi',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'TTA',
            label: 'Akustisk teknologi og lydteknikk',
            originalLabel: 'Akustisk teknologi og lydteknikk',
            alternativeLabels: [],
            note: 'Her: lyddesign, lydeffekter, lydingeniørarbeid',
            related: [],
            children: [],
          },
          {
            id: 'TTB',
            label: 'Anvendt optikk',
            originalLabel: 'Anvendt optikk',
            alternativeLabels: [],
            note: 'Her: anvendt fotonikk, optoelektronikk, optomekanikk eller andre bruksområder for optikk innen teknologi',
            related: [],
            children: [
              {
                id: 'TTBF',
                label: 'Fiberoptikk',
                originalLabel: 'Fiberoptikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TTBL',
                label: 'Laserteknologi og holografi',
                originalLabel: 'Laserteknologi og holografi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TTBM',
                label: 'Bildebehandling: systemer og teknologi',
                originalLabel: 'Bildebehandling: systemer og teknologi',
                alternativeLabels: [],
                note: 'Se også MKS* <a href="/thema/nb/MKS">Medisinsk avbildning</a> og dens underkategorier',
                related: [
                  {
                    id: 'MKS',
                    label: 'Medisinsk avbildning',
                    originalLabel: 'Medisinsk avbildning',
                    note: 'Se også TTBM <a href="/thema/nb/TTBM">Bildebehandling: systemer og teknologi</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TTBS',
                label: 'Skanning: systemer og teknologi',
                originalLabel: 'Skanning: systemer og teknologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TTD',
            label: 'Romforskning',
            originalLabel: 'Romforskning',
            alternativeLabels: [],
            note: 'Se også WNX <a href="/thema/nb/WNX">Astronomi og verdensrommet</a>',
            related: [
              {
                id: 'WNX',
                label: 'Astronomi og verdensrommet',
                originalLabel: 'Astronomi og verdensrommet',
                note: 'Her: stjernetegn og stjerneatlas, samt astronomi, verdensrommet og romfart for allmennmarkedet. Se også PG* <a href="/thema/nb/PG">Astronomi, sted og tid</a> og dens underkategorier, TTD <a href="/thema/nb/TTD">Romforskning</a>',
              },
            ],
            children: [
              {
                id: 'TTDS',
                label: 'Astronautikk',
                originalLabel: 'Astronautikk',
                alternativeLabels: [],
                note: 'Her: tekniske aspekter ved romforskning, for eksempel romskipdesign og -teknologi',
                related: [],
                children: [],
              },
              {
                id: 'TTDX',
                label: 'Utforsking av verdensrommet',
                originalLabel: 'Utforsking av verdensrommet',
                alternativeLabels: ['universet'],
                note: 'Her: bemannede og ubemannede romferder og -programmer, Apollo, Vostok, Sputnik, Voyager etc. Se også WNX <a href="/thema/nb/WNX">Astronomi og verdensrommet</a>',
                related: [
                  {
                    id: 'WNX',
                    label: 'Astronomi og verdensrommet',
                    originalLabel: 'Astronomi og verdensrommet',
                    note: 'Her: stjernetegn og stjerneatlas, samt astronomi, verdensrommet og romfart for allmennmarkedet. Se også PG* <a href="/thema/nb/PG">Astronomi, sted og tid</a> og dens underkategorier, TTD <a href="/thema/nb/TTD">Romforskning</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'TTG',
            label: 'Mekatronikk',
            originalLabel: 'Mekatronikk',
            alternativeLabels: [],
            note: 'Her: tverrfaglige bøker som handler om å kombinere systemer og prinsipper innen mekanikk, elektronikk, robotikk, datateknikk og informasjonsteknologi',
            related: [],
            children: [],
          },
          {
            id: 'TTM',
            label: 'Militærteknikk',
            originalLabel: 'Militærteknikk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'TTMW',
                label: 'Ammunisjons- og våpenteknologi',
                originalLabel: 'Ammunisjons- og våpenteknologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TTP',
            label: 'Sprengstoff- og pyroteknikk',
            originalLabel: 'Sprengstoff- og pyroteknikk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'TTS',
            label: 'Marin teknikk',
            originalLabel: 'Marin teknikk',
            alternativeLabels: [],
            note: 'Her: offshoreteknologi, sonar, marinteknologi, havteknologi etc. Brukes for bøker om å bygge skip eller andre fartøy eller strukturer som brukes til havs. Brukes med THVW for havvindteknologi, THFP for oljeplattformer, oljeboring etc. Se også TRLD <a href="/thema/nb/TRLD">Skipsdesign og -arkitektur</a>, TNFD <a href="/thema/nb/TNFD">Kystteknikk</a>',
            related: [
              {
                id: 'TNFD',
                label: 'Kystteknikk',
                originalLabel: 'Kystteknikk',
                note: 'Brukes for bøker om design, bygging og vedlikehold av bygninger og byggverk ved/nær kysten, eller utvikling av selve kystområdet, eller arbeid knyttet til utvikling av havner, kystforsvar, beskyttelse mot økning av havnivået, flom etc. Se også TTS <a href="/thema/nb/TTS">Marin teknikk</a>',
              },
              {
                id: 'TRLD',
                label: 'Skipsdesign og -arkitektur',
                originalLabel: 'Skipsdesign og -arkitektur',
                note: 'Se også TTS <a href="/thema/nb/TTS">Marin teknikk</a>',
              },
            ],
            children: [],
          },
          {
            id: 'TTU',
            label: 'Gruveteknologi- og teknikk',
            originalLabel: 'Gruveteknologi- og teknikk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'TTV',
            label: 'Andre yrker - teknologi og bransjer',
            originalLabel: 'Andre yrker - teknologi og bransjer',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'TTVC',
                label: 'Hotell-, serverings- og restaurantbransjen',
                originalLabel: 'Hotell-, serverings- og restaurantbransjen',
                alternativeLabels: ['restauranter', 'barer'],

                related: [],
                children: [
                  {
                    id: 'TTVC2',
                    label: 'Catering og matlaging: ferdigheter og bransjer',
                    originalLabel:
                      'Catering og matlaging: ferdigheter og bransjer',
                    alternativeLabels: [],
                    note: 'Brukes for bøker for profesjonsmarkedet',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'TTVH',
                label: 'Frisøryrket, skjønnhetsbehandlinger og salongarbeid',
                originalLabel:
                  'Frisøryrket, skjønnhetsbehandlinger og salongarbeid',
                alternativeLabels: ['spa', 'manikyr', 'pedikyr', 'barbering'],
                note: 'Her: profesjonell makeup, kosmetologi, barbering, hårdesign og -stell, hudpleie, massasje, manikyr, pedikyr og alle bøker som er rettet mot yrkesutøvere innen disse områdene. Brukes med kvalifikatorer fra 4CP* der det er hensiktsmessig, og med YPWC5 for bøker med for videregående skole',
                related: [],
                children: [],
              },
              {
                id: 'TTVR',
                label: 'Tradisjonelle næringer, håndverk og ferdigheter',
                originalLabel:
                  'Tradisjonelle næringer, håndverk og ferdigheter',
                alternativeLabels: [],
                note: 'Her: smedarbeid, tekking, bygging av steingjerder, snekkerarbeid, musikkinstrumentmaker etc. Brukes for bøker for studenter og for profesjons- og allmennmarkedet som dekker disse førindustrielle håndverksfagene og - teknikkene. Foretrekk WF* for mer praktiske, gjør-det-selv-bøker. Se også WFV <a href="/thema/nb/WFV">Tradisjonelt håndverk</a>',
                related: [
                  {
                    id: 'WFV',
                    label: 'Tradisjonelt håndverk',
                    originalLabel: 'Tradisjonelt håndverk',
                    note: 'Her: tradisjonelt håndverk som ofte forbindes med et liv på landet eller landbruksområder og som bruker tradisjonelle materialer. F.eks. kurvmakeri, lage fiskestenger, bygge gjerder, slåttonn, spinning. Se også AFT <a href="/thema/nb/AFT">Dekorativ kunst</a>, TTVR <a href="/thema/nb/TTVR">Tradisjonelle næringer, håndverk og ferdigheter</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TTVS',
                label: 'Yrker innen sikkerhetsbransjen',
                originalLabel: 'Yrker innen sikkerhetsbransjen',
                alternativeLabels: [],
                note: 'Brukes for bøker for profesjonsmarkedet ment for de som jobber innen sikkerhetsbransjen, personlig beskyttelse etc.',
                related: [],
                children: [],
              },
              {
                id: 'TTVT',
                label: 'Vaktmestere, husholdersker, renholdsarbeidere etc.',
                originalLabel:
                  'Vaktmestere, husholdersker, renholdsarbeidere etc.',
                alternativeLabels: ['vaktmester', 'husholdsarbeid'],
                note: 'Brukes for bøker for profesjonsmarkedet for yrker hvor man har ansvaret for bygninger, hushjelper etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TTW',
            label: 'Hjelpemiddelteknologi',
            originalLabel: 'Hjelpemiddelteknologi',
            alternativeLabels: ['velferdsteknologi'],
            note: 'Her: bruk av teknologi for å bistå og gjøre det mulig for folk med nedsatt funksjonsevne å leve et selvstendige liv',
            related: [],
            children: [],
          },
          {
            id: 'TTX',
            label: 'Taksidermi',
            originalLabel: 'Taksidermi',
            alternativeLabels: [],
            note: 'Her: utstopping av dyr',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'TV',
        label: 'Landbruk',
        originalLabel: 'Landbruk',
        alternativeLabels: ['agronomi', 'jordbruk'],
        note: 'Brukes for titler for høyere utdanning eller profesjonsmarkedet om praksis og teknikker innen landbruk, matproduksjon og tilhørende yrker. Bruk TV* med andre koder fra T* eller U* for bøker som handler om bruk av ny teknologi innen landbruk. Se også KCVD <a href="/thema/nb/KCVD">Landbruksøkonomi</a>, KNA <a href="/thema/nb/KNA">Landbruks- og primærnæringer</a>',
        related: [
          {
            id: 'KCVD',
            label: 'Landbruksøkonomi',
            originalLabel: 'Landbruksøkonomi',
          },
          {
            id: 'KNA',
            label: 'Landbruks- og primærnæringer',
            originalLabel: 'Landbruks- og primærnæringer',
            note: 'Brukes for generelle verk om disse næringene, hvor det ikke fins egne koder. Se også KCVD <a href="/thema/nb/KCVD">Landbruksøkonomi</a>',
          },
        ],
        children: [
          {
            id: 'TVB',
            label: 'Landbruksvitenskap',
            originalLabel: 'Landbruksvitenskap',
            alternativeLabels: [],
            note: 'Her: agroøkologi, agroøkosystemer. Brukes for bøker som ser på landbruk og tilhørende prosesser i et vitenskapelig perspektiv, som f.eks. økologiske prosesser som brukes i produksjonssystemer innen landbruket, studier av forholdet mellom avlinger og miljø. Brukes med PST* for bøker om bruk av botanikk i landbruket',
            related: [],
            children: [
              {
                id: 'TVBP',
                label: 'Jordbruksvitenskap',
                originalLabel: 'Jordbruksvitenskap',
                alternativeLabels: ['matjord'],
                note: 'Her: edafologi, jordvitenskap i agronomi og miljøsammenheng, jordkvalitet, jorderosjon etc. Se også RBGB <a href="/thema/nb/RBGB">Sedimentologi og pedologi</a>, TNCC <a href="/thema/nb/TNCC">Jord- og bergmekanikk</a>',
                related: [
                  {
                    id: 'RBGB',
                    label: 'Sedimentologi og pedologi',
                    originalLabel: 'Sedimentologi og pedologi',
                    note: 'Se også PNC <a href="/thema/nb/PNC">Miljøkjemi</a>, TNCC <a href="/thema/nb/TNCC">Jord- og bergmekanikk</a>, TVBP <a href="/thema/nb/TVBP">Jordbruksvitenskap</a>',
                  },
                  {
                    id: 'TNCC',
                    label: 'Jord- og bergmekanikk',
                    originalLabel: 'Jord- og bergmekanikk',
                    note: 'Her: geoteknisk ingeniørarbeid, geoteknikk. Se også RBGB <a href="/thema/nb/RBGB">Sedimentologi og pedologi</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TVBT',
                label: 'Naturbruk',
                originalLabel: 'Naturbruk',
                alternativeLabels: ['matjord'],
                note: 'Her: forvaltning og utnyttelse av jordbruksland og -områder, styring av beiteområder, bærekraftig bruk av landbruksområder etc. Brukes med RBG* for ulike landbruksområder',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TVD',
            label: 'Landbruksteknikk og -utstyr',
            originalLabel: 'Landbruksteknikk og -utstyr',
            alternativeLabels: [],
            note: 'Se også WGCT <a href="/thema/nb/WGCT">Traktorer og landbrukskjøretøy</a>',
            related: [
              {
                id: 'WGCT',
                label: 'Traktorer og landbrukskjøretøy',
                originalLabel: 'Traktorer og landbrukskjøretøy',
                note: 'Her: landbrukskjøretøy eller -maskiner som skurtreskere, sette- og plantemaskiner, sprøytere, ballepresser, ploger eller andre jordfresere. Se også TVD <a href="/thema/nb/TVD">Landbruksteknikk og -utstyr</a>',
              },
            ],
            children: [
              {
                id: 'TVDR',
                label: 'Irrigasjon og vannforvaltning',
                originalLabel: 'Irrigasjon og vannforvaltning',
                alternativeLabels: [],
                note: 'Brukes for bøker som handler om bruk av vannressurser i landbruket, forvaltning av ressurser, drenering og dreneringsteknikker',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TVF',
            label: 'Bærekraftig landbruk',
            originalLabel: 'Bærekraftig landbruk',
            alternativeLabels: [],
            note: 'Her: bevarende landbruk, regenererende jordbruk, integrert jordbruk, biodynamisk landbruk, paludyrking, permakultur, ingen jordbearbeiding. Brukes for bøker som handler om bærekraftige metoder for landbruk og jordbruk, matproduksjon og bruk av landbruksarealer, miljøvennlig utnyttelse av slike områder, eller landbruksmetoder som fremmer karbonfangst etc.',
            related: [],
            children: [],
          },
          {
            id: 'TVG',
            label: 'Økologisk landbruk',
            originalLabel: 'Økologisk landbruk',
            alternativeLabels: [],
            note: 'Her: bøker som handler om landbruk som vanligvis bruker økologiske gjødslingsmetoder og som ofte er underlagt et nasjonalt eller internasjonalt regelverk som sier hva som defineres som økologisk landbruk. Brukes med andre koder fra TV* for økologisk landbruk innen spesifikke sektorer',
            related: [],
            children: [],
          },
          {
            id: 'TVH',
            label: 'Husdyrhold',
            originalLabel: 'Husdyrhold',
            alternativeLabels: [
              'gårdsdyr',
              'kyr',
              'kveg',
              'geiter',
              'sauer',
              'griser',
              'hester',
              'gjeter',
              'dyrevelverd',
              'beitedyr',
              'nyttedyr',
            ],
            note: 'Her: dyrehold, bufe, produksjonsdyr. Se også WNF <a href="/thema/nb/WNF">Gårdsbruk og arbeidsdyr</a>',
            related: [
              {
                id: 'WNF',
                label: 'Gårdsbruk og arbeidsdyr',
                originalLabel: 'Gårdsbruk og arbeidsdyr',
                note: 'Brukes for titler om brukshunder og servicehunder. Se også TVH <a href="/thema/nb/TVH">Husdyrhold</a>',
              },
            ],
            children: [
              {
                id: 'TVHB',
                label: 'Dyreoppdrett',
                originalLabel: 'Dyreoppdrett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TVHE',
                label: 'Hestehold',
                originalLabel: 'Hestehold',
                alternativeLabels: [],
                note: 'Her: hesteavl, hestevitenskap. Se også SK* <a href="/thema/nb/SK">Hestesport og dyresport</a> og dens underkategorier',
                related: [
                  {
                    id: 'SK',
                    label: 'Hestesport og dyresport',
                    originalLabel: 'Hestesport og dyresport',
                    note: 'Her: rodeo, polo. Se også ATXZ1 <a href="/thema/nb/ATXZ1">Tyrefekting</a>, SVH <a href="/thema/nb/SVH">Jakt og skyting</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'TVHF',
                label: 'Melkeproduksjon',
                originalLabel: 'Melkeproduksjon',
                alternativeLabels: ['kyr', 'ku', 'kuer', 'melk'],
                note: 'Her: melking, melkekyr, geiter, sauer, bøffel, kamel eller andre pattedyr som brukes til melkeproduksjon. Brukes for bøker om teknikker, teknologi, velferd, produksjon, sykdom etc. som har med melkeproduksjon å gjøre. Brukes med TQSR for avfallshåndtering',
                related: [],
                children: [],
              },
              {
                id: 'TVHH',
                label: 'Birøkt',
                originalLabel: 'Birøkt',
                alternativeLabels: ['bier', 'bikuber', 'bivoks'],
                note: 'Her: råd for birøktere, høsting av honning eller pollen, bruk av bier til kommersiell pollinering, avl av bidronninger, sykdommer og parasitter hos bier, innendørs birøkt. Brukes med TVU for urban birøkt, TVF for naturlig eller bærekraftig birøkt',
                related: [],
                children: [],
              },
              {
                id: 'TVHP',
                label: 'Fjærfeavl',
                originalLabel: 'Fjærfeavl',
                alternativeLabels: [
                  'kylling',
                  'høns',
                  'ender',
                  'gjess',
                  'kalkun',
                  'gås',
                ],
                note: 'Her: oppdrett av kylling, and, gås, kalkun, vaktel, påfugl, perlehøns, fasan, due, struts etc. Brukes for bøker om husdyrhold av fjærfe for egg, kjøtt eller fjær, eller bøker om teknikker, teknologi, velferd, produksjon, produkter, sykdommer hos fjærfe',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TVK',
            label: 'Agronomi og avlinger',
            originalLabel: 'Agronomi og avlinger',
            alternativeLabels: ['planteproduksjon'],
            note: 'Her: beskjæring, ugressbekjempelse, genmodifisering av avlinger, forvaltning av avlinger',
            related: [],
            children: [],
          },
          {
            id: 'TVM',
            label: 'Småbruk',
            originalLabel: 'Småbruk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'TVP',
            label: 'Skadedyrkontroll / plantesykdommer',
            originalLabel: 'Skadedyrkontroll / plantesykdommer',
            alternativeLabels: [],
            note: 'Her: landbrukets bruk av plantevern- og ugressmidler, hvordan insekter og skadedyr håndteres, ugressmidler, sykdomsresistens i avlinger',
            related: [],
            children: [],
          },
          {
            id: 'TVQ',
            label: 'Tropisk landbruk',
            originalLabel: 'Tropisk landbruk',
            alternativeLabels: [],
            note: 'Brukes for bøker for høyere utdanning, allmenn- eller profesjonsmarkedet om praksis og teknikker i landbruk og matproduksjon i tropiske områder, avlinger og økosystemer i disse områdene',
            related: [],
            children: [],
          },
          {
            id: 'TVR',
            label: 'Skogbruk og skogkultur',
            originalLabel: 'Skogbruk og skogkultur',
            alternativeLabels: [],
            note: 'Her: trepleie, trebehandling, treplanting. Brukes for bøker for høyere utdanning, allmenn- eller profesjonsmarkedet som handler om praksis og teknikker for å administrere, bruke, bevare og reparere skog og relaterte ressurser, forvaltning av skoger og skogsområder, skogplanting, skogslandbruk, urbant skogbruk, tømmerhogst etc. Se også KNAL <a href="/thema/nb/KNAL">Skogbruk</a>, TDPJ <a href="/thema/nb/TDPJ">Tømmer og treforedling</a>',
            related: [
              {
                id: 'KNAL',
                label: 'Skogbruk',
                originalLabel: 'Skogbruk',
                note: 'Brukes for titler om skogbruksnæringa eller industri som skaper, forvalter, bruker, konserverer og reparerer skog og lignende ressurser. Se også TDPJ <a href="/thema/nb/TDPJ">Tømmer og treforedling</a>, TVR <a href="/thema/nb/TVR">Skogbruk og skogkultur</a>',
              },
              {
                id: 'TDPJ',
                label: 'Tømmer og treforedling',
                originalLabel: 'Tømmer og treforedling',
                note: 'Brukes for alle aspekter ved industriell treproduksjon. Se også KNAL <a href="/thema/nb/KNAL">Skogbruk</a>, TVR <a href="/thema/nb/TVR">Skogbruk og skogkultur</a>',
              },
            ],
            children: [],
          },
          {
            id: 'TVS',
            label: 'Kommersielt hagebruk',
            originalLabel: 'Kommersielt hagebruk',
            alternativeLabels: ['grønnsaksproduksjon', 'frukttrær'],
            note: 'Her: gartnerier, blomsterproduksjon, grønnsaks- og fruktproduksjon, pomologi, torvhåndtering. Brukes for bøker som handler om kommersiell dyrking og salg av frukt, nøtter, grønnsaker og planter, og praksis og teknikker som forbindes med dette. Se også WM* <a href="/thema/nb/WM">Hagearbeid</a> og dens underkategorier',
            related: [
              {
                id: 'WM',
                label: 'Hagearbeid',
                originalLabel: 'Hagearbeid',
                note: 'Bruk WM* med kvalifikatorer fra 1QM* for bøker om hagearbeid i ulike klimaregioner, eller andre kvalifikatorer for sted for regionale guider. Se også TVS <a href="/thema/nb/TVS">Kommersielt hagebruk</a>',
              },
            ],
            children: [
              {
                id: 'TVSH',
                label: 'Hydrokultur',
                originalLabel: 'Hydrokultur',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'TVSW',
                label: 'Vindyrking',
                originalLabel: 'Vindyrking',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'TVT',
            label: 'Akvakultur og fiskeoppdrett',
            originalLabel: 'Akvakultur og fiskeoppdrett',
            alternativeLabels: ['reker', 'østers', 'laks'],
            note: 'Her: akvaponikk, havbruk, algekultur. Brukes for bøker for høyere utdanning, allmenn- eller profesjonsmarkedet om praksis og teknikker innen fiskeoppdrett, oppdrett av skalldyr, bløtdyr, vannplanter, alger, tang i ferskvann eller saltvann. Se også KNAF <a href="/thema/nb/KNAF">Fiskeri og relaterte næringer</a>',
            related: [
              {
                id: 'KNAF',
                label: 'Fiskeri og relaterte næringer',
                originalLabel: 'Fiskeri og relaterte næringer',
                note: 'Her: hvalfangst. Se også TVT <a href="/thema/nb/TVT">Akvakultur og fiskeoppdrett</a>',
              },
            ],
            children: [],
          },
          {
            id: 'TVU',
            label: 'Urbant landbruk',
            originalLabel: 'Urbant landbruk',
            alternativeLabels: ['gårder', 'bondegårder'],
            note: 'Brukes med TVH (Husdyrhold) eller andre TV*-koder der det er hensiktsmessig',
            related: [],
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 'U',
    label: 'Data- og informasjonsteknologi',
    originalLabel: 'Data- og informasjonsteknologi',
    alternativeLabels: [],
    note: 'Bruk U*-koder for akademiske utgivelser, og utgivelser for profesjons- og allmennmarkedet. IKKE bruk koden ‘U’ alene, men velg spesifikke kategorier fra U*. Bruk U*-koder med andre emnekategorier og kvalifikatorer der det er hensiktsmessig, spesielt med kvalifikatorer for pedagogisk formål for bøker for studenter',
    related: [],
    children: [
      {
        id: 'UB',
        label: 'Informasjonsteknologi',
        originalLabel: 'Informasjonsteknologi',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'UBB',
            label: 'Databehandlingens historie',
            originalLabel: 'Databehandlingens historie',
            alternativeLabels: [],
            note: 'Her: digital teknologi, informasjonsteknologi',
            related: [],
            children: [],
          },
          {
            id: 'UBH',
            label:
              'Helse- og sikkerhetsaspekter ved digital- og informasjonsteknologi',
            originalLabel:
              'Helse- og sikkerhetsaspekter ved digital- og informasjonsteknologi',
            alternativeLabels: ['digital teknologi'],
            note: 'Bruk for: bøker som ser på virkningene på eller aspekter ved helse – fysisk og psykisk – og sikkerhet rundt digital teknologi på arbeidsplassen, hjemme eller i samfunnet, eller bøker som ser på hvordan digital teknologi og informasjonsteknologi brukes til å forbedre helse og sikkerhet - se MBF eller MBNS for bruk av teknologi i helsevesenet. Se også UDD <a href="/thema/nb/UDD">Sikkerhet og oppførsel på internett</a>',
            related: [
              {
                id: 'UDD',
                label: 'Sikkerhet og oppførsel på internett',
                originalLabel: 'Sikkerhet og oppførsel på internett',
                note: 'Brukes for bøker for allmennmarkedet som er på alle sider ved sikkerhet og oppførsel online, inkludert hvordan man trygt kan bruke internett eller andre digitale teknologier, råd om å beskytte seg selv mot cyberkriminalitet eller -svindel, personlig sikkerhet, personvern etc. Se også UBH <a href="/thema/nb/UBH">Helse- og sikkerhetsaspekter ved digital- og informasjonsteknologi</a>',
              },
            ],
            children: [],
          },
          {
            id: 'UBJ',
            label:
              'Etiske og sosiale aspekter ved digital- og informasjonsteknologi',
            originalLabel:
              'Etiske og sosiale aspekter ved digital- og informasjonsteknologi',
            alternativeLabels: [
              'fjerde industrielle revolusjonen',
              'internet of things',
              'internett',
              'digital teknologi',
              'crowdsourcing',
            ],
            note: 'Her: digitale skiller, industri 4.0, tingenes internett, smartbyer, velferdsteknologi, smarthjem, den fjerde industrielle revolusjonen, nettdugnad, digitalt medborgerskap, digital deltakelse, etikk innen kunstig intelligens, etiske sider ved innsamling av persondata, transhumanisme, teknologisk singularitet, cyberkultur, miljøpåvirkning, energiforbruk, påvirkning på bærekraft etc. Brukes for bøker som handler om påvirkning på samfunnet, næringslivet, miljøet, menneskelig oppførsel, økonomien, hverdagslivet, og som henger sammen med bruk av data-, digital- eller informasjonsteknologi, og deres påvirkning på samfunnet. Se også JBFV5 <a href="/thema/nb/JBFV5">Vitenskapelig, teknologisk og medisinsk utvikling</a>, PDR <a href="/thema/nb/PDR">Vitenskapens og teknologiens påvirkning på samfunnet</a>',
            related: [
              {
                id: 'JBFV5',
                label: 'Vitenskapelig, teknologisk og medisinsk utvikling',
                originalLabel:
                  'Vitenskapelig, teknologisk og medisinsk utvikling',
              },
              {
                id: 'PDR',
                label: 'Vitenskapens og teknologiens påvirkning på samfunnet',
                originalLabel:
                  'Vitenskapens og teknologiens påvirkning på samfunnet',
                note: 'Brukes for bøker som tar for seg påvirkning en vitenskap eller teknologi har hatt eller kan tenkes å ha på samfunnet. Brukes med NHTB for sosialhistorie eller andre P*, T* eller J*-koder der det er hensiktsmessig. Se også JBFV5 <a href="/thema/nb/JBFV5">Vitenskapelig, teknologisk og medisinsk utvikling</a>, UBJ <a href="/thema/nb/UBJ">Etiske og sosiale aspekter ved digital- og informasjonsteknologi</a>',
              },
            ],
            children: [],
          },
          {
            id: 'UBL',
            label: 'Juridiske aspekter ved digital- og informasjonsteknologi',
            originalLabel:
              'Juridiske aspekter ved digital- og informasjonsteknologi',
            alternativeLabels: ['digital teknologi'],
            note: 'Her: juridiske aspekter ved åpen kildekode, copyleft-lisenser, søppelpost, nettnøytralitet, sensur på internett, alle juridiske aspekter ved digital spredning av informasjon og programvare. Brukes for verk som diskuterer ulike juridiske aspekter ved IT. Se også LNQ <a href="/thema/nb/LNQ">IT og kommunikasjon: lover og regler</a>',
            related: [
              {
                id: 'LNQ',
                label: 'IT og kommunikasjon: lover og regler',
                originalLabel: 'IT og kommunikasjon: lover og regler',
                note: 'Her: Postregelverket, teknologi og telekommunikasjon. Se også LNCB2 <a href="/thema/nb/LNCB2">E-handelsloven</a>',
              },
            ],
            children: [],
          },
          {
            id: 'UBM',
            label: 'Makerbevegelsen og hackermiljøer',
            originalLabel: 'Makerbevegelsen og hackermiljøer',
            alternativeLabels: [],
            note: 'Brukes for generelle verk om teknologistyrte subkulturer',
            related: [],
            children: [],
          },
          {
            id: 'UBW',
            label: 'Internett',
            originalLabel: 'Internett',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'UD',
        label: 'Digital livsstil og nettsamfunn',
        originalLabel: 'Digital livsstil og nettsamfunn',
        alternativeLabels: [],
        note: 'Brukes for bøker for allmennmarkedet, eller guider som er ment å hjelpe brukerne til å foreta informerte valg om digital teknologi. Se også ATN <a href="/thema/nb/ATN">Internett og digitale medier: kunst og forestillinger</a>',
        related: [
          {
            id: 'ATN',
            label: 'Internett og digitale medier: kunst og forestillinger',
            originalLabel:
              'Internett og digitale medier: kunst og forestillinger',
            note: 'Her: Alle aspekter ved internett som mediekanal, spesielt for materiale som har oppstått på internett; internettkjendiser, bloggere og blogging, video bloggere (vlogger), podkast etc. Se også UD* <a href="/thema/nb/UD">Digital livsstil og nettsamfunn</a> og dens underkategorier, KNTV <a href="/thema/nb/KNTV">Data- og TV-spillbransjen</a>, KNTX <a href="/thema/nb/KNTX">IT-bransjen</a>',
          },
        ],
        children: [
          {
            id: 'UDA',
            label: 'Personlig organisering: programvare og applikasjoner',
            originalLabel:
              'Personlig organisering: programvare og applikasjoner',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UDB',
            label: 'Internettguider og tjenester på internett',
            originalLabel: 'Internettguider og tjenester på internett',
            alternativeLabels: [],
            note: 'Se også ATN <a href="/thema/nb/ATN">Internett og digitale medier: kunst og forestillinger</a>',
            related: [
              {
                id: 'ATN',
                label: 'Internett og digitale medier: kunst og forestillinger',
                originalLabel:
                  'Internett og digitale medier: kunst og forestillinger',
                note: 'Her: Alle aspekter ved internett som mediekanal, spesielt for materiale som har oppstått på internett; internettkjendiser, bloggere og blogging, video bloggere (vlogger), podkast etc. Se også UD* <a href="/thema/nb/UD">Digital livsstil og nettsamfunn</a> og dens underkategorier, KNTV <a href="/thema/nb/KNTV">Data- og TV-spillbransjen</a>, KNTX <a href="/thema/nb/KNTX">IT-bransjen</a>',
              },
            ],
            children: [
              {
                id: 'UDBA',
                label: 'Handel og auksjoner på internett',
                originalLabel: 'Handel og auksjoner på internett',
                alternativeLabels: ['nettforhandlere'],
                note: 'Brukes for bøker for allmennmarkedet som handler om e-handel, netthandel, digitale markedsplasser',
                related: [],
                children: [],
              },
              {
                id: 'UDBD',
                label: 'Internettsøking',
                originalLabel: 'Internettsøking',
                alternativeLabels: ['discovery', 'discoverability'],
                note: 'Her: søkemotorer, anbefalingsmotorer, det å oppdage innhold',
                related: [],
                children: [],
              },
              {
                id: 'UDBG',
                label: 'Internettgambling',
                originalLabel: 'Internettgambling',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'UDBM',
                label: 'Finans og investering på internett',
                originalLabel: 'Finans og investering på internett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'UDBR',
                label: 'Nettlesere',
                originalLabel: 'Nettlesere',
                alternativeLabels: [
                  'chrome',
                  'safari',
                  'explorer',
                  'firefox',
                  'edge',
                  'opera',
                ],

                related: [],
                children: [],
              },
              {
                id: 'UDBS',
                label: 'Sosiale medier / sosiale nettverk',
                originalLabel: 'Sosiale medier / sosiale nettverk',
                alternativeLabels: [
                  'Twitter',
                  'Facebook',
                  'Snapchat',
                  'Tumblr',
                  'Instagram',
                  'Reddit',
                  'WhatsApp',
                  'Pinterest',
                  'Tinder',
                  'Google Plus',
                  'YouTube',
                ],

                related: [],
                children: [],
              },
              {
                id: 'UDBV',
                label: 'Virtuelle verdener',
                originalLabel: 'Virtuelle verdener',
                alternativeLabels: ['avatarer', 'metaverse'],
                note: 'Brukes for bøker for allmennmarkedet som handler om ulike virtuelle verdener eller samfunn, om hvordan man bruker virtuelle verdener for å få bedre helse, til utdanning, i arbeidslivet eller til andre formål',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'UDD',
            label: 'Sikkerhet og oppførsel på internett',
            originalLabel: 'Sikkerhet og oppførsel på internett',
            alternativeLabels: [],
            note: 'Brukes for bøker for allmennmarkedet som er på alle sider ved sikkerhet og oppførsel online, inkludert hvordan man trygt kan bruke internett eller andre digitale teknologier, råd om å beskytte seg selv mot cyberkriminalitet eller -svindel, personlig sikkerhet, personvern etc. Se også UBH <a href="/thema/nb/UBH">Helse- og sikkerhetsaspekter ved digital- og informasjonsteknologi</a>',
            related: [
              {
                id: 'UBH',
                label:
                  'Helse- og sikkerhetsaspekter ved digital- og informasjonsteknologi',
                originalLabel:
                  'Helse- og sikkerhetsaspekter ved digital- og informasjonsteknologi',
                note: 'Bruk for: bøker som ser på virkningene på eller aspekter ved helse – fysisk og psykisk – og sikkerhet rundt digital teknologi på arbeidsplassen, hjemme eller i samfunnet, eller bøker som ser på hvordan digital teknologi og informasjonsteknologi brukes til å forbedre helse og sikkerhet - se MBF eller MBNS for bruk av teknologi i helsevesenet. Se også UDD <a href="/thema/nb/UDD">Sikkerhet og oppførsel på internett</a>',
              },
            ],
            children: [],
          },
          {
            id: 'UDF',
            label: 'E-post: brukerguider',
            originalLabel: 'E-post: brukerguider',
            alternativeLabels: ['gmail', 'outlook'],

            related: [],
            children: [],
          },
          {
            id: 'UDH',
            label:
              'E-boklesere, nettbrett og andre bærbare enheter: brukerveiledninger',
            originalLabel:
              'E-boklesere, nettbrett og andre bærbare enheter: brukerveiledninger',
            alternativeLabels: [
              'kindle',
              'ipad',
              'lesebrett',
              'ebok',
              'e-bok',
              'e-bøker',
            ],
            note: 'Her: digitale bøker, guider til selvpublisering. Brukes for bøker for allmennmarkedet om det å velge, kjøpe og bruke bærbare enheter',
            related: [],
            children: [],
          },
          {
            id: 'UDM',
            label: 'Digital musikk og lyd: brukerveiledninger',
            originalLabel: 'Digital musikk og lyd: brukerveiledninger',
            alternativeLabels: [],
            note: 'Use for: works aimed at a general readership about online music platforms, streaming music, audiobook and podcast services, podcasting, internet radio, long form audio services. Se også ATN <a href="/thema/nb/ATN">Internett og digitale medier: kunst og forestillinger</a>',
            related: [
              {
                id: 'ATN',
                label: 'Internett og digitale medier: kunst og forestillinger',
                originalLabel:
                  'Internett og digitale medier: kunst og forestillinger',
                note: 'Her: Alle aspekter ved internett som mediekanal, spesielt for materiale som har oppstått på internett; internettkjendiser, bloggere og blogging, video bloggere (vlogger), podkast etc. Se også UD* <a href="/thema/nb/UD">Digital livsstil og nettsamfunn</a> og dens underkategorier, KNTV <a href="/thema/nb/KNTV">Data- og TV-spillbransjen</a>, KNTX <a href="/thema/nb/KNTX">IT-bransjen</a>',
              },
            ],
            children: [],
          },
          {
            id: 'UDP',
            label: 'Digital fotografering: brukerveiledninger',
            originalLabel: 'Digital fotografering: brukerveiledninger',
            alternativeLabels: [
              'digitalfotografi',
              'digitalfotografering',
              'fotografi',
            ],
            note: 'Brukes for alle guider til digital fotografering. Brukes med AJT for digital fotografering som kunst eller UGP for bøker om redigering og software som Photoshop. Se også AJT <a href="/thema/nb/AJT">Fotoutstyr og -teknikker</a>, UGP <a href="/thema/nb/UGP">Foto og bildebehandling</a>',
            related: [
              {
                id: 'AJT',
                label: 'Fotoutstyr og -teknikker',
                originalLabel: 'Fotoutstyr og -teknikker',
                note: 'Her: kameraer, linser, film, mørkeromsteknikker og fremkalling av fotografier, retusjering og digital manipulering, fargeprinsipper, komposisjon etc. Se også UGP <a href="/thema/nb/UGP">Foto og bildebehandling</a>',
              },
              {
                id: 'UGP',
                label: 'Foto og bildebehandling',
                originalLabel: 'Foto og bildebehandling',
              },
            ],
            children: [],
          },
          {
            id: 'UDQ',
            label: 'Digital video: brukerveiledninger',
            originalLabel: 'Digital video: brukerveiledninger',
            alternativeLabels: [],
            note: 'Brukes for bøker for allmennmarkedet om nettbaserte videoplattformer, strømming av videoinnhold, videoblogger eller vlogger, livesendinger',
            related: [],
            children: [],
          },
          {
            id: 'UDT',
            label: 'Mobiltelefoner og smarttelefoner: brukerveiledninger',
            originalLabel:
              'Mobiltelefoner og smarttelefoner: brukerveiledninger',
            alternativeLabels: ['iphone', 'android'],

            related: [],
            children: [],
          },
          {
            id: 'UDV',
            label: 'Digital TV og mediesentre: brukerveiledninger',
            originalLabel: 'Digital TV og mediesentre: brukerveiledninger',
            alternativeLabels: [
              'HBO',
              'Netflix',
              'Disney+',
              'Apple TV',
              'Discovery plus',
              'TV2 Play',
              'strømmetjenester',
              'Viaplay',
              'Paramount',
              'Amazon Prime',
            ],
            note: 'Brukes for bøker for allmennmarkedet om produkter eller tjenester for å se alle typer kringkasting, både lineær-TV, strømming, leiefilm eller andre lignende tjenester',
            related: [],
            children: [],
          },
          {
            id: 'UDX',
            label: 'Dataspill / nettspill: strategiguider',
            originalLabel: 'Dataspill / nettspill: strategiguider',
            alternativeLabels: [],
            note: 'Se også SXE <a href="/thema/nb/SXE">E-sport</a>',
            related: [
              {
                id: 'SXE',
                label: 'E-sport',
                originalLabel: 'E-sport',
                note: 'Brukes for profesjonelle gamere, konkurranser i dataspill, gaming',
              },
            ],
            children: [],
          },
          {
            id: 'UDY',
            label: 'Smarthjem og virtuelle assistenter: brukerveiledninger',
            originalLabel:
              'Smarthjem og virtuelle assistenter: brukerveiledninger',
            alternativeLabels: [
              'fjernassistenter',
              'alexa',
              'siri',
              'google home',
            ],
            note: 'Brukes for bøker for allmennmarkedet, eller guider som er ment å hjelpe brukerne til å foreta informerte valg om smartteknologi eller tingenes internett',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'UF',
        label: 'Administrasjons- og forretningsapplikasjoner',
        originalLabel: 'Administrasjons- og forretningsapplikasjoner',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'UFB',
            label: 'Integrerte programvarepakker',
            originalLabel: 'Integrerte programvarepakker',
            alternativeLabels: [],
            note: 'Her: Microsoft Office, Microsoft Works, Lotus SmartSuite, OpenOffice etc.',
            related: [],
            children: [],
          },
          {
            id: 'UFC',
            label: 'Regneark',
            originalLabel: 'Regneark',
            alternativeLabels: [],
            note: 'Her: Excel, Lotus 1-2-3, etc.',
            related: [],
            children: [],
          },
          {
            id: 'UFD',
            label: 'Tekstbehandlingsprogrammer',
            originalLabel: 'Tekstbehandlingsprogrammer',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UFG',
            label: 'Presentasjonsprogrammer',
            originalLabel: 'Presentasjonsprogrammer',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UFK',
            label: 'Regnskapsprogrammer',
            originalLabel: 'Regnskapsprogrammer',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UFL',
            label: 'Forretningssystemer (ERP)',
            originalLabel: 'Forretningssystemer (ERP)',
            alternativeLabels: ['CMS', 'CMR'],
            note: 'Her: publiseringssystem, kunderelasjonshåndtering, ERP-systemer',
            related: [],
            children: [
              {
                id: 'UFLS',
                label: 'SAP (System, applikasjoner og produkter i databaser)',
                originalLabel:
                  'SAP (System, applikasjoner og produkter i databaser)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'UFM',
            label: 'Matematikk- og statistikkprogrammer',
            originalLabel: 'Matematikk- og statistikkprogrammer',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UFP',
            label: 'Prosjektstyringsprogrammer',
            originalLabel: 'Prosjektstyringsprogrammer',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UFS',
            label: 'Gruppevare',
            originalLabel: 'Gruppevare',
            alternativeLabels: ['Slack', 'Teams', 'Zoom', 'Webex'],
            note: 'Her: verktøy for samarbeidsstyring, videokonferanser, webinarer, nettmøter, programvare brukt i utdanning',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'UG',
        label: 'Datagrafikk og digitale medier',
        originalLabel: 'Datagrafikk og digitale medier',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'UGA',
            label: 'Tilgjengelighet i nett- og digital design',
            originalLabel: 'Tilgjengelighet i nett- og digital design',
            alternativeLabels: ['wcag'],
            note: 'Her: universell utforming i digitale produkter',
            related: [],
            children: [],
          },
          {
            id: 'UGB',
            label: 'Webgrafikk og design',
            originalLabel: 'Webgrafikk og design',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UGC',
            label: 'Dataassistert konstruksjon (DAK)',
            originalLabel: 'Dataassistert konstruksjon (DAK)',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UGD',
            label: 'Desktop publishing',
            originalLabel: 'Desktop publishing',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UGG',
            label: 'Spilldesign',
            originalLabel: 'Spilldesign',
            alternativeLabels: [],
            note: 'Brukes for logiske og narrative aspekter ved spilldesign. Se også AKLF <a href="/thema/nb/AKLF">Dataspillkunst</a>',
            related: [
              {
                id: 'AKLF',
                label: 'Dataspillkunst',
                originalLabel: 'Dataspillkunst',
                note: 'Brukes for bøker om det kunstneriske aspektet ved dataspilldesign, grafikk, tvspillkunst etc. Se også UGG <a href="/thema/nb/UGG">Spilldesign</a>',
              },
            ],
            children: [],
          },
          {
            id: 'UGK',
            label: '3D-grafikk og modellering',
            originalLabel: '3D-grafikk og modellering',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UGL',
            label: 'Programvare for illustrasjon og tegning',
            originalLabel: 'Programvare for illustrasjon og tegning',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UGM',
            label: 'Digital musikk: profesjonelt',
            originalLabel: 'Digital musikk: profesjonelt',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UGN',
            label: 'Digital animasjon',
            originalLabel: 'Digital animasjon',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UGP',
            label: 'Foto og bildebehandling',
            originalLabel: 'Foto og bildebehandling',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UGV',
            label: 'Digital video: profesjonelt',
            originalLabel: 'Digital video: profesjonelt',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'UK',
        label: 'Maskinvare',
        originalLabel: 'Maskinvare',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'UKC',
            label: 'Superdatamaskiner',
            originalLabel: 'Superdatamaskiner',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UKD',
            label: 'Stormaskiner og minidatamaskiner',
            originalLabel: 'Stormaskiner og minidatamaskiner',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UKF',
            label: 'Servere',
            originalLabel: 'Servere',
            alternativeLabels: [],
            note: 'Her: tjenere',
            related: [],
            children: [],
          },
          {
            id: 'UKG',
            label: 'Rutenett og parallelle datamaskiner',
            originalLabel: 'Rutenett og parallelle datamaskiner',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UKL',
            label: 'Smartteknologi',
            originalLabel: 'Smartteknologi',
            alternativeLabels: ['sammenkoblet smartteknologi'],
            note: 'Brukes for bøker for profesjonsmarkedet om smartteknologi i hjemmet, sammenkoblede smartenheter, tingenes internett, mekaniske eller digitale maskiner eller objekter',
            related: [],
            children: [],
          },
          {
            id: 'UKM',
            label: 'Innebygde systemer',
            originalLabel: 'Innebygde systemer',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UKN',
            label: 'Nettverkskomponenter',
            originalLabel: 'Nettverkskomponenter',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UKP',
            label: 'Personlige datamaskiner',
            originalLabel: 'Personlige datamaskiner',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'UKPC',
                label: 'PC-er (IBM-kompatible personlige datamaskiner)',
                originalLabel: 'PC-er (IBM-kompatible personlige datamaskiner)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'UKPM',
                label: 'Mac (MacIntosh)',
                originalLabel: 'Mac (MacIntosh)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'UKR',
            label:
              'Maskinvare for data- og informasjonsteknologi: vedlikehold og reparasjon',
            originalLabel:
              'Maskinvare for data- og informasjonsteknologi: vedlikehold og reparasjon',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UKS',
            label: 'Lagringsmedia og periferienheter',
            originalLabel: 'Lagringsmedia og periferienheter',
            alternativeLabels: [],
            note: 'Her: harddisker, cd-brennere, minnepenner, skrivere, skannere etc.',
            related: [],
            children: [],
          },
          {
            id: 'UKX',
            label: 'Systemverktøy og hjelpeprogrammer',
            originalLabel: 'Systemverktøy og hjelpeprogrammer',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'UL',
        label: 'Operativsystemer',
        originalLabel: 'Operativsystemer',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'ULD',
            label: 'Microsoft Windows',
            originalLabel: 'Microsoft Windows',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'ULH',
            label: 'Apple operativsystemer',
            originalLabel: 'Apple operativsystemer',
            alternativeLabels: ['iPad', 'iOS', 'macOS'],
            note: 'Her: MacOS, brukes med ULP for å angi iOS. Se også ULP <a href="/thema/nb/ULP">Mobile operativsystem</a>',
            related: [
              {
                id: 'ULP',
                label: 'Mobile operativsystem',
                originalLabel: 'Mobile operativsystem',
                note: 'Her: Android, iOS, BlackBerry, Tizen. Brukes for mobile operativsystem for telefoner, nettbrett og smartklokker. Se også ULD <a href="/thema/nb/ULD">Microsoft Windows</a>, ULH <a href="/thema/nb/ULH">Apple operativsystemer</a>',
              },
            ],
            children: [],
          },
          {
            id: 'ULJ',
            label: 'Åpen kildekode og andre operativsystem',
            originalLabel: 'Åpen kildekode og andre operativsystem',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'ULJL',
                label: 'Linux',
                originalLabel: 'Linux',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'ULP',
            label: 'Mobile operativsystem',
            originalLabel: 'Mobile operativsystem',
            alternativeLabels: [],
            note: 'Her: Android, iOS, BlackBerry, Tizen. Brukes for mobile operativsystem for telefoner, nettbrett og smartklokker. Se også ULD <a href="/thema/nb/ULD">Microsoft Windows</a>, ULH <a href="/thema/nb/ULH">Apple operativsystemer</a>',
            related: [
              {
                id: 'ULD',
                label: 'Microsoft Windows',
                originalLabel: 'Microsoft Windows',
              },
              {
                id: 'ULH',
                label: 'Apple operativsystemer',
                originalLabel: 'Apple operativsystemer',
                note: 'Her: MacOS, brukes med ULP for å angi iOS. Se også ULP <a href="/thema/nb/ULP">Mobile operativsystem</a>',
              },
            ],
            children: [],
          },
          {
            id: 'ULQ',
            label: 'IBM operativsystem',
            originalLabel: 'IBM operativsystem',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'ULR',
            label: 'Sanntidsoperativsystem',
            originalLabel: 'Sanntidsoperativsystem',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'UM',
        label: 'Programmering / programvareutvikling',
        originalLabel: 'Programmering / programvareutvikling',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'UMA',
            label: 'Programmeringsteknikker',
            originalLabel: 'Programmeringsteknikker',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UMB',
            label: 'Algoritmer og datastruktur',
            originalLabel: 'Algoritmer og datastruktur',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UMC',
            label: 'Kompilatorer og fortolkere',
            originalLabel: 'Kompilatorer og fortolkere',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UMF',
            label: 'Smidig programmering',
            originalLabel: 'Smidig programmering',
            alternativeLabels: [],
            note: 'Her: Agile programmering',
            related: [],
            children: [],
          },
          {
            id: 'UMG',
            label: 'Aspektorientert programmering',
            originalLabel: 'Aspektorientert programmering',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UMH',
            label: 'Extreme programming (XP)',
            originalLabel: 'Extreme programming (XP)',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UMJ',
            label: 'Funksjonell programmering',
            originalLabel: 'Funksjonell programmering',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UMK',
            label: 'Spillutvikling og -programmering',
            originalLabel: 'Spillutvikling og -programmering',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'UMKB',
                label: '2D-grafikk: spillprogrammering',
                originalLabel: '2D-grafikk: spillprogrammering',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'UMKC',
                label: '3D-grafikk: spillprogrammering',
                originalLabel: '3D-grafikk: spillprogrammering',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'UMKL',
                label: 'Nivådesign: spillprogrammering',
                originalLabel: 'Nivådesign: spillprogrammering',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'UML',
            label: 'Grafisk programmering',
            originalLabel: 'Grafisk programmering',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UMN',
            label: 'Objektorientert programmering',
            originalLabel: 'Objektorientert programmering',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UMP',
            label: 'Programmering: Microsoft',
            originalLabel: 'Programmering: Microsoft',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'UMPN',
                label: 'Programmering: .NET',
                originalLabel: 'Programmering: .NET',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'UMPW',
                label: 'Programmering: Windows',
                originalLabel: 'Programmering: Windows',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'UMQ',
            label: 'Programmering: Mac',
            originalLabel: 'Programmering: Mac',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UMR',
            label: 'Nettverksprogrammering',
            originalLabel: 'Nettverksprogrammering',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UMS',
            label: 'Mobiler og håndholdte enheter - programmering',
            originalLabel: 'Mobiler og håndholdte enheter - programmering',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UMT',
            label: 'Databaseprogrammering',
            originalLabel: 'Databaseprogrammering',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UMW',
            label: 'Webprogrammering',
            originalLabel: 'Webprogrammering',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'UMWS',
                label: 'Web services',
                originalLabel: 'Web services',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'UMX',
            label: 'Programmerings- og skriptspråk',
            originalLabel: 'Programmerings- og skriptspråk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UMZ',
            label: 'Programvareutvikling',
            originalLabel: 'Programvareutvikling',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'UMZL',
                label: 'Unified Modelling Language (UML)',
                originalLabel: 'Unified Modelling Language (UML)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'UMZT',
                label: 'Programvaretesting og -verifisering',
                originalLabel: 'Programvaretesting og -verifisering',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'UMZW',
                label: 'Objektorientert programvareutvikling',
                originalLabel: 'Objektorientert programvareutvikling',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'UN',
        label: 'Databaser',
        originalLabel: 'Databaser',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'UNA',
            label: 'Databasedesign og -teori',
            originalLabel: 'Databasedesign og -teori',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'UNAN',
                label: 'NoSQL-databaser',
                originalLabel: 'NoSQL-databaser',
                alternativeLabels: [],
                note: 'Her: databaser som ikke er relasjonsdatabaser',
                related: [],
                children: [],
              },
              {
                id: 'UNAR',
                label: 'Relasjonsdatabaser',
                originalLabel: 'Relasjonsdatabaser',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'UNC',
            label: 'Datafangst og analyse',
            originalLabel: 'Datafangst og analyse',
            alternativeLabels: [],
            note: 'Her: informasjonsgrafikk, datavisualisering, stordata, datasett, dataanalyse, datavitenskap. Se også GPH <a href="/thema/nb/GPH">Datavitenskap og -analyse</a>',
            related: [
              {
                id: 'GPH',
                label: 'Datavitenskap og -analyse',
                originalLabel: 'Datavitenskap og -analyse',
                note: 'Her: datavisualisering og informasjonsgrafikk, store datasett, stordata. Brukes for tverrfaglige bøker om bruken av data. Brukes med andre emnekoder der det er hensiktsmessig. Se også UN* <a href="/thema/nb/UN">Databaser</a> og dens underkategorier, UX* <a href="/thema/nb/UX">Anvendt databehandling</a> og dens underkategorier, UYZF <a href="/thema/nb/UYZF">Informasjonsvisualisering</a>',
              },
            ],
            children: [],
          },
          {
            id: 'UND',
            label: 'Datavarehus',
            originalLabel: 'Datavarehus',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UNF',
            label: 'Datagraving',
            originalLabel: 'Datagraving',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UNH',
            label: 'Informasjonsgjenfinning',
            originalLabel: 'Informasjonsgjenfinning',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UNJ',
            label: 'Objektdatabaser',
            originalLabel: 'Objektdatabaser',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UNK',
            label: 'Distribuerte databaser',
            originalLabel: 'Distribuerte databaser',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'UNKD',
                label: 'Distributed ledger-teknologi / blokkjede',
                originalLabel: 'Distributed ledger-teknologi / blokkjede',
                alternativeLabels: [
                  'Bitcoin',
                  'blockchain',
                  'blokkjedeteknologi',
                ],
                note: 'Her: kryptovaluta, generering av nye bitcoins (mining), altcion, Non-fungible token (NFT). Brukes med UBK for bøker om miljøpåvirkning, eller med KF* eller KC* for en økonomisk tilnærming til emnet. Se også KFFJ <a href="/thema/nb/KFFJ">Valuta</a>',
                related: [
                  {
                    id: 'KFFJ',
                    label: 'Valuta',
                    originalLabel: 'Valuta',
                    note: 'Her: valutahandel, pengemarkeder, kryptovaluta (fra et finans-/investeringssynspunkt) etc. Se også UNKD <a href="/thema/nb/UNKD">Distributed ledger-teknologi / blokkjede</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'UNKP',
                label: 'Peer-to-peer-nettverk (P2P)',
                originalLabel: 'Peer-to-peer-nettverk (P2P)',
                alternativeLabels: ['likemannsnettverk'],
                note: 'Her: BitTorrent, Freenet',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'UNN',
            label: 'Databaser og web',
            originalLabel: 'Databaser og web',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UNS',
            label: 'Databaseprogrammer',
            originalLabel: 'Databaseprogrammer',
            alternativeLabels: [],
            note: 'Her: Oracle, Access, SQL etc.',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'UP',
        label: 'Praktisk bruk av informasjonsteknologi',
        originalLabel: 'Praktisk bruk av informasjonsteknologi',
        alternativeLabels: [],
        note: 'Her: smarthus, automatiserte kontor, bruk av smartteknologi etc. Brukes med andre U*-koder for praktisk bruk av ulike digitale teknologier',
        related: [],
        children: [],
      },
      {
        id: 'UQ',
        label: 'IT-sertifisering',
        originalLabel: 'IT-sertifisering',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'UQF',
            label: 'IT-sertifisering: Microsoft',
            originalLabel: 'IT-sertifisering: Microsoft',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UQJ',
            label: 'IT-sertifisering: Cisco',
            originalLabel: 'IT-sertifisering: Cisco',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UQL',
            label: 'IT-sertifisering: Datakortet',
            originalLabel: 'IT-sertifisering: Datakortet',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UQR',
            label: 'IT-sertifisering: CompTIA',
            originalLabel: 'IT-sertifisering: CompTIA',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UQT',
            label: 'IT-sertifisering: CLAiT',
            originalLabel: 'IT-sertifisering: CLAiT',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'UR',
        label: 'Datasikkerhet',
        originalLabel: 'Datasikkerhet',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'URD',
            label: 'Personvern og datasikring',
            originalLabel: 'Personvern og datasikring',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'URH',
            label: 'Datainnbrudd og -svindel',
            originalLabel: 'Datainnbrudd og -svindel',
            alternativeLabels: [],
            note: 'Her: hacking',
            related: [],
            children: [],
          },
          {
            id: 'URJ',
            label: 'Datavirus, trojanere og dataormer',
            originalLabel: 'Datavirus, trojanere og dataormer',
            alternativeLabels: ['malware', 'anti-virus', 'skadeprogrammer'],

            related: [],
            children: [],
          },
          {
            id: 'URQ',
            label: 'Brannmurer',
            originalLabel: 'Brannmurer',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'URS',
            label: 'Søppelpost',
            originalLabel: 'Søppelpost',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'URW',
            label: 'Spionprogramvare',
            originalLabel: 'Spionprogramvare',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'URY',
            label: 'Datakryptering',
            originalLabel: 'Datakryptering',
            alternativeLabels: [],
            note: 'Se også GPJ <a href="/thema/nb/GPJ">Kodeteori og kryptologi</a>',
            related: [
              {
                id: 'GPJ',
                label: 'Kodeteori og kryptologi',
                originalLabel: 'Kodeteori og kryptologi',
                note: 'Her: kryptografi. Se også URY <a href="/thema/nb/URY">Datakryptering</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'UT',
        label: 'Datakommunikasjon og -nettverk',
        originalLabel: 'Datakommunikasjon og -nettverk',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'UTC',
            label: 'Nettskyen',
            originalLabel: 'Nettskyen',
            alternativeLabels: [],
            note: 'eng: cloud computing. Her: skylagring, programvare, infrastruktur, plattform, datasenter, informasjonsteknologi som en tjeneste etc.',
            related: [],
            children: [],
          },
          {
            id: 'UTD',
            label: 'Klient-server nettverk',
            originalLabel: 'Klient-server nettverk',
            alternativeLabels: [],
            note: 'Her: klient-tjener nettverk',
            related: [],
            children: [],
          },
          {
            id: 'UTE',
            label: 'Systemadministrasjon',
            originalLabel: 'Systemadministrasjon',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UTF',
            label: 'Nettverksadministrasjon',
            originalLabel: 'Nettverksadministrasjon',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'UTFB',
                label: 'Sikkerhetskopiering og datagjenoppretting',
                originalLabel: 'Sikkerhetskopiering og datagjenoppretting',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'UTG',
            label: 'Grid computing',
            originalLabel: 'Grid computing',
            alternativeLabels: [],
            note: 'Her: rutenett',
            related: [],
            children: [],
          },
          {
            id: 'UTM',
            label: 'Elektronisk post (e-post): profesjonelt',
            originalLabel: 'Elektronisk post (e-post): profesjonelt',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UTN',
            label: 'Nettverkssikkerhet',
            originalLabel: 'Nettverkssikkerhet',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UTP',
            label: 'Nettverksstandarder og -protokoller',
            originalLabel: 'Nettverksstandarder og -protokoller',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UTR',
            label: 'Distribuerte systemer',
            originalLabel: 'Distribuerte systemer',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UTS',
            label: 'Nettverkspakker',
            originalLabel: 'Nettverkspakker',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UTV',
            label: 'Virtualisering',
            originalLabel: 'Virtualisering',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UTW',
            label: 'WAP nettverk',
            originalLabel: 'WAP nettverk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UTX',
            label: 'Elektronisk datautveksling (EDI)',
            originalLabel: 'Elektronisk datautveksling (EDI)',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'UX',
        label: 'Anvendt databehandling',
        originalLabel: 'Anvendt databehandling',
        alternativeLabels: [],
        note: 'Her: digital forskning og teknikker, anvendelse av kunstig intelligens i samfunnet, analyse av stordata eller metoder og verktøy innen databehandling som brukes på tradisjonelle disipliner. Se også PSAX <a href="/thema/nb/PSAX">Bioinformatikk</a>, MBF <a href="/thema/nb/MBF">Medisinsk- og helsefaglig informatikk</a>',
        related: [
          {
            id: 'MBF',
            label: 'Medisinsk- og helsefaglig informatikk',
            originalLabel: 'Medisinsk- og helsefaglig informatikk',
            note: 'Her: bruk av kunstig intelligens i helsevesenet, elektronisk pasientjournal. Brukes for bøker som handler om design, utvikling og bruk av digitale systemer for å forbedre helsevesenet. Se også PSAX <a href="/thema/nb/PSAX">Bioinformatikk</a>',
          },
          {
            id: 'PSAX',
            label: 'Bioinformatikk',
            originalLabel: 'Bioinformatikk',
            note: 'Se også MBF <a href="/thema/nb/MBF">Medisinsk- og helsefaglig informatikk</a>',
          },
        ],
        children: [
          {
            id: 'UXA',
            label: 'Dataprogram for kunst og humaniora',
            originalLabel: 'Dataprogram for kunst og humaniora',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UXJ',
            label:
              'Dataprogram for pedagogikk, psykologi, sosiologi og samfunnsfag',
            originalLabel:
              'Dataprogram for pedagogikk, psykologi, sosiologi og samfunnsfag',
            alternativeLabels: [],
            note: 'Se også GTK <a href="/thema/nb/GTK">Kognitive studier</a>',
            related: [
              {
                id: 'GTK',
                label: 'Kognitive studier',
                originalLabel: 'Kognitive studier',
                note: 'Brukes for tverrfaglig arbeid. Se også QDTM <a href="/thema/nb/QDTM">Bevissthetsfilosofi</a>, JM* <a href="/thema/nb/JM">Psykologi</a> og dens underkategorier, MKJ <a href="/thema/nb/MKJ">Nevrologi og klinisk nevrofysiologi</a>, PSAN <a href="/thema/nb/PSAN">Nevrovitenskap</a>, UYQ* <a href="/thema/nb/UYQ">Kunstig intelligens</a> og dens underkategorier, UXJ <a href="/thema/nb/UXJ">Dataprogram for pedagogikk, psykologi, sosiologi og samfunnsfag</a>',
              },
            ],
            children: [],
          },
          {
            id: 'UXT',
            label: 'Dataprogram i industri og teknologi',
            originalLabel: 'Dataprogram i industri og teknologi',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'UY',
        label: 'Informatikk',
        originalLabel: 'Informatikk',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'UYA',
            label: 'Matematisk datateori',
            originalLabel: 'Matematisk datateori',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'UYAM',
                label: 'Matematikk for informatikkfag',
                originalLabel: 'Matematikk for informatikkfag',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'UYD',
            label: 'Systemanalyse og -design',
            originalLabel: 'Systemanalyse og -design',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UYF',
            label: 'Dataarkitektur og logisk design',
            originalLabel: 'Dataarkitektur og logisk design',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'UYFL',
                label: 'Assemblerspråk',
                originalLabel: 'Assemblerspråk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'UYFP',
                label: 'Parallellprosessering',
                originalLabel: 'Parallellprosessering',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'UYM',
            label: 'Datamodellering og -simulering',
            originalLabel: 'Datamodellering og -simulering',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UYQ',
            label: 'Kunstig intelligens',
            originalLabel: 'Kunstig intelligens',
            alternativeLabels: [],
            note: 'Se også GTK <a href="/thema/nb/GTK">Kognitive studier</a>',
            related: [
              {
                id: 'GTK',
                label: 'Kognitive studier',
                originalLabel: 'Kognitive studier',
                note: 'Brukes for tverrfaglig arbeid. Se også QDTM <a href="/thema/nb/QDTM">Bevissthetsfilosofi</a>, JM* <a href="/thema/nb/JM">Psykologi</a> og dens underkategorier, MKJ <a href="/thema/nb/MKJ">Nevrologi og klinisk nevrofysiologi</a>, PSAN <a href="/thema/nb/PSAN">Nevrovitenskap</a>, UYQ* <a href="/thema/nb/UYQ">Kunstig intelligens</a> og dens underkategorier, UXJ <a href="/thema/nb/UXJ">Dataprogram for pedagogikk, psykologi, sosiologi og samfunnsfag</a>',
              },
            ],
            children: [
              {
                id: 'UYQE',
                label: 'Ekspertsystemer og kunnskapsbaserte systemer',
                originalLabel: 'Ekspertsystemer og kunnskapsbaserte systemer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'UYQL',
                label: 'Naturlig språk og maskinoversettelse',
                originalLabel: 'Naturlig språk og maskinoversettelse',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'UYQM',
                label: 'Maskinlæring',
                originalLabel: 'Maskinlæring',
                alternativeLabels: [],
                note: 'Her: dyp læring',
                related: [],
                children: [],
              },
              {
                id: 'UYQN',
                label: 'Nevrale nettverk og fuzzy systemer',
                originalLabel: 'Nevrale nettverk og fuzzy systemer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'UYQP',
                label: 'Mønstergjenkjenning',
                originalLabel: 'Mønstergjenkjenning',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'UYQS',
                label: 'Stemmegjenkjenning',
                originalLabel: 'Stemmegjenkjenning',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'UYQV',
                label: 'Computer vision',
                originalLabel: 'Computer vision',
                alternativeLabels: [],
                note: 'Brukes for bøker som ser på hvordan kunstig intelligens gjør det mulig for maskiner og systemer å trekke ut informasjon fra digitale bilder, videoer og andre bildemedier, og hvordan man kan bruke denne informasjonen til å gi anbefalinger basert på disse bildene, f.eks. ansiktsgjenkjenning, gjenkjenning av gjenstander, bildeklassifisering, bevegelsesanalyse',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'UYS',
            label: 'Digital signalbehandling',
            originalLabel: 'Digital signalbehandling',
            alternativeLabels: ['DSP'],

            related: [],
            children: [],
          },
          {
            id: 'UYT',
            label: 'Bildebehandling',
            originalLabel: 'Bildebehandling',
            alternativeLabels: [],
            note: 'Her: bøker om hvordan digitale bilder prosesseres, forbedring av digital bildekvalitet, filtrering etc.',
            related: [],
            children: [],
          },
          {
            id: 'UYU',
            label: 'Lydbehandling',
            originalLabel: 'Lydbehandling',
            alternativeLabels: [],
            note: 'Class here: audio signal processing',
            related: [],
            children: [],
          },
          {
            id: 'UYV',
            label: 'Virtuell virkelighet',
            originalLabel: 'Virtuell virkelighet',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'UYW',
            label: 'Utvidet virkelighet',
            originalLabel: 'Utvidet virkelighet',
            alternativeLabels: [],
            note: 'Her: AR, augmented reality',
            related: [],
            children: [],
          },
          {
            id: 'UYX',
            label: 'Kvantedata og -informasjon',
            originalLabel: 'Kvantedata og -informasjon',
            alternativeLabels: [],
            note: 'Her: kvanteinformasjon, kvantedatamaskiner',
            related: [],
            children: [],
          },
          {
            id: 'UYY',
            label: 'Spatial computing',
            originalLabel: 'Spatial computing',
            alternativeLabels: [],
            note: 'Brukes for bøker om romlig databehandling eller om teknologier som bruker slike prinsipper. Brukes med andre U*-koder for bruk av romling databehandling på andreområder, med M* for bruk innen medisin, med RGW eller RGXH for bruk innen mapping, med T* for teknologi. Se også UYV <a href="/thema/nb/UYV">Virtuell virkelighet</a>, UYW <a href="/thema/nb/UYW">Utvidet virkelighet</a>',
            related: [
              {
                id: 'UYV',
                label: 'Virtuell virkelighet',
                originalLabel: 'Virtuell virkelighet',
              },
              {
                id: 'UYW',
                label: 'Utvidet virkelighet',
                originalLabel: 'Utvidet virkelighet',
                note: 'Her: AR, augmented reality',
              },
            ],
            children: [],
          },
          {
            id: 'UYZ',
            label: 'Menneske-maskin-interaksjon',
            originalLabel: 'Menneske-maskin-interaksjon',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'UYZF',
                label: 'Informasjonsvisualisering',
                originalLabel: 'Informasjonsvisualisering',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'UYZG',
                label: 'Brukergrensesnittdesign',
                originalLabel: 'Brukergrensesnittdesign',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'UYZM',
                label: 'Informasjonsarkitektur',
                originalLabel: 'Informasjonsarkitektur',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'V',
    label: 'Helse, relasjoner og personlig utvikling',
    originalLabel: 'Helse, relasjoner og personlig utvikling',
    alternativeLabels: [],
    note: 'Bruk V* koder for bøker for allmennmarkedet. IKKE bruk koden ‘V’ alene, men velg en spesifikk kode fra V*. Bruk V*-koder med andre emnekategorier og kvalifikatorer der det er hensiktsmessig. Foretrekk koder fra C*, J*, K*, L*, M*, Q*, R* for bøker for profesjonsmarkedet. NB: dersom V* kombineres med en av de opplistede kodene, må V* angis som den viktigste koden og stå først',
    related: [],
    children: [
      {
        id: 'VF',
        label: 'Familie og helse',
        originalLabel: 'Familie og helse',
        alternativeLabels: [],
        note: 'Bruk VF*-koder på bøker for allmenmarkedet. Foretrekk passende koder fra seksjonene J, M etc. på bøker for høyere utdanning og profesjonsmarkedet. NB: dersom VF* kombineres med J* eller M*, må VF* angis som den viktigste koden og stå først',
        related: [],
        children: [
          {
            id: 'VFB',
            label: 'Personlig sikkerhet',
            originalLabel: 'Personlig sikkerhet',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'VFD',
            label: 'Populærmedisin og helse',
            originalLabel: 'Populærmedisin og helse',
            alternativeLabels: [],
            note: 'Brukes for bøker med populærmedisinsk tilnærming menneskers helse, til årsaker, forebygging eller behandling av sykdommer, tilstander og andre helseproblemer. NB: dersom VFD kombineres med M*, må VFD angis som den viktigste koden og stå først',
            related: [],
            children: [
              {
                id: 'VFDB',
                label: 'Menneskekroppen',
                originalLabel: 'Populærmedisin og helse: menneskekroppen',
                alternativeLabels: [],
                note: 'Brukes for bøker med populærmedisinsk tilnærming til menneskekroppen og hvordan de enkelte organene fungerer, eller som gir råd om helserelaterte emner, eller som forklarer emner som forbindes med menneskekroppen som f.eks. bevegelse, personlig hygiene',
                related: [],
                children: [],
              },
              {
                id: 'VFDF',
                label: 'Førstehjelp for hjemmet',
                originalLabel: 'Førstehjelp for hjemmet',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'VFDJ',
                label: 'Barns helse',
                originalLabel: 'Barns helse',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'VFDM',
                label: 'Menns helse',
                originalLabel: 'Menns helse',
                alternativeLabels: ['testosteron'],
                note: 'Her: andropause, impotens, testosteronnivå, testikkelproblemer etc. Brukes for bøker som handler om menns helse, som gir råd om eller tar for seg deler av den mannlige kroppen, eller som gir råd om sykdommer og tilstander som berører menn spesielt. Brukes med andre VF*-koder der det er hensiktsmessig, f.eks. VFJB3 for bøker om testikkelkreft, VFJQ1 for bøker om depresjon hos menn, VFJX1 for bøker om selvmordstanker blant menn etc.',
                related: [],
                children: [
                  {
                    id: 'VFDM1',
                    label: 'Prostata',
                    originalLabel: 'Prostata',
                    alternativeLabels: [],
                    note: 'Her: råd om og emner som forbindes med prostata. Brukes med VFJB3 for bøker om prostatakreft',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'VFDW',
                label: 'Kvinners helse',
                originalLabel: 'Kvinners helse',
                alternativeLabels: ['kvinnehelse'],
                note: 'Her: råd om menstruasjon. Brukes for bøker som handler om kvinners helse, som gir råd om eller tar for seg deler av kvinnekroppen, eller som gir råd om sykdommer og tilstander som berører kvinner spesielt. Brukes med andre VF*-koder der det er hensiktsmessig, f.eks. VFJB2 for bøker om kreft, VFJQ1 for bøker om depresjon hos kvinner etc.',
                related: [],
                children: [
                  {
                    id: 'VFDW2',
                    label: 'Menopause',
                    originalLabel: 'Menopause',
                    alternativeLabels: [],
                    note: 'Her: kvinners overgangsalder, hormonterapi',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'VFG',
            label: 'Sykepleie i hjemmet',
            originalLabel: 'Sykepleie i hjemmet',
            alternativeLabels: [],
            note: 'Brukes for bøker for allmennmarkedet som inneholder praktiske råd om det å ta vare på personer i deres eget hjem. Se også MQC* <a href="/thema/nb/MQC">Sykepleie</a> og dens underkategorier',
            related: [
              {
                id: 'MQC',
                label: 'Sykepleie',
                originalLabel: 'Sykepleie',
                note: 'Brukes med andre koder fra M* for å angi spesialiserte områder og emner innen sykepleie, når de ikke har en egen MQCL*-kode',
              },
            ],
            children: [],
          },
          {
            id: 'VFJ',
            label: 'Takle personlige problemer og helseproblemer',
            originalLabel: 'Takle personlige problemer og helseproblemer',
            alternativeLabels: [],
            note: 'Brukes for bøker for allmennmarkedet som handler om hvordan man kan takle eller gir råd om personlige problemer og helseproblemer. Bruk VFJ* med andre koder der det er hensiktsmessig, f.eks. F*-koder for romaner, eller 5L* når det er spesifikke aldersgrupper som omhandles, eller DNC for memoarer fra personer som har hatt en spesifikk sykdom eller tilstand',
            related: [],
            children: [
              {
                id: 'VFJB',
                label: 'Takle sykdom og diagnoser',
                originalLabel: 'Takle sykdom og diagnoser',
                alternativeLabels: [],
                note: 'Her: råd om det å takle en sykdom eller helsetilstand, rekonvalesens fra en sykdom eller skade. Brukes med andre koder fra VF* eller M* for spesifikk(e) tilstand(er), der det er tilgjengelig eller hensiktsmessig. Bruk VFJB* med VFJX for bøker som handler om palliativ pleie. NB: dersom VFJB kombineres med M*, må V* angis som den viktigste koden og settes først',
                related: [],
                children: [
                  {
                    id: 'VFJB1',
                    label: 'Takle allergier, inkludert matallergi',
                    originalLabel: 'Takle allergier, inkludert matallergi',
                    alternativeLabels: [],
                    note: 'Her: bøker som handler om eller gir råd om skalldyrallergi, allergi mot meieriprodukter, hvete, nøtter, egg, soya, luftbårne allergier som pollenallergi, høyfeber, allergi mot dyr eller insektstikk, allergi mot medisiner etc., og praktiske planer for hvordan man kan identifisere allergenene som man møter i mat og i hverdagslivet. Brukes med WBHS for bøker om matlaging for personer med allergier eller intoleranser',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VFJB2',
                    label: 'Takle ryggproblemer',
                    originalLabel: 'Takle ryggproblemer',
                    alternativeLabels: ['ryggen'],
                    note: 'Her: bøker om å takle ryggsmerter, råd om holdning og hvordan man kan takle spesifikke ryggproblemer',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VFJB3',
                    label: 'Takle kreft',
                    originalLabel: 'Takle kreft',
                    alternativeLabels: [],
                    note: 'Brukes for bøker om å leve med og takle kreft, eller om spesifikke typer av kreft, om å redusere faren for å få kreft, eller bøker om rekonvalesens etter å ha hatt kreft, eller å forberede seg på at livet går mot slutten for kreftpasienter, eller som gir råd om disse temaene',
                    related: [],
                    children: [
                      {
                        id: 'VFJB31',
                        label: 'Takle brystkreft',
                        originalLabel: 'Takle brystkreft',
                        alternativeLabels: [],
                        note: 'Her: bøker om brystkreft, om å redusere risikoen for å få brystkreft eller råd om rekonvalesens etter brystkreft eller mastektomi',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'VFJB4',
                    label: 'Takle hjertesykdommer / råd om hjertet',
                    originalLabel: 'Takle hjertesykdommer / råd om hjertet',
                    alternativeLabels: ['hypertensjon', 'menneskehjertet'],
                    note: 'Her: populærmedisinske bøker om menneskehjertet, blodsirkulasjon, hvordan takle livet etter et hjerteattakk, høyt kolesterol, høyt blodtrykk, lavt blodtrykk. Brukes med WBHS* for bøker om matlaging og dietter for personer med hjerte- og karsykdommer',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VFJB5',
                    label: 'Takle diabetes',
                    originalLabel: 'Takle diabetes',
                    alternativeLabels: [],
                    note: 'Her: bøker som gir råd om det å leve med diabetes. Brukes med WBHS* for bøker om kosthold for personer med diabetes eller som har forstadier til diabetes',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VFJB6',
                    label: 'Takle Alzheimers og demens',
                    originalLabel: 'Takle Alzheimers og demens',
                    alternativeLabels: [],
                    note: 'Her: bøker som handler om å takle eller som gir råd om å leve med Alzheimers og demens',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VFJB7',
                    label: 'Takle hodepine og migrene',
                    originalLabel: 'Takle hodepine og migrene',
                    alternativeLabels: [],
                    note: 'Her: bøker som handler om å takle eller som gir råd om å leve med hodepine og migrene',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VFJB9',
                    label: 'Takle kroniske sykdommer eller tilstander',
                    originalLabel: 'Takle kroniske sykdommer eller tilstander',
                    alternativeLabels: [
                      'HIV',
                      'kronisk utmattelsessyndrom',
                      'ME',
                      'Crohns sykdom',
                      'IBD,Ulcerøs kolitt',
                      'Parkinsons',
                    ],
                    note: 'Her: langvarig sykdom. Brukes for bøker som handler om eller gir råd om å leve med kronisk sykdom eller om å gi omsorg til personer med kroniske eller langvarige sykdommer, leve med smerter, utmattelsessyndrom, kronisk covid / long covid, leddgikt etc.',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'VFJD',
                label: 'Takle funksjonsnedsettelse',
                originalLabel: 'Takle funksjonsnedsettelse',
                alternativeLabels: [],
                note: 'Brukes for praktiske råd til en person med fysiske eller sensoriske funksjonsnedsettelser. Brukes med kvalifikatorer fra 5PM* der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'VFJG',
                label: 'Takle aldring',
                originalLabel: 'Takle aldring',
                alternativeLabels: [],
                note: 'Brukes for praktiske råd om det å bli eldre, om å takle ulike sider ved aldring. Brukes med 5LK*-koder der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'VFJH',
                label: 'Takle spørsmål rundt kroppsbilde',
                originalLabel: 'Takle spørsmål rundt kroppsbilde',
                alternativeLabels: [
                  'kroppsbildeforstyrrelse',
                  'kroppsbildeproblem',
                ],
                note: 'Her: kroppspositivitet, -negativitet, -skam, megareksi etc., råd om hvordan takle ulike problemstillinger rundt kroppsbilde',
                related: [],
                children: [],
              },
              {
                id: 'VFJJ',
                label: 'Takle spiseforstyrrelser',
                originalLabel: 'Takle spiseforstyrrelser',
                alternativeLabels: [
                  'unnvikende restriktiv spiseforstyrrelse',
                  'Avoidant restrictive food intake disorder',
                  'ARFID',
                ],
                note: 'Her: anoreksi, bulimi, overspising, selektiv spiseforstyrrelse og råd om hvordan man kan takle disse spiseforstyrrelsene',
                related: [],
                children: [],
              },
              {
                id: 'VFJK',
                label: 'Takle narkotika- og alkoholproblemer',
                originalLabel: 'Takle narkotika- og alkoholproblemer',
                alternativeLabels: ['alkoholisme'],
                note: 'Her: stoffmisbruk, alkoholmisbruk, narkotikamisbruk og råd om hvordan man kan takle disse typene av misbruk',
                related: [],
                children: [],
              },
              {
                id: 'VFJL',
                label: 'Takle avhengighet',
                originalLabel: 'Takle avhengighet',
                alternativeLabels: [
                  'avhengige',
                  'kjøpebesettelse',
                  'besettelse',
                ],
                note: 'Her: spillavhengighet, sexavhengighet, shoppingavhengighet, avhengighet av internett og digitale medier. Brukes for bøker om eller som gir råd om avhengighet som ikke er dekket av andre koder',
                related: [],
                children: [],
              },
              {
                id: 'VFJM',
                label: 'Takle mishandling/overgrep',
                originalLabel: 'Takle mishandling/overgrep',
                alternativeLabels: [
                  'familievold',
                  'vold i hjemmet',
                  'psykisk vold',
                  'tvang',
                  'manipulering',
                ],
                note: 'Brukes for bøker om eller som har råd om å komme seg fra ulike typer mishandling eller overgrep, både fysiske, mentale eller følelsesmessige',
                related: [],
                children: [],
              },
              {
                id: 'VFJN',
                label: 'Takle mobbing, tvang og trakassering',
                originalLabel: 'Takle mobbing, tvang og trakassering',
                alternativeLabels: ['stalking'],
                note: 'Brukes for bøker som gir råd om hvordan man kan takle nettmobbing, trolling i sosiale medier, digital mobbing og trusler, trakassering på arbeidsplassen, tvang, personforfølgelse og truende eller kontrollerende oppførsel, gruppepress, gaslighting, ondsinnet oppførsel. Brukes med KJMV2 for mobbing og trakassering på arbeidsplassen',
                related: [],
                children: [],
              },
              {
                id: 'VFJP',
                label: 'Takle angst og fobier',
                originalLabel: 'Takle angst og fobier',
                alternativeLabels: ['GAD'],
                note: 'Her: panikkanfall, tvangslidelser, generalisert angstlidelse og råd om hvordan man kan takle slike lidelser og fobier',
                related: [],
                children: [],
              },
              {
                id: 'VFJQ',
                label: 'Takle psykiske problemer',
                originalLabel: 'Takle psykiske problemer',
                alternativeLabels: ['mental helse'],
                note: 'Brukes for bøker som handler om eller gir råd om psykiske problemer og lidelser som ikke er dekket andre steder',
                related: [],
                children: [
                  {
                    id: 'VFJQ1',
                    label: 'Takle depresjon og andre affektive lidelser',
                    originalLabel:
                      'Takle depresjon og andre affektive lidelser',
                    alternativeLabels: [],
                    note: 'Her: bøker som handler om eller gir råd om sesongavhengig depresjon, bipolar lidelse, fødselsdepresjon, svangerskapsdepresjon',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VFJQ2',
                    label: 'Takle selvskading',
                    originalLabel: 'Takle selvskading',
                    alternativeLabels: ['selvmutilering', 'kutting'],
                    note: 'Her: bøker som handler om eller gir råd om hvordan man kan takle selvskading',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VFJQ3',
                    label:
                      'Takle posttraumatisk stresslidelse og andre psykiske traumer',
                    originalLabel:
                      'Takle posttraumatisk stresslidelse og andre psykiske traumer',
                    alternativeLabels: [],
                    note: 'Her: bøker som handler om eller gir råd om å takle posttraumatisk stresslidelse (PTSD) og psykiske traumer',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'VFJR',
                label: 'Takle nevroutviklingsforstyrrelser',
                originalLabel: 'Takle nevroutviklingsforstyrrelser',
                alternativeLabels: [],
                note: 'Her: bøker som handler om eller gir råd om å takle nevroutviklingsforstyrrelser',
                related: [],
                children: [
                  {
                    id: 'VFJR1',
                    label: 'Takle autisme / aspergers syndrom',
                    originalLabel: 'Takle autisme / aspergers syndrom',
                    alternativeLabels: [],
                    note: 'Her: bøker som handler om eller gir råd om autismespekterforstyrrelser',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VFJR2',
                    label: 'Takle ADHD',
                    originalLabel: 'Takle ADHD',
                    alternativeLabels: [
                      'attention deficit hyperactivity disorder',
                    ],
                    note: 'Her: bøker som handler om eller gir råd om oppmerksomhets- og hyperaktivitetsforstyrrelse',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VFJR3',
                    label: 'Takle dysleksi og lærevansker',
                    originalLabel: 'Takle dysleksi og lærevansker',
                    alternativeLabels: [
                      'lesevansker',
                      'skrivevansker',
                      'ordblindhet',
                    ],
                    note: 'Her: bøker som handler om eller gir råd om dyskalkuli, dysgrafi',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VFJR4',
                    label: 'Takle kommunikasjonsvansker',
                    originalLabel: 'Takle kommunikasjonsvansker',
                    alternativeLabels: ['afasi', 'språkvansker'],
                    note: 'Her: bøker som handler om eller gir råd om stamming, taleforstyrrelser',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'VFJS',
                label: 'Takle stress',
                originalLabel: 'Takle stress',
                alternativeLabels: [],
                note: 'Her: råd om det å takle kriser',
                related: [],
                children: [],
              },
              {
                id: 'VFJT',
                label: 'Takle ensomhet',
                originalLabel: 'Takle ensomhet',
                alternativeLabels: [],
                note: 'Her: takle isolasjon, råd om å takle ensomhet',
                related: [],
                children: [],
              },
              {
                id: 'VFJV',
                label: 'Takle søvnproblemer',
                originalLabel: 'Takle søvnproblemer',
                alternativeLabels: [],
                note: 'Her: det å sove bra, råd om søvnløshet, snorking, kronisk utmattelsessyndrom, teknikker for å sove bedre, takle narkolepsi',
                related: [],
                children: [],
              },
              {
                id: 'VFJX',
                label: 'Takle død og sorg',
                originalLabel: 'Takle død og sorg',
                alternativeLabels: [],
                note: 'Her: begravelser, minnestunder og tap, råd om hvordan man kan forberede seg på livets slutt, takle dødelige sykdommer. Se også JHBZ <a href="/thema/nb/JHBZ">Sosiologi: døden</a>',
                related: [
                  {
                    id: 'JHBZ',
                    label: 'Døden',
                    originalLabel: 'Sosiologi: døden',
                    note: 'Brukes for bøker som undersøker forholdet mellom døden og samfunnet, holdninger og tabuer rundt dødelighet, skikker, seremonier og tradisjoner rundt begravelser, hvordan samfunnet håndterer sorg og etterlatte. Se også VFJX <a href="/thema/nb/VFJX">Takle død og sorg</a>, QRVL <a href="/thema/nb/QRVL">Religion: døden</a>, KNSZ <a href="/thema/nb/KNSZ">Begravelsesbransjen</a>',
                  },
                ],
                children: [
                  {
                    id: 'VFJX1',
                    label: 'Takle selvmord',
                    originalLabel: 'Takle selvmord',
                    alternativeLabels: [],
                    note: 'Her: bøker om å få hjelp dersom man sliter med selvmordstanker, hjelp og råd til å takle at noen har begått selvmord, hvordan man kan forhindre selvmord, råd for hvordan man kan snakke om temaet',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'VFL',
            label: 'Å slutte å røyke',
            originalLabel: 'Å slutte å røyke',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'VFM',
            label: 'Trening og kosthold',
            originalLabel: 'Trening og kosthold',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'VFMD',
                label: 'Dietter og slanking, ernæring',
                originalLabel: 'Dietter og slanking, ernæring',
                alternativeLabels: ['detox'],
                note: 'Her: vektkontroll, vektøkning, fasting som diett, kosttilskudd. Brukes for bøker for allmennmarkedet om godt kosthold og ernæring, samt bøker om slankekurer. Brukes med WB*-koder for dietter knyttet til spesielle matvarer, f.eks. WBJK for et plantebasert (vegansk) kosthold eller slankekur. Se også WBH <a href="/thema/nb/WBH">Kokebøker: diett og helsekost</a>',
                related: [
                  {
                    id: 'WBH',
                    label: 'Kokebøker: diett og helsekost',
                    originalLabel: 'Kokebøker: diett og helsekost',
                    note: 'Her: oppskrifter på sunn mat. Brukes for bøker som har menyforslag og oppskrifter på mat som er ment å vedlikeholde og forbedre god helse. Brukes med SCG eller andre koder fra S* for dietter og matlaging for idrettsutøvere',
                  },
                ],
                children: [],
              },
              {
                id: 'VFMG',
                label: 'Trening og treningsbøker',
                originalLabel: 'Trening og treningsbøker',
                alternativeLabels: ['cross-training', 'crosstraining'],
                note: 'Her: pilates, aerobics, stretching, fleksibilitet, zumba, spinning, sirkeltrening, CrossFit, dans som trening, intervalltrening etc. Brukes med S*-koder dersom treningsregimet er knyttet til en spesiell idrett',
                related: [],
                children: [
                  {
                    id: 'VFMG1',
                    label: 'Yoga som trening',
                    originalLabel: 'Yoga som trening',
                    alternativeLabels: [],
                    note: 'Se også QDHC2 <a href="/thema/nb/QDHC2">Yoga (som filosofi)</a>',
                    related: [
                      {
                        id: 'QDHC2',
                        label: 'Yoga (som filosofi)',
                        originalLabel: 'Yoga (som filosofi)',
                        note: 'Se også VFMG1 <a href="/thema/nb/VFMG1">Yoga som trening</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'VFMG2',
                    label: 'Styrketrening',
                    originalLabel: 'Styrketrening',
                    alternativeLabels: ['vekttrening'],
                    note: 'Her: trening med vekter, egenvektstrening. Se også SHP <a href="/thema/nb/SHP">Vektløfting</a>',
                    related: [
                      {
                        id: 'SHP',
                        label: 'Vektløfting',
                        originalLabel: 'Vektløfting',
                        note: 'Her: styrkeløft. Se også VFMG <a href="/thema/nb/VFMG">Trening og treningsbøker</a>',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'VFMS',
                label: 'Massasje',
                originalLabel: 'Massasje',
                alternativeLabels: [],
                note: 'Her: massasjeterapi og massasjeteknikker. Se også VXHJ <a href="/thema/nb/VXHJ">Refleksologi</a>',
                related: [
                  {
                    id: 'VXHJ',
                    label: 'Refleksologi',
                    originalLabel: 'Refleksologi',
                    note: 'Se også VFMS <a href="/thema/nb/VFMS">Massasje</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'VFV',
            label: 'Familie og relasjoner',
            originalLabel: 'Familie og relasjoner',
            alternativeLabels: [],
            note: 'Brukes for bøker som tar for seg ulike typer relasjoner i familier eller sosiale grupper, som ikke er dekket av andre koder, eller som har råd til ulike typer familier, inkludert utvidede, bonus-, dysfunksjonelle familier, relasjoner mellom søsken eller andre slektninger. Se også JBFW <a href="/thema/nb/JBFW">Sex og seksualitet: sosiale aspekter</a>, JHBK <a href="/thema/nb/JHBK">Sosiologi: familie og relasjoner</a>, WQY <a href="/thema/nb/WQY">Familiehistorie og slektsgransking</a>',
            related: [
              {
                id: 'JBFW',
                label: 'Sex og seksualitet',
                originalLabel: 'Sex og seksualitet: sosiale aspekter',
                note: 'Her: generelle bøker om sex og samfunn, pornografi og sexindustrien, sexarbeid og prostitusjon, eller bøker som tar for seg seksualitet i bestemte samfunn, kulturelle eller samfunnsmessige holdninger eller normer knyttet til seksualitet, undertrykkelse og kriminalisering av seksualitet, holdninger til prevensjon og seksualopplysning, etc. Se også JMU <a href="/thema/nb/JMU">Psykologi: seksuell adferd</a>, VFVC <a href="/thema/nb/VFVC">Sex og seksualitet</a>',
              },
              {
                id: 'JHBK',
                label: 'Familie og relasjoner',
                originalLabel: 'Sosiologi: familie og relasjoner',
                note: 'Her: familiesosiologi. Brukes for bøker om familiestrukturer, relasjoner innen familien, vennskap, relasjonspsykologi, sosial kontroll eller konformitet og ensretting innen grupper eller familier; medavhengighet i familier, bøker som ser på hvordan mennesker utvikler seg fysisk, følelsesmessig og sosialt innenfor konteksten av familie og samfunn. Se også JBFW <a href="/thema/nb/JBFW">Sex og seksualitet: sosiale aspekter</a>, VFV* <a href="/thema/nb/VFV">Familie og relasjoner</a> og dens underkategorier',
              },
              {
                id: 'WQY',
                label: 'Familiehistorie og slektsgransking',
                originalLabel: 'Familiehistorie og slektsgransking',
                note: 'Se også NHTG <a href="/thema/nb/NHTG">Genealogi, heraldikk og navnehistorie</a>',
              },
            ],
            children: [
              {
                id: 'VFVC',
                label: 'Sex og seksualitet',
                originalLabel: 'Sex og seksualitet',
                alternativeLabels: [],
                note: 'Her: Kama Sutra, tips til et godt sexliv. Brukes for bøker som handler om eller forklarer ulike sider ved sex og seksualitet, inkludert, men ikke begrenset til samtykke, seksuelle relasjoner, tilfeldig sex, pornografi, fysisk intimitet, seksuell helse, seksuell nytelse, teknikker og råd for å få et bedre sexliv, bruk av afrodisiakum, orgasmer, masturbering, sexråd til eldre, impotens, bøker om de menneskelige kjønnsorganene og forplantningssystemet. Se også JBFW <a href="/thema/nb/JBFW">Sex og seksualitet: sosiale aspekter</a>, JMU <a href="/thema/nb/JMU">Psykologi: seksuell adferd</a>',
                related: [
                  {
                    id: 'JBFW',
                    label: 'Sex og seksualitet',
                    originalLabel: 'Sex og seksualitet: sosiale aspekter',
                    note: 'Her: generelle bøker om sex og samfunn, pornografi og sexindustrien, sexarbeid og prostitusjon, eller bøker som tar for seg seksualitet i bestemte samfunn, kulturelle eller samfunnsmessige holdninger eller normer knyttet til seksualitet, undertrykkelse og kriminalisering av seksualitet, holdninger til prevensjon og seksualopplysning, etc. Se også JMU <a href="/thema/nb/JMU">Psykologi: seksuell adferd</a>, VFVC <a href="/thema/nb/VFVC">Sex og seksualitet</a>',
                  },
                  {
                    id: 'JMU',
                    label: 'Psykologi: seksuell adferd',
                    originalLabel: 'Psykologi: seksuell adferd',
                    note: 'Se også VFVC <a href="/thema/nb/VFVC">Sex og seksualitet</a>, JBFW <a href="/thema/nb/JBFW">Sex og seksualitet: sosiale aspekter</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'VFVG',
                label: 'Ekteskap, samliv, forhold og stevnemøter',
                originalLabel: 'Ekteskap, samliv, forhold og stevnemøter',
                alternativeLabels: ['dating'],
                note: 'Brukes før bøker som gir råd om eller som er praktiske guider til ulike aspekter ved forhold basert på kjærlighet og romantikk, trofasthet, monogame forhold, å være et par, å leve fra hverandre eller sammen, utroskap, samboerskap, sjalusi, rutiner, stevnemøter, datingapper etc. Se også WJW <a href="/thema/nb/WJW">Bryllup og bryllupsplanlegging</a>',
                related: [
                  {
                    id: 'WJW',
                    label: 'Bryllup og bryllupsplanlegging',
                    originalLabel: 'Bryllup og bryllupsplanlegging',
                  },
                ],
                children: [
                  {
                    id: 'VFVG2',
                    label: 'Ikke-monogame / polyamorøse forhold',
                    originalLabel: 'Ikke-monogame / polyamorøse forhold',
                    alternativeLabels: [
                      'flerkoneri',
                      'polygami',
                      'trekant',
                      'swingers',
                    ],
                    note: 'Her: ikke-monogame forhold hvor begge partene samtykker til dette, åpne forhold, polyamori',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'VFVJ',
                label: 'Ufrivillig barnløshet',
                originalLabel: 'Ufrivillig barnløshet',
                alternativeLabels: [],
                note: 'Her: infertilitet',
                related: [],
                children: [],
              },
              {
                id: 'VFVK',
                label: 'Adopsjon og fosterbarn',
                originalLabel: 'Adopsjon og fosterbarn',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'VFVM',
                label: 'Singelliv',
                originalLabel: 'Singelliv',
                alternativeLabels: [],
                note: 'Her: råd om å leve uten en partner',
                related: [],
                children: [],
              },
              {
                id: 'VFVN',
                label: 'Vennskap',
                originalLabel: 'Vennskap',
                alternativeLabels: [],
                note: 'Her: råd om det å få venner, vennskap, grupper av jevnaldrende, sosiale nettverk, sosiale grupper etc.',
                related: [],
                children: [],
              },
              {
                id: 'VFVS',
                label: 'Separasjon og skilsmisse',
                originalLabel: 'Separasjon og skilsmisse',
                alternativeLabels: [],
                note: 'Her: råd om a avslutte et (dårlig) forhold, å forlate en partner, hvordan takle et samlivsbrudd, praktiske råd for personer som har tatt ut separasjon eller som er skilte',
                related: [],
                children: [],
              },
              {
                id: 'VFVX',
                label: 'Relasjoner mellom generasjoner',
                originalLabel: 'Relasjoner mellom generasjoner',
                alternativeLabels: [],
                note: 'Her: relasjoner foreldre-barn, besteforeldre-barnebarn, generasjonskløft, misforståelser og relasjoner på tvers av generasjoner etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'VFX',
            label: 'Foreldrerollen',
            originalLabel: 'Foreldrerollen',
            alternativeLabels: [],
            note: 'Her: råd for mødre, fedre og besteforeldre, råd om å være nybakt mor eller far, å være enslig mor eller far, være steforelder, om oppdragelse etc. Brukes med 5JA dersom innholdet er skrevet spesielt for kvinner om morsrollen, eller 5JB dersom innholdet er skrevet spesielt for menn om farsrollen',
            related: [],
            children: [
              {
                id: 'VFXB',
                label: 'Graviditet, fødsel og spedbarnsstell',
                originalLabel: 'Graviditet, fødsel og spedbarnsstell',
                alternativeLabels: [],
                note: 'Her: råd om unnfangelse, amming etc.',
                related: [],
                children: [
                  {
                    id: 'VFXB1',
                    label: 'Spedbarnsnavn: håndbøker for foreldre',
                    originalLabel: 'Spedbarnsnavn: håndbøker for foreldre',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'VFXC',
                label: 'Barnestell og oppdragelse: råd for foreldre',
                originalLabel: 'Barnestell og oppdragelse: råd for foreldre',
                alternativeLabels: [],
                note: 'Brukes med kvalifikatorer for interesse fra 5L* der det er hensiktsmessig',
                related: [],
                children: [
                  {
                    id: 'VFXC1',
                    label: 'Tenåringer: råd for foreldre',
                    originalLabel: 'Tenåringer: råd for foreldre',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'VS',
        label: 'Selvhjelp, personlig utvikling og praktiske råd',
        originalLabel: 'Selvhjelp, personlig utvikling og praktiske råd',
        alternativeLabels: [],
        note: 'Bruk VS*-koder på bøker for allmennmarkedet. Foretrekk passende koder fra seksjonene C, J, K, L, Q, R etc. på bøker for høyere utdanning og profesjonsmarkedet',
        related: [],
        children: [
          {
            id: 'VSA',
            label: 'Hverdagstips og -triks',
            originalLabel: 'Hverdagstips og -triks',
            alternativeLabels: ['life hacks', 'kjerringråd'],
            note: 'Brukes for samlinger av nyttige og praktiske tips og knep som kan gjøre livet litt enklere. Brukes med andre emnekoder der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'VSB',
            label: 'Personlig økonomi',
            originalLabel: 'Personlig økonomi',
            alternativeLabels: [],
            note: 'Her: råd om det å forvalte pengene sine, inntekt, husholdningsbudsjett, familiens økonomi, økonomisk motstandskraft, lån, pensjon, investeringer, sparepenger, gjeld, skatt, tips for å spare eller andre aspekter ved personlig økonomi. Brukes med KF* for ulike sider ved regnskap og finans',
            related: [],
            children: [],
          },
          {
            id: 'VSC',
            label: 'Råd om karriere og oppnåelse av suksess',
            originalLabel: 'Råd om karriere og oppnåelse av suksess',
            alternativeLabels: ['jobbe hjemmefra'],
            note: 'Her: presentasjonsteknikk, hjemmekontor. Brukes for bøker som gir karriereråd og om hvordan du kan fungere best mulig på arbeidsplassen. Brukes med 4CP*-koder for forberedelse til tester, prøver eller eksamener, og med andre emnekoder for bransjespesifikke råd',
            related: [],
            children: [
              {
                id: 'VSCB',
                label: 'Jobbsøking / bytte karriere',
                originalLabel: 'Jobbsøking / bytte karriere',
                alternativeLabels: [
                  'oppsigelse',
                  'avskjedigelse',
                  'arbeidsløshet',
                ],
                note: 'Her: CV-er, følgebrev, intervjuteknikker, arbeidstakerrettigheter, håndtere det å miste jobben, jobbsøknader, personlighetstester. Brukes for bøker for allmennmarkedet som tar for seg teknikker rundt det å få en jobb',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'VSD',
            label:
              'Lover, statsborgerskap og rettigheter for den vanlige person',
            originalLabel:
              'Lover, statsborgerskap og rettigheter for den vanlige person',
            alternativeLabels: [],
            note: 'Her: råd om jus, lover, borgerplikter etc. Brukes med L* for bøker om jus for allmennmarkedet. NB: dersom VSD kombineres med L* må VSD angis som den viktigste koden og plasseres først',
            related: [],
            children: [],
          },
          {
            id: 'VSF',
            label: 'Kjørekunnskaper og kjøring',
            originalLabel: 'Kjørekunnskaper og kjøring',
            alternativeLabels: [],
            note: 'Her: førerkort, førerprøven, det å lære seg å kjøre. Brukes med WGC*-koder for å angi type kjøretøy der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'VSG',
            label: 'Forbrukerrådgivning',
            originalLabel: 'Forbrukerrådgivning',
            alternativeLabels: [],
            note: 'Se også WJS <a href="/thema/nb/WJS">Kjøpeguider</a>',
            related: [
              {
                id: 'WJS',
                label: 'Kjøpeguider',
                originalLabel: 'Kjøpeguider',
                note: 'Se også UDBA <a href="/thema/nb/UDBA">Handel og auksjoner på internett</a>, VSG <a href="/thema/nb/VSG">Forbrukerrådgivning</a>',
              },
            ],
            children: [],
          },
          {
            id: 'VSH',
            label:
              'Bolig og eiendom for enkeltindividet: kjøp/salg og juridiske spørsmål',
            originalLabel:
              'Bolig og eiendom for enkeltindividet: kjøp/salg og juridiske spørsmål',
            alternativeLabels: ['utleier'],
            note: 'Her: råd om å leie eller leie ut bolig, flytting, eiendomstyper. Se også WK* <a href="/thema/nb/WK">Hjem og vedlikehold av hus</a> og dens underkategorier',
            related: [
              {
                id: 'WK',
                label: 'Hjem og vedlikehold av hus',
                originalLabel: 'Hjem og vedlikehold av hus',
                note: 'Se også TTVT <a href="/thema/nb/TTVT">Vaktmestere, husholdersker, renholdsarbeidere etc.</a>, VSH <a href="/thema/nb/VSH">Bolig og eiendom for enkeltindividet: kjøp/salg og juridiske spørsmål</a>',
              },
            ],
            children: [],
          },
          {
            id: 'VSK',
            label: 'Yrkes- og utdanningsveiledning',
            originalLabel: 'Yrkes- og utdanningsveiledning',
            alternativeLabels: [],
            note: 'Se også JN* <a href="/thema/nb/JN">Pedagogikk</a> og dens underkategorier',
            related: [
              {
                id: 'JN',
                label: 'Pedagogikk',
                originalLabel: 'Pedagogikk',
                note: 'Her: utdanning. JN*-koder brukes for utgivelser om utdanningsfeltet som fag eller yrke, men bruk YP*-koder for læremidler for elever i grunn- og videregående skole',
              },
            ],
            children: [
              {
                id: 'VSKB',
                label: 'Studentliv',
                originalLabel: 'Studentliv',
                alternativeLabels: ['studieliv'],
                note: 'Her: praktiske guider om det å reise bort for å studere, livet på studiestedet, det å overleve som student. Se også VSW <a href="/thema/nb/VSW">Å bo og arbeide i utlandet</a>',
                related: [
                  {
                    id: 'VSW',
                    label: 'Å bo og arbeide i utlandet',
                    originalLabel: 'Å bo og arbeide i utlandet',
                    note: 'Her: praktiske råd om det å bo og arbeide i utlandet. Se også VSKB <a href="/thema/nb/VSKB">Studentliv</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'VSL',
            label: 'Lese- og skriveopplæring for voksne: guider og håndbøker',
            originalLabel:
              'Lese- og skriveopplæring for voksne: guider og håndbøker',
            alternativeLabels: [],
            note: 'Se også CFC <a href="/thema/nb/CFC">Lese- og skriveferdigheter</a>',
            related: [
              {
                id: 'CFC',
                label: 'Lese- og skriveferdigheter',
                originalLabel: 'Lese- og skriveferdigheter',
                note: 'Her: ferdigheter, teorier og emner knyttet til det å kunne lese og skrive, sosiale og kulturelle aspekter ved lesing og skriving. Se også JNTS <a href="/thema/nb/JNTS">Lese-, skrive- og regneopplæring</a>, VSL <a href="/thema/nb/VSL">Lese- og skriveopplæring for voksne: guider og håndbøker</a>',
              },
            ],
            children: [],
          },
          {
            id: 'VSN',
            label: 'Matematikk for voksne: guider og håndbøker',
            originalLabel: 'Matematikk for voksne: guider og håndbøker',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'VSP',
            label: 'Populærpsykologi',
            originalLabel: 'Populærpsykologi',
            alternativeLabels: [],
            note: 'Se også QDX <a href="/thema/nb/QDX">Populærfilosofi</a>',
            related: [
              {
                id: 'QDX',
                label: 'Populærfilosofi',
                originalLabel: 'Populærfilosofi',
                note: 'Brukes for faglitteratur for allmennmarkedet, verker som søker å bruke filosofiske prinsipper på hverdagslige situasjoner og problemer, eller bøker som forklarer filosofi for et bredt publikum. Brukes med QD* for bøker som har en populærvitenskapelig tilnærming til ulike filosofiske emner. Se også JBFV <a href="/thema/nb/JBFV">Etiske spørsmål</a>, VSP <a href="/thema/nb/VSP">Populærpsykologi</a>',
              },
            ],
            children: [
              {
                id: 'VSPD',
                label: 'Mindfulness',
                originalLabel: 'Mindfulness',
                alternativeLabels: [],
                note: 'Her: terapi basert på meditasjon og beslektede tilnærminger',
                related: [],
                children: [],
              },
              {
                id: 'VSPM',
                label:
                  'Selvutvikling, motivasjon, selvtillit og positiv mental innstilling',
                originalLabel:
                  'Selvutvikling, motivasjon, selvtillit og positiv mental innstilling',
                alternativeLabels: [],
                note: 'Her: lykke, velvære, positivt selvbilde, mental helse, balanse mellom arbeidsliv og fritid, positive tanker og hvordan være positivt innstilt til livet. Brukes for bøker som handler om psykologiske prinsipper, har motiverende eller inspirerende råd eller viser til sunn fornuft når det gjelder hvordan man kan bli lykkeligere, gjøre andre personer lykkelige',
                related: [],
                children: [],
              },
              {
                id: 'VSPP',
                label: 'Personlighetstrekk',
                originalLabel: 'Personlighetstrekk',
                alternativeLabels: [],
                note: 'Her: personlighetstester, ekstroverte, introverte, empatikere, høysensitive personer, høyt begavede personer etc. Brukes for bøker for allmennmarkedet som tar for seg ulike personlighetstrekk og -typer. Se også JMS <a href="/thema/nb/JMS">Psykologi: jeget, identitet, personlighet</a>',
                related: [
                  {
                    id: 'JMS',
                    label: 'Psykologi: jeget, identitet, personlighet',
                    originalLabel: 'Psykologi: jeget, identitet, personlighet',
                  },
                ],
                children: [],
              },
              {
                id: 'VSPQ',
                label: 'Følelser og emosjonell intelligens',
                originalLabel: 'Følelser og emosjonell intelligens',
                alternativeLabels: [],
                note: 'Her: bøker som handler om å oppfatte, forstå, bruke og takle menneskelige følelser, selvbevissthet når det gjelder egne og andres følelser',
                related: [],
                children: [],
              },
              {
                id: 'VSPT',
                label: 'Forbedring av hukommelsen og teknikker for tenkning',
                originalLabel:
                  'Forbedring av hukommelsen og teknikker for tenkning',
                alternativeLabels: [],
                note: 'Her: IQ-tester etc., for å teste selv, tankekart, logiske resonnementer, analytiske eller kreative teknikker for tenkning for voksne',
                related: [],
                children: [],
              },
              {
                id: 'VSPX',
                label: 'Nevrolingvistisk programmering (NLP)',
                originalLabel: 'Nevrolingvistisk programmering (NLP)',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'VSR',
            label: 'Pensjonisttilværelsen',
            originalLabel: 'Pensjonisttilværelsen',
            alternativeLabels: [],
            note: 'Her: råd om å forberede seg på å bli pensjonist, førtidspensjon, planlegge for økonomien etter pensjonering etc. Se også KFFP <a href="/thema/nb/KFFP">Pensjoner</a>',
            related: [
              {
                id: 'KFFP',
                label: 'Pensjoner',
                originalLabel: 'Pensjoner',
                note: 'Se også VSR <a href="/thema/nb/VSR">Pensjonisttilværelsen</a>',
              },
            ],
            children: [],
          },
          {
            id: 'VSS',
            label: 'Sosiale ferdigheter og omgang med andre mennesker',
            originalLabel: 'Sosiale ferdigheter og omgang med andre mennesker',
            alternativeLabels: [],
            note: 'Her: råd om å håndtere sosiale situasjoner',
            related: [],
            children: [],
          },
          {
            id: 'VSW',
            label: 'Å bo og arbeide i utlandet',
            originalLabel: 'Å bo og arbeide i utlandet',
            alternativeLabels: [],
            note: 'Her: praktiske råd om det å bo og arbeide i utlandet. Se også VSKB <a href="/thema/nb/VSKB">Studentliv</a>',
            related: [
              {
                id: 'VSKB',
                label: 'Studentliv',
                originalLabel: 'Studentliv',
                note: 'Her: praktiske guider om det å reise bort for å studere, livet på studiestedet, det å overleve som student. Se også VSW <a href="/thema/nb/VSW">Å bo og arbeide i utlandet</a>',
              },
            ],
            children: [],
          },
          {
            id: 'VSY',
            label: 'Egenberedskap / Å forberede seg på nødsituasjoner',
            originalLabel: 'Egenberedskap / Å forberede seg på nødsituasjoner',
            alternativeLabels: ['prepping', 'survivalisme'],
            note: 'Her: katastrofehåndbøker. Brukes for praktiske guider for den som vil forberede seg på kriser, naturkatastrofer og andre nødsituasjoner',
            related: [],
            children: [],
          },
          {
            id: 'VSZ',
            label: 'Selvforsyning og grønn livsstil',
            originalLabel: 'Selvforsyning og grønn livsstil',
            alternativeLabels: [],
            note: 'Her: livsstiler som inkluderer det å leve etisk, med lave karbonutslipp, uten plast, enkle og bærekraftige livsstiler, hvordan leve med klimaendringer etc. Brukes for bøker som inneholder praktiske tips og råd, eller forslag til hvordan man kan ha en bærekraftig livsstil, hvordan individer kan redusere sitt karbonavtrykk eller sin påvirkning på miljøet, guider til hvordan man kan gjenbruke og resirkulere og redusere forbruket sitt. Se også RNU <a href="/thema/nb/RNU">Bærekraft</a>',
            related: [
              {
                id: 'RNU',
                label: 'Bærekraft',
                originalLabel: 'Bærekraft',
                note: 'Brukes med andre emnekoder for bærekraft innen spesifikke områder. Se også TBR <a href="/thema/nb/TBR">Tilpasset teknologi</a>, THV <a href="/thema/nb/THV">Alternative og fornybare energikilder og teknologier</a>, VSZ <a href="/thema/nb/VSZ">Selvforsyning og grønn livsstil</a>',
              },
            ],
            children: [
              {
                id: 'VSZD',
                label: 'Vedlikehold, gjenbruk og reparasjon av gjenstander',
                originalLabel:
                  'Vedlikehold, gjenbruk og reparasjon av gjenstander',
                alternativeLabels: [],
                note: 'Her: oppsirkulering, bøker om å reparere ting, særlig gjenstander som er i daglig bruk, finne nye bruksområder eller forlenge levetiden for gjenstander, guider til gjenbruk',
                related: [],
                children: [],
              },
              {
                id: 'VSZM',
                label: 'Minimalistisk / enkel livsstil',
                originalLabel: 'Minimalistisk / enkel livsstil',
                alternativeLabels: [],
                note: 'Her: minimalisme som livsstil, ikke som kunstretning. Brukes for bøker som handler om eller gir råd om hvordan man kan leve med færre eiendeler, redusere hvor mye man kjøper og eier, leve et mindre materialistisk liv, sette pris på enkelhet, en livsstil som er mindre frenetisk, å leve et idealisert liv på landet',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'VX',
        label: 'Sinn, kropp, sjel',
        originalLabel: 'Sinn, kropp, sjel',
        alternativeLabels: [],
        note: 'Bruk VX*-koder bare på bøker for allmennmarkedet. Foretrekk passende koder fra seksjonene J, M, Q, etc. på bøker for høyere utdanning og profesjonsmarkedet',
        related: [],
        children: [
          {
            id: 'VXA',
            label: 'Sinn, kropp, sjel: tanker og praksis',
            originalLabel: 'Sinn, kropp, sjel: tanker og praksis',
            alternativeLabels: [],
            note: 'Brukes for bøker om det spirituelle, individers fysiske eller mentale utvikling; bøker om inspirerende sider ved livet, selvhjelp eller selvoppdagelse, spirituell og personlig utvikling eller forbedring, hvordan man kan bruke spirituelle eller metafysiske teknikker for å forbedre hverdagslivet, personlig helse og velvære',
            related: [],
            children: [],
          },
          {
            id: 'VXF',
            label: 'Spådomskunst og klarsynthet',
            originalLabel: 'Spådomskunst og klarsynthet',
            alternativeLabels: ['spådom', 'spå'],
            note: 'Her: runespådommer, geomanti, tradisjonelle eller lokale spådomsmetoder',
            related: [],
            children: [
              {
                id: 'VXFA',
                label: 'Astrologi',
                originalLabel: 'Astrologi',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'VXFA1',
                    label: 'Stjernetegn og horoskop',
                    originalLabel: 'Stjernetegn og horoskop',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'VXFC',
                label: 'Spå i kort (kartomanti)',
                originalLabel: 'Spå i kort (kartomanti)',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'VXFC1',
                    label: 'Tarot',
                    originalLabel: 'Tarot',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'VXFD',
                label: 'Yijing',
                originalLabel: 'Yijing',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'VXFG',
                label: 'Grafologi',
                originalLabel: 'Grafologi',
                alternativeLabels: [],
                note: 'Her: håndskriftsanalyse',
                related: [],
                children: [],
              },
              {
                id: 'VXFJ',
                label: 'Spådomskunst via fysiske egenskaper',
                originalLabel: 'Spådomskunst via fysiske egenskaper',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'VXFJ1',
                    label: 'Kiromanti',
                    originalLabel: 'Kiromanti',
                    alternativeLabels: [],
                    note: 'Her: å spå i hånd',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VXFJ2',
                    label: 'Frenologi og fysiognomikk',
                    originalLabel: 'Frenologi og fysiognomikk',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'VXFN',
                label: 'Numerologi',
                originalLabel: 'Numerologi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'VXFT',
                label: 'Klarsynthet og prekognisjon',
                originalLabel: 'Klarsynthet og prekognisjon',
                alternativeLabels: [],
                note: 'Her: fremsynthet, synskhet',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'VXH',
            label: 'Alternative behandlingsmåter, healing og helse',
            originalLabel: 'Alternative behandlingsmåter, healing og helse',
            alternativeLabels: [
              'Alexanderteknikk',
              'alternativ medisin',
              'hypnoterapi',
              'hypnose',
            ],
            note: 'Her: kopping, lymfeterapi og andre alternative behandlingsmåter. Brukes for behandlingsmåter og behandlere, inkludert healere, naturmedisinere. Bruk VXH*-koder med MX dersom innholdet er beregnet på medisinsk fagpersonell, studenter, behandlere. Se også MX* <a href="/thema/nb/MX">Alternativ medisin / terapi</a> og dens underkategorier',
            related: [
              {
                id: 'MX',
                label: 'Alternativ medisin / terapi',
                originalLabel: 'Alternativ medisin / terapi',
                note: 'Bruk MX*-koder for bøker om alternativ eller tradisjonell medisin for medisinstudenter og helsepersonell, som bøker om tradisjonell kinesisk og ayurvedisk medisin for profesjonsmarkedet, naturmedisin, fytoterapi, akupunktur, moxibusjon. Brukes med VXH* koder som sekundærkoder for å angi områder innen alternativ medisin der det er hensiktsmessig dersom innholdet er ment for profesjonsmarkedet eller studenter. Se også VXH* <a href="/thema/nb/VXH">Alternative behandlingsmåter, healing og helse</a> og dens underkategorier',
              },
            ],
            children: [
              {
                id: 'VXHA',
                label: 'Akupunktur',
                originalLabel: 'Akupunktur',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'VXHC',
                label: 'Aromaterapi og eteriske oljer',
                originalLabel: 'Aromaterapi og eteriske oljer',
                alternativeLabels: [],
                note: 'Brukes med MX dersom innholdet er beregnet på medisinsk fagpersonell, studenter eller aromaterapeuter',
                related: [],
                children: [],
              },
              {
                id: 'VXHF',
                label: 'Naturterapi',
                originalLabel: 'Naturterapi',
                alternativeLabels: ['shinrin-yoku', 'forest bathing'],
                note: 'Her: økoterapi, hageterapi, skogsterapi, jording. Brukes for populærvitenskapelig tilnærming til emnet, for akademiske bøker, se JM* Psykologi-koder. Se også MQT <a href="/thema/nb/MQT">Ergoterapi</a>',
                related: [
                  {
                    id: 'MQT',
                    label: 'Ergoterapi',
                    originalLabel: 'Ergoterapi',
                    note: 'Her: hageterapi, terapeutisk hagebruk, naturassistert terapi',
                  },
                ],
                children: [],
              },
              {
                id: 'VXHH',
                label: 'Homeopati',
                originalLabel: 'Homeopati',
                alternativeLabels: [],
                note: 'Her: homeopatisk medisin. Brukes for bøker som er beregnet på homeopater eller healere. Brukes med MX dersom innholdet er beregnet på medisinsk fagpersonell, studenter eller behandlere',
                related: [],
                children: [],
              },
              {
                id: 'VXHJ',
                label: 'Refleksologi',
                originalLabel: 'Refleksologi',
                alternativeLabels: [],
                note: 'Se også VFMS <a href="/thema/nb/VFMS">Massasje</a>',
                related: [
                  {
                    id: 'VFMS',
                    label: 'Massasje',
                    originalLabel: 'Massasje',
                    note: 'Her: massasjeterapi og massasjeteknikker. Se også VXHJ <a href="/thema/nb/VXHJ">Refleksologi</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'VXHK',
                label: 'Reiki',
                originalLabel: 'Reiki',
                alternativeLabels: [],
                note: 'Her: magnetterapi, lysterapi, lysakupunktur, terapeutisk berøring, healing på avstand, spirituell healing, kromoterapi. Se også VXPC <a href="/thema/nb/VXPC">Krystaller og fargehealing</a>',
                related: [
                  {
                    id: 'VXPC',
                    label: 'Krystaller og fargehealing',
                    originalLabel: 'Krystaller og fargehealing',
                    note: 'Her: fødselssteiner, fargeterapi, kromoterapi',
                  },
                ],
                children: [],
              },
              {
                id: 'VXHN',
                label: 'Naturopati',
                originalLabel: 'Naturopati',
                alternativeLabels: [],
                note: 'Brukes med MX dersom innholdet er beregnet på medisinsk fagpersonell, studenter, behandlere',
                related: [],
                children: [],
              },
              {
                id: 'VXHP',
                label: 'Hypnoterapi',
                originalLabel: 'Hypnoterapi',
                alternativeLabels: [],
                note: 'Brukes for bøker om hypnose brukt i behandling av medisinske eller psykologiske tilstander',
                related: [],
                children: [],
              },
              {
                id: 'VXHT',
                label: 'Tradisjonell medisin og naturmedisin',
                originalLabel: 'Tradisjonell medisin og naturmedisin',
                alternativeLabels: [],
                note: 'Her: bøker om tradisjonell medisin, folkemedisin, tradisjonell medisinsk kunnskap og praksis, som tradisjonell kinesisk eller afrikansk medisin, kampo etc. Brukes med kvalifikatorer for sted for spesifikke regionale medisinske tradisjoner. Brukes med MX dersom innholdet er beregnet på medisinsk fagpersonell, studenter, behandlere',
                related: [],
                children: [
                  {
                    id: 'VXHT2',
                    label: 'Ayurveda',
                    originalLabel: 'Ayurveda',
                    alternativeLabels: [],
                    note: 'Her: ayurvedaterapi',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VXHT4',
                    label: 'Urtemedisin / plantelegemidler',
                    originalLabel: 'Urtemedisin / plantelegemidler',
                    alternativeLabels: ['tinktur', 'CBD'],
                    note: 'Her: plantebaserte legemidler, fytoterapi, urtete brukt for å få bedre helse eller som legemiddel, kombucha, urteuttrekk, medisinske planter, medisinsk cannabis, cannabidiol, tradisjonell herbologi',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'VXHV',
                label: 'Lydterapi / ASMR',
                originalLabel: 'Lydterapi / ASMR',
                alternativeLabels: ['Autonom sensorisk meridianrespons'],
                note: 'Brukes for bøker som handler om lydbasert terapi og -teknikker, eller bøker som inneholder lydeffekter som skal brukes for å redusere stress og angst, virke beroligende, føre til at man sover bedre eller generelt føler seg bedre, avslapping, meditasjon etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'VXK',
            label: 'Jordenergi',
            originalLabel: 'Jordenergi',
            alternativeLabels: ['atlantis', 'bermudatriangelet', 'leylinjer'],
            note: 'Her: ønskekvist, energilinjer, kontroversiell viten',
            related: [],
            children: [],
          },
          {
            id: 'VXM',
            label: 'Sinn, kropp, sjel: meditasjon og visualisering',
            originalLabel: 'Sinn, kropp, sjel: meditasjon og visualisering',
            alternativeLabels: ['avslappingsteknikker'],
            note: 'Her: yoga som meditasjon',
            related: [],
            children: [],
          },
          {
            id: 'VXN',
            label: 'Drømmer og drømmetydning',
            originalLabel: 'Drømmer og drømmetydning',
            alternativeLabels: [],
            note: 'Se også JMT <a href="/thema/nb/JMT">Psykologi: tilstander av bevissthet</a>',
            related: [
              {
                id: 'JMT',
                label: 'Psykologi: tilstander av bevissthet',
                originalLabel: 'Psykologi: tilstander av bevissthet',
                note: 'Her: psykologiske verk om bevissthet og ubevissthet; søvn og drømmer; endrede sinnstilstander, hypnose, tilstander fremkalt av legemiddel- og narkotika, etc. Se også VXN <a href="/thema/nb/VXN">Drømmer og drømmetydning</a>',
              },
            ],
            children: [],
          },
          {
            id: 'VXP',
            label: 'Psykiske evner og psykiske fenomener',
            originalLabel: 'Psykiske evner og psykiske fenomener',
            alternativeLabels: [],
            note: 'Her: ekstrasensorisk persepsjon, telepati, telekinese etc. Se også JMX <a href="/thema/nb/JMX">Parapsykologiske studier</a>',
            related: [
              {
                id: 'JMX',
                label: 'Parapsykologiske studier',
                originalLabel: 'Parapsykologiske studier',
                note: 'Her: parapsykologi. Brukes for profesjonelle, akademiske verk. Foretrekk VX*-koder for populærvitenskapelige bøker rettet mot allmennmarkedet. Se også VXP <a href="/thema/nb/VXP">Psykiske evner og psykiske fenomener</a>',
              },
            ],
            children: [
              {
                id: 'VXPC',
                label: 'Krystaller og fargehealing',
                originalLabel: 'Krystaller og fargehealing',
                alternativeLabels: [],
                note: 'Her: fødselssteiner, fargeterapi, kromoterapi',
                related: [],
                children: [],
              },
              {
                id: 'VXPH',
                label: 'Chakraene, auraer og spirituell energi',
                originalLabel: 'Chakraene, auraer og spirituell energi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'VXPJ',
                label: 'Astralprojeksjon og ut av kroppen-opplevelser',
                originalLabel: 'Astralprojeksjon og ut av kroppen-opplevelser',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'VXPR',
                label: 'Livet etter døden, reinkarnasjon og tidligere liv',
                originalLabel:
                  'Livet etter døden, reinkarnasjon og tidligere liv',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'VXPS',
                label: 'Spirituelle veiledere, engler og kanalisering',
                originalLabel: 'Spirituelle veiledere, engler og kanalisering',
                alternativeLabels: ['medium', 'åndemedier'],
                note: 'Her: himmelske eller overnaturlige budbringere, skytsengler, engleterapi, medier. Brukes med F*, XQ*, JBGB eller QR* der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'VXQ',
            label: 'Oversanselige fenomener / det paranormale',
            originalLabel: 'Oversanselige fenomener / det paranormale',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'VXQB',
                label: 'UFO-er og utenomjordiske vesener',
                originalLabel: 'UFO-er og utenomjordiske vesener',
                alternativeLabels: ['romvesen'],

                related: [],
                children: [],
              },
              {
                id: 'VXQG',
                label: 'Spøkelser og poltergeister',
                originalLabel: 'Spøkelser og poltergeister',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'VXQM',
                label: 'Mytiske / overnaturlige vesen',
                originalLabel: 'Mytiske / overnaturlige vesen',
                alternativeLabels: [
                  'demoner',
                  'kentaurer',
                  'den avskyelige snømannen',
                  'overnaturlig',
                ],
                note: 'Her: skapninger fra myter og legender: naturånder, humanoider, monstre, kjemper, troll, golem, griffer, havfruer, alver, feer, enhjørninger, kentaurer, fauner, yetier, sjømonstre etc. Brukes for bøker for allmennmarkedet som handler om disse vesenene. Bruk VXQM* med F*-koder eller XQ* for fortellinger som handler om et eller flere av disse vesenene. Foretrekk JBGB eller QR*-koder for bøker for høyere utdanning eller profesjonsmarkedet. Se også JBGB <a href="/thema/nb/JBGB">Folklore, myter og legender</a>',
                related: [
                  {
                    id: 'JBGB',
                    label: 'Folklore, myter og legender',
                    originalLabel: 'Folklore, myter og legender',
                    note: 'Brukes for faglitteratur som tar for seg tradisjonelle fortellinger eller muntlig overbrakt folklore; folkloristikk, studier av folkereligioner og tradisjoner, studier av urbane og nyere myter og legender, myters opprinnelse, eventyr, løgnhistorier, overnaturlige fortellinger, overnaturlige vesener, overtro etc., utgivelser som tar for seg opprinnelsen til fortellinger om Atlantis, den hellige gral, Bermudatriangelet etc. Foretrekk QR*-koder for spirituelle eller religiøse aspekter ved mytologi eller VX*-koder for populærvitenskapelig tilnærming eller FN*-koder for selve historiene. Se også FN <a href="/thema/nb/FN">Tradisjonelle fortellinger, myter og eventyr</a>, QRS* <a href="/thema/nb/QRS">Oldtidens religioner og mytologier</a> og dens underkategorier, VXQM* <a href="/thema/nb/VXQM">Mytiske / overnaturlige vesen</a> og dens underkategorier',
                  },
                ],
                children: [
                  {
                    id: 'VXQM1',
                    label: 'Drager',
                    originalLabel: 'Mytiske vesen: drager',
                    alternativeLabels: ['midgardsormen'],
                    note: 'Brukes med F*-, XQ*-, JBGB eller QR*-koder der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VXQM2',
                    label: 'Vampyrer, varulver og andre formskiftere',
                    originalLabel:
                      'Mytiske vesen: vampyrer, varulver og andre formskiftere',
                    alternativeLabels: ['hamskiftere'],
                    note: 'Brukes med F*-, XQ*-, JBGB eller QR*-koder der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VXQM3',
                    label: 'Zombier og udøde',
                    originalLabel: 'Mytiske vesen: zombier og udøde',
                    alternativeLabels: ['mumier', 'skjeletter'],
                    note: 'Brukes med F*-, XQ*-, JBGB eller QR*-koder der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VXQM4',
                    label: 'Feer og alver',
                    originalLabel: 'Mytiske vesen: feer og alver',
                    alternativeLabels: [
                      'byttinger',
                      'leprechauns',
                      'underjordiske',
                      'nymfer',
                    ],
                    note: 'Brukes med F*-, XQ*-, JBGB eller QR*-koder der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VXQM5',
                    label: 'Demoner',
                    originalLabel: 'Mytiske vesen: demoner',
                    alternativeLabels: [],
                    note: 'Her: demoner, daimoner og lignende vesener, vennlige eller ondsinnede ånder. Brukes med F*-, XQ*-, JBGB eller QR*-koder der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'VXQM6',
                    label: 'Havfruer, havmenn og andre vannånder',
                    originalLabel:
                      'Mytiske vesen: havfruer, havmenn og andre vannånder',
                    alternativeLabels: ['kelpier', 'hamskiftere', 'havmann'],
                    note: 'Her: havfolk, sirener, selkier, humanoider etc. Brukes med F*-, XQ*-, JBGB eller QR*-koder der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'VXV',
            label:
              'Feng Shui og andre tilnærminger til boligdesign og byggestil',
            originalLabel:
              'Feng Shui og andre tilnærminger til boligdesign og byggestil',
            alternativeLabels: [],
            note: 'Her: Vastu Shastra',
            related: [],
            children: [],
          },
          {
            id: 'VXW',
            label: 'Mystikk, magi og okkulte interesser',
            originalLabel: 'Mystikk, magi og okkulte interesser',
            alternativeLabels: [],
            note: 'Bruk VXW*-koder på bøker for allmennmarkedet. Foretrekk QRY*-koder på bøker for høyere utdanning og profesjonsmarkedet. Se også QRYX* <a href="/thema/nb/QRYX">Okkulte studier</a> og dens underkategorier',
            related: [
              {
                id: 'QRYX',
                label: 'Okkulte studier',
                originalLabel: 'Okkulte studier',
                note: 'Brukes bare på seriøse og akademiske verk. Se også VXW* <a href="/thema/nb/VXW">Mystikk, magi og okkulte interesser</a> og dens underkategorier',
              },
            ],
            children: [
              {
                id: 'VXWK',
                label: 'Kabbalah: populære verker',
                originalLabel: 'Kabbalah: populære verker',
                alternativeLabels: [],
                note: 'Se også QRVK2 <a href="/thema/nb/QRVK2">Mystisisme</a>',
                related: [
                  {
                    id: 'QRVK2',
                    label: 'Mystisisme',
                    originalLabel: 'Mystisisme',
                    note: 'Her: opplevelser av visjonær karakter. Brukes med andre koder fra QR* for tradisjoner innen mystisisme og ulike religiøse skikker',
                  },
                ],
                children: [],
              },
              {
                id: 'VXWM',
                label: 'Magi, trolldom og alkymi',
                originalLabel: 'Magi, trolldom og alkymi',
                alternativeLabels: [],
                note: 'Her: utøvere av magi og trylleformler som hekser, trollmenn, magikere etc. Foretrekk VXMT for moderne heksekunst og dens utøvere',
                related: [],
                children: [],
              },
              {
                id: 'VXWS',
                label: 'Sjamanisme, paganisme og druidenes lære',
                originalLabel: 'Sjamanisme, paganisme og druidenes lære',
                alternativeLabels: [],
                note: 'Her: hedenskap, nyhedendom, gudinnetilbedelse. Se også QRS <a href="/thema/nb/QRS">Oldtidens religioner og mytologier</a>',
                related: [
                  {
                    id: 'QRS',
                    label: 'Oldtidens religioner og mytologier',
                    originalLabel: 'Oldtidens religioner og mytologier',
                    note: 'Her: paganisme, komparativ mytologi, generelle studier av mytologi og studier av den enkelte, regionale mytologi hvor denne ikke har sin egen kode. Bruk QRS*-koder for bøker som tar for seg disse formene for spirituell tro, deres mytologier, gudshus, religionsutøvelse, guder og ånder, skapelsesberetninger og andre fortellinger, legender, ritualer etc. Bruk QRS*-koder med kvalifikatorer for sted, spesielt 1QB* for historiske land og riker, eller med andre OR*-koder for sammenligning med nyere religioner. Brukes med FN*-koder for moderne gjenfortellinger av tradisjonelle myter fra disse religionene. Se også FN <a href="/thema/nb/FN">Tradisjonelle fortellinger, myter og eventyr</a>, JBGB <a href="/thema/nb/JBGB">Folklore, myter og legender</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'VXWT',
                label: 'Heksekunst og wicca',
                originalLabel: 'Heksekunst og wicca',
                alternativeLabels: [],
                note: 'Her: moderne eller hedensk heksekunst. Se også QRYX5 <a href="/thema/nb/QRYX5">Heksekunst</a>',
                related: [
                  {
                    id: 'QRYX5',
                    label: 'Heksekunst',
                    originalLabel: 'Heksekunst',
                    note: 'Brukes for studier både av heksekunst i nåtiden og i et historisk perspektiv, de som praktiserer/praktiserte heksekunst, holdninger til hekser og heksekunst etc. Brukes med kvalifikatorer for sted og tid der det er hensiktsmessig, og med andre emnekoder, f.eks. NHTX for hekseprosesser. Foretrekk VXWT for bøker med populærvitenskapelig tilnærming til emnet. Se også VXWT <a href="/thema/nb/VXWT">Heksekunst og wicca</a>',
                  },
                ],
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'W',
    label: 'Livsstil, hobby og fritid',
    originalLabel: 'Livsstil, hobby og fritid',
    alternativeLabels: [],
    note: 'Bruk koder fra W på bøker for allmennmarkedet. IKKE bruk koden ‘W’ alene, men velg spesifikke kategorier fra W*. Bruk W*-koder med andre emnekategorier og kvalifikatorer der det er hensiktsmessig. Foretrekk passende koder fra seksjonene A, K, P, R, T, etc. på bøker for høyere utdanning og profesjonsmarkedet',
    related: [],
    children: [
      {
        id: 'WB',
        label: 'Mat og drikke',
        originalLabel: 'Mat og drikke',
        alternativeLabels: ['kokebøker', 'kokebok', 'kokeboken'],
        note: 'Her: gastronomi, generelle utgivelser om mat, matlaging og drikke, journalistikk, kritikk, essay om mat og drikke. Brukes for generelle tekster om mat, matkultur, spising, måltider etc., og som ikke er oppskrifter eller kokebøker. Brukes med DNC for memoarer, med DNP for matjournalistikk, med WTL for reiselitteratur som handler om mat, med WTHR for restaurantanmeldelser. Bruk WB*-koder med NHTB for mat, matlaging og drikke i et historisk perspektiv, eller med JBCC4 for kulturstudier innen emnet. Se også JBCC4 <a href="/thema/nb/JBCC4">Kulturstudier: mat og samfunn</a>, TTVC2 <a href="/thema/nb/TTVC2">Catering og matlaging: ferdigheter og bransjer</a>',
        related: [
          {
            id: 'JBCC4',
            label: 'Mat og samfunn',
            originalLabel: 'Kulturstudier: mat og samfunn',
            note: 'Brukes for bøker som tar for seg tradisjoner, skikker, ritualer, seremonier, tabuer knyttet til mat og drikke i ulike kulturer, og mat og drikke som immateriell kulturarv. Brukes med NHTB for historisk synsvinkel og med WB*-koder der det er hensiktsmessig. Se også WB <a href="/thema/nb/WB">Mat og drikke</a>',
          },
          {
            id: 'TTVC2',
            label: 'Catering og matlaging: ferdigheter og bransjer',
            originalLabel: 'Catering og matlaging: ferdigheter og bransjer',
            note: 'Brukes for bøker for profesjonsmarkedet',
          },
        ],
        children: [
          {
            id: 'WBA',
            label: 'Kokebøker og oppskrifter',
            originalLabel: 'Kokebøker og oppskrifter',
            alternativeLabels: [],
            note: 'Her: matlaging og oppskrifter',
            related: [],
            children: [
              {
                id: 'WBAC',
                label: 'Trøstemat og matnostalgi',
                originalLabel: 'Trøstemat og matnostalgi',
                alternativeLabels: ['kosemat'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WBB',
            label: 'Kokebøker av kjente personer',
            originalLabel: 'Kokebøker av kjente personer',
            alternativeLabels: [
              'kafeer',
              'puber',
              'barer',
              'bistro',
              'kjendiser',
            ],
            note: 'Her: kokebøker basert på TV-serier, sosiale medier, oppskrifter fra kjente personer, restauranter, spisesteder etc., eller andre kjente, navngitte steder for mat serveres til kunder',
            related: [],
            children: [],
          },
          {
            id: 'WBC',
            label: 'Kokebøker for én',
            originalLabel: 'Kokebøker for én',
            alternativeLabels: ['matlaging'],

            related: [],
            children: [],
          },
          {
            id: 'WBD',
            label: 'Kokebøker: matlaging på budsjett',
            originalLabel: 'Kokebøker: matlaging på budsjett',
            alternativeLabels: [],
            note: 'Her: kokebøker for studenter, bøker som har menyforslag og oppskrifter som ikke koster så mye',
            related: [],
            children: [],
          },
          {
            id: 'WBF',
            label: 'Kokebøker for rask og enkel matlaging',
            originalLabel: 'Kokebøker for rask og enkel matlaging',
            alternativeLabels: ['one-pot', 'one pot'],
            note: 'Her: onepot. Brukes med andre koder fra WB* for å angi type mat',
            related: [],
            children: [],
          },
          {
            id: 'WBH',
            label: 'Kokebøker: diett og helsekost',
            originalLabel: 'Kokebøker: diett og helsekost',
            alternativeLabels: ['matlaging'],
            note: 'Her: oppskrifter på sunn mat. Brukes for bøker som har menyforslag og oppskrifter på mat som er ment å vedlikeholde og forbedre god helse. Brukes med SCG eller andre koder fra S* for dietter og matlaging for idrettsutøvere',
            related: [],
            children: [
              {
                id: 'WBHS',
                label: 'Kokebøker: spesielle dietter og helsetilstander',
                originalLabel:
                  'Kokebøker: spesielle dietter og helsetilstander',
                alternativeLabels: ['matlaging'],
                note: 'Her: laktosefri, saltredusert etc. Brukes for bøker om mat eller med oppskrifter på mat knyttet til spesifikke helsetilstander, kosthold eller dietter. Bruk WBHS* med VFMD for vektkontroll, VFJB4 for oppskrifter for et sunt hjerte, VFJB1 for oppskrifter for allergikere, VFJB3 for kreft, VFVC for oppskrifter knyttet til seksuell helse, S* for dietter knyttet til sport og trening og andre VF*- eller M*-koder der det er hensiktsmessig',
                related: [],
                children: [
                  {
                    id: 'WBHS1',
                    label:
                      'Kokebøker: fettredusert diett og kosthold for lavt kolesterol',
                    originalLabel:
                      'Kokebøker: fettredusert diett og kosthold for lavt kolesterol',
                    alternativeLabels: ['kosthold'],
                    note: 'Her: bøker med forslag til menyer, oppskrifter og mat som har fokus på å redusere fettmengden og kolesterolet i kostholdet',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'WBHS2',
                    label: 'Kokebøker: glutenfritt kosthold',
                    originalLabel: 'Kokebøker: glutenfritt kosthold',
                    alternativeLabels: ['glutenfri', 'cøliaki', 'kosthold'],
                    note: 'Her: bøker for personer med cøliaki eller andre sykdommer og tilstander relatert til hvete, bygg, rug og gluten',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'WBHS3',
                    label: 'Kokebøker: lavkarbo',
                    originalLabel: 'Kokebøker: lavkarbo',
                    alternativeLabels: ['kosthold', 'keto'],
                    note: 'Her: bøker med forslag til menyer, oppskrifter og mat som har fokus på å redusere mengden karbohydrater i kostholdet',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'WBHS4',
                    label: 'Kokebøker: kosthold med høyt proteininnhold',
                    originalLabel:
                      'Kokebøker: kosthold med høyt proteininnhold',
                    alternativeLabels: ['kosthold'],
                    note: 'Her: bøker som har forslag til menyer, oppskrifter og mat som har fokus på at høyt, daglig inntak av proteiner. Brukes med SXB for proteindietter i tilknytning til kroppsbygging',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'WBHS5',
                    label: 'Kokebøker: kosthold uten sukker',
                    originalLabel: 'Kokebøker: kosthold uten sukker',
                    alternativeLabels: ['sukkerfritt kosthold', 'sukkerfri'],
                    note: 'Her: bøker som har forslag til menyer, oppskrifter og mat som har fokus på å redusere mengden sukker i kostholdet',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'WBHS6',
                    label: 'Kokebøker: kalorier',
                    originalLabel: 'Kokebøker: kalorier',
                    alternativeLabels: [],
                    note: 'Her: bøker som har forslag til menyer, oppskrifter og mat som har fokus på hvor mange kalorier man inntar eller som har oppskrifter som angir antall kalorier pr porsjon',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'WBJ',
            label: 'Vegetariske kokebøker og vegetarianisme',
            originalLabel: 'Vegetariske kokebøker og vegetarianisme',
            alternativeLabels: ['kjøttfri', 'plantebasert', 'fleksitarianer'],
            note: 'Brukes for bøker med oppskrifter eller som handler om ernærings-, helse- og etiske aspekter ved et kjøttfritt kosthold',
            related: [],
            children: [
              {
                id: 'WBJK',
                label: 'Veganske kokebøker og veganisme',
                originalLabel: 'Veganske kokebøker og veganisme',
                alternativeLabels: ['kjøttfri', 'plantebasert'],
                note: 'Brukes for bøker med oppskrifter eller som handler om ernærings-, helse- og etiske aspekter ved et kjøttfritt kosthold',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WBK',
            label: 'Kokebøker: økologisk mat og matlaging',
            originalLabel: 'Kokebøker: økologisk mat og matlaging',
            alternativeLabels: [],
            note: 'Brukes for bøker som har forslag til menyer, oppskrifter og mat som inneholder råvarer som er dyrket uten bruk av kunstgjødsel, plantevernmidler, eller hvor man ikke bruker tilsetningsstoffer i husdyrfôr etc.',
            related: [],
            children: [],
          },
          {
            id: 'WBL',
            label: 'Kokebøker: bærekraftig mat og matlaging',
            originalLabel: 'Kokebøker: bærekraftig mat og matlaging',
            alternativeLabels: [],
            note: 'Brukes for bøker som har forslag til oppskrifter eller mat som bygger på ei bærekraftig tilnærming til spising, forslag til måltider og menyer for å redusere karbonavtrykket, forslag til hvordan man kan bruke rester, hvordan unngå matsvinn, forslag til hvilke råvarer som hører til den enkelte sesong, kortreist mat etc.',
            related: [],
            children: [],
          },
          {
            id: 'WBN',
            label: 'Nasjonale og regionale kokebøker',
            originalLabel: 'Nasjonale og regionale kokebøker',
            alternativeLabels: [],
            note: 'Her: oppskrifter og matlaging fra spesifikke kulturelle tradisjoner. Tildel også kvalifikator for geografi eller interesse der det er hensiktsmessig. F.eks. med 5PG* for dietter knyttet til ei trosretning eller 5PB* for matlaging knyttet til spesifikke folkegrupper, eller med WTL for reiseskildringer som handler om mat og drikke',
            related: [],
            children: [
              {
                id: 'WBNB',
                label: 'Kokebøker: street food',
                originalLabel: 'Kokebøker: street food',
                alternativeLabels: ['food truck', 'mathaller', 'gatemat'],
                note: 'Her: bøker med forslag til menyer, oppskrifter og mat som forbindes med eller er inspirert av gatemat, som selges fra en foodtruck eller boder, på matmarkeder eller i mathaller. Vanligvis matretter eller drikke som er klare til å spises som de er, ofte fingermat (dvs. mat som du kan holde i hånda mens du spiser). Brukes med kvalifikatorer for sted, f.eks. for ei bok om street food i Mexico City, bruk med 1KLCM-MX-FA',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WBQ',
            label: 'Kokebøker med og for barn / ungdom',
            originalLabel: 'Kokebøker med og for barn / ungdom',
            alternativeLabels: [],
            note: 'Her: barnemat. Brukes for bøker om å lage mat til babyer eller småbarn, eller forslag til menyer og oppskrifter på mat og måltider spesielt for barn, eller bøker som har oppskrifter som barn og voksne kan lage sammen. Brukes med VFXB for bøker som handler om mat for babyer, og med 5LB, 5LC, 5LD der det er hensiktsmessig. Foretrekk YNPC for kokebøker for barn. Se også YNPC <a href="/thema/nb/YNPC">Barn og ungdom: matlaging og mat</a>',
            related: [
              {
                id: 'YNPC',
                label: 'Matlaging og mat',
                originalLabel: 'Barn og ungdom: matlaging og mat',
                note: 'Her: kokebøker og oppskrifter, bøker om ernæring, frukt og grønnsaker',
              },
            ],
            children: [],
          },
          {
            id: 'WBR',
            label: 'Kokebøker for selskap og spesielle anledninger',
            originalLabel: 'Kokebøker for selskap og spesielle anledninger',
            alternativeLabels: ['selskapsmat'],
            note: 'Her: bøker med forslag til menyer og oppskrifter som forbindes med spesielle helligdager, som er knyttet til årstider, festmat etc. Brukes med kvalifikatorer for interesse fra 5H* der det er hensiktsmessig. F.eks. med 5HKA for bursdagsselskap, 5HPD for julemat, 5HCP for Halloween, 5HKU for bryllup etc.',
            related: [],
            children: [],
          },
          {
            id: 'WBS',
            label:
              'Kokebøker: matlaging ved hjelp av spesielle kjøkkenredskaper, -utstyr eller teknikker',
            originalLabel:
              'Kokebøker: matlaging ved hjelp av spesielle kjøkkenredskaper, -utstyr eller teknikker',
            alternativeLabels: ['slow cooker'],
            note: 'Her: mikrobølgeovn, wok, kjøkkenmaskin, trykkoker, riskoker, dampovn, leirgryte, jerngryte etc. Brukes for bøker om spesifikke teknikker eller metoder, som f.eks. steking, damping',
            related: [],
            children: [
              {
                id: 'WBSB',
                label: 'Grilling',
                originalLabel: 'Kokebøker: grilling og utendørs matlaging',
                alternativeLabels: ['turmat'],
                note: 'Her: barbeque, matlaging over åpen ild og andre utendørsteknikker som primus, stormkjøkken, bål, kokegrop, feltkjøkken osv.',
                related: [],
                children: [],
              },
              {
                id: 'WBSC',
                label: 'Luftfrityrkoker',
                originalLabel: 'Kokebøker: luftfrityrkoker',
                alternativeLabels: ['air fryer'],
                note: 'Her: airfryer og lignende utstyr eller teknikker',
                related: [],
                children: [],
              },
              {
                id: 'WBSD',
                label: 'Langtidskoking',
                originalLabel: 'Kokebøker: langtidskoking',
                alternativeLabels: ['slow cooking', 'slow cooker'],
                note: 'Her: sous vide og lignende utstyr eller teknikker',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WBT',
            label: 'Kokebøker etter ingrediens',
            originalLabel: 'Kokebøker etter ingrediens',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'WBTB',
                label: 'Kokebøker: kjøtt og vilt',
                originalLabel: 'Kokebøker: kjøtt og vilt',
                alternativeLabels: [
                  'steik',
                  'stuing',
                  'gryterett',
                  'skinke',
                  'bacon',
                ],
                note: 'Her: matlaging med ulike typer kjøtt som okse-, kalve-, svine-, lamme-, kanin-, viltkjøtt, pølser og bearbeidede kjøttprodukter',
                related: [],
                children: [],
              },
              {
                id: 'WBTC',
                label: 'Kokebøker: kylling og annet fjærfekjøtt',
                originalLabel: 'Kokebøker: kylling og annet fjærfekjøtt',
                alternativeLabels: [],
                note: 'Her matlaging med kalkun, and, gås, vaktel, fasan, rype etc.',
                related: [],
                children: [],
              },
              {
                id: 'WBTF',
                label: 'Kokebøker: fisk og sjømat',
                originalLabel: 'Kokebøker: fisk og sjømat',
                alternativeLabels: [],
                note: 'Har matlaging med skalldyr, østers, sushi, sild, makrell, torsk, laks, ørret og andre typer fisk',
                related: [],
                children: [],
              },
              {
                id: 'WBTH',
                label: 'Kokebøker: urter, krydder, oljer og eddiker',
                originalLabel: 'Kokebøker: urter, krydder, oljer og eddiker',
                alternativeLabels: [
                  'basilikum',
                  'mynte',
                  'persille',
                  'koriander',
                  'oregano',
                  'timian',
                  'estragon',
                  'laurbærblad',
                  'rosmarin',
                  'salvie',
                  'dill',
                  'lavendel',
                  'fennikel',
                  'anis',
                  'kardemomme',
                  'kanel',
                  'ingefær',
                  'sennepsfrø',
                  'muskat',
                  'pepper',
                  'sfran',
                  'gurkemeie',
                  'vanlije',
                ],
                note: 'Her matlaging hvor man bruker balsamicoeddik eller andre typer eddik, olivenolje og andre typer matlagingsolje, ulike typer urter og krydder',
                related: [],
                children: [],
              },
              {
                id: 'WBTJ',
                label: 'Kokebøker: ris, korn, belgfrukter, nøtter og frø',
                originalLabel:
                  'Kokebøker: ris, korn, belgfrukter, nøtter og frø',
                alternativeLabels: [],
                note: 'Her: bønner, peanøtter, linser, soya',
                related: [],
                children: [],
              },
              {
                id: 'WBTM',
                label: 'Kokebøker: frukt og grønnsaker',
                originalLabel: 'Kokebøker: frukt og grønnsaker',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WBTP',
                label: 'Kokebøker: pasta og nudler',
                originalLabel: 'Kokebøker: pasta og nudler',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WBTR',
                label: 'Kokebøker: egg, ost og meieriprodukter',
                originalLabel: 'Kokebøker: egg, ost og meieriprodukter',
                alternativeLabels: [],
                note: 'Her: melk, fløte, smør, iskrem, yoghurt, omelett, pannekaker, fondue, kjerne ost, guider til ulike ostetyper. Brukes for bøker om å tilberede eller lage mat med ulike meieriprodukter',
                related: [],
                children: [],
              },
              {
                id: 'WBTX',
                label: 'Kokebøker: sjokolade',
                originalLabel: 'Kokebøker: sjokolade',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WBV',
            label: 'Kokebøker: matretter og måltider',
            originalLabel: 'Kokebøker: matretter og måltider',
            alternativeLabels: ['lunch', 'brunch'],
            note: 'Brukes for bøker med forslag til menyer og oppskrifter som forbindes med spesifikke måltider som f.eks. frokost, brunsj, lunsj, middag, kveldsmat, eller bøker som handler om en spesifikk rett eller et spesifikt måltid',
            related: [],
            children: [
              {
                id: 'WBVD',
                label: 'Supper og forretter',
                originalLabel: 'Kokebøker: supper og forretter',
                alternativeLabels: [],
                note: 'Her: dipper, fingermat, tapas, meze, hors d’oeuvre, kanapeer, buljong. Brukes for mat som skal serveres på starten av måltidet eller som serveres sammen med en aperitiff, cocktail eller andre drinker',
                related: [],
                children: [],
              },
              {
                id: 'WBVG',
                label: 'Salater og grønnsaker',
                originalLabel: 'Kokebøker: salater og grønnsaker',
                alternativeLabels: ['raw food'],

                related: [],
                children: [],
              },
              {
                id: 'WBVH',
                label: 'Sauser',
                originalLabel: 'Kokebøker: sauser',
                alternativeLabels: [],
                note: 'Her: salsa, marinader, dressinger',
                related: [],
                children: [],
              },
              {
                id: 'WBVM',
                label: 'Hovedretter',
                originalLabel: 'Kokebøker: hovedretter',
                alternativeLabels: [],
                note: 'Her: forslag til menyer og oppskrifter på matretter som passer som hovedrett',
                related: [],
                children: [],
              },
              {
                id: 'WBVQ',
                label: 'Desserter',
                originalLabel: 'Kokebøker: desserter',
                alternativeLabels: [],
                note: 'Her: puddinger, sorbeter, konfekt, frosne desserter etc. Brukes med andre WB*-koder for spesifikke typer dessert, f.eks. WBTR for iskrem, WBTM for fruktbaserte desserter, WBH* for desserter for spesielle dietter etc.',
                related: [],
                children: [],
              },
              {
                id: 'WBVR',
                label: 'Kokebøker: smørbrød, matpakker og medbrakt mat',
                originalLabel: 'Kokebøker: smørbrød, matpakker og medbrakt mat',
                alternativeLabels: [
                  'matpakke',
                  'brødskiver',
                  'bento',
                  'turmat',
                ],
                note: 'Her: piknik, mat som er lett å ta med seg som wraps, ostesmørbrød, panini, burgere; ideer til matpakker og matbokser. Brukes for bøker med forslag til menyer eller oppskrifter på mat, måltider eller snacks som man forbereder hjemme, men spiser et annet sted, f.eks. på tur, på skolen eller på jobb. Brukes med WBQ for matpakker spesielt for barn eller ungdom',
                related: [],
                children: [],
              },
              {
                id: 'WBVS',
                label: 'Baking',
                originalLabel: 'Baking',
                alternativeLabels: ['bakverk'],
                note: 'Her, paier, terter, deiger, kjeks, scones etc. Brukes for bøker om å bake eller bøker som dekker ulike typer bakverk, søtsaker og desserter, baketeknikker. Brukes med WBT* for baking av andre typer mat, f.eks. frukt, grønnsaker, kjøtt etc.',
                related: [],
                children: [
                  {
                    id: 'WBVS1',
                    label: 'Kaker og kakedekorasjon, glasur og sukkerkunst',
                    originalLabel:
                      'Kaker og kakedekorasjon, glasur og sukkerkunst',
                    alternativeLabels: ['muffins', 'cupcakes'],
                    note: 'Brukes for baking av alle typer kaker, samt kakedekorasjon',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'WBVS2',
                    label: 'Brød og brødbaking',
                    originalLabel: 'Brød og brødbaking',
                    alternativeLabels: [],
                    note: 'Her: pizza, flatbrød, surdeigsbrød, bagels, rugbrød, brød bakt med gjær eller bakepulver etc. Brukes med WBS for bøker om å bake med brødbakemaskin',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'WBW',
            label: 'Konservering og frysing',
            originalLabel: 'Kokebøker: konservering og frysing',
            alternativeLabels: ['gele'],
            note: 'Her: chutney, syltetøy, pickles, salting, tørking, hermetisering, fermentering og andre konserveringsmetoder',
            related: [],
            children: [],
          },
          {
            id: 'WBX',
            label: 'Drikke',
            originalLabel: 'Drikke',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'WBXD',
                label: 'Alkoholholdige drikkevarer',
                originalLabel: 'Drikke: alkoholholdige drikkevarer',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'WBXD1',
                    label: 'Vin',
                    originalLabel: 'Vin',
                    alternativeLabels: [
                      'Champagne',
                      'Prosecco',
                      'sherry',
                      'portvin',
                    ],
                    note: 'Her: vinlegging, vinsmaking, ulike vintyper, sommelier, sette vin og mat sammen, vinatlas, vinguider etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'WBXD2',
                    label: 'Øl og sider',
                    originalLabel: 'Øl og sider',
                    alternativeLabels: [],
                    note: 'Her: ølbrygging, mikrobryggerier, guider til ulike øltyper etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'WBXD3',
                    label: 'Sprit, likør og cocktailer',
                    originalLabel: 'Sprit, likør og cocktailer',
                    alternativeLabels: ['Martini', 'Drinker'],
                    note: 'Her: bøker om ulike typer sprit som gin, whisky, tequila, vodka, rom, akevitt etc., destillering, guider for bartendere etc.',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'WBXN',
                label: 'Alkoholfrie drikkevarer',
                originalLabel: 'Drikke: alkoholfrie drikkevarer',
                alternativeLabels: [],
                note: 'Her: brus, mocktails, flaskevann, energidrikker etc. Brukes for guider til ulike typer drikker, både varme og kalde',
                related: [],
                children: [
                  {
                    id: 'WBXN1',
                    label: 'Te og kaffe',
                    originalLabel: 'Te og kaffe',
                    alternativeLabels: [],
                    note: 'Her: tekultur, kaffekultur. Svart, grønn, hvit, gul eller oolong te; kombucha, kaffebrenning, iste, iskaffe etc. Brukes for bøker om typer te og kaffe, og tilberedning av disse drikkene, om seremonier og ritualer som forbindes med dem, te- eller kaffedrikking. Brukes med andre kategorier for utgivelser om bransjen, historie, tradisjoner, helsefordeler, f.eks. med WBVS eller WBVS1 for kaker som passer til te eller kaffe, TTV for bøker som er ment for profesjonsmarkedet. Foretrekk VXHT4 for urtete',
                    related: [],
                    children: [
                      {
                        id: 'WBXN12',
                        label: 'Teseremonier',
                        originalLabel: 'Teseremonier',
                        alternativeLabels: [],
                        note: 'Her: japanske teseremonier, chanoyu',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'WBXN3',
                    label: 'Jus og smoothier',
                    originalLabel: 'Jus og smoothier',
                    alternativeLabels: ['juice'],
                    note: 'Her: saft, limonade, milkshake etc. Brukes med WBS for å lage jus ved hjelp av ei jusmaskin',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'WBZ',
            label: 'Sigarer og røyking',
            originalLabel: 'Sigarer og røyking',
            alternativeLabels: [],
            note: 'Se også VFL <a href="/thema/nb/VFL">Å slutte å røyke</a>',
            related: [
              {
                id: 'VFL',
                label: 'Å slutte å røyke',
                originalLabel: 'Å slutte å røyke',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'WC',
        label: 'Antikviteter og samleobjekter',
        originalLabel: 'Antikviteter og samleobjekter',
        alternativeLabels: ['vintage'],
        note: 'Bruk WC*-koder for bøker om antikviteter og om det å samle på dem',
        related: [],
        children: [
          {
            id: 'WCB',
            label: 'Antikviteter og samleobjekter: kjøpeguider',
            originalLabel: 'Antikviteter og samleobjekter: kjøpeguider',
            alternativeLabels: ['vintage'],
            note: 'Her: prislister, kataloger, kjøpsguider, verdivurdering, etc., brukes med annen WC*-kode som indikerer hva slags produkt det er, der det er hensiktsmessig. Se også UDBA <a href="/thema/nb/UDBA">Handel og auksjoner på internett</a>',
            related: [
              {
                id: 'UDBA',
                label: 'Handel og auksjoner på internett',
                originalLabel: 'Handel og auksjoner på internett',
                note: 'Brukes for bøker for allmennmarkedet som handler om e-handel, netthandel, digitale markedsplasser',
              },
            ],
            children: [],
          },
          {
            id: 'WCC',
            label: 'Vedlikehold og restaurering av antikviteter',
            originalLabel: 'Vedlikehold og restaurering av antikviteter',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'WCF',
            label:
              'Samle mynter, sedler, medaljer og andre lignende gjenstander',
            originalLabel:
              'Samle mynter, sedler, medaljer og andre lignende gjenstander',
            alternativeLabels: [],
            note: 'Her: det å samle på segl, numismatikk',
            related: [],
            children: [],
          },
          {
            id: 'WCG',
            label: 'Samle frimerker, filateli',
            originalLabel: 'Samle frimerker, filateli',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'WCJ',
            label: 'Antikke klokker, spilledåser m.m.',
            originalLabel: 'Antikke klokker, spilledåser m.m.',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'WCJB',
                label: 'Armbåndsur',
                originalLabel: 'Armbåndsur',
                alternativeLabels: [
                  'Rolex',
                  'Omega',
                  'Patek Phillipe',
                  'klokker',
                ],
                note: 'Brukes for: det å samle på armbåndsur, armbåndsur som mote, luksusobjekter etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WCK',
            label: 'Militæreffekter, våpen og rustninger',
            originalLabel: 'Militæreffekter, våpen og rustninger',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'WCL',
            label: 'Antikke møbler og møbelsamling',
            originalLabel: 'Antikke møbler og møbelsamling',
            alternativeLabels: ['vintage'],
            note: 'Brukes med WCC for bøker om å restaurere antikke møbler',
            related: [],
            children: [],
          },
          {
            id: 'WCN',
            label:
              'Antikviteter og samleobjekter: keramikk, glass og andre lignende gjenstander',
            originalLabel:
              'Antikviteter og samleobjekter: keramikk, glass og andre lignende gjenstander',
            alternativeLabels: ['servise', 'kjøkkenutstyr', 'vintage'],

            related: [],
            children: [
              {
                id: 'WCNC',
                label: 'Antikviteter og samleobjekter: keramikk og porselen',
                originalLabel:
                  'Antikviteter og samleobjekter: keramikk og porselen',
                alternativeLabels: ['vintage'],
                note: 'Class here: collecting china',
                related: [],
                children: [],
              },
              {
                id: 'WCNG',
                label: 'Antikviteter og samleobjekter: glass',
                originalLabel: 'Antikviteter og samleobjekter: glass',
                alternativeLabels: ['vintage'],
                note: 'Her: det å samle på flasker',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WCP',
            label: 'Antikviteter og samleobjekter: smykker',
            originalLabel: 'Antikviteter og samleobjekter: smykker',
            alternativeLabels: ['vintage'],

            related: [],
            children: [],
          },
          {
            id: 'WCR',
            label:
              'Antikviteter og samleobjekter: gull, sølv og andre metaller (annet enn smykker)',
            originalLabel:
              'Antikviteter og samleobjekter: gull, sølv og andre metaller (annet enn smykker)',
            alternativeLabels: ['vintage'],

            related: [],
            children: [
              {
                id: 'WCRB',
                label:
                  'Antikviteter og samleobjekter: knapper, merker, pins og lignende små gjenstander',
                originalLabel:
                  'Antikviteter og samleobjekter: knapper, merker, pins og lignende små gjenstander',
                alternativeLabels: ['vintage'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WCS',
            label:
              'Antikviteter og samleobjekter: bøker, manuskripter, trykksaker',
            originalLabel:
              'Antikviteter og samleobjekter: bøker, manuskripter, trykksaker',
            alternativeLabels: ['vintage'],
            note: 'Her: programmer, postkort, tegneserier, autografer, bilder, telekort, tidsskrifter, aviser, reklamemateriell etc.',
            related: [],
            children: [],
          },
          {
            id: 'WCT',
            label: 'Antikviteter og samleobjekter: sports memorabilia',
            originalLabel: 'Antikviteter og samleobjekter: sports memorabilia',
            alternativeLabels: ['vintage'],

            related: [],
            children: [],
          },
          {
            id: 'WCU',
            label: 'Antikviteter og samleobjekter: bilder, trykk og kart',
            originalLabel:
              'Antikviteter og samleobjekter: bilder, trykk og kart',
            alternativeLabels: ['vintage'],
            note: 'Her: plakater',
            related: [],
            children: [],
          },
          {
            id: 'WCV',
            label: 'Antikviteter og samleobjekter: tepper, ryer og tekstiler',
            originalLabel:
              'Antikviteter og samleobjekter: tepper, ryer og tekstiler',
            alternativeLabels: ['vintage'],

            related: [],
            children: [
              {
                id: 'WCVB',
                label: 'Antikviteter og samleobjekter: klær og tilbehør',
                originalLabel:
                  'Antikviteter og samleobjekter: klær og tilbehør',
                alternativeLabels: ['vintage', 'accessoirer'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WCW',
            label:
              'Antikviteter og samleobjekter: leker, spill, dukker og modeller',
            originalLabel:
              'Antikviteter og samleobjekter: leker, spill, dukker og modeller',
            alternativeLabels: ['vintage'],
            note: 'Her: teddybjørner',
            related: [],
            children: [],
          },
          {
            id: 'WCX',
            label:
              'Antikviteter og samleobjekter: instrumenter, verktøy og redskaper',
            originalLabel:
              'Antikviteter og samleobjekter: instrumenter, verktøy og redskaper',
            alternativeLabels: ['vintage'],

            related: [],
            children: [
              {
                id: 'WCXM',
                label: 'Antikviteter og samleobjekter: musikkinstrumenter',
                originalLabel:
                  'Antikviteter og samleobjekter: musikkinstrumenter',
                alternativeLabels: ['vintage'],

                related: [],
                children: [],
              },
              {
                id: 'WCXS',
                label:
                  'Antikviteter og samleobjekter: vitenskapelige instrumenter',
                originalLabel:
                  'Antikviteter og samleobjekter: vitenskapelige instrumenter',
                alternativeLabels: ['vintage'],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'WD',
        label: 'Hobbyer, spørrebøker og spill',
        originalLabel: 'Hobbyer, spørrebøker og spill',
        alternativeLabels: [],
        note: 'Bruk WD* med WZ* for å angi at produktet ikke er ei bok, f.eks. et spill eller et puslespill. NB: flere detaljer om produktet bør sendes i andre metadata',
        related: [],
        children: [
          {
            id: 'WDH',
            label: 'Hobbyer',
            originalLabel: 'Hobbyer',
            alternativeLabels: [],
            note: 'Se også WF* <a href="/thema/nb/WF">Husflid, kunsthåndverk og håndarbeid</a> og dens underkategorier',
            related: [
              {
                id: 'WF',
                label: 'Husflid, kunsthåndverk og håndarbeid',
                originalLabel: 'Husflid, kunsthåndverk og håndarbeid',
                note: 'Bruk WF *-koder for praktiske «hvordan-gjøre-det selv bøker» og hobbybøker. Foretrekk koder fra A* for bøker om kunsthistorie og -kritikk, eller koder fra T* for bøker for høyere utdanning og profesjonsmarkedet. Se også AFT <a href="/thema/nb/AFT">Dekorativ kunst</a>',
              },
            ],
            children: [
              {
                id: 'WDHB',
                label: 'Modellbygging og -konstruksjon',
                originalLabel: 'Modellbygging og -konstruksjon',
                alternativeLabels: [],
                note: 'Her: miniatyrfigurer, tinnsoldater, spillfigurer, skalamodeller',
                related: [],
                children: [],
              },
              {
                id: 'WDHM',
                label: 'Modelljernbaner',
                originalLabel: 'Modelljernbaner',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WDHR',
                label: 'Radiostyrte modeller',
                originalLabel: 'Radiostyrte modeller',
                alternativeLabels: [],
                note: 'Her: modellfly, modellbilder, modellbåter, droner etc.',
                related: [],
                children: [],
              },
              {
                id: 'WDHW',
                label: 'Rollespill, krigsspill og fantasy sport',
                originalLabel: 'Rollespill, krigsspill og fantasy sport',
                alternativeLabels: [],
                note: 'Her: rollespill av typen Dungeons &amp; Dragons. Se også FYW <a href="/thema/nb/FYW">Skjønnlitteratur: spesielle utgivelser: spill</a>',
                related: [
                  {
                    id: 'FYW',
                    label: 'Litterære rollespill',
                    originalLabel:
                      'Skjønnlitteratur: spesielle utgivelser: spill',
                    note: 'Her: litterære rollespill (LitRPG). Brukes for skjønnlitteratur som følger samme struktur som et tv-spill eller rollespill, eller har samme type utfordringer som man finner i alle typer spill, eller som foregår i et tv-spill, nettspill, rollespill, etc. Brukes med andre F*-koder, særlig FL* eller FM* der det er hensiktsmessig',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'WDJ',
            label: '3-D bilder og optiske illusjoner',
            originalLabel: '3-D bilder og optiske illusjoner',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'WDK',
            label: 'Hjernetrim, spill, spørsmål',
            originalLabel: 'Hjernetrim, spill, spørsmål',
            alternativeLabels: [],
            note: 'Her: minnespill, puslespill, spørrespill. Brukes for bøker som inneholder gåter, eller spørsmål og svar',
            related: [],
            children: [
              {
                id: 'WDKC',
                label: 'Kryssord og ordspill',
                originalLabel: 'Kryssord og ordspill',
                alternativeLabels: [],
                note: 'Her: ordbøker for Scrabble, ordlister, bokstavspill, språkspill',
                related: [],
                children: [],
              },
              {
                id: 'WDKN',
                label: 'Sudoku og talloppgaver',
                originalLabel: 'Sudoku og talloppgaver',
                alternativeLabels: [],
                note: 'Her: tallspill',
                related: [],
                children: [],
              },
              {
                id: 'WDKX',
                label: 'Spørrebøker',
                originalLabel: 'Spørrebøker',
                alternativeLabels: [],
                note: 'Her: quiz om allmennkunnskap, spørrebøker for ‘pub-quiz’. Brukes med andre emnekoder for spørrebøker om et spesielt tema der det er hensiktsmessig. Se også GBD <a href="/thema/nb/GBD">Samlinger og kompendier</a>',
                related: [
                  {
                    id: 'GBD',
                    label: 'Samlinger og kompendier',
                    originalLabel: 'Samlinger og kompendier',
                    note: 'Brukes for utgivelser som inneholder et utvalg tekster som er samlet fra ulike kilder og som kan dekke flere emner og format, som f.eks. sitater, fakta, illustrasjoner etc. Brukes med andre emnekoder når samlinga dekker et bredt emneområde (f.eks. sammen med WN*-koder). Se også WDKX <a href="/thema/nb/WDKX">Spørrebøker</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'WDM',
            label: 'Innendørsspill',
            originalLabel: 'Innendørsspill',
            alternativeLabels: [],
            note: 'Brukes for bøker om alle typer spill som vanligvis spilles innendørs, inkludert selskapsleker, pedagogiske spill, hasardspill, vitenskapsspill, terningspill. Se også UDX <a href="/thema/nb/UDX">Dataspill / nettspill: strategiguider</a>',
            related: [
              {
                id: 'UDX',
                label: 'Dataspill / nettspill: strategiguider',
                originalLabel: 'Dataspill / nettspill: strategiguider',
                note: 'Se også SXE <a href="/thema/nb/SXE">E-sport</a>',
              },
            ],
            children: [
              {
                id: 'WDMC',
                label: 'Kortspill',
                originalLabel: 'Kortspill',
                alternativeLabels: [],
                note: 'Her: kabal, samlekortspill. Brukes for alle slags spill som involverer bruk av alle typer spillkort',
                related: [],
                children: [
                  {
                    id: 'WDMC1',
                    label: 'Bridge',
                    originalLabel: 'Kortspill: bridge',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'WDMC2',
                    label: 'Poker',
                    originalLabel: 'Kortspill: poker',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'WDMG',
                label: 'Brettspill',
                originalLabel: 'Brettspill',
                alternativeLabels: [],
                note: 'Her: mahjong, backgammon, dam, terningspill. Brukes for alle typer brettspill',
                related: [],
                children: [
                  {
                    id: 'WDMG1',
                    label: 'Sjakk',
                    originalLabel: 'Brettspill: sjakk',
                    alternativeLabels: [],
                    note: 'Brukes for bøker om sjakk og alle varianter av sjakk',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'WDMG2',
                    label: 'Brettspill: Shogi',
                    originalLabel: 'Brettspill: Shogi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'WDMG3',
                    label: 'Brettspill: Go',
                    originalLabel: 'Brettspill: Go',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'WDP',
            label: 'Gambling: teorier og metoder',
            originalLabel: 'Gambling: teorier og metoder',
            alternativeLabels: [],
            note: 'Se også UDBG <a href="/thema/nb/UDBG">Internettgambling</a>',
            related: [
              {
                id: 'UDBG',
                label: 'Internettgambling',
                originalLabel: 'Internettgambling',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'WF',
        label: 'Husflid, kunsthåndverk og håndarbeid',
        originalLabel: 'Husflid, kunsthåndverk og håndarbeid',
        alternativeLabels: [],
        note: 'Bruk WF *-koder for praktiske «hvordan-gjøre-det selv bøker» og hobbybøker. Foretrekk koder fra A* for bøker om kunsthistorie og -kritikk, eller koder fra T* for bøker for høyere utdanning og profesjonsmarkedet. Se også AFT <a href="/thema/nb/AFT">Dekorativ kunst</a>',
        related: [
          {
            id: 'AFT',
            label: 'Dekorativ kunst',
            originalLabel: 'Dekorativ kunst',
            note: 'Her: ornamenter, dekorasjonsdetaljer, dekorative maleteknikker, dekorative mønstre og former, dekorativt metallarbeid, lakkarbeid og andre dekorative kunstarter. Brukes for bøker som tar for seg emnet fra et kunst- eller kunsthistorisk perspektiv, eller som retter seg mot et profesjonelt publikum, eller et publikum med tekniske ferdigheter. Se også WF* <a href="/thema/nb/WF">Husflid, kunsthåndverk og håndarbeid</a> og dens underkategorier',
          },
        ],
        children: [
          {
            id: 'WFA',
            label: 'Maling, tegning og kunsthåndbøker',
            originalLabel: 'Maling, tegning og kunsthåndbøker',
            alternativeLabels: [
              'vannfarger',
              'akvareller',
              'oljemaling',
              'akrylmaling',
            ],
            note: 'Se også AGZ <a href="/thema/nb/AGZ">Kunstteknikker og -prinsipper</a>',
            related: [
              {
                id: 'AGZ',
                label: 'Kunstteknikker og -prinsipper',
                originalLabel: 'Kunstteknikker og -prinsipper',
                note: 'Brukes for lærebøker, bøker for høyere utdanning eller profesjonsmarkedet om teknikker, perspektiv, etc. Brukes med passende AF* eller AK* koder for å angi kunstform, f.eks. AFF for tegneteknikker. Foretrekk WF*-koder som hovedkategori for bøker som handler om emnet som en hobby. Se også WFA <a href="/thema/nb/WFA">Maling, tegning og kunsthåndbøker</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WFB',
            label: 'Håndarbeid',
            originalLabel: 'Håndarbeid',
            alternativeLabels: [],
            note: 'Her: bøker med mønstre og design. Se også AFW <a href="/thema/nb/AFW">Tekstilkunstverk</a>, AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>',
            related: [
              {
                id: 'AFW',
                label: 'Tekstilkunstverk',
                originalLabel: 'Tekstilkunstverk',
                note: 'Her: tepper, kelimer, billedvever og andre kunstverk av tekstiler. Se også AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>, WFB* <a href="/thema/nb/WFB">Håndarbeid</a> og dens underkategorier, WFF <a href="/thema/nb/WFF">Tepper og teppeknyting</a>, WFG <a href="/thema/nb/WFG">Spinning og veving</a>',
              },
              {
                id: 'AKT',
                label: 'Mote- og tekstildesign',
                originalLabel: 'Mote- og tekstildesign',
                note: 'Brukes for stiler og trender innen motedesign, generelle verk, referanseverk, bøker om spesielle klesplagg, illustrerte verk om mote etc. Bruk AKT*-koder med AKB for spesifikke motedesignere, med AKX for motehistorie, pluss kvalifikator(er) for stil, sted og tidsperiode der det er hensiktsmessig, med AJC* for fotografiske samlinger eller samlinger av motefotografier, med AGZ for praktiske teknikker for motestudenter og -designere. Foretrekk WF*-koder som hovedkategori for bøker ment for allmennmarkedet og som handler om hvordan man skal kle seg, hvordan man skal se moteriktig ut, har praktiske tips etc. Se også TDPF* <a href="/thema/nb/TDPF">Tekstiler og fibre</a> og dens underkategorier, KNDD <a href="/thema/nb/KNDD">Tekstilindustri</a>, KNSX <a href="/thema/nb/KNSX">Mote- og skjønnhetsbransjen</a>, WJF <a href="/thema/nb/WJF">Mote- og stilguider</a>',
              },
            ],
            children: [
              {
                id: 'WFBC',
                label: 'Broderier',
                originalLabel: 'Broderier',
                alternativeLabels: [],
                note: 'Her: korssting, andre broderiteknikker, billedvev etc.',
                related: [],
                children: [],
              },
              {
                id: 'WFBL',
                label: 'Blonder og kniplinger',
                originalLabel: 'Blonder og kniplinger',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WFBQ',
                label: 'Quilting, lappeteknikk og applikasjon',
                originalLabel: 'Quilting, lappeteknikk og applikasjon',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WFBS',
                label: 'Strikking og hekling',
                originalLabel: 'Strikking og hekling',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'WFBS1',
                    label: 'Strikking',
                    originalLabel: 'Strikking',
                    alternativeLabels: [],
                    note: 'Her: strikkeoppskrifter, strikketeknikker, spesifikke strikketradisjoner som Fair Isle, Aran, Argyle, mønsterstrikk, eller bøker om å strikke spesifikke plagg eller tilbehør som sokker, gensere, luer, votter, skjerf etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'WFBS2',
                    label: 'Hekling',
                    originalLabel: 'Hekling',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'WFBV',
                label: 'Tekstilfarging',
                originalLabel: 'Tekstilfarging',
                alternativeLabels: [],
                note: 'Her: batikk og knytebatikk',
                related: [],
                children: [],
              },
              {
                id: 'WFBW',
                label: 'Søm',
                originalLabel: 'Søm',
                alternativeLabels: [],
                note: 'Her: sying',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WFC',
            label: 'Tau, knuter og makramé',
            originalLabel: 'Tau, knuter og makramé',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'WFD',
            label: 'Lærarbeid',
            originalLabel: 'Lærarbeid',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'WFF',
            label: 'Tepper og teppeknyting',
            originalLabel: 'Tepper og teppeknyting',
            alternativeLabels: [],
            note: 'Se også AFW <a href="/thema/nb/AFW">Tekstilkunstverk</a>, AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>',
            related: [
              {
                id: 'AFW',
                label: 'Tekstilkunstverk',
                originalLabel: 'Tekstilkunstverk',
                note: 'Her: tepper, kelimer, billedvever og andre kunstverk av tekstiler. Se også AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>, WFB* <a href="/thema/nb/WFB">Håndarbeid</a> og dens underkategorier, WFF <a href="/thema/nb/WFF">Tepper og teppeknyting</a>, WFG <a href="/thema/nb/WFG">Spinning og veving</a>',
              },
              {
                id: 'AKT',
                label: 'Mote- og tekstildesign',
                originalLabel: 'Mote- og tekstildesign',
                note: 'Brukes for stiler og trender innen motedesign, generelle verk, referanseverk, bøker om spesielle klesplagg, illustrerte verk om mote etc. Bruk AKT*-koder med AKB for spesifikke motedesignere, med AKX for motehistorie, pluss kvalifikator(er) for stil, sted og tidsperiode der det er hensiktsmessig, med AJC* for fotografiske samlinger eller samlinger av motefotografier, med AGZ for praktiske teknikker for motestudenter og -designere. Foretrekk WF*-koder som hovedkategori for bøker ment for allmennmarkedet og som handler om hvordan man skal kle seg, hvordan man skal se moteriktig ut, har praktiske tips etc. Se også TDPF* <a href="/thema/nb/TDPF">Tekstiler og fibre</a> og dens underkategorier, KNDD <a href="/thema/nb/KNDD">Tekstilindustri</a>, KNSX <a href="/thema/nb/KNSX">Mote- og skjønnhetsbransjen</a>, WJF <a href="/thema/nb/WJF">Mote- og stilguider</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WFG',
            label: 'Spinning og veving',
            originalLabel: 'Spinning og veving',
            alternativeLabels: [],
            note: 'Se også AFW <a href="/thema/nb/AFW">Tekstilkunstverk</a>, AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>',
            related: [
              {
                id: 'AFW',
                label: 'Tekstilkunstverk',
                originalLabel: 'Tekstilkunstverk',
                note: 'Her: tepper, kelimer, billedvever og andre kunstverk av tekstiler. Se også AKT <a href="/thema/nb/AKT">Mote- og tekstildesign</a>, WFB* <a href="/thema/nb/WFB">Håndarbeid</a> og dens underkategorier, WFF <a href="/thema/nb/WFF">Tepper og teppeknyting</a>, WFG <a href="/thema/nb/WFG">Spinning og veving</a>',
              },
              {
                id: 'AKT',
                label: 'Mote- og tekstildesign',
                originalLabel: 'Mote- og tekstildesign',
                note: 'Brukes for stiler og trender innen motedesign, generelle verk, referanseverk, bøker om spesielle klesplagg, illustrerte verk om mote etc. Bruk AKT*-koder med AKB for spesifikke motedesignere, med AKX for motehistorie, pluss kvalifikator(er) for stil, sted og tidsperiode der det er hensiktsmessig, med AJC* for fotografiske samlinger eller samlinger av motefotografier, med AGZ for praktiske teknikker for motestudenter og -designere. Foretrekk WF*-koder som hovedkategori for bøker ment for allmennmarkedet og som handler om hvordan man skal kle seg, hvordan man skal se moteriktig ut, har praktiske tips etc. Se også TDPF* <a href="/thema/nb/TDPF">Tekstiler og fibre</a> og dens underkategorier, KNDD <a href="/thema/nb/KNDD">Tekstilindustri</a>, KNSX <a href="/thema/nb/KNSX">Mote- og skjønnhetsbransjen</a>, WJF <a href="/thema/nb/WJF">Mote- og stilguider</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WFH',
            label: 'Leker: lage og dekorere',
            originalLabel: 'Leker: lage og dekorere',
            alternativeLabels: [],
            note: 'Inkluderer kosedyr, dukker, dukkehus etc.',
            related: [],
            children: [],
          },
          {
            id: 'WFJ',
            label: 'Smykker og perler',
            originalLabel: 'Smykker og perler',
            alternativeLabels: [],
            note: 'Se også AFKG <a href="/thema/nb/AFKG">Edle metaller, edelstener og smykker: kunstverk og design</a>',
            related: [
              {
                id: 'AFKG',
                label:
                  'Edle metaller, edelstener og smykker: kunstverk og design',
                originalLabel:
                  'Edle metaller, edelstener og smykker: kunstverk og design',
                note: 'Her: gull- og sølvsmedarbeid, Fabergé etc. Se også WFJ <a href="/thema/nb/WFJ">Smykker og perler</a>, WCP <a href="/thema/nb/WCP">Antikviteter og samleobjekter: smykker</a>, WCR <a href="/thema/nb/WCR">Antikviteter og samleobjekter: gull, sølv og andre metaller (annet enn smykker)</a>, WFP <a href="/thema/nb/WFP">Kunsthåndverk av metall</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WFK',
            label: 'Dekorasjonsteknikker og overflatebehandling',
            originalLabel: 'Dekorasjonsteknikker og overflatebehandling',
            alternativeLabels: [],
            note: 'Her: decoupage',
            related: [],
            children: [],
          },
          {
            id: 'WFL',
            label: 'Kunsthåndverk av bambus, strå',
            originalLabel: 'Kunsthåndverk av bambus, strå',
            alternativeLabels: [],
            note: 'Her: kurvmakeri, kurvfletting av pil, vidje, sjøgress, trespon og andre bøyelige plantebaserte materialer',
            related: [],
            children: [],
          },
          {
            id: 'WFN',
            label: 'Keramikk og glasskunst',
            originalLabel: 'Keramikk og glasskunst',
            alternativeLabels: [],
            note: 'Se også AFP <a href="/thema/nb/AFP">Keramikk, mosaikk og glass: kunstverk</a>',
            related: [
              {
                id: 'AFP',
                label: 'Keramikk, mosaikk og glass: kunstverk',
                originalLabel: 'Keramikk, mosaikk og glass: kunstverk',
                note: 'Her: keramikk (inkludert porselen, steingods, steintøy, bisque), glassmaleri, fliser eller emalje som kunstverk. Se også WFN <a href="/thema/nb/WFN">Keramikk og glasskunst</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WFP',
            label: 'Kunsthåndverk av metall',
            originalLabel: 'Kunsthåndverk av metall',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'WFQ',
            label: 'Kunsthåndverk av tre',
            originalLabel: 'Kunsthåndverk av tre',
            alternativeLabels: [],
            note: 'Her: dekorere esker, bilderammer, tremøbler',
            related: [],
            children: [],
          },
          {
            id: 'WFS',
            label: 'Treskjæring og modellering, forming og støping',
            originalLabel: 'Treskjæring og modellering, forming og støping',
            alternativeLabels: [],
            note: 'Her: treskjæring, gipsarbeid, støpe stearinlys, etc. Se også AFKC <a href="/thema/nb/AFKC">Utskjæring, masker, relieffer</a>',
            related: [
              {
                id: 'AFKC',
                label: 'Utskjæring, masker, relieffer',
                originalLabel: 'Utskjæring, masker, relieffer',
                note: 'Her: treskjæring, helleristninger, mindre og monumentale utskjæringer. Brukes for både teknikken og bøker om spesifikke objekter som har utskjæringer, uavhengig av materiale',
              },
            ],
            children: [],
          },
          {
            id: 'WFT',
            label: 'Bokkunst, papirkunst og -forming',
            originalLabel: 'Bokkunst, papirkunst og -forming',
            alternativeLabels: ['papiermache', 'papier-mache'],
            note: 'Her: lage papir, bokbinding, gavekort, scrapbooking, papirfly, papirmodeller, decoupage, silhuetter etc. Se også AKH <a href="/thema/nb/AKH">Bokdesign og bokinnbinding</a>',
            related: [
              {
                id: 'AKH',
                label: 'Bokdesign og bokinnbinding',
                originalLabel: 'Bokdesign og bokinnbinding',
                note: 'Her: omslagsdesign, luksusinnbinding, marmorpapir etc. som kunstform. Brukes for bøker om kunsten å designe og skape bøker. Se også WFT <a href="/thema/nb/WFT">Bokkunst, papirkunst og -forming</a>, AKLB <a href="/thema/nb/AKLB">Illustrasjoner</a>, KNTP1 <a href="/thema/nb/KNTP1">Forlags- og bokbransje</a>',
              },
            ],
            children: [
              {
                id: 'WFTM',
                label: 'Origami og papirbretting',
                originalLabel: 'Origami og papirbretting',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WFU',
            label: 'Kalligrafi og håndskrift',
            originalLabel: 'Kalligrafi og håndskrift',
            alternativeLabels: [],
            note: 'Se også AKD <a href="/thema/nb/AKD">Typografi og skrift</a>',
            related: [
              {
                id: 'AKD',
                label: 'Typografi og skrift',
                originalLabel: 'Typografi og skrift',
                note: 'Her: skrifttyper, fonter, kalligrafi som kunstform. Se også WFU <a href="/thema/nb/WFU">Kalligrafi og håndskrift</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WFV',
            label: 'Tradisjonelt håndverk',
            originalLabel: 'Tradisjonelt håndverk',
            alternativeLabels: ['kurver', 'gjerder', 'kulturarv'],
            note: 'Her: tradisjonelt håndverk som ofte forbindes med et liv på landet eller landbruksområder og som bruker tradisjonelle materialer. F.eks. kurvmakeri, lage fiskestenger, bygge gjerder, slåttonn, spinning. Se også AFT <a href="/thema/nb/AFT">Dekorativ kunst</a>, TTVR <a href="/thema/nb/TTVR">Tradisjonelle næringer, håndverk og ferdigheter</a>',
            related: [
              {
                id: 'AFT',
                label: 'Dekorativ kunst',
                originalLabel: 'Dekorativ kunst',
                note: 'Her: ornamenter, dekorasjonsdetaljer, dekorative maleteknikker, dekorative mønstre og former, dekorativt metallarbeid, lakkarbeid og andre dekorative kunstarter. Brukes for bøker som tar for seg emnet fra et kunst- eller kunsthistorisk perspektiv, eller som retter seg mot et profesjonelt publikum, eller et publikum med tekniske ferdigheter. Se også WF* <a href="/thema/nb/WF">Husflid, kunsthåndverk og håndarbeid</a> og dens underkategorier',
              },
              {
                id: 'TTVR',
                label: 'Tradisjonelle næringer, håndverk og ferdigheter',
                originalLabel:
                  'Tradisjonelle næringer, håndverk og ferdigheter',
                note: 'Her: smedarbeid, tekking, bygging av steingjerder, snekkerarbeid, musikkinstrumentmaker etc. Brukes for bøker for studenter og for profesjons- og allmennmarkedet som dekker disse førindustrielle håndverksfagene og - teknikkene. Foretrekk WF* for mer praktiske, gjør-det-selv-bøker. Se også WFV <a href="/thema/nb/WFV">Tradisjonelt håndverk</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WFW',
            label: 'Blomsterdekorering og blomsterarrangement',
            originalLabel: 'Blomsterdekorering og blomsterarrangement',
            alternativeLabels: ['blomsterdekoratør'],
            note: 'Her: blomsterdekorasjoner, blomsterspråk, ikebana, potpurri, tørkede blomster og planter',
            related: [],
            children: [],
          },
          {
            id: 'WFX',
            label: 'Tegne- og aktivitetsbøker for voksne',
            originalLabel: 'Tegne- og aktivitetsbøker for voksne',
            alternativeLabels: ['fargelegging'],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'WG',
        label: 'Transport',
        originalLabel: 'Transport',
        alternativeLabels: [],
        note: 'Se også KNG <a href="/thema/nb/KNG">Transportnæringer</a>, TR* <a href="/thema/nb/TR">Transportteknologi og -næringer</a> og dens underkategorier',
        related: [
          {
            id: 'KNG',
            label: 'Transportnæringer',
            originalLabel: 'Transportnæringer',
            note: 'Her: alle transportnæringer; vei, shipping, jernbane, luftfart etc. Brukes for verk som tar for seg disse bransjene fra et industri-/nærings-/bransjeperspektiv. Bruk med WG*-koder for å angi hvilken type kjøretøy det er snakk om der det er hensiktsmessig. Se også KNDR <a href="/thema/nb/KNDR">Produksjon av kjøretøy og transportmidler</a>, TR* <a href="/thema/nb/TR">Transportteknologi og -næringer</a> og dens underkategorier, WG* <a href="/thema/nb/WG">Transport</a> og dens underkategorier',
          },
          {
            id: 'TR',
            label: 'Transportteknologi og -næringer',
            originalLabel: 'Transportteknologi og -næringer',
            note: 'Se også KNG <a href="/thema/nb/KNG">Transportnæringer</a>, RPT <a href="/thema/nb/RPT">Transportplanlegging</a>, WG* <a href="/thema/nb/WG">Transport</a> og dens underkategorier',
          },
        ],
        children: [
          {
            id: 'WGC',
            label: 'Vei- og motorkjøretøy',
            originalLabel: 'Vei- og motorkjøretøy',
            alternativeLabels: [],
            note: 'Bruk WGC* med NH* for historisk behandling av emnet, RPT for planlegging av nye veier, JWMV / JWCD for militærkjøretøy. Se også TRCT <a href="/thema/nb/TRCT">Veitransport og transportnæringer</a>',
            related: [
              {
                id: 'TRCT',
                label: 'Veitransport og transportnæringer',
                originalLabel: 'Veitransport og transportnæringer',
              },
            ],
            children: [
              {
                id: 'WGCB',
                label: 'Biler',
                originalLabel: 'Biler',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WGCF',
                label: 'Busser, trikker og nyttekjøretøy',
                originalLabel: 'Busser, trikker og nyttekjøretøy',
                alternativeLabels: [],
                note: 'Her: lastebiler, trailere, varebiler etc.',
                related: [],
                children: [],
              },
              {
                id: 'WGCG',
                label: 'Anleggskjøretøy',
                originalLabel: 'Anleggskjøretøy',
                alternativeLabels: ['anleggsbiler'],
                note: 'Her: tunge kjøretøy og maskiner som gravemaskiner, kraner, sementbiler, bulldosere, løypemaskiner etc.',
                related: [],
                children: [],
              },
              {
                id: 'WGCK',
                label: 'Motorsykler',
                originalLabel: 'Motorsykler',
                alternativeLabels: ['scootere'],
                note: 'Her: mopeder, skutere',
                related: [],
                children: [],
              },
              {
                id: 'WGCQ',
                label: 'Bobiler',
                originalLabel: 'Bobiler',
                alternativeLabels: [],
                note: 'Her: campingvogner',
                related: [],
                children: [],
              },
              {
                id: 'WGCT',
                label: 'Traktorer og landbrukskjøretøy',
                originalLabel: 'Traktorer og landbrukskjøretøy',
                alternativeLabels: [],
                note: 'Her: landbrukskjøretøy eller -maskiner som skurtreskere, sette- og plantemaskiner, sprøytere, ballepresser, ploger eller andre jordfresere. Se også TVD <a href="/thema/nb/TVD">Landbruksteknikk og -utstyr</a>',
                related: [
                  {
                    id: 'TVD',
                    label: 'Landbruksteknikk og -utstyr',
                    originalLabel: 'Landbruksteknikk og -utstyr',
                    note: 'Se også WGCT <a href="/thema/nb/WGCT">Traktorer og landbrukskjøretøy</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'WGCV',
                label: 'Kjøretøy: vedlikehold og manualer',
                originalLabel: 'Kjøretøy: vedlikehold og manualer',
                alternativeLabels: [],
                note: 'Se også TRCS <a href="/thema/nb/TRCS">Bilmekanikerfaget</a>',
                related: [
                  {
                    id: 'TRCS',
                    label: 'Bilmekanikerfaget',
                    originalLabel: 'Bilmekanikerfaget',
                    note: 'Se også WGCV <a href="/thema/nb/WGCV">Kjøretøy: vedlikehold og manualer</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'WGD',
            label: 'Sykler og ikke-motorisert transport',
            originalLabel: 'Sykler og ikke-motorisert transport',
            alternativeLabels: [],
            note: 'Her: elsykler, elsparkesykler, guider til sykkelreparasjon. Se også SMQ <a href="/thema/nb/SMQ">Sykkelsport</a>, SZD <a href="/thema/nb/SZD">Sykling</a>',
            related: [
              {
                id: 'SMQ',
                label: 'Sykkelsport',
                originalLabel: 'Sykkelsport',
                note: 'Her: sykkelløp på vei og velodrom (bane); Tour de France etc. Se også SZD <a href="/thema/nb/SZD">Sykling</a>, WGD <a href="/thema/nb/WGD">Sykler og ikke-motorisert transport</a>',
              },
              {
                id: 'SZD',
                label: 'Sykling',
                originalLabel: 'Sykling',
                note: 'Brukes med WTH*-koder for reisehåndbøker og sykkelturer. Se også SMQ <a href="/thema/nb/SMQ">Sykkelsport</a>, WGD <a href="/thema/nb/WGD">Sykler og ikke-motorisert transport</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WGF',
            label: 'Tog og jernbane',
            originalLabel: 'Tog og jernbane',
            alternativeLabels: [],
            note: 'Her: bedriftshistorie, kart og atlas, generelle verk om jernbaner eller jernbanestrekninger. Brukes med NH* for historiske bøker og AMG for bøker om jernbanestasjoner, RPT for planlegging av nye jernbanestrekninger etc. Se også TRF* <a href="/thema/nb/TRF">Jernbaneteknologi, -teknikk og -næringer</a> og dens underkategorier',
            related: [
              {
                id: 'TRF',
                label: 'Jernbaneteknologi, -teknikk og -næringer',
                originalLabel: 'Jernbaneteknologi, -teknikk og -næringer',
              },
            ],
            children: [
              {
                id: 'WGFD',
                label: 'Lokomotiv og skinnegående materiell',
                originalLabel: 'Lokomotiv og skinnegående materiell',
                alternativeLabels: [],
                note: 'Her: damp-, diesel- eller elektriske lokomotiv, godsvogner, passasjervogner, motorer etc. eller bøker om berømte, navngitte tog eller lokomotiv, bøker om lyden av damplokomotiv etc.',
                related: [],
                children: [],
              },
              {
                id: 'WGFL',
                label: 'Skinnegående bytrafikk',
                originalLabel: 'Skinnegående bytrafikk',
                alternativeLabels: [],
                note: 'Her: undergrunnsbaner, T-bane (metro), trikk, énskinnebaner, og andre urbane transportsystemer på skinner',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WGG',
            label: 'Skip og båter',
            originalLabel: 'Skip og båter',
            alternativeLabels: ['havner', 'vannveier', 'småbåthavner'],
            note: 'Her: skip og båter som seiler på elver, innsjøer, havstrekninger eller kanaler, andre fartøy som er vanngående, havner, marinaer og amfibiekjøretøy som luftputefartøy, amfibiske ATV-er. Brukes med JWMV / JWCK for militærskip og -båter eller marinen. Se også NHTM <a href="/thema/nb/NHTM">Maritim historie</a>, SPN* <a href="/thema/nb/SPN">Båter: sport og fritid</a> og dens underkategorier',
            related: [
              {
                id: 'NHTM',
                label: 'Maritim historie',
                originalLabel: 'Maritim historie',
                note: 'Her: sjøfartshistorie om sjøreiser, sjømenn, sjørøveri, hvalfangst, fiskeri, navigasjon, handel, utforskning og oppdagelser, shipping, kystsamfunn, marinen etc. Brukes for historisk behandling av alle aspekter ved menneskelig samhandling eller aktivitet på sjøen. Se også JWCK <a href="/thema/nb/JWCK">Sjøstyrker og krigføring</a>',
              },
              {
                id: 'SPN',
                label: 'Båter: sport og fritid',
                originalLabel: 'Båter: sport og fritid',
              },
            ],
            children: [
              {
                id: 'WGGB',
                label: 'Båter',
                originalLabel: 'Båter',
                alternativeLabels: [],
                note: 'Her: småbåter, robåter, kanalbåter, lektere, lystbåter, husbåter, seilbåter. Brukes for båter som først og fremst er ment brukt på innsjøer, elver eller ved kysten',
                related: [],
                children: [],
              },
              {
                id: 'WGGD',
                label:
                  'Skip: passasjer- og cruiseskip og andre havgående fartøy',
                originalLabel:
                  'Skip: passasjer- og cruiseskip og andre havgående fartøy',
                alternativeLabels: ['gallioner', 'brigger', 'karaveller'],
                note: 'Her: seilskip. Brukes for historiske bøker om denne typen skip, rederier, større havgående fartøy etc. og titler om berømte, navngitte skip eller skipsvrak som RMS Titanic, Blücher, DS Donau',
                related: [],
                children: [],
              },
              {
                id: 'WGGP',
                label: 'Skip og båter: sertifisering og opplæring',
                originalLabel: 'Skip og båter: sertifisering og opplæring',
                alternativeLabels: [],
                note: 'Her: båtførerprøven, sertifisering for å kunne kjøre småbåter, seileopplæring etc. Se også TRLN <a href="/thema/nb/TRLN">Navigering og sjømannskap</a>, TRLT <a href="/thema/nb/TRLT">Maritime bransjer</a>',
                related: [
                  {
                    id: 'TRLN',
                    label: 'Navigering og sjømannskap',
                    originalLabel: 'Navigering og sjømannskap',
                  },
                  {
                    id: 'TRLT',
                    label: 'Maritime bransjer',
                    originalLabel: 'Maritime bransjer',
                  },
                ],
                children: [],
              },
              {
                id: 'WGGV',
                label: 'Båtbygging og vedlikehold',
                originalLabel: 'Båtbygging og vedlikehold',
                alternativeLabels: [],
                note: 'Her: båtdesign, båtkonstruksjon, reparasjon av båter. Se også TRL* <a href="/thema/nb/TRL">Skipsbygging: teknologi, teknikk og næring</a> og dens underkategorier',
                related: [
                  {
                    id: 'TRL',
                    label: 'Skipsbygging: teknologi, teknikk og næring',
                    originalLabel: 'Skipsbygging: teknologi, teknikk og næring',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'WGM',
            label: 'Luftfartøy og luftfart',
            originalLabel: 'Luftfartøy og luftfart',
            alternativeLabels: ['fly', 'helikoptere'],
            note: 'Her: kommersiell luftfart, flyselskap, flyplasser, luftskip etc. Brukes med JWMV / JWCM for militærfly eller militær luftfart. Se også TRP* <a href="/thema/nb/TRP">Romfarts- og luftfartsteknologi</a> og dens underkategorier',
            related: [
              {
                id: 'TRP',
                label: 'Romfarts- og luftfartsteknologi',
                originalLabel: 'Romfarts- og luftfartsteknologi',
                note: 'Her: ubemannede luftfartøy og droneteknologi, luftfarts- og romfartsteknologi, avionikk',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'WH',
        label: 'Humor',
        originalLabel: 'Humor',
        alternativeLabels: [],
        note: 'Brukes for generelle verk om humor eller bøker som tar for seg et emne på en humoristisk måte. Bruk WH* med andre kategorier for å angi emne, men angi WH som den viktigste koden for å signalisere at det primært er snakk om en humoristisk utgivelse. Se også XY <a href="/thema/nb/XY">Tegneseriestriper</a>',
        related: [
          {
            id: 'XY',
            label: 'Tegneseriestriper',
            originalLabel: 'Tegneseriestriper',
            note: 'Her: samlinger av tegneseriestriper, gjerne humoristiske med 3–5 ruter, som vanligvis har vært publisert i aviser eller blader, samt enkeltstående tegnede ruter',
          },
        ],
        children: [
          {
            id: 'WHG',
            label: 'Media-relatert humor',
            originalLabel: 'Media-relatert humor',
            alternativeLabels: [],
            note: 'Her: TV-relatert humor, digital humor. Brukes for humoristiske bøker som er inspirert av eller hentet fra mediene, inkludert film, TV, radio, internett, sosiale medier, andre medier eller digitale plattformer',
            related: [],
            children: [],
          },
          {
            id: 'WHJ',
            label: 'Vitser og gåter',
            originalLabel: 'Vitser og gåter',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'WHL',
            label: 'Humor: slang og dialekter',
            originalLabel: 'Humor: slang og dialekter',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'WHP',
            label: 'Parodier',
            originalLabel: 'Parodier',
            alternativeLabels: [],
            note: 'Se også FUP <a href="/thema/nb/FUP">Satire og parodier</a>',
            related: [
              {
                id: 'FUP',
                label: 'Satire og parodier',
                originalLabel: 'Satire og parodier',
                note: 'Brukes for humoristisk skjønnlitteratur som er satirisk og/eller som parodierer annen skjønnlitteratur, sjanger eller forfattere. Se også WHP <a href="/thema/nb/WHP">Parodier</a>, FYH <a href="/thema/nb/FYH">Pastisj</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WHX',
            label: 'Humorsamlinger og antologier',
            originalLabel: 'Humorsamlinger og antologier',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'WJ',
        label: 'Livsstil og personlig utseende',
        originalLabel: 'Livsstil og personlig utseende',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'WJF',
            label: 'Mote- og stilguider',
            originalLabel: 'Mote- og stilguider',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'WJH',
            label: 'Sminke, hår og skjønnhet',
            originalLabel: 'Sminke, hår og skjønnhet',
            alternativeLabels: [
              'frisyrer',
              'hårfrisyrer',
              'fletter',
              'fletting',
            ],
            note: 'Her: spabehandling, makeup, hudpleie, hårpleie, skjønnhetsbehandling, personlig pleie, manikyr, pedikyr, hårfjerning, barbering etc. Brukes med WF for hobbybøker om å lage produkter som såpe og andre kosmetikkprodukter. Se også TTVH <a href="/thema/nb/TTVH">Frisøryrket, skjønnhetsbehandlinger og salongarbeid</a>, VFM <a href="/thema/nb/VFM">Trening og kosthold</a>, YPWC5 <a href="/thema/nb/YPWC5">Læremidler: frisørfag og skjønnhetsbehandling</a>',
            related: [
              {
                id: 'TTVH',
                label: 'Frisøryrket, skjønnhetsbehandlinger og salongarbeid',
                originalLabel:
                  'Frisøryrket, skjønnhetsbehandlinger og salongarbeid',
                note: 'Her: profesjonell makeup, kosmetologi, barbering, hårdesign og -stell, hudpleie, massasje, manikyr, pedikyr og alle bøker som er rettet mot yrkesutøvere innen disse områdene. Brukes med kvalifikatorer fra 4CP* der det er hensiktsmessig, og med YPWC5 for bøker med for videregående skole',
              },
              {
                id: 'VFM',
                label: 'Trening og kosthold',
                originalLabel: 'Trening og kosthold',
              },
              {
                id: 'YPWC5',
                label: 'Læremidler: frisørfag og skjønnhetsbehandling',
                originalLabel: 'Læremidler: frisørfag og skjønnhetsbehandling',
                note: 'Brukes for elever som tar utdanning innen kosmetologi, hudpleie, hårpleie, barbering, makeup og andre fagområder som har med skjønnhetsbehandling å gjøre. Se også TTVH <a href="/thema/nb/TTVH">Frisøryrket, skjønnhetsbehandlinger og salongarbeid</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WJJ',
            label: 'Parfyme',
            originalLabel: 'Parfyme',
            alternativeLabels: [],
            note: 'Her: parfymer og dufter',
            related: [],
            children: [],
          },
          {
            id: 'WJK',
            label: 'Interiørdesign og dekor',
            originalLabel: 'Interiørdesign og dekor',
            alternativeLabels: [],
            note: 'Her: innredning for allmennmarkedet. Se også AMR <a href="/thema/nb/AMR">Arkitektur: interiørdesign</a>',
            related: [
              {
                id: 'AMR',
                label: 'Arkitektur: interiørdesign',
                originalLabel: 'Arkitektur: interiørdesign',
                note: 'Her: bøker for profesjonsmarkedet, inkludert studier av interiørarkitektur og -design, lysdesign, interiørdesign for privatboliger og næringsbygg og andre spesialområder innen fagfeltet. Brukes med AKB for verker om spesifikke interiørdesignere. Foretrekk WJK som hovedkategori for verk som ikke er ment for profesjonsmarkedet. Se også WJK <a href="/thema/nb/WJK">Interiørdesign og dekor</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WJS',
            label: 'Kjøpeguider',
            originalLabel: 'Kjøpeguider',
            alternativeLabels: [],
            note: 'Se også UDBA <a href="/thema/nb/UDBA">Handel og auksjoner på internett</a>, VSG <a href="/thema/nb/VSG">Forbrukerrådgivning</a>',
            related: [
              {
                id: 'UDBA',
                label: 'Handel og auksjoner på internett',
                originalLabel: 'Handel og auksjoner på internett',
                note: 'Brukes for bøker for allmennmarkedet som handler om e-handel, netthandel, digitale markedsplasser',
              },
              {
                id: 'VSG',
                label: 'Forbrukerrådgivning',
                originalLabel: 'Forbrukerrådgivning',
                note: 'Se også WJS <a href="/thema/nb/WJS">Kjøpeguider</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WJW',
            label: 'Bryllup og bryllupsplanlegging',
            originalLabel: 'Bryllup og bryllupsplanlegging',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'WJX',
            label: 'Fester, etikette og underholdning',
            originalLabel: 'Fester, etikette og underholdning',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'WJXC',
                label: 'Skikk og bruk',
                originalLabel: 'Skikk og bruk',
                alternativeLabels: [],
                note: 'Her: regler for skikk og bruk og vanlig folkeskikk, etikette, samhandling med andre, råd for hvordan man kan unngå å være uhøflig eller bli oppfattet som fornærmende, råd for tverrkulturelle møter',
                related: [],
                children: [],
              },
              {
                id: 'WJXF',
                label: 'Borddekking',
                originalLabel: 'Borddekking',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WJY',
            label: 'Tradisjonelle ritualer og seremonier',
            originalLabel: 'Tradisjonelle ritualer og seremonier',
            alternativeLabels: [],
            note: 'Her: velkomstseremonier, japanske estetiske kunster. Brukes for bøker om ritualer, seremonier og etikette for allmennmarkedet. Brukes med andre emnekoder for å angi type seremoni, f.eks. WBXN1 for en tradisjonell japansk teseremoni eler WFW for Ikebana. Se også JBCC6 <a href="/thema/nb/JBCC6">Kulturstudier: skikker og tradisjoner</a>',
            related: [
              {
                id: 'JBCC6',
                label: 'Skikker og tradisjoner',
                originalLabel: 'Kulturstudier: skikker og tradisjoner',
                note: 'Her: tradisjonelle ritualer og seremonier, kulturelle tabuer og overtro, oppførsel, verdier og normer slik disse er definert av samfunnet, institusjoner, tradisjoner og kultursyn, studier av immateriell kulturarv, tradisjonelle kulturelle uttrykk, etc. Se også JBGB <a href="/thema/nb/JBGB">Folklore, myter og legender</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'WK',
        label: 'Hjem og vedlikehold av hus',
        originalLabel: 'Hjem og vedlikehold av hus',
        alternativeLabels: [],
        note: 'Se også TTVT <a href="/thema/nb/TTVT">Vaktmestere, husholdersker, renholdsarbeidere etc.</a>, VSH <a href="/thema/nb/VSH">Bolig og eiendom for enkeltindividet: kjøp/salg og juridiske spørsmål</a>',
        related: [
          {
            id: 'TTVT',
            label: 'Vaktmestere, husholdersker, renholdsarbeidere etc.',
            originalLabel: 'Vaktmestere, husholdersker, renholdsarbeidere etc.',
            note: 'Brukes for bøker for profesjonsmarkedet for yrker hvor man har ansvaret for bygninger, hushjelper etc.',
          },
          {
            id: 'VSH',
            label:
              'Bolig og eiendom for enkeltindividet: kjøp/salg og juridiske spørsmål',
            originalLabel:
              'Bolig og eiendom for enkeltindividet: kjøp/salg og juridiske spørsmål',
            note: 'Her: råd om å leie eller leie ut bolig, flytting, eiendomstyper. Se også WK* <a href="/thema/nb/WK">Hjem og vedlikehold av hus</a> og dens underkategorier',
          },
        ],
        children: [
          {
            id: 'WKD',
            label: 'Gjør det selv',
            originalLabel: 'Gjør det selv',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'WKDM',
                label: 'Gjør det selv: vedlikeholdsmanualer',
                originalLabel: 'Gjør det selv: vedlikeholdsmanualer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WKDW',
                label: 'Gjør det selv: bygge- og snekkerarbeid',
                originalLabel: 'Gjør det selv: bygge- og snekkerarbeid',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WKH',
            label: 'Husholdningstips',
            originalLabel: 'Husholdningstips',
            alternativeLabels: ['rydde', 'rydding'],
            note: 'Her: rengjøringstips, tips om opprydning og hvordan man kan organisere hjemmet sitt',
            related: [],
            children: [],
          },
          {
            id: 'WKR',
            label: 'Oppussing og påbygg',
            originalLabel: 'Oppussing og påbygg',
            alternativeLabels: [],
            note: 'Se også WJK <a href="/thema/nb/WJK">Interiørdesign og dekor</a>',
            related: [
              {
                id: 'WJK',
                label: 'Interiørdesign og dekor',
                originalLabel: 'Interiørdesign og dekor',
                note: 'Her: innredning for allmennmarkedet. Se også AMR <a href="/thema/nb/AMR">Arkitektur: interiørdesign</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WKU',
            label: 'Utendørs- og rekreasjonsområder: design og vedlikehold',
            originalLabel:
              'Utendørs- og rekreasjonsområder: design og vedlikehold',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'WM',
        label: 'Hagearbeid',
        originalLabel: 'Hagearbeid',
        alternativeLabels: [],
        note: 'Bruk WM* med kvalifikatorer fra 1QM* for bøker om hagearbeid i ulike klimaregioner, eller andre kvalifikatorer for sted for regionale guider. Se også TVS <a href="/thema/nb/TVS">Kommersielt hagebruk</a>',
        related: [
          {
            id: 'TVS',
            label: 'Kommersielt hagebruk',
            originalLabel: 'Kommersielt hagebruk',
            note: 'Her: gartnerier, blomsterproduksjon, grønnsaks- og fruktproduksjon, pomologi, torvhåndtering. Brukes for bøker som handler om kommersiell dyrking og salg av frukt, nøtter, grønnsaker og planter, og praksis og teknikker som forbindes med dette. Se også WM* <a href="/thema/nb/WM">Hagearbeid</a> og dens underkategorier',
          },
        ],
        children: [
          {
            id: 'WMB',
            label: 'Hager (beskrivelse, historie, etc.)',
            originalLabel: 'Hager (beskrivelse, historie, etc.)',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'WMD',
            label: 'Hagedesign og -planlegging',
            originalLabel: 'Hagedesign og -planlegging',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'WMF',
            label: 'Drivhus, vinterhager, uteplasser',
            originalLabel: 'Drivhus, vinterhager, uteplasser',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'WMP',
            label: 'Planter og dyrking',
            originalLabel: 'Hagearbeid: planter og dyrking: guider',
            alternativeLabels: [],
            note: 'Her: dyrking av urter, sukkulenter og kaktuser. Brukes for titler om det å dyrke denne typen planter eller titler som tar for seg stue- og hageplanter. Brukes med WMQR* for stueplanter',
            related: [],
            children: [
              {
                id: 'WMPC',
                label: 'Blomster og prydplanter',
                originalLabel: 'Hagearbeid: blomster og prydplanter',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WMPF',
                label: 'Frukt og grønnsaker',
                originalLabel: 'Hagearbeid: frukt og grønnsaker',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WMPS',
                label: 'Trær og busker',
                originalLabel: 'Hagearbeid: trær og busker',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WMPY',
                label: 'Skadedyr og sykdommer',
                originalLabel: 'Hagearbeid: skadedyr og sykdommer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WMQ',
            label: 'Spesielle hagetyper og metoder',
            originalLabel: 'Spesielle hagetyper og metoder',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'WMQB',
                label: 'Bonsai',
                originalLabel: 'Bonsai',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WMQF',
                label: 'Økologisk / bærekraftig hagebruk',
                originalLabel: 'Økologisk / bærekraftig hagebruk',
                alternativeLabels: [],
                note: 'Her: guider til lokale planter og blomster, biodynamisk hagebruk, oppsamling av regnvann, planter som er motstandsdyktige mot tørke, permakultur i hagebruk. Brukes for bøker som handler om miljøvennlig hagebruk og landskapsarbeid, hager og hagearbeid med lavt miljøavtrykk, hagearbeid hvor man bruker et minimum av kjemiske stoff etc.',
                related: [],
                children: [],
              },
              {
                id: 'WMQL',
                label: 'Landskapsarkitektur',
                originalLabel: 'Landskapsarkitektur',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WMQN',
                label: 'Naturhager',
                originalLabel: 'Hagearbeid: naturhager',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WMQP',
                label: 'Hager med lokale planter',
                originalLabel: 'Hager med lokale planter',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WMQR',
                label: 'Krukker, potter og kasser',
                originalLabel: 'Hagearbeid: krukker, potter og kasser',
                alternativeLabels: [],
                note: 'Use for: titles about growing plants in pots, tubs, baskets, etc indoors or outdoors',
                related: [],
                children: [
                  {
                    id: 'WMQR1',
                    label: 'Innendørs plantedyrking',
                    originalLabel: 'Innendørs plantedyrking',
                    alternativeLabels: ['terrarium'],
                    note: 'Her: stueplanter',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'WMQW',
                label: 'Vannhager, bassenger',
                originalLabel: 'Vannhager, bassenger',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WMT',
            label: 'Parseller og kolonihager',
            originalLabel: 'Parseller og kolonihager',
            alternativeLabels: [],
            note: 'Se også TVM <a href="/thema/nb/TVM">Småbruk</a>, TVU <a href="/thema/nb/TVU">Urbant landbruk</a>',
            related: [
              {
                id: 'TVM',
                label: 'Småbruk',
                originalLabel: 'Småbruk',
              },
              {
                id: 'TVU',
                label: 'Urbant landbruk',
                originalLabel: 'Urbant landbruk',
                note: 'Brukes med TVH (Husdyrhold) eller andre TV*-koder der det er hensiktsmessig',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'WN',
        label: 'Natur',
        originalLabel: 'Natur',
        alternativeLabels: [],
        note: 'Her: populærvitenskapelige bøker om naturhistorie, ulike aspekter ved naturlige landskap eller naturens verden. Bruk WN*-koder på bøker for allmennmarkedet. Foretrekk koder fra seksjonene P, R, T etc., på bøker for høyere utdanning og profesjonsmarkedet',
        related: [],
        children: [
          {
            id: 'WNA',
            label: 'Dinosaurer og forhistorisk tid',
            originalLabel: 'Dinosaurer og forhistorisk tid',
            alternativeLabels: [],
            note: 'Her: utdødd megafauna, forhistoriske livsformer, pattedyr, mammut etc. Brukes med kvalifikatorer fra 3A* der det er hensiktsmessig. Se også RBX <a href="/thema/nb/RBX">Paleontologi</a>',
            related: [
              {
                id: 'RBX',
                label: 'Paleontologi',
                originalLabel: 'Paleontologi',
              },
            ],
            children: [],
          },
          {
            id: 'WNC',
            label: 'Ville dyr',
            originalLabel: 'Ville dyr',
            alternativeLabels: [],
            note: 'Se også PSV* <a href="/thema/nb/PSV">Zoologi og dyrevitenskap</a> og dens underkategorier',
            related: [
              {
                id: 'PSV',
                label: 'Zoologi og dyrevitenskap',
                originalLabel: 'Zoologi og dyrevitenskap',
                note: 'Her: fysiologi, forplantning, sykdommer hos dyr. Se også WNC <a href="/thema/nb/WNC">Ville dyr</a>, MZ* <a href="/thema/nb/MZ">Veterinærmedisin / dyremedisin</a> og dens underkategorier',
              },
            ],
            children: [
              {
                id: 'WNCB',
                label: 'Fugler og fugletitting',
                originalLabel: 'Ville dyr: fugler og fugletitting',
                alternativeLabels: [],
                note: 'Se også PSVJ <a href="/thema/nb/PSVJ">Zoologi: fugler</a>',
                related: [
                  {
                    id: 'PSVJ',
                    label: 'Zoologi: fugler',
                    originalLabel: 'Zoologi: fugler',
                    note: 'Her: ornitologi. Se også WNCB <a href="/thema/nb/WNCB">Ville dyr: fugler og fugletitting</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'WNCF',
                label: 'Pattedyr',
                originalLabel: 'Ville dyr: pattedyr',
                alternativeLabels: [],
                note: 'Se også PSVM <a href="/thema/nb/PSVM">Zoologi: pattedyr</a>',
                related: [
                  {
                    id: 'PSVM',
                    label: 'Zoologi: pattedyr',
                    originalLabel: 'Zoologi: pattedyr',
                    note: 'Her: mammalogi. Se også WNCF <a href="/thema/nb/WNCF">Ville dyr: pattedyr</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'WNCK',
                label: 'Reptiler og amfibier',
                originalLabel: 'Ville dyr: reptiler og amfibier',
                alternativeLabels: ['krokodiller'],
                note: 'Se også PSVF <a href="/thema/nb/PSVF">Zoologi: amfibier og reptiler</a>',
                related: [
                  {
                    id: 'PSVF',
                    label: 'Zoologi: amfibier og reptiler',
                    originalLabel: 'Zoologi: amfibier og reptiler',
                    note: 'Her: herpetologi. Se også WNCK <a href="/thema/nb/WNCK">Ville dyr: reptiler og amfibier</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'WNCN',
                label: 'Insekter, sommerfugler og edderkopper',
                originalLabel:
                  'Ville dyr: insekter, sommerfugler og edderkopper',
                alternativeLabels: [],
                note: 'Se også PSVA <a href="/thema/nb/PSVA">Zoologi: virvelløse dyr</a>',
                related: [
                  {
                    id: 'PSVA',
                    label: 'Zoologi: virvelløse dyr',
                    originalLabel: 'Zoologi: virvelløse dyr',
                    note: 'Her: leddormer, mangefotinger, pigghuder (sjøstjerner, sjøpiggsvin), maneter. Se også WNCN <a href="/thema/nb/WNCN">Ville dyr: insekter, sommerfugler og edderkopper</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'WNCS',
                label: 'Dyr som lever i vann',
                originalLabel: 'Ville dyr: dyr som lever i vann',
                alternativeLabels: [],
                note: 'Se også PSP <a href="/thema/nb/PSP">Hydrobiologi</a>, PSVC <a href="/thema/nb/PSVC">Zoologi: fisk</a>, PSVM2 <a href="/thema/nb/PSVM2">Zoologi: sjøpattedyr</a>',
                related: [
                  {
                    id: 'PSP',
                    label: 'Hydrobiologi',
                    originalLabel: 'Hydrobiologi',
                    note: 'Se også RBK <a href="/thema/nb/RBK">Hydrosfære og hydrologi</a>',
                  },
                  {
                    id: 'PSVC',
                    label: 'Zoologi: fisk',
                    originalLabel: 'Zoologi: fisk',
                    note: 'Her: iktyologi. Se også WNCS <a href="/thema/nb/WNCS">Ville dyr: dyr som lever i vann</a>',
                  },
                  {
                    id: 'PSVM2',
                    label: 'Zoologi: sjøpattedyr',
                    originalLabel: 'Zoologi: sjøpattedyr',
                    note: 'Her: hvaler, delfiner, sjøkuer etc. Brukes for marine og semimarine pattedyr, både i saltvann og ferskvann. Se også PSVM <a href="/thema/nb/PSVM">Zoologi: pattedyr</a>, WNCS <a href="/thema/nb/WNCS">Ville dyr: dyr som lever i vann</a>',
                  },
                ],
                children: [
                  {
                    id: 'WNCS1',
                    label: 'Dyr som lever i saltvann og livet i fjæra',
                    originalLabel: 'Dyr som lever i saltvann og livet i fjæra',
                    alternativeLabels: [],
                    note: 'Se også PSPM <a href="/thema/nb/PSPM">Marinbiologi</a>',
                    related: [
                      {
                        id: 'PSPM',
                        label: 'Marinbiologi',
                        originalLabel: 'Marinbiologi',
                        note: 'Her: saltvannsbiologi. Se også RBKC <a href="/thema/nb/RBKC">Oseanografi (hav)</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'WNCS2',
                    label: 'Dyr som lever i ferskvann',
                    originalLabel: 'Dyr som lever i ferskvann',
                    alternativeLabels: [],
                    note: 'Se også PSPF <a href="/thema/nb/PSPF">Ferskvannsbiologi</a>',
                    related: [
                      {
                        id: 'PSPF',
                        label: 'Ferskvannsbiologi',
                        originalLabel: 'Ferskvannsbiologi',
                        note: 'Se også RBKF <a href="/thema/nb/RBKF">Limnologi (innsjøer og rennende vann)</a>',
                      },
                    ],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'WND',
            label: 'Landsbygda, livet på landet',
            originalLabel: 'Landsbygda, livet på landet',
            alternativeLabels: ['bygder'],

            related: [],
            children: [],
          },
          {
            id: 'WNF',
            label: 'Gårdsbruk og arbeidsdyr',
            originalLabel: 'Gårdsbruk og arbeidsdyr',
            alternativeLabels: [
              'gårdsdyr',
              'bufe',
              'kyr',
              'geiter',
              'sauer',
              'griser',
              'kylling',
              'høns',
              'gjess',
              'kalkun',
            ],
            note: 'Brukes for titler om brukshunder og servicehunder. Se også TVH <a href="/thema/nb/TVH">Husdyrhold</a>',
            related: [
              {
                id: 'TVH',
                label: 'Husdyrhold',
                originalLabel: 'Husdyrhold',
                note: 'Her: dyrehold, bufe, produksjonsdyr. Se også WNF <a href="/thema/nb/WNF">Gårdsbruk og arbeidsdyr</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WNG',
            label: 'Husdyr og kjæledyr',
            originalLabel: 'Husdyr og kjæledyr',
            alternativeLabels: [],
            note: 'Her: fôring, ernæring, helse og dyrestell, matlaging for husdyr og kjæledyr',
            related: [],
            children: [
              {
                id: 'WNGC',
                label: 'Katter som kjæledyr',
                originalLabel: 'Katter som kjæledyr',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WNGD',
                label: 'Hunder som kjæledyr',
                originalLabel: 'Hunder som kjæledyr',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'WNGD1',
                    label: 'Hundedressur og -trening',
                    originalLabel: 'Hundedressur og -trening',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'WNGF',
                label: 'Fisker som kjæledyr og akvarier',
                originalLabel: 'Fisker som kjæledyr og akvarier',
                alternativeLabels: ['akvarium'],

                related: [],
                children: [],
              },
              {
                id: 'WNGH',
                label: 'Hester og ponnier',
                originalLabel: 'Hester og ponnier',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WNGK',
                label: 'Fugler, inkludert burfugler, som kjæledyr',
                originalLabel: 'Fugler, inkludert burfugler, som kjæledyr',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WNGR',
                label: 'Kaniner og gnagere som kjæledyr',
                originalLabel: 'Kaniner og gnagere som kjæledyr',
                alternativeLabels: [],
                note: 'Her: mus, rotter, hamstere, marsvin, ørkenrotte etc. som kjæledyr',
                related: [],
                children: [],
              },
              {
                id: 'WNGS',
                label: 'Reptiler og amfibier som kjæledyr',
                originalLabel: 'Reptiler og amfibier som kjæledyr',
                alternativeLabels: ['krokodiller'],
                note: 'Her: slanger, øgler, salamandere, skilpadder etc. som kjæledyr',
                related: [],
                children: [],
              },
              {
                id: 'WNGX',
                label: 'Insekter og edderkopper som kjæledyr',
                originalLabel: 'Insekter og edderkopper som kjæledyr',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WNH',
            label: 'Dyrehager og naturparker',
            originalLabel: 'Dyrehager og naturparker',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'WNJ',
            label: 'Nasjonalparker og naturreservater',
            originalLabel: 'Nasjonalparker og naturreservater',
            alternativeLabels: [],
            note: 'Brukes med kvalifikator(er) for geografi, samt WT*-koder for reiser for guidebøker til nasjonalparker der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'WNP',
            label: 'Trær, villblomster og planter',
            originalLabel: 'Trær, villblomster og planter',
            alternativeLabels: ['flora'],
            note: 'Se også PST* <a href="/thema/nb/PST">Botanikk og plantevitenskap</a> og dens underkategorier, WMP <a href="/thema/nb/WMP">Hagearbeid: planter og dyrking: guider</a>',
            related: [
              {
                id: 'PST',
                label: 'Botanikk og plantevitenskap',
                originalLabel: 'Botanikk og plantevitenskap',
              },
              {
                id: 'WMP',
                label: 'Planter og dyrking',
                originalLabel: 'Hagearbeid: planter og dyrking: guider',
                note: 'Her: dyrking av urter, sukkulenter og kaktuser. Brukes for titler om det å dyrke denne typen planter eller titler som tar for seg stue- og hageplanter. Brukes med WMQR* for stueplanter',
              },
            ],
            children: [
              {
                id: 'WNPB',
                label: 'Spiselige ville planter og sanking',
                originalLabel: 'Spiselige ville planter og sanking',
                alternativeLabels: ['matauk'],
                note: 'Her: bøker om innhøsting av matressurser fra naturen, guider til hvordan man kan samle mat i naturen, spiselige ville planter, sopp etc. Brukes med WB* for matlaging, VX* for tradisjonell medisin etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WNR',
            label: 'Steiner, mineraler og fossiler',
            originalLabel: 'Steiner, mineraler og fossiler',
            alternativeLabels: [],
            note: 'Her: verk for allmennmarkedet om geologi, inkludert håndbøker om edelsteiner. Brukes sammen med koder for relevant disiplin for å indikere emne. Se også PNV <a href="/thema/nb/PNV">Mineralogi, krystaller og edelstener</a>, RBX <a href="/thema/nb/RBX">Paleontologi</a>, RBG* <a href="/thema/nb/RBG">Geologi, geomorfologi og litosfæren</a> og dens underkategorier',
            related: [
              {
                id: 'PNV',
                label: 'Mineralogi, krystaller og edelstener',
                originalLabel: 'Mineralogi, krystaller og edelstener',
              },
              {
                id: 'RBG',
                label: 'Geologi, geomorfologi og litosfæren',
                originalLabel: 'Geologi, geomorfologi og litosfæren',
                note: 'Se også PHVG <a href="/thema/nb/PHVG">Geofysikk</a>, RGB <a href="/thema/nb/RGB">Fysisk geografi og topografi</a>',
              },
              {
                id: 'RBX',
                label: 'Paleontologi',
                originalLabel: 'Paleontologi',
              },
            ],
            children: [],
          },
          {
            id: 'WNS',
            label: 'Lyder fra naturen',
            originalLabel: 'Lyder fra naturen',
            alternativeLabels: [],
            note: 'Brukes for bøker som handler om eller som har funksjonalitet som gjør at man kan høre lyder fra naturen, bl.a. ulike dyrelyder, fuglesang, hvordan dyr kommuniserer gjennom lyd, dyrespråk, lyder av vær, vann etc. Brukes med andre koder f.eks. WNCB for fuglesang, WNWM for værlyder, WNCS for hvalsang etc.',
            related: [],
            children: [],
          },
          {
            id: 'WNW',
            label: 'Jorda: naturhistorie',
            originalLabel: 'Jorda: naturhistorie',
            alternativeLabels: [],
            note: 'Her: verk for allmennmarkedet om jordas naturhistorie, fysiske egenskaper og system, samt verk om livet på jorda. Her: verk om vulkaner, jordskjelv etc. for allmennmarkedet. Se også RB* <a href="/thema/nb/RB">Geovitenskap</a> og dens underkategorier, PSAF <a href="/thema/nb/PSAF">Økologi, biosfæren</a>',
            related: [
              {
                id: 'PSAF',
                label: 'Økologi, biosfæren',
                originalLabel: 'Økologi, biosfæren',
                note: 'Brukes for omfattende vitenskapelige, objektive og beskrivende verk om levende organismer og deres forhold til omgivelsene. Se også RNC <a href="/thema/nb/RNC">Anvendt økologi</a>',
              },
              {
                id: 'RB',
                label: 'Geovitenskap',
                originalLabel: 'Geovitenskap',
                note: 'Bruk RB*-koder for lærebøker for profesjonsmarkedet og høyere utdanning. Bruk WN*-koder for bøker for allmenmarkedet. Se også RGB* <a href="/thema/nb/RGB">Fysisk geografi og topografi</a> og dens underkategorier, WN* <a href="/thema/nb/WN">Natur</a> og dens underkategorier',
              },
            ],
            children: [
              {
                id: 'WNWM',
                label: 'Vær og klima',
                originalLabel: 'Vær og klima',
                alternativeLabels: ['været'],
                note: 'Her: verk for allmennmarkedet om vær og klima, inkludert stormer og andre ekstreme værforhold. Se også RBP* <a href="/thema/nb/RBP">Meteorologi og klimatologi</a> og dens underkategorier',
                related: [
                  {
                    id: 'RBP',
                    label: 'Meteorologi og klimatologi',
                    originalLabel: 'Meteorologi og klimatologi',
                    note: 'Brukes for bøker om værfenomener og atmosfæriske fenomener. Se også WNWM <a href="/thema/nb/WNWM">Vær og klima</a>, RNPG <a href="/thema/nb/RNPG">Klimaendringer</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'WNX',
            label: 'Astronomi og verdensrommet',
            originalLabel: 'Astronomi og verdensrommet',
            alternativeLabels: ['universet'],
            note: 'Her: stjernetegn og stjerneatlas, samt astronomi, verdensrommet og romfart for allmennmarkedet. Se også PG* <a href="/thema/nb/PG">Astronomi, sted og tid</a> og dens underkategorier, TTD <a href="/thema/nb/TTD">Romforskning</a>',
            related: [
              {
                id: 'PG',
                label: 'Astronomi, sted og tid',
                originalLabel: 'Astronomi, sted og tid',
                note: 'Se også TTD* <a href="/thema/nb/TTD">Romforskning</a> og dens underkategorier',
              },
              {
                id: 'TTD',
                label: 'Romforskning',
                originalLabel: 'Romforskning',
                note: 'Se også WNX <a href="/thema/nb/WNX">Astronomi og verdensrommet</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'WQ',
        label: 'Lokalhistorie og slekt, nostalgia',
        originalLabel: 'Lokalhistorie og slekt, nostalgia',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'WQH',
            label: 'Lokalhistorie',
            originalLabel: 'Lokalhistorie',
            alternativeLabels: [],
            note: 'Her: verk for allmennmarkedet om historie lokalisert til et bestemt sted, vanligvis bare av lokal interesse. Brukes med kvalifikatorer for geografi og tidsperiode der det er mulig',
            related: [],
            children: [],
          },
          {
            id: 'WQN',
            label: 'Nostalgi',
            originalLabel: 'Nostalgi',
            alternativeLabels: [],
            note: 'Her: verk for allmennmarkedet, om gamle dager. Brukes med andre koder som indikerer emne der det er hensiktsmessig, f.eks. WGF «Tog og jernbaner»',
            related: [],
            children: [],
          },
          {
            id: 'WQP',
            label: 'Steder i gamle fotografier',
            originalLabel: 'Steder i gamle fotografier',
            alternativeLabels: [],
            note: 'Her: samlinger av gamle fotografier knyttet til et bestemt sted eller steder. Brukes sammen med kvalifikatorer for geografi og tidsperiode der det er mulig. Se også AJC <a href="/thema/nb/AJC">Fotografisamlinger</a>',
            related: [
              {
                id: 'AJC',
                label: 'Fotografisamlinger',
                originalLabel: 'Fotografisamlinger',
                note: 'Brukes for bøker som er eller handler om tematiske samlinger, eller som presenterer en samlers, et museums, galleris eller byrås samling. Brukes med AG*-koder eller andre koder som angir publikasjonens tema der det er hensiktsmessig. Se også WQP <a href="/thema/nb/WQP">Steder i gamle fotografier</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WQY',
            label: 'Familiehistorie og slektsgransking',
            originalLabel: 'Familiehistorie og slektsgransking',
            alternativeLabels: ['genealogi'],
            note: 'Se også NHTG <a href="/thema/nb/NHTG">Genealogi, heraldikk og navnehistorie</a>',
            related: [
              {
                id: 'NHTG',
                label: 'Genealogi, heraldikk og navnehistorie',
                originalLabel: 'Genealogi, heraldikk og navnehistorie',
                note: 'Her: slektsforskning. Se også GTT <a href="/thema/nb/GTT">Flagg, emblemer, symboler, logoer</a>, VFXB1 <a href="/thema/nb/VFXB1">Spedbarnsnavn: håndbøker for foreldre</a>, WQY <a href="/thema/nb/WQY">Familiehistorie og slektsgransking</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'WT',
        label: 'Reiser og ferie',
        originalLabel: 'Reiser og ferie',
        alternativeLabels: [],
        note: 'Med WT*-koder , tildel også den mest spesifikke kvalifikatoren for sted',
        related: [],
        children: [
          {
            id: 'WTD',
            label: 'Reiser: råd og tips',
            originalLabel: 'Reiser: råd og tips',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'WTH',
            label: 'Reisehåndbøker',
            originalLabel: 'Reisehåndbøker',
            alternativeLabels: [],
            note: 'Brukes for reisehåndbøker som ikke passer i en mer spesifikk kategori og som først og fremst er guidebøker. Brukes med kvalifikator(er) for geografi, samt kvalifikator(er) for interesse, eller andre emnekoder. F.eks. 5PS for å angi ferier ment for spesielle grupper. Se også WJS <a href="/thema/nb/WJS">Kjøpeguider</a>, WTM <a href="/thema/nb/WTM">Steder og mennesker: generelle verk og fotografiske verk</a>',
            related: [
              {
                id: 'WJS',
                label: 'Kjøpeguider',
                originalLabel: 'Kjøpeguider',
                note: 'Se også UDBA <a href="/thema/nb/UDBA">Handel og auksjoner på internett</a>, VSG <a href="/thema/nb/VSG">Forbrukerrådgivning</a>',
              },
              {
                id: 'WTM',
                label:
                  'Steder og mennesker: generelle verk og fotografiske verk',
                originalLabel:
                  'Steder og mennesker: generelle verk og fotografiske verk',
                note: 'Brukes for illustrerte praktbøker om steder man bør besøke etc., som IKKE primært er guidebøker. Brukes med kvalifikator(er) for geografi og interesse, eller andre emnekoder dersom tittelen har et spesifikt tema. Se også AJC <a href="/thema/nb/AJC">Fotografisamlinger</a>',
              },
            ],
            children: [
              {
                id: 'WTHA',
                label: 'Reisehåndbøker: opplevelsesferier',
                originalLabel: 'Reisehåndbøker: opplevelsesferier',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WTHB',
                label: 'Reisehåndbøker: forretningsreiser',
                originalLabel: 'Reisehåndbøker: forretningsreiser',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WTHC',
                label: 'Reisehåndbøker: økoturisme, «grønn» turisme',
                originalLabel: 'Reisehåndbøker: økoturisme, «grønn» turisme',
                alternativeLabels: [],
                note: 'Her: guider til bærekraftig turisme',
                related: [],
                children: [],
              },
              {
                id: 'WTHD',
                label: 'Reisehåndbøker: mat- og drikkeproduserende regioner',
                originalLabel:
                  'Reisehåndbøker: mat- og drikkeproduserende regioner',
                alternativeLabels: [],
                note: 'Her: reisehåndbøker basert rundt lokale matvarer, vinproduserende regioner, etc. I tillegg til å legge til kode for geografi, bruk også passende kode fra WB* for å detaljere området for mat og drikke som er dekket. Se også WTHR <a href="/thema/nb/WTHR">Reisehåndbøker: restauranter og kafeer</a>',
                related: [
                  {
                    id: 'WTHR',
                    label: 'Reisehåndbøker: restauranter og kafeer',
                    originalLabel: 'Reisehåndbøker: restauranter og kafeer',
                    note: 'Her: guider til bestemte spise- og drikkesteder. Brukes for guider om hvor man bør spise/drikke, anbefalinger av spesifikke steder. Se også WTHD <a href="/thema/nb/WTHD">Reisehåndbøker: mat- og drikkeproduserende regioner</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'WTHE',
                label: 'Reisehåndbøker: aktivitetsferier',
                originalLabel: 'Reisehåndbøker: aktivitetsferier',
                alternativeLabels: [],
                note: 'Her : reiseguider for en aktiv ferie , brukes med SZ * koder for å angi aktiviteten som er involvert. F.eks. for med SPNG for seilferier, med SZD for sykkelferier etc.',
                related: [],
                children: [],
              },
              {
                id: 'WTHF',
                label: 'Reisehåndbøker: ferie med barn / familieferier',
                originalLabel: 'Reisehåndbøker: ferie med barn / familieferier',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WTHG',
                label: 'Reisehåndbøker: reise på budsjett',
                originalLabel: 'Reisehåndbøker: reise på budsjett',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WTHH',
                label: 'Reisehåndbøker: hotell og overnatting',
                originalLabel: 'Reisehåndbøker: hotell og overnatting',
                alternativeLabels: ['bed and breakfast'],
                note: 'Brukes for guider om hvor man bør bo, anbefalinger av spesifikke steder; vertshus, vandrerhjem, spa etc.',
                related: [],
                children: [
                  {
                    id: 'WTHH1',
                    label: 'Reisehåndbøker: campingplasser',
                    originalLabel: 'Reisehåndbøker: campingplasser',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'WTHK',
                label: 'Reisehåndbøker: strender og kystregioner',
                originalLabel: 'Reisehåndbøker: strender og kystregioner',
                alternativeLabels: [],
                note: 'Brukes med kvalifikator(er) for geografi der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'WTHL',
                label: 'Byguider',
                originalLabel: 'Byguider',
                alternativeLabels: [],
                note: 'Brukes med kvalifikator(er) for geografi der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'WTHM',
                label:
                  'Reisehåndbøker: museer, historiske steder, gallerier etc.',
                originalLabel:
                  'Reisehåndbøker: museer, historiske steder, gallerier etc.',
                alternativeLabels: [],
                note: 'Her: reisehåndbøker om kulturarvsteder, bygninger, monumenter, steder av kulturhistorisk og arkeologisk betydning etc.',
                related: [],
                children: [],
              },
              {
                id: 'WTHR',
                label: 'Reisehåndbøker: restauranter og kafeer',
                originalLabel: 'Reisehåndbøker: restauranter og kafeer',
                alternativeLabels: ['vertshus', 'barer', 'puber', 'bistroer'],
                note: 'Her: guider til bestemte spise- og drikkesteder. Brukes for guider om hvor man bør spise/drikke, anbefalinger av spesifikke steder. Se også WTHD <a href="/thema/nb/WTHD">Reisehåndbøker: mat- og drikkeproduserende regioner</a>',
                related: [
                  {
                    id: 'WTHD',
                    label:
                      'Reisehåndbøker: mat- og drikkeproduserende regioner',
                    originalLabel:
                      'Reisehåndbøker: mat- og drikkeproduserende regioner',
                    note: 'Her: reisehåndbøker basert rundt lokale matvarer, vinproduserende regioner, etc. I tillegg til å legge til kode for geografi, bruk også passende kode fra WB* for å detaljere området for mat og drikke som er dekket. Se også WTHR <a href="/thema/nb/WTHR">Reisehåndbøker: restauranter og kafeer</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'WTHT',
                label: 'Reisehåndbøker: fornøyelsesparker og tivoli',
                originalLabel: 'Reisehåndbøker: fornøyelsesparker og tivoli',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WTHV',
                label: 'Reisehåndbøker: togreiser',
                originalLabel: 'Reisehåndbøker: togreiser',
                alternativeLabels: ['jernbane', 'togferie'],
                note: 'Her: rutetabeller, kart, det å reise med tog etc.',
                related: [],
                children: [],
              },
              {
                id: 'WTHW',
                label: 'Reisehåndbøker: reiseruter og reisemåter',
                originalLabel: 'Reisehåndbøker: reiseruter og reisemåter',
                alternativeLabels: [],
                note: 'Her: guider til reiseruter til fots, på sykkel, på vannet, motorveier, ridestier etc. Brukes med kvalifikator(er) for geografi, samt andre emnekoder som SZ* der det er hensiktsmessig. F.eks. med SZD for reiseruter på sykkel, SZC for fotturer, RGXH for kart i stor skala for fotturister eller RBXB for kart i liten skala som viser en oversikt over langdistanseruter etc.',
                related: [],
                children: [],
              },
              {
                id: 'WTHX',
                label: 'Reisehåndbøker: cruise',
                originalLabel: 'Reisehåndbøker: cruise',
                alternativeLabels: [],
                note: 'Her: elvecruise, cruise på havet, guider til cruiseskip, kanalcruise etc. Brukes med kvalifikator(er) for geografi der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'WTHY',
                label: 'Reisehåndbøker: bilturer',
                originalLabel: 'Reisehåndbøker: bilturer',
                alternativeLabels: [],
                note: 'Brukes for guider til reiser med motorkjøretøy som bil, motorsykkel, bobil, buss, eller guider som følger spesifikke veier eller ruter som passer for slike kjøretøy etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'WTK',
            label: 'Parlører',
            originalLabel: 'Parlører',
            alternativeLabels: [],
            note: 'Tildel også kvalifikator for språk. Se også CJBT <a href="/thema/nb/CJBT">Språkopplæring: selvstudium</a>',
            related: [
              {
                id: 'CJBT',
                label: 'Språkopplæring: selvstudium',
                originalLabel: 'Språkopplæring: selvstudium',
                note: 'Brukes for materiale ment for selvstudium innen språkopplæring. Brukes med kvalifikator(er) for språk og pedagogisk formål der det er hensiktsmessig, f.eks. 4L. Se også WTK <a href="/thema/nb/WTK">Parlører</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WTL',
            label: 'Reiseskildringer',
            originalLabel: 'Reiseskildringer',
            alternativeLabels: [],
            note: 'Her: moderne reiseskildringer, reisedagbøker, reiselitteratur. Brukes for bøker som gir en personlig beskrivelse av et sted, et område, et land, og som ofte inneholder personlige anekdoter og inntrykk, og som kan inneholde noe historie, geografi, moderne samfunn og kultur. Bruk WTL* med kvalifikatorer for sted der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'WTLC',
                label: 'Klassiske reiseskildringer',
                originalLabel: 'Klassiske reiseskildringer',
                alternativeLabels: [],
                note: 'Her: reiseskildringer som er ansett som «klassisk», og som vanligvis, men ikke nødvendigvis, ble utgitt første gang for flere tiår siden',
                related: [],
                children: [],
              },
              {
                id: 'WTLP',
                label: 'Ekspedisjoner',
                originalLabel: 'Ekspedisjoner',
                alternativeLabels: [],
                note: 'Her: reiseskildringer om ekspedisjoner og utforskning, for allmennmarkedet. Se også RGR <a href="/thema/nb/RGR">Geografisk oppdagelse og oppdagelsesreiser</a>',
                related: [
                  {
                    id: 'RGR',
                    label: 'Geografisk oppdagelse og oppdagelsesreiser',
                    originalLabel: 'Geografisk oppdagelse og oppdagelsesreiser',
                    note: 'Se også WTLP <a href="/thema/nb/WTLP">Ekspedisjoner</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'WTM',
            label: 'Steder og mennesker: generelle verk og fotografiske verk',
            originalLabel:
              'Steder og mennesker: generelle verk og fotografiske verk',
            alternativeLabels: [],
            note: 'Brukes for illustrerte praktbøker om steder man bør besøke etc., som IKKE primært er guidebøker. Brukes med kvalifikator(er) for geografi og interesse, eller andre emnekoder dersom tittelen har et spesifikt tema. Se også AJC <a href="/thema/nb/AJC">Fotografisamlinger</a>',
            related: [
              {
                id: 'AJC',
                label: 'Fotografisamlinger',
                originalLabel: 'Fotografisamlinger',
                note: 'Brukes for bøker som er eller handler om tematiske samlinger, eller som presenterer en samlers, et museums, galleris eller byrås samling. Brukes med AG*-koder eller andre koder som angir publikasjonens tema der det er hensiktsmessig. Se også WQP <a href="/thema/nb/WQP">Steder i gamle fotografier</a>',
              },
            ],
            children: [],
          },
          {
            id: 'WTR',
            label: 'Kart og atlas',
            originalLabel: 'Kart og atlas',
            alternativeLabels: [],
            note: 'Bruk WTR* med kvalifikatorer for geografi og med andre koder for spesielle typer kart og atlas, f.eks. med WTHV for togreiser, med WTHX for kart eller atlas ment for turister etc. Se også RGX* <a href="/thema/nb/RGX">Geografiske oppslagsverk</a> og dens underkategorier',
            related: [
              {
                id: 'RGX',
                label: 'Geografiske oppslagsverk',
                originalLabel: 'Geografiske oppslagsverk',
              },
            ],
            children: [
              {
                id: 'WTRD',
                label: 'Bilatlas og veikart',
                originalLabel: 'Bilatlas og veikart',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'WTRM',
                label: 'Turistkart',
                originalLabel: 'Turistkart',
                alternativeLabels: [],
                note: 'Her: alle brettede kart, ikke bykart og veikart',
                related: [],
                children: [],
              },
              {
                id: 'WTRS',
                label: 'Bykart',
                originalLabel: 'Bykart',
                alternativeLabels: [],
                note: 'Her: Kart og kartbøker over byer, tettsteder og gater for generell bruk',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'WZ',
        label: 'Kontormateriell og diverse produkter',
        originalLabel: 'Kontormateriell og diverse produkter',
        alternativeLabels: [],
        note: 'Bruk WZ*-koder for produkter som kontormateriell, papirvarer, skrivesaker og andre varer for voksne, hvor man ikke kan bruke en annen emnekategori som hovedkategori. Brukes med andre emnekoder der det er hensiktsmessig, men WZ* bør angis som hovedkoden. Detaljer og formater beskrives i andre metadata',
        related: [],
        children: [
          {
            id: 'WZG',
            label: 'Gavebøker',
            originalLabel: 'Gavebøker',
            alternativeLabels: [],
            note: 'Her: små bøker, vanligvis humoristiske, inspirerende og dekorative bøker laget for å bli gitt bort som gaver. Se også YZG <a href="/thema/nb/YZG">Barn: gavebøker</a>',
            related: [
              {
                id: 'YZG',
                label: 'Gavebøker',
                originalLabel: 'Barn: gavebøker',
                note: 'Her: små bøker, vanligvis humoristiske, inspirerende og dekorative bøker laget for å bli gitt bort som gaver til barn. Skal IKKE brukes for markedsføringsformål, f.eks. for å si at boka passer som gave',
              },
            ],
            children: [],
          },
          {
            id: 'WZS',
            label: 'Kontormateriell, papirvarer',
            originalLabel: 'Kontormateriell, papirvarer',
            alternativeLabels: [],
            note: 'Her: varer som dagbøker, kalendere, plakater, kits, postkort, pakker, bokmerker, etc. Tildel også en kode som indikerer emnet der det er mulig',
            related: [],
            children: [
              {
                id: 'WZSJ',
                label: 'Dagbøker og notatbøker',
                originalLabel: 'Dagbøker og notatbøker',
                alternativeLabels: [],
                note: 'Brukes for dagbøker som har noe tekst eller illustrasjoner som er ment som informasjon for det man selv skriver. Brukes med andre koder for å angi et emne der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'WZSN',
                label: 'Blanke papirvarer',
                originalLabel: 'Blanke papirvarer',
                alternativeLabels: ['kladdebøker'],
                note: 'Her: tomme notatbøker, autografbøker og andre innholdsfrie produkter',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'X',
    label: 'Grafiske romaner, tegneserier, manga og tegneseriestriper',
    originalLabel: 'Grafiske romaner, tegneserier, manga og tegneseriestriper',
    alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
    note: 'Bruk X*-koder for bøker både for voksne og barn. IKKE bruk koden ‘X’ alene, men velg en spesifikk kategori fra X*. X* kan brukes med A*-W* ELLER Y* (men ikke begge) og kvalifikatorer der det er hensiktsmessig. Bruk en kode fra XA* for å angi tradisjon, og en fra XQ* for å angi sjanger, samt andre koder for å angi flere detaljer. Kvalifikatorer for alder fra 5A* MÅ brukes på utgivelser ment for barn og ungdom',
    related: [],
    children: [
      {
        id: 'XA',
        label: 'Grafiske romaner/ Tegneserier / Manga: stilarter / tradisjoner',
        originalLabel:
          'Grafiske romaner/ Tegneserier / Manga: stilarter / tradisjoner',
        alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
        note: 'Bruk XA* for å angi tradisjon eller type. Bruk XA alene kun dersom den spesifikke tradisjonen eller typen ikke er kjent, det er snakk om en blanding av tradisjoner, eller det ikke fins en mer spesifikk XA*-kode. Bruk XA* med XQ* for å angi sjanger der det er hensiktsmessig',
        related: [],
        children: [
          {
            id: 'XAB',
            label: 'Grafiske romaner',
            originalLabel: 'Grafiske romaner',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes for frittstående tegneserieutgivelser som bruker illustrasjoner for å fortelle ei historie (eller for å fortelle et sakprosaverk), ikke tegneserier som har seriepreg. Koden for grafisk roman kan brukes både for skjønnlitteratur og sakprosa, og historia er vanligvis avsluttet, selv om den er en del av ei større samling utgivelser',
            related: [],
            children: [],
          },
          {
            id: 'XAD',
            label: 'Tegneserier i europeisk stil / tradisjon',
            originalLabel: 'Tegneserier i europeisk stil / tradisjon',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Her: tegneseriealbum, «bandes dessinées», italienske fumetti, tegneserier i den europeiske eller fransk-belgiske tradisjonen. Brukes for tegneseriefortellinger som er en del av eller er inspirert av ulike europeiske stilarter og som vanligvis er publisert som en serie med gjentagende karakterer, i motsetning til grafiske romaner som utgjør frittstående titler',
            related: [],
            children: [
              {
                id: 'XADC',
                label: 'Tegneserier i klassisk europeisk stil / tradisjon',
                originalLabel:
                  'Tegneserier i klassisk europeisk stil / tradisjon',
                alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
                note: 'Her: klassiske europeiske tegneserier og tegneseriestriper for alle aldre, og som blir ansett som ‘klassiske’, og vanligvis, men ikke nødvendigvis, ble utgitt for første gang for flere tiår siden',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'XAK',
            label:
              'Tegneserier og grafiske romaner i amerikansk stil / tradisjon',
            originalLabel:
              'Tegneserier og grafiske romaner i amerikansk stil / tradisjon',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes for tegneseriefortellinger som er en del av eller er inspirert av ulike stilarter og tradisjoner som har oppstått i USA. Disse inkluderer ofte superhelter og en virkelighet med magiske elementer, og var opprinnelig utgitt i det tradisjonelle tegneserieformatet på 32 sider. De er vanligvis en del av et større univers som kan ha blitt utgitt over en lang tidsperiode. Denne stilarten har inspirert mange tegneserieskapere rundt om i verden, og koden trenger ikke bety at tegneserien er skapt eller utgitt i USA, bare at den er et eksempel på denne stilarten',
            related: [],
            children: [
              {
                id: 'XAKC',
                label: 'Tegneserier i klassisk amerikansk stil / tradisjon',
                originalLabel:
                  'Tegneserier i klassisk amerikansk stil / tradisjon',
                alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
                note: 'Her: tidlige og klassiske tegneseriekarakterer, inkludert såkalt ‘Golden Age’ eller ‘Silver age’ innen amerikansk tegneserietradisjon, og som passer for alle aldersnivå, og som blir ansett som ‘klassiske’, og vanligvis, men ikke nødvendigvis, ble utgitt for første gang for flere tiår siden',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'XAM',
            label: 'Manga og tegneserier i østasiatisk stil / tradisjon',
            originalLabel:
              'Manga og tegneserier i østasiatisk stil / tradisjon',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'XAM* inkluderer alle japanske tegneserier(manga), samt andre østasiatiske tegneserier som koreanske (manhwa) og kinesiske(manhua), og tegneserier fra ikke-asiatiske land som er tegnet i manga- eller østasiatisk stil. Brukes med XQ* for å angi emne eller sjanger, og med koder fra A*-W* ELLER Y* og kvalifikatorer der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'XAMC',
                label: 'Manga: Komodo',
                originalLabel: 'Manga: Komodo',
                alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
                note: 'Brukes for manga for barn av begge kjønn, aldersnivå ca. 6–10 år',
                related: [],
                children: [],
              },
              {
                id: 'XAMF',
                label: 'Manga: Shôjo',
                originalLabel: 'Manga: Shôjo',
                alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
                note: 'Brukes for manga for jenter, aldersnivå ca. 10–15 år',
                related: [],
                children: [],
              },
              {
                id: 'XAMG',
                label: 'Manga: Shonen',
                originalLabel: 'Manga: Shonen',
                alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
                note: 'Brukes for manga for gutter, aldersnivå ca. 10–15 år',
                related: [],
                children: [],
              },
              {
                id: 'XAML',
                label: 'Manga: Seinen',
                originalLabel: 'Manga: Seinen',
                alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
                note: 'Brukes for manga for unge mannlige lesere, fra ca. 15 til 30 år. Brukes med kvalifikator 5X der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'XAMR',
                label: 'Manga: Josei',
                originalLabel: 'Manga: Josei',
                alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
                note: 'Brukes for manga for unge kvinnelige lesere',
                related: [],
                children: [],
              },
              {
                id: 'XAMT',
                label: 'Manga: Yaoi',
                originalLabel: 'Manga: Yaoi',
                alternativeLabels: [
                  'boy’s love',
                  'danmei',
                  'tegneserieroman',
                  'tegneserieromaner',
                ],
                note: 'Her: manga som skildrer homoseksuelle forhold mellom unge menn (BL), hovedsakelig, men ikke utelukkende ment for unge kvinnelige lesere. Brukes med 5PSG der det er hensiktsmessig. Se også FYQ <a href="/thema/nb/FYQ">Skjønnlitteratur: spesielle utgivelser: yaoi</a>',
                related: [
                  {
                    id: 'FYQ',
                    label: 'Yaoi',
                    originalLabel:
                      'Skjønnlitteratur: spesielle utgivelser: yaoi',
                    note: 'Her: BL-romaner, danmei. Brukes med andre F*-koder for å angi sjanger, f.eks. FR*-koder for Romantikk, 5LKE*-koder dersom denne livsfasen er omhandlet. Se også XAMT <a href="/thema/nb/XAMT">Manga: Yaoi</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'XAMV',
                label: 'Manga: Bara',
                originalLabel: 'Manga: Bara',
                alternativeLabels: [
                  'male love',
                  'tegneserieroman',
                  'tegneserieromaner',
                ],
                note: 'Her: manga om mannlig kjærlighet (ML). Brukes for manga som handler om kjærlighet mellom menn, vanligvis skapt av homofile menn for et homofilt publikum. Brukes med kvalifikator 5PSG og 5X der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'XAMX',
                label: 'Manga: voksen (erotikk, ekstrem vold)',
                originalLabel: 'Manga: voksen (erotikk, ekstrem vold)',
                alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
                note: 'Brukes for manga for voksne med innhold av overveiende voksen art, som kun passer for et modent publikum. Brukes med andre XAM*-koder og kvalifikator 5X der det er hensiktsmessig. Se også XAMX2 <a href="/thema/nb/XAMX2">Manga: hentai manga</a>',
                related: [
                  {
                    id: 'XAMX2',
                    label: 'Manga: hentai manga',
                    originalLabel: 'Manga: hentai manga',
                    note: 'Brukes for manga for voksne (over 18 år), med innhold av en svært seksuell eller pornografisk art. Brukes med kvalifikator 5X der det er hensiktsmessig. Se også XAML <a href="/thema/nb/XAML">Manga: Seinen</a>',
                  },
                ],
                children: [
                  {
                    id: 'XAMX2',
                    label: 'Manga: hentai manga',
                    originalLabel: 'Manga: hentai manga',
                    alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
                    note: 'Brukes for manga for voksne (over 18 år), med innhold av en svært seksuell eller pornografisk art. Brukes med kvalifikator 5X der det er hensiktsmessig. Se også XAML <a href="/thema/nb/XAML">Manga: Seinen</a>',
                    related: [
                      {
                        id: 'XAML',
                        label: 'Manga: Seinen',
                        originalLabel: 'Manga: Seinen',
                        note: 'Brukes for manga for unge mannlige lesere, fra ca. 15 til 30 år. Brukes med kvalifikator 5X der det er hensiktsmessig',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'XAMY',
                label: 'Manga: Yuri',
                originalLabel: 'Manga: Yuri',
                alternativeLabels: [
                  'girl’s love',
                  'tegneserieroman',
                  'tegneserieromaner',
                ],
                note: 'Brukes for manga som omhandler unge kvinner i homoseksuelle forhold, ofte (men ikke utelukkende) ment for unge, kvinnelige lesere. Brukes med 5PSL der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'XAX',
            label: 'Tegneserier i alternative stilarter / tradisjoner',
            originalLabel: 'Tegneserier i alternative stilarter / tradisjoner',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Her: uavhengige tegneserier, indie tegneserier etc.',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'XQ',
        label: 'Grafiske romaner, tegneserier og manga: sjangre',
        originalLabel: 'Grafiske romaner, tegneserier og manga: sjangre',
        alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
        note: 'Bruk XQ* med F* eller YF* der det er hensiktsmessig, eller med A*-W* for sakprosa',
        related: [],
        children: [
          {
            id: 'XQA',
            label:
              'Grafiske romaner, tegneserier og manga: biografier, memoarer, dokumentarer og faglitteratur',
            originalLabel:
              'Grafiske romaner, tegneserier og manga: biografier, memoarer, dokumentarer og faglitteratur',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Her: selvbiografier, biografier, politiske historier, reiseskildringer i tegneserieform eller andre emner som ikke har en mer spesifikk XQ*-kode. Brukes med faglitterære koder A*-W* for å angi emne eller Y* for tegneserier for barn og ungdom der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'XQAY',
                label: 'Grafiske romaner, tegneserier og manga: pedagogisk',
                originalLabel:
                  'Grafiske romaner, tegneserier og manga: pedagogisk',
                alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
                note: 'Brukes for bøker som er utgitt som tegneserier eller manga, hvor innholdet primært består av fakta og er ment for å opplyse leseren om emnet. Brukes med YN* for tegneserier for barn og ungdom, med A*-W* for sakprosa, og med relevante XA*-koder for å angi type. Dersom tegneserien er utgitt spesielt for bruk til undervisningsformål, brukes XQAY med YP* og kvalifikator for pedagogisk formål der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'XQB',
            label:
              'Grafiske romaner, tegneserier og manga: litterære adapsjoner',
            originalLabel:
              'Grafiske romaner, tegneserier og manga: litterære adapsjoner',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Her: adapsjoner av eksisterende litterære verker til tegneseriemediet. Brukes med DB*, DC*, DD*, F*, YF*, YND* eller YPC* der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'XQC',
            label:
              'Grafiske romaner, tegneserier og manga: film og TV-adapsjoner',
            originalLabel:
              'Grafiske romaner, tegneserier og manga: film og TV-adapsjoner',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes for tegneserier som er utgitt som en spinoff eller som et ledsagende produkt til allerede eksisterende medieprodukter eller -varer som ikke er bøker. F.eks. bøker som er inspirert av populære filmer, spill eller TV-serier. Skal IKKE brukes for titler som i etterkant er blitt filmatisert etc.',
            related: [],
            children: [],
          },
          {
            id: 'XQD',
            label:
              'Grafiske romaner, tegneserier og manga: krim, mysterier og thrillere',
            originalLabel:
              'Grafiske romaner, tegneserier og manga: krim, mysterier og thrillere',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes med FF*, FH* eller YFC* for å angi krimsjanger der det er nødvendig',
            related: [],
            children: [],
          },
          {
            id: 'XQE',
            label: 'Grafiske romaner, tegneserier og manga: samfunn og kultur',
            originalLabel:
              'Grafiske romaner, tegneserier og manga: samfunn og kultur',
            alternativeLabels: [
              'chroniques sociales',
              'tegneserieroman',
              'tegneserieromaner',
            ],
            note: 'Her: samtidskulturelle, politiske eller sosiale emner eller problemstillinger etc. Brukes med A*-W* der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'XQF',
            label: 'Grafiske romaner, tegneserier og manga: moderne litteratur',
            originalLabel:
              'Grafiske romaner, tegneserier og manga: moderne litteratur',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes for tegneserier som er originalhistorier og som ikke dekkes av noen andre skjønnlitterære sjangere. Brukes med F* eller YF* for å angi sjanger der det er hensiktsmessig. Foretrekk XQB for tegneserier som er adapsjoner av eksisterende litterære verk',
            related: [],
            children: [],
          },
          {
            id: 'XQG',
            label: 'Grafiske romaner, tegneserier og manga: action og spenning',
            originalLabel:
              'Grafiske romaner, tegneserier og manga: action og spenning',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes med FJ* eller YF* for å angi sjanger der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'XQGW',
                label: 'Grafiske romaner, tegneserier og manga: western',
                originalLabel:
                  'Grafiske romaner, tegneserier og manga: western',
                alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'XQH',
            label:
              'Grafiske romaner, tegneserier og manga: grøssere / det overnaturlige',
            originalLabel:
              'Grafiske romaner, tegneserier og manga: grøssere / det overnaturlige',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes med FK* eller andre koder fra F* eller YFD for å angi sjanger, samt VXQ* eller YNX* der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'XQJ',
            label: 'Grafiske romaner, tegneserier og manga: sport',
            originalLabel: 'Grafiske romaner, tegneserier og manga: sport',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Her: tegneserier som handler om sport, idrett og konkurranser. Brukes med FG eller YFR for skjønnlitteratur, og S* eller YNW* for å angi hvilken sport det er snakk om',
            related: [],
            children: [],
          },
          {
            id: 'XQK',
            label:
              'Grafiske romaner, tegneserier og manga: superhelter og superskurker',
            originalLabel:
              'Grafiske romaner, tegneserier og manga: superhelter og superskurker',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes med andre koder fra XQ* der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'XQL',
            label: 'Grafiske romaner, tegneserier og manga: science fiction',
            originalLabel:
              'Grafiske romaner, tegneserier og manga: science fiction',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes med FL* eller YFG* for å angi sjanger der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'XQM',
            label:
              'Grafiske romaner, tegneserier og manga: fantasy og esoterikk',
            originalLabel:
              'Grafiske romaner, tegneserier og manga: fantasy og esoterikk',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes for tegneserier som skildrer tradisjoner av mytisk, esoterisk eller hemmelig kunnskap, eller som hører til spesifikke fantasysjangere. Brukes med FM* eller YFH* for å angi fantasysjanger, eller VXQ* eller YNX* der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'XQN',
            label:
              'Grafiske romaner, tegneserier og manga: antropomorfe historier / dyrehistorier',
            originalLabel:
              'Grafiske romaner, tegneserier og manga: antropomorfe historier / dyrehistorier',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes med andre koder fra XQ* der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'XQP',
            label:
              'Grafiske romaner, tegneserier og manga: tradisjonelle fortellinger, myter, legender',
            originalLabel:
              'Grafiske romaner, tegneserier og manga: tradisjonelle fortellinger, myter, legender',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Her: mytisk skjønnlitteratur. Brukes for moderne gjenfortellinger av tradisjonelle fortellinger, myter, legender, eventyr eller folketro i tegneserieform. Brukes med FN* eller YFJ* eller YFH for å angi sjanger, eller QR* eller YNRU for sakprosa og kvalifikatorer for geografi der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'XQR',
            label: 'Grafiske romaner, tegneserier og manga: romantikk',
            originalLabel: 'Grafiske romaner, tegneserier og manga: romantikk',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes med FR* eller YFM der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'XQS',
            label:
              'Grafiske romaner, tegneserier og manga: fortellinger fra skole- og universitetsliv',
            originalLabel:
              'Grafiske romaner, tegneserier og manga: fortellinger fra skole- og universitetsliv',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes med andre koder fra XQ*, F* eller YF* for å angi sjanger, og kvalifikator fra 5L* der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'XQT',
            label: 'Grafiske romaner, tegneserier og manga: humor',
            originalLabel: 'Grafiske romaner, tegneserier og manga: humor',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes med andre koder fra XQ*, WH* eller YFQ* der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'XQV',
            label: 'Grafiske romaner, tegneserier og manga: historisk',
            originalLabel: 'Grafiske romaner, tegneserier og manga: historisk',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes med koder fra F* eller YFT for å angi sjanger, N* eller YNH for historisk sakprosa og kvalifikator for tidsperiode der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'XQW',
            label:
              'Grafiske romaner, tegneserier og manga: religiøs, spirituell, inspirerende',
            originalLabel:
              'Grafiske romaner, tegneserier og manga: religiøs, spirituell, inspirerende',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes med kvalifikatorer fra 5PG*, F* eller YF* for sjanger, eller QR*, VX* eller YNR* for sakprosa',
            related: [],
            children: [],
          },
          {
            id: 'XQX',
            label: 'Grafiske romaner, tegneserier og manga: voksen',
            originalLabel: 'Grafiske romaner, tegneserier og manga: voksen',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes for tegneserier for voksne som kun passer for et modent publikum . Brukes med kvalifikator 5X eller andre X*-koder der det er hensiktsmessig. Se også XAMX <a href="/thema/nb/XAMX">Manga: voksen (erotikk, ekstrem vold)</a>',
            related: [
              {
                id: 'XAMX',
                label: 'Manga: voksen (erotikk, ekstrem vold)',
                originalLabel: 'Manga: voksen (erotikk, ekstrem vold)',
                note: 'Brukes for manga for voksne med innhold av overveiende voksen art, som kun passer for et modent publikum. Brukes med andre XAM*-koder og kvalifikator 5X der det er hensiktsmessig. Se også XAMX2 <a href="/thema/nb/XAMX2">Manga: hentai manga</a>',
              },
            ],
            children: [
              {
                id: 'XQXE',
                label:
                  'Grafiske romaner, tegneserier og manga: voksen - erotikk',
                originalLabel:
                  'Grafiske romaner, tegneserier og manga: voksen - erotikk',
                alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
                note: 'Brukes for tegneserier for voksne med innhold av svært seksuell art, eller som har innhold som beskriver seksuelle temaer svært grafisk, og som er ment for et modent publikum. Brukes med kvalifikator 5X og andre X*-koder der det er hensiktsmessig. Se også XAMX2 <a href="/thema/nb/XAMX2">Manga: hentai manga</a>',
                related: [
                  {
                    id: 'XAMX2',
                    label: 'Manga: hentai manga',
                    originalLabel: 'Manga: hentai manga',
                    note: 'Brukes for manga for voksne (over 18 år), med innhold av en svært seksuell eller pornografisk art. Brukes med kvalifikator 5X der det er hensiktsmessig. Se også XAML <a href="/thema/nb/XAML">Manga: Seinen</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'XQXV',
                label:
                  'Grafiske romaner, tegneserier og manga: voksen - ekstrem vold',
                originalLabel:
                  'Grafiske romaner, tegneserier og manga: voksen - ekstrem vold',
                alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
                note: 'Brukes for tegneserier for voksne med innhold av ekstremt voldelig art, eller som har innhold som beskriver voldelige temaer svært grafisk, og som er ment for et modent publikum. Brukes med kvalifikator 5X og andre X*-koder der det er hensiktsmessig. Se også XAMX <a href="/thema/nb/XAMX">Manga: voksen (erotikk, ekstrem vold)</a>',
                related: [
                  {
                    id: 'XAMX',
                    label: 'Manga: voksen (erotikk, ekstrem vold)',
                    originalLabel: 'Manga: voksen (erotikk, ekstrem vold)',
                    note: 'Brukes for manga for voksne med innhold av overveiende voksen art, som kun passer for et modent publikum. Brukes med andre XAM*-koder og kvalifikator 5X der det er hensiktsmessig. Se også XAMX2 <a href="/thema/nb/XAMX2">Manga: hentai manga</a>',
                  },
                ],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'XR',
        label:
          'Grafiske romaner, tegneserier og manga: oppslagsverk, guider og anmeldelser',
        originalLabel:
          'Grafiske romaner, tegneserier og manga: oppslagsverk, guider og anmeldelser',
        alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
        note: 'Brukes med andre emnekoder der det er hensiktsmessig, f.eks. med FZ for sammenlignende litteratur, GB* for oppslagsverk, DS* for analyse eller kritikk av tegneserier. Se også DSR <a href="/thema/nb/DSR">Litterære oppslagsverk</a>, FZ <a href="/thema/nb/FZ">Skjønnlitteratur: guider, ledsagende bøker</a>',
        related: [
          {
            id: 'DSR',
            label: 'Litterære oppslagsverk',
            originalLabel: 'Litterære oppslagsverk',
          },
          {
            id: 'FZ',
            label: 'Skjønnlitteratur: guider, ledsagende bøker',
            originalLabel: 'Skjønnlitteratur: guider, ledsagende bøker',
            note: 'Brukes for bøker som er utgitt som ledsagende bøker til populære serier eller enkelttitler, f.eks. et atlas over en mytisk verden, en oversikt over karakterer, en guide til serien etc. Brukes med andre F*-koder for å angi sjanger eller type, eller med DB*-, DC*-, DD*- eller XQ*-koder dersom utgivelsen er et dikt, en tegneserie etc. Se også DSR <a href="/thema/nb/DSR">Litterære oppslagsverk</a>, XR* <a href="/thema/nb/XR">Grafiske romaner, tegneserier og manga: oppslagsverk, guider og anmeldelser</a> og dens underkategorier',
          },
        ],
        children: [
          {
            id: 'XRM',
            label:
              'Manga og tegneserier i østasiatisk stil / tradisjon: oppslagsverk, guider og anmeldelser',
            originalLabel:
              'Manga og tegneserier i østasiatisk stil / tradisjon: oppslagsverk, guider og anmeldelser',
            alternativeLabels: ['tegneserieroman', 'tegneserieromaner'],
            note: 'Brukes med andre emnekoder der det er hensiktsmessig, f.eks. med FZ for sammenlignende litteratur, GB* for oppslagsverk, DS* for analyse eller kritikk av manga. Se også DSR <a href="/thema/nb/DSR">Litterære oppslagsverk</a>, FZ <a href="/thema/nb/FZ">Skjønnlitteratur: guider, ledsagende bøker</a>',
            related: [
              {
                id: 'DSR',
                label: 'Litterære oppslagsverk',
                originalLabel: 'Litterære oppslagsverk',
              },
              {
                id: 'FZ',
                label: 'Skjønnlitteratur: guider, ledsagende bøker',
                originalLabel: 'Skjønnlitteratur: guider, ledsagende bøker',
                note: 'Brukes for bøker som er utgitt som ledsagende bøker til populære serier eller enkelttitler, f.eks. et atlas over en mytisk verden, en oversikt over karakterer, en guide til serien etc. Brukes med andre F*-koder for å angi sjanger eller type, eller med DB*-, DC*-, DD*- eller XQ*-koder dersom utgivelsen er et dikt, en tegneserie etc. Se også DSR <a href="/thema/nb/DSR">Litterære oppslagsverk</a>, XR* <a href="/thema/nb/XR">Grafiske romaner, tegneserier og manga: oppslagsverk, guider og anmeldelser</a> og dens underkategorier',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'XY',
        label: 'Tegneseriestriper',
        originalLabel: 'Tegneseriestriper',
        alternativeLabels: [],
        note: 'Her: samlinger av tegneseriestriper, gjerne humoristiske med 3–5 ruter, som vanligvis har vært publisert i aviser eller blader, samt enkeltstående tegnede ruter',
        related: [],
        children: [],
      },
    ],
  },
  {
    id: 'Y',
    label: 'Barnebøker, ungdomsbøker og læremidler',
    originalLabel: 'Barnebøker, ungdomsbøker og læremidler',
    alternativeLabels: [
      'Bruk Y*-koder med kvalifikator(er) fra 5A* der det er hensiktsmessig',
    ],
    note: 'Bruk Y*-koder for bøker ment for eller skrevet for et ungt publikum (unntatt grafiske romaner, tegneserier, manga i X*). IKKE bruk koden ‘Y’ alene, men velg spesifikke kategorier fra Y*. Bruk Y*-koder med kun en kvalifikator for alder fra 5A*for å gi en indikasjon på hvilken alder utgivelsen passer fra (detaljert angivelse av aldersintervall bør sendes i andre metadata). For YP* brukes kvalifikatorer for pedagogisk formål. Alle andre kvalifikatorer kan brukes der det er hensiktsmessig. IKKE bruk Y*-koder for utgivelser som er ment for voksne, men som også kan leses av unge voksne. For innhold for ‘nye voksne’, se noten for 5LKE, for ‘unge voksne’ brukes den mest passende 5A*-kvalifikatoren, etter hva begrepet dekker i det lokale markedet. NB!: Y* bør brukes sammen med X* for grafiske romaner, tegneserier, manga. Y* kan brukes i kombinasjon med A*-W*, men foretrekk Y*, og dersom man kombinerer, skal Y* ALLTID angis som den første og viktigste koden, kombinert med en 5A*-kvalifikator. Dersom A*-W* angis som den første og viktigste koden, skal de IKKE kombineres med Y*, bortsett fra for JNU / JNUM som kan kombineres med YP* eller DSYC',
    related: [],
    children: [
      {
        id: 'YB',
        label: 'Bøker for barn',
        originalLabel: 'Bøker for barn',
        alternativeLabels: ['begrepstrening'],
        note: 'Bruk YB*-koder både for fysiske og digitale utgivelser. Beskrivelsene forholder seg kun til innholdet, ikke formatet (format beskrives i andre metadata). Her: billedbøker, aktivitetsbøker, bøker for tidlig læring. Ved bruk av YB*-kode tildel også en passende kvalifikator fra 5A* for å gi en indikasjon på hvilken alder utgivelsen passer fra',
        related: [],
        children: [
          {
            id: 'YBC',
            label: 'Billedbøker',
            originalLabel: 'Billedbøker',
            alternativeLabels: [
              'Brukes med andre koder fra Y* der det er hensiktsmessig (spesielt fra YF*',
              'YX*',
              'YN*)',
            ],
            note: 'Primært 0-6 år. Bruk YBC*-koder for illustrerte bøker for barn, hvor innholdet formidles av en kombinasjon av tekst og bilder eller gjennom bilder alene. Ei ‘billedbok’ er ikke det samme ei ‘illustrert bok’, siden illustrasjonene er minst like viktige som teksten og utgjør en viktig del av boka. Bruk YBC*-koder med andre koder fra Y* der det er hensiktsmessig, f.eks. YNB for billedbøker som er biografier eller YPCA21 for billedbøker som er en del av et leseopplæringsprogram',
            related: [],
            children: [
              {
                id: 'YBCB',
                label: 'Småbarnsbøker og pekebøker',
                originalLabel: 'Småbarnsbøker og pekebøker',
                alternativeLabels: [],
                note: 'Her: bøker som vanligvis er i lite format; kartonerte bøker, badebøker, tøybøker med lite eller ingen tekst, og ikke noe læringsinnhold, og vanligvis lite fortellende innhold; 0 til 2 år. Brukes med YBG*-koder for interaktive småbarnsbøker, eller med YBL* der boka har et tydelig tema, og med 5AB* kvalifikator for alder',
                related: [],
                children: [],
              },
              {
                id: 'YBCH',
                label: 'Billedbøker med spesielle figurer',
                originalLabel: 'Billedbøker med spesielle figurer',
                alternativeLabels: [],
                note: 'Brukes for alle billedbøker basert på figurer fra TV eller film, og bøker med kjente figurer som går igjen i flere bøker eller andre produkter',
                related: [],
                children: [],
              },
              {
                id: 'YBCS',
                label: 'Billedbøker med tekst',
                originalLabel: 'Billedbøker med tekst',
                alternativeLabels: [],
                note: 'Her: billedbøker som forteller en historie, hvor teksten er avhengig av illustrasjonene. Brukes med YF*-koder for å angi sjanger der det er hensiktsmessig, eller med YN*- eller YX*-kategorier som sekundærkoder for fortellinger om et spesielt tema. YBCS bør vanligvis være hovedkoden når den brukes sammen med YF*, YN* eller YX*. Foretrekk X*-koder for fortellinger i tegneserie- eller mangaform',
                related: [],
                children: [
                  {
                    id: 'YBCS1',
                    label: 'Godnatthistorier',
                    originalLabel:
                      'Billedbøker med tekst: godnatthistorier, søvn og drømmer',
                    alternativeLabels: [],
                    note: 'Brukes for samlinger eller enkelthistorier som er ment for høytlesning eller lytting til ved leggetid, eller for bøker om det å legge seg, sovne og rutiner rundt dette, eller bøker som er spesielt utformet for å trøste og roe ned barn for å få dem til å sovne, eller er historier om det å drømme',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YBCS2',
                    label: 'Billedbøker: fantasi og lek',
                    originalLabel: 'Billedbøker med tekst: fantasi og lek',
                    alternativeLabels: [],
                    note: 'Her: billedbøker som bruker fantasi og/eller lek, ofte for å skape en fantasiverden',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'YBD',
            label: 'Kapittelbøker',
            originalLabel: 'Barn og ungdom: kapittelbøker',
            alternativeLabels: [],
            note: 'Brukes for bøker for barn og ungdom som primært består av tekst, men som inneholder et betydelig antall illustrasjoner. Vanligvis er bøkene korte og forholdsvis enkle, delt i korte kapitler og ment for lesere som er i ferd med å utvikle leseferdighetene sine - de som er på vei fra billedbøker til bøker med mer tekst. Brukes med relevante YF*-koder for å angi sjanger der det er hensiktsmessig, eller med YN*- eller YX*-kategorier som sekundærkoder for fortellinger om et spesielt tema. Foretrekk X*-koder for fortellinger i tegneserie- eller mangaform. Se også YBCS <a href="/thema/nb/YBCS">Billedbøker med tekst</a>',
            related: [
              {
                id: 'YBCS',
                label: 'Billedbøker med tekst',
                originalLabel: 'Billedbøker med tekst',
                note: 'Her: billedbøker som forteller en historie, hvor teksten er avhengig av illustrasjonene. Brukes med YF*-koder for å angi sjanger der det er hensiktsmessig, eller med YN*- eller YX*-kategorier som sekundærkoder for fortellinger om et spesielt tema. YBCS bør vanligvis være hovedkoden når den brukes sammen med YF*, YN* eller YX*. Foretrekk X*-koder for fortellinger i tegneserie- eller mangaform',
              },
            ],
            children: [],
          },
          {
            id: 'YBG',
            label: 'Aktivitetsbøker og interaktive bøker for barn',
            originalLabel: 'Aktivitetsbøker og interaktive bøker for barn',
            alternativeLabels: ['klistremerker', 'klistremerkebøker'],
            note: 'Her: aktivitetspakker for barn. Bruk YBG* for barns aktivitetsprodukter og interaktive produkter for alle aldersgrupper. Brukes for utgivelser hvor hovedformålet og -innholdet er en aktivitet som krever deltagelse og interaksjon, f.eks. noe man skal gjøre eller lage, enten det er produkter kun for engangsbruk (f.eks. klistremerkebøker, press ut-bøker), eller flergangsbruk (f.eks. ta- og føle på-bøker). Bruk YBG*-koder med andre Y*-koder der det er hensiktsmessig og med kvalifikatorer fra 5H* for aktiviteter som er knyttet til en spesiell sesong eller høytid. Foretrekk andre Y*-koder som hovedkategori hvor hovedinnholdet er viktigere enn den interaktive delen av utgivelsen. NB!: Detaljert informasjon om format beskrives i andre metadata',
            related: [],
            children: [
              {
                id: 'YBGC',
                label: 'Fargeleggings-, tegne-, malebøker',
                originalLabel: 'Fargeleggings-, tegne-, malebøker',
                alternativeLabels: [
                  'fargeleggingsbøker',
                  'fargelegging',
                  'tegnebøker',
                ],
                note: 'Her: aktivitetsbøker hvor man skal fargelegge, pakker med bok og male- eller tegnesaker, prikk-til-prikk-bøker, bøker med stempler, å lære å tegne eller male etc. Se også YNA <a href="/thema/nb/YNA">Barn og ungdom: kunst og kunstnere</a>',
                related: [
                  {
                    id: 'YNA',
                    label: 'Kunst og kunstnere',
                    originalLabel: 'Barn og ungdom: kunst og kunstnere',
                    note: 'Her: malere, malerier, fotografer og fotografering. Tildel stilkvalifikator der det er hensiktsmessig, YNB for biografier, YNP for bøker om hvordan man skal utføre kunstarten',
                  },
                ],
                children: [],
              },
              {
                id: 'YBGH',
                label: 'Skjulte objekter / Let og finn',
                originalLabel: 'Skjulte objekter / Let og finn',
                alternativeLabels: ['aktivitetsbøker', 'vrimlebøker'],
                note: 'Her: bøker hvor målet er å finne gjemte objekter eller figurer i bildene, myldrebøker, eller bøker som har svært detaljerte illustrasjoner, men som ikke nødvendigvis forteller ei historie. Se også YNVP <a href="/thema/nb/YNVP">Barn og ungdom: hjernetrim, aktivitetsbøker og spørrebøker</a>',
                related: [
                  {
                    id: 'YNVP',
                    label: 'Hjernetrim, aktivitetsbøker og spørrebøker',
                    originalLabel:
                      'Barn og ungdom: hjernetrim, aktivitetsbøker og spørrebøker',
                    note: 'Her: kryssord, sudoku, tallgåter og andre gåter, tall- og ordspill, labyrinter, minnespill, puslespill. Brukes for utgivelser hvor man skal løse noe, finne et svar etc. Se også YBGH <a href="/thema/nb/YBGH">Skjulte objekter / Let og finn</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'YBGS',
                label: 'Papirforming',
                originalLabel: 'Papirforming',
                alternativeLabels: [],
                note: 'Her: origamibøker, bøker med papirdukker eller papirmodeller som man kan lage eller sette sammen, ting som kan klippes eller trykkes ut, papirbretting eller andre aktiviteter for barn hvor man bruker papir og kartong',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YBL',
            label: 'Barn under skolealder / Tidlig læring',
            originalLabel: 'Barn under skolealder / Tidlig læring',
            alternativeLabels: [],
            note: 'Bruk YBL*-koder på produkter beregnet for barn som ikke har begynt på skolen (Ca. 0 til 6 år); tidlig læring. Bruk YBL* med YN* eller YX* for å angi spesielle emner der det er hensiktsmessig, og en 5AB* kvalifikator for alder',
            related: [],
            children: [
              {
                id: 'YBLA',
                label: 'Bokstaver og ord',
                originalLabel: 'Barn under skolealder: bokstaver og ord',
                alternativeLabels: [],
                note: 'Her: ABC-bøker, alfabetbøker, barnets første ord etc.',
                related: [],
                children: [],
              },
              {
                id: 'YBLB',
                label: 'Rim og regler',
                originalLabel: 'Barn under skolealder: rim og regler',
                alternativeLabels: [],
                note: 'Her: barnerim, enkle vers etc. Se også YDP <a href="/thema/nb/YDP">Barn og ungdom: lyrikk</a>',
                related: [
                  {
                    id: 'YDP',
                    label: 'Lyrikk',
                    originalLabel: 'Barn og ungdom: lyrikk',
                    note: 'Se også YBLB <a href="/thema/nb/YBLB">Barn under skolealder: rim og regler</a>, YFV <a href="/thema/nb/YFV">Skjønnlitteratur for barn og ungdom: historier på vers</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'YBLC',
                label: 'Tall og regning',
                originalLabel: 'Barn under skolealder: tall og regning',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'YBLD',
                label: 'Farger',
                originalLabel: 'Barn under skolealder: farger',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'YBLF',
                label: 'Motsetninger',
                originalLabel: 'Barn under skolealder: motsetninger',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'YBLH',
                label: 'Størrelse, former og mønstre',
                originalLabel:
                  'Barn under skolealder: størrelse, former og mønstre',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'YBLJ',
                label: 'Tid og årstider',
                originalLabel: 'Barn under skolealder: tid og årstider',
                alternativeLabels: [],
                note: 'Her: bøker om klokka, dagene, årstider etc.',
                related: [],
                children: [],
              },
              {
                id: 'YBLL',
                label: 'Natur og dyr',
                originalLabel: 'Barn under skolealder: natur og dyr',
                alternativeLabels: [],
                note: 'Her: dyreunger',
                related: [],
                children: [],
              },
              {
                id: 'YBLM',
                label: 'Daglige rutiner',
                originalLabel: 'Barn under skolealder: daglige rutiner',
                alternativeLabels: [],
                note: 'Her: hverdagsliv for små barn: bading, legging, tannpuss, måltider, soving, det å stå opp og kle på seg, hårstell etc.',
                related: [],
                children: [
                  {
                    id: 'YBLM1',
                    label: 'Pottetrening',
                    originalLabel: 'Barn under skolealder: pottetrening',
                    alternativeLabels: ['bleier', 'do'],
                    note: 'Her: dotrening, bøker som handler om bæsj, tiss og promp. Brukes for bøker som forklarer kroppsfunksjoner knyttet til det å gå på do, samt bøker som brukes for å lære barn til å slutte med bleier, og begynne å bruke potte eller gå på do',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YBLN',
                label: 'Opplevelser',
                originalLabel: 'Barn under skolealder: opplevelser',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'YBLN1',
                    label: 'Kroppen og sansene',
                    originalLabel: 'Barn under skolealder: kroppen og sansene',
                    alternativeLabels: [],
                    note: 'Her: lyder, ta og føle på, smaker og lukter, fingre og tær, smil og latter, ansikt, borte-tittei, gjemsel etc.',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YBLP',
                label: 'Mennesker som hjelper oss',
                originalLabel:
                  'Barn under skolealder: mennesker som hjelper oss',
                alternativeLabels: [],
                note: 'Her: forskjellige yrker som leger, lærere, sykepleiere brannmenn, postmenn',
                related: [],
                children: [],
              },
              {
                id: 'YBLQ',
                label: 'Familie',
                originalLabel: 'Barn under skolealder: familie',
                alternativeLabels: [],
                note: 'Brukes med YXF*-koder der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'YBLT',
                label: 'Kjøretøy',
                originalLabel: 'Barn under skolealder: kjøretøy',
                alternativeLabels: [
                  'heisekraner',
                  'båter',
                  'luftskip',
                  'jernbaner',
                  'ambulanser',
                  'brannbiler',
                  'motorsykler',
                ],
                note: 'Her: bøker om skip, fly, tog, lastebiler, biler, sykler, busser, traktorer, gravemaskiner og andre kjøretøy',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'YD',
        label: 'Poesi, antologier, årbøker',
        originalLabel: 'Barn og ungdom: poesi, antologier, årbøker',
        alternativeLabels: [],
        note: 'Bruk YD*-koder med kvalifikator fra 5A* intellektuelt nivå, der det er hensiktsmessig',
        related: [],
        children: [
          {
            id: 'YDA',
            label: 'Årbøker',
            originalLabel: 'Barn og ungdom: årbøker',
            alternativeLabels: [],
            note: 'Her: alle årbøker som er ment for barn og ungdom og som blir publisert årlig, periodika e.l. Brukes som første kode på alle årbøker, sammen med ytterligere en kode som angir type eller emne der det er mulig',
            related: [],
            children: [],
          },
          {
            id: 'YDC',
            label: 'Antologier',
            originalLabel: 'Barn og ungdom: antologier',
            alternativeLabels: [],
            note: 'Brukes sammen med en annen kode som viser litterær form, der det er hensiktsmessig. For eksempel med en YF*-kode for skjønnlitterære antologier, med YDP for lyrikkantologier. Brukes alene for antologier i ulike litterære former',
            related: [],
            children: [],
          },
          {
            id: 'YDP',
            label: 'Lyrikk',
            originalLabel: 'Barn og ungdom: lyrikk',
            alternativeLabels: [],
            note: 'Se også YBLB <a href="/thema/nb/YBLB">Barn under skolealder: rim og regler</a>, YFV <a href="/thema/nb/YFV">Skjønnlitteratur for barn og ungdom: historier på vers</a>',
            related: [
              {
                id: 'YBLB',
                label: 'Rim og regler',
                originalLabel: 'Barn under skolealder: rim og regler',
                note: 'Her: barnerim, enkle vers etc. Se også YDP <a href="/thema/nb/YDP">Barn og ungdom: lyrikk</a>',
              },
              {
                id: 'YFV',
                label: 'Historier på vers',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: historier på vers',
                note: 'Brukes for historier som barn eller ungdom som er skrevet i verseform. Brukes med andre YF*- eller YBC*-koder for å angi sjanger der det er mulig. Foretrekk YDP for dikt eller diktsamlinger. Se også YDP <a href="/thema/nb/YDP">Barn og ungdom: lyrikk</a>',
              },
            ],
            children: [],
          },
        ],
      },
      {
        id: 'YF',
        label: 'Skjønnlitteratur for barn og ungdom og sanne historier',
        originalLabel: 'Skjønnlitteratur for barn og ungdom og sanne historier',
        alternativeLabels: [],
        note: 'Ved bruk av YF*-koder, tildel også ALLTID en kvalifikator for intellektuelt nivå fra 5A*. YF*-koder kan brukes med kvalifikatorer for geografi og tidsperiode, der dette er spesielt relevant. Brukes med og før relevante YN*- eller YX*-koder for skjønnlitteratur med spesifikke temaer. MERK at for ALLE skjønnlitterære utgivelser for barn og ungdom, må den første koden være fra YF*. Brukes med kvalifikatorer fra 5H*, 5J*, 5L* eller 5P* der disse uttrykker et viktig aspekt ved fortellinga, for å angi at innholdet kun passer for eldre ungdommer; med YB* for å angi sjangeren til ei billed- eller kapittelbok; med YDC for skjønnlitterære antologier; YFZ*-koder kan brukes for å angi spesifikke egenskaper ved ei skjønnlitterær bok. Foretrekk X*-koder for tegneserier eller manga. Se også XADC <a href="/thema/nb/XADC">Tegneserier i klassisk europeisk stil / tradisjon</a>, XAKC <a href="/thema/nb/XAKC">Tegneserier i klassisk amerikansk stil / tradisjon</a>, XAMC <a href="/thema/nb/XAMC">Manga: Komodo</a>, XAMF <a href="/thema/nb/XAMF">Manga: Shôjo</a>, XAMG <a href="/thema/nb/XAMG">Manga: Shonen</a>',
        related: [
          {
            id: 'XADC',
            label: 'Tegneserier i klassisk europeisk stil / tradisjon',
            originalLabel: 'Tegneserier i klassisk europeisk stil / tradisjon',
            note: 'Her: klassiske europeiske tegneserier og tegneseriestriper for alle aldre, og som blir ansett som ‘klassiske’, og vanligvis, men ikke nødvendigvis, ble utgitt for første gang for flere tiår siden',
          },
          {
            id: 'XAKC',
            label: 'Tegneserier i klassisk amerikansk stil / tradisjon',
            originalLabel: 'Tegneserier i klassisk amerikansk stil / tradisjon',
            note: 'Her: tidlige og klassiske tegneseriekarakterer, inkludert såkalt ‘Golden Age’ eller ‘Silver age’ innen amerikansk tegneserietradisjon, og som passer for alle aldersnivå, og som blir ansett som ‘klassiske’, og vanligvis, men ikke nødvendigvis, ble utgitt for første gang for flere tiår siden',
          },
          {
            id: 'XAMC',
            label: 'Manga: Komodo',
            originalLabel: 'Manga: Komodo',
            note: 'Brukes for manga for barn av begge kjønn, aldersnivå ca. 6–10 år',
          },
          {
            id: 'XAMF',
            label: 'Manga: Shôjo',
            originalLabel: 'Manga: Shôjo',
            note: 'Brukes for manga for jenter, aldersnivå ca. 10–15 år',
          },
          {
            id: 'XAMG',
            label: 'Manga: Shonen',
            originalLabel: 'Manga: Shonen',
            note: 'Brukes for manga for gutter, aldersnivå ca. 10–15 år',
          },
        ],
        children: [
          {
            id: 'YFA',
            label: 'Klassisk litteratur',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: klassisk litteratur',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur for barn og ungdom og som er ansett eller presentert som ‘klassisk’. Vanligvis, men ikke nødvendigvis utgitt for flere tiår siden',
            related: [],
            children: [],
          },
          {
            id: 'YFB',
            label: 'Moderne litteratur',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: moderne skjønnlitteratur',
            alternativeLabels: [],
            note: 'Her: all moderne skjønnlitteratur for barn og ungdom, skjønnlitteratur som ikke er i en spesiell sjanger. Brukes med YX* eller YN* for å angi bokas emne, f.eks. med YXL* for fortellinger om sykdom',
            related: [],
            children: [],
          },
          {
            id: 'YFC',
            label: 'Spennende og eventyrlige fortellinger',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: spennende og actionfylte fortellinger',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'YFCA',
                label: 'Interaktive spennende og eventyrlige fortellinger',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: interaktive spennende og actionfylte fortellinger',
                alternativeLabels: [],
                note: 'Her: bøker, fysiske eller digitale, hvor leseren kan velge mellom alternativer for å endre fortellingens utfall. Brukes sammen med andre YF*-koder som angir sjanger. Se også YFZW <a href="/thema/nb/YFZW">Skjønnlitteratur for barn og ungdom: spill</a>',
                related: [
                  {
                    id: 'YFZW',
                    label: 'Rollespill',
                    originalLabel: 'Skjønnlitteratur for barn og ungdom: spill',
                    note: 'Her: litterære rollespill (LitRPG). Brukes for skjønnlitteratur som følger samme struktur som et tv-spill, eller har samme type utfordringer som man finner i et spill, eller som foregår i et tv-spill. Brukes med andre YF*-koder der det er hensiktsmessig. Se også YFCA <a href="/thema/nb/YFCA">Skjønnlitteratur for barn og ungdom: interaktive spennende og actionfylte fortellinger</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'YFCB',
                label: 'Thrillere',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: thrillere / spenning',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'YFCF',
                label: 'Krim og mysterier',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: krim og mysterier',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'YFCW',
                label: 'Militæret og krig',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: militæret og krig',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YFD',
            label: 'Grøssere og spøkelseshistorier',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: grøssere og spøkelsesfortellinger',
            alternativeLabels: [],
            note: 'Brukes for spøkelsesfortellinger og overnaturlige og skumle fortellinger. Brukes med YNX* for fortellinger om spesielle overnaturlige eller paranormale vesener. Se også YNXW <a href="/thema/nb/YNXW">Barn og ungdom: hekser, trollmenn og magikere</a>',
            related: [
              {
                id: 'YNXW',
                label: 'Hekser, trollmenn og magikere',
                originalLabel: 'Barn og ungdom: hekser, trollmenn og magikere',
                note: 'Se også YFD <a href="/thema/nb/YFD">Skjønnlitteratur for barn og ungdom: grøssere og spøkelsesfortellinger</a>',
              },
            ],
            children: [],
          },
          {
            id: 'YFE',
            label: 'Dystopier, utopier og spekulative romaner',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: dystopier, utopier og spekulative romaner',
            alternativeLabels: [],
            note: 'Her: skjønnlitteratur som foregår i et dystopisk eller utopisk samfunn. Brukes sammen med YFG eller andre YF* koder hvis mulig',
            related: [],
            children: [
              {
                id: 'YFEB',
                label: 'Apokalyptiske og postapokalyptiske fortellinger',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: apokalyptiske og postapokalyptiske fortellinger',
                alternativeLabels: [
                  'global katastrofe',
                  'invasjon fra verdensrommet',
                  'pest',
                  'økologisk katastrofe',
                  'oversanselige fenomener',
                  'overnaturlige fenomener',
                ],
                note: 'Her: historier om verdens undergang, sivilisasjoner som går under. Brukes for historier som foregår i verdener som har opplevd eller prøver å avverge en apokalyptisk hendelse, f.eks. forårsaket av klimaendringer, knapphet på ressurser, atomkrig, pandemier, kosmisk nedslag, zombieapokalypse, invasjon fra verdensrommet, dysgenikk etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YFF',
            label: 'Superhelter',
            originalLabel: 'Skjønnlitteratur for barn og ungdom: superhelter',
            alternativeLabels: ['superkrefter'],
            note: 'Brukes for fortellinger hvor hovedpersonen er en superhelt eller oppfattes som en slik',
            related: [],
            children: [],
          },
          {
            id: 'YFG',
            label: 'Science fiction',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: science fiction',
            alternativeLabels: [],
            note: 'Brukes med YNTT for tidsreiser, YNXF for fortellinger om romvesener eller UFO-er, YNNZ for fortellinger om romreiser, YNTC for fortellinger om KI, roboter, ny teknologi etc.',
            related: [],
            children: [
              {
                id: 'YFGR',
                label: 'Romopera',
                originalLabel: 'Skjønnlitteratur for barn og ungdom: romopera',
                alternativeLabels: ['Star Wars'],
                note: 'Her: stjernekriger. Brukes for science fiction hvor handlingen foregår i verdensrommet, i imaginære galakser eller universer, eller på forskjellige planeter, ofte med avanserte kulturer og sofistikert teknologi. Hovedpersonen er ofte et barn eller en ungdom',
                related: [],
                children: [],
              },
              {
                id: 'YFGS',
                label: 'Steampunk',
                originalLabel: 'Skjønnlitteratur for barn og ungdom: steampunk',
                alternativeLabels: [],
                note: 'Her: dieselpunk, cyberpunk, retrofuturisme. Brukes for science fiction som er basert på en alternativ verden hvor dampmaskiner og annen teknikk fra 1800-tallet har blitt den dominerende teknologien',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YFH',
            label: 'Fantasy',
            originalLabel: 'Skjønnlitteratur for barn og ungdom: fantasy',
            alternativeLabels: [],
            note: 'Her: paranormal fantasy. Bruk YFH* med YNX* der det er hensiktsmessig, med YNXB* for fortellinger som handler om det overnaturlige eller spesielle overnaturlige vesener',
            related: [],
            children: [
              {
                id: 'YFHB',
                label: 'Episk / heroisk fantasy',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: episk / heroisk fantasy',
                alternativeLabels: [],
                note: 'Her: høyfantasy (high fantasy)',
                related: [],
                children: [],
              },
              {
                id: 'YFHD',
                label: 'Magisk realisme',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: magisk realisme / magisk fantasy',
                alternativeLabels: [],
                note: 'Her: mangasjangeren magisk jente. Brukes for bøker hvor handlinga er lagt til en realistisk verden, men samtidig har magiske, mytiske, fantastiske eller overnaturlige elementer. Brukes også for bøker der magien selv - enten et magisk objekt, eller en person med magiske krefter - er historiens emne, ikke bare et middel for å oppnå et mål. Brukes med YNXW for historier om hekser, trollmenn eller magikere, eller med YNXB* for historier om andre magiske vesener',
                related: [],
                children: [],
              },
              {
                id: 'YFHG',
                label: 'Mytisk fantasy',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: mytisk fantasy',
                alternativeLabels: ['shenmo'],
                note: 'Her: gjenfortellinger av eventyr, arthuriansk fantasy, klassisk fantasy (basert på greske og romerske myter) etc. Brukes for skjønnlitteratur som er inspirert av, er ei gjenfortelling av eller som på en eller annen måte baserer seg på karakterer, fortellinger og emner fra myter, legender, folketro eller eventyr. F.eks. ei historie som bygger på et populært eventyr, men som er lagt til en moderne setting, eller ei historie som tar utgangspunkt i historia til en mindre viktig karakter i den originale teksten, ei historie som tar utgangspunkt i ‘hva hvis’, eller skaper en mashup av ulike sjangere',
                related: [],
                children: [],
              },
              {
                id: 'YFHH',
                label: 'Historisk fantasy',
                originalLabel: 'Skjønnlitteratur for ungdom: historisk fantasy',
                alternativeLabels: [],
                note: 'Brukes for fantasy som inneholder fantastiske elementer som magi eller overnaturlige vesener, men som er lagt til en gjenkjennbar historisk periode, kultur etc. Brukes med kvalifikatorer for geografi og tid der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'YFHK',
                label: 'Humoristisk fantasy',
                originalLabel:
                  'Skjønnlitteratur for ungdom: humoristisk fantasy',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'YFHR',
                label: 'Romantisk fantasy',
                originalLabel: 'Skjønnlitteratur for ungdom: romantisk fantasy',
                alternativeLabels: [],
                note: 'Her: vampyr-romantikk, overnaturlig romantikk etc. Brukes for fantasyfortellinger som har mange av sjangertrekkene fra romantikk, men som først og fremst er fantasy. Brukes med YNXB* for romantisk fantasy som inneholder paranormale eller overnaturlige vesener, 5X dersom innholdet er av eksplisitt art og 5A* der dette er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'YFHT',
                label: 'Dark fantasy',
                originalLabel: 'Skjønnlitteratur for ungdom: dark fantasy',
                alternativeLabels: [],
                note: 'Brukes for mørk fantasy, dvs. fantasy med mørkere og skremmende temaer. Inkluderer ofte elementer fra grøssere eller har en dyster og truende atmosfære, eller man får en følelse av frykt',
                related: [],
                children: [],
              },
              {
                id: 'YFHW',
                label: 'Moderne og urban fantasy',
                originalLabel:
                  'Skjønnlitteratur for ungdom: moderne og urban fantasy',
                alternativeLabels: [],
                note: 'Brukes for fantasy hvor handlinga er lagt til samtiden eller til gjenkjennbare moderne settinger (ut fra forfatterens levetid)',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YFJ',
            label: 'Tradisjonelle historier',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: tradisjonelle historier',
            alternativeLabels: [],
            note: 'Her: eventyr, folkeeventyr, myter, fabler, sagn og legender fortalt som fiksjon. Bøker om troll, nisser etc. Se også YFHG <a href="/thema/nb/YFHG">Skjønnlitteratur for barn og ungdom: mytisk fantasy</a>',
            related: [
              {
                id: 'YFHG',
                label: 'Mytisk fantasy',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: mytisk fantasy',
                note: 'Her: gjenfortellinger av eventyr, arthuriansk fantasy, klassisk fantasy (basert på greske og romerske myter) etc. Brukes for skjønnlitteratur som er inspirert av, er ei gjenfortelling av eller som på en eller annen måte baserer seg på karakterer, fortellinger og emner fra myter, legender, folketro eller eventyr. F.eks. ei historie som bygger på et populært eventyr, men som er lagt til en moderne setting, eller ei historie som tar utgangspunkt i historia til en mindre viktig karakter i den originale teksten, ei historie som tar utgangspunkt i ‘hva hvis’, eller skaper en mashup av ulike sjangere',
              },
            ],
            children: [],
          },
          {
            id: 'YFK',
            label: 'Religiøs litteratur',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: religiøs og spirituell litteratur',
            alternativeLabels: [],
            note: 'Her: fortellinger som er inspirert av tro eller som er ment å være inspirerende. Brukes for skjønnlitteratur som framhever et spesifikt religiøst, spirituelt eller visjonært synspunkt. Brukes med 5PG* eller YNR* for å angi religiøs tradisjon der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'YFM',
            label: 'Forelskelse, kjærlighet eller vennskap',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: relasjoner og forhold',
            alternativeLabels: ['stevnemøter', 'dating'],
            note: 'Brukes for historier om alle typer forhold. Brukes med YX* for å angi hvilken type forhold der det er hensiktsmessig, f.eks. YXHB for fortellinger om vennskap, eller YXHL for fortellinger om kjærlighet, eller YXH for fortellinger om relasjoner i grupper av jevnaldrende. Se også YXHB <a href="/thema/nb/YXHB">Barn og ungdom: personlige og sosiale spørsmål: venner og vennskap</a>',
            related: [
              {
                id: 'YXHB',
                label: 'Venner og vennskap',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: venner og vennskap',
                note: 'Her: å få nye venner, vennegrupper, temaer rundt vennskap som konformitet, popularitet eller isolasjon innen ei gruppe av venner, vennskap som opphører, råd om det å få nye venner etc. Se også YFM <a href="/thema/nb/YFM">Skjønnlitteratur for barn og ungdom: relasjoner og forhold</a>',
              },
            ],
            children: [
              {
                id: 'YFMF',
                label: 'Vennskap',
                originalLabel: 'Skjønnlitteratur for barn og ungdom: vennskap',
                alternativeLabels: [],
                note: 'Brukes for historier hvor fortellingens hovedtema er vennskap mellom hovedpersoner som er barn eller ungdom',
                related: [],
                children: [],
              },
              {
                id: 'YFMR',
                label: 'Kjærlighet og forelskelse',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: forelskelse og kjærlighet',
                alternativeLabels: [],
                note: 'Brukes for moderne romantikk, romantiske komedier, fortellinger om den første forelskelsen, ulykkelig kjærlighet etc. Brukes for fortellinger som tydelig tilhører sjangeren romantikk og hvor hovedtemaet er en romantisk relasjon, som er spesielt skrevet for tenåringer og tar for seg forelskelse og kjærlighet fra en tenårings synsvinkel. Brukes med YNX* der det er hensiktsmessig, med YFT og kvalifikator for tid for historisk romantikk, med YFQ for humoristisk romantikk etc. Se også YFHR <a href="/thema/nb/YFHR">Skjønnlitteratur for ungdom: romantisk fantasy</a>',
                related: [
                  {
                    id: 'YFHR',
                    label: 'Romantisk fantasy',
                    originalLabel:
                      'Skjønnlitteratur for ungdom: romantisk fantasy',
                    note: 'Her: vampyr-romantikk, overnaturlig romantikk etc. Brukes for fantasyfortellinger som har mange av sjangertrekkene fra romantikk, men som først og fremst er fantasy. Brukes med YNXB* for romantisk fantasy som inneholder paranormale eller overnaturlige vesener, 5X dersom innholdet er av eksplisitt art og 5A* der dette er hensiktsmessig',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'YFN',
            label: 'Hjem og familie',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: hjem og familie',
            alternativeLabels: [],
            note: 'Brukes for bøker hvor familier eller familiemedlemmer er fortellingas hovedtema, eller for fortellinger som tar for seg en hvilken som helst familie. Brukes sammen med koder fra YXF*-delen for skjønnlitteratur som tar for seg familieproblemer',
            related: [],
            children: [],
          },
          {
            id: 'YFP',
            label: 'Natur og dyr',
            originalLabel: 'Skjønnlitteratur for barn og ungdom: natur og dyr',
            alternativeLabels: [],
            note: 'Brukes med YNN*-koder for å angi hvilken type dyre- eller naturhistorie det er snakk om, f.eks. YNNJ24 for historier om eller med hester, eller YNNJ21 for historier om eller med hunder',
            related: [],
            children: [],
          },
          {
            id: 'YFQ',
            label: 'Humor',
            originalLabel: 'Skjønnlitteratur for barn og ungdom: humor',
            alternativeLabels: [],
            note: 'Brukes etter andre YF*-koder som angir sjanger, hvis mulig',
            related: [],
            children: [],
          },
          {
            id: 'YFR',
            label: 'Idrettshistorier',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: idrettshistorier',
            alternativeLabels: [],
            note: 'Brukes med YNW* for å angi hvilken sport det er snakk om',
            related: [],
            children: [],
          },
          {
            id: 'YFS',
            label: 'Skolehistorier',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: skolehistorier',
            alternativeLabels: [],
            note: 'Her: skolestart, det å begynne på en ny skole, historier som i hovedsak foregår i et skolemiljø. Brukes med koder fra 5L* for å angi skoletrinn',
            related: [],
            children: [],
          },
          {
            id: 'YFT',
            label: 'Historiske fortellinger',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: historiske fortellinger',
            alternativeLabels: [],
            note: 'Brukes for fortellinger der handlingen er lagt til fortiden (vanligvis før forfatterens levetid). Tidsperioden er en viktig del av historien og den skildrer tidsånden når det gjelder skikker og sosiale forhold. Brukes med andre YF* koder og kvalifikatorer for geografi og/eller tidsperiode der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'YFU',
            label: 'Noveller',
            originalLabel: 'Skjønnlitteratur for barn og ungdom: noveller',
            alternativeLabels: [],
            note: 'Brukes etter andre YF*-koder som angir sjanger, hvis mulig',
            related: [],
            children: [],
          },
          {
            id: 'YFV',
            label: 'Historier på vers',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: historier på vers',
            alternativeLabels: [],
            note: 'Brukes for historier som barn eller ungdom som er skrevet i verseform. Brukes med andre YF*- eller YBC*-koder for å angi sjanger der det er mulig. Foretrekk YDP for dikt eller diktsamlinger. Se også YDP <a href="/thema/nb/YDP">Barn og ungdom: lyrikk</a>',
            related: [
              {
                id: 'YDP',
                label: 'Lyrikk',
                originalLabel: 'Barn og ungdom: lyrikk',
                note: 'Se også YBLB <a href="/thema/nb/YBLB">Barn under skolealder: rim og regler</a>, YFV <a href="/thema/nb/YFV">Skjønnlitteratur for barn og ungdom: historier på vers</a>',
              },
            ],
            children: [],
          },
          {
            id: 'YFX',
            label: 'Biografiske fortellinger',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: biografiske / selvbiografier fortellinger',
            alternativeLabels: [],
            note: 'Brukes for biografier og selvbiografier om virkelige personer som er skrevet som skjønnlitteratur, eller hvor en forfatter bruker fiksjonaliserte versjoner av virkelige minner eller erfaringer fra sitt eget liv som en viktig og integrert del av fortellinga',
            related: [],
            children: [],
          },
          {
            id: 'YFY',
            label: 'Sanne historier skrevet som skjønnlitteratur',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: sanne historier skrevet som skjønnlitteratur',
            alternativeLabels: [],
            note: 'Her: sanne historier, f. eks. om å overleve, oppdagelser, konflikter, krig etc. skrevet som skjønnlitteratur. Brukes med andre Y*-koder for å angi emne og kvalifikatorer for geografi og tid der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'YFZ',
            label: 'Spesielle utgivelser',
            originalLabel:
              'Skjønnlitteratur for barn og ungdom: spesielle utgivelser',
            alternativeLabels: [],
            note: 'Bruk YFZ* med YF* for å angi sjanger. NB!: YFZ* bør vanligvis ikke angis som den viktigste koden (skal ikke stå først)',
            related: [],
            children: [
              {
                id: 'YFZC',
                label: 'Julekalenderbøker',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: julekalenderbøker',
                alternativeLabels: [
                  'adventskalenderbøker',
                  'adventskalender',
                  'julekalender',
                ],
                note: 'Brukes for historier ment for barn og ungdom og som vanligvis er delt i 24 eller 25 kapitler, ment for å leses som ei nedtelling til jul i adventstida. Brukes med 5HPDA',
                related: [],
                children: [],
              },
              {
                id: 'YFZR',
                label: 'Serielitteratur',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: serielitteratur',
                alternativeLabels: [],
                note: 'Brukes for romantikk, science fiction eller annen skjønnlitteratur tilhørende en sjanger og som vanligvis utgis med en fast utgivelsesfrekvens. Brukes med andre YF*-koder for å angi sjanger eller type. YFZR-koder bør aldri være første eller eneste emnekode',
                related: [],
                children: [],
              },
              {
                id: 'YFZS',
                label: 'Ranobe',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: ranobe (lettroman i japansk stil)',
                alternativeLabels: ['lettroman'],
                note: 'Brukes for kortromaner for barn og ungdom, vanligvis dialog- og karakterdrevet, og vanligvis med manga/anime-illustrasjoner. Brukes med andre YF*-koder for å angi sjanger',
                related: [],
                children: [],
              },
              {
                id: 'YFZT',
                label: 'Oversatt skjønnlitteratur',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: oversatt skjønnlitteratur',
                alternativeLabels: [],
                note: 'Brukes for skjønnlitteratur som er oversatt fra et annet språk. Brukes ikke alene, og ikke som første kode, men med andre YF*-koder for å sikre at klassifikasjonen gir mening utover selve oversettelsen av verket. IKKE bruk kvalifikator for geografi eller språk for å beskrive originalspråket. Dette beskrives i andre metadata. Du kan likevel bruke kvalifikator for sted, hvis det er relevant for handlinga i boka',
                related: [],
                children: [],
              },
              {
                id: 'YFZV',
                label:
                  'Historier inspirert av eller omarbeidet fra andre medier',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: historier inspirert av eller omarbeidet fra andre medier',
                alternativeLabels: [],
                note: 'Brukes for skjønnlitteratur som er utgitt som en spinoff eller som et ledsagende produkt til allerede eksisterende medieprodukter eller -varer som ikke er bøker. F.eks. romaner som er inspirert av populære filmer, spill eller TV-serier. Skal IKKE brukes for romaner som i etterkant er blitt filmatisert etc. Brukes med andre YF*-koder for å angi sjanger eller type. YFZV-koder bør aldri være første eller eneste emnekode',
                related: [],
                children: [],
              },
              {
                id: 'YFZW',
                label: 'Rollespill',
                originalLabel: 'Skjønnlitteratur for barn og ungdom: spill',
                alternativeLabels: ['LitRPG'],
                note: 'Her: litterære rollespill (LitRPG). Brukes for skjønnlitteratur som følger samme struktur som et tv-spill, eller har samme type utfordringer som man finner i et spill, eller som foregår i et tv-spill. Brukes med andre YF*-koder der det er hensiktsmessig. Se også YFCA <a href="/thema/nb/YFCA">Skjønnlitteratur for barn og ungdom: interaktive spennende og actionfylte fortellinger</a>',
                related: [
                  {
                    id: 'YFCA',
                    label: 'Interaktive spennende og eventyrlige fortellinger',
                    originalLabel:
                      'Skjønnlitteratur for barn og ungdom: interaktive spennende og actionfylte fortellinger',
                    note: 'Her: bøker, fysiske eller digitale, hvor leseren kan velge mellom alternativer for å endre fortellingens utfall. Brukes sammen med andre YF*-koder som angir sjanger. Se også YFZW <a href="/thema/nb/YFZW">Skjønnlitteratur for barn og ungdom: spill</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'YFZZ',
                label: 'Ledsagende bøker',
                originalLabel:
                  'Skjønnlitteratur for barn og ungdom: ledsagende bøker',
                alternativeLabels: [],
                note: 'Brukes for bøker som er utgitt som ledsagende bøker til populære serier eller enkelttitler, f.eks. et atlas over en mytisk verden, en oversikt over karakterer, en guide til serien etc. Brukes med andre Y*-koder eller alene der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'YN',
        label: 'Barn og ungdom: emne',
        originalLabel: 'Barn og ungdom: emne',
        alternativeLabels: [],
        note: 'Bruk YN*-koder for faglitteratur av generell interesse som ikke dekkes andre steder og som ikke er laget spesielt for skolebruk. Bruk YN*-koder alene for faglitteratur, eller etter relevant YF*-kode for skjønnlitteratur som omhandler spesifikke emner der det er hensiktsmessig. Bruk alle YN* med en kvalifikator for intellektuelt nivå 5A*. NB!: YN kan brukes med A*-W*-koder for å angi emne der det ikke fins en mer spesifikk kode i Y*. Dersom man kombinerer YN* med A*-W*, MÅ YN*-koden eller en annen kode fra Y* angis som den viktigste koden og kombineres med en kvalifikator fra 5A*',
        related: [],
        children: [
          {
            id: 'YNA',
            label: 'Kunst og kunstnere',
            originalLabel: 'Barn og ungdom: kunst og kunstnere',
            alternativeLabels: [],
            note: 'Her: malere, malerier, fotografer og fotografering. Tildel stilkvalifikator der det er hensiktsmessig, YNB for biografier, YNP for bøker om hvordan man skal utføre kunstarten',
            related: [],
            children: [],
          },
          {
            id: 'YNB',
            label: 'Biografier og selvbiografier',
            originalLabel: 'Barn og ungdom: biografier og selvbiografier',
            alternativeLabels: ['rollemodeller', 'berømtheter'],
            note: 'Brukes både for enkeltbiografier og for biografier som handler om flere personer, bøker om helter og heltinner, inspirerende personer, kjendiser, en persons dagbøker. Brukes med andre YN*-koder for å angi ulike sjangre innen biografi',
            related: [],
            children: [],
          },
          {
            id: 'YNC',
            label: 'Musikk',
            originalLabel: 'Barn og ungdom: musikk',
            alternativeLabels: ['band'],
            note: 'Her: bøker om musikk eller musikere, popgrupper etc. Tildel stilkvalifikator der det er hensiktsmessig, YNB for biografier om sangere, musikere eller grupper',
            related: [],
            children: [
              {
                id: 'YNCB',
                label: 'Musikkinstrumenter',
                originalLabel: 'Barn og ungdom: musikkinstrumenter',
                alternativeLabels: [],
                note: 'Her: bøker om å lære å spille et instrument som piano, gitar etc. Brukes med AVR* for å angi hvilket instrument det er snakk om, der det er hensiktsmessig. NB!: dersom man kombinerer Y* med AVR* MÅ Y* angis som den viktigste koden og kombineres med en kvalifikator for 5A*',
                related: [],
                children: [],
              },
              {
                id: 'YNCS',
                label: 'Sangbøker',
                originalLabel: 'Barn og ungdom: sangbøker',
                alternativeLabels: [],
                note: 'Her: noter, det å lære å synge. Brukes med kvalifikatorer for stil der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YND',
            label: 'Drama og scenekunst',
            originalLabel: 'Barn og ungdom: drama og scenekunst',
            alternativeLabels: [],
            note: 'Her: sirkus, skuespillerteknikker, teater, audition etc. Brukes med kvalifikatorer for stil der det er hensiktsmessig, eller med YNB for biografier om utøvende kunstnere',
            related: [],
            children: [
              {
                id: 'YNDB',
                label: 'Dans, ballett',
                originalLabel: 'Barn og ungdom: dans, ballett',
                alternativeLabels: ['ballerinaer'],
                note: 'Her: det å lære å danse. Brukes med kvalifikatorer for stil der det er hensiktsmessig, eller med YNB for biografier om dansere eller dansegrupper',
                related: [],
                children: [],
              },
              {
                id: 'YNDS',
                label: 'Manus',
                originalLabel: 'Barn og ungdom: manus',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YNF',
            label: 'TV og film',
            originalLabel: 'Barn og ungdom: tv og film',
            alternativeLabels: [],
            note: 'Her: titler om å lage film, TV-serier, strømming, animasjon, radio, podkast etc. Brukes med kvalifikatorer for stil der det er hensiktsmessig, eller med YNB for biografier om skuespillere, TV- og filmstjerner eller -personligheter, kjendiser',
            related: [],
            children: [],
          },
          {
            id: 'YNG',
            label: 'Allmennkunnskap og interessante fakta',
            originalLabel:
              'Barn og ungdom: allmennkunnskap og interessante fakta',
            alternativeLabels: ['trivia'],

            related: [],
            children: [
              {
                id: 'YNGL',
                label: 'Informasjonsvitenskap',
                originalLabel: 'Barn og ungdom: informasjonsvitenskap',
                alternativeLabels: [],
                note: 'Her: titler om bibliotek, museum, skoler, det å bruke eller finne informasjon eller bruke informasjonsressurser etc. Brukes for informative bøker om museum, gallerier, bibliotek og andre kulturinstitusjoner, og om deres innhold',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YNH',
            label: 'Historie',
            originalLabel: 'Barn og ungdom: historie',
            alternativeLabels: [],
            note: 'Her: arkeologi. Brukes med kvalifikator(er) for geografi, tidsperiode eller stil der det er hensiktsmessig og det er snakk om bøker som handler om spesifikke sivilisasjoner, steder, folkeslag eller -grupper, perioder i menneskets historie etc. Bruk YNB for biografier om spesifikke, historiske personer, YR* for leksikon, historiske tidslinjer, eller andre Y*-koder for bøker om spesifikke emner i en historisk sammenheng. Se også YNB <a href="/thema/nb/YNB">Barn og ungdom: biografier og selvbiografier</a>, YPJH <a href="/thema/nb/YPJH">Læremidler: historie</a>',
            related: [
              {
                id: 'YNB',
                label: 'Biografier og selvbiografier',
                originalLabel: 'Barn og ungdom: biografier og selvbiografier',
                note: 'Brukes både for enkeltbiografier og for biografier som handler om flere personer, bøker om helter og heltinner, inspirerende personer, kjendiser, en persons dagbøker. Brukes med andre YN*-koder for å angi ulike sjangre innen biografi',
              },
              {
                id: 'YPJH',
                label: 'Læremidler: historie',
                originalLabel: 'Læremidler: historie',
                note: 'Brukes med kvalifikator(er) for tidsperiode og pedagogisk formål der det er hensiktsmessig. NB!: for læremidler innen historie kan YPJH kombineres med koder fra N*. Dersom de kombineres, MÅ alltid en kode fra YP* angis som den viktigste koden og kombineres med en kvalifikator for pedagogisk formål der det er hensiktsmessig',
              },
            ],
            children: [
              {
                id: 'YNHA',
                label: 'Eventyrere og røvere',
                originalLabel: 'Barn og ungdom: eventyrere og røvere',
                alternativeLabels: [
                  'Robin Hood',
                  'spioner',
                  'vikinger',
                  'cowboyer',
                ],
                note: 'Her: skattejegere, berømte historiske helter, skurker, eventyrere etc. Brukes med YNB for biografier og kvalifikatorer for geografi og/eller tidsperiode der det er hensiktsmessig. Se også YNB <a href="/thema/nb/YNB">Barn og ungdom: biografier og selvbiografier</a>',
                related: [
                  {
                    id: 'YNB',
                    label: 'Biografier og selvbiografier',
                    originalLabel:
                      'Barn og ungdom: biografier og selvbiografier',
                    note: 'Brukes både for enkeltbiografier og for biografier som handler om flere personer, bøker om helter og heltinner, inspirerende personer, kjendiser, en persons dagbøker. Brukes med andre YN*-koder for å angi ulike sjangre innen biografi',
                  },
                ],
                children: [
                  {
                    id: 'YNHA1',
                    label: 'Sjørøvere',
                    originalLabel: 'Barn og ungdom: sjørøvere',
                    alternativeLabels: ['pirater'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YNHD',
                label: 'Oppdagelser og oppdagelsesreiser',
                originalLabel:
                  'Barn og ungdom: oppdagelser og oppdagelsesreiser',
                alternativeLabels: [],
                note: 'Her: oppdagelsesreisende, ekspedisjoner, første kontakt med andre kontinenter og folk etc. Brukes med YNB for biografier og kvalifikatorer for geografi og/eller tidsperiode der det er hensiktsmessig. Se også YNB <a href="/thema/nb/YNB">Barn og ungdom: biografier og selvbiografier</a>',
                related: [
                  {
                    id: 'YNB',
                    label: 'Biografier og selvbiografier',
                    originalLabel:
                      'Barn og ungdom: biografier og selvbiografier',
                    note: 'Brukes både for enkeltbiografier og for biografier som handler om flere personer, bøker om helter og heltinner, inspirerende personer, kjendiser, en persons dagbøker. Brukes med andre YN*-koder for å angi ulike sjangre innen biografi',
                  },
                ],
                children: [],
              },
              {
                id: 'YNHP',
                label: 'Barndom i «gamle dager»',
                originalLabel: 'Barn og ungdom: barndom i «gamle dager»',
                alternativeLabels: [],
                note: 'Brukes med kvalifikator(er) for geografi og tidsperiode der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YNJ',
            label: 'Krig, slag og væpnede styrker',
            originalLabel: 'Barn og ungdom: krig, slag og væpnede styrker',
            alternativeLabels: [],
            note: 'Her: soldater, gladiatorer, krigere, kriger, uniformer, militære kjøretøy. Brukes andre YN*-koder og med kvalifikator(er) for geografi og tidsperiode der det er hensiktsmessig. Se også YXZW <a href="/thema/nb/YXZW">Barn og ungdom: personlige og sosiale spørsmål: krig og konflikter</a>',
            related: [
              {
                id: 'YXZW',
                label: 'Krig og konflikter',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: krig og konflikter',
              },
            ],
            children: [
              {
                id: 'YNJC',
                label: 'Borger og riddere',
                originalLabel: 'Barn og ungdom: borger og riddere',
                alternativeLabels: [],
                note: 'Her: kriger i middelalderen',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YNK',
            label: 'Arbeid og samfunn',
            originalLabel: 'Barn og ungdom: arbeid, politikk og samfunn',
            alternativeLabels: [],
            note: 'Her: arbeidsliv, næringsliv, industri, yrker, jobber, det å jobbe, samfunnsliv, samfunnsanalyse, hverdagsliv etc. Brukes med YNH for historie, YXZ* for sosiale forhold',
            related: [],
            children: [
              {
                id: 'YNKA',
                label: 'Politikk',
                originalLabel: 'Barn og ungdom: politikk og regjering',
                alternativeLabels: [
                  'presidenter',
                  'statsministre',
                  'politikere',
                  'aktivivister',
                ],
                note: 'Brukes for bøker om politikere, politikk og politisk aktivitet, politiske institusjoner, myndigheter, menneskerettigheter, politiske debatter, konsepter, ideologier, strategier, partier eller bevegelser. Brukes med YNB og YNH der det er hensiktsmessig, for biografier om politiske skikkelser eller historiske bøker. NB!: YNKA kan kombineres med JP* dersom det ikke fins en mer spesifikk Y*-kode, men dersom YNKA og JP* kombineres, MÅ Y*-koden angis som den viktigste, og kombineres med en kvalifikator fra 5A*',
                related: [],
                children: [],
              },
              {
                id: 'YNKC',
                label: 'Jus, politi og kriminalitet',
                originalLabel: 'Barn og ungdom: jus, politi og kriminalitet',
                alternativeLabels: [],
                note: 'Brukes for bøker som tar for seg kriminalitet, lover, rettshåndhevelse, eller som handler om de som jobber innen disse sektorene som politi, dommere, advokater etc., eller som handler om kriminelle eller kriminalitet. Brukes med YNB for biografier eller YNH for historie',
                related: [],
                children: [],
              },
              {
                id: 'YNKG',
                label: 'Redningstjenester',
                originalLabel:
                  'Barn og ungdom: helsevesen og redningstjenester',
                alternativeLabels: [
                  'paramedics',
                  'ambulansetjenester',
                  'nødetater',
                  'fjellredning',
                  'fjellredningstjenester',
                  'redingsskøyter',
                  'redningsselskapet',
                  'helsetjenester',
                  'helsevesen',
                  'helsetjenester',
                ],
                note: 'Her: leger, sjukepleiere, helsearbeidere, ambulanser, brannvesen, redningstjenester, det å være på sjukehuset, gå til legen, tannlegen, brannstasjonen etc. Brukes for bøker om helsetjenesten og nødetatene eller mennesker som jobber i disse sektorene. Brukes med YNB for biografier',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YNL',
            label: 'Litteratur, bøker og forfattere',
            originalLabel: 'Barn og ungdom: litteratur, bøker og forfattere',
            alternativeLabels: [],
            note: 'Brukes med YNB for biografier',
            related: [],
            children: [],
          },
          {
            id: 'YNM',
            label: 'Folk og steder',
            originalLabel: 'Barn og ungdom: folk og steder',
            alternativeLabels: [],
            note: 'Her: bøker om reiser og reisehåndbøker for barn og ungdom. Brukes med kvalifikator(er) for geografi eller 5P* der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'YNMC',
                label: 'Land, kulturer og nasjonal identitet',
                originalLabel:
                  'Barn og ungdom: land, kulturer og nasjonal identitet',
                alternativeLabels: ['bunader'],
                note: 'Her: de enkelte lands symboler, monumenter, klesdrakter, nasjonaldrakter, mat flagg, skikker etc. Brukes for bøker som tar for seg land eller forskjellige aspekter ved kultur og samfunn i enkelte land eller regioner. Brukes med kvalifikator(er) for geografi der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'YNMD',
                label: 'Feiringer, høytider, festivaler og ferier',
                originalLabel:
                  'Barn og ungdom: feiringer, høytider, festivaler og ferier',
                alternativeLabels: [],
                note: 'Brukes med kvalifikatorer fra 5H* der det er hensiktsmessig. Se også YNR* <a href="/thema/nb/YNR">Barn og ungdom: filosofi, religion og tro</a> og dens underkategorier, 5H* <a href="/thema/nb/5H">Høytider, begivenheter og årstider</a> og dens underkategorier',
                related: [
                  {
                    id: '5H',
                    label: 'Høytider, begivenheter og årstider',
                    originalLabel: 'Høytider, begivenheter og årstider',
                    note: 'Bruk koder fra 5H* for å gi en indikasjon på at innholdet i ei bok hovedsakelig er knyttet til ei høytid, feiring eller årstid, f.eks. 5HPD for ei julefortelling, 5HKA for ei bok om bursdager. IKKE bruk kodene for å sammenstille lister over bøker som skal selges i et gitt tidsrom (denne informasjonen sendes i andre metadata), dersom bøkene ikke har innhold som tilsier en 5H*-kode',
                  },
                  {
                    id: 'YNR',
                    label: 'Filosofi, religion og tro',
                    originalLabel: 'Barn og ungdom: filosofi, religion og tro',
                    note: 'Her: spirituelle eller inspirerende bøker som handler om tro, det å ikke tro, agnostisisme, ateisme. Bruk YNR*-koder for filosofiske retninger, trossystemer, spirituelle tradisjoner, religion eller fortellinger fra en religiøs tradisjon. Brukes med andre Y*-koder og kvalifikator(er) fra 5PG* der det er hensiktsmessig. Se også YPJN* <a href="/thema/nb/YPJN">Læremidler: religion</a> og dens underkategorier, YXZR <a href="/thema/nb/YXZR">Barn og ungdom: personlige og sosiale spørsmål: religion</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'YNMF',
                label: 'Jenter og kvinner',
                originalLabel: 'Barn og ungdom: jenter og kvinner',
                alternativeLabels: ['feminister', 'hunkjønn'],
                note: 'Brukes for bøker hvor det å være jente eller kvinne er et sentralt tema. Brukes med YNB for biografier, YNH for historie, og andre Y*-koder der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'YNMH',
                label: 'Gutter og menn',
                originalLabel: 'Barn og ungdom: gutter og menn',
                alternativeLabels: ['hankjønn'],
                note: 'Brukes for bøker hvor det å være gutt eller mann er et sentralt tema. Brukes med YNB for biografier, YNH for historie, og andre Y*-koder der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'YNMK',
                label: 'Byer og byliv',
                originalLabel: 'Barn og ungdom: byer og byliv',
                alternativeLabels: ['drabantbyer'],
                note: 'Her: forsteder, indre by, storbyer, småbyer etc. Brukes for bøker som foregår i urbane miljøer eller bøker om spesifikke byer. Brukes med kvalifikator(er) for sted der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'YNML',
                label: 'Bygder og livet på gården',
                originalLabel: 'Barn og ungdom: bygder og livet på gården',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'YNMP',
                label: 'Hverdagsliv',
                originalLabel: 'Barn og ungdom: hverdagsliv',
                alternativeLabels: [],
                note: 'Her: hjemmet mitt, det stedet/huset, leiligheten jeg bor i, det å flytte til et nytt sted, daglige rutiner og hverdagsliv',
                related: [],
                children: [],
              },
              {
                id: 'YNMW',
                label: 'Dronninger, konger, prinsesser, prinser etc.',
                originalLabel:
                  'Barn og ungdom: dronninger, konger, prinsesser, prinser etc.',
                alternativeLabels: [],
                note: 'Her: kongelige, keisere, keiserinner. Brukes for titler om kongelige eller titler som har en kongelig hovedperson. Brukes med YNB for biografier, YNH for historie',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YNN',
            label: 'Natur og dyr, naturens verden',
            originalLabel: 'Barn og ungdom: natur og dyr, naturens verden',
            alternativeLabels: [],
            note: 'Her: livsvitenskap, evolusjon, økologi, naturhistorie',
            related: [],
            children: [
              {
                id: 'YNNA',
                label: 'Dinosaurer og urtiden',
                originalLabel: 'Barn og ungdom: dinosaurer og urtiden',
                alternativeLabels: ['istid'],
                note: 'Her: mammuter, fossiler, utdødde dyr, paleontologi, forhistoriske pattedyr, istidene. Brukes med kvalifikatorer for tidsperiode der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'YNNB',
                label: 'Ville dyr og naturområder',
                originalLabel: 'Barn og ungdom: ville dyr og naturområder',
                alternativeLabels: [],
                note: 'Se også YXZG <a href="/thema/nb/YXZG">Barn og ungdom: personlige og sosiale spørsmål: miljøspørsmål</a>',
                related: [
                  {
                    id: 'YXZG',
                    label: 'Miljøspørsmål',
                    originalLabel:
                      'Barn og ungdom: personlige og sosiale spørsmål: miljøspørsmål',
                    note: 'Her: bærekraft, klimaendringer, klimakrise, klimastreiker, forurensning, resirkulering, bærekraft, miljøvern, naturvern, vannforbruk, forvaltning av naturressurser, reduksjon av forbruk. Brukes for bøker som handler om disse emnene og for bøker som har forslag til løsninger, eller hva ungdom kan gjøre selv',
                  },
                ],
                children: [
                  {
                    id: 'YNNB1',
                    label: 'Hav og sjøer',
                    originalLabel:
                      'Barn og ungdom: ville dyr og naturområder: hav og sjøer',
                    alternativeLabels: ['kyst', 'kysten', 'klipper'],
                    note: 'Her: strandlinje, kystlinje, strender etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNNB2',
                    label: 'Jungel og tropisk regnskog',
                    originalLabel:
                      'Barn og ungdom: ville dyr og naturområder: jungel og tropisk regnskog',
                    alternativeLabels: [],
                    note: 'Her: regnskoger',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNNB3',
                    label: 'Ørken',
                    originalLabel:
                      'Barn og ungdom: ville dyr og naturområder: ørken',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNNB4',
                    label: 'Skoger',
                    originalLabel:
                      'Barn og ungdom: ville dyr og naturområder: skoger',
                    alternativeLabels: [],
                    note: 'Her: skogsområder',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNNB5',
                    label: 'Savanner og gressletter',
                    originalLabel:
                      'Barn og ungdom: ville dyr og naturområder: savanner og gressletter',
                    alternativeLabels: [],
                    note: 'Her: prærier, enger, stepper',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNNB9',
                    label: 'Is, snø og tundra',
                    originalLabel:
                      'Barn og ungdom: ville dyr og naturområder: is, snø og tundra',
                    alternativeLabels: [],
                    note: 'Her: polare regioner og høyfjellsområder',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YNNC',
                label: 'Økosystemer',
                originalLabel: 'Barn og ungdom: økosystemer',
                alternativeLabels: [],
                note: 'Her: bøker om hele økosystemer. Se også YXZG <a href="/thema/nb/YXZG">Barn og ungdom: personlige og sosiale spørsmål: miljøspørsmål</a>',
                related: [
                  {
                    id: 'YXZG',
                    label: 'Miljøspørsmål',
                    originalLabel:
                      'Barn og ungdom: personlige og sosiale spørsmål: miljøspørsmål',
                    note: 'Her: bærekraft, klimaendringer, klimakrise, klimastreiker, forurensning, resirkulering, bærekraft, miljøvern, naturvern, vannforbruk, forvaltning av naturressurser, reduksjon av forbruk. Brukes for bøker som handler om disse emnene og for bøker som har forslag til løsninger, eller hva ungdom kan gjøre selv',
                  },
                ],
                children: [],
              },
              {
                id: 'YNND',
                label: 'Dyrelyder',
                originalLabel: 'Barn og ungdom: lyder fra naturen',
                alternativeLabels: [],
                note: 'Brukes for bøker om dyrelyder, eller bøker med lydeffekter som gjør at man kan lære om eller høre fuglesang, hvordan dyr kommuniserer, lyder fra havet, skoger, været etc. Brukes med YNN*, f.eks. YNNK for fuglesang, YNNJ14 for lyder av hval',
                related: [],
                children: [],
              },
              {
                id: 'YNNF',
                label: 'Husdyr',
                originalLabel: 'Barn og ungdom: husdyr',
                alternativeLabels: [
                  'kylling',
                  'and',
                  'ender',
                  'gås',
                  'gjess',
                  'kalkun',
                  'kyr',
                  'kuer',
                  'gris',
                  'sau',
                ],
                note: 'Brukes for bøker om dyra på gården eller for bøker om husdyr som ikke har sin egen kode',
                related: [],
                children: [],
              },
              {
                id: 'YNNH',
                label: 'Kjæledyr',
                originalLabel: 'Barn og ungdom: kjæledyr',
                alternativeLabels: [],
                note: 'Her: stell av og mat for kjæledyr, råd om ernæring og dyrestell',
                related: [],
                children: [
                  {
                    id: 'YNNH1',
                    label: 'Kjæledyr: Hunder',
                    originalLabel: 'Barn og ungdom: kjæledyr: hunder',
                    alternativeLabels: [],
                    note: 'Her: valper, hundetrening. Se også YNNJ21 <a href="/thema/nb/YNNJ21">Barn og ungdom: hundedyr og ulver</a>',
                    related: [
                      {
                        id: 'YNNJ21',
                        label: 'Hundedyr og ulver',
                        originalLabel: 'Barn og ungdom: hundedyr og ulver',
                        note: 'Her: hyener, rever etc. Brukes for titler som handler om alle typer hunder og hundedyr, og som ikke er spesifikt om hunder som kjæledyr. Se også YNNH1 <a href="/thema/nb/YNNH1">Barn og ungdom: kjæledyr: hunder</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'YNNH2',
                    label: 'Kjæledyr: Katter',
                    originalLabel: 'Barn og ungdom: kjæledyr: katter',
                    alternativeLabels: [],
                    note: 'Her: kattunger. Se også YNNJ22 <a href="/thema/nb/YNNJ22">Barn og ungdom: kattedyr</a>',
                    related: [
                      {
                        id: 'YNNJ22',
                        label: 'Kattedyr',
                        originalLabel: 'Barn og ungdom: kattedyr',
                        note: 'Her: store kattedyr, løver, tigre etc. Brukes for titler som handler om alle typer kattedyr, og som ikke er spesifikt om katter som kjæledyr. Se også YNNH2 <a href="/thema/nb/YNNH2">Barn og ungdom: kjæledyr: katter</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'YNNH3',
                    label: 'Kjæledyr: Kaniner og gnagere',
                    originalLabel:
                      'Barn og ungdom: kjæledyr: kaniner og gnagere',
                    alternativeLabels: [],
                    note: 'Her: mus, hamstere, marsvin, rotter etc. som kjæledyr. Se også YNNJ31 <a href="/thema/nb/YNNJ31">Barn og ungdom: gnagere og kaniner</a>',
                    related: [
                      {
                        id: 'YNNJ31',
                        label: 'Gnagere og kaniner',
                        originalLabel: 'Barn og ungdom: gnagere og kaniner',
                        note: 'Her: mus, rotter, ekorn, harer etc., ikke som kjæledyr. Se også YNNH3 <a href="/thema/nb/YNNH3">Barn og ungdom: kjæledyr: kaniner og gnagere</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'YNNH4',
                    label: 'Kjæledyr: Hester og ponnier',
                    originalLabel:
                      'Barn og ungdom: kjæledyr: hester og ponnier',
                    alternativeLabels: [],
                    note: 'Her: foring, stell eller trening av hester. Se også YNNJ24 <a href="/thema/nb/YNNJ24">Barn og ungdom: ponnier, hester og andre hestedyr</a>',
                    related: [
                      {
                        id: 'YNNJ24',
                        label: 'Ponnier og hester',
                        originalLabel:
                          'Barn og ungdom: ponnier, hester og andre hestedyr',
                        note: 'Her: esler, sebraer. Brukes med YF*-koder for skjønnlitterære fortellinger om hester, med YNW* for hestesport. Se også YNNH4 <a href="/thema/nb/YNNH4">Barn og ungdom: kjæledyr: hester og ponnier</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'YNNH5',
                    label: 'Kjæledyr: Fugler',
                    originalLabel: 'Barn og ungdom: kjæledyr: fugler',
                    alternativeLabels: ['undulater'],
                    note: 'Her: papegøyer, kanarifugler etc., som kjæledyr',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YNNJ',
                label: 'Pattedyr',
                originalLabel: 'Barn og ungdom: pattedyr',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'YNNJ1',
                    label: 'Sjøpattedyr',
                    originalLabel: 'Barn og ungdom: sjøpattedyr',
                    alternativeLabels: [
                      'flodhest',
                      'oter',
                      'bever',
                      'sel',
                      'hvalross',
                    ],
                    note: 'Her: flodhester, otere, bevere, seler, hvalrosser. Brukes for pattedyr som lever mesteparten av tida i ferskvann eller saltvann. Se også YNNS <a href="/thema/nb/YNNS">Barn og ungdom: fisk og livet i havet</a>',
                    related: [
                      {
                        id: 'YNNS',
                        label: 'Fisk og livet i havet',
                        originalLabel: 'Barn og ungdom: fisk og livet i havet',
                        note: 'Bruk YNNJ1 for sjøpattedyr. Se også YNNJ1 <a href="/thema/nb/YNNJ1">Barn og ungdom: sjøpattedyr</a>',
                      },
                    ],
                    children: [
                      {
                        id: 'YNNJ14',
                        label: 'Hvaler, delfiner og niser',
                        originalLabel:
                          'Barn og ungdom: hvaler, delfiner og niser',
                        alternativeLabels: [],
                        note: 'Brukes for pattedyr som lever i vann',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'YNNJ2',
                    label: 'Store landpattedyr',
                    originalLabel: 'Barn og ungdom: store landpattedyr',
                    alternativeLabels: ['reinsdyr', 'elg'],
                    note: 'Her: kameler, elefanter, sjiraffer, neshorn, lama etc. Brukes for titler som handler om store pattedyr som ikke har sin egen kode',
                    related: [],
                    children: [
                      {
                        id: 'YNNJ21',
                        label: 'Hundedyr og ulver',
                        originalLabel: 'Barn og ungdom: hundedyr og ulver',
                        alternativeLabels: ['dingo'],
                        note: 'Her: hyener, rever etc. Brukes for titler som handler om alle typer hunder og hundedyr, og som ikke er spesifikt om hunder som kjæledyr. Se også YNNH1 <a href="/thema/nb/YNNH1">Barn og ungdom: kjæledyr: hunder</a>',
                        related: [
                          {
                            id: 'YNNH1',
                            label: 'Kjæledyr: Hunder',
                            originalLabel: 'Barn og ungdom: kjæledyr: hunder',
                            note: 'Her: valper, hundetrening. Se også YNNJ21 <a href="/thema/nb/YNNJ21">Barn og ungdom: hundedyr og ulver</a>',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'YNNJ22',
                        label: 'Kattedyr',
                        originalLabel: 'Barn og ungdom: kattedyr',
                        alternativeLabels: ['leopard', 'panter', 'gaupe'],
                        note: 'Her: store kattedyr, løver, tigre etc. Brukes for titler som handler om alle typer kattedyr, og som ikke er spesifikt om katter som kjæledyr. Se også YNNH2 <a href="/thema/nb/YNNH2">Barn og ungdom: kjæledyr: katter</a>',
                        related: [
                          {
                            id: 'YNNH2',
                            label: 'Kjæledyr: Katter',
                            originalLabel: 'Barn og ungdom: kjæledyr: katter',
                            note: 'Her: kattunger. Se også YNNJ22 <a href="/thema/nb/YNNJ22">Barn og ungdom: kattedyr</a>',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'YNNJ23',
                        label: 'Bjørner',
                        originalLabel: 'Barn og ungdom: bjørner',
                        alternativeLabels: [
                          'isbjørn',
                          'grizzlybjørn',
                          'brunbjørn',
                        ],
                        note: 'Her: pandaer',
                        related: [],
                        children: [],
                      },
                      {
                        id: 'YNNJ24',
                        label: 'Ponnier og hester',
                        originalLabel:
                          'Barn og ungdom: ponnier, hester og andre hestedyr',
                        alternativeLabels: ['muldyr', 'føll'],
                        note: 'Her: esler, sebraer. Brukes med YF*-koder for skjønnlitterære fortellinger om hester, med YNW* for hestesport. Se også YNNH4 <a href="/thema/nb/YNNH4">Barn og ungdom: kjæledyr: hester og ponnier</a>',
                        related: [
                          {
                            id: 'YNNH4',
                            label: 'Kjæledyr: Hester og ponnier',
                            originalLabel:
                              'Barn og ungdom: kjæledyr: hester og ponnier',
                            note: 'Her: foring, stell eller trening av hester. Se også YNNJ24 <a href="/thema/nb/YNNJ24">Barn og ungdom: ponnier, hester og andre hestedyr</a>',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'YNNJ25',
                        label: 'Kyr og storfe',
                        originalLabel: 'Barn og ungdom: kyr og storfe',
                        alternativeLabels: [],
                        note: 'Her: bøffel, bison, jak',
                        related: [],
                        children: [],
                      },
                      {
                        id: 'YNNJ26',
                        label: 'Griser og villsvin',
                        originalLabel: 'Barn og ungdom: griser og villsvin',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'YNNJ27',
                        label: 'Sauer og geiter',
                        originalLabel: 'Barn og ungdom: sauer og geiter',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'YNNJ28',
                        label: 'Hjort, elg, reinsdyr',
                        originalLabel: 'Barn og ungdom: hjort, elg, reinsdyr',
                        alternativeLabels: [
                          'hjortedyr',
                          'muntjaker',
                          'dåhjort',
                          'rådyr',
                        ],
                        note: 'Her: antiloper, karibuer',
                        related: [],
                        children: [],
                      },
                      {
                        id: 'YNNJ29',
                        label: 'Aper og lemurer',
                        originalLabel: 'Barn og ungdom: aper og lemurer',
                        alternativeLabels: [],
                        note: 'Her: gorillaer, sjimpanser, orangutanger, gibboner',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'YNNJ3',
                    label: 'Små landpattedyr',
                    originalLabel: 'Barn og ungdom: små landpattedyr',
                    alternativeLabels: ['pinnsvin'],
                    note: 'Her: flaggermus, piggsvin, vaskebjørner etc. Brukes for titler som handler om små pattedyr som ikke har sin egen kode eller note',
                    related: [],
                    children: [
                      {
                        id: 'YNNJ31',
                        label: 'Gnagere og kaniner',
                        originalLabel: 'Barn og ungdom: gnagere og kaniner',
                        alternativeLabels: [],
                        note: 'Her: mus, rotter, ekorn, harer etc., ikke som kjæledyr. Se også YNNH3 <a href="/thema/nb/YNNH3">Barn og ungdom: kjæledyr: kaniner og gnagere</a>',
                        related: [
                          {
                            id: 'YNNH3',
                            label: 'Kjæledyr: Kaniner og gnagere',
                            originalLabel:
                              'Barn og ungdom: kjæledyr: kaniner og gnagere',
                            note: 'Her: mus, hamstere, marsvin, rotter etc. som kjæledyr. Se også YNNJ31 <a href="/thema/nb/YNNJ31">Barn og ungdom: gnagere og kaniner</a>',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'YNNJ9',
                    label: 'Pungdyr, nebbdyr, maurpiggsvin',
                    originalLabel:
                      'Barn og ungdom: pungdyr, nebbdyr, maurpiggsvin',
                    alternativeLabels: ['maurpinnsvin'],
                    note: 'Her: vombater, kenguruer og koalaer',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YNNK',
                label: 'Fugler',
                originalLabel: 'Barn og ungdom: fugler',
                alternativeLabels: [
                  'ugler',
                  'traner',
                  'pingviner',
                  'ender',
                  'gjess',
                  'kalkun',
                  'struts',
                  'høns',
                ],
                note: 'Her: ugler, hegrer, traner, pingviner, papegøyer, tukaner, ender, gjess, kalkuner, svaner, gripper, flamingoer, strutser, emuer, ørner, lundefugler etc.',
                related: [],
                children: [],
              },
              {
                id: 'YNNL',
                label: 'Insekter, edderkopper, småkryp',
                originalLabel: 'Barn og ungdom: insekter, edderkopper, småkryp',
                alternativeLabels: [
                  'bier',
                  'humler',
                  'veps',
                  'larver',
                  'biller',
                  'snegler',
                  'fluer',
                ],
                note: 'Her: bier, humler, veps, biller, larver, sommerfugler, maur, mark etc.',
                related: [],
                children: [],
              },
              {
                id: 'YNNM',
                label: 'Reptiler og amfibier',
                originalLabel: 'Barn og ungdom: reptiler og amfibier',
                alternativeLabels: ['krokodiller'],
                note: 'Her: slanger, øgler, skilpadder, frosker, padder etc. Brukes med YNNH for titler om kjæledyr',
                related: [],
                children: [],
              },
              {
                id: 'YNNS',
                label: 'Fisk og livet i havet',
                originalLabel: 'Barn og ungdom: fisk og livet i havet',
                alternativeLabels: [],
                note: 'Bruk YNNJ1 for sjøpattedyr. Se også YNNJ1 <a href="/thema/nb/YNNJ1">Barn og ungdom: sjøpattedyr</a>',
                related: [
                  {
                    id: 'YNNJ1',
                    label: 'Sjøpattedyr',
                    originalLabel: 'Barn og ungdom: sjøpattedyr',
                    note: 'Her: flodhester, otere, bevere, seler, hvalrosser. Brukes for pattedyr som lever mesteparten av tida i ferskvann eller saltvann. Se også YNNS <a href="/thema/nb/YNNS">Barn og ungdom: fisk og livet i havet</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'YNNT',
                label: 'Planter og trær',
                originalLabel: 'Barn og ungdom: planter og trær',
                alternativeLabels: [],
                note: 'Her: blomster, løv, frø, botanikk',
                related: [],
                children: [],
              },
              {
                id: 'YNNV',
                label: 'Fjell, vær og den fysiske verden',
                originalLabel: 'Barn og ungdom: den fysiske verden',
                alternativeLabels: [
                  'skyer',
                  'klima',
                  'orkaner',
                  'naturkatastroer',
                  'været',
                  'flom',
                  'orkan',
                ],
                note: 'Her: naturfenomener, grotter, fjell, vulkaner, jordskjelv, tsunamier, geologi, geovitenskap etc.',
                related: [],
                children: [
                  {
                    id: 'YNNV1',
                    label: 'Steiner og mineraler',
                    originalLabel: 'Barn og ungdom: steiner og mineraler',
                    alternativeLabels: [],
                    note: 'Her: edelstener, krystaller',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNNV2',
                    label: 'Vær og klima',
                    originalLabel: 'Barn og ungdom: vær og klima',
                    alternativeLabels: ['været'],
                    note: 'Her: meteorologi, atmosfæren, værsystemer, stormer, lyn og torden, tornadoer, tyfoner, orkaner, skyer, regn, snø, regnbuer, solskinn, årstider, ekstremvær, klimaendringer etc.',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YNNZ',
                label: 'Romfart, stjerner og solsystemet',
                originalLabel:
                  'Barn og ungdom: romfart, stjerner og solsystemet',
                alternativeLabels: ['astronomi', 'verdensrommet', 'universet'],
                note: 'Her: romfart, planeter, astronomi, se på stjernene, månen etc. Brukes med YFG* for science fiction som er lagt til verdensrommet eller YBCS eller YBD for fortellinger om verdensrommet, og med 1ZMC* der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YNP',
            label: 'Praktiske interesser',
            originalLabel: 'Barn og ungdom: praktiske interesser',
            alternativeLabels: ['makerspace'],
            note: 'Her: hobbyer. Brukes for praktiske bøker som handler om å lage eller gjøre ting, praktiske ideer, skaperverksted, folkeverksted. NB!* YNP kan brukes med W* dersom det ikke fins en mer spesifikk Y*-kode. Dersom YNP og W* kombineres MÅ YNP angis som den viktigste koden, og kombineres med en kvalifikator fra 5A*. Se også YNT <a href="/thema/nb/YNT">Barn og ungdom: vitenskap og teknologi</a>, YPAK <a href="/thema/nb/YPAK">Læremidler: håndverk</a>',
            related: [
              {
                id: 'YNT',
                label: 'Vitenskap og teknologi',
                originalLabel: 'Barn og ungdom: vitenskap og teknologi',
                note: 'Brukes for generelle verk om vitenskap, inkludert prosjektideer, eksperimenter, vitenskapelige eller teknologiske oppdagelser etc. Brukes med YNB for biografier',
              },
              {
                id: 'YPAK',
                label: 'Læremidler: håndverk',
                originalLabel: 'Læremidler: håndverk',
                note: 'Her: skaperverksted, sløyd, tradisjonelt kunsthåndverk. Brukes for tverrfaglige, praktiske bøker',
              },
            ],
            children: [
              {
                id: 'YNPB',
                label: 'Tegning, maling, fotografering',
                originalLabel: 'Barn og ungdom: tegning, maling, fotografering',
                alternativeLabels: ['brukskunst'],
                note: 'Her: animasjon, filmproduksjon. Brukes for guider til hvordan man kan utøve de ulike kunstartene',
                related: [],
                children: [],
              },
              {
                id: 'YNPC',
                label: 'Matlaging og mat',
                originalLabel: 'Barn og ungdom: matlaging og mat',
                alternativeLabels: ['baking'],
                note: 'Her: kokebøker og oppskrifter, bøker om ernæring, frukt og grønnsaker',
                related: [],
                children: [],
              },
              {
                id: 'YNPG',
                label: 'Hagearbeid',
                originalLabel: 'Barn og ungdom: hagearbeid',
                alternativeLabels: ['planter', 'trær', 'busker', 'dyrke ting'],
                note: 'Her: plantedyrking, blomster, urter, grønnsaker',
                related: [],
                children: [],
              },
              {
                id: 'YNPH',
                label: 'Håndverk',
                originalLabel: 'Barn og ungdom: håndverk',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'YNPH1',
                    label: 'Tresløyd og modellbygging',
                    originalLabel: 'Barn og ungdom: tresløyd og modellbygging',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNPH2',
                    label: 'Håndarbeid',
                    originalLabel: 'Barn og ungdom: håndarbeid',
                    alternativeLabels: ['sying', 'hekling', 'strikking', 'søm'],
                    note: 'Her: tekstilhåndverk, broderi',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YNPJ',
                label: 'Klær og mote',
                originalLabel: 'Barn og ungdom: klær og mote',
                alternativeLabels: [],
                note: 'Her: kostymer, utkledning, motedesignere etc.',
                related: [],
                children: [],
              },
              {
                id: 'YNPK',
                label: 'Penger',
                originalLabel: 'Barn og ungdom: penger',
                alternativeLabels: ['budsjett', 'inntekt'],
                note: 'Her: bøker som forklarer hvordan finansinstitusjoner fungerer, eller bøker som gir praktiske råd om personlig økonomi',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YNQ',
            label: 'Ungdomsklubber og organisasjoner',
            originalLabel:
              'Barn og ungdom: ungdomsklubber og ungdomsorganisasjoner',
            alternativeLabels: [],
            note: 'Her: speidere og lignende ungdomsorganisasjoner',
            related: [],
            children: [],
          },
          {
            id: 'YNR',
            label: 'Filosofi, religion og tro',
            originalLabel: 'Barn og ungdom: filosofi, religion og tro',
            alternativeLabels: ['religioner', 'agnostikere', 'ateisme'],
            note: 'Her: spirituelle eller inspirerende bøker som handler om tro, det å ikke tro, agnostisisme, ateisme. Bruk YNR*-koder for filosofiske retninger, trossystemer, spirituelle tradisjoner, religion eller fortellinger fra en religiøs tradisjon. Brukes med andre Y*-koder og kvalifikator(er) fra 5PG* der det er hensiktsmessig. Se også YPJN* <a href="/thema/nb/YPJN">Læremidler: religion</a> og dens underkategorier, YXZR <a href="/thema/nb/YXZR">Barn og ungdom: personlige og sosiale spørsmål: religion</a>',
            related: [
              {
                id: 'YPJN',
                label: 'Læremidler: religion',
                originalLabel: 'Læremidler: religion',
                note: 'Her: læremidler som introduserer elevene for religion og sammenlignende religionsvitenskap. Se også YNR <a href="/thema/nb/YNR">Barn og ungdom: filosofi, religion og tro</a>',
              },
              {
                id: 'YXZR',
                label: 'Religion',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: religion',
                note: 'Brukes for bøker som tar for seg ulike aspekter ved religion, tro, religiøs identitet etc. Brukes med YNR* eller andre koder fra Y*, og kvalifikatorer fra 5PG* der det er hensiktsmessig',
              },
            ],
            children: [
              {
                id: 'YNRA',
                label: 'Filosofi',
                originalLabel: 'Barn og ungdom: filosofi',
                alternativeLabels: [],
                note: 'Her: filosofiske retninger og skoler',
                related: [],
                children: [],
              },
              {
                id: 'YNRD',
                label: 'Hinduisme',
                originalLabel: 'Barn og ungdom: hinduisme',
                alternativeLabels: ['hinduer'],
                note: 'Brukes med YNRX for hellige tester fra hinduismen og med YNRY for fortellinger for barn eller ungdom som tar utgangspunkt i disse tekstene eller de religiøse tradisjonene. Se også YPJN1 <a href="/thema/nb/YPJN1">Læremidler: religion: hinduisme</a>',
                related: [
                  {
                    id: 'YPJN1',
                    label: 'Læremidler: hinduisme',
                    originalLabel: 'Læremidler: religion: hinduisme',
                    note: 'Se også YNRD <a href="/thema/nb/YNRD">Barn og ungdom: hinduisme</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'YNRF',
                label: 'Buddhisme',
                originalLabel: 'Barn og ungdom: buddhisme',
                alternativeLabels: ['buddhister'],
                note: 'Brukes med YNRX for hellige tester fra buddhismen og med YNRY for fortellinger for barn eller ungdom som tar utgangspunkt i disse tekstene eller de religiøse tradisjonene. Se også YPJN2 <a href="/thema/nb/YPJN2">Læremidler: religion: buddhisme</a>',
                related: [
                  {
                    id: 'YPJN2',
                    label: 'Læremidler: buddhisme',
                    originalLabel: 'Læremidler: religion: buddhisme',
                    note: 'Se også YNRF <a href="/thema/nb/YNRF">Barn og ungdom: buddhisme</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'YNRJ',
                label: 'Jødedom',
                originalLabel: 'Barn og ungdom: jødedom',
                alternativeLabels: ['jøder'],
                note: 'Brukes med YNRX for hellige tester fra jødedommen og med YNRY for fortellinger for barn eller ungdom som tar utgangspunkt i disse tekstene eller de religiøse tradisjonene. Se også YPJN3 <a href="/thema/nb/YPJN3">Læremidler: religion: jødedom</a>',
                related: [
                  {
                    id: 'YPJN3',
                    label: 'Læremidler: jødedom',
                    originalLabel: 'Læremidler: religion: jødedom',
                    note: 'Se også YNRJ <a href="/thema/nb/YNRJ">Barn og ungdom: jødedom</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'YNRM',
                label: 'Kristendom',
                originalLabel: 'Barn og ungdom: kristendom',
                alternativeLabels: ['kristne'],
                note: 'Her: barnebibler. Brukes med YNRX for hellige tester fra kristendommen og med YNRY for bibelfortellinger for barn eller ungdom. Se også YPJN4 <a href="/thema/nb/YPJN4">Læremidler: religion: kristendom</a>',
                related: [
                  {
                    id: 'YPJN4',
                    label: 'Læremidler: kristendom',
                    originalLabel: 'Læremidler: religion: kristendom',
                    note: 'Se også YNRM <a href="/thema/nb/YNRM">Barn og ungdom: kristendom</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'YNRP',
                label: 'Islam',
                originalLabel: 'Barn og ungdom: islam',
                alternativeLabels: ['muslimer'],
                note: 'Brukes med YNRX for hellige tester fra islam og med YNRY for fortellinger for barn eller ungdom som tar utgangspunkt Koranen eller andre hellige tekster eller muslimske tradisjoner. Se også YPJN5 <a href="/thema/nb/YPJN5">Læremidler: religion: islam</a>',
                related: [
                  {
                    id: 'YPJN5',
                    label: 'Læremidler: islam',
                    originalLabel: 'Læremidler: religion: islam',
                    note: 'Se også YNRP <a href="/thema/nb/YNRP">Barn og ungdom: islam</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'YNRR',
                label: 'Andre religioner og åndelige trossamfunn',
                originalLabel:
                  'Barn og ungdom: andre religioner og spirituelle trossamfunn',
                alternativeLabels: [],
                note: 'Brukes for bøker om religioner som praktiseres i samtida, men som ikke har egne kategorier. Brukes med 5PG* eller andre kvalifikatorer fra 5P* og kvalifikatorer for geografi der det er hensiktsmessig QR*-koder og kvalifikator for sted der det er hensiktsmessig. NB!* YNNR kan kombineres med QR* dersom det ikke fins mer spesifikke Y*-koder, men dersom Y*og QR* kombineres, MÅ Y*-koden angis som den mest viktigste, og kombineres med en kvalifikator fra 5A*. Se også YPJN9 <a href="/thema/nb/YPJN9">Læremidler: religion: andre religioner</a>',
                related: [
                  {
                    id: 'YPJN9',
                    label: 'Læremidler: andre religioner',
                    originalLabel: 'Læremidler: religion: andre religioner',
                    note: 'Brukes med kvalifikatorer fra 5PG*. NB!: YPJN kan kombineres med QR* for religioner som ikke har en egen kode i Y*. Dersom de kombineres, MÅ alltid en YP*-kode angis som den viktigste koden. Se også YNRR <a href="/thema/nb/YNRR">Barn og ungdom: andre religioner og spirituelle trossamfunn</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'YNRU',
                label: 'Oldtidens religioner, mytologier og legender',
                originalLabel:
                  'Barn og ungdom: oldtidens religioner, mytologi og legender',
                alternativeLabels: [],
                note: 'Brukes for bøker om eller samlinger av myter og legender fra bl.a. gresk, romersk, norrøn, egyptisk, keltisk, aztekisk, afrikansk, asiatisk, amerikansk religion og mytologi. Brukes med YBC*, YBD, YFH* eller YFJ for skjønnlitteratur basert på myter eller gjenfortelling av de originale historiene, og med kvalifikatorer for sted, språk og interesse der det er hensiktsmessig. Se også YFJ <a href="/thema/nb/YFJ">Skjønnlitteratur for barn og ungdom: tradisjonelle historier</a>, YFHG <a href="/thema/nb/YFHG">Skjønnlitteratur for barn og ungdom: mytisk fantasy</a>, YNXB <a href="/thema/nb/YNXB">Barn og ungdom: overnaturlige og mytologiske vesener</a>',
                related: [
                  {
                    id: 'YFHG',
                    label: 'Mytisk fantasy',
                    originalLabel:
                      'Skjønnlitteratur for barn og ungdom: mytisk fantasy',
                    note: 'Her: gjenfortellinger av eventyr, arthuriansk fantasy, klassisk fantasy (basert på greske og romerske myter) etc. Brukes for skjønnlitteratur som er inspirert av, er ei gjenfortelling av eller som på en eller annen måte baserer seg på karakterer, fortellinger og emner fra myter, legender, folketro eller eventyr. F.eks. ei historie som bygger på et populært eventyr, men som er lagt til en moderne setting, eller ei historie som tar utgangspunkt i historia til en mindre viktig karakter i den originale teksten, ei historie som tar utgangspunkt i ‘hva hvis’, eller skaper en mashup av ulike sjangere',
                  },
                  {
                    id: 'YFJ',
                    label: 'Tradisjonelle historier',
                    originalLabel:
                      'Skjønnlitteratur for barn og ungdom: tradisjonelle historier',
                    note: 'Her: eventyr, folkeeventyr, myter, fabler, sagn og legender fortalt som fiksjon. Bøker om troll, nisser etc. Se også YFHG <a href="/thema/nb/YFHG">Skjønnlitteratur for barn og ungdom: mytisk fantasy</a>',
                  },
                  {
                    id: 'YNXB',
                    label: 'Overnaturlige og mytologiske vesener',
                    originalLabel:
                      'Barn og ungdom: overnaturlige og mytologiske vesener',
                    note: 'Her: monstre og vesener fra myter og legender, magiske skapninger. Bruk YNXB* med YF* for fortellinger om disse skapningene',
                  },
                ],
                children: [],
              },
              {
                id: 'YNRX',
                label: 'Religiøse tekster, bønne- og andaktsbøker',
                originalLabel:
                  'Barn og ungdom: religiøse tekster, bønne- og andaktsbøker',
                alternativeLabels: ['bønn', 'bønnebøker'],
                note: 'Her: hellige tekster, blant annet forenklede versjoner, historier basert på hellige tekster, bønnebøker og materiale laget til andakt, for barn. Kan brukes med andre YNR*-koder som indikerer religion, og med 5PG*-koder der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'YNRY',
                label:
                  'Fortellinger fra hellige tekster eller religiøse tradisjoner',
                originalLabel:
                  'Barn og ungdom: fortellinger fra hellige tekster eller religiøse tradisjoner',
                alternativeLabels: [],
                note: 'Brukes for bøker som er ment for barn eller ungdom og som gjenforteller spesielle historier fra hellige tekster, spirituelle eller religiøse tradisjoner. F.eks. bibelfortellinger eller fortellinger fra Koranen etc. Brukes med andre YNR*-koder og kvalifikatorer fra 5PG* for å angi en spesifikk tradisjon eller religion. F.eks. med YNRM og 5PGM for bibelfortellinger, med YNRD og 5PGD for fortellinger fra hinduismen etc. Foretrekk YFK for skjønnlitteratur som er inspirert av et spesifikt trossystem',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YNT',
            label: 'Vitenskap og teknologi',
            originalLabel: 'Barn og ungdom: vitenskap og teknologi',
            alternativeLabels: [],
            note: 'Brukes for generelle verk om vitenskap, inkludert prosjektideer, eksperimenter, vitenskapelige eller teknologiske oppdagelser etc. Brukes med YNB for biografier',
            related: [],
            children: [
              {
                id: 'YNTA',
                label: 'Menneskekroppen',
                originalLabel: 'Barn og ungdom: vitenskap: menneskekroppen',
                alternativeLabels: ['anatomi', 'kroppen'],
                note: 'Her: generelle beskrivende, vitenskapelige tilnærminger til den menneskelige anatomien, kroppsdeler og hvordan de virker, humanbiologi, skjelettet, blod, muskler, hjernen, innvoller, tenner, hår etc. Se også YXA* <a href="/thema/nb/YXA">Barn og ungdom: personlige og sosiale spørsmål: kropp og helse</a> og dens underkategorier',
                related: [
                  {
                    id: 'YXA',
                    label: 'Kropp og helse',
                    originalLabel:
                      'Barn og ungdom: personlige og sosiale spørsmål: kropp og helse',
                    note: 'Her: råd om god søvn, børste tennene, gå til tannlegen, ta vare på kroppen sin, personlig hygiene. Brukes med YXL*-koder for mental helse i tilknytning til kroppen',
                  },
                ],
                children: [],
              },
              {
                id: 'YNTC',
                label: 'Databehandling og informasjonsteknologi',
                originalLabel:
                  'Barn og ungdom: databehandling og informasjonsteknologi',
                alternativeLabels: ['minecraft', 'AI'],
                note: 'Her: kunstig intelligens, virtuell og utvidet virkelighet, robotikk. Se også YNVU <a href="/thema/nb/YNVU">Barn og ungdom: data- og videospill</a>',
                related: [
                  {
                    id: 'YNVU',
                    label: 'Data- og videospill',
                    originalLabel: 'Barn og ungdom: data- og videospill',
                    note: 'Her: brukerguider for dataspill',
                  },
                ],
                children: [
                  {
                    id: 'YNTC1',
                    label: 'Programmering og programmeringsspråk',
                    originalLabel:
                      'Barn og ungdom: programmering og programmeringsspråk',
                    alternativeLabels: [
                      'Python',
                      'Scratch',
                      'Ruby',
                      'Logo',
                      'koding for kidsa',
                    ],
                    note: 'Her: koding for barn',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNTC2',
                    label: 'Sosiale medier',
                    originalLabel: 'Barn og ungdom: sosiale medier',
                    alternativeLabels: [
                      'Twitter',
                      'Facebook',
                      'Snapchat',
                      'Tumblr',
                      'Instagram',
                      'Reddit',
                      'WhatsApp',
                      'Pinterest',
                      'YouTube',
                      'vlogger',
                      'blogger',
                      'vloggere',
                      'bloggere',
                      'influencere',
                    ],
                    note: 'Her: påvirkere, vlogging, blogging, internettkjendiser. Brukes med andre koder fra Y* der det er hensiktsmessig. F.eks. YNB for biografier, selvbiografier, memoarer eller personlige beretninger fra kjendiser eller personligheter som er kjent fra ulike sosiale medier, eller med YXQ* for titler om mobbing i sosiale medier, YF* for skjønnlitterære fortellinger om sosiale medier etc.',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YNTD',
                label: 'Oppfinnere, oppfinnelser og eksperimenter',
                originalLabel:
                  'Barn og ungdom: oppfinnere, oppfinnelser og eksperimenter',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'YNTE',
                label: 'Bevegelse, lyd, lys, energi',
                originalLabel: 'Barn og ungdom: vitenskap: fysikk',
                alternativeLabels: [],
                note: 'Her: fysikk for barn og ungdom; bevegelse, lyd, lys, energibølger, partikler, atomer, magneter, tyngdekraft, varme, energi etc. Brukes for bøker ment for barn og ungdom om ulike aspekter ved fysikk. Se også YPMP5 <a href="/thema/nb/YPMP5">Læremidler: fysikk</a>',
                related: [
                  {
                    id: 'YPMP5',
                    label: 'Læremidler: fysikk',
                    originalLabel: 'Læremidler: fysikk',
                  },
                ],
                children: [],
              },
              {
                id: 'YNTF',
                label: 'Elementene, krystaller, kjemieksperimenter',
                originalLabel:
                  'Barn og ungdom: vitenskap: elementene, krystaller, kjemieksperimenter',
                alternativeLabels: [],
                note: 'Her: kjemi for barn og ungdom; det periodiske system, kjemiske reaksjoner etc. Brukes for bøker ment for barn og ungdom om ulike aspekter ved kjemi. Se også YPMP3 <a href="/thema/nb/YPMP3">Læremidler: kjemi</a>',
                related: [
                  {
                    id: 'YPMP3',
                    label: 'Læremidler: kjemi',
                    originalLabel: 'Læremidler: kjemi',
                  },
                ],
                children: [],
              },
              {
                id: 'YNTG',
                label: 'Maskiner og hvordan ting fungerer',
                originalLabel:
                  'Barn og ungdom: maskiner og hvordan ting fungerer',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'YNTM',
                label: 'Matematikk og tall',
                originalLabel: 'Barn og ungdom: matematikk og tall',
                alternativeLabels: [],
                note: 'Brukes for bøker om matematikk og tall som ikke primært er ment for bruk i skolen',
                related: [],
                children: [],
              },
              {
                id: 'YNTP',
                label: 'Bygninger og konstruksjoner',
                originalLabel:
                  'Barn og ungdom: arkitektur, bygninger og konstruksjoner',
                alternativeLabels: [],
                note: 'Her: konstruksjon eller riving av bygninger, strukturer, ingeniørprosjekter, bruer, tunneler, ulike typer bygninger eller monumenter, bygningstyper som hus, skyskrapere, pyramider etc. Brukes med kvalifikatorer for geografi for å angi lokasjon for navngitte bygninger eller konstruksjoner som pyramidene, verdens sju underverker, berømte landemerker etc.',
                related: [],
                children: [],
              },
              {
                id: 'YNTR',
                label: 'Transport og kjøretøy',
                originalLabel: 'Barn og ungdom: transport og kjøretøy',
                alternativeLabels: [],
                note: 'Her: skip, båter, fly, luftskip, jernbane, tog, biler, lastebiler, busser, ambulanser, brannbiler, traktorer, gravemaskiner, motorsykler, sykler, vogner, campingvogner etc. NB!: YNTR kan kombineres med WG*, men dersom man kombinerer YNTR og WG*, MÅ Y*-koden angis som den viktigste og kombineres med en kvalifikator fra 5A*',
                related: [],
                children: [],
              },
              {
                id: 'YNTT',
                label: 'Tidsreiser',
                originalLabel: 'Barn og ungdom: tidsreiser',
                alternativeLabels: [],
                note: 'Brukes for bøker som handler om konseptet tid og tidsreiser. Brukes med YF*-koder der det er hensiktsmessig. F.eks. med YFG for science fiction, eller YFH* for fantasy',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YNU',
            label: 'Humor og vitser',
            originalLabel: 'Barn og ungdom: humor og vitser',
            alternativeLabels: [],
            note: 'Brukes med andre Y*-koder og kvalifikatorer dersom det er snakk om ei humoristisk bok om et spesielt emne',
            related: [],
            children: [
              {
                id: 'YNUC',
                label: 'Tegneserier',
                originalLabel: 'Barn og ungdom: tegneserier',
                alternativeLabels: ['knøttene', 'pusur'],
                note: 'Brukes for humoristiske tegneserier eller tegneseriestriper ment for barn og ungdom. Foretrekk X*-koder med kvalifikator fra 5A* for alle grafiske romaner, manga, tegneseriebøker i europeisk eller amerikansk stil etc. Se også X* <a href="/thema/nb/X">Grafiske romaner, tegneserier, manga og tegneseriestriper</a> og dens underkategorier',
                related: [
                  {
                    id: 'X',
                    label:
                      'Grafiske romaner, tegneserier, manga og tegneseriestriper',
                    originalLabel:
                      'Grafiske romaner, tegneserier, manga og tegneseriestriper',
                    note: 'Bruk X*-koder for bøker både for voksne og barn. IKKE bruk koden ‘X’ alene, men velg en spesifikk kategori fra X*. X* kan brukes med A*-W* ELLER Y* (men ikke begge) og kvalifikatorer der det er hensiktsmessig. Bruk en kode fra XA* for å angi tradisjon, og en fra XQ* for å angi sjanger, samt andre koder for å angi flere detaljer. Kvalifikatorer for alder fra 5A* MÅ brukes på utgivelser ment for barn og ungdom',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'YNV',
            label: 'Hobbyer, spørrebøker og spill',
            originalLabel: 'Barn og ungdom: hobbyer, spørrebøker og spill',
            alternativeLabels: [],
            note: 'Bruk YNV* med andre koder fra YN* der det er hensiktsmessig, med kvalifikatorer fra 5H* for bøker som forbindes med spesielle høytider eller sesonger, og med andre kvalifikatorer der det er hensiktsmessig. Brukes med YZ* for å angi at det er snakk om en vare og ikke ei bok, som f.eks. et spill, en leke eller et puslespill. Mer detaljer om produktet bør sendes i andre metadata. NB!: YNV* kan kombineres med WD*, men dersom de kombineres MÅ YNV* bli angitt som den viktigste koden og kombineres med en kvalifikator fra 5A*',
            related: [],
            children: [
              {
                id: 'YNVD',
                label: 'Leker',
                originalLabel: 'Barn og ungdom: leker',
                alternativeLabels: [],
                note: 'Bruk YNVD*-koder for bøker som handler om leker. Brukes med YB*, YF*, YNP* der det er hensiktsmessig',
                related: [],
                children: [
                  {
                    id: 'YNVD1',
                    label: 'Dukker og figurer',
                    originalLabel: 'Barn og ungdom: dukker og figurer',
                    alternativeLabels: [],
                    note: 'Her: hånddukker, action-figurer, lekesoldater, etc. Brukes med YB*, YF*, YNP* der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNVD2',
                    label: 'Kosedyr og myke leker',
                    originalLabel: 'Barn og ungdom: kosedyr og myke leker',
                    alternativeLabels: [],
                    note: 'Her: teddybjørner. Brukes med YB*, YF*, YNP* der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNVD3',
                    label: 'Byggeklosser og byggesett',
                    originalLabel: 'Barn og ungdom: byggeklosser og byggesett',
                    alternativeLabels: ['klosser', 'lego'],
                    note: 'Brukes med YB*, YF*, YNP* der det er hensiktsmessig',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YNVM',
                label: 'Spill',
                originalLabel: 'Barn og ungdom: spill',
                alternativeLabels: [],
                note: 'Her: innendørsspill, selskapsspill, brettspill, kortspill, pedagogiske spill, vitenskapsspill, strategispill, rollespill, terningspill, sjakk etc.',
                related: [],
                children: [],
              },
              {
                id: 'YNVP',
                label: 'Hjernetrim, aktivitetsbøker og spørrebøker',
                originalLabel:
                  'Barn og ungdom: hjernetrim, aktivitetsbøker og spørrebøker',
                alternativeLabels: ['quiz'],
                note: 'Her: kryssord, sudoku, tallgåter og andre gåter, tall- og ordspill, labyrinter, minnespill, puslespill. Brukes for utgivelser hvor man skal løse noe, finne et svar etc. Se også YBGH <a href="/thema/nb/YBGH">Skjulte objekter / Let og finn</a>',
                related: [
                  {
                    id: 'YBGH',
                    label: 'Skjulte objekter / Let og finn',
                    originalLabel: 'Skjulte objekter / Let og finn',
                    note: 'Her: bøker hvor målet er å finne gjemte objekter eller figurer i bildene, myldrebøker, eller bøker som har svært detaljerte illustrasjoner, men som ikke nødvendigvis forteller ei historie. Se også YNVP <a href="/thema/nb/YNVP">Barn og ungdom: hjernetrim, aktivitetsbøker og spørrebøker</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'YNVU',
                label: 'Data- og videospill',
                originalLabel: 'Barn og ungdom: data- og videospill',
                alternativeLabels: [
                  'e-sport',
                  'esport',
                  'online gaming',
                  'dataspill',
                ],
                note: 'Her: brukerguider for dataspill',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YNW',
            label: 'Sport og friluftsliv',
            originalLabel: 'Barn og ungdom: sport og friluftsliv',
            alternativeLabels: [],
            note: 'Her: sportsarrangementer, utendørs spill og aktiviteter. Brukes med andre YNW*-koder der det er hensiktsmessig. NB!: YNW kan kombineres med S* dersom det ikke fins mer spesifikke koder i YNW. Dersom de kombineres, MÅ en Y*-kode angis som den viktigste koden og kombineres med en kvalifikator fra 5A*',
            related: [],
            children: [
              {
                id: 'YNWA',
                label: 'Idrettsarrangementer',
                originalLabel: 'Barn og ungdom: idrettsarrangementer',
                alternativeLabels: [
                  'de olympiske leker',
                  'OL',
                  'VM',
                  'NM',
                  'EM',
                  'Paralympics',
                ],
                note: 'Her: Olympiske og Paralympiske leker, verdensmesterskap, regionale og nasjonale mesterskap og andre typer idrettsarrangementer, turneringer etc. Brukes med andre YNW*-koder der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'YNWD',
                label: 'Ballsport og ballspill',
                originalLabel: 'Barn og ungdom: ballsport og ballspill',
                alternativeLabels: [],
                note: 'Her: alle ballspill som ikke er listet opp nedenfor, som golf, landhockey, lacrosse, hurling, nettball etc. NB!: YNWD kan kombineres med SFF* dersom det ikke fins en mer spesifikk YNW-kode. Dersom de kombineres, MÅ en Y*-kode angis som den viktigste koden og kombineres med en kvalifikator fra 5A*. Se også YNWK <a href="/thema/nb/YNWK">Barn og ungdom: tennis og andre typer racketsport</a>',
                related: [
                  {
                    id: 'YNWK',
                    label: 'Tennis og andre typer racketsport',
                    originalLabel:
                      'Barn og ungdom: tennis og andre typer racketsport',
                    note: 'Her: tennis, badminton, squash, bordtennis, pelota etc. NB!: YNWK kan kombineres med SFT*, men dersom de kombineres, MÅ en Y*-kode angis som den viktigste koden og kombineres med en kvalifikator fra 5A*',
                  },
                ],
                children: [
                  {
                    id: 'YNWD1',
                    label: 'Fotball',
                    originalLabel:
                      'Barn og ungdom: ballsport og ballspill: fotball',
                    alternativeLabels: ['europeisk fotball', 'soccer'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNWD2',
                    label: 'Amerikansk fotball',
                    originalLabel:
                      'Barn og ungdom: ballsport og ballspill: amerikansk fotball',
                    alternativeLabels: ['superbowl'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNWD3',
                    label: 'Baseball og softball',
                    originalLabel:
                      'Barn og ungdom: ballsport og ballspill: baseball og softball',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNWD4',
                    label: 'Basketball',
                    originalLabel:
                      'Barn og ungdom: ballsport og ballspill: basketball',
                    alternativeLabels: [],
                    note: 'Her: kurvball',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNWD5',
                    label: 'Cricket',
                    originalLabel:
                      'Barn og ungdom: ballsport og ballspill: cricket',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNWD6',
                    label: 'Volleyball',
                    originalLabel:
                      'Barn og ungdom: ballsport og ballspill: volleyball',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNWD7',
                    label: 'Rugby',
                    originalLabel:
                      'Barn og ungdom: ballsport og ballspill: rugby',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNWD8',
                    label: 'Håndball',
                    originalLabel:
                      'Barn og ungdom: ballsport og ballspill: håndball',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YNWG',
                label: 'Friidrett og turn',
                originalLabel: 'Barn og ungdom: friidrett og turn',
                alternativeLabels: [],
                note: 'Her: maraton, rytmisk sportsgymnastikk, cheerleading, trampoline etc. NB!: YNWG kan kombineres med SH*, men dersom de kombineres MÅ en Y*-kode angis som den viktigste koden og kombineres med en kvalifikator fra 5A*',
                related: [],
                children: [],
              },
              {
                id: 'YNWJ',
                label: 'Kampsport / kampkunst',
                originalLabel: 'Barn og ungdom: kampsport / kampkunst',
                alternativeLabels: ['jiujitsu', 'ju jitsu', 'ju-jitsu'],
                note: 'Her: aikido, capoeira, judo, jujutsu, karate, kung-fu, tai-chi, sumobryting, taekwondo, MMA etc. NB!: YNWJ kan kombineres med SR*, men dersom de kombineres, MÅ en Y*-kode angis som den viktigste koden og kombineres med en kvalifikator fra 5A*',
                related: [],
                children: [],
              },
              {
                id: 'YNWK',
                label: 'Tennis og andre typer racketsport',
                originalLabel:
                  'Barn og ungdom: tennis og andre typer racketsport',
                alternativeLabels: [],
                note: 'Her: tennis, badminton, squash, bordtennis, pelota etc. NB!: YNWK kan kombineres med SFT*, men dersom de kombineres, MÅ en Y*-kode angis som den viktigste koden og kombineres med en kvalifikator fra 5A*',
                related: [],
                children: [],
              },
              {
                id: 'YNWM',
                label: 'Vintersport',
                originalLabel: 'Barn og ungdom: vintersport',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'YNWM1',
                    label: 'Skisport',
                    originalLabel: 'Barn og ungdom: vintersport: skisport',
                    alternativeLabels: ['snøbrett'],
                    note: 'Her: langrenn, hopp, kombinert, alpint, telemark, snowboard',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNWM2',
                    label: 'Ishockey',
                    originalLabel: 'Barn og ungdom: vintersport: ishockey',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YNWP',
                label: 'Friluftsliv',
                originalLabel: 'Barn og ungdom: friluftsliv',
                alternativeLabels: [],
                note: 'Her: klatring, orientering, sportsfiske, camping, grotting. NB!: YNWP kan kombineres med SZ* eller SV*, men dersom de kombineres, MÅ en Y*-kode angis som den viktigste koden og kombineres med en kvalifikator fra 5A*',
                related: [],
                children: [],
              },
              {
                id: 'YNWT',
                label: 'Motorsport',
                originalLabel: 'Barn og ungdom: motorsport',
                alternativeLabels: ['rally', 'formel 1'],
                note: 'Her: bilsport, billøp, motorsykkelløp, luftsport, båtsport. NB!: YNWT kan kombineres med SM*, men dersom de kombineres, MÅ en Y*-kode angis som den viktigste koden og kombineres med en kvalifikator fra 5A*',
                related: [],
                children: [],
              },
              {
                id: 'YNWW',
                label: 'Svømming og vannsport',
                originalLabel: 'Barn og ungdom: svømming og vannsport',
                alternativeLabels: ['polo'],
                note: 'Her: dykking, surfing, vannski, seiling, båtsport. NB!: YNWW kan kombineres med SP*, men dersom de kombineres, MÅ en Y*-kode angis som den viktigste koden og kombineres med en kvalifikator fra 5A*',
                related: [],
                children: [],
              },
              {
                id: 'YNWY',
                label: 'Sykling, rullebrett og rulleskøyter',
                originalLabel:
                  'Barn og ungdom: sykling, rullebrett og rulleskøyter',
                alternativeLabels: ['skateboard'],
                note: 'NB!: YNWY kan kombineres med SM*, men dersom de kombineres, MÅ en Y*-kode angis som den viktigste koden og kombineres med en kvalifikator fra 5A*',
                related: [],
                children: [],
              },
              {
                id: 'YNWZ',
                label: 'Idrettslag og -klubber',
                originalLabel: 'Barn og ungdom: idrettslag og -klubber',
                alternativeLabels: ['idrettsklubber'],
                note: 'Brukes med andre YNW*-koder som angir sport',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YNX',
            label: 'Mysterier og det overnaturlige',
            originalLabel: 'Barn og ungdom: mysterier og det overnaturlige',
            alternativeLabels: [],
            note: 'Her: det paranormale, overtro, astrologi, horoskop, tarot, spådomskunst etc. NB!: YNX* kan kombineres med VX* dersom det ikke fins en mer spesifikk Y*-kode. Dersom de kombineres, MÅ en Y*-kode angis som den viktigste koden og kombineres med en kvalifikator fra 5A*',
            related: [],
            children: [
              {
                id: 'YNXB',
                label: 'Overnaturlige og mytologiske vesener',
                originalLabel:
                  'Barn og ungdom: overnaturlige og mytologiske vesener',
                alternativeLabels: [
                  'demoner',
                  'kentaurer',
                  'fauner',
                  'den avskyelige snømannen',
                  'sjømonster',
                ],
                note: 'Her: monstre og vesener fra myter og legender, magiske skapninger. Bruk YNXB* med YF* for fortellinger om disse skapningene',
                related: [],
                children: [
                  {
                    id: 'YNXB1',
                    label: 'Drager',
                    originalLabel: 'Barn og ungdom: drager',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNXB2',
                    label: 'Vampyrer, varulver og andre formskiftere',
                    originalLabel:
                      'Barn og ungdom: vampyrer, varulver og andre formskiftere',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNXB3',
                    label: 'Zombier, spøkelser og udøde',
                    originalLabel:
                      'Barn og ungdom: zombier, spøkelser og udøde',
                    alternativeLabels: [],
                    note: 'Her: mumier, skjeletter, poltergeist, demoner etc. Brukes med YF* for spøkelseshistorier eller andre historier om disse skapningene',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNXB4',
                    label: 'Feer og alver',
                    originalLabel: 'Barn og ungdom: feer og alver',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNXB5',
                    label: 'Enhjørninger',
                    originalLabel: 'Barn og ungdom: enhjørninger',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNXB6',
                    label: 'Havfruer / havmenn / havfolk',
                    originalLabel:
                      'Barn og ungdom: havfruer / havmenn / havfolk',
                    alternativeLabels: [],
                    note: 'Her: sirener, vannånder, selkier',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YNXB7',
                    label: 'Troll og kjemper',
                    originalLabel: 'Barn og ungdom: troll og kjemper',
                    alternativeLabels: [],
                    note: 'Her: mytiske skapninger som er delvis menneskelige, eller som har menneskelige trekk; dverger, gnomer, gobliner',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YNXF',
                label: 'UFO-er og utenomjordiske vesener',
                originalLabel:
                  'Barn og ungdom: UFO-er og utenomjordiske vesener',
                alternativeLabels: ['ET', 'romvesener'],
                note: 'Her: aliens',
                related: [],
                children: [],
              },
              {
                id: 'YNXW',
                label: 'Hekser, trollmenn og magikere',
                originalLabel: 'Barn og ungdom: hekser, trollmenn og magikere',
                alternativeLabels: ['magi', 'trylleformler'],
                note: 'Se også YFD <a href="/thema/nb/YFD">Skjønnlitteratur for barn og ungdom: grøssere og spøkelsesfortellinger</a>',
                related: [
                  {
                    id: 'YFD',
                    label: 'Grøssere og spøkelseshistorier',
                    originalLabel:
                      'Skjønnlitteratur for barn og ungdom: grøssere og spøkelsesfortellinger',
                    note: 'Brukes for spøkelsesfortellinger og overnaturlige og skumle fortellinger. Brukes med YNX* for fortellinger om spesielle overnaturlige eller paranormale vesener. Se også YNXW <a href="/thema/nb/YNXW">Barn og ungdom: hekser, trollmenn og magikere</a>',
                  },
                ],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'YP',
        label: 'Læremidler',
        originalLabel: 'Læremidler',
        alternativeLabels: [],
        note: 'Bruk YP*-koder for alle skolebøker og undervisningsmateriell for grunnskolen og videregående skole. For videreutdanning, høyere utdanning og voksenopplæring foretrekkes koder for dette. Brukes med minst en kvalifikator for pedagogisk formål der det er hensiktsmessig, eller med en kvalifikator fra 5A* dersom alder også er viktig. Brukes med JNU eller JNUM hvor J*ar angitt som den viktigste koden dersom boka er ment for læreren. NB!: YP* kan kombineres med andre koder fra Y* eller koder fra A*-W*, men dersom de kombineres med A*-W* MÅ alltid en YP*-koden angis som den viktigste koden, bortsett fra i kombinasjon med JNU eller JNUM. Se også JNU <a href="/thema/nb/JNU">Undervisning i et spesifikt fag</a>, JNUM <a href="/thema/nb/JNUM">Lærerveiledninger og undervisningsmateriell</a>',
        related: [
          {
            id: 'JNU',
            label: 'Undervisning i et spesifikt fag',
            originalLabel: 'Undervisning i et spesifikt fag',
            note: 'Bruk alle JNU*-koder for bøker som handler om undervisning i et spesifikt fag. Tildel også YP*-kode for faget som det undervises i, eller fagkoder for bøker rettet mot voksne som skal lære seg et spesifikt fag. Tildel også kvalifikator(er) for pedagogisk formål, hvis hensiktsmessig. JNU bør her være hovedkategori',
          },
          {
            id: 'JNUM',
            label: 'Lærerveiledninger og undervisningsmateriell',
            originalLabel: 'Lærerveiledninger og undervisningsmateriell',
            note: 'Her: interaktive læremidler, lærerveiledninger, tilleggslitteratur og -materiell etc. Tildel også YP*-kode for faget som det undervises i, eller fagkoder der det er snakk om lærerveiledninger eller undervisningsmateriell for voksne som skal lære seg et spesifikt fag. Tildel også kvalifikator(er) for pedagogisk formål, hvis hensiktsmessig. JNU bør her være hovedkategori',
          },
        ],
        children: [
          {
            id: 'YPA',
            label: 'Læremidler: kunst',
            originalLabel: 'Læremidler: kunst',
            alternativeLabels: [],
            note: 'Bruk stilkvalifikatorer der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'YPAB',
                label: 'Læremidler: kunst og design',
                originalLabel: 'Læremidler: kunst og design',
                alternativeLabels: [],
                note: 'Bruk stilkvalifikatorer der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'YPAD',
                label: 'Læremidler: musikk',
                originalLabel: 'Læremidler: musikk',
                alternativeLabels: [],
                note: 'Bruk stilkvalifikatorer der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'YPAF',
                label: 'Læremidler: drama og scenekunst',
                originalLabel: 'Læremidler: drama og scenekunst',
                alternativeLabels: [],
                note: 'Her: skuespill for skoler, dans',
                related: [],
                children: [],
              },
              {
                id: 'YPAG',
                label: 'Læremidler: moter og tekstil',
                originalLabel: 'Læremidler: moter og tekstil',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'YPAK',
                label: 'Læremidler: håndverk',
                originalLabel: 'Læremidler: håndverk',
                alternativeLabels: ['makerspace', 'hackerspace'],
                note: 'Her: skaperverksted, sløyd, tradisjonelt kunsthåndverk. Brukes for tverrfaglige, praktiske bøker',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YPC',
            label: 'Læremidler: språk, litteratur, lese- og skriveopplæring',
            originalLabel:
              'Læremidler: språk, litteratur, lese- og skriveopplæring',
            alternativeLabels: [],
            note: 'Bruk språkkvalifikatorer der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'YPCA',
                label: 'Læremidler: førstespråk/morsmål',
                originalLabel: 'Læremidler: førstespråk/morsmål',
                alternativeLabels: [],
                note: 'Her: norsk i den norske skolen (obs! Ikke morsmålsundervisning for fremmedspråklige elever)',
                related: [],
                children: [
                  {
                    id: 'YPCA1',
                    label:
                      'Læremidler: førstespråk/morsmål: grunnleggende lese- og skriveopplæring',
                    originalLabel:
                      'Læremidler: førstespråk/morsmål: grunnleggende lese- og skriveopplæring',
                    alternativeLabels: [],
                    note: 'Brukes for bøker ment for grunnleggende leseopplæring, gjenkjenning av bokstaver og ord',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPCA2',
                    label:
                      'Læremidler: førstespråk/morsmål: lese- og skriveferdigheter',
                    originalLabel:
                      'Læremidler: førstespråk/morsmål: lese- og skriveferdigheter',
                    alternativeLabels: [
                      'lesing',
                      'skriving',
                      'leseferdigheter',
                    ],
                    note: 'Brukes for bøker som gir opplæring i det og lese og skrive selvstendig, leseforståelse, kreativ skriving, syntetisk lesemetode etc.',
                    related: [],
                    children: [
                      {
                        id: 'YPCA21',
                        label:
                          'Læremidler: førstespråk/morsmål: lesehefter, lesing og leseplaner',
                        originalLabel:
                          'Læremidler: førstespråk/morsmål: lesehefter, lesing og leseplaner',
                        alternativeLabels: ['easy reader'],
                        note: 'Brukes for bøker og lesehefter som er en del av et strukturert opplæringsopplegg, vanligvis inndelt i ulike språknivåer og ordforråd. Brukes med kvalifikator(er) for pedagogisk formål og andre emnekoder fra YF* for å angi om det er snakk om fortellinger, eller YN* for emne',
                        related: [],
                        children: [],
                      },
                      {
                        id: 'YPCA22',
                        label: 'Læremidler: førstespråk/morsmål: håndskrift',
                        originalLabel:
                          'Læremidler: førstespråk/morsmål: håndskrift',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'YPCA23',
                        label:
                          'Læremidler: førstespråk/morsmål: staving og vokabular',
                        originalLabel:
                          'Læremidler: førstespråk/morsmål: staving og vokabular',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'YPCA4',
                    label:
                      'Læremidler: førstespråk/morsmål: grammatikk og tegnsetting',
                    originalLabel:
                      'Læremidler: førstespråk/morsmål: grammatikk og tegnsetting',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPCA5',
                    label:
                      'Læremidler: førstespråk/morsmål: muntlige ferdigheter',
                    originalLabel:
                      'Læremidler: førstespråk/morsmål: muntlige ferdigheter',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPCA9',
                    label: 'Læremidler: førstespråk/morsmål: litteratur',
                    originalLabel:
                      'Læremidler: førstespråk/morsmål: litteratur',
                    alternativeLabels: [],
                    note: 'Brukes for læremidler innen litteraturvitenskap på studentens morsmål, eller bøker som inneholder litterær analyse av / er guider til spesifikke utgivelser, ment for elever og studenter',
                    related: [],
                    children: [
                      {
                        id: 'YPCA91',
                        label:
                          'Læremidler: førstespråk/morsmål: skoleutgaver av litterære tekster',
                        originalLabel:
                          'Læremidler: førstespråk/morsmål: skoleutgaver av litterære tekster',
                        alternativeLabels: [],
                        note: 'Brukes for skoleutgaver av litterære tekster - skjønnlitteratur, lyrikk, prosa, drama etc. - og som ofte har supplerende materiale som noter, oppgaver, spørsmål etc. Brukes med kvalifikator(er) for språk og pedagogisk formål og emnekoder fra YF* for å angi sjanger for den litterære teksten',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'YPCK',
                label: 'Læremidler: fremmed- eller andrespråk',
                originalLabel: 'Læremidler: fremmed- eller andrespråk',
                alternativeLabels: ['fremmedspråk'],
                note: 'Bruk YPCK*-koder med kvalifikator(er) for språk der det er hensiktsmessig',
                related: [],
                children: [
                  {
                    id: 'YPCK2',
                    label:
                      'Læremidler: fremmed- eller andrespråk: språkopplæring',
                    originalLabel:
                      'Læremidler: fremmed- eller andrespråk: språkopplæring',
                    alternativeLabels: ['fremmedspråk'],
                    note: 'Brukes for språkopplæring og kursmateriell',
                    related: [],
                    children: [
                      {
                        id: 'YPCK21',
                        label:
                          'Læremidler: fremmed- eller andrespråk: språkopplæring: grammatikk, vokabular og uttale',
                        originalLabel:
                          'Læremidler: fremmed- eller andrespråk: språkopplæring: grammatikk, vokabular og uttale',
                        alternativeLabels: ['fremmedspråk'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'YPCK22',
                        label:
                          'Læremidler: fremmed- eller andrespråk: språkopplæring: lettlestbøker',
                        originalLabel:
                          'Læremidler: fremmed- eller andrespråk: språkopplæring: lettlestbøker',
                        alternativeLabels: ['easy readers', 'fremmedspråk'],
                        note: 'Brukes for bøker som er ment for de som lærer et språk, vanligvis er nivå og størrelse på vokabular angitt. Brukes med kvalifikator(er) for språk og pedagogisk formål og andre koder for barne- og ungdomsbøker dersom det er skjønnlitterære utgivelser som kan være av allmenn interesse (YF*)',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'YPCK9',
                    label: 'Læremidler: fremmed- eller andrespråk: litteratur',
                    originalLabel:
                      'Læremidler: fremmed- eller andrespråk: litteratur',
                    alternativeLabels: ['fremmedspråk'],
                    note: 'Brukes for læremidler innen litteraturvitenskap eller bøker som inneholder litterær analyse av / er guider til spesifikke utgivelser, ment for elever og studenter',
                    related: [],
                    children: [
                      {
                        id: 'YPCK91',
                        label:
                          'Læremidler: fremmed- eller andrespråk: skoleutgaver av litterære tekster',
                        originalLabel:
                          'Læremidler: fremmed- eller andrespråk: skoleutgaver av litterære tekster',
                        alternativeLabels: ['fremmedspråk'],
                        note: 'Brukes for skoleutgaver av litterære tekster - skjønnlitteratur, lyrikk, prosa, drama etc. - og som ofte har supplerende materiale som noter, oppgaver, spørsmål etc. Brukes med kvalifikator(er) for språk og pedagogisk formål, og emnekoder fra YF* for å angi sjanger for den litterære teksten',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'YPCS',
                label: 'Læremidler: klassiske språk',
                originalLabel: 'Læremidler: klassiske språk',
                alternativeLabels: [],
                note: 'Bruk YPCS*-koder med kvalifikator(er) for språk der det er hensiktsmessig',
                related: [],
                children: [
                  {
                    id: 'YPCS4',
                    label: 'Læremidler: klassiske språk: språkopplæring',
                    originalLabel:
                      'Læremidler: klassiske språk: språkopplæring',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPCS9',
                    label: 'Læremidler: klassiske språk: litteratur',
                    originalLabel: 'Læremidler: klassiske språk: litteratur',
                    alternativeLabels: [],
                    note: 'Brukes for læremidler innen litteraturvitenskap eller bøker som inneholder litterær analyse av / er guider til spesifikke utgivelser, ment for elever og studenter',
                    related: [],
                    children: [
                      {
                        id: 'YPCS91',
                        label:
                          'Læremidler: klassiske språk: skoleutgaver av litterære tekster',
                        originalLabel:
                          'Læremidler: klassiske språk: skoleutgaver av litterære tekster',
                        alternativeLabels: [],
                        note: 'Brukes for skoleutgaver av litterære tekster - skjønnlitteratur, lyrikk, prosa, drama etc. - og som ofte har supplerende materiale som noter, oppgaver, spørsmål etc. Brukes med kvalifikator(er) for sted, språk og pedagogisk formål, og emnekoder fra YF* for å angi sjanger for den litterære teksten',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: 'YPJ',
            label: 'Læremidler: humaniora og samfunnsfag',
            originalLabel: 'Læremidler: humaniora og samfunnsfag',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'YPJH',
                label: 'Læremidler: historie',
                originalLabel: 'Læremidler: historie',
                alternativeLabels: [],
                note: 'Brukes med kvalifikator(er) for tidsperiode og pedagogisk formål der det er hensiktsmessig. NB!: for læremidler innen historie kan YPJH kombineres med koder fra N*. Dersom de kombineres, MÅ alltid en kode fra YP* angis som den viktigste koden og kombineres med en kvalifikator for pedagogisk formål der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'YPJJ',
                label: 'Læremidler: samfunnskunnskap',
                originalLabel: 'Læremidler: samfunnskunnskap',
                alternativeLabels: [],
                note: 'Her: sosiologi',
                related: [],
                children: [
                  {
                    id: 'YPJJ1',
                    label: 'Læremidler: politikk og statskunnskap',
                    originalLabel: 'Læremidler: politikk og statskunnskap',
                    alternativeLabels: ['regjering'],
                    note: 'Her: myndigheter',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPJJ3',
                    label: 'Læremidler: statsborgerskap og sosialt arbeid',
                    originalLabel:
                      'Læremidler: statsborgerskap og sosialt arbeid',
                    alternativeLabels: [],
                    note: 'Her: samfunnskunnskap',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPJJ4',
                    label: 'Læremidler: tverrfaglig undervisning',
                    originalLabel: 'Læremidler: tverrfaglig undervisning',
                    alternativeLabels: [],
                    note: 'Brukes for tverrfaglige læremidler som ofte er knyttet til elevens bosted, og som tar for seg skikker, historie, geografi, natur etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPJJ5',
                    label: 'Læremidler: psykologi',
                    originalLabel: 'Læremidler: psykologi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPJJ6',
                    label: 'Læremidler: helse',
                    originalLabel: 'Læremidler: helse',
                    alternativeLabels: [],
                    note: 'Her: personlig helse, velvære, forhold, personlig pleie og sikkerhet, interaksjon med andre, mental helse, seksualundervisning. Brukes med YX*-koder der det er hensiktsmessig. Bruk YXR for sikkerhet, YXAX for seksualundervisning, med kvalifikatorer for pedagogisk formål der det er hensiktsmessig, og med 5A* for å angi målgruppe',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YPJK',
                label: 'Læremidler: medievitenskap',
                originalLabel: 'Læremidler: medievitenskap',
                alternativeLabels: [],
                note: 'Her: læremidler om film, nye medier, tv, digital kommunikasjon. Se også YPJX <a href="/thema/nb/YPJX">Læremidler: kulturstudier</a>',
                related: [
                  {
                    id: 'YPJX',
                    label: 'Læremidler: kulturstudier',
                    originalLabel: 'Læremidler: kulturstudier',
                    note: 'Brukes for læremidler som dekker ei blanding av historie, geografi, litteratur, samfunnsanalyse, kultur og språk. Brukes med YPC* for læremidler som er ment for språkopplæring og med kvalifikatorer for geografi og språk der det er hensiktsmessig. Se også YPJK <a href="/thema/nb/YPJK">Læremidler: medievitenskap</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'YPJL',
                label: 'Læremidler: filosofi og etikk',
                originalLabel: 'Læremidler: filosofi og etikk',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'YPJM',
                label: 'Læremidler: jus og rettsvitenskap',
                originalLabel: 'Læremidler: jus og rettsvitenskap',
                alternativeLabels: [],
                note: 'Brukes for alle typer undervisningsmateriale som tar for seg rettsvitenskap, jus og lover, ment for videregående skole',
                related: [],
                children: [],
              },
              {
                id: 'YPJN',
                label: 'Læremidler: religion',
                originalLabel: 'Læremidler: religion',
                alternativeLabels: [],
                note: 'Her: læremidler som introduserer elevene for religion og sammenlignende religionsvitenskap. Se også YNR <a href="/thema/nb/YNR">Barn og ungdom: filosofi, religion og tro</a>',
                related: [
                  {
                    id: 'YNR',
                    label: 'Filosofi, religion og tro',
                    originalLabel: 'Barn og ungdom: filosofi, religion og tro',
                    note: 'Her: spirituelle eller inspirerende bøker som handler om tro, det å ikke tro, agnostisisme, ateisme. Bruk YNR*-koder for filosofiske retninger, trossystemer, spirituelle tradisjoner, religion eller fortellinger fra en religiøs tradisjon. Brukes med andre Y*-koder og kvalifikator(er) fra 5PG* der det er hensiktsmessig. Se også YPJN* <a href="/thema/nb/YPJN">Læremidler: religion</a> og dens underkategorier, YXZR <a href="/thema/nb/YXZR">Barn og ungdom: personlige og sosiale spørsmål: religion</a>',
                  },
                ],
                children: [
                  {
                    id: 'YPJN1',
                    label: 'Læremidler: hinduisme',
                    originalLabel: 'Læremidler: religion: hinduisme',
                    alternativeLabels: [],
                    note: 'Se også YNRD <a href="/thema/nb/YNRD">Barn og ungdom: hinduisme</a>',
                    related: [
                      {
                        id: 'YNRD',
                        label: 'Hinduisme',
                        originalLabel: 'Barn og ungdom: hinduisme',
                        note: 'Brukes med YNRX for hellige tester fra hinduismen og med YNRY for fortellinger for barn eller ungdom som tar utgangspunkt i disse tekstene eller de religiøse tradisjonene. Se også YPJN1 <a href="/thema/nb/YPJN1">Læremidler: religion: hinduisme</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'YPJN2',
                    label: 'Læremidler: buddhisme',
                    originalLabel: 'Læremidler: religion: buddhisme',
                    alternativeLabels: [],
                    note: 'Se også YNRF <a href="/thema/nb/YNRF">Barn og ungdom: buddhisme</a>',
                    related: [
                      {
                        id: 'YNRF',
                        label: 'Buddhisme',
                        originalLabel: 'Barn og ungdom: buddhisme',
                        note: 'Brukes med YNRX for hellige tester fra buddhismen og med YNRY for fortellinger for barn eller ungdom som tar utgangspunkt i disse tekstene eller de religiøse tradisjonene. Se også YPJN2 <a href="/thema/nb/YPJN2">Læremidler: religion: buddhisme</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'YPJN3',
                    label: 'Læremidler: jødedom',
                    originalLabel: 'Læremidler: religion: jødedom',
                    alternativeLabels: [],
                    note: 'Se også YNRJ <a href="/thema/nb/YNRJ">Barn og ungdom: jødedom</a>',
                    related: [
                      {
                        id: 'YNRJ',
                        label: 'Jødedom',
                        originalLabel: 'Barn og ungdom: jødedom',
                        note: 'Brukes med YNRX for hellige tester fra jødedommen og med YNRY for fortellinger for barn eller ungdom som tar utgangspunkt i disse tekstene eller de religiøse tradisjonene. Se også YPJN3 <a href="/thema/nb/YPJN3">Læremidler: religion: jødedom</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'YPJN4',
                    label: 'Læremidler: kristendom',
                    originalLabel: 'Læremidler: religion: kristendom',
                    alternativeLabels: [],
                    note: 'Se også YNRM <a href="/thema/nb/YNRM">Barn og ungdom: kristendom</a>',
                    related: [
                      {
                        id: 'YNRM',
                        label: 'Kristendom',
                        originalLabel: 'Barn og ungdom: kristendom',
                        note: 'Her: barnebibler. Brukes med YNRX for hellige tester fra kristendommen og med YNRY for bibelfortellinger for barn eller ungdom. Se også YPJN4 <a href="/thema/nb/YPJN4">Læremidler: religion: kristendom</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'YPJN5',
                    label: 'Læremidler: islam',
                    originalLabel: 'Læremidler: religion: islam',
                    alternativeLabels: [],
                    note: 'Se også YNRP <a href="/thema/nb/YNRP">Barn og ungdom: islam</a>',
                    related: [
                      {
                        id: 'YNRP',
                        label: 'Islam',
                        originalLabel: 'Barn og ungdom: islam',
                        note: 'Brukes med YNRX for hellige tester fra islam og med YNRY for fortellinger for barn eller ungdom som tar utgangspunkt Koranen eller andre hellige tekster eller muslimske tradisjoner. Se også YPJN5 <a href="/thema/nb/YPJN5">Læremidler: religion: islam</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'YPJN9',
                    label: 'Læremidler: andre religioner',
                    originalLabel: 'Læremidler: religion: andre religioner',
                    alternativeLabels: [],
                    note: 'Brukes med kvalifikatorer fra 5PG*. NB!: YPJN kan kombineres med QR* for religioner som ikke har en egen kode i Y*. Dersom de kombineres, MÅ alltid en YP*-kode angis som den viktigste koden. Se også YNRR <a href="/thema/nb/YNRR">Barn og ungdom: andre religioner og spirituelle trossamfunn</a>',
                    related: [
                      {
                        id: 'YNRR',
                        label: 'Andre religioner og åndelige trossamfunn',
                        originalLabel:
                          'Barn og ungdom: andre religioner og spirituelle trossamfunn',
                        note: 'Brukes for bøker om religioner som praktiseres i samtida, men som ikke har egne kategorier. Brukes med 5PG* eller andre kvalifikatorer fra 5P* og kvalifikatorer for geografi der det er hensiktsmessig QR*-koder og kvalifikator for sted der det er hensiktsmessig. NB!* YNNR kan kombineres med QR* dersom det ikke fins mer spesifikke Y*-koder, men dersom Y*og QR* kombineres, MÅ Y*-koden angis som den mest viktigste, og kombineres med en kvalifikator fra 5A*. Se også YPJN9 <a href="/thema/nb/YPJN9">Læremidler: religion: andre religioner</a>',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'YPJT',
                label: 'Læremidler: geografi',
                originalLabel: 'Læremidler: geografi',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'YPJV',
                label: 'Læremidler: handel og økonomi',
                originalLabel: 'Læremidler: handel og økonomi',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'YPJV1',
                    label: 'Læremidler: økonomi',
                    originalLabel: 'Læremidler: økonomi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPJV2',
                    label: 'Læremidler: bedriftsøkonomi',
                    originalLabel: 'Læremidler: bedriftsøkonomi',
                    alternativeLabels: [],
                    note: 'Her: arbeidsliv. Brukes for undervisningsmateriale som dekker aspekter av bedriftsøkonomi og -ledelse',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPJV3',
                    label: 'Læremidler: regnskap',
                    originalLabel: 'Læremidler: regnskap',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YPJX',
                label: 'Læremidler: kulturstudier',
                originalLabel: 'Læremidler: kulturstudier',
                alternativeLabels: [],
                note: 'Brukes for læremidler som dekker ei blanding av historie, geografi, litteratur, samfunnsanalyse, kultur og språk. Brukes med YPC* for læremidler som er ment for språkopplæring og med kvalifikatorer for geografi og språk der det er hensiktsmessig. Se også YPJK <a href="/thema/nb/YPJK">Læremidler: medievitenskap</a>',
                related: [
                  {
                    id: 'YPJK',
                    label: 'Læremidler: medievitenskap',
                    originalLabel: 'Læremidler: medievitenskap',
                    note: 'Her: læremidler om film, nye medier, tv, digital kommunikasjon. Se også YPJX <a href="/thema/nb/YPJX">Læremidler: kulturstudier</a>',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'YPM',
            label: 'Læremidler: matematikk, naturfag og teknologi',
            originalLabel: 'Læremidler: matematikk, naturfag og teknologi',
            alternativeLabels: ['science technology engineering mathematics'],
            note: 'Her: STEM. NB!: YPM* kan kombineres med P* eller T*. Dersom de kombineres, MÅ alltid en YP*-kode angis som den viktigste koden',
            related: [],
            children: [
              {
                id: 'YPMF',
                label: 'Læremidler: matematikk og tallforståelse',
                originalLabel: 'Læremidler: matematikk og tallforståelse',
                alternativeLabels: [],
                note: 'Her: statistikk',
                related: [],
                children: [
                  {
                    id: 'YPMF1',
                    label:
                      'Læremidler: matematikk og tallforståelse: aritmetikk / gangetabellen',
                    originalLabel:
                      'Læremidler: matematikk og tallforståelse: aritmetikk / gangetabellen',
                    alternativeLabels: [],
                    note: 'Her: addisjon, subtraksjon, multiplikasjon, divisjon',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPMF2',
                    label: 'Læremidler: matematikk og tallforståelse: algebra',
                    originalLabel:
                      'Læremidler: matematikk og tallforståelse: algebra',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPMF3',
                    label: 'Læremidler: matematikk og tallforståelse: geometri',
                    originalLabel:
                      'Læremidler: matematikk og tallforståelse: geometri',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YPMP',
                label: 'Læremidler: naturvitenskap',
                originalLabel: 'Læremidler: naturvitenskap',
                alternativeLabels: [],
                note: 'Her: naturvitenskapene',
                related: [],
                children: [
                  {
                    id: 'YPMP1',
                    label: 'Læremidler: biologi',
                    originalLabel: 'Læremidler: biologi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPMP3',
                    label: 'Læremidler: kjemi',
                    originalLabel: 'Læremidler: kjemi',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPMP5',
                    label: 'Læremidler: fysikk',
                    originalLabel: 'Læremidler: fysikk',
                    alternativeLabels: [],

                    related: [],
                    children: [
                      {
                        id: 'YPMP51',
                        label: 'Læremidler: astronomi',
                        originalLabel: 'Læremidler: astronomi',
                        alternativeLabels: [],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'YPMP6',
                    label: 'Læremidler: miljøvitenskap',
                    originalLabel: 'Læremidler: miljøvitenskap',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPMP7',
                    label: 'Læremidler: geovitenskap',
                    originalLabel: 'Læremidler: geovitenskap',
                    alternativeLabels: [],
                    note: 'Her: geologi, geomorfologi, oseanografi, fysisk geografi, økologi, glasiologi, klimatologi, meteorologi',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YPMT',
                label: 'Læremidler: teknologi',
                originalLabel: 'Læremidler: teknologi',
                alternativeLabels: [],

                related: [],
                children: [
                  {
                    id: 'YPMT2',
                    label: 'Læremidler: design og teknologi',
                    originalLabel: 'Læremidler: design og teknologi',
                    alternativeLabels: [],
                    note: 'Her: læremidler om produktdesign',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPMT3',
                    label: 'Læremidler: ingeniørvitenskap',
                    originalLabel: 'Læremidler: ingeniørvitenskap',
                    alternativeLabels: [],
                    note: 'Her: læremidler om kjøretøyteknikk og -teknologi. NB!: YPMT3 kan kombineres med T* for spesialiserte bøker innen ingeniørutdanning. Dersom de kombineres, MÅ alltid YPMT3 angis som den viktigste koden',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPMT4',
                    label: 'Læremidler: næringsmiddelteknologi, matlaging',
                    originalLabel:
                      'Læremidler: næringsmiddelteknologi, matlaging',
                    alternativeLabels: ['Mat og helse'],
                    note: 'Her: læremidler om ernæring, tilberedning av mat, matsikkerhet. Se også YPWC4 <a href="/thema/nb/YPWC4">Læremidler: servicefag</a>',
                    related: [
                      {
                        id: 'YPWC4',
                        label: 'Læremidler: servicefag',
                        originalLabel: 'Læremidler: servicefag',
                        note: 'Brukes for læremidler for de som utdanner seg til yrker i serviceindustrien, reiselivsfag, turisme, hoteller, catering, serveringsindustri. Se også YPMT4 <a href="/thema/nb/YPMT4">Læremidler: næringsmiddelteknologi, matlaging</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'YPMT5',
                    label: 'Læremidler: elektronikk',
                    originalLabel: 'Læremidler: elektronikk',
                    alternativeLabels: [],
                    note: 'Her: læremidler om elektroniske produkter, systemer og teknologi, elektrisitet',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPMT6',
                    label: 'Læremidler: informasjonsteknologi',
                    originalLabel: 'Læremidler: informasjonsteknologi',
                    alternativeLabels: [],
                    note: 'Her: undervisning i digital kompetanse, koding eller programmering, kommunikasjonsteknologi, informatikk',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPMT7',
                    label: 'Læremidler: teknisk tegning',
                    originalLabel: 'Læremidler: teknisk tegning',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPMT8',
                    label: 'Læremidler: trearbeid, metallarbeid etc.',
                    originalLabel: 'Læremidler: trearbeid, metallarbeid etc.',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'YPW',
            label: 'Læremidler: yrkesfag og andre fag',
            originalLabel: 'Læremidler: yrkesfag og andre fag',
            alternativeLabels: [],
            note: 'Bruk YPW*-koder for alle skolebøker og undervisningsmateriell for grunnskolen og videregående skole. Brukes med kvalifikator(er) for pedagogisk formål der det er hensiktsmessig. NB!: YPW* kan kombineres med T* eller andre koder. Dersom de kombineres, MÅ alltid en YPW*-kode angis som den viktigste koden',
            related: [],
            children: [
              {
                id: 'YPWB',
                label: 'Læremidler: hjem og familie',
                originalLabel: 'Læremidler: hjem og familie',
                alternativeLabels: [],
                note: 'Brukes for læremidler som tar for seg hvordan man styrer og organiserer en familie og et hjem, og familien i samfunnet',
                related: [],
                children: [],
              },
              {
                id: 'YPWC',
                label: 'Læremidler: yrkesfag',
                originalLabel: 'Læremidler: yrkesfag',
                alternativeLabels: [],
                note: 'Her: yrkesfag som ikke er oppført andre steder. Brukes med kvalifikator for pedagogisk formål der det er hensiktsmessig. NB!: YPWC* kan kombineres med T* eller andre koder. Dersom de kombineres, MÅ alltid en YPWC*-kode angis som den viktigste koden',
                related: [],
                children: [
                  {
                    id: 'YPWC1',
                    label: 'Læremidler: helse- og oppvekstfag',
                    originalLabel: 'Læremidler: helse- og oppvekstfag',
                    alternativeLabels: [],
                    note: 'Brukes for læremidler for de som utdanner seg til yrker innen helse- og oppvekstfag',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPWC2',
                    label: 'Læremidler: barne- og ungdomsarbeider',
                    originalLabel: 'Læremidler: barne- og ungdomsarbeider',
                    alternativeLabels: [],
                    note: 'Brukes for læremidler ment for de som utdanner seg til yrker knyttet til arbeid med barn',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPWC3',
                    label: 'Læremidler: salg og detaljhandel',
                    originalLabel: 'Læremidler: salg og detaljhandel',
                    alternativeLabels: [],
                    note: 'Brukes for læremidler ment for de som utdanner seg til yrker innen salg og detaljhandel',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPWC4',
                    label: 'Læremidler: servicefag',
                    originalLabel: 'Læremidler: servicefag',
                    alternativeLabels: [],
                    note: 'Brukes for læremidler for de som utdanner seg til yrker i serviceindustrien, reiselivsfag, turisme, hoteller, catering, serveringsindustri. Se også YPMT4 <a href="/thema/nb/YPMT4">Læremidler: næringsmiddelteknologi, matlaging</a>',
                    related: [
                      {
                        id: 'YPMT4',
                        label: 'Læremidler: næringsmiddelteknologi, matlaging',
                        originalLabel:
                          'Læremidler: næringsmiddelteknologi, matlaging',
                        note: 'Her: læremidler om ernæring, tilberedning av mat, matsikkerhet. Se også YPWC4 <a href="/thema/nb/YPWC4">Læremidler: servicefag</a>',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'YPWC5',
                    label: 'Læremidler: frisørfag og skjønnhetsbehandling',
                    originalLabel:
                      'Læremidler: frisørfag og skjønnhetsbehandling',
                    alternativeLabels: [
                      'spa',
                      'manikyr',
                      'pedikyr',
                      'barbering',
                      'fotterapi',
                      'hudpleie',
                      'fotpleie',
                      'sminke',
                    ],
                    note: 'Brukes for elever som tar utdanning innen kosmetologi, hudpleie, hårpleie, barbering, makeup og andre fagområder som har med skjønnhetsbehandling å gjøre. Se også TTVH <a href="/thema/nb/TTVH">Frisøryrket, skjønnhetsbehandlinger og salongarbeid</a>',
                    related: [
                      {
                        id: 'TTVH',
                        label:
                          'Frisøryrket, skjønnhetsbehandlinger og salongarbeid',
                        originalLabel:
                          'Frisøryrket, skjønnhetsbehandlinger og salongarbeid',
                        note: 'Her: profesjonell makeup, kosmetologi, barbering, hårdesign og -stell, hudpleie, massasje, manikyr, pedikyr og alle bøker som er rettet mot yrkesutøvere innen disse områdene. Brukes med kvalifikatorer fra 4CP* der det er hensiktsmessig, og med YPWC5 for bøker med for videregående skole',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'YPWC9',
                    label: 'Læremidler: utdanningsvalg',
                    originalLabel: 'Læremidler: utdanningsvalg',
                    alternativeLabels: [],
                    note: 'Brukes for læremidler som tar for seg arbeidslivet, hvilke kvalifikasjoner man trenger',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YPWD',
                label: 'Læremidler: bygg- og anleggsteknikk',
                originalLabel: 'Læremidler: bygg- og anleggsteknikk',
                alternativeLabels: [],
                note: 'Brukes for læremidler som tar for seg temaer nyttet til bygg- og anleggssektoren. NB!: YPWD* kan kombineres med TN*. Dersom de kombineres, MÅ alltid en YPWD*-kode angis som den viktigste koden. Se også TNT <a href="/thema/nb/TNT">Byggkompetanse og bransjer</a>',
                related: [
                  {
                    id: 'TNT',
                    label: 'Byggkompetanse',
                    originalLabel: 'Byggkompetanse og bransjer',
                    note: 'Her: bøker om konstruksjon, håndverksferdigheter og -områder. Se også THRX <a href="/thema/nb/THRX">Elektrikerfaget: ferdigheter og bransjer</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'YPWE',
                label:
                  'Læremidler: landbruk, hagebruk og relaterte utdanningsløp',
                originalLabel:
                  'Læremidler: landbruk, hagebruk og relaterte utdanningsløp',
                alternativeLabels: [],
                note: 'Her: fiskeri, skogbruk, dyrehold, landskapsforvaltning, smedarbeid etc. Brukes for elever som tar utdanning innen primærnæringene eller tilknyttede sektorer. NB!: YPWE* kan kombineres med TV* eller andre koder. Dersom de kombineres, MÅ alltid en YPWE*-kode angis som den viktigste koden',
                related: [],
                children: [],
              },
              {
                id: 'YPWF',
                label: 'Læremidler: kroppsøving',
                originalLabel: 'Læremidler: kroppsøving',
                alternativeLabels: ['idrett'],
                note: 'Her: gymtimer, trening. Brukes for læremidler for elever som tar for seg det å ta vare på kroppen gjennom fysisk trening og sport',
                related: [],
                children: [],
              },
              {
                id: 'YPWG',
                label: 'Læremidler: samferdsel, transport, logistikk',
                originalLabel: 'Læremidler: samferdsel, transport, logistikk',
                alternativeLabels: [],
                note: 'Her: distribusjon, yrkessjåfør. Brukes for læremidler for som tar utdanning inne disse sektorene. NB!: YPWG* kan kombineres med T* eller K*. Dersom de kombineres, MÅ alltid en YPWG*-kode angis som den viktigste koden. Se også TR* <a href="/thema/nb/TR">Transportteknologi og -næringer</a> og dens underkategorier',
                related: [
                  {
                    id: 'TR',
                    label: 'Transportteknologi og -næringer',
                    originalLabel: 'Transportteknologi og -næringer',
                    note: 'Se også KNG <a href="/thema/nb/KNG">Transportnæringer</a>, RPT <a href="/thema/nb/RPT">Transportplanlegging</a>, WG* <a href="/thema/nb/WG">Transport</a> og dens underkategorier',
                  },
                ],
                children: [],
              },
              {
                id: 'YPWL',
                label: 'Læremidler: studier / studieteknikk',
                originalLabel: 'Læremidler: studier / studieteknikk',
                alternativeLabels: [],
                note: 'Brukes for tverrfaglige læremidler som handler om det å studere, studieteknikk og studiekompetanse',
                related: [],
                children: [
                  {
                    id: 'YPWL1',
                    label: 'Læremidler: allmennkunnskap',
                    originalLabel: 'Læremidler: allmennkunnskap',
                    alternativeLabels: [],
                    note: 'Brukes for læremidler som brukes til undervisning i allmennkunnskap, kultur, kulturell bevissthet',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPWL2',
                    label: 'Læremidler: studieteknikk',
                    originalLabel: 'Læremidler: studieteknikk',
                    alternativeLabels: [],
                    note: 'Brukes for læremidler tar for seg studieteknikk, hvordan man kan tilegne seg kunnskap på en effektiv måte bl.a. gjennom skriveteknikker, hvordan ta notater, organisatoriske ferdigheter, bruk av biblioteket etc.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPWL3',
                    label: 'Læremidler: hukommelse og kreativ tenkning',
                    originalLabel: 'Læremidler: hukommelse og kreativ tenkning',
                    alternativeLabels: [],
                    note: 'Brukes for læremidler som lærer elever minneteknikker og hvordan man kan ta initiativ',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YPWL4',
                    label: 'Læremidler: sosiale ferdigheter og livsmestring',
                    originalLabel:
                      'Læremidler: sosiale ferdigheter og livsmestring',
                    alternativeLabels: [],
                    note: 'Brukes for læremidler som lærer elever om interaksjon og samhandling med andre, og hvordan man kan takle hverdagslivet',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YPWN',
                label:
                  'Læremidler: offentlige tjenester, nødetater og redningstjenester',
                originalLabel:
                  'Læremidler: offentlige tjenester, nødetater og redningstjenester',
                alternativeLabels: [],
                note: 'Brukes for læremidler som brukes av elever som tar utdanning innen brannvesen, ambulansetjeneste, eller militæret. NB!: YPWN kan kombineres med JKSW* eller JWC*. Dersom de kombineres, MÅ alltid YPWN angis som den viktigste koden',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YPZ',
            label: 'Læremidler: studiehåndbøker og repetisjonsguider',
            originalLabel: 'Læremidler: studiehåndbøker og repetisjonsguider',
            alternativeLabels: [],
            note: 'Brukes for ALLE studiehåndbøker og repetisjonsbøker for grunnskolen og videregående skole, sammendrag av tekster eller temaer. Brukes med YP*-kodene ovenfor for å indikere emne der det er mulig',
            related: [],
            children: [
              {
                id: 'YPZH',
                label: 'Læremidler: arbeidsbok for bruk i ferier',
                originalLabel: 'Læremidler: arbeidsbok for bruk i ferier',
                alternativeLabels: [],
                note: 'Brukes for læremidler som er ment brukt som en supplement til andre læremidler, og som vanligvis er ment brukt i ferier for å repetisjon og som en forberedelse til overgangen til et nytt skoletrinn. Oppgavene er ofte i et aktivitetsbasert og morsomt format. Brukes med kvalifikatorer for pedagogisk formål og med andre koder fra YP* for å angi et spesifikt fagområde, eller med YPZH for å dekke alle fagområder som hører til et skoleår',
                related: [],
                children: [],
              },
              {
                id: 'YPZN',
                label:
                  'Læremidler: kognitiv forståelse / visuell argumentasjon',
                originalLabel:
                  'Læremidler: kognitiv forståelse / visuell argumentasjon',
                alternativeLabels: [],
                note: 'Brukes for læremidler om problemløsning som bruker bilder og diagrammer, eller læremidler som skal brukes for å forbedre elevens evne til å forstå og analysere visuell informasjon og løse oppgaver som å identifisere relasjoner, likheter og forskjeller mellom former og mønstre, gjenkjenne visuelle sekvenser og relasjoner mellom objekter, og å huske disse',
                related: [],
                children: [],
              },
              {
                id: 'YPZP',
                label: 'Læremidler: logisk språkforståelse',
                originalLabel: 'Læremidler: logisk språkforståelse',
                alternativeLabels: [],
                note: 'Brukes for læremidler om problemløsning ved hjelp av ord og bokstaver, elle læremidler som skal brukes for å forbedre elevens evne til å tenke konstruktivt, ikke bare å forbedre leseflyt og ordgjenkjennelse',
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'YR',
        label: 'Oppslagsverk',
        originalLabel: 'Barn og ungdom: oppslagsverk',
        alternativeLabels: [],
        note: 'Brukes med andre koder fra Y* for å angi hvilket emneområde oppslagsverket dekker',
        related: [],
        children: [
          {
            id: 'YRD',
            label: 'Ordbøker, ordlister, språk',
            originalLabel:
              'Barn og ungdom: oppslagsverk: ordbøker, ordlister, språk',
            alternativeLabels: [],
            note: 'Her: enspråklige ordbøker / ordlister for bruk i skolen eller hjemme, andre bøker om språk, språkferdigheter, ordlister, grammatikk etc. Bruk YRD*-koder med kvalifikator(er) for språk der det er hensiktsmessig. Foretrekk YPC*-koder for læremidler',
            related: [],
            children: [
              {
                id: 'YRDC',
                label: 'Billedordbøker',
                originalLabel: 'Barn og ungdom: oppslagsverk: billedordbøker',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'YRDL',
                label: 'Tospråklige / flerspråklige ordbøker',
                originalLabel:
                  'Barn og ungdom: oppslagsverk: tospråklige / flerspråklige ordbøker',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'YRDM',
                label: 'Fremmedspråk, andrespråk',
                originalLabel:
                  'Barn og ungdom: oppslagsverk: fremmedspråk eller andrespråk',
                alternativeLabels: [],
                note: 'Her: bøker ment for å lære et fremmedspråk selv, ordlister',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YRE',
            label: 'Leksika, oppslagsverk',
            originalLabel: 'Barn og ungdom: leksika, oppslagsverk',
            alternativeLabels: [],
            note: 'Brukes bare for ikke-faglige oppslagsverk, for fagleksika brukes YRG',
            related: [],
            children: [],
          },
          {
            id: 'YRG',
            label: 'Emnespesifikke oppslagsverk',
            originalLabel: 'Barn og ungdom: emnespesifikke oppslagsverk',
            alternativeLabels: [],
            note: 'Her: emnespesifikke fagordbøker eller -ordlister. Brukes med andre Y*-koder som viser emnet, YP*-koder for læremidler',
            related: [],
            children: [],
          },
          {
            id: 'YRW',
            label: 'Atlas og kart',
            originalLabel: 'Barn og ungdom: oppslagsverk: atlas og kart',
            alternativeLabels: [],
            note: 'Brukes for kartbøker og atlas som tar for seg ulike temaer for barn. Bruk med andre Y*-koder og kvalifikatorer for geografi der det er hensiktsmessig',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'YX',
        label: 'Personlige og sosiale spørsmål',
        originalLabel: 'Barn og ungdom: personlige og sosiale spørsmål',
        alternativeLabels: [],
        note: 'Bruk YX* sammen med passende kode fra YF* for skjønnlitteratur, eller med andre koder fra Y* for å angi hvilke personlige eller sosiale spørsmål det er snakk om. NB!: YX* kan kombineres med J* eller VF* der det ikke fins en mer spesifikk Y*-kode. Dersom de kombineres, MÅ en Y*-kode bli angitt som den viktigste koden og kombineres med en kvalifikator fra 5A*',
        related: [],
        children: [
          {
            id: 'YXA',
            label: 'Kropp og helse',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: kropp og helse',
            alternativeLabels: [],
            note: 'Her: råd om god søvn, børste tennene, gå til tannlegen, ta vare på kroppen sin, personlig hygiene. Brukes med YXL*-koder for mental helse i tilknytning til kroppen',
            related: [],
            children: [
              {
                id: 'YXAB',
                label: 'Trening og ernæring',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: trening og ernæring',
                alternativeLabels: [],
                note: 'Her: personlig helse og hygiene, tannpleie, ta vare på kroppen, være aktiv, mat og helse, ernæring, slanking, dietter, vektproblemer og andre vektspørsmål, veganisme, vegetarianisme, trim og treningsrutiner',
                related: [],
                children: [],
              },
              {
                id: 'YXAD',
                label: 'Yoga, meditasjon, mindfullness',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: yoga, meditasjon, mindfullness',
                alternativeLabels: [],
                note: 'Her: bøker om yoga og praktiske teknikker for mental og fysisk helse',
                related: [],
                children: [],
              },
              {
                id: 'YXAM',
                label: 'Hår og hårpleie',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: hår og hårpleie',
                alternativeLabels: [
                  'alopecia',
                  'frisyrer',
                  'hårfrisyrer',
                  'fletter',
                  'fletting',
                ],
                note: 'Her: bøker om å ta vare på håret sitt, hårtap, frisyrer, fletter etc.',
                related: [],
                children: [],
              },
              {
                id: 'YXAX',
                label: 'Seksualitet',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: seksualitet',
                alternativeLabels: ['menstruasjon', 'mensen', 'prevensjon'],
                note: 'Brukes for bøker som tar for seg ulike aspekter ved sex, seksualitet og forplantning i tilknytning til pubertet og forandringer av kroppen, men også i tilknytning til andre aldre hos barn og ungdom; samtykke, seksuelle forhold, fysisk intimitet, seksuell helse, seksuell tiltrekning, grensesetting, prevensjonsmidler, graviditet etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YXB',
            label: 'LHBT+',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: LHBT+',
            alternativeLabels: [
              'lesbiske',
              'homofile',
              'bifile',
              'transseksuelle',
              'homofili',
              'lhbtiq',
              'LGBTQ',
              'LHBT',
              'LHBTI',
              'LHBTIQ',
              'LHBTI+',
              'LHBTIQ+',
            ],
            note: 'Brukes med kvalifikator(er) 5PSG, 5PSL, 5PT der det er hensiktsmessig eller YF*-koder for fortellinger om disse emnene',
            related: [],
            children: [
              {
                id: 'YXBD',
                label: 'LHBT: Komme ut av skapet',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: LHBT+: komme ut av skapet',
                alternativeLabels: [
                  'lesbiske',
                  'homofile',
                  'bifile',
                  'transseksuelle',
                  'homofili',
                  'lhbtiq',
                  'LGBTQ',
                  'LHBT',
                  'LHBTI',
                  'LHBTIQ',
                  'LHBTI+',
                  'LHBTIQ+',
                ],
                note: 'Brukes for bøker som handler om eller gir råd til ungdom om prosessen for en LHBT+-person for å bli godtatt, personlig aksept, det å avsløre sin seksuelle orientering eller identitet for andre. Brukes med andre emnekoder og kvalifikatorer fra 5P* der det er hensiktsmessig',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YXC',
            label: 'Kjønnsidentitet',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: kjønnsidentitet',
            alternativeLabels: [],
            note: 'Brukes for titler som tar for seg kjønn og spørsmål rundt dette for ungdom, f.eks. hva det betyr å være ei jente eller en gutt, eller spørsmål om identitet. Brukes med andre koder for å gi mer hensiktsmessig der det er hensiktsmessig. Se også YNMF <a href="/thema/nb/YNMF">Barn og ungdom: jenter og kvinner</a>, YNMH <a href="/thema/nb/YNMH">Barn og ungdom: gutter og menn</a>',
            related: [
              {
                id: 'YNMF',
                label: 'Jenter og kvinner',
                originalLabel: 'Barn og ungdom: jenter og kvinner',
                note: 'Brukes for bøker hvor det å være jente eller kvinne er et sentralt tema. Brukes med YNB for biografier, YNH for historie, og andre Y*-koder der det er hensiktsmessig',
              },
              {
                id: 'YNMH',
                label: 'Gutter og menn',
                originalLabel: 'Barn og ungdom: gutter og menn',
                note: 'Brukes for bøker hvor det å være gutt eller mann er et sentralt tema. Brukes med YNB for biografier, YNH for historie, og andre Y*-koder der det er hensiktsmessig',
              },
            ],
            children: [],
          },
          {
            id: 'YXD',
            label: 'Selvbevissthet og selvfølelse',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: selvbevissthet og selvfølelse',
            alternativeLabels: [
              'selvtillit,egenverd',
              'egenverdi',
              'positivt selvbilde',
              'identitet',
            ],
            note: 'Her: bøker som handler om eller gir råd om det å finne seg selv, selvinnsikt, selvbilde, egenverdi',
            related: [],
            children: [],
          },
          {
            id: 'YXE',
            label: 'Følelser',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: følelser og atferd',
            alternativeLabels: ['oppførsel'],
            note: 'Bruk YXE* for bøker som handler om flere eller forskjellige følelser, hvordan man kan gjenkjenne, takle, forstå og uttrykke disse følelsene',
            related: [],
            children: [
              {
                id: 'YXEB',
                label: 'Sinne, frustrasjon',
                originalLabel: 'Barn og ungdom: følelser: sinne, frustrasjon',
                alternativeLabels: [],
                note: 'Her: det å takle sinne, fiendtlighet, sjalusi, egoisme, dårlig humør, avsky, raserianfall',
                related: [],
                children: [],
              },
              {
                id: 'YXED',
                label: 'Tristhet, ulykkelighet, skuffelse',
                originalLabel:
                  'Barn og ungdom: følelser: tristhet, ulykkelighet, skuffelse',
                alternativeLabels: [],
                note: 'Her: dysterhet, følelsesmessig smerte eller lidelse',
                related: [],
                children: [],
              },
              {
                id: 'YXEF',
                label: 'Bekymring, frykt',
                originalLabel: 'Barn og ungdom: følelser: bekymring, frykt',
                alternativeLabels: ['usikkerhet'],
                note: 'Her: takle å være bekymret, usikker, stresset, redd, skam, flau',
                related: [],
                children: [],
              },
              {
                id: 'YXEH',
                label: 'Ensomhet, isolasjon',
                originalLabel: 'Barn og ungdom: følelser: ensomhet, isolasjon',
                alternativeLabels: [],
                note: 'Her: føle seg som en outsider, føle seg alene, ensom eller fremmedgjort',
                related: [],
                children: [],
              },
              {
                id: 'YXEJ',
                label: 'Medfølelse, empati, vennlighet',
                originalLabel:
                  'Barn og ungdom: følelser: medfølelse, empati, vennlighet',
                alternativeLabels: [],
                note: 'Her: godta forskjeller, solidaritet eller empati med andre, toleranse, forståelse',
                related: [],
                children: [],
              },
              {
                id: 'YXEL',
                label: 'Mot, håp',
                originalLabel: 'Barn og ungdom: følelser: mot, håp',
                alternativeLabels: [],
                note: 'Her: heltemot, utholdenhet, lojalitet, ærlighet, tillit',
                related: [],
                children: [],
              },
              {
                id: 'YXEN',
                label: 'Glede',
                originalLabel: 'Barn og ungdom: følelser: glede',
                alternativeLabels: [],
                note: 'Her: munterhet, tilfredshet, begeistring',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YXF',
            label: 'Familier',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: familier',
            alternativeLabels: [
              'stefamilie',
              'onkel',
              'tante',
              'datter',
              'sønn',
              'barn',
              'barnebarn',
              'tanter',
              'onkler',
              'søskenbarn',
              'barnebarn',
            ],
            note: 'Her: familiemedlemmer, besteforeldre, slektninger. Brukes for bøker om familier, spesifikke familiemedlemmer etc.',
            related: [],
            children: [
              {
                id: 'YXFB',
                label: 'Foreldre',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: foreldre',
                alternativeLabels: [
                  'alenemor',
                  'stemor',
                  'stefar',
                  'mor',
                  'far',
                  'mamma',
                  'pappa',
                  'alenefar',
                ],
                note: 'Her: mødre, fedre, enslige foreldre, steforeldre',
                related: [],
                children: [],
              },
              {
                id: 'YXFD',
                label: 'Skilsmisse, separasjon, familier i oppbrudd',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: skilsmisse, separasjon, familier i oppbrudd',
                alternativeLabels: [],

                related: [],
                children: [],
              },
              {
                id: 'YXFF',
                label: 'Adopsjon, fosterhjem',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: adopsjon, fosterhjem',
                alternativeLabels: ['adoptivbarn', 'fosterbarn'],
                note: 'Her: foreldreløse',
                related: [],
                children: [],
              },
              {
                id: 'YXFR',
                label: 'Søsken',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: søsken',
                alternativeLabels: [
                  'stesøsken',
                  'bonussøsken',
                  'halvsøsken',
                  'halvbror',
                  'halvsøster',
                ],
                note: 'Her: brødre, søstre',
                related: [],
                children: [],
              },
              {
                id: 'YXFS',
                label: 'Ny baby',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: ny baby',
                alternativeLabels: [],
                note: 'Her: det å få søsken, bøker om babyer',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YXG',
            label: 'Død og sorg',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: død og sorg',
            alternativeLabels: [],
            note: 'Her: tap, det å dø og bøker som forklarer hvordan og hvorfor mennesker dør, livets slutt etc.',
            related: [],
            children: [
              {
                id: 'YXGS',
                label: 'Selvmord',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: selvmord',
                alternativeLabels: ['selvmordstanker'],
                note: 'Her: det å få hjelp eller takle selvmordstanker, hvordan forebygge selvmord, råd for hvordan man kan snakke om selvmord',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YXH',
            label: 'Relasjoner (ikke familie)',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: relasjoner (ikke familie)',
            alternativeLabels: ['ensomhet', 'outsidere', 'klikker', 'gjenger'],
            note: 'Brukes for relasjoner mellom sosiale grupperinger blant jevnaldrende',
            related: [],
            children: [
              {
                id: 'YXHB',
                label: 'Venner og vennskap',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: venner og vennskap',
                alternativeLabels: [],
                note: 'Her: å få nye venner, vennegrupper, temaer rundt vennskap som konformitet, popularitet eller isolasjon innen ei gruppe av venner, vennskap som opphører, råd om det å få nye venner etc. Se også YFM <a href="/thema/nb/YFM">Skjønnlitteratur for barn og ungdom: relasjoner og forhold</a>',
                related: [
                  {
                    id: 'YFM',
                    label: 'Forelskelse, kjærlighet eller vennskap',
                    originalLabel:
                      'Skjønnlitteratur for barn og ungdom: relasjoner og forhold',
                    note: 'Brukes for historier om alle typer forhold. Brukes med YX* for å angi hvilken type forhold der det er hensiktsmessig, f.eks. YXHB for fortellinger om vennskap, eller YXHL for fortellinger om kjærlighet, eller YXH for fortellinger om relasjoner i grupper av jevnaldrende. Se også YXHB <a href="/thema/nb/YXHB">Barn og ungdom: personlige og sosiale spørsmål: venner og vennskap</a>',
                  },
                ],
                children: [],
              },
              {
                id: 'YXHL',
                label: 'Stevnemøter, forhold, forelskelse og kjærlighet',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: stevnemøter, forhold, forelskelse og kjærlighet',
                alternativeLabels: [
                  'dating',
                  'romantikk',
                  'parforhold',
                  'intimitet',
                ],
                note: 'Her: det å være et par, råd om å være i et forhold, det første stevnemøtet, den første kjærligheten, grensesetting, samtykke og intimitet i et romantisk forhold',
                related: [],
                children: [],
              },
              {
                id: 'YXHP',
                label: 'Livsmestring og livsvalg',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: livsmestring og livsvalg',
                alternativeLabels: [],
                note: 'Her: manerer, oppførsel, dilemmaer, konsekvenser, ansvar, ferdigheter når det gjelder moderne livsførsel, interaksjon med andre personer, det å følge eller ikke følge regler, få problemer, ta ansvar for sine handlinger, ikke bruke vold etc.',
                related: [],
                children: [],
              },
              {
                id: 'YXHY',
                label: 'Tenåringsgraviditet',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: tenåringsgraviditet',
                alternativeLabels: ['sex'],
                note: 'Her: tenåringsforeldre. Brukes for bøker ment for ungdom og som handler om graviditet, fødsel, det å være unge foreldre',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YXJ',
            label: 'Narkotika og avhengighet',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: narkotika og avhengighet',
            alternativeLabels: ['rusmidler', 'rusmisbruk', 'narkotikamisbruk'],
            note: 'Her: rusavhengighet, alkoholmisbruk etc.',
            related: [],
            children: [],
          },
          {
            id: 'YXK',
            label: 'Funksjonsnedsettelser og spesielle behov',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: funksjonsnedsettelser og spesielle behov',
            alternativeLabels: [],
            note: 'Her: fysiske funksjonsnedsettelser eller usynlige funksjonsnedsettelser eller tilstander, rullestolbrukere etc. Brukes med kvalifikatorer fra 5PM* der det er hensiktsmessig',
            related: [],
            children: [],
          },
          {
            id: 'YXL',
            label: 'Å leve med fysiske og psykiske sykdommer og diagnoser',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: å leve med fysiske og psykiske sykdommer og diagnoser',
            alternativeLabels: [],
            note: 'Bruk YXL*-koder for bøker som er ment for ungdom som selv lever med sykdom eller ungdom som må takle at noen de kjenner lever med sykdom. Bruk alle YXL*-koder med kvalifikatorer fra 5PM* der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'YXLB',
                label: 'Sykdommer og diagnoser',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: sykdommer og diagnoser',
                alternativeLabels: ['kronisk sykdom'],
                note: 'Her: det å leve med fysisk sykdom eller tilstander, også det å være langtidssyk, skader, førstehjelp, medisin, eller bøker om spesielle sykdommer eller tilstander. Brukes med YNH for medisinens historie, pest og sykdom i tidligere tider, pandemier, epidemier etc.',
                related: [],
                children: [
                  {
                    id: 'YXLB1',
                    label: 'Kreft',
                    originalLabel:
                      'Barn og ungdom: personlige og sosiale spørsmål: kreft',
                    alternativeLabels: [],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'YXLD',
                label: 'Mental helse',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: mental helse',
                alternativeLabels: [],
                note: 'Her: psykiske lidelser',
                related: [],
                children: [
                  {
                    id: 'YXLD1',
                    label: 'Spiseforstyrrelser',
                    originalLabel:
                      'Barn og ungdom: personlige og sosiale spørsmål: spiseforstyrrelser og kroppsbilde',
                    alternativeLabels: [],
                    note: 'Her: anoreksi, bulimi, overspising, dysmorfofobi',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YXLD2',
                    label: 'Angst, depresjoner, selvskading',
                    originalLabel:
                      'Barn og ungdom: personlige og sosiale spørsmål: angst, depresjoner, selvskading',
                    alternativeLabels: [],
                    note: 'Her: panikkanfall, stress',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'YXLD6',
                    label: 'God mental helse',
                    originalLabel:
                      'Barn og ungdom: personlige og sosiale spørsmål: god mental helse',
                    alternativeLabels: ['selvbilde', 'mindfullness'],
                    note: 'Her: positivt selvbilde, velvære. Brukes for bøker som gir råd om god mental helse og velvære',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'YXM',
            label: 'Multikulturalisme',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: multikulturalisme',
            alternativeLabels: [],
            note: 'Brukes for bøker som tar for seg emner i tilknytning til det flerkulturelle og flerkulturelle samfunn',
            related: [],
            children: [],
          },
          {
            id: 'YXN',
            label: 'Rasisme',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: rasisme og antirasisme',
            alternativeLabels: ['fordommer', 'anti-rasisme'],
            note: 'Her: institusjonell rasisme, xenofobi. Brukes for bøker om diskriminering og fordommer basert på hudfarge eller etnisk opphav, eller fordommer som går på at noen er fra et annet land eller utenfor din egen krets, samt tiltak som gjøres for å adressere rasisme. Brukes med YNH for historie. Se også JBFA1 <a href="/thema/nb/JBFA1">Rasisme og rasediskriminering / antirasisme</a>',
            related: [
              {
                id: 'JBFA1',
                label: 'Rasisme og rasediskriminering / antirasisme',
                originalLabel: 'Rasisme og rasediskriminering / antirasisme',
                note: 'Her: institusjonell rasisme, fremmedfrykt, raseskille. Brukes for bøker om diskriminering og fordommer basert på hudfarge eller etnisk opphav, eller fordommer som går på at noen er fra et annet land eller utenfor din egen krets, samt tiltak som gjøres for å adressere rasisme',
              },
            ],
            children: [],
          },
          {
            id: 'YXP',
            label: 'Mangfold / inkludering',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: mangfold, likestilling, inkludering',
            alternativeLabels: ['toleranse', 'intoleranse'],
            note: 'Brukes for bøker som tar for seg disse emnene og som har et sett med ulike hovedpersoner eller som illustrerer sosial rettferdighet, likestilling, mangfold eller inkludering uten at dette nødvendigvis er fortellingens hovedtema. Brukes med YF*-koder og kvalifikatorer fra 5P* der det er hensiktsmessig',
            related: [],
            children: [
              {
                id: 'YXPB',
                label: 'Fordommer og intoleranse',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: fordommer og intoleranse',
                alternativeLabels: [],
                note: 'Her: diskriminering, urettferdighet. Brukes med YNH for historie',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YXQ',
            label: 'Mobbing, vold, overgrep og gruppepress',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: mobbing, vold, overgrep og gruppepress',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'YXQD',
                label: 'Misbruk / mishandling',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: misbruk / mishandling',
                alternativeLabels: [],
                note: 'Her: fysisk, mental, følelsesmessig eller seksuell mishandling, tvang, mishandling i hjemmet',
                related: [],
                children: [],
              },
              {
                id: 'YXQF',
                label: 'Mobbing og trakassering',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: mobbing og trakassering',
                alternativeLabels: [],
                note: 'Her: nettmobbing, trolling',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YXR',
            label: 'Personlig sikkerhet',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: personlig sikkerhet',
            alternativeLabels: [],
            note: 'Her: forholde seg til fremmede, trafikksikkerhet, sikkerhet i hjemmet etc. Brukes med YXZE for titler om personlig sikkerhet i forbindelse med ulykker',
            related: [],
            children: [],
          },
          {
            id: 'YXS',
            label: 'Rømlinger',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: rømlinger',
            alternativeLabels: [],
            note: 'Her: flyktninger, personer på flukt. Brukes for bøker som handler om barn og ungdom som rømmer og hvorfor de gjør det, det ø rømme hjemmefra, fra familien eller skolen, eller flukt fra undertrykking, slaveri, konflikt etc. Brukes med YF* for fortellinger om rømlinger, med YNB for biografier eller YNH for historie. Se også YXZM <a href="/thema/nb/YXZM">Barn og ungdom: personlige og sosiale spørsmål: migrasjon og flyktninger</a>',
            related: [
              {
                id: 'YXZM',
                label: 'Migrasjon og flyktninger',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: migrasjon og flyktninger',
                note: 'Her: innvandring og innvandrere, utvandring og emigranter, asylsøkere, eksil, tvangsforflytning, diasporaer. Brukes med YNH for historie, YNB for biografier, YF* for skjønnlitteratur, og 5P* der det er hensiktsmessig',
              },
            ],
            children: [],
          },
          {
            id: 'YXT',
            label: 'Skolen, utdanning, lærere',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: skolen, utdanning og lærere',
            alternativeLabels: [],
            note: 'Brukes for bøker som handler om å starte på skolen, gå på skolen, bytte skole, lærere, pedagoger, skolebibliotekarer',
            related: [],
            children: [
              {
                id: 'YXTB',
                label: 'Skulking og skoleproblemer',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: skulking og skoleproblemer',
                alternativeLabels: [],
                note: 'Brukes for bøker som handler om fravær etc.',
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'YXV',
            label: 'Studieveiledning, yrkesråd',
            originalLabel:
              'Ungdom: personlige og sosiale spørsmål: studieveiledning, yrkesråd',
            alternativeLabels: [],
            note: 'Brukes for titler om arbeid, jobber, karriere, yrker ment for ungdom, men ikke pedagogiske titler. Se også YPJV2 <a href="/thema/nb/YPJV2">Læremidler: bedriftsøkonomi</a>, YPWC9 <a href="/thema/nb/YPWC9">Læremidler: utdanningsvalg</a>',
            related: [
              {
                id: 'YPJV2',
                label: 'Læremidler: bedriftsøkonomi',
                originalLabel: 'Læremidler: bedriftsøkonomi',
                note: 'Her: arbeidsliv. Brukes for undervisningsmateriale som dekker aspekter av bedriftsøkonomi og -ledelse',
              },
              {
                id: 'YPWC9',
                label: 'Læremidler: utdanningsvalg',
                originalLabel: 'Læremidler: utdanningsvalg',
                note: 'Brukes for læremidler som tar for seg arbeidslivet, hvilke kvalifikasjoner man trenger',
              },
            ],
            children: [],
          },
          {
            id: 'YXW',
            label: 'Oppvekst',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: oppvekst',
            alternativeLabels: ['coming of age'],
            note: 'Her: det å bli voksen, dannelse. Brukes for bøker om de forandringene som skjer mens man vokser opp, som f.eks. å få mer ansvar, velge et yrke, bygge karakter, lære seg nye ferdigheter, nye opplevelser og utfordringer, å bli uavhengig, selvstendighet, flytte hjemmefra, ta utdanning, første kjærlighet etc. Brukes med andre koder fra Y* eller kvalifikatorer, som YF* for skjønnlitteratur om å bli voksen, YXA* for bøker om hvordan kroppen forandrer seg, YXHL for den første kjærligheten etc.',
            related: [],
            children: [],
          },
          {
            id: 'YXZ',
            label: 'Sosiale forhold',
            originalLabel:
              'Barn og ungdom: personlige og sosiale spørsmål: sosiale forhold',
            alternativeLabels: [],
            note: 'Her: sosial aktivisme, sosial rettferdighet. Brukes for bøker som handler om eller forklarer spørsmål rundt sosiale forhold. Brukes med andre koder fra YX*, YF*-koder for skjønnlitterære fortellinger om disse emnene. NB!:YXZ kan kombineres med JBF*, men dersom de kombineres, MÅ en Y*-kode angis som den viktigste koden, og kombineres med en kvalifikator fra 5A*',
            related: [],
            children: [
              {
                id: 'YXZB',
                label: 'Aktivisme / aktivister',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: aktivisme / aktivister',
                alternativeLabels: [],
                note: 'Brukes for bøker om sosial aktivisme, miljøaktivisme, aktivisme i forbindelse med menneskerettigheter og borgerrettigheter, frivillig arbeid og andre områder. Brukes med andre koder fra YN* eller YX*, og med YNB for biografier',
                related: [],
                children: [],
              },
              {
                id: 'YXZC',
                label: 'Identitet / tilhørighet',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: identitet / tilhørighet',
                alternativeLabels: ['patriotisme', 'nasjonalisme'],
                note: 'Brukes for bøker som handler om identitet og tilhørighet, både som individer og som del av ei gruppe eller samfunn, identitet som knytter seg til et bestemt sted, en bestemt kultur eller kulturarv',
                related: [],
                children: [],
              },
              {
                id: 'YXZD',
                label: 'Fred / forsoning',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: fred / forsoning',
                alternativeLabels: [],
                note: 'Brukes for bøker som handler fred som etterfølger en konflikt, det å bevare freden, fredsarbeid, konfliktløsning, forsoning mellom ulike grupper eller personer',
                related: [],
                children: [],
              },
              {
                id: 'YXZE',
                label: 'Ulykker / katastrofer / nødsituasjoner',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: ulykker / katastrofer /nødsituasjoner',
                alternativeLabels: ['flyktninger', 'migrasjon'],
                note: 'Brukes for bøker som handler om konsekvensene på både mennesker og samfunn av natur- og menneskeskapte katastrofer og ulykker. F.eks. pest, sykdom, epidemier, pandemier, sult, underernæring, vulkanutbrudd, jordskjelv, ekstremvær, flom, mennesker som tvinges på flukt, eller ulykker som brann, kollisjoner, forlis, trafikkulykker, eksplosjoner, oljeutslipp, det å bli fanget eller forsvinne etc., eller for bøker som handler om å være forberedt på ulykker og nødsituasjoner, f.eks. hva du skal gjøre dersom du opplever et jordskjelv eller en tsunami',
                related: [],
                children: [],
              },
              {
                id: 'YXZG',
                label: 'Miljøspørsmål',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: miljøspørsmål',
                alternativeLabels: [],
                note: 'Her: bærekraft, klimaendringer, klimakrise, klimastreiker, forurensning, resirkulering, bærekraft, miljøvern, naturvern, vannforbruk, forvaltning av naturressurser, reduksjon av forbruk. Brukes for bøker som handler om disse emnene og for bøker som har forslag til løsninger, eller hva ungdom kan gjøre selv',
                related: [],
                children: [],
              },
              {
                id: 'YXZH',
                label: 'Fattigdom',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: fattigdom',
                alternativeLabels: [],
                note: 'Brukes for bøker som handler om sosial ustabilitet, hjemløshet, sult, det å mangle ting etc.',
                related: [],
                children: [],
              },
              {
                id: 'YXZM',
                label: 'Migrasjon og flyktninger',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: migrasjon og flyktninger',
                alternativeLabels: [],
                note: 'Her: innvandring og innvandrere, utvandring og emigranter, asylsøkere, eksil, tvangsforflytning, diasporaer. Brukes med YNH for historie, YNB for biografier, YF* for skjønnlitteratur, og 5P* der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'YXZR',
                label: 'Religion',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: religion',
                alternativeLabels: ['religioner'],
                note: 'Brukes for bøker som tar for seg ulike aspekter ved religion, tro, religiøs identitet etc. Brukes med YNR* eller andre koder fra Y*, og kvalifikatorer fra 5PG* der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'YXZW',
                label: 'Krig og konflikter',
                originalLabel:
                  'Barn og ungdom: personlige og sosiale spørsmål: krig og konflikter',
                alternativeLabels: [],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'YZ',
        label: 'Papirvarer og annet materiell',
        originalLabel: 'Barn og ungdom: papirvarer og annet materiell',
        alternativeLabels: [],
        note: 'Bruk YZ*-koder for varer, produkter og materiell som ikke er bøker og som ikke kan klassifiseres under andre Y*-koder. Brukes med andre emnekoder der det er hensiktsmessig, men YZ* bør angis som den første koden. Detaljer om format angis i andre metadatafelt',
        related: [],
        children: [
          {
            id: 'YZG',
            label: 'Gavebøker',
            originalLabel: 'Barn: gavebøker',
            alternativeLabels: [],
            note: 'Her: små bøker, vanligvis humoristiske, inspirerende og dekorative bøker laget for å bli gitt bort som gaver til barn. Skal IKKE brukes for markedsføringsformål, f.eks. for å si at boka passer som gave',
            related: [],
            children: [],
          },
          {
            id: 'YZS',
            label: 'Papirvarer',
            originalLabel: 'Barn: papirvarer',
            alternativeLabels: [],
            note: 'Her: alle trykte papirvarer, dagbøker, kalendere, plakater, «kits», postkortpakker , bokmerker, etc. ment for barn. Tildel også en annen kode som indikerer emne der det er mulig. Se også WZS <a href="/thema/nb/WZS">Kontormateriell, papirvarer</a>',
            related: [
              {
                id: 'WZS',
                label: 'Kontormateriell, papirvarer',
                originalLabel: 'Kontormateriell, papirvarer',
                note: 'Her: varer som dagbøker, kalendere, plakater, kits, postkort, pakker, bokmerker, etc. Tildel også en kode som indikerer emnet der det er mulig',
              },
            ],
            children: [
              {
                id: 'YZSG',
                label: 'Dagbøker og notatbøker',
                originalLabel: 'Barn og ungdom: dagbøker og notatbøker',
                alternativeLabels: [],
                note: 'Brukes for dagbøker og notatbøker som har noe tekstlig innhold eller illustrasjoner ment som inspirasjon. Brukes med andre koder for å angi et emne og med kvalifikatorer fra 5A* der det er hensiktsmessig',
                related: [],
                children: [],
              },
              {
                id: 'YZSN',
                label: 'Blanke papirvarer',
                originalLabel: 'Barn: blanke papirvarer',
                alternativeLabels: [],
                note: 'Her: tomme treningsbøker, autografbøker og andre innholdsfrie produkter for barn. Se også WZSN <a href="/thema/nb/WZSN">Blanke papirvarer</a>',
                related: [
                  {
                    id: 'WZSN',
                    label: 'Blanke papirvarer',
                    originalLabel: 'Blanke papirvarer',
                    note: 'Her: tomme notatbøker, autografbøker og andre innholdsfrie produkter',
                  },
                ],
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
];
