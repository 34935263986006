import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import {styled} from '@mui/material/styles';
import {TaskType} from 'api/types';
import {useLocalization} from 'localization';
import {
  CheckRoundBackgroundIcon,
  Layout,
  RoundBackgroundIcon,
  Spacer,
  Text,
} from 'components';

export type TaskStatusProps = {
  task: TaskType;
  checked: boolean;
  count: number;
  toggle: () => void;
  loading: boolean;
};

const unit = 16;
const maxWidth = (20.5 - 2 * 3) * unit;

const StatusButton = styled(ButtonBase)(() => ({
  // TODO: Use theme colors ({ theme })
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  background: 'white',
  borderRadius: 1.5 * unit,
  paddingLeft: 0.5 * unit,
  paddingRight: 1.5 * unit,
  paddingTop: 0.75 * unit,
  paddingBottom: 0.75 * unit,
  maxWidth,
  height: 3 * unit,
  marginLeft: unit,
  '&:hover, &.Mui-focusVisible': {
    opacity: 0.8,
    outline: '1px solid currentColor',
  },
  '&:active, &.Mui-focusVisible': {
    opacity: 0.6,
    outline: '2px solid currentColor',
  },
}));

export const TaskStatus: React.FC<TaskStatusProps> = ({
  task,
  checked,
  count,
  toggle,
  loading,
}) => {
  const {tLoose} = useLocalization();
  const title = tLoose(`page.overview.task.type.plural.${task}.title`);
  return (
    <StatusButton onClick={toggle} disableRipple>
      <Layout
        horizontal
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          flex: 1,
        }}>
        <Layout horizontal adjustCenter>
          {checked ? (
            <CheckRoundBackgroundIcon fillColor={task} fontSize="large" />
          ) : (
            <RoundBackgroundIcon fillColor={task} fontSize="large" />
          )}

          <Spacer size={0.5} />
          <Text variant="body2">{title}</Text>
        </Layout>
        <Text variant="h2">{loading ? '-' : count}</Text>
      </Layout>
    </StatusButton>
  );
};
