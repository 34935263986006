import {Rect, Spacing} from '../types';

export const getItemSpacing = (
  containerRect: Rect | null,
  itemRects: Rect[],
): Partial<Spacing> => {
  const firstItemRect = itemRects[0];
  if (!firstItemRect || !containerRect) {
    return {};
  }

  const marginX = firstItemRect.left - containerRect.left;
  const marginY = firstItemRect.top - containerRect.top;

  const firstItemSecondRow = itemRects.find(r => r.top > firstItemRect.top);
  const gutterY = firstItemSecondRow
    ? firstItemSecondRow.top - (firstItemRect.top + firstItemRect.height)
    : undefined;

  const secondItemAnyRowIdx = itemRects.findIndex(
    r => r.left > firstItemRect.left,
  );
  const firstItemAnyRow =
    secondItemAnyRowIdx > 0 ? itemRects[secondItemAnyRowIdx - 1] : undefined;
  const secondItemAnyRow =
    secondItemAnyRowIdx > 0 ? itemRects[secondItemAnyRowIdx] : undefined;
  const gutterX =
    firstItemAnyRow && secondItemAnyRow
      ? secondItemAnyRow.left - (firstItemAnyRow.left + firstItemAnyRow.width)
      : undefined;

  return {
    marginX,
    marginY,
    gutterY,
    gutterX,
  };
};
