import {PartValueModifier} from 'schemaDefinition/types';
import {
  deweyNumberModifier,
  deweySourceModifier,
  intellectualLevelModifier,
  literatureTypeModifier,
} from 'schemas/valueModifiers';

export const interceptWorkSetFieldValue: PartValueModifier = parameters => {
  const {value, part} = parameters;

  if (part) {
    if (part.customizeKey === 'literatureType') {
      return literatureTypeModifier(parameters);
    }
    if (part.customizeKey === 'deweyNumber') {
      return deweyNumberModifier(parameters);
    }

    if (part.customizeKey === 'deweySource') {
      return deweySourceModifier(parameters);
    }

    if (part.customizeKey === 'intellectualLevel') {
      return intellectualLevelModifier(parameters);
    }
  }

  return {value};
};
