import {useCallback, useMemo, useState} from 'react';
import {useLocalization} from 'localization';
import {noOp} from 'services/utils';
import {DialogItems} from 'components/dialog/DialogItems';
import {DialogActionItem} from '../dialog/types';
import {WizardStepProps} from './types';
import {FlexBox, Layout} from '../layout';
import {Spacer} from '../spacer';
import {WizardHeader} from './components/WizardHeader';

type WizardProps = {
  title: string;
  initialStepId?: string;
  steps: WizardStepProps[];
  showSaveCancel?: boolean;
  onGotoStep?: (stepId: string) => void;
  onCancel: () => void;
  onSave: () => Promise<void> | void;
};

const getStepIndex = (
  stepId: string | undefined,
  steps: WizardStepProps[],
): number | undefined => {
  const index = stepId ? steps.findIndex(s => s.id === stepId) : -1;
  return index >= 0 ? index : undefined;
};

export const Wizard: React.FC<WizardProps> = ({
  title,
  initialStepId,
  steps: wizardSteps,
  showSaveCancel,
  onGotoStep,
  onCancel,
  onSave,
}) => {
  const {t} = useLocalization();
  const [activeStep, setActiveStep] = useState(
    getStepIndex(initialStepId, wizardSteps) ?? 0,
  );

  const handleStepSelected = useCallback(
    (stepIdx: number) => {
      onGotoStep?.(wizardSteps[stepIdx].id);
      setActiveStep(stepIdx);
    },
    [onGotoStep, wizardSteps],
  );

  const {actions, steps} = useMemo(() => {
    const cancelAction: DialogActionItem = {
      itemType: 'action',
      title: t('general.cancel'),
      background: 'transparent',
      hide: !showSaveCancel,
      onClick: onCancel ?? noOp,
    };
    const saveAction: DialogActionItem | undefined =
      activeStep === wizardSteps.length - 1
        ? {
            itemType: 'action',
            title: t('general.save'),
            background: 'green',
            hide: false,
            icon: 'Check',
            showSpinnerOnClick: true,
            onClick: onSave,
          }
        : undefined;
    const prevAction: DialogActionItem | undefined =
      activeStep > 0
        ? {
            itemType: 'action',
            title: wizardSteps[activeStep - 1].label,
            icon: 'Back',
            background: 'transparent',
            onClick: () => handleStepSelected(activeStep - 1),
          }
        : undefined;

    const nextAction: DialogActionItem | undefined =
      activeStep < wizardSteps.length - 1
        ? {
            itemType: 'action',
            title: wizardSteps[activeStep + 1].label,
            icon: 'ArrowRight',
            background: 'transparent',
            onClick: () => handleStepSelected(activeStep + 1),
          }
        : undefined;

    return {
      actions: [
        prevAction,
        wizardSteps[activeStep].extraitem,
        cancelAction,
        saveAction,
        nextAction,
      ].filter(a => !!a) as DialogActionItem[],
      steps: wizardSteps.map(({extraitem: _, ...stepProps}) => stepProps),
    };
  }, [
    activeStep,
    handleStepSelected,
    onCancel,
    onSave,
    showSaveCancel,
    t,
    wizardSteps,
  ]);

  return (
    <FlexBox>
      <DialogItems dialogItems={actions} />
      <WizardHeader
        title={title}
        showStepNumbers={false}
        activeStep={activeStep}
        onStepSelected={handleStepSelected}
        steps={steps}
      />
      <Spacer size={4} />
      <Layout>{steps[activeStep].content}</Layout>
    </FlexBox>
  );
};
