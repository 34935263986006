import {useCallback} from 'react';
import {NationalAgent, NationalAgentType} from 'api/types';
import {getAgentsByNationalId, searchAgentInNationalRegistry} from 'api';
import {useGetTokens} from 'services/auth';

export const useSearchAgentInNationalRegistry = (): ((
  agentType: NationalAgentType,
  name: string | undefined,
  other: string | undefined,
) => Promise<{hits: NationalAgent[]; inEbba: NationalAgent[]}>) => {
  const getTokens = useGetTokens();

  const handleSearch = useCallback(
    (
      agentType: NationalAgentType,
      name: string | undefined,
      other: string | undefined,
    ) => {
      return searchAgentInNationalRegistry(
        agentType,
        name ?? '',
        other ?? '',
        getTokens,
      ).then(hits => {
        const nationalIds = hits
          .map(hit => hit.nationalId)
          .filter(id => id) as string[];
        return getAgentsByNationalId(nationalIds, getTokens).then(
          ebbaAgents => {
            const hitsWithId = hits.map(hit => ({
              ...hit,
              id: ebbaAgents.find(
                ebbaAgent => ebbaAgent.nationalId === hit.nationalId,
              )?.id,
            }));
            return {
              hits: hitsWithId,
              inEbba: ebbaAgents as NationalAgent[],
            };
          },
        );
      });
    },
    [getTokens],
  );

  return handleSearch;
};
