import React from 'react';
import {ChangeRequestAction, PartRef} from 'schemaDefinition/types';
import {FlexBox} from 'components';
import {DataFormCompareActions} from './DataFormCompareActions';
import {ACTIONS_SIZE} from './DataFormCompareLayout';
import {Label} from './fields/Label';

export type CompareValueContainerProps = {
  part?: PartRef;
  /**
   * Value path to update
   */
  valuePath?: string;
  /**
   * Used for vertical offset action button for values
   * with label; offset corresponding to label height */
  actionOffset?: boolean;
  /** Default actions are: ['replace'] */
  actions?: ChangeRequestAction[];
  /** Render custom actions */
  renderActions?: () => React.ReactNode;
};

const DEFAULT_ACTIONS: ChangeRequestAction[] = ['replace'];

export type CompareValueContainerType = React.FC<
  React.PropsWithChildren<CompareValueContainerProps>
>;

type Props = CompareValueContainerProps & {
  /**
   * Set when empty value, will hide rendered value
   */
  emptyValue?: boolean;
};

/**
 * Two column layout for comparing data.
 * Handles column width of current and updated (proposed) values,
 * allocating extra space to updated value to allow for
 * action buttons, e.g. replace
 */
const BaseDataFormUpdatedValueContainer: React.FC<
  React.PropsWithChildren<Props>
> = ({
  valuePath,
  part,
  actions = DEFAULT_ACTIONS,
  actionOffset,
  renderActions,
  emptyValue,
  children,
}) => {
  return (
    <FlexBox position={'relative'} sx={{paddingRight: ACTIONS_SIZE}}>
      {emptyValue ? (
        <FlexBox visibility={'hidden'}>{children}</FlexBox>
      ) : (
        children
      )}
      <FlexBox
        position={'absolute'}
        center
        right
        sx={{
          right: 0,
          top: 0,
          bottom: 0,
          width: ACTIONS_SIZE,
          zIndex: 100,
        }}>
        {
          <>
            {/* Render empty label to get same offset as value fields */}
            {actionOffset ? (
              <FlexBox visibility={'hidden'}>
                <Label label="" htmlFor={''} />
              </FlexBox>
            ) : null}
            {
              /* Render custom actions */
              renderActions ? (
                <FlexBox minWidth={'50px'}>{renderActions()}</FlexBox>
              ) : valuePath ? (
                <DataFormCompareActions
                  valuePath={valuePath}
                  part={part}
                  actions={actions}
                />
              ) : null
            }
          </>
        }
      </FlexBox>
    </FlexBox>
  );
};

export type DataFormUpdatedValueContainerType = CompareValueContainerType;

// Update full value container
export const DataFormUpdatedFullValueContainer: DataFormUpdatedValueContainerType =
  props => {
    return <BaseDataFormUpdatedValueContainer actionOffset {...props} />;
  };

// Update item value container
export const DataFormUpdatedItemValueContainer: DataFormUpdatedValueContainerType =
  props => {
    return <BaseDataFormUpdatedValueContainer {...props} />;
  };

// Update empty item value container
export const DataFormUpdatedEmptyItemValueContainer: DataFormUpdatedValueContainerType =
  props => {
    return <BaseDataFormUpdatedValueContainer emptyValue {...props} />;
  };

// Update sub value container
export const DataFormUpdatedSubValueContainer: DataFormUpdatedValueContainerType =
  props => {
    return <BaseDataFormUpdatedValueContainer {...props} />;
  };

// Update empty sub value container
export const DataFormUpdatedEmptySubValueContainer: DataFormUpdatedValueContainerType =
  props => {
    return <BaseDataFormUpdatedValueContainer emptyValue {...props} />;
  };
