import React from 'react';
import {Tooltip} from '@mui/material';
import {Icon} from 'components';

export const HelpTooltip: React.FC = () => {
  return (
    <Tooltip
      title={
        <ul style={{listStyleType: 'none', margin: 0, padding: 4}}>
          <li>
            _ - Bytte ut et tegn
            <ul>
              <li>Ascheho_g & Co</li>
              <li>Asc_eho_g & Co</li>
            </ul>
          </li>
          <li>
            % - Bytte ut flere tegn
            <ul>
              <li>Asc% & Co</li>
              <li>Aschehou%</li>
            </ul>
          </li>
        </ul>
      }
      followCursor>
      <span
        style={{
          marginTop: 6,
          cursor: 'help',
        }}>
        <Icon icon={'InfoCircle'} />
      </span>
    </Tooltip>
  );
};
