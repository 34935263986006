import React, {useCallback, useMemo} from 'react';
import {assert} from 'assert-ts';
import {Concept, LinkTargetConcreteEntityType} from 'types';
import {
  VerifiedLinkedAgentLink,
  VerifiedLinkedCatalogPostLink,
  VerifiedLinkedValueLink,
} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {
  Builder,
  getEntityMainType,
  isAgentValue,
  useLoadErrorMessage,
} from 'services/utils';
import {isCollectionValue} from 'services/utils/functions/isCollectionValue';
import {
  ActivityIndicator,
  Card,
  IconButton,
  Layout,
  Spacer,
  Text,
} from 'components';
import {
  NOT_LOADED_ENTITY_STATE,
  SearchMainEntityType,
  SearchResultValue,
} from '../types';
import {EntityPreviewContent, EntityPreviewHeader} from './types';
import {useEntitySearchContext} from '../context';
import {getEntityTitle} from '../context/functions/getEntityTitle';
import {usePreviewLinkingConfiguration} from '../hooks';
import {useEntityDialogs} from '../hooks/useEntityDialogs';
import {AgentPreviewContent} from './AgentPreviewContent';
import {AgentPreviewHeader} from './AgentPreviewHeader';
import {CollectionPreviewHeader} from './CollectionPreviewHeader';
import {DefaultEntityPreviewContent} from './DefaultEntityPreviewContent';
import {ErrorMessage} from './ErrorMessage';
import {WorkPreviewContent} from './WorkPreviewContent';
import {WorkPreviewHeader} from './WorkPreviewHeader';

type Props = {
  entityType: LinkTargetConcreteEntityType;
  entityId?: string;
};

const entityPreviewHeaderMap: {
  [type in LinkTargetConcreteEntityType]: EntityPreviewHeader;
} = {
  [Concept.work]: WorkPreviewHeader,
  [Concept.manifestation]: WorkPreviewHeader,
  [Concept.person]: AgentPreviewHeader,
  [Concept.corporation]: AgentPreviewHeader,
  [Concept.event]: AgentPreviewHeader,
  [Concept.publisher]: AgentPreviewHeader,
  [Concept.series]: CollectionPreviewHeader,
  [Concept.educationalSeries]: CollectionPreviewHeader,
  [Concept.otherConnection]: CollectionPreviewHeader,
  [Concept.publisherSeries]: CollectionPreviewHeader,
};

const entityPreviewContentMap: {
  [type in LinkTargetConcreteEntityType]: EntityPreviewContent;
} = {
  [Concept.work]: WorkPreviewContent,
  [Concept.manifestation]: WorkPreviewContent,
  [Concept.person]: AgentPreviewContent,
  [Concept.corporation]: AgentPreviewContent,
  [Concept.event]: AgentPreviewContent,
  [Concept.publisher]: AgentPreviewContent,
  [Concept.series]: DefaultEntityPreviewContent,
  [Concept.educationalSeries]: DefaultEntityPreviewContent,
  [Concept.otherConnection]: DefaultEntityPreviewContent,
  [Concept.publisherSeries]: DefaultEntityPreviewContent,
};

export const EntityPreview: React.FC<Props> = ({entityType, entityId}) => {
  const {t} = useLocalization();
  const {
    currentSourceValue,
    currentSourceConfig,
    setSourceValue,
    state,
    currentIndex,
    totalCount,
    canGotoPrevEntity,
    canGotoNextEntity,
    gotoSearchResult,
    gotoNextEntity,
    gotoPrevEntity,
    navigateTo,
    siblingCount = 0,
    siblingIndex = 0,
    siblingLabel,
    canNavigateToPrevSibling,
    navigateToPrevSibling,
    canNavigateToNextSibling,
    navigateToNextSibling,
    navigateBack,
    prevEntityTitle,
  } = useEntitySearchContext();

  const handleOnSaveEntity = useCallback(
    (savedEntity: SearchResultValue) => {
      if (isAgentValue(savedEntity)) {
        // Update current source with any changes
        if (
          currentSourceValue?.linkStatus === 'verified' &&
          currentSourceValue?.entityId === savedEntity.id
        ) {
          const currentLinkValue =
            currentSourceValue as VerifiedLinkedAgentLink;
          const newLinkeValue: VerifiedLinkedAgentLink = {
            ...currentLinkValue,
            agentName:
              savedEntity.nameVariants.find(
                v => v.id === currentLinkValue.agentName.id,
              ) ?? currentLinkValue.agentName,
            nationalId: savedEntity.nationalId,
          };
          setSourceValue(newLinkeValue, 'link', 'keepOpen');
        }
      } else if (isCollectionValue(savedEntity)) {
        // Update current source with any changes
        if (
          currentSourceValue?.linkStatus === 'verified' &&
          currentSourceValue?.entityId === savedEntity.id
        ) {
          const newLinkeValue: VerifiedLinkedCatalogPostLink = {
            linkStatus: 'verified',
            type: savedEntity.collectionType,
            entityId: savedEntity.id,
            name: savedEntity.titles[0].value ?? '',
          };
          setSourceValue(newLinkeValue, 'link', 'keepOpen');
        }
      }
    },
    [currentSourceValue, setSourceValue],
  );

  const {entityState, EntityDialog, handleEdit} = useEntityDialogs(
    entityType,
    entityId,
    handleOnSaveEntity,
  );
  const {status, data, error} = entityState ?? NOT_LOADED_ENTITY_STATE;

  const handleCustomEdit = useCallback(
    (value: SearchResultValue) => {
      if (
        value.id &&
        currentSourceConfig?.canEditEntity &&
        currentSourceConfig.canEditEntity(entityType)
      ) {
        const customEdit = assert(
          currentSourceConfig?.handleEditEntity,
          'EntityPreview: handleEditEntity expected',
          {entityType},
        );
        return customEdit(entityType, value.id);
      }

      handleEdit && handleEdit(value);
    },
    [currentSourceConfig, entityType, handleEdit],
  );

  // Config preview according to current linked value
  const linkingConfig = usePreviewLinkingConfiguration(
    entityType,
    currentSourceValue,
    currentSourceConfig,
    entityState,
    setSourceValue,
  );

  const onNavigate = useCallback(
    (
      link: VerifiedLinkedValueLink,
      siblingIds?: (string | number)[],
      siblingLabel?: string,
    ) => {
      const backTitle = getEntityTitle(data, entityType, entityId);
      const backType = t(`general.entity.${entityType}`);
      const backLabel =
        new Builder(backType).add(': ', backTitle).value ?? t('general.back');
      navigateTo(link, backLabel, siblingIds, siblingLabel);
    },
    [data, entityId, entityType, navigateTo, t],
  );

  const previewConfig = useMemo(
    () => ({linking: linkingConfig, onNavigate, layout: {}}),
    [linkingConfig, onNavigate],
  );

  const errorCause = useLoadErrorMessage(status, error);

  const PreviewHeader = entityPreviewHeaderMap[entityType];
  const PreviewContent = entityPreviewContentMap[entityType];

  return (
    <Card colorx={ColorPalette.offWhite}>
      {state === 'browse' ? (
        <Card.NavigationHeader>
          <Layout horizontal flex={1} justifyContent={'space-between'}>
            <Layout horizontal adjustCenter>
              <IconButton icon="Back" onClick={gotoSearchResult} />
              <Text variant="subtitle1">{t('search.result.all')}</Text>
            </Layout>
            <Layout horizontal adjustCenter>
              <IconButton
                icon="ChevronLeft"
                disabled={!canGotoPrevEntity}
                onClick={gotoPrevEntity}
              />
              <Text variant="subtitle1">{`${t('search.result.at.item')} ${
                currentIndex + 1
              }/${totalCount}`}</Text>
              <IconButton
                icon="ChevronRight"
                disabled={!canGotoNextEntity}
                onClick={gotoNextEntity}
              />
            </Layout>
          </Layout>
        </Card.NavigationHeader>
      ) : state === 'navigate' ? (
        siblingLabel || siblingCount > 0 ? (
          <Card.NavigationHeader>
            <Layout horizontal flex={1} justifyContent={'space-between'}>
              <Layout horizontal adjustCenter>
                <IconButton icon="Back" onClick={navigateBack} />
                <Text variant="subtitle1">{prevEntityTitle}</Text>
              </Layout>
              <Layout horizontal adjustCenter>
                {siblingCount > 1 ? (
                  <>
                    <IconButton
                      icon="ChevronLeft"
                      disabled={!canNavigateToPrevSibling}
                      onClick={navigateToPrevSibling}
                    />
                    <Text variant="subtitle1">
                      {`${siblingLabel || t(`general.entity.${entityType}`)} ${
                        siblingIndex + 1
                      }/${siblingCount}`}
                    </Text>
                    <IconButton
                      icon="ChevronRight"
                      disabled={!canNavigateToNextSibling}
                      onClick={navigateToNextSibling}
                    />
                  </>
                ) : (
                  <>
                    <Text variant="subtitle1">
                      {siblingLabel || t(`general.entity.${entityType}`)}
                    </Text>
                    <Spacer width={2} />
                  </>
                )}
              </Layout>
            </Layout>
          </Card.NavigationHeader>
        ) : (
          <Card.NavigationHeader>
            <Layout horizontal flex={1} justifyContent={'flex-start'}>
              <Layout horizontal adjustCenter>
                <IconButton icon="Back" onClick={navigateBack} />
                <Text variant="subtitle1">{prevEntityTitle}</Text>
              </Layout>
            </Layout>
          </Card.NavigationHeader>
        )
      ) : null}
      {status === 'NotLoaded' || status === 'Loading' ? (
        <Layout adjustCenter>
          <ActivityIndicator />;
        </Layout>
      ) : status === 'Failed' ? (
        <ErrorMessage
          titleKey={`error.${
            getEntityMainType(entityType) as SearchMainEntityType
          }.failedToLoad`}
          errorCause={errorCause}
        />
      ) : null}
      {data?.id && status === 'Loaded' ? (
        <Card.Header>
          <PreviewHeader
            entityValue={assert(data, 'EntityPreview: data is not defined')}
            canLink={currentSourceConfig?.canLink}
            canMove={currentSourceConfig?.canMove}
            canCopy={currentSourceConfig?.canCopy}
            linkingConfiguration={linkingConfig}
            onEdit={handleCustomEdit}
          />
        </Card.Header>
      ) : null}
      {status === 'Loaded' ? (
        <Card.Content>
          <PreviewContent
            entityType={entityType}
            entityId={entityId}
            entityValue={assert(data, 'EntityPreview: data is not defined')}
            configuration={previewConfig}
          />
          {EntityDialog ? <EntityDialog /> : null}
        </Card.Content>
      ) : null}
    </Card>
  );
};
