import React from 'react';
import {useFontSizeScalePct} from 'services/settings/hooks';

type Props = {
  color?: string;
};

export const Logo: React.FC<Props> = ({color = '#480905'}) => {
  const scale = useFontSizeScalePct() / 100;
  return (
    <svg
      width={55 * scale}
      height={12 * scale}
      viewBox="0 0 55 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.35703 4.42549H4.53693V0H3.04346V11.8013H6.36005C6.83606 11.8042 7.36879 11.734 7.84046 11.5559C8.31214 11.3779 8.74241 11.1088 9.10568 10.7648C9.46896 10.4207 9.75782 10.0087 9.95509 9.55322C10.1524 9.09774 10.254 8.60809 10.254 8.11339C10.254 7.61869 10.1524 7.12904 9.95509 6.67356C9.75782 6.21807 9.46896 5.80605 9.10568 5.46201C8.74241 5.11796 8.31214 4.84891 7.84046 4.67084C7.36879 4.49278 6.83606 4.42549 6.36005 4.42549H6.35703Z"
        fill={color}
      />
      <path
        d="M1.49347 0.00292969H0V11.8042H1.49347V0.00292969Z"
        fill={color}
      />
      <path
        d="M20.4072 0H28.3371L28.1732 1.33344H21.9007V5.09267H27.1544V6.42611H21.9007V10.4747H28.3532V11.8081H20.4082L20.4072 0Z"
        fill={color}
      />
      <path
        d="M31.0555 10.4255L30.974 10.4412C30.9613 10.8982 30.9227 11.3543 30.8584 11.8071H29.5449V11.6792C29.6764 10.9646 29.7437 10.2402 29.7461 9.51419V0H31.142V4.62523L31.2234 4.65672C31.7162 3.98261 32.4061 3.29178 33.9489 3.29178C36.1986 3.29178 37.6267 4.96474 37.6267 7.6454C37.6267 10.3261 36.1946 12 33.9448 12C32.386 12 31.4829 11.2619 31.0555 10.4255ZM33.7316 10.763C35.2915 10.763 36.1785 9.62244 36.1785 7.64638C36.1785 5.67033 35.2915 4.52977 33.7316 4.52977C31.8761 4.52977 31.1379 5.81499 31.1379 7.64638C31.1379 9.47778 31.8761 10.764 33.7316 10.764V10.763Z"
        fill={color}
      />
      <path
        d="M40.3694 10.4255L40.288 10.4412C40.2753 10.8982 40.2367 11.3543 40.1723 11.8071H38.8589V11.6792C38.9904 10.9646 39.0577 10.2402 39.06 9.51419V0H40.4559V4.62523L40.5384 4.65672C41.0302 3.98261 41.7201 3.29178 43.2638 3.29178C45.5126 3.29178 46.9406 4.96474 46.9406 7.6454C46.9406 10.3261 45.5085 12 43.2598 12C41.702 12 40.7969 11.2619 40.3694 10.4255ZM43.0456 10.763C44.6054 10.763 45.4924 9.62244 45.4924 7.64638C45.4924 5.67033 44.6054 4.52977 43.0456 4.52977C41.1911 4.52977 40.4519 5.81499 40.4519 7.64638C40.4519 9.47778 41.1911 10.764 43.0456 10.764V10.763Z"
        fill={color}
      />
      <path
        d="M48.0189 9.75228C48.0189 7.80772 49.3163 7.24581 50.9746 6.86004L53.4044 6.2814C53.2887 5.15658 52.6159 4.5307 51.3186 4.5307C49.8744 4.5307 49.3816 5.26975 49.3323 6.00683L47.855 5.80018C47.9043 4.89973 48.8396 3.2937 51.3186 3.2937C53.4698 3.2937 54.7993 4.45001 54.7993 6.57072V9.51118C54.8022 10.2372 54.8695 10.9616 55.0004 11.6762V11.8041H53.6699C53.6045 11.3062 53.5382 10.7925 53.5221 10.2778L53.4567 10.262C53.0464 11.1772 51.9954 11.997 50.5171 11.997C48.8426 11.9999 48.0189 10.8604 48.0189 9.75228ZM50.7604 10.7797C52.2217 10.7797 53.4537 9.67159 53.4366 7.90416V7.50265L51.1386 8.04882C50.1208 8.28992 49.447 8.65896 49.447 9.62336C49.446 10.3949 49.973 10.7797 50.7604 10.7797Z"
        fill={color}
      />
    </svg>
  );
};
