import {MetadataStatuses} from '../../../types';
import {MetadataEditAction} from '../../../metadataEditActionTypes';
import {assertEntityStatus} from './assertEntityStatus';

export const updateEntitySavedStatus = (
  entityId: string,
  currentStatuses: MetadataStatuses,
  action: MetadataEditAction,
): MetadataStatuses => {
  const {status, changedStatus, previewStatus, type} = assertEntityStatus(
    entityId,
    {statuses: currentStatuses},
    action,
  );

  return changedStatus || previewStatus
    ? {
        ...currentStatuses,
        [entityId]: {
          status: changedStatus ?? status,
          type,
        },
      }
    : currentStatuses;
};
