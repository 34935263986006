import {useMemo} from 'react';
import assert from 'assert-ts';
import {PaginationState, PaginatorProps} from 'components/paginator/types';

export const usePaginationProps = (
  paginationState?: PaginationState,
  preText?: string,
): PaginatorProps | undefined => {
  return useMemo(() => {
    if (!paginationState) {
      return undefined;
    }

    assert(
      paginationState.onSelectNext,
      'usePaginationProps: PaginationState must have onSelectNext event',
    );
    assert(
      paginationState.onSelectPrevious,
      'usePaginationProps: PaginationState must have onSelectPrevious event',
    );

    return {
      ...paginationState,
      preText: preText,
    };
  }, [paginationState, preText]);
};
