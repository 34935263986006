import {useSelector} from 'react-redux';
import assert from 'assert-ts';
import {ManifestationDesignator} from 'types';
import {AppState} from 'store/types';

const useIdSelector =
  (designator: ManifestationDesignator | undefined) => (state: AppState) => {
    if (!designator) return undefined;

    const id = state.metadata?.data?.manifestations.find(
      m =>
        (designator.type === 'id' && m.id === designator.id) ||
        (designator.type === 'isbn' && m.isbn === designator.id) ||
        (designator.type === 'ean' && m.ean === designator.id),
    )?.id;

    if (!id) {
      assert.soft(
        false,
        'useManifestationIdByDesignators: no manifestation found',
        designator,
      );
    }

    return id;
  };

export const useManifestationIdByDesignators = (
  designator: ManifestationDesignator | undefined,
): string | undefined => {
  const selector = useIdSelector(designator);
  return useSelector(selector);
};
