import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {
  ActualChangesWithSchema,
  MetadataChangesInfo,
} from 'services/data/metadata/types';
import {AppState} from 'store/types';
import {useCreateSelectorById} from 'services/data/metadata/hooks/useCreateSelectorById';
import {ItemsCompletedStatus} from '../../../types';

const NoChanges: MetadataChangesInfo = {rootPaths: [], arraysUpdateHistory: {}};

const changesInfoSelectorById =
  (id: string | undefined) => (state: AppState) =>
    id ? state.metadataEdit.approvedChanges[id] ?? NoChanges : NoChanges;

export const useChangeRequestItemsCompletedStatus = (
  changes: ActualChangesWithSchema | undefined,
): {
  itemCompletedStatus: ItemsCompletedStatus;
  isCompleted: boolean;
  rootPaths: string[];
} => {
  const selector = useCreateSelectorById(changes?.id, changesInfoSelectorById);
  const changesInfo = useSelector(selector);
  return useMemo(() => {
    const {rootPaths, isCompleted} = changesInfo;
    const resolvedIsCompleted =
      isCompleted ?? (changes?.paths ?? []).every(p => rootPaths.includes(p));
    return {
      isCompleted: resolvedIsCompleted,
      itemCompletedStatus: (resolvedIsCompleted
        ? 'All'
        : rootPaths.length === 0
          ? 'None'
          : 'Some') as ItemsCompletedStatus,
      rootPaths,
    };
  }, [changes, changesInfo]);
};
