import {createContext, useCallback, useContext, useMemo, useState} from 'react';
import {assert} from 'assert-ts';
import {useAlertDialogAsPromise} from 'components';
import {assertSceneExtensionContext} from 'sceneExtensions/functions';
import {
  AnySceneExtension,
  AnySceneExtensionCore,
  FieldExtensionEnum,
  FieldExtensionUnsubscribe,
  SceneExtension,
} from '../types';

type SceneExtensionsContextType = {
  extensions: AnySceneExtension[];
  currentExtension?: AnySceneExtension;
};

export const SceneExtensionsContext = createContext<SceneExtensionsContextType>(
  {extensions: []},
);

const getExtension = <T extends Pick<AnySceneExtensionCore, 'type'>>(
  type: FieldExtensionEnum | undefined,
  extensions: T[],
): T | undefined => {
  return type === undefined
    ? undefined
    : assert(
        extensions.find(e => e.type === type),
        'getExtension: extension with type expectd',
        {type},
      );
};

export const useSceneExtensionsContextProviderValue = (
  extensions: AnySceneExtensionCore[],
): SceneExtensionsContextType => {
  const [currentExtensionType, setCurrentExtensionType] = useState<
    FieldExtensionEnum | undefined
  >();

  const {
    AlertDialog: ConfirmDiscardEditDialog,
    pleaseConfirm: pleaseConfirmDiscardEdit,
  } = useAlertDialogAsPromise();

  const confirmDiscardAnyChanges = useCallback(
    (
      type: FieldExtensionEnum | undefined,
      extensions: AnySceneExtensionCore[],
    ): Promise<boolean> => {
      const current = getExtension(type, extensions);
      if (current && current.fieldContext.hasChanges) {
        // console.log('confirmAnyDiscard...');
        const rootKey = 'extension.' + current.fieldContext.type;
        return pleaseConfirmDiscardEdit(rootKey);
      } else {
        return Promise.resolve(true);
      }
    },
    [pleaseConfirmDiscardEdit],
  );

  const wrappedExtensions = useMemo((): AnySceneExtension[] => {
    return extensions.map(
      ({renderRightPanel, fieldContext, ...restExtension}) => {
        const handleClose = () => {
          fieldContext.closeExtension();
          setCurrentExtensionType(undefined);
        };

        const handleCloseWithConfirm = () => {
          return new Promise<void>((resolve, reject) => {
            confirmDiscardAnyChanges(currentExtensionType, extensions).then(
              ok => {
                if (ok) {
                  handleClose();
                  resolve();
                } else {
                  reject();
                }
              },
            );
          });
        };

        return {
          ...restExtension,
          fieldContext: {
            ...fieldContext,
            setSourceField: (
              sourceField,
              setSourceFieldValue,
              onExtensionDisconnected,
            ) => {
              return confirmDiscardAnyChanges(
                currentExtensionType,
                extensions,
              ).then(ok => {
                if (ok) {
                  setCurrentExtensionType(() => restExtension.type);
                  return Promise.resolve(
                    fieldContext.setSourceField(
                      sourceField,
                      setSourceFieldValue,
                      onExtensionDisconnected,
                    ),
                  ).then(unsubscribeCore => {
                    const unsubscribe: FieldExtensionUnsubscribe | undefined =
                      unsubscribeCore
                        ? (fieldId: string) => {
                            if (unsubscribeCore && unsubscribeCore(fieldId)) {
                              handleClose();
                            }
                          }
                        : undefined;
                    return unsubscribe;
                  });
                } else {
                  return undefined;
                }
              });
            },
            setSourceValue: (
              newValue: unknown,
              action: string,
              keepOpen = 'close',
            ) => {
              fieldContext.setSourceValue(newValue, action);
              if (keepOpen !== 'keepOpen') {
                handleClose();
              }
            },
            closeExtension: handleClose,
          },
          // Extras to core
          renderRightPanel: () => (
            <>
              {renderRightPanel(handleCloseWithConfirm)}
              <ConfirmDiscardEditDialog />
            </>
          ),
          confirmCancelEditDialog: ConfirmDiscardEditDialog,
        };
      },
    );
  }, [
    ConfirmDiscardEditDialog,
    confirmDiscardAnyChanges,
    currentExtensionType,
    extensions,
  ]);

  return useMemo(() => {
    return {
      extensions: wrappedExtensions,
      currentExtension: getExtension(currentExtensionType, wrappedExtensions),
    };
  }, [currentExtensionType, wrappedExtensions]);
};

export const useSceneExtensionsContext = (): SceneExtensionsContextType => {
  return assert(useContext(SceneExtensionsContext));
};

export const useSceneExtensionContext = <
  TType extends FieldExtensionEnum,
  TValue,
  TSourceValue = TValue,
  TAction extends string = string,
  TFieldConfig = undefined,
>(
  extensionType: TType,
): SceneExtension<TType, TValue, TSourceValue, TAction, TFieldConfig> => {
  const extensionsContext = useSceneExtensionsContext();
  return assertSceneExtensionContext<
    TType,
    TValue,
    TSourceValue,
    TAction,
    TFieldConfig
  >(extensionType, extensionsContext.extensions);
};
