import {Data, DataValue} from 'schemaDefinition/types';

const EmptyScope = {};

export const verifyGlobalScope = (
  value: DataValue | undefined,
  usesGlobalScope: boolean | undefined,
): Data | undefined => {
  const verified = (value ?? (usesGlobalScope ? EmptyScope : undefined)) as
    | Data
    | undefined;
  return verified;
};
