import {assert} from 'assert-ts';
import isEqual from 'lodash/isEqual';
import {DataValue, PartThesaurus} from 'schemaDefinition/types';
import {TypeNodesWithAncestors} from 'services/thesaurus/types';
import {assertAsThesaurusValue} from 'schemaDefinition';
import {groupAndOrderByType} from 'services/thesaurus/functions';
import {ThesaurusMap} from '../types';

/**
 * Gets the actual thesaurus 'group' paths ('path#category' or 'path#nodetype') for each category/type that has changes,
 * i.e. terms in category/type in originalValue and changesValue are different.
 */
export const getActualThesaurusNodeTypePaths = <TNode extends string = string>(
  part: PartThesaurus<unknown>,
  path: string,
  originalValue: DataValue,
  changesValue: DataValue,
  thesauruses: ThesaurusMap | undefined,
): {
  paths: string[] | undefined;
  groupedOriginal: Array<TypeNodesWithAncestors<TNode>> | undefined;
  groupedChanges: Array<TypeNodesWithAncestors<TNode>> | undefined;
} => {
  const thesaurus = thesauruses?.[part.thesaurusId];
  const selectableNodeTypes = part.selectableNodeTypes as TNode[];
  if (
    !assert.soft(
      thesaurus,
      'getActualThesaurusCategoryPaths: Thesaurus not found',
      {part},
    ) ||
    !assert.soft(
      selectableNodeTypes,
      'getActualThesaurusCategoryPaths: Selectable node types not found',
      {part},
    )
  ) {
    return {
      paths: undefined,
      groupedOriginal: undefined,
      groupedChanges: undefined,
    };
  }

  const originalTerms = assertAsThesaurusValue(originalValue) ?? [];
  const changesTerms = assertAsThesaurusValue(changesValue) ?? [];

  const groupedOriginal = groupAndOrderByType<TNode>(
    selectableNodeTypes,
    thesaurus,
    originalTerms,
  );
  const groupedChanges = groupAndOrderByType<TNode>(
    selectableNodeTypes,
    thesaurus,
    changesTerms,
  );

  const allTypes = Array.from(
    new Set<TNode>([
      ...(groupedOriginal ?? []).map(go => go.nodeType as TNode),
      ...(groupedChanges ?? []).map(gc => gc.nodeType as TNode),
    ]),
  );
  const nodeTypesWithChanges = allTypes.filter(nodeType => {
    const original = groupedOriginal.find(go => go.nodeType === nodeType);
    const changes = groupedChanges.find(gc => gc.nodeType === nodeType);
    return !isEqual(original, changes);
  });

  return {
    paths: nodeTypesWithChanges.map(nodeType => `${path}#${nodeType}`),
    groupedOriginal,
    groupedChanges,
  };
};
