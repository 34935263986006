import {logError} from 'logging';
import {ChangeRequest} from 'api';
import {getAllStorageKeys} from './getAllStorageKeys';

export const loadAllChangeRequestsForWork = (
  workId: string,
): ChangeRequest[] => {
  const lowerCaseId = workId.toLowerCase();

  // Get all CR keys for workId sorted by date asceding
  const crKeys = getAllStorageKeys()
    .filter(k => k.toLowerCase().includes(lowerCaseId))
    .sort((a, b) => (a < b ? -1 : 1));

  // Load CR for each key
  const changeRequests = crKeys
    .map(k => {
      try {
        const json = localStorage.getItem(k);
        const cr = json ? (JSON.parse(json) as ChangeRequest) : null;
        return cr;
      } catch (error) {
        logError('Failed to load key from localStorage', {error, key: k});
        return null;
      }
    })
    .filter(cr => cr !== null) as ChangeRequest[];

  return changeRequests;
};
