import {assert} from 'assert-ts';
import {moveItem} from 'services/utils';
import {Rect, Spacing} from '../types';

export const getRelativePositionForIndex = (
  containerRect: Rect,
  spacing: Spacing,
  itemRects: Rect[],
  activeIndex: number,
  overIndex: number,
  index: number,
) => {
  assert(
    itemRects.length > 0,
    'getRelativePositionAtIndex: itemRects must be non-empty',
  );

  if (
    // Dragging single item over self
    itemRects.length === 1 ||
    // Dragging item over self
    activeIndex === overIndex
  ) {
    return {x: 0, y: 0};
  }

  // Determine new layout
  const {marginX, marginY, gutterX = 0, gutterY = 0} = spacing;
  const originalItems = itemRects.map((item, idx) => ({...item, id: idx}));
  const reorderedItemsRects = moveItem(originalItems, activeIndex, overIndex);
  const layedoutItemsRects = reorderedItemsRects.reduce<{
    items: (Rect & {id: number})[];
    left: number;
    top: number;
  }>(
    (acc, item) => {
      const {items, left, top} = acc;
      // Check for breaking to next row
      if (
        left + item.width + marginX >
        containerRect.width + containerRect.left
      ) {
        const nextTop = top + item.height + gutterY;
        const nextLeft = containerRect.left + marginX;
        return {
          items: [...items, {...item, top: nextTop, left: nextLeft}],
          left: nextLeft + item.width + gutterX,
          top: nextTop,
        };
      }

      // Add item to current row
      return {
        items: [...items, {...item, top, left}],
        left: left + item.width + gutterX,
        top,
      };
    },
    {
      items: [],
      left: containerRect.left + marginX,
      top: containerRect.top + marginY,
    },
  ).items;

  // Determine relative position
  const oldItemRect = itemRects[index];
  const newItemRect = assert(
    layedoutItemsRects.find(item => item.id === index),
  );
  return {
    x: newItemRect.left - oldItemRect.left,
    y: newItemRect.top - oldItemRect.top,
  };
};
