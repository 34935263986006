import {assert} from 'assert-ts';
import {PartValueModifier} from 'schemaDefinition/types';
import {isNullish} from 'services/utils';
import {partialDeweyNumberValidator} from '../validators';

export const deweyNumberModifier: PartValueModifier = ({
  value,
  part,
  oldValue,
}) => {
  assert(
    part && part.customizeKey === 'deweyNumber',
    'deweyNumberModifier: part must have customizeKey deweyNumber',
    part,
  );
  assert(
    isNullish(value) || typeof value === 'string',
    'deweyNumberModifier: value must be nullish or string',
    {value, part},
  );
  assert(
    isNullish(oldValue) || typeof oldValue === 'string',
    'deweyNumberModifier: oldValue must be nullish or string',
    {oldValue, part},
  );

  if (partialDeweyNumberValidator(value, part)) {
    return {value};
  }

  const strVal = value as string;
  // Try to fix the value: add .
  if (strVal.length > 3) {
    const dotPos = strVal[3] === '/' ? 4 : 3;
    const fixedValue = `${strVal.substring(0, dotPos)}.${strVal.substring(
      dotPos,
    )}`;
    if (partialDeweyNumberValidator(fixedValue, part)) {
      return {value: fixedValue};
    }
  }

  return {value: oldValue ?? null};
};
