import {PartValueRenderer} from 'schema/types';
import {CodeList} from 'schemaDefinition/types';
import {Dewey as DeweyType} from 'api';
import {useCodelist} from 'services/codeLists';
import {Builder, isNullish} from 'services/utils';
import {useTextValue} from '../../schema/preview/hooks';

const formatDewey = (dewey: DeweyType, deweySource: CodeList) => {
  return new Builder(dewey.value).add(
    ', ',
    dewey.source
      ? deweySource.codes.find(c => c.code === dewey.source)?.value
      : null,
  ).value;
};

export const Dewey: PartValueRenderer = ({value}) => {
  const deweySource = useCodelist('DEWEY_SOURCE');
  const TextValue = useTextValue();

  return isNullish(value) ||
    !Array.isArray(value) ||
    value.length === 0 ? null : (
    <TextValue>
      {value
        .map(v => formatDewey(v as DeweyType, deweySource))
        .filter(v => v)
        .join('; ')}
    </TextValue>
  );
};
