import {useSelector} from 'react-redux';
import {ManifestationV4} from 'api/types';
import {
  metadataEditManifestationSelectorById,
  nothingSelector,
} from '../selectors';
import {useCreateSelectorById} from './useCreateSelectorById';

export const useConditionalManifestationEdit = (
  manifestationId: string,
  required: boolean,
): ManifestationV4 | undefined => {
  const selector = useCreateSelectorById(
    manifestationId,
    metadataEditManifestationSelectorById,
  );
  return useSelector(required ? selector : nothingSelector);
};
