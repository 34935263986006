import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

/**
 * @param date string as YYYY-MM-DD
 *
 * Dayjs handles years below 100 as 19XX year.
 * E.g. 0003-01-01 becomes 1903-01-01.
 *
 * So we have to create the object more manually.
 *
 * @see https://github.com/iamkun/dayjs/issues/1237
 */
export const dateToDayJs = (date: string): dayjs.Dayjs => {
  const isBce = date.charAt(0) === '-';
  const dateString = isBce ? date.substring(1) : date;
  const [year = 0, month = 1, day = 1] = dateString
    .split('-')
    .map(v => Number(v));
  return dayjs()
    .utc()
    .year(isBce ? -year : year)
    .month(month - 1) // dayjs month is zero-indexed
    .date(day)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);
};

export const dateToDayJsFormat = (date: string): string => {
  return dateToDayJs(date).format('YYYY-MM-DD');
};

export const yearToDayJs = (year: number, resetDate?: boolean): dayjs.Dayjs => {
  const date = dayjs()
    .utc()
    .year(year)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);

  if (resetDate) {
    return date
      .month(0) // dayjs month is zero-indexed
      .date(1);
  }

  return date;
};

/**
 *
 * @param date string formatted YYYY-MM-DD
 */
export const dateToYear = (date: unknown): number | undefined => {
  if (typeof date !== 'string') {
    return undefined;
  }

  const number = dateToDayJs(date).format('YYYY');

  return Number(number);
};
export const yearToDate = (
  year: unknown,
  resetDate?: boolean,
): string | undefined => {
  if (typeof year === 'number') {
    return yearToDayJs(year, resetDate).format('YYYY-MM-DD');
  }

  /*
  if (typeof year === 'string') {
    const yearFromString = Number(year);
    if (!Number.isNaN(yearFromString)) {
      return yearToDayJs(yearFromString, resetDate).format('YYYY-MM-DD');
    }
  }
   */
  return undefined;
};
