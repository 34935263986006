import {useMemo} from 'react';
import {Schema, TypedSchema} from 'schemaDefinition/types';
import {NationalAgentType} from 'api';
import {useAgentSchema} from 'schemas';
import {NationalAgentWithInEbba} from '../types';

const extraSchemaParts: TypedSchema<NationalAgentWithInEbba> = {
  name: 'nationalAuthorityRegistry',
  parts: [
    {
      name: 'inEbba',
      type: 'bool',
    },
    {
      name: 'registryStatus',
      type: 'text',
    },
  ],
};

export const useNationalRegistryAgentSchema = (
  agentType: NationalAgentType,
): Schema => {
  const agentSchema = useAgentSchema(agentType);
  return useMemo(
    () => ({
      name: extraSchemaParts.name,
      parts: [...extraSchemaParts.parts, ...agentSchema.parts],
    }),
    [agentSchema],
  );
};
