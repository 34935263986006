import {ExpressionV4} from 'api/types';
import {clone, getId} from 'services/utils';

export const createExpression = (source: ExpressionV4): ExpressionV4 => {
  const newExpression = clone(source);
  newExpression.id = getId();
  newExpression.created = 0;
  newExpression.modified = 0;
  return newExpression;
};
