import QueryString from 'qs';
import {OverviewTask} from 'api/types';

export const getTaskLink = (task: OverviewTask): string | undefined => {
  return createTaskLink(task.isbn, task.workId, task.manifestationId, task.ean);
};

export const createTaskLink = (
  isbn: OverviewTask['isbn'],
  workId?: OverviewTask['workId'],
  manifestationId?: OverviewTask['manifestationId'],
  ean?: OverviewTask['ean'],
) => {
  if (workId) {
    const queryParams = isbn
      ? {isbn: isbn}
      : manifestationId
        ? {manifestationId: manifestationId}
        : {};

    return `/metadata/${workId}?${QueryString.stringify(queryParams, {
      skipNulls: true,
    })}`;
  }

  if (ean) {
    return `/metadata/ean/${ean}`;
  }

  return undefined;
};
