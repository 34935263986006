import {useCallback, useMemo} from 'react';
import {noOp} from 'services/utils';
import {
  AlertDialogAsPromiseV3,
  AlertDialogContentProps,
  useAlertDialogAsPromiseV3,
} from './useAlertDialogAsPromiseV3';

export const useStaticAlertDialogAsPromise = (
  dialogProps: AlertDialogContentProps,
): {
  /**
   * Present dialog and resolve to true if confirmed (and onConfirm completed successfully),
   * false if cancelled
   * @param onConfirm
   * @returns
   */
  pleaseConfirm: (
    /**
     * Confirm action, performed while dialog is still open with spinner if delayed
     */
    onConfirm?: () => void | Promise<void>,
  ) => Promise<boolean>;
  AlertDialog: AlertDialogAsPromiseV3;
  isOpen: boolean;
  isClosing: boolean;
} => {
  const {pleaseConfirm, AlertDialog, isOpen, isClosing} =
    useAlertDialogAsPromiseV3();

  const handlePleaseConfirm = useCallback(
    (onConfirm?: () => void | Promise<void>) => {
      return pleaseConfirm(onConfirm ?? noOp, dialogProps);
    },
    [pleaseConfirm, dialogProps],
  );

  return useMemo(
    () => ({
      pleaseConfirm: handlePleaseConfirm,
      AlertDialog,
      isOpen,
      isClosing,
    }),
    [handlePleaseConfirm, AlertDialog, isOpen, isClosing],
  );
};
