import {CodeListMap, WorkMetadata, WorkSummary, WorkWithExpressions} from 'api';
import {mapWorkMetadataToWorkSummary} from './mapWorkMetadataToWorkSummary';
import {mapWorkWithExpressionsToMetadata} from './mapWorkWithExpressionsToMetadata';

export const mapWorkWithExpressionToWorkSummary = (
  work: WorkWithExpressions,
  codelists: CodeListMap,
): WorkSummary => {
  const workMetadata: WorkMetadata = mapWorkWithExpressionsToMetadata(work);
  return mapWorkMetadataToWorkSummary(workMetadata, codelists);
};
