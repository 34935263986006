import {useSelector} from 'react-redux';
import {EntityEditStatus} from '../types';
import {editStatusesSelector} from '../selectors';

const DEFAULT_EDIT_STATUS: EntityEditStatus = {
  hasDataChanged: false,
  hasMoved: false,
};

export const useEditStatus = (id: string): EntityEditStatus => {
  const status = useSelector(editStatusesSelector)[id] ?? DEFAULT_EDIT_STATUS;
  return status;
};
