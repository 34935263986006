import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import {CodeListId, CodeListMap} from 'api/types';
import {AppState} from 'store/types';
import {useLocalization} from 'localization';
import {isLinkedRoleCodeListId} from 'schemaDefinition';
import {LinkedRoleCodeListId} from 'schemaDefinition/linkTypes';
import {getLinkedRoleCodelist} from 'schemas/links/functions';
import {useRestrictedCodelistsContext} from '../contexts';
import {getDummyCodeList} from './getDummyCodeList';

const codeListStateSelector = (state: AppState) => state.codeLists;

export const useOptionalCodeLists = (
  ids: (CodeListId | LinkedRoleCodeListId)[] | undefined,
): CodeListMap | undefined => {
  const localization = useLocalization();
  const {getRestrictedCodelist} = useRestrictedCodelistsContext();
  const codeListState = useSelector(codeListStateSelector);
  return useMemo(() => {
    if (!ids) {
      return undefined;
    }

    const map = ids.reduce<CodeListMap>((acc, id) => {
      if (isLinkedRoleCodeListId(id)) {
        const codelist = getLinkedRoleCodelist(id, localization);
        acc[id] = codelist;
        return acc;
      }

      const codeList = codeListState[id]?.data;
      if (
        assert.soft(codeList, 'useOptionalCodeLists: Code list not loaded', {
          id,
        })
      ) {
        acc[id] = getRestrictedCodelist(codeList);
      } else {
        acc[id] = getDummyCodeList(id);
      }

      return acc;
    }, {});

    return map;
  }, [codeListState, getRestrictedCodelist, ids, localization]);
};
