import {HighlightDto, HighlightPostTag, HighlightPreTag} from '../searchTypes';

export const mapHighlight = (dto?: HighlightDto): string[] => {
  const highlights: string[] = [];
  dto &&
    Object.keys(dto).forEach(k => {
      dto?.[k].forEach(v => {
        highlights.push(...mapFieldHighlights(v));
      });
    });

  return highlights;
};

// Each field can have multiple highlights
export const mapFieldHighlights = (
  fieldValue: string | undefined,
): string[] => {
  const highlights: string[] = [];
  if (!fieldValue) {
    return highlights;
  }

  let temp = fieldValue;

  while (temp.indexOf(HighlightPreTag) !== -1) {
    temp = temp.substring(temp.indexOf(HighlightPreTag));
    const highlight = temp
      .substring(0, temp.indexOf(HighlightPostTag))
      .substring(temp.indexOf(HighlightPreTag) + HighlightPreTag.length);

    highlights.push(highlight.toLowerCase());
    temp = temp.substring(
      temp.indexOf(HighlightPreTag) + HighlightPreTag.length,
    );
  }

  return highlights;
};
