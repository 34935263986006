import {createContext, useContext} from 'react';
import {assert} from 'assert-ts';
import {FCWithChildren} from 'types';
import {useMetadataOperations} from 'services/data/metadata';

type MetadataOperationsContextType = ReturnType<typeof useMetadataOperations>;

export const MetadataOperationsContext = createContext<
  MetadataOperationsContextType | undefined
>(undefined);

export const MetadataOperationsContextProvider: FCWithChildren = ({
  children,
}) => {
  const operations = useMetadataOperations();

  return (
    <MetadataOperationsContext.Provider value={operations}>
      {children}
    </MetadataOperationsContext.Provider>
  );
};

export const useMetadataOperationsContext =
  (): MetadataOperationsContextType => {
    return assert(
      useContext(MetadataOperationsContext),
      'useMetadataOperationsContext: context expected',
    );
  };
