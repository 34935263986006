import {ManifestationStatus} from 'api/types';
import {EntityWithStatus} from 'services/data/metadata/types';
import {getHighestStatus} from './getHighestStatus';

export const getAggregateStatusFromMap = (statuses: {
  [id: string]: EntityWithStatus;
}): ManifestationStatus | undefined => {
  return Object.keys(statuses).reduce<ManifestationStatus | undefined>(
    (acc, id) => {
      const itemStatus = statuses[id].status;
      return getHighestStatus(acc, itemStatus) ?? acc;
    },
    undefined,
  );
};
