import React from 'react';
import {SxProps} from '@mui/material';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {Icon, Layout, Spacer, Text} from 'components';
import {EditorMode} from '../types';

type Props = {
  mode: EditorMode;
};
const errorContainerSx: SxProps = {
  // height: 10,
  padding: 2,
  borderRadius: 2,
  backgroundColor: ColorPalette.warning40,
};

const guideContainerSx: SxProps = {
  padding: 2,
  borderRadius: 2,
  backgroundColor: ColorPalette.offWhite,
};

export const SearchAgentError: React.FC<Props> = ({mode}) => {
  const {t} = useLocalization();
  return (
    <Layout sx={errorContainerSx}>
      <Layout horizontal adjustCenter adjustLeft>
        <Icon icon="Info" />
        <Spacer size={1} />
        <Text variant="h3">
          {t('wizard.agent.step.1.search.failed.message')}
        </Text>
      </Layout>
      <Spacer size={2} />
      <Layout horizontal sx={guideContainerSx} adjustCenter adjustLeft>
        <Icon icon="RadioOn" />
        <Spacer size={1} />
        <Text variant="body1">
          {t(`wizard.agent.step.1.${mode}.search.failed.guide`)}
        </Text>
      </Layout>
    </Layout>
  );
};
