import {useMemo} from 'react';
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  styled,
} from '@mui/material';
// import {TextFieldProps} from 'components/types';
import {ColorPalette} from 'theme';
import './MuiTextFieldStyled.scss';

const MuiTextFieldStyledRaw = styled(MuiTextField)(() => ({
  'input:disabled, input.Mui-disabled': {
    color: ColorPalette.primary.burgundy,
    WebkitTextFillColor: ColorPalette.primary.burgundy,
  },
}));

type Props = Omit<MuiTextFieldProps, 'type' | 'disabled'> & {
  type?:
    | MuiTextFieldProps['type']
    /** Digits, but without inc/dec spinner */
    | 'digits';
  readonly?: boolean;
};

export const MuiTextFieldStyled = ({
  type = 'text',
  readonly = false,
  required = false,
  className,
  ...props
}: Props) => {
  const resolvedClassName = useMemo(() => {
    const partName = readonly
      ? type === 'digits'
        ? 'Ebba-digits Ebba-readonly'
        : 'Ebba-readonly'
      : type === 'digits'
        ? 'Ebba-digits'
        : type === 'number'
          ? 'Ebba-numbers'
          : undefined;
    return className === undefined && partName === undefined
      ? undefined
      : (className ?? '') + ' ' + (partName ?? '');
  }, [className, readonly, type]);
  const resolvedType = type === 'digits' ? 'number' : type;

  return (
    <MuiTextFieldStyledRaw
      className={resolvedClassName}
      disabled={readonly}
      required={required !== false}
      type={resolvedType}
      {...props}
    />
  );
};
