import React, {useCallback} from 'react';
import {Checkbox, FormControlLabel, Grid} from '@mui/material';
import {Box} from '@mui/system';
import {ColorPalette} from 'theme';
import {Accordion, Layout, Spacer, Text} from 'components';
import {useAdvancedSearchContext} from '../context/AdvancedSearchContext';
import {ColumnSets} from './ColumnSets';

export const SingleColumnSelectionAccordion: React.FC = () => {
  const {allColumns, currentColumns, setCurrentColumns} =
    useAdvancedSearchContext();

  const toggleCurrentColumn = useCallback(
    (column: string, checked: boolean) => {
      if (!checked) {
        setCurrentColumns(currentColumns.filter(c => c !== column));
      } else {
        setCurrentColumns([...currentColumns, column]);
      }
    },
    [setCurrentColumns, currentColumns],
  );

  return (
    <Accordion
      colorx={ColorPalette.lightBeige}
      initialExpanded={false}
      unmountOnExit
      onChange={() => undefined}>
      <Accordion.Header>
        <Layout horizontal flex={1} alignItems="space-between">
          <Layout horizontal adjustCenter adjustLeft>
            <Spacer size={0.5} />
            <Text variant="h4">{`${currentColumns.length} valgte kolonner`}</Text>
          </Layout>
        </Layout>
      </Accordion.Header>
      <Accordion.Content>
        <Box
          component={Grid}
          container
          spacing={4}
          padding={0}
          flex={1}
          flexDirection={'row'}>
          <Grid item xs={6} sm={3}>
            <ColumnSets />
          </Grid>

          <Grid item xs={6} sm={9}>
            <Layout horizontal alignItems={'center'} p={1}>
              <Text variant={'body1'}>Enkeltkolonner</Text>
            </Layout>
            <Grid container flex={1} flexDirection={'row'} spacing={1}>
              {allColumns.map(column => (
                <Grid item xs={12} sm={4} md={3} lg={2} key={column.name}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !!(currentColumns ?? []).find(c => c === column.name)
                        }
                        onChange={event => {
                          toggleCurrentColumn(
                            column.name,
                            event.target.checked,
                          );
                        }}
                        inputProps={{
                          'aria-label': column.label,
                        }}
                      />
                    }
                    label={column.label}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Accordion.Content>
    </Accordion>
  );
};
