import React from 'react';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {FlexBox, Text} from 'components';
import {SystemHealthStatus} from '../types';

type Props = {
  status: SystemHealthStatus;
};

export const HealthTitle: React.FC<Props> = ({status}) => {
  const {t} = useLocalization();
  const title = t(`system.health.${status}.title`);
  return (
    <FlexBox horizontal left center>
      {/* <HealthIndicator status={status} size="medium" />
      <Spacer width={1} /> */}
      <Text variant="body1" sx={{color: ColorPalette.offWhite}}>
        {title}
      </Text>
    </FlexBox>
  );
};
