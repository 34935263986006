import {ColumnConfig} from 'schema/table/types';

export const personAgentDefaultColumns: ColumnConfig[] = [
  {
    name: 'nameVariants',
    visible: true,
    width: 200,
  },
  {
    name: 'birth',
    visible: true,
  },
  {
    name: 'isni',
    visible: true,
    width: 170,
  },
  {
    name: 'nationalId',
    visible: true,
  },
  {
    name: 'death',
  },
  {
    name: 'countryOfBirth',
  },
  {
    name: 'country',
  },
  {
    name: 'profession',
  },
  {
    name: 'occupationalField',
  },
  {
    name: 'gender',
  },
  {
    name: 'internalComments',
  },
  {
    name: 'externalComments',
  },
];
