import {SxProps} from '@mui/system';
import {ColorPalette} from 'theme';
import {Layout, Text} from 'components';

type Props = {
  code: string;
};

const sx: SxProps = {
  borderRadius: 1,
  background: ColorPalette.beige,
  padding: 1,
};

export const ThesaurusCode: React.FC<Props> = ({code}) => {
  return (
    <Layout sx={sx}>
      <Text variant="body2">{code}</Text>
    </Layout>
  );
};
