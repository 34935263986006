import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import utc from 'dayjs/plugin/utc';
import {DateType} from '../types';
import {AgentSchemas} from '../avro-agent.generated';

dayjs.extend(objectSupport);
dayjs.extend(utc);

export const mapAgentSearchDate = (
  agentDate?: AgentSchemas.DateTypeDto,
): DateType | undefined => {
  if (!agentDate || (!agentDate.date && !agentDate.year)) {
    return undefined;
  }

  if (agentDate.date) {
    const newDate: DateType = {
      isApproximate: agentDate.isApproximate,
      bce: false,
      year: undefined,
      date: undefined,
    };

    const date = dayjs.utc({
      year: agentDate.date.year ? Math.abs(agentDate.date.year) : undefined,
      month: agentDate.date.month ? agentDate.date.month - 1 : 0, // months are 0 indexed
      day: agentDate.date.day,
    });

    if (agentDate.date.year && agentDate.date.year < 0) {
      newDate.bce = true;
      newDate.date = date.add({year: 1}).format('YYYY-MM-DD');
    } else {
      newDate.date = date.format('YYYY-MM-DD');
    }

    return newDate;
  }

  const newDate = agentDate as DateType;
  if (
    agentDate.year !== undefined &&
    agentDate.year !== null &&
    agentDate.year < 0
  ) {
    newDate.bce = true;
    newDate.year = agentDate.year ? Math.abs(agentDate.year - 1) : undefined;
  } else {
    newDate.bce = false;
  }

  return newDate;
};
