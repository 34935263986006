import {ValidationError} from 'schemaDefinition/types';
import {ValidationResult} from './types';
import {getAggregateValid} from './getAggregateValid';

export const addValidationError = (
  error: ValidationError,
  aggregateResult: ValidationResult,
): ValidationResult => ({
  valid: getAggregateValid(aggregateResult.valid, error.level),
  errors: [...aggregateResult.errors, error],
});
