import {useCallback} from 'react';
import {IconButton} from 'components';
import {CompareActionCompoent} from './types';
import {useDataFormContext} from '../contexts';
import {usePartChangeActionTooltip} from '../hooks';

export const DataFormCompareReplaceValue: CompareActionCompoent = ({
  valuePath,
  part,
  disabled,
}) => {
  const {setApprovedValue} = useDataFormContext();
  const tooltip = usePartChangeActionTooltip('replace', part);

  const handleApprove = useCallback(() => {
    setApprovedValue(valuePath, 'replace', undefined, undefined);
  }, [setApprovedValue, valuePath]);

  return (
    <IconButton
      icon="Swap"
      onClick={handleApprove}
      tooltip={tooltip}
      disabled={disabled}
    />
  );
};
