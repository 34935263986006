import {ThesaurusValue} from 'api/types';

export const areThesaurusValuesEqual = (
  a: ThesaurusValue | undefined,
  b: ThesaurusValue | undefined,
): boolean => {
  if (!a && !b) {
    return true;
  }

  if (!a || !b) {
    return false;
  }

  return a.length === b.length && a.every(id => b.includes(id));
};
