import {Accordion as AccordionContainer} from './Accordion';
import {AccordionContent} from './AccordionContent';
import {AccordionHeader} from './AccordionHeader';
import {HideWhenCollapsed} from './HideWhenCollapsed';

type AccordionType = typeof AccordionContainer & {
  Header: typeof AccordionHeader;
  HideWhenCollapsed: typeof HideWhenCollapsed;
  Content: typeof AccordionContent;
};

const Accordion: AccordionType = AccordionContainer as AccordionType;
Accordion.Header = AccordionHeader;
Accordion.Content = AccordionContent;
Accordion.HideWhenCollapsed = HideWhenCollapsed;

export {Accordion};
