import assert from 'assert-ts';
import {FieldRequired, FieldRequiredSimple} from '../types';

export const assertSimpleRequired = <TVal>(
  field?: FieldRequired<TVal>,
): FieldRequiredSimple | undefined => {
  if (typeof field === 'boolean' || field === 'should' || field === undefined) {
    return field;
  }

  assert(
    false,
    'Support for FieldConditionalRequired here is not implemented yet.',
  );
};
