import {useMemo} from 'react';
import {Concept} from 'types';
import {CodeListId} from 'api/types';
import {LinkedRoleCodeListId} from 'schemaDefinition/linkTypes';
import {useSchemaListCodelistIds} from 'schema/hooks';
import {useSchema} from 'schemas/hooks';

export const useMetadataCodelistIds = (): (
  | CodeListId
  | LinkedRoleCodeListId
)[] => {
  const workSchema = useSchema(Concept.work);
  const expressionSchema = useSchema(Concept.expression);
  const manifestationSchema = useSchema(Concept.manifestation);

  const schemas = useMemo(
    () => [workSchema, expressionSchema, manifestationSchema],
    [workSchema, expressionSchema, manifestationSchema],
  );

  const schemaCodelistIds = useSchemaListCodelistIds(schemas);

  return useMemo(() => {
    return [
      ...schemaCodelistIds,
      'product_form_simple_values',
      'UNNAMED_PERSON',
      'PUBLISHER_STATUS',
      'DISTRIBUTOR_STATUS',
    ];
  }, [schemaCodelistIds]);
};
