import {Concept} from 'types';
import {SchemaModifierMap} from 'schemaDefinition/types';
import {ModifiableSchemaReference} from 'schemas/types';
import {
  expressionSchemaModifiers,
  manifestationSchemaModifiers,
  workSchemaModifiers,
} from 'schemas/schemas';

export const getSchemaModifiers = <TKey extends string>(
  schemaRef: ModifiableSchemaReference,
): SchemaModifierMap<TKey> => {
  switch (schemaRef) {
    case Concept.work:
      return workSchemaModifiers;
    case Concept.expression:
      return expressionSchemaModifiers;
    case Concept.manifestation:
      return manifestationSchemaModifiers;
  }
};
