export const mapValues = <TSourceMap extends object, TTarget>(
  source: TSourceMap,
  mapper: (
    value: TSourceMap[keyof TSourceMap],
    key: keyof TSourceMap,
  ) => TTarget,
): {[key in keyof TSourceMap]: TTarget} => {
  return Object.keys(source).reduce(
    (acc, key) => {
      const sourceKey = key as keyof TSourceMap;
      return {
        ...acc,
        [key]: mapper(source[sourceKey], sourceKey),
      };
    },
    {} as {[key in keyof TSourceMap]: TTarget},
  );
};
