import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const PrintBookIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M16 4C15.2238 4 14.4582 4.18073 13.7639 4.52786C13.0697 4.875 12.4657 5.37902 12 6C11.5343 5.37902 10.9303 4.875 10.2361 4.52786C9.54179 4.18073 8.77623 4 8 4H4V18H11V20H13V18H20V4H16ZM6 16V6H8C8.79565 6 9.55871 6.31607 10.1213 6.87868C10.6839 7.44129 11 8.20435 11 9V16H6ZM18 16H13V9C13 8.20435 13.3161 7.44129 13.8787 6.87868C14.4413 6.31607 15.2044 6 16 6H18V16Z" />
    </SvgIcon>
  );
};
