import {Schemas} from 'api/dto.generated';

export const emneord: Schemas.ThesaurusExport[] = [
  {
    id: 'emneord_emneord',
    deactivated: false,
    label: 'Emneord',
    alternativeLabels: [],

    related: [],
    children: [
      {
        id: 'emneord_databehandlingoginformasjon',
        deactivated: false,
        label: 'Databehandling og informasjon',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'emneord_datamiljoeer',
            deactivated: false,
            label: 'Datamiljøer',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_informasjon',
            deactivated: false,
            label: 'Informasjon',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_arkiver',
                deactivated: false,
                label: 'Arkiver',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_bibliotek',
                deactivated: false,
                label: 'Bibliotek',
                alternativeLabels: [
                  'Avleveringsplikt',
                  'Bibliotekfag',
                  'Folkebibliotek',
                  'Forskningsbibliotek',
                  'Nasjonalbibliotek',
                  'Skolebibliotek',
                  'Universitetsbibliotek',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_bibliofili',
                    deactivated: false,
                    label: 'Bibliofili',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_informasjonssoeking',
                    deactivated: false,
                    label: 'Informasjonssøking',
                    alternativeLabels: [
                      'Informasjonsinnhenting',
                      'Referansearbeid',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_bokbransjen',
                deactivated: false,
                label: 'Bokbransjen',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_bokhandel',
                    deactivated: false,
                    label: 'Bokhandel',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_bokklubber',
                    deactivated: false,
                    label: 'Bokklubber',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_forlag',
                    deactivated: false,
                    label: 'Forlag',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_trykkerier',
                    deactivated: false,
                    label: 'Trykkerier',
                    alternativeLabels: ['Boktrykking', 'Typografi'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_forfatterskap',
                deactivated: false,
                label: 'Forfatterskap',
                alternativeLabels: [],
                note: 'Her om det å skrive',
                related: [
                  {
                    id: 'emneord_litteraeranalyse',
                    deactivated: false,
                    label: 'Litterær analyse',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_forskning',
                deactivated: false,
                label: 'Forskning',
                alternativeLabels: ['Forskningspolitikk'],

                related: [],
                children: [
                  {
                    id: 'emneord_forskere',
                    deactivated: false,
                    label: 'Forskere',
                    alternativeLabels: ['Vitenskapsfolk', 'Vitenskapsmenn'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_forskningsorganisasjon',
                    deactivated: false,
                    label: 'Forskningsorganisasjon',
                    alternativeLabels: ['NAVF', 'NTNF'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_undersoekelsesmetodikk',
                    deactivated: false,
                    label: 'Undersøkelsesmetodikk',
                    alternativeLabels: ['Dataanalyse', 'Hovedfagsoppgaver'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_forskningsmetoder',
                        deactivated: false,
                        label: 'Forskningsmetoder',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_kvalitativemetoder',
                            deactivated: false,
                            label: 'Kvalitative metoder',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_kvantitativemetoder',
                            deactivated: false,
                            label: 'Kvantitative metoder',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_informasjonsarbeid',
                deactivated: false,
                label: 'Informasjonsarbeid',
                alternativeLabels: ['PR', 'Public relations'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_informasjonsteori',
                deactivated: false,
                label: 'Informasjonsteori',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_kybernetikk',
                    deactivated: false,
                    label: 'Kybernetikk',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_operasjonsanalyse',
                    deactivated: false,
                    label: 'Operasjonsanalyse',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_systemteori',
                    deactivated: false,
                    label: 'Systemteori',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_kommunikasjonstjenester',
                deactivated: false,
                label: 'Kommunikasjonstjenester',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_postvesen',
                    deactivated: false,
                    label: 'Postvesen',
                    alternativeLabels: ['Posten'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_telekommunikasjon',
                    deactivated: false,
                    label: 'Telekommunikasjon',
                    alternativeLabels: [
                      'Teledata',
                      'Telefoner',
                      'Telematikk',
                      'Telex',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_isdn',
                        deactivated: false,
                        label: 'ISDN',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_mobiltelefoner',
                        deactivated: false,
                        label: 'Mobiltelefoner',
                        alternativeLabels: ['SMS'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_medievitenskap',
                deactivated: false,
                label: 'Medievitenskap',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_journalistikk',
                    deactivated: false,
                    label: 'Journalistikk',
                    alternativeLabels: ['Journalister', 'Nyhetsformidling'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_billedjournalistikk',
                        deactivated: false,
                        label: 'Billedjournalistikk',
                        alternativeLabels: ['Fotojournalistikk'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kildekritikk',
                        deactivated: false,
                        label: 'Kildekritikk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_videojournalistikk',
                        deactivated: false,
                        label: 'Videojournalistikk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_medier',
                    deactivated: false,
                    label: 'Medier',
                    alternativeLabels: [
                      'Massekommunikasjon',
                      'Massemedia',
                      'Massemedier',
                      'Media',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_aviser',
                        deactivated: false,
                        label: 'Aviser',
                        alternativeLabels: ['Presse'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_digitalemedier',
                        deactivated: false,
                        label: 'Digitale medier',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_podkast',
                            deactivated: false,
                            label: 'Podkast',
                            alternativeLabels: ['Podcast'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_kringkasting',
                        deactivated: false,
                        label: 'Kringkasting',
                        alternativeLabels: ['Kabel', 'Nærkringkasting'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_radio',
                            deactivated: false,
                            label: 'Radio',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_tv',
                            deactivated: false,
                            label: 'TV',
                            alternativeLabels: ['Fjernsyn'],

                            related: [],
                            children: [
                              {
                                id: 'emneord_tv-serier',
                                deactivated: false,
                                label: 'TV-serier',
                                alternativeLabels: [
                                  'Fjernsynsserier',
                                  'Reality',
                                ],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'emneord_mediamiljoeer',
                        deactivated: false,
                        label: 'Mediamiljøer',
                        alternativeLabels: [
                          'Avismiljøer',
                          'Fjernsynsmiljøer',
                          'Journalistmiljøer',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_mediefolk',
                        deactivated: false,
                        label: 'Mediefolk',
                        alternativeLabels: ['Forleggere'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_museer',
                deactivated: false,
                label: 'Museer',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_informasjonsteknologi',
            deactivated: false,
            label: 'Informasjonsteknologi',
            alternativeLabels: ['Databehandling'],

            related: [],
            children: [
              {
                id: 'emneord_administrativdatabehandling',
                deactivated: false,
                label: 'Administrativ databehandling',
                alternativeLabels: ['ADB'],

                related: [],
                children: [
                  {
                    id: 'emneord_kontorautomatisering',
                    deactivated: false,
                    label: 'Kontorautomatisering',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_arkivsystemer',
                        deactivated: false,
                        label: 'Arkivsystemer',
                        alternativeLabels: ['IR-systemer', 'Tekstsøkesystemer'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_dataassistertkonstruksjon',
                        deactivated: false,
                        label: 'Dataassistert konstruksjon',
                        alternativeLabels: ['DAK'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_desktoppublishing',
                        deactivated: false,
                        label: 'Desktop publishing',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_grafiskdatabehandling',
                        deactivated: false,
                        label: 'Grafisk databehandling',
                        alternativeLabels: ['Photoshop'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_powerpoint',
                            deactivated: false,
                            label: 'Powerpoint',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_virtuellvirkelighet',
                            deactivated: false,
                            label: 'Virtuell virkelighet',
                            alternativeLabels: ['Virtual reality', 'VR'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_gruppevare',
                        deactivated: false,
                        label: 'Gruppevare',
                        alternativeLabels: [
                          'Lotus notes',
                          'Microsoft office',
                          'Smartsuite',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_regneark',
                        deactivated: false,
                        label: 'Regneark',
                        alternativeLabels: ['Kalkulasjonsprogram'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_excel',
                            deactivated: false,
                            label: 'Excel',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_lotus1-2-3',
                            deactivated: false,
                            label: 'Lotus 1-2-3',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_tastaturbehandling',
                        deactivated: false,
                        label: 'Tastaturbehandling',
                        alternativeLabels: ['Maskinskriving'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_tekstbehandling',
                        deactivated: false,
                        label: 'Tekstbehandling',
                        alternativeLabels: ['ETB'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_sgml',
                            deactivated: false,
                            label: 'SGML',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_word',
                            deactivated: false,
                            label: 'Word',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_wordperfect',
                            deactivated: false,
                            label: 'Wordperfect',
                            alternativeLabels: ['WP'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_datakommunikasjon',
                deactivated: false,
                label: 'Datakommunikasjon',
                alternativeLabels: [
                  'Datanettverk',
                  'Elektronisk datautveksling',
                  'Nettverk',
                  'Protokoller',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_e-post',
                    deactivated: false,
                    label: 'E-post',
                    alternativeLabels: [
                      'E-mail',
                      'Elektronisk post',
                      'Email',
                      'Epost',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_internett',
                    deactivated: false,
                    label: 'Internett',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_blogger',
                        deactivated: false,
                        label: 'Blogger',
                        alternativeLabels: [],
                        note: 'Her: om det å skrive blogg (form for personlig journal elle dagbok som publiseres på internett)',
                        related: [
                          {
                            id: 'emneord_sosialemedier',
                            deactivated: false,
                            label: 'Sosiale medier',
                            note: 'Nettsider og apper som tilrettelegger for å skape og dele innhold, og å delta i sosiale nettverk',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'emneord_internettsider',
                        deactivated: false,
                        label: 'Internettsider',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_sosialemedier',
                        deactivated: false,
                        label: 'Sosiale medier',
                        alternativeLabels: [
                          'Facebook',
                          'Instagram',
                          'Linkedin',
                          'Twitter',
                          'Youtube',
                        ],
                        note: 'Nettsider og apper som tilrettelegger for å skape og dele innhold, og å delta i sosiale nettverk',
                        related: [
                          {
                            id: 'emneord_blogger',
                            deactivated: false,
                            label: 'Blogger',
                            note: 'Her: om det å skrive blogg (form for personlig journal elle dagbok som publiseres på internett)',
                          },
                        ],
                        children: [
                          {
                            id: 'emneord_influensere',
                            deactivated: false,
                            label: 'Influensere',
                            alternativeLabels: ['Bloggere'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_intranett',
                    deactivated: false,
                    label: 'Intranett',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_datapolitikk',
                deactivated: false,
                label: 'Datapolitikk',
                alternativeLabels: ['EDB og samfunn'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_datasikkerhet',
                deactivated: false,
                label: 'Datasikkerhet',
                alternativeLabels: ['Datavirus'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_datasikring',
                deactivated: false,
                label: 'Datasikring',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_datautstyr',
                deactivated: false,
                label: 'Datautstyr',
                alternativeLabels: [
                  'Datamaskiner',
                  'EDB-utstyr',
                  'Hardware',
                  'Lydkort',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_lagringsenheter',
                    deactivated: false,
                    label: 'Lagringsenheter',
                    alternativeLabels: [
                      'Båndstasjoner',
                      'Diskettstasjoner',
                      'Floppydisk',
                      'Platestasjoner',
                      'Winchesterdisk',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_mac',
                    deactivated: false,
                    label: 'Mac',
                    alternativeLabels: ['Macintosh'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_mikromaskiner',
                    deactivated: false,
                    label: 'Mikromaskiner',
                    alternativeLabels: ['Mikrodatamaskiner'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_hjemmedatamaskiner',
                        deactivated: false,
                        label: 'Hjemmedatamaskiner',
                        alternativeLabels: ['Hjemmecomputere'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_lommeregnere',
                        deactivated: false,
                        label: 'Lommeregnere',
                        alternativeLabels: ['Kalkulatorer'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_minimaskiner',
                    deactivated: false,
                    label: 'Minimaskiner',
                    alternativeLabels: [
                      'Minidatamaskiner',
                      'Superminimaskiner',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_nettbrett',
                    deactivated: false,
                    label: 'Nettbrett',
                    alternativeLabels: ['Ipad'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_pc',
                    deactivated: false,
                    label: 'PC',
                    alternativeLabels: ['Personlige datamaskiner'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_skrivere',
                    deactivated: false,
                    label: 'Skrivere',
                    alternativeLabels: [
                      'Linjeskrivere',
                      'Matriseskrivere',
                      'Printere',
                      'Typehjulskrivere',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_stormaskiner',
                    deactivated: false,
                    label: 'Stormaskiner',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_terminaler',
                    deactivated: false,
                    label: 'Terminaler',
                    alternativeLabels: [
                      'Skjermterminaler',
                      'Skrivende terminaler',
                      'VDU',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_digitalisering',
                deactivated: false,
                label: 'Digitalisering',
                alternativeLabels: [],
                related: [
                  {
                    id: 'emneord_digitallaering',
                    deactivated: false,
                    label: 'Digital læring',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_geografiskeinformasjonssystemer',
                deactivated: false,
                label: 'Geografiske informasjonssystemer',
                alternativeLabels: ['GIS'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_kunstigintelligens',
                deactivated: false,
                label: 'Kunstig intelligens',
                alternativeLabels: ['AI', 'KI'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_multimedia',
                deactivated: false,
                label: 'Multimedia',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_programvare',
                deactivated: false,
                label: 'Programvare',
                alternativeLabels: ['Programutstyr', 'Software'],

                related: [],
                children: [
                  {
                    id: 'emneord_databasesystemer',
                    deactivated: false,
                    label: 'Databasesystemer',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_access',
                        deactivated: false,
                        label: 'Access',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_dbase',
                        deactivated: false,
                        label: 'Dbase',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_paradox',
                        deactivated: false,
                        label: 'Paradox',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_sql',
                        deactivated: false,
                        label: 'SQL',
                        alternativeLabels: ['Structured query language'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_works',
                        deactivated: false,
                        label: 'Works',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_operativsystemer',
                    deactivated: false,
                    label: 'Operativsystemer',
                    alternativeLabels: ['Styresystemer'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_dos',
                        deactivated: false,
                        label: 'DOS',
                        alternativeLabels: ['MS DOS'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_linux',
                        deactivated: false,
                        label: 'Linux',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_osx',
                        deactivated: false,
                        label: 'OS X',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_unix',
                        deactivated: false,
                        label: 'UNIX',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_windows',
                        deactivated: false,
                        label: 'Windows',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_systemutvikling',
                deactivated: false,
                label: 'Systemutvikling',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_systemanalyse',
                    deactivated: false,
                    label: 'Systemanalyse',
                    alternativeLabels: ['Systemering'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_systemkonstruksjon',
                    deactivated: false,
                    label: 'Systemkonstruksjon',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_programmering',
                        deactivated: false,
                        label: 'Programmering',
                        alternativeLabels: [
                          'Programmeringsmetodikk',
                          'Strukturert programmering',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_programmeringsspraak',
                        deactivated: false,
                        label: 'Programmeringsspråk',
                        alternativeLabels: ['Dataspråk'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_basic',
                            deactivated: false,
                            label: 'Basic',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_visualbasic',
                                deactivated: false,
                                label: 'Visual Basic',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_cobol',
                            deactivated: false,
                            label: 'COBOL',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_c-programmering',
                            deactivated: false,
                            label: 'C-programmering',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_fortran',
                            deactivated: false,
                            label: 'Fortran',
                            alternativeLabels: ['Fortran 77', 'Fortran IV'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_html',
                            deactivated: false,
                            label: 'HTML',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_java',
                            deactivated: false,
                            label: 'Java',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_pascal',
                            deactivated: false,
                            label: 'Pascal',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_python',
                            deactivated: false,
                            label: 'Python',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_scratch',
                            deactivated: false,
                            label: 'Scratch',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_simula',
                            deactivated: false,
                            label: 'Simula',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_xml',
                            deactivated: false,
                            label: 'XML',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'emneord_filosofi',
        deactivated: false,
        label: 'Filosofi',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'emneord_erkjennelsesteori',
            deactivated: false,
            label: 'Erkjennelsesteori',
            alternativeLabels: ['Epistemologi'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_estetikk',
            deactivated: false,
            label: 'Estetikk',
            alternativeLabels: ['Camp', 'Kitsch', 'Skjønnhet'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_etikk',
            deactivated: false,
            label: 'Etikk',
            alternativeLabels: ['Moral', 'Moralfilosofi'],

            related: [],
            children: [
              {
                id: 'emneord_familieetikk',
                deactivated: false,
                label: 'Familieetikk',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_livssyn',
                deactivated: false,
                label: 'Livssyn',
                alternativeLabels: ['Nyorienteringsbevegelsen'],

                related: [],
                children: [
                  {
                    id: 'emneord_humanetikk',
                    deactivated: false,
                    label: 'Humanetikk',
                    alternativeLabels: ['Human-Etisk forbund'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_livssynsundervisning',
                    deactivated: false,
                    label: 'Livssynsundervisning',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_mennesketsnatur',
                deactivated: false,
                label: 'Menneskets natur',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_personligetikk',
                deactivated: false,
                label: 'Personlig etikk',
                alternativeLabels: ['Individual etikk'],

                related: [],
                children: [
                  {
                    id: 'emneord_egoisme',
                    deactivated: false,
                    label: 'Egoisme',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_seksualetikk',
                deactivated: false,
                label: 'Seksualetikk',
                alternativeLabels: ['Promiskuitet', 'Sølibat'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_sosialetikk',
                deactivated: false,
                label: 'Sosialetikk',
                alternativeLabels: ['Politisk etikk'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_yrkesetikk',
                deactivated: false,
                label: 'Yrkesetikk',
                alternativeLabels: ['Profesjonsetikk'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_filosofer',
            deactivated: false,
            label: 'Filosofer',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_filosofiskeretninger',
            deactivated: false,
            label: 'Filosofiske retninger',
            alternativeLabels: ['Filosofiske skoler'],

            related: [],
            children: [
              {
                id: 'emneord_antikkfilosofi',
                deactivated: false,
                label: 'Antikk filosofi',
                alternativeLabels: [
                  'Enhetsfilosofi',
                  'Epikureisme',
                  'Henologi',
                  'Platonisme',
                  'Stoisisme',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_idehistorie',
                deactivated: false,
                label: 'Idehistorie',
                alternativeLabels: ['Filosofihistorie'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_modernefilosofi',
                deactivated: false,
                label: 'Moderne filosofi',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_dialektiskmaterialisme',
                    deactivated: false,
                    label: 'Dialektisk materialisme',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_idealisme',
                    deactivated: false,
                    label: 'Idealisme',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kritiskfilosofi',
                    deactivated: false,
                    label: 'Kritisk filosofi',
                    alternativeLabels: [
                      'Eksistensialisme',
                      'Fenomenalisme',
                      'Fenomenologi',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_lingvistiskfilosofi',
                    deactivated: false,
                    label: 'Lingvistisk filosofi',
                    alternativeLabels: ['Strukturalisme'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_motkulturer',
                    deactivated: false,
                    label: 'Motkulturer',
                    alternativeLabels: ['New age', 'Økofilosofi'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_positivisme',
                    deactivated: false,
                    label: 'Positivisme',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_postmodernisme',
                    deactivated: false,
                    label: 'Postmodernisme',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_oestligfilosofi',
                deactivated: false,
                label: 'Østlig filosofi',
                alternativeLabels: ['Orientalsk filosofi', 'Østens filosofi'],

                related: [],
                children: [
                  {
                    id: 'emneord_fengshui',
                    deactivated: false,
                    label: 'Feng shui',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_logikk',
            deactivated: false,
            label: 'Logikk',
            alternativeLabels: [
              'Argumentasjon',
              'Deduksjon',
              'Feilkilder',
              'Hypotese',
              'Induksjon',
              'Saklighet',
            ],

            related: [],
            children: [],
          },
          {
            id: 'emneord_metafysikk',
            deactivated: false,
            label: 'Metafysikk',
            alternativeLabels: ['Spekulativ filosofi', 'Verdensanskuelse'],

            related: [],
            children: [
              {
                id: 'emneord_frihet',
                deactivated: false,
                label: 'Frihet',
                alternativeLabels: [
                  'Determinisme',
                  'Indeterminisme',
                  'Nødvendighet',
                  'Tilfeldighet',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_naturfilosofi',
                deactivated: false,
                label: 'Naturfilosofi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_ontologi',
                deactivated: false,
                label: 'Ontologi',
                alternativeLabels: ['Vesen', 'Væren'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_oversanseligefenomener',
            deactivated: false,
            label: 'Oversanselige fenomener',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_astrologi',
                deactivated: false,
                label: 'Astrologi',
                alternativeLabels: ['Horoskoper', 'Stjernetegn'],

                related: [],
                children: [
                  {
                    id: 'emneord_kinesiskastrologi',
                    deactivated: false,
                    label: 'Kinesisk astrologi',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_demonologi',
                deactivated: false,
                label: 'Demonologi',
                alternativeLabels: ['Onde ånder'],

                related: [],
                children: [
                  {
                    id: 'emneord_eksorsisme',
                    deactivated: false,
                    label: 'Eksorsisme',
                    alternativeLabels: ['Djevleutdrivelse'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_satanisme',
                    deactivated: false,
                    label: 'Satanisme',
                    alternativeLabels: ['Djevledyrkelse'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_fantasiskapninger',
                deactivated: false,
                label: 'Fantasiskapninger',
                alternativeLabels: ['Innbilte dyr og vesener', 'Liksomvenner'],

                related: [],
                children: [
                  {
                    id: 'emneord_drager',
                    deactivated: false,
                    label: 'Drager',
                    alternativeLabels: ['Draker'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_fabeldyr',
                    deactivated: false,
                    label: 'Fabeldyr',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_enhjoerninger',
                        deactivated: false,
                        label: 'Enhjørninger',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_havfruer',
                    deactivated: false,
                    label: 'Havfruer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_monstre',
                    deactivated: false,
                    label: 'Monstre',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_kontroversiellviten',
                deactivated: false,
                label: 'Kontroversiell viten',
                alternativeLabels: [
                  'Alternativ viten',
                  'Fortidsgåter',
                  'Mysterier',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_konspirasjonsteorier',
                    deactivated: false,
                    label: 'Konspirasjonsteorier',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_romvesener',
                    deactivated: false,
                    label: 'Romvesener',
                    alternativeLabels: ['Aliens', 'Utenomjordiske vesener'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_ufo-er',
                    deactivated: false,
                    label: 'UFO-er',
                    alternativeLabels: [
                      'Flyvende tallerkener',
                      'UFO',
                      'UFOer',
                      'Unidentified flying objects',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_magi',
                deactivated: false,
                label: 'Magi',
                alternativeLabels: ['Heksekunst', 'Trolldom'],

                related: [
                  {
                    id: 'emneord_hekser',
                    deactivated: false,
                    label: 'Hekser',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_okkultisme',
                deactivated: false,
                label: 'Okkultisme',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_spaadomskunst',
                deactivated: false,
                label: 'Spådomskunst',
                alternativeLabels: [
                  'Kiromanti',
                  'Klarsynte',
                  'Klarsynthet',
                  'Numerologi',
                  'Profetier',
                  'Spåing',
                  'Synske evner',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_tarot',
                    deactivated: false,
                    label: 'Tarot',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_spiritisme',
                deactivated: false,
                label: 'Spiritisme',
                alternativeLabels: [
                  'Borddans',
                  'Ektoplasma',
                  'Mediefenomener',
                  'Sjelereiser',
                  'Åndebankning',
                  'Åndemaning',
                  'Åndeverden',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_spoekelser',
                deactivated: false,
                label: 'Spøkelser',
                alternativeLabels: [
                  'Bankeånder',
                  'Dødninger',
                  'Gjenferd',
                  'Gjengangere',
                  'Poltergeister',
                  'Spøkelseshus',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_superhelter',
                deactivated: false,
                label: 'Superhelter',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_tidsreiser',
                deactivated: false,
                label: 'Tidsreiser',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_vampyrer',
                deactivated: false,
                label: 'Vampyrer',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_varulver',
                deactivated: false,
                label: 'Varulver',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_zombier',
                deactivated: false,
                label: 'Zombier',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_vitenskapsteori',
            deactivated: false,
            label: 'Vitenskapsteori',
            alternativeLabels: ['Vitenskapsfilosofi'],

            related: [],
            children: [
              {
                id: 'emneord_hermeneutikk',
                deactivated: false,
                label: 'Hermeneutikk',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'emneord_geografiogreiser',
        deactivated: false,
        label: 'Geografi og reiser',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'emneord_geografi',
            deactivated: false,
            label: 'Geografi',
            alternativeLabels: ['Berømte steder'],

            related: [],
            children: [
              {
                id: 'emneord_atmosfaeren',
                deactivated: false,
                label: 'Atmosfæren',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_havogsjoeer',
                deactivated: false,
                label: 'Hav og sjøer',
                alternativeLabels: ['Sjø og hav'],

                related: [
                  {
                    id: 'emneord_marinbiologi',
                    deactivated: false,
                    label: 'Marinbiologi',
                  },
                ],
                children: [
                  {
                    id: 'emneord_elveroginnsjoeer',
                    deactivated: false,
                    label: 'Elver og innsjøer',
                    alternativeLabels: [
                      'Elver',
                      'Ferskvann',
                      'Fosser',
                      'Innsjøer',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_fjorder',
                    deactivated: false,
                    label: 'Fjorder',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_klimaogvegetasjon',
                deactivated: false,
                label: 'Klima og vegetasjon',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_kaldesoner',
                    deactivated: false,
                    label: 'Kalde soner',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_temperertesoner',
                    deactivated: false,
                    label: 'Tempererte soner',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_tropiskesoner',
                    deactivated: false,
                    label: 'Tropiske soner',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_vegetasjonsomraader',
                    deactivated: false,
                    label: 'Vegetasjonsområder',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_fjellomraader',
                        deactivated: false,
                        label: 'Fjellområder',
                        alternativeLabels: ['Fjell'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kystomraader',
                        deactivated: false,
                        label: 'Kystområder',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_strender',
                            deactivated: false,
                            label: 'Strender',
                            alternativeLabels: ['Fjæra'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_oerkener',
                        deactivated: false,
                        label: 'Ørkener',
                        alternativeLabels: ['Ørken'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_skoger',
                        deactivated: false,
                        label: 'Skoger',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_skogbruk',
                            deactivated: false,
                            label: 'Skogbruk',
                          },
                        ],
                        children: [
                          {
                            id: 'emneord_regnskoger',
                            deactivated: false,
                            label: 'Regnskoger',
                            alternativeLabels: ['Jungel'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_oldtidsgeografi',
                deactivated: false,
                label: 'Oldtidsgeografi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_samfunnsgeografi',
                deactivated: false,
                label: 'Samfunnsgeografi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_reiser',
            deactivated: false,
            label: 'Reiser',
            alternativeLabels: ['Road novel', 'Safari'],

            related: [],
            children: [
              {
                id: 'emneord_oppdagelsesreiser',
                deactivated: false,
                label: 'Oppdagelsesreiser',
                alternativeLabels: ['Ekspedisjoner', 'Polarforskning'],

                related: [],
                children: [
                  {
                    id: 'emneord_oppdagelsesreisende',
                    deactivated: false,
                    label: 'Oppdagelsesreisende',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_sjoereiser',
                deactivated: false,
                label: 'Sjøreiser',
                alternativeLabels: ['Sjøferder'],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'emneord_historie',
        deactivated: false,
        label: 'Historie',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'emneord_arkeologi',
            deactivated: false,
            label: 'Arkeologi',
            alternativeLabels: ['Fornminner', 'Fortidsminner', 'Utgravninger'],

            related: [],
            children: [
              {
                id: 'emneord_bergkunst',
                deactivated: false,
                label: 'Bergkunst',
                alternativeLabels: ['Helleristninger'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_mumier',
                deactivated: false,
                label: 'Mumier',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_flagg',
            deactivated: false,
            label: 'Flagg',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_folkemordogetniskrensing',
            deactivated: false,
            label: 'Folkemord og etnisk rensing',
            alternativeLabels: [],

            related: [
              {
                id: 'emneord_krigsforbrytelser',
                deactivated: false,
                label: 'Krigsforbrytelser',
              },
            ],
            children: [
              {
                id: 'emneord_holocaust',
                deactivated: false,
                label: 'Holocaust',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_heraldikk',
            deactivated: false,
            label: 'Heraldikk',
            alternativeLabels: [
              'Byvåpen',
              'Kommunevåpen',
              'Ordener',
              'Slektsvåpen',
              'Våpenskjold',
            ],

            related: [],
            children: [],
          },
          {
            id: 'emneord_historiskepersoner',
            deactivated: false,
            label: 'Historiske personer',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_historiskfilosofi',
            deactivated: false,
            label: 'Historisk filosofi',
            alternativeLabels: [
              'Historiesyn',
              'Historisk metodelære',
              'Historisk teori',
            ],

            related: [],
            children: [],
          },
          {
            id: 'emneord_kulturhistorie',
            deactivated: false,
            label: 'Kulturhistorie',
            alternativeLabels: ['Sosialhistorie'],

            related: [],
            children: [
              {
                id: 'emneord_kulturminner',
                deactivated: false,
                label: 'Kulturminner',
                alternativeLabels: ['Kulturarv'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_kulturvern',
                deactivated: false,
                label: 'Kulturvern',
                alternativeLabels: ['Bevaring (kultur)'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_lokalhistorie',
            deactivated: false,
            label: 'Lokalhistorie',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_trolldomsprosesser',
            deactivated: false,
            label: 'Trolldomsprosesser',
            alternativeLabels: ['Hekseprosesser'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_verdenshistorie',
            deactivated: false,
            label: 'Verdenshistorie',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_denkaldekrigen',
                deactivated: false,
                label: 'Den kalde krigen',
                alternativeLabels: ['Kalde krigen'],
                note: 'Brukes for spenningstilstanden mellom Øst og Vest fra ca 1947 og til 1990',
                related: [],
                children: [],
              },
              {
                id: 'emneord_verdenskriger',
                deactivated: false,
                label: 'Verdenskriger',
                alternativeLabels: [],
                related: [
                  {
                    id: 'emneord_krig',
                    deactivated: false,
                    label: 'Krig',
                  },
                ],
                children: [
                  {
                    id: 'emneord_andreverdenskrig',
                    deactivated: false,
                    label: 'Andre verdenskrig',
                    alternativeLabels: [
                      '2. verdenskrig',
                      'Annen verdenskrig',
                      'Okkupasjonen',
                      'Verdenskrigen 1939-45',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_foersteverdenskrig',
                    deactivated: false,
                    label: 'Første verdenskrig',
                    alternativeLabels: [
                      '1. verdenskrig',
                      'Verdenskrigen 1914-18',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'emneord_hobbyogfritid',
        deactivated: false,
        label: 'Hobby og fritid',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'emneord_akvarier',
            deactivated: false,
            label: 'Akvarier',
            alternativeLabels: ['Akvarieplanter'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_baaterogsjoesport',
            deactivated: false,
            label: 'Båter og sjøsport',
            alternativeLabels: ['Sjøsport og båter'],

            related: [],
            children: [
              {
                id: 'emneord_baater',
                deactivated: false,
                label: 'Båter',
                alternativeLabels: ['Lystfartøyer', 'Plastbåter', 'Småbåter'],

                related: [
                  {
                    id: 'emneord_skip',
                    deactivated: false,
                    label: 'Skip',
                  },
                ],
                children: [
                  {
                    id: 'emneord_kajakker',
                    deactivated: false,
                    label: 'Kajakker',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_kajakkpadling',
                        deactivated: false,
                        label: 'Kajakkpadling',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'emneord_kanoer',
                    deactivated: false,
                    label: 'Kanoer',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_kanopadling',
                        deactivated: false,
                        label: 'Kanopadling',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'emneord_motorbaater',
                    deactivated: false,
                    label: 'Motorbåter',
                    alternativeLabels: ['Cabin cruisere', 'Påhengsmotorer'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_robaater',
                    deactivated: false,
                    label: 'Robåter',
                    alternativeLabels: ['Scullere'],

                    related: [
                      {
                        id: 'emneord_roing',
                        deactivated: false,
                        label: 'Roing',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'emneord_seilbaater',
                    deactivated: false,
                    label: 'Seilbåter',
                    alternativeLabels: ['Havseilere', 'Seiljoller'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_trebaater',
                    deactivated: false,
                    label: 'Trebåter',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_baatfoererproeven',
                deactivated: false,
                label: 'Båtførerprøven',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_sjoesport',
                deactivated: false,
                label: 'Sjøsport',
                alternativeLabels: ['Vannsport'],

                related: [],
                children: [
                  {
                    id: 'emneord_dykking',
                    deactivated: false,
                    label: 'Dykking',
                    alternativeLabels: [
                      'Dykkeutstyr',
                      'Froskemannsport',
                      'Sportsdykking',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_padling',
                    deactivated: false,
                    label: 'Padling',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_kajakkpadling',
                        deactivated: false,
                        label: 'Kajakkpadling',
                        alternativeLabels: ['Kajakksport'],

                        related: [
                          {
                            id: 'emneord_kajakker',
                            deactivated: false,
                            label: 'Kajakker',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'emneord_kanopadling',
                        deactivated: false,
                        label: 'Kanopadling',
                        alternativeLabels: ['Kanosport'],

                        related: [
                          {
                            id: 'emneord_kanoer',
                            deactivated: false,
                            label: 'Kanoer',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_roing',
                    deactivated: false,
                    label: 'Roing',
                    alternativeLabels: ['Rosport'],

                    related: [
                      {
                        id: 'emneord_robaater',
                        deactivated: false,
                        label: 'Robåter',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'emneord_seilsport',
                    deactivated: false,
                    label: 'Seilsport',
                    alternativeLabels: ['Kappseiling', 'Seiling'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_brettseiling',
                        deactivated: false,
                        label: 'Brettseiling',
                        alternativeLabels: ['Seilbrett', 'Windsurfing'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_svoemming',
                    deactivated: false,
                    label: 'Svømming',
                    alternativeLabels: ['Babysvømming', 'Stuping'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_vannskisport',
                    deactivated: false,
                    label: 'Vannskisport',
                    alternativeLabels: ['Vannskikjøring'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_sjoevett',
                deactivated: false,
                label: 'Sjøvett',
                alternativeLabels: ['Sjøveisregler'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_eksperimenter',
            deactivated: false,
            label: 'Eksperimenter',
            alternativeLabels: ['Slim'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_forming',
            deactivated: false,
            label: 'Forming',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_dekorasjoner',
                deactivated: false,
                label: 'Dekorasjoner',
                alternativeLabels: ['Pyntegjenstander'],

                related: [],
                children: [
                  {
                    id: 'emneord_blomsterarrangement',
                    deactivated: false,
                    label: 'Blomsterarrangement',
                    alternativeLabels: [
                      'Blomsterdekorasjoner',
                      'Blomsteroppsatser',
                      'Krydderbinding',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_gaverogpynt',
                    deactivated: false,
                    label: 'Gaver og pynt',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kurvfletting',
                    deactivated: false,
                    label: 'Kurvfletting',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_fargelegging',
                deactivated: false,
                label: 'Fargelegging',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_klaer',
                deactivated: false,
                label: 'Klær',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_accessoirer',
                    deactivated: false,
                    label: 'Accessoirer',
                    alternativeLabels: ['Bijouterier', 'Tilbehør', 'Vesker'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_smykker',
                        deactivated: false,
                        label: 'Smykker',
                        alternativeLabels: ['Bijouterier'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_barneklaer',
                    deactivated: false,
                    label: 'Barneklær',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_gensere',
                    deactivated: false,
                    label: 'Gensere',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_jakkerogkofter',
                    deactivated: false,
                    label: 'Jakker og kofter',
                    alternativeLabels: ['Kofter'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_luer',
                    deactivated: false,
                    label: 'Luer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_moter',
                    deactivated: false,
                    label: 'Moter',
                    alternativeLabels: ['Klesdesign', 'Motedesign'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_moteskapere',
                        deactivated: false,
                        label: 'Moteskapere',
                        alternativeLabels: ['Motedesignere'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_skjerfogsjal',
                    deactivated: false,
                    label: 'Skjerf og sjal',
                    alternativeLabels: ['Sjal'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sokkerogstroemper',
                    deactivated: false,
                    label: 'Sokker og strømper',
                    alternativeLabels: ['Strømper'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_votteroghansker',
                    deactivated: false,
                    label: 'Votter og hansker',
                    alternativeLabels: ['Hansker', 'Vanter'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_modellering',
                deactivated: false,
                label: 'Modellering',
                alternativeLabels: [
                  'Cernitleire',
                  'Hobbyleire',
                  'Keramikk',
                  'Leire',
                  'Lysstøping',
                  'Trolldeig',
                ],

                related: [
                  {
                    id: 'emneord_keramiskkunst',
                    deactivated: false,
                    label: 'Keramisk kunst',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_papirforming',
                deactivated: false,
                label: 'Papirforming',
                alternativeLabels: [
                  'Kartongsløyd',
                  'Papirarbeid',
                  'Papirsløyd',
                  'Pappmasje',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_origami',
                    deactivated: false,
                    label: 'Origami',
                    alternativeLabels: ['Papirbretting'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_papirklipp',
                    deactivated: false,
                    label: 'Papirklipp',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_perler',
                deactivated: false,
                label: 'Perler',
                alternativeLabels: ['Perling'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_redesign',
                deactivated: false,
                label: 'Redesign',
                alternativeLabels: ['Gjenbruk'],
                note: 'Her: å bruke et gammelt produkt til å skape et nytt',
                related: [],
                children: [],
              },
              {
                id: 'emneord_scrapbooking',
                deactivated: false,
                label: 'Scrapbooking',
                alternativeLabels: ['Fotoalbum'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_skinn-oglaerarbeid',
                deactivated: false,
                label: 'Skinn- og lærarbeid',
                alternativeLabels: ['Lærarbeid', 'Skinnarbeid'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_sloeyd',
                deactivated: false,
                label: 'Sløyd',
                alternativeLabels: ['Metallsløyd', 'Spikking', 'Tresløyd'],

                related: [],
                children: [
                  {
                    id: 'emneord_fuglekasser',
                    deactivated: false,
                    label: 'Fuglekasser',
                    alternativeLabels: ['Fuglehus'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_tekstilforming',
                deactivated: false,
                label: 'Tekstilforming',
                alternativeLabels: ['Håndarbeid'],

                related: [],
                children: [
                  {
                    id: 'emneord_applikasjon',
                    deactivated: false,
                    label: 'Applikasjon',
                    alternativeLabels: [
                      'Lappeteknikk',
                      'Patchwork',
                      'Quilting',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_batikk',
                    deactivated: false,
                    label: 'Batikk',
                    alternativeLabels: ['Stofftrykk'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_broderi',
                    deactivated: false,
                    label: 'Broderi',
                    alternativeLabels: ['Prydsøm', 'Sting'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_hekling',
                    deactivated: false,
                    label: 'Hekling',
                    alternativeLabels: ['Hakking'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_knipling',
                    deactivated: false,
                    label: 'Knipling',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_makrame',
                    deactivated: false,
                    label: 'Makrame',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_plantefarging',
                    deactivated: false,
                    label: 'Plantefarging',
                    alternativeLabels: ['Naturfarger', 'Plantefarger'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_soem',
                    deactivated: false,
                    label: 'Søm',
                    alternativeLabels: ['Sying', 'Tilskjæring'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_klesreparasjoner',
                        deactivated: false,
                        label: 'Klesreparasjoner',
                        alternativeLabels: ['Bøting', 'Stopping'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_reparasjoner',
                        deactivated: false,
                        label: 'Reparasjoner',
                        alternativeLabels: ['Bøting', 'Stopping'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_vedlikeholdavtekstiler',
                            deactivated: false,
                            label: 'Vedlikehold av tekstiler',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_spinning',
                    deactivated: false,
                    label: 'Spinning',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_strikking',
                    deactivated: false,
                    label: 'Strikking',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_toving',
                    deactivated: false,
                    label: 'Toving',
                    alternativeLabels: ['Filting'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_veving',
                    deactivated: false,
                    label: 'Veving',
                    alternativeLabels: ['Båndveving', 'Veveteknikk'],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_fotografering',
            deactivated: false,
            label: 'Fotografering',
            alternativeLabels: [],
            note: 'Her: bøker om å fotografere og ta bilder',
            related: [],
            children: [
              {
                id: 'emneord_digitalfotografering',
                deactivated: false,
                label: 'Digital fotografering',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_moerkeromsarbeid',
                deactivated: false,
                label: 'Mørkeromsarbeid',
                alternativeLabels: [
                  'Filmfremkalling',
                  'Fotokopiering',
                  'Mørkeromsteknikk',
                ],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_friluftsliv',
            deactivated: false,
            label: 'Friluftsliv',
            alternativeLabels: ['Hengekøyer', 'Utendørs overnatting'],

            related: [],
            children: [
              {
                id: 'emneord_jakt',
                deactivated: false,
                label: 'Jakt',
                alternativeLabels: [
                  'Elgjakt',
                  'Fangst',
                  'Småviltjakt',
                  'Storviltjakt',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_jegerproeven',
                    deactivated: false,
                    label: 'Jegerprøven',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_skyting',
                    deactivated: false,
                    label: 'Skyting',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_klatring',
                deactivated: false,
                label: 'Klatring',
                alternativeLabels: ['Buldring'],

                related: [],
                children: [
                  {
                    id: 'emneord_fjellklatring',
                    deactivated: false,
                    label: 'Fjellklatring',
                    alternativeLabels: ['Isklatring', 'Klippeklatring'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_naturskildringer',
                deactivated: false,
                label: 'Naturskildringer',
                alternativeLabels: [
                  'Dyreskildringer',
                  'Fiskehistorier',
                  'Jakthistorier',
                  'Naturopplevelser',
                  'Villmarksliv',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_sportsfiske',
                deactivated: false,
                label: 'Sportsfiske',
                alternativeLabels: [
                  'Fluefiske',
                  'Nymfefiske',
                  'På fisketur',
                  'Slukfiske',
                  'Stangbygging',
                ],

                related: [
                  {
                    id: 'emneord_casting',
                    deactivated: false,
                    label: 'Casting',
                  },
                  {
                    id: 'emneord_fiske',
                    deactivated: false,
                    label: 'Fiske',
                  },
                ],
                children: [
                  {
                    id: 'emneord_fluebinding',
                    deactivated: false,
                    label: 'Fluebinding',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_turer',
                deactivated: false,
                label: 'Turer',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_bilturer',
                    deactivated: false,
                    label: 'Bilturer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_fotturer',
                    deactivated: false,
                    label: 'Fotturer',
                    alternativeLabels: [
                      'Brevandring',
                      'Fjellvandring',
                      'Turmarsjer',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_motorsykkelturer',
                    deactivated: false,
                    label: 'Motorsykkelturer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_skiturer',
                    deactivated: false,
                    label: 'Skiturer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sykkelturer',
                    deactivated: false,
                    label: 'Sykkelturer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_fritid',
            deactivated: false,
            label: 'Fritid',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_hobbyteknikk',
            deactivated: false,
            label: 'Hobbyteknikk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_amatoerradio',
                deactivated: false,
                label: 'Amatørradio',
                alternativeLabels: ['Privatradio', 'Walkie-talkie'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_hobbyelektronikk',
                deactivated: false,
                label: 'Hobbyelektronikk',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_modellbygging',
                deactivated: false,
                label: 'Modellbygging',
                alternativeLabels: [
                  'Byggesett',
                  'Dragebygging',
                  'Fjernstyrte modeller',
                  'Modellbiler',
                  'Modellbåter',
                  'Modellfly',
                  'Modelljernbaner',
                  'Radiostyrte modeller',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_stereoanlegg',
                deactivated: false,
                label: 'Stereoanlegg',
                alternativeLabels: [
                  'Hi-fi',
                  'High fidelity',
                  'Kassettspillere',
                  'Musikkanlegg',
                ],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_husoghage',
            deactivated: false,
            label: 'Hus og hage',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_boligoppussing',
                deactivated: false,
                label: 'Boligoppussing',
                alternativeLabels: ['Oppussing'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_forbrukerspoersmaal',
                deactivated: false,
                label: 'Forbrukerspørsmål',
                alternativeLabels: [
                  'Forbrukeropplysning',
                  'Forbrukerrådet',
                  'Sparetips',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_hager',
                deactivated: false,
                label: 'Hager',
                alternativeLabels: ['Hagestell', 'I hagen'],

                related: [],
                children: [
                  {
                    id: 'emneord_blomsterbed',
                    deactivated: false,
                    label: 'Blomsterbed',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_blomsterplanter',
                        deactivated: false,
                        label: 'Blomsterplanter',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'emneord_busker',
                    deactivated: false,
                    label: 'Busker',
                    alternativeLabels: ['Hekker', 'Prydvekster'],

                    related: [
                      {
                        id: 'emneord_traer',
                        deactivated: false,
                        label: 'Trær',
                      },
                    ],
                    children: [
                      {
                        id: 'emneord_roser',
                        deactivated: false,
                        label: 'Roser',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_kjoekkenhager',
                    deactivated: false,
                    label: 'Kjøkkenhager',
                    alternativeLabels: [
                      'Grønnsakshager',
                      'Hobbydrivhus',
                      'Urtedyrking',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_spirer',
                        deactivated: false,
                        label: 'Spirer',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_plener',
                    deactivated: false,
                    label: 'Plener',
                    alternativeLabels: ['Gressplener'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_interioer',
                deactivated: false,
                label: 'Interiør',
                alternativeLabels: [
                  'Duker',
                  'Innredning',
                  'Puter',
                  'Serviettsying',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_borddekking',
                    deactivated: false,
                    label: 'Borddekking',
                    alternativeLabels: ['Bordpynting', 'Serviettbretting'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_gardiner',
                    deactivated: false,
                    label: 'Gardiner',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_moebler',
                    deactivated: false,
                    label: 'Møbler',
                    alternativeLabels: ['Møbeltapetsering'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_renhold',
                deactivated: false,
                label: 'Renhold',
                alternativeLabels: ['Rengjøring'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_rydding',
                deactivated: false,
                label: 'Rydding',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_stueplanter',
                deactivated: false,
                label: 'Stueplanter',
                alternativeLabels: ['Kaktus', 'Potteplanter', 'Sukkulenter'],

                related: [
                  {
                    id: 'emneord_blomsterplanter',
                    deactivated: false,
                    label: 'Blomsterplanter',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_kjaeledyr',
            deactivated: false,
            label: 'Kjæledyr',
            alternativeLabels: ['Selskapsdyr'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_lek',
            deactivated: false,
            label: 'Lek',
            alternativeLabels: [
              'Aktiviteter',
              'Barneaktiviteter',
              'Musikkleker',
              'Sangleker',
            ],

            related: [],
            children: [
              {
                id: 'emneord_hjernetrim',
                deactivated: false,
                label: 'Hjernetrim',
                alternativeLabels: ['Hjernegymnastikk'],

                related: [],
                children: [
                  {
                    id: 'emneord_kakuro',
                    deactivated: false,
                    label: 'Kakuro',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kryssord',
                    deactivated: false,
                    label: 'Kryssord',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sudoku',
                    deactivated: false,
                    label: 'Sudoku',
                    alternativeLabels: [
                      'Godoku',
                      'Su doku',
                      'Wordoku',
                      'Zoodoku',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_selskapsleker',
                deactivated: false,
                label: 'Selskapsleker',
                alternativeLabels: ['Sketsjer'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_tryllekunster',
                deactivated: false,
                label: 'Tryllekunster',
                alternativeLabels: ['Kortkunster', 'Trylling'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_leketoey',
            deactivated: false,
            label: 'Leketøy',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_byggeklosser',
                deactivated: false,
                label: 'Byggeklosser',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_legoklosser',
                    deactivated: false,
                    label: 'Legoklosser',
                    alternativeLabels: ['Duploklosser'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_dukker',
                deactivated: false,
                label: 'Dukker',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_kosedyr',
                deactivated: false,
                label: 'Kosedyr',
                alternativeLabels: ['Bamser', 'Teddybjørner', 'Tøydyr'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_spinnere',
                deactivated: false,
                label: 'Spinnere',
                alternativeLabels: ['Fidget spinnere'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_rekorder',
            deactivated: false,
            label: 'Rekorder',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_samling',
            deactivated: false,
            label: 'Samling',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_frimerkesamling',
                deactivated: false,
                label: 'Frimerkesamling',
                alternativeLabels: ['Filateli', 'Frimerker'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_myntsamling',
                deactivated: false,
                label: 'Myntsamling',
                alternativeLabels: [
                  'Medaljer',
                  'Mynter',
                  'Numismatikk',
                  'Sedler',
                  'Segl',
                  'Sigillografi',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_postkort',
                deactivated: false,
                label: 'Postkort',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_steinsamling',
                deactivated: false,
                label: 'Steinsamling',
                alternativeLabels: [
                  'Amatørgeologi',
                  'Mineralsamling',
                  'Steinsliping',
                ],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_selskapsdans',
            deactivated: false,
            label: 'Selskapsdans',
            alternativeLabels: ['Gammeldans', 'Konkurransedans'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_spill',
            deactivated: false,
            label: 'Spill',
            alternativeLabels: ['Figurspill'],

            related: [],
            children: [
              {
                id: 'emneord_brettspill',
                deactivated: false,
                label: 'Brettspill',
                alternativeLabels: ['Dam', 'Ludo', 'Monopol'],

                related: [],
                children: [
                  {
                    id: 'emneord_sjakk',
                    deactivated: false,
                    label: 'Sjakk',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_dataspill',
                deactivated: false,
                label: 'Dataspill',
                alternativeLabels: ['Gaming'],

                related: [
                  {
                    id: 'emneord_e-sport',
                    deactivated: false,
                    label: 'E-sport',
                    note: 'Her: om e-sport som formalisert konkurranseform på et profesjonelt nivå',
                  },
                ],
                children: [
                  {
                    id: 'emneord_fortnite',
                    deactivated: false,
                    label: 'Fortnite',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_minecraft',
                    deactivated: false,
                    label: 'Minecraft',
                    alternativeLabels: ['Minecraft dungeons'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_pokémon',
                    deactivated: false,
                    label: 'Pokémon',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_roblox',
                    deactivated: false,
                    label: 'Roblox',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_kortspill',
                deactivated: false,
                label: 'Kortspill',
                alternativeLabels: ['Kabaler'],

                related: [],
                children: [
                  {
                    id: 'emneord_bridge',
                    deactivated: false,
                    label: 'Bridge',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_poker',
                    deactivated: false,
                    label: 'Poker',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_kunnskapsspill',
                deactivated: false,
                label: 'Kunnskapsspill',
                alternativeLabels: [],
                note: 'Med pedagogisk innhold',
                related: [],
                children: [],
              },
              {
                id: 'emneord_mobilspill',
                deactivated: false,
                label: 'Mobilspill',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_rollespill',
                deactivated: false,
                label: 'Rollespill',
                alternativeLabels: [],
                note: 'Her: rollespill som emne',
                related: [],
                children: [],
              },
              {
                id: 'emneord_sjansespill',
                deactivated: false,
                label: 'Sjansespill',
                alternativeLabels: [
                  'Backgammon',
                  'Hasard',
                  'Lotto',
                  'Pengespill',
                  'Rulett',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_tipping',
                    deactivated: false,
                    label: 'Tipping',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_veddeloep',
                    deactivated: false,
                    label: 'Veddeløp',
                    alternativeLabels: [
                      'Hesteveddeløp',
                      'Totalisatorspill',
                      'V6',
                      'Veddeløpsmiljø',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_sport',
            deactivated: false,
            label: 'Sport',
            alternativeLabels: ['Idrett', 'Konkurranser'],

            related: [],
            children: [
              {
                id: 'emneord_ballsport',
                deactivated: false,
                label: 'Ballsport',
                alternativeLabels: ['Ballspill'],

                related: [],
                children: [
                  {
                    id: 'emneord_basketball',
                    deactivated: false,
                    label: 'Basketball',
                    alternativeLabels: ['Basket', 'Kurvball'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_fotball',
                    deactivated: false,
                    label: 'Fotball',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_golf',
                    deactivated: false,
                    label: 'Golf',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_haandball',
                    deactivated: false,
                    label: 'Håndball',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_racketspill',
                    deactivated: false,
                    label: 'Racketspill',
                    alternativeLabels: ['Squash'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_badminton',
                        deactivated: false,
                        label: 'Badminton',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_bordtennis',
                        deactivated: false,
                        label: 'Bordtennis',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_tennis',
                        deactivated: false,
                        label: 'Tennis',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_volleyball',
                    deactivated: false,
                    label: 'Volleyball',
                    alternativeLabels: ['Strandvolleyball'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_casting',
                deactivated: false,
                label: 'Casting',
                alternativeLabels: [],
                related: [
                  {
                    id: 'emneord_sportsfiske',
                    deactivated: false,
                    label: 'Sportsfiske',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_e-sport',
                deactivated: false,
                label: 'E-sport',
                alternativeLabels: ['Elektronisk sport', 'Esport'],
                note: 'Her: om e-sport som formalisert konkurranseform på et profesjonelt nivå',
                related: [
                  {
                    id: 'emneord_dataspill',
                    deactivated: false,
                    label: 'Dataspill',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_friidrett',
                deactivated: false,
                label: 'Friidrett',
                alternativeLabels: ['Kappgang', 'Mangekamp'],

                related: [],
                children: [
                  {
                    id: 'emneord_hoppoevelser',
                    deactivated: false,
                    label: 'Hoppøvelser',
                    alternativeLabels: [
                      'Høydehopp',
                      'Lengdehopp',
                      'Stavsprang',
                      'Tresteg',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kastoevelser',
                    deactivated: false,
                    label: 'Kastøvelser',
                    alternativeLabels: [
                      'Diskoskast',
                      'Kulekast',
                      'Sleggekast',
                      'Spydkast',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_loepsoevelser',
                    deactivated: false,
                    label: 'Løpsøvelser',
                    alternativeLabels: [
                      'Hekk',
                      'Hinder',
                      'Langdistanse',
                      'Mellomdistanse',
                      'Sprint',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_maraton',
                        deactivated: false,
                        label: 'Maraton',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_gymnastikk',
                deactivated: false,
                label: 'Gymnastikk',
                alternativeLabels: [
                  'Aerobic',
                  'Rytmisk sportsgymnastikk',
                  'Sportsgymnastikk',
                  'Turn',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_hestesport',
                deactivated: false,
                label: 'Hestesport',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_galopp',
                    deactivated: false,
                    label: 'Galopp',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_ridning',
                    deactivated: false,
                    label: 'Ridning',
                    alternativeLabels: ['Sprangridning'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_travsport',
                    deactivated: false,
                    label: 'Travsport',
                    alternativeLabels: ['Trav', 'Travløp'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_hundesport',
                deactivated: false,
                label: 'Hundesport',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_hundekjoering',
                    deactivated: false,
                    label: 'Hundekjøring',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_idrettsutoevere',
                deactivated: false,
                label: 'Idrettsutøvere',
                alternativeLabels: ['Sportsutøvere'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_kampsport',
                deactivated: false,
                label: 'Kampsport',
                alternativeLabels: [
                  'Fekting',
                  'Jiu jitsu',
                  'Judo',
                  'Kampidrett',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_boksing',
                    deactivated: false,
                    label: 'Boksing',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_bryting',
                    deactivated: false,
                    label: 'Bryting',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_karate',
                    deactivated: false,
                    label: 'Karate',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_selvforsvar',
                    deactivated: false,
                    label: 'Selvforsvar',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_taekwondo',
                    deactivated: false,
                    label: 'Taekwondo',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_luftsport',
                deactivated: false,
                label: 'Luftsport',
                alternativeLabels: [
                  'Fallskjermhopping',
                  'Glideflyging',
                  'Hanggliding',
                  'Sportsflyging',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_motorsport',
                deactivated: false,
                label: 'Motorsport',
                alternativeLabels: ['Billøp', 'Motorsykkelløp'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_olympiskeleker',
                deactivated: false,
                label: 'Olympiske leker',
                alternativeLabels: ['OL', 'Olympiader'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_orientering',
                deactivated: false,
                label: 'Orientering',
                alternativeLabels: ['Orienteringsløp', 'Terrengløp'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_rullebrett',
                deactivated: false,
                label: 'Rullebrett',
                alternativeLabels: ['Skateboard'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_rulleskoeyter',
                deactivated: false,
                label: 'Rulleskøyter',
                alternativeLabels: ['Inline-skøyter', 'Rollerblades'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_sykling',
                deactivated: false,
                label: 'Sykling',
                alternativeLabels: ['Sykkelsport', 'Sykler'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_triatlon',
                deactivated: false,
                label: 'Triatlon',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_vektloefting',
                deactivated: false,
                label: 'Vektløfting',
                alternativeLabels: ['Kroppsbygging'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_vintersport',
                deactivated: false,
                label: 'Vintersport',
                alternativeLabels: [
                  'Akesport',
                  'Aking',
                  'Bandy',
                  'Bob',
                  'Bobsleigh',
                  'Curling',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_skisport',
                    deactivated: false,
                    label: 'Skisport',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_alpinegrener',
                        deactivated: false,
                        label: 'Alpine grener',
                        alternativeLabels: [
                          'Slalåm',
                          'Storslalåm',
                          'Super-G',
                          'Utfor',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_freestyle',
                        deactivated: false,
                        label: 'Freestyle',
                        alternativeLabels: [
                          'Halfpipe',
                          'Kulekjøring',
                          'Skicross',
                          'Slopestyle',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kombinert',
                        deactivated: false,
                        label: 'Kombinert',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_langrenn',
                        deactivated: false,
                        label: 'Langrenn',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_skihopp',
                        deactivated: false,
                        label: 'Skihopp',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_skiskyting',
                        deactivated: false,
                        label: 'Skiskyting',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_skoeytesport',
                    deactivated: false,
                    label: 'Skøytesport',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_ishockey',
                        deactivated: false,
                        label: 'Ishockey',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kunstloep',
                        deactivated: false,
                        label: 'Kunstløp',
                        alternativeLabels: ['Isdans'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_skoeyteloep',
                        deactivated: false,
                        label: 'Skøyteløp',
                        alternativeLabels: ['Hurtigløp'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_snoebrett',
                    deactivated: false,
                    label: 'Snøbrett',
                    alternativeLabels: ['Snowboard'],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_tivoli',
            deactivated: false,
            label: 'Tivoli',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_trening',
            deactivated: false,
            label: 'Trening',
            alternativeLabels: ['Fysisk form', 'Mosjon', 'Trim'],

            related: [],
            children: [
              {
                id: 'emneord_gaaing',
                deactivated: false,
                label: 'Gåing',
                alternativeLabels: ['Gåsport'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_loeping',
                deactivated: false,
                label: 'Løping',
                alternativeLabels: ['Jogging'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_pilates',
                deactivated: false,
                label: 'Pilates',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_styrketrening',
                deactivated: false,
                label: 'Styrketrening',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_vaapen',
            deactivated: false,
            label: 'Våpen',
            alternativeLabels: ['Ammunisjon', 'Håndvåpen', 'Jaktvåpen'],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'emneord_jus',
        deactivated: false,
        label: 'Jus',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'emneord_folkerett',
            deactivated: false,
            label: 'Folkerett',
            alternativeLabels: [
              'Folkerettslige tekster',
              'Internasjonal rett',
              'Mellomfolkelig rett',
            ],

            related: [],
            children: [
              {
                id: 'emneord_havrett',
                deactivated: false,
                label: 'Havrett',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_krigensrett',
                deactivated: false,
                label: 'Krigens rett',
                alternativeLabels: ['Genevekonvensjonen'],

                related: [],
                children: [
                  {
                    id: 'emneord_landssvik',
                    deactivated: false,
                    label: 'Landssvik',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_luftfartsrett',
                deactivated: false,
                label: 'Luftfartsrett',
                alternativeLabels: ['Romfartsrett'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_statsborgerrett',
                deactivated: false,
                label: 'Statsborgerrett',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_flyktninger',
                    deactivated: false,
                    label: 'Flyktninger',
                    alternativeLabels: ['Asyl', 'Asylsøkere'],

                    related: [
                      {
                        id: 'emneord_menneskesmugling',
                        deactivated: false,
                        label: 'Menneskesmugling',
                        note: 'Her: Organisert illegal flytting av mennesker fra et land til et annet',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_traktater',
                deactivated: false,
                label: 'Traktater',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_juridiskeformularer',
            deactivated: false,
            label: 'Juridiske formularer',
            alternativeLabels: ['Formularer', ' juridiske'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_offentligrett',
            deactivated: false,
            label: 'Offentlig rett',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_forvaltningsrett',
                deactivated: false,
                label: 'Forvaltningsrett',
                alternativeLabels: ['Offentlighetsloven'],

                related: [],
                children: [
                  {
                    id: 'emneord_bygningsrett',
                    deactivated: false,
                    label: 'Bygningsrett',
                    alternativeLabels: ['Enterpriserett'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_finansrett',
                    deactivated: false,
                    label: 'Finansrett',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kommunalrett',
                    deactivated: false,
                    label: 'Kommunalrett',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_petroleumsrett',
                    deactivated: false,
                    label: 'Petroleumsrett',
                    alternativeLabels: ['Bergverksrett', 'Oljerett'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_samferdselsrett',
                    deactivated: false,
                    label: 'Samferdselsrett',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_jernbanerett',
                        deactivated: false,
                        label: 'Jernbanerett',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_luftrett',
                        deactivated: false,
                        label: 'Luftrett',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_sjoerett',
                        deactivated: false,
                        label: 'Sjørett',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_vegtrafikkrett',
                        deactivated: false,
                        label: 'Vegtrafikkrett',
                        alternativeLabels: ['Veitrafikkrett'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_skatterett',
                    deactivated: false,
                    label: 'Skatterett',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sosialrett',
                    deactivated: false,
                    label: 'Sosialrett',
                    alternativeLabels: ['Sosiallovgivning'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_trygderett',
                        deactivated: false,
                        label: 'Trygderett',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_samerett',
                deactivated: false,
                label: 'Samerett',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_privatrett',
            deactivated: false,
            label: 'Privatrett',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_arbeidsrett',
                deactivated: false,
                label: 'Arbeidsrett',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_arverett',
                deactivated: false,
                label: 'Arverett',
                alternativeLabels: ['Skifterett', 'Testamenter'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_avtalerett',
                deactivated: false,
                label: 'Avtalerett',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_bankrett',
                deactivated: false,
                label: 'Bankrett',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_erstatningsrett',
                deactivated: false,
                label: 'Erstatningsrett',
                alternativeLabels: [
                  'Ansvarsrett',
                  'Erstatningsansvar',
                  'Erstatningsutmåling',
                  'Produktansvar',
                  'Skadeserstatning',
                  'Uaktsomhet',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_familierett',
                deactivated: false,
                label: 'Familierett',
                alternativeLabels: [
                  'Barnebidrag',
                  'Barnefordeling',
                  'Barnerett',
                  'Bidrag',
                  'Ekteskapsrett',
                  'Mekling',
                  'Separasjon',
                  'Skilsmisserett',
                  'Underholdningsbidrag',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_formuerett',
                deactivated: false,
                label: 'Formuerett',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_forsikringsrett',
                deactivated: false,
                label: 'Forsikringsrett',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_handelsrett',
                deactivated: false,
                label: 'Handelsrett',
                alternativeLabels: ['Inkasso'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_immaterialrett',
                deactivated: false,
                label: 'Immaterialrett',
                alternativeLabels: ['Åndsrett'],

                related: [],
                children: [
                  {
                    id: 'emneord_opphavsrett',
                    deactivated: false,
                    label: 'Opphavsrett',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_patentrett',
                    deactivated: false,
                    label: 'Patentrett',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_konkursrett',
                deactivated: false,
                label: 'Konkursrett',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_gjeldsordningsrett',
                    deactivated: false,
                    label: 'Gjeldsordningsrett',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_medierett',
                deactivated: false,
                label: 'Medierett',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_obligasjonsrett',
                deactivated: false,
                label: 'Obligasjonsrett',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_kontraktsrett',
                    deactivated: false,
                    label: 'Kontraktsrett',
                    alternativeLabels: ['Boligrett', 'Kontrakter', 'Leierett'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_kjoepsrett',
                        deactivated: false,
                        label: 'Kjøpsrett',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_pengekravsrett',
                    deactivated: false,
                    label: 'Pengekravsrett',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_personrett',
                deactivated: false,
                label: 'Personrett',
                alternativeLabels: ['Kvinnerett'],

                related: [],
                children: [
                  {
                    id: 'emneord_personvern',
                    deactivated: false,
                    label: 'Personvern',
                    alternativeLabels: [
                      'GDPR',
                      'Injurie',
                      'Taushetsplikt',
                      'Ærekrenkelse',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_selskapsrett',
                deactivated: false,
                label: 'Selskapsrett',
                alternativeLabels: [
                  'Aksjeselskapsrett',
                  'Andelslag',
                  'Borettslag',
                  'Foreningsrett',
                  'Komandittselskaper',
                  'Samvirkelag',
                  'Stiftelser',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_tingsrett',
                deactivated: false,
                label: 'Tingsrett',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_dynamisktingsrett',
                    deactivated: false,
                    label: 'Dynamisk tingsrett',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_tinglysing',
                        deactivated: false,
                        label: 'Tinglysing',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_tingsrett(statisk)',
                    deactivated: false,
                    label: 'Tingsrett (statisk)',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_allemannsrett',
                        deactivated: false,
                        label: 'Allemannsrett',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_eiendomsrett',
                        deactivated: false,
                        label: 'Eiendomsrett',
                        alternativeLabels: [
                          'Bruksrett',
                          'Hevd',
                          'Jordskifte',
                          'Konsesjonsrett',
                          'Tomtefeste',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_ekspropriasjonsrett',
                            deactivated: false,
                            label: 'Ekspropriasjonsrett',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_odelsrett',
                            deactivated: false,
                            label: 'Odelsrett',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_panterett',
                            deactivated: false,
                            label: 'Panterett',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_sameie',
                            deactivated: false,
                            label: 'Sameie',
                            alternativeLabels: ['Felleseie'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_tvangsfullbyrdelse',
                        deactivated: false,
                        label: 'Tvangsfullbyrdelse',
                        alternativeLabels: ['Tvangsauksjon'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_rettsinformatikk',
            deactivated: false,
            label: 'Rettsinformatikk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_rettsvitenskap',
            deactivated: false,
            label: 'Rettsvitenskap',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_jurister',
                deactivated: false,
                label: 'Jurister',
                alternativeLabels: ['Advokater'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_rettsfilosofi',
                deactivated: false,
                label: 'Rettsfilosofi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_rettshistorie',
                deactivated: false,
                label: 'Rettshistorie',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_rettssystemer',
                deactivated: false,
                label: 'Rettssystemer',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_sammenlignenderett',
                deactivated: false,
                label: 'Sammenlignende rett',
                alternativeLabels: ['Komparativ rett'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_sakfoersel',
            deactivated: false,
            label: 'Sakførsel',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_statsforfatningsrett',
            deactivated: false,
            label: 'Statsforfatningsrett',
            alternativeLabels: ['Statsrett'],

            related: [],
            children: [
              {
                id: 'emneord_grunnloven',
                deactivated: false,
                label: 'Grunnloven',
                alternativeLabels: ['Grunnlover', 'Konstitusjoner', 'Riksrett'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_strafferett',
            deactivated: false,
            label: 'Strafferett',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'emneord_kunst',
        deactivated: false,
        label: 'Kunst',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'emneord_andrekunstformer',
            deactivated: false,
            label: 'Andre kunstformer',
            alternativeLabels: [],
            note: 'Her: ikke-grafiske kunstformer',
            related: [],
            children: [
              {
                id: 'emneord_installasjonskunst',
                deactivated: false,
                label: 'Installasjonskunst',
                alternativeLabels: ['Installasjoner (kunst)'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_performance',
                deactivated: false,
                label: 'Performance',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_popkunst',
                deactivated: false,
                label: 'Popkunst',
                alternativeLabels: ['Opkunst'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_arkitektur',
            deactivated: false,
            label: 'Arkitektur',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_byggverk',
                deactivated: false,
                label: 'Byggverk',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_bygninger',
                    deactivated: false,
                    label: 'Bygninger',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_boliger',
                        deactivated: false,
                        label: 'Boliger',
                        alternativeLabels: ['Hus'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_vaaningshus',
                            deactivated: false,
                            label: 'Våningshus',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_festningerogforsvarsverk',
                        deactivated: false,
                        label: 'Festninger og forsvarsverk',
                        alternativeLabels: ['Forsvarsverk'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_fritidsboliger',
                        deactivated: false,
                        label: 'Fritidsboliger',
                        alternativeLabels: ['Hytter'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kirkebygninger',
                        deactivated: false,
                        label: 'Kirkebygninger',
                        alternativeLabels: [
                          'Katedraler',
                          'Kirker',
                          'Prestegårder',
                        ],

                        related: [
                          {
                            id: 'emneord_kirken',
                            deactivated: false,
                            label: 'Kirken',
                            note: 'Her kirken som organisasjon',
                          },
                        ],
                        children: [
                          {
                            id: 'emneord_stavkirker',
                            deactivated: false,
                            label: 'Stavkirker',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_naust',
                        deactivated: false,
                        label: 'Naust',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_pyramider',
                        deactivated: false,
                        label: 'Pyramider',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_slottogherregaarder',
                        deactivated: false,
                        label: 'Slott og herregårder',
                        alternativeLabels: ['Gods', 'Herregårder', 'Palasser'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_gjerder',
                    deactivated: false,
                    label: 'Gjerder',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_interioerarkitektur',
                deactivated: false,
                label: 'Interiørarkitektur',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_landskapsarkitektur',
                deactivated: false,
                label: 'Landskapsarkitektur',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_byplanlegging',
                    deactivated: false,
                    label: 'Byplanlegging',
                    alternativeLabels: ['Byfornyelse'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_restaurering',
                deactivated: false,
                label: 'Restaurering',
                alternativeLabels: ['Fredede hus'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_bildendekunst',
            deactivated: false,
            label: 'Bildende kunst',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_billedkunstnere',
                deactivated: false,
                label: 'Billedkunstnere',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_billedhuggere',
                    deactivated: false,
                    label: 'Billedhuggere',
                    alternativeLabels: ['Skulptører'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_malere',
                    deactivated: false,
                    label: 'Malere',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_tegnere',
                    deactivated: false,
                    label: 'Tegnere',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_collage',
                deactivated: false,
                label: 'Collage',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_fotografi',
                deactivated: false,
                label: 'Fotografi',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_fotokunst',
                    deactivated: false,
                    label: 'Fotokunst',
                    alternativeLabels: ['Fotografisk kunst', 'Kunstfoto'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_hologrammer',
                    deactivated: false,
                    label: 'Hologrammer',
                    alternativeLabels: ['Tredimensjonale bilder'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_gatekunst',
                deactivated: false,
                label: 'Gatekunst',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_graffiti',
                deactivated: false,
                label: 'Graffiti',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_grafikk',
                deactivated: false,
                label: 'Grafikk',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_datakunst',
                    deactivated: false,
                    label: 'Datakunst',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_litografi',
                    deactivated: false,
                    label: 'Litografi',
                    alternativeLabels: ['Fargelitografi'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_radering',
                    deactivated: false,
                    label: 'Radering',
                    alternativeLabels: ['Etsing'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_silketrykk',
                    deactivated: false,
                    label: 'Silketrykk',
                    alternativeLabels: ['Serigrafi'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_tresnitt',
                    deactivated: false,
                    label: 'Tresnitt',
                    alternativeLabels: [
                      'Kobberstikk',
                      'Linoleumssnitt',
                      'Stålstikk',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_malerkunst',
                deactivated: false,
                label: 'Malerkunst',
                alternativeLabels: ['Kunstmaling', 'Maleri'],
                note: 'Her: Samlinger',
                related: [
                  {
                    id: 'emneord_maling',
                    deactivated: false,
                    label: 'Maling',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_maling',
                deactivated: false,
                label: 'Maling',
                alternativeLabels: ['Ansiktsmaling', 'Maleriteknikk'],

                related: [
                  {
                    id: 'emneord_dekorasjonsmaling',
                    deactivated: false,
                    label: 'Dekorasjonsmaling',
                  },
                  {
                    id: 'emneord_malerkunst',
                    deactivated: false,
                    label: 'Malerkunst',
                    note: 'Her: Samlinger',
                  },
                ],
                children: [
                  {
                    id: 'emneord_akvareller',
                    deactivated: false,
                    label: 'Akvareller',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_oljemaleri',
                    deactivated: false,
                    label: 'Oljemaleri',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_temperamaleri',
                    deactivated: false,
                    label: 'Temperamaleri',
                    alternativeLabels: ['Freskomaleri', 'Gouache'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_motiver',
                deactivated: false,
                label: 'Motiver',
                alternativeLabels: ['Billedanalyse', 'Ikonografi', 'Tegn'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_plakatkunst',
                deactivated: false,
                label: 'Plakatkunst',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_skulpturer',
                deactivated: false,
                label: 'Skulpturer',
                alternativeLabels: ['Billedhuggerkunst', 'Mosaikk'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_tegnekunst',
                deactivated: false,
                label: 'Tegnekunst',
                alternativeLabels: [],
                note: 'Her: Samlinger',
                related: [
                  {
                    id: 'emneord_tegning',
                    deactivated: false,
                    label: 'Tegning',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_tegning',
                deactivated: false,
                label: 'Tegning',
                alternativeLabels: [
                  'Frihåndstegning',
                  'Karikaturtegning',
                  'Perspektivtegning',
                  'Silhuetter',
                ],

                related: [
                  {
                    id: 'emneord_projeksjonstegning',
                    deactivated: false,
                    label: 'Projeksjonstegning',
                  },
                  {
                    id: 'emneord_tegnekunst',
                    deactivated: false,
                    label: 'Tegnekunst',
                    note: 'Her: Samlinger',
                  },
                  {
                    id: 'emneord_teknisktegning',
                    deactivated: false,
                    label: 'Teknisk tegning',
                  },
                ],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_kunsthaandverk',
            deactivated: false,
            label: 'Kunsthåndverk',
            alternativeLabels: ['Brukskunst', 'Folkekunst', 'Kunstindustri'],

            related: [],
            children: [
              {
                id: 'emneord_antikviteter',
                deactivated: false,
                label: 'Antikviteter',
                alternativeLabels: ['Møbelkunst'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_bokbinding',
                deactivated: false,
                label: 'Bokbinding',
                alternativeLabels: ['Innbinding (bøker)'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_bokkunst',
                deactivated: false,
                label: 'Bokkunst',
                alternativeLabels: ['Illustrasjonskunst'],

                related: [],
                children: [
                  {
                    id: 'emneord_kalligrafi',
                    deactivated: false,
                    label: 'Kalligrafi',
                    alternativeLabels: [
                      'Bokstavmønstre',
                      'Gotisk skrift',
                      'Skjønnskrift',
                      'Skrifttyper',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_dekorasjonsmaling',
                deactivated: false,
                label: 'Dekorasjonsmaling',
                alternativeLabels: [],
                related: [
                  {
                    id: 'emneord_maling',
                    deactivated: false,
                    label: 'Maling',
                  },
                ],
                children: [
                  {
                    id: 'emneord_porselensmaling',
                    deactivated: false,
                    label: 'Porselensmaling',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_rosemaling',
                    deactivated: false,
                    label: 'Rosemaling',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_silkemaling',
                    deactivated: false,
                    label: 'Silkemaling',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_glasskunst',
                deactivated: false,
                label: 'Glasskunst',
                alternativeLabels: ['Glassblåsing'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_keramiskkunst',
                deactivated: false,
                label: 'Keramisk kunst',
                alternativeLabels: [
                  'Fajanse',
                  'Leirvarer',
                  'Pottemakeri',
                  'Steintøy',
                ],

                related: [
                  {
                    id: 'emneord_modellering',
                    deactivated: false,
                    label: 'Modellering',
                  },
                ],
                children: [
                  {
                    id: 'emneord_porselen',
                    deactivated: false,
                    label: 'Porselen',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_metallkunst',
                deactivated: false,
                label: 'Metallkunst',
                alternativeLabels: [
                  'Emaljering',
                  'Gullsmedkunst',
                  'Juvelerkunst',
                  'Smijernskunst',
                  'Sølvsmedkunst',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_tekstilkunst',
                deactivated: false,
                label: 'Tekstilkunst',
                alternativeLabels: [
                  'Billedtepper',
                  'Billedvev',
                  'Kunstbroderi',
                  'Kunststrikking',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_folkedrakter',
                    deactivated: false,
                    label: 'Folkedrakter',
                    alternativeLabels: ['Bunader', 'Festdrakter'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_laerornamentikk',
                    deactivated: false,
                    label: 'Lærornamentikk',
                    alternativeLabels: ['Gyllenlær', 'Pauting'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_treskjaering',
                deactivated: false,
                label: 'Treskjæring',
                alternativeLabels: ['Billedskjærerkunst', 'Treskurd'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_kunsthistorie',
            deactivated: false,
            label: 'Kunsthistorie',
            alternativeLabels: [
              'Kunstretninger',
              'Stilarter',
              'Teaterhistorie',
            ],

            related: [],
            children: [
              {
                id: 'emneord_antikkkunst',
                deactivated: false,
                label: 'Antikk kunst',
                alternativeLabels: [
                  'Assyrisk kunst',
                  'Gresk kunst',
                  'Klassisk kunst',
                  'Romersk kunst',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_barokken',
                deactivated: false,
                label: 'Barokken',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_bysantinskkunst',
                deactivated: false,
                label: 'Bysantinsk kunst',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_gotikken',
                deactivated: false,
                label: 'Gotikken',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_impresjonisme',
                deactivated: false,
                label: 'Impresjonisme',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_jugendstil',
                deactivated: false,
                label: 'Jugendstil',
                alternativeLabels: ['Art noveau'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_modernekunst',
                deactivated: false,
                label: 'Moderne kunst',
                alternativeLabels: ['Modernisme', 'Samtidskunst'],

                related: [],
                children: [
                  {
                    id: 'emneord_abstraktkunst',
                    deactivated: false,
                    label: 'Abstrakt kunst',
                    alternativeLabels: [
                      'Konkret kunst',
                      'Konstruktivisme',
                      'Nonfigurativ kunst',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_ekspresjonisme',
                    deactivated: false,
                    label: 'Ekspresjonisme',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_fauvisme',
                    deactivated: false,
                    label: 'Fauvisme',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_futurisme',
                    deactivated: false,
                    label: 'Futurisme',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kubisme',
                    deactivated: false,
                    label: 'Kubisme',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_surrealisme',
                    deactivated: false,
                    label: 'Surrealisme',
                    alternativeLabels: ['Dadaisme'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_nyklassisisme',
                deactivated: false,
                label: 'Nyklassisisme',
                alternativeLabels: ['Empire', 'Regency'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_realisme',
                deactivated: false,
                label: 'Realisme',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_naturalisme',
                    deactivated: false,
                    label: 'Naturalisme',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sosialrealisme',
                    deactivated: false,
                    label: 'Sosialrealisme',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_renessansekunst',
                deactivated: false,
                label: 'Renessansekunst',
                alternativeLabels: ['Manierisme'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_rokokko',
                deactivated: false,
                label: 'Rokokko',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_romanskstil',
                deactivated: false,
                label: 'Romansk stil',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_romantikken',
                deactivated: false,
                label: 'Romantikken',
                alternativeLabels: ['Nasjonalromantikk', 'Wienerklassisisme'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_symbolisme',
                deactivated: false,
                label: 'Symbolisme',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_kunstnere',
            deactivated: false,
            label: 'Kunstnere',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_kunstnermiljoeer',
            deactivated: false,
            label: 'Kunstnermiljøer',
            alternativeLabels: [
              'Bohemmiljøer',
              'Filmmiljøer',
              'Forfattermiljøer',
              'Teatermiljøer',
            ],

            related: [],
            children: [],
          },
          {
            id: 'emneord_musikk',
            deactivated: false,
            label: 'Musikk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_komposisjon',
                deactivated: false,
                label: 'Komposisjon',
                alternativeLabels: ['Arrangering', 'Instrumentasjon'],

                related: [],
                children: [
                  {
                    id: 'emneord_komponister',
                    deactivated: false,
                    label: 'Komponister',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_musikere',
                deactivated: false,
                label: 'Musikere',
                alternativeLabels: ['Dirigenter', 'Sangere', 'Solister'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_musikkformer',
                deactivated: false,
                label: 'Musikkformer',
                alternativeLabels: ['Musikkgenre'],

                related: [],
                children: [
                  {
                    id: 'emneord_folkemusikk',
                    deactivated: false,
                    label: 'Folkemusikk',
                    alternativeLabels: ['Etnomusikk', 'Folkeviser'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_klassiskmusikk',
                    deactivated: false,
                    label: 'Klassisk musikk',
                    alternativeLabels: [
                      'Orkestermusikk',
                      'Overturer',
                      'Seriøs musikk',
                      'Suiter',
                      'Symfonier',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_filmmusikk',
                        deactivated: false,
                        label: 'Filmmusikk',
                        alternativeLabels: ['Teatermusikk'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kirkemusikk',
                        deactivated: false,
                        label: 'Kirkemusikk',
                        alternativeLabels: ['Oratorier'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_samtidsmusikk',
                        deactivated: false,
                        label: 'Samtidsmusikk',
                        alternativeLabels: ['Atonal musikk'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_populaermusikk',
                    deactivated: false,
                    label: 'Populærmusikk',
                    alternativeLabels: ['Band', 'Salsa'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_blues',
                        deactivated: false,
                        label: 'Blues',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_country',
                        deactivated: false,
                        label: 'Country',
                        alternativeLabels: [
                          'Bluegrass',
                          'Country & western',
                          'Countrymusikk',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_elektronika',
                        deactivated: false,
                        label: 'Elektronika',
                        alternativeLabels: ['Elektronisk musikk'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_jazz',
                        deactivated: false,
                        label: 'Jazz',
                        alternativeLabels: ['Dixie', 'Swing'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_popogrock',
                        deactivated: false,
                        label: 'Pop og rock',
                        alternativeLabels: [
                          'Beat',
                          'Black metal',
                          'Countryrock',
                          'Dance',
                          'Disco',
                          'Europop',
                          'Folkrock',
                          'Hard rock',
                          'Heavy metal',
                          'House',
                          'Indiepop',
                          'Indierock',
                          'Metal',
                          'New wave',
                          'Pop',
                          'Popmusikk',
                          'Progressiv rock',
                          'Progrock',
                          'Rave',
                          'Rock',
                          'Rock & roll',
                          'Tekno',
                          'Trash metal',
                          'Tungrock',
                          'Viserock',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_punk',
                        deactivated: false,
                        label: 'Punk',
                        alternativeLabels: ['Punkrock'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_rapoghiphop',
                        deactivated: false,
                        label: 'Rap og hiphop',
                        alternativeLabels: ['Hip-hop', 'Hiphop', 'Rap'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_reggae',
                        deactivated: false,
                        label: 'Reggae',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_rythm&blues',
                        deactivated: false,
                        label: 'Rythm & blues',
                        alternativeLabels: ['Funk', 'Soul'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_underholdningsmusikk',
                        deactivated: false,
                        label: 'Underholdningsmusikk',
                        alternativeLabels: ['Dansemusikk', 'Gammeldans'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_religioesmusikk',
                    deactivated: false,
                    label: 'Religiøs musikk',
                    alternativeLabels: ['Gospel', 'Spiritual'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_vokalmusikk',
                    deactivated: false,
                    label: 'Vokalmusikk',
                    alternativeLabels: ['Sang', 'Stemmebruk'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_kor',
                        deactivated: false,
                        label: 'Kor',
                        alternativeLabels: ['Sangkor'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_musikkforstaaelse',
                deactivated: false,
                label: 'Musikkforståelse',
                alternativeLabels: ['Musikkopplevelse', 'Verkanalyser'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_musikkframfoering',
                deactivated: false,
                label: 'Musikkframføring',
                alternativeLabels: ['Dirigering'],

                related: [],
                children: [
                  {
                    id: 'emneord_festivaler',
                    deactivated: false,
                    label: 'Festivaler',
                    alternativeLabels: ['Musikkfestivaler'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_konserter',
                    deactivated: false,
                    label: 'Konserter',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_orkestere',
                    deactivated: false,
                    label: 'Orkestere',
                    alternativeLabels: [
                      'Kammerorkestere',
                      'Storband',
                      'Symfoniorkestere',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_musikkorps',
                        deactivated: false,
                        label: 'Musikkorps',
                        alternativeLabels: [
                          'Brassband',
                          'Janitsjarkorps',
                          'Korps',
                          'Skolekorps',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_musikkgrupper',
                deactivated: false,
                label: 'Musikkgrupper',
                alternativeLabels: ['Band', 'Popgrupper', 'Rockegrupper'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_musikkhistorie',
                deactivated: false,
                label: 'Musikkhistorie',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_musikkinstrumenter',
                deactivated: false,
                label: 'Musikkinstrumenter',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_blaaseinstrumenter',
                    deactivated: false,
                    label: 'Blåseinstrumenter',
                    alternativeLabels: [
                      'Fløyter',
                      'Horn',
                      'Klarinetter',
                      'Trompeter',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_slaginstrumenter',
                    deactivated: false,
                    label: 'Slaginstrumenter',
                    alternativeLabels: ['Pauker', 'Slagverk', 'Trommer'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_strengeinstrumenter',
                    deactivated: false,
                    label: 'Strengeinstrumenter',
                    alternativeLabels: ['Celloer', 'Harper', 'Kontrabasser'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_fioliner',
                        deactivated: false,
                        label: 'Fioliner',
                        alternativeLabels: ['Feler', 'Hardingfeler'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_gitarer',
                        deactivated: false,
                        label: 'Gitarer',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_tangentinstrumenter',
                    deactivated: false,
                    label: 'Tangentinstrumenter',
                    alternativeLabels: ['Keyboard', 'Orgel', 'Trekkspill'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_piano',
                        deactivated: false,
                        label: 'Piano',
                        alternativeLabels: ['Klaver'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_musikkmiljoeer',
                deactivated: false,
                label: 'Musikkmiljøer',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_musikkteori',
                deactivated: false,
                label: 'Musikkteori',
                alternativeLabels: ['Harmonilære', 'Kontrapunkt', 'Melodilære'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_notasjonognotelaere',
                deactivated: false,
                label: 'Notasjon og notelære',
                alternativeLabels: ['Notelære'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_scenekunst',
            deactivated: false,
            label: 'Scenekunst',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_dans',
                deactivated: false,
                label: 'Dans',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_ballett',
                    deactivated: false,
                    label: 'Ballett',
                    alternativeLabels: [
                      'Jazzballett',
                      'Klassisk dans',
                      'Moderne dans',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_folkedans',
                    deactivated: false,
                    label: 'Folkedans',
                    alternativeLabels: ['Etniske danser', 'Folkeviseleik'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_dragshow',
                deactivated: false,
                label: 'Dragshow',
                alternativeLabels: ['Drag'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_film',
                deactivated: false,
                label: 'Film',
                alternativeLabels: [
                  'Filmfotografering',
                  'Filmhistorie',
                  'Filmkunst',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_video',
                    deactivated: false,
                    label: 'Video',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_kino',
                deactivated: false,
                label: 'Kino',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_koreografi',
                deactivated: false,
                label: 'Koreografi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_kostymer',
                deactivated: false,
                label: 'Kostymer',
                alternativeLabels: ['Drakter', 'Masker', 'Teatersminke'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_kulisser',
                deactivated: false,
                label: 'Kulisser',
                alternativeLabels: ['Lyssetting'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_opera',
                deactivated: false,
                label: 'Opera',
                alternativeLabels: ['Operette'],

                related: [],
                children: [
                  {
                    id: 'emneord_musikaler',
                    deactivated: false,
                    label: 'Musikaler',
                    alternativeLabels: ['Musicaler'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_regi',
                deactivated: false,
                label: 'Regi',
                alternativeLabels: ['Instruksjon', 'Iscenesetting'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_scenekunstnere',
                deactivated: false,
                label: 'Scenekunstnere',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_dansere',
                    deactivated: false,
                    label: 'Dansere',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_komikere',
                    deactivated: false,
                    label: 'Komikere',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_skuespillere',
                    deactivated: false,
                    label: 'Skuespillere',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_sirkus',
                deactivated: false,
                label: 'Sirkus',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_skuespillerkunst',
                deactivated: false,
                label: 'Skuespillerkunst',
                alternativeLabels: ['Mimekunst', 'Pantomime'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_standup',
                deactivated: false,
                label: 'Standup',
                alternativeLabels: ['Stand up', 'Stand-up'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_teater',
                deactivated: false,
                label: 'Teater',
                alternativeLabels: [
                  'Drama',
                  'Komedieteater',
                  'Lystspill',
                  'Revyer',
                  'Revyteater',
                  'Tragedier',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_barneteater',
                    deactivated: false,
                    label: 'Barneteater',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_dukketeater',
                    deactivated: false,
                    label: 'Dukketeater',
                    alternativeLabels: ['Figurteater'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_teaterkritikk',
                    deactivated: false,
                    label: 'Teaterkritikk',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'emneord_matogdrikke',
        deactivated: false,
        label: 'Mat og drikke',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'emneord_bakverk',
            deactivated: false,
            label: 'Bakverk',
            alternativeLabels: ['Baking', 'Lefser'],

            related: [],
            children: [
              {
                id: 'emneord_broed',
                deactivated: false,
                label: 'Brød',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_kaker',
                deactivated: false,
                label: 'Kaker',
                alternativeLabels: ['Småkaker'],

                related: [],
                children: [
                  {
                    id: 'emneord_kakepynt',
                    deactivated: false,
                    label: 'Kakepynt',
                    alternativeLabels: ['Kakedekorering', 'Kakeglasur'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_muffins',
                    deactivated: false,
                    label: 'Muffins',
                    alternativeLabels: ['Cakepops', 'Cupcakes'],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_barnemat',
            deactivated: false,
            label: 'Barnemat',
            alternativeLabels: ['Spedbarnsmat', 'Sykekost'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_desserter',
            deactivated: false,
            label: 'Desserter',
            alternativeLabels: ['Fruktdessert', 'Nøtter'],

            related: [],
            children: [
              {
                id: 'emneord_iskrem',
                deactivated: false,
                label: 'Iskrem',
                alternativeLabels: ['Sorbeter'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_drikkevarer',
            deactivated: false,
            label: 'Drikkevarer',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_alkoholfriedrikker',
                deactivated: false,
                label: 'Alkoholfrie drikker',
                alternativeLabels: ['Kakao', 'Saft'],

                related: [],
                children: [
                  {
                    id: 'emneord_juice',
                    deactivated: false,
                    label: 'Juice',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kaffe',
                    deactivated: false,
                    label: 'Kaffe',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_smoothier',
                    deactivated: false,
                    label: 'Smoothier',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_te',
                    deactivated: false,
                    label: 'Te',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_alkoholholdigedrikker',
                deactivated: false,
                label: 'Alkoholholdige drikker',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_brennevin',
                    deactivated: false,
                    label: 'Brennevin',
                    alternativeLabels: ['Dram', 'Sprit'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_akevitt',
                        deactivated: false,
                        label: 'Akevitt',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_drinker',
                        deactivated: false,
                        label: 'Drinker',
                        alternativeLabels: ['Cocktails', 'Longdrinks'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_whisky',
                        deactivated: false,
                        label: 'Whisky',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_oel',
                    deactivated: false,
                    label: 'Øl',
                    alternativeLabels: ['Hjemmebrygging', 'Mjød'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_oelbrygging',
                        deactivated: false,
                        label: 'Ølbrygging',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_vin',
                    deactivated: false,
                    label: 'Vin',
                    alternativeLabels: [
                      'Champagne',
                      'Hetvin',
                      'Hvitvin',
                      'Rødvin',
                      'Vinlegging',
                      'Vinproduksjon',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_eggeretter',
            deactivated: false,
            label: 'Eggeretter',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_fiskeretter',
            deactivated: false,
            label: 'Fiskeretter',
            alternativeLabels: ['Rekemat', 'Sjømat', 'Skalldyr'],

            related: [],
            children: [
              {
                id: 'emneord_sushi',
                deactivated: false,
                label: 'Sushi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_godteri',
            deactivated: false,
            label: 'Godteri',
            alternativeLabels: [
              'Drops',
              'Slikkeri',
              'Snop',
              'Sukkertøy',
              'Søtsaker',
            ],

            related: [],
            children: [
              {
                id: 'emneord_sjokolade',
                deactivated: false,
                label: 'Sjokolade',
                alternativeLabels: ['Konfekt'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_grilling',
            deactivated: false,
            label: 'Grilling',
            alternativeLabels: ['Barbeque'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_groennsakretter',
            deactivated: false,
            label: 'Grønnsakretter',
            alternativeLabels: ['Rotfrukter'],

            related: [],
            children: [
              {
                id: 'emneord_salater',
                deactivated: false,
                label: 'Salater',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_hjemmekonservering',
            deactivated: false,
            label: 'Hjemmekonservering',
            alternativeLabels: [
              'Dypfrysing',
              'Frysing',
              'Hermetisering',
              'Røykemat',
              'Safting',
              'Syltetøy',
              'Sylting',
              'Tørkekonservering',
            ],

            related: [],
            children: [],
          },
          {
            id: 'emneord_honning',
            deactivated: false,
            label: 'Honning',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_kjoettretter',
            deactivated: false,
            label: 'Kjøttretter',
            alternativeLabels: ['Kjøtt', 'Partering av slakt'],

            related: [],
            children: [
              {
                id: 'emneord_kyllingretter',
                deactivated: false,
                label: 'Kyllingretter',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_viltretter',
                deactivated: false,
                label: 'Viltretter',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_krydder',
            deactivated: false,
            label: 'Krydder',
            alternativeLabels: ['Hvitløk', 'Smakstilsetninger', 'Urter'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_luftfrityrkoking',
            deactivated: false,
            label: 'Luftfrityrkoking',
            alternativeLabels: ['Airfryer'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_matpakker',
            deactivated: false,
            label: 'Matpakker',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_mikroboelgeovnretter',
            deactivated: false,
            label: 'Mikrobølgeovnretter',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_ost',
            deactivated: false,
            label: 'Ost',
            alternativeLabels: ['Osteretter'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_pasta',
            deactivated: false,
            label: 'Pasta',
            alternativeLabels: ['Nudler', 'Spagetti'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_pizza',
            deactivated: false,
            label: 'Pizza',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_risretter',
            deactivated: false,
            label: 'Risretter',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_sauser',
            deactivated: false,
            label: 'Sauser',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_smaaretter',
            deactivated: false,
            label: 'Småretter',
            alternativeLabels: [
              'Forretter',
              'Koldtbord',
              'Meze',
              'Omeletter',
              'Smørbrød',
            ],

            related: [],
            children: [
              {
                id: 'emneord_tapas',
                deactivated: false,
                label: 'Tapas',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_supper',
            deactivated: false,
            label: 'Supper',
            alternativeLabels: ['Buljong', 'Sodd'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_taco',
            deactivated: false,
            label: 'Taco',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_turmat',
            deactivated: false,
            label: 'Turmat',
            alternativeLabels: ['Utendørs matlaging'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_veganretter',
            deactivated: false,
            label: 'Veganretter',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_vegetarretter',
            deactivated: false,
            label: 'Vegetarretter',
            alternativeLabels: [],

            related: [
              {
                id: 'emneord_helsekost',
                deactivated: false,
                label: 'Helsekost',
              },
            ],
            children: [],
          },
          {
            id: 'emneord_wokretter',
            deactivated: false,
            label: 'Wokretter',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'emneord_medisinoghelse',
        deactivated: false,
        label: 'Medisin og helse',
        alternativeLabels: ['Legevitenskap', 'Medisinske fag'],

        related: [],
        children: [
          {
            id: 'emneord_alternativmedisin',
            deactivated: false,
            label: 'Alternativ medisin',
            alternativeLabels: ['Fargeterapi'],

            related: [],
            children: [
              {
                id: 'emneord_akupunktur',
                deactivated: false,
                label: 'Akupunktur',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_healing',
                deactivated: false,
                label: 'Healing',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_helsekost',
                deactivated: false,
                label: 'Helsekost',
                alternativeLabels: ['Organisk mat'],

                related: [
                  {
                    id: 'emneord_vegetarretter',
                    deactivated: false,
                    label: 'Vegetarretter',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_homeopati',
                deactivated: false,
                label: 'Homeopati',
                alternativeLabels: ['Homøopati'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_kinesiologi',
                deactivated: false,
                label: 'Kinesiologi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_kiropraktikk',
                deactivated: false,
                label: 'Kiropraktikk',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_meditasjon',
                deactivated: false,
                label: 'Meditasjon',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_yoga',
                    deactivated: false,
                    label: 'Yoga',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_naturmedisin',
                deactivated: false,
                label: 'Naturmedisin',
                alternativeLabels: [
                  'Folkemedisin',
                  'Medisinske planter',
                  'Plantemedisin',
                  'Urtemedisin',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_religioesterapi',
                deactivated: false,
                label: 'Religiøs terapi',
                alternativeLabels: ['Helbredelse ved bønn'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_soneterapi',
                deactivated: false,
                label: 'Soneterapi',
                alternativeLabels: ['Fotsoneterapi', 'Refleksologi'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_leger',
            deactivated: false,
            label: 'Leger',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_medisin',
            deactivated: false,
            label: 'Medisin',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_forebyggendemedisin',
                deactivated: false,
                label: 'Forebyggende medisin',
                alternativeLabels: [
                  'Helsekontroll',
                  'Preventiv medisin',
                  'Smittevern',
                  'Vaksinasjoner',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_folkehelse',
                    deactivated: false,
                    label: 'Folkehelse',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_epidemiologi',
                        deactivated: false,
                        label: 'Epidemiologi',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_epidemier',
                            deactivated: false,
                            label: 'Epidemier',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_pandemier',
                            deactivated: false,
                            label: 'Pandemier',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_fysikalskmedisin',
                deactivated: false,
                label: 'Fysikalsk medisin',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_helse',
                deactivated: false,
                label: 'Helse',
                alternativeLabels: ['Personlig helse'],

                related: [],
                children: [
                  {
                    id: 'emneord_aromaterapi',
                    deactivated: false,
                    label: 'Aromaterapi',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_ernaering',
                    deactivated: false,
                    label: 'Ernæring',
                    alternativeLabels: ['Ernæringslære', 'Kosthold'],

                    related: [
                      {
                        id: 'emneord_diett',
                        deactivated: false,
                        label: 'Diett',
                      },
                    ],
                    children: [
                      {
                        id: 'emneord_lavkarbo',
                        deactivated: false,
                        label: 'Lavkarbo',
                        alternativeLabels: ['LCHF'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_slanking',
                        deactivated: false,
                        label: 'Slanking',
                        alternativeLabels: ['Kalorier'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_vegetarianisme',
                        deactivated: false,
                        label: 'Vegetarianisme',
                        alternativeLabels: ['Veganisme'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_funksjonsnedsettelser',
                    deactivated: false,
                    label: 'Funksjonsnedsettelser',
                    alternativeLabels: [
                      'Funksjonshemmede',
                      'Funksjonshemninger',
                      'Nedsatt funksjonsevne',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_bevegelseshemmede',
                        deactivated: false,
                        label: 'Bevegelseshemmede',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_blinde',
                        deactivated: false,
                        label: 'Blinde',
                        alternativeLabels: ['Blindhet'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_doeve',
                        deactivated: false,
                        label: 'Døve',
                        alternativeLabels: ['Døvhet', 'Hørselshemmede'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_psykiskutviklingshemmede',
                        deactivated: false,
                        label: 'Psykisk utviklingshemmede',
                        alternativeLabels: ['Downs syndrom'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_synshemmede',
                        deactivated: false,
                        label: 'Synshemmede',
                        alternativeLabels: ['Briller'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_inneklima',
                    deactivated: false,
                    label: 'Inneklima',
                    alternativeLabels: ['Bolighygiene', 'Innemiljø'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_overvekt',
                    deactivated: false,
                    label: 'Overvekt',
                    alternativeLabels: ['Fedme'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_personligutseende',
                    deactivated: false,
                    label: 'Personlig utseende',
                    alternativeLabels: ['Personlig hygiene'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_haaroghaarfrisyrer',
                        deactivated: false,
                        label: 'Hår og hårfrisyrer',
                        alternativeLabels: [
                          'Fletter',
                          'Hårfletter',
                          'Hårpleie',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kosmetikk',
                        deactivated: false,
                        label: 'Kosmetikk',
                        alternativeLabels: ['Skjønnhetspleie'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_hudpleie',
                            deactivated: false,
                            label: 'Hudpleie',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_parfymer',
                            deactivated: false,
                            label: 'Parfymer',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_sminke',
                            deactivated: false,
                            label: 'Sminke',
                            alternativeLabels: ['Makeup', 'Sminking'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_modeller',
                        deactivated: false,
                        label: 'Modeller',
                        alternativeLabels: ['Mannekenger'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_piercing',
                        deactivated: false,
                        label: 'Piercing',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_tatoveringer',
                        deactivated: false,
                        label: 'Tatoveringer',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_kirurgi',
                deactivated: false,
                label: 'Kirurgi',
                alternativeLabels: [
                  'Anestesi',
                  'Anestesiologi',
                  'Kosmetisk kirurgi',
                  'Lobotomi',
                  'Plastisk kirurgi',
                  'Transplantasjoner',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_barnekirurgi',
                    deactivated: false,
                    label: 'Barnekirurgi',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_kliniskmedisin',
                deactivated: false,
                label: 'Klinisk medisin',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_diagnose',
                    deactivated: false,
                    label: 'Diagnose',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_diett',
                    deactivated: false,
                    label: 'Diett',
                    alternativeLabels: ['Dietikk', 'Diettlære'],

                    related: [
                      {
                        id: 'emneord_ernaering',
                        deactivated: false,
                        label: 'Ernæring',
                      },
                    ],
                    children: [
                      {
                        id: 'emneord_glutenfrikost',
                        deactivated: false,
                        label: 'Glutenfri kost',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_lavfodmap-dietten',
                        deactivated: false,
                        label: 'LavFODMAP-dietten',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_ergoterapi',
                    deactivated: false,
                    label: 'Ergoterapi',
                    alternativeLabels: ['Arbeidsterapi'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_farmasi',
                    deactivated: false,
                    label: 'Farmasi',
                    alternativeLabels: ['Farmakologi'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_legemidler',
                        deactivated: false,
                        label: 'Legemidler',
                        alternativeLabels: ['Medikamenter', 'Medisiner'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_foerstehjelp',
                    deactivated: false,
                    label: 'Førstehjelp',
                    alternativeLabels: ['Akutt medisin'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_fotterapi',
                    deactivated: false,
                    label: 'Fotterapi',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_fysioterapi',
                    deactivated: false,
                    label: 'Fysioterapi',
                    alternativeLabels: [
                      'Fysikalsk behandling',
                      'Mensendieck',
                      'Sykegymnastikk',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_massasje',
                        deactivated: false,
                        label: 'Massasje',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_rehabilitering',
                    deactivated: false,
                    label: 'Rehabilitering',
                    alternativeLabels: ['Etterbehandling'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_smerter',
                    deactivated: false,
                    label: 'Smerter',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_smertebehandling',
                        deactivated: false,
                        label: 'Smertebehandling',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_palliativpleie',
                            deactivated: false,
                            label: 'Palliativ pleie',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_sykepleie',
                    deactivated: false,
                    label: 'Sykepleie',
                    alternativeLabels: ['Sykepleiere'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_helsesykepleiere',
                        deactivated: false,
                        label: 'Helsesykepleiere',
                        alternativeLabels: ['Helsebrødre', 'Helsesøstre'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_hjemmesykepleie',
                        deactivated: false,
                        label: 'Hjemmesykepleie',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_jordmoedre',
                        deactivated: false,
                        label: 'Jordmødre',
                        alternativeLabels: ['Jordmortjeneste'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_palliativpleie',
                        deactivated: false,
                        label: 'Palliativ pleie',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_smertebehandling',
                            deactivated: false,
                            label: 'Smertebehandling',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_medisinskespesialomraader',
                deactivated: false,
                label: 'Medisinske spesialområder',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_andrologi',
                    deactivated: false,
                    label: 'Andrologi',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_foedsler',
                    deactivated: false,
                    label: 'Fødsler',
                    alternativeLabels: ['Obstetrikk'],

                    related: [
                      {
                        id: 'emneord_svangerskap',
                        deactivated: false,
                        label: 'Svangerskap',
                      },
                    ],
                    children: [
                      {
                        id: 'emneord_prematurfoedsel',
                        deactivated: false,
                        label: 'Prematur fødsel',
                        alternativeLabels: [
                          'For tidlig fødte barn',
                          'Neonatalmedisin',
                          'Premature barn',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_geriatri',
                    deactivated: false,
                    label: 'Geriatri',
                    alternativeLabels: ['Alderdomssykdommer'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_alderspsykiatri',
                        deactivated: false,
                        label: 'Alderspsykiatri',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_gynekologi',
                    deactivated: false,
                    label: 'Gynekologi',
                    alternativeLabels: ['Kvinnesykdommer'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_menstruasjon',
                        deactivated: false,
                        label: 'Menstruasjon',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_idrettsmedisin',
                    deactivated: false,
                    label: 'Idrettsmedisin',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_idrettsskader',
                        deactivated: false,
                        label: 'Idrettsskader',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'emneord_nevrologi',
                    deactivated: false,
                    label: 'Nevrologi',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_nervesykdommer',
                        deactivated: false,
                        label: 'Nervesykdommer',
                      },
                    ],
                    children: [
                      {
                        id: 'emneord_nevroutviklingsforstyrrelser',
                        deactivated: false,
                        label: 'Nevroutviklingsforstyrrelser',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_adhd',
                            deactivated: false,
                            label: 'ADHD',
                            alternativeLabels: ['AD-HD'],

                            related: [
                              {
                                id: 'emneord_atferdsproblemer',
                                deactivated: false,
                                label: 'Atferdsproblemer',
                              },
                            ],
                            children: [],
                          },
                          {
                            id: 'emneord_autismespekterforstyrrelser',
                            deactivated: false,
                            label: 'Autismespekterforstyrrelser',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_aspergersyndrom',
                                deactivated: false,
                                label: 'Asperger syndrom',
                                alternativeLabels: [
                                  'Asperger',
                                  'Aspergers syndrom',
                                ],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_autisme',
                                deactivated: false,
                                label: 'Autisme',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_tourettessyndrom',
                            deactivated: false,
                            label: 'Tourettes syndrom',
                            alternativeLabels: ['Tics'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_onkologi',
                    deactivated: false,
                    label: 'Onkologi',
                    alternativeLabels: ['Svulstlære'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_oere-,nese-,halssykdommer',
                    deactivated: false,
                    label: 'Øre-, nese-, halssykdommer',
                    alternativeLabels: [
                      'Halssykdommer',
                      'Nesesykdommer',
                      'Otorhinolaryngologi',
                      'Øresykdommer',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_tinnitus',
                        deactivated: false,
                        label: 'Tinnitus',
                        alternativeLabels: ['Øresus'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_oeyesykdommer',
                    deactivated: false,
                    label: 'Øyesykdommer',
                    alternativeLabels: ['Oftalmologi'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_pediatri',
                    deactivated: false,
                    label: 'Pediatri',
                    alternativeLabels: ['Barnemedisin'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_primaerhelsetjeneste',
                    deactivated: false,
                    label: 'Primærhelsetjeneste',
                    alternativeLabels: ['Allmennpraksis'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_rettsmedisin',
                    deactivated: false,
                    label: 'Rettsmedisin',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_rettspsykiatri',
                        deactivated: false,
                        label: 'Rettspsykiatri',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_tannhelse',
                    deactivated: false,
                    label: 'Tannhelse',
                    alternativeLabels: [
                      'Odontologi',
                      'Tannlegefag',
                      'Tannpleie',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_tenner',
                        deactivated: false,
                        label: 'Tenner',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_trafikkmedisin',
                    deactivated: false,
                    label: 'Trafikkmedisin',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_trafikkskader',
                        deactivated: false,
                        label: 'Trafikkskader',
                        alternativeLabels: [
                          'Nakkesleng',
                          'Trafikkulykker',
                          'Whiplash',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_medisinskforskning',
                deactivated: false,
                label: 'Medisinsk forskning',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_medisinskteknologi',
                deactivated: false,
                label: 'Medisinsk teknologi',
                alternativeLabels: [
                  'Høreapparater',
                  'Medisinsk utstyr',
                  'Nukleær medisin',
                  'Proteser',
                  'Røntgen',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_prekliniskmedisin',
                deactivated: false,
                label: 'Preklinisk medisin',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_anatomi',
                    deactivated: false,
                    label: 'Anatomi',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_menneskekroppen',
                        deactivated: false,
                        label: 'Menneskekroppen',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_blodet',
                            deactivated: false,
                            label: 'Blodet',
                            alternativeLabels: ['Blodomløpet'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_hjernen',
                            deactivated: false,
                            label: 'Hjernen',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_hjertet',
                            deactivated: false,
                            label: 'Hjertet',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_kjoennsorgan',
                            deactivated: false,
                            label: 'Kjønnsorgan',
                            alternativeLabels: ['Penis'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_fysiologi',
                    deactivated: false,
                    label: 'Fysiologi',
                    alternativeLabels: ['Fysiologiske tester'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_forplantning',
                        deactivated: false,
                        label: 'Forplantning',
                        alternativeLabels: ['Formering'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_fysiognomi',
                        deactivated: false,
                        label: 'Fysiognomi',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_fysiologiskutvikling',
                        deactivated: false,
                        label: 'Fysiologisk utvikling',
                        alternativeLabels: [
                          'Fysisk utvikling',
                          'Utviklingsbiologi',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_fosterutvikling',
                            deactivated: false,
                            label: 'Fosterutvikling',
                            alternativeLabels: ['Embryologi'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_vekst',
                            deactivated: false,
                            label: 'Vekst',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_kroppsfunksjoner',
                        deactivated: false,
                        label: 'Kroppsfunksjoner',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_fordoeyelse',
                            deactivated: false,
                            label: 'Fordøyelse',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_avfoering',
                                deactivated: false,
                                label: 'Avføring',
                                alternativeLabels: ['Bæsj', 'Bæsjing'],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_promping',
                                deactivated: false,
                                label: 'Promping',
                                alternativeLabels: ['Flatulens'],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_tissing',
                            deactivated: false,
                            label: 'Tissing',
                            alternativeLabels: ['Urin'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_livssyklus',
                        deactivated: false,
                        label: 'Livssyklus',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_aldring',
                            deactivated: false,
                            label: 'Aldring',
                            alternativeLabels: ['Gerontologi'],

                            related: [],
                            children: [
                              {
                                id: 'emneord_overgangsalder',
                                deactivated: false,
                                label: 'Overgangsalder',
                                alternativeLabels: ['Menopause'],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_doeden',
                            deactivated: false,
                            label: 'Døden',
                            alternativeLabels: [
                              'Nær døden-opplevelser',
                              'Udødelighet',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_svangerskap',
                        deactivated: false,
                        label: 'Svangerskap',
                        alternativeLabels: ['Graviditet'],

                        related: [
                          {
                            id: 'emneord_foedsler',
                            deactivated: false,
                            label: 'Fødsler',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_genetikk',
                    deactivated: false,
                    label: 'Genetikk',
                    alternativeLabels: ['Genteknologi'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_arvelighet',
                        deactivated: false,
                        label: 'Arvelighet',
                        alternativeLabels: ['Arvelære'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_genforskning',
                        deactivated: false,
                        label: 'Genforskning',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_mutasjoner',
                        deactivated: false,
                        label: 'Mutasjoner',
                        alternativeLabels: ['Biologisk forandring'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_reproduksjonsmedisin',
                    deactivated: false,
                    label: 'Reproduksjonsmedisin',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_infertilitetogassistertbefruktning',
                        deactivated: false,
                        label: 'Infertilitet og assistert befruktning',
                        alternativeLabels: [
                          'Assistert befruktning',
                          'Kunstig befruktning',
                          'Surrogati',
                        ],

                        related: [
                          {
                            id: 'emneord_barnloeshet',
                            deactivated: false,
                            label: 'Barnløshet',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_psykiatri',
                deactivated: false,
                label: 'Psykiatri',
                alternativeLabels: ['Klinisk psykologi'],

                related: [],
                children: [
                  {
                    id: 'emneord_psykiskelidelser',
                    deactivated: false,
                    label: 'Psykiske lidelser',
                    alternativeLabels: [
                      'Depresjoner',
                      'Nervøsitet',
                      'Psykiske problemer',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_barnepsykiatri',
                        deactivated: false,
                        label: 'Barnepsykiatri',
                        alternativeLabels: ['Ungdompsykiatri'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_bipolarelidelser',
                        deactivated: false,
                        label: 'Bipolare lidelser',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_nevroser',
                        deactivated: false,
                        label: 'Nevroser',
                        alternativeLabels: [
                          'Agorafobi',
                          'Fobier',
                          'Klaustrofobi',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_angst',
                            deactivated: false,
                            label: 'Angst',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_posttraumatiskstresslidelse',
                            deactivated: false,
                            label: 'Posttraumatisk stresslidelse',
                            alternativeLabels: ['PTSD'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_spiseforstyrrelser',
                            deactivated: false,
                            label: 'Spiseforstyrrelser',
                            alternativeLabels: [
                              'Anoreksi',
                              'Bulimi',
                              'Spisevegring',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_personlighetsforstyrrelser',
                        deactivated: false,
                        label: 'Personlighetsforstyrrelser',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_borderline',
                            deactivated: false,
                            label: 'Borderline',
                            alternativeLabels: [
                              'Emosjonelt ustabil personlighetsforstyrrelse',
                              'Grensepsykoser',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_psykopati',
                            deactivated: false,
                            label: 'Psykopati',
                            alternativeLabels: [
                              'Dyssosial personlighetsforstyrrelse',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_psykoser',
                        deactivated: false,
                        label: 'Psykoser',
                        alternativeLabels: [
                          'Melankoli',
                          'Paranoia',
                          'Psykotiske lidelser',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_schizofreni',
                            deactivated: false,
                            label: 'Schizofreni',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_psykosomatiskelidelser',
                        deactivated: false,
                        label: 'Psykosomatiske lidelser',
                        alternativeLabels: ['Psykosomatiske sykdommer'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_selvskading',
                        deactivated: false,
                        label: 'Selvskading',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_traumer',
                        deactivated: false,
                        label: 'Traumer',
                        alternativeLabels: ['Traumatisering'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_psykoterapi',
                    deactivated: false,
                    label: 'Psykoterapi',
                    alternativeLabels: [
                      'Psykiatrisk behandling',
                      'Psykologiske metoder',
                    ],
                    note: 'Her: bruk av psykologiske metoder i behandling',
                    related: [],
                    children: [
                      {
                        id: 'emneord_danse-ogbevegelsesterapi',
                        deactivated: false,
                        label: 'Danse- og bevegelsesterapi',
                        alternativeLabels: ['Danseterapi'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_familieterapi',
                        deactivated: false,
                        label: 'Familieterapi',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_parterapi',
                            deactivated: false,
                            label: 'Parterapi',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_gruppeterapi',
                        deactivated: false,
                        label: 'Gruppeterapi',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_krisepsykiatri',
                        deactivated: false,
                        label: 'Krisepsykiatri',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kroppsterapi',
                        deactivated: false,
                        label: 'Kroppsterapi',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_miljoeterapi',
                        deactivated: false,
                        label: 'Miljøterapi',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_musikkterapi',
                        deactivated: false,
                        label: 'Musikkterapi',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_psykoanalyse',
                        deactivated: false,
                        label: 'Psykoanalyse',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_psykodrama',
                        deactivated: false,
                        label: 'Psykodrama',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_samtaleterapi',
                        deactivated: false,
                        label: 'Samtaleterapi',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_seksualitet',
                deactivated: false,
                label: 'Seksualitet',
                alternativeLabels: [
                  'Kjønnsliv',
                  'Masturbasjon',
                  'Onani',
                  'Orgasme',
                  'Samlivslære',
                  'Sex',
                  'Sexologi',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_seksuellevariasjoner',
                    deactivated: false,
                    label: 'Seksuelle variasjoner',
                    alternativeLabels: [
                      'Eksibisjonisme',
                      'Fetishisme',
                      'Kikking',
                      'Sadomasochisme',
                      'Transvestittisme',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_seksuelllegning',
                    deactivated: false,
                    label: 'Seksuell legning',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_heterofili',
                        deactivated: false,
                        label: 'Heterofili',
                        alternativeLabels: ['Heteroseksualitet'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_homofili',
                        deactivated: false,
                        label: 'Homofili',
                        alternativeLabels: [
                          'Biseksualitet',
                          'Homoseksualitet',
                          'Lesbiskhet',
                        ],

                        related: [
                          {
                            id: 'emneord_lhbt',
                            deactivated: false,
                            label: 'LHBT',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_utroskap',
                    deactivated: false,
                    label: 'Utroskap',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_ekteskapogparforhold',
                        deactivated: false,
                        label: 'Ekteskap og parforhold',
                      },
                      {
                        id: 'emneord_skilsmisse',
                        deactivated: false,
                        label: 'Skilsmisse',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_skader',
                deactivated: false,
                label: 'Skader',
                alternativeLabels: ['Personskader'],

                related: [],
                children: [
                  {
                    id: 'emneord_hjerneskader',
                    deactivated: false,
                    label: 'Hjerneskader',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_hodeskader',
                    deactivated: false,
                    label: 'Hodeskader',
                    alternativeLabels: ['Hodesmerter'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_idrettsskader',
                    deactivated: false,
                    label: 'Idrettsskader',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_idrettsmedisin',
                        deactivated: false,
                        label: 'Idrettsmedisin',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'emneord_saarbehandling',
                    deactivated: false,
                    label: 'Sårbehandling',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_sosialmedisin',
                deactivated: false,
                label: 'Sosialmedisin',
                alternativeLabels: ['Samfunnsmedisin'],

                related: [],
                children: [
                  {
                    id: 'emneord_foedselskontroll',
                    deactivated: false,
                    label: 'Fødselskontroll',
                    alternativeLabels: [
                      'Befolkningskontroll',
                      'Familieplanlegging',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_abort',
                        deactivated: false,
                        label: 'Abort',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_prevensjon',
                        deactivated: false,
                        label: 'Prevensjon',
                        alternativeLabels: [
                          'Barnebegrensning',
                          'Familieplanlegging',
                          'P-piller',
                          'Sterilisering',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_sykdommer',
                deactivated: false,
                label: 'Sykdommer',
                alternativeLabels: ['Patologi'],

                related: [],
                children: [
                  {
                    id: 'emneord_allergi',
                    deactivated: false,
                    label: 'Allergi',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_avhengighet',
                    deactivated: false,
                    label: 'Avhengighet',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_alkoholisme',
                        deactivated: false,
                        label: 'Alkoholisme',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_alkohol',
                            deactivated: false,
                            label: 'Alkohol',
                          },
                          {
                            id: 'emneord_alkoholmisbruk',
                            deactivated: false,
                            label: 'Alkoholmisbruk',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'emneord_narkomani',
                        deactivated: false,
                        label: 'Narkomani',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_narkotika',
                            deactivated: false,
                            label: 'Narkotika',
                          },
                          {
                            id: 'emneord_narkotikamisbruk',
                            deactivated: false,
                            label: 'Narkotikamisbruk',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'emneord_spilleavhengighet',
                        deactivated: false,
                        label: 'Spilleavhengighet',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_barnesykdommer',
                    deactivated: false,
                    label: 'Barnesykdommer',
                    alternativeLabels: [
                      'Kusma',
                      'Meslinger',
                      'Røde hunder',
                      'Vannkopper',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_blodsykdommer',
                    deactivated: false,
                    label: 'Blodsykdommer',
                    alternativeLabels: ['Lymfesykdommer'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_demenssykdommer',
                    deactivated: false,
                    label: 'Demenssykdommer',
                    alternativeLabels: [
                      'Aldersdemens',
                      'Alzheimers sykdom',
                      'Demens',
                      'Senilitet',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_ernaeringssykdommer',
                    deactivated: false,
                    label: 'Ernæringssykdommer',
                    alternativeLabels: ['Feilernæring'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_coeliaki',
                        deactivated: false,
                        label: 'Cøliaki',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_fordoeyelsessykdommer',
                    deactivated: false,
                    label: 'Fordøyelsessykdommer',
                    alternativeLabels: [
                      'Crohns sykdom',
                      'Magesykdommer',
                      'Tarmsykdommer',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_hiv',
                    deactivated: false,
                    label: 'HIV',
                    alternativeLabels: ['Aids'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_hjerte-ogkarsykdommer',
                    deactivated: false,
                    label: 'Hjerte- og karsykdommer',
                    alternativeLabels: ['Hjertelidelser', 'Karsykdommer'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_hjerneslag',
                        deactivated: false,
                        label: 'Hjerneslag',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_hjerteinfarkt',
                        deactivated: false,
                        label: 'Hjerteinfarkt',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_hjertesykdommer',
                        deactivated: false,
                        label: 'Hjertesykdommer',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_hodepine',
                    deactivated: false,
                    label: 'Hodepine',
                    alternativeLabels: ['Migrene'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_hudsykdommer',
                    deactivated: false,
                    label: 'Hudsykdommer',
                    alternativeLabels: [
                      'Eksem',
                      'Hudlidelser',
                      'Kviser',
                      'Psoriasis',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_infeksjonssykdommer',
                    deactivated: false,
                    label: 'Infeksjonssykdommer',
                    alternativeLabels: [
                      'Candidiasis',
                      'Forgiftninger',
                      'Matforgiftninger',
                      'Soppinfeksjoner',
                      'Virussykdommer',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_influensa',
                        deactivated: false,
                        label: 'Influensa',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_forkjoelelse',
                            deactivated: false,
                            label: 'Forkjølelse',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_kjoennssykdommer',
                        deactivated: false,
                        label: 'Kjønnssykdommer',
                        alternativeLabels: [
                          'Gonore',
                          'Herpes',
                          'Syfilis',
                          'Veneriske sykdommer',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_koronavirussykdommer',
                        deactivated: false,
                        label: 'Koronavirussykdommer',
                        alternativeLabels: [
                          'Coronavirussykdommer',
                          'Covid-19',
                          'Koronavirus-sykdommer',
                          'Mers',
                          'Sars',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_pest',
                        deactivated: false,
                        label: 'Pest',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_svartedauden',
                            deactivated: false,
                            label: 'Svartedauden',
                            alternativeLabels: [],
                            note: 'Her: pestepidemi i Asia og Europa på midten av 1300-tallet',
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_spedalskhet',
                        deactivated: false,
                        label: 'Spedalskhet',
                        alternativeLabels: ['Lepra'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_tuberkulose',
                        deactivated: false,
                        label: 'Tuberkulose',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_kreft',
                    deactivated: false,
                    label: 'Kreft',
                    alternativeLabels: [
                      'Blodkreft',
                      'Cancer',
                      'Leukemi',
                      'Lungekreft',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kroniskesykdommer',
                    deactivated: false,
                    label: 'Kroniske sykdommer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_leversykdommer',
                    deactivated: false,
                    label: 'Leversykdommer',
                    alternativeLabels: ['Nyresykdommer'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_luftveissykdommer',
                    deactivated: false,
                    label: 'Luftveissykdommer',
                    alternativeLabels: ['Bronkitt'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_astma',
                        deactivated: false,
                        label: 'Astma',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_lungesykdommer',
                    deactivated: false,
                    label: 'Lungesykdommer',
                    alternativeLabels: ['Lungebetennelse'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_muskel-ogskjelettsykdommer',
                    deactivated: false,
                    label: 'Muskel- og skjelettsykdommer',
                    alternativeLabels: [
                      'Benskjørhet',
                      'Fibromyalgi',
                      'Fibrositt',
                      'Gikt',
                      'Isjias',
                      'Leddgikt',
                      'Lumbago',
                      'Muskelsykdommer',
                      'Myalgia encephalomeylitis',
                      'Osteoporose',
                      'Revmatisme',
                      'Rygglidelser',
                      'Skjelettsykdommer',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_nervesykdommer',
                    deactivated: false,
                    label: 'Nervesykdommer',
                    alternativeLabels: ['ALS', 'Amyotrofisk lateralsklerose'],

                    related: [
                      {
                        id: 'emneord_nevrologi',
                        deactivated: false,
                        label: 'Nevrologi',
                      },
                    ],
                    children: [
                      {
                        id: 'emneord_cerebralparese',
                        deactivated: false,
                        label: 'Cerebral parese',
                        alternativeLabels: ['CP'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_epilepsi',
                        deactivated: false,
                        label: 'Epilepsi',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kroniskutmattelsessyndrom',
                        deactivated: false,
                        label: 'Kronisk utmattelsessyndrom',
                        alternativeLabels: ['ME', 'myalgisk encefalomyelopati'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_multippelsklerose',
                        deactivated: false,
                        label: 'Multippel sklerose',
                        alternativeLabels: ['Ms'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_parkinsonssykdom',
                        deactivated: false,
                        label: 'Parkinsons sykdom',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_stoffskiftesykdommer',
                    deactivated: false,
                    label: 'Stoffskiftesykdommer',
                    alternativeLabels: ['Indresekretoriske sykdommer'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_diabetes',
                        deactivated: false,
                        label: 'Diabetes',
                        alternativeLabels: ['Sukkersyke'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_foellingssykdom',
                        deactivated: false,
                        label: 'Føllings sykdom',
                        alternativeLabels: ['PKU'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_urinveissykdommer',
                    deactivated: false,
                    label: 'Urinveissykdommer',
                    alternativeLabels: [
                      'Inkontinens',
                      'Ufrivillig vannlatning',
                      'Urologi',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'emneord_naturvitenskap',
        deactivated: false,
        label: 'Naturvitenskap',
        alternativeLabels: ['Livets opprinnelse', 'Naturfag', 'Realfag'],

        related: [],
        children: [
          {
            id: 'emneord_astronomi',
            deactivated: false,
            label: 'Astronomi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_teoretiskastrofysikk',
                deactivated: false,
                label: 'Teoretisk astrofysikk',
                alternativeLabels: ['Astrofysikk', 'Pulsarer', 'Svarte hull'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_universet',
                deactivated: false,
                label: 'Universet',
                alternativeLabels: [
                  'Galakser',
                  'Kosmologi',
                  'Kosmos',
                  'Kvasarer',
                  'Melkeveien',
                  'Verdensrommet',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_kometer',
                    deactivated: false,
                    label: 'Kometer',
                    alternativeLabels: ['Haleys komet'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_maanen',
                    deactivated: false,
                    label: 'Månen',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_meteorer',
                    deactivated: false,
                    label: 'Meteorer',
                    alternativeLabels: ['Meteoritter'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_planetene',
                    deactivated: false,
                    label: 'Planetene',
                    alternativeLabels: [
                      'Jupiter',
                      'Merkur',
                      'Neptun',
                      'Pluto',
                      'Saturn',
                      'Venus',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_jorden',
                        deactivated: false,
                        label: 'Jorden',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_mars',
                        deactivated: false,
                        label: 'Mars',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_sola',
                    deactivated: false,
                    label: 'Sola',
                    alternativeLabels: ['Solen', 'Solflekker'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_stjerner',
                    deactivated: false,
                    label: 'Stjerner',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_biologi',
            deactivated: false,
            label: 'Biologi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_biokjemi',
                deactivated: false,
                label: 'Biokjemi',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_biosubstanser',
                    deactivated: false,
                    label: 'Biosubstanser',
                    alternativeLabels: ['Enzymer'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_hormoner',
                        deactivated: false,
                        label: 'Hormoner',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_proteiner',
                        deactivated: false,
                        label: 'Proteiner',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_vitaminer',
                        deactivated: false,
                        label: 'Vitaminer',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_stoffskifte',
                    deactivated: false,
                    label: 'Stoffskifte',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_cellebiologi',
                deactivated: false,
                label: 'Cellebiologi',
                alternativeLabels: ['Cytologi'],

                related: [],
                children: [
                  {
                    id: 'emneord_celler',
                    deactivated: false,
                    label: 'Celler',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kromosomer',
                    deactivated: false,
                    label: 'Kromosomer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_immunologi',
                deactivated: false,
                label: 'Immunologi',
                alternativeLabels: [
                  'Antistoffer',
                  'Immunitet',
                  'Immunsystemer',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_livsprosesser',
                deactivated: false,
                label: 'Livsprosesser',
                alternativeLabels: ['Liv'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_marinbiologi',
                deactivated: false,
                label: 'Marinbiologi',
                alternativeLabels: ['Livet i havet'],

                related: [
                  {
                    id: 'emneord_fisk',
                    deactivated: false,
                    label: 'Fisk',
                  },
                  {
                    id: 'emneord_havogsjoeer',
                    deactivated: false,
                    label: 'Hav og sjøer',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_mikrobiologi',
                deactivated: false,
                label: 'Mikrobiologi',
                alternativeLabels: ['Mikroorganismer'],

                related: [],
                children: [
                  {
                    id: 'emneord_bakterier',
                    deactivated: false,
                    label: 'Bakterier',
                    alternativeLabels: ['Bakteriologi'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_virus',
                    deactivated: false,
                    label: 'Virus',
                    alternativeLabels: ['Virologi'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_parasitter',
                deactivated: false,
                label: 'Parasitter',
                alternativeLabels: ['Parasittologi'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_utviklingslaeren',
                deactivated: false,
                label: 'Utviklingslæren',
                alternativeLabels: [
                  'Darwinisme',
                  'Evolusjon',
                  'Lamarckisme',
                  'Utviklingslære',
                ],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_botanikk',
            deactivated: false,
            label: 'Botanikk',
            alternativeLabels: [
              'Plantegeografi',
              'Planteliv',
              'Plantelære',
              'Planter',
            ],

            related: [],
            children: [
              {
                id: 'emneord_blomsterplanter',
                deactivated: false,
                label: 'Blomsterplanter',
                alternativeLabels: [
                  'Dikotyledoner',
                  'Fanerogamer',
                  'Monotyledoner',
                ],

                related: [
                  {
                    id: 'emneord_blomsterbed',
                    deactivated: false,
                    label: 'Blomsterbed',
                  },
                  {
                    id: 'emneord_stueplanter',
                    deactivated: false,
                    label: 'Stueplanter',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_plantefysiologi',
                deactivated: false,
                label: 'Plantefysiologi',
                alternativeLabels: ['Fotosyntese'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_spiseligeplanter',
                deactivated: false,
                label: 'Spiselige planter',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_sporeplanter',
                deactivated: false,
                label: 'Sporeplanter',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_alger',
                    deactivated: false,
                    label: 'Alger',
                    alternativeLabels: ['Tang'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_karsporeplanter',
                    deactivated: false,
                    label: 'Karsporeplanter',
                    alternativeLabels: ['Bregner', 'Pteridaphyta'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_lav',
                    deactivated: false,
                    label: 'Lav',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_moser',
                    deactivated: false,
                    label: 'Moser',
                    alternativeLabels: ['Bryophyta', 'Musci'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sopp',
                    deactivated: false,
                    label: 'Sopp',
                    alternativeLabels: ['Mykologi'],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_fysikk',
            deactivated: false,
            label: 'Fysikk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_akustikk',
                deactivated: false,
                label: 'Akustikk',
                alternativeLabels: ['Akustiske fenomener', 'Lydlære'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_elektrisitet',
                deactivated: false,
                label: 'Elektrisitet',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_kjernefysikk',
                deactivated: false,
                label: 'Kjernefysikk',
                alternativeLabels: [
                  'Atomfysikk',
                  'Fisjon',
                  'Fusjon',
                  'Molekylfysikk',
                  'Radioaktivitet',
                  'Stråling',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_magnetisme',
                deactivated: false,
                label: 'Magnetisme',
                alternativeLabels: ['Elektromagnetisme'],

                related: [],
                children: [
                  {
                    id: 'emneord_nordlys',
                    deactivated: false,
                    label: 'Nordlys',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_mekanikk',
                deactivated: false,
                label: 'Mekanikk',
                alternativeLabels: [
                  'Aeromekanikk',
                  'Dynamikk',
                  'Gravitasjon',
                  'Hydraulikk',
                  'Hydromekanikk',
                  'Statikk',
                  'Vakuum',
                  'Væsker',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_optikk',
                deactivated: false,
                label: 'Optikk',
                alternativeLabels: ['Lyslære'],

                related: [],
                children: [
                  {
                    id: 'emneord_farger',
                    deactivated: false,
                    label: 'Farger',
                    alternativeLabels: ['Fargelære'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_teoretiskfysikk',
                deactivated: false,
                label: 'Teoretisk fysikk',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_fysiskeenheter',
                    deactivated: false,
                    label: 'Fysiske enheter',
                    alternativeLabels: ['Enhetssystemer', 'SI-systemet'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kvantemekanikk',
                    deactivated: false,
                    label: 'Kvantemekanikk',
                    alternativeLabels: ['Kvantefysikk'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_relativitetsteori',
                    deactivated: false,
                    label: 'Relativitetsteori',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_varmelaere',
                deactivated: false,
                label: 'Varmelære',
                alternativeLabels: ['Termodynamikk'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_geologi',
            deactivated: false,
            label: 'Geologi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_bergarter',
                deactivated: false,
                label: 'Bergarter',
                alternativeLabels: ['Petrologi', 'Steiner'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_glasiologi',
                deactivated: false,
                label: 'Glasiologi',
                alternativeLabels: ['Breer', 'Havis', 'Isbreer', 'Isfjell'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_landmaaling',
                deactivated: false,
                label: 'Landmåling',
                alternativeLabels: ['Kartografi', 'Kartprojeksjoner'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_landskapsformer',
                deactivated: false,
                label: 'Landskapsformer',
                alternativeLabels: ['Landformasjoner'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_meteorologi',
                deactivated: false,
                label: 'Meteorologi',
                alternativeLabels: ['Lokalmeteorlogi', 'Mikrometeorlogi'],

                related: [],
                children: [
                  {
                    id: 'emneord_klimatologi',
                    deactivated: false,
                    label: 'Klimatologi',
                    alternativeLabels: ['Klima', 'Klimasoner', 'Tropene'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_klimaendringer',
                        deactivated: false,
                        label: 'Klimaendringer',
                        alternativeLabels: [
                          'Drivhuseffekten',
                          'Global oppvarming',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_vaeret',
                    deactivated: false,
                    label: 'Været',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_nedboer',
                        deactivated: false,
                        label: 'Nedbør',
                        alternativeLabels: ['Hydrometeorologi'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_regn',
                            deactivated: false,
                            label: 'Regn',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_snoe',
                            deactivated: false,
                            label: 'Snø',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_vind',
                        deactivated: false,
                        label: 'Vind',
                        alternativeLabels: [
                          'Bris',
                          'Kuling',
                          'Luftstrømmer',
                          'Orkan',
                          'Storm',
                          'Sykloner',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_mineraler',
                deactivated: false,
                label: 'Mineraler',
                alternativeLabels: ['Diamanter', 'Mineralogi'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_naturkatastrofer',
                deactivated: false,
                label: 'Naturkatastrofer',
                alternativeLabels: ['Naturkrefter'],

                related: [],
                children: [
                  {
                    id: 'emneord_flodboelger',
                    deactivated: false,
                    label: 'Flodbølger',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_tsunamier',
                        deactivated: false,
                        label: 'Tsunamier',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'emneord_flommer',
                    deactivated: false,
                    label: 'Flommer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_jordskjelv',
                    deactivated: false,
                    label: 'Jordskjelv',
                    alternativeLabels: ['Seismologi'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_skred',
                    deactivated: false,
                    label: 'Skred',
                    alternativeLabels: ['Lavine', 'Ras'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_snoeskred',
                        deactivated: false,
                        label: 'Snøskred',
                        alternativeLabels: ['Snøras'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_tsunamier',
                    deactivated: false,
                    label: 'Tsunamier',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_flodboelger',
                        deactivated: false,
                        label: 'Flodbølger',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_paleontologi',
                deactivated: false,
                label: 'Paleontologi',
                alternativeLabels: ['Fossiler'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_vannforskning',
                deactivated: false,
                label: 'Vannforskning',
                alternativeLabels: ['Hydrologi'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_vulkaner',
                deactivated: false,
                label: 'Vulkaner',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_kjemi',
            deactivated: false,
            label: 'Kjemi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_analytiskkjemi',
                deactivated: false,
                label: 'Analytisk kjemi',
                alternativeLabels: ['Kromatografi'],

                related: [],
                children: [
                  {
                    id: 'emneord_organiskkjemi',
                    deactivated: false,
                    label: 'Organisk kjemi',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_uorganiskkjemi',
                    deactivated: false,
                    label: 'Uorganisk kjemi',
                    alternativeLabels: ['Baser', 'Syrer'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_vann',
                        deactivated: false,
                        label: 'Vann',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_fysikalskkjemi',
                deactivated: false,
                label: 'Fysikalsk kjemi',
                alternativeLabels: ['Kjemiske reaksjoner', 'Reaksjoner'],

                related: [],
                children: [
                  {
                    id: 'emneord_elektrokjemi',
                    deactivated: false,
                    label: 'Elektrokjemi',
                    alternativeLabels: ['Halvledere'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_fotokjemi',
                    deactivated: false,
                    label: 'Fotokjemi',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_termokjemi',
                    deactivated: false,
                    label: 'Termokjemi',
                    alternativeLabels: ['Forbrenning'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_stoffer',
                deactivated: false,
                label: 'Stoffer',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_gasser',
                    deactivated: false,
                    label: 'Gasser',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_grunnstoffer',
                    deactivated: false,
                    label: 'Grunnstoffer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_rusmidler',
                    deactivated: false,
                    label: 'Rusmidler',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_alkohol',
                        deactivated: false,
                        label: 'Alkohol',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_alkoholisme',
                            deactivated: false,
                            label: 'Alkoholisme',
                          },
                          {
                            id: 'emneord_alkoholmisbruk',
                            deactivated: false,
                            label: 'Alkoholmisbruk',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'emneord_narkotika',
                        deactivated: false,
                        label: 'Narkotika',
                        alternativeLabels: ['Hasj', 'Narkotiske stoffer'],

                        related: [
                          {
                            id: 'emneord_narkomani',
                            deactivated: false,
                            label: 'Narkomani',
                          },
                          {
                            id: 'emneord_narkotikamisbruk',
                            deactivated: false,
                            label: 'Narkotikamisbruk',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_teoretiskkjemi',
                deactivated: false,
                label: 'Teoretisk kjemi',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_atomstrukturer',
                    deactivated: false,
                    label: 'Atomstrukturer',
                    alternativeLabels: ['Molekylstrukturer'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_toksikologi',
                deactivated: false,
                label: 'Toksikologi',
                alternativeLabels: [
                  'Løsemidler',
                  'Miljøtoksikologi',
                  'Toksikodynamikk',
                  'Toksikokinetikk',
                  'Toxikologi',
                  'Yrkestoksikologi',
                ],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_matematikk',
            deactivated: false,
            label: 'Matematikk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_algebra',
                deactivated: false,
                label: 'Algebra',
                alternativeLabels: ['Bokstavregning'],

                related: [],
                children: [
                  {
                    id: 'emneord_ligninger',
                    deactivated: false,
                    label: 'Ligninger',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_logaritmer',
                    deactivated: false,
                    label: 'Logaritmer',
                    alternativeLabels: ['Logaritmetabeller'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_matriser',
                    deactivated: false,
                    label: 'Matriser',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_tallteori',
                    deactivated: false,
                    label: 'Tallteori',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_vektorregning',
                    deactivated: false,
                    label: 'Vektorregning',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_aritmetikk',
                deactivated: false,
                label: 'Aritmetikk',
                alternativeLabels: ['Praktisk regning', 'Regning'],

                related: [],
                children: [
                  {
                    id: 'emneord_tall',
                    deactivated: false,
                    label: 'Tall',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_geometri',
                deactivated: false,
                label: 'Geometri',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_analytiskgeometri',
                    deactivated: false,
                    label: 'Analytisk geometri',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_trigonometri',
                        deactivated: false,
                        label: 'Trigonometri',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_deskriptivgeometri',
                    deactivated: false,
                    label: 'Deskriptiv geometri',
                    alternativeLabels: ['Beskrivende geometri'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_projeksjonstegning',
                        deactivated: false,
                        label: 'Projeksjonstegning',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_tegning',
                            deactivated: false,
                            label: 'Tegning',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_geometriskeformer',
                    deactivated: false,
                    label: 'Geometriske former',
                    alternativeLabels: ['Former'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_grafteori',
                deactivated: false,
                label: 'Grafteori',
                alternativeLabels: ['Grafer'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_kombinatorikk',
                deactivated: false,
                label: 'Kombinatorikk',
                alternativeLabels: ['Kombinasjoner', 'Permutasjoner'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_matematiskanalyse',
                deactivated: false,
                label: 'Matematisk analyse',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_differensialregning',
                    deactivated: false,
                    label: 'Differensialregning',
                    alternativeLabels: ['Integralregning'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_funksjonslaere',
                    deactivated: false,
                    label: 'Funksjonslære',
                    alternativeLabels: ['Funksjoner'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_matematisklogikk',
                deactivated: false,
                label: 'Matematisk logikk',
                alternativeLabels: ['Symbolsk logikk'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_mengdelaere',
                deactivated: false,
                label: 'Mengdelære',
                alternativeLabels: ['Boolesk algebra'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_sannsynlighetsberegning',
                deactivated: false,
                label: 'Sannsynlighetsberegning',
                alternativeLabels: ['Spillteori'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_statistikk',
                deactivated: false,
                label: 'Statistikk',
                alternativeLabels: ['Regresjonsanalyse'],
                note: 'Her: Statistikk som emne',
                related: [],
                children: [],
              },
              {
                id: 'emneord_topologi',
                deactivated: false,
                label: 'Topologi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_miljoevern',
            deactivated: false,
            label: 'Miljøvern',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_forurensning',
                deactivated: false,
                label: 'Forurensning',
                alternativeLabels: ['Miljøforurensning', 'Naturforurensning'],

                related: [],
                children: [
                  {
                    id: 'emneord_jordforurensning',
                    deactivated: false,
                    label: 'Jordforurensning',
                    alternativeLabels: ['Erosjon'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_luftforurensning',
                    deactivated: false,
                    label: 'Luftforurensning',
                    alternativeLabels: ['Sur nedbør'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_stoey',
                    deactivated: false,
                    label: 'Støy',
                    alternativeLabels: ['Lydforurensning'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_vannforurensning',
                    deactivated: false,
                    label: 'Vannforurensning',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_naturressurser',
                deactivated: false,
                label: 'Naturressurser',
                alternativeLabels: [
                  'Fornybare ressurser',
                  'Jordvern',
                  'Lagerressurser',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_naturvern',
                deactivated: false,
                label: 'Naturvern',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_dyrevern',
                    deactivated: false,
                    label: 'Dyrevern',
                    alternativeLabels: [
                      'Dyrebeskyttelse',
                      'Dyrevelferd',
                      'Dyreøkologi',
                      'Truede dyrearter',
                      'Viltstell',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_nasjonalparker',
                    deactivated: false,
                    label: 'Nasjonalparker',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_naturreservater',
                    deactivated: false,
                    label: 'Naturreservater',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_oekologi',
                deactivated: false,
                label: 'Økologi',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_oekologiskbalanse',
                    deactivated: false,
                    label: 'Økologisk balanse',
                    alternativeLabels: ['Naturbalanse', 'Økosfære'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_oekosystemer',
                    deactivated: false,
                    label: 'Økosystemer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_naturhistorie',
            deactivated: false,
            label: 'Naturhistorie',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_tidsregning',
            deactivated: false,
            label: 'Tidsregning',
            alternativeLabels: ['Kronologi'],

            related: [],
            children: [
              {
                id: 'emneord_aarstider',
                deactivated: false,
                label: 'Årstider',
                alternativeLabels: ['Mørketid', 'Året'],

                related: [],
                children: [
                  {
                    id: 'emneord_hoest',
                    deactivated: false,
                    label: 'Høst',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sommer',
                    deactivated: false,
                    label: 'Sommer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_vaar',
                    deactivated: false,
                    label: 'Vår',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_vinter',
                    deactivated: false,
                    label: 'Vinter',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_tid',
                deactivated: false,
                label: 'Tid',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_zoologi',
            deactivated: false,
            label: 'Zoologi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_dyr',
                deactivated: false,
                label: 'Dyr',
                alternativeLabels: ['Dyreliv', 'Fauna'],

                related: [],
                children: [
                  {
                    id: 'emneord_dyrelyder',
                    deactivated: false,
                    label: 'Dyrelyder',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_dyrespor',
                    deactivated: false,
                    label: 'Dyrespor',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_forhistoriskedyr',
                    deactivated: false,
                    label: 'Forhistoriske dyr',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_dinosaurer',
                        deactivated: false,
                        label: 'Dinosaurer',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_mammuter',
                        deactivated: false,
                        label: 'Mammuter',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_virveldyr',
                    deactivated: false,
                    label: 'Virveldyr',
                    alternativeLabels: ['Vertebrater'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_amfibier',
                        deactivated: false,
                        label: 'Amfibier',
                        alternativeLabels: ['Salamandre'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_frosker',
                            deactivated: false,
                            label: 'Frosker',
                            alternativeLabels: ['Padder'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_fisk',
                        deactivated: false,
                        label: 'Fisk',
                        alternativeLabels: ['Fisker', 'Ichtyologi'],

                        related: [
                          {
                            id: 'emneord_marinbiologi',
                            deactivated: false,
                            label: 'Marinbiologi',
                          },
                        ],
                        children: [
                          {
                            id: 'emneord_haier',
                            deactivated: false,
                            label: 'Haier',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_laks',
                            deactivated: false,
                            label: 'Laks',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_oerret',
                            deactivated: false,
                            label: 'Ørret',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_torsk',
                            deactivated: false,
                            label: 'Torsk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_fugler',
                        deactivated: false,
                        label: 'Fugler',
                        alternativeLabels: ['Ornitologi'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_andefugler',
                            deactivated: false,
                            label: 'Andefugler',
                            alternativeLabels: [
                              'Ender',
                              'Gjess',
                              'Gås',
                              'Svaner',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_hoensefugler',
                            deactivated: false,
                            label: 'Hønsefugler',
                            alternativeLabels: [
                              'Fasaner',
                              'Kalkuner',
                              'Påfugler',
                              'Tiur',
                            ],

                            related: [],
                            children: [
                              {
                                id: 'emneord_hoens',
                                deactivated: false,
                                label: 'Høns',
                                alternativeLabels: [
                                  'Eggproduksjon',
                                  'Haner',
                                  'Høner',
                                  'Hønserier',
                                  'Kyllinger',
                                ],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_ryper',
                                deactivated: false,
                                label: 'Ryper',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_pingviner',
                            deactivated: false,
                            label: 'Pingviner',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_rovfugler',
                            deactivated: false,
                            label: 'Rovfugler',
                            alternativeLabels: [
                              'Falker',
                              'Gribber',
                              'Hauker',
                              'Ugler',
                              'Ørner',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_spurvefugler',
                            deactivated: false,
                            label: 'Spurvefugler',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_kraakefamilien',
                                deactivated: false,
                                label: 'Kråkefamilien',
                                alternativeLabels: [],
                                related: [],
                                children: [
                                  {
                                    id: 'emneord_kaier',
                                    deactivated: false,
                                    label: 'Kaier',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'emneord_kraaker',
                                    deactivated: false,
                                    label: 'Kråker',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'emneord_ravner',
                                    deactivated: false,
                                    label: 'Ravner',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: 'emneord_stuefugler',
                            deactivated: false,
                            label: 'Stuefugler',
                            alternativeLabels: [
                              'Burfugler',
                              'Finker',
                              'Kanarifugler',
                            ],

                            related: [],
                            children: [
                              {
                                id: 'emneord_papegoeyer',
                                deactivated: false,
                                label: 'Papegøyer',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_parakitter',
                                deactivated: false,
                                label: 'Parakitter',
                                alternativeLabels: [],
                                related: [],
                                children: [
                                  {
                                    id: 'emneord_undulater',
                                    deactivated: false,
                                    label: 'Undulater',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: 'emneord_vade-,maake-ogalkefugler',
                            deactivated: false,
                            label: 'Vade-, måke- og alkefugler',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_alkefugler',
                                deactivated: false,
                                label: 'Alkefugler',
                                alternativeLabels: [
                                  'Alkefamilien',
                                  'Alker',
                                  'Lomvi',
                                ],

                                related: [],
                                children: [
                                  {
                                    id: 'emneord_lundefugler',
                                    deactivated: false,
                                    label: 'Lundefugler',
                                    alternativeLabels: ['Lunde', 'Lunder'],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'emneord_maakefugler',
                                deactivated: false,
                                label: 'Måkefugler',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_vadefugler',
                                deactivated: false,
                                label: 'Vadefugler',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'emneord_krypdyr',
                        deactivated: false,
                        label: 'Krypdyr',
                        alternativeLabels: ['Reptiler'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_krokodiller',
                            deactivated: false,
                            label: 'Krokodiller',
                            alternativeLabels: ['Alligatorer'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_oegler',
                            deactivated: false,
                            label: 'Øgler',
                            alternativeLabels: [
                              'Firfisler',
                              'Iguaner',
                              'Kameloner',
                              'Stålormer',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_skilpadder',
                            deactivated: false,
                            label: 'Skilpadder',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_slanger',
                            deactivated: false,
                            label: 'Slanger',
                            alternativeLabels: ['Ormer'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_pattedyr',
                        deactivated: false,
                        label: 'Pattedyr',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_aper',
                            deactivated: false,
                            label: 'Aper',
                            alternativeLabels: [
                              'Bavianer',
                              'Gorillaer',
                              'Orangutanger',
                              'Sjimpanser',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_beltedyr',
                            deactivated: false,
                            label: 'Beltedyr',
                            alternativeLabels: ['Maurslukere'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_elefanter',
                            deactivated: false,
                            label: 'Elefanter',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_flaggermus',
                            deactivated: false,
                            label: 'Flaggermus',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_gnagere',
                            deactivated: false,
                            label: 'Gnagere',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_bevere',
                                deactivated: false,
                                label: 'Bevere',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_ekorn',
                                deactivated: false,
                                label: 'Ekorn',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_hamstere',
                                deactivated: false,
                                label: 'Hamstere',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_marsvin',
                                deactivated: false,
                                label: 'Marsvin',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_mus',
                                deactivated: false,
                                label: 'Mus',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_rotter',
                                deactivated: false,
                                label: 'Rotter',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_harer',
                            deactivated: false,
                            label: 'Harer',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_hovdyr',
                            deactivated: false,
                            label: 'Hovdyr',
                            alternativeLabels: ['Neshorn'],

                            related: [],
                            children: [
                              {
                                id: 'emneord_esler',
                                deactivated: false,
                                label: 'Esler',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_hester',
                                deactivated: false,
                                label: 'Hester',
                                alternativeLabels: ['Hestestell', 'Ponnier'],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_sebraer',
                                deactivated: false,
                                label: 'Sebraer',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_insektetere',
                            deactivated: false,
                            label: 'Insektetere',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_muldvarper',
                                deactivated: false,
                                label: 'Muldvarper',
                                alternativeLabels: ['Moldvarper'],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_pinnsvin',
                                deactivated: false,
                                label: 'Pinnsvin',
                                alternativeLabels: ['Piggsvin'],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_kaniner',
                            deactivated: false,
                            label: 'Kaniner',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_kloakkdyr',
                            deactivated: false,
                            label: 'Kloakkdyr',
                            alternativeLabels: ['Maurpinnsvin', 'Nebbdyr'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_klovdyr',
                            deactivated: false,
                            label: 'Klovdyr',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_antiloper',
                                deactivated: false,
                                label: 'Antiloper',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_flodhester',
                                deactivated: false,
                                label: 'Flodhester',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_geiter',
                                deactivated: false,
                                label: 'Geiter',
                                alternativeLabels: ['Killinger'],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_griser',
                                deactivated: false,
                                label: 'Griser',
                                alternativeLabels: [
                                  'Svin',
                                  'Svineoppdrett',
                                  'Villsvin',
                                ],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_hjortedyr',
                                deactivated: false,
                                label: 'Hjortedyr',
                                alternativeLabels: [],
                                related: [],
                                children: [
                                  {
                                    id: 'emneord_daahjorter',
                                    deactivated: false,
                                    label: 'Dåhjorter',
                                    alternativeLabels: ['Dådyr'],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'emneord_elger',
                                    deactivated: false,
                                    label: 'Elger',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'emneord_hjorter',
                                    deactivated: false,
                                    label: 'Hjorter',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'emneord_raadyr',
                                    deactivated: false,
                                    label: 'Rådyr',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'emneord_reinsdyr',
                                    deactivated: false,
                                    label: 'Reinsdyr',
                                    alternativeLabels: ['Tamrein', 'Villrein'],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'emneord_kameler',
                                deactivated: false,
                                label: 'Kameler',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_kyr',
                                deactivated: false,
                                label: 'Kyr',
                                alternativeLabels: [
                                  'Kalver',
                                  'Kuer',
                                  'Okser',
                                  'Storfe',
                                ],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_sauer',
                                deactivated: false,
                                label: 'Sauer',
                                alternativeLabels: [
                                  'Lam',
                                  'Småfe',
                                  'Ullproduksjon',
                                ],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_sjiraffer',
                                deactivated: false,
                                label: 'Sjiraffer',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_pungdyr',
                            deactivated: false,
                            label: 'Pungdyr',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_kenguruer',
                                deactivated: false,
                                label: 'Kenguruer',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_koalaer',
                                deactivated: false,
                                label: 'Koalaer',
                                alternativeLabels: ['Koalabjørner'],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_rovdyr',
                            deactivated: false,
                            label: 'Rovdyr',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_bjoerner',
                                deactivated: false,
                                label: 'Bjørner',
                                alternativeLabels: ['Pandaer', 'Vaskebjørner'],

                                related: [],
                                children: [
                                  {
                                    id: 'emneord_isbjoerner',
                                    deactivated: false,
                                    label: 'Isbjørner',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'emneord_hundedyr',
                                deactivated: false,
                                label: 'Hundedyr',
                                alternativeLabels: [],
                                related: [],
                                children: [
                                  {
                                    id: 'emneord_hunder',
                                    deactivated: false,
                                    label: 'Hunder',
                                    alternativeLabels: [
                                      'Jakthunder',
                                      'Rasehunder',
                                    ],

                                    related: [],
                                    children: [
                                      {
                                        id: 'emneord_hundedressur',
                                        deactivated: false,
                                        label: 'Hundedressur',
                                        alternativeLabels: [],
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'emneord_rever',
                                    deactivated: false,
                                    label: 'Rever',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'emneord_ulver',
                                    deactivated: false,
                                    label: 'Ulver',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'emneord_kattedyr',
                                deactivated: false,
                                label: 'Kattedyr',
                                alternativeLabels: [
                                  'Gauper',
                                  'Geparder',
                                  'Leoparder',
                                  'Pantere',
                                ],

                                related: [],
                                children: [
                                  {
                                    id: 'emneord_katter',
                                    deactivated: false,
                                    label: 'Katter',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'emneord_loever',
                                    deactivated: false,
                                    label: 'Løver',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'emneord_tigere',
                                    deactivated: false,
                                    label: 'Tigere',
                                    alternativeLabels: ['Tigre'],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'emneord_maardyr',
                                deactivated: false,
                                label: 'Mårdyr',
                                alternativeLabels: [
                                  'Ildere',
                                  'Mink',
                                  'Mår',
                                  'Otere',
                                  'Røyskatter',
                                ],

                                related: [],
                                children: [
                                  {
                                    id: 'emneord_grevlinger',
                                    deactivated: false,
                                    label: 'Grevlinger',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'emneord_jerver',
                                    deactivated: false,
                                    label: 'Jerver',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: 'emneord_sjoepattedyr',
                            deactivated: false,
                            label: 'Sjøpattedyr',
                            alternativeLabels: ['Hvalrosser', 'Sjøkuer'],

                            related: [],
                            children: [
                              {
                                id: 'emneord_hvaler',
                                deactivated: false,
                                label: 'Hvaler',
                                alternativeLabels: ['Delfiner'],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_seler',
                                deactivated: false,
                                label: 'Seler',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_virvelloesedyr',
                    deactivated: false,
                    label: 'Virvelløse dyr',
                    alternativeLabels: ['Sjøstjerner'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_bloetdyr',
                        deactivated: false,
                        label: 'Bløtdyr',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_blekkspruter',
                            deactivated: false,
                            label: 'Blekkspruter',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_muslinger',
                            deactivated: false,
                            label: 'Muslinger',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_oesters',
                                deactivated: false,
                                label: 'Østers',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_snegler',
                            deactivated: false,
                            label: 'Snegler',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_edderkopper',
                        deactivated: false,
                        label: 'Edderkopper',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_insekter',
                        deactivated: false,
                        label: 'Insekter',
                        alternativeLabels: ['Biller'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_bier',
                            deactivated: false,
                            label: 'Bier',
                            alternativeLabels: ['Birøkt'],

                            related: [],
                            children: [
                              {
                                id: 'emneord_humler',
                                deactivated: false,
                                label: 'Humler',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_lus',
                            deactivated: false,
                            label: 'Lus',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_maur',
                            deactivated: false,
                            label: 'Maur',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_sommerfugler',
                            deactivated: false,
                            label: 'Sommerfugler',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_krepsdyr',
                        deactivated: false,
                        label: 'Krepsdyr',
                        alternativeLabels: ['Reker'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_hummere',
                            deactivated: false,
                            label: 'Hummere',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_krabber',
                            deactivated: false,
                            label: 'Krabber',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_maneter',
                        deactivated: false,
                        label: 'Maneter',
                        alternativeLabels: ['Brennmaneter'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_protoktister',
                        deactivated: false,
                        label: 'Protoktister',
                        alternativeLabels: [
                          'Tang',
                          'Amøber',
                          'Protister',
                          'Protozoer',
                          'Urdyr',
                        ],
                        note: 'her: encellede, eukaryote organismer, samt flercellede alger',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_zoologiskehager',
                    deactivated: false,
                    label: 'Zoologiske hager',
                    alternativeLabels: ['Dyrehager', 'Dyreparker'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_oekologiskzoologi',
                deactivated: false,
                label: 'Økologisk zoologi',
                alternativeLabels: ['Nyttedyr'],

                related: [],
                children: [
                  {
                    id: 'emneord_skadedyr',
                    deactivated: false,
                    label: 'Skadedyr',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'emneord_oekonomiogadministrasjon',
        deactivated: false,
        label: 'Økonomi og administrasjon',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'emneord_administrasjon',
            deactivated: false,
            label: 'Administrasjon',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_driftsutsetting',
                deactivated: false,
                label: 'Driftsutsetting',
                alternativeLabels: ['Outsourcing', 'Utsetting'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_effektivisering',
                deactivated: false,
                label: 'Effektivisering',
                alternativeLabels: ['Rasjonalisering'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_internkontroll',
                deactivated: false,
                label: 'Internkontroll',
                alternativeLabels: ['Intern kontroll'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_kontoradministrasjon',
                deactivated: false,
                label: 'Kontoradministrasjon',
                alternativeLabels: ['Handelskorrespondanse', 'Kontorteknikk'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_ledelse',
                deactivated: false,
                label: 'Ledelse',
                alternativeLabels: [
                  'Beslutningsteori',
                  'Ledelsesstil',
                  'Sjefsrollen',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_endringsledelse',
                    deactivated: false,
                    label: 'Endringsledelse',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kunnskapsledelse',
                    deactivated: false,
                    label: 'Kunnskapsledelse',
                    alternativeLabels: ['Knowledge management'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_moeteledelse',
                    deactivated: false,
                    label: 'Møteledelse',
                    alternativeLabels: ['Møteteknikk'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_relasjonsledelse',
                    deactivated: false,
                    label: 'Relasjonsledelse',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_styrearbeid',
                    deactivated: false,
                    label: 'Styrearbeid',
                    alternativeLabels: ['Bedriftsstyre'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_organisasjon',
                deactivated: false,
                label: 'Organisasjon',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_bedriftsorganisasjon',
                    deactivated: false,
                    label: 'Bedriftsorganisasjon',
                    alternativeLabels: [
                      'Fisjoner',
                      'Franchising',
                      'Fusjoner',
                      'Kjededrift',
                      'Sammenslutninger',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_bedriftsetablering',
                        deactivated: false,
                        label: 'Bedriftsetablering',
                        alternativeLabels: ['Etablering'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_bedriftshistorie',
                            deactivated: false,
                            label: 'Bedriftshistorie',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_bedriftstyper',
                        deactivated: false,
                        label: 'Bedriftstyper',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_aksjeselskaper',
                            deactivated: false,
                            label: 'Aksjeselskaper',
                            alternativeLabels: ['Konserner'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_multinasjonaleselskaper',
                            deactivated: false,
                            label: 'Multinasjonale selskaper',
                            alternativeLabels: ['Flernasjonale selskaper'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_organisasjonsteori',
                    deactivated: false,
                    label: 'Organisasjonsteori',
                    alternativeLabels: ['Organisasjonsstruktur'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_organisasjonsutvikling',
                    deactivated: false,
                    label: 'Organisasjonsutvikling',
                    alternativeLabels: ['OA', 'Organisasjonspsykologi'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_prosjektorganisasjon',
                    deactivated: false,
                    label: 'Prosjektorganisasjon',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_prosjektarbeid',
                        deactivated: false,
                        label: 'Prosjektarbeid',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_prosjektledelse',
                        deactivated: false,
                        label: 'Prosjektledelse',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_personaladministrasjon',
                deactivated: false,
                label: 'Personaladministrasjon',
                alternativeLabels: [
                  'HR',
                  'Personalarbeid',
                  'Personalforvaltning',
                  'Personalpolitikk',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_personaloekonomi',
                    deactivated: false,
                    label: 'Personaløkonomi',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_personalutvikling',
                    deactivated: false,
                    label: 'Personalutvikling',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_coaching',
                        deactivated: false,
                        label: 'Coaching',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_veiledning',
                            deactivated: false,
                            label: 'Veiledning',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'emneord_medarbeidersamtaler',
                        deactivated: false,
                        label: 'Medarbeidersamtaler',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_planlegging',
                deactivated: false,
                label: 'Planlegging',
                alternativeLabels: ['Strategisk planlegging', 'Styring'],

                related: [],
                children: [
                  {
                    id: 'emneord_tidsplanlegging',
                    deactivated: false,
                    label: 'Tidsplanlegging',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_markedsfoering',
            deactivated: false,
            label: 'Markedsføring',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_industriellmarkedsfoering',
                deactivated: false,
                label: 'Industriell markedsføring',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_markedsanalyse',
                deactivated: false,
                label: 'Markedsanalyse',
                alternativeLabels: [
                  'Kundepsykologi',
                  'Markedsbedømmelse',
                  'Markedsdata',
                  'Markedsforskning',
                  'Markedsinformasjon',
                  'Markedsvurdering',
                  'Trendforskning',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_reklame',
                deactivated: false,
                label: 'Reklame',
                alternativeLabels: ['Annonser', 'Direkte reklame', 'Sponsing'],

                related: [],
                children: [
                  {
                    id: 'emneord_utstillingsteknikk',
                    deactivated: false,
                    label: 'Utstillingsteknikk',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_relasjonsmarkedsfoering',
                deactivated: false,
                label: 'Relasjonsmarkedsføring',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_salgsteknikk',
                deactivated: false,
                label: 'Salgsteknikk',
                alternativeLabels: ['Direkte salg'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_oekonomi',
            deactivated: false,
            label: 'Økonomi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_bedriftsoekonomi',
                deactivated: false,
                label: 'Bedriftsøkonomi',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_finansadministrasjon',
                    deactivated: false,
                    label: 'Finansadministrasjon',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_bedriftsinvesteringer',
                        deactivated: false,
                        label: 'Bedriftsinvesteringer',
                        alternativeLabels: ['Investeringsanalyse'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_budsjettarbeid',
                        deactivated: false,
                        label: 'Budsjettarbeid',
                        alternativeLabels: ['Budsjettering'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_oekonomistyring',
                        deactivated: false,
                        label: 'Økonomistyring',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_logistikk',
                    deactivated: false,
                    label: 'Logistikk',
                    alternativeLabels: [
                      'Materialadministrasjon',
                      'Materialflyt',
                      'Materialstyring',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_regnskap',
                    deactivated: false,
                    label: 'Regnskap',
                    alternativeLabels: [
                      'Bokholderi',
                      'Driftsregnskap',
                      'Finansregnskap',
                      'Regnskapsanalyse',
                      'Resultatregnskap',
                      'Årsregnskap',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_revisjon',
                        deactivated: false,
                        label: 'Revisjon',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_oekonomer',
                deactivated: false,
                label: 'Økonomer',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_oekonomiskhistorie',
                deactivated: false,
                label: 'Økonomisk historie',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_personligoekonomi',
                deactivated: false,
                label: 'Personlig økonomi',
                alternativeLabels: ['Selvangivelse'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_sosialoekonomi',
                deactivated: false,
                label: 'Sosialøkonomi',
                alternativeLabels: ['Samfunnsøkonomi'],

                related: [],
                children: [
                  {
                    id: 'emneord_finans',
                    deactivated: false,
                    label: 'Finans',
                    alternativeLabels: ['Finansmiljøer'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_banker',
                        deactivated: false,
                        label: 'Banker',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_forretningsbanker',
                            deactivated: false,
                            label: 'Forretningsbanker',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_internasjonalebanker',
                            deactivated: false,
                            label: 'Internasjonale banker',
                            alternativeLabels: ['Verdensbanken'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_nasjonalbanker',
                            deactivated: false,
                            label: 'Nasjonalbanker',
                            alternativeLabels: ['Sentralbanker'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_postbanker',
                            deactivated: false,
                            label: 'Postbanker',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_sparebanker',
                            deactivated: false,
                            label: 'Sparebanker',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_statsbanker',
                            deactivated: false,
                            label: 'Statsbanker',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_forsikring',
                        deactivated: false,
                        label: 'Forsikring',
                        alternativeLabels: [
                          'Bilforsikring',
                          'Brannforsikring',
                          'Forsikringsselskaper',
                          'Tyveriforsikring',
                          'Ulykkesforsikring',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_investering',
                        deactivated: false,
                        label: 'Investering',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_boersvirksomhet',
                            deactivated: false,
                            label: 'Børsvirksomhet',
                            alternativeLabels: [
                              'Aksjekurser',
                              'Aksjer',
                              'Spekulasjon',
                              'Verdipapirer',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_kreditt',
                        deactivated: false,
                        label: 'Kreditt',
                        alternativeLabels: [
                          'Boligfinansiering',
                          'Kausjon',
                          'Lån',
                          'Utlån',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_renter',
                            deactivated: false,
                            label: 'Renter',
                            alternativeLabels: ['Rentepolitikk'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_penger',
                        deactivated: false,
                        label: 'Penger',
                        alternativeLabels: ['Betalingsmidler'],

                        related: [
                          {
                            id: 'emneord_rikdom',
                            deactivated: false,
                            label: 'Rikdom',
                          },
                        ],
                        children: [
                          {
                            id: 'emneord_kredittkort',
                            deactivated: false,
                            label: 'Kredittkort',
                            alternativeLabels: ['Betalingskort', 'Sjekker'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_kryptovaluta',
                            deactivated: false,
                            label: 'Kryptovaluta',
                            alternativeLabels: ['Bitcoin', 'Digital valuta'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_valuta',
                            deactivated: false,
                            label: 'Valuta',
                            alternativeLabels: ['Valutakurser'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_makrooekonomi',
                    deactivated: false,
                    label: 'Makroøkonomi',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_konjunkturer',
                        deactivated: false,
                        label: 'Konjunkturer',
                        alternativeLabels: ['Økonomiske kriser'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_nasjonalinntekt',
                        deactivated: false,
                        label: 'Nasjonalinntekt',
                        alternativeLabels: ['BNP', 'Bruttonasjonalprodukt'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_priser',
                        deactivated: false,
                        label: 'Priser',
                        alternativeLabels: [
                          'Konsumprisindeks',
                          'Levekostnader',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_mikrooekonomi',
                    deactivated: false,
                    label: 'Mikroøkonomi',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_offentligefinanser',
                    deactivated: false,
                    label: 'Offentlige finanser',
                    alternativeLabels: ['Kommunalfinanser', 'Kommunebudsjett'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_statsbudsjettet',
                        deactivated: false,
                        label: 'Statsbudsjettet',
                        alternativeLabels: ['Nasjonalbudsjett'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_statsinntekter',
                        deactivated: false,
                        label: 'Statsinntekter',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_avgifter',
                            deactivated: false,
                            label: 'Avgifter',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_merverdiavgift',
                                deactivated: false,
                                label: 'Merverdiavgift',
                                alternativeLabels: ['Moms'],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_oljeinntekter',
                            deactivated: false,
                            label: 'Oljeinntekter',
                            alternativeLabels: [
                              'Oljeøkonomi',
                              'Petroleumsøkonomi',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_skatter',
                            deactivated: false,
                            label: 'Skatter',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_eiendomsskatt',
                                deactivated: false,
                                label: 'Eiendomsskatt',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_inntektsskatt',
                                deactivated: false,
                                label: 'Inntektsskatt',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_oekonomiskesystemer',
                    deactivated: false,
                    label: 'Økonomiske systemer',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_blandingsoekonomi',
                        deactivated: false,
                        label: 'Blandingsøkonomi',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kapitalisme',
                        deactivated: false,
                        label: 'Kapitalisme',
                        alternativeLabels: ['Frihandelskapitalisme'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_markedsoekonomi',
                        deactivated: false,
                        label: 'Markedsøkonomi',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_planoekonomi',
                        deactivated: false,
                        label: 'Planøkonomi',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_oekonomiskteori',
                    deactivated: false,
                    label: 'Økonomisk teori',
                    alternativeLabels: [
                      'Keynesianisme',
                      'Monetarisme',
                      'Sosialøkonomiske skoler',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_utenrikshandel',
                    deactivated: false,
                    label: 'Utenrikshandel',
                    alternativeLabels: [
                      'Handelsboikott',
                      'Internasjonal handel',
                      'Verdenshandel',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_eksport',
                        deactivated: false,
                        label: 'Eksport',
                        alternativeLabels: ['Eksportrestriksjoner'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_handelsavtaler',
                        deactivated: false,
                        label: 'Handelsavtaler',
                        alternativeLabels: ['Frihandelsavtaler', 'Tollunioner'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_comecon',
                            deactivated: false,
                            label: 'Comecon',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_efta',
                            deactivated: false,
                            label: 'EFTA',
                            alternativeLabels: ['Europeiske frihandelsforbund'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_eoes',
                            deactivated: false,
                            label: 'EØS',
                            alternativeLabels: [
                              'Europeisk økonomisk samarbeidsområde',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_eu',
                            deactivated: false,
                            label: 'EU',
                            alternativeLabels: [
                              'EEC',
                              'EF',
                              'Europeiske fellesskap',
                              'Fellesmarkedet',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_import',
                        deactivated: false,
                        label: 'Import',
                        alternativeLabels: [
                          'Importforbud',
                          'Importkvoter',
                          'Importlisenser',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_toll',
                        deactivated: false,
                        label: 'Toll',
                        alternativeLabels: ['Tollsatser'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'emneord_psykologiogpedagogikk',
        deactivated: false,
        label: 'Psykologi og pedagogikk',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'emneord_pedagogikk',
            deactivated: false,
            label: 'Pedagogikk',
            alternativeLabels: ['Undervisning'],

            related: [],
            children: [
              {
                id: 'emneord_barnehagepedagogikk',
                deactivated: false,
                label: 'Barnehagepedagogikk',
                alternativeLabels: ['Førskolepedagogikk'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_barnehager',
                deactivated: false,
                label: 'Barnehager',
                alternativeLabels: ['Familiebarnehager', 'Førskoler'],

                related: [],
                children: [
                  {
                    id: 'emneord_barnehageadministrasjon',
                    deactivated: false,
                    label: 'Barnehageadministrasjon',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_begrepstrening',
                deactivated: false,
                label: 'Begrepstrening',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_flerkulturellpedagogikk',
                deactivated: false,
                label: 'Flerkulturell pedagogikk',
                alternativeLabels: ['Migrasjonspedagogikk'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_lese-ogskriveopplaering',
                deactivated: false,
                label: 'Lese- og skriveopplæring',
                alternativeLabels: [
                  'Leseopplæring',
                  'Regneopplæring',
                  'Skriveopplæring',
                ],

                related: [
                  {
                    id: 'emneord_lesing',
                    deactivated: false,
                    label: 'Lesing',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_pedagoger',
                deactivated: false,
                label: 'Pedagoger',
                alternativeLabels: ['Barnehagelærere', 'Lærere'],
                note: 'Her biografier om lærere',
                related: [
                  {
                    id: 'emneord_laererrollen',
                    deactivated: false,
                    label: 'Lærerrollen',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_pedagogiskeretninger',
                deactivated: false,
                label: 'Pedagogiske retninger',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_montessoripedagogikk',
                    deactivated: false,
                    label: 'Montessoripedagogikk',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_privatskoler',
                        deactivated: false,
                        label: 'Privatskoler',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'emneord_steinerpedagogikk',
                    deactivated: false,
                    label: 'Steinerpedagogikk',
                    alternativeLabels: [
                      'Rudolf Steinerpedagogikk',
                      'Waldorfpedagogikk',
                    ],

                    related: [
                      {
                        id: 'emneord_privatskoler',
                        deactivated: false,
                        label: 'Privatskoler',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_pedagogiskfilosofi',
                deactivated: false,
                label: 'Pedagogisk filosofi',
                alternativeLabels: [
                  'Pedagogiske skoler',
                  'Pedagogiske systemer',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_pedagogiskhistorie',
                deactivated: false,
                label: 'Pedagogisk historie',
                alternativeLabels: ['Skolehistorie', 'Undervisningshistorie'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_pedagogiskpsykologi',
                deactivated: false,
                label: 'Pedagogisk psykologi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_sosialpedagogikk',
                deactivated: false,
                label: 'Sosialpedagogikk',
                alternativeLabels: ['Utdanningssosiologi'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_spesialpedagogikk',
                deactivated: false,
                label: 'Spesialpedagogikk',
                alternativeLabels: ['Spesialundervisning'],

                related: [],
                children: [
                  {
                    id: 'emneord_laerevansker',
                    deactivated: false,
                    label: 'Lærevansker',
                    alternativeLabels: ['Generelle lærevansker'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_matematikkvansker',
                        deactivated: false,
                        label: 'Matematikkvansker',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_dyskalkuli',
                            deactivated: false,
                            label: 'Dyskalkuli',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_spraakvansker',
                        deactivated: false,
                        label: 'Språkvansker',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_afasi',
                            deactivated: false,
                            label: 'Afasi',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_lese-ogskrivevansker',
                            deactivated: false,
                            label: 'Lese- og skrivevansker',
                            alternativeLabels: ['Lesevansker', 'Skrivevansker'],

                            related: [],
                            children: [
                              {
                                id: 'emneord_dysleksi',
                                deactivated: false,
                                label: 'Dysleksi',
                                alternativeLabels: ['Ordblindhet'],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'emneord_talevansker',
                        deactivated: false,
                        label: 'Talevansker',
                        alternativeLabels: [
                          'Løpsk tale',
                          'Talefeil',
                          'Taleflytvansker',
                          'Talestimulering',
                        ],

                        related: [
                          {
                            id: 'emneord_logopedi',
                            deactivated: false,
                            label: 'Logopedi',
                          },
                        ],
                        children: [
                          {
                            id: 'emneord_mutisme',
                            deactivated: false,
                            label: 'Mutisme',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_stamming',
                            deactivated: false,
                            label: 'Stamming',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_logopedi',
                    deactivated: false,
                    label: 'Logopedi',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_talevansker',
                        deactivated: false,
                        label: 'Talevansker',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_undervisningsmetodikk',
                deactivated: false,
                label: 'Undervisningsmetodikk',
                alternativeLabels: ['Didaktikk', 'Metodikk'],

                related: [],
                children: [
                  {
                    id: 'emneord_digitallaering',
                    deactivated: false,
                    label: 'Digital læring',
                    alternativeLabels: [
                      'Datastøttet undervisning',
                      'Digital kompetanse',
                    ],

                    related: [
                      {
                        id: 'emneord_digitalisering',
                        deactivated: false,
                        label: 'Digitalisering',
                      },
                      {
                        id: 'emneord_laeremidler',
                        deactivated: false,
                        label: 'Læremidler',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'emneord_gruppeundervisning',
                    deactivated: false,
                    label: 'Gruppeundervisning',
                    alternativeLabels: ['Gruppearbeid'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_prosjektundervisning',
                    deactivated: false,
                    label: 'Prosjektundervisning',
                    alternativeLabels: ['PBL', 'Problembasert læring'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_samarbeidslaering',
                    deactivated: false,
                    label: 'Samarbeidslæring',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_utdanning',
                deactivated: false,
                label: 'Utdanning',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_etter-ogvidereutdanning',
                    deactivated: false,
                    label: 'Etter- og videreutdanning',
                    alternativeLabels: ['Etterutdanning', 'Videreutdanning'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_mesterproeven',
                    deactivated: false,
                    label: 'Mesterprøven',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_profesjonsutdanning',
                    deactivated: false,
                    label: 'Profesjonsutdanning',
                    alternativeLabels: ['Profesjonskvalifisering'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_raadgivning',
                    deactivated: false,
                    label: 'Rådgivning',
                    alternativeLabels: [
                      'Karriereveiledning',
                      'Studieveiledning',
                      'Utdanningsvalg',
                    ],

                    related: [
                      {
                        id: 'emneord_yrkesvalg',
                        deactivated: false,
                        label: 'Yrkesvalg',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'emneord_skolen',
                    deactivated: false,
                    label: 'Skolen',
                    alternativeLabels: ['Skoleverket'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_elever',
                        deactivated: false,
                        label: 'Elever',
                        alternativeLabels: ['Elevkultur'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_forsoeksskoler',
                        deactivated: false,
                        label: 'Forsøksskoler',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_grunnskolen',
                        deactivated: false,
                        label: 'Grunnskolen',
                        alternativeLabels: ['Folkeskolen'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_barneskolen',
                            deactivated: false,
                            label: 'Barneskolen',
                            alternativeLabels: [
                              'Mellomtrinnet',
                              'Småskoletrinnet',
                            ],

                            related: [],
                            children: [
                              {
                                id: 'emneord_6-aaringeriskolen',
                                deactivated: false,
                                label: '6-åringer i skolen',
                                alternativeLabels: ['Seksåringer i skolen'],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_skolefritidsordning',
                                deactivated: false,
                                label: 'Skolefritidsordning',
                                alternativeLabels: ['SFO'],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_skolestart',
                                deactivated: false,
                                label: 'Skolestart',
                                alternativeLabels: [],
                                note: 'Her: det å begynne i 1. klasse',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_ungdomsskolen',
                            deactivated: false,
                            label: 'Ungdomsskolen',
                            alternativeLabels: ['Ungdomstrinnet'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_klasseledelse',
                        deactivated: false,
                        label: 'Klasseledelse',
                        alternativeLabels: ['Klasseromsledelse'],

                        related: [
                          {
                            id: 'emneord_laeringsmiljoe',
                            deactivated: false,
                            label: 'Læringsmiljø',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'emneord_laeremidler',
                        deactivated: false,
                        label: 'Læremidler',
                        alternativeLabels: [
                          'Lærebøker',
                          'Undervisningsmateriell',
                        ],

                        related: [
                          {
                            id: 'emneord_digitallaering',
                            deactivated: false,
                            label: 'Digital læring',
                          },
                        ],
                        children: [
                          {
                            id: 'emneord_audiovisueltutstyr',
                            deactivated: false,
                            label: 'Audiovisuelt utstyr',
                            alternativeLabels: ['Av-utstyr'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_laereplaner',
                        deactivated: false,
                        label: 'Læreplaner',
                        alternativeLabels: ['Fagkrets', 'Pensum'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_rammeplaner',
                            deactivated: false,
                            label: 'Rammeplaner',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_laererrollen',
                        deactivated: false,
                        label: 'Lærerrollen',
                        alternativeLabels: ['Undervisningspersonale'],

                        related: [
                          {
                            id: 'emneord_pedagoger',
                            deactivated: false,
                            label: 'Pedagoger',
                            note: 'Her biografier om lærere',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'emneord_privatskoler',
                        deactivated: false,
                        label: 'Privatskoler',
                        alternativeLabels: [
                          'Kostskoler',
                          'Montessoriskoler',
                          'Rudolf Steiner-skoler',
                          'Steinerskoler',
                          'Waldorfskoler',
                        ],

                        related: [
                          {
                            id: 'emneord_montessoripedagogikk',
                            deactivated: false,
                            label: 'Montessoripedagogikk',
                          },
                          {
                            id: 'emneord_steinerpedagogikk',
                            deactivated: false,
                            label: 'Steinerpedagogikk',
                          },
                        ],
                        children: [
                          {
                            id: 'emneord_folkehoegskoler',
                            deactivated: false,
                            label: 'Folkehøgskoler',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_skoleadministrasjon',
                        deactivated: false,
                        label: 'Skoleadministrasjon',
                        alternativeLabels: [
                          'Elevdemokrati',
                          'Skoledemokrati',
                          'Skoleutvikling',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_rektorer',
                            deactivated: false,
                            label: 'Rektorer',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_skoledisiplin',
                        deactivated: false,
                        label: 'Skoledisiplin',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_skolemiljoe',
                        deactivated: false,
                        label: 'Skolemiljø',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_laeringsmiljoe',
                            deactivated: false,
                            label: 'Læringsmiljø',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'emneord_spesialskoler',
                        deactivated: false,
                        label: 'Spesialskoler',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_videregaaendeskoler',
                        deactivated: false,
                        label: 'Videregående skoler',
                        alternativeLabels: ['Gymnas'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_laerlinger',
                            deactivated: false,
                            label: 'Lærlinger',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_yrkesrettedeskoler',
                            deactivated: false,
                            label: 'Yrkesrettede skoler',
                            alternativeLabels: [
                              'Yrkesfag',
                              'Yrkesopplæring',
                              'Yrkesskoler',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_vurdering',
                        deactivated: false,
                        label: 'Vurdering',
                        alternativeLabels: [
                          'Elevvurdering',
                          'Evaluering',
                          'Karakterer',
                          'Mappevurdering',
                          'Normerte prøver',
                          'Prøver',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_studiefinansiering',
                    deactivated: false,
                    label: 'Studiefinansiering',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_legater',
                        deactivated: false,
                        label: 'Legater',
                        alternativeLabels: ['Stipender'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_studieteknikk',
                    deactivated: false,
                    label: 'Studieteknikk',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_undervisningsfag',
                    deactivated: false,
                    label: 'Undervisningsfag',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_kroppsoeving',
                        deactivated: false,
                        label: 'Kroppsøving',
                        alternativeLabels: [],
                        note: 'Her: kroppsøving som undervisningsfag',
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_lesing',
                        deactivated: false,
                        label: 'Lesing',
                        alternativeLabels: ['Leseferdigheter', 'Lesevaner'],

                        related: [
                          {
                            id: 'emneord_lese-ogskriveopplaering',
                            deactivated: false,
                            label: 'Lese- og skriveopplæring',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_universiteteroghoegskoler',
                    deactivated: false,
                    label: 'Universiteter og høgskoler',
                    alternativeLabels: [
                      'Høgskoler',
                      'Høyskoler',
                      'Universiteter',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_voksenopplaering',
                    deactivated: false,
                    label: 'Voksenopplæring',
                    alternativeLabels: [
                      'Brevskoler',
                      'Realkompetansevurdering',
                      'Voksenundervisning',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_veiledning',
                deactivated: false,
                label: 'Veiledning',
                alternativeLabels: ['Pedagogisk veiledning'],

                related: [
                  {
                    id: 'emneord_coaching',
                    deactivated: false,
                    label: 'Coaching',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_voksenpedagogikk',
                deactivated: false,
                label: 'Voksenpedagogikk',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_psykologi',
            deactivated: false,
            label: 'Psykologi',
            alternativeLabels: ['Sinnet'],

            related: [],
            children: [
              {
                id: 'emneord_atferd',
                deactivated: false,
                label: 'Atferd',
                alternativeLabels: ['Handlinger', 'Kroppsspråk'],

                related: [],
                children: [
                  {
                    id: 'emneord_atferdsproblemer',
                    deactivated: false,
                    label: 'Atferdsproblemer',
                    alternativeLabels: ['Atferdsvansker'],

                    related: [
                      {
                        id: 'emneord_adhd',
                        deactivated: false,
                        label: 'ADHD',
                      },
                    ],
                    children: [
                      {
                        id: 'emneord_hyperaktivitet',
                        deactivated: false,
                        label: 'Hyperaktivitet',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_instinkter',
                    deactivated: false,
                    label: 'Instinkter',
                    alternativeLabels: ['Vaner'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_viljebestemtehandlinger',
                    deactivated: false,
                    label: 'Viljebestemte handlinger',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_hemmeligheter',
                        deactivated: false,
                        label: 'Hemmeligheter',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_hevn',
                        deactivated: false,
                        label: 'Hevn',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_loegn',
                        deactivated: false,
                        label: 'Løgn',
                        alternativeLabels: ['Lyve'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_mobbing',
                        deactivated: false,
                        label: 'Mobbing',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_motivasjon',
                        deactivated: false,
                        label: 'Motivasjon',
                        alternativeLabels: ['Belønning', 'Incitamenter'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_ondskap',
                        deactivated: false,
                        label: 'Ondskap',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_svik',
                        deactivated: false,
                        label: 'Svik',
                        alternativeLabels: ['Bedrag'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_vilje',
                        deactivated: false,
                        label: 'Vilje',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_dybdepsykologi',
                deactivated: false,
                label: 'Dybdepsykologi',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_fantasier',
                    deactivated: false,
                    label: 'Fantasier',
                    alternativeLabels: ['Dagdrømmer', 'Ønsker'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_hypnose',
                    deactivated: false,
                    label: 'Hypnose',
                    alternativeLabels: ['Hypnotisme', 'Suggesjon', 'Transe'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_soevn',
                    deactivated: false,
                    label: 'Søvn',
                    alternativeLabels: [
                      'Somnambulisme',
                      'Søvnfenomener',
                      'Søvnforstyrrelser',
                      'Søvngjengeri',
                      'Søvnløshet',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_droemmer',
                        deactivated: false,
                        label: 'Drømmer',
                        alternativeLabels: ['Drømmetydning'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_underbevissthet',
                    deactivated: false,
                    label: 'Underbevissthet',
                    alternativeLabels: ['Det underbevisste', 'Ego', 'Superego'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_hemninger',
                        deactivated: false,
                        label: 'Hemninger',
                        alternativeLabels: ['Komplekser'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_foelelser',
                deactivated: false,
                label: 'Følelser',
                alternativeLabels: ['Sinnstilstander', 'Stemninger'],

                related: [],
                children: [
                  {
                    id: 'emneord_begjaer',
                    deactivated: false,
                    label: 'Begjær',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_ensomhet',
                    deactivated: false,
                    label: 'Ensomhet',
                    alternativeLabels: ['Utenforskap'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_glede',
                    deactivated: false,
                    label: 'Glede',
                    alternativeLabels: ['Lykke'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kjaerlighet',
                    deactivated: false,
                    label: 'Kjærlighet',
                    alternativeLabels: ['Forelskelse', 'Morskjærlighet'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_mot',
                    deactivated: false,
                    label: 'Mot',
                    alternativeLabels: ['Modig'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_redsel',
                    deactivated: false,
                    label: 'Redsel',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_savn',
                    deactivated: false,
                    label: 'Savn',
                    alternativeLabels: ['Lengsel'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_selvtillit',
                    deactivated: false,
                    label: 'Selvtillit',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sinne',
                    deactivated: false,
                    label: 'Sinne',
                    alternativeLabels: ['Aggresjon'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sjalusi',
                    deactivated: false,
                    label: 'Sjalusi',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_misunnelse',
                        deactivated: false,
                        label: 'Misunnelse',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_skam',
                    deactivated: false,
                    label: 'Skam',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_skyld',
                    deactivated: false,
                    label: 'Skyld',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sorg',
                    deactivated: false,
                    label: 'Sorg',
                    alternativeLabels: ['Sorgarbeid'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_vennskap',
                    deactivated: false,
                    label: 'Vennskap',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_humor',
                deactivated: false,
                label: 'Humor',
                alternativeLabels: [],
                note: 'Her: Humor som emne',
                related: [],
                children: [],
              },
              {
                id: 'emneord_individualpsykologi',
                deactivated: false,
                label: 'Individualpsykologi',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_identitet',
                    deactivated: false,
                    label: 'Identitet',
                    alternativeLabels: ['Individualitet', 'Selvbilde'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_kjoennsidentitet',
                        deactivated: false,
                        label: 'Kjønnsidentitet',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_kjoennsroller',
                            deactivated: false,
                            label: 'Kjønnsroller',
                          },
                        ],
                        children: [
                          {
                            id: 'emneord_kvinner',
                            deactivated: false,
                            label: 'Kvinner',
                            alternativeLabels: ['Kvinnerollen'],

                            related: [],
                            children: [
                              {
                                id: 'emneord_jenter',
                                deactivated: false,
                                label: 'Jenter',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_lhbt',
                            deactivated: false,
                            label: 'LHBT',
                            alternativeLabels: [
                              'Biseksuelle',
                              'Interseksuelle',
                              'LHBTIQ',
                              'Panseksuelle',
                              'Queer-personer',
                            ],

                            related: [
                              {
                                id: 'emneord_homofili',
                                deactivated: false,
                                label: 'Homofili',
                              },
                            ],
                            children: [
                              {
                                id: 'emneord_homofile',
                                deactivated: false,
                                label: 'Homofile',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_lesbiske',
                                deactivated: false,
                                label: 'Lesbiske',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_transpersoner',
                                deactivated: false,
                                label: 'Transpersoner',
                                alternativeLabels: ['Transseksuelle'],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_menn',
                            deactivated: false,
                            label: 'Menn',
                            alternativeLabels: ['Mannsrollen'],

                            related: [],
                            children: [
                              {
                                id: 'emneord_gutter',
                                deactivated: false,
                                label: 'Gutter',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_personlighet',
                    deactivated: false,
                    label: 'Personlighet',
                    alternativeLabels: ['Grafologi', 'Karakter', 'Temperament'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_integritet',
                        deactivated: false,
                        label: 'Integritet',
                        alternativeLabels: ['Selvstendighet', 'Uavhengighet'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_sensitivitet',
                    deactivated: false,
                    label: 'Sensitivitet',
                    alternativeLabels: ['Høysensitive'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_intellektuelleprosesser',
                deactivated: false,
                label: 'Intellektuelle prosesser',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_intelligens',
                    deactivated: false,
                    label: 'Intelligens',
                    alternativeLabels: [
                      'Begavelse',
                      'Evner',
                      'Ferdigheter',
                      'Talent',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kognitivpsykologi',
                    deactivated: false,
                    label: 'Kognitiv psykologi',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_hukommelse',
                        deactivated: false,
                        label: 'Hukommelse',
                        alternativeLabels: ['Erindring', 'Minne'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_hukommelsestap',
                            deactivated: false,
                            label: 'Hukommelsestap',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_kreativitet',
                        deactivated: false,
                        label: 'Kreativitet',
                        alternativeLabels: [
                          'Fantasi',
                          'Inspirasjon',
                          'Intuisjon',
                          'Orginalitet',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kunnskap',
                        deactivated: false,
                        label: 'Kunnskap',
                        alternativeLabels: ['Praktisk kunnskap'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_laering',
                        deactivated: false,
                        label: 'Læring',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_laeringsmiljoe',
                            deactivated: false,
                            label: 'Læringsmiljø',
                            alternativeLabels: [],
                            related: [
                              {
                                id: 'emneord_klasseledelse',
                                deactivated: false,
                                label: 'Klasseledelse',
                              },
                              {
                                id: 'emneord_skolemiljoe',
                                deactivated: false,
                                label: 'Skolemiljø',
                              },
                            ],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_tenkning',
                        deactivated: false,
                        label: 'Tenkning',
                        alternativeLabels: [
                          'Abstraksjon',
                          'Problemløsning',
                          'Rasjonell tenkning',
                          'Resonnement',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_mentalhelse',
                deactivated: false,
                label: 'Mental helse',
                alternativeLabels: ['Psykisk helse', 'Selvrealisering'],

                related: [],
                children: [
                  {
                    id: 'emneord_avspenning',
                    deactivated: false,
                    label: 'Avspenning',
                    alternativeLabels: [
                      'Autogen trening',
                      'Selvsuggesjon',
                      'Visualisering',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_livsmestring',
                    deactivated: false,
                    label: 'Livsmestring',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_mindfulness',
                    deactivated: false,
                    label: 'Mindfulness',
                    alternativeLabels: ['Oppmerksomhetstrening'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_selvutvikling',
                    deactivated: false,
                    label: 'Selvutvikling',
                    alternativeLabels: ['Personlighetsutvikling'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_stress',
                    deactivated: false,
                    label: 'Stress',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_nevropsykologi',
                deactivated: false,
                label: 'Nevropsykologi',
                alternativeLabels: ['Fysiologisk psykologi'],

                related: [],
                children: [
                  {
                    id: 'emneord_bevegelser',
                    deactivated: false,
                    label: 'Bevegelser',
                    alternativeLabels: [
                      'Bevegelsesfunksjoner',
                      'Høyrehendte',
                      'Motorisk læring',
                      'Reflekser',
                      'Venstrehendte',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_bevissthet',
                    deactivated: false,
                    label: 'Bevissthet',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_psykonevroimmunologi',
                    deactivated: false,
                    label: 'Psykonevroimmunologi',
                    alternativeLabels: ['PNI'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sansing',
                    deactivated: false,
                    label: 'Sansing',
                    alternativeLabels: ['Persepsjon', 'Sensorisk persepsjon'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_parapsykologi',
                deactivated: false,
                label: 'Parapsykologi',
                alternativeLabels: [
                  'Clairvoyance',
                  'Ekstrasensorisk persepsjon',
                  'Psykokinese',
                  'Synskhet',
                  'Tankeoverføring',
                  'Telepati',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_psykologiskeretninger',
                deactivated: false,
                label: 'Psykologiske retninger',
                alternativeLabels: [
                  'Atferdspsykologi',
                  'Behaviorisme',
                  'Eksistensiell psykologi',
                  'Freudiansk psykologi',
                  'Gestaltpsykologi',
                  'Neo-freudiansk psykologi',
                  'Psykoanalytisk psykologi',
                  'Psykologiske skoler',
                  'Psykologiske systemer',
                  'Transaksjonsanalyse',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_psykologisketester',
                deactivated: false,
                label: 'Psykologiske tester',
                alternativeLabels: [
                  'Evneprøver',
                  'Ferdighetsprøver',
                  'Intelligens tester',
                  'IQ-tester',
                  'Psykologiske prøver',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_sosialpsykologi',
                deactivated: false,
                label: 'Sosialpsykologi',
                alternativeLabels: ['Samfunnspsykologi'],

                related: [],
                children: [
                  {
                    id: 'emneord_gruppepsykologi',
                    deactivated: false,
                    label: 'Gruppepsykologi',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_utviklingspsykologi',
                deactivated: false,
                label: 'Utviklingspsykologi',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_barnepsykologi',
                    deactivated: false,
                    label: 'Barnepsykologi',
                    alternativeLabels: ['Ungdomspsykologi'],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'emneord_religion',
        deactivated: false,
        label: 'Religion',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'emneord_blandingsreligioner',
            deactivated: false,
            label: 'Blandingsreligioner',
            alternativeLabels: ['Gnostisisme', 'Subud', 'Voodoo'],

            related: [],
            children: [
              {
                id: "emneord_baha'i",
                deactivated: false,
                label: "Baha'i",
                alternativeLabels: ['Bahai'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_scientologi',
                deactivated: false,
                label: 'Scientologi',
                alternativeLabels: ['Dianetikk'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_sjamanisme',
                deactivated: false,
                label: 'Sjamanisme',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_teosofi',
                deactivated: false,
                label: 'Teosofi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_indiskereligioner',
            deactivated: false,
            label: 'Indiske religioner',
            alternativeLabels: ['Jainisme', 'Sikhisme'],

            related: [],
            children: [
              {
                id: 'emneord_buddhisme',
                deactivated: false,
                label: 'Buddhisme',
                alternativeLabels: [
                  'Buddister',
                  'Hinayana',
                  'Lamaisme',
                  'Manayana',
                  'Tibetansk buddhisme',
                  'Zen buddhisme',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_hinduisme',
                deactivated: false,
                label: 'Hinduisme',
                alternativeLabels: ['Brahmanisme', 'Hindu', 'Hinduer', 'Veda'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_islam',
            deactivated: false,
            label: 'Islam',
            alternativeLabels: [
              'Muhammedanere',
              'Muhammedanisme',
              'Muslimske miljøer',
              'Sjiisme',
              'Sunnisme',
            ],

            related: [],
            children: [
              {
                id: 'emneord_koranen',
                deactivated: false,
                label: 'Koranen',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_muslimer',
                deactivated: false,
                label: 'Muslimer',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_japanskereligioner',
            deactivated: false,
            label: 'Japanske religioner',
            alternativeLabels: ['Shintoisme'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_joededommen',
            deactivated: false,
            label: 'Jødedommen',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_joediskesamfunn',
                deactivated: false,
                label: 'Jødiske samfunn',
                alternativeLabels: [
                  'Chassidisme',
                  'Hassidisme',
                  'Jødiske miljøer',
                  'Konservativ jødedom',
                  'Mosaiske trossamfunn',
                  'Ortodoks jødedom',
                  'Reformert jødedom',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_kabbala',
                deactivated: false,
                label: 'Kabbala',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_talmud',
                deactivated: false,
                label: 'Talmud',
                alternativeLabels: ['Haggada', 'Halaka', 'Midrasj'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_kinesiskereligioner',
            deactivated: false,
            label: 'Kinesiske religioner',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_konfusianisme',
                deactivated: false,
                label: 'Konfusianisme',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_taoisme',
                deactivated: false,
                label: 'Taoisme',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_kristendom',
            deactivated: false,
            label: 'Kristendom',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_bibelen',
                deactivated: false,
                label: 'Bibelen',
                alternativeLabels: ['Kanoniske skrifter'],

                related: [],
                children: [
                  {
                    id: 'emneord_apokryfer',
                    deactivated: false,
                    label: 'Apokryfer',
                    alternativeLabels: [
                      'Apokryfiske skrifter',
                      'Gnostiske skrifter',
                      'Psevepigrafer',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_bibelhistorie',
                    deactivated: false,
                    label: 'Bibelhistorie',
                    alternativeLabels: ['Bibelsk historie'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_bibelkritikk',
                    deactivated: false,
                    label: 'Bibelkritikk',
                    alternativeLabels: ['Kanon'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_bibeloversettelser',
                    deactivated: false,
                    label: 'Bibeloversettelser',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_bibeltolkning',
                    deactivated: false,
                    label: 'Bibeltolkning',
                    alternativeLabels: ['Bibelkommentarer', 'Eksegese'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_gamletestamente',
                    deactivated: false,
                    label: 'Gamle testamente',
                    alternativeLabels: ['Det gamle testamente', 'GT'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_historiskeboeker',
                        deactivated: false,
                        label: 'Historiske bøker',
                        alternativeLabels: [
                          'Dommernes bok',
                          'Esras bok',
                          'Esters bok',
                          'Josvas bok',
                          'Kongebøkene',
                          'Krønikebøkene',
                          'Mosebøkene',
                          'Nehemias bok',
                          'Ruts bok',
                          'Samuelsbøkene',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_poetiskeboeker',
                        deactivated: false,
                        label: 'Poetiske bøker',
                        alternativeLabels: [
                          'Jobs bok',
                          'Predikerens bok',
                          'Salomos høysang',
                          'Salomos ordspråk',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_salmenesbok',
                            deactivated: false,
                            label: 'Salmenes bok',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_profetene',
                        deactivated: false,
                        label: 'Profetene',
                        alternativeLabels: [
                          'Amos bok',
                          'Daniels bok',
                          'Elias',
                          'Esekiels bok',
                          'Hoseas bok',
                          'Jeremias bok',
                          'Jesaias bok',
                          'Joels bok',
                          'Klagesangene',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_nyetestamente',
                    deactivated: false,
                    label: 'Nye testamente',
                    alternativeLabels: ['NT'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_apostlenesgjerninger',
                        deactivated: false,
                        label: 'Apostlenes gjerninger',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_evangeliene',
                        deactivated: false,
                        label: 'Evangeliene',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_johannes',
                            deactivated: false,
                            label: 'Johannes',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_lukas',
                            deactivated: false,
                            label: 'Lukas',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_juleevangeliet',
                                deactivated: false,
                                label: 'Juleevangeliet',
                                alternativeLabels: [],
                                related: [
                                  {
                                    id: 'emneord_julen',
                                    deactivated: false,
                                    label: 'Julen',
                                  },
                                ],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_markus',
                            deactivated: false,
                            label: 'Markus',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_matteus',
                            deactivated: false,
                            label: 'Matteus',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_johannesaapenbaring',
                        deactivated: false,
                        label: 'Johannes åpenbaring',
                        alternativeLabels: ['Apokalypsen'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_nytestamentligebrev',
                        deactivated: false,
                        label: 'Nytestamentlige brev',
                        alternativeLabels: [
                          'Brevene',
                          'Efeserbrevet',
                          'Filipperbrevet',
                          'Galeaterbrevet',
                          'Hebreerbrevet',
                          'Johannesbrevene',
                          'Kolosserbrevet',
                          'Korinterbrevet',
                          'Romerbrevet',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_kirkehistorie',
                deactivated: false,
                label: 'Kirkehistorie',
                alternativeLabels: ['Kirkens historie'],

                related: [],
                children: [
                  {
                    id: 'emneord_apostlene',
                    deactivated: false,
                    label: 'Apostlene',
                    alternativeLabels: ['Apostelbiografier'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_dogmestrid',
                    deactivated: false,
                    label: 'Dogmestrid',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_inkvisisjonen',
                        deactivated: false,
                        label: 'Inkvisisjonen',
                        alternativeLabels: ['Kjetteri'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_reformasjonen',
                    deactivated: false,
                    label: 'Reformasjonen',
                    alternativeLabels: ['Calvin', 'Luther'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_urkristendom',
                    deactivated: false,
                    label: 'Urkristendom',
                    alternativeLabels: ['Oldkirken'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_kirken',
                deactivated: false,
                label: 'Kirken',
                alternativeLabels: [],
                note: 'Her kirken som organisasjon',
                related: [
                  {
                    id: 'emneord_kirkebygninger',
                    deactivated: false,
                    label: 'Kirkebygninger',
                  },
                ],
                children: [
                  {
                    id: 'emneord_gudstjeneste',
                    deactivated: false,
                    label: 'Gudstjeneste',
                    alternativeLabels: [
                      'Høymessen',
                      'Kristelige møter',
                      'Vekkelsesmøter',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_liturgi',
                        deactivated: false,
                        label: 'Liturgi',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_prekener',
                        deactivated: false,
                        label: 'Prekener',
                        alternativeLabels: [
                          'Forkynnelse',
                          'Homiletikk',
                          'Prekekunst',
                          'Prekenlære',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_salmer',
                        deactivated: false,
                        label: 'Salmer',
                        alternativeLabels: [
                          'Koraler',
                          'Kristelige sanger',
                          'Kristne sanger',
                          'Salmebøker',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_kirkeligehandlinger',
                    deactivated: false,
                    label: 'Kirkelige handlinger',
                    alternativeLabels: [
                      'Hellige handlinger',
                      'Nådemidler',
                      'Sakramenter',
                    ],

                    related: [
                      {
                        id: 'emneord_livetshoeytider',
                        deactivated: false,
                        label: 'Livets høytider',
                      },
                    ],
                    children: [
                      {
                        id: 'emneord_daap',
                        deactivated: false,
                        label: 'Dåp',
                        alternativeLabels: [
                          'Barnedåp',
                          'Dåpsskikker',
                          'Voksendåp',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_konfirmasjon',
                        deactivated: false,
                        label: 'Konfirmasjon',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_nattverd',
                        deactivated: false,
                        label: 'Nattverd',
                        alternativeLabels: ['Kommunion'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_skriftemaal',
                        deactivated: false,
                        label: 'Skriftemål',
                        alternativeLabels: ['Absolusjon', 'Bot'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_vigsel',
                        deactivated: false,
                        label: 'Vigsel',
                        alternativeLabels: ['Kirkelig ekteskap'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_kirkensorganisasjon',
                    deactivated: false,
                    label: 'Kirkens organisasjon',
                    alternativeLabels: ['Kirkelig organisasjon'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_geistligheten',
                        deactivated: false,
                        label: 'Geistligheten',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_geistlige',
                            deactivated: false,
                            label: 'Geistlige',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_biskoper',
                                deactivated: false,
                                label: 'Biskoper',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_erkebiskoper',
                                deactivated: false,
                                label: 'Erkebiskoper',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_pastorer',
                                deactivated: false,
                                label: 'Pastorer',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_prester',
                                deactivated: false,
                                label: 'Prester',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_ordinasjon',
                            deactivated: false,
                            label: 'Ordinasjon',
                            alternativeLabels: ['Prestevigsel'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_kirkepolitikk',
                        deactivated: false,
                        label: 'Kirkepolitikk',
                        alternativeLabels: ['Statskirke'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_menigheten',
                        deactivated: false,
                        label: 'Menigheten',
                        alternativeLabels: ['Menighetsarbeid'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_diakoni',
                            deactivated: false,
                            label: 'Diakoni',
                            alternativeLabels: ['Diakoner', 'Menighetspleie'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_kateketikk',
                            deactivated: false,
                            label: 'Kateketikk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_menighetsraad',
                            deactivated: false,
                            label: 'Menighetsråd',
                            alternativeLabels: ['Eldste'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_sjelesorg',
                            deactivated: false,
                            label: 'Sjelesorg',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_kristneorganisasjoner',
                    deactivated: false,
                    label: 'Kristne organisasjoner',
                    alternativeLabels: [
                      'Bedehus',
                      'Emissærer',
                      'Frelsesarmeen',
                      'Indremisjon',
                      'Klostre',
                      'Kongregasjoner',
                      'Predikanter',
                    ],

                    related: [
                      {
                        id: 'emneord_religioesebevegelser',
                        deactivated: false,
                        label: 'Religiøse bevegelser',
                      },
                    ],
                    children: [
                      {
                        id: 'emneord_klostervesen',
                        deactivated: false,
                        label: 'Klostervesen',
                        alternativeLabels: ['Munker', 'Nonner'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_misjon',
                    deactivated: false,
                    label: 'Misjon',
                    alternativeLabels: [
                      'Misjonshistorie',
                      'Misjonsselskap',
                      'Misjonsvirksomhet',
                      'Ytremisjon',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_misjonaerer',
                        deactivated: false,
                        label: 'Misjonærer',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_vekkelse',
                    deactivated: false,
                    label: 'Vekkelse',
                    alternativeLabels: [
                      'Evangelisering',
                      'Framgangsteologi',
                      'Karismatiske vekkelse',
                      'Kirkevekst',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_kirkesamfunn',
                deactivated: false,
                label: 'Kirkesamfunn',
                alternativeLabels: ['Konfesjonskunnskap'],

                related: [],
                children: [
                  {
                    id: 'emneord_anglikanskekirker',
                    deactivated: false,
                    label: 'Anglikanske kirker',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_katolskekirker',
                    deactivated: false,
                    label: 'Katolske kirker',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_katolskemiljoeer',
                        deactivated: false,
                        label: 'Katolske miljøer',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_paver',
                        deactivated: false,
                        label: 'Paver',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_romersk-katolskekirker',
                        deactivated: false,
                        label: 'Romersk-katolske kirker',
                        alternativeLabels: ['Romerkirken'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_oekumenikk',
                    deactivated: false,
                    label: 'Økumenikk',
                    alternativeLabels: ['Kirkenes verdensråd', 'Kristen enhet'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_ortodoksekirker',
                    deactivated: false,
                    label: 'Ortodokse kirker',
                    alternativeLabels: [
                      'Gresk-ortodokse kirker',
                      'Orientalsk-ortodokse kirker',
                      'Østkirken',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_protestantiskekirkesamfunn',
                    deactivated: false,
                    label: 'Protestantiske kirkesamfunn',
                    alternativeLabels: ['Frikirker'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_adventister',
                        deactivated: false,
                        label: 'Adventister',
                        alternativeLabels: ['Syvendedagsadventister'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_baptister',
                        deactivated: false,
                        label: 'Baptister',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_jehovasvitner',
                        deactivated: false,
                        label: 'Jehovas vitner',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kongregasjonalistkirker',
                        deactivated: false,
                        label: 'Kongregasjonalistkirker',
                        alternativeLabels: ['Dnm', 'Misjonsforbundet'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kvekere',
                        deactivated: false,
                        label: 'Kvekere',
                        alternativeLabels: ['Vennenes samfunn'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_lutherskekirker',
                        deactivated: false,
                        label: 'Lutherske kirker',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_norskekirke',
                            deactivated: false,
                            label: 'Norske kirke',
                            alternativeLabels: ['Den norske kirke'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_metodister',
                        deactivated: false,
                        label: 'Metodister',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_mormonere',
                        deactivated: false,
                        label: 'Mormonere',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_pinsebevegelsen',
                        deactivated: false,
                        label: 'Pinsebevegelsen',
                        alternativeLabels: ['Pinsemenigheter', 'Pinsevenner'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_reformertekirker',
                        deactivated: false,
                        label: 'Reformerte kirker',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_smithsvenner',
                        deactivated: false,
                        label: 'Smiths venner',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_kristenlivet',
                deactivated: false,
                label: 'Kristenlivet',
                alternativeLabels: ['Bibelgrupper', 'Katolikker', 'Kristne'],

                related: [],
                children: [
                  {
                    id: 'emneord_andakter',
                    deactivated: false,
                    label: 'Andakter',
                    alternativeLabels: [
                      'Andaktsbøker',
                      'Familieandakt',
                      'Husandakt',
                      'Oppbyggelse',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_bibellesning',
                    deactivated: false,
                    label: 'Bibellesning',
                    alternativeLabels: ['Bibelstudier'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_helliggjoerelse',
                    deactivated: false,
                    label: 'Helliggjørelse',
                    alternativeLabels: ['Åndelig vekst'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kristenetikk',
                    deactivated: false,
                    label: 'Kristen etikk',
                    alternativeLabels: ['Adiafora', 'Kristen moral'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_naadegaver',
                    deactivated: false,
                    label: 'Nådegaver',
                    alternativeLabels: ['Tungetale', 'Åndsdåp'],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_persiskereligioner',
            deactivated: false,
            label: 'Persiske religioner',
            alternativeLabels: ['Parsisme', 'Zoroaster'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_religionsforkjempere',
            deactivated: false,
            label: 'Religionsforkjempere',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_helgener',
                deactivated: false,
                label: 'Helgener',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_religionsvitenskap',
            deactivated: false,
            label: 'Religionsvitenskap',
            alternativeLabels: [
              'Religionshistorie',
              'Sammenlignende religionsvitenskap',
            ],

            related: [],
            children: [
              {
                id: 'emneord_gudsbegrep',
                deactivated: false,
                label: 'Gudsbegrep',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_ateisme',
                    deactivated: false,
                    label: 'Ateisme',
                    alternativeLabels: ['Agnostisisme'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_deisme',
                    deactivated: false,
                    label: 'Deisme',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_mytologi',
                    deactivated: false,
                    label: 'Mytologi',
                    alternativeLabels: ['Gudelære'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_klassiskmytologi',
                        deactivated: false,
                        label: 'Klassisk mytologi',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_greskmytologi',
                            deactivated: false,
                            label: 'Gresk mytologi',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_romerskmytologi',
                            deactivated: false,
                            label: 'Romersk mytologi',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_norroenmytologi',
                        deactivated: false,
                        label: 'Norrøn mytologi',
                        alternativeLabels: [
                          'Nordisk mytologi',
                          'Norsk mytologi',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_naturreligion',
                    deactivated: false,
                    label: 'Naturreligion',
                    alternativeLabels: ['Primitiv religion'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_panteisme',
                    deactivated: false,
                    label: 'Panteisme',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_teisme',
                    deactivated: false,
                    label: 'Teisme',
                    alternativeLabels: ['Monoteisme', 'Polyteisme'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_helligeskrifter',
                deactivated: false,
                label: 'Hellige skrifter',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_reinkarnasjon',
                deactivated: false,
                label: 'Reinkarnasjon',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_religionsfenomenologi',
                deactivated: false,
                label: 'Religionsfenomenologi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_religionsfilosofi',
                deactivated: false,
                label: 'Religionsfilosofi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_religionsforfoelgelse',
                deactivated: false,
                label: 'Religionsforfølgelse',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_religionspedagogikk',
                deactivated: false,
                label: 'Religionspedagogikk',
                alternativeLabels: ['Religionsopplæring', 'Trosopplæring'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_religionspsykologi',
                deactivated: false,
                label: 'Religionspsykologi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_religionsutoevelse',
                deactivated: false,
                label: 'Religionsutøvelse',
                alternativeLabels: ['Fromhetsliv'],

                related: [],
                children: [
                  {
                    id: 'emneord_boenn',
                    deactivated: false,
                    label: 'Bønn',
                    alternativeLabels: [
                      'Bønnebøker',
                      'Lovprisning',
                      'Tilbedelse',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_konversjon',
                    deactivated: false,
                    label: 'Konversjon',
                    alternativeLabels: ['Konvertering', 'Religionsskifte'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_pilegrimsreiser',
                    deactivated: false,
                    label: 'Pilegrimsreiser',
                    alternativeLabels: [
                      'Helgen- og relekviedyrkelse',
                      'Pilegrimsferder',
                      'Relekviedyrkelse',
                      'Valfart',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_religioeseforskrifter',
                    deactivated: false,
                    label: 'Religiøse forskrifter',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_faste',
                        deactivated: false,
                        label: 'Faste',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_ritualer',
                    deactivated: false,
                    label: 'Ritualer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_religioeseopplevelser',
                deactivated: false,
                label: 'Religiøse opplevelser',
                alternativeLabels: [
                  'Kontemplasjon',
                  'Mirakler',
                  'Religiøs mystisisme',
                  'Undere',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_spiritualitet',
                    deactivated: false,
                    label: 'Spiritualitet',
                    alternativeLabels: ['Åndelighet'],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_religioesebevegelser',
            deactivated: false,
            label: 'Religiøse bevegelser',
            alternativeLabels: [
              'Esoterisk filosofi',
              'Forente familie',
              'Nye religioner',
              'Nyreligiøsitet',
            ],

            related: [
              {
                id: 'emneord_kristneorganisasjoner',
                deactivated: false,
                label: 'Kristne organisasjoner',
              },
            ],
            children: [
              {
                id: 'emneord_antroposofi',
                deactivated: false,
                label: 'Antroposofi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_sekter',
                deactivated: false,
                label: 'Sekter',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_teologi',
            deactivated: false,
            label: 'Teologi',
            alternativeLabels: ['Dogmatikk'],

            related: [],
            children: [
              {
                id: 'emneord_eskatologi',
                deactivated: false,
                label: 'Eskatologi',
                alternativeLabels: [
                  'Antikrist',
                  'Dommedag',
                  'Endetid',
                  'Eschatologi',
                  'Tusenårsriket',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_frelse',
                deactivated: false,
                label: 'Frelse',
                alternativeLabels: [
                  'Forsoning',
                  'Gjenfødelse',
                  'Predestinasjon',
                  'Rettferdiggjørelse',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_kristenteologi',
                deactivated: false,
                label: 'Kristen teologi',
                alternativeLabels: ['Kristen dogmatikk'],

                related: [],
                children: [
                  {
                    id: 'emneord_apologetikk',
                    deactivated: false,
                    label: 'Apologetikk',
                    alternativeLabels: ['Kirkekritikk', 'Kristendomsdebatt'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_himmeloghelvete',
                    deactivated: false,
                    label: 'Himmel og helvete',
                    alternativeLabels: ['Helvete'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_engler',
                        deactivated: false,
                        label: 'Engler',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_skapelsen',
                    deactivated: false,
                    label: 'Skapelsen',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_syndefallet',
                    deactivated: false,
                    label: 'Syndefallet',
                    alternativeLabels: ['Arvesynden'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_treenigheten',
                    deactivated: false,
                    label: 'Treenigheten',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_denhelligeaand',
                        deactivated: false,
                        label: 'Den hellige ånd',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_gud',
                        deactivated: false,
                        label: 'Gud',
                        alternativeLabels: ['Guds allmakt', 'Guds vesen'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_jesus',
                        deactivated: false,
                        label: 'Jesus',
                        alternativeLabels: ['Jesu liv'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_trosbekjennelser',
                    deactivated: false,
                    label: 'Trosbekjennelser',
                    alternativeLabels: ['Bekjennelsesskrifter', 'Katekismer'],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'emneord_samfunnsfag',
        deactivated: false,
        label: 'Samfunnsfag',
        alternativeLabels: ['Samfunnsvitenskap'],

        related: [],
        children: [
          {
            id: 'emneord_arbeidsliv',
            deactivated: false,
            label: 'Arbeidsliv',
            alternativeLabels: [
              'Arbeidsplasser',
              'Arbeidspsykologi',
              'Arbeidssosiologi',
            ],

            related: [],
            children: [
              {
                id: 'emneord_arbeidsforhold',
                deactivated: false,
                label: 'Arbeidsforhold',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_arbeidsinnhold',
                    deactivated: false,
                    label: 'Arbeidsinnhold',
                    alternativeLabels: ['Jobbinnhold'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_arbeidsmiljoe',
                    deactivated: false,
                    label: 'Arbeidsmiljø',
                    alternativeLabels: [
                      'Sikkerhetsarbeid',
                      'Vernearbeid',
                      'Verneombud',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_ergonomi',
                        deactivated: false,
                        label: 'Ergonomi',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_hygiene',
                        deactivated: false,
                        label: 'Hygiene',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_arbeidstid',
                    deactivated: false,
                    label: 'Arbeidstid',
                    alternativeLabels: [
                      'Deltidsarbeid',
                      'Fleksitid',
                      'Skiftarbeid',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_hjemmekontor',
                    deactivated: false,
                    label: 'Hjemmekontor',
                    alternativeLabels: ['Hjemmearbeid'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_loenninger',
                    deactivated: false,
                    label: 'Lønninger',
                    alternativeLabels: [
                      'Akkordlønn',
                      'Borgerlønn',
                      'Inntekter',
                      'Lønnsforhold',
                      'Tariffavtaler',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_varsling',
                    deactivated: false,
                    label: 'Varsling',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_arbeidsgivere',
                deactivated: false,
                label: 'Arbeidsgivere',
                alternativeLabels: ['Arbeidskjøpere'],

                related: [],
                children: [
                  {
                    id: 'emneord_naeringsorganisasjoner',
                    deactivated: false,
                    label: 'Næringsorganisasjoner',
                    alternativeLabels: ['Arbeidsgiverorganisasjoner', 'NAF'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_arbeidskonflikter',
                deactivated: false,
                label: 'Arbeidskonflikter',
                alternativeLabels: ['Blokade', 'Lockout', 'Streik'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_arbeidsmarkedet',
                deactivated: false,
                label: 'Arbeidsmarkedet',
                alternativeLabels: ['Arbeidskraft', 'Sysselsetting'],

                related: [],
                children: [
                  {
                    id: 'emneord_ansettelse',
                    deactivated: false,
                    label: 'Ansettelse',
                    alternativeLabels: ['Headhunting'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_arbeidsformidling',
                    deactivated: false,
                    label: 'Arbeidsformidling',
                    alternativeLabels: [
                      'Arbeidskontorer',
                      'Sysselsettingstiltak',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_arbeidsloeshet',
                    deactivated: false,
                    label: 'Arbeidsløshet',
                    alternativeLabels: ['Arbeidsledighet'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_oppsigelser',
                    deactivated: false,
                    label: 'Oppsigelser',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_permitteringer',
                    deactivated: false,
                    label: 'Permitteringer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_vernetarbeid',
                    deactivated: false,
                    label: 'Vernet arbeid',
                    alternativeLabels: ['Arbeidstrening'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_attfoering',
                        deactivated: false,
                        label: 'Attføring',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_yrkesvalg',
                    deactivated: false,
                    label: 'Yrkesvalg',
                    alternativeLabels: ['Yrke', 'Yrkesveiledning'],

                    related: [
                      {
                        id: 'emneord_raadgivning',
                        deactivated: false,
                        label: 'Rådgivning',
                      },
                    ],
                    children: [
                      {
                        id: 'emneord_enkelteyrker',
                        deactivated: false,
                        label: 'Enkelte yrker',
                        alternativeLabels: ['Jobber'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_detektiver',
                            deactivated: false,
                            label: 'Detektiver',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_jobbsoeking',
                        deactivated: false,
                        label: 'Jobbsøking',
                        alternativeLabels: [
                          'Arbeidssøkere',
                          'Jobbintervju',
                          'Jobbsøknad',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_arbeidssystemer',
                deactivated: false,
                label: 'Arbeidssystemer',
                alternativeLabels: ['Husmannsvesen', 'Livegenskap'],

                related: [],
                children: [
                  {
                    id: 'emneord_slaveri',
                    deactivated: false,
                    label: 'Slaveri',
                    alternativeLabels: ['Trelldom'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_arbeidstakere',
                deactivated: false,
                label: 'Arbeidstakere',
                alternativeLabels: ['Ansatte'],

                related: [],
                children: [
                  {
                    id: 'emneord_fagforeningsarbeid',
                    deactivated: false,
                    label: 'Fagforeningsarbeid',
                    alternativeLabels: [
                      'Fagbevegelsen',
                      'Fagforeninger',
                      'Tillitsmannsarbeid',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_landsorganisasjonen',
                        deactivated: false,
                        label: 'Landsorganisasjonen',
                        alternativeLabels: ['LO'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_ferie',
                deactivated: false,
                label: 'Ferie',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_permisjoner',
                deactivated: false,
                label: 'Permisjoner',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_sykefravaer',
                deactivated: false,
                label: 'Sykefravær',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_beredskap',
            deactivated: false,
            label: 'Beredskap',
            alternativeLabels: [],
            note: 'Å være forberedt på å møte kritiske situasjoner, dvs. å håndtere og redusere skadevirkninger av uønskede hendelser',
            related: [
              {
                id: 'emneord_krisehaandtering',
                deactivated: false,
                label: 'Krisehåndtering',
              },
            ],
            children: [],
          },
          {
            id: 'emneord_krisehaandtering',
            deactivated: false,
            label: 'Krisehåndtering',
            alternativeLabels: ['Kriser'],

            related: [
              {
                id: 'emneord_beredskap',
                deactivated: false,
                label: 'Beredskap',
                note: 'Å være forberedt på å møte kritiske situasjoner, dvs. å håndtere og redusere skadevirkninger av uønskede hendelser',
              },
            ],
            children: [],
          },
          {
            id: 'emneord_kulturforskning',
            deactivated: false,
            label: 'Kulturforskning',
            alternativeLabels: [
              'Kulturprosesser',
              'Kulturutvikling',
              'Levekår',
              'Levevilkår',
              'Miljø',
              'Storbyforskning',
            ],

            related: [],
            children: [
              {
                id: 'emneord_folkelivsgransking',
                deactivated: false,
                label: 'Folkelivsgransking',
                alternativeLabels: ['Etnologi'],

                related: [],
                children: [
                  {
                    id: 'emneord_folketro',
                    deactivated: false,
                    label: 'Folketro',
                    alternativeLabels: ['Overtro'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_alver',
                        deactivated: false,
                        label: 'Alver',
                        alternativeLabels: ['Feer'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_hekser',
                        deactivated: false,
                        label: 'Hekser',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_magi',
                            deactivated: false,
                            label: 'Magi',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'emneord_nisser',
                        deactivated: false,
                        label: 'Nisser',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_troll',
                        deactivated: false,
                        label: 'Troll',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_skikker',
                    deactivated: false,
                    label: 'Skikker',
                    alternativeLabels: [
                      'Etikette',
                      'Seremonier',
                      'Skikk og bruk',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_halloween',
                        deactivated: false,
                        label: 'Halloween',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_hoeytider',
                        deactivated: false,
                        label: 'Høytider',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_17.mai',
                            deactivated: false,
                            label: '17. mai',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_julen',
                            deactivated: false,
                            label: 'Julen',
                            alternativeLabels: [
                              'Julefeiring',
                              'Juleforberedelser',
                              'Julefortellinger',
                            ],

                            related: [
                              {
                                id: 'emneord_juleevangeliet',
                                deactivated: false,
                                label: 'Juleevangeliet',
                              },
                            ],
                            children: [
                              {
                                id: 'emneord_advent',
                                deactivated: false,
                                label: 'Advent',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_paasken',
                            deactivated: false,
                            label: 'Påsken',
                            alternativeLabels: ['Påske', 'Påskefortellinger'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_pinsen',
                            deactivated: false,
                            label: 'Pinsen',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_ramadan',
                            deactivated: false,
                            label: 'Ramadan',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_karneval',
                        deactivated: false,
                        label: 'Karneval',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_livetshoeytider',
                        deactivated: false,
                        label: 'Livets høytider',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_kirkeligehandlinger',
                            deactivated: false,
                            label: 'Kirkelige handlinger',
                          },
                        ],
                        children: [
                          {
                            id: 'emneord_begravelse',
                            deactivated: false,
                            label: 'Begravelse',
                            alternativeLabels: [
                              'Begravelsesskikker',
                              'Gravferd',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_borgerligkonfirmasjon',
                            deactivated: false,
                            label: 'Borgerlig konfirmasjon',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_bryllup',
                            deactivated: false,
                            label: 'Bryllup',
                            alternativeLabels: ['Bryllupsskikker'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_bursdager',
                            deactivated: false,
                            label: 'Bursdager',
                            alternativeLabels: ['Fødselsdager', 'Gebursdager'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_omskjaering',
                        deactivated: false,
                        label: 'Omskjæring',
                        alternativeLabels: ['Kjønnslemlestelse'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_folkeminne',
                deactivated: false,
                label: 'Folkeminne',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_folkediktning',
                    deactivated: false,
                    label: 'Folkediktning',
                    alternativeLabels: ['Folkeeventyr', 'Muntlig tradisjon'],
                    note: 'Her: kun opprinnelig anonyme diktverk bevart gjennom muntlig overlevering',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_kultursosiologi',
                deactivated: false,
                label: 'Kultursosiologi',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_hverdagsliv',
                    deactivated: false,
                    label: 'Hverdagsliv',
                    alternativeLabels: ['Dagligliv'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_besoek',
                        deactivated: false,
                        label: 'Besøk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_selskap',
                        deactivated: false,
                        label: 'Selskap',
                        alternativeLabels: ['Fester'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_subkultur',
                    deactivated: false,
                    label: 'Subkultur',
                    alternativeLabels: [
                      'Gjengmiljø',
                      'Hippie',
                      'Hooligans',
                      'Motorsykkelklubber',
                      'Punkere',
                      'Rockabilly',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_ungdomskultur',
                        deactivated: false,
                        label: 'Ungdomskultur',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_russ',
                            deactivated: false,
                            label: 'Russ',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_populaerkultur',
                deactivated: false,
                label: 'Populærkultur',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_religionssosiologi',
                deactivated: false,
                label: 'Religionssosiologi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_slektsforskning',
                deactivated: false,
                label: 'Slektsforskning',
                alternativeLabels: ['Genealogi', 'Slektsgranskning', 'Stamtre'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_sosialantropologi',
                deactivated: false,
                label: 'Sosialantropologi',
                alternativeLabels: [
                  'Antropologi',
                  'Barn i andre land',
                  'Etnografi',
                  'Kulturantropologi',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_kulturforskjeller',
                    deactivated: false,
                    label: 'Kulturforskjeller',
                    alternativeLabels: [
                      'Kulturelle forskjeller',
                      'Møte med fremmede kulturer',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kulturkonflikter',
                    deactivated: false,
                    label: 'Kulturkonflikter',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_offentligforvaltning',
            deactivated: false,
            label: 'Offentlig forvaltning',
            alternativeLabels: ['Forvaltningslære'],

            related: [],
            children: [
              {
                id: 'emneord_lokalforvaltning',
                deactivated: false,
                label: 'Lokalforvaltning',
                alternativeLabels: [
                  'Arealplanlegging',
                  'Fylkeskommunal forvaltning',
                  'Kommunalforvaltning',
                  'Lokal administrasjon',
                  'Reguleringsplaner',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_statsforvaltning',
                deactivated: false,
                label: 'Statsforvaltning',
                alternativeLabels: [
                  'Departementer',
                  'Direktorater',
                  'Sentralforvaltning',
                ],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_organisasjoner',
            deactivated: false,
            label: 'Organisasjoner',
            alternativeLabels: ['Frivillige organisasjoner'],

            related: [],
            children: [
              {
                id: 'emneord_buekorps',
                deactivated: false,
                label: 'Buekorps',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_hemmeligeforeninger',
                deactivated: false,
                label: 'Hemmelige foreninger',
                alternativeLabels: [
                  'Hemmelige samfunn',
                  'Hemmelige selskaper',
                  'Illuminati',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_frimurere',
                    deactivated: false,
                    label: 'Frimurere',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_tempelherreordenen',
                    deactivated: false,
                    label: 'Tempelherreordenen',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_speideren',
                deactivated: false,
                label: 'Speideren',
                alternativeLabels: ['Speiderbevegelsen'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_samfunnsanalyse',
            deactivated: false,
            label: 'Samfunnsanalyse',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_sosiologi',
            deactivated: false,
            label: 'Sosiologi',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_barnekunnskap',
                deactivated: false,
                label: 'Barnekunnskap',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_barneoppdragelse',
                    deactivated: false,
                    label: 'Barneoppdragelse',
                    alternativeLabels: ['Oppdragelse'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_pottetrening',
                        deactivated: false,
                        label: 'Pottetrening',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_smokker',
                        deactivated: false,
                        label: 'Smokker',
                        alternativeLabels: ['Narresmokker', 'Tåtesmokker'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_barnestell',
                    deactivated: false,
                    label: 'Barnestell',
                    alternativeLabels: ['Spedbarnstell'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_amming',
                        deactivated: false,
                        label: 'Amming',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_barnloeshet',
                deactivated: false,
                label: 'Barnløshet',
                alternativeLabels: [],
                related: [
                  {
                    id: 'emneord_infertilitetogassistertbefruktning',
                    deactivated: false,
                    label: 'Infertilitet og assistert befruktning',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_demografi',
                deactivated: false,
                label: 'Demografi',
                alternativeLabels: [
                  'Barnedødelighet',
                  'Befolkningseksplosjon',
                  'Befolkningslære',
                  'Befolkningsvekst',
                  'Dødelighet',
                  'Migrasjon',
                  'Overbefolkning',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_emigrasjon',
                    deactivated: false,
                    label: 'Emigrasjon',
                    alternativeLabels: ['Utvandring'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_flukt',
                    deactivated: false,
                    label: 'Flukt',
                    alternativeLabels: ['Rømninger'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_immigrasjon',
                    deactivated: false,
                    label: 'Immigrasjon',
                    alternativeLabels: ['Innvandring'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_nybyggere',
                        deactivated: false,
                        label: 'Nybyggere',
                        alternativeLabels: ['Pionerer'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_pendling',
                    deactivated: false,
                    label: 'Pendling',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_forbruk',
                deactivated: false,
                label: 'Forbruk',
                alternativeLabels: ['Forbrukeradferd', 'Shopping'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_forsvinninger',
                deactivated: false,
                label: 'Forsvinninger',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_humanistiskeforkjempere',
                deactivated: false,
                label: 'Humanistiske forkjempere',
                alternativeLabels: ['Fredsforkjempere', 'Samfunnsforkjempere'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_integrering',
                deactivated: false,
                label: 'Integrering',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_kjoennsroller',
                deactivated: false,
                label: 'Kjønnsroller',
                alternativeLabels: [],
                related: [
                  {
                    id: 'emneord_kjoennsidentitet',
                    deactivated: false,
                    label: 'Kjønnsidentitet',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_kommunikasjon',
                deactivated: false,
                label: 'Kommunikasjon',
                alternativeLabels: [],
                related: [
                  {
                    id: 'emneord_sosialkompetanse',
                    deactivated: false,
                    label: 'Sosial kompetanse',
                  },
                ],
                children: [
                  {
                    id: 'emneord_flerkulturellkommunikasjon',
                    deactivated: false,
                    label: 'Flerkulturell kommunikasjon',
                    alternativeLabels: [
                      'Internasjonalisering',
                      'Tverrkulturell kommunikasjon',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_forhandlingsteknikk',
                    deactivated: false,
                    label: 'Forhandlingsteknikk',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_formidling',
                    deactivated: false,
                    label: 'Formidling',
                    alternativeLabels: ['Kulturformidling'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_fortellerkunst',
                    deactivated: false,
                    label: 'Fortellerkunst',
                    alternativeLabels: ['Historiefortelling'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_hersketeknikker',
                    deactivated: false,
                    label: 'Hersketeknikker',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_muntligkommunikasjon',
                    deactivated: false,
                    label: 'Muntlig kommunikasjon',
                    alternativeLabels: [
                      'Lytteteknikk',
                      'Retorikk',
                      'Talespråk',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_presentasjonsteknikk',
                        deactivated: false,
                        label: 'Presentasjonsteknikk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_taler',
                        deactivated: false,
                        label: 'Taler',
                        alternativeLabels: [
                          'Festtaler',
                          'Taleferdighet',
                          'Talekunst',
                          'Taleteknikk',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_skriftligkommunikasjon',
                    deactivated: false,
                    label: 'Skriftlig kommunikasjon',
                    alternativeLabels: [
                      'Skriftspråk',
                      'Skriveferdighet',
                      'Skrivekunst',
                      'Stilskriving',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_kryptografi',
                        deactivated: false,
                        label: 'Kryptografi',
                        alternativeLabels: ['Kryptologi'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_oppgaveskriving',
                        deactivated: false,
                        label: 'Oppgaveskriving',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_tekstkompetanse',
                        deactivated: false,
                        label: 'Tekstkompetanse',
                        alternativeLabels: ['Literacy', 'Litterasitet'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_symboler',
                    deactivated: false,
                    label: 'Symboler',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_konflikter',
                deactivated: false,
                label: 'Konflikter',
                alternativeLabels: ['Konflikthåndtering', 'Konfliktløsning'],

                related: [],
                children: [
                  {
                    id: 'emneord_etniskekonflikter',
                    deactivated: false,
                    label: 'Etniske konflikter',
                    alternativeLabels: ['Rasekonflikter', 'Rasemotsetninger'],

                    related: [
                      {
                        id: 'emneord_diskriminering',
                        deactivated: false,
                        label: 'Diskriminering',
                      },
                      {
                        id: 'emneord_rasisme',
                        deactivated: false,
                        label: 'Rasisme',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_likestilling',
                deactivated: false,
                label: 'Likestilling',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_kvinneforskning',
                    deactivated: false,
                    label: 'Kvinneforskning',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kvinnesak',
                    deactivated: false,
                    label: 'Kvinnesak',
                    alternativeLabels: ['Kvinnebevegelsen', 'Kvinnefrigjøring'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_kvinnesaksforkjempere',
                        deactivated: false,
                        label: 'Kvinnesaksforkjempere',
                        alternativeLabels: ['Feminister'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_livsfaser',
                deactivated: false,
                label: 'Livsfaser',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_oppvekst',
                    deactivated: false,
                    label: 'Oppvekst',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_barndom',
                        deactivated: false,
                        label: 'Barndom',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_barn',
                            deactivated: false,
                            label: 'Barn',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'emneord_ungdomstid',
                        deactivated: false,
                        label: 'Ungdomstid',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_ungdom',
                            deactivated: false,
                            label: 'Ungdom',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_voksenlivet',
                    deactivated: false,
                    label: 'Voksenlivet',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_voksne',
                        deactivated: false,
                        label: 'Voksne',
                      },
                    ],
                    children: [
                      {
                        id: 'emneord_alderdom',
                        deactivated: false,
                        label: 'Alderdom',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_eldre',
                            deactivated: false,
                            label: 'Eldre',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_mangfold',
                deactivated: false,
                label: 'Mangfold',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_omsorgssvikt',
                deactivated: false,
                label: 'Omsorgssvikt',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_samlivsformer',
                deactivated: false,
                label: 'Samlivsformer',
                alternativeLabels: ['Familiesosiologi'],

                related: [],
                children: [
                  {
                    id: 'emneord_enslige',
                    deactivated: false,
                    label: 'Enslige',
                    alternativeLabels: ['Single'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_familie',
                    deactivated: false,
                    label: 'Familie',
                    alternativeLabels: ['Kjernefamilien', 'Storfamilien'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_adopsjon',
                        deactivated: false,
                        label: 'Adopsjon',
                        alternativeLabels: ['Adoptivbarn'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_besteforeldre',
                        deactivated: false,
                        label: 'Besteforeldre',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_bonusfamilie',
                        deactivated: false,
                        label: 'Bonusfamilie',
                        alternativeLabels: ['Stefamilie', 'Steforeldre'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_bonusbarn',
                            deactivated: false,
                            label: 'Bonusbarn',
                            alternativeLabels: ['Stebarn'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_ekteskapogparforhold',
                        deactivated: false,
                        label: 'Ekteskap og parforhold',
                        alternativeLabels: ['Ekteskap', 'Parforhold'],

                        related: [
                          {
                            id: 'emneord_utroskap',
                            deactivated: false,
                            label: 'Utroskap',
                          },
                        ],
                        children: [
                          {
                            id: 'emneord_monogami',
                            deactivated: false,
                            label: 'Monogami',
                            alternativeLabels: ['Engifte'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_polygami',
                            deactivated: false,
                            label: 'Polygami',
                            alternativeLabels: ['Flergifte'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_samboerforhold',
                            deactivated: false,
                            label: 'Samboerforhold',
                            alternativeLabels: [
                              'Papirløse ekteskap',
                              'Samboere',
                              'Særboere',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_tvangsekteskap',
                            deactivated: false,
                            label: 'Tvangsekteskap',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_etterlatte',
                        deactivated: false,
                        label: 'Etterlatte',
                        alternativeLabels: ['Enkemenn', 'Enker'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_foreldre',
                        deactivated: false,
                        label: 'Foreldre',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_aleneforeldre',
                            deactivated: false,
                            label: 'Aleneforeldre',
                            alternativeLabels: ['Enslige forsørgere'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_fedre',
                            deactivated: false,
                            label: 'Fedre',
                            alternativeLabels: ['Far', 'Pappa'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_moedre',
                            deactivated: false,
                            label: 'Mødre',
                            alternativeLabels: ['Mamma', 'Mor'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_nybakteforeldre',
                            deactivated: false,
                            label: 'Nybakte foreldre',
                            alternativeLabels: ['Barseltid'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_foreldreloese',
                        deactivated: false,
                        label: 'Foreldreløse',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_samlivsbrudd',
                        deactivated: false,
                        label: 'Samlivsbrudd',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_skilsmisse',
                            deactivated: false,
                            label: 'Skilsmisse',
                            alternativeLabels: [],
                            related: [
                              {
                                id: 'emneord_utroskap',
                                deactivated: false,
                                label: 'Utroskap',
                              },
                            ],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_slekter',
                        deactivated: false,
                        label: 'Slekter',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_soesken',
                        deactivated: false,
                        label: 'Søsken',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_broedre',
                            deactivated: false,
                            label: 'Brødre',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_soestre',
                            deactivated: false,
                            label: 'Søstre',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_tvillinger',
                            deactivated: false,
                            label: 'Tvillinger',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_kollektiver',
                    deactivated: false,
                    label: 'Kollektiver',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sjekking',
                    deactivated: false,
                    label: 'Sjekking',
                    alternativeLabels: ['Dating'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_sosialeendringer',
                deactivated: false,
                label: 'Sosiale endringer',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_framtid',
                    deactivated: false,
                    label: 'Framtid',
                    alternativeLabels: ['Fremtid'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_framtidsforskning',
                        deactivated: false,
                        label: 'Framtidsforskning',
                        alternativeLabels: [
                          'Framtidssamfunnet',
                          'Fremtidsforskning',
                          'Fremtidssamfunnet',
                          'Futurologi',
                          'Prognoser',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_reformer',
                    deactivated: false,
                    label: 'Reformer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_sosialegrupper',
                deactivated: false,
                label: 'Sosiale grupper',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_aldersgrupper',
                    deactivated: false,
                    label: 'Aldersgrupper',
                    alternativeLabels: ['Levetid', 'Livsløp'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_barn',
                        deactivated: false,
                        label: 'Barn',
                        alternativeLabels: ['Babyer', 'Spedbarn'],

                        related: [
                          {
                            id: 'emneord_barndom',
                            deactivated: false,
                            label: 'Barndom',
                          },
                        ],
                        children: [
                          {
                            id: 'emneord_krigsbarn',
                            deactivated: false,
                            label: 'Krigsbarn',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_roeverunger',
                            deactivated: false,
                            label: 'Røverunger',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_eldre',
                        deactivated: false,
                        label: 'Eldre',
                        alternativeLabels: ['Pensjonister'],

                        related: [
                          {
                            id: 'emneord_alderdom',
                            deactivated: false,
                            label: 'Alderdom',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'emneord_ungdom',
                        deactivated: false,
                        label: 'Ungdom',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_ungdomstid',
                            deactivated: false,
                            label: 'Ungdomstid',
                          },
                        ],
                        children: [
                          {
                            id: 'emneord_pubertet',
                            deactivated: false,
                            label: 'Pubertet',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_voksne',
                        deactivated: false,
                        label: 'Voksne',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_voksenlivet',
                            deactivated: false,
                            label: 'Voksenlivet',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_etniskegrupper',
                    deactivated: false,
                    label: 'Etniske grupper',
                    alternativeLabels: ['Etniske miljøer'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_folkegrupper',
                        deactivated: false,
                        label: 'Folkegrupper',
                        alternativeLabels: ['Folkeslag'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_asiater',
                            deactivated: false,
                            label: 'Asiater',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_fargede',
                            deactivated: false,
                            label: 'Fargede',
                            alternativeLabels: ['Mulatter', 'Svarte'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_hvite',
                            deactivated: false,
                            label: 'Hvite',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_keltere',
                            deactivated: false,
                            label: 'Keltere',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_kurdere',
                            deactivated: false,
                            label: 'Kurdere',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_kvener',
                            deactivated: false,
                            label: 'Kvener',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_naturfolk',
                            deactivated: false,
                            label: 'Naturfolk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_semitter',
                            deactivated: false,
                            label: 'Semitter',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_arabere',
                                deactivated: false,
                                label: 'Arabere',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_joeder',
                                deactivated: false,
                                label: 'Jøder',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_urbefolkning',
                            deactivated: false,
                            label: 'Urbefolkning',
                            alternativeLabels: [
                              'Eskimoer',
                              'Inuiter',
                              'Maorier',
                              'Urinnvånere',
                            ],

                            related: [],
                            children: [
                              {
                                id: 'emneord_aboriginere',
                                deactivated: false,
                                label: 'Aboriginere',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_amerikanskeurfolk',
                                deactivated: false,
                                label: 'Amerikanske urfolk',
                                alternativeLabels: [
                                  'Amerikansk urbefolkning',
                                  'Indianere',
                                ],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_samer',
                                deactivated: false,
                                label: 'Samer',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_vandrefolk',
                            deactivated: false,
                            label: 'Vandrefolk',
                            alternativeLabels: ['Nomader'],

                            related: [],
                            children: [
                              {
                                id: 'emneord_romanifolk',
                                deactivated: false,
                                label: 'Romanifolk',
                                alternativeLabels: ['Reisende', 'Tatere'],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_romfolk',
                                deactivated: false,
                                label: 'Romfolk',
                                alternativeLabels: ['Sigøynere'],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_skogfinner',
                                deactivated: false,
                                label: 'Skogfinner',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_vikinger',
                            deactivated: false,
                            label: 'Vikinger',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_innvandrere',
                        deactivated: false,
                        label: 'Innvandrere',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_minoriteter',
                        deactivated: false,
                        label: 'Minoriteter',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_afro-amerikanere',
                            deactivated: false,
                            label: 'Afro-amerikanere',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_spansk-amerikanere',
                            deactivated: false,
                            label: 'Spansk-amerikanere',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_lokalsamfunn',
                    deactivated: false,
                    label: 'Lokalsamfunn',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_byer',
                        deactivated: false,
                        label: 'Byer',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_drabantbyer',
                            deactivated: false,
                            label: 'Drabantbyer',
                            alternativeLabels: ['Forsteder'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_smaabyer',
                            deactivated: false,
                            label: 'Småbyer',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_storbyer',
                            deactivated: false,
                            label: 'Storbyer',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_bygder',
                        deactivated: false,
                        label: 'Bygder',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_naboskap',
                    deactivated: false,
                    label: 'Naboskap',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_sosialeproblemer',
                deactivated: false,
                label: 'Sosiale problemer',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_diskriminering',
                    deactivated: false,
                    label: 'Diskriminering',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_etniskekonflikter',
                        deactivated: false,
                        label: 'Etniske konflikter',
                      },
                    ],
                    children: [
                      {
                        id: 'emneord_antisemittisme',
                        deactivated: false,
                        label: 'Antisemittisme',
                        alternativeLabels: ['Jødeforfølgelse', 'Jødehat'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_apartheid',
                        deactivated: false,
                        label: 'Apartheid',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_rasisme',
                        deactivated: false,
                        label: 'Rasisme',
                        alternativeLabels: [
                          'Rasediskriminering',
                          'Rasefordommer',
                          'Raseskiller',
                        ],

                        related: [
                          {
                            id: 'emneord_etniskekonflikter',
                            deactivated: false,
                            label: 'Etniske konflikter',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'emneord_seksuelltrakassering',
                        deactivated: false,
                        label: 'Seksuell trakassering',
                        alternativeLabels: ['Metoo'],
                        note: 'Omfatter både verbal og fysisk atferd',
                        related: [
                          {
                            id: 'emneord_seksuelleovergrep',
                            deactivated: false,
                            label: 'Seksuelle overgrep',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_fattigdom',
                    deactivated: false,
                    label: 'Fattigdom',
                    alternativeLabels: ['Hungersnød', 'Sult'],

                    related: [
                      {
                        id: 'emneord_rikdom',
                        deactivated: false,
                        label: 'Rikdom',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'emneord_kriminologi',
                    deactivated: false,
                    label: 'Kriminologi',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_etterforskning',
                        deactivated: false,
                        label: 'Etterforskning',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kriminalitet',
                        deactivated: false,
                        label: 'Kriminalitet',
                        alternativeLabels: [
                          'Forbrytelser',
                          'Forbrytere',
                          'Forseelser',
                          'Kriminelle',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_datakriminalitet',
                            deactivated: false,
                            label: 'Datakriminalitet',
                            alternativeLabels: ['Cyberkriminalitet'],

                            related: [],
                            children: [
                              {
                                id: 'emneord_hacking',
                                deactivated: false,
                                label: 'Hacking',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_gjengkriminalitet',
                            deactivated: false,
                            label: 'Gjengkriminalitet',
                            alternativeLabels: ['Bander', 'Bandevirksomhet'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_hatkriminalitet',
                            deactivated: false,
                            label: 'Hatkriminalitet',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_menneskehandel',
                            deactivated: false,
                            label: 'Menneskehandel',
                            alternativeLabels: ['Trafficking'],

                            related: [
                              {
                                id: 'emneord_menneskesmugling',
                                deactivated: false,
                                label: 'Menneskesmugling',
                                note: 'Her: Organisert illegal flytting av mennesker fra et land til et annet',
                              },
                            ],
                            children: [],
                          },
                          {
                            id: 'emneord_menneskesmugling',
                            deactivated: false,
                            label: 'Menneskesmugling',
                            alternativeLabels: [],
                            note: 'Her: Organisert illegal flytting av mennesker fra et land til et annet',
                            related: [
                              {
                                id: 'emneord_flyktninger',
                                deactivated: false,
                                label: 'Flyktninger',
                              },
                              {
                                id: 'emneord_menneskehandel',
                                deactivated: false,
                                label: 'Menneskehandel',
                              },
                            ],
                            children: [],
                          },
                          {
                            id: 'emneord_miljoekriminalitet',
                            deactivated: false,
                            label: 'Miljøkriminalitet',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_narkotikahandel',
                            deactivated: false,
                            label: 'Narkotikahandel',
                            alternativeLabels: ['Narkotikasmugling'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_oekonomiskkriminalitet',
                            deactivated: false,
                            label: 'Økonomisk kriminalitet',
                            alternativeLabels: [
                              'Bedrageri',
                              'Smugling',
                              'Svindel',
                              'Underslag',
                            ],

                            related: [],
                            children: [
                              {
                                id: 'emneord_korrupsjon',
                                deactivated: false,
                                label: 'Korrupsjon',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_skatteunndragelse',
                                deactivated: false,
                                label: 'Skatteunndragelse',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_organisertkriminalitet',
                            deactivated: false,
                            label: 'Organisert kriminalitet',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_mafia',
                                deactivated: false,
                                label: 'Mafia',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_tyveri',
                            deactivated: false,
                            label: 'Tyveri',
                            alternativeLabels: ['Ran', 'Tyver'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_ungdomskriminalitet',
                            deactivated: false,
                            label: 'Ungdomskriminalitet',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_vold',
                            deactivated: false,
                            label: 'Vold',
                            alternativeLabels: ['Hærverk', 'Voldsforbrytelser'],

                            related: [],
                            children: [
                              {
                                id: 'emneord_doedshjelp',
                                deactivated: false,
                                label: 'Dødshjelp',
                                alternativeLabels: [
                                  'Aktiv dødshjelp',
                                  'Eutanasi',
                                  'Passiv dødshjelp',
                                ],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_drap',
                                deactivated: false,
                                label: 'Drap',
                                alternativeLabels: [
                                  'Forsettlig drap',
                                  'Mord',
                                  'Uaktsomt drap',
                                ],

                                related: [],
                                children: [
                                  {
                                    id: 'emneord_aeresdrap',
                                    deactivated: false,
                                    label: 'Æresdrap',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'emneord_familievold',
                                deactivated: false,
                                label: 'Familievold',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_kidnapping',
                                deactivated: false,
                                label: 'Kidnapping',
                                alternativeLabels: ['Bortførelse'],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_mishandling',
                                deactivated: false,
                                label: 'Mishandling',
                                alternativeLabels: [
                                  'Barnemishandling',
                                  'Overgrep',
                                ],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_politivold',
                                deactivated: false,
                                label: 'Politivold',
                                alternativeLabels: [],
                                related: [
                                  {
                                    id: 'emneord_politi',
                                    deactivated: false,
                                    label: 'Politi',
                                  },
                                ],
                                children: [],
                              },
                              {
                                id: 'emneord_seksuelleovergrep',
                                deactivated: false,
                                label: 'Seksuelle overgrep',
                                alternativeLabels: [
                                  'Metoo',
                                  'Seksuell mishandling',
                                ],

                                related: [
                                  {
                                    id: 'emneord_seksuelltrakassering',
                                    deactivated: false,
                                    label: 'Seksuell trakassering',
                                    note: 'Omfatter både verbal og fysisk atferd',
                                  },
                                ],
                                children: [
                                  {
                                    id: 'emneord_incest',
                                    deactivated: false,
                                    label: 'Incest',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'emneord_voldtekt',
                                    deactivated: false,
                                    label: 'Voldtekt',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'emneord_kriminalreform',
                        deactivated: false,
                        label: 'Kriminalreform',
                        alternativeLabels: ['Fengselsreform'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kriminalteknikk',
                        deactivated: false,
                        label: 'Kriminalteknikk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_straff',
                        deactivated: false,
                        label: 'Straff',
                        alternativeLabels: ['Penologi', 'Straffemetoder'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_boeter',
                            deactivated: false,
                            label: 'Bøter',
                            alternativeLabels: ['Bøtelegging'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_doedsstraff',
                            deactivated: false,
                            label: 'Dødsstraff',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_fengsling',
                            deactivated: false,
                            label: 'Fengsling',
                            alternativeLabels: [
                              'Arbeidsleire',
                              'Fengsler',
                              'Forvisning',
                              'Samvittighetsfanger',
                              'Sikring',
                              'Straffeanstalter',
                              'Straffekolonier',
                              'Varetektsfengsling',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_samfunnstjeneste',
                            deactivated: false,
                            label: 'Samfunnstjeneste',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_pornografi',
                    deactivated: false,
                    label: 'Pornografi',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_prostitusjon',
                    deactivated: false,
                    label: 'Prostitusjon',
                    alternativeLabels: ['Hallikvirksomhet'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_radikalisering',
                    deactivated: false,
                    label: 'Radikalisering',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_rusmisbruk',
                    deactivated: false,
                    label: 'Rusmisbruk',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_alkoholmisbruk',
                        deactivated: false,
                        label: 'Alkoholmisbruk',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_alkohol',
                            deactivated: false,
                            label: 'Alkohol',
                          },
                          {
                            id: 'emneord_alkoholisme',
                            deactivated: false,
                            label: 'Alkoholisme',
                          },
                        ],
                        children: [],
                      },
                      {
                        id: 'emneord_doping',
                        deactivated: false,
                        label: 'Doping',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_narkotikamisbruk',
                        deactivated: false,
                        label: 'Narkotikamisbruk',
                        alternativeLabels: [],
                        related: [
                          {
                            id: 'emneord_narkomani',
                            deactivated: false,
                            label: 'Narkomani',
                          },
                          {
                            id: 'emneord_narkotika',
                            deactivated: false,
                            label: 'Narkotika',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_selvmord',
                    deactivated: false,
                    label: 'Selvmord',
                    alternativeLabels: ['Selvvalgt livsavslutning'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sosialisolasjon',
                    deactivated: false,
                    label: 'Sosial isolasjon',
                    alternativeLabels: ['Fremmedgjøring', 'Sosialt nettverk'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sosialnoed',
                    deactivated: false,
                    label: 'Sosial nød',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_hjemloese',
                        deactivated: false,
                        label: 'Hjemløse',
                        alternativeLabels: ['Gatebarn', 'Uteliggere'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_sosialuro',
                    deactivated: false,
                    label: 'Sosial uro',
                    alternativeLabels: ['Opptøyer'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_tigging',
                    deactivated: false,
                    label: 'Tigging',
                    alternativeLabels: ['Tiggere'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_tobakkskader',
                    deactivated: false,
                    label: 'Tobakkskader',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_tobakk',
                        deactivated: false,
                        label: 'Tobakk',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_sosialkompetanse',
                deactivated: false,
                label: 'Sosial kompetanse',
                alternativeLabels: [],
                related: [
                  {
                    id: 'emneord_kommunikasjon',
                    deactivated: false,
                    label: 'Kommunikasjon',
                  },
                ],
                children: [],
              },
              {
                id: 'emneord_sosialkontroll',
                deactivated: false,
                label: 'Sosial kontroll',
                alternativeLabels: [],
                note: 'Her: brukes om begrensinger av individuell atferd gjennom f.eks. lederskap, sosiale normer, folkeopinion eller tvang',
                related: [],
                children: [],
              },
              {
                id: 'emneord_sosiallagdeling',
                deactivated: false,
                label: 'Sosial lagdeling',
                alternativeLabels: [
                  'Klasseanalyse',
                  'Klassestruktur',
                  'Sosial ulikhet',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_flytting',
                    deactivated: false,
                    label: 'Flytting',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_klasseforskjeller',
                    deactivated: false,
                    label: 'Klasseforskjeller',
                    alternativeLabels: ['Klasseskiller'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_klassekamp',
                    deactivated: false,
                    label: 'Klassekamp',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_klasser',
                    deactivated: false,
                    label: 'Klasser',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_arbeiderklassen',
                        deactivated: false,
                        label: 'Arbeiderklassen',
                        alternativeLabels: [
                          'Arbeiderbevegelsen',
                          'Arbeidere',
                          'Arbeidermiljøer',
                          'Industrimiljøer',
                          'Proletariatet',
                          'Rallare',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_middelklassen',
                        deactivated: false,
                        label: 'Middelklassen',
                        alternativeLabels: [
                          'Intellektuelle',
                          'Intelligentsia',
                          'Mellomlag',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_overklassen',
                        deactivated: false,
                        label: 'Overklassen',
                        alternativeLabels: [
                          'Adel',
                          'Adelige',
                          'Borgerskapet',
                          'Hoffmiljøer',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_kongelige',
                            deactivated: false,
                            label: 'Kongelige',
                            alternativeLabels: [
                              'Dronninger',
                              'Konger',
                              'Prinser',
                              'Prinsesser',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_riddere',
                            deactivated: false,
                            label: 'Riddere',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_outsidere',
                    deactivated: false,
                    label: 'Outsidere',
                    alternativeLabels: ['Undergrunnsmiljøer'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_profesjonssosiologi',
                    deactivated: false,
                    label: 'Profesjonssosiologi',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_rikdom',
                    deactivated: false,
                    label: 'Rikdom',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_fattigdom',
                        deactivated: false,
                        label: 'Fattigdom',
                      },
                      {
                        id: 'emneord_penger',
                        deactivated: false,
                        label: 'Penger',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'emneord_sosialmobilitet',
                    deactivated: false,
                    label: 'Sosial mobilitet',
                    alternativeLabels: ['Klassereiser'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sosialstatus',
                    deactivated: false,
                    label: 'Sosial status',
                    alternativeLabels: ['Statussymboler'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_sosialvelferd',
                deactivated: false,
                label: 'Sosial velferd',
                alternativeLabels: [
                  'Sosial trygghet',
                  'Sosialomsorg',
                  'Sosialpolitikk',
                  'Velferdssamfunnet',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_helse-ogsosialvesen',
                    deactivated: false,
                    label: 'Helse- og sosialvesen',
                    alternativeLabels: [
                      'Helsepolitikk',
                      'Helsevesen',
                      'Sosialvesen',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_bedriftshelsetjeneste',
                        deactivated: false,
                        label: 'Bedriftshelsetjeneste',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_behandlingshjem',
                        deactivated: false,
                        label: 'Behandlingshjem',
                        alternativeLabels: [
                          'Aldershjem',
                          'Behandlingsinstitusjoner',
                          'Sykehjem',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_helseinstitusjoner',
                        deactivated: false,
                        label: 'Helseinstitusjoner',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_helsestasjoner',
                            deactivated: false,
                            label: 'Helsestasjoner',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_sykehus',
                            deactivated: false,
                            label: 'Sykehus',
                            alternativeLabels: [
                              'Klinikker',
                              'Psykiatriske sykehus',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_helse-ogomsorgstjenester',
                        deactivated: false,
                        label: 'Helse- og omsorgstjenester',
                        alternativeLabels: [
                          'Helsetjenester',
                          'Omsorgstjenester',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_eldreomsorg',
                            deactivated: false,
                            label: 'Eldreomsorg',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_velferdsteknologi',
                            deactivated: false,
                            label: 'Velferdsteknologi',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_paaroerende',
                        deactivated: false,
                        label: 'Pårørende',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_pasienter',
                        deactivated: false,
                        label: 'Pasienter',
                        alternativeLabels: ['Klienter'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_noedetater',
                    deactivated: false,
                    label: 'Nødetater',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_ambulansetjenester',
                        deactivated: false,
                        label: 'Ambulansetjenester',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_brannvesen',
                        deactivated: false,
                        label: 'Brannvesen',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_brannkonstabler',
                            deactivated: false,
                            label: 'Brannkonstabler',
                            alternativeLabels: ['Brannmenn'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_politi',
                        deactivated: false,
                        label: 'Politi',
                        alternativeLabels: [
                          'Lensmenn',
                          'Ordensmakt',
                          'Overvåkingspoliti',
                          'Politiet',
                        ],

                        related: [
                          {
                            id: 'emneord_politivold',
                            deactivated: false,
                            label: 'Politivold',
                          },
                        ],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_noedhjelp',
                    deactivated: false,
                    label: 'Nødhjelp',
                    alternativeLabels: ['Katastrofehjelp'],

                    related: [
                      {
                        id: 'emneord_bistand',
                        deactivated: false,
                        label: 'Bistand',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'emneord_redningstjenester',
                    deactivated: false,
                    label: 'Redningstjenester',
                    alternativeLabels: [
                      'Redningsaksjoner',
                      'Sjøredningstjenester',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sosialarbeid',
                    deactivated: false,
                    label: 'Sosialarbeid',
                    alternativeLabels: ['Sosialt arbeid', 'Sosionomer'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_barnevern',
                        deactivated: false,
                        label: 'Barnevern',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_barnehjem',
                            deactivated: false,
                            label: 'Barnehjem',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_fosterhjem',
                            deactivated: false,
                            label: 'Fosterhjem',
                            alternativeLabels: [
                              'Fosterbarn',
                              'Fosterfamilier',
                              'Fosterforeldre',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_oppsoekendearbeid',
                        deactivated: false,
                        label: 'Oppsøkende arbeid',
                        alternativeLabels: ['Feltarbeid', 'Hjemmehjelp'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_sosialraadgivning',
                        deactivated: false,
                        label: 'Sosialrådgivning',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_vernepleie',
                        deactivated: false,
                        label: 'Vernepleie',
                        alternativeLabels: ['Vernepleier'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_sosialehjelpetiltak',
                    deactivated: false,
                    label: 'Sosiale hjelpetiltak',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_humanitaereorganisasjoner',
                        deactivated: false,
                        label: 'Humanitære organisasjoner',
                        alternativeLabels: ['Veldedighet'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_sosialeytelser',
                    deactivated: false,
                    label: 'Sosiale ytelser',
                    alternativeLabels: [
                      'Arbeidsledighetstrygd',
                      'Barnetrygd',
                      'Bostøtte',
                      'Sosialhjelp',
                      'Sosialtrygd',
                      'Stønader',
                      'Sykelønn',
                      'Syketrygd',
                      'Trygder',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_pensjoner',
                        deactivated: false,
                        label: 'Pensjoner',
                        alternativeLabels: [
                          'Alderspensjon',
                          'Folkepensjon',
                          'Folketrygd',
                          'Krigspensjon',
                          'Uføretrygd',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_statsvitenskap',
            deactivated: false,
            label: 'Statsvitenskap',
            alternativeLabels: ['Politologi'],

            related: [],
            children: [
              {
                id: 'emneord_politikk',
                deactivated: false,
                label: 'Politikk',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_aktivisme',
                    deactivated: false,
                    label: 'Aktivisme',
                    alternativeLabels: ['Politisk aktivisme'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_identitetspolitikk',
                    deactivated: false,
                    label: 'Identitetspolitikk',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_internasjonalpolitikk',
                    deactivated: false,
                    label: 'Internasjonal politikk',
                    alternativeLabels: [
                      'Mellomfolkelige forhold',
                      'Utenrikspolitikk',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_bistand',
                        deactivated: false,
                        label: 'Bistand',
                        alternativeLabels: [
                          'Norad',
                          'U-hjelp',
                          'Ulandshjelp',
                          'Utviklingshjelp',
                        ],

                        related: [
                          {
                            id: 'emneord_noedhjelp',
                            deactivated: false,
                            label: 'Nødhjelp',
                          },
                        ],
                        children: [
                          {
                            id: 'emneord_utviklingsland',
                            deactivated: false,
                            label: 'Utviklingsland',
                            alternativeLabels: [
                              'Den tredje verden',
                              'Tredje verden',
                              'U-land',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_diplomati',
                        deactivated: false,
                        label: 'Diplomati',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_globalisering',
                        deactivated: false,
                        label: 'Globalisering',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_internasjonaleorganisasjoner',
                        deactivated: false,
                        label: 'Internasjonale organisasjoner',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_forentenasjoner',
                            deactivated: false,
                            label: 'Forente nasjoner',
                            alternativeLabels: ['FN', 'Folkeforbundet'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_internasjonaltsamarbeid',
                        deactivated: false,
                        label: 'Internasjonalt samarbeid',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_krigogfred',
                    deactivated: false,
                    label: 'Krig og fred',
                    alternativeLabels: ['Forsvarsdebatt', 'Forsvarspolitikk'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_forsvar',
                        deactivated: false,
                        label: 'Forsvar',
                        alternativeLabels: ['Totalforsvar'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_ikkevoldsforsvar',
                            deactivated: false,
                            label: 'Ikkevoldsforsvar',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_militaerforsvar',
                            deactivated: false,
                            label: 'Militærforsvar',
                            alternativeLabels: [
                              'Militær strategi',
                              'Militær taktikk',
                              'Militærvesen',
                            ],

                            related: [],
                            children: [
                              {
                                id: 'emneord_forsvarsgrener',
                                deactivated: false,
                                label: 'Forsvarsgrener',
                                alternativeLabels: ['Våpengrener'],

                                related: [],
                                children: [
                                  {
                                    id: 'emneord_flyvaapenet',
                                    deactivated: false,
                                    label: 'Flyvåpenet',
                                    alternativeLabels: [
                                      'Luftforsvaret',
                                      'Luftstridskrefter',
                                    ],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'emneord_haeren',
                                    deactivated: false,
                                    label: 'Hæren',
                                    alternativeLabels: [
                                      'Artilleri',
                                      'Infanteri',
                                      'Kavaleri',
                                      'Landstridskrefter',
                                      'Panser',
                                    ],

                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'emneord_marinen',
                                    deactivated: false,
                                    label: 'Marinen',
                                    alternativeLabels: [
                                      'Flåten',
                                      'Kystartilleriet',
                                      'Kystvakten',
                                      'Sjøstridskrefter',
                                    ],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'emneord_fredsbevarendeoperasjoner',
                                deactivated: false,
                                label: 'Fredsbevarende operasjoner',
                                alternativeLabels: [
                                  'Fredsbevarende styrker',
                                  'Fredsopprettende operasjoner',
                                ],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_militaere',
                                deactivated: false,
                                label: 'Militære',
                                alternativeLabels: [],
                                related: [],
                                children: [
                                  {
                                    id: 'emneord_soldater',
                                    deactivated: false,
                                    label: 'Soldater',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [
                                      {
                                        id: 'emneord_frontkjempere',
                                        deactivated: false,
                                        label: 'Frontkjempere',
                                        alternativeLabels: [],
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'emneord_krigsveteraner',
                                        deactivated: false,
                                        label: 'Krigsveteraner',
                                        alternativeLabels: ['Militærveteraner'],

                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                id: 'emneord_militaerhistorie',
                                deactivated: false,
                                label: 'Militærhistorie',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_verneplikt',
                                deactivated: false,
                                label: 'Verneplikt',
                                alternativeLabels: [
                                  'Heimevernet',
                                  'Militærtjeneste',
                                  'Mobilisering',
                                  'Rekruttering',
                                  'Verving',
                                ],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_sikkerhetspolitikk',
                            deactivated: false,
                            label: 'Sikkerhetspolitikk',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_etterretning',
                                deactivated: false,
                                label: 'Etterretning',
                                alternativeLabels: [
                                  'Hemmelige tjenester',
                                  'Spionasje',
                                ],

                                related: [],
                                children: [
                                  {
                                    id: 'emneord_etterretningsorganisasjoner',
                                    deactivated: false,
                                    label: 'Etterretningsorganisasjoner',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [
                                      {
                                        id: 'emneord_cia',
                                        deactivated: false,
                                        label: 'CIA',
                                        alternativeLabels: [
                                          'Central Intelligence Agency',
                                        ],

                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'emneord_etterretningstjenesten',
                                        deactivated: false,
                                        label: 'Etterretningstjenesten',
                                        alternativeLabels: [
                                          'Forsvarets etterretningstjeneste',
                                        ],

                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'emneord_fbi',
                                        deactivated: false,
                                        label: 'FBI',
                                        alternativeLabels: [
                                          'Federal Bureau of Investigation',
                                        ],

                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'emneord_gestapo',
                                        deactivated: false,
                                        label: 'Gestapo',
                                        alternativeLabels: [],
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'emneord_kgb',
                                        deactivated: false,
                                        label: 'KGB',
                                        alternativeLabels: [],
                                        note: 'her: etterretningstjenesten i Sovjetunionen',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'emneord_mi5',
                                        deactivated: false,
                                        label: 'MI5',
                                        alternativeLabels: [
                                          'The Security Service',
                                        ],

                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'emneord_mi6',
                                        deactivated: false,
                                        label: 'MI6',
                                        alternativeLabels: [
                                          'Secret Intelligence Service',
                                          'SIS',
                                        ],

                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'emneord_mossad',
                                        deactivated: false,
                                        label: 'Mossad',
                                        alternativeLabels: [],
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'emneord_nsa',
                                        deactivated: false,
                                        label: 'NSA',
                                        alternativeLabels: [
                                          'National Security Agency',
                                        ],

                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'emneord_pst',
                                        deactivated: false,
                                        label: 'PST',
                                        alternativeLabels: [
                                          'Politiets sikkerhetstjeneste',
                                        ],

                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'emneord_stasi',
                                        deactivated: false,
                                        label: 'Stasi',
                                        alternativeLabels: [],
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'emneord_svr',
                                        deactivated: false,
                                        label: 'SVR',
                                        alternativeLabels: [],
                                        note: 'her:  Utenriksetterretningstjenesten i Russland etter Sovjetunionens sammenbrudd',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'emneord_xu',
                                        deactivated: false,
                                        label: 'XU',
                                        alternativeLabels: [],
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'emneord_overvaaking',
                                    deactivated: false,
                                    label: 'Overvåking',
                                    alternativeLabels: [
                                      'Overvåkingstjenester',
                                      'Overvåkning',
                                    ],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'emneord_forsvarspakter',
                                deactivated: false,
                                label: 'Forsvarspakter',
                                alternativeLabels: [
                                  'Forsvarsblokker',
                                  'Militærallianser',
                                  'Warszawapakten',
                                ],

                                related: [],
                                children: [
                                  {
                                    id: 'emneord_nato',
                                    deactivated: false,
                                    label: 'NATO',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'emneord_militaerbalanse',
                                deactivated: false,
                                label: 'Militærbalanse',
                                alternativeLabels: ['Maktbalanse'],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_sivilforsvar',
                            deactivated: false,
                            label: 'Sivilforsvar',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_fredsarbeid',
                        deactivated: false,
                        label: 'Fredsarbeid',
                        alternativeLabels: ['Fredsbevegelser'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_fredsforskning',
                            deactivated: false,
                            label: 'Fredsforskning',
                            alternativeLabels: ['Konfliktforskning'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_militaernekting',
                            deactivated: false,
                            label: 'Militærnekting',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_nedrustning',
                            deactivated: false,
                            label: 'Nedrustning',
                            alternativeLabels: ['Rustningskontroll'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_nobelsfredspris',
                            deactivated: false,
                            label: 'Nobels fredspris',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_pasifisme',
                            deactivated: false,
                            label: 'Pasifisme',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_motstandsarbeid',
                        deactivated: false,
                        label: 'Motstandsarbeid',
                        alternativeLabels: [
                          'Hjemmefronten',
                          'Motstandsbevegelser',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_vaepnedekonflikter',
                        deactivated: false,
                        label: 'Væpnede konflikter',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_fremmedkrigere',
                            deactivated: false,
                            label: 'Fremmedkrigere',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_krig',
                            deactivated: false,
                            label: 'Krig',
                            alternativeLabels: ['Krigføring'],

                            related: [
                              {
                                id: 'emneord_verdenskriger',
                                deactivated: false,
                                label: 'Verdenskriger',
                              },
                            ],
                            children: [
                              {
                                id: 'emneord_atomkrig',
                                deactivated: false,
                                label: 'Atomkrig',
                                alternativeLabels: ['Atomstrategi'],

                                related: [],
                                children: [
                                  {
                                    id: 'emneord_atomvaapen',
                                    deactivated: false,
                                    label: 'Atomvåpen',
                                    alternativeLabels: [
                                      'Atombomber',
                                      'Kjernefysiske våpen',
                                    ],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'emneord_borgerkrig',
                                deactivated: false,
                                label: 'Borgerkrig',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_fangeleirer',
                                deactivated: false,
                                label: 'Fangeleirer',
                                alternativeLabels: ['Arbeidsleirer'],

                                related: [],
                                children: [
                                  {
                                    id: 'emneord_konsentrasjonsleirer',
                                    deactivated: false,
                                    label: 'Konsentrasjonsleirer',
                                    alternativeLabels: ['Krigsfanger'],

                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'emneord_geriljakrig',
                                deactivated: false,
                                label: 'Geriljakrig',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_kjemiskkrigfoering',
                                deactivated: false,
                                label: 'Kjemisk krigføring',
                                alternativeLabels: [
                                  'Bakteriologisk krigføring',
                                  'Nervegass',
                                ],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_krigsforbrytelser',
                                deactivated: false,
                                label: 'Krigsforbrytelser',
                                alternativeLabels: [],
                                related: [
                                  {
                                    id: 'emneord_folkemordogetniskrensing',
                                    deactivated: false,
                                    label: 'Folkemord og etnisk rensing',
                                  },
                                ],
                                children: [],
                              },
                              {
                                id: 'emneord_religionskrig',
                                deactivated: false,
                                label: 'Religionskrig',
                                alternativeLabels: ['Hellig krig', 'Jihad'],

                                related: [],
                                children: [
                                  {
                                    id: 'emneord_korstog',
                                    deactivated: false,
                                    label: 'Korstog',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: 'emneord_terrorisme',
                            deactivated: false,
                            label: 'Terrorisme',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_22.juli-terroren',
                                deactivated: false,
                                label: '22. juli-terroren',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_vaapenhandel',
                            deactivated: false,
                            label: 'Våpenhandel',
                            alternativeLabels: ['Våpensmugling'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_kulturpolitikk',
                    deactivated: false,
                    label: 'Kulturpolitikk',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_menneskerettigheter',
                    deactivated: false,
                    label: 'Menneskerettigheter',
                    alternativeLabels: [
                      'Barns rettigheter',
                      'Borgerrettigheter',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_organisasjonsfrihet',
                        deactivated: false,
                        label: 'Organisasjonsfrihet',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_religionsfrihet',
                        deactivated: false,
                        label: 'Religionsfrihet',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_rettssikkerhet',
                        deactivated: false,
                        label: 'Rettssikkerhet',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_ytringsfrihet',
                        deactivated: false,
                        label: 'Ytringsfrihet',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_miljoepolitikk',
                    deactivated: false,
                    label: 'Miljøpolitikk',
                    alternativeLabels: ['Miljøsosiologi'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_baerekraftigutvikling',
                        deactivated: false,
                        label: 'Bærekraftig utvikling',
                        alternativeLabels: ['Bærekraft'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_politikere',
                    deactivated: false,
                    label: 'Politikere',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_presidenter',
                        deactivated: false,
                        label: 'Presidenter',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_politiskemiljoeer',
                    deactivated: false,
                    label: 'Politiske miljøer',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_avhoppere',
                        deactivated: false,
                        label: 'Avhoppere',
                        alternativeLabels: [],
                        note: 'Her: personer som hopper av fra et totalitært regime, eller som forlater sitt politiske parti',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_politiskepartier',
                    deactivated: false,
                    label: 'Politiske partier',
                    alternativeLabels: ['Partier'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_fascistiskepartier',
                        deactivated: false,
                        label: 'Fascistiske partier',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_nasjonalsamling',
                            deactivated: false,
                            label: 'Nasjonal samling',
                            alternativeLabels: ['NS'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_konservativepartier',
                        deactivated: false,
                        label: 'Konservative partier',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_fremskrittspartiet',
                            deactivated: false,
                            label: 'Fremskrittspartiet',
                            alternativeLabels: [
                              'FpU',
                              'Fremskrittspartiets ungdom',
                              'FrP',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_hoeyre',
                            deactivated: false,
                            label: 'Høyre',
                            alternativeLabels: ['Unge Høyre'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_miljoepartietdegroenne',
                        deactivated: false,
                        label: 'Miljøpartiet de grønne',
                        alternativeLabels: ['Grønn ungdom', 'MDG'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_religioesepartier',
                        deactivated: false,
                        label: 'Religiøse partier',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_kristeligfolkeparti',
                            deactivated: false,
                            label: 'Kristelig folkeparti',
                            alternativeLabels: [
                              'KrF',
                              'KrFU',
                              'Kristelig folkepartis ungdom',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_sentrumspartier',
                        deactivated: false,
                        label: 'Sentrumspartier',
                        alternativeLabels: ['Liberale partier'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_liberalefolkepartiet',
                            deactivated: false,
                            label: 'Liberale folkepartiet',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_senterpartiet',
                            deactivated: false,
                            label: 'Senterpartiet',
                            alternativeLabels: [
                              'Bondepartiet',
                              'Senterungdommen',
                              'SP',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_venstre',
                            deactivated: false,
                            label: 'Venstre',
                            alternativeLabels: ['Unge Venstre'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_sosialistiskepartier',
                        deactivated: false,
                        label: 'Sosialistiske partier',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_kommunistiskepartier',
                            deactivated: false,
                            label: 'Kommunistiske partier',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_arbeiderneskommunistparti',
                                deactivated: false,
                                label: 'Arbeidernes kommunistparti',
                                alternativeLabels: ['AKP', 'AKP m-l'],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_norgeskommunistiskeparti',
                                deactivated: false,
                                label: 'Norges kommunistiske parti',
                                alternativeLabels: ['NKP'],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_roedt',
                            deactivated: false,
                            label: 'Rødt',
                            alternativeLabels: [
                              'RU',
                              'RV',
                              'Rød ungdom',
                              'Rød valgallianse',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_sosialdemokratiskepartier',
                            deactivated: false,
                            label: 'Sosialdemokratiske partier',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_arbeiderpartiet',
                                deactivated: false,
                                label: 'Arbeiderpartiet',
                                alternativeLabels: [
                                  'AP',
                                  'AUF',
                                  'Det norske arbeiderparti',
                                  'DNA',
                                ],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_sosialistiskvenstreparti',
                            deactivated: false,
                            label: 'Sosialistisk venstreparti',
                            alternativeLabels: [
                              'SF',
                              'Sosialistisk folkeparti',
                              'Sosialistisk ungdom',
                              'SV',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_politiskevalg',
                    deactivated: false,
                    label: 'Politiske valg',
                    alternativeLabels: [
                      'Folkeavstemninger',
                      'Kommunevalg',
                      'Referendum',
                      'Stortingsvalg',
                      'Valg',
                      'Valgordning',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_stemmerett',
                        deactivated: false,
                        label: 'Stemmerett',
                        alternativeLabels: ['Stemmerettsalder'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_valgforskning',
                        deactivated: false,
                        label: 'Valgforskning',
                        alternativeLabels: [
                          'Valganalyser',
                          'Valgarbeid',
                          'Valgkamper',
                          'Valgresultater',
                          'Velgerundersøkelser',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_politiskstyring',
                    deactivated: false,
                    label: 'Politisk styring',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_distriktsutbygging',
                        deactivated: false,
                        label: 'Distriktsutbygging',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_folkevalgteorganer',
                        deactivated: false,
                        label: 'Folkevalgte organer',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_lokalemyndigheter',
                            deactivated: false,
                            label: 'Lokale myndigheter',
                            alternativeLabels: [
                              'Formannskap',
                              'Fylkesting',
                              'Kommunestyrer',
                              'Lokaldemokrati',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_nasjonalforsamlinger',
                            deactivated: false,
                            label: 'Nasjonalforsamlinger',
                            alternativeLabels: [
                              'Lovgivende forsamlinger',
                              'Parlamenter',
                              'Regjering',
                            ],

                            related: [],
                            children: [
                              {
                                id: 'emneord_sametinget',
                                deactivated: false,
                                label: 'Sametinget',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_stortinget',
                                deactivated: false,
                                label: 'Stortinget',
                                alternativeLabels: ['Lagtinget', 'Odelstinget'],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'emneord_politiskebeslutninger',
                        deactivated: false,
                        label: 'Politiske beslutninger',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_demonstrasjoner',
                            deactivated: false,
                            label: 'Demonstrasjoner',
                            alternativeLabels: ['Protester'],

                            related: [],
                            children: [
                              {
                                id: 'emneord_sivilulydighet',
                                deactivated: false,
                                label: 'Sivil ulydighet',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_opinionsdannelse',
                            deactivated: false,
                            label: 'Opinionsdannelse',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_opinionsundersoekelser',
                                deactivated: false,
                                label: 'Opinionsundersøkelser',
                                alternativeLabels: [
                                  'Gallup',
                                  'Gallupundersøkelser',
                                  'Meningsmålinger',
                                ],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_propaganda',
                                deactivated: false,
                                label: 'Propaganda',
                                alternativeLabels: ['Indoktrinering'],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_pressgrupper',
                            deactivated: false,
                            label: 'Pressgrupper',
                            alternativeLabels: [
                              'Interesseorganisasjoner',
                              'Lobbyvirksomhet',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_politiskeinstitusjoner',
                        deactivated: false,
                        label: 'Politiske institusjoner',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_statsmakt',
                        deactivated: false,
                        label: 'Statsmakt',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_rettsvesen',
                            deactivated: false,
                            label: 'Rettsvesen',
                            alternativeLabels: [
                              'Domstoler',
                              'Forliksråd',
                              'Juridisk bistand',
                              'Konfliktråd',
                              'Rettshjelp',
                              'Rettssosiologi',
                              'Rettsstell',
                              'Sivile domstoler',
                              'Tvistemål',
                              'Voldgift',
                            ],

                            related: [],
                            children: [
                              {
                                id: 'emneord_rettssaker',
                                deactivated: false,
                                label: 'Rettssaker',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_revolusjon',
                            deactivated: false,
                            label: 'Revolusjon',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_statsformer',
                            deactivated: false,
                            label: 'Statsformer',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_demokrati',
                                deactivated: false,
                                label: 'Demokrati',
                                alternativeLabels: [],
                                related: [],
                                children: [
                                  {
                                    id: 'emneord_parlamentarisme',
                                    deactivated: false,
                                    label: 'Parlamentarisme',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'emneord_diktatur',
                                deactivated: false,
                                label: 'Diktatur',
                                alternativeLabels: [
                                  'Militærdiktatur',
                                  'Totalitære samfunn',
                                ],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_forbundsstater',
                                deactivated: false,
                                label: 'Forbundsstater',
                                alternativeLabels: ['Føderalisme'],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_monarki',
                                deactivated: false,
                                label: 'Monarki',
                                alternativeLabels: ['Kongedømme'],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_republikk',
                                deactivated: false,
                                label: 'Republikk',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_statskupp',
                            deactivated: false,
                            label: 'Statskupp',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_statsteori',
                            deactivated: false,
                            label: 'Statsteori',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_politiskteori',
                    deactivated: false,
                    label: 'Politisk teori',
                    alternativeLabels: [
                      'Ideologier',
                      'Politisk filosofi',
                      'Politisk ideologi',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_ekstremisme',
                        deactivated: false,
                        label: 'Ekstremisme',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_hoeyreekstremisme',
                            deactivated: false,
                            label: 'Høyreekstremisme',
                            alternativeLabels: ['Høyreradikalisme'],

                            related: [],
                            children: [
                              {
                                id: 'emneord_nynazisme',
                                deactivated: false,
                                label: 'Nynazisme',
                                alternativeLabels: [],
                                related: [
                                  {
                                    id: 'emneord_nazisme',
                                    deactivated: false,
                                    label: 'Nazisme',
                                  },
                                ],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_venstreekstremisme',
                            deactivated: false,
                            label: 'Venstreekstremisme',
                            alternativeLabels: ['Venstreradikalisme'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_feminisme',
                        deactivated: false,
                        label: 'Feminisme',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_internasjonalisme',
                        deactivated: false,
                        label: 'Internasjonalisme',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_islamisme',
                        deactivated: false,
                        label: 'Islamisme',
                        alternativeLabels: ['Islamsk fundamentalisme'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kolonialisme',
                        deactivated: false,
                        label: 'Kolonialisme',
                        alternativeLabels: ['Kolonier', 'Kolonitiden'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_avkolonisering',
                            deactivated: false,
                            label: 'Avkolonisering',
                            alternativeLabels: ['Dekolonisering'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_konservatisme',
                        deactivated: false,
                        label: 'Konservatisme',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_nasjonalisme',
                        deactivated: false,
                        label: 'Nasjonalisme',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_pluralisme',
                        deactivated: false,
                        label: 'Pluralisme',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_anarkisme',
                            deactivated: false,
                            label: 'Anarkisme',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_liberalisme',
                            deactivated: false,
                            label: 'Liberalisme',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_nyliberalisme',
                                deactivated: false,
                                label: 'Nyliberalisme',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_sosialliberalisme',
                                deactivated: false,
                                label: 'Sosialliberalisme',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'emneord_populisme',
                        deactivated: false,
                        label: 'Populisme',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_sosialisme',
                        deactivated: false,
                        label: 'Sosialisme',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_kommunisme',
                            deactivated: false,
                            label: 'Kommunisme',
                            alternativeLabels: ['Maoisme'],

                            related: [],
                            children: [
                              {
                                id: 'emneord_marxisme',
                                deactivated: false,
                                label: 'Marxisme',
                                alternativeLabels: ['Marxisme-leninisme'],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'emneord_totalitarisme',
                        deactivated: false,
                        label: 'Totalitarisme',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_fascisme',
                            deactivated: false,
                            label: 'Fascisme',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_nazisme',
                                deactivated: false,
                                label: 'Nazisme',
                                alternativeLabels: ['Nasjonalsosialisme'],

                                related: [
                                  {
                                    id: 'emneord_nynazisme',
                                    deactivated: false,
                                    label: 'Nynazisme',
                                  },
                                ],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_ulykker',
            deactivated: false,
            label: 'Ulykker',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_branner',
                deactivated: false,
                label: 'Branner',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_ulykkesforebygging',
                deactivated: false,
                label: 'Ulykkesforebygging',
                alternativeLabels: [
                  'Brannvern',
                  'Hjemmeulykker',
                  'Sikkerhetstiltak',
                  'Vernetiltak',
                ],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'emneord_spraakoglitteratur',
        deactivated: false,
        label: 'Språk og litteratur',
        alternativeLabels: ['Filologi'],

        related: [],
        children: [
          {
            id: 'emneord_litteratur',
            deactivated: false,
            label: 'Litteratur',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_forfattere',
                deactivated: false,
                label: 'Forfattere',
                alternativeLabels: ['Diktere', 'Skribenter'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_litteraturformer',
                deactivated: false,
                label: 'Litteraturformer',
                alternativeLabels: ['Litteraturgenre'],

                related: [],
                children: [
                  {
                    id: 'emneord_barne-ogungdomslitteratur',
                    deactivated: false,
                    label: 'Barne- og ungdomslitteratur',
                    alternativeLabels: ['Barnelitteratur', 'Ungdomslitteratur'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_essay',
                    deactivated: false,
                    label: 'Essay',
                    alternativeLabels: [],
                    note: 'Her: essay som emne',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_fantastisklitteratur',
                    deactivated: false,
                    label: 'Fantastisk litteratur',
                    alternativeLabels: [],
                    note: 'Her: fantastisk litteratur som emne',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kvinnelitteratur',
                    deactivated: false,
                    label: 'Kvinnelitteratur',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_lyrikk',
                    deactivated: false,
                    label: 'Lyrikk',
                    alternativeLabels: [],
                    note: 'Her: lyrikk som emne',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_noveller',
                    deactivated: false,
                    label: 'Noveller',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_poetikk',
                    deactivated: false,
                    label: 'Poetikk',
                    alternativeLabels: ['Diktekunst'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_populaerlitteratur',
                    deactivated: false,
                    label: 'Populærlitteratur',
                    alternativeLabels: [
                      'Triviallitteratur',
                      'Ukeblader',
                      'Underholdningslitteratur',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_romaner',
                    deactivated: false,
                    label: 'Romaner',
                    alternativeLabels: [],
                    note: 'Her: romaner som emne',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sakprosa',
                    deactivated: false,
                    label: 'Sakprosa',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_skuespill',
                    deactivated: false,
                    label: 'Skuespill',
                    alternativeLabels: ['Dramaer'],
                    note: 'Her: skuespill som emne',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_spenningslitteratur',
                    deactivated: false,
                    label: 'Spenningslitteratur',
                    alternativeLabels: ['Kriminallitteratur'],
                    note: 'Her: spenningslitteratur som emne',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_tegneserier',
                    deactivated: false,
                    label: 'Tegneserier',
                    alternativeLabels: [],
                    note: 'Her: tegneserier som emne',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_tidsskrift',
                    deactivated: false,
                    label: 'Tidsskrift',
                    alternativeLabels: ['Periodika'],
                    note: 'Her: tidsskrifter som emne',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_litteraturformidling',
                deactivated: false,
                label: 'Litteraturformidling',
                alternativeLabels: [
                  'Litteraturpedagogikk',
                  'Litteratursosiologi',
                ],

                related: [],
                children: [],
              },
              {
                id: 'emneord_litteraturhistorie',
                deactivated: false,
                label: 'Litteraturhistorie',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_litteraereperioder',
                    deactivated: false,
                    label: 'Litterære perioder',
                    alternativeLabels: ['Litterære retninger'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_litteraturteori',
                deactivated: false,
                label: 'Litteraturteori',
                alternativeLabels: ['Litteraturforskning', 'Litterær metode'],

                related: [],
                children: [
                  {
                    id: 'emneord_litteraeranalyse',
                    deactivated: false,
                    label: 'Litterær analyse',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_forfatterskap',
                        deactivated: false,
                        label: 'Forfatterskap',
                        note: 'Her om det å skrive',
                      },
                    ],
                    children: [],
                  },
                  {
                    id: 'emneord_litteraturkritikk',
                    deactivated: false,
                    label: 'Litteraturkritikk',
                    alternativeLabels: ['Bokanmeldelser'],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_spraak',
            deactivated: false,
            label: 'Språk',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'emneord_deenkeltespraak',
                deactivated: false,
                label: 'De enkelte språk',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_afrikanskespraak',
                    deactivated: false,
                    label: 'Afrikanske språk',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_niger-kongospraak',
                        deactivated: false,
                        label: 'Niger-kongo språk',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_bantu',
                            deactivated: false,
                            label: 'Bantu',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_ibo',
                            deactivated: false,
                            label: 'Ibo',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_swahili',
                            deactivated: false,
                            label: 'Swahili',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_zulu',
                            deactivated: false,
                            label: 'Zulu',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_sudanskespraak',
                        deactivated: false,
                        label: 'Sudanske språk',
                        alternativeLabels: ['Nubiske språk'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_afroasiatiskespraak',
                    deactivated: false,
                    label: 'Afroasiatiske språk',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_hamittiskespraak',
                        deactivated: false,
                        label: 'Hamittiske språk',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_berber-spraak',
                            deactivated: false,
                            label: 'Berber-språk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_koptisk',
                            deactivated: false,
                            label: 'Koptisk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_kusjittiskespraak',
                        deactivated: false,
                        label: 'Kusjittiske språk',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_oromo',
                            deactivated: false,
                            label: 'Oromo',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_somali',
                            deactivated: false,
                            label: 'Somali',
                            alternativeLabels: ['Somalisk språk'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_semittiskespraak',
                        deactivated: false,
                        label: 'Semittiske språk',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_arabisk',
                            deactivated: false,
                            label: 'Arabisk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_arameiskespraak',
                            deactivated: false,
                            label: 'Arameiske språk',
                            alternativeLabels: [
                              'Syrisk',
                              'Øst arameiske språk',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_etiopiskspraak',
                            deactivated: false,
                            label: 'Etiopisk språk',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_tigrinja',
                                deactivated: false,
                                label: 'Tigrinja',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_hebraisk',
                            deactivated: false,
                            label: 'Hebraisk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_amerikanskeurfolkspraak',
                    deactivated: false,
                    label: 'Amerikanske urfolkspråk',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_eskimoisk-aleutiskespraak',
                        deactivated: false,
                        label: 'Eskimoisk-aleutiske språk',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_aleutisk',
                            deactivated: false,
                            label: 'Aleutisk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_eskimoisk',
                            deactivated: false,
                            label: 'Eskimoisk',
                            alternativeLabels: [
                              'Grønlandsk språk',
                              'Inuitspråk',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_indianerspraak',
                        deactivated: false,
                        label: 'Indianerspråk',
                        alternativeLabels: [],
                        note: 'Her: språk snakket av amerikanske urfolk',
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_asiatiskespraak',
                    deactivated: false,
                    label: 'Asiatiske språk',
                    alternativeLabels: [
                      'Sørøst-asiatiske språk',
                      'Øst-asiatiske språk',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_burmanskspraak',
                        deactivated: false,
                        label: 'Burmansk språk',
                        alternativeLabels: ['Burmesisk'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_japanskspraak',
                        deactivated: false,
                        label: 'Japansk språk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kinesiskspraak',
                        deactivated: false,
                        label: 'Kinesisk språk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_koreanskspraak',
                        deactivated: false,
                        label: 'Koreansk språk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_lao',
                        deactivated: false,
                        label: 'Lao',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_thai',
                        deactivated: false,
                        label: 'Thai',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_tibetanskspraak',
                        deactivated: false,
                        label: 'Tibetansk språk',
                        alternativeLabels: ['Tibeto-burmanske språk'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_vietnamesiskspraak',
                        deactivated: false,
                        label: 'Vietnamesisk språk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_austronesiskespraak',
                    deactivated: false,
                    label: 'Austronesiske språk',
                    alternativeLabels: ['Malayo-polynesiske språk'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_madagassisk',
                        deactivated: false,
                        label: 'Madagassisk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_malayiskespraak',
                        deactivated: false,
                        label: 'Malayiske språk',
                        alternativeLabels: [
                          'Filipinsk',
                          'Indonesisk',
                          'Javanesisk',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_melanesiskespraak',
                        deactivated: false,
                        label: 'Melanesiske språk',
                        alternativeLabels: ['Mikronesiske språk'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_polynesiskespraak',
                        deactivated: false,
                        label: 'Polynesiske språk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_baskiskspraak',
                    deactivated: false,
                    label: 'Baskisk språk',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_eurasiskespraak',
                    deactivated: false,
                    label: 'Eurasiske språk',
                    alternativeLabels: [
                      'Altaiske språk',
                      'Uralaltaiske språk',
                      'Uralske språk',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_finsk-ugriskespraak',
                        deactivated: false,
                        label: 'Finsk-ugriske språk',
                        alternativeLabels: [
                          'Samojediske språk',
                          'Ugriske språk',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_estiskspraak',
                            deactivated: false,
                            label: 'Estisk språk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_finskspraak',
                            deactivated: false,
                            label: 'Finsk språk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_kvenskspraak',
                            deactivated: false,
                            label: 'Kvensk språk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_samiskspraak',
                            deactivated: false,
                            label: 'Samisk språk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_ungarskspraak',
                            deactivated: false,
                            label: 'Ungarsk språk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_mongolskespraak',
                        deactivated: false,
                        label: 'Mongolske språk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_tungusiskespraak',
                        deactivated: false,
                        label: 'Tungusiske språk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_tyrkiskspraak',
                        deactivated: false,
                        label: 'Tyrkisk språk',
                        alternativeLabels: ['Turko-tartariske språk'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_indoeuropeiskespraak',
                    deactivated: false,
                    label: 'Indoeuropeiske språk',
                    alternativeLabels: ['Øst indoeuropeiske språk'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_albanskspraak',
                        deactivated: false,
                        label: 'Albansk språk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_armenskspraak',
                        deactivated: false,
                        label: 'Armensk språk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_baltiskespraak',
                        deactivated: false,
                        label: 'Baltiske språk',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_gammelpreussisk',
                            deactivated: false,
                            label: 'Gammel preussisk',
                            alternativeLabels: ['Preussisk'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_latviskspraak',
                            deactivated: false,
                            label: 'Latvisk språk',
                            alternativeLabels: ['Lettisk språk'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_litauiskspraak',
                            deactivated: false,
                            label: 'Litauisk språk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_germanskespraak',
                        deactivated: false,
                        label: 'Germanske språk',
                        alternativeLabels: [
                          'Vest germanske språk',
                          'Øst germanske språk',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_engelskspraak',
                            deactivated: false,
                            label: 'Engelsk språk',
                            alternativeLabels: ['Angelsaksisk'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_nederlandskspraak',
                            deactivated: false,
                            label: 'Nederlandsk språk',
                            alternativeLabels: ['Flamsk', 'Hollandsk'],

                            related: [],
                            children: [
                              {
                                id: 'emneord_afrikaans',
                                deactivated: false,
                                label: 'Afrikaans',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_nedertysk',
                                deactivated: false,
                                label: 'Nedertysk',
                                alternativeLabels: ['Plattysk'],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_nordiskespraak',
                            deactivated: false,
                            label: 'Nordiske språk',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_danskspraak',
                                deactivated: false,
                                label: 'Dansk språk',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_faeroeyskspraak',
                                deactivated: false,
                                label: 'Færøysk språk',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_islandskspraak',
                                deactivated: false,
                                label: 'Islandsk språk',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_norroent',
                                deactivated: false,
                                label: 'Norrønt',
                                alternativeLabels: ['Gammelnorsk'],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_norskspraak',
                                deactivated: false,
                                label: 'Norsk språk',
                                alternativeLabels: [],
                                related: [],
                                children: [
                                  {
                                    id: 'emneord_bokmaal',
                                    deactivated: false,
                                    label: 'Bokmål',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'emneord_nynorsk',
                                    deactivated: false,
                                    label: 'Nynorsk',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'emneord_svenskspraak',
                                deactivated: false,
                                label: 'Svensk språk',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_tyskspraak',
                            deactivated: false,
                            label: 'Tysk språk',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_frisisk',
                                deactivated: false,
                                label: 'Frisisk',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_jiddisk',
                                deactivated: false,
                                label: 'Jiddisk',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_sveitsertysk',
                                deactivated: false,
                                label: 'Sveitsertysk',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'emneord_greskespraak',
                        deactivated: false,
                        label: 'Greske språk',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_klassiskgresk',
                            deactivated: false,
                            label: 'Klassisk gresk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_nygresk',
                            deactivated: false,
                            label: 'Nygresk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_indiskespraak',
                        deactivated: false,
                        label: 'Indiske språk',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_bengali',
                            deactivated: false,
                            label: 'Bengali',
                            alternativeLabels: ['Bangla', 'Bengalsk'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_dravidiskespraak',
                            deactivated: false,
                            label: 'Dravidiske språk',
                            alternativeLabels: ['Tamilsk'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_hindi',
                            deactivated: false,
                            label: 'Hindi',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_mellomindisk',
                            deactivated: false,
                            label: 'Mellomindisk',
                            alternativeLabels: ['Pali'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_nepali',
                            deactivated: false,
                            label: 'Nepali',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_punjabi',
                            deactivated: false,
                            label: 'Punjabi',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_romani',
                            deactivated: false,
                            label: 'Romani',
                            alternativeLabels: ['Sigøynerspråk'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_sanskrit',
                            deactivated: false,
                            label: 'Sanskrit',
                            alternativeLabels: ['Gammelindisk', 'Vedaspråk'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_singhalesisk',
                            deactivated: false,
                            label: 'Singhalesisk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_urdu',
                            deactivated: false,
                            label: 'Urdu',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_iranskspraak',
                        deactivated: false,
                        label: 'Iransk språk',
                        alternativeLabels: ['Avestsik'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_afghanskspraak',
                            deactivated: false,
                            label: 'Afghansk språk',
                            alternativeLabels: ['Dari', 'Pashto'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_kurdiskspraak',
                            deactivated: false,
                            label: 'Kurdisk språk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_persiskspraak',
                            deactivated: false,
                            label: 'Persisk språk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_italiskespraak',
                        deactivated: false,
                        label: 'Italiske språk',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_latin',
                            deactivated: false,
                            label: 'Latin',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_keltiskespraak',
                        deactivated: false,
                        label: 'Keltiske språk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_romanskespraak',
                        deactivated: false,
                        label: 'Romanske språk',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_franskspraak',
                            deactivated: false,
                            label: 'Fransk språk',
                            alternativeLabels: ['Katalansk', 'Provencalsk'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_italienskspraak',
                            deactivated: false,
                            label: 'Italiensk språk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_portugisiskspraak',
                            deactivated: false,
                            label: 'Portugisisk språk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_rumenskspraak',
                            deactivated: false,
                            label: 'Rumensk språk',
                            alternativeLabels: ['Retoromansk'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_spanskspraak',
                            deactivated: false,
                            label: 'Spansk språk',
                            alternativeLabels: ['Baskisk'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_slaviskespraak',
                        deactivated: false,
                        label: 'Slaviske språk',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_bulgarskspraak',
                            deactivated: false,
                            label: 'Bulgarsk språk',
                            alternativeLabels: ['Kirkeslavisk', 'Makedonsk'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_polskspraak',
                            deactivated: false,
                            label: 'Polsk språk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_russiskspraak',
                            deactivated: false,
                            label: 'Russisk språk',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_kaukasiskespraak',
                                deactivated: false,
                                label: 'Kaukasiske språk',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_ukrainskspraak',
                                deactivated: false,
                                label: 'Ukrainsk språk',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_serbokroatisk',
                            deactivated: false,
                            label: 'Serbokroatisk',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_kroatisk',
                                deactivated: false,
                                label: 'Kroatisk',
                                alternativeLabels: ['Bosnisk'],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_serbisk',
                                deactivated: false,
                                label: 'Serbisk',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'emneord_slovakisk',
                            deactivated: false,
                            label: 'Slovakisk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_slovensk',
                            deactivated: false,
                            label: 'Slovensk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_tsjekkiskspraak',
                            deactivated: false,
                            label: 'Tsjekkisk språk',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_kunstspraak',
                    deactivated: false,
                    label: 'Kunstspråk',
                    alternativeLabels: ['Planspråk'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_esperanto',
                        deactivated: false,
                        label: 'Esperanto',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_interlingua',
                        deactivated: false,
                        label: 'Interlingua',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_tegnspraak',
                        deactivated: false,
                        label: 'Tegnspråk',
                        alternativeLabels: ['Munnavlesning'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_pidgin',
                    deactivated: false,
                    label: 'Pidgin',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sumerisk',
                    deactivated: false,
                    label: 'Sumerisk',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_oversettelse',
                deactivated: false,
                label: 'Oversettelse',
                alternativeLabels: ['Gjendiktning', 'Tolkning'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_rettskriving',
                deactivated: false,
                label: 'Rettskriving',
                alternativeLabels: ['Normativ lingvistikk', 'Språkriktighet'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_spraakhistorie',
                deactivated: false,
                label: 'Språkhistorie',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_spraaksosiologi',
                deactivated: false,
                label: 'Språksosiologi',
                alternativeLabels: [
                  'Psykolingvistikk',
                  'Sosiolekter',
                  'Sosiolingvistikk',
                  'Språkpsykologi',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_spraakligeminoriteter',
                    deactivated: false,
                    label: 'Språklige minoriteter',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_tospraaklighet',
                    deactivated: false,
                    label: 'Tospråklighet',
                    alternativeLabels: ['Bilingvisme', 'Flerspråklighet'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_spraakteorier',
                deactivated: false,
                label: 'Språkteorier',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_spraakvitenskap',
                deactivated: false,
                label: 'Språkvitenskap',
                alternativeLabels: [
                  'Lingvistikk',
                  'Språkforskning',
                  'Språkteori',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_alfabeter',
                    deactivated: false,
                    label: 'Alfabeter',
                    alternativeLabels: [
                      'Billedskrift',
                      'Grafonomi',
                      'Paleografi',
                      'Translitterasjon',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_blindeskrift',
                        deactivated: false,
                        label: 'Blindeskrift',
                        alternativeLabels: ['Braille'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_bokstaver',
                        deactivated: false,
                        label: 'Bokstaver',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_runeskrift',
                        deactivated: false,
                        label: 'Runeskrift',
                        alternativeLabels: ['Runer'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_dialekter',
                    deactivated: false,
                    label: 'Dialekter',
                    alternativeLabels: ['Dialektologi'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_etymologi',
                    deactivated: false,
                    label: 'Etymologi',
                    alternativeLabels: ['Fremmedord', 'Låneord'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_fonologi',
                    deactivated: false,
                    label: 'Fonologi',
                    alternativeLabels: ['Fonetikk', 'Uttale'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_grammatikk',
                    deactivated: false,
                    label: 'Grammatikk',
                    alternativeLabels: ['Språkstruktur'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_morfologi',
                        deactivated: false,
                        label: 'Morfologi',
                        alternativeLabels: ['Adverb', 'Substantiver', 'Verb'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_syntaks',
                        deactivated: false,
                        label: 'Syntaks',
                        alternativeLabels: ['Setningsoppbygning'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_idiomer',
                    deactivated: false,
                    label: 'Idiomer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_navn',
                    deactivated: false,
                    label: 'Navn',
                    alternativeLabels: ['Navneforskning'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_personnavn',
                        deactivated: false,
                        label: 'Personnavn',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_stedsnavn',
                        deactivated: false,
                        label: 'Stedsnavn',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_semantikk',
                    deactivated: false,
                    label: 'Semantikk',
                    alternativeLabels: ['Betydningslære'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_leksikografi',
                        deactivated: false,
                        label: 'Leksikografi',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_terminologi',
                            deactivated: false,
                            label: 'Terminologi',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_semiotikk',
                    deactivated: false,
                    label: 'Semiotikk',
                    alternativeLabels: ['Semiologi'],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'emneord_teknikkognaeringsliv',
        deactivated: false,
        label: 'Teknikk og næringsliv',
        alternativeLabels: ['Næringsliv', 'Teknikk'],

        related: [],
        children: [
          {
            id: 'emneord_landbrukogfiske',
            deactivated: false,
            label: 'Landbruk og fiske',
            alternativeLabels: ['Primærnæringer'],

            related: [],
            children: [
              {
                id: 'emneord_fiske',
                deactivated: false,
                label: 'Fiske',
                alternativeLabels: ['Fiskere', 'Fiskerier'],

                related: [
                  {
                    id: 'emneord_sportsfiske',
                    deactivated: false,
                    label: 'Sportsfiske',
                  },
                ],
                children: [
                  {
                    id: 'emneord_fiskemetoder',
                    deactivated: false,
                    label: 'Fiskemetoder',
                    alternativeLabels: ['Fiskeredskaper'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_garnfiske',
                        deactivated: false,
                        label: 'Garnfiske',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_traalfiske',
                            deactivated: false,
                            label: 'Trålfiske',
                            alternativeLabels: ['Tråling'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_linefiske',
                        deactivated: false,
                        label: 'Linefiske',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_fiskeoppdrett',
                    deactivated: false,
                    label: 'Fiskeoppdrett',
                    alternativeLabels: ['Akvakultur', 'Havbruk'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_hvalfangst',
                    deactivated: false,
                    label: 'Hvalfangst',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_selfangst',
                    deactivated: false,
                    label: 'Selfangst',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_landbruk',
                deactivated: false,
                label: 'Landbruk',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_fruktdyrking',
                    deactivated: false,
                    label: 'Fruktdyrking',
                    alternativeLabels: ['Fruktavl'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_baer',
                        deactivated: false,
                        label: 'Bær',
                        alternativeLabels: ['Bringebær', 'Jordbær', 'Solbær'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_frukt',
                        deactivated: false,
                        label: 'Frukt',
                        alternativeLabels: [
                          'Epletrær',
                          'Kirsebærtrær',
                          'Plommetrær',
                          'Pæretrær',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_hagebruk',
                    deactivated: false,
                    label: 'Hagebruk',
                    alternativeLabels: [
                      'Drivhus',
                      'Gartnerier',
                      'Planteskoler',
                      'Veksthus',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_husdyr',
                    deactivated: false,
                    label: 'Husdyr',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_fôring',
                        deactivated: false,
                        label: 'Fôring',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_husdyravl',
                        deactivated: false,
                        label: 'Husdyravl',
                        alternativeLabels: ['Avl'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_melkeproduksjon',
                        deactivated: false,
                        label: 'Melkeproduksjon',
                        alternativeLabels: ['Melkemaskiner', 'Melking'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_reindrift',
                        deactivated: false,
                        label: 'Reindrift',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_jordbruk',
                    deactivated: false,
                    label: 'Jordbruk',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_avlinger',
                        deactivated: false,
                        label: 'Avlinger',
                        alternativeLabels: ['Kulturplanter', 'Matproduksjon'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_belgfrukter',
                            deactivated: false,
                            label: 'Belgfrukter',
                            alternativeLabels: ['Bønner', 'Erter'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_fôrplanter',
                            deactivated: false,
                            label: 'Fôrplanter',
                            alternativeLabels: ['Gress', 'Høy', 'Timotei'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_korn',
                            deactivated: false,
                            label: 'Korn',
                            alternativeLabels: [
                              'Havre',
                              'Hvete',
                              'Mais',
                              'Ris',
                            ],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_sukkerplanter',
                            deactivated: false,
                            label: 'Sukkerplanter',
                            alternativeLabels: ['Sukkerroer', 'Sukkerrør'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_tekstilplanter',
                            deactivated: false,
                            label: 'Tekstilplanter',
                            alternativeLabels: ['Bomull', 'Lin'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_tobakksplanter',
                            deactivated: false,
                            label: 'Tobakksplanter',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'emneord_tobakk',
                                deactivated: false,
                                label: 'Tobakk',
                                alternativeLabels: [
                                  'Røyking',
                                  'Sigarer',
                                  'Sigaretter',
                                ],

                                related: [
                                  {
                                    id: 'emneord_tobakkskader',
                                    deactivated: false,
                                    label: 'Tobakkskader',
                                  },
                                ],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'emneord_avlingsskader',
                        deactivated: false,
                        label: 'Avlingsskader',
                        alternativeLabels: [
                          'Plantesykdommer',
                          'Sprøyting',
                          'Ugress',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_dyrkingoginnhoesting',
                        deactivated: false,
                        label: 'Dyrking og innhøsting',
                        alternativeLabels: [
                          'Harving',
                          'Innhøsting',
                          'Pløying',
                          'Vekselbruk',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_gaardsbruk',
                        deactivated: false,
                        label: 'Gårdsbruk',
                        alternativeLabels: ['Bondegårder', 'Fjøs', 'Gardsbruk'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_jordforbedring',
                        deactivated: false,
                        label: 'Jordforbedring',
                        alternativeLabels: ['Jordbunn'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_gjoedsling',
                            deactivated: false,
                            label: 'Gjødsling',
                            alternativeLabels: ['Gjødsel', 'Kompost'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_landbruksbygninger',
                        deactivated: false,
                        label: 'Landbruksbygninger',
                        alternativeLabels: ['Driftsbygninger'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_landbruksredskaper',
                        deactivated: false,
                        label: 'Landbruksredskaper',
                        alternativeLabels: ['Landbruksutstyr', 'Ploger'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_oekologisklandbruk',
                        deactivated: false,
                        label: 'Økologisk landbruk',
                        alternativeLabels: ['Biodynamisk dyrking'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_plantevern',
                        deactivated: false,
                        label: 'Plantevern',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_veterinaermedisin',
                    deactivated: false,
                    label: 'Veterinærmedisin',
                    alternativeLabels: ['Dyrlegevitenskap'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_dyrleger',
                        deactivated: false,
                        label: 'Dyrleger',
                        alternativeLabels: ['Veterinærer'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_husdyrsykdommer',
                        deactivated: false,
                        label: 'Husdyrsykdommer',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_skogbruk',
                deactivated: false,
                label: 'Skogbruk',
                alternativeLabels: ['Forstvitenskap'],

                related: [
                  {
                    id: 'emneord_skoger',
                    deactivated: false,
                    label: 'Skoger',
                  },
                ],
                children: [
                  {
                    id: 'emneord_floeting',
                    deactivated: false,
                    label: 'Fløting',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_hogst',
                    deactivated: false,
                    label: 'Hogst',
                    alternativeLabels: ['Avvirkning', 'Tømmerhogging'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_skogplanting',
                    deactivated: false,
                    label: 'Skogplanting',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_traer',
                    deactivated: false,
                    label: 'Trær',
                    alternativeLabels: [
                      'Bjørk',
                      'Bonsai',
                      'Furu',
                      'Gran',
                      'Tresorter',
                    ],

                    related: [
                      {
                        id: 'emneord_busker',
                        deactivated: false,
                        label: 'Busker',
                      },
                    ],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_maalogvekt',
            deactivated: false,
            label: 'Mål og vekt',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'emneord_naeringslivsfolk',
            deactivated: false,
            label: 'Næringslivsfolk',
            alternativeLabels: ['Forretningsfolk', 'Industriledere'],

            related: [],
            children: [],
          },
          {
            id: 'emneord_produksjonogteknologi',
            deactivated: false,
            label: 'Produksjon og teknologi',
            alternativeLabels: ['Sekundærnæringer', 'Teknikkens historie'],

            related: [],
            children: [
              {
                id: 'emneord_automatiskkontrollteknikk',
                deactivated: false,
                label: 'Automatisk kontrollteknikk',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_byggoganlegg',
                deactivated: false,
                label: 'Bygg og anlegg',
                alternativeLabels: ['Byggeteknikk'],

                related: [],
                children: [
                  {
                    id: 'emneord_anleggsmaskiner',
                    deactivated: false,
                    label: 'Anleggsmaskiner',
                    alternativeLabels: [
                      'Bulldosere',
                      'Dumpere',
                      'Entreprenørmaskiner',
                      'Hjullastere',
                      'Mobilkraner',
                      'Skraper',
                      'Veivalser',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_gravemaskiner',
                        deactivated: false,
                        label: 'Gravemaskiner',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_anleggsvirksomhet',
                    deactivated: false,
                    label: 'Anleggsvirksomhet',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_brobygging',
                        deactivated: false,
                        label: 'Brobygging',
                        alternativeLabels: ['Broer', 'Hengebroer'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kommunalteknikk',
                        deactivated: false,
                        label: 'Kommunalteknikk',
                        alternativeLabels: [
                          'Havneanlegg',
                          'Idrettsanlegg',
                          'Kaianlegg',
                          'Kloakk',
                          'Vannforsyning',
                          'Vannverk',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_renovasjon',
                            deactivated: false,
                            label: 'Renovasjon',
                            alternativeLabels: ['Avfallshåndtering'],

                            related: [],
                            children: [
                              {
                                id: 'emneord_avfall',
                                deactivated: false,
                                label: 'Avfall',
                                alternativeLabels: ['Søppel'],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'emneord_kraftutbygging',
                        deactivated: false,
                        label: 'Kraftutbygging',
                        alternativeLabels: [
                          'Damanlegg',
                          'Vassdragsregulering',
                          'Vassdragsutbygging',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_tunnelarbeider',
                        deactivated: false,
                        label: 'Tunnelarbeider',
                        alternativeLabels: ['Tunneler'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_undervannsteknologi',
                        deactivated: false,
                        label: 'Undervannsteknologi',
                        alternativeLabels: ['Dykkerklokker'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_veibygging',
                        deactivated: false,
                        label: 'Veibygging',
                        alternativeLabels: [
                          'Jernbanebygging',
                          'Jernbanekonstruksjon',
                          'Veikonstruksjon',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_veier',
                            deactivated: false,
                            label: 'Veier',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'emneord_byggematerialer',
                    deactivated: false,
                    label: 'Byggematerialer',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_asbest',
                        deactivated: false,
                        label: 'Asbest',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_betong',
                        deactivated: false,
                        label: 'Betong',
                        alternativeLabels: ['Sement'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_glass',
                        deactivated: false,
                        label: 'Glass',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_naturstein',
                        deactivated: false,
                        label: 'Naturstein',
                        alternativeLabels: ['Grus', 'Sand', 'Singel'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_staal',
                        deactivated: false,
                        label: 'Stål',
                        alternativeLabels: ['Jern'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_tegl',
                        deactivated: false,
                        label: 'Tegl',
                        alternativeLabels: ['Murstein'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_tre',
                        deactivated: false,
                        label: 'Tre',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_byggeplasser',
                    deactivated: false,
                    label: 'Byggeplasser',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_grunnarbeider',
                    deactivated: false,
                    label: 'Grunnarbeider',
                    alternativeLabels: ['Fundamentering', 'Sprengning'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_husbygging',
                    deactivated: false,
                    label: 'Husbygging',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_byggeplaner',
                        deactivated: false,
                        label: 'Byggeplaner',
                        alternativeLabels: ['Byggetegninger'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_glassarbeid',
                        deactivated: false,
                        label: 'Glassarbeid',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_lafting',
                        deactivated: false,
                        label: 'Lafting',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_malerarbeid',
                        deactivated: false,
                        label: 'Malerarbeid',
                        alternativeLabels: [
                          'Beising',
                          'Lakkering',
                          'Tapetsering',
                          'Teppelegging',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_murarbeider',
                        deactivated: false,
                        label: 'Murarbeider',
                        alternativeLabels: ['Muring'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_snekkerarbeid',
                        deactivated: false,
                        label: 'Snekkerarbeid',
                        alternativeLabels: ['Snekring', 'Tømring'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_vvs-teknikk',
                        deactivated: false,
                        label: 'VVS-teknikk',
                        alternativeLabels: [
                          'Luftkondisjonering',
                          'Romoppvarming',
                          'Rørleggerarbeid',
                          'Sanitærteknikk',
                          'Sentraloppvarming',
                          'VA',
                          'Ventilasjon',
                          'VVA',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_konstruksjonsteori',
                    deactivated: false,
                    label: 'Konstruksjonsteori',
                    alternativeLabels: [
                      'Spenning',
                      ' mekanisk',
                      'Statiske beregninger',
                      'Styrkeberegning',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_miljoeteknologi',
                    deactivated: false,
                    label: 'Miljøteknologi',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_elektroteknikk',
                deactivated: false,
                label: 'Elektroteknikk',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_elektronikk',
                    deactivated: false,
                    label: 'Elektronikk',
                    alternativeLabels: [
                      'Elektro-svakstrøm',
                      'Halvlederteknikk',
                      'Svakstrømsteknikk',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_elektroniskekomponenter',
                        deactivated: false,
                        label: 'Elektroniske komponenter',
                        alternativeLabels: [
                          'Billedrør',
                          'Elektronrør',
                          'Katodestrålerør',
                          'Modulatorer',
                          'Oscillatorer',
                          'Radiorør',
                          'Transistorer',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_industrielektronikk',
                        deactivated: false,
                        label: 'Industrielektronikk',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_reguleringsteknikk',
                            deactivated: false,
                            label: 'Reguleringsteknikk',
                            alternativeLabels: [
                              'Prosesstyring',
                              'Styringsteknikk',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_integrertekretser',
                        deactivated: false,
                        label: 'Integrerte kretser',
                        alternativeLabels: [
                          'Digitalteknikk',
                          'EPROM',
                          'Hukommelsesbrikker',
                          'Mikroelektronikk',
                          'Mikroprosessorer',
                          'PROM',
                          'RAM',
                          'ROM',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_radio-ogtv-teknikk',
                        deactivated: false,
                        label: 'Radio- og tv-teknikk',
                        alternativeLabels: [
                          'Antenner',
                          'Fjernsynsteknikk',
                          'Kortbølge',
                          'Langbølge',
                          'Mikrofonteknikk',
                          'Radiotransmisjon',
                          'Tv-teknikk',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_teleteknikk',
                        deactivated: false,
                        label: 'Teleteknikk',
                        alternativeLabels: ['Telefonsentraler', 'Telenett'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_elektro-sterkstroem',
                    deactivated: false,
                    label: 'Elektro-sterkstrøm',
                    alternativeLabels: ['Elkraftteknikk'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_elektrisitetsoverfoering',
                        deactivated: false,
                        label: 'Elektrisitetsoverføring',
                        alternativeLabels: [
                          'Elektriske linjer',
                          'Høyspentlinjer',
                          'Strømoverføring',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_elektriskeinstallasjoner',
                            deactivated: false,
                            label: 'Elektriske installasjoner',
                            alternativeLabels: [
                              'Kontakter',
                              'Ledninger',
                              'Sikringer',
                              'Støpsler',
                            ],

                            related: [],
                            children: [
                              {
                                id: 'emneord_belysning',
                                deactivated: false,
                                label: 'Belysning',
                                alternativeLabels: [
                                  'Elektrisk belysning',
                                  'Lamper',
                                ],

                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'emneord_elektromotorer',
                        deactivated: false,
                        label: 'Elektromotorer',
                        alternativeLabels: [
                          'Dynamoer',
                          'Elektriske motorer',
                          'Elektromaskiner',
                          'Generatorer',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_energi',
                        deactivated: false,
                        label: 'Energi',
                        alternativeLabels: [
                          'Elektrisitetsproduksjon',
                          'Elektrisitetsverk',
                          'Energiøkonomisering',
                          'Enøk',
                          'Kraftproduksjon',
                          'Strømforsyning',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_alternativenergi',
                            deactivated: false,
                            label: 'Alternativ energi',
                            alternativeLabels: [
                              'Bølgekraft',
                              'Energi',
                              ' alternativ',
                              'Jordkraft',
                              'Tidevannskraft',
                            ],

                            related: [],
                            children: [
                              {
                                id: 'emneord_solenergi',
                                deactivated: false,
                                label: 'Solenergi',
                                alternativeLabels: ['Solceller'],

                                related: [],
                                children: [],
                              },
                              {
                                id: 'emneord_vindkraft',
                                deactivated: false,
                                label: 'Vindkraft',
                                alternativeLabels: [],
                                related: [],
                                children: [
                                  {
                                    id: 'emneord_vindmoeller',
                                    deactivated: false,
                                    label: 'Vindmøller',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: 'emneord_kjernekraft',
                            deactivated: false,
                            label: 'Kjernekraft',
                            alternativeLabels: ['Atomkraftverk'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_vannkraft',
                            deactivated: false,
                            label: 'Vannkraft',
                            alternativeLabels: ['Vannkraftverk'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_varmekraft',
                            deactivated: false,
                            label: 'Varmekraft',
                            alternativeLabels: [
                              'Gasskraftverk',
                              'Kullkraftverk',
                              'Oljekraftverk',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_kondensatorer',
                        deactivated: false,
                        label: 'Kondensatorer',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_transformatorer',
                        deactivated: false,
                        label: 'Transformatorer',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_entreprenoerskap',
                deactivated: false,
                label: 'Entreprenørskap',
                alternativeLabels: ['Gründervirksomhet'],

                related: [],
                children: [
                  {
                    id: 'emneord_innovasjon',
                    deactivated: false,
                    label: 'Innovasjon',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_gruvedrift',
                deactivated: false,
                label: 'Gruvedrift',
                alternativeLabels: ['Bergverksdrift', 'Gullgraving'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_industri',
                deactivated: false,
                label: 'Industri',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_elektronikkindustri',
                    deactivated: false,
                    label: 'Elektronikkindustri',
                    alternativeLabels: ['Dataindustri'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_farmasoeytiskindustri',
                    deactivated: false,
                    label: 'Farmasøytisk industri',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_garveriindustri',
                    deactivated: false,
                    label: 'Garveriindustri',
                    alternativeLabels: [
                      'Garving',
                      'Lær og skinn',
                      'Skinn og lær',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_glass-ogkeramiskindustri',
                    deactivated: false,
                    label: 'Glass- og keramisk industri',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_keramiskindustri',
                        deactivated: false,
                        label: 'Keramisk industri',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_kjemiskindustri',
                    deactivated: false,
                    label: 'Kjemisk industri',
                    alternativeLabels: ['Elektro-metallurgisk industri'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_metallindustri',
                    deactivated: false,
                    label: 'Metallindustri',
                    alternativeLabels: ['Tungindustri'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_aluminiumsindustri',
                        deactivated: false,
                        label: 'Aluminiumsindustri',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_jernindustri',
                        deactivated: false,
                        label: 'Jernindustri',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_staalindustri',
                        deactivated: false,
                        label: 'Stålindustri',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_naeringsmiddelindustri',
                    deactivated: false,
                    label: 'Næringsmiddelindustri',
                    alternativeLabels: ['Matindustri'],

                    related: [],
                    children: [
                      {
                        id: 'emneord_bakerier',
                        deactivated: false,
                        label: 'Bakerier',
                        alternativeLabels: ['Konditorier'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_fiskeforedling',
                        deactivated: false,
                        label: 'Fiskeforedling',
                        alternativeLabels: ['Fiskeindustri'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_kjoettindustri',
                        deactivated: false,
                        label: 'Kjøttindustri',
                        alternativeLabels: ['Slakterfag', 'Slakterier'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_konserveringsindustri',
                        deactivated: false,
                        label: 'Konserveringsindustri',
                        alternativeLabels: ['Hermetikkindustri'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_meieridrift',
                        deactivated: false,
                        label: 'Meieridrift',
                        alternativeLabels: ['Osteproduksjon'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_nytelsesmiddelindustri',
                        deactivated: false,
                        label: 'Nytelsesmiddelindustri',
                        alternativeLabels: [
                          'Brennerier',
                          'Bryggerier',
                          'Tobakksindustri',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_ultraprosessertmat',
                        deactivated: false,
                        label: 'Ultraprosessert mat',
                        alternativeLabels: ['Prosessert mat'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_tekstilindustri',
                    deactivated: false,
                    label: 'Tekstilindustri',
                    alternativeLabels: ['Bekledningsindustri'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_treforedlingsindustri',
                    deactivated: false,
                    label: 'Treforedlingsindustri',
                    alternativeLabels: [
                      'Celluloseindustri',
                      'Møbelindustri',
                      'Papirproduksjon',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_trelastindustri',
                    deactivated: false,
                    label: 'Trelastindustri',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_verkstedsindustri',
                    deactivated: false,
                    label: 'Verkstedsindustri',
                    alternativeLabels: [
                      'Forbruksvareproduksjon',
                      'Mekanisk industri',
                      'Mekaniske verksteder',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_materialerogmetoder',
                deactivated: false,
                label: 'Materialer og metoder',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_design',
                    deactivated: false,
                    label: 'Design',
                    alternativeLabels: [
                      'Industridesign',
                      'Produktutforming',
                      'Produktutvikling',
                      'Varemerker',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_universellutforming',
                        deactivated: false,
                        label: 'Universell utforming',
                        alternativeLabels: ['Livsløpsstandard'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_kvalitetskontroll',
                    deactivated: false,
                    label: 'Kvalitetskontroll',
                    alternativeLabels: ['Produktkontroll'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_materiallaere',
                    deactivated: false,
                    label: 'Materiallære',
                    alternativeLabels: [
                      'Korrosjon',
                      'Materialtretthet',
                      'Rust',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_metallurgi',
                        deactivated: false,
                        label: 'Metallurgi',
                        alternativeLabels: ['Kopper', 'Metall', 'Zinc'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_plast',
                        deactivated: false,
                        label: 'Plast',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_patenter',
                    deactivated: false,
                    label: 'Patenter',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_standardisering',
                    deactivated: false,
                    label: 'Standardisering',
                    alternativeLabels: ['Standarder'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_teknisktegning',
                    deactivated: false,
                    label: 'Teknisk tegning',
                    alternativeLabels: [],
                    related: [
                      {
                        id: 'emneord_tegning',
                        deactivated: false,
                        label: 'Tegning',
                      },
                    ],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_olje-oggassutvinning',
                deactivated: false,
                label: 'Olje- og gassutvinning',
                alternativeLabels: [
                  'Gassutvinning',
                  'Oljeboring',
                  'Oljeutvinning',
                  'Petroleumsutvinning',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_oljeinstallasjoner',
                    deactivated: false,
                    label: 'Oljeinstallasjoner',
                    alternativeLabels: [
                      'Boreplattformer',
                      'Boreslamteknologi',
                      'Produksjonsplattformer',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_oppfinnelser',
                deactivated: false,
                label: 'Oppfinnelser',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'emneord_teknologi',
                deactivated: false,
                label: 'Teknologi',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'emneord_kjemiskteknologi',
                    deactivated: false,
                    label: 'Kjemisk teknologi',
                    alternativeLabels: [
                      'Industriell kjemi',
                      'Kjemisk teknikk',
                      'Kjemiteknikk',
                      'Prosesskjemi',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_mekaniskteknologi',
                    deactivated: false,
                    label: 'Mekanisk teknologi',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_finmekanikk',
                        deactivated: false,
                        label: 'Finmekanikk',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'emneord_klokker',
                            deactivated: false,
                            label: 'Klokker',
                            alternativeLabels: ['Ur', 'Urmakerkunst'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_maskinteknikk',
                        deactivated: false,
                        label: 'Maskinteknikk',
                        alternativeLabels: [
                          'Løfteredskaper',
                          'Maskinlære',
                          'Pneumatikk',
                          'Trucker',
                          'Trykkluft',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_maskindeler',
                            deactivated: false,
                            label: 'Maskindeler',
                            alternativeLabels: [
                              'Kammer',
                              'Kulelagre',
                              'Stempler',
                              'Tannhjul',
                              'Ventiler',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_mekaniskbearbeiding',
                        deactivated: false,
                        label: 'Mekanisk bearbeiding',
                        alternativeLabels: ['Boring', 'Fresing', 'Sliping'],

                        related: [],
                        children: [
                          {
                            id: 'emneord_dreiing',
                            deactivated: false,
                            label: 'Dreiing',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'emneord_sveising',
                            deactivated: false,
                            label: 'Sveising',
                            alternativeLabels: ['Buesveising', 'Lodding'],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'emneord_roboter',
                        deactivated: false,
                        label: 'Roboter',
                        alternativeLabels: ['Robotteknologi'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_verktoey',
                        deactivated: false,
                        label: 'Verktøy',
                        alternativeLabels: [
                          'Trykkluftverktøy',
                          'Verktøysmaskiner',
                        ],

                        related: [],
                        children: [
                          {
                            id: 'emneord_kniver',
                            deactivated: false,
                            label: 'Kniver',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            id: 'emneord_servicenaeringer',
            deactivated: false,
            label: 'Servicenæringer',
            alternativeLabels: ['Tertiærnæringer'],

            related: [],
            children: [
              {
                id: 'emneord_handel',
                deactivated: false,
                label: 'Handel',
                alternativeLabels: ['Forretningsliv'],

                related: [],
                children: [
                  {
                    id: 'emneord_detaljhandel',
                    deactivated: false,
                    label: 'Detaljhandel',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_butikker',
                        deactivated: false,
                        label: 'Butikker',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_loppemarkeder',
                        deactivated: false,
                        label: 'Loppemarkeder',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_engroshandel',
                    deactivated: false,
                    label: 'Engroshandel',
                    alternativeLabels: ['Grossister'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_hotell-ogrestaurantdrift',
                deactivated: false,
                label: 'Hotell- og restaurantdrift',
                alternativeLabels: ['Storhusholdninger'],

                related: [],
                children: [
                  {
                    id: 'emneord_hoteller',
                    deactivated: false,
                    label: 'Hoteller',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kafeer',
                    deactivated: false,
                    label: 'Kafeer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_kokkefag',
                    deactivated: false,
                    label: 'Kokkefag',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_puber',
                    deactivated: false,
                    label: 'Puber',
                    alternativeLabels: ['Barer'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_restauranter',
                    deactivated: false,
                    label: 'Restauranter',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_servitoerfag',
                    deactivated: false,
                    label: 'Servitørfag',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_konsulenttjenester',
                deactivated: false,
                label: 'Konsulenttjenester',
                alternativeLabels: ['Konsulentvirksomhet'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_turisme',
                deactivated: false,
                label: 'Turisme',
                alternativeLabels: ['Reisebyråer', 'Reiselivsnæringen'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'emneord_transport',
            deactivated: false,
            label: 'Transport',
            alternativeLabels: ['Samferdsel', 'Transportmidler'],

            related: [],
            children: [
              {
                id: 'emneord_jernbaner',
                deactivated: false,
                label: 'Jernbaner',
                alternativeLabels: ['Jernbanetransport'],

                related: [],
                children: [
                  {
                    id: 'emneord_tog',
                    deactivated: false,
                    label: 'Tog',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_kjoeretoey',
                deactivated: false,
                label: 'Kjøretøy',
                alternativeLabels: ['Motorvogner'],

                related: [],
                children: [
                  {
                    id: 'emneord_biler',
                    deactivated: false,
                    label: 'Biler',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_lastebiler',
                        deactivated: false,
                        label: 'Lastebiler',
                        alternativeLabels: [
                          'Lastevogner',
                          'Trailere',
                          'Vogntog',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_personbiler',
                        deactivated: false,
                        label: 'Personbiler',
                        alternativeLabels: ['Sportsbiler', 'Varebiler'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_bilteknikk',
                    deactivated: false,
                    label: 'Bilteknikk',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_motorteknikk',
                        deactivated: false,
                        label: 'Motorteknikk',
                        alternativeLabels: [
                          'Bensinmotorer',
                          'Dieselmotorer',
                          'Forbrenningsmotorer',
                        ],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_busser',
                    deactivated: false,
                    label: 'Busser',
                    alternativeLabels: ['Minibusser', 'Rutebiler'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_drosjer',
                    deactivated: false,
                    label: 'Drosjer',
                    alternativeLabels: ['Taxi'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_motorsykler',
                    deactivated: false,
                    label: 'Motorsykler',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_mopeder',
                        deactivated: false,
                        label: 'Mopeder',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_scootere',
                        deactivated: false,
                        label: 'Scootere',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_snoescootere',
                    deactivated: false,
                    label: 'Snøscootere',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_traktorer',
                    deactivated: false,
                    label: 'Traktorer',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_lufttransport',
                deactivated: false,
                label: 'Lufttransport',
                alternativeLabels: [
                  'Ballongferder',
                  'Flyplasser',
                  'Flytransport',
                  'Flyvning',
                  'Helikopter',
                  'Luftfart',
                  'Luftskip',
                ],

                related: [],
                children: [
                  {
                    id: 'emneord_droner',
                    deactivated: false,
                    label: 'Droner',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_fly',
                    deactivated: false,
                    label: 'Fly',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_flyteknikk',
                    deactivated: false,
                    label: 'Flyteknikk',
                    alternativeLabels: ['Aerodynamikk', 'Aeronautikk'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_flyulykker',
                    deactivated: false,
                    label: 'Flyulykker',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_romfart',
                deactivated: false,
                label: 'Romfart',
                alternativeLabels: ['Astronauter', 'Romferder', 'Satelitter'],

                related: [],
                children: [],
              },
              {
                id: 'emneord_sjoefart',
                deactivated: false,
                label: 'Sjøfart',
                alternativeLabels: ['Skipsfart'],

                related: [],
                children: [
                  {
                    id: 'emneord_baatbygging',
                    deactivated: false,
                    label: 'Båtbygging',
                    alternativeLabels: ['Skipsbygging'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_rederivirksomhet',
                    deactivated: false,
                    label: 'Rederivirksomhet',
                    alternativeLabels: ['Rederier'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sjoefartshistorie',
                    deactivated: false,
                    label: 'Sjøfartshistorie',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'emneord_forlis',
                        deactivated: false,
                        label: 'Forlis',
                        alternativeLabels: [
                          'Havari',
                          'Sjøulykker',
                          'Skipsbranner',
                        ],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_fyr',
                        deactivated: false,
                        label: 'Fyr',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_krigsseilere',
                        deactivated: false,
                        label: 'Krigsseilere',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_sjoeroevere',
                        deactivated: false,
                        label: 'Sjørøvere',
                        alternativeLabels: ['Pirater'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_sjoelivet',
                    deactivated: false,
                    label: 'Sjølivet',
                    alternativeLabels: ['Sjøfolk'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_sjoemannskap',
                    deactivated: false,
                    label: 'Sjømannskap',
                    alternativeLabels: [
                      'Redningstjeneste',
                      'Sjøredningstjenste',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'emneord_knuter',
                        deactivated: false,
                        label: 'Knuter',
                        alternativeLabels: ['Knoper', 'Spleiser'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_navigasjon',
                        deactivated: false,
                        label: 'Navigasjon',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_radiotelefoni',
                        deactivated: false,
                        label: 'Radiotelefoni',
                        alternativeLabels: ['Skipsradio', 'VHF'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'emneord_skip',
                    deactivated: false,
                    label: 'Skip',
                    alternativeLabels: [
                      'Dampbåter',
                      'Dampskip',
                      'Lasteskip',
                      'Passasjerbåter',
                      'Supertankere',
                      'Tankbåter',
                    ],

                    related: [
                      {
                        id: 'emneord_baater',
                        deactivated: false,
                        label: 'Båter',
                      },
                    ],
                    children: [
                      {
                        id: 'emneord_seilskuter',
                        deactivated: false,
                        label: 'Seilskuter',
                        alternativeLabels: ['Seilskip'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'emneord_ubaater',
                        deactivated: false,
                        label: 'Ubåter',
                        alternativeLabels: ['U-båter'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'emneord_trafikk',
                deactivated: false,
                label: 'Trafikk',
                alternativeLabels: ['Trafikkopplæring', 'Trafikkregler'],

                related: [],
                children: [
                  {
                    id: 'emneord_foererkort',
                    deactivated: false,
                    label: 'Førerkort',
                    alternativeLabels: [
                      'Førerprøve',
                      'Kjøreskoler',
                      'Sertifikat',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'emneord_trafikksikkerhet',
                    deactivated: false,
                    label: 'Trafikksikkerhet',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'emneord_trikkerogt-baner',
                deactivated: false,
                label: 'Trikker og t-baner',
                alternativeLabels: [
                  'Metro',
                  'T-baner',
                  'Tunnelbaner',
                  'Undergrunnsbaner',
                ],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'emneord_vitenskapshistorie',
        deactivated: false,
        label: 'Vitenskapshistorie',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'emneord_humaniora',
            deactivated: false,
            label: 'Humaniora',
            alternativeLabels: ['Menneskevitenskapene'],

            related: [],
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 'form_form',
    deactivated: true,
    label: 'Form',
    alternativeLabels: [],

    related: [],
    children: [
      {
        id: 'form_litteraerform',
        deactivated: true,
        label: 'Litterær form',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'form_adresseboeker',
            deactivated: true,
            label: 'Adressebøker',
            alternativeLabels: ['Adresseregistre', 'Telefonbøker'],

            related: [],
            children: [],
          },
          {
            id: 'form_aktivitetsboeker',
            deactivated: true,
            label: 'Aktivitetsbøker',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_almanakker',
            deactivated: true,
            label: 'Almanakker',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_antologier',
            deactivated: true,
            label: 'Antologier',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_aarboeker',
            deactivated: true,
            label: 'Årbøker',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_bibliografier',
            deactivated: true,
            label: 'Bibliografier',
            alternativeLabels: [
              'Boklister',
              'Nasjonalbibliografier',
              'Registre',
            ],

            related: [],
            children: [],
          },
          {
            id: 'form_billedboeker',
            deactivated: true,
            label: 'Billedbøker',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_biografier',
            deactivated: true,
            label: 'Biografier',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'form_selvbiografier',
                deactivated: true,
                label: 'Selvbiografier',
                alternativeLabels: ['Memoarer'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'form_brevsamlinger',
            deactivated: true,
            label: 'Brevsamlinger',
            alternativeLabels: ['Manuskripter'],

            related: [],
            children: [],
          },
          {
            id: 'form_dagboeker',
            deactivated: true,
            label: 'Dagbøker',
            alternativeLabels: ['Å skrive dagbok'],

            related: [],
            children: [],
          },
          {
            id: 'form_diskografier',
            deactivated: true,
            label: 'Diskografier',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_epos',
            deactivated: true,
            label: 'Epos',
            alternativeLabels: [
              'Gudesagn',
              'Heltediktning',
              'Middelalderdiktning',
            ],

            related: [],
            children: [],
          },
          {
            id: 'form_essay',
            deactivated: true,
            label: 'Essay',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_eventyr',
            deactivated: true,
            label: 'Eventyr',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_fabler',
            deactivated: true,
            label: 'Fabler',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_festskrift',
            deactivated: true,
            label: 'Festskrift',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_floraer',
            deactivated: true,
            label: 'Floraer',
            alternativeLabels: ['Flora'],

            related: [],
            children: [],
          },
          {
            id: 'form_formelsamlinger',
            deactivated: true,
            label: 'Formelsamlinger',
            alternativeLabels: ['Tabeller'],

            related: [],
            children: [],
          },
          {
            id: 'form_fortellinger',
            deactivated: true,
            label: 'Fortellinger',
            alternativeLabels: [],
            note: 'Brukes primært på barnebøker',
            related: [],
            children: [],
          },
          {
            id: 'form_fotografisamlinger',
            deactivated: true,
            label: 'Fotografisamlinger',
            alternativeLabels: [],
            note: 'Her: praktbøker, gavebøker hvor fotografi er hovedsak',
            related: [],
            children: [],
          },
          {
            id: 'form_gaater',
            deactivated: true,
            label: 'Gåter',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_haandboeker',
            deactivated: true,
            label: 'Håndbøker',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'form_studiehaandboeker',
                deactivated: true,
                label: 'Studiehåndbøker',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'form_jubileumsboeker',
            deactivated: true,
            label: 'Jubileumsbøker',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_julehefter',
            deactivated: true,
            label: 'Julehefter',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_julekalenderboeker',
            deactivated: true,
            label: 'Julekalenderbøker',
            alternativeLabels: ['Adventskalenderbøker'],

            related: [],
            children: [],
          },
          {
            id: 'form_kart',
            deactivated: true,
            label: 'Kart',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'form_atlas',
                deactivated: true,
                label: 'Atlas',
                alternativeLabels: ['Globuser', 'Kartverk'],

                related: [],
                children: [],
              },
              {
                id: 'form_bykart',
                deactivated: true,
                label: 'Bykart',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'form_kartboeker',
                deactivated: true,
                label: 'Kartbøker',
                alternativeLabels: ['Bilatlas', 'Veiatlas'],

                related: [],
                children: [],
              },
              {
                id: 'form_politiskekart',
                deactivated: true,
                label: 'Politiske kart',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'form_sjoekart',
                deactivated: true,
                label: 'Sjøkart',
                alternativeLabels: ['Draft'],

                related: [],
                children: [],
              },
              {
                id: 'form_topografiskekart',
                deactivated: true,
                label: 'Topografiske kart',
                alternativeLabels: ['Gradteigskart', 'Orienteringskart'],

                related: [],
                children: [],
              },
              {
                id: 'form_veikart',
                deactivated: true,
                label: 'Veikart',
                alternativeLabels: ['Bilkart', 'Turistkart', 'Vegkart'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'form_kaaserier',
            deactivated: true,
            label: 'Kåserier',
            alternativeLabels: ['Petiter'],

            related: [],
            children: [],
          },
          {
            id: 'form_kataloger',
            deactivated: true,
            label: 'Kataloger',
            alternativeLabels: ['Lagerkataloger', 'Varekataloger'],

            related: [],
            children: [
              {
                id: 'form_utstillingskataloger',
                deactivated: true,
                label: 'Utstillingskataloger',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'form_kokeboeker',
            deactivated: true,
            label: 'Kokebøker',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_kryssordboeker',
            deactivated: true,
            label: 'Kryssordbøker',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_kurs',
            deactivated: true,
            label: 'Kurs',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'form_spraakkurs',
                deactivated: true,
                label: 'Språkkurs',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'form_leksika',
            deactivated: true,
            label: 'Leksika',
            alternativeLabels: [
              'Biografiske leksika',
              'Encyklopedier',
              'Konversasjonsleksika',
              'Leksikon',
            ],

            related: [],
            children: [],
          },
          {
            id: 'form_lover',
            deactivated: true,
            label: 'Lover',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'form_domssamlinger',
                deactivated: true,
                label: 'Domssamlinger',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'form_forskrifter',
                deactivated: true,
                label: 'Forskrifter',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'form_lovsamlinger',
                deactivated: true,
                label: 'Lovsamlinger',
                alternativeLabels: ['Lovregistre'],

                related: [],
                children: [],
              },
              {
                id: 'form_saertrykkavlover',
                deactivated: true,
                label: 'Særtrykk av lover',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'form_lyrikk',
            deactivated: true,
            label: 'Lyrikk',
            alternativeLabels: [
              'Dikt',
              'Limericks',
              'Lyrikksamlinger',
              'Poesi',
            ],

            related: [],
            children: [
              {
                id: 'form_prosalyrikk',
                deactivated: true,
                label: 'Prosalyrikk',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'form_multimedia',
            deactivated: true,
            label: 'Multimedia',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_myldreboeker',
            deactivated: true,
            label: 'Myldrebøker',
            alternativeLabels: ['Letebøker', 'Vrimlebøker'],

            related: [],
            children: [],
          },
          {
            id: 'form_noter',
            deactivated: true,
            label: 'Noter',
            alternativeLabels: ['Notetrykk', 'Partitur'],

            related: [],
            children: [],
          },
          {
            id: 'form_noveller',
            deactivated: true,
            label: 'Noveller',
            alternativeLabels: ['Historier', 'Novellesamlinger', 'Novelletter'],

            related: [],
            children: [],
          },
          {
            id: 'form_ordboeker',
            deactivated: true,
            label: 'Ordbøker',
            alternativeLabels: [
              'Fagordbøker',
              'Frekvensordbøker',
              'Konkordans',
              'Rettskrivningsordbøker',
              'Rimordbøker',
            ],

            related: [],
            children: [
              {
                id: 'form_synonym-ogfremmedordboeker',
                deactivated: true,
                label: 'Synonym- og fremmedordbøker',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'form_fremmedordboeker',
                    deactivated: true,
                    label: 'Fremmedordbøker',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'form_synonymordboeker',
                    deactivated: true,
                    label: 'Synonymordbøker',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'form_ordspraak',
            deactivated: true,
            label: 'Ordspråk',
            alternativeLabels: ['Visdomsord'],

            related: [],
            children: [],
          },
          {
            id: 'form_pekeboeker',
            deactivated: true,
            label: 'Pekebøker',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_prosastykker',
            deactivated: true,
            label: 'Prosastykker',
            alternativeLabels: ['Kortprosa', 'Tekster'],

            related: [],
            children: [],
          },
          {
            id: 'form_reise-ogturguider',
            deactivated: true,
            label: 'Reise- og turguider',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'form_reisehaandboeker',
                deactivated: true,
                label: 'Reisehåndbøker',
                alternativeLabels: ['Bil- og campingguider', 'Reiseguider'],

                related: [],
                children: [
                  {
                    id: 'form_byguider',
                    deactivated: true,
                    label: 'Byguider',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'form_parloerer',
                    deactivated: true,
                    label: 'Parlører',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'form_temareiseguider',
                    deactivated: true,
                    label: 'Temareiseguider',
                    alternativeLabels: ['Temaguider'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'form_reiseskildringer',
                deactivated: true,
                label: 'Reiseskildringer',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'form_turguider',
                deactivated: true,
                label: 'Turguider',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'form_rimogregler',
            deactivated: true,
            label: 'Rim og regler',
            alternativeLabels: ['Barnevers'],

            related: [],
            children: [],
          },
          {
            id: 'form_romaner',
            deactivated: true,
            label: 'Romaner',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'form_grafiskeromaner',
                deactivated: true,
                label: 'Grafiske romaner',
                alternativeLabels: ['Tegneserieromaner'],
                note: 'Lengre tegneseriefortelling med en sammenhengende historie',
                related: [
                  {
                    id: 'form_tegneserier',
                    deactivated: true,
                    label: 'Tegneserier',
                  },
                ],
                children: [],
              },
              {
                id: 'form_kortromaner',
                deactivated: true,
                label: 'Kortromaner',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'form_sagaer',
            deactivated: true,
            label: 'Sagaer',
            alternativeLabels: [
              'Kongesagaer',
              'Norrøn litteratur',
              'Ættesagaer',
            ],

            related: [],
            children: [],
          },
          {
            id: 'form_sagnoglegender',
            deactivated: true,
            label: 'Sagn og legender',
            alternativeLabels: ['Legender'],

            related: [],
            children: [],
          },
          {
            id: 'form_sangboeker',
            deactivated: true,
            label: 'Sangbøker',
            alternativeLabels: ['Sanger', 'Visebøker med besifring', 'Viser'],

            related: [],
            children: [
              {
                id: 'form_julesangboeker',
                deactivated: true,
                label: 'Julesangbøker',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'form_sangboekermedbesifring',
                deactivated: true,
                label: 'Sangbøker med besifring',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'form_sangboekermednoter',
                deactivated: true,
                label: 'Sangbøker med noter',
                alternativeLabels: [
                  'Populærmusikk med noter',
                  'Sangtekster med noter',
                  'Visebøker med noter',
                ],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'form_sitatsamlinger',
            deactivated: true,
            label: 'Sitatsamlinger',
            alternativeLabels: ['Aforismer', 'Bevingede ord'],

            related: [],
            children: [],
          },
          {
            id: 'form_skuespill',
            deactivated: true,
            label: 'Skuespill',
            alternativeLabels: ['Drama', 'Dramatiseringer', 'Tragedier'],

            related: [],
            children: [
              {
                id: 'form_hoerespill',
                deactivated: true,
                label: 'Hørespill',
                alternativeLabels: [],
                note: 'Her: Trykte og innspilte hørespill',
                related: [],
                children: [],
              },
              {
                id: 'form_manus',
                deactivated: true,
                label: 'Manus',
                alternativeLabels: ['Filmmanus', 'TV-seriemanus'],

                related: [],
                children: [],
              },
              {
                id: 'form_rollespill',
                deactivated: true,
                label: 'Rollespill',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'form_spoerreboeker',
            deactivated: true,
            label: 'Spørrebøker',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_statistikk',
            deactivated: true,
            label: 'Statistikk',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'form_tegneserier',
            deactivated: true,
            label: 'Tegneserier',
            alternativeLabels: [],

            related: [
              {
                id: 'form_grafiskeromaner',
                deactivated: true,
                label: 'Grafiske romaner',
                note: 'Lengre tegneseriefortelling med en sammenhengende historie',
              },
            ],
            children: [],
          },
          {
            id: 'form_tidsskrifter',
            deactivated: true,
            label: 'Tidsskrifter',
            alternativeLabels: ['Periodika'],

            related: [],
            children: [],
          },
          {
            id: 'form_tilrettelagtlitteratur',
            deactivated: true,
            label: 'Tilrettelagt litteratur',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'form_enkeltekst',
                deactivated: true,
                label: 'Enkel tekst',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'form_enkeltinnhold',
                deactivated: true,
                label: 'Enkelt innhold',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'form_lettlest',
                deactivated: true,
                label: 'Lettlest',
                alternativeLabels: [
                  'Lettles-bøker',
                  'Lettlesbøker',
                  'Lettlest-bøker',
                ],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'form_vitser',
            deactivated: true,
            label: 'Vitser',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'form_vitsetegninger',
                deactivated: true,
                label: 'Vitsetegninger',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'form_musikk',
        deactivated: true,
        label: 'Musikk',
        alternativeLabels: [],

        related: [],
        children: [],
      },
      {
        id: 'form_podkaster',
        deactivated: true,
        label: 'Podkaster',
        alternativeLabels: ['Podcaster'],

        related: [],
        children: [],
      },
    ],
  },
  {
    id: 'genre_genre',
    deactivated: true,
    label: 'Genre',
    alternativeLabels: [],

    related: [],
    children: [
      {
        id: 'genre_biografisklitteratur',
        deactivated: true,
        label: 'Biografisk litteratur',
        alternativeLabels: [],
        note: 'Personlige fortellinger, memoarer, bøker som skildrer deler av et liv og som er mindre omfattende enn Biografier. Eller for høyt fiksjonaliserte beretninger om livene til virkelige mennesker. Både for fag- og skjønnlitteratur.',
        related: [
          {
            id: 'genre_personligeberetninger',
            deactivated: true,
            label: 'Personlige beretninger',
            note: 'Brukes for faglitteratur hvor faget presenteres med eksempler fra eget liv, opplevelser, hendelser. Legg på emneord for hendelsen/faget',
          },
        ],
        children: [],
      },
      {
        id: 'genre_dystopier',
        deactivated: true,
        label: 'Dystopier',
        alternativeLabels: [],
        note: 'Brukes for skjønnlitteratur der handlingen foregår i et ideelt, dysfunksjonelt eller degradert samfunn',
        related: [],
        children: [],
      },
      {
        id: 'genre_erotikk',
        deactivated: true,
        label: 'Erotikk',
        alternativeLabels: [],
        note: 'Brukes for erotisk skjønnlitteratur med eksplisitte skildringer av forhold av seksuell karakter, og hvor hovedpersonene ikke nødvendigvis har et romantisk forhold',
        related: [],
        children: [],
      },
      {
        id: 'genre_fantastisklitteratur',
        deactivated: true,
        label: 'Fantastisk litteratur',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'genre_fantasy',
            deactivated: true,
            label: 'Fantasy',
            alternativeLabels: [
              'Dark fantasy',
              'Heroisk fantasy',
              'Magisk verden',
              'Romantisk fantasy',
              'Urban fantasy',
            ],
            note: 'Brukes for skjønnlitteratur som har handling i et tenkt og imaginært univers. Med innhold av magi, det overnaturlige og magiske vesener',
            related: [],
            children: [],
          },
          {
            id: 'genre_magisk-realistisklitteratur',
            deactivated: true,
            label: 'Magisk-realistisk litteratur',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur hvor overnaturlige fenomener finner sted i en realistisk handling. Opprinnelig brukt på magisk-realistisk litteratur fra Latin-Amerika',
            related: [],
            children: [],
          },
          {
            id: 'genre_sciencefiction',
            deactivated: true,
            label: 'Science fiction',
            alternativeLabels: [
              'Cyberpunk',
              'Framtidsvisjoner',
              'Romopera',
              'Steampunk',
              'Utopier',
            ],
            note: 'En imaginær verden, ofte lagt til fremtid. Handler ofte om tidsreiser, roboter, romfart, telepati, kloning, forholdet mellom menneske og maskin',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'genre_godnatthistorier',
        deactivated: true,
        label: 'Godnatthistorier',
        alternativeLabels: [],
        note: 'Billedbøker, for små barn, som kan leses høyt på sengekanten',
        related: [],
        children: [],
      },
      {
        id: 'genre_historisklitteratur',
        deactivated: true,
        label: 'Historisk litteratur',
        alternativeLabels: [],
        note: 'Brukes for skjønnlitteratur der handlingen er lagt til fortiden (vanligvis før forfatterens levetid). Tidsperioden er en viktig del av historien og den skildrer tidsånden når det gjelder skikker og sosiale forhold.',
        related: [],
        children: [],
      },
      {
        id: 'genre_humor',
        deactivated: true,
        label: 'Humor',
        alternativeLabels: ['Skrøner'],
        note: 'Brukes på både for fag- og skjønnlitteratur der humor er det primære målet',
        related: [],
        children: [
          {
            id: 'genre_satire',
            deactivated: true,
            label: 'Satire',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'genre_klassisklitteratur',
        deactivated: true,
        label: 'Klassisk litteratur',
        alternativeLabels: [],
        note: 'Her: skjønnlitteratur som er utgitt for minst 50 år siden, eller som er ansett som «klassisk»',
        related: [],
        children: [],
      },
      {
        id: 'genre_kontrafaktisklitteratur',
        deactivated: true,
        label: 'Kontrafaktisk litteratur',
        alternativeLabels: [],
        note: 'Brukes for skjønnlitteratur som foregår i en tenkt verden som kunne ha eksistert dersom viktige historiske hendelser hadde fått et annet utfall, f.eks utfallet av 2. verdenskrig',
        related: [],
        children: [],
      },
      {
        id: 'genre_krimogspenning',
        deactivated: true,
        label: 'Krim og spenning',
        alternativeLabels: [],
        note: 'Brukes for skjønnlitteratur',
        related: [],
        children: [
          {
            id: 'genre_agenterogspioner',
            deactivated: true,
            label: 'Agenter og spioner',
            alternativeLabels: [],
            note: 'Brukes sammen med Krim eller Thriller',
            related: [],
            children: [],
          },
          {
            id: 'genre_groessere',
            deactivated: true,
            label: 'Grøssere',
            alternativeLabels: ['Okkulte romaner', 'Spøkelseshistorier'],
            note: 'Her: også klassiske, gotiske grøssere',
            related: [],
            children: [],
          },
          {
            id: 'genre_krim',
            deactivated: true,
            label: 'Krim',
            alternativeLabels: [],
            note: 'Brukes på fortellinger hvor fokuset er på å oppklare en forbrytelse',
            related: [],
            children: [],
          },
          {
            id: 'genre_politiogdetektiver',
            deactivated: true,
            label: 'Politi og detektiver',
            alternativeLabels: [],
            note: 'Brukes sammen med krim. Hovedpersonene er politi eller detektiver som oppklarer en forbrytelse',
            related: [],
            children: [],
          },
          {
            id: 'genre_spenning',
            deactivated: true,
            label: 'Spenning',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'genre_thrillere',
            deactivated: true,
            label: 'Thrillere',
            alternativeLabels: [],
            note: 'Spennende, psykologiske fortellinger. Spennende fortellinger hvor fokus ikke nødvendigvis er å oppklare en forbrytelse',
            related: [],
            children: [
              {
                id: 'genre_psykologiskethrillere',
                deactivated: true,
                label: 'Psykologiske thrillere',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'genre_manga',
        deactivated: true,
        label: 'Manga',
        alternativeLabels: ['Manhua', 'Manhwa'],
        note: 'Her: Tegneserier i asiatisk tradisjon',
        related: [],
        children: [],
      },
      {
        id: 'genre_personligeberetninger',
        deactivated: true,
        label: 'Personlige beretninger',
        alternativeLabels: ['Erindringer'],
        note: 'Brukes for faglitteratur hvor faget presenteres med eksempler fra eget liv, opplevelser, hendelser. Legg på emneord for hendelsen/faget',
        related: [
          {
            id: 'genre_biografisklitteratur',
            deactivated: true,
            label: 'Biografisk litteratur',
            note: 'Personlige fortellinger, memoarer, bøker som skildrer deler av et liv og som er mindre omfattende enn Biografier. Eller for høyt fiksjonaliserte beretninger om livene til virkelige mennesker. Både for fag- og skjønnlitteratur.',
          },
        ],
        children: [
          {
            id: 'genre_hverdagsbetraktninger',
            deactivated: true,
            label: 'Hverdagsbetraktninger',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'genre_religioeslitteratur',
        deactivated: true,
        label: 'Religiøs litteratur',
        alternativeLabels: ['Kristelig litteratur'],
        note: 'Brukes først og fremst for skjønnlitteratur som fremmer et religiøst, åndelig eller visjonært budskap',
        related: [],
        children: [
          {
            id: 'genre_bibelfortellinger',
            deactivated: true,
            label: 'Bibelfortellinger',
            alternativeLabels: [],
            note: 'Brukes for skjønnlitteratur hvor fortellingen henter karakterer og handling fra Bibelen',
            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'genre_romantikk',
        deactivated: true,
        label: 'Romantikk',
        alternativeLabels: [],
        note: 'Skjønnlitteratur som skildrer kjærlighet og romanse mellom to personer, ofte med lykkelig slutt. Ikke narrativ litteratur, ofte serielitteratur',
        related: [],
        children: [],
      },
      {
        id: 'genre_western',
        deactivated: true,
        label: 'Western',
        alternativeLabels: [],
        note: 'Skjønnlitteratur hvor handlingen foregår i det såkalte ville vesten i amerikansk historie',
        related: [],
        children: [],
      },
    ],
  },
  {
    id: 'sted_sted',
    deactivated: false,
    label: 'Sted',
    alternativeLabels: [],

    related: [],
    children: [
      {
        id: 'sted_historiskelandogriker',
        deactivated: false,
        label: 'Historiske land og riker',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'sted_indokina',
            deactivated: false,
            label: 'Indokina',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'sted_mesopotamia',
            deactivated: false,
            label: 'Mesopotamia',
            alternativeLabels: ['Assyria'],

            related: [],
            children: [],
          },
          {
            id: 'sted_osmanskerike',
            deactivated: false,
            label: 'Osmanske rike',
            alternativeLabels: ['Ottomanske rike'],

            related: [],
            children: [],
          },
          {
            id: 'sted_persia',
            deactivated: false,
            label: 'Persia',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'sted_romerriket',
            deactivated: false,
            label: 'Romerriket',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
      {
        id: 'sted_polaromraadene',
        deactivated: false,
        label: 'Polarområdene',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'sted_antarktis',
            deactivated: false,
            label: 'Antarktis',
            alternativeLabels: ['Sydpolen', 'Sørpolen'],

            related: [],
            children: [],
          },
          {
            id: 'sted_arktis',
            deactivated: false,
            label: 'Arktis',
            alternativeLabels: ['Nordpolen'],

            related: [],
            children: [
              {
                id: 'sted_groenland',
                deactivated: false,
                label: 'Grønland',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_janmayen',
                deactivated: false,
                label: 'Jan Mayen',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_svalbard',
                deactivated: false,
                label: 'Svalbard',
                alternativeLabels: ['Spitsbergen'],

                related: [],
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 'sted_verdensdeler',
        deactivated: false,
        label: 'Verdensdeler',
        alternativeLabels: ['Kontinenter'],

        related: [],
        children: [
          {
            id: 'sted_afrika',
            deactivated: false,
            label: 'Afrika',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'sted_algerie',
                deactivated: false,
                label: 'Algerie',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_angola',
                deactivated: false,
                label: 'Angola',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_benin',
                deactivated: false,
                label: 'Benin',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_botswana',
                deactivated: false,
                label: 'Botswana',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_burkinafaso',
                deactivated: false,
                label: 'Burkina Faso',
                alternativeLabels: ['Øvre Volta'],

                related: [],
                children: [],
              },
              {
                id: 'sted_burundi',
                deactivated: false,
                label: 'Burundi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_djibouti',
                deactivated: false,
                label: 'Djibouti',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_egypt',
                deactivated: false,
                label: 'Egypt',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_kairo',
                    deactivated: false,
                    label: 'Kairo',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_ekvatorial-guinea,republikken',
                deactivated: false,
                label: 'Ekvatorial-Guinea, Republikken',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_elfenbenskysten',
                deactivated: false,
                label: 'Elfenbenskysten',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_eritrea',
                deactivated: false,
                label: 'Eritrea',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_eswatini',
                deactivated: false,
                label: 'Eswatini',
                alternativeLabels: ['Kongeriket Eswatini', 'Swaziland'],

                related: [],
                children: [],
              },
              {
                id: 'sted_etiopia',
                deactivated: false,
                label: 'Etiopia',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_gabon',
                deactivated: false,
                label: 'Gabon',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_gambia',
                deactivated: false,
                label: 'Gambia',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_ghana',
                deactivated: false,
                label: 'Ghana',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_guinea',
                deactivated: false,
                label: 'Guinea',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_kamerun',
                deactivated: false,
                label: 'Kamerun',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_kappverde',
                deactivated: false,
                label: 'Kapp Verde',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_kenya',
                deactivated: false,
                label: 'Kenya',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_kongo,dendemokratiskerepublikken',
                deactivated: false,
                label: 'Kongo, Den demokratiske republikken',
                alternativeLabels: [
                  'Den demokratiske republikken Kongo',
                  'Zaire',
                ],

                related: [],
                children: [
                  {
                    id: 'sted_kinshasa',
                    deactivated: false,
                    label: 'Kinshasa',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_kongo,republikken',
                deactivated: false,
                label: 'Kongo, republikken',
                alternativeLabels: ['Kongo-Brazzaville', 'Republikken Kongo'],

                related: [],
                children: [
                  {
                    id: 'sted_brazzaville',
                    deactivated: false,
                    label: 'Brazzaville',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_lesotho',
                deactivated: false,
                label: 'Lesotho',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_liberia',
                deactivated: false,
                label: 'Liberia',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_libya',
                deactivated: false,
                label: 'Libya',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_madagaskar',
                deactivated: false,
                label: 'Madagaskar',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_malawi',
                deactivated: false,
                label: 'Malawi',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_mali',
                deactivated: false,
                label: 'Mali',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_marokko',
                deactivated: false,
                label: 'Marokko',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_mauritania',
                deactivated: false,
                label: 'Mauritania',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_mauritius',
                deactivated: false,
                label: 'Mauritius',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_mosambik',
                deactivated: false,
                label: 'Mosambik',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_namibia',
                deactivated: false,
                label: 'Namibia',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_niger',
                deactivated: false,
                label: 'Niger',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_nigeria',
                deactivated: false,
                label: 'Nigeria',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_rwanda',
                deactivated: false,
                label: 'Rwanda',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_senegal',
                deactivated: false,
                label: 'Senegal',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_sentral-afrikanskerepublikk',
                deactivated: false,
                label: 'Sentral-Afrikanske republikk',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_seychellene',
                deactivated: false,
                label: 'Seychellene',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_sierraleone',
                deactivated: false,
                label: 'Sierra Leone',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_somalia',
                deactivated: false,
                label: 'Somalia',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_soer-afrika',
                deactivated: false,
                label: 'Sør-Afrika',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_natal',
                    deactivated: false,
                    label: 'Natal',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_transvaal',
                    deactivated: false,
                    label: 'Transvaal',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_soer-sudan',
                deactivated: false,
                label: 'Sør-Sudan',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_sudan',
                deactivated: false,
                label: 'Sudan',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_tanzania',
                deactivated: false,
                label: 'Tanzania',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_tchad',
                deactivated: false,
                label: 'Tchad',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_tunisia',
                deactivated: false,
                label: 'Tunisia',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_uganda',
                deactivated: false,
                label: 'Uganda',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_vest-sahara',
                deactivated: false,
                label: 'Vest-Sahara',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_zambia',
                deactivated: false,
                label: 'Zambia',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_zimbabwe',
                deactivated: false,
                label: 'Zimbabwe',
                alternativeLabels: ['Rhodesia'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'sted_amerika',
            deactivated: false,
            label: 'Amerika',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'sted_nord-amerikaogmellom-amerika',
                deactivated: false,
                label: 'Nord-Amerika og Mellom-Amerika',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_canada',
                    deactivated: false,
                    label: 'Canada',
                    alternativeLabels: ['Kanada'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_karibia',
                    deactivated: false,
                    label: 'Karibia',
                    alternativeLabels: ['Antillene', 'Vest-India'],

                    related: [],
                    children: [
                      {
                        id: 'sted_bahamas',
                        deactivated: false,
                        label: 'Bahamas',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_barbados',
                        deactivated: false,
                        label: 'Barbados',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_bermuda',
                        deactivated: false,
                        label: 'Bermuda',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_cuba',
                        deactivated: false,
                        label: 'Cuba',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_dominica',
                        deactivated: false,
                        label: 'Dominica',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_dominikanskerepublikk',
                        deactivated: false,
                        label: 'Dominikanske republikk',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_haiti',
                        deactivated: false,
                        label: 'Haiti',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_jamaica',
                        deactivated: false,
                        label: 'Jamaica',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_puertorico',
                        deactivated: false,
                        label: 'Puerto Rico',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_trinidadogtobago',
                        deactivated: false,
                        label: 'Trinidad og Tobago',
                        alternativeLabels: ['Tobago'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'sted_mexico',
                    deactivated: false,
                    label: 'Mexico',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_sentral-amerika',
                    deactivated: false,
                    label: 'Sentral-Amerika',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_belize',
                        deactivated: false,
                        label: 'Belize',
                        alternativeLabels: ['Britisk Honduras'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_costarica',
                        deactivated: false,
                        label: 'Costa Rica',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_elsalvador',
                        deactivated: false,
                        label: 'El Salvador',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_guatemala',
                        deactivated: false,
                        label: 'Guatemala',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_honduras',
                        deactivated: false,
                        label: 'Honduras',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_nicaragua',
                        deactivated: false,
                        label: 'Nicaragua',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_panama',
                        deactivated: false,
                        label: 'Panama',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'sted_usa',
                    deactivated: false,
                    label: 'USA',
                    alternativeLabels: ['De forente stater', 'Forente stater'],

                    related: [],
                    children: [
                      {
                        id: 'sted_alaska',
                        deactivated: false,
                        label: 'Alaska',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_hawaii',
                        deactivated: false,
                        label: 'Hawaii',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_nordligesentralstater',
                        deactivated: false,
                        label: 'Nordlige sentralstater',
                        alternativeLabels: ['Midtvesten'],

                        related: [],
                        children: [
                          {
                            id: 'sted_illinois',
                            deactivated: false,
                            label: 'Illinois',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'sted_chicago',
                                deactivated: false,
                                label: 'Chicago',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'sted_indiana',
                            deactivated: false,
                            label: 'Indiana',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_iowa',
                            deactivated: false,
                            label: 'Iowa',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_michigan',
                            deactivated: false,
                            label: 'Michigan',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_minnesota',
                            deactivated: false,
                            label: 'Minnesota',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_missouri',
                            deactivated: false,
                            label: 'Missouri',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_ohio',
                            deactivated: false,
                            label: 'Ohio',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_wisconsin',
                            deactivated: false,
                            label: 'Wisconsin',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'sted_nordoestligestater',
                        deactivated: false,
                        label: 'Nordøstlige stater',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'sted_connecticut',
                            deactivated: false,
                            label: 'Connecticut',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_maine',
                            deactivated: false,
                            label: 'Maine',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_massachusetts',
                            deactivated: false,
                            label: 'Massachusetts',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'sted_boston',
                                deactivated: false,
                                label: 'Boston',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'sted_newhampshire',
                            deactivated: false,
                            label: 'New Hampshire',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_newjersey',
                            deactivated: false,
                            label: 'New Jersey',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_newyork',
                            deactivated: false,
                            label: 'New York',
                            alternativeLabels: [],
                            note: 'Både by og stat',
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_pennsylvania',
                            deactivated: false,
                            label: 'Pennsylvania',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_rhodeisland',
                            deactivated: false,
                            label: 'Rhode Island',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_vermont',
                            deactivated: false,
                            label: 'Vermont',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'sted_soerligesentralstater',
                        deactivated: false,
                        label: 'Sørlige sentralstater',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'sted_alabama',
                            deactivated: false,
                            label: 'Alabama',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_arkansas',
                            deactivated: false,
                            label: 'Arkansas',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_kentucky',
                            deactivated: false,
                            label: 'Kentucky',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_louisiana',
                            deactivated: false,
                            label: 'Louisiana',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'sted_neworleans',
                                deactivated: false,
                                label: 'New Orleans',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'sted_mississippi',
                            deactivated: false,
                            label: 'Mississippi',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_oklahoma',
                            deactivated: false,
                            label: 'Oklahoma',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_tennessee',
                            deactivated: false,
                            label: 'Tennessee',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_texas',
                            deactivated: false,
                            label: 'Texas',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'sted_soeroestligestater',
                        deactivated: false,
                        label: 'Sørøstlige stater',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'sted_delaware',
                            deactivated: false,
                            label: 'Delaware',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_florida',
                            deactivated: false,
                            label: 'Florida',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'sted_miami',
                                deactivated: false,
                                label: 'Miami',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'sted_georgia,usa',
                            deactivated: false,
                            label: 'Georgia, USA',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_maryland',
                            deactivated: false,
                            label: 'Maryland',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_northcarolina',
                            deactivated: false,
                            label: 'North Carolina',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_southcarolina',
                            deactivated: false,
                            label: 'South Carolina',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_virginia',
                            deactivated: false,
                            label: 'Virginia',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_washingtond.c.',
                            deactivated: false,
                            label: 'Washington D.C.',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_westvirginia',
                            deactivated: false,
                            label: 'West Virginia',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'sted_stillehavsstatene',
                        deactivated: false,
                        label: 'Stillehavsstatene',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'sted_arizona',
                            deactivated: false,
                            label: 'Arizona',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_california',
                            deactivated: false,
                            label: 'California',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'sted_losangeles',
                                deactivated: false,
                                label: 'Los Angeles',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_sanfrancisco',
                                deactivated: false,
                                label: 'San Francisco',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'sted_idaho',
                            deactivated: false,
                            label: 'Idaho',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_nevada',
                            deactivated: false,
                            label: 'Nevada',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'sted_lasvegas',
                                deactivated: false,
                                label: 'Las Vegas',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'sted_oregon',
                            deactivated: false,
                            label: 'Oregon',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_utah',
                            deactivated: false,
                            label: 'Utah',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_washington',
                            deactivated: false,
                            label: 'Washington',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'sted_veststatene',
                        deactivated: false,
                        label: 'Veststatene',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'sted_colorado',
                            deactivated: false,
                            label: 'Colorado',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_kansas',
                            deactivated: false,
                            label: 'Kansas',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_montana',
                            deactivated: false,
                            label: 'Montana',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_nebraska',
                            deactivated: false,
                            label: 'Nebraska',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_newmexico',
                            deactivated: false,
                            label: 'New Mexico',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_northdakota',
                            deactivated: false,
                            label: 'North Dakota',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_southdakota',
                            deactivated: false,
                            label: 'South Dakota',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_wyoming',
                            deactivated: false,
                            label: 'Wyoming',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'sted_soer-amerika',
                deactivated: false,
                label: 'Sør-Amerika',
                alternativeLabels: ['Latin-Amerika'],

                related: [],
                children: [
                  {
                    id: 'sted_argentina',
                    deactivated: false,
                    label: 'Argentina',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_buenosaires',
                        deactivated: false,
                        label: 'Buenos Aires',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'sted_bolivia',
                    deactivated: false,
                    label: 'Bolivia',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_brasil',
                    deactivated: false,
                    label: 'Brasil',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_chile',
                    deactivated: false,
                    label: 'Chile',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_colombia',
                    deactivated: false,
                    label: 'Colombia',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_ecuador',
                    deactivated: false,
                    label: 'Ecuador',
                    alternativeLabels: ['Galapagos-øyene'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_franskguyana',
                    deactivated: false,
                    label: 'Fransk Guyana',
                    alternativeLabels: ['Guyane Francaise'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_guyana',
                    deactivated: false,
                    label: 'Guyana',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_paraguay',
                    deactivated: false,
                    label: 'Paraguay',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_peru',
                    deactivated: false,
                    label: 'Peru',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_suriname',
                    deactivated: false,
                    label: 'Suriname',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_uruguay',
                    deactivated: false,
                    label: 'Uruguay',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_venezuela',
                    deactivated: false,
                    label: 'Venezuela',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'sted_asia',
            deactivated: false,
            label: 'Asia',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'sted_arabiskehalvoey',
                deactivated: false,
                label: 'Arabiske halvøy',
                alternativeLabels: ['Arabia'],

                related: [],
                children: [
                  {
                    id: 'sted_bahrain',
                    deactivated: false,
                    label: 'Bahrain',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_forentearabiskeemirater',
                    deactivated: false,
                    label: 'Forente arabiske emirater',
                    alternativeLabels: ['Emiratene'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_jemen',
                    deactivated: false,
                    label: 'Jemen',
                    alternativeLabels: [
                      'Den arabiske republikk Yemen',
                      'Yemen',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_kuwait',
                    deactivated: false,
                    label: 'Kuwait',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_oman',
                    deactivated: false,
                    label: 'Oman',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_qatar',
                    deactivated: false,
                    label: 'Qatar',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_saudi-arabia',
                    deactivated: false,
                    label: 'Saudi-Arabia',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_bangladesh',
                deactivated: false,
                label: 'Bangladesh',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_bhutan',
                deactivated: false,
                label: 'Bhutan',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_india',
                deactivated: false,
                label: 'India',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_iran',
                deactivated: false,
                label: 'Iran',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_japan',
                deactivated: false,
                label: 'Japan',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_kyoto',
                    deactivated: false,
                    label: 'Kyoto',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_tokyo',
                    deactivated: false,
                    label: 'Tokyo',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_kina',
                deactivated: false,
                label: 'Kina',
                alternativeLabels: ['China'],

                related: [],
                children: [
                  {
                    id: 'sted_beijing',
                    deactivated: false,
                    label: 'Beijing',
                    alternativeLabels: ['Peking'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_hongkong',
                    deactivated: false,
                    label: 'Hong Kong',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_macau',
                    deactivated: false,
                    label: 'Macau',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_tibet',
                    deactivated: false,
                    label: 'Tibet',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_korea',
                deactivated: false,
                label: 'Korea',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_nord-korea',
                    deactivated: false,
                    label: 'Nord-Korea',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_soer-korea',
                    deactivated: false,
                    label: 'Sør-Korea',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_maldivene',
                deactivated: false,
                label: 'Maldivene',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_midtoesten',
                deactivated: false,
                label: 'Midtøsten',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_irak',
                    deactivated: false,
                    label: 'Irak',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_israel',
                    deactivated: false,
                    label: 'Israel',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_jerusalem',
                        deactivated: false,
                        label: 'Jerusalem',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'sted_jordan',
                    deactivated: false,
                    label: 'Jordan',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_kypros',
                    deactivated: false,
                    label: 'Kypros',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_libanon',
                    deactivated: false,
                    label: 'Libanon',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_palestina',
                    deactivated: false,
                    label: 'Palestina',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_gaza',
                        deactivated: false,
                        label: 'Gaza',
                        alternativeLabels: ['Gazastripen'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_vestbredden',
                        deactivated: false,
                        label: 'Vestbredden',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'sted_syria',
                    deactivated: false,
                    label: 'Syria',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_tyrkia',
                    deactivated: false,
                    label: 'Tyrkia',
                    alternativeLabels: ['Bysants'],

                    related: [],
                    children: [
                      {
                        id: 'sted_istanbul',
                        deactivated: false,
                        label: 'Istanbul',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'sted_mongolia',
                deactivated: false,
                label: 'Mongolia',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_myanmar',
                deactivated: false,
                label: 'Myanmar',
                alternativeLabels: ['Burma'],

                related: [],
                children: [],
              },
              {
                id: 'sted_nepal',
                deactivated: false,
                label: 'Nepal',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_pakistan',
                deactivated: false,
                label: 'Pakistan',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_sentral-asia',
                deactivated: false,
                label: 'Sentral-Asia',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_afghanistan',
                    deactivated: false,
                    label: 'Afghanistan',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_kasakhstan',
                    deactivated: false,
                    label: 'Kasakhstan',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_kirgisistan',
                    deactivated: false,
                    label: 'Kirgisistan',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_tadsjikistan',
                    deactivated: false,
                    label: 'Tadsjikistan',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_turkmenistan',
                    deactivated: false,
                    label: 'Turkmenistan',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_usbekistan',
                    deactivated: false,
                    label: 'Usbekistan',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_soeroest-asia',
                deactivated: false,
                label: 'Sørøst-Asia',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_brunei',
                    deactivated: false,
                    label: 'Brunei',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_filippinene',
                    deactivated: false,
                    label: 'Filippinene',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_indonesia',
                    deactivated: false,
                    label: 'Indonesia',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_bali',
                        deactivated: false,
                        label: 'Bali',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_jakarta',
                        deactivated: false,
                        label: 'Jakarta',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_java',
                        deactivated: false,
                        label: 'Java',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_timor',
                        deactivated: false,
                        label: 'Timor',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'sted_oest-timor',
                            deactivated: false,
                            label: 'Øst-Timor',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'sted_kambodsja',
                    deactivated: false,
                    label: 'Kambodsja',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_laos',
                    deactivated: false,
                    label: 'Laos',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_malaysia',
                    deactivated: false,
                    label: 'Malaysia',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_singapore',
                    deactivated: false,
                    label: 'Singapore',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_thailand',
                    deactivated: false,
                    label: 'Thailand',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_bangkok',
                        deactivated: false,
                        label: 'Bangkok',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'sted_vietnam',
                    deactivated: false,
                    label: 'Vietnam',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_nord-vietnam',
                        deactivated: false,
                        label: 'Nord-Vietnam',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_soer-vietnam',
                        deactivated: false,
                        label: 'Sør-Vietnam',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'sted_srilanka',
                deactivated: false,
                label: 'Sri Lanka',
                alternativeLabels: ['Ceylon'],

                related: [],
                children: [],
              },
              {
                id: 'sted_taiwan',
                deactivated: false,
                label: 'Taiwan',
                alternativeLabels: ['Formosa'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'sted_australiaogoseania',
            deactivated: false,
            label: 'Australia og Oseania',
            alternativeLabels: ['Stillehavsøyene'],

            related: [],
            children: [
              {
                id: 'sted_australia',
                deactivated: false,
                label: 'Australia',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_sydney',
                    deactivated: false,
                    label: 'Sydney',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_tasmania',
                    deactivated: false,
                    label: 'Tasmania',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_newzealand',
                deactivated: false,
                label: 'New Zealand',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_oseania',
                deactivated: false,
                label: 'Oseania',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_melanesia',
                    deactivated: false,
                    label: 'Melanesia',
                    alternativeLabels: [
                      'Ny-Caledonia',
                      'Salomonøyene',
                      'Vanuatu',
                    ],

                    related: [],
                    children: [
                      {
                        id: 'sted_fiji',
                        deactivated: false,
                        label: 'Fiji',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_ny-guinea',
                        deactivated: false,
                        label: 'Ny-Guinea',
                        alternativeLabels: [],
                        note: 'Brukes for hele øya',
                        related: [],
                        children: [
                          {
                            id: 'sted_papua',
                            deactivated: false,
                            label: 'Papua',
                            alternativeLabels: ['Vest-Papua'],
                            note: 'Her: det indonesiske territoriumet som utgjør den vestlige halvdelen av øya Ny-Guinea',
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_papuany-guinea',
                            deactivated: false,
                            label: 'Papua Ny-Guinea',
                            alternativeLabels: [
                              'Den uavhengige staten Papua Ny-Guinea',
                            ],

                            related: [],
                            children: [],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'sted_mikronesia',
                    deactivated: false,
                    label: 'Mikronesia',
                    alternativeLabels: [
                      'Gilbertøyene',
                      'Guam',
                      'Karolinene',
                      'Marshalløyene',
                      'Mikronesiaføderasjonen',
                      'Nauru',
                      'Nord-Marianene',
                      'Palau',
                    ],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_polynesia',
                    deactivated: false,
                    label: 'Polynesia',
                    alternativeLabels: [
                      'Cookøyene',
                      'Fransk Polynesia',
                      'Kiribati',
                      'Niue',
                      'Pitcairnøyene',
                      'Påskeøya',
                      'Samoaøyene',
                      'Tahiti',
                      'Tokelau',
                      'Tongaøyene',
                      'Tuvalu',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'sted_europa',
            deactivated: false,
            label: 'Europa',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'sted_armenia',
                deactivated: false,
                label: 'Armenia',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_aserbajdsjan',
                deactivated: false,
                label: 'Aserbajdsjan',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_balkan',
                deactivated: false,
                label: 'Balkan',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_albania',
                    deactivated: false,
                    label: 'Albania',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_bosnia-hercegovina',
                    deactivated: false,
                    label: 'Bosnia-Hercegovina',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_sarajevo',
                        deactivated: false,
                        label: 'Sarajevo',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'sted_kosovo',
                    deactivated: false,
                    label: 'Kosovo',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_kroatia',
                    deactivated: false,
                    label: 'Kroatia',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_dubrovnik',
                        deactivated: false,
                        label: 'Dubrovnik',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'sted_montenegro',
                    deactivated: false,
                    label: 'Montenegro',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_nord-makedonia',
                    deactivated: false,
                    label: 'Nord-Makedonia',
                    alternativeLabels: ['Makedonia'],
                    note: 'Endret navn fra Makedonia i februar 2019',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_serbia',
                    deactivated: false,
                    label: 'Serbia',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_slovenia',
                    deactivated: false,
                    label: 'Slovenia',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_ljubljana',
                        deactivated: false,
                        label: 'Ljubljana',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'sted_baltikum',
                deactivated: false,
                label: 'Baltikum',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_estland',
                    deactivated: false,
                    label: 'Estland',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_tallinn',
                        deactivated: false,
                        label: 'Tallinn',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'sted_latvia',
                    deactivated: false,
                    label: 'Latvia',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_riga',
                        deactivated: false,
                        label: 'Riga',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'sted_litauen',
                    deactivated: false,
                    label: 'Litauen',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_vilnius',
                        deactivated: false,
                        label: 'Vilnius',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'sted_belarus',
                deactivated: false,
                label: 'Belarus',
                alternativeLabels: ['Hviterussland'],

                related: [],
                children: [],
              },
              {
                id: 'sted_benelux',
                deactivated: false,
                label: 'Benelux',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_belgia',
                    deactivated: false,
                    label: 'Belgia',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_brussel',
                        deactivated: false,
                        label: 'Brussel',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'sted_luxembourg',
                    deactivated: false,
                    label: 'Luxembourg',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_nederland',
                    deactivated: false,
                    label: 'Nederland',
                    alternativeLabels: ['Holland'],

                    related: [],
                    children: [
                      {
                        id: 'sted_amsterdam',
                        deactivated: false,
                        label: 'Amsterdam',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'sted_britiskeoeyer',
                deactivated: false,
                label: 'Britiske øyer',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_irland',
                    deactivated: false,
                    label: 'Irland',
                    alternativeLabels: ['Eire', 'Irske republikk'],

                    related: [],
                    children: [
                      {
                        id: 'sted_dublin',
                        deactivated: false,
                        label: 'Dublin',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'sted_storbritannia',
                    deactivated: false,
                    label: 'Storbritannia',
                    alternativeLabels: ['UK', 'United Kingdom'],

                    related: [],
                    children: [
                      {
                        id: 'sted_england',
                        deactivated: false,
                        label: 'England',
                        alternativeLabels: ['Kanaløyene'],

                        related: [],
                        children: [
                          {
                            id: 'sted_london',
                            deactivated: false,
                            label: 'London',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'sted_nord-irland',
                        deactivated: false,
                        label: 'Nord-Irland',
                        alternativeLabels: ['Ulster'],

                        related: [],
                        children: [
                          {
                            id: 'sted_belfast',
                            deactivated: false,
                            label: 'Belfast',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'sted_skottland',
                        deactivated: false,
                        label: 'Skottland',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'sted_edinburgh',
                            deactivated: false,
                            label: 'Edinburgh',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_hebridene',
                            deactivated: false,
                            label: 'Hebridene',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_orknoeyene',
                            deactivated: false,
                            label: 'Orknøyene',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_shetlandsoeyene',
                            deactivated: false,
                            label: 'Shetlandsøyene',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'sted_wales',
                        deactivated: false,
                        label: 'Wales',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'sted_bulgaria',
                deactivated: false,
                label: 'Bulgaria',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_frankrike',
                deactivated: false,
                label: 'Frankrike',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_korsika',
                    deactivated: false,
                    label: 'Korsika',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_paris',
                    deactivated: false,
                    label: 'Paris',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_georgia',
                deactivated: false,
                label: 'Georgia',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_tbilisi',
                    deactivated: false,
                    label: 'Tbilisi',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_hellas',
                deactivated: false,
                label: 'Hellas',
                alternativeLabels: ['Grekenland'],

                related: [],
                children: [
                  {
                    id: 'sted_athen',
                    deactivated: false,
                    label: 'Athen',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_kos',
                    deactivated: false,
                    label: 'Kos',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_kreta',
                    deactivated: false,
                    label: 'Kreta',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_rhodos',
                    deactivated: false,
                    label: 'Rhodos',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_iberiskehalvoey',
                deactivated: false,
                label: 'Iberiske halvøy',
                alternativeLabels: ['Iberia'],

                related: [],
                children: [
                  {
                    id: 'sted_andorra',
                    deactivated: false,
                    label: 'Andorra',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_portugal',
                    deactivated: false,
                    label: 'Portugal',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_azorene',
                        deactivated: false,
                        label: 'Azorene',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_lisboa',
                        deactivated: false,
                        label: 'Lisboa',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_madeira',
                        deactivated: false,
                        label: 'Madeira',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'sted_spania',
                    deactivated: false,
                    label: 'Spania',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_balearene',
                        deactivated: false,
                        label: 'Balearene',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'sted_formentera',
                            deactivated: false,
                            label: 'Formentera',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_ibiza',
                            deactivated: false,
                            label: 'Ibiza',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_mallorca',
                            deactivated: false,
                            label: 'Mallorca',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_menorca',
                            deactivated: false,
                            label: 'Menorca',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'sted_barcelona',
                        deactivated: false,
                        label: 'Barcelona',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_kanarioeyene',
                        deactivated: false,
                        label: 'Kanariøyene',
                        alternativeLabels: ['Gomera', 'Hierro', 'La Palma'],

                        related: [],
                        children: [
                          {
                            id: 'sted_fuerteventura',
                            deactivated: false,
                            label: 'Fuerteventura',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_grancanaria',
                            deactivated: false,
                            label: 'Gran Canaria',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_lanzarote',
                            deactivated: false,
                            label: 'Lanzarote',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_tenerife',
                            deactivated: false,
                            label: 'Tenerife',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                        ],
                      },
                      {
                        id: 'sted_madrid',
                        deactivated: false,
                        label: 'Madrid',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'sted_italia',
                deactivated: false,
                label: 'Italia',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_firenze',
                    deactivated: false,
                    label: 'Firenze',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_roma',
                    deactivated: false,
                    label: 'Roma',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_sardinia',
                    deactivated: false,
                    label: 'Sardinia',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_sicilia',
                    deactivated: false,
                    label: 'Sicilia',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_venezia',
                    deactivated: false,
                    label: 'Venezia',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_liechtenstein',
                deactivated: false,
                label: 'Liechtenstein',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_malta',
                deactivated: false,
                label: 'Malta',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_moldova',
                deactivated: false,
                label: 'Moldova',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_monaco',
                deactivated: false,
                label: 'Monaco',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_norden',
                deactivated: false,
                label: 'Norden',
                alternativeLabels: ['Skandinavia'],

                related: [],
                children: [
                  {
                    id: 'sted_danmark',
                    deactivated: false,
                    label: 'Danmark',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_faeroeyene',
                        deactivated: false,
                        label: 'Færøyene',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                      {
                        id: 'sted_koebenhavn',
                        deactivated: false,
                        label: 'København',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'sted_finland',
                    deactivated: false,
                    label: 'Finland',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_helsinki',
                        deactivated: false,
                        label: 'Helsinki',
                        alternativeLabels: ['Helsingfors'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'sted_island',
                    deactivated: false,
                    label: 'Island',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_reykjavik',
                        deactivated: false,
                        label: 'Reykjavik',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'sted_norge',
                    deactivated: false,
                    label: 'Norge',
                    alternativeLabels: ['Noreg'],

                    related: [],
                    children: [
                      {
                        id: 'sted_nord-norge',
                        deactivated: false,
                        label: 'Nord-Norge',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'sted_nordland',
                            deactivated: false,
                            label: 'Nordland',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'sted_alstahaug',
                                deactivated: false,
                                label: 'Alstahaug',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_andoey',
                                deactivated: false,
                                label: 'Andøy',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_beiarn',
                                deactivated: false,
                                label: 'Beiarn',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_bindal',
                                deactivated: false,
                                label: 'Bindal',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_bodoe',
                                deactivated: false,
                                label: 'Bodø',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_boe(nordland)',
                                deactivated: false,
                                label: 'Bø (Nordland)',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_broennoey',
                                deactivated: false,
                                label: 'Brønnøy',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_doenna',
                                deactivated: false,
                                label: 'Dønna',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_evenes',
                                deactivated: false,
                                label: 'Evenes',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_fauske',
                                deactivated: false,
                                label: 'Fauske',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_flakstad',
                                deactivated: false,
                                label: 'Flakstad',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_gildeskaal',
                                deactivated: false,
                                label: 'Gildeskål',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_grane',
                                deactivated: false,
                                label: 'Grane',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_hadsel',
                                deactivated: false,
                                label: 'Hadsel',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_hamaroey',
                                deactivated: false,
                                label: 'Hamarøy',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020, sammenslåing av Hamarøy og deler av Tysfjord til nye Hamarøy kommune',
                                related: [
                                  {
                                    id: 'sted_tysfjord',
                                    deactivated: false,
                                    label: 'Tysfjord',
                                    note: 'Fra 1.1.2020 deles Tysfjord i to deler som tas opp i hhv. Narvik og Hamarøy kommuner',
                                  },
                                ],
                                children: [],
                              },
                              {
                                id: 'sted_hattfjelldal',
                                deactivated: false,
                                label: 'Hattfjelldal',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_hemnes',
                                deactivated: false,
                                label: 'Hemnes',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_heroey(nordland)',
                                deactivated: false,
                                label: 'Herøy (Nordland)',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_leirfjord',
                                deactivated: false,
                                label: 'Leirfjord',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_loedingen',
                                deactivated: false,
                                label: 'Lødingen',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_lofoten',
                                deactivated: false,
                                label: 'Lofoten',
                                alternativeLabels: [],
                                note: 'Distriktet består av kommunene Vågan, Vestvågøy, Flakstad, Moskenes, Værøy og Røst, samt de deler av Hadsel',
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_luroey',
                                deactivated: false,
                                label: 'Lurøy',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_meloey',
                                deactivated: false,
                                label: 'Meløy',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_moskenes',
                                deactivated: false,
                                label: 'Moskenes',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_narvik',
                                deactivated: false,
                                label: 'Narvik',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020, sammenslåing av Narvik, Ballangen og Tysfjord til nye Narvik kommune',
                                related: [
                                  {
                                    id: 'sted_tysfjord',
                                    deactivated: false,
                                    label: 'Tysfjord',
                                    note: 'Fra 1.1.2020 deles Tysfjord i to deler som tas opp i hhv. Narvik og Hamarøy kommuner',
                                  },
                                ],
                                children: [
                                  {
                                    id: 'sted_ballangen',
                                    deactivated: false,
                                    label: 'Ballangen',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 slått sammen med Narvik og deler av Tysfjord til nye Narvik kommune',
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_nesna',
                                deactivated: false,
                                label: 'Nesna',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_oeksnes',
                                deactivated: false,
                                label: 'Øksnes',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_rana',
                                deactivated: false,
                                label: 'Rana',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_roedoey',
                                deactivated: false,
                                label: 'Rødøy',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_roest',
                                deactivated: false,
                                label: 'Røst',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_saltdal',
                                deactivated: false,
                                label: 'Saltdal',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_soemna',
                                deactivated: false,
                                label: 'Sømna',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_soerfold',
                                deactivated: false,
                                label: 'Sørfold',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_sortland',
                                deactivated: false,
                                label: 'Sortland',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_steigen',
                                deactivated: false,
                                label: 'Steigen',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_traena',
                                deactivated: false,
                                label: 'Træna',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_tysfjord',
                                deactivated: false,
                                label: 'Tysfjord',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020 deles Tysfjord i to deler som tas opp i hhv. Narvik og Hamarøy kommuner',
                                related: [
                                  {
                                    id: 'sted_hamaroey',
                                    deactivated: false,
                                    label: 'Hamarøy',
                                    note: 'Fra 1.1.2020, sammenslåing av Hamarøy og deler av Tysfjord til nye Hamarøy kommune',
                                  },
                                  {
                                    id: 'sted_narvik',
                                    deactivated: false,
                                    label: 'Narvik',
                                    note: 'Fra 1.1.2020, sammenslåing av Narvik, Ballangen og Tysfjord til nye Narvik kommune',
                                  },
                                ],
                                children: [],
                              },
                              {
                                id: 'sted_vaeroey',
                                deactivated: false,
                                label: 'Værøy',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_vaagan',
                                deactivated: false,
                                label: 'Vågan',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_vefsn',
                                deactivated: false,
                                label: 'Vefsn',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_vega',
                                deactivated: false,
                                label: 'Vega',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_vesteraalen',
                                deactivated: false,
                                label: 'Vesterålen',
                                alternativeLabels: [],
                                note: 'Distriktet består av består av kommunene Andøy, Bø, Hadsel, Sortland og Øksnes',
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_vestvaagoey',
                                deactivated: false,
                                label: 'Vestvågøy',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_vevelstad',
                                deactivated: false,
                                label: 'Vevelstad',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'sted_tromsogfinnmark',
                            deactivated: false,
                            label: 'Troms og Finnmark',
                            alternativeLabels: [],
                            note: 'Fra 1.1.2020 til 31.12.2023, sammenslåing av Troms og Finnmark til Troms og Finnmark fylke. Fra 1.1.2024 gjenopprettet som egne fylker',
                            related: [],
                            children: [
                              {
                                id: 'sted_finnmark',
                                deactivated: false,
                                label: 'Finnmark',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020, slått sammen med Troms til Troms og Finnmark fylke. Fra 1.1.2024 gjenopprettet som eget fylke',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_alta',
                                    deactivated: false,
                                    label: 'Alta',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_baatsfjord',
                                    deactivated: false,
                                    label: 'Båtsfjord',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_berlevaag',
                                    deactivated: false,
                                    label: 'Berlevåg',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_gamvik',
                                    deactivated: false,
                                    label: 'Gamvik',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_hammerfest',
                                    deactivated: false,
                                    label: 'Hammerfest',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020, sammenslåing av Hammerfest og Kvalsund til nye Hammerfest kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_kvalsund',
                                        deactivated: false,
                                        label: 'Kvalsund',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020, slått sammen med Hammerfest til nye Hammerfest kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_hasvik',
                                    deactivated: false,
                                    label: 'Hasvik',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_karasjok',
                                    deactivated: false,
                                    label: 'Karasjok',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_kautokeino',
                                    deactivated: false,
                                    label: 'Kautokeino',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_lebesby',
                                    deactivated: false,
                                    label: 'Lebesby',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_loppa',
                                    deactivated: false,
                                    label: 'Loppa',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_maasoey',
                                    deactivated: false,
                                    label: 'Måsøy',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_nesseby',
                                    deactivated: false,
                                    label: 'Nesseby',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_nordkapp',
                                    deactivated: false,
                                    label: 'Nordkapp',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_honningsvaag',
                                        deactivated: false,
                                        label: 'Honningsvåg',
                                        alternativeLabels: [],
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_porsanger',
                                    deactivated: false,
                                    label: 'Porsanger',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_soer-varanger',
                                    deactivated: false,
                                    label: 'Sør-Varanger',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_kirkenes',
                                        deactivated: false,
                                        label: 'Kirkenes',
                                        alternativeLabels: [],
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_tana',
                                    deactivated: false,
                                    label: 'Tana',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vadsoe',
                                    deactivated: false,
                                    label: 'Vadsø',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vardoe',
                                    deactivated: false,
                                    label: 'Vardø',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_troms',
                                deactivated: false,
                                label: 'Troms',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020, slått sammen med Finnmark til Troms og Finnmark fylke. Fra 1.1.2024 gjenopprettet som eget fylke',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_balsfjord',
                                    deactivated: false,
                                    label: 'Balsfjord',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_bardu',
                                    deactivated: false,
                                    label: 'Bardu',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_dyroey',
                                    deactivated: false,
                                    label: 'Dyrøy',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_gratangen',
                                    deactivated: false,
                                    label: 'Gratangen',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_harstad',
                                    deactivated: false,
                                    label: 'Harstad',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_ibestad',
                                    deactivated: false,
                                    label: 'Ibestad',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_kaafjord',
                                    deactivated: false,
                                    label: 'Kåfjord',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_karlsoey',
                                    deactivated: false,
                                    label: 'Karlsøy',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_kvaefjord',
                                    deactivated: false,
                                    label: 'Kvæfjord',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_kvaenangen',
                                    deactivated: false,
                                    label: 'Kvænangen',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_lavangen',
                                    deactivated: false,
                                    label: 'Lavangen',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_lyngen',
                                    deactivated: false,
                                    label: 'Lyngen',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_maalselv',
                                    deactivated: false,
                                    label: 'Målselv',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_nordreisa',
                                    deactivated: false,
                                    label: 'Nordreisa',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_salangen',
                                    deactivated: false,
                                    label: 'Salangen',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_senja',
                                    deactivated: false,
                                    label: 'Senja',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020, sammenslåing av Berg, Lenvik, Torsken og Tranøy til nye Senja kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_berg',
                                        deactivated: false,
                                        label: 'Berg',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020, slått sammen med Lenvik, Torsken og Tranøy til nye Senja kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_lenvik',
                                        deactivated: false,
                                        label: 'Lenvik',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020, slått sammen med Berg, Torsken og Tranøy til nye Senja kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_torsken',
                                        deactivated: false,
                                        label: 'Torsken',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020, slått sammen med Lenvik, Berg og Tranøy til nye Senja kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_tranoey',
                                        deactivated: false,
                                        label: 'Tranøy',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020, slått sammen med Lenvik, Torsken og Berg til nye Senja kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_skjervoey',
                                    deactivated: false,
                                    label: 'Skjervøy',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_soerreisa',
                                    deactivated: false,
                                    label: 'Sørreisa',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_storfjord',
                                    deactivated: false,
                                    label: 'Storfjord',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_tjeldsund',
                                    deactivated: false,
                                    label: 'Tjeldsund',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020, sammenslåing av Tjeldsund og Skånland til nye Tjeldsund kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_skaanland',
                                        deactivated: false,
                                        label: 'Skånland',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Tjeldsund til nye Tjeldsund kommune, og overført fra Troms til Nordland fylke',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_tromsoe',
                                    deactivated: false,
                                    label: 'Tromsø',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'sted_oestlandet',
                        deactivated: false,
                        label: 'Østlandet',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'sted_innlandet',
                            deactivated: false,
                            label: 'Innlandet',
                            alternativeLabels: [],
                            note: 'Fra 1.1.2020, sammenslåing av Hedmark og Oppland til Innlandet fylke',
                            related: [],
                            children: [
                              {
                                id: 'sted_hedmark',
                                deactivated: false,
                                label: 'Hedmark',
                                alternativeLabels: [],
                                related: [],
                                children: [
                                  {
                                    id: 'sted_alvdal',
                                    deactivated: false,
                                    label: 'Alvdal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_aamot',
                                    deactivated: false,
                                    label: 'Åmot',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_aasnes',
                                    deactivated: false,
                                    label: 'Åsnes',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_eidskog',
                                    deactivated: false,
                                    label: 'Eidskog',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_elverum',
                                    deactivated: false,
                                    label: 'Elverum',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_engerdal',
                                    deactivated: false,
                                    label: 'Engerdal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_folldal',
                                    deactivated: false,
                                    label: 'Folldal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_grue',
                                    deactivated: false,
                                    label: 'Grue',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_hamar',
                                    deactivated: false,
                                    label: 'Hamar',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_kongsvinger',
                                    deactivated: false,
                                    label: 'Kongsvinger',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_loeten',
                                    deactivated: false,
                                    label: 'Løten',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_nord-odal',
                                    deactivated: false,
                                    label: 'Nord-Odal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_os(hedmark)',
                                    deactivated: false,
                                    label: 'Os (Hedmark)',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_rendalen',
                                    deactivated: false,
                                    label: 'Rendalen',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_ringsaker',
                                    deactivated: false,
                                    label: 'Ringsaker',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_soer-odal',
                                    deactivated: false,
                                    label: 'Sør-Odal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_stange',
                                    deactivated: false,
                                    label: 'Stange',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_stor-elvdal',
                                    deactivated: false,
                                    label: 'Stor-Elvdal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_tolga',
                                    deactivated: false,
                                    label: 'Tolga',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_trysil',
                                    deactivated: false,
                                    label: 'Trysil',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_tynset',
                                    deactivated: false,
                                    label: 'Tynset',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vaaler(hedmark)',
                                    deactivated: false,
                                    label: 'Våler (Hedmark)',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_oppland',
                                deactivated: false,
                                label: 'Oppland',
                                alternativeLabels: [],
                                related: [],
                                children: [
                                  {
                                    id: 'sted_dovre',
                                    deactivated: false,
                                    label: 'Dovre',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_etnedal',
                                    deactivated: false,
                                    label: 'Etnedal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_gausdal',
                                    deactivated: false,
                                    label: 'Gausdal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_gjoevik',
                                    deactivated: false,
                                    label: 'Gjøvik',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_gran',
                                    deactivated: false,
                                    label: 'Gran',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_jevnaker',
                                    deactivated: false,
                                    label: 'Jevnaker',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 til 31.12.2023, sammenslåing av Akershus, Buskerud og Østfold til Viken fylke. Fra 1.1.2024 gjenopprettet som egne fylke',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_lesja',
                                    deactivated: false,
                                    label: 'Lesja',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_lillehammer',
                                    deactivated: false,
                                    label: 'Lillehammer',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_lom',
                                    deactivated: false,
                                    label: 'Lom',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_nord-aurdal',
                                    deactivated: false,
                                    label: 'Nord-Aurdal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_nord-fron',
                                    deactivated: false,
                                    label: 'Nord-Fron',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_nordreland',
                                    deactivated: false,
                                    label: 'Nordre Land',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_oestretoten',
                                    deactivated: false,
                                    label: 'Østre Toten',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_oeyer',
                                    deactivated: false,
                                    label: 'Øyer',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_oeystreslidre',
                                    deactivated: false,
                                    label: 'Øystre Slidre',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_ringebu',
                                    deactivated: false,
                                    label: 'Ringebu',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_sel',
                                    deactivated: false,
                                    label: 'Sel',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_sjaak',
                                    deactivated: false,
                                    label: 'Sjåk',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_soendreland',
                                    deactivated: false,
                                    label: 'Søndre Land',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_soer-aurdal',
                                    deactivated: false,
                                    label: 'Sør-Aurdal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_soer-fron',
                                    deactivated: false,
                                    label: 'Sør-Fron',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vaagaa',
                                    deactivated: false,
                                    label: 'Vågå',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_valdres',
                                    deactivated: false,
                                    label: 'Valdres',
                                    alternativeLabels: [],
                                    note: 'her: dalføre i Oppland fylke',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vang',
                                    deactivated: false,
                                    label: 'Vang',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vestreslidre',
                                    deactivated: false,
                                    label: 'Vestre Slidre',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vestretoten',
                                    deactivated: false,
                                    label: 'Vestre Toten',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: 'sted_oslo',
                            deactivated: false,
                            label: 'Oslo',
                            alternativeLabels: ['Christiania', 'Kristiania'],

                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_oslofjorden',
                            deactivated: false,
                            label: 'Oslofjorden',
                            alternativeLabels: [],
                            related: [],
                            children: [],
                          },
                          {
                            id: 'sted_vestfoldogtelemark',
                            deactivated: false,
                            label: 'Vestfold og Telemark',
                            alternativeLabels: [],
                            note: 'Fra 1.1.2020 til 31.12.2023, sammenslåing av Telemark og Vestfold til Vestfold og Telemark fylke. Fra 1.1.2024 gjenopprettet som egne fylker',
                            related: [],
                            children: [
                              {
                                id: 'sted_telemark',
                                deactivated: false,
                                label: 'Telemark',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020 til 31.12.2023, sammenslåing av Telemark og Vestfold til Vestfold og Telemark fylke. Fra 1.1.2024 gjenopprettet som eget fylke',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_bamble',
                                    deactivated: false,
                                    label: 'Bamble',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_drangedal',
                                    deactivated: false,
                                    label: 'Drangedal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_fyresdal',
                                    deactivated: false,
                                    label: 'Fyresdal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_hjartdal',
                                    deactivated: false,
                                    label: 'Hjartdal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_krageroe',
                                    deactivated: false,
                                    label: 'Kragerø',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_kviteseid',
                                    deactivated: false,
                                    label: 'Kviteseid',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_midt-telemark',
                                    deactivated: false,
                                    label: 'Midt-Telemark',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 sammenslåing av Bø og Sauherad til Midt-Telemark kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_boe(telemark)',
                                        deactivated: false,
                                        label: 'Bø (Telemark)',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Sauherad til Midt-Telemark kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_sauherad',
                                        deactivated: false,
                                        label: 'Sauherad',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Bø til Midt-Telemark kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_nissedal',
                                    deactivated: false,
                                    label: 'Nissedal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_nome',
                                    deactivated: false,
                                    label: 'Nome',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_notodden',
                                    deactivated: false,
                                    label: 'Notodden',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_porsgrunn',
                                    deactivated: false,
                                    label: 'Porsgrunn',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_seljord',
                                    deactivated: false,
                                    label: 'Seljord',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_siljan',
                                    deactivated: false,
                                    label: 'Siljan',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_skien',
                                    deactivated: false,
                                    label: 'Skien',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_tinn',
                                    deactivated: false,
                                    label: 'Tinn',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_tokke',
                                    deactivated: false,
                                    label: 'Tokke',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vinje',
                                    deactivated: false,
                                    label: 'Vinje',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_vestfold',
                                deactivated: false,
                                label: 'Vestfold',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020 til 31.12.2023, sammenslåing av Telemark og Vestfold til Vestfold og Telemark fylke. Fra 1.1.2024 gjenopprettet som eget fylke',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_faerder',
                                    deactivated: false,
                                    label: 'Færder',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2018, sammenslåing av Tjøme og Nøtterøy til Færder kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_noetteroey',
                                        deactivated: false,
                                        label: 'Nøtterøy',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2018 slått sammen med Tjøme til Færder kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_tjoeme',
                                        deactivated: false,
                                        label: 'Tjøme',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2018 slått sammen med Nøtterøy til Færder kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_holmestrand',
                                    deactivated: false,
                                    label: 'Holmestrand',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2018 slått sammen med Hof, og fra 1.1.2020 slått sammen med Sande til nye Holmestrand kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_hof',
                                        deactivated: false,
                                        label: 'Hof',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2018 slått sammen med Holmestrand til nye Holmestrand kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_sande(vestfold)',
                                        deactivated: false,
                                        label: 'Sande (Vestfold)',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Holmestrand til nye Holmestrand kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_horten',
                                    deactivated: false,
                                    label: 'Horten',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_larvik',
                                    deactivated: false,
                                    label: 'Larvik',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2018 slått sammen med Lardal til nye Larvik kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_lardal',
                                        deactivated: false,
                                        label: 'Lardal',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2018 slått sammen med Larvik til Larvik kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_sandefjord',
                                    deactivated: false,
                                    label: 'Sandefjord',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2017 slått sammen med Stokke og Andebu til nye Sandefjord kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_andebu',
                                        deactivated: false,
                                        label: 'Andebu',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2017 slått sammen med Stokke og Sandefjord til Sandefjord kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_stokke',
                                        deactivated: false,
                                        label: 'Stokke',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2017 slått sammen med Andebu og Sandefjord til Sandefjord kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_toensberg',
                                    deactivated: false,
                                    label: 'Tønsberg',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 sammenslåing av Tønsberg og Re til nye Tønsberg kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_re',
                                        deactivated: false,
                                        label: 'Re',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Tønsberg til nye Tønsberg kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: 'sted_viken',
                            deactivated: false,
                            label: 'Viken',
                            alternativeLabels: [],
                            note: 'Fra 1.1.2020 til 31.12.2023, sammenslåing av Akershus, Buskerud og Østfold til Viken fylke. Fra 1.1.2024 gjenopprettet som egne fylker',
                            related: [],
                            children: [
                              {
                                id: 'sted_akershus',
                                deactivated: false,
                                label: 'Akershus',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020 til 31.12.2023, sammenslåing av Akershus, Buskerud og Østfold til Viken fylke. Fra 1.1.2024 gjenopprettet som eget fylke',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_aas',
                                    deactivated: false,
                                    label: 'Ås',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_asker',
                                    deactivated: false,
                                    label: 'Asker',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020, sammenslåing av Asker, Hurum og Røyken til nye Asker kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_hurum',
                                        deactivated: false,
                                        label: 'Hurum',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Asker og Røyken til nye Asker kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_roeyken',
                                        deactivated: false,
                                        label: 'Røyken',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Asker og Hurum til nye Asker kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_aurskog-hoeland',
                                    deactivated: false,
                                    label: 'Aurskog-Høland',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020, sammenslåing av Aurskog-Høland og Rømskog til Aurskog-Høland kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_roemskog',
                                        deactivated: false,
                                        label: 'Rømskog',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020, slått sammen med Aurskog-Høland til Aurskog-Høland kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_baerum',
                                    deactivated: false,
                                    label: 'Bærum',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_eidsvoll',
                                    deactivated: false,
                                    label: 'Eidsvoll',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_enebakk',
                                    deactivated: false,
                                    label: 'Enebakk',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_frogn',
                                    deactivated: false,
                                    label: 'Frogn',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_gjerdrum',
                                    deactivated: false,
                                    label: 'Gjerdrum',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_hurdal',
                                    deactivated: false,
                                    label: 'Hurdal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_lillestroemkommune',
                                    deactivated: false,
                                    label: 'Lillestrøm kommune',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020, sammenslåing av Skedsmo, Fet og Sørum til Lillestrøm kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_fet',
                                        deactivated: false,
                                        label: 'Fet',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Skedsmo og Sørum til Lillestrøm kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_skedsmo',
                                        deactivated: false,
                                        label: 'Skedsmo',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Fet og Sørum til Lillestrøm kommune',
                                        related: [],
                                        children: [
                                          {
                                            id: 'sted_lillestroem',
                                            deactivated: false,
                                            label: 'Lillestrøm',
                                            alternativeLabels: [],
                                            note: 'Her: byen Lillestrøm',
                                            related: [],
                                            children: [],
                                          },
                                        ],
                                      },
                                      {
                                        id: 'sted_soerum',
                                        deactivated: false,
                                        label: 'Sørum',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Skedsmo og Fet til Lillestrøm kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_loerenskog',
                                    deactivated: false,
                                    label: 'Lørenskog',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_lunner',
                                    deactivated: false,
                                    label: 'Lunner',
                                    alternativeLabels: [],
                                    note: 'Fram til 1.1.2020 Oppland fylke. Fra 2020 til 2023 i Viken. Fra 1.1.2024 Akershus',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_nannestad',
                                    deactivated: false,
                                    label: 'Nannestad',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_nes(akershus)',
                                    deactivated: false,
                                    label: 'Nes (Akershus)',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_nesodden',
                                    deactivated: false,
                                    label: 'Nesodden',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_nittedal',
                                    deactivated: false,
                                    label: 'Nittedal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_nordrefollo',
                                    deactivated: false,
                                    label: 'Nordre Follo',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020, sammenslåing av Oppegård og Ski til Nordre Follo kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_oppegaard',
                                        deactivated: false,
                                        label: 'Oppegård',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Ski til Nordre Follo kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_ski',
                                        deactivated: false,
                                        label: 'Ski',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020, slått sammen med Oppegård til Nordre Follo kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_raelingen',
                                    deactivated: false,
                                    label: 'Rælingen',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_ullensaker',
                                    deactivated: false,
                                    label: 'Ullensaker',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vestby',
                                    deactivated: false,
                                    label: 'Vestby',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_buskerud',
                                deactivated: false,
                                label: 'Buskerud',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020 til 31.12.2023, sammenslåing av Akershus, Buskerud og Østfold til Viken fylke. Fra 1.1.2024 gjenopprettet som eget fylke',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_aal',
                                    deactivated: false,
                                    label: 'Ål',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_drammen',
                                    deactivated: false,
                                    label: 'Drammen',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020, sammenslåing av Drammen, Nedre Eiker og Svelvik til nye Drammen kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_nedreeiker',
                                        deactivated: false,
                                        label: 'Nedre Eiker',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Drammen og Svelvik til nye Drammen kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_svelvik',
                                        deactivated: false,
                                        label: 'Svelvik',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Drammen og Nedre Eiker til nye Drammen kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_flaa',
                                    deactivated: false,
                                    label: 'Flå',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_flesberg',
                                    deactivated: false,
                                    label: 'Flesberg',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_gol',
                                    deactivated: false,
                                    label: 'Gol',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_hemsedal',
                                    deactivated: false,
                                    label: 'Hemsedal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_hol',
                                    deactivated: false,
                                    label: 'Hol',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_hole',
                                    deactivated: false,
                                    label: 'Hole',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_utoeya',
                                        deactivated: false,
                                        label: 'Utøya',
                                        alternativeLabels: [],
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_kongsberg',
                                    deactivated: false,
                                    label: 'Kongsberg',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_kroedsherad',
                                    deactivated: false,
                                    label: 'Krødsherad',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_lier',
                                    deactivated: false,
                                    label: 'Lier',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_modum',
                                    deactivated: false,
                                    label: 'Modum',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_nes(buskerud)',
                                    deactivated: false,
                                    label: 'Nes (Buskerud)',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_nesbyen',
                                    deactivated: false,
                                    label: 'Nesbyen',
                                    alternativeLabels: [],
                                    note: 'Tidligere Nes kommune',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_noreoguvdal',
                                    deactivated: false,
                                    label: 'Nore og Uvdal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_oevreeiker',
                                    deactivated: false,
                                    label: 'Øvre Eiker',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_ringerike',
                                    deactivated: false,
                                    label: 'Ringerike',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_hoenefoss',
                                        deactivated: false,
                                        label: 'Hønefoss',
                                        alternativeLabels: [],
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_rollag',
                                    deactivated: false,
                                    label: 'Rollag',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_sigdal',
                                    deactivated: false,
                                    label: 'Sigdal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_oestfold',
                                deactivated: false,
                                label: 'Østfold',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020 til 31.12.2023, sammenslåing av Akershus, Buskerud og Østfold til Viken fylke. Fra 1.1.2024 gjenopprettet som egne fylke',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_aremark',
                                    deactivated: false,
                                    label: 'Aremark',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_fredrikstad',
                                    deactivated: false,
                                    label: 'Fredrikstad',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_halden',
                                    deactivated: false,
                                    label: 'Halden',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_hvaler',
                                    deactivated: false,
                                    label: 'Hvaler',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_indreoestfold',
                                    deactivated: false,
                                    label: 'Indre Østfold',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020, sammenslåing av Askim, Eidsberg, Hobøl, Spydeberg og Trøgstad til Indre Østfold kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_askim',
                                        deactivated: false,
                                        label: 'Askim',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Eidsberg, Hobøl, Spydeberg og Trøgstad til Indre Østfold kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_eidsberg',
                                        deactivated: false,
                                        label: 'Eidsberg',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Askim, Hobøl, Spydeberg og Trøgstad til Indre Østfold kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_hoboel',
                                        deactivated: false,
                                        label: 'Hobøl',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Askim, Eidsberg, Spydeberg og Trøgstad til Indre Østfold kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_spydeberg',
                                        deactivated: false,
                                        label: 'Spydeberg',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Askim, Eidsberg, Hobøl og Trøgstad til Indre Østfold kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_troegstad',
                                        deactivated: false,
                                        label: 'Trøgstad',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Askim, Eidsberg, Hobøl og Spydeberg til Indre Østfold kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_marker',
                                    deactivated: false,
                                    label: 'Marker',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_moss',
                                    deactivated: false,
                                    label: 'Moss',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020, sammenslåing av Moss og Rygge til nye Moss kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_rygge',
                                        deactivated: false,
                                        label: 'Rygge',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020, slått sammen med Moss til nye Moss kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_raade',
                                    deactivated: false,
                                    label: 'Råde',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_rakkestad',
                                    deactivated: false,
                                    label: 'Rakkestad',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_sarpsborg',
                                    deactivated: false,
                                    label: 'Sarpsborg',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_skiptvet',
                                    deactivated: false,
                                    label: 'Skiptvet',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vaaler(oestfold)',
                                    deactivated: false,
                                    label: 'Våler (Østfold)',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'sted_soerlandet',
                        deactivated: false,
                        label: 'Sørlandet',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'sted_agder',
                            deactivated: false,
                            label: 'Agder',
                            alternativeLabels: [],
                            note: 'Fra 1.1.2020, sammenslåing av Aust-Agder og Vest-Agder til Agder fylke',
                            related: [],
                            children: [
                              {
                                id: 'sted_aust-agder',
                                deactivated: false,
                                label: 'Aust-Agder',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020, slått sammen med Vest-Agder til Agder fylke',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_aamli',
                                    deactivated: false,
                                    label: 'Åmli',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_arendal',
                                    deactivated: false,
                                    label: 'Arendal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_birkenes',
                                    deactivated: false,
                                    label: 'Birkenes',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_bygland',
                                    deactivated: false,
                                    label: 'Bygland',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_bykle',
                                    deactivated: false,
                                    label: 'Bykle',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_evjeoghornnes',
                                    deactivated: false,
                                    label: 'Evje og Hornnes',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_froland',
                                    deactivated: false,
                                    label: 'Froland',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_gjerstad',
                                    deactivated: false,
                                    label: 'Gjerstad',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_grimstad',
                                    deactivated: false,
                                    label: 'Grimstad',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_iveland',
                                    deactivated: false,
                                    label: 'Iveland',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_lillesand',
                                    deactivated: false,
                                    label: 'Lillesand',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_risoer',
                                    deactivated: false,
                                    label: 'Risør',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_tvedestrand',
                                    deactivated: false,
                                    label: 'Tvedestrand',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_valle',
                                    deactivated: false,
                                    label: 'Valle',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vegaarshei',
                                    deactivated: false,
                                    label: 'Vegårshei',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_vest-agder',
                                deactivated: false,
                                label: 'Vest-Agder',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020, slått sammen med Aust-Agder til Agder fylke',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_aaseral',
                                    deactivated: false,
                                    label: 'Åseral',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_farsund',
                                    deactivated: false,
                                    label: 'Farsund',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_flekkefjord',
                                    deactivated: false,
                                    label: 'Flekkefjord',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_haegebostad',
                                    deactivated: false,
                                    label: 'Hægebostad',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_kristiansand',
                                    deactivated: false,
                                    label: 'Kristiansand',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020, sammenslåing av Kristiansand, Songdalen og Søgne til nye Kristiansand kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_soegne',
                                        deactivated: false,
                                        label: 'Søgne',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020, slått sammen med Kristiansand og Songdalen til nye Kristiansand kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_songdalen',
                                        deactivated: false,
                                        label: 'Songdalen',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Kristiansand og Søgne til nye Kristiansand kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_kvinesdal',
                                    deactivated: false,
                                    label: 'Kvinesdal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_lindesnes',
                                    deactivated: false,
                                    label: 'Lindesnes',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020, sammenslåing av Lindesnes, Mandal og Marnadal til nye Lindesnes kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_mandal',
                                        deactivated: false,
                                        label: 'Mandal',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Lindesnes og Marnadal til nye Lindesnes kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_marnadal',
                                        deactivated: false,
                                        label: 'Marnadal',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Lindesnes og Mandal til nye Lindesnes kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_lyngdal',
                                    deactivated: false,
                                    label: 'Lyngdal',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020, sammenslåing av Audnedal og Lyngdal til nye Lyngdal kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_audnedal',
                                        deactivated: false,
                                        label: 'Audnedal',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Lyngdal til nye Lyngdal kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_sirdal',
                                    deactivated: false,
                                    label: 'Sirdal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vennesla',
                                    deactivated: false,
                                    label: 'Vennesla',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'sted_troendelag',
                        deactivated: false,
                        label: 'Trøndelag',
                        alternativeLabels: [],
                        note: 'Fra 1.1.2018, sammenslåing av Sør- og Nord-Trøndelag til Trøndelag fylke',
                        related: [],
                        children: [
                          {
                            id: 'sted_heim',
                            deactivated: false,
                            label: 'Heim',
                            alternativeLabels: [],
                            note: 'Fra 1.1.2020, sammenslåing av Hemne, Halsa og deler av Snillfjord til nye Heim kommune, samt flytting av Halsa fra Møre og Romsdal til Trøndelag',
                            related: [
                              {
                                id: 'sted_snillfjord',
                                deactivated: false,
                                label: 'Snillfjord',
                                note: 'Fra 1.1.2020 deles Snillfjord i tre og tas opp i hhv. Heim, Hitra og Orkland kommuner',
                              },
                            ],
                            children: [
                              {
                                id: 'sted_halsa',
                                deactivated: false,
                                label: 'Halsa',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020 slått sammen med Hemne og deler av Snillfjord til nye Heim kommune',
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_hemne',
                                deactivated: false,
                                label: 'Hemne',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020 slått sammen med Halsa og deler av Snillfjord til nye Heim kommune',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'sted_indrefosen',
                            deactivated: false,
                            label: 'Indre Fosen',
                            alternativeLabels: [],
                            note: 'Fra 1.1.2018, sammenslåing av Rissa og Leksvik til Indre Fosen kommune, samt flytting av kommunene fra hhv. Sør- og Nord-Trøndelag til Trøndelag fylke',
                            related: [],
                            children: [
                              {
                                id: 'sted_leksvik',
                                deactivated: false,
                                label: 'Leksvik',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2018 slått sammen med Rissa til Indre Fosen kommune',
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_rissa',
                                deactivated: false,
                                label: 'Rissa',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2018 slått sammen med Leksvik til Indre Fosen kommune',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'sted_nord-troendelag',
                            deactivated: false,
                            label: 'Nord-Trøndelag',
                            alternativeLabels: [],
                            note: 'Fra 1.1.2018 slått sammen med Sør-Trøndelag til Trøndelag fylke',
                            related: [],
                            children: [
                              {
                                id: 'sted_flatanger',
                                deactivated: false,
                                label: 'Flatanger',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_frosta',
                                deactivated: false,
                                label: 'Frosta',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_grong',
                                deactivated: false,
                                label: 'Grong',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_hoeylandet',
                                deactivated: false,
                                label: 'Høylandet',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_inderoey',
                                deactivated: false,
                                label: 'Inderøy',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_leka',
                                deactivated: false,
                                label: 'Leka',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_levanger',
                                deactivated: false,
                                label: 'Levanger',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_lierne',
                                deactivated: false,
                                label: 'Lierne',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_meraaker',
                                deactivated: false,
                                label: 'Meråker',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_naeroeysund',
                                deactivated: false,
                                label: 'Nærøysund',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020, sammenslåing av Nærøy og Vikna til nye Nærøysund kommune',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_naeroey',
                                    deactivated: false,
                                    label: 'Nærøy',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 slått sammen med Vikna til nye Nærøysund kommune',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vikna',
                                    deactivated: false,
                                    label: 'Vikna',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 slått sammen med Nærøy til nye Nærøysund kommune',
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_namsos',
                                deactivated: false,
                                label: 'Namsos',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020, sammenslåing av Namsos, Namdalseid og Fosnes til nye Namsos kommune',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_fosnes',
                                    deactivated: false,
                                    label: 'Fosnes',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 slått sammen med Namsos og Namdalseid til nye Namsos kommune',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_namdalseid',
                                    deactivated: false,
                                    label: 'Namdalseid',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 slått sammen med Namsos og Fosnes til nye Namsos kommune',
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_namsskogan',
                                deactivated: false,
                                label: 'Namsskogan',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_overhalla',
                                deactivated: false,
                                label: 'Overhalla',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_roeyrvik',
                                deactivated: false,
                                label: 'Røyrvik',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_snaasa',
                                deactivated: false,
                                label: 'Snåsa',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_steinkjer',
                                deactivated: false,
                                label: 'Steinkjer',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020, sammenslåing av Steinkjer og Verran til nye Steinkjer kommune',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_verran',
                                    deactivated: false,
                                    label: 'Verran',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 slått sammen med Steinkjer til nye Steinkjer kommune',
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_stjoerdal',
                                deactivated: false,
                                label: 'Stjørdal',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_verdal',
                                deactivated: false,
                                label: 'Verdal',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'sted_orkland',
                            deactivated: false,
                            label: 'Orkland',
                            alternativeLabels: [],
                            note: 'Fra 1.1.2020 sammenslåing av Orkdal, Agdenes, Meldal og deler av Snillfjord til nye Orkland kommune, og flytting av kommune til nye Trøndelag fylke',
                            related: [
                              {
                                id: 'sted_snillfjord',
                                deactivated: false,
                                label: 'Snillfjord',
                                note: 'Fra 1.1.2020 deles Snillfjord i tre og tas opp i hhv. Heim, Hitra og Orkland kommuner',
                              },
                            ],
                            children: [
                              {
                                id: 'sted_agdenes',
                                deactivated: false,
                                label: 'Agdenes',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020 slått sammen med Orkdal, Meldal og deler av Snillfjord til nye Orkland kommune',
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_meldal',
                                deactivated: false,
                                label: 'Meldal',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020 slått sammen med Orkdal, Agdenes og deler av Snillfjord til nye Orkland kommune',
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_orkdal',
                                deactivated: false,
                                label: 'Orkdal',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020 slått sammen med Agdenes, Meldal og deler av Snillfjord til nye Orkland kommune',
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'sted_soer-troendelag',
                            deactivated: false,
                            label: 'Sør-Trøndelag',
                            alternativeLabels: [],
                            note: 'Fra 1.1.2018 slått sammen med Nord-Trøndelag til Trøndelag fylke',
                            related: [],
                            children: [
                              {
                                id: 'sted_aafjord',
                                deactivated: false,
                                label: 'Åfjord',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020, sammenslåing av Åfjord og Roan til nye Åfjord kommune',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_roan',
                                    deactivated: false,
                                    label: 'Roan',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 slått sammen med Åfjord til nye Åfjord kommune',
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_froeya',
                                deactivated: false,
                                label: 'Frøya',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_hitra',
                                deactivated: false,
                                label: 'Hitra',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020 slått sammen med deler av Snillfjord til nye Hitra kommune',
                                related: [
                                  {
                                    id: 'sted_snillfjord',
                                    deactivated: false,
                                    label: 'Snillfjord',
                                    note: 'Fra 1.1.2020 deles Snillfjord i tre og tas opp i hhv. Heim, Hitra og Orkland kommuner',
                                  },
                                ],
                                children: [],
                              },
                              {
                                id: 'sted_holtaalen',
                                deactivated: false,
                                label: 'Holtålen',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_malvik',
                                deactivated: false,
                                label: 'Malvik',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_melhus',
                                deactivated: false,
                                label: 'Melhus',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_midtregauldal',
                                deactivated: false,
                                label: 'Midtre Gauldal',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_oppdal',
                                deactivated: false,
                                label: 'Oppdal',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_oerland',
                                deactivated: false,
                                label: 'Ørland',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020, sammenslåing av Ørland og Bjugn til nye Ørland kommune',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_bjugn',
                                    deactivated: false,
                                    label: 'Bjugn',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 slått sammen med Ørland til nye Ørland kommune',
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_osen',
                                deactivated: false,
                                label: 'Osen',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_rennebu',
                                deactivated: false,
                                label: 'Rennebu',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_rindal',
                                deactivated: false,
                                label: 'Rindal',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2019 overført til Trøndelag fylke fra Møre og Romsdal',
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_roeros',
                                deactivated: false,
                                label: 'Røros',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_selbu',
                                deactivated: false,
                                label: 'Selbu',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_skaun',
                                deactivated: false,
                                label: 'Skaun',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_snillfjord',
                                deactivated: false,
                                label: 'Snillfjord',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020 deles Snillfjord i tre og tas opp i hhv. Heim, Hitra og Orkland kommuner',
                                related: [
                                  {
                                    id: 'sted_heim',
                                    deactivated: false,
                                    label: 'Heim',
                                    note: 'Fra 1.1.2020, sammenslåing av Hemne, Halsa og deler av Snillfjord til nye Heim kommune, samt flytting av Halsa fra Møre og Romsdal til Trøndelag',
                                  },
                                  {
                                    id: 'sted_hitra',
                                    deactivated: false,
                                    label: 'Hitra',
                                    note: 'Fra 1.1.2020 slått sammen med deler av Snillfjord til nye Hitra kommune',
                                  },
                                  {
                                    id: 'sted_orkland',
                                    deactivated: false,
                                    label: 'Orkland',
                                    note: 'Fra 1.1.2020 sammenslåing av Orkdal, Agdenes, Meldal og deler av Snillfjord til nye Orkland kommune, og flytting av kommune til nye Trøndelag fylke',
                                  },
                                ],
                                children: [],
                              },
                              {
                                id: 'sted_trondheim',
                                deactivated: false,
                                label: 'Trondheim',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020, sammenslåing av Trondheim og Klæbu til nye Trondheim kommune',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_klaebu',
                                    deactivated: false,
                                    label: 'Klæbu',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 slått sammen med Trondheim til nye Trondheim kommune',
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_tydal',
                                deactivated: false,
                                label: 'Tydal',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        id: 'sted_vestlandet',
                        deactivated: false,
                        label: 'Vestlandet',
                        alternativeLabels: [],
                        related: [],
                        children: [
                          {
                            id: 'sted_moereogromsdal',
                            deactivated: false,
                            label: 'Møre og Romsdal',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'sted_haram',
                                deactivated: false,
                                label: 'Haram',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020, slått sammen med Ålesund, Sandøy, Skodje og Ørskog til nye Ålesund kommune. Fra 1.1.2024 gjenopprettet som egen kommune',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_fra1.1.2020,slaattsammenmedaalesund,sandoey,skodjeogoerskogtilnyeaalesundkommune',
                                    deactivated: false,
                                    label:
                                      'Fra 1.1.2020, slått sammen med Ålesund, Sandøy, Skodje og Ørskog til nye Ålesund kommune',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_nordmoere',
                                deactivated: false,
                                label: 'Nordmøre',
                                alternativeLabels: [],
                                related: [],
                                children: [
                                  {
                                    id: 'sted_aure',
                                    deactivated: false,
                                    label: 'Aure',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_averoey',
                                    deactivated: false,
                                    label: 'Averøy',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_gjemnes',
                                    deactivated: false,
                                    label: 'Gjemnes',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_kristiansund',
                                    deactivated: false,
                                    label: 'Kristiansund',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_smoela',
                                    deactivated: false,
                                    label: 'Smøla',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_sunndal',
                                    deactivated: false,
                                    label: 'Sunndal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_surnadal',
                                    deactivated: false,
                                    label: 'Surnadal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_tingvoll',
                                    deactivated: false,
                                    label: 'Tingvoll',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_romsdal',
                                deactivated: false,
                                label: 'Romsdal',
                                alternativeLabels: [],
                                related: [],
                                children: [
                                  {
                                    id: 'sted_aukra',
                                    deactivated: false,
                                    label: 'Aukra',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_hustadvika',
                                    deactivated: false,
                                    label: 'Hustadvika',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020, sammenslåing av Eide og Fræna til Hustadvika kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_eide',
                                        deactivated: false,
                                        label: 'Eide',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020, slått sammen med Fræna til nye Hustadvika kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_fraena',
                                        deactivated: false,
                                        label: 'Fræna',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020, slått sammen med Eide til nye Hustadvika kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_molde',
                                    deactivated: false,
                                    label: 'Molde',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020, sammenslåing av Molde, Nesset og Midsund til nye Molde kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_midsund',
                                        deactivated: false,
                                        label: 'Midsund',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Molde og Nesset til nye Molde kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_nesset',
                                        deactivated: false,
                                        label: 'Nesset',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020, slått sammen med Molde og Midsund til nye Molde kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_rauma',
                                    deactivated: false,
                                    label: 'Rauma',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vestnes',
                                    deactivated: false,
                                    label: 'Vestnes',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_sunnmoere',
                                deactivated: false,
                                label: 'Sunnmøre',
                                alternativeLabels: [],
                                related: [],
                                children: [
                                  {
                                    id: 'sted_aalesund',
                                    deactivated: false,
                                    label: 'Ålesund',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 til 31.12.2023, sammenslåing av Ålesund, Haram, Sandøy, Skodje og Ørskog til nye Ålesund kommune. Fra 1.1.2024 Haram gjenopprettet som egen kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_oerskog',
                                        deactivated: false,
                                        label: 'Ørskog',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020, slått sammen med Ålesund, Sandøy, Skodje og Haram til nye Ålesund kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_sandoey',
                                        deactivated: false,
                                        label: 'Sandøy',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020, slått sammen med Ålesund, Haram, Skodje og Ørskog til nye Ålesund kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_skodje',
                                        deactivated: false,
                                        label: 'Skodje',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020, slått sammen med Ålesund, Sandøy, Haram og Ørskog til nye Ålesund kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_fjord',
                                    deactivated: false,
                                    label: 'Fjord',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020, sammenslåing av Norddal og Stordal til Fjord kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_norddal',
                                        deactivated: false,
                                        label: 'Norddal',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020, slått sammen med Stordal til nye Fjord kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_stordal',
                                        deactivated: false,
                                        label: 'Stordal',
                                        alternativeLabels: [
                                          'Fra 1.1.2020',
                                          ' slått sammen med Norddal til nye Fjord kommune',
                                        ],

                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_giske',
                                    deactivated: false,
                                    label: 'Giske',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_hareid',
                                    deactivated: false,
                                    label: 'Hareid',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_heroey(moereogromsdal)',
                                    deactivated: false,
                                    label: 'Herøy (Møre og Romsdal)',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_oersta',
                                    deactivated: false,
                                    label: 'Ørsta',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_sande(moereogromsdal)',
                                    deactivated: false,
                                    label: 'Sande (Møre og Romsdal)',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_stranda',
                                    deactivated: false,
                                    label: 'Stranda',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_sula',
                                    deactivated: false,
                                    label: 'Sula',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_sykkylven',
                                    deactivated: false,
                                    label: 'Sykkylven',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_ulstein',
                                    deactivated: false,
                                    label: 'Ulstein',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vanylven',
                                    deactivated: false,
                                    label: 'Vanylven',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_volda',
                                    deactivated: false,
                                    label: 'Volda',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 sammenslåing av Volda og Hornindal til nye Volda kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_hornindal',
                                        deactivated: false,
                                        label: 'Hornindal',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Volda til nye Volda kommune, og Hornindal flyttet til Møre og Romsdal fylke',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            id: 'sted_rogaland',
                            deactivated: false,
                            label: 'Rogaland',
                            alternativeLabels: [],
                            related: [],
                            children: [
                              {
                                id: 'sted_bjerkreim',
                                deactivated: false,
                                label: 'Bjerkreim',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_bokn',
                                deactivated: false,
                                label: 'Bokn',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_eigersund',
                                deactivated: false,
                                label: 'Eigersund',
                                alternativeLabels: [],
                                related: [],
                                children: [
                                  {
                                    id: 'sted_egersund',
                                    deactivated: false,
                                    label: 'Egersund',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_gjesdal',
                                deactivated: false,
                                label: 'Gjesdal',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_haa',
                                deactivated: false,
                                label: 'Hå',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_haugesund',
                                deactivated: false,
                                label: 'Haugesund',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_hjelmeland',
                                deactivated: false,
                                label: 'Hjelmeland',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_karmoey',
                                deactivated: false,
                                label: 'Karmøy',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_klepp',
                                deactivated: false,
                                label: 'Klepp',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_kvitsoey',
                                deactivated: false,
                                label: 'Kvitsøy',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_lund',
                                deactivated: false,
                                label: 'Lund',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_randaberg',
                                deactivated: false,
                                label: 'Randaberg',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_sandnes',
                                deactivated: false,
                                label: 'Sandnes',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020 sammenslåing av Sandnes og Forsand til nye Sandnes kommune',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_forsand',
                                    deactivated: false,
                                    label: 'Forsand',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 slått sammen med Sandnes til nye Sandnes kommune',
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_sauda',
                                deactivated: false,
                                label: 'Sauda',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_sokndal',
                                deactivated: false,
                                label: 'Sokndal',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_sola',
                                deactivated: false,
                                label: 'Sola',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_stavanger',
                                deactivated: false,
                                label: 'Stavanger',
                                alternativeLabels: [],
                                note: 'Fra 1.1.2020, sammenslåing av Stavanger, Finnøy og Rennesøy til nye Stavanger kommune',
                                related: [],
                                children: [
                                  {
                                    id: 'sted_finnoey',
                                    deactivated: false,
                                    label: 'Finnøy',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 slått sammen med Stavanger og Rennesøy til nye Stavanger kommune',
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_rennesoey',
                                    deactivated: false,
                                    label: 'Rennesøy',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 slått sammen med Stavanger og Finnøy til nye Stavanger kommune',
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                              {
                                id: 'sted_strand',
                                deactivated: false,
                                label: 'Strand',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_suldal',
                                deactivated: false,
                                label: 'Suldal',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_time',
                                deactivated: false,
                                label: 'Time',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_tysvaer',
                                deactivated: false,
                                label: 'Tysvær',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_utsira',
                                deactivated: false,
                                label: 'Utsira',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                              {
                                id: 'sted_vindafjord',
                                deactivated: false,
                                label: 'Vindafjord',
                                alternativeLabels: [],
                                related: [],
                                children: [],
                              },
                            ],
                          },
                          {
                            id: 'sted_vestland',
                            deactivated: false,
                            label: 'Vestland',
                            alternativeLabels: [],
                            note: 'Fra 1.1.2020, sammenslåing av Hordaland og Sogn og Fjordane til Vestland fylke',
                            related: [],
                            children: [
                              {
                                id: 'sted_hordaland',
                                deactivated: false,
                                label: 'Hordaland',
                                alternativeLabels: [],
                                related: [],
                                children: [
                                  {
                                    id: 'sted_alver',
                                    deactivated: false,
                                    label: 'Alver',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 sammenslåing av Radøy, Lindås og Meland til nye Alver kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_lindaas',
                                        deactivated: false,
                                        label: 'Lindås',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Meland og Radøy til nye Alver kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_meland',
                                        deactivated: false,
                                        label: 'Meland',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Lindås og Radøy til nye Alver kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_radoey',
                                        deactivated: false,
                                        label: 'Radøy',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Lindås og Meland til nye Alver kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_askoey',
                                    deactivated: false,
                                    label: 'Askøy',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_austevoll',
                                    deactivated: false,
                                    label: 'Austevoll',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_austrheim',
                                    deactivated: false,
                                    label: 'Austrheim',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_bergen',
                                    deactivated: false,
                                    label: 'Bergen',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_bjoernafjorden',
                                    deactivated: false,
                                    label: 'Bjørnafjorden',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 sammenslåing av Fusa og Os til Bjørnafjorden kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_fusa',
                                        deactivated: false,
                                        label: 'Fusa',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Os til Bjørnafjorden kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_os(hordaland)',
                                        deactivated: false,
                                        label: 'Os (Hordaland)',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Fusa til Bjørnafjorden kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_boemlo',
                                    deactivated: false,
                                    label: 'Bømlo',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_eidfjord',
                                    deactivated: false,
                                    label: 'Eidfjord',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_etne',
                                    deactivated: false,
                                    label: 'Etne',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_fedje',
                                    deactivated: false,
                                    label: 'Fedje',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_fitjar',
                                    deactivated: false,
                                    label: 'Fitjar',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_kvam',
                                    deactivated: false,
                                    label: 'Kvam',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_kvinnherad',
                                    deactivated: false,
                                    label: 'Kvinnherad',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_masfjorden',
                                    deactivated: false,
                                    label: 'Masfjorden',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_modalen',
                                    deactivated: false,
                                    label: 'Modalen',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_osteroey',
                                    deactivated: false,
                                    label: 'Osterøy',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_oeygarden',
                                    deactivated: false,
                                    label: 'Øygarden',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 sammenslåing av Øygarden, Fjell og Sund til nye Øygarden kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_fjell',
                                        deactivated: false,
                                        label: 'Fjell',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Øygarden og Sund til nye Øygarden kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_sund',
                                        deactivated: false,
                                        label: 'Sund',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Øygarden og Fjell til nye Øygarden kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_samnanger',
                                    deactivated: false,
                                    label: 'Samnanger',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_stord',
                                    deactivated: false,
                                    label: 'Stord',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_sveio',
                                    deactivated: false,
                                    label: 'Sveio',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_tysnes',
                                    deactivated: false,
                                    label: 'Tysnes',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_ullensvang',
                                    deactivated: false,
                                    label: 'Ullensvang',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 sammenslåing av Ullensvang, Jondal og Odda til nye Ullensvang kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_jondal',
                                        deactivated: false,
                                        label: 'Jondal',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Ullensvang og Odda til nye Ullensvang kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_odda',
                                        deactivated: false,
                                        label: 'Odda',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Ullensvang og Jondal til nye Ullensvang kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_ulvik',
                                    deactivated: false,
                                    label: 'Ulvik',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vaksdal',
                                    deactivated: false,
                                    label: 'Vaksdal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_vossherad',
                                    deactivated: false,
                                    label: 'Voss herad',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 sammenslåing av Voss og Granvin til Voss herad',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_granvin',
                                        deactivated: false,
                                        label: 'Granvin',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Voss kommune til Voss herad',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_voss',
                                        deactivated: false,
                                        label: 'Voss',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Granvin til Voss herad',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                id: 'sted_sognogfjordane',
                                deactivated: false,
                                label: 'Sogn og Fjordane',
                                alternativeLabels: [],
                                related: [],
                                children: [
                                  {
                                    id: 'sted_aardal',
                                    deactivated: false,
                                    label: 'Årdal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_askvoll',
                                    deactivated: false,
                                    label: 'Askvoll',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_aurland',
                                    deactivated: false,
                                    label: 'Aurland',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_fjaler',
                                    deactivated: false,
                                    label: 'Fjaler',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_gulen',
                                    deactivated: false,
                                    label: 'Gulen',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_hoeyanger',
                                    deactivated: false,
                                    label: 'Høyanger',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_hyllestad',
                                    deactivated: false,
                                    label: 'Hyllestad',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_laerdal',
                                    deactivated: false,
                                    label: 'Lærdal',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_luster',
                                    deactivated: false,
                                    label: 'Luster',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_nordfjord',
                                    deactivated: false,
                                    label: 'Nordfjord',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_bremanger',
                                        deactivated: false,
                                        label: 'Bremanger',
                                        alternativeLabels: [],
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_gloppen',
                                        deactivated: false,
                                        label: 'Gloppen',
                                        alternativeLabels: [],
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_kinn',
                                        deactivated: false,
                                        label: 'Kinn',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 sammenslåing av Flora og Vågsøy til Kinn kommune',
                                        related: [],
                                        children: [
                                          {
                                            id: 'sted_flora',
                                            deactivated: false,
                                            label: 'Flora',
                                            alternativeLabels: [],
                                            note: 'Fra 1.1.2020, slått sammen med Vågsøy til Kinn kommune',
                                            related: [],
                                            children: [],
                                          },
                                          {
                                            id: 'sted_vaagsoey',
                                            deactivated: false,
                                            label: 'Vågsøy',
                                            alternativeLabels: [],
                                            note: 'Fra 1.1.2020 slått sammen med Flora til Kinn kommune',
                                            related: [],
                                            children: [],
                                          },
                                        ],
                                      },
                                      {
                                        id: 'sted_stad',
                                        deactivated: false,
                                        label: 'Stad',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 sammenslåing av Eid og Selje til Stad kommune',
                                        related: [],
                                        children: [
                                          {
                                            id: 'sted_eid',
                                            deactivated: false,
                                            label: 'Eid',
                                            alternativeLabels: [],
                                            note: 'Fra 1.1.2020 slått sammen med Selje til Stad kommune',
                                            related: [],
                                            children: [],
                                          },
                                          {
                                            id: 'sted_selje',
                                            deactivated: false,
                                            label: 'Selje',
                                            alternativeLabels: [],
                                            note: 'Fra 1.1.2020 slått sammen med Eid til Stad kommune',
                                            related: [],
                                            children: [],
                                          },
                                        ],
                                      },
                                      {
                                        id: 'sted_stryn',
                                        deactivated: false,
                                        label: 'Stryn',
                                        alternativeLabels: [],
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_sogndal',
                                    deactivated: false,
                                    label: 'Sogndal',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 sammenslåing av Sogndal, Balestrand og Leikanger til nye Sogndal kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_balestrand',
                                        deactivated: false,
                                        label: 'Balestrand',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Sogndal og Leikanger til nye Sogndal kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_leikanger',
                                        deactivated: false,
                                        label: 'Leikanger',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Sogndal og Balestrand til nye Sogndal kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_solund',
                                    deactivated: false,
                                    label: 'Solund',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                  {
                                    id: 'sted_sunnfjord',
                                    deactivated: false,
                                    label: 'Sunnfjord',
                                    alternativeLabels: [],
                                    note: 'Fra 1.1.2020 sammenslåing av Førde, Naustdal, Gaular og Jølster til Sunnfjord kommune',
                                    related: [],
                                    children: [
                                      {
                                        id: 'sted_foerde',
                                        deactivated: false,
                                        label: 'Førde',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Naustdal, Gaular og Jølster til Sunnfjord kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_gaular',
                                        deactivated: false,
                                        label: 'Gaular',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Førde, Naustdal og Jølster til Sunnfjord kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_joelster',
                                        deactivated: false,
                                        label: 'Jølster',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Førde, Naustdal og Gaular til Sunnfjord kommune',
                                        related: [],
                                        children: [],
                                      },
                                      {
                                        id: 'sted_naustdal',
                                        deactivated: false,
                                        label: 'Naustdal',
                                        alternativeLabels: [],
                                        note: 'Fra 1.1.2020 slått sammen med Førde, Gaular og Jølster til Sunnfjord kommune',
                                        related: [],
                                        children: [],
                                      },
                                    ],
                                  },
                                  {
                                    id: 'sted_vik',
                                    deactivated: false,
                                    label: 'Vik',
                                    alternativeLabels: [],
                                    related: [],
                                    children: [],
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: 'sted_sverige',
                    deactivated: false,
                    label: 'Sverige',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_stockholm',
                        deactivated: false,
                        label: 'Stockholm',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'sted_oesterrike',
                deactivated: false,
                label: 'Østerrike',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_wien',
                    deactivated: false,
                    label: 'Wien',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_oest-europa',
                deactivated: false,
                label: 'Øst-Europa',
                alternativeLabels: [],
                note: 'fellesbetegnelse på land som etter 2. verdenskrig fikk et kommunistisk styresett (Albania, Bulgaria, Jugoslavia, Polen, Romania, Sovjetunionen, Tsjekkoslovakia, Øst-Tyskland, Ungarn',
                related: [],
                children: [
                  {
                    id: 'sted_jugoslavia',
                    deactivated: false,
                    label: 'Jugoslavia',
                    alternativeLabels: ['Rest-Jugoslavia'],
                    note: 'historisk; inkl. Kongeriket Jugoslavia, Den sosialistiske føderative republikk Jugoslavia og Rest-Jugoslavia, 1918-2006',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_sovjetunionen',
                    deactivated: false,
                    label: 'Sovjetunionen',
                    alternativeLabels: [
                      'Samveldet av uavhengige stater',
                      'Sus',
                    ],
                    note: 'historisk ; forbundsrepublikk i perioden 1922-1991',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_tsjekkoslovakia',
                    deactivated: false,
                    label: 'Tsjekkoslovakia',
                    alternativeLabels: [],
                    note: 'historisk ; republikk opprettet etter 1. verdenskrig, delt i 1993',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_polen',
                deactivated: false,
                label: 'Polen',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_warszawa',
                    deactivated: false,
                    label: 'Warszawa',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_romania',
                deactivated: false,
                label: 'Romania',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_russland',
                deactivated: false,
                label: 'Russland',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_moskva',
                    deactivated: false,
                    label: 'Moskva',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_stalingrad',
                    deactivated: false,
                    label: 'Stalingrad',
                    alternativeLabels: [],
                    note: 'historisk ; 1925-1961, fra 1961: Volgograd',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_st.petersburg',
                    deactivated: false,
                    label: 'St. Petersburg',
                    alternativeLabels: ['Leningrad'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_tsjetsjenia',
                    deactivated: false,
                    label: 'Tsjetsjenia',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_sanmarino',
                deactivated: false,
                label: 'San Marino',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'sted_sápmi',
                deactivated: false,
                label: 'Sápmi',
                alternativeLabels: [
                  'Sábme',
                  'Saepmie',
                  'Sámeednam',
                  'Sameland',
                ],
                note: 'Her: samenes hstoriske bosettingsområder på Kolahalvøya, og i Norge, Sverige og Finland',
                related: [],
                children: [],
              },
              {
                id: 'sted_slovakia',
                deactivated: false,
                label: 'Slovakia',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_bratislava',
                    deactivated: false,
                    label: 'Bratislava',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_sveits',
                deactivated: false,
                label: 'Sveits',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_bern',
                    deactivated: false,
                    label: 'Bern',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_tsjekkia',
                deactivated: false,
                label: 'Tsjekkia',
                alternativeLabels: ['Den tsjekkiske republikk'],

                related: [],
                children: [
                  {
                    id: 'sted_praha',
                    deactivated: false,
                    label: 'Praha',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_tyskland',
                deactivated: false,
                label: 'Tyskland',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_berlin',
                    deactivated: false,
                    label: 'Berlin',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'sted_oest-tyskland',
                    deactivated: false,
                    label: 'Øst-Tyskland',
                    alternativeLabels: ['DDR', 'Tyske demokratiske republikk'],
                    note: 'historisk ; 1949-1990',
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_ukraina',
                deactivated: false,
                label: 'Ukraina',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_kyiv',
                    deactivated: false,
                    label: 'Kyiv',
                    alternativeLabels: ['Kiev'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'sted_ungarn',
                deactivated: false,
                label: 'Ungarn',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'sted_budapest',
                    deactivated: false,
                    label: 'Budapest',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'sted_verdenshav',
        deactivated: false,
        label: 'Verdenshav',
        alternativeLabels: [],

        related: [],
        children: [
          {
            id: 'sted_atlanterhavet',
            deactivated: false,
            label: 'Atlanterhavet',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'sted_nord-atlanteren',
                deactivated: false,
                label: 'Nord-Atlanteren',
                alternativeLabels: ['Barentshavet'],

                related: [],
                children: [
                  {
                    id: 'sted_nordsjoeen',
                    deactivated: false,
                    label: 'Nordsjøen',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'sted_skagerrak',
                        deactivated: false,
                        label: 'Skagerrak',
                        alternativeLabels: [],
                        related: [],
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: 'sted_soer-atlanteren',
                deactivated: false,
                label: 'Sør-Atlanteren',
                alternativeLabels: ['Karibhavet'],

                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'sted_detindiskehav',
            deactivated: false,
            label: 'Det indiske hav',
            alternativeLabels: ['Arabiahavet', 'Indiahavet', 'Rødehavet'],

            related: [],
            children: [],
          },
          {
            id: 'sted_middelhavet',
            deactivated: false,
            label: 'Middelhavet',
            alternativeLabels: ['Egeerhavet'],

            related: [],
            children: [
              {
                id: 'sted_adriaterhavet',
                deactivated: false,
                label: 'Adriaterhavet',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'sted_nordishavet',
            deactivated: false,
            label: 'Nordishavet',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'sted_soerishavet',
            deactivated: false,
            label: 'Sørishavet',
            alternativeLabels: [],
            note: 'Havområdet som omgir Antarktis',
            related: [],
            children: [],
          },
          {
            id: 'sted_stillehavet',
            deactivated: false,
            label: 'Stillehavet',
            alternativeLabels: [
              'Beringshavet',
              'Japanhavet',
              'Okhotskhavet',
              'Sør-Kinahavet',
              'Øst-Kinahavet',
            ],

            related: [],
            children: [],
          },
          {
            id: 'sted_svartehavet',
            deactivated: false,
            label: 'Svartehavet',
            alternativeLabels: [],

            related: [],
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 'tid_tid',
    deactivated: false,
    label: 'Tid',
    alternativeLabels: [],

    related: [],
    children: [
      {
        id: 'tid_eldretid',
        deactivated: false,
        label: 'Eldre tid',
        alternativeLabels: [],
        note: 'Eldre tid: frem til ca. 1500 e.Kr.',
        related: [],
        children: [
          {
            id: 'tid_middelalderen',
            deactivated: false,
            label: 'Middelalderen',
            alternativeLabels: ['Riddertiden', 'Sagatiden'],
            note: 'Ca. 500-1500 e. Kr.',
            related: [],
            children: [
              {
                id: 'tid_hoeymiddelalderen',
                deactivated: false,
                label: 'Høymiddelalderen',
                alternativeLabels: ['Korstogene'],
                note: 'Ca. 1050-1300',
                related: [],
                children: [
                  {
                    id: 'tid_1100-tallet',
                    deactivated: false,
                    label: '1100-tallet',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'tid_1200-tallet',
                    deactivated: false,
                    label: '1200-tallet',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'tid_senmiddelalderen',
                deactivated: false,
                label: 'Senmiddelalderen',
                alternativeLabels: ['Svartedauen'],
                note: 'Ca. 1300-1500',
                related: [],
                children: [
                  {
                    id: 'tid_1300-tallet',
                    deactivated: false,
                    label: '1300-tallet',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'tid_1400-tallet',
                    deactivated: false,
                    label: '1400-tallet',
                    alternativeLabels: ['Renessansen'],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'tid_tidligmiddelalder',
                deactivated: false,
                label: 'Tidlig middelalder',
                alternativeLabels: ['Folkevandringstiden', 'Merovingertiden'],
                note: 'Ca. 500-1050 e. Kr.',
                related: [],
                children: [
                  {
                    id: 'tid_vikingtiden',
                    deactivated: false,
                    label: 'Vikingtiden',
                    alternativeLabels: [],
                    note: 'Ca. 800-1050 e.Kr.',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'tid_oldtiden',
            deactivated: false,
            label: 'Oldtiden',
            alternativeLabels: [],
            note: 'Oldtiden: frem til ca. 500 e.Kr.',
            related: [],
            children: [
              {
                id: 'tid_antikken',
                deactivated: false,
                label: 'Antikken',
                alternativeLabels: ['Romertiden'],
                note: 'Antikken: ca. 500 f.kr. til 500 e.kr.',
                related: [],
                children: [],
              },
              {
                id: 'tid_foerhistorisktid',
                deactivated: false,
                label: 'Førhistorisk tid',
                alternativeLabels: [],
                note: 'Førhistorisk tid: I Norden frem til ca. 1000 e.Kr.',
                related: [],
                children: [
                  {
                    id: 'tid_bronsealderen',
                    deactivated: false,
                    label: 'Bronsealderen',
                    alternativeLabels: [],
                    note: 'I Norden ca. 1700-500 f.Kr.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'tid_jernalderen',
                    deactivated: false,
                    label: 'Jernalderen',
                    alternativeLabels: [],
                    note: 'I Norge ca. 500 f.Kr.-1050 e.Kr.',
                    related: [],
                    children: [],
                  },
                  {
                    id: 'tid_steinalderen',
                    deactivated: false,
                    label: 'Steinalderen',
                    alternativeLabels: ['Eldre steinalder', 'Yngre steinalder'],
                    note: 'I Norge ca. 10 000-1800 f.Kr.',
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'tid_nyeretid',
        deactivated: false,
        label: 'Nyere tid',
        alternativeLabels: ['Kolonitiden', 'Moderne tid'],

        related: [],
        children: [
          {
            id: 'tid_1500-tallet',
            deactivated: false,
            label: '1500-tallet',
            alternativeLabels: ['Reformasjonstiden'],

            related: [],
            children: [],
          },
          {
            id: 'tid_1600-tallet',
            deactivated: false,
            label: '1600-tallet',
            alternativeLabels: [],

            related: [],
            children: [],
          },
          {
            id: 'tid_1700-tallet',
            deactivated: false,
            label: '1700-tallet',
            alternativeLabels: ['Revolusjonstiden'],

            related: [],
            children: [
              {
                id: 'tid_1700-1750',
                deactivated: false,
                label: '1700-1750',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'tid_1750-1799',
                deactivated: false,
                label: '1750-1799',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'tid_1800-tallet',
            deactivated: false,
            label: '1800-tallet',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'tid_1800-1850',
                deactivated: false,
                label: '1800-1850',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'tid_1850-1899',
                deactivated: false,
                label: '1850-1899',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
          {
            id: 'tid_1900-tallet',
            deactivated: false,
            label: '1900-tallet',
            alternativeLabels: [],

            related: [],
            children: [
              {
                id: 'tid_1900-1945',
                deactivated: false,
                label: '1900-1945',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'tid_1900-1914',
                    deactivated: false,
                    label: '1900-1914',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'tid_1914-1918',
                    deactivated: false,
                    label: '1914-1918',
                    alternativeLabels: ['1. verdenskrig', 'Første verdenskrig'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'tid_1918-1939',
                    deactivated: false,
                    label: '1918-1939',
                    alternativeLabels: ['Depresjonen', 'Mellomkrigstiden'],

                    related: [],
                    children: [],
                  },
                  {
                    id: 'tid_1939-1945',
                    deactivated: false,
                    label: '1939-1945',
                    alternativeLabels: [
                      '2. verdenskrig',
                      'Andre verdenskrig',
                      'Annen verdenskrig',
                    ],

                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'tid_1945-1999',
                deactivated: false,
                label: '1945-1999',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'tid_1960-1999',
                    deactivated: false,
                    label: '1960-1999',
                    alternativeLabels: [],
                    related: [],
                    children: [
                      {
                        id: 'tid_1960-1969',
                        deactivated: false,
                        label: '1960-1969',
                        alternativeLabels: ['1960-tallet'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'tid_1970-1979',
                        deactivated: false,
                        label: '1970-1979',
                        alternativeLabels: ['1970-tallet'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'tid_1980-1989',
                        deactivated: false,
                        label: '1980-1989',
                        alternativeLabels: ['1980-tallet'],

                        related: [],
                        children: [],
                      },
                      {
                        id: 'tid_1990-1999',
                        deactivated: false,
                        label: '1990-1999',
                        alternativeLabels: ['1990-tallet'],

                        related: [],
                        children: [],
                      },
                    ],
                  },
                  {
                    id: 'tid_etterkrigstiden',
                    deactivated: false,
                    label: 'Etterkrigstiden',
                    alternativeLabels: ['1945-1959', '1950 tallet'],

                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'tid_2000-tallet',
            deactivated: false,
            label: '2000-tallet',
            alternativeLabels: ['2000-2099', '21. århundre'],

            related: [],
            children: [
              {
                id: 'tid_2000-2049',
                deactivated: false,
                label: '2000-2049',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'tid_2000-2009',
                    deactivated: false,
                    label: '2000-2009',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'tid_2010-2019',
                    deactivated: false,
                    label: '2010-2019',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'tid_2020-2029',
                    deactivated: false,
                    label: '2020-2029',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'tid_2030-2039',
                    deactivated: false,
                    label: '2030-2039',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'tid_2040-2049',
                    deactivated: false,
                    label: '2040-2049',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
              {
                id: 'tid_2050-2099',
                deactivated: false,
                label: '2050-2099',
                alternativeLabels: [],
                related: [],
                children: [
                  {
                    id: 'tid_2050-2059',
                    deactivated: false,
                    label: '2050-2059',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'tid_2060-2069',
                    deactivated: false,
                    label: '2060-2069',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'tid_2070-2079',
                    deactivated: false,
                    label: '2070-2079',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'tid_2080-2089',
                    deactivated: false,
                    label: '2080-2089',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                  {
                    id: 'tid_2090-2099',
                    deactivated: false,
                    label: '2090-2099',
                    alternativeLabels: [],
                    related: [],
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            id: 'tid_2100-tallet',
            deactivated: false,
            label: '2100-tallet',
            alternativeLabels: [],
            related: [],
            children: [
              {
                id: 'tid_2100-2149',
                deactivated: false,
                label: '2100-2149',
                alternativeLabels: [],
                related: [],
                children: [],
              },
              {
                id: 'tid_2150-2199',
                deactivated: false,
                label: '2150-2199',
                alternativeLabels: [],
                related: [],
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
];
