import React, {useMemo} from 'react';
import assert from 'assert-ts';
import {DataValue, DefaultPartCompare} from 'schemaDefinition/types';
import {DataFormPartProps, PartDiffProps} from './types';
import {FlexBox} from '../../../components';
import {useDataFormContext} from '../contexts';
import {DataFormCompareLayout} from './DataFormCompareLayout';
import {DataFormPart} from './DataFormPart';
// import {DataFormPartReplaceValue} from './DataFormPartReplaceValue';
import {DataFormSchema} from './DataFormSchema';

export const DataFormCompare: React.FC<
  DataFormPartProps
> = dataFormPartProps => {
  const {
    part,
    valuePath,
    scopePath,
    usesGlobalScope,
    relatedScope,
    mode,
    noLabel,
    useValue,
    useOriginalValue,
  } = dataFormPartProps;

  const {id, useReviewStatus} = useDataFormContext();
  const status = useReviewStatus(valuePath, id);
  const name = part?.name ?? part?.role ?? 'unamed part';
  const value = (useValue(valuePath, id, name) ?? null) as DataValue;
  const originalValue = (useOriginalValue?.(valuePath, id, name) ??
    null) as DataValue;

  type Diff = {original: PartDiffProps; updated: PartDiffProps};
  const diff = useMemo((): Diff | undefined => {
    if (!part || (part.type === 'schema' && part.compare === 'nested')) {
      return undefined;
    }

    const outerCompare =
      part.compare === 'nested' ? DefaultPartCompare : part.compare;
    const setCompareValue =
      part.compare === 'subValues' || part.compare === 'items';

    return {
      original: {
        mode: 'original',
        compareValue: setCompareValue ? value : null,
        outerCompare,
      },
      updated: {
        mode: 'updated',
        compareValue: setCompareValue ? originalValue : null,
        outerCompare,
      },
    };
  }, [originalValue, part, value]);

  if (!part) {
    return null;
  }

  if (part.type === 'schema' && part.compare === 'nested') {
    return (
      <DataFormSchema
        schema={part}
        valuePath={valuePath}
        relatedScope={relatedScope}
        mode={mode}
        noLabel={noLabel}
        useValue={useValue}
        useOriginalValue={useOriginalValue}
      />
    );
  }

  return (
    <FlexBox horizontal width={'100%'}>
      <DataFormCompareLayout
        renderCurrent={() => (
          <DataFormPart
            part={part}
            useValue={assert(
              useOriginalValue,
              'DataFormPart: useOriginalValue must be provided in compare mode',
            )}
            valuePath={valuePath}
            scopePath={scopePath}
            usesGlobalScope={usesGlobalScope}
            relatedScope={relatedScope}
            mode="read-only"
            diff={diff?.original}
            noLabel={noLabel}
          />
        )}
        renderProposed={() => (
          <DataFormPart
            part={part}
            useValue={useValue}
            valuePath={valuePath}
            scopePath={scopePath}
            usesGlobalScope={usesGlobalScope}
            relatedScope={relatedScope}
            mode={
              status && ['approved', 'rejected', 'corrected'].includes(status)
                ? 'read-only'
                : 'edit'
            }
            diff={diff?.updated}
            noLabel={noLabel}
          />
        )}
      />
      {/* <Spacer size={0.5} />
      <FlexBox center top>
        <DataFormPartReplaceValue valuePath={valuePath} />
      </FlexBox> */}
    </FlexBox>
  );
};
