import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const EbookIcon: React.FC<IconProps> = ({...props}) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.273 4.42945C17.5242 4.64228 17.7189 4.91382 17.84 5.21999C17.9451 5.46664 17.9995 5.7319 18 5.99999V18C18.005 18.2815 17.9505 18.561 17.84 18.82C17.7136 19.1187 17.5165 19.3822 17.2657 19.5878C17.0148 19.7933 16.7177 19.9348 16.4 20C16.2671 20.0153 16.1329 20.0153 16 20H8C7.8671 20.0153 7.7329 20.0153 7.6 20C7.2781 19.9309 6.97824 19.7834 6.72704 19.5705C6.47584 19.3577 6.28106 19.0862 6.16 18.78C6.05493 18.5333 6.00052 18.2681 6 18V5.99999C6.00052 5.7319 6.05493 5.46664 6.16 5.21999C6.28106 4.91382 6.47584 4.64228 6.72704 4.42945C6.97824 4.21663 7.2781 4.06911 7.6 3.99999C7.7329 3.9847 7.8671 3.9847 8 3.99999H16C16.1329 3.9847 16.2671 3.9847 16.4 3.99999C16.7219 4.06911 17.0218 4.21663 17.273 4.42945ZM8 16H16V5.99999H8V16ZM13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18Z"
      />
      <path
        d="M13 11H15"
        stroke={'currentColor'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M9 11H12"
        stroke={'currentColor'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M9 8H15"
        stroke={'currentColor'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
};
