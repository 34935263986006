import React, {useMemo} from 'react';
import {IconButton} from '@mui/material';
import {useLocalization} from 'localization';
import {FlexBox, Icon, Text} from 'components';
import {SearchResultType} from '../types';
import {getEntityIcon} from '../functions/getEntityIcon';

export const useSearchResultHeader = (
  entityType: SearchResultType,
  total: number | undefined,
  onAdd: ((type: SearchResultType) => void) | undefined,
): React.ReactNode => {
  const {tLoose} = useLocalization();
  return useMemo(() => {
    const title = `${tLoose(`general.entity.${entityType}`)} (${total})`;
    const addLabel = tLoose(`extension.linkedValueSearch.${entityType}.add`);

    const handleAdd = onAdd ? () => onAdd(entityType) : undefined;

    return (
      <FlexBox horizontal center justifyContent={'space-between'} flex={1}>
        <FlexBox horizontal center>
          <Icon icon={getEntityIcon(entityType)} sx={{mr: 1}} />
          <Text variant="h3">{title}</Text>
        </FlexBox>
        {handleAdd ? (
          <FlexBox center left horizontal>
            <IconButton size="small" onClick={handleAdd} sx={{pr: 2}}>
              <FlexBox center horizontal>
                <Icon icon={'Add'} color="primary" />
                <Text variant="button">{addLabel}</Text>
              </FlexBox>
            </IconButton>
          </FlexBox>
        ) : null}
      </FlexBox>
    );
  }, [tLoose, entityType, total, onAdd]);
};
