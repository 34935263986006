import {TypedSchema} from 'schemaDefinition/types';
import {CodeListRef, Collection} from 'api';
import {collectionTitlesSchemaPart} from './collectionTitlesSchemaPart';

export const workCollectionSchema: TypedSchema<Collection> = {
  name: 'workCollection',
  key: 'workCollection',
  parts: [
    {
      type: 'codelist',
      name: 'collectionType',
      codelistId: 'work_collection_type',
      required: true,
    },
    collectionTitlesSchemaPart,
    {
      type: 'text',
      name: 'note',
    },
    {
      type: 'expand',
      role: 'linksForType',
      when: [
        {
          condition: {
            op: 'eq',
            arg1: {
              $ref: '.collectionType',
            },
            arg2: CodeListRef.collection_type.Læreverk,
          },
          parts: [
            {
              type: 'linkedLiterary',
              name: 'links',
              roleCodelistId: 'educationalSeries.linkedRole',
              linkRole: 'contains.work',
              cardinality: 'multiple',
              // TODO: Remove readonly, when full edit of supported series is supported by backend
              readonly: true,
            },
          ],
        },
        {
          condition: {
            op: 'eq',
            arg1: {
              $ref: '.collectionType',
            },
            arg2: CodeListRef.collection_type['Annen tilknytning'],
          },
          parts: [
            {
              type: 'linkedLiterary',
              name: 'links',
              roleCodelistId: 'otherConnection.linkedRole',
              linkRole: 'contains.work',
              cardinality: 'multiple',
              // TODO: Remove readonly, when full edit of supported series is supported by backend
              readonly: true,
            },
          ],
        },
      ],
      default: [
        // Serie
        {
          type: 'linkedLiterary',
          name: 'links',
          roleCodelistId: 'series.linkedRole',
          linkRole: 'contains.work',
          cardinality: 'multiple',
          // TODO: Remove readonly, when full edit of supported series is supported by backend
          readonly: true,
        },
      ],
    },
  ],
};
