import {InputLabel, Stack, SxProps} from '@mui/material';
import {Concept, EntitySubType, EntitySubTypes} from 'types';
import {ColorPalette} from 'theme';
import {UXUnit} from 'theme/settings';
import {Bar, FlexBox} from 'components';
import {SearchMainEntityType} from '../types';
import {EntityButton} from './EntityButton';

type Props = {
  sx?: SxProps;
  // initialEntityType?: EntityType;
  subtypeRestriction?: EntitySubType[];
  selectedEntity?: SearchMainEntityType;
  selectedSubtype?: EntitySubType;
  onEntitySelected?: (entityType: SearchMainEntityType) => void;
  onSubEntitySelected?: (entitySubtype: EntitySubType) => void;
};

const SearchMainEntityTypes: SearchMainEntityType[] = [
  Concept.work,
  Concept.agent,
  Concept.collection,
];

const labelSx: SxProps = {color: ColorPalette.burgundy};

export const SearchEntitySelector: React.FC<Props> = ({
  sx,
  selectedEntity,
  selectedSubtype,
  onEntitySelected,
  onSubEntitySelected,
}) => {
  const subentities = selectedEntity
    ? EntitySubTypes[selectedEntity]
    : undefined;

  return (
    <FlexBox sx={sx}>
      <InputLabel sx={labelSx}>{'Vis meg'}</InputLabel>
      <Stack direction={'row'} spacing={1}>
        {SearchMainEntityTypes.map(entityType => (
          <EntityButton
            key={entityType}
            type={entityType}
            isSelected={entityType === selectedEntity}
            onSelected={onEntitySelected}
          />
        ))}
        {subentities ? (
          <>
            <FlexBox px={0.5} center>
              <Bar height={2 * UXUnit} colorx={ColorPalette.burgundy} />
            </FlexBox>
            {subentities.map(subentity => (
              <EntityButton
                key={subentity}
                type={subentity}
                isSelected={subentity === selectedSubtype}
                onSelected={onSubEntitySelected}
              />
            ))}
          </>
        ) : null}
      </Stack>
    </FlexBox>
  );
};
