import {
  AnyValidation,
  Condition,
  ValidationModifier,
} from 'schemaDefinition/types';
import {
  isAndCondition,
  isAndConditionV0,
  isBooleanCondition,
  isComparisonCondition,
  isComparisonConditionV0,
  isIncludesCondition,
  isLengthCondition,
  isNotCondition,
  isNotConditionV0,
  isOrCondition,
  isOrConditionV0,
  isRangeCondition,
  isRegexCondition,
} from './isConditionType';

export const isAnyValidation = (
  validationModifier: ValidationModifier,
): validationModifier is AnyValidation => {
  const condition = validationModifier as Condition;
  return (
    isBooleanCondition(condition) ||
    isRangeCondition(condition) ||
    isLengthCondition(condition) ||
    isRegexCondition(condition) ||
    isComparisonConditionV0(condition) ||
    isComparisonCondition(condition) ||
    isNotConditionV0(condition) ||
    isOrConditionV0(condition) ||
    isAndConditionV0(condition) ||
    isNotCondition(condition) ||
    isOrCondition(condition) ||
    isAndCondition(condition) ||
    isIncludesCondition(condition)
  );
};
