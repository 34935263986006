import {assert} from 'assert-ts';
import {SubdirParams} from '../types';
import {findURIParamPlaceholders} from './findURIParamPlaceholders';

export const expandURIParamPlaceholders = (
  subdir: string,
  params?: SubdirParams,
): string => {
  const placeholders = findURIParamPlaceholders(subdir);

  // No params
  if (placeholders.length === 0) {
    assert(
      params === undefined ||
        (typeof params === 'object' && Object.keys(params).length === 0),
      'expandURIParamPlaceholders: mismatch: no placeholders but params provided',
      {subdir, params},
    );

    return subdir;
  }

  // Multiple placeholders => params must have matching key
  // Singlel placholder and key/value params => ditto
  if (placeholders.length > 1 || typeof params === 'object') {
    assert(
      typeof params === 'object',
      'expandURIParamPlaceholders: mismatch: multiple placeholders requires params key/value map',
      {subdir, params},
    );

    const keys = Object.keys(params);
    const hasAllParams = placeholders.every(p => keys.includes(p));
    assert(
      hasAllParams,
      'expandURIParamPlaceholders: mismatch: missing parameter for placeholder',
      {subdir, params},
    );

    let expanded = subdir;
    placeholders.forEach(key => {
      expanded = expanded.replace(`{${key}}`, params[key].toString());
    });

    return expanded;
  }

  assert(
    params !== undefined,
    'expandURIParamPlaceholders: no params provided',
    {subdir, params},
  );
  return subdir.replace(`{${placeholders[0]}}`, params.toString());
};
