import assert from 'assert-ts';
import {ChangeRequestAction, DataValue} from 'schemaDefinition/types';
import {areValuesEqual} from 'schemaDefinition';
import {isNullish} from 'services/utils/isNullish';

const getItemValue = (idx: number, data: DataValue): DataValue | undefined =>
  Array.isArray(data) ? data[idx] : undefined;

export const getUpdatedItemActions = (
  idx: number,
  update: DataValue,
  original: DataValue,
): ChangeRequestAction[] => {
  const originalItem = getItemValue(idx, original);
  const updateItem = getItemValue(idx, update);

  if (
    !assert.soft(
      !isNullish(originalItem) || !isNullish(updateItem),
      'Original and update item should not both be nullish',
      {idx, original, update},
    )
  ) {
    return [];
  }

  // No original item => add
  if (isNullish(originalItem)) {
    return ['addItem'];
  }

  // No update item => remove
  if (isNullish(updateItem)) {
    return ['removeItem'];
  }

  // No change => no action
  if (areValuesEqual(originalItem, updateItem)) {
    return [];
  }

  // Changes => replace or add
  return ['addItem', 'replace'];
};
