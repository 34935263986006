import {useSelector} from 'react-redux';
import {AppState} from 'store/types';

const tablesSelector = (state: AppState) => state.settings.tables;

/**
 * Returns the inital state of columns.
 */
export const useInitialColumns = (
  schemaName: string,
  fallback: string[],
): string[] => {
  // Only fetch state once, never listen to changes.
  const tablesState = useSelector(tablesSelector, () => true);

  if (!tablesState[schemaName]) {
    return fallback;
  }

  const columns = tablesState[schemaName]?.columns.map(c => c.name);
  if (columns?.length > 0) {
    return columns;
  }

  return fallback;
};
