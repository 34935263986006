export const getAllStorageKeys = (): string[] => {
  const keys = [];
  const count = localStorage.length;
  for (let i = 0; i < count; i++) {
    const key = localStorage.key(i);
    if (typeof key === 'string') {
      keys.push(key);
    }
  }

  return keys;
};
