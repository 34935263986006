import React, {useMemo} from 'react';
import {SxProps} from '@mui/material';
import {Code} from 'api/types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {Icon, Layout, RadioButtonsField, Spacer, Text} from 'components';
import {EmptyResultAlternative} from '../types';

type Props = {
  emptyResultAlternative: EmptyResultAlternative;
  setEmptyResultAlternative: (value: EmptyResultAlternative) => void;
};
const infoContainerSx: SxProps = {
  // height: 10,
  padding: 2,
  borderRadius: 2,
  backgroundColor: ColorPalette.enhancedGreen,
};

const guideContainerSx: SxProps = {
  padding: 2,
  borderRadius: 2,
  backgroundColor: ColorPalette.offWhite,
};

export const SearchAgentEmptyResult: React.FC<Props> = ({
  emptyResultAlternative,
  setEmptyResultAlternative,
}) => {
  const {t} = useLocalization();
  const alternatives = useMemo(
    (): Code[] => [
      {
        code: 'createExternal',
        value: t('wizard.agent.step.1.search.empty.createExternal.guide'),
      },
      {
        code: 'editInternal',
        value: t('wizard.agent.step.1.search.empty.editInternal.guide'),
      },
    ],
    [t],
  );
  return (
    <Layout sx={infoContainerSx}>
      <Layout>
        <Layout horizontal adjustTop adjustLeft>
          <Icon icon="Ghost" />
          <Spacer size={1} />
          <Layout>
            <Text variant="h3">
              {t('wizard.agent.step.1.search.empty.title')}
            </Text>
            <Text variant="body1">
              {t('wizard.agent.step.1.search.empty.message')}
            </Text>
          </Layout>
        </Layout>
      </Layout>
      <Spacer size={2} />
      <Layout horizontal sx={guideContainerSx} adjustCenter adjustLeft>
        <RadioButtonsField
          name="createNew"
          value={emptyResultAlternative}
          options={alternatives}
          variant="button"
          onChange={val =>
            setEmptyResultAlternative(val as EmptyResultAlternative)
          }
        />
      </Layout>
    </Layout>
  );
};
