import React, {ChangeEvent, useEffect, useState} from 'react';
import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  SxProps,
  styled,
} from '@mui/material';
import {Icon} from '../../icons';

type SearchInputProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  sx?: SxProps;
};

const StyledTextField = styled(MuiTextField)({
  borderRadius: '24px',
  '& .MuiOutlinedInput-root': {
    border: 'none',
    outline: 'none',
    boxShadow: 'none',

    input: {
      paddingRight: 0,
    },
    ':hover': {
      boxShadow: 'none',
    },
  },
});

export const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
  placeholder,
  sx,
}) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const handleInputChange = (
    event?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDebouncedValue(event?.target.value || '');
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onChange(debouncedValue);
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [debouncedValue, onChange]);

  return (
    <StyledTextField
      sx={sx}
      className={'search-input'}
      size={'small'}
      value={debouncedValue}
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={() => handleInputChange()}>
              <Icon icon={'Search'} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={handleInputChange}
    />
  );
};
