import {useMemo} from 'react';
import {Concept} from 'types';
import {ColumnConfig} from 'schema/table/types';
import {SchemaReference} from 'schemas/types';
import {NationalAgentType} from 'api';
import {useDefaultColumns} from 'schemas/hooks/useDefaultColumns';

export const useNationalAgentsTableColumns = (
  agentType: NationalAgentType,
): ColumnConfig[] => {
  const schemaRef = useMemo(
    (): SchemaReference => ({entity: Concept.agent, subType: agentType}),
    [agentType],
  );
  const defaultEntityColumns = useDefaultColumns(schemaRef);

  return useMemo(() => {
    const inEbbaColumn: ColumnConfig = {
      name: 'inEbba',
      visible: true,
      width: 60,
    };

    const nationalRegistryStatus: ColumnConfig = {
      name: 'registryStatus',
      visible: true,
    };

    return [inEbbaColumn, nationalRegistryStatus, ...defaultEntityColumns];
  }, [defaultEntityColumns]);
};
