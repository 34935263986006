import {useMemo, useState} from 'react';
import {SxProps} from '@mui/material';
import {ManifestationStatus} from 'api/types';
import {MenuItemProps} from 'components/popups/types';
import {ManifestationStatuses} from 'services/data/metadata/types';
import {useLocalization} from 'localization';
import {
  getManifestationStatusColorName,
  useManifestationStatusColor,
} from 'services/utils';
import {
  CheckRoundBackgroundIcon,
  DropDownIcon,
  PopupMenu,
  ToolButton,
} from 'components';
import {RoundBackgroundIcon} from 'components/icons/RoundBackgroundIcon';

type Props = {
  status: ManifestationStatus;
  disabled?: boolean;
  onStatusChangePreview: (status: ManifestationStatus | undefined) => void;
  onStatusChanged: (status: ManifestationStatus) => void;
  miniMode: boolean;
};

export const ManifestationStatusButton: React.FC<Props> = ({
  status,
  disabled,
  onStatusChangePreview,
  onStatusChanged,
  miniMode,
}) => {
  const {tLoose} = useLocalization();
  const {label} = useMemo(() => {
    return {
      label: tLoose(`entity.manifestation.status.short.${status}`),
    };
  }, [status, tLoose]);
  const color = useManifestationStatusColor(status);

  const statusItems = useMemo(
    () =>
      ManifestationStatuses.map(itemStatus => {
        const colorName = getManifestationStatusColorName(itemStatus);
        return {
          id: itemStatus,
          title: tLoose(`entity.manifestation.status.full.${itemStatus}`),
          icon:
            itemStatus === status ? (
              <CheckRoundBackgroundIcon fillColor={colorName} />
            ) : (
              <RoundBackgroundIcon fillColor={colorName} />
            ),
        } as MenuItemProps<ManifestationStatus>;
      }),
    [status, tLoose],
  );

  const [anchorEButton, setAnchorEButton] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEButton(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEButton(null);
  };
  const handleSelect = (item: ManifestationStatus) => {
    onStatusChanged(item);
    handleCloseMenu();
  };

  const toolSx: SxProps = {
    backgroundColor: color,
  };

  return (
    <>
      <ToolButton
        title={label}
        disabled={disabled}
        renderIcon={() => <DropDownIcon />}
        sx={toolSx}
        onClick={handleOpenMenu}
        data-cy={'manifestation-status'}
        iconOnly={miniMode}
      />
      <PopupMenu
        data-cy={'manifestation-status-menu'}
        anchorEl={anchorEButton}
        popupTitle={'Sett status'}
        items={statusItems}
        layout={{
          positionVertical: 'bottom',
        }}
        onClose={handleCloseMenu}
        onPreview={onStatusChangePreview}
        onSelect={handleSelect}
      />
    </>
  );
};
