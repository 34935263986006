import {
  Part,
  PartBool,
  PartCodeList,
  PartCodeListOrText,
  PartDate,
  PartHtml,
  PartInt,
  PartNameVariant,
  PartText,
  PartTextArea,
  PartYear,
  PartYearOrDate,
} from 'schemaDefinition/types';

export const isLeafPart = <TVal = unknown>(
  part: Part<TVal>,
): part is
  | PartBool<TVal>
  | PartHtml<TVal>
  | PartCodeList<TVal>
  | PartCodeListOrText<TVal>
  | PartNameVariant<TVal>
  | PartInt<TVal>
  | PartText<TVal>
  | PartTextArea<TVal>
  | PartYear<TVal>
  | PartDate<TVal>
  | PartYearOrDate<TVal> => {
  switch (part.type) {
    case 'bool':
    case 'html':
    case 'codelist':
    case 'codelist|text':
    case 'nameVariant':
    case 'int':
    case 'text':
    case 'textarea':
    case 'year':
    case 'date':
    case 'yearOrDate':
      return true;
    case 'linkedAgent':
    case 'linkedLiterary':
    case 'thesaurus':
    case 'schema':
    case 'expand':
      return false;
  }
};
