import {assert} from 'assert-ts';
import {DataValue, DateValue, YearOrDateValue} from 'schemaDefinition/types';
import {
  stringToDateValue,
  yearOrDateValueToDateValue,
} from '../../services/utils';

export const getDateMinValue = (
  value: DataValue | undefined,
): DateValue | undefined => getDateValue(value, '01-01');

export const getDateMaxValue = (
  value: DataValue | undefined,
): DateValue | undefined => getDateValue(value, '12-31');

export const getDateValue = (
  value: DataValue | undefined,
  defaultDatePart = '01-01',
): DateValue | undefined => {
  // Null/undefined
  if (value === null || value === undefined) {
    return undefined;
  }

  const defaultDateParts = defaultDatePart.split('-').map(Number);

  // Numeric
  if (typeof value === 'number') {
    // Interpret as year
    if (value !== 0 && Math.abs(value) < 3000) {
      return {
        year: value,
        month: defaultDateParts[0],
        day: defaultDateParts[1],
      };
    }

    // Probably a timestamp.
    if (value > 3000 || value < 3000) {
      const date = new Date(value);
      return {
        year: date.getFullYear(),
        month: date.getUTCMonth() + 1,
        day: date.getUTCDate(),
      };
    }

    // Interpret as year
    return {
      year: value,
      month: defaultDateParts[0],
      day: defaultDateParts[1],
    };
  }
  // String
  else if (typeof value === 'string') {
    // Interpret as year?
    if (/^-?[0-9]{1,4}$/.test(value)) {
      const year = parseInt(value);
      return {
        year: year,
        month: defaultDateParts[0],
        day: defaultDateParts[1],
      };
    } else {
      return stringToDateValue(value);
    }
  }
  // DateOrYear
  else if (
    typeof value === 'object' &&
    Object.keys(value).some(
      k => k === 'date' || k === 'year' || k === 'bce' || k === 'isApproximate',
    )
  ) {
    const yearOrDate = value as YearOrDateValue;

    if (yearOrDate.date) {
      return yearOrDateValueToDateValue(yearOrDate);
    } else if (yearOrDate.year) {
      return {
        year: yearOrDate.bce ? -yearOrDate.year : yearOrDate.year,
        month: defaultDateParts[0],
        day: defaultDateParts[1],
      };
    }
  }
  // Unsupported
  else {
    assert.soft(
      false,
      'getDateValue: value must be of type number | string | YearOrDate',
      {
        value,
      },
    );

    return undefined;
  }

  assert.soft(false, 'getDateValue: value is invalid date/year', {
    value,
  });

  return undefined;
};
