import React, {useCallback} from 'react';
import {IconButton, Popover, styled} from '@mui/material';
import {assert} from 'assert-ts';
import {ReviewStatusType} from 'types';
import {IconName} from 'components/icons/types';
import {Kind} from 'theme/types';
import {px2rem} from 'theme';
import {reverseMap} from 'api/mappers/reverseMap';
import {Icon} from 'components/icons';
import {StatusSelector} from './StatusSelector';

type Props = {
  status?: ReviewStatusType;
  pctValue: number;
  sizePx: number;
  onChange: (status: ReviewStatusType) => void;
};

const statusToIconMap: {[status in ReviewStatusType]: IconName} = {
  approved: 'CheckCircle',
  rejected: 'RemoveCircle',
  corrected: 'EditCircle',
  none: 'Circle',
};

const statusToColorMap: {
  [status in ReviewStatusType]: Kind;
} = {
  approved: 'approve',
  rejected: 'reject',
  corrected: 'primary',
  none: 'secondary',
};

const ReviewStatuses: ReviewStatusType[] = [
  'none',
  // 'corrected',
  'rejected',
  'approved',
];
const StatusIcons = ReviewStatuses.map(s => statusToIconMap[s]);

const PopoverStyled = styled(Popover)(() => ({
  // Could not figure out how to set this from scss
  '.MuiPopover-root, .MuiPopover-paper': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}));

export const ReviewStatus: React.FC<Props> = ({
  status = 'none',
  pctValue,
  sizePx,
  onChange,
}) => {
  const size = px2rem(sizePx);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelectOption = useCallback(
    (optionIcon: IconName) => {
      const status = assert(reverseMap(optionIcon, statusToIconMap));
      onChange(status);
      handleClose();
    },
    [handleClose, onChange],
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        color={statusToColorMap[status]}
        aria-describedby={id}
        sx={{height: size, width: size, marginTop: -1.5}}
        onClick={handleClick}>
        <Icon icon={statusToIconMap[status]} fontSize="medium" />
      </IconButton>

      <PopoverStyled
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}>
        <StatusSelector
          pctValue={pctValue}
          size={size}
          options={StatusIcons}
          onSelect={handleSelectOption}
        />
      </PopoverStyled>
    </div>
  );
};
