import {useMemo} from 'react';
import {TableConfiguration} from 'schema/table/types';
import {NationalAgentType} from 'api';
import {NationalAgentWithInEbba} from '../types';
import {TrueAsVerifiedIcon} from '../../../schemas/renderers';
import {useNationalAgentsTableColumns} from './useNationalAgentsTableColumns';

export const useNationalAgentsResultTableConfig = (
  agentType: NationalAgentType,
): TableConfiguration<NationalAgentWithInEbba> => {
  const defaultColumns = useNationalAgentsTableColumns(agentType);

  return useMemo(() => {
    const config: TableConfiguration<NationalAgentWithInEbba> = {
      titleVariant: 'noHeaderAndSelectorEmbedded',
      defaultColumns,
      enableSelectionButton: 'single',
      partValueRenderers: {
        inEbba: TrueAsVerifiedIcon,
      },
    };

    return config;
  }, [defaultColumns]);
};
