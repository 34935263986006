import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {NoProps} from 'types';
import {useLocalization} from 'localization';
import {useBrowserTabTitle} from 'services/head/hooks/useBrowserTabTitle';
import {Scene, Spacer} from 'components';
import {SceneTitle} from '../../components/scene/SceneTitle';
import {Tasks, TasksToolbar} from './components';
import {
  OverviewContext,
  useOverviewContextProviderValue,
} from './context/OverviewContext';

type Props = NoProps;

export const OverviewScene: React.FC<Props> = () => {
  const {t} = useLocalization();
  const value = useOverviewContextProviderValue();
  const {setBrowserTabTitle} = useBrowserTabTitle();

  useEffect(() => {
    setBrowserTabTitle(value.title);
  });

  const title = t('page.overview.title');

  return (
    <OverviewContext.Provider value={value}>
      <Scene browserTitle={title}>
        <Scene.Header>
          <Box
            component={Grid}
            container
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Grid item xs={12}>
              <SceneTitle title={title} xs={12} sm={'auto'}>
                <Box component={Grid} item xs={12} sm={9}>
                  <TasksToolbar />
                </Box>
              </SceneTitle>
            </Grid>
          </Box>
        </Scene.Header>
        <Scene.Content>
          <Spacer size={2} />
          <Tasks />
        </Scene.Content>
      </Scene>
    </OverviewContext.Provider>
  );
};
