import {useCallback} from 'react';
import {useSelector, useStore} from 'react-redux';
import get from 'lodash/get';
import {GetValueById, UseValueById} from 'schema/form/types';
import {DataValue} from 'schemaDefinition/types';
import {AppState} from 'store/types';

const getValue = (
  state: AppState,
  localPath: string | undefined,
): DataValue => {
  if (localPath === undefined) {
    return null;
  }

  const work = state.metadataEdit.metadata?.work;
  return work ? (localPath === '' ? work : get(work, localPath) ?? null) : null;
};

const useValueSelector = (localPath: string | undefined) =>
  useCallback(
    (state: AppState): DataValue => getValue(state, localPath),
    [localPath],
  );

/**
 * Hook to look up entity property value by entityId and local path.
 * Use to trigger updates (re-render) when store changes
 */
export const useWorkEditValue: UseValueById = (
  localPath: string | undefined,
  _: string,
): DataValue | undefined => {
  const selector = useValueSelector(localPath);
  const value = useSelector(selector);

  return value;
};

/**
 * Returns function to look up entity property value by entityId and local path.
 * Use to lookup value in store when needed
 */
export const useGetWorkEditValue = (): GetValueById => {
  const {getState} = useStore<AppState>();
  return useCallback(
    (localPath: string | undefined): DataValue | undefined =>
      getValue(getState(), localPath),
    [getState],
  );
};
