import React, {useCallback, useMemo} from 'react';
import {Grid, Autocomplete as MuiAutocomplete, SxProps} from '@mui/material';
import assert from 'assert-ts';
import {CurrentFilterType, FilterOperation} from 'api/types';
import {IconButton, Layout, Select, TextField} from 'components';
import {useAdvancedSearchContext} from '../context/AdvancedSearchContext';
import {getDefaultValue} from '../functions/getDefaultValue';
import {useDataTranslations} from '../hooks';
import {RenderFilterValue} from './fields/RenderFilterValue';

type Props = {
  filter: CurrentFilterType;
};

type FilterNameOption = {
  id: string;
  label: string;
};

export const Filter: React.FC<Props> = ({filter}) => {
  const {
    availableFilters,
    updateCurrentFilter,
    removeCurrentFilterById,
    onSearch,
  } = useAdvancedSearchContext();
  const {getOperation} = useDataTranslations();

  const filterNameOptions: FilterNameOption[] = useMemo(() => {
    return availableFilters.map(f => ({
      id: f.name,
      label: f.label,
    }));
  }, [availableFilters]);

  const availableFilter = useMemo(() => {
    return assert(
      availableFilters.find(f => f.name === filter.name),
      'Filter: Expected to find filter among available filters',
      {
        filter,
        availableFilters,
      },
    );
  }, [availableFilters, filter]);

  const availableFilterOperations = useMemo(() => {
    return availableFilter.operations.map(o => ({
      value: o,
      label: getOperation(o),
    }));
  }, [availableFilter.operations, getOperation]);

  const handleOnChangeFilterName = useCallback(
    (_: React.SyntheticEvent, value: FilterNameOption | null) => {
      if (value) {
        const newName = value.id;
        const operations = availableFilters.find(
          f => f.name === newName,
        )?.operations;

        updateCurrentFilter(filter.id, {
          ...filter,
          name: newName,
          operation:
            operations && !operations.includes(filter.operation)
              ? operations[0]
              : filter.operation,
          values: [],
        });
      }
    },
    [availableFilters, filter, updateCurrentFilter],
  );

  const handleOnChangeOperation = useCallback(
    (value: FilterOperation) => {
      updateCurrentFilter(filter.id, {
        ...filter,
        operation: value,
        values: getDefaultValue(availableFilter.type, value),
      });
    },
    [availableFilter.type, filter, updateCurrentFilter],
  );

  const sxHidden: SxProps = useMemo(() => {
    return {
      display: filter.hidden ? 'none' : 'inherit',
    };
  }, [filter]);

  const value = useMemo(
    () => ({
      id: filter.name,
      label:
        filterNameOptions.find(f => f.id === filter.name)?.label ?? filter.name,
    }),
    [filter.name, filterNameOptions],
  );

  return (
    <Layout flex={1} horizontal pb={1} sx={sxHidden}>
      <Grid container>
        <Grid item sm={3} pr={1}>
          <MuiAutocomplete<FilterNameOption, false, true, false>
            value={value}
            options={filterNameOptions}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={handleOnChangeFilterName}
            noOptionsText={'Fant ikke feltet'}
            disableClearable={true}
            size={'small'}
            getOptionKey={(option: FilterNameOption) => option.id}
            renderInput={params => (
              <TextField type={'text'} name={'name'} {...params} flex={1} />
            )}
          />
        </Grid>
        <Grid item sm={3} pr={1}>
          <Select<FilterOperation>
            value={filter.operation}
            options={availableFilterOperations}
            onChange={handleOnChangeOperation}
          />
        </Grid>
        <Grid item sm={5}>
          <RenderFilterValue
            filter={filter}
            onChange={f => updateCurrentFilter(f.id, f)}
            onSubmit={onSearch}
            codelistId={availableFilter.codelistId}
            type={availableFilter.type}
          />
        </Grid>
        <Grid item sm={1}>
          <IconButton
            icon={'Remove'}
            onClick={() => removeCurrentFilterById(filter.id)}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};
