import {GridColumnVisibilityModel, GridRowModel} from '@mui/x-data-grid-pro';
import {ColumnSelectionOption} from '../hooks/useColumnSelectionPopover';

export const mapColumnSelectionOptions = (
  columns: GridRowModel[],
  columnVisibilityModel: GridColumnVisibilityModel,
): ColumnSelectionOption[] =>
  columns.map(c => {
    return {
      key: c.field,
      title: c.headerName || c.field,
      checked: columnVisibilityModel[c.field] || false,
    };
  });
