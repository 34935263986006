import {assert} from 'assert-ts';
import dayjs from 'dayjs';
import enLocale from 'dayjs/locale/en-gb';
import nbLocale from 'dayjs/locale/nb';
import nnLocale from 'dayjs/locale/nn';
import {Log} from '../../../logging/types';
import {Indexer} from '../../../types';
import {Locale, MasterLocale} from '../../types';
import {Locales, Translate} from './types';
import {Args} from './coreTypes';
import {formatLocaleString} from './formatLocaleString';
import {lookupRawLocaleString} from './lookupRawLocaleString';

type Configuration = {
  logWarning: Log;
};

let configuration: Configuration = {
  logWarning: (_message: string, _data?: Indexer) => undefined,
};
let currentLocale: Locale = MasterLocale;
const lookupCache = new Map<string, string | null>();

export const getLocale = (): Locale => currentLocale;
export const setLocale = (locale: Locale) => {
  currentLocale = locale;
  dayjs.locale(locale);
  lookupCache.clear();
};

export const LocaleMaps = {
  en: enLocale,
  nb: nbLocale,
  nn: nnLocale,
};

export const getDayjsLocale = (locale: Locale): ILocale => {
  return LocaleMaps[locale];
};

/**
 * Looks up translation.
 * If translation is missing:
 * - logWarning provided: will return key-based string and log warning
 * - no logWarning: just returs undefined
 */
const lookup = (
  key: string,
  args: Args | undefined,
  logWarning?: Log,
  caseInsensitive?: boolean,
): string | undefined => {
  const raw =
    lookupRawLocaleString(
      key,
      args,
      Locales[currentLocale],
      currentLocale,
      logWarning,
      caseInsensitive,
    ) ??
    (currentLocale !== MasterLocale
      ? lookupRawLocaleString(
          key,
          args,
          Locales[MasterLocale],
          MasterLocale,
          logWarning,
          caseInsensitive,
        )
      : undefined);

  if (!raw) {
    return logWarning ? `Missing: ${key}` : undefined;
  }

  const formatted = formatLocaleString(key, raw, args, logWarning);

  return formatted;
};

const translateCore = (
  key: string,
  args?: Args,
  logWarning?: Log,
  caseInsensitive?: boolean,
): string | undefined => {
  const cacheKey = key + (args !== undefined ? JSON.stringify(args) : '');
  let cached = lookupCache.get(cacheKey);
  if (cached === undefined) {
    cached = lookup(key, args, logWarning, caseInsensitive);
    lookupCache.set(cacheKey, cached ?? null);
  }

  return cached ?? undefined;
};

/**
 * Returns looked up translation. If not found, key-based string is returned, and warning is logged
 * @param key
 * @param args
 * @returns
 */
export const translate: Translate = (key: string, args?: Args): string =>
  assert(translateCore(key, args, configuration.logWarning));

/**
 * Try to lookup translation. If not found, undefined is returned and no warning is logged
 * @param key
 * @param args
 * @returns
 */
export const tryTranslate: Translate<string | undefined> = (
  key: string,
  args?: Args,
  caseInsentitive?: boolean,
): string | undefined => translateCore(key, args, undefined, caseInsentitive);

export const configureLocalization = (config: Configuration) => {
  configuration = {...configuration, ...config};
};
