import {CodeList} from 'api/types';
import {ExpressionImportProps} from 'services/data/metadata/types';
import {getRestrictedExpressionRoleCodelist} from './getRestrictedExpressionRoleCodelist';
import {getRestrictedProductFormCodelistByExpressionFormat} from './getRestrictedProductFormCodelistByExpressionFormat';

export const getRestrictedExpressionCodelist = (
  importProps: ExpressionImportProps,
  outerCodelist: CodeList,
): CodeList => {
  if (importProps === undefined) {
    return outerCodelist;
  }

  if (outerCodelist.id === 'PRODUCT_FORM') {
    return getRestrictedProductFormCodelistByExpressionFormat(
      importProps,
      outerCodelist,
    );
  }

  if (outerCodelist.id === 'EXPRESSION_ROLE_TYPE') {
    return getRestrictedExpressionRoleCodelist(importProps, outerCodelist);
  }
  return outerCodelist;
};
