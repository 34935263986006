import sortBy from 'lodash/sortBy';
import {Concept, LinkEntityType} from 'types';
import {
  VerifiedLinkedCatalogPost,
  VerifiedLinkedLiterary,
} from 'schemaDefinition/types';
import {isLinkedAgentLink} from 'schemaDefinition/functions/isLinkedAgentLink';
import {LinkRole} from 'schemaDefinition/linkTypes';
import {isNullish} from 'services/utils';
import {formatNameVariantValue} from 'schema/preview/functions/formatNameVariantValue';
import {splitRoleEntityCode} from './splitEntityRoleEntityCode';

const targetTypeOrder: {[key in LinkEntityType]: number} = {
  [Concept.series]: 1,
  [Concept.educationalSeries]: 2,
  [Concept.otherConnection]: 3,
  [Concept.publisherSeries]: 1,
  [Concept.work]: 4,
  [Concept.expression]: 5,
  [Concept.manifestation]: 6,
  [Concept.agent]: 7,
  [Concept.person]: 8,
  [Concept.corporation]: 9,
  [Concept.event]: 10,
  [Concept.publisher]: 11,
};

const linkRoleOrder: {[key in LinkRole]: number} = {
  partOf: 1,
  contains: 2,
  basedOn: 3,
  hasInspired: 4,
  mentions: 5,
  mentionedIn: 6,
  relatedTo: 7,
  hasPseudonym: 8,
  pseudonymFor: 9,
};

export const sortLinks = (
  links: VerifiedLinkedLiterary[] | undefined,
): VerifiedLinkedLiterary[] | undefined => {
  if (!links) {
    return links;
  }

  const extendedLinks = links.map(link => ({
    link,
    value: isLinkedAgentLink(link.link)
      ? formatNameVariantValue(link.link.agentName, link.link.type)
      : link.link.name,
    codeParts: splitRoleEntityCode(link.role),
  }));

  const sortedExtendedLinks = sortBy(
    extendedLinks,
    el => targetTypeOrder[el.codeParts.target],
    el => linkRoleOrder[el.codeParts.role],
    el => el.value,
  );

  return sortedExtendedLinks.map(el => el.link);
};

export const sortCollectionLinks = (
  links: VerifiedLinkedCatalogPost[],
): VerifiedLinkedCatalogPost[] => {
  const hasNumber = (link: VerifiedLinkedCatalogPost) =>
    !isNullish(link.numberInSeries) && link.numberInSeries.length > 0;
  const numberedLinks = links.filter(l => hasNumber(l));
  const unnumberedLinks = links.filter(l => !hasNumber(l));

  const descByNameNumbered = sortBy(numberedLinks, l =>
    l.link.name.toLowerCase(),
  ).reverse();
  // Desc by number/range, then asc by name
  const digitsRegex = /^\d+/;
  const rangeRegex = /^\d+-\d+/;
  const descNumbered = sortBy(descByNameNumbered, l =>
    rangeRegex.test(l.numberInSeries ?? '')
      ? parseFloat((l.numberInSeries ?? '0').replace('-', '.'))
      : digitsRegex.test(l.numberInSeries ?? '')
        ? parseInt(l.numberInSeries ?? '0')
        : l.numberInSeries,
  ).reverse();

  const ascUnnumbered = sortBy(unnumberedLinks, l => l.link.name.toLowerCase());

  return [...descNumbered, ...ascUnnumbered];
};
