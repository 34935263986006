import {CodeList, CodeListId, CodeListRef} from 'api/types';
import {assertCodelistItem} from './assertCodelistItem';

const separateCodes: {[key in CodeListId]?: string[]} = {
  LANGUAGE: [CodeListRef.LANGUAGE.Bokmål, CodeListRef.LANGUAGE.Nynorsk],
  EXPRESSION_FORMAT: [
    CodeListRef.EXPRESSION_FORMAT.TEXT,
    CodeListRef.EXPRESSION_FORMAT.ILLUSTRATED_TEXT,
    CodeListRef.EXPRESSION_FORMAT.AUDIO,
  ],
};

const getZeroPaddedValue = (value: number): string => ('0' + value).slice(-2);

export const getCodeSortValue = (
  code: string | undefined,
  codelist: CodeList,
): string => {
  if (!code) {
    return '!!null';
  }

  const separateCodeIdx = separateCodes[codelist.id]?.findIndex(
    sc => sc === code,
  );
  if (separateCodeIdx !== undefined && separateCodeIdx !== -1) {
    return '!' + getZeroPaddedValue(separateCodeIdx);
  }

  return assertCodelistItem(code, codelist).value;
};
