import {Collection, DataLoadStatus} from 'api/types';
import {loadReducer} from 'services/utils';
import {LoadAction} from 'services/utils/redux/actions';

export type CollectionState = DataLoadStatus<Collection>;

export type CollectionAction_Load = {
  type: 'LOAD_COLLECTION';
} & LoadAction<Collection>;

export type CollectionAction_Saved = {
  type: 'SAVED_COLLECTION';
  payload: {data: Collection};
};

export type CollectionAction = CollectionAction_Load | CollectionAction_Saved;

export const CollectionDefaultState: CollectionState = {status: 'NotLoaded'};

export const collectionReducer = (
  state: CollectionState = CollectionDefaultState,
  action: CollectionAction,
): CollectionState => {
  switch (action.type) {
    case 'LOAD_COLLECTION': {
      return loadReducer(state, action);
    }
    case 'SAVED_COLLECTION': {
      return {status: 'Loaded', data: action.payload.data};
    }
    default: {
      return state;
    }
  }
};
