import {useCallback, useRef} from 'react';
import {useSelector} from 'react-redux';
import {CodeList} from 'api/types';
import {AppState} from 'store/types';
import {useCodelist} from 'services/codeLists/hooks';
import {getExpressionManifestationsLocalCodelist} from '../functions';

const useValueSelector = (
  expressionId: string,
  productFormOverride: CodeList,
  productForm: CodeList,
  productFormDetails: CodeList,
) => {
  const valueRef = useRef<CodeList | undefined>(undefined);
  return useCallback(
    (state: AppState): CodeList => {
      const value = getExpressionManifestationsLocalCodelist(
        state,
        expressionId,
        {
          productFormOverride,
          productForm,
          productFormDetails,
        },
      );
      if (
        !valueRef.current ||
        JSON.stringify(valueRef.current) !== JSON.stringify(value)
      ) {
        valueRef.current = value;
      }

      return valueRef.current;
    },
    [expressionId, productForm, productFormDetails, productFormOverride],
  );
};

/**
 * Hook to generate expression_manifestation codelist for given expression id
 */
export const useExpressionManifestationsLocalCodelist = (
  expressionId: string,
): CodeList => {
  const productFormOverrideCodelist = useCodelist('product_form_simple_values');
  const productFormCodelist = useCodelist('PRODUCT_FORM');
  const productFormDetailCodelist = useCodelist('PRODUCT_FORM_DETAIL');
  const selector = useValueSelector(
    expressionId,
    productFormOverrideCodelist,
    productFormCodelist,
    productFormDetailCodelist,
  );
  const value = useSelector(selector);

  return value;
};
