import {
  Reducer,
  applyMiddleware,
  combineReducers,
  legacy_createStore,
} from 'redux';
import {PersistConfig, persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {thunk} from 'redux-thunk';
import {authReducer} from 'services/auth';
import {codeListsReducer} from 'services/codeLists';
import {
  metadataEditReducer,
  metadataReducer,
} from 'services/data/metadata/reducer';
import {headReducer} from 'services/head';
import {settingsReducer} from 'services/settings';
import {thesaurusesReducer} from 'services/thesaurus';
import {usersReducer} from 'services/users';
import {AppActions, AppState, AppStateKey} from './types';

export const configureStore = () => {
  // Configure persistence
  const whitelist: Array<AppStateKey> = ['settings', 'users'];

  type AppStateNever = Record<AppStateKey, never>;

  const persistConfig: PersistConfig<Partial<AppStateNever>> = {
    key: 'redux-store',
    storage,
    whitelist,
  };

  // Set up reducers
  // - ensure consistency in naming and typing
  const reducers: {
    [key in AppStateKey]: (
      state: AppState[key] | undefined,
      action: AppActions[key],
    ) => AppState[key];
  } = {
    auth: authReducer,
    settings: settingsReducer,
    head: headReducer,
    users: usersReducer,
    codeLists: codeListsReducer,
    thesauruses: thesaurusesReducer,
    metadata: metadataReducer,
    metadataEdit: metadataEditReducer,
  };

  const rootReducer = combineReducers(reducers) as unknown as Reducer<
    Partial<AppStateNever>
  >;

  // Create persisted reducer
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  // Create store.
  const store = legacy_createStore(persistedReducer, applyMiddleware(thunk));

  const persistor = persistStore(store);

  return {store, persistor};
};
