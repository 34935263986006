import {FC, PropsWithChildren, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import {Concept} from 'types';
import {metadataEditSavedWorkSelector, metadataSelector} from 'services/data';
import {MetadataEditAction} from 'services/data/metadata/metadataEditActionTypes';
import {useSchemasCodelists} from 'schema/hooks/useSchemasCodelists';
import {getManifestationBasedModifier, useSchema} from 'schemas';
import {useSchemaModifiers} from 'schemas/hooks/useSchemaModifiers';

export const MetadataEditStateGate: FC<PropsWithChildren> = ({children}) => {
  const dispatch = useDispatch();
  const workSchema = useSchema(Concept.work);
  const expressionSchema = useSchema(Concept.expression);
  const manifestationSchema = useSchema(Concept.manifestation);

  const workSchemaModifiers = useSchemaModifiers(Concept.work);
  const expressionSchemaModifiers = useSchemaModifiers(Concept.expression);
  const manifestationSchemaModifiers = useSchemaModifiers(
    Concept.manifestation,
  );

  const schemasRef = useRef([
    workSchema,
    expressionSchema,
    manifestationSchema,
  ]);
  const codelists = useSchemasCodelists(schemasRef.current);

  const savedMetadata = useSelector(metadataSelector);
  const hasEditState = !!useSelector(metadataEditSavedWorkSelector);

  useEffect(() => {
    if (!hasEditState && savedMetadata.status === 'Loaded') {
      const data = assert(savedMetadata.data);

      const action: MetadataEditAction = {
        type: 'METADATAEDIT_LOADED',
        payload: {
          schemas: {
            work: {schema: workSchema, modifiers: workSchemaModifiers},
            expression: {
              schema: expressionSchema,
              modifiers: expressionSchemaModifiers,
            },
            manifestation: {
              schema: manifestationSchema,
              getDataBasedModifier: getManifestationBasedModifier,
              modifiers: manifestationSchemaModifiers,
            },
          },
          codelists,
          metadata: data,
        },
      };
      dispatch(action);
    }
  }, [
    codelists,
    dispatch,
    expressionSchema,
    expressionSchemaModifiers,
    hasEditState,
    manifestationSchema,
    manifestationSchemaModifiers,
    savedMetadata,
    workSchema,
    workSchemaModifiers,
  ]);

  return hasEditState ? <>{children}</> : null;
};
