import React from 'react';
import {SystemHealth} from 'api';
import {FlexBox, Spacer} from 'components';
import {ComponentHealth} from './ComponentHealth';
import {HealthSubtitle} from './HealthSubtitle';
import {QueueHealth} from './QueueHealth';

type Props = {
  data?: SystemHealth;
};

export const HealthDetails: React.FC<Props> = ({data}) => {
  const hasSyncInfo = (data?.syncComponents.length ?? 0) > 0;
  const hasQueueInfo = (data?.messageQueues.length ?? 0) > 0;
  const hasServiceInfo = (data?.services.length ?? 0) > 0;

  return data ? (
    <FlexBox>
      {hasSyncInfo ? (
        <FlexBox>
          <Spacer size={1} />
          <HealthSubtitle title="Import/eksport:" />
          <FlexBox ml={1}>
            {data.syncComponents.map(component => (
              <ComponentHealth key={component.name} data={component} />
            ))}
          </FlexBox>
        </FlexBox>
      ) : null}
      {hasQueueInfo ? (
        <FlexBox>
          <Spacer size={1} />
          <HealthSubtitle title="Køer:" />
          <FlexBox ml={1}>
            {data.messageQueues.map(queue => {
              return <QueueHealth key={queue.topic} data={queue} />;
            })}
          </FlexBox>
        </FlexBox>
      ) : null}
      {hasServiceInfo ? (
        <FlexBox>
          <Spacer size={1} />
          <HealthSubtitle title="Tjenester:" />
          <FlexBox ml={1}>
            {data.services.map(service => (
              <ComponentHealth key={service.name} data={service} />
            ))}
          </FlexBox>
        </FlexBox>
      ) : null}
    </FlexBox>
  ) : null;
};
