import {createContext, useContext, useMemo} from 'react';
import {assert} from 'assert-ts';
import {TableConfiguration} from '../types';
import {defaultTableConfig} from '../configuration/defaultTableConfig';

type TableConfigurationContextType = TableConfiguration;

const defaultContext: TableConfigurationContextType = defaultTableConfig;

export const TableConfigurationContext =
  createContext<TableConfigurationContextType>(defaultContext);

export const useTableConfigurationContextProviderValue = (
  configuration?: Partial<TableConfigurationContextType>,
): TableConfigurationContextType => {
  return useMemo(
    () => ({
      ...defaultContext,
      ...configuration,
      defaultColumns: [
        ...defaultContext.defaultColumns,
        ...(configuration?.defaultColumns || []),
      ],
    }),
    [configuration],
  );
};

export const useTableConfigurationContext =
  (): TableConfigurationContextType => {
    return assert(
      useContext(TableConfigurationContext),
      'useTableConfigurationContext: context expected',
    );
  };
