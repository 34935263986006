import {Part} from 'schemaDefinition/types';
import {PartDiffProps} from '../components/types';

export const resolveNestedDiff = (
  diff: PartDiffProps,
  /** Part to resolve nested diff for */
  part: Part<unknown>,
  updateForPart: boolean | undefined,
): PartDiffProps | undefined => {
  // If original, no need to resolve nested diff
  if (diff.mode === 'original') {
    return diff;
  }

  if (
    diff.outerCompare === 'fullValue' ||
    part.compare === 'fullValue' ||
    updateForPart
  ) {
    return {
      mode: 'updated',
      compareValue: diff.compareValue,
      outerCompare: 'fullValue',
    };
  }

  return diff;
};
