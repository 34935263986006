export const valueAsNumber = (value: unknown): number | undefined => {
  if (typeof value === 'number') {
    return isNaN(value) ? undefined : value;
  }

  if (typeof value === 'string') {
    return valueAsNumber(parseFloat(value));
  }

  return undefined;
};
