import React, {Fragment, useMemo} from 'react';
import {
  Grid,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {ShortcutCommand} from 'shortcuts/types';
import {isFeatureEnabled} from 'configuration';
import {useLocalization} from 'localization';
import {
  getGroupByCommand,
  shortcutCommandGroups,
  useShortcuts,
} from 'shortcuts';
import {Icon} from 'components';

const StyledTooltip = styled(
  ({className, children, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}>
      {children}
    </Tooltip>
  ),
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '400px',
  },
});

export const ShortcutsTooltip: React.FC = () => {
  const enabled = isFeatureEnabled('shortcutTooltip');
  const shortcutCommandMap = useShortcuts();
  const {tLoose, t} = useLocalization();

  const shortcuts = useMemo(() => {
    if (!enabled || !shortcutCommandMap) {
      return undefined;
    }

    const shortcuts: Array<{
      id: ShortcutCommand;
      label: string;
      command: string;
      group: string;
    }> = [];
    Object.keys(shortcutCommandMap).forEach(k => {
      const id = k as ShortcutCommand;
      shortcuts.push({
        id,
        label: tLoose(`shortcuts.${id}.label`),
        command: shortcutCommandMap[id] ?? '',
        group: getGroupByCommand(id),
      });
    });

    return shortcuts;
  }, [enabled, shortcutCommandMap, tLoose]);

  const GroupedCommands = useMemo(() => {
    if (!shortcuts) {
      return null;
    }

    return shortcutCommandGroups.map(group => {
      const groupCommands = shortcuts.filter(s => s.group === group);

      if (!groupCommands.length) {
        return null;
      }

      return (
        <Fragment key={group}>
          <Typography variant={'h3'} mt={2}>
            {t(`shortcuts.header.${group}.label`)}:
          </Typography>
          <Grid container>
            {groupCommands.map(s => (
              <Fragment key={s.id}>
                <Grid item xs={9}>
                  {s.label}
                </Grid>
                <Grid item xs={3}>
                  {s.command}
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Fragment>
      );
    });
  }, [shortcuts, t]);

  if (!GroupedCommands) {
    return null;
  }

  return (
    <StyledTooltip
      title={
        <>
          <Typography variant={'h2'}>
            {t(`shortcuts.header.shortcuts.label`)}:
          </Typography>
          {GroupedCommands}
          <ul style={{listStyleType: 'none', margin: 0, padding: 4}}></ul>
        </>
      }
      followCursor>
      <span
        style={{
          marginTop: '8px',
          cursor: 'help',
        }}>
        <Icon icon={'InfoCircle'} />
      </span>
    </StyledTooltip>
  );
};
