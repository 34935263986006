import {NodeIdsAncestorIdsMap, ThesaurusData, TreeNode} from '../types';

export const getAllNodesAncestorMap = (
  thesaurus: ThesaurusData,
): NodeIdsAncestorIdsMap => {
  const nodeAncestorsMap: NodeIdsAncestorIdsMap = {};
  const visit = (node: TreeNode, ancestorIds: string[]) => {
    nodeAncestorsMap[node.id] = ancestorIds;
    if (node.children) {
      node.children.forEach(child => visit(child, [...ancestorIds, node.id]));
    }
  };
  thesaurus.forEach(node => visit(node, []));
  return nodeAncestorsMap;
};
