//import {useSelector} from 'react-redux';
//import {AppState} from 'store/types';
import {DefaultShortcuts} from '../types';

//const shortcutsSelector = (state: AppState) => state.settings.shortcutOverrides;
export const useShortcuts = () => {
  //const shortcuts = useSelector(shortcutsSelector);

  return {...DefaultShortcuts};
};
