import React, {useCallback, useMemo, useState} from 'react';
import {TextFieldDiffProps} from 'components/fields/types';
import {FieldError, PartText, Valx} from 'schemaDefinition/types';
import {evaluateFieldRequired} from 'schemaDefinition/functions';
import {TextField} from 'components';
import {
  toFieldError,
  validateSingleTextValue,
} from 'schema/form/functions/validators';
import {useAdornments} from 'schema/form/hooks';
import {BaseFieldProps} from './types';
import {useDataFormContext} from '../../contexts';

type Props = BaseFieldProps & {
  part: PartText<Valx>;
};

export const Text: React.FC<Props> = ({
  part,
  value,
  setFieldValue,
  valuePath,
  label,
  placeholder,
  width,
  maxWidth,
  flex,
  focusableId,
  mode,
  diff,
  showWhenReadonlyAndEmpty,
  localScope,
  globalScope,
  relatedScope,
}) => {
  // const [localValue, setLocalValuel] = useState(value);

  const [visited, setVisited] = useState(false);
  const {prefix, suffix} = useAdornments(part, value);
  const handleSetVisited = useCallback(() => setVisited(true), []);
  const {showErrors} = useDataFormContext();

  const required = useMemo(() => {
    return evaluateFieldRequired(
      part.required,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      value,
    );
  }, [globalScope, part.required, relatedScope, localScope, value, valuePath]);

  const error = useMemo((): FieldError => {
    if (showErrors || visited) {
      const valid = validateSingleTextValue(part, {
        value,
        valuePath,
        localScope,
        globalScope,
        relatedScope,
      }).valid;
      return toFieldError(valid);
    }
    return false;
  }, [
    globalScope,
    part,
    relatedScope,
    localScope,
    showErrors,
    value,
    valuePath,
    visited,
  ]);
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      // Set empty values to null, to avoid sending empty strings to the backend
      const inputValue = event.target.value || null;
      setFieldValue(valuePath, inputValue, part);
    },
    [setFieldValue, valuePath, part],
  );

  return (
    <TextField
      name={valuePath}
      label={label}
      placeholder={placeholder}
      width={width}
      maxWidth={maxWidth}
      flex={flex}
      focusableId={focusableId}
      type="text"
      readonly={mode === 'read-only'}
      diff={
        typeof diff?.compareValue === 'string'
          ? (diff as TextFieldDiffProps)
          : undefined
      }
      showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
      required={required}
      value={value}
      prefix={prefix}
      suffix={suffix}
      onChange={handleChange}
      onBlur={handleSetVisited}
      error={error}
    />
  );
};
