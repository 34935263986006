import React, {useEffect} from 'react';
import {isEmptyValue, isNullish} from 'services/utils';
import {PartWithActions} from 'schema/components';
import {FormSimpleMode} from '../types';
import {DataFormPartProps} from './types';
import {useDataFormConfigurationContext, useDataFormContext} from '../contexts';
import {needsUpdatedFullValueContainer, resolveNestedDiff} from '../functions';
import {useResolvedDataFormPartConfiguration} from '../hooks/useResolvedDataFormPartConfiguration';
import {CompareThesaurus} from './CompareThesaurus';
import {DataFormCompare} from './DataFormCompare';
import {DataFormMultiValue} from './DataFormMultiValue';
import {DataFormNameVariantList} from './DataFormNameVariantList';
import {DataFormSingleValue} from './DataFormSingleValue';
import {DataFormSingleValueList} from './DataFormSingleValueList';
import {DataFormTable} from './DataFormTable';
import {DataFormThesaurusTerms} from './DataFormThesaurusTerms';
import {
  DataFormUpdatedFullValueContainer,
  DataFormUpdatedValueContainerType,
} from './DataFormUpdatedValueContainer';

export const DataFormPart: React.FC<DataFormPartProps> = dataFormPartProps => {
  const {
    part,
    valuePath,
    scopePath,
    usesGlobalScope,
    relatedScope,
    mode,
    diff,
    noLabel,
    useValue,
  } = dataFormPartProps;
  const {id, setDefaultFieldValue} = useDataFormContext();
  const {hideWhenEmpty, showWhenReadonlyAndEmpty} =
    useResolvedDataFormPartConfiguration(part);
  const {dataFormPartRenderers} = useDataFormConfigurationContext();

  const value =
    useValue(valuePath, id, part?.name ?? part?.role ?? 'unamed part') ?? null;

  // Set default if provided and value is nullish
  useEffect(() => {
    if (mode === 'edit' && part && part.type !== 'expand' && isNullish(value)) {
      setDefaultFieldValue(valuePath, part);
    }
    // On mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!part) {
    return null;
  }

  // Compare-mode: present original values readonly and values (changes) editable
  if (mode === 'compare') {
    return part.type === 'thesaurus' ? (
      <CompareThesaurus {...dataFormPartProps} part={part} />
    ) : (
      <DataFormCompare {...dataFormPartProps} />
    );
  }

  const adjustedMode: FormSimpleMode = part.readonly ? 'read-only' : mode;

  /**
   * Override rendering of schema part.
   */
  const CustomPartRenderer =
    part && part.name ? dataFormPartRenderers?.[part.name] : undefined;
  if (CustomPartRenderer) {
    return <CustomPartRenderer {...dataFormPartProps} />;
  }

  // Use updated (proposed) value container if diff.mode is 'updated' and compare is 'fullValue'
  const useUpdatedValueContainer =
    diff && needsUpdatedFullValueContainer(part, diff);

  const PartContainer: DataFormUpdatedValueContainerType =
    useUpdatedValueContainer
      ? DataFormUpdatedFullValueContainer
      : React.Fragment;
  const partContainerProps = useUpdatedValueContainer
    ? {valuePath, part}
    : undefined;
  const nestedDiff = diff
    ? resolveNestedDiff(diff, part, useUpdatedValueContainer)
    : undefined;

  if (
    isEmptyValue(value) &&
    // undefined ~= false, and should be shown as unchecked
    part.type !== 'bool'
  ) {
    if (hideWhenEmpty) {
      // Ony hide if no diff or also empty diff.compareValue
      if (isEmptyValue(diff?.compareValue)) {
        return null;
      }
    } else if (part.readonly && !showWhenReadonlyAndEmpty) {
      return null;
    }
  }

  // Input types handling cardinality internally
  // - codeltable, codelist|text
  if (part.type === 'codelist' || part.type === 'codelist|text') {
    return (
      <PartContainer {...partContainerProps}>
        <DataFormMultiValue
          part={part}
          useValue={useValue}
          valuePath={valuePath}
          scopePath={scopePath}
          usesGlobalScope={usesGlobalScope}
          relatedScope={relatedScope}
          noLabel={noLabel}
          mode={adjustedMode}
          diff={nestedDiff}
        />
      </PartContainer>
    );
  }
  // - nameVariant
  if (part.type === 'nameVariant') {
    return (
      <PartContainer {...partContainerProps}>
        <DataFormNameVariantList
          part={part}
          useValue={useValue}
          valuePath={valuePath}
          scopePath={scopePath}
          usesGlobalScope={usesGlobalScope}
          relatedScope={relatedScope}
          mode={adjustedMode}
          diff={nestedDiff}
          noLabel={noLabel}
        />
      </PartContainer>
    );
  }

  // - thesaurus
  if (part.type === 'thesaurus') {
    return (
      <PartContainer {...partContainerProps}>
        <DataFormThesaurusTerms
          part={part}
          useValue={useValue}
          valuePath={valuePath}
          scopePath={scopePath}
          usesGlobalScope={usesGlobalScope}
          relatedScope={relatedScope}
          mode={adjustedMode}
          diff={nestedDiff}
          noLabel={noLabel}
        />
      </PartContainer>
    );
  }

  // Single value part
  if (part.cardinality === undefined || part.cardinality === 'single') {
    return part.actions ? (
      <PartContainer {...partContainerProps}>
        <PartWithActions part={part} value={value}>
          <DataFormSingleValue
            part={part}
            useValue={useValue}
            valuePath={valuePath}
            scopePath={scopePath}
            usesGlobalScope={usesGlobalScope}
            relatedScope={relatedScope}
            mode={adjustedMode}
            diff={nestedDiff}
            noLabel={noLabel}
          />
        </PartWithActions>
      </PartContainer>
    ) : (
      <PartContainer {...partContainerProps}>
        <DataFormSingleValue
          part={part}
          useValue={useValue}
          valuePath={valuePath}
          scopePath={scopePath}
          usesGlobalScope={usesGlobalScope}
          relatedScope={relatedScope}
          mode={adjustedMode}
          diff={nestedDiff}
          noLabel={noLabel}
        />
      </PartContainer>
    );
  }

  const noMove = part.type === 'linkedLiterary';

  // TableView of Parttype 'schema' with variant 'table'
  if (part.type === 'schema' && part.variant === 'table') {
    return (
      <PartContainer {...partContainerProps}>
        <DataFormTable
          part={part}
          useValue={useValue}
          valuePath={valuePath}
          scopePath={scopePath}
          usesGlobalScope={usesGlobalScope}
          relatedScope={relatedScope}
          mode={adjustedMode}
          diff={nestedDiff}
        />
      </PartContainer>
    );
  }

  // Multiple single-value part
  return (
    <PartContainer {...partContainerProps}>
      <DataFormSingleValueList
        part={part}
        useValue={useValue}
        valuePath={valuePath}
        scopePath={scopePath}
        usesGlobalScope={usesGlobalScope}
        relatedScope={relatedScope}
        mode={adjustedMode}
        diff={nestedDiff}
        noLabel={noLabel}
        noMove={noMove}
      />
    </PartContainer>
  );
};
