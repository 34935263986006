import {useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import isEqual from 'lodash/isEqual';
import {
  ExpressionImportProps,
  ManifestationImportProps,
  WorkImportProps,
} from '../types';
import {
  expressionExportsSelectorById,
  manifestationExportsSelectorById,
  workExportsSelector,
} from '../selectors';
import {useCreateSelectorById} from './useCreateSelectorById';

/**
 * Returns either WorkImportProps, ExpressionImportProps, or ManifestationImportProps
 * But does not crash if manifestation or expression is not found.
 */
export const useChangeRequestImportProps = (
  manifestationId?: string,
  expressionId?: string,
): ManifestationImportProps | ExpressionImportProps | WorkImportProps => {
  // Work props
  const workExports = useSelector(workExportsSelector);

  // Expression props
  const expressionExportsSelector = useCreateSelectorById(
    expressionId || 'DummyExpressionId',
    expressionExportsSelectorById,
  );
  const expressionExports = useSelector(expressionExportsSelector);

  // Manifestation props
  const manifestationExportsSelector = useCreateSelectorById(
    manifestationId || 'DummyManifestationId',
    manifestationExportsSelectorById,
  );
  const manifestationExports = useSelector(manifestationExportsSelector);

  const exportsRef = useRef<
    ManifestationImportProps | ExpressionImportProps | WorkImportProps
  >();

  return useMemo(() => {
    const newExports = {
      work: workExports,
      expression: expressionExports,
      manifestation: manifestationExports,
    };

    if (!isEqual(newExports, exportsRef.current)) {
      exportsRef.current = newExports;
    }

    return assert(exportsRef.current);
  }, [expressionExports, manifestationExports, workExports]);
};
