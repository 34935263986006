import {useMemo} from 'react';
import {
  ContentLabelWithLayout,
  CorporationNameVariantFieldLayout,
} from 'components/fields/types';
import {mapValues} from 'services/utils';
import {CorporateNameVariantExtraField as ExtraField} from '../components/types';
import {useLabelsAndPlaceholders} from './useLabelsAndPlaceholders';

export const useCorporationNameVariantLabelsAndPlaceholders = (
  extraField: ExtraField | undefined,
  fieldLayout: CorporationNameVariantFieldLayout,
): {
  contentLabels: ContentLabelWithLayout[];
  fieldLayoutWithPlaceholders: CorporationNameVariantFieldLayout;
} => {
  const labelsAndPlaceholders = useLabelsAndPlaceholders([
    'name',
    'subsidiary',
    'description',
  ]);

  return useMemo(() => {
    const requiredFields = ['name'];
    const labelKeys: (keyof CorporationNameVariantFieldLayout)[] = ['name'];
    if (extraField) labelKeys.push(extraField);
    labelKeys.push('mainForm');

    return {
      // Content labels only for selected fields
      contentLabels: labelKeys.map(key => ({
        ...fieldLayout[key],
        label:
          key !== 'mainForm' ? labelsAndPlaceholders[key].label : undefined,
        required: requiredFields.includes(key),
      })),
      fieldLayoutWithPlaceholders: mapValues(fieldLayout, (value, key) => ({
        ...value,
        placeholder:
          key !== 'mainForm'
            ? labelsAndPlaceholders[key].placeholder
            : undefined,
      })),
    };
  }, [extraField, fieldLayout, labelsAndPlaceholders]);
};
