import React, {useCallback, useMemo, useState} from 'react';
import {Grid, SxProps} from '@mui/material';
import {Box} from '@mui/system';
import {Note} from 'services/data/metadata/types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {getFirstName} from 'services/users/functions';
import {useHandleDelayedCall} from 'services/utils';
import {
  Avatar,
  Card,
  ControlledIconButton,
  Layout,
  NoteInput,
  Spacer,
  Text,
  useAlertDialog,
} from 'components';

type Props = {
  note: Note;
  onUpdateNote: (id: string, note: string) => Promise<void>;
  onRemoveNote: (id: string) => Promise<void>;
};

type NoteMode = 'read-only' | 'edit';

export const NoteCard: React.FC<Props> = ({
  note: userNote,
  onUpdateNote,
  onRemoveNote,
}) => {
  const {t, formatDate} = useLocalization();
  const [message, setMessage] = useState(userNote.note.message);
  const [mode, setMode] = useState<NoteMode>('read-only');
  const {note, user} = userNote;

  const handleEdit = useCallback(() => {
    return Promise.resolve(setMode(() => 'edit'));
  }, []);

  const handleReset = useCallback(() => {
    return Promise.resolve().then(() => {
      setMode(() => 'read-only');
      setMessage(() => note.message);
    });
  }, [note.message]);

  const handleUpdate = useCallback(
    () =>
      onUpdateNote(note.id, message)
        .then(() => setMode(() => 'read-only'))
        .catch(), // Remain in edit-mode to allow retry
    [message, note.id, onUpdateNote],
  );

  const handleUpdateDelayed = useHandleDelayedCall(handleUpdate);

  const handleRemove = useCallback(
    () => onRemoveNote(note.id).catch(() => setMode('read-only')), // Cancel any editing before remove
    [note.id, onRemoveNote],
  );

  const handleRemoveDelayed = useHandleDelayedCall(handleRemove);

  const confirmRemoveProps = useMemo(
    () => ({
      title: t('page.metadata.manifestation.notes.confirmRemove.title'),
      body: t('page.metadata.manifestation.notes.confirmRemove.body'),
      cancelTitle: t('general.cancel'),
      okTitle: t('general.ok'),
      onConfirm: handleRemoveDelayed.handleCall,
      onCancel: handleReset,
    }),
    [handleRemoveDelayed.handleCall, handleReset, t],
  );

  const {AlertDialog: ConfirmRemoveDialog, pleaseConfirm} =
    useAlertDialog(confirmRemoveProps);

  const isCalling =
    handleRemoveDelayed.isCalling || handleUpdateDelayed.isCalling;

  const dateModifiedSx: SxProps = {
    opacity: 0.4,
  };
  const dateCreatedSx: SxProps = {
    ...dateModifiedSx,
    ...{
      textDecoration: 'line-through',
    },
  };

  return (
    <Card colorx={ColorPalette.primary.white}>
      <Card.Header minHeight={0}>
        <Layout
          horizontal
          flex={1}
          alignItems={'center'}
          justifyContent={'space-between'}>
          <Box component={Grid} container spacing={1}>
            <Box
              component={Grid}
              item
              xs={6}
              display={'flex'}
              alignItems={'center'}>
              <Avatar src={user.picture} />
              <Spacer size={1} />
              <Text variant="subtitle1" data-sentry-mask>
                {getFirstName(user)}
              </Text>
            </Box>
            <Box component={Grid} item xs={6}>
              {note.created !== note.lastModified ? (
                <Text variant="subtitle1" sx={dateCreatedSx}>
                  {formatDate(note.created, 'short', true)}
                </Text>
              ) : null}
              <Text variant="subtitle1" sx={dateModifiedSx}>
                {formatDate(note.lastModified, 'short', true)}
              </Text>
            </Box>
          </Box>
        </Layout>
      </Card.Header>
      <Card.Content>
        {mode === 'read-only' ? (
          <Text variant="body2" keepWhitespace>
            {note.message}
          </Text>
        ) : (
          <NoteInput
            key={note.message}
            disablePadding
            value={message}
            onChange={setMessage}
            onReset={handleReset}
            onSubmit={handleUpdateDelayed.handleCall}
            isSubmitting={isCalling}
            isDelayed={handleRemoveDelayed.isDelayed}
          />
        )}
        <Layout horizontal adjustRight>
          <ControlledIconButton
            icon={'Delete'}
            size="small"
            onClick={pleaseConfirm}
            isCalling={isCalling}
            isDelayed={handleRemoveDelayed.isDelayed}
          />
          {mode === 'read-only' ? (
            <ControlledIconButton
              icon={'Edit'}
              size="small"
              onClick={handleEdit}
              isCalling={isCalling}
            />
          ) : (
            <ControlledIconButton
              icon={'CancelEdit'}
              size="small"
              onClick={handleReset}
              isCalling={isCalling}
            />
          )}
          {mode === 'edit' ? (
            <ControlledIconButton
              icon={'Send'}
              size="small"
              onClick={handleUpdateDelayed.handleCall}
              isCalling={isCalling}
              isDelayed={handleUpdateDelayed.isDelayed}
            />
          ) : null}
        </Layout>
        <ConfirmRemoveDialog />
      </Card.Content>
    </Card>
  );
};
