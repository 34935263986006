import {ExpressionV4} from 'api/types';
import {ExpressionExportProps} from 'services/data/metadata/types';

export const getExpressionExportProps = (
  expression: ExpressionV4,
  // To avoid nullish expressionFormat if previous value, e.g. when editing on autocomplete field
  previous?: ExpressionExportProps,
): ExpressionExportProps => {
  return {
    expressionFormat:
      expression?.expressionFormat ?? previous?.expressionFormat,
  };
};
