import React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

type Props = Pick<CircularProgressProps, 'color'> & {
  size?: CircularProgressProps['size'] | 'small' | 'medium';
};

export const ActivityIndicator: React.FC<Props> = ({size, ...props}) => {
  const progressSize = size === 'small' ? 16 : size === 'medium' ? 24 : size;
  return <CircularProgress size={progressSize} {...props} />;
};
