import {useCallback} from 'react';
import {useStore} from 'react-redux';
import {produce} from 'immer';
import set from 'lodash/set';
import {DataValue} from 'schemaDefinition/types';
import {AppState} from 'store/types';
import {ManifestationV4} from 'api';
import {MetadataEditAction} from '../metadataEditActionTypes';

export const useSetManifestationEditValue = (entityId: string) => {
  const {getState, dispatch} = useStore<AppState, MetadataEditAction>();
  return useCallback(
    (localPath: string, value: DataValue) => {
      const manifestation = ((
        getState().metadataEdit.metadata?.manifestations ?? []
      ).find(e => e.id === entityId) ?? {}) as ManifestationV4;
      const newManifestation = produce(manifestation, draft => {
        set(draft, localPath, value);
      });

      const action: MetadataEditAction = {
        type: 'METADATAEDIT_MANIFESTATION_EDITED',
        payload: newManifestation,
      };

      dispatch(action);
    },
    [dispatch, entityId, getState],
  );
};
