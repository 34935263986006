import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {AutocompleteRenderGetTagProps} from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
// import {useRef} from 'react';
import {Code} from 'schemaDefinition/types';
import {getCodeLabel} from '../functions';
import {useCodeInputContext} from './contexts';

// import {useEventListener} from 'usehooks-ts';

type Props = {
  option: Code;
  showCode?: boolean;
  index: number;
  getTagProps: AutocompleteRenderGetTagProps;
  onMove: (fromIndex: number, toIndex: number) => void;
  // onDelete: (option: Code) => void;
};

const withTabIndex = false;

export const DraggableChip: React.FC<Props> = ({
  option,
  showCode,
  index,
  getTagProps,
  // onMove,
}) => {
  const {
    state: {focusItemCode},
  } = useCodeInputContext();
  const sortable = useSortable({id: option.code});
  const {
    attributes: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      tabIndex,
      ...restAttributes
    },
    listeners,
    setNodeRef,
    transform,
    transition,
    active,
  } = sortable;
  const {
    className,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tabIndex: ignoreMuiTabIndex,
    ...tagProps
  } = getTagProps({index});

  // TODO: Optimize

  const isBeingDragged = active && active.id === option.code;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const adjustTransform = transform
    ? {
        ...transform,
        scaleX: 1,
      }
    : undefined;

  const style = adjustTransform
    ? {
        transform: CSS.Transform.toString(adjustTransform),
        transition,
        // zIndex: 1,
      }
    : undefined;

  let classNames = isBeingDragged
    ? className + ' Ebba-draggable-active'
    : className;

  classNames += option.code === focusItemCode ? ' Mui-focusVisible' : '';

  const actualTabIndex = withTabIndex ? tabIndex : -1;

  return (
    <div ref={setNodeRef}>
      <Chip
        // ref={chipRef}
        // key={option.code}
        className={classNames}
        style={style}
        {...listeners}
        {...restAttributes}
        label={getCodeLabel(option, showCode)}
        size="small"
        {...tagProps}
        tabIndex={actualTabIndex}
      />
    </div>
  );
};
