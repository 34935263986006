import React, {useMemo} from 'react';
import {IconButton, styled} from '@mui/material';
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
  PickersShortcutsItem,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs';
import {Dayjs} from 'dayjs';
import {UXUnit} from 'theme/settings';
import {CalendarIcon, CloseIcon} from '../icons';
import {FlexBox} from '../layout';
import {Label} from '../text';

export type DateRangeValue = [Dayjs | null, Dayjs | null];

type Props = {
  name?: string;
  label?: string;
  placeholder?: string;
  noOptionsPlaceholder?: string;
  // Only use undefined if component should be uncontrolled
  value: DateRangeValue | undefined;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  onChange: (value: DateRangeValue | undefined) => void;
  onBlur?: () => void;
  shortcuts?: PickersShortcutsItem<DateRange<Dayjs>>[];
};

const StyledDatePicker = styled(DateRangePicker<Dayjs | null>)(() => ({
  '.MuiInputBase-root': {
    borderRadius: UXUnit,
  },
}));

export const DateRangeField: React.FC<Props> = ({
  name,
  placeholder,
  required,
  label,
  value,
  disabled = false,
  error = false,
  onChange,
  shortcuts,
}) => {
  /**
   * Display CalendarIcon if empty.
   * Display ClearIcon if not empty.
   */
  const EndAdornment = useMemo(() => {
    if (!value || !value[0] || !value[1]) {
      return (
        <IconButton sx={{p: 0.5}}>
          <CalendarIcon />
        </IconButton>
      );
    }
    return (
      <IconButton sx={{p: 0.5}} onClick={() => onChange(undefined)}>
        <CloseIcon />
      </IconButton>
    );
  }, [onChange, value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'nb'}>
      <FlexBox>
        <Label>{label}</Label>
        <StyledDatePicker
          disabled={disabled}
          onAccept={onChange}
          value={value}
          calendars={2}
          slots={{
            field: SingleInputDateRangeField,
          }}
          slotProps={{
            shortcuts: {
              items: shortcuts,
            },
            actionBar: {
              actions: [],
            },
            textField: {
              InputProps: {endAdornment: EndAdornment},
              variant: 'outlined',
              size: 'small',
              placeholder,
              name,
              required,
              error,
            },
          }}
        />
      </FlexBox>
    </LocalizationProvider>
  );
};
