import React, {useEffect, useRef} from 'react';
import {Box} from '@mui/material';
import {TextChangesProps} from 'components/text/types';
import {Browser, useIsBrowser} from 'services/utils';
import {Layout} from 'components/layout';
import {TextChanges} from 'components/text/TextChanges';
import {TextFieldProps} from './types';
import {FieldLabel} from './FieldLabel';
import {MuiTextFieldStyled} from './muiStyled';

type TextFieldWithChangesProps = Omit<TextFieldProps, 'InputProps'> & {
  InputProps: TextFieldProps['InputProps'] & {
    id: string;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
  };
  className?: string;
};

const browsersWithInputScroll: Browser[] = ['chrome', 'firefox', 'edge'];

/**
 * Variant of TextField that shows changes when in diff mode, otherwise null
 * Should not be used directly, but through TextField
 */
export const TextFieldWithChanges: React.FC<TextFieldWithChangesProps> = ({
  label,
  placeholder,
  name,
  type,
  width,
  maxWidth,
  flex,
  alignItems,
  required = false,
  readonly = false,
  value,
  className,
  InputProps,
  prefix,
  suffix,
  diff,
  error = false,
  onFocus,
  onBlur,
  ...props
}) => {
  const hasInputScroll = useIsBrowser(browsersWithInputScroll);
  const [hasFocus, setHasFocus] = React.useState(false);

  const textChangesProps: TextChangesProps | undefined = diff
    ? {
        original:
          diff.mode === 'original' ? (value as string) : diff.compareValue,
        updated:
          diff.mode === 'original' ? diff.compareValue : (value as string),
        diffMode: diff.mode,
      }
    : undefined;

  const inputRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLElement>(null);
  const overlayContainerRef = useRef<HTMLElement>(null);
  const [offset, setOffset] = React.useState({top: 0, left: 0});

  useEffect(() => {
    const calculatePosition = () => {
      if (inputRef.current && containerRef.current) {
        const inputRect = inputRef.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();

        const relativeTop = inputRect.top - containerRect.top;
        const relativeLeft = inputRect.left - containerRect.left;

        setOffset({top: relativeTop, left: relativeLeft});
      }
    };

    calculatePosition();

    if (!hasInputScroll) {
      return;
    }

    const scrollListener = () => {
      overlayContainerRef.current?.scroll(inputRef.current?.scrollLeft ?? 0, 0);
    };
    const element = inputRef.current;

    element?.addEventListener('scroll', scrollListener);

    return () => {
      element?.removeEventListener('scroll', scrollListener);
    };
  }, [InputProps.id, hasInputScroll]);

  return (
    <Layout
      width={width}
      maxWidth={maxWidth}
      flex={flex}
      alignItems={alignItems}>
      {label && (
        <FieldLabel
          label={label}
          required={required}
          error={error}
          htmlFor={InputProps.id}
        />
      )}
      {textChangesProps ? (
        <Box position={'relative'} ref={containerRef}>
          <MuiTextFieldStyled
            required={required !== false}
            size="small"
            fullWidth
            id={name}
            name={name}
            placeholder={placeholder}
            value={(value ?? '') as string | number}
            readonly={readonly}
            type={type}
            error={error !== false}
            InputProps={{
              ...InputProps,
              style: {overflow: 'hidden'},
              inputRef: inputRef,
            }}
            onFocus={e => {
              setHasFocus(true);
              onFocus?.(e);
            }}
            onBlur={e => {
              setHasFocus(false);
              onBlur?.(e);
            }}
            {...props}
            className={className}
            sx={
              prefix
                ? {['.MuiOutlinedInput-root']: {paddingLeft: '8px'}}
                : undefined
            }></MuiTextFieldStyled>
          {hasInputScroll || !hasFocus ? (
            <Box
              position={'absolute'}
              width={'100%'}
              pl={prefix ? 0 : 1.75}
              pr={suffix ? 0 : 1.75}
              py={1}
              {...offset}
              sx={{pointerEvents: 'none', color: 'transparent'}}>
              <Box
                ref={overlayContainerRef}
                sx={{
                  textWrap: 'nowrap',
                  overflow: 'hidden',
                  marginRight: `${offset.left}px`,
                }}>
                <TextChanges {...textChangesProps} />
              </Box>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Layout>
  );
};
