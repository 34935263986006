import React, {useMemo, useState} from 'react';
import Chip from '@mui/material/Chip';
import {PublisherNameVariantFieldLayout} from 'components/fields/types';
import {
  FieldError,
  NameVariantValue,
  PartNameVariant,
  Valx,
} from 'schemaDefinition/types';
import {ColorPalette} from 'theme';
import {assertAsNameVariantValue} from 'schemaDefinition/functions';
import {FieldLayout, Layout, TextField} from 'components';
import {toFieldError} from 'schema/form/functions/validators';
import {BaseFieldProps} from './types';
import {useDataFormContext} from '../../contexts';
import {useCustomValidator} from '../../hooks';

type Props = BaseFieldProps & {
  part: PartNameVariant<Valx>;
  isMainForm: boolean;
  layout: PublisherNameVariantFieldLayout;
};

const makeNameVariantValue = (
  id: string | null | undefined,
  name: string | null | undefined,
): NameVariantValue | null => {
  if (![name].some(v => typeof v === 'string')) {
    return null;
  }

  const value: NameVariantValue = {};

  if (id) {
    value.id = id;
  }

  if (typeof name === 'string') {
    value.name = name;
  }
  return value;
};

export const PublisherNameVariant: React.FC<Props> = ({
  part,
  value,
  setFieldValue,
  isMainForm,
  valuePath,
  mode,
  showWhenReadonlyAndEmpty,
  focusableId,
  layout,
}) => {
  const {showErrors} = useDataFormContext();
  const {id, name} = useMemo(() => {
    const nameVariantValue = assertAsNameVariantValue(value, valuePath);
    return {
      id: null,
      name: null,
      ...nameVariantValue,
    };
  }, [value, valuePath]);
  const validate = useCustomValidator(part);
  const [visited, setVisited] = useState<{name?: boolean}>({});
  const error = useMemo((): FieldError => {
    if (showErrors || visited.name) {
      const valid = validate ? validate(part, value) : 'valid';
      return toFieldError(valid);
    }

    return false;
  }, [part, showErrors, validate, value, visited.name]);

  return (
    <Layout horizontal>
      <FieldLayout horizontal>
        <TextField
          type="text"
          name="name"
          focusableId={focusableId}
          placeholder={layout.name.placeholder}
          readonly={mode === 'read-only'}
          showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
          value={name}
          width={layout.name.width}
          flex={layout.name.flex}
          onChange={e => {
            const newValue = e.target.value;
            setFieldValue(valuePath, makeNameVariantValue(id, newValue));
          }}
          onBlur={() => setVisited(v => ({...v, name: true}))}
          error={error}
        />

        <Layout hidden={!isMainForm} sx={layout.mainForm} adjustCenter>
          <Chip
            size="small"
            label={'Hovedform'}
            sx={{background: ColorPalette.spot.urgent}}
          />
        </Layout>
      </FieldLayout>
    </Layout>
  );
};
