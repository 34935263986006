import {LinkAction} from 'types';
import {
  NameForm,
  NameVariantValue,
  VerifiedLinkedValueLink,
} from 'schemaDefinition/types';
import {isLinkedToNameVariant} from 'services/data/agent/functions';

export const determineAgentLinkAction = (
  currentLink: VerifiedLinkedValueLink | undefined,
  agentId: string,
  nameVariant: NameVariantValue,
  nameForm: NameForm,
): LinkAction => {
  const action = !currentLink?.entityId
    ? 'link'
    : isLinkedToNameVariant(currentLink, agentId, nameVariant, nameForm)
    ? 'unlink'
    : 'changeLink';

  return action;
};
