import {ExternalSuggestions} from 'api/types';
import {TypedSchema} from 'schemaDefinition/types';

export const externalSuggestionsSchema: TypedSchema<ExternalSuggestions> = {
  name: 'externalSuggestions',
  parts: [
    {
      name: 'intellectualLevel',
      type: 'text',
      cardinality: 'multiple',
    },
    {
      name: 'seriesTitles',
      labelKey: 'seriesTitles.suggestion',
      type: 'text',
      cardinality: 'multiple',
    },
    {
      name: 'seriesNumbers',
      labelKey: 'seriesNumbers.suggestion',
      type: 'text',
      cardinality: 'multiple',
    },
  ],
};
