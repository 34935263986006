import {useNavigate} from 'react-router-dom';
import {FullManifestation} from 'api/types';
import {getManifestationByEan, getManifestationByIsbn} from 'api';
import {useGetTokens} from 'services/auth';

export type SearchError = 'notFound' | 'invalidLength';

export type GlobalSearch = {
  search: (
    query: string,
  ) => Promise<FullManifestation | SearchError> | SearchError | undefined;
  navigateToManifestation: (manifestation: FullManifestation) => void;
};

export const useGlobalSearch = (): GlobalSearch => {
  const navigate = useNavigate();

  const getTokens = useGetTokens();

  const navigateToManifestation = (manifestation: FullManifestation) => {
    navigate(
      `/metadata/${manifestation.workId}?manifestationId=${manifestation.id}&isbn=${manifestation.isbn}`,
    );
  };

  const search = (
    query: string,
  ): Promise<FullManifestation | SearchError> | SearchError | undefined => {
    const cleanedQuery = query.trim().replaceAll('-', '');
    if (cleanedQuery.length !== 13) {
      return 'invalidLength';
    }

    return getManifestationByIsbn(cleanedQuery, getTokens).then(
      manifestation => {
        if (manifestation) {
          return manifestation;
        }

        return getManifestationByEan(cleanedQuery, getTokens).then(
          manifestation => {
            if (manifestation) {
              return manifestation;
            }

            return 'notFound';
          },
        );
      },
    );
  };

  return {
    search,
    navigateToManifestation,
  };
};
