export class Builder {
  public value: string | undefined;

  constructor(initial: string | number | undefined | null) {
    this.value = initial?.toString() || undefined;
  }

  public add(separator: string, val: string | number | undefined | null) {
    if (val === undefined || val === null || val === '') {
      return this;
    }
    if (this.value === undefined) {
      this.value = val.toString();
    } else {
      this.value += separator + val.toString();
    }
    return this;
  }

  /**
   * Only add value if not already present in current value
   * @param separator
   * @param val
   * @returns
   */
  public addUnique(separator: string, val: string | number | undefined | null) {
    if (
      val === undefined ||
      val === null ||
      val === '' ||
      (this.value ?? '')
        .toLocaleLowerCase()
        .includes(val.toString().toLocaleLowerCase())
    ) {
      return this;
    }
    if (this.value === undefined) {
      this.value = val.toString();
    } else {
      this.value += separator + val.toString();
    }
    return this;
  }
}
