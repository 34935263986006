import {PreviewConfiguration} from 'schema/types';
import {CollectionTitles} from '../../renderers';

export const collectionPreviewConfig: PreviewConfiguration = {
  layout: {
    linkedValuesByRoles: true,
  },
  partValueRenderers: {
    titles: CollectionTitles,
  },
};
