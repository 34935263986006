import {assert} from 'assert-ts';
import {
  CodeListMap,
  ExpressionV4,
  ManifestationStatus,
  WorkV4,
} from 'api/types';
import {ValidationResult} from 'schema/form/functions/validators/types';
import {getRestrictedManifestationCodelists} from 'schemas/codelistRestrictors';
import {Metadata, MetadataSchemas, MetadataStatuses} from '../types';
import {validateEntity} from './validateEntity';

export const validateManifestation = (
  manifestationId: string,
  relatedScope: {work: WorkV4; expression: ExpressionV4},
  savedMetadata: Pick<Metadata, 'manifestations'>,
  metadata: Pick<Metadata, 'manifestations'>,
  schemas: MetadataSchemas,
  codelists: CodeListMap,
  statuses: MetadataStatuses,
): {validation: ValidationResult; status: ManifestationStatus} => {
  const savedManifestation = assert(
    savedMetadata.manifestations.find(m => m.id === manifestationId),
    'validateManifestation: saved manifestation not found',
  );
  const manifestation = assert(
    metadata.manifestations.find(m => m.id === manifestationId),
    'validateManifestation: manifestation not found',
  );

  const manifestaitonCodelists = getRestrictedManifestationCodelists(
    {...relatedScope, manifestation},
    codelists,
  );

  return validateEntity(
    savedManifestation,
    manifestation,
    relatedScope,
    relatedScope.work.type,
    statuses[manifestation.id],
    schemas.manifestation.schema,
    schemas.manifestation.getDataBasedModifier,
    schemas.manifestation.modifiers,
    manifestaitonCodelists,
  );
};
