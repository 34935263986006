import React, {ReactNode, useMemo} from 'react';
import {Box} from '@mui/material';
import Grid from '@mui/material/Grid';
import {GridProps} from '@mui/material/Grid/Grid';
import {TextProps} from 'components/text/types';
import {ColorPalette} from 'theme';
import {Text} from 'components';

type Props = GridProps & {
  title?: string;
  textProps?: TextProps;
  children?: ReactNode;
};

export const SceneTitle: React.FC<Props> = ({
  title,
  children,
  textProps,
  ...gridProps
}) => {
  const titleComponent = useMemo(() => {
    return title ? (
      <Box
        component={Grid}
        container
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Grid
          item
          {...gridProps}
          sx={{
            overflow: 'hidden',
          }}>
          <Text
            data-cy={'scene-title'}
            sx={
              textProps?.sx
                ? {color: ColorPalette.textPrimary, ...textProps.sx}
                : {color: ColorPalette.textPrimary}
            }
            component={textProps?.component}
            variant={textProps?.variant ?? 'h1'}
            noWrap>
            {title}
          </Text>
        </Grid>
        {children}
      </Box>
    ) : null;
  }, [
    title,
    gridProps,
    textProps?.sx,
    textProps?.component,
    textProps?.variant,
    children,
  ]);

  return (
    <Box
      component={Grid}
      container
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}>
      <Grid item xs={12}>
        {titleComponent}
      </Grid>
    </Box>
  );
};
