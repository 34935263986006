import {YearOrDateValue} from 'schemaDefinition/types';

/**
 * Takes input from year or date field, and converts it to a YearOrDateValue object.
 */
export const makeYearOrDateValue = (
  year: number | null | undefined,
  date: string | null | undefined,
  bce: boolean | null | undefined,
  isApproximate: boolean | null | undefined,
): YearOrDateValue | null => {
  if (
    typeof year !== 'number' &&
    typeof date !== 'string' &&
    !isApproximate &&
    !bce
  ) {
    return null;
  }

  const value: YearOrDateValue = {};
  if (typeof year === 'number') {
    value.year = year;
  }

  if (typeof date === 'string') {
    value.date = date;
  }

  if (typeof bce === 'boolean') {
    value.bce = bce;
  }

  if (typeof isApproximate === 'boolean') {
    value.isApproximate = isApproximate;
  }

  return value;
};
