/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {useCallback} from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import {ThesaurusNode} from 'api/types';
import {SelectActionType} from 'feature/editThesaurus/types';
import {ShowThesaurusCode} from 'schemaDefinition/types';
import {NodeAndAncestors} from 'services/thesaurus/types';
import {ColorPalette} from 'theme';
import {FlexBox, Layout} from 'components/layout';
import {Text} from 'components/text';
import {ThesaurusValue} from './ThesaurusValue';

type Props = {
  category: ThesaurusNode;
  nodes: NodeAndAncestors[];
  readonly?: boolean;
  showCode?: ShowThesaurusCode;
  highlight: string[];
  highlightType?: SelectActionType;
  onDelete?: (nodeId: string) => void;
  onSelect?: (node: ThesaurusNode) => void;
  onMove?: (oldIndex: number, newIndex: number) => void;
};

const grid = 8;

const labelSx = {
  mb: 1,
  textTransform: 'none',
  color: ColorPalette.textSecondary,
} as const;

const getListStyle = (_isDraggingOver: boolean) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  // padding: grid,
  overflow: 'auto',
  margin: `-${grid}px 0 0 0`,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getItemStyle = (_isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // padding: grid * 2,
  margin: `${grid}px ${grid}px 0 0`,

  // change background colour if dragging
  // background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

export const ThesaurusValueCategory: React.FC<Props> = ({
  category,
  nodes,
  readonly,
  showCode,
  highlight,
  highlightType,
  onDelete,
  onSelect,
  onMove,
}) => {
  const handleOnDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      if (onMove) {
        onMove(result.source.index, result.destination.index);
      }
    },
    [onMove],
  );

  return readonly ? (
    <Layout adjustLeft pt={2}>
      <Text variant="label" sx={labelSx}>
        {category.label}
      </Text>
      <FlexBox gap={1}>
        {nodes.map(n => (
          <ThesaurusValue
            key={n.node.id}
            node={n.node}
            showCode={showCode}
            highlight={false}
            highlightType={highlightType}
            canMove={false}
          />
        ))}
      </FlexBox>
    </Layout>
  ) : (
    <Layout adjustLeft pt={2}>
      <Text variant="label" sx={labelSx}>
        {category.label}
      </Text>
      {/* TODO: Fix typing */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {/* TODO: Fix typing */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            // TODO: Fix typing
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <FlexBox
              ref={provided.innerRef}
              left
              {...provided.droppableProps}
              style={getListStyle(snapshot.isDraggingOver)}>
              {nodes.map((n, index) => (
                //TODO: Fix typing
                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                <Draggable
                  key={n.node.id}
                  draggableId={n.node.id}
                  index={index}>
                  {(provided, snapshot) => {
                    const highlightValue = highlight.includes(n.node.id);
                    return (
                      <div
                        key={n.node.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}>
                        <ThesaurusValue
                          node={n.node}
                          showCode={showCode}
                          highlight={highlightValue}
                          highlightType={highlightType}
                          canMove={nodes.length > 1 && !!onMove}
                          onDelete={onDelete}
                          onSelect={onSelect}
                        />
                      </div>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </FlexBox>
          )}
        </Droppable>
      </DragDropContext>
    </Layout>
  );
};
