import {assert} from 'assert-ts';
import {Concept} from 'types';
import {
  DataValue,
  NameVariantValue,
  Part,
  PartNameVariant,
  Valid,
  Valx,
} from 'schemaDefinition/types';

export const nameVariantValidator = (
  part: Part<Valx>,
  value: DataValue,
): Valid => {
  assert(
    part.type === 'nameVariant',
    'validateNameVariant: part.type !== "nameVariant"',
  );

  if (typeof value !== 'object') {
    return 'error';
  }

  const nameVariantValue = value as NameVariantValue;
  const nameVariantPart = part as PartNameVariant;
  return (
    nameVariantPart.agentType === Concept.person
      ? nameVariantValue.name || nameVariantValue.surName
      : nameVariantValue.name
  )
    ? 'valid'
    : 'error';
};
