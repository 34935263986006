import React, {useMemo} from 'react';
import {assert} from 'assert-ts';
import {ThesaurusNode} from 'api/types';
import {IconName} from 'components/icons/types';
import {useLocalization} from 'localization';
import {BaseColorName, ColorPalette} from 'theme';
import {
  ActionButton,
  Card,
  Layout,
  Spacer,
  ThesaurusNodeBreadcrumb,
} from 'components';
import {useThesaurusEditStateContext} from '../contexts';
import {GrepDetailsBody} from './GrepDetailsBody';
import {ThesaurusNodeDetailsBody} from './ThesaurusNodeDetailsBody';
import {ThesaurusNodeDetailsTitle} from './ThesaurusNodeDetailsTitle';

type Props = {
  node: ThesaurusNode;
};

export const ThesaurusNodeDetails: React.FC<Props> = ({node}) => {
  const {tLoose} = useLocalization();
  const {
    thesaurus,
    focusNodeId,
    gotoFocusNodeId,
    focusNodeAction,
    addNodeId,
    removeNodeId,
    categoryId,
  } = useThesaurusEditStateContext();

  const {nodeId, actionTitle, actionIcon, actionBackground, onAction} =
    useMemo(() => {
      const nodeId = assert(
        focusNodeId,
        'ThesaurusNodeDetails: focusNodeId expected',
      );

      const actionType = focusNodeAction?.type ?? 'select';
      const actionTitle = tLoose(`extension.thesaurusEdit.${actionType}`);
      const actionIcon: IconName =
        actionType === 'select'
          ? 'Check'
          : actionType === 'swap'
            ? 'Swap'
            : 'Remove';
      const actionBackground: BaseColorName =
        actionType === 'select' ? 'brown' : 'offWhite';
      return {
        nodeId,
        actionTitle,
        actionIcon,
        actionBackground,
        onAction: () =>
          actionType === 'remove' ? removeNodeId(nodeId) : addNodeId(nodeId),
      };
    }, [addNodeId, focusNodeAction, focusNodeId, removeNodeId, tLoose]);

  // Not allowed to add a category, or when no action
  const hasAction =
    node.selectable && categoryId !== focusNodeId && focusNodeAction;

  return (
    <Card colorx={ColorPalette.offWhite}>
      <Card.Header>
        <Layout
          horizontal
          flex={1}
          overflow="hidden"
          alignItems="center"
          justifyContent={'space-between'}>
          <ThesaurusNodeBreadcrumb
            nodeId={nodeId}
            thesaurus={thesaurus}
            onClick={gotoFocusNodeId}
          />
          {hasAction ? (
            <Layout horizontal>
              <Spacer size={2} />
              <ActionButton
                title={actionTitle}
                icon={actionIcon}
                background={actionBackground}
                showSpinnerOnClick
                onClick={onAction}
                data-cy={'button-thesaurus-picker'}
              />
            </Layout>
          ) : null}
        </Layout>
      </Card.Header>
      <Card.Content>
        <Layout>
          <ThesaurusNodeDetailsTitle node={node} />
          {thesaurus.id === 'grep' ? (
            <>
              <Spacer size={1} />
              <GrepDetailsBody node={node} />
            </>
          ) : (
            <ThesaurusNodeDetailsBody node={node} />
          )}
        </Layout>
      </Card.Content>
    </Card>
  );
};
