import {EntityMainType, EntitySubType} from 'types';
import {getConcreteEntityType} from './getConcreteEntityType';
import {getEntityMainType} from './getEntityMainType';
import {isEntityMainType} from './isEntityMainType';

export const getMainAndSubEntityType = (
  value: unknown,
): {
  mainType?: EntityMainType;
  subType?: EntitySubType;
} => {
  const type = getConcreteEntityType(value);
  if (!type) {
    return {};
  }

  if (isEntityMainType(type)) {
    return {mainType: type};
  }

  return {
    mainType: getEntityMainType(type),
    subType: type,
  };
};
