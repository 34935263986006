import React, {useMemo} from 'react';
import {assert} from 'assert-ts';
import {LinkAction} from 'types';
import {Linking} from 'schema/types';
import {NameVariantValue} from 'schemaDefinition/types';
import {assertAgentSubType} from 'services/utils/functions';
import {ActionButton} from 'components';
import {determineAgentLinkAction} from '../functions';
import {useLinkButtonIconAndTitle} from '../hooks';

type Props = {
  value: NameVariantValue;
  linkingConfiguration: Linking;
};

export const LinkNameVariantButton: React.FC<Props> = ({
  value,
  linkingConfiguration,
}) => {
  const {linkAction, handleLinkAction} = useMemo((): {
    linkAction: LinkAction;
    handleLinkAction: () => Promise<void>;
  } => {
    const {entityId, entityType, nationalId, currentValue, link, unlink} =
      linkingConfiguration;

    const action = determineAgentLinkAction(
      currentValue,
      assert(
        entityId,
        'LinkNameVariantButton: entitytId expected',
        linkingConfiguration,
      ),
      value,
      'variant',
    );

    const handleClick =
      action === 'unlink'
        ? assert(
            unlink,
            'LinkNameVariantButton: unlink expected',
            linkingConfiguration,
          )
        : () => {
            assert(
              link,
              'LinkNameVariantButton: link expected',
              linkingConfiguration,
            )({
              linkStatus: 'verified',
              entityId: assert(
                entityId,
                'LinkMainFormButton: entityId expected',
              ),
              nationalId,
              type: assertAgentSubType(entityType),
              agentName: value,
            });
          };
    return {
      linkAction: action,
      handleLinkAction: () => Promise.resolve(handleClick()),
    };
  }, [linkingConfiguration, value]);

  const {title, icon} = useLinkButtonIconAndTitle(linkAction);

  return (
    <ActionButton
      title={title}
      icon={icon}
      onClick={handleLinkAction}
      size="small"
    />
  );
};
