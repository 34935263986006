import {DataValue, Part, PartType, Valid, Valx} from 'schemaDefinition/types';
import {nameVariantValidator} from './nameVariantValidator';

type Validator = (part: Part<Valx>, value: DataValue) => Valid;

const validatorMap: {[key in PartType]?: Validator} = {
  nameVariant: nameVariantValidator,
};

export const getCustomValidator = (part: Part<Valx>): Validator | undefined => {
  return validatorMap[part.type];
};
