import assert from 'assert-ts';
import {Concept} from 'types';
import {ExpressionV4, ManifestationV4, WorkV4} from 'api/types';
import {Metadata} from 'services/data/metadata/types';

export const getMetadataEntity = (
  entityId: string,
  metadata: Metadata,
): {
  entity: WorkV4 | ExpressionV4 | ManifestationV4;
  entityType: Concept.work | Concept.expression | Concept.manifestation;
} => {
  const {work, expressions, manifestations} = metadata;
  if (metadata.work.id === entityId) {
    return {entity: work, entityType: Concept.work};
  }

  const expression = expressions.find(e => e.id === entityId);
  if (expression) {
    return {entity: expression, entityType: Concept.expression};
  }

  const manifestation = manifestations.find(m => m.id === entityId);
  if (manifestation) {
    return {entity: manifestation, entityType: Concept.manifestation};
  }

  assert(false, `Entity with id ${entityId} not found in metadata`);
};
