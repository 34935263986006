import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const InfoCircleIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12.6016 4C11.0193 4 9.4726 4.46919 8.157 5.34824C6.84141 6.22729 5.81603 7.47672 5.21053 8.93853C4.60503 10.4003 4.4466 12.0089 4.75528 13.5607C5.06397 15.1126 5.82589 16.538 6.94471 17.6569C8.06353 18.7757 9.489 19.5376 11.0408 19.8463C12.5927 20.155 14.2012 19.9965 15.663 19.391C17.1248 18.7855 18.3743 17.7602 19.2533 16.4446C20.1324 15.129 20.6016 13.5823 20.6016 12C20.6016 9.87827 19.7587 7.84344 18.2584 6.34315C16.7581 4.84285 14.7233 4 12.6016 4V4ZM12.6016 18C11.4149 18 10.2548 17.6481 9.26814 16.9888C8.28145 16.3295 7.51241 15.3925 7.05829 14.2961C6.60416 13.1997 6.48534 11.9933 6.71685 10.8295C6.94837 9.66557 7.51981 8.59647 8.35893 7.75736C9.19804 6.91824 10.2671 6.3468 11.431 6.11529C12.5949 5.88378 13.8013 6.0026 14.8977 6.45672C15.994 6.91085 16.9311 7.67988 17.5904 8.66658C18.2497 9.65327 18.6016 10.8133 18.6016 12C18.6016 13.5913 17.9694 15.1174 16.8442 16.2426C15.719 17.3679 14.1929 18 12.6016 18Z" />
      <path d="M12.6016 8C12.0493 8 11.6016 8.44772 11.6016 9C11.6016 9.55228 12.0493 10 12.6016 10C13.1538 10 13.6016 9.55228 13.6016 9C13.6016 8.44772 13.1538 8 12.6016 8Z" />
      <path d="M13.6016 16L13.6016 11L11.6016 11L11.6016 16L13.6016 16Z" />
    </SvgIcon>
  );
};
