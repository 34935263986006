import {Schema} from 'schemaDefinition/types';
import {visitSchemaParts} from 'schemaDefinition/functions/visitSchemaParts';

export const getAllPartPaths = (schema: Schema<unknown>): string[] => {
  const partPaths: string[] = [];
  visitSchemaParts(schema, (_part, partPath) => {
    // Ignore duplicates
    if (!partPaths.includes(partPath)) {
      partPaths.push(partPath);
    }
  });
  return partPaths;
};
