import {DataLoadStatus, User} from 'api/types';

export type UsersState = DataLoadStatus<User[]>;

export const UsersDefaultState: UsersState = {
  status: 'NotLoaded',
  // status: 'Loaded',
  // data: [
  //   {
  //     id: '1',
  //     name: 'Arne',
  //     email: 'arne@bokbasen.no',
  //     picture:
  //       'https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
  //   },

  //   {
  //     id: '2',
  //     name: 'Liv',
  //     email: 'liv@bokbasen.no',
  //     picture:
  //       'https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
  //   },

  //   {
  //     id: '3',
  //     name: 'Harald',
  //     email: 'harald@bokbasen.no',
  //     picture:
  //       'https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
  //   },

  //   {
  //     id: '4',
  //     name: 'Maiken',
  //     email: 'maiken@bokbasen.no',
  //     picture:
  //       'https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
  //   },
  // ],
};
