import {
  Concept,
  LinkTargetCatalogPostType,
  LinkTargetCatalogPostTypes,
} from 'types';

export const isLinkTargetCatalogPostType = (
  entityType: Concept | undefined,
): entityType is LinkTargetCatalogPostType => {
  return (
    !!entityType &&
    LinkTargetCatalogPostTypes.includes(entityType as LinkTargetCatalogPostType)
  );
};
