import React from 'react';
import {Box, SxProps, Theme} from '@mui/material';
import {DataLoadStatus} from 'api/types';
import {ActivityIndicator} from 'components/activityIndicator';
import {ErrorMessage} from 'components/text';

type Props = {
  src: string | DataLoadStatus<string>;
};

const imageStyle: SxProps<Theme> = {
  borderRadius: '8px',
};

export const Image: React.FC<Props> = ({src}) => {
  const source = typeof src === 'string' ? src : src.data;
  const loading = typeof src === 'string' ? false : src.status === 'Loading';

  return source ? (
    <Box component="img" src={source} sx={imageStyle} />
  ) : (
    <Box sx={imageStyle}>
      {loading ? (
        <ActivityIndicator size={'small'} />
      ) : (
        <ErrorMessage message="Couldn't load image" />
      )}
    </Box>
  );
};
