import {PathKeys} from 'schemaDefinition/types';

export const getPathKeys = (
  /**
   * dot-separator: '', 'root', 'root.child', 'root.child[0].item'
   **/
  path: string,
): PathKeys => {
  if (!path || path === '') {
    return []; // TODO: Rather undefined or assert
  }

  return path
    .replace(/\[/g, '.[')
    .split('.')
    .map(key => (key[0] === '[' ? parseInt(key.substring(1)) : key));
};
