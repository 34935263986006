import {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import {useLocalization} from 'localization';
import {ExpressionV4, moveManifestationToDuplicategWork} from 'api';
import {useGetTokens} from 'services/auth';
import {
  metadataExpressionOrderSelector,
  useAssertExpressionsEdit,
  useAssertManifestationEdit,
  useAssertSavedWork,
} from 'services/data';
import {useStaticAlertDialogAsPromise} from 'components';
import {
  AlertDialogAsPromiseV3,
  AlertDialogContentProps,
} from 'components/alertdialog/hooks/useAlertDialogAsPromiseV3';
import {useMetadataOperationsContext} from '../contexts/MetadataOperationsContext';
import {useMoveToOrCopyFromOtherWork} from './useMoveToOrCopyFromOtherWork';

export const useMoveManifestationContext = (
  manifestationId: string,
): {
  expressions: ExpressionV4[];
  currentExpressionId: string;
  manifestationCountInExpression: number;
  manifestationCountInWork: number;
  moveManifestationToOtherExpression: (
    manifestationId: string,
    expressionId: string,
  ) => void;
  moveManifestationToNewExpression: (manifestationId: string) => void;
  moveManifestationToOtherWork: (manifestationId: string) => void;
  moveManifestationToNewWork: (manifestationId: string) => void;
  ConfirmMoveToOtherWork: AlertDialogAsPromiseV3;
  isConfirmMoveToOtherOpen: boolean;
  isConfirmMoveToOtherClosing: boolean;
  ConfirmMoveToNewWork: AlertDialogAsPromiseV3;
  isConfirmMoveToNewWorkOpen: boolean;
  isConfirmMoveToNewWorkClosing: boolean;
} => {
  const {t} = useLocalization();
  const getTokens = useGetTokens();
  const navigate = useNavigate();
  const workId = useAssertSavedWork().id;
  const {moveManifestationToOtherExpression, moveManifestationToNewExpression} =
    useMetadataOperationsContext();
  const manifestation = useAssertManifestationEdit(manifestationId);
  const expressions = useAssertExpressionsEdit();
  const expressionOrder = useSelector(metadataExpressionOrderSelector);

  const {
    openSearch,
    ConfirmMoveToOther: ConfirmMoveToOtherWork,
    isConfirmOpen: isConfirmMoveToOtherOpen,
    isConfirmClosing: isConfirmMoveToOtherClosing,
  } = useMoveToOrCopyFromOtherWork(manifestationId);

  const confirmMoveToNewWorkProps = useMemo(
    (): AlertDialogContentProps => ({
      title: t('page.metadata.manifestation.moveToNewWork.confirm.title'),
      body: t('page.metadata.manifestation.moveToNewWork.confirm.body'),
      cancelTitle: t('general.cancel'),
      okTitle: t('general.move'),
      okIcon: 'Move',
    }),
    [t],
  );
  const {
    pleaseConfirm: confirmMoveToNewWork,
    AlertDialog: ConfirmMoveToNewWork,
    isOpen: isConfirmMoveToNewWorkOpen,
    isClosing: isConfirmMoveToNewWorkClosing,
  } = useStaticAlertDialogAsPromise(confirmMoveToNewWorkProps);

  const handleMoveToNewWork = useCallback(
    (manifestationId: string) => {
      return confirmMoveToNewWork().then(confirmed => {
        if (confirmed) {
          return moveManifestationToDuplicategWork(
            manifestationId,
            workId,
            getTokens,
          ).then(newWorkId => {
            navigate(
              `/metadata/${newWorkId}?manifestationId=${manifestationId}`,
            );
          });
        }
        return Promise.resolve();
      });
    },
    [confirmMoveToNewWork, getTokens, navigate, workId],
  );

  return useMemo(() => {
    const orderedExpressions = expressionOrder
      .map(group => expressions.find(e => e.id === group.expressionId))
      .filter(e => !!e) as ExpressionV4[];

    const manifestationCountInExpression = assert(
      expressionOrder.find(g => g.expressionId === manifestation.expressionId),
    ).content.filter(c => c.type === 'manifestation').length;

    const manifestationCountInWork = expressionOrder
      .map(g => g.content.filter(c => c.type === 'manifestation').length)
      .reduce((a, b) => a + b, 0);

    return {
      expressions: orderedExpressions,
      currentExpressionId: manifestation.expressionId,
      manifestationCountInExpression,
      manifestationCountInWork,
      moveManifestationToOtherExpression,
      moveManifestationToNewExpression,
      moveManifestationToOtherWork: openSearch,
      moveManifestationToNewWork: handleMoveToNewWork,
      ConfirmMoveToOtherWork,
      isConfirmMoveToOtherOpen,
      isConfirmMoveToOtherClosing,
      ConfirmMoveToNewWork,
      isConfirmMoveToNewWorkOpen,
      isConfirmMoveToNewWorkClosing,
    };
  }, [
    ConfirmMoveToNewWork,
    ConfirmMoveToOtherWork,
    expressionOrder,
    expressions,
    handleMoveToNewWork,
    isConfirmMoveToNewWorkClosing,
    isConfirmMoveToNewWorkOpen,
    isConfirmMoveToOtherClosing,
    isConfirmMoveToOtherOpen,
    manifestation.expressionId,
    moveManifestationToNewExpression,
    moveManifestationToOtherExpression,
    openSearch,
  ]);
};
