import {useCallback} from 'react';
import {Auth} from '@aws-amplify/auth';
import {GetTokens} from 'api/types';

export const useGetTokens = (): GetTokens => {
  return useCallback(
    () =>
      Auth.currentSession().then(session => {
        return {
          accessToken: session.getAccessToken().getJwtToken(),
          idToken: session.getIdToken().getJwtToken(),
          refreshToken: session.getRefreshToken().getToken(),
        };
      }),
    [],
  );
};
