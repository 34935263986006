import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const CalendarIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M18 6H16V4H14V6H10V4H8V6H6C5.46957 6 4.96086 6.21071 4.58579 6.58579C4.21071 6.96086 4 7.46957 4 8V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V8C20 7.46957 19.7893 6.96086 19.4142 6.58579C19.0391 6.21071 18.5304 6 18 6ZM8 8V10H10V8H14V10H16V8H18V11H6V8H8ZM6 18V13H18V18H6Z" />
    </SvgIcon>
  );
};
