import * as React from 'react';
import {useMemo} from 'react';
import {SxProps} from '@mui/material';
import {PageSizeProps} from './types';
import {Select} from '../fields';
import {FlexBox} from '../layout';

const wrapperSx: SxProps = {
  textWrap: 'nowrap',
};

export const PageSizePicker: React.FC<
  Pick<PageSizeProps, 'size' | 'setSize' | 'pageSizes' | 'total' | 'allowAll'>
> = ({size, setSize, pageSizes, total, allowAll}) => {
  const pageSizeOptions = useMemo(() => {
    const allOption = allowAll && total > Math.max(...pageSizes) ? [total] : [];
    return [...pageSizes, ...allOption].map(o => o.toString());
  }, [allowAll, pageSizes, total]);

  return (
    <FlexBox
      alignItems={'center'}
      horizontal
      alignContent={'center'}
      justifyContent={'center'}
      sx={wrapperSx}>
      <Select
        value={size.toString()}
        options={pageSizeOptions}
        onChange={value => setSize(Number(value))}
      />
    </FlexBox>
  );
};
