import {LicenseInfo} from '@mui/x-license-pro';

/**
 * This license is NOT a secret, so we allow it hardcoded
 * @see https://mui.com/components/data-grid/getting-started/#license-key-installation
 * @see https://v5.mui.com/x/introduction/licensing/#license-key-installation
 */
export const configureMuiPro = () => {
  LicenseInfo.setLicenseKey(
    '0ece420aed9755e4a49dd45472a51a97Tz05MDQzNSxFPTE3NDc0Njk4MjAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
  );
};
