import {Code} from 'api/types';

export const expressionFormat: Code[] = [
  {
    code: 'TEXT',
    value: 'Tekst',
  },
  {
    code: 'ILLUSTRATED_TEXT',
    value: 'Illustrert tekst',
  },
  {
    code: 'AUDIO',
    value: 'Lyd',
  },
];
