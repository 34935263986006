import React from 'react';
import {ProductStatus} from 'api/types';
import {IconName, IconProps} from './types';
import {Icon} from './Icon';

type Props = IconProps & {
  productStatus?: ProductStatus | null | undefined;
};

const statusMap: {[key in ProductStatus]: IconName} = {
  COMING: 'History',
  ACTIVE: 'Check',
  INACTIVE: 'CloseSmall',
  ONHOLD: 'InfoCircle',
};

export const ProductStatusIcon: React.FC<Props> = ({
  productStatus,
  ...props
}) => {
  const icon = productStatus ? statusMap[productStatus] : undefined;

  return icon ? <Icon icon={icon} {...props} /> : null;
};
