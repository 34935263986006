import {useMemo} from 'react';
import {RightPanelConfig} from 'components/scene/types';
import {FieldExtensionEnum} from 'sceneExtensions/types';
import {ColorPalette} from 'theme';
import {LinkedValueSearchSceneExtensionCore} from '../types';
import {LinkedValueSearchContainer} from '../LinkedValueSearchContainer';
import {useLinkedValueSearchFieldExtension} from './useLinkedValueSearchFieldExtension';

const defaultConfig: Partial<RightPanelConfig> = {};

export const useLinkedValueSearchSceneExtensionCore = (
  config: Partial<RightPanelConfig> = defaultConfig,
) => {
  const linkedValueSearchFieldExtension = useLinkedValueSearchFieldExtension();

  return useMemo((): LinkedValueSearchSceneExtensionCore => {
    return {
      type: FieldExtensionEnum.linkedValueSearch,
      panelConfig: {
        backgroundColour: ColorPalette.enhancedBrown,
        ...config,
      },
      renderRightPanel: () => <LinkedValueSearchContainer />,
      fieldContext: linkedValueSearchFieldExtension,
    };
  }, [config, linkedValueSearchFieldExtension]);
};
