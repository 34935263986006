import {DateType} from '../../types';

export const toApiDate = (date: string, isBce?: boolean): string => {
  return isBce ? `-${date}` : date;
};

export const fromApiDate = (date: string): Pick<DateType, 'date' | 'bce'> => {
  const isBce = date.charAt(0) === '-';
  return {
    bce: isBce,
    date: isBce ? date.substring(1) : date,
  };
};

export const toApiYear = (year: number, isBce?: boolean): number => {
  return isBce ? -year : year;
};

export const fromApiYear = (year: number): Pick<DateType, 'year' | 'bce'> => {
  if (year < 0) {
    return {
      bce: true,
      year: Math.abs(year),
    };
  } else {
    return {
      bce: false,
      year: year,
    };
  }
};
