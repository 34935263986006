import {PartValueRenderer} from 'schema/types';
import {ProductOwner as ProductOwnerType} from 'api';
import {Builder, isNullish} from 'services/utils';
import {useTextValue} from '../../schema/preview/hooks';

const formatProductOwner = (productOwner: ProductOwnerType) => {
  return new Builder(productOwner.code).add(': ', productOwner.name).value;
};

export const ProductOwner: PartValueRenderer = ({value}) => {
  const TextValue = useTextValue();

  return isNullish(value) ||
    !Array.isArray(value) ||
    value.length === 0 ? null : (
    <TextValue>
      {value
        .map(v => formatProductOwner(v as ProductOwnerType))
        .filter(v => v)
        .join('; ')}
    </TextValue>
  );
};
