import {CollectionSubType} from '../types';
import {Collection, GetTokens} from './types';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpElastic} from './http/search/httpElastic';
import {mapSearchCollectionResult} from './mappers';
import {mapToCollectionTypeDto} from './mappers/mapCollectionTypeDto';
import {
  SearchCollectionDto,
  SearchResult,
  SearchResultDto,
} from './searchTypes';

const makeSearchPart = (search: string): object => {
  return {
    must: [
      {
        multi_match: {
          query: search,
          operator: 'and',
          fields: ['titles.value^3', '*'],
        },
      },
    ],
  };
};
const makeFilterPart = (
  collectionTypes: SearchCollectionDto['type'][],
): object => {
  if (collectionTypes.length === 0) {
    return {};
  }

  if (collectionTypes.length === 1) {
    return {
      filter: {
        term: {'type.keyword': collectionTypes[0]},
      },
    };
  }

  return {
    filter: {
      bool: {
        should: collectionTypes.map(collectionType => ({
          term: {
            'type.keyword': collectionType,
          },
        })),
      },
    },
  };
};

export const searchSeries = (
  search: string,
  seriesTypes: CollectionSubType[],
  page: number,
  size: number,
  getTokens: GetTokens,
  abortSignal?: AbortSignal,
  mock?: boolean,
): Promise<SearchResult<Collection>> => {
  const apiCollectionTypes = seriesTypes.map(mapToCollectionTypeDto);

  return exceptionToPromiseReject(() =>
    httpElastic<SearchResultDto<SearchCollectionDto>>(
      getTokens,
      '_search',
      'collection',
      {
        bool: {
          ...makeSearchPart(search),
          ...makeFilterPart(apiCollectionTypes),
        },
      },
      page,
      size,
      10,
      ['*'],
      abortSignal,
      mock,
    ).then(mapSearchCollectionResult),
  );
};
