import {useEffect, useState} from 'react';
import assert from 'assert-ts';
import {Concept} from 'types';
import {Data, Schema, Valid} from 'schemaDefinition/types';
import {CodeList, CodeListId, CodeListMap, LocalCodeListId} from 'api';
import {AllLocalCodeListIds} from 'schemaDefinition/linkTypes';
import {useRestrictedCodelistsContext} from 'services/codeLists';
import {useConditionalManifestationEdit} from 'services/data';
import {validate} from 'schema/form/functions/validators/validate';
import {useSchemaRefCodelists} from 'schemas';

const getLocalCodelists = (
  localIds: LocalCodeListId[],
  getLocalCodelist: (id: CodeListId) => CodeList | undefined,
): CodeListMap => {
  return localIds.reduce<CodeListMap>((acc, id) => {
    const codelist = getLocalCodelist(id);
    if (codelist) {
      acc[id] = codelist;
    }
    return acc;
  }, {});
};

export const useManifestationValidation = (
  manifestationid: string,
  relatedScope: Data,
  showErrors: boolean,
  schema: Schema,
): Valid | undefined => {
  // Optimization: Only select manifestation if we are showing errors
  const manifestation = useConditionalManifestationEdit(
    manifestationid,
    showErrors,
  );
  const codelistMap = useSchemaRefCodelists(Concept.manifestation);
  const {getLocalCodelist} = useRestrictedCodelistsContext();

  const [valid, setValid] = useState<Valid | undefined>(undefined);

  useEffect(() => {
    if (showErrors) {
      const localCodeLists = getLocalCodelists(
        AllLocalCodeListIds,
        getLocalCodelist,
      );

      const allCodelistsMap = {
        ...codelistMap,
        ...localCodeLists,
      };

      const validation = validate(
        assert(manifestation, 'useManifestationValidation: no manifestation'),
        relatedScope,
        schema,
        allCodelistsMap,
      );

      setValid(validation.valid);
    } else {
      setValid(undefined);
    }
  }, [
    manifestation,
    schema,
    codelistMap,
    showErrors,
    relatedScope,
    getLocalCodelist,
  ]);

  return valid;
};
