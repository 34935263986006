import {SxProps} from '@mui/system';
import {FieldError} from 'schemaDefinition/types';
import {Text} from '../text';

type Props = {
  error?: FieldError;
  errorMessage?: string;
  sx?: SxProps;
};

export const FieldErrorMessage: React.FC<Props> = ({
  error = false,
  errorMessage,
  sx,
}) => {
  return errorMessage && error ? (
    <Text variant="body2" kind={error === true ? 'error' : 'warning'} sx={sx}>
      {errorMessage}
    </Text>
  ) : null;
};
