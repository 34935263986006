import {assert} from 'assert-ts';
import {ManifestationV4} from 'api/types';
import {Metadata} from '../../../types';
import {MetadataEditAction} from '../../../metadataEditActionTypes';

export const assertManifestation = (
  manifestationId: string,
  metadata: Metadata | undefined,
  action?: MetadataEditAction,
  qualifer?: 'saved' | 'edit',
): ManifestationV4 => {
  const manifestation = metadata?.manifestations.find(
    e => e.id === manifestationId,
  );

  if (!manifestation) {
    assert(
      false,
      qualifer
        ? `metadataEditReducer.assertManifestation (${qualifer}): manifestation not found`
        : 'metadataEditReducer.assertManifestation: manifestation not found',
      action,
    );
  }

  return manifestation;
};
