import React from 'react';
import {SvgIcon} from '@mui/material';
import {FilledIconProps} from './types';
import {useStatusColor} from '../../theme';

export const CircleFilledIcon: React.FC<FilledIconProps> = ({
  fillColor,
  fillColorCode,
  ...props
}) => {
  const themeFillColor = useStatusColor(fillColor) ?? fillColorCode ?? 'none';
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="7" fill={themeFillColor} />
      <path d="M12 6C13.1867 6 14.3467 6.35189 15.3334 7.01118C16.3201 7.67047 17.0892 8.60754 17.5433 9.7039C17.9974 10.8003 18.1162 12.0067 17.8847 13.1705C17.6532 14.3344 17.0818 15.4035 16.2426 16.2426C15.4035 17.0818 14.3344 17.6532 13.1705 17.8847C12.0067 18.1162 10.8003 17.9974 9.7039 17.5433C8.60755 17.0892 7.67047 16.3201 7.01119 15.3334C6.3519 14.3467 6 13.1867 6 12C6 10.4087 6.63214 8.88258 7.75736 7.75736C8.88258 6.63214 10.4087 6 12 6ZM12 4C10.4178 4 8.87103 4.46919 7.55544 5.34824C6.23985 6.22729 5.21447 7.47672 4.60897 8.93853C4.00347 10.4003 3.84504 12.0089 4.15372 13.5607C4.4624 15.1126 5.22433 16.538 6.34315 17.6569C7.46197 18.7757 8.88743 19.5376 10.4393 19.8463C11.9911 20.155 13.5997 19.9965 15.0615 19.391C16.5233 18.7855 17.7727 17.7602 18.6518 16.4446C19.5308 15.129 20 13.5823 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4Z" />
    </SvgIcon>
  );
};
