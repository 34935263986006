import {AppError} from './types';
import {logError} from './logger';

export const configureLoggingOfUnhandledPromiseRejections = () => {
  window.onunhandledrejection = err => {
    const reason = err.reason;
    if (reason instanceof Error) {
      reason.message = 'Unhandled promise rejection: ' + reason.message;
      if (reason instanceof AppError) {
        logError(reason, reason.props);
      } else {
        logError(reason, {reason});
      }
    } else if (typeof reason === 'string') {
      logError('Unhandled promise rejection: ' + reason);
    } else {
      logError('Unhandled promise rejection', {reason});
    }
    err.preventDefault();
  };
};
