import React from 'react';
import {DataLoadStatus, ExternalSuggestions, Media} from 'api/types';
import {Note} from 'services/data/notes/types';
import {ManifestationPanel as ManifestationPanelType} from '../types';
import {FilesPanel} from '../../FilesPanel';
import {InfoPanel} from '../../InfoPanel';
import {NotesPanel} from '../../NotesPanel';

type Props = {
  manifestationId: string;
  showManifestationPanel?: ManifestationPanelType | undefined;
  onToggleManifestationPanel: (panel: ManifestationPanelType) => void;
  media: DataLoadStatus<Media[]>;
  notes: DataLoadStatus<Note[]>;
  createNote: (note: string) => Promise<void>;
  updateNote: (id: string, note: string) => Promise<void>;
  removeNote: (id: string) => Promise<void>;
  externalSuggestions: DataLoadStatus<ExternalSuggestions>;
};

export const ManifestationPanel: React.FC<Props> = ({
  manifestationId,
  showManifestationPanel,
  onToggleManifestationPanel,
  media,
  notes,
  createNote,
  updateNote,
  removeNote,
  externalSuggestions,
}) => {
  return (
    <>
      {showManifestationPanel === 'files' ? (
        <FilesPanel
          files={media}
          onClose={() => onToggleManifestationPanel('files')}
        />
      ) : showManifestationPanel === 'notes' ? (
        <NotesPanel
          notes={notes}
          onCreateNote={createNote}
          onUpdateNote={updateNote}
          onRemoveNote={removeNote}
          onClose={() => onToggleManifestationPanel('notes')}
        />
      ) : showManifestationPanel === 'info' ? (
        <InfoPanel
          manifestationId={manifestationId}
          externalSuggestions={externalSuggestions}
          onClose={() => onToggleManifestationPanel('info')}
        />
      ) : null}
    </>
  );
};
