import React from 'react';
import {Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useLocalization} from 'localization';

const StyledGridOverlay = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

export const NoRowsOverlay: React.FC = () => {
  const {t} = useLocalization();

  return (
    <StyledGridOverlay>
      <Box>{t('datatable.no-rows')}</Box>
    </StyledGridOverlay>
  );
};
