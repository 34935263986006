import {assert} from 'assert-ts';
import {removeEmptyStringValues} from 'services/utils';
import {CodeListRef, WorkV4} from '../types';
import {Schemas} from '../dto.generated';
import {mapAgentRoleDto, mapToAgentRoleListDto} from './mapAgentRoleDto';
import {mapToWorkRelationsDto, mapWorkRelationsDto} from './mapRelationsDto';

export const mapWorkBaseDto = (dto: Schemas.WorkBaseDto): WorkV4 => {
  const {
    id,
    created,
    modified,
    agents,
    workRelations,
    agentRelations,
    seriesRelations,
    preferredTitle,
    originalTitle,
    titleInfo,
    type,
    generalNote,
    ...rest
  } = dto;
  const links = mapWorkRelationsDto({
    workRelations,
    agentRelations,
    seriesRelations,
  });
  return {
    id: assert(id, 'mapWorkBaseDto: id expected', dto),
    created: assert(created, 'mapWorkBaseDto: created expected', dto),
    modified: assert(modified, 'mapWorkBaseDto: modified expected', dto),
    ...rest,
    agents: (agents ?? []).map(mapAgentRoleDto),
    links,
    preferredTitle: titleInfo?.preferredTitle ?? preferredTitle ?? [],
    originalTitle: titleInfo?.originalTitle ?? originalTitle,
    otherTitles: titleInfo?.otherTitles.sort((a, b) => a.order - b.order) ?? [],
    type: type ?? CodeListRef.WORK_TYPE.Bok,
    generalNote: removeEmptyStringValues(generalNote),
  };
};

export const mapToWorkBaseDto = (data: WorkV4): Schemas.WorkBaseDto => {
  const {agents, links, preferredTitle, originalTitle, otherTitles, ...rest} =
    data;
  const relations = mapToWorkRelationsDto(links);

  return {
    ...rest,
    agents: mapToAgentRoleListDto(agents),
    titleInfo: {
      preferredTitle: preferredTitle ?? [],
      originalTitle,
      otherTitles:
        otherTitles?.map((oT, i) => ({
          ...oT,
          order: i,
        })) ?? [],
    },
    ...relations,
  };
};
