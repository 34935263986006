import {assert} from 'assert-ts';
import {ExpressionV4, GetTokens, ManifestationV4} from './types';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpPost, httpPut} from './http/ebba';
import {
  mapExpressionsDto,
  mapManifestationBaseDto,
  mapToManifestationBaseDto,
} from './mappers';
import {
  mapExpressionBaseDto,
  mapToExpressionBaseDto,
} from './mappers/mapExpressionBaseDto';

export const getExpression = (
  id: string,
  getTokens: GetTokens,
): Promise<ExpressionV4> => {
  /** Use post to allow getting many expressions at once, @see {@link getExpressions} */
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.ExpressionsDto, string[]>({
      subdir: 'expression/id',
      body: [id],
      getTokens,
    })
      .then(mapExpressionsDto)
      .then(expressions => expressions[0]),
  );
};

export const getExpressions = (
  ids: string[],
  getTokens: GetTokens,
): Promise<ExpressionV4[]> => {
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.ExpressionsDto, string[]>({
      subdir: 'expression/id',
      body: ids,
      getTokens,
    }).then(mapExpressionsDto),
  );
};

export const putExpression = (
  expression: ExpressionV4,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<ExpressionV4> => {
  return exceptionToPromiseReject(() =>
    httpPut<Schemas.ExpressionBaseDto>({
      subdir: 'expression/{id}',
      subdirParams: {id: expression.id},
      body: mapToExpressionBaseDto(expression),
      getTokens,
      mock,
    }).then(mapExpressionBaseDto),
  );
};

export const postNewExpressionWithManifestation = (
  expression: ExpressionV4,
  manifestation: ManifestationV4,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<{
  expression: ExpressionV4;
  manifestation: ManifestationV4;
}> => {
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.MoveManifestationBaseDto>({
      subdir: 'expression/create',
      body: {
        expressionBaseDto: mapToExpressionBaseDto(expression),
        manifestationBaseDto: mapToManifestationBaseDto(manifestation),
      },
      getTokens,
      mock,
    }).then(({expressionBaseDto, manifestationBaseDto}) => {
      const expDto = assert(
        expressionBaseDto,
        'postNewExpressionWithManifestation: expressionBaseDto is expected',
      );
      manifestationBaseDto.expressionId = expDto.id;
      return {
        expression: mapExpressionBaseDto(expDto),
        manifestation: mapManifestationBaseDto(manifestationBaseDto),
      };
    }),
  );
};
