import {PartCodeListOrText, Valx} from 'schemaDefinition/types';
import {ValidationArgs, ValidationResult} from './types';
import {validResult} from './validResult';
import {validateSingleTextValue} from './validateText';
import {validateValueType} from './validateValueType';

export const validateCodeListOrTextValue = (
  part: PartCodeListOrText<Valx>,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(
    part,
    validateSingleTextValue,
    args,
    aggregateResult,
  );
};
