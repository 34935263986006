import {useCallback} from 'react';
import {useLocalization} from 'localization';
import {DataLoadStatus, GrepExtendedConcept, useGetResourceById} from 'api';
import {getExtendedGrepNode} from 'api/thesaurus';
import {useGetTokens} from 'services/auth';

export const useExtendedGrepNode = (
  code: string,
): DataLoadStatus<GrepExtendedConcept> => {
  const getTokens = useGetTokens();
  const {locale} = useLocalization();
  const getResource = useCallback(
    (code: string | undefined) =>
      code
        ? getExtendedGrepNode(code, locale ?? 'nb', getTokens)
        : Promise.reject(),
    [getTokens, locale],
  );
  return useGetResourceById(code, getResource);
};
