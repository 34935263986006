import {useMemo} from 'react';
import {
  Breakpoint,
  Breakpoints,
  Theme,
  createTheme,
  useTheme,
} from '@mui/material';

type BreakpointValues = Breakpoints['values'];

export const useLayoutTheme = (size: number, columns: number = 12): Theme => {
  const outerTheme = useTheme();

  return useMemo(() => {
    const outerValues = outerTheme.breakpoints.values;
    const values = Object.keys(outerValues).reduce(
      (acc: BreakpointValues, key) => {
        acc[key as Breakpoint] =
          (outerValues[key as Breakpoint] / size) * columns;
        return acc;
      },
      {} as BreakpointValues,
    );

    const subTheme = {
      ...outerTheme,
      breakpoints: {
        values,
      },
    };

    return createTheme(subTheme);
  }, [columns, outerTheme, size]);
};
