import React, {useMemo} from 'react';
import {AgentSubType, Concept} from 'types';
import {NationalAgentType} from 'api/types';
import {MenuItemProps} from 'components/popups/types';
import {useLocalization} from 'localization';
import {ButtonWithPopupMenu} from 'components';

type Props = {
  title?: string;
  agentTypes: AgentSubType[];
  backgroundColor?: string;
  onAdd: (agentType: AgentSubType) => void;
  onAddWizard: (agentType: NationalAgentType) => void;
};

export const AddAgentButton: React.FC<Props> = ({
  title,
  agentTypes,
  backgroundColor,
  onAdd,
  onAddWizard,
}) => {
  const {t} = useLocalization();
  const addItems = useMemo(() => {
    const items: MenuItemProps[] = [];
    if (agentTypes.includes(Concept.person)) {
      items.push({
        id: Concept.person,
        title: t('page.agents.add.type.person'),
        icon: 'User',
        action: () => onAddWizard(Concept.person),
      });
    }
    if (agentTypes.includes(Concept.corporation)) {
      items.push({
        id: Concept.corporation,
        title: t('page.agents.add.type.corporation'),
        icon: 'Building',
        action: () => onAddWizard(Concept.corporation),
      });
    }

    if (agentTypes.includes(Concept.publisher)) {
      items.push({
        id: Concept.publisher,
        title: t('page.agents.add.type.publisher'),
        icon: 'PrintBook',
        action: () => onAdd(Concept.publisher),
      });
    }
    if (agentTypes.includes(Concept.event)) {
      items.push({
        id: Concept.event,
        title: t('page.agents.add.type.event'),
        icon: 'Event',
        action: () => onAddWizard(Concept.event),
      });
    }

    return items;
  }, [agentTypes, onAdd, onAddWizard, t]);

  const {popupTitle} = useMemo(
    () => ({
      popupTitle: t('search.agent.addAgent.popupTitle'),
    }),
    [t],
  );
  return (
    <ButtonWithPopupMenu
      icon="Add"
      title={title}
      popupTitle={popupTitle}
      backgroundColor={backgroundColor}
      items={addItems}
    />
  );
};
