import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {NoProps} from 'types';
import {useLocalization} from 'localization';
import {LoadStatus, getTasksV2} from 'api';
import {useGetTokens} from 'services/auth';
import {Scene} from 'components/scene';

type Props = NoProps;

export const SearchMetadataScene: React.FC<Props> = () => {
  const navigate = useNavigate();
  const {t} = useLocalization();

  const getTokens = useGetTokens();
  const [loadStatus, setLoadStatus] = useState<LoadStatus>('Loading');

  useEffect(() => {
    getTasksV2(getTokens).then(tasks => {
      navigate(`/metadata/${tasks?.[0].metadata.workId}`);
      setLoadStatus('Loaded');
    });
  }, [getTokens, navigate]);

  return (
    <Scene browserTitle={t('appbar.searchMetadata')}>
      <Scene.Header title={t('appbar.searchMetadata')} />
      <Scene.Content loadStatus={loadStatus} />
    </Scene>
  );
};
