// import {ButtonTypeMap} from '@mui/material/Button';
import React, {useCallback} from 'react';
import {FCWithChildren} from 'types';
import './UnstyledButton.scss';

type Props = {
  type?: 'button' | 'submit' | 'reset';
  onClick: () => void;
  onBlur?: () => void;
};

export const UnstyledButton: FCWithChildren<Props> = ({
  type = 'button',
  onClick,
  onBlur,
  children,
}) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onClick();
      event.stopPropagation();
    },
    [onClick],
  );

  return (
    <button
      type={type}
      onClick={handleClick}
      onBlur={onBlur}
      className="UnstyledButton">
      {children}
    </button>
  );
};
