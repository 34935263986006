import React, {useMemo} from 'react';
import {List} from '@mui/material';
import {ThesaurusNode} from 'api/types';
import {useLocalization} from 'localization';
import {assertThesaurusNodeWithAncestors} from 'services/thesaurus';
import {Card, GridLayout, Layout, Spacer, TextMatch} from 'components';
import {useThesaurusEditStateContext} from '../contexts';
import {NodeLabel} from './NodeLabel';
import {ThesaurusNodeDetailsRelated} from './ThesaurusNodeDetailsRelated';
import {ThesaurusNoteWithRefs} from './ThesaurusNoteWithRefs';

type Props = {
  node: ThesaurusNode;
};

export const ThesaurusNodeDetailsBody: React.FC<Props> = ({node}) => {
  const {t} = useLocalization();
  const {thesaurus, searchQuery, gotoFocusNodeId} =
    useThesaurusEditStateContext();

  const related = useMemo(() => {
    return node.related && node.related.length > 0
      ? node.related.map(rel =>
          assertThesaurusNodeWithAncestors(rel.id, thesaurus),
        )
      : undefined;
  }, [node, thesaurus]);

  return (
    <Layout>
      <List sx={{margin: 0, padding: 0}}>
        {node.alternativeLabels && node.alternativeLabels.length > 0 ? (
          <>
            <Card>
              <GridLayout>
                <GridLayout.Item span={4}>
                  <NodeLabel
                    label={t('extension.thesaurusEdit.details.synonyms')}
                  />
                </GridLayout.Item>
                <GridLayout.Item>
                  {node.alternativeLabels.map(synonym => (
                    <TextMatch
                      key={synonym}
                      match={searchQuery}
                      variant="body2">
                      {synonym}
                    </TextMatch>
                  ))}
                </GridLayout.Item>
              </GridLayout>
            </Card>
            <Spacer size={2} />
          </>
        ) : null}
        {node.definition ? (
          <>
            <Card>
              <NodeLabel
                label={t('extension.thesaurusEdit.details.definition')}
              />
              <TextMatch match={searchQuery} variant="body2">
                {node.definition}
              </TextMatch>
            </Card>
            <Spacer size={2} />
          </>
        ) : null}
        {related ? (
          <>
            <Card>
              <ThesaurusNodeDetailsRelated
                label={t('extension.thesaurusEdit.details.related')}
                related={node.related}
              />
            </Card>
            <Spacer size={2} />
          </>
        ) : null}
        {node.note ? (
          <Card>
            <NodeLabel label={t('extension.thesaurusEdit.details.note')} />
            <ThesaurusNoteWithRefs
              note={node.note}
              searchQuery={searchQuery}
              gotoNodeId={gotoFocusNodeId}
            />
          </Card>
        ) : null}
      </List>
    </Layout>
  );
};
