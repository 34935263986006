import React from 'react';
import {SvgIcon} from '@mui/material';
import {ColorPalette} from 'theme';
import {IconProps} from './types';

export const RadiobuttonUncheckedIcon: React.FC<IconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <rect
        x="4.625"
        y="4.625"
        width="14.75"
        height="14.75"
        rx="7.375"
        fill={ColorPalette.offWhite}
        stroke={'currentColor'}
        strokeWidth="1.25"
      />
    </SvgIcon>
  );
};
