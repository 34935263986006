import React, {useMemo} from 'react';
import {Divider, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {Fonts, FontsReduced} from 'theme/fonts';
import {Text} from 'components';
import {SceneTitle} from 'components/scene/SceneTitle';
import {HeaderMode} from './WorkHeader';

type Props = {
  title?: string;
  subtitle?: string;
  mode: HeaderMode;
};
export const WorkTitles: React.FC<Props> = ({title, subtitle, mode}) => {
  const theme = useTheme();

  const {textProps, subtitleSx, dividerSx} = useMemo(
    () => ({
      textProps: {
        variant: 'h1',
        component: 'h1',
        sx: {
          ...(mode === 'mini' ? FontsReduced.h1 : Fonts.h1),
          transition: 'font-size 0.3s',
        },
      } as const,
      subtitleSx: {
        ...(mode === 'mini' ? FontsReduced.h2 : Fonts.h2),
        transition: 'font-size 0.3s, font-weight 0.3s, letter-spacing 0.3s',
      },
      dividerSx: {
        width: '2px',
        ml: theme.spacing(2),
        mr: theme.spacing(2),
        display: {
          xs: 'none',
          sm: 'block',
        },
      },
    }),
    [mode, theme],
  );

  return (
    <SceneTitle title={title} xs={12} sm={'auto'} textProps={textProps}>
      {subtitle ? (
        <Grid item xs={12} sm>
          <Box display={'flex'} flexDirection={'row'}>
            <Divider flexItem orientation="vertical" sx={dividerSx} />
            <Text
              noWrap
              className={'cy-scene-subtitle'}
              variant={'h2'}
              sx={subtitleSx}
              kind="disabled">
              {subtitle}
            </Text>
          </Box>
        </Grid>
      ) : null}
    </SceneTitle>
  );
};
