import {assert} from 'assert-ts';
import {Concept} from 'types';
import {
  Agent,
  AgentCorporation,
  AgentEvent,
  AgentName,
  AgentPerson,
  AgentPublisher,
  Gender,
} from 'api/types';
import {SearchAgentDto, SearchResult, SearchResultDto} from 'api/searchTypes';
import {mapAgentSearchDate} from './mapAgentSearchDate';
import {mapAgentTypeDto} from './mapAgentTypeDto';
import {mapHighlight} from './mapHighlights';

export const mapSearchAgentResult = (
  dto: SearchResultDto<SearchAgentDto>,
): SearchResult<Agent> => {
  const hits = dto.hits.hits
    .map(h => mapAgentDto(h._source))
    .filter(a => a && a.agentType) as Agent[];

  return {
    hits,
    highlights: dto?.hits.hits.map(h => mapHighlight(h.highlight)).flat(1),
    total: dto?.hits?.total.value || 0,
  };
};

const mapAgentDto = (dto: SearchAgentDto): Agent | undefined => {
  const agentType = mapAgentTypeDto(
    assert(dto.agentType, 'mapSearchAgentResult: expected agentType', dto),
  );

  switch (agentType) {
    case Concept.person: {
      return mapPerson(dto);
    }
    case Concept.publisher: {
      return mapPublisher(dto);
    }
    case Concept.corporation: {
      return mapCorporation(dto);
    }
    case Concept.event: {
      return mapEvent(dto);
    }
    default: {
      assert.soft(
        false,
        'mapAgentSearchResult: agentType is not supported yet',
        {
          agentType,
        },
      );
      return undefined;
    }
  }
};

const mapPerson = (dto: SearchAgentDto): AgentPerson => {
  return {
    agentType: Concept.person,
    id: dto.id,
    nameVariants: mapNameVariants(dto),
    birth: mapAgentSearchDate(dto.birth),
    death: mapAgentSearchDate(dto.death),
    externalComments: dto.externalComments ?? undefined,
    internalComments: dto.internalComments ?? undefined,
    isni: dto.isni ?? undefined,
    pseudonym: dto.isPseudonym ?? undefined,
    nationalId: dto.nationalId ?? undefined,
    occupationalField: dto.occupationalField ?? undefined,
    profession: dto.profession ?? undefined,
    gender: dto.gender as Gender,
    country: dto.country,
    countryOfBirth: dto.countryOfBirth ?? undefined,
  };
};

const mapPublisher = (dto: SearchAgentDto): AgentPublisher => {
  return {
    id: dto.id,
    agentType: Concept.publisher,
    nameVariants: mapNameVariants(dto),
    externalComments: dto.externalComments ?? undefined,
    internalComments: dto.internalComments ?? undefined,
    isni: dto.isni ?? undefined,
    nationalId: dto.nationalId ?? undefined,
    place: dto.place ?? undefined,
  };
};

const mapCorporation = (dto: SearchAgentDto): AgentCorporation => {
  return {
    agentType: Concept.corporation,
    id: dto.id,
    nameVariants: mapNameVariants(dto),
    externalComments: dto.externalComments ?? undefined,
    internalComments: dto.internalComments ?? undefined,
    isni: dto.isni ?? undefined,
    nationalId: dto.nationalId ?? undefined,
    validFrom: undefined, // TODO: Incorrect format from elastic
    validTo: undefined, // TODO: Incorrect format from elastic
  };
};
const mapEvent = (dto: SearchAgentDto): AgentEvent => {
  return {
    agentType: Concept.event,
    id: dto.id,
    nameVariants: mapNameVariants(dto),
    externalComments: dto.externalComments ?? undefined,
    internalComments: dto.internalComments ?? undefined,
    isni: dto.isni ?? undefined,
    nationalId: dto.nationalId ?? undefined,
    place: dto.place ?? undefined,
    from: undefined, // TODO: Incorrect format from elastic
    to: undefined, // TODO: Incorrect format from elastic
  };
};

const mapNameVariants = (agentDto: SearchAgentDto): AgentName[] => {
  const {nameVariants} = agentDto;
  return (nameVariants ?? []).map<AgentName>(nv => ({
    id: nv.id ?? undefined,
    name: nv.name ?? undefined,
    surName: nv.surName ?? undefined,
    regnalNumber: nv.regnalNumber ?? undefined,
    addition: nv.addition ?? undefined,
    description: agentDto.description ?? undefined,
  }));
};
