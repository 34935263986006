import {assert} from 'assert-ts';
import {
  Data,
  DataValue,
  GroupedParts,
  Part,
  PartSeparator,
  SeparatedParts,
  Valx,
} from 'schemaDefinition/types';
import {isNullish} from 'services/utils';

const hasValue = (value: DataValue): boolean => {
  if (Array.isArray(value)) {
    return value.some(hasValue);
  }

  return (
    !isNullish(value) &&
    // Non-empty string
    (typeof value !== 'string' || value.trim().length > 0) &&
    // Non-empty object
    (typeof value !== 'object' || Object.keys(value).length > 0)
  );
};

const hasPartValue = (
  part: Part<Valx>,
  value: Data,
  excludeMainForm: boolean,
): boolean => {
  switch (part.type) {
    case 'expand': {
      assert.soft(false, 'removeEmptyParts: expand not implemented');
      return true;
    }
    case 'nameVariant': {
      const partValue = value[part.name];

      if (!excludeMainForm) {
        return hasValue(partValue);
      }

      return Array.isArray(partValue) && partValue.length > 1;
    }
    default: {
      const partValue = value[part.name];
      return hasValue(partValue);
    }
  }
};

export const removeEmptyParts = (
  parts: GroupedParts<Valx>[],
  value: Data,
  mode: 'form' | 'preview',
  excludeMainForm = false,
): GroupedParts<Valx>[] => {
  // Groups with at least one non-empty part
  const nonEmptyGroups = parts.reduce<GroupedParts<Valx>[]>((acc, group) => {
    let filteredParts = group.parts.reduce<SeparatedParts<Valx>>(
      (groupAcc, part) => {
        if (!Array.isArray(part) && part.type === 'separator') {
          if (
            groupAcc.length === 0 ||
            (groupAcc[groupAcc.length - 1] as PartSeparator).type !==
              'separator'
          ) {
            groupAcc.push(part);
          }
          return groupAcc;
        }

        if (Array.isArray(part)) {
          const filteredRowParts = part.filter(rp =>
            hasPartValue(rp, value, excludeMainForm),
          );
          if (filteredRowParts.length > 0) {
            groupAcc.push(filteredRowParts);
          }
          return groupAcc;
        }

        if (hasPartValue(part, value, excludeMainForm)) {
          groupAcc.push(part);
        }

        return groupAcc;
      },
      [],
    );

    if (filteredParts.some(p => Array.isArray(p) || p.type !== 'separator')) {
      const firstPart = filteredParts[0] as PartSeparator;
      if (firstPart.type === 'separator' && firstPart[mode] !== 'card') {
        filteredParts = filteredParts.slice(1);
      }

      const lastPart = filteredParts[filteredParts.length - 1] as PartSeparator;
      if (lastPart.type === 'separator') {
        filteredParts = filteredParts.slice(0, -1);
      }

      acc.push({...group, parts: filteredParts});
    }

    return acc;
  }, []);

  return nonEmptyGroups;
};
