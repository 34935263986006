import {assert} from 'assert-ts';
import {DataValue, LinkedValue} from 'schemaDefinition/types';
import {assertAsLinkedValue} from 'schemaDefinition/functions/assertAsLinkedValue';

type RoleWithLinks = {
  role: string;
  links: LinkedValue[];
};
export const getLinkedValuesByRoles = (value: DataValue): RoleWithLinks[] => {
  const values = (Array.isArray(value) ? value : [value]).map(v =>
    assert(assertAsLinkedValue(v)),
  );
  const roles = values.reduce<string[]>((acc, val) => {
    val.roles?.forEach(r => {
      if (!acc.includes(r)) {
        acc.push(r);
      }
    });
    return acc;
  }, []);

  const rolesWithLinks = roles.map(role => ({
    role,
    links: values.filter(val => val.roles?.includes(role)),
  }));

  return rolesWithLinks;
};
