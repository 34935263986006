import {createContext, useContext, useMemo} from 'react';
import {assert} from 'assert-ts';
import {Data, Schema, Valx} from 'schemaDefinition/types';

export type PreviewSchemaScopeContextType = {
  schema: Schema<Valx>;
  values: Data;
};

export const PreviewSchemaGlobalScopeContext = createContext<
  PreviewSchemaScopeContextType | undefined
>(undefined);

export const usePreviewSchemaGlobalScopeContextProviderValue = (
  schema: Schema<Valx>,
  values: Data,
): PreviewSchemaScopeContextType =>
  useMemo(
    () => ({
      schema,
      values,
    }),
    [schema, values],
  );

export const usePreviewSchemaGlobalScopeContext =
  (): PreviewSchemaScopeContextType => {
    return assert(
      useContext(PreviewSchemaGlobalScopeContext),
      'usePreviewSchemaGlobalScopeContext: context expected',
    );
  };

export const PreviewSchemaLocalScopeContext = createContext<
  PreviewSchemaScopeContextType | undefined
>(undefined);

export const usePreviewSchemaLocalScopeContext =
  (): PreviewSchemaScopeContextType => {
    return assert(
      useContext(PreviewSchemaLocalScopeContext),
      'usePreviewSchemaLocalScopeContext: context expected',
    );
  };
