import React, {Fragment} from 'react';
import {NoProps} from '../types';
import {useBrowserDescription} from '../services/head/hooks/useBrowserDescription';
import {useBrowserTabTitle} from '../services/head/hooks/useBrowserTabTitle';
import {ThemeColors} from '../theme/themeColors';

type Props = NoProps;

export const Head: React.FC<Props> = () => {
  const {browserTabTitle} = useBrowserTabTitle();
  const {browserDescription} = useBrowserDescription();

  const title = browserTabTitle ? `${browserTabTitle} | Ebba` : 'Ebba';

  return (
    <Fragment>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content={ThemeColors.text.primary.main} />
      <meta name="description" content={browserDescription} />
      <title>{title}</title>
    </Fragment>
  );
};
