import {useCallback} from 'react';
import {useStore} from 'react-redux';
import {produce} from 'immer';
import set from 'lodash/set';
import {DataValue} from 'schemaDefinition/types';
import {AppState} from 'store/types';
import {ExpressionV4} from 'api';
import {
  MetadataEditAction,
  MetadataEditAction_ExpressionEdited,
} from '../metadataEditActionTypes';

export const useSetExpressionEditValue = (
  entityId: string,
  editActionType: MetadataEditAction_ExpressionEdited['type'],
) => {
  const {getState, dispatch} = useStore<AppState, MetadataEditAction>();
  return useCallback(
    (localPath: string, value: DataValue) => {
      const expression = ((
        getState().metadataEdit.metadata?.expressions ?? []
      ).find(e => e.id === entityId) ?? {}) as ExpressionV4;
      const newExpression = produce(expression, draft => {
        set(draft, localPath, value);
      });

      const action: MetadataEditAction = {
        type: editActionType,
        payload: newExpression,
      };

      dispatch(action);
    },
    [dispatch, editActionType, entityId, getState],
  );
};
