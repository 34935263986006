/* eslint-disable @typescript-eslint/no-unused-vars */
import {useMemo} from 'react';
import {DateRange, PickersShortcutsItem} from '@mui/x-date-pickers-pro';
import dayjs, {Dayjs} from 'dayjs';
import {useLocalization} from 'localization';

export const useDateRangeShortcuts = (): PickersShortcutsItem<
  DateRange<Dayjs>
>[] => {
  const {t} = useLocalization();

  return useMemo(() => {
    return [
      {
        label: 'Denne måneden',
        getValue: () => {
          const today = dayjs();
          return [today.startOf('month'), today.endOf('month')];
        },
      },
      {
        label: 'Forrige måned',
        getValue: () => {
          const today = dayjs();
          const lastMonth = today.subtract(1, 'month');
          return [lastMonth.startOf('month'), lastMonth.endOf('month')];
        },
      },
      {
        label: 'Dette året',
        getValue: () => {
          const today = dayjs();
          return [today.startOf('year'), today.endOf('year')];
        },
      },
      {
        label: 'Forrige år',
        getValue: () => {
          const today = dayjs();
          const lastYear = today.subtract(1, 'year');
          return [lastYear.startOf('year'), lastYear.endOf('year')];
        },
      },
      {
        label: 'Siste 3 år',
        getValue: () => {
          const today = dayjs();
          const yearsAgo = today.subtract(3, 'year');
          return [yearsAgo.startOf('year'), today.endOf('year')];
        },
      },
      {label: 'Nullstill', getValue: () => [null, null]},
    ];
  }, []);
};
