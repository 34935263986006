import React from 'react';
import {Layout} from 'components';
import {useShortcut} from '../../../shortcuts';
import {useOverviewContext} from '../context/OverviewContext';
import {useTaskTypeCounts} from '../hooks';
import {TaskStatus} from './TaskStatus';

export const TasksToolbar: React.FC = () => {
  const {allTasks, selectedTaskType, taskTypes, setSelectedTaskType, loading} =
    useOverviewContext();

  const counts = useTaskTypeCounts(allTasks);

  useShortcut('showNew', () => setSelectedTaskType('new'));
  useShortcut('showPre', () => setSelectedTaskType('pre'));
  useShortcut('showChanged', () => setSelectedTaskType('change'));

  return (
    <Layout horizontal adjustRight flex={1}>
      {taskTypes.map(f => (
        <TaskStatus
          key={f.task}
          checked={f.checked}
          task={f.task}
          count={counts[f.task]}
          toggle={() => {
            setSelectedTaskType(f.task !== selectedTaskType ? f.task : 'all');
          }}
          loading={loading}
        />
      ))}
    </Layout>
  );
};
