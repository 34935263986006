import {DateValue} from '../types';

type Comparison = -1 | 1 | 0;

/**
 * Returns:
 *  -1 a before b
 *   0  keep order
 *   1  b before a
 */
export const compareDateValues = (a: DateValue, b: DateValue): Comparison => {
  if (a.year < b.year) {
    return -1;
  }
  if (a.year > b.year) {
    return 1;
  }

  if (a.month < b.month) {
    return -1;
  }
  if (a.month > b.month) {
    return 1;
  }

  if (a.day < b.day) {
    return -1;
  }
  if (a.day > b.day) {
    return 1;
  }

  return 0;
};
