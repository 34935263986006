import React, {useCallback, useMemo, useState} from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import {SxProps, styled} from '@mui/material/styles';
import assert from 'assert-ts';
import {px2rem} from 'theme';
import {ColorPalette} from 'theme/colorPalette';
import {ActionButtonProps} from './types';
import {ActivityIndicator} from '../activityIndicator';
import {Icon} from '../icons';
import {Layout} from '../layout';
import {Spacer} from '../spacer';
import {Text} from '../text';

const largeStyle = {
  // TODO: Use theme colors ({ theme })
  display: 'flex',
  borderRadius: 999,
  borderStyle: 'solid',
  borderWidth: '1.5px',
  height: px2rem(34),
  paddingLeft: px2rem(16),
  paddingRight: px2rem(16),
  '&[type="reset"]': {
    background: ColorPalette.offWhite,
  },
} as const;

const ActionButtonRaw = styled(ButtonBase)(() => largeStyle);

const ActionButtonTransitionRaw = styled(ButtonBase)(() => ({
  ...largeStyle,
  transition: '0.2s',
}));

const smallStyle = {
  display: 'flex',
  background: 'transparent',
  borderRadius: 999,
  height: px2rem(20),
  paddingLeft: px2rem(8),
  paddingRight: px2rem(8),
  '&[type="reset"]': {
    background: ColorPalette.offWhite,
  },
} as const;
const SmallActionButtonRaw = styled(ButtonBase)(() => smallStyle);

const SmallActionButtonTransitionRaw = styled(ButtonBase)(() => ({
  ...smallStyle,
  transition: '0.2s',
}));

const disabledSx = {
  opacity: 0.5,
};

const layoutSx = {
  flex: 1,
} as const;

export const ActionButton: React.FC<ActionButtonProps> = ({
  icon,
  showSpinnerOnClick,
  disabled,
  title,
  type,
  size,
  background,
  borderColor,
  noTransition = false,
  formId,
  onClick,
  'data-cy': dataCy,
}) => {
  // const {tLoose} = useLocalization();
  // const title = tLoose(`page.overview.task.type.${task}.title`);
  const [handlingClick, setHandlingClick] = useState<boolean>(false);
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setHandlingClick(true);
      Promise.resolve(onClick())
        .catch(error => {
          assert.soft(
            false,
            'ActionButton: onClick failed unexpectedly',
            error,
          );
        })
        .finally(() => setHandlingClick(false));

      e.preventDefault();
      e.stopPropagation();
    },
    [onClick],
  );

  const busy = showSpinnerOnClick ? handlingClick : undefined;
  const Raw = noTransition
    ? size === 'small'
      ? SmallActionButtonRaw
      : ActionButtonRaw
    : size === 'small'
    ? SmallActionButtonTransitionRaw
    : ActionButtonTransitionRaw;

  const sx = useMemo(
    (): SxProps => ({
      ...(disabled || handlingClick ? disabledSx : {}),
      background:
        background === 'transparent'
          ? undefined
          : background
          ? ColorPalette[background]
          : ColorPalette.secondary.brown,
      borderColor: borderColor
        ? ColorPalette[borderColor]
        : size === 'small'
        ? undefined
        : ColorPalette.secondary.lightBurgundy,
    }),
    [background, borderColor, disabled, handlingClick, size],
  );

  return (
    <Raw
      onClick={handleClick}
      disableRipple
      disabled={disabled || handlingClick}
      type={type}
      sx={sx}
      form={formId}>
      <Layout
        kind="primary"
        horizontal
        adjustCenter
        sx={layoutSx}
        data-cy={dataCy}>
        {/* Busy not used => display icon, if any */}
        {busy === undefined ? (
          <>
            {icon ? (
              <Icon icon={icon} fontSize={size} color={'primary'} />
            ) : null}
            {icon ? <Spacer size={0.5} /> : null}
            <Text variant="h4">{title}</Text>
          </>
        ) : // Busy and icon => replace icon with spinner
        busy ? (
          icon ? (
            <>
              <Spacer size={0.5} />
              <ActivityIndicator size={'small'} />
              <Spacer size={1} />
              <Text variant="h4">{title}</Text>
            </>
          ) : (
            // Busy and no icon => show spinner in allocated space
            <>
              <Spacer size={0.5} />
              <ActivityIndicator size={'small'} />
              <Spacer size={1} />
              <Text variant="h4">{title}</Text>
            </>
          )
        ) : icon ? (
          // Not busy and icon => display icon
          <>
            <Icon icon={icon} fontSize={size} color={'primary'} />
            <Spacer size={0.5} />
            <Text variant="h4">{title}</Text>
          </>
        ) : (
          // Not busy and no icon => allocate space for spinner
          <>
            <Spacer size={1.75} />
            <Text variant="h4">{title}</Text>
            <Spacer size={1.75} />
          </>
        )}
      </Layout>
    </Raw>
  );
};
