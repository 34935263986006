import {assert} from 'assert-ts';
import {Collection} from 'api/types';
import {isCollectionValue} from './isCollectionValue';

export const assertCollectionValue = (value: unknown): Collection => {
  assert(
    isCollectionValue(value),
    'assertCollectionValue: invalid collection',
    {value},
  );

  return value as Collection;
};
