import {valueAsNumber} from 'services/utils';
import {LengthCondition, Valx} from '../types';
import {EvaluationArgs} from './types';
import {evaluateValx} from './evaluateValx';
import {evaluateValxWithDefault} from './evaluateValxWithDefault';

export const evaluateLengthCondition = (
  condition: LengthCondition<Valx>,
  args: EvaluationArgs,
): boolean => {
  const minLength = condition.minLength
    ? valueAsNumber(evaluateValx(condition.minLength, args))
    : undefined;
  const maxLength = condition.maxLength
    ? valueAsNumber(evaluateValx(condition.maxLength, args))
    : undefined;
  const arg1 = evaluateValxWithDefault(condition.arg1, args, args.value);

  return (
    (typeof arg1 === 'string' || Array.isArray(arg1)) &&
    (minLength === undefined || arg1.length >= minLength) &&
    (maxLength === undefined || arg1.length <= maxLength)
  );
};
