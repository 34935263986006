import {ByNameRef, GlobalRef, Reference, RelatedRef} from '../types';

export const isRef = (ref: unknown): ref is Reference => {
  if (ref === null || typeof ref !== 'object') {
    return false;
  }

  const keys = Object.keys(ref);
  return keys.length === 1 && keys[0] === '$ref';
};

export const isPropRef = (ref: unknown): ref is ByNameRef =>
  typeof ref === 'string' && ref.startsWith('.');

export const isByNameRef = (ref: unknown): ref is ByNameRef =>
  typeof ref === 'string' && ref.startsWith('#');

export const isGlobalRef = (ref: unknown): ref is GlobalRef =>
  typeof ref === 'string' && ref.startsWith('/');

export const isRelatedRef = (ref: unknown): ref is RelatedRef =>
  typeof ref === 'string' && ref.startsWith('^');
