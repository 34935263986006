import {TableConfiguration} from 'schema/table/types';
import {defaultTableConfig} from 'schema/table/configuration/defaultTableConfig';
import {Isbn} from '../../renderers';
import {EanLink} from '../../renderers/table';
import {advancedSearchDefaultColumns} from './columns';

export const advancedSearchTableConfig: TableConfiguration = {
  ...defaultTableConfig,
  defaultColumns: advancedSearchDefaultColumns,
  hideColumnSelector: true,
  disableFiltering: true,
  partValueDelimiter: {
    generalNote: '; ',
    workGeneralNote: '; ',
  },
  partValueRenderers: {
    ean: EanLink,
    isbn: Isbn,
  },
  hideFooter: false,
  pinnedColumns: {
    left: ['ean'],
  },
};
