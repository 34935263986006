import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {Concept} from 'types';
import {isVerifiedLinkedWorkLink} from 'schemaDefinition';
import {useMetadataSaveState} from 'services/data/metadata/hooks/useMetadataSaveState';
import {assertWorkWithExpressions} from 'services/utils/functions';
import {Icon, IconButton, Layout, Spacer, Text} from 'components';
import {useLinkedValueSearchContext} from 'sceneExtensions';
import {LinkedType} from '../types';
import {EntityPreviewHeader} from './types';
import {LinkCatalogPostButtons} from './LinkCatalogPostButtons';

export const WorkPreviewHeader: EntityPreviewHeader = ({
  entityValue,
  canLink,
  canMove,
  canCopy,
  linkingConfiguration,
}) => {
  const work = assertWorkWithExpressions(entityValue);
  const sourceConfig = useLinkedValueSearchContext().sourceField?.fieldConfig;
  const navigate = useNavigate();
  const saving = useMetadataSaveState();

  const {title, linked, isCurrentSource} = useMemo((): {
    title: string;
    linked: LinkedType;
    isCurrentSource: boolean;
  } => {
    const currentValue = linkingConfiguration?.currentValue;
    return {
      title: work.preferredTitle?.[0] ?? '',
      linked:
        isVerifiedLinkedWorkLink(currentValue) &&
        currentValue.entityId === entityValue.id
          ? 'verified'
          : 'none',
      isCurrentSource:
        sourceConfig?.entity === Concept.work &&
        sourceConfig?.sourceEntityId === work.id,
    };
  }, [
    entityValue.id,
    linkingConfiguration?.currentValue,
    sourceConfig?.entity,
    sourceConfig?.sourceEntityId,
    work.id,
    work.preferredTitle,
  ]);

  return (
    <Layout
      paddingLeft={linked ? 1 : 2}
      paddingRight={1}
      alignItems="center"
      justifyContent="space-between"
      horizontal
      flex={1}>
      <Layout horizontal adjustCenter>
        {isCurrentSource ? (
          <>
            <Icon icon={'ArrowRight'} color={'primary'} />
            <Spacer width={1} />
          </>
        ) : linked === 'none' ? null : (
          <>
            <Icon icon={'CheckCircle'} />
            <Spacer width={1} />
          </>
        )}
        <Text variant="h2">{title}</Text>
        <IconButton
          icon={'Preview'}
          disabled={saving}
          onClick={() => navigate(`/metadata/${work.id}`)}
        />
      </Layout>
      {linkingConfiguration && (canLink || canMove || canCopy) ? (
        <Layout horizontal adjustCenter>
          <LinkCatalogPostButtons
            title={title}
            linked={linked}
            linkingConfiguration={linkingConfiguration}
            entityValue={work}
          />
        </Layout>
      ) : null}
    </Layout>
  );
};
