import {Collection, GetTokens} from './types';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpGet, httpPost, httpPut} from './http/ebba';
import {
  mapGetCollectionDto,
  mapToUpsertCollectionDto,
} from './mappers/mapCollectionDto';

export const getCollection = (
  id: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<Collection> => {
  return exceptionToPromiseReject(() =>
    httpGet<Schemas.SeriesWithRelationsDto>({
      subdir: 'series/{id}',
      subdirParams: {id},
      mock,
      getTokens,
    }).then(mapGetCollectionDto),
  );
};

export const postCollection = (
  collection: Collection,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<Collection> => {
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.SeriesWithRelationsDto, Schemas.CreateOrUpdateSeriesDto>({
      subdir: 'series/',
      mock,
      body: mapToUpsertCollectionDto(collection),
      getTokens,
    }).then(mapGetCollectionDto),
  );
};

export const putCollection = (
  collection: Collection,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<Collection> => {
  return exceptionToPromiseReject(() =>
    httpPut<Schemas.SeriesWithRelationsDto, Schemas.CreateOrUpdateSeriesDto>({
      subdir: 'series/{id}',
      subdirParams: {id: collection.id},
      mock,
      body: mapToUpsertCollectionDto(collection),
      getTokens,
    }).then(mapGetCollectionDto),
  );
};
