import {FilterSet} from 'api/types';
import {Schemas} from '../dto.generated';
import {sortFilterSets} from '../http/functions';
import {mapFilters} from './mapFilters';

export const mapSavedFilters = (dto: Schemas.FilterSet[]): FilterSet[] => {
  return dto.map(mapSavedFilter).sort(sortFilterSets);
};

export const mapSavedFilter = (dto: Schemas.FilterSet): FilterSet => {
  return {
    ...dto,
    filters: mapFilters(dto.filters),
  };
};
