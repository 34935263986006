import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import {DateTimeValue, Variant} from './types';

dayjs.extend(LocalizedFormat);

export const formatDateNo = (
  date?: DateTimeValue,
  variant: Variant = 'short',
  withTime?: boolean,
): string => {
  if (!date) return '';

  switch (variant) {
    case 'long':
      return dayjs(date).format(`ll${withTime ? 'l' : ''}`);
    case 'short':
      return dayjs(date).format(`DD.MM.YYYY${withTime ? ' HH:mm' : ''}`);
    case 'x-short':
      return dayjs(date).format(`DD.MM ${withTime ? ' HH:mm' : ''}`);
  }
};
