import {LooseTranslate} from 'localization/core/translation/coreTypes';
import {ValidationResult} from './validators/types';

const defaultExcludeErrorKeys = ['required'];

export const getErrorMessage = (
  validation: ValidationResult,
  tLoose: LooseTranslate,
  excludeKeys: string[] = defaultExcludeErrorKeys,
): string | undefined => {
  if (validation.valid === 'valid') {
    return undefined;
  }

  const error = validation.errors.filter(
    error => !error.messageKey || !excludeKeys.includes(error.messageKey),
  )[0];

  return error && error.messageKey
    ? tLoose(`form.errors.${error.messageKey}`)
    : undefined;
};
