import {assert} from 'assert-ts';
import {ExpressionV4} from 'api/types';
import {MetadataEditState} from '../types';

export const assertExpression = (
  expressionId: string,
  state: Pick<MetadataEditState, 'metadata'>,
): ExpressionV4 => {
  return assert(
    state.metadata?.expressions.find(e => e.id === expressionId),
    `metadataEditReducer.assertExpression: expression not found`,
    {expressionId},
  );
};
