import {useMemo} from 'react';
import {FCWithChildren} from 'types';
import {CustomTextProps} from 'schema/types';
import {ColorPalette} from 'theme';
import {Text} from 'components';
import {usePreviewConfigurationContext} from '../contexts';

const DefaultTextLabel: FCWithChildren = ({children}) => (
  <Text
    component={'span'}
    variant={'caption'}
    textTransform="uppercase"
    sx={{color: ColorPalette.textSecondary}}>
    {children}
  </Text>
);

export const useTextLabel = (props?: CustomTextProps): FCWithChildren => {
  const configuration = usePreviewConfigurationContext();
  return useMemo(() => {
    const textProps =
      props || configuration.text?.label
        ? ({...configuration.text?.label, ...props} as CustomTextProps)
        : undefined;
    return textProps
      ? ((({children}) => (
          <Text component={'span'} {...textProps}>
            {children}
          </Text>
        )) as FCWithChildren)
      : DefaultTextLabel;
  }, [configuration.text?.label, props]);
};
