import React, {useCallback, useEffect, useState} from 'react';
import {useLocalization} from 'localization';
import {isNullish} from 'services/utils';
import {Layout} from 'components/layout';
import {BaseFieldProps} from './types';
import {FieldLabel} from './FieldLabel';
import {NullFieldPlaceholder} from './NullFieldPlaceholder';
import {TextField} from './TextField';

type Props = BaseFieldProps & {
  /** Year as number, e.g. 2021, or null */
  value?: number | null;
  /** Default 1600 */
  minValue?: number;
  /** Default next year */
  maxValue?: number;
  name: string;
  onChange: (value: number | null) => void;
  onBlur?: () => void;
};

export const YearPickerField: React.FC<Props> = ({
  name,
  label,
  value,
  readonly,
  showWhenReadonlyAndEmpty = false,
  required = false,
  error,
  width,
  maxWidth,
  flex,
  onChange,
  onBlur,
}) => {
  const {t} = useLocalization();
  const defaultValue: string | null = !isNullish(value)
    ? ('0000' + value).slice(-4)
    : null;

  // Local value is string, because it's string we get from the input.
  const [localValue, setLocalValue] = useState<string | null>(defaultValue);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      // Remove first digit, if last one makes the length exceed 4 digits.
      const v = event.target.value.replaceAll('-', '');
      const fourDigits = v.length > 4 ? v.substring(1) : v;
      const newValue = fourDigits === '' ? null : Number(fourDigits);

      if (newValue === null || !Number.isNaN(newValue)) {
        setLocalValue(fourDigits);
        onChange(newValue);
      }
    },
    [onChange],
  );

  // When  value changes from outside
  // => update local value
  useEffect(() => {
    setLocalValue(defaultValue);
  }, [defaultValue]);

  return (
    <Layout width={width} maxWidth={maxWidth} flex={flex}>
      {label && (
        <FieldLabel
          label={label}
          required={required}
          error={error}
          htmlFor={name}
        />
      )}
      {readonly && !localValue && !showWhenReadonlyAndEmpty ? (
        <NullFieldPlaceholder />
      ) : (
        <TextField
          type={'number'}
          placeholder={t('form.format.yyyy')}
          name={name}
          required={required}
          error={error}
          onBlur={onBlur}
          onChange={handleChange}
          readonly={readonly}
          value={localValue}
        />
      )}
    </Layout>
  );
};
