import {ThesaurusNode} from 'api/types';

export const findThesaurusNode = (
  id: string,
  thesaurus: ThesaurusNode,
): ThesaurusNode | undefined => {
  if (id === thesaurus.id || id === thesaurus.code) {
    return {
      id: thesaurus.id,
      code: thesaurus.code,
      label: thesaurus.label,
    };
  }

  let match: ThesaurusNode | undefined = undefined;
  if (thesaurus.children) {
    thesaurus.children.forEach(child => {
      if (match) {
        return;
      }

      match = findThesaurusNode(id, child);
    });
  }

  return match;
};
