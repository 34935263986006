import {assert} from 'assert-ts';

export const moveItem = <T>(
  items: T[],
  fromIdx: number,
  toIndex: number,
): T[] => {
  assert(
    fromIdx >= 0 && fromIdx < items.length,
    'moveItem: fromIdx out of bounds',
  );
  assert(
    toIndex >= 0 && toIndex < items.length,
    'moveItem: toIndex out of bounds',
  );
  const newValue = [...items];
  const [moved] = newValue.splice(fromIdx, 1);
  newValue.splice(toIndex, 0, moved);
  return newValue;
};
