import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const MusicIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M19 4H13C12.4696 4 11.9609 4.21071 11.5858 4.58579C11.2107 4.96086 11 5.46957 11 6V12.57C10.236 12.1426 9.35542 11.9702 8.48663 12.0782C7.61785 12.1861 6.80622 12.5686 6.17 13.17C5.70528 13.6352 5.36243 14.2079 5.17179 14.8372C4.98115 15.4665 4.9486 16.1332 5.07703 16.7781C5.20546 17.423 5.4909 18.0263 5.90809 18.5346C6.32528 19.0429 6.86135 19.4405 7.46884 19.6922C8.07633 19.9439 8.73651 20.042 9.39094 19.9777C10.0454 19.9134 10.6739 19.6888 11.2208 19.3237C11.7677 18.9586 12.2162 18.4644 12.5265 17.8846C12.8369 17.3049 12.9995 16.6576 13 16V10H19V4ZM10.41 17.41C10.0353 17.7825 9.52837 17.9916 9 17.9916C8.47163 17.9916 7.96472 17.7825 7.59 17.41C7.3091 17.1308 7.11744 16.7744 7.0393 16.3861C6.96117 15.9979 7.00008 15.5951 7.1511 15.229C7.30213 14.8628 7.55847 14.5498 7.88762 14.3295C8.21678 14.1092 8.60393 13.9916 9 13.9916C9.39607 13.9916 9.78322 14.1092 10.1124 14.3295C10.4415 14.5498 10.6979 14.8628 10.8489 15.229C10.9999 15.5951 11.0388 15.9979 10.9607 16.3861C10.8826 16.7744 10.6909 17.1308 10.41 17.41ZM17 8H13V6H17V8Z" />
    </SvgIcon>
  );
};
