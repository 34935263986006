import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const CheckIcon: React.FC<IconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 26">
      <path d="M17.1205 8.5L10.5 15.43L6.47015 11.62L5.03015 13L10.5 18.31L18.5605 9.9L17.1205 8.5Z" />
    </SvgIcon>
  );
};
