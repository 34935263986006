import get from 'lodash/get';
import {PartVisitor} from 'schemaDefinition/functions/types';
import {Data, DataFormValueType, Schema} from 'schemaDefinition/types';
import {visitVisibleSchemaParts} from 'schemaDefinition/functions';

export const getVisiblePartPaths = (
  schema: Schema<DataFormValueType>,
  data: Data,
  relatedScope: Data,
  onlyWithValue: boolean = false,
): string[] => {
  const visiblePartPaths: string[] = [];
  const visitor: PartVisitor<DataFormValueType> = (_part, partPath) => {
    const value = get(data, partPath);
    if (onlyWithValue) {
      if (value) {
        visiblePartPaths.push(partPath);
      }
    } else {
      visiblePartPaths.push(partPath);
    }
  };

  visitVisibleSchemaParts(data, relatedScope, schema, visitor);

  return visiblePartPaths;
};
