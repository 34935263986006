import {LinkedValueLink, VerifiedLinkedValueLink} from 'schemaDefinition/types';
import {isLinkedAgentLink} from 'schemaDefinition/functions/isLinkedAgentLink';
import {formatNameVariantValue} from '../../preview/functions';

export const formatLinkedValueLink = (
  link: LinkedValueLink | undefined,
): string | undefined => {
  if (!link || !link.linkStatus || link.linkStatus === 'empty') {
    return undefined;
  }

  if (link.linkStatus === 'unverified' || link.linkStatus === 'unnamed') {
    return link.name ?? '';
  }

  const verified = link as VerifiedLinkedValueLink;

  return isLinkedAgentLink(verified)
    ? formatNameVariantValue(verified.agentName, verified.type, 'excludeId')
    : verified.name;
};
