import {useMemo} from 'react';
import {Concept} from 'types';
import {MetadataValidationResultV0, SchemaName} from 'schemas/types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {ManifestationStatus} from 'api';
import {Dot, Layout, Spacer, Text} from 'components';
import {
  getMetadataValidationPrPart,
  getSchemaPartLabel,
  getValidationLevels,
} from '../functions';

type Props = {
  entity: Concept.work | Concept.expression | Concept.manifestation;
  status: ManifestationStatus | undefined;
  validation: MetadataValidationResultV0;
};

type ValidationEntities = keyof Omit<MetadataValidationResultV0, 'valid'>;
const entities: ValidationEntities[] = [
  Concept.work,
  Concept.expression,
  Concept.manifestation,
];

const entityToSchemaNameMap: {[key in ValidationEntities]: SchemaName} = {
  work: Concept.work,
  expression: Concept.expression,
  manifestation: Concept.manifestation,
};

export const ValidationErrors: React.FC<Props> = ({
  // entity,
  status,
  validation,
}) => {
  const localization = useLocalization();
  const {t, tLoose} = localization;

  const entityErrorsPrPart = useMemo(
    () => getMetadataValidationPrPart(validation),
    [validation],
  );
  const validationLevels = useMemo(
    () => getValidationLevels(entityErrorsPrPart),
    [entityErrorsPrPart],
  );
  const {pretext, posttext} = useMemo(() => {
    if (!(validationLevels && status)) {
      return {pretext: undefined, posttext: undefined};
    }

    const statusText = tLoose(`entity.manifestation.status.full.${status}`);
    return {
      pretext: tLoose(`page.metadata.saveWith.${validationLevels}.pretext`, {
        status: statusText,
      }),
      posttext:
        validationLevels === 'warning'
          ? t('page.metadata.saveWith.warning.posttext', {
              status: statusText,
            })
          : undefined,
    };
  }, [status, t, tLoose, validationLevels]);

  if (validation.valid === 'valid') {
    return null;
  }

  return validationLevels ? (
    <Layout>
      <Text variant="body2">{pretext}</Text>
      <Spacer size={1} />
      {entities.map(entity => {
        const entityErrors = entityErrorsPrPart[entity];
        if (!entityErrors || entityErrors.errors.length === 0) {
          return null;
        }

        return (
          <Layout key={entity}>
            <Text variant="body2" sx={{fontWeight: 'bold'}}>
              {tLoose(`entity.${entity}.title`)}
            </Text>
            <Spacer size={0.5} />
            {entityErrors.errors.map(error => (
              <Layout key={error.part.name} horizontal adjustCenter adjustLeft>
                <Dot color={ColorPalette[error.level]} />
                <Spacer size={0.5} />
                <Text
                  variant="body2"
                  // kind={error.level === 'error' ? 'error' : undefined}
                  textTransform="capitalize">
                  {getSchemaPartLabel(
                    entityToSchemaNameMap[entity],
                    error.part,
                    localization,
                  )}
                </Text>
              </Layout>
            ))}
            <Spacer size={1.5} />
          </Layout>
        );
      })}
      {posttext ? <Text variant="body2">{posttext}</Text> : null}
    </Layout>
  ) : null;
};
