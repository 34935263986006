import {Thesaurus, ThesaurusValue} from 'api';
import {CategoryNodesWithAncestors} from '../types';
import {getNodesAndAncestors} from './getNodesAndAncestors';
import {groupPrCategory} from './groupPrCategory';
import {orderByThesaurusCategories} from './orderByThesaurusCategories';

export const groupAndOrderByCategory = (
  thesaurus: Thesaurus,
  thesaurusValue: ThesaurusValue | undefined,
): CategoryNodesWithAncestors[] => {
  const nodesWithAncestors = thesaurusValue
    ? getNodesAndAncestors(thesaurusValue, thesaurus)
    : [];
  const grouped = groupPrCategory(nodesWithAncestors);
  return orderByThesaurusCategories(grouped, thesaurus, g => g.categoryNode.id);
};
