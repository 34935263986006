import assert from 'assert-ts';
import dayjs from 'dayjs';
import {ChangeRequest, CodeListRef, ExternalSuggestions} from '../types';
import {Schemas} from '../dto.generated';
import {mapExpressionBaseDto} from './mapExpressionBaseDto';
import {mapManifestationBaseDto} from './mapManifestationBaseDto';
import {mapWorkBaseDto} from './mapWorkBaseDto';

export const mapManualApprovalChangeRequestsDto = (
  workId: string,
  dto: Schemas.ManualApprovalChangeRequestsDto,
): ChangeRequest[] => {
  return (dto.changeRequests ?? []).map<ChangeRequest>(cr =>
    mapManualApprovalChangeRequestDto(workId, cr),
  );
};

const mapManualApprovalChangeRequestDto = (
  workId: string,
  dto: Schemas.ManualApprovalChangeRequestDto,
): ChangeRequest => {
  const {work, expression, manifestation, externalSuggestionsDto} = dto;
  const unixNow = dayjs().unix();

  assert(
    work || expression || manifestation || externalSuggestionsDto,
    'mapManualApprovalChangeRequestDto: Expected work, expression, manifestation or externalSuggestions',
    dto,
  );

  return {
    id: assert(
      dto.refId,
      'mapManualApprovalChangeRequestDto: Expected refId',
      dto,
    ),
    taskType: 'METADATA_IMPORT_CHANGE',
    storage: 'backend',
    level: 'manifestation',
    work: dto.work
      ? mapWorkBaseDto(dto.work)
      : {
          id: workId,
          created: unixNow,
          modified: unixNow,
          type: CodeListRef.WORK_TYPE.Bok,
        },
    expression: dto.expression
      ? mapExpressionBaseDto(dto.expression)
      : {
          id: 'expressionIdPlaceholder',
          workId: workId,
          created: unixNow,
          modified: unixNow,
        },
    manifestation: manifestation
      ? mapManifestationBaseDto(manifestation)
      : {
          id: 'manifestationIdPlaceholder',
          expressionId: expression?.id ?? 'expressionIdPlaceholder',
          created: unixNow,
          modified: unixNow,
          status: 'NEW',
        },
    externalSuggestions: mapExternalSuggestions(dto.externalSuggestionsDto),
  };
};

const mapExternalSuggestions = (
  dto?: Schemas.ExternalSuggestionsDto,
): ExternalSuggestions | undefined => {
  if (dto) {
    const externalSuggestions: ExternalSuggestions = {};
    let empty = true;

    if (dto.intellectualLevel?.length) {
      externalSuggestions.intellectualLevel = dto.intellectualLevel;
      empty = false;
    }
    if (dto.seriesTitles?.length) {
      externalSuggestions.seriesTitles = dto.seriesTitles;
      empty = false;
    }
    if (dto.seriesNumbers?.length) {
      externalSuggestions.seriesNumbers = dto.seriesNumbers;
      empty = false;
    }

    if (!empty) {
      return externalSuggestions;
    }
  }

  return undefined;
};
