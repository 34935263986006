import React, {useMemo} from 'react';
import {AppBar, useScrollTrigger, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {useShortcut} from 'shortcuts';
import {useMoveToOrCopyFromOtherWork, useWorkTitles} from '../../hooks';
import {WorkActions} from './WorkActions';
import {WorkTitles} from './WorkTitles';

export type HeaderMode = 'normal' | 'mini';

export const WorkHeader: React.FC = () => {
  const {title, subtitle} = useWorkTitles();
  const theme = useTheme();

  const {
    openSearch,
    ConfirmMoveToOther: ConfirmMoveDialog,
    isConfirmOpen,
    isConfirmClosing,
  } = useMoveToOrCopyFromOtherWork();

  useShortcut('contextSearch', openSearch);

  const normalHeaderTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window,
  });

  const appbarSx = useMemo(() => {
    return {
      boxShadow: 0,
      zIndex: theme.zIndex.drawer - 1,
      backgroundColor: '#EAEADE',
      top: normalHeaderTrigger ? 52 : 0,
      mb: theme.spacing(1),
      pt: normalHeaderTrigger ? theme.spacing(1) : 0,
      transition: 'top 0.5s ease 0, padding-top 0.5s',
    };
  }, [normalHeaderTrigger, theme]);

  return (
    <AppBar component={'div'} position={'sticky'} sx={appbarSx}>
      <Box
        component={Grid}
        container
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Grid item xs={7}>
          <WorkTitles
            mode={normalHeaderTrigger ? 'mini' : 'normal'}
            title={title}
            subtitle={subtitle}
          />
        </Grid>
        <Grid item xs={5} sm={'auto'}>
          <WorkActions onOpenSearch={openSearch} />
        </Grid>
        <ConfirmMoveDialog
          isOpen={isConfirmOpen}
          isClosing={isConfirmClosing}
        />
      </Box>
    </AppBar>
  );
};
