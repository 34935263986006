import React from 'react';
import {TranslationKey} from 'localization/context/types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {Layout, Spacer, Text} from 'components';

type Props = {
  titleKey: TranslationKey;
  errorCause?: string;
};

export const ErrorMessage: React.FC<Props> = ({
  titleKey,
  errorCause: errorCause,
}) => {
  const {t} = useLocalization();

  return (
    <Layout
      py={2}
      px={4}
      borderRadius={2}
      adjustCenter
      sx={{backgroundColor: ColorPalette.enhancedLightBeige}}>
      <Text variant="body1" kind="error">
        {t(titleKey)}
      </Text>
      {errorCause ? (
        <>
          <Spacer size={1} />
          <Text variant="body2" kind="error">
            {errorCause}
          </Text>
        </>
      ) : null}
    </Layout>
  );
};
