import {useMemo} from 'react';
import {SxProps} from '@mui/system';
import {IconName} from 'components/icons/types';
import {TextVariant} from 'theme/types';
import {Icon} from '../icons';
import {Layout} from '../layout';
import {Text, TextMatch, TextMatchProps} from '../text';
import {UnstyledButton} from './UnstyledButton';

type Props = {
  title: string;
  icon?: IconName;
  textMatch?: TextMatchProps['match'];
  variant?: TextVariant;
  color?: string;
  titleSx?: SxProps;
  onClick: () => void;
};
export const NavigationButton: React.FC<Props> = ({
  title,
  icon,
  textMatch,
  variant = 'body2',
  color,
  titleSx,
  onClick,
}) => {
  const {layoutSx, textSx} = useMemo(() => {
    return {
      layoutSx: {cursor: 'pointer'},
      textSx:
        titleSx || color
          ? {
              ...(titleSx || {}),
              color,
            }
          : undefined,
    };
  }, [color, titleSx]);

  return (
    <UnstyledButton onClick={onClick}>
      <Layout horizontal adjustCenter color={color} sx={layoutSx}>
        {icon ? <Icon icon={icon} color={'primary'} /> : null}
        {textMatch ? (
          <TextMatch match={textMatch} variant={variant} sx={textSx}>
            {title}
          </TextMatch>
        ) : (
          <Text variant={variant} sx={textSx}>
            {title}
          </Text>
        )}
      </Layout>
    </UnstyledButton>
  );
};
