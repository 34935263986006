import {useMemo} from 'react';
import {
  ContentLabelWithLayout,
  PublisherNameVariantFieldLayout,
} from 'components/fields/types';
import {mapValues} from 'services/utils';
import {useLabelsAndPlaceholders} from './useLabelsAndPlaceholders';

export const usePublisherNameVariantLabelsAndPlaceholders = (
  fieldLayout: PublisherNameVariantFieldLayout,
): {
  contentLabels: ContentLabelWithLayout[];
  fieldLayoutWithPlaceholders: PublisherNameVariantFieldLayout;
} => {
  const labelsAndPlaceholders = useLabelsAndPlaceholders(['name']);

  return useMemo(() => {
    const requiredFields = ['name'];
    const labelKeys: (keyof PublisherNameVariantFieldLayout)[] = ['name'];
    labelKeys.push('mainForm');

    return {
      // Content labels only for selected fields
      contentLabels: labelKeys.map(key => ({
        ...fieldLayout[key],
        label:
          key !== 'mainForm' ? labelsAndPlaceholders[key].label : undefined,
        required: requiredFields.includes(key),
      })),
      fieldLayoutWithPlaceholders: mapValues(fieldLayout, (value, key) => ({
        ...value,
        placeholder:
          key !== 'mainForm'
            ? labelsAndPlaceholders[key].placeholder
            : undefined,
      })),
    };
  }, [fieldLayout, labelsAndPlaceholders]);
};
