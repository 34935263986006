import {ManifestationV4} from 'api';
import {Metadata} from '../../../types';
import {replaceManifestationBase} from './replaceManifestationBase';

export const replaceManifestationData = (
  newManifestation: ManifestationV4,
  currentMetadata: Metadata,
): Metadata => {
  return replaceManifestationBase(newManifestation, currentMetadata, [
    'expressionId',
    'status',
  ]);
};
