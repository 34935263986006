import {Concept} from 'types';
import {ExpressionV4, ManifestationV4, WorkV4} from 'api';
import {Metadata} from '../../../types';

type EntityVisitor = (
  entity: WorkV4 | ExpressionV4 | ManifestationV4,
  type: Concept.work | Concept.expression | Concept.manifestation,
) => void;

export const visitEntities = (
  metadata: Metadata | undefined,
  visitor: EntityVisitor,
) => {
  if (!metadata) {
    return;
  }

  visitor(metadata.work, Concept.work);
  metadata.expressions.forEach(e => {
    visitor(e, Concept.expression);
  });
  metadata.manifestations.forEach(m => {
    visitor(m, Concept.manifestation);
  });
};
