import React, {useEffect, useState} from 'react';
import {SxProps} from '@mui/material';
import {assert} from 'assert-ts';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {
  useChangeRequestEditValue,
  useChangeRequestImportProps,
  useChangeRequestOriginalValue,
  useChangeRequestSetApprovedValue,
  useChangeRequestStatus,
  useGetChangeRequestEditValue,
  useSetChangeRequestEditValue,
} from 'services/data';
import {useShortcut} from 'shortcuts';
import {Accordion, ActionButton, FlexBox, Spacer, Text} from 'components';
import {Preview} from 'schema';
import {DataForm} from 'schema/form';
import {DataFormCompareLayout} from 'schema/form/components/DataFormCompareLayout';
import {
  externalSuggestionPreviewConfig,
  externalSuggestionsSchema,
} from 'schemas';
import {ChangeRequestCardProps} from './types';
import {ChangeRequestIcon} from './components';
import {useChangeRequestEditState} from './hooks';
import {useChangeRequestItemsCompletedStatus} from './hooks/useChangeRequestCompletedInfo';
import {useChangeRequestFormConfiguration} from './hooks/useChangeRequestFormConfiguration';

const primaryColorSx: SxProps = {
  color: ColorPalette.primary.burgundy,
};

export const ChangeRequestCard: React.FC<ChangeRequestCardProps> = props => {
  const {t} = useLocalization();
  const formConfiguration = useChangeRequestFormConfiguration();
  const getEditValue = useGetChangeRequestEditValue();
  const setChangeRequestEditValue = useSetChangeRequestEditValue(
    props.changeRequestId,
  );

  const setApprovedValue = useChangeRequestSetApprovedValue(
    props.changeRequestId,
  );
  const {initialExpanded, changeRequest, rejectRemaining, autoClose} =
    useChangeRequestEditState(props);

  const [isExpanded, setIsExpanded] = useState(initialExpanded);
  useShortcut('expandAllChangeRequests', () => setIsExpanded(true));
  useShortcut('collapseAllChangesRequests', () => setIsExpanded(false));

  const initialVisibleRef = React.useRef<boolean>(
    changeRequest?.status !== 'COMPLETED',
  );
  const completedStatus = useChangeRequestItemsCompletedStatus(changeRequest);

  const related = useChangeRequestImportProps(
    changeRequest?.changes?.manifestation?.id,
    changeRequest?.changes.expression?.id,
  );

  // Effect:
  // - When changes and all completed => auto close
  useEffect(() => {
    if (completedStatus.rootPaths.length > 0 && completedStatus.isCompleted) {
      autoClose();
    }
  }, [
    autoClose,
    completedStatus.isCompleted,
    completedStatus.rootPaths.length,
  ]);

  if (!changeRequest) return null;
  const {schema, taskType} = changeRequest;

  // Check initialVisibleRef.current to avoid showing and then hiding the accordion
  return initialVisibleRef.current ? (
    <Accordion
      colorx={ColorPalette.secondary.beige}
      removed={changeRequest.status === 'COMPLETED'}
      unmountOnExit
      initialExpanded={initialExpanded}
      onChange={setIsExpanded}
      expanded={
        changeRequest.count === 0 &&
        changeRequest.externalSuggestionsCount === 0
          ? false
          : isExpanded
      }>
      <Accordion.Header>
        <FlexBox horizontal flex={1} spaceBetween>
          <FlexBox horizontal center>
            <ChangeRequestIcon type={taskType} />
            <Spacer size={1.5} />
            <Text variant="h4" sx={primaryColorSx}>
              {changeRequest.count === 0 &&
              changeRequest.externalSuggestionsCount === 0
                ? t(`page.metadata.cr.${taskType}.noChanges.title`)
                : `${changeRequest.count + changeRequest.externalSuggestionsCount} ${t(
                    `page.metadata.cr.${taskType}.changes.title`,
                  )}`}
            </Text>
          </FlexBox>
          {changeRequest.count === 0 &&
          changeRequest.externalSuggestionsCount === 0 ? (
            <FlexBox paddingRight={2}>
              <ActionButton
                key="b"
                title={t('page.metadata.cr.noChanges.action.title')}
                background="offWhite"
                showSpinnerOnClick
                onClick={rejectRemaining}
              />
            </FlexBox>
          ) : (
            <Accordion.HideWhenCollapsed>
              <FlexBox paddingRight={2}>
                <ActionButton
                  key="b"
                  title={
                    changeRequest.taskType === 'METADATA_COPIED'
                      ? t('general.close')
                      : t(
                          `page.metadata.cr.completedstatus.${completedStatus.itemCompletedStatus}.action.title`,
                        )
                  }
                  background="offWhite"
                  showSpinnerOnClick
                  onClick={rejectRemaining}
                />
              </FlexBox>
            </Accordion.HideWhenCollapsed>
          )}
        </FlexBox>
      </Accordion.Header>
      <Accordion.Content>
        <FlexBox flex={1}>
          {changeRequest.count > 0 ? (
            <>
              <FlexBox horizontal pb={1} px={2} flex={1}>
                <DataFormCompareLayout
                  renderCurrent={() => (
                    <Text
                      variant="h4"
                      sx={primaryColorSx}
                      textTransform="uppercase">
                      {t(`page.metadata.cr.${taskType}.currentValues.title`)}
                    </Text>
                  )}
                  renderProposed={() => (
                    <Text
                      variant="h4"
                      sx={primaryColorSx}
                      textTransform="uppercase">
                      {t(`page.metadata.cr.${taskType}.changedValues.title`)}
                    </Text>
                  )}
                />
              </FlexBox>
              <DataForm
                showErrors={true}
                id={changeRequest.id}
                schema={assert(schema)}
                mode={'compare'}
                relatedData={related}
                configuration={formConfiguration}
                getEditValue={getEditValue}
                useEditValue={useChangeRequestEditValue}
                setEditValue={setChangeRequestEditValue}
                useOriginalValue={useChangeRequestOriginalValue}
                useReviewStatus={useChangeRequestStatus}
                setApprovedValue={setApprovedValue}
              />
            </>
          ) : null}
          {changeRequest.externalSuggestions && externalSuggestionsSchema ? (
            <>
              <Spacer height={2} />
              <Text variant="h4" sx={primaryColorSx}>
                {t('page.metadata.cr.externalSuggestion.title')}
              </Text>
              <Spacer height={1} />
              <Preview
                schema={externalSuggestionsSchema}
                data={changeRequest.externalSuggestions}
                configuration={externalSuggestionPreviewConfig}
              />
            </>
          ) : null}
        </FlexBox>
      </Accordion.Content>
    </Accordion>
  ) : null;
};
