import React from 'react';
import {AccordionDetails} from '@mui/material';
import {FCWithChildren} from 'types';

const AccordionContentName = 'AccordionContentName';

const AccordionContent: FCWithChildren = ({children}) => {
  return <AccordionDetails>{children}</AccordionDetails>;
};

AccordionContent.displayName = AccordionContentName;

export {AccordionContent, AccordionContentName};
