import {useCallback, useMemo, useRef, useState} from 'react';
import assert from 'assert-ts';
import {DataFormPartRenderer} from 'schema/types';
import {Part, PartSchema, Valx} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {ManifestationNotes} from 'api';
import {FlexBox, Spacer} from 'components';
import {AddButton} from 'components/fields/AddButton';
import {FieldLabel} from 'components/fields/FieldLabel';
import {DataFormSchema} from 'schema/form/components/DataFormSchema';
import {useDataFormContext} from 'schema/form/contexts';

const getVisibleNotes = (notes: ManifestationNotes | null) => {
  return notes
    ? {
        generalNote:
          !!notes.generalNote &&
          notes.generalNote.length > 0 &&
          (notes.generalNote ?? []).some(v => !!v),
        editionNote:
          !!notes.editionNote &&
          notes.editionNote.length > 0 &&
          (notes.editionNote ?? []).some(v => !!v),
        editionType:
          !!notes.editionType &&
          notes.editionType.length > 0 &&
          (notes.editionType ?? []).some(v => !!v),
        attachment:
          !!notes.attachment &&
          notes.attachment.length > 0 &&
          (notes.attachment ?? []).some(v => !!v),
      }
    : {
        generalNote: false,
        editionNote: false,
        editionType: false,
        attachment: false,
      };
};

export const Notes: DataFormPartRenderer = props => {
  const {id, setEditValue} = useDataFormContext();
  const {part, valuePath, useValue, ...rest} = props;
  const value = useValue(valuePath, id);
  const {t} = useLocalization();
  const notesPart = part as PartSchema<Valx>;
  const notesValue = value as ManifestationNotes | null;
  const visibleNotesRef = useRef<{
    [key: string]: boolean;
  }>(getVisibleNotes(notesValue));

  const [expanded, setExpanded] = useState<boolean>(
    Object.values(visibleNotesRef.current).some(v => v),
  );

  const visibleNotesPart = useMemo(() => {
    return {
      ...notesPart,
      parts: notesPart.parts.filter(p => {
        const name = (p as Part).name;
        return expanded || (name && visibleNotesRef.current[name]);
      }),
    };
  }, [notesPart, expanded]);

  const handleExpand = useCallback(() => {
    const changes: Partial<ManifestationNotes> = {};

    if ((notesValue?.generalNote ?? []).length === 0) {
      changes.generalNote = [''];
    }

    if ((notesValue?.editionNote ?? []).length === 0) {
      changes.editionNote = [''];
    }

    if ((notesValue?.attachment ?? []).length === 0) {
      changes.attachment = [''];
    }

    if (Object.keys(changes).length > 0) {
      setEditValue(props.valuePath, {
        ...notesValue,
        ...changes,
      });
    }

    setExpanded(true);
  }, [notesValue, props.valuePath, setEditValue]);

  if (!notesPart || notesPart.type !== 'schema' || notesPart.name !== 'notes') {
    assert.soft(false, 'Notes: expected notes schema');
    return null;
  }

  /*
    Empty: No parts has value
      * No fields visible
      * Show show-button -> Expanded

    Partial: Some, but not all, fields has value
      * Show fields with value
      * Show show-button -> Expanded

    All/expanded: All fields has value
      * All fields visible
      * Hide show-button
   */

  const showButtonLabel = t(`form.global.field.notes.add`);

  return (
    <FlexBox>
      <FieldLabel label={t('form.global.field.notes.label')} />

      {visibleNotesPart.parts.length > 0 ? (
        <>
          <Spacer size={2} />
          <DataFormSchema
            schema={visibleNotesPart}
            useValue={useValue}
            valuePath={valuePath}
            {...rest}
          />
        </>
      ) : null}

      {!expanded ? (
        <AddButton
          color={'primary'}
          label={showButtonLabel}
          onClick={handleExpand}
        />
      ) : null}
    </FlexBox>
  );
};
