import React, {useMemo} from 'react';
import {DataFormConfiguration} from 'schema/types';
import {isFeatureEnabled} from 'configuration';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {ExpressionV4} from 'api';
import {
  RestrictedCodelistsContext,
  useRestrictedCodelistsContextProviderValue,
} from 'services/codeLists';
import {
  useExpressionEditValue,
  useGetExpressionEditValue,
  useSetExpressionEditValue,
} from 'services/data';
import {Card, IconButton, Layout, Spacer, Text} from 'components';
import {DataForm} from 'schema/form';
import {ExpressionCardProps} from './types';
import {CustomAgentActions} from '../CustomAgentActions';
import {ManifestationCard} from '../ManifestationCard';
import {ReadonlyExpression} from './ReadonlyExpression';
import {useExpressionEditState} from './hooks/useExpressionEditState';

export const ExpressionCard: React.FC<ExpressionCardProps> = props => {
  const {t, tLoose} = useLocalization();
  const formConfiguration = useMemo(
    (): Partial<DataFormConfiguration<ExpressionV4>> => ({
      schemaContainers: {},
      showWhenReadonlyAndEmpty: true,
      parts: {
        agents: isFeatureEnabled('unnamedAgent')
          ? {
              label: t('form.global.field.agents.label'),
              renderCustomListActions: (part, props) => (
                <CustomAgentActions part={part} {...props} />
              ),
            }
          : undefined,
      },
    }),
    [t],
  );
  const getEditValue = useGetExpressionEditValue();
  const setEditValue = useSetExpressionEditValue(
    props.expressionId,
    'METADATAEDIT_EXPRESSION_EDITED',
  );

  const {
    title,
    codelistRestrictor,
    getLocalCodelist,
    relatedScope,
    schema,
    readonly,
    editStatus,
    valid,
    showErrors,
    handleEdit,
    handleCancelEdit,
  } = useExpressionEditState(props);

  const restrictedCodelistsContextValue =
    useRestrictedCodelistsContextProviderValue(
      codelistRestrictor,
      getLocalCodelist,
    );

  const ChangeExpressionText: React.ReactElement = useMemo(() => {
    const preTextSx = {
      lineHeight: '1rem',
    };

    return (
      <Text
        variant={'h4'}
        sx={preTextSx}
        kind={props.readonly ? 'disabled' : undefined}>
        {t('form.expression.change.title')}
      </Text>
    );
  }, [props.readonly, t]);

  return (
    <RestrictedCodelistsContext.Provider
      value={restrictedCodelistsContextValue}>
      <Card
        color="primary"
        mode="light"
        headerSpacing={0}
        data-cy="expression-card">
        <Card.Header>
          <Layout horizontal flex={1} alignItems="center">
            <Text variant="h4">{title}</Text>
            <Layout horizontal adjustCenter adjustRight>
              {showErrors && (valid === 'warning' || valid === 'error') ? (
                <>
                  <Text variant="h4" kind={valid}>
                    {tLoose(`form.global.validation.${valid}.label`)}
                  </Text>
                  <Spacer width={4} />
                </>
              ) : null}
              {readonly ? (
                <IconButton
                  preText={ChangeExpressionText}
                  icon="DropDown"
                  disabled={props.readonly}
                  onClick={handleEdit}
                  data-cy={'expression-closed'}
                />
              ) : editStatus === 'Unchanged' ? (
                <IconButton
                  preText={ChangeExpressionText}
                  icon="ChevronUp"
                  onClick={handleCancelEdit}
                  data-cy={'expression-open'}
                />
              ) : null}
            </Layout>
          </Layout>
        </Card.Header>
        <Card.Content>
          {readonly ? (
            <ReadonlyExpression
              expressionId={props.expressionId}
              schema={schema}
            />
          ) : (
            <Layout paddingTop={1}>
              <Card colorx={ColorPalette.secondary.brown}>
                <Card.Header minHeight={'20px'}>
                  <Text variant="h4" textTransform="capitalize">
                    {t('form.expression.header.title')}
                  </Text>
                </Card.Header>
                <Card.Content>
                  <DataForm
                    id={props.expressionId}
                    schema={schema}
                    configuration={formConfiguration}
                    relatedData={relatedScope}
                    mode={readonly ? 'read-only' : 'edit'}
                    showErrors={showErrors}
                    getEditValue={getEditValue}
                    useEditValue={useExpressionEditValue}
                    setEditValue={setEditValue}
                  />
                </Card.Content>
              </Card>
            </Layout>
          )}
          <Spacer size={2} />
          {props.content.map(mcr => (
            <ManifestationCard
              key={mcr.id}
              initialExpanded={mcr.id === props.initialManifestationId}
              manifestationId={mcr.id}
              expressionId={props.expressionId}
              changeRequests={mcr.changeRequests}
              workId={props.workId}
              readonly={props.readonly}
            />
          ))}
        </Card.Content>
      </Card>
    </RestrictedCodelistsContext.Provider>
  );
};
