import {useMemo} from 'react';
import assert from 'assert-ts';
import {AgentSubType} from 'types';
import {DeleteAgentFunc, SaveAgentFunc, useAgent} from 'services/data/agent';
import {AgentState} from 'services/data/agent/reducer/agentReducer';
import {AgentIdParam, NewAgentParam} from '../types';

export const useNewOrExistingAgent = ({
  agentId,
  agentType,
  suggestion,
}: NewAgentParam | AgentIdParam):
  | {
      agent: AgentState;
      agentType?: undefined;
      suggestion?: undefined;
      saveAgent: SaveAgentFunc;
      deleteAgent: DeleteAgentFunc;
    }
  | {
      agent?: undefined;
      suggestion: string;
      agentType: AgentSubType;
      saveAgent: SaveAgentFunc;
      deleteAgent: DeleteAgentFunc;
    } => {
  const {
    agent: existingAgent,
    saveAgent,
    deleteAgent,
  } = useAgent(agentId, 'full', 'mentionedIn');

  return useMemo(() => {
    if (agentId) {
      return {agent: existingAgent, saveAgent, deleteAgent};
    }

    return {
      agentType: assert(agentType, 'agentType is required'),
      suggestion: suggestion ?? '',
      saveAgent,
      deleteAgent,
    };
  }, [agentId, agentType, deleteAgent, existingAgent, saveAgent, suggestion]);
};
