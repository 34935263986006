import React, {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Box, Tooltip} from '@mui/material';
import {useLocalization} from 'localization';
import {useMetadataSavedStatus} from 'services/data';
import {useTasksTodoLinks} from 'services/settings/hooks/useTasksTodoLinks';
import {useShortcut} from 'shortcuts';
import {Layout, ToolBar} from 'components';
import {ManifestationStatus} from './ManifestationStatus';

type Props = {
  onSearch: () => void;
};

export const MetadataToolbar: React.FC<Props> = ({onSearch}) => {
  const status = useMetadataSavedStatus();
  const navigate = useNavigate();
  const {t} = useLocalization();

  const todoLinks = useTasksTodoLinks();
  const dispatch = useDispatch();

  const goToNextTodo = useCallback(() => {
    const next = todoLinks.shift();
    if (next) {
      dispatch({
        type: 'SET_TASKS_TODOLINKS',
        payload: [...todoLinks],
      });

      navigate(next);
    }
  }, [dispatch, navigate, todoLinks]);

  const NextTodoButton = useMemo(() => {
    if (todoLinks.length > 0) {
      return (
        <Tooltip
          title={t('page.metadata.tasks.next')}
          arrow
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
            },
          }}>
          <Box>
            <ToolBar.IconTool
              icon="DoubleChevronRight"
              onClick={goToNextTodo}
            />
          </Box>
        </Tooltip>
      );
    }

    return undefined;
  }, [goToNextTodo, todoLinks.length, t]);

  useShortcut('goToNext', goToNextTodo);

  return (
    <Layout horizontal adjustCenter adjustRight flex={1}>
      <ToolBar>
        <ManifestationStatus status={status} />
        {NextTodoButton}
        <ToolBar.IconTool icon="Search" onClick={onSearch} />
      </ToolBar>
    </Layout>
  );
};
