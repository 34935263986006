import React from 'react';
import {SxProps} from '@mui/material';
import {FilterModel, QuickFilter} from 'components/dataTable/types';
import {FlexBox, IconButton} from 'components';
import {QuickFilterButton} from 'components/dataTable/components';
import {useLocalization} from '../../../localization';

type Props = {
  filterModel: FilterModel | undefined;
  quickFilters: QuickFilter[] | undefined;
  setFilterModel: (filterModel: FilterModel) => void;
  onOpenFilterPanel: () => void;
};

const wrapperSx: SxProps = {
  background: 'none',
  flex: 'auto',
  justifyContent: 'end',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 2,
};

const sx: SxProps = {flexDirection: 'row', alignItems: 'center', mr: 1, gap: 1};

export const QuickFilterButtons: React.FC<Props> = ({
  quickFilters,
  filterModel,
  setFilterModel,
  onOpenFilterPanel,
}) => {
  const {tLoose} = useLocalization();

  return (
    <FlexBox sx={wrapperSx}>
      <FlexBox sx={sx}>
        {quickFilters?.map((quickFilter, index) => (
          <QuickFilterButton
            key={index}
            activeFilterModel={filterModel}
            filterModel={quickFilter.filterModel}
            setFilterModel={setFilterModel}
            title={tLoose(`schema.table.filter.${quickFilter.title}`)}
          />
        ))}

        <IconButton
          icon={'Filter'}
          onClick={onOpenFilterPanel}
          badgeCount={filterModel?.items.length}
        />
      </FlexBox>
    </FlexBox>
  );
};
