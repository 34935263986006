import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {ThemeProvider} from '@mui/system';
import {metadataExpressionOrderSelector} from 'services/data';
import {Scene} from 'components';
import {useLayoutTheme} from 'components/scene/hooks/useLayoutTheme';
import {useSceneExtensionsContext} from 'sceneExtensions/contexts/SceneExtensionsContext';
import {
  ExpressionCard,
  NewExpressionWithManifestationCard,
  WorkCard,
  WorkHeader,
} from './components';
import {useHasOpenWorkLevelChangeRequests} from './components/WorkCard/hooks';
import {
  WorkEditStateContext,
  useUpdateMetadataParamsContext,
  useWorkEditStateContextProviderValue,
  useWorkToggleContext,
} from './contexts';
import {useManifestationId, useWorkTitles} from './hooks';

export const WorkScene: React.FC = () => {
  const {workId, manifestationDesignator: initialManifestationDesignator} =
    useUpdateMetadataParamsContext();
  const initialManifestationId = useManifestationId(
    initialManifestationDesignator,
  );

  const expressionOrder = useSelector(metadataExpressionOrderSelector);
  const {currentExtension} = useSceneExtensionsContext();
  const {title} = useWorkTitles();
  const hasWorkChangeRequests = useHasOpenWorkLevelChangeRequests();

  const {workHidden} = useWorkToggleContext();

  const {workLayoutSize, expressionLayoutSize, columns, transitionStyle} =
    useMemo(() => {
      // Multiply max number of Grid columns (from default 12) to
      // allow smaller size on minimized WorkCard.
      const columns = 24;
      const columnsMultiplier = 2;
      const workLayoutSize = workHidden
        ? 1 // Only 1 grid column to minimized WorkCard
        : hasWorkChangeRequests
          ? 7 * columnsMultiplier
          : 5 * columnsMultiplier;
      const expressionLayoutSize = columns - workLayoutSize;

      return {
        workLayoutSize,
        expressionLayoutSize,
        columns,
        transitionStyle: {
          transition: 'all .5s ease-in-out',
        },
      };
    }, [hasWorkChangeRequests, workHidden]);

  const workTheme = useLayoutTheme(workLayoutSize, 12);
  const expressionTheme = useLayoutTheme(expressionLayoutSize, 12);

  const workStateValue = useWorkEditStateContextProviderValue(workId);

  return (
    // TODO: Consider to move WorkEditStateContextProvider to just enclose WorkCard
    <WorkEditStateContext.Provider value={workStateValue}>
      <Scene
        browserTitle={title}
        rightPanelConfig={currentExtension?.panelConfig}>
        <Scene.Header>
          <WorkHeader />
        </Scene.Header>
        <Scene.Content>
          <Box component={Grid} container spacing={2} columns={columns}>
            <Box
              component={Grid}
              item
              xs={columns}
              sm={columns}
              md={workLayoutSize}
              style={transitionStyle}>
              <ThemeProvider theme={workTheme}>
                <WorkCard workId={workId} />
              </ThemeProvider>
            </Box>
            <Box
              component={Grid}
              item
              xs={columns}
              sm={columns}
              md={expressionLayoutSize}
              style={transitionStyle}>
              <ThemeProvider theme={expressionTheme}>
                <Grid container spacing={2}>
                  {expressionOrder.map(({expressionId, type, content}) => (
                    <Grid key={expressionId} item xs={12}>
                      {type === 'existing' ? (
                        <ExpressionCard
                          workId={workId}
                          expressionId={expressionId}
                          content={content}
                          initialManifestationId={initialManifestationId}
                          readonly={workStateValue.readonly}
                        />
                      ) : (
                        <NewExpressionWithManifestationCard
                          workId={workId}
                          expressionId={expressionId}
                          content={content}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </ThemeProvider>
            </Box>
          </Box>
        </Scene.Content>

        {currentExtension ? (
          <Scene.RightPanel>
            {currentExtension.renderRightPanel()}
          </Scene.RightPanel>
        ) : null}
      </Scene>
    </WorkEditStateContext.Provider>
  );
};
