import {useMemo} from 'react';

type ListOperations<T> = {
  onAppendValue?: () => T[];
  onDeleteValue?: (index: number) => T[];
  onDeleteAll?: () => T[];
  onMoveValue?: (oldIndex: number, newIndex: number) => T[];
  onReplace: (atIdx: number, newValue: T) => T[];
  onCreateValue?: () => T;
};
export const useListOperations = <T>(
  onChange: (update: (prev: T[]) => T[]) => T[],
  onNew: () => T,
): ListOperations<T> => {
  return useMemo(
    () => ({
      onAppendValue: () => onChange(prev => [...prev, onNew()]),
      onDeleteValue: idx =>
        onChange(prev => {
          const next = [...prev];
          next.splice(idx, 1);
          return next;
        }),
      onDeleteAll: () =>
        onChange(() => {
          const next = [] as T[];
          return next;
        }),
      onMoveValue: (fromIdx: number, toIdx: number) =>
        onChange(prev => {
          const next = [...prev];
          const [moved] = next.splice(fromIdx, 1);
          next.splice(toIdx, 0, moved);
          return next;
        }),
      onReplace: (atIdx: number, newValue: T) =>
        onChange(prev => {
          const next = [...prev];
          next[atIdx] = newValue;
          return next;
        }),
      onCreateValue: onNew,
    }),
    [onChange, onNew],
  );
};
