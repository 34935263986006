import {assert} from 'assert-ts';
import {Concept, LinkTargetCatalogPostType} from 'types';
import {isLinkTargetCatalogPostType} from './isLinkTargetCatalogPostType';

export const assertLinkTargetCatalogPostType = (
  entityType: Concept | undefined,
): LinkTargetCatalogPostType => {
  assert(
    isLinkTargetCatalogPostType(entityType),
    `assertLinkTargetCatalogPostType: invalid ${entityType}`,
  );
  return entityType as LinkTargetCatalogPostType;
};
