import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'store/types';
import {SettingsAction} from 'services/settings';

const searchVisibilitySelector = (state: AppState) =>
  state.settings.overview.searchVisibility;

export const useAgentSearchVisibility = () => {
  const dispatch = useDispatch();
  const searchVisibility = useSelector(searchVisibilitySelector);

  const setSearchVisibility = useCallback(
    (searchVisibility: boolean) => {
      const action: SettingsAction = {
        type: 'SET_AGENTS_SEARCH_VISIBILITY',
        payload: searchVisibility,
      };
      dispatch(action);
    },
    [dispatch],
  );

  return {
    setSearchVisibility,
    searchVisibility,
  };
};
