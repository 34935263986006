import {useCallback, useEffect, useMemo, useState} from 'react';
import {assert} from 'assert-ts';
import orderBy from 'lodash/orderBy';
import {
  ByCategoryResult,
  ByCategoryResultPage,
  SearchResult,
} from 'services/thesaurus/types';
import {Thesaurus} from 'api';
import {groupPrCategory} from 'services/thesaurus/functions';

type GotoTarget = 'nextPage' | 'prevPage' | {categoryId: string};

type SearchResultPaging = {
  resultCategories: {
    id: string;
    label: string;
    count: number;
  }[];

  // Paging of search result
  resultByCategoriesPage: ByCategoryResultPage[];
  startItemIndex: number;

  canGoto: (target: GotoTarget) => boolean;
  goto: (target: GotoTarget) => void;
};

const getTargetCategory = (
  target: GotoTarget,
  resultsPrCategory: ByCategoryResult[],
): ByCategoryResult => {
  assert(
    typeof target === 'object',
    'useSearchResultPaging: Invalid goto target',
    {target},
  );
  const {categoryId} = target;
  const category = assert(
    resultsPrCategory.find(c => c.categoryNode.id === categoryId),
    'useSearchResultPaging: Invalid category id',
    {categoryId},
  );

  return category;
};

export const useSearchResultPaging = (
  thesaurus: Thesaurus,
  searchResult: SearchResult | undefined,
  pageSize = 10,
): SearchResultPaging => {
  const [startItemIndex, setStartItemIndex] = useState(0);
  const resultsPrCategory = useMemo((): ByCategoryResult[] => {
    const prCategory = groupPrCategory(searchResult?.matches ?? []);
    let accItemCount = 0;
    const reduced = prCategory
      .map<ByCategoryResult>(m => {
        const startIndex = accItemCount;
        accItemCount += m.nodes.length;
        const category = {
          categoryNode: m.categoryNode,
          nodes: orderBy(m.nodes, n => (n.hasTitleMatch ? 0 : 1)),
          startIndex,
          count: m.nodes.length,
        };
        return category;
      })
      .filter(m => m.nodes.length > 0);

    return reduced;
  }, [searchResult]);

  const resultCategories = useMemo(() => {
    const allCategories =
      thesaurus.children?.map(c => ({
        id: c.id,
        label: c.label,
      })) ?? [];
    return allCategories?.map(c => ({
      ...c,
      count:
        resultsPrCategory.find(r => r.categoryNode.id === c.id)?.count ?? 0,
    }));
  }, [resultsPrCategory, thesaurus.children]);

  const searchResultPage = useMemo(() => {
    const endItemIndex = startItemIndex + pageSize - 1;
    const pageItems = resultsPrCategory
      .filter(
        c =>
          c.startIndex <= endItemIndex &&
          c.startIndex + c.count - 1 >= startItemIndex,
      )
      .map<ByCategoryResultPage>(c => {
        const startOffset = Math.max(0, startItemIndex - c.startIndex);
        const endOffset = Math.min(c.count - 1, endItemIndex - c.startIndex);
        const nodes = c.nodes.slice(startOffset, endOffset + 1);
        return {
          ...c,
          nodes,
          firstIndexInCategory: startOffset,
          lastIndexInCategory: endOffset,
        };
      });
    return pageItems;
  }, [pageSize, resultsPrCategory, startItemIndex]);

  const handleCanGoto = useCallback(
    (target: GotoTarget): boolean => {
      if (!searchResult) {
        return false;
      }
      switch (target) {
        case 'nextPage':
          return startItemIndex + pageSize < searchResult.matches.length;
        case 'prevPage':
          return startItemIndex > 0;
        default: {
          const category = getTargetCategory(target, resultsPrCategory);
          return category.startIndex !== startItemIndex;
        }
      }
    },
    [pageSize, resultsPrCategory, searchResult, startItemIndex],
  );

  const handleGoto = useCallback(
    (target: GotoTarget) => {
      if (!searchResult) {
        return;
      }
      switch (target) {
        case 'nextPage':
          setStartItemIndex(startItemIndex + pageSize);
          break;
        case 'prevPage':
          setStartItemIndex(Math.max(0, startItemIndex - pageSize));
          break;
        default: {
          const category = getTargetCategory(target, resultsPrCategory);
          setStartItemIndex(category.startIndex);
        }
      }
    },
    [pageSize, resultsPrCategory, searchResult, startItemIndex],
  );

  // When search result changes,
  // => reset page number
  useEffect(() => {
    setStartItemIndex(0);
  }, [searchResult]);

  return useMemo(() => {
    return {
      resultCategories,
      resultByCategoriesPage: searchResultPage,
      startItemIndex,
      canGoto: handleCanGoto,
      goto: handleGoto,
    };
  }, [
    handleCanGoto,
    handleGoto,
    resultCategories,
    searchResultPage,
    startItemIndex,
  ]);
};
