import {useMemo} from 'react';
import {DataLoadStatus, Media} from 'api/types';
import {getMediaForManifestation, useLazyGetResource} from '../../../../api';
import {useGetTokens} from '../../../auth/hooks/useGetTokens';

export const useLazyManifestationMediaData = (
  manifestationId: string,
  doLoad: boolean,
  mock?: boolean,
): {
  media: DataLoadStatus<Media[]>;
} => {
  const token = useGetTokens();

  const media = useLazyGetResource(doLoad, () =>
    getMediaForManifestation(manifestationId, token, mock),
  );

  return useMemo(
    () => ({
      media,
    }),
    [media],
  );
};
