import {LocalizationContextType} from 'localization/context/types';
import {Part} from 'schemaDefinition/types';
import {SchemaName} from 'schemas/types';
import {getFieldRootKeys, getLabel} from 'schema/form/functions';

export const getSchemaPartLabel = <TVal>(
  schemaKey: SchemaName,
  part: Pick<Part<TVal>, 'name' | 'labelKey'>,
  localization: LocalizationContextType,
): string => {
  const rootKeys = getFieldRootKeys(schemaKey, false, part.labelKey, part.name);
  return (
    getLabel(rootKeys, !!part.labelKey, localization) ??
    `Missing label for ${part.name}`
  );
};
