import React from 'react';
import {SxProps} from '@mui/system';
import {FCWithChildren} from 'types';
import {Kind} from '../../theme/types';
import {Text} from './Text';

type TextTransform = 'lowercase' | 'uppercase' | 'capitalize' | 'none';

type Props = {
  htmlFor?: string;
  kind?: Kind;
  /** Default: uppercase */
  textTransform?: TextTransform;
  sx?: SxProps;
};

export const Label: FCWithChildren<Props> = ({
  kind = 'primary',
  textTransform = 'uppercase',
  children,
  sx,
}) => (
  <Text kind={kind} variant="label" textTransform={textTransform} sx={sx}>
    {children}
  </Text>
);
