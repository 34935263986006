import React from 'react';
import {SceneTitle} from './SceneTitle';

const SceneHeaderName = 'SceneHeaderName';

type Props =
  | {
      title: string;
      children?: undefined;
    }
  | {
      title?: undefined;
      children: React.ReactNode | (null | React.ReactNode)[];
    };

const SceneHeader: React.FC<Props> = ({title, children}) => {
  if (children) {
    return <>{children}</>;
  }

  return <SceneTitle title={title} />;
};

SceneHeader.displayName = SceneHeaderName;

export {SceneHeader, SceneHeaderName};
