import {ColumnConfig} from 'schema/table/types';

export const eventAgentDefaultColumns: ColumnConfig[] = [
  {
    name: 'nameVariants',
    visible: true,
    width: 200,
  },
  {
    name: 'place',
  },
  {
    name: 'isni',
    visible: true,
    width: 170,
  },
  {
    name: 'nationalId',
    visible: true,
  },
  {
    name: 'from',
    visible: true,
  },
  {
    name: 'to',
  },
  {
    name: 'internalComments',
  },
  {
    name: 'externalComments',
  },
];
