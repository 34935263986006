import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import isEqual from 'lodash/isEqual';
import {AppState} from 'store/types';

const workLevelChangeRequestsSelector = (state: AppState) => {
  if (
    !state.metadataEdit?.metadata?.changeRequests?.some(
      cr => cr.level === 'work',
    )
  ) {
    return undefined;
  }

  // As comma separated string to avoid rerendering when the array changes
  return state.metadataEdit.metadata.changeRequests
    .filter(cr => cr.level === 'work')
    .map(cr => cr.id)
    .join(',');
};

export const useWorkLevelChangeRequests = (): string[] | undefined => {
  const ids = useSelector(workLevelChangeRequestsSelector);
  const idsRef = React.useRef(ids);
  return useMemo(() => {
    if (!isEqual(idsRef.current, ids)) {
      idsRef.current = ids;
    }
    return idsRef.current ? idsRef.current.split(',') : undefined;
  }, [ids]);
};
