import React, {useMemo} from 'react';
import {assert} from 'assert-ts';
import {PublisherNameVariantFieldLayout} from 'components/fields/types';
import {NameVariantValue} from 'schemaDefinition/types';
import {ColorPalette} from 'theme';
import {assertSimpleRequired} from 'schemaDefinition/functions/assertSimpleRequired';
import {SingleValueListField, useListOperations} from 'components';
import {DataFormNameVariantListProps} from './types';
import {useDataFormContext} from '../contexts';
import {
  useAddItemLabel,
  usePublisherNameVariantLabelsAndPlaceholders,
  useResolvedDataFormPartConfiguration,
} from '../hooks';
import {PublisherNameVariant} from './fields';

const nameVariantFieldLayout: PublisherNameVariantFieldLayout = {
  name: {
    flex: 1,
  },
  mainForm: {
    flex: 1,
    alignItems: 'flex-end',
  },
};

const newVariant = (): NameVariantValue => ({});

export const DataFormPublisherNameVariantList: React.FC<
  DataFormNameVariantListProps
> = ({part, useValue, valuePath, mode}) => {
  const {id, setEditValue} = useDataFormContext();
  const {showWhenReadonlyAndEmpty, hideStructureOperations} =
    useResolvedDataFormPartConfiguration(part);
  const value = useValue(valuePath, id, part.name) ?? null;

  assert(
    value === null || Array.isArray(value),
    'DataForm: "name variants"-value property must be array (or null)',
    {part: part, contextPath: valuePath, dataScope: value},
  );

  const requiredValue = useMemo(
    () => (!value || value.length === 0 ? [newVariant()] : value),
    [value],
  );

  const {onAppendValue, onDeleteValue, onMoveValue} =
    useListOperations<NameVariantValue>(update => {
      const nextValue = update(requiredValue as NameVariantValue[]);
      setEditValue(valuePath, nextValue, part);
      return nextValue;
    }, newVariant);

  const {contentLabels, fieldLayoutWithPlaceholders} =
    usePublisherNameVariantLabelsAndPlaceholders(nameVariantFieldLayout);
  const addLabel = useAddItemLabel(part);

  return (
    <SingleValueListField<NameVariantValue>
      addLabel={addLabel}
      label={'.'}
      contentLabels={contentLabels}
      itemBackgroundColor={ColorPalette.enhancedLightBeige}
      itemPadding={1}
      name={part.name}
      required={assertSimpleRequired(part.required)}
      readonly={mode === 'read-only'}
      showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
      values={requiredValue as NameVariantValue[]}
      getValueKey={idx => `${valuePath}[${idx}]`}
      renderValue={(idx, value, focusableId) => {
        return (
          <PublisherNameVariant
            focusableId={focusableId}
            part={part}
            value={value as NameVariantValue}
            valuePath={`${valuePath}[${idx}]`}
            setFieldValue={setEditValue}
            isMainForm={idx === 0}
            mode={mode}
            showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
            layout={fieldLayoutWithPlaceholders}
          />
        );
      }}
      onAppendValue={
        hideStructureOperations?.includes('add') ? undefined : onAppendValue
      }
      onDeleteValue={
        hideStructureOperations?.includes('delete') ? undefined : onDeleteValue
      }
      onMoveValue={
        hideStructureOperations?.includes('move') ? undefined : onMoveValue
      }
    />
  );
};
