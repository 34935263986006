import {useLocalization} from 'localization';
import {
  LinkedRoleCodeList,
  LinkedRoleCodeListId,
} from 'schemaDefinition/linkTypes';
import {entityLinkTypes} from '../entityLinks';
import {getLinkedSourceRoleCodelist} from '../functions/getSourceLinkedRoleCodelist';

export const useLinkedRoleCodelist = (
  codelistId: LinkedRoleCodeListId,
): LinkedRoleCodeList => {
  const localization = useLocalization();
  return getLinkedSourceRoleCodelist(codelistId, entityLinkTypes, localization);
};
