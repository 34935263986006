import {useCallback, useEffect, useMemo, useState} from 'react';
import {assert} from 'assert-ts';
import dayjs from 'dayjs';
import {DataLoadStatus, NoteContextType, NoteData} from 'api/types';
import {useLocalization} from 'localization';
import {useLazyGetResource} from 'api';
import {deleteNote, getNotesForContext, postNote, putNote} from 'api/notes';
import {useUsers} from 'services/users';
import {getDummyUser} from 'services/users/functions';
import {useLoadError} from 'services/utils';
import {useSnacks} from 'components';
import {Note} from '../types';
import {useGetTokens} from '../../../auth/hooks/useGetTokens';

export const useLazyNotes = (
  contextId: string,
  doLoad: boolean,
  contextType: NoteContextType,
  mock?: boolean,
): {
  notes: DataLoadStatus<Note[]>;
  createNote: (note: string) => Promise<void>;
  updateNote: (id: string, note: string) => Promise<void>;
  removeNote: (id: string) => Promise<void>;
} => {
  const getTokens = useGetTokens();

  const {errorSnack} = useSnacks();
  const {t} = useLocalization();

  const {resource: users} = useUsers();

  // Notes loaded from backend
  const loadedNotesData = useLazyGetResource(doLoad, () =>
    getNotesForContext(contextId, getTokens, mock).then(notes =>
      notes.sort((a, b) => b.lastModified - a.lastModified),
    ),
  );

  useLoadError(loadedNotesData.status, 'error.notes.failedToLoad');

  // Notes created/updated, may overlap with notes loaded from backend
  const [editedNotesData, setEditedNotesData] = useState<NoteData[]>([]);

  // Note ids removed, may overlap with notes loaded from backend or created/updated
  const [removedNoteIds, setRemovedNoteIds] = useState<string[]>([]);

  // Merged [editedNotes, loadedNotes] x users
  const [notes, setNotes] = useState<DataLoadStatus<Note[]>>({
    status: 'NotLoaded',
  });

  // Create new note: added to edited notes and posted to backend
  const createNote = useCallback(
    (message: string) => {
      assert(
        loadedNotesData.status === 'Loaded',
        'useLazyNotes.createNote: notes can only be created after notes from backend have been successfully loaded',
      );

      const lastModified = dayjs();
      const tempId = new Date().getTime().toString();
      const newNote: NoteData = {
        id: tempId, // Will be set by backend
        // TODO: Get current user, invalid jwt-token, both idToken and accessToken, cannot get user id
        userId: '1',
        contextId,
        contextType,
        created: lastModified.valueOf(),
        modified: lastModified.valueOf(),
        lastModified: lastModified.valueOf(),
        message,
      };

      return postNote(newNote, getTokens, mock)
        .then(resultNote => {
          setEditedNotesData(notes => [resultNote, ...notes]);
        })
        .catch(error => {
          errorSnack(t('error.notes.failedToCreateNote'));
          throw error;
        });
    },
    [
      loadedNotesData.status,
      contextId,
      contextType,
      getTokens,
      mock,
      errorSnack,
      t,
    ],
  );

  const updateNote = useCallback(
    (id: string, note: string) => {
      assert(
        loadedNotesData.status === 'Loaded',
        'useLazyNotes.updateNote: notes can only be created after notes from backend have been successfully loaded',
      );

      const oldNote = assert(
        loadedNotesData.data.find(l => l.id === id) ??
          editedNotesData.find(e => e.id === id),
        'useLazyNotes.updateNote: note to be updated not found',
      );

      const updatedNote: NoteData = {
        ...oldNote,
        message: note,
        lastModified: dayjs().valueOf(),
      };

      return putNote(updatedNote, getTokens, mock)
        .then(resultNote => {
          setEditedNotesData(notes => [
            resultNote,
            // Filter out previous edits to same note
            ...notes.filter(n => n.id !== id),
          ]);
        })
        .catch(error => {
          errorSnack(t('error.notes.failedToUpdatesNote'));
          throw error;
        });
    },
    [
      getTokens,
      editedNotesData,
      errorSnack,
      loadedNotesData.data,
      loadedNotesData.status,
      mock,
      t,
    ],
  );

  const removeNote = useCallback(
    (id: string) => {
      assert(
        loadedNotesData.status === 'Loaded',
        'useLazyNotes.deleteNote: notes can only be deleted after notes from backend have been successfully loaded',
      );

      return deleteNote(id, getTokens, mock)
        .then(() => {
          setRemovedNoteIds(ids => [...ids, id]);
        })
        .catch(error => {
          errorSnack(t('error.notes.failedToDeleteNote'));
          throw error;
        });
    },
    [getTokens, errorSnack, loadedNotesData.status, mock, t],
  );

  // Combine noteData and users into (note, user)
  useEffect(() => {
    if (loadedNotesData.status === 'Loaded') {
      // TODO: Load missing users
      const notesData = [
        ...editedNotesData,
        // Filter out notes that have been edited
        ...loadedNotesData.data.filter(
          l => !editedNotesData.find(e => e.id === l.id),
        ),
      ];

      setNotes(() => {
        const newNotes = {
          status: 'Loaded' as const,
          data: notesData
            .map(d => {
              const note = {
                note: d,
                user:
                  users?.data?.find(u => u.id === d.userId) ??
                  getDummyUser(d.userId),
              };
              return note;
            })
            .filter(n => !removedNoteIds.includes(n.note.id)),
        };
        return newNotes;
      });
    } else {
      setNotes(old =>
        loadedNotesData.status === old.status
          ? old
          : loadedNotesData.status === 'Failed'
          ? {
              status: 'Failed',
              error: loadedNotesData.error,
              old: old.data,
            }
          : loadedNotesData.status === 'Loading'
          ? {
              status: 'Loading',
              error: old.error,
              old: old.data,
            }
          : old,
      );
    }
  }, [editedNotesData, loadedNotesData, removedNoteIds, users]);

  return useMemo(
    () => ({
      notes,
      createNote,
      updateNote,
      removeNote,
    }),
    [createNote, notes, removeNote, updateNote],
  );
};
