import {useCallback, useState} from 'react';
import Link from '@mui/material/Link';
import {JSONValue} from 'types';
import {ProblemConstraintViolation} from 'api/http/types';
import {useLocalization} from 'localization';
import {AlertDialog} from 'components/alertdialog';
import {Layout} from 'components/layout';
import {PrettyPrintJson} from 'components/prettyPrintJson';
import {Spacer} from 'components/spacer';
import {Text} from 'components/text';
import {SnackBodyWithErrorDetailsProps} from '../types';

export const SnackBodyWithErrorDetails: React.FC<
  SnackBodyWithErrorDetailsProps
> = ({message, error, closeSnack}) => {
  const {t} = useLocalization();
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseDialog = useCallback(() => {
    setIsOpen(false);
    closeSnack();
  }, [closeSnack]);

  const problemError =
    error && error.info
      ? (error?.info as unknown as ProblemConstraintViolation).violations
        ? (error.info as unknown as ProblemConstraintViolation)
        : (error.info as unknown as JSONValue)
      : undefined;

  return (
    <Layout horizontal adjustCenter>
      <Text variant="body2" mode={'light'}>
        {message}
      </Text>
      <Spacer size={2} />
      <Link variant="body2" onClick={() => setIsOpen(true)} color={'#fff'}>
        {t('error.details.label')}
      </Link>
      <AlertDialog
        isOpen={isOpen}
        title={t('error.details.title')}
        body={<PrettyPrintJson json={problemError ?? {}} />}
        onConfirm={() => Promise.resolve(handleCloseDialog())}
        onCancel={() => undefined}
      />
    </Layout>
  );
};
