import React, {useCallback, useMemo, useState} from 'react';
import {FieldError, PartYear, Valx} from 'schemaDefinition/types';
import {evaluateFieldRequired} from 'schemaDefinition/functions';
import {isNullish, valueAsYear} from 'services/utils';
import {YearPickerField} from 'components';
import {toFieldError} from 'schema/form/functions/validators';
import {validateSingleYearValue} from 'schema/form/functions/validators/validateYear';
import {BaseFieldProps} from './types';
import {useDataFormContext} from '../../contexts';
import {useResolvedDataFormPartConfiguration, useYearRange} from '../../hooks';

type Props = BaseFieldProps & {
  part: PartYear<Valx>;
};

export const YearPicker: React.FC<Props> = ({
  part,
  value,
  setFieldValue,
  valuePath,
  label,
  mode,
  width,
  maxWidth,
  flex,
  localScope,
  globalScope,
  relatedScope,
}) => {
  const [visited, setVisited] = useState(false);
  const handleSetVisited = useCallback(() => setVisited(true), []);
  const {showErrors} = useDataFormContext();
  const {showWhenReadonlyAndEmpty} = useResolvedDataFormPartConfiguration(part);

  const handleValueChange = useCallback(
    (year: number | null) => {
      const newVal =
        isNullish(year) || isNaN(year) ? null : parseInt(year.toString());
      setFieldValue(valuePath, newVal);
    },
    [setFieldValue, valuePath],
  );

  const required = useMemo(() => {
    return evaluateFieldRequired(
      part.required,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      value,
    );
  }, [globalScope, part.required, relatedScope, localScope, value, valuePath]);

  const error = useMemo((): FieldError => {
    if (showErrors || visited) {
      const valid = validateSingleYearValue(part, {
        value,
        valuePath,
        localScope,
        globalScope,
        relatedScope,
      }).valid;
      return toFieldError(valid);
    }
    return false;
  }, [
    globalScope,
    part,
    relatedScope,
    localScope,
    showErrors,
    value,
    valuePath,
    visited,
  ]);

  const {minValue, maxValue} = useYearRange(
    part,
    valuePath,
    localScope,
    globalScope,
    relatedScope,
  );

  const yearNum = valueAsYear(value);
  return (
    <YearPickerField
      name={valuePath}
      label={label}
      value={yearNum}
      readonly={mode === 'read-only'}
      showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
      required={required}
      error={error}
      width={width}
      maxWidth={maxWidth}
      flex={flex}
      onChange={handleValueChange}
      onBlur={handleSetVisited}
      minValue={minValue}
      maxValue={maxValue}
    />
  );
};
