import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const ChevronRightIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M15.41 12L10.71 16.71L9.29004 15.29L12.59 12L9.29004 8.70996L10.71 7.28996L15.41 12Z" />
    </SvgIcon>
  );
};
