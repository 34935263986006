import React from 'react';
import {isMerchProductForm} from 'services/utils';
import {IconName, IconProps} from './types';
import {logWarning} from '../../logging';
import {Icon} from './Icon';

type Props = IconProps & {
  productForm?: string | null | undefined;
};

const formMap: {[key: string]: IconName} = {
  ED: 'Ebook',
  SA: 'PrintBook',
};

const categoryMap: {[key: string]: IconName} = {
  0: 'PrintBook', // When null
  A: 'AudioBook', // code: 'AA', label: 'Audio', notes: 'Audio recording – detail unspecified'},
  B: 'PrintBook', // code: 'BA', label: 'Book', notes: 'Book – detail unspecified'},
  C: 'Document', // code: 'CA', label: 'Sheet map', notes: 'Sheet map – detail unspecified'},
  D: 'DigitalPlatform', // code: 'DA', label: 'Digital (on physical carrier)',
  E: 'DigitalPlatform', // code: 'EA', label: 'Digital (delivered electronically)',
  F: 'Film', // code: 'FA', label: 'Film or transparency',
  L: 'DigitalPlatform', // code: 'LA', label: 'Digital product license',
  M: 'Document', // code: 'MA', label: 'Microform', notes: 'Microform – detail unspecified'},
  P: 'OtherItem', // code: 'PA', label: 'Miscellaneous print',
  S: 'OtherItem', // code: 'SA', label: 'Multiple-component retail product',
  V: 'Film', // code: 'VA', label: 'Video', notes: 'Video – detail unspecified'},
  X: 'OtherItem', // code: 'XA', label: 'Trade-only material',
  Z: 'OtherItem', // code: 'ZA', label: 'General merchandise',
};

const groupMap: {[key: string]: IconName} = {
  MERCH: 'OtherItem',
};

export const FormatIcon: React.FC<Props> = ({productForm, ...props}) => {
  const key = productForm || '0';
  const icon = isMerchProductForm(key)
    ? groupMap['MERCH']
    : formMap[key] ?? categoryMap[key[0]] ?? undefined;

  if (icon === undefined) {
    logWarning(`Unknown productform: ${productForm}`);

    return <Icon icon={categoryMap[0]} {...props} />;
  }

  return <Icon icon={icon} {...props} />;
};
