import {
  formatAmount,
  formatDate,
  formatRelativeTime,
  formatTime,
} from '../../localization';
import {Layout} from '../layout';
import {Text} from '../text';

export const LocalizedFormatting = () => {
  return (
    <Layout kind="primary">
      <Text variant="h4" textTransform="none">
        {formatDate()}
      </Text>
      <Text variant="h4" textTransform="none">
        {formatDate(undefined, 'long')}
      </Text>
      <Text variant="h4" textTransform="none">
        {formatTime()}
      </Text>
      <Text variant="h4" textTransform="none">
        {formatTime(undefined, 'long')}
      </Text>
      <Text variant="h4" textTransform="none">
        {formatRelativeTime('01/01/2022')}
      </Text>
      <Text variant="h4" textTransform="none">
        {formatRelativeTime('02/01/2022')}
      </Text>
      <Text variant="h4" textTransform="none">
        {formatAmount(234456.42723)}
      </Text>
      <Text variant="h4" textTransform="none">
        {formatAmount(234456.42723, 2)}
      </Text>
      <Text variant="h4" textTransform="none">
        {formatAmount(234456.42723, 3)}
      </Text>
    </Layout>
  );
};
