import {LocalizationContextType} from 'localization/context/types';
import {LinkedRoleCodeListId} from 'schemaDefinition/linkTypes';
import {entityLinkTypes} from '../entityLinks';
import {getLinkedSourceRoleCodelist} from './getSourceLinkedRoleCodelist';

export const getLinkedRoleCodelist = (
  codelistId: LinkedRoleCodeListId,
  localization?: LocalizationContextType,
) => {
  return getLinkedSourceRoleCodelist(codelistId, entityLinkTypes, localization);
};
