import {LoadStatus} from 'api/types';

const loading: LoadStatus[] = ['NotLoaded', 'Loading'];

export const mergeLoadStatus = (
  workStatus: LoadStatus,
  changeRequestsStatus: LoadStatus,
): LoadStatus => {
  if (loading.includes(workStatus) || loading.includes(changeRequestsStatus)) {
    return 'Loading';
  }

  // Consider Loaded / Error independent of chagenRequestStatus
  return workStatus;
};
