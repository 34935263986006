import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const MinusIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M17 11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11Z" />
    </SvgIcon>
  );
};
