import {Theme} from '@mui/system';
import {HeaderHeight} from '../settings';

export const useOuterSx = (theme: Theme) => {
  return {
    ml: {
      xs: 0,
      xl: 'auto',
    },
    mr: {
      xs: 0,
      xl: 'auto',
    },
    mt: `${HeaderHeight}px`,
    maxWidth: `${theme.breakpoints.values.xl}px`,
    p: 3,
    display: 'flex',
  };
};
