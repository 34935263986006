import {AgentSubType} from 'types';
import {Agent, AgentName} from 'api/types';

export const createEmptyAgent = (
  agentType: AgentSubType,
  suggestion?: string,
): Agent => {
  const suggestedNameVariant: AgentName[] | undefined = suggestion
    ? [{name: suggestion}]
    : undefined;

  return {agentType, nameVariants: suggestedNameVariant} as Agent;
};
