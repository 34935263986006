import {YearOrDateValue} from 'schemaDefinition/types';

export const isNullYearOrDate = (
  yearOrDate: YearOrDateValue | null | undefined,
): boolean => {
  // Year: no object or no date (since: date => no year)
  return (
    !yearOrDate ||
    (typeof yearOrDate.date !== 'string' && typeof yearOrDate.year !== 'number')
  );
};
