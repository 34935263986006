import {CodeListMap} from 'api/types';
import {Part} from 'schemaDefinition/types';
import {useOptionalCodeLists} from 'services/codeLists/hooks';
import {usePartCodelistIds} from './usePartCodelistIds';

export const usePartCodelists = <TVal>(
  part: Part<TVal>,
): CodeListMap | undefined => {
  const ids = usePartCodelistIds(part);
  return useOptionalCodeLists(ids);
};
