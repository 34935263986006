import {Thesaurus} from 'api/types';

export const getMissingNodeWithAncestors = (
  code: string,
  thesaurus: Thesaurus,
) => {
  return {
    node: {
      id: code,
      code,
      label: code,
    },
    ancestors: [
      {
        id: thesaurus.id,
        code: thesaurus.code,
        label: `missing_${code}`,
      },
      {
        id: thesaurus.children?.[0].id || 'missing',
        code: thesaurus.children?.[0].code || 'missing',
        label: `missing_${code}`,
      },
    ],
  };
};
