import {ManifestationV4} from 'api/types';
import {useLocalization} from 'localization';
import {useCodelist} from '../../../../../services/codeLists';
import {getManifestationHeaderTitle} from '../functions';

export const useManifestationTitle = (
  manifestation?: ManifestationV4,
): {line1: string; line2?: string} => {
  const {t} = useLocalization();

  const productFormOverrideCodelist = useCodelist('product_form_simple_values');
  const productFormCodelist = useCodelist('PRODUCT_FORM');
  const productFormDetailCodelist = useCodelist('PRODUCT_FORM_DETAIL');

  return (
    getManifestationHeaderTitle(manifestation, {
      productForm: productFormCodelist,
      productFormDetails: productFormDetailCodelist,
      productFormOverride: productFormOverrideCodelist,
    }) || t('page.metadata.manifestation.default.title')
  );
};
