import {useCallback} from 'react';
import {LinkedValueSearchFieldConfig} from 'feature/linkedValueSearch/types';
import {useLinkedValueSearchContext} from './useLinkedValueSearchContext';

export const useOpenSearch = () => {
  const context = useLinkedValueSearchContext();

  return useCallback(
    (config?: Partial<LinkedValueSearchFieldConfig>) => {
      context.setSourceField(
        {
          fieldId: 'searchPanel',
          fieldConfig: {
            titleKey: 'general.search',
            ...config,
          },
        },
        () => undefined,
      );
    },
    [context],
  );
};
