import {assert} from 'assert-ts';
import {
  PartExpand,
  PartSchema,
  Schema,
  SeparatedPart,
  Valx,
} from 'schemaDefinition/types';

/**
 * Flattens schema, i.e. horizontal layout (nested part arrays)
 * changed to vertical layout. Also applied to sub-schema and expand parts.
 */
export const getSchemaFlattended = <TVal = Valx>(
  schema: Schema<TVal>,
  shouldFlattenSubSchema: (part: PartSchema<TVal>) => boolean = () => true,
): Schema<TVal> => ({
  ...schema,
  parts: getPartsFlattend(schema.parts, shouldFlattenSubSchema),
});

const getPartsFlattend = <TVal = Valx>(
  parts: SeparatedPart<TVal>[],
  shouldFlattenSubSchema: (part: PartSchema<TVal>) => boolean,
): SeparatedPart<TVal>[] => {
  return parts
    .reduce<SeparatedPart<TVal>[]>((acc, p) => {
      if (Array.isArray(p)) {
        acc.push(...p);
      } else {
        acc.push(p);
      }
      return acc;
    }, [])
    .map(p => {
      assert(!Array.isArray(p));
      if (p.type === 'schema' && shouldFlattenSubSchema(p)) {
        return {
          ...p,
          parts: getPartsFlattend(p.parts, shouldFlattenSubSchema),
        };
      }
      if (p.type === 'expand') {
        return getExpandFlattended(p, shouldFlattenSubSchema);
      }

      return p;
    });
};

const getExpandFlattended = <TVal = Valx>(
  expand: PartExpand<TVal>,
  shouldFlattenSubSchema: (part: PartSchema<TVal>) => boolean,
): PartExpand<TVal> => {
  return {
    ...expand,
    when: expand.when.map(w => ({
      ...w,
      parts: getPartsFlattend(
        w.parts,
        shouldFlattenSubSchema,
      ) as typeof w.parts,
    })),
    default: expand.default
      ? (getPartsFlattend(
          expand.default,
          shouldFlattenSubSchema,
        ) as typeof expand.default)
      : undefined,
  };
};
