import {DefaultPartCompare, Part, Valx} from 'schemaDefinition/types';
import {PartDiffProps} from '../components/types';

export const needsUpdatedFullValueContainer = (
  part: Part<Valx>,
  diff: PartDiffProps,
): boolean => {
  return !diff || // Not appliacle if no diff
    diff.mode === 'original' || // Not applicable to original value
    // Should already have full value container on outer level
    diff.outerCompare === 'fullValue'
    ? false
    : (part.compare ?? diff.outerCompare ?? DefaultPartCompare) === 'fullValue';
};
