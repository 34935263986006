import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const MetadataSelectionIcon: React.FC<IconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M20 6H9V8H20V6Z" />
      <path d="M20 11H9V13H20V11Z" />
      <path d="M20 16H9V18H20V16Z" />
      <path d="M6 9C7.10457 9 8 8.10457 8 7C8 5.89543 7.10457 5 6 5C4.89543 5 4 5.89543 4 7C4 8.10457 4.89543 9 6 9Z" />
      <path d="M6 10C5.60444 10 5.21776 10.1173 4.88886 10.3371C4.55996 10.5568 4.30362 10.8692 4.15224 11.2346C4.00087 11.6001 3.96126 12.0022 4.03843 12.3902C4.1156 12.7781 4.30608 13.1345 4.58579 13.4142C4.86549 13.6939 5.22186 13.8844 5.60982 13.9616C5.99778 14.0387 6.39992 13.9991 6.76537 13.8478C7.13082 13.6964 7.44318 13.44 7.66294 13.1111C7.8827 12.7822 8 12.3956 8 12C8 11.4696 7.78929 10.9609 7.41421 10.5858C7.03914 10.2107 6.53043 10 6 10ZM6 12.75C5.85167 12.75 5.70666 12.706 5.58332 12.6236C5.45999 12.5412 5.36386 12.4241 5.30709 12.287C5.25033 12.15 5.23547 11.9992 5.26441 11.8537C5.29335 11.7082 5.36478 11.5746 5.46967 11.4697C5.57456 11.3648 5.7082 11.2933 5.85368 11.2644C5.99917 11.2355 6.14997 11.2503 6.28701 11.3071C6.42406 11.3639 6.54119 11.46 6.6236 11.5833C6.70601 11.7067 6.75 11.8517 6.75 12C6.74741 12.1981 6.66756 12.3874 6.52747 12.5275C6.38737 12.6676 6.19811 12.7474 6 12.75Z" />
      <path d="M6 15C5.60444 15 5.21776 15.1173 4.88886 15.3371C4.55996 15.5568 4.30362 15.8692 4.15224 16.2346C4.00087 16.6001 3.96126 17.0022 4.03843 17.3902C4.1156 17.7781 4.30608 18.1345 4.58579 18.4142C4.86549 18.6939 5.22186 18.8844 5.60982 18.9616C5.99778 19.0387 6.39992 18.9991 6.76537 18.8478C7.13082 18.6964 7.44318 18.44 7.66294 18.1111C7.8827 17.7822 8 17.3956 8 17C8 16.4696 7.78929 15.9609 7.41421 15.5858C7.03914 15.2107 6.53043 15 6 15ZM6 17.75C5.85167 17.75 5.70666 17.706 5.58332 17.6236C5.45999 17.5412 5.36386 17.4241 5.30709 17.287C5.25033 17.15 5.23547 16.9992 5.26441 16.8537C5.29335 16.7082 5.36478 16.5746 5.46967 16.4697C5.57456 16.3648 5.7082 16.2934 5.85368 16.2644C5.99917 16.2355 6.14997 16.2503 6.28701 16.3071C6.42406 16.3639 6.54119 16.46 6.6236 16.5833C6.70601 16.7067 6.75 16.8517 6.75 17C6.74741 17.1981 6.66756 17.3874 6.52747 17.5275C6.38737 17.6676 6.19811 17.7474 6 17.75Z" />
    </SvgIcon>
  );
};
