import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AnyAction} from 'redux';
import {FCWithChildren} from 'types';
import {useLocalization} from 'localization';
import {useGetTokens} from 'services/auth';
import {useLoadError} from 'services/utils';
import {useMockContext} from 'services/utils/contexts';
import {Scene} from 'components';
import {loadChangeRequestsAction} from '../functions/loadChangeRequestsAction';
import {loadWorkAction} from '../functions/loadWorkAction';
import {MetadataAction} from '../metadataActionTypes';
import {metadataSelector} from '../selectors';

type Props = {
  workId: string;
};

/**
 * Will only render children after metadata (work and changerequests) are successfully loaded.
 * If metadata are already loaded, they will be reloaded if id changes.
 * Shows spinner while loading, and an error message if any fails
 * @param param0
 * @returns
 */
export const MetadataGate: FCWithChildren<Props> = ({workId, children}) => {
  const dispatch = useDispatch();
  const getTokens = useGetTokens();
  const {t} = useLocalization();
  const mock =
    useMockContext() || workId === '89495de6-3796-473f-834c-52af773a4d8d'; /*||
    workId === 'e5d06718-29e1-49d5-93d6-95e933224554' ||
    workId === '06d8705d-b6f5-471b-9a7b-fd69fb6ee33d' ||
    workId === 'e5d06718-29e1-49d5-93d6-95e933224553' ; */
  const {status, data, parts} = useSelector(metadataSelector);

  // When mounting, trigger
  // => reset of metadata state if id changes
  // => loading work (with expressions and manifestations) if not loaded
  useEffect(() => {
    const initialStatus = status;
    if (initialStatus === 'Loaded' && data?.work.id !== workId) {
      const action: MetadataAction = {type: 'METADATA_RESET'};
      dispatch(action);
    }

    if (initialStatus === 'NotLoaded' || data?.work.id !== workId) {
      dispatch(loadWorkAction(workId, getTokens, mock) as unknown as AnyAction);
    }
    // On mount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When work is loaded, trigger
  // => loading of change requests. We need the EANs from manifestations to load the change requests.
  useEffect(() => {
    if (
      parts.work.status === 'Loaded' &&
      parts.changeRequests.status === 'NotLoaded'
    ) {
      const eans: string[] = (parts.work.data.manifestations
        .map(m => m.ean)
        .filter(ean => ean !== undefined) || []) as string[];

      dispatch(
        loadChangeRequestsAction(
          workId,
          eans,
          getTokens,
          mock,
        ) as unknown as AnyAction,
      );
    }
  }, [
    dispatch,
    getTokens,
    mock,
    parts.changeRequests.status,
    parts.work,
    workId,
  ]);

  useLoadError(parts.changeRequests.status, 'page.metadata.cr.load.failed');

  return status === 'NotLoaded' ||
    status === 'Loading' ||
    status === 'Failed' ||
    parts.work.status === 'Failed' ||
    data?.work.id !== workId ? (
    <Scene>
      <Scene.Header title="" />
      <Scene.Content
        loadStatus={parts.work.status === 'Failed' ? parts.work.status : status}
        error={
          status === 'Failed' || parts.work.status === 'Failed'
            ? t('error.work.failedToLoad')
            : undefined
        }
      />
    </Scene>
  ) : (
    <>{children}</>
  );
};
