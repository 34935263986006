import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import {ManifestationV4} from 'api';
import {metadataSelector} from '../selectors';

export const useSavedManifestation = (
  manifestationId: string,
): ManifestationV4 => {
  const metadata = useSelector(metadataSelector);
  const manifestation = assert(
    metadata.data?.manifestations.find(m => m.id === manifestationId),
    'useSavedManifestation: unknown manifestationId',
    {manifestationId},
  );
  return manifestation;
};
