import {FilterSet} from '../../types';

/**
 * Private filtersets firsts, then sorted by name.
 */
export const sortFilterSets = (a: FilterSet, b: FilterSet) => {
  return a.isPublic < b.isPublic
    ? -1
    : a.isPublic > b.isPublic
      ? 1
      : a.name < b.name
        ? -1
        : 1;
};
