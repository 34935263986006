import {Dispatch} from 'redux';
import {AppState} from 'store/types';
import {CodeList, CodeListId, DataLoadStatus, GetTokens} from 'api';
import {getCodeList} from 'api/codeList';
import {AllCodeListIdsScopeMap} from 'schemaDefinition/linkTypes';
import {DefaultDataLoadStatus} from 'services/utils';
import {makeLoadByIdActionFactories} from 'services/utils/redux/functions/makeLoadByIdActionFactories';
import {CodeListActionType, CodeListsAction} from '../types';

const {makeRequestAction, makeSuccessAction, makeFailureAction} =
  makeLoadByIdActionFactories<
    CodeList,
    CodeListId,
    CodeListActionType.LOAD_CODELIST
  >(CodeListActionType.LOAD_CODELIST);

export const loadCodeListAction = (
  id: CodeListId,
  language: string,
  getTokens: GetTokens,
  mock?: boolean,
) => {
  return (dispatch: Dispatch<CodeListsAction>, getState: () => AppState) => {
    const codeList =
      getState().codeLists[id] ??
      (DefaultDataLoadStatus as DataLoadStatus<CodeList>);

    if (
      codeList.status === 'NotLoaded' &&
      AllCodeListIdsScopeMap[id] !== 'local'
    ) {
      dispatch(makeRequestAction(id));

      getCodeList(id, language, getTokens, mock)
        .then(codelist => {
          dispatch(makeSuccessAction(id, codelist));
        })
        .catch(error => {
          dispatch(makeFailureAction(id, error));
        });
    }
  };
};
