import {NotCondition, NotConditionV0, Valx} from '../types';
import {EvaluationArgs} from './types';
import {evaluateCondition} from './evaluateCondition';

export const evaluateNotCondition = (
  condition: NotConditionV0<Valx> | NotCondition<Valx>,
  args: EvaluationArgs,
): boolean => {
  const notArgs =
    (condition as NotCondition<Valx>).$not ??
    (condition as NotConditionV0<Valx>).arg;

  return !evaluateCondition(notArgs, args);
};
