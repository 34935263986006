import {Persist_Rehydrate} from 'store/types';
import {logWarning} from 'logging';
import {SettingsDefaultState, SettingsState} from './types';
import {deepMergeStates} from '../utils/deepMergeStates';
import {SettingsAction} from './actions';

const FONT_SIZE_DEFAULT = 100;
export const settingsReducer = (
  state: SettingsState = SettingsDefaultState,
  action: SettingsAction | Persist_Rehydrate,
): SettingsState => {
  switch (action.type) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    case 'persist/REHYDRATE': {
      const mergedState = action.payload
        ? deepMergeStates(action.payload['settings'], state, logWarning)
        : state;
      return mergedState;
    }

    case 'ADJUST_FONT': {
      return {
        ...state,
        fontSizePct: Math.min(
          150,
          Math.max(
            90,
            (state.fontSizePct ?? FONT_SIZE_DEFAULT) +
              action.payload.changePercentPoint,
          ),
        ),
      };
    }
    case 'RESET_FONT': {
      return {
        ...state,
        fontSizePct: FONT_SIZE_DEFAULT,
      };
    }
    case 'SET_LOCALE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'SET_TABLE_COLUMN_CONFIGS': {
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.payload.name]: {
            ...state.tables[action.payload.name],
            columns: action.payload.config,
          },
        },
      };
    }
    case 'SET_TABLE_SORTING': {
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.payload.name]: {
            ...state.tables[action.payload.name],
            sorting: action.payload.sorting,
          },
        },
      };
    }
    case 'SET_TABLE_FILTERMODEL': {
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.payload.name]: {
            ...state.tables[action.payload.name],
            filterModel: action.payload.filterModel,
          },
        },
      };
    }
    case 'SET_OVERVIEW_COLUMN_VISIBILITY': {
      return {
        ...state,
        overview: {
          ...state.overview,
          columnsVisibility: {
            ...state.overview.columnsVisibility,
            ...action.payload,
          },
        },
      };
    }
    case 'SET_OVERVIEW_COLUMN_ORDER': {
      return {
        ...state,
        overview: {
          ...state.overview,
          columnsOrder: action.payload,
        },
      };
    }
    case 'SET_OVERVIEW_SELECTED_TASK_TYPE': {
      return {
        ...state,
        overview: {
          ...state.overview,
          selectedTaskType: action.payload,
        },
      };
    }
    case 'SET_OVERVIEW_SORTING': {
      return {
        ...state,
        overview: {
          ...state.overview,
          sorting: action.payload,
        },
      };
    }
    case 'SET_OVERVIEW_SEARCH_TEXT': {
      return {
        ...state,
        overview: {
          ...state.overview,
          searchText: action.payload,
        },
      };
    }
    case 'SET_ADVANCEDSEARCH_FILTERS': {
      return {
        ...state,
        advancedSearch: {
          ...state.advancedSearch,
          currentFilters: action.payload,
        },
      };
    }
    case 'SET_TASKS_TODOLINKS': {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          todoLinks: action.payload,
        },
      };
    }
  }

  return state;
};
