import {useMemo} from 'react';
import {AvailabilityStatus} from 'api/types';
import {useLocalization} from 'localization';

type AvailabilityStatusTipsMap = {
  [key in AvailabilityStatus]: string;
};

/**
 * @deprecated Use useProductStatusTips instead
 */
export const useAvailabilityStatusTips = (): AvailabilityStatusTipsMap => {
  const {t} = useLocalization();
  return useMemo(
    () => ({
      ['AVAILABLE']: t('entity.manifestation.availabilityStatus.tip.AVAILABLE'),
      ['PRE_ORDER']: t('entity.manifestation.availabilityStatus.tip.PRE_ORDER'),
      ['UNAVAILABLE']: t(
        'entity.manifestation.availabilityStatus.tip.UNAVAILABLE',
      ),
    }),
    [t],
  );
};
