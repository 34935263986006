import {assert} from 'assert-ts';
import {
  AnySceneExtension,
  FieldExtensionEnum,
  SceneExtension,
} from 'sceneExtensions/types';

export const assertSceneExtensionContext = <
  TType extends FieldExtensionEnum,
  TValue,
  TSourceValue = TValue,
  TAction extends string = string,
  TFieldConfig = undefined,
>(
  extensionType: TType,
  extensions: AnySceneExtension[],
): SceneExtension<TType, TValue, TSourceValue, TAction, TFieldConfig> => {
  return assert(
    extensions.find(ex => ex.type === extensionType),
    'assertSceneExtensionContext: extension expected',
    {extensionType},
  ) as SceneExtension<TType, TValue, TSourceValue, TAction, TFieldConfig>;
};
