import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import {ThesaurusesMap} from 'api/types';
import {Schema} from 'schemaDefinition/types';
import {AppState} from 'store/types';
import {useSchemaThesaurusIds} from './useSchemaThesaurusIds';

const thesaurusStateSelector = (state: AppState) => state.thesauruses;

/**
 * Assumes needed thesauruses have been loaded
 * @param schema
 * @returns
 */
export const useSchemaThesauruses = <TVal>(
  schema: Schema<TVal> | undefined,
): ThesaurusesMap => {
  const thesaurusIds = useSchemaThesaurusIds(schema);
  const thesauruses = useSelector(thesaurusStateSelector);

  return useMemo(() => {
    return thesaurusIds.reduce<ThesaurusesMap>((acc, id) => {
      const thesaurus = assert(
        thesauruses[id],
        `useSchemaThesauruses: missing thesaurus for id: ${id}`,
        {id},
      );
      assert(
        thesaurus.status === 'Loaded',
        'useSchemaThesauruses: thesaurus not loaded',
        {id},
      );
      acc[id] = thesaurus.data;
      return acc;
    }, {} as ThesaurusesMap);
  }, [thesaurusIds, thesauruses]);
};
