import assert from 'assert-ts';
import {ArrayUpdateHistory} from 'services/data/metadata/types';

/**
 * Resolved current index to an item in an array,
 * given initial index to the item and history of updates
 * E.g.:
 * original index 4, history: [ remove at 3, remove at 2, add at 3, add at 1, add at 4]
 * updated index pr step in history: 4->3->2->2->3->3 (reducer below)
 */
export const resolveUpdatedArrayIndex = (
  initialIndex: number | undefined,
  history: ArrayUpdateHistory = [],
): number | undefined => {
  return history.reduce<number | undefined>((index, update) => {
    const itemIndex = update.atIndex;
    if (
      index === undefined ||
      !assert.soft(itemIndex, 'resolveUpdatedArrayIndex: itemIndex not found', {
        initialIndex,
        history,
      })
    ) {
      return undefined;
    }

    if (update.operation === 'addItem') {
      return itemIndex <= index ? index + 1 : index;
    } else if (update.operation === 'removeItem') {
      if (
        !assert.soft(
          itemIndex != index,
          'resolveUpdatedArrayIndex: cannot perform multiple operations on the same item',
          {initialIndex, history, itemIndex, index},
        )
      ) {
        return undefined;
      }
      return itemIndex < index ? index - 1 : index;
    }
    return index;
  }, initialIndex);
};
