import dayjs from 'dayjs';
import {Indexer} from 'types';
import {Schemas} from 'api/dto.generated';
import {HttpArgs} from '../httpMethod';
import agentSearchPerson from './data/agent.search.person';
import changerequestforimportapprovalsWork from './data/changerequestforimportapprovals.work.89495de6-3796-473f-834c-52af773a4d8d';
import {collections} from './data/collections';
import notes from './data/note.contextId-9b0726fd-bca2-4bf9-b331-507e4e41ff1d.json';
import bugWhenEanOnlyWork from './data/work.v2.06d8705d-b6f5-471b-9a7b-fd69fb6ee33d';
import work from './data/work.v2.3c32868c-69ae-48c5-9674-42273022f090';
import workWithChangeRequestFromImport from './data/work.v2.89495de6-3796-473f-834c-52af773a4d8d';
import movedToWork from './data/work.v2.e5d06718-29e1-49d5-93d6-95e933224553';
import movedToWorkDemo from './data/work.v2.e5d06718-29e1-49d5-93d6-95e933224554';

let lazyGetMocks: {[key in string]: Indexer | Indexer[]} | undefined;

let lazyPatchMocks: typeof lazyGetMocks;

const getNotes = () => {
  return {
    notes: notes.notes.map(n => {
      return {
        ...n,
        created: dayjs().subtract(n.created, 'seconds').toISOString(),
        modified: dayjs().subtract(n.created, 'seconds').toISOString(),
      };
    }),
  };
};

const getLock = (userId?: string): Schemas.LockDto => ({
  id: '1',
  contextId: '9ad7483d-f735-434e-b18a-2ddddd16cb58',
  contextType: 'work',
  // lockedBy: 'onelogin_bjorn.egil@framx.no',
  lockedBy: userId ?? 'onelogin_harald@bokbasen.no',
  lockedAt: Date.now() - 5000,
});

// Usage example:
const grepExtendedConcept: Schemas.GrepExtendedConcept = {
  id: 'KRO1018',
  type: 'subject_code',
  deactivated: false,
  label: 'Kroppsøving 1. årstrinn',
  note: `
      details: 1. årstrinn, 2. årstrinn, 3. årstrinn,
      note: Dette er en note',
      validFrom: 2019-08-01,
      validTo: 2020-07-31,
    `,
  related: [
    {
      id: 'NOR0204',
      type: 'subject_code',
      deactivated: false,
      label: 'Norsk 4. årstrinn',
    },
  ],
};

export const getMockByKey = (
  key: string,
  method: HttpArgs['method'],
): Indexer | Indexer[] | undefined => {
  switch (method) {
    case 'GET': {
      if (!lazyGetMocks) {
        lazyGetMocks = {
          'agent.search': [
            require('./data/agent.c875d125-2e2e-422d-a8a8-0c6c931a87b8'),
            require('./data/agent.35556133-555c-4bfd-bece-90822db8ff4b.json'),
          ],
          'agent.search.person': agentSearchPerson,
          'agent.c875d125-2e2e-422d-a8a8-0c6c931a87b8': require('./data/agent.c875d125-2e2e-422d-a8a8-0c6c931a87b8'),
          'manifestation.isbn': require('./data/manifestation.isbn'),
          'manifestation.9b0726fd-bca2-4bf9-b331-507e4e41ff1d.media': require('./data/manifestation.9b0726fd-bca2-4bf9-b331-507e4e41ff1d.media.json'),
          'work.v2.3c32868c-69ae-48c5-9674-42273022f090': work,

          'work.v2.e5d06718-29e1-49d5-93d6-95e933224553': movedToWork,
          'work.v2.e5d06718-29e1-49d5-93d6-95e933224554': movedToWorkDemo,
          'work.v2.06d8705d-b6f5-471b-9a7b-fd69fb6ee33d': bugWhenEanOnlyWork,
          'work.v2.89495de6-3796-473f-834c-52af773a4d8d':
            workWithChangeRequestFromImport,
          'manualapproval.changerequest.ean_ean-9788202276874,9788202285883,9788202740658':
            changerequestforimportapprovalsWork,
          'query.manifestation.isbn.9788202098087': require('./data/query.manifestation.isbn.9788202098087.json'),
          'query.tasks': require('./data/query.tasks.json'),
          'note_contextId-9b0726fd-bca2-4bf9-b331-507e4e41ff1d': getNotes(),
          'media.1': require('./data/media.1.json'),
          'media.2': require('./data/media.2.json'),
          'media.3': require('./data/media.3.json'),
          'media.4': require('./data/media.4.json'),
          'collection.1': collections['1'],
          'collection.2': collections['2'],
          'collection.3': collections['3'],
          'collection.4': collections['4'],
          'collection.5': collections['5'],
          'collection.new': collections['1'],
          'lock.work.9ad7483d-f735-434e-b18a-2ddddd16cb58': [getLock()],
          'thesaurus.grep.code.KRO1018': grepExtendedConcept,
          'work.v2.4e45e65c-71e7-444f-8372-8b4b9ee4b8a9': require('./data/work.v2.4e45e65c-71e7-444f-8372-8b4b9ee4b8a9.json'),
          'manualapproval.changerequest.ean_ean-9788202842130': require('./data/manualapproval.changerequest.ean_ean-9788202842130.json'),
          'manualapproval.changerequest.ean_ean-9788202499891,9788202446543,9788202401269,9788202430214,9788202838065,9788245086621,9788245087512,9788245087567,9788202519612,9788202611774,9788202484187,9788202470135,9788202416102,9788202591793,9788245089486,9788202668303,9788202508340,9788202776213,9788202585914,9788202650643,9788202571467,9788202629588,9788245088953,9788245089189,9788202811266,9788202795849,9788245049923,9788202736200,9788202690311,9788245088465,9788245087079,9788245085709,9788202756574,9788202714499,9788245086133,9788245088922,9788245088199,9788245087444': require('./data/9788245087444.json'),
        };
      }

      return lazyGetMocks[key];
    }
    case 'PATCH': {
      if (!lazyPatchMocks) {
        lazyPatchMocks = {
          'lock.1.acquire': getLock('onelogin_bjorn.egil@framx.no'),
        };
      }

      return lazyPatchMocks[key];
    }
    default:
      return undefined;
  }
};
