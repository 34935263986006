import {useEffect, useRef, useState} from 'react';
import {HttpError} from 'api/http/types';
import {DataLoadStatus} from '../types';

/**
 * Handles loading of a resource (entity) by id for a given a load function,
 * i.e. returns resource with load stauts ('NotLoaded', 'Loading', 'Loaded', 'Failed')
 *
 * get - function to load resource from api by id
 * returns resource with load status
 */
export const useGetResourceById = <TData>(
  id: string | undefined,
  get: (id: string | undefined) => Promise<TData>,
): DataLoadStatus<TData> => {
  const [result, setResult] = useState<DataLoadStatus<TData>>({
    status: 'NotLoaded',
  });
  const idRef = useRef(id);

  useEffect(() => {
    if (result.status === 'NotLoaded' || idRef.current !== id) {
      idRef.current = id;
      setResult(old => ({status: 'Loading', data: old.data}));
      get(id)
        .then(data => setResult(() => ({status: 'Loaded', data})))
        .catch((error: HttpError) =>
          setResult(() => ({status: 'Failed', error})),
        );
    }
  }, [id, get, result.status]);

  return result;
};
