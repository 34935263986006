import React from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  SxProps,
} from '@mui/material';
import {useLocalization} from 'localization';

export interface ConfirmationDialogProps {
  id: string;
  keepMounted: boolean;
  open: boolean;
  onClose: (value?: boolean) => void;
}

const ConfirmationDialogRawSx: SxProps = {
  '& .MuiDialog-paper': {width: '80%'},
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  onClose,
  open,
  ...other
}) => {
  const {t} = useLocalization();
  const [doubleConfirmed, setDoubleConfirmed] = React.useState(false);

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    if (doubleConfirmed) {
      onClose(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDoubleConfirmed(event.target.checked);
  };

  return (
    <Dialog sx={ConfirmationDialogRawSx} maxWidth="xs" open={open} {...other}>
      <DialogTitle>{t('general.areyousure')}</DialogTitle>
      <DialogContent dividers>
        <FormControlLabel
          control={
            <Checkbox
              aria-label="doubleConfirm"
              name="doubleConfirm"
              value={doubleConfirmed}
              onChange={handleChange}
            />
          }
          label={t('general.deleteDoubleConfirm')}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          {t('general.cancel')}
        </Button>
        <Button onClick={handleOk}>{t('general.deleteConfirm')}</Button>
      </DialogActions>
    </Dialog>
  );
};
