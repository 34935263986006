import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const ArrowRightIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M3.21994 13.0002L16.3599 13.0002L10.2899 19.0702L11.7099 20.4902L20.1899 12.0002L11.7099 3.51024L10.2899 4.93024L16.3599 11.0002L3.21994 11.0002L3.21994 13.0002Z"
        fill={'currentColor'}
      />
    </SvgIcon>
  );
};
