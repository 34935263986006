import React, {useMemo} from 'react';
import {DialogActionItem} from 'components/dialog/types';
import {IconName} from 'components/icons/types';
import {useLocalization} from 'localization';
import {Dialog} from '../dialog';

export type AlertDialogProps = {
  isOpen: boolean;
  title: string;
  body?: string | React.ReactNode;
  okTitle?: string;
  okIcon?: IconName;
  cancelTitle?: string;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
};

export const AlertDialog: React.FC<AlertDialogProps> = ({
  isOpen,
  title,
  body,
  okTitle,
  okIcon,
  cancelTitle,
  onCancel,
  onConfirm,
}) => {
  const {t} = useLocalization();

  const actions = useMemo(() => {
    const cancelAction: DialogActionItem | undefined = cancelTitle
      ? {
          itemType: 'action',
          icon: 'Close',
          title: cancelTitle,
          background: 'transparent',
          onClick: onCancel,
        }
      : undefined;

    const okAction: DialogActionItem = {
      itemType: 'action',
      icon: okIcon ?? 'Check',
      title: okTitle ?? t('general.ok'),
      background: 'green2',
      onClick: onConfirm,
      showSpinnerOnClick: true,
    };

    return (
      cancelAction ? [cancelAction, okAction] : [okAction]
    ) as DialogActionItem[];
  }, [cancelTitle, okIcon, okTitle, onCancel, onConfirm, t]);

  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      body={body}
      onClose={onCancel}
      dialogItems={actions}
    />
  );
};
