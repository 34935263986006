import {assert} from 'assert-ts';
import {IconName} from 'components/icons/types';
import {AdornmentResolver} from 'schema/types';
import {Icon, IconNames} from 'components/icons';

const defaultAdornementResolver = (ref: string | undefined) => {
  if (!ref) return undefined;
  if (ref.startsWith('icon:')) {
    const iconName = ref.slice(5);
    assert(
      IconNames.includes(iconName as IconName),
      'adornmentResolver: Invalid icon name: ' + iconName,
    );
    return <Icon icon={iconName as IconName} />;
  }
  return ref;
};

export const useDefaultAdornementResolver = (): AdornmentResolver => {
  return defaultAdornementResolver;
};
