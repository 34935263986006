import {User} from 'api/types';
import {LoadAction} from 'services/utils/redux/actions';

export enum UserActionType {
  LOAD_USERS = 'LOAD_USERS',
  RESET_USERS = 'RESET_USERS',
}
export type UserAction_Load = {
  type: UserActionType.LOAD_USERS;
} & LoadAction<User[]>;

export type UsersAction_Reset = {
  type: UserActionType.RESET_USERS;
};

export type UsersAction = UserAction_Load | UsersAction_Reset;
