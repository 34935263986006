import {Thesaurus} from 'api/types';
import {Translate} from 'localization/context/types';
import {
  PartThesaurus,
  SelectableThesaurusNodeType,
} from 'schemaDefinition/types';
import {getActualThesaurusNodeTypePaths} from 'services/data/metadata/functions/getActualThesaurusNodeTypePaths';
import {groupAndOrderByType} from 'services/thesaurus/functions';
import {PerGroupDiff} from './types';

export const getThesaurusDiffPerType = (
  part: PartThesaurus<unknown>,
  valuePath: string,
  originalValue: string[] | undefined,
  changedValue: string[] | undefined,
  thesaurus: Thesaurus,
  t: Translate,
): PerGroupDiff => {
  groupAndOrderByType;

  const {paths, groupedOriginal, groupedChanges} =
    getActualThesaurusNodeTypePaths<SelectableThesaurusNodeType>(
      part,
      valuePath,
      originalValue ?? null,
      changedValue ?? null,
      {[part.thesaurusId]: thesaurus},
    );

  const groupedOriginalTerms = groupedOriginal?.map(g => ({
    groupId: g.nodeType,
    groupName: t(`thesaurus.grep.nodeType.${g.nodeType}.label`),
    groupTerms: g.nodes.map(n => n.node.code),
  }));
  const groupedChangesTerms = groupedChanges?.map(g => ({
    groupId: g.nodeType,
    groupName: t(`thesaurus.grep.nodeType.${g.nodeType}.label`),
    groupTerms: g.nodes.map(n => n.node.code),
  }));

  const perGroupDiff = (paths ?? []).map(groupPath => {
    const [, groupId] = groupPath.split('#');
    const originalTerms = groupedOriginalTerms?.find(
      g => g.groupId === groupId,
    )?.groupTerms;
    const changesTerms = groupedChangesTerms?.find(
      g => g.groupId === groupId,
    )?.groupTerms;
    return {
      groupId: groupId,
      groupName: (groupedOriginalTerms ?? groupedChangesTerms)?.find(
        g => g.groupId === groupId,
      )?.groupName,
      groupPath: groupPath,
      originalTerms,
      changesTerms,
    };
  });

  return perGroupDiff;
};
