import {configureAssert} from 'assert-ts';
import {Indexer} from '../types';
import {AppError} from './types';
import {logError, logWarning} from './logger';

export const configureAssertLogging = () => {
  configureAssert({
    errorCreator: (failureType: string, message?: string, props?: object) => {
      const typeMsg = failureType === 'Condition' ? 'false' : 'undefined/null';
      const msg = message ? `${typeMsg}: ${message}` : typeMsg;
      return new AppError(msg, props as Indexer);
    },
    errorReporter: (
      _failureType: string,
      error: Error,
      _message?: string,
      props?: object,
    ) => {
      logError(error, props as Indexer);
    },
    warningReporter: (
      failureType: string,
      message?: string,
      props?: object,
    ) => {
      const typeMsg = failureType === 'Condition' ? 'false' : 'undefined/null';
      const msg = `${typeMsg}: ${message ?? ''}`;
      logWarning(msg, props as Indexer);
    },
  });
};
