import {useCallback, useEffect, useMemo, useReducer, useRef} from 'react';
import {
  Collection,
  HttpError,
  getCollection,
  postCollection,
  putCollection,
} from 'api';
import {useGetTokens} from 'services/auth';
import {
  CollectionDefaultState,
  CollectionState,
  collectionReducer,
} from '../reducer/collectionReducer';

export type SaveStatus = 'Saved' | 'Failed' | 'None';

export type SaveCollectionFunc = (
  collection: Collection,
) => Promise<Collection>;

export const useCollection = (
  collectionId?: string | null,
  mock?: boolean,
): {
  collection: CollectionState;
  saveCollection: SaveCollectionFunc;
} => {
  const getTokens = useGetTokens();
  const [collection, dispatch] = useReducer(
    collectionReducer,
    CollectionDefaultState,
  );
  const collectionIdRef = useRef(collectionId);

  useEffect(() => {
    if (collection.status === 'NotLoaded') {
      if (collectionId) {
        dispatch({type: 'LOAD_COLLECTION', loadType: 'REQUEST'});

        getCollection(collectionId, getTokens, mock)
          .then(data =>
            dispatch({
              type: 'LOAD_COLLECTION',
              loadType: 'SUCCESS',
              payload: {data},
            }),
          )
          .catch((error: HttpError) =>
            dispatch({
              type: 'LOAD_COLLECTION',
              loadType: 'FAILURE',
              payload: {error},
            }),
          );
      }
    }
  }, [collection.status, collectionId, getTokens, mock]);

  // Reset load when collectionId changes
  useEffect(() => {
    if (collectionId !== collectionIdRef.current) {
      collectionIdRef.current = collectionId;
      dispatch({type: 'LOAD_COLLECTION', loadType: 'NOT_LOADED'});
    }
  });

  /** Save collection to backend and update reducer state if successful */
  const saveCollection = useCallback<SaveCollectionFunc>(
    collection => {
      const apiCall: Promise<Collection> = collectionId
        ? putCollection(collection, getTokens, mock)
        : postCollection(collection, getTokens, mock);

      return apiCall.then(updatedCollection => {
        dispatch({
          type: 'SAVED_COLLECTION',
          payload: {data: {...collection, ...updatedCollection}},
        });
        return updatedCollection;
      });
    },
    [collectionId, getTokens, mock],
  );

  return useMemo(() => {
    return {
      collection,
      saveCollection,
    };
  }, [collection, saveCollection]);
};
