import React, {useMemo} from 'react';
import {Translate} from 'localization/context/types';
import {useLocalization} from 'localization';
import {Lock} from 'api';
import {getFirstName} from 'services/users/functions';
import {ActionButton, Avatar, FlexBox, Icon, Spacer, Text} from 'components';
import {LockState} from '../contexts/types';
import {useWorkEditStateContext} from '../contexts';

const formatLockDuration = (
  lock: Lock | undefined,
  nowMs: number,
  t: Translate,
): string | undefined => {
  if (!lock) return undefined;
  const duration = Math.floor((nowMs - lock.lockedAt) / 1000);
  if (duration < 60) return `${duration} ${t('general.unit.seconds')}`;
  if (duration < 3600)
    return `${Math.floor(duration / 60)} ${t('general.unit.minutes')}`;
  if (duration < 86400)
    return `${Math.floor(duration / 3600)}:${Math.floor(
      (duration % 3600) / 60,
    )} ${t('general.unit.hours')}`;
  return `${Math.floor(duration / 86400)} ${t('general.unit.days')}`;
};

export const WorkLockStatus: React.FC = () => {
  const {
    state,
    lock,
    byOtherUser: byUser,
    acquireLock: unlock,
  } = useWorkEditStateContext();
  const {t} = useLocalization();
  const lockDuration = useMemo(() => {
    return formatLockDuration(lock, Date.now(), t);
  }, [lock, t]);
  return state === LockState.LockedByOther ? (
    <FlexBox horizontal left center>
      {byUser?.picture ? (
        <>
          <Avatar src={byUser.picture} color="primary" />
          <Spacer width={1} />
        </>
      ) : null}
      <FlexBox center>
        {/* User name and lock duration */}
        {byUser?.name ? (
          <Text
            variant="caption"
            sx={{lineHeight: '1rem', textAlign: 'center'}}>
            {t('page.metadata.lock.lockedBy')} <b>{getFirstName(byUser)}</b>
            <br />
            {t('page.metadata.lock.lockedFor')} {lockDuration}
          </Text>
        ) : null}
      </FlexBox>
      <Spacer width={2} />
      <ActionButton
        title={t('page.metadata.lock.acquire.title')}
        icon={'Locked'}
        onClick={unlock}
      />
    </FlexBox>
  ) : state === LockState.LockedByThis ||
    state === LockState.TemporarilyLocked ? (
    <FlexBox px={1}>
      <Icon icon="Locked" color="primary" />
    </FlexBox>
  ) : null;
};
