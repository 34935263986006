import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import jwt_decode from 'jwt-decode';
import {AppState} from 'store/types';
import {clearUser} from 'logging';
import {User} from 'api';
import {AuthState} from '../types';
import {IdToken} from './useUser';

const authSelector = (state: AppState) => state.auth;

type AuthStateProps = {
  authState: AuthState;
  login: (jwtToken: string) => void;
  loginFailed: (error?: string) => void;
  logout: () => void;
};

export const useAuthState = (): AuthStateProps => {
  const dispatch = useDispatch();
  const authState = useSelector(authSelector);
  const navigate = useNavigate();

  const login = useCallback(
    (jwtToken: string) => {
      const token = jwt_decode<IdToken>(jwtToken);

      const user: User = {
        id: token['cognito:username'],
        email: token.email,
        name: token.given_name || token.name,
        picture: token.picture,
      };

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });

      /* * Since we use Sentry Replay Session we don't want user identifiable information anymore
      setUser({
        id: user.id,
        email: user.email,
        username: user.email,
        name: user.name
      });
      */

      dispatch({
        type: 'RESET_USERS',
        payload: {
          status: 'NotLoaded',
        },
      });
    },
    [dispatch],
  );

  const loginFailed = useCallback(
    (error?: string) => {
      dispatch({type: 'LOGIN_FAILED', payload: {error}});
      navigate('/login');
    },
    [dispatch, navigate],
  );

  const logout = useCallback(() => {
    dispatch({
      type: 'LOGOUT',
    });

    // Remove Sentry user
    clearUser();

    dispatch({
      type: 'RESET_USERS',
      payload: {
        status: 'NotLoaded',
      },
    });

    navigate('/login', {replace: true});
  }, [dispatch, navigate]);

  return {
    authState,
    login,
    loginFailed,
    logout,
  };
};
