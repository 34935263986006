import React, {useMemo} from 'react';
import {SxProps} from '@mui/material';
import {PaginatorProps} from './types';
import {FlexBox} from '../layout';
import {Text} from '../text';
import {IconTool} from '../toolbar';

const wrapperSx: SxProps = {
  textWrap: 'nowrap',
};

export function Paginator({
  page,
  pages,
  onSelectPrevious,
  onSelectNext,
  preText,
}: Pick<
  PaginatorProps,
  'page' | 'pages' | 'onSelectPrevious' | 'onSelectNext' | 'preText'
>) {
  const paginationText = useMemo(() => {
    return `${preText ? preText + ' ' : ''}${page} / ${pages}`;
  }, [preText, page, pages]);

  return (
    <FlexBox
      alignItems={'center'}
      horizontal
      alignContent={'center'}
      justifyContent={'center'}
      sx={wrapperSx}>
      <IconTool
        icon="DropDown"
        rotate={90}
        onClick={onSelectPrevious}
        disabled={page <= 1}
      />
      <Text variant={'h3'} component={'span'} sx={{pt: '3px'}}>
        {paginationText}
      </Text>
      <IconTool
        icon="DropDown"
        onClick={onSelectNext}
        rotate={270}
        disabled={page >= pages}
      />
    </FlexBox>
  );
}
