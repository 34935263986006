import {Column} from '../types';
import {Schemas} from '../dto.generated';

export const mapAvailableColumns = (dto: Schemas.Column[]): Column[] => {
  return dto.map(mapAvailableColumn);
};

export const mapAvailableColumn = (dto: Schemas.Column): Column => {
  return {
    ...dto,
    codelistId: dto.codelistId as Schemas.CodelistDto['id'],
  };
};
