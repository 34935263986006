import React, {useMemo} from 'react';
import {CodeListsGate} from 'services/codeLists';
import {useAllCodeListIds} from 'services/codeLists/hooks/useAllCodeListIds';
import {AllThesaurusIds, ThesaurusesGate} from 'services/thesaurus';
import {
  SceneExtensionsContext,
  useLinkedValueSearchSceneExtensionCore,
  useSceneExtensionsContextProviderValue,
} from 'sceneExtensions';
import {AgentEditSceneCore} from './AgentEditSceneCore';

export const AgentEditScene: React.FC = () => {
  const codelistIds = useAllCodeListIds();

  const linkedValueSearchExtension = useLinkedValueSearchSceneExtensionCore();
  const extensions = useMemo(
    () => [linkedValueSearchExtension],
    [linkedValueSearchExtension],
  );

  const sceneExtensionContext =
    useSceneExtensionsContextProviderValue(extensions);

  return (
    <ThesaurusesGate thesauruses={AllThesaurusIds}>
      <CodeListsGate codeLists={codelistIds}>
        <SceneExtensionsContext.Provider value={sceneExtensionContext}>
          <AgentEditSceneCore />
        </SceneExtensionsContext.Provider>
      </CodeListsGate>
    </ThesaurusesGate>
  );
};
