import {useCallback} from 'react';
import {useLocalization} from 'localization';
import {
  DataLoadStatus,
  GrepExtendedConcept,
  useGetResourceListByIds,
} from 'api';
import {getExtendedGrepNode} from 'api/thesaurus';
import {useGetTokens} from 'services/auth';

export const useExtendedGrepNodeList = (
  codes: string[],
): DataLoadStatus<GrepExtendedConcept[]> => {
  const getTokens = useGetTokens();
  const {locale} = useLocalization();
  const getResource = useCallback(
    (codes: string[] | undefined) =>
      codes
        ? Promise.all(
            codes.map(code =>
              getExtendedGrepNode(code, locale ?? 'nb', getTokens),
            ),
          )
        : Promise.reject(),
    [getTokens, locale],
  );

  return useGetResourceListByIds<GrepExtendedConcept>(codes, getResource);
};
