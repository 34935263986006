import {assert} from 'assert-ts';
import {YearOrDateValue} from 'schemaDefinition/types';

export const valueAsYearOrDate = (
  value: unknown,
): YearOrDateValue | undefined => {
  if (
    !assert.soft(
      value === undefined || value === null || typeof value === 'object',
      'valueAsYearOrDate: value must be undefined, null, or a YearOrDateValue',
    )
  ) {
    return undefined;
  }

  if (!value) {
    return undefined;
  }

  const yearOrDate = value as YearOrDateValue;
  return yearOrDate;
};
