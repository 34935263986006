import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import assert from 'assert-ts';
import get from 'lodash/get';
import {UseValueById} from 'schema/form/types';
import {DataValue} from 'schemaDefinition/types';
import {AppState} from 'store/types';

const useValueSelector = (path: string | undefined, entityId: string) =>
  useCallback(
    (state: AppState): DataValue => {
      if (path === undefined) {
        return null;
      }
      const changeRequest = (
        state.metadataEdit.metadata?.changeRequests ?? []
      ).find(cr => cr.id.toString() === entityId);

      if (!changeRequest) {
        return null;
      }

      const [entity, ...localParts] = path.split('.');
      const localPath = localParts.join('.');

      switch (entity as 'work' | 'expression' | 'manifestation') {
        case 'work': {
          const work = state.metadata.data?.work;
          return work
            ? localPath === ''
              ? work
              : get(work, localPath) ?? null
            : null;
        }
        case 'expression': {
          const expressionId = changeRequest.expression.id;
          const expression = state.metadata.data?.expressions.find(
            e => e.id === expressionId,
          );
          return expression
            ? localPath === ''
              ? expression
              : get(expression, localPath) ?? null
            : null;
        }
        case 'manifestation': {
          const manifestationId = changeRequest.manifestation.id;
          const manifestation = state.metadata.data?.manifestations.find(
            m => m.id === manifestationId,
          );
          return manifestation
            ? localPath === ''
              ? manifestation
              : get(manifestation, localPath) ?? null
            : null;
        }
        default: {
          assert(false, 'useChangeRequestOriginalValue: unknown entity', {
            path,
          });
          return null;
        }
      }
    },
    [entityId, path],
  );

export const useChangeRequestOriginalValue: UseValueById = (
  localPath: string | undefined,
  entityId: string,
): DataValue | undefined => {
  const selector = useValueSelector(localPath, entityId);
  const value = useSelector(selector);

  return value;
};
