import {ChangeRequest, ExpressionV4, ManifestationV4, WorkV4} from 'api';

type Args = {
  toWork: WorkV4;
  toManifestation?: ManifestationV4;
  fromWork: WorkV4;
};

export const copyFromOtherWork = ({
  toWork,
  toManifestation,
  fromWork,
}: Args): ChangeRequest => {
  const {expression: fromExpression, manifestation: fromManifestation} =
    findMatchingManifestation(fromWork, toManifestation);
  // Dummy ids to have a "valid" structure when there are changes only on work level
  const dummyExpressionId = `DummyExpressionId-${Date.now()}`;
  const dummyManifestationId = `DummyManifestationId-${Date.now()}`;
  return {
    id: Date.now().toString(),
    taskType: 'METADATA_COPIED',
    storage: 'transient',
    level: fromManifestation ? 'manifestation' : 'work',
    work: {
      ...fromWork,
      id: toWork.id,
    },
    expression: {
      id: fromExpression?.id ?? dummyExpressionId,
      workId: toWork.id,
      ...fromExpression,
    },
    manifestation: {
      id: fromManifestation?.id ?? dummyManifestationId,
      expressionId: fromExpression?.id ?? dummyExpressionId,
      ...fromManifestation,
    },
  } as ChangeRequest;
};

const findMatchingManifestation = (
  _fromWork: WorkV4,
  _toManifestation?: Pick<ManifestationV4, 'id' | 'expressionId'> &
    Partial<ManifestationV4>,
): {
  expression?: ExpressionV4;
  manifestation?: ManifestationV4;
} => {
  return {};
};
