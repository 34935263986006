import React, {useCallback, useState} from 'react';
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {arrayMove, sortableKeyboardCoordinates} from '@dnd-kit/sortable';
import {SxProps} from '@mui/material';
import {Box} from '@mui/system';
import {useLocalization} from 'localization';
import {ColumnItems, ColumnName, DndDraggableItem} from '../types';
import {useDataTranslations} from '../hooks';
import {ListItem} from './ListItem';
import {DndContainer} from './columnPicker/DndContainer';

type Props = {
  columns: ColumnItems;
  setColumns: React.Dispatch<React.SetStateAction<ColumnItems>>;
};
export const ColumnPicker: React.FC<Props> = ({columns, setColumns}) => {
  const {t} = useLocalization();
  const {getLabel} = useDataTranslations();

  const [items, setItems] = useState<{
    pickableColumns: Array<DndDraggableItem>;
    pickedColumns: Array<DndDraggableItem>;
  }>({
    pickableColumns: columns.pickableColumns.map(c => ({
      label: getLabel(c),
      id: c,
    })),
    pickedColumns: columns.pickedColumns.map(c => ({
      label: getLabel(c),
      id: c,
    })),
  });

  const handleUpdate = useCallback(
    (
      pickableColumns: Array<DndDraggableItem>,
      pickedColumns: Array<DndDraggableItem>,
    ) => {
      setColumns(() => {
        return {
          pickableColumns: pickableColumns.map(p => p.id) as string[],
          pickedColumns: pickedColumns.map(p => p.id) as string[],
        };
      });
    },
    [setColumns],
  );

  const [activeId, setActiveId] = useState<string | null>();
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const wrapperStyle: SxProps = {
    display: 'flex',
    flexDirection: 'row',
  };

  const handleAdd = useCallback(
    (item: DndDraggableItem) => {
      setItems(prev => {
        const newState = {
          pickableColumns: prev['pickableColumns'].filter(
            c => c.id !== item.id,
          ),
          pickedColumns: [...prev['pickedColumns'], item],
        };

        handleUpdate(newState.pickableColumns, newState.pickedColumns);

        return newState;
      });
    },
    [handleUpdate],
  );

  const handleRemove = useCallback(
    (item: DndDraggableItem) => {
      setItems(prev => {
        const newState = {
          pickedColumns: prev['pickedColumns'].filter(c => c.id !== item.id),
          pickableColumns: [...prev['pickableColumns'], item],
        };

        handleUpdate(newState.pickableColumns, newState.pickedColumns);

        return newState;
      });
    },
    [handleUpdate],
  );

  const findContainer = (id: string | UniqueIdentifier | undefined) => {
    if (id && id in items) {
      return id as ColumnName;
    }

    return Object.keys(items).find(key =>
      items[key as ColumnName].find(k => k.id === (id as string)),
    ) as ColumnName | undefined;
  };

  const handleDragStart = (event: DragStartEvent) => {
    const {active} = event;
    const {id} = active;

    setActiveId(id as string);
  };

  const handleDragOver = (event: DragOverEvent) => {
    const {active, over} = event;
    const activeId = active.id;
    const overId = over?.id;

    // Find the containers
    const activeContainer = findContainer(activeId);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setItems(prev => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex(
        a => a.id === (activeId as string),
      );
      const overIndex = overItems.findIndex(o => o.id === (overId as string));

      let newIndex;
      if (overId && overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          (active.rect.current.translated?.top ?? 0) >
            over.rect.top + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter(item => item.id !== activeId),
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;
    const activeId = active.id;
    const overId = over?.id;

    const activeContainer = findContainer(activeId);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = items[activeContainer].findIndex(
      i => i.id === (activeId as string),
    );
    const overIndex = items[overContainer].findIndex(
      i => i.id === (overId as string),
    );

    if (activeIndex !== overIndex) {
      setItems(items => {
        const newState = {
          ...items,
          [overContainer]: arrayMove(
            items[overContainer],
            activeIndex,
            overIndex,
          ),
        };

        handleUpdate(newState.pickableColumns, newState.pickedColumns);

        return newState;
      });
    }

    setActiveId(null);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}>
      <Box sx={wrapperStyle}>
        <DndContainer
          id="pickableColumns"
          header={t('data.column.all')}
          items={items.pickableColumns}
          action={{
            type: 'add',
            action: handleAdd,
          }}
        />
        <DndContainer
          id="pickedColumns"
          header={t('data.column.picked')}
          items={items.pickedColumns}
          action={{
            type: 'remove',
            action: handleRemove,
          }}
        />
        <DragOverlay>
          {activeId ? <ListItem label={getLabel(activeId)} active /> : null}
        </DragOverlay>
      </Box>
    </DndContext>
  );
};
