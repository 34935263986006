import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import {DateTimeValue, Variant} from './types';

dayjs.extend(LocalizedFormat);

export const formatDate = (
  date?: DateTimeValue,
  variant: Variant = 'short',
  withTime?: boolean,
): string => {
  if (variant !== 'x-short')
    // short or long
    return dayjs(date).format(
      variant === 'short' ? 'L' : `LL${withTime ? 'L' : ''}`,
    );

  // else x-short
  const short = formatDate(date, 'short');
  const year = dayjs(date).year().toString();
  const yearRegex = `[^0-9]?${year}[^0-9]?`;
  // Removes year
  const xShort = short.replace(new RegExp(yearRegex), '');
  return xShort;
};
