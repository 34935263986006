import {LinkTargetConcreteEntityType} from 'types';
import {IconName} from 'components/icons/types';

const iconMap: {[key in LinkTargetConcreteEntityType]: IconName} = {
  work: 'PrintBook',
  manifestation: 'PrintBook',
  person: 'User',
  corporation: 'Building',
  publisher: 'Building',
  event: 'Event',
  series: 'Series',
  educationalSeries: 'Series',
  otherConnection: 'Series',
  publisherSeries: 'Series',
};

export const getEntityIcon = (
  entityType: LinkTargetConcreteEntityType,
): IconName => iconMap[entityType];
