export function removeById<T>(
  id: string,
  map: {[id: string]: T},
): {[id: string]: T} {
  if (!map[id]) {
    return map;
  }

  const newMap = {...map};
  delete newMap[id];
  return newMap;
}
