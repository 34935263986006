import React, {useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Auth} from '@aws-amplify/auth';
import {AppBar, Box, Link, Stack, Toolbar} from '@mui/material';
import {NoProps} from 'types';
import {Configuration} from 'configuration';
import {useLocalization} from 'localization';
import {useThemeColors} from 'theme';
import {useAuthState} from 'services/auth/hooks/useAuthState';
import {ActionButton} from 'components';
import {Logo} from 'components/svg';

type Props = NoProps;

export const Login: React.FC<Props> = () => {
  const themeColor = '#EAEADE';
  const bodySx = useMemo(
    () => ({
      background: themeColor,
      width: '100vw',
      height: '100vh',
    }),
    [themeColor],
  );
  const wrapperSx = useMemo(
    () => ({
      display: 'flex',
      justifyContent: 'center',
      mt: 10,
    }),
    [],
  );
  const themeColors = useThemeColors();
  const navigate = useNavigate();
  const {t} = useLocalization();

  const {loginFailed} = useAuthState();
  const [loading, setLoading] = useState<boolean>(false);

  const handleLogin = useCallback(() => {
    if (loading) {
      return;
    }
    setLoading(true);

    try {
      Auth.federatedSignIn({
        customProvider: Configuration.user.cognitoIdentityProvider,
      });
    } catch (e) {
      setLoading(false);
      console.warn('Auth.federatedSignIn failed', e);
      loginFailed('Auth.federatedSignIn failed');
    }
  }, [loading, loginFailed]);

  const AnonAppBar = useMemo(() => {
    return (
      <AppBar
        position="static"
        sx={{
          backgroundClip: 'padding-box',
        }}>
        <Toolbar
          disableGutters
          sx={{
            paddingX: 3,
            justifyContent: 'space-between',
            background: themeColors.background.topBar.main,
          }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link onClick={() => navigate('/')} className={'pointer'}>
              <Logo color={themeColors.background.topBar.light} />
            </Link>
          </Stack>
        </Toolbar>
      </AppBar>
    );
  }, [
    navigate,
    themeColors.background.topBar.light,
    themeColors.background.topBar.main,
  ]);

  return (
    <div style={bodySx}>
      {AnonAppBar}

      <Box sx={wrapperSx}>
        <ActionButton
          data-cy={'login-button'}
          title={loading ? t('page.login.redirecting') : t('page.login.log_in')}
          onClick={handleLogin}
        />
      </Box>
    </div>
  );
};
