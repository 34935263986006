import React from 'react';
import {NoProps} from '../../types';
import {Scene} from '../../components';
import {useLocalization} from '../../localization';

type Props = NoProps;

export const StatisticsScene: React.FC<Props> = () => {
  const {t} = useLocalization();
  return (
    <Scene browserTitle={t('appbar.statistics')}>
      <Scene.Header title={t('appbar.statistics')} />
    </Scene>
  );
};
