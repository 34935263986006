import cloneDeep from 'lodash/cloneDeep';
import {Data, DataFormValueType, Schema} from 'schemaDefinition/types';
import {getAllPartPaths, getVisiblePartPaths} from 'schema/form/functions';
import {cleanValueAtPath} from './cleanValueAtPath';

export const cleanInvisibleSchemaValues = <TData extends Data>(
  schema: Schema<DataFormValueType>,
  data: TData,
  relatedScope: Data,
): TData => {
  const allPartPaths = getAllPartPaths(schema);
  const visiblePartPaths = getVisiblePartPaths(schema, data, relatedScope);

  const invisiblePartPaths = allPartPaths.filter(
    a => !visiblePartPaths.includes(a),
  );

  const newData = cloneDeep(data);
  invisiblePartPaths.forEach(invisiblePartPath => {
    cleanValueAtPath(invisiblePartPath, newData);
  });

  return newData;
};
