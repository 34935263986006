import {
  ExpressionV4,
  ManifestationV4,
  WorkMetadata,
  WorkWithExpressions,
} from 'api';

/**
 * Maps nested {work {expresssions manifestations}}} to flat {work, expressions, manifestations}
 * @param param0
 * @returns
 */
export const mapWorkWithExpressionsToMetadata = ({
  expressions,
  ...work
}: WorkWithExpressions): WorkMetadata => {
  const {expressionsOnly, manifestations} = (expressions ?? []).reduce(
    (acc, expression) => {
      const {manifestations, ...expressionWithoutManifestations} = expression;
      acc.expressionsOnly.push(expressionWithoutManifestations);
      acc.manifestations.push(...(manifestations ?? []));
      return acc;
    },
    {
      expressionsOnly: [] as ExpressionV4[],
      manifestations: [] as ManifestationV4[],
    },
  );
  return {
    work,
    expressions: expressionsOnly,
    manifestations,
  };
};
