import {
  ChangeRequest,
  ExpressionV4,
  ManifestationV4,
  WorkMetadata,
  WorkV4,
} from 'api/types';
import {LoadAction} from 'services/utils/redux/actions';

export enum MetadataActionType {
  LOAD_WORK = 'METADATA_LOAD_WORK',
  LOAD_CHANGE_REQUESTS = 'METADATA_LOAD_CHANGE_REQUESTS',
}

export type MetadataAction_Reset = {
  type: 'METADATA_RESET';
};

export type MetadataAction_Load_Work = {
  type: MetadataActionType.LOAD_WORK;
} & LoadAction<WorkMetadata>;

export type MetadataAction_Load_ChangeRequests = {
  type: MetadataActionType.LOAD_CHANGE_REQUESTS;
} & LoadAction<ChangeRequest[]>;

export type MetadataAction_WorkSaved = {
  type: 'METADATA_WORK_SAVED';
  payload: WorkV4;
};

export type MetadataAction_ExpressionSaved = {
  type: 'METADATA_EXPRESSION_SAVED';
  payload: ExpressionV4;
};

export type MetadataAction_NewExpressionWithManifestationSaved = {
  type: 'METADATA_NEW_EXPRESSION_WITH_MANIFESTATION_SAVED';
  payload: {
    localExpressionId: string;
    expression: ExpressionV4;
    manifestation: ManifestationV4;
  };
};

export type MetadataAction_ManifestationSaved = {
  type: 'METADATA_MANIFESTATION_SAVED';
  payload: {
    manifestation: ManifestationV4;
    work: WorkV4;
  };
};

export type MetadataAction_ChangeRequestSaved = {
  type: 'METADATA_CHANGEREQUEST_SAVED';
  payload: ChangeRequest;
};

export type MetadataAction =
  | MetadataAction_Reset
  | MetadataAction_Load_Work
  | MetadataAction_Load_ChangeRequests
  | MetadataAction_WorkSaved
  | MetadataAction_ExpressionSaved
  | MetadataAction_NewExpressionWithManifestationSaved
  | MetadataAction_ManifestationSaved
  | MetadataAction_ChangeRequestSaved;
