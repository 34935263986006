import {useEffect, useState} from 'react';
import assert from 'assert-ts';
import {Concept} from 'types';
import {Schema, Valid} from 'schemaDefinition/types';
import {useConditionalWorkEdit} from 'services/data';
import {validate} from 'schema/form/functions/validators/validate';
import {useSchemaRefCodelists} from 'schemas';

export const useWorkValidation = (
  showErrors: boolean,
  schema: Schema,
): Valid | undefined => {
  // Optimization: Only select work if we are showing errors
  const work = useConditionalWorkEdit(showErrors);
  // TODO: Make look up function instead of hook, call from within useEffect
  const codelistMap = useSchemaRefCodelists(Concept.work);

  const [valid, setValid] = useState<Valid | undefined>(undefined);

  useEffect(() => {
    if (showErrors) {
      const validation = validate(
        assert(work, 'useWorkValidation: no work'),
        undefined,
        schema,
        codelistMap,
      );

      setValid(validation.valid);
    } else {
      setValid(undefined);
    }
  }, [work, schema, codelistMap, showErrors]);

  return valid;
};
