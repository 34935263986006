import React, {useCallback, useMemo} from 'react';
import {assert} from 'assert-ts';
import uniqueId from 'lodash/uniqueId';
import {OptionsObject, SnackbarKey, useSnackbar} from 'notistack';
import {HttpError} from 'api/http/types';
import {ColorPalette, px2rem} from 'theme';
import {IconButton} from 'components/button';
import {SnackBodyWithMessageAndNavLinkProps} from '../types';
import {
  SnackBodyWithErrorDetails,
  SnackBodyWithMessageAndNavLink,
} from '../components';

export type SnackVariant = 'info' | 'success' | 'warning' | 'error';

const baseSnackStyle: React.CSSProperties = {
  borderRadius: '999px',
  boxShadow: 'none',
  borderWidth: '1px',
  borderStyle: 'solid',
  paddingTop: px2rem(3),
  paddingBottom: px2rem(3),
};

const Snacks: {
  variant: SnackVariant;
  persist: boolean;
  style?: React.CSSProperties;
}[] = [
  {
    variant: 'info',
    persist: false,
    style: {
      ...baseSnackStyle,
      color: ColorPalette.burgundy,
      backgroundColor: ColorPalette.info20,
      borderColor: ColorPalette.info,
    },
  },
  {
    variant: 'success',
    persist: false,
    style: {
      ...baseSnackStyle,
      color: ColorPalette.burgundy,
      backgroundColor: ColorPalette.green,
      borderColor: ColorPalette.success,
    },
  },
  {
    variant: 'warning',
    persist: true,
    style: {
      ...baseSnackStyle,
      color: ColorPalette.burgundy,
      backgroundColor: ColorPalette.warning20,
      borderColor: ColorPalette.warning,
    },
  },
  {
    variant: 'error',
    persist: true,
    style: {
      ...baseSnackStyle,
      color: ColorPalette.burgundy,
      backgroundColor: ColorPalette.error20,
      borderColor: ColorPalette.error,
    },
  },
];

type SnackOptionsMap = {[variant in SnackVariant]: OptionsObject};

const snackOptionsMap: SnackOptionsMap = Snacks.reduce<SnackOptionsMap>(
  (acc, v) => {
    acc[v.variant] = {...v};
    return acc;
  },
  {} as SnackOptionsMap,
);

type SnackMessage =
  | string
  | Omit<SnackBodyWithMessageAndNavLinkProps, 'closeSnack'>;

const isSimpleMessageType = (message: SnackMessage): message is string =>
  typeof message === 'string';

export const useSnacks = () => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const closeButton = useCallback(
    (key: SnackbarKey) => (
      <IconButton
        size="small"
        icon={'CloseSmall'}
        onClick={async () => closeSnackbar(key)}
      />
    ),
    [closeSnackbar],
  );

  const showSnack = useCallback(
    (variant: SnackVariant, content: SnackMessage) => {
      const key = uniqueId();
      const {message, options} = isSimpleMessageType(content)
        ? // simple string message
          {
            message: content,
            options: snackOptionsMap[variant],
          }
        : // content with message and link
          {
            message: (
              <SnackBodyWithMessageAndNavLink
                {...content}
                closeSnack={() => closeSnackbar(key)}
              />
            ),
            options: {
              ...snackOptionsMap[variant],
              // override persist to allow user to click on the link
              persist: true,
            },
          };
      enqueueSnackbar(message, {
        key,
        ...options,
        action: (key: SnackbarKey) => closeButton(key),
      });
    },
    [closeButton, closeSnackbar, enqueueSnackbar],
  );

  const showSnackWithError = useCallback(
    (content: SnackMessage, error: HttpError) => {
      const key = uniqueId();
      assert(isSimpleMessageType(content), 'content must be a simple message');
      const snackBody = (
        <SnackBodyWithErrorDetails
          message={content}
          error={error}
          closeSnack={() => closeSnackbar(key)}
        />
      );
      const options = {...snackOptionsMap['error'], persist: true};
      enqueueSnackbar(snackBody, {
        key,
        ...options,
        action: (key: SnackbarKey) => closeButton(key),
      });
    },
    [closeButton, closeSnackbar, enqueueSnackbar],
  );

  return useMemo(
    () => ({
      infoSnack: (message: SnackMessage) => showSnack('info', message),
      successSnack: (message: SnackMessage) => showSnack('success', message),
      warningSnack: (message: SnackMessage) => showSnack('warning', message),
      errorSnack: (message: SnackMessage, error?: HttpError) =>
        error
          ? showSnackWithError(message, error)
          : showSnack('error', message),
      snack: (message: SnackMessage, variant: SnackVariant) =>
        showSnack(variant, message),
    }),
    [showSnack, showSnackWithError],
  );
};
