import React from 'react';
import {GrepDetailsCodeValue as GrepDetailsCodeValueType} from 'api/types';
import {useExtendedGrepNodeList} from '../hooks';
import {ThesaurusNodeDetailsRelated} from './ThesaurusNodeDetailsRelated';

type Props = {
  itemKey: string;
  value: GrepDetailsCodeValueType;
};

export const GrepDetailsCodeValue: React.FC<Props> = ({itemKey, value}) => {
  const nodes = useExtendedGrepNodeList(value.value);
  return nodes.status === 'Loaded' ? (
    <ThesaurusNodeDetailsRelated label={itemKey} related={nodes.data} />
  ) : null;
};
