import orderBy from 'lodash/orderBy';
import {Thesaurus, ThesaurusNodeType, ThesaurusValue} from 'api/types';
import {TypeNodesWithAncestors} from '../types';
import {getNodesAndAncestors} from './getNodesAndAncestors';
import {groupPrNodeType} from './groupPrNodeType';

export const groupAndOrderByType = <TNode extends string = string>(
  selectableNodeTypes: string[],
  thesaurus: Thesaurus,
  thesaurusValue: ThesaurusValue | undefined,
): Array<TypeNodesWithAncestors<TNode>> => {
  const nodesWithAncestors = thesaurusValue
    ? getNodesAndAncestors(thesaurusValue, thesaurus)
    : [];
  const grouped = groupPrNodeType<TNode>(nodesWithAncestors);
  return orderBy(grouped, g =>
    (selectableNodeTypes ?? []).indexOf(g.nodeType as ThesaurusNodeType),
  );
};
