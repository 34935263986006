import {assert} from 'assert-ts';
import {CodeListId, CodeListMap} from 'api/types';
import {ExpressionImportProps} from 'services/data/metadata/types';
import {getRestrictedExpressionCodelist} from './getRestrictedExpressionCodelist';

export const getRestrictedExpressionCodelists = (
  importProps: ExpressionImportProps,
  outerCodelists: CodeListMap,
  allowAnyExpressionFormat?: boolean,
): CodeListMap => {
  if (importProps === undefined) {
    return outerCodelists;
  }

  const restrictedCodelists = {...outerCodelists};

  Object.keys(restrictedCodelists).forEach(key => {
    const id = key as CodeListId;
    const outer = assert(outerCodelists[id]);
    restrictedCodelists[id] =
      key !== 'EXPRESSION_FORMAT' && !allowAnyExpressionFormat
        ? getRestrictedExpressionCodelist(importProps, outer)
        : outer;
  });

  return restrictedCodelists;
};
