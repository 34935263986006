import {useEffect, useState} from 'react';
import {Data, Schema, Valid} from 'schemaDefinition/types';
import {CodeListMap} from 'api';
import {validate} from 'schema/form/functions/validators/validate';
import {useAssertExpressionEdit} from './useAssertExpressionEdit';

export const useExpressionValidation = (
  expressionId: string,
  relatedScope: Data,
  showErrors: boolean,
  schema: Schema,
  codelistMap: CodeListMap,
): Valid | undefined => {
  const expression = useAssertExpressionEdit(expressionId);
  const [valid, setValid] = useState<Valid | undefined>(undefined);

  useEffect(() => {
    if (showErrors) {
      const validation = validate(
        expression,
        relatedScope,
        schema,
        codelistMap,
      );

      setValid(validation.valid);
    } else {
      setValid(undefined);
    }
  }, [expression, schema, codelistMap, showErrors, relatedScope]);

  return valid;
};
