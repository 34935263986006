import {ColumnConfig} from 'schema/table/types';

export const workDefaultColumns: ColumnConfig[] = [
  {
    name: 'preferredTitle',
    visible: true,
    width: 150,
  },
  {
    name: 'agents',
    visible: true,
    width: 150,
  },
  {
    name: 'intellectualLevel',
    visible: true,
  },
  {
    name: 'literatureType',
    visible: true,
  },
  {
    name: 'languages',
    visible: true,
  },
  {
    name: 'subjects',
    visible: true,
  },
  {
    name: 'themes',
    visible: false,
  },
  {
    name: 'themaCodes',
    visible: true,
    width: 100,
  },
  {
    name: 'themaLabels',
    width: 150,
  },
  {
    name: 'links',
  },
  {
    name: 'dewey',
  },
];
