import {ColumnConfig} from 'schema/table/types';

export const overviewTaskDefaultColumns: ColumnConfig[] = [
  {
    name: 'lockedBy',
    width: 80,
    visible: true,
  },
  {
    name: 'contentReceivedDate',
    width: 150,
    visible: true,
  },
  {
    name: 'registered',
    width: 150,
  },
  {
    name: 'productForm',
    visible: true,
    width: 50,
  },
  {
    name: 'mainTitle',
    visible: true,
    width: 250,
  },
  {
    name: 'isbn',
    width: 150,
    visible: true,
  },
  {
    name: 'ean',
    width: 150,
  },
  {
    name: 'mainAgent',
    width: 150,
  },
  {
    name: 'productOwner',
    visible: true,
    width: 200,
  },
  {
    name: 'productOwnerName',
    visible: false,
  },
  {
    name: 'productOwnerCode',
    visible: false,
  },
  {
    name: 'publisherSalesDate',
    width: 150,
  },
  {
    name: 'inSaleDate',
    width: 150,
  },
  {
    name: 'availabilityStatus',
    width: 150,
  },
  {
    name: 'media',
    width: 150,
    visible: true,
  },
  {
    name: 'taskTypes',
    visible: true,
    width: 100,
  },
];
