import React from 'react';
import {IconButton} from '@mui/material';
import {IconName} from 'components/icons/types';
import {ColorPalette} from 'theme';
import {Icon} from 'components/icons';
import {Layout} from 'components/layout';
import {Spacer} from 'components/spacer';
import {Label} from 'components/text';

type Props = {
  pctValue: number;
  size: string;
  options: IconName[];
  onSelect: (option: IconName, index: number) => void;
};

const layoutSx = {
  background: 'white',
  borderRadius: '999px',
  boxSizing: 'content-box',
  borderWidth: '2px',
  borderColor: ColorPalette.brown,
  borderStyle: 'solid',
} as const;

export const StatusSelector: React.FC<Props> = ({
  pctValue,
  size,
  options,
  onSelect,
}) => {
  return (
    <Layout horizontal adjustCenter sx={layoutSx}>
      {(pctValue ?? 0) > 0 ? (
        <>
          <Spacer size={1.5} />
          <Label kind="secondary">{`${Math.round(pctValue)}%`}</Label>
          <Spacer size={0.5} />
        </>
      ) : null}
      {options.map((opt, idx) => (
        <IconButton
          key={opt}
          // autoFocus={idx === options.length - 1}
          sx={{height: size, width: size}}
          color="secondary"
          onClick={() => onSelect(opt, idx)}>
          <Icon icon={opt} />
        </IconButton>
      ))}
    </Layout>
  );
};
