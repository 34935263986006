export type ShortcutCommand =
  | 'search'
  | 'contextSearch'
  | 'showNew'
  | 'showPre'
  | 'showControl'
  | 'showChanged'
  | 'goToOverview'
  | 'undo'
  | 'save'
  | 'goToNext'
  | 'expandAllManifestations'
  | 'collapseAllManifestations'
  | 'expandAllChangeRequests'
  | 'collapseAllChangesRequests'
  | 'toggleWork';

export type ShortcutCommandGroup =
  | 'global'
  | 'cataloguing'
  | 'overview'
  | 'uncategorized';

export type ShortcutCommandMap = {
  [command in ShortcutCommand]?: string;
};
export const DefaultShortcuts: ShortcutCommandMap = {
  showNew: 'alt+1',
  showPre: 'alt+2',
  showControl: 'alt+3',
  showChanged: 'alt+4',

  goToOverview: 'alt+u',

  save: 'alt+d',
  undo: 'alt+z',
  goToNext: 'alt+v',
  contextSearch: 'alt+s',
  toggleWork: 'alt+h',

  expandAllManifestations: 'alt+x',
  collapseAllManifestations: 'alt+shift+x',
  expandAllChangeRequests: 'alt+c',
  collapseAllChangesRequests: 'alt+shift+c',
};
