import React from 'react';
import {SvgIcon} from '@mui/material';
import {useStatusColor} from 'theme';
import {FilledIconProps} from './types';

export const CheckRoundBackgroundIcon: React.FC<FilledIconProps> = ({
  fillColor,
  fillColorCode,
  ...props
}) => {
  const themeFillColor = useStatusColor(fillColor) ?? fillColorCode ?? 'none';

  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="8" fill={themeFillColor} />
      <path
        d="M11.3398 15.5699L7.77979 11.8799L9.21979 10.4899L11.3498 12.6899L14.7798 9.16992L16.2198 10.5699L11.3398 15.5699Z"
        fill={'currentColor'}
      />
    </SvgIcon>
  );
};
