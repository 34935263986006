import {Locale} from '../../types';
import en from '../../locales/en.json';
import no from '../../locales/no.json';
import {CoreTranslate} from './coreTypes';

const masterLocaleStrings = en;

type LocaleContent = typeof masterLocaleStrings;

export const Locales: {[loc in Locale]: LocaleContent} = {
  en,
  nb: no as unknown as LocaleContent,
  nn: no as unknown as LocaleContent,
} as const;

export type Translate<TResult = string> = CoreTranslate<
  typeof masterLocaleStrings,
  TResult
>;

export type LocaleMap = {
  locale: Locale;
  dayjsLocale: ILocale;
  mask: string;
  maskFormat: string;
};
