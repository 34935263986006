import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const EditCircleIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <svg x="4" y="4" width="16px" height="16px" viewBox="0 0 16 16">
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd">
          <g id="Group-8" fill={'currentColor'} fillRule="nonzero">
            <g id="Group" transform="translate(4.500000, 4.500000)">
              <path
                d="M6.5895632,0.379052579 C6.33601776,0.135816091 5.99829173,0 5.64695019,0 C5.29560864,0 4.95788262,0.135816091 4.70433717,0.379052579 L0,5.08340197 L0,6.968628 L1.88522603,6.968628 L6.5895632,2.26427773 C6.71624865,2.14215262 6.81705587,1.99573776 6.88587535,1.83377852 C6.95473847,1.67181927 6.99021737,1.49764533 6.99021737,1.32166472 C6.99021737,1.14568411 6.95473847,0.971510169 6.88587535,0.809555289 C6.81705587,0.647596044 6.71624865,0.501176823 6.5895632,0.379052579 Z"
                id="Shape"></path>
            </g>
            <g id="Group">
              <path
                d="M8,0 C6.41774994,0 4.87102994,0.469192 3.55543994,1.34824 C2.23984994,2.22729 1.21446994,3.47672 0.608966941,4.93853 C0.00346619097,6.40034 -0.154960059,8.00887 0.153720941,9.56072 C0.462402941,11.1126 1.22432994,12.538 2.34314994,13.6569 C3.46196994,14.7757 4.88742994,15.5376 6.43927994,15.8463 C7.99112994,16.155 9.59965994,15.9965 11.0614999,15.391 C12.5232999,14.7855 13.7726999,13.7602 14.6517999,12.4446 C15.5307999,11.129 16,9.58225 16,8 C16,5.87827 15.1570999,3.84344 13.6568999,2.34315 C12.1565999,0.842855 10.1216999,0 8,0 Z M8.00000001,13.9999997 C6.81331994,13.9999997 5.65327994,13.6481 4.66657994,12.9888 C3.67988994,12.3295 2.91084994,11.3925 2.45672994,10.2961 C2.00259994,9.19974 1.88377994,7.99334 2.11528994,6.82946 C2.34679994,5.66557 2.91824994,4.59647 3.75735994,3.75736 C4.59647994,2.91824 5.66557994,2.3468 6.82945994,2.11529 C7.99334994,1.88378 9.19974994,2.0026 10.2960999,2.45672 C11.3924999,2.91085 12.3294999,3.67988 12.9887999,4.66658 C13.6480999,5.65327 14,6.81331 14,7.9999997 C14,9.5913 13.3678999,11.1174 12.2425999,12.2426 C11.1173999,13.3679 9.59129994,13.9999997 8.00000001,13.9999997 Z"
                id="Shape"></path>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};
