import {AgentCorporation, AgentEvent, AgentPerson} from 'api/types';
import {Part, SchemaValueType} from 'schemaDefinition/types';

export const agentBAREParts: Part<
  SchemaValueType,
  keyof Pick<AgentPerson | AgentCorporation | AgentEvent, 'isni' | 'nationalId'>
>[] = [
  {
    type: 'text',
    name: 'isni',
  },
  {
    type: 'text',
    name: 'nationalId',
    readonly: true,
    prefix: 'icon:Verified',
    prefixOnEmpty: 'icon:None',
  },
];
