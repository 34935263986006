import {useCallback, useMemo, useState} from 'react';
import {assert} from 'assert-ts';
import {LocalizationContextType} from 'localization/context/types';
import {useLocalization} from 'localization';
import {noOp} from 'services/utils/functions/noOp';
import {AlertDialog, AlertDialogProps} from '../AlertDialog';

export type AlertDialogStringProps = Omit<
  AlertDialogProps,
  'isOpen' | 'onCancel' | 'onConfirm'
>;

const getDialogStringProps = (
  propsOrKey: AlertDialogStringProps | string,
  {tLoose, tryT}: LocalizationContextType,
): AlertDialogStringProps => {
  return typeof propsOrKey === 'string'
    ? {
        title: tLoose(propsOrKey + '.title'),
        body: tryT(propsOrKey + '.body'),
        cancelTitle: tryT(propsOrKey + '.cancelTitle'),
        okTitle: tryT(propsOrKey + '.okTitle'),
      }
    : propsOrKey;
};

const dummyStringProps: AlertDialogStringProps = {
  title: 'dummy',
};

const createDialog = (
  isOpen: boolean,
  props: AlertDialogStringProps,
  onCancel: () => void = noOp,
  onConfirm = () => Promise.resolve(),
) => (
  <AlertDialog
    isOpen={isOpen}
    onCancel={onCancel}
    onConfirm={onConfirm}
    {...props}
  />
);

/**
 * @deprecated use useAlertDialogAsPromiseV3 or useStaticAlertDialogAsPromise instead
 */
export const useAlertDialogAsPromise = (
  staticPropsOrRootKey?: AlertDialogStringProps | string,
): {
  pleaseConfirm: (
    dynamicPropsOrRootKey?: AlertDialogStringProps | string,
  ) => Promise<boolean>;
  AlertDialog: React.FC;
} => {
  const localization = useLocalization();
  const [dialogInstance, setDialogInstance] = useState<React.ReactElement>(
    createDialog(
      false,
      staticPropsOrRootKey
        ? getDialogStringProps(staticPropsOrRootKey, localization)
        : dummyStringProps,
    ),
  );

  const pleaseConfirm = useCallback(
    (dynamicPropsOrRootKey?: AlertDialogStringProps | string) => {
      const propsOrRootKey = assert(
        dynamicPropsOrRootKey || staticPropsOrRootKey,
        'useAlertDialogAsPromise: propsOrRootKey expected',
      );
      const props = getDialogStringProps(propsOrRootKey, localization);

      return new Promise<boolean>(resolve => {
        const cancel = () => {
          setDialogInstance(() => createDialog(false, props));
          resolve(false);
        };

        const confirm = () => {
          setDialogInstance(() => createDialog(false, props));
          resolve(true);
          return Promise.resolve();
        };

        setDialogInstance(() => createDialog(true, props, cancel, confirm));
      });
    },
    [localization, staticPropsOrRootKey],
  );

  return useMemo(
    () => ({
      pleaseConfirm,
      AlertDialog: () => dialogInstance,
    }),
    [dialogInstance, pleaseConfirm],
  );
};
