import * as Sentry from '@sentry/react';
import {Replay} from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {Configuration} from 'configuration';
import {Indexer} from '../types';
import {Log, LogError} from './types';
import {filterSensitiveData} from './filterSensitiveData';

export const initLogging = () => {
  if (Configuration.sentry.enabled) {
    Sentry.init({
      dsn: Configuration.sentry.dsn,
      environment: Configuration.env,
      integrations: [
        new Integrations.BrowserTracing({
          beforeNavigate: context => {
            return {
              ...context,
              // Can even look at context tags or other data to adjust
              // transaction name
              name: window.location.pathname, //  getTransactionName(window.location),
            };
          },
        }),
        new Replay({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          networkDetailAllowUrls: [
            window.location.origin,
            Configuration.api.baseUri,
          ],
          mutationLimit: 2000, // Default 10000
        }),
      ],
      tracesSampleRate: Configuration.env === 'prod' ? 0.2 : 1.0,
      replaysSessionSampleRate: Configuration.env === 'prod' ? 0.1 : 1.0,
      replaysOnErrorSampleRate: 1.0,

      beforeSend(event) {
        // Modify the event here
        event.message = event.message ?? event.exception?.values?.[0]?.value;
        return event;
      },
    });
  }
};

export function setUser(user?: Sentry.User) {
  if (Configuration.sentry.enabled) {
    Sentry.setUser(user ?? null);
  }
}

export function clearUser() {
  setUser();
}

type UIAction = 'click';

type BreadcrumbType = 'debug' | 'error' | 'query' | 'ui' | `ui.${UIAction}`;

export function addBreadcrumb(breadcrumb: {
  type?: BreadcrumbType;
  message: string;
  data?: Indexer;
}) {
  if (Configuration.sentry.enabled) {
    const {type, data, ...rest} = breadcrumb;
    const dataFiltered = filterSensitiveData(data);
    const baseType = type?.split('.')[0];
    Sentry.addBreadcrumb({
      type: baseType,
      category: type,
      data: dataFiltered,
      ...rest,
    });
  }
}

export const logError: LogError = (error: Error | string, data?: Indexer) => {
  const dataFiltered = filterSensitiveData(data);
  if (Configuration.sentry.enabled) {
    Sentry.withScope(scope => {
      dataFiltered && scope.setExtras(dataFiltered);

      if (typeof error === 'string') {
        Sentry.captureMessage(error, 'error');
      } else {
        Sentry.captureException(error);
      }
    });
  } else {
    console.error(error, data);
  }
};

export const logWarning: Log = (message: string, data?: Indexer) => {
  const dataFiltered = filterSensitiveData(data);

  if (Configuration.sentry.enabled) {
    Sentry.withScope(scope => {
      dataFiltered && scope.setExtras(dataFiltered);

      Sentry.captureMessage(message, 'warning');
    });
  } else {
    console.warn(message, data);
  }
};
