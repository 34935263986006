import React from 'react';
import {GrepDetailsTextValue as GrepDetailsTextValueType} from 'api/types';
import {FlexBox, Text, TextArea} from 'components';
import {NodeLabel} from './NodeLabel';

type Props = {
  itemKey: string;
  value: GrepDetailsTextValueType;
};

export const GrepDetailsTextValue: React.FC<Props> = ({itemKey, value}) => {
  return (
    <FlexBox>
      <NodeLabel label={itemKey} />
      {value.type === 'text' ? (
        <Text variant="body2">{value.value}</Text>
      ) : (
        <TextArea value={value.value} />
      )}
    </FlexBox>
  );
};
