import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {assert} from 'assert-ts';
import {useLocalization} from 'localization';
import {px2rem} from 'theme';
import {getWorkByEan, useGetResource} from 'api';
import {useGetTokens} from 'services/auth';
import {
  ActionButton,
  ActivityIndicator,
  Card,
  Layout,
  Spacer,
  Text,
} from 'components';

export const EanToWorkRedirect = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {t} = useLocalization();
  const getTokens = useGetTokens();

  const ean = assert(
    params['ean'],
    'EanToWorkRedirect: ean parameter expected',
  );

  const {status, data} = useGetResource(() => getWorkByEan(ean, getTokens));

  if (status === 'Loaded') {
    const workId = data.work.id;
    navigate(`/metadata/${workId}?ean=${ean}`, {
      replace: true,
    });
  }

  if (status === 'Failed') {
    return (
      <Layout adjustCenter paddingTop="20vh">
        <Layout sx={{maxWidth: px2rem(400)}}>
          <Card color="secondary">
            <Layout p={2} adjustCenter>
              <Text variant="h3" kind="error">
                {t('error.failedtoLoadMetadata')}
              </Text>
              <Spacer size={4} />
              <Layout sx={{minWidth: px2rem(100)}}>
                <ActionButton
                  title={t('error.retry')}
                  onClick={() =>
                    navigate(`${location.pathname}${location.search}`)
                  }
                />
              </Layout>
            </Layout>
          </Card>
        </Layout>
      </Layout>
    );
  }

  return (
    <Layout adjustCenter paddingTop="20vh">
      <ActivityIndicator />
    </Layout>
  );
};
