import {DataValue, LinkedAgentMultiRole} from '../types';
import {isLinkedValueLink} from './isLinkedValueLink';

export const isLinkedAgentMultiRole = (
  value: DataValue | undefined,
): value is LinkedAgentMultiRole => {
  return (
    isLinkedValueLink((value as LinkedAgentMultiRole).link) &&
    Array.isArray((value as LinkedAgentMultiRole).roles)
  );
};
