import {ManifestationDesignator} from 'types';
import {InitialManifestationParam} from '../types';

export const getManifestationDesignatorParam = (
  param: InitialManifestationParam,
): ManifestationDesignator | undefined => {
  if (!param) return undefined;

  return param.manifestationId
    ? {type: 'id', id: param.manifestationId}
    : param.isbn
    ? {type: 'isbn', id: param.isbn}
    : param.ean
    ? {type: 'ean', id: param.ean}
    : undefined;
};
