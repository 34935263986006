import {createContext, useContext} from 'react';

type MockContextType = boolean | undefined;

/**
 * Context
 */
export const MockContext = createContext<MockContextType>(false);

export const useMockContext = (): MockContextType => !!useContext(MockContext);
