import {assert} from 'assert-ts';
import {HttpError} from 'api/http/types';
import {ChangeRequest, DataLoadStatus} from 'api/types';
import {loadReducer} from 'services/utils';
import {Metadata, MetadataState} from '../types';
import {mergeLoadStatus} from '../functions';
import {MetadataAction, MetadataActionType} from '../metadataActionTypes';

export const MetadataDefaultState: MetadataState = {
  status: 'NotLoaded',
  parts: {
    work: {
      status: 'NotLoaded',
    },
    changeRequests: {status: 'NotLoaded'},
  },
};

/**
 * Holds metadata state for one work with expressions, manifestations and change requests as loaded/stored from/to backend
 * @param state
 * @param action
 * @returns
 */
export const metadataReducer = (
  state: MetadataState = MetadataDefaultState,
  action: MetadataAction,
): MetadataState => {
  switch (action.type) {
    case 'METADATA_RESET': {
      return MetadataDefaultState;
    }
    case MetadataActionType.LOAD_WORK: {
      const parts = {
        ...state.parts,
        work: loadReducer(state.parts.work, action),
      };

      return {
        ...aggregateParts(parts),
        parts,
      };
    }
    case MetadataActionType.LOAD_CHANGE_REQUESTS: {
      const parts = {
        ...state.parts,
        changeRequests: loadReducer(state.parts.changeRequests, action),
      };
      return {
        ...aggregateParts(parts),
        parts,
      };
    }
    case 'METADATA_WORK_SAVED': {
      if (!assertSoftLoaded(state.parts.work, action)) {
        return state;
      }

      const parts = {
        ...state.parts,
        work: {
          ...state.parts.work,
          data: {
            ...assert(state.parts.work.data),
            work: action.payload,
          },
        },
      };

      return {
        ...aggregateParts(parts),
        parts,
      };
    }
    case 'METADATA_EXPRESSION_SAVED': {
      if (!assertSoftLoaded(state.parts.work, action)) {
        return state;
      }

      const metadata = assert(state.parts.work.data);
      const updated = action.payload;

      const parts = {
        ...state.parts,
        work: {
          ...state.parts.work,
          data: {
            ...metadata,
            expressions: metadata.expressions.map(e =>
              e.id === updated.id ? updated : e,
            ),
          },
        },
      };
      return {
        ...aggregateParts(parts),
        parts,
      };
    }
    case 'METADATA_NEW_EXPRESSION_WITH_MANIFESTATION_SAVED': {
      if (!assertSoftLoaded(state.parts.work, action)) {
        return state;
      }

      const metadata = assert(state.parts.work.data);
      const {expression: newExpression, manifestation: updatedManifestation} =
        action.payload;

      const parts = {
        ...state.parts,
        work: {
          ...state.parts.work,
          data: {
            ...metadata,
            expressions: [...metadata.expressions, newExpression],
            manifestations: metadata.manifestations.map(m =>
              m.id === updatedManifestation.id ? updatedManifestation : m,
            ),
          },
        },
      };
      return {
        ...aggregateParts(parts),
        parts,
      };
    }
    case 'METADATA_MANIFESTATION_SAVED': {
      if (!assertSoftLoaded(state.parts.work, action)) {
        return state;
      }

      const metadata = assert(state.parts.work.data);
      const updatedWork = action.payload.work;
      const updatedManifestation = action.payload.manifestation;

      const parts = {
        ...state.parts,
        work: {
          ...state.parts.work,
          data: {
            ...metadata,
            work: {
              ...metadata.work,
              modified: updatedWork.modified, // Updates work timestamp
            },
            manifestations: metadata.manifestations.map(m =>
              m.id === updatedManifestation.id ? updatedManifestation : m,
            ),
          },
        },
      };
      return {
        ...aggregateParts(parts),
        parts,
      };
    }
    case 'METADATA_CHANGEREQUEST_SAVED': {
      if (!assertSoftLoaded(state.parts.changeRequests, action)) {
        return state;
      }

      const changeRequests = assert(state.parts.changeRequests.data);
      const updated = action.payload;

      const parts = {
        ...state.parts,
        changeRequests: {
          ...state.parts.changeRequests,
          data: changeRequests.map(cr => (cr.id === updated.id ? updated : cr)),
        },
      };

      return {
        ...aggregateParts(parts),
        parts,
      };
    }
    default: {
      return state;
    }
  }
};

const assertSoftLoaded = <TPart>(
  part: DataLoadStatus<TPart>,
  action: MetadataAction,
): boolean => {
  if (part.status === 'Loaded') {
    return true;
  }

  return assert.soft(
    false,
    `metadataReducer:${action.type}: status loaded expected`,
    {status: part.status},
  );
};
const aggregateParts = ({
  work,
  changeRequests,
}: MetadataState['parts']): DataLoadStatus<Metadata> => {
  const status = mergeLoadStatus(work.status, changeRequests.status);

  return status === 'Loaded'
    ? {
        status,
        data: {
          ...assert(work.data),
          changeRequests: (changeRequests.data ?? []) as ChangeRequest[],
        },
      }
    : status === 'Failed'
    ? {
        status,
        error:
          // TODO: Better general error message
          work.error ?? changeRequests.error ?? new HttpError(400, '', ''),
      }
    : {status};
};
