import {Args, LocaleStrings} from './coreTypes';

const pluralSuffixes: {[count: number]: string | undefined} = {
  0: 'zero',
  1: 'one',
};

const findKey = (
  localeStrings: LocaleStrings,
  key: string,
  caseInsensitive?: boolean,
) => {
  if (caseInsensitive) {
    return (
      Object.keys(localeStrings).find(
        k => k.toLowerCase() === key.toLowerCase(),
      ) ?? key
    );
  }

  return key;
};
export const lookupRawLocaleString = (
  key: string,
  args: (Args & {count?: number}) | undefined,
  localeStrings: LocaleStrings,
  lang?: string,
  logWarning?: (msg: string, args?: Args) => void,
  caseInsensitive?: boolean,
): string | undefined => {
  let value = localeStrings[findKey(localeStrings, key, caseInsensitive)];

  // Exact match
  if (value) return value;

  // Check for plurals
  const count = args?.count;
  if (typeof count === 'number') {
    const suffix = pluralSuffixes[count];
    value = localeStrings[`${key}.${suffix}`] ?? localeStrings[`${key}.other`];
    if (value) return value;
  }

  logWarning &&
    logWarning(`lookup locale: missing key: ${key} for lang: ${lang}`, args);

  return undefined;
};
