import {assert} from 'assert-ts';
import {Schema, SchemaModifierMap} from 'schemaDefinition/types';
import {getModifiedSchemaWithModifier} from './getModifiedSchemaWithModifier';

export const getModifiedSchema = <TMetadataModifierKey extends string>(
  schema: Schema,
  modifiers: SchemaModifierMap<TMetadataModifierKey>,
  forKey: TMetadataModifierKey,
  keys: TMetadataModifierKey[],
): Schema => {
  const forKeyIdx = keys.indexOf(forKey);
  assert(forKeyIdx >= 0, 'getModifiedSchema: forKey not found in keys');

  return keys.slice(0, forKeyIdx + 1).reduce<Schema>((accSchema, key) => {
    const modifier = modifiers[key];
    return getModifiedSchemaWithModifier(accSchema, modifier);
  }, schema);
};
