import assert from 'assert-ts';
import {Locale} from 'localization/types';
import {translate} from 'localization';
import {Schemas} from 'api/dto.generated';
import {
  Thesaurus,
  ThesaurusId,
  ThesaurusNode,
  ThesaurusNodeDto,
} from '../types';

export const mapThesaurusDto = (
  nodeDtos: ThesaurusNodeDto[],
  thesaurusId: ThesaurusId,
  language: Locale,
): Thesaurus => {
  const thesaurus: Thesaurus = {
    id: thesaurusId,
    code: thesaurusId,
    label: translate(`thesaurus.${thesaurusId}.root.label`),
    language,
    children: nodeDtos.map(dto => mapThesaurusNodeDto(thesaurusId, dto)),
  };

  // Wraps letter categories into a new top-level category
  if (thesaurus.id === 'thema') {
    const letterChildren =
      thesaurus.children?.filter(c => c.id.match('[a-zA-Z]')) ?? [];
    const topLevelLetters: ThesaurusNode = {
      id: 'A-Z',
      code: 'A-Z',
      label: translate('thesaurus.thema.subject.label'),
      children: letterChildren,
    };

    const numberChildren =
      thesaurus.children?.filter(c => c.id.match('[0-9]')) ?? [];

    thesaurus.children = [topLevelLetters, ...numberChildren];
  }

  return thesaurus;
};

export const mapThesaurusNodeDto = (
  thesaurusId: ThesaurusId,
  dto: ThesaurusNodeDto,
): ThesaurusNode => {
  const children = (dto.children ?? []).map(c => {
    const cc = {
      ...c,
      deactivated: (c as ThesaurusNodeDto).deactivated,
    } as ThesaurusNodeDto;

    return mapThesaurusNodeDto(thesaurusId, cc);
  });
  const anyActiveChildren = children.some(c => !c.deactivated);

  const id = assert(dto.id, 'mapThesaurusNodeDto: id missing', dto);
  // GenreAndForm sends uri instead of code
  const code = (thesaurusId === 'genreandform' ? dto.uri : dto.code) ?? id;

  return {
    id: id,
    code,
    label: assert(dto.label, 'mapThesaurusNodeDto: label missing', dto),
    deactivated: dto.deactivated && !anyActiveChildren,
    selectable: !dto.deactivated,
    type: dto.type,
    alternativeLabels: dto.alternativeLabels ?? [],
    related: dto.related
      ? dto.related
          // Exclude deactivated relations
          .filter(r => !(r as Schemas.ThesaurusConcept).deactivated)
          .map(mapThesaurusConceptDto)
      : undefined,
    note: dto.note,
    definition: dto.definition,
    children,
  };
};

const mapThesaurusConceptDto = (
  dto:
    | Schemas.ThesaurusConcept
    | Schemas.GenreAndFormConcept
    | Schemas.ThemaConcept,
): ThesaurusNode => ({
  ...dto,
  id: assert(dto.id, 'mapThesaurusConceptDto: id missing', dto),
  code: dto.id,
  label: assert(dto.label, 'mapThesaurusConceptDto: label missing', dto),
  children: [],
});
