import React, {useMemo} from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import {Layout} from 'components/layout';
import {TextFieldProps} from './types';
import {FieldLabel} from './FieldLabel';
import {NullFieldPlaceholder} from './NullFieldPlaceholder';
import {TextFieldWithChanges} from './TextFieldWithChanges';
import {MuiTextFieldStyled} from './muiStyled';

/**
 * TextField input component
 * If diff is set, TextFieldWithChanges is used to show changes
 */
export const TextField: React.FC<TextFieldProps> = ({
  focusableId,
  showWhenReadonlyAndEmpty = false,
  ...props
}) => {
  const {
    label,
    placeholder,
    name,
    type,
    width,
    maxWidth,
    flex,
    alignItems,
    required = false,
    readonly = false,
    value,
    prefix,
    suffix,
    diff,
    error = false,
    onFocus,
    onBlur,
    ...restProps
  } = props;

  const inputProps = useMemo(
    () => ({
      ...restProps.InputProps,
      id: focusableId ?? name,
      startAdornment: prefix ? (
        <InputAdornment position="start">{prefix}</InputAdornment>
      ) : undefined,
      endAdornment: suffix ? (
        <InputAdornment position="end">{suffix}</InputAdornment>
      ) : undefined,
    }),
    [focusableId, name, prefix, restProps.InputProps, suffix],
  );

  const className = error === 'warning' ? 'Ebba-warning' : undefined;

  return diff ? (
    <TextFieldWithChanges
      {...props}
      InputProps={inputProps}
      className={className}
    />
  ) : (
    <Layout
      width={width}
      maxWidth={maxWidth}
      flex={flex}
      alignItems={alignItems}>
      {label && (
        <FieldLabel
          label={label}
          required={required}
          error={error}
          htmlFor={inputProps.id}
        />
      )}
      {readonly && !value && !showWhenReadonlyAndEmpty ? (
        <NullFieldPlaceholder />
      ) : (
        <MuiTextFieldStyled
          required={required !== false}
          size="small"
          fullWidth
          id={name}
          name={name}
          placeholder={placeholder}
          value={(value ?? '') as string | number}
          readonly={readonly}
          type={type}
          error={error !== false}
          InputProps={inputProps}
          onFocus={onFocus}
          onBlur={onBlur}
          {...restProps}
          className={className}
          spellCheck={false}
          sx={
            prefix
              ? {['.MuiOutlinedInput-root']: {paddingLeft: '8px'}}
              : undefined
          }
        />
      )}
    </Layout>
  );
};
