import {
  LinkedValueLink,
  NameForm,
  NameVariantValue,
} from 'schemaDefinition/types';
import {isVerifiedLinkedAgentLink} from 'schemaDefinition';

export const isLinkedToNameVariant = (
  linkedValue: LinkedValueLink | undefined,
  agentId: string | undefined,
  nameVariant: NameVariantValue,
  nameForm: NameForm,
): boolean => {
  return (
    isVerifiedLinkedAgentLink(linkedValue) &&
    linkedValue.entityId === agentId &&
    (linkedValue.agentName.id === nameVariant.id ||
      (!linkedValue.agentName.id && nameForm === 'mainForm'))
  );
};
