import {assert} from 'assert-ts';
import {
  AnyValidation,
  PartLinkedLiterary,
  PartModifier,
  PartModifierContext,
  Schema,
  SchemaModifier,
  SchemaPartModifier,
  ValidationModifier,
  ValuePartModifier,
} from 'schemaDefinition/types';
import {clone} from 'services/utils';
import {asAnyValidation} from './asAnyValidation';
import {visitSchemaParts} from './visitSchemaParts';

export const getModifiedSchemaWithModifier = (
  schema: Schema,
  modifier: SchemaModifier | undefined,
): Schema => {
  if (!modifier) {
    return schema;
  }

  const modifiedSchema = clone(schema);
  visitSchemaParts(modifiedSchema, (part, path, partContext) => {
    const partModifier = getPartModifier(path, partContext, modifier);
    if (!partModifier) {
      return;
    }

    if ((partModifier as ValuePartModifier).readonly !== undefined) {
      part.readonly = (partModifier as ValuePartModifier).readonly;
    }

    if (partModifier.required !== undefined) {
      part.required = partModifier.required;
    }

    if ((partModifier as ValuePartModifier).linkPropRequired) {
      assert.soft(
        part.type === 'linkedLiterary',
        'getModifiedSchema: linkPropRequired only valid for PartLinkedLiterary',
        part,
      );
      (part as PartLinkedLiterary).linkPropRequired = (
        partModifier as ValuePartModifier
      ).linkPropRequired;
    }

    const validation = getModifiedValidation(
      part.validation,
      partModifier.validation,
    );
    if (validation) {
      part.validation = validation;
    }
    const listValidation = getModifiedValidation(
      part.listValidation,
      partModifier.listValidation,
    );
    if (listValidation) {
      part.listValidation = listValidation;
    }
  });
  return modifiedSchema;
};

const getPartModifier = (
  path: string,
  partContext: PartModifierContext | undefined,
  modifier: SchemaModifier,
): PartModifier | undefined => {
  const pathElements = path.split('.');
  assert(pathElements.length > 0, 'getPartModifier: path expected');

  const partModifier = modifier.find(
    m =>
      m.name === pathElements[0] &&
      (m.partContext === partContext || m.partContext === undefined),
  );
  if (!partModifier) {
    return undefined;
  }

  if (pathElements.length > 1) {
    const schemaPartModifier = partModifierAsSchemaPartModifier(partModifier);

    return schemaPartModifier
      ? getPartModifier(
          pathElements.slice(1).join('.'),
          partContext,
          schemaPartModifier.parts,
        )
      : undefined;
  }

  return partModifier;
};

const partModifierAsSchemaPartModifier = (
  partModifier: PartModifier,
): SchemaPartModifier | undefined => {
  const schmePartModifier = partModifier as SchemaPartModifier;
  if (schmePartModifier.parts) {
    return schmePartModifier;
  }

  return undefined;
};

const getModifiedValidation = (
  validation: AnyValidation | AnyValidation[] | undefined,
  validadtionModifier: ValidationModifier | ValidationModifier[] | undefined,
): AnyValidation | AnyValidation[] | undefined => {
  if (validadtionModifier === undefined) {
    return validation;
  }

  if (validation === undefined) {
    return Array.isArray(validadtionModifier)
      ? validadtionModifier.map(vm => assert(asAnyValidation(vm)))
      : assert(asAnyValidation(validadtionModifier));
  }

  assert(false, 'Merging of validations not yet implemented');
};
