import {PreviewConfiguration} from 'schema/types';
import {Part} from 'schemaDefinition/types';

export const isPartExcluded = <TVal>(
  part: Part<TVal> | undefined,
  config: PreviewConfiguration,
): boolean => {
  return !!(
    part &&
    part.name &&
    ((config.excludeParts && config.excludeParts.includes(part.name)) ||
      (config.includeParts && !config.includeParts.includes(part.name)))
  );
};
