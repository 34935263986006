import {ChangeRequest, ManifestationV4, WorkV4} from 'api';

export const createChangeRequestFromMove = (
  targetWorkId: string,
  fromWork: WorkV4,
  {expressionId: _, ...manifestationWithoutExpressionId}: ManifestationV4,
): ChangeRequest => {
  return {
    id: Date.now().toString(),
    taskType: 'METADATA_MOVED',
    storage: 'local',
    level: 'manifestation',
    work: {
      ...fromWork,
      id: targetWorkId,
    },
    expression: {
      id: 'expressionIdPlaceholder',
    },
    manifestation: manifestationWithoutExpressionId as ManifestationV4,
  } as ChangeRequest;
};
