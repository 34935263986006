import {Concept, ConcreteEntityType} from 'types';
import {SchemaReference} from 'schemas/types';

export const entityTypeToSchemaReference = (
  entityType: ConcreteEntityType,
): SchemaReference => {
  switch (entityType) {
    case Concept.work:
    case Concept.expression:
    case Concept.manifestation:
      return entityType;

    case Concept.person:
    case Concept.event:
    case Concept.publisher:
    case Concept.corporation:
      return {
        entity: Concept.agent,
        subType: entityType,
      };

    case Concept.series:
    case Concept.educationalSeries:
    case Concept.otherConnection:
    case Concept.publisherSeries:
      return {
        entity: Concept.collection,
        subType: entityType,
      };
  }
};
