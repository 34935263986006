import React, {useMemo} from 'react';
import InputLabel from '@mui/material/InputLabel';
import {SxProps} from '@mui/material/styles';
import {FieldError, FieldRequiredSimple} from 'schemaDefinition/types';
import {ColorPalette} from 'theme';
import {WarningDot} from 'components/dot';
import {Layout} from 'components/layout';

type Props = {
  label: string;
  required?: FieldRequiredSimple;
  error?: FieldError;
  htmlFor?: string;
  colorx?: string;
};

const sxWarning: SxProps = {
  // color: ColorPalette.warning,
  fontWeight: 'bold',
};

const sxError: SxProps = {
  color: ColorPalette.error,
  fontWeight: 'bold',
};

const sxWarningDot: SxProps = {
  marginBottom: '9px',
  marginRight: '4px',
};

export const FieldLabel: React.FC<Props> = ({
  label,
  required = false,
  error = false,
  htmlFor,
  colorx,
}) => {
  const sxs = useMemo((): SxProps | undefined => {
    const errorSx =
      error === 'warning' ? sxWarning : error ? sxError : undefined;
    return errorSx ?? (colorx ? {color: colorx} : undefined);
  }, [error, colorx]);
  return error === 'warning' ? (
    <Layout horizontal alignItems="center" justifyContent="start">
      <WarningDot sx={sxWarningDot} />
      <InputLabel htmlFor={htmlFor} required={required === true} sx={sxs}>
        {label}
      </InputLabel>
    </Layout>
  ) : (
    <InputLabel htmlFor={htmlFor} required={required === true} sx={sxs}>
      {label}
    </InputLabel>
  );
};
