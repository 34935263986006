import {DataValue, Valx} from '../types';
import {EvaluationArgs} from './types';
import {evaluateValx} from './evaluateValx';

/**
 * If value cannot be converted to argType, then it is undefined.
 */
export const evaluateValxWithDefault = (
  valx: Valx | undefined,
  args: EvaluationArgs,
  defaultValue: DataValue | undefined,
): DataValue | undefined => {
  return valx !== undefined ? evaluateValx(valx, args) : defaultValue ?? null;
};
