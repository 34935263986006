import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const EventIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g clipPath="url(#clip0_5967_130393)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 6.61627H18C18.5304 6.61627 19.0391 6.82699 19.4142 7.20206C19.7893 7.57713 20 8.08584 20 8.61627V18.6163C20 19.1467 19.7893 19.6554 19.4142 20.0305C19.0391 20.4056 18.5304 20.6163 18 20.6163H6C5.46957 20.6163 4.96086 20.4056 4.58579 20.0305C4.21071 19.6554 4 19.1467 4 18.6163V8.61627C4 8.08584 4.21071 7.57713 4.58579 7.20206C4.96086 6.82699 5.46957 6.61627 6 6.61627H8V4.61627H10V6.61627H14V4.61627H16V6.61627ZM8 10.6163V8.61627H6V11.6163V13.6163V18.6163H18V13.6163V11.6163V8.61627H16V10.6163H14V8.61627H10V10.6163H8Z"
        />
        <g clipPath="url(#clip1_5967_130393)">
          <path d="M12 10.7869L10.9194 12.9744L8.5 13.3288L10.25 15.0351L9.83875 17.4457L12 16.3038L14.1613 17.4413L13.75 15.0351L15.5 13.3288L13.0806 12.9788L12 10.7869Z" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5967_130393">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4.61627)"
          />
        </clipPath>
        <clipPath id="clip1_5967_130393">
          <rect
            width="7"
            height="7"
            fill="white"
            transform="translate(8.5 10.6163)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
