import React from 'react';
import {noOp} from 'services/utils';
import {Layout, ToolBar} from 'components';

type Props = {
  onClose: () => void;
};

export const ThesaurusToolbar: React.FC<Props> = ({onClose}) => {
  return (
    <Layout horizontal adjustCenter adjustRight flex={1}>
      <ToolBar>
        <ToolBar.IconTool icon="Open" onClick={noOp} />
        <ToolBar.IconTool icon="Filter" onClick={noOp} />
        <ToolBar.IconTool icon="Close" onClick={onClose} />
      </ToolBar>
    </Layout>
  );
};
