import {ThesaurusNode} from 'api/types';
import {DataValue, PartThesaurus, Valx} from 'schemaDefinition/types';
import {formatNodeTitle} from 'services/thesaurus/functions/formatNodeTitle';
import {findThesaurusNode} from '../../table/functions/findThesaurusNode';
import {formatNullValue} from './formatNullValue';

export const formatThesaurusValue = (
  value: DataValue,
  part: PartThesaurus<Valx>,
  thesaurus: ThesaurusNode,
): string => {
  if (
    value === undefined ||
    value === null ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return formatNullValue();
  }

  switch (part.type) {
    case 'thesaurus': {
      const codes = Array.isArray(value) ? value : [value];
      return codes
        .map(code => {
          const node = findThesaurusNode(code, thesaurus);
          if (node) {
            return formatNodeTitle(
              node,
              part.showCode,
              part.selectableNodeTypes,
            );
          }
        })
        .join(', ');
    }
  }
};
