import isEqual from 'lodash/isEqual';
import {ManifestationV4} from 'api/types';
import {MetadataExports} from 'services/data/metadata/types';
import {getManifestationExportProps} from './getManifestationExportProps';

export const updateManifestationExportProps = (
  manifestation: ManifestationV4,
  currentExports: MetadataExports | undefined,
): MetadataExports => {
  const newProps = getManifestationExportProps(manifestation);
  return currentExports
    ? isEqual(currentExports.manifestations[manifestation.id], newProps)
      ? currentExports
      : {
          ...currentExports,
          manifestations: {
            ...currentExports.manifestations,
            [manifestation.id]: newProps,
          },
        }
    : {
        work: {
          type: 'BOOK',
        },
        expressions: {},
        manifestations: {
          [manifestation.id]: newProps,
        },
      };
};
