import React from 'react';
import {useLocalization} from 'localization';
import {ToolButton} from 'components';
import {ConfirmationDialog} from './ConfirmationDialog';

type Props = {
  onConfirm: () => void;
  miniMode: boolean;
};

export const ManifestationDeleteButton: React.FC<Props> = ({
  onConfirm,
  miniMode,
}) => {
  const {t} = useLocalization();
  const [open, setOpen] = React.useState(false);

  const handleOpenConfirmation = () => {
    setOpen(true);
  };

  const handleCloseConfirmation = (confirmed?: boolean) => {
    setOpen(false);

    if (confirmed) {
      onConfirm();
    }
  };

  return (
    <>
      <ToolButton
        title={t('page.metadata.manifestation.tools.delete')}
        icon="Delete"
        onClick={handleOpenConfirmation}
        iconOnly={miniMode}
      />
      <ConfirmationDialog
        id="confirm-dialog"
        keepMounted={false}
        open={open}
        onClose={handleCloseConfirmation}
      />
    </>
  );
};
