import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {InputAdornment, TextField as MuiTextField} from '@mui/material';
import {IconName} from 'components/icons/types';
import {ControlledIconButton} from 'components/button';
import './ToolInput.scss';

type Props = {
  value?: string;
  placeholder?: string;
  icons?: {
    empty: IconName;
    submit: IconName;
  };
  disablePadding?: boolean;
  onChange: (value: string) => void;
  onReset: () => void;
  onSubmit: () => Promise<void>;
  isSubmitting: boolean;
  isDelayed: boolean;
};

export function NoteInput({
  value,
  placeholder,
  icons,
  disablePadding,
  onChange,
  onReset,
  onSubmit,
  isSubmitting,
  isDelayed,
}: Props) {
  const inputRef = useRef<HTMLInputElement>();
  const valueRef = useRef('');

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      valueRef.current = newValue;
      onChange(newValue);
    },
    [onChange],
  );

  const handleReset = useCallback(() => {
    onReset();
    inputRef.current?.focus();
    return Promise.resolve();
  }, [onReset]);

  const handleSubmit = useCallback(
    () =>
      (value ? onSubmit() : Promise.resolve()).finally(
        () => inputRef.current?.focus(),
      ),
    [onSubmit, value],
  );

  const handleKeyboardShortcuts = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        handleSubmit();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        handleReset();
      }
    },
    [handleReset, handleSubmit],
  );

  // Handle carret position in case of value reset
  useEffect(() => {
    const input = inputRef.current;
    if (value !== valueRef.current && input) {
      const end = value?.length ?? 0;
      input.focus();
      input.setSelectionRange(end, end, 'none');
    }
  }, [value]);

  const adornment = useMemo(() => {
    return icons ? (
      <InputAdornment position="end">
        <ControlledIconButton
          icon={value ? icons.submit : icons.empty}
          size="small"
          onClick={handleSubmit}
          isCalling={isSubmitting}
          isDelayed={isDelayed}
        />
      </InputAdornment>
    ) : null;
  }, [handleSubmit, icons, isDelayed, isSubmitting, value]);

  const className = disablePadding
    ? 'ToolInput Multiline-ToolInput NoPadding-ToolInput'
    : 'ToolInput Multiline-ToolInput';

  return (
    <MuiTextField
      id="toolInput"
      name="toolInput"
      value={value}
      className={className}
      disabled={isSubmitting}
      autoFocus
      autoComplete="off"
      multiline
      placeholder={placeholder}
      InputProps={{
        inputRef: inputRef,
        endAdornment: adornment,
        onKeyDown: handleKeyboardShortcuts,
      }}
      onChange={handleChange}
    />
  );
}
