import React, {useCallback, useMemo} from 'react';
import {Checkbox, FormControlLabel} from '@mui/material';
import {CurrentFilterType} from 'api/types';
import {useCurrentFilters} from '../hooks';

type Props = {
  label: string;
  filter: CurrentFilterType;
};
export const CustomFilter: React.FC<Props> = ({filter, label}) => {
  const {currentFilters, addCurrentFilter, removeCurrentFilterById} =
    useCurrentFilters();

  const checked: boolean = useMemo(() => {
    return !!currentFilters.find(c => c.id === filter.id);
  }, [currentFilters, filter.id]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const add = event.target.checked;

      if (add) {
        addCurrentFilter(filter);
      } else {
        removeCurrentFilterById(filter.id);
      }
    },
    [addCurrentFilter, filter, removeCurrentFilterById],
  );

  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={handleChange}
          checked={checked}
          inputProps={{'aria-label': 'controlled'}}
        />
      }
      label={label}
    />
  );
};
