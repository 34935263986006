import React from 'react';
import {Tooltip} from '@mui/material';
import {ProductOwner as ProductOwnerApiType} from 'api/types';
import {PartValueRenderer} from 'schema/types';

export const ProductOwner: PartValueRenderer = ({value}) => {
  if (value && (value as ProductOwnerApiType).code) {
    if ((value as ProductOwnerApiType).name) {
      return (
        <Tooltip title={(value as ProductOwnerApiType).code}>
          <span>{(value as ProductOwnerApiType).name}</span>
        </Tooltip>
      );
    }
    return <span>{(value as ProductOwnerApiType).code}</span>;
  }

  return null;
};
