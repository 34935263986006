import {PartInt, Valx} from 'schemaDefinition/types';
import {evaluateFieldRequired} from 'schemaDefinition';
import {ValidationArgs, ValidationResult} from './types';
import {fail} from './fail';
import {validResult} from './validResult';
import {validateConditions} from './validateConditions';
import {validateValueType} from './validateValueType';
import {warning} from './warning';

export const validateInt = (
  part: PartInt<Valx>,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(part, validateSingleIntValue, args, aggregateResult);
};

export const validateSingleIntValue = (
  part: PartInt<Valx>,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  if (args.value === undefined || args.value === null || args.value === '') {
    const required = evaluateFieldRequired(
      part.required,
      args.valuePath,
      args.localScope,
      args.globalScope,
      args.relatedScope,
      args.value,
    );
    return required
      ? (required === true ? fail : warning)(
          {
            ...args,
            part,
            validation: 'required',
            messageKey: 'required',
          },
          aggregateResult,
        )
      : aggregateResult;
  }

  if (typeof args.value !== 'number' || isNaN(args.value)) {
    return fail(
      {
        ...args,
        part,
        validation: 'invalid type',
        messageKey: 'invalid_number',
      },
      aggregateResult,
    );
  }

  if (part.validation) {
    return validateConditions(part.validation, part, args, aggregateResult);
  }

  return aggregateResult;
};
