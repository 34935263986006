import {
  GridFilterInputMultipleValue,
  GridFilterOperator,
} from '@mui/x-data-grid-pro';

export const isNotAnyOf = (exactMatch: boolean) => {
  return {
    value: 'isNotAnyOf',
    label: 'er ikke en av',
    getApplyFilterFn: filterItem => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length === 0) {
        return null;
      }
      const disableTrim = false;
      const filterItemValue = disableTrim
        ? filterItem.value
        : filterItem.value.map(val => val.trim());

      return params =>
        params.value != null
          ? !filterItemValue.some((filterValue: string) =>
              isMatch(exactMatch, params.value, filterValue),
            )
          : true;
    },
    InputComponent: GridFilterInputMultipleValue,
  } as GridFilterOperator;
};

const isMatch = (
  exactMatch: boolean,
  value?: string | undefined,
  filterValue?: string | undefined,
): boolean => {
  if (!value && !filterValue) {
    return true;
  }
  if (!value || !filterValue) {
    return false;
  }

  return exactMatch
    ? value.toLowerCase() === filterValue.toLowerCase()
    : value.toLowerCase().includes(filterValue.toLowerCase());
};
