import {useSelector} from 'react-redux';
import {AppState} from 'store/types';

const hasOpenWorkLevelChangeRequestsSelector = (state: AppState) => {
  return !!state.metadataEdit.metadata?.changeRequests.some(
    cr => cr.level === 'work' && cr.status !== 'COMPLETED',
  );
};

export const useHasOpenWorkLevelChangeRequests = () => {
  return useSelector(hasOpenWorkLevelChangeRequestsSelector);
};
