import {
  LinkedRoleCodeListId,
  SpecificLinkedRoleCodeListId,
  SpecificLinkedRoleCodeListIds,
} from 'schemaDefinition/linkTypes';

export const isSpecificLinkedRoleCodeListId = (
  codelistId: LinkedRoleCodeListId | undefined,
): codelistId is SpecificLinkedRoleCodeListId => {
  return (
    !!codelistId &&
    SpecificLinkedRoleCodeListIds.includes(
      codelistId as SpecificLinkedRoleCodeListId,
    )
  );
};
