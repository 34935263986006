import {assert} from 'assert-ts';
import {Code, CodeList} from 'api/types';

export const assertCodelistItem = (code: string, codelist: CodeList): Code => {
  const item = codelist.codes.find(c => c.code === code);

  if (
    assert.soft(item, 'getCodeSortValue: code expected', {
      code,
      codelistId: codelist.id,
      language: codelist.language,
    })
  ) {
    return item;
  }

  return {code, value: code};
};
