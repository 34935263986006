import {useMemo} from 'react';
import {Locale} from '../../types';
import {LocalizationContextType} from '../types';
import {setFormattingLocale, setLocale} from '../../core';
import {getLocalizationContext} from '../getLocalizationContext';

export const useLocalizationContextProvider = (
  preferredLocale?: Locale,
): LocalizationContextType => {
  return useMemo(() => {
    if (preferredLocale) {
      setLocale(preferredLocale);
      setFormattingLocale(preferredLocale);
    }

    return getLocalizationContext(preferredLocale);
  }, [preferredLocale]);
};
