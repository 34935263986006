import assert from 'assert-ts';
import {DateType} from '../types';
import {Schemas} from '../dto.generated';
import {
  fromApiDate,
  fromApiYear,
  toApiDate,
  toApiYear,
} from '../http/functions/apiDate';

export const mapDateTypeDto = (
  dto?: Schemas.DateTypeDto,
): DateType | undefined => {
  if (!dto || (!dto.date && dto.year === undefined)) {
    return undefined;
  }

  if (!dto.date && dto.year === 0) {
    assert.soft(false, 'mapDateTypeDto: year 0 is not allowed');
    return undefined;
  }

  const isApproximate = dto.isApproximate ? {isApproximate: true} : {};

  // Date
  if (dto.date) {
    return {
      ...isApproximate,
      ...fromApiDate(dto.date),
    };
  }

  // Year
  if (dto.year !== undefined) {
    return {
      ...isApproximate,
      ...fromApiYear(dto.year),
    };
  }
};

export const mapToDateTypeDto = (
  data?: DateType,
): Schemas.DateTypeDto | undefined => {
  if (!data || (!data.date && data.year === undefined)) {
    return undefined;
  }

  const isApproximate = data.isApproximate ? {isApproximate: true} : {};

  if (data.date) {
    return {
      ...isApproximate,
      date: toApiDate(data.date, data.bce),
    };
  }

  if (data.year !== undefined) {
    return {
      ...isApproximate,
      year: toApiYear(data.year, data.bce),
    };
  }
};
