import isEqual from 'lodash/isEqual';
import {ExpressionV4} from 'api/types';
import {MetadataExports} from 'services/data/metadata/types';
import {getExpressionExportProps} from './getExpressionExportProps';

export const updateExpressionExportProps = (
  expression: ExpressionV4,
  currentExports: MetadataExports | undefined,
): MetadataExports => {
  const newProps = getExpressionExportProps(expression);
  return currentExports
    ? isEqual(currentExports.expressions[expression.id], newProps)
      ? currentExports
      : {
          ...currentExports,
          expressions: {
            ...currentExports.expressions,
            [expression.id]: newProps,
          },
        }
    : {
        work: {
          type: 'BOOK',
        },
        expressions: {
          [expression.id]: newProps,
        },
        manifestations: {},
      };
};
