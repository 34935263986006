import {useMemo} from 'react';
import {OverviewTask, TaskTypeAll} from 'api/types';
import {searchTasks, toggleTaskTypes} from '../functions';

export const useSearchedTasks = (
  allTasks: OverviewTask[] | undefined,
  taskType: TaskTypeAll | undefined,
  searchText: string | undefined,
): OverviewTask[] => {
  const filteredTasks = useMemo(() => {
    return toggleTaskTypes(allTasks || [], taskType);
  }, [allTasks, taskType]);

  return useMemo(() => {
    return searchText ? searchTasks(filteredTasks, searchText) : filteredTasks;
  }, [filteredTasks, searchText]);
};
