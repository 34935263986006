import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const EditIcon: React.FC<IconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M19.1 4.89997C18.519 4.34259 17.7451 4.03137 16.94 4.03137C16.1349 4.03137 15.361 4.34259 14.78 4.89997L4 15.68V20H8.32L19.1 9.21997C19.3903 8.94012 19.6213 8.60461 19.779 8.23348C19.9368 7.86235 20.0181 7.46323 20.0181 7.05997C20.0181 6.65671 19.9368 6.25759 19.779 5.88647C19.6213 5.51534 19.3903 5.17982 19.1 4.89997ZM7.5 18H6V16.5L14 8.49997L15.5 9.99997L7.5 18ZM17.69 7.80997L16.94 8.54997L15.45 7.05997L16.19 6.30997C16.3925 6.11796 16.6609 6.01092 16.94 6.01092C17.2191 6.01092 17.4875 6.11796 17.69 6.30997C17.8867 6.51008 17.9968 6.77941 17.9968 7.05997C17.9968 7.34053 17.8867 7.60987 17.69 7.80997V7.80997Z" />
    </SvgIcon>
  );
};
