import {SxProps} from '@mui/material';
import {GridValidRowModel} from '@mui/x-data-grid-pro';
import {Fonts} from 'theme/fonts';
import {DataTableProps} from '../DataTable';

export const getTableStyles = <TData extends GridValidRowModel>(
  onRowClick?: DataTableProps<TData>['onRowClick'],
  getRowHeight?: DataTableProps<TData>['getRowHeight'],
): SxProps => {
  const defaultSx = {
    borderWidth: 0,
    '& .MuiDataGrid-cell': {
      ...Fonts.body1,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      ...Fonts.label,
    },
  };

  const rowHeightSx =
    getRowHeight &&
    getRowHeight({
      id: 1,
      densityFactor: 1,
    }) === 'auto'
      ? {
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: 1},
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: 2,
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: 3,
          },
        }
      : undefined;

  const cursorSx = onRowClick ? {cursor: 'pointer'} : undefined;

  // If row height is set to "auto", add some padding top and bottom of rows, because density will be ignored.
  return rowHeightSx || cursorSx
    ? {...defaultSx, ...rowHeightSx, ...cursorSx}
    : defaultSx;
};
