import React, {Fragment, useMemo} from 'react';
import {assertAsLinkedValue} from 'schemaDefinition/functions';
import {valueAsString} from 'services/utils';
import {FlexBox, Html} from 'components';
import {isMultiple} from 'schema/form/functions';
import {
  LinkedLiterary,
  PartBool,
  PartDate,
  PartHtml,
  PartInt,
  PartLinkedAgent,
  PartLinkedLiterary,
  PartText,
  PartTextArea,
  PartYear,
  PartYearOrDate,
  Valx,
} from '../../../schemaDefinition/types';
import {BasePartProps} from './types';
import {
  formatLinkedValueLink,
  formatNullValue,
  formatScalarValue,
} from '../functions';
import {useTextValue} from '../hooks';
import {useAdornments} from '../hooks/useAdornments';
import {SingleLineValue, SingleValue} from './fields';
import {MultiLineValue} from './fields/MultiLineValue';
import {MultiValue} from './fields/MultiValue';

type PreviewSimpleValueProps = BasePartProps<
  | PartText<Valx>
  | PartTextArea<Valx>
  | PartHtml<Valx>
  | PartInt<Valx>
  | PartBool<Valx>
  | PartDate<Valx>
  | PartYear<Valx>
  | PartYearOrDate<Valx>
  | PartLinkedAgent<Valx>
  | PartLinkedLiterary<Valx>
>;

export const PreviewSimpleValue: React.FC<PreviewSimpleValueProps> = ({
  part,
  value,
  layoutDirection,
}) => {
  const TextValue = useTextValue();
  const {prefix, suffix} = useAdornments(part, value);

  return useMemo(() => {
    switch (part.type) {
      case 'year':
      case 'date':
      case 'yearOrDate':
      case 'int':
      case 'bool': {
        return (
          <SingleLineValue<
            | PartInt<Valx>
            | PartBool<Valx>
            | PartDate<Valx>
            | PartYear<Valx>
            | PartYearOrDate<Valx>
          >
            part={part}
            value={value}
            layoutDirection={layoutDirection}
            prefix={prefix}
            suffix={suffix}
            formatter={formatScalarValue}
          />
        );
      }
      case 'text': {
        if (isMultiple(part.cardinality)) {
          return (
            <MultiLineValue<PartText<Valx>>
              part={part}
              value={value}
              layoutDirection={layoutDirection}
            />
          );
        } else {
          return (
            <SingleLineValue<PartText<Valx>>
              part={part}
              value={value}
              layoutDirection={layoutDirection}
              prefix={prefix}
              suffix={suffix}
              formatter={formatScalarValue}
            />
          );
        }
      }
      case 'textarea': {
        return (
          <MultiValue
            part={part}
            value={value}
            layoutDirection={layoutDirection}
            renderValue={(key, singleValue) => {
              return (
                <Fragment key={key}>
                  {(singleValue?.toString() ?? '')
                    .split('\n')
                    .map((line, index) => (
                      <FlexBox key={index}>
                        <TextValue>{line}</TextValue>
                      </FlexBox>
                    ))}
                </Fragment>
              );
            }}
          />
        );
      }
      case 'html': {
        return (
          <SingleValue
            part={part}
            layoutDirection={layoutDirection}
            renderValue={() => {
              const strValue = valueAsString(value) ?? null;
              return <Html name={part.name} value={strValue} readonly />;
            }}
          />
        );
      }
      case 'linkedAgent':
      case 'linkedLiterary': {
        return (
          <MultiValue
            part={part}
            value={value}
            layoutDirection={layoutDirection}
            renderValue={(key, singleValue) => {
              const linkedValue = assertAsLinkedValue(singleValue);
              const formattedValue = formatLinkedValueLink(
                linkedValue?.link,
                (linkedValue as LinkedLiterary)?.numberInSeries,
              );
              return (
                <TextValue key={key}>
                  {formattedValue ?? formatNullValue()}
                </TextValue>
              );
            }}
          />
        );
      }
      default:
        return null;
    }
  }, [TextValue, layoutDirection, part, prefix, suffix, value]);
};
