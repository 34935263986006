import {CodeList, CodeListId, GetTokens} from './types';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpGet} from './http/ebba';
import {mapCodeListDto} from './mappers';
import {getMockCodeList} from './mockCodeLists/getMockCodeList';

const mockCodeLists: CodeListId[] = [
  // 'EXPRESSION_FORMAT',
  // 'EXPRESSION_CONTENT',
  // 'COLOUR',
  // 'GENDER',
  // 'LANGUAGE',
  // 'PRODUCT_FORM',
  // 'EXPRESSION_ROLE_TYPE',
  'manifestation_role_type',
  'collection_type',
  'work_collection_type',
  'product_form_simple_values',
  'availability_status',
  'task_type',
  'media_sub_type',
  'WORK_TITLE_TYPE',
  'WORK_TYPE',
];

export const getCodeList = (
  codelistName: CodeListId,
  language: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<CodeList> => {
  return exceptionToPromiseReject(() =>
    mockCodeLists.includes(codelistName)
      ? getMockCodeList(codelistName, language)
      : httpGet<Schemas.CodelistDto>({
          subdir: 'codelist/v2/',
          queryParams: {
            id: codelistName,
            language: language,
          },
          getTokens,
          mock,
        }).then(mapCodeListDto),
  );
};
