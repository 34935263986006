import React, {useMemo} from 'react';
import {SxProps, Theme} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {IconName} from '../icons/types';
import {useThemeColors} from '../../theme';
import {Icon} from '../icons';

type Props = {
  icon: IconName;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  rotate?: number;
  disabled?: boolean;
};

export const IconTool: React.FC<Props> = ({
  icon,
  onClick,
  rotate,
  disabled,
}) => {
  const themeColors = useThemeColors();

  const buttonSx = useMemo(
    (): SxProps<Theme> => ({
      color: themeColors.text.primary.dark,
      opacity: disabled ? 0.3 : 1,
      background: 'inherit',
      transform: rotate ? 'rotate(' + rotate + 'deg)' : 'inherit',
    }),
    [disabled, rotate, themeColors.text.primary.dark],
  );
  return (
    <IconButton
      // aria-label="account of current user"
      // aria-controls="menu-appbar"
      sx={buttonSx}
      onClick={onClick}>
      <Icon icon={icon} />
    </IconButton>
  );
};
