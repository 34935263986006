import assert from 'assert-ts';

/**
 * Gets path to parent array, i.e. without index, and index of item in array
 * E.g. 'preferredTitle[0]' => {parentPath: 'preferredTitle', itemIndex: 0}
 */
export const getParentArrayPathParts = (
  path: string,
): {parentPath?: string; itemIndex?: number} => {
  const regex = /^(.*)\[(\d+)\]$/; // '....[2]'
  const match = path.match(regex);
  assert.soft(match, 'getParentArrayPathParts: parentArrayPath not found', {
    path,
  });
  return match ? {parentPath: match[1], itemIndex: parseInt(match[2])} : {};
};
