import {Thesaurus, ThesaurusValue} from 'api';
import {getThesaurusMaps} from './getThesaurusMaps';

/**
 * Finds id of all nodes in the thesaurus with code matching the given valueAsCodes
 * @param valueAsCodes
 * @param thesaurus
 */
export const findAllNodeIds = (
  valueAsCodes: ThesaurusValue,
  thesaurus: Thesaurus,
): ThesaurusValue => {
  const {codeToIds} = getThesaurusMaps(thesaurus);
  return valueAsCodes.map(code => codeToIds.get(code)!).flat();
};
