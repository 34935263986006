import React from 'react';
import {FCWithChildren} from 'types';

const SceneRightPanelName = 'SceneRightPanelName';

const SceneRightPanel: FCWithChildren = ({children}) => {
  return <>{children}</>;
};

SceneRightPanel.displayName = SceneRightPanelName;

export {SceneRightPanel, SceneRightPanelName};
