import {PartValueRenderer} from 'schema/types';
import {
  isLinkedAgentMultiRole,
  isVerifiedLinkedAgentLink,
} from 'schemaDefinition';
import {FlexBox} from 'components';
import {formatNameVariantValue} from 'schema/preview/functions';
import {useTextValue} from 'schema/preview/hooks';

export const Agents: PartValueRenderer = ({value}) => {
  const TextValue = useTextValue();
  if (!value || !Array.isArray(value)) {
    return null;
  }

  const names: string[] = value
    .map(v => {
      if (isLinkedAgentMultiRole(v)) {
        if (isVerifiedLinkedAgentLink(v.link)) {
          return formatNameVariantValue(
            v.link.agentName,
            v.link.type,
            'excludeId',
          );
        } else {
          return v.link.name;
        }
      }
    })
    .filter(v => v) as string[];

  return names.length === 0 ? null : (
    <FlexBox>
      {names.map((v, i) => (
        <TextValue key={i}>{v}</TextValue>
      ))}
    </FlexBox>
  );
};
