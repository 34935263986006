import get from 'lodash/get';
import set from 'lodash/set';
import {PartVisitor} from 'schemaDefinition/functions/types';
import {visitSchemaParts} from 'schemaDefinition/functions';
import {clone} from 'services/utils/functions';
import {Data, DataSimpleValue, Schema} from '../../../schemaDefinition/types';

/**
 * Remove empty values from data
 */
export const trimDataValue = <TEntity extends Data = Data>(
  data: TEntity,
  schema: Schema,
): TEntity => {
  const trimmedData = clone(data);
  const visitor: PartVisitor = (part, path) => {
    if (part.cardinality === 'multiple' || part.cardinality === '2') {
      const partValue = get(trimmedData, path);
      if (Array.isArray(partValue)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const trimmedValue = partValue.filter(
          (v: Data | DataSimpleValue) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            typeof v !== 'object' || Object.keys(v).length > 0,
        );
        set(trimmedData, path, trimmedValue);
      }
    }
  };

  visitSchemaParts(schema, visitor);

  return trimmedData;
};
