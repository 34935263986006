import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const MoveIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" transform="scale(1)">
      <path
        d="M14.21 5.78999L12.79 7.20999L16.59 11L11 11L11 13L16.59 13L12.79 16.79L14.21 18.21L20.41 12L14.21 5.78999Z"
        fill="#480905"
      />
      <path d="M9 13V11H7V13H9Z" fill="#480905" />
      <path d="M5 13V11H4V13H5Z" fill="#480905" />
    </SvgIcon>
  );
};
