import {useRef} from 'react';
import {assert} from 'assert-ts';

interface IStableIds {
  isValid(idx: number): boolean;
  getId(idx: number): string;
  appendValue(): IStableIds;
  deleteValue(idx: number): IStableIds;
  deleteAll(): IStableIds;
  moveValue(fromIdx: number, toIdx: number): IStableIds;
  resetIds<T>(list: T[] | undefined | null, baseId?: number): void;
}

export class StableIds implements IStableIds {
  static maxBaseId = 0;
  ids: number[];
  maxId: number;
  baseId: number;

  constructor(initialLength: number, baseId?: number) {
    this.maxId = initialLength - 1;
    this.ids = Array.from(Array(initialLength).keys());
    this.baseId = baseId ?? StableIds.maxBaseId;
    StableIds.maxBaseId++;
  }

  isValid(idx: number): boolean {
    return idx >= 0 && idx < this.ids.length;
  }

  getId(idx: number): string {
    assert(idx >= 0 && idx < this.ids.length, 'getId: invalid idx', () => ({
      idx,
      ids: this.ids,
    }));
    return `${this.baseId}-${this.ids[idx]}`;
  }

  appendValue(): IStableIds {
    this.maxId++;
    this.ids.push(this.maxId);
    return this;
  }

  deleteValue(idx: number): IStableIds {
    assert(
      idx >= 0 && idx < this.ids.length,
      'deleteValue: invalid idx',
      () => ({idx, ids: this.ids}),
    );
    this.ids.splice(idx, 1);
    return this;
  }

  deleteAll(): IStableIds {
    this.ids = [];
    return this;
  }

  moveValue(fromIdx: number, toIdx: number): IStableIds {
    assert(
      fromIdx >= 0 && fromIdx < this.ids.length,
      'moveValue: invalid idx',
      () => ({fromIdx, ids: this.ids}),
    );
    assert(
      toIdx >= 0 && toIdx < this.ids.length,
      'moveValue: invalid idx',
      () => ({toIdx, ids: this.ids}),
    );
    const value = this.ids[fromIdx];
    this.ids.splice(fromIdx, 1);
    this.ids.splice(toIdx, 0, value);
    return this;
  }

  resetIds(list: [] | undefined | null, baseId?: number) {
    const initialLength = list?.length ?? 0;
    this.maxId = initialLength - 1;
    this.ids = Array.from(Array(initialLength).keys());
    this.baseId = baseId ?? StableIds.maxBaseId;
    StableIds.maxBaseId++;
  }
}

export const useStableIds = <T>(
  initialList: T[] | undefined | null,
): IStableIds => {
  const origianlRef = useRef(new StableIds(initialList?.length ?? 0));

  return origianlRef.current;
};
