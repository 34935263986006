import {useMemo} from 'react';
import {ProductStatus} from 'api/types';
import {useLocalization} from 'localization';

type ProductStatusTipsMap = {
  [key in ProductStatus]: string;
};

export const useProductStatusTips = (): ProductStatusTipsMap => {
  const {t} = useLocalization();
  return useMemo(
    () => ({
      ['ACTIVE']: t('entity.manifestation.productStatus.tip.ACTIVE'),
      ['INACTIVE']: t('entity.manifestation.productStatus.tip.INACTIVE'),
      ['ONHOLD']: t('entity.manifestation.productStatus.tip.ONHOLD'),
      ['COMING']: t('entity.manifestation.productStatus.tip.COMING'),
    }),
    [t],
  );
};
