import {MetadataValidationResultV0} from 'schemas/types';
import {getValidationPrPart} from 'schema/form/functions/validators';

export const getMetadataValidationPrPart = (
  validation: MetadataValidationResultV0,
): MetadataValidationResultV0 => {
  return {
    ...validation,
    work: getValidationPrPart(validation.work),
    expression: getValidationPrPart(validation.expression),
    manifestation: getValidationPrPart(validation.manifestation),
  };
};
