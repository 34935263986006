import {useCallback, useMemo, useState} from 'react';
import {RowId} from 'components/dataTable/types';
import {useLocalization} from 'localization';
import {AgentSelection, EditorMode} from '../types';

const ALTERNATIVE_SELECTION = 'ALTERNATIVE_SELECTION';

export const useAgentSearchSelection = (mode: EditorMode): AgentSelection => {
  const {t} = useLocalization();
  const [selection, setSelection] = useState<RowId[]>([]);

  const handleSetAlternativeSelection = useCallback((selected: boolean) => {
    setSelection(selected ? [ALTERNATIVE_SELECTION] : []);
  }, []);

  return useMemo(
    () => ({
      resultSelection: selection[0] === ALTERNATIVE_SELECTION ? [] : selection,
      alternativeSelection: selection[0] === ALTERNATIVE_SELECTION,
      alternativeLabel: t(`wizard.agent.step.1.alternative.${mode}.label`),
      setAlternativeSelection: handleSetAlternativeSelection,
      setResultSelection: setSelection,
    }),
    [handleSetAlternativeSelection, mode, selection, t],
  );
};
