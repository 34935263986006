import {ThesaurusAction} from 'types';
import {FieldExtensionEnum} from 'sceneExtensions/types';
import {ThesaurusValue} from 'schemaDefinition/types';
import {useFieldExtensionContext} from 'sceneExtensions/hooks';
import {ThesaurusFieldConfig, ThesaurusMetadataFieldExtension} from '../types';

export const useThesaurusMetadataFieldExtension =
  (): ThesaurusMetadataFieldExtension => {
    return useFieldExtensionContext<
      FieldExtensionEnum.thesaurusEdit,
      ThesaurusValue,
      ThesaurusValue,
      ThesaurusAction,
      ThesaurusFieldConfig
    >(FieldExtensionEnum.thesaurusEdit);
  };
