import React from 'react';
import {FCWithChildren} from 'types';
import './NoWrap.scss';

export const NoWrap: FCWithChildren = ({children}) => {
  const style = {
    overflow: 'hidden',
  };

  return (
    <div style={style}>
      <div className={'nowrap'}>{children}</div>
    </div>
  );
};
