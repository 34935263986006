import React from 'react';
import {useDroppable} from '@dnd-kit/core';
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {SxProps} from '@mui/material';
import {ColumnName, DndItemAction, DndItemProps} from '../../types';
import {Card} from '../../../../components';
import {DndSortableItem} from './DndSortableItem';

type Props = {
  id: ColumnName;
  items: DndItemProps[];
  header?: React.ReactNode;
  action?: DndItemAction;
};

const style: SxProps = {
  height: '100%',
  width: '300px',
  marginRight: '1em',
};

export const DndContainer: React.FC<Props> = ({id, items, header, action}) => {
  const {setNodeRef} = useDroppable({
    id,
  });

  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}>
      <div ref={setNodeRef}>
        <Card sx={style}>
          {header ? <Card.Header minHeight={20}>{header}</Card.Header> : null}
          <Card.Content>
            {items.map(item => (
              <DndSortableItem key={item.id} {...item} action={action} />
            ))}
          </Card.Content>
        </Card>
      </div>
    </SortableContext>
  );
};
