import React, {useCallback} from 'react';
import {AgentSubType, Concept} from 'types';
import {Agent} from 'api/types';
import {useLocalization} from 'localization';
import {SaveAgentFunc} from 'services/data/agent';
import {useSnacks} from 'components';
import {DataFormDialogV1} from 'schema/form/components/DataFormDialogV1';
import {useSchema} from 'schemas';
import {useLinkedValueSearchContextProvider} from 'sceneExtensions';

type Props = {
  isOpen: boolean;
  /**
   * Original value of the agent: when new agent, this will just contain the type,
   * otherwise it will contain the whole agent
   * If isOpen, then originalAgent must be set
   **/
  originalAgent?: Agent;
  onSave: SaveAgentFunc;
  onCancel: () => void;
};

const titleMap: {[key in AgentSubType]: string} = {
  person: 'page.agents.add.dialog.person.title',
  publisher: 'page.agents.add.dialog.publisher.title',
  corporation: 'page.agents.add.dialog.corporation.title',
  event: 'page.agents.add.dialog.event.title',
};

export const EditAgentDialog: React.FC<Props> = ({
  isOpen,
  originalAgent,
  onSave,
  onCancel,
}) => {
  const {t, tLoose} = useLocalization();
  const {successSnack, errorSnack} = useSnacks();
  const LinkedValueSearchContext = useLinkedValueSearchContextProvider();
  const schema = useSchema({
    entity: Concept.agent,
    subType: originalAgent?.agentType ?? Concept.person,
  });

  const handleSaveAgent = useCallback(
    (data: Agent) => {
      return onSave(data, 'bbOnly')
        .then(() => {
          successSnack(t('page.agents.save.success'));
        })
        .catch(() => {
          errorSnack(t('page.agents.save.failed'));
        });
    },
    [errorSnack, onSave, successSnack, t],
  );

  return (
    <LinkedValueSearchContext>
      <DataFormDialogV1
        isOpen={isOpen}
        title={tLoose(titleMap[originalAgent?.agentType ?? Concept.person])}
        originalData={originalAgent}
        schema={schema}
        onSave={handleSaveAgent}
        onCancel={onCancel}
      />
    </LinkedValueSearchContext>
  );
};
