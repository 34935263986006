import {Locale, MasterLocale} from '../../types';

const localeMap: {[locale in Locale]: string} = {
  en: 'en-GB',
  nb: 'no-NO', // TODO: consider de-DE instead for '.' as thousand separator instead of ' '
  nn: 'no-NO',
};

let preferredLocale: Locale = MasterLocale;

const createFormatter = (
  locale: Locale,
  maxDecimals: number,
  minDecimals?: number,
) => {
  const loc = localeMap[locale ?? preferredLocale];

  return new Intl.NumberFormat(loc, {
    maximumFractionDigits: maxDecimals,
    minimumFractionDigits: minDecimals,
  });
};

const createFormatters = (locale: Locale) => ({
  amountFormatter0Dec: createFormatter(locale, 0),
  amountFormatter2Dec: createFormatter(locale, 2, 2),
});

let {amountFormatter0Dec, amountFormatter2Dec} = createFormatters(MasterLocale);

export const setNumberLocale = (locale: Locale) => {
  preferredLocale = locale;
  const newFormatters = createFormatters(locale);
  amountFormatter0Dec = newFormatters.amountFormatter0Dec;
  amountFormatter2Dec = newFormatters.amountFormatter2Dec;
};

export const formatAmount = (value: number, decimals = 0): string => {
  switch (decimals) {
    case 0:
      return amountFormatter0Dec.format(value);
    case 2:
      return amountFormatter2Dec.format(value);
    default:
      return createFormatter(preferredLocale, decimals).format(value);
  }
};
