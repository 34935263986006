import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import {
  CodeList,
  CodeListId,
  CodeListMap,
  DataLoadStatus,
  LoadStatus,
} from 'api/types';
import {AppState} from 'store/types';

const codeListStateSelector = (state: AppState) => state.codeLists;

const aggregateLoadStatus = (statuses: LoadStatus[]): LoadStatus => {
  if (statuses.every(s => s === 'Loaded')) {
    return 'Loaded';
  }

  if (statuses.some(s => s === 'Failed')) {
    return 'Failed';
  }

  return 'Loading';
};

export const useCodeListsWithLoadStatus = (
  ids: CodeListId[],
): DataLoadStatus<CodeListMap> => {
  const codeListState = useSelector(codeListStateSelector);
  return useMemo(() => {
    const codelists = ids.map(
      id =>
        codeListState[id] ??
        ({status: 'NotLoaded'} as DataLoadStatus<CodeList>),
    );
    const statuses = codelists.map(cl => cl.status);
    const status = aggregateLoadStatus(statuses);

    switch (status) {
      case 'NotLoaded':
      case 'Loading': {
        return {status};
      }
      case 'Failed': {
        return {
          status,
          error: assert(
            codelists.find(cl => cl.status === 'Failed')?.error,
            'useCodeListsWithLoadStatus: some error expectred',
          ),
        };
      }
      case 'Loaded': {
        const map = ids.reduce<CodeListMap>((acc, id) => {
          return {...acc, [id]: assert(codeListState[id]?.data)};
        }, {});
        return {status, data: map};
      }
    }
  }, [codeListState, ids]);
};
