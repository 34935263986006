import React from 'react';
import {RightPanelConfig} from 'components/scene/types';

export enum FieldExtensionEnum {
  // For testing/demo purposes
  agentSearchTest = 'agentSearchTest',
  fixedTest = 'fixedTest',

  // Proper extensions
  thesaurusEdit = 'thesaurusEdit',
  linkedValueSearch = 'linkedValueSearch',
  workSearch = 'workSearch',
}

/**
 *
 */
export type SourceField<TValue, TFieldConfig = undefined> = {
  /** Key/path of property to edit/view value for */
  fieldId: string;
  /** Current linked value */
  currentValue?: TValue;
  /** Field config */
  fieldConfig?: TFieldConfig;
};

export type SetSourceFieldValue<TSourceValue, TAction extends string> = (
  value: TSourceValue | undefined,
  fieldId: string,
  action: TAction,
) => void;

export type SetSourceValueCore<TSourceValue, TAction extends string> = (
  value: TSourceValue | undefined,
  action: TAction,
) => void;

export type KeepOpen = 'keepOpen' | 'close';

export type SetSourceValue<TSourceValue, TAction extends string> = (
  value: TSourceValue | undefined,
  action: TAction,
  keepOpen?: KeepOpen,
) => void;

/**
 * Returns true if extension is should be closed, i.e. fieldId same as current source field id
 */
export type FieldExtensionUnsubscribeCore = (fieldId: string) => boolean;

export type FieldExtensionUnsubscribe = (fieldId: string) => void;

/**
 * Context to coordinate interactions between source fields
 * and extension
 */
export type FieldExtensionContextCore<
  // was LinkedValueSearchContextType
  TType extends FieldExtensionEnum,
  TValue,
  TSourceValue = TValue,
  TAction extends string = string,
  TFieldConfig = undefined,
  TUnsubscribe = FieldExtensionUnsubscribeCore,
> = {
  type: TType;
  sourceField: SourceField<TValue, TFieldConfig> | undefined;

  /**
   * Called by extended field: on user interaction,
   * e.g. getting focus or click on button,
   * this function is called to set the field context.
   * @param sourceField: context for current search
   * @param setSourceFieldValue: callback for current source
   * @returns cancel subscription function
   */
  setSourceField: (
    sourceField: SourceField<TValue, TFieldConfig>,
    /**
     * Callback to extended field to update field value or clear field value,
     */
    setSourceFieldValue: SetSourceFieldValue<TSourceValue, TAction>,
    /**
     * Callback to notify that extension has been closed or allocated to
     * some other field
     */
    onExtensionDisconnected?: () => void,
  ) => TUnsubscribe | undefined | Promise<TUnsubscribe | undefined>;
  /**
   * Called by scene extension, e.g. entity search, to set/clear source field value
   */
  setSourceValue: SetSourceValueCore<TSourceValue, TAction>;
  hasChanges: boolean;
  setHasChanges: (hasChanges: boolean) => void;
  closeExtension: () => void;
};

export type FieldExtensionContext<
  // was LinkedValueSearchContextType
  TType extends FieldExtensionEnum,
  TValue,
  TSourceValue = TValue,
  TAction extends string = string,
  TFieldConfig = undefined,
> = Omit<
  FieldExtensionContextCore<
    TType,
    TValue,
    TSourceValue,
    TAction,
    TFieldConfig,
    FieldExtensionUnsubscribe
  >,
  'setSourceValue'
> & {
  setSourceValue: SetSourceValue<TSourceValue, TAction>;
};

export type SceneExtensionCore<
  TType extends FieldExtensionEnum,
  TValue = unknown,
  TSourceValue = TValue,
  TAction extends string = string,
  TFieldConfig = undefined,
> = {
  type: TType;
  panelConfig?: RightPanelConfig;
  fieldContext: FieldExtensionContextCore<
    TType,
    TValue,
    TSourceValue,
    TAction,
    TFieldConfig
  >;
  renderRightPanel: (onClose: () => Promise<void>) => React.ReactNode;
  // ToolBar?: React.FC;
};

export type SceneExtension<
  TType extends FieldExtensionEnum,
  TValue = unknown,
  TSourceValue = TValue,
  TAction extends string = string,
  TFieldConfig = undefined,
> = Omit<
  SceneExtensionCore<TType, TValue, TSourceValue, TAction, TFieldConfig>,
  'fieldContext' | 'renderRightPanel'
> & {
  fieldContext: FieldExtensionContext<
    TType,
    TValue,
    TSourceValue,
    TAction,
    TFieldConfig
  >;
  renderRightPanel: () => React.ReactNode;
  confirmCancelEditDialog?: React.FC;
  pleaseConfirmCancelEdit?: () => Promise<boolean>;
};

export type AnySceneExtensionCore = SceneExtensionCore<
  FieldExtensionEnum,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnySceneExtension = SceneExtension<
  FieldExtensionEnum,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>;
