import {useSelector} from 'react-redux';
import {AppState} from 'store/types';

const newExpressionIdsSelector = (state: AppState) =>
  state.metadataEdit.newExpressionIds;

export const useMetadataHasNewExpressions = (): boolean => {
  const newExpressionIds = useSelector(newExpressionIdsSelector);

  return newExpressionIds && newExpressionIds.length > 0;
};
