import {useSelector} from 'react-redux';
import {AppState} from 'store/types';
import {EditMode} from '../types';

const modesSelector = (state: AppState) => state.metadataEdit.editModes;

export const useEditMode = (
  id: string,
  key: string,
  defaultValue: EditMode,
): EditMode => {
  const status = useSelector(modesSelector)?.[id]?.[key] ?? defaultValue;
  return status;
};
