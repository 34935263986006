import {TaskType} from '../types';
import {Schemas} from '../dto.generated';

type DtoTaskType = Required<Schemas.TaskDto>['type'];

const taskTypeMap: {
  [key in DtoTaskType]: TaskType;
} = {
  METADATA_NEW: 'new',
  METADATA_READY_FOR_COMPLETION: 'pre',
  METADATA_CONTROL: 'control',
  METADATA_MANUAL_APPROVAL: 'mottak',
  ML_MANUAL_APPROVAL: 'ml',
  METADATA_IMPORT_CHANGE: 'change',
};

export const mapTaskTypeDto = (dto: DtoTaskType): TaskType =>
  // TODO: Should we handle missing
  dto === undefined ? 'change' : taskTypeMap[dto];
