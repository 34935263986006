import {Indexer} from 'types';

export type SubdirParams = Indexer<string | number> | string | number;

export type QueryParams = Indexer | string;

export const HTTP_STATUS_ABORTED = 299;

export type Violation = {
  field: string;
  message: string;
};

export type ProblemConstraintViolation = {
  type: string;
  title: string;
  detail?: string;
  status: number;
  violations: Violation[];
};

export type ErrorKeyAndMessage = {
  errorKey?: string;
  errorMessage?: string;
} & Indexer;

export type ErrorInfo = ProblemConstraintViolation | ErrorKeyAndMessage;

export class HttpError extends Error {
  constructor(
    public readonly status: number,
    public readonly url: string,
    public readonly message: string,
    public readonly errorKey?: string,
    public readonly info?: ErrorInfo,
    public readonly innerError?: Error,
  ) {
    super(message);
  }
}
