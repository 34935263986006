import React, {useMemo} from 'react';
import {assert} from 'assert-ts';
import {assertThesaurusNode} from 'services/thesaurus/functions';
import {Card, Layout, NavigationButton, Spacer} from 'components';
import {ColorPalette} from '../../../theme';
import {useThesaurusEditStateContext} from '../contexts';
import {GrepCategoryGuide} from './GrepCategoryGuide';
import {ThemaCategoryGuide} from './ThemaCategoryGuide';
import {ThesaurusNodeDetails} from './ThesaurusNodeDetails';
import {ThesaurusTree} from './ThesaurusTree';
import {TopLevelsGuide} from './TopLevelsGuide';

export const CategoryContainer: React.FC = () => {
  const {thesaurus, categoryId, focusNodeId, gotoTopLevels} =
    useThesaurusEditStateContext();
  const title = useMemo(() => {
    const item = assertThesaurusNode(
      assert(categoryId, 'CategoryContainer: categoryId expected'),
      thesaurus,
    );
    return item.label;
  }, [categoryId, thesaurus]);
  const focusNode = useMemo(() => {
    const node = focusNodeId
      ? assertThesaurusNode(focusNodeId, thesaurus)
      : undefined;
    return node && (node.note || node.definition || node.id !== categoryId)
      ? node
      : undefined;
  }, [categoryId, focusNodeId, thesaurus]);

  return (
    <Layout horizontal flex={1}>
      <Card colorx={ColorPalette.offWhite} flex={1}>
        <Card.Header>
          <NavigationButton
            title={title}
            icon={'ChevronLeft'}
            onClick={gotoTopLevels}
          />
        </Card.Header>
        <Card.Content>
          <ThesaurusTree />
        </Card.Content>
      </Card>
      <Spacer size={2} />
      {focusNode ? (
        <Layout flex={1}>
          <ThesaurusNodeDetails node={focusNode} />
        </Layout>
      ) : thesaurus.id === 'bokbasen' ? (
        <TopLevelsGuide thesaurusId={thesaurus.id} />
      ) : thesaurus.id === 'thema' ? (
        <Layout flex={1}>
          <ThemaCategoryGuide />
        </Layout>
      ) : thesaurus.id === 'grep' ? (
        <Layout flex={1}>
          <GrepCategoryGuide />
        </Layout>
      ) : // genreandform (should have definition pr category, hence, showing node details)
      null}
    </Layout>
  );
};
