import {useCallback, useMemo, useState} from 'react';
import {CollectionSubType, Concept} from 'types';
import {Collection} from 'api/types';
import {SaveCollectionFunc} from 'services/data/collection';
import {EditCollectionDialog} from '../components';

const createNewCollection = (type: CollectionSubType): Collection => {
  switch (type) {
    case Concept.series:
    case Concept.educationalSeries:
    case Concept.otherConnection:
    case Concept.publisherSeries:
      return {
        collectionType: type,
        titles: [{value: ''}],
      } as Collection;
  }
};

/**
 * Takes a save function, and returns functions to edit and add an collection, with a
 * corresponding edit dialog component.
 * @param onSave function to save new or edited collection
 * @returns
 */
export const useEditCollectionDialog = (onSave: SaveCollectionFunc) => {
  const [originalCollection, setOriginalCollection] = useState<Collection>(
    createNewCollection(Concept.series),
  );
  const [mode, setMode] = useState<'add' | 'edit'>('add');

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleAddCollection = useCallback((type: CollectionSubType) => {
    setOriginalCollection(createNewCollection(type));
    setMode('add');
    setDialogOpen(true);
  }, []);

  const handleEditCollection = useCallback((collection: Collection) => {
    setOriginalCollection(collection);
    setMode('edit');
    setDialogOpen(true);
  }, []);

  const handleSaveCollection = useCallback(
    (collection: Collection) => {
      return onSave(collection).then(savedCollection => {
        setDialogOpen(false);
        return savedCollection;
      });
    },
    [onSave],
  );

  const Dialog = useCallback(() => {
    return (
      <EditCollectionDialog
        isOpen={dialogOpen}
        mode={mode}
        originalCollection={originalCollection}
        onCancel={() => setDialogOpen(false)}
        onSave={handleSaveCollection}
      />
    );
  }, [dialogOpen, mode, originalCollection, handleSaveCollection]);

  return useMemo(() => {
    return {
      handleAddCollection,
      handleEditCollection,
      EditCollectionDialog: Dialog,
    };
  }, [Dialog, handleAddCollection, handleEditCollection]);
};
