import {assert} from 'assert-ts';
import {
  LinkedAgentMultiRole,
  UnnamedLinkedAgentMultiRole,
  UnverifiedLinkedAgentMultiRole,
  VerifiedLinkedAgentMultiRole,
} from 'schemaDefinition/types';
import {Schemas} from 'api/dto.generated';
import {
  isUnnamedLinkedAgentMultiRole,
  isVerifiedLinkedAgentMultiRole,
} from 'schemaDefinition';
import {mapAgentNameDto, mapToAgentNameDto} from './mapAgentNameDto';
import {mapAgentTypeDto, mapToAgentTypeDto} from './mapAgentTypeDto';

export function mapAgentRoleDto(
  dto:
    | Schemas.AgentRoleDto
    | Schemas.UnverifiedAgentRoleDto
    | Schemas.UnnamedAgentRoleDto,
): LinkedAgentMultiRole {
  const verifiedDto = dto as Schemas.AgentRoleDto;
  const unverifiedDto = dto as Schemas.UnverifiedAgentRoleDto;
  const unnamedDto = dto as Schemas.UnnamedAgentRoleDto;
  return unnamedDto.unnamedAgentRole
    ? mapUnnamedAgentRoleDto(unnamedDto)
    : verifiedDto.agentId
      ? mapVerifiedAgentRoleDto(verifiedDto)
      : mapUnverifiedAgentRoleDto(unverifiedDto);
}

export function mapVerifiedAgentRoleDto(
  dto: Schemas.AgentRoleDto,
): VerifiedLinkedAgentMultiRole {
  const {agentId, agentName, agentType, description, roles, ...rest} = dto;
  return {
    roles,
    link: {
      linkStatus: 'verified',
      entityId: agentId,
      type: mapAgentTypeDto(agentType),
      ...rest,
      agentName: mapAgentNameDto(
        assert(agentName, 'mapVerifiedAgentRoleDto: agentName expected', dto),
        description,
      ),
    },
  };
}

export function mapUnnamedAgentRoleDto({
  roles,
  agentName,
  unnamedAgentRole,
}: Schemas.UnnamedAgentRoleDto): UnnamedLinkedAgentMultiRole {
  return {
    roles,
    unnamedAgentRole,
    link: {
      linkStatus: 'unnamed',
      name: agentName,
    },
  };
}

export function mapUnverifiedAgentRoleDto({
  roles,
  ...link
}: Schemas.UnverifiedAgentRoleDto): UnverifiedLinkedAgentMultiRole {
  return {
    roles,
    link: {
      linkStatus: 'unverified',
      ...link,
    },
  };
}

export function mapToAgentRoleListDto(
  data: LinkedAgentMultiRole[] | undefined,
): (
  | Schemas.AgentRoleDto
  | Schemas.UnverifiedAgentRoleDto
  | Schemas.UnnamedAgentRoleDto
)[] {
  const nonEmpty = (data ?? []).filter(
    agent => (agent.link?.linkStatus ?? 'empty') !== 'empty',
  ) as (UnverifiedLinkedAgentMultiRole | VerifiedLinkedAgentMultiRole)[];
  return nonEmpty.map(mapToAgentRoleDto);
}

export function mapToAgentRoleDto(
  data: UnverifiedLinkedAgentMultiRole | VerifiedLinkedAgentMultiRole,
):
  | Schemas.AgentRoleDto
  | Schemas.UnverifiedAgentRoleDto
  | Schemas.UnnamedAgentRoleDto {
  return isVerifiedLinkedAgentMultiRole(data)
    ? mapToVerifiedAgentRoleDto(data)
    : isUnnamedLinkedAgentMultiRole(data)
      ? mapToUnnamedAgentRoleDto(data)
      : mapToUnverifiedAgentRoleDto(data);
}

export function mapToVerifiedAgentRoleDto(
  data: VerifiedLinkedAgentMultiRole,
): Schemas.AgentRoleDto {
  const {roles, link} = data;
  const {
    // Deconstruct linkStatus and possible name and ignore them
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    linkStatus,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    name,
    entityId,
    type,
    agentName,
    ...rest
  } = link as VerifiedLinkedAgentMultiRole['link'] &
    Pick<UnverifiedLinkedAgentMultiRole['link'], 'name'>;
  const dto: Schemas.AgentRoleDto = {
    ...rest,
    agentId: entityId,
    agentType: mapToAgentTypeDto(type),
    agentName: mapToAgentNameDto(agentName),
    roles,
  };
  const description = agentName.description;
  if (description) {
    dto.description = description;
  }

  return dto;
}

export function mapToUnverifiedAgentRoleDto(
  data: UnverifiedLinkedAgentMultiRole,
): Schemas.UnverifiedAgentRoleDto {
  const {roles, link} = data;
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    linkStatus,
    ...rest
  } = link;
  return {...rest, roles};
}

export function mapToUnnamedAgentRoleDto(
  data: UnnamedLinkedAgentMultiRole,
): Schemas.UnnamedAgentRoleDto {
  const {roles, link, unnamedAgentRole} = data;
  const {name} = link;
  return {
    roles,
    agentName: name,
    unnamedAgentRole,
  };
}
