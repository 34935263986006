import {assert} from 'assert-ts';
import {Concept, EntityMainType, EntitySubTypes} from 'types';
import {isEntityMainType} from './isEntityMainType';

export const getEntityMainType = (entity: Concept): EntityMainType => {
  return isEntityMainType(entity)
    ? entity
    : assert(
        Object.keys(EntitySubTypes).find(key =>
          (EntitySubTypes[key as EntityMainType] ?? []).includes(entity),
        ) as EntityMainType,
        'getMainEntityType',
        {entity},
      );
};
