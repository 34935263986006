import {assert} from 'assert-ts';
import {
  LinkTargetConcreteEntityType,
  LinkTargetConcreteEntityTypes,
} from 'types';
import {getConcreteEntityType} from './getConcreteEntityType';

export const getLinkTargetConcreteEntityType = (
  value: unknown,
): LinkTargetConcreteEntityType | undefined => {
  const type = getConcreteEntityType(value);
  if (!type) {
    return undefined;
  }

  if (
    LinkTargetConcreteEntityTypes.includes(type as LinkTargetConcreteEntityType)
  ) {
    return type as LinkTargetConcreteEntityType;
  }

  assert(false, 'getLinkTargetConcreteEntityType: unknown type', {type});
};
