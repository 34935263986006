import {assert} from 'assert-ts';
import {AgentSubType, Concept} from 'types';
import {isAgentSubType} from './isAgentSubType';

export const assertAgentSubType = (
  entity: Concept | undefined,
): AgentSubType => {
  assert(isAgentSubType(entity), `assertAgentSubType: invalid ${entity}`);
  return entity as AgentSubType;
};
