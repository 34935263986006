import {Concept} from 'types';
import {getSchemaForEdit} from 'schema/form/functions';
import {getSchema} from 'schemas/functions';
import {EntityContainer, Metadata} from '../types';
import {cleanInvisibleSchemaValues} from './cleanInvisibleSchemaValues';
import {getRelatedData} from './getRelatedData';

export const cleanEntityContainer = (
  entityContainer: EntityContainer,
  metadata: Metadata | undefined,
): EntityContainer => {
  if (entityContainer.type === Concept.changeRequest) {
    return entityContainer;
  }
  if (!metadata) {
    return entityContainer;
  }
  const schema = getSchema(entityContainer.type);

  const relatedData = getRelatedData(entityContainer, metadata);

  const newData = cleanInvisibleSchemaValues(
    getSchemaForEdit(schema),
    entityContainer.data,
    relatedData,
  );

  return {
    type: entityContainer.type,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data: newData as Data,
  };
};
