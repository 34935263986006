import {isNullish} from 'services/utils';

export const getScrollToValue = ({
  scrollTop,
  scrollHeight,
  contentHeight,
  itemCount,
  itemIndex,
}: {
  scrollTop?: number;
  scrollHeight?: number;
  contentHeight?: number;
  itemCount: number;
  itemIndex?: number;
}): number | undefined => {
  if (
    isNullish(itemIndex) ||
    isNullish(scrollTop) ||
    isNullish(scrollHeight) ||
    isNullish(contentHeight)
  ) {
    // console.log(
    //   'getScrollToValue: missing value',
    //   'scrollTop',
    //   scrollTop,
    //   'scrollHeight',
    //   scrollHeight,
    //   'contentHeight',
    //   contentHeight,
    //   'itemCount',
    //   itemCount,
    //   'itemIndex',
    //   itemIndex,
    // );
    return undefined;
  }

  if (contentHeight <= scrollHeight) {
    // console.log(
    //   'getScrollToValue: contentHeight <= scrollHeight',
    //   'scrollHeight',
    //   scrollHeight,
    //   'contentHeight',
    //   contentHeight,
    // );
    return undefined;
  }

  const itemHeight = contentHeight / itemCount;
  const itemTop = itemIndex * itemHeight;

  // Only scroll if not visible
  const scrollTo =
    itemTop < scrollTop || itemTop > scrollTop + scrollHeight
      ? itemTop < contentHeight - scrollHeight / 2
        ? itemTop - scrollHeight / 2
        : itemTop
      : undefined;

  // console.log(
  //   'scrollTop',
  //   scrollTop,
  //   'scrollHeight',
  //   scrollHeight,
  //   'contentHeight',
  //   contentHeight,
  //   'itemCount',
  //   itemCount,
  //   'itemIndex',
  //   itemIndex,
  //   ' => itemHeight',
  //   itemHeight,
  //   'itemTop',
  //   itemTop,
  //   ' => scrollTo',
  //   scrollTo,
  // );

  return scrollTo;
};
