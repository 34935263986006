import {MessageQueueStatus} from 'api';
import {SystemHealthStatus} from '../types';

export const getQueueHealth = (
  queue: MessageQueueStatus,
): SystemHealthStatus => {
  if (queue.length > 50) {
    return 'red';
  } else if (queue.length > 5) {
    return 'yellow';
  } else {
    return 'green';
  }
};
