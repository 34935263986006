import React, {useCallback, useMemo, useState} from 'react';
import {ColumnSet, ColumnWithLabel} from 'api/types';
import {useLocalization} from 'localization';
import {
  Dialog,
  FieldLabel,
  Layout,
  Spacer,
  TextField,
  useSnacks,
} from 'components';
import {ColumnItems, CurrentColumnsType} from '../types';
import {ColumnPicker} from '../components/ColumnPicker';

export const useEditColumnSetDialog = (
  allColumns: ColumnWithLabel[],
  onSave: (
    id: string,
    name: string,
    columns: string[],
    isPublic: boolean,
  ) => Promise<ColumnSet>,
  postSave: CurrentColumnsType['setCurrentColumns'],
) => {
  const {t} = useLocalization();
  const [open, setOpen] = useState<boolean>(false);
  const [columns, setColumns] = useState<ColumnItems>({
    pickableColumns: allColumns.map(c => c.name),
    pickedColumns: [],
  });
  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const {successSnack, errorSnack} = useSnacks();

  const handleOpen = useCallback(
    (columnSet: ColumnSet) => {
      setId(columnSet.id);
      setName(columnSet.name);
      setColumns({
        pickableColumns: allColumns
          .map(a => a.name)
          .filter(a => !columnSet.columns.includes(a)),
        pickedColumns: columnSet.columns,
      });
      setIsPublic(columnSet.isPublic);
      setOpen(true);
    },
    [allColumns],
  );

  const handleSave = useCallback(
    (columns: string[]) => {
      onSave(id, name, columns, isPublic)
        .then(() => {
          if (postSave) {
            postSave(columns);
          }
          successSnack('Kolonnesamling opprettet');
          setOpen(false);
        })
        .catch(error =>
          errorSnack('Opprettelse av kolonnesamling feilet', error),
        );
    },
    [errorSnack, id, isPublic, name, onSave, postSave, successSnack],
  );

  const Form = useMemo(() => {
    return (
      <Dialog
        isOpen={open}
        title={t('page.search.columnSet.edit.title')}
        body={
          <Layout flex={1}>
            <FieldLabel label={t('form.global.field.name.label')} required />
            <TextField
              type={'text'}
              name={'name'}
              value={name}
              onChange={event => setName(event.target.value)}
              required
            />
            <Spacer height={2} />
            <FieldLabel required label={t('data.field.columns.label')} />

            <ColumnPicker columns={columns} setColumns={setColumns} />
          </Layout>
        }
        dialogItems={[
          {
            itemType: 'action',
            title: t('general.save'),
            onClick: () => handleSave(columns.pickedColumns),
          },
          {
            itemType: 'action',
            type: 'reset',
            alignLeft: true,
            title: t('general.cancel'),
            onClick: () => setOpen(false),
          },
        ]}
      />
    );
  }, [open, t, name, columns, handleSave]);

  return {
    EditColumnSetDialog: Form,
    openEditColumnSetDialog: handleOpen,
  };
};
