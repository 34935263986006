import React from 'react';
import {NoProps} from 'types';
import {useLocalization} from 'localization';
import {ThesaurusesGate} from 'services/thesaurus';
import {Scene, Spacer} from 'components';
import {AdvancedSearch} from './AdvancedSearch';
import {
  AdvancedSearchContext,
  useAdvancedSearchContextProviderValue,
} from './context/AdvancedSearchContext';

type Props = NoProps;

export const AdvancedSearchScene: React.FC<Props> = () => {
  const {t} = useLocalization();

  const advancedSearchContextProviderValue =
    useAdvancedSearchContextProviderValue();

  return (
    <AdvancedSearchContext.Provider value={advancedSearchContextProviderValue}>
      <Scene browserTitle={t('appbar.advancedSearch')}>
        <Scene.Header title={t('appbar.advancedSearch')} />
        <Scene.Content>
          <Spacer size={2} />
          <ThesaurusesGate thesauruses={['thema']}>
            <AdvancedSearch />
          </ThesaurusesGate>
        </Scene.Content>
      </Scene>
    </AdvancedSearchContext.Provider>
  );
};
