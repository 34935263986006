import {useCallback} from 'react';
import {useLocalization} from 'localization';
import {useSaveOrCancelMetadata} from 'services/data/metadata/hooks/useSaveOrCancelMetadata';
import {useShortcut} from 'shortcuts';
import {
  ActionButton,
  FlexBox,
  Layout,
  Spacer,
  useAlertDialogAsPromise,
  useSnacks,
} from 'components';
import {useSceneExtensionsContext} from 'sceneExtensions';
import {MetadataSaveErrors} from './MetadataSaveErrors';
import {MetadataValidationErrors} from './MetadataValidationErrors';
import {WorkLockStatus} from './WorkLockStatus';

export const MetadataSaveOrCancelButtons: React.FC = () => {
  const {t} = useLocalization();
  const {hasChanges, hasNewExpressions, canSave, handleSave, handleCancel} =
    useSaveOrCancelMetadata();
  const {currentExtension} = useSceneExtensionsContext();

  const {AlertDialog, pleaseConfirm} = useAlertDialogAsPromise();
  const {successSnack} = useSnacks();

  const handleSaveWithConfirmation = useCallback((): Promise<void> => {
    return new Promise((resolve, reject) => {
      const validation = canSave();

      const {okTitle, cancelTitle} =
        validation.valid === 'valid'
          ? {
              okTitle: undefined,
              cancelTitle: undefined,
            }
          : validation.valid === 'warning'
            ? {
                okTitle: t('general.save'),
                cancelTitle: t('general.cancel'),
              }
            : {
                okTitle: t('general.ok'),
                cancelTitle: undefined,
              };

      // Show confirm save dialog with warnings, or inform about errors, if any
      (validation.valid === 'valid'
        ? Promise.resolve(true)
        : pleaseConfirm({
            title: t('page.metadata.saveValidationIssues.title'),
            body: <MetadataValidationErrors validation={validation} />,
            okTitle,
            cancelTitle,
          })
      )
        .then(confirmed => {
          // Save if warnings confirmed or valid
          if (validation.valid !== 'error' && confirmed) {
            handleSave(true).then(entitiesSaveStatus => {
              // All saved: just show a success snack
              const allSaved = entitiesSaveStatus.every(
                s => s.status.status === 'Saved',
              );
              const someSaved = entitiesSaveStatus.some(
                s => s.status.status === 'Saved',
              );
              if (allSaved) {
                successSnack(t('page.metadata.save.success'));
                return resolve();
              }

              return pleaseConfirm({
                title: someSaved
                  ? t('page.metadata.save.failed.someSaved.title')
                  : t('page.metadata.save.failed.noneSaved.title'),
                body: <MetadataSaveErrors statuses={entitiesSaveStatus} />,
              })
                .then(() => resolve())
                .catch(() => resolve());
            });
          } else {
            reject();
          }
        })
        .catch(resolve);
    });
  }, [canSave, handleSave, pleaseConfirm, successSnack, t]);

  const handleCancelWithCloseOfExtension = useCallback(() => {
    if (currentExtension) {
      currentExtension.fieldContext.closeExtension();
    }
    handleCancel();
  }, [currentExtension, handleCancel]);

  useShortcut('save', handleSaveWithConfirmation);
  useShortcut('undo', handleCancelWithCloseOfExtension);

  return (
    <FlexBox horizontal center right>
      <WorkLockStatus />
      {hasChanges ? (
        <>
          <Layout horizontal adjustCenter adjustRight flex={1}>
            <ActionButton
              title={t('page.metadata.cancel')}
              disabled={hasNewExpressions}
              background={'offWhite'}
              onClick={handleCancelWithCloseOfExtension}
            />
            <Spacer size={1} />
            <ActionButton
              data-cy={'save-metadata'}
              title={t('page.metadata.save')}
              disabled={hasNewExpressions}
              icon="Check"
              background={'green2'}
              showSpinnerOnClick
              onClick={handleSaveWithConfirmation}
            />
          </Layout>
          <AlertDialog />
        </>
      ) : null}
    </FlexBox>
  );
};
