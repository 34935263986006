import {assert} from 'assert-ts';
import {ExpressionV4} from 'api/types';
import {Schemas} from '../dto.generated';
import {mapAgentRoleDto, mapToAgentRoleListDto} from './mapAgentRoleDto';

export const mapExpressionBaseDto = (
  dto: Schemas.ExpressionBaseDto,
): ExpressionV4 => {
  const {id, created, modified, workId, agents, ...rest} = dto;
  return {
    id: assert(id, 'mapExpressionBaseDto: id expected', dto),
    created: created ?? 0, // assert(created, 'mapExpressionBaseDto: created expected', dto),
    modified: modified ?? 0, //  assert(modified, 'mapExpressionBaseDto: modified expected', dto),
    workId: assert(workId, 'mapExpressionBaseDto: workId expected', dto),
    ...rest,
    agents: (agents ?? []).map(mapAgentRoleDto),
  };
};

export const mapToExpressionBaseDto = (
  data: ExpressionV4,
): Schemas.ExpressionBaseDto => {
  const {agents, ...rest} = data;
  return {
    ...rest,
    agents: mapToAgentRoleListDto(agents),
  };
};
