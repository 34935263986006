import assert from 'assert-ts';
import {Column} from 'api/types';
import {Part, Schema} from 'schemaDefinition/types';

const migration: Array<{old: string; new: string}> = [
  {
    old: 'title',
    new: 'mainTitles',
  },
  {
    old: 'actor',
    new: 'agentName',
  },
  {
    old: 'publisherDate',
    new: 'publisherSalesDate',
  },
  {
    old: 'distributorDate',
    new: 'distributorSalesDate',
  },
];

const mapToNew = (old: string): string | undefined => {
  return migration.find(m => m.old === old)?.new;
};

export const mapDataColumnsToSchema = (
  schemaName: string,
  allColumns: Column[],
  currentColumns: string[],
): Schema => {
  return {
    name: schemaName,
    parts: currentColumns.map(cc => {
      let currentColumn = allColumns.find(ac => ac.name === cc);
      if (!currentColumn) {
        currentColumn = allColumns.find(ac => ac.name === mapToNew(cc));
      }

      return mapColumnPart(
        assert(
          currentColumn,
          `mapDataColumnsToSchema: Unexpected column: ${cc}`,
          {cc},
        ),
      );
    }),
  };
};

const mapColumnPart = (column: Column): Part => {
  switch (column.type) {
    case 'TEXT':
      if (column.codelistId) {
        return {
          type: 'codelist',
          codelistId: column.codelistId,
          name: column.name,
        };
      }

      if (column.name === 'subjects') {
        return {
          name: column.name,
          type: 'thesaurus',
          thesaurusId: 'bokbasen',
          cardinality: 'multiple',
        };
      }
      return {
        name: column.name,
        type: 'text',
      };
    case 'NUMBER':
      return {
        name: column.name,
        type: 'int',
      };
    case 'DATE':
    case 'DATETIME':
      return {
        name: column.name,
        type: 'date',
      };
    case 'YEAR':
      return {
        name: column.name,
        type: 'year',
      };
    case 'PRICE':
      return {
        name: column.name,
        type: 'text',
      };
    default:
      assert.soft(
        false,
        `mapDataColumnsToSchema: Unexpected column type: ${column.type}`,
        column,
      );
      return {
        name: column.name,
        type: 'text',
      };
  }
};
