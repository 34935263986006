import {PartValueRenderer} from 'schema/types';
import {useTextValue} from 'schema/preview/hooks';
import {CopyToClipboard} from '../../components';

export const Isbn: PartValueRenderer = ({value}) => {
  const TextValue = useTextValue();

  const firstValue =
    Array.isArray(value) && value.length > 0 ? value[0] : value;

  return typeof firstValue !== 'string' ? null : (
    <TextValue>
      {String(firstValue)}
      <CopyToClipboard text={String(firstValue)} size={'small'} />
    </TextValue>
  );
};
