import React, {useMemo} from 'react';
import {SxProps} from '@mui/material';
import {RelatedThesaurusNode} from 'api/types';
import {ColorPalette} from 'theme';
import {assertThesaurusNodeWithAncestors} from 'services/thesaurus';
import {FlexBox} from 'components';
import {useThesaurusEditStateContext} from '../contexts';
import {NodeLabel} from './NodeLabel';
import {ThesaurusNodeButton} from './ThesaurusNodeButton';
import {useLocalization} from 'localization';

type Props = {
  label: string;
  related?: RelatedThesaurusNode[];
};

const sxRelatedButton: SxProps = {
  borderRadius: 1,
  py: 1,
  px: 1,
  my: 0.5,
  background: ColorPalette.enhancedLightBeige,
};

export const ThesaurusNodeDetailsRelated: React.FC<Props> = ({
  label,
  related,
}) => {
  const {thesaurus, searchQuery, gotoFocusNodeId} =
    useThesaurusEditStateContext();
  const {tryT} = useLocalization();

  const relatedWithAncestors = useMemo(() => {
    return related && related.length > 0
      ? related.map(rel => assertThesaurusNodeWithAncestors(rel.id, thesaurus))
      : undefined;
  }, [related, thesaurus]);

  const tLabel = useMemo(() => {
    return tryT(`thesaurus.grep.details.${label}.label`) ?? label;
  }, [label, tryT])

  return relatedWithAncestors ? (
    <FlexBox>
      <NodeLabel label={tLabel} />
      {relatedWithAncestors.map(rel => (
        <ThesaurusNodeButton
          key={rel.node.id}
          node={rel}
          searchQuery={searchQuery}
          matchAllLevels
          noRightArrow
          sx={sxRelatedButton}
          onGotoNode={() => gotoFocusNodeId(rel.node.id)}
        />
      ))}
    </FlexBox>
  ) : null;
};
