import {Concept} from 'types';
import {CodeListRef, ManifestationV4, Product, ProductOwner} from 'api/types';
import {
  AlwaysIncludedSeparator,
  BooleanCondition,
  Condition,
  EqComparisonCondition,
  IncludesCondition,
  Part,
  RegexCondition,
  SchemaValueType,
  Separator,
  TypedSchema,
  TypedSchemaModifierMap,
  ValuePartModifier,
} from 'schemaDefinition/types';
import {isFeatureEnabled} from 'configuration';
import {
  MetadataModifierKey,
  StatusModifierKey,
  WorkBook,
  WorkMerchandise,
} from '../types';
import {validateLinkedImprintId} from './partValidations';

/**
 * Manifestation schema
 */

const pageCountRegex =
  '^(([1-9][0-9]*)|((?=[MDCLXVI])M*(C[MD]|D?C*)(X[CL]|L?X*)(I[XV]|V?I*)))(,\\s*(([1-9][0-9]*)|((?=[MDCLXVI])M*(C[MD]|D?C*)(X[CL]|L?X*)(I[XV]|V?I*))))*$';
const scaleRegex = '^[1-9][0-9]*$';
const runtimeRegex = [
  // hh:mm:ss
  '^[0-9]{1,3}:[0-5][0-9]:[0-5][0-9]$',
  // // hh:mm / mm:ss
  // '^[0-9]{1,2}:[0-5][0-9]$',
  // // mm / ss
  // '^[0-5]?[0-9]$',
];

// Groups of product forms with specific properties

const matchCodesRegex = (codes: string[]) =>
  `^${codes.map(c => `(${c})`).join('|')}$`;

const dimWeightPagesAndColorForms = [
  CodeListRef.PRODUCT_FORM['Innbundet'],
  CodeListRef.PRODUCT_FORM['Heftet'],
  CodeListRef.PRODUCT_FORM['Fleksibind'],
  CodeListRef.PRODUCT_FORM['Spiralbundet'],
  CodeListRef.PRODUCT_FORM['Pappbok'],
  CodeListRef.PRODUCT_FORM['Løse ark'],
  CodeListRef.PRODUCT_FORM['Badebok'],
  CodeListRef.PRODUCT_FORM['Tekstilbok'],

  // CodeListRef.PRODUCT_FORM['Bok'],
  // CodeListRef.PRODUCT_FORM['Stiftet'],
  // CodeListRef.PRODUCT_FORM['Praktinnbinding'],
  // CodeListRef.PRODUCT_FORM['Eksperimentell innbinding'],
  // CodeListRef.PRODUCT_FORM['Annet bokformat'],
];

const dimAndWeightForms = [
  CodeListRef.PRODUCT_FORM['Mappe eller omslag'],
  CodeListRef.PRODUCT_FORM['Plansje'],
  CodeListRef.PRODUCT_FORM['Diskett'],
  CodeListRef.PRODUCT_FORM['Andre trykte artikler'],
  CodeListRef.PRODUCT_FORM['Dagbok'],
  CodeListRef.PRODUCT_FORM['Postkort'],
  CodeListRef.PRODUCT_FORM['Diverse trykk'],
  CodeListRef.PRODUCT_FORM['Kalender'],
  CodeListRef.PRODUCT_FORM['Plakat'],
  CodeListRef.PRODUCT_FORM['Notisbok / skrivebok'],
  CodeListRef.PRODUCT_FORM['Diskeske- fylt'],
  CodeListRef.PRODUCT_FORM['Globus'],
  CodeListRef.PRODUCT_FORM['Andre klær'],
  CodeListRef.PRODUCT_FORM['Lydbokspiller'],
  CodeListRef.PRODUCT_FORM['Lesebrett'],
  CodeListRef.PRODUCT_FORM['Leke'],
  CodeListRef.PRODUCT_FORM['Puslespill'],
  CodeListRef.PRODUCT_FORM['Spill'],
  CodeListRef.PRODUCT_FORM['Andre varer'],
  CodeListRef.PRODUCT_FORM['Myk leke'],
  CodeListRef.PRODUCT_FORM['Servise'],
  CodeListRef.PRODUCT_FORM['Paraply'],
  CodeListRef.PRODUCT_FORM['Handlenett'],
  CodeListRef.PRODUCT_FORM['Varer, uspesifisert'],
  CodeListRef.PRODUCT_FORM['Pakke (i eske)'],
];

const dimWeightAndScaleForms = [
  // Starting with B
  'B.',
  // Or:
  CodeListRef.PRODUCT_FORM['Kart, falset'],
  CodeListRef.PRODUCT_FORM['Kart, rullet'],
];

// All product forms with any code starting with 'S'
const dimWeightAndColourForms = ['S.'];

const dimWeightAndPagesForms = [
  CodeListRef.PRODUCT_FORM['Lysark'],
  CodeListRef.PRODUCT_FORM['Film eller lysark'],
];

const dimWeightAndRuntimeForms = [
  CodeListRef.PRODUCT_FORM['MP3-spiller med innhold'],
  CodeListRef.PRODUCT_FORM['Video'],
  CodeListRef.PRODUCT_FORM['Annet digitalt format (på fysisk bærer)'],
  // TODO: Legg til når vi har fått inn koder for lyd og video
  // CodeListRef.PRODUCT_FORM['CD-ROM'],
  // CodeListRef.PRODUCT_FORM['DVD-ROM'],
];

const fileFormatVersionForms = [
  CodeListRef.PRODUCT_FORM_DETAIL['EPUB'],
  CodeListRef.PRODUCT_FORM_DETAIL['OEB'],
];

const pagesForms = [CodeListRef.PRODUCT_FORM.ED];

const noForms = [CodeListRef.PRODUCT_FORM.EB];

const runtimeForms = [
  CodeListRef.PRODUCT_FORM['Lydfil'],
  CodeListRef.PRODUCT_FORM['CD (lyd)'],
  CodeListRef.PRODUCT_FORM['Lydbånd'],
  CodeListRef.PRODUCT_FORM['Lydkassett'],
  CodeListRef.PRODUCT_FORM['Diskett'],
  CodeListRef.PRODUCT_FORM['Blu-ray'],
  CodeListRef.PRODUCT_FORM['DVD video'],
  CodeListRef.PRODUCT_FORM['Film'],
];

const statementOfResponsibilityForms = ['^B', CodeListRef.PRODUCT_FORM['ED']];
const isAutoGenerateStatementOfResponsibility: BooleanCondition = {
  arg: {$ref: '#autoGenerateStatementOfResponsibility'},
};

const showStatementOfResponsibilityForm: RegexCondition = {
  arg: {$ref: '#productForm'},
  regex: statementOfResponsibilityForms,
  default: false,
};

const isBook: EqComparisonCondition = {
  $eq: [{$ref: '^work.type'}, CodeListRef.WORK_TYPE.Bok],
};

const isMerch: EqComparisonCondition = {
  $eq: [{$ref: '^work.type'}, CodeListRef.WORK_TYPE.Vare],
};

const isMapCB: RegexCondition = {
  regex: CodeListRef.PRODUCT_FORM['Kart, falset'],
  arg: {$ref: '/productForm'},
  default: true,
};
const isMapCC: RegexCondition = {
  regex: CodeListRef.PRODUCT_FORM['Kart, rullet'],
  arg: {$ref: '/productForm'},
  default: true,
};

const isSchoolBook: IncludesCondition = {
  $includes: [
    {$ref: '^work.literatureType'},
    CodeListRef.LITERATURE_TYPE['Skolebøker'],
  ],
  default: false,
};

const isLydfil: EqComparisonCondition = {
  $eq: [{$ref: '/productForm'}, CodeListRef.PRODUCT_FORM.Lydfil],
};
const isEbook: EqComparisonCondition = {
  $eq: [{$ref: '/productForm'}, CodeListRef.PRODUCT_FORM.Ebok],
};

const dimWeightPagesAndColorFormsRegex = matchCodesRegex(
  dimWeightPagesAndColorForms,
);
const dimAndWeightFormsRegex = matchCodesRegex(dimAndWeightForms);
const dimWeightAndColourFormsRegex = matchCodesRegex(dimWeightAndColourForms);
const dimWeightAndScaleFormsRegex = matchCodesRegex(dimWeightAndScaleForms);
const dimWeightAndPagesFormsRegex = matchCodesRegex(dimWeightAndPagesForms);
const dimWeightAndRuntimeFormsRegex = matchCodesRegex(dimWeightAndRuntimeForms);
const pagesFormsRegex = matchCodesRegex(pagesForms);
const runtimeFormsRegex = matchCodesRegex(runtimeForms);
const noFormsRegex = matchCodesRegex(noForms);

type ManifestationPart<
  TKey extends keyof ManifestationV4 = keyof ManifestationV4,
> = Part<SchemaValueType, TKey>;

const pageParts: ManifestationPart[] = [
  {
    type: 'text',
    name: 'pageCount',
    validation: {regex: pageCountRegex, default: true},
  },
  ...(isFeatureEnabled('unnumberedPages')
    ? [
        {
          type: 'bool',
          name: 'unnumberedPages',
        } as ManifestationPart,
      ]
    : []),
];

const runtimePart: ManifestationPart = {
  type: 'text',
  name: 'runtimeSeconds',
  validation: {regex: runtimeRegex, default: true},
};
const scalePart: ManifestationPart = {
  type: 'text',
  name: 'scale',
  labelKey: 'scale',
  prefix: '1:',
  cardinality: 'multiple',
  validation: {regex: scaleRegex, default: true},
};
const colourPart: ManifestationPart = {
  type: 'codelist',
  name: 'colour',
  codelistId: 'COLOUR',
};

export const mainTitlesPart: ManifestationPart<'mainTitles'> = {
  type: 'schema',
  name: 'mainTitles',
  cardinality: 'multiple',
  required: true,
  compare: 'subValues',
  parts: [
    {
      type: 'text',
      name: 'value',
      labelKey: 'mainTitlesValue',
      required: true,
    },
    {
      type: 'schema',
      name: 'subTitles',
      cardinality: 'multiple',
      parts: [
        {
          type: 'text',
          name: 'value',
          labelKey: 'subTitlesValue',
          required: true,
        },
        {
          name: 'parallelTitles',
          labelKey: 'parallelSubTitles',
          type: 'text',
          cardinality: 'multiple',
        },
      ],
    },
    {
      name: 'parallelTitles',
      type: 'text',
      cardinality: 'multiple',
    },
  ],
};

const productOwnerSchema: TypedSchema<ProductOwner> = {
  name: 'productOwners',
  parts: [
    [
      {
        type: 'text',
        name: 'code',
        labelKey: 'productOwner.code',
        readonly: true,
      },
      {
        type: 'text',
        name: 'name',
        labelKey: 'productOwner.name',
        readonly: true,
      },
    ],
  ],
};

const productSchema: TypedSchema<Product> = {
  name: 'products',
  parts: [
    [
      {
        type: 'text',
        name: 'owner',
        labelKey: 'product.owner.code',
        readonly: true,
      },
      {
        type: 'text',
        name: 'distributor',
        labelKey: 'product.distributor.code',
        readonly: true,
      },
    ],
  ],
};

const isbnPart: ManifestationPart = {
  type: 'text',
  name: 'isbn',
  readonly: true,
  actions: ['copy'],
};

const ismnPart: ManifestationPart = {
  type: 'text',
  name: 'ismn',
  readonly: true,
  actions: ['copy'],
};

const eanPart: ManifestationPart = {
  type: 'text',
  name: 'ean',
  readonly: true,
  validation: [
    {regex: '^[0-9]{13}$', default: true},
    {
      $or: [
        {arg: {$ref: '/ean'}}, // ean (this) is set, or
        {arg: {$ref: '/isbn'}}, // isbn is set
      ],
    },
  ],
};

const contentReceivedDatePart: ManifestationPart = {
  type: 'date',
  name: 'contentReceivedDate',
  readonly: true,
};

const hasIsbn: Condition = {
  arg: {$ref: '/isbn'},
};
const hasIsmn: Condition = {
  arg: {$ref: '/ismn'},
};
const hasBothIsbnAndIsbn: Condition[] = [hasIsbn, hasIsmn];

const isTextOrTextIllustrated: RegexCondition = {
  arg: {$ref: '^expression.expressionFormat'},
  regex: [
    CodeListRef.EXPRESSION_FORMAT.TEXT,
    CodeListRef.EXPRESSION_FORMAT.ILLUSTRATED_TEXT,
  ],
  default: false,
};

const publishedYearNotPrintedInBookPartExpand: ManifestationPart = {
  type: 'expand',
  role: 'showMissingPublishedYearWhenText',
  when: [
    {
      condition: isTextOrTextIllustrated,
      parts: [{type: 'bool', name: 'publishedYearNotPrintedInBook'}],
    },
  ],
};

const editionAndPublishedYearParts: ManifestationPart[] = [
  {
    type: 'int',
    name: 'edition',
    validation: {
      min: 1,
      max: 99999,
      messageKey: 'invalid_number',
    },
  },
  {type: 'year', name: 'publishedYear', required: true},
];

export const manifestationDataSchema: TypedSchema<ManifestationV4> = {
  name: 'manifestation',
  key: 'manifestation',
  parts: [
    [
      {
        type: 'expand',
        role: 'showAndLayoutIsbnIsmnAndEditionAndPublishedYear',
        when: [
          {
            // Both isbn and ismn is set, show both, with edition and published year on separate line
            condition: hasBothIsbnAndIsbn,
            parts: [[isbnPart, ismnPart], editionAndPublishedYearParts],
          },
          {
            // When ismn is set, show ismn and edition and published year on same line
            condition: hasIsmn,
            parts: [[ismnPart, ...editionAndPublishedYearParts]],
          },
        ],
        // Otherwise show isbn and edition and published year on same line
        default: [[isbnPart, ...editionAndPublishedYearParts]],
      },
      ...(isFeatureEnabled('publishedYearNotPrintedInBook')
        ? [publishedYearNotPrintedInBookPartExpand]
        : []),
    ],
    [eanPart, contentReceivedDatePart],
    {
      type: 'text',
      name: 'wrongIsbn',
      cardinality: 'multiple',
      validation: {regex: '^[0-9]{13}$', default: true},
    },
    {
      type: 'schema',
      name: 'productOwners',
      labelKey: 'productOwners',
      parts: productOwnerSchema.parts,
      readonly: true,
      cardinality: 'multiple',
    },
    {
      type: 'schema',
      name: 'products',
      labelKey: 'products',
      parts: productSchema.parts,
      readonly: true,
      cardinality: 'multiple',
    },
    mainTitlesPart,
    {
      type: 'schema',
      name: 'otherTitles',
      parts: [
        [
          {
            type: 'text',
            name: 'value',
            labelKey: 'otherTitle.value',
            required: true,
          },
          {
            type: 'codelist',
            name: 'type',
            labelKey: 'otherTitle.type',
            codelistId: 'TITLE_TYPE',
            required: true,
          },
        ],
      ],
      cardinality: 'multiple',
    },
    ...((isFeatureEnabled('statementOfResponsibility')
      ? [
          {
            type: 'bool',
            name: 'autoGenerateStatementOfResponsibility',
            labelKey: 'autoGenerateStatementOfResponsibility',
            default: true,
          },
          {
            type: 'expand',
            role: 'showStatementOfResponsibilityIfCorrentProductForm',
            when: [
              {
                condition: [
                  {
                    op: 'not',
                    arg: isAutoGenerateStatementOfResponsibility,
                  },
                  showStatementOfResponsibilityForm,
                ],
                parts: [
                  Separator('line', 'line'),
                  {
                    type: 'textarea',
                    name: 'statementOfResponsibility',
                    labelKey: 'statementOfResponsibility',
                  },
                ],
              },
            ],
          },
        ]
      : []) as TypedSchema<ManifestationV4>['parts']),
    Separator('line', 'line'),
    {
      type: 'schema',
      name: 'notes',
      labelKey: 'notes',
      parts: [
        {
          type: 'textarea',
          name: 'generalNote',
          labelKey: 'notes.generalNote',
          cardinality: 'multiple',
        },
        {
          type: 'textarea',
          name: 'editionNote',
          labelKey: 'notes.editionNote',
          cardinality: 'multiple',
        },
        {
          type: 'codelist',
          name: 'editionType',
          labelKey: 'notes.editionType',
          codelistId: 'EDITION_TYPE',
          cardinality: 'multiple',
        },
        {
          type: 'expand',
          role: 'showAttachmentIfBook',
          when: [
            {
              condition: isBook,
              parts: [
                {
                  type: 'text',
                  name: 'attachment',
                  labelKey: 'notes.attachment',
                  cardinality: 'multiple',
                },
              ],
            },
          ],
        },
      ],
    },
    Separator('line', 'line'),
    {
      type: 'linkedAgent',
      name: 'imprints',
      cardinality: 'multiple',
      entityRole: 'publisher',
      entitySubtypes: [Concept.publisher],
      roleCodelistId: 'manifestation_role_type',
      validation: [validateLinkedImprintId],
    },
    Separator('line', 'line'),
    {
      type: 'linkedLiterary',
      name: 'links',
      labelKey: 'publisherSeries',
      cardinality: 'multiple',
      roleCodelistId: 'manifestation.linkedRole',
      linkRole: 'partOf.publisherSeries',
    },
    Separator('line', 'line'),
    {
      type: 'expand',
      role: 'descriptionContent',
      when: [
        {
          condition: {arg: {$ref: '#descriptionContent'}},
          parts: [
            {
              type: 'html',
              name: 'descriptionContent',
              labelKey: 'descriptionContent',
              readonly: true,
            },
            AlwaysIncludedSeparator('line', 'line'),
          ],
        },
      ],
    },
    {
      type: 'codelist',
      name: 'productForm',
      codelistId: 'PRODUCT_FORM',
      validation: {
        arg: {$ref: '^work.type'},
      },
    },
    {
      type: 'codelist',
      name: 'productFormDetail',
      cardinality: 'multiple',
      codelistId: 'PRODUCT_FORM_DETAIL',
      // Validation for productformdetail is added by schema modifier (see getManifestationBasedModifier)
    },
    {
      type: 'expand',
      role: 'fileFormatVersionRole',
      when: [
        {
          condition: {
            arg: {$ref: '/productFormDetail'},
            regex: fileFormatVersionForms,
            default: false,
          },
          parts: [
            {
              type: 'codelist',
              name: 'fileFormatVersion',
              codelistId: 'FILEFORMAT_VERSION',
            },
          ],
        },
      ],
    },
    {
      type: 'expand',
      role: 'basedOnIsbnForSchoolBooks',
      when: [
        {
          condition: [
            // Work litterature type is Lærebøker (høyere utd.)
            {
              regex: CodeListRef.LITERATURE_TYPE['Lærebøker (høyere utd.)'],
              arg: {$ref: '^work.literatureType'},
              default: false,
            },
            // and manifestation product form is Digitalt format, nedlastbart (download)
            {
              regex:
                CodeListRef.PRODUCT_FORM[
                  'Digitalt format, nedlastbart (download)'
                ],
              arg: {$ref: '/productForm'},
              default: false,
            },
          ],
          parts: [
            {
              type: 'codelist',
              name: 'basedOnIsbn',
              codelistId: 'expression_manifestation',
            },
          ],
        },
      ],
    },
    {
      type: 'codelist',
      name: 'productGroup',
      codelistId: 'PRODUCT_GROUP',
      showCode: true,
    },
    {
      type: 'codelist',
      name: 'bookGroup',
      codelistId: 'BOOK_GROUP',
      required: 'should',
      showCode: true,
      readonly: true,
    },
    {
      type: 'expand',
      role: 'visUndervisningsMateriellForSkolebøker',
      when: [
        {
          condition: isSchoolBook,
          parts: [
            {
              type: 'codelist',
              name: 'teachingMaterials',
              codelistId: 'TEACHING_MATERIALS',
              cardinality: 'multiple',
            },
          ],
        },
      ],
    },
    Separator('line', 'line'),
    {
      type: 'expand',
      role: 'formatSpecificProps',
      when: [
        {
          condition: {
            arg: {$ref: '/productForm'},
            regex: dimWeightPagesAndColorFormsRegex,
            default: false,
          },
          parts: [pageParts, [colourPart], [scalePart]],
        },
        {
          condition: {
            arg: {$ref: '/productForm'},
            regex: dimAndWeightFormsRegex,
            default: false,
          },
          parts: [[]],
        },
        {
          condition: {
            arg: {$ref: '/productForm'},
            regex: dimWeightAndColourFormsRegex,
            default: false,
          },
          parts: [[colourPart]],
        },
        {
          condition: {
            arg: {$ref: '/productForm'},
            regex: dimWeightAndScaleFormsRegex,
            default: false,
          },
          parts: [[scalePart]],
        },
        {
          condition: {
            arg: {$ref: '/productForm'},
            regex: dimWeightAndPagesFormsRegex,
            default: false,
          },
          parts: [pageParts],
        },
        {
          condition: {
            arg: {$ref: '/productForm'},
            regex: dimWeightAndRuntimeFormsRegex,
            default: false,
          },
          parts: [[runtimePart]],
        },
        {
          condition: {
            arg: {$ref: '/productForm'},
            regex: pagesFormsRegex,
            default: false,
          },
          parts: [pageParts],
        },
        {
          condition: {
            arg: {$ref: '/productForm'},
            regex: noFormsRegex,
            default: false,
          },
          parts: [],
        },
        {
          condition: {
            arg: {$ref: '/productForm'},
            regex: runtimeFormsRegex,
            default: false,
          },
          parts: [runtimePart],
        },
      ],
      default: [pageParts, [runtimePart, scalePart, colourPart]],
    },
    {
      type: 'expand',
      role: 'showQuantityIfMerch',
      when: [
        {
          condition: isMerch,
          parts: [
            {
              type: 'int',
              name: 'packQuantity',
              labelKey: 'packQuantity',
            },
          ],
        },
      ],
    },
  ],
};

export const manifestationSchemaModifiers: TypedSchemaModifierMap<
  ManifestationV4,
  MetadataModifierKey
> = {
  [`${WorkMerchandise}.${StatusModifierKey.precat}`]: [
    {
      name: 'imprints',
      required: 'should',
    },
    {
      name: 'productForm',
      required: 'should',
    },
    {
      name: 'productGroup',
      required: true,
    },
    {
      name: 'packQuantity',
      required: true,
    },
  ],
  [`${WorkMerchandise}.${StatusModifierKey.cat}`]: [
    {
      name: 'packQuantity',
      required: true,
    },
    {
      name: 'basedOnIsbn',
      required: 'should',
    },
    {
      name: 'imprints',
      required: 'should',
    },
    {
      name: 'productForm',
      required: true,
    },
    {
      name: 'teachingMaterials',
      required: 'should',
    },
    {
      name: 'dimensions',
      required: 'should',
      partContext: 'expand.when',
    },
    {
      name: 'weight',
      required: 'should',
      partContext: 'expand.when',
    },
    {
      name: 'pageCount',
      required: 'should',
      partContext: 'expand.when',
    },
    {
      name: 'runtimeSeconds',
      required: 'should',
      partContext: 'expand.when',
    },
    {
      name: 'scale',
      required: {
        should: {
          op: 'or',
          arg: [isMapCB, isMapCC],
        },
      },
      partContext: 'expand.when',
    },
    {
      name: 'packQuantity',
      required: true,
    },
  ],
  [`${WorkBook}.${StatusModifierKey.precat}`]: [
    {
      name: 'imprints',
      required: 'should',
    },
    {
      name: 'productForm',
      required: 'should',
    },
    {
      name: 'productGroup',
      required: true,
    },
    {
      name: 'productFormDetail',
      required: {
        should: {
          op: 'or',
          arg: [isLydfil, isEbook],
        },
      },
    },
  ],
  [`${WorkBook}.${StatusModifierKey.cat}`]: [
    {
      name: 'basedOnIsbn',
      required: 'should',
    },
    {
      name: 'imprints',
      required: true,
    },
    {
      name: 'productForm',
      required: true,
    },
    {
      name: 'teachingMaterials',
      required: 'should',
    },
    {
      name: 'dimensions',
      required: 'should',
      partContext: 'expand.when',
    },
    {
      name: 'weight',
      required: 'should',
      partContext: 'expand.when',
    },
    {
      name: 'pageCount',
      required: 'should',
      partContext: 'expand.when',
    },
    {
      name: 'runtimeSeconds',
      required: 'should',
      partContext: 'expand.when',
    },
    {
      name: 'scale',
      required: {
        should: {
          op: 'or',
          arg: [isMapCB, isMapCC],
        },
      },
      partContext: 'expand.when',
    },
    {
      name: 'productFormDetail',
      required: {
        required: {
          op: 'or',
          arg: [isLydfil, isEbook],
        },
      },
    },
  ],
};

/**
 * Used by getManifestationBasedModifier, but defined here to keep it close to schema definition.
 *  If bookGroup already has a value in a saved state, it should not be editable.
 */
export const EditableBookGroupModifier: ValuePartModifier<'bookGroup'> = {
  name: 'bookGroup',
  readonly: false,
};
