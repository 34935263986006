import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const DoubleChevronLeftIcon: React.FC<IconProps> = (
  props: IconProps,
) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M5.58996 12L10.29 7.29004L11.71 8.71004L8.41 12L11.71 15.29L10.29 16.71L5.58996 12Z" />
      <path d="M11.58996 12L16.29 7.29004L17.71 8.71004L14.41 12L17.71 15.29L16.29 16.71L11.58996 12Z" />
    </SvgIcon>
  );
};
