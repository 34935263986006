import React from 'react';
import {Tooltip} from '@mui/material';
import {AvailabilityStatus as AvailabilityStatusType} from 'api/types';
import {PartValueRenderer} from 'schema/types';
import {
  AvailabilityStatusIcon,
  Layout,
  useAvailabilityStatusTips,
} from 'components';

export const AvailabilityStatus: PartValueRenderer = ({value}) => {
  const availabilityStatusTip = useAvailabilityStatusTips();

  const availabilityStatus =
    typeof value === 'string' &&
    Object.keys(availabilityStatusTip).includes(value)
      ? (value as AvailabilityStatusType)
      : undefined;

  return (
    <Layout flex={1} adjustLeft pl={2} horizontal kind="primary">
      {availabilityStatus ? (
        <Tooltip title={availabilityStatusTip[availabilityStatus]}>
          <span>
            <AvailabilityStatusIcon availabilityStatus={availabilityStatus} />
          </span>
        </Tooltip>
      ) : null}
    </Layout>
  );
};
