import {createContext, useCallback, useContext, useState} from 'react';
import {assert} from 'assert-ts';
import {moveItem, valueAsArrayOf} from 'services/utils';
import {CodeListFieldProps} from '../../types';
import {InputState, InputStateEnum} from '../types';

export type CodeInputContextType = {
  state: InputState;
  handleKeyCapture?: (event: React.KeyboardEvent) => void;
};

export const CodeInputContext = createContext<CodeInputContextType | undefined>(
  undefined,
);

const getNewIndex = (
  indexAtCode: string,
  codes: string[],
  inc: 1 | -1,
): number => {
  const indexAt = codes.indexOf(indexAtCode);
  const newIndex = indexAt + inc;
  const inRangeIndex =
    newIndex < 0
      ? 0
      : newIndex > codes.length - 1
      ? codes.length - 1
      : newIndex;
  return inRangeIndex;
};

const getNewCode = (
  indexAtCode: string,
  codes: string[],
  inc: 1 | -1,
): string => {
  return codes[getNewIndex(indexAtCode, codes, inc)];
};

export const useCodeInputContextProviderValue = (
  value: CodeListFieldProps['value'],
  cardinality: CodeListFieldProps['cardinality'],
  onChange: CodeListFieldProps['onChange'],
): CodeInputContextType => {
  const [state, setState] = useState<InputState>({
    state: InputStateEnum.inputFocus,
  });

  const handleMove = useCallback(
    (codes: string[], fromIdx: number, toIdx: number) => {
      const newCodes = moveItem(codes, fromIdx, toIdx);
      onChange && onChange(newCodes);
    },
    [onChange],
  );

  const handleKeyCapture = useCallback(
    (event: React.KeyboardEvent) => {
      const codes = valueAsArrayOf<string>(value);

      if (!codes || codes.length === 0) {
        return;
      }

      if (
        event.key === 'ArrowRight' ||
        event.key === 'ArrowLeft'
        // &&
        // event.shiftKey
      ) {
        event.preventDefault();
        event.stopPropagation();

        // Focus item and shift-left/right, reorder the items
        if (state.state === InputStateEnum.itemFocus && event.shiftKey) {
          const oldIndex = codes.indexOf(state.focusItemCode);
          const newIndex = getNewIndex(
            state.focusItemCode,
            codes,
            event.key === 'ArrowRight' ? 1 : -1,
          );
          if (newIndex !== oldIndex) {
            handleMove(codes, oldIndex, newIndex);
          }
        } else {
          setState(prevState => {
            // Left: If input is focused, focus the last item, else focus the previous item
            if (event.key === 'ArrowLeft') {
              return prevState.state === InputStateEnum.inputFocus
                ? {
                    state: InputStateEnum.itemFocus,
                    focusItemCode: codes[codes.length - 1],
                  }
                : {
                    state: InputStateEnum.itemFocus,
                    focusItemCode: getNewCode(
                      prevState.focusItemCode,
                      codes,
                      -1,
                    ),
                  };
            }
            // Right:
            // - Item focus: if last item is focused, focus the input, else focus the next item
            // - Input focus: keep input focused
            else {
              if (prevState.state === InputStateEnum.itemFocus) {
                return prevState.focusItemCode === codes[codes.length - 1]
                  ? {state: InputStateEnum.inputFocus}
                  : {
                      state: InputStateEnum.itemFocus,
                      focusItemCode: getNewCode(
                        prevState.focusItemCode,
                        codes,
                        1,
                      ),
                    };
              }

              // Keep input focus state
              return prevState;
            }
          });
        }

        // console.log('Input: key captured', event);
        // event.stopImmediatePropagation();

        // const offset = keyEvent.key === 'ArrowRight' ? 1 : -1;
        // onMove(index, index + offset);
      }
      // Allow shift in item focus state to support shift-left/right
      else if (
        event.key === 'Shift' &&
        state.state === InputStateEnum.itemFocus
      ) {
        event.preventDefault();
        event.stopPropagation();
        // console.log('Input: key shift captured', event);
      } else {
        // console.log('Input: key not captured', event);
        setState({state: InputStateEnum.inputFocus});
      }
    },
    [handleMove, state.focusItemCode, state.state, value],
  );

  const hasMultipleCodes = (valueAsArrayOf<string>(value)?.length ?? 0) > 1;

  return {
    handleKeyCapture: hasMultipleCodes ? handleKeyCapture : undefined,
    state,
  };
};

export const useCodeInputContext = (): CodeInputContextType => {
  return assert(
    useContext(CodeInputContext),
    'CodeInputContext: context expected',
  );
};
