import {Concept} from 'types';
import {ExpressionV4} from 'api/types';
import {PreviewConfiguration} from 'schema/types';
import {SchemaReference} from 'schemas/types';
import {Layout, Text} from 'components';
import {useSchema} from 'schemas/hooks';
import {ReadonlyExpressionCard} from 'widgets/metadata/readonlyExpressionCard/ReadonlyExpressionCard';
import {useExpressionTitle} from '../ExpressionCard/hooks/useExpressionTitle';

type Props = {
  expression: ExpressionV4;
};

const schemaRef: SchemaReference = Concept.expression;

const configuration: PreviewConfiguration = {
  layout: {
    partSpacing: 0.25,
  },
};

export const ExpressionItem: React.FC<Props> = ({expression}) => {
  const schema = useSchema(schemaRef);

  const title = useExpressionTitle(expression, undefined);

  return (
    <Layout py={0.5}>
      <Text variant="h4">{title}</Text>
      <ReadonlyExpressionCard
        expression={expression}
        schema={schema}
        configuration={configuration}
      />
    </Layout>
  );
};
