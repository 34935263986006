import {Feature} from '../types';
import {Configuration} from '../configuration';

export const isFeatureEnabled = (feature: Feature): boolean => {
  const env = Configuration.env;
  const toggle = Configuration.featureToggles[feature];

  //   t \ e | 'local' | 'dev' | 'stage' | 'prod'
  // 'off'   |   off   |  off  |  off    |  off
  // 'local' |   on    |  off  |  off    |  off
  // 'dev'   |   on    |  on   |  off    |  off
  // 'stage' |   on    |  on   |  on     |  off
  // 'prod'  |   on    |  on   |  on     |  on

  switch (toggle) {
    case 'off':
      return false;
    case 'local':
      return env === 'local';
    case 'dev':
      return env === 'local' || env === 'dev';
    case 'stage':
      return env === 'local' || env === 'dev' || env === 'stage';
    case 'prod':
      return true;
  }
};
