import {assert} from 'assert-ts';
import sortBy from 'lodash/sortBy';
import {Concept} from 'types';
import {Metadata, MetadataOrder} from 'services/data/metadata/types';
import {CodeListMap, ExpressionV4} from 'api';
import {getManifestationCount} from './getManifestationCount';
import {orderExpressionsByProps} from './orderExpressionsByProps';

/**
 * Orderes expressions with groups of ordered manifestations with any change requests:
 * - Expressions by language and format
 * - Expression group:
 *   - Manifestations with any corresponding change requests ordered descending
 *     by published year
 *     - Change requests, if any (in given order)
 *     - Manifestation
 */
export const getMetadataOrder = (
  data: Metadata,
  newExpressionIds: string[],
  codelists: CodeListMap,
): MetadataOrder => {
  ensureAllExpressionIdsSet(data);

  const {expressions, manifestations, changeRequests} = data;

  // Order all manifestations by publishedYear
  const manifestationsOrdered = sortBy(manifestations, [
    m => -(m.publishedYear ?? -10000),
  ]);

  // Order expressions by existing expressions first then new expressions
  const existingExpressions = expressions.filter(
    e => !newExpressionIds.includes(e.id),
  );
  const newExpressions = expressions.filter(e =>
    newExpressionIds.includes(e.id),
  );
  // - existing expressions by props
  // - new expressions by order of creation
  const expressionsOrdered = [
    ...orderExpressionsByProps(existingExpressions, codelists),
    ...newExpressions,
  ]
    // Filter out empty expressions
    .filter(e => getManifestationCount(e.id, data) > 0);

  const order: MetadataOrder = expressionsOrdered.reduce<MetadataOrder>(
    (acc, e) => {
      const manifestationsForExpression = manifestationsOrdered.filter(
        m => m.expressionId === e.id,
      );
      const changeRequestsForExpression = changeRequests?.filter(
        cr => cr.expression.id === e.id && cr.level === 'manifestation',
      );

      const manifestationsWithChangeRequests = manifestationsForExpression
        .map(m => {
          const changeRequestsForManifestation = changeRequestsForExpression
            ?.filter(cr => cr.manifestation.id === m.id)
            .map(cr => ({
              type: Concept.changeRequest as const,
              taskId: cr.id,
            }));

          return [
            {
              type: Concept.manifestation as const,
              id: m.id,
              changeRequests: changeRequestsForManifestation,
            },
          ];
        })
        .flat(1);

      acc.push({
        expressionId: e.id,
        type: newExpressionIds.includes(e.id) ? 'new' : 'existing',
        content: manifestationsWithChangeRequests,
      });
      return acc;
    },
    [],
  );

  return order;
};

const ensureAllExpressionIdsSet = (data: Metadata) => {
  if (!data.changeRequests) {
    return;
  }

  data.changeRequests.forEach(cr => {
    if (!cr.expression || cr.expression.id === 'expressionIdPlaceholder') {
      const expressionId = data.manifestations.find(
        m => m.id === cr.manifestation.id,
      )?.expressionId;
      if (
        assert.soft(
          expressionId,
          'ensureAllExpressionIdsSet: expressionid expected',
        )
      ) {
        cr.manifestation.expressionId = expressionId;
        if (!cr.expression) {
          cr.expression = {
            id: expressionId,
            workId: data.work.id,
          } as ExpressionV4;
        } else {
          cr.expression.id = expressionId;
          cr.expression.workId = data.work.id;
        }
      }
    }
  });

  data.changeRequests = data.changeRequests.filter(
    cr => cr.expression.id !== 'expressionIdPlaceholder',
  );
};
