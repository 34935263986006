import {Concept, ConcreteEntityType} from 'types';
import {
  SchemaReference,
  schemaRefCorporation,
  schemaRefEducationalSeries,
  schemaRefEvent,
  schemaRefOtherConnection,
  schemaRefPerson,
  schemaRefPublisher,
  schemaRefPublisherSeries,
  schemaRefSeries,
  schemaRefWork,
  schemaRefexpression,
  schemaRefmanifestation,
} from 'schemas/types';
import {useSchema} from './useSchema';

const schemaRefMap: Record<ConcreteEntityType, SchemaReference> = {
  [Concept.work]: schemaRefWork,
  [Concept.expression]: schemaRefexpression,
  [Concept.manifestation]: schemaRefmanifestation,
  [Concept.person]: schemaRefPerson,
  [Concept.corporation]: schemaRefCorporation,
  [Concept.event]: schemaRefEvent,
  [Concept.publisher]: schemaRefPublisher,
  [Concept.series]: schemaRefSeries,
  [Concept.educationalSeries]: schemaRefEducationalSeries,
  [Concept.otherConnection]: schemaRefOtherConnection,
  [Concept.publisherSeries]: schemaRefPublisherSeries,
};
export const useEntitySchema = (entity: ConcreteEntityType) =>
  useSchema(schemaRefMap[entity]);
