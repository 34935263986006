import React from 'react';
import {useParams} from 'react-router-dom';
import {Grid, Typography} from '@mui/material';
import {assert} from 'assert-ts';
import {CodeListsGate} from 'services/codeLists';
import {useMetadataCodelistIds} from 'services/data';
import {ThesaurusesGate} from 'services/thesaurus';
import {
  expressionDataSchema,
  manifestationDataSchema,
  workDataSchema,
} from 'schemas';
import {Dewey, MainTitles} from 'schemas/renderers';
import {NoProps} from '../../types';
import {ThesaurusId, getManifestation} from '../../api';
import {useGetResource} from '../../api/hooks';
import {Scene} from '../../components';
import {Preview} from '../../schema/preview/components/Preview';
import {useGetTokens} from '../../services/auth/hooks/useGetTokens';

type Props = NoProps;

const thesaurusIds: ThesaurusId[] = ['thema', 'bokbasen'];

export const PreviewScene: React.FC<Props> = () => {
  const params = useParams();
  const manifestationId = assert(
    params['manifestationId'],
    'PreviewScene: manifestationId parameter expected',
  );
  const token = useGetTokens();
  const {status, data, error} = useGetResource(() =>
    getManifestation(manifestationId, token),
  );

  const codelistIds = useMetadataCodelistIds();

  return (
    <CodeListsGate codeLists={codelistIds}>
      <ThesaurusesGate thesauruses={thesaurusIds}>
        <Scene browserTitle={data?.mainTitles?.[0].value || 'Preview'}>
          <Scene.Content loadStatus={status} error={error?.message}>
            {data ? (
              <Grid container spacing={2} bgcolor={'white'} padding={2}>
                <Grid item xs={12} lg={4}>
                  <Typography variant={'h4'} mb={1}>
                    Work
                  </Typography>
                  <Preview
                    schema={workDataSchema}
                    data={{
                      ...data,
                      agents: data.workAgents ?? [],
                    }}
                    configuration={{
                      layout: {},
                      partValueRenderers: {
                        dewey: Dewey,
                      },
                    }}

                    // configuration={workConfig}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Typography variant={'h4'} mb={1}>
                    Expression
                  </Typography>
                  <Preview
                    schema={expressionDataSchema}
                    data={{...data, agents: data.expressionAgents ?? []}}
                    configuration={{
                      layout: {
                        noLabels: true,
                        noGrid: true,
                        partSpacing: 1,
                        groups: {},
                      },
                      text: {
                        value: {
                          variant: 'body1',
                          kind: 'primary',
                        },
                      },
                      rootContainer: 'NoContainer',
                      schemaContainers: {
                        expression: 'NoContainer',
                      },
                    }}
                    // configuration={expressionConfig}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Typography variant={'h4'} mb={1}>
                    Manifestation
                  </Typography>
                  <Preview
                    schema={manifestationDataSchema}
                    data={data}
                    configuration={{
                      layout: {},
                      partValueRenderers: {
                        mainTitles: MainTitles,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Scene.Content>
        </Scene>
      </ThesaurusesGate>
    </CodeListsGate>
  );
};
