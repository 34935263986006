import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'store/types';
import {SettingsAction} from 'services/settings';

const searchTextSelector = (state: AppState) =>
  state.settings.overview.searchText;

export const useTaskSearchText = () => {
  const dispatch = useDispatch();
  const searchText = useSelector(searchTextSelector);

  const setSearchText = useCallback(
    (searchText: string) => {
      const action: SettingsAction = {
        type: 'SET_OVERVIEW_SEARCH_TEXT',
        payload: searchText,
      };
      dispatch(action);
    },
    [dispatch],
  );

  return {
    setSearchText,
    searchText,
  };
};
