import {PreviewConfiguration} from 'schema/types';
import {
  agentWorkSummaryTableConfig,
  createLinkFromWorkSummaryRow,
} from '../table/agentWorkSummaryTableConfig';

export const agentPreviewConfig: PreviewConfiguration = {
  nameVariant: {
    excludeMainForm: true,
  },
  layout: {
    linkedValuesByRoles: true,
    groups: {
      info: {
        direction: 'horizontal',
      },
      internalComments: {
        direction: 'vertical',
      },
    },
  },
  parts: {
    works: {
      table: agentWorkSummaryTableConfig,
      createLink: createLinkFromWorkSummaryRow,
    },
  },
};
