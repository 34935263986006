import {TreeNode} from 'services/thesaurus/functions/types';

export const getVisibleNodeIds = (
  expanded: string[],
  nodes: TreeNode[],
  preceedingIds: string[] = [],
): string[] => {
  return nodes.reduce<string[]>((acc, node) => {
    acc.push(node.id);
    if (expanded.includes(node.id)) {
      getVisibleNodeIds(expanded, node.children ?? [], acc);
    }

    return acc;
  }, preceedingIds);
};
