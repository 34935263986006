import React, {useEffect} from 'react';
import {NoProps} from '../../../types';
import {useAuthState} from '../../../services/auth/hooks/useAuthState';
import {useCallbackParams} from '../../login/callback/hooks/useCallbackParams';

type Props = NoProps;

export const LogoutCallback: React.FC<Props> = () => {
  const {code, state, error} = useCallbackParams();

  const {logout} = useAuthState();

  useEffect(() => {
    logout();
  }, [code, state, error, logout]);

  return null;
};
