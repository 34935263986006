import {ValidationResult} from './types';
import {addValidationError} from './addValidationError';

export const addAggregateResult = (
  newResult: ValidationResult,
  aggregateResult: ValidationResult,
): ValidationResult =>
  newResult.errors.reduce<ValidationResult>(
    (aggr, error) => addValidationError(error, aggr),
    aggregateResult,
  );
