import React, {FocusEventHandler, MouseEventHandler} from 'react';
import {IconButton} from '@mui/material';
import {ButtonBaseTypeMap} from '@mui/material/ButtonBase/ButtonBase';
import {IconButtonTypeMap} from '@mui/material/IconButton/IconButton';
import {Icon} from '../icons';
import {FlexBox} from '../layout';
import {Text} from '../text';

type Props = {
  label?: string;
  id?: string;
  onBlur?: FocusEventHandler;
  color?: IconButtonTypeMap['props']['color'];
  onClick?: MouseEventHandler;
  iconButtonTouchRippleProps?: ButtonBaseTypeMap['props']['TouchRippleProps'];
};

const sxTitle = {
  pl: 0,
};

export const AddButton: React.FC<Props> = ({
  label,
  id,
  onBlur,
  onClick,
  color,
  iconButtonTouchRippleProps,
}) => {
  return (
    <FlexBox center left horizontal onBlur={onBlur}>
      <IconButton id={id} color={color} size="small" onClick={onClick}>
        <Icon icon={'Add'} />
      </IconButton>

      <IconButton
        id={id}
        tabIndex={-1}
        color={color}
        size="small"
        sx={sxTitle}
        disableRipple
        TouchRippleProps={iconButtonTouchRippleProps}
        onClick={onClick}>
        <Text variant="button" data-cy={'add-item'}>
          {label}
        </Text>
      </IconButton>
    </FlexBox>
  );
};
