export const mapRuntime = (
  runtime?: string | undefined,
): number | undefined => {
  if (!runtime) {
    return undefined;
  }
  const parts = runtime.split(':');
  const [first, second, third] = parts;

  let seconds = 0;

  switch (parts.length) {
    case 3: {
      seconds += parseInt(first, 10) * 60 * 60;
      seconds += parseInt(second, 10) * 60;
      seconds += parseInt(third, 10);
      break;
    }
    case 2: {
      seconds += parseInt(first, 10) * 60;
      seconds += parseInt(second, 10);
      break;
    }
    case 1: {
      seconds += parseInt(first, 10);
      break;
    }
  }

  return seconds > 0 ? seconds : undefined;
};

export const mapToRuntime = (
  runtime?: number | undefined,
): string | undefined => {
  if (!runtime) {
    return undefined;
  }

  const hours = Math.floor(runtime / 3600);
  const minutes = Math.floor((runtime % 3600) / 60);
  const seconds = Math.floor(runtime % 60);

  if (hours + minutes + seconds === 0) {
    return undefined;
  }

  return `${prependZeroes(hours)}:${prependZeroes(minutes)}:${prependZeroes(
    seconds,
  )}`;
};

const prependZeroes = (number: number): string => {
  if (number.toString().length === 0) {
    return `00`;
  }
  if (number.toString().length === 1) {
    return `0${number}`;
  }

  return number.toString();
};
