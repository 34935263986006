import {useMemo} from 'react';
import {assert} from 'assert-ts';
import {Agent} from 'api/types';
import {isLinkedAgentLink} from 'schemaDefinition/functions/isLinkedAgentLink';
import {isLinkedToNameVariant} from 'services/data/agent';
import {assertAgentValue} from 'services/utils';
import {Icon, IconButton, Layout, Spacer, Text} from 'components';
import {formatNameVariantValue} from 'schema/preview/functions';
import {LinkedType} from '../types';
import {EntityPreviewHeader} from './types';
import {LinkMainFormButton} from './LinkMainFormButton';

// agent: Agent;
// linkingConfiguration: Linking;
// onEdit: (agent: Agent) => void;

export const AgentPreviewHeader: EntityPreviewHeader = ({
  entityValue,
  linkingConfiguration,
  canLink,
  onEdit,
}) => {
  const agent = assertAgentValue(entityValue);
  const mainForm = agent.nameVariants[0];
  const {nameMainForm, linked, handleEdit} = useMemo((): {
    nameMainForm: string;
    linked: LinkedType;
    handleEdit: () => Promise<void>;
  } => {
    const agentLink =
      linkingConfiguration &&
      isLinkedAgentLink(linkingConfiguration.currentValue)
        ? linkingConfiguration.currentValue
        : undefined;

    const nameMainForm = formatNameVariantValue(
      mainForm,
      agent.agentType,
      'excludeId',
    );

    const handleEdit = (agent: Agent) =>
      assert(onEdit, 'AgentPreviewHeader: onEdit is required')(agent);

    return {
      nameMainForm,
      linked:
        linkingConfiguration &&
        isLinkedToNameVariant(
          agentLink,
          agent.id,
          agent.nameVariants[0],
          'mainForm',
        )
          ? agent.nationalId
            ? 'externalVerified'
            : 'verified'
          : 'none',
      handleEdit: () => Promise.resolve(handleEdit(agent)),
    };
  }, [agent, linkingConfiguration, mainForm, onEdit]);

  return (
    <Layout
      paddingLeft={linked ? 1 : 2}
      paddingRight={1}
      alignItems="center"
      justifyContent="space-between"
      horizontal
      flex={1}>
      <Layout horizontal adjustCenter>
        {linked === 'none' ? null : (
          <>
            <Icon icon={linked === 'verified' ? 'CheckCircle' : 'Verified'} />
            <Spacer width={1} />
          </>
        )}
        <Text variant="h2">{nameMainForm}</Text>
      </Layout>
      <Layout horizontal adjustCenter>
        <IconButton icon="Edit" onClick={handleEdit} />

        {linkingConfiguration && canLink ? (
          <>
            <Spacer size={1} />
            <LinkMainFormButton
              value={agent.nameVariants[0]}
              linkingConfiguration={linkingConfiguration}
            />
          </>
        ) : null}
      </Layout>
    </Layout>
  );
};
