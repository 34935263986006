import {Indexer} from '../types';

export interface Log {
  (message: string, data?: Indexer): void;
}

export interface LogError {
  (message: Error | string, data?: Indexer): void;
}

export class AppError extends Error {
  constructor(
    public message: string,
    public props?: Indexer,
  ) {
    super(message);
  }
}
