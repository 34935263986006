import {WorkV4} from 'api/types';
import {WorkExportProps} from 'services/data/metadata/types';

export const getWorkExportProps = (work: WorkV4): WorkExportProps => ({
  /* Required by manifestation validation */
  type: work?.type,
  literatureType: work?.literatureType,
  /* Required by expression validation */
  languages: work?.languages,
});
