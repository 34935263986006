import {
  MessageQueueStatus,
  SystemComponentStatus,
  SystemHealth,
} from 'api/types';
import {Schemas} from 'api/dto.generated';
import {isNullish} from 'services/utils';

export const mapHealthCheckDto = (
  dto: Schemas.HealthcheckDto,
): SystemHealth => {
  return {
    syncComponents: (dto.syncStatus?.entries ?? [])
      .filter(
        component => !isNullish(component.name) && !isNullish(component.status),
      )
      .map(
        component =>
          ({
            name: component.name,
            status: component.status,
          }) as SystemComponentStatus,
      ),
    messageQueues: (dto.messageQueueStatus?.entries ?? [])
      .filter(
        queue => !isNullish(queue.topic) && !isNullish(queue.messagesBehind),
      )
      .map(
        queue =>
          ({
            topic: queue.topic,
            length: queue.messagesBehind,
          }) as MessageQueueStatus,
      ),
    services: (dto.serviceStatus?.entries ?? [])
      .filter(
        service =>
          !isNullish(service.serviceName) && !isNullish(service.status),
      )
      .map(
        service =>
          ({
            name: service.serviceName,
            status: service.status,
          }) as SystemComponentStatus,
      ),
  };
};
