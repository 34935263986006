import {Thesaurus, ThesaurusValue} from 'api/types';
import {NodeAndAncestors} from '../types';
import {findNodeWithAncestors} from './findNodeWithAncestors';
import {getMissingNodeWithAncestors} from './getMissingNodeWithAncestors';

/**
 * Will only match the first node with the given code or id
 * @param thesaurusValue node ids or codes
 * @param thesaurus
 * @returns
 */
export const getNodesAndAncestors = (
  thesaurusValue: ThesaurusValue,
  thesaurus: Thesaurus,
): NodeAndAncestors[] => {
  return thesaurusValue
    .map(
      codeOrId =>
        getNodeAndAncestors(codeOrId, thesaurus) ||
        getMissingNodeWithAncestors(codeOrId, thesaurus),
    )
    .filter(
      (nodeAndAncestors): nodeAndAncestors is NodeAndAncestors =>
        !!nodeAndAncestors,
    );
};

const getNodeAndAncestors = (
  codeOrId: string,
  thesaurus: Thesaurus,
): NodeAndAncestors | undefined => {
  return findNodeWithAncestors(codeOrId, thesaurus);
};
