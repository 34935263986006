import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Box, IconButton, SxProps} from '@mui/material';
import parse from 'html-react-parser';
import {Fonts} from 'theme/fonts';
import {HtmlProps} from './types';
import {useThemeColors} from '../../theme';
import {ColorPalette} from '../../theme/colorPalette';
import {LessIcon, MoreIcon} from '../icons';
import {Layout} from '../layout';

const fixedHtmlFragmentContainerSx: SxProps = {
  overflow: 'hidden',
};

const containerSx: SxProps = {
  borderRadius: 1,
  font: Fonts.body1,
  px: 2,
  pt: 2,
  pb: 2,
  background: ColorPalette.beige,
};

const expandableContainerSx: SxProps = {
  ...containerSx,
  pb: 0,
};

export const HtmlReadonly: React.FC<HtmlProps> = ({value}) => {
  const themeColors = useThemeColors();

  const [maxRows, setMaxRows] = useState<number | undefined>(3);
  const [showExpandHandle, setShowExpandHandle] = useState(false);
  const ref = useRef<HTMLHtmlElement>(null);

  const parsedValue = useMemo(() => (value ? parse(value) : null), [value]);

  const htmlFragmentContainerSx: SxProps = useMemo(
    () =>
      maxRows
        ? {
            ...fixedHtmlFragmentContainerSx,
            maxHeight: `${maxRows * 1.4}rem`,
          }
        : fixedHtmlFragmentContainerSx,
    [maxRows],
  );

  const handleToggle = useCallback(() => {
    setMaxRows(old => (old === 3 ? undefined : 3));
  }, []);

  useEffect(() => {
    if (ref.current) {
      const clientHeight = ref.current.clientHeight;
      const scrollHeight = ref.current.scrollHeight;
      // console.log('clientHeight', clientHeight, 'scrollHeight', scrollHeight);
      setShowExpandHandle(maxRows === undefined || clientHeight < scrollHeight);
    }
  }, [value, maxRows]);

  if (!parsedValue) return null;

  return (
    <Layout
      kind="primary"
      sx={showExpandHandle ? expandableContainerSx : containerSx}>
      <Box ref={ref} sx={htmlFragmentContainerSx}>
        {parsedValue}
      </Box>
      {showExpandHandle ? (
        <Layout horizontal adjustCenter>
          <Layout
            horizontal
            flex={1}
            sx={{
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
              borderBlockColor: ColorPalette.secondary.lightBurgundy,
              height: '0px',
            }}
          />
          <IconButton
            onClick={handleToggle}
            sx={{color: themeColors.text.primary.dark}}>
            {maxRows ? <MoreIcon /> : <LessIcon />}
          </IconButton>
          <Layout
            horizontal
            flex={1}
            sx={{
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
              borderBlockColor: ColorPalette.secondary.lightBurgundy,
              height: '0px',
            }}
          />
        </Layout>
      ) : null}
    </Layout>
  );
};
