import React, {useMemo} from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {PreviewConfiguration} from 'schema/types';
import {Data, Schema} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {decorateSchema, getSchemaFlattended} from 'schemaDefinition/functions';
import {
  FormSchemaGlobalScopeContextProvider,
  PreviewSchemaGlobalScopeContext,
  usePreviewSchemaGlobalScopeContextProviderValue,
} from 'schema/contexts';
import {
  PreviewConfigurationContext,
  usePreviewConfigurationContextProviderValue,
} from '../contexts';
import {PreviewSchema} from './PreviewSchema';

type Props = {
  schema: Schema;
  data: Data;
  configuration?: PreviewConfiguration;
};

export const Preview: React.FC<Props> = ({schema, data, configuration}) => {
  const {locale} = useLocalization();
  const previewContext =
    usePreviewConfigurationContextProviderValue(configuration);

  const previewSchema = useMemo(() => {
    const flatSchema = getSchemaFlattended(schema);
    return decorateSchema(flatSchema);
  }, [schema]);

  const values = usePreviewSchemaGlobalScopeContextProviderValue(
    previewSchema,
    data,
  );

  return (
    <PreviewConfigurationContext.Provider value={previewContext}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        <PreviewSchemaGlobalScopeContext.Provider value={values}>
          {/* Required by label lookup */}
          <FormSchemaGlobalScopeContextProvider schema={previewSchema}>
            <PreviewSchema
              schema={previewSchema}
              value={data}
              valuePath=""
              relatedScope={{}}
              containerConfiguration={previewContext.rootContainer}
            />
          </FormSchemaGlobalScopeContextProvider>
        </PreviewSchemaGlobalScopeContext.Provider>
      </LocalizationProvider>
    </PreviewConfigurationContext.Provider>
  );
};
