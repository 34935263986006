import {useMemo} from 'react';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme/colorPalette';
import {useDefaultAdornementResolver} from 'schema';
import {DataFormConfigurationContextType} from 'schema/form/contexts';
import {Notes} from 'schemas/renderers/form/Notes';

export const useManifestationFormConfiguration =
  (): DataFormConfigurationContextType => {
    const {t} = useLocalization();
    const adornmentResolver = useDefaultAdornementResolver();
    return useMemo(
      () => ({
        schemaContainers: {
          productOwners: 'NoContainer',
          products: 'NoContainer',
          mainTitles: {
            colorx: ColorPalette.beige,
          },
          otherTitles: 'NoContainer',
          notes: 'NoContainer',
        },
        showWhenReadonlyAndEmpty: true,
        adornmentResolver,
        parts: {
          'notes.generalNote': {
            label: t('form.global.field.notes.generalNote.label'),
            addItemTitle: t('form.global.field.notes.generalNote.addItem'),
            rows: 1,
            contentLabels: {},
          },
          'notes.editionNote': {
            label: t('form.global.field.notes.editionNote.label'),
            rows: 1,
          },
          // To not use field lables should:
          'productOwner.code': {
            layout: {
              width: '8em',
            },
          },
          'productOwner.name': {
            layout: {
              flex: 1,
            },
          },
          products: {
            label: t('form.global.field.products.label'),
            addItemTitle: '',
          },
          'product.owner.code': {
            label: 'Produkteier',
            layout: {
              width: '8em',
            },
          },
          'product.distributor.code': {
            layout: {
              width: '8em',
            },
          },
          'otherTitle.value': {
            layout: {
              flex: 1,
            },
          },
          'otherTitle.type': {
            layout: {
              width: '10em',
            },
          },
          scale: {
            label: t('form.global.field.scale.label'),
            hideStructureOperations: ['move'],
          },
        },
        dataFormPartRenderers: {
          notes: Notes,
        },
      }),
      [adornmentResolver, t],
    );
  };
