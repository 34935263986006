import {useEffect, useMemo, useRef, useState} from 'react';

export type Focusable = {focus?: () => void};

const getElementById = (
  element: Element & Focusable,
  id: string,
): Focusable | undefined => {
  if (element.id === id) {
    return element;
  }

  if (!(element instanceof Element)) return undefined;

  for (let i = 0; i < element.children.length; i++) {
    const match = getElementById(element.children[i], id);
    if (match) return match;
  }
};

export const useNextFocus = () => {
  const [nextFocusId, setNextFocusId] = useState<string | undefined>();
  const scopeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (nextFocusId) {
      if (scopeRef.current) {
        const nextFocusElement = getElementById(scopeRef.current, nextFocusId);
        if (nextFocusElement && nextFocusElement.focus) {
          nextFocusElement.focus();
        }
      }

      setNextFocusId(undefined);
    }
  }, [nextFocusId]);

  return useMemo(
    () => ({
      scopeRef,
      setNextFocusId,
    }),
    [],
  );
};
