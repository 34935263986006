/**
 * Wrap API-call in this to ensure exceptions are caught, e.g. when mapping to/from DTOs
 * @param fn
 * @returns
 */
export const exceptionToPromiseReject = <T>(
  fn: () => Promise<T>,
): Promise<T> => {
  try {
    return fn();
  } catch (e) {
    return Promise.reject(e);
  }
};
