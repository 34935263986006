import {CodeListId} from 'api/types';
import {Part} from 'schemaDefinition/types';
import {LinkedRoleCodeListId} from 'schemaDefinition/linkTypes';

export const getPartCodelistId = <TVal>(
  part: Part<TVal>,
): Array<CodeListId | LinkedRoleCodeListId> | undefined => {
  switch (part.type) {
    case 'codelist':
    case 'codelist|text': {
      return [part.codelistId];
    }
    case 'linkedAgent':
    case 'linkedLiterary': {
      return [part.roleCodelistId, 'UNNAMED_PERSON'];
    }
  }
  return undefined;
};
