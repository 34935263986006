import {createContext, useContext, useMemo} from 'react';
import {assert} from 'assert-ts';
import {PreviewConfiguration} from 'schema/types';

type PreviewConfigurationContextType = PreviewConfiguration;

const defaultContext: PreviewConfigurationContextType = {
  nameVariant: {},
  layout: {
    partSpacing: 1,
    groups: {},
  },
};

export const PreviewConfigurationContext =
  createContext<PreviewConfigurationContextType>(defaultContext);

export const usePreviewConfigurationContextProviderValue = (
  configuration?: Partial<PreviewConfigurationContextType>,
): PreviewConfigurationContextType => {
  return useMemo(
    () => ({
      ...defaultContext,
      ...configuration,
      nameVariant: {
        ...defaultContext.nameVariant,
        ...configuration?.nameVariant,
      },
      layout: {
        ...defaultContext.layout,
        ...configuration?.layout,
        groups: {
          ...defaultContext.layout.groups,
          ...configuration?.layout?.groups,
        },
      },
    }),
    [configuration],
  );
};

export const usePreviewConfigurationContext =
  (): PreviewConfigurationContextType => {
    return assert(
      useContext(PreviewConfigurationContext),
      'usePreviewConfigurationContext: context expected',
    );
  };
