import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const StarIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12 18.26L4.94704 22.208L6.52204 14.28L0.587036 8.792L8.61404 7.84L12 0.5L15.386 7.84L23.413 8.792L17.478 14.28L19.053 22.208L12 18.26ZM12 15.968L16.247 18.345L15.298 13.572L18.871 10.267L14.038 9.694L12 5.275L9.96204 9.695L5.12904 10.267L8.70204 13.572L7.75304 18.345L12 15.968Z" />
    </SvgIcon>
  );
};
