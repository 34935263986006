import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const SeriesIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25" transform="scale(1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4.30127C12.4178 4.30127 11.8437 4.43681 11.3229 4.69717C10.8022 4.95752 10.3493 5.33553 10 5.80127C9.6507 5.33553 9.19776 4.95752 8.67705 4.69717C8.15634 4.43681 7.58217 4.30127 7 4.30127H4V14.8013H7.5V12.7718H6V6.30127H7C7.59674 6.30127 8.07804 6.37931 8.5 6.80127C8.78564 7.08691 8.94359 7.3524 8.98733 7.78183H11.0127C11.0564 7.3524 11.2144 7.08691 11.5 6.80127C11.922 6.37931 12.4033 6.30127 13 6.30127H14V9.10986C14.6525 8.4291 15.0791 8.23519 16 7.97014V4.30127H13ZM15.3229 8.69717C15.8437 8.43681 16.4178 8.30127 17 8.30127H20V18.8013H15V20.3013H13V18.8013H8V8.30127H11C11.5822 8.30127 12.1563 8.43681 12.6771 8.69717C13.1978 8.95752 13.6507 9.33553 14 9.80127C14.3493 9.33553 14.8022 8.95752 15.3229 8.69717ZM10 10.3013V16.7718H13V12.0513C13 11.4545 12.841 11.1423 12.5 10.8013C12.078 10.3793 11.5967 10.3013 11 10.3013H10ZM15 16.8013H18V10.3013H17C16.4033 10.3013 15.922 10.3793 15.5 10.8013C15.159 11.1423 15 11.4545 15 12.0513V16.8013Z"
        fill={props.color || 'currentColor'}
      />{' '}
    </SvgIcon>
  );
};
