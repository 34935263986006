import {Code} from 'api/types';

export const onix_74: Code[] = [
  {code: 'aar', value: 'Afar'},
  {code: 'abk', value: 'Abkhazian'},
  {code: 'ace', value: 'Achinese'},
  {code: 'ach', value: 'Acoli'},
  {code: 'ada', value: 'Adangme'},
  {code: 'ady', value: 'Adygei; Adyghe', notes: '(West Circassian)'},
  {code: 'afa', value: 'Afro-Asiatic languages', notes: 'Collective name'},
  {code: 'afh', value: 'Afrihili', notes: 'Artificial language'},
  {code: 'afr', value: 'Afrikaans'},
  {code: 'ain', value: 'Ainu'},
  {code: 'aka', value: 'Akan', notes: 'Macrolanguage'},
  {code: 'akk', value: 'Akkadian'},
  {code: 'alb', value: 'Albanian', notes: 'Macrolanguage'},
  {code: 'ale', value: 'Aleut'},
  {code: 'alg', value: 'Algonquian languages', notes: 'Collective name'},
  {code: 'alt', value: 'Southern Altai'},
  {code: 'amh', value: 'Amharic'},
  {code: 'ang', value: 'English, Old (ca. 450-1100)'},
  {code: 'anp', value: 'Angika'},
  {code: 'apa', value: 'Apache languages', notes: 'Collective name'},
  {code: 'ara', value: 'Arabic', notes: 'Macrolanguage'},
  {code: 'arc', value: 'Official Aramaic; Imperial Aramaic (700-300 BCE)'},
  {code: 'arg', value: 'Aragonese'},
  {code: 'arm', value: 'Armenian'},
  {code: 'arn', value: 'Mapudungun; Mapuche'},
  {code: 'arp', value: 'Arapaho'},
  {code: 'art', value: 'Artificial languages', notes: 'Collective name'},
  {code: 'arw', value: 'Arawak'},
  {code: 'asm', value: 'Assamese'},
  {code: 'ast', value: 'Asturian; Bable; Leonese; Asturleonese'},
  {code: 'ath', value: 'Athapascan languages', notes: 'Collective name'},
  {code: 'aus', value: 'Australian languages', notes: 'Collective name'},
  {code: 'ava', value: 'Avaric'},
  {code: 'ave', value: 'Avestan'},
  {code: 'awa', value: 'Awadhi'},
  {code: 'aym', value: 'Aymara', notes: 'Macrolanguage'},
  {code: 'aze', value: 'Azerbaijani', notes: 'Macrolanguage'},
  {code: 'bad', value: 'Banda languages', notes: 'Collective name'},
  {code: 'bai', value: 'Bamileke languages', notes: 'Collective name'},
  {code: 'bak', value: 'Bashkir'},
  {code: 'bal', value: 'Baluchi', notes: 'Macrolanguage'},
  {code: 'bam', value: 'Bambara'},
  {code: 'ban', value: 'Balinese'},
  {code: 'baq', value: 'Basque'},
  {code: 'bas', value: 'Basa'},
  {code: 'bat', value: 'Baltic languages', notes: 'Collective name'},
  {code: 'bej', value: 'Beja; Bedawiyet'},
  {code: 'bel', value: 'Belarusian'},
  {code: 'bem', value: 'Bemba'},
  {code: 'ben', value: 'Bengali'},
  {code: 'ber', value: 'Berber languages', notes: 'Collective name'},
  {code: 'bho', value: 'Bhojpuri'},
  {code: 'bih', value: 'Bihari languages', notes: 'Collective name'},
  {code: 'bik', value: 'Bikol', notes: 'Macrolanguage'},
  {code: 'bin', value: 'Bini; Edo'},
  {code: 'bis', value: 'Bislama'},
  {code: 'bla', value: 'Siksika'},
  {code: 'bnt', value: 'Bantu languages', notes: 'Collective name'},
  {code: 'bos', value: 'Bosnian'},
  {code: 'bra', value: 'Braj'},
  {code: 'bre', value: 'Breton'},
  {code: 'btk', value: 'Batak languages', notes: 'Collective name'},
  {code: 'bua', value: 'Buriat', notes: 'Macrolanguage'},
  {code: 'bug', value: 'Buginese'},
  {code: 'bul', value: 'Bulgarian'},
  {code: 'bur', value: 'Burmese'},
  {code: 'byn', value: 'Blin; Bilin'},
  {code: 'cad', value: 'Caddo'},
  {
    code: 'cai',
    value: 'Central American Indian languages',
    notes: 'Collective name',
  },
  {code: 'car', value: 'Galibi Carib'},
  {code: 'cat', value: 'Catalan'},
  {code: 'cau', value: 'Caucasian languages', notes: 'Collective name'},
  {code: 'ceb', value: 'Cebuano'},
  {code: 'cel', value: 'Celtic languages', notes: 'Collective name'},
  {code: 'cha', value: 'Chamorro'},
  {code: 'chb', value: 'Chibcha'},
  {code: 'che', value: 'Chechen'},
  {code: 'chg', value: 'Chagatai'},
  {code: 'chi', value: 'Chinese', notes: 'Macrolanguage'},
  {code: 'chk', value: 'Chuukese (Truk)'},
  {code: 'chm', value: 'Mari', notes: 'Macrolanguage'},
  {code: 'chn', value: 'Chinook jargon'},
  {code: 'cho', value: 'Choctaw'},
  {code: 'chp', value: 'Chipewyan; Dene Suline'},
  {code: 'chr', value: 'Cherokee'},
  {
    code: 'chu',
    value:
      'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
  },
  {code: 'chv', value: 'Chuvash'},
  {code: 'chy', value: 'Cheyenne'},
  {
    code: 'ckb',
    value: 'Central Kurdish (Sorani)',
    notes:
      'ONIX local code, equivalent to ckb in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'cmc', value: 'Chamic languages', notes: 'Collective name'},
  {
    code: 'cmn',
    value: 'Mandarin',
    notes: 'ONIX local code, equivalent to cmn in ISO 639-3',
  },
  {code: 'cnr', value: 'Montenegrin', notes: 'For use in ONIX 3.0 only'},
  {code: 'cop', value: 'Coptic'},
  {code: 'cor', value: 'Cornish'},
  {code: 'cos', value: 'Corsican'},
  {
    code: 'cpe',
    value: 'Creoles and pidgins, English-based',
    notes: 'Collective name',
  },
  {
    code: 'cpf',
    value: 'Creoles and pidgins, French-based',
    notes: 'Collective name',
  },
  {
    code: 'cpp',
    value: 'Creoles and pidgins, Portuguese-based',
    notes: 'Collective name',
  },
  {code: 'cre', value: 'Cree', notes: 'Macrolanguage'},
  {code: 'crh', value: 'Crimean Turkish; Crimean Tatar'},
  {code: 'crp', value: 'Creoles and pidgins', notes: 'Collective name'},
  {code: 'csb', value: 'Kashubian'},
  {code: 'cus', value: 'Cushitic languages', notes: 'Collective name'},
  {code: 'cze', value: 'Czech'},
  {code: 'dak', value: 'Dakota'},
  {code: 'dan', value: 'Danish'},
  {code: 'dar', value: 'Dargwa'},
  {code: 'day', value: 'Land Dayak languages', notes: 'Collective name'},
  {code: 'del', value: 'Delaware', notes: 'Macrolanguage'},
  {code: 'den', value: 'Slave (Athapascan)', notes: 'Macrolanguage'},
  {code: 'dgr', value: 'Dogrib'},
  {code: 'din', value: 'Dinka', notes: 'Macrolanguage'},
  {code: 'div', value: 'Divehi; Dhivehi; Maldivian'},
  {code: 'doi', value: 'Dogri', notes: 'Macrolanguage'},
  {code: 'dra', value: 'Dravidian languages', notes: 'Collective name'},
  {code: 'dsb', value: 'Lower Sorbian'},
  {code: 'dua', value: 'Duala'},
  {code: 'dum', value: 'Dutch, Middle (ca. 1050-1350)'},
  {code: 'dut', value: 'Dutch; Flemish'},
  {code: 'dyu', value: 'Dyula'},
  {code: 'dzo', value: 'Dzongkha'},
  {code: 'efi', value: 'Efik'},
  {
    code: 'egl',
    value: 'Emilian',
    notes:
      'ONIX local code for Italian dialect, equivalent to egl in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'egy', value: 'Egyptian (Ancient)'},
  {code: 'eka', value: 'Ekajuk'},
  {code: 'elx', value: 'Elamite'},
  {code: 'eng', value: 'English'},
  {code: 'enm', value: 'English, Middle (1100-1500)'},
  {code: 'epo', value: 'Esperanto', notes: 'Artificial language'},
  {code: 'est', value: 'Estonian', notes: 'Macrolanguage'},
  {code: 'ewe', value: 'Ewe'},
  {code: 'ewo', value: 'Ewondo'},
  {code: 'fan', value: 'Fang'},
  {code: 'fao', value: 'Faroese'},
  {code: 'fat', value: 'Fanti'},
  {code: 'fij', value: 'Fijian'},
  {code: 'fil', value: 'Filipino; Pilipino'},
  {code: 'fin', value: 'Finnish'},
  {
    code: 'fit',
    value: 'Meänkieli / Tornedalen Finnish',
    notes: 'ONIX local code, equivalent to fit in ISO 639-3',
  },
  {code: 'fiu', value: 'Finno-Ugrian languages', notes: 'Collective name'},
  {
    code: 'fkv',
    value: 'Kvensk',
    notes: 'ONIX local code, equivalent to fkv in ISO 639-3',
  },
  {code: 'fon', value: 'Fon'},
  {code: 'fre', value: 'French'},
  {code: 'frm', value: 'French, Middle (ca. 1400-1600)'},
  {code: 'fro', value: 'French, Old (ca. 842-1400)'},
  {code: 'frr', value: 'Northern Frisian'},
  {code: 'frs', value: 'Eastern Frisian'},
  {code: 'fry', value: 'Western Frisian'},
  {code: 'ful', value: 'Fulah'},
  {code: 'fur', value: 'Friulian'},
  {code: 'gaa', value: 'Gã'},
  {code: 'gay', value: 'Gayo'},
  {code: 'gba', value: 'Gbaya', notes: 'Macrolanguage'},
  {code: 'gem', value: 'Germanic languages', notes: 'Collective name'},
  {code: 'geo', value: 'Georgian'},
  {code: 'ger', value: 'German'},
  {code: 'gez', value: 'Ethiopic (Ge’ez)'},
  {code: 'gil', value: 'Gilbertese'},
  {code: 'gla', value: 'Scottish Gaelic'},
  {code: 'gle', value: 'Irish'},
  {code: 'glg', value: 'Galician'},
  {code: 'glv', value: 'Manx'},
  {code: 'gmh', value: 'German, Middle High (ca. 1050-1500)'},
  {code: 'goh', value: 'German, Old High (ca. 750-1050)'},
  {code: 'gon', value: 'Gondi', notes: 'Macrolanguage'},
  {code: 'gor', value: 'Gorontalo'},
  {code: 'got', value: 'Gothic'},
  {code: 'grb', value: 'Grebo', notes: 'Macrolanguage'},
  {code: 'grc', value: 'Greek, Ancient (to 1453)'},
  {code: 'gre', value: 'Greek, Modern (1453-)'},
  {code: 'grn', value: 'Guarani', notes: 'Macrolanguage'},
  {
    code: 'grt',
    value: 'Garo',
    notes:
      'ONIX local code, equivalent to grt in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'gsw', value: 'Swiss German; Alemannic; Alsatian'},
  {code: 'guj', value: 'Gujarati'},
  {code: 'gwi', value: 'Gwich’in'},
  {code: 'hai', value: 'Haida', notes: 'Macrolanguage'},
  {code: 'hat', value: 'Haitian; Haitian Creole'},
  {code: 'hau', value: 'Hausa'},
  {code: 'haw', value: 'Hawaiian'},
  {code: 'heb', value: 'Hebrew'},
  {code: 'her', value: 'Herero'},
  {code: 'hil', value: 'Hiligaynon'},
  {
    code: 'him',
    value: 'Himachali languages; Western Pahari languages',
    notes: 'Collective name',
  },
  {code: 'hin', value: 'Hindi'},
  {code: 'hit', value: 'Hittite'},
  {code: 'hmn', value: 'Hmong; Mong', notes: 'Macrolanguage'},
  {code: 'hmo', value: 'Hiri Motu'},
  {code: 'hrv', value: 'Croatian'},
  {code: 'hsb', value: 'Upper Sorbian'},
  {code: 'hun', value: 'Hungarian'},
  {code: 'hup', value: 'Hupa'},
  {code: 'iba', value: 'Iban'},
  {code: 'ibo', value: 'Igbo'},
  {code: 'ice', value: 'Icelandic'},
  {code: 'ido', value: 'Ido', notes: 'Artificial language'},
  {code: 'iii', value: 'Sichuan Yi; Nuosu'},
  {code: 'ijo', value: 'Ijo languages', notes: 'Collective name'},
  {code: 'iku', value: 'Inuktitut', notes: 'Macrolanguage'},
  {code: 'ile', value: 'Interlingue; Occidental', notes: 'Artificial language'},
  {code: 'ilo', value: 'Iloko'},
  {
    code: 'ina',
    value: 'Interlingua (International Auxiliary Language Association)',
    notes: 'Artificial language',
  },
  {code: 'inc', value: 'Indic languages', notes: 'Collective name'},
  {code: 'ind', value: 'Indonesian'},
  {code: 'ine', value: 'Indo-European languages', notes: 'Collective name'},
  {code: 'inh', value: 'Ingush'},
  {code: 'ipk', value: 'Inupiaq', notes: 'Macrolanguage'},
  {code: 'ira', value: 'Iranian languages', notes: 'Collective name'},
  {code: 'iro', value: 'Iroquoian languages', notes: 'Collective name'},
  {code: 'ita', value: 'Italian'},
  {code: 'jav', value: 'Javanese'},
  {code: 'jbo', value: 'Lojban'},
  {code: 'jpn', value: 'Japanese'},
  {code: 'jpr', value: 'Judeo-Persian'},
  {code: 'jrb', value: 'Judeo-Arabic', notes: 'Macrolanguage'},
  {code: 'kaa', value: 'Kara-Kalpak'},
  {code: 'kab', value: 'Kabyle'},
  {code: 'kac', value: 'Kachin; Jingpho'},
  {code: 'kal', value: 'Kalâtdlisut; Greenlandic'},
  {code: 'kam', value: 'Kamba'},
  {code: 'kan', value: 'Kannada'},
  {code: 'kar', value: 'Karen languages', notes: 'Collective name'},
  {code: 'kas', value: 'Kashmiri'},
  {code: 'kau', value: 'Kanuri', notes: 'Macrolanguage'},
  {code: 'kaw', value: 'Kawi'},
  {code: 'kaz', value: 'Kazakh'},
  {code: 'kbd', value: 'Kabardian', notes: '(East Circassian)'},
  {
    code: 'kdr',
    value: 'Karaim',
    notes: 'ONIX local code, equivalent to kdr in ISO 639-3',
  },
  {code: 'kha', value: 'Khasi'},
  {code: 'khi', value: 'Khoisan languages', notes: 'Collective name'},
  {code: 'khm', value: 'Central Khmer'},
  {code: 'kho', value: 'Khotanese; Sakan'},
  {code: 'kik', value: 'Kikuyu; Gikuyu'},
  {code: 'kin', value: 'Kinyarwanda'},
  {code: 'kir', value: 'Kirghiz; Kyrgyz'},
  {code: 'kmb', value: 'Kimbundu'},
  {code: 'kok', value: 'Konkani', notes: 'Macrolanguage'},
  {code: 'kom', value: 'Komi', notes: 'Macrolanguage'},
  {code: 'kon', value: 'Kongo', notes: 'Macrolanguage'},
  {code: 'kor', value: 'Korean'},
  {code: 'kos', value: 'Kusaiean (Caroline Islands)'},
  {code: 'kpe', value: 'Kpelle', notes: 'Macrolanguage'},
  {code: 'krc', value: 'Karachay-Balkar'},
  {code: 'krl', value: 'Karelian'},
  {code: 'kro', value: 'Kru languages', notes: 'Collective name'},
  {code: 'kru', value: 'Kurukh'},
  {code: 'kua', value: 'Kuanyama; Kwanyama'},
  {code: 'kum', value: 'Kumyk'},
  {code: 'kur', value: 'Kurdish', notes: 'Macrolanguage'},
  {code: 'kut', value: 'Kutenai'},
  {code: 'lad', value: 'Ladino'},
  {code: 'lah', value: 'Lahnda', notes: 'Macrolanguage'},
  {code: 'lam', value: 'Lamba'},
  {code: 'lao', value: 'Lao'},
  {code: 'lat', value: 'Latin'},
  {code: 'lav', value: 'Latvian', notes: 'Macrolanguage'},
  {code: 'lez', value: 'Lezgian'},
  {
    code: 'lij',
    value: 'Ligurian',
    notes:
      'ONIX local code for Italian dialect, equivalent to lij in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'lim', value: 'Limbergan; Limburger; Limburgish'},
  {code: 'lin', value: 'Lingala'},
  {code: 'lit', value: 'Lithuanian'},
  {
    code: 'lmo',
    value: 'Lombard',
    notes:
      'ONIX local code for Italian dialect, equivalent to lmo in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'lol', value: 'Mongo-Nkundu'},
  {code: 'loz', value: 'Lozi'},
  {code: 'ltz', value: 'Luxembourgish; Letzeburgesch'},
  {code: 'lua', value: 'Luba-Lulua'},
  {code: 'lub', value: 'Luba-Katanga'},
  {code: 'lug', value: 'Ganda'},
  {code: 'lui', value: 'Luiseño'},
  {code: 'lun', value: 'Lunda'},
  {code: 'luo', value: 'Luo (Kenya and Tanzania)'},
  {code: 'lus', value: 'Lushai'},
  {code: 'mac', value: 'Macedonian'},
  {code: 'mad', value: 'Madurese'},
  {code: 'mag', value: 'Magahi'},
  {code: 'mah', value: 'Marshallese'},
  {code: 'mai', value: 'Maithili'},
  {code: 'mak', value: 'Makasar'},
  {code: 'mal', value: 'Malayalam'},
  {code: 'man', value: 'Mandingo', notes: 'Macrolanguage'},
  {code: 'mao', value: 'Maori'},
  {code: 'map', value: 'Austronesian languages', notes: 'Collective name'},
  {code: 'mar', value: 'Marathi'},
  {code: 'mas', value: 'Masai'},
  {code: 'may', value: 'Malay', notes: 'Macrolanguage'},
  {code: 'mdf', value: 'Moksha'},
  {code: 'mdr', value: 'Mandar'},
  {code: 'men', value: 'Mende'},
  {code: 'mga', value: 'Irish, Middle (ca. 1100-1550)'},
  {code: 'mic', value: 'Mi’kmaq; Micmac'},
  {code: 'min', value: 'Minangkabau'},
  {
    code: 'mis',
    value: 'Uncoded languages',
    notes: 'Use where no suitable code is available',
  },
  {code: 'mkh', value: 'Mon-Khmer languages', notes: 'Collective name'},
  {code: 'mlg', value: 'Malagasy', notes: 'Macrolanguage'},
  {code: 'mlt', value: 'Maltese'},
  {code: 'mnc', value: 'Manchu'},
  {code: 'mni', value: 'Manipuri'},
  {code: 'mno', value: 'Manobo languages', notes: 'Collective name'},
  {code: 'moh', value: 'Mohawk'},
  {code: 'mol', value: 'Moldavian; Moldovan', notes: 'DEPRECATED – use rum'},
  {code: 'mon', value: 'Mongolian', notes: 'Macrolanguage'},
  {code: 'mos', value: 'Mooré; Mossi'},
  {code: 'mul', value: 'Multiple languages'},
  {code: 'mun', value: 'Munda languages', notes: 'Collective name'},
  {code: 'mus', value: 'Creek'},
  {
    code: 'mwf',
    value: 'Murrinh-Patha',
    notes:
      'ONIX local code, equivalent to mwf in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'mwl', value: 'Mirandese'},
  {code: 'mwr', value: 'Marwari', notes: 'Macrolanguage'},
  {code: 'myn', value: 'Mayan languages', notes: 'Collective name'},
  {code: 'myv', value: 'Erzya'},
  {code: 'nah', value: 'Nahuatl languages', notes: 'Collective name'},
  {
    code: 'nai',
    value: 'North American Indian languages',
    notes: 'Collective name',
  },
  {code: 'nap', value: 'Neapolitan'},
  {code: 'nau', value: 'Nauruan'},
  {code: 'nav', value: 'Navajo; Navaho'},
  {code: 'nbl', value: 'Ndebele, South'},
  {code: 'nde', value: 'Ndebele, North'},
  {code: 'ndo', value: 'Ndonga'},
  {code: 'nds', value: 'Low German; Low Saxon'},
  {code: 'nep', value: 'Nepali', notes: 'Macrolanguage'},
  {code: 'new', value: 'Newari; Nepal Bhasa'},
  {code: 'nia', value: 'Nias'},
  {code: 'nic', value: 'Niger-Kordofanian languages', notes: 'Collective name'},
  {code: 'niu', value: 'Niuean'},
  {code: 'nno', value: 'Norwegian Nynorsk'},
  {code: 'nob', value: 'Norwegian Bokmål'},
  {code: 'nog', value: 'Nogai'},
  {code: 'non', value: 'Old Norse'},
  {code: 'nor', value: 'Norwegian', notes: 'Macrolanguage'},
  {code: 'nqo', value: 'N’Ko'},
  {
    code: 'nrf',
    value: 'Guernésiais, Jèrriais',
    notes:
      'ONIX local code, equivalent to nrf in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'nso', value: 'Pedi; Sepedi; Northern Sotho'},
  {code: 'nub', value: 'Nubian languages', notes: 'Collective name'},
  {code: 'nwc', value: 'Classical Newari; Old Newari; Classical Nepal Bhasa'},
  {code: 'nya', value: 'Chichewa; Chewa; Nyanja'},
  {code: 'nym', value: 'Nyamwezi'},
  {code: 'nyn', value: 'Nyankole'},
  {code: 'nyo', value: 'Nyoro'},
  {code: 'nzi', value: 'Nzima'},
  {code: 'oci', value: 'Occitan (post 1500)'},
  {
    code: 'odt',
    value: 'Old Dutch / Old Low Franconian (ca. 400–1050)',
    notes: 'ONIX local code, equivalent to odt in ISO 639-3',
  },
  {code: 'oji', value: 'Ojibwa', notes: 'Macrolanguage'},
  {
    code: 'omq',
    value: 'Oto-Manguean languages',
    notes: 'ONIX local code, equivalent to omq in ISO 639-5. Collective name',
  },
  {code: 'ori', value: 'Oriya', notes: 'Macrolanguage'},
  {code: 'orm', value: 'Oromo', notes: 'Macrolanguage'},
  {code: 'osa', value: 'Osage'},
  {code: 'oss', value: 'Ossetian; Ossetic'},
  {code: 'ota', value: 'Turkish, Ottoman'},
  {code: 'oto', value: 'Otomian languages', notes: 'Collective name'},
  {code: 'paa', value: 'Papuan languages', notes: 'Collective name'},
  {code: 'pag', value: 'Pangasinan'},
  {code: 'pal', value: 'Pahlavi'},
  {code: 'pam', value: 'Pampanga; Kapampangan'},
  {code: 'pan', value: 'Panjabi'},
  {code: 'pap', value: 'Papiamento'},
  {code: 'pau', value: 'Palauan'},
  {code: 'peo', value: 'Old Persian (ca. 600-400 B.C.)'},
  {code: 'per', value: 'Persian; Farsi', notes: 'Macrolanguage'},
  {
    code: 'pes',
    value: 'Iranian Persian; Parsi',
    notes:
      'ONIX local code, equivalent to pes in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'phi', value: 'Philippine languages', notes: 'Collective name'},
  {code: 'phn', value: 'Phoenician'},
  {code: 'pli', value: 'Pali'},
  {
    code: 'pms',
    value: 'Piedmontese',
    notes:
      'ONIX local code for Italian dialect, equivalent to pms in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'pol', value: 'Polish'},
  {code: 'pon', value: 'Ponapeian'},
  {code: 'por', value: 'Portuguese'},
  {code: 'pra', value: 'Prakrit languages', notes: 'Collective name'},
  {code: 'pro', value: 'Provençal, Old (to 1500); Occitan, Old (to 1500)'},
  {
    code: 'prs',
    value: 'Dari; Afghan Persian',
    notes:
      'ONIX local code, equivalent to prs in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'pus', value: 'Pushto; Pashto', notes: 'Macrolanguage'},
  {
    code: 'qar',
    value: 'Aranés',
    notes:
      'ONIX local code, distinct dialect of Occitan (not distinguished from oci by ISO 639-3)',
  },
  {
    code: 'qav',
    value: 'Valencian',
    notes:
      'ONIX local code, distinct dialect of Catalan (not distinguished from cat by ISO 639-3)',
  },
  {
    code: 'qgl',
    value: 'Gallo',
    notes:
      'ONIX local code, distinct variant of langue d’oïl (old northern French) (not distinguished from fro, or from frm, fre, nrf by ISO 639-3). For use in ONIX 3.0 only',
  },
  {
    code: 'qlk',
    value: 'Lemko',
    notes:
      'ONIX local code, distinct dialect of of Rusyn (not distinguished from rue by ISO 639-3). For use in ONIX 3.0 only',
  },
  {
    code: 'qls',
    value: 'Neutral Latin American Spanish',
    notes:
      'ONIX local code, distinct and exclusively spoken variation of Spanish, not distinguished from spa (Spanish, Castilian) by ISO 639-3. Neutral Latin American Spanish should be considered a ‘shorthand’ for spa plus a ‘country code’ for Latin America – but prefer spa plus the relevant country code for specifically Mexican Spanish, Argentine (Rioplatense) Spanish, Puerto Rican Spanish etc. Neutral Latin American Spanish must only be used with audio material (including the audio tracks of TV, video and film) to indicate use of accent, vocabulary and construction suitable for broad use across Latin America. For use in ONIX 3.0 only',
  },
  {code: 'que', value: 'Quechua', notes: 'Macrolanguage'},
  {code: 'raj', value: 'Rajasthani', notes: 'Macrolanguage'},
  {code: 'rap', value: 'Rapanui'},
  {code: 'rar', value: 'Rarotongan; Cook Islands Maori'},
  {
    code: 'rcf',
    value: 'Réunion Creole French',
    notes:
      'ONIX local code, equivalent to rcf in ISO 639-3. For use in ONIX 3.0 only',
  },
  {
    code: 'rgn',
    value: 'Romagnol',
    notes:
      'ONIX local code for Italian dialect, equivalent to rgl in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'roa', value: 'Romance languages', notes: 'Collective name'},
  {code: 'roh', value: 'Romansh'},
  {code: 'rom', value: 'Romany', notes: 'Macrolanguage'},
  {code: 'rum', value: 'Romanian'},
  {code: 'run', value: 'Rundi'},
  {code: 'rup', value: 'Aromanian; Arumanian; Macedo-Romanian'},
  {code: 'rus', value: 'Russian'},
  {code: 'sad', value: 'Sandawe'},
  {code: 'sag', value: 'Sango'},
  {code: 'sah', value: 'Yakut'},
  {
    code: 'sai',
    value: 'South American Indian languages',
    notes: 'Collective name',
  },
  {code: 'sal', value: 'Salishan languages', notes: 'Collective name'},
  {code: 'sam', value: 'Samaritan Aramaic'},
  {code: 'san', value: 'Sanskrit'},
  {code: 'sas', value: 'Sasak'},
  {code: 'sat', value: 'Santali'},
  {code: 'scc', value: 'Serbian', notes: 'DEPRECATED – use srp'},
  {code: 'scn', value: 'Sicilian'},
  {code: 'sco', value: 'Scots'},
  {code: 'scr', value: 'Croatian', notes: 'DEPRECATED – use hrv'},
  {
    code: 'sdc',
    value: 'Sassarese',
    notes:
      'ONIX local code for Sardinian dialect, equivalent to sdc in ISO 639-3. For use in ONIX 3.0 only',
  },
  {
    code: 'sdn',
    value: 'Gallurese',
    notes:
      'ONIX local code for Sardinian dialect, equivalent to sdn in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'sel', value: 'Selkup'},
  {code: 'sem', value: 'Semitic languages', notes: 'Collective name'},
  {code: 'sga', value: 'Irish, Old (to 1100)'},
  {code: 'sgn', value: 'Sign languages', notes: 'Collective name'},
  {code: 'shn', value: 'Shan'},
  {code: 'sid', value: 'Sidamo'},
  {code: 'sin', value: 'Sinhala; Sinhalese'},
  {code: 'sio', value: 'Siouan languages', notes: 'Collective name'},
  {code: 'sit', value: 'Sino-Tibetan languages', notes: 'Collective name'},
  {code: 'sla', value: 'Slavic languages', notes: 'Collective name'},
  {code: 'slo', value: 'Slovak'},
  {code: 'slv', value: 'Slovenian'},
  {code: 'sma', value: 'Southern Sami'},
  {code: 'sme', value: 'Northern Sami'},
  {code: 'smi', value: 'Sami languages', notes: 'Collective name'},
  {code: 'smj', value: 'Lule Sami'},
  {code: 'smn', value: 'Inari Sami'},
  {code: 'smo', value: 'Samoan'},
  {code: 'sms', value: 'Skolt Sami'},
  {code: 'sna', value: 'Shona'},
  {code: 'snd', value: 'Sindhi'},
  {code: 'snk', value: 'Soninke'},
  {code: 'sog', value: 'Sogdian'},
  {code: 'som', value: 'Somali'},
  {code: 'son', value: 'Songhai languages', notes: 'Collective name'},
  {code: 'sot', value: 'Sotho; Sesotho'},
  {code: 'spa', value: 'Spanish'},
  {code: 'srd', value: 'Sardinian', notes: 'Macrolanguage'},
  {
    code: 'srm',
    value: 'Saramaccan',
    notes:
      'ONIX local code for Saramaccan language, equivalent to srm in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'srn', value: 'Sranan Tongo'},
  {
    code: 'sro',
    value: 'Campidanese',
    notes:
      'ONIX local code for Sardinian dialect, equivalent to sro in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'srp', value: 'Serbian'},
  {code: 'srr', value: 'Serer'},
  {code: 'ssa', value: 'Nilo-Saharan languages', notes: 'Collective name'},
  {code: 'ssw', value: 'Swazi; Swati'},
  {code: 'suk', value: 'Sukuma'},
  {code: 'sun', value: 'Sundanese'},
  {code: 'sus', value: 'Susu'},
  {code: 'sux', value: 'Sumerian'},
  {code: 'swa', value: 'Swahili', notes: 'Macrolanguage'},
  {code: 'swe', value: 'Swedish'},
  {code: 'syc', value: 'Classical Syriac'},
  {code: 'syr', value: 'Syriac', notes: 'Macrolanguage'},
  {code: 'tah', value: 'Tahitian'},
  {code: 'tai', value: 'Tai languages', notes: 'Collective name'},
  {code: 'tam', value: 'Tamil'},
  {code: 'tat', value: 'Tatar'},
  {code: 'tel', value: 'Telugu'},
  {code: 'tem', value: 'Temne; Time'},
  {code: 'ter', value: 'Terena'},
  {code: 'tet', value: 'Tetum'},
  {code: 'tgk', value: 'Tajik; Tajiki Persian'},
  {code: 'tgl', value: 'Tagalog'},
  {code: 'tha', value: 'Thai'},
  {code: 'tib', value: 'Tibetan'},
  {code: 'tig', value: 'Tigré'},
  {code: 'tir', value: 'Tigrinya'},
  {code: 'tiv', value: 'Tiv'},
  {code: 'tkl', value: 'Tokelauan'},
  {code: 'tlh', value: 'Klingon; tlhIngan-Hol', notes: 'Artificial language'},
  {code: 'tli', value: 'Tlingit'},
  {code: 'tmh', value: 'Tamashek', notes: 'Macrolanguage'},
  {code: 'tog', value: 'Tonga (Nyasa)'},
  {code: 'ton', value: 'Tongan'},
  {code: 'tpi', value: 'Tok Pisin'},
  {code: 'tsi', value: 'Tsimshian'},
  {code: 'tsn', value: 'Tswana', notes: 'AKA Setswana'},
  {code: 'tso', value: 'Tsonga'},
  {code: 'tuk', value: 'Turkmen'},
  {code: 'tum', value: 'Tumbuka'},
  {code: 'tup', value: 'Tupi languages', notes: 'Collective name'},
  {code: 'tur', value: 'Turkish'},
  {code: 'tut', value: 'Altaic languages'},
  {code: 'tvl', value: 'Tuvaluan'},
  {code: 'twi', value: 'Twi'},
  {code: 'tyv', value: 'Tuvinian'},
  {
    code: 'tzo',
    value: 'Tzotzil',
    notes: 'ONIX local code, equivalent to tzo in ISO 639-3',
  },
  {code: 'udm', value: 'Udmurt'},
  {code: 'uga', value: 'Ugaritic'},
  {code: 'uig', value: 'Uighur; Uyghur'},
  {code: 'ukr', value: 'Ukrainian'},
  {code: 'umb', value: 'Umbundu'},
  {code: 'und', value: 'Undetermined language'},
  {code: 'urd', value: 'Urdu'},
  {code: 'uzb', value: 'Uzbek', notes: 'Macrolanguage'},
  {code: 'vai', value: 'Vai'},
  {
    code: 'vec',
    value: 'Venetian/Venetan',
    notes:
      'ONIX local code for Italian dialect, equivalent to vec in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'ven', value: 'Venda'},
  {code: 'vie', value: 'Vietnamese'},
  {code: 'vol', value: 'Volapük', notes: 'Artificial language'},
  {code: 'vot', value: 'Votic'},
  {code: 'wak', value: 'Wakashan languages', notes: 'Collective name'},
  {code: 'wal', value: 'Wolaitta; Wolaytta'},
  {code: 'war', value: 'Waray'},
  {code: 'was', value: 'Washo'},
  {code: 'wel', value: 'Welsh'},
  {code: 'wen', value: 'Sorbian languages', notes: 'Collective name'},
  {code: 'wln', value: 'Walloon'},
  {code: 'wol', value: 'Wolof'},
  {
    code: 'wym',
    value: 'Wymysorys',
    notes:
      'Vilamovian. ONIX local code, equivalent to wym in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'xal', value: 'Kalmyk; Oirat'},
  {code: 'xho', value: 'Xhosa'},
  {
    code: 'xuu',
    value: 'Khwedam, Kxoe',
    notes:
      'ONIX local code, equivalent to xuu in ISO 639-3. For use in ONIX 3.0 only',
  },
  {code: 'yao', value: 'Yao'},
  {code: 'yap', value: 'Yapese'},
  {code: 'yid', value: 'Yiddish', notes: 'Macrolanguage'},
  {code: 'yor', value: 'Yoruba'},
  {code: 'ypk', value: 'Yupik languages', notes: 'Collective name'},
  {
    code: 'yue',
    value: 'Cantonese',
    notes: 'ONIX local code, equivalent to yue in ISO 639-3',
  },
  {code: 'zap', value: 'Zapotec', notes: 'Macrolanguage'},
  {
    code: 'zbl',
    value: 'Blissymbols; Blissymbolics; Bliss',
    notes: 'Artificial language',
  },
  {code: 'zen', value: 'Zenaga'},
  {code: 'zgh', value: 'Standard Moroccan Tamazight'},
  {code: 'zha', value: 'Zhuang; Chuang', notes: 'Macrolanguage'},
  {code: 'znd', value: 'Zande languages', notes: 'Collective name'},
  {code: 'zul', value: 'Zulu'},
  {code: 'zun', value: 'Zuni'},
  {code: 'zxx', value: 'No linguistic content'},
  {
    code: 'zza',
    value: 'Zaza; Dimili; Dimli; Kirdki; Kirmanjki; Zazaki',
    notes: 'Macrolanguage',
  },
];
