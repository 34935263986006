import {Concept} from 'types';
import {AgentWorkSummary, WorkSummary} from 'api/types';
import {TableConfiguration} from 'schema/table/types';
import {CreateLinkFromRowValue} from 'schema/types';
import {VerifiedLinkedCatalogPostLink} from 'schemaDefinition/types';
import {workSummaryTableConfig} from './workSummaryTableConfig';

export const agentWorkSummaryTableConfig: TableConfiguration<AgentWorkSummary> =
  {
    ...workSummaryTableConfig,
    defaultColumns: [
      {
        name: 'roles',
        visible: true,
        width: 150,
      },
      ...workSummaryTableConfig.defaultColumns,
    ],
  };

export const createLinkFromWorkSummaryRow: CreateLinkFromRowValue = value => {
  const link: VerifiedLinkedCatalogPostLink = {
    type: Concept.work,
    linkStatus: 'verified',
    entityId: (value as WorkSummary).id,
    name: 'title',
  };
  return link;
};
