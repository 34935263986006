import {CodeListRef} from 'api/types';
import {Schemas} from 'api/dto.generated';

const dto: Schemas.WorkFlattenedDto = {
  work: {
    id: '3c32868c-69ae-48c5-9674-42273022f090',
    created: 1644328308.703405,
    modified: 1644328308.703406,
    titleInfo: {
      preferredTitle: ['Songs'],
      otherTitles: [],
    },
    // manifestations: ['9b0726fd-bca2-4bf9-b331-507e4e41ff1d'],
  },
  expressions: [
    {
      id: '9b0726fd-bca2-4bf9-b331-507e4e41fee',
      workId: '3c32868c-69ae-48c5-9674-42273022f090',
      created: 1644328308.703405,
      modified: 1644328308.703406,
      expressionFormat: CodeListRef.EXPRESSION_FORMAT.TEXT,
      languages: [],
      content: [],
      agents: [
        {
          name: 'Ringnes, Vivi',
          roles: [CodeListRef.EXPRESSION_ROLE_TYPE['Illustrert av']],
        },
      ],
    },
    {
      id: '9b0726fd-bca2-4bf9-b331-507e4e41fdd',
      workId: '3c32868c-69ae-48c5-9674-42273022f090',
      created: 1644328308.703405,
      modified: 1644328308.703406,
      expressionFormat: CodeListRef.EXPRESSION_FORMAT.AUDIO,
      languages: [],
      content: [],
      agents: [
        {
          name: 'Ringnes, Vivi',
          roles: [], // [CodeListRef.EXPRESSION_ROLE_TYPE['Av (komponist)']],
        },
      ],
    },
  ],
  manifestations: [
    {
      id: '9b0726fd-bca2-4bf9-b331-507e4e41ff1d',
      expressionId: '9b0726fd-bca2-4bf9-b331-507e4e41fee',
      created: 1644328308.702023,
      modified: 1644328308.702024,
      status: 'PRE_CATALOGED',
      isbn: '9788205547766',
      ean: '9788205547766',
      mainTitle: 'Forsøkshefte',
      edition: 1,
    },
    {
      id: '537bbdb2-5cb5-4a70-8d0f-497570ff3837',
      expressionId: '9b0726fd-bca2-4bf9-b331-507e4e41fdd',
      created: 1644328308.702023,
      modified: 1644328308.702024,
      status: 'PRE_CATALOGED',
      isbn: '9788205547777',
      ean: '9788205547777',
      mainTitle: 'Forsøkshefte',
      edition: 1,
      // productForm: CodeListRef.PRODUCT_FORM['DVD (lyd)'],
    },
  ],
};

export default dto;
