import {SxProps} from '@mui/material';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {Icon, Layout, Spacer, Text} from 'components';

const containerSx: SxProps = {
  backgroundColor: ColorPalette.warning40,
  borderRadius: 9999,
  mx: 1,
  my: 0.5,
  py: 1,
  pl: 1,
  pr: 1.5,
};

type Props = {
  type: 'expression' | 'work';
};

export const EmptyAncestorsWarning: React.FC<Props> = ({type}) => {
  const {t} = useLocalization();
  return (
    <Layout sx={containerSx} horizontal adjustCenter>
      <Icon icon="Info" fontSize="small" />
      <Spacer size={0.5} />
      <Text variant="body2">
        {t(`page.metadata.manifestation.tools.move.level2.${type}.warning`)}
      </Text>
    </Layout>
  );
};
