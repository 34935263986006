import {assert} from 'assert-ts';
import {WorkWithExpressions} from 'api/types';
import {isWorkWithExpressions} from './isWorkWithExpressions';

export const assertWorkWithExpressions = (
  value: unknown,
): WorkWithExpressions => {
  assert(isWorkWithExpressions(value), 'assertWorkWithExpressions: invalid', {
    value,
  });

  return value as WorkWithExpressions;
};
