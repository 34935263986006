import {assert} from 'assert-ts';
import {ThesaurusAction} from 'types';
import {FieldExtensionEnum} from 'sceneExtensions/types';
import {ThesaurusValue} from 'schemaDefinition/types';
import {useSceneExtensionContext} from 'sceneExtensions/contexts';
import {ThesaurusFieldConfig} from '../types';

export const useThesaurusEditContext = () => {
  const extension = useSceneExtensionContext<
    FieldExtensionEnum.thesaurusEdit,
    ThesaurusValue,
    ThesaurusValue,
    ThesaurusAction,
    ThesaurusFieldConfig
  >(FieldExtensionEnum.thesaurusEdit);
  return assert(extension).fieldContext;
};
