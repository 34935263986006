import {assert} from 'assert-ts';
import {DataValue, Part, Valx} from 'schemaDefinition/types';
import {isNullish} from 'services/utils';

// Partial value regex
const regex0_3d = /^\d{0,3}$/;
const regex3dSlash = /^\d{3}\/?$/;
const regex3dDotnd = /^\d{3}\.\d*$/;
const regex3dSlashDotnd = /^\d{3}\/?\.\d*$/;
const regex3dDotndSlashnd = /^\d{3}\.\d*\/\d*$/;

export const partialDeweyNumberValidator = (
  value: DataValue,
  part?: Part<Valx>,
): boolean => {
  if (
    !assert.soft(
      isNullish(value) || typeof value === 'string',
      'deweyNumberValidator: value must be nullish or string',
      {value},
    ) ||
    !assert.soft(
      part && part.customizeKey === 'deweyNumber',
      'deweyNumberModifier: part must have customizeKey deweyNumber',
      part,
    )
  ) {
    return false;
  }

  const strVal = value as string | null;

  if (isNullish(strVal)) {
    return true;
  }

  return (
    regex0_3d.test(strVal) ||
    regex3dSlash.test(strVal) ||
    regex3dDotnd.test(strVal) ||
    regex3dSlashDotnd.test(strVal) ||
    regex3dDotndSlashnd.test(strVal)
  );
};
