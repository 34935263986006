import {Configuration} from './configuration';

export default {
  Auth: {
    region: 'eu-north-1',
    userPoolId: Configuration.user.cognitoUserPoolId,
    userPoolWebClientId: Configuration.user.cognitoUserpoolClientId,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_SRP_AUTH',
    oauth: {
      domain: Configuration.user.cognitoDomain,
      scope: Configuration.user.cognitoScope.split('+'),
      redirectSignIn: Configuration.user.cognitoLoginCallback,
      redirectSignOut: Configuration.user.cognitoLogoutCallback,
      responseType: Configuration.user.cognitoResponseType,
      clientId: Configuration.user.cognitoUserpoolClientId,
    },
  },
};
