import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {MultiSelectTreeViewProps, TreeView} from '@mui/x-tree-view';
import {ThesaurusValue} from 'api/types';
import {useLocalization} from 'localization';
import {TreeNode} from '../types';
import {ChevronLeftIcon, ChevronRightIcon} from '../../icons';
import {
  ThesaurusSelectionContext,
  useThesaurusSelectionContextProviderValue,
} from '../contexts/ThesaurusSelectionContext';
import {ThesaurusItem} from './ThesaurusItem';

// Use MultiSelectTreeViewProps to be able to use controlled
// tree view with empty initial selected
// although only one node may be selected at a time.
type Props = MultiSelectTreeViewProps & {
  // The data structure
  data: TreeNode[];
  /**
   * Terms (node ids) set.
   * Nodes will be marked in the tree.
   */
  thesaurusValue?: ThesaurusValue;
  /**
   * gets the display code for a node, if any
   */
  getDisplayCode: (node: TreeNode) => string | undefined;
  /**
   * Current focused node id, if any,
   * either node clicked by user in the tree or
   * term clicked in the work.
   */
  focusedNodeId?: string;
  onlyTopLevel?: boolean;
};

const treeSx = {flexGrow: 1, flex: 1, marginRight: 2} as const;

export const Thesaurus: React.FC<Props> = ({
  data,
  thesaurusValue,
  getDisplayCode,
  focusedNodeId,
  onlyTopLevel,
  ...props
}) => {
  const {t} = useLocalization();
  const contextValue = useThesaurusSelectionContextProviderValue(
    data,
    thesaurusValue,
    focusedNodeId,
  );
  const renderNode = useCallback(
    (node: TreeNode) => {
      return (
        <ThesaurusItem
          key={node.id}
          nodeId={node.id}
          // disabled={node.deactivated}
          code={getDisplayCode?.(node)}
          label={
            node.deactivated
              ? `${node.label} (${t('general.deactivated')})`
              : node.label
          }
          onlyTopLevel={onlyTopLevel}>
          {!onlyTopLevel && Array.isArray(node.children)
            ? node.children.map(renderNode)
            : null}
        </ThesaurusItem>
      );
    },
    [getDisplayCode, onlyTopLevel, t],
  );

  return (
    <ThesaurusSelectionContext.Provider value={contextValue}>
      <TreeView
        aria-label="thema thesaurus"
        defaultCollapseIcon={<ChevronLeftIcon color={'primary'} />}
        defaultExpandIcon={<ChevronRightIcon color={'primary'} />}
        sx={treeSx}
        {...props}
        defaultSelected={undefined}
        selected={focusedNodeId ? [focusedNodeId] : []}>
        {useMemo(() => data.map(renderNode), [data, renderNode])}
      </TreeView>
    </ThesaurusSelectionContext.Provider>
  );
};
