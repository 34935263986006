import React from 'react';
import {Layout} from 'components/layout';
import {Spacer} from 'components/spacer';
import {Text} from 'components/text';
import {WizardStepper, WizardStepperProps} from './WizardStepper';

type Props = WizardStepperProps & {
  title: string;
};

export const WizardHeader: React.FC<Props> = ({title, ...stepperProps}) => {
  return (
    <Layout>
      <Text variant="h2">{title}</Text>
      <Spacer size={2} />
      <WizardStepper {...stepperProps} />
    </Layout>
  );
};
