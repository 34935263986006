import {DataLoadStatus, SystemHealth} from 'api/types';
import {SystemHealthStatus} from '../types';
import {getQueueHealth} from '../functions';

export const useAggregatedSystemStatus = (
  healthData: DataLoadStatus<SystemHealth>,
): SystemHealthStatus => {
  // Red if failed to load health data
  if (healthData.status === 'Failed') {
    return 'red';
  }

  // Green if no health data yet
  if (!healthData.data) {
    return 'green';
  }

  // Aggregated status:
  // Red if any sync component is down, any message queue has more than 50 messages or any service is down
  // Yellow if any message queue has more than 5 messages
  // Green otherwise
  const {syncComponents, messageQueues, services} = healthData.data;
  if (
    syncComponents.some(component => component.status === 'DOWN') ||
    messageQueues.some(queue => getQueueHealth(queue) === 'red') ||
    services.some(service => service.status === 'DOWN')
  ) {
    return 'red';
  } else if (messageQueues.some(queue => getQueueHealth(queue) === 'yellow')) {
    return 'yellow';
  } else {
    return 'green';
  }
};
