import {Fragment} from 'react';
import {PartValueRenderer} from 'schema/types';
import {MainTitle} from 'api';
import {Builder, isNullish} from 'services/utils';
import {FlexBox, TextMatch} from 'components';

const makeTitleWithParallelTitles = (
  value?: string,
  parallelTitles?: string[],
): string | undefined => {
  const parallel = (parallelTitles ?? []).join('; ');
  const title = new Builder(value).add(' = ', parallel).value;

  return title;
};

export const MainTitlesCell: PartValueRenderer = ({value, match}) => {
  return isNullish(value) ||
    !Array.isArray(value) ||
    value.length === 0 ? null : (
    <FlexBox>
      {value.map((v, i) => {
        const mainTitle = v as MainTitle;
        return (
          <Fragment key={i}>
            <TextMatch key={i} variant="body1" match={match}>
              {makeTitleWithParallelTitles(
                mainTitle.value,
                mainTitle.parallelTitles,
              )}
            </TextMatch>
            {mainTitle.subTitles ? (
              <FlexBox ml={1} component={'span'}>
                {mainTitle.subTitles.map((st, j) => (
                  <TextMatch key={j} variant="body1" match={match}>
                    {makeTitleWithParallelTitles(st.value, st.parallelTitles)}
                  </TextMatch>
                ))}
              </FlexBox>
            ) : null}
          </Fragment>
        );
      })}
    </FlexBox>
  );
};
