import {Media} from 'api/types';
import {LocalizationContextType} from 'localization/context/types';

export const formatMedia = (
  media?: Media[],
  localization?: LocalizationContextType,
): string | undefined => {
  if (!localization) return undefined;

  const {tLoose} = localization;

  const value = media
    ?.filter(m => m.type === 'TRYKKUNDERLAG' && m.subType !== 'ATTACHMENT')
    .map(m => tLoose(`page.overview.task.mediaSubType.${m.subType}.title`))
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort()
    .join(', ');

  return value
    ? value[0].toLocaleUpperCase() + value.substring(1).toLocaleLowerCase()
    : undefined;
};
