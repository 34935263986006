import {CodeList, CodeListId} from 'api';

export const getDummyCodeList = (id: CodeListId): CodeList => ({
  id,
  language: 'nb',
  codes: [
    {
      code: 'code1',
      value: `${id}-1`,
    },
    {
      code: 'code1',
      value: `${id}-2`,
    },
    {
      code: 'code1',
      value: `${id}-3`,
    },
  ],
});
