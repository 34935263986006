import {useMemo} from 'react';
import {useLocalization} from 'localization';
import {ManifestationStatus} from 'api';
import {Layout, Spacer, Text} from 'components';

type Props = {
  status: ManifestationStatus | undefined;
};

export const WorkValidationErrorHeader: React.FC<Props> = ({status}) => {
  const localization = useLocalization();
  const {t, tLoose} = localization;
  const statusLabel = useMemo(() => {
    return tLoose(`entity.manifestation.status.short.${status}`);
  }, [status, tLoose]);

  return (
    <Layout horizontal adjustLeft>
      <Text variant="body2" sx={{fontWeight: 'bold'}}>
        {t(`entity.work.title`)}
      </Text>
      <Spacer size={0.5} />
      <Text variant="body2">{`(${statusLabel})`}</Text>
    </Layout>
  );
};
