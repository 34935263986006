import React from 'react';
import {SxProps} from '@mui/material';
import {IconName} from 'components/icons/types';
import {px2rem} from 'theme';
import {FlexBox, Icon} from 'components';
import {SystemHealthStatus} from '../types';

type Size = 'small' | 'medium' | 'large';
type Props = {
  status: SystemHealthStatus;
  size?: Size;
  icon?: IconName;
};

const indicatorSmallSx: SxProps = {
  width: `${px2rem(6)}`,
  height: `${px2rem(6)}`,
  borderRadius: '50%',
  transition: 'background-color 0.3s ease-in-out!important',
};

const indicatorMediumSx: SxProps = {
  width: `${px2rem(14)}`,
  height: `${px2rem(14)}`,
  borderRadius: '50%',
  transition: 'background-color 0.3s ease-in-out!important',
};

const indicatorLargeSx: SxProps = {
  width: `${px2rem(20)}`,
  height: `${px2rem(20)}`,
  borderRadius: '50%',
  transition: 'background-color 0.3s ease-in-out!important',
};

// TODO: Use from ColorPalette when move to work has been merged
const greenSx: SxProps = {
  backgroundColor: '#A5CE90',
};

const yellowSx: SxProps = {
  backgroundColor: '#FFBD12',
};

const redSx: SxProps = {
  backgroundColor: '#AD4F49',
};

const healthSxMap: Record<SystemHealthStatus, Record<Size, SxProps>> = {
  green: {
    small: {...indicatorSmallSx, ...greenSx},
    medium: {...indicatorMediumSx, ...greenSx},
    large: {...indicatorLargeSx, ...greenSx},
  },
  yellow: {
    small: {...indicatorSmallSx, ...yellowSx},
    medium: {...indicatorMediumSx, ...yellowSx},
    large: {...indicatorLargeSx, ...yellowSx},
  },
  red: {
    small: {...indicatorSmallSx, ...redSx},
    medium: {...indicatorMediumSx, ...redSx},
    large: {...indicatorLargeSx, ...redSx},
  },
};

export const HealthIndicator: React.FC<Props> = ({status, size = 'small'}) => {
  const sx = healthSxMap[status][size];
  const icon = status === 'green' || size === 'small' ? undefined : 'Info';
  return (
    <FlexBox sx={sx} center>
      {icon ? (
        <Icon
          icon={icon}
          fontSize={size}
          color={status === 'yellow' ? 'primary' : undefined}
        />
      ) : null}
    </FlexBox>
  );
};
