import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const BuildingIcon: React.FC<IconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M6 19H5V20H6V19ZM18 19V20H19V19H18ZM10 19V20H11V19H10ZM14 19H13V20H14V19ZM7 19V6H5V19H7ZM17 4H7V6H17V4ZM17 6V19H19V6H17ZM6 20H10V18H6V20ZM11 19V18H9V19H11ZM11 18H13V16H11V18ZM13 18V19H15V18H13ZM14 20H18V18H14V20ZM11 18V16C9.89543 16 9 16.8954 9 18H11ZM17 6H19C19 4.89543 18.1046 4 17 4V6ZM13 18H15C15 16.8954 14.1046 16 13 16V18ZM7 6L7 6V4C5.89543 4 5 4.89543 5 6H7Z"
        // fill="#480905"
      />
      <rect
        x="9"
        y="8"
        width="2"
        height="2"
        // fill="#480905"
      />
      <rect
        x="13"
        y="8"
        width="2"
        height="2"
        // fill="#480905"
      />
      <rect
        x="9"
        y="12"
        width="2"
        height="2"
        // fill="#480905"
      />
      <rect
        x="13"
        y="12"
        width="2"
        height="2"
        // fill="#480905"
      />
    </SvgIcon>
  );
};
