import {isNullish} from 'services/utils/isNullish';
import {PhysicalProperties} from '../types';
import {Schemas} from '../dto.generated';

export const mapPhysicalPropertiesDto = (
  dto: Schemas.PhysicalPropertyDto[] = [],
): PhysicalProperties => {
  const properties: PhysicalProperties = {
    colour: undefined,
    epages: undefined,
    scale: [],
  };

  dto.forEach(field => {
    if (field.value) {
      switch (field.type) {
        case 'COLOUR':
          properties.colour = field.value;
          break;
        case 'EPAGES':
          properties.epages = field.value;
          break;
        case 'SCALE':
          properties.scale = [...(properties.scale ?? []), field.value];
          break;
      }
    }
  });

  return properties;
};

export const mapToPhysicalPropertiesDto = (
  colour: string | undefined,
  epages: string | undefined,
  scale: string[] | undefined,
): Schemas.PhysicalPropertyDto[] | undefined => {
  const properties: Schemas.PhysicalPropertyDto[] = [];
  if (!isNullish(colour)) properties.push({type: 'COLOUR', value: colour});
  if (!isNullish(epages)) properties.push({type: 'EPAGES', value: epages});

  if (!isNullish(scale)) {
    scale.forEach(s => properties.push({type: 'SCALE', value: s}));
  }

  return properties.length === 0 ? undefined : properties;
};
