import {ValidationError} from 'schemaDefinition/types';
import {ValidationResult} from './types';

/**
 * Finds first most severe error pr part
 * @param validation
 * @returns
 */
export const getValidationPrPart = (
  validation: ValidationResult | undefined,
): ValidationResult | undefined => {
  if (!validation) {
    return undefined;
  }

  const firstErrorPrPart = validation.errors.reduce<ValidationError[]>(
    (acc, error) => {
      const prevError = acc.find(err => err.valuePath === error.valuePath);
      if (error.level === 'warning') {
        return prevError ? acc : [...acc, error];
      }

      return prevError
        ? prevError.level === 'warning'
          ? [...acc.filter(err => err.valuePath !== error.valuePath), error]
          : acc
        : [...acc, error];
    },
    [],
  );

  return {
    valid: validation.valid,
    errors: firstErrorPrPart,
  };
};
