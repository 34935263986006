import {SxProps, Theme, useTheme} from '@mui/material';
import Grid from '@mui/material/Grid';
import {FCWithChildren} from 'types';

type Transition = 'slide' | 'fade';
type Props = {
  span?: number;
  xsSpan?: number;
  sx?: SxProps<Theme>;
  transition?: Transition;
};

export const GridItem: FCWithChildren<Props> = ({
  span,
  xsSpan,
  sx,
  transition = 'slide',
  children,
}) => {
  const theme = useTheme();
  return (
    <Grid
      item
      sm={xsSpan ?? true}
      md={span ?? true}
      sx={{
        ...sx,
        transition:
          transition === 'slide'
            ? theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: '1s', //theme.transitions.duration.leavingScreen,
              })
            : theme.transitions.create('opacity', {
                easing: theme.transitions.easing.sharp,
                duration: '1s', // theme.transitions.duration.leavingScreen,
                delay: '1s',
              }),
      }}>
      {children}
    </Grid>
  );
};
