import {useMemo} from 'react';
import {
  ContentLabelWithLayout,
  PersonNameVariantFieldLayout,
} from 'components/fields/types';
import {mapValues} from 'services/utils';
import {useLabelsAndPlaceholders} from './useLabelsAndPlaceholders';

export const usePersonNameVariantLabelsAndPlaceholders = (
  showRegnalNumber: boolean,
  showSuffix: boolean,
  fieldLayout: PersonNameVariantFieldLayout,
): {
  contentLabels: ContentLabelWithLayout[];
  fieldLayoutWithPlaceholders: PersonNameVariantFieldLayout;
} => {
  const labelsAndPlaceholders = useLabelsAndPlaceholders([
    'surName',
    'name',
    'regnalNumber',
    'addition',
  ]);

  return useMemo(() => {
    const requiredFields = ['name', 'surName'];
    const labelKeys: (keyof PersonNameVariantFieldLayout)[] = [
      'surName',
      'name',
    ];
    if (showRegnalNumber) labelKeys.push('regnalNumber');
    if (showSuffix) labelKeys.push('addition');
    labelKeys.push('mainForm');

    return {
      // Content labels only for selected fields
      contentLabels: labelKeys.map(key => ({
        ...fieldLayout[key],
        label:
          key !== 'mainForm' ? labelsAndPlaceholders[key].label : undefined,
        required: requiredFields.includes(key),
      })),
      fieldLayoutWithPlaceholders: mapValues(fieldLayout, (value, key) => ({
        ...value,
        placeholder:
          key !== 'mainForm'
            ? labelsAndPlaceholders[key].placeholder
            : undefined,
      })),
    };
  }, [fieldLayout, labelsAndPlaceholders, showRegnalNumber, showSuffix]);
};
