import {Concept, EntityMainType, EntitySubType, EntitySubTypes} from 'types';

export const isSubTypeOfEntity = (
  subEntity: Concept | undefined,
  entity: Concept | undefined,
): boolean => {
  if (!subEntity || !entity) {
    return false;
  }

  return (EntitySubTypes[entity as EntityMainType] ?? []).includes(
    subEntity as EntitySubType,
  );
};
