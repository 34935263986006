import {assert} from 'assert-ts';
import {logError} from 'logging';
import {RegexCondition, Valx} from '../types';
import {EvaluationArgs} from './types';
import {evaluateValxWithDefault} from './evaluateValxWithDefault';

const regexpCache: {[key: string]: RegExp} = {};

export const evaluateRegexCondition = (
  condition: RegexCondition<Valx>,
  args: EvaluationArgs,
): boolean => {
  const value = evaluateValxWithDefault(condition.arg, args, args.value);

  if (value === undefined || value === null) {
    return condition.default;
  }

  const strValue = typeof value === 'string' ? value : JSON.stringify(value);

  if (value === '') {
    return condition.default;
  }

  const regexps = Array.isArray(condition.regex)
    ? condition.regex
    : [condition.regex];
  return regexps.reduce<boolean>((acc, re) => {
    if (acc) {
      return acc;
    }
    const cached = regexpCache[re];
    const regex = cached ?? makeRegex(re);
    if (!cached && regex) {
      regexpCache[re] = regex;
    }

    if (!regex) {
      assert.soft(false, 'evaluateRegexCondition: invalid regex', {re});
      return true;
    }

    return regex.test(strValue);
  }, false);
};

const makeRegex = (regexStr: string): RegExp | undefined => {
  try {
    return new RegExp(regexStr, 'i');
  } catch (err) {
    logError(`RegexCondition: invalid: ${regexStr}`);
    return undefined;
  }
};
