import React, {useCallback, useMemo} from 'react';
import {SxProps, Typography} from '@mui/material';
import {ThesaurusNode} from 'api/types';
import {IconName} from 'components/icons/types';
import {SelectActionType} from 'feature/editThesaurus/types';
import {ShowThesaurusCode} from 'schemaDefinition/types';
import {ColorPalette} from 'theme';
import {formatNodeTitle} from 'services/thesaurus/functions/formatNodeTitle';
import {noOp} from 'services/utils';
import {Icon, IconButton} from 'components';
import {FlexBox, Layout} from 'components/layout';
import {UnstyledButton} from '../../button/UnstyledButton';

type Props = {
  node: ThesaurusNode;
  showCode?: ShowThesaurusCode;
  selectableNodeTypes?: string[];
  highlight: boolean;
  highlightType?: SelectActionType;
  canMove?: boolean;
  onDelete?: (nodeId: string) => void;
  onSelect?: (node: ThesaurusNode) => void;
};

const textSx: SxProps = {
  color: ColorPalette.burgundy,
  cursor: 'pointer',
} as const;

export const ThesaurusValue: React.FC<Props> = ({
  node,
  showCode,
  selectableNodeTypes,
  highlight,
  highlightType,
  canMove = true,
  onDelete,
  onSelect,
}) => {
  const {containerSx, title, backgroundColor, icon} = useMemo(() => {
    const title = formatNodeTitle(node, showCode, selectableNodeTypes);
    const backgroundColor =
      highlight && (highlightType === 'swap' || highlightType === 'remove')
        ? ColorPalette.offWhite
        : ColorPalette.beige;
    const borderColor =
      highlight && (highlightType === 'swap' || highlightType === 'remove')
        ? ColorPalette.terracotta
        : ColorPalette.beige;
    const icon: IconName | undefined =
      highlight && highlightType === 'swap'
        ? 'Swap'
        : canMove
          ? 'Drag'
          : undefined;
    const containerSx: SxProps = {
      borderRadius: 1,
      borderColor: borderColor,
      borderStyle: 'solid',
      borderWidth: '2px',
      pl: canMove ? 0.75 : 1.25,
      pr: onDelete ? 0.75 : 1.25,
    } as const;

    return {containerSx, title, backgroundColor, icon};
  }, [
    canMove,
    highlight,
    highlightType,
    node,
    onDelete,
    selectableNodeTypes,
    showCode,
  ]);

  const handleSelect = useCallback(() => {
    onSelect && onSelect(node);
  }, [node, onSelect]);

  return node ? (
    <Layout
      horizontal
      alignItems={'center'}
      bgcolor={backgroundColor}
      sx={containerSx}>
      {icon && <Icon icon={icon} fontSize="small" />}
      <Layout>
        <UnstyledButton onClick={handleSelect}>
          <Typography variant="body1" align="left" sx={textSx}>
            {title}
          </Typography>
        </UnstyledButton>
      </Layout>
      {onDelete ? (
        <IconButton
          data-cy="button-delete"
          icon="CloseSmall"
          size="small"
          onClick={() => onDelete(node.code)}
        />
      ) : (
        // "show" button to keep the layout consistent
        <FlexBox hidden width={0}>
          <IconButton
            data-cy="button-delete"
            icon="CloseSmall"
            size="small"
            onClick={noOp}
          />
        </FlexBox>
      )}
    </Layout>
  ) : null;
};
