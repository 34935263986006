/**
 * Number of padding items to add for value and compareValue
 * to make them equal in size
 */
export const useValuePaddingSize = (
  value: unknown,
  compareValue: unknown,
): number => {
  const valueSize = Array.isArray(value) ? value.length : 0;
  const compareValueSize = Array.isArray(compareValue)
    ? compareValue.length
    : 0;

  return compareValueSize > valueSize ? compareValueSize - valueSize : 0;
};
