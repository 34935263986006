import React from 'react';
import {ErrorBoundary as SentryErrorBoundary} from '@sentry/react';
import {FallbackRender} from '@sentry/react/types/errorboundary';
import {FCWithChildren, Indexer} from 'types';
import {translate} from 'localization';
import {ColorPalette} from 'theme';
import {Layout} from 'components/layout';
import {Spacer} from 'components/spacer';
import {Text} from 'components/text';

type Props = {
  fallback?: FallbackRender;
};

type ExtendedError = Error & {
  props?: Indexer;
};

const ViewError: FallbackRender = ({error, componentStack}) => {
  // Funker ikke med hooks i denne, så kaller translate direkte
  const title = translate('error.unexpected.title');
  const guide = translate('error.unexpected.guide');
  const detailsHeader = translate('error.unexpected.details.title');
  const extendedError = error as ExtendedError;

  return (
    <Layout pl={2}>
      <Text variant="h2">{title}</Text>
      <Spacer size={1} />
      <Text variant="body2">{guide}</Text>
      <Spacer size={2} />
      <details style={{whiteSpace: 'pre-wrap', color: ColorPalette.burgundy}}>
        <summary>
          <Text component="span" variant="h3">
            {detailsHeader}
          </Text>
        </summary>
        {error && error.toString()}
        <br />
        {extendedError.props && JSON.stringify(extendedError.props, null, 2)}
        <br />
        {componentStack}
      </details>
    </Layout>
  );
};

export const ErrorBoundary: FCWithChildren<Props> = ({fallback, children}) => (
  <SentryErrorBoundary fallback={fallback ?? ViewError}>
    {children}
  </SentryErrorBoundary>
);
