import {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'store/types';
import {AgentTypeAll, SettingsAction} from 'services/settings';
import {getAgentTypes} from '../functions';

const selectedAgentTypeSelector = (state: AppState) =>
  state.settings.agents.selectedAgentType;

export const useAgentTypes = () => {
  const dispatch = useDispatch();
  const selectedAgentType = useSelector(selectedAgentTypeSelector);

  const setSelectedAgentType = useCallback(
    (selectedAgentType: AgentTypeAll) => {
      const action: SettingsAction = {
        type: 'SET_AGENTS_SELECTED_TASK_TYPE',
        payload: selectedAgentType,
      };
      dispatch(action);
    },
    [dispatch],
  );

  const agentTypes = useMemo(
    () => getAgentTypes(selectedAgentType),
    [selectedAgentType],
  );

  return {
    agentTypes,
    selectedAgentType,
    setSelectedAgentType,
  };
};
