import {useMemo} from 'react';
import {Schema} from 'schemaDefinition/types';
import {getSchemaFlattended} from 'schemaDefinition/functions';
import {PartValueRenderers} from '../types';

export const useTableSchema = (
  schema: Schema,
  partValueRenderers?: PartValueRenderers,
): Schema => {
  return useMemo(() => {
    const flatSchema = getSchemaFlattended(schema);
    const filteredSchema = {
      ...flatSchema,
      parts: flatSchema.parts.filter(
        p =>
          !Array.isArray(p) &&
          p.type !== 'separator' &&
          p.type !== 'expand' &&
          (p.type !== 'schema' || partValueRenderers?.[p.name]),
      ),
    };

    return filteredSchema;
  }, [partValueRenderers, schema]);
};
