import React from 'react';
import {PartValueRenderer} from 'schema/types';
import {FormatIcon, Layout} from 'components';

export const ProductForm: PartValueRenderer = ({value}) => {
  if (typeof value === 'string') {
    return (
      <Layout flex={1} adjustLeft horizontal kind="primary">
        {value ? <FormatIcon productForm={value} /> : null}
      </Layout>
    );
  }

  return null;
};
