import React from 'react';
import Typography from '@mui/material/Typography';
import {ColorPalette} from 'theme';
import {Card} from 'components';
import {useLocalization} from '../../../localization';

export const ThemaCategoryGuide: React.FC = () => {
  const {t} = useLocalization();

  return (
    <Card colorx={ColorPalette.offWhite}>
      <Card.Header>
        <Typography
          variant={'h3'}
          align={'center'}
          color={ColorPalette.primary.burgundy}
          fontSize={'1.2em'}>
          {t('text.thema.rule.header')}
        </Typography>
      </Card.Header>
      <Card.Content>
        <Card colorx={ColorPalette.lightBeige}>
          <Card.Content>
            <Typography variant={'body2'}>
              <ol className={'themaRules'}>
                <li>{t('text.thema.rule.first')}</li>
                <li>{t('text.thema.rule.second')}</li>
                <li>{t('text.thema.rule.third')}</li>
                <li>{t('text.thema.rule.fourth')}</li>
                <li>{t('text.thema.rule.fifth')}</li>
                <li>{t('text.thema.rule.sixth')}</li>
              </ol>
            </Typography>
          </Card.Content>
        </Card>
      </Card.Content>
    </Card>
  );
};
