import {Fragment, useCallback, useMemo, useState} from 'react';
import {
  CustomMenuItemProps,
  MenuDividerProps,
  MenuItemProps,
  StandardMenuItemProps,
} from 'components/popups/types';
import {useLocalization} from 'localization';
import {MoveIcon, PopupMenu, ToolButton} from 'components';
import {useMoveManifestationContext} from '../../hooks';
import {EmptyAncestorsWarning} from './EmptyAncestorsWarning';
import {ExpressionItem} from './ExpressionItem';

type Props = {
  manifestationId: string;
  disabled?: boolean;
  miniMode?: boolean;
};

const MoveActions = ['moveToExpression', 'moveToWork'] as const;

export const ManifestationMoveButton: React.FC<Props> = ({
  manifestationId,
  disabled,
  miniMode,
}) => {
  const {t} = useLocalization();

  const {
    expressions,
    currentExpressionId,
    manifestationCountInExpression,
    manifestationCountInWork,
    moveManifestationToOtherExpression,
    moveManifestationToNewExpression,
    moveManifestationToOtherWork,
    moveManifestationToNewWork,
    ConfirmMoveToOtherWork,
    isConfirmMoveToOtherOpen,
    isConfirmMoveToOtherClosing,
    ConfirmMoveToNewWork,
    isConfirmMoveToNewWorkOpen,
    isConfirmMoveToNewWorkClosing,
  } = useMoveManifestationContext(manifestationId);

  const [level1Selection, setLevel1Selection] = useState<
    'expression' | 'work' | undefined
  >();

  const [anchorLevel1Button, setAnchorLevel1Button] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const handleOpenLevel1Menu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorLevel1Button(event.currentTarget);
  };

  const handleCloseLevel1Menu = useCallback(() => {
    setAnchorLevel1Button(null);
  }, []);

  const [anchorLevel2Button, setAnchorLevel2Button] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const handleOpenLevel2Menu = useCallback(
    (
      target: (EventTarget & HTMLButtonElement) | null,
      selection: 'expression' | 'work',
    ) => {
      setLevel1Selection(selection);
      setAnchorLevel2Button(target);
    },
    [],
  );

  const handleCloseLevel2Menu = useCallback(() => {
    setLevel1Selection(undefined);
    setAnchorLevel2Button(null);
  }, []);

  const handleMoveToExpression = useCallback(() => {
    handleOpenLevel2Menu(anchorLevel1Button, 'expression');
    handleCloseLevel1Menu();
  }, [anchorLevel1Button, handleCloseLevel1Menu, handleOpenLevel2Menu]);

  const handleMoveToWork = useCallback(() => {
    handleOpenLevel2Menu(anchorLevel1Button, 'work');
    handleCloseLevel1Menu();
  }, [anchorLevel1Button, handleCloseLevel1Menu, handleOpenLevel2Menu]);

  const handleMoveToOtherExpression = useCallback(
    (expressionId: string) => {
      handleCloseLevel2Menu();
      moveManifestationToOtherExpression(manifestationId, expressionId);
    },
    [
      handleCloseLevel2Menu,
      manifestationId,
      moveManifestationToOtherExpression,
    ],
  );

  const handleMoveToNewExpression = useCallback(() => {
    handleCloseLevel2Menu();
    moveManifestationToNewExpression(manifestationId);
  }, [
    handleCloseLevel2Menu,
    manifestationId,
    moveManifestationToNewExpression,
  ]);

  const handleMoveToOtherWork = useCallback(() => {
    handleCloseLevel2Menu();
    moveManifestationToOtherWork(manifestationId);
  }, [handleCloseLevel2Menu, manifestationId, moveManifestationToOtherWork]);

  const handleMoveToNewWork = useCallback(() => {
    handleCloseLevel2Menu();
    moveManifestationToNewWork(manifestationId);
  }, [handleCloseLevel2Menu, manifestationId, moveManifestationToNewWork]);

  const {level1Items, level2} = useMemo(() => {
    const level1Items = MoveActions.map(moveAction => {
      return {
        id: moveAction,
        title: t(
          `page.metadata.manifestation.tools.move.level1.${moveAction}.title`,
        ),
        rightIcon: 'ChevronRight',
        action:
          moveAction === 'moveToExpression'
            ? handleMoveToExpression
            : handleMoveToWork,
      } as MenuItemProps;
    });

    const otherExpressionItems = expressions.map(expression => {
      const isCurrent = expression.id === currentExpressionId;
      return {
        id: expression.id,
        disabled: isCurrent,
        renderItem: () => <ExpressionItem expression={expression} />,
        action: () => {
          if (isCurrent) {
            return;
          }

          handleMoveToOtherExpression(expression.id);
        },
      } as CustomMenuItemProps;
    });

    const level2ExpressionItems = [
      ...otherExpressionItems,
      {type: 'divider'} as MenuDividerProps,
      {
        id: 'newExpression',
        title: t(
          'page.metadata.manifestation.tools.move.level2.expression.moveToNewExpression',
        ),
        action: handleMoveToNewExpression,
      } as StandardMenuItemProps,
    ];

    const level2WorkItems: MenuItemProps[] = [
      {
        id: 'moveToOtherWork',
        title: t(
          'page.metadata.manifestation.tools.move.level2.work.moveToOtherWork.title',
        ),
        rightIcon: 'Search',
        action: handleMoveToOtherWork,
      },
      {
        id: 'moveToNewWork',
        title: t(
          'page.metadata.manifestation.tools.move.level2.work.moveToNewWork.title',
        ),
        disabled: manifestationCountInWork < 2,
        action: handleMoveToNewWork,
      },
    ];

    return {
      level1Items,
      level2: {
        items: {
          expression: level2ExpressionItems,
          work: level2WorkItems,
        },
        count: {
          expression: manifestationCountInExpression,
          work: manifestationCountInWork,
        },
      },
    };
  }, [
    currentExpressionId,
    expressions,
    handleMoveToExpression,
    handleMoveToNewExpression,
    handleMoveToNewWork,
    handleMoveToOtherExpression,
    handleMoveToOtherWork,
    handleMoveToWork,
    manifestationCountInExpression,
    manifestationCountInWork,
    t,
  ]);

  return (
    <Fragment>
      <ToolButton
        title={t('page.metadata.manifestation.tools.move')}
        disabled={disabled}
        renderIcon={() => <MoveIcon />}
        onClick={handleOpenLevel1Menu}
        iconOnly={miniMode}
      />
      {/* Level 1 menu */}
      <PopupMenu
        anchorEl={anchorLevel1Button}
        popupTitle={t('page.metadata.manifestation.tools.move.level1.title')}
        items={level1Items}
        layout={{
          positionVertical: 'bottom',
        }}
        onClose={handleCloseLevel1Menu}
      />
      {/* Level 2 menu */}
      <PopupMenu
        anchorEl={anchorLevel2Button}
        popupTitle={t(
          `page.metadata.manifestation.tools.move.level2.${
            level1Selection ?? 'expression'
          }.title`,
        )}
        renderPopupFooter={
          level1Selection && level2.count[level1Selection] === 1
            ? () => <EmptyAncestorsWarning type={level1Selection} />
            : undefined
        }
        items={level1Selection ? level2.items[level1Selection] : []}
        layout={{
          positionVertical: 'bottom',
        }}
        onClose={handleCloseLevel2Menu}
      />
      <ConfirmMoveToOtherWork
        isOpen={isConfirmMoveToOtherOpen}
        isClosing={isConfirmMoveToOtherClosing}
      />
      <ConfirmMoveToNewWork
        isOpen={isConfirmMoveToNewWorkOpen}
        isClosing={isConfirmMoveToNewWorkClosing}
      />
    </Fragment>
  );
};
