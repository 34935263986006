import {Metadata} from 'services/data/metadata/types';
import {ExpressionV4} from 'api';
import {MetadataEditAction} from 'services/data/metadata/metadataEditActionTypes';
import {changeManifestationExpressionId} from './changeManifestationExpressionId';

export function moveManifestationToNewExpression(
  manifestationId: string,
  newExpression: ExpressionV4,
  metadata: Metadata,
  action: MetadataEditAction | undefined,
): Metadata {
  const metadataWithNewExpression: Metadata = {
    ...metadata,
    expressions: [...metadata.expressions, newExpression],
  };

  const newMetadata = changeManifestationExpressionId(
    manifestationId,
    newExpression.id,
    metadataWithNewExpression,
    action,
  );

  return newMetadata;
}
