import assert from 'assert-ts';
import {produce} from 'immer';
import get from 'lodash/get';
import set from 'lodash/set';
import {Data, DataValue} from 'schemaDefinition/types';
import {ArrayUpdateHistory} from 'services/data/metadata/types';
import {getParentArrayPathParts} from './getParentArrayPathParts';
import {resolveUpdatedArrayIndex} from './resolveUpdatedArrayIndex';

export const removeItemFromOriginalValue = <T extends Data>(
  entity: T,
  history: ArrayUpdateHistory | undefined,
  localPath: string,
): {entity: T; history: ArrayUpdateHistory | undefined} => {
  const {parentPath, itemIndex} = getParentArrayPathParts(localPath);
  if (
    !assert.soft(
      parentPath,
      'removeItemFromOriginalValue: parentArrayPath not found',
    )
  ) {
    return {entity, history};
  }

  const updatedIndex = resolveUpdatedArrayIndex(itemIndex, history);
  if (
    !assert.soft(
      updatedIndex,
      'removeItemFromOriginalValue: updatedIndex not found',
      {itemIndex, history, localPath},
    )
  ) {
    return {entity, history};
  }

  const newEntity = produce(entity, draft => {
    const array = get(draft, parentPath) as DataValue[] | undefined;
    // Delete item at updatedIndex
    const newArray = array?.filter((_, index) => index !== updatedIndex);
    set(draft, parentPath, newArray);
  });

  return {
    entity: newEntity,
    history: [
      ...(history ?? []),
      {
        atIndex: updatedIndex,
        operation: 'removeItem',
      },
    ],
  };
};
