import {useCallback} from 'react';
import {Divider, MenuItem, Typography} from '@mui/material';
import {assert} from 'assert-ts';
import {Icon} from 'components/icons';
import {Layout} from 'components/layout';
import {Spacer} from 'components/spacer';
import {IconName} from '../icons/types';
import {MenuItemProps} from './types';
import {asCustomItem, asDivider, asStandardItem} from './functions';

type Props<TItemId> = {
  item: MenuItemProps<TItemId>;
  onSetPreview?: (itemId: TItemId | null) => void;
  onSelect?: (itemId?: TItemId) => void;
};

export function PopupMenuItem<TItemId>({
  item,
  onSetPreview,
  onSelect,
}: Props<TItemId>): ReturnType<React.FC> {
  const customItem = asCustomItem(item);
  const stdItem = asStandardItem(item);
  const divider = asDivider(item);

  const handleSelect = useCallback(
    (item: MenuItemProps<TItemId>) => {
      assert(
        item.action || onSelect,
        'PopupMenuItem: missing item.action or onSelect',
        item,
      );

      if (item.action) {
        item.action();
      }

      if (onSelect) {
        onSelect(item.id);
      }
    },
    [onSelect],
  );

  const handleSetPreview = useCallback(
    (item: MenuItemProps<TItemId> | undefined) => {
      if (onSetPreview) {
        if (item === undefined) {
          onSetPreview(null);
        } else {
          onSetPreview(
            assert(
              item.id,
              'PopupMenuItem.handleSetPreview: missing stdItem.id',
              item,
            ),
          );
        }
      }
    },
    [onSetPreview],
  );

  return divider ? (
    <Divider
      variant="middle"
      //  sx={{mx: 2, mt: 0}}
    />
  ) : (
    <MenuItem
      disabled={item.disabled}
      onClick={() => handleSelect(item)}
      onMouseEnter={() => handleSetPreview(item)}
      onMouseLeave={() => handleSetPreview(undefined)}>
      {customItem ? (
        customItem.renderItem()
      ) : stdItem ? (
        <>
          {stdItem.icon || stdItem.rightIcon ? (
            <Layout horizontal alignItems="center" kind="primary" flex={1}>
              {stdItem.icon ? (
                <>
                  {typeof stdItem.icon === 'string' ? (
                    <Icon icon={stdItem.icon as IconName} />
                  ) : (
                    stdItem.icon
                  )}
                  <Spacer size={1} />
                </>
              ) : undefined}
              <Typography variant="body2" textAlign="center">
                {stdItem.title}
              </Typography>
              {stdItem.rightIcon ? (
                <>
                  <Spacer size={1} />
                  {typeof stdItem.rightIcon === 'string' ? (
                    <Icon
                      icon={stdItem.rightIcon as IconName}
                      sx={{justifySelf: 'flex-end'}}
                    />
                  ) : (
                    stdItem.rightIcon
                  )}
                </>
              ) : undefined}
            </Layout>
          ) : (
            <Typography variant="body2" textAlign="center">
              {stdItem.title}
            </Typography>
          )}
        </>
      ) : null}
    </MenuItem>
  );
}
