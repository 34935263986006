import {isEmptyValue} from './isEmptyValue';

export const removeEmptyStringValues = (
  values: string[] | undefined,
): string[] | undefined => {
  if (isEmptyValue(values)) {
    return undefined;
  }

  // Filter empty strings.
  const nonEmptyValues = (values ?? []).filter(
    value => value && value.trim().length > 0,
  );
  return nonEmptyValues.length > 0 ? nonEmptyValues : undefined;
};
