import {assert} from 'assert-ts';
import {AgentSubType, Concept} from 'types';
import {AgentTypeDto} from 'api/types';
import {reverseMap} from './reverseMap';

const agentTypeMap: {[dto in AgentTypeDto]: AgentSubType} = {
  PERSON: Concept.person,
  CORPORATION: Concept.corporation,
  PUBLISHER: Concept.publisher,
  EVENT: Concept.event,
};

export const mapAgentTypeDto = (dto: AgentTypeDto): AgentSubType =>
  agentTypeMap[dto];

export const mapToAgentTypeDto = (agentType: AgentSubType): AgentTypeDto => {
  return assert(
    reverseMap(agentType, agentTypeMap),
    'mapToAgentTypeDto: unknown agentType',
    {agentType},
  );
};
