import React from 'react';
import {PartHtml, Valx} from 'schemaDefinition/types';
import {Fonts} from 'theme/fonts';
import {valueAsString} from 'services/utils';
import {HtmlField, Layout} from 'components';
import {BaseFieldProps} from './types';

type Props = BaseFieldProps & {
  part: PartHtml<Valx>;
};

export const Html: React.FC<Props> = ({
  part,
  value,
  valuePath,
  label,
  placeholder,
}) => {
  const strValue = valueAsString(value);
  return (
    <Layout sx={Fonts.body1}>
      <HtmlField
        name={valuePath}
        label={label}
        placeholder={placeholder}
        value={strValue ?? null}
        required={part.required}
        readonly={part.readonly}
      />
    </Layout>
  );
};
