import {useMemo} from 'react';
import {SchemaMode, SchemaReference} from '../types';
import {getSchema, getSchemaForMode} from '../functions';

export const useSchema = (
  reference: SchemaReference,
  schemaMode: SchemaMode = 'full',
) => {
  return useMemo(() => {
    const baseSchema = getSchema(reference);
    const schema = getSchemaForMode(baseSchema, schemaMode);
    return schema;
  }, [reference, schemaMode]);
};
