import {Stack} from '@mui/material';
import {ByCategoryResultPage} from 'services/thesaurus/types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {Card, Layout, Spacer, Text} from 'components';
import {useThesaurusEditStateContext} from '../contexts';
import {SearchResultRange} from './SearchResultRange';
import {SearchingTopLevelMatch} from './SearchingTopLevelMatch';
import {ThesaurusCode} from './ThesaurusCode';

type Props = {
  resultByCategories: ByCategoryResultPage[];
};

export const SearchingTopLevels: React.FC<Props> = ({resultByCategories}) => {
  const {t} = useLocalization();
  const {getDisplayCode} = useThesaurusEditStateContext();

  return resultByCategories.length === 0 ? (
    <Text variant="body2">
      {t('extension.thesaurusEdit.search.noMatches') + '-3'}
    </Text>
  ) : (
    <Stack spacing={2} flex={1}>
      {resultByCategories.map(match => {
        const code = getDisplayCode(match.categoryNode);
        return (
          <Card
            key={match.categoryNode.id}
            colorx={ColorPalette.offWhite}
            spacing={1}>
            <Card.Header>
              <Layout
                flex={1}
                horizontal
                adjustCenter
                justifyContent={'space-between'}>
                <Layout horizontal adjustCenter>
                  {code ? <ThesaurusCode code={code} /> : null}
                  {code ? <Spacer size={1} /> : null}
                  <Text variant="h3">{match.categoryNode.label}</Text>
                </Layout>
                <Layout pr={3}>
                  <SearchResultRange
                    count={match.count}
                    from={match.firstIndexInCategory + 1}
                    to={match.lastIndexInCategory + 1}
                  />
                </Layout>
              </Layout>
            </Card.Header>

            <Card.Content>
              <Stack id={'searchMatchStack'} spacing={1}>
                {match.nodes.map(node => (
                  <SearchingTopLevelMatch key={node.node.id} match={node} />
                ))}
              </Stack>
            </Card.Content>
          </Card>
        );
      })}
    </Stack>
  );
};
