import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {ManifestationStatus} from 'api';
import {EntityStatus} from '../types';
import {getLowestStatus} from '../reducer/metadataEdit/functions';
import {statusesSelector} from '../selectors';

export const useMetadataSavedStatus = (): ManifestationStatus => {
  const statuses = useSelector(statusesSelector);

  return useMemo(() => {
    return Object.values(statuses).reduce(
      (acc, s) => {
        return {status: getLowestStatus(acc.status, s.status)} as EntityStatus;
      },
      {status: 'COMPLETED'} as EntityStatus,
    ).status;
  }, [statuses]);
};
