import {useCallback, useMemo} from 'react';
import {useColumnConfigState} from 'schema/table/hooks/useColumnConfigState';
import {advancedSearchSchemaName} from '../types';
import {mapSortingConfigToString} from '../mappers/mapSortingConfigToString';

export const useSort = (): {
  sort?: string;
  resetSort: () => void;
} => {
  const {sortingConfig, setSortingConfig} = useColumnConfigState(
    advancedSearchSchemaName,
  );

  const resetSort = useCallback(() => {
    setSortingConfig(undefined);
  }, [setSortingConfig]);

  return useMemo(
    () => ({
      sort: sortingConfig ? mapSortingConfigToString(sortingConfig) : undefined,
      resetSort,
    }),
    [resetSort, sortingConfig],
  );
};
