import React, {useCallback, useMemo, useState} from 'react';
import {FilterSet} from 'api/types';
import {useLocalization} from 'localization';
import {
  CheckboxField,
  Dialog,
  FieldLabel,
  Layout,
  Spacer,
  TextField,
} from 'components';

export const useCreateFilterDialog = (
  onSave: (name: string, isPublic: boolean) => Promise<FilterSet>,
) => {
  const {t} = useLocalization();
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [isPublic, setIsPublic] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    setName('');
    setIsPublic(false);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setName('');
    setIsPublic(false);
    setOpen(false);
  }, []);

  const handleSave = useCallback(
    (name: string, isPublic: boolean) => {
      onSave(name, isPublic).then(() => setOpen(false));
    },
    [onSave],
  );

  const Form = useMemo(() => {
    return (
      <Dialog
        isOpen={open}
        title={t('page.search.filter.create.title')}
        body={
          <Layout flex={1}>
            <FieldLabel label={t('form.global.field.name.label')} />
            <TextField
              type={'text'}
              name={'name'}
              required={true}
              value={name}
              onChange={e => setName(e.target.value ?? '')}
            />

            <Spacer height={2} />

            <CheckboxField
              fieldId={'isPublic'}
              name={'isPublic'}
              value={isPublic}
              onChange={value => setIsPublic(value)}
              boxLabel={t('data.field.isPublic.label')}
            />
          </Layout>
        }
        dialogItems={[
          {
            itemType: 'action',
            title: t('general.save'),
            onClick: () => handleSave(name, isPublic),
          },
          {
            itemType: 'action',
            type: 'reset',
            alignLeft: true,
            title: t('general.cancel'),
            onClick: () => handleClose(),
          },
        ]}
      />
    );
  }, [handleClose, handleSave, isPublic, name, open, t]);

  return {
    CreateFilterDialog: Form,
    openCreateFilterDialog: handleOpen,
  };
};
