import React, {useMemo} from 'react';
import {assert} from 'assert-ts';
import {PreviewConfiguration} from 'schema/types';
import {CodeListsGate} from 'services/codeLists';
import {Preview, useSchemaCodelistIds} from 'schema';
import {entityTypeToSchemaReference} from 'schemas/functions/entityTypeToSchemaReference';
import {useEntitySchema, usePreviewConfiguration} from 'schemas/hooks';
import {EntityPreviewContent} from './types';

export const DefaultEntityPreviewContent: EntityPreviewContent = ({
  entityType,
  entityValue,
  configuration,
}) => {
  const schema = useEntitySchema(entityType);
  const codeLists = useSchemaCodelistIds(schema);
  const dynamicPreviewConfig = useMemo((): Partial<PreviewConfiguration> => {
    return {
      nameVariant: {
        excludeMainForm: true,
      },
      ...configuration,
    };
  }, [configuration]);

  const previewConfig = usePreviewConfiguration(
    entityTypeToSchemaReference(entityType),

    dynamicPreviewConfig,
  );

  return (
    <CodeListsGate codeLists={codeLists}>
      <Preview
        schema={assert(schema, 'EntityPreview: schema is not defined')}
        data={entityValue}
        configuration={previewConfig}
      />
    </CodeListsGate>
  );
};
