import React from 'react';
import {CurrentFilterType} from 'api/types';
import {DateRangeField} from 'components/fields/DateRangeField';
import {useDateRangeShortcuts} from '../../hooks/useDateRangeShortcuts';
import {
  mapDateRangeFieldValue,
  mapToDateRangeFieldValue,
} from '../../mappers/mapDateRangeFieldValue';

type Props = {
  filter: CurrentFilterType;
  onChange: (filter: CurrentFilterType) => void;
  onSubmit?: () => void;
};
export const FilterDateRangeValue: React.FC<Props> = ({filter, onChange}) => {
  const shortcuts = useDateRangeShortcuts();

  return (
    <DateRangeField
      shortcuts={shortcuts}
      value={mapToDateRangeFieldValue(filter.values)}
      onChange={value => {
        onChange({
          ...filter,
          values: mapDateRangeFieldValue(value),
        });
      }}
    />
  );
};
