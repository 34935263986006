import {useMemo} from 'react';
import {useLocalization} from 'localization';
import {DataFormConfigurationContextType} from 'schema/form/contexts';

export const useChangeRequestFormConfiguration = () => {
  const {t} = useLocalization();
  return useMemo(() => {
    const config: Partial<DataFormConfigurationContextType> = {
      parts: {
        'agent.link': {
          compare: {
            actionTooltip: {
              replace: t('page.metadata.cr.part.agent.link.replaceTooltip'),
            },
          },
        },
        'agent.roles': {
          compare: {
            actionTooltip: {
              replace: t('page.metadata.cr.part.agent.roles.replaceTooltip'),
            },
          },
        },
        links: {
          compare: {
            actionTooltip: {
              replace: t('page.metadata.cr.part.links.replaceTooltip'),
              addItem: t('page.metadata.cr.part.links.addTooltip'),
              removeItem: t('page.metadata.cr.part.links.removeTooltip'),
            },
          },
        },
        mainTitlesValue: {
          compare: {
            actionTooltip: {
              replace: t('page.metadata.cr.part.mainTitles.replaceTooltip'),
            },
          },
        },
        subTitles: {
          hideWhenEmpty: true,
          label: t('form.global.field.subTitles.label'),
        },
        subTitlesValue: {
          compare: {
            actionTooltip: {
              replace: t('page.metadata.cr.part.subTitles.replaceTooltip'),
            },
          },
        },
        parallelTitles: {
          hideWhenEmpty: true,
          label: t('form.global.field.parallelTitles.label'),
          compare: {
            actionTooltip: {
              replace: t('page.metadata.cr.part.parallelTitles.replaceTooltip'),
            },
          },
        },
        parallelSubTitles: {
          hideWhenEmpty: true,
          label: t('form.global.field.parallelSubTitles.label'),
          compare: {
            actionTooltip: {
              replace: t(
                'page.metadata.cr.part.parallelSubTitles.replaceTooltip',
              ),
            },
          },
        },
      },
      schemaContainers: {
        dewey: 'NoContainer',
        mainTitles: 'MinimalIndentContainer',
        subTitles: 'MinimalIndentContainer',
      },
      hideAllStructureOperations: true,
    };

    return config;
  }, [t]);
};
