import {Schemas} from 'api/dto.generated';

const dto: Schemas.AuthorityRegistryWrapperDto[] = [
  {
    agent: {
      id: undefined,
      isni: undefined,
      nationalId: '9037020',
      agentType: 'PERSON',
      internalComments: undefined,
      externalComments: undefined,
      nameVariants: [
        {id: undefined, name: 'Hamsun', addition: 'slekten', nameOrder: 0},
      ],
      gender: undefined,
      country: undefined,
      countryOfBirth: undefined,
      profession: undefined,
      occupationalField: [],
      pseudonym: false,
    },
    metadata: {deleted: false, status: 'kat3'},
  },
  {
    agent: {
      id: undefined,
      isni: '0000000039590926',
      nationalId: '4093210',
      agentType: 'PERSON',
      internalComments: undefined,
      externalComments: undefined,
      nameVariants: [
        {id: undefined, name: 'Ellinor', surName: 'Hamsun', nameOrder: 0},
      ],
      gender: undefined,
      country: undefined,
      countryOfBirth: undefined,
      profession: undefined,
      occupationalField: [],
      pseudonym: false,
    },
    metadata: {deleted: false, status: 'kat2'},
  },
  {
    agent: {
      id: undefined,
      isni: undefined,
      nationalId: '3005116',
      agentType: 'PERSON',
      internalComments: undefined,
      externalComments: undefined,
      nameVariants: [
        {id: undefined, name: 'Victoria', surName: 'Hamsun', nameOrder: 0},
      ],
      gender: undefined,
      country: undefined,
      countryOfBirth: undefined,
      profession: undefined,
      occupationalField: [],
      pseudonym: false,
    },
    metadata: {deleted: false, status: 'kat2'},
  },
  {
    agent: {
      id: undefined,
      isni: '0000000489896703',
      nationalId: '9054106',
      agentType: 'PERSON',
      internalComments: undefined,
      externalComments: undefined,
      nameVariants: [
        {id: undefined, name: 'Cecilia', surName: 'Hamsun', nameOrder: 0},
      ],
      gender: undefined,
      country: undefined,
      countryOfBirth: undefined,
      profession: undefined,
      occupationalField: [],
      pseudonym: false,
    },
    metadata: {deleted: false, status: 'kat1'},
  },
  {
    agent: {
      id: undefined,
      isni: '0000000398295611',
      nationalId: '90348994',
      agentType: 'PERSON',
      internalComments: undefined,
      externalComments: undefined,
      nameVariants: [
        {id: undefined, name: 'Arild', surName: 'Hamsun', nameOrder: 0},
      ],
      gender: 'MALE',
      country: undefined,
      countryOfBirth: undefined,
      profession: undefined,
      occupationalField: [],
      birth: {year: 1914, date: undefined, isApproximate: undefined},
      death: {year: 1988, date: undefined, isApproximate: undefined},
      pseudonym: false,
    },
    metadata: {deleted: false, status: 'kat3'},
  },
  {
    agent: {
      id: undefined,
      isni: '0000000488166813',
      nationalId: '6006153',
      agentType: 'PERSON',
      internalComments: undefined,
      externalComments: undefined,
      nameVariants: [
        {id: undefined, name: 'Frid', surName: 'Hamsun', nameOrder: 0},
      ],
      gender: undefined,
      country: undefined,
      countryOfBirth: undefined,
      profession: undefined,
      occupationalField: [],
      pseudonym: false,
    },
    metadata: {deleted: false, status: 'kat2'},
  },
  {
    agent: {
      id: undefined,
      isni: '0000000489772305',
      nationalId: '9048517',
      agentType: 'PERSON',
      internalComments: undefined,
      externalComments: undefined,
      nameVariants: [
        {id: undefined, name: 'Leif', surName: 'Hamsun', nameOrder: 0},
      ],
      gender: undefined,
      country: undefined,
      countryOfBirth: undefined,
      profession: undefined,
      occupationalField: [],
      pseudonym: false,
    },
    metadata: {deleted: false, status: 'kat2'},
  },
  {
    agent: {
      id: undefined,
      isni: '0000000016341038',
      nationalId: '98070796',
      agentType: 'PERSON',
      internalComments: undefined,
      externalComments: undefined,
      nameVariants: [
        {id: undefined, name: 'Marianne', surName: 'Hamsun', nameOrder: 0},
      ],
      gender: 'FEMALE',
      country: undefined,
      countryOfBirth: undefined,
      profession: undefined,
      occupationalField: [],
      birth: {year: 1928, date: undefined, isApproximate: undefined},
      pseudonym: false,
    },
    metadata: {deleted: false, status: 'kat3'},
  },
  {
    agent: {
      id: undefined,
      isni: undefined,
      nationalId: '1642068396977',
      agentType: 'PERSON',
      internalComments: undefined,
      externalComments: undefined,
      nameVariants: [
        {id: undefined, name: 'Geir', surName: 'Hamsun', nameOrder: 0},
      ],
      gender: undefined,
      country: undefined,
      countryOfBirth: undefined,
      profession: undefined,
      occupationalField: [],
      pseudonym: false,
    },
    metadata: {deleted: false, status: 'kat1'},
  },
  {
    agent: {
      id: undefined,
      isni: undefined,
      nationalId: '1642068396978',
      agentType: 'PERSON',
      internalComments: undefined,
      externalComments: undefined,
      nameVariants: [
        {id: undefined, name: 'Regine', surName: 'Hamsun', nameOrder: 0},
        {
          id: undefined,
          name: 'Regine Christianne',
          surName: 'Hamsun',
          nameOrder: 1,
        },
      ],
      gender: 'FEMALE',
      country: undefined,
      countryOfBirth: undefined,
      profession: 'Billedkunstner',
      occupationalField: [],
      birth: {year: 1962, date: undefined, isApproximate: undefined},
      pseudonym: false,
    },
    metadata: {deleted: false, status: 'kat3'},
  },
  {
    agent: {
      id: undefined,
      isni: '0000000361206830',
      nationalId: '90905912',
      agentType: 'PERSON',
      internalComments: undefined,
      externalComments: undefined,
      nameVariants: [
        {id: undefined, name: 'Almar', surName: 'Bjørnefjell', nameOrder: 0},
        {id: undefined, name: 'Almar', surName: 'Hamsun', nameOrder: 1},
        {id: undefined, name: 'Almer', surName: 'Hamsun', nameOrder: 2},
        {
          id: undefined,
          name: 'Toralf Oscar Almar',
          surName: 'Hamsun',
          nameOrder: 3,
        },
      ],
      gender: 'MALE',
      country: undefined,
      countryOfBirth: undefined,
      profession: undefined,
      occupationalField: [],
      birth: {year: 1898, date: undefined, isApproximate: undefined},
      death: {year: 1982, date: undefined, isApproximate: undefined},
      pseudonym: false,
    },
    metadata: {deleted: false, status: 'kat3'},
  },
  {
    agent: {
      id: undefined,
      isni: '0000000399295349',
      nationalId: '12063162',
      agentType: 'PERSON',
      internalComments: undefined,
      externalComments: undefined,
      nameVariants: [
        {id: undefined, name: 'Victoria', surName: 'Charlesson', nameOrder: 0},
        {id: undefined, name: 'Victoria', surName: 'Hamsun', nameOrder: 1},
      ],
      gender: 'FEMALE',
      country: undefined,
      countryOfBirth: undefined,
      profession: undefined,
      occupationalField: [],
      birth: {year: 1902, date: undefined, isApproximate: undefined},
      death: {year: 1980, date: undefined, isApproximate: undefined},
      pseudonym: false,
    },
    metadata: {deleted: false, status: 'kat3'},
  },
  {
    agent: {
      id: undefined,
      isni: undefined,
      nationalId: '1642068454260',
      agentType: 'PERSON',
      internalComments: undefined,
      externalComments: undefined,
      nameVariants: [
        {id: undefined, name: 'Esben Hamsun', surName: 'Rustad', nameOrder: 0},
      ],
      gender: undefined,
      country: undefined,
      countryOfBirth: undefined,
      profession: undefined,
      occupationalField: [],
      pseudonym: false,
    },
    metadata: {deleted: false, status: 'kat1'},
  },
  {
    agent: {
      id: undefined,
      isni: '0000000021663242',
      nationalId: '90096667',
      agentType: 'PERSON',
      internalComments: undefined,
      externalComments: undefined,
      nameVariants: [
        {id: undefined, name: 'Tore', surName: 'Hamsun', nameOrder: 0},
        {id: undefined, name: 'Tore', surName: 'Gamsun', nameOrder: 1},
        {id: undefined, name: 'Ture', surName: 'Gamsun', nameOrder: 2},
      ],
      gender: 'MALE',
      country: undefined,
      countryOfBirth: undefined,
      profession: undefined,
      occupationalField: [],
      birth: {year: 1912, date: undefined, isApproximate: undefined},
      death: {year: 1995, date: undefined, isApproximate: undefined},
      pseudonym: false,
    },
    metadata: {deleted: false, status: 'kat3'},
  },
  {
    agent: {
      id: undefined,
      isni: '0000000120169813',
      nationalId: '90081146',
      agentType: 'PERSON',
      internalComments: undefined,
      externalComments: undefined,
      nameVariants: [
        {id: undefined, name: 'Marie', surName: 'Hamsun', nameOrder: 0},
        {id: undefined, name: 'Marie', surName: 'Andersen', nameOrder: 1},
        {id: undefined, name: 'Marija', surName: 'Gamsun', nameOrder: 2},
        {id: undefined, name: 'Marî', surName: 'Hamuzun', nameOrder: 3},
      ],
      gender: 'FEMALE',
      country: undefined,
      countryOfBirth: undefined,
      profession: undefined,
      occupationalField: [],
      birth: {year: 1881, date: undefined, isApproximate: undefined},
      death: {year: 1969, date: undefined, isApproximate: undefined},
      pseudonym: false,
    },
    metadata: {deleted: false, status: 'kat3'},
  },
  {
    agent: {
      id: undefined,
      isni: '0000000121347845',
      nationalId: '90052521',
      agentType: 'PERSON',
      internalComments: undefined,
      externalComments: undefined,
      nameVariants: [
        {id: undefined, name: 'Knut', surName: 'Hamsun', nameOrder: 0},
        {id: undefined, name: 'Knutas', surName: 'Hamsunas', nameOrder: 1},
        {id: undefined, name: 'Ego', nameOrder: 2},
        {id: undefined, name: 'W.T.', nameOrder: 3},
        {id: undefined, name: 'Han Musheng', nameOrder: 4},
        {id: undefined, name: 'Knuts', surName: 'Hamsuns', nameOrder: 5},
        {id: undefined, name: 'Knut', surName: 'Chamsun', nameOrder: 6},
        {id: undefined, name: 'Knut', surName: 'Hamsund', nameOrder: 7},
        {id: undefined, name: 'Knout', surName: 'Chamsoun', nameOrder: 8},
        {id: undefined, name: 'Knud', surName: 'Pedersen', nameOrder: 9},
        {id: undefined, name: 'Ke', surName: 'Hanmusheng', nameOrder: 10},
        {id: undefined, name: 'Kanoot', surName: 'Hamsun', nameOrder: 11},
        {
          id: undefined,
          name: 'Knut Pedersen',
          surName: 'Hamsund',
          nameOrder: 12,
        },
        {id: undefined, name: 'Knut', surName: 'Thode', nameOrder: 13},
        {
          id: undefined,
          name: 'Knud Pedersen',
          surName: 'Hamsund',
          nameOrder: 14,
        },
        {id: undefined, name: 'K.', surName: 'Hamusun', nameOrder: 15},
        {id: undefined, name: 'Knut', surName: 'Gamsun', nameOrder: 16},
        {id: undefined, name: 'Кнут', surName: 'Гамсун', nameOrder: 17},
        {id: undefined, name: "Knyut'", surName: 'Hamsun', nameOrder: 18},
        {id: undefined, name: 'Knut', surName: 'Hamsuni', nameOrder: 19},
        {id: undefined, name: 'Knuṭ', surName: 'Hamsun', nameOrder: 20},
        {id: undefined, name: 'קנוט', surName: '‏ה‏מסון', nameOrder: 21},
        {id: undefined, name: "K'nyut", surName: 'Hamsun', nameOrder: 22},
        {id: undefined, name: 'Քնութ', surName: 'Համսուն', nameOrder: 23},
        {id: undefined, name: "N'yuṭa", surName: 'Hyāmasuna', nameOrder: 24},
        {id: undefined, name: ' ন্যুট', surName: 'হ্যামসুন', nameOrder: 25},
      ],
      gender: 'MALE',
      country: undefined,
      countryOfBirth: undefined,
      profession: undefined,
      occupationalField: [],
      birth: {year: 1859, date: undefined, isApproximate: undefined},
      death: {year: 1952, date: undefined, isApproximate: undefined},
      pseudonym: false,
    },
    metadata: {deleted: false, status: 'kat3'},
  },
];

export default dto;
