import {Card as CardContainer} from './Card';
import {CardContent} from './CardContent';
import {CardHeader} from './CardHeader';
import {CardNavigationHeader} from './CardNavigationHeader';

type CardType = typeof CardContainer & {
  NavigationHeader: typeof CardNavigationHeader;
  Header: typeof CardHeader;
  Content: typeof CardContent;
};
const Card: CardType = CardContainer as CardType;
Card.NavigationHeader = CardNavigationHeader;
Card.Header = CardHeader;
Card.Content = CardContent;

export {Card};
