import assert from 'assert-ts';
import {PartValueRenderer} from 'schema/types';
import {
  PartBool,
  PartDate,
  PartInt,
  PartText,
  PartYear,
  PartYearOrDate,
  Valx,
} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {NoWrap, TextMatch} from 'components';
import {formatScalarValue} from 'schema/preview/functions';

export const NoWrapped: PartValueRenderer = ({value, match, part}) => {
  const l = useLocalization();
  const values = Array.isArray(value) ? value : [value];

  assert(
    ['text', 'int', 'bool', 'date', 'yearOrDate'].includes(part.type),
    'Unexpected part type',
    {part},
  );

  return (
    <NoWrap>
      <TextMatch variant="body1" match={match}>
        {formatScalarValue(
          values,
          part as
            | PartText<Valx>
            | PartInt<Valx>
            | PartBool<Valx>
            | PartYear<Valx>
            | PartDate<Valx>
            | PartYearOrDate<Valx>,
          l,
        )}
      </TextMatch>
    </NoWrap>
  );
};
