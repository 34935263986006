import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {produce} from 'immer';
import get from 'lodash/get';
import set from 'lodash/set';
import {
  DataFormProps as DataFormPropsV2,
  GetValueById,
  SetValue,
  UseValueById,
} from 'schema/form/types';
import {Data, DataValue} from 'schemaDefinition/types';
import {getId} from 'services/utils';
import {DataFormProps as DataFormPropsV1} from 'schema/form/typesV1';

export const useDataFormV1toV2Props = <TData extends Data>(
  propsV1: DataFormPropsV1<TData>,
): DataFormPropsV2<TData> => {
  const {
    id = getId(),
    mode,
    showErrors,
    schema,
    configuration,
    data: savedData,
    dataTimestamp,
    // originalData, - comparison mode not supported by wrapper
    relatedData,
    onDataChanged,
    onSubmit: onSubmitV1,
    setFieldValueModifier,
  } = propsV1;

  const [timestamp, setTimestamp] = useState(0);
  const dataRef = useRef(savedData ?? ({} as TData));

  const getEditValue = useCallback<GetValueById>(valuePath => {
    return valuePath === undefined
      ? undefined
      : valuePath === ''
      ? dataRef.current
      : get(dataRef.current, valuePath);
  }, []);

  const useEditValue = useCallback<UseValueById>(
    valuePath => {
      return valuePath === undefined
        ? undefined
        : valuePath === ''
        ? dataRef.current
        : get(dataRef.current, valuePath);
    },
    // Trigger update on new timestamp
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timestamp],
  );

  const setEditValue = useCallback<SetValue>(
    (valuePath: string, value: DataValue) => {
      dataRef.current = produce(dataRef.current, draft =>
        set(draft, valuePath, value),
      );

      onDataChanged?.(dataRef.current);
      setTimestamp(prev => prev + 1);
    },
    [onDataChanged],
  );

  const onSubmitV2 = useCallback(() => {
    return Promise.resolve(onSubmitV1?.(dataRef.current));
  }, [onSubmitV1]);

  useEffect(() => {
    dataRef.current = savedData ?? ({} as TData);
    setTimestamp(prev => prev + 1);
  }, [savedData, dataTimestamp]);

  return useMemo(
    () => ({
      id,
      mode,
      showErrors,
      schema,
      configuration,
      relatedData,
      getEditValue,
      useEditValue,
      setEditValue,
      onSubmit: onSubmitV2,
      setFieldValueModifier,
    }),
    [
      configuration,
      getEditValue,
      id,
      mode,
      onSubmitV2,
      relatedData,
      schema,
      setFieldValueModifier,
      showErrors,
      useEditValue,
      setEditValue,
    ],
  );
};
