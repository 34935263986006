import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import {Concept} from 'types';
import {
  Agent,
  AgentCorporation,
  AgentEvent,
  AgentPerson,
  AgentWorkSummary,
} from 'api/types';
import {Schemas} from 'api/dto.generated';
import {mapToAgentTypeDto} from './mapAgentTypeDto';
import {mapDateTypeDto, mapToDateTypeDto} from './mapDateTypeDto';
import {
  mapNameVariantsDto,
  mapNameVariantsWithDescriptionDto,
  mapToNameVariantsDto,
  mapToNameVariantsDtoAndDescription,
} from './mapNameVariantsDto';
import {
  mapAgentCorporationRelationsDto,
  mapAgentExpressionsDto,
  mapAgentPersonRelationsDto,
  mapAgentWorksDto,
  mapToAgentCorporationRelationsDto,
  mapToAgentPersonRelationsDto,
} from './mapRelationsDto';

export const mapAgentDto = (
  dto:
    | Schemas.AgentPersonDto
    | Schemas.AgentCorporationDto
    | Schemas.AgentEventDto
    | Schemas.AgentPublisherDto,
): Agent => {
  const agentType = dto.agentType;
  //--- Corporation ---
  if (agentType === 'CORPORATION') {
    const corpDto = dto as Schemas.AgentCorporationDto;
    const {
      nameVariants,
      description,
      agentCorporationRelations,
      workRelations,
      works,
      expressions,
      ...restDto
    } = corpDto;

    const links =
      mapAgentCorporationRelationsDto({
        agentCorporationRelations,
        workRelations,
      }) ?? [];
    return {
      ...restDto,
      agentType: Concept.corporation,
      nameVariants: mapNameVariantsWithDescriptionDto(
        nameVariants,
        description,
      ),
      works: mergeWorkSummariesById(
        mapAgentWorksDto(works),
        mapAgentExpressionsDto(expressions),
      ),
      links: links.length > 0 ? links : undefined,
    };
  }
  //--- Event ---
  else if (agentType === 'EVENT') {
    const corpDto = dto as Schemas.AgentEventDto;
    const {
      nameVariants,
      description,
      // workRelations,
      works,
      expressions,
      ...restDto
    } = corpDto;

    return {
      ...restDto,
      agentType: Concept.event,
      nameVariants: mapNameVariantsWithDescriptionDto(
        nameVariants,
        description,
      ),
      works: mergeWorkSummariesById(
        mapAgentWorksDto(works),
        mapAgentExpressionsDto(expressions),
      ),
    };
  }
  //--- Person ---
  else if (agentType === 'PERSON') {
    const personDto = dto as Schemas.AgentPersonDto;
    const {
      nameVariants,
      birth,
      death,
      agentPersonRelations,
      workRelations,
      works,
      expressions,
      ...restDto
    } = personDto;

    return {
      ...restDto,
      agentType: Concept.person,
      nameVariants: mapNameVariantsDto(nameVariants),
      birth: mapDateTypeDto(birth),
      death: mapDateTypeDto(death),
      works: mergeWorkSummariesById(
        mapAgentWorksDto(works),
        mapAgentExpressionsDto(expressions),
      ),
      links: mapAgentPersonRelationsDto({agentPersonRelations, workRelations}),
    };
  }
  //--- Publisher ---
  else {
    const {
      nameVariants,
      // workRelations,
      works,
      expressions,
      ...restDto
    } = dto;
    return {
      ...restDto,
      agentType: Concept.publisher,
      nameVariants: mapNameVariantsDto(nameVariants),
      works: mergeWorkSummariesById(
        mapAgentWorksDto(works),
        mapAgentExpressionsDto(expressions),
      ),
    };
  }
};

/**
 * Strip id, userId, created and modified
 */
export const mapToPostPutAgentDto = (
  _method: 'POST' | 'PUT',
  agent: Agent,
  internalAgent: boolean,
):
  | Schemas.AgentPersonDto
  | Schemas.AgentCorporationDto
  | Schemas.AgentPublisherDto
  | Schemas.AgentEventDto => {
  //--- Corporation ---
  if (agent.agentType === Concept.corporation) {
    const corpAgent = agent as AgentCorporation;
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      id: _id,
      agentType,
      nameVariants,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      works: _works,
      links,
      ...data
    } = corpAgent;
    const relations = mapToAgentCorporationRelationsDto(links);

    return {
      ...data,
      agentType: mapToAgentTypeDto(agentType),
      ...mapToNameVariantsDtoAndDescription(nameVariants),
      ...relations,
      internalAgent,
    };
  }
  //--- Event ---
  else if (agent.agentType === Concept.event) {
    const eventAgent = agent as AgentEvent;
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      id: _id,
      agentType,
      nameVariants,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      works: _works,
      ...data
    } = eventAgent;
    return {
      ...data,
      agentType: mapToAgentTypeDto(agentType),
      ...mapToNameVariantsDtoAndDescription(nameVariants),
      internalAgent,
    };
  }
  //--- Person ---
  else if (agent.agentType === Concept.person) {
    const personAgent = agent as AgentPerson;

    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      id: _id,
      agentType,
      nameVariants,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      works: _works,
      links,
      birth,
      death,
      ...data
    } = personAgent;

    const relations = mapToAgentPersonRelationsDto(links);

    return {
      ...data,
      birth: mapToDateTypeDto(birth),
      death: mapToDateTypeDto(death),
      agentType: mapToAgentTypeDto(agentType),
      nameVariants: mapToNameVariantsDto(nameVariants),
      ...relations,
      internalAgent,
    };
  }
  //--- Publisher ---
  else {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      id: _id,
      agentType,
      nameVariants,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      works: _works,
      ...data
    } = agent;

    return {
      ...data,
      agentType: mapToAgentTypeDto(agentType),
      nameVariants: mapToNameVariantsDto(nameVariants),
      internalAgent,
    };
  }
};

const mergeWorkSummariesById = (
  works: AgentWorkSummary[],
  expressions: AgentWorkSummary[],
): AgentWorkSummary[] => {
  const all = [...works, ...expressions];
  const groupedById = groupBy(all, 'id');
  const mergedOnId = Object.keys(groupedById).map<AgentWorkSummary>(key => ({
    ...groupedById[key][0],
    roles: uniq(groupedById[key].flatMap(summary => summary.roles)),
  }));

  return mergedOnId;
};
