import React from 'react';
import {
  usePreviewSchemaGlobalScopeContext,
  usePreviewSchemaLocalScopeContext,
} from 'schema/contexts';
import {PreviewPart, PreviewPartProps} from './PreviewPart';

/**
 * Render part, wrapped with global and local scope, as needed by part
 */
export const PreviewPartWrapper: React.FC<PreviewPartProps> = props => {
  const {values: globalScope} = usePreviewSchemaGlobalScopeContext();
  const {values: localScope} = usePreviewSchemaLocalScopeContext();

  return (
    <PreviewPart globalScope={globalScope} scope={localScope} {...props} />
  );
};
