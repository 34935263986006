import {Fragment, useMemo} from 'react';
import {FCWithChildren} from 'types';
import {ContainerConfiguration} from 'schema/types';
import {Card} from 'components';
import {DefaultContainer, MinimalIndentContainer} from 'schema/form/components';

export const useContainerFromConfig = (
  config: ContainerConfiguration | undefined,
): FCWithChildren => {
  const Container = useMemo(() => {
    if (!config || config === 'DefaultCard') {
      return DefaultContainer;
    }

    if (config === 'NoContainer') {
      return Fragment;
    }

    if (config === 'MinimalIndentContainer') {
      return MinimalIndentContainer;
    }

    return (({children}) => (
      <Card {...config}>
        <Card.Content>{children}</Card.Content>
      </Card>
    )) as FCWithChildren;
  }, [config]);

  return Container;
};
