import {assert} from 'assert-ts';
import {useHotkeys} from 'react-hotkeys-hook';
import {ShortcutCommand} from '../types';
import {useShortcuts} from './useShortcuts';

export const useShortcut = <T extends HTMLElement>(
  command: ShortcutCommand,
  action: () => void,
) => {
  const shortcuts = useShortcuts();
  const shortcut = assert(
    shortcuts && shortcuts[command],
    'useShortcut: missing command short cut',
    {command, shortcuts},
  );
  return useHotkeys<T>(
    shortcut,
    action,
    {
      enabled: true,
    },
    [shortcut],
  );
};
