import {ChangeRequest} from 'api';
import {Metadata} from '../../../types';

export const replaceChangeRequest = (
  changeRequest: ChangeRequest,
  metadata: Metadata,
): Metadata => {
  return {
    ...metadata,
    changeRequests: metadata.changeRequests.map(cr =>
      cr.id === changeRequest.id ? changeRequest : cr,
    ),
  };
};
