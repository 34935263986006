import React, {useCallback, useMemo, useState} from 'react';
import {
  DataValue,
  FieldError,
  PartThesaurus,
  Valx,
} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {
  assertAsThesaurusValue,
  evaluateFieldRequired,
} from 'schemaDefinition/functions';
import {ThesaurusField} from 'components';
import {useFormSchemaGlobalScopeContext} from 'schema/contexts';
import {BasePartSimpleProps} from './types';
import {useDataFormContext} from '../contexts';
import {getErrorMessage} from '../functions';
import {toFieldError} from '../functions/validators';
import {validateThesaurus} from '../functions/validators/validateThesaurus';
import {
  useFieldId,
  useLabelAndPlaceholder,
  verifyGlobalScope,
  verifyLocalScope,
} from '../hooks';

export type DataFormThesaurusTermsProps = BasePartSimpleProps & {
  part: PartThesaurus<Valx>;
};

export const DataFormThesaurusTerms: React.FC<DataFormThesaurusTermsProps> = ({
  part,
  useValue,
  valuePath,
  scopePath,
  usesGlobalScope,
  relatedScope,
  noLabel,
  mode,
}) => {
  const {tLoose} = useLocalization();
  const [visited, setVisited] = useState(false);
  const handleSetVisited = useCallback(() => setVisited(true), []);

  const {label, placeholder} = useLabelAndPlaceholder(part, noLabel);
  const fieldId = useFieldId(valuePath);
  const {id, setEditValue, showErrors} = useDataFormContext();
  const value = useValue(valuePath, id, part.name) as DataValue;
  const scope = verifyLocalScope(
    useValue(scopePath, id, `${part.name}.localScope`),
    scopePath,
  );
  const {valuePath: globalPath} = useFormSchemaGlobalScopeContext();
  const globalScope = verifyGlobalScope(
    useValue(
      usesGlobalScope ? globalPath : undefined,
      id,
      `${part.name}.globalScope`,
    ),
    usesGlobalScope,
  );

  const setFieldValue = useCallback(
    (value: DataValue) => {
      handleSetVisited();
      setEditValue(valuePath, value, part);
    },
    [handleSetVisited, part, setEditValue, valuePath],
  );

  const required = useMemo(() => {
    return evaluateFieldRequired(
      part.required,
      valuePath,
      scope,
      globalScope,
      relatedScope,
      value,
    );
  }, [globalScope, part.required, relatedScope, scope, value, valuePath]);

  const {error, errorMessage} = useMemo((): {
    error: FieldError;
    errorMessage?: string;
  } => {
    if (showErrors || visited) {
      const validation = validateThesaurus(part, {
        value,
        valuePath,
        localScope: scope,
        globalScope,
        relatedScope,
      });

      return {
        error: toFieldError(validation.valid),
        errorMessage: getErrorMessage(validation, tLoose),
      };
    }
    return {error: false};
  }, [
    globalScope,
    part,
    relatedScope,
    scope,
    showErrors,
    tLoose,
    value,
    valuePath,
    visited,
  ]);

  const thesaurusValue = assertAsThesaurusValue(value);

  return (
    <ThesaurusField
      label={label}
      placeholder={placeholder}
      variant={part.variant}
      name={fieldId}
      thesaurusValue={thesaurusValue}
      thesaurusId={part.thesaurusId}
      showCode={part.showCode}
      selectableNodeTypes={part.selectableNodeTypes}
      required={required}
      error={error}
      errorMessage={errorMessage}
      readonly={mode === 'read-only'}
      onChange={setFieldValue}
      onBlur={handleSetVisited}
    />
  );
};
