import React, {useCallback, useMemo} from 'react';
import {Grid, SxProps} from '@mui/material';
import {ColorPalette} from 'theme';
import {IconButton, Text} from 'components';
import {ItemAction, ItemProps} from '../types';

const ContainerSx: SxProps = {
  height: 40,
  display: 'flex',
  alignItems: 'stretch',
  cursor: 'pointer',
  borderRadius: 1,
  backgroundColor: ColorPalette.primary.lightGreen,
  paddingLeft: '10px',
};

const TextSx: SxProps = {
  color: ColorPalette.primary.burgundy,
  alignContent: 'center',
};

const GridBoxSx: SxProps = {
  display: 'flex',
};

export const ListItem: React.FC<ItemProps> = ({
  label,
  active,
  onClick,
  actions,
  sxProps,
}) => {
  const WrapperSx = useMemo(() => {
    const sx = {
      ...ContainerSx,
      ...sxProps,
    };

    if (active) {
      return {
        ...sx,
        backgroundColor: ColorPalette.primary.warmGreen,
      };
    }

    return sx;
  }, [active, sxProps]);

  const ItemActions = useCallback(
    (position: ItemAction['position']) => {
      const items = (actions ?? [])
        .filter(a => a.position === position)
        .map(a => <IconButton key={a.props.icon} {...a.props} />);

      if (!items || !items.length) {
        return undefined;
      }
      return (
        <Grid item xs={'auto'}>
          {items}
        </Grid>
      );
    },
    [actions],
  );

  return (
    <Grid container sx={WrapperSx} flexDirection="row">
      {ItemActions('beforeText')}
      <Grid item xs={true} onClick={onClick} sx={GridBoxSx}>
        <Text variant="h4" sx={TextSx}>
          {label}
        </Text>
      </Grid>
      {ItemActions('afterText')}
    </Grid>
  );
};
