import {useCallback, useEffect, useMemo, useState} from 'react';

type CallState = 'none' | 'calling' | 'delayed';

/**
 * Change call state from calling to delayed, if call takes more than delay milliseconds.
 * Allows showing e.g. spinner after a while
 * @param call
 * @param delay
 * @returns {
 *   handleCall: Wrapper around provided call
 *   isCalling: call in progress
 *   isDelayed: call in progress, but delayed
 * }
 */
export const useHandleDelayedCall = (
  call: (() => Promise<void>) | (() => void),
  delay = 300,
): {
  handleCall: () => Promise<void>;
  isCalling: boolean;
  isDelayed: boolean;
} => {
  const [callState, setCallState] = useState<CallState>('none');

  const handleCall = useCallback(() => {
    setCallState(() => 'calling');
    return Promise.resolve(call()).finally(() => {
      setCallState(() => 'none');
    });
  }, [call]);

  // set delayed after delay and still calling
  useEffect(() => {
    if (callState === 'calling') {
      const handle = setTimeout(() => {
        setCallState(current => (current === 'calling' ? 'delayed' : current));
      }, delay);
      return () => clearTimeout(handle);
    }
  }, [delay, callState]);

  return useMemo(
    () => ({
      handleCall,
      isCalling: callState !== 'none',
      isDelayed: callState === 'delayed',
    }),
    [callState, handleCall],
  );
};
