import {ValidationError} from 'schemaDefinition/types';
import {MetadataValidationResultV0} from 'schemas/types';

type ValidationLevels = 'error' | 'warning' | 'errorAndWarning';

const noErrors = [] as ValidationError[];

export const getValidationLevels = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  valid,
  ...entityValidations
}: MetadataValidationResultV0): ValidationLevels | undefined => {
  const hasErrors = Object.values(entityValidations).some(
    validation =>
      validation &&
      (validation.errors ?? noErrors).some(
        (error: ValidationError) => error.level === 'error',
      ),
  );
  const hasWarnings = Object.values(entityValidations).some(
    validation =>
      validation &&
      (validation.errors ?? noErrors).some(
        (error: ValidationError) => error.level === 'warning',
      ),
  );

  return hasErrors && hasWarnings
    ? 'errorAndWarning'
    : hasErrors
    ? 'error'
    : hasWarnings
    ? 'warning'
    : undefined;
};
