import {PropsWithChildren, useMemo} from 'react';
import {BoxStyleProps} from 'components/types';
import {ColorPalette} from 'theme';
import {Layout} from '../layout';

const CardNavigationHeaderName = 'CardNavigationHeaderName';

type Props = {
  minHeight?: string | number;
};

const CardNavigationHeader: React.FC<PropsWithChildren<Props>> = ({
  minHeight = '40px',
  children,
}) => {
  const sx = useMemo(
    (): BoxStyleProps => ({
      background: ColorPalette.beige,
      px: 1,
    }),
    [],
  );

  return (
    <Layout horizontal minHeight={minHeight} sx={sx}>
      {children}
    </Layout>
  );
};

CardNavigationHeader.displayName = CardNavigationHeaderName;

export {CardNavigationHeader, CardNavigationHeaderName};
