import {Indexer} from 'types';

let lazyMocks: {[key in string]: Indexer | Indexer[]} | undefined;

export const getMockByKey = (key: string): Indexer | Indexer[] | undefined => {
  if (!lazyMocks) {
    lazyMocks = {
      search: require('./data/search.json'),
    };
  }

  return lazyMocks[key];
};
