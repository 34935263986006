import {Concept} from 'types';
import {EntityContainer, MetadataEditState} from '../types';
import {assertEntityStatus} from '../reducer/metadataEdit/functions';
import {assertChangeRequest} from './assertChangeRequest';
import {assertExpression} from './assertExpression';
import {assertManifestation} from './assertManifestation';
import {getHasChangedById} from './getHasChangedId';

export const getPotentialChangedEntities = (
  editState: MetadataEditState,
): EntityContainer[] | undefined => {
  const {metadata, order} = editState;
  if (metadata) {
    let workIncluded = false;
    const ids: EntityContainer[] = [];

    if (getHasChangedById(metadata.work.id, editState, Concept.work)) {
      ids.push({type: Concept.work, data: metadata.work});
      workIncluded = true;
    }

    order.forEach(expression => {
      let expressionIncluded = false;
      if (
        getHasChangedById(
          expression.expressionId,
          editState,
          Concept.expression,
        ) ||
        workIncluded
      ) {
        ids.push({
          type: Concept.expression,
          data: assertExpression(expression.expressionId, editState),
        });
        expressionIncluded = true;
      }

      expression.content.forEach(item => {
        if (
          item.type === Concept.manifestation &&
          (expressionIncluded ||
            getHasChangedById(item.id, editState, Concept.manifestation))
        ) {
          const entityStatus = assertEntityStatus(item.id, editState);
          ids.push({
            type: Concept.manifestation,
            data: {
              ...assertManifestation(item.id, editState),
              status: entityStatus.changedStatus ?? entityStatus.status,
            },
          });
        }
        item.changeRequests.forEach(crItem => {
          if (
            getHasChangedById(crItem.taskId, editState, Concept.changeRequest)
          ) {
            ids.push({
              type: Concept.changeRequest,
              data: assertChangeRequest(crItem.taskId, editState),
            });
          }
        });
      });
    });

    return ids;
  }
};
