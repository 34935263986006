import {useCallback, useMemo, useRef, useState} from 'react';
import {IconName} from 'components/icons/types';
import {noOp} from 'services/utils/functions/noOp';
import {AlertDialog, AlertDialogProps} from '../AlertDialog';

export type AlertDialogContentProps = Omit<
  AlertDialogProps,
  'isOpen' | 'onCancel' | 'onConfirm'
>;

export type AlertDialogAsPromiseV3 = React.FC<{
  isOpen: boolean;
  isClosing: boolean;
}>;

const voidPromiseFunc = () => Promise.resolve();

export const useAlertDialogAsPromiseV3 = (): {
  /**
   * Present dialog and resolve to true if confirmed (and onConfirm completed successfully),
   * false if cancelled
   * @param onConfirm
   * @param dialogProps
   * @returns
   */
  pleaseConfirm: (
    /**
     * Confirm action, performed while dialog is still open with spinner if delayed
     */
    onConfirm: () => void | Promise<void>,
    dialogProps: AlertDialogContentProps,
  ) => Promise<boolean>;
  AlertDialog: AlertDialogAsPromiseV3;
  isOpen: boolean;
  isClosing: boolean;
} => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  // Refs to all content and cancel, confirm functions, set
  // by pleaseConfirm before dialog is opened
  const titleRef = useRef<string>('');
  const bodyRef = useRef<string | React.ReactNode>();
  const cancelTitleRef = useRef<string>();
  const okTitleRef = useRef<string>();
  const okIconNameRef = useRef<IconName>();
  const cancelRef = useRef<() => void>(noOp);
  const confirmRef = useRef<() => Promise<void>>(voidPromiseFunc);

  const DialogRef = useRef<AlertDialogAsPromiseV3>(({isOpen, isClosing}) => {
    return isOpen || isClosing ? (
      <AlertDialog
        isOpen={isOpen}
        title={titleRef.current}
        body={bodyRef.current}
        cancelTitle={cancelTitleRef.current}
        okIcon={okIconNameRef.current}
        okTitle={okTitleRef.current}
        onCancel={cancelRef.current}
        onConfirm={confirmRef.current}
      />
    ) : null;
  });

  const delayedCloseRef = useRef<() => void>(() => {
    setIsOpen(false);
    setIsClosing(true);
    // Allow animation to complete before closing
    setTimeout(() => setIsClosing(false), 500);
  });

  const pleaseConfirm = useCallback(
    (
      onConfirm: () => void | Promise<void>,
      {title, body, cancelTitle, okIcon, okTitle}: AlertDialogContentProps,
    ) => {
      return new Promise<boolean>(resolve => {
        titleRef.current = title;
        bodyRef.current = body;
        cancelTitleRef.current = cancelTitle;
        okTitleRef.current = okTitle;
        okIconNameRef.current = okIcon;
        cancelRef.current = () => {
          delayedCloseRef.current();
          resolve(false);
        };

        confirmRef.current = () => {
          return (
            Promise.resolve(onConfirm())
              .then(() => {
                delayedCloseRef.current();
                resolve(true);
              })
              // Keep open if failing, assume error is handled by onConfirm
              .catch(() => undefined)
          );
        };

        setIsOpen(true);
      });
    },
    [],
  );

  return useMemo(
    () => ({
      pleaseConfirm,
      AlertDialog: DialogRef.current,
      isOpen,
      isClosing,
    }),
    [pleaseConfirm, isOpen, isClosing],
  );
};
