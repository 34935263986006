import {OverviewTask, TaskType} from 'api/types';

// Merge tasks by manifestations id, and merge taskTypes.
export const mergeDuplicateManifestations = (
  overviewTasks: OverviewTask[],
): OverviewTask[] => {
  const merged: OverviewTask[] = [];

  overviewTasks.forEach(ot => {
    const existingIndex = merged.findIndex(
      m => m.manifestationId && m.manifestationId === ot.manifestationId,
    );

    if (existingIndex === -1) {
      ot.taskTypes.sort(sortTaskTypes);
      merged.push(ot);
    } else {
      ot.taskTypes.forEach(taskType => {
        if (!merged[existingIndex].taskTypes.includes(taskType)) {
          merged[existingIndex].taskTypes.push(taskType);
        }
      });

      merged[existingIndex].taskTypes.sort(sortTaskTypes);
    }
  });

  return merged;
};

export const sortTaskTypes = (a: TaskType, b: TaskType): number => {
  const order = [undefined, '', 'ml', 'change', 'pre', 'new'];

  if (order.indexOf(a) === order.indexOf(b)) {
    return 0;
  }

  return order.indexOf(a) > order.indexOf(b) ? 1 : -1;
};
