import {DataValue, PartThesaurus} from 'schemaDefinition/types';
import {ThesaurusMap} from '../types';
import {getActualThesaurusCategoryPaths} from './getActualThesaurusCategoryPaths';
import {getActualThesaurusNodeTypePaths} from './getActualThesaurusNodeTypePaths';

/**
 * Gets the actual thesaurus 'group' paths ('path#category' or 'path#nodetype') for each category/type that has changes,
 * i.e. terms in category/type in originalValue and changesValue are different.
 */
export const getActualThesaurusGroupPaths = (
  part: PartThesaurus<unknown>,
  path: string,
  originalValue: DataValue,
  changesValue: DataValue,
  thesauruses: ThesaurusMap | undefined,
): string[] | undefined => {
  const {paths} =
    part.variant === 'byType'
      ? getActualThesaurusNodeTypePaths(
          part,
          path,
          originalValue,
          changesValue,
          thesauruses,
        )
      : getActualThesaurusCategoryPaths(
          part,
          path,
          originalValue,
          changesValue,
          thesauruses,
        );

  return paths;
};
