import {Sorting} from '../types';

export const getUpdatedSorting = (
  field: string,
  sorting: Sorting | undefined,
): Sorting => {
  if (sorting === undefined || sorting.field !== field) {
    return {
      field: field,
      sort: 'asc',
    };
  }

  return {
    field: field,
    sort: sorting.sort === 'asc' ? 'desc' : 'asc',
  };
};
