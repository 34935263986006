import {assert} from 'assert-ts';
import {WorkV4} from 'api/types';
import {PartValueModifier, SideEffect} from 'schemaDefinition/types';
import {isNullish} from 'services/utils';

export const deweySourceModifier: PartValueModifier = ({
  valuePath,
  value,
  isDefaultValue,
  part,
  entityValue,
}) => {
  assert(
    part && part.customizeKey === 'deweySource',
    'deweySourceModifier: part must have customizeKey deweySource',
    part,
  );
  assert(
    isNullish(value) || typeof value === 'string',
    'deweySourceModifier: value must be nullish or string',
    {value, part},
  );

  if (entityValue) {
    const workValue = entityValue as WorkV4;

    if (isDefaultValue) {
      //  When setting default value, use first dewey source, if any, otherwise given value
      return {value: workValue.dewey?.[0].source ?? value};
    }

    if (workValue.dewey && Array.isArray(workValue.dewey)) {
      // Create sideeffects of all deweys
      const sideEffects: SideEffect[] = workValue.dewey
        .map((d, i) => {
          return {
            valuePath: `dewey[${i}].source`,
            value,
          };
        })
        .filter(s => s.valuePath !== valuePath); // Removes current target

      // Return change with sideeffects
      if (sideEffects.length > 0) {
        return {
          value,
          sideEffects,
        };
      }
    }
  }

  return {
    value: value ?? null,
  };
};
