import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {assert} from 'assert-ts';
import {ThesaurusData} from '../types';
import {getAllNodesAncestorMap} from '../functions';

type Unsubscribe = () => void;
type NotificationCallback = (selected: boolean) => void;

type NotificiationCallbacks = {
  onSelectedChange?: NotificationCallback;
  onChildSelectedChange?: NotificationCallback;
  onFocusedChange?: NotificationCallback;
  onChildFocusedChange?: NotificationCallback;
};

type SubscribeToSelection = (
  nodeId: string,
  callbacks: NotificiationCallbacks,
) => Unsubscribe;

export type ThesaurusSelectionContextType = {
  subscribe: SubscribeToSelection;
};

export const ThesaurusSelectionContext = createContext<
  ThesaurusSelectionContextType | undefined
>(undefined);

export const useThesaurusSelectionContextProviderValue = (
  thesaurus: ThesaurusData,
  selectedNodeIds: string[] | undefined,
  focusedNodeId: string | undefined,
): ThesaurusSelectionContextType => {
  const nodeAncestorsMapRef = useRef(getAllNodesAncestorMap(thesaurus));
  const selectedNodeIdsRef = useRef<string[]>([]);
  const focusedNodeIdRef = useRef<string | undefined>(focusedNodeId);
  const subscriptionsRef = useRef<Record<string, NotificiationCallbacks>>({});

  // Notify all parent subscribers about selection/focus (active) changes
  const notifyNodesAndAncestors = useCallback(
    (
      prevActiveIds: string[],
      newActiveIds: string[],
      callbackName: keyof Pick<
        NotificiationCallbacks,
        'onFocusedChange' | 'onSelectedChange'
      >,
      ancestorCallbackName: keyof Pick<
        NotificiationCallbacks,
        'onChildFocusedChange' | 'onChildSelectedChange'
      >,
    ) => {
      // Find updates to node ids that become inactive or active
      // - newly inactive
      const newInactiveNodeIds = prevActiveIds.filter(
        id => !newActiveIds.includes(id),
      );
      // - new active
      const newActiveNodeIds = newActiveIds.filter(
        id => !prevActiveIds.includes(id),
      );

      // Find all effected ancestors
      const activeAncestors = newActiveIds.flatMap(
        id => nodeAncestorsMapRef.current[id],
      );
      // - newly inactive children
      const newInactiveAncestorIds = newInactiveNodeIds
        .flatMap(id => nodeAncestorsMapRef.current[id])
        .filter(id => !activeAncestors.includes(id));
      // - newly active children
      const newActiveAncestorIds = newActiveNodeIds.flatMap(
        id => nodeAncestorsMapRef.current[id],
      );

      // Notify all the subscribers of the inactive node ids
      newInactiveNodeIds.forEach(id => {
        const callbacks = subscriptionsRef.current[id];
        callbacks?.[callbackName]?.(false);
      });

      // Notify all the subscribers of the new active node ids
      newActiveNodeIds.forEach(id => {
        const callbacks = subscriptionsRef.current[id];
        callbacks?.[callbackName]?.(true);
      });

      // Notify all the "inactive" ancestors
      newInactiveAncestorIds.forEach(ancestorId => {
        const callbacks = subscriptionsRef.current[ancestorId];
        callbacks?.[ancestorCallbackName]?.(false);
      });

      // Notify all the new "active" ancestors
      newActiveAncestorIds.forEach(ancestorId => {
        const callbacks = subscriptionsRef.current[ancestorId];
        callbacks?.[ancestorCallbackName]?.(true);
      });
    },
    [],
  );

  // Notifies node about selected, child selected, focus or child focus
  const notifyCurrentActive = useCallback((nodeId: string) => {
    const callbacks = subscriptionsRef.current[nodeId];

    if (!callbacks) {
      return;
    }

    // Notify of selected node ids if selected or child selected
    // - self
    if (
      selectedNodeIdsRef.current.includes(nodeId) &&
      callbacks.onSelectedChange
    ) {
      callbacks.onSelectedChange(true);
    }

    // - child
    const hasSelectedChild = selectedNodeIdsRef.current.some(id => {
      const ancestorIds = nodeAncestorsMapRef.current[id];
      return ancestorIds?.includes(nodeId);
    });

    if (hasSelectedChild && callbacks.onChildSelectedChange) {
      callbacks.onChildSelectedChange(true);
    }

    const focusId = focusedNodeIdRef.current;
    if (focusId) {
      // Notify of focused node ids if focused or child focused
      // - self
      if (focusId === nodeId && callbacks.onFocusedChange) {
        callbacks.onFocusedChange(true);
      }

      // - child
      const hasFocusedChild =
        nodeAncestorsMapRef.current[focusId]?.includes(nodeId);

      if (hasFocusedChild && callbacks.onChildFocusedChange) {
        callbacks.onChildFocusedChange(true);
      }
    }
  }, []);

  const handleNodesSelect = useCallback(
    (nodeIds: string[]) => {
      notifyNodesAndAncestors(
        selectedNodeIdsRef.current,
        nodeIds,
        'onSelectedChange',
        'onChildSelectedChange',
      );
      selectedNodeIdsRef.current = nodeIds;
    },
    [notifyNodesAndAncestors],
  );

  const handleNodeFocus = useCallback(
    (nodeId: string | undefined) => {
      if (nodeId !== focusedNodeIdRef.current) {
        notifyNodesAndAncestors(
          focusedNodeIdRef.current ? [focusedNodeIdRef.current] : [],
          nodeId ? [nodeId] : [],
          'onFocusedChange',
          'onChildFocusedChange',
        );
        focusedNodeIdRef.current = nodeId;
      }
    },
    [notifyNodesAndAncestors],
  );

  const subscribe = useCallback<SubscribeToSelection>(
    (nodeId: string, callbacks: NotificiationCallbacks) => {
      subscriptionsRef.current[nodeId] = callbacks;
      notifyCurrentActive(nodeId);

      return () => {
        delete subscriptionsRef.current[nodeId];
      };
    },
    [notifyCurrentActive],
  );

  // Handle selection changed, notify the subscribers
  useEffect(() => {
    handleNodesSelect(selectedNodeIds ?? []);
  }, [handleNodesSelect, selectedNodeIds]);

  // Handle focus changed, notify the subscribers
  useEffect(() => {
    handleNodeFocus(focusedNodeId);
  }, [focusedNodeId, handleNodeFocus]);

  return useMemo(
    () => ({
      subscribe,
    }),
    [subscribe],
  );
};

export const useThesaurusSelectionContext =
  (): ThesaurusSelectionContextType => {
    return assert(
      useContext(ThesaurusSelectionContext),
      'useThesaurusSelectionContext: context expected',
    );
  };
