import {ReactElement} from 'react';
import {DataValue, Part, Valx} from 'schemaDefinition/types';
import {BaseFieldProps} from '../types';
import {ValueLayout} from './ValueLayout';

type Props<TPart extends Part<Valx>, TValue extends DataValue> = Pick<
  BaseFieldProps<TPart, TValue | TValue[]>,
  'part' | 'layoutDirection'
> & {
  renderValue: () => ReactElement | null;
};

export const SingleValue = <
  TPart extends Part<Valx>,
  TValue extends DataValue,
>({
  part,
  layoutDirection,
  renderValue,
}: Props<TPart, TValue>) => {
  return (
    <ValueLayout
      renderValue={renderValue}
      part={part}
      layoutDirection={layoutDirection}
    />
  );
};
