import {Fragment} from 'react';
import {PartValueRenderer} from 'schema/types';
import {isNullish} from 'services/utils';
import {CopyToClipboard} from 'components';
import {useTextValue} from 'schema/preview/hooks';

export const TextCollection: PartValueRenderer = ({value}) => {
  const TextValue = useTextValue();

  if (isNullish(value) || !Array.isArray(value) || value.length === 0) {
    return null;
  }

  const withoutEmpty = (value as string[]).filter((v: string) => v);
  if (withoutEmpty.length === 0) {
    return null;
  }

  return (
    <Fragment>
      {withoutEmpty.map(v => (
        <Fragment key={v as string}>
          <TextValue>{String(v)}</TextValue>
          <CopyToClipboard text={String(v)} size={'small'} />
        </Fragment>
      ))}
    </Fragment>
  );
};
