import {RangeCondition, Valx} from '../types';
import {EvaluationArgs} from './types';
import {compareDateValues} from './compareDateValues';
import {evaluateValxWithDefault} from './evaluateValxWithDefault';
import {getDateRange} from './getDateRange';
import {getDateMaxValue, getDateMinValue} from './getDateValue';

export const evaluateDateRangeCondition = (
  condition: RangeCondition<Valx>,
  args: EvaluationArgs,
): boolean => {
  const {minValue, maxValue} = getDateRange(condition, args);

  const arg1Valx = evaluateValxWithDefault(condition.arg1, args, args.value);

  // In case of year, arg1 is date range 1-1 - 12-31,
  // and arg1Low <= max and arg1High >= min
  const arg1LowValue = getDateMinValue(arg1Valx);
  const arg1HighValue = getDateMaxValue(arg1Valx);

  return (
    (arg1HighValue === undefined ||
      minValue === undefined ||
      compareDateValues(arg1HighValue, minValue) !== -1) &&
    (arg1LowValue === undefined ||
      maxValue === undefined ||
      compareDateValues(arg1LowValue, maxValue) !== 1)
  );
};
