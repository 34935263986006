import {useCallback, useMemo, useState} from 'react';
import {useStore} from 'react-redux';
import assert from 'assert-ts';
import {ExpressionV4, ManifestationV4} from 'api/types';
import {Valid} from 'schemaDefinition/types';
import {
  ManifestationValidationResultV0,
  MetadataValidationResult,
} from 'schemas/types';
import {AppState} from 'store/types';
import {useLocalization} from 'localization';
import {aggregateValid} from 'schemaDefinition/functions';
import {CodelistRestrictor} from 'services/codeLists';
import {
  assertEntityStatus,
  assertExpression,
  assertManifestation,
} from 'services/data';
import {validateNewExpressionWithManifestation} from 'services/data/metadata/functions/validateNewExpressionWithManifestation';
import {useMetadataEditExpression} from 'services/data/metadata/hooks/useMetadataEditExpression';
import {useSnacks} from 'components';
import {trimDataValue} from 'schema/form/functions';
import {useExpressionAndManifestationCodelistRestrictor} from 'schemas/codelistRestrictors/hooks/useExpressionAndManifestationCodelistRestrictor';
import {useMetadataOperationsContext} from 'scenes/updateMetadata/contexts';
import {ExpressionCardCoreProps} from '../../ExpressionCard/types';
import {ManifestationCardProps} from '../../ManifestationCard/types';
import {useExpressionEditStateCore} from '../../ExpressionCard/hooks/useExpressionEditStateCore';
import {useManifestationEditState} from '../../ManifestationCard/hooks';

export const useNewExpressionWithManifestationEditState = (
  workId: string,
  expressionId: string,
  manifestationId: string,
): {
  expressionEditState: ReturnType<typeof useExpressionEditStateCore>;
  manifestationEditState: ReturnType<typeof useManifestationEditState>;
  codelistRestrictor: CodelistRestrictor;
  valid: Valid | undefined;
  showErrors: boolean;
  saving: boolean;
  handleSave: (
    saveWithWarnings?: boolean,
  ) => Promise<ManifestationValidationResultV0>;
  handleCancel: () => void;
} => {
  const {getState} = useStore<AppState>();
  const {t, tryT} = useLocalization();
  const expression = useMetadataEditExpression(expressionId);

  const expressionParams = useMemo(
    (): ExpressionCardCoreProps => ({
      workId,
      expressionId,
      stableExpression: expression,
      readonly: false,
    }),
    [expression, expressionId, workId],
  );

  const expressionEditState = useExpressionEditStateCore(expressionParams);

  const manifestationParams = useMemo(
    (): ManifestationCardProps => ({
      workId,
      expressionId,
      manifestationId,
      // TODO: Also move change requests
      changeRequests: undefined,
      readonly: false,
      initialExpanded: true,
    }),
    [expressionId, manifestationId, workId],
  );

  const manifestationEditState = useManifestationEditState(manifestationParams);

  const [valid, setValid] = useState<Valid | undefined>(undefined);
  const [showErrors, setShowErrors] = useState(false);
  const [saving, setSaving] = useState(false);

  const {successSnack, errorSnack} = useSnacks();

  const {
    saveNewExpressionWithManifestation,
    cancelMoveManifestationToNewExpression,
  } = useMetadataOperationsContext();

  const codelistRestrictor = useExpressionAndManifestationCodelistRestrictor(
    manifestationId,
    expressionId,
  );

  const handleValidate = useCallback((): MetadataValidationResult => {
    const {savedMetadata, metadata, configuration, statuses} =
      getState().metadataEdit;
    const validation = validateNewExpressionWithManifestation({
      savedWork: assert(
        savedMetadata?.work,
        'handleValidate: savedWork expected',
      ),
      work: assert(metadata?.work, 'handleValidate: work expected'),
      expression: assert(
        metadata?.expressions.find(e => e.id === expressionId),
        'handleValidate: expression expected',
      ),
      savedManifestation: assert(
        savedMetadata?.manifestations.find(m => m.id === manifestationId),
        'handleValidate: savedManifestation expected',
      ),
      manifestation: assert(
        metadata?.manifestations.find(m => m.id === manifestationId),
        'handleValidate: manifestation expected',
      ),
      configuration,
      statuses,
    });

    return validation;
  }, [expressionId, getState, manifestationId]);

  const handleSave = useCallback(
    (saveWithWarnings?: boolean): Promise<ManifestationValidationResultV0> => {
      const currentExpressoinEdit = assertExpression(
        expressionId,
        getState().metadataEdit,
      );
      const currentManifestationEdit = assertManifestation(
        manifestationId,
        getState().metadataEdit,
      );

      const manifestationStatus = assertEntityStatus(
        manifestationId,
        getState().metadataEdit,
        'useNewExpressionWithManifestationEditState.handleSave',
      );

      const trimmedExpression: ExpressionV4 = trimDataValue(
        currentExpressoinEdit,
        expressionEditState.schema,
      );

      const trimmedManifestationEditWithStatus: ManifestationV4 = {
        ...trimDataValue(
          currentManifestationEdit,
          manifestationEditState.schema,
        ),
        status: manifestationStatus.changedStatus ?? manifestationStatus.status,
      };

      const validation = handleValidate();

      if (
        validation.valid === 'error' ||
        (!saveWithWarnings && validation.valid === 'warning')
      ) {
        setShowErrors(true);
        return Promise.resolve(validation);
      }

      setShowErrors(false);
      setValid(undefined);
      setSaving(true);

      return saveNewExpressionWithManifestation(
        trimmedExpression,
        trimmedManifestationEditWithStatus,
      ).then(saveStatus => {
        if (saveStatus.status === 'Saved') {
          successSnack(t('page.metadata.newExpression.save.success'));
        } else {
          const messageKey = saveStatus.error?.message;

          const message =
            tryT(`error.api.${messageKey}`) ??
            t('page.metadata.newExpression.save.failed');

          errorSnack(message, saveStatus.error);
        }
        setSaving(false);
        return validation;
      });
    },
    [
      expressionId,
      getState,
      manifestationId,
      expressionEditState.schema,
      manifestationEditState.schema,
      handleValidate,
      saveNewExpressionWithManifestation,
      successSnack,
      t,
      tryT,
      errorSnack,
    ],
  );

  const handleCancel = useCallback(
    () => cancelMoveManifestationToNewExpression(manifestationId),
    [cancelMoveManifestationToNewExpression, manifestationId],
  );

  return useMemo(() => {
    const aggregatedValid = aggregateValid(
      valid,
      expressionEditState.valid,
      manifestationEditState.valid,
    );
    return {
      expressionEditState,
      manifestationEditState,
      codelistRestrictor,
      valid: aggregatedValid,
      showErrors: showErrors || expressionEditState.showErrors,
      saving,
      handleSave,
      handleCancel,
    };
  }, [
    codelistRestrictor,
    expressionEditState,
    handleCancel,
    handleSave,
    manifestationEditState,
    saving,
    showErrors,
    valid,
  ]);
};
