import {useRef} from 'react';
import {Thesaurus, ThesaurusNode, ThesaurusValue} from 'api';
import {clone} from 'services/utils';
import {findAllNodeIds, getNodesAndAncestors} from '../functions';

const getTrimmedThesaurus = (
  fullThesaurus: Thesaurus,
  value: ThesaurusValue = [],
) => {
  const nodeIds = findAllNodeIds(value, fullThesaurus);
  const nodesWithAncestors = getNodesAndAncestors(nodeIds, fullThesaurus);
  const allNodeIds = nodesWithAncestors
    .map(node => [node.node.id, ...node.ancestors.map(ancestor => ancestor.id)])
    .flat();
  const trimmedThesaurus = clone(fullThesaurus);

  const trimChildren = <TNode extends ThesaurusNode>(node: TNode): TNode => {
    if (node.children) {
      node.children = node.children.filter(
        child => !child.deactivated || allNodeIds.includes(child.id),
      );
      node.children.forEach(trimChildren);
    }
    return node;
  };

  return trimChildren(trimmedThesaurus);
};

/**
 * Remove all deactivated nodes, unless currently selected (i.e. part of value)
 */
export const useTrimmedThesaurus = (
  fullThesaurus: Thesaurus,
  initialValue: ThesaurusValue | undefined,
): Thesaurus => {
  const trimmedThesaurusRef = useRef<Thesaurus>(
    getTrimmedThesaurus(fullThesaurus, initialValue),
  );
  return trimmedThesaurusRef.current;
};
