import {ManifestationV4} from 'api/types';
import {ManifestationExportProps} from 'services/data/metadata/types';

export const getManifestationExportProps = (
  manifestation: ManifestationV4,
): ManifestationExportProps => {
  return {
    productForm: manifestation?.productForm,
  };
};
