import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, Chip, Typography, styled} from '@mui/material';
import {TreeItem, TreeItemProps, treeItemClasses} from '@mui/x-tree-view';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {ChevronRightIcon} from '../../icons';
import {useThesaurusSelectionContext} from '../contexts/ThesaurusSelectionContext';
import TransitionComponent from './TransitionComponent';

type Props = TreeItemProps & {
  code: string | undefined;
  selected?: boolean;
  hasChildrenSelected?: boolean;
  onlyTopLevel?: boolean;
};

const StyledTreeItem = styled(TreeItem)(() => ({
  [`& .${treeItemClasses.label}`]: {
    '& .labelBox': {
      display: 'flex',
      alignItems: 'center',
      padding: '2px 0',
      minHeight: '42px',
      '& .outerBox': {
        margin: '3px 0 3px 3px',
        borderRadius: 8,
        backgroundColor: ColorPalette.secondary.beige,
        '& .nodeCode': {
          padding: '4px 8px',
          color: ColorPalette.burgundy,
        },
      },
      '& .nodeText': {
        paddingLeft: '4px',
        color: ColorPalette.primary.burgundy,
      },
      '& .nodeStatus': {
        marginLeft: 'auto',
        color: 'inherit',
      },
    },
  },
  [`& .${treeItemClasses.content}`]: {
    marginTop: '4px',
    borderRadius: 4,
    flex: 1,
    backgroundColor: `${ColorPalette.secondary.lightBeige}`,
    '&:hover': {
      backgroundColor: `${ColorPalette.enhancedLightBeige}`,
    },
  },
  [`& .${treeItemClasses.selected}`]: {
    backgroundColor: `${ColorPalette.secondary.beige}!important`,
    [`& .${treeItemClasses.content}`]: {
      backgroundColor: `${ColorPalette.secondary.beige}!important`,
      '&:hover': {
        backgroundColor: `${ColorPalette.secondary.beige}!important`,
      },
      '&:focus': {
        backgroundColor: `${ColorPalette.secondary.beige}!important`,
      },
    },
  },
}));

const focusedText = {fontWeight: 'bold'} as const;

const selectedChip = {
  backgroundColor: ColorPalette.secondary.beige,
  color: ColorPalette.warmGreen,
} as const;

export const ThesaurusItem: React.FC<Props> = props => {
  const {nodeId, code, label, onlyTopLevel, ...rest} = props;
  const {t} = useLocalization();

  // Whether node is selected (part of the thesaurus value) or not
  const [selected, setSelected] = useState<boolean>(false);
  // Whether node is focused (clicked by user in the tree) or not
  const [focused, setFocused] = useState<boolean>(false);
  // Whether node has a child that is focused (clicked by user in the tree) or not
  const [childFocused, setChildFocused] = useState<boolean>(false);
  const {subscribe} = useThesaurusSelectionContext();

  useEffect(() => {
    return subscribe(nodeId, {
      onSelectedChange: setSelected,
      onFocusedChange: setFocused,
      onChildFocusedChange: setChildFocused,
    });
  }, [nodeId, subscribe]);

  return (
    <StyledTreeItem
      TransitionComponent={TransitionComponent}
      nodeId={nodeId}
      label={
        <Box className={'labelBox'}>
          {code ? (
            <Box className={'outerBox'}>
              <Typography className={'nodeCode'} variant="subtitle1">
                {code}
              </Typography>
            </Box>
          ) : null}
          <Typography
            variant="body2"
            className={'nodeText'}
            style={focused || childFocused ? focusedText : undefined}>
            {label}
          </Typography>
          <Typography className={'nodeStatus'} variant="label">
            {selected ? (
              <Chip
                label={t('extension.thesaurusEdit.selected')}
                sx={selectedChip}
                color="primary"
                size="small"
              />
            ) : null}
            {onlyTopLevel ? <ChevronRightIcon color={'primary'} /> : null}
          </Typography>
        </Box>
      }
      {...rest}
    />
  );
};
