import {assert} from 'assert-ts';
import isEqual from 'lodash/isEqual';
import {Concept} from 'types';
import {
  ExpressionV4,
  ManifestationStatus,
  ManifestationV4,
  WorkV4,
} from 'api/types';
import {MetadataEditState} from '../../types';
import {assertEntityStatus} from '../../functions';
import {getMetadataOrder} from '../../functions/getMetadataOrder';
import {
  MetadataEditDefaultState as Default,
  MetadataEditAction,
} from '../../metadataEditActionTypes';
import {
  addApproval,
  addNewChangeRequestFromCopy,
  addNewExpression,
  aggregateChangedStatus,
  aggregatePreviewStatus,
  aggregateStatuses,
  assertExpression,
  assertManifestation,
  assertMetadata,
  cancelMoveManifestationToNewExpression,
  changeManifestationExpressionId,
  completeChangeRequest,
  createExpression,
  getMetadataExports,
  getMetadataTimestamps,
  incrementMetadataTimestampForId,
  incrementMetadataTimestamps,
  moveManifestationToNewExpression,
  putById,
  removeById,
  removeEditStatus,
  replaceChangeRequest,
  replaceExpression,
  replaceLocalExpression,
  replaceManifestation,
  replaceManifestationData,
  replaceWork,
  updateEditStatusOnCancelMoveToNewExpression,
  updateEditStatusOnEdit,
  updateEditStatusOnMove,
  updateEditStatusOnMoveToNewExpression,
  updateEntitySavedStatus,
  updateExpressionExportProps,
  updateManifestationExportProps,
  updateWorkExportProps,
} from './functions';

export const metadataEditReducer = (
  state: MetadataEditState = Default,
  action: MetadataEditAction,
): MetadataEditState => {
  switch (action.type) {
    case 'METADATA_RESET': {
      return Default;
    }

    case 'METADATAEDIT_SET_SAVING': {
      const {saving} = action.payload;
      return {
        ...state,
        saving,
      };
    }

    case 'METADATAEDIT_LOADED': {
      const {schemas, codelists, metadata} = action.payload;
      return {
        configuration: {
          schemas,
          codelists,
        },
        saving: false,
        savedMetadata: metadata,
        metadata: metadata,
        approvedChanges: Default.approvedChanges,
        timestamps: getMetadataTimestamps(metadata),
        newExpressionIds: Default.newExpressionIds,
        order: getMetadataOrder(metadata, [], codelists),
        statuses: aggregateStatuses(metadata),
        editStatuses: Default.editStatuses,
        editModes: Default.editModes,
        exports: getMetadataExports(metadata),
      };
    }
    case 'METADATAEDIT_CANCELLED':
    case 'METADATAEDIT_SAVED': {
      const savedMetadata = assertMetadata(
        state.savedMetadata,
        action,
        'saved',
      );
      const codelists = state.configuration.codelists;

      return {
        configuration: state.configuration,
        saving: state.saving,
        savedMetadata: savedMetadata,
        metadata: savedMetadata,
        approvedChanges: Default.approvedChanges,
        timestamps: incrementMetadataTimestamps(state.timestamps),
        newExpressionIds: Default.newExpressionIds,
        order: getMetadataOrder(savedMetadata, [], codelists),
        statuses: aggregateStatuses(savedMetadata),
        editStatuses: Default.editStatuses,
        editModes: Default.editModes,
        exports: getMetadataExports(savedMetadata),
      };
    }
    case 'METADATAEDIT_WORK_EDITED': {
      const savedMetadata = assertMetadata(
        state.savedMetadata,
        action,
        'saved',
      );
      const metadata = assertMetadata(state.metadata, action, 'edit');
      const newWork = {...metadata.work, ...action.payload};
      return {
        saving: state.saving,
        configuration: state.configuration,
        savedMetadata: state.savedMetadata,
        metadata: replaceWork(newWork, metadata),
        approvedChanges: state.approvedChanges,
        timestamps: state.timestamps,
        newExpressionIds: state.newExpressionIds,
        order: state.order,
        statuses: state.statuses,
        editStatuses: updateEditStatusOnEdit(
          savedMetadata.work,
          newWork,
          state.editStatuses,
        ),
        editModes: state.editModes,
        exports: updateWorkExportProps(newWork, state.exports),
      };
    }

    case 'METADATA_WORK_SAVED': {
      const savedMetadata = assertMetadata(
        state.savedMetadata,
        action,
        'saved',
      );
      const metadata = assertMetadata(state.metadata, action, 'edit');
      const newWork = action.payload;

      return {
        configuration: state.configuration,
        saving: state.saving,
        savedMetadata: replaceWork(newWork, savedMetadata),
        metadata: replaceWork(newWork, metadata),
        approvedChanges: state.approvedChanges,
        timestamps: incrementMetadataTimestampForId(
          newWork.id,
          state.timestamps,
        ),
        newExpressionIds: state.newExpressionIds,
        order: state.order,
        statuses: updateEntitySavedStatus(newWork.id, state.statuses, action),
        editStatuses: removeEditStatus(newWork.id, state.editStatuses),
        editModes: removeById(newWork.id, state.editModes),
        exports: updateWorkExportProps(newWork, state.exports),
      };
    }

    case 'METADATAEDIT_EXPRESSION_EDITED': {
      // Handles editing of expressions loaded from backend (saved expressions)
      const savedExpression = assertExpression(
        action.payload.id,
        state.savedMetadata,
        action,
        'saved',
      );
      const currentMetadata = assertMetadata(state.metadata, action, 'edit');
      const currentExpression = assertExpression(
        action.payload.id,
        state.metadata,
        action,
        'edit',
      );

      const newExpression = {...currentExpression, ...action.payload};
      const newMetadata = replaceExpression(newExpression, currentMetadata);

      return {
        configuration: state.configuration,
        saving: state.saving,
        savedMetadata: state.savedMetadata,
        metadata: newMetadata,
        approvedChanges: state.approvedChanges,
        timestamps: state.timestamps,
        newExpressionIds: state.newExpressionIds,
        order: state.order,
        statuses: state.statuses,
        editStatuses: updateEditStatusOnEdit(
          savedExpression,
          newExpression,
          state.editStatuses,
        ),
        editModes: state.editModes,
        exports: updateExpressionExportProps(newExpression, state.exports),
      };
    }

    case 'METADATAEDIT_NEW_EXPRESSION_EDITED': {
      const currentMetadata = assertMetadata(state.metadata, action, 'edit');
      const currentExpression = assertExpression(
        action.payload.id,
        state.metadata,
        action,
        'edit',
      );

      const changedExpression = {...currentExpression, ...action.payload};
      // if (isEqual(changedExpression, currentExpression)) {
      //   return state;
      // }

      const changedMetadata = replaceExpression(
        changedExpression,
        currentMetadata,
      );

      if (isEqual(changedMetadata, currentMetadata)) {
        return state;
      }

      return {
        configuration: state.configuration,
        saving: state.saving,
        savedMetadata: state.savedMetadata,
        metadata: changedMetadata,
        approvedChanges: state.approvedChanges,
        timestamps: state.timestamps,
        newExpressionIds: state.newExpressionIds,
        order: state.order,
        statuses: state.statuses,
        editStatuses: state.editStatuses,
        editModes: state.editModes,
        exports: updateExpressionExportProps(changedExpression, state.exports),
      };
    }

    case 'METADATA_EXPRESSION_SAVED': {
      const savedMetadata = assertMetadata(
        state.savedMetadata,
        action,
        'saved',
      );
      const metadata = assertMetadata(state.metadata, action, 'edit');
      const newExpression = action.payload;

      return {
        configuration: state.configuration,
        saving: state.saving,
        savedMetadata: replaceExpression(newExpression, savedMetadata),
        metadata: replaceExpression(newExpression, metadata),
        approvedChanges: state.approvedChanges,
        timestamps: incrementMetadataTimestampForId(
          newExpression.id,
          state.timestamps,
        ),
        // TODO: BB-3632
        newExpressionIds: state.newExpressionIds,
        order: state.order,
        statuses: updateEntitySavedStatus(
          newExpression.id,
          state.statuses,
          action,
        ),
        editStatuses: removeEditStatus(newExpression.id, state.editStatuses),
        editModes: removeById(newExpression.id, state.editModes),
        exports: updateExpressionExportProps(newExpression, state.exports),
      };
    }

    case 'METADATA_NEW_EXPRESSION_WITH_MANIFESTATION_SAVED': {
      const savedMetadata = assertMetadata(
        state.savedMetadata,
        action,
        'saved',
      );
      const metadata = assertMetadata(state.metadata, action, 'edit');
      const {
        localExpressionId,
        expression: savedExpression,
        manifestation: savedManifestation,
      } = action.payload;

      const newSavedMetadata1 = replaceManifestation(
        savedManifestation,
        savedMetadata,
      );
      const newSavedMetadata2 = addNewExpression(
        savedExpression,
        newSavedMetadata1,
      );

      const newMetadata1 = replaceManifestation(savedManifestation, metadata);
      const newMetadata2 = replaceLocalExpression(
        localExpressionId,
        savedExpression,
        newMetadata1,
      );

      const newTimestamps1 = incrementMetadataTimestampForId(
        savedExpression.id,
        state.timestamps,
      );
      const newTimestamps2 = incrementMetadataTimestampForId(
        savedManifestation.id,
        newTimestamps1,
      );

      const newNewExpressionIds = state.newExpressionIds.filter(
        id => id !== localExpressionId,
      );

      const newEditStatuses1 = removeEditStatus(
        savedManifestation.id,
        state.editStatuses,
      );
      const newEditStatuses2 = removeEditStatus(
        localExpressionId,
        newEditStatuses1,
      );

      const newEditModes1 = removeById(savedManifestation.id, state.editModes);
      const newEditModes2 = removeById(localExpressionId, newEditModes1);

      return {
        configuration: state.configuration,
        saving: state.saving,
        savedMetadata: newSavedMetadata2,
        metadata: newMetadata2,
        approvedChanges: state.approvedChanges,
        timestamps: newTimestamps2,
        newExpressionIds: newNewExpressionIds,
        // Recalcualte order, statuses and exports - assuming all data have been saved
        order: getMetadataOrder(
          newMetadata2,
          newNewExpressionIds,
          state.configuration.codelists,
        ),
        statuses: aggregateStatuses(newMetadata2),
        editStatuses: newEditStatuses2,
        editModes: newEditModes2,
        exports: getMetadataExports(newMetadata2),
      };
    }

    case 'METADATAEDIT_CHANGEREQUEST_EDITED': {
      const metadata = assertMetadata(state.metadata, action, 'edit');
      return {
        ...state,
        metadata: replaceChangeRequest(action.payload, metadata),
      };
    }

    case 'METADATAEDIT_MANIFESTATION_EDITED': {
      const savedManifestation = assertManifestation(
        action.payload.id,
        state.savedMetadata,
        action,
        'saved',
      );
      const currentMetadata = assertMetadata(state.metadata, action, 'edit');
      const currentStatus = state.editStatuses[action.payload.id];

      const newMetadata = replaceManifestationData(
        action.payload,
        currentMetadata,
      );

      const newManifestation = assertManifestation(
        action.payload.id,
        newMetadata,
        action,
        'edit',
      );

      return {
        configuration: state.configuration,
        saving: state.saving,
        savedMetadata: state.savedMetadata,
        metadata: newMetadata,
        approvedChanges: state.approvedChanges,
        timestamps: state.timestamps,
        // TODO: BB-3632
        newExpressionIds: state.newExpressionIds,
        order: currentStatus?.hasMoved
          ? getMetadataOrder(
              newMetadata,
              state.newExpressionIds,
              state.configuration.codelists,
            )
          : state.order,
        statuses: state.statuses,
        editStatuses: updateEditStatusOnEdit(
          savedManifestation,
          newManifestation,
          state.editStatuses,
        ),
        editModes: state.editModes,
        exports: updateManifestationExportProps(
          newManifestation,
          state.exports,
        ),
      };
    }

    case 'METADATA_MANIFESTATION_SAVED': {
      const savedMetadata = assertMetadata(
        state.savedMetadata,
        action,
        'saved',
      );
      const metadata = assertMetadata(state.metadata, action, 'edit');
      const newManifestation = action.payload.manifestation;
      const newWork = action.payload.work;

      return {
        configuration: state.configuration,
        saving: state.saving,
        savedMetadata: replaceManifestation(newManifestation, savedMetadata),
        metadata: replaceWork(
          newWork,
          replaceManifestation(newManifestation, metadata),
        ),
        approvedChanges: state.approvedChanges,
        timestamps: incrementMetadataTimestampForId(
          newManifestation.id,
          state.timestamps,
        ),
        // TODO: BB-3632
        newExpressionIds: state.newExpressionIds,
        order: state.order,
        statuses: updateEntitySavedStatus(
          newManifestation.id,
          state.statuses,
          action,
        ),
        editStatuses: removeEditStatus(newManifestation.id, state.editStatuses),
        editModes: removeById(newManifestation.id, state.editModes),
        exports: updateManifestationExportProps(
          newManifestation,
          state.exports,
        ),
      };
    }

    case 'METADATAEDIT_APPROVE_CHANGEREQUESTITEM': {
      const {changeRequestId, rootValuePath, itemAction, newValue, statusPath} =
        action.payload;
      const {approvals, updatedEntity, entityType} = addApproval(
        changeRequestId,
        rootValuePath,
        itemAction,
        newValue,
        statusPath,
        state.approvedChanges,
        assert(
          state.metadata,
          'METADATAEDIT_APPROVE_CHANGEREQUESTITEM: metadata expected',
        ),
      );
      const state1: MetadataEditState = {
        ...state,
        approvedChanges: approvals,
      };

      switch (entityType) {
        case Concept.work:
          return metadataEditReducer(state1, {
            type: 'METADATAEDIT_WORK_EDITED',
            payload: updatedEntity as WorkV4,
          });
        case Concept.expression:
          return metadataEditReducer(state1, {
            type: 'METADATAEDIT_EXPRESSION_EDITED',
            payload: updatedEntity as ExpressionV4,
          });
        case Concept.manifestation:
          return metadataEditReducer(state1, {
            type: 'METADATAEDIT_MANIFESTATION_EDITED',
            payload: updatedEntity as ManifestationV4,
          });
        default: {
          assert(
            false,
            'METADATAEDIT_APPROVE_CHANGEREQUESTITEM: unknown entity type',
          );
          return state;
        }
      }
    }

    case 'METADATAEDIT_CLOSE_CHANGEREQUEST': {
      const {changeRequestId} = action.payload;

      return completeChangeRequest(changeRequestId, state);
    }

    case 'METADATAEDIT_NEW_CHANGEREQUEST_FROM_COPY': {
      const changeRequest = action.payload;
      return addNewChangeRequestFromCopy(changeRequest, state);
    }

    case 'METADATAEDIT_MANIFESTATION_STAUTS_PREVIEW': {
      const {id, status} = action.payload;
      const metadata = assertMetadata(state.metadata, action, 'edit');
      const currentEntityStatus = assertEntityStatus(id, state, action.type);

      const newPreviewStatus: ManifestationStatus | undefined =
        status ===
        (currentEntityStatus?.changedStatus ?? currentEntityStatus.status)
          ? undefined
          : status;

      const newState = {
        ...state,
        statuses: aggregatePreviewStatus(
          newPreviewStatus,
          id,
          state.statuses,
          metadata,
        ),
      };
      return newState;
    }

    case 'METADATAEDIT_MANIFESTATION_STATUS_CHANGED': {
      const {id, status} = action.payload;
      const metadata = assertMetadata(state.metadata, action, 'edit');
      const currentEntityStatus = assertEntityStatus(id, state, action.type);

      const newChangedStatus: ManifestationStatus | undefined =
        status === currentEntityStatus.status ? undefined : status;

      return {
        ...state,
        statuses: aggregateChangedStatus(
          newChangedStatus,
          id,
          state.statuses,
          metadata,
        ),
      };
    }

    case 'MOVE_MANIFESTATION_TO_OTHER_EXPRESSION': {
      const metadata = assertMetadata(state.metadata, action, 'edit');
      const {manifestationId, expressionId} = action.payload;

      const newMetadata = changeManifestationExpressionId(
        manifestationId,
        expressionId,
        metadata,
        action,
      );

      return {
        configuration: state.configuration,
        saving: state.saving,
        savedMetadata: state.savedMetadata,
        metadata: newMetadata,
        approvedChanges: state.approvedChanges,
        timestamps: state.timestamps,
        newExpressionIds: state.newExpressionIds,
        // Recalcualte order, statuses and exports - assuming all data have been saved
        order: getMetadataOrder(
          newMetadata,
          state.newExpressionIds,
          state.configuration.codelists,
        ),
        statuses: aggregateStatuses(newMetadata),
        editStatuses: updateEditStatusOnMove(
          manifestationId,
          expressionId,
          state.editStatuses,
          state,
        ),
        editModes: state.editModes,
        exports: getMetadataExports(newMetadata),
      };
    }

    case 'MOVE_MANIFESTATION_TO_NEW_EXPRESSION': {
      const metadata = assertMetadata(state.metadata, action, 'edit');
      const {manifestationId} = action.payload;
      const manifestation = assertManifestation(
        manifestationId,
        metadata,
        action,
        'edit',
      );
      const fromExpression = assertExpression(
        manifestation.expressionId,
        metadata,
        action,
        'edit',
      );

      const newExpression = createExpression(fromExpression);

      const newMetadata = moveManifestationToNewExpression(
        manifestationId,
        newExpression,
        metadata,
        action,
      );

      const newNewExpressionIds = [...state.newExpressionIds, newExpression.id];

      return {
        configuration: state.configuration,
        saving: state.saving,
        savedMetadata: state.savedMetadata,
        metadata: newMetadata,
        approvedChanges: state.approvedChanges,
        timestamps: state.timestamps,
        newExpressionIds: newNewExpressionIds,
        // Recalcualte order, statuses and exports - assuming all data have been saved
        order: getMetadataOrder(
          newMetadata,
          newNewExpressionIds,
          state.configuration.codelists,
        ),
        statuses: aggregateStatuses(newMetadata),
        editStatuses: updateEditStatusOnMoveToNewExpression(
          manifestationId,
          newExpression.id,
          state.editStatuses,
        ),
        editModes: state.editModes,
        exports: getMetadataExports(newMetadata),
      };
    }

    case 'CANCEL_MOVE_MANIFESTATION_TO_NEW_EXPRESSION': {
      const metadata = assertMetadata(state.metadata, action, 'edit');
      const savedMetadata = assertMetadata(state.savedMetadata, action, 'edit');
      const {manifestationId} = action.payload;
      const manifestation = assertManifestation(
        manifestationId,
        metadata,
        action,
        'edit',
      );
      const newExpressionId = manifestation.expressionId;
      const savedManifestation = assertManifestation(
        manifestationId,
        savedMetadata,
        action,
        'saved',
      );

      const newMetadata = cancelMoveManifestationToNewExpression(
        manifestation,
        savedManifestation,
        metadata,
        action,
      );

      const newNewExpressionIds = state.newExpressionIds.filter(
        id => id !== newExpressionId,
      );

      return {
        configuration: state.configuration,
        saving: state.saving,
        savedMetadata: state.savedMetadata,
        metadata: newMetadata,
        approvedChanges: state.approvedChanges,
        timestamps: state.timestamps,
        newExpressionIds: newNewExpressionIds,
        // Recalcualte order, statuses and exports - assuming all data have been saved
        order: getMetadataOrder(
          newMetadata,
          newNewExpressionIds,
          state.configuration.codelists,
        ),
        statuses: aggregateStatuses(newMetadata),
        editStatuses: updateEditStatusOnCancelMoveToNewExpression(
          manifestationId,
          newExpressionId,
          state.editStatuses,
        ),
        editModes: state.editModes,
        exports: getMetadataExports(newMetadata),
      };
    }

    case 'METADATAEDIT_SET_EDITMODE': {
      const {id, key, mode} = action.payload;

      return {
        ...state,
        editModes: putById(id, {[key]: mode}, {}, state.editModes),
      };
    }

    case 'METADATA_CHANGEREQUEST_SAVED': {
      const savedMetadata = assertMetadata(
        state.savedMetadata,
        action,
        'saved',
      );
      const metadata = assertMetadata(state.metadata, action, 'edit');
      const newCR = action.payload;

      return {
        configuration: state.configuration,
        saving: state.saving,
        savedMetadata: replaceChangeRequest(newCR, savedMetadata),
        metadata: replaceChangeRequest(newCR, metadata),
        approvedChanges: removeById(newCR.id, state.approvedChanges),
        timestamps: incrementMetadataTimestampForId(newCR.id, state.timestamps),
        newExpressionIds: state.newExpressionIds,
        order: state.order,
        statuses: state.statuses,
        editStatuses: removeEditStatus(newCR.id, state.editStatuses),
        editModes: removeById(newCR.id, state.editModes),
        exports: state.exports,
      };
    }
    default: {
      return state;
    }
  }

  return state;
};
