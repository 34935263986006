import {clone} from 'services/utils/functions/clone';
import {Schema, Valx} from '../types';
import {decorateSchemaValues} from './decorateSchemaValues';

export const decorateSchema = (schema: Schema): Schema<Valx> => {
  const draftSchema = clone(schema);

  // Decorate all values, i.e. determine constans or ref paths
  return decorateSchemaValues(draftSchema);
};
