import {assert} from 'assert-ts';
import {PartCodeList, Valx} from 'schemaDefinition/types';
import {evaluateFieldRequired} from 'schemaDefinition/functions/evaluateFieldRequired';
import {ValidationArgs, ValidationResult} from './types';
import {fail} from './fail';
import {validResult} from './validResult';
import {validateConditions} from './validateConditions';
import {validateValueType} from './validateValueType';
import {warning} from './warning';

export const validateCodeList = (
  part: PartCodeList<Valx>,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(
    part,
    validateSingleCodeListValue,
    args,
    aggregateResult,
  );
};

const validateSingleCodeListValue = (
  part: PartCodeList<Valx>,
  args: ValidationArgs,
  aggregateResult: ValidationResult,
): ValidationResult => {
  const codelist = args.codelistMap
    ? args.codelistMap[part.codelistId]
    : undefined;
  if (!codelist) {
    assert.soft(
      false,
      `Validate codelist part: Codelist ${part.codelistId} not found`,
    );

    return fail(
      {
        ...args,
        part,
        validation: 'missing codelist',
        messageKey: 'missing.codelist',
      },
      aggregateResult,
    );
  }

  if (args.value === undefined || args.value === null) {
    const required = evaluateFieldRequired(
      part.required,
      args.valuePath,
      args.localScope,
      args.globalScope,
      args.relatedScope,
      args.value,
    );
    return required
      ? (required === true ? fail : warning)(
          {
            ...args,
            part,
            validation: 'required',
            messageKey: 'required',
          },
          aggregateResult,
        )
      : aggregateResult;
  }

  if (typeof args.value !== 'string') {
    return fail(
      {
        ...args,
        part,
        validation: 'invalid type',
        messageKey: 'invalid type',
      },
      aggregateResult,
    );
  }

  const code = codelist.codes.find(code => code.code === args.value);
  if (!code || code.disabled === true) {
    if (!code) {
      assert.soft(false, `Validate codelist part: Code not found: ${code}`, {
        codelistid: codelist.id,
        code,
      });
    }

    return fail(
      {
        ...args,
        part,
        validation: 'invalid value',
        messageKey: 'invalid.code',
      },
      aggregateResult,
    );
  }

  if (part.validation) {
    return validateConditions(part.validation, part, args, aggregateResult);
  }

  return aggregateResult;
};
