import {DateRange, RangeCondition, Valx} from 'schemaDefinition/types';
import {EvaluationArgs} from './types';
import {compareDateValues} from './compareDateValues';
import {evaluateValx} from './evaluateValx';
import {getDateMaxValue, getDateMinValue} from './getDateValue';

export const getDateRange = (
  conditions: RangeCondition<Valx> | RangeCondition<Valx>[] | undefined,
  args: EvaluationArgs,
): DateRange => {
  const rangeConditions: RangeCondition<Valx>[] = Array.isArray(conditions)
    ? conditions
    : conditions
      ? [conditions]
      : [];

  return rangeConditions.reduce<DateRange>(
    (acc, cond) => {
      const condMin = cond.min ? evaluateValx(cond.min, args) : undefined;
      const condMax = cond.max ? evaluateValx(cond.max, args) : undefined;

      const condMinVal = getDateMinValue(condMin);
      const condMaxVal = getDateMaxValue(condMax);

      return {
        minValue:
          !acc.minValue ||
          (condMinVal && compareDateValues(condMinVal, acc.minValue) > 0)
            ? condMinVal
            : acc.minValue,
        maxValue:
          !acc.maxValue ||
          (condMaxVal && compareDateValues(condMaxVal, acc.maxValue) < 0)
            ? condMaxVal
            : acc.maxValue,
      };
    },
    {
      minValue: undefined,
      maxValue: undefined,
    },
  );
};
