import {AgentSubType} from 'types';
import {AgentTypeAll} from 'services/settings';

export const getAgentTypes = (
  _selectedAgentType: AgentTypeAll,
): AgentSubType[] => [];
// AgentTypes.map(agent => ({
//   agent,
//   // , checked: agent === selectedAgentType
// }));
