import {useCallback} from 'react';
import {assert} from 'assert-ts';
import {ChangeRequest} from 'api/types';
import {HttpError, postChangeRequestClose, putTaskStatus} from 'api';
import {useGetTokens} from 'services/auth';
import {useMockContext} from 'services/utils/contexts';
import {SaveStatus} from '../types';

export const useCloseChangeRequest = () => {
  const getTokens = useGetTokens();
  const mock = useMockContext();

  return useCallback(
    (
      changeRequestId: string,
      type: ChangeRequest['taskType'],
    ): Promise<SaveStatus | void> => {
      if (type === 'METADATA_IMPORT_CHANGE') {
        return postChangeRequestClose(changeRequestId, getTokens, mock);
      }

      const taskId = parseInt(changeRequestId);
      if (isNaN(taskId) && changeRequestId.toString().length === 0) {
        assert.soft(false, 'saveChangeRequest: could not parse id', {
          id: changeRequestId,
        });
        return Promise.resolve({
          status: 'Failed',
          error: new HttpError(400, '', 'Invalid id'),
        });
      }

      return putTaskStatus(taskId, 'COMPLETED', getTokens, mock);
    },
    [getTokens, mock],
  );
};
