import union from 'lodash/union';
import {AgentName, AgentNameWithDescription, NationalAgent} from 'api/types';
import {clone, isNullish} from 'services/utils';

const excludeKeys = [
  'id',
  'created',
  'modified',
  'nameVariants',
  'nationalId',
  'isni',
  'agentType',
  'registryStatus',
  'inEbba',
];

export const mergeFromNationalRegistryAgent = (
  ebbaAgent: NationalAgent,
  nationalRegistryAgent: NationalAgent,
): NationalAgent => {
  const keys = union(
    Object.keys(ebbaAgent),
    Object.keys(nationalRegistryAgent),
  ).filter(k => !excludeKeys.includes(k)) as (keyof NationalAgent)[];

  const mergedAgent = keys.reduce<NationalAgent>((acc, key) => {
    const eValue = ebbaAgent[key];
    const nValue = nationalRegistryAgent[key];
    const value = isNullish(eValue) || eValue === '' ? nValue : eValue;
    return {...acc, [key]: value};
  }, clone(ebbaAgent));

  mergedAgent.nationalId = nationalRegistryAgent.nationalId;
  mergedAgent.isni = nationalRegistryAgent.isni;

  mergedAgent.nameVariants = [
    ...ebbaAgent.nameVariants,
    ...nationalRegistryAgent.nameVariants.filter(
      nv => !ebbaAgent.nameVariants.find(ebbaNv => areNamesEqual(ebbaNv, nv)),
    ),
  ];

  return mergedAgent;
};

const NameVariantKeys = [
  'name',
  'surName',
  'regnalNumber',
  'addition',
  'subsidiary',
  'description',
] as const;

function areNamesEqual(nv1: AgentName, nv2: AgentName): boolean {
  const nvd1 = nv1 as AgentNameWithDescription;
  const nvd2 = nv2 as AgentNameWithDescription;
  return NameVariantKeys.every(key => nvd1[key] == nvd2[key]);
}
