import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const SettingsAlternativeIcon: React.FC<IconProps> = ({...props}) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12.4399 6L12.7199 7.11L12.9399 8.01L13.7699 8.42C13.9248 8.49427 14.0751 8.57775 14.2199 8.67L14.9899 9.19L15.8799 8.93L16.9999 8.62L17.4399 9.38L16.6099 10.18L15.9399 10.82L15.9999 11.74V11.83C15.9999 11.89 15.9999 11.94 15.9999 12C15.9999 12.06 15.9999 12.11 15.9999 12.17V12.26L15.9399 13.18L16.6099 13.82L17.4399 14.62L16.9999 15.38L15.8999 15.07L14.9999 14.81L14.2299 15.33C14.0851 15.4222 13.9348 15.5057 13.7799 15.58L12.9499 15.99L12.7299 16.89L12.4399 18H11.5599L11.2799 16.89L11.0599 15.99L10.2299 15.58C10.0751 15.5057 9.92482 15.4222 9.77995 15.33L8.99995 14.81L8.10995 15.07L6.99995 15.38L6.55995 14.62L7.38995 13.82L8.05995 13.18L7.99995 12.26V12.17C7.99995 12.11 7.99995 12.06 7.99995 12C7.99995 11.94 7.99995 11.89 7.99995 11.83V11.74L8.05995 10.82L7.38995 10.18L6.55995 9.38L6.99995 8.62L8.09995 8.93L8.99995 9.19L9.76995 8.67C9.91482 8.57775 10.0651 8.49427 10.2199 8.42L11.0599 8L11.2799 7.1L11.5599 6H12.4399ZM13.9999 4H9.99995L9.33995 6.63C9.10831 6.73765 8.88443 6.86129 8.66995 7L6.06995 6.26L4.06995 9.72L5.99995 11.62C5.99995 11.74 5.99995 11.87 5.99995 12C5.99995 12.13 5.99995 12.26 5.99995 12.38L4.06995 14.27L6.06995 17.73L8.66995 17C8.88414 17.1421 9.10802 17.2691 9.33995 17.38L9.99995 20H13.9999L14.6599 17.37C14.8919 17.2591 15.1158 17.1321 15.3299 16.99L17.9299 17.73L19.9299 14.27L17.9999 12.38C17.9999 12.26 17.9999 12.13 17.9999 12C17.9999 11.87 17.9999 11.74 17.9999 11.62L19.9499 9.73L17.9499 6.27L15.3299 7C15.1158 6.85786 14.8919 6.73088 14.6599 6.62L13.9999 4Z" />
      <path
        d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
        stroke={'currentColor'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
};
