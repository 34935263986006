import {assert} from 'assert-ts';
import {ThesaurusValue} from 'schemaDefinition/types';

export const assertAsThesaurusValue = (
  value: unknown,
  context?: string,
): ThesaurusValue | undefined => {
  if (value === undefined || value === null) return undefined;

  if (Array.isArray(value)) {
    return value as ThesaurusValue;
  }

  assert.soft(
    false,
    `assertAsThesaurusValue: thesaurus value expected (${context ?? ''})`,
    {value},
  );

  return undefined;
};
