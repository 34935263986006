import React from 'react';
import {JSONValue} from 'types';
import {Layout} from 'components/layout';

type Props = {
  json: JSONValue;
};

export const PrettyPrintJson: React.FC<Props> = ({json}) => {
  return (
    <Layout>
      <pre style={{margin: 0}}>{JSON.stringify(json, null, 2)}</pre>
    </Layout>
  );
};
