import React, {useMemo} from 'react';
import {DialogActionItem} from 'components/dialog/types';
import {useLocalization} from 'localization';
import {noOp} from 'services/utils';
import {Card, Layout, Spacer} from 'components';
import {DialogItems} from 'components/dialog/DialogItems';
import {DataFormV1} from 'schema/form';
import {useLinkedValueSearchContextProvider} from 'sceneExtensions';
import {EditAgentProps} from '../types';
import {useEditAgentState} from '../hooks/useEditAgentState';

export const EditAgent: React.FC<EditAgentProps> = props => {
  const {t} = useLocalization();
  const LinkedValueSearchContext = useLinkedValueSearchContextProvider();

  const {schema, initialEditAgent, hasChanges, showErrors, onChanged, save} =
    useEditAgentState(props);

  const actions = useMemo(() => {
    const cancelAction: DialogActionItem = {
      itemType: 'action',
      title: t('general.cancel'),
      background: 'transparent',
      hide: !hasChanges,
      onClick: props.onCancel ?? noOp,
    };

    const saveAction: DialogActionItem = {
      itemType: 'action',
      title: t('general.save'),
      background: 'green',
      hide: !hasChanges,
      icon: 'Check',
      showSpinnerOnClick: true,
      onClick: save,
    };
    return [cancelAction, saveAction];
  }, [props.onCancel, hasChanges, save, t]);

  return (
    <Layout>
      <DialogItems dialogItems={actions} />
      <Spacer size={2} />
      <Card>
        <Card.Content>
          <LinkedValueSearchContext>
            <DataFormV1
              key={'edit-agent'}
              id={'edit-agent'}
              schema={schema}
              data={initialEditAgent}
              relatedData={{}}
              mode={'edit'}
              showErrors={showErrors}
              onDataChanged={onChanged}
            />
          </LinkedValueSearchContext>
        </Card.Content>
      </Card>
    </Layout>
  );
};
