import {useCallback, useMemo, useState} from 'react';
import {DialogActionItem, DialogCustomItem} from 'components/dialog/types';
import {noOp} from 'services/utils';
import {Dialog, DialogProps} from '../dialog';
import {Layout} from '../layout';
import {WizardHeader} from './components/WizardHeader';

type WizardStepProps = {
  id: string;
  label: string;
  content: React.ReactElement;
  extraitem?: DialogCustomItem;
};

type WizardDialogProps = {
  isOpen: DialogProps['isOpen'];
  title: string;
  initialStepId?: string;
  steps: WizardStepProps[];
  onGotoStep?: (stepId: string) => void;
  onClose: DialogProps['onClose'];
  onSave: () => Promise<void> | void;
};

const getStepIndex = (
  stepId: string | undefined,
  steps: WizardStepProps[],
): number | undefined => {
  const index = stepId ? steps.findIndex(s => s.id === stepId) : -1;
  return index >= 0 ? index : undefined;
};

export const WizardDialog: React.FC<WizardDialogProps> = ({
  isOpen,
  title,
  initialStepId,
  steps,
  onGotoStep,
  onClose,
  onSave,
}) => {
  const [activeStep, setActiveStep] = useState(
    getStepIndex(initialStepId, steps) ?? 0,
  );

  const handleStepSelected = useCallback(
    (stepIdx: number) => {
      onGotoStep?.(steps[stepIdx].id);
      setActiveStep(stepIdx);
    },
    [onGotoStep, steps],
  );

  const actions = useMemo(() => {
    const cancelAction: DialogActionItem = {
      itemType: 'action',
      title: 'Avbryt',
      background: 'transparent',
      onClick: onClose ?? noOp,
    };
    const saveAction: DialogActionItem | undefined =
      activeStep === steps.length - 1
        ? {
            itemType: 'action',
            title: 'Lagre',
            background: 'green',
            icon: 'Check',
            showSpinnerOnClick: true,
            onClick: onSave,
          }
        : undefined;
    const prevAction: DialogActionItem | undefined =
      activeStep > 0
        ? {
            itemType: 'action',
            title: steps[activeStep - 1].label,
            icon: 'Back',
            background: 'transparent',
            alignLeft: true,
            onClick: () => handleStepSelected(activeStep - 1),
          }
        : undefined;

    const nextAction: DialogActionItem | undefined =
      activeStep < steps.length - 1
        ? {
            itemType: 'action',
            title: steps[activeStep + 1].label,
            icon: 'ArrowRight',
            background: 'transparent',
            onClick: () => handleStepSelected(activeStep + 1),
          }
        : undefined;

    return [
      prevAction,
      steps[activeStep].extraitem,
      cancelAction,
      saveAction,
      nextAction,
    ].filter(a => !!a) as DialogActionItem[];
  }, [activeStep, handleStepSelected, onClose, onSave, steps]);

  return (
    <Dialog
      isOpen={isOpen}
      title={
        <WizardHeader
          title={title}
          showStepNumbers={false}
          activeStep={activeStep}
          onStepSelected={handleStepSelected}
          steps={steps}
        />
      }
      onClose={onClose}
      body={<Layout>{steps[activeStep].content}</Layout>}
      dialogItems={actions}
    />
  );
};
