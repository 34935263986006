import {AgentTypes, DataValue, LinkedAgentLink} from '../types';
import {isLinkedValueLink} from './isLinkedValueLink';

export const isLinkedAgentLink = (
  value: DataValue | undefined,
): value is LinkedAgentLink => {
  return (
    isLinkedValueLink(value) &&
    (value.linkStatus !== 'verified' || AgentTypes.includes(value.type ?? ''))
  );
};
