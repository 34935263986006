import React, {useCallback, useId, useMemo} from 'react';
import {SxProps} from '@mui/material';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {NationalAgentType} from 'api';
import {ActionButton, FlexBox, Layout, Spacer, TextField} from 'components';
import {AgentQuery} from '../types';

type Props = {
  agentType: NationalAgentType;
  value: AgentQuery;
  onSetValue: (value: AgentQuery) => void;
  onSearch: (value: AgentQuery) => Promise<void>;
};

const searchInputContainerSx: SxProps = {
  padding: 2,
  borderRadius: 2,
  backgroundColor: ColorPalette.lightBeige,
};

export const SearchInput: React.FC<Props> = ({
  agentType,
  value: {name, other},
  onSetValue,
  onSearch,
}) => {
  const formId = useId();
  const {t} = useLocalization();
  const strings = useMemo(
    () => ({
      name: {
        label: t('form.global.field.name.label'),
        placeholder: t(
          `wizard.agent.step.1.${agentType}.form.field.name.placeholder`,
        ),
      },
      other: {
        label: t(`wizard.agent.step.1.${agentType}.form.field.other.label`),
        placeholder: t(
          `wizard.agent.step.1.${agentType}.form.field.other.placeholder`,
        ),
      },
      searchTitle: t('general.search'),
    }),
    [agentType, t],
  );

  const handleSetName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {value} = event.target;
      onSetValue({name: value, other});
    },
    [onSetValue, other],
  );

  const handleSetOther = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {value} = event.target;
      onSetValue({name, other: value});
    },
    [name, onSetValue],
  );

  const handleSearch = useCallback(() => {
    return onSearch({name, other});
  }, [name, onSearch, other]);

  return (
    // Search input and search button
    <Layout horizontal adjustBottom sx={searchInputContainerSx}>
      <Layout flex={7}>
        <form id={formId}>
          <FlexBox horizontal left>
            <TextField
              type="text"
              name="name"
              label={strings.name.label}
              placeholder={strings.name.placeholder}
              value={name}
              onChange={handleSetName}
              flex={4}
              maxWidth="400px"
            />
            <Spacer size={2} />
            <TextField
              type="text"
              name="other"
              label={strings.other.label}
              placeholder={strings.other.placeholder}
              value={other}
              flex={3}
              maxWidth="300px"
              onChange={handleSetOther}
            />
          </FlexBox>
        </form>
      </Layout>
      <Spacer size={2} />
      <Layout flex={1} maxWidth={'6rem'}>
        <ActionButton
          title={strings.searchTitle}
          icon="Search"
          type="submit"
          formId={formId}
          showSpinnerOnClick
          onClick={handleSearch}
        />
      </Layout>
    </Layout>
  );
};
