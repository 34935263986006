import assert from 'assert-ts';
import {RootKey} from '../types';

export const getChangeRequestRootAndLocalPath = (
  rootPath: string,
): {root: RootKey; localPath: string} => {
  const validRoots: RootKey[] = ['work', 'expression', 'manifestation'];
  const [root, ...localParts] = rootPath.split('.');
  assert(validRoots.includes(root as RootKey));

  const localPath = localParts.join('.');

  return {root: root as RootKey, localPath};
};
