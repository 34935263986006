import {GetTokens, WorkMetadata, WorkV4, WorkWithExpressions} from './types';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpGet, httpPost, httpPut} from './http/ebba';
import {
  mapToWorkBaseDto,
  mapWorkBaseDto,
  mapWorkFlattenedDto,
  mapWorkMetadataToWorkWithExpressions,
} from './mappers';

export const getWork = (
  id: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<WorkMetadata> => {
  return exceptionToPromiseReject(() =>
    httpGet<Schemas.WorkFlattenedDto>({
      subdir: 'work/v2/{id}',
      subdirParams: {id},
      mock: !!mock,
      getTokens,
    }).then(mapWorkFlattenedDto),
  );
};

export const getWorkByEan = (
  ean: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<WorkMetadata> => {
  return exceptionToPromiseReject(() =>
    httpGet<Schemas.WorkFlattenedDto>({
      subdir: 'work/v2/ean/{ean}',
      subdirParams: {ean},
      mock: !!mock,
      getTokens,
    }).then(mapWorkFlattenedDto),
  );
};

export const getWorks = (
  ids: string[],
  getTokens: GetTokens,
  mock?: boolean,
): Promise<WorkMetadata[]> => {
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.WorkFlattenedDtos, {ids: string[]}>({
      subdir: 'work/v2/id',
      body: {ids},
      mock,
      getTokens,
    }).then(({works: worksFlattended}) =>
      (worksFlattended ?? []).map(mapWorkFlattenedDto),
    ),
  );
};

export const putWork = (
  work: WorkV4,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<WorkV4> => {
  return exceptionToPromiseReject(() =>
    httpPut<Schemas.WorkBaseDto>({
      subdir: 'work/{id}',
      subdirParams: {id: work.id},
      mock: !!mock,
      body: mapToWorkBaseDto(work),
      getTokens,
    }).then(mapWorkBaseDto),
  );
};

export const getWorkWithExpressions = (
  id: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<WorkWithExpressions> => {
  return getWork(id, getTokens, mock).then(
    mapWorkMetadataToWorkWithExpressions,
  );
};
