import {assert} from 'assert-ts';
import {LinkAction} from 'types';
import {LinkedValueSearchFieldConfig} from 'feature/linkedValueSearch/types';
import {FieldExtensionEnum} from 'sceneExtensions/types';
import {LinkedValueLink, VerifiedLinkedValueLink} from 'schemaDefinition/types';
import {useSceneExtensionContext} from 'sceneExtensions/contexts';

export const useLinkedValueSearchContext = () => {
  const extension = useSceneExtensionContext<
    FieldExtensionEnum.linkedValueSearch,
    LinkedValueLink,
    VerifiedLinkedValueLink,
    LinkAction,
    LinkedValueSearchFieldConfig
  >(FieldExtensionEnum.linkedValueSearch);
  return assert(extension).fieldContext;
};
