import {useCallback} from 'react';
import {useStore} from 'react-redux';
import {UseSetApprovedValue} from 'schema/form/types';
import {ChangeRequestAction, DataValue} from 'schemaDefinition/types';
import {AppState} from 'store/types';
import {MetadataEditAction} from '../metadataEditActionTypes';

export const useChangeRequestSetApprovedValue: UseSetApprovedValue = (
  id: string,
) => {
  const {dispatch} = useStore<AppState, MetadataEditAction>();

  return useCallback(
    (
      rootValuePath: string,
      itemAction: ChangeRequestAction,
      newValue?: DataValue,
      statusPath?: string,
    ) => {
      const action: MetadataEditAction = {
        type: 'METADATAEDIT_APPROVE_CHANGEREQUESTITEM',
        payload: {
          changeRequestId: id,
          rootValuePath: rootValuePath,
          itemAction: itemAction,
          newValue: newValue,
          statusPath: statusPath,
        },
      };

      dispatch(action);
    },
    [dispatch, id],
  );
};
