import {useMemo} from 'react';
import {Indexer} from 'types';
import {useRawQueryParams} from './useRawQueryParams';

export const useQueryParams = <T extends Indexer<string>>(): T => {
  const rawParams = useRawQueryParams();
  return useMemo(() => {
    const params = Array.from(rawParams.entries()).reduce((acc, p) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      acc[p[0]] = p[1];
      return acc;
    }, {} as T);
    return params;
  }, [rawParams]);
};
