import React, {useCallback, useRef, useState} from 'react';
import {assert} from 'assert-ts';
import {DataFormConfiguration} from 'schema/types';
import {Data, Schema} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {CodeListsGate, useCodelists} from 'services/codeLists';
import {Dialog, Layout} from 'components';
import {useSchemaCodelistIds} from 'schema/hooks';
import {validate} from '../functions/validators/validate';
import {DataFormV1} from './DataFormV1';

type Props<TData extends Data> = {
  isOpen: boolean;
  title: string;
  /**
   * Original value: when new instance, this may just be an empty object,
   * otherwise it will contain an existing instance
   * If isOpen, then originalData must be set
   **/
  originalData?: TData | undefined;
  /**
   * Schema of data to be edited
   * If isOpen, then schema must be set
   */
  schema?: Schema;
  /**
   * configuration of DataForm
   */
  configuration?: Partial<DataFormConfiguration<TData>>;
  /**
   * Will be called when the user saves the data and validations pass
   */
  onSave: (data: TData) => Promise<void>;
  onCancel: () => void;
};

/**
 * Handled editing of new or existing instance according to given schema.
 * On save will only be called if the data is valid according to the schema.
 */
export function DataFormDialogV1<TData extends Data>({
  isOpen,
  title,
  originalData,
  schema,
  configuration,
  onSave,
  onCancel,
}: Props<TData>) {
  const {t} = useLocalization();
  const codelists = useSchemaCodelistIds(schema);
  const codelistMap = useCodelists(codelists);
  const editDataRef = useRef<TData>();
  const [hasChanged, setHasChanged] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const errorMessage = showErrors
    ? t('form.agent.validationError.label')
    : undefined;

  const handleDataChanged = useCallback((data: TData) => {
    setHasChanged(true);
    editDataRef.current = data;
  }, []);

  const handleSave = useCallback(() => {
    // Don't use ref.current directly to avoid old value in closure
    const edit = editDataRef;
    if (!assert.soft(edit.current, 'handleSave')) {
      return Promise.resolve();
    }

    const validation = validate(
      assert(edit.current, 'handleSave: data expected'),
      undefined,
      assert(schema, 'handleSave: schema expected'),
      codelistMap,
    );

    if (validation.valid !== 'valid') {
      setShowErrors(true);
      return Promise.reject();
    }

    return onSave(edit.current);
  }, [codelistMap, onSave, schema]);

  return (
    <CodeListsGate codeLists={codelists}>
      <Dialog
        isOpen={isOpen}
        title={title}
        body={
          <Layout width={'52em'}>
            {originalData && schema ? (
              <DataFormV1<TData>
                key={'edit-agent'}
                id={'edit-agent'}
                schema={schema}
                data={originalData}
                configuration={configuration}
                relatedData={{}}
                mode={'edit'}
                showErrors={showErrors}
                onDataChanged={handleDataChanged}
              />
            ) : null}
          </Layout>
        }
        dividers={true}
        errorMessage={errorMessage}
        dialogItems={[
          {
            itemType: 'action',
            icon: 'CloseSmall',
            title: t('general.cancel'),
            type: 'reset',
            onClick: onCancel,
          },
          {
            itemType: 'action',
            icon: 'Check',
            title: t('general.ok'),
            disabled: !hasChanged,
            showSpinnerOnClick: true,
            onClick: handleSave,
          },
        ]}
      />
    </CodeListsGate>
  );
}
