import {AuthDefaultState, AuthState} from './types';
import {AuthAction} from './actions';

export const authReducer = (
  state: AuthState = AuthDefaultState,
  action: AuthAction,
): AuthState => {
  switch (action.type) {
    case 'LOGIN': {
      return {
        ...state,
        ...action.payload,
        status: 'Authenticated',
      };
    }
    case 'LOGIN_FAILED': {
      return {status: 'Failed'};
    }
    case 'LOGOUT': {
      return {status: 'Anonymous'};
    }
  }

  return state;
};
