import {useCallback} from 'react';
import {Media} from 'api/types';
import {useSnacks} from 'components';
import {getMediaUrl} from '../../../../api';
import {Configuration} from '../../../../configuration';
import {useGetTokens} from '../../../auth';

export const useDownloadFile = (
  file: Media,
  mock?: boolean,
): {
  download: () => Promise<void>;
} => {
  const getTokens = useGetTokens();
  const {errorSnack} = useSnacks();

  const download = useCallback(() => {
    return getMediaUrl(file.id, getTokens, mock)
      .then(url => {
        return fetch(url, {
          method: 'GET',
          referrer: Configuration.app.domain,
          credentials: 'include',
        });
      })
      .then(response => {
        if (response.ok) {
          return response.blob();
        } else {
          throw new Error('Empty blob');
        }
      })
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${file.id}.${file.extension}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(e => {
        errorSnack('Download failed: ' + e);
      });
  }, [file.id, file.extension, getTokens, mock, errorSnack]);

  return {
    download,
  };
};
