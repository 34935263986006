import React from 'react';
import {FieldLabel} from 'components';

type Props = {
  label: string;
};

export const NodeLabel: React.FC<Props> = ({label}) => {
  return <FieldLabel label={label} />;
};
