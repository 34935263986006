import {assert} from 'assert-ts';
import {DataValue, LinkedLiterary} from 'schemaDefinition/types';
import {isLinkedLiterary} from './isLinkedLiterary';

export const assertAsLinkedLiterary = (
  value: DataValue | null,
  context?: string,
): LinkedLiterary | undefined => {
  if (isLinkedLiterary(value)) {
    return value;
  }

  assert.soft(
    isLinkedLiterary(value),
    `assertAsLinkedLiterary: linked literary expected (${context ?? ''})`,
    {value},
  );

  return undefined;
};
