import {
  LinkEntityType,
  LinkTargetMainEntityType,
  LinkTargetSubEntityType,
} from 'types';
import {getMainType} from './getMainType';
import {isLinkTargetMainEntityType} from './isLinkTargetMainEntityType';

export const getLinkTargetMainAndSubTypes = (
  type: LinkEntityType | undefined,
): [
  LinkTargetMainEntityType | undefined,
  LinkTargetSubEntityType[] | undefined,
] => {
  if (!type) {
    return [undefined, undefined];
  }

  if (isLinkTargetMainEntityType(type)) {
    return [type, undefined];
  }

  return [
    getMainType(type) as LinkTargetMainEntityType,
    [type as LinkTargetSubEntityType],
  ];
};
