import {Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import {Layout} from 'components/layout';
import {Spacer} from 'components/spacer';
import {Text} from 'components/text';
import {SnackBodyWithMessageAndNavLinkProps} from '../types';

export const SnackBodyWithMessageAndNavLink: React.FC<
  SnackBodyWithMessageAndNavLinkProps
> = ({message, linkLabel, navigationLink, closeSnack}) => (
  <Layout horizontal adjustCenter>
    <Text variant="body2">{message}</Text>
    <Spacer size={2} />
    <Link
      component={RouterLink}
      variant="body2"
      to={navigationLink}
      onClick={closeSnack}>
      {linkLabel}
    </Link>
  </Layout>
);
