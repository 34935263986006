import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {DateTimeValue} from './types';

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const formatRelativeTime = (
  relativeToDateTime: DateTimeValue,
): string => {
  const relativeTo = dayjs(relativeToDateTime);
  const now = dayjs();

  return now.isAfter(relativeTo) ? now.to(relativeTo) : relativeTo.from(now);
};
