import {assert} from 'assert-ts';
import {ExpressionV4} from 'api/types';
import {Metadata} from 'services/data/metadata/types';
import {MetadataEditAction} from 'services/data/metadata/metadataEditActionTypes';

export const assertExpression = (
  expressionId: string,
  metadata: Metadata | undefined,
  action?: MetadataEditAction,
  qualifer?: 'saved' | 'edit',
): ExpressionV4 => {
  const expression = metadata?.expressions.find(e => e.id === expressionId);

  if (!expression) {
    assert(
      false,
      qualifer
        ? `metadataEditReducer.assertExpression (${qualifer}): expression not found`
        : 'metadataEditReducer.assertExpression: expression not found',
      action,
    );
  }

  return expression;
};
