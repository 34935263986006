import {CodeListMap, ManifestationStatus} from 'api/types';
import {ValidationResult} from 'schema/form/functions/validators/types';
import {Metadata, MetadataSchemas, MetadataStatuses} from '../types';
import {validateEntity} from './validateEntity';

export const validateWork = (
  savedMetadata: Pick<Metadata, 'work'>,
  metadata: Pick<Metadata, 'work'>,
  schemas: MetadataSchemas,
  codelists: CodeListMap,
  statuses: MetadataStatuses,
): {validation: ValidationResult; status: ManifestationStatus} => {
  // Validate work
  const {work: savedWork} = savedMetadata;
  const {work} = metadata;

  const status = statuses[work.id];

  return validateEntity(
    savedWork,
    work,
    {},
    work.type,
    status,
    schemas.work.schema,
    schemas.work.getDataBasedModifier,
    schemas.work.modifiers,
    codelists,
  );
};
