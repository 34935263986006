import React, {useCallback, useMemo} from 'react';
import {Paper, PaperProps, Popper, PopperProps} from '@mui/material';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import {Code} from 'schemaDefinition/types';
import {ColorPalette} from 'theme';
import {CodeListFieldProps} from './types';
import {Layout} from '../../layout';
import {Text} from '../../text';
import {FieldLabel} from '../FieldLabel';
import {NullFieldPlaceholder} from '../NullFieldPlaceholder';
import {MuiTextFieldStyled} from '../muiStyled';
import './CodeListField.scss';
import {getCodeLabel, getSelectedOptions} from './functions';
import {useClassName} from './hooks';

export type CodeListFieldRawProps = Omit<
  CodeListFieldProps,
  'label' | 'codeListName' | 'cardinality'
> &
  Required<Pick<CodeListFieldProps, 'cardinality'>> & {
    selectedCodes: Code | Code[] | null;
  };

export const CodeListFieldRaw: React.FC<CodeListFieldRawProps> = ({
  name,
  id,
  placeholder,
  value,
  showCode,
  cardinality,
  readonly,
  error = false,
  options,
  fullWidth,
  renderOptionContent,
  selectedCodes,
  onChange,
  onBlur,
}) => {
  const handleChange = useCallback(
    (_: unknown, option: Code | Code[] | null) => {
      if (onChange) {
        option === null
          ? onChange(null)
          : Array.isArray(option)
          ? onChange(option.map(o => o.code))
          : onChange(option?.code);
      }
    },
    [onChange],
  );

  const getOptionLabel = useCallback(
    (option: Code) => getCodeLabel(option, showCode),
    [showCode],
  );

  const isOptionEqualToValue = useCallback((option: Code, value: Code) => {
    return option.code === value.code;
  }, []);

  const getOptionDisabled = useCallback(
    (option: Code) => !!(option.disabled || option.deactivated),
    [],
  );

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: Code) => {
      return (
        <Box
          component="li"
          {...props}
          key={option.code}
          data-cy={`codelist-option-${option.code}`}>
          {renderOptionContent ? (
            renderOptionContent(option)
          ) : (
            <Text variant="body2">{getCodeLabel(option, showCode)}</Text>
          )}
        </Box>
      );
    },
    [renderOptionContent, showCode],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      return cardinality === 'single' ? (
        <div className="sizer">
          <MuiTextFieldStyled
            value={''}
            type={'text'}
            {...params}
            name={name}
            placeholder={placeholder}
            readonly={readonly}
            // helperText={"helperText"}
            error={error !== false}
            inputProps={{
              ...params.inputProps,
              id,
              autoComplete: 'off', // disable autocomplete and autofill
            }}
            onBlur={onBlur}
          />
          <div className="ghost">
            {/* {options.find(o => o.code === value)?.value ?? ''} */}
            {params.inputProps.value || placeholder || ''}
          </div>
        </div>
      ) : (
        <MuiTextFieldStyled
          {...params}
          name={name}
          readonly={readonly}
          // helperText={"helperText"}
          // TODO: needs styling
          // error={error}
          inputProps={{
            ...params.inputProps,
            id,
            style:
              error === true
                ? {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    'input::placeholder': {
                      color: ColorPalette.error20,
                    },
                  }
                : undefined,
            placeholder:
              Array.isArray(value) && value.length > 0
                ? undefined
                : placeholder,
            autoComplete: 'off', // disable autocomplete and autofill
          }}
          onBlur={onBlur}
        />
      );
    },
    [cardinality, id, name, placeholder, readonly, error, onBlur, value],
  );

  const PopperComponent = useCallback(
    (props: PopperProps) => (
      <Popper
        {...props}
        style={{
          ...props.style,
          // Offset popper vertically from input field, not to overlap borders
          borderBottom: '4px solid transparent',
          borderTop: '8px solid transparent',
        }}
      />
    ),
    [],
  );

  const PaperComponent = useCallback(
    (props: PaperProps) => (
      <Paper
        {...props}
        style={{
          ...props.style,
          // Outline to hide borders of other fields underneath popper
          outline: `2px solid ${ColorPalette.primary.warmGreen}`,
        }}
      />
    ),
    [],
  );

  const className = useClassName(cardinality, fullWidth, selectedCodes, error);

  const chipPlacedholderSx = useMemo(() => {
    return error === true
      ? {
          background: ColorPalette.error20,
          border: `1.25px solid ${ColorPalette.error}`,
          '&:hover': {
            border: `2px solid ${ColorPalette.error}`,
          },
        }
      : error === 'warning'
      ? {
          background: ColorPalette.warning40,
          border: `1.25px solid ${ColorPalette.warning}`,
          '&:hover': {
            border: `2px solid ${ColorPalette.warning}`,
          },
        }
      : {
          background: ColorPalette.beige,
        };
  }, [error]);

  return cardinality === 'single' ? (
    <Autocomplete
      className={className}
      id={name}
      options={options}
      multiple={false}
      disabled={readonly}
      value={selectedCodes as Code}
      clearIcon={null}
      clearOnBlur
      forcePopupIcon={false}
      onChange={handleChange}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      isOptionEqualToValue={isOptionEqualToValue}
      size="small"
      renderOption={renderOption}
      renderInput={renderInput}
      PopperComponent={PopperComponent}
      PaperComponent={PaperComponent}
    />
  ) : (
    <Layout position="relative">
      {/* Add Layout element as background to placeholder when null/empty */}
      {selectedCodes === null || (selectedCodes as Code[]).length === 0 ? (
        <Layout
          position="absolute"
          top="0"
          left="0"
          height="34px"
          minWidth="4em"
          borderRadius={1}
          sx={chipPlacedholderSx}>
          <Layout px={1} hidden>
            <Text variant="body1">{placeholder}</Text>
          </Layout>
        </Layout>
      ) : null}
      <Autocomplete
        className={className}
        options={options}
        multiple={true}
        disabled={readonly}
        readOnly={readonly}
        value={selectedCodes as Code[]}
        clearIcon={null}
        clearOnBlur
        forcePopupIcon={false}
        onChange={handleChange}
        getOptionLabel={getOptionLabel}
        getOptionDisabled={getOptionDisabled}
        isOptionEqualToValue={isOptionEqualToValue}
        size="small"
        renderOption={renderOption}
        renderInput={renderInput}
        PopperComponent={PopperComponent}
        PaperComponent={PaperComponent}
      />
    </Layout>
  );
};

export const CodeListField: React.FC<CodeListFieldProps> = ({
  name,
  id,
  value,
  cardinality = 'single',
  showCode,
  readonly = false,
  showWhenReadonlyAndEmpty = false,
  error,
  required = false,
  label,
  placeholder,
  options,
  fullWidth,
  width,
  maxWidth,
  flex,
  alignItems,
  renderOptionContent,
  onChange,
  onBlur,
}) => {
  const selectedCodes = useMemo(
    () => getSelectedOptions(value, cardinality, options),
    [value, cardinality, options],
  );

  const showNull =
    readonly &&
    !showWhenReadonlyAndEmpty &&
    (value === undefined ||
      value === null ||
      (Array.isArray(value) && value.length === 0));

  return cardinality === 'single' ? (
    <Layout
      width={width}
      maxWidth={maxWidth}
      flex={flex}
      alignItems={alignItems}>
      {label && (
        <FieldLabel
          label={label}
          required={required}
          error={error}
          htmlFor={id}
        />
      )}
      {showNull ? (
        <NullFieldPlaceholder />
      ) : (
        <CodeListFieldRaw
          name={name}
          id={id}
          value={value}
          cardinality={cardinality}
          readonly={readonly}
          showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
          showCode={showCode}
          error={error}
          placeholder={placeholder}
          options={options}
          fullWidth={fullWidth}
          renderOptionContent={renderOptionContent}
          selectedCodes={selectedCodes}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
    </Layout>
  ) : (
    // Trick to keep height stable on focus/blur: Since blurred selected items
    // are a bit larger than focused onces, keep hidden blurred duplicate  of
    // selected items to determine size
    <Layout
      width={width}
      maxWidth={maxWidth}
      flex={flex}
      alignItems={alignItems}>
      {label && (
        <FieldLabel
          label={label}
          required={required}
          error={error}
          htmlFor={id}
        />
      )}
      {showNull ? (
        <NullFieldPlaceholder />
      ) : (
        <Layout horizontal flex={1} sx={{position: 'relative'}}>
          <Layout
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
            }}>
            <CodeListFieldRaw
              name={name}
              id={id}
              value={value}
              cardinality={cardinality}
              placeholder={placeholder}
              readonly={readonly}
              showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
              showCode={showCode}
              required={required}
              error={error}
              options={options}
              fullWidth={fullWidth}
              renderOptionContent={renderOptionContent}
              selectedCodes={selectedCodes}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Layout>
          {/* Hidden duplicate which never gets focus */}
          <Layout flex={1} sx={{visibility: 'hidden'}}>
            <CodeListFieldRaw
              name={name}
              id={`${id}-sizer`}
              value={value}
              cardinality={cardinality}
              placeholder={placeholder}
              readonly={readonly}
              showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
              showCode={showCode}
              required={required}
              options={options}
              fullWidth={fullWidth}
              renderOptionContent={renderOptionContent}
              selectedCodes={selectedCodes}
            />
          </Layout>
        </Layout>
      )}
    </Layout>
  );
};
