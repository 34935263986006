import {ExpressionV4, ManifestationV4, WorkV4} from 'api/types';
import {MetadataValidationResult} from 'schemas/types';
import {MetadataEditState} from '../types';
import {validateMetadata} from './validateMetadata';

export const validateNewExpressionWithManifestation = ({
  savedWork,
  work,
  expression,
  savedManifestation,
  manifestation,
  configuration,
  statuses,
}: {
  savedWork: WorkV4;
  work: WorkV4;
  expression: ExpressionV4;
  savedManifestation: ManifestationV4;
  manifestation: ManifestationV4;
} & Pick<
  MetadataEditState,
  'configuration' | 'statuses'
>): MetadataValidationResult => {
  return validateMetadata(
    {
      savedMetadata: {
        work: savedWork,
        expressions: [expression],
        manifestations: [savedManifestation],
        changeRequests: [],
      },
      metadata: {
        work,
        expressions: [expression],
        manifestations: [manifestation],
        changeRequests: [],
      },
      configuration,
      statuses,
    },
    {
      workId: work.id,
      expressions: [
        {
          expressionId: expression.id,
          manifestationIds: [manifestation.id],
        },
      ],
    },
  );
};
