import {SxProps} from '@mui/material';
import {Data} from 'schemaDefinition/types';
import {CodeListsGate} from 'services/codeLists';
import {CheckboxField, Layout, Spacer, Text} from 'components';
import {useUniqueFieldId} from 'components/fields/hooks/useUniqueFieldId';
import {WizardDialog} from 'components/wizard';
import {DataFormV1} from 'schema/form';
import {useLinkedValueSearchContextProvider} from 'sceneExtensions';
import {EditAgentWizardDialogProps} from '../types';
import {useAgentFormConfiguration} from '../hooks/useAgentFormConfiguration';
import {useEditAgentWizardState} from '../hooks/useEditAgentWizardState';
import {useWizardLabels} from '../hooks/useWizardLabels';
import {SearchAgentResult} from './SearchAgentResult';
import {SearchInput} from './SearchInput';

const contentSx: SxProps = {
  width: '66vw',
  maxWidth: '1100px',
};

const empty: Data = {};

export const EditAgentWizardDialog: React.FC<
  EditAgentWizardDialogProps
> = props => {
  const {isOpen, onCancel} = props;
  const {
    step1Id,
    step2Id,
    currentStep,
    agentType,
    agentSchema,
    codelistIds,
    mode,
    hasNationalId,
    upsertBARE,
    disabledUpsertBARE,
    setUpsertBARE,
    agentQuery,
    search,
    isAutoSearching,
    selection,
    emptyResultAlternative,
    setEmptyResultAlternative,
    initialEditAgent,
    showValidationErrors,
    setAgentQuery,
    gotoStep,
    agentEdited,
    onSave,
  } = useEditAgentWizardState(props, 'dialog');

  const {title, steps, upsertLabel} = useWizardLabels(
    agentType,
    mode,
    hasNationalId,
  );

  const agentFormConfiguration = useAgentFormConfiguration();

  const LinkedValueSearchContext = useLinkedValueSearchContextProvider();

  const checkBoxFieldId = useUniqueFieldId('upsertBARE');

  return (
    <CodeListsGate codeLists={codelistIds}>
      <WizardDialog
        isOpen={isOpen}
        initialStepId={currentStep}
        title={title}
        onClose={onCancel}
        onSave={onSave}
        onGotoStep={gotoStep}
        steps={[
          {
            id: step1Id,
            label: steps[0].label,
            content: (
              <Layout sx={contentSx}>
                <Text variant="body2">{steps[0].guide}</Text>
                <Spacer size={1} />
                {/* Search input and search button */}
                <SearchInput
                  agentType={agentType}
                  value={agentQuery}
                  onSetValue={setAgentQuery}
                  onSearch={search.doSearch}
                />
                <Spacer size={1} />
                {/* Search result */}
                <SearchAgentResult
                  agentType={agentType}
                  mode={mode}
                  isSearching={isAutoSearching}
                  showSearchErrors={search.showSearchErrors}
                  searchResult={search.searchResult}
                  {...selection}
                  emptyResultAlternative={emptyResultAlternative}
                  setEmptyResultAlternative={setEmptyResultAlternative}
                />
              </Layout>
            ),
          },
          {
            id: step2Id,
            label: steps[1].label,
            content: (
              <Layout sx={contentSx}>
                <LinkedValueSearchContext>
                  <DataFormV1
                    id={step2Id}
                    schema={agentSchema}
                    data={initialEditAgent}
                    configuration={agentFormConfiguration}
                    mode="edit"
                    relatedData={empty}
                    showErrors={showValidationErrors}
                    onDataChanged={agentEdited}
                  />
                </LinkedValueSearchContext>
              </Layout>
            ),
            extraitem: {
              itemType: 'custom',
              render: () => (
                <CheckboxField
                  fieldId={checkBoxFieldId}
                  name="upsertBARE"
                  boxLabel={upsertLabel}
                  value={upsertBARE}
                  variant="button"
                  readonly={disabledUpsertBARE}
                  onChange={setUpsertBARE}
                />
              ),
            },
          },
        ]}
      />
    </CodeListsGate>
  );
};
