import {assert} from 'assert-ts';
import {
  Data,
  DataValue,
  Part,
  SeparatedPart,
  Valx,
} from 'schemaDefinition/types';
import {isNullish} from 'services/utils';

const hasValue = (value: DataValue): boolean => {
  if (Array.isArray(value)) {
    return value.some(hasValue);
  }

  return (
    !isNullish(value) &&
    // Non-empty string
    (typeof value !== 'string' || value.trim().length > 0) &&
    // Non-empty object
    (typeof value !== 'object' || Object.values(value).some(hasValue))
  );
};

const hasPartValue = (
  part: Part<Valx>,
  value: Data,
  excludeMainForm: boolean,
): boolean => {
  switch (part.type) {
    case 'expand': {
      assert.soft(false, 'removeEmptyParts: expand not implemented');
      return true;
    }
    case 'nameVariant': {
      const partValue = value[part.name];

      if (!excludeMainForm) {
        return hasValue(partValue);
      }

      return Array.isArray(partValue) && partValue.length > 1;
    }
    default: {
      const partValue = value[part.name];
      return hasValue(partValue);
    }
  }
};

export const removeEmptyValueParts = (
  parts: SeparatedPart<Valx>[],
  value: Data,
  mode: 'form' | 'preview',
  excludeMainForm = false,
): SeparatedPart<Valx>[] => {
  const filteredParts = parts.reduce<SeparatedPart<Valx>[]>((acc, part) => {
    if (Array.isArray(part)) {
      const filteredRowParts = part.filter(rp =>
        hasPartValue(rp, value, excludeMainForm),
      );
      if (filteredRowParts.length > 0) {
        acc.push(filteredRowParts);
      }
      return acc;
    }

    if (
      part.type === 'separator' ||
      hasPartValue(part, value, excludeMainForm)
    ) {
      acc.push(part);
    }

    return acc;
  }, []);

  return filteredParts;
};
