import {PartDate, Valx} from 'schemaDefinition/types';
import {evaluateFieldRequired} from 'schemaDefinition';
import {ValidationArgs, ValidationResult} from './types';
import {fail} from './fail';
import {validResult} from './validResult';
import {validateValueType} from './validateValueType';
import {validateYearOrDateValidations} from './validateYearOrDateValidations';
import {warning} from './warning';

export const validateDate = (
  part: PartDate<Valx>,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(
    part,
    validateSingleDateValue,
    args,
    aggregateResult,
  );
};

export const validateSingleDateValue = (
  part: PartDate<Valx>,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  if (args.value === undefined || args.value === null) {
    const required = evaluateFieldRequired(
      part.required,
      args.valuePath,
      args.localScope,
      args.globalScope,
      args.relatedScope,
      args.value,
    );
    return required
      ? (required === true ? fail : warning)(
          {
            ...args,
            part,
            validation: 'required',
            messageKey: 'required',
          },
          aggregateResult,
        )
      : aggregateResult;
  }

  if (!isValidDate(args.value)) {
    return fail(
      {
        ...args,
        part,
        validation: 'invalid type',
        messageKey: 'invalidDate',
      },
      aggregateResult,
    );
  }

  if (part.validation) {
    return validateYearOrDateValidations(
      part.validation,
      part,
      args,
      aggregateResult,
    );
  }

  return aggregateResult;
};

export const isValidDate = (value: unknown): boolean =>
  typeof value === 'string' && /^-?\d{4}-\d{2}-\d{2}$/.test(value);
