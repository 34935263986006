import React, {useCallback, useMemo} from 'react';
import {Checkbox, FormControlLabel, TypographyProps} from '@mui/material';
import {useImportantFont} from 'theme';
import {CheckboxCheckedIcon, CheckboxUncheckedIcon} from 'components/icons';
import {BaseFieldProps} from './types';
import {Layout} from '../layout';
import './CheckboxField.scss';
import {FieldLabel} from './FieldLabel';

type Props = Omit<BaseFieldProps, 'label'> & {
  /** Label displayed above field */
  label?: string;
  /** Label displayed next to check box */
  boxLabel?: string;
  /** Unique id for field */
  fieldId: string;
  name: string;
  value?: boolean | null;
  /**
   * Font variant to use for option labels, default label
   */
  variant?: 'button';
  onChange?: (value: boolean) => void;
};

const checkboxSx = {padding: '6px'} as const;

export const CheckboxField: React.FC<Props> = ({
  label,
  boxLabel,
  fieldId,
  name,
  value,
  variant,
  readonly = false,
  onChange,
  width,
  maxWidth,
  flex,
}) => {
  const handleToggleValue = useCallback(() => {
    onChange && onChange(!value);
  }, [onChange, value]);
  const importantFont = useImportantFont(variant);
  const componentProps = useMemo(
    () =>
      importantFont
        ? {typography: importantFont as TypographyProps}
        : undefined,
    [importantFont],
  );

  return (
    <Layout width={width} maxWidth={maxWidth} flex={flex} adjustLeft>
      {label && <FieldLabel label={label} htmlFor={fieldId} />}
      {boxLabel ? (
        <FormControlLabel
          label={boxLabel}
          htmlFor={fieldId}
          componentsProps={componentProps}
          control={
            <Checkbox
              id={fieldId}
              name={name}
              checked={!!value}
              readOnly={readonly}
              disabled={readonly}
              onClick={handleToggleValue}
              icon={<CheckboxUncheckedIcon />}
              checkedIcon={<CheckboxCheckedIcon />}
              sx={checkboxSx}
            />
          }
        />
      ) : (
        <Checkbox
          id={fieldId}
          name={name}
          checked={!!value}
          readOnly={readonly}
          disabled={readonly}
          onClick={handleToggleValue}
          icon={<CheckboxUncheckedIcon />}
          checkedIcon={<CheckboxCheckedIcon />}
          sx={checkboxSx}
        />
      )}
    </Layout>
  );
};
