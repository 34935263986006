import {useMemo} from 'react';
import {Concept, DerivedConcept} from 'types';
import {WorkSummary, WorkWithExpressions} from 'api/types';
import {TableConfiguration} from 'schema/table/types';
import {Schema} from 'schemaDefinition/types';
import {useCodelists} from 'services/codeLists';
import {
  mapWorkWithExpressionToWorkSummary,
  useMetadataCodelistIds,
} from 'services/data';
import {useSchema} from 'schemas';
import {useSchemaReference} from 'schemas/hooks/useSchemaReference';
import {useTableConfiguration} from 'schemas/hooks/useTableConfiguration';
import {
  LinkedValueSearchFieldConfig,
  SearchResultType,
  SearchResultValue,
} from '../types';

type TableSearchResultValue =
  | Exclude<SearchResultValue, WorkWithExpressions>
  | WorkSummary;
/**
 * Maps data for table presentation, i.e. maps nested work (with expressions and manifestations)
 * to work summary with aggregated values (MainTitles from first manifestation)
 * Returns mapped data, and corresponding schema and table configuration
 */
export const useTablePresentation = (
  searchEntityType: SearchResultType,
  searchResult: SearchResultValue[],
  schema: Schema,
  sourceConfig?: LinkedValueSearchFieldConfig,
): {
  tableSearchResult: TableSearchResultValue[];
  tableSchema: Schema;
  tableConfiguration: TableConfiguration;
} => {
  const resolvedEntity =
    searchEntityType === Concept.work
      ? DerivedConcept.workSummary
      : searchEntityType;
  const workSummarySchema = useSchema(DerivedConcept.workSummary);
  const codelistIds = useMetadataCodelistIds();
  const codelists = useCodelists(codelistIds);
  const schemaRef = useSchemaReference(resolvedEntity);
  const tableConfiguration = useTableConfiguration(schemaRef);

  return useMemo(() => {
    const indicateRowId =
      (searchEntityType as string) === sourceConfig?.entity
        ? sourceConfig?.sourceEntityId
        : undefined;

    const resolvedTableConfiguration = indicateRowId
      ? {
          ...tableConfiguration,
          indicateRowId,
        }
      : tableConfiguration;

    if (searchEntityType === Concept.work) {
      return {
        tableSearchResult: searchResult.map(work =>
          mapWorkWithExpressionToWorkSummary(
            work as WorkWithExpressions,
            codelists,
          ),
        ),
        tableSchema: workSummarySchema,
        tableConfiguration: resolvedTableConfiguration,
      };
    }

    return {
      tableSearchResult: searchResult as TableSearchResultValue[],
      tableSchema: schema,
      tableConfiguration: resolvedTableConfiguration,
    };
  }, [
    searchEntityType,
    sourceConfig?.entity,
    sourceConfig?.sourceEntityId,
    tableConfiguration,
    searchResult,
    schema,
    workSummarySchema,
    codelists,
  ]);
};
