import {Data} from 'schemaDefinition/types';
import {DataResponse} from '../types';
import {Schemas} from '../dto.generated';

export const mapFilterExportResponse = (
  dto: Schemas.ExportInformationResponse,
): DataResponse => {
  return {
    hits: dto.hits,
    rows: dto.rows.map(mapExportRow),
  };
};

const mapExportRow = (row: Schemas.ExportRow): Data => {
  const values: Data = {
    id: row.ean,
  };

  row.columns.forEach(r => {
    if (r.values) {
      switch (r.column.type) {
        case 'NUMBER':
        case 'YEAR':
          values[r.column.name] = r.values.map(v => parseInt(v, 10));
          break;
        default:
          values[r.column.name] = r.values;
      }
    }
  });

  return values;
};
