import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {metadataExpressionOrderSelector} from 'services/data';

/**
 * Count how many manifestations are in the current work.
 */
export const useManifestationCount = (): number => {
  const expressionOrder = useSelector(metadataExpressionOrderSelector);

  return useMemo(() => {
    return expressionOrder
      .map(g => g.content.filter(c => c.type === 'manifestation').length)
      .reduce((a, b) => a + b, 0);
  }, [expressionOrder]);
};
