import {Valid} from 'schemaDefinition/types';

export const getAggregateValid = (...valids: Valid[]): Valid => {
  if (valids.includes('error')) {
    return 'error';
  }

  if (valids.includes('warning')) {
    return 'warning';
  }

  return 'valid';
};
