import {assert} from 'assert-ts';
import {GetTokens, Tokens} from 'api/types';
import {ErrorKeyAndMessage, HTTP_STATUS_ABORTED, HttpError} from '../types';
import {QueryParams} from '../types';
import {Configuration} from '../../../configuration';
import {resolveURI} from '../functions/resolveURI';

type Headers = {[key: string]: string};

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export type HttpArgs<TBody = unknown> = {
  method: 'GET' | 'POST';
  subdir: string;
  queryParams?: QueryParams;
  body?: TBody | undefined;
  abortSignal?: AbortSignal;
  extraHeaders?: Headers | undefined;
  getTokens?: GetTokens | undefined;
};

type HttpCallArgs = Omit<HttpArgs, 'subdir' | 'queryParams'> & {
  url: string;
  bodyJson?: string;
  accessToken?: string;
  idToken?: string;
};

export const httpMethod = <TResponse, TBody>(
  args: HttpArgs<TBody>,
): Promise<TResponse> => {
  const {
    method,
    subdir,
    queryParams,
    body,
    abortSignal,
    extraHeaders = {},
    getTokens,
  } = args;

  const baseUri = Configuration.searchApi.elastic.uri;
  assert(baseUri, 'httpMethod: baseUrl for search must be given', args);

  const url = resolveURI(baseUri, subdir, undefined, queryParams);

  let bodyJson: string | undefined;
  if (body) {
    bodyJson = JSON.stringify(body);
  }

  if (getTokens) {
    return getTokens().then((tokens: Tokens) => {
      return httpCall({
        method,
        url,
        bodyJson,
        abortSignal,
        extraHeaders,
        accessToken: tokens.accessToken,
        idToken: tokens.idToken,
      });
    });
  } else {
    return httpCall({
      method,
      url,
      bodyJson,
      abortSignal,
      extraHeaders,
    });
  }
};

const httpCall = <TData>(args: HttpCallArgs): Promise<TData> => {
  const {
    accessToken,
    idToken,
    abortSignal,
    extraHeaders,
    url,
    method,
    bodyJson,
  } = args;

  const headers =
    accessToken && idToken
      ? {
          ...defaultHeaders,
          Authorization: accessToken,
          'Authentication-Info': idToken,
          ...extraHeaders,
        }
      : {...defaultHeaders, ...extraHeaders};

  return fetch(url, {
    method,
    body: bodyJson,
    signal: abortSignal,
    headers,
  })
    .then(async response => {
      if (response.ok) {
        // No content
        if (response.status === 204) {
          return null as unknown as TData;
        }

        return (await response.json()) as TData;
      } else {
        // Try get more error info
        let errorInfo: ErrorKeyAndMessage = {};
        try {
          errorInfo = await response.json();
        } catch (jsonError) {
          console.log(
            `HTTP ${method}: status: ${response.status}: url: ${url}, could not get json response`,
          );
        }

        throw new HttpError(
          response.status,
          url,
          errorInfo?.errorMessage ??
            `HTTP ${method} failed: status: ${response.status}`,
          errorInfo?.errorKey,
          errorInfo,
          undefined,
        );
      }
    })
    .catch(error => {
      if (error instanceof DOMException && error.name === 'AbortError') {
        console.log(
          'HTTP request aborted',
          error.message,
          method,
          url,
          bodyJson,
        );

        throw new HttpError(
          HTTP_STATUS_ABORTED,
          url,
          error.message,
          undefined,
          undefined,
          error,
        );
      }

      if (error instanceof HttpError) {
        console.error(
          `HTTP ${method} ${url} failed: ${error.status ?? 'unknown'}: ${
            error.message ?? ''
          }`,
          error.status ?? undefined,
          error.message,
          method,
          url,
          bodyJson,
        );
      } else {
        console.error(
          'HTTP request failed ',
          error.status ?? undefined,
          error.errorKey,
          error.message,
          method,
          url,
          bodyJson,
        );
      }

      throw error;
    });
};
