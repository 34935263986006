import React, {useMemo} from 'react';
import {styled} from '@mui/material';
import MuiBox from '@mui/material/Box';
import {FlexBoxProps} from './types';

const MuiBoxStyled = styled(MuiBox)(() => ({
  '.BB-hidden': {
    pointerEvents: 'none',
    opacity: 0,
  },
}));

const getAlignmentProps = (
  props: FlexBoxProps,
): {
  alignItems?: string;
  justifyContent?: string;
  flexDirection: 'row' | 'column';
} => {
  const {horizontal, center, left, right, top, bottom, spaceBetween} = props;

  const horAlignment = left ? 'flex-start' : right ? 'flex-end' : undefined;
  const vertAlignment = top ? 'flex-start' : bottom ? 'flex-end' : undefined;
  return horizontal
    ? {
        alignItems: vertAlignment ?? (center ? 'center' : undefined),
        justifyContent:
          horAlignment ??
          (spaceBetween ? 'space-between' : center ? 'center' : undefined),
        flexDirection: 'row' as const,
      }
    : {
        alignItems: horAlignment ?? (center ? 'center' : undefined),
        justifyContent:
          vertAlignment ??
          (spaceBetween ? 'space-between' : center ? 'center' : undefined),
        flexDirection: 'column' as const,
      };
};

const getClassName = (props: FlexBoxProps) => {
  const {hidden} = props;
  return hidden ? 'BB-hidden' : undefined;
};

const useDerivedLayoutProps = (props: FlexBoxProps) => {
  return useMemo(() => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      hidden,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      horizontal,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      center,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      left,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      right,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      top,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      bottom,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      spaceBetween,
      ...otherProps
    } = props;

    return {
      className: getClassName(props),
      ...getAlignmentProps(props),
      ...otherProps,
    };
  }, [props]);
};

export const FlexBox = React.forwardRef<HTMLDivElement, FlexBoxProps>(
  (props, ref) => {
    const derivedProps = useDerivedLayoutProps(props);

    return <MuiBoxStyled ref={ref} display="flex" {...derivedProps} />;
  },
);
