import React from 'react';
import {Tooltip} from '@mui/material';
import Badge, {BadgeProps} from '@mui/material/Badge';
import MuiIconButton from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';
import {ColorPalette} from 'theme';
import {ActivityIndicator} from 'components/activityIndicator';
import {Icon} from 'components/icons';
import {Layout} from 'components/layout';
import {IconButtonProps} from './types';

type Props = IconButtonProps & {
  isCalling: boolean;
  isDelayed?: boolean;
};

const BadgeRaw = styled(Badge)<BadgeProps>(() =>
  // {theme}
  ({
    '& .MuiBadge-badge': {
      right: -4,
      top: 6,
      color: ColorPalette.burgundy,
      // border: `2px solid ${theme.palette.background.paper}`,
      // padding: '0 4px',
    },
  }),
);

const Container: React.FC<{tooltip?: string; children: React.ReactElement}> = ({
  tooltip,
  children,
}) => {
  return tooltip ? (
    <Tooltip title={tooltip} placement="top">
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

/**
 * Has 2 main states:
 * - enabled: the button is enabled and can be clicked
 * - isCalling: button is clicked, is waiting for a response and is disabled
 *   - isDelayed: substate of isCalling, the response is delayed and a spinner is shown
 */
export const ControlledIconButton: React.FC<Props> = ({
  icon,
  badgeCount,
  size = 'medium',
  isCalling,
  isDelayed = false,
  disabled,
  preText,
  postText,
  tooltip,
  ...props
}) => {
  return (
    <Container tooltip={tooltip}>
      <MuiIconButton disabled={isCalling || disabled} size={size} {...props}>
        {preText ? preText : null}
        {isDelayed ? (
          <Layout width={24} height={24} adjustCenter>
            <ActivityIndicator size={14} />
          </Layout>
        ) : badgeCount !== undefined ? (
          <BadgeRaw badgeContent={badgeCount}>
            <Icon icon={icon} color={disabled ? 'disabled' : 'primary'} />
          </BadgeRaw>
        ) : (
          <Icon icon={icon} color={disabled ? 'disabled' : 'primary'} />
        )}
        {postText ? postText : null}
      </MuiIconButton>
    </Container>
  );
};
