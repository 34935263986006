import get from 'lodash/get';
import {Data, DataValue, Part, Schema} from 'schemaDefinition/types';
import {
  ShouldVisitSubSchema,
  areValuesEqual,
  resolveCompare,
  visitPartCompareSubvalues,
  visitSchemaParts,
} from 'schemaDefinition';
import {ThesaurusMap} from '../types';
import {getActualThesaurusGroupPaths} from './getActualThesaurusGroupPaths';

export const getPartsCountAndPaths = <T extends Data>(
  schema: Schema<unknown>,
  shouldVisitSubSchema: ShouldVisitSubSchema<unknown> = () => true,
  changes: T,
  original: Data,
  thesauruses: ThesaurusMap | undefined = undefined,
): {count: number; paths: string[]} => {
  let count = 0;
  const paths: string[] = [];

  visitSchemaParts(
    schema,
    (part, path, __, parentParts) => {
      const compare = resolveCompare(part, parentParts);
      if (part.type === 'thesaurus') {
        const originalValue = get(original, path);
        const changesValue = get(changes, path);
        const categoryPaths = getActualThesaurusGroupPaths(
          part,
          path,
          originalValue,
          changesValue,
          thesauruses,
        );
        if (categoryPaths && categoryPaths.length > 0) {
          count += categoryPaths.length;
          paths.push(...categoryPaths);
        }
      } else if (compare === 'subValues') {
        const originalValue = get(original, path);
        const changesValue = get(changes, path);
        const subpaths = getActualSubValuePaths(
          part,
          path,
          parentParts,
          originalValue,
          changesValue,
        );

        count += subpaths.length;
        paths.push(...subpaths);
      } else if (compare === 'items') {
        const originalValue = get(original, path);
        const changesValue = get(changes, path);
        const itempaths = getActualItemsPaths(
          part,
          path,
          parentParts,
          originalValue,
          changesValue,
        );
        count += itempaths.length;
        paths.push(...itempaths);
      } else if (compare === 'fullValue') {
        count++;
        paths.push(path);
      }
    },
    shouldVisitSubSchema,
    '',
    [],
  );

  // Remove duplicates, parent paths
  return {count, paths};
};

const getActualSubValuePaths = (
  part: Part<unknown>,
  path: string,
  parentParts: Part<unknown>[] | undefined,
  originalValue: DataValue,
  changesValue: DataValue,
): string[] => {
  const subpaths: string[] = [];

  visitPartCompareSubvalues(
    part,
    originalValue,
    path,
    parentParts,
    // Visitor, add all subpaths
    (_, subpath) => {
      subpaths.push(subpath);
    },
  );

  visitPartCompareSubvalues(
    part,
    changesValue,
    path,
    parentParts,
    // Visitor, avoid duplicates
    (_, subpath) => {
      if (!subpaths.includes(subpath)) subpaths.push(subpath);
    },
  );

  return subpaths;
};

const getActualItemsPaths = (
  part: Part<unknown>,
  path: string,
  parentParts: Part<unknown>[] | undefined,
  originalValue: DataValue,
  changesValue: DataValue,
): string[] => {
  const resolvedOriginal = Array.isArray(originalValue) ? originalValue : [];
  const resolvedChanges = Array.isArray(changesValue) ? changesValue : [];

  const maxIdx = Math.max(resolvedOriginal.length, resolvedChanges.length) - 1;
  const allItemIdx = Array.from({length: maxIdx + 1}, (_, idx) => idx);
  const nonEqualIdxs = allItemIdx.filter(
    idx => !areValuesEqual(resolvedOriginal[idx], resolvedChanges[idx]),
  );
  return nonEqualIdxs.map(idx => `${path}[${idx}]`);
};
