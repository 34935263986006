import React from 'react';
import {Stack} from '@mui/material';
import {FCWithChildren} from 'types';
import {ColorPalette} from '../../theme';

type Props = {
  horizontal?: boolean;
  tight?: boolean;
};

export const FieldLayout: FCWithChildren<Props> = ({
  horizontal = false,
  tight = false,
  children,
}) => {
  return (
    <Stack
      color={ColorPalette.primary.burgundy}
      direction={horizontal ? 'row' : 'column'}
      flex={horizontal ? '1' : undefined}
      spacing={tight ? 0 : 2}>
      {children}
    </Stack>
  );
};
