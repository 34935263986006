import React, {useCallback} from 'react';
import {IconButton, IconButtonProps} from '@mui/material';
import {useLocalization} from 'localization';
import {CopyIcon} from '../icons';

type Props = IconButtonProps & {
  text: string;
};

export const CopyToClipboard: React.FC<Props> = ({text, ...props}) => {
  const {t} = useLocalization();
  const copy = useCallback(() => {
    navigator.clipboard.writeText(text);
  }, [text]);

  return (
    <IconButton onClick={copy} aria-label={t('general.copy')} {...props}>
      <CopyIcon />
    </IconButton>
  );
};
