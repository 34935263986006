import {Agent, DataLoadStatus} from 'api/types';
import {loadReducer} from 'services/utils';
import {LoadAction} from 'services/utils/redux/actions';

export type AgentState = DataLoadStatus<Agent>;

export type AgentAction_Load = {
  type: 'LOAD_AGENT';
} & LoadAction<Agent>;

export type AgentAction_Saved = {
  type: 'SAVED_AGENT';
  payload: {data: Agent};
};

export type AgentAction_Deleted = {
  type: 'DELETED_AGENT';
};

export type AgentAction =
  | AgentAction_Load
  | AgentAction_Saved
  | AgentAction_Deleted;

export const AgentDefaultState: AgentState = {status: 'NotLoaded'};

export const agentReducer = (
  state: AgentState = AgentDefaultState,
  action: AgentAction,
): AgentState => {
  switch (action.type) {
    case 'LOAD_AGENT': {
      return loadReducer(state, action);
    }
    case 'SAVED_AGENT': {
      return {status: 'Loaded', data: action.payload.data};
    }
    case 'DELETED_AGENT': {
      return AgentDefaultState;
    }
    default: {
      return state;
    }
  }
};
