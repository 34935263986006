import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const OpenIcon: React.FC<IconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M18 4H12V6H16.59L11.29 11.29L12.71 12.71L18 7.41V12H20V6V4H18Z" />
      <path d="M18 18H6V6H10V4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V14H18V18Z" />
    </SvgIcon>
  );
};
