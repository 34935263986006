import React from 'react';
import {SxProps} from '@mui/material';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {Layout, Text} from 'components';

const textSx: SxProps = {
  color: ColorPalette.burgundy50,
};
export const EmptyListPlaceholder = () => {
  const {t} = useLocalization();
  return (
    <Layout pb={0.5}>
      <Text variant="body2" sx={textSx}>
        {t('general.noItems')}
      </Text>
    </Layout>
  );
};
