import {useSelector} from 'react-redux';
import assert from 'assert-ts';
import {ManifestationV4} from 'api';
import {savedManifestationSelectorById} from '../selectors';
import {useCreateSelectorById} from './useCreateSelectorById';

export const useAssertSavedManifestation = (
  manifestationId: string,
): ManifestationV4 => {
  const selector = useCreateSelectorById(
    manifestationId,
    savedManifestationSelectorById,
  );
  const manifestation = useSelector(selector);
  return assert(manifestation, 'useAssertSavedManifestation: not loaded');
};
