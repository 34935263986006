import assert from 'assert-ts';
import groupBy from 'lodash/groupBy';
import {NodeAndAncestors, TypeNodesWithAncestors} from '../types';

/**
 * Groups
 * @returns
 */
export const groupPrNodeType = <
  TNode extends string = string,
  T extends NodeAndAncestors = NodeAndAncestors,
>(
  nodes: T[],
): TypeNodesWithAncestors<TNode, T>[] => {
  const nodesWithType = nodes.filter(n => n.node.type as TNode);
  assert.soft(
    nodesWithType.length === nodes.length,
    'groupPrNodeType: nodes missing type',
    {nodes},
  );
  const grouped = groupBy(nodesWithType, n => n.node.type);

  return Object.keys(grouped).map(nodeType => ({
    nodeType: nodeType as TNode,
    nodes: grouped[nodeType],
  }));
};
