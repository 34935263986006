import {useMemo} from 'react';
import {FieldLayout} from 'schema/types';
import {Part} from '../../../schemaDefinition/types';
import {useFormSchemaGlobalScopeContext} from '../../contexts';
import {useDataFormConfigurationContext} from '../contexts/DataFormConfigurationContext';

const layoutConfig: {[key: string]: FieldLayout} = {
  // Pr schema layouts
  'publisher.name': {maxWidth: '12rem'},

  // Global field layouts
  place: {flex: 1},
  bibleVersion: {flex: 1},
  bibleContent: {flex: 1},
  country: {flex: 1},
  countryOfBirth: {flex: 1},
  countryFlourishedIn: {flex: 1},
  deweyNumber: {width: '8rem'},
  deweySource: {width: '6rem'},
  edition: {maxWidth: '4rem'},
  ean: {width: '8rem'},
  occupationalField: {flex: 1},
  isbn: {width: '8rem'},
  ismn: {width: '8rem'},
  isni: {width: '8rem'},
  productOwner: {flex: 1},
  nationalId: {maxWidth: '11rem'},
  profession: {flex: 1},
  expressionFormat: {maxWidth: '8rem'},
  content: {flex: 1},
  productForm: {flex: 1},
  productFormDetail: {flex: 1},
  productGroup: {flex: 1},
  bookGroup: {flex: 1},
  publishedYear: {maxWidth: '6rem'},

  intermediateOriginalLanguage: {flex: 1},

  dimensions: {flex: 2, maxWidth: '10rem'},
  weight: {flex: 1, maxWidth: '6rem'},
  pageCount: {maxWidth: '6rem'},
  unnumberedPages: {maxWidth: '6rem'},
  runtime: {flex: 2, maxWidth: '10rem'},
  scale: {flex: 1, maxWidth: '13rem'},
  colour: {flex: 2},

  // Pr part type layout
  'type:date': {maxWidth: '10rem'},
  'type:year': {width: '6rem'},
  'type:yearOrDate': {maxWidth: '10rem'},
};

export const getLayout = (key: string): FieldLayout | undefined =>
  layoutConfig[key];

export const useLayout = <TVal>(part: Part<TVal>): FieldLayout | undefined => {
  const {schema} = useFormSchemaGlobalScopeContext();
  const config = useDataFormConfigurationContext();
  return useMemo(() => {
    const partLayout =
      (part.name ? config.parts?.[part.name]?.layout : undefined) ??
      (part.labelKey ? config.parts?.[part.labelKey]?.layout : undefined);
    if (partLayout) {
      return partLayout;
    }

    const scopedKey =
      schema.key && part.name ? `${schema.key}.${part.name}` : undefined;
    return (
      (scopedKey ? layoutConfig[scopedKey] : undefined) ??
      (part.name ? layoutConfig[part.name] : undefined) ??
      layoutConfig[`type:${part.type}`] ??
      (part.labelKey ? layoutConfig[part.labelKey] : undefined) ??
      undefined
    );
  }, [config.parts, part, schema.key]);
};
