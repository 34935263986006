import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {styled} from '@mui/material';
import MuiBox from '@mui/material/Box';
import {useEventListener} from 'usehooks-ts';
import {FCWithChildren} from 'types';
import {ColorPalette} from 'theme';
import {Fonts} from 'theme/fonts';
import {BoxProps} from '../types';
import {LayoutProps} from './types';
import {useThemeColor} from '../../theme/hooks/useThemeColor';

const MuiBoxStyled = styled(MuiBox)(() => ({
  div: {
    opacity: 1,
    transition: 'opacity 0.3s',
    '&.Ebba-hidden': {
      pointerEvents: 'none',
      opacity: 0,
    },
    '&.Ebba-focusable': {
      ...Fonts.body2,
      color: ColorPalette.primary.burgundy,
      border: 'none',
      boxShadow: `0 0 0 1.25px ${ColorPalette.primary.warmGreen}`,
      borderRadius: '8px',
      outline: 'none',
      backgroundColor: ColorPalette.primary.white,
      transition: '.1s',
      paddingLeft: '14px',
      paddingRight: '14px',
      paddingTop: '8px',
      paddingBottom: '8px',
      ':focus-within, :hover, :active': {
        boxShadow: `0 0 0 2px ${ColorPalette.primary.warmGreen}`,
        '> *': {
          border: 'none',
          padding: 0,
          outline: 0,
          WebkitAppearance: 'none',
          boxShadow: 'none',
        },
      },
      '> *': {
        border: 'none',
        padding: 0,
        '::selection': {
          background: ColorPalette.primary.lightGreen,
        },
        '::placeholder': {
          color: ColorPalette.primary.warmGreen,
        },
      },
    },
    '&.Ebba-focusable-error': {
      boxShadow: `0 0 0 1.25px ${ColorPalette.error}`,
      ':focus-within, :hover, :active': {
        boxShadow: `0 0 0 2px ${ColorPalette.error}`,
      },
      '> *': {
        '::placeholder': {
          color: ColorPalette.error40,
        },
      },
    },
    '&.Ebba-focusable-warning': {
      boxShadow: `0 0 0 1.25px ${ColorPalette.warning}`,
      ':focus-within, :hover, :active': {
        boxShadow: `0 0 0 2px ${ColorPalette.warning}`,
      },
      '> *': {
        '::placeholder': {
          color: `${ColorPalette.warning60} !important`,
        },
      },
    },
  },
}));

const getAlignmentProps = (
  props: LayoutProps,
): {
  alignItems?: string;
  justifyContent?: string;
  flexWrap?: 'wrap';
  flexDirection: 'row' | 'column';
} => {
  const {
    horizontal,
    wrap,
    adjustCenter,
    adjustLeft,
    adjustRight,
    adjustTop,
    adjustBottom,
  } = props;
  const horAlignment = adjustLeft
    ? 'flex-start'
    : adjustRight
      ? 'flex-end'
      : adjustCenter
        ? 'center'
        : 'space-between';
  const vertAlignment = adjustTop
    ? 'flex-start'
    : adjustBottom
      ? 'flex-end'
      : adjustCenter
        ? 'center'
        : undefined;
  const flexWrap: BoxProps['flexWrap'] = wrap ? 'wrap' : undefined;
  return horizontal
    ? {
        alignItems: vertAlignment,
        justifyContent: horAlignment,
        flexWrap,
        flexDirection: 'row' as const,
      }
    : {
        alignItems: horAlignment,
        justifyContent: vertAlignment,
        flexWrap,
        flexDirection: 'column' as const,
      };
};

const getClassName = (props: LayoutProps) => {
  const {hidden, focusable, error, warning} = props;

  return hidden
    ? focusable
      ? 'Ebba-hidden Ebba-focusable'
      : 'Ebba-hidden'
    : focusable
      ? error
        ? 'Ebba-focusable Ebba-focusable-error'
        : warning
          ? 'Ebba-focusable Ebba-focusable-warning'
          : 'Ebba-focusable'
      : undefined;
};

const useDerivedLayoutProps = (props: LayoutProps) => {
  const themeColor = useThemeColor(props.kind, props.colorMode);

  return useMemo(() => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      kind,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      colorMode,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      hidden,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      focusable,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      error,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      warning,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      horizontal,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      wrap,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      adjustCenter,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      adjustLeft,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      adjustRight,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      adjustTop,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      adjustBottom,
      ...otherProps
    } = props;

    return {
      background: themeColor,
      className: getClassName(props),
      ...getAlignmentProps(props),
      ...otherProps,
    };
  }, [props, themeColor]);
};

export const Layout: FCWithChildren<LayoutProps> = props => {
  const derivedProps = useDerivedLayoutProps(props);

  return <MuiBoxStyled display="flex" {...derivedProps} />;
};

type InteractiveLayoutProps = LayoutProps & {
  onClick?: () => void;
  /** Emulates blur using focusin and focusout events */
  onBlur?: () => void;
};

export const InteractiveLayout: React.FC<InteractiveLayoutProps> = ({
  onClick,
  onBlur,
  ...layoutProps
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const derivedProps = useDerivedLayoutProps(layoutProps);

  const handleClick = useCallback(() => {
    onClick && onClick();
  }, [onClick]);

  useEventListener('click', handleClick, ref);

  const [hasFocus, setHasFocus] = useState<boolean | undefined>(undefined);

  const handleOnFocusOut = useCallback(() => {
    // console.log('handleOnFocusOut');
    onBlur && setHasFocus(false);
  }, [onBlur]);

  useEventListener('focusout', handleOnFocusOut, ref);

  const handleOnFocusIn = useCallback(() => {
    // console.log('handleOnFocusIn');
    onBlur && setHasFocus(true);
  }, [onBlur]);

  useEventListener('focusin', handleOnFocusIn, ref);

  const delayedBlurTimerRef = useRef<ReturnType<typeof setTimeout> | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!onBlur) {
      return;
    }

    if (delayedBlurTimerRef.current) {
      clearTimeout(delayedBlurTimerRef.current);
    }

    if (hasFocus === false) {
      delayedBlurTimerRef.current = setTimeout(() => {
        // console.log('hasFocus', hasFocus);
        onBlur && onBlur();
      }, 100);
    }
    // Only trigger on hasFocus changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFocus]);

  return <MuiBoxStyled ref={ref} display="flex" {...derivedProps} />;
};
