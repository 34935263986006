import {AgentSubType} from 'types';
import {Agent, AgentTypeDto, GetTokens} from 'api/types';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpElastic} from './http/search/httpElastic';
import {mapToAgentTypeDto} from './mappers/mapAgentTypeDto';
import {mapSearchAgentResult} from './mappers/mapSearchAgentResult';
import {SearchAgentDto, SearchResult, SearchResultDto} from './searchTypes';

export const searchAgents = (
  search: string,
  agentTypes: AgentSubType[],
  page: number,
  size: number,
  getTokens: GetTokens,
  abortSignal?: AbortSignal,
  mock?: boolean,
): Promise<SearchResult<Agent>> => {
  const apiAgentTypes = agentTypes.map(mapToAgentTypeDto);

  return exceptionToPromiseReject(() =>
    httpElastic<SearchResultDto<SearchAgentDto>>(
      getTokens,
      '_search',
      'agent',
      {
        bool: {
          ...makeSearchPart(search),
          ...makeFilterPart(apiAgentTypes),
        },
      },
      page,
      size,
      0.1,
      undefined,
      abortSignal,
      mock,
    ).then(mapSearchAgentResult),
  );
};

const makeSearchPart = (search: string): object => {
  return {
    must: [
      ...search.split(' ').map(term => ({
        query_string: {
          query: '*' + term + '*',
        },
      })),
    ],
    should: [
      ...search.split(' ').map(term => ({
        query_string: {
          query: term,
        },
      })),
    ],
  };
};
const makeFilterPart = (agentTypes: AgentTypeDto[]): object => {
  if (agentTypes.length === 0) {
    return {};
  }

  if (agentTypes.length === 1) {
    return {
      filter: {
        term: {'agentType.keyword': agentTypes[0]},
      },
    };
  }

  return {
    filter: {
      bool: {
        should: agentTypes.map(agentType => ({
          term: {
            'agentType.keyword': agentType,
          },
        })),
      },
    },
  };
};
