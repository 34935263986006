import {Schema, SchemaValueType, Valx} from '../types';
import {ValueVisitor} from './types';
import {resolveSchemaValue} from './resolveSchemaValue';
import {visitSchemaValues} from './visitSchemaValues';

export const decorateSchemaValues = (
  mutableSchema: Schema<SchemaValueType>,
): Schema<Valx> => {
  const tempSchema = mutableSchema as Schema<SchemaValueType & Valx>;
  const visitor: ValueVisitor<SchemaValueType, Valx> = (
    val,
    replace,
    context,
  ) => replace(resolveSchemaValue(val, context));

  visitSchemaValues<SchemaValueType, Valx>(tempSchema, visitor);

  return tempSchema as Schema<Valx>;
};
