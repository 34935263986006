import {AppState} from 'store/types';
import {getUsers} from 'api';
import {useGetTokens} from 'services/auth';
import {useResource} from 'services/utils';
import {UserActionType} from '../actions';

const usersSelector = (state: AppState) => state.users;

/**
 * Returns the users with load status from the store, with an reload operation.
 * If not yet loaded, the users are loaded from the api.
 * @returns
 */
export const useUsers = () => {
  const getTokens = useGetTokens();
  return useResource(UserActionType.LOAD_USERS, usersSelector, () =>
    getUsers(getTokens),
  );
};
