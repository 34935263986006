import {Concept, EntitySubType} from 'types';
import {isSubTypeOfEntity} from 'services/utils/functions/isSubTypeOfEntity';
import {LinkedValueSearchFieldConfig, SearchMainEntityType} from '../../types';

const defaultSubEntityMap: {
  [key in SearchMainEntityType]: EntitySubType | undefined;
} = {
  work: undefined,
  agent: Concept.person,
  collection: Concept.series,
};

export const getDefaultSubEntity = (
  mainEntity: SearchMainEntityType | undefined,
  config: LinkedValueSearchFieldConfig | undefined,
  defaultDefaultSub?: EntitySubType | undefined,
): EntitySubType | undefined => {
  if (!mainEntity) {
    return undefined;
  }

  let defaultSub = isSubTypeOfEntity(defaultDefaultSub, mainEntity)
    ? // Use default default if subentity of main entity
      defaultDefaultSub
    : defaultSubEntityMap[mainEntity];

  // Constrain by config, if any and same entity
  if (config && config.entity === mainEntity && config.entitySubtypes) {
    defaultSub =
      defaultSub && config.entitySubtypes.includes(defaultSub)
        ? defaultSub
        : config.entitySubtypes[0];
  }

  return defaultSub;
};
