import {GroupedParts, SeparatedParts} from 'schemaDefinition/types';

export const getGroupedParts = <TVal>(
  separatedParts: SeparatedParts<TVal>,
  mode: 'form' | 'preview',
): GroupedParts<TVal>[] => {
  let group: GroupedParts<TVal> = {parts: []};
  const grouped: GroupedParts<TVal>[] = [];
  separatedParts.forEach(p => {
    // Include proper parts (not separators) and line-separators
    if (Array.isArray(p) || p.type !== 'separator' || p[mode] === 'line') {
      group.parts.push(p);
    }
    // Handle card-separator, ignore 'none'
    else if (p[mode] === 'card') {
      if (group.parts.length > 0) {
        grouped.push(group);
      }
      group = {titleKey: p.titleKey, role: p.role, parts: []};
    }
  });
  if (group.parts.length > 0) {
    grouped.push(group);
  }

  return grouped;
};
