import React from 'react';
import {FieldRequiredSimple} from 'schemaDefinition/types';
import {isNullish} from 'services/utils';
import {Layout} from 'components/layout';
import {TextFieldProps} from '../types';
import {BaseFieldProps} from './types';
import {TextArea} from '../text';
import {FieldLabel} from './FieldLabel';

type Props = BaseFieldProps &
  Pick<TextFieldProps, 'placeholder' | 'onChange' | 'onBlur'> &
  Required<Pick<TextFieldProps, 'name'>> & {
    value: string | null;
    required?: FieldRequiredSimple;
    maxLength?: number;
    rows?: number;
  };

export const TextAreaField: React.FC<Props> = ({
  label,
  placeholder,
  name,
  value,
  required = false,
  error = false,
  readonly,
  maxLength,
  rows,
  ...props
}) => {
  return (
    <div>
      {/* Label and count */}
      {isNullish(maxLength) ? (
        label ? (
          <FieldLabel
            label={label}
            required={required}
            error={error}
            htmlFor={name}
          />
        ) : null
      ) : label ? (
        <Layout
          horizontal
          adjustCenter
          justifyContent={'space-between'}
          width={props.width}
          maxWidth={props.maxWidth}
          flex={props.flex}
          alignItems={props.alignItems}>
          <FieldLabel
            label={label}
            required={required}
            error={error}
            htmlFor={name}
          />
          <FieldLabel label={`${value?.length}/${maxLength}`} />
        </Layout>
      ) : (
        <Layout
          horizontal
          adjustCenter
          adjustRight
          width={props.width}
          maxWidth={props.maxWidth}
          flex={props.flex}
          alignItems={props.alignItems}>
          <FieldLabel label={`${value?.length}/${maxLength}`} />
        </Layout>
      )}
      <TextArea
        name={name}
        placeholder={placeholder}
        value={value}
        readonly={readonly}
        error={error === true}
        warning={error === 'warning'}
        maxLength={maxLength}
        rows={rows}
        {...props}
      />
    </div>
  );
};
