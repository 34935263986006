import {Fragment} from 'react';
import {SxProps} from '@mui/material';
import {IconName} from 'components/icons/types';
import {NodeAndAncestors} from 'services/thesaurus/types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {Icon, Layout, Text, TextMatch} from 'components';
import {UnstyledButton} from 'components/button/UnstyledButton';
import {useThesaurusEditStateContext} from '../contexts';

type Props = {
  node: NodeAndAncestors;
  prefixIcon?: IconName;
  /** Will highlight any matches in code and node label if set */
  searchQuery?: string;
  matchAllLevels?: boolean;
  noRightArrow?: boolean;
  sx?: SxProps;
  innerSx?: SxProps;
  onGotoNode: () => void;
};

const separatorSx = {
  // fontWeight: 'bold',
  px: 1,
};
const Separator = () => (
  <Text variant="body2" sx={separatorSx}>
    {'/'}
  </Text>
);

const sxAlpha75: SxProps = {
  color: ColorPalette.burgundy75,
};

const sxAlpha50: SxProps = {
  color: ColorPalette.burgundy50,
};

const sxCodeDeactivated: SxProps = {
  color: ColorPalette.burgundy50,
  pr: 0.5,
};

const sxCode = {
  pr: 0.5,
};

export const ThesaurusNodeButton: React.FC<Props> = ({
  node,
  prefixIcon,
  noRightArrow = false,
  searchQuery,
  sx,
  innerSx,
  onGotoNode,
}) => {
  const {t} = useLocalization();
  const {getDisplayCode} = useThesaurusEditStateContext();

  const {
    node: {label, deactivated},
    ancestors,
  } = node;

  const code = getDisplayCode(node.node);

  return (
    <UnstyledButton onClick={onGotoNode}>
      <Layout horizontal adjustCenter justifyContent={'space-between'} sx={sx}>
        <Layout horizontal adjustCenter adjustLeft wrap sx={innerSx}>
          {prefixIcon ? <Icon icon={prefixIcon} /> : null}
          {code ? (
            <TextMatch
              match={searchQuery}
              sx={deactivated ? sxCodeDeactivated : sxCode}
              variant="body2">{`(${code})`}</TextMatch>
          ) : null}
          {ancestors.slice(2).map(ancestor => (
            <Fragment key={ancestor.id}>
              <Text variant="body2" sx={deactivated ? sxAlpha50 : sxAlpha75}>
                {ancestor.label}
              </Text>
              <Separator />
            </Fragment>
          ))}
          <TextMatch
            match={searchQuery}
            variant="body2"
            sx={deactivated ? sxAlpha50 : undefined}>
            {deactivated ? `${label} (${t('general.deactivated')})` : label}
          </TextMatch>
        </Layout>
        {!noRightArrow ? (
          <Icon icon={'ArrowRight'} sx={{cursor: 'pointer'}} />
        ) : null}
      </Layout>
    </UnstyledButton>
  );
};
