import React from 'react';
import {SxProps} from '@mui/material';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {ActivityIndicator, Layout, Spacer, Text} from 'components';

const containerSx: SxProps = {
  // height: 10,
  padding: 2,
  borderRadius: 2,
  backgroundColor: ColorPalette.lightBeige,
};

export const SearchingAgent: React.FC = () => {
  const {t} = useLocalization();
  return (
    <Layout sx={containerSx}>
      <Layout horizontal adjustCenter adjustLeft>
        <ActivityIndicator size={'small'} />
        <Spacer size={1} />
        <Text variant="h3">{t('wizard.agent.step.1.searching')}</Text>
      </Layout>
    </Layout>
  );
};
