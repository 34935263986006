import {useMemo} from 'react';
import {ValidationResult} from 'schema/form/functions/validators/types';
import {useLocalization} from 'localization';
import {ManifestationStatus} from 'api';
import {useAssertManifestationEdit} from 'services/data';
import {Layout, Spacer, Text} from 'components';
import {ManifestationTitle} from './ManifestationTitle';

type Props = {
  entityId: string;
  status: ManifestationStatus | undefined;
  validation: ValidationResult;
};

export const ManifestationValidationErrorHeader: React.FC<Props> = ({
  entityId,
  status,
  validation,
}) => {
  const manifestation = useAssertManifestationEdit(entityId);

  const localization = useLocalization();
  const {tLoose} = localization;
  const statusLabel = useMemo(() => {
    return status && validation.valid !== 'valid'
      ? tLoose(`entity.manifestation.status.short.${status}`)
      : null;
  }, [status, tLoose, validation.valid]);

  return (
    <Layout horizontal adjustCenter adjustLeft>
      <ManifestationTitle manifestation={manifestation} />
      <Spacer size={1} />
      {statusLabel ? <Text variant="body2">{`(${statusLabel})`}</Text> : null}
    </Layout>
  );
};
