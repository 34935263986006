import {PreviewConfiguration} from 'schema/types';
import {TextCollection} from '../../renderers';

export const externalSuggestionPreviewConfig: PreviewConfiguration = {
  layout: {},
  partValueRenderers: {
    intellectualLevel: TextCollection,
    'seriesTitles.suggestion': TextCollection,
    'seriesNumbers.suggestion': TextCollection,
  },
};
