import {useCallback, useRef} from 'react';
import {assert} from 'assert-ts';
import {CodeList, CodeListId} from 'api';
import {
  CodelistRestrictor,
  useRestrictedCodelistsContext,
} from 'services/codeLists';
import {useManifestationImportProps} from 'services/data';
import {getRestrictedManifestationCodelist} from '../functions/getRestrictedManifestationCodelist';

type RestrictedCodelistCache = {
  [key in CodeListId]?: {
    importPropsJson: string;
    codelist: CodeList;
  };
};
export const useManifestationCodelistRestrictor = (
  manifestationId: string,
  expressionId: string,
): CodelistRestrictor => {
  const importProps = useManifestationImportProps(
    manifestationId,
    expressionId,
  );
  const {getRestrictedCodelist: getOuterRestrictedCodelist} =
    useRestrictedCodelistsContext();

  const restrictedCodelistCacheRef = useRef<RestrictedCodelistCache>({});

  return useCallback(
    codelist => {
      const outerCodelist = getOuterRestrictedCodelist(codelist);
      const cached = restrictedCodelistCacheRef.current[codelist.id];
      const importPropsJson = JSON.stringify(importProps);
      if (!cached || cached.importPropsJson !== importPropsJson) {
        const restrictedCodeList = getRestrictedManifestationCodelist(
          importProps,
          outerCodelist,
        );
        restrictedCodelistCacheRef.current[codelist.id] = {
          importPropsJson,
          codelist: restrictedCodeList,
        };
      }

      return assert(
        restrictedCodelistCacheRef.current[codelist.id],
        'useManifestationCodelistRestrictor: codelist expected',
      ).codelist;
    },
    [importProps, getOuterRestrictedCodelist],
  );
};
