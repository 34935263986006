import React, {useCallback} from 'react';
import {SxProps} from '@mui/material';
import {assert} from 'assert-ts';
import {Concept, LinkTargetConcreteEntityType} from 'types';
import {ColorPalette} from 'theme';
import {getEntityMainType} from 'services/utils';
import {Layout, SearchInput, Spacer} from 'components';
import {SceneTitle} from 'components/scene/SceneTitle';
import {Table} from 'schema';
import {useSchema} from 'schemas';
import {useSchemaReference} from 'schemas/hooks/useSchemaReference';
import {
  LinkedValueSearchProps,
  SearchMainEntityType,
  SearchResultValue,
} from './types';
import {SearchToolbar} from './SearchToolbar';
import {EntityPreview} from './components/EntityPreview';
import {ErrorMessage} from './components/ErrorMessage';
import {SearchEntitySelector} from './components/SearchEntitySelector';
import {
  EntitySearchContext,
  useEntitySearchContextProviderValue,
} from './context';
import {useTablePresentation} from './hooks';
import {useEntityDialogs} from './hooks/useEntityDialogs';
import {useSearchResultHeader} from './hooks/useSearchResultHeader';
import {useSearchStrings} from './hooks/useSearchStrings';

const contentContainerSx: SxProps = {
  pt: 1,
  my: 2,
};

export const LinkedValueSearchCore: React.FC<LinkedValueSearchProps> = ({
  fieldId,
  sourceValue,
  sourceConfig,
  onSetSourceValue,
  onClose,
}) => {
  const entitySearchState = useEntitySearchContextProviderValue(
    fieldId,
    sourceValue,
    sourceConfig,
    onSetSourceValue,
  );

  const {
    currentSourceConfig,
    state,
    searchQuery,
    searchStatus,
    searchEntityType,
    setSearchQuery,
    selectedMainEntity,
    setSelectedMainEntity,
    selectedSubEntity,
    setSelectedSubEntity,
    onNewEntitySaved,
    searchResult,
    pagination,
    highlights,
    loading,
    currentEntity,
    currentEntityType,
    gotoEntity,
  } = entitySearchState;

  const {title, placeholder} = useSearchStrings(
    sourceConfig?.titleKey,
    sourceConfig?.entity as SearchMainEntityType | undefined,
    sourceConfig?.entitySubtypes,
  );

  const schemaRef = useSchemaReference(searchEntityType);
  const schema = useSchema(schemaRef);
  const entityDialogConfig = useEntityDialogs(
    searchEntityType,
    undefined,
    onNewEntitySaved,
  );

  const {tableSchema, tableConfiguration, tableSearchResult} =
    useTablePresentation(searchEntityType, searchResult, schema, sourceConfig);

  const handleCustomAdd = useCallback(
    (entityType: LinkTargetConcreteEntityType) => {
      if (
        currentSourceConfig?.canEditEntity &&
        currentSourceConfig.canEditEntity(entityType)
      ) {
        const customAdd = assert(
          currentSourceConfig?.handleAddEntity,
          'LinkedValueSearch: handleAddEntity expected',
          {entityType},
        );
        return customAdd(entityType, searchQuery);
      }

      entityDialogConfig?.handleAdd &&
        entityDialogConfig.handleAdd(entityType, searchQuery);
    },
    [currentSourceConfig, entityDialogConfig, searchQuery],
  );

  const resultHeader = useSearchResultHeader(
    selectedMainEntity === Concept.work
      ? Concept.work
      : selectedSubEntity ?? Concept.person,
    pagination.total,
    // Only provide handleCustomAdd if handle add is set
    entityDialogConfig?.handleAdd ? handleCustomAdd : undefined,
  );

  const themeColor = ColorPalette.primary.white;

  return (
    <EntitySearchContext.Provider value={entitySearchState}>
      <Layout>
        <SceneTitle title={title}>
          <SearchToolbar onClose={onClose} />
        </SceneTitle>
        <Spacer height={1} />
        <SearchInput
          value={searchQuery}
          placeholder={placeholder}
          triggerFocus={currentSourceConfig}
          onChange={setSearchQuery}
        />
        <SearchEntitySelector
          selectedEntity={selectedMainEntity}
          onEntitySelected={setSelectedMainEntity}
          selectedSubtype={selectedSubEntity}
          onSubEntitySelected={setSelectedSubEntity}
          sx={{mt: 2, ml: 1}}
        />

        {state === 'search' ? (
          <Layout
            sx={[
              contentContainerSx,
              {
                px: 2,
                backgroundColor: themeColor,
                borderRadius: 1,
              },
            ]}
            flex={1}>
            {searchStatus === 'Failed' ? (
              <Layout
                sx={{
                  pb: 1,
                }}>
                <ErrorMessage
                  titleKey={`error.search.${
                    getEntityMainType(searchEntityType) as SearchMainEntityType
                  }.failedToLoad`}
                />
              </Layout>
            ) : (
              <Table<SearchResultValue>
                key={`${selectedMainEntity}-${selectedSubEntity}`}
                header={resultHeader}
                schema={tableSchema}
                data={tableSearchResult}
                loading={loading}
                onRowClick={gotoEntity}
                configuration={tableConfiguration}
                pagination={pagination}
                highlights={highlights}
              />
            )}
          </Layout>
        ) : state === 'viewEntity' ||
          state === 'browse' ||
          state === 'navigate' ? (
          <Layout sx={contentContainerSx} flex={1}>
            <EntityPreview
              key={currentEntity?.id}
              entityType={assert(currentEntityType)}
              entityId={currentEntity?.id?.toString()}
            />
          </Layout>
        ) : null}
      </Layout>
      {entityDialogConfig?.EntityDialog ? (
        <entityDialogConfig.EntityDialog />
      ) : null}
    </EntitySearchContext.Provider>
  );
};
