import {Collection} from '../types';
import {Schemas} from '../dto.generated';
import {
  mapCollectionTypeDto,
  mapToCollectionTypeDto,
} from './mapCollectionTypeDto';
import {mapWorkToSeriesRelationDto} from './mapRelationsDto';
import {sortCollectionLinks} from './sortLinks';

export const mapGetCollectionDto = (
  dto: Schemas.SeriesWithRelationsDto,
): Collection => {
  let links = (dto.relations ?? []).map(relationDto =>
    mapWorkToSeriesRelationDto(relationDto),
  );
  links = sortCollectionLinks(links);
  return {
    id: dto.id,
    collectionType: mapCollectionTypeDto(dto.seriesType),
    titles: dto.titles,
    note: dto.note,
    links,
  };
};

export const mapToUpsertCollectionDto = (
  collection: Collection,
): Schemas.CreateOrUpdateSeriesDto => {
  return {
    titles: collection.titles,
    seriesType: mapToCollectionTypeDto(collection.collectionType),
    note: collection.note,
  };
};
