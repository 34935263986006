import {useSelector} from 'react-redux';
import {ManifestationV4} from 'api/types';
import {metadataSelector} from '../selectors';

/**
 * Use unique manifestation, either by given id
 * or single manifestation on work
 */
export const useUniqueSavedManifestation = (
  manifestationId: string | undefined,
): ManifestationV4 | undefined => {
  const metadata = useSelector(metadataSelector);
  const manifestations = metadata.data?.manifestations ?? [];
  const manifestation = manifestationId
    ? manifestations.find(m => m.id === manifestationId)
    : manifestations.length === 1
    ? manifestations[0]
    : undefined;

  return manifestation;
};
