import {SxProps} from '@mui/material';
import {UXUnit} from 'theme/settings';
import {Layout} from 'components/layout';

export type DotProps = {
  color: string;
  sx?: SxProps;
};

const sxDot: SxProps = {
  height: `${0.75 * UXUnit}px`,
  width: `${0.75 * UXUnit}px`,
  borderRadius: 9999,
};

export const Dot: React.FC<DotProps> = ({color, sx}) => {
  const mergedSx = sx ? {...sxDot, ...sx} : sxDot;
  return <Layout sx={mergedSx} bgcolor={color} />;
};
