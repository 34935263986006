import {assert} from 'assert-ts';
import {ManifestationsMedia, Media} from '../types';
import {Schemas} from '../dto.generated';

export const mapMultipleManifestationMedia = (
  dto: Schemas.MediaForManifestationsDto,
): ManifestationsMedia[] => {
  return assert(dto.mediaForManifestations).map(mapManifestationMedia);
};

export const mapManifestationMedia = (
  dto: Schemas.MediaForManifestationDto,
): ManifestationsMedia => {
  const medias = assert(assert(dto.medias).map(mapMediaDto)) || [];

  return {
    manifestationId: assert(dto.manifestationId),
    medias: filterOnlyLatest(medias).sort(sortMedia).sort(sortMediaAttachment),
  };
};

export const mapSingleManifestationMedia = (
  dto: Schemas.MediaForManifestationsDto,
): Media[] => {
  const media =
    assert(assert(dto.mediaForManifestations)[0].medias).map(mapMediaDto) || [];

  return filterOnlyLatest(media).sort(sortMedia).sort(sortMediaAttachment);
};

const sortMedia = (a: Media, b: Media): number => {
  const order = ['CONTENT', 'COVER', 'ATTACHMENT', '', undefined];

  return order.indexOf(a.subType) > order.indexOf(b.subType) ? 1 : -1;
};

const sortMediaAttachment = (a: Media, b: Media): number => {
  if (a.subType === b.subType && a.subType === 'ATTACHMENT') {
    if (a.fileNr === b.fileNr) {
      return 0;
    }

    return a.fileNr < b.fileNr ? 1 : -1;
  }

  return 0;
};

export const filterOnlyLatest = (media: Media[]): Media[] => {
  media.sort((a: Media, b: Media) => {
    if (a.version > b.version) {
      return -1;
    }
    if (a.version < b.version) {
      return 1;
    }

    return 0;
  });

  return media.filter((value, index, self) => {
    if (value.subType === 'ATTACHMENT') {
      // Attachment has to check fileNr aswell
      const testAttachment = self.findIndex(
        m =>
          m.type === value.type &&
          m.subType === value.subType &&
          m.fileNr === value.fileNr,
      );

      if (index === testAttachment) {
        return true;
      }
    }

    const testOther = self.findIndex(
      m => m.type === value.type && m.subType === value.subType,
    );

    return index === testOther;
  });
};

export const mapMediaDto = (dto: Schemas.MediaDto): Media => {
  return {
    id: assert(dto.mediaId, 'mapMediaDto: mediaId expected', dto),
    version: dto.version ?? 1,
    type: assert(dto.mediaType, 'mapMediaDto: mediaType expected', dto),
    subType: assert(
      dto.mediaSubType,
      'mapMediaDto: mediaSubType expected',
      dto,
    ),
    extension: assert(
      dto.fileExtension,
      'mapMediaDto: fileExtension expected',
      dto,
    ),
    fileNr: parseInt(dto.filenumber ?? '0', 10),
  };
};
