import {useMemo} from 'react';
import {Agent} from 'api/types';
import {DataFormConfiguration} from 'schema/types';
import {useLocalization} from 'localization';
import {useDefaultAdornementResolver} from 'schema';
import {agentWorkSummaryTableConfig} from 'schemas/configurations/table';

export const useAgentFormConfiguration = (): Partial<
  DataFormConfiguration<Agent>
> => {
  const adornmentResolver = useDefaultAdornementResolver();
  const {t} = useLocalization();
  return useMemo(() => {
    const config: Partial<DataFormConfiguration<Agent>> = {
      parts: {
        works: {
          label: t('form.global.field.works.label'),
          empty: t('form.global.field.works.empty'),
          table: agentWorkSummaryTableConfig,
        },
        links: {
          label: t('form.global.field.links.label'),
          showWhenReadonlyAndEmpty: false,
        },
        gender: {
          layout: {
            width: '14em',
          },
        },
      },
      adornmentResolver,
      showWhenReadonlyAndEmpty: true,
    };

    return config;
  }, [adornmentResolver, t]);
};
