import {DataChanges} from 'services/data/metadata/types';

export const getManifestationHeaderTitle = (data?: DataChanges): string => {
  if (!data) return '';
  const manifestation = data.manifestation;

  const titles = (manifestation?.mainTitles || []).map(m => m.value);

  const fields = [
    ...titles,
    manifestation?.isbn,
    manifestation?.productForm,
    manifestation?.publishedYear,
  ].filter(f => !!f);

  return fields.join(' / ');
};
