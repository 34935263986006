import {useCallback, useEffect, useMemo, useState} from 'react';
import {FilterSet} from 'api/types';
import {Filter, LoadStatus} from 'api';
import {
  deleteSavedFilter,
  getSavedFilters,
  postSavedFilter,
} from 'api/advancedsearch';
import {useGetTokens} from 'services/auth';
import {FilterSetsType} from '../types';

export const useFilterSets = (): FilterSetsType => {
  const getTokens = useGetTokens();
  const [savedFilters, setSavedFilters] = useState<FilterSet[] | undefined>();

  const status: LoadStatus = useMemo(() => {
    return savedFilters === undefined ? 'Loading' : 'Loaded';
  }, [savedFilters]);

  const loadSavedFilters = useCallback(() => {
    getSavedFilters(getTokens).then(r => setSavedFilters(r));
  }, [getTokens]);

  const createFilter = useCallback(
    (name: string, filters: Filter[], isPublic: boolean) => {
      setSavedFilters(undefined);
      return postSavedFilter(getTokens, name, filters, isPublic).then(
        newSavedFilter => {
          loadSavedFilters();
          return newSavedFilter;
        },
      );
    },
    [getTokens, loadSavedFilters],
  );

  const deleteFilter = useCallback(
    (id: string) => {
      setSavedFilters(undefined);
      return deleteSavedFilter(getTokens, id).then(() => {
        loadSavedFilters();
      });
    },
    [getTokens, loadSavedFilters],
  );

  useEffect(() => {
    loadSavedFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => {
    return {
      filterSets: savedFilters ?? [],
      statusFilterSets: status,
      createFilterSet: createFilter,
      deleteFilterSet: deleteFilter,
    };
  }, [createFilter, deleteFilter, savedFilters, status]);
};
