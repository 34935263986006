import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import {CodeList, CodeListId} from 'api/types';
import {AppState} from 'store/types';
import {useRestrictedCodelistsContext} from '../contexts';
import {getDummyCodeList} from './getDummyCodeList';

const codeListStateSelector = (state: AppState) => state.codeLists;

export const useCodelist = (id: CodeListId): CodeList => {
  const {getRestrictedCodelist, getLocalCodelist} =
    useRestrictedCodelistsContext();
  const codeListState = useSelector(codeListStateSelector);
  return useMemo(() => {
    const codeList = codeListState[id]?.data ?? getLocalCodelist(id);
    if (assert.soft(codeList, 'useCodeList: Code list not loaded', {id})) {
      return getRestrictedCodelist(codeList);
    }
    return getDummyCodeList(id);
  }, [codeListState, getLocalCodelist, getRestrictedCodelist, id]);
};
