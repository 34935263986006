import {HttpError} from '../../types';
import {getShouldFail, resolveURI} from '../../functions';
import {HttpArgs} from '../httpMethod';
import {getMockByKey} from './getMockByKey';

const responseTime = 2 * 1000;

export const httpMock = <TResponse, TBody>({
  method,
  subdir,
  subdirParams,
  queryParams,
  body,
}: HttpArgs<TBody>): Promise<TResponse> => {
  return new Promise<TResponse>((resolve, reject) => {
    const shouldFail = getShouldFail(method, subdir);

    setTimeout(
      () => {
        const url = resolveURI('', subdir, subdirParams, queryParams);

        if (shouldFail) {
          reject(new HttpError(500, url, `${method}: mock failed `));
        }

        const fullKey = url
          .replace(/\//g, '.')
          .replace(/\?/g, '_')
          .replace(/[=[\]]/g, '-');

        const [key, valueKey] = fullKey.split('?');

        if (['PUT', 'DELETE', 'PATCH'].includes(method)) {
          resolve((getMockByKey(key, method) ?? body) as unknown as TResponse);
          return;
        }
        if (method === 'POST') {
          resolve(
            (getMockByKey(key, method) ?? {
              id: 'new',
              ...body,
            }) as unknown as TResponse,
          );
          return;
        }

        const data = getMockByKey(key, method);
        if (!data) {
          reject(new HttpError(404, url, 'mock not found', undefined, {key}));
          return;
        }

        if (valueKey) {
          if (typeof data !== 'object' || Array.isArray(data)) {
            reject(
              new HttpError(
                404,
                url,
                'mock must be object when using query parameters',
                undefined,
                {key, valueKey},
              ),
            );
            return;
          }

          const value = data[valueKey];
          if (value === undefined) {
            reject(
              new HttpError(
                404,
                url,
                'mock value not found for query parameters',
                undefined,
                {key, valueKey},
              ),
            );
            return;
          }

          resolve(value as TResponse);
          return;
        }

        resolve(data as TResponse);
      },
      method.toUpperCase() === 'GET' ? 500 : responseTime,
    );
  });
};
