import {Agent} from 'api/types';
import {Translate} from 'localization/context/types';
import {Matching} from '../types';

export const getSavedNationalAgentMessage = (
  originalAgent: Agent | undefined,
  savedAgent: Agent,
  matching: Matching,
  upsertBare: boolean,
  t: Translate,
) => {
  const originalHasNID = !!originalAgent?.nationalId;
  const savedHasNID = !!savedAgent.nationalId;

  // New
  if (!originalAgent) {
    switch (matching) {
      case 'NO_MATCH': {
        return savedHasNID
          ? t('wizard.agent.save.success.createdBBandCreatedInBARE')
          : upsertBare // Checked but failed
          ? t('wizard.agent.save.success.createdBBandFailedInBARE')
          : t('wizard.agent.save.success.createdBBNotInBare');
      }
      case 'MATCH': {
        return t('wizard.agent.save.success.createdBBwithNID');
      }
      // Search BARE failed, not able to select
      case 'FAILED': {
        return t('wizard.agent.save.success.createdBBNotInBare');
      }
    }
  }

  // Existing
  if (originalHasNID) {
    return originalAgent.nationalId === savedAgent.nationalId
      ? t('wizard.agent.save.success.updatedBBAndBARE')
      : t('wizard.agent.save.success.updatedBBwithOtherNID');
  }

  if (matching === 'NO_MATCH') {
    return savedHasNID
      ? t('wizard.agent.save.success.updatedBBandCreatedInBARE')
      : upsertBare // Checked but failed
      ? t('wizard.agent.save.success.updatedBBandFailedInBARE')
      : t('wizard.agent.save.success.updatedBBNotInBare');
  }

  if (matching === 'MATCH') {
    return t('wizard.agent.save.success.updatedBBwithNID');
  }

  // matching = FAILED
  return t('wizard.agent.save.success.updatedBBNotInBare');
};
