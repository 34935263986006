import {NodeCodeToIdsMap, NodeDuplicateIdsMap} from './types';

/**
 * Returns map of {nodeId: otherNodeId[]} for all codes with duplicates
 */
export const getAllNodeDuplicatesByIdMap = (
  codeToIdsMap: NodeCodeToIdsMap,
): NodeDuplicateIdsMap => {
  // Map to { id: otherid[] },  P Remove single [code: id] (no duplicate)
  const codes = Array.from(codeToIdsMap.keys());
  const duplicateIdsMap: NodeDuplicateIdsMap = new Map();
  codes.forEach(code => {
    const ids = codeToIdsMap.get(code);
    if (ids && ids.length > 1) {
      ids.forEach(id => {
        duplicateIdsMap.set(
          id,
          ids.filter(otherId => otherId != id),
        );
      });
    }
  });

  return duplicateIdsMap;
};
