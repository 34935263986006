export type PaginatorProps = {
  // Current page. Initial value is 1 (not 0)
  page: number;

  // Total number of pages (total / pageLimit rounded up to the closest integer)
  pages: number;

  // Decreases page by 1
  onSelectPrevious: () => void;

  // Bumps page by 1
  onSelectNext: () => void;

  // Optional text in front, ex Page. Ex.: Page X / Y
  preText?: string;
};

export type PageSizeProps = {
  // Number of items per page
  size: number;

  // Set number of items per page
  setSize: (size: number) => void;

  // What sizes the dropdown should allow
  pageSizes: Array<number>;

  // Total number of items
  total: number;

  // Adds total to pageSizes
  allowAll?: boolean;
};

export type PaginationState = Omit<PageSizeProps, 'allowAll'> &
  Omit<PaginatorProps, 'preText'> & {
    // Sets page back to initial value
    resetPage: (total: number, page?: number) => void;
  };

export const PAGINATION_PAGE_START = 1;
export const PAGESIZE_DEFAULT: PageSizeProps['pageSizes'] = [20, 50, 100, 200];
