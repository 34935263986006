import {useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import isEqual from 'lodash/isEqual';
import {ManifestationImportProps} from '../types';
import {manifestationExportsSelectorById} from '../selectors';
import {useCreateSelectorById} from './useCreateSelectorById';
import {useExpressionImportProps} from './useExpressionImportProps';

export const useManifestationImportProps = (
  manifestationId: string,
  expressionId: string,
): ManifestationImportProps => {
  const manifestationExportsSelector = useCreateSelectorById(
    manifestationId,
    manifestationExportsSelectorById,
  );
  const manifestationExports = useSelector(manifestationExportsSelector);
  const expressionImports = useExpressionImportProps(expressionId);
  const exportsRef = useRef<ManifestationImportProps>();

  // console.log('useManifestationImportProps.expressionId:', expressionId);
  const imports = useMemo(() => {
    const newExports = {
      ...expressionImports,
      manifestation: assert(
        manifestationExports,
        'useManifestationImportProps: unknown manifestationId',
      ),
    };

    if (!isEqual(newExports, exportsRef.current)) {
      exportsRef.current = newExports;
    }

    return assert(exportsRef.current);
  }, [expressionImports, manifestationExports]);

  return imports;
};
