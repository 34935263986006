import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import {ManifestationStatus} from 'api/types';
import {editStatusesSelector, statusesSelector} from '../selectors';

export const useStatusAndShowError = (
  id: string,
): {
  activeStatus: ManifestationStatus;
  status: ManifestationStatus;
  changedStatus?: ManifestationStatus;
  showError: boolean;
} => {
  const status = useSelector(statusesSelector)[id];
  const editStatus = useSelector(editStatusesSelector)[id];

  const result = useMemo(() => {
    const s = assert(status, 'useStatusAndShowError: no status');
    return {
      activeStatus: s.previewStatus ?? s.changedStatus ?? s.status,
      status: s.status,
      changedStatus: s.changedStatus,
      showError: !!(
        s.previewStatus ||
        s.changedStatus ||
        (editStatus?.isSaving ?? false)
      ),
    };
  }, [editStatus, status]);

  // useLogValue(`status.${id.substring(0, 5)}`, status);
  // useLogValue(`result.${id.substring(0, 5)}`, result);
  return result;
};
