import {useEffect, useRef, useState} from 'react';
import {HttpError} from 'api/http/types';
import {DataLoadStatus} from '../types';

/**
 * Handles loading of a list of resources (entities) by a list of ids for a given a load function,
 * i.e. returns list of resources with load stauts ('NotLoaded', 'Loading', 'Loaded', 'Failed')
 *
 * get - function to load list of resources from api by list of ids
 * returns list of resources with load status
 */
export const useGetResourceListByIds = <TData>(
  ids: string[] | undefined,
  get: (ids: string[]) => Promise<TData[]>,
): DataLoadStatus<TData[]> => {
  const [result, setResult] = useState<DataLoadStatus<TData[]>>({
    status: 'NotLoaded',
  });
  const idsRef = useRef(ids);

  useEffect(() => {
    if (result.status === 'NotLoaded' || idsRef.current !== ids) {
      idsRef.current = ids;
      if (ids === undefined) {
        setResult({status: 'NotLoaded'});
      } else if (ids.length === 0) {
        setResult({status: 'Loaded', data: []});
      } else {
        setResult(old => ({status: 'Loading', data: old.data}));
        get(ids)
          .then(data => setResult(() => ({status: 'Loaded', data})))
          .catch((error: HttpError) =>
            setResult(() => ({status: 'Failed', error})),
          );
      }
    }
  }, [ids, get, result.status]);

  return result;
};
