import {useMemo} from 'react';
import {SxProps} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import {Thesaurus} from 'api/types';
import {ColorPalette} from 'theme';
import {assertThesaurusNodeWithAncestors} from 'services/thesaurus/functions/assertThesaurusNodeWithAncestors';
import {NavigationButton} from 'components';
import {Layout} from 'components/layout';

type Props = {
  nodeId: string;
  thesaurus: Thesaurus;
  /**
   * Number of root levels to skip. Default 1, e.g. skip thesaurus root node
   */
  skipRootLevels?: number;
  onClick?: (nodeId: string) => void;
};

const Separator = (
  <Typography variant="body2" color={ColorPalette.burgundy}>
    {'/'}
  </Typography>
);

const breadcrumbSx: SxProps = {
  overflow: 'hidden',
  '& li': {
    overflow: 'hidden',
  },
};

const textSx: SxProps = {
  textAlign: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const ThesaurusNodeBreadcrumb: React.FC<Props> = ({
  nodeId,
  thesaurus,
  skipRootLevels = 1,
  onClick,
}) => {
  const {node, ancestorPath} = useMemo(() => {
    const nodeWithAncestors = assertThesaurusNodeWithAncestors(
      nodeId,
      thesaurus,
    );
    return {
      node: nodeWithAncestors.node,
      ancestorPath:
        // Skip thesaurus root node
        skipRootLevels > 0
          ? nodeWithAncestors.ancestors.slice(skipRootLevels)
          : nodeWithAncestors.ancestors,
    };
  }, [nodeId, skipRootLevels, thesaurus]);

  return (
    <Layout horizontal overflow={'hidden'}>
      <Breadcrumbs
        key={nodeId}
        maxItems={2}
        aria-label="breadcrumb"
        sx={breadcrumbSx}
        separator={Separator}>
        {ancestorPath.map(node =>
          onClick ? (
            <NavigationButton
              key={node.id}
              title={node.label}
              variant="body2"
              onClick={() => onClick(node.id)}
              titleSx={textSx}
              color={ColorPalette.burgundy75}
            />
          ) : (
            <Typography key={node.id} variant="body2" sx={textSx}>
              {node.label}
            </Typography>
          ),
        )}
        <Typography
          key={nodeId}
          variant="body2"
          color={ColorPalette.burgundy50}
          sx={textSx}>
          {node.label}
        </Typography>
      </Breadcrumbs>
    </Layout>
  );
};
