import {assert} from 'assert-ts';
import {
  CodeList,
  DataValue,
  PartCodeList,
  PartCodeListOrText,
  Valx,
} from 'schemaDefinition/types';
import {LinkedRoleCodeList} from 'schemaDefinition/linkTypes';
import {formatNullValue} from './formatNullValue';

export const formatCodeListValue = (
  value: DataValue,
  part: PartCodeList<Valx> | PartCodeListOrText<Valx>,
  codeList: CodeList | LinkedRoleCodeList | undefined,
): string => {
  if (
    value === undefined ||
    value === null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return formatNullValue();
  }

  switch (part.type) {
    case 'codelist': {
      const codes = Array.isArray(value) ? value : [value];
      return codes
        .map(code => {
          const formatted =
            (code
              ? // Either formatted value based on codelist or just code (will handle e.g. basedOnIsbn without local codelist)
                codeList?.codes?.find(item => item.code === code)?.value ?? code
              : undefined) ?? formatNullValue();
          return formatted;
        })
        .join(', ');
    }
    case 'codelist|text': {
      assert.soft(
        false,
        'formatCodeListValue: not implemented - codelist|text',
      );
      return 'Not implemented: ' + JSON.stringify(value);
    }
  }
};
