import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {User} from 'api/types';
import {AppState} from '../../../store/types';

const authSelector = (state: AppState) => state.auth;

/*
Example JWT token
{
  at_hash: "LoD9Y_eKsUnSnW7mTeHkjA",
  aud: "4ineo3fnvm5nhft337fiq07jm7",
  auth_time: 1678457351,
  cognito:groups: ['eu-north-1_UO8Wejjtm_onelogin'],
  cognito:username: "onelogin_harald@bokbasen.no",
  email: "harald@bokbasen.no",
  exp: 1678460951,
  given_name: "Harald",
  iat: 1678457352,
  identities: [
    {
      dateCreated:"1652459976260",
      issuer:"https://app.onelogin.com/saml/metadata/692a1467-462c-4a28-91ba-99e0b1e2fe8a",
      primary:"true",
      providerName:"onelogin",
      providerType:"SAML",
      userId:"harald@bokbasen.no"
    }
  ],
  iss:"https://cognito-idp.eu-north-1.amazonaws.com/eu-north-1_UO8Wejjtm",
  jti:"8be17ce4-1b1a-47b7-88bc-acdec78e4393",
  name:"Harald Tollefsen",
  nonce:"WlX1AJVdSkanax1sxqTEJ5CoAas2GilW69FqyuF-GoKTf4fVvMshx0y53AN7HC2j3bpiu7IXcWXUCSHdp27Pg6qmZwUlsn-ie5KrhDoyEsAmRxsbuuoMaW_-gE6F8sTfRF2lybbkM-gP6RJtf3x_OceM8-O6lFa6DQfMRVFvkws",
  origin_jti:"6cdcb5a4-94db-4139-b0ca-391bb90cf938",
  picture:"https://onelogin-assets-prod.s3.us-west-2.amazonaws.com/images/profile_service/profile_pictures/73dd9740-9643-11ec-ba92-932b2bfa3f36",
  sub:"347eb647-54c5-4d4a-b8c0-6176be3ac296",
  token_use:"id"
}
 */
export type IdToken = {
  'cognito:username': string;
  email: string;
  given_name: string;
  name: string;
  picture?: string;
};

export const useUser = (): User | null => {
  const auth = useSelector(authSelector);

  return useMemo(() => {
    if (auth.status === 'Authenticated') {
      return auth.user;
    }
    return null;
  }, [auth]);
};
