import {useMemo} from 'react';
import {Schema} from 'schemaDefinition/types';
import {CodeListId} from 'api';
import {getSchemaListCodelistIds} from 'schemaDefinition/functions';
import {LinkedRoleCodeListId} from 'schemaDefinition/linkTypes';

export const useSchemaListCodelistIds = <TVal>(
  schemas: Schema<TVal>[],
): (CodeListId | LinkedRoleCodeListId)[] => {
  return useMemo(() => getSchemaListCodelistIds(schemas), [schemas]);
};
