import {useCallback, useMemo, useState} from 'react';
import {AgentSubType, Concept} from 'types';
import {Agent, AgentName, AgentPerson} from 'api/types';
import {SaveAgentFunc} from 'services/data/agent';
import {EditAgentDialog} from '../components';

const createNewAgent = (type: AgentSubType): Agent => {
  switch (type) {
    case Concept.person:
      return {
        agentType: Concept.person,
        nameVariants: [] as AgentName[],
      } as AgentPerson;
    case Concept.event:
    case Concept.corporation:
    case Concept.publisher:
      return {
        agentType: type,
        nameVariants: [] as AgentName[],
      };
  }
};

/**
 * Takes a save function, and returns functions to edit and add an agent, with a
 * corresponding edit dialog component.
 * @param onSave function to save new or edited agent
 * @returns
 */
export const useEditAgentDialog = (onSave: SaveAgentFunc) => {
  const [originalAgent, setOriginalAgent] = useState<Agent>(
    createNewAgent(Concept.person),
  );
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleAddAgent = useCallback((type: AgentSubType) => {
    setOriginalAgent(createNewAgent(type));
    setDialogOpen(true);
  }, []);

  const handleEditAgent = useCallback((agent: Agent) => {
    setOriginalAgent(agent);
    setDialogOpen(true);
  }, []);

  const handleSaveAgent = useCallback(
    (agent: Agent) => {
      return onSave(agent, 'bbOnly').then(savedAgent => {
        setDialogOpen(false);
        return savedAgent;
      });
    },
    [onSave],
  );

  const Dialog = useCallback(() => {
    return (
      <EditAgentDialog
        isOpen={dialogOpen}
        originalAgent={originalAgent}
        onCancel={() => setDialogOpen(false)}
        onSave={handleSaveAgent}
      />
    );
  }, [dialogOpen, originalAgent, handleSaveAgent]);

  return useMemo(() => {
    return {
      handleAddAgent,
      handleEditAgent,
      EditAgentDialog: Dialog,
    };
  }, [Dialog, handleAddAgent, handleEditAgent]);
};
