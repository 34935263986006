import {createContext, useContext, useMemo} from 'react';
import {assert} from 'assert-ts';
import {LockProps} from './types';
import {useWorkEditState} from '../components/WorkCard/hooks/useWorkEditState';
import {useLockWorkOnChange} from '../hooks';

export type WorkEditStateContextType = ReturnType<typeof useWorkEditState> &
  LockProps;

export const WorkEditStateContext = createContext<
  WorkEditStateContextType | undefined
>(undefined);

export const useWorkEditStateContextProviderValue = (
  workId: string,
): WorkEditStateContextType => {
  const {readonly, lockProps} = useLockWorkOnChange(workId);
  const editState = useWorkEditState(readonly);

  return useMemo(() => ({...editState, ...lockProps}), [editState, lockProps]);
};

export const useWorkEditStateContext = (): WorkEditStateContextType => {
  return assert(
    useContext(WorkEditStateContext),
    'WorkEditStateContext: context expected',
  );
};

enum DetailTextType {
  text = 'text',
  textArea = 'textarea',
}

type GrepNode = {
  id: string;
  code?: string;
  label: string;
};

type GrepDetailsText = {
  type: DetailTextType;
  value: string[];
};

type GrepDetailsRelated = GrepNode;

type GrepDetailsValue = GrepDetailsText | GrepDetailsRelated[];

type GrepDetails = {
  [key: string]: GrepDetailsValue;
};

export type GrepInfo = GrepNode & {
  details: GrepDetails;
};
