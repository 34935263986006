import React from 'react';
import {FilledIconName, FilledIconProps} from './types';
import {CheckCircleFilledIcon} from './CheckCircleFilledIcon';
import {CheckRoundBackgroundIcon} from './CheckRoundBackgroundIcon';
import {CircleFilledIcon} from './CircleFilledIcon';
import {RoundBackgroundIcon} from './RoundBackgroundIcon';

type Props = FilledIconProps & {
  icon: FilledIconName;
};

const iconMap = {
  CheckCircle: CheckCircleFilledIcon,
  Circle: CircleFilledIcon,
  CheckRoundBackground: CheckRoundBackgroundIcon,
  RoundBackground: RoundBackgroundIcon,
};

export const FilledIconNames = Object.keys(iconMap) as FilledIconName[];

export const FilledIcon: React.FC<Props> = ({icon, ...props}) => {
  return iconMap[icon](props);
};
