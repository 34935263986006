import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useSelector, useStore} from 'react-redux';
import {HttpError} from 'api/http/types';
import {DataLoadStatus} from 'api/types';
import {AppState} from 'store/types';
import {makeLoadActionFactories} from '../functions';

/**
 * Wraps a redux based api resource and provides resource with load status
 * reload operation
 *
 * @param loadAction - action to dispatch to load resource
 * @param selector - selector to get resource from store
 * @param load - function to load resource from api
 * @returns resource with load status and reload operation
 */
export const useResource = <TActionType extends string, TData>(
  loadAction: TActionType,
  selector: (state: AppState) => DataLoadStatus<TData>,
  load: () => Promise<TData>,
): {
  resource: DataLoadStatus<TData>;
  reload: () => Promise<TData>;
} => {
  const {dispatch, getState} = useStore<AppState>();
  const resource = useSelector(selector);
  const resolveRef = useRef<(data: TData) => void>();
  const rejectRef = useRef<(error: HttpError) => void>();

  const reload = useCallback(() => {
    return new Promise<TData>((resolve, reject) => {
      const {status} = selector(getState());
      if (status !== 'Loading') {
        // console.log(loadAction, ': Loading resource...');
        const {makeRequestAction, makeSuccessAction, makeFailureAction} =
          makeLoadActionFactories<TData, TActionType>(loadAction);
        dispatch(makeRequestAction());
        load()
          .then(data => {
            // console.log(loadAction, ': direct load success', data);
            dispatch(makeSuccessAction(data));
            resolve(data);
          })
          .catch((error: HttpError) => {
            // console.log(loadAction, ': direct load failure', error);
            dispatch(makeFailureAction(error));
            reject(error);
          });
      } else {
        // console.log(loadAction, ': Resource already loading');
        resolveRef.current = resolve;
        rejectRef.current = reject;
      }
    });
  }, [dispatch, getState, load, loadAction, selector]);

  // Effect to resolve/reject reload when status updates
  useEffect(() => {
    if (resource.status === 'Failed' && rejectRef.current) {
      // console.log(loadAction, ': side reload failed');
      rejectRef.current(resource.error);
      resolveRef.current = undefined;
      rejectRef.current = undefined;
    } else if (resource.status === 'Loaded' && resolveRef.current) {
      // console.log(loadAction, ': side reload success');
      resolveRef.current(resource.data);
      resolveRef.current = undefined;
      rejectRef.current = undefined;
    }
  }, [loadAction, resource.data, resource.error, resource.status]);

  // Effect to load resource initially if needed
  useEffect(() => {
    const {status} = selector(getState());
    if (status === 'NotLoaded') {
      reload();
    }
    // Only on initial mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => ({resource, reload}), [reload, resource]);
};
