import {loadByIdReducer} from 'services/utils/redux/functions/loadByIdReducer';
import {CodeListActionType, CodeListsAction, CodeListsState} from '../types';

export const CodeListsDefaultState: CodeListsState = {};

export const codeListsReducer = (
  state: CodeListsState = CodeListsDefaultState,
  action: CodeListsAction,
): CodeListsState => {
  switch (action.type) {
    case CodeListActionType.LOAD_CODELIST: {
      return loadByIdReducer(state, action);
    }
    default: {
      return state;
    }
  }
};
