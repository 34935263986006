import {SxProps} from '@mui/material';
import {ChangeRequestAction, PartRef} from 'schemaDefinition/types';
import {ColorPalette} from 'theme';
import {FlexBox, Icon} from 'components';
import {CompareActionCompoent} from './types';
import {useDataFormContext} from '../contexts';
import {DataFormCompareAddValue} from './DataFormCompareAddValue';
import {DataFormCompareDeleteValue} from './DataFormCompareDeleteValue';
import {DataFormCompareReplaceValue} from './DataFormCompareReplaceValue';

const statusSx: SxProps = {color: ColorPalette.approve};

const actionMap: {
  [action in ChangeRequestAction]: CompareActionCompoent;
} = {
  replace: DataFormCompareReplaceValue,
  addItem: DataFormCompareAddValue,
  removeItem: DataFormCompareDeleteValue,
};

type Props = {
  valuePath: string;
  part?: PartRef;
  actions: ChangeRequestAction[];
};

export const DataFormCompareActions: React.FC<Props> = ({
  valuePath,
  part,
  actions,
}) => {
  const {id, useReviewStatus} = useDataFormContext();
  const status = useReviewStatus(valuePath, id);

  return !status || status === 'none' ? (
    !actions || actions.length === 0 ? (
      <DataFormCompareReplaceValue valuePath={valuePath} part={part} disabled />
    ) : (
      <FlexBox horizontal>
        {actions.map((action, idx) => {
          const ActionComp = actionMap[action];
          return <ActionComp key={idx} valuePath={valuePath} part={part} />;
        })}
      </FlexBox>
    )
  ) : (
    <FlexBox flex={1} center p={1}>
      <Icon icon="Check" sx={statusSx} />
    </FlexBox>
  );
};
