/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import {Stack} from '@mui/material';
import {SelectActionType} from 'feature/editThesaurus/types';
import {ShowThesaurusCode} from 'schemaDefinition/types';
import {NodeAndAncestors} from 'services/thesaurus/types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {ThesaurusId, ThesaurusNode} from 'api';
import {FlexBox, Layout} from 'components/layout';
import {Text} from 'components/text';
import {ThesaurusValue} from './ThesaurusValue';

type Props = {
  thesaurusId: ThesaurusId;
  nodeType: string;
  nodes: NodeAndAncestors[];
  readonly?: boolean;
  showCode?: ShowThesaurusCode;
  selectableNodeTypes?: string[];
  highlight: string[];
  highlightType?: SelectActionType;
  onDelete?: (nodeId: string) => void;
  onSelect?: (node: ThesaurusNode) => void;
};

const labelSx = {
  mb: 1,
  textTransform: 'none',
  color: ColorPalette.textSecondary,
} as const;

export const ThesaurusValueNodeType: React.FC<Props> = ({
  thesaurusId,
  nodeType,
  nodes,
  readonly,
  showCode,
  selectableNodeTypes,
  highlight,
  highlightType,
  onDelete,
  onSelect,
}) => {
  const {tLoose} = useLocalization();
  const typeLabel = tLoose(
    `thesaurus.${thesaurusId}.nodeType.${nodeType}.label`,
  );
  return readonly ? (
    <Layout adjustLeft pt={2}>
      <Text variant="label" sx={labelSx}>
        {typeLabel}
      </Text>
      <FlexBox gap={1}>
        {nodes.map(n => (
          <ThesaurusValue
            key={n.node.id}
            node={n.node}
            showCode={showCode}
            highlight={false}
            highlightType={highlightType}
            canMove={false}
          />
        ))}
      </FlexBox>
    </Layout>
  ) : (
    <Layout adjustLeft pt={2}>
      <Text variant="label" sx={labelSx}>
        {typeLabel}
      </Text>
      <Stack spacing={1} alignItems={'flex-start'}>
        {nodes.map(n => {
          const highlightValue = highlight.includes(n.node.id);
          return (
            <ThesaurusValue
              key={n.node.id}
              node={n.node}
              showCode={showCode}
              selectableNodeTypes={selectableNodeTypes}
              highlight={highlightValue}
              highlightType={highlightType}
              canMove={false}
              onDelete={onDelete}
              onSelect={onSelect}
            />
          );
        })}
      </Stack>
    </Layout>
  );
};
