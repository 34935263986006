import dayjs from 'dayjs';
import {Concept} from 'types';
import {DateType, NationalAgent} from 'api';
import {Builder} from 'services/utils';
import {formatNameVariantValue} from 'schema/preview/functions';
import {AgentQuery} from '../types';

export const getAgentQuery = (agent: NationalAgent): AgentQuery => {
  if (!agent.nameVariants || agent.nameVariants.length === 0) {
    return {name: '', other: ''};
  }

  const mainNameForm = agent.nameVariants[0];

  const name = mainNameForm
    ? formatNameVariantValue(
        agent.nameVariants[0],
        agent.agentType,
        'excludeId',
      )
    : '';
  const other =
    agent.agentType === Concept.person
      ? new Builder(getPartialYearSpan(agent.birth, agent.death)).value
      : '';

  return {name, other};
};

const getYear = (date: DateType | undefined): number | undefined => {
  if (!date) {
    return undefined;
  }
  if (date.year) {
    return date.year;
  }
  if (date.date) {
    return dayjs(date.date).year();
  }
  return undefined;
};

const getPartialYearSpan = (
  from: DateType | undefined,
  to: DateType | undefined,
): string | undefined => {
  const fromYear = getYear(from);
  const toYear = getYear(to);
  if (fromYear && toYear) {
    return `${fromYear}–${toYear}`;
  }

  if (fromYear) {
    return `${fromYear}-`;
  }

  if (toYear) {
    return `${toYear}`;
  }

  return undefined;
};
