import {FullManifestation} from '../types';
import {Schemas} from '../dto.generated';
import {mapManifestationWithExpressionDto} from './mapManifestationWithExpressionDto';

export const mapManifestationsDto = (
  dto: Schemas.ManifestationsDto,
): FullManifestation[] => {
  return (
    dto.manifestations?.map(m => mapManifestationWithExpressionDto(m)) || []
  );
};
