import {assert} from 'assert-ts';
import {GetTokens, Tokens} from '../../types';
import {
  HttpError,
  ProblemConstraintViolation,
  QueryParams,
  SubdirParams,
} from '../types';
import {Configuration} from '../../../configuration';
import {getLocale} from '../../../localization';
import {resolveURI} from '../functions';


type Headers = {[key: string]: string};

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export type HttpArgs<TBody = unknown> = {
  method: 'GET' | 'PATCH' | 'POST' | 'PUT' | 'DELETE';
  subdir: string;
  subdirParams?: SubdirParams;
  queryParams?: QueryParams;
  body?: TBody | undefined;
  extraHeaders?: Headers | undefined;
  getTokens?: GetTokens | undefined;
};

type HttpCallArgs = Omit<
  HttpArgs,
  'getTokens' | 'body' | 'subdir' | 'subdirParams' | 'queryParams'
> & {
  url: string;
  bodyJson?: string;
  accessToken?: string;
  idToken?: string;
};

export const httpMethod = <TResponse, TBody = TResponse>(
  args: HttpArgs<TBody>,
): Promise<TResponse> => {
  const {
    method,
    subdir,
    subdirParams,
    queryParams,
    body,
    extraHeaders = {},
    getTokens,
  } = args;

  const baseUri = Configuration.api.baseUri;
  assert(baseUri, 'httpMethod: baseUrl must be given', args);
  assert(subdir, 'httpMethod: subdir must be given', args);

  const url = resolveURI(baseUri, subdir, subdirParams, queryParams);

  let bodyJson: string | undefined;
  if (body) {
    bodyJson = JSON.stringify(body);
  }

  const locale = getLocale();
  if (locale) {
    extraHeaders['Accept-Language'] = locale;
  }

  if (getTokens) {
    return getTokens().then((tokens: Tokens) => {
      return httpCall({
        method,
        url,
        bodyJson,
        extraHeaders,
        accessToken: tokens.accessToken,
        idToken: tokens.idToken,
      });
    });
  } else {
    return httpCall({
      method,
      url,
      bodyJson,
      extraHeaders,
    });
  }
};

const httpCall = <TData>(args: HttpCallArgs): Promise<TData> => {
  const {accessToken, idToken, extraHeaders, url, method, bodyJson} = args;

  const headers =
    accessToken && idToken
      ? {
          ...defaultHeaders,
          Authorization: accessToken,
          'Authentication-Info': idToken,
          ...extraHeaders,
        }
      : {...defaultHeaders, ...extraHeaders};

  const parseResponseAsBlob = [
    'application/csv',
    'application/vnd.openxmlformats-officedocument.spreatsheetml.sheet',
  ].includes(headers['Accept']);

  return fetch(url, {
    method,
    body: bodyJson,
    headers,
  })
    .then(async response => {
      if (response.ok) {
        // No content
        if (response.status === 204) {
          return null as unknown as TData;
        }

        // TEMP FIX because DELETE /advancedsearch/filters/saved returns HTTP 200
        if (method === 'DELETE') {
          return null as unknown as TData;
        }

        return parseResponseAsBlob
          ? ((await response.blob()) as TData)
          : ((await response.json()) as TData);
      } else {
        // Try get more error info
        let errorInfo: ProblemConstraintViolation | undefined = undefined;
        try {
          errorInfo = await response.json();
        } catch (jsonError) {
          console.log(
            `HTTP ${method}: status: ${response.status}: url: ${url}, could not get json response`,
          );
        }

        throw new HttpError(
          response.status,
          url,
          errorInfo?.title ??
            `HTTP ${method} failed: status: ${response.status}`,
          undefined,
          errorInfo,
          undefined,
        );
      }
    })
    .catch(error => {
      if (error instanceof HttpError) {
        console.error(
          `HTTP ${method} ${url} failed: ${error.status ?? 'unknown'}: ${
            error.message ?? ''
          }`,
          error.status ?? undefined,
          error.message,
          method,
          url,
          bodyJson,
        );
      } else {
        console.error(
          'HTTP request failed ',
          error.status ?? undefined,
          error.errorKey,
          error.message,
          method,
          url,
          bodyJson,
        );
      }

      throw error;
    });
};
