import {useMemo} from 'react';
import {ExpressionV4} from 'api';
import {ExpressionExportProps} from '../types';

export const useExpressionExports = (
  expression: Pick<ExpressionV4, 'expressionFormat'>,
): ExpressionExportProps => {
  return useMemo(
    () => ({
      expressionFormat: expression.expressionFormat,
    }),
    [expression.expressionFormat],
  );
};
