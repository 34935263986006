import {HighlightsDto} from '../../../searchTypes';

export const mapToHighlightsDto = (
  highlightFields: string[],
): HighlightsDto => {
  return (highlightFields ?? []).reduce(
    (ac, a) => ({...ac, [a]: {} || {}}),
    {},
  );
};
