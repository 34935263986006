import React, {useCallback, useMemo} from 'react';
import {CollectionSubType, Concept} from 'types';
import {Collection} from 'api/types';
import {TranslationKey} from 'localization/context/types';
import {useLocalization} from 'localization';
import {SaveCollectionFunc} from 'services/data/collection';
import {useSnacks} from 'components';
import {DataFormDialogV1} from 'schema/form/components/DataFormDialogV1';
import {DataFormConfigurationContextType} from 'schema/form/contexts';
import {useSchema} from 'schemas';
import {useLinkedValueSearchContextProvider} from 'sceneExtensions';

type Props = {
  mode: 'add' | 'edit';
  isOpen: boolean;
  /**
   * Original value of the collection: when new collection, this will just contain the type,
   * otherwise it will contain the whole collection
   * If isOpen, then originalCollection must be set
   **/
  originalCollection?: Collection;
  onSave: SaveCollectionFunc;
  onCancel: () => void;
};

const addTitleMap: {[key in CollectionSubType]: TranslationKey} = {
  series: 'page.collection.add.dialog.series.title',
  educationalSeries: 'page.collection.add.dialog.educationalSeries.title',
  otherConnection: 'page.collection.add.dialog.otherConnection.title',
  publisherSeries: 'page.collection.add.dialog.publisherSeries.title',
} as const;

const editTitleMap: {[key in CollectionSubType]: TranslationKey} = {
  series: 'page.collection.edit.dialog.series.title',
  educationalSeries: 'page.collection.edit.dialog.educationalSeries.title',
  otherConnection: 'page.collection.edit.dialog.otherConnection.title',
  publisherSeries: 'page.collection.edit.dialog.publisherSeries.title',
} as const;

export const EditCollectionDialog: React.FC<Props> = ({
  mode,
  isOpen,
  originalCollection,
  onSave,
  onCancel,
}) => {
  const {t} = useLocalization();
  const {successSnack, errorSnack} = useSnacks();
  const LinkedValueSearchContext = useLinkedValueSearchContextProvider();
  const collectionSubtype: CollectionSubType =
    originalCollection?.collectionType ?? Concept.series;

  const collectionFormConfiguration =
    useMemo((): DataFormConfigurationContextType => {
      const config: DataFormConfigurationContextType = {
        schemaContainers: {
          titles: 'NoContainer',
        },
        parts: {
          value: {
            layout: {
              flex: 1,
            },
          },
          language: {
            layout: {
              flex: 1,
            },
          },
        },
      };
      return config;
    }, []);
  const schema = useSchema({
    entity: Concept.collection,
    subType: collectionSubtype,
  });

  const handleSaveCollection = useCallback(
    (data: Collection) => {
      return onSave(data)
        .then(() => {
          successSnack(t(`page.collection.${collectionSubtype}.save.success`));
        })
        .catch(() => {
          errorSnack(t(`page.collection.${collectionSubtype}.save.failed`));
        });
    },
    [collectionSubtype, errorSnack, onSave, successSnack, t],
  );

  return (
    <LinkedValueSearchContext>
      <DataFormDialogV1
        isOpen={isOpen}
        title={t(
          mode === 'add'
            ? addTitleMap[collectionSubtype]
            : editTitleMap[collectionSubtype],
        )}
        originalData={originalCollection}
        schema={schema}
        configuration={collectionFormConfiguration}
        onSave={handleSaveCollection}
        onCancel={onCancel}
      />
    </LinkedValueSearchContext>
  );
};
