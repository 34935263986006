import {AvailableFilter} from '../types';
import {Schemas} from '../dto.generated';

export const mapAvailableFilters = (
  dto: Schemas.AvailableFilter[],
): AvailableFilter[] => {
  return dto.map(mapAvailableFilter);
};

export const mapAvailableFilter = (
  dto: Schemas.AvailableFilter,
): AvailableFilter => {
  return {
    ...dto,
    codelistId: dto.codelistId as Schemas.CodelistDto['id'],
  };
};
