import {useCallback, useMemo} from 'react';
import uniq from 'lodash/uniq';
import {FilterOperation, useGetResource} from 'api';
import {getAvailableFilters} from 'api/advancedsearch';
import {useGetTokens} from 'services/auth';
import {AvailableFiltersType} from '../types';
import {useDataTranslations} from './useDataTranslations';

export const useAvailableFiltersWithLabel = (): AvailableFiltersType => {
  const getTokens = useGetTokens();
  const handleGetAvailableFilters = useCallback(
    () => getAvailableFilters(getTokens),
    [getTokens],
  );
  const {status, data} = useGetResource(handleGetAvailableFilters);
  const {getLabel} = useDataTranslations();

  const availableFiltersWithLabel = useMemo(() => {
    return data
      ?.map(d => {
        const operations: FilterOperation[] =
          d.type === 'DATE' ? ['BETWEEN', ...d.operations] : d.operations;

        return {
          ...d,
          operations: uniq(operations),
          label: getLabel(d.name),
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [data, getLabel]);

  return useMemo(() => {
    return {
      availableFilters: availableFiltersWithLabel ?? [],
      statusAvailableFilters: status,
    };
  }, [availableFiltersWithLabel, status]);
};
