import {QueryParams, SubdirParams} from '../types';
import {encodeURIParams} from './encodeURIParams';
import {expandURIParamPlaceholders} from './expandURIParamPlaceholders';

export const resolveURI = (
  baseUrl: string,
  subdir: string,
  subdirParams?: SubdirParams,
  queryParams?: QueryParams,
): string => {
  const sub = expandURIParamPlaceholders(subdir, subdirParams);
  const params = queryParams ? encodeURIParams(queryParams) : undefined;
  return params ? `${baseUrl}${sub}?${params}` : `${baseUrl}${sub}`;
};
