import {CodeList, CodeListRef} from 'api/types';
import {ManifestationImportProps} from 'services/data/metadata/types';
import {isMerchProductForm, isNullish} from 'services/utils';

export function getRestrictedProductFormCodelistByWorkType(
  {work: {type}}: ManifestationImportProps,
  codelist: CodeList,
): CodeList {
  return isNullish(type)
    ? codelist
    : type === CodeListRef.WORK_TYPE.Vare
    ? {
        ...codelist,
        codes: codelist.codes.map(item =>
          isMerchProductForm(item.code) ? item : {...item, disabled: true},
        ),
      }
    : {
        ...codelist,
        codes: codelist.codes.map(item =>
          !isMerchProductForm(item.code) ? item : {...item, disabled: true},
        ),
      };
}
