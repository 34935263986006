import {ThesaurusNode} from 'api/types';

/**
 * Will only match the first node with the given code or id
 */
export const findNodeWithAncestors = (
  codeOrId: string,
  node: ThesaurusNode,
  ancestors: ThesaurusNode[] = [],
): {node: ThesaurusNode; ancestors: ThesaurusNode[]} | undefined => {
  if (node.code === codeOrId || node.id === codeOrId) {
    return {node, ancestors};
  }

  if (node.children) {
    const subAncestors = [...ancestors, node];
    for (const child of node.children) {
      const subNode = findNodeWithAncestors(codeOrId, child, subAncestors);
      if (subNode) {
        return subNode;
      }
    }
  }

  return undefined;
};
