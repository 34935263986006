import React, {SyntheticEvent} from 'react';
import {assert} from 'assert-ts';
import {Layout, Thesaurus} from 'components';
import {useThesaurusEditStateContext} from '../contexts';

export const TopLevels: React.FC = () => {
  const {
    thesaurus,
    getDisplayCode,
    gotoCategory,
    categoryId,
    thesaurusEditValue,
  } = useThesaurusEditStateContext();

  assert(
    thesaurus.children,
    'ThesaurusTopLevels: children expected',
    thesaurus,
  );

  return (
    <Layout flex={1}>
      <Thesaurus
        onlyTopLevel
        thesaurusValue={thesaurusEditValue}
        getDisplayCode={getDisplayCode}
        data={thesaurus.children ?? []}
        focusedNodeId={categoryId}
        onNodeSelect={(event: SyntheticEvent, nodeIds: string | string[]) => {
          if (typeof nodeIds === 'string') {
            gotoCategory(nodeIds, nodeIds);
          }
        }}
      />
    </Layout>
  );
};
