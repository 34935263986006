import {JSONValue} from '../types';
import {AgentSchemas} from './avro-agent.generated';
import {CatalogSchemas} from './avro-catalog.generated';
import {SeriesSchemas} from './avro-series.generated';

export type SearchWorkDto = CatalogSchemas.CatalogKafkaEntryDto;
export type SearchAgentDto = AgentSchemas.AgentKafkaEntryDto;
export type SearchCollectionDto = SeriesSchemas.SeriesKafkaEntryDto;

export type HighlightsDto = JSONValue;

// -- Elastic
export const HighlightPreTag = 'HIGHLIGHT_START';
export const HighlightPostTag = 'HIGHLIGHT_END';

// Search indexes
export type SearchType = 'catalog' | 'agent' | 'collection';

export type ElasticCloudAuthLambda = {
  target: '_search' | '_msearch' | '_async_search' | '_terms_enum';
  indexes: string[];
  elasticBody: JSONValue;
};

export type SearchResult<T> = {
  hits: T[];
  highlights: string[];
  total: number;
};

export type SearchResultDto<Source> = {
  _id: string;
  took: number;
  timed_out: boolean;
  _shards: ShardsDto;
  hits: HitsDto<Source>;
};

export type ShardsDto = {
  total: number;
  successful: number;
  skipped: number;
  failed: number;
};

export type HitsDto<Source> = {
  total: {
    value: number;
    relation: string;
  };
  max_score: number;
  hits: HitDto<Source>[];
};

export type HighlightDto = Record<string, string[]>;

export type HitDto<Source> = {
  _index: Index;
  _id: string;
  _score: number;
  _source: Source;
  highlight?: HighlightDto;
};

export type Index =
  | 'dev-agent-cms'
  | 'dev-catalog-cms'
  | 'dev-search-series'
  | 'prod-search-agent'
  | 'prod-search-catalog'
  | 'prod-search-series';
