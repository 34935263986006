import {Schemas} from 'api/dto.generated';
import {CodeListRef} from '../../../../types';

export const collections: {[id: string]: Schemas.SeriesWithRelationsDto} = {
  1: {
    id: '1',
    titles: [{value: 'Series 1', language: CodeListRef.LANGUAGE['Bokmål']}],
    seriesType: 'SERIES',
    relations: [],
  },
  2: {
    id: '2',
    titles: [{value: 'Series 2', language: CodeListRef.LANGUAGE['Bokmål']}],
    seriesType: 'SERIES',
    relations: [],
  },
  3: {
    id: '3',
    titles: [{value: 'Læreverk', language: CodeListRef.LANGUAGE['Bokmål']}],
    seriesType: 'EDUCATIONAL_SERIES',
    relations: [],
  },
  4: {
    id: '4',
    titles: [{value: 'Læreverk', language: CodeListRef.LANGUAGE['Bokmål']}],
    seriesType: 'EDUCATIONAL_SERIES',
    relations: [],
  },
  5: {
    id: '5',
    titles: [{value: 'Forlag 1', language: CodeListRef.LANGUAGE['Bokmål']}],
    seriesType: 'PUBLISHER_SERIES',
    relations: [],
  },
};
