import {useCallback} from 'react';
import {deleteManifestation} from 'api';
import {useGetTokens} from 'services/auth';

export const useMetadataDeleteOperations = () => {
  const getTokens = useGetTokens();
  const handleDeleteManifestation = useCallback(
    (manifestationId: string, mock?: boolean) => {
      return deleteManifestation(manifestationId, getTokens, mock);
    },
    [getTokens],
  );
  return {
    deleteManifestation: handleDeleteManifestation,
  };
};
