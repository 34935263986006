import {assert} from 'assert-ts';
import {CollectionSubType, Concept} from 'types';
import {CollectionTypeDto} from 'api/types';
import {SeriesSchemas} from '../avro-series.generated';
import {reverseMap} from './reverseMap';

const collectionTypeMap: {
  [dto in CollectionTypeDto]: CollectionSubType;
} = {
  SERIES: Concept.series,
  EDUCATIONAL_SERIES: Concept.educationalSeries,
  OTHER_CONNECTION: Concept.otherConnection,
  PUBLISHER_SERIES: Concept.publisherSeries,
};

export const mapSearchCollectionTypeDto = (
  dto: SeriesSchemas.SeriesTypeDto,
): CollectionSubType => collectionTypeMap[dto];

export const mapCollectionTypeDto = (
  dto: CollectionTypeDto,
): CollectionSubType => collectionTypeMap[dto];

export const mapToCollectionTypeDto = (
  collectionType: CollectionSubType,
): CollectionTypeDto =>
  assert(
    reverseMap(collectionType, collectionTypeMap),
    'mapToCollectionTypeDto',
    {collectionType},
  );
