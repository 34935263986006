import {assert} from 'assert-ts';
import orderBy from 'lodash/orderBy';
import {CodeList, CodeListMap, ExpressionV4} from 'api';
import {getCodeSortValue} from 'services/codeLists/functions';

const getLanguagesSortValue = (
  languages: string[] | undefined,
  codelist: CodeList,
): string => {
  const sortValue =
    languages?.map(l => getCodeSortValue(l, codelist)).join('/') ??
    getCodeSortValue(undefined, codelist);
  return sortValue;
};

type ExpressionOrderByProps = {
  languages?: ExpressionV4['languages'];
  expressionFormat?: ExpressionV4['expressionFormat'];
};

export const orderExpressionsByProps = <T extends ExpressionOrderByProps>(
  unordered: T[],
  codelists: CodeListMap,
): T[] => {
  const languageCodeList = assert(
    codelists.LANGUAGE,
    'orderExpressionsByProps: codelists.language expected',
  );
  const formatCodelist = assert(
    codelists.EXPRESSION_FORMAT,
    'orderExpressionsByProps: codelists.EXPRESSION_FORMAT expected',
  );

  return orderBy(unordered, [
    e => getLanguagesSortValue(e.languages, languageCodeList),
    e => getCodeSortValue(e.expressionFormat, formatCodelist),
  ]);
};
