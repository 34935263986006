import {useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import isEqual from 'lodash/isEqual';
import {WorkImportProps} from '../types';
import {workExportsSelector} from '../selectors';

export const useWorkImportProps = (): WorkImportProps => {
  const workExports = useSelector(workExportsSelector);
  const exportsRef = useRef<WorkImportProps>();

  return useMemo(() => {
    const newExports = {
      work: workExports,
    };

    if (!isEqual(newExports, exportsRef.current)) {
      exportsRef.current = newExports;
    }

    return assert(exportsRef.current);
  }, [workExports]);
};
