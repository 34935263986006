import {Part} from 'schemaDefinition/types';
import {PartDiffProps} from '../components/types';

export const resolveNestedItemDiff = (
  diff: PartDiffProps,
  index: number,
  /** Part to resolve nested diff for */
  part: Part<unknown>,
  updateForItem: boolean | undefined,
): PartDiffProps | undefined => {
  // If original, no need to resolve nested diff
  // if (diff.mode === 'original') {
  //   return diff;
  // }

  return {
    mode: diff.mode,
    compareValue: Array.isArray(diff.compareValue)
      ? diff.compareValue[index] ?? null
      : null,
    outerCompare: updateForItem ? 'fullValue' : diff.outerCompare,
  };
};
