import assert from 'assert-ts';
import isEqual from 'lodash/isEqual';
import {Concept} from 'types';
import {EntityContainer, Metadata, MetadataEditState} from '../types';
import {cleanEntityContainer} from './cleanEntityContainer';
import {getPotentialChangedEntities} from './getPotentialChangedEntities';

export const getChangedEntities = (
  editState: MetadataEditState,
): EntityContainer[] | undefined => {
  const {metadata, savedMetadata} = editState;

  if (metadata) {
    const potentialChangedEntities = getPotentialChangedEntities(editState);

    const cleanedEntityContainers = potentialChangedEntities?.map(entity =>
      cleanEntityContainer(entity, metadata),
    );

    return cleanedEntityContainers?.filter(c =>
      isEntityChanged(c, savedMetadata),
    );
  }
};

const isEntityChanged = (
  entityContainer: EntityContainer,
  savedMetadata: Metadata | undefined,
): boolean => {
  let entity = undefined;
  switch (entityContainer.type) {
    case Concept.work:
      assert(savedMetadata?.work, 'isEntityChanged: work expected');
      return !isEqual(entityContainer.data, savedMetadata?.work);
    case Concept.expression:
      entity = savedMetadata?.expressions.find(
        e => e.id === entityContainer.data.id,
      );
      assert(entity, 'isEntityChanged: expression expected', {
        entityContainer: entityContainer,
        savedMetadataExpressions: savedMetadata?.expressions,
      });
      return !isEqual(entityContainer.data, entity);
    case Concept.manifestation:
      entity = savedMetadata?.manifestations.find(
        m => m.id === entityContainer.data.id,
      );
      assert(entity, 'isEntityChanged: manifestations expected', {
        entityContainer: entityContainer,
        savedMetadataManifestations: savedMetadata?.manifestations,
      });
      return !isEqual(entityContainer.data, entity);
    case Concept.changeRequest:
      entity = savedMetadata?.changeRequests.find(
        c => c.id === entityContainer.data.id,
      );
      return !isEqual(entityContainer.data, entity);
  }
};
