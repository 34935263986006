import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const CommentsFilledIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <svg width="14" height="12" x="5" y="5" viewBox="0 0 14 12" fill="none">
        <rect width="14" height="12" rx="2" fill="#FFEEB1" />
      </svg>
      <path
        d="M4 19.76V7C4 6.20435 4.31607 5.44129 4.87868 4.87868C5.44129 4.31607 6.20435 4 7 4H17C17.7956 4 18.5587 4.31607 19.1213 4.87868C19.6839 5.44129 20 6.20435 20 7V14.5C20 15.2956 19.6839 16.0587 19.1213 16.6213C18.5587 17.1839 17.7956 17.5 17 17.5H7.79L4 19.76ZM7 6C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7V16.24L7.24 15.5H17C17.2652 15.5 17.5196 15.3946 17.7071 15.2071C17.8946 15.0196 18 14.7652 18 14.5V7C18 6.73478 17.8946 6.48043 17.7071 6.29289C17.5196 6.10536 17.2652 6 17 6H7Z"
        fill="#480905"
      />
    </SvgIcon>
  );
};
