import {useEffect, useRef, useState} from 'react';
import {assert} from 'assert-ts';
import {AgentSubType, CollectionSubType, Concept, EntityMainType} from 'types';
import {GetTokens} from 'api/types';
import {
  DataLoadStatus,
  HTTP_STATUS_ABORTED,
  HttpError,
  searchAgents,
} from 'api';
import {searchSeries} from 'api/searchSeries';
import {SearchResult} from 'api/searchTypes';
import {searchWork} from 'api/searchWork';
import {useGetTokens} from 'services/auth';
import {PaginationState} from '../../../components/paginator/types';
import {
  SearchMainEntityType,
  SearchResultValue,
  SearchSubEntityType,
} from '../types';
import {trimSearchQuery} from '../functions';

// const hasSameItems = <T>(a: T[], b: T[]) =>
//   a.length === b.length &&
//   a.every(aItem => b.includes(aItem)) &&
//   b.every(bItem => a.includes(bItem));

type SearchLoadStatus<T> = DataLoadStatus<T> & {
  page: number;
};

export const EMPTY_RESULT: SearchResult<SearchResultValue> = {
  hits: [],
  highlights: [],
  total: 0,
};
export const DEFAULT_RESULT: SearchLoadStatus<SearchResult<SearchResultValue>> =
  {
    status: 'Loaded',
    data: EMPTY_RESULT,
    page: 1,
  };

type SearchEntityFunc = (
  search: string,
  subtypes: SearchSubEntityType[] | undefined,
  page: number,
  size: number,
  getTokens: GetTokens,
  signal: AbortSignal,
) => Promise<SearchResult<SearchResultValue>>;

const searchEntityMap: {[type in SearchMainEntityType]: SearchEntityFunc} = {
  [Concept.agent]: (
    search: string,
    subtypes: SearchSubEntityType[] | undefined,
    page: number,
    size: number,
    getTokens: GetTokens,
    signal: AbortSignal,
  ) =>
    searchAgents(
      search,
      (subtypes ?? []) as AgentSubType[],
      page,
      size,
      getTokens,
      signal,
    ),
  [Concept.collection]: (
    search: string,
    subtypes: SearchSubEntityType[] | undefined,
    page: number,
    size: number,
    getTokens: GetTokens,
    signal: AbortSignal,
  ) =>
    searchSeries(
      search,
      (subtypes ?? []) as CollectionSubType[],
      page,
      size,
      getTokens,
      signal,
      false,
    ),
  [Concept.work]: (
    search: string,
    _subtypes: SearchSubEntityType[] | undefined,
    page: number,
    size: number,
    getTokens: GetTokens,
    signal: AbortSignal,
  ) => searchWork(search, page, size, getTokens, signal),
};

export const useEntitySearch = (
  searchQuery: string,
  entityType: SearchMainEntityType,
  subtype: SearchSubEntityType | undefined,
  pagination: PaginationState,
): SearchLoadStatus<SearchResult<SearchResultValue>> => {
  const [result, setResult] =
    useState<SearchLoadStatus<SearchResult<SearchResultValue>>>(DEFAULT_RESULT);
  const searchQueryRef = useRef<string | undefined>();
  const pageRef = useRef<number | undefined>();
  const sizeRef = useRef<number | undefined>();
  const entityTypeRef = useRef<EntityMainType>(entityType);
  const subtypeRef = useRef<SearchSubEntityType | undefined>(subtype);
  const abortControllerRef = useRef<AbortController | undefined>();

  const {page, size, resetPage} = pagination;

  const getTokens = useGetTokens();

  // When search query or entity type/subtype is changed
  // => abort any ongoing search, search with new criteria
  useEffect(() => {
    if (
      searchQuery === searchQueryRef.current &&
      page === pageRef.current &&
      size === sizeRef.current &&
      entityType === entityTypeRef.current &&
      subtype === subtypeRef.current
    ) {
      return;
    }

    // Abort any ongoing call
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = undefined;
    }

    if (!searchQuery) {
      setResult(DEFAULT_RESULT);
      return;
    }

    if (
      entityType === entityTypeRef.current &&
      subtype === subtypeRef.current
    ) {
      setResult(prev => ({
        ...prev,
        status: 'Loading',
      }));
    } else {
      setResult({
        status: 'Loading',
        page,
      });
    }

    const reset =
      searchQuery !== searchQueryRef.current ||
      entityType !== entityTypeRef.current ||
      subtype !== subtypeRef.current;

    const newPage = reset ? 1 : page;

    abortControllerRef.current = new AbortController();
    searchQueryRef.current = searchQuery;
    pageRef.current = newPage;
    sizeRef.current = size;
    entityTypeRef.current = entityType;
    subtypeRef.current = subtype;

    const trimmedQuery = trimSearchQuery(searchQuery);
    assert(
      searchEntityMap[entityType],
      'searchEntityMap[entityType] expected',
      {entityType},
    )(
      trimmedQuery,
      subtype ? [subtype] : undefined,
      newPage,
      size,
      getTokens,
      abortControllerRef.current.signal,
    )
      .then(data => {
        setResult({status: 'Loaded', data, page: newPage});
        resetPage(data.total, newPage);
      })
      .catch(error => {
        if (
          error instanceof HttpError &&
          error.status === HTTP_STATUS_ABORTED
        ) {
          // Caused by abort above, leave state as is - to be updated by next call
          // console.log('useAgentSearch: search aborted');
          return;
        }

        console.error('useAgentSearch: search failed', error);
        setResult({
          status: 'Failed',
          page,
          error,
        });
      });
  }, [subtype, getTokens, searchQuery, entityType, page, size, resetPage]);

  return result;
};
