import {OverviewTask, TaskTypeAll} from 'api/types';

export const toggleTaskTypes = (
  allTasks: OverviewTask[],
  filter: TaskTypeAll | undefined,
): OverviewTask[] => {
  const all = filter === undefined || filter === 'all';

  if (all) {
    return allTasks;
  }

  return allTasks.filter(t => t.taskTypes.includes(filter));
};
