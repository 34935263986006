import React, {Fragment} from 'react';
import {Data, PartSchema, SchemaValueType, Valx} from 'schemaDefinition/types';
import {FieldValue} from 'components';
import {FieldLabel} from 'components/fields/FieldLabel';
import {Table} from 'schema/table/components/Table';
import {BasePartSimpleProps} from './types';
import {useDataFormConfigurationContext, useDataFormContext} from '../contexts';
import {useLabelAndPlaceholder} from '../hooks';

type DataFormTableProps = BasePartSimpleProps & {
  part: PartSchema<Valx>;
};

export const DataFormTable: React.FC<DataFormTableProps> = ({
  part,
  valuePath,
  useValue,
}) => {
  const {label, empty} = useLabelAndPlaceholder(part);
  const config = useDataFormConfigurationContext();
  const {id} = useDataFormContext();
  const value = useValue(valuePath, id, part.name);

  return !Array.isArray(value) || value.length === 0 ? (
    <Fragment>
      <FieldLabel label={label ?? 'missing label: ' + part.name} />
      <FieldValue value={empty ?? 'missing empty placholder: ' + part.name} />
    </Fragment>
  ) : (
    <Table
      schema={part as PartSchema<SchemaValueType>}
      data={value as Data[]}
      header={<FieldLabel label={label ?? 'missing label: ' + part.name} />}
      configuration={config?.parts?.[part.name]?.table}
    />
  );
};
