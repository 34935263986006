import React, {useMemo} from 'react';
import {TextChangesProps} from './types';
import './TextChanges.scss';
import {findDiff} from './functions/findDiff';

/**
 * Shows changes to a text in 'original' or 'updated' mode.
 * - 'original' mode shows the original text with underline of
 *   parts that have been deleted
 * - 'updated' mode shows the updated text with underline of
 *   parts that have been added
 */
export const TextChanges: React.FC<TextChangesProps> = ({
  original,
  updated,
  diffMode,
}) => {
  const diff = useMemo(() => findDiff(original, updated), [original, updated]);

  if (!diff) {
    return null;
  }

  const htmlString = diff
    .filter(d =>
      diffMode === 'original' ? d.type !== 'insert' : d.type !== 'delete',
    )
    .map(d =>
      d.type === 'insert'
        ? `<span class="text-changes-added">${d.value}</span>`
        : d.type === 'delete'
          ? `<span class="text-changes-deleted">${d.value}</span>`
          : d.value,
    )
    .join('');

  return <HtmlRenderer htmlString={htmlString} />;
};

const HtmlRenderer = ({htmlString}: {htmlString: string}) => {
  return <span dangerouslySetInnerHTML={{__html: htmlString}} />;
};
