import {Configuration} from 'configuration';
import {HttpArgs, httpMethod} from './httpMethod';
import {httpMock} from './mock';

/**
 * @private
 */
export const httpWithMock = <TResponse, TBody = unknown>({
  mock,
  ...args
}: HttpArgs<TBody> & {mock?: boolean}): Promise<TResponse> => {
  return (Configuration.env === 'local' || Configuration.env === 'dev') &&
    (mock || (mock === undefined && Configuration.searchApi.mock.enabled))
    ? httpMock<TResponse, TBody>(args)
    : httpMethod<TResponse, TBody>(args);
};
