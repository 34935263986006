import React from 'react';
import Box from '@mui/material/Box';

type Props = {
  size?: string | number;
  height?: string | number;
  width?: string | number;
};

export const Spacer: React.FC<Props> = ({size, height, width}) => {
  return <Box paddingTop={height ?? size} paddingLeft={width ?? size} />;
};
