import React from 'react';
import {TextAreaProps} from './types';
import {TextAreaEditable} from './TextAreaEditable';
import {TextAreaReadonly} from './TextAreaReadonly';

export const TextArea: React.FC<TextAreaProps> = ({
  readonly,
  rows,
  ...props
}) =>
  readonly ? (
    <TextAreaReadonly rows={rows} {...props} />
  ) : (
    <TextAreaEditable rows={rows} {...props} />
  );
