import assert from 'assert-ts';
import {produce} from 'immer';
import get from 'lodash/get';
import set from 'lodash/set';
import {Data, DataValue} from 'schemaDefinition/types';
import {ArrayUpdateHistory} from 'services/data/metadata/types';
import {isNullish} from 'services/utils';
import {getParentArrayPathParts} from './getParentArrayPathParts';

export const appendItemToOriginalValue = <T extends Data>(
  entity: T,
  history: ArrayUpdateHistory | undefined,
  localPath: string,
  proposedValue: DataValue,
): {entity: T; history: ArrayUpdateHistory | undefined} => {
  // Ignore nullish proposed values
  if (isNullish(proposedValue)) {
    return {entity, history};
  }

  const {parentPath} = getParentArrayPathParts(localPath);
  if (
    !assert.soft(
      parentPath,
      'addItemToOriginalValue: parentArrayPath not found',
    )
  ) {
    return {entity, history};
  }

  let atIndex = 0;

  const newEntity = produce(entity, draft => {
    const array = get(draft, parentPath) as DataValue[] | undefined;
    if (Array.isArray(array)) {
      array.push(proposedValue);
      atIndex = array.length - 1;
    } else {
      set(draft, parentPath, [proposedValue]);
      atIndex = 0;
    }
  });

  return {
    entity: newEntity,
    history: [
      ...(history ?? []),
      {
        atIndex,
        operation: 'addItem',
      },
    ],
  };
};
