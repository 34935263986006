import React, {CSSProperties, useMemo} from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {DndItemProps} from '../../types';
import {DndItem} from './DndItem';

export const DndSortableItem: React.FC<DndItemProps> = props => {
  const {attributes, listeners, setNodeRef, transform, transition} =
    useSortable({id: props.id});

  const style: CSSProperties = useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition,
    }),
    [transform, transition],
  );

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <DndItem {...props} />
    </div>
  );
};
