import {useCallback, useMemo, useState} from 'react';
import {NationalAgent, NationalAgentType} from 'api/types';
import {RowId} from 'components/dataTable/types';
import {getId} from 'services/utils';
import {AgentQuery, NationalAgentWithInEbba} from '../types';
import {useSearchAgentInNationalRegistry} from './useSearchAgentInNationalRegistry';

export type NationalAgentSearch = {
  showSearchErrors: boolean;
  searchResult: NationalAgentWithInEbba[] | undefined;
  doSearch: (searchInfo: AgentQuery) => Promise<void>;
  getNationalRegistryAgent: (
    id: RowId | undefined,
  ) => NationalAgent | undefined;
  getEbbaAgent: (nationalId: string | undefined) => NationalAgent | undefined;
};

const NOT_IN_EBBA_ID_PREFIX = 'NOT_IN_EBBA';

export const useNationalAgentSearch = (
  agentType: NationalAgentType,
): NationalAgentSearch => {
  const search = useSearchAgentInNationalRegistry();
  const [showSearchErrors, setShowSearchErrors] = useState(false);
  const [searchResult, setSearchResult] = useState<
    NationalAgentWithInEbba[] | undefined
  >(undefined);
  const [inEbbaAgents, setInEbbaAgents] = useState<
    NationalAgent[] | undefined
  >();

  const handleSearch = useCallback(
    (query: AgentQuery) => {
      return search(agentType, query.name, query.other)
        .then(({hits, inEbba}) => {
          const hitsWithInEbba = hits.map<NationalAgentWithInEbba>(a => ({
            ...a,
            id: a.id ?? `${NOT_IN_EBBA_ID_PREFIX}:${getId()}`,
            inEbba: !!a.id,
          }));
          setSearchResult(hitsWithInEbba);
          setInEbbaAgents(inEbba);
          setShowSearchErrors(false);
        })
        .catch(() => {
          setShowSearchErrors(true);
        });
    },
    [agentType, search],
  );

  const getNationalRegistryAgent = useCallback(
    (id: RowId | undefined) => {
      if (!id) {
        return undefined;
      }

      const nationalRegistryAgent = searchResult?.find(
        agent => agent.id === id,
      );
      return nationalRegistryAgent;
    },
    [searchResult],
  );

  const getEbbaAgent = useCallback(
    (nationalId: string | undefined) => {
      const ebbaAgent = nationalId
        ? inEbbaAgents?.find(agent => agent.nationalId === nationalId)
        : undefined;

      return ebbaAgent;
    },
    [inEbbaAgents],
  );

  return useMemo(
    () => ({
      showSearchErrors,
      searchResult,
      doSearch: handleSearch,
      getNationalRegistryAgent,
      getEbbaAgent,
    }),
    [
      getEbbaAgent,
      getNationalRegistryAgent,
      handleSearch,
      searchResult,
      showSearchErrors,
    ],
  );
};
