import React from 'react';
import {CurrentFilterType} from 'api/types';
import {DatePickerField} from 'components';

type Props = {
  filter: CurrentFilterType;
  onChange: (filter: CurrentFilterType) => void;
  onSubmit?: () => void;
};
export const FilterDateValue: React.FC<Props> = ({filter, onChange}) => {
  const value =
    Array.isArray(filter.values) && filter.values.length
      ? filter.values[0]
      : null;

  return (
    <DatePickerField
      name={`${filter.name}-${filter.id}`}
      value={value}
      error={false}
      onChange={value => {
        onChange({
          ...filter,
          values: value && value !== 'Invalid date' ? [value] : undefined,
        });
      }}
    />
  );
};
