import {useMemo} from 'react';
import {PartRef} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {useFormSchemaGlobalScopeContext} from '../../contexts';
import {useDataFormConfigurationContext} from '../contexts';
import {getFieldRootKeys, getLabel, getPlaceholder} from '../functions';

export const useLabelAndPlaceholder = (
  {labelKey, name}: PartRef,
  noLabel?: boolean,
): {
  label?: string;
  placeholder?: string;
  empty?: string;
} => {
  const localization = useLocalization();
  const {schema} = useFormSchemaGlobalScopeContext();
  const configuration = useDataFormConfigurationContext();

  return useMemo(() => {
    const configurationPart =
      configuration.parts?.[labelKey ?? '__no_key__'] ??
      configuration.parts?.[name ?? '__no_name__'];
    if (configurationPart) {
      return {
        label: noLabel ? undefined : configurationPart.label,
        placeholder: configurationPart.placeholder,
        empty: configurationPart.empty,
      };
    }

    const rootKeys = getFieldRootKeys(schema.key, noLabel, labelKey, name);
    return {
      label: getLabel(rootKeys, !!labelKey, localization),
      placeholder: getPlaceholder(rootKeys, localization),
    };
  }, [configuration.parts, labelKey, localization, name, noLabel, schema.key]);
};
