import React, {useMemo} from 'react';
import {SxProps} from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import {styled} from '@mui/material/styles';
import {FilterModel} from '../types';
import {compareFilterModels} from '../functions';

type Props = {
  title: string;
  activeFilterModel?: FilterModel;
  filterModel: FilterModel;
  setFilterModel: (filterModel: FilterModel) => void;
};

const StyledButton = styled(ButtonBase)(({theme}) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  background: 'white',
  borderRadius: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  height: theme.spacing(4),
  marginLeft: theme.spacing(1),
  fontSize: theme.typography.body2.fontSize,
  whiteSpace: 'nowrap',
}));

export const QuickFilterButton: React.FC<Props> = ({
  title,
  activeFilterModel,
  filterModel,
  setFilterModel,
}) => {
  const active = compareFilterModels(filterModel, activeFilterModel);

  const sx: SxProps = useMemo(
    () => ({outline: active ? '1px solid currentColor;' : undefined}),
    [active],
  );

  return (
    <StyledButton
      sx={sx}
      onClick={() => {
        if (active) {
          setFilterModel({items: []});
        } else {
          setFilterModel(filterModel);
        }
      }}>
      {title}
    </StyledButton>
  );
};
