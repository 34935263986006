import {getOffset} from './getOffset';

export const getIndexOnPage = (
  index: number | undefined,
  page: number,
  size: number,
): number | undefined => {
  if (index === undefined) {
    return undefined;
  }

  const offset = getOffset({page, size});
  return index >= offset && index < offset + size ? index - offset : undefined;
};
