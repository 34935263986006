import orderBy from 'lodash/orderBy';
import {AgentName, AgentNameWithDescription} from 'api/types';
import {Schemas} from 'api/dto.generated';

export const mapNameVariantsDto = (
  dto: Schemas.AgentNameDto[],
): AgentName[] => {
  return orderBy(dto, ['nameOrder'], ['asc']).map(
    ({nameOrder: _, ...rest}) => rest,
  );
};

export const mapNameVariantsWithDescriptionDto = (
  dto: Schemas.AgentNameDto[],
  description?: string,
): AgentNameWithDescription[] => {
  return orderBy(dto, ['nameOrder'], ['asc']).map(({nameOrder: _, ...rest}) =>
    description ? {...rest, description} : rest,
  );
};

export const mapToNameVariantsDto = (
  nameVariants: AgentName[],
): Schemas.AgentNameDto[] =>
  nameVariants.map((n, idx) => ({
    ...n,
    nameOrder: idx,
  }));

export const mapToNameVariantsDtoAndDescription = (
  nameVariants: AgentNameWithDescription[],
): {nameVariants: Schemas.AgentNameDto[]; description?: string} => ({
  nameVariants: nameVariants.map(({description: _, ...rest}, idx) => ({
    ...rest,
    nameOrder: idx,
  })),
  description: nameVariants[0]?.description,
});
