import {NoteData} from 'api/types';
import {Schemas} from '../dto.generated';

/**
 * Strip id, userId, created and modified
 */
export const mapToPostPutCommentDto = (
  method: 'POST' | 'PUT',
  {
    id: _id,
    userId: _userId,
    created: _created,
    modified: _modified,
    lastModified: _lastModified,
    ...data
  }: NoteData,
): Schemas.CommentDto => {
  return method === 'POST'
    ? data
    : {
        created: _created,
        modified: _modified,
        ...data,
      };
};
