import {ManifestationDesignator} from 'types';
import {useManifestationIdByDesignators} from 'services/data/metadata';

export const useManifestationId = (
  designator: ManifestationDesignator | undefined,
): string | undefined => {
  const id = useManifestationIdByDesignators(designator);

  return id;
};
