import {ManifestationStatus} from 'api/types';
import {StatusColor} from 'theme/types';

const colorNameMap: {[key in ManifestationStatus]: StatusColor} = {
  NEW: 'new',
  PRE_CATALOGED: 'pre',
  CONTROL: 'control',
  COMPLETED: 'done',
};

export const getManifestationStatusColorName = (
  status: ManifestationStatus,
): StatusColor => {
  return colorNameMap[status];
};
