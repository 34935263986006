import {useMemo} from 'react';
import {Concept} from 'types';
import {Schema, Valid} from 'schemaDefinition/types';
import {
  useAssertSavedWork,
  useStatusAndShowError,
  useWorkImportProps,
} from 'services/data';
import {useModifiedSchema} from 'schemas/hooks/useModifiedSchema';
import {useWorkValidation} from './useWorkValidation';

export const useWorkEditState = (
  readonly?: boolean,
): {
  schema: Schema;
  readonly: boolean;
  valid: Valid | undefined;
  showErrors: boolean;
} => {
  const savedWork = useAssertSavedWork();
  const workId = savedWork.id;
  const {activeStatus: workStatus, showError} = useStatusAndShowError(workId);

  const workImportProps = useWorkImportProps();

  const schema = useModifiedSchema(
    savedWork,
    workImportProps.work.type,
    Concept.work,
    workStatus,
  );

  const valid = useWorkValidation(showError, schema);

  return useMemo(
    () => ({
      schema,
      readonly: !!readonly,
      valid,
      showErrors: showError,
    }),
    [readonly, schema, showError, valid],
  );
};
