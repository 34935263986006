import {useSelector} from 'react-redux';
import {ExpressionV4} from 'api/types';
import {AppState} from 'store/types';
import {useCreateSelectorById} from './useCreateSelectorById';

const expressionSelectorById =
  (expressionId: string | undefined) => (state: AppState) =>
    expressionId
      ? state.metadataEdit.metadata?.expressions.find(
          e => e.id === expressionId,
        )
      : undefined;

export const useOptionalExpression = (
  expressionId: string | undefined,
): ExpressionV4 | undefined => {
  const selector = useCreateSelectorById(expressionId, expressionSelectorById);
  return useSelector(selector);
};
