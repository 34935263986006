import {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useStore} from 'react-redux';
import assert from 'assert-ts';
import {Concept} from 'types';
import {EntitySearchState} from 'feature/linkedValueSearch/context/types';
import {VerifiedLinkedCatalogPostLink} from 'schemaDefinition/types';
import {AppState} from 'store/types';
import {useLocalization} from 'localization';
import {
  ManifestationV4,
  createChangeRequestFromMove,
  moveManifestationToExistingWork,
  saveChangeRequestInLocalStore,
} from 'api';
import {useGetTokens} from 'services/auth';
import {
  useAssertSavedWork,
  useOptionalExpression,
  useUniqueSavedManifestation,
} from 'services/data';
import {MetadataEditAction} from 'services/data/metadata/metadataEditActionTypes';
import {useSnacks} from 'components';
import {
  AlertDialogAsPromiseV3,
  useAlertDialogAsPromiseV3,
} from 'components/alertdialog/hooks/useAlertDialogAsPromiseV3';
import {useLinkedValueSearchContext} from 'sceneExtensions';
import {MoveToExistingWorkInfo} from '../components/MoveToExistingWorkInfo';
import {copyFromOtherWork} from '../functions';

/**
 * Use to move manifestation to other work (target work), either
 * explicit manifestation id on source work (current edit context)
 * or implicit when source work has just one manifestation
 */
export const useMoveToOrCopyFromOtherWork = (
  manifestationId?: string,
): {
  openSearch: () => void;
  ConfirmMoveToOther: AlertDialogAsPromiseV3;
  isConfirmOpen: boolean;
  isConfirmClosing: boolean;
} => {
  const {t} = useLocalization();
  const {errorSnack} = useSnacks();
  const navigate = useNavigate();
  const getTokens = useGetTokens();
  const {dispatch, getState} = useStore<AppState>();
  const work = useAssertSavedWork();
  const manifestation = useUniqueSavedManifestation(manifestationId);
  const expression = useOptionalExpression(manifestation?.expressionId);

  const {
    pleaseConfirm: pleaseConfirmMove,
    AlertDialog: ConfirmMoveToOther,
    isOpen,
    isClosing,
  } = useAlertDialogAsPromiseV3();

  const context = useLinkedValueSearchContext();

  const handleMove = useCallback(
    (
      targetWork: VerifiedLinkedCatalogPostLink,
      manifestation: ManifestationV4,
    ) => {
      const targetWorkId = targetWork.entityId;
      pleaseConfirmMove(
        () =>
          moveManifestationToExistingWork(
            manifestation.id,
            targetWorkId,
            getTokens,
          ).catch(err => {
            errorSnack(t('search.work.move.failed'), err);
            throw err;
          }),
        {
          title: t('search.work.move.title'),
          body: (
            <MoveToExistingWorkInfo
              manifestation={manifestation}
              currentExpression={assert(expression)}
              targetWork={assert(targetWork.entityValue, 'targetWork')}
            />
          ),
          okTitle: t('search.work.move.ok'),
          okIcon: 'Move',
          cancelTitle: t('general.cancel'),
        },
      ).then(confirmed => {
        if (confirmed) {
          return moveManifestationToExistingWork(
            manifestation.id,
            targetWorkId,
            getTokens,
          ).then(() => {
            const changeRequest = createChangeRequestFromMove(
              targetWorkId,
              work,
              manifestation,
            );
            saveChangeRequestInLocalStore(changeRequest);
            navigate(
              `/metadata/${targetWorkId}?manifestationId=${manifestation.id}`,
            );
          });
        }
        return Promise.resolve();
      });
    },
    [errorSnack, expression, getTokens, navigate, pleaseConfirmMove, t, work],
  );

  const handleCopy = useCallback(
    (
      targetWork: VerifiedLinkedCatalogPostLink,
      manifestation?: ManifestationV4,
    ) => {
      const dummyManifestation = assert(
        getState().metadata.data?.manifestations[0],
      );
      const manifestationInfo = manifestation
        ? ({
            toManifestation: manifestation,
          } as const)
        : {
            toManifestationPlaceholder: {
              id: dummyManifestation.id,
              expressionId: dummyManifestation.expressionId,
            } as const,
          };

      const changeRequest = copyFromOtherWork({
        toWork: work,
        ...manifestationInfo,
        fromWork: assert(
          targetWork.entityValue,
          'useMoveToOrCopyFromOtherWork: fromWork expected',
        ),
      });
      if (changeRequest) {
        const copyAction: MetadataEditAction = {
          type: 'METADATAEDIT_NEW_CHANGEREQUEST_FROM_COPY',
          payload: changeRequest,
        };
        dispatch(copyAction);
      }
      return Promise.resolve();
    },
    [dispatch, getState, work],
  );

  const handleOpenSearch = useCallback(() => {
    context.setSourceField(
      {
        fieldId: 'searchPanel',
        fieldConfig: {
          titleKey: 'general.search',
          initialState: EntitySearchState.search,
          entity: Concept.work,
          sourceEntityId: work.id,
          canMove: !!manifestation,
          canCopy: true,
          allowedEntities: [Concept.work],
        },
        currentValue: {
          linkStatus: 'verified',
          entityId: work.id,
          type: Concept.work,
          name: work?.preferredTitle?.[0] ?? '',
        },
      },
      (target, _fieldId, action) => {
        const targetWork = target as VerifiedLinkedCatalogPostLink | undefined;
        if (targetWork) {
          if (action === 'move') {
            handleMove(
              targetWork,
              assert(manifestation, 'useMoveToOrCopyFromOtherWork: required'),
            );
          } else if (action === 'copy') {
            handleCopy(targetWork, manifestation);
          } else {
            assert(
              false,
              'useMoveToOrCopyFromOtherWork: Unexpected move or copy action',
              {
                action,
              },
            );
          }
        }
      },
    );
  }, [
    context,
    handleCopy,
    handleMove,
    manifestation,
    work.id,
    work?.preferredTitle,
  ]);

  return useMemo(
    () => ({
      openSearch: handleOpenSearch,
      handleOpenSearch,
      ConfirmMoveToOther,
      isConfirmOpen: isOpen,
      isConfirmClosing: isClosing,
    }),
    [ConfirmMoveToOther, handleOpenSearch, isClosing, isOpen],
  );
};
