import {Dispatch} from 'redux';
import {ChangeRequest, GetTokens} from 'api';
import {getChangeRequestsForWork} from 'api/changeRequest';
import {makeLoadActionFactories} from 'services/utils';
import {MetadataAction, MetadataActionType} from '../metadataActionTypes';

const {makeRequestAction, makeSuccessAction, makeFailureAction} =
  makeLoadActionFactories<
    ChangeRequest[],
    MetadataActionType.LOAD_CHANGE_REQUESTS
  >(MetadataActionType.LOAD_CHANGE_REQUESTS);

export const loadChangeRequestsAction = (
  workId: string,
  eans: string[],
  getTokens: GetTokens,
  mock?: boolean,
) => {
  return (dispatch: Dispatch<MetadataAction>) => {
    dispatch(makeRequestAction());

    getChangeRequestsForWork(workId, eans, getTokens, mock)
      .then(data => {
        dispatch(makeSuccessAction(data));
      })
      .catch(error => {
        dispatch(makeFailureAction(error));
      });
  };
};
