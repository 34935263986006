import {useMemo} from 'react';
import {SxProps} from '@mui/material';
import {isEmpty, isEqual} from 'lodash';
import {ChangeRequestAction} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {FlexBox, Icon, IconButton} from 'components';
import {useDataFormContext} from '../contexts';

const statusDoneSx: SxProps = {color: ColorPalette.approve};

type Props = {
  groupName?: string;
  groupPath: string;
  originalGroupTerms?: string[];
  changesGroupTerms?: string[];
  onReplace: () => void;
  onAdd: () => void;
  onRemove: () => void;
};

export const CompareThesaurusGroupActions: React.FC<Props> = ({
  groupName,
  groupPath,
  originalGroupTerms,
  changesGroupTerms,
  onReplace,
  onAdd,
  onRemove,
}) => {
  const {t} = useLocalization();
  const {id, useReviewStatus} = useDataFormContext();
  const status = useReviewStatus(groupPath, id);
  const action = useMemo((): ChangeRequestAction | null => {
    if (isEmpty(originalGroupTerms)) return 'addItem';
    if (isEmpty(changesGroupTerms)) return 'removeItem';
    if (isEqual(originalGroupTerms, changesGroupTerms)) return null;
    return 'replace';
  }, [changesGroupTerms, originalGroupTerms]);

  const tooltip = `${t(`page.metadata.cr.thesaurus.${action ?? 'replace'}`)} ${groupName ?? ''}`;

  return (
    <FlexBox flex={1} center p={1} mt={1}>
      {status === 'none' ? (
        action === 'replace' ? (
          <IconButton icon="Swap" onClick={onReplace} tooltip={tooltip} />
        ) : action === 'addItem' ? (
          <IconButton icon="Add" onClick={onAdd} tooltip={tooltip} />
        ) : action === 'removeItem' ? (
          <IconButton icon="Delete" onClick={onRemove} tooltip={tooltip} />
        ) : (
          <IconButton icon="Swap" onClick={() => {}} disabled />
        )
      ) : (
        <Icon icon="Check" sx={statusDoneSx} />
      )}
    </FlexBox>
  );
};
