import {Scene as SceneContainer} from './Scene';
import {SceneContent} from './SceneContent';
import {SceneHeader} from './SceneHeader';
import {SceneRightPanel} from './SceneRightPanel';
import {SceneSubMenu} from './SceneSubMenu';

type SceneType = typeof SceneContainer & {
  Header: typeof SceneHeader;
  SubMenu: typeof SceneSubMenu;
  Content: typeof SceneContent;
  RightPanel: typeof SceneRightPanel;
};
const Scene: SceneType = SceneContainer as SceneType;
Scene.Header = SceneHeader;
Scene.SubMenu = SceneSubMenu;
Scene.Content = SceneContent;
Scene.RightPanel = SceneRightPanel;

export {Scene};
