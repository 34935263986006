import {assert} from 'assert-ts';
import {Data, DataSimpleValue, Part, Valx} from 'schemaDefinition/types';
import {
  ValidateSingleTypeValue,
  ValidationArgs,
  ValidationResult,
} from './types';
import {isMultiple} from '../isMultiple';
import {validResult} from './validResult';
import {validateConditions} from './validateConditions';
import {validateMultipleValue} from './validateMultipleValue';

/**
 * validates data[part.name] according to "value" part
 * part - to validate against
 * validateSingleTypeValue - function to validate single value for part type
 * returns aggregateResult expanded with any validation results
 */
export const validateValueType = <TPart extends Part<Valx>>(
  part: TPart,
  validateSingleTypeValue: ValidateSingleTypeValue<TPart>,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  let result = aggregateResult;
  if (isMultiple(part.cardinality)) {
    result = validateMultipleValue(part, args, result);

    // No further validation if the value is not an array
    if (args.value === undefined || args.value === null) {
      return result;
    }
  }

  if (isMultiple(part.cardinality)) {
    if (part.listValidation) {
      result = validateConditions(part.listValidation, part, args, result);
    }

    const items = args.value as Data[] | DataSimpleValue[];
    items.forEach((_, idx) => {
      const {item, itemPath} = getItemValue(items, idx, args.valuePath);
      result = validateSingleTypeValue(
        part,
        {
          ...args,
          value: item,
          valuePath: itemPath,
        },
        result,
      );
    });
    return result;
  } else {
    assert.soft(
      !part.listValidation,
      'validateValueType: listValidation is only valid for cardinality > 1',
      part,
    );
    return validateSingleTypeValue(part, args, result);
  }
};

const getItemValue = (
  value: Data[] | DataSimpleValue[],
  idx: number,
  valuePath: string,
): {item: Data | DataSimpleValue | undefined; itemPath: string} => {
  const itemPath = `${valuePath}[${idx}]`;
  const item = value[idx];
  return {item, itemPath};
};
