import {useCallback, useMemo} from 'react';
import {useStore} from 'react-redux';
import {Concept} from 'types';
import {CodeList, CodeListId, ManifestationStatus} from 'api/types';
import {EditStatus} from 'scenes/updateMetadata/types';
import {Data, Schema, Valid} from 'schemaDefinition/types';
import {EditMode} from 'services/data/metadata/types';
import {AppState} from 'store/types';
import {CodelistRestrictor} from 'services/codeLists';
import {
  useEditMode,
  useEditStatus,
  useExpressionLocalCodelistMap,
  useStatusAndShowError,
} from 'services/data';
import {useExpressionImportProps} from 'services/data/metadata/hooks/useExpressionImportProps';
import {useExpressionValidation} from 'services/data/metadata/hooks/useExpressionValidation';
import {MetadataEditAction} from 'services/data/metadata/metadataEditActionTypes';
import {
  useExpressionCodelistRestrictor,
  useRestrictedCodelistsForSchema,
} from 'schemas';
import {useModifiedSchema} from 'schemas/hooks/useModifiedSchema';
import {ExpressionCardCoreProps} from '../types';
import {useExpressionTitle} from './useExpressionTitle';

export const useExpressionEditStateCore = ({
  expressionId,
  stableExpression,
  // Given readonly prop, i.e. edit mode not available when readonly only
  readonly: readonlyOnly,
}: ExpressionCardCoreProps): {
  title: string;
  codelistRestrictor: CodelistRestrictor;
  getLocalCodelist: (id: CodeListId) => CodeList | undefined;
  /** Aggregated status, undefined when not yet loaded */
  expressionStatus: ManifestationStatus | undefined;
  relatedScope: Data;
  schema: Schema;
  readonly: boolean;
  editStatus: EditStatus;
  valid: Valid | undefined;
  showErrors: boolean;
  handleEdit: () => void;
  handleCancelEdit: () => void;
} => {
  const {dispatch} = useStore<AppState>();
  const {activeStatus: expressionStatus, showError} =
    useStatusAndShowError(expressionId);
  const {hasDataChanged} = useEditStatus(expressionId);
  const mode = useEditMode(expressionId, 'root', 'readonly');

  const relatedScope = useExpressionImportProps(expressionId);

  const codelistRestrictor = useExpressionCodelistRestrictor(expressionId);

  const codelistMap = useRestrictedCodelistsForSchema(
    codelistRestrictor,
    Concept.expression,
  );

  const localCodelistMap = useExpressionLocalCodelistMap(expressionId);

  const combinedCodelistMap = useMemo(
    () => ({
      ...codelistMap,
      ...localCodelistMap,
    }),
    [codelistMap, localCodelistMap],
  );

  const schema = useModifiedSchema(
    stableExpression,
    relatedScope.work.type,
    Concept.expression,
    expressionStatus,
  );

  const valid = useExpressionValidation(
    expressionId,
    relatedScope,
    showError,
    schema,
    combinedCodelistMap,
  );

  const title = useExpressionTitle(stableExpression, undefined);

  const setMode = useCallback(
    (mode: EditMode) => {
      const action: MetadataEditAction = {
        type: 'METADATAEDIT_SET_EDITMODE',
        payload: {
          id: expressionId,
          key: 'root',
          mode,
        },
      };
      dispatch(action);
    },
    [dispatch, expressionId],
  );

  const handleEdit = useCallback(() => {
    if (readonlyOnly) {
      return;
    }

    setMode('edit');
  }, [readonlyOnly, setMode]);

  const handleCancelEdit = useCallback(() => {
    if (hasDataChanged) {
      return;
    }

    setMode('readonly');
  }, [hasDataChanged, setMode]);

  return useMemo(
    () => ({
      title,
      codelistRestrictor,
      getLocalCodelist: id => localCodelistMap[id],
      expressionStatus,
      relatedScope,
      schema,
      editStatus: hasDataChanged ? 'Changed' : 'Unchanged',
      readonly: mode === 'readonly',
      valid,
      showErrors: showError || showError,
      handleEdit,
      handleCancelEdit,
    }),
    [
      title,
      codelistRestrictor,
      expressionStatus,
      relatedScope,
      schema,
      hasDataChanged,
      mode,
      valid,
      showError,
      handleEdit,
      handleCancelEdit,
      localCodelistMap,
    ],
  );
};
