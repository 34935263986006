import cloneDeep from 'lodash/cloneDeep';
import {Schema, SchemaValueType} from 'schemaDefinition/types';
import {SchemaMode} from 'schemas/types';
import {visitSchemaParts} from 'schemaDefinition';

/**
 * Make some parts readonly if schemaMode is 'dialog', since right panel
 * for editing of value is not available in dialog mode
 */
export const getSchemaForMode = <TVal = SchemaValueType>(
  schema: Schema<TVal>,
  schemaMode: SchemaMode,
): Schema<TVal> => {
  if (schemaMode === 'full') {
    return schema;
  }

  const clonedSchema = cloneDeep(schema);
  visitSchemaParts(clonedSchema, part => {
    if (
      part.type === 'linkedAgent' ||
      part.type === 'linkedLiterary' ||
      part.type === 'thesaurus'
    ) {
      part.readonly = true;
    }
  });
  return clonedSchema;
};
