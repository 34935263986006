import {EditStatuses, EntityEditStatus} from 'services/data/metadata/types';

export function updateEditStatuses(
  entityId: string,
  newStatus: EntityEditStatus | undefined,
  editStatuses: EditStatuses,
): EditStatuses {
  const current: EntityEditStatus | undefined = editStatuses[entityId];
  if (newStatus === current) {
    return editStatuses;
  }

  const newEditStatuses = {...editStatuses};

  if (newStatus?.hasDataChanged || newStatus?.hasMoved) {
    newEditStatuses[entityId] = newStatus;
  } else {
    delete newEditStatuses[entityId];
  }

  return newEditStatuses;
}
