import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const DoubleChevronRightIcon: React.FC<IconProps> = (
  props: IconProps,
) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12.41 12L7.71 16.71L6.29004 15.29L9.59 12L6.29004 8.70996L7.71 7.28996L12.41 12Z" />
      <path d="M18.41 12L13.71 16.71L12.29004 15.29L15.59 12L12.29004 8.70996L13.71 7.28996L18.41 12Z" />
    </SvgIcon>
  );
};
