import {useMemo} from 'react';
import {ManifestationDesignator} from 'types';
import {useQueryParams} from 'navigation/hooks';
import {InitialManifestationParam} from '../types';
import {getManifestationDesignatorParam} from '../functions';

export const useManifestationDesignatorParam = ():
  | ManifestationDesignator
  | undefined => {
  const manifestationParam = useQueryParams<InitialManifestationParam>();

  const designator = useMemo(
    () => getManifestationDesignatorParam(manifestationParam),
    [manifestationParam],
  );

  return designator;
};
