import Box from '@mui/material/Box';
import {SxProps} from '@mui/system';
import {FCWithChildren} from 'types';

type Props = {
  sx?: SxProps;
  offset?: number;
};

export const Scrollable: FCWithChildren<Props> = ({sx, offset, children}) => {
  return (
    <Box
      sx={sx}
      style={{
        height: `calc(100vh - ${offset || 0}px)`,
        display: 'flex',
        overflow: 'auto',
      }}>
      {children}
    </Box>
  );
};
