import {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Collapse} from '@mui/material';
import {Concept, JSONValue} from 'types';
import {ProblemConstraintViolation} from 'api/http/types';
import {EntitySaveStatus} from 'services/data/metadata/types';
import {useLocalization} from 'localization';
import {
  Icon,
  IconButton,
  Layout,
  PrettyPrintJson,
  Spacer,
  Text,
} from 'components';
import {ManifestationV4} from '../../../api';
import {metadataEditSelector} from '../../../services/data';
import {ExpressionTitle} from './ExpressionTitle';
import {ManifestationTitle} from './ManifestationTitle';
import {WorkTitle} from './WorkTitle';

type Props = {
  statuses: EntitySaveStatus[];
};

export const MetadataSaveErrors: React.FC<Props> = ({statuses}) => {
  const localization = useLocalization();
  const {t} = localization;

  const [showErrorDetails, setShowErrorDetails] = useState(false);

  const {pretext, posttext, errorJson} = useMemo(() => {
    const someSaved = statuses.some(s => s.status.status === 'Saved');
    const error = statuses.find(s => s.status.status === 'Failed')?.status
      .error;

    const problemError = error
      ? (error.info as unknown as ProblemConstraintViolation)?.violations
        ? (error.info as unknown as ProblemConstraintViolation)
        : error.info
        ? (error.info as unknown as JSONValue)
        : ({
            message: error.message ?? null,
            stack: error.stack ?? null,
            status: error.status ?? null,
          } as unknown as JSONValue)
      : undefined;

    return {
      pretext: someSaved
        ? t('page.metadata.save.failed.someSaved')
        : t('page.metadata.save.failed.noneSaved'),
      posttext: undefined,
      errorJson: problemError,
    };
  }, [statuses, t]);

  const state = useSelector(metadataEditSelector);
  const getManifestation = useCallback(
    (manifestationId: ManifestationV4['id']) => {
      return state.metadata?.manifestations.find(e => e.id === manifestationId);
    },
    [state.metadata?.manifestations],
  );

  return (
    <Layout>
      <Text variant="body2">{pretext}</Text>
      <Spacer size={1} />
      <>
        {statuses.map((s, idx) => {
          const status = s.status.status;
          const {type, id} = s.entity;
          return (
            <Layout key={idx}>
              <Layout horizontal justifyContent={'space-between'}>
                <Layout horizontal adjustCenter adjustLeft>
                  {status === 'Saved' ? (
                    <Icon icon="Check" color="success" />
                  ) : status === 'Failed' ? (
                    <Icon icon="CloseSmall" color="error" />
                  ) : (
                    <Icon icon="Minus" color="warning" />
                  )}
                  {type === Concept.expression ? (
                    <ExpressionTitle id={id} />
                  ) : type === Concept.manifestation ? (
                    <ManifestationTitle manifestation={getManifestation(id)} />
                  ) : (
                    <WorkTitle />
                  )}
                </Layout>
                <Layout>
                  {status === 'Failed' ? (
                    <IconButton
                      icon={showErrorDetails ? 'DropDown' : 'DropDown'}
                      onClick={() => setShowErrorDetails(prev => !prev)}
                    />
                  ) : null}
                </Layout>
              </Layout>
              {status === 'Failed' ? (
                <Collapse in={showErrorDetails}>
                  <Layout mb={2}>
                    <PrettyPrintJson json={errorJson ?? {}} />
                  </Layout>
                </Collapse>
              ) : null}
            </Layout>
          );
        })}
      </>

      {posttext ? <Text variant="body2">{posttext}</Text> : null}
    </Layout>
  );
};
