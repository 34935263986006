import React from 'react';
import {AvailabilityStatus} from 'api/types';
import {IconName, IconProps} from './types';
import {Icon} from './Icon';

type Props = IconProps & {
  availabilityStatus?: AvailabilityStatus | null | undefined;
};

/**
 * @deprecated Use ProductStatusIcon instead
 */
const statusMap: {[key in AvailabilityStatus]: IconName} = {
  PRE_ORDER: 'History',
  AVAILABLE: 'Check',
  UNAVAILABLE: 'CloseSmall',
};

/**
 * @deprecated Use ProductStatusIcon instead
 */
export const AvailabilityStatusIcon: React.FC<Props> = ({
  availabilityStatus,
  ...props
}) => {
  const icon = availabilityStatus ? statusMap[availabilityStatus] : undefined;

  return icon ? <Icon icon={icon} {...props} /> : null;
};
