import {useMemo} from 'react';
import {HttpError} from 'api/http/types';
import {LoadStatus} from 'api/types';
import {
  LocalizationContextType,
  TranslationKey,
} from 'localization/context/types';
import {useLocalization} from 'localization';

const getErrorMessage = (
  errorOrKey: TranslationKey | HttpError | undefined,
  {t}: LocalizationContextType,
): string => {
  if (errorOrKey instanceof HttpError) {
    return errorOrKey.errorKey
      ? t(`error.api.${errorOrKey.errorKey}` as TranslationKey)
      : t('error.api.unknown');
  }

  if (errorOrKey) return t(errorOrKey);

  return t('error.api.unknown');
};

export const useLoadErrorMessage = (
  status: LoadStatus,
  errorOrKey: TranslationKey | HttpError | undefined,
) => {
  const localization = useLocalization();
  return useMemo(
    () =>
      status === 'Failed'
        ? getErrorMessage(errorOrKey, localization)
        : undefined,
    [errorOrKey, localization, status],
  );
};
