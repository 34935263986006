import React from 'react';
import {SvgIcon} from '@mui/material';
import {useStatusColor} from 'theme';
import {FilledIconProps} from './types';

export const RoundBackgroundIcon: React.FC<FilledIconProps> = ({
  fillColor,
  fillColorCode,
  ...props
}) => {
  const themeFillColor = useStatusColor(fillColor) ?? fillColorCode ?? 'none';
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="8" fill={themeFillColor} />
    </SvgIcon>
  );
};
