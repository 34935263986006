import {EditStatuses} from 'services/data/metadata/types';
import {updateEditStatuses} from './updateEditStatuses';

export function updateEditStatusOnCancelMoveToNewExpression(
  manifestationId: string,
  toExpressionId: string,
  editStatuses: EditStatuses,
): EditStatuses {
  const newStatuses1 = updateEditStatuses(
    manifestationId,
    undefined,
    editStatuses,
  );
  const newStatuses2 = updateEditStatuses(
    toExpressionId,
    undefined,
    newStatuses1,
  );

  return newStatuses2;
}
