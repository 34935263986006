import {ThesaurusValue} from 'schemaDefinition/types';
import {SearchMatch} from 'services/thesaurus/types';
import {RelatedThesaurusNode, ThesaurusNode} from 'api';

export const getSearchMatches = (
  query: string,
  thesaurusValue: ThesaurusValue | undefined,
  getDisplayCode: (node: ThesaurusNode) => string | undefined,
  node: ThesaurusNode,
  ancestors: ThesaurusNode[],
): SearchMatch[] => {
  if (node.deactivated && !thesaurusValue?.includes(node.code)) {
    return [];
  }

  const matches: SearchMatch[] = [];
  const queryLower = query.toLowerCase();
  const code = getDisplayCode(node);
  const hasTitleMatch =
    node.label.toLowerCase().includes(queryLower) ||
    !!code?.toLowerCase().includes(queryLower);

  const hasNoteMatch = !!(
    node.note && node.note.toLowerCase().includes(queryLower)
  );

  const hasSynonymMatch = !!(
    node.alternativeLabels &&
    node.alternativeLabels.some(s => s.toLowerCase().includes(queryLower))
  );

  const hasRelatedMatch = hasRelatedSearchMatches(
    queryLower,
    getDisplayCode,
    node.related,
  );

  if (hasTitleMatch || hasNoteMatch || hasSynonymMatch || hasRelatedMatch) {
    matches.push({
      node,
      ancestors: ancestors,
      hasTitleMatch,
      hasNoteMatch,
      hasSynonymMatch,
    });
  }

  return matches;
};

const hasRelatedSearchMatches = (
  queryLower: string,
  getDisplayCode: (node: ThesaurusNode) => string | undefined,
  nodes: RelatedThesaurusNode[] = [],
): boolean => {
  return nodes.some(n => {
    const code = getDisplayCode(n);
    return (
      n.label.toLowerCase().includes(queryLower) ||
      code?.toLowerCase().includes(queryLower) ||
      (n.note && n.note.toLowerCase().includes(queryLower))
    );
  });
};
