import React, {CSSProperties, useMemo} from 'react';
import {Collapse} from '@mui/material';
import {TransitionGroup} from 'react-transition-group';
import {PartExpand, Valx} from 'schemaDefinition/types';
import {evaluateCondition} from 'schemaDefinition/functions';
import {FieldLayout} from 'components';
import {useFormSchemaGlobalScopeContext} from 'schema/contexts';
import {BasePartProps} from './types';
import {useDataFormContext} from '../contexts';
import {verifyGlobalScope, verifyLocalScope} from '../hooks';
import {DataFormSchemaFragment} from './DataFormSchemaFragment';

type DataFormPartExpandProps = BasePartProps & {
  expand: PartExpand<Valx>;
};

const emptyGroupStyle: CSSProperties = {
  marginTop: 0,
};

export const DataFormPartExpand: React.FC<DataFormPartExpandProps> = ({
  expand,
  useValue,
  valuePath,
  scopePath,
  usesGlobalScope,
  relatedScope,
  mode,
  noLabel = false,
}) => {
  const {id} = useDataFormContext();
  const value = useValue(valuePath, id, expand.role);
  const scope = verifyLocalScope(
    useValue(scopePath, id, `${expand.role}.localScope`),
    scopePath,
  );

  const {valuePath: globalPath} = useFormSchemaGlobalScopeContext();
  const globalScope = verifyGlobalScope(
    useValue(
      usesGlobalScope ? globalPath : undefined,
      id,
      `${expand.role}.globalScope`,
    ),
    usesGlobalScope,
  );
  const activeParts = useMemo(() => {
    const activeCase = expand.when.find(w =>
      evaluateCondition(w.condition, {
        valuePath,
        localScope: scope,
        globalScope,
        relatedScope,
        value,
      }),
    );

    return activeCase?.parts ?? expand.default;
  }, [
    expand.default,
    expand.when,
    globalScope,
    relatedScope,
    scope,
    value,
    valuePath,
  ]);

  const anyParts = activeParts !== undefined && activeParts.length > 0;

  // If default provided, we will always show something
  // Otherwise use transition group to animate in/out
  return expand.default ? (
    anyParts ? (
      <FieldLayout>
        <DataFormSchemaFragment
          useValue={useValue}
          valuePath={valuePath}
          relatedScope={relatedScope}
          fragment={activeParts}
          mode={mode}
          noLabel={noLabel}
        />
      </FieldLayout>
    ) : null
  ) : (
    <TransitionGroup style={anyParts ? undefined : emptyGroupStyle}>
      {anyParts ? (
        <Collapse unmountOnExit>
          <FieldLayout>
            <DataFormSchemaFragment
              useValue={useValue}
              valuePath={valuePath}
              relatedScope={relatedScope}
              fragment={activeParts}
              mode={mode}
              noLabel={noLabel}
            />
          </FieldLayout>
        </Collapse>
      ) : null}
    </TransitionGroup>
  );
};
