import {useSelector} from 'react-redux';
import memoizeOne from 'memoize-one';
import {AppState} from 'store/types';
import {ChangeRequest, ChangeRequestType} from 'api';
import {useCreateSelectorById} from './useCreateSelectorById';

const getChangeRequests = (
  manifestationId: string,
  changeRequests: ChangeRequest[] | undefined,
) => {
  if (
    !changeRequests ||
    !changeRequests.some(
      cr =>
        cr.status !== 'COMPLETED' && cr.manifestation.id === manifestationId,
    )
  ) {
    return undefined;
  }

  const types = changeRequests
    .filter(
      cr =>
        cr.status !== 'COMPLETED' && cr.manifestation.id === manifestationId,
    )
    .map(cr => cr.taskType);

  return types.reduce<ChangeRequestType[]>((acc, t) => {
    if (!acc.includes(t)) {
      acc.push(t);
    }
    return acc;
  }, []);
};

const memoizedGetChangeRequests = memoizeOne(getChangeRequests);

const changeRequestTypesSelectorById =
  (manifestationId: string) => (state: AppState) =>
    memoizedGetChangeRequests(
      manifestationId,
      state.metadataEdit.metadata?.changeRequests,
    );

export const useChangeRequestTypes = (manifestationId: string) => {
  const selector = useCreateSelectorById(
    manifestationId,
    changeRequestTypesSelectorById,
  );
  return useSelector(selector);
};
