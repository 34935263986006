import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const HistoryIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12 4C11.7348 4 11.4805 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V12C10.9993 12.1316 11.0245 12.2621 11.0743 12.3839C11.124 12.5057 11.1974 12.6166 11.29 12.71L14.29 15.71L15.71 14.29L13 11.59V6.08C14.3489 6.30665 15.5799 6.98737 16.4889 8.00937C17.398 9.03137 17.9306 10.3333 17.9984 11.6994C18.0663 13.0655 17.6653 14.4138 16.862 15.5209C16.0587 16.6279 14.9013 17.4273 13.5815 17.7865C12.2617 18.1457 10.8588 18.0431 9.60526 17.4959C8.35171 16.9487 7.32276 15.9895 6.68893 14.7775C6.05509 13.5654 5.85441 12.1731 6.12014 10.8314C6.38587 9.48966 7.10206 8.27899 8.15004 7.4L6.87004 5.86C5.42596 7.06615 4.45322 8.7427 4.12277 10.595C3.79233 12.4473 4.12532 14.3568 5.06323 15.9879C6.00114 17.619 7.48395 18.8673 9.25105 19.5134C11.0181 20.1596 12.9565 20.1623 14.7253 19.5209C16.4942 18.8796 17.9804 17.6353 18.9227 16.0068C19.8651 14.3782 20.2033 12.4696 19.8779 10.6165C19.5525 8.76329 18.5843 7.08409 17.1435 5.87401C15.7028 4.66392 13.8816 4.00038 12 4Z" />
    </SvgIcon>
  );
};
