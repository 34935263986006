import {useLocation} from 'react-router-dom';
import qs from 'qs';
import {logError} from '../../../../logging';

export const useCallbackParams = () => {
  let {search, hash} = useLocation();

  search = search.substring(1); // Removes ?
  hash = hash.substring(1); // Removes #

  const params = {
    ...qs.parse(search),
    ...qs.parse(hash),
  };

  const {error, error_description} = params;

  if (error || error_description) {
    logError(
      `Error: ${error || '(empty)'}, description: ${
        error_description || '(empty)'
      }`,
    );
  }

  return {
    code: params.code ?? null,
    state: params.state ?? null,
    error: params.error ?? null,
  };
};
