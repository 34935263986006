import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {IconButton} from '@mui/material';
import {Fonts} from 'theme/fonts';
import {TextAreaProps} from './types';
import {useThemeColors} from '../../theme';
import {ColorPalette} from '../../theme/colorPalette';
import {LessIcon, MoreIcon} from '../icons';
import {Layout} from '../layout';

export const TextAreaReadonly: React.FC<TextAreaProps> = ({
  value,
  expandable,
}) => {
  const themeColors = useThemeColors();

  const [maxRows, setMaxRows] = useState<number | undefined>(
    expandable ? 3 : undefined,
  );
  const [showExpandHandle, setShowExpandHandle] = useState(false);
  const ref = useRef<HTMLTextAreaElement>(null);

  const textareaStyle = useMemo(
    (): React.CSSProperties => ({
      border: 0,
      background: 'transparent',
      resize: 'none',
      fontFamily: 'inherit',
      fontSize: Fonts.body1?.fontSize,
      color: 'currentcolor',
      padding: 0,
      height:
        expandable && maxRows === undefined
          ? `${(ref.current?.scrollHeight ?? 0) + 0}px`
          : undefined,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      outline: 'none',
    }),
    [maxRows, expandable],
  );

  const handleToggle = useCallback(() => {
    setMaxRows(old => (old === 3 ? undefined : 3));
  }, []);

  useEffect(() => {
    if (ref.current && expandable) {
      const clientHeight = ref.current.clientHeight;
      const scrollHeight = ref.current.scrollHeight;
      // console.log('clientHeight', clientHeight, 'scrollHeight', scrollHeight);
      setShowExpandHandle(maxRows === undefined || clientHeight < scrollHeight);
    }
  }, [value, maxRows, expandable]);

  return (
    <Layout kind="primary" sx={{borderRadius: 1, font: Fonts.body1}}>
      <textarea
        ref={ref}
        value={value ?? ''}
        rows={expandable ? maxRows : 1}
        readOnly
        style={textareaStyle}
      />
      {showExpandHandle ? (
        <Layout horizontal adjustCenter>
          <Layout
            horizontal
            flex={1}
            sx={{
              borderBottomWidth: '1px',
              borderBottomStyle: 'solid',
              borderBlockColor: ColorPalette.secondary.lightBurgundy,
              height: '0px',
            }}
          />
          {expandable ? (
            <>
              <IconButton
                onClick={handleToggle}
                sx={{color: themeColors.text.primary.dark}}>
                {maxRows ? <MoreIcon /> : <LessIcon />}
              </IconButton>
              <Layout
                horizontal
                flex={1}
                sx={{
                  borderBottomWidth: '1px',
                  borderBottomStyle: 'solid',
                  borderBlockColor: ColorPalette.secondary.lightBurgundy,
                  height: '0px',
                }}
              />
            </>
          ) : null}
        </Layout>
      ) : null}
    </Layout>
  );
};
