import React, {useMemo} from 'react';
import {RightPanelConfig} from 'components/scene/types';
import {CodeListsGate} from 'services/codeLists';
import {useAllCodeListIds} from 'services/codeLists/hooks/useAllCodeListIds';
import {AllThesaurusIds, ThesaurusesGate} from 'services/thesaurus';
import {
  SceneExtensionsContext,
  useLinkedValueSearchSceneExtensionCore,
  useSceneExtensionsContextProviderValue,
} from 'sceneExtensions';
import {SearchAgentsScene} from './SearchAgentsScene';

const searchConfig: Partial<RightPanelConfig> = {
  fullWidth: true,
};

export const AgentsScene: React.FC = () => {
  const codelistIds = useAllCodeListIds();

  const linkedValueSearchExtension =
    useLinkedValueSearchSceneExtensionCore(searchConfig);
  const extensions = useMemo(
    () => [linkedValueSearchExtension],
    [linkedValueSearchExtension],
  );

  const sceneExtensionContext =
    useSceneExtensionsContextProviderValue(extensions);

  return (
    <ThesaurusesGate thesauruses={AllThesaurusIds}>
      <CodeListsGate codeLists={codelistIds}>
        <SceneExtensionsContext.Provider value={sceneExtensionContext}>
          <SearchAgentsScene />
        </SceneExtensionsContext.Provider>
      </CodeListsGate>
    </ThesaurusesGate>
  );
};
