import React from 'react';
import {assert} from 'assert-ts';
import {Concept} from 'types';
import {PreviewConfiguration} from 'schema/types';
import {Data} from 'schemaDefinition/types';
import {ExpressionWithManifestations} from 'api';
import {useExpressionExports} from 'services/data/metadata/hooks';
import {Card, Layout, Spacer, Text} from 'components';
import {useEntitySchema} from 'schemas';
import {ReadonlyExpressionCard} from 'widgets/metadata/readonlyExpressionCard/ReadonlyExpressionCard';
import {useExpressionTitle} from 'scenes/updateMetadata/components/ExpressionCard/hooks/useExpressionTitle';
import {ManifestationPreview} from './ManifestationPreview';

type ExpressionPreviewProps = {
  expression: ExpressionWithManifestations;
  expandedManifestationId?: string;
  configuration?: Pick<
    PreviewConfiguration,
    'linking' | 'onNavigate' | 'layout' | 'relatedScope'
  >;
};
export const ExpressionPreview: React.FC<ExpressionPreviewProps> = ({
  expression,
  expandedManifestationId,
  configuration,
}) => {
  const title = useExpressionTitle(expression);
  const schemaExpression = useEntitySchema(Concept.expression);
  const expressionExports = useExpressionExports(expression);
  const configurationWithRelatedScope = React.useMemo(
    () => ({
      ...assert(configuration, 'ExpressionPreview: configuration expected'),
      relatedScope: {
        ...configuration?.relatedScope,
        expression: expressionExports,
      } as Data,
    }),
    [configuration, expressionExports],
  );

  return (
    <Card color="primary" mode="light" headerSpacing={0}>
      <Card.Header>
        <Layout horizontal flex={1} alignItems="center">
          <Text variant="h4">{title}</Text>
        </Layout>
      </Card.Header>
      <Card.Content>
        <ReadonlyExpressionCard
          expression={expression}
          schema={schemaExpression}
          configuration={configurationWithRelatedScope}
        />
        <Spacer size={2} />
        {(expression.manifestations ?? []).map(m => (
          <ManifestationPreview
            key={m.id}
            manifestation={m}
            isExpanded={m.id === expandedManifestationId}
            configuration={configurationWithRelatedScope}
          />
        ))}
      </Card.Content>
    </Card>
  );
};
