import React from 'react';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import {ThesaurusNode} from 'api/types';
import {ColorPalette} from 'theme';
import {useThesaurusEditStateContext} from '../contexts';

type Props = {
  node: ThesaurusNode;
};

export const ThesaurusNodeDetailsTitle: React.FC<Props> = ({node}) => {
  const {getDisplayCode} = useThesaurusEditStateContext();

  const code = getDisplayCode(node);

  return (
    <Box className={'labelBox thesaurusInfoBox'}>
      {code ? (
        <Box className={'outerBox'}>
          <Typography className={'nodeCode'} variant="subtitle1">
            {code}
          </Typography>
        </Box>
      ) : null}
      <Typography
        variant="h3"
        color={ColorPalette.primary.burgundy}
        fontSize={'1.2em'}>
        {node.label}
      </Typography>
    </Box>
  );
};
