import {Code} from 'schemaDefinition/types';
import {CodeListFieldProps} from '../types';

export type Spacing = {
  marginX: number;
  marginY: number;
  gutterX: number | undefined;
  gutterY: number | undefined;
};

export type Rect = {
  width: number;
  height: number;
  top: number;
  left: number;
};

export enum InputStateEnum {
  inputFocus = 'inputFocus',
  itemFocus = 'itemFocus',
}

export type InputState =
  | {
      state: InputStateEnum.inputFocus;
      focusItemCode?: undefined;
    }
  | {
      state: InputStateEnum.itemFocus;
      focusItemCode: string;
    };

export type PropsRaw = Omit<
  CodeListFieldProps,
  'label' | 'codeListName' | 'cardinality'
> &
  Required<Pick<CodeListFieldProps, 'cardinality'>> & {
    selectedCodes: Code | Code[] | null;
  };

export type InputTextFieldProps = Pick<
  PropsRaw,
  | 'value'
  | 'cardinality'
  | 'error'
  | 'name'
  | 'onBlur'
  | 'placeholder'
  | 'readonly'
  | 'onChange'
> & {
  onFocus?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;
  handleKeyCapture?: (event: React.KeyboardEvent) => void;
  inputState?: InputState;
};

export type SortableInputProps = InputTextFieldProps & {
  options: Code[];
  activeId?: string;
  handleKeyCapture?: (event: React.KeyboardEvent) => void;
  inputState?: InputState;
};
