import {DataLoadByIdStatus} from 'api/types';
import {DefaultDataLoadStatus} from 'services/utils/redux/functions';
import {LoadByIdAction} from '../actions/loadByIdAction';
import {loadReducer} from './loadReducer';

export function loadByIdReducer<TData, TId extends string | number>(
  state: DataLoadByIdStatus<TData, TId>,
  action: LoadByIdAction<TData, TId>,
): DataLoadByIdStatus<TData, TId> {
  switch (action.loadType) {
    case 'NOT_LOADED':
    case 'REQUEST':
    case 'SUCCESS':
    case 'FAILURE':
      return {
        ...state,
        [action.id]: loadReducer(
          state[action.id] ?? DefaultDataLoadStatus,
          action,
        ),
      };
  }
}
