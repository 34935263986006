import {useCallback} from 'react';
import {useStore} from 'react-redux';
import {produce} from 'immer';
import set from 'lodash/set';
import {DataValue} from 'schemaDefinition/types';
import {AppState} from 'store/types';
import {MetadataEditAction} from '../metadataEditActionTypes';

export const useSetChangeRequestEditValue = (entityId: string) => {
  const {getState, dispatch} = useStore<AppState, MetadataEditAction>();

  return useCallback(
    (valuePath: string, value: DataValue) => {
      const metadata = getState().metadataEdit.metadata;
      const changeRequest = (metadata?.changeRequests ?? []).find(
        cr => cr.id.toString() === entityId,
      );

      if (!changeRequest || !metadata) {
        return null;
      }

      const updatedChangeRequest = produce(changeRequest, draft => {
        set(draft, valuePath, value);
      });

      const action: MetadataEditAction = {
        type: 'METADATAEDIT_CHANGEREQUEST_EDITED',
        payload: updatedChangeRequest,
      };

      dispatch(action);
    },
    [dispatch, entityId, getState],
  );
};
