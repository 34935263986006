import React from 'react';
import {useLocalization} from 'localization';
import {FlexBox, Spacer} from 'components';
import {useTextLabel, useTextValue} from 'schema/preview/hooks';

export type ManifestationSubPanelItemProps = {
  fieldName: string;
  value: React.ReactNode;
};

export const ManifestationSubPanelItem: React.FC<
  ManifestationSubPanelItemProps
> = ({fieldName, value}) => {
  const {tLoose} = useLocalization();
  const TextLabel = useTextLabel();
  const TextValue = useTextValue();

  return (
    <>
      <TextValue>
        <TextLabel>{tLoose(`form.global.field.${fieldName}.label`)}</TextLabel>
        <Spacer width={0.5} />
        <FlexBox left>{value}</FlexBox>
      </TextValue>
      <Spacer size={1} />
    </>
  );
};
