import {PartNameVariant, Valx} from 'schemaDefinition/types';
import {evaluateFieldRequired} from 'schemaDefinition';
import {isNameVariantValue} from 'schemaDefinition/functions/isNameVariantValue';
import {ValidationArgs, ValidationResult} from './types';
import {fail} from './fail';
import {getCustomValidator} from './getCustomValidator';
import {validResult} from './validResult';
import {validateValueType} from './validateValueType';
import {warning} from './warning';

export const validateNameVariant = (
  part: PartNameVariant<Valx>,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(
    part,
    validateSingleNameVariantValue,
    args,
    aggregateResult,
  );
};

const validateSingleNameVariantValue = (
  part: PartNameVariant<Valx>,
  args: ValidationArgs,
  aggregateResult: ValidationResult,
): ValidationResult => {
  if (args.value === undefined || args.value === null) {
    const required = evaluateFieldRequired(
      part.required,
      args.valuePath,
      args.localScope,
      args.globalScope,
      args.relatedScope,
      args.value,
    );
    return required
      ? (required === true ? fail : warning)(
          {
            ...args,
            part,
            validation: 'required',
            messageKey: 'required',
          },
          aggregateResult,
        )
      : aggregateResult;
  }

  if (!isNameVariantValue(args.value)) {
    return fail(
      {
        ...args,
        part,
        validation: 'invalid type',
        messageKey: 'invalid type',
      },
      aggregateResult,
    );
  }

  const validator = getCustomValidator(part);

  const valid = validator ? validator(part, args.value) : 'valid';

  return valid === 'valid'
    ? aggregateResult
    : (valid === 'error' ? fail : warning)(
        {
          ...args,
          part,
          validation: 'invalid value',
          messageKey: 'invalidNameVariant',
        },
        aggregateResult,
      );
};
