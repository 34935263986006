import {PartCodeList, PartLinkedAgent, Valx} from 'schemaDefinition/types';

export const getPartCodelistFromLinkedAgent = (
  partLinkedAgent: PartLinkedAgent<Valx>,
): PartCodeList<Valx> | undefined => {
  if (!partLinkedAgent.roleCodelistId) {
    return undefined;
  }

  return {
    type: 'codelist',
    name: 'roles',
    codelistId: partLinkedAgent.roleCodelistId,
    required: true,
    cardinality: 'multiple',
  };
};
