import React from 'react';
import {FCWithChildren} from 'types';

const CardContentName = 'CardContentName';

const CardContent: FCWithChildren = ({children}) => {
  return <>{children}</>;
};

CardContent.displayName = CardContentName;

export {CardContent, CardContentName};
