import {TableConfiguration} from '../types';

export const defaultTableConfig: TableConfiguration = {
  defaultColumns: [],
  disableSorting: false,
  disableFiltering: true, // Plan to change this to false in the future.
  disableFilteringByName: {},
  disableColumnResize: false,
  autoHeight: true,
  autoRowHeight: true,
  paginationPreText: '',
  enableQuickFilter: false,
  hideFooter: true,
};
