import React, {useMemo} from 'react';
import {SxProps} from '@mui/material';
import {ColorPalette} from 'theme';
import {Layout} from 'components/layout';

type Props = {
  spacing?: string | number;
  height?: string | number;
  colorx?: string;
};

const makeBorder = (colorx: string) => `0.5px solid ${colorx}`;

const defaultSx: SxProps = {
  border: makeBorder(ColorPalette.warmGreen),
};

export const Bar: React.FC<Props> = ({spacing, height, colorx}) => {
  const sx = useMemo(
    () =>
      colorx
        ? {
            border: makeBorder(colorx),
          }
        : defaultSx,
    [colorx],
  );

  return (
    <Layout
      mx={spacing}
      height={height}
      flex={height === undefined ? 1 : undefined}
      sx={sx}
    />
  );
};
