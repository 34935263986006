import React from 'react';
import {ManifestationStatus} from 'api/types';
import {useLocalization} from 'localization';
import {FlexBox, Spacer, ToolButton} from 'components';
import {ManifestationPanel} from '../types';
import {ManifestationMoveButton} from '../../ManifestationMoveButton';
import {ManifestationStatusButton} from '../../ManifestationStatusButton';
import {ManifestationDeleteButton} from './ManifestationDeleteButton';

type Props = {
  manifestationId: string;
  manifestationStatus: ManifestationStatus;
  miniMode: boolean;
  changeStatusDisabled?: boolean;
  moveDisabled?: boolean;
  notesCount: number;
  mediaCount: number;
  infoCount: number;
  panel?: ManifestationPanel;
  onManifestationStatusPreview: (
    status: ManifestationStatus | undefined,
  ) => void;
  onManifestationStatusChanged: (status: ManifestationStatus) => void;
  onToggleManifestationPanel: (panel: ManifestationPanel) => void;
  onManifestationDelete: () => void;
  metaqUrl?: string;
  historyUrl?: string;
  ebookUrl?: string;
};

export const ManifestationButtons: React.FC<Props> = ({
  manifestationId,
  manifestationStatus,
  miniMode,
  changeStatusDisabled,
  moveDisabled,
  notesCount,
  mediaCount,
  infoCount,
  panel,
  onToggleManifestationPanel,
  onManifestationStatusPreview,
  onManifestationStatusChanged,
  onManifestationDelete,
  metaqUrl,
  ebookUrl,
  historyUrl,
}) => {
  const {t} = useLocalization();

  return (
    <FlexBox right>
      <ManifestationStatusButton
        status={manifestationStatus}
        disabled={changeStatusDisabled}
        onStatusChangePreview={onManifestationStatusPreview}
        onStatusChanged={onManifestationStatusChanged}
        miniMode={miniMode}
      />
      <Spacer size={1} />
      <ToolButton
        title={t('page.metadata.manifestation.tools.files')}
        icon="Files"
        badgeCount={mediaCount}
        checked={panel === 'files'}
        onClick={() => onToggleManifestationPanel('files')}
        iconOnly={miniMode}
      />
      <Spacer size={1} />
      <ToolButton
        title={t('page.metadata.manifestation.tools.notes')}
        icon="Comments"
        badgeCount={notesCount}
        checked={panel === 'notes'}
        onClick={() => onToggleManifestationPanel('notes')}
        iconOnly={miniMode}
      />
      <Spacer size={1} />
      <ManifestationMoveButton
        manifestationId={manifestationId}
        disabled={moveDisabled}
        miniMode={miniMode}
      />
      <Spacer size={1} />
      <ToolButton
        title={t('page.metadata.manifestation.tools.info')}
        icon="Info"
        badgeCount={infoCount}
        checked={panel === 'info'}
        onClick={() => onToggleManifestationPanel('info')}
        iconOnly={miniMode}
      />
      <Spacer size={1} />
      <ToolButton
        title={t('page.metadata.manifestation.tools.preview')}
        icon="Preview"
        onClick={() => window.open(metaqUrl, '_blank')}
        iconOnly={miniMode}
      />
      {historyUrl ? (
        <>
          <Spacer size={1} />
          <ToolButton
            title={t('page.metadata.manifestation.tools.history')}
            icon="History"
            onClick={() => window.open(historyUrl, '_blank')}
            iconOnly={miniMode}
          />
        </>
      ) : null}
      {ebookUrl ? (
        <>
          <Spacer size={1} />
          <ToolButton
            title={t('page.metadata.manifestation.tools.ebook')}
            icon="Download"
            onClick={() => window.open(ebookUrl, '_blank')}
            iconOnly={miniMode}
          />
        </>
      ) : null}
      <Spacer size={1} />
      <ManifestationDeleteButton
        onConfirm={onManifestationDelete}
        miniMode={miniMode}
      />
    </FlexBox>
  );
};
