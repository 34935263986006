import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../../store/types';
import {HeadAction} from '../actions';

const descriptionSelector = (state: AppState) => state.head.description;

export const useBrowserDescription = () => {
  const dispatch = useDispatch();
  const browserDescription = useSelector(descriptionSelector);

  const setBrowserDescription = useCallback(
    (description: string) => {
      const action: HeadAction = {
        type: 'SET_DESCRIPTION',
        payload: description,
      };
      dispatch(action);
    },
    [dispatch],
  );

  return {
    setBrowserDescription,
    browserDescription,
  };
};
