import React, {useMemo} from 'react';
import assert from 'assert-ts';
import {CurrentFilterType} from 'api/types';
import {useLocalization} from 'localization';
import {Select} from 'components';
import {BooleanOptions} from '../../types';

type Props = {
  filter: CurrentFilterType;
  onChange: (filter: CurrentFilterType) => void;
};

export const FilterBooleanValue: React.FC<Props> = ({filter, onChange}) => {
  const {t} = useLocalization();

  const options: BooleanOptions = useMemo(() => {
    return [
      {
        value: 'true',
        label: t('general.yes'),
      },
      {
        value: 'false',
        label: t('general.no'),
      },
    ];
  }, [t]);

  assert.soft(filter?.values?.[0], 'FilterBooleanValue: Expected value', {
    filter,
  });

  return (
    <Select
      value={filter?.values?.[0] ?? ''}
      options={options}
      onChange={value =>
        onChange({
          ...filter,
          values: [value],
        })
      }
    />
  );
};
