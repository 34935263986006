import {CustomFilterType, Filter} from 'api/types';

export const assertAsCustomFilterType = (
  value: Filter,
): CustomFilterType | undefined => {
  if ((value as CustomFilterType).hidden) {
    return value as CustomFilterType;
  }

  return undefined;
};

export const isNotCustomFilter = (value: Filter): boolean => {
  return assertAsCustomFilterType(value) === undefined;
};
