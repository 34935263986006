import {Part, PartCodeList, Valx} from 'schemaDefinition/types';

const partUnnamedAgentRole: Part<Valx> = {
  type: 'codelist',
  name: 'unnamedAgentRole',
  codelistId: 'UNNAMED_PERSON',
  required: true,
};

export const getPartCodelistFromUnnamedAgentRole = (): PartCodeList<Valx> =>
  partUnnamedAgentRole;
