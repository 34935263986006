import {assert} from 'assert-ts';
import {
  AgentCorporation,
  AgentEvent,
  AgentPerson,
  NationalAgent,
  NationalRegistryStatus,
} from 'api/types';
import {Schemas} from 'api/dto.generated';
import {mapAgentDto} from './mapAgent';

export const mapNationalAgentDto = (
  dto: Schemas.AuthorityRegistryWrapperDto,
): NationalAgent => {
  return {
    ...(mapAgentDto(
      assert(dto.agent, 'mapNationalAgentDto: agent is required', dto),
    ) as AgentPerson | AgentCorporation | AgentEvent),
    registryStatus: mapNationalMetadataDto(dto.metadata),
  };
};

function mapNationalMetadataDto(
  dto?: Schemas.AuthorityRegistryMetadata,
): NationalRegistryStatus | undefined {
  return dto
    ? dto.deleted
      ? 'deleted'
      : dto.status
      ? (dto.status as NationalRegistryStatus)
      : undefined
    : undefined;
}
