import React, {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import {NoProps} from 'types';
import {AppState} from 'store/types';
import {useLocalizationContextProvider} from 'localization';
import {LocalizationContext} from 'localization/context/localizationContext';
import {createCustomTheme} from 'theme';
import {useFontSizeScalePct} from 'services/settings/hooks';
import {AppRoutes} from './AppRoutes';
import {ThemedSnackbarProvider} from './ThemedSnackbarProvider';

type Props = NoProps;

const preferredLocaleSelector = (state: AppState) =>
  state.settings.preferredLocale;

export const ThemedApp: React.FC<Props> = () => {
  const preferredLocale = useSelector(preferredLocaleSelector);
  const theme = useMemo(
    () => createCustomTheme(preferredLocale),
    [preferredLocale],
  );
  const localizationContext = useLocalizationContextProvider(preferredLocale);
  const fontSizePct = useFontSizeScalePct();

  useEffect(() => {
    document.documentElement.style.setProperty('font-size', `${fontSizePct}%`);
  }, [fontSizePct]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <LocalizationContext.Provider value={localizationContext}>
          <ThemedSnackbarProvider>
            <AppRoutes />
          </ThemedSnackbarProvider>
        </LocalizationContext.Provider>
      </CssBaseline>
    </ThemeProvider>
  );
};
