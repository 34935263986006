import {assert} from 'assert-ts';
import {ValidationResult} from 'schema/form/functions/validators/types';
import {MetadataValidationResult} from 'schemas/types';
import {getValidationPrPart} from 'schema/form/functions/validators';

type TransformValidationResult = (
  validationResult: ValidationResult | undefined,
) => ValidationResult | undefined;
const transformValidationResults = (
  validation: MetadataValidationResult,
  transform: TransformValidationResult,
): MetadataValidationResult => {
  return {
    ...validation,
    work: transform(validation.work),
    expressions: validation.expressions?.map(expression => ({
      ...expression,
      validation: assert(transform(expression.validation)),
      manifestations: expression.manifestations.map(m => ({
        ...m,
        validation: assert(transform(m.validation)),
      })),
    })),
  };
};

export const getMostSevereMetadataValidationPrPart = (
  validation: MetadataValidationResult,
): MetadataValidationResult => {
  return transformValidationResults(validation, getValidationPrPart);
};
