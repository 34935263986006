import {ManifestationStatuses} from 'services/data/metadata/types';
import {ManifestationStatus} from 'api';

export const getHighestStatus = (
  a: ManifestationStatus | undefined,
  b: ManifestationStatus | undefined,
): ManifestationStatus | undefined => {
  if (!a || !b) {
    return a ?? b;
  }

  const aIdx = ManifestationStatuses.indexOf(a);
  const bIdx = ManifestationStatuses.indexOf(b);
  return bIdx > aIdx ? b : a;
};
