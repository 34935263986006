import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const DownloadIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M18.21 10.21L16.79 8.79L13 12.59V4H11V12.59L7.21004 8.79L5.79004 10.21L12 16.41L18.21 10.21Z" />
      <path d="M20 18H4V20H20V18Z" />
    </SvgIcon>
  );
};
