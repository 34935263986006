import {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {TaskTypeAll} from 'api/types';
import {AppState} from 'store/types';
import {SettingsAction} from 'services/settings';
import {getTaskTypes} from '../functions';

const selectedTaskTypeSelector = (state: AppState) =>
  state.settings.overview.selectedTaskType;

export const useTaskTypes = () => {
  const dispatch = useDispatch();
  const selectedTaskType = useSelector(selectedTaskTypeSelector);

  const setSelectedTaskType = useCallback(
    (selectedTaskType: TaskTypeAll) => {
      const action: SettingsAction = {
        type: 'SET_OVERVIEW_SELECTED_TASK_TYPE',
        payload: selectedTaskType,
      };
      dispatch(action);
    },
    [dispatch],
  );

  const taskTypes = useMemo(
    () => getTaskTypes(selectedTaskType),
    [selectedTaskType],
  );

  return {
    taskTypes,
    selectedTaskType,
    setSelectedTaskType,
  };
};
