import {useMemo} from 'react';
import {Concept} from 'types';
import {ManifestationStatus} from 'api/types';
import {Data, Schema, Valid} from 'schemaDefinition/types';
import {
  useManifestationImportProps,
  useSavedManifestation,
  useStatusAndShowError,
} from 'services/data';
import {useModifiedSchema} from 'schemas/hooks/useModifiedSchema';
import {ManifestationCardProps} from '../types';
import {useManifestationValidation} from './useManifestationValidation';

export type ManifestationEditState = {
  manifestationStatus: ManifestationStatus;
  relatedScope: Data;
  schema: Schema;
  readonly: boolean;
  valid: Valid | undefined;
  showErrors: boolean;
};

export const useManifestationEditState = ({
  manifestationId,
  expressionId,
  readonly,
}: ManifestationCardProps): ManifestationEditState => {
  const savedManifestation = useSavedManifestation(manifestationId);

  const {
    activeStatus: manifestationStatus,
    changedStatus,
    status: savedStatus,
    showError,
  } = useStatusAndShowError(manifestationId);
  const manifestationImportProps = useManifestationImportProps(
    manifestationId,
    expressionId,
  );

  const schema = useModifiedSchema(
    savedManifestation,
    manifestationImportProps.work.type,
    Concept.manifestation,
    manifestationStatus,
  );

  const valid = useManifestationValidation(
    manifestationId,
    manifestationImportProps,
    showError,
    schema,
  );

  return useMemo(() => {
    return {
      relatedScope: manifestationImportProps,
      manifestationStatus: changedStatus ?? savedStatus,
      schema,
      readonly: !!readonly,
      showErrors: showError,
      valid,
    };
  }, [
    changedStatus,
    savedStatus,
    manifestationImportProps,
    schema,
    readonly,
    showError,
    valid,
  ]);
};
