import {useMemo} from 'react';
import {ValidationResult} from 'schema/form/functions/validators/types';
import {useLocalization} from 'localization';
import {ManifestationStatus} from 'api';
import {useAssertExpressionEdit} from 'services/data';
import {Layout, Spacer, Text} from 'components';
import {useExpressionTitle} from './ExpressionCard/hooks/useExpressionTitle';

type Props = {
  entityId: string;
  status: ManifestationStatus | undefined;
  validation: ValidationResult;
};

export const ExpressionValidationErrorHeader: React.FC<Props> = ({
  entityId,
  status,
  validation,
}) => {
  const expression = useAssertExpressionEdit(entityId);
  const title = useExpressionTitle(expression, undefined);

  const localization = useLocalization();
  const {tLoose} = localization;
  const statusLabel = useMemo(() => {
    return status && validation.valid !== 'valid'
      ? tLoose(`entity.manifestation.status.short.${status}`)
      : undefined;
  }, [status, tLoose, validation.valid]);

  return (
    <Layout horizontal adjustLeft>
      <Text variant="body2" sx={{fontWeight: 'bold'}}>
        {title}
      </Text>
      <Spacer size={0.5} />
      {statusLabel ? <Text variant="body2">{`(${statusLabel})`}</Text> : null}
    </Layout>
  );
};
