import {DataValue, LinkStatuses, LinkedValueLink} from '../types';
import {isData} from './isData';

export const isLinkedValueLink = (
  value: DataValue | undefined,
): value is LinkedValueLink => {
  return (
    isData(value) &&
    LinkStatuses.includes((value as LinkedValueLink).linkStatus ?? '')
  );
};
