import {useMemo} from 'react';
import {Concept} from 'types';
import {useThesaurus} from 'services/thesaurus';
import {useModifiedSchema} from 'schemas';
import {ActualChangesWithSchema} from '../types';
import {getActualChangesWithSchema} from '../functions/getActualChangesWithSchema';
import {useAggregatedStatus} from './useAggregatedStatus';
import {useAssertChangeRequest} from './useAssertChangeRequest';
import {useAssertMetadata} from './useAssertMetadata';
import {useChangeRequestImportProps} from './useChangeRequestImportProps';

export const useActualChangesWithSchema = (
  changeRequestId: string,
): ActualChangesWithSchema | undefined => {
  const metadata = useAssertMetadata();
  const genreAndFormThesaurus = useThesaurus('genreandform');
  const subjectThesaurus = useThesaurus('bokbasen');
  const themaThesaurus = useThesaurus('thema');
  const grepThesaurus = useThesaurus('grep');

  const changeRequest = useAssertChangeRequest(changeRequestId);

  const relatedProps = useChangeRequestImportProps(
    changeRequest.manifestation.id,
    changeRequest.expression.id,
  );

  const workStatus = useAggregatedStatus(changeRequest.work.id);
  const expressionStatus = useAggregatedStatus(changeRequest.expression.id);
  const manifestationStatus = useAggregatedStatus(
    changeRequest.manifestation.id,
  );

  const modifiedManifestationSchema = useModifiedSchema(
    changeRequest.manifestation,
    relatedProps.work.type,
    Concept.manifestation,
    manifestationStatus,
  );

  const modifiedExpressionSchema = useModifiedSchema(
    changeRequest.expression,
    relatedProps.work.type,
    Concept.expression,
    expressionStatus,
  );

  const modifiedWorkSchema = useModifiedSchema(
    changeRequest.work,
    relatedProps.work.type,
    Concept.work,
    workStatus,
  );

  return useMemo(
    () =>
      getActualChangesWithSchema(
        metadata,
        changeRequest,
        {
          work: modifiedWorkSchema,
          expression: modifiedExpressionSchema,
          manifestation: modifiedManifestationSchema,
        },
        {
          genreandform: genreAndFormThesaurus,
          bokbasen: subjectThesaurus,
          thema: themaThesaurus,
          grep: grepThesaurus,
        },
      ),
    [
      changeRequest,
      genreAndFormThesaurus,
      grepThesaurus,
      metadata,
      modifiedExpressionSchema,
      modifiedManifestationSchema,
      modifiedWorkSchema,
      subjectThesaurus,
      themaThesaurus,
    ],
  );
};
