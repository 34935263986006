import {useMemo} from 'react';
import {AgentSubType, Concept} from 'types';
import {Agent} from 'api/types';
import {Schema} from 'schemaDefinition/types';
import {SchemaMode, SchemaReference} from 'schemas/types';
import {getModifiedSchemaWithModifier} from 'schemaDefinition';
import {getAgentSchemaModifiers} from '../functions';
import {useSchema} from './useSchema';

export const useAgentSchema = (
  agentType: AgentSubType,
  schemaMode: SchemaMode = 'full',
  initialAgent?: Agent,
) => {
  const schemaRef = useMemo(
    (): SchemaReference => ({entity: Concept.agent, subType: agentType}),
    [agentType],
  );
  const schemaForMode = useSchema(schemaRef, schemaMode);
  return useMemo(() => {
    const schemaModifiers = getAgentSchemaModifiers(initialAgent);
    const modifiedSchema =
      schemaModifiers.length > 0
        ? schemaModifiers.reduce<Schema>((accSchema, modifier) => {
            return getModifiedSchemaWithModifier(accSchema, modifier);
          }, schemaForMode)
        : schemaForMode;

    return modifiedSchema;
  }, [initialAgent, schemaForMode]);
};
