import React from 'react';
import {Media} from 'api/types';
import {useDownloadFile, usePreviewFile} from 'services/data';
import {useHandleDelayedCall} from 'services/utils';
import {Layout, ToolBar} from 'components';

type Props = {
  file: Media;
  mock?: boolean;
};

export const FileToolbar: React.FC<Props> = ({file, mock = false}) => {
  const {preview} = usePreviewFile(file, mock);
  const {download} = useDownloadFile(file, mock);

  const handlePreviewDelayed = useHandleDelayedCall(preview);
  const handleDownloadDelayed = useHandleDelayedCall(download);

  return (
    <Layout horizontal adjustCenter adjustRight sx={{width: '100%'}}>
      <ToolBar spacing="tight">
        <ToolBar.ControlledIconTool
          icon="Download"
          size="small"
          onClick={handleDownloadDelayed.handleCall}
          isCalling={handleDownloadDelayed.isCalling}
          isDelayed={handleDownloadDelayed.isDelayed}
        />
        <ToolBar.ControlledIconTool
          icon="Preview"
          size="small"
          onClick={handlePreviewDelayed.handleCall}
          isCalling={handlePreviewDelayed.isCalling}
          isDelayed={handlePreviewDelayed.isDelayed}
        />
      </ToolBar>
    </Layout>
  );
};
