import {assert} from 'assert-ts';
import dayjs from 'dayjs';
import {Locale, MasterLocale} from 'localization/types';
import {formatDateNo} from 'localization/core/formatting/formatDateNo';
import {DateTimeValue, Variant} from '../core/formatting/types';
import {LocalizationContextType} from './types';
import {dateToDayJs} from '../../services/utils/functions/dateToDayJs';
import {
  formatAmount,
  formatDate,
  formatRelativeTime,
  formatTime,
  formatYear,
  getApiDateFormat,
  getDayjsLocale,
  getLocale,
  translate,
  tryTranslate,
} from '../core';

const isNullOrUndefined = (value: unknown): boolean =>
  value === null || value === undefined;

export const getLocalizationContext = (
  locale?: Locale,
): LocalizationContextType => {
  const dateFormat = assert(
    getDayjsLocale(locale ?? MasterLocale).formats.L,
    'getLocalizationContext::dateFormat locale missing format L',
    getDayjsLocale(locale ?? MasterLocale),
  );

  const apiDateFormat = getApiDateFormat();

  const isNorway =
    (locale ?? MasterLocale) === 'nb' || (locale ?? MasterLocale) === 'nn';

  return {
    locale,
    dayjsLocale: getDayjsLocale(locale ?? MasterLocale),
    dateFormat,
    dateFromApiFormat: value =>
      isNullOrUndefined(value)
        ? null
        : typeof value === 'string'
          ? dateToDayJs(value) // Handles year before 100, defaults to 19
          : dayjs(value),
    dateToApiFormat: value =>
      isNullOrUndefined(value)
        ? null
        : (typeof value === 'string'
            ? dateToDayJs(value) // Handles year before 100, defaults to 19
            : dayjs(value)
          ).format(apiDateFormat),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    t: (key: string, args?: any) => translate(key as any, args),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tLoose: (key: string, args?: any) => translate(key as any, args),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tryT: (key: string, args?: any, caseInsentitive?: boolean) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tryTranslate(key as any, args, caseInsentitive),
    formatDate: (
      date?: DateTimeValue,
      variant?: Variant,
      withTime?: boolean,
    ) =>
      isNorway
        ? formatDateNo(date, variant, withTime)
        : formatDate(date, variant, withTime),
    formatYear: (date?: DateTimeValue, variant?: Variant) =>
      formatYear(date, variant),
    formatTime: (time?: DateTimeValue, variant?: Variant) =>
      formatTime(time, variant),
    formatRelativeTime: (toDateTime: DateTimeValue) =>
      formatRelativeTime(toDateTime),
    formatAmount: (value: number, decimals?: number) =>
      formatAmount(value, decimals),
    getPreferredLocale: () => getLocale(),
  };
};
