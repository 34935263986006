import {CodeListRef} from 'api/types';
import {Schemas} from 'api/dto.generated';

const dto: Schemas.WorkFlattenedDto = {
  work: {
    id: 'e5d06718-29e1-49d5-93d6-95e933224553',
    created: 1688630245667,
    modified: 1688633417131,
    titleInfo: {
      preferredTitle: ['Malacandra'],
      otherTitles: [],
    },
    languages: ['nob'],
    agents: [
      {
        roles: ['A01'],
        agentId: 'c9fdd1f0-c9a8-4075-ba9f-ed8bb1ac2d34',
        agentType: 'PERSON',
        agentName: {
          id: 'dee2601b-8b81-4519-9fb1-e2a43507143b',
          name: 'C.S.',
          surName: 'Lewis',
          nameOrder: 0,
        },
        nationalId: '90089794',
      },
    ],
    dewey: [
      {
        source: '15',
        value: '200',
      },
    ],
    intellectualLevel: ['15'],
    literatureType: [CodeListRef.LITERATURE_TYPE.Skjønnlitteratur],
    themes: ['FL'],
    subjects: ['form_romaner', 'genre_sciencefiction'],
    seriesRelations: [],
    workRelations: [],
    agentRelations: [],
  },
  expressions: [
    {
      id: '6b163264-00e0-4a6b-9a9a-c17ed05242cd',
      workId: 'e5d06718-29e1-49d5-93d6-95e933224553',
      created: 1688630245668,
      modified: 1688630245668,
      expressionFormat: 'TEXT',
      languages: ['nob'],
      content: [],
      agents: [],
    },
  ],
  manifestations: [
    {
      id: '878fecd7-6af2-4798-bbca-541a1061aa03',
      expressionId: '6b163264-00e0-4a6b-9a9a-c17ed05242cd',
      created: 1688630245668,
      modified: 1688633417131,
      status: 'PRE_CATALOGED',
      isbn: '9788283850451',
      ean: '9788283850451',
      mainTitle: 'Malacandra',
      edition: 1,
      publishedYear: 2023,
      contentReceivedDate: '2023-05-31',
      productForm: 'BB',
      productFormDetail: [],
      bookGroup: '421',
      productGroup: '41010',
      externalSystemInfo: {
        ids: [150463124],
        created: '2023-04-26T10:15:17Z',
        modified: '2023-07-06T10:46:59Z',
      },
      titleInfo: {
        mainTitles: [
          {
            subTitles: [
              {
                parallelTitles: [],
                value: 'reisen',
                order: 1,
              },
            ],
            parallelTitles: [],
            value: 'Malacandra',
            order: 1,
          },
        ],
        otherTitles: [],
      },
      generalNote: [],
      editionNote: [],
      editionType: [],
      seriesRelations: [],
      imprints: [
        {
          agentId: 'b1f5121b-5e37-4f1a-9239-b9708dfc571d',
          agentName: {
            id: '4062b6f8-de9e-4c4a-9aa2-f02db7d4c15c',
            name: 'Veritas forlag',
            nameOrder: 0,
          },
        },
      ],
      subTitles: ['reisen'],
      productOwners: [
        {
          code: '0046',
          name: 'Lunde Forlag AS',
        },
      ],
      marketingData: {
        products: [
          {
            owner: '0046',
            distributor: 'FS',
          },
        ],
      },
    },
    {
      id: 'c5682428-7fc0-4b80-8dfd-4cd51662b7fd',
      expressionId: '6b163264-00e0-4a6b-9a9a-c17ed05242cd',
      created: 1688630275496,
      modified: 1692688892372,
      status: 'NEW',
      isbn: '9788283850505',
      ean: '9788283850505',
      mainTitle: 'Malacandra',
      edition: 1,
      publishedYear: 2023,
      contentReceivedDate: '2023-07-06',
      productForm: 'ED',
      productFormDetail: ['E101'],
      physicalProperties: [],
      bookGroup: '854',
      externalSystemInfo: {
        ids: [150466186],
        created: '2023-07-06T09:56:48Z',
        modified: '2023-08-14T11:30:19Z',
      },
      titleInfo: {
        mainTitles: [
          {
            subTitles: [
              {
                parallelTitles: [],
                value: 'Reisen',
                order: 1,
              },
            ],
            parallelTitles: [],
            value: 'Malacandra',
            order: 1,
          },
        ],
        otherTitles: [],
      },
      generalNote: [],
      editionNote: [],
      editionType: [],
      seriesRelations: [],
      imprints: [],
      subTitles: ['Reisen'],
      productOwners: [
        {
          code: '3751',
          name: 'eBokNorden AS',
        },
      ],
      marketingData: {
        products: [
          {
            owner: '3751',
            distributor: 'DDS',
          },
        ],
      },
    },
  ],
};

export default dto;
