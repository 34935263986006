import {CategoryNodesWithAncestors, NodeAndAncestors} from '../types';

/**
 * Groups
 * @returns
 */
export const groupPrCategory = <T extends NodeAndAncestors>(
  nodes: T[],
): CategoryNodesWithAncestors<T>[] => {
  const grouped = nodes.reduce<CategoryNodesWithAncestors<T>[]>((acc, n) => {
    const categoryNode = n.ancestors.length > 1 ? n.ancestors[1] : n.node;
    let categoryGroup = acc.find(c => c.categoryNode.id === categoryNode.id);
    if (!categoryGroup) {
      categoryGroup = {
        categoryNode,
        nodes: [],
      };
      acc.push(categoryGroup);
    }

    categoryGroup.nodes.push(n);
    return acc;
  }, []);

  return grouped;
};
