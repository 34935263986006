import {PartThesaurus} from 'schemaDefinition/types';
import {Thesaurus} from 'api';
import {getActualThesaurusCategoryPaths} from 'services/data/metadata/functions/getActualThesaurusCategoryPaths';
import {PerGroupDiff} from './types';

export const getThesaurusDiffPerCategory = (
  part: PartThesaurus<unknown>,
  valuePath: string,
  originalValue: string[] | undefined,
  changedValue: string[] | undefined,
  thesaurus: Thesaurus,
): PerGroupDiff => {
  const {paths, groupedOriginal, groupedChanges} =
    getActualThesaurusCategoryPaths(
      part,
      valuePath,
      originalValue ?? null,
      changedValue ?? null,
      {
        [part.thesaurusId]: thesaurus,
      },
    );

  const groupedOriginalTerms = groupedOriginal?.map(g => ({
    groupId: g.categoryNode.id,
    groupName: g.categoryNode.label,
    groupTerms: g.nodes.map(n => n.node.code),
  }));
  const groupedChangesTerms = groupedChanges?.map(g => ({
    groupId: g.categoryNode.id,
    groupName: g.categoryNode.label,
    groupTerms: g.nodes.map(n => n.node.code),
  }));

  const perGroupDiff = (paths ?? []).map(groupPath => {
    const [, groupId] = groupPath.split('#');
    const originalTerms = groupedOriginalTerms?.find(
      g => g.groupId === groupId,
    )?.groupTerms;
    const changesTerms = groupedChangesTerms?.find(
      g => g.groupId === groupId,
    )?.groupTerms;
    return {
      groupId: groupId,
      groupName: (groupedOriginalTerms ?? groupedChangesTerms)?.find(
        g => g.groupId === groupId,
      )?.groupName,
      groupPath: groupPath,
      originalTerms,
      changesTerms,
    };
  });

  return perGroupDiff;
};
