import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../../store/types';
import {HeadAction} from '../actions';

const titleSelector = (state: AppState) => state.head.title;

export const useBrowserTabTitle = () => {
  const dispatch = useDispatch();
  const browserTabTitle = useSelector(titleSelector);

  const setBrowserTabTitle = useCallback(
    (title?: string) => {
      const action: HeadAction = {
        type: 'SET_TITLE',
        payload: title,
      };
      dispatch(action);
    },
    [dispatch],
  );

  return {
    setBrowserTabTitle,
    browserTabTitle,
  };
};
