import {LocalizationContextType} from 'localization/context/types';
import {Schema, Valx} from 'schemaDefinition/types';
import {getFieldRootKeys} from './getFieldRootKeys';
import {getFieldString} from './getFieldString';

export const getBoxLabel = (
  localization: LocalizationContextType,
  {
    labelKey,
    name,
  }: {
    labelKey?: string;
    name?: string;
  },
  schema: Schema<Valx>,
): string | undefined => {
  const rootKeys = getFieldRootKeys(schema.key, false, labelKey, name);
  return getFieldString(rootKeys, false, '.label.box', localization);
};
