import {
  DataValue,
  LinkedAgentMultiRole,
  LinkedLiterary,
} from 'schemaDefinition/types';
import {isLinkedValue} from './isLinkedValue';

export const isLinkedLiterary = (
  value: DataValue | undefined,
): value is LinkedLiterary =>
  // (Potential) LinkedLiterary if LinkedValue and not LinkedAgent
  isLinkedValue(value) && !(value as LinkedAgentMultiRole).roles;
