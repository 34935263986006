import get from 'lodash/get';
import {Data} from 'schemaDefinition/types';

export const cleanValueAtPath = <TData extends Data>(
  path: string,
  value: TData | TData[],
) => {
  if (Array.isArray(value)) {
    value.forEach(item => {
      cleanValueAtPath(path, item);
    });
    return;
  }

  const steps = path.split('.');
  if (steps.length === 1) {
    if (value && typeof value === 'object' && value[path] !== undefined) {
      delete value[path];
    }
    return;
  }

  const rootPropName = steps[0];
  const subPath = steps.slice(1).join('.');
  const subValue = get(value, rootPropName) as Data;
  if (subValue) {
    cleanValueAtPath(subPath, subValue);
  }
};
