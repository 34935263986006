import React, {useCallback, useMemo, useState} from 'react';
import {ColumnSet, ColumnWithLabel} from 'api/types';
import {useLocalization} from 'localization';
import {
  CheckboxField,
  Dialog,
  FieldLabel,
  Layout,
  Spacer,
  TextField,
} from 'components';
import {advancedSearchDefaultColumns} from 'schemas/configurations/table/columns';
import {ColumnItems, CurrentColumnsType} from '../types';
import {ColumnPicker} from '../components/ColumnPicker';

const defaultPicked = advancedSearchDefaultColumns.map(a => a.name);

export const useCreateColumnSetDialog = (
  allColumns: ColumnWithLabel[],
  onSave: (
    name: string,
    columns: string[],
    isPublic: boolean,
  ) => Promise<ColumnSet>,
  postSave: CurrentColumnsType['setCurrentColumns'],
) => {
  const {t} = useLocalization();
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [columns, setColumns] = useState<ColumnItems>({
    pickableColumns: allColumns
      .map(c => c.name)
      .filter(c => !defaultPicked.includes(c)),
    pickedColumns: defaultPicked,
  });

  const resetState = useCallback(
    (open: boolean) => {
      setName('');
      setColumns({
        pickableColumns: allColumns
          .map(c => c.name)
          .filter(c => !defaultPicked.includes(c)),
        pickedColumns: defaultPicked,
      });
      setIsPublic(false);
      setOpen(open);
    },
    [allColumns],
  );

  const handleSave = useCallback(
    (name: string, columns: string[], isPublic: boolean) => {
      onSave(name, columns, isPublic).then(() => {
        if (postSave) {
          postSave(columns);
        }
        resetState(false);
      });
    },
    [onSave, postSave, resetState],
  );

  const Form = useMemo(() => {
    return (
      <Dialog
        isOpen={open}
        title={t('page.search.columnSet.create.title')}
        body={
          <Layout flex={1}>
            <FieldLabel label={t('form.global.field.name.label')} required />
            <TextField
              type={'text'}
              name={'name'}
              required={true}
              value={name}
              onChange={e => setName(e.target.value ?? '')}
            />

            <Spacer height={2} />

            <CheckboxField
              fieldId={'isPublic'}
              name={'isPublic'}
              value={isPublic}
              onChange={value => setIsPublic(value)}
              boxLabel={t('data.field.isPublic.label')}
            />

            <Spacer height={2} />

            <FieldLabel required label={t('data.field.columns.label')} />
            <ColumnPicker columns={columns} setColumns={setColumns} />
          </Layout>
        }
        dialogItems={[
          {
            itemType: 'action',
            title: t('general.save'),
            onClick: () => handleSave(name, columns.pickedColumns, isPublic),
          },
          {
            itemType: 'action',
            type: 'reset',
            alignLeft: true,
            title: t('general.cancel'),
            onClick: () => resetState(false),
          },
        ]}
      />
    );
  }, [open, t, name, columns, isPublic, handleSave, resetState]);

  return {
    CreateColumnSetDialog: Form,
    openCreateColumnSetDialog: () => resetState(true),
  };
};
