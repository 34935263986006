import {useMemo} from 'react';
import {ColorPalette} from 'theme';
import {PercentageCircleSvg} from './ProgressCircleSvg';

type Size = 'small' | 'medium' | 'large';

type Props = {
  /** Percentage: 0 - 100 */
  value: number;
  color?: string;
  background?: string;
  size?: Size | number;
};

const sizeMap = {
  small: 16,
  medium: 20,
  large: 24,
};

export const ProgressCircle = (props: Props) => {
  const {color, background, size} = useMemo(() => {
    const s = props.size ?? 'medium';
    const size = typeof s === 'string' ? sizeMap[s] : s;

    return {
      size,
      color: props.color ?? ColorPalette.burgundy,
      background: props.background ?? ColorPalette.brown,
    };
  }, [props]);

  return (
    <PercentageCircleSvg
      percentage={props.value}
      size={size}
      percentageColor={color}
      backgroundColor={background}
    />
  );
};
