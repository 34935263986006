import {OverviewTask} from 'api/types';

export const searchTasks = (
  tasks: OverviewTask[],
  searchText: string,
): OverviewTask[] => {
  if (!searchText) {
    return tasks;
  }

  const lowerCaseSearchText = searchText.toLocaleLowerCase();
  return tasks.filter(t =>
    t.searchableTaskString.includes(lowerCaseSearchText),
  );
};
