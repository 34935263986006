import {Lock, User} from 'api/types';

export enum LockState {
  Initial = 'Initial',
  LockedByOther = 'LockedByOther',
  NotLocked = 'NotLocked',
  /**
   * After acquiring lock or locked by this user in other context (browser tab)
   */
  TemporarilyLocked = 'TemporarilyLocked',
  LockedByThis = 'LockedByThis',
}

export type LockProps = {
  state: LockState;
  lock?: Lock;
  byOtherUser?: User;
  acquireLock: () => Promise<void>;
};
