import {
  ExpressionWithManifestations,
  WorkMetadata,
  WorkWithExpressions,
} from '../types';

export const mapWorkMetadataToWorkWithExpressions = (
  workMetadata: WorkMetadata,
): WorkWithExpressions => {
  const {work, expressions, manifestations} = workMetadata;

  const expressionsWithManifestations = expressions.map(e => {
    return {
      ...e,
      manifestations: manifestations.filter(m => m.expressionId === e.id),
    } as ExpressionWithManifestations;
  });

  return {
    ...work,
    expressions: expressionsWithManifestations,
  };
};
