import {useCallback, useMemo} from 'react';
import {GridRowSpacing, GridRowSpacingParams} from '@mui/x-data-grid-pro';
import {Data, Schema} from 'schemaDefinition/types';
import {UXUnit} from 'theme/settings';
import {isMultiple} from 'schema/form/functions';
import {TablePart} from '../types';

type GetRowHeightParam = {
  id: string | number;
  densityFactor: number;
};

type GetRowHeight = (param: GetRowHeightParam) => number | undefined | 'auto';

type GetRowSpacing = (params: GridRowSpacingParams) => GridRowSpacing;

type DataWithId = Data & {id: string | number};

const isMultilinePart = (part: TablePart) =>
  part.type === 'nameVariant' || isMultiple(part.cardinality);

export const useRowFunctions = (
  schema: Schema,
  rows?: Data[],
  autoRowHeight?: boolean,
): {
  getRowHeight: GetRowHeight | undefined;
  getRowSpacing: GetRowSpacing | undefined;
} => {
  const variableHeightParts = useMemo(() => {
    const parts = schema.parts as TablePart[];
    return parts.filter(p => isMultilinePart(p));
  }, [schema.parts]);

  const getRowHeight = useCallback(
    (dataRows: Data[] | undefined, param: GetRowHeightParam) => {
      const {id, densityFactor} = param;
      const row = dataRows
        ? dataRows.find(v => (v as DataWithId).id === id)
        : undefined;
      if (!row) return undefined;

      const height = variableHeightParts.reduce<number>((h, part) => {
        const val = row[part.name] as Array<unknown> | undefined;
        const valHeight =
          Array.isArray(val) && val.length > 1
            ? val.length * UXUnit * 2.9 * densityFactor + UXUnit * 2
            : 0;

        return Math.max(valHeight, h);
      }, 0);
      return height === 0 ? undefined : height;
    },
    [variableHeightParts],
  );

  return useMemo(() => {
    return {
      getRowHeight: autoRowHeight
        ? () => 'auto'
        : variableHeightParts.length > 0
        ? (param: GetRowHeightParam) => getRowHeight(rows, param)
        : undefined,
      getRowSpacing: autoRowHeight
        ? () => {
            return {
              top: UXUnit,
              bottom: UXUnit,
            };
          }
        : undefined,
    };
  }, [autoRowHeight, getRowHeight, rows, variableHeightParts.length]);
};
