import {stepLabelClasses} from '@mui/material/StepLabel';
import {createTheme} from '@mui/material/styles';
import {enUS, nbNO} from '@mui/x-data-grid';
import {Locale, MasterLocale} from '../localization/types';
import {ColorPalette} from './colorPalette';
import {Fonts} from './fonts';
import {px2rem} from './px2rem';
import {UXUnit} from './settings';
import {ThemeColors} from './themeColors';

export const createCustomTheme = (locale: Locale | undefined = MasterLocale) =>
  createTheme(
    {
      breakpoints: {
        values: {
          xs: 0,
          sm: 1040,
          md: 1200,
          lg: 2000,
          xl: 2600,
        },
      },
      typography: {
        fontFamily: ['ABCMonumentGrotesk', 'sans-serif'].join(','),
        ...Fonts,
      },
      spacing: UXUnit,
      palette: {
        background: {},
        primary: {
          main: ColorPalette.burgundy,
          // TODO: Figure out: when are these colors applied, #F00, #88F, #0B0, #95a
          light: '#F0F', // ColorPalette.offWhite,
          dark: ColorPalette.burgundy,
          contrastText: ColorPalette.offWhite,
        },
        secondary: {
          main: ColorPalette.textSecondary, //  ColorPalette.lightBeige,
          light: '#88F', // ColorPalette.offWhite,
          dark: '#226', // ColorPalette.brown,
          contrastText: '#0B0', // ColorPalette.burgundy,
        },
        text: {
          primary: ColorPalette.burgundy,
          secondary: ColorPalette.textSecondary,
          disabled: ColorPalette.burgundy50,
        },
        error: {
          main: ColorPalette.error,
        },
        warning: {
          main: ColorPalette.warning,
        },
        success: {
          main: ColorPalette.success,
        },
        approve: {
          main: ColorPalette.approve,
        },
        reject: {
          main: ColorPalette.reject,
        },
      },
      components: {
        MuiBadge: {
          styleOverrides: {
            badge: {
              background: ThemeColors.text.primary.light,
              ...Fonts.body2,
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              '@media (min-width: 0px)': {
                minHeight: px2rem(46),
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
              ...Fonts.button,
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              ':hover': {
                borderRadius: '16px',
              },
              borderRadius: '16px',
            },
          },
        },
        // Metadata input styling
        MuiInputLabel: {
          styleOverrides: {
            root: {
              paddingBottom: `${UXUnit}px!important`,
              ...Fonts.label,
              color: ColorPalette.secondary.text,
            },
          },
        },
        // Metadata input styling
        MuiInputBase: {
          styleOverrides: {
            root: {
              ...Fonts.body1,
              border: 'none',
              boxShadow: `0 0 0 1.25px ${ColorPalette.primary.warmGreen}`,
              outline: 'none',
              transition: '.1s',
              ':focus-within, :hover': {
                boxShadow: `0 0 0 2px ${ColorPalette.primary.warmGreen}`,
              },
              '&.Mui-error': {
                boxShadow: `0 0 0 1.25px ${ColorPalette.error}`,
              },
              '&.Mui-error:focus-within, &.Mui-error:hover': {
                boxShadow: `0 0 0 2px ${ColorPalette.error}`,
              },
              '&.MuiOutlinedInput-root': {
                '&.Mui-disabled': {
                  opacity: 0.6,
                },
              },
            },
            input: {
              color: ColorPalette.primary.burgundy,
              '::selection': {
                background: ColorPalette.primary.lightGreen,
              },
              '::placeholder': {
                color: ColorPalette.primary.warmGreen,
              },
            },
            inputMultiline: {
              color: ColorPalette.primary.burgundy,
              padding: '0!important',
              '::selection': {
                background: ColorPalette.primary.lightGreen,
              },
            },
          },
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: {
              '&.MuiInputAdornment-positionEnd': {
                marginLeft: '0',
              },
            },
          },
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              // Same padding t/r/b/l to have symmetric, circular checkbox hover
              padding: '3px!important',
              // Use margins to adjust position
              margin: '0 0 0 2px!important',
            },
          },
        },
        MuiRadio: {
          styleOverrides: {
            root: {
              // Same padding t/r/b/l to have symmetric, circular hover
              padding: '4px!important',
              // Use margins to adjust position
              margin: '0 2px 0 4px!important',
            },
          },
        },
        // Metadata input styling
        MuiFormControl: {
          styleOverrides: {
            root: {
              background: ColorPalette.primary.white,
            },
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            label: {
              ...Fonts.label,
            },
          },
        },
        // Metadata input styling
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              border: 'none',
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              ...Fonts.body1,
              textTransform: 'capitalize',
              borderRadius: `${UXUnit}px`,
            },
            label: {
              overflow: 'visible',
            },
          },
        },
        MuiAvatar: {
          styleOverrides: {
            root: {
              // Hard code 24px to match icon sizes in svg
              width: '24px', // px2rem(24),
              height: '24px', // px2rem(24),
              background: 'transparent',
            },
          },
        },
        MuiTextField: {
          styleOverrides: {
            root: {
              borderRadius: `${UXUnit}px`,
            },
          },
        },
        MuiStep: {
          styleOverrides: {
            root: {
              marginRight: `${2 * UXUnit}px`,
              padding: 0,
            },
          },
        },
        MuiStepButton: {
          styleOverrides: {
            root: {
              margin: 0,
              padding: 0,
            },
          },
        },
        MuiStepLabel: {
          styleOverrides: {
            iconContainer: {
              padding: 0,
              visibility: 'hidden',
              width: 0,
            },
            label: {
              padding: `${px2rem(6)} ${px2rem(12)}`,
              borderRadius: 9999,
              [`&.${stepLabelClasses.active}`]: {
                backgroundColor: ColorPalette.burgundy,
                color: ColorPalette.offWhite,
              },
            },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              ...Fonts.body2,
              backgroundColor: ColorPalette.primary.burgundy,
              color: ColorPalette.offWhite,
              borderRadius: `${UXUnit}px`,
              padding: `${UXUnit}px ${2 * UXUnit}px`,
              marginTop: `${4 * UXUnit}px!important`,
            },
            arrow: {
              color: ColorPalette.primary.burgundy,
            },
          },
          defaultProps: {
            arrow: true,
          },
        },
        MuiSnackbar: {
          styleOverrides: {
            root: {
              top: `${4 * UXUnit}px`,
              borderRadius: `${4 * UXUnit}px`,
            },
          },
          //   variants: [
          //     {
          //       props: {
          //         key: 'success',
          //       },
          //       style: {
          //         backgroundColor: ColorPalette.success,
          //       },
          //     },
          //     {
          //       props: {key: 'error'},
          //       style: {
          //         backgroundColor: ColorPalette.error,
          //       },
          //     },
          //     {
          //       props: {key: 'warning'},
          //       style: {
          //         backgroundColor: ColorPalette.warmGreen,
          //       },
          //     },
          //   ],
        },
        MuiAlert: {
          styleOverrides: {
            root: {
              borderRadius: `${4 * UXUnit}px`,
            },
          },
          variants: [
            {
              props: {severity: 'success'},
              style: {
                backgroundColor: ColorPalette.success,
              },
            },
            {
              props: {severity: 'error'},
              style: {
                backgroundColor: ColorPalette.error,
              },
            },
            {
              props: {severity: 'warning'},
              style: {
                backgroundColor: ColorPalette.warmGreen,
              },
            },
          ],
        },
      },
      shape: {
        borderRadius: UXUnit,
      },
    },
    locale === 'en' ? enUS : nbNO,
  );
