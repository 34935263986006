import {useMemo} from 'react';
import {assert} from 'assert-ts';
import {useAssertMetadataEdit} from './useAssertMetadataEdit';

export const useAssertChangeRequest = (id: string) => {
  const {changeRequests} = useAssertMetadataEdit();
  return useMemo(
    () =>
      assert(
        changeRequests.find(cr => cr.id === id),
        'useAssertChangeRequest: unknown id',
      ),
    [changeRequests, id],
  );
};
