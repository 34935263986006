import {PreviewConfiguration} from 'schema/types';
import {Isbn, MainTitles, ProductOwner, Products} from 'schemas/renderers';

export const manifestationPreviewConfig: PreviewConfiguration = {
  layout: {
    linkedValuesByRoles: true,
    groups: {
      info: {
        direction: 'horizontal',
      },
    },
    parts: {
      notes: {
        direction: 'horizontal',
      },
    },
  },
  partValueRenderers: {
    productOwners: ProductOwner,
    products: Products,
    mainTitles: MainTitles,
    isbn: Isbn,
  },
};
