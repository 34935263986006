import {useLocalization} from 'localization';
import {Text} from 'components';

type Props = {
  count: number;
  from: number;
  to: number;
};
export const SearchResultRange: React.FC<Props> = ({count, from, to}) => {
  const {t} = useLocalization();
  return !count ? null : count === 1 ? (
    <Text variant="body2">{`1 ${t('general.of')} 1`}</Text>
  ) : (
    <Text variant="body2">{`${from} - ${to} ${t('general.of')} ${count}`}</Text>
  );
};
