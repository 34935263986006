import React from 'react';
import {Avatar as MuiAvatar} from '@mui/material';
import {IconProps} from 'components/icons/types';
import {UserIcon} from '../icons';
import {Layout} from '../layout';

type Props = {
  src?: string;
  color?: IconProps['color'];
};

export const Avatar: React.FC<Props> = ({src, color}) => {
  return (
    <MuiAvatar src={src} data-sentry-block>
      <Layout kind="primary">
        <UserIcon color={color} />
      </Layout>
    </MuiAvatar>
  );
};
