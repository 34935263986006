import {HttpError} from 'api/http/types';
import {LoadByIdAction} from '../actions/loadByIdAction';

export function makeLoadByIdActionFactories<
  TData,
  TId extends string | number,
  TActionType,
>(
  type: TActionType,
): {
  makeRequestAction: (
    id: TId,
  ) => LoadByIdAction<TData, TId> & {type: TActionType};
  makeSuccessAction: (
    id: TId,
    data: TData,
  ) => LoadByIdAction<TData, TId> & {type: TActionType};
  makeFailureAction: (
    id: TId,
    error: HttpError,
  ) => LoadByIdAction<TData, TId> & {type: TActionType};
} {
  return {
    makeRequestAction: (id: TId) => ({
      type,
      id,
      loadType: 'REQUEST',
    }),
    makeSuccessAction: (id: TId, data: TData) => ({
      type,
      id,
      loadType: 'SUCCESS',
      payload: {data},
    }),
    makeFailureAction: (id: TId, error: HttpError) => ({
      type,
      id,
      loadType: 'FAILURE',
      payload: {error},
    }),
  };
}
