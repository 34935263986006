import {Metadata} from 'services/data/metadata/types';
import {ManifestationV4} from 'api';
import {MetadataEditAction} from 'services/data/metadata/metadataEditActionTypes';
import {changeManifestationExpressionId} from './changeManifestationExpressionId';

export function cancelMoveManifestationToNewExpression(
  movedManifestation: ManifestationV4,
  savedManifestation: ManifestationV4,
  metadata: Metadata,
  action: MetadataEditAction | undefined,
): Metadata {
  // Remove new expression
  const metadataWithoutNewExpression: Metadata = {
    ...metadata,
    expressions: metadata.expressions.filter(
      e => e.id !== movedManifestation.expressionId,
    ),
  };

  // Undo move of manifestation including any change requests
  const metadataUndoMove = changeManifestationExpressionId(
    movedManifestation.id,
    savedManifestation.expressionId,
    metadataWithoutNewExpression,
    action,
  );

  // Replace edited manifestation with saved manifestation to cancel all changes
  const newMetadata: Metadata = {
    ...metadataUndoMove,
    manifestations: metadataUndoMove.manifestations.map(m =>
      m.id === savedManifestation.id ? savedManifestation : m,
    ),
  };

  return newMetadata;
}
