import {Concept} from 'types';
import {ModifiableSchemaReference} from 'schemas/types';
import {GetDataBasedModifier} from 'services/data/metadata/types';
import {getManifestationBasedModifier} from 'schemas/schemas';

export const getGetDataBasedSchemaModifiers = (
  schemaRef: ModifiableSchemaReference,
): GetDataBasedModifier | undefined => {
  switch (schemaRef) {
    case Concept.work:
    case Concept.expression: {
      return undefined;
    }
    case Concept.manifestation: {
      return getManifestationBasedModifier;
    }
  }
};
