import {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {MetadataEditAction} from '../metadataEditActionTypes';
import {
  MetadataSaveOperations,
  useMetadataSaveOperations,
} from './useMetadataSaveOperations';

export type MetadataEditOperations = {
  cancelMetadataEdits: () => void;
  moveManifestationToOtherExpression: (
    manifestationId: string,
    expressionId: string,
  ) => void;
  moveManifestationToNewExpression: (manifestationId: string) => void;
  cancelMoveManifestationToNewExpression: (manifestationId: string) => void;
};

type MetadataOperations = MetadataSaveOperations & MetadataEditOperations;

/**
 * Provides save and edit operations for metadata. Assumes metadata is loaded and
 * edit state is initialized.
 * @returns
 */
export const useMetadataOperations = (): MetadataOperations => {
  const dispatch = useDispatch();
  const saveOperations = useMetadataSaveOperations();

  const cancelMetadataEdits = useCallback(() => {
    const action: MetadataEditAction = {
      type: 'METADATAEDIT_CANCELLED',
    };
    dispatch(action);
  }, [dispatch]);

  const moveManifestationToOtherExpression = useCallback(
    (manifestationId: string, expressionId: string) => {
      const action: MetadataEditAction = {
        type: 'MOVE_MANIFESTATION_TO_OTHER_EXPRESSION',
        payload: {manifestationId, expressionId},
      };
      dispatch(action);
    },
    [dispatch],
  );

  // const cancelMoveManifestationToOtherExpression = useCallback(
  //   (manifestationId: string, expressionId: string) => {
  //     const action: MetadataEditAction = {
  //       type: 'CANCEL_MOVE_MANIFESTATION_TO_OTHER_EXPRESSION',
  //       payload: {manifestationId, expressionId},
  //     };
  //     dispatch(action);
  //   },
  //   [dispatch],
  // );

  const moveManifestationToNewExpression = useCallback(
    (manifestationId: string) => {
      const action: MetadataEditAction = {
        type: 'MOVE_MANIFESTATION_TO_NEW_EXPRESSION',
        payload: {manifestationId},
      };
      dispatch(action);
    },
    [dispatch],
  );

  const cancelMoveManifestationToNewExpression = useCallback(
    (manifestationId: string) => {
      const action: MetadataEditAction = {
        type: 'CANCEL_MOVE_MANIFESTATION_TO_NEW_EXPRESSION',
        payload: {manifestationId},
      };
      dispatch(action);
    },
    [dispatch],
  );

  return useMemo((): MetadataOperations => {
    return {
      ...saveOperations,
      cancelMetadataEdits,
      moveManifestationToOtherExpression,
      moveManifestationToNewExpression,
      cancelMoveManifestationToNewExpression,
    };
  }, [
    cancelMetadataEdits,
    cancelMoveManifestationToNewExpression,
    moveManifestationToNewExpression,
    moveManifestationToOtherExpression,
    saveOperations,
  ]);
};
