import React, {Fragment, useCallback, useMemo} from 'react';
import {assert} from 'assert-ts';
import {
  LinkedLiterary,
  LinkedValueLink,
  PartLinkedAgent,
  Valx,
  VerifiedLinkedValueLink,
} from 'schemaDefinition/types';
import {useCodelist} from 'services/codeLists';
import {FieldLabel} from 'components/fields/FieldLabel';
import {useLabelAndPlaceholder} from 'schema/form/hooks';
import {BasePartProps} from './types';
import {usePreviewConfigurationContext} from '../contexts';
import {formatLinkedValueLink, getLinkedValuesByRoles} from '../functions';
import {useTextValue} from '../hooks';
import {GroupLabelProps} from './constants';
import {ValueLayout} from './fields/ValueLayout';

type PreviewSimpleValueProps = BasePartProps<PartLinkedAgent<Valx>>;

export const PreviewLinkedValueByRoles: React.FC<PreviewSimpleValueProps> = ({
  part,
  value,
}) => {
  const {label} = useLabelAndPlaceholder(part);
  const config = usePreviewConfigurationContext();
  const {noLabels} = config.layout;
  const codelist = useCodelist(part.roleCodelistId);
  const formattedLinkedValuesByRoles = useMemo(() => {
    const byRoles = getLinkedValuesByRoles(value);
    const byRolesFormatted = byRoles.map(byRole => {
      const role = codelist.codes.find(c => c.code === byRole.role)?.value;
      const links = byRole.links.map(link => ({
        value:
          formatLinkedValueLink(
            link?.link,
            (link as LinkedLiterary)?.numberInSeries,
          ) ?? '',
        link: link.link,
      }));
      if (
        assert.soft(role, 'PreviewLinkedValueByRoles: code missing', {
          code: byRole.role,
          codelist: part.roleCodelistId,
        })
      ) {
        return {role, links};
      } else {
        return {role: `${byRole.role} (missing)`, links};
      }
    });

    return byRolesFormatted;
  }, [codelist.codes, part.roleCodelistId, value]);

  const TextValue = useTextValue();
  // const Separator = useStaticTextValue(';', {pr: 0.5});

  const renderValue = useCallback(
    (links: {value: string; link: LinkedValueLink}[], label?: string) => {
      const allVerifiedSiblingIds = links
        .filter(l => l.link.linkStatus === 'verified')
        .map(l => l.link.entityId)
        .filter(Boolean) as string[];

      return config.onNavigate ? (
        <>
          {links.map((link, idx) => {
            const verifiedLink =
              link.link.linkStatus === 'verified'
                ? (link.link as VerifiedLinkedValueLink)
                : undefined;
            return verifiedLink ? (
              <Fragment key={idx}>
                <TextValue
                  onClick={() =>
                    config.onNavigate?.(
                      verifiedLink,
                      allVerifiedSiblingIds,
                      label,
                    )
                  }>
                  {link.value}
                </TextValue>
                {/* {idx < links.length - 1 ? <Separator /> : null} */}
              </Fragment>
            ) : (
              <TextValue key={idx}>{link.value}</TextValue>
            );
          })}
        </>
      ) : (
        <TextValue>{links.map(l => l.value).join('; ')}</TextValue>
      );
    },
    [TextValue, config],
  );

  return (
    <Fragment>
      {noLabels ? null : <FieldLabel label={label ?? ''} />}
      {formattedLinkedValuesByRoles.map(({role, links}, idx) => (
        <ValueLayout
          key={idx}
          renderValue={() => renderValue(links, label)}
          forcedLabel={role}
          labelProps={GroupLabelProps}
          part={part}
          layoutDirection={'horizontal'}
        />
      ))}
    </Fragment>
  );
};
