import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const DragIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M17 7H7C6.44772 7 6 7.44772 6 8C6 8.55228 6.44772 9 7 9H17C17.5523 9 18 8.55228 18 8C18 7.44772 17.5523 7 17 7Z" />
      <path d="M17 15H7C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17H17C17.5523 17 18 16.5523 18 16C18 15.4477 17.5523 15 17 15Z" />
      <path d="M17 11H7C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11Z" />
    </SvgIcon>
  );
};
