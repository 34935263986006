import React from 'react';
import {Valid} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {ManifestationStatus as ManifestationStatusType} from 'api';
import {useAssertManifestationEdit} from 'services/data';
import {noOp} from 'services/utils';
import {IconButton, Layout, Spacer, Text} from 'components';
import {ManifestationStatus} from '../../ManifestationStatus';
import {ManifestationTitle} from '../../ManifestationTitle';
import {ChangeRequestIndicators} from './ChangeRequestIndicators';

type Props = {
  manifestationId: string;
  isExpanded: boolean;
  showErrors: boolean;
  valid?: Valid;
  manifestationStatus: ManifestationStatusType;
  notesCount: number;
  mediaCount: number;
};

export const ManifestationHeader: React.FC<Props> = ({
  manifestationId,
  isExpanded,
  showErrors,
  valid,
  manifestationStatus,
  notesCount,
  mediaCount,
}) => {
  const {tLoose} = useLocalization();
  const savedManifestation = useAssertManifestationEdit(manifestationId);
  return (
    <Layout horizontal flex={1} alignItems="space-between">
      <ManifestationTitle manifestation={savedManifestation} />

      <Layout horizontal paddingRight={2} adjustCenter adjustRight>
        {showErrors && (valid === 'warning' || valid === 'error') ? (
          <Text variant="h4" kind={valid}>
            {tLoose(`form.global.validation.${valid}.label`)}
          </Text>
        ) : null}

        {!isExpanded ? (
          <Layout horizontal adjustCenter>
            <Spacer width={4} />
            <ChangeRequestIndicators manifestationId={manifestationId} />
            {mediaCount > 0 ? (
              <>
                <IconButton
                  icon="Files"
                  onClick={noOp}
                  badgeCount={mediaCount}
                />
                <Spacer size={1} />
              </>
            ) : null}
            {notesCount > 0 ? (
              <>
                <IconButton
                  icon="Comments"
                  onClick={noOp}
                  badgeCount={notesCount}
                />
                <Spacer size={1} />
              </>
            ) : null}
            <ManifestationStatus status={manifestationStatus} />
          </Layout>
        ) : null}
      </Layout>
    </Layout>
  );
};
