import {CodeList} from 'api/types';
import {AutocompleteOption} from '../types';

export const mapCodelistToOptions = (
  codelist: CodeList,
): AutocompleteOption[] => {
  const includeCode = ['BOOK_GROUP', 'PRODUCT_GROUP'].includes(codelist.id);

  return codelist.codes
    .filter(c => !c.disabled && !c.deactivated)
    .filter(
      (c, index, self) =>
        index === self.findIndex(t => t.code === c.code || t.value === c.value),
    )
    .map(c => ({
      id: c.code,
      label: includeCode ? `${c.code}: ${c.value}` : c.value,
    }));
};
