import React from 'react';
import {FCWithChildren} from 'types';

const SceneSubMenuName = 'SceneSubMenu';

const SceneSubMenu: FCWithChildren = ({children}) => {
  return <>{children}</>;
};

SceneSubMenu.displayName = SceneSubMenuName;

export {SceneSubMenu, SceneSubMenuName};
