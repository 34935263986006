import {
  Part,
  PartExpand,
  PartSeparator,
  SeparatedPart,
  Valx,
} from 'schemaDefinition/types';

type ProperPart<TVal> = Exclude<Part<TVal>, PartExpand<TVal>>;
/**
 * Flattens parts, i.e.:
 * - removes separators
 * - flattens arrays
 * - expands expand parts (includes all branches, including default), but does not include the expand itself
 *   and excludes dupicates
 * Will NOT recurse through sub-schemas.
 * @param parts
 * @returns
 */
export const getPartsFlattended = <TVal = Valx>(
  parts: SeparatedPart<TVal>[],
): ProperPart<TVal>[] => {
  const noArrayParts = parts.some(p => Array.isArray(p))
    ? parts.reduce<(PartSeparator | Part<TVal>)[]>((acc, p) => {
        if (Array.isArray(p)) {
          acc.push(...p);
        } else {
          acc.push(p);
        }
        return acc;
      }, [])
    : (parts as (PartSeparator | Part<TVal>)[]);

  const noSeparatorParts = noArrayParts.some(p => p.type === 'separator')
    ? noArrayParts.filter(p => p.type !== 'separator')
    : noArrayParts;

  const noExpandParts = noSeparatorParts.some(p => p.type === 'expand')
    ? noSeparatorParts.reduce<ProperPart<TVal>[]>((acc, p) => {
        if (p.type === 'expand') {
          acc.push(...getExpandPartsMerged(p));
        } else {
          acc.push(p as ProperPart<TVal>);
        }
        return acc;
      }, [])
    : (noSeparatorParts as ProperPart<TVal>[]);

  return noExpandParts;
};

const getExpandPartsMerged = <TVal = Valx>(
  expand: PartExpand<TVal>,
): ProperPart<TVal>[] => {
  return (
    [
      ...expand.when.map(w => getPartsFlattended(w.parts)),
      ...(expand.default ? getPartsFlattended(expand.default) : []),
    ].flat() as ProperPart<TVal>[]
  ).reduce<ProperPart<TVal>[]>(
    (acc, part) => (acc.includes(part) ? acc : [...acc, part]), // Consider comparing names instead of objects
    [],
  );
};
