import {Metadata, MetadataStatuses} from 'services/data/metadata/types';
import {ManifestationStatus} from 'api';
import {getExpressionWorkId} from './getExpressionWorkId';
import {getManifestationExpressionId} from './getManifestationExpressionId';

/**
 * Sets new preview status for a manifestation and updates statuses for
 * expression and work.
 * Assumption: at any time only one manifestation can have preview status,
 * hence, not neccessary to consider other manifestations when updating
 * @param previewStatus
 * @param manifestationId
 * @param currentStatuses
 * @param data
 * @returns
 */
export const aggregatePreviewStatus = (
  previewStatus: ManifestationStatus | undefined,
  manifestationId: string,
  currentStatuses: MetadataStatuses,
  data: Metadata,
): MetadataStatuses => {
  const currentManifestationStatus = currentStatuses[manifestationId];
  if (currentManifestationStatus.previewStatus === previewStatus) {
    return currentStatuses;
  }

  const newManifestationStatus = {
    ...currentManifestationStatus,
    previewStatus,
  };

  const newStatuses = {
    ...currentStatuses,
    [manifestationId]: newManifestationStatus,
  };

  const expressionId = getManifestationExpressionId(data, manifestationId);
  newStatuses[expressionId] = {
    ...currentStatuses[expressionId],
    previewStatus,
  };

  const workId = getExpressionWorkId(data, expressionId);
  newStatuses[workId] = {
    ...currentStatuses[workId],
    previewStatus,
  };

  return newStatuses;
};
