import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import {ExpressionV4} from 'api/types';
import {AppState} from 'store/types';

const selector = (state: AppState) => state.metadataEdit.metadata?.expressions;

export const useAssertExpressionsEdit = (): ExpressionV4[] => {
  const expressions = useSelector(selector);
  return assert(expressions, 'useAsserExpressions: no expressions');
};
