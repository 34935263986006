import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const GhostIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12 4C10.078 4.00265 8.23546 4.76733 6.8764 6.1264C5.51733 7.48546 4.75265 9.32799 4.75 11.25V17.38C4.75291 17.9775 4.929 18.5614 5.25694 19.0608C5.58488 19.5603 6.0506 19.954 6.5977 20.1942C7.1448 20.4344 7.74982 20.5109 8.33947 20.4143C8.92913 20.3177 9.47813 20.0522 9.92 19.65C10.494 20.1576 11.2338 20.4377 12 20.4377C12.7662 20.4377 13.506 20.1576 14.08 19.65C14.5219 20.0522 15.0709 20.3177 15.6605 20.4143C16.2502 20.5109 16.8552 20.4344 17.4023 20.1942C17.9494 19.954 18.4151 19.5603 18.7431 19.0608C19.071 18.5614 19.2471 17.9775 19.25 17.38V11.25C19.2474 9.32799 18.4827 7.48546 17.1236 6.1264C15.7645 4.76733 13.922 4.00265 12 4ZM17.25 17.38C17.2505 17.5946 17.1871 17.8045 17.0679 17.9829C16.9486 18.1613 16.7789 18.3001 16.5804 18.3817C16.3819 18.4632 16.1636 18.4838 15.9534 18.4408C15.7431 18.3977 15.5505 18.293 15.4 18.14C15.0458 17.7982 14.5722 17.608 14.08 17.61C13.5904 17.6064 13.1193 17.7969 12.77 18.14C12.562 18.3369 12.2864 18.4467 12 18.4467C11.7136 18.4467 11.438 18.3369 11.23 18.14C10.8774 17.7985 10.4058 17.6075 9.915 17.6075C9.42415 17.6075 8.95256 17.7985 8.6 18.14C8.44952 18.293 8.25688 18.3977 8.04664 18.4408C7.8364 18.4838 7.6181 18.4632 7.4196 18.3817C7.2211 18.3001 7.05139 18.1613 6.93213 17.9829C6.81287 17.8045 6.74946 17.5946 6.75 17.38V11.25C6.75 9.85761 7.30312 8.52226 8.28769 7.53769C9.27225 6.55312 10.6076 6 12 6C13.3924 6 14.7277 6.55312 15.7123 7.53769C16.6969 8.52226 17.25 9.85761 17.25 11.25V17.38Z" />
      <path d="M10 12C10.5523 12 11 11.5523 11 11C11 10.4477 10.5523 10 10 10C9.44772 10 9 10.4477 9 11C9 11.5523 9.44772 12 10 12Z" />
      <path d="M14 12C14.5523 12 15 11.5523 15 11C15 10.4477 14.5523 10 14 10C13.4477 10 13 10.4477 13 11C13 11.5523 13.4477 12 14 12Z" />
      <path d="M12 15.5C12.8284 15.5 13.5 14.8284 13.5 14C13.5 13.1716 12.8284 12.5 12 12.5C11.1716 12.5 10.5 13.1716 10.5 14C10.5 14.8284 11.1716 15.5 12 15.5Z" />
    </SvgIcon>
  );
};
