import {Fragment} from 'react';
import {PartValueRenderer} from 'schema/types';
import {MainTitle} from 'api';
import {Builder, isNullish} from 'services/utils';
import {FlexBox} from 'components';
import {useTextValue} from '../../schema/preview/hooks';

const makeTitleWithParallelTitles = (
  value?: string,
  parallelTitles?: string[],
): string | undefined => {
  const parallel = (parallelTitles ?? []).join('; ');
  const title = new Builder(value).add(' = ', parallel).value;

  return title;
};

export const MainTitles: PartValueRenderer = ({value}) => {
  const TextValue = useTextValue();

  return isNullish(value) ||
    !Array.isArray(value) ||
    value.length === 0 ? null : (
    <FlexBox>
      {value.map((v, i) => {
        const mainTitle = v as MainTitle;
        return (
          <Fragment key={i}>
            <TextValue key={i}>
              {makeTitleWithParallelTitles(
                mainTitle.value,
                mainTitle.parallelTitles,
              )}
            </TextValue>
            {mainTitle.subTitles ? (
              <FlexBox ml={1} component={'span'}>
                {mainTitle.subTitles.map(st => (
                  <TextValue key={st.value}>
                    {makeTitleWithParallelTitles(st.value, st.parallelTitles)}
                  </TextValue>
                ))}
              </FlexBox>
            ) : null}
          </Fragment>
        );
      })}
    </FlexBox>
  );
};
