import {useMemo} from 'react';
import isChrome from '@braintree/browser-detection/dist/is-chrome';
import isEdge from '@braintree/browser-detection/dist/is-edge';
import isFirefox from '@braintree/browser-detection/dist/is-firefox';
import isIosFirefox from '@braintree/browser-detection/dist/is-ios-firefox';
import isIosSafari from '@braintree/browser-detection/dist/is-ios-safari';
import isSafari from '@braintree/browser-detection/dist/is-safari';

export type Browser = 'safari' | 'chrome' | 'firefox' | 'edge';

export const useIsBrowser = (browser: Browser | Browser[]): boolean => {
  return useMemo(() => {
    const browsers = Array.isArray(browser) ? browser : [browser];
    return browsers.reduce((acc, b) => {
      switch (b) {
        case 'safari':
          return acc || isSafari() || isIosSafari();
        case 'chrome':
          return acc || isChrome();
        case 'firefox':
          return acc || isFirefox() || isIosFirefox();
        case 'edge':
          return acc || isEdge();
        default:
          return acc;
      }
    }, false);
  }, [browser]);
};
