import {
  DataLoadStatus,
  WorkWithExpressions,
  getWorkWithExpressions,
  useGetResource,
} from 'api';
import {useGetTokens} from 'services/auth';

const NoResult = {} as WorkWithExpressions;
export const useWorkWithExpressions = (
  workId: string | undefined,
): DataLoadStatus<WorkWithExpressions> => {
  const mock = false;
  const getTokens = useGetTokens();
  return useGetResource(() =>
    workId
      ? getWorkWithExpressions(workId, getTokens, mock).then(data => data)
      : Promise.resolve(NoResult),
  );
};
