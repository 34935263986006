import {createContext, useContext, useMemo} from 'react';
import {assert} from 'assert-ts';
import {DataFormConfiguration} from 'schema/types';

export type DataFormConfigurationContextType = DataFormConfiguration;

const defaultContext: DataFormConfigurationContextType = {
  schemaContainers: {},
  parts: {},
  showWhenReadonlyAndEmpty: false,
};

export const DataFormConfigurationContext =
  createContext<DataFormConfigurationContextType>(defaultContext);

export const useDataFormConfigurationContextProviderValue = (
  configuration?: Partial<DataFormConfigurationContextType>,
): DataFormConfigurationContextType => {
  return useMemo(
    () => ({
      ...defaultContext,
      ...configuration,
      schemaContainers: {
        ...defaultContext.schemaContainers,
        ...configuration?.schemaContainers,
      },
    }),
    [configuration],
  );
};

export const useDataFormConfigurationContext =
  (): DataFormConfigurationContextType => {
    return assert(
      useContext(DataFormConfigurationContext),
      'useDataFormConfigurationContext: context expected',
    );
  };
