import {Schemas} from 'api/dto.generated';

const dto: Schemas.WorkFlattenedDto = {
  work: {
    id: '06d8705d-b6f5-471b-9a7b-fd69fb6ee33d',
    created: 1695296757144,
    modified: 1697800041627,
    preferredTitle: ['Sjå ut'],
    languages: ['nno'],
    agents: [],
    dewey: [
      {
        source: '15',
        value: '839.8218',
      },
    ],
    intellectualLevel: ['15'],
    literatureType: ['12'],
    themes: ['AVLT', 'DCC', 'DCR'],
    subjects: ['form_lyrikk', 'emneord_folkemusikk'],
    seriesRelations: [],
    workRelations: [],
    agentRelations: [],
  },
  expressions: [
    {
      id: '7f30e79e-a1a1-41e0-a1e3-3ba8b6506e5a',
      workId: '06d8705d-b6f5-471b-9a7b-fd69fb6ee33d',
      created: 1697799859295,
      modified: 1697799859295,
      expressionFormat: 'TEXT',
      languages: ['nno'],
      content: [],
      agents: [
        {
          roles: ['E06'],
          agentId: 'c50ca558-6d72-48ef-ad21-e1def3fcf9b5',
          agentType: 'CORPORATION',
          agentName: {
            id: '48e3c86a-0785-4671-bb6a-1acd45986511',
            name: 'Atterljomen (musikkgruppe)',
            nameOrder: 0,
          },
          description: 'musikkgruppe',
          nationalId: '1633429907001',
        },
      ],
    },
  ],
  manifestations: [
    {
      id: '8b823416-7865-4650-b90c-5ae4ff014f8f',
      expressionId: '7f30e79e-a1a1-41e0-a1e3-3ba8b6506e5a',
      created: 1695296757147,
      modified: 1697800041627,
      status: 'COMPLETED',
      ean: '0197188200862',
      mainTitle: 'Sjå ut',
      // availabilityStatus: 'AVAILABLE',
      // distributorStatus: '21',
      // publisherStatus: '04',
      // publisherSalesDate: '2023-09-21',
      edition: 1,
      publishedYear: 2023,
      pageCount: '40',
      contentReceivedDate: '2023-10-19',
      productForm: 'BC',
      productFormDetail: [],
      physicalProperties: [
        {
          type: 'PAGES',
          value: '40',
        },
      ],
      bookGroup: '311',
      productGroup: '42020',
      externalSystemInfo: {
        ids: [150470664],
        created: '2023-09-21T13:45:54Z',
        modified: '2023-10-20T13:07:18Z',
      },
      titleInfo: {
        mainTitles: [
          {
            subTitles: [],
            parallelTitles: [],
            value: 'Sjå ut',
            order: 1,
          },
        ],
        otherTitles: [],
      },
      generalNote: [],
      editionNote: [],
      editionType: [],
      seriesRelations: [],
      imprints: [
        {
          agentId: 'bffeb726-daf0-4cdb-bf63-c5935422c642',
          agentName: {
            id: '2f0799bc-bf62-4476-8c6e-250af692ae68',
            name: 'Musikkoperatørene',
            nameOrder: 0,
          },
        },
      ],
      // descriptions: [
      //   {
      //     content:
      //       'Tre av Norges mest meritterte felespiller gir ut sitt tredje album med slåttemusikk fra Gudbrandsdalen. Denne gangen har de også gjort et redaksjonelt arbeid og laget en tilhørende bok med dikt av lyrikere fra Skjåk, Lom og Vågå. Det er ett dikt for hver av de seksten melodiene. Gruppa Atterljomen består av Bjørn Kåre Odde (fele/bratsj), Astrid Sulheim (fele) og Aslak Brimi (fele).',
      //     language: 'nor',
      //     agentId: null,
      //     type: null,
      //   },
      // ],
      subTitles: [],
      productOwners: [
        {
          code: '0135',
          name: 'Musikkoperatørene AS',
        },
      ],
      marketingData: {
        products: [
          {
            owner: '0135',
            distributor: 'EGEN',
            status: 'ACTIVE',
            publisherSalesDate: '2023-09-21',
            inSaleDate: '2023-09-21',
            measures: [],
            descriptions: [
              {
                content:
                  'Tre av Norges mest meritterte felespiller gir ut sitt tredje album med slåttemusikk fra Gudbrandsdalen. Denne gangen har de også gjort et redaksjonelt arbeid og laget en tilhørende bok med dikt av lyrikere fra Skjåk, Lom og Vågå. Det er ett dikt for hver av de seksten melodiene. Gruppa Atterljomen består av Bjørn Kåre Odde (fele/bratsj), Astrid Sulheim (fele) og Aslak Brimi (fele).',
                language: 'nor',
                type: 'PLAINTEXT',
              },
            ],
            hidden: false,
          },
        ],
      },
    },
  ],
};

export default dto;
