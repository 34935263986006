import {GridItem} from './GridItem';
import {GridLayout as GridLayoutContainer} from './GridLayout';

type GridLayoutType = typeof GridLayoutContainer & {
  Item: typeof GridItem;
};
/**
 * @deprecated Use MUI grid directly instead
 */
const GridLayout: GridLayoutType = GridLayoutContainer as GridLayoutType;
GridLayout.Item = GridItem;

export {GridLayout};
