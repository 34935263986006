import {PartCodeList, PartLinkedLiterary, Valx} from 'schemaDefinition/types';
import {CodeListId} from 'api';

export const getPartCodelistFromLinkedLiterary = (
  partLinkedLiterary: PartLinkedLiterary<Valx>,
): PartCodeList<Valx> | undefined => {
  return {
    type: 'codelist',
    name: 'role',
    codelistId: partLinkedLiterary.roleCodelistId as CodeListId,
    required: true,
  };
};
