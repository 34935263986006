import {ExpressionV4} from 'api';
import {Metadata} from '../../../types';

export const replaceExpression = (
  newExpression: ExpressionV4,
  currentMetadata: Metadata,
): Metadata => {
  return {
    ...currentMetadata,
    expressions: currentMetadata.expressions.map(e =>
      e.id === newExpression.id ? newExpression : e,
    ),
  };
};
