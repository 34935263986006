import React, {useMemo} from 'react';
import {assert} from 'assert-ts';
import {PreviewConfiguration} from 'schema/types';
import {Agent} from 'api';
import {CodeListsGate} from 'services/codeLists';
import {FlexBox, Spacer} from 'components';
import {Preview, useSchemaCodelistIds} from 'schema';
import {entityTypeToSchemaReference} from 'schemas/functions/entityTypeToSchemaReference';
import {useEntitySchema, usePreviewConfiguration} from 'schemas/hooks';
import {EntityPreviewContent} from './types';

const secondaryParts: (keyof Agent)[] = ['internalComments'];

export const AgentPreviewContent: EntityPreviewContent = ({
  entityType,
  entityValue,
  configuration,
}) => {
  const schema = useEntitySchema(entityType);
  const codeLists = useSchemaCodelistIds(schema);
  const mainDynamicPreviewConfig =
    useMemo((): Partial<PreviewConfiguration> => {
      return {
        ...configuration,
        excludeParts: secondaryParts,
      };
    }, [configuration]);

  const mainPreviewConfig = usePreviewConfiguration(
    entityTypeToSchemaReference(entityType),
    mainDynamicPreviewConfig,
  );

  const secondaryDynamicPreviewConfig =
    useMemo((): Partial<PreviewConfiguration> => {
      return {
        ...configuration,
        includeParts: secondaryParts,
      };
    }, [configuration]);

  const secondaryPreviewConfig = usePreviewConfiguration(
    entityTypeToSchemaReference(entityType),
    secondaryDynamicPreviewConfig,
  );

  return (
    <CodeListsGate codeLists={codeLists}>
      <FlexBox>
        <Preview
          schema={assert(schema, 'EntityPreview: schema is not defined')}
          data={entityValue}
          configuration={mainPreviewConfig}
        />
        <Spacer height={2} />
        <Preview
          schema={assert(schema, 'EntityPreview: schema is not defined')}
          data={entityValue}
          configuration={secondaryPreviewConfig}
        />
      </FlexBox>
    </CodeListsGate>
  );
};
