import {TypographyStyleOptions} from '@mui/material/styles/createTypography';
import {assert} from 'assert-ts';
import {Fonts} from 'theme/fonts';

type Variant = keyof typeof Fonts;
const importantCache: {[key in Variant]?: TypographyStyleOptions} = {};

export const useImportantFont = (
  variant: Variant | undefined,
): TypographyStyleOptions | undefined => {
  if (!variant) {
    return undefined;
  }

  if (!importantCache[variant]) {
    const font = Fonts[variant] as TypographyStyleOptions;
    const importantFont = Object.keys(font).reduce<TypographyStyleOptions>(
      (acc, key) => {
        const isImportant =
          typeof font[key] === 'string' &&
          (font[key] as string).includes('important');
        acc[key] = isImportant ? font[key] : `${font[key]}!important`;
        return acc;
      },
      {},
    );

    importantCache[variant] = importantFont;
  }

  return assert(importantCache[variant]);
};
