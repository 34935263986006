import React from 'react';
import {GridValidRowModel} from '@mui/x-data-grid-pro';
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {RowId} from 'components/dataTable/types';
import {PaginationState} from 'components/paginator/types';
import {Data, Schema} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {TableConfiguration} from '../types';
import {
  TableConfigurationContext,
  useTableConfigurationContextProviderValue,
} from '../contexts/TableConfigurationContextType';
import {useTableSchema} from '../hooks/useTableSchema';
import {TableSchema} from './TableSchema';

dayjs.extend(utc);

export type TableProps<TData extends Data> = {
  schema: Schema;
  data: TData[];
  loading?: boolean;
  onRowClick?: (row: TData) => void;
  header: React.ReactNode | string;
  configuration?: TableConfiguration;
  highlights?: string[];
  pagination?: PaginationState;
  selection?: RowId[];
  onSelectionChanged?: (selection: RowId[]) => void;
  onRowsUpdate?: (gridRows: GridValidRowModel[]) => void;
};

export function Table<TData extends Data>({
  schema,
  data,
  loading,
  onRowClick,
  header,
  configuration,
  highlights,
  pagination,
  selection,
  onSelectionChanged,
  onRowsUpdate,
}: TableProps<TData>) {
  const {locale} = useLocalization();

  const tableContext = useTableConfigurationContextProviderValue(configuration);

  const tableSchema = useTableSchema(schema, tableContext.partValueRenderers);

  return (
    <TableConfigurationContext.Provider value={tableContext}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        <TableSchema
          header={header}
          schema={tableSchema}
          rows={data}
          loading={loading}
          onRowClick={onRowClick}
          highlights={highlights}
          pagination={pagination}
          selection={selection}
          onSelectionChanged={onSelectionChanged}
          onRowsUpdate={onRowsUpdate}
        />
      </LocalizationProvider>
    </TableConfigurationContext.Provider>
  );
}
