import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {IconButton, InputAdornment, Tooltip} from '@mui/material';
import {assert} from 'assert-ts';
import {
  Concept,
  LinkTargetMainEntityType,
  LinkTargetSubEntityType,
} from 'types';
import {FieldExtensionUnsubscribe as LinkedSearchUnsubscribe} from 'sceneExtensions/types';
import {
  EmptyLinkedValueLink,
  FieldRequiredSimple,
  LinkedValueLink,
  UnnamedLinkedValueLink,
  UnverifiedLinkedValueLink,
  VerifiedLinkedValueLink,
} from 'schemaDefinition/types';
import {useLinkedValueSearchContext} from 'sceneExtensions';
import {TextFieldProps} from '../types';
import {BaseFieldProps} from './types';
import {
  AIIcon,
  CheckCircleIcon,
  GhostIcon,
  Icon,
  SearchIcon,
  VerifiedIcon,
} from '../icons';
import {Layout} from '../layout';
import {FieldLabel} from './FieldLabel';
import './LinkedField.scss';
import {NullFieldPlaceholder} from './NullFieldPlaceholder';
import {MuiTextFieldStyled} from './muiStyled';

type Props = BaseFieldProps &
  Pick<TextFieldProps, 'placeholder'> &
  Required<Pick<TextFieldProps, 'name'>> & {
    /** Displays what field is being edited via the right panel */
    focusableId?: string;
    link?: LinkedValueLink;
    /** Default true */
    linkable?: boolean;
    formattedValue: string;
    required?: FieldRequiredSimple;
    searchTitleKey?: string;
    entity: LinkTargetMainEntityType | undefined;
    entitySubtypes?: LinkTargetSubEntityType[] | undefined;
    allowedEntities?: Concept[] | undefined;
    onChange: (linkedValue: VerifiedLinkedValueLink | undefined) => void;
    onEdit?: (
      linkedValue:
        | UnverifiedLinkedValueLink
        | UnnamedLinkedValueLink
        | EmptyLinkedValueLink,
    ) => void;
    onBlur?: () => void;
  };

export const LinkedField: React.FC<Props> = ({
  focusableId,
  label,
  name,
  required = false,
  error = false,
  readonly,
  showWhenReadonlyAndEmpty = false,
  searchTitleKey,
  entity,
  entitySubtypes,
  allowedEntities,
  link,
  linkable = true,
  formattedValue,
  onChange,
  onEdit,
  onBlur,
  flex,
  ...props
}) => {
  const {sourceField, setSourceField} = useLinkedValueSearchContext();
  const ref = useRef<HTMLInputElement>(null);

  const [isDirectEditMode, setIsDirectEditMode] = useState<boolean>(
    link?.linkStatus === 'unnamed',
  );

  const unsubscribeRef = useRef<LinkedSearchUnsubscribe>();

  const handleSearch = useCallback(() => {
    if (!linkable) return;

    setSourceField(
      {
        fieldId: name,
        fieldConfig: {
          titleKey: searchTitleKey,
          canLink: !readonly,
          entity,
          entitySubtypes,
          allowedEntities,
        },
        currentValue: link,
      },
      val => {
        onChange(val);
        unsubscribeRef.current && unsubscribeRef.current(name);
        unsubscribeRef.current = undefined;
      },
      () => {
        unsubscribeRef.current = undefined;
      },
    ); //.then(unsubscribe => (unsubscribeRef.current = unsubscribe));
  }, [
    allowedEntities,
    entity,
    entitySubtypes,
    link,
    linkable,
    name,
    onChange,
    readonly,
    searchTitleKey,
    setSourceField,
  ]);

  const handleDirectChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const edit = assert(onEdit, 'handleDirectChange: onEdit expected');
      const value = e.target.value;

      // Unnamed links remain unnamed
      if (link?.linkStatus === 'unnamed') {
        edit({...link, name: value});
      } else if (value) {
        const unverified: UnverifiedLinkedValueLink = {
          linkStatus: 'unverified',
          name: value,
        };
        edit(unverified);
      } else {
        const empty: EmptyLinkedValueLink = {
          linkStatus: 'empty',
        };
        edit(empty);
      }
    },
    [link, onEdit],
  );

  const handleToggleDirectEditMode = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDirectEditMode(prev => !prev);
    ref.current?.focus();
  }, []);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!linkable) return;

      if (e.code !== 'Enter' && e.code !== 'Space') return;

      handleSearch();
      e.preventDefault();
    },
    [handleSearch, linkable],
  );

  useEffect(() => {
    return () => unsubscribeRef.current && unsubscribeRef.current(name);
    // On unmount only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const valueState = link?.nationalId
    ? 'externalVerified'
    : link?.linkStatus ?? 'empty';

  const searching = !readonly && sourceField?.fieldId === name;
  const iconColor = searching
    ? 'primary'
    : error === true
      ? 'error'
      : error === 'warning'
        ? 'warning'
        : 'primary';
  const className = searching
    ? 'LinkedField-searching'
    : error === true
      ? 'LinkedField-error'
      : error === 'warning'
        ? 'LinkedField-warning'
        : undefined;

  const canEdit =
    !!onEdit &&
    !readonly &&
    (valueState === 'empty' ||
      valueState === 'unverified' ||
      valueState === 'unnamed');

  const endAdornmentActions: React.JSX.Element = useMemo(() => {
    const EditAction =
      canEdit && link?.linkStatus !== 'unnamed' ? (
        <InputAdornment position="end">
          <IconButton
            data-cy={'button-edit'}
            color="primary"
            size="small"
            onClick={handleToggleDirectEditMode}>
            <Icon icon={isDirectEditMode ? 'CancelEdit' : 'Edit'} />
          </IconButton>
        </InputAdornment>
      ) : null;

    const ShowMoreAction =
      formattedValue && formattedValue.length > 0 ? (
        <Tooltip title={formattedValue} followCursor>
          <span
            style={{
              marginTop: 6,
              cursor: 'help',
            }}>
            <Icon icon={'InfoCircle'} />
          </span>
        </Tooltip>
      ) : null;

    return (
      <InputAdornment position="end">
        {EditAction}
        {ShowMoreAction}
      </InputAdornment>
    );
  }, [
    canEdit,
    formattedValue,
    handleToggleDirectEditMode,
    isDirectEditMode,
    link?.linkStatus,
  ]);

  return (
    <Layout flex={flex} sx={{flexDirection: 'row'}}>
      {label && (
        <FieldLabel
          label={label}
          required={required}
          error={error}
          htmlFor={name}
        />
      )}
      {readonly && !link && !showWhenReadonlyAndEmpty ? (
        <NullFieldPlaceholder />
      ) : (
        <MuiTextFieldStyled
          inputRef={ref}
          required={required !== false}
          size="small"
          fullWidth
          id={name}
          name={name}
          readonly={readonly}
          value={formattedValue}
          className={className}
          type="text"
          onClick={isDirectEditMode || !linkable ? undefined : handleSearch}
          onChange={handleDirectChange}
          onBlur={onBlur}
          InputProps={{
            id: focusableId,
            readOnly: readonly || !isDirectEditMode,
            onKeyDown: isDirectEditMode ? undefined : handleKeyDown,
            startAdornment: (
              <InputAdornment position="start">
                {valueState === 'unnamed' ? (
                  <AIIcon color={iconColor} />
                ) : valueState === 'empty' ? (
                  <SearchIcon color={iconColor} />
                ) : valueState === 'unverified' ? (
                  <GhostIcon color={iconColor} />
                ) : valueState === 'verified' ? (
                  <CheckCircleIcon color={iconColor} />
                ) : (
                  // External verified
                  <VerifiedIcon color={iconColor} />
                )}
              </InputAdornment>
            ),
            endAdornment: endAdornmentActions,
          }}
          {...props}
        />
      )}
    </Layout>
  );
};
