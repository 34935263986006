import assert from 'assert-ts';
import {Thesaurus} from 'api';
import {getAllNodeDuplicatesByIdMap} from './getAllNodeDuplicatesByIdMap';
import {getNodeCodeToIdsMap} from './getNodeCodeToIdsMap';
import {getNodeIdToCodeMap} from './getNodeIdToCodeMap';

type ThesaurusMaps = {
  /** Map of id to code */
  idToCode: Map<string, string>;
  /** Map of code to ids (more when duplicated) */
  codeToIds: Map<string, string[]>;
  /** Map of each duplicated node's (same code) id to the other nodes' ids (those with the same code) */
  duplicateIdToIds: Map<string, string[]>;
};

const lazyMapsCache = new Map<string, ThesaurusMaps>();

/**
 * Returns maps of thesaurus nodes to their ids and codes, and duplicates
 * For a given thesaurus, these should be called first from "thesarus edit context"
 * @param thesaurus
 */
export const getThesaurusMaps = (thesaurus: Thesaurus): ThesaurusMaps => {
  const thesaurusId = thesaurus.id;
  const cached = lazyMapsCache.get(thesaurusId);
  if (!cached) {
    const codeToIds = getNodeCodeToIdsMap(thesaurus);
    const idToCode = getNodeIdToCodeMap(thesaurus);
    const duplicateIdToIds = getAllNodeDuplicatesByIdMap(codeToIds);
    lazyMapsCache.set(thesaurusId, {
      idToCode,
      codeToIds,
      duplicateIdToIds,
    });
  }

  return assert(lazyMapsCache.get(thesaurusId), 'Thesaurus maps not found');
};
