import {useCallback} from 'react';
import {useStore} from 'react-redux';
import {assert} from 'assert-ts';
import {MetadataValidationResult} from 'schemas/types';
import {AppState} from 'store/types';
import {EntitySaveStatus, MetadataIds} from '../types';
import {
  getChangedEntities,
  getEntityIdsNeedingValidation,
  validateMetadata,
} from '../functions';
import {useMetadataHasChanges} from './useMetadataHasChanges';
import {useMetadataHasNewExpressions} from './useMetadataHasNewExpressions';
import {useMetadataOperations} from './useMetadataOperations';

export const useSaveOrCancelMetadata = (): {
  hasChanges: boolean;
  hasNewExpressions: boolean;
  canSave: () => MetadataValidationResult;
  handleSave: (saveWithWarnings?: boolean) => Promise<EntitySaveStatus[]>;
  handleCancel: () => void;
} => {
  const {getState} = useStore<AppState>();
  const hasChanges = useMetadataHasChanges();
  const hasNewExpressions = useMetadataHasNewExpressions();
  const {saveMetadataEntities, cancelMetadataEdits} = useMetadataOperations();

  const handleValidate = useCallback(
    (ids: MetadataIds): MetadataValidationResult => {
      const editState = getState().metadataEdit;
      const validation = validateMetadata(editState, ids);
      // console.log('handleValidate:', validation);
      return validation;
    },
    [getState],
  );

  const canSave = useCallback((): MetadataValidationResult => {
    const editState = getState().metadataEdit;
    const needsValidation = getEntityIdsNeedingValidation(editState);
    return needsValidation ? handleValidate(needsValidation) : {valid: 'valid'};
  }, [getState, handleValidate]);

  const handleSave = useCallback(
    (saveWithWarnings?: boolean): Promise<EntitySaveStatus[]> => {
      if (
        !assert.soft(
          !hasNewExpressions,
          'handleSave: cannot save when new expressions',
        )
      ) {
        return Promise.reject();
      }

      const editState = getState().metadataEdit;
      const entitiesToSave = assert(
        getChangedEntities(editState),
        'handleSave: no entities changed',
      );
      const needsValidation = getEntityIdsNeedingValidation(editState);
      const validation = needsValidation
        ? handleValidate(needsValidation)
        : {valid: 'valid'};

      if (
        validation.valid === 'error' ||
        (!saveWithWarnings && validation.valid === 'warning')
      ) {
        // setShowErrorsWhenSave(true);
        return Promise.reject();
      }

      return saveMetadataEntities(entitiesToSave);
    },
    [getState, handleValidate, hasNewExpressions, saveMetadataEntities],
  );

  const handleCancel = useCallback(
    () => cancelMetadataEdits(),
    [cancelMetadataEdits],
  );

  return {hasChanges, hasNewExpressions, canSave, handleSave, handleCancel};
};
