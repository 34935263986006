import {Args} from './coreTypes';

export const formatLocaleString = (
  key: string,
  format: string,
  args?: Args,
  logWarning?: (msg: string, args?: Args) => void,
): string => {
  if (!format.includes('{{') && args === undefined) {
    return format;
  }

  const prefixAndOParams = format.split('{{');
  const textParts = prefixAndOParams.map((pp, idx) =>
    idx === 0 ? pp : pp.split('}}')[1],
  );
  const argKeys = prefixAndOParams
    .map((pp, idx) => (idx === 0 ? '' : pp.split('}}')[0]))
    .map(p => (p === '' ? undefined : p))
    .slice(1);

  let result = '';
  textParts.forEach((t, i) => {
    result = t !== '' ? result + t : result;
    const argKey = argKeys[i];
    if (argKey) {
      const value = args && args[argKey];
      if (value === undefined) {
        logWarning &&
          logWarning(
            `lookup locale: missing argument ${argKey} for key ${key}`,
            args,
          );
      } else {
        result = `${result}${value}`;
      }
    }
  });

  if (!result) {
    logWarning &&
      logWarning(`lookup locale: formatting failed for key ${key}`, args);
    return `Invalid: ${key}`;
  }

  return result;
};
