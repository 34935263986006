import Grow, {GrowProps} from '@mui/material/Grow';
import {SnackbarProvider} from 'notistack';
import {FCWithChildren} from 'types';
import {CheckCircleIcon, Layout} from 'components';
import './ThemedSnackbarProvider.scss';

function GrowTransition(props: GrowProps) {
  return <Grow {...props} />;
}

const snackbarProviderAnchorOrigin = {
  vertical: 'top',
  horizontal: 'center',
} as const;

export const ThemedSnackbarProvider: FCWithChildren = ({children}) => {
  return (
    // See useSnacks for custom themeing of snack variants
    <SnackbarProvider
      anchorOrigin={snackbarProviderAnchorOrigin}
      autoHideDuration={5 * 1000}
      iconVariant={{
        success: (
          <Layout paddingRight={1}>
            <CheckCircleIcon />
          </Layout>
        ),
      }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      TransitionComponent={GrowTransition}>
      {children}
    </SnackbarProvider>
  );
};
