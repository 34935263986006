import {Metadata, MetadataTimestamps} from '../../../types';
import {visitEntities} from './visitEntities';

export const getMetadataTimestamps = (
  metadata: Metadata,
): MetadataTimestamps => {
  const timestamps: MetadataTimestamps = {};

  visitEntities(metadata, ({id}) => {
    timestamps[id] = 1;
  });

  return timestamps;
};
