import React, {useCallback, useMemo, useState} from 'react';
import {Stack} from '@mui/material';
import {useLocalization} from 'localization';
import {Label, Text, useAlertDialogAsPromiseV3, useSnacks} from 'components';
import {useAdvancedSearchContext} from '../context/AdvancedSearchContext';
import {compareFilters} from '../functions/compareFilters';
import {ChevronToggle} from './ChevronToggle';
import {ListItem} from './ListItem';

export const FilterSets: React.FC = () => {
  const {currentFilters, filterSets, setCurrentFilters, deleteFilterSet} =
    useAdvancedSearchContext();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const limit = 5;
  const {errorSnack} = useSnacks();
  const {t} = useLocalization();
  const {
    pleaseConfirm: pleaseConfirmDeleteFilter,
    AlertDialog: ConfirmDeleteFilter,
    isOpen,
    isClosing,
  } = useAlertDialogAsPromiseV3();

  const handleDelete = useCallback(
    (id: string) => {
      pleaseConfirmDeleteFilter(
        () =>
          deleteFilterSet(id).catch(() => {
            errorSnack(t('page.search.filter.delete.failed'));
          }),
        {
          title: t('general.areyousure'),
          okTitle: t('general.delete'),
          okIcon: 'Delete',
          cancelTitle: t('general.cancel'),
        },
      );
    },
    [deleteFilterSet, errorSnack, pleaseConfirmDeleteFilter, t],
  );

  /**
   * Filtered by private and public, then name.
   */
  const ExpandableFilterSets = useMemo(() => {
    const items: React.ReactElement[] = [];

    // Headers only displayed one time each, or never if no items match
    let firstPrivate = filterSets.filter(c => !c.isPublic).length > 0;
    let firstPublic = filterSets.filter(c => c.isPublic).length > 0;

    filterSets.slice(0, isExpanded ? undefined : limit).forEach(f => {
      if (firstPrivate && !f.isPublic) {
        items.push(
          <Label key={'header-private'}>{t('data.field.private.label')}</Label>,
        );
        firstPrivate = false;
      }
      if (firstPublic && f.isPublic) {
        items.push(
          <Label key={'header-public'}>{t('data.field.public.label')}</Label>,
        );
        firstPublic = false;
      }
      items.push(
        <ListItem
          key={f.id}
          label={f.name}
          active={compareFilters(currentFilters, f.filters)}
          onClick={() => setCurrentFilters(f.filters)}
          actions={[
            {
              position: 'afterText',
              props: {
                icon: 'Delete',
                size: 'small',
                onClick: () => handleDelete(f.id),
              },
            },
          ]}
        />,
      );
    });

    return items;
  }, [
    currentFilters,
    filterSets,
    handleDelete,
    isExpanded,
    setCurrentFilters,
    t,
  ]);

  return (
    <>
      <Text variant={'body1'}>Lagrede søk</Text>

      <Stack p={1} direction={'column'} spacing={1} overflow={'hidden'}>
        {ExpandableFilterSets}

        {filterSets.length > limit ? (
          <ChevronToggle
            isExpanded={isExpanded}
            onClick={() => setIsExpanded(o => !o)}>
            {isExpanded ? 'Se færre' : 'Se flere'}
          </ChevronToggle>
        ) : null}
      </Stack>
      <ConfirmDeleteFilter isOpen={isOpen} isClosing={isClosing} />
    </>
  );
};
