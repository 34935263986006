import {createContext, useCallback, useContext, useMemo, useRef} from 'react';
import {assert} from 'assert-ts';
import {ReviewStatusType} from 'types';
import {
  Data,
  DataFormSchema,
  DataValue,
  Part,
  Valx,
} from 'schemaDefinition/types';
import {decorateSchema} from 'schemaDefinition/functions';
import {isNullish} from 'services/utils';
import {
  DataFormProps,
  FormMode,
  GetValueById,
  SetApprovedValue,
  SetPartValue,
  SetValue,
  UseValueById,
} from '../types';
import {isMultiple} from '../functions';
import {getSchemaForMode} from '../functions/getSchemaForMode';

type DataFormContextType<TId = string> = {
  /**
   * Id for instance of form.
   */
  id: TId;
  dataFormSchema: DataFormSchema;
  relatedData: Data;
  mode: FormMode;
  showErrors: boolean;
  setDefaultFieldValue: (key: string, part: Part<Valx>) => DataValue;
  useEditValue: UseValueById;
  setEditValue: SetPartValue;
  useOriginalValue?: UseValueById;
  useReviewStatus: UseValueById<ReviewStatusType>;
  setApprovedValue: SetApprovedValue;
};

export const DataFormContext = createContext<DataFormContextType | undefined>(
  undefined,
);

const NoGetValueById: GetValueById = () => undefined;
const NoSetValue: SetValue = () => () => undefined;
const NoUseValueById: UseValueById = () => undefined;
const NoUseReviewStatusById: UseValueById<ReviewStatusType> = () => undefined;
const NoSetApprovedValue: SetApprovedValue = () => () => undefined;

export const useDataFormContextProviderValue = <TData extends Data = Data>({
  id,
  mode = 'read-only',
  showErrors = false,
  schema,
  relatedData,
  setFieldValueModifier,
  getEditValue = NoGetValueById,
  useEditValue = NoUseValueById,
  setEditValue = NoSetValue,
  useOriginalValue = NoUseValueById,
  useReviewStatus = NoUseReviewStatusById,
  setApprovedValue = NoSetApprovedValue,
}: DataFormProps<TData>): DataFormContextType => {
  const idRef = useRef(id);

  const dataFormSchema = useMemo(() => {
    const modeSchema = getSchemaForMode(mode, schema);
    return decorateSchema(modeSchema);
  }, [mode, schema]);

  const handleSetValue = useCallback(
    (valuePath: string, value: DataValue, part?: Part<Valx>) => {
      const oldValue = getEditValue(valuePath, idRef.current) as DataValue;
      const entityValue = getEditValue('', idRef.current) as Data;
      const {value: modifiedValue, sideEffects: sideEffects} =
        setFieldValueModifier
          ? setFieldValueModifier({
              valuePath,
              value,
              isDefaultValue: false,
              part,
              oldValue,
              entityValue,
            })
          : {value, sideEffects: undefined};

      // Applies sideeffects
      if (Array.isArray(sideEffects) && sideEffects.length > 0) {
        sideEffects.forEach(sideEffect => {
          setEditValue(sideEffect.valuePath, sideEffect.value);
        });
      }

      setEditValue(valuePath, modifiedValue);
    },
    [getEditValue, setEditValue, setFieldValueModifier],
  );

  const handleSetDefaultFieldValue = useCallback(
    (valuePath: string, part: Part<Valx>) => {
      const staticDefaultValue = (part.default ?? null) as DataValue;
      assert(
        isNullish(staticDefaultValue) ||
          isMultiple(part.cardinality) === Array.isArray(staticDefaultValue),
        'Any default value must be an array if cardinality is multiple',
      );

      const entityValue = getEditValue('', idRef.current) as Data;
      const {value: defaultValue} = setFieldValueModifier
        ? setFieldValueModifier({
            valuePath,
            value: staticDefaultValue,
            isDefaultValue: true,
            validate: false,
            part,
            oldValue: null,
            entityValue,
          })
        : {value: staticDefaultValue};

      if (!isNullish(defaultValue)) {
        setEditValue(valuePath, defaultValue);
      }

      return defaultValue;
    },
    [getEditValue, setEditValue, setFieldValueModifier],
  );

  return useMemo(() => {
    return {
      id: idRef.current,
      dataFormSchema,
      mode,
      showErrors,
      relatedData,
      setDefaultFieldValue: handleSetDefaultFieldValue,
      useEditValue,
      setEditValue: handleSetValue,
      useOriginalValue,
      useReviewStatus,
      setApprovedValue,
    };
  }, [
    dataFormSchema,
    mode,
    showErrors,
    relatedData,
    handleSetDefaultFieldValue,
    useEditValue,
    handleSetValue,
    useOriginalValue,
    useReviewStatus,
    setApprovedValue,
  ]);
};

export const useDataFormContext = (): DataFormContextType => {
  return assert(
    useContext(DataFormContext),
    'useDataFormContext: context expected',
  );
};
