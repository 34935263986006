import React from 'react';
import {SxProps} from '@mui/material';
import {IconName} from 'components/icons/types';
import {ColorPalette} from 'theme';
import {ChangeRequestType} from 'api';
import {FlexBox, Icon} from 'components';

type Props = {
  type: ChangeRequestType;
};

const typeMap: Record<
  ChangeRequestType,
  {name: IconName; bgcolor: string; iconSx: SxProps}
> = {
  ML_MANUAL_APPROVAL: {
    name: 'ML2',
    bgcolor: ColorPalette.status.ml,
    iconSx: {color: ColorPalette.offWhite},
  },
  METADATA_NEW: {
    name: 'Download',
    bgcolor: ColorPalette.status.ml,
    iconSx: {color: ColorPalette.offWhite},
  },
  METADATA_MOVED: {
    name: 'Swap',
    bgcolor: ColorPalette.status.change,
    iconSx: {color: ColorPalette.primary.burgundy},
  },
  METADATA_COPIED: {
    name: 'Copy',
    bgcolor: ColorPalette.status.change,
    iconSx: {color: ColorPalette.primary.burgundy},
  },
  METADATA_IMPORT_CHANGE: {
    name: 'Building',
    bgcolor: ColorPalette.status.ml,
    iconSx: {color: ColorPalette.offWhite},
  },
};

export const ChangeRequestIcon: React.FC<Props> = ({type}) => {
  return (
    <FlexBox
      center
      bgcolor={typeMap[type].bgcolor}
      p={0.25}
      sx={{borderRadius: 1}}>
      <Icon icon={typeMap[type].name} sx={typeMap[type].iconSx} />
    </FlexBox>
  );
};
