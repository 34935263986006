import {EditStatuses, EntityEditStatus} from 'services/data/metadata/types';
import {getMergedEditStatus} from './getMergedEditStatus';
import {updateEditStatuses} from './updateEditStatuses';

export function updateEditStatusOnMoveToNewExpression(
  manifestationId: string,
  toExpressionId: string,
  editStatuses: EditStatuses,
): EditStatuses {
  const currentManifestationStatus: EntityEditStatus | undefined =
    editStatuses[manifestationId];
  const newManifestationStatus = getMergedEditStatus(
    {hasMoved: true},
    currentManifestationStatus,
  );
  const newStatuses1 = updateEditStatuses(
    manifestationId,
    newManifestationStatus,
    editStatuses,
  );
  const newStatuses2 = updateEditStatuses(
    toExpressionId,
    {hasDataChanged: true},
    newStatuses1,
  );

  return newStatuses2;
}
