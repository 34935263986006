import {assert} from 'assert-ts';
import {ByNameRef, Data, DataValue} from '../types';
import {evaluateByPathKeys} from './evaluateByPathKeys';
import {getPathKeys} from './getPathKeys';
import {isByNameRef} from './isRef';

export const evaluateByNameRef = (
  byNameRef: ByNameRef,
  scope: Data,
  /**
   * dot-separator: '', 'root', 'root.child', 'root.child[0].item'
   **/
  contextPath: string,
): DataValue => {
  const assertProps = {byNameRef, contextPath, scope};

  assert(
    isByNameRef(byNameRef),
    'evaluateByNameRef: invalid byNameRef',
    assertProps,
  );

  assert(scope, 'evaluateByNameRef: scope expected', assertProps);

  // Find path in scope
  // - search for first key in byNameRef from context (contextPath) and outwards
  const uniqueKeys = getPathKeys(byNameRef.substring(1));
  const firstKey = uniqueKeys[0];
  const contextKeys = getPathKeys(contextPath);
  contextKeys.push('dummy');
  let resolvedFirst = null;
  do {
    contextKeys.pop();
    const searchKeys = [...contextKeys, firstKey];
    resolvedFirst = evaluateByPathKeys(searchKeys, scope, false);
  } while (resolvedFirst === null && contextKeys.length > 0);

  return evaluateByPathKeys(
    [...contextKeys, ...uniqueKeys],
    scope,
    assertProps,
  );
};
