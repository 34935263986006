import React, {useMemo} from 'react';
import {Stack} from '@mui/material';
import assert from 'assert-ts';
import {TypeNodesWithAncestors} from 'services/thesaurus/types';
import {groupAndOrderByType} from 'services/thesaurus/functions';
import {ThesaurusValuesProps} from './types';
import {ThesaurusValueNodeType} from './ThesaurusValueNodeType';

export const ThesaurusValuesByTypes: React.FC<ThesaurusValuesProps> = ({
  thesaurusValue,
  thesaurus,
  readonly,
  showCode,
  selectableNodeTypes,
  highlight = [],
  highlightType,
  onDelete,
  onSelect,
}) => {
  const prType = useMemo((): TypeNodesWithAncestors[] => {
    const nodeTypes = assert(
      selectableNodeTypes,
      'selectableNodeTypes is required',
    );
    return groupAndOrderByType(nodeTypes, thesaurus, thesaurusValue);
  }, [selectableNodeTypes, thesaurus, thesaurusValue]);

  return thesaurusValue ? (
    <Stack spacing={1}>
      {prType.map(t => (
        <ThesaurusValueNodeType
          key={t.nodeType}
          thesaurusId={thesaurus.id}
          showCode={showCode}
          selectableNodeTypes={selectableNodeTypes}
          nodeType={t.nodeType}
          nodes={t.nodes}
          readonly={readonly}
          highlight={highlight}
          highlightType={highlightType}
          onDelete={onDelete}
          onSelect={onSelect}
        />
      ))}
    </Stack>
  ) : null;
};
