import {Configuration} from 'configuration';
import {SystemHealth, useGetResource} from 'api';
import {getSystemHealth} from 'api/system';
import {useGetTokens} from 'services/auth';
import {SystemHealthStatus} from '../types';
import {useAggregatedSystemStatus} from './useAggregatedSystemStatus';

const UPDATE_INTERVAL = 60 * 1000;

const EBSDown: SystemHealth = {
  syncComponents: [],
  messageQueues: [],
  services: [
    {
      name: 'EBS',
      status: 'DOWN',
    },
  ],
};

export const useSystemHealth = (): {
  status: SystemHealthStatus;
  data: SystemHealth | undefined;
} => {
  const interval = Configuration.interval.healthcheck ?? UPDATE_INTERVAL;
  const getTokens = useGetTokens();
  const healthData = useGetResource(
    () => getSystemHealth(getTokens, false),
    interval,
  );
  const status = useAggregatedSystemStatus(healthData);

  return {
    status,
    data: healthData.status === 'Failed' ? EBSDown : healthData.data,
  };
};
