import {HttpError} from '../../types';
import {getShouldFail} from '../../functions';
import {resolveURI} from '../../functions/resolveURI';
import {HttpArgs} from '../httpMethod';
import {getMockByKey} from './getMockByKey';

const responseTime = 2 * 1000;

export const httpMock = <TResponse, TBody>({
  method,
  subdir,
  queryParams,
}: HttpArgs<TBody>): Promise<TResponse> => {
  return new Promise<TResponse>((resolve, reject) => {
    const shouldFail = getShouldFail(method, subdir);

    setTimeout(
      () => {
        const url = resolveURI('', subdir, undefined, queryParams);

        if (shouldFail) {
          reject(new HttpError(500, url, `${method}: mock failed `));
        }

        if (['PUT', 'POST', 'DELETE'].includes(method)) {
          resolve(undefined as unknown as TResponse);
          return;
        }
        const fullKey = url
          .replace(/\//g, '.')
          // .replace(/\?/g, '_')
          .replace(/[=[\]]/g, '-');
        const [key] = (
          fullKey[0] === '.' ? fullKey.substring(1) : fullKey
        ).split('?');

        const data = getMockByKey(key);
        if (!data) {
          reject(new HttpError(404, url, 'mock not found', undefined, {key}));
          return;
        }

        resolve(data as TResponse);
      },
      method.toUpperCase() === 'GET' ? 500 : responseTime,
    );
  });
};
