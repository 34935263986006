import {AgentSubType, Concept, LinkTargetCatalogPostType} from 'types';
import {
  AgentName,
  Code as ApiCode,
  CodeList as ApiCodeList,
  CodeListId as ApiCodeListId,
  ThesaurusId as ApiThesaurusId,
  ThesaurusValue as ApiThesaurusValue,
  WorkWithExpressions,
} from 'api/types';
import {Schemas} from 'api/dto.generated';
import {LinkedRoleCodeListId, RoleEntityCode} from './linkTypes';

/** For now, applicable to GREP-codes */
export type SelectableThesaurusNodeType =
  | 'education_program_type'
  | 'education_program'
  | 'program_area'
  | 'subject_code';

export type SimpleCardinality = 'single' | 'multiple';
export type Cardinality = SimpleCardinality | '2';

export type FieldRequiredConditional<TVal = Val> = {
  should?: Condition<TVal> | Condition<TVal>[];
  required?: Condition<TVal> | Condition<TVal>[];
};

/**
 * Should implies that field should be filled in and will give warning when empty,
 * however, user is allowed to save
 */
export type FieldRequiredSimple = boolean | 'should';
export type FieldRequired<TVal = Val> =
  | FieldRequiredSimple
  | FieldRequiredConditional<TVal>;

/** Indicates invalid value in field, or warning e.g. if recommended value is missing */
export type FieldError = boolean | 'warning';

export type Constant = number | string | boolean | null;

export type RelativeDate = {
  $date: {
    /** Offset from current date, negative or positive */
    offset: number;
    /** Unit of offset */
    unit: 'day' | 'week' | 'month' | 'year';
  };
};

export const Today: RelativeDate = {
  $date: {
    offset: 0,
    unit: 'day',
  },
};

/**
 * Path to value in a given scope, e.g.
 * 'prop', 'prop.subprop', 'prop[0].subprop'
 */
export type RefPath = string;

export type PropRef = `.${RefPath}`;

export type ByNameRef = `#${RefPath}`;

export type GlobalRef = `/${RefPath}`;

export type RelatedRef = `^${RefPath}`;

export type Reference = {
  /**
   * Reference to value in json structure, either:
   * - Prop ref: .path (from local value), e.g. agentId on linked value
   * - By name: #<path> (from local scope, will search upwards until match)
   * - Globabl: /<path> (from root schema)
   * - Related: ^<path> (from related data, e.g. {work: {...}} in expression schema)
   * Where <path> is e.g., 'prop', 'prop.subprop', 'prop[0].subprop'
   */
  $ref: PropRef | ByNameRef | GlobalRef | RelatedRef;
};

export type SchemaValueType = Constant | RelativeDate | Reference;
type Val = SchemaValueType;

export type SchemaValue<TVal = Val> = TVal;

/**
 * @obsolete
 * True if arg1 op arg2
 * @param arg1 default value is "self"
 * TODO: Migration to easier syntax;
 */
export type ComparisonConditionV0<TVal = Val> = {
  op: 'lt' | 'le' | 'eq' | 'ge' | 'gt';
  arg1?: SchemaValue<TVal>;
  arg2: SchemaValue<TVal>;
};

export type EqComparisonCondition<TVal = Val> = {
  /**
   * Compares two values
   * - If only single value is given, the value of the field (left hand operand)
   *   is compared to the given value
   * - If array of two values is given, they are compared
   */
  $eq: [SchemaValue<TVal>, SchemaValue<TVal>] | SchemaValue<TVal>;
};

export type LtComparisonCondition<TVal = Val> = {
  /**
   * Compares two values
   * - If only single value is given, the value of the field (left hand operand)
   *   is compared to the given value
   * - If array of two values is given, they are compared
   */
  $lt: [SchemaValue<TVal>, SchemaValue<TVal>] | SchemaValue<TVal>;
};

export type LeComparisonCondition<TVal = Val> = {
  /**
   * Compares two values
   * - If only single value is given, the value of the field (left hand operand)
   *   is compared to the given value
   * - If array of two values is given, they are compared
   */
  $le: [SchemaValue<TVal>, SchemaValue<TVal>] | SchemaValue<TVal>;
};

export type GeComparisonCondition<TVal = Val> = {
  /**
   * Compares two values
   * - If only single value is given, the value of the field (left hand operand)
   *   is compared to the given value
   * - If array of two values is given, they are compared
   */
  $ge: [SchemaValue<TVal>, SchemaValue<TVal>] | SchemaValue<TVal>;
};

export type GtComparisonCondition<TVal = Val> = {
  /**
   * Compares two values
   * - If only single value is given, the value of the field (left hand operand)
   *   is compared to the given value
   * - If array of two values is given, they are compared
   */
  $gt: [SchemaValue<TVal>, SchemaValue<TVal>] | SchemaValue<TVal>;
};

export type ComparisonCondition<TVal = Val> =
  | EqComparisonCondition<TVal>
  | LtComparisonCondition<TVal>
  | LeComparisonCondition<TVal>
  | GeComparisonCondition<TVal>
  | GtComparisonCondition<TVal>;

/**
 * True if referred value is truely
 */
export type BooleanCondition<TVal = Val> = {
  arg?: SchemaValue<TVal>;
};

export type ExpressionCondition<TVal = Val> =
  | ComparisonConditionV0<TVal>
  | ComparisonCondition<TVal>;

export type RangeCondition<TVal = Val> = {
  arg1?: SchemaValue<TVal>;
  min?: SchemaValue<TVal>;
  max?: SchemaValue<TVal>;
};

export type LengthCondition<TVal = Val> = {
  arg1?: SchemaValue<TVal>;
  minLength?: SchemaValue<TVal>;
  maxLength?: SchemaValue<TVal>;
};

export type RegexCondition<TVal = Val> = {
  arg?: SchemaValue<TVal>;
  /**
   * Regex to match for condition to be true.
   * Only inner part of regex as string, i.e. "test" not /test/
   * If an array of regexps is set, one of them must match for value to be valid
   */
  regex: string | string[];

  /**
   * Default value for condition when null, undefined and '' (independent of regex match)
   *
   * Often true on validations (because empty is usually valid),
   * and false on include conditions
   */
  default: boolean;
};

/**
 *
 */
export type IncludesCondition<TVal = Val> = {
  /**
   * True if first item (array of values) includes second item (single value).
   *
   * Array of two items:
   * - list: Should evaluate to array of values or null/undefined, e.g.
   *   - [CodeListRef.LITERATURE_TYPE.Faglitteratur, CodeListRef.LITERATURE_TYPE.Skjonnlitteratur]
   *   - { $ref: '#languages' } // Array of languages in scope
   * - value: Should evaluate to single value or null/undefined
   */
  $includes: [
    list: SchemaValue<TVal> | SchemaValue<TVal>[],
    value: SchemaValue<TVal>,
  ];
  /**
   * Default value for condition when list is null, undefined or empty or value is null, undefined or ''
   *
   * Often true on validations (because null/empty is handled by required validation),
   * and false on include conditions
   */
  default: boolean;
};

/**
 * @obsolete
 */
export type NotConditionV0<TVal = Val> = {
  op: 'not';
  arg: Condition<TVal>;
};

export type NotCondition<TVal = Val> = {
  $not: Condition<TVal>;
};

/**
 * @obsolete
 */
export type OrConditionV0<TVal = Val> = {
  op: 'or';
  arg: Condition<TVal>[];
};

export type OrCondition<TVal = Val> = {
  $or: Condition<TVal>[];
};

/**
 * @obsolete
 */
export type AndConditionV0<TVal = Val> = {
  op: 'and';
  arg: Condition<TVal>[];
};

export type AndCondition<TVal = Val> = {
  $and: Condition<TVal>[];
};

export type LogicalConditionV0<TVal = Val> =
  | NotConditionV0<TVal>
  | OrConditionV0<TVal>
  | AndConditionV0<TVal>;

export type LogicalCondition<TVal = Val> =
  | NotCondition<TVal>
  | OrCondition<TVal>
  | AndCondition<TVal>;

export type Condition<TVal = Val> =
  | BooleanCondition<TVal>
  | ExpressionCondition<TVal>
  | RangeCondition<TVal>
  | LengthCondition<TVal>
  | RegexCondition<TVal>
  | IncludesCondition<TVal>
  | LogicalConditionV0<TVal>
  | LogicalCondition<TVal>;

type ValidationErrorLevel = 'error' | 'warning';

export type BaseValidation = {
  /** Id is used to reference a condition for modification, e.g. from warning to error */
  id?: string;
  /** Level of error if condition is not met, default is error */
  level?: ValidationErrorLevel;
  /** Key to error/warning message to be displayed if condition is not met */
  messageKey?: string;
};

export type TextValidation<TVal = Val> = BaseValidation &
  (LengthCondition<TVal> | RegexCondition<TVal>);

export type NumberValidation<TVal = Val> = BaseValidation &
  (RangeCondition<TVal> | ExpressionCondition<TVal>);

export type DateValidation<TVal = Val> = BaseValidation & RangeCondition<TVal>;

export type AnyValidation<TVal = Val> = BaseValidation & Condition<TVal>;

export type PartAction = 'copy';

export type PartCompare =
  /** Compare full value, may be array, object, simple value */
  | 'fullValue'
  /** Compare corresponding leaf-values in array/object structure, or until fullValue or items is specified */
  | 'subValues'
  /** Compare corresponding items in a list. Applicable to parts with cardinality > 1 */
  | 'items';

export const DefaultPartCompare: PartCompare = 'fullValue';

export type BasePart<TVal, T, TPartKey = string> = {
  /** name will correspond to data value in corresponding context */
  name: TPartKey;
  role?: undefined;
  labelKey?: string;
  /** Customization key, Key of modifier function to intercept updates to value, e.g. to format value */
  customizeKey?: string;
  readonly?: boolean;
  required?: FieldRequired<TVal>;
  cardinality?: Cardinality;
  /** Default value of part, if any */
  default?: T extends undefined ? T : T | T[];
  /**
   * TODO: Replace specifc validation props with this one
   * Validation rules for part.
   * When cardinality is multiple:
   * - validation will be applied to each value in array
   */
  validation?: AnyValidation<TVal> | AnyValidation<TVal>[];
  /** Only applies to cardinality multiple/2. Will validate across list of values, e.g. to verify reader of audio book */
  listValidation?: AnyValidation<TVal> | AnyValidation<TVal>[];
  /** Actions on part/part value, i.e. copy */
  actions?: PartAction[];
  /**
   * Compare mode for part (and possible subparts)
   * - fullValue: compare whole value (i.e. same json)
   * - subValues: Will compare the original value with the new value and show the difference
   *   and, vice versa, compare the new value with the original value and show the difference
   * - items: Will compare each item in array separately. Pr item, compare fullValue.
   *   Only applicable for parts with cardinality multiple or 2
   *
   * Default is 'fullValue'
   *
   * Only applicable for parts whose parent is a schema with compare set to 'nested'
   */
  compare?: PartCompare;
};

/**
 * Defines same property keys as BasePart, but all as optional and undefined
 * Purpose is to ease use of general Part type, when all subtypes of Part
 * have these property keys
 */
type NoBasePart = {
  name?: undefined;
  /** role is used to refer to part, but will not correspond to data value, as BasePart.name does */
  role: string;
  readonly?: undefined;
  required?: undefined;
  cardinality?: undefined;
  labelKey?: undefined;
  /** Key of modifier function to intercept updates to value, e.g. to format value */
  customizeKey?: undefined;
  default?: undefined;
  validation?: undefined;
  listValidation?: undefined;
  actions?: undefined;
  compare?: undefined;
};

/**
 * Expands schema with conditional parts. Defines one or more cases. First case with conditions
 * satisfied will be expanded. A default may also be defined, which will be expanded
 * when none of the cases are satisfied.
 */
export type PartExpand<TVal = Val, TPartKey = string> = Omit<
  NoBasePart,
  'default'
> & {
  type: 'expand';
  when: Array<{
    /** When array of conditions, all conditions must be true (and) */
    condition: Condition<TVal> | Condition<TVal>[];
    parts: SeparatedParts<TVal, TPartKey>;
  }>;
  default?: SeparatedParts<TVal, TPartKey>;
};

export type PartSchema<TVal = Val, TPartKey = string> = Omit<
  BasePart<TVal, Data, TPartKey>,
  'compare'
> &
  Schema<TVal> & {
    type: 'schema';
    variant?: 'form' | 'table';
    /**
     * When noValidation is set, validation will be ignored for its subparts
     */
    noValidation?: boolean;
    /**
     * Whether to compare schema part nested or fullValue
     * - nested: compare values of nested parts, i.e. parts in nested schemas
     * - fullValue: compare whole value (i.e. same json)
     * - subValues: Will compare the original value with the new value and show the difference
     *   and, vice versa, compare the new value with the original value and show the difference
     * - items: Will compare each item in array separately. Pr item, compare fullValue.
     *   Only applicable for parts with cardinality multiple or 2
     *
     * Default is 'fullValue'
     */
    compare?: 'nested' | PartCompare;
  };

export type PartText<TVal = Val, TPartKey = string> = BasePart<
  TVal,
  string,
  TPartKey
> & {
  type: 'text';
  /** Prefix string/icon reference displayed in input field and in preview value */
  prefix?: string;
  /** Prefix override when value is empty: string/icon reference displayed in input field and in preview value */
  prefixOnEmpty?: string;
  /** Suffix string/icon reference displayed in input field and in preview value */
  suffix?: string;
  /** Suffix override when value is empty: string/icon reference displayed in input field and in preview value */
  suffixOnEmpty?: string;
  validation?: TextValidation<TVal> | TextValidation<TVal>[];
};

export type PartTextArea<TVal = Val, TPartKey = string> = BasePart<
  TVal,
  string,
  TPartKey
> & {
  type: 'textarea';
  validation?: TextValidation<TVal> | TextValidation<TVal>[];
  maxLength?: number;
};

// Html part, only read-only for now, i.e. omit props for edit
export type PartHtml<TVal = Val, TPartKey = string> = Omit<
  BasePart<TVal, string, TPartKey>,
  'readonly' | 'required' | 'customizeKey' | 'default' | 'validation'
> &
  Pick<NoBasePart, 'required' | 'customizeKey' | 'default' | 'validation'> & {
    type: 'html';
    readonly: true;
  };

export type PartInt<TVal = Val, TPartKey = string> = BasePart<
  TVal,
  number,
  TPartKey
> & {
  type: 'int';
  /** Prefix string/icon reference (icon:name) displayed in input field and in preview value */
  prefix?: string;
  /** Prefix override when value is empty: string/icon reference displayed in input field and in preview value */
  prefixOnEmpty?: string;
  /** Suffix string/icon reference displayed in input field and in preview value */
  suffix?: string;
  /** Suffix override when value is empty: string/icon reference displayed in input field and in preview value */
  suffixOnEmpty?: string;
  validation?: NumberValidation<TVal> | NumberValidation<TVal>[];
};

export type PartBool<TVal = Val, TPartKey = string> = BasePart<
  TVal,
  boolean,
  TPartKey
> & {
  type: 'bool';
};

export type PartYear<TVal = Val, TPartKey = string> = BasePart<
  TVal,
  number,
  TPartKey
> & {
  type: 'year';
  default?: number;
  validation?: DateValidation<TVal> | DateValidation<TVal>[];
};

export type PartDate<TVal = Val, TPartKey = string> = BasePart<
  TVal,
  string,
  TPartKey
> & {
  type: 'date';
  validation?: DateValidation<TVal> | DateValidation<TVal>[];
};

export type PartYearOrDate<TVal = Val, TPartKey = string> = Omit<
  BasePart<TVal, YearOrDateValue, TPartKey>,
  'validation'
> & {
  type: 'yearOrDate';
  default?: number | string;
  /** Add checkbox to indicate BCE. Default false */
  includeBce?: boolean;
  /** Add checkbox to indicate approximate year/date. Default false */
  includeApproximate?: boolean;
  validation?: DateValidation<TVal> | DateValidation<TVal>[];
};

export type PartCodeList<TVal = Val, TPartKey = string> = BasePart<
  TVal,
  string,
  TPartKey
> & {
  type: 'codelist';
  codelistId: CodeListId;
  showCode?: boolean;
  /** Default variant is 'dropdown' */
  variant?: 'dropdown' | 'buttons';
  validation?: AnyValidation<TVal> | AnyValidation<TVal>[];
};

export type PartCodeListOrText<TVal = Val, TPartKey = string> = BasePart<
  TVal,
  string,
  TPartKey
> & {
  type: 'codelist|text';
  codelistId: CodeListId;
  showCode?: boolean;
  default?: string | string[];
  /** Default variant is 'dropdown' */
  variant?: 'dropdown' | 'buttons';
  validation?: AnyValidation<TVal> | AnyValidation<TVal>[];
};

export type ShowThesaurusCode = boolean;
export type PartThesaurus<TVal = Val, TPartKey = string> = Omit<
  BasePart<TVal, ThesaurusValue, TPartKey>,
  'cardinality' | 'compare'
> & {
  type: 'thesaurus';
  thesaurusId: ThesaurusId;
  // Must always be multiple
  cardinality: 'multiple';
  /**
   * Thesaurus compare is handled specifically
   */
  compare?: undefined;
  showCode?: ShowThesaurusCode;
  /**
   * If set,
   * - only nodes of these types will be selectable,
   * - only nodes of these types will show code
   * - thesaurus value will be ordered by order of types in array
   * If not set, all nodes will be selectable
   */
  selectableNodeTypes?: SelectableThesaurusNodeType[];
  /**
   * Thesaurus value display variants:
   * - byCategory: show terms grouped by top level node (facet). Default
   * - byType: show terms grouped by node type (assumes nodes have type)
   */
  variant?: 'byCategory' | 'byType';
  validation?: AnyValidation<TVal> | AnyValidation<TVal>[];
};

export type PartLinkedAgent<TVal = Val, TPartKey = string> = BasePart<
  TVal,
  LinkedAgentMultiRole,
  TPartKey
> & {
  type: 'linkedAgent';
  entitySubtypes?: AgentSubType[];
  /**
   * If set, static role is assumed and linked value edit will not include selector for roles of linked entity
   */
  entityRole?: string;
  roleCodelistId: CodeListId;
  validation?: AnyValidation<TVal> | AnyValidation<TVal>[];
};

export type PartLinkedLiterary<TVal = Val, TPartKey = string> = Omit<
  BasePart<TVal, LinkedAgentMultiRole, TPartKey>,
  'compare'
> & {
  type: 'linkedLiterary';
  /** subValues not suported for linked literary */
  compare?: Exclude<PartCompare, 'subValues'>;
  /**
   * Id of linked role codelist containing possible link roles
   */
  roleCodelistId: LinkedRoleCodeListId;
  /**
   * If set, static role is assumed and linked value edit will not include selector for roles of linked entity
   * E.g. manifestation may only be part of publisher series, and vice versa
   */
  linkRole?: RoleEntityCode;
  /**
   * When link role has link property: whether link-prop is required
   */
  linkPropRequired?: FieldRequiredSimple | undefined;
  validation?: AnyValidation<TVal> | AnyValidation<TVal>[];
};

export type PartNameVariant<TVal = Val, TPartKey = string> = Omit<
  BasePart<TVal, NameVariantValue, TPartKey>,
  'cardinality'
> & {
  type: 'nameVariant';
  agentType: AgentSubType;
  // When used, namevariants will always have cardinality multiple
  cardinality: 'multiple';
};

type SeparatorVariant = 'card' | 'line' | 'none';

export type PartSeparator = NoBasePart & {
  type: 'separator';
  titleKey?: string;
  /**
   * Form variants of separation/grouping. Default 'none'
   * - 'card': will separate each group into different cards
   * - 'line': will just add a horizontal ruler to separate groups
   * - 'none': no separator in form mode
   */
  form?: SeparatorVariant;
  /**
   * Preview variants of separation/grouping. Default 'none'
   * - 'card': will separate each group into different cards
   * - 'line': will just add a horizontal ruler to separate groups
   * - 'none': no separator in preview mode
   */
  preview?: SeparatorVariant;
  include?: 'always';

  name?: undefined;
};

export type Part<TVal = Val, TPartKey = string> =
  | PartText<TVal, TPartKey>
  | PartTextArea<TVal, TPartKey>
  | PartHtml<TVal, TPartKey>
  | PartInt<TVal, TPartKey>
  | PartBool<TVal, TPartKey>
  | PartYear<TVal, TPartKey>
  | PartDate<TVal, TPartKey>
  | PartYearOrDate<TVal, TPartKey>
  | PartCodeList<TVal, TPartKey>
  | PartCodeListOrText<TVal, TPartKey>
  | PartThesaurus<TVal, TPartKey>
  | PartLinkedAgent<TVal, TPartKey>
  | PartLinkedLiterary<TVal, TPartKey>
  | PartNameVariant<TVal, TPartKey>
  | PartExpand<TVal, TPartKey>
  | PartSchema<TVal, TPartKey>;

export type PartType = Part['type'];

export type PartRef = Partial<
  Pick<BasePart<unknown, Data>, 'name' | 'labelKey'> & {
    type: PartType;
  }
>;

export type PartValueType = Exclude<
  PartType,
  | 'schema'
  | 'expand'
  | 'nameVariant'
  | 'codelist'
  | 'codelist|text'
  | 'linkedAgent'
  | 'linkedLiterary'
>;

/**
 * A part row consists of a single part or a list of parts
 */
export type PartRow<TVal = Val, TPartKey = string> =
  | Part<TVal, TPartKey>
  | Part<TVal, TPartKey>[];

/**
 * Top level parts are layed out vertically, while second level parts (PartRow)
 * are layed ouut horizontally, e.g.:
 * [
 *   part1,
 *   [part2, part3],
 *   part4
 * ]
 * part1, [part2, part3] and part4 are layed out vertically, while
 * part2 and part3 are layed out horizontally.
 */
export type Parts<TVal = Val, TPartKey = string> = PartRow<TVal, TPartKey>[];

export type SeparatedPart<TVal = Val, TPartKey = string> =
  | PartSeparator
  | PartRow<TVal, TPartKey>;

export type SeparatedParts<TVal = Val, TPartKey = string> = SeparatedPart<
  TVal,
  TPartKey
>[];

export type GroupedParts<TVal> = {
  titleKey?: string;
  role?: string;
  parts: SeparatedParts<TVal>;
};

export type Schema<TVal = Val, TPartKey = string> = {
  name: string;
  /** Used to lookup schema scoped localizations */
  key?: string;
  /**
   * Used when aggregating schemas, e.g. for change request, for global references
   * to be resolved correctly in context of aggregated schema and data
   *
   * @example
   * schema: {
   *   work: { globalScopePath: 'work', ...workSchema},
   *   expression: { globalScopePath: 'expression', ...expressionSchema},
   *   manifestation: { globalScopePath: 'manifestation', ...manifestationSchema},
   * }
   * data: {
   *  work: {...},
   *  expression: {...},
   *  manifestation: {...},
   * }
   * */
  globalScopePath?: string;
  parts: SeparatedParts<TVal, TPartKey>;
};

export type TypedSchema<TEntity> = Schema<Val, keyof TEntity>;

export const Separator = (
  form: SeparatorVariant,
  preview: SeparatorVariant,
): PartSeparator => ({
  type: 'separator',
  role: 'group',
  form,
  preview,
});

export const AlwaysIncludedSeparator = (
  form: SeparatorVariant,
  preview: SeparatorVariant,
): PartSeparator => ({
  ...Separator(form, preview),
  include: 'always',
});

/****************************
 * Data types
 ****************************/

export type Code = ApiCode;
export type CodeListId = ApiCodeListId;
export type CodeList = ApiCodeList;

export type ThesaurusId = ApiThesaurusId;

//----- Linked Value Links: Generalisation of link-part of LinkedAgent and LinkedLiterary  ------------------------------
export type LinkStatus = LinkedValueLink['linkStatus'];

export const LinkStatuses: Readonly<LinkStatus[]> = [
  /** No value yet, being edited */
  'empty',
  /** Entity (only applicable for agent) is only a string, no id */
  'unverified',
  /** Entity is a verified object, with or without national id */
  'verified',
  /** Entity is unnamed agent/ai */
  'unnamed',
] as const;

export type EmptyLinkedValueLink = {
  linkStatus: 'empty';
  name?: null;
  entityId?: null;
  nationalId?: null;
  type?: null;
  languages?: null;
};

export type UnverifiedLinkedValueLink = {
  linkStatus: 'unverified';
  name: Schemas.UnverifiedAgentRoleDto['name'];
  entityId?: null;
  nationalId?: null;
  type?: null;
  /** Language codes, applicable to series: languages titles */
  languages?: string[] | null;
};

/** Will only represent AI-"links", which will not be links or linkable */
export type UnnamedLinkedValueLink = {
  linkStatus: 'unnamed';
  name: Schemas.UnnamedAgentRoleDto['agentName'];
  entityId?: null;
  nationalId?: null;
  type?: null;
  languages?: null;
};

export type VerifiedLinkedCatalogPostLink = {
  linkStatus: 'verified';
  /** uuid of linked work, manifestation or series */
  entityId: string;
  /** Type of entity linked to, e.g. Concept.work or Concept.series */
  type: LinkTargetCatalogPostType;
  name: string;
  nationalId?: string;
  /** Language codes, applicable to series: languages of titles */
  languages?: string[] | null;
  /**
   * May contain entityValue (only WorkWithExpressions for now) to allow
   * more context info to confirm move and copying values from entity
   */
  entityValue?: WorkWithExpressions;
};

export const AgentTypes: Readonly<string[]> = [
  Concept.person,
  Concept.corporation,
  Concept.event,
  Concept.publisher,
] as const;

export type VerifiedLinkedAgentLink = {
  linkStatus: 'verified';
  entityId: string; // uuid of linked agent
  type: AgentSubType;
  agentName: AgentName;
  description?: string;
  nationalId?: string;
  numberInSeries?: string | null;
};

//----- LinkedValue ----------------------------------------------------------------------------------

export type VerifiedLinkedValueLink =
  | VerifiedLinkedCatalogPostLink
  | VerifiedLinkedAgentLink;

export type LinkedValueLink =
  | EmptyLinkedValueLink
  | UnverifiedLinkedValueLink
  | VerifiedLinkedValueLink
  | UnnamedLinkedValueLink;

export type LinkedValue = LinkedAgentMultiRole | LinkedLiterary;

//----- LinkedAgent ----------------------------------------------------------------------------------

export type LinkedAgentRoles = {
  roles: Schemas.UnverifiedAgentRoleDto['roles'];
};

export type UnnamedAgentRole = {
  unnamedAgentRole: Schemas.UnnamedAgentRoleDto['unnamedAgentRole'];
};

export type EmptyLinkedAgentMultiRole = {
  link: EmptyLinkedValueLink;
} & LinkedAgentRoles;

export type UnverifiedLinkedAgentMultiRole = {
  link: UnverifiedLinkedValueLink;
} & LinkedAgentRoles;

export type UnnamedLinkedAgentMultiRole = {
  link: UnnamedLinkedValueLink;
} & LinkedAgentRoles &
  UnnamedAgentRole;

export type VerifiedLinkedAgentMultiRole = {
  link: VerifiedLinkedAgentLink;
} & LinkedAgentRoles;

export type LinkedAgentLink =
  | EmptyLinkedValueLink
  | UnverifiedLinkedValueLink
  | VerifiedLinkedAgentLink
  | UnverifiedLinkedValueLink;

export type LinkedAgentMultiRole =
  | EmptyLinkedAgentMultiRole
  | UnverifiedLinkedAgentMultiRole
  | VerifiedLinkedAgentMultiRole
  | UnnamedLinkedAgentMultiRole;

//----- LinkedLiterary ----------------------------------------------------------------------------------

export type LinkedLiteraryRole = {
  role?: RoleEntityCode;
  // Alternatively:
  // // &: Ensure role has same type for all links
  // role: Schemas.AgentLinkDto['role'] & Schemas.CatalogPostLinkDto['role'];
};

export type LinkedLiteraryNumberInSeries = {
  /** I.e. part number in numbered series, e.g. 3 (of 5) */
  numberInSeries?: string | null;
};

/*
export type LinkedLiteraryProperty = {
  prop?: number;
  // Alternatively:
  // // &: Ensure role has same type for all links
  // role: Schemas.AgentLinkDto['role'] & Schemas.CatalogPostLinkDto['role'];
};
*/

export type EmptyLinkedLiterary = {
  link: EmptyLinkedValueLink;
} & LinkedLiteraryRole &
  LinkedLiteraryNumberInSeries;

export type UnverifiedLinkedLiterary = {
  link: UnverifiedLinkedValueLink;
} & LinkedLiteraryRole &
  LinkedLiteraryNumberInSeries;

export type VerifiedLinkedLiterary = {
  link: VerifiedLinkedValueLink;
} & Required<LinkedLiteraryRole> &
  LinkedLiteraryNumberInSeries;

export type LinkedLiterary =
  | EmptyLinkedLiterary
  | UnverifiedLinkedLiterary
  | VerifiedLinkedLiterary;

export type VerifiedLinkedCatalogPost = {
  link: VerifiedLinkedCatalogPostLink;
} & Required<LinkedLiteraryRole> &
  LinkedLiteraryNumberInSeries;

export type VerifiedLinkedAgent = {
  link: VerifiedLinkedAgentLink;
} & Required<LinkedLiteraryRole>;

/** Only one of year or date should be set */
export type YearOrDateValue = {
  /** As year, value being year, e.g. 2022  */
  year?: number | null;
  /** As date, value being "YYYY-MM-DD" or "-YYYY-MM-DD" */
  date?: string | null;
  /** Negative date/date is BCE/BC */
  bce?: boolean;
  /**
   * Indicates that the year/date is approximate
   * @default false
   */
  isApproximate?: boolean;
};

export type DateValue = {
  year: number; // -9999 to 9999
  month: number; // 1 to 12
  day: number; // 1 to 31
};

// Given to components
export type DateRange = {
  minValue: DateValue | undefined;
  maxValue: DateValue | undefined;
};

export type YearRange = {
  minValue: number | undefined;
  maxValue: number | undefined;
};

// Includes BCE
/**
 * @deprecated
 */
export type BceDateRange = {
  minValue: BceDateValue | undefined;
  maxValue: BceDateValue | undefined;
};
export type BceDateValue = Required<{
  date: Exclude<YearOrDateValue['date'], null>;
  bce: YearOrDateValue['bce'];
}>;

export type NameVariantValue = AgentName;
export type NameForm = 'mainForm' | 'variant';

/**
 * Array of thesaurus node codes (or ids if code is missing)
 * (when loading thesaurus, thesaurus node code will be set from id if missing)
 */
export type ThesaurusValue = ApiThesaurusValue;

export type DataSimpleValue =
  | number
  | string
  | boolean
  | LinkedValue
  | YearOrDateValue
  | NameVariantValue
  | ThesaurusValue
  | null;

export type DataValue =
  | null
  | DataSimpleValue
  | Data
  | DataSimpleValue[]
  | Data[];

export type Data = {
  [key: string]: DataValue;
};

export type ChangeRequestAction = 'replace' | 'addItem' | 'removeItem';

/****************************
 * Schema modifiers
 ****************************/

export type ValidationModifier<TVal = Val> = Partial<AnyValidation<TVal>>;

export type PartModifierContext = 'expand.when' | 'expand.default';

export type ValuePartModifier<TPropKey = string, TVal = Val> = Pick<
  BasePart<TVal, DataValue, TPropKey>,
  'name' | 'required' | 'readonly'
> &
  Pick<PartLinkedLiterary<TVal, TPropKey>, 'linkPropRequired'> & {
    validation?:
      | ValidationModifier<DataValue>
      | ValidationModifier<DataValue>[];
    listValidation?:
      | ValidationModifier<DataValue>
      | ValidationModifier<DataValue>[];
    partContext?: PartModifierContext;
  };

export type SchemaPartModifier<TPropKey = string, TVal = Val> = Pick<
  BasePart<TVal, DataValue, TPropKey>,
  'name' | 'required'
> & {
  parts: SchemaModifier;
  validation?: ValidationModifier<DataValue> | ValidationModifier<DataValue>[];
  listValidation?:
    | ValidationModifier<DataValue>
    | ValidationModifier<DataValue>[];
  partContext?: PartModifierContext;
};

export type PartModifier<TPropKey = string> =
  | ValuePartModifier<TPropKey>
  | SchemaPartModifier<TPropKey>;

export type SchemaModifier<TPropKey = string> = PartModifier<TPropKey>[];

export type TypedSchemaModifier<TEntity> = SchemaModifier<keyof TEntity>;

export type SchemaModifierMap<TKey extends string> = {
  [key in TKey]?: SchemaModifier;
};

export type TypedSchemaModifierMap<TEntity, TModifierKey extends string> = {
  [key in TModifierKey]?: TypedSchemaModifier<TEntity>;
};

/****************************
 * Resolved schema
 ****************************/

export type PathKeys = (string | number)[];

/**
 * Represents resolved SchemaValue
 * One of the properties will be set, while the others are undefiend
 */
export type DataFormValueType = {
  original: SchemaValueType | undefined;
} & (
  | {
      value: Constant | Constant[];
      propPath?: undefined;
      localPathKeys?: undefined;
      globalPathKeys?: undefined;
      globalByName?: undefined;
      relatedPathKeys?: undefined;
    }
  | {
      // .
      value?: undefined;
      propPath: RefPath;
      localPathKeys?: undefined;
      globalPathKeys?: undefined;
      globalByName?: undefined;
      relatedPathKeys?: undefined;
    }
  | {
      value?: undefined;
      propPath?: undefined;
      localPathKeys: PathKeys;
      globalPathKeys?: undefined;
      globalByName?: undefined;
      relatedPathKeys?: undefined;
    }
  | {
      value?: undefined;
      propPath?: undefined;
      localPathKeys?: undefined;
      globalPathKeys: PathKeys;
      globalByName?: undefined;
      relatedPathKeys?: undefined;
    }
  | {
      value?: undefined;
      propPath?: undefined;
      localPathKeys?: undefined;
      globalPathKeys?: undefined;
      globalByName: ByNameRef;
      relatedPathKeys?: undefined;
    }
  | {
      value?: undefined;
      propPath?: undefined;
      localPathKeys?: undefined;
      globalPathKeys?: undefined;
      globalByName?: undefined;
      relatedPathKeys: PathKeys;
    }
);

export type Valx = DataFormValueType;

export type DataFormSchema = Schema<DataFormValueType>;

/**
 * Function signatures
 */

export type SideEffect = {
  valuePath: string;
  value: DataValue;
};

export type PartValueModifier = (params: {
  valuePath: string;
  value: DataValue;
  isDefaultValue: boolean;
  validate?: boolean;
  part?: Part<Valx>;
  oldValue?: DataValue;
  entityValue?: Data;
}) => {
  value: DataValue;
  sideEffects?: SideEffect[];
};

// Validation

export type Valid = 'valid' | 'warning' | 'error';

export type ValidationError = {
  value: DataValue | undefined;
  valuePath: string;
  part: Part<Valx> | Schema<Valx>;
  localScope: Data | undefined;
  validation:
    | Condition<Valx>
    | 'required'
    | 'invalid type'
    | 'invalid value'
    | 'missing codelist'
    | 'custom';
  messageKey?: string;
  level: ValidationErrorLevel;
};

export type PartValueValidator<TData extends Data> = (
  valuePath: string,
  value: DataValue | undefined,
  part: Part<Valx>,
  localScope: Data | undefined,
  globalScope: TData,
) => {valid: Valid; error?: ValidationError};
