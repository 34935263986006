import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const InfoIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM13 16v-5h-2v5h2Z" />
    </SvgIcon>
  );
};
