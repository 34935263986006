import {useEffect, useState} from 'react';
import {
  DataLoadStatus,
  HttpError,
  WorkWithExpressions,
  getManifestation,
  getWorkWithExpressions,
} from 'api';
import {useGetTokens} from 'services/auth';

export const useWorkForManifestation = (
  manifestationId: string | undefined,
): DataLoadStatus<WorkWithExpressions> => {
  const getTokens = useGetTokens();
  const [result, setResult] = useState<DataLoadStatus<WorkWithExpressions>>({
    status: 'NotLoaded',
  });

  useEffect(() => {
    if (manifestationId && result.status === 'NotLoaded') {
      setResult(old => ({status: 'Loading', data: old.data}));
      getManifestation(manifestationId, getTokens)
        .then(manifestation =>
          getWorkWithExpressions(manifestation.workId, getTokens),
        )
        .then(data => setResult(() => ({status: 'Loaded', data})))
        .catch((error: HttpError) =>
          setResult(() => ({status: 'Failed', error})),
        );
    }
    // Only trigger if manifestationId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manifestationId]);

  return result;
};
