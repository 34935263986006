import {CodeList, ManifestationV4} from 'api/types';
import {getProductFormText} from 'services/data/metadata/functions';

export const getManifestationHeaderTitle = (
  data: ManifestationV4 | undefined,
  codelists: {
    productFormOverride?: CodeList;
    productForm?: CodeList;
    productFormDetails?: CodeList;
  },
): {line1: string; line2?: string} => {
  if (!data) return {line1: ''};
  const manifestation = data;

  const titles = (manifestation?.mainTitles || []).map(m => m.value);
  const productFormText = getProductFormText({
    code: manifestation.productForm,
    detailsCode: manifestation.productFormDetail?.[0],
    codelists,
  });

  const line1Fields = [
    ...titles,
    manifestation.isbn,
    manifestation.publishedYear,
  ].filter(f => !!f);

  const line2Fields = [manifestation.productGroup, productFormText].filter(
    f => !!f,
  );

  return {
    line1: line1Fields.join(' / '),
    line2: line2Fields.join(' / ') || undefined,
  };
};
