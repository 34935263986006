import React, {useCallback, useMemo} from 'react';
import Stack from '@mui/material/Stack';
import {FCWithChildren} from 'types';
import {BackgroundColor, ColorMode} from 'theme/types';
import {useBackgroundColor} from 'theme';
import {BaseProps, BoxProps, BoxStyleProps, ContainerChild} from '../types';
import {Layout, Scrollable} from '../layout';
import {Spacer} from '../spacer';
import {CardContentName} from './CardContent';
import {CardHeaderName} from './CardHeader';
import {CardNavigationHeaderName} from './CardNavigationHeader';

type Props = BaseProps & {
  flex?: BoxProps['flex'];
  color?: BackgroundColor;
  mode?: ColorMode;
  headerSpacing?: number;
  spacing?: number;
  /** Use "toggle" if you have use dynamic scrollable effect, to prevent re-render */
  scrollable?: boolean | 'toggle';
  fillHeight?: boolean;
  /** Temp property until color theme/palette decided */
  colorx?: string;
  sx?: BoxProps['sx'];
};

const ScrollableWithSpacing: FCWithChildren<
  Pick<Props, 'scrollable' | 'spacing'>
> = ({scrollable, spacing, children}) => {
  return scrollable === 'toggle' ? (
    <Scrollable>{children}</Scrollable>
  ) : scrollable && spacing ? (
    <Scrollable>
      <Stack spacing={spacing}>{children}</Stack>
    </Scrollable>
  ) : scrollable ? (
    <Scrollable>{children}</Scrollable>
  ) : spacing ? (
    <Stack spacing={spacing}>{children}</Stack>
  ) : (
    <>{children}</>
  );
};
export const Card: FCWithChildren<Props> = ({
  flex,
  color,
  mode,
  headerSpacing = 1,
  spacing,
  scrollable,
  fillHeight,
  colorx,
  sx,
  children,
  'data-cy': dataCy,
}) => {
  const themeColor = useBackgroundColor(color, mode);

  const components = useMemo(
    () =>
      (React.Children.toArray(children) as ContainerChild[]).filter(
        c => c.type?.displayName,
      ),
    [children],
  );

  const getComponentWithName = useCallback(
    (name: string): React.ReactNode | null => {
      const comps = components.filter(
        c => c && c.type && c.type.displayName === name,
      );
      return comps.length > 0 ? comps[0] : null;
    },
    [components],
  );

  const {
    sxOuter,
    sxInner,
    navigationHeader,
    header,
    // Spacing, Scrollable
  } = useMemo((): {
    sxOuter: BoxStyleProps;
    sxInner: BoxStyleProps;
    navigationHeader: React.ReactNode | null;
    header: React.ReactNode | null;
  } => {
    const resolvedColor = colorx ?? themeColor;
    const navigationHeader = getComponentWithName(CardNavigationHeaderName);
    const header = getComponentWithName(CardHeaderName);

    return {
      sxOuter: navigationHeader
        ? {
            background: resolvedColor,
            borderRadius: 2,
            overflow: 'hidden',
            flex,
            ...sx,
          }
        : {
            background: resolvedColor,
            padding: 2,
            borderRadius: 2,
            flex,
            ...sx,
          },
      sxInner: {padding: 2},
      navigationHeader,
      header,
    };
  }, [colorx, flex, getComponentWithName, sx, themeColor]);

  return components.length === 0 ? (
    <Layout data-cy={dataCy} sx={sxOuter}>
      {/* <Scrollable> */}
      {/* <Spacing> */}
      {children}
      {/* </Spacing> */}
      {/* </Scrollable> */}
    </Layout>
  ) : navigationHeader ? (
    <Layout data-cy={dataCy} sx={sxOuter} flex={fillHeight ? 1 : undefined}>
      {navigationHeader}
      <Layout sx={sxInner}>
        {header}
        {header ? <Spacer size={headerSpacing} /> : null}
        {/* <Scrollable> */}
        {/* <Spacing> */}
        {getComponentWithName(CardContentName)}
        {/* </Spacing> */}
        {/* </Scrollable> */}
      </Layout>
    </Layout>
  ) : (
    <Layout data-cy={dataCy} sx={sxOuter} flex={fillHeight ? 1 : undefined}>
      {header}
      {header ? <Spacer size={headerSpacing} /> : null}
      <ScrollableWithSpacing scrollable={scrollable} spacing={spacing}>
        {getComponentWithName(CardContentName)}
      </ScrollableWithSpacing>
    </Layout>
  );
};
