import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useLocalization} from 'localization';
import {Lock, UserDto} from 'api';
import {acquireLock, deleteLock, postLock} from 'api/lock';
import {useGetTokens, useUser} from 'services/auth';
import {useMetadataHasChanges} from 'services/data/metadata/hooks/useMetadataHasChanges';
import {useUsers} from 'services/users';
import {getFirstName} from 'services/users/functions';
import {useSnacks} from 'components/snacks';
import {LockProps, LockState} from '../contexts/types';
import {useCurrentLock} from './useCurrentLock';

const TEMP_LOCK_DURATION_MS = 30 * 1000;

const isLockedByOtherUser = (
  lock: Lock | undefined,
  user: UserDto | undefined | null,
) => {
  return !!lock && !!user && lock.lockedBy !== user.id;
};

const getCurrentLockUser = (
  lock: Lock | undefined,
  users: UserDto[] | undefined,
) => {
  if (!lock || !users) {
    return undefined;
  }
  return users.find(u => u.id === lock.lockedBy);
};

/**
 * States with actions:
 * - LockedByOther: initial if lock exists, locked by other user/session (readonly)
 *   - Every 5 seconds: reload lock, set state LockedByOther or NotLocked
 *   - Action: acquire lock, set state TemporarilyLocked
 * - NotLocked: initial if lock does not exist, not locked (editable)
 *   - Every 5 seconds: reload lock, set state LockedByOther or NotLocked
 *   - On changes: post lock, set state LockedByThis
 * - TemporarilyLocked: after acquiring lock (editable, locked by this)
 *   - After 5 seconds: set state NotLocked (no editing within 5 seconds)
 *   - On changes: set state LockedByThis
 * - LockedByThis: after posting lock or acquiring lock (editable, locked by this)
 *   - Every 5 seconds: reload lock, if acquired/deleted: set state LockedByOther or NotLocked
 *   - Save/cancel: delete lock, set state NotLocked
 *   - On close/leave: try delete lock
 * @param workId
 * @param mock
 * @returns
 */

export const useLockWorkOnChange = (
  workId: string,
  mock?: boolean,
): {
  readonly: boolean;
  lockProps: LockProps;
} => {
  const {t} = useLocalization();
  const user = useUser();
  const {
    resource: {data: users},
  } = useUsers();
  const [lockState, setLockState] = useState<LockState>(LockState.Initial);
  // const [lock, setLock] = useState<Lock | undefined>();
  const hasChanges = useMetadataHasChanges();
  /**
   * CurrentLock for work, updated every 5 sec
   */
  const currentLock = useCurrentLock(workId, mock);

  /**
   * CurrentLockRef, either from updates to currentLock or
   * from locking/acquring lock or releasing lock for this user
   */
  const currentLockRef = useRef(currentLock);

  /**
   * Keep track of lockId for lock set by this user
   */
  const byThisUserLockIdRef = useRef<string | undefined>();

  const getTokens = useGetTokens();
  const {warningSnack, errorSnack} = useSnacks();

  const handleSetLockByThisUser = useCallback(
    (thisUserLock: Lock | undefined, state: LockState) => {
      // setLock(thisUserLock);
      currentLockRef.current = thisUserLock
        ? {status: 'Loaded', data: thisUserLock}
        : {status: 'NotLoaded'};
      byThisUserLockIdRef.current = thisUserLock?.id;
      setLockState(state);
    },
    [],
  );

  const handleLock = useCallback(() => {
    postLock(workId, 'work', getTokens, mock)
      .then(lock => handleSetLockByThisUser(lock, LockState.LockedByThis))
      .catch(() => {
        // If lock fails, still allow editing with warning
        warningSnack(t('page.metadata.lock.message.lockFailedButEditable'));
        handleSetLockByThisUser(undefined, LockState.NotLocked);
      });
  }, [getTokens, handleSetLockByThisUser, mock, t, warningSnack, workId]);

  const handleReleaseLock = useCallback(() => {
    byThisUserLockIdRef.current &&
      deleteLock(byThisUserLockIdRef.current, getTokens, mock).catch(() => {});
    handleSetLockByThisUser(undefined, LockState.NotLocked);
  }, [getTokens, handleSetLockByThisUser, mock]);

  // When hasChanges changes:
  // - Initial,NotLocked: hasChanges: post lock, set state LockedByThis
  // - Acquired: hasChanges: set state LockedByThis
  // - LockedByThis: !hasChanges: delete lock, set state NotLocked
  const hasChangesRef = useRef<boolean>(false);
  useEffect(() => {
    // Only have effect when hasChanges changes
    if (hasChanges === hasChangesRef.current) {
      return;
    }
    hasChangesRef.current = hasChanges;

    switch (lockState) {
      case LockState.Initial:
      case LockState.NotLocked: {
        if (hasChanges && user) {
          handleLock();
        }
        break;
      }
      case LockState.TemporarilyLocked: {
        if (hasChanges) {
          handleSetLockByThisUser(
            currentLockRef.current.data,
            LockState.LockedByThis,
          );
        }
        break;
      }
      case LockState.LockedByThis: {
        if (!hasChanges) {
          handleReleaseLock();
        }
        break;
      }

      default:
        break;
    }
  }, [
    handleLock,
    handleReleaseLock,
    handleSetLockByThisUser,
    hasChanges,
    lockState,
    user,
  ]);

  // When currentLock changes
  // - Initial & isLocked & byOther=> LockedByOther
  // - Initial & isLocked & bySelf=> TemporarilyLocked (must confirm lock by editing)
  // - Initial & isNotLocked => NotLocked
  // - LockedByOther & isLocked & byOther => LockedByOther
  // - LockedByOther & isLocked & bySelf => TemporarilyLocked (sideeffect of acquiring lock in other tab)
  // - LockedByOther & isNotLocked => NotLocked
  // - NotLocked & isLocked & byOther => LockedByOther
  // - NotLocked & isLocked & bySelf => TemporarilyLocked (sideeffect of locking in other tab)
  // - NotLocked & isNotLocked => NotLocked
  // - TemporarilyLocked & isLocked & byOther => LockedByOther (snackbar warning)
  // - TemporarilyLocked & isLocked & bySelf => ignore (sideeffect of acquring lock)
  // - TemporarilyLocked & isNotLocked => NotLocked
  // - LockedByThis & isLocked & byOther => LockedByOther (snackbar warning)
  // - LockedByThis & isLocked & bySelf => ignore (sideeffect of locking)
  // - LockedByThis & isNotLocked => ignore (sideeffect of unlocking)
  useEffect(() => {
    if (
      currentLock === currentLockRef.current ||
      JSON.stringify(currentLock) === JSON.stringify(currentLockRef.current)
    ) {
      return;
    }
    currentLockRef.current = currentLock;

    const isLocked =
      currentLock.status === 'Loaded'
        ? true
        : currentLock.status === 'Failed' && currentLock.error.status === 404
        ? false
        : undefined;
    if (isLocked === undefined) {
      return;
    }

    const byOther = isLockedByOtherUser(currentLock.data, user);
    const lockUser = byOther
      ? getCurrentLockUser(currentLock.data, users)
      : undefined;
    const lockUserName = getFirstName(lockUser) ?? 'other user';

    switch (lockState) {
      case LockState.Initial:
      case LockState.LockedByOther:
      case LockState.NotLocked: {
        currentLockRef.current = currentLock;
        if (isLocked && !byOther) {
          byThisUserLockIdRef.current = currentLock.data?.id;
        }
        setLockState(
          isLocked
            ? byOther
              ? LockState.LockedByOther
              : LockState.TemporarilyLocked
            : LockState.NotLocked,
        );
        break;
      }
      case LockState.TemporarilyLocked: {
        const newLockState = isLocked
          ? byOther
            ? LockState.LockedByOther
            : LockState.TemporarilyLocked
          : LockState.NotLocked;
        handleSetLockByThisUser(currentLock.data, newLockState);

        if (isLocked && byOther) {
          warningSnack(
            t('page.metadata.lock.message.lockedByOtherUser', {
              name: lockUserName,
            }),
          );
        }
        break;
      }
      case LockState.LockedByThis: {
        handleSetLockByThisUser(currentLock.data, LockState.LockedByThis);
        if (isLocked && byOther) {
          setLockState(LockState.LockedByOther);
          warningSnack(
            t('page.metadata.lock.message.lockedByOtherUser', {
              name: lockUserName,
            }),
          );
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentLock,
    handleSetLockByThisUser,
    t,
    // lockState,
    user,
    users,
    warningSnack,
  ]);

  // When lockState changes:
  // - TemporarilyLocked: set timeout to release lock after 5 seconds
  // - Not TemporarilyLocked: clear timeout
  const releaseTempHandleRef = useRef<NodeJS.Timeout | undefined>();
  useEffect(() => {
    if (lockState === LockState.TemporarilyLocked) {
      releaseTempHandleRef.current = setTimeout(() => {
        setLockState(LockState.NotLocked);
        handleReleaseLock();
      }, TEMP_LOCK_DURATION_MS);
    } else if (releaseTempHandleRef.current) {
      clearTimeout(releaseTempHandleRef.current);
      releaseTempHandleRef.current = undefined;
    }

    return () => {
      if (releaseTempHandleRef.current) {
        clearTimeout(releaseTempHandleRef.current);
        releaseTempHandleRef.current = undefined;
      }
    };
  }, [handleReleaseLock, lockState]);

  const handleAcquireLock = useCallback((): Promise<void> => {
    if (
      currentLockRef.current.data &&
      user &&
      isLockedByOtherUser(currentLockRef.current.data, user)
    ) {
      return acquireLock(
        currentLockRef.current.data.id,
        user.id,
        getTokens,
        mock,
      )
        .then(lock =>
          handleSetLockByThisUser(lock, LockState.TemporarilyLocked),
        )
        .catch(() => {
          // If unlock fails, still allow editing with warning
          errorSnack(t('page.metadata.lock.message.lockFailedButEditable'));
        });
    }
    return Promise.resolve();
  }, [errorSnack, getTokens, handleSetLockByThisUser, mock, t, user]);

  useEffect(() => {
    const handleUnlock = () => {
      if (byThisUserLockIdRef.current) {
        const id = byThisUserLockIdRef.current;
        byThisUserLockIdRef.current = undefined;
        deleteLock(id, getTokens, mock).catch(() => {});
      }
    };

    window.onbeforeunload = handleUnlock;

    return () => {
      // Remove event listener on unmount
      window.removeEventListener('beforeunload', handleUnlock);
      // Unlock in case of just unmounting, e.g. when navigting
      handleUnlock();
    };
    // Only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => {
    const readonly = lockState === LockState.LockedByOther;
    const lock = currentLockRef.current.data;
    const lockProps: LockProps = {
      state: lockState,
      lock,
      byOtherUser: readonly
        ? getCurrentLockUser(lock, users) ?? {
            id: lock?.id ?? 'unknown',
            email: '',
            name: '',
          }
        : undefined,
      acquireLock: handleAcquireLock,
    };

    return {
      readonly,
      lockProps,
    };
  }, [handleAcquireLock, lockState, users]);
};
