import {assert} from 'assert-ts';
import {Code} from 'schemaDefinition/types';

export const getSelectedOptions = (
  codes: string | string[] | null | undefined,
  cardinality: 'single' | 'multiple',
  options: Code[],
  addMissing?: boolean,
): Code | Code[] | null => {
  if (codes === null || codes === undefined) return null;

  if (typeof codes === 'string') {
    assert(
      cardinality === 'single',
      'getSelectedOptions: single code must have cardinality single',
      {codes, cardinality},
    );
    return options.find(o => o.code === codes) ?? null;
  }

  assert(
    cardinality === 'multiple',
    'getSelectedOptions: code array must have cardinality multiple',
    {codes, cardinality},
  );

  return codes
    .map<Code | undefined>(
      code =>
        options.find(o => o.code === code) ??
        (!addMissing
          ? undefined
          : {
              code: code,
              value: code,
            }),
    )
    .filter(opt => !!opt) as Code[];
};
