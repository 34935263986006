import {GetTokens, NoteData} from './types';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpDelete, httpGet, httpPost, httpPut} from './http/ebba';
import {mapToPostPutCommentDto} from './mappers';
import {mapCommentDto} from './mappers/mapCommentDto';

export const getNotesForContext = (
  contextId: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<NoteData[]> => {
  return exceptionToPromiseReject(() =>
    httpGet<Schemas.CommentDto[]>({
      subdir: 'comment',
      queryParams: {contextId},
      getTokens,
      mock,
    }).then(dto => dto.map(mapCommentDto)),
  );
};

export const putNote = (
  note: NoteData,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<NoteData> => {
  return exceptionToPromiseReject(() =>
    httpPut<Schemas.CommentDto>({
      subdir: 'comment/{id}',
      subdirParams: {id: note.id},
      body: mapToPostPutCommentDto('PUT', note),
      getTokens,
      mock,
    }).then(mapCommentDto),
  );
};

export const postNote = (
  note: NoteData,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<NoteData> => {
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.CommentDto>({
      subdir: 'comment',
      body: mapToPostPutCommentDto('POST', note),
      getTokens,
      mock,
    }).then(mapCommentDto),
  );
};

export const deleteNote = (
  id: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<void> => {
  return exceptionToPromiseReject(() =>
    httpDelete({
      subdir: 'comment/{id}',
      subdirParams: {id},
      getTokens,
      mock,
    }),
  );
};
