import {MetadataEditState, MetadataEntityType} from '../types';

export const getHasChangedById = (
  id: string | number,
  {statuses, editStatuses}: MetadataEditState,
  type: MetadataEntityType,
) => {
  return !!(
    (type === 'manifestation' && statuses[id].changedStatus) ||
    editStatuses[id]?.hasDataChanged ||
    editStatuses[id]?.hasMoved
  );
};
