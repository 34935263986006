import {assert} from 'assert-ts';
import orderBy from 'lodash/orderBy';
import {Thesaurus} from 'api';

export const orderByThesaurusCategories = <T>(
  items: T[],
  thesaurus: Thesaurus,
  getCategoryId: (item: T) => string,
): T[] => {
  return orderBy(items, item => {
    const categoryId = getCategoryId(item);
    const categoryIdx = thesaurus.children?.findIndex(c => c.id === categoryId);
    assert.soft(
      categoryIdx !== undefined && categoryIdx >= 0,
      'orderByThesaurusCategories: Invalid category id',
      {item},
    );
    return categoryIdx;
  });
};
