import React, {PropsWithChildren} from 'react';
import {Box, SxProps} from '@mui/material';
import {ColorPalette} from 'theme';
import {Card, Icon, IconButton, Layout} from 'components';
import {IconName} from '../../../components/icons/types';

type Props = PropsWithChildren & {
  title: string;
  icon: IconName;
  onClose: () => void;
};

const headerSx: SxProps = {
  justifyContent: 'space-between',
};
export const ManifestationPanelContainer: React.FC<Props> = ({
  title,
  icon,
  onClose,
  children,
}) => {
  return (
    <Card colorx={ColorPalette.secondary.beige} fillHeight>
      <Card.Header sx={headerSx}>
        <Box>
          {title || icon ? (
            <Layout horizontal>
              {icon ? <Icon icon={icon} /> : null}
              {title ? <Box>{title}</Box> : null}
            </Layout>
          ) : null}
        </Box>
        <Box>
          <IconButton icon={'Close'} onClick={onClose} size={'small'} />
        </Box>
      </Card.Header>
      <Card.Content>{children}</Card.Content>
    </Card>
  );
};
