import {GridColumnVisibilityModel} from '@mui/x-data-grid-pro';
import {BooleanPartOption, ColumnConfig} from '../types';

export const mapToVisibilityModel = (
  columns: ColumnConfig[],
  hidden?: BooleanPartOption,
): GridColumnVisibilityModel => {
  const visibilityModel: GridColumnVisibilityModel = {};
  columns.forEach(c => {
    if (hidden && hidden[c.name]) {
      visibilityModel[c.name] = false;
    } else {
      visibilityModel[c.name] = c.visible || false;
    }
  });

  return visibilityModel;
};
