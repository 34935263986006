import {NestedKey} from 'types';
import {StatusColor} from './types';

const BaseColorPalette = {
  reject: '#AD4F49',
  approve: '#A5CE90',
  green: '#E1F9DD',
  green2: '#DBF5D7',
  burgundy: '#480905',
  burgundy50: '#A48482',
  burgundy75: '#764744',
  warmGreen: '#9A9D90',
  // warmGreen: '#C6C6C6',
  warmGreen06: '#9A9D9099',
  offWhite: '#FEFEFE',
  beige: '#EAEADF',
  beige50: '#EAEADF80',
  brown: '#CEC0B2',
  terracotta: '#B1928F',
  lightBeige: '#F9FAF8',
  enhancedBeige: '#F9FAF8',
  enhancedBrown: '#CCB9A8',
  enhancedGreen: '#D2E8C7',
  enhancedLightBeige: '#F2F4F0',
  info: '#7FB7D7',
  info60: '#B2D4E7',
  info40: '#D5EAF6',
  info20: '#EAF4FB',
  success: '#A5CE90',
  warning: '#FFBD12',
  warning80: '#FFCA41',
  warning60: '#FFD771',
  warning40: '#FFE5A0',
  warning20: '#FFF2D0',
  error: '#ef5350',
  error80: '#F27573',
  error60: '#F59896',
  error40: '#F9BAB9',
  error20: '#FCDDDC',
  textPrimary: '#212121',
  textSecondary: '#666761',
};

export type BaseColorName = keyof typeof BaseColorPalette;

const status: {[key in StatusColor]: string} = {
  new: '#CAE4EA',
  pre: '#FFCD9F',
  control: '#E1D7D1',
  done: '#D2E8C7',
  change: '#EFA794',
  mottak: '#EFA794',
  ml: BaseColorPalette.burgundy,
};

export const ColorPalette = {
  ...BaseColorPalette,
  primary: {
    green: BaseColorPalette.green,
    /** @deprecated */
    lightGreen: '#EAEADE',
    warmGreen: BaseColorPalette.warmGreen,
    warmGreen06: BaseColorPalette.warmGreen06,
    burgundy: BaseColorPalette.burgundy,
    white: BaseColorPalette.offWhite,
    text: BaseColorPalette.textPrimary,
  },
  secondary: {
    lightBeige: BaseColorPalette.lightBeige, // '#F9FAF8',
    /** @deprecated */
    lightBurgundy: '#B1928F',
    /** @deprecated */
    grell: '#9A9D90',
    brown: BaseColorPalette.brown,
    beige: BaseColorPalette.beige,
    /** @deprecated */
    dirtyLightGreen: '#F7F8F5',
    enhanced: {
      beige: BaseColorPalette.beige,
      brown: BaseColorPalette.brown,
      lightBeige: BaseColorPalette.lightBeige,
    },
    text: BaseColorPalette.textSecondary,
  },
  reject: BaseColorPalette.reject,
  approve: BaseColorPalette.approve,
  spot: {
    new: '#CAE4EA',
    new40: '#F1FFC3',
    pre: '#DCDCFE',
    change: '#FFD4CB',
    ml: '#d9d9d9',
    mottak: '#FFD4CB',
    urgent: '#FFEEB1',
    trykkunderlag: '#f1f1f1f1',
  },

  status: {
    ...status,
    /**
     * @deprecated
     */
    precat: status.pre,
  },
} as const;

export type ColorName = Exclude<
  NestedKey<typeof ColorPalette>,
  NestedKey<typeof BaseColorPalette>
>;
