import {assert} from 'assert-ts';
import {
  LinkedAgentMultiRole,
  PartLinkedAgent,
  UnnamedLinkedAgentMultiRole,
  UnverifiedLinkedAgentMultiRole,
  Valx,
  VerifiedLinkedAgentMultiRole,
} from 'schemaDefinition/types';
import {
  evaluateFieldRequired,
  getPartCodelistFromLinkedAgent,
} from 'schemaDefinition/functions';
import {getPartCodelistFromUnnamedAgentRole} from 'schemaDefinition/functions/getPartCodelistFromUnnamedAgentRole';
import {isLinkedValue} from 'schemaDefinition/functions/isLinkedValue';
import {ValidationArgs, ValidationResult} from './types';
import {fail} from './fail';
import {validResult} from './validResult';
import {validateCodeList} from './validateCodeList';
import {validateConditions} from './validateConditions';
import {validateValueType} from './validateValueType';
import {warning} from './warning';

export const validateLinkedAgent = (
  part: PartLinkedAgent<Valx>,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(
    part,
    validateSingleLinkedAgentValue,
    args,
    aggregateResult,
  );
};

const validateSingleLinkedAgentValue = (
  part: PartLinkedAgent<Valx>,
  args: ValidationArgs,
  aggregateResult: ValidationResult,
): ValidationResult => {
  if (args.value === undefined || args.value === null) {
    const required = evaluateFieldRequired(
      part.required,
      args.valuePath,
      args.localScope,
      args.globalScope,
      args.relatedScope,
      args.value,
    );
    return required
      ? (required === true ? fail : warning)(
          {
            ...args,
            part,
            validation: 'required',
            messageKey: 'required',
          },
          aggregateResult,
        )
      : aggregateResult;
  }

  if (!isLinkedValue(args.value)) {
    return fail(
      {
        ...args,
        part,
        validation: 'invalid type',
        messageKey: 'invalid type',
      },
      aggregateResult,
    );
  }

  const linkAggregateResult = validateSingleLinkedAgentLink(
    part,
    args,
    aggregateResult,
  );
  const rolesAggregateResult = validateSingleLinkedAgentRoles(
    part,
    args,
    linkAggregateResult,
  );

  return (args.value as UnnamedLinkedAgentMultiRole).link.linkStatus ===
    'unnamed'
    ? validateSingleUnnamedAgentRole(part, args, rolesAggregateResult)
    : rolesAggregateResult;
};

export const validateSingleLinkedAgentLink = (
  part: PartLinkedAgent<Valx>,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  const verified = args.value as VerifiedLinkedAgentMultiRole;
  const unverified = args.value as UnverifiedLinkedAgentMultiRole;

  const required = evaluateFieldRequired(
    part.required,
    args.valuePath,
    args.localScope,
    args.globalScope,
    args.relatedScope,
    args.value,
  );

  if (required && !(verified?.link?.agentName || unverified?.link?.name)) {
    return (required === true ? fail : warning)(
      {
        ...args,
        part,
        validation: 'required',
        messageKey: 'required',
      },
      aggregateResult,
    );
  }

  if (part.validation) {
    return validateConditions(part.validation, part, args, aggregateResult);
  }

  return aggregateResult;
};

export const validateSingleLinkedAgentRoles = (
  part: PartLinkedAgent<Valx>,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  if (part.entityRole) return aggregateResult;

  const rolesPart = assert(
    getPartCodelistFromLinkedAgent(part),
    'validateSingleLinkedValueRoles: codelistId expected',
    part,
  );
  const rolesValue = args.value
    ? (args.value as LinkedAgentMultiRole).roles
    : undefined;
  const rolesPath = args.valuePath + '.roles';
  return validateCodeList(
    rolesPart,
    {
      ...args,
      value: rolesValue,
      valuePath: rolesPath,
    },
    aggregateResult,
  );
};

export const validateSingleUnnamedAgentRole = (
  part: PartLinkedAgent<Valx>,
  args: ValidationArgs,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  if (part.entityRole) return aggregateResult;

  const rolePart = getPartCodelistFromUnnamedAgentRole();

  const roleValue = args.value
    ? (args.value as UnnamedLinkedAgentMultiRole).unnamedAgentRole
    : undefined;
  const rolePath = args.valuePath + '.unnamedAgentRole';
  return validateCodeList(
    rolePart,
    {
      ...args,
      value: roleValue,
      valuePath: rolePath,
    },
    aggregateResult,
  );
};
