import {assert} from 'assert-ts';
import {
  Concept,
  EntityMainType,
  EntityMainTypes,
  EntitySubType,
  EntitySubTypes,
} from 'types';

export const getMainType = (type: Concept): EntityMainType => {
  const mainType: EntityMainType | undefined = (
    EntityMainTypes.includes(type as EntityMainType)
      ? type
      : Object.keys(EntitySubTypes).find(mainType =>
          (EntitySubTypes[mainType as EntityMainType] ?? []).includes(
            type as EntitySubType,
          ),
        )
  ) as EntityMainType | undefined;

  return assert(mainType, 'getMainType: Could not find main type', {type});
};
