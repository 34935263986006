import {useCallback, useState} from 'react';
import {noOp} from 'services/utils/functions';
import {AlertDialog, AlertDialogProps} from '../AlertDialog';

const createDialog = (
  isOpen: boolean,
  props: Omit<AlertDialogProps, 'isOpen' | 'onCancel' | 'onConfirm'>,
  onCancel: () => void = noOp,
  onConfirm = () => Promise.resolve(),
) => (
  <AlertDialog
    isOpen={isOpen}
    onCancel={onCancel}
    onConfirm={onConfirm}
    {...props}
  />
);

export const useAlertDialog = ({
  onCancel,
  onConfirm,
  ...props
}: Omit<AlertDialogProps, 'isOpen'>): {
  pleaseConfirm: () => Promise<void>;
  AlertDialog: React.FC;
} => {
  const [dialogInstance, setDialogInstance] = useState<React.ReactElement>(
    createDialog(false, props),
  );

  const pleaseConfirm = useCallback(() => {
    return new Promise<void>(resolve => {
      const cancel = () => {
        onCancel();
        setDialogInstance(createDialog(false, props));
        resolve();
      };

      const confirm = () => {
        setDialogInstance(createDialog(false, props));
        resolve();
        return onConfirm();
      };

      setDialogInstance(createDialog(true, props, cancel, confirm));
    });
  }, [onCancel, onConfirm, props]);

  return {
    pleaseConfirm,
    AlertDialog: () => dialogInstance,
  };
};
