import assert from 'assert-ts';
import {Dispatch} from 'redux';
import {AppState} from 'store/types';
import {CodeList, CodeListId, CombinedCodeListId} from 'api';
import {makeLoadByIdActionFactories} from 'services/utils/redux/functions/makeLoadByIdActionFactories';
import {CodeListActionType, CodeListsAction} from '../types';

const {makeSuccessAction} = makeLoadByIdActionFactories<
  CodeList,
  CodeListId,
  CodeListActionType.LOAD_CODELIST
>(CodeListActionType.LOAD_CODELIST);

export const combineCodeListAction = (
  combinedId: CombinedCodeListId,
  partCodelistIds: CodeListId[],
) => {
  return (dispatch: Dispatch<CodeListsAction>, getState: () => AppState) => {
    const partCodeLists = partCodelistIds.map(id =>
      assert(
        getState().codeLists[id]?.data,
        `combinedCodeListAction: ${id}.data not found`,
        {combinedId, partCodelistIds},
      ),
    );

    const combinedCodeList = partCodeLists.reduce(
      (acc, partCodeList) => {
        return {
          ...acc,
          codes: [...acc.codes, ...partCodeList.codes],
        };
      },
      {
        id: combinedId,
        language: partCodeLists[0].language,
        codes: [],
      } as CodeList,
    );

    dispatch(makeSuccessAction(combinedId, combinedCodeList));
  };
};
