import {Concept, EntitySubType, LinkTargetConcreteEntityType} from 'types';
import {SearchMainEntityType, SearchResultType} from '../types';

export enum EntitySearchState {
  viewEntity = 'viewEntity',
  /**
   * Searching for entities and showing search result matching current search query and entity type
   */
  search = 'search',
  /**
   * Browsing search result, i.e. showing search result item with prev/next buttons
   */
  browse = 'browse',
  /**
   * Nested navigation from search result item to another entity
   */
  navigate = 'navigate',
}

export const DefaultMainEntity = Concept.work as const;

type CoreSearchState_Search = {
  searchQuery: string;
  selectedMainEntity: SearchMainEntityType;
  selectedSubEntity?: EntitySubType;
};

type CoreSearchState_Browse = {
  currentEntityIdx: number;
};

type NavigationItem = {
  title: string;
  entityId: string | number;
  entityType: SearchResultType;
  state: EntitySearchState;
  /**
   * List of sibling ids, i.e. that user may navigate between using prev/next buttons
   * Only applicable when navigating entities in a homogen list, e.g.
   * works in a series, works by an author, etc.
   */
  siblingIds?: (string | number)[];
  siblingLabel?: string;
};
type CoreSearchState_Navigate = {
  viewEntity: EntityIdOnly;
  navigationEntityType: LinkTargetConcreteEntityType;
  navigationHistory: NavigationItem[];
};

type CoreSearchState_ViewEntity = {
  viewEntity: EntityIdOnly;
};

export type CoreSearchState =
  | ({
      state: EntitySearchState.search;
    } & CoreSearchState_Search &
      Partial<CoreSearchState_Browse> &
      Partial<CoreSearchState_Navigate> &
      Partial<CoreSearchState_ViewEntity>)
  | ({
      state: EntitySearchState.browse;
    } & CoreSearchState_Search &
      CoreSearchState_Browse &
      Partial<CoreSearchState_Navigate> &
      Partial<CoreSearchState_ViewEntity>)
  | ({
      state: EntitySearchState.navigate;
    } & CoreSearchState_Search &
      CoreSearchState_Navigate &
      Partial<CoreSearchState_Browse> &
      Partial<CoreSearchState_ViewEntity>)
  | ({
      state: EntitySearchState.viewEntity;
    } & CoreSearchState_Search &
      CoreSearchState_ViewEntity &
      Partial<CoreSearchState_Browse> &
      Partial<CoreSearchState_Navigate>);

export type EntityIdOnly = {
  id?: string | number | undefined;
};
