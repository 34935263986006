import {PartValueRenderer} from 'schema/types';
import {Product} from 'api';
import {Builder, isNullish} from 'services/utils';
import {FlexBox} from 'components';
import {useTextValue} from '../../schema/preview/hooks';

const formatProduct = (product: Product) => {
  return new Builder(product.owner).add(': ', product.distributor).value;
};

export const Products: PartValueRenderer = ({value}) => {
  const TextValue = useTextValue();

  return isNullish(value) ||
    !Array.isArray(value) ||
    value.length === 0 ? null : (
    <FlexBox>
      {value
        .map(v => formatProduct(v as Product))
        .filter(v => v)
        .map(v => (
          <TextValue key={v}>{v}</TextValue>
        ))}
    </FlexBox>
  );
};
