import {useRef} from 'react';

const logwhenSame = false;

export const useLogValue = (name: string, value: unknown) => {
  const prevValueRef = useRef(value);
  const isInitialRef = useRef(true);

  const valueJson = JSON.stringify(value);
  const prevValueJson = JSON.stringify(prevValueRef.current);

  if (prevValueRef.current !== value) {
    if (prevValueJson === valueJson && !isInitialRef.current) {
      console.warn(
        `Changed: ${name} - unnecessarily`,
        value,
        ', prev',
        prevValueRef.current,
      );
    } else {
      console.log(`Changed: ${name}`, value, ', prev', prevValueRef.current);
    }
  } else if (prevValueJson !== valueJson) {
    console.error(
      `Changed: ${name} - MUTATED`,
      value,
      ', prev',
      prevValueRef.current,
    );
  } else if (logwhenSame) {
    console.log(`Unchanged: ${name}`, value, ', prev', prevValueRef.current);
  }

  prevValueRef.current = value;
  isInitialRef.current = false;
};
