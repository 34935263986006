export const findURIParamPlaceholders = (subdir: string): string[] => {
  const match = subdir.matchAll(/(?:[^{]*\{([^}]*)\})/g);

  const placeholders: string[] = [];
  let next = match.next();
  while (!next.done) {
    placeholders.push(next.value[1]);
    next = match.next();
  }

  return placeholders;
};
