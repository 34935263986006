import {Concept} from 'types';
import {ValidationResult} from 'schema/form/functions/validators/types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {ManifestationStatus} from 'api';
import {Dot, Layout, Spacer, Text} from 'components';
import {getSchemaPartLabel} from '../functions';
import {ExpressionValidationErrorHeader} from './ExpressionValidationErrorHeader';
import {ManifestationValidationErrorHeader} from './ManifestationValidationErrorHeader';
import {WorkValidationErrorHeader} from './WorkValidationErrorHeader';

type Props = {
  entity: Concept.work | Concept.expression | Concept.manifestation;
  entityId: string;
  status: ManifestationStatus | undefined;
  validation: ValidationResult;
};

const excludeErrorKeys = ['required'];

export const EntityValidationErrors: React.FC<Props> = ({
  entity,
  entityId,
  status,
  validation,
}) => {
  const localization = useLocalization();

  return (
    <Layout>
      {entity === Concept.expression ? (
        <ExpressionValidationErrorHeader
          entityId={entityId}
          status={status}
          validation={validation}
        />
      ) : entity === Concept.manifestation ? (
        <ManifestationValidationErrorHeader
          entityId={entityId}
          status={status}
          validation={validation}
        />
      ) : (
        <WorkValidationErrorHeader status={status} />
      )}
      <Spacer size={0.5} />
      {validation.errors.map((error, idx) => {
        const message =
          error.messageKey && !excludeErrorKeys.includes(error.messageKey)
            ? localization.tLoose(`form.errors.${error.messageKey}`)
            : undefined;
        return (
          <Layout key={idx}>
            <Layout horizontal adjustCenter adjustLeft ml={1}>
              <Dot color={ColorPalette[error.level]} />
              <Spacer size={0.5} />
              <Text variant="body2">
                {getSchemaPartLabel(entity, error.part, localization) +
                  (message ? ':' : '')}
              </Text>
            </Layout>
            {message ? (
              <Layout horizontal adjustCenter adjustLeft ml={1}>
                <Spacer size={1.2} />
                <Text variant="body2">{message}</Text>
              </Layout>
            ) : null}
          </Layout>
        );
      })}
      <Spacer size={1.5} />
    </Layout>
  );
};
