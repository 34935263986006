import dayjs from 'dayjs';
import {DateTimeValue, Variant} from './types';

export const formatYear = (
  date?: DateTimeValue,
  variant: Variant = 'long',
): string => {
  if (typeof date === 'number' && Math.abs(date) < 4000) {
    return date.toString();
  }

  const year = dayjs(date).year().toString();
  return variant === 'long' ? year : year.substring(2);
};
