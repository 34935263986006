import {ManifestationV4, WorkWithExpressions} from 'api';

export const findManifestation = (
  work: WorkWithExpressions,
  manifestationId: string | undefined,
): ManifestationV4 | undefined => {
  const manifestation = manifestationId
    ? (work.expressions ?? [])
        .map(e => e.manifestations)
        .flat()
        .find(m => m.id === manifestationId)
    : undefined;
  return manifestation;
};
