import {produce} from 'immer';
import set from 'lodash/set';
import {Data, DataValue} from 'schemaDefinition/types';
import {isEmptyValue} from 'services/utils/isEmptyValue';

export const replaceOriginalValue = <T extends Data>(
  entity: T,
  localPath: string,
  proposedValue: DataValue,
): T => {
  return produce(entity, draft => {
    // TODO: Consider check if we are setting empty value for array item, then delete instead
    set(draft, localPath, isEmptyValue(proposedValue) ? null : proposedValue);
  });
};
