import {ManifestationStatus} from 'api/types';
import {EntityWithStatusKey} from 'services/data/metadata/types';
import {getHighestStatus} from './getHighestStatus';

export const getAggregateStatusFromList = <TKey extends string>(
  statuses: EntityWithStatusKey<TKey>[],
  statusKey: TKey,
): ManifestationStatus | undefined => {
  return statuses.reduce<ManifestationStatus | undefined>((acc, s) => {
    const itemStatus = s[statusKey];
    return getHighestStatus(acc, itemStatus) ?? acc;
  }, undefined);
};
