import {useCallback, useMemo, useState} from 'react';
import {assert} from 'assert-ts';
import {Concept} from 'types';
import {Agent, NationalAgentType} from 'api/types';
import {
  DeleteAgentFunc,
  SaveAgentFunc,
  removeMentionedIn,
} from 'services/data/agent';
import {clone} from 'services/utils';
import {EditAgentInitialValue} from '../types';
import {EditAgentWizardDialog} from '../components';

/**
 * Takes a save function, and returns functions to edit and add an agent, with a
 * corresponding edit dialog component.
 * @param onSave function to save new or edited agent
 * @param onDelete function to delete agent
 * @returns
 */
export const useEditAgentWizardDialog = (
  onSave: SaveAgentFunc,
  onDelete: DeleteAgentFunc,
) => {
  const [initialValue, setInitialValue] = useState<EditAgentInitialValue>({
    agentType: Concept.person,
  } as EditAgentInitialValue);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleAddAgentWizard = useCallback(
    (type: NationalAgentType, suggestion: string) => {
      setInitialValue({agentType: type, suggestion});
      setDialogOpen(true);
    },
    [],
  );

  const handleEditAgentWizard = useCallback((agent: Agent) => {
    assert(
      agent.agentType === Concept.person ||
        agent.agentType === Concept.corporation ||
        agent.agentType === Concept.event,
      'handleEditAgentWizard: type must be PERSON, CORPORATION or EVENT',
    );
    const trimmedAgent = clone(agent);
    removeMentionedIn(trimmedAgent);
    setInitialValue({originalAgent: trimmedAgent});
    setDialogOpen(true);
  }, []);

  const handleSaveAgent = useCallback<SaveAgentFunc>(
    (agent, option) => {
      return onSave(agent, option).then(savedAgent => {
        setDialogOpen(false);
        return savedAgent;
      });
    },
    [onSave],
  );

  const handleDeleteAgent = useCallback<DeleteAgentFunc>(
    agent => {
      return onDelete(agent).then(() => {
        setDialogOpen(false);
      });
    },
    [onDelete],
  );

  const Wizard = useCallback(() => {
    return (
      <EditAgentWizardDialog
        {...initialValue}
        isOpen={dialogOpen}
        onCancel={() => setDialogOpen(false)}
        onSave={handleSaveAgent}
        onDelete={handleDeleteAgent}
      />
    );
  }, [initialValue, dialogOpen, handleSaveAgent, handleDeleteAgent]);

  return useMemo(() => {
    return {
      handleAddAgentWizard,
      handleEditAgentWizard,
      EditAgentWizard: Wizard,
    };
  }, [Wizard, handleAddAgentWizard, handleEditAgentWizard]);
};
