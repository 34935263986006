import {Code} from 'api/types';

export const onix_150: Code[] = [
  {code: '00', value: 'Undefined', notes: ''},
  {code: 'AA', value: 'Audio', notes: 'Audio recording – detail unspecified'},
  {code: 'AB', value: 'Audio cassette', notes: 'Audio cassette (analogue)'},
  {
    code: 'AC',
    value: 'CD-Audio',
    notes:
      'Audio compact disc: use for ‘Red book’ discs (conventional audio CD) and SACD, and use coding in &lt;ProductFormDetail&gt; to specify the format, if required',
  },
  {code: 'AD', value: 'DAT', notes: 'Digital audio tape cassette'},
  {
    code: 'AE',
    value: 'Audio disc',
    notes:
      'Audio disc (excluding CD-Audio): use for ‘Yellow book’ (CD-Rom-style) discs, including for example mp3 CDs, and use coding in &lt;ProductFormDetail&gt; to specify the format of the data on the disc',
  },
  {
    code: 'AF',
    value: 'Audio tape',
    notes: 'Audio tape (analogue open reel tape)',
  },
  {code: 'AG', value: 'MiniDisc', notes: 'Sony MiniDisc format'},
  {
    code: 'AH',
    value: 'CD-Extra',
    notes:
      'Audio compact disc with part CD-ROM content, also termed CD-Plus or Enhanced-CD: use for ‘Blue book’ and ‘Yellow/Red book’ two-session discs',
  },
  {code: 'AI', value: 'DVD Audio', notes: ''},
  {
    code: 'AJ',
    value: 'Downloadable audio file',
    notes:
      'Digital audio recording downloadable to the purchaser’s own device(s)',
  },
  {
    code: 'AK',
    value: 'Pre-recorded digital audio player',
    notes:
      'For example, Playaway audiobook and player: use coding in &lt;ProductFormDetail&gt; to specify the recording format, if required',
  },
  {
    code: 'AL',
    value: 'Pre-recorded SD card',
    notes: 'For example, Audiofy audiobook chip',
  },
  {code: 'AM', value: 'LP', notes: 'Vinyl disc (analogue).'},
  {
    code: 'AN',
    value: 'Downloadable and online audio file',
    notes:
      'Digital audio recording available both by download to the purchaser’s own device(s) and by online (eg streamed) access',
  },
  {
    code: 'AO',
    value: 'Online audio file',
    notes:
      'Digital audio recording available online (eg streamed), not downloadable to the purchaser’s own device(s)',
  },
  {
    code: 'AZ',
    value: 'Other audio format',
    notes:
      'Other audio format not specified by AB to AM. Further detail is expected in &lt;ProductFormDescription&gt;, as &lt;ProductFormDetail&gt; and &lt;ProductFormFeature&gt; are unlikely to be sufficient',
  },
  {code: 'BA', value: 'Book', notes: 'Book – detail unspecified'},
  {code: 'BB', value: 'Hardback', notes: 'Hardback or cased book'},
  {
    code: 'BC',
    value: 'Paperback / softback',
    notes: 'Paperback or other softback book',
  },
  {code: 'BD', value: 'Loose-leaf', notes: 'Loose-leaf book'},
  {code: 'BE', value: 'Spiral bound', notes: 'Spiral, comb or coil bound book'},
  {
    code: 'BF',
    value: 'Pamphlet',
    notes:
      'Pamphlet, stapled; use for German ‘geheftet’. Includes low-extent wire-stitched books bound without a distinct spine (eg many comic books)',
  },
  {
    code: 'BG',
    value: 'Leather / fine binding',
    notes: 'Use &lt;ProductFormDetail&gt; to provide additional description',
  },
  {
    code: 'BH',
    value: 'Board book',
    notes: 'Child’s book with all pages printed on board',
  },
  {
    code: 'BI',
    value: 'Rag book',
    notes: 'Child’s book with all pages printed on textile',
  },
  {
    code: 'BJ',
    value: 'Bath book',
    notes: 'Child’s book printed on waterproof material',
  },
  {
    code: 'BK',
    value: 'Novelty book',
    notes:
      'A book whose novelty consists wholly or partly in a format which cannot be described by any other available code – a ‘conventional’ format code is always to be preferred; one or more Product Form Detail codes, eg from the B2nn group, should be used whenever possible to provide additional description',
  },
  {code: 'BL', value: 'Slide bound', notes: 'Slide bound book'},
  {
    code: 'BM',
    value: 'Big book',
    notes:
      'Extra-large format for teaching etc; this format and terminology may be specifically UK; required as a top-level differentiator',
  },
  {
    code: 'BN',
    value: 'Part-work (fascículo)',
    notes:
      'A part-work issued with its own ISBN and intended to be collected and bound into a complete book.',
  },
  {
    code: 'BO',
    value: 'Fold-out book or chart',
    notes:
      'Concertina-folded booklet or chart, designed to fold to pocket or regular page size, and usually bound within distinct board or card covers: use for German ‘Leporello’',
  },
  {
    code: 'BP',
    value: 'Foam book',
    notes: 'A children’s book whose cover and pages are made of foam',
  },
  {
    code: 'BZ',
    value: 'Other book format',
    notes:
      'Other book format or binding not specified by BB to BP. Further detail is expected in &lt;ProductFormDescription&gt;, as &lt;ProductFormDetail&gt; and &lt;ProductFormFeature&gt; are unlikely to be sufficient',
  },
  {code: 'CA', value: 'Sheet map', notes: 'Sheet map – detail unspecified'},
  {code: 'CB', value: 'Sheet map, folded', notes: ''},
  {code: 'CC', value: 'Sheet map, flat', notes: ''},
  {
    code: 'CD',
    value: 'Sheet map, rolled',
    notes: 'See &lt;ProductPackaging&gt; and Codelist 80 for ‘rolled in tube’',
  },
  {code: 'CE', value: 'Globe', notes: 'Globe or planisphere'},
  {
    code: 'CZ',
    value: 'Other cartographic',
    notes:
      'Other cartographic format not specified by CB to CE. Further detail is expected in &lt;ProductFormDescription&gt;, as &lt;ProductFormDetail&gt; and &lt;ProductFormFeature&gt; are unlikely to be sufficient',
  },
  {
    code: 'DA',
    value: 'Digital (on physical carrier)',
    notes:
      'Digital content delivered on a physical carrier (detail unspecified)',
  },
  {code: 'DB', value: 'CD-ROM', notes: ''},
  {
    code: 'DC',
    value: 'CD-I',
    notes: 'CD interactive: use for ‘Green book’ discs',
  },
  {code: 'DE', value: 'Game cartridge', notes: ''},
  {code: 'DF', value: 'Diskette', notes: 'AKA ‘floppy disc’'},
  {code: 'DI', value: 'DVD-ROM', notes: ''},
  {code: 'DJ', value: 'Secure Digital (SD) Memory Card', notes: ''},
  {code: 'DK', value: 'Compact Flash Memory Card', notes: ''},
  {code: 'DL', value: 'Memory Stick Memory Card', notes: ''},
  {code: 'DM', value: 'USB Flash Drive', notes: ''},
  {
    code: 'DN',
    value: 'Double-sided CD/DVD',
    notes: 'Double-sided disc, one side Audio CD/CD-ROM, other side DVD',
  },
  {code: 'DO', value: 'BR-ROM', notes: '(Blu Ray ROM)'},
  {
    code: 'DZ',
    value: 'Other digital carrier',
    notes:
      'Other carrier of digital content not specified by DB to DO. Further detail is expected in &lt;ProductFormDescription&gt;, as &lt;ProductFormDetail&gt; and &lt;ProductFormFeature&gt; are unlikely to be sufficient',
  },
  {
    code: 'EA',
    value: 'Digital (delivered electronically)',
    notes:
      'Digital content delivered electronically (delivery method unspecified)',
  },
  {
    code: 'EB',
    value: 'Digital download and online',
    notes: 'Digital content available both by download and by online access',
  },
  {
    code: 'EC',
    value: 'Digital online',
    notes: 'Digital content accessed online only',
  },
  {
    code: 'ED',
    value: 'Digital download',
    notes: 'Digital content delivered by download only',
  },
  {
    code: 'FA',
    value: 'Film or transparency',
    notes: 'Film or transparency – detail unspecified',
  },
  {
    code: 'FC',
    value: 'Slides',
    notes: 'Photographic transparencies mounted for projection',
  },
  {
    code: 'FD',
    value: 'OHP transparencies',
    notes: 'Transparencies for overhead projector',
  },
  {
    code: 'FE',
    value: 'Filmstrip',
    notes:
      'Photographic transparencies, unmounted but cut into short multi-frame strips',
  },
  {
    code: 'FF',
    value: 'Film',
    notes: 'Continuous movie film as opposed to filmstrip',
  },
  {
    code: 'FZ',
    value: 'Other film or transparency format',
    notes:
      'Other film or transparency format not specified by FB to FF. Further detail is expected in &lt;ProductFormDescription&gt;, as &lt;ProductFormDetail&gt; and &lt;ProductFormFeature&gt; are unlikely to be sufficient',
  },
  {
    code: 'LA',
    value: 'Digital product license',
    notes: 'Digital product license (delivery method not encoded)',
  },
  {
    code: 'LB',
    value: 'Digital product license key',
    notes:
      'Digital product license delivered through the retail supply chain as a physical ‘key’, typically a card or booklet containing a code enabling the purchaser to download the associated product',
  },
  {
    code: 'LC',
    value: 'Digital product license code',
    notes:
      'Digital product license delivered by email or other electronic distribution, typically providing a code enabling the purchaser to activate, upgrade or extend the license supplied with the associated product',
  },
  {code: 'MA', value: 'Microform', notes: 'Microform – detail unspecified'},
  {code: 'MB', value: 'Microfiche', notes: ''},
  {code: 'MC', value: 'Microfilm', notes: 'Roll microfilm'},
  {
    code: 'MZ',
    value: 'Other microform',
    notes:
      'Other microform not specified by MB or MC. Further detail is expected in &lt;ProductFormDescription&gt;, as &lt;ProductFormDetail&gt; and &lt;ProductFormFeature&gt; are unlikely to be sufficient',
  },
  {
    code: 'PA',
    value: 'Miscellaneous print',
    notes: 'Miscellaneous printed material – detail unspecified',
  },
  {
    code: 'PB',
    value: 'Address book',
    notes:
      'May use &lt;ProductFormDetail&gt; codes P201 to P204 to specify binding',
  },
  {code: 'PC', value: 'Calendar', notes: ''},
  {
    code: 'PD',
    value: 'Cards',
    notes:
      'Cards, flash cards (eg for teaching reading), revision cards, divination, playing or trading cards',
  },
  {
    code: 'PE',
    value: 'Copymasters',
    notes: 'Copymasters, photocopiable sheets',
  },
  {
    code: 'PF',
    value: 'Diary or journal',
    notes:
      'May use &lt;ProductFormDetail&gt; codes P201 to P204 to specify binding',
  },
  {
    code: 'PG',
    value: 'Frieze',
    notes:
      'Narrow strip-shaped printed sheet used mostly for education or children’s products (eg depicting alphabet, number line, procession of illustrated characters etc). Usually intended for horizontal display',
  },
  {
    code: 'PH',
    value: 'Kit',
    notes:
      'Parts for post-purchase assembly, including card, wood or plastic parts or model components, interlocking construction blocks, beads and other crafting materials etc',
  },
  {
    code: 'PI',
    value: 'Sheet music',
    notes:
      'May use &lt;ProductFormDetail&gt; codes P201 to P204 to specify binding',
  },
  {
    code: 'PJ',
    value: 'Postcard book or pack',
    notes:
      'Including greeting cards and packs. For bound books (usually with perforated sheets to remove cards), may use &lt;ProductFormDetail&gt; codes P201 to P204 to specify binding',
  },
  {code: 'PK', value: 'Poster', notes: 'Poster for retail sale – see also XF'},
  {
    code: 'PL',
    value: 'Record book',
    notes:
      'Record book (eg ‘birthday book’, ‘baby book’): binding unspecified; may use &lt;ProductFormDetail&gt; codes P201 to P204 to specify binding',
  },
  {
    code: 'PM',
    value: 'Wallet or folder',
    notes:
      'Wallet, folder or box (containing loose sheets etc, or empty): it is preferable to code the contents and treat ‘wallet’ (or folder / box) as packaging in &lt;ProductPackaging&gt; with Codelist 80, but if this is not possible (eg where the product is empty and intended for storing other loose items) the product as a whole may be coded as a ‘wallet’. For binders intended for loose leaf or partwork publications intended to be updateable, see codes BD, BN',
  },
  {code: 'PN', value: 'Pictures or photographs', notes: ''},
  {code: 'PO', value: 'Wallchart', notes: ''},
  {code: 'PP', value: 'Stickers', notes: ''},
  {
    code: 'PQ',
    value: 'Plate (lámina)',
    notes:
      'A book-sized (as opposed to poster-sized) sheet, usually in color or high quality print',
  },
  {
    code: 'PR',
    value: 'Notebook / blank book',
    notes:
      'A book with all pages blank for the buyer’s own use; may use &lt;ProductFormDetail&gt; codes P201 to P204 to specify binding',
  },
  {
    code: 'PS',
    value: 'Organizer',
    notes:
      'May use &lt;ProductFormDetail&gt; codes P201 to P204 to specify binding',
  },
  {code: 'PT', value: 'Bookmark', notes: ''},
  {code: 'PU', value: 'Leaflet', notes: 'Folded but unbound'},
  {code: 'PV', value: 'Book plates', notes: 'Ex libris’ book labels and packs'},
  {
    code: 'PZ',
    value: 'Other printed item',
    notes:
      'Other printed item not specified by PB to PQ. Further detail is expected in &lt;ProductFormDescription&gt;, as &lt;ProductFormDetail&gt; and &lt;ProductFormFeature&gt; are unlikely to be sufficient',
  },
  {
    code: 'SA',
    value: 'Multiple-component retail product',
    notes:
      'Presentation unspecified: format of product components must be given in &lt;ProductPart&gt;',
  },
  {
    code: 'SB',
    value: 'Multiple-component retail product, boxed',
    notes: 'Format of product components must be given in &lt;ProductPart&gt;',
  },
  {
    code: 'SC',
    value: 'Multiple-component retail product, slip-cased',
    notes: 'Format of product components must be given in &lt;ProductPart&gt;',
  },
  {
    code: 'SD',
    value: 'Multiple-component retail product, shrink-wrapped',
    notes:
      'Format of product components must be given in &lt;ProductPart&gt;. Use code XL for a shrink-wrapped pack for trade supply, where the retail items it contains are intended for sale individually',
  },
  {
    code: 'SE',
    value: 'Multiple-component retail product, loose',
    notes: 'Format of product components must be given in &lt;ProductPart&gt;',
  },
  {
    code: 'SF',
    value: 'Multiple-component retail product, part(s) enclosed',
    notes:
      'Multiple component product where subsidiary product part(s) is/are supplied as enclosures to the primary part, eg a book with a CD packaged in a sleeve glued within the back cover. Format of product components must be given in &lt;ProductPart&gt;',
  },
  {code: 'VA', value: 'Video', notes: 'Video – detail unspecified'},
  {code: 'VF', value: 'Videodisc', notes: 'eg Laserdisc'},
  {
    code: 'VI',
    value: 'DVD video',
    notes: 'DVD video: specify TV standard in &lt;ProductFormDetail&gt;',
  },
  {
    code: 'VJ',
    value: 'VHS video',
    notes: 'VHS videotape: specify TV standard in &lt;ProductFormDetail&gt;',
  },
  {
    code: 'VK',
    value: 'Betamax video',
    notes:
      'Betamax videotape: specify TV standard in &lt;ProductFormDetail&gt;',
  },
  {code: 'VL', value: 'VCD', notes: 'VideoCD'},
  {code: 'VM', value: 'SVCD', notes: 'Super VideoCD'},
  {
    code: 'VN',
    value: 'HD DVD',
    notes: 'High definition DVD disc, Toshiba HD DVD format',
  },
  {
    code: 'VO',
    value: 'Blu-ray',
    notes: 'High definition DVD disc, Sony Blu-ray format',
  },
  {code: 'VP', value: 'UMD Video', notes: 'Sony Universal Media disc'},
  {
    code: 'VQ',
    value: 'CBHD',
    notes: 'China Blue High-Definition, derivative of HD-DVD',
  },
  {
    code: 'VZ',
    value: 'Other video format',
    notes:
      'Other video format not specified by VB to VQ. Further detail is expected in &lt;ProductFormDescription&gt;, as &lt;ProductFormDetail&gt; and &lt;ProductFormFeature&gt; are unlikely to be sufficient',
  },
  {
    code: 'XA',
    value: 'Trade-only material',
    notes: 'Trade-only material (unspecified)',
  },
  {code: 'XB', value: 'Dumpbin – empty', notes: ''},
  {
    code: 'XC',
    value: 'Dumpbin – filled',
    notes:
      'Dumpbin with contents. ISBN (where applicable) and format of contained items must be given in &lt;ProductPart&gt;',
  },
  {code: 'XD', value: 'Counterpack – empty', notes: ''},
  {
    code: 'XE',
    value: 'Counterpack – filled',
    notes:
      'Counterpack with contents. ISBN (where applicable) and format of contained items must be given in &lt;ProductPart&gt;',
  },
  {
    code: 'XF',
    value: 'Poster, promotional',
    notes: 'Promotional poster for display, not for sale – see also PK',
  },
  {code: 'XG', value: 'Shelf strip', notes: ''},
  {
    code: 'XH',
    value: 'Window piece',
    notes: 'Promotional piece for shop window display',
  },
  {code: 'XI', value: 'Streamer', notes: ''},
  {code: 'XJ', value: 'Spinner – empty', notes: ''},
  {
    code: 'XK',
    value: 'Large book display',
    notes: 'Large scale facsimile of book for promotional display',
  },
  {
    code: 'XL',
    value: 'Shrink-wrapped pack',
    notes:
      'A quantity pack with its own product code, usually for trade supply only: the retail items it contains are intended for sale individually. ISBN (where applicable) and format of contained items must be given in &lt;ProductPart&gt;. For products or product bundles supplied individually shrink-wrapped for retail sale, use code SD',
  },
  {
    code: 'XM',
    value: 'Boxed pack',
    notes:
      'A quantity pack with its own product code, usually for trade supply only: the retail items it contains are intended for sale individually. ISBN (where applicable) and format of contained items must be given in &lt;ProductPart&gt;. For products or product bundles boxed individually for retail sale, use code SB',
  },
  {
    code: 'XN',
    value: 'Pack (outer packaging unspecified)',
    notes:
      'A quantity pack with its own product code, usually for trade supply only: the retail items it contains are intended for sale individually. ISBN (where applicable) and format of contained items must be given in &lt;ProductPart&gt;. Use only when the pack is neither shrinp-wrapped nor boxed',
  },
  {
    code: 'XO',
    value: 'Spinner – filled',
    notes:
      'Spinner with contents. ISBN(s) (where applicable) and detail of contained items must be given in &lt;ProductPart&gt;',
  },
  {
    code: 'XY',
    value: 'Other point of sale – including retail product',
    notes:
      'Other point of sale material not specified by XB to XO, supplied with included product(s) for retail sale. The retail product(s) must be described in &lt;ProductPart&gt;. Further detail of the POS material is expected in &lt;ProductFormDescription&gt;, as &lt;ProductFormDetail&gt; and &lt;ProductFormFeature&gt; are unlikely to be sufficient',
  },
  {
    code: 'XZ',
    value: 'Other point of sale',
    notes:
      'Other point of sale material not specified by XB to XY, promotional or decorative. Further detail is expected in &lt;ProductFormDescription&gt;, as &lt;ProductFormDetail&gt; and &lt;ProductFormFeature&gt; are unlikely to be sufficient',
  },
  {
    code: 'ZA',
    value: 'General merchandise',
    notes: 'General merchandise – unspecified',
  },
  {
    code: 'ZB',
    value: 'Doll or figure',
    notes: 'Including action figures, figurines',
  },
  {code: 'ZC', value: 'Soft toy', notes: 'Soft or plush toy'},
  {
    code: 'ZD',
    value: 'Toy',
    notes: 'Including educational toys (where no other code is relevant)',
  },
  {
    code: 'ZE',
    value: 'Game',
    notes:
      'Board game, or other game (except computer game: see DE and other D* codes)',
  },
  {code: 'ZF', value: 'T-shirt', notes: ''},
  {
    code: 'ZG',
    value: 'E-book reader',
    notes: 'Dedicated e-book reading device, typically with mono screen',
  },
  {
    code: 'ZH',
    value: 'Tablet computer',
    notes: 'General purpose tablet computer, typically with color screen',
  },
  {
    code: 'ZI',
    value: 'Audiobook player',
    notes:
      'Dedicated audiobook player device, typically including book-related features like bookmarking',
  },
  {code: 'ZJ', value: 'Jigsaw', notes: 'Jigsaw or similar ‘shapes’ puzzle'},
  {
    code: 'ZK',
    value: 'Mug',
    notes: 'For example, branded, promotional or tie-in drinking mug, cup etc',
  },
  {
    code: 'ZL',
    value: 'Tote bag',
    notes: 'For example, branded, promotional or tie-in bag',
  },
  {
    code: 'ZM',
    value: 'Tableware',
    notes:
      'For example, branded, promotional or tie-in plates, bowls etc (note for mugs and cups, use code ZK)',
  },
  {
    code: 'ZN',
    value: 'Umbrella',
    notes: 'For example, branded, promotional or tie-in umbrella',
  },
  {
    code: 'ZO',
    value: 'Paints, crayons, pencils',
    notes: 'Coloring set, including pens, chalks, etc',
  },
  {
    code: 'ZX',
    value: 'Other toy/game accessories',
    notes:
      'Other toy, game and puzzle items not specified by ZB to ZN, generally accessories to other products etc. Further detail is expected in &lt;ProductFormDescription&gt;, as &lt;ProductFormDetail&gt; and &lt;ProductFormFeature&gt; are unlikely to be sufficient',
  },
  {
    code: 'ZY',
    value: 'Other apparel',
    notes:
      'Other apparel items not specified by ZB to ZN, including branded, promotional or tie-in scarves, caps, aprons, dress-up costumes etc. Further detail is expected in &lt;ProductFormDescription&gt;, as &lt;ProductFormDetail&gt; and &lt;ProductFormFeature&gt; are unlikely to be sufficient',
  },
  {
    code: 'ZZ',
    value: 'Other merchandise',
    notes:
      'Other merchandise not specified by ZB to ZY. Further detail is expected in &lt;ProductFormDescription&gt;, as &lt;ProductFormDetail&gt; and &lt;ProductFormFeature&gt; are unlikely to be sufficient',
  },
];
