import {useMemo} from 'react';
import {ThesaurusAction} from 'types';
import {FieldExtensionEnum, SceneExtensionCore} from 'sceneExtensions/types';
import {ThesaurusValue} from 'schemaDefinition/types';
import {ColorPalette} from 'theme';
import {ThesaurusFieldConfig} from '../types';
import {ThesaurusEditContainer} from '../ThesaurusEditContainer';
import {useThesaurusMetadataFieldExtension} from './useThesaurusMetadataFieldExtension';

export const useThesaurusEditSceneExtensionCore = () => {
  const thesaurusMetadataFieldExtension = useThesaurusMetadataFieldExtension();

  return useMemo((): SceneExtensionCore<
    FieldExtensionEnum.thesaurusEdit,
    ThesaurusValue,
    ThesaurusValue,
    ThesaurusAction,
    ThesaurusFieldConfig
  > => {
    return {
      type: FieldExtensionEnum.thesaurusEdit,
      panelConfig: {
        backgroundColour: ColorPalette.enhancedBrown,
      },
      renderRightPanel: (onClose: () => Promise<void>) => (
        <ThesaurusEditContainer onClose={onClose} />
      ),
      fieldContext: thesaurusMetadataFieldExtension,
    };
  }, [thesaurusMetadataFieldExtension]);
};
