import {AgentCorporation} from 'api/types';
import {TableConfiguration} from 'schema/table/types';
import {defaultTableConfig} from 'schema/table/configuration/defaultTableConfig';
import {corporationAgentDefaultColumns} from './columns';

export const corporationAgentTableConfig: TableConfiguration<AgentCorporation> =
  {
    ...defaultTableConfig,
    defaultColumns: corporationAgentDefaultColumns,
  };
