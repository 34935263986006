import {createContext, useContext, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {assert} from 'assert-ts';
import {ManifestationDesignator} from 'types';
import {useMockParam} from 'navigation';
import {useManifestationDesignatorParam} from '../hooks/useManifestationDesignatorParam';

type UpdateMetadataParamsContextType = {
  workId: string;
  manifestationDesignator: ManifestationDesignator | undefined;
  mock?: boolean;
};

export const UpdateMetadataParamsContext = createContext<
  UpdateMetadataParamsContextType | undefined
>(undefined);

export const useUpdateMetadataParamsContextProviderValue =
  (): UpdateMetadataParamsContextType => {
    const params = useParams();

    const workId = assert(
      params['workId'],
      'useUpdateMetadataParamsContextProviderValue: workId parameter expecrted',
    );

    const manifestationDesignator = useManifestationDesignatorParam();
    const mock = useMockParam();

    return useMemo(
      () => ({workId, manifestationDesignator, mock}),
      [manifestationDesignator, mock, workId],
    );
  };

export const useUpdateMetadataParamsContext =
  (): UpdateMetadataParamsContextType => {
    return assert(
      useContext(UpdateMetadataParamsContext),
      'useUpdateMetadataContext: context expected',
    );
  };
