import {assert} from 'assert-ts';
import {Data, DataValue} from 'schemaDefinition/types';
import {isData} from 'schemaDefinition';

export const assertData = (
  value: Data | DataValue | undefined,
): Data | undefined => {
  if (value === undefined) {
    return undefined;
  }
  if (isData(value)) {
    return value as Data;
  }

  assert.soft(false, `assertData: value must be Data`, {value});

  return undefined;
};
