import {useMemo} from 'react';
import {LinkTargetSubEntityType} from 'types';
import {useLocalization} from 'localization';
import {Builder} from 'services/utils';
import {SearchMainEntityType} from '../types';

export const useSearchStrings = (
  titleKey: string | undefined,
  targetEntity: SearchMainEntityType | undefined,
  targetEntitySubtypes?: LinkTargetSubEntityType[],
) => {
  const {t, tLoose} = useLocalization();
  return useMemo(() => {
    const title = titleKey
      ? tLoose(titleKey)
      : new Builder(t('extension.linkedValueSearch.title')).add(
          ' - ',
          targetEntitySubtypes?.length === 1
            ? t(`general.entity.${targetEntitySubtypes[0]}`)
            : targetEntity
            ? t(`general.entity.${targetEntity}`)
            : undefined,
        ).value;
    const placeholder = targetEntity
      ? t(`extension.linkedValueSearch.${targetEntity}.placeholder`)
      : t('extension.linkedValueSearch.placeholder');
    return {title, placeholder};
  }, [t, tLoose, targetEntity, targetEntitySubtypes, titleKey]);
};
