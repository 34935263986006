import {areValuesEqual} from 'schemaDefinition';
import {EditStatuses, EntityEditStatus, EntityWithId} from '../../../types';
import {getMergedEditStatus} from './getMergedEditStatus';
import {updateEditStatuses} from './updateEditStatuses';

export function updateEditStatusOnEdit<T extends EntityWithId>(
  currentValue: T,
  newValue: T,
  editStatuses: EditStatuses,
): EditStatuses {
  const hasDataChanged = !areValuesEqual(newValue, currentValue);
  const current: EntityEditStatus | undefined = editStatuses[currentValue.id];
  const newStatus: EntityEditStatus | undefined = getMergedEditStatus(
    {hasDataChanged},
    current,
  );
  return updateEditStatuses(currentValue.id, newStatus, editStatuses);
}
