import {assert} from 'assert-ts';
import {CodeListRef, FullManifestation} from '../types';
import {Schemas} from '../dto.generated';
import {mapAgentRoleDto} from './mapAgentRoleDto';
import {mapImprintDto} from './mapImprintDto';
import {mapPhysicalPropertiesDto} from './mapPhysicalPropertiesDto';
import {mapWorkRelationsDto} from './mapRelationsDto';
import {mapToRuntime} from './mapRuntime';
import {mapTitleInfoDto} from './mapTitleInfoDto';

export const mapManifestationWithExpressionDto = (
  dto: Schemas.ManifestationWithExpressionDto,
): FullManifestation => {
  const {
    expression,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    agents: manifestationAgents,
    imprints,
    physicalProperties,
    marketingData,
    ddsData,
    runtimeSeconds,
    ...manifestationDto
  } = dto;
  const {
    work,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    agents: _expressionAgents,
    ...expressionDto
  } = assert(expression);
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    agents: _workAgents,
    workRelations,
    agentRelations,
    seriesRelations,
    ...workDto
  } = assert(work);

  const physicalPropertyFields = mapPhysicalPropertiesDto(physicalProperties);
  const links = mapWorkRelationsDto({
    workRelations,
    agentRelations,
    seriesRelations,
  });

  // const manifestationLinks = mapManifestationRelationsDto({seriesRelations: manifestationSeriesRelations});

  return {
    ...workDto,
    type: workDto.type ?? CodeListRef.WORK_TYPE.Bok,
    ...expressionDto,
    ...manifestationDto,
    id: assert(manifestationDto.id, 'mapManifestationDto: id expected', dto),
    workId: assert(workDto.id, 'mapManifestationDto: workId expected', dto),
    expressionId: assert(
      expressionDto.id,
      'mapManifestationDto: expressionId expected',
      dto,
    ),
    created: assert(
      manifestationDto.created,
      'mapManifestationDto: created expected',
      dto,
    ),
    modified: assert(
      manifestationDto.modified,
      'mapManifestationDto: modified expected',
      dto,
    ),
    status: assert(
      manifestationDto.status,
      'mapManifestationDto: status expected',
      dto,
    ),
    productFormDetail: manifestationDto.productFormDetail,
    ...mapTitleInfoDto(manifestationDto.titleInfo),
    agents: manifestationAgents?.map(mapAgentRoleDto),
    workAgents: _workAgents?.map(mapAgentRoleDto),
    links,
    expressionAgents: _expressionAgents?.map(mapAgentRoleDto),
    imprints: imprints ? imprints.map(mapImprintDto) : undefined,
    products: marketingData ? marketingData.products : undefined,
    ddsStatus: ddsData?.ebooks,
    runtimeSeconds: mapToRuntime(runtimeSeconds),
    ...physicalPropertyFields,
  };
};
