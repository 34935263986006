import {loadReducer} from 'services/utils';
import {UsersDefaultState, UsersState} from './types';
import {UserActionType, UsersAction} from './actions';

export const usersReducer = (
  state: UsersState = UsersDefaultState,
  action: UsersAction,
): UsersState => {
  switch (action.type) {
    case UserActionType.LOAD_USERS: {
      return loadReducer(state, action);
    }
    case UserActionType.RESET_USERS: {
      return UsersDefaultState;
    }
    // case 'LOGIN': {
    //   return {
    //     ...state,
    //     ...action.payload,
    //     status: 'Usersenticated',
    //   };
    // }
    // case 'LOGIN_FAILED': {
    //   return {status: 'Failed'};
    // }
    // case 'LOGOUT': {
    //   return UsersDefaultState;
    // }
  }

  return state;
};
