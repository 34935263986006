export function putById<T>(
  id: string,
  update: Partial<T>,
  defaultValue: T,
  map: {[id: string]: T},
): {[id: string]: T} {
  const prevValue = map[id] ?? defaultValue;
  const newValue = {...prevValue, ...update};
  const newMap = {...map};
  newMap[id] = newValue;
  return newMap;
}
