import {
  Concept,
  LinkTargetMainEntityType,
  LinkTargetMainEntityTypes,
} from 'types';

export const isLinkTargetMainEntityType = (
  type: Concept | undefined,
): type is LinkTargetMainEntityType => {
  return LinkTargetMainEntityTypes.includes(type as LinkTargetMainEntityType);
};
