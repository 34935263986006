import {useCallback, useMemo, useRef} from 'react';
import {Configuration} from 'configuration';
import {DataLoadStatus, Lock, useGetResource} from 'api';
import {getLock} from 'api/lock';
import {useGetTokens} from 'services/auth';

const LOCK_REFRESH_INTERVAL_MS = 5 * 1000;

export const useCurrentLock = (
  workId: string,
  mock?: boolean,
): DataLoadStatus<Lock | undefined> => {
  const getTokens = useGetTokens();
  const handleGetLock = useCallback(
    () => getLock(workId, 'work', getTokens, mock),
    [getTokens, mock, workId],
  );

  const interval = Configuration.interval.lock ?? LOCK_REFRESH_INTERVAL_MS;
  const currentLock = useGetResource(handleGetLock, interval);
  const currentLockRef = useRef<DataLoadStatus<Lock | undefined>>(currentLock);

  return useMemo(() => {
    // Only update ref for relevant statuses, i.e.
    // - loaded (locked)
    // - failed with 404 or 204 (not locked)

    if (
      (currentLock.status === 'Loaded' ||
        (currentLock.status === 'Failed' &&
          (currentLock.error.status === 404 ||
            currentLock.error.status === 204))) &&
      (currentLockRef.current.status === 'NotLoaded' ||
        currentLockRef.current.status === 'Loading' ||
        JSON.stringify(currentLock) !== JSON.stringify(currentLockRef.current))
    ) {
      currentLockRef.current = currentLock;
    }
    return currentLockRef.current;
  }, [currentLock]);
};
