import {useMemo} from 'react';
import {PreviewConfiguration} from 'schema/types';
import {ColorPalette} from 'theme/colorPalette';
import {Preview} from 'schema/preview';
import {usePreviewConfigurationContextProviderValue} from 'schema/preview/contexts';
import {ReadonlyExpressionCardProps} from './types';

export const ReadonlyExpressionCard: React.FC<ReadonlyExpressionCardProps> = ({
  expression,
  schema,
  configuration,
}) => {
  const expressionPreviewConfiguration =
    useMemo((): Partial<PreviewConfiguration> => {
      return {
        nameVariant: {
          excludeMainForm: false,
        },
        layout: {
          noLabels: true,
          noGrid: true,
          partSpacing: 1,
          linkedValuesByRoles: true,
          groups: {},
          ...(configuration?.layout ?? {}),
        },
        rootContainer: 'NoContainer',
        schemaContainers: {
          expression: 'NoContainer',
        },
        text: {
          label: {
            variant: 'caption',
            sx: {
              color: ColorPalette.warmGreen,
            },
          },
          value: {
            variant: 'caption',
            sx: {
              color: ColorPalette.warmGreen,
            },
          },
        },
        excludeParts: ['languages', 'expressionFormat'],
        linking: configuration?.linking,
        onNavigate: configuration?.onNavigate,
      };
    }, [configuration]);

  const previewConfiguration = usePreviewConfigurationContextProviderValue(
    expressionPreviewConfiguration,
  );

  return (
    <Preview
      schema={schema}
      data={expression}
      configuration={previewConfiguration}
    />
  );
};
