import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {CodeListMap} from 'api/types';
import {CodeListId} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {isLinkedRoleCodeListId} from 'schemaDefinition';
import {LinkedRoleCodeListId} from 'schemaDefinition/linkTypes';
import {getLinkedRoleCodelist} from 'schemas/links/functions';
import {codelistsSelector} from '../selectors';

export function useCodelistsLazy(
  codelistIds: (CodeListId | LinkedRoleCodeListId)[],
): CodeListMap | undefined {
  const localization = useLocalization();
  const codelists = useSelector(codelistsSelector);
  return useMemo(() => {
    let anyMissing = false;
    const map = codelistIds.reduce<CodeListMap>((acc, id) => {
      if (isLinkedRoleCodeListId(id)) {
        const codelist = getLinkedRoleCodelist(id, localization);
        acc[id] = codelist;
        return acc;
      }

      const codelist = codelists[id]?.data;
      if (!codelist) {
        anyMissing = true;
        return acc;
      }

      acc[id] = codelist;
      return acc;
    }, {});
    return anyMissing ? undefined : map;
  }, [codelistIds, codelists, localization]);
}
