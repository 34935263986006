import {ColorMode, Kind} from '../types';
import {ThemeColors} from '../themeColors';

export const useThemeColor = (
  kind?: Kind,
  mode: ColorMode = 'main',
): string | undefined => {
  return kind
    ? kind !== 'inherit'
      ? ThemeColors.text[kind][mode]
      : kind
    : undefined;
};
