import React from 'react';
import {SvgIcon} from '@mui/material';
import {ColorPalette} from 'theme';
import {IconProps} from './types';

export const CheckboxCheckedIcon: React.FC<IconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <rect x="4" y="4" width="16" height="16" rx="4" fill={'currentColor'} />
      <path
        d="M15.4137 9.66669L11.0001 14.2867L8.31352 11.7467L7.35352 12.6667L11.0001 16.2067L16.3737 10.6L15.4137 9.66669Z"
        fill={ColorPalette.offWhite}
      />
    </SvgIcon>
  );
};
