import {useMemo} from 'react';
import {useLocalization} from '../../../localization';
import {useFormSchemaGlobalScopeContext} from '../../contexts';
import {getFieldRootKeys, getLabel, getPlaceholder} from '../functions';

type LabelsAndPlaceholders<TKey extends string> = {
  [key in TKey]: {
    label: string;
    placeholder?: string;
  };
};

export const useLabelsAndPlaceholders = <TKey extends string>(
  keys: TKey[],
): LabelsAndPlaceholders<TKey> => {
  const localization = useLocalization();
  const {schema} = useFormSchemaGlobalScopeContext();
  return useMemo(
    () =>
      keys.reduce<LabelsAndPlaceholders<TKey>>((acc, key) => {
        const rootKeys = getFieldRootKeys(schema.key, false, key, undefined);
        return {
          ...acc,
          [key]: {
            label: getLabel(rootKeys, true, localization),
            placeholder: getPlaceholder(rootKeys, localization),
          },
        };
      }, {} as LabelsAndPlaceholders<TKey>),
    [keys, localization, schema.key],
  );
};
