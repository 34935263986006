import {useMemo} from 'react';
import {ContentLabelWithLayout} from 'components/fields/types';
import {Part} from 'schemaDefinition/types';
import {useDataFormConfigurationContext} from '../contexts/DataFormConfigurationContext';
import {getLayout} from './useLayout';

export const useContentLabelsWithLayout = <TVal>(
  part: Part<TVal>,
): ContentLabelWithLayout[] | undefined => {
  const configuration = useDataFormConfigurationContext();

  const contentLabels = useMemo(() => {
    const labels = part.name
      ? configuration?.parts?.[part.name]?.contentLabels ?? undefined
      : undefined;
    if (!labels) return undefined;

    // Patch with layout
    const labelsWithLayout = Object.keys(labels).map(key => {
      const {layout, ...rest} = labels[key];
      const globalLayout = getLayout(key);
      return globalLayout
        ? {...globalLayout, ...layout, ...rest}
        : {...layout, ...rest};
    });

    return labelsWithLayout;
  }, [configuration?.parts, part.name]);

  return contentLabels;
};
