import {ValidationError} from 'schemaDefinition/types';
import {MetadataValidationResult} from 'schemas/types';

type ValidationLevels = 'error' | 'warning' | 'errorAndWarning';

type Visitor = (error: ValidationError) => void;

const visitValidationErrors = (
  validation: MetadataValidationResult,
  visitor: Visitor,
) => {
  if (validation.work) {
    validation.work.errors.forEach(visitor);
  }

  if (validation.expressions) {
    validation.expressions.forEach(e => {
      e.validation.errors.forEach(visitor);
      e.manifestations.forEach(m => {
        m.validation.errors.forEach(visitor);
      });
    });
  }
};

export const getMetadataValidationLevels = (
  validation: MetadataValidationResult,
): ValidationLevels | undefined => {
  let hasErrors = false;
  visitValidationErrors(validation, error => {
    hasErrors = hasErrors || error.level === 'error';
  });

  let hasWarnings = false;
  visitValidationErrors(validation, error => {
    hasWarnings = hasWarnings || error.level === 'warning';
  });

  return hasErrors && hasWarnings
    ? 'errorAndWarning'
    : hasErrors
    ? 'error'
    : hasWarnings
    ? 'warning'
    : undefined;
};
