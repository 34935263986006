import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const PocketBookIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M19.21 4.47C18.8623 4.20472 18.4373 4.0607 18 4.06C17.8212 4.06292 17.6434 4.08641 17.47 4.13C15.4501 4.65309 13.5782 5.63523 12 7C10.4218 5.63523 8.54986 4.65309 6.53 4.13C6.3566 4.08641 6.17877 4.06292 6 4.06C5.46957 4.06 4.96086 4.27071 4.58579 4.64578C4.21071 5.02086 4 5.52956 4 6.06V14C3.99967 14.4383 4.14335 14.8646 4.40895 15.2133C4.67454 15.562 5.04734 15.8138 5.47 15.93C7.39071 16.354 9.10074 17.4409 10.3 19C10.4728 19.2994 10.7202 19.5489 11.0182 19.7241C11.3161 19.8994 11.6544 19.9944 12 20C12.3473 19.9962 12.6877 19.9019 12.9875 19.7266C13.2873 19.5512 13.5363 19.3008 13.71 19C14.9029 17.4454 16.6063 16.3617 18.52 15.94C18.9462 15.8252 19.3224 15.5728 19.5901 15.2218C19.8578 14.8709 20.0019 14.4413 20 14V6.06C19.9996 5.75204 19.9281 5.44832 19.7911 5.17253C19.6541 4.89674 19.4552 4.65631 19.21 4.47V4.47ZM6 14V6.06C7.87552 6.54208 9.59667 7.49559 11 8.83V16.75C9.59446 15.4228 7.8735 14.4763 6 14V14ZM18 14C16.1245 14.4821 14.4033 15.4356 13 16.77V8.83C14.4033 7.49559 16.1245 6.54208 18 6.06V14Z" />
    </SvgIcon>
  );
};
