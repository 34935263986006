import React, {useMemo} from 'react';
import {getScopeDependencies} from 'schemaDefinition/functions';
import {DataFormPartProps} from './types';
import {useFormSchemaLocalScopeContext} from '../../contexts';
import {DataFormPart} from './DataFormPart';

/**
 * Render part, wrapped with global and local scope, as needed by part
 */
export const DataFormPartWrapper: React.FC<DataFormPartProps> = ({
  relatedScope,
  ...props
}) => {
  const {valuePath: localScopeValuePath} = useFormSchemaLocalScopeContext();
  const {resolvedRelatedScope, localScopePath, usesGlobalScope} =
    useMemo(() => {
      const {local, global, related} = getScopeDependencies(props.part);
      return {
        resolvedRelatedScope: related ? relatedScope : undefined,
        localScopePath: local ? localScopeValuePath : undefined,
        usesGlobalScope: global ? true : undefined,
      };
    }, [localScopeValuePath, props.part, relatedScope]);

  return (
    <DataFormPart
      {...props}
      scopePath={localScopePath}
      usesGlobalScope={usesGlobalScope}
      relatedScope={resolvedRelatedScope}
    />
  );
};
