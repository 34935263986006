import {EntityEditStatus} from 'services/data/metadata/types';

const defaultEntityStatus: EntityEditStatus | undefined = undefined;

export function getMergedEditStatus(
  changes: Partial<EntityEditStatus> | undefined,
  current: EntityEditStatus | undefined,
): EntityEditStatus | undefined {
  const newStatus: EntityEditStatus | undefined = current
    ? {
        ...current,
        ...changes,
      }
    : {
        ...defaultEntityStatus,
        ...changes,
      };

  if (
    !!newStatus?.hasDataChanged === !!current?.hasDataChanged &&
    !!newStatus?.hasMoved === !!current?.hasMoved
  ) {
    return current;
  }

  return newStatus?.hasDataChanged || newStatus?.hasMoved
    ? newStatus
    : undefined;
}
