import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const ChevronLeftIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M8.58996 12L13.29 7.29004L14.71 8.71004L11.41 12L14.71 15.29L13.29 16.71L8.58996 12Z" />
    </SvgIcon>
  );
};
