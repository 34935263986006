import {MetadataTimestamps} from '../../../types';

export const incrementMetadataTimestamps = (
  timestamps: MetadataTimestamps,
): MetadataTimestamps => {
  const newTimestamps: MetadataTimestamps = {...timestamps};

  Object.keys(newTimestamps).forEach(key => {
    newTimestamps[key]++;
  });

  return newTimestamps;
};
