import {
  LinkedAgentMultiRole,
  VerifiedLinkedAgentMultiRole,
} from 'schemaDefinition/types';

export const isVerifiedLinkedAgentMultiRole = (
  linkedAgent: LinkedAgentMultiRole,
): linkedAgent is VerifiedLinkedAgentMultiRole => {
  return linkedAgent.link.linkStatus === 'verified';
};
