import React from 'react';
import {NoProps} from 'types';
import {useLocalization} from 'localization';
import {Scene} from 'components';
import {ReceiveEans} from './components/ReceiveEans';

type Props = NoProps;

export const ReceiveScene: React.FC<Props> = () => {
  const {t} = useLocalization();
  return (
    <Scene browserTitle={t('appbar.receive')}>
      <Scene.Header title={t('appbar.receive')} />
      <Scene.Content>
        <ReceiveEans />
      </Scene.Content>
    </Scene>
  );
};
