import {CodeList} from 'api/types';
import {ManifestationImportProps} from 'services/data/metadata/types';
import {isNullish} from 'services/utils';

const excludedProductForms = (code: string): boolean => {
  return [
    'PZ', // Andre trykte artikler
    'PM', // Mappe
    'PK', // Plakat
    'PC', // Kalender
    'PA', // Diverse trykk
  ].includes(code);
};

export function getRestrictedProductFormDetailCodelist(
  {manifestation: {productForm}}: ManifestationImportProps,
  codelist: CodeList,
): CodeList {
  return isNullish(productForm)
    ? codelist
    : {
        ...codelist,
        codes: codelist.codes.map(item => {
          const hasProductForm = item.code[0] === productForm[0];

          // Never show detail options for these productforms
          const notExcluded = !excludedProductForms(productForm);

          return hasProductForm && notExcluded
            ? item
            : {...item, disabled: true};
        }),
      };
}
