import React from 'react';
import {FilledIconName, IconName} from '../icons/types';
import {FilledIcon, FilledIconNames, Icon, IconNames} from '../icons';
import {Layout} from '../layout';
import {Spacer} from '../spacer';
import {Text} from '../text';

type IconViewProps = {
  iconName: IconName;
};
const IconView: React.FC<IconViewProps> = ({iconName}) => {
  return (
    <Layout width={150} adjustCenter>
      <Icon icon={iconName} fontSize="small" />
      <Icon icon={iconName} fontSize="medium" />
      <Icon icon={iconName} fontSize="medium" color="error" />
      <Icon icon={iconName} fontSize="large" />
      <Text variant="h4">{iconName}</Text>
      <Spacer size={2} />
    </Layout>
  );
};

type FilledIconViewProps = {
  iconName: FilledIconName;
};
const FilledIconView: React.FC<FilledIconViewProps> = ({iconName}) => {
  return (
    <Layout width={150} adjustCenter>
      <FilledIcon icon={iconName} fontSize="small" />
      <FilledIcon icon={iconName} fillColor="new" fontSize="medium" />
      <FilledIcon icon={iconName} fillColor="pre" fontSize="medium" />
      <FilledIcon icon={iconName} fillColor="change" fontSize="large" />
      <Text variant="h4">{iconName}</Text>
      <Spacer size={2} />
    </Layout>
  );
};

export const Icons = () => {
  return (
    <Layout kind="primary">
      <Layout horizontal wrap>
        {IconNames.map(n => (
          <IconView key={n} iconName={n} />
        ))}
      </Layout>
      <Layout horizontal wrap>
        {FilledIconNames.map(n => (
          <FilledIconView key={n} iconName={n} />
        ))}
      </Layout>
    </Layout>
  );
};
