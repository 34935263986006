import {CodeList, CodeListRef} from 'api/types';
import {ExpressionImportProps} from 'services/data/metadata/types';
import {isNullish} from 'services/utils';
import {isAudioProductForm} from './isAudioProductForm';

export function getRestrictedProductFormCodelistByExpressionFormat(
  {expression: {expressionFormat}}: ExpressionImportProps,
  codelist: CodeList,
): CodeList {
  return isNullish(expressionFormat)
    ? codelist
    : expressionFormat === CodeListRef.EXPRESSION_FORMAT.AUDIO
      ? {
          ...codelist,
          codes: codelist.codes.map(item =>
            isAudioProductForm(item.code) ? item : {...item, disabled: true},
          ),
        }
      : {
          ...codelist,
          codes: codelist.codes.map(item =>
            !isAudioProductForm(item.code) ? item : {...item, disabled: true},
          ),
        };
}
