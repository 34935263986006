import {assert} from 'assert-ts';
import {MetadataValidationResult} from 'schemas/types';
import {MetadataEditState, MetadataIds} from '../types';
import {aggregateMetadataValid} from './aggregateMetadataValid';
import {validateExpression} from './validateExpression';
import {validateWork} from './validateWork';

export const validateMetadata = (
  {
    savedMetadata,
    metadata,
    configuration,
    statuses,
  }: Pick<
    MetadataEditState,
    'savedMetadata' | 'metadata' | 'configuration' | 'statuses'
  >,
  ids: MetadataIds | undefined,
): MetadataValidationResult => {
  if (
    ids === undefined ||
    metadata === undefined ||
    !assert.soft(savedMetadata, 'validateMetadata: no savedMetadata')
  ) {
    return {valid: 'valid'};
  }

  const schemas = assert(configuration.schemas, 'validateMetadata: no schemas');
  const codelists = assert(
    configuration.codelists,
    'validateMetadata: no codelists',
  );

  const {validation: workValidation, status: workStatus} = validateWork(
    savedMetadata,
    metadata,
    schemas,
    codelists,
    statuses,
  );

  // Validate expressions
  const expressionValidations = ids.expressions.map(expressionIds =>
    validateExpression(
      expressionIds,
      savedMetadata,
      metadata,
      schemas,
      codelists,
      statuses,
    ),
  );

  return {
    valid: aggregateMetadataValid(workValidation, expressionValidations),
    workStatus,
    work: workValidation,
    workId: metadata.work.id,
    expressions: expressionValidations,
  };
};

// Schema x modifier, codelist x restrictor, data => validation
