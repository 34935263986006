import {Schema} from 'schemaDefinition/types';
import {CodeListId} from 'api';
import {getPartCodelistId, visitSchemaParts} from 'schemaDefinition/functions';
import {LinkedRoleCodeListId} from 'schemaDefinition/linkTypes';

export const getSchemaListCodelistIds = <TVal>(
  schemas: Schema<TVal>[],
): (CodeListId | LinkedRoleCodeListId)[] => {
  const acutalSchemas = schemas.filter(Boolean) as Schema<TVal>[];
  if (acutalSchemas.length === 0) {
    return [];
  }

  const codelists: (CodeListId | LinkedRoleCodeListId)[] = [];

  acutalSchemas.forEach(schema =>
    visitSchemaParts(schema, part => {
      const partCodelistIds = getPartCodelistId(part);
      partCodelistIds?.forEach(partCodelistId => {
        if (!codelists.includes(partCodelistId)) {
          codelists.push(partCodelistId);
        }
      });
    }),
  );

  return codelists;
};
