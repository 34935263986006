import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const FilterIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M16.89 6.00002L13.39 10.31C13.1248 10.6756 12.9877 11.1185 13 11.57V18H11.05V11.57C11.048 11.1127 10.8932 10.6692 10.61 10.31L7.11005 6.00002H16.89ZM19 4.00002H5.00005C4.82082 4.00272 4.64561 4.05355 4.49275 4.14719C4.3399 4.24083 4.21503 4.37383 4.13121 4.53228C4.04739 4.69072 4.00769 4.86879 4.01629 5.04783C4.02488 5.22688 4.08144 5.40033 4.18005 5.55002L9.07005 11.55V18C9.07005 18.5304 9.28076 19.0392 9.65583 19.4142C10.0309 19.7893 10.5396 20 11.07 20H13.07C13.6005 20 14.1092 19.7893 14.4843 19.4142C14.8593 19.0392 15.07 18.5304 15.07 18V11.57L19.96 5.57002C20.0739 5.41035 20.1384 5.22071 20.1453 5.0247C20.1522 4.8287 20.1014 4.63498 19.9991 4.46765C19.8968 4.30032 19.7475 4.16678 19.5699 4.08363C19.3922 4.00048 19.1941 3.97141 19 4.00002Z" />
    </SvgIcon>
  );
};
