import React from 'react';
import Stack from '@mui/material/Stack';
import {FCWithChildren} from 'types';

type Spacing = 'tight' | 'medium' | 'wide';

type Props = {
  spacing?: Spacing;
};

const spacingMap: {[key in Spacing]: number} = {
  tight: 0.5,
  medium: 1,
  wide: 1,
};

export const ToolBar: FCWithChildren<Props> = ({
  spacing = 'medium',
  children,
}) => {
  return (
    <Stack direction="row" spacing={spacingMap[spacing]} alignItems="center">
      {children}
    </Stack>
  );
};
