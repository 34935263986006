import React from 'react';
import Typography from '@mui/material/Typography';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {Card} from 'components';

export const GrepCategoryGuide: React.FC = () => {
  const {t} = useLocalization();

  return (
    <Card colorx={ColorPalette.offWhite}>
      <Card.Header>
        <Typography
          variant={'h3'}
          align={'center'}
          color={ColorPalette.primary.burgundy}
          fontSize={'1.2em'}>
          {t('text.grep.rule.header')}
        </Typography>
      </Card.Header>
      <Card.Content>
        <Card colorx={ColorPalette.lightBeige}>
          <Card.Content>
            <Typography variant={'body2'}>
              <ol className={'themaRules'}>
                <li>{t('text.grep.rule.first')}</li>
              </ol>
            </Typography>
          </Card.Content>
        </Card>
      </Card.Content>
    </Card>
  );
};
