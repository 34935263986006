import React, {useMemo} from 'react';
import {percentageToCartesian} from './percentageToCartesian';

type PercentageCircleSvgProps = {
  /**
   * Percentage: 0 - 100
   */
  percentage: number;
  size: number;
  backgroundColor?: string;
  percentageColor?: string;
};

const describePercentageArc = (
  cx: number,
  cy: number,
  radius: number,
  percentage: number,
) => {
  const start = percentageToCartesian(radius, 0);
  const end = percentageToCartesian(radius, percentage);

  const d = [
    'M',
    cx + start.x,
    cy + start.y,
    'A',
    radius,
    radius,
    0,
    percentage > 50 ? 1 : 0,
    1,
    cx + end.x,
    cx + end.y,
  ].join(' ');

  return d;
};

export const PercentageCircleSvg: React.FC<PercentageCircleSvgProps> = ({
  percentage,
  size,
  backgroundColor,
  percentageColor,
}) => {
  const {strokeWidth, radius, d, status} = useMemo(() => {
    const strokeWidth = (2 * 40) / size;
    const radius = 20 - strokeWidth;
    return {
      strokeWidth,
      radius,
      d: describePercentageArc(20, 20, radius, percentage),
      status:
        percentage < 0.01 ? 'empty' : percentage > 99.9 ? 'full' : 'partial',
    };
  }, [percentage, size]);

  return (
    <svg width={size} height={size} viewBox="0 0 40 40">
      <ellipse
        ry={radius}
        rx={radius}
        id="svg_1"
        cy={20}
        cx={20}
        strokeWidth={strokeWidth}
        stroke={status === 'full' ? percentageColor : backgroundColor}
        fill={'none'}
      />
      {status === 'partial' ? (
        <path
          id="arc1"
          strokeWidth={strokeWidth}
          stroke={percentageColor}
          // strokeLinecap="round"
          d={d}
          fill={'none'}
        />
      ) : null}
    </svg>
  );
};
