import {assert} from 'assert-ts';
import {Concept} from 'types';
import {RelationTargetTypeDto} from 'api/types';
import {VerifiedLinkedLiterary} from 'schemaDefinition/types';
import {reverseMap} from './reverseMap';

type TargetEntityType = VerifiedLinkedLiterary['link']['type'];

export const targetEntityTypeMap: {
  [key in RelationTargetTypeDto]: TargetEntityType;
} = {
  WORK: Concept.work,
  MANIFESTATION: Concept.manifestation,
  SERIES: Concept.series,
  EDUCATIONAL_SERIES: Concept.educationalSeries,
  OTHER_CONNECTION: Concept.otherConnection,
  PUBLISHER_SERIES: Concept.publisherSeries,
  PERSON: Concept.person,
  CORPORATION: Concept.corporation,
  EVENT: Concept.event,
};

export const mapTargetEntityTypeDto = (
  dto: RelationTargetTypeDto,
): TargetEntityType => targetEntityTypeMap[dto];

export const mapToTargetEntityTypeDto = (
  targetType: TargetEntityType,
): RelationTargetTypeDto => {
  return assert(
    reverseMap(targetType, targetEntityTypeMap),
    'mapToTargetEntityTypeDto: unknown type',
    {targetType},
  );
};
