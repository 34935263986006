import {Code, CodeList, CodeListRef, ManifestationV4} from 'api/types';
import {Metadata} from '../types';
import {getProductFormText} from '../functions';

type CodeLists = {
  productFormOverride?: CodeList;
  productForm?: CodeList;
  productFormDetails?: CodeList;
};

const getManifestationValue = (
  manifestation: ManifestationV4,
  codelists: CodeLists,
): string => {
  const productFormText = getProductFormText({
    code: manifestation.productForm,
    detailsCode: manifestation.productFormDetail?.[0],
    codelists,
  });

  const fields = [
    manifestation.isbn,
    productFormText,
    manifestation.publishedYear,
  ].filter(f => !!f);

  return fields.join(' / ');
};
export const getExpressionManifestationsLocalCodelist = (
  state: {metadataEdit: {metadata?: Pick<Metadata, 'manifestations'>}},
  expressionId: string,
  codelists: CodeLists,
): CodeList => {
  const allManifestations =
    state.metadataEdit.metadata?.manifestations.filter(
      m =>
        m.isbn &&
        // Filter out E-bok here for now. If manifestation needs to be selected
        // in more contexts, add codelist restrictor filtering out
        // manifestation with same isbn instead
        m.productForm !==
          CodeListRef.PRODUCT_FORM['Digitalt format, nedlastbart (download)'],
    ) ?? [];

  const expressionManifestations = allManifestations.filter(
    m => m.expressionId === expressionId,
  );
  const otherManifestations = allManifestations.filter(
    m => m.expressionId !== expressionId,
  );
  const codes: Code[] = [
    ...expressionManifestations,
    ...otherManifestations,
  ].map(m => ({
    code: m.isbn!,
    value: getManifestationValue(m, codelists),
    deactivated: m.expressionId !== expressionId,
  }));

  return {
    id: 'expression_manifestation',
    language: 'nb',
    codes,
  };
};
