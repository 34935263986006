import {assert} from 'assert-ts';
import {EntitySubType} from 'types';
import {LinkedValueSearchFieldConfig} from 'feature/linkedValueSearch/types';
import {LinkedValueLink} from 'schemaDefinition/types';
import {CoreSearchState, DefaultMainEntity, EntitySearchState} from '../types';
import {getDefaultSubEntity} from './getDefaultSubEntity';
import {getSearchString} from './getSearchString';

type CoreSearchStateSelectedTypes = Pick<
  CoreSearchState,
  'selectedMainEntity' | 'selectedSubEntity'
>;

const hasSameTypes = (
  a: CoreSearchStateSelectedTypes,
  b: CoreSearchStateSelectedTypes,
): boolean =>
  a.selectedMainEntity === b.selectedMainEntity &&
  a.selectedSubEntity === b.selectedSubEntity;

export const updateSearchStateOnNewSource = (
  currentSourceValue: LinkedValueLink | undefined,
  currentSourceConfig: LinkedValueSearchFieldConfig | undefined,
  prevState: CoreSearchState,
): CoreSearchState => {
  // When different linked field is selected in work/expression/manifestation
  // => update search state:
  // Messages x States => new State
  //
  //             State  | Common              | Closed           | Open:View entity | Open:Search      | Open:Browse result  |
  // Message            |                     |                  |                  |                  |                     |
  // -------------------------------------------------------------------------------------------------------------------------
  //   Empty link field | Set entity and      | Open:Search      | same             | same             | same                |
  //                    | sub-entity          |                  |                  |                  |                     |
  // -------------------------------------------------------------------------------------------------------------------------
  //   Unverified link  | -""-                | Open:Search      | Open:Search      | Open:Search      | Open:Search         |
  //                    |                     | (on unverified   | (same)           | (same)           | (same)              |
  //                    |                     | name)            |                  |                  |                     |
  // -------------------------------------------------------------------------------------------------------------------------
  //   Verified link    | -""-                | Open:View entity | Open:View entity | Open:View entity | Open:View entity    |
  // -------------------------------------------------------------------------------------------------------------------------

  const mainEntity =
    currentSourceConfig?.entity ??
    prevState.selectedMainEntity ??
    DefaultMainEntity;
  const currentLinkEntity =
    currentSourceValue?.linkStatus === 'verified'
      ? currentSourceValue.type
      : undefined;
  const defaultTypes: CoreSearchStateSelectedTypes = {
    selectedMainEntity: mainEntity,
    selectedSubEntity: getDefaultSubEntity(
      mainEntity,
      currentSourceConfig,
      (currentLinkEntity as EntitySubType) ?? prevState.selectedSubEntity,
    ),
  } as const;

  if (!currentSourceValue || currentSourceValue.linkStatus === 'empty') {
    // Keep same state
    return hasSameTypes(prevState, defaultTypes)
      ? prevState
      : {
          ...prevState,
          ...defaultTypes,
        };
  }

  switch (currentSourceValue.linkStatus) {
    case 'unnamed': {
      assert(false, 'updateSearchStateOnNewSource: unnamed link not supported');
      break;
    }
    case 'unverified': {
      // I.e. unverified agent
      if (!currentSourceValue.name) {
        // Keep same state when other field is empty, but update types
        return hasSameTypes(prevState, defaultTypes)
          ? prevState
          : {
              ...prevState,
              ...defaultTypes,
            };
      }

      // State search with new query
      return {
        ...prevState,
        state: EntitySearchState.search,
        searchQuery: getSearchString(currentSourceValue),
        ...defaultTypes,
      };
    }
    case 'verified': {
      return {
        ...prevState,
        state: EntitySearchState.viewEntity,
        viewEntity: {
          id: assert(
            currentSourceValue.entityId,
            'updateSearchStateOnNewSource: entityId expected',
            currentSourceValue,
          ),
        },
        ...defaultTypes,
      };
    }
  }
};
