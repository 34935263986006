import React, {useCallback, useMemo} from 'react';
import {FCWithChildren} from 'types';
import {
  SceneExtensionsContext,
  useSceneExtensionsContextProviderValue,
} from 'sceneExtensions/contexts';
import {useLinkedValueSearchSceneExtensionCore} from './useLinkedValueSearchSceneExtensionCore';

export const useLinkedValueSearchContextProvider = () => {
  const linkedValueSearchExtension = useLinkedValueSearchSceneExtensionCore();
  const extensions = useMemo(
    () => [linkedValueSearchExtension],
    [linkedValueSearchExtension],
  );

  const sceneExtensionContext =
    useSceneExtensionsContextProviderValue(extensions);

  return useCallback<FCWithChildren>(
    ({children}) => (
      <SceneExtensionsContext.Provider value={sceneExtensionContext}>
        {children}
      </SceneExtensionsContext.Provider>
    ),
    [sceneExtensionContext],
  );
};
