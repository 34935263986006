export type HeadTitleParam = {title?: string};

export type HeadState = {
  title?: string;
  description: string;
};

export const HeadDefaultState: HeadState = {
  title: undefined,
  description: 'Bokbasen metadata modelering',
};
