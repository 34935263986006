import {ChangeRequest} from 'api';
import {createChangeRequestStoreKey} from './createChangeRequestStoreKey';

export const saveChangeRequestInLocalStore = (changeRequest: ChangeRequest) => {
  const key = createChangeRequestStoreKey(
    changeRequest.id,
    changeRequest.work.id,
  );
  localStorage.setItem(key, JSON.stringify(changeRequest));
};
