import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const CloseSmallIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M16.5 8.92L15.08 7.5L12 10.59L8.92 7.5L7.5 8.92L10.59 12L7.58001 15L9.00001 16.42L12 13.41L15 16.42L16.42 15L13.41 12L16.5 8.92Z" />
    </SvgIcon>
  );
};
