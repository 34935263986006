import {AgentSubType} from 'types';
import {Agent} from 'api/types';
import {AgentTypeAll} from 'services/settings';
import {useAgentSearchText} from './useAgentSearchText';
import {useAgentSearchVisibility} from './useAgentSearchVisibility';
import {useAgentTypes} from './useAgentTypes';

export const useAgents = (): {
  allAgents: Agent[];
  filteredAgents: Agent[];
  agentTypes: AgentSubType[];
  selectedAgentType: AgentTypeAll;
  setSelectedAgentType: (agentType: AgentTypeAll) => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
  searchVisibility: boolean;
  setSearchVisibility: (visibility: boolean) => void;
  loading: boolean;
  errorMsg?: string;
} => {
  const {agentTypes, selectedAgentType, setSelectedAgentType} = useAgentTypes();
  const {searchText, setSearchText} = useAgentSearchText();
  const {searchVisibility, setSearchVisibility} = useAgentSearchVisibility();

  return {
    allAgents: [],
    filteredAgents: [],
    agentTypes,
    selectedAgentType,
    setSelectedAgentType,
    searchText,
    setSearchText,
    searchVisibility,
    setSearchVisibility,
    loading: false,
    errorMsg: undefined,
  };
};
