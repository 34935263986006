import React from 'react';
import {Tooltip, TooltipProps} from '@mui/material';
import {px2rem} from 'theme';
import {FlexBox} from 'components';
import {HealthDetails, HealthTitle} from './components';
import {HealthIndicator} from './components/HealthIndicator';
import {useSystemHealth} from './hooks';

const slotProps: TooltipProps['slotProps'] = {
  tooltip: {
    sx: {
      marginTop: `${px2rem(20)}!important`,
      borderRadius: `${px2rem(16)}!important`,
      padding: `${px2rem(16)}!important`,
    },
  },
};

export const SystemHealthWidget: React.FC = () => {
  const {status, data} = useSystemHealth();

  const Title = React.useCallback(() => {
    return (
      <FlexBox>
        <HealthTitle status={status} />
        <HealthDetails data={data} />
      </FlexBox>
    );
  }, [data, status]);

  return (
    <Tooltip title={<Title />} placement="bottom" slotProps={slotProps}>
      <FlexBox width={50} height={50} center right>
        <HealthIndicator status={status} size="medium" />
      </FlexBox>
    </Tooltip>
  );
};
