import {createContext, useContext, useMemo} from 'react';
import {CodeList, CodeListId} from 'api';

export type CodelistRestrictor = (codelist: CodeList) => CodeList;

/**
 * Context providing a function to restrict code lists
 */
export type RestrictedCodelistsContextType = {
  getRestrictedCodelist: CodelistRestrictor;
  getLocalCodelist: (id: CodeListId) => CodeList | undefined;
};

const notRestrictedCodelistsContext: RestrictedCodelistsContextType = {
  getRestrictedCodelist: (codelist: CodeList) => codelist,
  getLocalCodelist: () => undefined,
};

export const RestrictedCodelistsContext =
  createContext<RestrictedCodelistsContextType>(notRestrictedCodelistsContext);

export const useRestrictedCodelistsContext = () => {
  return useContext(RestrictedCodelistsContext);
};

export const useRestrictedCodelistsContextProviderValue = (
  restrictCodelist: CodelistRestrictor,
  getLocalCodelist?: (id: CodeListId) => CodeList | undefined,
): RestrictedCodelistsContextType => {
  const {getLocalCodelist: getOuterLocalCodelist} =
    useRestrictedCodelistsContext();
  return useMemo(
    () => ({
      getRestrictedCodelist: restrictCodelist,
      getLocalCodelist: (id: CodeListId) =>
        getLocalCodelist?.(id) ?? getOuterLocalCodelist(id),
    }),
    [getLocalCodelist, getOuterLocalCodelist, restrictCodelist],
  );
};
