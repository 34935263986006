import get from 'lodash/get';
import set from 'lodash/set';
import {Configuration} from 'configuration';

const callCount = {};

export const getShouldFail = (
  method: string,
  subdir: string,
  config = Configuration.api.mock.failure,
): boolean => {
  if (Configuration.env !== 'local') {
    return false;
  }

  const removeStartSlash = subdir[0] === '/' ? subdir.substring(1) : subdir;
  const removeParam =
    removeStartSlash.indexOf('/{') > 0
      ? removeStartSlash.substring(0, removeStartSlash.indexOf('/{'))
      : removeStartSlash;
  const trimmed = removeParam;
  const path = `${trimmed.split('/').join('.')}.${method.toLowerCase()}`;
  const rate = get(config, path);
  if (rate === undefined) return false;

  const count = (get(callCount, path) ?? config.seed) + 1;
  const shouldFail = count >= rate;
  set(callCount, path, shouldFail ? 0 : count);

  return shouldFail;
};
