import React from 'react';
import {AgentSubType} from 'types';
import {NationalAgentType} from 'api/types';
import {IconButton, Layout} from 'components';
import {AddAgentButton} from 'editors';

type Props = {
  agentTypes: AgentSubType[];
  onSearch: () => void;
  onAdd: (type: AgentSubType) => void;
  onAddWizard: (type: NationalAgentType) => void;
};

export const AgentsToolbar: React.FC<Props> = ({
  agentTypes,
  onSearch,
  onAdd,
  onAddWizard,
}) => {
  return (
    <Layout horizontal adjustRight flex={1}>
      <IconButton icon="Search" onClick={onSearch} />
      <AddAgentButton
        agentTypes={agentTypes}
        onAdd={onAdd}
        onAddWizard={onAddWizard}
      />
    </Layout>
  );
};
