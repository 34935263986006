import {assert} from 'assert-ts';
import {Agent, GetTokens, NationalAgent, NationalAgentType} from './types';
import {Schemas} from './dto.generated';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpDelete, httpGet, httpPost, httpPut} from './http/ebba';
import {mapAgentDto, mapToPostPutAgentDto} from './mappers';
import {mapNationalAgentDto} from './mappers/mapNationalAgentDto';

export const getAgent = (
  agentId: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<Agent> => {
  return exceptionToPromiseReject(() =>
    httpGet<Schemas.AgentDto>({
      subdir: 'agent/{id}',
      subdirParams: {id: agentId},
      getTokens,
      mock,
    }).then(mapAgentDto),
  );
};

export const getAgentsByNationalId = (
  nationalIds: string[],
  getTokens: GetTokens,
  mock?: boolean,
): Promise<Agent[]> => {
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.AgentDto[], string[]>({
      subdir: 'agent/nationalId',
      body: nationalIds,
      getTokens,
      mock,
    }).then(result => result.map(mapAgentDto)),
  );
};

/**
 * bbOnly: Only save to Bokbasen, not National Agent Registry
 */
export type SaveAgentOptions = 'bbOnly' | undefined;

export const putAgent = (
  agent: Agent,
  options: SaveAgentOptions,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<Agent> => {
  return exceptionToPromiseReject(() =>
    httpPut<Schemas.AgentDto>({
      subdir: 'agent/{id}',
      subdirParams: {id: assert(agent.id, 'agent.id is required')},
      body: mapToPostPutAgentDto('PUT', agent, options === 'bbOnly'),
      getTokens,
      mock,
    }).then(mapAgentDto),
  );
};

export const postAgent = (
  agent: Agent,
  options: SaveAgentOptions,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<Agent> => {
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.AgentDto>({
      subdir: 'agent',
      body: mapToPostPutAgentDto('POST', agent, options === 'bbOnly'),
      getTokens,
      mock,
    }).then(mapAgentDto),
  );
};

export const deleteAgent = (
  id: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<void> => {
  return exceptionToPromiseReject(() =>
    httpDelete({
      subdir: 'agent/{id}',
      subdirParams: {id},
      getTokens,
      mock,
    }),
  );
};

export const searchAgentInNationalRegistry = (
  agentType: NationalAgentType,
  name: string | undefined,
  other: string | undefined,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<NationalAgent[]> => {
  return exceptionToPromiseReject(() =>
    httpPost<
      Schemas.AuthorityRegistryWrapperDto[],
      Schemas.AuthorityRegistryQueryDto
    >({
      subdir: `agent/search/${agentType.toLowerCase()}`,
      body: {
        name: name?.replaceAll(':', ''), // name: 'hamsun',
        other: other?.replaceAll(':', ''), // colon not allowed in authority registry search
      },
      getTokens,
      mock,
    }).then(
      data =>
        (data ?? [])
          .map(mapNationalAgentDto)
          .filter(a => !!a) as NationalAgent[],
    ),
  );
};
