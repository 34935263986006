import {Persist_Rehydrate} from '../../store/types';
import {HeadDefaultState, HeadState} from './types';
import {logWarning} from '../../logging';
import {deepMergeStates} from '../utils/deepMergeStates';
import {HeadAction} from './actions';

export const headReducer = (
  state: HeadState = HeadDefaultState,
  action: HeadAction | Persist_Rehydrate,
): HeadState => {
  switch (action.type) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    case 'persist/REHYDRATE': {
      const mergedState = action.payload
        ? deepMergeStates(action.payload['head'], state, logWarning)
        : state;
      return mergedState;
    }

    case 'SET_TITLE': {
      return {
        ...state,
        title: action.payload,
      };
    }

    case 'SET_DESCRIPTION': {
      return {
        ...state,
        description: action.payload,
      };
    }
  }

  return state;
};
