import assert from 'assert-ts';
import {Filter, FilterOperation, FilterOperator} from '../types';
import {Schemas} from '../dto.generated';
import {swapCustomFilters} from '../functions/swapCustomFilters';

export const mapFilters = (dto: Schemas.Filter[]): Filter[] => {
  const filters: Filter[] = [];

  dto.forEach(d => {
    const i = filters.findIndex(
      f => f.name === d.name && f.operation === d.operation,
    );
    // If not exist, add new entry.
    if (i === -1) {
      filters.push({
        operation: d.operation,
        name: d.name,
        values: d.value ? [d.value] : undefined,
      });
    }

    // If exist, add only value.
    if (i !== -1 && d.value) {
      filters[i].values = [...(filters[i].values ?? []), d.value];
    }
  });

  return swapCustomFilters(filters);
};

export const mapToFilters = (
  filters: Filter[],
  keepEmpty: boolean,
): Schemas.Filter[] => {
  const dataFilters: Schemas.Filter[] = [];

  let first = true;

  filters.forEach(filter => {
    const includeAnyway =
      keepEmpty ||
      filter.operation === 'NULL' ||
      filter.operation === 'NOT_NULL';

    first = true;

    if (filter.operation === 'BETWEEN') {
      if (!filter.values?.length && includeAnyway) {
        dataFilters.push({
          operation: 'GREATER_THAN_EQUAL',
          name: filter.name,
          operator: 'AND',
        });
        dataFilters.push({
          operation: 'LESS_THAN_EQUAL',
          name: filter.name,
          operator: 'AND',
        });
      } else {
        if (filter?.values?.length) {
          dataFilters.push({
            operation: 'GREATER_THAN_EQUAL',
            name: filter.name,
            operator: 'AND',
            value: filter.values[0],
          });
          dataFilters.push({
            operation: 'LESS_THAN_EQUAL',
            name: filter.name,
            operator: 'AND',
            value: filter.values[1],
          });
        }
      }
    } else if (!filter.values?.length && includeAnyway) {
      dataFilters.push({
        operation: filter.operation,
        name: filter.name,
        operator: getOperator(first, filter.operation),
        value: undefined,
      });
    } else {
      filter.values?.forEach(value => {
        assert(
          filter.operation !== 'BETWEEN',
          'Expected: Between to be handled earlier.',
        );

        dataFilters.push({
          operation: filter.operation,
          name: filter.name,
          operator: getOperator(first, filter.operation),
          value,
        });
        first = false;
      });
    }
  });

  return dataFilters;
};

const getOperator = (
  first: boolean,
  operation: FilterOperation,
): FilterOperator => {
  return first ? 'AND' : negativeOperation.includes(operation) ? 'AND' : 'OR';
};

const negativeOperation: FilterOperation[] = [
  'NOT_EQUAL',
  'NOT_NULL',
  'DOES_NOT_CONTAIN',
  'DOES_NOT_BEGIN_WITH',
  'DOES_NOT_END_WITH',
];
