import {CodeList, CodeListRef} from 'api/types';

export const getProductFormText = ({
  code,
  detailsCode,
  codelists,
}: {
  /** ProductForm code */
  code?: string;
  /** ProductFormDetails code */
  detailsCode?: string;
  codelists: {
    productFormOverride?: CodeList;
    productForm?: CodeList;
    productFormDetails?: CodeList;
  };
}): string | undefined => {
  if (!code) {
    return undefined;
  }

  const details =
    code === CodeListRef.PRODUCT_FORM['E-bok']
      ? detailsCode
        ? codelists.productFormDetails?.codes.find(c => c.code === detailsCode)
            ?.value ?? detailsCode
        : detailsCode
      : undefined;
  const detailsPostfix = details ? ` (${details})` : '';

  /** Use override if exists. */
  if (codelists.productFormOverride) {
    const override = codelists.productFormOverride.codes.find(
      c => c.code === code,
    );
    if (override) {
      return `${override.value}${detailsPostfix}`;
    }
  }

  /** Use normal codelist value if override is not present. */
  if (codelists.productForm) {
    const productForm = codelists.productForm.codes.find(c => c.code === code);
    if (productForm) {
      return `${productForm.value}${detailsPostfix}`;
    }
  }

  /** Fallback: Just use the code directly. */
  return `${code}${detailsPostfix}`;
};
