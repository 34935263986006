import {PartValueRenderer} from 'schema/types';
import {CopyToClipboard, Link} from 'components';

export const EanLink: PartValueRenderer = ({value}) => {
  const firstValue =
    Array.isArray(value) && value.length > 0 ? value[0] : value;

  return !firstValue || typeof firstValue !== 'string' ? null : (
    <>
      <Link to={`/metadata/ean/${firstValue}`} title={String(value)} />
      <CopyToClipboard text={String(firstValue)} size={'small'} />
    </>
  );
};
