import {ThesaurusNode} from 'api/types';
import {ThesaurusValue} from 'schemaDefinition/types';
import {SearchMatch} from 'services/thesaurus/types';
import {getSearchMatches} from './getSearchMatches';

export const searchThesaurus = (
  query: string,
  thesaurusValue: ThesaurusValue | undefined,
  getDisplayCode: (node: ThesaurusNode) => string | undefined,
  thesaurus: ThesaurusNode,
  ancestors: ThesaurusNode[] = [],
): SearchMatch[] => {
  const itemMatches = getSearchMatches(
    query,
    thesaurusValue,
    getDisplayCode,
    thesaurus,
    ancestors,
  );
  const subPath = [...ancestors, thesaurus];
  const childrenMatches =
    thesaurus.children
      ?.map(child =>
        searchThesaurus(query, thesaurusValue, getDisplayCode, child, subPath),
      )
      .flatMap(childMatches => childMatches) ?? [];
  const result = [...itemMatches, ...childrenMatches];
  // If on root level, remove duplicate nodes
  if (ancestors.length === 0) {
    const unique: {[id: string]: SearchMatch} = {};
    result.forEach(match => {
      unique[match.node.id] = unique[match.node.id] ?? match;
    });
    return Object.values(unique);
  }

  return result;
};
