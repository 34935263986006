import {useEffect, useRef} from 'react';
import {HttpError} from 'api/http/types';
import {LoadStatus} from 'api/types';
import {TranslationKey} from 'localization/context/types';
import {useSnacks} from 'components/snacks';
import {useLoadErrorMessage} from './useLoadErrorMessage';

type Severity = 'error' | 'warning';

export const useLoadError = (
  status: LoadStatus,
  errorOrKey: TranslationKey | HttpError | undefined,
  severity: Severity = 'error',
) => {
  const {errorSnack, warningSnack} = useSnacks();
  const message = useLoadErrorMessage(status, errorOrKey);
  const prevStatusRef = useRef<LoadStatus>();

  useEffect(() => {
    if (status !== prevStatusRef.current) {
      prevStatusRef.current === status;
      if (status === 'Failed') {
        if (severity === 'error') {
          errorSnack(message ?? 'missing message');
        } else {
          warningSnack(message ?? 'missing message');
        }
      }
    }
  }, [errorSnack, errorOrKey, severity, status, warningSnack, message]);
};
