import {Thesaurus} from 'api/types';
import {addNodeIdWithDuplicatesToEditValue} from './addNodeIdWithDuplicatesToEditValue';

/**
 * Adds given node id and any ids of duplicate nodes (nodes with the same code)
 * to the edit value.
 * Any related ids, i.e. ids of ancestors or children of added ids, are removed.
 * Any duplicates of removed ids are also removed.
 */
export const addNodeIdAndDuplicateIdsToEditValue = (
  nodeId: string,
  value: string[],
  thesaurus: Thesaurus,
  duplicateIdToIds: Map<string, string[]>,
): string[] => {
  let newValue = addNodeIdWithDuplicatesToEditValue(
    nodeId,
    value,
    thesaurus,
    duplicateIdToIds,
  );

  // Find duplicates of removed ids
  const removedIds = value.filter(id => !newValue.includes(id));
  const duplicatesOfRemovedIds = removedIds.flatMap(
    id => duplicateIdToIds.get(id) || [],
  );

  // Also remove duplicate ids
  newValue = newValue.filter(id => !duplicatesOfRemovedIds.includes(id));

  return newValue;
};
