import {assert} from 'assert-ts';
import {ManifestationV4} from 'api/types';
import {MetadataEditState} from '../types';

export const assertManifestation = (
  manifestationId: string,
  state: Pick<MetadataEditState, 'metadata'>,
): ManifestationV4 => {
  return assert(
    state.metadata?.manifestations.find(e => e.id === manifestationId),
    `metadataEditReducer.assertManifestation: manifestation not found`,
    {manifestationId},
  );
};
