import {ManifestationStatus} from 'api/types';
import {ColorPalette} from 'theme';
import {getManifestationStatusColorName} from './getManifestationStatusColorName';

export const getManifestationStatusColor = (
  status: ManifestationStatus,
): string => {
  const name = getManifestationStatusColorName(status);
  return ColorPalette.status[name];
};
