import {useMemo} from 'react';
import {SxProps} from '@mui/material';
import {useLocalization} from 'localization';
import {useShortcut} from 'shortcuts';
import {Text} from 'components';
import {useWorkToggleContext} from '../../../contexts';

export const WorkCardTitle: React.FC = () => {
  const {t} = useLocalization();

  const {workHidden, onToggle} = useWorkToggleContext();

  useShortcut('toggleWork', () => onToggle());

  const sx: SxProps = useMemo(
    () => ({
      cursor: 'pointer',
      writingMode: workHidden ? 'vertical-lr' : 'inherit',
    }),
    [workHidden],
  );

  return (
    <Text
      variant="h4"
      textTransform="capitalize"
      onClick={() => onToggle()}
      sx={sx}>
      {t('page.metadata.work.title')}
    </Text>
  );
};
