import cloneDeep from 'lodash/cloneDeep';
import {CustomFilterType, Filter} from '../types';
import {getCustomFilters} from './getCustomFilters';

export const swapCustomFilters = (filters: Filter[]) => {
  const withCustom = cloneDeep(filters);

  getCustomFilters.forEach(customFilter => {
    if (
      isCustomFilter(
        customFilter.name,
        customFilter.operation,
        customFilter.values,
        filters,
      )
    ) {
      for (let i = 0; i < filters.length; i++) {
        if (
          withCustom[i].name === customFilter.name &&
          withCustom[i].operation === customFilter.operation
        ) {
          // Removes custom filter value not marked as custom filter values
          const newValues = withCustom[i].values?.filter(
            v => !customFilter.values?.includes(v),
          );

          // Remove the entry entirely if no values
          if (newValues?.length === 0) {
            delete withCustom[i];
          } else {
            withCustom[i].values = newValues;
          }

          // Adds the custom filter instead.
          withCustom.push(customFilter);
        }
      }
    }
  });

  return withCustom.filter(w => w);
};

const isCustomFilter = (
  customFilterName: CustomFilterType['name'],
  customFilterOperation: CustomFilterType['operation'],
  customFilterValues: CustomFilterType['values'],
  filters: Filter[],
) => {
  return customFilterValues?.every(value => {
    let exists = false;
    filters.forEach(filter => {
      if (
        filter.name === customFilterName &&
        filter.operation === customFilterOperation &&
        filter.values?.includes(value)
      ) {
        exists = true;
      }
    });
    return exists;
  });
};
