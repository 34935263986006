import {CodeListMap} from 'api/types';
import {SchemaReference} from 'schemas/types';
import {CodelistRestrictor} from 'services/codeLists/contexts/RestrictedCodelistsContext';
import {useRestrictedCodelists} from 'services/codeLists/hooks/useRestrictedCodelists';
import {useSchemaCodelistIds} from 'schema/hooks/useSchemaCodelistIds';
import {useSchema} from 'schemas/hooks/useSchema';

export function useRestrictedCodelistsForSchema(
  codelistRestrictor: CodelistRestrictor,
  schemaRef: SchemaReference,
): CodeListMap {
  const schema = useSchema(schemaRef);
  const codelistIds = useSchemaCodelistIds(schema);
  return useRestrictedCodelists(codelistRestrictor, codelistIds);
}
