import React, {useMemo} from 'react';
import {ThesaurusesGate} from 'services/thesaurus';
import {ThesaurusEdit} from 'feature';
import {useThesaurusEditContext} from './hooks';

type Props = {
  onClose: () => Promise<void>;
};
export const ThesaurusEditContainer: React.FC<Props> = ({onClose: _}) => {
  const {sourceField, setSourceValue, closeExtension} =
    useThesaurusEditContext();
  const {
    thesaurusIds,
    showCode,
    selectableNodeTypes,
    focusNodeCodeWithTimestamp,
  } = useMemo(
    () => ({
      thesaurusIds: sourceField?.fieldConfig?.thesaurusId
        ? [sourceField?.fieldConfig?.thesaurusId]
        : undefined,
      showCode: sourceField?.fieldConfig?.showCode,
      selectableNodeTypes: sourceField?.fieldConfig?.selectableNodeTypes,
      focusNodeCodeWithTimestamp:
        sourceField?.fieldConfig?.focusNodeCodeWithTimestamp,
    }),
    [sourceField?.fieldConfig],
  );

  return (
    <ThesaurusesGate thesauruses={thesaurusIds}>
      {thesaurusIds ? (
        <ThesaurusEdit
          // Set key to force remount when thesaurusIds[0] change, e.g. switch between thema and emneord
          key={thesaurusIds[0]}
          thesaurusId={thesaurusIds[0]}
          showCode={showCode}
          selectableNodeTypes={selectableNodeTypes}
          focusNodeCodeWithTimestamp={focusNodeCodeWithTimestamp}
          fieldConfig={sourceField?.fieldConfig}
          thesaurusValue={sourceField?.currentValue}
          onSetSourceValue={setSourceValue}
          onClose={closeExtension}
        />
      ) : null}
    </ThesaurusesGate>
  );
};
