import React, {useCallback, useMemo, useState} from 'react';
import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {Paper, PaperProps, Popper, PopperProps} from '@mui/material';
import Autocomplete, {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import {assert} from 'assert-ts';
import {ColorPalette} from 'theme';
import {Code} from 'api';
import {moveItem, valueAsArrayOf} from 'services/utils';
import {PropsRaw} from './types';
import {Layout} from '../../../layout';
import {Text} from '../../../text';
import '../CodeListField.scss';
import {getCodeLabel} from '../functions';
import {useClassName} from '../hooks';
import {InputTextField} from './CodeInputTextField';
import {DraggableChip} from './DraggableChip';
import {SortableInput} from './SortableInput';
import {CodeInputContext, useCodeInputContextProviderValue} from './contexts';

export const SortableCodeListFieldRaw: React.FC<PropsRaw> = ({
  name,
  id,
  placeholder,
  value,
  cardinality,
  showCode,
  readonly,
  error,
  options,
  fullWidth,
  renderOptionContent,
  selectedCodes,
  onChange,
  onBlur,
  'data-cy': dataCy,
}) => {
  const handleChange = useCallback(
    (_: unknown, selectedOptions: Code | Code[] | null) => {
      if (onChange) {
        selectedOptions === null
          ? onChange(null)
          : Array.isArray(selectedOptions)
            ? onChange(selectedOptions.map(o => o.code))
            : onChange(selectedOptions?.code);
      }
    },
    [onChange],
  );

  const enabledOptions = useMemo(() => {
    const enabled = options.some(o => o.disabled || o.deactivated)
      ? options.filter(o => !(o.disabled || o.deactivated))
      : options;

    // Add inactive options if value is set already.
    // Autocomplete options should consist of all values provided
    const valuesAsOptions = options.filter(
      o => value?.includes(o.code) && (o.disabled || o.deactivated),
    );
    valuesAsOptions.forEach(o => {
      enabled.push(o);
    });

    return enabled;
  }, [options, value]);

  const handleMove = useCallback(
    (fromIndex: number, toIndex: number) => {
      const codes = assert(
        valueAsArrayOf<string>(value),
        'handleMove: value expected',
      );
      const updatedCodes = moveItem(codes, fromIndex, toIndex);
      onChange && onChange(updatedCodes);
    },
    [onChange, value],
  );

  const codeInputContext = useCodeInputContextProviderValue(
    value,
    cardinality,
    onChange,
  );

  const renderTags = useCallback(
    (
      tags: Code[],
      getTagProps: AutocompleteRenderGetTagProps,
    ): React.ReactNode => {
      return (
        <>
          {tags.map((t, idx) => {
            // const tagProps = getTagProps({index: idx});
            // console.log(tagProps);
            return (
              <DraggableChip
                key={t.code}
                option={t}
                showCode={showCode}
                index={idx}
                getTagProps={getTagProps}
                onMove={handleMove}
              />
              // onDelete={handleDelete}

              // <Chip key={t.code} label={t.value} />
            );
          })}
        </>
      );
    },
    [handleMove, showCode],
  );

  const getOptionLabel = useCallback(
    (option: Code) => getCodeLabel(option, showCode),
    [showCode],
  );

  const isOptionEqualToValue = useCallback((option: Code, value: Code) => {
    return option.code === value.code;
  }, []);

  const getOptionDisabled = useCallback(
    (option: Code) => !!(option.disabled || option.deactivated),
    [],
  );

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: Code) => {
      return (
        <Box component="li" {...props} key={option.code}>
          {renderOptionContent ? (
            renderOptionContent(option)
          ) : (
            <Text variant="body2">{getCodeLabel(option, showCode)}</Text>
          )}
        </Box>
      );
    },
    [renderOptionContent, showCode],
  );

  const [activeId, setActiveId] = useState<string | undefined>(undefined);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      // Delay drag to allow delete button to be clicked
      activationConstraint: {
        delay: 250,
        tolerance: 50,
      },
    }),
    // useSensor(KeyboardSensor, {
    //   coordinateGetter: sortableKeyboardCoordinates,
    // }),
  );
  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id.toString());
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const {active, over} = event;
      if (over && active.id !== over.id) {
        const codes = assert(
          valueAsArrayOf<string>(value),
          'handleDragEnd: value expected',
        );
        const fromIdx = codes.findIndex(c => c === active.id);
        const toIdx = codes.findIndex(c => c === over.id);
        const updatedCodes = moveItem(codes, fromIdx, toIdx);
        onChange && onChange(updatedCodes);
      }
      setActiveId(undefined);
    },
    [onChange, value],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      // const hasCodes = Array.isArray(value) && value.length > 0;
      return cardinality === 'single' ? (
        <div className="sizer">
          <InputTextField
            value={value}
            name={name}
            cardinality={cardinality}
            readonly={readonly}
            error={error}
            placeholder={placeholder}
            params={params}
            onBlur={onBlur}
          />
          <div className="ghost">
            {/* {options.find(o => o.code === value)?.value ?? ''} */}
            {params.inputProps.value || placeholder || ''}
          </div>
        </div>
      ) : (
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}>
          <SortableInput
            activeId={activeId}
            options={options}
            value={value}
            name={name}
            cardinality={cardinality}
            readonly={readonly}
            error={error}
            placeholder={placeholder}
            params={params}
            onBlur={onBlur}
            handleKeyCapture={codeInputContext.handleKeyCapture}
            inputState={codeInputContext.state}
          />
        </DndContext>
      );
    },
    [
      value,
      cardinality,
      name,
      readonly,
      error,
      placeholder,
      onBlur,
      sensors,
      handleDragStart,
      handleDragEnd,
      activeId,
      options,
      codeInputContext.handleKeyCapture,
      codeInputContext.state,
    ],
  );

  const PopperComponent = useCallback(
    (props: PopperProps) => (
      <Popper
        data-cy={`popper-${name}`}
        {...props}
        style={{
          ...props.style,
          // Offset popper vertically from input field, not to overlap borders
          borderBottom: '4px solid transparent',
          borderTop: '8px solid transparent',
        }}
      />
    ),
    [name],
  );

  const PaperComponent = useCallback(
    (props: PaperProps) => (
      <Paper
        data-cy={`paper-${name}`}
        {...props}
        style={{
          ...props.style,
          // Outline to hide borders of other fields underneath popper
          outline: `2px solid ${ColorPalette.primary.warmGreen}`,
          minWidth: 'min-content',
        }}
      />
    ),
    [name],
  );

  const className = useClassName(cardinality, fullWidth, selectedCodes, error);

  const placedholderSx = useMemo(() => {
    return error === true
      ? {
          background: ColorPalette.error20,
          border: `1.25px solid ${ColorPalette.error}`,
          '&:hover': {
            border: `2px solid ${ColorPalette.error}`,
          },
        }
      : error === 'warning'
        ? {
            background: ColorPalette.warning40,
            border: `1.25px solid ${ColorPalette.warning}`,
            '&:hover': {
              border: `2px solid ${ColorPalette.warning}`,
            },
          }
        : {
            background: ColorPalette.beige,
          };
  }, [error]);

  return cardinality === 'single' ? (
    <Autocomplete
      className={className}
      id={id}
      options={enabledOptions}
      multiple={false}
      disabled={readonly}
      value={selectedCodes as Code}
      clearIcon={null}
      clearOnBlur
      forcePopupIcon={false}
      onChange={handleChange}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      isOptionEqualToValue={isOptionEqualToValue}
      size="small"
      renderOption={renderOption}
      renderInput={renderInput}
      PopperComponent={PopperComponent}
      PaperComponent={PaperComponent}
    />
  ) : (
    <CodeInputContext.Provider value={codeInputContext}>
      <Layout position="relative" data-cy={`sortable-codelist-fieldraw`}>
        {/* Add Layout element as background to placeholder when null/empty */}
        {selectedCodes === null || (selectedCodes as Code[]).length === 0 ? (
          <Layout
            position="absolute"
            top="0"
            left="0"
            height="34px"
            minWidth="4em"
            borderRadius={1}
            sx={placedholderSx}>
            <Layout px={1} hidden>
              <Text variant="body1" data-cy={'placeholder'}>
                {placeholder}
              </Text>
            </Layout>
          </Layout>
        ) : null}
        <Autocomplete
          className={className}
          id={id}
          options={enabledOptions}
          multiple={true}
          disabled={readonly}
          value={selectedCodes as Code[]}
          clearIcon={null}
          clearOnBlur
          forcePopupIcon={false}
          onChange={handleChange}
          renderTags={renderTags}
          getOptionLabel={getOptionLabel}
          getOptionDisabled={getOptionDisabled}
          isOptionEqualToValue={isOptionEqualToValue}
          size="small"
          renderOption={renderOption}
          renderInput={renderInput}
          PopperComponent={PopperComponent}
          PaperComponent={PaperComponent}
          data-cy={dataCy}
        />
      </Layout>
    </CodeInputContext.Provider>
  );
};
