import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const SnoozeIcon: React.FC<IconProps> = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M19.67 9.74C19.4016 10.4715 18.966 11.1302 18.3979 11.6635C17.8297 12.1968 17.1448 12.5899 16.3978 12.8115C15.6508 13.0331 14.8623 13.077 14.0953 12.9398C13.3283 12.8026 12.6039 12.488 11.9801 12.0211C11.3562 11.5542 10.8501 10.948 10.5021 10.2508C10.1542 9.55362 9.97397 8.78475 9.9759 8.00554C9.97784 7.22634 10.1619 6.45837 10.5133 5.76292C10.8647 5.06746 11.3738 4.46377 12 4C10.3199 3.99913 8.68211 4.52725 7.31899 5.50947C5.95588 6.49168 4.9366 7.87813 4.40572 9.47218C3.87483 11.0662 3.85929 12.787 4.3613 14.3903C4.86331 15.9937 5.85738 17.3983 7.20253 18.405C8.54769 19.4117 10.1756 19.9693 11.8555 19.9988C13.5354 20.0282 15.1819 19.5281 16.5615 18.5692C17.9412 17.6104 18.9839 16.2415 19.5418 14.6567C20.0998 13.0719 20.1446 11.3517 19.67 9.74ZM12 18C10.7831 18.0018 9.59439 17.6335 8.59166 16.944C7.58892 16.2545 6.81954 15.2765 6.3856 14.1395C5.95167 13.0026 5.87369 11.7606 6.16202 10.5784C6.45035 9.39612 7.09136 8.32949 7.99999 7.52C7.99999 7.68 7.99999 7.84 7.99999 8C7.99999 9.85652 8.73749 11.637 10.0502 12.9497C11.363 14.2625 13.1435 15 15 15C15.8295 14.9962 16.6519 14.8473 17.43 14.56C16.9444 15.5894 16.176 16.4594 15.2145 17.0685C14.253 17.6776 13.1382 18.0007 12 18Z" />
    </SvgIcon>
  );
};
