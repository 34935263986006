import {LocalizationContextType} from 'localization/context/types';
import {FieldRootKeys} from '../../../schemaDefinition/functions/types';

export const getFieldString = (
  fieldRootKeys: FieldRootKeys | undefined,
  required: boolean,
  suffix: string,
  {t, tryT}: LocalizationContextType,
): string | undefined => {
  const reqT = required ? t : tryT;
  return fieldRootKeys
    ? fieldRootKeys.schemaScoped
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tryT((fieldRootKeys.schemaScoped + suffix) as any) ??
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        reqT((fieldRootKeys.global + suffix) as any)
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        reqT((fieldRootKeys.global + suffix) as any)
    : undefined;
};
