import React from 'react';
import {AgentSubType} from 'types';
import {PartNameVariant, Valx} from 'schemaDefinition/types';
import {BasePartSimpleProps} from './types';
import {DataFormCorporationNameVariantList} from './DataFormCorporationNameVariantList';
import {DataFormEventNameVariantList} from './DataFormEventNameVariantList';
import {DataFormPersonNameVariantList} from './DataFormPersonNameVariantList';
import {DataFormPublisherNameVariantList} from './DataFormPublisherNameVariantList';

type DataFormNameVariantListProps = BasePartSimpleProps & {
  part: PartNameVariant<Valx>;
  // error?: boolean;
  // helperText?: string;
};

const entityMap: {
  [key in AgentSubType]: React.FC<DataFormNameVariantListProps>;
} = {
  person: DataFormPersonNameVariantList,
  publisher: DataFormPublisherNameVariantList,
  corporation: DataFormCorporationNameVariantList,
  event: DataFormEventNameVariantList,
};

export const DataFormNameVariantList: React.FC<
  DataFormNameVariantListProps
> = props => {
  return entityMap[props.part.agentType](props);
};
