import {useMemo} from 'react';
import {CodeListId} from 'api/types';
import {Part} from 'schemaDefinition/types';
import {
  getPartCodelistId,
  getSchemaListCodelistIds,
} from 'schemaDefinition/functions';
import {LinkedRoleCodeListId} from 'schemaDefinition/linkTypes';

export const usePartCodelistIds = <TVal>(
  part: Part<TVal>,
): (CodeListId | LinkedRoleCodeListId)[] | undefined => {
  return useMemo(() => {
    if (part.type === 'schema') {
      return getSchemaListCodelistIds([part]);
    }

    return getPartCodelistId(part);
  }, [part]);
};
