import React from 'react';
import {Step, StepButton, StepLabel, Stepper} from '@mui/material';
import {Layout} from 'components/layout';
import {StepProps} from './Step';

export type WizardStepperProps = {
  showStepNumbers?: boolean;
  activeStep: number;
  steps: Omit<StepProps, 'content'>[];
  onStepSelected?: (stepIndex: number) => void;
};

export const WizardStepper: React.FC<WizardStepperProps> = ({
  activeStep,
  steps,
  onStepSelected,
}) => {
  return (
    <Layout adjustLeft>
      <Stepper nonLinear activeStep={activeStep} connector={null}>
        {steps.map(({label, ...stepProps}, index) => (
          <Step
            key={label}
            {...stepProps}
            active={index === activeStep}
            data-cy={`step-${index + 1}`}>
            <StepButton
              disableRipple
              onClick={
                onStepSelected ? () => onStepSelected(index) : undefined
              }>
              <StepLabel>{`${index + 1}. ${label}`}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Layout>
  );
};
