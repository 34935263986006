import {useSavedExpression} from 'services/data';
import {ExpressionCardProps} from '../types';
import {useExpressionEditStateCore} from './useExpressionEditStateCore';

export const useExpressionEditState = ({
  workId,
  expressionId,
  // Given readonly prop, i.e. edit mode not available when readonly only
  readonly,
}: ExpressionCardProps) => {
  const savedExpression = useSavedExpression(expressionId);

  return useExpressionEditStateCore({
    workId,
    expressionId,
    stableExpression: savedExpression,
    readonly,
  });
};
