import {useCallback} from 'react';
import {Button, SxProps} from '@mui/material';
import {EntityMainTypes, EntitySubType} from 'types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {Text} from 'components';
import {SearchMainEntityType} from '../types';

type Props<TEntityType> = {
  type: TEntityType;
  isSelected: boolean;
  onSelected?: (type: TEntityType) => void;
};

const baseSx: SxProps = {
  borderRadius: 9999,
  color: ColorPalette.burgundy,
  paddingX: 1.5,
  paddingY: 1,
};

const mainEntitySx: SxProps = {
  ...baseSx,
  backgroundColor: ColorPalette.beige,
};

const subEntitySx: SxProps = {
  ...baseSx,
  backgroundColor: ColorPalette.beige50,
};

const selectedSx: SxProps = {
  borderColor: ColorPalette.burgundy,
  borderWidth: 1,
  borderStyle: 'solid',
};

const sxMap: {
  [isMain in 'main' | 'sub']: {
    [isSelected in 'selected' | 'notSelected']: SxProps;
  };
} = {
  main: {
    selected: {...mainEntitySx, ...selectedSx},
    notSelected: mainEntitySx,
  },
  sub: {
    selected: {...subEntitySx, ...selectedSx},
    notSelected: subEntitySx,
  },
};

export function EntityButton<
  TEntityType extends SearchMainEntityType | EntitySubType,
>({type, isSelected, onSelected}: Props<TEntityType>) {
  const {tLoose} = useLocalization();
  const level = EntityMainTypes.includes(type as SearchMainEntityType)
    ? 'main'
    : 'sub';
  const sx = sxMap[level][isSelected ? 'selected' : 'notSelected'];
  const title = tLoose(
    `general.entity.${type as SearchMainEntityType | EntitySubType}`,
  );
  const handleClick = useCallback(() => {
    onSelected && onSelected(type);
  }, [onSelected, type]);
  return (
    <Button sx={sx} onClick={handleClick}>
      <Text variant="body2">{title}</Text>
    </Button>
  );
}
