import {useCallback, useMemo, useRef, useState} from 'react';
import {
  FieldExtensionContextCore,
  FieldExtensionEnum,
  FieldExtensionUnsubscribeCore,
  SetSourceFieldValue,
  SourceField,
} from '../types';

/**
 * Used by source fields, i.e. fields with extension, and scene extension to communicate:
 * - field -> extension: id of field being edited/searched/etc,
 *   any field value (e.g. currently linked agent) and config (e.g. size) to scene extension
 * - extension -> field: new value edited/selected, or similar, if relevant
 * @param type
 * @returns
 */
export const useFieldExtensionContext = <
  TType extends FieldExtensionEnum,
  TValue,
  TSourceValue = TValue,
  TAction extends string = string,
  TFieldConfig = undefined,
>(
  type: TType,
): FieldExtensionContextCore<
  TType,
  TValue,
  TSourceValue,
  TAction,
  TFieldConfig
> => {
  const [sourceField, setSourceField] =
    useState<SourceField<TValue, TFieldConfig>>();
  const [hasChanges, setHasChanges] = useState(false);

  const sourceFieldRef = useRef<SourceField<TValue, TFieldConfig>>();
  const setSourceValueRef = useRef<
    SetSourceFieldValue<TSourceValue, TAction> | undefined
  >();
  const onExtensionDisconnectedRef = useRef<() => void>();

  const handleClose = useCallback(() => {
    setSourceField(undefined);
    onExtensionDisconnectedRef.current && onExtensionDisconnectedRef.current();
    setSourceValueRef.current = undefined;
    sourceFieldRef.current = undefined;
    onExtensionDisconnectedRef.current = undefined;
  }, []);

  const handleSetSourceValue = useCallback(
    (value: TSourceValue | undefined, action: TAction) => {
      if (sourceField && setSourceValueRef.current) {
        setSourceValueRef.current(value, sourceField.fieldId, action);
      }
    },
    [sourceField],
  );

  const handleSetSourceField = useCallback(
    (
      _sourceField: SourceField<TValue, TFieldConfig> | undefined,
      setSourceValue: SetSourceFieldValue<TSourceValue, TAction>,
      onExtensionDisconnected?: () => void,
    ): FieldExtensionUnsubscribeCore => {
      // Notify previous source field when loosing "focus" (released)
      if (
        onExtensionDisconnectedRef.current &&
        sourceFieldRef.current?.fieldId === _sourceField?.fieldId
      ) {
        onExtensionDisconnectedRef.current();
      }

      setSourceField(_sourceField);
      setHasChanges(false);
      sourceFieldRef.current = _sourceField;
      setSourceValueRef.current = setSourceValue;
      onExtensionDisconnectedRef.current = onExtensionDisconnected;

      // Return unsubscribe function
      return (fieldId: string) => {
        if (
          sourceFieldRef.current === undefined ||
          sourceFieldRef.current?.fieldId === fieldId
        ) {
          handleClose();
          return true;
        }
        return false;
      };
    },
    [handleClose],
  );

  // const contextType = `useFieldExtensionContext.${type}`;
  // useLogMounting(contextType);
  // useLogValue(`${contextType}.hasChanges`, hasChanges);

  return useMemo(() => {
    const extension: FieldExtensionContextCore<
      TType,
      TValue,
      TSourceValue,
      TAction,
      TFieldConfig
    > = {
      type,
      sourceField: sourceField,
      setSourceField: handleSetSourceField,
      hasChanges,
      setHasChanges,
      setSourceValue: handleSetSourceValue,
      closeExtension: handleClose,
    };
    return extension;
  }, [
    handleClose,
    handleSetSourceField,
    handleSetSourceValue,
    hasChanges,
    sourceField,
    type,
  ]);
};
