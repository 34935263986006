import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const AIIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M3.99555 8.9104L2.84755 11.2343L0.277344 11.6107L2.13645 13.4234L1.69956 15.9843L3.99555 14.7712L6.29154 15.9796L5.85465 13.4234L7.71375 11.6107L5.14354 11.2389L3.99555 8.9104Z"
        fill={'currentColor'}
      />
      <path
        d="M10.5439 0.015625L8.94491 3.25244L5.36499 3.77681L7.95444 6.30153L7.34592 9.8685L10.5439 8.17888L13.7419 9.86203L13.1334 6.30153L15.7228 3.77681L12.1429 3.25892L10.5439 0.015625Z"
        fill={'currentColor'}
      />
      ;
    </SvgIcon>
  );
};
