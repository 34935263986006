import {assert} from 'assert-ts';
import {NameVariantValue} from '../types';

export const assertAsNameVariantValue = (
  value: unknown,
  context?: string,
): NameVariantValue | undefined => {
  if (value === undefined || value === null) return undefined;

  if (typeof value === 'object' && !Array.isArray(value)) {
    return value;
  }

  assert.soft(
    false,
    `assertAsNameVariantValue: name variant value expected (${context ?? ''})`,
    {value},
  );

  return undefined;
};
