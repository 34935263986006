import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const AddItemIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M17 11H13V7H11V11H7V13H11V17H13V13H17V11Z"
        transform="translate(4, -2)"
      />
      <path
        d="M4.00012 15.0567L8.70012 10.3467L10.1201 11.7667L6.82012 15.0567L10.1201 18.3467L8.70012 19.7667L4.00012 15.0567Z"
        fill={'currentColor'}
      />
      <path d="M13 14L13 16L6 16L6 14L13 14Z" fill={'currentColor'} />
    </SvgIcon>
  );
};
