import {useMemo} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AppState} from '../store/types';
import {Layout, TopBar} from '../components';
import './App.scss';

const authSelector = (state: AppState) => state.auth;

function App() {
  const authState = useSelector(authSelector);
  const themeColor = '#EAEADE'; // useBackgroundColor();
  const location = useLocation();

  const bodySx = useMemo(
    () => ({
      background: themeColor,
    }),
    [themeColor],
  );

  if (authState.status !== 'Authenticated') {
    return <Navigate to={'/login'} />;
  }

  return (
    <Layout height="100vh">
      <TopBar role="admin" />
      <Layout flex={1} sx={bodySx}>
        {/* Set location key to support reload, i.e. unmount/remount when navigating to same path&search (but with new key) */}
        <Outlet key={location.key} />
      </Layout>
    </Layout>
  );
}

export default App;
