import React, {useCallback, useMemo, useState} from 'react';
import {assert} from 'assert-ts';
import {EventNameVariantFieldLayout} from 'components/fields/types';
import {Code, DataValue, NameVariantValue} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {ColorPalette} from 'theme';
import {assertSimpleRequired} from 'schemaDefinition/functions';
import {valueAsNameVariantArray} from 'services/utils';
import {
  Layout,
  RadioButtonsField,
  SingleValueListField,
  useListOperations,
} from 'components';
import {
  DataFormNameVariantListProps,
  EventNameVariantExtraField as ExtraField,
} from './types';
import {useFormSchemaGlobalScopeContext} from '../../contexts';
import {useDataFormContext} from '../contexts';
import {getBoxLabel} from '../functions';
import {
  useAddItemLabel,
  useEventNameVariantLabelsAndPlaceholders,
  useResolvedDataFormPartConfiguration,
} from '../hooks';
import {EventNameVariant} from './fields';

const nameVariantFieldLayout: EventNameVariantFieldLayout = {
  name: {
    flex: 1,
  },
  description: {
    flex: 1,
  },
  mainForm: {
    flex: 1,
    alignItems: 'flex-end',
  },
};

const ExtraFields: ExtraField[] = ['description'];

const anyExtraField = (value: DataValue): ExtraField | undefined => {
  const nameVariants = valueAsNameVariantArray(value) ?? [];
  return nameVariants.some(v => v.description) ? 'description' : undefined;
};

const newVariant = (extraField: ExtraField | undefined): NameVariantValue => {
  const value: NameVariantValue = {};
  if (extraField) {
    value[extraField] = '';
  }

  return value;
};

export const DataFormEventNameVariantList: React.FC<
  DataFormNameVariantListProps
> = ({part, useValue, valuePath, mode}) => {
  const localization = useLocalization();
  const {id, setEditValue} = useDataFormContext();
  const value = (useValue(valuePath, id, part.name) ?? null) as DataValue;
  const {showWhenReadonlyAndEmpty, hideStructureOperations} =
    useResolvedDataFormPartConfiguration(part);
  const {schema} = useFormSchemaGlobalScopeContext();
  const [extraField, setExtraField] = useState<ExtraField | undefined>(() =>
    anyExtraField(value),
  );

  const extraFieldOptions = useMemo(
    () =>
      ExtraFields.map<Code>(key => ({
        code: key,
        value:
          getBoxLabel(localization, {labelKey: key}, schema) ??
          `Missing: boxlabel for ${key}`,
      })),
    [localization, schema],
  );
  assert(
    value === null || Array.isArray(value),
    'DataForm: "name variants"-value property must be array (or null)',
    {part: part, contextPath: valuePath, dataScope: value},
  );

  const requiredValue = useMemo(
    () =>
      (!value || value.length === 0
        ? [newVariant(extraField)]
        : (value as NameVariantValue[]).map(val => ({
            ...val,
            description:
              (value[0] as NameVariantValue).description ?? undefined,
          }))) as NameVariantValue[],
    [extraField, value],
  );

  const {onAppendValue, onDeleteValue, onMoveValue} =
    useListOperations<NameVariantValue>(
      update => {
        const nextValue = update(requiredValue);
        setEditValue(valuePath, nextValue, part);
        return nextValue;
      },
      () => newVariant(extraField),
    );

  const {contentLabels, fieldLayoutWithPlaceholders} =
    useEventNameVariantLabelsAndPlaceholders(
      extraField,
      nameVariantFieldLayout,
    );

  const addLabel = useAddItemLabel(part);

  const renderCustomActions = useCallback(() => {
    const handleChangeShowExtraField = (
      field: ExtraField | undefined | null,
    ) => {
      const nameVariants = valueAsNameVariantArray(requiredValue);
      setExtraField(field ?? undefined);
      const newValue =
        field === 'description'
          ? nameVariants?.map<NameVariantValue>(nv => ({
              ...nv,
              description: nv.description ?? '',
            }))
          : nameVariants?.map<NameVariantValue>(
              ({description: _d, ...nv}) => nv,
            );
      setEditValue(valuePath, newValue ?? null, part);
    };

    return (
      <Layout horizontal>
        <RadioButtonsField
          value={extraField}
          name={`${valuePath}-showExtraField`}
          horizontal
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          onChange={handleChangeShowExtraField}
          options={extraFieldOptions}
        />
      </Layout>
    );
  }, [
    extraField,
    extraFieldOptions,
    part,
    requiredValue,
    setEditValue,
    valuePath,
  ]);

  return (
    <SingleValueListField<NameVariantValue>
      addLabel={addLabel}
      label={'.'}
      itemBackgroundColor={ColorPalette.enhancedLightBeige}
      itemPadding={1}
      contentLabels={contentLabels}
      name={part.name}
      required={assertSimpleRequired(part.required)}
      readonly={mode === 'read-only'}
      showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
      values={requiredValue}
      getValueKey={idx => `${valuePath}[${idx}]`}
      renderValue={(idx, value, focusableId) => {
        return (
          <EventNameVariant
            focusableId={focusableId}
            part={part}
            value={value as NameVariantValue}
            valuePath={`${valuePath}[${idx}]`}
            setFieldValue={setEditValue}
            isMainForm={idx === 0}
            mode={mode}
            showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
            layout={fieldLayoutWithPlaceholders}
          />
        );
      }}
      renderCustomActions={renderCustomActions}
      onAppendValue={
        hideStructureOperations?.includes('add') ? undefined : onAppendValue
      }
      onDeleteValue={
        hideStructureOperations?.includes('delete') ? undefined : onDeleteValue
      }
      onMoveValue={
        hideStructureOperations?.includes('move') ? undefined : onMoveValue
      }
    />
  );
};
