import {ColumnConfig} from 'schema/table/types';

export const corporationAgentDefaultColumns: ColumnConfig[] = [
  {
    name: 'nameVariants',
    visible: true,
    width: 200,
  },
  {
    name: 'isni',
    visible: true,
    width: 170,
  },
  {
    name: 'nationalId',
    visible: true,
  },
  {
    name: 'validFrom',
  },
  {
    name: 'validTo',
  },
  {
    name: 'internalComments',
  },
  {
    name: 'externalComments',
  },
];
