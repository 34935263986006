import {ShowThesaurusCode} from 'schemaDefinition/types';
import {ThesaurusNode} from 'api';

export const getNodeDisplayCode = (
  node: ThesaurusNode,
  showCodes: ShowThesaurusCode,
  forNodeTypes: string[] | undefined,
): string | undefined => {
  if (!showCodes) {
    return undefined;
  }

  const code = node.code;
  if (!forNodeTypes || (node.type && forNodeTypes.includes(node.type))) {
    return code[code.length - 1] === '-' ? code.replace(/-+$/, '') : code;
  }

  return undefined;
};
