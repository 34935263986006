import {useCallback} from 'react';
import {IconButton} from 'components';
import {CompareActionCompoent} from './types';
import {useDataFormContext} from '../contexts';
import {usePartChangeActionTooltip} from '../hooks';

export const DataFormCompareAddValue: CompareActionCompoent = ({
  valuePath,
  part,
}) => {
  const {setApprovedValue} = useDataFormContext();
  const tooltip = usePartChangeActionTooltip('addItem', part);

  const handleApprove = useCallback(() => {
    setApprovedValue(valuePath, 'addItem', undefined, undefined);
  }, [setApprovedValue, valuePath]);

  return <IconButton icon="Add" onClick={handleApprove} tooltip={tooltip} />;
};
