import {useCallback, useMemo, useState} from 'react';
import debounce from 'lodash/debounce';
import {DataResponse, Filter} from 'api/types';
import {useLocalization} from 'localization';
import {postFilters, postFiltersExport} from 'api/advancedsearch';
import {useGetTokens} from 'services/auth';
import {useSnacks} from 'components';
import {DataFetchType} from '../types';
import {usePaginationState} from '../../../feature';
import {getExportFilename} from '../functions/getExportFilename';

export const useDataFetch = (): DataFetchType => {
  const getTokens = useGetTokens();
  const {errorSnack} = useSnacks();
  const {t} = useLocalization();
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
  const [data, setData] = useState<DataResponse['rows']>([]);

  const pagination = usePaginationState(20);
  const {resetPage} = pagination;

  const searchHandler = useCallback(
    (
      filters: Filter[],
      columns?: string[],
      page: number = 1,
      size: number = 20,
      sort?: string,
    ) => {
      setLoadingSearch(true);

      postFilters(getTokens, filters, columns, page, size, sort)
        .then(response => {
          setData(response.rows);
          resetPage(response.hits, page);
        })
        .catch(error => errorSnack(t('data.searching.failed'), error))
        .finally(() => {
          setLoadingSearch(false);
        });
    },
    [errorSnack, getTokens, resetPage, t],
  );

  const debouncedSearchHandler = useMemo(
    () => debounce(searchHandler, 200),
    [searchHandler],
  );

  const triggerDownload = useCallback((data: Blob) => {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = getExportFilename('xlsx');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, []);

  const handleDownload = useCallback(
    (filters: Filter[], columns?: string[], sort?: string) => {
      setLoadingDownload(true);
      return postFiltersExport(getTokens, filters, columns, sort)
        .then(data => triggerDownload(data))
        .catch(error => errorSnack(t('data.download.failed'), error))
        .finally(() => {
          setLoadingDownload(false);
        });
    },
    [errorSnack, getTokens, t, triggerDownload],
  );

  return useMemo(
    () => ({
      searchResult: data,
      pagination,
      loadingSearch,
      onSearch: debouncedSearchHandler,
      loadingDownload,
      onDownload: handleDownload,
    }),
    [
      data,
      debouncedSearchHandler,
      handleDownload,
      loadingDownload,
      loadingSearch,
      pagination,
    ],
  );
};
