import React, {useMemo} from 'react';
import {SxProps} from '@mui/material';
import {ColorPalette} from 'theme';
import {Layout} from 'components/layout';

type Props = {
  spacing?: string | number;
  indentation?: string | number;
  colorx?: string;
};

const makeBorder = (colorx: string) => `0.5px solid ${colorx}`;

const defaultSx: SxProps = {
  border: makeBorder(ColorPalette.beige),
};

export const Ruler: React.FC<Props> = ({spacing, indentation, colorx}) => {
  const sx = useMemo(
    () =>
      colorx
        ? {
            border: makeBorder(colorx),
          }
        : defaultSx,
    [colorx],
  );

  return (
    <Layout flex={1}>
      <Layout my={spacing} mx={indentation} flex={1} sx={sx} />
    </Layout>
  );
};
