import {createContext, useContext, useMemo} from 'react';
import {GridColDef} from '@mui/x-data-grid-pro';
import {assert} from 'assert-ts';
import {AgentSubType} from 'types';
import {Agent} from 'api/types';
import {Sorting} from 'components/dataTable/types';
import {useLocalization} from 'localization';
import {HeadTitleParam} from 'services/head';
import {AgentTypeAll} from 'services/settings';
import {
  ColumnProp,
  SetColumnVisibility,
  SetColumnsOrder,
  useAgentListColumns,
  useAgents,
  useAgentsSorting,
} from '../hooks';

export type AgentsContextType = {
  loading: boolean;
  columns: GridColDef[];
  columnsVisibility: ColumnProp[];
  setColumnVisibility: SetColumnVisibility;
  allAgents: Agent[];
  filteredAgents: Agent[];
  sorting?: Sorting;
  setSorting: (sorting?: Sorting) => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
  agentTypes: AgentSubType[];
  selectedAgentType: AgentTypeAll;
  setSelectedAgentType: (agentType: AgentTypeAll) => void;
  setColumnsOrder: SetColumnsOrder;
} & HeadTitleParam;

export const AgentsContext = createContext<AgentsContextType | undefined>(
  undefined,
);

export const useAgentsContextProviderValue = (): AgentsContextType => {
  const {t} = useLocalization();
  const {
    allAgents,
    filteredAgents,
    agentTypes,
    selectedAgentType,
    setSelectedAgentType,
    searchText,
    setSearchText,
    loading,
    searchVisibility,
    setSearchVisibility,
  } = useAgents();

  const {columns, columnsVisibility, setColumnVisibility, setColumnsOrder} =
    useAgentListColumns(selectedAgentType);

  const {sorting, setSorting} = useAgentsSorting();

  return useMemo(
    () => ({
      title: t('appbar.overview'),
      loading,
      columns,
      columnsVisibility,
      setColumnVisibility,
      allAgents,
      filteredAgents,
      agentTypes,
      selectedAgentType,
      setSelectedAgentType,
      searchText,
      setSearchText,
      sorting,
      setSorting,
      searchVisibility,
      setSearchVisibility,
      setColumnsOrder,
    }),
    [
      t,
      allAgents,
      columns,
      columnsVisibility,
      filteredAgents,
      agentTypes,
      selectedAgentType,
      setSelectedAgentType,
      searchText,
      loading,
      setColumnVisibility,
      setSorting,
      sorting,
      setSearchText,
      searchVisibility,
      setSearchVisibility,
      setColumnsOrder,
    ],
  );
};

export const useAgentsContext = (): AgentsContextType => {
  return assert(useContext(AgentsContext), 'AgentsContext: context expected');
};
