import DiffMatchPatch from 'diff-match-patch';

const Diff = new DiffMatchPatch();

type DiffPart = {
  type: 'equal' | 'insert' | 'delete';
  casing?: boolean;
  value: string;
};

const DIFF_TYPE = 0;
const DIFF_VALUE = 1;
const DIFF_CASING = 2;

/**
 * Find the diff between two strings
 * @param original Original string
 * @param updated Updated string
 * @returns If one of the values are empty/nullish, undefined is returned.
 * Otherwise, array of diff parts, each part contains the type of diff, the value and if the casing is different
 */
export const findDiff = (
  original: string,
  updated: string,
): undefined | DiffPart[] => {
  if (!original || !updated) {
    return undefined;
  }

  // Find the diff between the two strings, ignoring casing
  const diff = Diff.diff_main(original.toUpperCase(), updated.toUpperCase());
  Diff.diff_cleanupSemantic(diff);

  let originalPos = 0;
  let updatedPos = 0;

  type DMPPart = [...DiffMatchPatch.Diff, boolean];

  const dmpDiff: DMPPart[] = [];
  // Convert the diff to an array of diff parts,
  // where each part contains the type of diff, the value with casing
  // Also checks different casing for each equal part
  diff.forEach(d => {
    if (d[DIFF_TYPE] === DiffMatchPatch.DIFF_INSERT) {
      // Get proper value from updated string
      const value = updated.slice(
        updatedPos,
        updatedPos + d[DIFF_VALUE].length,
      );
      updatedPos += d[DIFF_VALUE].length;
      dmpDiff.push([d[DIFF_TYPE], value, false]);
    } else if (d[DIFF_TYPE] === DiffMatchPatch.DIFF_DELETE) {
      // Get proper value from original string
      const value = original.slice(
        originalPos,
        originalPos + d[DIFF_VALUE].length,
      );
      originalPos += d[DIFF_VALUE].length;
      dmpDiff.push([d[DIFF_TYPE], value, false]);
    } else {
      // Get proper value from both strings
      // - original
      const originalPartValue = original.slice(
        originalPos,
        originalPos + d[DIFF_VALUE].length,
      );
      originalPos += d[DIFF_VALUE].length;

      // - updated
      const updatedPartValue = updated.slice(
        updatedPos,
        updatedPos + d[DIFF_VALUE].length,
      );
      updatedPos += d[DIFF_VALUE].length;

      // Check if the casing is different
      if (originalPartValue === updatedPartValue) {
        dmpDiff.push([d[DIFF_TYPE], originalPartValue, false]);
      } else {
        // Find the diff between the two parts, including casing
        const partDiff = Diff.diff_main(originalPartValue, updatedPartValue);
        dmpDiff.push(
          ...partDiff.map<DMPPart>(pd => [
            ...pd,
            pd[DIFF_TYPE] !== DiffMatchPatch.DIFF_EQUAL,
          ]),
        );
      }
    }
  });

  return dmpDiff.map(dmpPart => ({
    type:
      dmpPart[DIFF_TYPE] === DiffMatchPatch.DIFF_EQUAL
        ? 'equal'
        : dmpPart[DIFF_TYPE] === DiffMatchPatch.DIFF_INSERT
          ? 'insert'
          : 'delete',
    value: dmpPart[DIFF_VALUE],
    casing: dmpPart[DIFF_CASING] ? true : undefined,
  }));
};
