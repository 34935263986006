import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {assert} from 'assert-ts';
import {CodeListMap, LocalCodeListId} from 'api/types';
import {Schema} from 'schemaDefinition/types';
import {AppState} from 'store/types';
import {useLocalization} from 'localization';
import {isLinkedRoleCodeListId} from 'schemaDefinition/functions';
import {AllLocalCodeListIds} from 'schemaDefinition/linkTypes';
import {getLinkedRoleCodelist} from 'schemas/links/functions';
import {useSchemaListCodelistIds} from './useSchemaListCodelistIds';

const codeListsStateSelector = (state: AppState) => state.codeLists;

/**
 * Assumes needed codelists have been loaded
 */
export const useSchemasCodelists = <TVal>(
  schemas: Schema<TVal>[],
): CodeListMap => {
  const localization = useLocalization();
  const codelistIds = useSchemaListCodelistIds(schemas);
  const codelists = useSelector(codeListsStateSelector);

  return useMemo(() => {
    return codelistIds.reduce<CodeListMap>((acc, id) => {
      if (AllLocalCodeListIds.includes(id as LocalCodeListId)) {
        return acc;
      }

      if (isLinkedRoleCodeListId(id)) {
        const codelist = getLinkedRoleCodelist(id, localization);
        acc[id] = codelist;
        return acc;
      }

      const codelist = assert(
        codelists[id],
        `useSchemaCodelists: missing codelist for id: ${id}`,
        {id},
      );
      assert(
        codelist.status === 'Loaded',
        'useSchemaCodelists: codelist not loaded',
        {id},
      );
      acc[id] = codelist.data;
      return acc;
    }, {} as CodeListMap);
  }, [codelistIds, codelists, localization]);
};
