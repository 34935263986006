import dayjs from 'dayjs';
import {DateRangeValue} from 'components/fields/DateRangeField';

export const mapDateRangeFieldValue = (
  dateRangeValue: DateRangeValue | undefined,
): string[] | undefined => {
  if (!dateRangeValue) {
    return undefined;
  }

  if (dateRangeValue.length === 2) {
    if (dayjs.isDayjs(dateRangeValue[0]) && dayjs.isDayjs(dateRangeValue[1])) {
      return [
        dateRangeValue[0].format('YYYY-MM-DD'),
        dateRangeValue[1].format('YYYY-MM-DD'),
      ];
    }
  }

  return undefined;
};

export const mapToDateRangeFieldValue = (
  values: string[] | undefined,
): DateRangeValue => {
  if (!values) {
    return [null, null];
  }

  if (values.length === 2) {
    return [dayjs(values[0]), dayjs(values[1])];
  }

  return [null, null];
};
