import {ThemeColorsType} from './types';
import {ColorPalette} from './colorPalette';

export const ThemeColors: ThemeColorsType = {
  background: {
    topBar: {
      main: ColorPalette.primary.burgundy,
      light: ColorPalette.offWhite,
      dark: ColorPalette.primary.burgundy,
    },
    primary: {
      main: ColorPalette.lightBeige,
      light: ColorPalette.offWhite,
      dark: ColorPalette.brown,
    },
    secondary: {
      main: ColorPalette.secondary.dirtyLightGreen,
      light: '#FEFEFE',
      dark: '#CEC0B2',
    },
  },
  text: {
    primary: {
      main: ColorPalette.primary.burgundy,
      light: ColorPalette.primary.white,
      dark: ColorPalette.primary.burgundy,
    },
    secondary: {
      main: ColorPalette.primary.warmGreen,
      light: 'yellow',
      dark: 'yellow',
    },
    error: {
      main: ColorPalette.error,
      light: ColorPalette.error,
      dark: ColorPalette.error,
    },
    info: {
      main: 'yellow',
      light: 'yellow',
      dark: 'yellow',
    },
    success: {
      main: 'yellow',
      light: 'yellow',
      dark: 'yellow',
    },
    warning: {
      main: ColorPalette.warning,
      light: ColorPalette.warning,
      dark: ColorPalette.warning,
    },
    disabled: {
      main: ColorPalette.primary.warmGreen,
      light: 'yellow',
      dark: 'yellow',
    },
    approve: {
      main: ColorPalette.approve,
      light: 'yellow',
      dark: 'yellow',
    },
    reject: {
      main: ColorPalette.reject,
      light: 'yellow',
      dark: 'yellow',
    },
  },
  spot: {
    new: ColorPalette.status.new,
    pre: ColorPalette.status.pre,
    control: ColorPalette.status.control,
    change: ColorPalette.status.change,
    ml: ColorPalette.status.ml,
    mottak: ColorPalette.status.mottak,
    all: ColorPalette.spot.urgent,
    trykkunderlag: ColorPalette.spot.trykkunderlag,
  },
  spotText: {
    new: ColorPalette.burgundy,
    pre: ColorPalette.burgundy,
    control: ColorPalette.burgundy,
    change: ColorPalette.burgundy,
    ml: ColorPalette.offWhite,
    mottak: ColorPalette.burgundy,
    all: ColorPalette.burgundy,
    trykkunderlag: ColorPalette.burgundy,
  },
  status: {
    new: ColorPalette.status.new,
    pre: ColorPalette.status.precat,
    control: ColorPalette.status.control,
    change: ColorPalette.status.change,
    done: ColorPalette.status.done,
    ml: ColorPalette.status.ml,
    mottak: ColorPalette.status.mottak,
  },
};
