import React from 'react';
import {MessageQueueStatus} from 'api/types';
import {useLocalization} from 'localization';
import {FlexBox, Spacer} from 'components';
import {getQueueHealth} from '../functions';
import {HealthIndicator} from './HealthIndicator';
import {HealthSubtitle} from './HealthSubtitle';

type Props = {
  data: MessageQueueStatus;
};

export const QueueHealth: React.FC<Props> = ({data}) => {
  const {t, tLoose} = useLocalization();
  const status = getQueueHealth(data);

  const topic = tLoose(`system.queue.${data.topic}.title`);
  const length = t('system.queue.length', {count: data.length});
  return (
    <FlexBox horizontal center left>
      <HealthIndicator status={status} />
      <Spacer width={1} />
      <HealthSubtitle title={`${topic}, ${length}`} />
    </FlexBox>
  );
};
