import {OverviewTask} from 'api/types';
import {LocalizationContextType} from 'localization/context/types';
import {isNullish} from 'services/utils';
import {formatMedia} from './formatMedia';

const nonSearchableKeys: (keyof OverviewTask)[] = [
  'id',
  'manifestationId',
  'workId',
  'taskTypes',
  'contentReceivedDate',
  'searchableTaskString',
];

export const makeSearchableTaskString = (
  {
    publisherSalesDate,
    media,
    productOwner,
    ...rest
  }: Omit<OverviewTask, 'searchableTaskString'>,
  l?: LocalizationContextType,
): string => {
  return [
    l ? l.formatDate(publisherSalesDate) : undefined,
    formatMedia(media, l),
    productOwner?.name,
    productOwner?.code,
    ...Object.keys(rest)
      .filter(key => !nonSearchableKeys.includes(key as keyof OverviewTask))
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      .map(key => rest[key as keyof OverviewTask]),
  ]
    .filter(v => !isNullish(v))
    .join(';')
    .toLocaleLowerCase();
};
