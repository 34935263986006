import {useMemo} from 'react';
import {Product} from 'api/types';
import {GetValueById} from 'schema/form/types';

export const useManifestationDescription = (
  getEditValue: GetValueById,
  manifestationId: string,
): string | undefined => {
  const products = getEditValue('products', manifestationId) as
    | Product[]
    | undefined;

  return useMemo(() => {
    return products && products[0]
      ? products?.[0]?.descriptions?.[0]?.content ?? undefined
      : undefined;
  }, [products]);
};
