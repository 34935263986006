import {assert} from 'assert-ts';
import {Data, DataSimpleValue, DataValue, Valx} from '../types';
import {EvaluationArgs} from './types';
import {evaluateByNameRef} from './evaluateByNameRef';
import {evaluateByPathKeys} from './evaluateByPathKeys';
import {evaluatePropValue} from './evaluatePropValue';

/**
 * If any values cannot be converted to argType, then the condition is undefined.
 */
export const evaluateValx = (
  valx: Valx | undefined,
  args: EvaluationArgs,
): DataValue => {
  if (valx === undefined) {
    return null;
  }

  const {
    value,
    propPath,
    localPathKeys,
    globalPathKeys,
    globalByName,
    relatedPathKeys,
  } = valx;
  // Constant value
  if (value !== undefined) {
    return value;
  }

  // Array of values
  if (Array.isArray(valx)) {
    return valx.map(v => evaluateValx(v, args)) as DataSimpleValue[] | Data[];
  }

  // Reference value
  // - prop keys
  if (propPath) {
    return evaluatePropValue(propPath, args.value);
  }

  // - local keys
  if (localPathKeys) {
    const assertProps = {
      valx,
      valuePath: args.valuePath,
      localScope: args.localScope,
    };
    assert(
      args.localScope !== undefined,
      'evaluateValx: localScope must be set when localPathKeys',
      assertProps,
    );
    return evaluateByPathKeys(localPathKeys, args.localScope, assertProps);
  }

  // - global keys
  if (globalPathKeys) {
    const assertProps = {
      valx,
      valuePath: args.valuePath,
      globalScope: args.globalScope,
    };
    assert(
      args.globalScope !== undefined,
      'evaluateValx: globalScope must be set when globalPathKeys',
      assertProps,
    );
    return evaluateByPathKeys(globalPathKeys, args.globalScope, assertProps);
  }

  // - global by name
  if (globalByName) {
    assert(
      args.globalScope !== undefined,
      'evaluateValx(global by name): global scope expected',
    );
    return evaluateByNameRef(globalByName, args.globalScope, args.valuePath);
  }

  // - related keys
  if (relatedPathKeys) {
    const assertProps = {
      valx,
      valuePath: args.valuePath,
      relatedScope: args.relatedScope,
    };
    assert(
      args.relatedScope !== undefined,
      'evaluateValx: relatedScope must be set when relatedPathKeys',
      assertProps,
    );
    return evaluateByPathKeys(relatedPathKeys, args.relatedScope, assertProps);
  }

  assert(false, 'evaluateValx: invalid valx - all undefined', {
    valx,
    valuePath: args.valuePath,
  });
};
