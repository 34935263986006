import {useCallback, useState} from 'react';
import {DragOverlay} from '@dnd-kit/core';
import {SortableContext} from '@dnd-kit/sortable';
import Chip from '@mui/material/Chip';
import {assert} from 'assert-ts';
import {noOp, valueAsArrayOf} from 'services/utils';
import {Layout} from 'components/layout';
import {SortableInputProps} from './types';
import {InputTextField} from './CodeInputTextField';
import {useVariableWrappedItemsSortingStrategy} from './hooks';

export const SortableInput: React.FC<SortableInputProps> = ({
  activeId,
  options,
  onBlur,
  ...props
}) => {
  const itemIds = assert(
    valueAsArrayOf<string>(props.value),
    'SortableInput: itemIds',
  );
  const sortingStrategy = useVariableWrappedItemsSortingStrategy(itemIds);

  // Keep track of focus to render drag overlay as chip (different with or without focus)
  const [focusWithin, setFocusWithin] = useState(false);

  const handleBlur = useCallback(() => {
    onBlur && onBlur();
    setFocusWithin(false);
  }, [onBlur]);

  const handleFocus = useCallback(() => {
    setFocusWithin(true);
  }, []);

  const activeLabel = activeId
    ? options.find(o => o.code === activeId)?.value
    : undefined;
  return (
    <SortableContext strategy={sortingStrategy} items={itemIds}>
      <InputTextField {...props} onFocus={handleFocus} onBlur={handleBlur} />

      <DragOverlay
        dropAnimation={{
          duration: 200,
          easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
        }}>
        {activeId ? (
          <Layout>
            {focusWithin ? (
              <Chip
                sx={{m: '2px'}}
                label={activeLabel}
                size="small"
                onDelete={noOp}
              />
            ) : (
              <Chip label={activeLabel} />
            )}
          </Layout>
        ) : null}
      </DragOverlay>
    </SortableContext>
  );
};
