import React, {useMemo} from 'react';
import {SxProps} from '@mui/material';
import {useThemeColors} from '../../theme';
import {Link} from './Link';

type Props = {
  title: string;
  path: string;
  selected: boolean;
};

export const MenuButton: React.FC<Props> = ({title, selected, path}) => {
  const themeColors = useThemeColors();

  const sx = useMemo((): SxProps => {
    const boxStyle: SxProps = {
      padding: '3px 8px 2px 8px',
      borderRadius: 4,
      cursor: 'pointer',
    };

    if (selected) {
      boxStyle.borderColor = themeColors.background.topBar.light;
      boxStyle.borderStyle = 'solid';
      boxStyle.borderWidth = 'thin';
    }

    return boxStyle;
  }, [selected, themeColors]);

  return (
    <Link
      title={title}
      sx={sx}
      to={path}
      textTransform="capitalize"
      variant="h4"
      kind="primary"
      mode="light"
    />
  );
};
