import {assert} from 'assert-ts';
import {Metadata} from 'services/data/metadata/types';
import {MetadataEditAction} from 'services/data/metadata/metadataEditActionTypes';

export const assertMetadata = (
  metadata: Metadata | undefined,
  action?: MetadataEditAction,
  qualifer?: 'saved' | 'edit',
): Metadata => {
  if (!metadata) {
    assert(
      false,
      qualifer
        ? `assertMetadata (${qualifer}): metadata expected`
        : 'assertMetadata: metadata expected',
      action,
    );
  }

  return metadata;
};
