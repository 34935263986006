import {Filter} from 'api/types';
import {AvailableFilter, CurrentFilterType} from 'api/types';

export const findNextFilter = (
  currentFilters: CurrentFilterType[],
  availableFilters: AvailableFilter[],
): Filter => {
  const next = availableFilters.find(a => match(a, currentFilters));

  if (!next) {
    return {
      name: 'bookGroup',
      operation: 'EQUAL',
      values: [],
    };
  }

  return {
    name: next.name,
    operation: next?.operations[0] ?? 'EQUAL',
    values: [],
  };
};

const match = (
  availableFilter: AvailableFilter,
  currentFilters: CurrentFilterType[],
): boolean => {
  return !currentFilters.find(c => c.name === availableFilter.name);
};
