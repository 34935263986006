import React from 'react';
import {Button} from '@mui/material';
import {Icon, Layout} from '../../../components';

type Props = {
  isExpanded: boolean;
  onClick: () => void;
  children: React.ReactNode;
};
export const ChevronToggle: React.FC<Props> = ({
  isExpanded,
  onClick,
  children,
}) => {
  return (
    <Layout>
      <Button
        variant={'text'}
        size={'large'}
        onClick={onClick}
        startIcon={<Icon icon={isExpanded ? 'ChevronUp' : 'DropDown'} />}>
        {children}
      </Button>
    </Layout>
  );
};
