import React, {useCallback, useMemo, useState} from 'react';
import {Stack} from '@mui/material';
import {useLocalization} from 'localization';
import {
  IconButton,
  Label,
  Layout,
  Text,
  useAlertDialogAsPromiseV3,
  useSnacks,
} from 'components';
import {useAdvancedSearchContext} from '../context/AdvancedSearchContext';
import {compareColumns} from '../functions/compareColumns';
import {useCreateColumnSetDialog} from '../hooks';
import {useEditColumnSetDialog} from '../hooks/useEditColumnSetDialog';
import {ChevronToggle} from './ChevronToggle';
import {ListItem} from './ListItem';

export const ColumnSets: React.FC = () => {
  const {
    columnSets,
    currentColumns,
    setCurrentColumns,
    createColumnSet,
    editColumnSet,
    allColumns,
    deleteColumnSet,
  } = useAdvancedSearchContext();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const limit = 10;
  const {errorSnack} = useSnacks();
  const {t} = useLocalization();
  const {CreateColumnSetDialog, openCreateColumnSetDialog} =
    useCreateColumnSetDialog(allColumns, createColumnSet, setCurrentColumns);

  const {EditColumnSetDialog, openEditColumnSetDialog} = useEditColumnSetDialog(
    allColumns,
    editColumnSet,
    setCurrentColumns,
  );
  const {
    pleaseConfirm: pleaseConfirmDeleteColumnSet,
    AlertDialog: ConfirmDeleteColumnSet,
    isOpen: confirmDeleteIsOpen,
    isClosing: confirmDeleteIsClosing,
  } = useAlertDialogAsPromiseV3();

  const handleDelete = useCallback(
    (id: string) => {
      pleaseConfirmDeleteColumnSet(
        () =>
          deleteColumnSet(id).catch(() => {
            errorSnack(t('page.search.columnSet.delete.failed'));
          }),
        {
          title: t('general.areyousure'),
          okTitle: t('general.delete'),
          okIcon: 'Delete',
          cancelTitle: t('general.cancel'),
        },
      );
    },
    [deleteColumnSet, errorSnack, pleaseConfirmDeleteColumnSet, t],
  );

  /**
   * Filtered by private and public, then name.
   */
  const ExpandableColumnSets = useMemo(() => {
    const items: React.ReactElement[] = [];

    // Headers only displayed one time each, or never if no items match
    let firstPrivate = columnSets.filter(c => !c.isPublic).length > 0;
    let firstPublic = columnSets.filter(c => c.isPublic).length > 0;

    columnSets.slice(0, isExpanded ? undefined : limit).forEach(cc => {
      if (firstPrivate && !cc.isPublic) {
        items.push(
          <Label key={'header-private'}>{t('data.field.private.label')}</Label>,
        );
        firstPrivate = false;
      }
      if (firstPublic && cc.isPublic) {
        items.push(
          <Label key={'header-public'}>{t('data.field.public.label')}</Label>,
        );
        firstPublic = false;
      }
      items.push(
        <ListItem
          key={cc.id}
          label={cc.name}
          onClick={() => setCurrentColumns(cc.columns)}
          active={compareColumns(cc.columns, currentColumns)}
          actions={[
            {
              position: 'afterText',
              props: {
                icon: 'Edit',
                size: 'small',
                onClick: () => openEditColumnSetDialog(cc),
              },
            },
            {
              position: 'afterText',
              props: {
                icon: 'Delete',
                size: 'small',
                onClick: () => handleDelete(cc.id),
              },
            },
          ]}
        />,
      );
    });

    return items;
  }, [
    columnSets,
    currentColumns,
    handleDelete,
    isExpanded,
    openEditColumnSetDialog,
    setCurrentColumns,
    t,
  ]);

  return (
    <>
      <Layout horizontal alignItems={'center'}>
        <Text variant={'body1'}>Kolonnesamlinger</Text>
        <IconButton
          size={'small'}
          icon={'Add'}
          onClick={openCreateColumnSetDialog}
        />
        {CreateColumnSetDialog}
      </Layout>

      <Stack p={1} direction={'column'} spacing={1}>
        {ExpandableColumnSets}

        <ConfirmDeleteColumnSet
          isOpen={confirmDeleteIsOpen}
          isClosing={confirmDeleteIsClosing}
        />
        {EditColumnSetDialog}
        {columnSets.length > limit ? (
          <ChevronToggle
            isExpanded={isExpanded}
            onClick={() => setIsExpanded(o => !o)}>
            Se {isExpanded ? 'færre' : 'flere'}
          </ChevronToggle>
        ) : null}
      </Stack>
    </>
  );
};
