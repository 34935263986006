import React, {useCallback, useEffect, useState} from 'react';
import {
  DatePicker,
  DateValidationError,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {elGR, nbNO} from '@mui/x-date-pickers/locales';
import {format, parse} from 'date-fns';
import {nb} from 'date-fns/locale/nb';
import {UsePickerValueBaseProps} from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types';
import {Locale} from 'localization/types';
import {FieldRequiredSimple} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {Layout} from 'components/layout';
import {BaseFieldProps} from './types';
import {CalendarIcon} from '../icons';
import './DatePickerField.scss';
import {FieldLabel} from './FieldLabel';
import {NullFieldPlaceholder} from './NullFieldPlaceholder';
import {MuiTextFieldStyled} from './muiStyled';

type Props = BaseFieldProps & {
  /** Date as string, e.g. "2021-12-31", or null */
  value?: string | null;
  required?: FieldRequiredSimple;
  name: string;
  onChange: (value: string | null) => void;
  onBlur?: () => void;
};

const dateLocaleMap: Record<Locale, typeof nbNO> = {
  nb: nbNO,
  nn: nbNO,
  en: elGR,
};

export const DatePickerField: React.FC<Props> = ({
  name,
  label,
  value,
  readonly,
  showWhenReadonlyAndEmpty = false,
  required = false,
  error,
  width,
  maxWidth,
  flex,
  onChange,
  onBlur,
}) => {
  const {locale = 'nb'} = useLocalization();
  const [localValue, setLocalValue] = useState<Date | null>(null);
  const handleChange: UsePickerValueBaseProps<
    Date | null,
    DateValidationError
  >['onChange'] = useCallback(
    (newValue: Date | null) => {
      try {
        setLocalValue(newValue);
        onChange(dateToString(newValue));
      } catch (e) {
        onChange('Invalid date'); // Will fail date check by validator.
      }
    },
    [onChange],
  );

  // When  value changes from outside
  // => update local value
  useEffect(() => {
    if (value !== dateToString(localValue)) {
      setLocalValue(stringToDate(value));
    }
  }, [value, localValue]);

  const inputFieldClassName = error === 'warning' ? 'Ebba-warning' : undefined;

  return (
    <Layout width={width} maxWidth={maxWidth} flex={flex}>
      {label && (
        <FieldLabel
          label={label}
          required={required}
          error={error}
          htmlFor={name}
        />
      )}
      {readonly && !value && !showWhenReadonlyAndEmpty ? (
        <NullFieldPlaceholder />
      ) : (
        <LocalizationProvider
          // We don't use dayjs here, because it does not handle year 1-99.
          dateAdapter={AdapterDateFns}
          adapterLocale={nb}
          localeText={
            dateLocaleMap[locale].components.MuiLocalizationProvider
              .defaultProps.localeText
          }>
          <DatePicker
            value={localValue}
            disabled={!!readonly}
            onChange={handleChange}
            slotProps={{
              textField: () => {
                return {
                  fullWidth: true,
                  autoComplete: 'off',
                  size: 'small',
                  required: required !== false,
                  name,
                  id: name,
                  error: error !== false,
                  className: inputFieldClassName,
                  InputProps: {
                    onBlur,
                  },
                };
              },
            }}
            slots={{
              textField: MuiTextFieldStyled,
              openPickerIcon: () => (
                <CalendarIcon color={readonly ? 'disabled' : 'primary'} />
              ),
            }}
          />
        </LocalizationProvider>
      )}
    </Layout>
  );
};

const dateToString = (date: Date | null | undefined): string | null => {
  try {
    return date ? format(date, 'yyyy-MM-dd') : null;
  } catch (e) {
    return 'Invalid date';
  }
};

const stringToDate = (string: string | null | undefined): Date | null => {
  try {
    return string ? parse(string, 'yyyy-MM-dd', new Date()) : null;
  } catch (e) {
    return null;
  }
};
