import {assert} from 'assert-ts';
import {Thesaurus} from 'api/types';
import {getSelectAction} from './getSelectAction';

export const addNodeIdWithDuplicatesToEditValue = (
  nodeId: string,
  value: string[],
  thesaurus: Thesaurus,
  duplicateIdToIds: Map<string, string[]>,
): string[] => {
  const action = getSelectAction(
    nodeId,
    value,
    thesaurus,
    undefined,
    duplicateIdToIds,
  );
  if (!action) {
    return value;
  }

  assert(
    action.type === 'select' || action.type === 'swap',
    'addNodeIdToThesaurusValue: action select or swap expected',
  );

  if (action.type === 'select') {
    // Add selected node
    return [...value, nodeId, ...(duplicateIdToIds.get(nodeId) || [])];
  } else {
    // Swap selected node with related nodes, i.e. nodes that are ancestors or children of selected node
    // including ancestors or children of duplicates of selected node
    return [
      ...value.filter(id => !action.relatedNodeIds.includes(id)),
      nodeId,
      ...(duplicateIdToIds.get(nodeId) || []),
    ];
  }
};
