import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {MetadataSaveOrCancelButtons} from '../MetadataSaveOrCancelButtons';
import {MetadataToolbar} from '../MetadataToolbar';

type Props = {
  onOpenSearch: () => void;
};
export const WorkActions: React.FC<Props> = ({onOpenSearch}) => {
  return (
    <Box
      component={Grid}
      container
      justifyContent={'flex-end'}
      alignItems={'center'}
      flexDirection={{
        xs: 'row',
        sm: 'row-reverse',
      }}
      spacing={1}>
      <Grid item>
        <MetadataToolbar onSearch={onOpenSearch} />
      </Grid>
      <Grid item>
        <MetadataSaveOrCancelButtons />
      </Grid>
    </Box>
  );
};
