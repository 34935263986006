import React, {useEffect, useMemo, useState} from 'react';
import './CollapsableLayout.scss';

const classNameVisible = 'Ebba-collapsableLayout';
const classNameCollapsed = 'Ebba-collapsableLayout Ebba-collapsed-vertical';

export const useVerticalCollapsableLayout = <THtmlElement extends HTMLElement>(
  elementRef: React.RefObject<THtmlElement>,
  collapsed?: boolean,
) => {
  const [className, setClassName] = useState<string>(classNameVisible);

  // Postpone any collapsed class to first set height and max-height of element to be translated
  useEffect(() => {
    setClassName(() => (collapsed ? classNameCollapsed : classNameVisible));
  }, [collapsed]);

  return useMemo(() => {
    const height = elementRef.current?.clientHeight ?? undefined;

    const style = {
      maxHeight: collapsed ? height : undefined,
      height: collapsed ? height : undefined,
    };

    return {
      className,
      style,
    };
  }, [className, elementRef, collapsed]);
};
