import React from 'react';
import {SystemComponentStatus} from 'api/types';
import {useLocalization} from 'localization';
import {FlexBox, Spacer} from 'components';
import {HealthIndicator} from './HealthIndicator';
import {HealthSubtitle} from './HealthSubtitle';

type Props = {
  data: SystemComponentStatus;
};

export const ComponentHealth: React.FC<Props> = ({data}) => {
  const {tLoose} = useLocalization();

  const name = tLoose(`system.component.${data.name}.title`);
  return (
    <FlexBox horizontal center left>
      <HealthIndicator status={data.status === 'UP' ? 'green' : 'red'} />
      <Spacer width={1} />
      <HealthSubtitle title={name} />
    </FlexBox>
  );
};
