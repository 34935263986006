import {useCallback, useMemo} from 'react';
import parse, {DOMNode, Element, Text, domToReact} from 'html-react-parser';
import {last} from 'services/utils';
import {TextMatch} from 'components';

type Props = {
  note: string | undefined;
  searchQuery?: string;
  gotoNodeId: (nodeId: string) => void;
};

export const ThesaurusNoteWithRefs: React.FC<Props> = ({
  note,
  searchQuery,
  gotoNodeId,
}) => {
  const replace = useCallback(
    (domNode: DOMNode) => {
      const recursiveReplace = (domNode: DOMNode) => {
        if (domNode.type === 'text') {
          return (
            <TextMatch match={searchQuery} variant="body2" component="span">
              {(domNode as Text).data}
            </TextMatch>
          );
        }

        if (domNode instanceof Element) {
          if (domNode.type === 'tag' && domNode.name === 'a') {
            const hrefValue = domNode.attributes.find(
              attr => attr.name === 'href',
            )?.value;
            const nodeId = hrefValue ? last(hrefValue.split('/')) : undefined;
            return (
              <a
                href={hrefValue}
                onClick={event => {
                  nodeId && gotoNodeId(nodeId);
                  event.preventDefault();
                  event.stopPropagation();
                }}>
                {domToReact(domNode.children as DOMNode[], {
                  replace: recursiveReplace,
                })}
              </a>
            );
          }
        }
        return domNode;
      };

      return recursiveReplace(domNode);
    },
    [gotoNodeId, searchQuery],
  );

  const parsedNote = useMemo(
    () =>
      note
        ? parse(note, {
            replace,
          })
        : null,
    [note, replace],
  );

  if (!parsedNote) return null;

  return (
    <TextMatch match={searchQuery} variant="body2">
      {parsedNote}
    </TextMatch>
  );
};
