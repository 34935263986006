import {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {assert} from 'assert-ts';
import {Dispatch} from 'redux';
import {Concept} from 'types';
import {HttpError} from 'api/http/types';
import {ChangeRequest, ManifestationV4, WorkV4} from 'api/types';
import {
  ExpressionV4,
  putManifestation,
  putWork,
  removeChangeRequestInLocalStore,
} from 'api';
import {
  postNewExpressionWithManifestation,
  putExpression,
} from 'api/expression';
import {useGetTokens} from 'services/auth/hooks/useGetTokens';
import {useMockContext} from 'services/utils/contexts';
import {
  EntityContainer,
  EntitySaveStatus,
  OptionalSaveStatus,
  SaveStatus,
} from '../types';
import {MetadataAction} from '../metadataActionTypes';
import {MetadataEditAction} from '../metadataEditActionTypes';
import {useCloseChangeRequest} from './useCloseChangeRequest';
import {useTriggerManifestationOutbox} from './useTriggerManifestationOutbox';
import {useTriggerStatementOfResponsibilityGeneratedFeedback} from './useTriggerStatementOfResponsibilityGeneratedFeedback';

export type ReviewSaveStatuses = [
  workStatus: OptionalSaveStatus,
  expressionStatus: OptionalSaveStatus,
  manifestationStatus: OptionalSaveStatus,
  taskStatus: OptionalSaveStatus,
];

export type MetadataSaveOperations = {
  saveWork: (work: WorkV4) => Promise<SaveStatus>;
  saveExpression: (expression: ExpressionV4) => Promise<SaveStatus>;
  saveManifestation: (manifestation: ManifestationV4) => Promise<SaveStatus>;
  saveMetadataEntities: (
    entities: EntityContainer[],
  ) => Promise<EntitySaveStatus[]>;
  saveNewExpressionWithManifestation: (
    expression: ExpressionV4,
    manifestation: ManifestationV4,
  ) => Promise<OptionalSaveStatus>;
  saveChangeRequest: (
    changeRequest: ChangeRequest,
    onlyChangeRequests: boolean,
  ) => Promise<SaveStatus>;
};

export const useMetadataSaveOperations = (): MetadataSaveOperations => {
  const getTokens = useGetTokens();
  const mock = useMockContext();
  const closeChangeRequest = useCloseChangeRequest();
  const triggerManifestationOutbox = useTriggerManifestationOutbox();
  const triggerStatementOfResponsibilityGeneratedFeedback =
    useTriggerStatementOfResponsibilityGeneratedFeedback();

  const dispatch = useDispatch<Dispatch<MetadataEditAction>>();

  const saveWork = useCallback(
    (work: WorkV4): Promise<SaveStatus> => {
      return putWork(work, getTokens, mock)
        .then(updatedWork => {
          dispatch({
            type: 'METADATA_WORK_SAVED',
            payload: updatedWork,
          });

          return {status: 'Saved'} as SaveStatus;
        })
        .catch((error: HttpError) => {
          return {status: 'Failed' as const, error};
        });
    },
    [getTokens, mock, dispatch],
  );

  const saveExpression = useCallback(
    (expression: ExpressionV4): Promise<SaveStatus> => {
      return putExpression(expression, getTokens, mock)
        .then(updatedExpression => {
          dispatch({
            type: 'METADATA_EXPRESSION_SAVED',
            payload: updatedExpression,
          });

          return {status: 'Saved'} as SaveStatus;
        })
        .catch((error: HttpError) => {
          return {status: 'Failed' as const, error};
        });
    },
    [getTokens, mock, dispatch],
  );

  const saveManifestation = useCallback(
    (manifestation: ManifestationV4): Promise<SaveStatus> => {
      return putManifestation(manifestation, getTokens, mock)
        .then(response => {
          dispatch({
            type: 'METADATA_MANIFESTATION_SAVED',
            payload: response,
          });

          // Notify user if field has been populated from backend
          triggerStatementOfResponsibilityGeneratedFeedback(
            manifestation,
            response.manifestation,
          );

          return {status: 'Saved'} as SaveStatus;
        })
        .catch((error: HttpError) => {
          return {status: 'Failed' as const, error};
        });
    },
    [
      getTokens,
      mock,
      dispatch,
      triggerStatementOfResponsibilityGeneratedFeedback,
    ],
  );

  /**
   * saveChangeRequest, i.e. update status if from backend, remove if from local, then
   * dispatch SAVED with status 'COMPLETED'
   */
  const saveChangeRequest = useCallback(
    (
      changeRequest: ChangeRequest,
      onlyChangeRequests: boolean,
    ): Promise<SaveStatus> => {
      return (
        changeRequest.storage === 'backend' // Backend: Update status in backend
          ? closeChangeRequest(changeRequest.id, changeRequest.taskType)
          : changeRequest.storage === 'local'
            ? // Local: Remove from local storage
              Promise.resolve(
                removeChangeRequestInLocalStore(
                  changeRequest.id,
                  changeRequest.work.id,
                ),
              )
            : // Transient: do nothing
              Promise.resolve()
      )
        .then(() => {
          const updatedChangeRequest: ChangeRequest = {
            ...changeRequest,
            status: 'COMPLETED',
            work: {
              id: changeRequest.work.id,
            } as WorkV4,
            expression: {
              id: changeRequest.expression.id,
              workId: changeRequest.expression.workId,
            } as ExpressionV4,
            manifestation: {
              id: changeRequest.manifestation.id,
              expressionId: changeRequest.manifestation.expressionId,
            } as ManifestationV4,
          };

          dispatch({
            type: 'METADATA_CHANGEREQUEST_SAVED',
            payload: updatedChangeRequest,
          });

          if (onlyChangeRequests) {
            // Meta needs us to trigger outbox when change request is closed with no changes to metadata.
            triggerManifestationOutbox(changeRequest.manifestation.id);
          }

          return {status: 'Saved'} as SaveStatus;
        })
        .catch((error: HttpError) => {
          return {status: 'Failed' as const, error};
        });
    },
    [closeChangeRequest, dispatch, triggerManifestationOutbox],
  );

  const saveMetadataEntities = useCallback(
    async (entities: EntityContainer[]): Promise<EntitySaveStatus[]> => {
      if (
        !assert.soft(entities.length > 0, 'saveMetadataByIds: no ids to save')
      ) {
        return [];
      }

      dispatch({
        type: 'METADATAEDIT_SET_SAVING',
        payload: {
          saving: true,
        },
      });

      const result = entities.map<EntitySaveStatus>(e => ({
        entity: {type: e.type, id: e.data.id},
        status: {status: 'None'},
      }));

      const onlyChangeRequests = entities.every(
        e => e.type === Concept.changeRequest,
      );

      for (let i = 0; i < entities.length; i++) {
        const entity = entities[i];
        const status = await (entity.type === Concept.work
          ? saveWork(entity.data)
          : entity.type === Concept.expression
            ? saveExpression(entity.data)
            : entity.type === Concept.manifestation
              ? saveManifestation(entity.data)
              : saveChangeRequest(entity.data, onlyChangeRequests));

        result[i].status = status;
        if (status.status === 'Failed') {
          break;
        }
      }

      // Dispatch SAVED action if all successful
      if (result.every(r => r.status.status === 'Saved')) {
        const action: MetadataEditAction = {
          type: 'METADATAEDIT_SAVED',
        };
        dispatch(action);
      }

      if (result.every(r => r.status.status !== 'None')) {
        const action: MetadataEditAction = {
          type: 'METADATAEDIT_SET_SAVING',
          payload: {
            saving: false,
          },
        };
        dispatch(action);
      }

      return result;
    },
    [saveWork, saveExpression, saveManifestation, saveChangeRequest, dispatch],
  );

  /** Save one or more of work, expression or manifestation to backend and update reducer state if successful */
  const saveNewExpressionWithManifestation = useCallback(
    (
      expression: ExpressionV4,
      manifestation: ManifestationV4,
    ): Promise<OptionalSaveStatus> => {
      const localExpressionId = expression.id;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      delete expression['id'];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      delete manifestation['expressionId'];

      return postNewExpressionWithManifestation(
        expression,
        manifestation,
        getTokens,
        mock,
      )
        .then(
          ({
            expression: updatedExpression,
            manifestation: updatedManifestation,
          }) => {
            const action: MetadataAction = {
              type: 'METADATA_NEW_EXPRESSION_WITH_MANIFESTATION_SAVED',
              payload: {
                localExpressionId,
                expression: updatedExpression,
                manifestation: updatedManifestation,
              },
            };
            dispatch(action);
            return {status: 'Saved' as const};
          },
        )
        .catch((error: HttpError) => {
          return {status: 'Failed' as const, error};
        });
    },
    [getTokens, mock, dispatch],
  );

  return useMemo(() => {
    return {
      saveWork,
      saveExpression,
      saveManifestation,
      saveMetadataEntities,
      saveNewExpressionWithManifestation,
      saveChangeRequest,
    };
  }, [
    saveChangeRequest,
    saveExpression,
    saveManifestation,
    saveMetadataEntities,
    saveNewExpressionWithManifestation,
    saveWork,
  ]);
};
