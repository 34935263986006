import {useCallback} from 'react';
import {
  CodelistRestrictor,
  useRestrictedCodelistsContext,
} from 'services/codeLists';
import {useManifestationImportProps} from 'services/data/metadata/hooks/useManifestationImportProps';
import {
  getRestrictedExpressionCodelist,
  getRestrictedManifestationCodelist,
} from '../functions';

export const useExpressionAndManifestationCodelistRestrictor = (
  manifestationId: string,
  expressionId: string,
): CodelistRestrictor => {
  const importProps = useManifestationImportProps(
    manifestationId,
    expressionId,
  );
  const {getRestrictedCodelist: getOuterRestrictedCodelist} =
    useRestrictedCodelistsContext();
  return useCallback(
    codelist => {
      const outerCodelist = getOuterRestrictedCodelist(codelist);
      const expressionCodelist = getRestrictedExpressionCodelist(
        importProps,
        outerCodelist,
      );
      return getRestrictedManifestationCodelist(
        importProps,
        expressionCodelist,
      );
    },
    [getOuterRestrictedCodelist, importProps],
  );
};
