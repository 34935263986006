import {assert} from 'assert-ts';
import {DataValue, LinkedLiterary} from 'schemaDefinition/types';
import {assertAsLinkedLiterary} from 'schemaDefinition/functions/assertAsLinkedLiterary';

type RoleWithLinks = {
  role: string;
  links: LinkedLiterary[];
};
export const getLinkedLiteralsByRoles = (value: DataValue): RoleWithLinks[] => {
  const values = (Array.isArray(value) ? value : [value]).map(v =>
    assert(assertAsLinkedLiterary(v)),
  );
  const roles = values.reduce<string[]>((acc, val) => {
    if (val.role && !acc.includes(val.role)) {
      acc.push(val.role);
    }
    return acc;
  }, []);

  const rolesWithLinks = roles.map(role => ({
    role,
    links: values.filter(val => val.role === role),
  }));

  return rolesWithLinks;
};
