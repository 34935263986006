import {useMemo} from 'react';
import {assert} from 'assert-ts';
import {ManifestationItem} from 'services/data/metadata/types';

export const useMovedManifestationId = (
  content: ManifestationItem[],
): string => {
  return useMemo(() => {
    assert(
      content.length === 1,
      'NewExpression: Only one manifestation is supported',
    );
    assert(
      content[0].type === 'manifestation',
      'NewExpression: Content must be manifestation',
    );
    const manifestationId = content[0].id;
    return manifestationId;
  }, [content]);
};
