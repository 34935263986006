import React, {useCallback, useMemo} from 'react';
import {assert} from 'assert-ts';
import {AgentSubType, CollectionSubType, Concept} from 'types';
import {NationalAgentType} from 'api/types';
import {useWorkForManifestation} from 'services/data';
import {SaveAgentFunc, useAgent} from 'services/data/agent';
import {SaveCollectionFunc, useCollection} from 'services/data/collection';
import {useWorkWithExpressions} from 'services/data/metadata/hooks/useWorkWithExpressions';
import {assertCollectionValue, isAgentSubType} from 'services/utils';
import {assertAgentValue} from 'services/utils/functions/assertAgentValue';
import {isCollectionSubType} from 'services/utils/functions/isCollectionSubType';
import {useEditAgentDialog, useEditAgentWizardDialog} from 'editors';
import {useEditCollectionDialog} from 'editors/collections/hooks/useEditCollectionDialog';
import {EntityState, SearchResultType, SearchResultValue} from '../types';

type EntityDialogConfig = {
  entityState?: EntityState;
  handleAdd?: (type: SearchResultType, suggestion: string) => void;
  handleEdit?: (value: SearchResultValue) => void;
  EntityDialog?: React.FC;
};

const NotImplementedEntityDialogConfig: EntityDialogConfig = {
  EntityDialog: () => {
    assert(false, 'useEntityDialogs: EntityDialog not implemented');
    return null;
  },
};

export const useEntityDialogs = (
  entityType: SearchResultType,
  entityId: string | undefined,
  onSaveEntity?: (entityValue: SearchResultValue) => void,
): EntityDialogConfig => {
  const agentId = isAgentSubType(entityType) ? entityId : undefined;
  const collectionId = isCollectionSubType(entityType) ? entityId : undefined;
  const workId = entityType === Concept.work ? entityId : undefined;
  const manifestationId =
    entityType === Concept.manifestation ? entityId : undefined;
  const {agent, saveAgent, deleteAgent} = useAgent(agentId, 'partial');
  const {collection, saveCollection} = useCollection(collectionId);
  const work = useWorkWithExpressions(workId);
  const workForManifestation = useWorkForManifestation(manifestationId);
  const handleSaveAgent = useCallback<SaveAgentFunc>(
    (agent, options) => {
      return saveAgent(agent, options).then(savedAgent => {
        onSaveEntity && onSaveEntity(savedAgent);
        // setViewEntity(savedAgent);
        return savedAgent;
      });
    },
    [onSaveEntity, saveAgent],
  );
  const handleSaveCollection = useCallback<SaveCollectionFunc>(
    collection => {
      return saveCollection(collection).then(savedCollection => {
        onSaveEntity && onSaveEntity(savedCollection);
        return savedCollection;
      });
    },
    [onSaveEntity, saveCollection],
  );

  const {handleAddAgent, handleEditAgent, EditAgentDialog} =
    useEditAgentDialog(handleSaveAgent);
  const {handleAddAgentWizard, handleEditAgentWizard, EditAgentWizard} =
    useEditAgentWizardDialog(handleSaveAgent, deleteAgent);
  const {handleAddCollection, handleEditCollection, EditCollectionDialog} =
    useEditCollectionDialog(handleSaveCollection);

  return useMemo(() => {
    const entityMap: {
      [key in SearchResultType]?: EntityDialogConfig;
    } = {
      person: {
        entityState: agent,
        handleAdd: (type: SearchResultType, suggestion: string) =>
          handleAddAgentWizard(type as NationalAgentType, suggestion),
        handleEdit: (value: SearchResultValue) =>
          handleEditAgentWizard(assertAgentValue(value)),
        EntityDialog: EditAgentWizard,
      },
      corporation: {
        entityState: agent,
        handleAdd: (type: SearchResultType, suggestion: string) =>
          handleAddAgentWizard(type as NationalAgentType, suggestion),
        handleEdit: (value: SearchResultValue) =>
          handleEditAgentWizard(assertAgentValue(value)),
        EntityDialog: EditAgentWizard,
      },
      event: {
        entityState: agent,
        handleAdd: (type: SearchResultType, suggestion: string) =>
          handleAddAgentWizard(type as NationalAgentType, suggestion),
        handleEdit: (value: SearchResultValue) =>
          handleEditAgentWizard(assertAgentValue(value)),
        EntityDialog: EditAgentWizard,
      },
      publisher: {
        entityState: agent,
        handleAdd: (type: SearchResultType) =>
          handleAddAgent(type as AgentSubType),
        handleEdit: (value: SearchResultValue) =>
          handleEditAgent(assertAgentValue(value)),
        EntityDialog: EditAgentDialog,
      },
      series: {
        entityState: collection,
        handleAdd: (type: SearchResultType) =>
          handleAddCollection(type as CollectionSubType),
        handleEdit: (value: SearchResultValue) =>
          handleEditCollection(assertCollectionValue(value)),
        EntityDialog: EditCollectionDialog,
      },
      educationalSeries: {
        entityState: collection,
        handleAdd: (type: SearchResultType) =>
          handleAddCollection(type as CollectionSubType),
        handleEdit: (value: SearchResultValue) =>
          handleEditCollection(assertCollectionValue(value)),
        EntityDialog: EditCollectionDialog,
      },
      otherConnection: {
        entityState: collection,
        handleAdd: (type: SearchResultType) =>
          handleAddCollection(type as CollectionSubType),
        handleEdit: (value: SearchResultValue) =>
          handleEditCollection(assertCollectionValue(value)),
        EntityDialog: EditCollectionDialog,
      },
      publisherSeries: {
        entityState: collection,
        handleAdd: (type: SearchResultType) =>
          handleAddCollection(type as CollectionSubType),
        handleEdit: (value: SearchResultValue) =>
          handleEditCollection(assertCollectionValue(value)),
        EntityDialog: EditCollectionDialog,
      },
      work: {
        entityState: work,
      },
      manifestation: {
        entityState: workForManifestation,
      },
    };

    return entityMap[entityType] ?? NotImplementedEntityDialogConfig;
  }, [
    agent,
    EditAgentWizard,
    EditAgentDialog,
    collection,
    EditCollectionDialog,
    work,
    workForManifestation,
    entityType,
    handleAddAgentWizard,
    handleEditAgentWizard,
    handleAddAgent,
    handleEditAgent,
    handleAddCollection,
    handleEditCollection,
  ]);
};
