import {AgentName} from 'api/types';
import {Schemas} from 'api/dto.generated';

export const mapAgentNameDto = (
  dto: Schemas.AgentNameDto | string,
  description: string | undefined,
): AgentName => {
  if (typeof dto === 'string') {
    return {name: dto};
  }

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    nameOrder,
    ...rest
  } = dto;

  return {...rest, description};
};

export const mapToAgentNameDto = (
  data: AgentName,
  index = 0,
): Schemas.AgentNameDto => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    description,
    ...rest
  } = data;

  return {
    ...rest,
    nameOrder: index,
  };
};
