import {Metadata} from 'services/data/metadata//types';
import {MetadataEditAction} from 'services/data/metadata/metadataEditActionTypes';
import {assertExpression} from './assertExpression';

export function changeManifestationExpressionId(
  manifestationId: string,
  expressionId: string,
  metadata: Metadata,
  action?: MetadataEditAction,
): Metadata {
  assertExpression(expressionId, metadata, action, 'edit');
  return {
    work: metadata.work,
    // If prev expression becomes empty it will be filtered out in entity ordering.
    expressions: metadata.expressions,
    manifestations: metadata.manifestations.map(m =>
      m.id === manifestationId
        ? // Move given manifestation to other expression
          {
            ...m,
            expressionId,
          }
        : m,
    ),
    changeRequests: metadata.changeRequests.map(cr =>
      cr.manifestation.id === manifestationId
        ? // Also, move any change requests for given manifestation to other expression
          {
            ...cr,
            expression: {
              ...cr.expression,
              id: expressionId,
            },
            manifestation: {
              ...cr.manifestation,
              expressionId,
            },
          }
        : cr,
    ),
  };
}
