import React, {Fragment, useMemo} from 'react';
import {evaluateCondition} from 'schemaDefinition/functions';
import {Data, PartExpand, Valx} from '../../../schemaDefinition/types';
import {BasePartProps} from './types';
import {usePreviewConfigurationContext} from '../contexts';
import {renderSchemaFragment} from './renderSchemaFragment';

type PreviewPartExpandProps = BasePartProps<PartExpand<Valx>, Data>;

export const PreviewPartExpand: React.FC<PreviewPartExpandProps> = ({
  part,
  value,
  valuePath,
  scope,
  relatedScope,
  globalScope,
  layoutDirection,
}) => {
  const activeParts = useMemo(() => {
    const activeCase = part.when.find(w =>
      evaluateCondition(w.condition, {
        valuePath,
        localScope: scope,
        globalScope,
        relatedScope,
        value,
      }),
    );

    return activeCase?.parts ?? part.default;
  }, [
    part.when,
    part.default,
    valuePath,
    scope,
    globalScope,
    relatedScope,
    value,
  ]);
  const config = usePreviewConfigurationContext();

  const anyParts = activeParts !== undefined && activeParts.length > 0;

  // console.log("PreviewPartExpand...");
  // If default provided, we will always show something
  // Otherwise use transition group to animate in/out
  return anyParts ? (
    <Fragment>
      {renderSchemaFragment(
        value,
        valuePath,
        activeParts,
        layoutDirection,
        config,
      )}
    </Fragment>
  ) : null;
};
