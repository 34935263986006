import {Stack} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DataFormProps} from 'schema/form/types';
import {Data} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {FormSchemaGlobalScopeContextProvider} from '../../contexts';
import {
  DataFormConfigurationContext,
  DataFormContext,
  useDataFormConfigurationContextProviderValue,
  useDataFormContextProviderValue,
} from '../contexts';
import {DataFormForm} from './DataFormForm';
import {DataFormSchema} from './DataFormSchema';

export function DataForm<TData extends Data>(props: DataFormProps<TData>) {
  const {locale} = useLocalization();
  const dataFormConfigurationValue =
    useDataFormConfigurationContextProviderValue(props.configuration);
  const formUpdateValue = useDataFormContextProviderValue<TData>(props);
  const {dataFormSchema, relatedData, mode, useEditValue, useOriginalValue} =
    formUpdateValue;

  return (
    <DataFormConfigurationContext.Provider value={dataFormConfigurationValue}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
        <FormSchemaGlobalScopeContextProvider schema={dataFormSchema}>
          <DataFormContext.Provider value={formUpdateValue}>
            <DataFormForm {...props}>
              <Stack>
                <DataFormSchema
                  mode={mode}
                  schema={dataFormSchema}
                  relatedScope={relatedData}
                  valuePath=""
                  useValue={useEditValue}
                  useOriginalValue={useOriginalValue}
                />
              </Stack>
            </DataFormForm>
          </DataFormContext.Provider>
        </FormSchemaGlobalScopeContextProvider>
      </LocalizationProvider>
    </DataFormConfigurationContext.Provider>
  );
}
