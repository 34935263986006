import {LinkTargetCatalogPostTypes} from 'types';
import {DataValue, VerifiedLinkedCatalogPostLink} from 'schemaDefinition/types';
import {isLinkedValueLink} from './isLinkedValueLink';

export const isVerifiedLinkedCatalogPostLink = (
  link: DataValue | undefined,
): link is VerifiedLinkedCatalogPostLink =>
  // (Potential) LinkedLiterary if LinkedValue and not LinkedAgent
  isLinkedValueLink(link) &&
  link.linkStatus === 'verified' &&
  LinkTargetCatalogPostTypes.includes(
    (link as VerifiedLinkedCatalogPostLink).type,
  );
