import {Schemas} from 'api/dto.generated';
import {GetTokens, Lock, LockContextType} from './types';
import {exceptionToPromiseReject} from './exceptionToPromiseReject';
import {httpDelete, httpGet, httpPatch, httpPost} from './http/ebba';
import {mapLockDto} from './mappers';

export const getLocks = (
  getTokens: GetTokens,
  mock?: boolean,
): Promise<Lock[]> => {
  return exceptionToPromiseReject(() =>
    httpGet<Schemas.LocksDto>({
      subdir: 'lock',
      getTokens,
      mock,
    }).then(dto => (dto.locks ?? []).map(mapLockDto)),
  );
};

export const getLock = (
  contextId: string,
  contextType: LockContextType,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<Lock> => {
  return exceptionToPromiseReject(() =>
    httpGet<Schemas.LockDto>({
      subdir: 'lock/v2/{contextType}/{contextId}',
      subdirParams: {contextId, contextType},
      getTokens,
      mock,
    }).then(lock => {
      // Throw error on 204 (no lock)
      if (!lock) {
        throw new Error('204 No Content');
      }

      return mapLockDto(lock);
    }),
  );
};

type PostLockDataDto = Pick<Lock, 'contextId' | 'contextType'>;

export const postLock = (
  contextId: string,
  contextType: LockContextType,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<Lock> => {
  // console.log('postLock...', contextId, contextType);
  return exceptionToPromiseReject(() =>
    httpPost<Schemas.LockDto, PostLockDataDto>({
      subdir: 'lock',
      body: {contextId, contextType},
      getTokens,
      mock,
    }).then(dto => {
      // console.log('...posted lock', dto.id);
      return mapLockDto(dto);
    }),
  );
};

export const acquireLock = (
  id: string,
  userId: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<Lock> => {
  // console.log('acquire lock...', id);
  return exceptionToPromiseReject(() =>
    httpPatch<Schemas.LockDto, undefined>({
      subdir: 'lock/{id}/acquire',
      subdirParams: {id},
      getTokens,
      mock,
    }).then(dto => {
      // console.log('...acquired lock', dto.id);
      return mapLockDto(dto);
    }),
  );
};

export const deleteLock = (
  id: string,
  getTokens: GetTokens,
  mock?: boolean,
): Promise<void> => {
  // console.log('deleteLock', id);
  return exceptionToPromiseReject(() =>
    httpDelete({
      subdir: 'lock/{id}',
      subdirParams: {id},
      getTokens,
      mock,
    }),
  );
};
