import {useCallback} from 'react';
import {postManifestationOutboxTrigger} from 'api';
import {useGetTokens} from 'services/auth';
import {useMockContext} from 'services/utils/contexts';

export const useTriggerManifestationOutbox = () => {
  const getTokens = useGetTokens();
  const mock = useMockContext();

  return useCallback(
    (manifestationId: string): Promise<void> => {
      return postManifestationOutboxTrigger(manifestationId, getTokens, mock);
    },
    [getTokens, mock],
  );
};
