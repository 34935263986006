import React from 'react';
import {SvgIcon} from '@mui/material';
import {IconProps} from './types';

export const PreviewIcon: React.FC<IconProps> = (props: IconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M6.00004 6V8H14.59L5.29004 17.29L6.71004 18.71L16 9.41V18H18V6H6.00004Z" />
    </SvgIcon>
  );
};
