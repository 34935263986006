import React, {useCallback, useMemo, useState} from 'react';
import {FieldError, PartTextArea, Valx} from 'schemaDefinition/types';
import {evaluateFieldRequired} from 'schemaDefinition/functions';
import {TextAreaField} from 'components';
import {BaseFieldProps} from './types';
import {useDataFormContext} from '../../contexts';
import {
  toFieldError,
  validateSingleTextValue,
} from '../../functions/validators';
import {usePartConfiguration} from '../../hooks';

type Props = BaseFieldProps & {
  part: PartTextArea<Valx>;
};

export const TextArea: React.FC<Props> = ({
  part,
  value,
  setFieldValue,
  valuePath,
  label,
  placeholder,
  localScope,
  globalScope,
  relatedScope,
}) => {
  const [visited, setVisited] = useState(false);
  const handleSetVisited = useCallback(() => setVisited(true), []);
  const {showErrors} = useDataFormContext();

  const required = useMemo(() => {
    return evaluateFieldRequired(
      part.required,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      value,
    );
  }, [globalScope, part.required, relatedScope, localScope, value, valuePath]);

  const error = useMemo((): FieldError => {
    if (showErrors || visited) {
      const valid = validateSingleTextValue(part, {
        value,
        valuePath,
        localScope,
        globalScope,
        relatedScope,
      }).valid;
      return toFieldError(valid);
    }
    return false;
  }, [
    globalScope,
    part,
    relatedScope,
    localScope,
    showErrors,
    value,
    valuePath,
    visited,
  ]);
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      // Set empty values to null, to avoid sending empty strings to the backend
      const inputValue = event.target.value || null;
      setFieldValue(valuePath, inputValue);
    },
    [setFieldValue, valuePath],
  );

  const partLayout = usePartConfiguration(part);

  return (
    <TextAreaField
      name={valuePath}
      label={label}
      placeholder={placeholder}
      value={(value ?? '').toString()}
      required={required}
      readonly={part.readonly}
      maxLength={part.maxLength}
      rows={partLayout?.rows}
      onChange={handleChange}
      onBlur={handleSetVisited}
      error={error}
    />
  );
};
