import {ShowThesaurusCode} from 'schemaDefinition/types';
import {ThesaurusNode} from 'api';
import {getNodeDisplayCode} from './getNodeDisplayCode';

export const formatNodeTitle = (
  node: ThesaurusNode,
  showCodes: ShowThesaurusCode = false,
  forNodeTypes: string[] | undefined = undefined,
): string => {
  const code = getNodeDisplayCode(node, showCodes, forNodeTypes);
  return code ? `(${code}) ${node.label}` : node.label;
};
