import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {Amplify} from '@aws-amplify/core';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import {AppContainer} from './app/AppContainer';
import awsConfig from './aws-exports';
import {Head} from './head';
import './index.scss';
import {configureMuiPro} from './license/datagrid-pro';
import {configureLocalization} from './localization/core';
import {
  configureAssertLogging,
  configureLoggingOfUnhandledPromiseRejections,
  initLogging,
  logWarning,
} from './logging';
import reportWebVitals from './reportWebVitals';
import {configureStore} from './store';

initLogging();
configureAssertLogging();
configureLoggingOfUnhandledPromiseRejections();
configureLocalization({logWarning});
configureMuiPro();

Amplify.configure(awsConfig);

const {store, persistor} = configureStore();

const appContainer = document.getElementById('root');
if (!appContainer) {
  throw new Error('No root element found');
}
const root = createRoot(appContainer);
root.render(
  // TODO: Reenable strict mode when react-beautiful-dnd is compatible or has been phased out
  // Strict-mode disabled, not supported by react-beautiful-dnd,
  // <React.StrictMode>
  <BrowserRouter>
    <AppContainer store={store} persistor={persistor} />
  </BrowserRouter>,
  // </React.StrictMode>,
);

const head = document.getElementsByTagName('head').item(0);
if (!head) {
  throw new Error('No head element found');
}
const headContainer = document.createElement('head');
const headRoot = createRoot(headContainer);
headRoot.render(
  ReactDOM.createPortal(
    <Provider store={store}>
      <Head />
    </Provider>,
    head,
  ),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
