import {FieldRequired, FieldRequiredConditional} from '../types';

export const isFieldRequiredConditional = <TVal>(
  field?: FieldRequired<TVal>,
): field is FieldRequiredConditional<TVal> => {
  return !(
    typeof field === 'boolean' ||
    field === 'should' ||
    field === undefined
  );
};
