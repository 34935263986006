import * as React from 'react';
import {SxProps} from '@mui/material';
import {FooterPropsOverrides} from '@mui/x-data-grid';
import {GridFooterContainer} from '@mui/x-data-grid-pro';
import {PageSizeProps, PaginatorProps} from '../../paginator/types';
import {PageSizePicker, Paginator} from '../../paginator';

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    paginatorProps?: PaginatorProps;
    pageSizeProps?: PageSizeProps;
  }
}

const containerSx: SxProps = {
  flex: 1,
  justifyContent: 'end',
  p: 1,
};

export function CustomFooter({
  paginatorProps,
  pageSizeProps,
}: FooterPropsOverrides) {
  return paginatorProps ? (
    <GridFooterContainer sx={containerSx}>
      {pageSizeProps ? <PageSizePicker {...pageSizeProps} /> : null}
      <Paginator {...paginatorProps} />
    </GridFooterContainer>
  ) : null;
}
