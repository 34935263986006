import {assert} from 'assert-ts';
import {Collection} from 'api/types';
import {VerifiedLinkedCatalogPost} from '../../schemaDefinition/types';
import {Concept, LinkEntityType} from '../../types';
import {SeriesSchemas} from '../avro-series.generated';
import {
  SearchCollectionDto,
  SearchResult,
  SearchResultDto,
} from '../searchTypes';
import {mapSearchCollectionTypeDto} from './mapCollectionTypeDto';
import {mapHighlight} from './mapHighlights';
import {mapBaseRelationDto} from './mapRelationsDto';

export const mapSearchCollectionResult = (
  dto: SearchResultDto<SearchCollectionDto>,
): SearchResult<Collection> => {
  const hits = dto.hits.hits
    .map(h => mapSearchCollectionDto(h._source))
    .filter(a => a && a.collectionType) as Collection[];

  return {
    hits,
    highlights: dto?.hits.hits.map(h => mapHighlight(h.highlight)).flat(1),
    total: dto?.hits?.total.value || 0,
  };
};

const mapSearchCollectionDto = (dto: SearchCollectionDto): Collection => {
  const collectionType = mapSearchCollectionTypeDto(
    assert(dto.type, 'mapSearchCollectionDto: expected type', dto),
  );

  return {
    id: assert(dto.id, 'mapSearchCollectionDto: expected id', dto),
    collectionType,
    titles: dto.titles ?? [],
    links: (dto.relations ?? []).map(r => mapSeriesRelation(r, collectionType)),
  };
};

const mapSeriesRelation = (
  dto: SeriesSchemas.RelationDto,
  sourceType: LinkEntityType,
): VerifiedLinkedCatalogPost => {
  const {id, title, numberInSeries} = dto;
  const {role, link} = mapBaseRelationDto({
    role: 'HAS_PART',
    type: sourceType === Concept.publisherSeries ? 'MANIFESTATION' : 'WORK',
    entityId: assert(id, 'mapSeriesRelation: Expected id', dto),
  }) as VerifiedLinkedCatalogPost;

  return {
    role,
    numberInSeries,
    link: {
      ...link,
      name: title ?? '',
    },
  };
};
