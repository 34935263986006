import React from 'react';
import {ThesaurusId} from 'schemaDefinition/types';
import {CodeListsGate} from 'services/codeLists';
import {useAllCodeListIds} from 'services/codeLists/hooks/useAllCodeListIds';
import {ThesaurusesGate} from 'services/thesaurus/components';
import {LinkedValueSearchProps} from './types';
import {LinkedValueSearchCore} from './LinkedValueSearchCore';

const thesaurusIds: ThesaurusId[] = ['thema', 'bokbasen'];

export const LinkedValueSearch: React.FC<LinkedValueSearchProps> = props => {
  const allCodelistIds = useAllCodeListIds();

  return (
    <CodeListsGate codeLists={allCodelistIds}>
      <ThesaurusesGate thesauruses={thesaurusIds}>
        <LinkedValueSearchCore {...props} />
      </ThesaurusesGate>
    </CodeListsGate>
  );
};
