import {Concept} from 'types';
import {isSubTypeOfEntity} from 'services/utils/functions/isSubTypeOfEntity';
import {LinkedValueSearchFieldConfig} from '../types';

export const canLinkEntityToSource = (
  targetEntityType: Concept,
  sourceConfig: LinkedValueSearchFieldConfig | undefined,
) => {
  return sourceConfig && sourceConfig.canLink
    ? sourceConfig.allowedEntities
      ? sourceConfig.allowedEntities.reduce((acc, allowedEntityType) => {
          const allowed =
            targetEntityType === allowedEntityType ||
            isSubTypeOfEntity(targetEntityType, allowedEntityType);
          return allowed || acc;
        }, false)
      : true // Allowed not specified, then any
    : false; // No source to link to;
};
