import React, {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {assert} from 'assert-ts';
import {Concept} from 'types';
import {useLocalization} from 'localization';
import {AgentTypes} from 'services/settings';
import {isAgentSubType} from 'services/utils';
import {FlexBox, Scene, Text} from 'components';
import {useOpenSearch, useSceneExtensionsContext} from 'sceneExtensions';
import {AgentsToolbar} from './components';
import {AgentsContext, useAgentsContextProviderValue} from './context';

export const SearchAgentsScene: React.FC = () => {
  const {t} = useLocalization();
  const navigate = useNavigate();
  const value = useAgentsContextProviderValue();
  const {currentExtension} = useSceneExtensionsContext();
  const openSearch = useOpenSearch();

  const canEditEntity = useCallback(
    (entityType: Concept) => isAgentSubType(entityType),
    [],
  );

  const customHandleEditAgent = useCallback(
    (entityType: Concept, entityId: string) => {
      assert(
        isAgentSubType(entityType),
        'handleEditAgent: agent type expected',
        {entityType},
      );

      navigate(`/agent/${entityId}`);
    },
    [navigate],
  );

  const customHandleAddAgent = useCallback(
    (entityType: Concept) => {
      assert(
        isAgentSubType(entityType),
        'handleCreateAgent: agent type expected',
        {entityType},
      );

      navigate(`/agent/new?type=${entityType}&suggestion=${value.searchText}`);
    },
    [navigate, value.searchText],
  );

  const handleOpenSearch = useCallback(() => {
    openSearch({
      entity: Concept.agent,
      canEditEntity,
      handleEditEntity: customHandleEditAgent,
      handleAddEntity: customHandleAddAgent,
    });
  }, [canEditEntity, customHandleAddAgent, customHandleEditAgent, openSearch]);

  useEffect(() => {
    handleOpenSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AgentsContext.Provider value={value}>
      <Scene
        browserTitle={t('appbar.agents')}
        rightPanelConfig={currentExtension?.panelConfig}>
        <Scene.Header>
          <FlexBox horizontal justifyContent={'space-between'}>
            <Text variant="h1">{'Aktørregister'}</Text>
            <AgentsToolbar
              agentTypes={AgentTypes}
              onSearch={handleOpenSearch}
              onAdd={customHandleAddAgent}
              onAddWizard={customHandleAddAgent}
            />
          </FlexBox>
        </Scene.Header>
        <Scene.Content></Scene.Content>
        {currentExtension ? (
          <Scene.RightPanel>
            {currentExtension.renderRightPanel()}
          </Scene.RightPanel>
        ) : null}
      </Scene>
    </AgentsContext.Provider>
  );
};
