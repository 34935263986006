import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {editStatusesSelector, statusesSelector} from '../selectors';

export const useMetadataHasChanges = (): boolean => {
  const editStatuses = useSelector(editStatusesSelector);
  const statuses = useSelector(statusesSelector);

  return useMemo(
    () =>
      Object.keys(editStatuses).some(
        id => editStatuses[id].hasDataChanged || editStatuses[id].hasMoved,
      ) || Object.keys(statuses).some(id => statuses[id].changedStatus),
    [editStatuses, statuses],
  );
};
