import {Thesaurus} from 'api';
import {NodeIdToCodeMap, TreeNode} from './types';

/**
 * Returns map of {code: nodeId[]} for all nodes,
 * both with duplicates and single
 * @param thesaurus
 * @returns
 */
export const getNodeIdToCodeMap = (thesaurus: Thesaurus): NodeIdToCodeMap => {
  const map: NodeIdToCodeMap = new Map();
  // Find all {id: code} maps
  const visit = (node: TreeNode) => {
    map.set(node.id, node.code);
    if (node.children) {
      node.children.forEach(child => visit(child));
    }
  };
  visit(thesaurus);

  return map;
};
