import {ColumnConfig} from 'schema/table/types';
import {workDefaultColumns} from './workDefaultColumns';

export const workSummaryDefaultColumns: ColumnConfig[] = [
  {
    name: 'mainTitles',
    width: 150,
  },
  ...workDefaultColumns,
];
